//Chg_V1 : Select multiple consignee name for get data (Asrar Jr / 03-08-2024)
//Chg_V2 : Group by mainStation if source selected All option (Asrar Jr / 05-08-2024)
//Chg_V3 : Hide summary table if View type is "Details" on selected source dropdown (Asrar Jr / 05-08-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { ReportService } from "src/app/dataService/report-service";
import { NgbDateStruct, NgbTypeaheadSelectItemEvent, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Subject, Subscription, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';

@Component({
    selector: "app-consignee-report",
    templateUrl: "./consignee-report.component.html",
    styleUrls: ["./consignee-report.component.css"],
})
export class ConsigneeReportComponent implements OnInit {
    gettingDataFrmServiceFrSmryTable: any;
    gettingDataFrmServiceFrDetailTable: any;

    summaryDataList: any;
    detailDataList: any;

    onDestroyUnsubscribtionSmry: Subscription;
    onDestroyUnsubscribtionDetail: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    viewTypeSummary = true;
    viewTypeDetail = false;

    searchWithOldConsignor = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    @ViewChildren(DataTableDirective)
    public dtElements: QueryList<DataTableDirective>;

    dtTriggerSummary: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsSummary: any;
    dtOptionDetail: any;
    cbCOD = false;
    showAgentCB = false;

    ///For Agent Names

    agentNameOptions: LRDto[];
    agentDtoForAgentName: LRDto = new LRDto();
    public modelAgentName: any;
    agentNameTA: Array<LRDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    };
    agentDto: LRDto = new LRDto();
    agentDtoAll: LRDto = new LRDto();
    formatterAgentName = (x: { subStations: string }) => x.subStations;
    agentDtoSourceStationAllOption: LRDto = new LRDto();

    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    superAdminview: boolean = false;
    agentDetailsOptionsGet: any;
    agentNameList: LRDto = new LRDto();
    isLoggedIn = true;
    address: any;

    //
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;
    //For Consignee
    consigneeNameOptions: any;
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.consigneeNameTA
                    : this.consigneeNameTA.filter(
                        (v) =>
                            v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
    public modelDestinationFotGet: any;
    public modelConsigneeNameId: any;
    //for ConsignorName
    modelConsignorName: any;
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndex: any;

    //For Consignor Index
    consignorIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];


    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    ////
    consigneeSummaryTable = false;
    detailTable = false;
    selectedDestination: any;
    selectedConsigneeId: any;

    ///For Commodity
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
    setTodayDateOnFromDate: any;
    setTodayDateOnToDate: any;

    srcAllDest = false;
    srcAll = false;
    srcSpec = false;
    destView = false;
    agentView = false;

    showDestination = true;
    srcListsSet: Array<any> = [];
    // srcList: Array<any> = [];
    selectedFromDate: any;
    selectedToDate: any;

    selectedConsignorName: any;
    selectedConsigneeName: any;

    selectedSearchMode: any;
    selectedViewMode: any;

    sentSrcList: Array<any> = [];
    selectedCommodityName: any;
    selectedLrNumber: any;
    showAgent = false;
    showorhideDestination = false;//Pending
    //
    lrDtoSourceAll: LRDto = new LRDto();
    lrDtosSourceOptionsGet: LRDto[];
    lrDtosSourceOptionsGetForAgent: LRDto[];
    lrDtoBkgSrc: LRDto = new LRDto();
    srcList: LRDto = new LRDto;
    srcLists: any;
    lrDtoBkgSrcAll: LRDto = new LRDto();
    //For Source
    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
    //
    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    lrDtoAgentNameAddOption: LRDto = new LRDto();
    lrDtoSourceAddOption: LRDto = new LRDto();
    selectedSource: any;
    selectedAgentName: any;
    lrDtoForSearch: LRDto = new LRDto();
    selectedConsignorId: any;
    public modelConsignorNameId: any;
    searchSrcListInLoop: Array<any> = [];
    searchAgentListInLoop: Array<any> = [];
    sentAgentList: Array<any> = [];
    srcListTrue = false;
    setStation: any;

    //
    viewCustomPrintV1 = false;
    viewCustomPrintV2 = false;

    grandActWt = 0;
    grandChWt = 0;
    grandToPay = 0;
    grandPaid = 0;
    grandTotalLRVal = 0;
    grandTotalArtVal = 0;
    grandFovVal = 0;
    grandAOCVal = 0;
    grandTotalCashAmt = 0;
    grandGoodsVal = 0;

    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    pageId = "cnrc";
    //Chg_V1
    consigneeNamesList: any;
    dtTriggerConsigneeNames: Subject<any> = new Subject();
    dtOptionsConsigneeNames: any;
    private newAttributeSetConsigneeNames: any = {};
    selectedConsigneeNames: any;
    consigneeNameForDetails = false;
    consigneeNameForSummary = true;
    consigneeNamesDetails: any;
    dtTriggerSummaryWithAllOption: Subject<any> = new Subject();
    dtOptionsSummaryWithAllOption: any;
    consigneeSummaryTableWithAllOption = true;
    printForSummaryTable = true;
    selectedSourceOnDropdown: any;

    constructor(private reportService: ReportService,
        private masterReadService: MasterReadService, private datePipe: DatePipe,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        //Rights For Agent
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.address = this.userDataDtoReturnSession.addressId == null ? "" : this.userDataDtoReturnSession.addressId;
            this.setStation = this.userDataDtoReturnSession.mainStation;

            // this.getAgentDetailList();
            this.getDestinationDetails();
            this.getConsigneeDetails(false);
            this.getCommodityListRead(false);
            this.setConsginorIndex();
            this.getCommodityMasterDetailsList();
            this.selectTodayDate();
            this.showAgent = true;
            // In this src and agent is visible to all
            // but for super admin src and agent from same service
            // for others src and agent is form stationlist
            // In this report rigths is given only for  iqbal, superadmin,manojadmin login to show destiantion for others it is hidden as per asrar bhai
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt DestView") {
                        this.destView = true;
                        // all except delivery off
                        //  this.showorhideDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt SrcAll") {
                        this.srcAll = true;
                        // this.showorhideDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt SrcSpec") {
                        this.srcSpec = true;
                        // this.showorhideDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt AgentView") {
                        // this.agentView = true;
                        // for all
                        this.showAgentCB = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt SrcAllDest") {
                        this.srcAllDest = true;
                        //source for destination

                        //  this.showorhideDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt ShowDest") {
                        this.srcAllDest = true;
                        //New Right // by basha to show destination
                        this.showorhideDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsigneeRpt srcList") {
                        // New Right
                        //It is true for super admin //by basha  to show src and agent from service
                        this.srcListTrue = true;

                    }
                }
            }
            if (this.srcListTrue == true) {
                this.getSourceDetails();
                console.log('1');
            } else {
                console.log('2');
                this.getSourceList();
            }

        }


    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }
    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsSummary = {

            dom: "Bfrtip",

            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
                },
                title: function () {

                    var returSummary = null;
                    returSummary = "Consignee Summary Report " +
                        " From Date : " + moment($("#cnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#cnrctoDate").val()).format('DD-MM-YYYY');
                    return returSummary;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search...",
            },

            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,

            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var lrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var art = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodsVal = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var fov = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(lrs);
                // $(api.column(3).footer()).html();
                $(api.column(4).footer()).html(toPay);
                $(api.column(5).footer()).html(paid);
                $(api.column(6).footer()).html(art);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(chgWgt);
                $(api.column(9).footer()).html(goodsVal);
                $(api.column(10).footer()).html(fov);
                // $(api.column(11).footer()).html();


            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var lrs = 0;
                var toPay = 0;
                var paid = 0;
                var art = 0;
                var actWgt = 0;
                var chgWgt = 0;
                var goodsVal = 0;
                var fov = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total" + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": lrs
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": art
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": goodsVal
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));
                            lrs = 0;
                            toPay = 0;
                            paid = 0;
                            art = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            goodsVal = 0;
                            fov = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        //the below is for column name grouping (in this case we group for destination wise)
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 12,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    lrs += +val[2];
                    toPay += +val[4];
                    paid += +val[5];
                    art += +val[6];
                    actWgt += +val[7];
                    chgWgt += +val[8];
                    goodsVal += +val[9];
                    fov += +val[10];
                    if (i == (rows.length - 1)) {
                        //the below is for Sumary details grouping 
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total" + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": lrs
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": art
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": actWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": chgWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": goodsVal
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": fov
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))


                                    .prop('outerHTML'));
                        lrs = 0;
                        toPay = 0;
                        paid = 0;
                        art = 0;
                        actWgt = 0;
                        chgWgt = 0;
                        goodsVal = 0;
                        fov = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        },

            this.dtOptionDetail = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
                    },
                    title: function () {

                        var returSummary = null;
                        returSummary = "Consignee Detailed Report " +
                            " From Date : " + moment($("#cnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#cnrctoDate").val()).format('DD-MM-YYYY');
                        return returSummary;
                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,
                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var toPay = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var paid = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var art = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var memoAmt = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var aoc = api.column(20).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var goosdVal = api.column(21).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var fov = api.column(22).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var lc = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var bc = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(1).footer()).html('Total : ' + data.length);
                    // $(api.column(1).footer()).html();
                    // $(api.column(2).footer()).html();
                    // $(api.column(3).footer()).html();
                    // $(api.column(4).footer()).html();
                    // $(api.column(5).footer()).html();
                    // $(api.column(6).footer()).html();
                    $(api.column(8).footer()).html(toPay);
                    $(api.column(9).footer()).html(paid);
                    $(api.column(10).footer()).html(art);
                    $(api.column(11).footer()).html(actWgt);
                    $(api.column(12).footer()).html(chgWgt);
                    $(api.column(13).footer()).html(lc);
                    $(api.column(14).footer()).html(bc);
                    $(api.column(15).footer()).html(memoAmt);
                    // $(api.column(15).footer()).html();
                    // $(api.column(16).footer()).html();
                    // $(api.column(17).footer()).html();
                    // $(api.column(18).footer()).html();
                    $(api.column(20).footer()).html(aoc);
                    $(api.column(21).footer()).html(goosdVal);
                    $(api.column(22).footer()).html(fov);
                    // $(api.column(21).footer()).html();

                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var toPay = 0;
                    var paid = 0;
                    var art = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var memoAmt = 0;
                    var aoc = 0;
                    var goodsVal = 0;
                    var fov = 0;
                    var lc = 0;
                    var bc = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 6,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPay
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": art
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": lc
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": bc
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": memoAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 4,
                                                    "text": ""
                                                }))


                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": aoc
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": goodsVal
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": fov
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));
                                toPay = 0;
                                paid = 0;
                                art = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                memoAmt = 0;
                                aoc = 0;
                                goodsVal = 0;
                                fov = 0;
                                lc = 0;
                                bc = 0;
                                count = 0;
                                //pendingQty = 0;
                            }
                            //the below is for column name grouping (in this case we group for destination wise)
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 24,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;

                        toPay += +val[8];
                        paid += +val[9];
                        art += +val[10];
                        actWgt += +val[11];
                        chgWgt += +val[12];
                        memoAmt += +val[15];
                        aoc += +val[20];
                        goodsVal += +val[21];
                        fov += +val[22];
                        lc += +val[13];
                        bc += +val[14];
                        if (i == (rows.length - 1)) {
                            //the below is for Sumary details grouping 
                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": art
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": lc
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": bc
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": memoAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 4,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": aoc
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": goodsVal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))


                                        .prop('outerHTML'));
                            toPay = 0;
                            paid = 0;
                            art = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            memoAmt = 0;
                            aoc = 0;
                            goodsVal = 0;
                            fov = 0;
                            lc = 0;
                            bc = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                    });
                }
            },
            //Chg_V1
            this.dtOptionsConsigneeNames = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": false,
                "scrollY": 140,
                "scrollCollapse": false,
                "paging": false,
                "info": false
            },
            //Chg_V2
            this.dtOptionsSummaryWithAllOption = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    },
                    title: function () {

                        var returSummary = null;
                        returSummary = "Consignee Summary Report " +
                            " From Date : " + moment($("#cnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#cnrctoDate").val()).format('DD-MM-YYYY');
                        return returSummary;
                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,

                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var lrs = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPay = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var paid = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var art = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var goodsVal = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var fov = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(3).footer()).html(lrs);
                    // $(api.column(4).footer()).html();
                    $(api.column(5).footer()).html(toPay);
                    $(api.column(6).footer()).html(paid);
                    $(api.column(7).footer()).html(art);
                    $(api.column(8).footer()).html(actWgt);
                    $(api.column(9).footer()).html(chgWgt);
                    $(api.column(10).footer()).html(goodsVal);
                    $(api.column(11).footer()).html(fov);
                    // $(api.column(12).footer()).html();


                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var lrs = 0;
                    var toPay = 0;
                    var paid = 0;
                    var art = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var goodsVal = 0;
                    var fov = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": lrs
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPay
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": art
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": goodsVal
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": fov
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));
                                lrs = 0;
                                toPay = 0;
                                paid = 0;
                                art = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                goodsVal = 0;
                                fov = 0;
                                count = 0;
                                //pendingQty = 0;
                            }
                            //the below is for column name grouping (in this case we group for destination wise)
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 12,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        lrs += +val[3];
                        toPay += +val[5];
                        paid += +val[6];
                        art += +val[7];
                        actWgt += +val[8];
                        chgWgt += +val[9];
                        goodsVal += +val[10];
                        fov += +val[11];
                        if (i == (rows.length - 1)) {
                            //the below is for Sumary details grouping 
                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": lrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": art
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": goodsVal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))


                                        .prop('outerHTML'));
                            lrs = 0;
                            toPay = 0;
                            paid = 0;
                            art = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            goodsVal = 0;
                            fov = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                    });
                }
            }
    }


    ngOnDestroy(): void {
        this.dtTriggerSummary.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
        //Chg_V1
        this.dtTriggerConsigneeNames.unsubscribe();
        this.dtTriggerSummaryWithAllOption.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerSummary.next();
        this.dtTriggerDetail.next();
        //Chg_V1
        this.dtTriggerConsigneeNames.next();
        this.dtTriggerSummaryWithAllOption.next();
    }

    viewTypeMode(viewType: string) {
        if (viewType === "summary") {
            this.viewTypeSummary = true;
            this.viewTypeDetail = false;

            this.hideTable();
            this.clearTable();
            this.consigneeSummaryTable = false;
            //Chg_V1
            this.consigneeNameForSummary = true;
            this.consigneeNameForDetails = false;
            this.consigneeSummaryTableWithAllOption = true
            this.printForSummaryTable = true;

        } else if (viewType === "detail") {
            this.viewTypeSummary = false;
            this.viewTypeDetail = true;

            this.hideTable();
            this.clearTable();
            this.detailTable = true;
            //Chg_V1
            this.consigneeNameForDetails = true;
            this.consigneeNameForSummary = false;
            this.printForSummaryTable = false;


        } else {
            this.viewTypeSummary = false;
            this.viewTypeDetail = false;
            this.hideTable();
            this.clearTable();
            //Chg_V1
            this.consigneeNameForDetails = false;
            this.consigneeNameForSummary = false;
        }
    }
    hideTable() {
        this.consigneeSummaryTable = false;
        this.detailTable = false;
        //Chg_V1
        this.consigneeSummaryTableWithAllOption = false
        this.printForSummaryTable = false;
    }
    clearTable() {
        $("#" + this.pageId + "summaryTableId").DataTable().destroy();
        this.summaryDataList = [];
        this.dtTriggerSummary.next();
        $("#" + this.pageId + "detailTableId").DataTable().destroy();
        this.detailDataList = [];
        this.dtTriggerDetail.next();
        //Chg_V1
        $("#" + this.pageId + "consigneeNameDataTableId").DataTable().destroy();
        this.consigneeNamesList = [];
        this.dtTriggerConsigneeNames.next();
        //Chg_V2
        $("#" + this.pageId + "summaryTableIdWithAllOption").DataTable().destroy();
        this.summaryDataList = [];
        this.dtTriggerSummaryWithAllOption.next();

    }

    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();
    }

    //for datePicker
    toggleEditableForCOD(event) {
        if (event.target.checked) {
            this.cbCOD = true;
            this.showAgent = true;
        } else {
            this.cbCOD = false;
            this.showAgent = false;
        }
    }
    //For Agent Name Listener
    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
    }
    // //For Agent Names
    // getUserValuesForAgentDetailsList() {
    //     if ((this.superAdminview = true)) {
    //         this.agentDto.mode = "Sub";
    //         this.agentDto.status = "All";
    //     } else {
    //         this.agentDto.mode = "mainBranch";
    //         if ((this.userDataDtoReturnSession.role = "Booking Administrator")) {
    //             this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
    //         } else {
    //             this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    //         }
    //     }
    //     this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
    //     console.log(this.agentDto);
    // }

    // getAgentDetailList() {
    //     this.getUserValuesForAgentDetailsList();
    //     this.showSpinnerForAction = true;
    //     this.masterReadService
    //         .getSourceListDetails(this.agentDto)
    //         .subscribe((response) => {
    //             console.log(response);
    //             this.agentNameTA = [];

    //             this.agentNameOptions = [];
    //             if (response.length > 0) {
    //                 this.agentNameOptions = response;
    //                 this.agentDtoSourceStationAllOption.subStation = "All";
    //                 this.agentNameTA.push(this.agentDtoSourceStationAllOption);
    //                 for (let i = 0; i < this.agentNameOptions.length; i++) {
    //                     //console.log( this.agentDetailsOptionsGet[i].subStation );
    //                     this.agentNameTA.push(this.agentNameOptions[i]);
    //                 }

    //             }
    //             this.showSpinnerForAction = false;
    //         }),
    //         (error) => {
    //             this.showSpinnerForAction = false;
    //             swal(
    //                 "Error",
    //                 "Server Problem Occurred While getting the Source Details",
    //                 "info"
    //             );
    //         },
    //         () => console.log("done");
    // }

    //For Destination Listener
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }
    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
        console.log(this.selectedConsignorId);
    }

    //for the select option with filter ends
    //For Destination DropDown
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        // this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        // this.lrDtoDestinationAll.destination = "All";
                        // this.destinationTA.push(this.lrDtoDestinationAll);
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                        console.log(this.userDataDtoReturnSession.mainStation);
                        window.setTimeout(function () {
                            $("#" + this.pageId + "destination").val(this.setStation);
                        }, 1000);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the Destination Details",
                    "info"
                );
            },
            () => console.log("done");
    }


    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.showSpinnerForAction = true;
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedDestination = this.modelDestinationFotGet;
        if (destShow) {
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService
            .getConsigneeMaster(this.partyMasterDtoForconsignee)
            .subscribe((response) => {
                //Chg_V1
                this.selectedConsigneeNames = [];
                this.consigneeNamesList = [];
                this.consigneeNamePartyDtoAllOption = new PartyMasterDto();

                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val("");
                } else {
                    //Chg_V1
                    this.consigneeNamesList = [];
                    $("#" + this.pageId + "consigneeNameDataTableId").DataTable().destroy();

                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val("");
                    this.consigneeNamePartyDtoAllOption.consigneeName = 'All';
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.consigneeNameTATemp = this.consigneeNameTA;
                    console.log(this.consigneeNameTA);
                    if (destShow) {
                        $("#" + this.pageId + "consigneeName").focus();
                    }
                    //Chg_V1
                    this.consigneeNamesDetails = [];
                    this.consigneeNamesDetails = response;
                    this.consigneeNamePartyDtoAllOption.consigneeName = 'All';
                    this.consigneeNamesList = [];
                    this.consigneeNamesList.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNamesDetails.length; i++) {
                        this.consigneeNamesList.push(this.consigneeNamesDetails[i]);
                    }
                }
                //Chg_V1
                this.dtTriggerConsigneeNames.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the consignee details",
                    "info"
                );
            },
            () => console.log("done");
    };

    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedDestination = this.modelDestinationFotGet.destination;
        console.log(this.selectedDestination)
        if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != "All") {
            this.getConsigneeDetails(true);
            this.getCommodityListRead(true);
        } else {
            this.consigneeNameTA = [];
            this.modelConsigneeName = '';
        }
    }
    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
        console.log(this.selectedConsigneeId);
        console.log(this.modelConsigneeNameId);
    }

    ///For Consignor DropDown
    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    // window.setTimeout(function () {
                    //     $("#"+this.pageId+"consignorName").focus();
                    // }, 100);
                    console.log(this.consignorNameTA);
                    this.changeDetectorRef.detectChanges();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }


    //click Listener For Commodity
    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    }


    //For Commodity Drop Down
    getCommodityListRead(destShow) {
        console.log('am here');
        this.selectedDestination = this.modelDestinationFotGet;
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        if (destShow) {
            this.rateMasterDto.destination = this.selectedDestination.destination;
            //this.rateMasterDto.destination = null;
            console.log(this.selectedDestination);
        } else {
            this.rateMasterDto.destination = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.status = "Working";
        console.log(this.rateMasterDto);
        this.getCommodityMasterDetailsList();
    }

    getCommodityMasterDetailsList = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        console.log('am here1');
                    } else {
                        console.log('am here2');
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption.commodityName = 'All';
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        $("#" + this.pageId + "commodityListId").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    //FromStation for superadmin 
    getSourceDetailedList() {
        this.lrDtoSource.mode = 'Booking';
        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSource.reportMode = 'InclMainStation';
        console.log(this.lrDtoSource);
    }

    getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.lrDtoSourceOptions = response;
                        this.agentNameOptions = response;
                        console.log(this.lrDtoSourceOptions);
                        this.sourceTA = [];
                        this.agentNameTA = [];
                        this.searchSrcListInLoop = [];
                        this.searchAgentListInLoop = [];

                        this.lrDtoSourceAllOption = new LRDto();
                        this.lrDtoSourceAllOption.subStations = "All";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        this.agentDtoSourceStationAllOption.subStations = "All";
                        this.agentNameTA.push(this.agentDtoSourceStationAllOption);
                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.sourceTA.push(this.lrDtoSourceOptions[i]);
                            this.agentNameTA.push(this.agentNameOptions[i]);
                        }
                        this.lrDtoSourceAllOption = new LRDto();
                        this.lrDtoSourceAllOption.subStations = "Non Delhi";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        this.lrDtoSourceAllOption = new LRDto();
                        this.lrDtoSourceAllOption.subStations = "Delhi & Non Delhi";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.searchSrcListInLoop.push(this.lrDtoSourceOptions[i].subStations);
                            this.searchAgentListInLoop.push(this.agentNameOptions[i].subStations);
                        }
                        $("#" + this.pageId + "source").val('All');
                        $("#" + this.pageId + "agentName").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Details", "info");
            },
            () => console.log('done');

    };

    //From Station
    getSourceList() {
        console.log('not');
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStations = this.srcLists;
        this.sourceTA = [];
        this.lrDtoSourceAllOption.subStations = 'All';
        this.sourceTA.push(this.lrDtoSourceAllOption);
        this.agentNameTA = [];
        this.agentDtoSourceStationAllOption.subStations = "All";
        this.agentNameTA.push(this.agentDtoSourceStationAllOption);
        this.searchSrcListInLoop = [];
        this.searchAgentListInLoop = [];
        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.lrDtoSourceAddOption = new LRDto();
            this.lrDtoSourceAddOption.subStations = this.srcList.subStations[i];
            this.sourceTA.push(this.lrDtoSourceAddOption);
            this.lrDtoAgentNameAddOption = new LRDto();
            this.lrDtoAgentNameAddOption.subStations = this.srcList.subStations[i];
            this.agentNameTA.push(this.lrDtoAgentNameAddOption);

        }
        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.searchSrcListInLoop.push(this.srcList.subStations[i]);
            this.searchAgentListInLoop.push(this.srcList.subStations[i]);
        }
        window.setTimeout(function () {
            $("#" + this.pageId + "source").val('All');
        }, 200);
        window.setTimeout(function () {
            $("#" + this.pageId + "agentName").val('All');
        }, 200);

    }

    //For Search Button
    searchMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.selectedCommodityName = $("#" + this.pageId + "commodityListId").val();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.selectedViewMode = $("#" + this.pageId + "viewType").val();
        this.lrDtoForSearch = new LRDto();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "Please select From Date,To Date  to get the  details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.lrDtoForSearch.fromDate = this.selectedFromDate;
            this.lrDtoForSearch.toDate = this.selectedToDate;
            this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForSearch.consigneeId = 0;
            this.lrDtoForSearch.source = this.selectedSource;

            if (this.selectedViewMode == "summary") {
                this.lrDtoForSearch.reportMode = "summary";
                //Chg_V1
                if (this.selectedConsigneeNames != null && this.selectedConsigneeNames != '' && this.selectedConsigneeNames.length > 0) {
                    this.lrDtoForSearch.listOfConsigneId = [];
                    for (let i = 0; i < this.selectedConsigneeNames.length; i++) {
                        console.log(this.selectedConsigneeNames[i].consigneeName);
                        if (this.selectedConsigneeNames[i].consigneeName == "All") {
                            this.lrDtoForSearch.listOfConsigneId = [];
                            this.lrDtoForSearch.consigneeName = "All";
                        } else {
                            this.lrDtoForSearch.listOfConsigneId.push(this.selectedConsigneeNames[i].consigneeId);
                        }
                    }
                }
            } else {
                this.lrDtoForSearch.consigneeName = this.selectedConsigneeName;
                this.lrDtoForSearch.reportMode = "details";
                //Chg_V1
                if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
                    this.lrDtoForSearch.consigneeId = this.selectedConsigneeId;
                }
            }

            // if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
            //     this.lrDtoForSearch.consigneeId = this.selectedConsigneeId
            // }
            this.lrDtoForSearch.consignorId = 0;
            this.lrDtoForSearch.consignorName = this.selectedConsignorName;
            if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                this.lrDtoForSearch.consignorId = this.selectedConsignorId;
                console.log(this.selectedConsignorId);
            }
            this.lrDtoForSearch.mode = "source";
            if (this.selectedCommodityName == '' || this.selectedCommodityName == null || this.selectedCommodityName == 'All') {
                this.lrDtoForSearch.column2 = 'All';
            } else {
                this.lrDtoForSearch.column2 = this.selectedCommodityName;
            }
            if (this.selectedSource == '' || this.selectedSource == null || this.selectedSource == 'All') {
                this.sentSrcList = [];
                this.sentSrcList = this.searchSrcListInLoop;
                this.lrDtoForSearch.list = this.sentSrcList;
            } else {
                this.sentSrcList = [];
                this.sentSrcList.push(this.selectedSource);
                this.lrDtoForSearch.list = this.sentSrcList;
            }
            if (this.selectedAgentName == '' || this.selectedAgentName == null || this.selectedAgentName == 'All') {
                this.sentAgentList = [];
                this.sentAgentList = this.searchAgentListInLoop;
                this.lrDtoForSearch.listAgent = this.sentAgentList;
            } else {
                this.sentAgentList = [];
                this.sentAgentList.push(this.selectedAgentName);
                this.lrDtoForSearch.listAgent = this.sentAgentList;
            }
            if (this.showorhideDestination == true) {
                if (this.selectedDestination == null || this.selectedDestination == '') {
                    swal({
                        title: "Mandatory Field",
                        text: "Please select Destination to get the  details!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrDtoForSearch.destination = this.selectedDestination;
                    this.getSearchDetails();
                }
            } else {

                this.lrDtoForSearch.destination = this.userDataDtoReturnSession.mainStation;
                this.getSearchDetails();
            }
            console.log(this.lrDtoForSearch);
        }

    }
    getSearchDetails = () => {
        this.showSpinnerForAction = true;
        this.reportService.getConsigneeNamesReport(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "summaryTableId").DataTable().destroy();
                this.summaryDataList = [];
                $("#" + this.pageId + "detailTableId").DataTable().destroy();
                this.detailDataList = [];

                //Chg_V2
                $("#" + this.pageId + "summaryTableIdWithAllOption").DataTable().destroy();
                this.summaryDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.selectedViewMode = $("#" + this.pageId + "viewType").val();
                    if (this.selectedViewMode == "summary") {
                        this.summaryDataList = response;
                        console.log("this.summaryDataList");
                        console.log(this.summaryDataList);
                    } else {
                        this.detailDataList = response;
                        console.log("this.detailDataList");
                        console.log(this.detailDataList);
                    }

                }
                this.dtTriggerSummary.next();
                this.dtTriggerDetail.next();
                //Chg_V2
                this.dtTriggerSummaryWithAllOption.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Consignee Details  Report ", "info");
            }, () => console.log('done');
    };


    lrNumberToEnquiryForm(validationLrsDetailsData) {
        this.selectedLrNumber = validationLrsDetailsData.lrNumber;
        console.log(this.selectedLrNumber);
        localStorage.clear();
        localStorage.setItem('lrNumberFromEwayBillValidation',
            JSON.stringify(this.selectedLrNumber));
        this.router.navigate(['/lr/enquiry/lrEnquiry']);
    }



    pritnSummaryWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.summaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "Total Lrs", "Consignee Gst", "To Pay", "Paid", "Article", "Act Wgt", "Chg Wgt", "Goods Value", "F O V", "IS Rate Set"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalLRVal = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalArtVal = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandGoodsVal = 0;
            this.grandFovVal = 0;

            for (let i = 0; i < this.summaryDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].consigneeName, this.summaryDataList[i].totalLrs, this.summaryDataList[i].gstNoConsignee, this.summaryDataList[i].toPay, this.summaryDataList[i].paid, this.summaryDataList[i].totalArticles, this.summaryDataList[i].actualWeight, this.summaryDataList[i].chargedWeight, this.summaryDataList[i].goodsValue, this.summaryDataList[i].riskCharge, this.summaryDataList[i].isAdminRateSet];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalLRVal = +this.grandTotalLRVal + +this.summaryDataList[i].totalLrs;
                this.grandToPay = +this.grandToPay + +this.summaryDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.summaryDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.summaryDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.summaryDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.summaryDataList[i].chargedWeight;
                this.grandGoodsVal = +this.grandGoodsVal + +this.summaryDataList[i].goodsValue;
                this.grandFovVal = +this.grandFovVal + +this.summaryDataList[i].riskCharge;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, this.grandTotalArtVal, "", this.grandToPay, this.grandPaid, this.grandTotalArtVal, this.grandActWt, this.grandChWt, this.grandGoodsVal, this.grandFovVal, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From", "To "];
            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Consignee Summary Report ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnDetailsWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.detailDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Booking Date", "Invoice Number", "Consignor Name", "Commodity", "To Pay", "Paid", "Art", "LC", "BC", "CashMemo Amt", "Act Wgt", "Chg Wgt", "Arr Wt", "Del Wt", "AOC", "Goods Value", "F O V", "Is Rate Set?"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [5, 5, 5, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalArtVal = 0;
            this.grandFovVal = 0;
            this.grandAOCVal = 0;
            this.grandTotalCashAmt = 0;
            this.grandGoodsVal = 0;

            for (let i = 0; i < this.detailDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].lrNumber, this.detailDataList[i].bookingDateStr, this.detailDataList[i].invoiceNumber, this.detailDataList[i].consignorName, this.detailDataList[i].commodityName, this.detailDataList[i].toPay, this.detailDataList[i].paid, this.detailDataList[i].totalArticles, this.detailDataList[i].lcChg, this.detailDataList[i].bcChg, this.detailDataList[i].cashAmt, this.detailDataList[i].actualWeight, this.detailDataList[i].chargedWeight, this.detailDataList[i].arrivalwithin, this.detailDataList[i].deliveredwithin, this.detailDataList[i].goodsValue, this.detailDataList[i].aoc, this.detailDataList[i].riskCharge, this.detailDataList[i].isAdminRateSet];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandToPay = +this.grandToPay + +this.detailDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.detailDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.detailDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.detailDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.detailDataList[i].chargedWeight;
                this.grandFovVal = +this.grandFovVal + +this.detailDataList[i].riskCharge;
                this.grandAOCVal = +this.grandAOCVal + +this.detailDataList[i].aoc;

                this.grandTotalCashAmt = +this.grandTotalCashAmt + +this.detailDataList[i].cashAmt;
                this.grandGoodsVal = +this.grandGoodsVal + +this.detailDataList[i].goodsValue;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", "", "", this.grandToPay, this.grandPaid, this.grandTotalArtVal, "", "", this.grandTotalCashAmt, this.grandActWt, this.grandChWt, "", "", this.grandAOCVal, this.grandGoodsVal, this.grandFovVal, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ['AGRA','Alang– BH','ALIGARH','Aligrarh','Amritsar','ANDHERI','BAKOLI','Balotra','Bangalore Bkg','Bawana, Bhilwara','BUDHPUR','Chatral','CHINCH BUNDER','Daman','DAYA BASTI','Dhared - JM','Dhebar rd. - RJ', 'DINA NAGAR', 'FARIDABAD', 'Gandhi Nagar', 'GHAZIABAD','Gurgaon', 'Hamilton Road', 'Jagadhri', 'Jalandhar', 'JALLANDHAR', 'JAMUNA BAZAR', 'Jetalpur - AH', 'Kalasipalyam', 'KAMLA MARKET', 'KARNAL', 'KAROL BAGH', 'KAROL BAGH (NEW)', 'KASHMERE GATE', 'LAJPAT RAI MARKET', 'Ludhiana', 'Malerkotla', 'MANGOL PURI(NANGLOI)', 'MAYA PURI', 'Meerut', 'Metoda - RJ', 'Mundka', 'NANGLIPOONA', 'Naraina', 'Narela', 'Narol - AH','NOIDA', 'Odhav - AH',' OLD Bunder rd. - BH', 'OPERA HOUSE', 'Pali', 'PANIPAT', 'PILKHUWA, PURNA', 'SADAR BAZAR', 'SADAR BAZAR (P.O)', 'SAMAY PUR BADLI', 'Sarangpur - AH', 'Sarkhej - AH', 'Shahdara', 'Shapar - RJ', 'Sihor– BH','Udyog Nagar - JM', 'VAPI', 'VASAI', 'VASAI (E)', 'vashi','Wazir Pur'];
            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Consignee Details Report ");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }

    clearAll() {

        this.selectedFromDate = '';
        this.selectedLrNumber = '';
        this.selectedToDate = '';
        this.selectedConsignorName = '';
        $("#" + this.pageId + "consignorName").val('');
        this.selectedCommodityName = '';
        $("#" + this.pageId + "commodityListId").val('');
        this.selectedConsigneeName = '';
        $("#" + this.pageId + "consigneeName").val('');
        this.selectedDestination = '';
        $("#" + this.pageId + "destination").val('');
        this.selectedSearchMode = '';

        this.selectedViewMode = '';
        $("#" + this.pageId + "viewType").val('summary');
        this.lrDtoForSearch = new LRDto();
        this.searchWithOldConsignor = false;
        $("#" + this.pageId + "consignorName").val('');
        this.modelConsignorName = '';
        this.consignorNameTA = [];
        this.modelConsignorIndex = '';
        $("#" + this.pageId + "consignorIndex").val('');

        this.selectTodayDate();
        this.viewTypeSummary = true;
        this.viewTypeDetail = false;
        this.hideTable();
        this.clearTable();
        this.consigneeSummaryTable = false;



        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandToPay = 0;
        this.grandPaid = 0;
        this.grandTotalLRVal = 0;
        this.grandTotalArtVal = 0;
        this.grandFovVal = 0;
        this.grandAOCVal = 0;
        this.grandTotalCashAmt = 0;
        this.grandGoodsVal = 0;
        this.viewCustomPrintV2 = false;
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.selectTodayDate();
        this.showSpinnerForAction = false;
        this.modelConsignorNameId = '';
        this.modelConsignorNameId = '';
        this.selectedConsignorId = '';
        this.modelConsigneeNameId = '';
        this.selectedConsigneeId = '';
        this.modelDestinationFotGet = '';
        this.selectedDestination = '';
        //Chg_v1
        this.consigneeNameForDetails = false;
        this.consigneeNameForSummary = true;
        this.consigneeSummaryTableWithAllOption = true
        this.printForSummaryTable = false;
        this.summaryDataList = [];
        $("#" + this.pageId + "summaryTableIdWithAllOption").DataTable().destroy();
        this.dtTriggerSummaryWithAllOption.next();
    }

    //Chg_v1
    checkBoxForConsigneeNameClicked(e, consigneeNames, index) {
        console.log("e, consigneeNames, index");
        console.log(e, consigneeNames, index);

        if (e.currentTarget.checked) {
            const newAttributeSetConsigneeNames = {
                consigneeId: consigneeNames.consigneeId,
                consigneeName: consigneeNames.consigneeName
            };
            if (!this.selectedConsigneeNames) {
                this.selectedConsigneeNames = [];
            }
            const consigneeDtails = this.selectedConsigneeNames.find(consigneeNameDetails =>
                consigneeNameDetails.consigneeId === newAttributeSetConsigneeNames.consigneeId);

            if (!consigneeDtails) {
                this.selectedConsigneeNames.push(newAttributeSetConsigneeNames);
            }
        } else {
            let index = this.selectedConsigneeNames.findIndex(x => x.consigneeId === consigneeNames.consigneeId);
            if (index !== -1) {
                this.selectedConsigneeNames.splice(index, 1);
            }
        }
        console.log("this.consigneeNameDetails >> checkBoxForConsigneeNameClicked");
        console.log(this.selectedConsigneeNames);
        this.changeDetectorRef.detectChanges();
    }

    //Chg_v2
    rowSelectedForSource(e: NgbTypeaheadSelectItemEvent) {
        this.modelSource = e.item;
        this.selectedSourceOnDropdown = this.modelSource.subStations;
        console.log(this.modelSource);
        console.log(this.selectedSourceOnDropdown);
        this.summaryDataList = [];
        $("#" + this.pageId + "summaryTableId").DataTable().destroy();
        this.dtTriggerSummary.next();
        $("#" + this.pageId + "summaryTableIdWithAllOption").DataTable().destroy();
        this.dtTriggerSummaryWithAllOption.next();

        //Chg_V3
        this.selectedViewMode = $("#" + this.pageId + "viewType").val();
        if (this.selectedViewMode == "summary") {
            if (this.selectedSourceOnDropdown == '' || this.selectedSourceOnDropdown == null || this.selectedSourceOnDropdown == "All") {
                this.consigneeSummaryTableWithAllOption = true;
                this.consigneeSummaryTable = false;
                this.printForSummaryTable = true;
            } else {
                this.consigneeSummaryTableWithAllOption = false;
                this.consigneeSummaryTable = true;
                this.printForSummaryTable = true;
            }
        }
    }

}
