import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { MasterReadService } from 'src/app/dataService/masterread-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { MasterService } from 'src/app/dataService/master-service';
@Component( {
    selector: 'app-expenses-heads',
    templateUrl: './expenses-heads.component.html',
    styleUrls: ['./expenses-heads.component.css']
} )
export class ExpensesHeadsComponent implements OnInit {
    //    getDataExpensesHeadsFrTable: any;

    expensesHeadsDataList: any;
    hireSlipDtoSaveRet: any;


    //    onDestroyUnsubscribtionExpensesHeads: Subscription;
    hireSlipDto: HireSlipDto = new HireSlipDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView = false;
    loadingIndicator = true;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerExpensesHeads: Subject<any> = new Subject();


    dataTable: any;
    dtOptionsExpensesHeads: any;
    pageId="exhct";




    constructor( private masterReadService: MasterReadService, private http: HttpClient,
        private router: Router,
        private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef,
        
        //            service ends        
    ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.expensesHeadsDatatable();
            this.getExpensesHeadsList();
        }
    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }




    ngOnInit() {


    }
    expensesHeadsDatatable() {
        this.dtOptionsExpensesHeads = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.getDataExpensesHeadsFrTable = this.expensesHeadsScreen.getSummaryData()
        //            this.onDestroyUnsubscribtionExpensesHeads = this.getDataExpensesHeadsFrTable.subscribe( data => {
        //                this.expensesHeadsDataList = data['data'];
        //                this.dtTriggerExpensesHeads.next();
        //            } );
    }
    ngOnDestroy(): void {
        this.dtTriggerExpensesHeads.unsubscribe();


        //        this.onDestroyUnsubscribtionExpensesHeads.unsubscribe();
    }

    //  service starts
    getUserDetailsForReadExpensesHeads() {
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        if ( this.supAdmView == false ) {
            this.hireSlipDto.mode = "Spec";
            if ( this.userDataDtoReturnSession.role == "Booking Administrator" ) {
                this.hireSlipDto.branch = this.userDataDtoReturnSession.mainStation;
            } else {
                this.hireSlipDto.branch = this.userDataDtoReturnSession.mainAdminStation;
            }
        }

    }


    getExpensesHeadsList = () => {
//        console.log( this.hireSlipDto.companyid );
        this.getUserDetailsForReadExpensesHeads();
        this.masterReadService.getExpensesHeadsService( this.hireSlipDto ).subscribe(
            ( response ) => {
                if ( response ) {
//                    console.log( response );
                    this.expensesHeadsDataList = response;
                    this.dtTriggerExpensesHeads.next();
//                    console.log( this.expensesHeadsDataList[0] );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Expenses Heads Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    //    service ends
    //to insert value of selected row in table to input field starts
    rowSelected( expensesHeadsData ) {
        this.hireSlipDto.expensesHead = expensesHeadsData.expensesHead;
        this.hireSlipDto.balance = expensesHeadsData.balance;
    }
    //to insert value of selected row in table to input field ends

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    validateExpensesHeadDetails() {
        this.saveUpdateExpensesHeadDetails();
    }
    saveExpensesHeadDetails() {
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDto.branch = this.userDataDtoReturnSession.mainStation;
    }
    saveUpdateExpensesHeadDetails = () => {
        this.saveExpensesHeadDetails();
        this.masterService.createExpensesHead( this.hireSlipDto ).
            subscribe(( data ) => {
                this.hireSlipDtoSaveRet = data;
//                console.log( this.hireSlipDtoSaveRet.status );
                if ( this.hireSlipDtoSaveRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Expenses Head Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Expenses Head Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Expenses Head Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
    }

}

