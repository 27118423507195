<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>

    <div>
        <!-- Row -->
        <div class="row" *ngIf="isLoggedIn" id="{{pageId}}mainSrcLrDetailsInvRptPopupId">
            <div class="col-lg-12">
                <div class="card " style="border: 1px solid darkcyan !important;">
                    <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                        <h6 class="card-title text-white">Main Source Invoice Details - {{popupMainSrcLrDetailsPopupLocalStorage.invoiceNumber}}</h6>
                    </div>
                    <div></div>
                    <div class="col-sm-4 col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Report Type</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-search"></i>
									</span>
                                </div>
                                <select class="custom-select col-12" id="{{pageId}}reportType" name="reportType" #reportType (change)="reportTypeMode(reportType.value)">
									<option selected value="mainWise">Main Station Wise</option>
									<option value="subWise">Sub Station Wise</option>
								</select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8 col-md-8"></div>

                </div>
                <br />
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <table datatable id="{{pageId}}mainSrcLrDetailsPopupTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMainSrcLrDetailsPopupDetails" [dtTrigger]="dtTriggerMainSrcLrDetailsPopupDetails">

                                <thead>
                                    <tr>

                                        <th>LR Number</th>
                                        <th>Booking Dt</th>
                                        <th>Art</th>
                                        <th>Source</th>
                                        <th>Consignor</th>
                                        <th>Consignee</th>
                                        <th>Topay</th>
                                        <th>Paid</th>
                                        <th>FOV</th>
                                        <th>Goods Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let mainSrcLrDetailsPopupList of mainSrcLrDetailsDataPopupList let i = index ">
                                        <td>{{ mainSrcLrDetailsPopupList.lrNumber }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.bookingDateStr }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.totalArticles }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.bookedFrom }}</td>
                                        <td>{{ mainSrcLrDetailsPopupList.consignorName }}</td>
                                        <td>{{ mainSrcLrDetailsPopupList.consigneeName }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.toPay }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.paid }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.riskCharge }}</td>
                                        <td>{{mainSrcLrDetailsPopupList.description }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>