import { LRDto } from "./LR-dto";

export class RateMasterDto {
  commodityName: string;
  percentage: number;
  fromDate: Date;
  toDate: Date;
  id: number;
  consigneeName: string;
  memoNumber: string;
  gcNoteNumber: string;
  demurrage: string;
  inRupees: string;
  consignor: string;
  consignee: string;
  invoiceNumber: string;
  collectionMan: string;
  area: string;
  godown: string;
  column1: string;
  column2: string;
  column3: string;
  description: string;
  enteredBy: string;
  fixedBy: string;
  fromLocation: string;
  lastModifiedBy: string;
  toLocation: string;
  unit: string;
  hamaliDestination: string;
  hamaliSource: string;
  deliveryType: string;
  perUnit: string;
  status: string;
  source: string;
  destination: string;
  cashMemoType: string;
  userName: string;
  station: string;
  financialYear: string;
  memoName: string;
  totalArticles: number;
  weight: number;
  lorryFreight: number;
  localCharge: number;
  hamaligd: number;
  receiptCharges: number;
  serviceCharges: number;
  less: number;
  totalRupees: number;
  serviceTax: number;
  grandTotal: number;
  paid: number;
  column4: number;
  column5: number;
  gcCharge: number;
  hamaliDD: number;
  discount: number;
  subTotal: number;
  serviceTaxAmt: number;
  rate: number;
  invoiceDate: Date;
  bookingDate: Date;
  effectiveFromDate: Date;
  effectiveToDate: Date;
  enteredDate: Date;
  lastModifiedDate: Date;
  fixedDate: Date;
  capacity: number;
  actual: number;
  bulkyAndSemi: number;
  mode: string;
  address: string;
  pincode: number;
  teleNum: number;
  city: string;
  faxNum: number;
  emailId: string;
  amountLimit: number;
  srdPerc: number;
  hamaliPerc: number;
  deliveryMode: string;
  //    HashMap<String, Double> hashMap;
  hamali: number;
  tdsChg: number;
  memoAmt: number;
  servTaxExemp: boolean;
  crossingChg: number;
  unloadHamaliChg: number;
  unitCrossChg: string;
  unitUnloadHamaliChg: string;
  mainStation: string;
  subStation: string;
  size: number;
  waitingHrs: number;
  memoDate: Date;
  agentName: string;
  subCommodity: string;
  confirm: string;
  commodityType: string;
  selectedCommodity: string;
  commodityCode: string;
  //    HashMap<String, RateMasterDto> hashMapRate;
  consigneeId: number;
  consignorId: number;
  commission: number;
  tempoFrgt: number;
  bc: number;
  lc: number;
  unitComm: string;
  unitTempoFrgt: string;
  unitHamali: string;
  unitBC: string;
  unitLC: string;
  duration: string;
  stmtDate: Date;
  stmtId: string;
  totalLrs: number;
  totalActWgt: number;
  totalChgWgt: number;
  totalPaid: number;
  totalToPay: number;
  totalAmount: number;
  multipleAdd: string;
  multipleDed: string;
  createdDate: Date;
  completedDate: Date;
  createdBy: string;
  toBePaidAmt: number;
  credit: number;
  debit: number;
  creditTotal: number;
  debitTotal: number;
  fromPeriodStr: string;
  toPeriodStr: string;
  paymentType: string;
  bankName: string;
  chequeNumber: string;
  chequeDate: Date;
  amtPaidDate: Date;
  sourceCode: string;
  //    List<String> list;
  mnthAmt: number;
  mnthAmtUnit: string;
  multipleComms: string;
  multipleCommsUnit: string;
  multipleCommsCommd: string;
  multipleLdgHamComms: string;
  multipleLdgHamUnit: string;
  multipleLdgHamCommd: string;
  multipleUnldgHamComms: string;
  multipleUnldgHamUnit: string;
  multipleUnldgHamCommd: string;
  loaderHead: string;
  tdsOnLdg: number;
  demurrageAmt: number;
  commidtyForRpt: string;
  rptMode: string;
  rateSrcStation: string;
  rateSrcHamali: number;
  rateCommodity: string;
  rateDestHamali: number;
  rateUnit: string;
  rateServiceChg: number;
  ratePerUnit: string;
  rateReceiptChg: number;
  rateGcChg: number;
  rateDiscountOn: string;
  cheatCode: string;
  inPrint: boolean;
  //    List<Integer> listOfConsigneId;
  incentiveRate: number;
  incentiveUnit: string;
  incentiveDays: number;
  penaltyRate: number;
  penaltyUnit: string;
  penaltyDays: number;
  isIncentive: boolean;
  inPenalty: boolean;
  incentiveRange: string;
  penaltyRange: string;
  fromRange: string;
  toRange: string;
  maxChgWgt: number;
  maxActWgt: number;
  ratePerChgWgt: number;
  memoTime: string;
  password: string;
  roundOffAmt: number;
  isMultiArtSize: boolean;
  LcChg: boolean;
  BcChg: boolean;
  AOC: boolean;
  InsChg: boolean;
  DD: boolean;
  isFixedRate: boolean;
  rptModeId: number;
  allEmailId: string;
  inRemindSms: boolean;
  inStockSms: boolean;
  fromDateStg: string;
  toDateStg: string;
  inLaserCashMemo: boolean;
  duplicateMemo: boolean;
  transitMemoPrt: boolean;
  unloadingChg: number;
  withLetterHead: boolean;
  consignorTinNo: string;
  imgURL: string;
  imgUploaded: boolean;
  receiveDate: Date;
  effectiveFromDateStg: string;
  effectiveToDateSrg: string;
  consigneeTinNo: string;
  count: number;
  rateType: string;
  telephone: string;
  fax: string;
  destHamali: number;
  destHamaliPerArt: number;
  truckCapacity: number;
  reason: any;
  isAutoLr: boolean;
  deliveryPendingStockSms: boolean;
  barCode: string;
  validMemoDate: boolean;
  HamaliChg: boolean;
  prevUnit: string;
  prevSourceHamali: string;
  prevDestHamali: string;
  prevRate: number;
  prevReceiptCharges: number;
  prevServiceCharges: number;
  prevGcCharges: number;
  prevPerUnit: string;
  prevCommodity: string;
  prevEffectiveFromDate: Date;
  prevEffectiveToDate: Date;
  prevIsRatefix: boolean;
  IGST: number;
  IGSTAmt: number;
  CGST: number;
  CGSTAmt: number;
  SGST: number;
  SGSTAmt: number;
  //the below is add for not getting value in Controller it is used in Cashmemo Generation 
  igstamt: number
  cgstamt: number;
  sgstamt: number;
  igst: number;
  cgst: number;
  sgst: number
  consigneeState: string;
  consigneeStateCode: string;
  consignorState: string;
  consignorStateCode: string;
  gstConsignee: string;
  gstConsgnor: string;
  consignorAddress: string;
  gstAmtTotal: number;
  consigneeAddress: string;
  stationCode: string;
  consigneeMobileNo: string;
  consignorMobileNo: string;
  groupBy: string;
  riskCharge: boolean;
  fovPerc: number;
  consignorName: string;
  eWayBillNo: string;
  goodsValue: number;
  tempoPenaltyApplicable: boolean;
  hamaliValidation: string;
  tripsheetHamali: number;
  paymentMode: string;
  subStationGDChgUnit: string;
  subStationGDChg: number;
  ddMinAmtOnLr: number;
  ddMinPercOnLr: number;
  isDDFlowApplicableOnLr: boolean;
  companyId: string;
  searchType: string;
  selectedCommodityType: String;
  withSlash: string;
  withOutSlash: string;
  gujDiac: boolean;
  otherChg: boolean;
  otherChgAmt: number;
  lastModifiedDateStr: string;
  memoDateStr: Date;
  from: number;
  to: number;
  comm: number;
  list: Array<String>;
  lcChgStr: string;
  bcChgStr: string;
  aocChgStr: string;
  ddChgStr: string;
  otherChgStr: string;
  shamaliChgStr: string;
  riskChargeStr: string;
  perDayArt: number;
  perDayActWgt: number;
  perDayChgWgt: number;
  //Basha
  //04062021
  rateMasterDtos: RateMasterDto[];
  editValiateMode: string;
  listOfConsigneId: Array<Number>;
  rateMasterDtos1: RateMasterDto[];
  listOfRateIds: Array<Number>;
  fovOn: String;
  rateOnActWgt: number;

  unloadingChgDd: number;
  unitDd: string;
  unloadingChgGd: number;
  unitGd: string;
  lrDtos: LRDto[];
  unldgCommodities: string;
  unldgChg: string;
  unldgUnit: string;
  memoDirectAssg: boolean;
  riskChargeAmt: number;
  basicCharge: number;
  aocChargeAmt: number;
  rateFor: string;
  //sub
  subStationRate: boolean;
  basicChargeSub: number;
  hamaliSub: number;
  gcChargeSub: number;
  aocChargeAmtSub: number;
  otherChgAmtSub: number;
  riskChargeAmtSub: number;
  receiptChargesSub: number;
  serviceChargesSub: number;
  destHamaliSub: number;
  fixedBySub: string;
  effectiveFromDateSub: Date;
  effectiveToDateSub: Date;
  fixedDateSub: Date;
  effectiveFromDateSubStg: string;
  effectiveToDateSubSrg: string;
  ddChargeAmt: number;
  ddChargeAmtSub: number;
  rateAtSrcCheck: boolean;
  srcHamaliChg: number;
  hamaliDDRateLessStatus: boolean;
  ddHamaliLess: number;
  chargedWgt: number;
  consigneeIdTemp: number;
  unloadingChgUnit: string;
  unloadingChgUnitGd: string;
  otherChgUnit: string;
  otherChgDestMemoCneeAutoId: number;
  cneeRateMasterAutoId: number;
  indexValue: string;
  isOfferLR: boolean;
  serviceChargesUnit: string;
  vehicleNo: string;
  hamaliSrcCustom: boolean;
  customHamaliSrcChg: number;
  consolidatedEWayBillNo: string;
  isNewParty: boolean;
  isExpired: boolean;
  otherChgArea: string;
  listOfSource: Array<String>;
  listOfCommodity: Array<String>;
  perKgRangeLimit: number;
  length: number;
  saveOrEdit: string;
}