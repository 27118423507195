<!--First Main Table Start-->
<div class="page row system_responsive" id="{{pageId}}hireslipPrint">
    <div class="col-md-12" style='margin-top: 0px;'>

        <div>
            <table *ngIf="hideShowBarcode" width='100%' border='0' cellspacing='0' cellpadding='0'>
                <tr>
                    <td width='50%' valign='top' align='left'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                        <br> <span>{{address}} </span>
                    </td>
                    <td width='50%' valign='top' align='right' *ngFor="let PS of printBarcode">
                        <!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13" [bc-value]="PS.barcode"
							[bc-display-value]="false">
						</ngx-barcode> -->
                        <ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="false">
                        </ngx-barcode6>
                    </td>
                </tr>
            </table>

            <table *ngIf="!hideShowBarcode" width='100%' border='0' cellspacing='0' cellpadding='0'>
                <tr>
                    <td width='50%' valign='top' align='left'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                        <br> <span>{{address}} </span>
                    </td>
                    <td width='50%' valign='top' align='right'></td>
                </tr>
            </table>

        </div>
        <!--First Main Table End-->
        <!--Second Main Table Start-->
        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> From </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{fromStation}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To
										</strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{toStation}}
										</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Hireslip No </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{hireslipNumber}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='65%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invoice
											No </strong>
                                    </td>
                                    <td width='35%' valign='top' class='fontStyle' align='left'>
                                        <span>
											{{invoiceNumber}}
										</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Eway Bill </strong>
                                    </td>
                                    <td width='70' valign='top' class='fontStyle' align='left'>
                                        <span> {{ewayBillNo}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date
										</strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{invoiceDateStr}}
										</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!--Second Main Table End-->
        <!--Third Main Table Start-->
        <br>
        <hr>
        <br> <u><b> The Under Mentioned Goods Have Been Loaded In
			</b></u> <br>
        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Lorry No </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{lorryNumber}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PAN
											No </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{panNo}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Lorry Owner </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{lorryOwnerName}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lorry
											Type </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{lorryType}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Address </strong>
                                    </td>
                                    <td width='70%' valign='top' style='word-wrap: break-word;' class='fontStyle' align='left'><span>
											{{lorry_address}}</span></td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Truck
											Length </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{truckLength}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!--Third Main Table End-->

        <!--Four Main Table Start-->
        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; margin-top: 7px;'>
                    <tr>
                        <td width='25%' align='center' class='fontSizeHeaderTable' valign='top' type='text'><strong>
								Total No. of GC </strong>
                        </td>
                        <td width='25%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong>
								Article as per
								Invoice </strong></td>
                        <td width='25%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong>
								Gurantee Weight
							</strong></td>
                        <td width='25%' align='center' class='fontSizeHeaderTable' style='border-right: 1px solid #000;' valign='top' type='text'><strong>
								Kanta Weight </strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center' class='fontSizeBodyTable' valign='top' type='text'><strong class='fontStyle'>
								{{totalLrs }} </strong></td>
                        <td width='25%' align='center' class='fontSizeBodyTable' valign='top' type='number'><strong class='fontStyle'>
								{{totalArticles}} </strong></td>
                        <td width='25%' align='center' class='fontSizeBodyTable' valign='top' type='number'><strong class='fontStyle'>
								{{guranteeWgt}} </strong></td>
                        <td width='25%' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><strong class='fontStyle'> {{kantaWgt}} </strong></td>
                    </tr>
                </table>
                <br>
                <hr>
            </div>
        </div>
        <!--Four Main Table End-->

        <!--Five Main Table Start-->
        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Broker Name </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{brokerName}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PAN
											No </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{brokerPanNo}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <hr>
            </div>
        </div>
        <br>
        <!--Five Main Table End-->

        <!--Six Main Table Start-->
        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='35%' valign='top' class='fontStyle' align='left'>
                                        <strong> Company Name </strong>
                                    </td>
                                    <td width='65%' valign='top' class='fontStyle' align='left'>
                                        <span> {{companyName}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total
											Hire </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{totalHire}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advance
										</strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{advanceAmt}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                    <td width='70%' valign='top' style='word-wrap: break-word;' class='fontStyle' align='left'></td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance
										</strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{balanceAmt}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='35%' valign='top' class='fontStyle' align='left'>
                                        <strong> To Pay </strong>
                                    </td>
                                    <td width='65%' valign='top' class='fontStyle' align='left'>
                                        <span>{{toPay}}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payable
											At </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{payableAt}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <hr>
            </div>
        </div>
        <br>
        <!--Six Main Table End-->

        <!--Seven Main Table Start-->

        <div class="row">
            <div class="col-md-12">
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Driver Name </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{driverName}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Driver
											Licence </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span> {{driverLicNo}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Phone No </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{driverPhoneNo}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <hr>
            </div>
        </div>
        <br>
        <!--Seven Main Table End-->

        <!--Eight Main Table Start-->
        <div class="row">
            <div class="col-md-12">

                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong> Departure Date </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> &nbsp;&nbsp;{{departureDateStr}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='60%' valign='top' class='fontStyle' align='left'>
                                        <strong>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Departure
											Time </strong>
                                    </td>
                                    <td width='40%' valign='top' class='fontStyle' align='left'>
                                        <span>{{departureTime}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                        <strong>Remarks </strong>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                        <span> {{remarks}} </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td width='4%' valign='top'></td>
                        <td width='48%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                                <tr>
                                    <td width='30%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                    <td width='70%' valign='top' class='fontStyle' align='left'>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <!--<tr>
								<td width='100%' valign='top'>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'style='margin-top: 7px;'>
											  <tr>
												  <td width='100%' valign='top'class='fontStyle'align='left'>
												   <strong *ngIf="hideShowForDoorCharge">Door Charge:{{doorCharge}},</strong>
												   
												   <strong *ngIf="hideShowForVehicleHeight">Vehicle Height:{{vehicleHeight}},</strong>
												  
												   <strong *ngIf="hideShowForDoorDelivery">Door Delivery:{{doorDelivery}},</strong>
												  
												   <strong *ngIf="hideShowForExtraWeight">Extra weight(Kg):{{extraWeight}},</strong>
												   
												   <strong *ngIf="hideShowForCheckPost">Check Post:{{checkPost}}</strong>
												   
												   <strong *ngIf="hideShowForOtherCharges">Other Charges:{{otherCharges}}</strong>
												  </td>
											  </tr>
										</table>
									</td>
							</tr>-->
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;'>
                        <tr>
                            <td width='100%' class='fontStyle'><strong *ngIf="hideShowForDoorCharge">Door
									Charge:{{doorCharge}},</strong> <strong *ngIf="hideShowForVehicleHeight">Vehicle
									Height:{{vehicleHeight}},</strong> <strong *ngIf="hideShowForDoorDelivery">Door
									Delivery:{{doorDelivery}},</strong> <strong *ngIf="hideShowForExtraWeight">Extra
									weight(Kg):{{extraWeight}},</strong> <strong *ngIf="hideShowForCheckPost">Check
									Post:{{checkPost}}</strong> <strong *ngIf="hideShowForOtherCharges">Other
									Charges:{{otherCharges}}</strong></td>
                        </tr>
                    </table>
                </table>
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;' *ngIf="hideShowLRsExtraTable">
                    <tr>
                        <td height='25' class='fontStyle'><strong><u>LR's
									Extra</u> </strong></td>
                    </tr>
                    <tr>
                        <td height='25'><span>{{validateLrShortage}}</span></td>
                    </tr>
                </table>

                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 7px;' *ngIf="hideShowCODLR">
                    <tr>
                        <td height='25' class='fontStyle'><strong><u>COD LR</u> </strong></td>									
                    </tr>
                    <tr>
                        <td height='25'><span>{{CODLrNumbers}}</span></td>
                    </tr>
                </table>

                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 5px;'>
                    <tr>
                        <td width='48%' *ngIf="hideShowForRewardTable">
                            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                                <tr>
                                    <td colspan='3' class='fontSizeHeaderTable' style='border-right: 1px solid #000; border-bottom: none !important;'>
                                        <strong class='fontsizetable'>Reward Amount Details</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='33%' align='center' class='fontSizeHeaderTable' valign='top' type='text'>
                                        <strong> From </strong></td>
                                    <td width='33%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong class='fontsizetable'>
											To </strong></td>
                                    <td width='34%' align='center' class='fontSizeHeaderTable' style='border-right: 1px solid #000;' valign='top' type='text'><strong>
											Amount </strong></td>
                                </tr>
                                <tr>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='text'>
                                        <span class='fontStyle'>
											{{fromTime1}} </span></td>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span class='fontStyle'>
											{{toTime1}} </span></td>
                                    <td width='34%' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><span class='fontStyle'> {{rewardingAmount1}} </span></td>
                                </tr>
                                <tr>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='text'>
                                        <span class='fontStyle'>
											{{fromTime2}} </span></td>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span class='fontStyle'>
											{{toTime2}} </span></td>
                                    <td width='34%' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><span class='fontStyle'> {{rewardingAmount2}} </span></td>
                                </tr>
                                <tr>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='text'>
                                        <span class='fontStyle'>
											{{toTime2}} </span></td>
                                    <td width='33%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span class='fontStyle'>
											and above </span></td>
                                    <td width='34%' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><span class='fontStyle'> 0 </span></td>
                                </tr>
                            </table>
                        </td>
                        <td width='2%'></td>
                        <td width='48%' *ngIf="hideShowForLRShortageTable">
                            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                                <tr>
                                    <td colspan='7' class='fontSizeHeaderTable' style='border-right: 1px solid #000; border-bottom: none !important;'>
                                        <strong class='fontsizetable'>LR Shortage Details</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='15%' align='center' class='fontSizeHeaderTable' valign='top' type='text'>
                                        <strong> LR No </strong></td>
                                    <td width='14%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong> Art </strong></td>
                                    <td width='14%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong> Extra </strong></td>
                                    <td width='14%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong> Open </strong></td>
                                    <td width='14%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong> Short </strong></td>
                                    <td width='14%' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong> Wet </strong></td>
                                    <td width='15%' align='center' class='fontSizeHeaderTable' style='border-right: 1px solid #000;' valign='top' type='text'><strong>
											Remarks </strong></td>
                                </tr>
                                <!--<tr>-->
                                <tr *ngFor="let listDataLrsShortage of datatableForLrsShortage">
                                    <td width='15%' align='center' class='fontSizeBodyTable' valign='top' type='text'>
                                        <span>
											{{listDataLrsShortage.lrNumber}} </span></td>
                                    <td width='14%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span>
											{{listDataLrsShortage.totalArticles}} </span></td>
                                    <td width='14%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span>
											{{listDataLrsShortage.excessGoods}} </span></td>
                                    <td width='14%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span>
											{{listDataLrsShortage.openConditionGoods}} </span></td>
                                    <td width='14%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span>
											{{listDataLrsShortage.shortGoods}} </span></td>
                                    <td width='14%' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span>
											{{listDataLrsShortage.wetConditionGoods}} </span></td>
                                    <td width='15%' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><span>
											{{listDataLrsShortage.remarks}} </span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

                <!--for COD lrs-->
                <table width='100%' border='0' cellspacing='0' cellpadding='0' style='margin-top: 5px;'>
                    <tr>
                        <td width='60%' *ngIf="hideShowForCodLrsTable">
                            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                                <tr>
                                    <td colspan='3' class='fontSizeHeaderTable' style='border-right: 1px solid #000; border-bottom: none !important;'>
                                        <strong class='fontsizetable'>COD LR Details</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td style='width:30% !important' align='center' class='fontSizeHeaderTable' valign='top' type='text'>
                                        <strong> LR Number </strong></td>
                                    <td style='width:15% !important' align='center' class='fontSizeHeaderTable' valign='top' type='number'><strong class='fontsizetable'>
											Art </strong></td>
                                    <td style='width:55% !important' align='center' class='fontSizeHeaderTable' style='border-right: 1px solid #000;' valign='top' type='text'><strong>
											Consignee Name </strong></td>
                                </tr>
                                <tr *ngFor="let listOfCodLrDtoData of listOfCodLrDto.lrDtos">
                                    <td style='width:30% !important' align='center' class='fontSizeBodyTable' valign='top' type='text'>
                                        <span class='fontStyle'>
											{{listOfCodLrDtoData.lrNumber}} </span></td>
                                    <td style='width:15% !important' align='center' class='fontSizeBodyTable' valign='top' type='number'>
                                        <span class='fontStyle'>
											{{listOfCodLrDtoData.totalArticles}} </span></td>
                                    <td style='width:55% !important' align='center' class='fontSizeBodyTable' style='border-right: 1px solid #000;' valign='top' type='text'><span class='fontStyle'> {{listOfCodLrDtoData.consigneeName}} </span></td>
                                </tr>
                            </table>
                        </td>
                        <td width='40%'></td>
                    </tr>
                </table>
            </div>
        </div>
        <!--Eight Main Table End-->
    </div>
</div>