<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<!-- <div class="col-md-12 col-sm-12">
	<i title="Reload" class="fas fa-sync-alt fa xs cli"
		style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood; cursor: pointer;"
		(click)="getDetails()"></i>
</div> -->
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Pending Consignee
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}pendingConsigneeTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsPendingConsignee"
			[dtTrigger]="dtTriggerPendingConsignee">

			<thead>
				<tr>
					<th>Consignee Name</th>
					<th>GST No</th>
					<th>Update</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let pendingConsigneeData of pendingConsigneeDataList ">
					<td>{{ pendingConsigneeData.consigneeName }}</td>
					<td>{{ pendingConsigneeData.gstNoConsignee }}</td>
					<td><i title="Edit" (click)="editDialog()"
						style="cursor: pointer;" class="fas fa-pencil-alt"></i></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
