<div class="page row system_responsive" id="{{pageId}}stmtDtlsPrint_LR">
	<div class="col-md-12">
		<!--first row starts-->
		<!-- 		<div class="row" style="padding-top: 5px;" height='30px'> -->
		<!-- 			<div class="col-md-4"> -->
		<!-- 				<h6 style='font-size: 17px;'> -->
		<!-- 					No.32, Plain Street, Hospital Road, Tasker Town, Bangalore - 560 001 -->
		<!-- 				</h6> -->
		<!-- 			</div> -->
		<!-- 			<div class="col-md-4"> -->
		<!-- 				<h6 style='font-size: 17px;'> -->
		<!-- 					Tel.: (+91-80) 22865959 <br> -->
		<!-- 					Fax.: (+91-80) 22865950 <br> -->
		<!-- 					Email: bng@srdlogistics.com -->
		<!-- 				</h6> -->
		<!-- 			</div> -->
		<!-- 			<div class="col-md-4"> -->
		<!-- 				<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo"> -->
		<!-- 			</div> -->
		<!-- 		</div> -->
		<div class="row" style="padding-top: 0px;" height='30px'>
			<div class="col-md-4">
				<h6 style='font-size: 18px;'>No.131, 2nd Floor, Infantry Road,
					Bengaluru - 560 001</h6>
			</div>
			<div class="col-md-4">
				<h6 style='font-size: 18px;'>
					Tel.: (+91-80) 22865959 <br> Fax.: (+91-80) 22865950 <br>
					Email: bng@srdlogistics.com
				</h6>
			</div>
			<div class="col-md-4">
				<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
			</div>
		</div>
		<!--<br>-->
		<hr>
		<!--first row starts-->
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6
								style='margin-right: 20px; font-size: 20px; padding-top: 5px; text-align: center'>
								<strong> Statement Report </strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 20px; padding-top: 2px;'>
								<span style='font-weight: bold; font-size: 16px;'>Statement
									of Account : </span>
								<!--<strong>{{todayDate}}</strong>-->
								<span style='font-weight: bold; font-size: 16px;'>{{destination}}</span>
							</h6>
							<h6 style='margin-right: 20px; padding-top: 2px;'>
								<span style='font-weight: bold; font-size: 16px;'>Period
									: </span> <span style='font-size: 16px;'>{{fromPeriod}} To
									{{toPeriod}}</span>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6
								style='margin-right: 20px; padding-top: 2px; text-align: right;'>
								<span style='font-weight: bold; font-size: 16px;'>Statement
									No : </span> <span style='font-size: 16px;'>{{stmtNo}}</span>
							</h6>
							<h6
								style='margin-right: 20px; padding-top: 2px; text-align: right;'>
								<span style='font-weight: bold; font-size: 16px;'>Statement
									Date : </span>
								<!--<strong>{{date}}</strong>-->
								<span style='font-size: 16px;'>{{stmtDateStr}}</span>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
		<table width='100%' border='0' cellspacing='0' cellpadding='1'
			style='table-layout: fixed;'>
			<tr>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-left: 1px solid black; border-top: 1px solid black;'
					valign='top' type='text'><span>From</span></td>
				<td width='45%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-top: 1px solid black;'
					valign='top' type='number'><span> Invoice Nos</span></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-top: 1px solid black;'
					valign='top' type='number'><span> Tot Wgt</span></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-top: 1px solid black;'
					valign='top' type='number'><span> Tot Amt</span></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-top: 1px solid black;'
					valign='top' type='text'><span> Tot Paid</span></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 18px; border-top: 1px solid black;'
					valign='top' type='text'><span>Ser Tax</span></td>
			</tr>
			<tr *ngFor="let item of finalResult">
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span> {{item.branch}} </span></td>
				<td width='45%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px;'
					valign='top' type='number'><span>{{item.invoiceNumber}}</span></td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px;'
					valign='top' type='number'><span>{{item.totalActWgt}}
				</span></td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px;'
					valign='top' type='text'><span>{{item.totalAmount}} </span></td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px;'
					valign='top' type='text'><span> {{item.totalPaid}} </span></td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 17px;'
					valign='top' type='text'><span>{{item.serviceTaxAdd}} </span></td>
			</tr>
			<tr>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span>Total</span></td>
				<td width='45%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='number'><span> LRs :
						{{summaryTotalLrs}}</span></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='number'><span>{{summaryTotalActWgt}}</span></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='number'><span>{{summaryTotalAmt}}</span></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='text'><span>{{summaryTotalPaid}} </span></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='text'><span>{{summaryTotalServTax}}
				</span></td>
			</tr>
			<tr>
				<td width='100%' align='center' colspan='6'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span>Amount Details</span></td>
			</tr>
			<tr>
				<td align='center' colspan='2'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span>Description</span></td>
				<td align='center' colspan='2'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='number'><span>Credit </span></td>
				<td align='center' colspan='2'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-weight: bold; font-size: 17px;'
					valign='top' type='number'><span>Debit</span></td>
			</tr>
			<tr>
				<td align='left' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-weight: bold; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span style='margin-left: 10px;'><u>Addition:</u></span></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;'
					valign='top' type='number'></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;'
					valign='top' type='number'></td>
			</tr>

			<tr *ngFor="let additionData of additionDataDatailList"
				style='line-height: 30px;'>
				<td align='left' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-size: 17px; border-left: 1px solid black; font-weight: 600;'
					valign='top' type='text'><span style='margin-left: 30px;'>{{additionData.description}}&nbsp;</span>
				</td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: 600;'
					valign='top' type='number'><span style='margin-left: 30px;'>{{additionData.totalAmount}}&nbsp;</span></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;'
					valign='top' type='number'><strong></strong></td>
			</tr>
			<tr>
				<td align='left' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-weight: bold; font-size: 17px; border-left: 1px solid black;'
					valign='top' type='text'><span style='margin-left: 10px;'><u>Deduction:</u></span></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;'
					valign='top' type='number'><strong> </strong></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;'
					valign='top' type='number'><strong> </strong></td>
			</tr>
			<tr *ngFor="let deductionData of deductionDataDetailList"
				style='line-height: 30px;'>
				<td align='left' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-size: 17px; border-left: 1px solid black; font-weight: 600;'
					valign='top' type='text'><span style='margin-left: 30px;'>{{deductionData.description}}&nbsp;</span>
				</td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'></td>
				<td align='right' colspan='2'
					style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: 600;'
					valign='top' type='number'><span style='margin-left: 30px;'>{{deductionData.totalAmount}}&nbsp;</span></td>
			</tr>
			<tr>
				<td align='left' colspan='2'
					style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word; font-size: 17px; font-weight: bold; border-left: 1px solid black;'
					valign='top' type='text'><span style='margin-left: 30px;'>Total&nbsp;</span>
				</td>
				<td align='right'
					style='border-right: none; border-bottom: 1px solid black;; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span>( A )&nbsp;</span></td>
				<td align='right'
					style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word; font-size: 17px; font-weight: 600;'
					valign='top' type='number'><span>{{summaryAdditionTotAmt}}&nbsp;</span>
				</td>
				<td align='right'
					style='border-right: none; border-bottom: 1px solid black;; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span>( B )&nbsp;</span></td>
				<td align='right'
					style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word; font-size: 17px; font-weight: 600;'
					valign='top' type='number'><span>{{summaryDeductionTotAmt}}&nbsp;</span>
				</td>
			</tr>
			<tr>
				<td align='left' colspan='2'
					style='border-right: none; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold; border-left: none'
					valign='top' type='text'><span>Total Amount to be
						Received (A - B)&nbsp;</span></td>
				<td align='right' colspan='2'
					style='border-right: none; border-bottom: 1px solid black; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span>{{summaryAdditionTotAmt
						- summaryDeductionTotAmt}}&nbsp;</span></td>
				<td align='right' colspan='2'
					style='border-right: none; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span></span></td>
			</tr>
			<br>
			<tr>
				<td align='left' colspan='2'
					style='border-right: none; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold; border-left: none'
					valign='top' type='text'><span></span></td>
				<td align='right' colspan='2'
					style='border-right: none; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span></span></td>
				<td align='right' colspan='2'
					style='border-right: none; border-bottom: none; word-wrap: break-word; font-size: 17px; font-weight: bold;'
					valign='top' type='number'><span>For SRD Logistics
						Pvt.Ltd.&nbsp;&nbsp;</span></td>
			</tr>
		</table>
	</div>
</div>