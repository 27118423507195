import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
//service starts
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
//service ends
@Component( {
    selector: 'app-to-be-billed-party',
    templateUrl: './to-be-billed-party.component.html',
    styleUrls: ['./to-be-billed-party.component.css']
} )
export class ToBeBilledPartyComponent implements OnInit {

    loadingIndicator = true;
    viewTypeDateWise = true;
    viewTypePartyWise = false;

    isLoggedIn = true;
    //    userdata get through from login starts
    userDataDtoReturnSession: any;
    srcLists: any;


    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends
    //service starts      
    lrDto: LRDto = new LRDto();
    //    service ends
    getDataToBeBilledPartyRptFrTable: any;

    toBeBilledPartyRptDataList: any;


    onDestroyUnsubscribtionToBeBilledPartyRpt: Subscription;


    controlMainSource = new FormControl();
    controlConsingorIndex = new FormControl();
    controlConsignorName = new FormControl();

    partyDto: PartyMasterDto = new PartyMasterDto();
    partyDtoAll: PartyMasterDto = new PartyMasterDto();
    partyDetailsOptionsGet: PartyMasterDto[];


    lrDtoSourceAll: LRDto = new LRDto();
    lrDtosSourceOptionsGet: LRDto[];
    srcForRpt = "Billed Rpt Show Src All";
    isLocalAgent = false;
    mainAdminStation: string;
    isAgentAll: string;
    consignorIndexOptions: Array<any> = ['Select Consignor Index', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'ALL'];
    consignorIndexOptionsGet: Array<any> = [];
    sourceList: Array<any> = [];
    selectedAgentName: any;
    showGenerateMemo = false;
    selectedLrNumber: any;
    lrDtoBkgSrc: LRDto = new LRDto();
    srcList: LRDto = new LRDto;
    lrDtoBkgSrcAll: LRDto = new LRDto();
    pageTitle = "TO BE BILLED LRs REPORT";

    indexVal: any;
    gstConsignor: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedSource: any;
    selectedSearchType: any;


    selectedConsignorName: any;
    selectedConsignorIndex: any;

    viewTypeSourceWise = false;

    selectedFromDatePrint: any;
    selectedToDatePrint: any;
    todayDate: string;
    datasToExport: string;
    dateWeNeed: string;



    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerToBeBilledPartyRpt: Subject<any> = new Subject();


    dataTable: any;
    dtOptionsToBeBilledPartyRpt: any;
	pageId="tbpc";
    constructor( private toBeBilledReportScreen: ReportService,
        //          service starts
        private http: HttpClient,
        //            service ends
        private router: Router,

        private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef
    ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
        }
        if ( this.isLoggedIn ) {
            //          userdata get through from login starts

            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.mainAdminStation = this.userDataDtoReturnSession.mainAdminStation;
            this.getConsignorIndexvalue();
            this.getConsignorInfo();
            this.toBeBilledReportMethod();

            if ( this.srcForRpt == "Billed Rpt Show Src All" ) {
                this.viewTypeSourceWise = true;
                this.getBookingOfficeDetailsList();
            }
            if ( this.srcForRpt == "Billed Rpt Show Src Specific" ) {
                this.viewTypeSourceWise = true;
                //                Source list is showing error
                this.getSourceList();
            }


        }

    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }



    ngOnInit() {
    }
    toBeBilledReportMethod() {
        this.dtOptionsToBeBilledPartyRpt = {

            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    title: this.changeDataTableTitileForPrint(),
                    //                    messageTop: this.chnageDataTableMessageTop(),
                    autoPrint: true,
                    footer: true,

                }, {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: this.changeDataTableTitileForExcel(),
                    messageTop: this.chnageDataTableMessageTopExcel(),
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],


            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function( i ) {
                    return typeof i === 'string' ?
                        +i.replace( /[\$,]/g, '' ) * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                //                count starts
                var columnData = api
                    .column(
                    0 )
                    .data();

                // Update footer
                $( api.column( 0 ).footer() ).html(
                    columnData.count()
                );

                var total = api
                    .column( 1 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b );
                    }, 0 );
                // Update footer
                $( api.column( 1 ).footer() ).html(
                    total
                );

                var total = api
                    .column( 2 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b );
                    }, 0 );
                // Update footer
                $( api.column( 2 ).footer() ).html(
                    total
                );

                var total = api
                    .column( 5 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b );
                    }, 0 );
                // Update footer
                $( api.column( 5 ).footer() ).html(
                    total
                );

//                var total = api
//                    .column( 6 )
//                    .data()
//                    .reduce( function( a, b ) {
//                        return intVal( a ) + intVal( b );
//                    }, 0 );
//                // Update footer
//                $( api.column( 6 ).footer() ).html(
//                    total
//                );
                //                counts ends
            }

        }
    }
    ngOnDestroy(): void {
        this.dtTriggerToBeBilledPartyRpt.unsubscribe();
    }

    viewTypeMode( viewType: string ) {
        if ( viewType === 'dateWise' ) {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = false;
        } else if ( viewType === 'partyWise' ) {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = true;
        } else {
            this.viewTypeDateWise = false;
            this.viewTypePartyWise = false;
        }
    }

    //FromStation
    getBookingOfficeDetails() {
        this.lrDtoBkgSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBkgSrc.mode = "All";
    }
    getBookingOfficeDetailsList = () => {
        this.getBookingOfficeDetails();
        this.masterReadService.getSubBookingStationDetailsService( this.lrDtoBkgSrc ).subscribe(
            ( response ) => {
                if ( response.length == 0 ) {
                    swal( {
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                } else {
                    this.lrDtosSourceOptionsGet = response;
                    this.lrDtoBkgSrcAll.subStations = "ALL";
                    this.lrDtosSourceOptionsGet.push( this.lrDtoBkgSrcAll );
                    this.srcLists = response;
                    //                    this.srclist is pending 
                }
                this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };
    //From Station
    getSourceList() {
        //        this.srcList="";
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStations = this.srcLists;
        for ( let i = 0; i < this.srcList.subStations.length; i++ ) {
            this.lrDtosSourceOptionsGet = this.srcLists;
            this.lrDtoBkgSrcAll.subStations = "ALL";
            this.lrDtosSourceOptionsGet.push( this.lrDtoBkgSrcAll );
        }
        //       

    }


    ///get Consignor Index

    getConsignorIndexvalue() {
        for ( let i = 0; i < this.consignorIndexOptions.length; i++ ) {
            this.consignorIndexOptionsGet.push( { label: this.consignorIndexOptions[i], value: i } );
        }
    }
    ////Get Consignor Name 
    getConsignorInfo() {
        this.partyDto.companyId = this.userDataDtoReturnSession.companyId;
        //        this.indexVal = $("#"+this.pageId+"consignorIndex" ).val();
        //        this.indexVal = "Select Consignor Index";
        this.indexVal = "A";
        //        this.gstConsignor = $("#"+this.pageId+"consignorGstNumber" ).val();
        //        this.gstConsignor ="06AAACH8287K1Z5";

        this.partyDto.indexValue = this.indexVal;
        this.partyDto.gstNoConsignor = this.gstConsignor;

        if ( ( this.indexVal != null )
            && ( this.indexVal == "Select Consignor Index" ) ) {
            if ( this.gstConsignor != null ) {
                this.partyDto.mode = "LREntry";
                this.getConsignorDetailsList();
            }
        } else if ( ( this.indexVal != null )
            && ( this.indexVal == "ALL" ) ) {
            if ( this.gstConsignor != null ) {
                this.partyDto.mode = "LREntry";
            } else {
                this.partyDto.mode = "Index";
                this.partyDto.indexValue = "";
            }
            if ( this.partyDto.mode != null ) {
                this.getConsignorDetailsList();
            }
        } else {
            if ( this.gstConsignor != null ) {
                this.partyDto.mode = "IndexTinNo";
            } else {
                this.partyDto.mode = "Index";
            }
            if ( this.partyDto.mode != null ) {
                this.getConsignorDetailsList();
            }
        }
    }
    getConsignorDetailsList = () => {
        this.masterReadService.getConsignorDetails( this.partyDto ).subscribe(
            ( response ) => {
                if ( response.length == 0 ) {
                    swal( {
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                } else {

                    this.partyDetailsOptionsGet = response;
                    this.srcLists = response;

                }
                this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };

    validateBtnSearch() {
        this.getToBeBilledReportDetails();
    }
    getEnteredDetails() {
        /// here am not getting the src list value so its hot coded in this  we have didnt  get consingee Id so it is hot coded 
        //        is used, date is hard code
        this.selectedFromDate = $("#"+this.pageId+"fromDate" ).val();
        this.selectedToDate = $("#"+this.pageId+"toDate" ).val();
        this.selectedFromDatePrint = $("#"+this.pageId+"fromDate" ).val();
        this.selectedToDatePrint = $("#"+this.pageId+"toDate" ).val();

        this.selectedSource = $("#"+this.pageId+"source" ).val();
        //        console.log( this.selectedSource );
        this.selectedConsignorName = $("#"+this.pageId+"consignorName" ).val();
        //        this.sourceList = this.selectedSource;
        //        this.sourceList = ["Budhpur"];
        this.sourceList = ["Budhpur", "Aligarh", "Nanglipoona", "daya basti"];
        //        console.log( this.sourceList );
        this.selectedSearchType = $("#"+this.pageId+"viewType" ).val();
        this.selectedConsignorIndex = $("#"+this.pageId+"consignorIndex" ).val();
        this.lrDto.fromDate = this.selectedFromDate;
        this.lrDto.toDate = this.selectedToDate;

        this.selectedFromDate = '2019-05-01';
        this.selectedToDate = '2019-06-01';

        this.selectedFromDatePrint = '01-01-2020';
        this.selectedToDatePrint = '20-01-2020';
      

        this.lrDto.fromDate = '2019-01-01';
        this.lrDto.toDate = '2019-06-01';
        this.toBeBilledReportMethod();

        if ( this.selectedFromDate != null && this.selectedFromDate != null ) {
            this.lrDto.fromdate = this.selectedFromDate;
            this.lrDto.todate = this.selectedToDate;
            this.lrDto.description = "TO BE BILLED LRs REPORT";
            this.lrDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            if ( this.viewTypeSourceWise == true ) {
                this.lrDto.source = this.selectedSource;
                if ( this.selectedSource != null && this.selectedSource != "" && this.selectedSource != "ALL" ) {
                    this.lrDto.list = this.sourceList;
                } else {
                    this.lrDto.list = this.sourceList;
                }
            } else {
                this.lrDto.source = this.userDataDtoReturnSession.office;
                this.lrDto.list = this.sourceList;
            }
            this.lrDto.mode = "dummy";
            this.lrDto.reportMode = "ToBePaid";

            /// For PartyWise
            if ( this.selectedSearchType == "partyWise" ) {
                if ( this.viewTypePartyWise == true ) {
                    if ( this.selectedConsignorName == null || this.selectedConsignorName == "" || this.selectedConsignorName == "Select Consignor" ) {
                        swal( {
                            title: "Warning",
                            text: "Please Select Consignor Name",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        } );
                    } else {
                        this.lrDto.mode = "PartyWise";
                        if ( this.viewTypePartyWise == true ) {
                            //                            this.lrDto.consignorId = this.selectedConsignorName;
                            this.lrDto.consignorId = 95527;
                        }
                        //                        main.gridClass.gridReconfigure(dto);
                    }

                }
            }

        } else {
            swal( {
                title: "Warning",
                text: "From Date ,To Date & Source Is Mandatory Field",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } );

        }
    }
    getToBeBilledReportDetails = () => {
        this.getEnteredDetails();
        this.toBeBilledReportScreen.getPaidRpt( this.lrDto ).subscribe(
            ( data ) => {
                if ( data == 0 ) {
                    swal( {
                        title: "Warning",
                        text: "No records found for the selected city!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.showGenerateMemo = false;
                } else {
                    this.showGenerateMemo = true;
                    this.toBeBilledPartyRptDataList = data;
                    this.dtTriggerToBeBilledPartyRpt.next();
                }
            } ), ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };

    rowSelectedToGenrtMemoParty( toBeBilledPartyRptData ) {

        $("#"+this.pageId+"hiddenLrNumber" ).val( toBeBilledPartyRptData.lrNumber );
    }



    generateMemo() {
        this.selectedLrNumber = $("#"+this.pageId+"hiddenLrNumber" ).val();
        if ( this.selectedLrNumber == null || this.selectedLrNumber == "" ) {
            alert( 'Please Select Lr ' );
        } else {
            alert( this.selectedLrNumber );
        }
    }


    getDateInfo() {
        this.dateWeNeed = '';
//        this.selectedFromDatePrint = $("#"+this.pageId+"fromDate" ).val();
//        this.selectedToDatePrint = $("#"+this.pageId+"toDate" ).val();
        this.selectedFromDatePrint = '01-01-2020';
      this.selectedToDatePrint = '20-01-2020';
        this.dateWeNeed = '<h6 style="font-weight:bold;font-size:16px;">From Period: <span style="font-weight:400;">' + this.selectedFromDatePrint + '</span></h6>'
            + '<h6 style="font-weight:bold;font-size:16px;">To Period: <span style="font-weight:400;">' + this.selectedToDatePrint + '</span></h6>'
        this.pageTitle = "TO BE BILLED LRs REPORT";
        return this.dateWeNeed;

    }
    changeDataTableTitileForPrint() {

        this.datasToExport = '';
        //        console.log( "Error>>>>>> " + this.datasToExport );
        this.datasToExport = this.getDateInfo();


        return '<div class="row">'
            + '<div class="col-md-7">'
            + '<img src="assets/images/srdLogisticPrintLogo.png">'
            + '</div>'
            + '<div class="col-md-5">'
            + '<p style="font-size:16px;">' + this.userDataDtoReturnSession.addressId
            + '</p>'
            + '</div>'
            + '</div>'
            + '<div class="row"  style="text-align:right">'
            + '<div class="col-md-8">'
            + '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' + this.pageTitle + '</span></h5>'
            + '</div>'
            + '<div class="col-md-4">'
            + '<h6 style="font-weight:bold;font-size:16px;">From Period: <span style="font-weight:400;">' + this.selectedFromDatePrint + '</span></h6>'
            + '<h6 style="font-weight:bold;font-size:16px;">To Period: <span style="font-weight:400;">' + this.selectedToDatePrint + '</span></h6>'
            + '</div>'
            + '</div>'
    }

    changeDataTableTitileForExcel() {
        return "SRDlogo"
            + this.userDataDtoReturnSession.addressId
    }
    chnageDataTableMessageTopExcel() {
        return + this.pageTitle
            + this.selectedFromDatePrint
            + "                 "
            + this.selectedToDatePrint
    }
    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        $("#"+this.pageId+"fromDate" ).val( '' );
        $("#"+this.pageId+"toDate" ).val( '' );
        $("#"+this.pageId+"source" ).val( '' );
        $("#"+this.pageId+"consignorGstNumber" ).val( '' );
        $("#"+this.pageId+"consignorIndex" ).val( '' );
        $("#"+this.pageId+"consignorName" ).val( '' );
        $("#"+this.pageId+"viewType" ).val( 'dateWise' );
        this.viewTypeDateWise = true;
        this.viewTypePartyWise = false;
        this.toBeBilledPartyRptDataList = null;
        this.showGenerateMemo = false;
    }

}
