import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component({
    selector: 'app-business-stop-report',
    templateUrl: './business-stop-report.component.html',
    styleUrls: ['./business-stop-report.component.css']
})
export class BusinessStopReportComponent implements OnInit {

    getDataFrmServiceFrTable: any;

    businessStopRptDataList: any;

    onDestroyUnsubscribtionBusinessStopRpt: Subscription;



    //summaryTable:any;


    loadingIndicator = true;


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBusinessStopRpt: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsBusinessStopRpt: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;


    /*agentDtoForMainBookingStationSrc: AgentDetailsDto = new AgentDetailsDto();
    agentDtoForMainBookingStationSrcAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionsForMainBookingStationSrc: AgentDetailsDto[];
*/
    /*lrDtoOptionSourceStationForOther: LRDto[];
    lrDtoSourceBookingStationForOther: LRDto = new LRDto();
    public modelSourceBookingStationForOther: any;
    sourceBookingStationForOtherTA: Array<LRDto> = [];
    focusSourceBookingStationForOtherTA$ = new Subject<string>();
    searchSourceBookingStationForOther = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceBookingStationForOtherTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceBookingStationForOtherTA
                : this.sourceBookingStationForOtherTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceBookingStationForOthers = (x: { destination: string }) => x.destination;

    lrDtoOptionSourceStation: LRDto[];
    lrDtoSourceBookingStation: LRDto = new LRDto();
    public modelSourceBookingStation: any;
    sourceBookingStationTA: Array<LRDto> = [];
    focusSourceBookingStationTA$ = new Subject<string>();
    searchSourceBookingStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceBookingStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceBookingStationTA
                : this.sourceBookingStationTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceBookingStation = (x: { subStations: string }) => x.subStations;
    */

    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelSourceStation: any;
    sourceStationTA: Array<AgentDetailsDto> = [];
    focusSourceStationTA$ = new Subject<string>();
    searchSourceStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceStationTA
                : this.sourceStationTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceStation = (x: { mainBookStations: string }) => x.mainBookStations;

    destinationAll: boolean = false;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    avgActWtSummary: number;
    avgChgWtSummary: number;
    pageId = "bsprc";
    lrDto: LRDto = new LRDto();
    enteredSource: any;
    enteredDestination; any;
    showSpinnerForAction = false;

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef,
        private consigneeService: ConsigneeService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "destinationAllCneeBusStopRpt") {
                        this.destinationAll = true;
                    }
                    /*else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Source BkgLrDispSupAdmin") {
                        this.getSourceDetailsForOthers();
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Source BkgLrDispBkgAdmin") {
                        this.getSourceDetails();
                    }*/
                }
            }
            this.getDestinationDetails();
            this.getSourceDetails();
        }


    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {


        this.dtOptionsBusinessStopRpt = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    title: function () {
                        return "Business Stop Report Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);

            },
        }

        /* this.getDataFrmServiceFrTable = this.businessStopReport.getSummaryData()
         this.onDestroyUnsubscribtionBusinessStopRpt = this.getDataFrmServiceFrTable.subscribe( data => {
             this.businessStopRptDataList = data['data'];
             this.dtTriggerBusinessStopRpt.next();
         } );*/



        //the below code is for the getting data through json ends



    }

    ngAfterViewInit(): void {
        this.dtTriggerBusinessStopRpt.next();
    }


    ngOnDestroy(): void {
        this.dtTriggerBusinessStopRpt.unsubscribe();

        //this.onDestroyUnsubscribtionBusinessStopRpt.unsubscribe();
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    clickListnerForSourcStation(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSourceStation = e.item;
        $("#" + this.pageId + "sourceStationId").val(this.modelSourceStation.mainBookStations)
    }

    /* clickListnerForSourceBookingStationForOther(e: NgbTypeaheadSelectItemEvent, fubi: any) {
         this.modelSourceBookingStationForOther = e.item;
         $("#"+this.pageId+"sourceBookingStationForOtherId").val(this.modelSourceBookingStationForOther.destination)
     }
     clickListnerForSourceBookingStation(e: NgbTypeaheadSelectItemEvent, fubi: any) {
         this.modelSourceBookingStation = e.item;
         $("#"+this.pageId+"sourceBookingStationId").val(this.modelSourceBookingStation.subStations)
     }*/

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        if (this.destinationAll == true) {
            this.lrDtoDestination.mode = "LrForm";
        } else {
            this.lrDtoDestination.mode = "ALL";
            this.lrDtoDestination.mainstation = this.userDataDtoReturnSession.mainStation;
        }
        console.log(this.destinationAll);
    }
    getDestinationDetails() {
        //this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    //this.showSpinnerForAction = false;
                } else {
                    //this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getSourceDetails() {
        this.getSourceDataDetails();
        //this.showSpinnerForAction=true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.sourceStationTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "All";
                    this.sourceStationTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.sourceStationTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    //this.showSpinnerForAction = false;
                } else {
                    //this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
            },
            () => console.log('done');

    };

    //Custom Print
    customPrintBusinessStop() {
        if (this.businessStopRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Party Name", "Address", "Office No", "Mobile No", "Last LR Date", "Avg Act Wt.Per Day", "Avg Chg Wt.Per Day"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            this.avgActWtSummary = this.avgChgWtSummary = 0;

            for (let i = 0; i < this.businessStopRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.businessStopRptDataList[i].lrNumber, this.businessStopRptDataList[i].partyName,
                this.businessStopRptDataList[i].address, this.businessStopRptDataList[i].officeNo,
                this.businessStopRptDataList[i].mobileNo, this.businessStopRptDataList[i].lastLrDate, this.businessStopRptDataList[i].avgActWtPerDay,
                this.businessStopRptDataList[i].avgChgWtPerDay];

                /////////

                this.avgActWtSummary = this.avgActWtSummary + this.businessStopRptDataList[i].avgActWtPerDay;
                this.avgChgWtSummary = this.avgChgWtSummary + this.businessStopRptDataList[i].avgChgWtPerDay;


                /////
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.businessStopRptDataList.length, "", "", "", "", "", this.avgActWtSummary, this.avgChgWtSummary];



            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));

            localStorage.setItem('printTitle', "Consignee Business Stop Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

    validateForGetBusinessReportDetails() {
        if ($("#" + this.pageId + "sourceStationId").val() == null || $("#" + this.pageId + "sourceStationId").val() == undefined ||
            $("#" + this.pageId + "sourceStationId").val() == "" || $("#" + this.pageId + "destinationId").val() == null ||
            $("#" + this.pageId + "destinationId").val() == undefined || $("#" + this.pageId + "destinationId").val() == "") {
            swal(" Mandatory Field", "All fields are mandatory", "warning");
            return false;
        } else {
            this.getBusinessStopReportDetils();
        }
    }

    setBusinessStopReportDetils() {
        this.enteredSource = $("#" + this.pageId + "sourceStationId").val();
        this.enteredDestination = $("#" + this.pageId + "destinationId").val();

        this.lrDto = new LRDto();
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.source = this.enteredSource;
        this.lrDto.destination = this.enteredDestination;
        this.lrDto.noOfLrs = 500;
    }

    getBusinessStopReportDetils = () => {
        this.setBusinessStopReportDetils();
        this.showSpinnerForAction = true;
        console.log("lrDto");
        console.log(this.lrDto);
        this.consigneeService.getBusinessStopReportDetilList(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "businessStopReportTableId").DataTable().destroy();
                this.businessStopRptDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Business Stop Report Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.businessStopRptDataList = response;
                    console.log("Business Report list");
                    console.log(this.businessStopRptDataList);
                }
                this.dtTriggerBusinessStopRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  business stop report details", "info");
            }, () => console.log('done');
    };

    clearFeilds() {
        $("#" + this.pageId + "sourceStationId").val('');
        $("#" + this.pageId + "destinationId").val('');
        this.businessStopRptDataList = [];
        $("#" + this.pageId + "businessStopReportTableId").DataTable().destroy();
        this.dtTriggerBusinessStopRpt.next();
    }
}
