//for datepicker starts
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrService } from 'src/app/dataService/lr-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from "src/app/dataService/hireslip-service";
import *  as moment from 'moment';
import { variable } from '@angular/compiler/src/output/output_ast';
//for the select option with filter ends
@Component({
    selector: 'app-lorry-hire-advance-payment-details',
    templateUrl: './lorry-hire-advance-payment-details.component.html',
    styleUrls: ['./lorry-hire-advance-payment-details.component.css']
})
export class LorryHireAdvancePaymentDetailsComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    controlMain = new FormControl();

    dataTable: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAdvancePaymentCashChequePdc: Subject<any> = new Subject();
    dtOptionsAdvancePaymentCashChequePdc: any;
    advancePaymentChequePdcDataList: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction: boolean = false;

    userDataDto: UserDataDto = new UserDataDto();
    bankNameOptions: UserDataDto[];
    public modelBankName: any;
    bankNameTA: Array<UserDataDto> = [];
    focusBankNameTA$ = new Subject<string>();
    searchBankName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    viewCashChequePdcAmtField: boolean = true;
    viewChequePdcNoField: boolean = true;
    viewChequeDueDateField: boolean = false;
    viewChequePdcBankNameField: boolean = true;

    modelChequeDueDates: any;
    modelPayingDates: any;

    localStorageOfPaymentData: any;
    localStorageOfPaymentDataHireslipNumber: any;

    private newAttributeTosetBank: any = {};
    editBankTableDetailsId: any;

    validateBalAmtToBePaid: any;
    validateBalAmtCash: any;
    validateBalAmtCheque: any;
    validateBalAmtPdc: any;

    validateBalAmtForSave: any;
    validateSummCashAmtForSave: any;
    validateSummChequeAmtForSave: any;
    validateSummPdcAmtForSave: any;
    validatePayingDateForSave: any;
    validateInitBalAmtForSave: any;
    resultDuplicate: any;

    fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();

    Statement: any;
    hireslipNumber: any;
    selectedAdvancePayable: any;
    hireslipDtoForSave: HireSlipDto = new HireSlipDto();
    hirePrintDto: HireSlipDto = new HireSlipDto();
    hireslipDtoForUserVal: HireSlipDto = new HireSlipDto();
    hireslipDtoForMulVal: HireSlipDto = new HireSlipDto();
    valueMin = 0.0;
    valueMax = 0.0;

    viewPaymentVoucher=false;
    viewPaymentVoucherChq=false;
    viewPaymentVoucherPdc=false;
    viewTdsVoucher=false;
    pageId="lhadc";



    constructor(private agentCommodityScreen: MasterService, private modalService: NgbModal,
        private router: Router, private masterReadService: MasterReadService,
        private lrService: LrService, private hireslipService: HireslipService,
        public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.localStorageOfPaymentData = null;
            this.localStorageOfPaymentData = JSON.parse(localStorage.getItem('popupAdvancePaymentData'));
            this.localStorageOfPaymentDataHireslipNumber = null;
            this.localStorageOfPaymentDataHireslipNumber = JSON.parse(localStorage.getItem('popupAdvancePaymentReportHireslipnumber'));
            if (this.localStorageOfPaymentData != null &&
                this.localStorageOfPaymentData != undefined) {
                this.filledValues(this.localStorageOfPaymentData);
            }
            if (this.localStorageOfPaymentDataHireslipNumber != null &&
                this.localStorageOfPaymentDataHireslipNumber != undefined &&
                this.localStorageOfPaymentDataHireslipNumber != '') {
                this.hireslipNumber = this.localStorageOfPaymentDataHireslipNumber
            }
            this.getBankDetailsList();


        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsAdvancePaymentCashChequePdc = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var totalBankAmt = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalBankAmt
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": ""
                                                            }))
                                        .prop('outerHTML'));
                            totalBankAmt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    totalBankAmt += +val[2];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count

                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalBankAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        })).append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                    .prop('outerHTML'));
                        totalBankAmt = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerAdvancePaymentCashChequePdc.unsubscribe();
        //this.onDestroyUnsubscribtionBkgAgentStmtReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        /*var strstring ="null#";
            var dd= strstring.replace("null#","A");
            console.log(dd);*/
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
        this.modelBankName = e.item;
        $("#"+this.pageId+"bankNameId").val(this.modelBankName.bankName);
        $("#"+this.pageId+"bankNameId").focusout();
        $("#"+this.pageId+""+this.pageId+"cashChequePdcAmount").focus();

    }

    getDetailsForBankMasterRead() {
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        this.showSpinnerForAction = true;
        this.bankNameOptions = [];
        this.getDetailsForBankMasterRead();
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.bankNameOptions = response;
                        this.bankNameTA = [];
                        for (let i = 0; i < this.bankNameOptions.length; i++) {
                            this.bankNameTA.push(this.bankNameOptions[i]);
                        }
                        this.showSpinnerForAction = false;
                    } else {
                        this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Master Details", "error");
            },
            () => console.log('done');
    };




    payemntModeTypeListner(modeType: string) {
        if (modeType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');
            $("#"+this.pageId+"cashChequePdcAmount").focus();
            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            $("#"+this.pageId+"chequePdcNo").focus();

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');


            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');
            $("#"+this.pageId+"chequePdcNo").focus();

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        }
    }

    chequePdcNoEvent(e) {
        if (e.keyCode == 13) {
            if (($("#"+this.pageId+"payemntModeType").val() == "Cheque")) {
                $("#"+this.pageId+"bankNameId").focus();
            } else if (($("#"+this.pageId+"payemntModeType").val() == "PDC")) {
                $("#"+this.pageId+"chequeDueDates").focus();
            }
        }
    }

    filledValues(localStorageOfPaymentData) {
        console.log(localStorageOfPaymentData);
        var chequeAmtSumm = 0.0, pdcAmtSmm = 0.0;


        var cashAmt = [];
        var chequeAmt = [], chequeNumber = [], cheqBankName = [];
        var pdcAmt = [], pdcNumber = [], pdcDueDate = [], pdcBankName = [];

        //cashAmt
        // cashAmt = localStorageOfPaymentData.cashAmt == null ? null
        //     : localStorageOfPaymentData.cashAmt.split("#");
        cashAmt = localStorageOfPaymentData.cashAmt == null ? null :localStorageOfPaymentData.cashAmt == 0 ? null
            : localStorageOfPaymentData.cashAmt;

        //cheque
        chequeAmt = localStorageOfPaymentData.advChqAmt == null ? null
            : localStorageOfPaymentData.advChqAmt.split("#");
        chequeNumber = localStorageOfPaymentData.advChqNumber == null ? null
            : localStorageOfPaymentData.advChqNumber.split("#");
        cheqBankName = localStorageOfPaymentData.advChqBankName == null ? null
            : localStorageOfPaymentData.advChqBankName.split("#");

        //pdc
        pdcAmt = localStorageOfPaymentData.advPdcChqAmt == null ? null
            : localStorageOfPaymentData.advPdcChqAmt.split("#");
        pdcNumber = localStorageOfPaymentData.advPdcChqNumber == null ? null
            : localStorageOfPaymentData.advPdcChqNumber.split("#");
        pdcDueDate = localStorageOfPaymentData.advPdcChqDueDate == null ? null
            : localStorageOfPaymentData.advPdcChqDueDate.split("#");
        pdcBankName = localStorageOfPaymentData.advPdcChqBankName == null ? null
            : localStorageOfPaymentData.advPdcChqBankName.split("#");
        //console.log(pdcAmt, chequeAmt);

        this.advancePaymentChequePdcDataList = [];

        if (cashAmt != null && cashAmt != undefined ) {
            this.newAttributeTosetBank = {};
            this.newAttributeTosetBank.paymentType = "Cash";
            this.newAttributeTosetBank.chequeNumber = null;
            this.newAttributeTosetBank.amount = cashAmt;
            this.newAttributeTosetBank.bankName = null;
            this.newAttributeTosetBank.pdcDueDate = null;
            this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
        }

        if (chequeAmt != null && chequeAmt != undefined &&
            chequeAmt.length > 0) {
            for (let i = 0; i < chequeAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "Cheque";
                this.newAttributeTosetBank.chequeNumber = chequeNumber[i];
                this.newAttributeTosetBank.amount = chequeAmt[i];
                this.newAttributeTosetBank.bankName = cheqBankName[i];
                this.newAttributeTosetBank.pdcDueDate = null;
                this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);

                chequeAmtSumm = +chequeAmtSumm + +chequeAmt[i];
            }
        }

        if (pdcAmt != null && pdcAmt != undefined &&
            pdcAmt.length > 0) {
            for (let i = 0; i < pdcAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "PDC";
                this.newAttributeTosetBank.chequeNumber = pdcNumber[i];
                this.newAttributeTosetBank.amount = pdcAmt[i];
                this.newAttributeTosetBank.bankName = pdcBankName[i];
                this.newAttributeTosetBank.pdcDueDate = pdcDueDate[i];
                this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);

                pdcAmtSmm = +pdcAmtSmm + +pdcAmt[i];
            }
        }
        $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        var balanceAmt = 0.0;
        this.showSpinnerForAction = true;
        setTimeout(() => {
            $("#"+this.pageId+"destinationId").val(localStorageOfPaymentData.hireslipnumber);
            $("#"+this.pageId+"amount").val(localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance);
            if (localStorageOfPaymentData.paymentDate != null && localStorageOfPaymentData.paymentDate != undefined  && localStorageOfPaymentData.paymentDate !=''){
                $("#"+this.pageId+"payingDates").val(moment(localStorageOfPaymentData.paymentDate).format("YYYY-MM-DD"));
            } else {
                $("#"+this.pageId+"payingDates").val(moment(new Date()).format("YYYY-MM-DD"));
            }
            $("#"+this.pageId+"totalAmountToBePaid").val(localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance);
            $("#"+this.pageId+"tdsDeducted").val(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount);
            $("#"+this.pageId+"advancePayable").val((localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance) + -(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount));
            $("#"+this.pageId+"summaryCashAmt").val(localStorageOfPaymentData.cashAmt == null ? 0 : localStorageOfPaymentData.cashAmt);
            $("#"+this.pageId+"summaryChequeAmt").val(chequeAmtSumm == null ? 0 : chequeAmtSumm);
            $("#"+this.pageId+"summaryPDCAmt").val(pdcAmtSmm == null ? 0 : pdcAmtSmm);
            console.log(localStorageOfPaymentData.advance, localStorageOfPaymentData.advCashAmt, chequeAmtSumm, pdcAmtSmm);
            balanceAmt = (localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance) + -(localStorageOfPaymentData.cashAmt == null ? 0 : localStorageOfPaymentData.cashAmt) + -(chequeAmtSumm == null ? 0 : chequeAmtSumm) + -(pdcAmtSmm == null ? 0 : pdcAmtSmm) + -(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount);

            //Basha
            //  balanceAmt=(localStorageOfPaymentData.advance==null ? 0 :localStorageOfPaymentData.advance) + -(localStorageOfPaymentData.tdsAmount==null ? 0 :localStorageOfPaymentData.tdsAmount)
            $("#"+this.pageId+"balanceToBePaid").val(balanceAmt == null ? 0 : balanceAmt);
            this.showSpinnerForAction = false;
        }, 1000);

        this.hirePrintDto = new HireSlipDto();
        this.hirePrintDto.fromStation=localStorageOfPaymentData.fromStation;
        this.hirePrintDto.toStation=localStorageOfPaymentData.toStation;
        this.hirePrintDto.vehicleNumber=localStorageOfPaymentData.vehicleNumber;
        this.hirePrintDto.hireslipnumber=localStorageOfPaymentData.hireslipnumber;
        this.hirePrintDto.hireslipdate=localStorageOfPaymentData.hireslipdate;
        this.hirePrintDto.suppliername=localStorageOfPaymentData.supplierName;
        this.hirePrintDto.tdsAmount=localStorageOfPaymentData.tdsAmount;
        this.hirePrintDto.tdsPercent=localStorageOfPaymentData.tdsPercent;
        this.hirePrintDto.totalhire=localStorageOfPaymentData.totalhire;
        this.hirePrintDto.advance=localStorageOfPaymentData.advance;
    }

    balanceAmt() {
        var balanceAmt = 0.0;
        this.validateBalAmtToBePaid = 0;
        this.validateBalAmtCash = 0;
        this.validateBalAmtCheque = 0;
        this.validateBalAmtPdc = 0;
        this.selectedAdvancePayable = 0;

        this.validateBalAmtCash = $("#"+this.pageId+"summaryCashAmt").val();
        this.validateBalAmtCheque = $("#"+this.pageId+"summaryChequeAmt").val();
        this.validateBalAmtPdc = $("#"+this.pageId+"summaryPDCAmt").val();
        this.selectedAdvancePayable = $("#"+this.pageId+"advancePayable").val();

        balanceAmt = +(this.selectedAdvancePayable == null ? 0 : this.selectedAdvancePayable) + -(this.validateBalAmtCash == null ? 0 : this.validateBalAmtCash) + -(this.validateBalAmtCheque == null ? 0 : this.validateBalAmtCheque) + -(this.validateBalAmtPdc == null ? 0 : this.validateBalAmtPdc);

        $("#"+this.pageId+"balanceToBePaid").val(balanceAmt == null ? 0 : balanceAmt);
    }

    calculateCashAmtSum() {
        var cashSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
                cashSum = cashSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return cashSum;
    }

    calculateChequeAmtSum() {
        var chequeSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cheque") {
                chequeSum = chequeSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return chequeSum;
    }

    calculatePdcAmtSum() {
        var pdcSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
                pdcSum = pdcSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return pdcSum;
    }

    onKeyPressListnerForAmount(event) {
        if (event.charCode == 13) {
            // $("#"+this.pageId+"incentiveToId").focus();
            $("#"+this.pageId+"addBtn").focus();
            this.balanceAmt();

        }
    }

    addBankDetailsInTable() {
        if (($("#"+this.pageId+"payemntModeType").val() == "Cash") &&
            ($("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter amount", "warning");
            return false;
        } else if (($("#"+this.pageId+"payemntModeType").val() == "Cheque") &&
            ($("#"+this.pageId+"chequePdcNo").val() == null || $("#"+this.pageId+"chequePdcNo").val() == undefined ||
                $("#"+this.pageId+"chequePdcNo").val() == "" || $("#"+this.pageId+"bankNameId").val() == null ||
                $("#"+this.pageId+"bankNameId").val() == undefined || $("#"+this.pageId+"bankNameId").val() == "" ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter the  mandatory field", "warning");
            return false;
        } else if (($("#"+this.pageId+"payemntModeType").val() == "PDC") &&
            ($("#"+this.pageId+"chequePdcNo").val() == null || $("#"+this.pageId+"chequePdcNo").val() == undefined ||
                $("#"+this.pageId+"chequePdcNo").val() == "" || $("#"+this.pageId+"bankNameId").val() == null ||
                $("#"+this.pageId+"bankNameId").val() == undefined || $("#"+this.pageId+"bankNameId").val() == "" ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "" || $("#"+this.pageId+"chequeDueDates").val() == null ||
                $("#"+this.pageId+"chequeDueDates").val() == undefined || $("#"+this.pageId+"chequeDueDates").val() == "")) {
            swal("Mandatory Field", "Please enter the  mandatory field", "warning");
            return false;
        } else {
            this.addIntoTableOfBankDetails();
        }
    }

    addIntoTableOfBankDetails() {
        this.newAttributeTosetBank = {};
        this.newAttributeTosetBank.paymentType = $("#"+this.pageId+"payemntModeType").val();
        this.newAttributeTosetBank.chequeNumber = $("#"+this.pageId+"chequePdcNo").val();
        this.newAttributeTosetBank.amount = $("#"+this.pageId+"cashChequePdcAmount").val();
        this.newAttributeTosetBank.bankName = $("#"+this.pageId+"bankNameId").val();
        this.newAttributeTosetBank.pdcDueDate = $("#"+this.pageId+"chequeDueDates").val();
        //this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);


        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.advancePaymentChequePdcDataList.splice(this.editBankTableDetailsId, 1);
        }

        if (this.advancePaymentChequePdcDataList == null ||
            this.advancePaymentChequePdcDataList == undefined ||
            this.advancePaymentChequePdcDataList.length == 0) {
            this.advancePaymentChequePdcDataList = [];
        }

        if (this.advancePaymentChequePdcDataList.length == 0) {
            this.advancePaymentChequePdcDataList = [];
            $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
            this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
            this.dtTriggerAdvancePaymentCashChequePdc.next();
            this.clearFieldBank();
            console.log('checking for length zero');
            $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
            $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
            $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
            this.balanceAmt();
        } else {
            if ($("#"+this.pageId+"payemntModeType").val() == "Cash") {
                const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Cash Amount Already Available ,Please Edit the Cash Amount and then Add", "warning");
                } else {
                    $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
                    this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAdvancePaymentCashChequePdc.next();
                    this.clearFieldBank();
                    $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            } else {
                const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                    bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
                } else {
                    console.log('checking');
                    console.log(this.calculateChequeAmtSum());
                    $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
                    this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAdvancePaymentCashChequePdc.next();
                    this.clearFieldBank();
                    $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            }

            /* here start */
            /* const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                 bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                 bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
             if (result.length > 0) {
                 swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
             } else {
                 $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
                 this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
                 this.dtTriggerAdvancePaymentCashChequePdc.next();
                 this.clearFieldBank();
                 $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                 $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                 $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                 this.balanceAmt();
             }*/
            /* here end */
        }
    }

    rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData, index) {
        this.editBankTableDetailsId = index;
        if (bkgAgentStmtCashChequePdcData.paymentType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("Cash");
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("Cheque");
                $("#"+this.pageId+"chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#"+this.pageId+"bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("PDC");
                $("#"+this.pageId+"chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#"+this.pageId+"bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#"+this.pageId+"chequeDueDates").val(bkgAgentStmtCashChequePdcData.pdcDueDate);
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        }
    }

    deleteSelectedBankDetails(index) {
        $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.advancePaymentChequePdcDataList.splice(index, 1);
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
        $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
        $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
        this.balanceAmt();
    }


    clearFieldBank() {
        this.editBankTableDetailsId = null;
        this.newAttributeTosetBank = {};
        $("#"+this.pageId+"chequePdcNo").val('');
        $("#"+this.pageId+"chequeDueDates").val('');
        $("#"+this.pageId+"bankNameId").val('');
        $("#"+this.pageId+"cashChequePdcAmount").val('');

        this.modelChequeDueDates = null;
        this.modelBankName = null;
    }

    clearBankDetailsInTable() {
        this.clearFieldBank();
        $("#"+this.pageId+"payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
    }


    /* save start here */
    validateConfirmPaymentBtn() {
        if ($("#"+this.pageId+"payingDates").val() == null || $("#"+this.pageId+"payingDates").val() == undefined ||
            $("#"+this.pageId+"payingDates").val() == "") {
            swal("Mandatory Field", "Please Select the Receiving Date", "warning");
            return false;
        } else {
            if ($("#"+this.pageId+"tdsDeducted").val() != null && $("#"+this.pageId+"tdsDeducted").val() != undefined &&
                $("#"+this.pageId+"tdsDeducted").val() != "") {
                swal({
                    title: "TDS Voucher Print",
                    text: "Sure U Want To Print the TDS Voucher",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((sureYesBtn) => {
                    if (sureYesBtn) {
                        //Pending
                        // this.HireSlipAdvancePaymentTdsVoucherPrt();
                    localStorage.clear();
                    console.log(this.hirePrintDto);
                    localStorage.setItem('tdsVoucherPrint', JSON.stringify( this.localStorageOfPaymentData));
                    // this.viewPaymentVoucher = false;
                    // this.viewTdsVoucher=false;
                    this.viewTdsVoucher=true;
                    // window.addEventListener('afterPopUp', (event) => {
                        window.addEventListener('afterprint', (onclick) => {
                        console.log('dddsadfads');
                        // this.viewTdsVoucher = false;
                        // this.confirmPayment();
                        if (this.viewTdsVoucher) {
                            this.viewTdsVoucher = false;
                            this.confirmPayment();
                        }
                        this.changeDetectorRef.detectChanges();
                    });
                    
                      
                    } else {
                        this.confirmPayment();
                    }
                    this.changeDetectorRef.detectChanges();
                });

            } else {
                this.confirmPayment();
            }


        }
    }

    getUserValues() {
        this.hireslipDtoForUserVal = new HireSlipDto();

        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validatePayingDateForSave = '';
        this.validateInitBalAmtForSave = 0.0;

        this.validateBalAmtForSave = $("#"+this.pageId+"balanceToBePaid").val();
        this.validateSummCashAmtForSave = $("#"+this.pageId+"summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#"+this.pageId+"summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#"+this.pageId+"summaryPDCAmt").val();
        this.validatePayingDateForSave = $("#"+this.pageId+"payingDates").val();
        this.validateInitBalAmtForSave = $("#"+this.pageId+"amount").val();

        this.hireslipDtoForUserVal = this.setMultipleValue();
        this.hireslipDtoForUserVal.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireslipDtoForUserVal.advHireAmtPaidDate = this.validatePayingDateForSave;
        this.hireslipDtoForUserVal.cashAmt = this.validateSummCashAmtForSave;
        this.hireslipDtoForUserVal.advTotalChqAmt = this.validateSummChequeAmtForSave;
        this.hireslipDtoForUserVal.advTotalPdcAmt = this.validateSummPdcAmtForSave;
        this.hireslipDtoForUserVal.totalhire = this.localStorageOfPaymentData.totalHire;
        this.hireslipDtoForUserVal.tdsAmount = this.localStorageOfPaymentData.tdsAmount;

        var totAmtPaid = 0.0;
        totAmtPaid = +(this.hireslipDtoForUserVal.cashAmt = null ? 0.0 : this.hireslipDtoForUserVal.cashAmt) +
            +(this.hireslipDtoForUserVal.advTotalChqAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalChqAmt) +
            +(this.hireslipDtoForUserVal.advTotalPdcAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalPdcAmt) + +(this.hireslipDtoForUserVal.tdsAmount == null ? 0.0 : this.hireslipDtoForUserVal.tdsAmount);

        this.hireslipDtoForUserVal.advTotalPaid = totAmtPaid;
        this.hireslipDtoForUserVal.hireslipnumber = this.localStorageOfPaymentData.hireslipnumber;
        return this.hireslipDtoForUserVal;
    }

    setMultipleValue() {
        this.hireslipDtoForMulVal = new HireSlipDto();
        var cashAmtMultiple = null;
        var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
        var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;
        var chequeNames = "NA", pdcChequeNames = "NA";

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cheque") {
                chqNumMultiple = chqNumMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].chequeNumber;
                chqAmtMultiple = chqAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
                cheqBankNameMultiple = cheqBankNameMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].bankName;

                if (i == 0) {
                    chequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
                } else {
                    chequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
                }
            }
        }

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
                pdcNumMultiple = pdcNumMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].chequeNumber;
                pdcAmtMultiple = pdcAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
                pdcDueDateMultiple = pdcDueDateMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].pdcDueDate;
                pdcBankNameMultiple = pdcBankNameMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].bankName;
                if (i == 0) {
                    pdcChequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
                } else {
                    pdcChequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
                }
            }
        }

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
                cashAmtMultiple = cashAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
            }
        }

        if (chqNumMultiple != null) {
            chqNumMultiple = chqNumMultiple.replace("null#", "");
        }
        if (chqAmtMultiple != null) {
            chqAmtMultiple = chqAmtMultiple.replace("null#", "");
        }
        if (pdcNumMultiple != null) {
            pdcNumMultiple = pdcNumMultiple.replace("null#", "");
        }
        if (pdcAmtMultiple != null) {
            pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
        }
        if (pdcDueDateMultiple != null) {
            pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
        }
        if (pdcBankNameMultiple != null) {
            pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
        }
        if (cheqBankNameMultiple != null) {
            cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
        }

        if (cashAmtMultiple != null) {
            cashAmtMultiple = cashAmtMultiple.replace("null#", "");
        }
        this.hireslipDtoForMulVal.advChqNumber = chqNumMultiple;
        this.hireslipDtoForMulVal.advChqAmt = chqAmtMultiple;
        this.hireslipDtoForMulVal.advChqBankName = cheqBankNameMultiple;
        this.hireslipDtoForMulVal.advPdcChqNumber = pdcNumMultiple;
        this.hireslipDtoForMulVal.advPdcChqAmt = pdcAmtMultiple;
        this.hireslipDtoForMulVal.advPdcChqDueDate = pdcDueDateMultiple;
        this.hireslipDtoForMulVal.advPdcChqBankName = pdcBankNameMultiple;

        this.hirePrintDto.chequeno = chequeNames;
        this.hirePrintDto.column1 = pdcChequeNames;
        this.hirePrintDto.advChqBankName = cheqBankNameMultiple;
        this.hirePrintDto.advPdcChqBankName = pdcBankNameMultiple;
        return this.hireslipDtoForMulVal;
    }

    overAllClearFieldBtn() {
        this.clearOverAllField();
    }
    clearOverAllField() {
        $("#"+this.pageId+"payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
        this.clearFieldBank();

        this.modelChequeDueDates = null;
        this.modelPayingDates = null;

        this.localStorageOfPaymentData = null;

        this.newAttributeTosetBank = {};
        this.editBankTableDetailsId = null;

        this.validateBalAmtToBePaid = null;
        this.validateBalAmtCash = null;
        this.validateBalAmtCheque = null;
        this.validateBalAmtPdc = null;

        this.validateBalAmtForSave = null;
        this.validateSummCashAmtForSave = null;
        this.validateSummChequeAmtForSave = null;
        this.validateSummPdcAmtForSave = null;
        this.validatePayingDateForSave = null;
        this.validateInitBalAmtForSave = null;
        this.resultDuplicate = null;

        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

        $("#"+this.pageId+"totalAmountToBePaid").val('');
        $("#"+this.pageId+"balanceToBePaid").val('');
        $("#"+this.pageId+"summaryCashAmt").val('');
        $("#"+this.pageId+"summaryChequeAmt").val('');
        $("#"+this.pageId+"summaryPDCAmt").val('');
        $("#"+this.pageId+"payingDates").val('');
        $("#"+this.pageId+"destinationId").val('');
        $("#"+this.pageId+"tdsDeducted").val('');
        $("#"+this.pageId+"amount").val('');
        $("#"+this.pageId+"advancePayable").val('')

        this.advancePaymentChequePdcDataList = [];
        $("#"+this.pageId+"advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        this.Statement = '';
    }


    chqDueDateFocus(e) {
        if (e.keyCode == 13) {
            this.focusChqDueDateCalendar();
        }
    }

    focusChqDueDateCalendar() {
        window.setTimeout(function () {
            $("#"+this.pageId+"bankNameId").focus();
        }, 100);

    }
    confirmPayment() {
        console.log('ddaccccc');
        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validateBalAmtForSave = $("#"+this.pageId+"amount").val();
        this.validateSummCashAmtForSave = $("#"+this.pageId+"summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#"+this.pageId+"summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#"+this.pageId+"summaryPDCAmt").val();

        var overAllTotalAmt = (this.validateSummCashAmtForSave == null ? 0.0 : parseInt(this.validateSummCashAmtForSave)) + +(this.validateSummChequeAmtForSave == null ? 0.0 : parseInt(this.validateSummChequeAmtForSave)) + +(this.validateSummPdcAmtForSave == null ? 0.0 : parseInt(this.validateSummPdcAmtForSave));
        var balnceToBePaidAmt = (this.validateBalAmtForSave == null ? 0.0 : parseInt(this.validateBalAmtForSave)) + -(overAllTotalAmt == null ? 0.0 : overAllTotalAmt);
        
        this.hirePrintDto.advChequeAmt = this.validateSummChequeAmtForSave;
        this.hirePrintDto.advCashAmt = this.validateSummCashAmtForSave;
        console.log(balnceToBePaidAmt);
        console.log(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave, overAllTotalAmt, balnceToBePaidAmt);

        if ((overAllTotalAmt > 0.0) && !(balnceToBePaidAmt < 0.0)) {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Submit the Amount Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((payment) => {
                if (payment) {
                    this.hireslipDtoForSave = new HireSlipDto();
                    this.hireslipDtoForSave = this.getUserValues();
                    if (this.hireslipDtoForSave.advTotalPaid > this.localStorageOfPaymentData.totalHire) {
                        swal("Not Allowed", "Total Paid Should not be Greater than Paid Amount", "warning");
                        return false;
                    } else {
                        this.hirePrintDto.paid = this.hireslipDtoForSave.advTotalPaid;
                        this.saveLryAdvance(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave);

                    }
                }
            });
        } else if (balnceToBePaidAmt < 0.0) {
            swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Extra", "warning");
            return false;
        } else if (overAllTotalAmt == 0.0) {
            swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Zero", "warning");
            return false;
        }


    }





    // saveLryAdvance(cashTotAmt, chequeTotAmt, pdcTotAmt) {
    //     console.log(this.hireslipDtoForSave);
    //     this.showSpinnerForAction = true;
    //     this.hireslipService.lryHireAdvPayment(this.hireslipDtoForSave).subscribe(
    //         (response) => {

    //             console.log(response);
    //             this.showSpinnerForAction = false;
    //             this.validatePayingDateForSave = $("#"+this.pageId+"payingDates").val();
    //             if (response.status == 'Success') {
    //                 this.hirePrintDto.paymentDate = this.validatePayingDateForSave;
    //                 this.clearOverAllField();
    //                 swal({
    //                     title: "Payment Print",
    //                     text: "Sure U Want To Print",
    //                     icon: "warning",
    //                     closeOnClickOutside: false,
    //                     closeOnEsc: false,
    //                     buttons: ["No", "Yes"],
    //                 }).then((paymentPrint) => {
    //                     if (paymentPrint) {

    //                         if (parseInt(cashTotAmt) > 0) {
    //                             console.log('cash');

    //                             if (parseInt(cashTotAmt) <= 20000) {

    //                                 swal({
    //                                     title: "Payment Confirmation",
    //                                     text: "Sure U Want To Print the Cash Voucher",
    //                                     icon: "warning",
    //                                     closeOnClickOutside: false,
    //                                     closeOnEsc: false,
    //                                     buttons: ["No", "Yes"],
    //                                 }).then((cash) => {
    //                                     if (cash) {
    //                                         this.hirePrintDto.paid = cashTotAmt;
    //                                         this.hirePrintDto.mode = 'cash';
    //                                         console.log('cash');
    //                                         console.log(this.hirePrintDto);
    //                                         //Pending
    //                                         // this.HireSlipAdvancePaymentVoucher
    //                                         this.paymentVoucher();
    //                                         if (parseInt(chequeTotAmt) > 0) {
    //                                             console.log('chq');

                                                // swal({
                                                //     title: "Payment Confirmation",
                                                //     text: "Sure U Want To Print the Cheque Voucher",
                                                //     icon: "warning",
                                                //     closeOnClickOutside: false,
                                                //     closeOnEsc: false,
                                                //     buttons: ["No", "Yes"],
                                                // }).then((cheque) => {
                                                //     if (cheque) {
                                                //         this.hirePrintDto.paid = chequeTotAmt;
                                                //         this.hirePrintDto.mode = 'cheque';
                                                //         if (this.hirePrintDto.chequeno != null) {
                                                //             var chequeNo = this.hirePrintDto.chequeno;
                                                //             var chequeNoNew = chequeNo.replace('#', ',');
                                                //             this.hirePrintDto.chequeno = chequeNoNew;
                                                //         }
                                                //         if (this.hirePrintDto.advChqBankName != null) {
                                                //             var cheqBankName = this.hirePrintDto.advChqBankName;
                                                //             var cheqBankNameNew = cheqBankName.replace('#', ',');
                                                //             this.hirePrintDto.advChqBankName = cheqBankNameNew;
                                                //         }
                                                //         console.log('chq');
                                                //         console.log(this.hirePrintDto);
                                                //         //Pending
                                                //         // this.HireSlipAdvancePaymentVoucher
                                                //         this.paymentVoucher();

    //                                                     if (parseInt(pdcTotAmt) > 0) {

    //                                                         swal({
    //                                                             title: "Payment Confirmation",
    //                                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                                             icon: "warning",
    //                                                             closeOnClickOutside: false,
    //                                                             closeOnEsc: false,
    //                                                             buttons: ["No", "Yes"],
    //                                                         }).then((cheque) => {
    //                                                             if (cheque) {
    //                                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                                 if (this.hirePrintDto.column1 != null) {
    //                                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                                 }
    //                                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                                 }
    //                                                                 console.log('pdc');
    //                                                                 console.log(this.hirePrintDto);
    //                                                                 //Pending
    //                                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                                 this.paymentVoucher();
    //                                                             }
    //                                                         });


    //                                                     }
    //                                                 } else {
    //                                                     if (parseInt(pdcTotAmt) > 0) {

    //                                                         swal({
    //                                                             title: "Payment Confirmation",
    //                                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                                             icon: "warning",
    //                                                             closeOnClickOutside: false,
    //                                                             closeOnEsc: false,
    //                                                             buttons: ["No", "Yes"],
    //                                                         }).then((cheque) => {
    //                                                             if (cheque) {
    //                                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                                 if (this.hirePrintDto.column1 != null) {
    //                                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                                 }
    //                                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                                 }
    //                                                                 console.log('pdc');
    //                                                                 console.log(this.hirePrintDto);
    //                                                                 //Pending
    //                                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                                 this.paymentVoucher();
    //                                                             }
    //                                                         });


    //                                                     }
    //                                                 }
    //                                             });


    //                                         } else {
    //                                             if (parseInt(pdcTotAmt) > 0) {

    //                                                 swal({
    //                                                     title: "Payment Confirmation",
    //                                                     text: "Sure U Want To Print the Pdc Voucher",
    //                                                     icon: "warning",
    //                                                     closeOnClickOutside: false,
    //                                                     closeOnEsc: false,
    //                                                     buttons: ["No", "Yes"],
    //                                                 }).then((cheque) => {
    //                                                     if (cheque) {
    //                                                         this.hirePrintDto.paid = pdcTotAmt;
    //                                                         this.hirePrintDto.mode = 'pdcCheque';
    //                                                         if (this.hirePrintDto.column1 != null) {
    //                                                             var pdcChequeNo = this.hirePrintDto.column1;
    //                                                             var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                             this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                         }
    //                                                         if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                             var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                             var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                             this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                         }
    //                                                         console.log('pdc');
    //                                                         console.log(this.hirePrintDto);
    //                                                         //Pending
    //                                                         // this.HireSlipAdvancePaymentVoucher
    //                                                         this.paymentVoucher();
    //                                                     }
    //                                                 });


    //                                             }
    //                                         }
    //                                     } else {
    //                                         if (parseInt(chequeTotAmt) > 0) {
    //                                             console.log('chq');

    //                                             swal({
    //                                                 title: "Payment Confirmation",
    //                                                 text: "Sure U Want To Print the Cheque Voucher",
    //                                                 icon: "warning",
    //                                                 closeOnClickOutside: false,
    //                                                 closeOnEsc: false,
    //                                                 buttons: ["No", "Yes"],
    //                                             }).then((cheque) => {
    //                                                 if (cheque) {
    //                                                     this.hirePrintDto.paid = chequeTotAmt;
    //                                                     this.hirePrintDto.mode = 'cheque';
    //                                                     if (this.hirePrintDto.chequeno != null) {
    //                                                         var chequeNo = this.hirePrintDto.chequeno;
    //                                                         var chequeNoNew = chequeNo.replace('#', ',');
    //                                                         this.hirePrintDto.chequeno = chequeNoNew;
    //                                                     }
    //                                                     if (this.hirePrintDto.advChqBankName != null) {
    //                                                         var cheqBankName = this.hirePrintDto.advChqBankName;
    //                                                         var cheqBankNameNew = cheqBankName.replace('#', ',');
    //                                                         this.hirePrintDto.advChqBankName = cheqBankNameNew;
    //                                                     }
    //                                                     console.log('chq');
    //                                                     console.log(this.hirePrintDto);
    //                                                     //Pending
    //                                                     // this.HireSlipAdvancePaymentVoucher
    //                                                     this.paymentVoucher();

    //                                                     if (parseInt(pdcTotAmt) > 0) {

    //                                                         swal({
    //                                                             title: "Payment Confirmation",
    //                                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                                             icon: "warning",
    //                                                             closeOnClickOutside: false,
    //                                                             closeOnEsc: false,
    //                                                             buttons: ["No", "Yes"],
    //                                                         }).then((cheque) => {
    //                                                             if (cheque) {
    //                                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                                 if (this.hirePrintDto.column1 != null) {
    //                                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                                 }
    //                                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                                 }
    //                                                                 console.log('pdc');
    //                                                                 console.log(this.hirePrintDto);
    //                                                                 //Pending
    //                                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                                 this.paymentVoucher();
    //                                                             }
    //                                                         });


    //                                                     }
    //                                                 } else {
    //                                                     if (parseInt(pdcTotAmt) > 0) {

    //                                                         swal({
    //                                                             title: "Payment Confirmation",
    //                                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                                             icon: "warning",
    //                                                             closeOnClickOutside: false,
    //                                                             closeOnEsc: false,
    //                                                             buttons: ["No", "Yes"],
    //                                                         }).then((cheque) => {
    //                                                             if (cheque) {
    //                                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                                 if (this.hirePrintDto.column1 != null) {
    //                                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                                 }
    //                                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                                 }
    //                                                                 console.log('pdc');
    //                                                                 console.log(this.hirePrintDto);
    //                                                                 //Pending
    //                                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                                 this.paymentVoucher();
    //                                                             }
    //                                                         });


    //                                                     }
    //                                                 }
    //                                             });


    //                                         } else {
    //                                             if (parseInt(pdcTotAmt) > 0) {

    //                                                 swal({
    //                                                     title: "Payment Confirmation",
    //                                                     text: "Sure U Want To Print the Pdc Voucher",
    //                                                     icon: "warning",
    //                                                     closeOnClickOutside: false,
    //                                                     closeOnEsc: false,
    //                                                     buttons: ["No", "Yes"],
    //                                                 }).then((cheque) => {
    //                                                     if (cheque) {
    //                                                         this.hirePrintDto.paid = pdcTotAmt;
    //                                                         this.hirePrintDto.mode = 'pdcCheque';
    //                                                         if (this.hirePrintDto.column1 != null) {
    //                                                             var pdcChequeNo = this.hirePrintDto.column1;
    //                                                             var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                             this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                         }
    //                                                         if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                             var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                             var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                             this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                         }
    //                                                         console.log('pdc');
    //                                                         console.log(this.hirePrintDto);
    //                                                         //Pending
    //                                                         // this.HireSlipAdvancePaymentVoucher
    //                                                         this.paymentVoucher();
    //                                                     }
    //                                                 });


    //                                             }
    //                                         }
    //                                     }

    //                                 });


    //                             } else {
    //                                 //Pending
    //                                 this.paymentCalculation(cashTotAmt);

    //                                 if (parseInt(chequeTotAmt) > 0) {
    //                                     console.log('chq');

    //                                     swal({
    //                                         title: "Payment Confirmation",
    //                                         text: "Sure U Want To Print the Cheque Voucher",
    //                                         icon: "warning",
    //                                         closeOnClickOutside: false,
    //                                         closeOnEsc: false,
    //                                         buttons: ["No", "Yes"],
    //                                     }).then((cheque) => {
    //                                         if (cheque) {
    //                                             this.hirePrintDto.paid = chequeTotAmt;
    //                                             this.hirePrintDto.mode = 'cheque';
    //                                             if (this.hirePrintDto.chequeno != null) {
    //                                                 var chequeNo = this.hirePrintDto.chequeno;
    //                                                 var chequeNoNew = chequeNo.replace('#', ',');
    //                                                 this.hirePrintDto.chequeno = chequeNoNew;
    //                                             }
    //                                             if (this.hirePrintDto.advChqBankName != null) {
    //                                                 var cheqBankName = this.hirePrintDto.advChqBankName;
    //                                                 var cheqBankNameNew = cheqBankName.replace('#', ',');
    //                                                 this.hirePrintDto.advChqBankName = cheqBankNameNew;
    //                                             }
    //                                             console.log('chq');
    //                                             console.log(this.hirePrintDto);
    //                                             //Pending
    //                                             // this.HireSlipAdvancePaymentVoucher
    //                                             this.paymentVoucher();

    //                                             if (parseInt(pdcTotAmt) > 0) {

    //                                                 swal({
    //                                                     title: "Payment Confirmation",
    //                                                     text: "Sure U Want To Print the Pdc Voucher",
    //                                                     icon: "warning",
    //                                                     closeOnClickOutside: false,
    //                                                     closeOnEsc: false,
    //                                                     buttons: ["No", "Yes"],
    //                                                 }).then((cheque) => {
    //                                                     if (cheque) {
    //                                                         this.hirePrintDto.paid = pdcTotAmt;
    //                                                         this.hirePrintDto.mode = 'pdcCheque';
    //                                                         if (this.hirePrintDto.column1 != null) {
    //                                                             var pdcChequeNo = this.hirePrintDto.column1;
    //                                                             var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                             this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                         }
    //                                                         if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                             var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                             var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                             this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                         }
    //                                                         console.log('pdc');
    //                                                         console.log(this.hirePrintDto);
    //                                                         //Pending
    //                                                         // this.HireSlipAdvancePaymentVoucher
    //                                                         this.paymentVoucher();
    //                                                     }
    //                                                 });


    //                                             }
    //                                         } else {
    //                                             if (parseInt(pdcTotAmt) > 0) {

    //                                                 swal({
    //                                                     title: "Payment Confirmation",
    //                                                     text: "Sure U Want To Print the Pdc Voucher",
    //                                                     icon: "warning",
    //                                                     closeOnClickOutside: false,
    //                                                     closeOnEsc: false,
    //                                                     buttons: ["No", "Yes"],
    //                                                 }).then((cheque) => {
    //                                                     if (cheque) {
    //                                                         this.hirePrintDto.paid = pdcTotAmt;
    //                                                         this.hirePrintDto.mode = 'pdcCheque';
    //                                                         if (this.hirePrintDto.column1 != null) {
    //                                                             var pdcChequeNo = this.hirePrintDto.column1;
    //                                                             var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                             this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                         }
    //                                                         if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                             var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                             var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                             this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                         }
    //                                                         console.log('pdc');
    //                                                         console.log(this.hirePrintDto);
    //                                                         //Pending
    //                                                         // this.HireSlipAdvancePaymentVoucher
    //                                                         this.paymentVoucher();
    //                                                     }
    //                                                 });


    //                                             }
    //                                         }
    //                                     });


    //                                 } else {
    //                                     if (parseInt(pdcTotAmt) > 0) {

    //                                         swal({
    //                                             title: "Payment Confirmation",
    //                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                             icon: "warning",
    //                                             closeOnClickOutside: false,
    //                                             closeOnEsc: false,
    //                                             buttons: ["No", "Yes"],
    //                                         }).then((cheque) => {
    //                                             if (cheque) {
    //                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                 if (this.hirePrintDto.column1 != null) {
    //                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                 }
    //                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                 }
    //                                                 console.log('pdc');
    //                                                 console.log(this.hirePrintDto);
    //                                                 //Pending
    //                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                 this.paymentVoucher();
    //                                             }
    //                                         });


    //                                     }
    //                                 }

    //                             }

    //                         } else if (parseInt(chequeTotAmt) > 0) {
    //                             console.log('chq');

    //                             swal({
    //                                 title: "Payment Confirmation",
    //                                 text: "Sure U Want To Print the Cheque Voucher",
    //                                 icon: "warning",
    //                                 closeOnClickOutside: false,
    //                                 closeOnEsc: false,
    //                                 buttons: ["No", "Yes"],
    //                             }).then((cheque) => {
    //                                 if (cheque) {
    //                                     this.hirePrintDto.paid = chequeTotAmt;
    //                                     this.hirePrintDto.mode = 'cheque';
    //                                     if (this.hirePrintDto.chequeno != null) {
    //                                         var chequeNo = this.hirePrintDto.chequeno;
    //                                         var chequeNoNew = chequeNo.replace('#', ',');
    //                                         this.hirePrintDto.chequeno = chequeNoNew;
    //                                     }
    //                                     if (this.hirePrintDto.advChqBankName != null) {
    //                                         var cheqBankName = this.hirePrintDto.advChqBankName;
    //                                         var cheqBankNameNew = cheqBankName.replace('#', ',');
    //                                         this.hirePrintDto.advChqBankName = cheqBankNameNew;
    //                                     }
    //                                     console.log('chq');
    //                                     console.log(this.hirePrintDto);
    //                                     //Pending
    //                                     // this.HireSlipAdvancePaymentVoucher
    //                                     this.paymentVoucher();

    //                                     if (parseInt(pdcTotAmt) > 0) {

    //                                         swal({
    //                                             title: "Payment Confirmation",
    //                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                             icon: "warning",
    //                                             closeOnClickOutside: false,
    //                                             closeOnEsc: false,
    //                                             buttons: ["No", "Yes"],
    //                                         }).then((cheque) => {
    //                                             if (cheque) {
    //                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                 if (this.hirePrintDto.column1 != null) {
    //                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                 }
    //                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                 }
    //                                                 console.log('pdc');
    //                                                 console.log(this.hirePrintDto);
    //                                                 //Pending
    //                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                 this.paymentVoucher();
    //                                             }
    //                                         });


    //                                     }
    //                                 } else {
    //                                     if (parseInt(pdcTotAmt) > 0) {

    //                                         swal({
    //                                             title: "Payment Confirmation",
    //                                             text: "Sure U Want To Print the Pdc Voucher",
    //                                             icon: "warning",
    //                                             closeOnClickOutside: false,
    //                                             closeOnEsc: false,
    //                                             buttons: ["No", "Yes"],
    //                                         }).then((cheque) => {
    //                                             if (cheque) {
    //                                                 this.hirePrintDto.paid = pdcTotAmt;
    //                                                 this.hirePrintDto.mode = 'pdcCheque';
    //                                                 if (this.hirePrintDto.column1 != null) {
    //                                                     var pdcChequeNo = this.hirePrintDto.column1;
    //                                                     var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                                     this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                                 }
    //                                                 if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                                     var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                                     var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                                     this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                                 }
    //                                                 console.log('pdc');
    //                                                 console.log(this.hirePrintDto);
    //                                                 //Pending
    //                                                 // this.HireSlipAdvancePaymentVoucher
    //                                                 this.paymentVoucher();
    //                                             }
    //                                         });


    //                                     }
    //                                 }
    //                             });


    //                         } else if (parseInt(pdcTotAmt) > 0) {

    //                             swal({
    //                                 title: "Payment Confirmation",
    //                                 text: "Sure U Want To Print the Pdc Voucher",
    //                                 icon: "warning",
    //                                 closeOnClickOutside: false,
    //                                 closeOnEsc: false,
    //                                 buttons: ["No", "Yes"],
    //                             }).then((cheque) => {
    //                                 if (cheque) {
    //                                     this.hirePrintDto.paid = pdcTotAmt;
    //                                     this.hirePrintDto.mode = 'pdcCheque';
    //                                     if (this.hirePrintDto.column1 != null) {
    //                                         var pdcChequeNo = this.hirePrintDto.column1;
    //                                         var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
    //                                         this.hirePrintDto.column1 = pdcChequeNoNew;
    //                                     }
    //                                     if (this.hirePrintDto.advPdcChqBankName != null) {
    //                                         var pdcBankName = this.hirePrintDto.advPdcChqBankName;
    //                                         var pdcBankNameNew = pdcBankName.replace('#', ',');
    //                                         this.hirePrintDto.advChqBankName = pdcBankNameNew;
    //                                     }
    //                                     console.log('pdc');
    //                                     console.log(this.hirePrintDto);
    //                                     //Pending
    //                                     // this.HireSlipAdvancePaymentVoucher
    //                                     this.paymentVoucher();
    //                                 }
    //                             });


    //                         }




    //                     }
    //                 });
    //             } else if (response.status == 'Not Found') {
    //                 swal("Error", "Hireslip number not Found", "info");
    //             } else if (response.status == 'Failed') {
    //                 swal("Error", "Server Problem Occurred While Saving The Details", "info");
    //             }
    //             this.changeDetectorRef.detectChanges();

    //         }), (error) => {
    //             this.showSpinnerForAction = false;
    //             swal("Error", "Server Problem Occurred While Saving The Details", "info");
    //         },
    //         () => console.log('done');
    // };

    saveLryAdvance(cashTotAmt, chequeTotAmt, pdcTotAmt) {
        console.log(this.hireslipDtoForSave);
        this.showSpinnerForAction = true;
        this.hireslipService.lryHireAdvPayment(this.hireslipDtoForSave).subscribe(
            (response) => {

                console.log(response);
                this.showSpinnerForAction = false;
                this.validatePayingDateForSave = $("#"+this.pageId+"payingDates").val();
                if (response.status == 'Success') {
                    this.hirePrintDto.paymentDate = this.validatePayingDateForSave;
                    this.clearOverAllField();
                    swal({
                        title: "Payment Print",
                        text: "Sure U Want To Print",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((paymentPrint) => {
                        if (paymentPrint) {
                            if (parseInt(cashTotAmt) > 0) {
                                console.log('cash');

                                if (parseInt(cashTotAmt) <= 20000) {
                                    // alert("inside of if cashTotAmt) <= 20000 ");
                                    this.cashPrint(cashTotAmt, chequeTotAmt, pdcTotAmt);
                                } else {
                                    // alert("inside of else of cashTotAmt) <= 20000 ");
                                    this.paymentCalculation(cashTotAmt, chequeTotAmt, pdcTotAmt);
                                }
                            } else if (parseInt(chequeTotAmt) > 0) {
                                // alert("inside of else if chequeTotAmt) > 0 ");
                                this.chequePrint(chequeTotAmt, pdcTotAmt);

                            } else if (parseInt(pdcTotAmt) > 0) {
                                // alert("inside of else if pdcTotAmt) > 0 ");
                                this.pdcPrint(pdcTotAmt)
                            }

                        }
                    });
                } else if (response.status == 'Not Found') {
                    swal("Error", "Hireslip number not Found", "info");
                } else if (response.status == 'Failed') {
                    swal("Error", "Server Problem Occurred While Saving The Details", "info");
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Saving The Details", "info");
            },
            () => console.log('done');
    };

    paymentCalculation(sum, chequeTotAmt, pdcTotAmt) {

        var lastValue = 20001, firstValve = 0, iterValve = 2;
        var finalValueMin = 0, finalValueMax = 0;
        var resultValue = 0.0, finalValue = 0.0, maxIterValue = 0.0, finalResult2 = 0.0;
        var finalResult = null;
        for (let i = 0; i < 20; i++) {
            let z = i * 20000;
            firstValve = lastValue;
            lastValue = z;
            maxIterValue = (iterValve++);
            if ((sum >= firstValve) && (sum <= lastValue)) {
                resultValue = maxIterValue;
                break;
            }
            lastValue = lastValue + 1;
        }
        console.log(sum, resultValue);
        finalValue = sum / resultValue;
        var j = 0;
        var x = 0;
        console.log(finalValue);
        if (resultValue > 1) {
            for (let j = 0; j < resultValue - 1; j++) {
                x++;
                console.log(x);
                console.log(finalValue);
                finalValueMin = Math.round(finalValue);
                finalValueMax = finalValueMax + finalValueMin;
                console.log(finalValueMin, finalValueMax);
                finalResult = finalValueMin + "";
                console.log(finalResult);
                this.valueMin = finalResult;
                swal({
                    title: "Payment Confirmation",
                    text: "Sure U Want To Print the Cash Voucher-" + (x),
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((cashAmt) => {
                    if (cashAmt) {
                        this.hirePrintDto.paid = this.valueMin;
                        this.hirePrintDto.mode = 'cash';
                        console.log('cash');
                        console.log(this.hirePrintDto);
                        //Pending
                        // this.HireSlipAdvancePaymentVoucher
                        this.paymentVoucher(chequeTotAmt, pdcTotAmt);
                    } else {
                        if (parseInt(chequeTotAmt) > 0) {
                            this.chequePrint(chequeTotAmt, pdcTotAmt);
                        } else if (parseInt(pdcTotAmt) > 0) {
                            this.pdcPrint(pdcTotAmt);
                        }
                    }
                });
            }
            if (j == resultValue - 1) {
                finalResult2 = sum - finalValueMax;
                this.valueMax = finalResult2;

                swal({
                    title: "Payment Confirmation",
                    text: "Sure U Want To Print the Cash Voucher",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((cashAmt) => {
                    if (cashAmt) {
                        this.hirePrintDto.paid = this.valueMax;
                        this.hirePrintDto.mode = 'cash';
                        console.log('cash');
                        console.log(this.hirePrintDto);
                        //Pending
                        // this.HireSlipAdvancePaymentVoucher
                        this.paymentVoucher(chequeTotAmt, pdcTotAmt);
                    } else {
                        if (parseInt(chequeTotAmt) > 0) {
                            this.chequePrint(chequeTotAmt, pdcTotAmt);
                        } else if (parseInt(pdcTotAmt) > 0) {
                            this.pdcPrint(pdcTotAmt);
                        }
                    }
                });


            }


        } else {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Print the Cash Voucher",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((cashAmt) => {
                if (cashAmt) {
                    this.hirePrintDto.paid = sum;
                    this.hirePrintDto.mode = 'cash';
                    console.log('cash');
                    console.log(this.hirePrintDto);
                    //Pending
                    // this.HireSlipAdvancePaymentVoucher
                    this.paymentVoucher(chequeTotAmt, pdcTotAmt);
                } else {
                    if (parseInt(chequeTotAmt) > 0) {
                        this.chequePrint(chequeTotAmt, pdcTotAmt);
                    } else if (parseInt(pdcTotAmt) > 0) {
                        this.pdcPrint(pdcTotAmt);
                    }
                }
            });

        }
    }
    validateTDSPaymentBtn(){
     
        localStorage.clear();
        console.log(this.hirePrintDto);
        localStorage.setItem('tdsVoucherPrint', JSON.stringify( this.localStorageOfPaymentData));
        // this.viewTdsVoucher=false;
        // this.viewPaymentVoucher = false;
        this.viewTdsVoucher=true;
        // window.addEventListener('afterPopUp', (event) => {
        //     console.log('chekc');
        //     this.viewTdsVoucher = false;
        // });
        window.addEventListener('afterprint', (onclick) => {
            console.log('chekc');
            this.viewTdsVoucher = false;
        });
        // this.changeDetectorRef.detectChanges();
    }
paymentVoucher(chequeTotAmt, pdcTotAmt){
    localStorage.clear();
    console.log(this.hirePrintDto);
    localStorage.setItem('paymentVoucherPrint', JSON.stringify( this.hirePrintDto));
    // this.viewPaymentVoucher = false;
    // this.viewTdsVoucher=false;
    this.viewPaymentVoucher=true;
    // window.addEventListener('afterPopUp', (event) => {
    // 	alert('ok');
    //     this.viewPaymentVoucher = false;
    // });
    window.addEventListener('afterprint', (onclick) => {
        if (this.viewPaymentVoucher) {
            console.log('chekc');
            this.viewPaymentVoucher = false;
            if (parseInt(chequeTotAmt) > 0) {
                this.chequePrint(chequeTotAmt, pdcTotAmt);
            }
        }
        
    });
    // this.changeDetectorRef.detectChanges();
}

cashPrint(cashTotAmt, chequeTotAmt, pdcTotAmt){
    swal({
        title: "Payment Confirmation",
        text: "Sure U Want To Print the Cash Voucher",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
    }).then((cash) => {
        if (cash) {
            this.hirePrintDto.paid = cashTotAmt;
            this.hirePrintDto.mode = 'cash';
            console.log('cash');
            console.log(this.hirePrintDto);
            //Pending
            // this.HireSlipAdvancePaymentVoucher
            localStorage.clear();
            console.log(this.hirePrintDto);
            localStorage.setItem('paymentVoucherPrint', JSON.stringify( this.hirePrintDto));
            this.viewPaymentVoucher=true;
            window.addEventListener('afterprint', (onclick) => {
                console.log('chekc');
                if (this.viewPaymentVoucher) {
                    this.viewPaymentVoucher = false;
                    if (parseInt(chequeTotAmt) > 0) {
                        this.chequePrint(chequeTotAmt, pdcTotAmt);
                    }
                }
                
            });
        } else {
            if (parseInt(chequeTotAmt) > 0) {
                this.chequePrint(chequeTotAmt, pdcTotAmt);
            }
        }
        // this.changeDetectorRef.detectChanges();    
    });
}

chequePrint(chequeTotAmt, pdcTotAmt){
    swal({
        title: "Payment Confirmation",
        text: "Sure U Want To Print the Cheque Voucher",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
    }).then((cheque) => {
        if (cheque) {
            this.hirePrintDto.paid = chequeTotAmt;
            this.hirePrintDto.mode = 'cheque';
            if (this.hirePrintDto.chequeno != null) {
                var chequeNo = this.hirePrintDto.chequeno;
                var chequeNoNew = chequeNo.replace('#', ',');
                this.hirePrintDto.chequeno = chequeNoNew;
            }
            if (this.hirePrintDto.advChqBankName != null) {
                var cheqBankName = this.hirePrintDto.advChqBankName;
                var cheqBankNameNew = cheqBankName.replace('#', ',');
                this.hirePrintDto.advChqBankName = cheqBankNameNew;
            }
            console.log('chq');
            console.log(this.hirePrintDto);
            localStorage.clear();
            console.log(this.hirePrintDto);
            localStorage.setItem('paymentVoucherPrint', JSON.stringify( this.hirePrintDto));
            this.viewPaymentVoucherChq=true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewPaymentVoucherChq) {
                    console.log('chekc');
                    this.viewPaymentVoucherChq = false;
                    if (parseInt(pdcTotAmt) > 0) {
                        this.pdcPrint(pdcTotAmt);
                    }
                }
                
            });
            
        } else {
            if (parseInt(pdcTotAmt) > 0) {
                this.pdcPrint(pdcTotAmt);
                }
        }
        // this.changeDetectorRef.detectChanges();
    });

}
pdcPrint(pdcTotAmt){
    swal({
        title: "Payment Confirmation",
        text: "Sure U Want To Print the Pdc Voucher",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
    }).then((pdc) => {
        if (pdc) {
                this.hirePrintDto.paid = pdcTotAmt;
                this.hirePrintDto.mode = 'pdcCheque';
                if (this.hirePrintDto.column1 != null) {
                    var pdcChequeNo = this.hirePrintDto.column1;
                    var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
                    this.hirePrintDto.column1 = pdcChequeNoNew;
                }
                if (this.hirePrintDto.advPdcChqBankName != null) {
                    var pdcBankName = this.hirePrintDto.advPdcChqBankName;
                    var pdcBankNameNew = pdcBankName.replace('#', ',');
                    this.hirePrintDto.advChqBankName = pdcBankNameNew;
                }
                console.log('pdc');
                console.log(this.hirePrintDto);
                localStorage.clear();
                console.log(this.hirePrintDto);
                localStorage.setItem('paymentVoucherPrint', JSON.stringify( this.hirePrintDto));
                this.viewPaymentVoucherPdc=true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewPaymentVoucherPdc) {
                        console.log('chekc');
                        this.viewPaymentVoucherPdc = false;
                        // if (parseInt(chequeTotAmt) > 0) {
                        //     this.chequePrint(pdcTotAmt);
                        // }
                    }
                    
                    
                });
                
            }
            // this.changeDetectorRef.detectChanges();
        });
    }
}