export class AgentDetailsDto {
    agentName: string;
    address: string;
    city: string;
    state: string;
    phoneNo: string;
    emailAddress: string;
    contactPerson: string;
    mode: string;
    mainStation: string;
    subStation: string;
    subStatAddress: string;
    subStatContPers: string;
    subStatPhoneNum: string;
    userName: string;
    unitCrossChg: string;
    unitHamaliChg: string;
    crossingChg: number;
    hamaliChg: number;
    stationType: string;
    count: number;
    totalCrossingChg: number;
    status: string;
    sourceStation: string;
    destType: string;
    officeType: string;
    routingType: string;
    sundayWork: string;
    column1: string;
    column2: string;
    column3: string;
    column4: string;
    column5: string;
    agentCode: string;
    balanceAmount: number;
    mainBookStations: string;
    fovPerc: number;
    companyId: string;
    unitCrossChgMain: string;
    unitHamaliChgMain: string;
    crossingChgMain: number;
    hamaliChgMain: number;
    subStationGDChgUnit: string;
    subStationGDChg: number;
    agent: string;
    source: string;
    selected: boolean;
    lat: string;
    lng: string;
}