import { Component, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
    selector: 'app-booking-agent-statement-details',
    templateUrl: './booking-agent-statement-details.component.html',
    styleUrls: ['./booking-agent-statement-details.component.css']
})
export class BookingAgentStatementDetailsComponent implements OnInit {

    lrDetailsDataList: any;
    additionDatatableDataList: any;
    deductionDatatableDataList: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dtTriggerAdditionDatatable: Subject<any> = new Subject();
    dtTriggerDeductionDatatable: Subject<any> = new Subject();
    dtOptionsLrDetails: any;
    dtOptionAdditionDatatable: any;
    dtOptionDeductionDatatable: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    rateMasterDtoSelectedData: RateMasterDto = new RateMasterDto();
    lrDtoUserValues: LRDto = new LRDto();

    rateMasterDtoUpdate: RateMasterDto = new RateMasterDto();
    deductionDatatableDataListUpdated: any;
    additionDatatableDataListUpdated: any;
    rateMasterDtoAddDed: RateMasterDto = new RateMasterDto();
    rateMasterDtoReturn: RateMasterDto = new RateMasterDto();
    toBePaidAmt: any;
    descReadOnly = false;
    enteredDesc: any;
    enteredValue: any;
    descIsSelectedAdd = false;
    selectedIndexAdd: any;
    descIsSelectedDed = false;
    selectedIndexDed: any;
    totAddition = null;
    totDed = null;

    fortNightStmtDtoPrint: FortNightStmtDto = new FortNightStmtDto();
    viewStatementRptLRDetlsPrint = false;
    viewBkgAgentPrintInPopUp = false;

    modalRefferencePrintPreviewPopUp: NgbModalRef;
    modalRefferencePrintPreviewClosePopUp: string;
    //print
    ////
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    actWgtSummary: number;
    chgWgtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    lcChgSummary: number;
    bcChgSummary: number;
    totalArtSummary: number;
    incentiveSummary: number;
    penalitySummary: number;
    viewCustomPrintV1 = false;

    @ViewChild('printPreviewPopUpTemplate') private printPreviewPopUpTemplate;
    pageId = "basdc";

    constructor(private router: Router, private destStmtReport: DestStmtReport,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {

            this.rateMasterDtoSelectedData = new RateMasterDto();
            this.rateMasterDtoSelectedData = JSON.parse(localStorage.getItem('bookingAgentStatementDataLocalStorage'));
            this.additionDatatableDataList = [];
            this.additionDatatableDataList = this.rateMasterDtoSelectedData.rateMasterDtos;
            this.deductionDatatableDataList = [];
            this.deductionDatatableDataList = this.rateMasterDtoSelectedData.rateMasterDtos1;
            this.toBePaidAmt = this.rateMasterDtoSelectedData.toBePaidAmt;
            this.totalAmtToBePaid();
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
                this.getBookiingAgentPendingStmtDetails();
                this.changeDetectorRef.detectChanges();
            }, 500);

        }

    }

    ngOnInit(): void {
        //first datatable starts 
        var stmtNoPrint = this.rateMasterDtoSelectedData.stmtId;
        var destinationPrint = this.rateMasterDtoSelectedData.agentName;
        var fromPeriodPrint = this.rateMasterDtoSelectedData.fromPeriodStr;
        var toPeriodPrint = this.rateMasterDtoSelectedData.toPeriodStr;

        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            //place holder in search/filter in datatable starts
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel LR Details</i>',
                    titleAttr: 'Excel LR Details',
                    footer: true,
                    title: function () {
                        return "Booking Statement Report LR Details" + " " +
                            "From Date : " + fromPeriodPrint + " -  " +
                            "To Date : " + toPeriodPrint + " - " +
                            "Destination : " + destinationPrint + " " +
                            "Statement No : " + stmtNoPrint + "";

                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalArt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalActWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalchgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalTopay = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalpaid = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalLC = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalBc = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalDispatchedwithIn = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalIncentiveAmt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalPenaltyAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(totalArt);
                $(api.column(5).footer()).html(totalActWgt);
                $(api.column(6).footer()).html(totalchgWgt);
                $(api.column(7).footer()).html(totalTopay);
                $(api.column(8).footer()).html(totalpaid);
                $(api.column(9).footer()).html(totalLC);
                $(api.column(10).footer()).html(totalBc);
                $(api.column(13).footer()).html(totalDispatchedwithIn);
                $(api.column(14).footer()).html(totalIncentiveAmt);
                $(api.column(15).footer()).html(totalPenaltyAmt);
            }
        },
            this.dtOptionAdditionDatatable = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(total);
                }
            },
            this.dtOptionDeductionDatatable = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(total);
                }
            }

    }

    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
        this.dtTriggerAdditionDatatable.unsubscribe();
        this.dtTriggerDeductionDatatable.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();
        this.dtTriggerAdditionDatatable.next();
        this.dtTriggerDeductionDatatable.next();
    }

    getUserValues() {
        this.lrDtoUserValues = new LRDto();
        this.lrDtoUserValues.fortNightStmtNo = this.rateMasterDtoSelectedData.stmtId;
        this.lrDtoUserValues.companyId = this.userDataDtoReturnSession.companyId;
    }

    getBookiingAgentPendingStmtDetails = () => {
        this.showSpinnerForAction = true;
        this.getUserValues();
        this.destStmtReport.getLrDetailsForBkgAgentStmt(this.lrDtoUserValues).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "bkgAgentStmtDetailsId").DataTable().destroy();
                this.lrDetailsDataList = [];
                if (response.length == 0) {
                    swal("Warning", "No Details found!", "warning");
                } else {
                    this.lrDetailsDataList = response;
                    console.log(this.lrDetailsDataList);
                }
                setTimeout(() => {
                    this.dtTriggerLrDetails.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting LR Details For Bkg Agent Stmt", "info");
            }, () => console.log('done');
    };



    getAdditionValues() {
        this.additionDatatableDataListUpdated = [];
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.rateMasterDtoAddDed = new RateMasterDto();
            this.rateMasterDtoAddDed.description = this.additionDatatableDataList[i].description;
            this.rateMasterDtoAddDed.totalAmount = this.additionDatatableDataList[i].totalAmount;
            this.additionDatatableDataListUpdated.push(this.rateMasterDtoAddDed);
        }
        return this.additionDatatableDataListUpdated;
    }

    getDeductionValues() {
        this.deductionDatatableDataListUpdated = [];
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.rateMasterDtoAddDed = new RateMasterDto();
            this.rateMasterDtoAddDed.description = this.deductionDatatableDataList[i].description;
            this.rateMasterDtoAddDed.totalAmount = this.deductionDatatableDataList[i].totalAmount;
            this.deductionDatatableDataListUpdated.push(this.rateMasterDtoAddDed);
        }
        return this.deductionDatatableDataListUpdated;
    }

    updateStmtUserValues(mode) {
        this.rateMasterDtoUpdate = new RateMasterDto();
        this.rateMasterDtoUpdate.mode = mode;
        this.rateMasterDtoUpdate.agentName = this.rateMasterDtoSelectedData.agentName;
        this.rateMasterDtoUpdate.fromDate = this.rateMasterDtoSelectedData.fromDate;
        this.rateMasterDtoUpdate.toDate = this.rateMasterDtoSelectedData.toDate;
        this.rateMasterDtoUpdate.stmtId = this.rateMasterDtoSelectedData.stmtId;
        this.rateMasterDtoUpdate.toBePaidAmt = this.toBePaidAmt;
        this.rateMasterDtoUpdate.status = "AmtPaid";
        this.rateMasterDtoUpdate.rateMasterDtos = this.getAdditionValues();
        this.rateMasterDtoUpdate.rateMasterDtos1 = this.getDeductionValues();
        this.rateMasterDtoUpdate.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    }

    saveBookingAgentStmtDetails(mode) {
        swal({
            title: "Confirm " + mode + "",
            text: "Sure U Want to " + mode + " this  Statement?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.saveUpdateStmtDetails(mode);
            }
        });
    }

    saveUpdateStmtDetails = (mode) => {
        this.updateStmtUserValues(mode);
        this.showSpinnerForAction = true;
        console.log(this.rateMasterDtoUpdate);
        this.destStmtReport.updateBkgAgntStmt(this.rateMasterDtoUpdate).subscribe((data) => {
            console.log(data);
            this.rateMasterDtoReturn = data;
            if (this.rateMasterDtoReturn.status == "success") {
                //swal("Success", "Booking Agent Statement Details Saved/Updated Successfully", "success");
                this.showSpinnerForAction = false;
                swal({
                    title: "Print Statement Report",
                    text: "Sure U Want to Print the Booking Agent Statement Report For " +
                        this.rateMasterDtoUpdate.agentName + "",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((sureYesBtn) => {
                    if (sureYesBtn) {
                        if (mode != null && mode == 'Finished') {
                            this.viewBkgAgentPrintInPopUp = false;
                            this.modalRefferencePrintPreviewPopUp.close();
                            this.printStmtReport();
                        } else {
                            this.printStmtReport();
                        }
                    } else {
                        // this.router.navigate(['/dashboard/classic']);
                        this.lrPrintPopup();
                    }
                });

            } else {
                this.showSpinnerForAction = false;
                swal("Success", "Error While Saving the Booking Agent Statement Details", "warning");
            }
            this.changeDetectorRef.detectChanges();
        }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving the Labour Statement Details", "info");
            }, () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rowSelectedEditAddition(additionDatatableData, index) {
        $("#" + this.pageId + "description").val(additionDatatableData.description);
        $("#" + this.pageId + "value").val(additionDatatableData.totalAmount);
        $("#" + this.pageId + "commonDescription").val("Addition");
        this.descReadOnly = true;
        this.descIsSelectedAdd = true;
        this.selectedIndexAdd = index;
    }
    rowSelectedEditDeduction(deductionDatatableData, index) {
        $("#" + this.pageId + "description").val(deductionDatatableData.description);
        $("#" + this.pageId + "value").val(deductionDatatableData.totalAmount);
        $("#" + this.pageId + "commonDescription").val("Deduction");
        this.descReadOnly = true;
        this.descIsSelectedDed = true;
        this.selectedIndexDed = index;
    }

    addInTable() {
        if (($("#" + this.pageId + "description").val() == null) || ($("#" + this.pageId + "description").val() == undefined) || ($("#" + this.pageId + "description").val() == "") ||
            ($("#" + this.pageId + "value").val() == null) || ($("#" + this.pageId + "value").val() == undefined) || ($("#" + this.pageId + "value").val() == "")) {
            swal("Mandatory Field", "Please enter the details to proceed!", "warning");
            return false;
        } else {

            if ($("#" + this.pageId + "commonDescription").val() == "Addition") {
                this.addInTableAddition();
            } else {
                this.addInTableDeduction();
            }
        }
    }

    addInTableAddition() {
        this.rateMasterDtoAddDed = new RateMasterDto();
        this.enteredDesc = $("#" + this.pageId + "description").val();
        this.enteredValue = $("#" + this.pageId + "value").val();
        if (this.descIsSelectedAdd == false) {
            for (let i = 0; i < this.additionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.additionDatatableDataList[i].description.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.rateMasterDtoAddDed.description = this.enteredDesc;
            this.rateMasterDtoAddDed.totalAmount = this.enteredValue;
            this.additionDatatableDataList.push(this.rateMasterDtoAddDed);

            $("#" + this.pageId + "additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.additionDatatableDataList.splice(this.selectedIndexAdd, 1);
            this.rateMasterDtoAddDed.description = this.enteredDesc;
            this.rateMasterDtoAddDed.totalAmount = this.enteredValue;
            this.additionDatatableDataList.push(this.rateMasterDtoAddDed);

            $("#" + this.pageId + "additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    addInTableDeduction() {
        this.rateMasterDtoAddDed = new RateMasterDto();
        this.enteredDesc = $("#" + this.pageId + "description").val();
        this.enteredValue = $("#" + this.pageId + "value").val();
        if (this.descIsSelectedDed == false) {
            for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.deductionDatatableDataList[i].description.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.rateMasterDtoAddDed.description = this.enteredDesc;
            this.rateMasterDtoAddDed.totalAmount = this.enteredValue;
            this.deductionDatatableDataList.push(this.rateMasterDtoAddDed);

            $("#" + this.pageId + "deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.deductionDatatableDataList.splice(this.selectedIndexDed, 1);
            this.rateMasterDtoAddDed.description = this.enteredDesc;
            this.rateMasterDtoAddDed.totalAmount = this.enteredValue;
            this.deductionDatatableDataList.push(this.rateMasterDtoAddDed);

            $("#" + this.pageId + "deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    rowSelectedDeleteAddition(additionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.additionDatatableDataList.splice(index, 1);
                $("#" + this.pageId + "additionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerAdditionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);

                this.changeDetectorRef.detectChanges();
            }
        });
    }

    rowSelectedDeleteDed(deductionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.deductionDatatableDataList.splice(index, 1);
                $("#" + this.pageId + "deductionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerDeductionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);

                this.changeDetectorRef.detectChanges();
            }
        });
    }

    totalAmtToBePaid() {
        this.totAddition = 0;
        this.totDed = 0;
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.totAddition += +this.additionDatatableDataList[i].totalAmount;
        }
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.totDed += +this.deductionDatatableDataList[i].totalAmount;
        }
        console.log(this.totAddition + -this.totDed);
        this.toBePaidAmt = this.totAddition + -this.totDed;
    }

    clearAddDedFields() {
        this.enteredDesc = null;
        this.enteredValue = null;
        $("#" + this.pageId + "description").val("");
        $("#" + this.pageId + "value").val("");
        $("#" + this.pageId + "commonDescription").val("Addition");
        this.descReadOnly = false;
        this.descIsSelectedAdd = false;
        this.selectedIndexAdd = null;
        this.descIsSelectedDed = false;
        this.selectedIndexDed = null;
        this.totAddition = 0;
        this.totDed = 0;
    }

    printStmtReport() {
        this.fortNightStmtDtoPrint = new FortNightStmtDto();
        this.fortNightStmtDtoPrint.agentName = this.rateMasterDtoSelectedData.agentName;
        this.fortNightStmtDtoPrint.stmtNo = this.rateMasterDtoSelectedData.stmtId;
        this.fortNightStmtDtoPrint.fromPeriod = this.rateMasterDtoSelectedData.fromDate;
        this.fortNightStmtDtoPrint.toPeriod = this.rateMasterDtoSelectedData.toDate;
        this.fortNightStmtDtoPrint.stmtDate = this.rateMasterDtoSelectedData.stmtDate;


        localStorage.clear();
        localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.additionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.deductionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.fortNightStmtDtoPrint));
        localStorage.setItem('clearLocalStorage', "No");
        this.viewStatementRptLRDetlsPrint = true;
        this.showSpinnerForAction = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStatementRptLRDetlsPrint) {
                this.viewStatementRptLRDetlsPrint = false;
                // localStorage.clear();
                this.lrPrintPopup();
            }
            this.showSpinnerForAction = false;
            // this.router.navigate(['/dashboard/classic']);
        });
        this.changeDetectorRef.detectChanges();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    stmtPreview(printPreviewPopUpTemplate) {
        this.modalRefferencePrintPreviewPopUp = this.modalService.open(printPreviewPopUpTemplate,
            { centered: true, size: "lg" });
        this.modalRefferencePrintPreviewPopUp.result.then((result) => {
            this.modalRefferencePrintPreviewClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferencePrintPreviewClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });


        this.fortNightStmtDtoPrint = new FortNightStmtDto();
        this.fortNightStmtDtoPrint.agentName = this.rateMasterDtoSelectedData.agentName;
        this.fortNightStmtDtoPrint.stmtNo = this.rateMasterDtoSelectedData.stmtId;
        this.fortNightStmtDtoPrint.fromPeriod = this.rateMasterDtoSelectedData.fromDate;
        this.fortNightStmtDtoPrint.toPeriod = this.rateMasterDtoSelectedData.toDate;
        this.fortNightStmtDtoPrint.stmtDate = this.rateMasterDtoSelectedData.stmtDate;

        localStorage.clear();
        localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.additionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.deductionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.fortNightStmtDtoPrint));

        localStorage.setItem('bookingAgentStmtDashboard', JSON.stringify("bookingAgentStmtDashboard"));

        this.viewBkgAgentPrintInPopUp = true;
    }

    printPopUpBtn() {
        this.modalRefferencePrintPreviewPopUp.close();
        this.viewBkgAgentPrintInPopUp = false;

        this.fortNightStmtDtoPrint = new FortNightStmtDto();
        this.fortNightStmtDtoPrint.agentName = this.rateMasterDtoSelectedData.agentName;
        this.fortNightStmtDtoPrint.stmtNo = this.rateMasterDtoSelectedData.stmtId;
        this.fortNightStmtDtoPrint.fromPeriod = this.rateMasterDtoSelectedData.fromDate;
        this.fortNightStmtDtoPrint.toPeriod = this.rateMasterDtoSelectedData.toDate;
        this.fortNightStmtDtoPrint.stmtDate = this.rateMasterDtoSelectedData.stmtDate;


        localStorage.clear();
        localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.additionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.deductionDatatableDataList));
        localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.fortNightStmtDtoPrint));

        this.viewStatementRptLRDetlsPrint = true;
        this.showSpinnerForAction = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStatementRptLRDetlsPrint) {
                this.viewStatementRptLRDetlsPrint = false;
                localStorage.clear();
            }
            this.showSpinnerForAction = false;
            this.router.navigate(['/dashboard/classic']);
        });
    }

    closePopUpBtn() {
        this.viewBkgAgentPrintInPopUp = false;
        this.modalRefferencePrintPreviewPopUp.close();
        //this.router.navigate(['/dashboard/classic']);
    }


    lrPrintPopup() {
        swal({
            title: "Print LR Details",
            text: "Do U Want to Print the Booking Agent Statement LR Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((printData) => {
            if (printData) {

                if (this.modalRefferencePrintPreviewPopUp != undefined) {
                    this.modalRefferencePrintPreviewPopUp.close();
                }
                this.viewBkgAgentPrintInPopUp = false;
                // this.modalRefferencePrintPreviewPopUp.close();
                this.customPrintBkgAgentStmtLr();
            } else {

                if (this.modalRefferencePrintPreviewPopUp != undefined) {
                    this.modalRefferencePrintPreviewPopUp.close();
                }
                localStorage.clear();
                this.viewBkgAgentPrintInPopUp = false;
                // this.modalRefferencePrintPreviewPopUp.close();
                this.router.navigate(['/dashboard/classic']);
            }
        });
        this.changeDetectorRef.detectChanges();
    }

    customPrintBkgAgentStmtLr() {
        if (this.lrDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            console.log("this.lrDetailsDataList in Print");
            console.log(this.lrDetailsDataList);
            this.cashMemoDtoForCustomPrintList = [];
            // this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Source", "Destination", "Commodity", "A.Wt", "C.Wt", "ToPay", "Paid", "LC", "BC", "Bkg Date", "Rec Date", "Diff", "Ince", "R.Comm"];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Source", "Destination", "Commodity", "Article", "A.Wt", "C.Wt", "ToPay", "Paid", "LC", "BC"];
            // this.cashMemoDtoForCustomPrintListColumnWidths = [9, 10, 10, 16, 5, 5, 6, 4, 4, 3, 8, 8, 4, 4, 4];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 20, 6, 6, 10, 9, 9, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.actWgtSummary = this.chgWgtSummary = this.toPaySummary = this.paidSummary = this.lcChgSummary = this.bcChgSummary = this.incentiveSummary = this.penalitySummary = this.totalArtSummary = 0;
            for (let i = 0; i < this.lrDetailsDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.lrDetailsDataList[i].lrNumber,
                    this.lrDetailsDataList[i].source,
                    this.lrDetailsDataList[i].destination,
                    this.lrDetailsDataList[i].description,
                    this.lrDetailsDataList[i].totalArticles,
                    this.lrDetailsDataList[i].actualWeight,
                    this.lrDetailsDataList[i].chargedWeight,
                    this.lrDetailsDataList[i].toPay,
                    this.lrDetailsDataList[i].paid,
                    this.lrDetailsDataList[i].lcChg,
                    this.lrDetailsDataList[i].bcChg,

                ];

                this.actWgtSummary = this.actWgtSummary + this.lrDetailsDataList[i].actualWeight;
                this.chgWgtSummary = this.chgWgtSummary + this.lrDetailsDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.lrDetailsDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.lrDetailsDataList[i].paid;
                this.lcChgSummary = this.lcChgSummary + this.lrDetailsDataList[i].lcChg;
                this.bcChgSummary = this.bcChgSummary + this.lrDetailsDataList[i].bcChg;

                this.totalArtSummary = this.totalArtSummary + this.lrDetailsDataList[i].totalArticles;
                // this.incentiveSummary = this.incentiveSummary + this.lrDetailsDataList[i].incentiveAmt;
                // this.penalitySummary = this.penalitySummary + this.lrDetailsDataList[i].penaltyAmt;
                //by kamil to round of the 
                // console.log("this.incentiveSummary, this.penalitySummary");
                // console.log(this.incentiveSummary, this.penalitySummary);
                // let incentiveSummaryRoundOff = this.incentiveSummary == null ? 0 : this.incentiveSummary;
                // let penalitySummaryRoundOff  = this.penalitySummary == null ? 0 : this.penalitySummary;
                // console.log("this.incentiveSummary, this.penalitySummary after Math Round >> ");
                // console.log(incentiveSummaryRoundOff, penalitySummaryRoundOff);
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                //this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrDetailsDataList.length, "", "", "",
                //     this.actWgtSummary, this.chgWgtSummary, this.toPaySummary, this.paidSummary,
                //     this.lcChgSummary, this.bcChgSummary, "", "", "", Math.round(incentiveSummaryRoundOff), Math.round(penalitySummaryRoundOff)];
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrDetailsDataList.length, "", "", "", this.totalArtSummary,
                this.actWgtSummary, this.chgWgtSummary, this.toPaySummary, this.paidSummary,
                this.lcChgSummary, this.bcChgSummary,];
            }


            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Stmt No"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.rateMasterDtoSelectedData.stmtId];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }



            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking Agent Statement Report LR Details");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    this.router.navigate(['/dashboard/classic']);
                    localStorage.clear();
                }
            });
        }
        this.changeDetectorRef.detectChanges();
    }

}

