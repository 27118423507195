import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css']
})
export class MonthlyReportComponent implements OnInit {

    gettingDataFrmServiceFrDirectMonthlyReportTable: any;
gettingDataFrmServiceFrViaMonthlyReportTable: any;

directMonthlyReportDataList: any; 
viaMonthlyReportDataList: any;

onDestroyUnsubscribtionDirectMonthlyReport:  Subscription;
onDestroyUnsubscribtionViaMonthlyReport:  Subscription;




loadingIndicator = true;

//datatable hide/show is pending
//searchByDirect = true;
//searchByVia = false;


    

@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

        dtTriggerDirectMonthlyReport: Subject<any> = new Subject();
        dtTriggerViaMonthlyReport: Subject<any> = new Subject();

     
      
        dataTable: any;
        dtOptionsDirectMonthlyReport: any;
        dtOptionViaMonthlyReport: any;
		pageId="mtlyr";

        constructor(private monthlyRpt:ReportService, public changeDetectorRef : ChangeDetectorRef){
          
            
        }
        
        rerender(): void {
            this.dtElements.forEach((dtElement: DataTableDirective) => {
              dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
      dtInstance.destroy();
              });
            });
          }

        

        
        
        
        
        ngOnInit(): void {
            
            
            this.dtOptionsDirectMonthlyReport = {  
                    //};
                            //columns is used to for export and others starts
                    columns: [
                              {
                                  title: 'Month',
                                    data: 'month'
                                },
                                {
                                    title: 'Tot Trucks',
                                    data: 'totTrucks'
                                },
                                {
                                    title: 'TO PAY FREIGHT',
                                    data: 'toPayFreight'
                                },
                                {
                                    title: 'PAID FREIGHT',
                                    data: 'paidFreight'
                                },
                                {
                                    title: 'TOTAL FREIGHT',
                                    data: 'totalFreight'
                                },
                                {
                                    title: 'TOTAL HIRE',
                                    data: 'totalHire'
                                },
                                {
                                    title: 'ADVANCE',
                                    data: 'advance'
                                },
                                {
                                    title: 'GUR WT',
                                    data: 'gurWt'
                                },
                                {
                                    title: 'KANTA WT',
                                    data: 'kantaWt'
                                },
                                {
                                    title: 'ACTUAL WT',
                                    data: 'actualWt'
                                },
                                {
                                    title: 'CHARGED WT',
                                    data: 'chargedWt'
                                }
                    ],        
                    
                    
                  //columns is used to for export and others endss
                    
                    
                    // the below code is for button export starts
                        dom: 'Bfrtip',
                    /*buttons: [
                              'excel', 'print'
                          ],*/
                        buttons: [
                                      {
                                          extend: 'excel',
                                          text:      '<i class="fas fa-file-excel"> Excel</i>',
                                          titleAttr: 'Excel',
                                          exportOptions: {
                                              columns: ':visible'
                                          }
                                      },
                                      {
                                          extend: 'print',
                                          text:      '<i class="fas fa-print"> Print</i>',
                                          titleAttr: 'Print',

                                      }
                                      
                                 
                              ],    
                          
                          
                          
                       // the below code is for button export ends
                          
                          
                          //place holder in search/filter in datatable starts
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                        //place holder in search/filter in datatable ends

                          
                          
                          processing: true,
                        //scroll in datatable starts
                          responsive: true,
                          "scrollX": true,
                          "scrollY": 380,
                          "scrollCollapse": true,
                              //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                                  "paging": false,
                                  "info": false,
                               //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                        //scroll in datatable ends
                          

                    
                          
                          
                }
            
            this.dtOptionViaMonthlyReport = {  
                    //};
                            //columns is used to for export and others starts
                            columns: [
                                      {
                                          title: 'MONTH',
                                            data: 'month'
                                        },
                                        {
                                            title: 'TO PAY FREIGHT',
                                            data: 'toPayFreight'
                                        },
                                        {
                                            title: 'PAID FREIGHT',
                                            data: 'paidFreight'
                                        },
                                        {
                                            title: 'TOTAL FREIGHT',
                                            data: 'totalFreight'
                                        },
                                        {
                                            title: 'ACTUAL WT',
                                            data: 'actualWt'
                                        },
                                        {
                                            title: 'CHARGED WT',
                                            data: 'chargedWt'
                                        }
                            ],
                  //columns is used to for export and others endss
                    
                    
                    // the below code is for button export starts
                        dom: 'Bfrtip',
                    /*buttons: [
                              'excel', 'print'
                          ],*/
                        buttons: [
                                      {
                                          extend: 'excel',
                                          text:      '<i class="fas fa-file-excel"> Excel</i>',
                                          titleAttr: 'Excel',
                                          exportOptions: {
                                              columns: ':visible'
                                          }
                                      },
                                      {
                                          extend: 'print',
                                          text:      '<i class="fas fa-print"> Print</i>',
                                          titleAttr: 'Print',

                                      }
                                      
                                 
                              ],    
                          
                          
                          
                       // the below code is for button export ends
                          
                          
                          //place holder in search/filter in datatable starts
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                        //place holder in search/filter in datatable ends
                          
                       
                          
                                   

                          
                          processing: true,
                        //scroll in datatable starts
                          responsive: true,
                          "scrollX": true,
                          "scrollY": 380,
                          "scrollCollapse": true,
                              //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                                  "paging": false,
                                  "info": false,
                               //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                        //scroll in datatable ends
                          

                          
                          
                }

                                  
                                  

            
            
          //the below code is for the getting data through json starts
//            this.supplierList.getAllData().subscribe(data => {
//                this.lrDispatchBknRptList = data['data'];
//                this.dtTriggerSummary.next();
//                } );
            this.gettingDataFrmServiceFrDirectMonthlyReportTable=this.monthlyRpt.getSummaryData()
            this.onDestroyUnsubscribtionDirectMonthlyReport= this.gettingDataFrmServiceFrDirectMonthlyReportTable.subscribe(data => {
                this.directMonthlyReportDataList = data['data'];
                this.dtTriggerDirectMonthlyReport.next();
                } );
            
            this.gettingDataFrmServiceFrViaMonthlyReportTable = this.monthlyRpt.getSummaryData();
            this.onDestroyUnsubscribtionViaMonthlyReport = this.gettingDataFrmServiceFrViaMonthlyReportTable.subscribe(data => {
                this.viaMonthlyReportDataList = data['data'];
                this.dtTriggerViaMonthlyReport.next();
                } );
            
           
          //the below code is for the getting data through json ends
          
         
            
        }
        
        
      
     
        ngOnDestroy(): void {
            this.dtTriggerDirectMonthlyReport.unsubscribe();
            this.dtTriggerViaMonthlyReport.unsubscribe();
            this.onDestroyUnsubscribtionDirectMonthlyReport.unsubscribe();
            this.onDestroyUnsubscribtionViaMonthlyReport.unsubscribe();
          }
        
   //datatable hide/show is pending     
//        searchByMode( searchBy: string ) {
//            if ( searchBy === 'direct' ) {
//                this.searchByDirect = true;
//                this.searchByVia = false;
//
//            } else if ( searchBy === 'via' ) {
//                this.searchByDirect = false;
//                this.searchByVia = true;
//                
//            }  else {
//                this.searchByDirect = false;
//                this.searchByVia = false;
//            }
//        }
        

}
