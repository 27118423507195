import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbTypeaheadSelectItemEvent, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge, Observable } from 'rxjs';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { MatDialog } from '@angular/material/dialog';
import { LabourStatementLRDetailsComponent } from 'src/app/report/hamali-report/labour-statement-lr-details/labour-statement-lr-details.component';
import { LabourStatementLoadingUnloadingComponent } from 'src/app/report/hamali-report/labour-statement-loading-unloading/labour-statement-loading-unloading.component';
import { LabourStatementPaymentDetailsComponent } from 'src/app/report/hamali-report/labour-statement-payment-details/labour-statement-payment-details.component';
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';

@Component({
    selector: 'app-labour-statement-report.component',
    templateUrl: './labour-statement-report.component.html',
    styleUrls: ['./labour-statement-report.component.css']
})
export class LabourStatementReportComponent implements OnInit {
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    labourStmtReportDataList: any;
    controlMain = new FormControl();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLabourStmtReport: Subject<any> = new Subject();
    dtOptionsLabourStmtReport: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    viewLabourHamaliLoadUnLoadDetlsPrint = false;
    labourHeadNameOption: Array<HireSlipDto> = [];
    hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    hireSlipDtoLabourHeadNameAll: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

    isShowAll: boolean = false;
    viewStmtNoField: boolean = true;
    viewDateWiseField: boolean = false;

    modelFromDate: any;
    modelToDate: any;
    validateFromDate: any;
    validateToDate: any;
    validateStmtNo: any;
    validateLoaderHeadName: any;

    fortNightStmtDtoForSearch: FortNightStmtDto = new FortNightStmtDto();
    additionValueSplit: Array<String> = [];
    deductionValueSpilt: Array<String> = [];
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintDataSummaryList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    totalLrsForPrint = 0;
    totalActWgtForPrint = 0;
    totalKantaWgtForPrint = 0;
    amtToBeReceivedForPrint = 0;
    cashAmtForPrint = 0;
    totChequeAmtForPrint = 0;
    totPdcAmtForPrint = 0;
    hireDto: HireSlipDto = new HireSlipDto();
    sum: any;
    hamaliAmtUnloading: any;
    hireDtoForPayemntType: HireSlipDto = new HireSlipDto();
    printDto: HireSlipDto = new HireSlipDto();
    valueMin: any;
    valueMax: any;
    hMapPrint: Map<Number, HireSlipDto> = new Map<Number, HireSlipDto>();
    printVoucherDto: any;
    printVoucherDtoList: HireSlipDto[];
    viewPaymentVoucherPrint: boolean = false;
    //by kamil
    viewPaymentVoucherUnloadingPrint: boolean = false;

    pageId = "lbsrc";
    //labour hamali payment
    dtTriggerBkgAgentStmtCashChequePdc: Subject<any> = new Subject();
    dtOptionsBkgAgentStmtCashChequePdc: any;
    bkgAgentStmtCashChequePdcDataList: any;
    userDataDto: UserDataDto = new UserDataDto();
    bankNameOptions: UserDataDto[];
    public modelBankName: any;
    bankNameTA: Array<UserDataDto> = [];
    focusBankNameTA$ = new Subject<string>();
    searchBankName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    viewCashChequePdcAmtField: boolean = true;
    viewChequePdcNoField: boolean = true;
    viewChequeDueDateField: boolean = false;
    viewChequePdcBankNameField: boolean = true;

    modelChequeDueDates: any;
    modelPayingDates: any;
    private newAttributeTosetBank: any = {};
    editBankTableDetailsId: any;

    validateBalAmtToBePaid: any;
    validateBalAmtCash: any;
    validateBalAmtCheque: any;
    validateBalAmtPdc: any;

    validateBalAmtForSave: any;
    validateSummCashAmtForSave: any;
    validateSummChequeAmtForSave: any;
    validateSummPdcAmtForSave: any;
    validatePayingDateForSave: any;
    validateStmtNoForSave: any;
    validateInitBalAmtForSave: any;
    resultDuplicate: any;
    fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();
    labourStmtReportData: any;
    Statement: any;
    modalRefferenceContentPaymentConfirmPopUp: NgbModalRef;
    fortNightStmtDtoSelectedData: FortNightStmtDto = new FortNightStmtDto();

    constructor(private router: Router, private masterReadService: MasterReadService,
        private lrService: LrService,
        public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "LbrHamaliAll") {
                        this.isShowAll = true;
                    } else {
                        this.isShowAll = false;
                    }
                }
            }

            setTimeout(() => {
                this.setLabourHeadNameDetailsList();
            }, 1000);

            this.getBankDetailsList();
        }
    }
    ngOnInit(): void {
        var groupColumn = 0;
        var groupColumnPayment = 0;
        this.dtOptionsLabourStmtReport = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel">Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'
                    },
                    title: function () {
                        var returnLabourStatementReport = null;
                        if ($("#lbsrcreportModeType").val() == "StatementNumber") {
                            returnLabourStatementReport = "Labour Statement Report " + " Statement No : " + $("#lbsrcstatementNumber").val();
                        } else {
                            returnLabourStatementReport = "Labour Statement Report " +
                                "Loader Head : " + $("#lbsrcdropDownInputFieldLabourHeadName").val() +
                                " From Date : " + moment($("#lbsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#lbsrctoDates").val()).format('DD-MM-YYYY');
                        }

                        return returnLabourStatementReport;
                    }
                },
                // {
                //     extend: 'print',
                //     text: '<i class="fas fa-print">Print</i>',
                //     titleAttr: 'Print',

                // }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totaLRs = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var challanKantaWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var payableAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cashAmt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chqueAmt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var pdcAmt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                // Update footer by showing the total with the reference of the column index 
                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(totaLRs);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(challanKantaWgt);
                $(api.column(9).footer()).html(payableAmt);
                $(api.column(11).footer()).html(cashAmt);
                $(api.column(12).footer()).html(chqueAmt);
                $(api.column(13).footer()).html(pdcAmt);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var totaLRs = 0;
                var actWgt = 0;
                var challanKantaWgt = 0;
                var payableAmt = 0;
                var cashAmt = 0;
                var chqueAmt = 0;
                var pdcAmt = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total"
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 3,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totaLRs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": challanKantaWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": payableAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cashAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chqueAmt
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": pdcAmt
                                            }))
                                        .prop('outerHTML'));
                            totaLRs = 0;
                            actWgt = 0;
                            challanKantaWgt = 0;
                            payableAmt = 0;
                            cashAmt = 0;
                            chqueAmt = 0;
                            pdcAmt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        //the below is for column name grouping (in this case we group for destination wise)
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 14,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    totaLRs += +val[6];
                    actWgt += +val[7];
                    challanKantaWgt += +val[8];
                    payableAmt += +val[9];
                    cashAmt += +val[11];
                    chqueAmt += +val[12];
                    pdcAmt += +val[13];
                    if (i == (rows.length - 1)) {
                        //the below is for Sumary details grouping 
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total"
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 3,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totaLRs
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": actWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": challanKantaWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": payableAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": cashAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": chqueAmt
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": pdcAmt
                                        }))


                                    .prop('outerHTML'));
                        totaLRs = 0;
                        actWgt = 0;
                        challanKantaWgt = 0;
                        payableAmt = 0;
                        cashAmt = 0;
                        chqueAmt = 0;
                        pdcAmt = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        },

            this.dtOptionsBkgAgentStmtCashChequePdc = {
                dom: 'Bfrtip',
                buttons: [

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                searching: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totaAmt = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total');
                    $(api.column(2).footer()).html(totaAmt);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totalBankAmt = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumnPayment, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": totalBankAmt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": ""
                                                            })).append($("<td></td>",
                                                                {
                                                                    "colspan": 1,
                                                                    "text": ""
                                                                }))
                                            .prop('outerHTML'));
                                totalBankAmt = 0;
                                count = 0;
                                //pendingQty = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 6,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totalBankAmt += +val[2];
                        //pendingQty += val[6];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count

                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalBankAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                        .prop('outerHTML'));
                            totalBankAmt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                    });
                }
            }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    ngOnDestroy(): void {
        this.dtTriggerLabourStmtReport.unsubscribe();
        this.dtTriggerBkgAgentStmtCashChequePdc.unsubscribe();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerLabourStmtReport.next();
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
    }

    clickListnerForLoaderName(e: NgbTypeaheadSelectItemEvent) {
        this.modelDropDownLabourHeadName = e.item;
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val(this.modelDropDownLabourHeadName.loaderHead);
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").focusout();
    }

    getLabourHeadNameDetails() {
        this.hireSlipDtoLabourHeadName = new HireSlipDto();
        this.hireSlipDtoLabourHeadName.mode = "NotActive";
        this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.getLabourHeadNameDetails();
        this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.hireSlipDtoLabourHeadNameAll = new HireSlipDto();
                    this.hireSlipDtoLabourHeadNameAll.loaderHead = "All";
                    this.labourHeadNameTA.push(this.hireSlipDtoLabourHeadNameAll);
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                    }
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    }

    reportModeTypeListner(reportModeType: string) {
        if (reportModeType == "StatementNumber") {
            this.viewStmtNoField = true;
            this.viewDateWiseField = false;

            this.modelDropDownLabourHeadName = null;
            this.modelFromDate = null;
            this.modelToDate = null;

            $("#" + this.pageId + "statementNumber").val('');
            $("#" + this.pageId + "agentName").val('');
            $("#" + this.pageId + "fromDates").val('');
            $("#" + this.pageId + "toDates").val('');
        } else if (reportModeType == "DateWise") {
            this.viewStmtNoField = false;
            this.viewDateWiseField = true;

            this.modelDropDownLabourHeadName = null;
            this.modelFromDate = null;
            this.modelToDate = null;

            $("#" + this.pageId + "statementNumber").val('');
            $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
            $("#" + this.pageId + "fromDates").val('');
            $("#" + this.pageId + "toDates").val('');
        }
    }

    validateSearchBtn() {
        if (($("#" + this.pageId + "reportModeType").val() == "StatementNumber") &&
            ($("#" + this.pageId + "statementNumber").val() == null || $("#" + this.pageId + "statementNumber").val() == undefined ||
                $("#" + this.pageId + "statementNumber").val() == "")) {
            swal("Mandatory Field", "Please enter the statement number", "warning");
            return false;
        } else if (($("#" + this.pageId + "reportModeType").val() == "DateWise") &&
            ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
                $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
                $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
                $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == null ||
                $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == undefined ||
                $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == "")) {
            swal("Mandatory Field", "Please enter the Agent Name,From Date & To Date", "warning");
            return false;
        } else {
            this.setStmtDetailsDatatableList();
        }
    }

    getStmtDetails() {
        this.fortNightStmtDtoForSearch = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.validateLoaderHeadName = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();

        if ($("#" + this.pageId + "reportModeType").val() == "StatementNumber") {
            this.fortNightStmtDtoForSearch.mode = "STMT";
            this.fortNightStmtDtoForSearch.stmtNo = this.validateStmtNo;
        } else if ($("#" + this.pageId + "reportModeType").val() == "DateWise") {
            this.fortNightStmtDtoForSearch.mode = "AGENT";
            this.fortNightStmtDtoForSearch.fromDate = this.validateFromDate;
            this.fortNightStmtDtoForSearch.toDate = this.validateToDate;
            this.fortNightStmtDtoForSearch.agentName = this.validateLoaderHeadName;
        }

        this.fortNightStmtDtoForSearch.mainBranch = this.userDataDtoReturnSession.mainStation;
        this.fortNightStmtDtoForSearch.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDtoForSearch.isShowAll = this.isShowAll;
    }

    setStmtDetailsDatatableList() {
        this.getStmtDetails();
        this.showSpinnerForAction = true;
        this.lrService.getLabourHamaliStatementReportDetails(this.fortNightStmtDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.labourStmtReportDataList = [];
                $("#" + this.pageId + "labourStmtRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.labourStmtReportDataList = response;
                    console.log("this.labourStmtReportDataList");
                    console.log(this.labourStmtReportDataList);
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerLabourStmtReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Statement Report Details", "info");
            }, () => console.log('done');
    }

    getTotalLrsDetails(labourStmtReportData) {
        localStorage.clear();
        localStorage.setItem('labourStmtTotalLrsDetails',
            JSON.stringify(labourStmtReportData));
        const dialogRef = this.dialog.open(LabourStatementLRDetailsComponent, {
        });
        // dialogRef.afterClosed().subscribe(result => {
        //     console.log('The dialog was closed');
        //     this.router.navigate(['.'], { relativeTo: this.route });
        // });
    }

    getStmtNoDetails(labourStmtReportData) {
        console.log(labourStmtReportData);
        var additionValue = null, deductionValue = null;

        additionValue = labourStmtReportData.multipleAdd;
        deductionValue = labourStmtReportData.multipleDed;

        if (additionValue != null && additionValue != undefined && additionValue != "") {
            this.additionValueSplit = additionValue.split("#");
        }

        if (deductionValue != null && deductionValue != undefined && deductionValue != "") {
            this.deductionValueSpilt = deductionValue.split("#");
        }

        localStorage.clear();
        localStorage.setItem('labourStmtLoadingUnloadingDetails',
            JSON.stringify(labourStmtReportData));
        localStorage.setItem('popupLabourStmtRptLrDetlsAddition', JSON.stringify(this.additionValueSplit));
        localStorage.setItem('popupLabourStmtRptLrDetlsDeduction', JSON.stringify(this.deductionValueSpilt));
        const dialogRef = this.dialog.open(LabourStatementLoadingUnloadingComponent, {
        });
        // dialogRef.afterClosed().subscribe(result => {
        //     console.log('The dialog was closed');
        //     this.router.navigate(['.'], { relativeTo: this.route });
        // });

        dialogRef.afterClosed().subscribe(result => {
            localStorage.clear();
            console.log('The dialog was closed');
            console.log(JSON.parse(result));
            // this.router.navigate(['.'], { relativeTo: this.route });

            localStorage.clear();
            localStorage.setItem('labourStmtLoadingUnloadingPrintShow',
                JSON.stringify(JSON.parse(result)));
            this.viewLabourHamaliLoadUnLoadDetlsPrint = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewLabourHamaliLoadUnLoadDetlsPrint) {
                    this.viewLabourHamaliLoadUnLoadDetlsPrint = false;
                    localStorage.clear();

                }
            });
            this.changeDetectorRef.detectChanges();
        });
    }

    labourStmtPayment(labourStmtReportData) {
        localStorage.clear();
        localStorage.setItem('popupLabourStmtPaymentRptLrDetls',
            JSON.stringify(labourStmtReportData));
        const dialogRef = this.dialog.open(LabourStatementPaymentDetailsComponent, {
        });
        // dialogRef.afterClosed().subscribe(result => {
        //     console.log('The dialog was closed');
        //     this.setStmtDetailsDatatableList();
        //     // this.router.navigate(['.'], { relativeTo: this.route });
        // });
    }

    customPrintLabourStmtRpt() {
        if (this.labourStmtReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Loader Name", "Stmt No", "Stmt Dt", "From Period", "To Period", "Tot LRs Unldg", "Tot A. Wt Unldg", "Tot K. Wt Unldg", "Payabale Amt", "D.O.P", "Cash", "Cheque", "PDC"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 7, 7];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totalLrsForPrint = 0;
            this.totalActWgtForPrint = 0;
            this.totalKantaWgtForPrint = 0;
            this.amtToBeReceivedForPrint = 0;
            this.cashAmtForPrint = 0;
            this.totChequeAmtForPrint = 0;
            this.totPdcAmtForPrint = 0;
            for (let i = 0; i < this.labourStmtReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.labourStmtReportDataList[i].loaderHead,
                this.labourStmtReportDataList[i].stmtNo,
                this.labourStmtReportDataList[i].stmtDateStr,
                this.labourStmtReportDataList[i].fromDate,
                this.labourStmtReportDataList[i].toDate,
                this.labourStmtReportDataList[i].totalLrs,
                this.labourStmtReportDataList[i].totalActWgt,
                this.labourStmtReportDataList[i].totalKantaWgt,
                this.labourStmtReportDataList[i].amtToBeReceived,
                this.labourStmtReportDataList[i].dateOfPaymentStr,
                this.labourStmtReportDataList[i].cashAmt,
                this.labourStmtReportDataList[i].totChequeAmt,
                this.labourStmtReportDataList[i].totPdcAmt];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                // this.artSummary = this.artSummary + this.labourStmtReportDataList[i].totalArticles;



                this.totalLrsForPrint = this.totalLrsForPrint + this.labourStmtReportDataList[i].totalLrs;
                this.totalActWgtForPrint = this.totalActWgtForPrint + this.labourStmtReportDataList[i].totalActWgt;
                this.totalKantaWgtForPrint = this.totalKantaWgtForPrint + this.labourStmtReportDataList[i].totalKantaWgt;
                this.amtToBeReceivedForPrint = this.amtToBeReceivedForPrint + this.labourStmtReportDataList[i].amtToBeReceived;
                this.cashAmtForPrint = this.cashAmtForPrint + this.labourStmtReportDataList[i].cashAmt;
                this.totChequeAmtForPrint = this.totChequeAmtForPrint + this.labourStmtReportDataList[i].totChequeAmt;
                this.totPdcAmtForPrint = this.totPdcAmtForPrint + this.labourStmtReportDataList[i].totPdcAmt;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.labourStmtReportDataList.length, "", "", "", "", this.totalLrsForPrint, this.totalActWgtForPrint, this.totalKantaWgtForPrint, this.amtToBeReceivedForPrint, "", this.cashAmtForPrint, this.totChequeAmtForPrint, this.totPdcAmtForPrint];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.validateFromDate = $("#" + this.pageId + "fromDates").val();
            this.validateToDate = $("#" + this.pageId + "toDates").val();
            this.validateStmtNo = $("#" + this.pageId + "statementNumber").val();
            this.validateLoaderHeadName = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
            if ($("#" + this.pageId + "reportModeType").val() == "StatementNumber") {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Stmt No"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateStmtNo];

            } else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
                this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Loader Head"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDate + " - " + this.validateToDate];
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateLoaderHeadName];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Labour Hamali Statement Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    setPrintVoucher(labourStmtReportData) {
        this.fortNightStmtDtoSelectedData = labourStmtReportData;

        this.hireDto = new HireSlipDto();
        this.hireDto.fromDate = labourStmtReportData.fromPeriod;
        this.hireDto.toDate = labourStmtReportData.toPeriod;
        this.hireDto.loaderHead = labourStmtReportData.loaderHead;
        this.sum = +labourStmtReportData.cashAmt +
            +labourStmtReportData.chequeAmt +
            +labourStmtReportData.pdcAmt;
        if (this.sum > 0) {
            this.hamaliAmtUnloading = null;
            var hamaliAmtLoading = labourStmtReportData.totLoadingHamaliAmt;
            this.hamaliAmtUnloading = labourStmtReportData.totUnloadingHamaliAmt;
            this.paymentTypePrintLoading("Loading", hamaliAmtLoading, labourStmtReportData);
        }
    }

    paymentTypePrintLoading(mode, hamaliAmountLoading, labourStmtReportData) {
        this.printVoucherDtoList = [];
        this.sum = hamaliAmountLoading;
        if (this.sum > 20000.0) {
            this.paymentCalculation(mode, this.sum, labourStmtReportData);
        } else if (this.sum <= 20000.0) {
            this.hireDtoForPayemntType = new HireSlipDto();
            this.hireDtoForPayemntType.loaderHead = labourStmtReportData.loaderHead;
            this.hireDtoForPayemntType.fromDate = labourStmtReportData.fromDate;
            this.hireDtoForPayemntType.toDate = labourStmtReportData.toDate;
            this.hireDtoForPayemntType.kantaWeight = labourStmtReportData.totalKantaWgt;
            this.hireDtoForPayemntType.totalAmt = this.sum;
            this.hireDtoForPayemntType.type = mode;
            this.printVoucherDtoList.push(this.hireDtoForPayemntType);
            this.printVouchersLoading(mode, labourStmtReportData, this.printVoucherDtoList);
        }
    }

    paymentCalculation(mode, sumAmt, labourStmtReportData) {
        this.valueMin = 0.0;
        this.valueMax = 0.0;
        var lastValue = 20001, firstValve = 0, iterValve = 1;
        var finalValueMin = 0;
        var resultValue = 0.0, finalValue = 0.0, maxIterValue = 0.0;
        var finalResult = null;
        for (let i = 2; i < 20; i++) {
            let z = i * 20000;
            firstValve = lastValue;
            lastValue = z;
            console.log(iterValve);
            //console.log(++iterValve);
            maxIterValue = ++iterValve;
            if ((sumAmt >= firstValve) && (sumAmt <= lastValue)) {
                resultValue = maxIterValue;
                break;
            }
            lastValue = lastValue + +1;
        }
        console.log("firstValve >> " + firstValve);
        console.log("lastValue >> " + lastValue);
        console.log("maxIterValue >> " + maxIterValue);
        console.log("sumAmt >> " + sumAmt);
        console.log("resultValue >> " + resultValue);

        finalValue = sumAmt / resultValue;
        finalValueMin = Math.round(finalValue);
        finalResult = finalValueMin + "";
        this.valueMin = finalResult;

        console.log("finalValue >> " + finalValue);

        this.printVoucherDtoList = [];
        for (let i = 0; i < resultValue; i++) {
            this.hireDtoForPayemntType = new HireSlipDto();
            this.hireDtoForPayemntType.loaderHead = labourStmtReportData.loaderHead;
            this.hireDtoForPayemntType.fromDate = labourStmtReportData.fromDate;
            this.hireDtoForPayemntType.toDate = labourStmtReportData.toDate;
            this.hireDtoForPayemntType.kantaWeight = labourStmtReportData.totalKantaWgt;
            this.hireDtoForPayemntType.totalAmt = this.valueMin;
            this.hireDtoForPayemntType.type = mode;
            this.printVoucherDtoList.push(this.hireDtoForPayemntType);
        }
        if (mode == "Loading") {
            this.printVouchersLoading(mode, labourStmtReportData, this.printVoucherDtoList);
        } else {
            this.printVouchersUnloading(mode, labourStmtReportData, this.printVoucherDtoList);
        }
    }

    printVouchersLoading(mode, labourStmtReportData, printVoucherDtoList) {
        swal({
            title: "Print Confirmation",
            text: "Sure U Want To Take The PrintOut ?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                localStorage.clear();
                localStorage.setItem('paymentVoucherDatePassLocalStorage',
                    JSON.stringify(labourStmtReportData));
                localStorage.setItem('paymentVoucherDatePassHireslipDtoLocalStorage',
                    JSON.stringify(printVoucherDtoList));
                localStorage.setItem('paymentVoucherDatePassTypeLocalStorage', mode);
                this.viewPaymentVoucherPrint = true;
                this.showSpinnerForAction = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewPaymentVoucherPrint) {
                        this.viewPaymentVoucherPrint = false;
                        localStorage.clear();
                        this.printUnloading(this.fortNightStmtDtoSelectedData);
                    }
                    this.showSpinnerForAction = false;
                    // this.changeDetectorRef.detectChanges();
                });
            } else {
                this.printUnloading(labourStmtReportData);
            }
            this.changeDetectorRef.detectChanges();
        });
    }


    printVouchersUnloading(mode, labourStmtReportData, printVoucherDtoList) {
        localStorage.clear();
        localStorage.setItem('paymentVoucherDatePassLocalStorage',
            JSON.stringify(labourStmtReportData));
        localStorage.setItem('paymentVoucherDatePassHireslipDtoLocalStorage',
            JSON.stringify(printVoucherDtoList));
        localStorage.setItem('paymentVoucherDatePassTypeLocalStorage', mode);

        this.viewPaymentVoucherUnloadingPrint = true;
        this.showSpinnerForAction = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewPaymentVoucherUnloadingPrint) {
                // this.changeDetectorRef.detectChanges();
                this.viewPaymentVoucherUnloadingPrint = false;
                localStorage.clear();
                this.clearOverAllField();
            }
            this.showSpinnerForAction = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    printUnloading(labourStmtReportData) {
        swal({
            title: "Print Confirmation",
            text: "Sure U Want To Take The PrintOut ?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.paymentTypePrintUnloading("UnLoading", this.hamaliAmtUnloading, labourStmtReportData);
            } else {
                this.clearOverAllField();
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    paymentTypePrintUnloading(mode, hamaliAmountLoading, labourStmtReportData) {
        this.printVoucherDtoList = [];
        this.sum = hamaliAmountLoading;
        if (this.sum > 20000.0) {
            this.paymentCalculation(mode, this.sum, labourStmtReportData);
        } else if (this.sum <= 20000.0) {
            this.hireDtoForPayemntType = new HireSlipDto();
            this.hireDtoForPayemntType.loaderHead = labourStmtReportData.loaderHead;
            this.hireDtoForPayemntType.fromDate = labourStmtReportData.fromDate;
            this.hireDtoForPayemntType.toDate = labourStmtReportData.toDate;
            this.hireDtoForPayemntType.kantaWeight = labourStmtReportData.totalKantaWgt;
            this.hireDtoForPayemntType.totalAmt = this.sum;
            this.hireDtoForPayemntType.type = mode;
            this.printVoucherDtoList.push(this.hireDtoForPayemntType);
            console.log('paymentTypePrintUnloading : ' + this.printVoucherDtoList.length);
            this.printVouchersUnloading(mode, labourStmtReportData, this.printVoucherDtoList);
        }
    }

    validateClearBtn() {
        $("#" + this.pageId + "statementNumber").val('');
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
        $("#" + this.pageId + "fromDates").val('');
        $("#" + this.pageId + "toDates").val('');
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "reportModeType").val('StatementNumber');
        $("#" + this.pageId + "labourStmtRptTableId").DataTable().destroy();
        this.dtTriggerLabourStmtReport.next();
    }

    setLabourPaymentValues(labourStmtReportData) {
        this.labourStmtReportData = labourStmtReportData;
        this.Statement = this.labourStmtReportData.loaderHead;
        this.filledValues(this.labourStmtReportData);
    }

    clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
        this.modelBankName = e.item;
        $("#" + this.pageId + "bankNameId").val(this.modelBankName.bankName);
        $("#" + this.pageId + "bankNameId").focusout();
    }

    getDetailsForBankMasterRead() {
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        //this.showSpinnerForAction = true;
        this.bankNameOptions = [];
        this.getDetailsForBankMasterRead();
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.bankNameOptions = response;
                        this.bankNameTA = [];
                        for (let i = 0; i < this.bankNameOptions.length; i++) {
                            this.bankNameTA.push(this.bankNameOptions[i]);
                        }
                        //this.showSpinnerForAction = false;
                    } else {
                        //this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Master Details", "error");
            },
            () => console.log('done');
    };

    payemntModeTypeListner(modeType: string) {
        if (modeType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        }
    }


    filledValues(localStorageOfPaymentData) {
        console.log(localStorageOfPaymentData);
        $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        var chequeAmtSumm = 0.0, pdcAmtSmm = 0.0;


        var cashAmt = [];
        var chequeAmt = [], chequeNumber = [], cheqBankName = [];
        var pdcAmt = [], pdcNumber = [], pdcDueDate = [], pdcBankName = [];

        //cashAmt
        // cashAmt = localStorageOfPaymentData.cashAmt == null ? null
        //     : localStorageOfPaymentData.cashAmt.split("#");
        cashAmt = localStorageOfPaymentData.cashAmt == null ? null
            : localStorageOfPaymentData.cashAmt;

        //cheque
        chequeAmt = localStorageOfPaymentData.chequeAmtStr == null ? null
            : localStorageOfPaymentData.chequeAmtStr.split("#");
        chequeNumber = localStorageOfPaymentData.chequeNumber == null ? null
            : localStorageOfPaymentData.chequeNumber.split("#");
        cheqBankName = localStorageOfPaymentData.cheqBankName == null ? null
            : localStorageOfPaymentData.cheqBankName.split("#");

        //pdc
        pdcAmt = localStorageOfPaymentData.pdcAmtStr == null ? null
            : localStorageOfPaymentData.pdcAmtStr.split("#");
        pdcNumber = localStorageOfPaymentData.pdcNumber == null ? null
            : localStorageOfPaymentData.pdcNumber.split("#");
        pdcDueDate = localStorageOfPaymentData.pdcDueDateStr == null ? null
            : localStorageOfPaymentData.pdcDueDateStr.split("#");
        pdcBankName = localStorageOfPaymentData.pdcBankName == null ? null
            : localStorageOfPaymentData.pdcBankName.split("#");
        console.log(pdcAmt, chequeAmt, cashAmt);

        this.bkgAgentStmtCashChequePdcDataList = [];

        if (cashAmt != null && cashAmt != undefined) {
            this.newAttributeTosetBank = {};
            this.newAttributeTosetBank.paymentType = "Cash";
            this.newAttributeTosetBank.chequeNumber = null;
            this.newAttributeTosetBank.amount = cashAmt;
            this.newAttributeTosetBank.bankName = null;
            this.newAttributeTosetBank.pdcDueDate = null;
            this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
        }

        if (chequeAmt != null && chequeAmt != undefined &&
            chequeAmt.length > 0) {
            for (let i = 0; i < chequeAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "Cheque";
                this.newAttributeTosetBank.chequeNumber = chequeNumber[i];
                this.newAttributeTosetBank.amount = chequeAmt[i];
                this.newAttributeTosetBank.bankName = cheqBankName[i];
                this.newAttributeTosetBank.pdcDueDate = null;
                this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);

                chequeAmtSumm = +chequeAmtSumm + +chequeAmt[i];
            }
        }

        if (pdcAmt != null && pdcAmt != undefined &&
            pdcAmt.length > 0) {
            for (let i = 0; i < pdcAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "PDC";
                this.newAttributeTosetBank.chequeNumber = pdcNumber[i];
                this.newAttributeTosetBank.amount = pdcAmt[i];
                this.newAttributeTosetBank.bankName = pdcBankName[i];
                this.newAttributeTosetBank.pdcDueDate = pdcDueDate[i];
                this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);

                pdcAmtSmm = +pdcAmtSmm + +pdcAmt[i];
            }
        }

        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        var balanceAmt = 0.0;
        //this.showSpinnerForAction = true;
        setTimeout(() => {
            $("#" + this.pageId + "destination").val(localStorageOfPaymentData.stmtNo);
            $("#" + this.pageId + "amount").val(localStorageOfPaymentData.amtToBeReceived);
            if (localStorageOfPaymentData.dateOfPayment != null && localStorageOfPaymentData.dateOfPayment != undefined && localStorageOfPaymentData.dateOfPayment != '') {
                $("#" + this.pageId + "payingDates").val(localStorageOfPaymentData.dateOfPayment);
            } else {
                $("#" + this.pageId + "payingDates").val(moment(new Date()).format("YYYY-MM-DD"));
            }


            $("#" + this.pageId + "totalAmountToBePaid").val(localStorageOfPaymentData.amtToBeReceived);
            $("#" + this.pageId + "summaryCashAmt").val(localStorageOfPaymentData.cashAmt);
            $("#" + this.pageId + "summaryChequeAmt").val(chequeAmtSumm);
            $("#" + this.pageId + "summaryPDCAmt").val(pdcAmtSmm);
            balanceAmt = localStorageOfPaymentData.amtToBeReceived + -localStorageOfPaymentData.cashAmt + -chequeAmtSumm + -pdcAmtSmm;
            $("#" + this.pageId + "balanceToBePaid").val(balanceAmt);
            //this.showSpinnerForAction = false;
        }, 500);
    }

    balanceAmt() {
        var balanceAmt = 0.0;
        this.validateBalAmtToBePaid = 0;
        this.validateBalAmtCash = 0;
        this.validateBalAmtCheque = 0;
        this.validateBalAmtPdc = 0;

        this.validateBalAmtToBePaid = $("#" + this.pageId + "amount").val();
        this.validateBalAmtCash = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateBalAmtCheque = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateBalAmtPdc = $("#" + this.pageId + "summaryPDCAmt").val();
        console.log("total : " + this.validateBalAmtToBePaid + " - " + this.validateBalAmtCash + " - " + this.validateBalAmtCheque + " - " + this.validateBalAmtPdc);
        balanceAmt = +this.validateBalAmtToBePaid + -this.validateBalAmtCash + -this.validateBalAmtCheque + -this.validateBalAmtPdc;
        $("#" + this.pageId + "balanceToBePaid").val(balanceAmt);
    }

    calculateCashAmtSum() {
        var cashSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashSum = cashSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return cashSum;
    }

    calculateChequeAmtSum() {
        var chequeSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chequeSum = chequeSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return chequeSum;
    }

    calculatePdcAmtSum() {
        var pdcSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcSum = pdcSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return pdcSum;
    }

    onKeyPressListnerForAmount(event) {
        if (event.charCode == 13) {
            this.addBankDetailsInTable();
        }
    }

    addBankDetailsInTable() {
        if (($("#" + this.pageId + "payemntModeType").val() == "Cash") &&
            ($("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter amount", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "Cheque") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "PDC") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "" || $("#" + this.pageId + "chequeDueDates").val() == null ||
                $("#" + this.pageId + "chequeDueDates").val() == undefined || $("#" + this.pageId + "chequeDueDates").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else {
            this.addIntoTableOfBankDetails();
        }
    }

    addIntoTableOfBankDetails() {
        this.newAttributeTosetBank = {};
        this.newAttributeTosetBank.paymentType = $("#" + this.pageId + "payemntModeType").val();
        this.newAttributeTosetBank.chequeNumber = $("#" + this.pageId + "chequePdcNo").val();
        this.newAttributeTosetBank.amount = $("#" + this.pageId + "cashChequePdcAmount").val();
        this.newAttributeTosetBank.bankName = $("#" + this.pageId + "bankNameId").val();
        this.newAttributeTosetBank.pdcDueDate = $("#" + this.pageId + "chequeDueDates").val();
        //this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.bkgAgentStmtCashChequePdcDataList.splice(this.editBankTableDetailsId, 1);
        }
        if (this.bkgAgentStmtCashChequePdcDataList == null ||
            this.bkgAgentStmtCashChequePdcDataList == undefined ||
            this.bkgAgentStmtCashChequePdcDataList.length == 0) {
            this.bkgAgentStmtCashChequePdcDataList = [];
        }
        if (this.bkgAgentStmtCashChequePdcDataList.length == 0) {
            this.bkgAgentStmtCashChequePdcDataList = [];
            $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
            this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
            this.dtTriggerBkgAgentStmtCashChequePdc.next();
            this.clearFieldBank();
            $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
            $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
            $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
            this.balanceAmt();
        } else {
            if ($("#" + this.pageId + "payemntModeType").val() == "Cash") {
                const result = this.bkgAgentStmtCashChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Cash Amount Already Available ,Please Edit the Cash Amount and then Add", "warning");
                } else {
                    $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerBkgAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            } else {
                const result = this.bkgAgentStmtCashChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                    bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
                } else {
                    $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerBkgAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            }
        }
    }

    rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData, index) {
        this.editBankTableDetailsId = index;
        if (bkgAgentStmtCashChequePdcData.paymentType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cash");
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cheque");
                $("#" + this.pageId + "chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("PDC");
                $("#" + this.pageId + "chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "chequeDueDates").val(bkgAgentStmtCashChequePdcData.pdcDueDate);
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        }
    }

    deleteSelectedBankDetails(index) {
        $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.bkgAgentStmtCashChequePdcDataList.splice(index, 1);
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
        $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
        $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
        this.balanceAmt();
    }


    clearFieldBank() {
        this.editBankTableDetailsId = null;
        this.newAttributeTosetBank = {};
        $("#" + this.pageId + "chequePdcNo").val('');
        $("#" + this.pageId + "chequeDueDates").val('');
        $("#" + this.pageId + "bankNameId").val('');
        $("#" + this.pageId + "cashChequePdcAmount").val('');

        this.modelChequeDueDates = null;
        this.modelBankName = null;
    }

    clearBankDetailsInTable() {
        this.clearFieldBank();
        $("#" + this.pageId + "payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
    }


    /* save start here */
    validateConfirmPaymentBtn() {
        if ($("#" + this.pageId + "payingDates").val() == null || $("#" + this.pageId + "payingDates").val() == undefined ||
            $("#" + this.pageId + "payingDates").val() == "") {
            swal("Mandatory Field", "Please Select the Payment Date", "warning");
            return false;
        } else {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Submit the Amount Details ?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.setBookingAgentAmtPaymentDetails();
                }
            });
        }
    }

    setBookingAgentAmtPaymentDetails() {
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = this.getUserValues();
        this.showSpinnerForAction = true;
        this.lrService.labourHamaliAmtPayable(this.fortNightStmtDtoForReturnGetUserValue).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "success") {
                    swal("Alert", "Update Successfully", "success");
                    this.setPrintVoucher(this.labourStmtReportData);
                    this.setStmtDetailsDatatableList();
                } else if (response.status == "notfound") {
                    swal("No Found", "No Record Found", "warning");
                } else if (response.status == "failed") {
                    swal("Failed", "Ooops...Problem occured", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Labour Hamali Amt Payable", "error");
            },
            () => console.log('done');
    }


    getUserValues() {
        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();

        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validatePayingDateForSave = 0.0;
        this.validateStmtNoForSave = null;
        this.validateInitBalAmtForSave = 0.0;

        this.validateBalAmtForSave = $("#" + this.pageId + "balanceToBePaid").val();
        this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();
        this.validatePayingDateForSave = $("#" + this.pageId + "payingDates").val();
        this.validateStmtNoForSave = $("#" + this.pageId + "destination").val();
        this.validateInitBalAmtForSave = $("#" + this.pageId + "amount").val();

        this.fortNightStmtDtoForGetUserValue = this.setMultipleValue();

        this.fortNightStmtDtoForGetUserValue.initialBalanceAmt = this.validateInitBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.receivedDate = this.validatePayingDateForSave;
        this.fortNightStmtDtoForGetUserValue.chequeAmt = this.validateSummChequeAmtForSave;
        this.fortNightStmtDtoForGetUserValue.pdcAmt = this.validateSummPdcAmtForSave;
        this.fortNightStmtDtoForGetUserValue.balanceAmt = this.validateBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.updatedBy = this.userDataDtoReturnSession.userId;
        var totAmtPaid = 0.0;
        totAmtPaid = +this.fortNightStmtDtoForGetUserValue.cashAmt +
            +this.fortNightStmtDtoForGetUserValue.chequeAmt +
            +this.fortNightStmtDtoForGetUserValue.pdcAmt;
        this.fortNightStmtDtoForGetUserValue.totalPaid = totAmtPaid;
        this.fortNightStmtDtoForGetUserValue.stmtNo = this.validateStmtNoForSave;
        return this.fortNightStmtDtoForGetUserValue;
    }

    setMultipleValue() {
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();
        var cashAmtMultiple = null;
        var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
        var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;


        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chqNumMultiple = chqNumMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].chequeNumber;
                chqAmtMultiple = chqAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
                cheqBankNameMultiple = cheqBankNameMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcNumMultiple = pdcNumMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].chequeNumber;
                pdcAmtMultiple = pdcAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
                pdcDueDateMultiple = pdcDueDateMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].pdcDueDate;
                pdcBankNameMultiple = pdcBankNameMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashAmtMultiple = cashAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }

        if (chqNumMultiple != null) {
            chqNumMultiple = chqNumMultiple.replace("null#", "");
        }
        if (chqAmtMultiple != null) {
            chqAmtMultiple = chqAmtMultiple.replace("null#", "");
        }
        if (pdcNumMultiple != null) {
            pdcNumMultiple = pdcNumMultiple.replace("null#", "");
        }
        if (pdcAmtMultiple != null) {
            pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
        }
        if (pdcDueDateMultiple != null) {
            pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
        }
        if (pdcBankNameMultiple != null) {
            pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
        }
        if (cheqBankNameMultiple != null) {
            cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
        }

        if (cashAmtMultiple != null) {
            cashAmtMultiple = cashAmtMultiple.replace("null#", "");
        }

        this.fortNightStmtDtoForMultipleValue.cashAmt = cashAmtMultiple;

        this.fortNightStmtDtoForMultipleValue.chequeNumber = chqNumMultiple;
        this.fortNightStmtDtoForMultipleValue.chequeAmtStr = chqAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.cheqBankName = cheqBankNameMultiple;

        this.fortNightStmtDtoForMultipleValue.pdcNumber = pdcNumMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcAmtStr = pdcAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcDueDateStr = pdcDueDateMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcBankName = pdcBankNameMultiple;
        return this.fortNightStmtDtoForMultipleValue;
    }

    overAllClearFieldBtn() {
        this.clearOverAllField();
    }
    clearOverAllField() {
        this.fortNightStmtDtoSelectedData = new FortNightStmtDto();
        $("#" + this.pageId + "payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
        this.clearFieldBank();

        this.modelChequeDueDates = null;
        this.modelPayingDates = null;

        this.newAttributeTosetBank = {};
        this.editBankTableDetailsId = null;

        this.validateBalAmtToBePaid = null;
        this.validateBalAmtCash = null;
        this.validateBalAmtCheque = null;
        this.validateBalAmtPdc = null;

        this.validateBalAmtForSave = null;
        this.validateSummCashAmtForSave = null;
        this.validateSummChequeAmtForSave = null;
        this.validateSummPdcAmtForSave = null;
        this.validatePayingDateForSave = null;
        this.validateStmtNoForSave = null;
        this.validateInitBalAmtForSave = null;
        this.resultDuplicate = null;

        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

        $("#" + this.pageId + "totalAmountToBePaid").val('');
        $("#" + this.pageId + "balanceToBePaid").val('');
        $("#" + this.pageId + "summaryCashAmt").val('');
        $("#" + this.pageId + "summaryChequeAmt").val('');
        $("#" + this.pageId + "summaryPDCAmt").val('');
        $("#" + this.pageId + "payingDates").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "amount").val('');

        this.bkgAgentStmtCashChequePdcDataList = [];
        $("#" + this.pageId + "bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        this.hamaliAmtUnloading = null;
    }
}
