<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Booking Cashmemo</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}fromDate">
                                                    <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}city">
                                                        <label>City</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input class="auto_selectOption input is-medium" placeholder="Select Agent" [formControl]="controlMain" [appAutocomplete]="autocompleteCity">
                                                    </div>
                                                </div>
                                            </div>

                                            <app-autocomplete #autocompleteCity="appAutocomplete">
                                                <ng-template appAutocompleteContent>
                                                    <ng-container *ngIf="(cityOptions | filter: controlMain.value) as resultCity">
                                                        <app-option *ngFor="let option of resultCity" [value]="option.label"> {{ option.label }} </app-option>
                                                        <app-option class="no-result" *ngIf="!resultCity.length">No result
                                                        </app-option>
                                                    </ng-container>
                                                </ng-template>
                                            </app-autocomplete>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="box-body">
                            <h6 class="card-title border_bottom">Booking Cashmemo Report</h6>
                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBkgCashMemoReport" [dtTrigger]="dtTriggerBkgCashMemoReport">

                                <thead>
                                    <tr>
                                        <th>Cashmemo</th>
                                        <th>LR Number</th>
                                        <th>Date</th>
                                        <th>From Period</th>
                                        <th>Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bkgCashMemoReportData of bkgCashMemoReportDataList ">
                                        <td>{{ bkgCashMemoReportData.cashmemo }}</td>
                                        <td>{{ bkgCashMemoReportData.lrNumber }}</td>
                                        <td>{{ bkgCashMemoReportData.date }}</td>
                                        <td>{{ bkgCashMemoReportData.fromPeriod }}</td>
                                        <td>{{ bkgCashMemoReportData.totalAmount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>