<!-- Row -->
<div class="row">

	<div class="col-lg-12">


		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Hireslip Advance</h6>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-4">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}hireslipNumber">
											<label>Hireslip Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-clipboard-list"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-8">
									<button type="submit" class="p_custom btn btn-success m-r-10"
										id="{{pageId}}getBtn">Get Details</button>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<hr style="margin: 0px;">
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<h6 class="card-title">Trip Details</h6>
							<div class="row">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Invoice Date</label> <input id="{{pageId}}invoiceDate"
												class="form-control" placeholder="yyyy-mm-dd"
												name="invoiceDates" ngbDatepicker
												#invoiceDates="ngbDatepicker">
											<div class="input-group-append"
												(click)="invoiceDates.toggle()">
												<span class="input-group-text"> <i
													class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}source">
											<label>Source</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-box"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}destination">
											<label>Destination</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class=" fas fa-road"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>


							</div>
						</div>
						<!-- <hr> -->
					</div>
				</div>
				<div class="col-md-3 vl">
					<div class="card">
						<div class="card-body">
							<h6 class="card-title">Driver Details</h6>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}vehicleNumber">
										<label>Vehicle Number</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-truck"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}supplierName">
										<label>Supplier Name</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-user"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}driverName">
										<label>Driver Name</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-user"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}mobileNumber">
										<label>Mobile Number</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-phone"></i>
											</span>
										</div>
										<input type="number" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}licenseNumber">
										<label>License Number </label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-id-card"></i>
											</span>
										</div>
										<input type="number" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 vl">
					<div class="card">
						<div class="card-body">
							<h6 class="card-title">Time Details</h6>
							<div class="row">

								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Departure Date</label> <input id="{{pageId}}departureDate"
												class="form-control" placeholder="yyyy-mm-dd"
												name="departureDates" ngbDatepicker
												#departureDates="ngbDatepicker">
											<div class="input-group-append"
												(click)="departureDates.toggle()">
												<span class="input-group-text"> <i
													class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>

								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}departureTime">
												<label>Departure Time</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-clock"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder="Select Departure Time" [formControl]="control"
													[appAutocomplete]="autocomplete">
											</div>
										</div>
									</div>

									<app-autocomplete #autocomplete="appAutocomplete">
									<ng-template appAutocompleteContent> <ng-container
										*ngIf="(departureTimeOptions | filter: control.value) as resultDepartureTime">
									<app-option *ngFor="let option of resultDepartureTime"
										[value]="option.label"> {{ option.label }} </app-option> <app-option
										class="no-result" *ngIf="!resultDepartureTime.length">No
									result</app-option> </ng-container> </ng-template> </app-autocomplete>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}scheduledDate">
											<label>Scheduled Date</label> <input class="form-control"
												placeholder="yyyy-mm-dd" name="scheduledDates" ngbDatepicker
												#scheduledDates="ngbDatepicker">
											<div class="input-group-append"
												(click)="scheduledDates.toggle()">
												<span class="input-group-text"> <i
													class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}scheduledTime">
												<label>Scheduled Time</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-clock"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder="Select Scheduled Time" [formControl]="control"
													[appAutocomplete]="autocomplete">
											</div>
										</div>
									</div>

									<app-autocomplete #autocomplete="appAutocomplete">
									<ng-template appAutocompleteContent> <ng-container
										*ngIf="(scheduledTimeOptions | filter: control.value) as resultScheduledTime">
									<app-option *ngFor="let option of resultScheduledTime"
										[value]="option.label"> {{ option.label }} </app-option> <app-option
										class="no-result" *ngIf="!resultScheduledTime.length">No
									result</app-option> </ng-container> </ng-template> </app-autocomplete>
								</div>

								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}arrivalDate">
											<label>Arrival Date</label> <input class="form-control"
												placeholder="yyyy-mm-dd" name="arrivalDates" ngbDatepicker
												#arrivalDates="ngbDatepicker">
											<div class="input-group-append"
												(click)="arrivalDates.toggle()">
												<span class="input-group-text"> <i
													class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12 ">
									<div class="form-group ">
										<div class="input-group " id="{{pageId}}arrivalTime ">
											<label>Arrival Time</label>
											<div class="input-group-prepend ">
												<span class="input-group-text "> <i
													class="fas fa-clock"></i>
												</span>
											</div>
											<input type="text" class="form-control "
												aria-describedby="basic-addon11 ">
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 vl">
					<div class="card">
						<div class="card-body">

							<!-- </div>style="background-color:#f2f9fb;"> -->
							<h6 class="card-title">Billing Detail</h6>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}hireAmount">
										<label>Hire Amount</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-rupee-sign"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}advance">
										<label>Advance</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-rupee-sign"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}balance">
										<label>Balance</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-rupee-sign"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}payableAt">
										<label>Payable At</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class=" fas fa-rupee-sign"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<hr style="width: 80%; border-top: none; margin: 3px;">
				<div class="col-md-12" style="text-align: center;">
					<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}editBtn">Edit</button>
					<button type="submit" class="btn btn-dark" id="{{pageId}}savenprintBtn">Save
						& Print</button>
					<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn">Clear</button>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
