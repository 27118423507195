<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}lrIssueReport">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">LR Issue Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<!-- <div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}source">
															<label>Agent Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																id="{{pageId}}agent" #agent placeholder="Select Agent"
																autocomplete="off" [formControl]="controlMainSource"
																[appAutocomplete]="autocompleteSource">
														</div>
													</div>
												</div>
	
												<app-autocomplete #autocompleteSource="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(agentDetailsSourceOptions | filterAgentSorce: controlMainSource.value) as resultSource">
												<app-option *ngFor="let option of resultSource"
													[value]="option.subStation"> {{
												option.subStation }} </app-option> <app-option class="no-result"
													*ngIf="!resultSource.length">No result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div> -->

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Agent Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text">
															<i class="ti-user"></i>
														</span>
													</div>
													<input id="{{pageId}}agentName" type="text" class="form-control"
														[(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName"
														(selectItem)="clickListnerForAgentName($event)"
														[resultFormatter]="formatterAgentName"
														[inputFormatter]="formatterAgentName"
														(focus)="focusAgentNameTA$.next($any($event).target.value)"
														placeholder="Select Agent Name" />
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>From Date</label> <input class="form-control" id="{{pageId}}fromDate"
														[(ngModel)]="setTodayDateOnFromDate" placeholder="dd-mm-yyyy"
														name="fromPeriods" ngbDatepicker #fromPeriods="ngbDatepicker"
														required>
													<div class="input-group-append" (click)="fromPeriods.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDate" class="form-control"
														[(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy"
														name="toPeriods" ngbDatepicker #toPeriods="ngbDatepicker">
													<div class="input-group-append" (click)="toPeriods.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
							(click)="validateBtnSearch()">Search</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
					</div>
				</div>
				<!-- <div class="col-md-9 vl p-t-10">
					<div class="card-body">
						<div class="box-body">
							<h6 class="card-title border_bottom">Lr Issue Report</h6>
							<table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}issueReportTableId"
								[dtOptions]="dtOptionsIssueReport" [dtTrigger]="dtTriggerIssueReport">

								<thead>
									<tr>
										<th>Agent Name</th>
										<th>Issue Date</th>
										<th>LR From</th>
										<th>LR To</th>
										<th>No.Of LRs</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let issueReportData of issueReportDataList ">
										<td>{{ issueReportData.agentName }}</td>
										<td>{{ issueReportData.dateInDate }}</td>
										<td>{{ issueReportData.fromLrNo }}</td>
										<td>{{ issueReportData.toLrNo }}</td>
										<td>{{ issueReportData.noOfLrs }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td style="text-align: left">Total</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div> -->

				<div class="col-md-9 vl p-t-10">
					<div class="card-body">
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div class="row p-t-10">

							<div class="col-md-8">
								<h6 class="card-title">LR Issue Report</h6>
							</div>
							<div class="col-md-4">

								<button type="submit" style="margin-left:225px;" class="dt-button" id="{{pageId}}printBtn"
									(click)="printMethodForDateWise();">
									<span><i class="fas fa-print">Print</i></span>
								</button>
							</div>

						</div>
						<div class="box-body">

							<table datatable class="table table-striped table-bordered row-border hover"
								id="{{pageId}}issueReportTableId" [dtOptions]="dtOptionsIssueReport"
								[dtTrigger]="dtTriggerIssueReport">

								<thead>
									<tr>
										<th>Agent Name</th>
										<th>Issue Date</th>
										<th>LR From</th>
										<th>LR To</th>
										<th>No.Of LRs</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let issueReportData of issueReportDataList ">
										<td>{{ issueReportData.agentName }}</td>
										<td>{{ issueReportData.dateInDate }}</td>
										<td>{{ issueReportData.fromLrNo }}</td>
										<td>{{ issueReportData.toLrNo }}</td>
										<td>{{ issueReportData.noOfLrs }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>

									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<!-- <div class="col-md-9 vl">
					<div *ngIf="showSpinnerForAction" class="col-md-8">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;">
								</mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<div class="row">
						
			 <div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row p-t-10">
								<div class="col-md-8">
									<h6 class="card-title">LR Issue Report</h6>
								</div>
								<div class="col-md-4">

									<button type="submit" style="margin-left:225px;" class="dt-button" id="{{pageId}}printBtn"
										(click)="printMethodForDateWise();">
										<span><i class="fas fa-print">Print</i></span>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="box-body">
										<table datatable class="table table-striped table-bordered row-border hover"
											id="{{pageId}}issueReportTableId" [dtOptions]="dtOptionsIssueReport"
											[dtTrigger]="dtTriggerIssueReport">

											<thead>
												<tr>
													<th>Agent Name</th>
													<th>Issue Date</th>
													<th>LR From</th>
													<th>LR To</th>
													<th>No.Of LRs</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let issueReportData of issueReportDataList ">
													<td>{{ issueReportData.agentName }}</td>
													<td>{{ issueReportData.dateInDate }}</td>
													<td>{{ issueReportData.fromLrNo }}</td>
													<td>{{ issueReportData.toLrNo }}</td>
													<td>{{ issueReportData.noOfLrs }}</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>

												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> --> 



	</div>
	<!-- Row -->
</div>
</div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>