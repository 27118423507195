import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
    selector: 'app-statement-verification',
    templateUrl: './statement-verification.component.html',
    styleUrls: ['./statement-verification.component.css']
})
export class StatementVerificationComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    statementReportDataList: any;
    dtTriggerStatementReport: Subject<any> = new Subject();
    dtOptionsStatementReport: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    showSpinnerForAction = false;
    validateStatementNumber: any = null;
    searchByAuthorityFortNightDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightDtoLocalStorage: FortNightStmtDto = new FortNightStmtDto();
    modalRefferenceStmtVerifcationPopUp: NgbModalRef;
    modalRefferenceStmtVerifcationClosePopUp: string;
    viewStmtVerificationRptPopUp = false;
    validateLocalStorageStmtNo: any = null;

    rightsForStmtVerifRpt = "Right For Stmt Verification SuperAdmin"
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    totLrSummary: number;
    totArtSummary: number;
    actWghtSummary: number;
    chgWghtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    servTaxSummary: number;
    pageId = "stmvr";

    constructor(private destStmtReport: DestStmtReport, private router: Router,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsStatementReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Statement Verification Report- " +
                            "Statement No : " + $("#stmvrstatementNo").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var serviceTax = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(2).footer()).html(articles);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(5).footer()).html(topay);
                $(api.column(6).footer()).html(paid);
                $(api.column(7).footer()).html(serviceTax);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStatementReport.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerStatementReport.unsubscribe();
    }

    clearBtn() {
        this.clearField();
    }

    clearField() {
        $("#" + this.pageId + "statementNo").val('');
        this.statementReportDataList = [];
        $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
        this.dtTriggerStatementReport.next();
    }

    validateSearchBtn() {
        if ($("#" + this.pageId + "statementNo").val() == null || $("#" + this.pageId + "statementNo").val() == undefined ||
            $("#" + this.pageId + "statementNo").val() == "") {
            swal("Mandatory Field", "Please enter the Statement Number", "warning");
            return false;
        }
        else if (this.userDataDtoReturnSession.role != null
            && this.userDataDtoReturnSession.role == "Super Administrator") {
            this.setStatementVerificationDetailsReport();
        } else {
            this.checkForAuthority();
        }
    }

    checkForAuthority() {
        this.validateStatementNumber = $("#" + this.pageId + "statementNo").val();
        this.searchByAuthorityFortNightDto = new FortNightStmtDto();
        this.searchByAuthorityFortNightDto.stmtNo = this.validateStatementNumber;
        this.searchByAuthorityFortNightDto.branch = this.userDataDtoReturnSession.office;
        this.searchByAuthorityFortNightDto.status = 'StmtRpt';
        this.searchForAuthorization();
    }

    searchForAuthorization = () => {
        this.showSpinnerForAction = true;
        this.destStmtReport.chkAgentAutority(this.searchByAuthorityFortNightDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == 'notExist') {
                    swal({
                        title: "No Result Found",
                        text: "No Result Found " + $("#" + this.pageId + "statementNo").val(),
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.statementReportDataList = [];
                    $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
                    this.dtTriggerStatementReport.next();
                } else if (response.status == 'unauthorized') {
                    swal({
                        title: "UnAuthorized",
                        text: "U are not authorized to view this informations",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.statementReportDataList = [];
                    $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
                    this.dtTriggerStatementReport.next();
                } else {
                    this.setStatementVerificationDetailsReport();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Checking Authorization of Statement verfication Details", "info");
            }, () => console.log('done');
    };

    getStatementVerificationData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateStatementNumber = $("#" + this.pageId + "statementNo").val();
        this.fortNightStmtDto.stmtNo = this.validateStatementNumber;
        this.fortNightStmtDto.status = "Transit";
        this.fortNightStmtDto.destination = this.userDataDtoReturnSession.mainStation;
        this.fortNightStmtDto.role = this.userDataDtoReturnSession.role;
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    setStatementVerificationDetailsReport() {
        this.getStatementVerificationData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtVerificationDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                this.statementReportDataList = [];
                $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.statementReportDataList = response;
                    this.dtTriggerStatementReport.next();
                } else {
                    this.dtTriggerStatementReport.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While getting of Statement verfication Details", "info");
            }, () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    stmtVerificationPopUpBtnLink(stmtVerificationLrsDetailsPopUpTemplate, statementReportData) {
        this.modalRefferenceStmtVerifcationPopUp = this.modalService.open(stmtVerificationLrsDetailsPopUpTemplate,
            { centered: true, size: "lg" });
        //{ centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceStmtVerifcationPopUp.result.then((result) => {
            this.modalRefferenceStmtVerifcationClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStmtVerifcationClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.fortNightDtoLocalStorage = new FortNightStmtDto();
        this.validateLocalStorageStmtNo = null;
        this.validateLocalStorageStmtNo = $("#" + this.pageId + "statementNo").val();;
        this.fortNightDtoLocalStorage.stmtNo = this.validateLocalStorageStmtNo;
        this.fortNightDtoLocalStorage.description = statementReportData.description;
        localStorage.clear();
        localStorage.setItem('stmtVerificationLrsDetailsPopUpTemplate', JSON.stringify(this.fortNightDtoLocalStorage));
        this.viewStmtVerificationRptPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewStmtVerificationRptPopUp = false;
        });
    }
    closeStmtVerificationRptPopUp() {
        this.modalRefferenceStmtVerifcationPopUp.close();
    }


    customPrintStatementVerRpt() {
        if (this.statementReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Title", "Total Lr", "Total Article", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "Service Tax"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [25, 10, 11, 11, 11, 11, 11, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Statement No
            this.validateStatementNumber = $("#" + this.pageId + "statementNo").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Statement No"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateStatementNumber];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            this.totLrSummary = this.totArtSummary = this.actWghtSummary = this.chgWghtSummary = this.toPaySummary = this.paidSummary = this.servTaxSummary = 0;

            for (let i = 0; i < this.statementReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.statementReportDataList[i].description, this.statementReportDataList[i].totalLrs,
                this.statementReportDataList[i].totalArticles, this.statementReportDataList[i].totalActWgt,
                this.statementReportDataList[i].totalChgWgt, this.statementReportDataList[i].toPay, this.statementReportDataList[i].totalPaid, this.statementReportDataList[i].serviceTax,];

                /////////
                this.totLrSummary = this.totLrSummary + this.statementReportDataList[i].totalLrs;
                this.totArtSummary = this.totArtSummary + this.statementReportDataList[i].totalArticles;
                this.actWghtSummary = this.actWghtSummary + this.statementReportDataList[i].totalActWgt;
                this.chgWghtSummary = this.chgWghtSummary + this.statementReportDataList[i].totalChgWgt;
                this.toPaySummary = this.toPaySummary + this.statementReportDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.statementReportDataList[i].totalPaid;
                this.servTaxSummary = this.servTaxSummary + this.statementReportDataList[i].serviceTax;

                /////
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.statementReportDataList.length, this.totLrSummary, this.totArtSummary, this.actWghtSummary, this.chgWghtSummary, this.toPaySummary, this.paidSummary, this.servTaxSummary];
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Statement Verification Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

}
