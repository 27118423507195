<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetails">
            <ng-template #content let-a="close" let-b="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Edit LR Freight</h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>LR Number :</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}lrNumber" class="form-control"
                                                aria-describedby="basic-addon11" (keyup)="validateForLrNumber($event)"
                                                autocomplete="off" #lrNumber>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>B.Freight</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #baseFreight id="{{pageId}}baseFreight"
                                            (keyup)="fieldFocus($event, hamali)" (blur)="baseFreightBlur( $event)"
                                                type="number" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Hamali</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #hamali id="{{pageId}}hamali" (keyup)="fieldFocus($event, gcCharge)"
                                                (blur)="hamaliBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group" style="margin-right: 160px;">
                                            <mat-spinner></mat-spinner>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <h6 class="card-title" style="color: blue;">Saving Lr details......
                                            </h6>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>GC.Chg</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #gcCharge id="{{pageId}}gcCharge" (keyup)="fieldFocus($event, lcCharge)"
                                                (blur)="gcChargeBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>LC</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #lcCharge id="{{pageId}}lcCharge" (keyup)="fieldFocus($event, bcCharge)"
                                                (blur)="lcChargeBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>BC</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #bcCharge id="{{pageId}}bcCharge" (keyup)="fieldFocus($event, fov)"
                                                (blur)="bcChargeBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>F.O.V</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #fov id="{{pageId}}fov" (keyup)="fieldFocus($event, aocCharge)"
                                                (blur)="fovBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>A.O.C</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #aocCharge id="{{pageId}}aocCharge" (keyup)="fieldFocus($event, others)"
                                                (blur)="aocChargeBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Others</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-donate"></i>
                                                </span>
                                            </div>
                                            <input #others id="{{pageId}}others" (keyup)="fieldFocus($event, ddAmt)"
                                                (blur)="othersBlur($event)" type="number" class="form-control"
                                                aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>DD</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input #ddAmt id="{{pageId}}ddAmt" (keyup)="fieldFocus($event, updateBtn)"
                                                 type="number" class="form-control"
                                                aria-describedby="basic-addon11" (blur)="ddAmtBlur($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Total Amt</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input #totalAmount id="{{pageId}}totalAmount" type="number"
                                                class="form-control" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button #updateBtn type="button" class="btn btn-outline-dark" id={{pageId}}updateBtn
                        (click)="checkInvoiceNo()">Update</button>

                    <button type="button" class="btn btn-outline-dark" (click)="b('Save click')">Cancel</button>
                </div>
            </ng-template>

            <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="open2(content)">Click Here</a>
        </div>
    </div>
</div>