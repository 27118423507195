import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
//for the select option with filter ends


import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { MasterService } from "src/app/dataService/master-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TripLrDetailsComponent } from "src/app/report/bookings/trip-lr-details/trip-lr-details.component";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { NgbModalRef, NgbModal, ModalDismissReasons, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
//import {Subscription} from 'rxjs';
//for modal ends

@Component({
    selector: 'app-trip-eta-setup',
    templateUrl: './trip-eta-setup.component.html',
    styleUrls: ['./trip-eta-setup.component.css']
})
export class TripEtaComponent implements OnInit {



    //    getDataFrmServiceFrTable: any;

	etaTripTripDataList: any;

    //    onDestroyUnsubscribtionAgentLocalTrip: Subscription;

    // toAgentDto: AgentDetailsDto = new AgentDetailsDto();
    // fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDto: LRDto = new LRDto();

    // toStationDataListOptions: AgentDetailsDto[];
    fromStationDataListOptions: any;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoToStationAll: AgentDetailsDto = new AgentDetailsDto();
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    hireSlipDtoUserValue: HireSlipDto = new HireSlipDto();
    
    selectedFromStation: any;
    selectedToStation: any;
    selectedDuration: any;
    selectedTripId =0;
    pageId="tescp";


    //popup
    modalRefferenceAgentLocalTripPopUp: NgbModalRef;
    modalRefferenceAgentLocalTripClosePopUp: string;
    @ViewChild('memoLessDescrPopUpTemplate') private memoLessDescrPopUpTemplate;
    viewAgentLocalTriptionPopUp = false;
    /////
    pageTitle = "Local Booking Trip Report";
    //for the select option with filter starts
    //    selectedRow: LRDto = new LRDto();
    selectedRow: any;
    controlFromStation = new FormControl();
    controlToStation = new FormControl();
    //    fromStationDataListOptions = [
    //        { id: 1, label: 'ADHIK' },
    //        { id: 2, label: 'AFZAL VASHI' },
    //        { id: 3, label: 'AHMED BHAI' },
    //        { id: 4, label: 'ANOOP' }
    //    ];

    //    toStationDataListOptions = [
    //        { id: 1, label: 'AB04TU5795' },
    //        { id: 2, label: 'AP01X4208' },
    //        { id: 3, label: 'AP02T2209' },
    //        { id: 4, label: 'AP02TB1113' }
    //    ];
    //For From Station
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { mainBookStations: string }) => x.mainBookStations;
    //    For From Station
    lrDtoFromStn: LRDto = new LRDto();
    lrDtoFromStnAll: LRDto = new LRDto();
    lrDtosFromStnOptionsGet: LRDto[];

    srcLists: any;
    srcList: LRDto = new LRDto();
    srcListForBkgAdmin: LRDto = new LRDto();
    rightsOfHirelsipRpt: any;
    ///////
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    //For Sorce Main Stations
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();

    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        // return merge(debouncedText$, inputFocus$).pipe(
        //     map(term => (term === '' ? this.sourceSubStationNameTA
        //         : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        // );
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { source: string }) => x.source;
      agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;


    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];
    ////
    address: any;
    /////
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerEtaTrip: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsEtaTrip: any;
    //for modal starts
    //    animal: string;
    listOfDataToPass: string[];
    routeQueryParams$: Subscription;
    showSpinnerForAction: boolean;
    agentLocalTripService: any;
    agentDtoFromStationAddOption: AgentDetailsDto;
    //for modal ends
    cityName: string;
    rightsToShowReportMode = 'Rights For BkgAgtlocalTrip Show ReportMode';
    fromStationList: Array<any> = [];
    toStationList: Array<any> = [];
    showFromStations = false;
    showToStations = false;


    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    totLrsSummary: number;
    totArtSummary: number;
    chgWtSummary: number;
    viewCustomPrintV1: boolean;

    constructor(private masterReadService : MasterReadService,private masterService : MasterService, private router: Router, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getMainBookingSourceDetails();
            this.getDetails();
        }
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    
    ngOnInit(): void {
        this.dtOptionsEtaTrip = {
        		dom: 'Bfrtip',
                buttons: [
                    {
                     extend: 'excel',
                     text: '<i class="fas fa-file-excel"> Excel</i>',
                     titleAttr: 'Excel',
                     exportOptions: {
                     columns: ':visible'
                      }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": true
            }
    }
    
    ngOnDestroy(): void {
        this.dtTriggerEtaTrip.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerEtaTrip.next();
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    clearFields() {
        $("#"+this.pageId+"mainBookingSourceId").val('');
        $("#"+this.pageId+"destination").val('');
        $("#"+this.pageId+"etaDays").val('');
        this.selectedTripId = 0;
    }

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoSourceStation.mainBookStations = this.userDataDtoReturnSession.companyId;
        
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "All";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log('done');

    };

    //For Destination Listener
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        ///Mode Added
        this.lrDtoDestination.mode = "desttrsp";
    }

    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(

            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];



                        this.destinationList = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                            this.destinationList.push(this.destinationOptions[i].destination);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.hireSlipDtoUserValue = new HireSlipDto();
		this.masterService.getTripETADetails(this.hireSlipDtoUserValue).subscribe(
			(response) => {
				$("#"+this.pageId+"etaTableId").DataTable().destroy();
				this.etaTripTripDataList = [];
				if (response.length == 0) {
					swal({
						title: "ETA For Trip Detail",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.etaTripTripDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerEtaTrip.next();
	            }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	confirmSave(){
		swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveDetails();
            }
        })
	}
	
	saveDetails = () => {
		this.showSpinnerForAction = true;
		this.hireSlipDtoUserValue = new HireSlipDto();
		
		this.selectedFromStation = $("#"+this.pageId+"mainBookingSourceId").val();
		this.selectedToStation = $("#"+this.pageId+"destination").val();
		this.selectedDuration = $("#"+this.pageId+"etaDays").val();
		
		this.hireSlipDtoUserValue.fromStation = this.selectedFromStation;
		this.hireSlipDtoUserValue.toStation = this.selectedToStation;
		this.hireSlipDtoUserValue.countTrucks = this.selectedDuration;
		this.hireSlipDtoUserValue.tripId = this.selectedTripId;
		this.hireSlipDtoUserValue.lastupdatedby = this.userDataDtoReturnSession.userId;
		
		this.masterService.addTripETADetails(this.hireSlipDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "ETA details saved/updated successfully!", "info");
					this.clearFields();
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to save/update the details!", "info");
				}
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while saving the details", "info");
			}, () => console.log('done');
	};
	
	confirmDelete(etaTripData){
		swal({
            title: "Confirmation",
            text: "Sure you want to delete the selected details?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.deleteDetails(etaTripData);
            }
        })
	}
	
	deleteDetails = (etaTripData) => {
		this.showSpinnerForAction = true;
		this.hireSlipDtoUserValue = new HireSlipDto();
		
		this.hireSlipDtoUserValue.lastupdatedby = this.userDataDtoReturnSession.userId;
		this.hireSlipDtoUserValue.tripId = etaTripData.tripId;
		
		this.masterService.deleteTripETADetails(this.hireSlipDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "Selected ETA details deleted!", "info");
					this.clearFields();
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to delete the details!", "info");
				}
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while deleting the details", "info");
			}, () => console.log('done');
	};
	
	rowSelectedEdit(etaTripData) {
		this.selectedTripId = etaTripData.tripId;
		$("#"+this.pageId+"mainBookingSourceId").val(etaTripData.fromStation);
		$("#"+this.pageId+"destination").val(etaTripData.toStation);
		$("#"+this.pageId+"etaDays").val(etaTripData.countTrucks);
	}

    customPrintAgentLocalTrp() {
        if (this.etaTripTripDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Source","Destination","ETA(Days)"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [35,35,30];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.totLrsSummary = this.totArtSummary = this.chgWtSummary = 0;
            for (let i = 0; i < this.etaTripTripDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.etaTripTripDataList[i].tripDateStr, this.etaTripTripDataList[i].vehicleNumber,
                this.etaTripTripDataList[i].tripNo, this.etaTripTripDataList[i].tripFrom, this.etaTripTripDataList[i].deliveryarea,
                this.etaTripTripDataList[i].totalLrs, this.etaTripTripDataList[i].totalArticles, this.etaTripTripDataList[i].chargedWeight];
                /////////

                this.totLrsSummary = this.totLrsSummary + this.etaTripTripDataList[i].totalLrs;
                this.totArtSummary = this.totArtSummary + this.etaTripTripDataList[i].totalArticles;
                this.chgWtSummary = this.chgWtSummary + this.etaTripTripDataList[i].chargedWeight;

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.etaTripTripDataList.length, "", "", "", "", this.totLrsSummary, this.totArtSummary, this.chgWtSummary];

            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];


            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date,To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "Local Booking Trip Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

}
