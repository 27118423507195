<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text"
                                                            class="form-control" [(ngModel)]="agentNameModal"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [ngbTypeahead]="agentNameSearchTA"
                                                            [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName"
                                                            placeholder="Select Source"
                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                            #instanceAgentName="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text"
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            (selectItem)="clickListnerForDestination($event)"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text"
                                                        class="form-control"
                                                        (selectItem)="clickListnerForCommodityList($event)"
                                                        [(ngModel)]="modelCommodityList"
                                                        [ngbTypeahead]="searchCommodityList"
                                                        [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList"
                                                        (focus)="focusCommodityListTA$.next($any($event).target.value)"
                                                        autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Per KG Range Limit &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class=" fas fa-rupee-sign"></i>
                                                            </span>
                                                        </div>
                                                        <input #perKGRangeLimit type="number" class="form-control"
                                                            aria-describedby="basic-addon11"
                                                            id="{{pageId}}perKGRangeLimit" name="perKGRangeLimit">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;
                                        <div class="col-sm-12 col-md-12">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}saveBtn" #saveBtn
                                                    (click)="validateSetupDetails()">Save</button>
                                                <button type="submit" class="btn btn-dark m-r-10"
                                                    id="{{pageId}}clearBtn" (click)="clearDetails()">Clear</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 vl">
                    <div class="row">
                        <div class="col-md-12 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Per KG Rate Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}perKgRateDataTable"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsPerKgRateSetup"
                                            [dtTrigger]="dtTriggerPerKgRateSetup">
                                            <thead>
                                                <tr>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>Per KG Range Limit</th>
                                                    <th>Slabs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let perKgRateDetails of perKgRateDataLists; let i = index">
                                                    <td>{{ perKgRateDetails.source }}</td>
                                                    <td>{{ perKgRateDetails.destination }}</td>
                                                    <td>{{ perKgRateDetails.perKgRangeLimit }}</td>
                                                    <td><button style="padding: 1px 4px; background-color: #ffffff00;"
                                                            class="btn m-r-10" id="{{pageId}}editBtn"
                                                            (click)="rowSelectedView(perKgRateDetails);">
                                                            <i title="View" class="fas fa-edit"></i>
                                                        </button>&nbsp;
                                                        <button
                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                            class="btn m-r-10"
                                                            id="{{pageId}}tableRemoveBtn"
                                                            (click)="userDeleteDetails(perKgRateDetails)">
                                                            <i title="Remove" class="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>