<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showDailyBkgRpt">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label>
                                                    <input class="form-control" id="{{pageId}}fromDate" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input #sourceDropDown id="{{pageId}}dropDownInputFieldSource" type="text" class="form-control" [(ngModel)]="modelDropDownSource" [ngbTypeahead]="searchDropDownInputFieldSource" [resultFormatter]="formatterDropDownSource" [inputFormatter]="formatterDropDownSource"
                                                        placeholder="Select Source" (focus)="focusDropDownSourceTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input #destinationDropDown id="{{pageId}}dropDownInputFieldDestination" type="text" class="form-control" [(ngModel)]="modelDropDownDestination" [ngbTypeahead]="searchDropDownInputFieldDestination" [resultFormatter]="formatterDropDownDestination" [inputFormatter]="formatterDropDownDestination"
                                                        placeholder="Select Destination" (focus)="focusDropDownDestinationTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateBtnSearch()">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAllFields()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="box-body">
                            <table datatable id="{{pageId}}dailyBkgLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDailyBkgReport" [dtTrigger]="dtTriggerDailyBkgReport">
                                <thead>
                                    <tr>
                                        <th>LR Number</th>
                                        <th>Booking Date</th>
                                        <th>Entered Date</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Consignee</th>
                                        <th>Consignor</th>
                                        <th>Art</th>
                                        <th>Art(s) Bulky</th>
                                        <th>Commodity Name</th>
                                        <th>Size</th>
                                        <th>Act Wgt Bulky Art(s)</th>
                                        <th>Chg Wgt Bulky Art(s)</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Chg Wgt As Per CFT</th>
                                        <th>Chg Wgt As Per Range</th>
                                        <th>Chg Wgt Diff</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>B.Freight</th>
                                        <th>Hamali</th>
                                        <th>GC Chg</th>
                                        <th>LC Chg</th>
                                        <th>BC Chg</th>
                                        <th>FOV</th>
                                        <th>AOC</th>
                                        <th>Ins.Chg</th>
                                        <th>Others</th>
                                        <th>DD Amt</th>
                                        <th>Cod Chg</th>
                                        <th>Is Ret Set?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dailyBkgReportData of dailyBkgReportDataList ">
                                        <td>{{ dailyBkgReportData.lrNumber }}</td>
                                        <td>{{ dailyBkgReportData.bookingDateStr }}</td>
                                        <td>{{ dailyBkgReportData.enteredDateStr }}</td>
                                        <td>{{ dailyBkgReportData.source }}</td>
                                        <td>{{ dailyBkgReportData.destination }}</td>
                                        <td>{{ dailyBkgReportData.consigneeName }}</td>
                                        <td>{{ dailyBkgReportData.consignorName }}</td>
                                        <td>{{ dailyBkgReportData.totalArticles }}</td>
                                        <td>{{ dailyBkgReportData.sizeTotArt }}</td>
                                        <td>{{ dailyBkgReportData.commodityName }}</td>
                                        <td>{{ dailyBkgReportData.multiArtSize }}</td>
                                        <td>{{ dailyBkgReportData.sizeTotActWgt }}</td>
                                        <td>{{ dailyBkgReportData.sizeTotChgWgt}}</td>
                                        <td>{{ dailyBkgReportData.actualWeight }}</td>
                                        <td>{{ dailyBkgReportData.chargedWeight}}</td>
                                        <td>{{ dailyBkgReportData.chgWgtCft}}</td>
                                        <td>{{ dailyBkgReportData.chgWgtRange}}</td>
                                        <td>{{ dailyBkgReportData.chgWgtDiff}}</td>
                                        <td>{{ dailyBkgReportData.toPay }}</td>
                                        <td>{{ dailyBkgReportData.paid }}</td>
                                        <td>{{ dailyBkgReportData.baseFreight }}</td>
                                        <td>{{ dailyBkgReportData.hamali }}</td>
                                        <td>{{ dailyBkgReportData.gcCharge }}</td>
                                        <td>{{ dailyBkgReportData.lcChg }}</td>
                                        <td>{{ dailyBkgReportData.bcChg }}</td>
                                        <td>{{ dailyBkgReportData.riskCharge }}</td>
                                        <td>{{ dailyBkgReportData.aoc }}</td>
                                        <td>{{ dailyBkgReportData.insurance }}</td>
                                        <td>{{ dailyBkgReportData.others }}</td>
                                        <td>{{ dailyBkgReportData.ddAmt }}</td>
                                        <td>{{ dailyBkgReportData.codChg }}</td>
                                        <td *ngIf="dailyBkgReportData.isAdminRateSet== false">
                                            {{ dailyBkgReportData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                        <td *ngIf="dailyBkgReportData.isAdminRateSet == true" style='font-size: 13px; color: red; font-weight: bold;'>
                                            {{ dailyBkgReportData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>