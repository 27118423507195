import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-destination-income',
    templateUrl: './destination-income.component.html',
    styleUrls: ['./destination-income.component.css']
})
export class DestinationIncomeComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number; month: number };

    loadingIndicator = true;
    reorderable = true;
    lrDtosToStnOptionsGet: any;
    lrDtoToStn: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    fortNgtSearchDto: FortNightStmtDto = new FortNightStmtDto();
    fromDate: any;
    toDate: any;
    destination: any;
    showSpinnerForAction: any;
    commission: number;
    reducedCommission: number;
    actualCommissionEarned: number;
    rcChg: number;
    hamaliChg: number;
    total: number;



    




    //for datePicker starts
    hoveredDate: NgbDateStruct;
    // fromDate: NgbDateStruct;
    // toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    destAgentIncomeDetailsReportDataList: any;
    onDestroyUnsubscribtionDestAgentIncomeDetailsReport: Subscription;


    controlMain = new FormControl();

    destinationStationOptions: LRDto[];
    lrDtoDestinationStation: LRDto = new LRDto();
    

    public modelDestination: any;
    destinationSubStationNameTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSubStationNameTA
                : this.destinationSubStationNameTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;





    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDestAgentIncomeDetailsReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsDestAgentIncomeDetailsReport: any;

    pageId = "desin";

    constructor(private destAgentIncomeDetailsReport: MasterService, public changeDetectorRef: ChangeDetectorRef,
        private masterReadService: MasterReadService,private router: Router, private http: HttpClient) {
            if (sessionStorage.length == 0) {
                this.isLoggedIn = false;
                swal({
                    title: "Session Expired",
                    text: "Please relogin to access the application!",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    this.logInPage();
                })
            }
            if (this.isLoggedIn) {
                this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                this.gettingToStationList();
            }



    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {


        this.dtOptionsDestAgentIncomeDetailsReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var commission = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var reducedCommission = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actualCommissionEarned = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var rcChg = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var  hamaliChg= api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var total = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total: ' + data.length);
                $(api.column(5).footer()).html(commission);
                $(api.column(6).footer()).html(reducedCommission);
                $(api.column(7).footer()).html(actualCommissionEarned);
                $(api.column(8).footer()).html(rcChg);
                $(api.column(9).footer()).html(hamaliChg);
                $(api.column(10).footer()).html(total);
            },
            

        }
        this.getDataFrmServiceFrTable = this.destAgentIncomeDetailsReport.getSummaryData()
        this.onDestroyUnsubscribtionDestAgentIncomeDetailsReport = this.getDataFrmServiceFrTable.subscribe(data => {
            this.destAgentIncomeDetailsReportDataList = data['data'];
            this.dtTriggerDestAgentIncomeDetailsReport.next();
        });


    }

    ngOnDestroy(): void {
        this.dtTriggerDestAgentIncomeDetailsReport.unsubscribe();

        this.onDestroyUnsubscribtionDestAgentIncomeDetailsReport.unsubscribe();
    }

    gettingToStationDetails() {
        this.lrDtoToStn = new LRDto();
        this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStn.mode = "other";
        this.lrDtoToStn.reportMode = "subStation";
        console.log(this.lrDtoToStn);

    }
    gettingToStationList = () => {
        // this.showSpinnerForAction = true;
        this.gettingToStationDetails();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoToStn).subscribe(
            (response) => {
                // this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.destinationSubStationNameTA = [];
                    this.lrDtosToStnOptionsGet = [];
                    this.lrDtosToStnOptionsGet = response;
                    for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
                        this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);

                    }




                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                // this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    getDestIncomeSearch() {
        this.fortNgtSearchDto = new FortNightStmtDto();
        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        this.destination = $("#" + this.pageId + "destination").val();

        this.fortNgtSearchDto.fromPeriod = this.fromDate;
        this.fortNgtSearchDto.toPeriod = this.toDate;
        this.fortNgtSearchDto.destination = this.destination;

        console.log("this.fortNgtSearchDto");
        console.log(this.fortNgtSearchDto); 
    }

    getDestIncomeList = () => {
        this.getDestIncomeSearch();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestIncomeListSearch(this.fortNgtSearchDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.destAgentIncomeDetailsReportDataList = response;
                    console.log("this.destAgentIncomeDetailsReportDataList");
                    console.log(this.destAgentIncomeDetailsReportDataList);
                    $("#" + this.pageId + "destAgentIncomeDetailsReportId").DataTable().destroy();
                    this.dtTriggerDestAgentIncomeDetailsReport.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting TDS Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

}
