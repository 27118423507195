import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { MaterialModule } from "src/app/material.module";
import { AgentRoutes } from 'src/app/fortnightStatement/agent/agent.routing';
import { CommissionMasterComponent } from 'src/app/fortnightStatement/agent/commission-master/commission-master.component';
import { CommodityRateMasterComponent } from 'src/app/fortnightStatement/agent/commodity-rate-master/commodity-rate-master.component';
import { DestinationAgentStatementGenerationComponent } from 'src/app/fortnightStatement/agent/destination-agent-statement-generation/destination-agent-statement-generation.component';
import { AmountReceivableFromAgentComponent } from 'src/app/fortnightStatement/agent/amount-receivable-from-agent/amount-receivable-from-agent.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(AgentRoutes),
         FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule,CashmemoReportModule],
    declarations: [
        CommissionMasterComponent, CommodityRateMasterComponent,DestinationAgentStatementGenerationComponent,AmountReceivableFromAgentComponent
    ]
})
export class AgentModule { }
