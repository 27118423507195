<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Debit Note Summary Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}fromDate">
													<label>From Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
														#fromDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="fromDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDate"
														class="form-control" placeholder="yyyy-mm-dd"
														name="toDates" ngbDatepicker #toDates="ngbDatepicker">
													<div class="input-group-append" (click)="toDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-road"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Destination"
															[formControl]="controlMain"
															[appAutocomplete]="autocompleteDestination">
													</div>
												</div>
											</div>

											<app-autocomplete #autocompleteDestination="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(destinationOptions | filter: controlMain.value) as resultDestination">
											<app-option *ngFor="let option of resultDestination"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultDestination.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>




									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10"
							id="{{pageId}}searchBtn">Search</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
					</div>
				</div>
				<div class="col-md-9 vl p-t-10">
					<div class="card-body">
						<div class="box-body">
							<h6 class="card-title border_bottom">Debit Note Summary
								Report</h6>
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsDebitNoteSummaryReport"
								[dtTrigger]="dtTriggerDebitNoteSummaryReport">

								<thead>
									<tr>
										<th>Destination</th>
										<th>Check Post Expenses</th>
										<th>Claims</th>
										<th>Delhi Party Less</th>
										<th>Door Delivery</th>
										<th>Load&UnLoad Hamali</th>
										<th>Mumbai Parties Less</th>
										<th>Sharing Of Rent</th>
										<th>Tempo Freight</th>
										<th>Others</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let debitNoteSummaryData of debitNoteSummaryReportDataList ">
										<td>{{ debitNoteSummaryData.destination }}</td>
										<td>{{ debitNoteSummaryData.checkPostExpenses }}</td>
										<td>{{ debitNoteSummaryData.claims }}</td>
										<td>{{ debitNoteSummaryData.delhiPartyLess }}</td>
										<td>{{ debitNoteSummaryData.doorDelivery }}</td>
										<td>{{ debitNoteSummaryData.loadUnloadHamali }}</td>
										<td>{{ debitNoteSummaryData.mumbaiPartiesLess }}</td>
										<td>{{ debitNoteSummaryData.sharingOfRent }}</td>
										<td>{{ debitNoteSummaryData.tempoFreight }}</td>
										<td>{{ debitNoteSummaryData.others }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>
