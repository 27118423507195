<!-- Row -->
<div class="row" id="{{pageId}}statementVerRptId" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Party Less Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Report Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                    </span>
                                                </div>
                                                <select #searchBy class="custom-select col-12" id="{{pageId}}rptTypeId" (change)="searchByMode(searchBy.value)" [(ngModel)]="searchByType">
                                                    <option value="chkedUnChked" *ngIf="viewAdminFields">Checked &
                                                        Un-Checked
                                                        LRs</option>
                                                    <option value="chkedUnChkedDatewise" *ngIf="viewAdminFields">Checked
                                                        & Unchecked
                                                        LRs Date Wise</option>
                                                    <option value="enteredLess" *ngIf="viewAdminFields">Entered Less LRs
                                                    </option>
                                                    <option value="enteredLessDatewise" *ngIf="viewAdminFields">Entered
                                                        Less LRs Date Wise</option>
                                                    <option value="autoRateLess" *ngIf="viewAdminFields">Auto Rate Less
                                                        LRs</option>
                                                    <option value="stmtNoWise">Statement No</option>
                                                    <option value="stmtDateWise">Statement Date</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div [hidden]="!viewAdminFields">
                                        <div class="col-sm-12 col-md-12" [hidden]="viewStmtNoWiseTable">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}destination">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input [disabled]="!viewAdminFields" id="{{pageId}}destinationId" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" [hidden]="viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates" [(ngModel)]="fromDatesModal" autocomplete="off">
                                                <div class="input-group-append" (click)="fromDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off">
                                                <div class="input-group-append" (click)="toDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- for date wise search -->
                                    <div class="col-sm-12 col-md-12" [hidden]="!viewDateWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDatesDateWise" ngbDatepicker #fromDatesDateWise="ngbDatepicker" id="{{pageId}}fromDatesDateWise" [(ngModel)]="fromDatesDateWiseModal"
                                                    autocomplete="off">
                                                <div class="input-group-append" (click)="fromDatesDateWise.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="!viewDateWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Date</label> <input id="{{pageId}}toDatesDateWise" class="form-control" placeholder="yyyy-mm-dd" name="toDatesDateWise" ngbDatepicker #toDatesDateWise="ngbDatepicker" [(ngModel)]="toDatesDateWiseModal"
                                                    autocomplete="off">
                                                <div class="input-group-append" (click)="toDatesDateWise.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-sm-12 col-md-12" [hidden]="!viewDateWiseTable">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}destinationDateWise">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}destinationIdDateWise" type="text" class="form-control" (keyup)="clickListnerForDestinationDateWise($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Statement No</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                    </span>
                                                </div>
                                                <input type="text" id="{{pageId}}stmtNo" class="form-control" aria-describedby="basic-addon11" (keyup)="getStatmentNumberWiseService($event)">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" id="{{pageId}}agentName" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LR Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                    </span>
                                                </div>
                                                <input type="text" id="{{pageId}}lrNo" class="form-control" aria-describedby="basic-addon11" (keyup)="lrSearchMtd($event)" [disabled]="blockLessCheckFinishedFields" (blur)="lrSearchMtdBlur($event)">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label>Remarks</label>
                                                <div class="form-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-notes-medical"></i>
                                                    </span>
                                                </div>
                                                <textarea type="text" class="form-control" id="{{pageId}}remarks" rows="2" (keyup)="lrRemakrsEvent($event)"></textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Less Amount</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" id="{{pageId}}lessAmt" class="form-control" aria-describedby="basic-addon11" (keyup)="getLrStatus($event)" [disabled]="blockLessCheckFinishedFields">
                                            </div>
                                        </div>
                                    </div>

                                    <div [hidden]="!viewAdminFields">
                                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <label>Commodity</label> <input id="{{pageId}}commodityCombo" type="text" class="form-control" [(ngModel)]="commodityCombo" [ngbTypeahead]="commodityComboSearchTA" (focus)="focusCommodityComboTA$.next($any($event).target.value)"
                                                            (click)="clickCommodityComboTA(instanceCommodityCombo)" #instanceCommodityCombo="ngbTypeahead" #elem />

                                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}btnRecalculate" (click)="rcBtmValidate();" style="font-size: 14px; width: 33px; height: 30px; padding-left: 6px; margin-right: -16px">RC</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="!viewAdminFields">
                                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Discount Amount</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}discountAmt" class="form-control" [readonly]=true aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="mLessDetailsPopup();" *ngIf="viewAdminFields">MLess</button>
                        <button type="submit" class="btn btn-dark" (click)="clearBtn();" id="{{pageId}}clearBtn">Clear</button>
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}finishBtn" (click)="confirmFinishBtn();" *ngIf="!viewAdminFields" [disabled]="blockLessCheckFinishedFields">Finish</button>
                    </div>

                    <hr style="width: 80%;">
                    <div [hidden]="!viewAdminFields">
                        <div class="col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <h6 style="font-size: 15px;">Standard Rate Master</h6>
                        </div>
                    </div>

                    <div class="col-md-12" [hidden]="!viewAdminFields">

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterRate" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterGcChg" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Dest. Hamali</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterDestHamali" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Receipt Chg</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterRecChg" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Service Chg</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterSerChg" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Source Station</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterSource" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" [hidden]=true>
                            <a id="{{pageId}}fromPeriodForExport"></a>
                        </div>
                        <div class="col-sm-12 col-md-12" [hidden]=true>
                            <a id="{{pageId}}toPeriodForExport"></a>
                        </div>


                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Per Unit</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterPerUnit" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" [hidden]="!viewStmtNoWiseTable">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Discount</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                        </span>
                                    </div>
                                    <input type="text" id="{{pageId}}masterDiscount" class="form-control" aria-describedby="basic-addon11" [readonly]=true>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div class="col-md-9 vl p-t-10">
                    <h6 *ngIf="blockLessCheckFinishedFields" class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Less Check For this statement has been finished</h6>
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8">
                                <h6 class="card-title border_bottom" id="{{pageId}}textId"></h6>
                            </div>
                            <div [hidden]="!viewAdminFields">
                                <div class="col-sm-12 col-md-4" [hidden]="!viewStmtNoWiseTable">


                                    <button type="submit" style="float: right;" class="dt-button" (click)="validateDiifLessLRBtn()" id="{{pageId}}diffLessBtn">
                                        <span><i class="fas fa-search">View Diff Less Lr</i></span>
                                    </button>
                                    <!-- <button type="submit" style="float: right;" class="dt-button"
									(click)="getDataForExport()" id="{{pageId}}exportBtn">
									<span><i class="fas fa-file-excel">Excel</i></span>
								</button> -->
                                </div>
                            </div>
                        </div>
                        <div class="box-body" [hidden]="!viewChkUnCkhedLessTable">
                            <!-- 							<button type="submit" class="dt-button" style="margin-left: 92%;" -->
                            <!-- 								(click)="customPrintStatementVerRpt()" id="{{pageId}}printAllBtn"> -->
                            <!-- 								<span><i class="fas fa-print">Print</i></span> -->
                            <!-- 							</button> -->
                            <table datatable id="{{pageId}}totalLessTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTotalLess" [dtTrigger]="dtTriggerTotalLess">

                                <thead>
                                    <tr>
                                        <th>Destination</th>
                                        <th>Stmt No</th>
                                        <th>Stmt Date</th>
                                        <th>From Period</th>
                                        <th>To Period</th>
                                        <th>Tot Lrs</th>
                                        <th>Tot Amt</th>
                                        <th>Tot Less</th>
                                        <th>Less Lrs</th>
                                        <th>Checked Lrs</th>
                                        <th>Unchecked Lrs</th>
                                        <th [hidden]="!viewAdminFields">Act Less</th>
                                        <th>Ent Less</th>
                                        <th [hidden]="!viewAdminFields">Diff Less</th>
                                        <th>Action</th>
                                        <th>Statement Copy</th>
                                        <th>Statement Copy 2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let totalLessData of totalLessDataList ">
                                        <td>{{ totalLessData.destination }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(totalLessData,'statementWise')">
                                            {{ totalLessData.stmtId }}</td>
                                        <td>{{ totalLessData.stmtDateStr }}</td>
                                        <td>{{ totalLessData.fromPeriodStr }}</td>
                                        <td>{{ totalLessData.toPeriodStr }}</td>
                                        <td>{{ totalLessData.totalLrs }}</td>
                                        <td>{{ totalLessData.totalAmount }}</td>
                                        <td>{{ totalLessData.mLessAmt }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(totalLessData,'lesslrs')">
                                            {{ totalLessData.lessLrs }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(totalLessData,'checked')">
                                            {{ totalLessData.checkedLrs }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(totalLessData,'unchecked')">
                                            {{ totalLessData.unCheckedLrs }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsV2Popup(totalLessData,'statementWise')" [hidden]="!viewAdminFields">{{ totalLessData.actualLessAmt }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsV2Popup(totalLessData,'statementWise')">{{ totalLessData.enteredLessAmt }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsV2Popup(totalLessData,'statementWise')" [hidden]="!viewAdminFields">{{ totalLessData.diffValue }}</td>
                                        <!-- <td><button type="submit"
												class="btn btn-primary m-r-10"
												style="padding: 1px 4px;"
												id="{{pageId}}getRetrunLrBtn"
												(click)="setUploadMtd(totalLessData,uploadPopUpTemplate);">
												<i class="fas fa-file-alt" title="Upload"><h6>Upload</h6></i>
											</button></td> -->
                                        <td>
                                            <i class="fas fa-upload upload-icon-custome" (click)="setUploadMtd(totalLessData,uploadPopUpTemplate);" title="Upload"> Upload</i>
                                        </td>
                                        <td>
                                            <a (click)="getImgForCpy1(totalLessData);" class="pointer"> <u>{{
                                                    totalLessData.statementCopyURL == null ? "NA" :
                                                    totalLessData.statementCopyURL == "NA" ? "NA" : "View
                                                    Statement Document" }}</u></a>
                                        </td>
                                        <td>
                                            <a (click)="getImgForCpy2(totalLessData);" class="pointer"> <u>{{
                                                    totalLessData.statementCopyURL2 == null ? "NA" :
                                                    totalLessData.statementCopyURL2 == "NA" ? "NA" :
                                                    "View Statement Document" }}</u></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!viewEnteredLessTable">
                            <!-- for entered less lrs -->
                            <table datatable id="{{pageId}}enteredLessTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsEnteredLess" [dtTrigger]="dtTriggerEnteredLess">
                                <thead>
                                    <tr>
                                        <th>Stmt No</th>
                                        <th>Stmt Dt</th>
                                        <th>From Dt</th>
                                        <th>To Dt</th>
                                        <th>Tot Lrs</th>
                                        <th>Ent Less Chk</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Act Less</th>
                                        <th>Ent Less</th>
                                        <th>Diff Less</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let enteredLessData of enteredLessDataList ">
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(enteredLessData,'statementWise')">
                                            {{ enteredLessData.stmtId }}</td>
                                        <td>{{ enteredLessData.stmtDateStr }}</td>
                                        <td>{{ enteredLessData.fromPeriodStr }}</td>
                                        <td>{{ enteredLessData.toPeriodStr }}</td>
                                        <td>{{ enteredLessData.stmttotalLrs }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(enteredLessData,'Entredlesslrs')">
                                            {{ enteredLessData.totalLrs }}</td>
                                        <td>{{ enteredLessData.totalArticles }}</td>
                                        <td>{{ enteredLessData.totalActWgt }}</td>
                                        <td>{{ enteredLessData.totalChgWgt }}</td>
                                        <td>{{ enteredLessData.actualLessAmt }}</td>
                                        <td>{{ enteredLessData.enteredLessAmt }}</td>
                                        <td>{{ enteredLessData.diffValue }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!viewAutoLessTable">
                            <!-- for auto less lrs -->
                            <table datatable id="{{pageId}}autoLessTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAutoLess" [dtTrigger]="dtTriggerAutoLess">
                                <thead>
                                    <tr>
                                        <th>Stmt No</th>
                                        <th>Stmt Dt</th>
                                        <th>From Dt</th>
                                        <th>To Dt</th>
                                        <th>Tot Lrs</th>
                                        <th>System Less Lrs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let autoLessData of autoLessDataList ">
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(autoLessData,'statementWise')">
                                            {{ autoLessData.stmtId }}</td>
                                        <td>{{ autoLessData.stmtDateStr }}</td>
                                        <td>{{ autoLessData.fromPeriodStr }}</td>
                                        <td>{{ autoLessData.toPeriodStr }}</td>
                                        <td>{{ autoLessData.totalLrs }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalLrsDetailsPopup(autoLessData,'statementlesslrsByAuto')">
                                            {{ autoLessData.lessLrs }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!viewStmtNoWiseTable">
                            <table datatable id="{{pageId}}stmtNoWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStmtNoWise" [dtTrigger]="dtTriggerStmtNoWise">

                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>LR No</th>
                                        <th>Src</th>
                                        <th>Consignee</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th [hidden]="!viewAdminFields">Act Less</th>
                                        <th>Ent Less</th>
                                        <th [hidden]="!viewAdminFields">Diff Less</th>
                                        <th>Commodity</th>
                                        <th>Remarks</th>
                                        <th>Action</th>

                                        <th [hidden]=true>Bkg Date</th>
                                        <th [hidden]=true>Tot Amt</th>
                                        <th [hidden]=true>FOV</th>
                                        <th [hidden]=true>Unit</th>
                                        <th [hidden]=true>Per Unit</th>
                                        <th [hidden]=true>Rate</th>
                                        <th [hidden]=true>GC Chg</th>
                                        <th [hidden]=true>S.H</th>
                                        <th [hidden]=true>LC</th>
                                        <th [hidden]=true>BC</th>
                                        <th [hidden]=true>AOC</th>
                                        <th [hidden]=true>DD</th>
                                        <th [hidden]=true>B.Frgt</th>
                                        <!-- charges as per rate -->
                                        <th [hidden]=true>Rate B.Frgt</th>
                                        <th [hidden]=true>Rate GC</th>
                                        <th [hidden]=true>Rate S.H</th>
                                        <th [hidden]=true>Rate LC</th>
                                        <th [hidden]=true>Rate BC</th>
                                        <th [hidden]=true>Rate AOC</th>
                                        <th [hidden]=true>Rate DD</th>
                                        <th [hidden]=true>Rate FOV</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let stmtNoDetailsData of stmtNoDetailsDataList let i = index">
                                        <td>{{i + 1}}</td>
                                        <td>{{ stmtNoDetailsData.lrNumber }}</td>
                                        <td>{{ stmtNoDetailsData.agentCode }}</td>
                                        <td>{{ stmtNoDetailsData.consigneeName }}</td>
                                        <td>{{ stmtNoDetailsData.totalArticles }}</td>
                                        <td>{{ stmtNoDetailsData.actualWeight }}</td>
                                        <td>{{ stmtNoDetailsData.chargedWeight }}</td>
                                        <td [hidden]="!viewAdminFields">{{ stmtNoDetailsData.actualLessAmt }}</td>
                                        <td>{{ stmtNoDetailsData.enteredLessAmt }}</td>
                                        <td [hidden]="!viewAdminFields">{{ stmtNoDetailsData.diffValue }}</td>
                                        <td>{{ stmtNoDetailsData.commodityName }}</td>
                                        <td>{{ stmtNoDetailsData.remarks }}</td>
                                        <td>
                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmRemoveStmtWise(stmtNoDetailsData)">
                                                <i title="Remove" class="fas fa-trash"></i>
                                            </button>
                                        </td>

                                        <td [hidden]=true>{{ stmtNoDetailsData.bookingDateStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.totalAmount }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.riskCharge }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.mode }}</td>
                                        <!-- <td [hidden]=true>{{ stmtNoDetailsData.ratePerUnit}}</td> -->
                                        <td [hidden]=true>{{ stmtNoDetailsData.ratePerUnit == "Chargable Weight" ? "CW" : stmtNoDetailsData.ratePerUnit =="Actual Weight" ? "AW" : stmtNoDetailsData.ratePerUnit =="Article" ? "Art" : stmtNoDetailsData.ratePerUnit== null ?
                                            "NA" : stmtNoDetailsData.ratePerUnit== undefined ? "NA" : stmtNoDetailsData.ratePerUnit== '' ? "NA" : stmtNoDetailsData.ratePerUnit}}
                                        </td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rate }}</td>
                                        <!-- <td [hidden]=true>{{ stmtNoDetailsData.gcCharge }}</td>
										<td [hidden]=true>{{ stmtNoDetailsData.hamaliCharge ==
											true ? "Yes" : "No" }}</td>
										<td [hidden]=true>{{ stmtNoDetailsData.lcCharge == true ?
											"Yes" : "No" }}</td>
										<td [hidden]=true>{{ stmtNoDetailsData.aocChargeNew ==
											true ? "Yes" : "No" }}</td>
										<td [hidden]=true>{{ stmtNoDetailsData.ddChargeNew ==
											true ? "Yes" : "No" }}</td> -->
                                        <td [hidden]=true>{{ stmtNoDetailsData.gcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.srcHamaliChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.lcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.bcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.aocChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.ddChgStr }}</td>

                                        <td [hidden]=true>{{ stmtNoDetailsData.baseFreight }}</td>
                                        <!-- charges as per rate -->
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateBaseFreightStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateGcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateSrcHamaliChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateLcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateBcChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateAocChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateddChgStr }}</td>
                                        <td [hidden]=true>{{ stmtNoDetailsData.rateFovChgStr }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=true></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body row" [hidden]="!viewStmtNoWiseTable" style="padding-top: 15px;">
                            <div class="col-md-4">
                                <h6>Main Station Wise</h6>
                                <table datatable id="{{pageId}}stmtNoMainSrcWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStmtNoMainSrcWise" [dtTrigger]="dtTriggerStmtNoMainSrcWise">

                                    <thead>
                                        <tr>
                                            <th>Station</th>
                                            <th>Tot Lrs</th>
                                            <th [hidden]="!viewAdminFields">Act Less</th>
                                            <th>Ent Less</th>
                                            <th [hidden]="!viewAdminFields">Diff Less</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stmtNoMainSrcDetailsData of stmtNoMainSrcDetailsDataList ">
                                            <td>{{ stmtNoMainSrcDetailsData.mainstation }}</td>
                                            <td>{{ stmtNoMainSrcDetailsData.totalLrs }}</td>
                                            <td [hidden]="!viewAdminFields">{{ stmtNoMainSrcDetailsData.actualLessAmt }}
                                            </td>
                                            <td>{{ stmtNoMainSrcDetailsData.enteredLessAmt }}</td>
                                            <td [hidden]="!viewAdminFields">{{ stmtNoMainSrcDetailsData.diffValue }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td [hidden]="!viewAdminFields"></td>
                                            <td></td>
                                            <td [hidden]="!viewAdminFields"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="col-md-8">
                                <h6>Blocked Lr Details</h6>
                                <table datatable id="{{pageId}}stmtNoBlockedLrTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStmtNoBlockedLr" [dtTrigger]="dtTriggerStmtNoBlockedLr">

                                    <thead>
                                        <tr>
                                            <th>LR No</th>
                                            <th>Src</th>
                                            <th>Consignee</th>
                                            <th>Art</th>
                                            <th>Act Wgt</th>
                                            <th>Chg Wgt</th>
                                            <th [hidden]="!viewAdminFields">Act Less</th>
                                            <th>Ent Less</th>
                                            <th [hidden]="!viewAdminFields">Diff Less</th>
                                            <th>Commodity</th>
                                            <th [hidden]=true>Bkg Date</th>
                                            <th [hidden]=true>Tot Amt</th>
                                            <th [hidden]=true>FOV</th>
                                            <th [hidden]=true>Unit</th>
                                            <th [hidden]=true>Per Unit</th>
                                            <th [hidden]=true>Rate</th>
                                            <th [hidden]=true>GC Chg</th>
                                            <th [hidden]=true>S.H</th>
                                            <th [hidden]=true>LC</th>
                                            <th [hidden]=true>AOC</th>
                                            <th [hidden]=true>DD</th>
                                            <th [hidden]=true>B.Frgt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stmtNoBlockedData of stmtNoBlockedLrDataList let i = index">
                                            <td>{{ stmtNoBlockedData.lrNumber }}</td>
                                            <td>{{ stmtNoBlockedData.agentCode }}</td>
                                            <td>{{ stmtNoBlockedData.consigneeName }}</td>
                                            <td>{{ stmtNoBlockedData.totalArticles }}</td>
                                            <td>{{ stmtNoBlockedData.actualWeight }}</td>
                                            <td>{{ stmtNoBlockedData.chargedWeight }}</td>
                                            <td [hidden]="!viewAdminFields">{{ stmtNoBlockedData.actualLessAmt }}</td>
                                            <td>{{ stmtNoBlockedData.enteredLessAmt }}</td>
                                            <td [hidden]="!viewAdminFields">{{ stmtNoBlockedData.diffValue }}</td>
                                            <td>{{ stmtNoBlockedData.commodityName }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.bookingDateStr }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.totalAmount }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.riskCharge }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.mode }}</td>
                                            <!-- <td [hidden]=true>{{ stmtNoBlockedData.ratePerUnit}}</td> -->
                                            <td [hidden]=true>{{ stmtNoBlockedData.ratePerUnit == "Chargable Weight" ? "CW" : stmtNoBlockedData.ratePerUnit =="Actual Weight" ? "AW" : stmtNoBlockedData.ratePerUnit =="Article" ? "Art" : stmtNoBlockedData.ratePerUnit== null
                                                ? "NA" : stmtNoBlockedData.ratePerUnit== undefined ? "NA" : stmtNoBlockedData.ratePerUnit== '' ? "NA" : stmtNoBlockedData.ratePerUnit}}
                                            </td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.rate }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.gcCharge }}</td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.hamaliCharge == true ? "Yes" : "No" }}
                                            </td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.lcCharge == true ? "Yes" : "No" }}
                                            </td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.aocChargeNew == true ? "Yes" : "No" }}
                                            </td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.ddChargeNew == true ? "Yes" : "No" }}
                                            </td>
                                            <td [hidden]=true>{{ stmtNoBlockedData.baseFreight }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td [hidden]="!viewAdminFields"></td>
                                            <td></td>
                                            <td [hidden]="!viewAdminFields"></td>
                                            <td></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                            <td [hidden]=true></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div class="box-body" [hidden]="!viewChkUnCkhedLessDatewiseTable">
                            <table datatable id="{{pageId}}lrDetailsChkUnCkhdDateWiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrDetailsChkUnCkhdDateWise" [dtTrigger]="dtTriggerLrDetailsChkUnCkhdDateWise">
                                <thead>
                                    <tr>
                                        <th>Bkg Dt</th>
                                        <th>LR Number</th>
                                        <th>Agent</th>
                                        <th>Consignee</th>
                                        <th>To Pay</th>
                                        <th>M Less Amt</th>
                                        <th>Total Amt</th>
                                        <th>Articles</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Commodity</th>
                                        <th [hidden]="!viewAdminFields">Act Less</th>
                                        <th>Ent Less</th>
                                        <th [hidden]="!viewAdminFields">Diff Less</th>
                                        <th>Stmt No</th>
                                        <th>Stmt Date</th>
                                        <th>From Period</th>
                                        <th>To Period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lrDetailsData of checkedUncheckedLrsDatewiseList ">
                                        <td>{{ lrDetailsData.bookingDateStr }}</td>
                                        <td>{{ lrDetailsData.lrNumber }}</td>
                                        <td>{{ lrDetailsData.agentName }}</td>
                                        <td>{{ lrDetailsData.consigneeName }}</td>
                                        <td>{{ lrDetailsData.toPay }}</td>
                                        <td>{{ lrDetailsData.mLessAmt }}</td>
                                        <td>{{ lrDetailsData.totalAmount }}</td>
                                        <td>{{ lrDetailsData.totalArticles }}</td>
                                        <td>{{ lrDetailsData.actualWeight }}</td>
                                        <td>{{ lrDetailsData.chargedWeight }}</td>
                                        <td>{{ lrDetailsData.commodityName }}</td>
                                        <td [hidden]="!viewAdminFields">{{ lrDetailsData.actualLessAmt }}</td>
                                        <td>{{ lrDetailsData.enteredLessAmt }}</td>
                                        <td [hidden]="!viewAdminFields">{{ lrDetailsData.diffValue }}</td>
                                        <td>{{ lrDetailsData.enteredStmtNo }}</td>
                                        <td>{{ lrDetailsData.stmtDateStr }}</td>
                                        <td>{{ lrDetailsData.fromPeriodStr }}</td>
                                        <td>{{ lrDetailsData.toPeriodStr }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!viewEnteredLessDatewiseTable">
                            <table datatable id="{{pageId}}enteredLessTableDatewiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsEnteredLessDatewise" [dtTrigger]="dtTriggerEnteredLessDatewise">

                                <thead>
                                    <tr>
                                        <th>Bkg Dt</th>
                                        <th>LR Number</th>
                                        <th>Agent</th>
                                        <th>Consignee</th>
                                        <th>B.Frgt</th>
                                        <th>S.Ham Chg</th>
                                        <th>LC Chg</th>
                                        <th>BC Chg</th>
                                        <th>AOC Chg</th>
                                        <th>FOV Chg</th>
                                        <th>DD Chg</th>
                                        <th>GC Chg</th>
                                        <th>Oth Chg</th>
                                        <th>To Pay</th>
                                        <th>M Less Amt</th>
                                        <th>Total Amt</th>
                                        <th>Articles</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Commodity</th>
                                        <th [hidden]="!viewAdminFields">Act Less</th>
                                        <th>Ent Less</th>
                                        <th [hidden]="!viewAdminFields">Diff Less</th>
                                        <th>Rate For</th>
                                        <th>Stmt No</th>
                                        <th>Unit</th>
                                        <th>Per Unit</th>
                                        <th>Rate</th>
                                        <th>GC</th>
                                        <th>S.Ham</th>
                                        <th>LC</th>
                                        <th>BC</th>
                                        <th>AOC</th>
                                        <th>FOV</th>
                                        <th>DD</th>
                                        <th>Oth</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lrDetailsData of enteredLessLrsDatewiseList ">
                                        <td>{{ lrDetailsData.bookingDateStr }}</td>
                                        <td>{{ lrDetailsData.lrNumber }}</td>
                                        <td>{{ lrDetailsData.agentName }}</td>
                                        <td>{{ lrDetailsData.consigneeName }}</td>
                                        <td>{{ lrDetailsData.baseFreight }}</td>
                                        <td>{{ lrDetailsData.srcHamali }}</td>
                                        <td>{{ lrDetailsData.lcChg }}</td>
                                        <td>{{ lrDetailsData.bcChg }}</td>
                                        <td>{{ lrDetailsData.aoc }}</td>
                                        <td>{{ lrDetailsData.riskCharge }}</td>
                                        <td>{{ lrDetailsData.ddAmt }}</td>
                                        <td>{{ lrDetailsData.gcChargeLr }}</td>
                                        <td>{{ lrDetailsData.others }}</td>
                                        <td>{{ lrDetailsData.toPay }}</td>
                                        <td>{{ lrDetailsData.mLessAmt }}</td>
                                        <td>{{ lrDetailsData.totalAmount }}</td>
                                        <td>{{ lrDetailsData.totalArticles }}</td>
                                        <td>{{ lrDetailsData.actualWeight }}</td>
                                        <td>{{ lrDetailsData.chargedWeight }}</td>
                                        <td>{{ lrDetailsData.commodityName }}</td>

                                        <td [hidden]="!viewAdminFields">{{ lrDetailsData.actualLessAmt }}</td>
                                        <td>{{ lrDetailsData.enteredLessAmt }}</td>
                                        <td [hidden]="!viewAdminFields">{{ lrDetailsData.diffValue }}</td>
                                        <td>{{ lrDetailsData.rateDest}}</td>
                                        <td>{{ lrDetailsData.enteredStmtNo }}</td>
                                        <td>{{ lrDetailsData.mode }}</td>
                                        <td>{{ lrDetailsData.ratePerUnit }}</td>
                                        <td>{{ lrDetailsData.rate }}</td>
                                        <td>{{ lrDetailsData.gcCharge }}</td>
                                        <td>{{ lrDetailsData.hamaliCharge==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.lcCharge==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.bcCharge==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.aOCCharge==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.riskChg==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.dDCharge==true?"Yes":"No" }}
                                        </td>
                                        <td>{{ lrDetailsData.othersCharge==true?"Yes":"No" }}
                                        </td>


                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #uploadPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Upload</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Uploading Documnents....</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Statement Copy:</label> <input type="file" id="{{pageId}}partyLessChkStmtFile1Upload" (change)="onFileChangedPartyLessChkStmt1($event)" #fileInputPartyLessChkStmt1 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Statement Copy:</label> <input type="file" id="{{pageId}}partyLessChkStmtFile2Upload" (change)="onFileChangedPartyLessChkStmt2($event)" #fileInputPartyLessChkStmt2 />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadPartyLessChkStmtBtnPopUp()">Upload</button>
                                    </div>

                                </div>
                                <br>
                                <div class="row">


                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closePartyLessChkStmtBtnPopUp()">Cancel</button>
                                        <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}applyBtnId" (click)="applyPartyLessChkStmtBtnPopUp()">Apply</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>


<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>