<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetails">
			<ng-template #content let-a="close" let-b="close" let-c="close"
				let-d="dismiss">
			<div class="modal-header">
				<h6 class="modal-title" id="{{pageId}}modal-basic-title">LR Delivery</h6>
				<button type="button" class="close" aria-label="Close"
					(click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<form>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group">
										<label>Mode:</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-search"></i>
											</span>
										</div>
										<select class="custom-select col-12" id="{{pageId}}marksAs"
											name="markAs" #markAs (change)="markAsMode(markAs.value)">
											<option value="delivered">Delivered</option>
											<option value="unDelivered">UnDelivered</option>

										</select>
									</div>
								</div>
							</div>

							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group">
										<label>Delivery Date:</label> <input id="{{pageId}}deliveryDate"
											class="form-control" placeholder="yyyy-mm-dd"
											name="deliveryDates" ngbDatepicker
											#deliveryDates="ngbDatepicker">
										<div class="input-group-append"
											(click)="deliveryDates.toggle()">
											<span class="input-group-text"> <i
												class="fa fa-calendar"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="unDeliveredView">
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}reasonForReturn">
												<label>Reason For Return:</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder=" Select Reason" [formControl]="control"
													[appAutocomplete]="autocomplete">
											</div>
										</div>
									</div>

									<app-autocomplete #autocomplete="appAutocomplete">
									<ng-template appAutocompleteContent> <ng-container
										*ngIf="(reasonForReturnOptions | filter: control.value) as resultReasonForReturn">
									<app-option *ngFor="let option of resultReasonForReturn"
										[value]="option.label"> {{ option.label }} </app-option> <app-option
										class="no-result" *ngIf="!resultReasonForReturn.length">No
									result</app-option> </ng-container> </ng-template> </app-autocomplete>
								</div>
							</div>
							<!-- 							the below is hidded till now we have not found the hide and show in autocomplete -->
							<div [hidden]="!newReason" class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}newReason">
										<label>New Reason :</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-file-alt"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}lrNumber">
										<label>LR Number :</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-file-alt"></i>
											</span>
										</div>
										<input type="text" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-dark"
					(click)="a('Delivered click')" disabled>Delivered</button>
				<button type="button" class="btn btn-outline-dark"
					(click)="b('Undelivered click')" disabled>UnDelivered</button>
				<button type="button" class="btn btn-outline-dark"
					(click)="c('Cancel click')">Cancel</button>
			</div>
			</ng-template>

			<a style="cursor: pointer; color: blue; font-weight: bolder;"
				(click)="open2(content)">Click Here</a>
		</div>
	</div>
</div>