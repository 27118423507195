<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showloaderPerforRpt">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Loader Performance Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<!-- spinner start-->
									<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<mat-progress-bar mode="indeterminate" style="color: green;">
												</mat-progress-bar>
												<br>
												<h6 class="card-title" align='center'
													style="color: green; margin: auto; font-size: 18px;">
													Please Wait Loading Details.....</h6>
											</div>
										</div>
									</div>
									<!-- spinner end-->
									<div class="col-md-3">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="Hireslip Wise">Hireslip
																Wise</option>
															<option value="Loader Wise">Loader Wise</option>
															<option value="Truck Wise">Truck Wise</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="searchByHireslipWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Hireslip Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control" 
														id="{{pageId}}hireslipNumber" autocomplete="off">
													</div>
												</div>
											</div>


											<div *ngIf="viewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDates" placeholder="dd-mm-yyyy" name="fromDates"
															ngbDatepicker #fromDates="ngbDatepicker"
															autocomplete="off">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="viewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
															placeholder="dd-mm-yyyy" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker"
															autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="searchByTruckWise" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Truck Number</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-truck"></i>
																</span>
															</div>
															<input id="{{pageId}}truckNumber" type="text" class="form-control"
																(selectItem)="clickListnerForTruckNumber($event)"
																[(ngModel)]="modelTruckNumber"
																[ngbTypeahead]="searchTruckNumber"
																[resultFormatter]="formatterTruckNumber"
																[inputFormatter]="formatterTruckNumber"
																(focus)="focusTruckNumber$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="searchByLoaderWise" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Loader Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}loaderStaffName" type="text" class="form-control"
																(selectItem)="clickListnerForLoaderStaffName($event)"
																[(ngModel)]="modelLoaderStaffName"
																[ngbTypeahead]="searchLoaderStaffName"
																[resultFormatter]="formatterLoaderStaffName"
																[inputFormatter]="formatterLoaderStaffName"
																(focus)="focusLoaderStaffNameTA$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr style="width: 80%; border-top: none; margin: 3px;">
										<div class="col-md-12" style="text-align: center;">
											<button class="btn btn-success m-r-10" id="{{pageId}}searchBtn" #searchBtn
												(click)="validateSearchBtn();">Search</button>
											<button (click)="clearAll()" class="btn btn-dark"
												id="{{pageId}}clearBtn">Clear</button>
										</div>

									</div>
									<div class="col-md-9 vl p-t-10">
										<button type="submit" class="dt-button" style="margin-left: 92%;"
											(click)="customPrintLoaderPerfor();" id="{{pageId}}printAllBtn">
											<span><i class="fas fa-print">Print</i></span>
										</button>
										<div class="box-body">
											<table datatable id="{{pageId}}loaderPerformanceRptTableId" #loaderPerformanceTable
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionsLoaderPerformaceReport"
												[dtTrigger]="dtTriggerLoaderPerformaceReport">

												<thead>
													<tr>
														<th>Hireslip No</th>
														<th>Loader Name</th>
														<th>Act Wt</th>
														<th>Gur Wt</th>
														<th>Kan Wt</th>
														<th>Gur Wt-Kan Wt</th>
														<th>To Pay</th>
														<th>Remarks</th>

													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="let loaderPerformaceReportData of loaderPerformaceReportDataList ">
														<td>{{ loaderPerformaceReportData.invoiceNumber }}</td>
														<td>{{ loaderPerformaceReportData.loaderName }}</td>
														<td>{{ loaderPerformaceReportData.actualWeight }}</td>
														<td>{{ loaderPerformaceReportData.guranteeWt }}</td>
														<td>{{ loaderPerformaceReportData.kantaWeight }}</td>
														<td>{{ loaderPerformaceReportData.totalAmount }}</td>
														<td>{{ loaderPerformaceReportData.toPay }}</td>
														<td>{{ loaderPerformaceReportData.remarks }}</td>
													</tr>
												</tbody>
												<tfoot>
													<tr>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>