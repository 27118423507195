import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { FilterPipe, AgentFilterPipe, UserGodownFilterPipe, SupplierNameFilterPipe, AgentSrcFilterPipe, StaffNameFilterPipe, TruckNumberFilterPipe, DestinationFrmSrcFilterPipe, SourceFilterPipe, ConsignorNameFilterPipe, DestFilterPipe, SourceSummNameFilterPipe, CollectioManFilterPipe, StateFilterPipe, LoaderHeadFilterPipe, PackNameFilterPipe, CommodityNameFilterPipe, GodownNameFilterPipe, BankNameFilterPipe, ConsigneeNameFilterPipe, DestListFilterPipe, MultipleCommodityFilterPipe, DestinationFilterPipe, SubCommodityFilterPipe, CollectionManFilterPipe } from "src/app/filter.pipe";
import { DailyBookingReportComponent } from 'src/app/report/daily-report/daily-booking-report/daily-booking-report.component';
import { OfficeReportComponent } from 'src/app/report/daily-report/office-report/office-report.component';
import { BangaloreOfficeComponent } from 'src/app/report/daily-report/bangalore-office/bangalore-office.component';
import { DoorDeliveryComponent } from 'src/app/report/daily-report/door-delivery/door-delivery.component';
import { MaterialModule } from "src/app/material.module";
import { CustomBangaloreOfcPrintComponent } from 'src/app/report/daily-report/custom-bangalore-ofc-print/custom-bangalore-ofc-print.component';
import { DailyBkgRptPrintComponent } from './daily-bkg-rpt-print/daily-bkg-rpt-print.component';
import { CftRangeReportComponent } from './cft-range-report/cft-range-report.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( DailyReportRoutes ), 
        FormsModule, NgbModule, DataTablesModule, MatAutocompleteModule,
        MatFormFieldModule, ReactiveFormsModule, MatInputModule, AutocompleteModule, MaterialModule,
    ],
    declarations: [
        DailyBookingReportComponent,
        OfficeReportComponent,
        BangaloreOfficeComponent,
        FilterPipe,
        AgentFilterPipe,
        UserGodownFilterPipe,
        SupplierNameFilterPipe,
        AgentSrcFilterPipe,
        StaffNameFilterPipe,
        TruckNumberFilterPipe,
        DoorDeliveryComponent,
        DestinationFrmSrcFilterPipe,
        SourceFilterPipe,
        ConsignorNameFilterPipe,
        DestFilterPipe,
        SourceSummNameFilterPipe,
        CollectioManFilterPipe,
        StateFilterPipe,
        LoaderHeadFilterPipe,
        PackNameFilterPipe,
        CommodityNameFilterPipe,
        GodownNameFilterPipe,
        BankNameFilterPipe,
        CommodityNameFilterPipe,
        ConsigneeNameFilterPipe,
        DestListFilterPipe,
        MultipleCommodityFilterPipe,
        DestinationFilterPipe,
        SubCommodityFilterPipe,
        CollectionManFilterPipe,
        CustomBangaloreOfcPrintComponent,
        DailyBkgRptPrintComponent,
        CftRangeReportComponent
    ],
    exports: [
        FilterPipe,
        AgentFilterPipe,
        UserGodownFilterPipe,
        SupplierNameFilterPipe,
        AgentSrcFilterPipe,
        StaffNameFilterPipe,
        TruckNumberFilterPipe,
        DestinationFrmSrcFilterPipe,
        SourceFilterPipe,
        ConsignorNameFilterPipe,
        DestFilterPipe,
        SourceSummNameFilterPipe,
        CollectioManFilterPipe,
        StateFilterPipe,
        LoaderHeadFilterPipe,
        PackNameFilterPipe,
        CommodityNameFilterPipe,
        GodownNameFilterPipe,
        BankNameFilterPipe,
        CommodityNameFilterPipe,
        ConsigneeNameFilterPipe,
        DestListFilterPipe,
        MultipleCommodityFilterPipe,
        DestinationFilterPipe,
        SubCommodityFilterPipe,
        CollectionManFilterPipe,
        CustomBangaloreOfcPrintComponent
    ],
})
export class DailyReportModule { }


