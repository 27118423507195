<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Agent Commission Setup</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-6 vl">
					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-12">
											<div class="row">
												<div class="col-sm-12 col-md-12">

													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class=" fas fa-road"></i>
																</span>
															</div>
															<input id="{{pageId}}destinationId" type="text"
																class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination.." />

														</div>
													</div>

													<app-autocomplete #autocomplete="appAutocomplete">
													<ng-template appAutocompleteContent> <ng-container
														*ngIf="(destinationOptions | filter: control.value) as resultDestination">
													<app-option *ngFor="let option of resultDestination"
														[value]="option.label"> {{ option.label }} </app-option> <app-option
														class="no-result" *ngIf="!resultDestination.length">No
													result</app-option> </ng-container> </ng-template> </app-autocomplete>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<label>Initial Commission(%)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" id="{{pageId}}initialCommission"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<label>Unit</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-weight"></i>
																</span>
															</div>
															<select class="custom-select col-12" id="{{pageId}}unit">
																<option selected value="Actual Weight">Actual
																	Weight</option>
																<option value="Charged Weight">Charged Weight</option>
																<option value="Freight Amount">Freight Amount</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<label>Service Charge (Rs)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" id="{{pageId}}serviceCharge"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 vl">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-12">
											<h6 class="card-title">Slabs (Days)</h6>
											<div class="col-md-12">
												<div class="row">
													<div class="col-md-3">
														<div class="input-group">
															<input type="number" id="{{pageId}}fromValue" placeholder="From"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
													<div class="col-md-3">
														<div class="input-group">
															<input type="number" id="{{pageId}}toValue" placeholder="To"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<div class="input-group">
																<input type="number" id="{{pageId}}commissionValue"
																	placeholder="Commission (%)" class="form-control"
																	aria-describedby="basic-addon11">
															</div>
														</div>
													</div>
													<div class="col-md-2">
														<div class="form-group">
															<div class="input-group">
																<a href="javascript:;" type='button' id="{{pageId}}addInTable"
																	#addBtn style="padding: 1px 4px;"
																	class="btn btn-icon-only yellow"
																	(click)="addInTable();"> <i class="fa fa-plus"
																	title="Add"></i>
																</a>
															</div>
														</div>
													</div>

												</div>
											</div>
											<div class="box-body p-t-10">
												<!-- 							<the second datatable starts  -->
												<div>
													<table datatable id="{{pageId}}slabTableId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionSlabDatatable"
														[dtTrigger]="dtTriggerSlabDatatable">
														<thead>
															<tr>
																<th>From</th>
																<th>To</th>
																<th>Commission (%)</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>

															<tr
																*ngFor="let slabDatatableData of slabDatatableDataList ; let i = index">
																<td>{{ slabDatatableData.fromDays }}</td>
																<td>{{ slabDatatableData.toDays }}</td>
																<td>{{ slabDatatableData.commSlaps }}</td>
																<td><button
																		style="padding: 1px 4px; background-color: #ffffff00;"
																		class="btn m-r-10" id="{{pageId}}editBtn"
																		(click)="rowSelectedEdit(slabDatatableData,i);">
																		<i title="Edit" class="fas fa-edit"></i>
																	</button></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 95%;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}applyBtn" (click)="saveCommissionDetails();">Apply</button>
							<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"
								(click)="clearFields();">Clear</button>
						</div>
					</div>
				</div>

				<div class="col-md-6 vl">
					<h6 class="card-title border_bottom">Agent Commission Details</h6>
					<div class="box-body">
						<table datatable id="{{pageId}}commissionDetailsId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsCommissionMaster"
							[dtTrigger]="dtTriggerCommissionMaster">

							<thead>
								<tr>
									<th>Agent</th>
									<th>Commission(%)</th>
									<th>Effective From</th>
									<th>Fixed By</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let commissionMasterData of commissionMasterDataList ">
									<td>{{ commissionMasterData.destination }}</td>
									<td>{{ commissionMasterData.initialCommission}}</td>
									<td>{{ commissionMasterData.effectiveFromStr }}</td>
									<td>{{ commissionMasterData.fixedBy }}</td>
									<td><button
											style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}editCommissionBtn"
											(click)="rowSelectedEditCommission(commissionMasterData,i);">
											<i title="Edit" class="fas fa-edit"></i>
										</button></td>
								</tr>
							</tbody>
						</table>


					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
