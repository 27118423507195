import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends
//for drag and drop starts
import { DragulaModule } from 'ng2-dragula';
//for drag and drop ends
import { MaterialModule } from "src/app/material.module";


import { StocksForTripSheetPageRoutes } from 'src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page.routing';

import { StocksForTripsheetComponent } from "src/app/stocks-for-tripsheet-page/stocks-for-tripsheet/stocks-for-tripsheet.component";
import { StocksForTripsheetPageLocalDetailsComponent } from "src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page-local-details/stocks-for-tripsheet-page-local-details.component";
import { StocksForTripsheetPageStockSummaryComponent } from "src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page-stock-summary/stocks-for-tripsheet-page-stock-summary.component";
import { StocksForTripsheetPageTripDetailsComponent } from "src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page-trip-details/stocks-for-tripsheet-page-trip-details.component";
import { StocksForTripsheetPageTripScheduleComponent } from "src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page-trip-schedule/stocks-for-tripsheet-page-trip-schedule.component";
import { StockForTripsheetDriverPrintComponent } from "src/app/stocks-for-tripsheet-page/stock-for-tripsheet-driver-print/stock-for-tripsheet-driver-print.component";
import { StockForTripsheetDriverPrintDestComponent } from "src/app/stocks-for-tripsheet-page/stock-for-tripsheet-driver-print-dest/stock-for-tripsheet-driver-print-dest.component";
import { StockForTripsheetDriverPrintV2Component } from './stock-for-tripsheet-driver-print-v2/stock-for-tripsheet-driver-print-v2.component';


@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(StocksForTripSheetPageRoutes),
        FormsModule,
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule,
        MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule,
        MaterialModule, DragulaModule
    ],
    declarations: [StocksForTripsheetComponent,
        StocksForTripsheetPageLocalDetailsComponent,
        StocksForTripsheetPageStockSummaryComponent,
        StocksForTripsheetPageTripDetailsComponent,
        StocksForTripsheetPageTripScheduleComponent,
        StockForTripsheetDriverPrintComponent,
        StockForTripsheetDriverPrintDestComponent,
        StockForTripsheetDriverPrintV2Component],
    exports: [StockForTripsheetDriverPrintComponent, StockForTripsheetDriverPrintDestComponent, StockForTripsheetDriverPrintV2Component]
})
export class StocksForTripSheetPageModule { }
