import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    multiEwayTableDataList: string[];
}

@Component({
    selector: 'multi-eway-details-popup',
    templateUrl: './multi-eway-details-popup.component.html',
    styleUrls: ['./multi-eway-details-popup.component.css']
})
export class MultiEwayDetailsPopupComponent implements OnInit {

    userDataDtoReturnSession: any;
    multiEwayTableDataLists: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerMultEway: Subject<any> = new Subject();
    dtOptionsMultiEway: any;
    pageId = "mulewaydtls";
    multiEwayTableDataList: any;

    constructor(public changeDetectorRef: ChangeDetectorRef, public dialogRef: MatDialogRef<MultiEwayDetailsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.multiEwayTableDataLists = this.data.multiEwayTableDataList;
    }

    ngOnInit(): void {
    }

    dataTableBayInfo() {
        this.dtOptionsMultiEway = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerMultEway.unsubscribe();
    }
    
    onCancelClick(): void {
        this.dialogRef.close();
    }
}
