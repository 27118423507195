<div class="row" *ngIf="isLoggedIn" id="{{pageId}}viewShipmentStatus">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <h6 class="card-title" style="color: green; font-size: 15px; margin-left:1%; margin-bottom:2%">
                                Each file size must be less than 1 MB</h6>
                        </div>

                        <div class="row">
                            <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Uploading Documnents....</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="file" multiple id="{{pageId}}podLrFileUpload" (change)="onFileChanged($event)" #fileInputPodLr />
                                    <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadFilesBtn()">Upload</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <img *ngFor="let url of urls" [src]="url" class="rounded mb-3" width="180">
                            <!-- <a (click)="removeImage(i)" class="btn btn-xs btn-danger">Remove</a> -->
                            <!-- <ul>
                                                <li *ngFor="let url of urls" [src]="url" class="rounded mb-3" width="180">{{fileName}} <button (click)="removeImage(i)">X</button></li>
                                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>