<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Edit Cretaria</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Edit By</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}editBy"
                                                        name="editBy" #editBy (change)="editByMethod(editBy.value)">
                                                        <option selected value="lrEdit">LR</option>
                                                        <option value="invoiceEdit">Invoice</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Invoice Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}invoiceNumber"
                                                        (keyup)="getHireSlipEnquiry($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="editView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}enterAdditionLrNo">
                                                    <label>Enter Addition LR No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="invoiceView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" readonly
                                                        aria-describedby="basic-addon11" id="{{pageId}}source">
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="invoiceView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" readonly
                                                        aria-describedby="basic-addon11" id="{{pageId}}destination">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="invoiceView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Status</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" readonly
                                                        aria-describedby="basic-addon11" id="{{pageId}}status">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                            (click)="clearAllFeilds()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl">
                    <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="editView" class="row">
                        <!-- column -->
                        <div class="col-md-12">
                            <h6 class="card-title border_bottom">Invoice LR Edit</h6>
                            <div class="box-body">
                                <table datatable class="table table-striped table-bordered row-border hover"
                                    [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                                    <thead>
                                        <tr>
                                            <th>LR Number</th>
                                            <th>Articles</th>
                                            <th>Act.Wgt</th>
                                            <th>Chg.Wgt</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>Goods Value</th>
                                            <th>Booking Date</th>
                                            <th>Consignee</th>
                                            <th>Destination</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                            <td>{{ lrDetailsData.lrNumber }}</td>
                                            <td>{{ lrDetailsData.articles}}</td>
                                            <td>{{ lrDetailsData.actualWeight }}</td>
                                            <td>{{ lrDetailsData.chargedWeight }}</td>
                                            <td>{{ lrDetailsData.toPay }}</td>
                                            <td>{{ lrDetailsData.paid }}</td>
                                            <td>{{ lrDetailsData.goodsValue }}</td>
                                            <td>{{ lrDetailsData.bookingDate }}</td>
                                            <td>{{ lrDetailsData.consignee }}</td>
                                            <td>{{ lrDetailsData.destination }}</td>

                                        </tr>
                                        <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                    </tbody>
                                </table>
                                <div class='row'>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: left;">
                                        <button type="submit" class="btn btn-success m-r-10"
                                            id="{{pageId}}updateBtn">Update</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div *ngIf="invoiceView" class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h6>Trip Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Gur Wt</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}gurWt">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Kanta Wt</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}kantaWt">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6>Vehicle Details</h6>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Loader Name
                                                        <span class="mandatoryField_Style"> *
                                                        </span>
                                                    </label>

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}loaderStaffName" type="text"
                                                        class="form-control"
                                                        (selectItem)="clickListnerForLoaderStaffName($event)"
                                                        [(ngModel)]="modelLoaderStaffName"
                                                        [ngbTypeahead]="searchLoaderStaffName"
                                                        [resultFormatter]="formatterLoaderStaffName"
                                                        [inputFormatter]="formatterLoaderStaffName"
                                                        (focus)="focusLoaderStaffNameTA$.next($any($event).target.value)"
                                                        [readonly]="isReadOnlyForLoaderName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Truck No
                                                        <span class="mandatoryField_Style"> *
                                                        </span>
                                                    </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}truckNumberSearch" type="text"
                                                        class="form-control"
                                                        (keypress)="getTruckMasterDetailsSingleEvent($event.keyCode)"
                                                        [readonly]="isReadOnlyForSearchTruckNo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Truck No <span class="mandatoryField_Style"> *
                                                        </span>
                                                    </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}truckNumber" type="text" class="form-control"
                                                        (selectItem)="clickListnerForTruckNumber($event)"
                                                        [(ngModel)]="modelTruckNumber"
                                                        [ngbTypeahead]="searchTruckNumber"
                                                        [resultFormatter]="formatterTruckNumber"
                                                        [inputFormatter]="formatterTruckNumber"
                                                        (focus)="focusTruckNumber$.next($any($event).target.value)"
                                                        [readonly]="isReadOnlyForSelectedTruckNumber" />

                                                    <span style="background-color: orange; color: #fff"
                                                        class="input-group-text"> <i class="fas fa-undo"
                                                            title="Load All Vehicle" style="cursor: pointer;"
                                                            (click)="getTruckMasterDetailsList()"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Supplier Name
                                                        <span class="mandatoryField_Style"> *
                                                        </span>
                                                    </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}supplierName" type="text" class="form-control"
                                                        [(ngModel)]="modelSupplierName"
                                                        [ngbTypeahead]="searchSupplierName"
                                                        [resultFormatter]="formatterSupplierName"
                                                        [inputFormatter]="formatterSupplierName"
                                                        (focus)="focusSupplierNameTA$.next($any($event).target.value)"
                                                        [readonly]="isReadOnlyForSupplierName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Driver Name
                                                        <span class="mandatoryField_Style"> *
                                                        </span>
                                                    </label>

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}driverName" type="text" class="form-control"
                                                        (selectItem)="clickListnerForDriverName($event)"
                                                        [(ngModel)]="modelDriverName" [ngbTypeahead]="searchDriverName"
                                                        [resultFormatter]="formatterDriverName"
                                                        [inputFormatter]="formatterDriverName" [resultTemplate]="rt"
                                                        (focus)="focusDriverNameTA$.next($any($event).target.value)"
                                                        [readonly]="isReadOnlyForDriverName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Licence Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-id-card"></i>
                                                    </span>
                                                </div>
                                                <input type="text" id="{{pageId}}licenceNumber" class="form-control"
                                                    aria-describedby="basic-addon11" autocomplete="off"
                                                    (keypress)="onKeyPressFieldForLicNo($event.keyCode)"
                                                    [readonly]="isReadOnlyForLicenceNumber">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6>Hire & Date Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Total Hire</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}totalHire"
                                                    [readonly]="isReadOnlyForTotalHire">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Advance</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}advance"
                                                    [readonly]="isReadOnlyForAdvance">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Balance</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}balance"
                                                    [readonly]="isReadOnlyForBalance">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Departure Date</label> <input class="form-control"
                                                    placeholder="yyyy-mm-dd" name="depatureDates" ngbDatepicker
                                                    #depatureDates="ngbDatepicker" id="{{pageId}}departureDate"
                                                    [readonly]="isReadOnlyForDepartureDate">
                                                <div class="input-group-append" (click)="depatureDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Departure Time<span class="mandatoryField_Style"> * </span>
                                                </label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}departureTime"
                                                    autocomplete="off" aria-describedby="basic-addon11"
                                                    (keypress)="onKeyPressFieldForDepartureTime($event.keyCode)"
                                                    [readonly]="isReadOnlyForDepartureTime">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Scheduled Date</label> <input id="{{pageId}}scheduledDate"
                                                    class="form-control" placeholder="yyyy-mm-dd" name="scheduledDates"
                                                    ngbDatepicker #scheduledDates="ngbDatepicker"
                                                    [readonly]="isReadOnlyForScheduledDate">
                                                <div class="input-group-append" (click)="scheduledDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Scheduled Time<span class="mandatoryField_Style"> * </span>
                                                </label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}scheduledTime"
                                                    autocomplete="off" aria-describedby="basic-addon11"
                                                    (keypress)="onKeyPressFieldForScheduledTime($event.keyCode)"
                                                    [readonly]="isReadOnlyForScheduledTime">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 95%;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateBtn"
                                (click)="validateUpdateInvoiceLrEditDetailsDetails()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>