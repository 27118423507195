<div class="page row system_responsive" id="{{pageId}}loadingsheetprint">
	<div class="col-md-12">
		<!--first row starts-->

		<!--first row starts-->
		<div class="row">
			<div class="col-md-4">
				<div class="row">
					<div class="col-md-12">
						<td style="padding-top: 10px;" width='100%' valign='top'>
							<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
							<br>
							{{address}}
						</td>
						<!--<div class="col-sm-12 col-md-12">
							<table width='100%' border='0' cellspacing='0' cellpadding='0'>
								<tr>
									<td style="padding-top: 10px;" width='100%' valign='top'>
										<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
										<br>
										{{address}}
									</td>
								</tr>
							</table>
						</div>-->
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Start Time : </strong>
								<!--<strong>{{todayDate}}</strong>-->
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 100px;'></u>
								</strong>
							</h6>
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Truck No : </strong>
								<strong>{{truckNumber}}</strong>
								<!--<strong>AN5633j9</strong>-->
							</h6>
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Source : </strong>
								<strong>{{source}}</strong>
								<!--<strong>Allepy</strong>-->
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Complete Time : </strong>
								<!--<strong>{{todayDate}}</strong>-->
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 100px;'></u>
								</strong>
							</h6>
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Challan No : </strong>
								<!--<strong>{{todayDate}}</strong>-->
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 100px;'></u>
								</strong>
							</h6>
							<h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
								<strong>Dated : </strong>
								<strong>{{date}}</strong>
								<!--<strong>28-07-2020</strong>-->
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center;font-size: large;'>
					<strong><u>{{toStation}} Loading Sheet</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<!--Second  row starts-->
		<div class="container border">
			<div>
				<table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
					<tr>
						<td width='12%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong> Booking Dt</strong></td>
						<td width='12%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='number'><strong> LR No</strong></td>
						<td width='5%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='number'><strong> Art.</strong></td>
						<td width='20%' align='center'
							style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong> Remarks</strong></td>
						<td width='15%' align='center'
							style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong> Booked From</strong></td>
						<td width='7%' align='center'
							style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong> A.Wgt</strong></td>
						<td width='7%' align='center'
							style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong> C.Wgt</strong></td>
						<td width='7%' align='center'
							style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='text'><strong>Pvt Mak</strong></td>
						<td width='15%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;'
							valign='top' type='number'><strong> Note**</strong></td>

					</tr>
					<tr *ngFor="let listOfLrDtoData of listOfLrDtoDataTable">
						<!--<tr>-->
						<td width='12%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoBookingDate'>
							<strong>{{listOfLrDtoData.bookingDateStr}}</strong>
						</td>
						<td width='12%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='number' id='{{pageId}}lrDtoLrNo'>
							<strong>{{listOfLrDtoData.lrNumber}} </strong>
						</td>

						<td width='5%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoTotArt'>
							<strong>{{listOfLrDtoData.totalArticles}} </strong>
						</td>
						<td width='5%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoRemarks'>
							<strong></strong>
						</td>
						<td width='15%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoAgentName'>
							<strong>{{listOfLrDtoData.agentName}} </strong>
						</td>
						<td width='7%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}leDtoActWgt'>
							<strong>{{listOfLrDtoData.actualWeight}}</strong>
						</td>
						<td width='7%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoChgWgt'>
							<strong>{{listOfLrDtoData.chargedWeight}}</strong>
						</td>
						<td width='7%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
							<strong>{{listOfLrDtoData.privateMarker}} </strong>
						</td>
						<td width='15%' height='10px' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size: 18px;padding-bottom: 15px;'
							valign='top' type='number' id='{{pageId}}appAmountPrint'>
							<strong> {{listOfLrDtoData.description}}</strong>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<br>
		<div class="container border">
			<div>
				<table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
					<tr>
						<td width='25%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text'><strong> Shift.</strong></td>
						<td width='25%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number'><strong> </strong></td>
						<td width='25%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number'><strong> Short </strong></td>
						<td width='25%' align='center'
							style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number'><strong> Extra </strong></td>
					</tr>
					<!--<tr *ngFor="let pfCollectionManDetailsData of pfCollectionManDetailsDataList">-->
					<tr>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>Total L.R</strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>{{totalCount}}</strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1 </strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1</strong>
						</td>
					</tr>
					<tr>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>Weight</strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>{{totWgt}} </strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1 </strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1</strong>
						</td>
					</tr>
					<tr>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>To Pay</strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='text' id='{{pageId}}'>
							<strong>{{totPay}} </strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1 </strong>
						</td>
						<td width='25%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number' id='{{pageId}}'>
							<strong>1</strong>
						</td>

					</tr>
				</table>
			</div>
		</div>



		<div class="row">
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-left: 20px; font-size: 18px; padding-top: 30px;'>
								<strong>HC-HANDLE WITH CARE, NW PRTY-NEW PARTY, COD </strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<br><br>
							<h6 style='margin-right: 20px; font-size: 18px; padding-top: 30px;'>
								<strong>Loading Clerk : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6><br><br>
							<h6 style='margin-right: 20px; font-size: 18px; padding-top: 30px;'>
								<strong>Incharge Sign : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>