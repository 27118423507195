import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";

@Component( {
    selector: 'app-delayed-trips',
    templateUrl: './delayed-trips.component.html',
    styleUrls: ['./delayed-trips.component.css']
} )
export class DelayedTripsComponent implements  OnInit {

    delayedTripsDataList: any;
    dtTriggerDelayedTrips: Subject<any> = new Subject();
    dtOptionsDelayedTrips: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    hireSlipDtoUserValue: HireSlipDto = new HireSlipDto();
    isSuperAdmin=false;
    pageId="dltrc";
    constructor(private router: Router,private hireslipService : HireslipService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
          	  for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "DelayTrips All" ) {
                        	this.isSuperAdmin = true;
                      	break;
                    }
                }
            }
        }
    }

    ngOnInit(): void {
        this.dtOptionsDelayedTrips = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }
    
    getUserValues() {
		this.hireSlipDtoUserValue = new HireSlipDto();
		this.hireSlipDtoUserValue.status = "Transit";
		if(this.isSuperAdmin == true){
			this.hireSlipDtoUserValue.mainStation = "All";
		}else{
			this.hireSlipDtoUserValue.mainStation = this.userDataDtoReturnSession.mainStation;
		}
	}
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.hireslipService.getDelayedTripsDashBoard(this.hireSlipDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"delayedTripsTableId").DataTable().destroy();
				this.delayedTripsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Delayed Trips",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.delayedTripsDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerDelayedTrips.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

    // ngOnDestroy(): void {
    //     this.dtTriggerDelayedTrips.unsubscribe();
    // }
    
    ngAfterViewInit(): void {
        this.dtTriggerDelayedTrips.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
