<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-10">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Fortnight Statement Summary
			</h6>
		</div>
		<div class="col-md-2">
			<i title="Reload" class="fas fa-sync-alt fa xs cli"
				style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
				(click)="getPendingStmtDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}destStmtDBTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsFortnightStatementSmry"
			[dtTrigger]="dtTriggerFortnightStatementSmry">

			<thead>
				<tr>
					<th>Stmt No</th>
					<th>Destination</th>
					<th>Stmt Dt</th>
					<th>Receivable Amt</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let fortnightStatementSmryData of fortnightStatementSmryDataList ">
					<td
						(click)="goToStatementDetailsPage(fortnightStatementSmryData)"><u
						class="hyperLink_td">{{ fortnightStatementSmryData.stmtId }}</u></td>
					<td>{{ fortnightStatementSmryData.destination }}</td>
					<td>{{ fortnightStatementSmryData.stmtDateStr }}</td>
					<td>{{ fortnightStatementSmryData.receivableAmt }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
