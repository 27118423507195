<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        
        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 34px; display: inline; margin-bottom: 29px;'>
                    <strong>Internal follow up</strong>
                </h6>
            </div>
        </div>
        <br>
        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Lr Number -</strong>  &nbsp; {{lrNumber}}
                </h6>
            </div>
        </div>
        

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Consignee Name -</strong>  &nbsp; {{consigneeName}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Consignor Name -</strong>  &nbsp; {{consignorName}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Src. -</strong>  &nbsp; {{source}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Dest. -</strong>  &nbsp; {{destination}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Booking date -</strong>  &nbsp; {{bookingDateStr}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Collection centre date -</strong>  &nbsp; {{enteredDateStr}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Departure date -</strong>  &nbsp; {{departuredateStr}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Arrival date -</strong>  &nbsp; {{arrivalDateStr}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Delivery date -</strong>  &nbsp; {{deliveryDateStr}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Current status -</strong>  &nbsp; {{status}} from 
                    {{source}} to {{destination}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Expected to arrive on Dt -</strong>  &nbsp; 
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Service status -</strong>  &nbsp; 
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Arrival in -</strong>  &nbsp; {{arrivalwithin}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; margin-bottom: 29px;'>
                    <strong>Delivery in -</strong>  &nbsp; {{deliveredwithin}}
                </h6>
            </div>
        </div>

        <div class="row" style='flex-wrap: wrap; margin: 0; padding-left: 55px;'>
            <div class="col-md-12" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline-block; '>
                    <strong>Description -</strong>  &nbsp;  {{multiDescription[0]}}
                </h6>
            </div>
            <div class="col-md-12" *ngFor="let multiDesc of multiDescription.slice(1)" style='padding-bottom: 5px;'>
                <h6 style='font-size: 25px; display: inline; margin-left: 181px;'>
                    {{multiDesc}}
                </h6>
            </div>
        </div>
    </div>
</div>