<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <!-- <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Vehicle Master</h6>
            </div> -->
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Vehicle Number*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input #vehicleNumber (keyup)="fieldFocus($event, vehicleName)" id="{{pageId}}vehicleNumber" type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.truckNumber">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Vehicle Name*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-truck"></i>
														</span>
                                                    </div>
                                                    <input #vehicleName (keyup)="fieldFocus($event, vehicleType)" id="{{pageId}}vehicleName" type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.vehicleName">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Vehicle Type*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-truck"></i>
														</span>
                                                    </div>
                                                    <input #vehicleType (keyup)="fieldFocus($event, saveBtn)" id="{{pageId}}vehicleType" type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.truckType">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button #saveBtn type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateVehicleDetails()">Save</button>
                        <!-- <button type="submit" class="btn btn-dark" id="{{pageId}}deleteBtn" (click)="validateDeleteRow()">Delete</button> -->
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                    </div>
                </div>
                <div class="col-md-6 vl ">
                    <!-- <h6 class="card-title">Vehicle Details</h6> -->
                    <div class="box-body">
                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsVehicleMaster" [dtTrigger]="dtTriggerVehicleMaster">

                            <thead>
                                <tr>
                                    <th>Vehicle Number</th>
                                    <th>Vehicle Name</th>
                                    <th>Vehicle Type</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let vehicleNumberData of vehicleMasterDataList" (click)="rowSelected(vehicleNumberData);">
                                    <td>{{ vehicleNumberData.truckNumber }}</td>
                                    <td>{{ vehicleNumberData.vehicleName }}</td>
                                    <td>{{ vehicleNumberData.truckType }}</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>