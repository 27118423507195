import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
const my = new Date();
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { Router } from '@angular/router';

@Component({
	selector: 'app-cod-lr-details',
	templateUrl: './cod-lr-details.component.html',
	styleUrls: ['./cod-lr-details.component.css']
})
export class CodLrDetailsComponent implements OnInit {
	closeResult: string;
	chequeView = true;
	neftView = false;
	model: NgbDateStruct;

	getDataCodLrDetailsDataTable: any;
	codLrDetailsDataList: any;
	onDestroyUnsubscribtionCodLrDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerCodLrDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsCodLrDetails: any;
	lrDtoForDetails: LRDto = new LRDto();
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	lrDtoForCOD: LRDto = new LRDto();
	responseForRemMos: any;
	showSpinnerForAction = false;
	userDataDtoForBankDetails: UserDataDto = new UserDataDto();
	userDataDtoForBankDetailsRet: UserDataDto = new UserDataDto();
	consigneeBankNameArrayRet: UserDataDto[];
	consigneeBankNameArray: any;
	//Consignee Bank 
	@ViewChild('instanceConsigneeBankName') instanceConsigneeBankName: NgbTypeahead;
	modelBankName: any;
	consigneeBankNameTA: Array<UserDataDto> = [];
	focusConsigneeBankNameTA$ = new Subject<string>();
	consigneeBankNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeBankNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeBankNameTA
				: this.consigneeBankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterBankName = (x: { bankName: string }) => x.bankName;
	lrDtoForUpdate: LRDto = new LRDto();
	selectedLr: any;
	selectedPaymentMode: any;
	enteredCheckNumber: any;
	selectedCheckDate: any;
	enteredRefNumber: any;
	selectedBankName: any;
	modalRefferenceConfirmPaymentPopUp: NgbModalRef;
	modalRefferenceConfirmPaymentClosePopUp: string;
	checkDateModel: any;
	@ViewChild('confirmPaymentPopUpTemplate') private confirmPaymentPopUpTemplate;
	pageId = "clrdc";
	constructor(private modalService: NgbModal, private dashboardService: DashboardService,
		private masterReadService: MasterReadService,
		private router: Router, public changeDetectorRef: ChangeDetectorRef
	) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
				this.getPartyBankDetailsService();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	//for popup modal starts 
	open2(codLrDetailsData, confirmPaymentPopUpTemplate) {
		this.selectedLr = codLrDetailsData.lrNumber;
		console.log(this.selectedLr);
		this.lrDtoForUpdate = new LRDto();
		this.selectedPaymentMode = '';
		$("#" + this.pageId + "paymentMode").val('cheque');
		this.selectedBankName = '';
		$("#" + this.pageId + "consigneeBankName").val('');
		this.modelBankName = '';
		this.enteredCheckNumber = '';
		$("#" + this.pageId + "chequeNumber").val('');
		this.selectedCheckDate = '';
		$("#" + this.pageId + "chequeDate").val('');
		this.enteredRefNumber = '';
		$("#" + this.pageId + "refNo").val('');
		this.chequeView = true;
		this.neftView = false;
		this.checkDateModel = null;
		/* this.modalService.open( content, { centered: true } ).result.then(
			 result => {
				 this.closeResult = `Closed with: ${result}`;
			 },
			 reason => {
				 this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
			 }
		 );*/
		this.modalRefferenceConfirmPaymentPopUp = this.modalService.open(confirmPaymentPopUpTemplate,
			{ centered: true });
		this.modalRefferenceConfirmPaymentPopUp.result.then((result) => {
			this.modalRefferenceConfirmPaymentClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceConfirmPaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	paymentModeType(paymentMode: string) {
		if (paymentMode === 'cheque') {
			this.chequeView = true;
			this.neftView = false;
		} else if
			(paymentMode === 'neft') {
			this.chequeView = false;
			this.neftView = true;
		} else {
			this.chequeView = false;
			this.neftView = false;
		}
	}
	ngOnInit(): void {

		this.dtOptionsCodLrDetails = {
			dom: 'Bfrtip',
			// language: {
			//     search: "_INPUT_",
			//     searchPlaceholder: "Search..."
			// },
			buttons: [
			],
			searching: false,
			pagingType: 'full_numbers',
			processing: true,
			"scrollX": true,
			"scrollY": 170,
			"scrollCollapse": true,
			"paging": false,
			"info": true
			// destroy:true
		}


	}

	// ngOnDestroy(): void {
	//     this.dtTriggerCodLrDetails.unsubscribe();

	// }
	ngAfterViewInit(): void {
		this.dtTriggerCodLrDetails.next();

	}

	//for redirect to other page starts
	goToLrEnquiryPage() {
		//  this.router.navigate( ['/lr/enquiry/lrEnquiry'] );
	}

	gridReconifgureOnReloadBtn() {
		this.getPartyBankDetailsService();
		this.lrDtoForCOD = new LRDto();
		this.lrDtoForCOD.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForCOD.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForCOD);
		this.gridReconifgureDetails();
	}
	gridReconifgureDetails = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getCODLrDetails(this.lrDtoForCOD).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				//$("#"+this.pageId+"codLrDetailsId").DataTable().destroy();
				this.codLrDetailsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.codLrDetailsDataList = response;
					console.log(this.codLrDetailsDataList);
				}
				//   this.dtTriggerCodLrDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the COD LR  Details", "info");
			}, () => console.log('done');
	};

	getMethodForgetPartyBankDetailsService() {
		this.userDataDtoForBankDetails = new UserDataDto();
		this.userDataDtoForBankDetails.office = this.userDataDtoReturnSession.mainStation;
		this.userDataDtoForBankDetails.companyId = this.userDataDtoReturnSession.companyId;
	}

	getPartyBankDetailsService() {
		this.getMethodForgetPartyBankDetailsService();
		this.masterReadService.getPartyBankDetailsService(this.userDataDtoForBankDetails).subscribe(
			(response) => {
				console.log('1');
				this.consigneeBankNameArray = [];
				this.consigneeBankNameArray = response;
				this.consigneeBankNameTA = [];
				console.log(this.consigneeBankNameArray);
				for (let i = 0; i < this.consigneeBankNameArray.length; i++) {
					this.consigneeBankNameTA.push(this.consigneeBankNameArray[i]);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Server Error",
				text: "Problem occur while getting Party Bank Details",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};
	updateBtn() {

		swal({
			title: "Confirm Update",
			text: "Sure you want to update the COD payment details",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((update) => {
			if (update) {
				this.updatePaymentDetails();
			}
		});
	}

	updatePaymentDetails() {
		this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
		this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
		this.enteredCheckNumber = $("#" + this.pageId + "chequeNumber").val();
		this.selectedCheckDate = $("#" + this.pageId + "chequeDate").val();
		this.enteredRefNumber = $("#" + this.pageId + "refNo").val();

		if (this.selectedPaymentMode == 'cheque') {
			if (this.selectedBankName == null || this.selectedBankName == '' || this.enteredCheckNumber == null || this.enteredCheckNumber == '' || this.selectedCheckDate == null || this.selectedCheckDate == '') {
				swal({
					title: "warning",
					text: "Please select the Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		} else if (this.selectedPaymentMode == 'neft') {
			if (this.selectedBankName == null || this.selectedBankName == '' || this.enteredRefNumber == null || this.enteredRefNumber == '') {
				swal({
					title: "warning",
					text: "Please select the Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}

		}



		this.lrDtoForUpdate = new LRDto();
		this.lrDtoForUpdate.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForUpdate.lrNumber = this.selectedLr;
		this.lrDtoForUpdate.paymentMode = this.selectedPaymentMode;
		this.lrDtoForUpdate.bankName = this.selectedBankName;
		this.lrDtoForUpdate.chequeNumber = this.enteredCheckNumber;
		this.lrDtoForUpdate.chequeDate = this.selectedCheckDate;
		this.lrDtoForUpdate.refNumber = this.enteredRefNumber;
		this.lrDtoForUpdate.userName = this.userDataDtoReturnSession.userId;
		this.lrDtoForUpdate.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForUpdate);
		this.updatePaymentDetailsInfo();
	}
	updatePaymentDetailsInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.updateCODPaymentDetails(this.lrDtoForUpdate).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				if (response.status == 'Success') {

					this.lrDtoForUpdate = new LRDto();
					this.selectedPaymentMode = '';
					$("#" + this.pageId + "paymentMode").val('cheque');
					this.selectedBankName = '';
					$("#" + this.pageId + "consigneeBankName").val('');
					this.enteredCheckNumber = '';
					$("#" + this.pageId + "chequeNumber").val('');
					this.selectedCheckDate = '';
					$("#" + this.pageId + "chequeDate").val('');
					this.enteredRefNumber = '';
					$("#" + this.pageId + "refNo").val('');
					this.selectedLr = '';
					this.chequeView = true;
					this.neftView = false;
					this.checkDateModel = null;
					this.modelBankName = '';
					this.modalRefferenceConfirmPaymentPopUp.close();

					swal({
						title: "Success",
						text: "COD Payment details updated successfully",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.gridReconifgureOnReloadBtn();
				} else if (response.status == 'NotExist') {
					swal({
						title: "Failed to update",
						text: "Lr Number Does Not Exist",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					swal({
						title: "Failed to update",
						text: "Problem occurred while updating the COD Payment details",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the COD Payment details", "info");
			}, () => console.log('done');
	};


	clearFields() {

		this.lrDtoForUpdate = new LRDto();
		this.selectedPaymentMode = '';
		$("#" + this.pageId + "paymentMode").val('cheque');
		this.selectedBankName = '';
		$("#" + this.pageId + "consigneeBankName").val('');
		this.enteredCheckNumber = '';
		$("#" + this.pageId + "chequeNumber").val('');
		this.selectedCheckDate = '';
		$("#" + this.pageId + "chequeDate").val('');
		this.enteredRefNumber = '';
		$("#" + this.pageId + "refNo").val('');
		this.selectedLr = '';
		this.chequeView = true;
		this.neftView = false;
		this.checkDateModel = null;
		this.modelBankName = '';
	}
	closeBtnPopup() {
		this.clearFields();
		this.modalRefferenceConfirmPaymentPopUp.close();

	}


}

