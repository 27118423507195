//Chg_V1 : Create new popup for GD update (Asrar Jr / 06-08-2024)
//Chg_V2 : Create new popup for Rate Increase (Asrar Jr / 07-08-2024)
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UpdateRateIncreaseComponent } from '../update-rate-increase/update-rate-increase.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-rate-master',
    templateUrl: './rate-master.component.html',
    styleUrls: ['./rate-master.component.css']
})
export class RateMasterComponent implements OnInit {
    unitRate = false;
    unitDiscount = false;
    model: NgbDateStruct;
    gettingDataFrmServiceFrRateMasterTable: any;
    consigneeDataListForTable: any;
    onDestroyUnsubscribtionRateMaster: Subscription;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerRateMaster: Subject<any> = new Subject();
    dtOptionsRateMaster: any;

    control = new FormControl();
    sourceOptions = [
        { id: 1, label: 'Agra' },
        { id: 2, label: 'Daman' },
        { id: 3, label: 'Delhi' },
    ];

    commodityOptions = [
        { id: 1, label: 'A C Parts' },
        { id: 2, label: 'A S Goods' },
        { id: 3, label: 'A. Footwear' },
    ];

    //for the select option with filter ends
    //for ConsigneeName
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    address: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNameOptions: any;
    //For Commodity 
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoOptionsAll: RateMasterDto = new RateMasterDto();
    public modelCommodity: any;
    modelCommodityTA: Array<RateMasterDto> = [];
    focusCommodity$ = new Subject<string>();
    searchCommodity = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodity$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelCommodityTA
                : this.modelCommodityTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodity = (x: { commodityName: string }) => x.commodityName;

    //For Fixed By 
    fixedByPassDatalrDto: LRDto = new LRDto();
    lrDtoOptions: any;
    public modelFixedBy: any;
    modelFixedByTA: Array<LRDto> = [];
    focusFixedBy$ = new Subject<string>();
    searchFixedBy = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFixedBy$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelFixedByTA
                : this.modelFixedByTA.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFixedBy = (x: { userName: string }) => x.userName;
    lrDtoCheckedList: Array<LRDto> = [];
    dtOptionsSourceList: any;
    dtTriggerSourceList: Subject<any> = new Subject();
    sourceListDataList: any;

    // for src
    agentDtoSrc: AgentDetailsDto = new AgentDetailsDto();

    //for Main Commodity

    rateMasterDtoForMainCommodity: RateMasterDto = new RateMasterDto();

    rateMasterDtoOptionsForMainCommodity: RateMasterDto[];
    rateMasterDtoCommodityUnldAddNew: RateMasterDto = new RateMasterDto();
    public modelCommodityMainUnldg: any;
    modelCommodityMainUnldgTA: Array<RateMasterDto> = [];
    focusCommodityMainUnldgTA$ = new Subject<string>();
    searchCommodityMainUnldg = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityMainUnldgTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelCommodityMainUnldgTA
                : this.modelCommodityMainUnldgTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityMainUnldg = (x: { commodityName: string }) => x.commodityName;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;

    private newAttributeSetUnldgCommoditySet: any = {};
    editUnldgCommodityTableId: any;
    commodityDataList: any;
    dtTriggerCommodity: Subject<any> = new Subject();
    dtOptionsCommodity: any;
    onLoadUnloadingChg: any;
    onLoadUnit: any;
    onLoadUnloadingChgGodownDelivery: any;
    onLoadUnitGodownDelivery: any;
    onLoadRcChg: any;
    onLoadScChg: any;

    rateMasterDtoCneeGrid: RateMasterDto = new RateMasterDto();
    btnSetRateAll = false;
    showCommodity = false;
    isRateEdit = false;
    showSaveBtn = true;
    showUpdateBtn = false;
    showNewRateBtn = false;
    viewDestination = false;
    public modelDestinationFotGet: any;
    selectedDestination: any;
    rateMasterDtoCneeRateDel: RateMasterDto = new RateMasterDto();
    selectedRowIdForEdit: any;
    consigneeNameTxt: any;
    consigneeGstTxt: any;
    consigneeAddressTxt: any;
    consigneeAreaTxt: any;
    consigneeCollectionManTxt: any;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    showCneeDtlsTxt = false;
    modelRate: any;
    modelRatePercentage: any;
    partyMasterDtoCneeSet: PartyMasterDto[];
    public modelConsigneeNameId: any;
    selectedConsigneeId: any;
    private newAttributeSetUnldgCommoditySetonEdit: any = {};
    // unldgCommoditiesList = [];
    // unldgChgList = [];
    // unldgSourceList = [];
    // unldgUnitList = [];
    rateMasterDtoCneeRateUpdate: RateMasterDto = new RateMasterDto();
    selectedConsigneName: any;
    seleectedCommodity: any;
    enteredHamaliSrc: any;
    enteredHamaliDest: any;
    enteredServiceChg: any;
    enteredGcChg: any;
    enteredReciptChg: any;
    selectedPerUnit: any;
    selectedStatus: any;
    selectedFixedBy: any;
    selectedEffectiveFromDate: any;
    selectedFixedOnDate: any;
    selectedUnitHideShow: any;
    selectedFov: any;
    selectedOn: any;
    enteredRate: any;
    enteredRatePercentage: any;
    selectedConsigneeIdForEdit: any;
    rateMasterDtoCneeRateUpdateList: Array<RateMasterDto> = [];
    lrDtoSourceListDummy: Array<LRDto> = [];
    lrDtoSouceDummy: LRDto = new LRDto();
    lrDtoSouceDummyTwo: LRDto = new LRDto();
    rateMasterDtoForSaveReturn: any;
    rateMasterDtoCneeRateSave: RateMasterDto = new RateMasterDto();
    rateMasterDtoCneeRateSaveList: Array<RateMasterDto> = [];
    tdyFixedOnDate: any;
    enteredUnldgChgDd: any;
    enteredUnitDd: any;
    enteredUnldgChgGd: any;
    enteredUnitGd: any;
    lrDtosOtherChargesReturnType: Array<LRDto> = [];

    controlOtherChargeArea = new FormControl();
    otherChargeAreaOptions: LRDto[];
    lrDto: LRDto = new LRDto();

    public modelOtherChargeArea: any;
    otherChargeAreaTA: Array<LRDto> = [];
    focusOtherChargeAreaTA$ = new Subject<string>();
    searchOtherChargeArea = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusOtherChargeAreaTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.otherChargeAreaTA
                : this.otherChargeAreaTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterOtherChargeArea = (x: { destination: string }) => x.destination;


    gridTosetOtherChargeList: Array<LRDto> = [];
    private newAttributeTosetOtherCharges: any = {};
    editOtherChargesId = null;
    lrDtosOtherCharge: LRDto = new LRDto();
    dtTriggerOtherChargeDatatable: Subject<any> = new Subject();
    dtOptionsOtherChargeDatatable: any;
    modalRefferenceDdUpdateTemplatePopUp: NgbModalRef;
    showSpinnerForHamaliDd = false;
    lrDtoAreaOptions: LRDto[];
    lrDtoArea: LRDto = new LRDto();
    public modelArea: any;
    modelAreaTA: Array<LRDto> = [];
    focusAreaTA$ = new Subject<string>();
    searchArea = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAreaTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelAreaTA
                : this.modelAreaTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterArea = (x: { destination: string }) => x.destination;
    rateMasterDtoNewHamaliDd: RateMasterDto = new RateMasterDto();
    selecrtedArea: any;
    currentHamaliDest: any;
    updateHamaliDest: any;
    pageId = "rmcpt";

    /* For Source Drop Down Start Search */
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameRptSearchModal: any;
    agentNameRptSearchTA: Array<AgentDetailsDto> = [];
    focusAgentNameRptSearchTA$ = new Subject<string>();
    agentNameSearchRptSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameRptSearchTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameRptSearchTA
                : this.agentNameRptSearchTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentNameRptSearch = (x: { source: string }) => x.source;
    /* For Source Drop Down End */

    /* Commodity Drop Down search */
    public modelCommodityListSearchRpt: any;
    commodityListSearchRptTA: Array<RateMasterDto> = [];
    focusCommodityListSearchRptTA$ = new Subject<string>();
    searchCommodityListSearchRpt = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListSearchRptTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListSearchRptTA
                : this.commodityListSearchRptTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityListSearchRpt = (x: { commodityName: string }) => x.commodityName;
    selectedSourceForSearch: any;
    selectedCommodityForSearch: any;
    ddHamaliLessCheckStatus: boolean = false;
    consigneeDataListForTableSelected: any;
    modalRefferenceAreaOtherChgUpdateTemplatePopUp: NgbModalRef;
    currentAreaOtherChg: any;
    updateAreaOtherChg: any;

    //For consignee Index
    public modelConsigneeIndex: any;
    @ViewChild('instanceConsigneeIndex') instanceConsigneeIndex: NgbTypeahead;
    consigneeIndexTA: Array<any> = [];
    focusConsigneeIndexTA$ = new Subject<string>();
    consigneeIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeIndexTA
                : this.consigneeIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    selectedConsigneeIndex: any;
    consigneeIndexOptions = [
        { id: 1, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' },
        { id: 27, label: 'ALL' }
    ];

    selectedOtherChgArea: any;
    selectedOtherChgAmt: any;
    selectedOtherChgUnit: any;

    modalRefferenceGdUpdateTemplatePopUp: NgbModalRef;
    rateMasterDtoNewHamaliGd: RateMasterDto = new RateMasterDto();
    selecetedAreaForGd: any;
    currentGdCharges: any;
    updateGdCharges: any;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private masterReadService: MasterReadService,
        private masterService: MasterService,
        private consigneeService: ConsigneeService,
        private datePipe: DatePipe,
        private modalService: NgbModal, public dialog: MatDialog) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.selectTodayFixedOn();
            this.getConsigneeDetails();
            this.getCommodityMasterDetailsList();
            this.getFixedByDetailsList();
            this.gettingSrcList();
            this.getMainCommodityMasterDetailsList();
            //this.gridReconfigureForConsignee();
            this.getBranchAreaDetailsList();
            this.setConsigneeIndex();
            // console.log(this.userDataDtoReturnSession);
            this.onLoadUnloadingChg = this.userDataDtoReturnSession.unloadingHamaliChg;
            this.onLoadUnit = this.userDataDtoReturnSession.unit;
            this.onLoadUnloadingChgGodownDelivery = this.userDataDtoReturnSession.unloadingHamaliChgGD;
            this.onLoadUnitGodownDelivery = this.userDataDtoReturnSession.unitGD;
            this.onLoadRcChg = this.userDataDtoReturnSession.rcChg == null ? 0 : this.userDataDtoReturnSession.rcChg;
            this.onLoadScChg = this.userDataDtoReturnSession.scChg == null ? 0 : this.userDataDtoReturnSession.scChg;
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
                // cbDestination.setVisible(true);
                // cbDestinationPopUp.setVisible(true);
                // setDestInCombo();
                this.getDestinationDetails();
                this.viewDestination = true;
            }




            // if (SRDWeb.sortedMapFeatures.get("Rights") != null) {
            //     for (int i = 0; i < SRDWeb.sortedMapFeatures.get("Rights").size(); i++) {
            //         if (SRDWeb.sortedMapFeatures.get("Rights").get(i) != null
            //                 && SRDWeb.sortedMapFeatures.get("Rights").get(i)
            //                         .equalsIgnoreCase("Show RateAll congRateMas")) {
            //             btnSetRateAll.setVisible(true);
            //         }
            //     }
            // }

            // if (SRDWeb.sortedMapFeatures.get("Rights") != null) {
            //     for (int i = 0; i < SRDWeb.sortedMapFeatures.get("Rights").size(); i++) {
            //         if (SRDWeb.sortedMapFeatures.get("Rights").get(i) != null
            //                 && SRDWeb.sortedMapFeatures.get("Rights").get(i)
            //                         .equalsIgnoreCase("Commodity ConsigneeRatMas")) {
            //             getMainCommodityMethod();
            //             getCommodityGridMethod();
            //             showCommodity = true;
            //         } else if (SRDWeb.sortedMapFeatures.get("Rights").get(i) != null
            //                 && SRDWeb.sortedMapFeatures.get("Rights").get(i)
            //                         .equalsIgnoreCase("CgneeRateMasterEdit")) {
            //             isRateEdit = true;
            //         }
            //     }
            // }
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Show RateAll congRateMas") {
                        //    kamil on 20-09-2021
                        // the above rights not found for any user so it depend work is postpond as per priority wise move to other work
                        // popup -modal to introduce if needed and use the below service it need to introduce in UI only
                        // updateConsigneeRateForAllRateMasterApi the service 
                        this.btnSetRateAll = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ChCommodity ConsigneeRatMas") {
                        // getMainCommodityMethod();
                        // getCommodityGridMethod();
                        this.showCommodity = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgneeRateMasterEdit") {
                        this.isRateEdit = true;
                    }
                    // console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
                }
            }

            if (!this.isRateEdit) {
                this.showNewRateBtn = false;
                this.showSaveBtn = false;
                this.showUpdateBtn = false;
            }

            $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
            for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
                this.gridTosetOtherChargeList.push(this.userDataDtoReturnSession.lrDtos[i]);
            }
            this.dtTriggerOtherChargeDatatable.next();
        }


    }

    setConsigneeIndex() {
        for (let i = 0; i < this.consigneeIndexOptions.length; i++) {
            this.consigneeIndexTA.push(this.consigneeIndexOptions[i].label);
        }
    }

    rowSelectedConsigneeIndex(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeIndex = e.item;
        this.selectedConsigneeIndex = this.modelConsigneeIndex;
        //this.gridReconfigureForConsignee('Index');
    }

    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    /* for datatable ends */


    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsSourceList = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,

        },
            this.dtOptionsCommodity = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 100,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                searching: false,
            },
            this.dtOptionsRateMaster = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    $(api.column(1).footer()).html('Total : ' + data.length);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 6,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));

                                count = 0;
                                //pendingQty = 0;
                            }
                            //the below is for column name grouping (in this case we group for destination wise)
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 8,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        if (i == (rows.length - 1)) {
                            //the below is for Sumary details grouping 
                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));

                            count = 0;
                            //pendingQty = 0;
                        }
                    });
                }
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            this.dtOptionsOtherChargeDatatable = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 200,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            }

        //the below code is for the getting data through json starts
        //        this.supplierList.getAllData().subscribe(data => {
        //            this.lrDispatchBknRptList = data['data'];
        //            this.dtTriggerSummary.next();
        //            } );
        // this.gettingDataFrmServiceFrRateMasterTable = this.rateMaster.getSummaryData()
        // this.onDestroyUnsubscribtionRateMaster = this.gettingDataFrmServiceFrRateMasterTable.subscribe( data => {
        //     this.consigneeDataListForTable = data['data'];
        //     this.dtTriggerRateMaster.next();
        // } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }

    ngOnDestroy(): void {
         //for datatable starts
         this.dtTriggerRateMaster.unsubscribe();
         this.dtTriggerSourceList.unsubscribe();
         this.dtTriggerCommodity.unsubscribe();
         this.dtTriggerOtherChargeDatatable.unsubscribe();
         //for datatable ends
    }

    ngAfterViewInit(): void {
        this.dtTriggerCommodity.next();
        this.dtTriggerSourceList.next();
        this.dtTriggerRateMaster.next();
        this.dtTriggerOtherChargeDatatable.next();
    }
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends


    unitMethod(selectUnit: string) {
        if (selectUnit === 'Rate') {
            this.unitRate = true;
            this.unitDiscount = false;
        } else if (selectUnit === 'Discount') {
            this.unitRate = false;
            this.unitDiscount = true;
        } else {
            this.unitRate = false;
            this.unitDiscount = false;
        }
    }


    //for datepicker starts
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker ends

    getConsigneeDetailsList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        if (this.viewDestination) {
            this.partyMasterDtoForconsignee.office = this.modelDestination;
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }

        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        // console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();

        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {

                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    // console.log(this.consigneeNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    //For Commodity

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.rateMasterDto.destination = null;
        // this.rateMasterDto.mode = "AllCommodity";
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.showSpinnerForAction = true;
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {

                if (response) {
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.modelCommodityTA = [];
                        this.commodityListSearchRptTA = [];
                    } else {
                        this.rateMasterDtoOptions = response;
                        this.modelCommodityTA = [];
                        this.commodityListSearchRptTA = [];
                        this.rateMasterDtoOptionsAll = new RateMasterDto();
                        this.rateMasterDtoOptionsAll.commodityName = "All";
                        this.commodityListSearchRptTA.push(this.rateMasterDtoOptionsAll);

                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.modelCommodityTA.push(this.rateMasterDtoOptions[i]);
                            this.commodityListSearchRptTA.push(this.rateMasterDtoOptions[i]);
                        }
                        $("#" + this.pageId + "commodity").val('All');
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    //For Fixed By

    getFixedByListRead() {
        this.fixedByPassDatalrDto = new LRDto();
        this.fixedByPassDatalrDto.destination = this.userDataDtoReturnSession.mainStation;
        this.fixedByPassDatalrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getFixedByDetailsList = () => {
        this.getFixedByListRead();
        this.showSpinnerForAction = true;
        this.masterService.getFixedByDetails(this.fixedByPassDatalrDto).subscribe(
            (response) => {
                if (response) {
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No Fixed By details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.lrDtoOptions = [];
                        this.modelFixedByTA = [];


                    } else {
                        this.lrDtoOptions = response;
                        this.modelFixedByTA = [];

                        for (let i = 0; i < this.lrDtoOptions.length; i++) {
                            this.modelFixedByTA.push(this.lrDtoOptions[i]);
                        }


                    }
                }

                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Fixed By Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };




    rowCheckBoxChecked(e, repeatedCommodityListData) {
        console.log("repeatedCommodityListData");
        console.log(repeatedCommodityListData);
        if (e.currentTarget.checked) {
            $("#" + this.pageId + "hamaliSrc").val(repeatedCommodityListData.srcHamali);
            $("#" + this.pageId + "gcCharge").val(repeatedCommodityListData.gcCharge);
            if (this.lrDtoCheckedList.length == 0) {
                this.lrDtoCheckedList = [];
                this.lrDtoCheckedList.push(repeatedCommodityListData);
            } else {
                this.lrDtoCheckedList.push(repeatedCommodityListData);
            }
        } else {
            $("#" + this.pageId + "hamaliSrc").val('');
            $("#" + this.pageId + "gcCharge").val('');
            let index = this.lrDtoCheckedList.indexOf(repeatedCommodityListData);
            if (index > -1) {
                this.lrDtoCheckedList.splice(index, 1);
            }
        }
        console.log(this.lrDtoCheckedList);
    }


    detailsForSrc() {
        this.agentDtoSrc = new AgentDetailsDto();
        this.agentDtoSrc.mode = "MainAndGrpRateMaster";
        this.agentDtoSrc.status = "Working";
        this.agentDtoSrc.companyId = this.userDataDtoReturnSession.companyId;
    }
    gettingSrcList = () => {
        this.detailsForSrc();
        this.showSpinnerForAction = true;
        this.masterService.getAgentNamesGrouping(this.agentDtoSrc).subscribe(
                (response) => {

                    $("#" + this.pageId + "sourceId").DataTable().destroy();
                    this.sourceListDataList = [];
                    this.agentNameRptSearchTA = [];

                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No Source records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.sourceListDataList = response;
                        // console.log("this.sourceListDataList >> Getting Response");
                        // console.log(this.sourceListDataList);
                        // this.srcPopUp

                        this.agentDtoParam = new AgentDetailsDto();
                        this.agentDtoParam.source = "ALL";
                        this.agentNameRptSearchTA.push(this.agentDtoParam);
                        for (let i = 0; i < this.sourceListDataList.length; i++) {
                            this.agentNameRptSearchTA.push(this.sourceListDataList[i]);
                        }
                    }
                    this.dtTriggerSourceList.next();
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred While getting the Source List", "info");
                }, () => console.log('done');
    };



    getMainCommodityListRead() {
        this.rateMasterDtoForMainCommodity = new RateMasterDto();
        this.rateMasterDtoForMainCommodity.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoForMainCommodity.mode = "mainConsigneeMaster";
        this.rateMasterDtoForMainCommodity.status = "Working";
        this.rateMasterDtoForMainCommodity.destination = this.userDataDtoReturnSession.mainStation;
    }

    getMainCommodityMasterDetailsList = () => {
        this.showSpinnerForAction = true;
        this.getMainCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDtoForMainCommodity).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.rateMasterDtoOptionsForMainCommodity = [];
                    this.modelCommodityMainUnldgTA = [];


                } else {
                    this.rateMasterDtoOptionsForMainCommodity = response;
                    this.modelCommodityMainUnldgTA = [];
                    this.rateMasterDtoCommodityUnldAddNew.commodityName = 'All';
                    this.modelCommodityMainUnldgTA.push(this.rateMasterDtoCommodityUnldAddNew);
                    for (let i = 0; i < this.rateMasterDtoOptionsForMainCommodity.length; i++) {
                        this.modelCommodityMainUnldgTA.push(this.rateMasterDtoOptionsForMainCommodity[i]);
                    }
                }

                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Master Details", "warning");
            },
            () => console.log('done');
    };


    clickListnerForCommodityMainUnldg(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityMainUnldg = e.item;
        $("#" + this.pageId + "CommodityMainUnldgId").val(this.modelCommodityMainUnldg.commodityName);
    }



    addCommodityDetailsTable() {
        if ($("#" + this.pageId + "CommodityMainUnldgId").val() == null || $("#" + this.pageId + "CommodityMainUnldgId").val() == undefined ||
            $("#" + this.pageId + "CommodityMainUnldgId").val() == "" ||
            $("#" + this.pageId + "unldnChg").val() == null || $("#" + this.pageId + "unldnChg").val() == undefined ||
            $("#" + this.pageId + "unldnChg").val() == "" || $("#" + this.pageId + "unitId").val() == null ||
            $("#" + this.pageId + "unitId").val() == undefined || $("#" + this.pageId + "unitId").val() == "" ||
            $("#" + this.pageId + "unitId").val() == "Unit") {
            swal("Mandatory Field", "Please select the Mandatory Field", "warning");
            return false;
        } else {
            this.reconfigureAddMultiCUnldgCommodity();
        }
    }

    reconfigureAddMultiCUnldgCommodity() {
        this.newAttributeSetUnldgCommoditySet = {};
        this.newAttributeSetUnldgCommoditySet.commodity = $("#" + this.pageId + "CommodityMainUnldgId").val();
        this.newAttributeSetUnldgCommoditySet.unldgChg = $("#" + this.pageId + "unldnChg").val();
        this.newAttributeSetUnldgCommoditySet.unit = $("#" + this.pageId + "unitId").val();

        //alert(this.editUnldgCommodityTableId);
        if (this.editUnldgCommodityTableId != null && this.editUnldgCommodityTableId != undefined) {
            this.commodityDataList.splice(this.editUnldgCommodityTableId, 1);
        }

        if (this.commodityDataList == null || this.commodityDataList == undefined ||
            this.commodityDataList.length == 0) {
            this.commodityDataList = [];
        }

        if (this.commodityDataList.length == 0) {
            $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
            this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
            this.dtTriggerCommodity.next();

            this.newAttributeSetUnldgCommoditySet = {};
            this.editUnldgCommodityTableId = null;
            $("#" + this.pageId + "CommodityMainUnldgId").val('');
            $("#" + this.pageId + "unldnChg").val('');
            $("#" + this.pageId + "unitId").val('Unit');
            this.modelCommodityMainUnldg = null;
        } else {
            const result = this.commodityDataList.filter(commodityData =>
                commodityData.commodity === this.newAttributeSetUnldgCommoditySet.commodity);
            // console.log(result);
            if (result.length > 0 && this.editUnldgCommodityTableId == null) {
                swal({
                    title: "Duplicate Entry",
                    text: "Commodity " + this.newAttributeSetUnldgCommoditySet.commodity + " is Already Exist",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
                this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
                this.dtTriggerCommodity.next();

                this.newAttributeSetUnldgCommoditySet = {};
                this.newAttributeSetUnldgCommoditySetonEdit = {};
                this.editUnldgCommodityTableId = null;
                $("#" + this.pageId + "CommodityMainUnldgId").val('');
                $("#" + this.pageId + "unldnChg").val('');
                $("#" + this.pageId + "unitId").val('Unit');
                this.modelCommodityMainUnldg = null;
            }
        }
    }

    rowSelectedEditUnldgCommodityDeatils(commodityData, index) {
        $("#" + this.pageId + "CommodityMainUnldgId").val(commodityData.commodity);
        $("#" + this.pageId + "unldnChg").val(commodityData.unldgChg);
        $("#" + this.pageId + "unitId").val(commodityData.unit);
        this.editUnldgCommodityTableId = index;
        // console.log(this.editUnldgCommodityTableId);
    }

    deleteRowSelectedUnldgCommodity(commodityData, index) {
        this.commodityDataList.splice(index, 1);
        $("#" + this.pageId + "CommodityMainUnldgId").val('');
        $("#" + this.pageId + "unldnChg").val('');
        $("#" + this.pageId + "unitId").val('Unit');
        $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
        this.dtTriggerCommodity.next();
    }

    clearCommodityDetailsTable() {
        this.newAttributeSetUnldgCommoditySet = {};
        this.newAttributeSetUnldgCommoditySetonEdit = {};
        this.editUnldgCommodityTableId = null;
        $("#" + this.pageId + "CommodityMainUnldgId").val('');
        $("#" + this.pageId + "unldnChg").val('');
        $("#" + this.pageId + "unitId").val('Unit');
        this.modelCommodityMainUnldg = null;
    }

    gridReconfigureForConsignee() {
        this.showSpinnerForAction = true;
        this.rateMasterDtoCneeGrid = new RateMasterDto();
        this.selectedSourceForSearch = $("#" + this.pageId + "agentNameRptSearch").val();
        this.selectedCommodityForSearch = $("#" + this.pageId + "commodityListIdSearchRpt").val();
        if (this.selectedConsigneeIndex != null) {
            this.rateMasterDtoCneeGrid.searchType = "Index";
            this.rateMasterDtoCneeGrid.indexValue = this.selectedConsigneeIndex;
        }
        if (this.viewDestination) {
            this.rateMasterDtoCneeGrid.city = this.modelDestination;
        } else {
            this.rateMasterDtoCneeGrid.city = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDtoCneeGrid.mode = "specific";
        this.rateMasterDtoCneeGrid.companyId = this.userDataDtoReturnSession.companyId;
        // console.log("this.rateMasterDtoCneeGrid");
        // console.log(this.rateMasterDtoCneeGrid);

        this.rateMasterDtoCneeGrid.source = this.selectedSourceForSearch;
        this.rateMasterDtoCneeGrid.commodityName = this.selectedCommodityForSearch;

        this.consigneeService.getConsigneeRateDtls(this.rateMasterDtoCneeGrid).subscribe(
            (response) => {
                $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                this.consigneeDataListForTable = [];
                if (response.length == 0) {
                    swal("Warning", "No Party Details found !", "warning");
                } else {
                    this.consigneeDataListForTable = response;
                }
                // console.log(this.consigneeDataListForTable);
                this.dtTriggerRateMaster.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Party details", "info");
            }, () => console.log('done');
    }

    getRateDetails() {
        this.gridReconfigureForConsignee();
    }


    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        // this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {

                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        // this.lrDtoDestinationAll.destination = "All";
                        // this.destinationTA.push(this.lrDtoDestinationAll);
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the Destination Details",
                    "info"
                );
            },
            () => console.log("done");
    }


    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.modelDestination = this.modelDestinationFotGet.destination;
        // console.log("this.selectedDestination");
        // console.log(this.modelDestination);
        $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
        this.consigneeDataListForTable = [];

        this.getConsigneeDetails();
        this.gridReconfigureForConsignee();
        // this.gettingSrcList();
        for (var i = 0; i < this.sourceListDataList.length; i++) {
            $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
        }
        // pending

        this.selectedConsigneeIdForEdit = null;

        this.consigneeNameTxt = "";
        this.consigneeGstTxt = "";
        this.consigneeAddressTxt = "";
        this.consigneeAreaTxt = "";
        this.consigneeCollectionManTxt = "";
        this.lrDtoCheckedList = [];
        this.clearFields();

        // console.log(this.selectedDestination)
        // if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != "All") {
        //     this.getConsigneeDetails(true);
        //     this.getCommodityListRead(true);
        // } else {
        //     this.consigneeNameTA = [];
        //     this.modelConsigneeName = '';
        // }

    }

    confirmDelete(consigneeData) {
        swal({
            title: "Confirmation",
            text: "Delete Consignee Rate Master, Are you sure want to delete the selected consignee rates details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.deleteCneeRateDtls(consigneeData);
            }
        })
    }

    deleteCneeRateDtls(consigneeData) {
        console.log("consigneeData.id");
        console.log(consigneeData.id);
        this.rateMasterDtoCneeRateDel = new RateMasterDto();
        this.rateMasterDtoCneeRateDel.id = consigneeData.id;
        this.rateMasterDtoCneeRateDel.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        console.log("this.rateMasterDtoCneeRateDel");
        console.log(this.rateMasterDtoCneeRateDel);
        this.consigneeService.deleteConsigneeRateDtls(this.rateMasterDtoCneeRateDel).subscribe(
            (response) => {
                let deleteResponse = response;
                if (deleteResponse.status == "Success") {
                    swal("Success", "Selected detail deleted successfully", "success");
                    this.clearFields();
                    // this.gettingSrcList();
                    for (var i = 0; i < this.sourceListDataList.length; i++) {
                        $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
                    }
                    $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    this.consigneeDataListForTable = [];

                    this.gridReconfigureForConsignee();

                    if (this.isRateEdit) {
                        this.showSaveBtn = true;
                        this.showUpdateBtn = false;
                    }
                    this.selectedRowIdForEdit = null;
                    this.selectedConsigneeIdForEdit = null;
                    this.showCneeDtlsTxt = false;
                    this.consigneeNameTxt = "";
                    this.consigneeGstTxt = "";
                    this.consigneeAddressTxt = "";
                    this.consigneeAreaTxt = "";
                    this.consigneeCollectionManTxt = "";
                    this.lrDtoCheckedList = [];

                    $("#" + this.pageId + "unitHideShow").val("Rate");
                    this.unitDiscount = false;
                    this.unitRate = true;

                } else if (deleteResponse.status == "Norecord") {
                    swal("Warning", "Selected detail not found", "info");
                } else if (deleteResponse.status == "Failed") {
                    swal("Error", "Faild to delete selected detail", "warning");
                }
                // console.log(this.consigneeDataListForTable);
                this.dtTriggerRateMaster.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While deleting the consignee rate details", "info");
            }, () => console.log('done');
    }
    rowSelectedEdit(consigneeData) {

        this.clearOnEdit();
        console.log("consigneeData");
        console.log(consigneeData);
        this.consigneeDataListForTableSelected = consigneeData;
        for (var i = 0; i < this.sourceListDataList.length; i++) {
            // console.log("this.sourceListDataList >> in edit 1");
            // console.log(this.sourceListDataList[i]);
            // this.sourceListDataList.checked=true;
            // console.log("inside of For if 1");
            $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
        }

        this.selectedRowIdForEdit = consigneeData.id;
        this.selectedConsigneeIdForEdit = consigneeData.consigneeId;
        $("#" + this.pageId + "consigneeName").val(consigneeData.consigneeName);
        $("#" + this.pageId + "commodity").val(consigneeData.commodityName);
        $("#" + this.pageId + "hamaliSrc").val(consigneeData.hamaliSource);
        $("#" + this.pageId + "hamaliDestination").val(consigneeData.hamaliDestination);
        $("#" + this.pageId + "serviceCharge").val(consigneeData.serviceCharges);
        $("#" + this.pageId + "gcCharge").val(consigneeData.gcCharge);
        $("#" + this.pageId + "reciptCharge").val(consigneeData.receiptCharges);
        $("#" + this.pageId + "perUnit").val(consigneeData.perUnit);
        $("#" + this.pageId + "status").val(consigneeData.status);
        $("#" + this.pageId + "fixedBy").val(consigneeData.fixedBy);
        // source pending
        // console.log("consigneeData.source");
        // console.log(consigneeData.source);
        // this.sourceListDataList.forEach(sourceList => sourceList = consigneeData.source.target.checked)
        // for (var j = 0; j < consigneeData.source.length; i++) {
        //     console.log("inside of 1st For");
        //     console.log("consigneeData.source");
        //     console.log(consigneeData.source);
        //     for (var i = 0; i < this.sourceListDataList.length; i++) {
        //         console.log("this.sourceListDataList[j].source");
        //         console.log(this.sourceListDataList[j].source);
        //         if (this.sourceListDataList[i].source == consigneeData[j].source) {
        //             // this.sourceListDataList.checked=true;
        //             console.log("inside of 2nd For");
        //             $("#rmcptselectedSource").prop("checked", true);
        //             break;
        //         }
        //     }
        // }

        console.log("consigneeData >> before For loop");
        console.log(consigneeData);

        for (var i = 0; i < this.sourceListDataList.length; i++) {
            // console.log("this.sourceListDataList >> in edit");
            // console.log(this.sourceListDataList);
            if (this.sourceListDataList[i].source.toLowerCase() == consigneeData.source.toLowerCase()) {
                // this.sourceListDataList.checked=true;
                // console.log("inside of For if");
                // console.log("this.sourceListDataList[i].agentCode");
                // console.log(this.sourceListDataList[i].agentCode);
                $("#" + this.sourceListDataList[i].agentCode).prop("checked", true);
                this.lrDtoCheckedList = [];
                this.lrDtoCheckedList.push(consigneeData);
                // console.log("this.lrDtoCheckedList inside of for on edit");
                // console.log(this.lrDtoCheckedList);
                break;
            }
        }

        $("#" + this.pageId + "effectFrom").val(consigneeData.effectiveFromDate);
        let dateConvertedFixedDate = this.datePipe.transform(consigneeData.fixedDate, "yyyy-MM-dd");
        $("#" + this.pageId + "fixedOn").val(dateConvertedFixedDate);
        if (this.isRateEdit) {
            this.showSaveBtn = false;
            this.showUpdateBtn = true;
            this.showNewRateBtn = true;
        }
        // if (showCommodity) {
        //     listMainCommodity
        //             .setItemSelected(
        //                     0, true);
        //     storeCommodity
        //             .removeAll();
        //     storeCommodity.add(TestData
        //             .getMainCommodityForReportList(maintenance
        //                     .getSelectedItem()
        //                     .getCommForRpt()));
        //     gridCommodity
        //             .reconfigure(
        //                     storeCommodity,
        //                     cmCommodity);
        // }
        $("#" + this.pageId + "unitHideShow").val(consigneeData.unit);
        if (consigneeData.unit == "Discount") {
            this.unitDiscount = true;
            $("#" + this.pageId + "ratePercentage").val(consigneeData.rate);
            this.modelRatePercentage = consigneeData.rate;
        } else {
            this.unitRate = true;
            $("#" + this.pageId + "rate").val(consigneeData.rate);
            this.modelRate = consigneeData.rate;
        }

        if (consigneeData.column2 == "onLr") {
            $("#" + this.pageId + "On").val("onLr");
        } else if (consigneeData.column2 == "onTotal") {
            $("#" + this.pageId + "On").val("onTotal");
        }

        if (consigneeData.riskCharge) {
            $("#" + this.pageId + "fov").val("yes");
        } else {
            $("#" + this.pageId + "fov").val("no");
        }

        if (consigneeData.rateMasterDtos != null &&
            consigneeData.rateMasterDtos != undefined &&
            consigneeData.rateMasterDtos.length > 0) {

            // this.gridTosetOtherChargeList = [];
            // $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
            // for (let i = 0; i < consigneeData.rateMasterDtos.length; i++) {
            //     this.newAttributeTosetOtherCharges = {};
            //     this.newAttributeTosetOtherCharges.areaOfDelivery = consigneeData.rateMasterDtos[i].area;
            //     this.newAttributeTosetOtherCharges.ratePerUnit = consigneeData.rateMasterDtos[i].ratePerUnit;
            //     this.newAttributeTosetOtherCharges.chqAmt = consigneeData.rateMasterDtos[i].otherChgAmt;
            //     this.newAttributeTosetOtherCharges.autoId = consigneeData.rateMasterDtos[i].otherChgDestMemoCneeAutoId;
            //     this.newAttributeTosetOtherCharges.cneeRateMasterAutoId = consigneeData.rateMasterDtos[i].cneeRateMasterAutoId;
            //     this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
            // }
            // this.dtTriggerOtherChargeDatatable.next();

            $("#" + this.pageId + "otherChargeArea").val(consigneeData.rateMasterDtos[0].area);
            $("#" + this.pageId + "basedOnId").val(consigneeData.rateMasterDtos[0].ratePerUnit);
            $("#" + this.pageId + "otherChargeAmountId").val(consigneeData.rateMasterDtos[0].otherChgAmt);
        }
        //  else {
        //     this.gridTosetOtherChargeList = [];
        //     $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
        //     this.dtTriggerOtherChargeDatatable.next();
        // }

        $("#" + this.pageId + "unloadingChg").val(consigneeData.unloadingChgDd);
        $("#" + this.pageId + "unit").val(consigneeData.unitDd);
        $("#" + this.pageId + "unloadingChgGodownDelivery").val(consigneeData.unloadingChgGd);
        $("#" + this.pageId + "unitGodownDelivery").val(consigneeData.unitGd);

        this.ddHamaliLessCheckStatus = consigneeData.hamaliDDRateLessStatus;
        $("#rmcptddRateHamaliLessCheckbox").prop("checked", this.ddHamaliLessCheckStatus);

        this.getUnldgReconfigure(consigneeData);
        this.setConsigneeDetailsIntoView(consigneeData.consigneeId);
        this.changeDetectorRef.detectChanges();
    }

    setConsigneeDetailsIntoView(consigneeId) {
        this.showCneeDtlsTxt = true;
        this.consigneeNameTxt = "";
        this.consigneeGstTxt = "";
        this.consigneeAddressTxt = "";
        this.consigneeAreaTxt = "";
        this.consigneeCollectionManTxt = "";
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.consigneeId = consigneeId;
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.consigneeService.getConsigneeDtlsByID(this.partyMasterDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.partyMasterDtoCneeSet = [];
                    this.partyMasterDtoCneeSet = response;
                    this.consigneeNameTxt = this.partyMasterDtoCneeSet[0].consigneeName;
                    this.consigneeAddressTxt = this.partyMasterDtoCneeSet[0].address;
                    this.consigneeGstTxt = this.partyMasterDtoCneeSet[0].gstNoConsignee;
                    this.consigneeAreaTxt = (this.partyMasterDtoCneeSet[0].area == null ? "NA" : this.partyMasterDtoCneeSet[0].area);
                    this.consigneeCollectionManTxt = (this.partyMasterDtoCneeSet[0].collectionMan == null ? "NA" : this.partyMasterDtoCneeSet[0].collectionMan);

                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee rate details by Id", "info");
            }, () => console.log('done');


    }
    clearOnEdit() {
        this.consigneeNameTxt = "";
        this.consigneeGstTxt = "";
        this.consigneeAddressTxt = "";
        this.consigneeAreaTxt = "";
        this.consigneeCollectionManTxt = "";
        this.selectedRowIdForEdit = null;
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "commodity").val('');
        $("#" + this.pageId + "rate").val('');
        $("#" + this.pageId + "hamaliSrc").val('');
        $("#" + this.pageId + "hamaliDestination").val('');
        $("#" + this.pageId + "serviceCharge").val('');
        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "reciptCharge").val('');
        $("#" + this.pageId + "perUnit").val('');
        $("#" + this.pageId + "status").val('');
        $("#" + this.pageId + "fixedBy").val('');


        $("#" + this.pageId + "effectFrom").val('');
        $("#" + this.pageId + "fixedOn").val('');
        if (this.isRateEdit) {
            this.showSaveBtn = false;
            this.showUpdateBtn = false;
            this.showNewRateBtn = false;
        }
        // if (showCommodity) {
        //     listMainCommodity
        //             .setItemSelected(
        //                     0, true);
        //     storeCommodity
        //             .removeAll();
        //     storeCommodity.add(TestData
        //             .getMainCommodityForReportList(maintenance
        //                     .getSelectedItem()
        //                     .getCommForRpt()));
        //     gridCommodity
        //             .reconfigure(
        //                     storeCommodity,
        //                     cmCommodity);
        // }
        $("#" + this.pageId + "unitHideShow").val('Select Unit');
        this.unitDiscount = false;
        $("#" + this.pageId + "ratePercentage").val('');
        this.modelRatePercentage = '';
        this.unitRate = false;
        $("#" + this.pageId + "rate").val('');
        this.modelRate = '';
        $("#" + this.pageId + "On").val("onLr");
        $("#" + this.pageId + "fov").val("yes");

        this.selectedConsigneeIdForEdit = null;
        // this.dtTriggerRateMaster.next();
        // this.commodityDataList = [];
        // $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
        // this.dtTriggerCommodity.next();
        // this.gridTosetOtherChargeList = [];
        // $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
        // this.dtTriggerOtherChargeDatatable.next();
        for (var i = 0; i < this.sourceListDataList.length; i++) {
            $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
        }

        $("#" + this.pageId + "otherChargeArea").val('');
        $("#" + this.pageId + "basedOnId").val('');
        $("#" + this.pageId + "otherChargeAmountId").val('');
        this.selectedOtherChgArea = null;
        this.selectedOtherChgAmt = null;
        this.selectedOtherChgUnit = null;
    }
    getUnldgReconfigure(consigneeData) {
        // this.reconfigureAddMultiCUnldgCommodity();

        if ((consigneeData.unldgCommodities != null) &&
            (consigneeData.unldgCommodities != undefined) &&
            (consigneeData.unldgCommodities != "")) {
            var unldgCommoditiesList = [];
            var unldgChgList = [];
            var unldgUnitList = [];
            unldgCommoditiesList = consigneeData.unldgCommodities.split("#");
            unldgChgList = consigneeData.unldgChg.split("#");
            unldgUnitList = consigneeData.unldgUnit.split("#");
            this.commodityDataList = [];
            $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
            // if (this.unldgCommoditiesList != null && this.unldgCommoditiesList != undefined &&
            //     this.unldgCommoditiesList.length > 0) {
            for (let i = 0; i < unldgCommoditiesList.length; i++) {
                this.newAttributeSetUnldgCommoditySet = {};
                this.newAttributeSetUnldgCommoditySet.commodity = unldgCommoditiesList[i];
                this.newAttributeSetUnldgCommoditySet.unldgChg = unldgChgList[i];
                this.newAttributeSetUnldgCommoditySet.unit = unldgUnitList[i];
                this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
            }
            // }
            this.dtTriggerCommodity.next();
        } else {
            // alert("am in else");
            this.commodityDataList = [];
            $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
            this.dtTriggerCommodity.next();
        }
    }

    selectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
        console.log("this.selectedConsigneeId");
        console.log(this.selectedConsigneeId);
        this.setConsigneeDetailsIntoView(this.selectedConsigneeId);
    }


    confirmUpdate() {
        swal({
            title: "Confirm Update",
            text: "Sure U want to update the changes",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.updateCneeRateDtls();
            }
        })
    }

    updateCneeRateDtlsSetValues() {
        // clearing object
        this.rateMasterDtoCneeRateUpdate = new RateMasterDto();
        this.rateMasterDtoCneeRateUpdateList = [];
        this.selectedConsigneName = null;
        this.seleectedCommodity = null;
        this.enteredHamaliSrc = null;
        this.enteredHamaliDest = null;
        this.enteredServiceChg = null;
        this.enteredGcChg = null;
        this.enteredReciptChg = null;
        this.selectedPerUnit = null;
        this.selectedStatus = null;
        this.selectedFixedBy = null;
        this.selectedEffectiveFromDate = null;
        this.selectedFixedOnDate = null;
        this.selectedUnitHideShow = null;
        this.selectedFov = null;
        this.selectedOn = null;
        this.enteredRate = null;
        this.enteredRatePercentage = null;
        this.enteredUnldgChgDd = null;
        this.enteredUnitDd = null;
        this.enteredUnldgChgGd = null;
        this.enteredUnitGd = null;
        this.selectedOtherChgArea = null;
        this.selectedOtherChgAmt = null;
        this.selectedOtherChgUnit = null;

        // assign object
        this.selectedConsigneName = $("#" + this.pageId + "consigneeName").val();
        this.seleectedCommodity = $("#" + this.pageId + "commodity").val();
        this.enteredHamaliSrc = $("#" + this.pageId + "hamaliSrc").val();
        this.enteredHamaliDest = $("#" + this.pageId + "hamaliDestination").val();
        this.enteredServiceChg = $("#" + this.pageId + "serviceCharge").val();
        this.enteredGcChg = $("#" + this.pageId + "gcCharge").val();
        this.enteredReciptChg = $("#" + this.pageId + "reciptCharge").val();
        this.selectedPerUnit = $("#" + this.pageId + "perUnit").val();
        this.selectedStatus = $("#" + this.pageId + "status").val();
        this.selectedFixedBy = $("#" + this.pageId + "fixedBy").val();
        this.selectedEffectiveFromDate = $("#" + this.pageId + "effectFrom").val();
        this.selectedFixedOnDate = $("#" + this.pageId + "fixedOn").val();
        this.selectedUnitHideShow = $("#" + this.pageId + "unitHideShow").val();
        this.selectedFov = $("#" + this.pageId + "fov").val();
        this.selectedOn = $("#" + this.pageId + "On").val();
        this.enteredRate = $("#" + this.pageId + "rate").val();
        this.enteredRatePercentage = $("#" + this.pageId + "ratePercentage").val();

        this.enteredUnldgChgDd = $("#" + this.pageId + "unloadingChg").val();
        this.enteredUnitDd = $("#" + this.pageId + "unit").val();
        this.enteredUnldgChgGd = $("#" + this.pageId + "unloadingChgGodownDelivery").val();
        this.enteredUnitGd = $("#" + this.pageId + "unitGodownDelivery").val();

        this.selectedOtherChgArea = $("#" + this.pageId + "otherChargeArea").val();
        this.selectedOtherChgAmt = $("#" + this.pageId + "otherChargeAmountId").val();
        this.selectedOtherChgUnit = $("#" + this.pageId + "basedOnId").val();
        // var data: any;
        // data.source = ["Delhi", "Delhi"];
        // console.log("data");
        // console.log(data);
        // this.lrDtoCheckedList = [];
        // this.lrDtoCheckedList.push[data];


        this.lrDtoSouceDummy.source = "Delhi";
        this.lrDtoSouceDummyTwo.source = "Mumbai";
        this.lrDtoSourceListDummy.push(this.lrDtoSouceDummy);
        this.lrDtoSourceListDummy.push(this.lrDtoSouceDummyTwo);

        var unldgCommodities = null, unldgChg = null, unldgUnit = null;
        if (this.commodityDataList != null &&
            this.commodityDataList != undefined &&
            this.commodityDataList.length > 0) {

            for (let i = 0; i < this.commodityDataList.length; i++) {

                unldgCommodities = unldgCommodities + "#" + this.commodityDataList[i].commodity;

                unldgChg = unldgChg + "#" + this.commodityDataList[i].unldgChg;

                unldgUnit = unldgUnit + "#" + this.commodityDataList[i].unit;
            }
        }

        if (unldgCommodities != null && unldgCommodities.endsWith("#")) {
            unldgCommodities = unldgCommodities.replace("# ", "");
        }

        if (unldgCommodities != null) {
            unldgCommodities = unldgCommodities.replace("null#", "");
            unldgCommodities = unldgCommodities.replace("##", "#");
        }

        if (unldgChg != null && unldgChg.endsWith("#")) {
            unldgChg = unldgChg.replace("# ", "");
        }

        if (unldgChg != null) {
            unldgChg = unldgChg.replace("null#", "");
            unldgChg = unldgChg.replace("##", "#");
        }

        if (unldgUnit != null && unldgUnit.endsWith("#")) {
            unldgUnit = unldgUnit.replace("# ", "");
        }

        if (unldgUnit != null) {
            unldgUnit = unldgUnit.replace("null#", "");
            unldgUnit = unldgUnit.replace("##", "#");
        }
        // console.log("unldgCommodities, unldgChg, unldgUnit");
        // console.log(unldgCommodities, unldgChg, unldgUnit);
        // using object
        console.log("this.lrDtoCheckedList >> Updated Btn ");
        console.log(this.lrDtoCheckedList);
        for (var j = 0; j < this.lrDtoCheckedList.length; j++) {
            console.log("this.lrDtoCheckedList[j].source");
            console.log(this.lrDtoCheckedList[j].source);
            this.rateMasterDtoCneeRateUpdate.companyId = this.userDataDtoReturnSession.companyId;
            this.rateMasterDtoCneeRateUpdate.id = this.selectedRowIdForEdit;
            this.rateMasterDtoCneeRateUpdate.consigneeId = this.selectedConsigneeIdForEdit;
            this.rateMasterDtoCneeRateUpdate.consigneeName = this.selectedConsigneName;
            this.rateMasterDtoCneeRateUpdate.commodityName = this.seleectedCommodity;
            if (this.selectedUnitHideShow == "Discount") {
                this.rateMasterDtoCneeRateUpdate.column2 = this.selectedOn;
                this.rateMasterDtoCneeRateUpdate.rate = this.enteredRatePercentage;
            } else {
                this.rateMasterDtoCneeRateUpdate.rate = this.enteredRate;
            }
            this.rateMasterDtoCneeRateUpdate.unit = this.selectedUnitHideShow;
            this.rateMasterDtoCneeRateUpdate.hamaliSource = this.enteredHamaliSrc == "" ? 0 : this.enteredHamaliSrc;
            this.rateMasterDtoCneeRateUpdate.hamaliDestination = this.enteredHamaliDest == "" ? 0 : this.enteredHamaliDest;
            this.rateMasterDtoCneeRateUpdate.serviceCharges = this.enteredServiceChg == "" ? 0 : this.enteredServiceChg;
            this.rateMasterDtoCneeRateUpdate.gcCharge = this.enteredGcChg == "" ? 0 : this.enteredGcChg;
            this.rateMasterDtoCneeRateUpdate.receiptCharges = this.enteredReciptChg == "" ? 0 : this.enteredReciptChg;
            this.rateMasterDtoCneeRateUpdate.perUnit = this.selectedPerUnit;
            this.rateMasterDtoCneeRateUpdate.status = this.selectedStatus;
            this.rateMasterDtoCneeRateUpdate.fixedBy = this.selectedFixedBy;
            this.rateMasterDtoCneeRateUpdate.effectiveFromDate = this.selectedEffectiveFromDate;
            this.rateMasterDtoCneeRateUpdate.source = this.lrDtoCheckedList[j].source;
            this.rateMasterDtoCneeRateUpdate.fixedDate = this.selectedFixedOnDate;
            if (this.selectedFov == "yes") {
                this.rateMasterDtoCneeRateUpdate.riskCharge = true;
            } else {
                this.rateMasterDtoCneeRateUpdate.riskCharge = false;
            }
            this.rateMasterDtoCneeRateUpdate.lastModifiedBy = this.userDataDtoReturnSession.userId;

            this.rateMasterDtoCneeRateUpdate.unloadingChgDd = this.enteredUnldgChgDd;
            this.rateMasterDtoCneeRateUpdate.unitDd = this.enteredUnitDd;
            this.rateMasterDtoCneeRateUpdate.unloadingChgGd = this.enteredUnldgChgGd;
            this.rateMasterDtoCneeRateUpdate.unitGd = this.enteredUnitGd;
            this.rateMasterDtoCneeRateUpdate.lrDtos = this.getOtherChgValues();
            this.rateMasterDtoCneeRateUpdate.unldgCommodities = unldgCommodities;
            this.rateMasterDtoCneeRateUpdate.unldgChg = unldgChg;
            this.rateMasterDtoCneeRateUpdate.unldgUnit = unldgUnit;
            this.rateMasterDtoCneeRateUpdate.mainStation = this.userDataDtoReturnSession.mainStation;
            this.rateMasterDtoCneeRateUpdate.userName = this.userDataDtoReturnSession.userId;
            this.rateMasterDtoCneeRateUpdate.hamaliDDRateLessStatus = this.ddHamaliLessCheckStatus;

            this.rateMasterDtoCneeRateUpdate.otherChgArea = this.selectedOtherChgArea;
            this.rateMasterDtoCneeRateUpdate.otherChgAmt = this.selectedOtherChgAmt;
            this.rateMasterDtoCneeRateUpdate.otherChgUnit = this.selectedOtherChgUnit;

            //     if (showCommodity) {
            //         String multipleCommodity = null;
            //         for (int i = 0; i < storeCommodity
            //                 .getCount(); i++) {
            //             multipleCommodity = multipleCommodity
            //                     + "#"
            //                     + storeCommodity
            //                             .getModels()
            //                             .get(i)
            //                             .getConsigneeName();
            //         }
            //         if (multipleCommodity != null
            //                 && multipleCommodity
            //                         .endsWith("#")) {
            //             multipleCommodity = multipleCommodity
            //                     .replace("# ", "");
            //         }
            //         if (multipleCommodity != null) {
            //             multipleCommodity = multipleCommodity
            //                     .replace("null#", "");
            //             multipleCommodity = multipleCommodity
            //                     .replace("##", "#");
            //         }
            //         rateMasterDto
            //                 .setCommidtyForRpt(multipleCommodity);
            //     } else {
            //         rateMasterDto.setCommidtyForRpt("");
            //     }
            // }
            console.log("this.rateMasterDtoCneeRateUpdate");
            console.log(this.rateMasterDtoCneeRateUpdate);
            this.rateMasterDtoCneeRateUpdateList.push(this.rateMasterDtoCneeRateUpdate);
        }
    }
    updateCneeRateDtls() {
        this.updateCneeRateDtlsSetValues();

        this.showSpinnerForAction = true;
        console.log("this.rateMasterDtoCneeRateUpdateList");
        console.log(this.rateMasterDtoCneeRateUpdateList);
        this.consigneeService.updateConsigneeRate(this.rateMasterDtoCneeRateUpdateList).subscribe(
            (response) => {
                let updateResponse = response;
                if (updateResponse.status == "Success") {
                    swal("Success", "Selected detail Updated successfully", "success");
                    this.clearFields();
                    $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    this.consigneeDataListForTable = [];
                    // this.gettingSrcList();
                    for (var i = 0; i < this.sourceListDataList.length; i++) {
                        $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
                    }
                    this.gridReconfigureForConsignee();
                    this.getFixedByDetailsList();
                    if (this.isRateEdit) {
                        this.showSaveBtn = true;
                        this.showUpdateBtn = false;
                    }



                    // if (showCommodity) {
                    //     btnRemove
                    //         .setEnabled(false);
                    //     storeCommodity
                    //         .removeAll();
                    //     listMainCommodity
                    //         .setItemSelected(
                    //             0, true);
                    // }
                    this.selectedRowIdForEdit = null;
                    this.selectedConsigneeIdForEdit = null;
                    this.showCneeDtlsTxt = false;
                    this.consigneeNameTxt = "";
                    this.consigneeGstTxt = "";
                    this.consigneeAddressTxt = "";
                    this.consigneeAreaTxt = "";
                    this.consigneeCollectionManTxt = "";
                    this.lrDtoCheckedList = [];
                    $("#" + this.pageId + "consigneeName").val('');
                } else if (updateResponse.status == "Failed") {
                    swal("Error", "Faild to Update selected detail", "warning");
                }
                // console.log(this.consigneeDataListForTable);
                this.dtTriggerRateMaster.next();
                this.commodityDataList = [];
                $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
                this.dtTriggerCommodity.next();
                this.gridTosetOtherChargeList = [];
                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                this.dtTriggerOtherChargeDatatable.next();
                for (var i = 0; i < this.sourceListDataList.length; i++) {
                    $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
                }

                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
                    this.gridTosetOtherChargeList.push(this.userDataDtoReturnSession.lrDtos[i]);
                }
                this.dtTriggerOtherChargeDatatable.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Updating the consignee rate details", "info");
            }, () => console.log('done');
    }

    clearFields() {
        // this.consigneeNameTxt = "";
        // this.consigneeGstTxt = "";
        // this.consigneeAddressTxt = "";
        // this.selectedRowIdForEdit = null;

        $("#" + this.pageId + "commodity").val('');
        $("#" + this.pageId + "rate").val('');
        $("#" + this.pageId + "unitHideShow").val('Select Unit');
        this.unitDiscount = false;
        $("#" + this.pageId + "ratePercentage").val('');
        this.modelRatePercentage = '';
        this.unitRate = false;
        this.modelRate = '';
        $("#" + this.pageId + "hamaliSrc").val('');
        $("#" + this.pageId + "hamaliDestination").val('');
        $("#" + this.pageId + "serviceCharge").val('');
        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "reciptCharge").val('');
        $("#" + this.pageId + "perUnit").val('');
        $("#" + this.pageId + "status").val('');
        $("#" + this.pageId + "fixedBy").val('');
        $("#" + this.pageId + "effectFrom").val('');
        $("#" + this.pageId + "fixedOn").val('');
        for (var i = 0; i < this.sourceListDataList.length; i++) {
            $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
        }
        $("#" + this.pageId + "fov").val("yes");
        $("#" + this.pageId + "perUnit").val('Chargable Weight');
        $("#" + this.pageId + "status").val('Delivery');
        $("#" + this.pageId + "commodity").val('ALL');
        // cbCommodityNamePopUp.setRawValue("All");
        $("#" + this.pageId + "reciptCharge").val(this.userDataDtoReturnSession.rcChg == null ? 0 : this.userDataDtoReturnSession.rcChg);
        $("#" + this.pageId + "serviceCharge").val(this.userDataDtoReturnSession.scChg == null ? 0 : this.userDataDtoReturnSession.scChg);

        this.selectTodayFixedOn();
        $("#" + this.pageId + "unloadingChg").val(this.userDataDtoReturnSession.unloadingHamaliChg);
        $("#" + this.pageId + "unit").val(this.userDataDtoReturnSession.unit);
        $("#" + this.pageId + "unloadingChgGodownDelivery").val(this.userDataDtoReturnSession.unloadingHamaliChgGD);
        //this.onLoadUnloadingChgGodownDelivery = this.userDataDtoReturnSession.unloadingHamaliChgGD;
        $("#" + this.pageId + "unitGodownDelivery").val(this.userDataDtoReturnSession.unitGD);
        this.ddHamaliLessCheckStatus = false;
        $("#rmcptddRateHamaliLessCheckbox").prop("checked", false);

        $("#" + this.pageId + "otherChargeArea").val('');
        $("#" + this.pageId + "basedOnId").val('');
        $("#" + this.pageId + "otherChargeAmountId").val('');
        this.selectedOtherChgArea = null;
        this.selectedOtherChgAmt = null;
        this.selectedOtherChgUnit = null;
    }

    confirmReset() {
        swal({
            title: "Clear All",
            text: "Sure U Want to clear all the fields",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                $("#" + this.pageId + "consigneeName").val('');
                this.selectedRowIdForEdit = null;
                this.selectedConsigneeIdForEdit = null;
                this.showCneeDtlsTxt = false;
                this.consigneeNameTxt = "";
                this.consigneeGstTxt = "";
                this.consigneeAddressTxt = "";
                this.consigneeAreaTxt = "";
                this.consigneeCollectionManTxt = "";
                this.lrDtoCheckedList = [];
                this.clearFields();
                // this.gettingSrcList();
                // stationsGridSetup(null);
                // cbDestination.reset();
                // grid.getSelectionModel().deselectAll();
                $("#" + this.pageId + "consigneeName").focus();
                if (this.isRateEdit) {
                    this.showSaveBtn = true;
                    this.showUpdateBtn = false;
                    this.showNewRateBtn = false;
                }
                $("#" + this.pageId + "unitHideShow").val("Rate");
                this.unitDiscount = false;
                this.unitRate = true;
                // if (showCommodity) {
                //     btnRemove.setEnabled(false);
                //     storeCommodity.removeAll();
                //     listMainCommodity.setItemSelected(0,
                //             true);
                // }      
                this.dtTriggerRateMaster.next();
                this.commodityDataList = [];
                $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
                this.dtTriggerCommodity.next();
                this.gridTosetOtherChargeList = [];
                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                this.dtTriggerOtherChargeDatatable.next();
                for (var i = 0; i < this.sourceListDataList.length; i++) {
                    $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
                }

                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
                    this.gridTosetOtherChargeList.push(this.userDataDtoReturnSession.lrDtos[i]);
                }
                this.dtTriggerOtherChargeDatatable.next();
                this.selectTodayFixedOn();
                this.changeDetectorRef.detectChanges();
            }
        })
    }

    confirmSave() {
        swal({
            title: "Confirm Save",
            text: "Sure U Want to add the rate details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.addCneeRateDtls();
                // this.validationOnSave();
            }
        })
    }

    validationOnSave() {
        // clearing object
        this.selectedConsigneName = null;
        this.seleectedCommodity = null;
        this.selectedPerUnit = null;
        this.selectedFixedBy = null;
        this.selectedEffectiveFromDate = null;
        this.selectedFixedOnDate = null;
        this.selectedUnitHideShow = null;
        this.selectedFov = null;
        this.selectedOn = null;
        this.enteredRate = null;
        this.enteredRatePercentage = null;
        this.selectedOtherChgArea = null;
        this.selectedOtherChgAmt = null;
        this.selectedOtherChgUnit = null;

        // assign object
        this.selectedConsigneName = $("#" + this.pageId + "consigneeName").val();
        this.seleectedCommodity = $("#" + this.pageId + "commodity").val();
        this.selectedPerUnit = $("#" + this.pageId + "perUnit").val();
        this.selectedFixedBy = $("#" + this.pageId + "fixedBy").val();
        this.selectedEffectiveFromDate = $("#" + this.pageId + "effectFrom").val();
        this.selectedFixedOnDate = $("#" + this.pageId + "fixedOn").val();
        this.selectedUnitHideShow = $("#" + this.pageId + "unitHideShow").val();
        this.selectedFov = $("#" + this.pageId + "fov").val();
        this.selectedOn = $("#" + this.pageId + "On").val();
        this.enteredRate = $("#" + this.pageId + "rate").val();
        this.enteredRatePercentage = $("#" + this.pageId + "ratePercentage").val();
        //pending need to start
        console.log("this.selectedConsigneName, this.seleectedCommodity, this.selectedUnitHideShow,  this.selectedPerUnit, this.selectedFixedBy, this.selectedEffectiveFromDate, this.selectedFixedOnDate, this.selectedFov,  this.lrDtoCheckedList, this.selectedOn, this.enteredRatePercentage, this.enteredRate");
        console.log(this.selectedConsigneName, this.seleectedCommodity, this.selectedUnitHideShow, this.selectedPerUnit,
            this.selectedFixedBy, this.selectedEffectiveFromDate, this.selectedFixedOnDate, this.selectedFov,
            this.lrDtoCheckedList, this.selectedOn, this.enteredRatePercentage, this.enteredRate);

        if (this.selectedConsigneName == null || this.selectedConsigneName == undefined || this.selectedConsigneName == ""
            || this.seleectedCommodity == null || this.seleectedCommodity == undefined || this.seleectedCommodity == ""
            || this.selectedUnitHideShow == null || this.selectedUnitHideShow == undefined || this.selectedUnitHideShow == "Select Unit"
            || this.selectedPerUnit == null || this.selectedPerUnit == undefined || this.selectedPerUnit == ""
            || this.selectedFixedBy == null || this.selectedFixedBy == undefined || this.selectedFixedBy == ""
            || this.selectedEffectiveFromDate == null || this.selectedEffectiveFromDate == undefined || this.selectedEffectiveFromDate == ""
            || this.selectedFixedOnDate == null || this.selectedFixedOnDate == undefined || this.selectedFixedOnDate == ""
            || this.selectedFov == null || this.selectedFov == undefined || this.selectedFov == ""
            || this.lrDtoCheckedList == null || this.lrDtoCheckedList == undefined || this.lrDtoCheckedList.length == 0) {
            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory Fields! ( * )",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedUnitHideShow != "Select Unit" && this.selectedUnitHideShow == "Discount" &&
            (this.selectedOn == null || this.selectedOn == undefined || this.selectedOn == ""
                || this.enteredRatePercentage == null || this.enteredRatePercentage == undefined || this.enteredRatePercentage == "")) {
            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory Fields! ( * )",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedUnitHideShow != "Select Unit" && this.selectedUnitHideShow != "Discount" &&
            (this.enteredRate == null || this.enteredRate == undefined || this.enteredRate == "")) {
            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory Fields! ( * )",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            // this.gridTosetOtherChargeList 
        } else {
            this.confirmSave();
        }


    }
    addCneeRateDtlsSetValues() {
        // clearingo bject
        this.rateMasterDtoCneeRateSave = new RateMasterDto();
        this.rateMasterDtoCneeRateSaveList = [];
        this.selectedConsigneName = null;
        this.seleectedCommodity = null;
        this.enteredHamaliSrc = null;
        this.enteredHamaliDest = null;
        this.enteredServiceChg = null;
        this.enteredGcChg = null;
        this.enteredReciptChg = null;
        this.selectedPerUnit = null;
        this.selectedStatus = null;
        this.selectedFixedBy = null;
        this.selectedEffectiveFromDate = null;
        this.selectedFixedOnDate = null;
        this.selectedUnitHideShow = null;
        this.selectedFov = null;
        this.selectedOn = null;
        this.enteredRate = null;
        this.enteredRatePercentage = null;
        this.enteredUnldgChgDd = null;
        this.enteredUnitDd = null;
        this.enteredUnldgChgGd = null;
        this.enteredUnitGd = null;
        this.selectedOtherChgArea = null;
        this.selectedOtherChgAmt = null;
        this.selectedOtherChgUnit = null;
        // assign object
        this.selectedConsigneName = $("#" + this.pageId + "consigneeName").val();
        this.seleectedCommodity = $("#" + this.pageId + "commodity").val();
        this.enteredHamaliSrc = $("#" + this.pageId + "hamaliSrc").val();
        this.enteredHamaliDest = $("#" + this.pageId + "hamaliDestination").val();
        this.enteredServiceChg = $("#" + this.pageId + "serviceCharge").val();
        this.enteredGcChg = $("#" + this.pageId + "gcCharge").val();
        this.enteredReciptChg = $("#" + this.pageId + "reciptCharge").val();
        this.selectedPerUnit = $("#" + this.pageId + "perUnit").val();
        this.selectedStatus = $("#" + this.pageId + "status").val();
        this.selectedFixedBy = $("#" + this.pageId + "fixedBy").val();
        this.selectedEffectiveFromDate = $("#" + this.pageId + "effectFrom").val();
        this.selectedFixedOnDate = $("#" + this.pageId + "fixedOn").val();
        this.selectedUnitHideShow = $("#" + this.pageId + "unitHideShow").val();
        this.selectedFov = $("#" + this.pageId + "fov").val();
        this.selectedOn = $("#" + this.pageId + "On").val();
        this.enteredRate = $("#" + this.pageId + "rate").val();
        this.enteredRatePercentage = $("#" + this.pageId + "ratePercentage").val();

        this.enteredUnldgChgDd = $("#" + this.pageId + "unloadingChg").val();
        this.enteredUnitDd = $("#" + this.pageId + "unit").val();
        this.enteredUnldgChgGd = $("#" + this.pageId + "unloadingChgGodownDelivery").val();
        this.enteredUnitGd = $("#" + this.pageId + "unitGodownDelivery").val();

        this.selectedOtherChgArea = $("#" + this.pageId + "otherChargeArea").val();
        this.selectedOtherChgAmt = $("#" + this.pageId + "otherChargeAmountId").val();
        this.selectedOtherChgUnit = $("#" + this.pageId + "basedOnId").val();
        // var data: any;
        // data.source = ["Delhi", "Delhi"];
        // console.log("data");
        // console.log(data);
        // this.lrDtoCheckedList = [];
        // this.lrDtoCheckedList.push[data];


        this.lrDtoSouceDummy.source = "Delhi";
        this.lrDtoSouceDummyTwo.source = "Mumbai";
        this.lrDtoSourceListDummy.push(this.lrDtoSouceDummy);
        this.lrDtoSourceListDummy.push(this.lrDtoSouceDummyTwo);
        var unldgCommodities = null, unldgChg = null, unldgUnit = null;
        if (this.commodityDataList != null &&
            this.commodityDataList != undefined &&
            this.commodityDataList.length > 0) {

            for (let i = 0; i < this.commodityDataList.length; i++) {

                unldgCommodities = unldgCommodities + "#" + this.commodityDataList[i].commodity;

                unldgChg = unldgChg + "#" + this.commodityDataList[i].unldgChg;

                unldgUnit = unldgUnit + "#" + this.commodityDataList[i].unit;
            }
        }

        if (unldgCommodities != null && unldgCommodities.endsWith("#")) {
            unldgCommodities = unldgCommodities.replace("# ", "");
        }

        if (unldgCommodities != null) {
            unldgCommodities = unldgCommodities.replace("null#", "");
            unldgCommodities = unldgCommodities.replace("##", "#");
        }

        if (unldgChg != null && unldgChg.endsWith("#")) {
            unldgChg = unldgChg.replace("# ", "");
        }

        if (unldgChg != null) {
            unldgChg = unldgChg.replace("null#", "");
            unldgChg = unldgChg.replace("##", "#");
        }

        if (unldgUnit != null && unldgUnit.endsWith("#")) {
            unldgUnit = unldgUnit.replace("# ", "");
        }

        if (unldgUnit != null) {
            unldgUnit = unldgUnit.replace("null#", "");
            unldgUnit = unldgUnit.replace("##", "#");
        }
        // console.log("unldgCommodities,unldgChg,unldgUnit");
        // console.log(unldgCommodities, unldgChg, unldgUnit);
        // using object
        console.log("this.lrDtoCheckedList");
        console.log(this.lrDtoCheckedList);
        for (var j = 0; j < this.lrDtoCheckedList.length; j++) {
            console.log("this.lrDtoCheckedList[j].source");
            console.log(this.lrDtoCheckedList[j].source);
            this.rateMasterDtoCneeRateSave = new RateMasterDto();
            this.rateMasterDtoCneeRateSave.companyId = this.userDataDtoReturnSession.companyId;
            this.rateMasterDtoCneeRateSave.consigneeName = this.selectedConsigneName;
            this.rateMasterDtoCneeRateSave.consigneeId = this.selectedConsigneeId;
            this.rateMasterDtoCneeRateSave.commodityName = this.seleectedCommodity;
            if (this.selectedUnitHideShow == "Discount") {
                this.rateMasterDtoCneeRateSave.column2 = this.selectedOn;
                this.rateMasterDtoCneeRateSave.rate = this.enteredRatePercentage;
            } else {
                this.rateMasterDtoCneeRateSave.rate = this.enteredRate;
            }
            this.rateMasterDtoCneeRateSave.unit = this.selectedUnitHideShow;
            this.rateMasterDtoCneeRateSave.hamaliSource = this.enteredHamaliSrc == "" ? 0 : this.enteredHamaliSrc;
            this.rateMasterDtoCneeRateSave.hamaliDestination = this.enteredHamaliDest == "" ? 0 : this.enteredHamaliDest;
            this.rateMasterDtoCneeRateSave.serviceCharges = this.enteredServiceChg == "" ? 0 : this.enteredServiceChg;
            this.rateMasterDtoCneeRateSave.gcCharge = this.enteredGcChg == "" ? 0 : this.enteredGcChg;
            this.rateMasterDtoCneeRateSave.receiptCharges = this.enteredReciptChg == "" ? 0 : this.enteredReciptChg;
            this.rateMasterDtoCneeRateSave.perUnit = this.selectedPerUnit;
            this.rateMasterDtoCneeRateSave.status = this.selectedStatus;
            this.rateMasterDtoCneeRateSave.fixedBy = this.selectedFixedBy;
            this.rateMasterDtoCneeRateSave.effectiveFromDate = this.selectedEffectiveFromDate;
            this.rateMasterDtoCneeRateSave.source = this.lrDtoCheckedList[j].source;
            this.rateMasterDtoCneeRateSave.fixedDate = this.selectedFixedOnDate;
            if (this.selectedFov == "yes") {
                this.rateMasterDtoCneeRateSave.riskCharge = true;
            } else {
                this.rateMasterDtoCneeRateSave.riskCharge = false;
            }
            this.rateMasterDtoCneeRateSave.lastModifiedBy = this.userDataDtoReturnSession.userId;
            this.rateMasterDtoCneeRateSave.unloadingChgDd = this.enteredUnldgChgDd;
            this.rateMasterDtoCneeRateSave.unitDd = this.enteredUnitDd;
            this.rateMasterDtoCneeRateSave.unloadingChgGd = this.enteredUnldgChgGd;
            this.rateMasterDtoCneeRateSave.unitGd = this.enteredUnitGd;
            this.rateMasterDtoCneeRateSave.lrDtos = this.getOtherChgValues();
            this.rateMasterDtoCneeRateSave.unldgCommodities = unldgCommodities;
            this.rateMasterDtoCneeRateSave.unldgChg = unldgChg;
            this.rateMasterDtoCneeRateSave.unldgUnit = unldgUnit;
            this.rateMasterDtoCneeRateSave.mainStation = this.userDataDtoReturnSession.mainStation;
            this.rateMasterDtoCneeRateSave.userName = this.userDataDtoReturnSession.userId;
            this.rateMasterDtoCneeRateSave.enteredBy = this.userDataDtoReturnSession.userId;
            this.rateMasterDtoCneeRateSave.hamaliDDRateLessStatus = this.ddHamaliLessCheckStatus;

            this.rateMasterDtoCneeRateSave.otherChgArea = this.selectedOtherChgArea;
            this.rateMasterDtoCneeRateSave.otherChgAmt = this.selectedOtherChgAmt;
            this.rateMasterDtoCneeRateSave.otherChgUnit = this.selectedOtherChgUnit;
            // if (showCommodity) {
            //     String multipleCommodity = null;
            //     for (int i = 0; i < storeCommodity
            //             .getCount(); i++) {
            //         multipleCommodity = multipleCommodity
            //                 + "#"
            //                 + storeCommodity
            //                         .getModels()
            //                         .get(i)
            //                         .getConsigneeName();
            //     }
            //     if (multipleCommodity != null
            //             && multipleCommodity
            //                     .endsWith("#")) {
            //         multipleCommodity = multipleCommodity
            //                 .replace("# ", "");
            //     }

            //     if (multipleCommodity != null) {
            //         multipleCommodity = multipleCommodity
            //                 .replace("null#", "");
            //         multipleCommodity = multipleCommodity
            //                 .replace("##", "#");
            //     }
            //     rateMasterDto
            //             .setCommidtyForRpt(multipleCommodity);
            // } else {
            //     rateMasterDto.setCommidtyForRpt("");
            // }

            console.log("this.rateMasterDtoCneeRateSave");
            console.log(this.rateMasterDtoCneeRateSave);
            this.rateMasterDtoCneeRateSaveList.push(this.rateMasterDtoCneeRateSave);



        }


    }

    addCneeRateDtls() {
        this.addCneeRateDtlsSetValues();
        this.showSpinnerForAction = true;
        console.log("this.rateMasterDtoCneeRateSaveList");
        console.log(this.rateMasterDtoCneeRateSaveList);
        this.consigneeService.addConsigneeRateDtls(this.rateMasterDtoCneeRateSaveList).subscribe(
            (response) => {
                this.rateMasterDtoForSaveReturn = [];
                this.rateMasterDtoForSaveReturn = response;

                console.log(this.rateMasterDtoForSaveReturn);
                console.log(this.rateMasterDtoForSaveReturn.status);
                if (this.rateMasterDtoForSaveReturn.status == "Success") {
                    swal("Success", "Consignee Details Saved Successfully", "success");
                    this.clearFields();
                    $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    this.consigneeDataListForTable = [];
                    // this.gettingSrcList();

                    this.gridReconfigureForConsignee();
                    this.getFixedByDetailsList();
                    $("#" + this.pageId + "consigneeName").val('');
                    this.selectedRowIdForEdit = null;
                    this.selectedConsigneeIdForEdit = null;
                    this.showCneeDtlsTxt = false;
                    this.consigneeNameTxt = "";
                    this.consigneeGstTxt = "";
                    this.consigneeAddressTxt = "";
                    this.consigneeAreaTxt = "";
                    this.consigneeCollectionManTxt = "";
                    this.lrDtoCheckedList = [];
                    // stationsGridSetup(null);
                    // if (showCommodity) {
                    //     btnRemove
                    //         .setEnabled(false);
                    //     storeCommodity
                    //         .removeAll();
                    //     listMainCommodity
                    //         .setItemSelected(
                    //             0,
                    //             true);
                    // }
                    this.commodityDataList = [];
                    $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
                    this.dtTriggerCommodity.next();
                    this.gridTosetOtherChargeList = [];
                    $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                    this.dtTriggerOtherChargeDatatable.next();
                    for (var i = 0; i < this.sourceListDataList.length; i++) {
                        $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
                    }

                    $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                    for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
                        this.gridTosetOtherChargeList.push(this.userDataDtoReturnSession.lrDtos[i]);
                    }
                    this.dtTriggerOtherChargeDatatable.next();
                } else if (this.rateMasterDtoForSaveReturn.status == "Duplicate") {
                    swal("Duplicate Entry", "This details are Already Exists", "info");
                } else if (this.rateMasterDtoForSaveReturn.status == "Failed") {
                    swal("Not Allowed", "Rate is already set for All commodity.", "warning");
                } else if (this.rateMasterDtoForSaveReturn.status == "Failed") {
                    swal("Error", "Faild to Update selected detail", "warning");
                }
                // console.log(this.consigneeDataListForTable);
                this.dtTriggerRateMaster.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While adding the consignee rate details", "info");
            }, () => console.log('done');
    }

    openRateAllPopup(setRateAllPopup) {
        this.modalService.open(setRateAllPopup, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }


    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    selectTodayFixedOn() {
        this.tdyFixedOnDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

    }

    clickListnerForOtherChargeArea(e) {
        this.modelOtherChargeArea = e.item;
        $("#" + this.pageId + "otherChargeArea").val(this.modelOtherChargeArea.destination);
        this.findOtherChgFromEditCompanySetup();
    }

    getBranchAreaDetails() {
        this.lrDto = new LRDto();
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        // this.lrDto.mode = this.userDataDtoReturnSession.mainStation;
        this.lrDto.mode = "ALL";
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getBranchAreaDetailsList = () => {
        this.getBranchAreaDetails();
        this.masterReadService.getBranchAreas(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Areas records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = [];
                    this.otherChargeAreaTA = [];
                    this.modelAreaTA = [];
                } else {
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = response;
                    console.log("this.otherChargeAreaOptions >> see me");
                    console.log(this.otherChargeAreaOptions);
                    this.otherChargeAreaTA = [];
                    this.modelAreaTA = [];
                    for (let i = 0; i < this.otherChargeAreaOptions.length; i++) {
                        this.otherChargeAreaTA.push(this.otherChargeAreaOptions[i]);
                        this.modelAreaTA.push(this.otherChargeAreaOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    addInTableOtherCharge() {
        if (($("#" + this.pageId + "otherChargeArea").val() == null) || ($("#" + this.pageId + "otherChargeArea").val() == undefined) ||
            ($("#" + this.pageId + "otherChargeArea").val() == "") || ($("#" + this.pageId + "basedOnId").val() == null) ||
            ($("#" + this.pageId + "basedOnId").val() == undefined) || ($("#" + this.pageId + "basedOnId").val() == "") ||
            ($("#" + this.pageId + "otherChargeAmountId").val() == null) || ($("#" + this.pageId + "otherChargeAmountId").val() == undefined) ||
            ($("#" + this.pageId + "otherChargeAmountId").val() == "")) {
            swal("Not Allowed", "Please enter all the details to proceed", "warning");
            return false;
        } else {
            this.addRowOtherChg();
        }
    }
    addRowOtherChg() {
        this.newAttributeTosetOtherCharges.areaOfDelivery = $("#" + this.pageId + "otherChargeArea").val();
        this.newAttributeTosetOtherCharges.ratePerUnit = $("#" + this.pageId + "basedOnId").val();
        this.newAttributeTosetOtherCharges.chqAmt = $("#" + this.pageId + "otherChargeAmountId").val();
        if (this.editOtherChargesId != null) {
            this.gridTosetOtherChargeList.splice(this.editOtherChargesId, 1);
        }
        if (this.gridTosetOtherChargeList == null) {
            this.gridTosetOtherChargeList = [];
        }
        if (this.gridTosetOtherChargeList.length == 0) {
            $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
            this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
            this.dtTriggerOtherChargeDatatable.next();
        } else {
            //console.log(this.newAttributeTosetOtherCharges);
            //console.log(this.gridTosetOtherChargeList);
            const result = this.gridTosetOtherChargeList.filter(otherChargeData =>
                otherChargeData.areaOfDelivery === this.newAttributeTosetOtherCharges.areaOfDelivery);
            //console.log( result );
            if (result.length > 0 && this.editOtherChargesId == null) {
                swal("Duplicate Entry", "The entered Area is already available," +
                    "please select different area or edit it!", "warning");
            } else {
                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
                this.dtTriggerOtherChargeDatatable.next();
            }
        }
        this.newAttributeTosetOtherCharges = {};
        $("#" + this.pageId + "otherChargeArea").val('');
        $("#" + this.pageId + "basedOnId").val('');
        $("#" + this.pageId + "otherChargeAmountId").val('');
        $("#" + this.pageId + "hiddenIndexOtherChgId").val('');
        this.editOtherChargesId = null;
    }

    rowSelectedOtherChargeDataOnEdit(editOtherChargeData, index) {
        console.log(editOtherChargeData);
        $("#" + this.pageId + "otherChargeArea").val(editOtherChargeData.areaOfDelivery);
        $("#" + this.pageId + "basedOnId").val(editOtherChargeData.ratePerUnit);
        $("#" + this.pageId + "otherChargeAmountId").val(editOtherChargeData.chqAmt);
        this.editOtherChargesId = index;
    }

    getOtherChgValues() {
        this.lrDtosOtherChargesReturnType = [];
        for (let i = 0; i < this.gridTosetOtherChargeList.length; i++) {
            this.lrDtosOtherCharge = new LRDto();
            this.lrDtosOtherCharge.areaOfDelivery = this.gridTosetOtherChargeList[i].areaOfDelivery;
            this.lrDtosOtherCharge.ratePerUnit = this.gridTosetOtherChargeList[i].ratePerUnit;
            this.lrDtosOtherCharge.chqAmt = this.gridTosetOtherChargeList[i].chqAmt;
            this.lrDtosOtherCharge.deliveryType = "DD";
            this.lrDtosOtherChargesReturnType.push(this.lrDtosOtherCharge);
        }
        return this.lrDtosOtherChargesReturnType;
    }

    newRateMtd() {
        this.clearFields();
        for (var i = 0; i < this.sourceListDataList.length; i++) {
            $("#" + this.sourceListDataList[i].agentCode).prop("checked", false);
        }
        if (this.isRateEdit) {
            this.showSaveBtn = true;
            this.showUpdateBtn = false;
            this.showNewRateBtn = false;
        }

        $("#" + this.pageId + "unitHideShow").val("Rate");
        // if (showCommodity) {
        // 	btnRemove.setEnabled(false);
        // 	storeCommodity.removeAll();
        // 	listMainCommodity.setItemSelected(0, true);
        // }
        this.commodityDataList = [];
        $("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
        this.dtTriggerCommodity.next();
        this.gridTosetOtherChargeList = [];
        $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
        this.dtTriggerOtherChargeDatatable.next();
        $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
        for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
            this.gridTosetOtherChargeList.push(this.userDataDtoReturnSession.lrDtos[i]);
        }
        this.dtTriggerOtherChargeDatatable.next();
    }
    ddUpdateMtd(ddUpdatePopUpTemplate) {
        this.modalRefferenceDdUpdateTemplatePopUp = this.modalService.open(ddUpdatePopUpTemplate,
            { centered: true, size: "lg" });

    }

    saveNewHamaliDdPopUpBtn() {

        if ($("#" + this.pageId + "area").val() == null
            || $("#" + this.pageId + "area").val() == undefined || $("#" + this.pageId + "area").val() == "" ||
            $("#" + this.pageId + "currentHamaliDestId").val() == null || $("#" + this.pageId + "currentHamaliDestId").val() == undefined ||
            $("#" + this.pageId + "currentHamaliDestId").val() == "" || $("#" + this.pageId + "updateHamaliDestId").val() == null
            || $("#" + this.pageId + "updateHamaliDestId").val() == undefined || $("#" + this.pageId + "updateHamaliDestId").val() == "") {
            swal("Mandatory Field", "Please select the area and enter the update hamali destination ", "warning");
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to Update the Hamali DD details for the selected Area?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.addNewHamaliDdDetails();
                }
            })
        }
    }

    addNewHamaliDdDetails() {
        this.rateMasterDtoNewHamaliDd = new RateMasterDto();
        this.selecrtedArea = null;
        this.updateHamaliDest = null;
        this.currentHamaliDest = null;
        this.selecrtedArea = $("#" + this.pageId + "area").val();
        this.currentHamaliDest = $("#" + this.pageId + "currentHamaliDestId").val();
        this.updateHamaliDest = $("#" + this.pageId + "updateHamaliDestId").val();



        // console.log("selecrtedArea, currentHamaliDest, updateHamaliDest");
        // console.log(this.selecrtedArea, this.currentHamaliDest, this.updateHamaliDest);
        this.showSpinnerForHamaliDd = true;
        if (this.viewDestination) {
            this.rateMasterDtoNewHamaliDd.city = this.modelDestination;
        } else {
            this.rateMasterDtoNewHamaliDd.city = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDtoNewHamaliDd.mode = "specific";
        this.rateMasterDtoNewHamaliDd.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoNewHamaliDd.area = this.selecrtedArea;
        this.rateMasterDtoNewHamaliDd.hamaliDestination = this.currentHamaliDest;
        this.rateMasterDtoNewHamaliDd.hamaliDD = this.updateHamaliDest;
        this.rateMasterDtoNewHamaliDd.userName = this.userDataDtoReturnSession.userId;

        // console.log("this.rateMasterDtoNewHamaliDd >> ");
        // console.log(this.rateMasterDtoNewHamaliDd);
        this.consigneeService.updateHamliDdAreaWise(this.rateMasterDtoNewHamaliDd).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "success") {
                    swal("Alert", "Hamali Destination Updated Successfully", "success");
                    this.closeNewCollectionManPopUpBtn();
                    $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    this.consigneeDataListForTable = [];

                    this.gridReconfigureForConsignee();

                } else {
                    swal("Failed", "Failed To Update Hamali Destination", "warning");
                }
                this.showSpinnerForHamaliDd = false;
                this.dtTriggerRateMaster.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForHamaliDd = false;
                swal("Error", "Server Error While Updating Hamali Destination", "warning");
            },
            () => console.log('done');
    }

    closeNewCollectionManPopUpBtn() {
        // this.partyMasterDtoForNewCollectionman = new PartyMasterDto();
        // this.validateNewCollectionMan = null;
        // $("#" + this.pageId + "newCollectionManId").val('');
        this.modalRefferenceDdUpdateTemplatePopUp.close();
        this.modelArea = null;

    }

    clickListnerForArea(e: NgbTypeaheadSelectItemEvent) {
        this.modelArea = e.item;
        // console.log(this.modelArea);
        $("#" + this.pageId + "area").val(this.modelArea.destination);
    }

    toggleddRateHamaliLessCheckbox(event) {
        if (event.target.checked) {
            this.ddHamaliLessCheckStatus = true;
        } else {
            this.ddHamaliLessCheckStatus = false;
        }
    }

    confirmOtherChgAreaDelete(editOtherChargeData) {
        swal({
            title: "Confirmation",
            text: "Delete Other Charge of the selected Area?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.deleteOtherChgAreaDtls(editOtherChargeData);
            }
        })
    }

    deleteOtherChgAreaDtls(editOtherChargeData) {
        this.rateMasterDtoCneeRateDel = new RateMasterDto();
        this.rateMasterDtoCneeRateDel.id = editOtherChargeData.autoId;
        this.rateMasterDtoCneeRateDel.area = editOtherChargeData.areaOfDelivery;
        this.showSpinnerForAction = true;
        this.consigneeService.deleteDestMemoOtherChgCneeAreaDetails(this.rateMasterDtoCneeRateDel).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Success") {
                    swal("Success", "Selected Area deleted!", "success");
                    this.showSpinnerForAction = false;
                    this.getOtherChgAreaDtls(editOtherChargeData);
                } else {
                    this.showSpinnerForAction = false;
                    swal("Failed", "Failed to delete the Area", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While deleting the Other Charges area details", "warning");
            },
            () => console.log('done');
    }

    getOtherChgAreaDtls(editOtherChargeData) {
        this.rateMasterDtoCneeRateDel = new RateMasterDto();
        this.rateMasterDtoCneeRateDel.cneeRateMasterAutoId = editOtherChargeData.cneeRateMasterAutoId;
        this.consigneeService.getDestMemoOtherChgCneeDetails(this.rateMasterDtoCneeRateDel).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.gridTosetOtherChargeList = [];
                    $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                    for (let i = 0; i < response.length; i++) {
                        this.newAttributeTosetOtherCharges = {};
                        this.newAttributeTosetOtherCharges.areaOfDelivery = response[i].area;
                        this.newAttributeTosetOtherCharges.ratePerUnit = response[i].ratePerUnit;
                        this.newAttributeTosetOtherCharges.chqAmt = response[i].otherChgAmt;
                        this.newAttributeTosetOtherCharges.autoId = response[i].otherChgDestMemoCneeAutoId;
                        this.newAttributeTosetOtherCharges.cneeRateMasterAutoId = response[i].cneeRateMasterAutoId;
                        this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
                    }
                }
                this.dtTriggerOtherChargeDatatable.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForHamaliDd = false;
                swal("Error", "Server Error", "warning");
            },
            () => console.log('done');
    }

    areaOtherChgUpdateMtd(areaOtherChgUpdatePopUpTemplate) {
        this.modalRefferenceAreaOtherChgUpdateTemplatePopUp = this.modalService.open(areaOtherChgUpdatePopUpTemplate,
            { centered: true, size: "lg" });
    }

    closeAreaOtherChgUpdatePopUpBtn() {
        this.modalRefferenceAreaOtherChgUpdateTemplatePopUp.close();
        this.modelArea = null;
        $("#" + this.pageId + "currentAreaOtherChargesId").val('');
        $("#" + this.pageId + "updateAreaOtherChargesId").val('');
        this.rateMasterDtoNewHamaliDd = new RateMasterDto();
        this.selecrtedArea = null;
        this.currentAreaOtherChg = null;
        this.updateAreaOtherChg = null;
    }

    updateAreaOtherChgPopUpBtn() {
        if ($("#" + this.pageId + "area").val() == null
            || $("#" + this.pageId + "area").val() == undefined || $("#" + this.pageId + "area").val() == "" ||
            $("#" + this.pageId + "currentAreaOtherChargesId").val() == null || $("#" + this.pageId + "currentAreaOtherChargesId").val() == undefined ||
            $("#" + this.pageId + "currentAreaOtherChargesId").val() == "" || $("#" + this.pageId + "updateAreaOtherChargesId").val() == null
            || $("#" + this.pageId + "updateAreaOtherChargesId").val() == undefined || $("#" + this.pageId + "updateAreaOtherChargesId").val() == "") {
            swal("Mandatory Field", "Please select the area and enter the Other Charges details ", "warning");
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to Update the Other Charge details for the selected Area?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.updateAreaOtherChgDetails();
                }
            })
        }
    }

    updateAreaOtherChgDetails() {
        this.rateMasterDtoNewHamaliDd = new RateMasterDto();
        this.selecrtedArea = null;
        this.currentAreaOtherChg = null;
        this.updateAreaOtherChg = null;
        this.selecrtedArea = $("#" + this.pageId + "area").val();
        this.currentAreaOtherChg = $("#" + this.pageId + "currentAreaOtherChargesId").val();
        this.updateAreaOtherChg = $("#" + this.pageId + "updateAreaOtherChargesId").val();

        this.showSpinnerForHamaliDd = true;
        if (this.viewDestination) {
            this.rateMasterDtoNewHamaliDd.city = this.modelDestination;
        } else {
            this.rateMasterDtoNewHamaliDd.city = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDtoNewHamaliDd.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoNewHamaliDd.area = this.selecrtedArea;
        this.rateMasterDtoNewHamaliDd.otherChgAmt = this.updateAreaOtherChg;
        this.rateMasterDtoNewHamaliDd.otherChgAmtSub = this.currentAreaOtherChg;
        this.rateMasterDtoNewHamaliDd.userName = this.userDataDtoReturnSession.userId;

        this.consigneeService.consolidateUpdateDestMemoOtherChgArea(this.rateMasterDtoNewHamaliDd).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Success") {
                    swal("Success", "Other charges for the selected Area updated successfully for Rate Consignee!", "success");
                    this.closeAreaOtherChgUpdatePopUpBtn();
                    // $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    // this.consigneeDataListForTable = [];
                    // this.gridReconfigureForConsignee();
                } else {
                    swal("Failed", "Failed to update the Other Charges!", "error");
                }
                this.showSpinnerForHamaliDd = false;
                // this.dtTriggerRateMaster.next();
                // this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForHamaliDd = false;
                swal("Error", "Server Error occurred while updating the Other Charges!", "error");
            },
            () => console.log('done');
    }

    findOtherChgFromEditCompanySetup() {
        $("#" + this.pageId + "basedOnId").val('');
        $("#" + this.pageId + "otherChargeAmountId").val('');

        let selectedArea = this.modelOtherChargeArea.destination.toLowerCase();
        for (let i = 0; i < this.userDataDtoReturnSession.lrDtos.length; i++) {
            if (selectedArea == this.userDataDtoReturnSession.lrDtos[i].areaOfDelivery.toLowerCase()) {
                console.log(this.userDataDtoReturnSession.lrDtos[i].areaOfDelivery);
                console.log(this.userDataDtoReturnSession.lrDtos[i].ratePerUnit);
                console.log(this.userDataDtoReturnSession.lrDtos[i].chqAmt);

                $("#" + this.pageId + "basedOnId").val(this.userDataDtoReturnSession.lrDtos[i].ratePerUnit);
                $("#" + this.pageId + "otherChargeAmountId").val(this.userDataDtoReturnSession.lrDtos[i].chqAmt);
            }
        }
    }

    //Chg_V1 start
    gdUpdateMtd(gdUpdatePopUpTemplate) {
        this.modalRefferenceGdUpdateTemplatePopUp = this.modalService.open(gdUpdatePopUpTemplate,
            { centered: true, size: "lg" });
    }

    closeGdPopUpBtn() {
        this.modalRefferenceGdUpdateTemplatePopUp.close();
        this.modelArea = null;
    }

    saveNewHamaliGdPopUpBtn() {

        if ($("#" + this.pageId + "areaForGd").val() == null
            || $("#" + this.pageId + "areaForGd").val() == undefined || $("#" + this.pageId + "arareaForGdea").val() == "" ||
            $("#" + this.pageId + "currentGdChargesId").val() == null || $("#" + this.pageId + "currentGdChargesId").val() == undefined ||
            $("#" + this.pageId + "currentGdChargesId").val() == "" || $("#" + this.pageId + "updateGdChargesId").val() == null
            || $("#" + this.pageId + "updateGdChargesId").val() == undefined || $("#" + this.pageId + "updateGdChargesId").val() == "") {
            swal("Mandatory Field", "Please select the area and enter the update hamali GD ", "warning");
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to Update the Hamali GD details for the selected Area?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.addNewHamaliGdDetails();
                }
            })
        }
    }

    addNewHamaliGdDetails() {
        this.rateMasterDtoNewHamaliGd = new RateMasterDto();
        this.selecetedAreaForGd = null;
        this.currentGdCharges = null;
        this.updateGdCharges = null;
        this.selecetedAreaForGd = $("#" + this.pageId + "areaForGd").val();
        this.currentGdCharges = $("#" + this.pageId + "currentGdChargesId").val();
        this.updateGdCharges = $("#" + this.pageId + "updateGdChargesId").val();

        this.showSpinnerForHamaliDd = true;
        if (this.viewDestination) {
            this.rateMasterDtoNewHamaliGd.city = this.modelDestination;
        } else {
            this.rateMasterDtoNewHamaliGd.city = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDtoNewHamaliGd.mode = "specific";
        this.rateMasterDtoNewHamaliGd.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoNewHamaliGd.area = this.selecetedAreaForGd;
        this.rateMasterDtoNewHamaliGd.unloadingChg = this.currentGdCharges;
        this.rateMasterDtoNewHamaliGd.hamaligd = this.updateGdCharges;
        this.rateMasterDtoNewHamaliGd.userName = this.userDataDtoReturnSession.userId;
        console.log(this.rateMasterDtoNewHamaliGd);

        this.consigneeService.updateHamliGdAreaWise(this.rateMasterDtoNewHamaliGd).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "success") {
                    swal("Success", "Hamali GD Updated Successfully", "success");
                    this.closeGdPopUpBtn();
                    $("#" + this.pageId + "consigneeTabelId").DataTable().destroy();
                    this.consigneeDataListForTable = [];
                    this.gridReconfigureForConsignee();
                } else {
                    swal("Failed", "Failed To Update Hamali GD", "warning");
                }
                this.showSpinnerForHamaliDd = false;
                this.dtTriggerRateMaster.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForHamaliDd = false;
                swal("Error", "Server Error While Updating Hamali GD", "warning");
            },
            () => console.log('done');
    }
    //Chg_V1 end

    //Chg_V2
    rateIncreaseUpdateMtd() {
        const dialogRef = this.dialog.open(UpdateRateIncreaseComponent)
    }

}


