<!--<html>

<head>
	<style>
		.imb_patch {
			text-align: center;
			background-color: orange;
			padding: 2px;
		}
	</style>
</head>

<body>-->
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-university"></i>
								</span>
							</div>
							<input id="{{pageId}}adminStationSource" type="text" class="form-control"
								(selectItem)="clickListnerForAdminStationSource($event)"
								[(ngModel)]="modelAdminStationSource" [ngbTypeahead]="searchAdminStationSource"
								[resultFormatter]="formatterAdminStationSource"
								[inputFormatter]="formatterAdminStationSource" placeholder="Select Source"
								(focus)="focusAdminStationSourceTA$.next($any($event).target.value)" />
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-search"></i>
								</span>
							</div>
							<select class="custom-select col-12" id="{{pageId}}hamaliType" name="hamaliType" #hamaliType
								(keypress)="onKeyPressFieldForHamaliType($event.keyCode)">
								<option value="Loading Hamali">Loading Hamali</option>
								<option value="Unloading Hamali">Unloading Hamali</option>
								<option value="Thappi">Thappi</option>
							</select>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="control">
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-university"></i>
									</span>
								</div>
								<input id="{{pageId}}hamaliValue" type="text" class="form-control" autocomplete="off"
									placeholder="Hamali Value"
									(keypress)="onKeyPressFieldForHamaliValue($event.keyCode)" />
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<div class="input-group">
							<a href="javascript:;" type='button' id="{{pageId}}addInTableOtherChargeBtnId" #addBtn
								style="padding: 1px 4px;" class="btn btn-icon-only yellow"
								(click)="addInTableOfHamaliDetailsTable();">
								<i class="fa fa-plus" title="Add"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}hamaliDetailTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsHamali" [dtTrigger]="dtTriggerHamali">
								<thead>
									<tr>
										<th>Source</th>
										<th>Hamali</th>
										<th>Hamali Value</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let gridTosetHamali of gridTosetHamaliList let i = index"
										(click)="rowSelectedHamaliDataOnEdit(gridTosetHamali,i);">
										<td>{{ gridTosetHamali.source }}</td>
										<td>{{ gridTosetHamali.hamaliType }}</td>
										<td>{{ gridTosetHamali.hamaliValue }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-outline-success" mat-button (click)="hamaliDetailsOkClick()">Ok</button>
	<button class="btn btn-danger" mat-button (click)="onCancelClick()">Cancel</button>
</div>
<!--</body>

</html>-->