<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">PDC Info
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					
					<div class="row">
						<div class="box-body">
					
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails"
								[dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<!--<th>Source</th>-->
										<th>PDC Cheque Number</th>
										<th>PDC Amount</th>
										
										
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsData of listOfDataToGet ">
										<!--<td>{{lrDetailsData.source}}</td>-->
										<td>{{lrDetailsData.chequeNumber}}</td>
										<td>{{lrDetailsData.chequeAmt}}</td>
									</tr>


									<!-- <tr *ngIf="supList.length==0">
										<td colspan="9">No Data To Display</td>
									</tr>-->
								</tbody>
								
							</table>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
