import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ConsignorService } from 'src/app/dataService/consignor-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

//for the select option with filter ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { HireslipService } from 'src/app/dataService/hireslip-service';
//for popup modal ends


@Component({
    selector: 'app-stocks-for-tripsheet-page-trip-schedule',
    templateUrl: './stocks-for-tripsheet-page-trip-schedule.component.html',
    styleUrls: ['./stocks-for-tripsheet-page-trip-schedule.component.css']
})
export class StocksForTripsheetPageTripScheduleComponent implements OnInit {

    //to insert value of selected row in table to input field starts 
    consignorName: string;
    selectedUser: any;
    //to insert value of selected row in table to input field ends
    getDataFrmServiceFrEligibleLrsTable: any;
    getDataFrmServiceFrRemainingLrsTable: any;

    eligibleLrsDataList: any;
    remainingLrsDataList: any;

pageId="sftpt";

    onDestroyUnsubscribtionEligibleLrs: Subscription;
    onDestroyUnsubscribtionRemainingLrs: Subscription;

    //for the select option with filter starts

    //for the select option with filter ends

    //for popup modal starts
    closeResultContent: string;
    //for popup modal ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dataTable: any;
    //
    dtTriggerEligibleLrs: Subject<any> = new Subject();
    dtTriggerRemainingLrs: Subject<any> = new Subject();

    dtOptionsEligibleLrs: any;
    dtOptionRemainingLrs: any;

    loadingIndicator = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    branchBinsOption: Array<LRDto> = [];
    lrDtoBranchBins: LRDto = new LRDto();
    public modelDropDownBins: any;
    branchBinsTA: Array<LRDto> = [];
    focusDropDownBinsTA$ = new Subject<string>();
    searchDropDownInputFieldBins = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownBinsTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.branchBinsTA
                : this.branchBinsTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownBins = (x: { godownName: string }) => x.godownName;

    stockTripSheetReturnLocalStorage: LRDto = new LRDto();
    lrDtoControlBinService: LRDto = new LRDto();
    lrDtoCreateATripDetailLocalStorage: LRDto = new LRDto();

    enteredStockBinsDestination: any = null;
    enteredLrNumber: any = null;
    selectedControBinsDataOfAllLrsList: any = [];
    resultList: any = null;

    viewNextTripSheetBtn = false;


    constructor(private tripCreationMakeATripScreen: ConsignorService, private modalService: NgbModal,
        private router: Router, private masterReadService: MasterReadService,
        private hireslipService: HireslipService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }

        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //this.stockTripSheetReturnLocalStorage = JSON.parse(localStorage.getItem('stockTripSheetScheduleForLocalStorage'));
            //console.log( this.stockTripSheetReturnLocalStorage);
            this.setBranchAreaDetailsList();
            /*  this.lrDtoBranchBins = new LRDto();
              this.lrDtoBranchBins.mode = this.userDataDtoReturnSession.office;
              this.lrDtoBranchBins.companyId = this.userDataDtoReturnSession.companyId;
              if (this.userDataDtoReturnSession.isOpenTrip != null &&
                  this.userDataDtoReturnSession.isOpenTrip) {
                      this.setBranchAreaDetailsList();
              }else if(){
  
              }*/
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsEligibleLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 8,
        }, this.dtOptionRemainingLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 8,
        }
    }


    ngAfterViewInit(): void {
        this.dtTriggerEligibleLrs.next();
        this.dtTriggerRemainingLrs.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerEligibleLrs.unsubscribe();
        this.dtTriggerRemainingLrs.unsubscribe();
    }
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected(confirmedConsignorListData) {
        this.selectedUser = confirmedConsignorListData;
        this.consignorName = this.selectedUser.name;
        console.log(this.selectedUser.name);
    }
    //to insert value of selected row in table to input field ends


    //for popup modal starts 

    openPopup(content) {
        this.modalService.open(content, { centered: true }).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

    clickListnerForDropDownInputFieldBins(event) {
        this.modelDropDownBins = event.item;
        $("#"+this.pageId+"dropDownInputFieldBins").val(this.modelDropDownBins.godownName);
        console.log(this.modelDropDownBins);
        this.setControlBinsDestinationDetails();
    }

    getMethodForBranchArea() {
        this.lrDtoBranchBins = new LRDto();
        this.lrDtoBranchBins.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchBins.companyId = this.userDataDtoReturnSession.companyId;
    }

    setBranchAreaDetailsList() {
        this.showSpinnerForAction = true;
        this.getMethodForBranchArea();
        this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchBins).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.branchBinsOption = response;
                    for (let i = 0; i < this.branchBinsOption.length; i++) {
                        this.branchBinsTA.push(this.branchBinsOption[i]);
                    }
                    //hashmap concept pending here
                    this.showSpinnerForAction = false;
                } else {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Area Details", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForBinsDestination($event) {
        if ($event.charCode == 13) {
            this.setControlBinsDestinationDetails();
        }

    }

    onKeyPressListnerForLRNumber($event) {
        if ($event.charCode == 13) {
            console.log($event.charCode);
            this.setLRNumberDetailsValueInDatatTable();
        }
    }

    setControlBinsDestinationDetails() {
        this.lrDtoControlBinService = new LRDto();
        this.stockTripSheetReturnLocalStorage = JSON.parse(localStorage.getItem('stockTripSheetScheduleForLocalStorage'));
        localStorage.clear();
        console.log(this.stockTripSheetReturnLocalStorage);
        this.enteredStockBinsDestination = $("#"+this.pageId+"dropDownInputFieldBins").val();
        this.lrDtoControlBinService.destination = this.enteredStockBinsDestination;
        this.lrDtoControlBinService.branch = this.userDataDtoReturnSession.office;
        this.lrDtoControlBinService.branchType = this.userDataDtoReturnSession.role;
        this.lrDtoControlBinService.stationType = "local";
        this.lrDtoControlBinService.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.hireslipService.getArticlesStocksForDestination(this.lrDtoControlBinService).subscribe(
            (response) => {
                console.log(response);
                /*this.remainingLrsDataList = [];
                $("#"+this.pageId+"remainingLrsTableId").DataTable().destroy();
                this.remainingLrsDataList = response;
                setTimeout(() => {
                    this.dtTriggerRemainingLrs.next();
                }, 3000);
                this.showSpinnerForAction = false;*/
                this.remainingLrsDataList = [];
                this.selectedControBinsDataOfAllLrsList = [];
                this.selectedControBinsDataOfAllLrsList = response;

                if (this.selectedControBinsDataOfAllLrsList != null &&
                    this.selectedControBinsDataOfAllLrsList.length > 0) {
                    this.remainingLrsDataList = this.selectedControBinsDataOfAllLrsList;
                }
                console.log(this.remainingLrsDataList);
                console.log(this.eligibleLrsDataList);
                if (this.eligibleLrsDataList == null || this.eligibleLrsDataList == undefined ||
                    this.eligibleLrsDataList == "" || this.eligibleLrsDataList.length == 0) {
                    this.eligibleLrsDataList = [];
                }
                this.remainingLrsDataList = this.remainingLrsDataList.filter(remainingLrsData =>
                    !this.eligibleLrsDataList.find(eligibleLrsData =>
                        (eligibleLrsData.lrNumber === remainingLrsData.lrNumber)));

                console.log(this.remainingLrsDataList);
                $("#"+this.pageId+"remainingLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerRemainingLrs.next();
                }, 3000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Details Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    setLRNumberDetailsValueInDatatTable() {
        this.enteredLrNumber = null;
        this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        // finding eligible & remaing lrs method
        this.showSpinnerForAction = true;
        var validateRemaingLrNumber = this.findRemainingLrsDup(this.remainingLrsDataList, this.enteredLrNumber);
        var validateEligibleLrNumber = this.findEligibleLrs(this.eligibleLrsDataList, this.enteredLrNumber);
        this.showSpinnerForAction = false;
        console.log("EligibleFind: " + validateEligibleLrNumber);
        console.log("RemaingFind: " + validateRemaingLrNumber);
        if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
            swal("Alert", "LR number does not exist", "warning");
            $("#"+this.pageId+"lrNumber").focus();
        } else if (validateEligibleLrNumber != null) {
            swal("Make Invoice", "LRNumber '" + $("#"+this.pageId+"lrNumber").val() + "'Already Transfered", "warning");
            $("#"+this.pageId+"lrNumber").focus();
        } else {
            this.setLrNumberEligibleAndRemaingLrs();
            $("#"+this.pageId+"lrNumber").val('');
            $("#"+this.pageId+"lrNumber").focus();
        }

    }

    findRemainingLrsDup(findRemaingLrs, lrNumber) {
        var checkingRemLrNumber = null;
        var remainLrNumberReturn = null;
        for (let i = 0; i < findRemaingLrs.length; i++) {
            checkingRemLrNumber = findRemaingLrs[i].lrNumber;
            if (checkingRemLrNumber == lrNumber) {
                remainLrNumberReturn = checkingRemLrNumber;
                break;
            } else {
                remainLrNumberReturn = null;
            }
        }
        return remainLrNumberReturn;
    }

    findEligibleLrs(findEligibleLrs, lrNumber) {
        var checkingEligLrNumber = null;
        var eligibleLrNumberReturn = null;
        for (let i = 0; i < findEligibleLrs.length; i++) {
            checkingEligLrNumber = findEligibleLrs[i].lrNumber;
            if (checkingEligLrNumber == lrNumber) {
                eligibleLrNumberReturn = checkingEligLrNumber;
                break;
            } else {
                eligibleLrNumberReturn = null;
            }
        }
        return eligibleLrNumberReturn;
    }

    setLrNumberEligibleAndRemaingLrs() {
        this.resultList = null;
        this.showSpinnerForAction = true;
        this.resultList = this.remainingLrsDataList.filter(remainingLrsData =>
            remainingLrsData.lrNumber == $("#"+this.pageId+"lrNumber").val());
        if (this.resultList.length > 0) {
            for (let i = 0; i < this.resultList.length; i++) {
                this.eligibleLrsDataList.push(this.resultList[i]);
            }
            $("#"+this.pageId+"eligibleLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerEligibleLrs.next();
            }, 3000);
            /* this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                 dtElement.dtInstance.then((dtInstance: any) => {
                     if (dtInstance.table().node().id == "eligiblelrsTableId") {
                         dtInstance.destroy();
                         for (let i = 0; i < this.resultList.length; i++) {
                             this.eligibleLrsDataList.push(this.resultList[i]);
                         }
                         this.dtTriggerEligibleLrs.next();
                     }
                 });
             });*/
            const deletedContrat = this.remainingLrsDataList.find(x => x.lrNumber === $("#"+this.pageId+"lrNumber").val());
            this.remainingLrsDataList.splice(this.remainingLrsDataList.indexOf(deletedContrat), 1);
            $("#"+this.pageId+"remainingLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerRemainingLrs.next();
            }, 3000);
            this.showSpinnerForAction = false;
            // console.log(deletedContrat);
            //console.log(this.remainingLrsDataList.splice(this.remainingLrsDataList.indexOf(deletedContrat), 1));
            /* this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                 dtElement.dtInstance.then((dtInstance: any) => {
                     if (dtInstance.table().node().id == "DataTables_Table_2") {
                         dtInstance.destroy();
                         this.dtTriggerRemainingLrs.next();
                     }
                 });
             });*/

        }
    }


    check(matStepperNext:any){

    }
    stockTripNextBtn() {
        this.lrDtoCreateATripDetailLocalStorage = new LRDto();
        this.lrDtoCreateATripDetailLocalStorage.lrDtoEligible = this.eligibleLrsDataList;
        localStorage.clear();
        localStorage.setItem('createTripsheetForDriverLocalStorage', JSON.stringify(this.lrDtoCreateATripDetailLocalStorage));
        console.log(this.lrDtoCreateATripDetailLocalStorage);
    }

}
