<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBusinessStopRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Business Stop Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
														</div>
														<input id="{{pageId}}sourceStationId" type="text"
															class="form-control"
															(selectItem)="clickListnerForSourcStation($event)"
															[(ngModel)]="modelSourceStation"
															[ngbTypeahead]="searchSourceStation"
															[resultFormatter]="formatterSourceStation"
															[inputFormatter]="formatterSourceStation"
															(focus)="focusSourceStationTA$.next($any($event).target.value)"
															placeholder="Select Source.." />
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text"
															class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="validateForGetBusinessReportDetails()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearFeilds()">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div class="box-body">
							<button type="submit" class="dt-button" style="margin-left: 92%;"
								(click)="customPrintBusinessStop()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>

							<table datatable class="table table-striped table-bordered row-border hover"
								id="{{pageId}}businessStopReportTableId" [dtOptions]="dtOptionsBusinessStopRpt"
								[dtTrigger]="dtTriggerBusinessStopRpt">

								<thead>
									<tr>
										<th>LR Number</th>
										<th>Party Name</th>
										<th>Address</th>
										<th>Office No</th>
										<th>Mobile No</th>
										<th>Last LR Date</th>
										<th>Avg Act Wt. per day</th>
										<th>Avg Chg Wt. per day</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let businessStopRptData of businessStopRptDataList ">
										<td>{{ businessStopRptData.lrNumber }}</td>
										<td>{{ businessStopRptData.consigneeName }}</td>
										<td>{{ businessStopRptData.addressConsignee }}</td>
										<td>{{ businessStopRptData.officeNo }}</td>
										<td>{{ businessStopRptData.mobileNum }}</td>
										<td>{{ businessStopRptData.bookingDate }}</td>
										<td>{{ businessStopRptData.actualWeight }}</td>
										<td>{{ businessStopRptData.chargedWeight }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>

</body>

</html>