<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" id="{{pageId}}agentRankingRptId" *ngIf="isLoggedIn">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Point-to-point Service Check</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Routes</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="fromAgent">From Agent</option>
															<option value="atSource">At Source</option>
															<option value="toDestination">To Destination</option>

														</select>
													</div>
												</div>
											</div>

											<div *ngIf="showAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}agentName">
														<label>Agent Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input id="{{pageId}}agentName" type="text" class="form-control"
															[(ngModel)]="modelAgentName"
															[ngbTypeahead]="searchAgentName"
															(selectItem)="clickListnerForAgentName($event)"
															[resultFormatter]="formatterAgentName"
															[inputFormatter]="formatterAgentName"
															(focus)="focusAgentNameTA$.next($any($event).target.value)"
															placeholder="Select Agent Name" />
													</div>
												</div>
											</div>

											<div *ngIf="showCollectionCenter" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}agentName">
														<label>Collection Center</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input id="{{pageId}}collectionCenter" type="text"
															class="form-control" [(ngModel)]="modelCollectionCenter"
															[ngbTypeahead]="searchCollectionCenter"
															(selectItem)="clickListnerForCollectionCenter($event)"
															[resultFormatter]="formatterCollectionCenter"
															[inputFormatter]="formatterCollectionCenter"
															(focus)="focusCollectionCenterTA$.next($any($event).target.value)"
															placeholder="Select Collection Center" />
													</div>
												</div>
											</div>

											<div *ngIf="showDaysTaken" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Days Taken</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}daysInTakenId"
															class=" form-control " aria-describedby="basic-addon11 ">
													</div>
												</div>
											</div>
											<div *ngIf="showHaltingDays " class="col-sm-12 col-md-12 ">
												<div class="form-group ">
													<div class="input-group ">
														<label>Halting Days</label>
														<div class="input-group-prepend ">
															<span class="input-group-text "> <i
																class="fas fa-file-alt "></i>
															</span>
														</div>
														<input type="text " id="{{pageId}}haltingDaysId "
															class="form-control " aria-describedby="basic-addon11 ">
													</div>
												</div>
											</div>

											<div *ngIf="showDestination" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>

															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																(selectItem)="clickListnerForDestination($event)"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>



										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12 " style="text-align: center;">
							<button type="submit " class="btn btn-success m-r-10 "
								id="{{pageId}}searchBtn ">Search</button>
							<button type="submit " class="btn btn-dark " id="{{pageId}}clearBtn ">Clear</button>
						</div>
					</div>

					<div class="col-md-9 vl p-t-10">
						<div class="card-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="row p-t-10">

								<div class="col-md-8">
									<h6 class="card-title">Point-to-point Service Check</h6>
								</div>
								<div class="col-md-4">

									<button type="submit" class="dt-button"
										style="margin-left: 72%;"
										(click)="customPrintGcAmtCollected()" id="{{pageId}}printAllBtn">
										<span><i class="fas fa-print">Print</i></span>
									</button>
								</div>

							</div>
							<div class="box-body">

								<table datatable id="{{pageId}}p2pTableId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsPointToPointSerChk"
									[dtTrigger]="dtTriggerPointToPointSerChk">

									<thead>
										<tr>
											<th>From Station</th>
											<th>To Station</th>
											<th>Days Taken</th>
											<th>Is Halting</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let p2pData of p2pDataList ">
											<td>{{ p2pData.fromStation }}</td>
											<td>{{ p2pData.toStation}}</td>
											<td>{{ p2pData.diffDays }}</td>
											<td>{{ p2pData.isTrue }}</td>
											<td><button
													style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}editBtn"
													(click)="rowSelectedEdit(p2pData);">
													<i title="View" class="fas fa-edit"></i>
												</button>
												<button
													style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}removeBtn"
													(click)="confirmDelete(p2pData)">
													<i title="Remove" class="fas fa-trash"></i>
												</button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1 " onafterprint="afterPrint() "
		id="{{pageId}}viewCustomPrintV1 ">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>