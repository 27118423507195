import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import *  as moment from 'moment';
import { LrReportService } from 'src/app/dataService/lr-report-service';

@Component({
	selector: 'app-manual-stock-check',
	templateUrl: './manual-stock-check.component.html',
	styleUrls: ['./manual-stock-check.component.css']
})
export class ManualStockCheckComponent implements OnInit {

	manualStockCheckDataList: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerManualStockCheck: Subject<any> = new Subject();
	dtOptionsManualStockCheck: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	checkedLrDto: LRDto = new LRDto();
	selectedType: any;
	enteredLrNumber: any;
	getLrInfoLrDto: LRDto = new LRDto();
	checkLrNumberLRDto: LRDto = new LRDto();
	responseOfCheckLRNumber: any;
	detailsOfLrLrDto: LRDto = new LRDto();
	responseOfCheckLrForTable: any;
	lrInfoForTable: LRDto = new LRDto();
	updateLrLrDto: LRDto = new LRDto();
	private newAttribute: any = {};
	textLrCount = '';
	lrCount = 0;
	lblLrCount = false;
	countOfLr = 0;
	sumOfTotToPay = 0;
	sumOfTotPaid = 0;
	sumOfTotArt = 0;
	unCheckedLrDto: LRDto = new LRDto();
	isUncheckedLr = false;
	pageId = "mscc";
	//For Custom Print
	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	printSecondTitle: any;
	avgActWtSummary: number;
	avgChgWtSummary: number;
	articleSummary: number;
	actWtSummary: number;
	totalMemoSummary: number;
	chgWtSummary: number;
	totalAmtSummary: number;
	todayDate: any;
	toPaySummary: any;
	paidSummary: any;
	toPaySummaryRoundOff: any;
	paidSummaryRoundOff: any;

	constructor(private router: Router, private lrReportService: LrReportService, private datePipe: DatePipe,
		public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.gridReconfigure();

		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
			});
		})
	}

	ngOnInit(): void {
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		this.dtOptionsManualStockCheck = {

			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "Manual Stock Check - " + dateformate;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalArt = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totToPay = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totPaid = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(totalArt);
				$(api.column(3).footer()).html(totToPay);
				$(api.column(4).footer()).html(totPaid);
				$(api.column(5).footer()).html();
			}
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerManualStockCheck.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerManualStockCheck.next();
	}

	gridReconfigure() {

		this.checkedLrDto = new LRDto();
		this.checkedLrDto.mode = 'Check';
		this.checkedLrDto.destination = this.userDataDtoReturnSession.office;
		this.checkedLrDto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.checkedLrDto);
		$("#" + this.pageId + "viewId").val('Check');
		this.getCheckedLrDetails();
	}
	getCheckedLrDetails = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getStockCheckReport(this.checkedLrDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "manualStockCheckId").DataTable().destroy();
				this.manualStockCheckDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No checked LRs available!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.manualStockCheckDataList = response;
					console.log(this.manualStockCheckDataList);
				}
				this.dtTriggerManualStockCheck.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the checked Lr Details", "info");
			}, () => console.log('done');
	};

	entryTypeMode(entryType: string) {
		$("#" + this.pageId + "lrNumber").val('');
		$("#" + this.pageId + "toPay").val('');
		$("#" + this.pageId + "paid").val('');
		$("#mscclrNumber").focus();
	}
	getLrDetails(e) {
		this.selectedType = $("#" + this.pageId + "entryType").val();
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		if (this.selectedType == 'manual') {
			if (e.keyCode == 13) {
				if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
					this.getLRStatusAndGetLRInfo();
				} else {
					swal({
						title: "Warning",
						text: "Please Enter Lr Number",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						window.setTimeout(function () {
							$("#mscclrNumber").focus();
						}, 100);
						return false;
					});
				}
			}
		} else if (this.selectedType == 'scan') {
			/* setTimeout(() => {
				   this.showforAutomatic();
			   },
				   500 );*/
			if (e.keyCode == 13) {
				this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
				if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
					this.getLRStatusAndGetLRInfo();
				} else {
					swal({
						title: "Warning",
						text: "Please Scan Lr Number",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						window.setTimeout(function () {
							$("#mscclrNumber").focus();
						}, 100);
						return false;
					});
				}

			}
		}
	}

	/* showforAutomatic() {
		   this.enteredLrNumber =$("#"+this.pageId+"lrNumber").val();
			if ( this.enteredLrNumber != null && this.enteredLrNumber != '' ) {
				this.getLRStatusAndGetLRInfo();
			}else{
				swal({
						title: "Warning",
						text: "Please Scan Lr Number",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						 window.setTimeout(function () {
							   $("#mscc#lrNumber" ).focus();
						}, 100);
					 return false;
					});
			}
	    
	}*/
	getLRStatusAndGetLRInfo() {
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.getLrInfoLrDto = new LRDto();
		this.getLrInfoLrDto.lrNumber = this.enteredLrNumber;
		this.getLrInfoLrDto.stocksAt = this.userDataDtoReturnSession.office;
		this.getLrInfoLrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.getLRStatusAndGetLRDetails();
		console.log(this.getLrInfoLrDto);
	}
	getLRStatusAndGetLRDetails() {
		this.showSpinnerForAction = true;
		$("#" + this.pageId + "#okBtn").prop("disabled", true);
		this.lrReportService.getLRStatus(this.getLrInfoLrDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.length > 0) {
					this.showSpinnerForAction = false;
					this.checkLrNumberLRDto = new LRDto();
					this.responseOfCheckLRNumber = response;
					this.checkLrNumberLRDto = this.responseOfCheckLRNumber;
					console.log(this.checkLrNumberLRDto);
					console.log(this.checkLrNumberLRDto[0].lrNumber);
					if (this.checkLrNumberLRDto[0].lrNumber != null && this.checkLrNumberLRDto[0].lrNumber != '') {

						if ((response.length != 0) && (this.checkLrNumberLRDto[0].lrStockCheck == true)) {
							swal({
								title: "Checked",
								text: "This LR is already checked",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'Deleted') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "Deleted",
								text: "LR is deleted",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'Delivered') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "Delivered",
								text: "LR is already delivered",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'InCP') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "InCP",
								text: "LR is in check post",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'Loading') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "Loading",
								text: "LR is in loading",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'Scheduled') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "Scheduled",
								text: "LR is scheduled to transit ",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else if ((response.length != 0) && (this.checkLrNumberLRDto[0].status == 'Transit') && (this.checkLrNumberLRDto[0].lrStockCheck == false)) {
							swal({
								title: "Transit",
								text: "LR is in transit",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();
							});
						} else {
							this.getDetailsLRInfoMethod();
						}


					} else {
						swal({
							title: "Warning",
							text: "This LR number does not exist",
							icon: "error",
							closeOnClickOutside: false,
							closeOnEsc: false,
						}).then(() => {
							this.clearMethod();
						});
					}
				} else {
					swal({
						title: "Warning",
						text: "This LR number does not exist",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.clearMethod();
					});

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Getting Lr Details", "info");
			}, () => console.log('done');
	};

	getDetailsLRInfoMethod() {
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.detailsOfLrLrDto = new LRDto();
		this.detailsOfLrLrDto.mode = 'rpt';
		this.detailsOfLrLrDto.destination = this.userDataDtoReturnSession.office;
		this.detailsOfLrLrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.detailsOfLrLrDto.lrNumber = this.enteredLrNumber;
		console.log(this.detailsOfLrLrDto);
		this.getDetailsMethod();
	}
	getDetailsMethod() {
		this.showSpinnerForAction = true;
		this.lrReportService.getStockCheckReport(this.detailsOfLrLrDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Alert",
						text: "LR not found",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.clearMethod();
					});
				} else {
					this.lrInfoForTable = new LRDto();
					this.responseOfCheckLrForTable = response;
					this.lrInfoForTable = this.responseOfCheckLrForTable;
					this.selectedType = $("#" + this.pageId + "#entryType").val();

					$("#" + this.pageId + "toPay").val(this.lrInfoForTable[0].toPay);
					$("#" + this.pageId + "paid").val(this.lrInfoForTable[0].paid);
					if (this.selectedType == 'scan') {
						this.updateMethod();
					} else {
						$("#" + this.pageId + "okBtn").prop("disabled", false);
						$("#msccokBtn").focus();
					}



				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Error in getting details for LR: ", "info");
			}, () => console.log('done');
	};
	clearMethod() {
		$("#" + this.pageId + "lrNumber").val('');
		$("#" + this.pageId + "toPay").val('');
		$("#" + this.pageId + "paid").val('');

		window.setTimeout(function () {
			$("#mscc#lrNumber").focus();
		}, 100);
	}

	addInTable() {
		console.log('8')
		if (this.isUncheckedLr == true) {
			this.manualStockCheckDataList = [];
			this.isUncheckedLr = false;
		}
		this.newAttribute.lrNumber = this.lrInfoForTable[0].lrNumber;
		this.newAttribute.toPay = this.lrInfoForTable[0].toPay
		this.newAttribute.paid = this.lrInfoForTable[0].paid
		this.newAttribute.bookingDateStr = this.lrInfoForTable[0].bookingDateStr
		this.newAttribute.totalArticles = this.lrInfoForTable[0].totalArticles
		this.newAttribute.consigneeName = this.lrInfoForTable[0].consigneeName

		$("#" + this.pageId + "manualStockCheckId").DataTable().destroy();
		if (this.manualStockCheckDataList == null) {
			this.manualStockCheckDataList = [];
		}


		if (this.manualStockCheckDataList.length == 0) {
			this.manualStockCheckDataList.push(this.newAttribute);
		} else {
			this.manualStockCheckDataList.push(this.newAttribute);
		}
		this.dtTriggerManualStockCheck.next();
		// console.log( this.manualStockCheckDataList );

		this.countOfLr = +this.countOfLr + 1;
		this.sumOfTotArt = +this.sumOfTotArt + +this.newAttribute.totalArticles;
		this.sumOfTotToPay = +this.sumOfTotToPay + +this.newAttribute.toPay;
		this.sumOfTotPaid = +this.sumOfTotPaid + +this.newAttribute.paid;

		this.newAttribute = {};
	}
	okBtnMethod() {
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
			this.updateMethod();
		} else {

		}
	}

	updateMethod() {
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.updateLrLrDto = new LRDto();
		this.updateLrLrDto.userName = this.userDataDtoReturnSession.userId;
		this.updateLrLrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.updateLrLrDto.lrNumber = this.enteredLrNumber;
		console.log(this.updateLrLrDto);
		this.updateLrMethod();
	}
	updateLrMethod() {
		this.showSpinnerForAction = true;
		this.lrReportService.updateStockCheck(this.updateLrLrDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.status == 'Updated') {
					this.lblLrCount = true;
					this.lrCount = +this.lrCount + +1
					this.textLrCount = " No.Of LRs: " + this.lrCount;
					this.clearMethod();
					this.addInTable();
					console.log('6');
					$("#" + this.pageId + "viewId").val('Check');
				} else {


				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Error in getting updating  LR: ", "info");
			}, () => console.log('done');
	}

	unCheckedLrsPrintMethod() {
		this.unCheckedLrDto = new LRDto();
		this.unCheckedLrDto.mode = 'unCheck';
		this.unCheckedLrDto.destination = this.userDataDtoReturnSession.office;
		this.unCheckedLrDto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.unCheckedLrDto);
		this.unCheckedLrsPrintDetails();
		$("#" + this.pageId + "viewId").val('unCheck');

	}
	unCheckedLrsPrintDetails = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getStockCheckReport(this.unCheckedLrDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				this.isUncheckedLr = false;
				$("#" + this.pageId + "manualStockCheckId").DataTable().destroy();
				this.manualStockCheckDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No UnChecked LRs available!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.isUncheckedLr = true;
					this.manualStockCheckDataList = response;
					console.log(this.manualStockCheckDataList);
				}
				this.dtTriggerManualStockCheck.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the unchecked Lr Details", "info");
			}, () => console.log('done');
	};


	checkedLrsPrintMethod() {
		$("#" + this.pageId + "viewId").val('Check');
		this.gridReconfigure();
	}
	clearAllMethod() {
		$("#" + this.pageId + "viewId").val('Check');
		this.clearMethod();
		$("#" + this.pageId + "manualStockCheckId").DataTable().destroy();
		this.manualStockCheckDataList = [];
		this.dtTriggerManualStockCheck.next();
		this.showSpinnerForAction = false;
		this.unCheckedLrDto = new LRDto();
		this.checkedLrDto = new LRDto();
		this.getLrInfoLrDto = new LRDto();
		this.enteredLrNumber = '';
		this.checkLrNumberLRDto = new LRDto();
		this.responseOfCheckLRNumber = '';
		this.detailsOfLrLrDto = new LRDto();
		this.lrInfoForTable = new LRDto();
		this.responseOfCheckLrForTable = '';
		this.selectedType = '';
		$("#" + this.pageId + "#okBtn").prop("disabled", true);
		this.countOfLr = 0;
		this.sumOfTotArt = 0;
		this.sumOfTotToPay = 0;
		this.sumOfTotPaid = 0;
		this.newAttribute = {};
		this.updateLrLrDto = new LRDto();
		this.lblLrCount = false;
		this.lrCount = 0;
		this.textLrCount = "";
	}

	customPrintManualStockCkh() {
		if (this.manualStockCheckDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Booking Date", "Articles", "To Pay", "Paid", "Party Name"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [20, 15, 10, 10, 15, 30];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.articleSummary = this.toPaySummary = this.paidSummary = this.toPaySummaryRoundOff = this.paidSummaryRoundOff = 0;
			for (let i = 0; i < this.manualStockCheckDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.manualStockCheckDataList[i].lrNumber, this.manualStockCheckDataList[i].bookingDateStr,
				this.manualStockCheckDataList[i].totalArticles, this.manualStockCheckDataList[i].toPay, this.manualStockCheckDataList[i].paid, this.manualStockCheckDataList[i].consigneeName];
				this.articleSummary = +this.articleSummary + this.manualStockCheckDataList[i].totalArticles;
				this.toPaySummary = +this.toPaySummary + this.manualStockCheckDataList[i].toPay;
				this.paidSummary = +this.paidSummary + this.manualStockCheckDataList[i].paid;
				this.toPaySummaryRoundOff = Math.round(this.toPaySummary);
				this.paidSummaryRoundOff = Math.round(this.paidSummary);
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.manualStockCheckDataList.length, "", this.articleSummary, this.toPaySummaryRoundOff, this.paidSummaryRoundOff, "",];
			}

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			//heading logics
			var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
			var dateformate = moment(todayDate).format('DD-MM-YYYY');
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [dateformate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}
			this.printSecondTitle = null;
			if (this.isUncheckedLr) {
				this.printSecondTitle = "Unchecked LRs";
			} else {
				this.printSecondTitle = "Checked LRs";
			}
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Type"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.printSecondTitle];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Manual Stock Check");
			// localStorage.setItem('printTitle', "Manual Stock Check" +" - "+ this.printSecondTitle);
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}

}