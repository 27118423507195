<h1 class="title_custom" mat-dialog-title>
		<h6>Collection Man:{{getCollectionMan}} , Statement Number: {{getStmtNo}}</h6>
</h1>
<div mat-dialog-content>
	<h6 style="margin-bottom: 0px;">Pending Lr Details</h6>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="box-body">
						<table datatable style="width: 100%;" id="{{pageId}}pendingLrDetailsId" 
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsPendingLrDetails"
							[dtTrigger]="dtTriggerPendingLrDetails">

							<thead>
								<tr>
									<th>LR Number</th>
									<th>Cashmemo No</th>
									<th>Consignee Name</th>
									<th>Cashmemo Date</th>
									<th>Assign Date</th>
									<th>Memo Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let pendingLrDetailsData of pendingLrDetailsDataList ">
									<td>{{ pendingLrDetailsData.lrNumber }}</td>
									<td>{{ pendingLrDetailsData.memoNumber }}</td>
									<td>{{ pendingLrDetailsData.consigneeName }}</td>
									<td>{{ pendingLrDetailsData.memoDateStr }}</td>
									<td>{{ pendingLrDetailsData.assignedDateStr }}</td>
									<td>{{ pendingLrDetailsData.amount }}</td>
								</tr>

							</tbody>
							<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										
									</tr>
								</tfoot>
						</table>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-outline-danger" mat-button
		(click)="onCancelClick()">Cancel</button>
</div>

