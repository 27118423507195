import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";
import { InvoiceDto } from '../dto/Invoice-dto';
import { HireSlipDto } from '../dto/HireSlip-dto';


@Injectable({
    providedIn: 'root'
})
export class StockReport {

    private _baseUrl = serverUrl.webStockReport;

    constructor(private http: HttpClient) {

    }

    getUnloadingReportNotDetain(invoiceDto: Object): Observable<InvoiceDto[]> {
        return this.http.post<InvoiceDto[]>(`${this._baseUrl}/getUnloadingReportNotDetainApi`, invoiceDto);
    }

    getUnloadingReportDetain(invoiceDto: Object): Observable<InvoiceDto[]> {
        return this.http.post<InvoiceDto[]>(`${this._baseUrl}/getUnloadingReportDetainApi`, invoiceDto);
    }

    getUnloadingReportLrDetailsDetainReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUnloadingReportLrDetailsDetainApi`, lrDto);
    }

    getUnloadingReportLrDetailsNotDetainReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUnloadingReportLrDetailsNotDetainApi`, lrDto);
    }

    advanceConsentProcessFTService(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/advanceConsentProcessFTServiceApi`, hireSlipDto);
    }

}








