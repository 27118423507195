import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from "src/app/dataService/hireslip-service";

@Component( {
    selector: 'app-get-hireslip-barcode-value',
    templateUrl: './get-hireslip-barcode-value.component.html',
    styleUrls: ['./get-hireslip-barcode-value.component.css']
} )
export class GetHireslipBarcodeValueComponent implements OnInit {

    closeResult: string;
	userDataDtoReturnSession: any;
	isLoggedIn = true;
	hireslipDtoSearch: HireSlipDto = new HireSlipDto();
	hireslipNumberEntered:any;
	pageId="ghbvc";

    constructor( private modalService: NgbModal, private router: Router, private hireslipService: HireslipService, public changeDetectorRef : ChangeDetectorRef ) { 
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
    	if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
    	}
    }
    
    open2( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }
    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
    }
    
    searchMethod() {
    	this.hireslipDtoSearch = new HireSlipDto();
    	this.hireslipNumberEntered = $("#"+this.pageId+"hireslipNumber").val();
    	this.hireslipDtoSearch.hireslipnumber = this.hireslipNumberEntered;
        this.hireslipService.getHireslipBarcodeValue(this.hireslipDtoSearch).subscribe(
            (response) => {
                if (response.barcodeValue == null) {
                	swal("No Records", "No Records found for this search", "info");
                    console.log(response);
                } else {
                	$("#"+this.pageId+"barcodeValue").val(response.barcodeValue);
                }
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            }, () => console.log('done');
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


}
