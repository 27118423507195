<div class="row" *ngIf="isLoggedIn" id="{{pageId}}agentsubstnmstr">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Agent Substation Master</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center'
                                            style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row ">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Main Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text"
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Main Station"
                                                            [readonly]="readonlyMainStation" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Substation</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-map-marker-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}substationId"
                                                        name="substation" autocomplete="off"
                                                        [readonly]="readonlySubstation">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Person</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}contactPersonId"
                                                        name="contactPerson" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-phone"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" autocomplete="off"
                                                        aria-describedby="basic-addon11" id="{{pageId}}contactNoId"
                                                        name="contactNo">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Address</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <textarea class="form-control " rows="2" id="{{pageId}}addressId"
                                                        name="address" autocomplete="off"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Email-Id</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}emailId"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>City</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-map-marker-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}cityId"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>State</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-map-marker-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input #stateNameCode id="{{pageId}}stateNameAndCodeId"
                                                            type="text" class="form-control"
                                                            [(ngModel)]="modelStateNameAndCode"
                                                            [ngbTypeahead]="searchStateNameAndCode"
                                                            [resultFormatter]="formatterStateNameAndCode"
                                                            [inputFormatter]="formatterStateNameAndCode"
                                                            (focus)="focusStateNameAndCodeTA$.next($any($event).target.value)"
                                                            autocomplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Latitude</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input #latitude type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}latitudeId"
                                                        name="latitude" [(ngModel)]="latitudeModel">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Longitude</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input #longitude type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}longitudeId"
                                                        name="longitude" [(ngModel)]="longitudeModel">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="row ">
                                        <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Delivery Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input #deliveryChg type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}deliveryChgId"
                                                        name="deliveryChg" [(ngModel)]="deliveryChgModel">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Unit(DC)</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                        </span>
                                                    </div>
                                                    <select #unit class="custom-select col-12"
                                                        id="{{pageId}}deliveryChgunitId" name="deliveryChgunit"
                                                        [(ngModel)]="deliveryChgunitModel">
                                                        <option value="">Select.....</option>
                                                        <option value="Article">Article</option>
                                                        <option value="Actual Weight">Actual Weight</option>
                                                        <option value="Charged Weight">Charged Weight</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Loading & Unloading Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input #ldgAndUnldgChg type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}ldgAndUnldgChgId"
                                                        name="ldgAndUnldgChg" [(ngModel)]="onLoadUnloadingChgModel">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Unit</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                        </span>
                                                    </div>
                                                    <select #unit class="custom-select col-12"
                                                        id="{{pageId}}ldgAndUnldgUnitId" name="ldgUnldgunit"
                                                        [(ngModel)]="onLoadUnlLoadUnitModel">
                                                        <option value="">Select.....</option>
                                                        <option value="Article">Article</option>
                                                        <option value="Actual Weight">Actual Weight</option>
                                                        <option value="Charged Weight">Charged Weight</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GD Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input #gdCharge type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}gdChargeId"
                                                        name="gdCharge" [(ngModel)]="gdChargeModel">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Unit(GD)</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                        </span>
                                                    </div>
                                                    <select #unit class="custom-select col-12" id="{{pageId}}gdUnitId"
                                                        name="gdUnit" [(ngModel)]="gdUnitModel">
                                                        <option value="">Select.....</option>
                                                        <option value="Article">Article</option>
                                                        <option value="Actual Weight">Actual Weight</option>
                                                        <option value="Charged Weight">Charged Weight</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="width: 80%; border-top: none; margin: 3px;">
                                        <div class="col-md-12" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10"
                                                id="{{pageId}}searchBtn"
                                                (click)="validateForAgentSubstionDetailsSave()">Save/Update</button>
                                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                                (click)="clearFields()">Clear</button>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-6 vl p-t-10">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}agentSubstationMasterId"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsAgentSubstationMaster"
                                            [dtTrigger]="dtTriggerAgentSubstationMaster">

                                            <thead>
                                                <tr>
                                                    <th [hidden]=true>Main Station</th>
                                                    <th>Action</th>
                                                    <th>Substation Name</th>
                                                    <th [hidden]=true>Substation Type</th>
                                                    <th [hidden]=true>Contact Person</th>
                                                    <th [hidden]=true>Contact Number</th>
                                                    <th [hidden]=true>Address</th>
                                                    <th [hidden]=true>Email-Id</th>
                                                    <th [hidden]=true>City</th>
                                                    <th [hidden]=true>State</th>
                                                    <th [hidden]=true>Latitude</th>
                                                    <th [hidden]=true>Longitude</th>
                                                    <th>D.Chg</th>
                                                    <th>Unit</th>
                                                    <th>Ldg & Uldg Chg</th>
                                                    <th>Unit</th>
                                                    <th>GD Chg</th>
                                                    <th>Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let agentSubstationDetailsData of agentSubstationDetailsDataList">
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.mainStation }}</td>
                                                    <td><button type="button"
                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                            class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn"
                                                            (click)="rowSelectedGetAgentSSubstationDeatils(agentSubstationDetailsData);">
                                                            <i title="Agent Substation Master"
                                                                class="fas fa-pencil-alt"></i>
                                                        </button></td>
                                                    <td>{{ agentSubstationDetailsData.subStation }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.column1 }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.contactPerson }}
                                                    </td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.phoneNo }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.address }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.emailAddress }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.city }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.state }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.lat }}</td>
                                                    <td [hidden]=true>{{ agentSubstationDetailsData.lng }}</td>
                                                    <td>{{ agentSubstationDetailsData.crossingChg }}</td>
                                                    <td>{{ agentSubstationDetailsData.unitCrossChg }}</td>
                                                    <td>{{ agentSubstationDetailsData.hamaliChg }}</td>
                                                    <td>{{ agentSubstationDetailsData.unitHamaliChg }}</td>
                                                    <td>{{ agentSubstationDetailsData.subStationGDChg }}</td>
                                                    <td>{{ agentSubstationDetailsData.subStationGDChgUnit }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td [hidden]=true>Total</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>