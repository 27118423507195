<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Destination Commission</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text"
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control"
                                                        id="{{pageId}}fromDate" placeholder="yyyy-mm-dd"
                                                        name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate"
                                                        class="form-control" placeholder="yyyy-mm-dd" name="toDates"
                                                        ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" (click)="getDestCommissionList()"
                            id="{{pageId}}saveBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">

                        <div class="box-body">
                            <h6 class="card-title border_bottom">Destination Agent Commission Details</h6>
                            <table datatable class="table table-striped table-bordered row-border hover"
                                id="{{pageId}}destAgentCommissionDetailsReportId"
                                [dtOptions]="dtOptionsDestAgentCommissionDetailsReport"
                                [dtTrigger]="dtTriggerDestAgentCommissionDetailsReport">

                                <thead>
                                    <tr>
                                        <th>Agent</th>
                                        <th>From Period</th>
                                        <th>To Period</th>
                                        <th>Statement No</th>
                                        <th>Actual Weight</th>
                                        <th>Per Day Weight</th>
                                        <th>Freight</th>
                                        <th>Receivable Amount</th>
                                        <th>Commission</th>
                                        <th>Commission Reduced</th>
                                        <th>Actual Commission Paid</th>
                                        <th>Days In Clearance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let destAgentCommissionDetailsData of destAgentCommissionDetailsReportDataList ">
                                        <td>{{ destAgentCommissionDetailsData.destination }}</td>
                                        <td>{{ destAgentCommissionDetailsData.fromPeriod }}</td>
                                        <td>{{ destAgentCommissionDetailsData.toPeriod }}</td>
                                        <td>{{ destAgentCommissionDetailsData.stmtId }}</td>
                                        <td>{{ destAgentCommissionDetailsData.totalActWgt }}</td>
                                        <td>{{ destAgentCommissionDetailsData.perDayWgt }}</td>
                                        <td>{{ destAgentCommissionDetailsData.totalFreight }}</td>
                                        <td>{{ destAgentCommissionDetailsData.receivableAmt }}</td>
                                        <td>{{ destAgentCommissionDetailsData.totalCommission }}</td>
                                        <td>{{ destAgentCommissionDetailsData.commReduced }}</td>
                                        <td>{{ destAgentCommissionDetailsData.actCommsPaid }}</td>
                                        <td>{{ destAgentCommissionDetailsData.daysLate }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>