import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';

@Component({
    selector: 'app-src-lr-ratecheck-rpt',
    templateUrl: './src-lr-ratecheck-rpt.component.html',
    styleUrls: ['./src-lr-ratecheck-rpt.component.css']
})
export class SrcLrRatecheckRptComponent implements OnInit {
    lrReportDataList: any;
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;

    showSpinnerForAction = false;
    isLoggedIn = true;
    fromDateModel: any;
    toDateModel: any;
    userDataDtoReturnSession: any;
    consignorIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedDestination: any;
    selectedIndex: any;
    selectedConsignorGSTNumber: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedConsignorName: any;
    selectedSource: any;
    selectedConsignorId: any;

    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    public modelConsignorNameId: any;

    //For Source
    sourceOptions: AgentDetailsDto[];
    lrDtoSource: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;

    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    sourceDetailsOptionsGet: AgentDetailsDto[];
    searchLrRptDto = new LRDto();
    dtTriggerLrReport: Subject<any> = new Subject();
    dtOptionsLrReport: any;

    artSummary: number;
    actWtSummary: number;
    chgWtSummary: number;
    baseFreightSummary: number;
    hamaliSummary: number;
    aocSummary: number;
    lcChgSummary: number;
    bcChgSummary: number;
    insuranceSummary: number;
    gcChgSummary: number;
    othersSummary: number;
    ddAmtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    pageId = "srclrratechkrpt";

    constructor(private router: Router,
        private masterReadService: MasterReadService,
        private lrReportService: LrReportService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.setConsginorIndex();
            this.getSourceDetails();
            this.getDestinationDetails();
        }

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsLrReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Source LR Entry Rate Check Report ";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var articles = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //Act LR Values
                var actBaseFrgt = api.column(28).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actHamali = api.column(29).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actGcChg = api.column(30).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actAoc = api.column(31).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actOthers = api.column(32).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actFov = api.column(33).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actLc = api.column(34).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actBc = api.column(35).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actDdAmt = api.column(36).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //
                //As per Master Values
                var mastBaseFrgt = api.column(43).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var mastHamali = api.column(44).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var mastGcChg = api.column(45).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var mastAoc = api.column(46).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var mastOthers = api.column(47).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //
                //Act And Master diff
                var diffBaseFrgt = api.column(48).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffHamali = api.column(49).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffGcChg = api.column(50).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffAoc = api.column(51).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffOthers = api.column(52).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffFov = api.column(53).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffLc = api.column(54).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffBc = api.column(55).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffDdAmt = api.column(56).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //


                $(api.column(0).footer()).html('Total : ' + data.length);

                $(api.column(7).footer()).html(articles);
                $(api.column(8).footer()).html(actWgt);
                $(api.column(9).footer()).html(chgWgt);
                $(api.column(13).footer()).html(toPay);
                $(api.column(14).footer()).html(paid);
                $(api.column(28).footer()).html(actBaseFrgt);
                $(api.column(29).footer()).html(actHamali);
                $(api.column(30).footer()).html(actGcChg);
                $(api.column(31).footer()).html(actAoc);
                $(api.column(32).footer()).html(actOthers);
                $(api.column(33).footer()).html(actFov);
                $(api.column(34).footer()).html(actLc);
                $(api.column(35).footer()).html(actBc);
                $(api.column(36).footer()).html(actDdAmt);
                $(api.column(43).footer()).html(mastBaseFrgt);
                $(api.column(44).footer()).html(mastHamali);
                $(api.column(45).footer()).html(mastGcChg);
                $(api.column(46).footer()).html(mastAoc);
                $(api.column(47).footer()).html(mastOthers);
                $(api.column(48).footer()).html(diffBaseFrgt);
                $(api.column(49).footer()).html(diffHamali);
                $(api.column(50).footer()).html(diffGcChg);
                $(api.column(51).footer()).html(diffAoc);
                $(api.column(52).footer()).html(diffOthers);
                $(api.column(53).footer()).html(diffFov);
                $(api.column(54).footer()).html(diffLc);
                $(api.column(55).footer()).html(diffBc);
                $(api.column(56).footer()).html(diffDdAmt);
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerLrReport.next();
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];

                        this.lrDtoDestination = new LRDto();
                        this.lrDtoDestination.destination = "All";
                        this.destinationTA.push(this.lrDtoDestination);

                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();
    }

    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "Index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    window.setTimeout(function () {
                        $("#" + this.pageId + "consignorName").focus();
                    }, 100);
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    //For Getting Consignor Details
    getConsignorGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
            //            console.log( this.selectedConsignorGSTNumber );
            if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {
                this.getConsignorDetailsForGst();
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignor GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#" + this.pageId + "consignorGSTNumber").focus();
                })
                this.consignorNameOptions = [];
                this.consignorNameTA = [];
            }

        }
    }

    getConsignorDetailsForGst() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();

        this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        //this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.isGST = true;
        this.partyMasterDtoForconsignor.mode = "LREntry";
        this.partyMasterDtoForconsignor.gstNoConsignor = this.selectedConsignorGSTNumber;
        this.getConsignorDetailsForGSTList();
    }
    getConsignorDetailsForGSTList = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.selectedConsignorId = '';
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    $("#" + this.pageId + "consignorName").val(this.consignorNameOptions[0].consignorName);
                    this.selectedConsignorId = this.consignorNameOptions[0].consignorId;
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
    }
    /// Get Agent Names
    getSourceDetails() {
        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.sourceAgentDto.column2 = "Working";
        this.sourceAgentDto.mode = "Sub";
        this.sourceAgentDto.status = "Working";
        this.getSourceMethod();
    }
    getSourceMethod = () => {
        this.masterReadService.getSourceListDetails(this.sourceAgentDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.sourceDetailsOptionsGet = response;
                    this.sourceTA = [];
                    this.sourceAgentDtoAll.subStation = "All";
                    this.sourceTA.push(this.sourceAgentDtoAll);
                    for (let i = 0; i < this.sourceDetailsOptionsGet.length; i++) {
                        this.sourceTA.push(this.sourceDetailsOptionsGet[i]);
                    }
                }
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    searchMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.searchLrRptDto.consignorId = 0;
        if ((this.selectedFromDate != null) && (this.selectedFromDate != '') &&
            (this.selectedToDate != null) && (this.selectedToDate != '') &&
            (this.selectedSource != null) && (this.selectedSource != '') &&
            (this.selectedDestination != null) && (this.selectedDestination != '')) {
            this.searchLrRptDto = new LRDto();
            this.searchLrRptDto.source = this.selectedSource;
            this.searchLrRptDto.destination = this.selectedDestination;
            this.searchLrRptDto.fromDate = this.selectedFromDate;
            this.searchLrRptDto.toDate = this.selectedToDate;
            this.searchLrRptDto.mainstation = this.userDataDtoReturnSession.mainStation;

            if (this.selectedConsignorName != null && this.selectedConsignorName != '') {
                this.searchLrRptDto.consignorId = this.selectedConsignorId;
            }
            this.searchMethodGetDetails();
        } else {
            swal({
                title: "Mandatory Field",
                text: "Please select the mandatory fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    searchMethodGetDetails = () => {
        this.showSpinnerForAction = true;
        this.lrReportService.getSrcLREntryRateCheckReport(this.searchLrRptDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "lrRptTable").DataTable().destroy();
                this.lrReportDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrReportDataList = response;
                    console.log(this.lrReportDataList);
                }
                this.dtTriggerLrReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };

    clearMethod() {
        $("#" + this.pageId + "lrRptTable").DataTable().destroy();
        this.lrReportDataList = [];
        this.dtTriggerLrReport.next();
        this.searchLrRptDto = new LRDto();
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "consignorGSTNumber").val('');
        this.fromDateModel = null;
        this.toDateModel = null;
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedConsignorGSTNumber = '';
        this.selectedDestination = '';
        this.selectedSource = '';
        this.selectedConsignorId = '';
        this.consignorNameTA = [];
        this.modelConsignorNameId = '';
        this.showSpinnerForAction = false;
    }
}
