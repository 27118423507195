import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule,NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//for service starts
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
//for service ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datatable starts
import { Subject, Subscription,merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";

@Component( {
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.css']
} )
export class TimeComponent implements OnInit {

    hireSlipDto: HireSlipDto = new HireSlipDto();
    hireSlipDtoSaveRet: any;
    hireSlipDtoDelete: HireSlipDto = new HireSlipDto();
    hireSlipDtoDeleteRet: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView: any;
    timeDataList: any;
    splitTimeDetails: any;
    commaSplitTime: any;
    undersCodeSplitTime: any;
    private newAttribute: any = {};
    editTableId: null;
    fromTimeCheck: number;
    toTimeCheck: number;
    pageId="timct";
    //    timeSettingEntryTableDataList: any;
    timeSettingEntryTableDataList: Array<any> = [];
    //for the select option with filter starts

    controlSource = new FormControl();
    controlDestination = new FormControl();


    sourceOptions = [
        { id: 1, label: 'Delhi', },
        { id: 2, label: 'Mumbai', },
        { id: 3, label: 'Budhpur', },
    ];

    destinationOptions = [
        { id: 1, label: 'Chennai' },
        { id: 2, label: 'Bangalore' },
        { id: 3, label: 'Kerala' },
    ];
    //for the select option with filter ends

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTimeSettingEntryTable: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsTimeSettingEntryTable: any;
    
    showSpinnerForAction = false;
    selectedFromStation : any;
    selectedToStation : any;
    
    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                :  this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;
    
    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                :  this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;


    constructor( private masterReadService: MasterReadService, private router: Router, private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.getBranchList();
        }
    }

    ngOnInit() {
    	this.dtOptionsTimeSettingEntryTable = {
                language: {
         search: "_INPUT_",
         searchPlaceholder: "Search..."
     },
     searching: false,
     processing: true,
     responsive: true,
     "scrollX": true,
     "scrollY": 220,
     "scrollCollapse": true,
     "paging": false,
     "info": false,
     pagingType: 'full_numbers',
     pageLength: 9
 		}
    }

    getUserDetailsForReadTime() {
    	this.selectedFromStation = $("#"+this.pageId+"source" ).val();
        this.selectedToStation = $("#"+this.pageId+"destination" ).val();
        
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.fromstation = this.selectedFromStation;
        this.hireSlipDto.tostation = this.selectedToStation;
    }


    getTimeList = () => {
    	this.showSpinnerForAction = true;
        this.getUserDetailsForReadTime();
        this.masterReadService.getTimeService( this.hireSlipDto ).subscribe(
            ( response ) => {
            	$("#"+this.pageId+"timeTableId").DataTable().destroy();
            	this.timeDataList = [];
            	this.hireSlipDto = new HireSlipDto();
            	this.timeSettingEntryTableDataList = [];
            	
            	if (response.size == 0) {
					swal({
						title: "No record",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.timeDataList = response;
                    this.hireSlipDto = this.timeDataList;
                    this.hireSlipDto.buffTimeInMin = this.timeDataList.buffTimeInMin.toFixed();
                    this.timeSettingEntryTableDataList = this.hireSlipDto.listTimeSettings;
				}
            	setTimeout(() => {
					this.dtTriggerTimeSettingEntryTable.next();
	            }, 500);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the details", "info");
			}, () => console.log('done');
    }

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
    serviceCall() {
        //        console.log( this.hireSlipDto.tostation );
        this.getTimeList();
    }

    ngOnDestroy(): void {
        this.dtTriggerTimeSettingEntryTable.unsubscribe();
    }
    
    ngAfterViewInit(): void {
        this.dtTriggerTimeSettingEntryTable.next();
    }

    addInTable() {
        this.newAttribute.fromTime = $("#"+this.pageId+"fromTime" ).val();
        this.newAttribute.toTime = $("#"+this.pageId+"toTime" ).val();
        this.newAttribute.rewardAmt = $("#"+this.pageId+"rewardingAmt" ).val();

        if ( parseInt( this.newAttribute.toTime ) > parseInt( this.newAttribute.fromTime ) && ( this.newAttribute.rewardAmt != '' ) ) {


            if ( this.editTableId != null ) {
                this.timeSettingEntryTableDataList.splice( this.editTableId, 1 );
            }
            if ( this.timeSettingEntryTableDataList == null ) {
                this.timeSettingEntryTableDataList = [];
            }

            if ( this.timeSettingEntryTableDataList.length == 0 ) {
                this.timeSettingEntryTableDataList.push( this.newAttribute );
            } else {

                let array = this.timeSettingEntryTableDataList;
                this.timeSettingEntryTableDataList.push( this.newAttribute );
                //                                for ( let index in array ) {
                //                                  console.log( 'this is the Index ' + index );
                //                                   if( index.length ==0){
                //                                   }
                //                                    this.timeSettingEntryTableDataList.push( this.newAttribute );
                //                                }

            }
            //            if ( this.editTableId == null ) {
            //                this.timeSettingEntryTableDataList.push( this.newAttribute );
            //            } else {
            //                let array = this.timeSettingEntryTableDataList;
            //                for ( let index in array ) {
            //                    console.log( 'this is the Index ' + index );
            //                    this.timeSettingEntryTableDataList.push( this.newAttribute );
            //                }
            //
            //            }
            //        if(this.newAttribute.toTime>=)

            this.newAttribute = {};
            $( "#"+this.pageId+"fromTime" ).val( '' );
            $( "#"+this.pageId+"toTime" ).val( '' );
            $( "#"+this.pageId+"rewardingAmt" ).val( '' );
            $( "#"+this.pageId+"hiddenIndex" ).val( '' );

            this.editTableId = null;
        } else {
            swal( {
                title: "Warning",
                text: "To  Time  must been greater then From Time",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } );

        }


    }
    rowSelectedEdit( timeSettingEntryTableData, index ) {
        $( "#"+this.pageId+"fromTime" ).val( timeSettingEntryTableData.fromTime );
        $( "#"+this.pageId+"toTime" ).val( timeSettingEntryTableData.toTime );
        $( "#"+this.pageId+"rewardingAmt" ).val( timeSettingEntryTableData.rewardAmt );
        $( "#"+this.pageId+"hiddenIndex" ).val( index );
        this.editTableId = index;
        //        console.log( timeSettingEntryTableData.fromTime, index );
    }
    rowSelectedDelete( index ) {
        this.timeSettingEntryTableDataList.splice( index, 1 );
        $( "#"+this.pageId+"fromTime" ).val( '' );
        $( "#"+this.pageId+"toTime" ).val( '' );
        $( "#"+this.pageId+"rewardingAmt" ).val( '' );
        $( "#"+this.pageId+"hiddenIndex" ).val( '' );
    }

    validateTimeDetails() {
    	swal({
            title: "Confirmation",
            text: "Sure you want to save/update the reward details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveUpdateTimeDetails();
            }
        })
    }
    saveTimeDetails() {
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDto.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDto.listTimeSettings = this.timeSettingEntryTableDataList;
        
        this.selectedFromStation = $("#"+this.pageId+"source" ).val();
        this.selectedToStation = $("#"+this.pageId+"destination" ).val();
        this.hireSlipDto.fromstation = this.selectedFromStation;
        this.hireSlipDto.tostation = this.selectedToStation;
        //        console.log( this.hireSlipDto.listTimeSettings );
    }
    saveUpdateTimeDetails = () => {
        this.saveTimeDetails();
        this.masterService.createTimeSettings( this.hireSlipDto ).
            subscribe(( data ) => {
                this.hireSlipDtoSaveRet = data;
                if ( this.hireSlipDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Time Settings Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Time Settings Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Time Settings Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        $( "#"+this.pageId+"source" ).val( '' );
        $( "#"+this.pageId+"destination" ).val( '' );
        this.hireSlipDto.fromstation = null;
        this.hireSlipDto.tostation = null;
        $("#"+this.pageId+"timeTableId").DataTable().destroy();
    	this.timeDataList = [];
    	this.hireSlipDto = new HireSlipDto();
    	this.timeSettingEntryTableDataList = [];
    	this.dtTriggerTimeSettingEntryTable.next();
    }

    validateDeleteRow() {
        	swal({
                title: "Confirmation",
                text: "Sure you want to delete the reward details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                	this.deleteTimeDetails();
                }
            })
    }

    getDeleteTimeDetails() {
        this.hireSlipDtoDelete.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoDelete.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoDelete.fromstation = this.hireSlipDto.fromstation;
        this.hireSlipDtoDelete.tostation = this.hireSlipDto.tostation;
    }

    deleteTimeDetails = () => {
        this.getDeleteTimeDetails();
        this.masterService.deleteTime( this.hireSlipDtoDelete ).
            subscribe(( data ) => {
                this.hireSlipDtoDeleteRet = data;
                if ( this.hireSlipDtoDeleteRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Time Details Removed Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                    //                    this.getTimeList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Deleting Time Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Deleting Time Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    }
    
    getBranchList() {
      	 this.lrDtoSearch = new LRDto();
         	 this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch ).subscribe(
                ( response ) => {
                    if (response) {
                        if (response.length > 0) {
                        	 this.lrDtoFromOptions = response;
                            this.fromTA = [];
                            
                            this.lrDtoToOptions = response;
                            this.toTA = [];
                            
                            for ( let i = 0; i < this.lrDtoFromOptions.length; i++ ) {
                                this.fromTA.push( this.lrDtoFromOptions[i]);
                                this.toTA.push( this.lrDtoFromOptions[i]);
                            }
                        }
                    }
                    this.changeDetectorRef.detectChanges();
                  }),(error) => {
              			this.showSpinnerForAction = false;
              		   swal("Error","Server Problem Occurred While getting the the branch details","info");
              		},
              		   () => console.log('done');
        }
}
