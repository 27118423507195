<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}viewShowStmtRptMainId">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Statement Report</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>View Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-question"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
														<option selected value="stmtNo">Stmt No</option>
														<option value="datewise">Datewise</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" [hidden]="!hideshowStmtNoField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Statement No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}statementNo" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" [hidden]="!hideshowDestinationDropDown">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" [hidden]="!hideshowFromToDateField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates" [(ngModel)]="fromDatesModal" autocomplete="off">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" [hidden]="!hideshowFromToDateField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="box-body">
                        <table datatable id="{{pageId}}statementRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReport" [dtTrigger]="dtTriggerStatementReport">

                            <thead>
                                <tr>
                                    <th>Statement No</th>
                                    <th>Statement Date</th>
                                    <th>From Period</th>
                                    <th>To Period</th>
                                    <th>Total LRs</th>
                                    <th>Total Invoice</th>
                                    <th>Total Weight</th>
                                    <th>To Pay</th>
                                    <th>Paid</th>
                                    <th>Receivable Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let statementReportData of statementReportDataList ">
                                    <!--<td>{{ statementReportData.statementNo }}</td>-->
                                    <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="statementReportPopUpBtnLink(statementReportPopUpTemplate,statementReportData)">
                                        {{ statementReportData.stmtId }}</td>
                                    <td>{{ statementReportData.stmtDate }}</td>
                                    <td>{{ statementReportData.fromPeriod }}</td>
                                    <td>{{ statementReportData.toPeriod }}</td>
                                    <td>{{ statementReportData.totalLrs }}</td>
                                    <td>{{ statementReportData.totalInvoice }}</td>
                                    <td>{{ statementReportData.totalActWgt }}</td>
                                    <td>{{ statementReportData.totalAmount }}</td>
                                    <td>{{ statementReportData.totalPaid }}</td>
                                    <td>{{ statementReportData.receivableAmt }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #statementReportPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewStatementReportPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupStatementRpt">
                                            <app-statement-rpt-lr-details>
                                            </app-statement-rpt-lr-details>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4"></div>
                                    <div class="col-sm-12 col-md-4">
                                        <!---<button class="btn btn-primary" type="button" 
											(click)="pdfMethod('printForShowStmtRptId')">PDF</button>
											-->
                                        <button style='float: right; height: 100%;' class="button dt-button" id="{{pageId}}printStmtRptLrBtnId" (click)="clickListnerForPrintStmtRptLrDetails()">
										<i class="fas fa-print"> &nbsp;Print LR Details</i>
									</button>
                                        <button style='float: right; height: 100%;' class="button dt-button" id="{{pageId}}printStmtRptLrBtnId" (click)="clickListnerForPrintStmtRptLr()">
										<i class="fas fa-print"> &nbsp;Print</i>
									</button>
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeStatementReportPopUp()">Close</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div *ngIf="viewStatementRptLRDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printForShowStmtRptId" #printForShowStmtRptId>
    <app-statement-rpt-lr-details-print></app-statement-rpt-lr-details-print>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>