import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { DatePipe } from "@angular/common";

@Component({
	selector: 'app-memo-less-search-report',
	templateUrl: './memo-less-search-report.component.html',
	styleUrls: ['./memo-less-search-report.component.css']
})
export class MemoLessSearchReportComponent implements OnInit {


	gettingDataFrmServiceFrMemoLessReportTable: any;
	gettingDataFrmServiceFrMemoLessSrcWiseTable: any;

	memoLessReportDataList: any;
	memoLessSrcWiseDataList: any;
	memoLessDescDataList: any;

	onDestroyUnsubscribtionMemoLessReport: Subscription;
	onDestroyUnsubscribtionMemoLessSrcWise: Subscription;
	onDestroyUnsubscribtionMemoLessDescription: Subscription;


	//PartyLessRptTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends
	//for the select option with filter starts

	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

	control = new FormControl();

	partyOptions = [
		{ id: 1, label: 'All' },
		{ id: 2, label: 'K.G. Associates' },
		{ id: 3, label: 'KK Enterprises' },
		{ id: 4, label: 'KC Enterprises' }
	];



	//for the select option with filter ends

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerMemoLessReport: Subject<any> = new Subject();
	dtTriggerMemoLessSrcWise: Subject<any> = new Subject();
	dtTriggerMemoLessDescriptionDetails: Subject<any> = new Subject();



	dataTable: any;
	dtOptionsMemoLessReport: any;
	dtOptionMemoLessSrcWise: any;
	dtOptionsMemoLessDescriptionDetails: any;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();

	cashMemoDto: CashMemoDto = new CashMemoDto();

	validateFromDate: any = null;
	validateToDate: any = null;
	validateConsigneeId: any = null;
	validateConsigneeName: any = null;
	address: any;

	modalRefferenceMemoLessDescriptionPopUp: NgbModalRef;
	modalRefferenceMemoLessDescriptionClosePopUp: string;
	@ViewChild('memoLessDescrPopUpTemplate') private memoLessDescrPopUpTemplate;


	cashMemoDtoMemoLessDescrp: CashMemoDto = new CashMemoDto();

	showSpinnerForAction = false;
	fromDatesModal: any = null;
	toDatesModal: any = null;
	viewMemoLessDescriptionPopUp = false;

	//for custom print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	viewCustomPrintV1 = false;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	//Custom Print Footer
	summary1: number;
	summary2: number;
	summary3: number;
	summary4: number;
	summary5: number;
	summary6: number;
	//
	pageId = "mlsrc";
	constructor(private memoLessRpt: ReportService, private router: Router, private datePipe: DatePipe,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.getConsigneeDetails();
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			//console.log(this.address);
		}

	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsMemoLessReport = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "Memo Less Report - " +
							"From Date : " + moment($("#mlsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
							"To Date : " + moment($("#mlsrctoDates").val()).format('DD-MM-YYYY') + "";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//"iDisplayLength" : 5,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": true,
			//"sPaginationType" : "simple_numbers",
			//"sPaginationType" : "full_numbers",
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var memoAmt = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var discAmt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var memoLessAmt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var article = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actualWgt = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargeWgt = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var topay = api.column(14).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html('Total : ' + data.length);
				//$(api.column(1).footer()).html(data.length);
				$(api.column(4).footer()).html(memoAmt);
				$(api.column(5).footer()).html(discAmt);
				$(api.column(6).footer()).html(memoLessAmt);
				$(api.column(8).footer()).html(article);
				$(api.column(9).footer()).html(actualWgt);
				$(api.column(10).footer()).html(chargeWgt);
				$(api.column(14).footer()).html(topay);
			},
		},
			this.dtOptionMemoLessSrcWise = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				searching: false,
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 150,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					// computing column Total of the complete result 
					var discAmt = api.column(1).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					// Update footer by showing the total with the reference of the column index 
					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html(discAmt);
				},
			},
			this.dtOptionsMemoLessDescriptionDetails = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				searching: false,
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 200,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					// computing column Total of the complete result 
					var discAmt = api.column(1).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					// Update footer by showing the total with the reference of the column index 
					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html(discAmt);
				},
			}
	}


	ngOnDestroy(): void {
		this.dtTriggerMemoLessReport.unsubscribe();
		this.dtTriggerMemoLessSrcWise.unsubscribe();
		this.dtTriggerMemoLessDescriptionDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerMemoLessReport.next();
		this.dtTriggerMemoLessSrcWise.next();
		this.dtTriggerMemoLessDescriptionDetails.next();
	}

	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker

	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}

	getConsigneeDetailsList() {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
	}
	getConsigneeDetails = () => {
		this.getConsigneeDetailsList();
		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	clearAllFields() {
		this.fromDatesModal = null;
		this.toDatesModal = null;
		$("#" + this.pageId + "fromDates").val('');
		$("#" + this.pageId + "toDates").val('');
		$("#" + this.pageId + "consigneeName").val('');
		this.validateFromDate = null;
		this.validateToDate = null;
		this.validateConsigneeName = null;
		this.validateConsigneeId = null;
		this.memoLessReportDataList = [];
		$("#" + this.pageId + "memoLessDetailsTableId").DataTable().destroy();
		this.dtTriggerMemoLessReport.next();
		this.memoLessSrcWiseDataList = [];
		$("#" + this.pageId + "memoLessSourceWiseDatatableId").DataTable().destroy();
		this.dtTriggerMemoLessSrcWise.next();
		this.memoLessDescDataList = [];
		$("#" + this.pageId + "memoLessDescriptionTableId").DataTable().destroy();
		this.dtTriggerMemoLessDescriptionDetails.next();
	}
	clearMemoLessBtn() {
		this.clearAllFields();
	}
	searchMemoLessReptBtn() {
		if (($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined) ||
			($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
			($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
			($("#" + this.pageId + "consigneeName").val() == null) || ($("#" + this.pageId + "consigneeName").val() == undefined) ||
			($("#" + this.pageId + "consigneeName").val() == "")) {
			swal("Mandatory Field", "please enter the mandatory fields", "warning");
			return false;
		} else {
			this.getMemoLessDetailsRptList();
		}
	}

	consigneeDropDownListner(event) {
		$("#" + this.pageId + "consigneeName").val(event.item.consigneeName);
		this.validateConsigneeName = event.item.consigneeName;
		this.validateConsigneeId = event.item.consigneeId;
	}

	getMemoLessDetailsRpt() {
		this.cashMemoDto = new CashMemoDto();
		this.validateFromDate = $("#" + this.pageId + "fromDates").val();
		this.validateToDate = $("#" + this.pageId + "toDates").val();
		this.cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
		this.cashMemoDto.destination = this.userDataDtoReturnSession.mainStation;
		this.cashMemoDto.fromDate = this.validateFromDate;
		this.cashMemoDto.toDate = this.validateToDate;
		this.cashMemoDto.consigneeName = this.validateConsigneeName;
		this.cashMemoDto.consigneeId = this.validateConsigneeId;
		if (this.cashMemoDto.fromDate != null &&
			this.cashMemoDto.fromDate != undefined) {
			this.cashMemoDto.mode = "dateBetween";
		}
		if (this.cashMemoDto.consigneeName == null || this.cashMemoDto.consigneeName == undefined ||
			this.cashMemoDto.consigneeName == "") {
			this.cashMemoDto.consigneeName = "All";
			this.cashMemoDto.consigneeId = 0;
		} else {
			this.cashMemoDto.consigneeName = this.validateConsigneeName;
			this.cashMemoDto.consigneeId = this.validateConsigneeId;
		}
		this.cashMemoDto.dateInDate = new Date();
		console.log(this.cashMemoDto);
	}
	getMemoLessDetailsRptList() {
		this.getMemoLessDetailsRpt();
		this.showSpinnerForAction = true;
		this.memoReport.getMemoLessDetailsReport(this.cashMemoDto).subscribe(
			(response) => {
				if (response.length > 0) {
					this.memoLessReportDataList = response;
					$("#" + this.pageId + "memoLessDetailsTableId").DataTable().destroy();
					this.dtTriggerMemoLessReport.next();
					this.showSpinnerForAction = false;
					this.getMemoLessForSourceWiseDetails();
				} else {
					this.showSpinnerForAction = false;
					swal("No Records", "No Records found for this search", "info");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Memo Less Details", "info");
			},
			() => console.log('done');
	};

	getMemoLessForSourceWiseDetails() {
		this.showSpinnerForAction = true;
		this.memoReport.getMemoLessDetailsForSourceWiseReport(this.cashMemoDto).subscribe(
			(response) => {
				if (response.length > 0) {
					this.memoLessSrcWiseDataList = response;
					$("#" + this.pageId + "memoLessSourceWiseDatatableId").DataTable().destroy();
					this.dtTriggerMemoLessSrcWise.next();
					this.showSpinnerForAction = false;
				} else {
					this.showSpinnerForAction = false;
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Memo Less Source Details", "info");
			},
			() => console.log('done');
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	memoLessDescriptionPopUpBtnLink(memoLessDescrPopUpTemplate, memoLessReportData) {
		this.modalRefferenceMemoLessDescriptionPopUp = this.modalService.open(memoLessDescrPopUpTemplate,
			{ centered: true, windowClass: "modalClassForPopUpTruckOwner" });
		this.modalRefferenceMemoLessDescriptionPopUp.result.then((result) => {
			this.modalRefferenceMemoLessDescriptionClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceMemoLessDescriptionClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
		localStorage.clear();
		localStorage.setItem('popupMemoLessDescription', JSON.stringify(memoLessReportData));
		this.viewMemoLessDescriptionPopUp = true;
		window.addEventListener('afterPopUp', (event) => {
			this.viewMemoLessDescriptionPopUp = false;
		});
	}
	closeMemoLessDescriptionPopUp() {
		this.modalRefferenceMemoLessDescriptionPopUp.close();
	}
	getMemoLessDescriptionDetailsList(memoNumber) {
		this.cashMemoDtoMemoLessDescrp = new CashMemoDto();
		this.cashMemoDtoMemoLessDescrp.memoNumber = memoNumber;
		this.memoReport.getMemoLessDescriptionDetailsReport(this.cashMemoDtoMemoLessDescrp).subscribe(
			(response) => {
				if (response.length > 0) {
					this.memoLessDescDataList = response;
					/*this.dtElements.forEach(( dtElement: DataTableDirective,index:number ) => {
						dtElement.dtInstance.then(( dtInstance: any ) => {
							
							console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
							if(dtInstance.table().node().id == "memoLessDescriptionTableId"){
								dtInstance.destroy();
								this.dtTriggerMemoLessDescriptionDetails.next();
							}
							
						} );
					} );*/
					$("#" + this.pageId + "memoLessDescriptionTableId").DataTable().destroy();
					this.dtTriggerMemoLessDescriptionDetails.next();
				} else {
					swal("No Records", "No Records found for this search", "info");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				swal("Error", "Server Problem Occurred While getting the Memo Less Description Details", "info");
			},
			() => console.log('done');
	}

	customPrintMemoLess() {
		if (this.memoLessReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Memo Number", "LR No.", "Party Name", "Memo Date", "Computer Less", "Memo Amt", "Memo Less", "Less Type", "Art", "Act Wgt", "Chg Wgt", "Source", "Collection Man"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [13, 8, 15, 8, 6, 5, 5, 5, 3, 5, 5, 13, 9];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.memoLessReportDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.memoLessReportDataList[i].memoNumber, this.memoLessReportDataList[i].lrNumber, this.memoLessReportDataList[i].consigneeName, this.memoLessReportDataList[i].memoDateStr, this.memoLessReportDataList[i].discount, this.memoLessReportDataList[i].amount, this.memoLessReportDataList[i].less, this.memoLessReportDataList[i].lessType, this.memoLessReportDataList[i].article, this.memoLessReportDataList[i].actualWeight, this.memoLessReportDataList[i].chargedWeight, this.memoLessReportDataList[i].source, this.memoLessReportDataList[i].collectionMan];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.summary1 = this.summary1 + this.memoLessReportDataList[i].discount;
				this.summary2 = this.summary2 + this.memoLessReportDataList[i].amount;
				this.summary3 = this.summary3 + this.memoLessReportDataList[i].less;
				this.summary4 = this.summary4 + this.memoLessReportDataList[i].article;
				this.summary5 = this.summary5 + this.memoLessReportDataList[i].actualWeight;
				this.summary6 = this.summary6 + this.memoLessReportDataList[i].chargedWeight;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.memoLessReportDataList.length, "", "", "", this.summary1, this.summary2, this.summary3, "", this.summary4, this.summary5, this.summary6, "", ""];
			}
			//heading logics For Date
			this.fromDatePrint = this.datePipe.transform(this.cashMemoDto.fromDate, "dd-MM-yyyy");
			this.toDatePrint = this.datePipe.transform(this.cashMemoDto.toDate, "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fromDatePrint, this.toDatePrint];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Memo Less Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

}

