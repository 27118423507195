import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//for datepicker starts

const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for service starts
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { ReportService } from 'src/app/dataService/report-service';
import { HttpClient } from '@angular/common/http';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
//for service ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { MasterReadService } from "src/app/dataService/masterread-service";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";

@Component({
    selector: 'app-expenses-details-entry',
    templateUrl: './expenses-details-entry.component.html',
    styleUrls: ['./expenses-details-entry.component.css']
})
export class ExpensesDetailsEntryComponent implements OnInit {

    gettingDataFrmServiceFrExpensesDetailsEntryTable: any;


    expensesDetailsEntryDataList: any;


    onDestroyUnsubscribtionExpensesDetailsEntry: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    showSpinnerForAction = false;
    //for the select option with filter starts

    control = new FormControl();




    userDataDtoReturnSession: any;
    isLoggedIn = true;

    //for the select option with filter ends



    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerExpensesDetailsEntry: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsExpensesDetailsEntry: any;
    //// Vendor
    lrDtoVendor: LRDto = new LRDto();
    lrDtoVendorAddNew: LRDto = new LRDto();
    lrDtosVendorOptionsGet: LRDto[];
    vendorOptions: LRDto[];
    public modelVendor: any;
    vendorNameTA: Array<LRDto> = [];
    focusVendorTA$ = new Subject<string>();
    searchVendor = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVendorTA$;


        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vendorNameTA
                : this.vendorNameTA.filter(v => v.vendorNameAndItemName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVendor = (x: { vendorNameAndItemName: string }) => x.vendorNameAndItemName;
    // Item details

    lrDtoItem: LRDto = new LRDto();
    lrDtoItemAddNew: LRDto = new LRDto();
    lrDtosItemOptionsGet: LRDto[];
    itemOptions: LRDto[];
    public modelItem: any;
    itemNameTA: Array<LRDto> = [];
    focusItemTA$ = new Subject<string>();
    searchItem = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusItemTA$;


        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.itemNameTA
                : this.itemNameTA.filter(v => v.vendorNameAndItemName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterItem = (x: { vendorNameAndItemName: string }) => x.vendorNameAndItemName;
    showNewVendorName = false;
    showNewItemName = false;
    public modelVendorName: any;
    public modelItemName: any;
    selectedVendorName: any;
    selectedItemName: any;
    enteredBillNumber: any;
    selectedBllDate: any;
    enteredNewVendorName: any;
    enteredNewItemName: any;
    enteredQuantity: any;
    selectedUnit: any;
    enteredRate; any;
    enteredAmt: any;
    private newAttributeToSetParticulars: any = {};
    editParticularsTableDetailsId: any;
    enteredGrandTotal: any;
    finalVendorName: any;
    finalItemName: any;
    modelBillDates: any;
    itemNameToShow: any;
    countOfParticulars = 0;
    sumOfParticulars = 0;
    editAmountVal: any;
    setNewName: any;
    particularDetailsLRDto: Array<LRDto> = [];
    lrDtoSaveParticular: LRDto = new LRDto();
    lrDtoValidateParticular: LRDto = new LRDto();
    billAutoId = 0;
    pageId="expde";




    constructor(private reportService: ReportService, private masterService: MasterService, private lrReportService: LrReportService, private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.gettingVendorList();
            this.gettingItemList();
        }

    }

    //for service ends
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }



    ngOnInit(): void {

        this.dtOptionsExpensesDetailsEntry = {
            dom: 'Bfrtip',
            buttons: [],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

            }
        }


    }


    ngOnDestroy(): void {
        this.dtTriggerExpensesDetailsEntry.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerExpensesDetailsEntry.next();
    }


    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    gettingVendorDetails() {
        this.lrDtoVendor = new LRDto();
        this.lrDtoVendor.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoVendor.branch = this.userDataDtoReturnSession.mainStation;
        console.log(this.lrDtoVendor);
    }
    gettingVendorList = () => {
        this.showSpinnerForAction = true;
        this.gettingVendorDetails();
        this.masterService.getExpensesVendorDetails(this.lrDtoVendor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Vendor records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.vendorNameTA = [];
                    this.lrDtosVendorOptionsGet = [];
                    this.lrDtosVendorOptionsGet = response;
                    this.lrDtoVendorAddNew.vendorNameAndItemName = "Add New";
                    this.vendorNameTA.push(this.lrDtoVendorAddNew);
                    for (let i = 0; i < this.lrDtosVendorOptionsGet.length; i++) {
                        this.vendorNameTA.push(this.lrDtosVendorOptionsGet[i]);

                    }
                    console.log(this.vendorNameTA);

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Vendor Details", "warning");
            },
            () => console.log('done');
    };

    //for the select option with filter ends
    gettingItemDetails() {
        this.lrDtoItem = new LRDto();
        this.lrDtoItem.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoItem.branch = this.userDataDtoReturnSession.mainStation;
        console.log(this.lrDtoItem);
    }
    gettingItemList = () => {
        this.showSpinnerForAction = true;
        this.gettingItemDetails();
        this.masterService.getExpensesItemDetails(this.lrDtoItem).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Item records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.itemNameTA = [];
                    this.lrDtosItemOptionsGet = [];
                    this.lrDtosItemOptionsGet = response;
                    this.lrDtoItemAddNew.vendorNameAndItemName = "Add New";
                    this.itemNameTA.push(this.lrDtoItemAddNew);
                    for (let i = 0; i < this.lrDtosItemOptionsGet.length; i++) {
                        this.itemNameTA.push(this.lrDtosItemOptionsGet[i]);

                    }
                    console.log(this.itemNameTA);

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Item Details", "warning");
            },
            () => console.log('done');
    };

    rowSelectedVendorName(e: NgbTypeaheadSelectItemEvent) {
        this.modelVendorName = e.item;
        this.selectedVendorName = this.modelVendorName.vendorNameAndItemName;
        if (this.selectedVendorName == "Add New") {
            this.showNewVendorName = true;
            //  $("#"+this.pageId+"newVendorName").focus();
        } else {
            this.showNewVendorName = false;
            // $("#"+this.pageId+"vendorName").focus();
        }
    }

    rowSelectedItemName(e: NgbTypeaheadSelectItemEvent) {
        this.modelItemName = e.item;
        this.selectedItemName = this.modelItemName.vendorNameAndItemName;
        if (this.selectedItemName == "Add New") {
            this.showNewItemName = true;
            // $("#"+this.pageId+"newItemName").focus();
        } else {
            this.showNewItemName = false;
            //   $("#"+this.pageId+"quantity").focus();
        }
    }

    validateAddInTable() {
        this.calculateAmountIn();
        this.enteredBillNumber = $("#"+this.pageId+"billNumber").val();
        this.selectedBllDate = $("#"+this.pageId+"billDate").val();
        this.selectedVendorName = $("#"+this.pageId+"vendorName").val();
        this.enteredNewVendorName = $("#"+this.pageId+"newVendorName").val();
        this.finalVendorName = '';
        this.finalItemName = '';
        if (this.selectedVendorName == "Add New") {
            this.finalVendorName = $("#"+this.pageId+"newVendorName").val();
        } else {
            this.finalVendorName = $("#"+this.pageId+"vendorName").val();
        }
        this.selectedItemName = $("#"+this.pageId+"itemName").val();
        this.enteredNewItemName = $("#"+this.pageId+"newItemName").val();
        this.itemNameToShow = 'Old';
        if (this.selectedItemName == "Add New") {
            this.finalItemName = $("#"+this.pageId+"newItemName").val();
            this.itemNameToShow = 'New';
        } else {
            this.finalItemName = $("#"+this.pageId+"itemName").val();
            this.itemNameToShow = 'Old';
        }

        this.enteredQuantity = $("#"+this.pageId+"quantity").val();
        this.selectedUnit = $("#"+this.pageId+"unit").val();
        this.enteredRate = $("#"+this.pageId+"rate").val();
        this.enteredAmt = $("#"+this.pageId+"amount").val();


        this.enteredGrandTotal = '';

        if (this.finalVendorName == null || this.finalVendorName == '' || this.finalItemName == null || this.finalItemName == '') {
            swal({
                title: "Warning",
                text: "Please Select the Vendor name and Item Name",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });


        } else {
            this.newAttributeToSetParticulars.particulars = this.finalItemName;
            this.newAttributeToSetParticulars.quantity = (this.enteredQuantity == null ? 0 : this.enteredQuantity == '' ? 0 : this.enteredQuantity);
            this.newAttributeToSetParticulars.unit = this.selectedUnit;
            this.newAttributeToSetParticulars.rate = (this.enteredRate == null ? 0 : this.enteredRate == '' ? 0 : this.enteredRate);
            this.newAttributeToSetParticulars.amount = (this.enteredAmt == null ? 0 : this.enteredAmt == '' ? 0 : this.enteredAmt);
            this.newAttributeToSetParticulars.itemNameToShow = this.itemNameToShow;


            if (this.editParticularsTableDetailsId != null &&
                this.editParticularsTableDetailsId != undefined) {

                console.log(this.editParticularsTableDetailsId);

            }

            if (this.expensesDetailsEntryDataList == null ||
                this.expensesDetailsEntryDataList == undefined ||
                this.expensesDetailsEntryDataList.length == 0) {
                this.expensesDetailsEntryDataList = [];
            }

            if (this.expensesDetailsEntryDataList.length == 0) {
                this.expensesDetailsEntryDataList = [];
                $("#"+this.pageId+"expensesDetailsEntryId").DataTable().destroy();
                this.expensesDetailsEntryDataList.push(this.newAttributeToSetParticulars);
                this.dtTriggerExpensesDetailsEntry.next();
                this.countOfParticulars = +this.countOfParticulars + 1;
                this.sumOfParticulars = +this.sumOfParticulars + +this.newAttributeToSetParticulars.amount;
                this.clearFields();
                this.calculateGrandTotal();
                console.log('checking for length zero');
                //  $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());


            } else {
                const result = this.expensesDetailsEntryDataList.filter(expensesDetailsEntryData =>
                    expensesDetailsEntryData.particulars === this.newAttributeToSetParticulars.particulars);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Item Name  Already Available ,Please Edit the Item Name and then Add", "warning");
                } else {
                    $("#"+this.pageId+"expensesDetailsEntryId").DataTable().destroy();
                    this.expensesDetailsEntryDataList.push(this.newAttributeToSetParticulars);
                    this.dtTriggerExpensesDetailsEntry.next();
                    this.countOfParticulars = +this.countOfParticulars + 1;
                    this.sumOfParticulars = +this.sumOfParticulars + +this.newAttributeToSetParticulars.amount;
                    this.clearFields();
                    this.calculateGrandTotal();
                    console.log('checking for length ');
                    //  $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                }
            }

        }


    }

    clearFields() {
        this.enteredQuantity = '';
        $("#"+this.pageId+"quantity").val('');
        this.selectedUnit = '';
        $("#"+this.pageId+"unit").val('Liters');
        this.enteredRate = '';
        $("#"+this.pageId+"rate").val('');
        this.enteredAmt = '';
        $("#"+this.pageId+"amount").val('');
        this.finalItemName = '';
        this.selectedItemName = '';
        $("#"+this.pageId+"newItemName").val('');
        $("#"+this.pageId+"itemName").val('');
        this.itemNameToShow = 'Old';
        this.editAmountVal = 0;

        this.showNewItemName = false;
        this.editParticularsTableDetailsId = null;
        this.newAttributeToSetParticulars = {};
        //this.modelBillDates = null;
        this.setNewName = '';
    }

    rowSelectedParticularDataOnEdit(expensesDetailsEntryData, index) {
        this.editParticularsTableDetailsId = index;
        this.editAmountVal = expensesDetailsEntryData.amount;
        console.log(this.editParticularsTableDetailsId);
        this.showSpinnerForAction = true;
        $("#"+this.pageId+"expensesDetailsEntryId").DataTable().destroy();
        this.expensesDetailsEntryDataList.splice(index, 1);
        this.dtTriggerExpensesDetailsEntry.next();
        this.countOfParticulars = +this.countOfParticulars - 1;
        this.sumOfParticulars = +this.sumOfParticulars - +expensesDetailsEntryData.amount;
        setTimeout(() => {
            $("#"+this.pageId+"quantity").val(expensesDetailsEntryData.quantity);
            $("#"+this.pageId+"unit").val(expensesDetailsEntryData.unit);
            $("#"+this.pageId+"amount").val(expensesDetailsEntryData.amount);
            $("#"+this.pageId+"rate").val(expensesDetailsEntryData.rate);
            this.itemNameToShow = expensesDetailsEntryData.itemNameToShow;
            if (this.itemNameToShow == "Old") {
                $("#"+this.pageId+"itemName").val(expensesDetailsEntryData.particulars);
                $("#"+this.pageId+"newItemName").val('');
                this.showNewItemName = false;
            } else {
                this.showNewItemName = true;
                $("#"+this.pageId+"itemName").val('');
                $("#"+this.pageId+"newItemName").val(expensesDetailsEntryData.particulars);
                this.setNewName = expensesDetailsEntryData.particulars;
            }
            this.calculateGrandTotal();
            this.showSpinnerForAction = false;
        }, 1000);

    }

    deleteSelectedParticularDetails(expensesDetailsEntryData, index) {
        $("#"+this.pageId+"expensesDetailsEntryId").DataTable().destroy();
        this.expensesDetailsEntryDataList.splice(index, 1);
        this.dtTriggerExpensesDetailsEntry.next();
        this.countOfParticulars = +this.countOfParticulars - 1;
        this.sumOfParticulars = +this.sumOfParticulars - +expensesDetailsEntryData.amount;
        this.calculateGrandTotal();
    }

    calculateGrandTotal() {
        let totalAmt = 0.0;
        let grandTot = 0.0;
        for (let i = 0; i < this.expensesDetailsEntryDataList.length; i++) {
            totalAmt = +totalAmt + +this.expensesDetailsEntryDataList[i].amount;
        }
        grandTot = Math.round(totalAmt);
        $("#"+this.pageId+"grandTotal").val(grandTot);
    }


    calculateAmount(e) {
        if (e.keyCode == 13) {
            this.enteredQuantity = $("#"+this.pageId+"quantity").val();
            this.enteredRate = $("#"+this.pageId+"rate").val();
            let rate = 0.0;
            let qnty = 0.0;
            let amount = 0.0;

            rate = (this.enteredRate == null ? 0 : this.enteredRate == '' ? 0 : this.enteredRate);
            qnty = (this.enteredQuantity == null ? 0 : this.enteredQuantity == '' ? 0 : this.enteredQuantity);
            amount = rate * qnty;
            $("#"+this.pageId+"amount").val(amount);
        }
    }
   calculateAmountIn(){
    this.enteredQuantity = $("#"+this.pageId+"quantity").val();
    this.enteredRate = $("#"+this.pageId+"rate").val();
    let rate = 0.0;
    let qnty = 0.0;
    let amount = 0.0;

    rate = (this.enteredRate == null ? 0 : this.enteredRate == '' ? 0 : this.enteredRate);
    qnty = (this.enteredQuantity == null ? 0 : this.enteredQuantity == '' ? 0 : this.enteredQuantity);
    amount = rate * qnty;
    $("#"+this.pageId+"amount").val(amount);

   }

    // newItemNameEnter(e){
    //     if (e.keyCode == 13) {
    //         $("#"+this.pageId+"quantity").focus();
    //     }

    // }

    // unitEnter(){
    //     $("#"+this.pageId+"rate").focus();

    // }

    validateFinalBtn() {
        this.enteredBillNumber = $("#"+this.pageId+"billNumber").val();
        this.selectedBllDate = $("#"+this.pageId+"billDate").val();
        this.selectedVendorName = $("#"+this.pageId+"vendorName").val();
        this.enteredNewVendorName = $("#"+this.pageId+"newVendorName").val();
        this.finalVendorName = '';
        if (this.selectedVendorName == "Add New") {
            this.finalVendorName = $("#"+this.pageId+"newVendorName").val();
        } else {
            this.finalVendorName = $("#"+this.pageId+"vendorName").val();
        }

        if (this.finalVendorName == null || this.finalVendorName == '' || this.enteredBillNumber == null || this.enteredBillNumber == '' || this.selectedBllDate == null || this.selectedBllDate == '') {
            swal({
                title: "Warning",
                text: "Please Select the Vendor name ,Bill Number and Bill Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });


        } else {
            if (this.expensesDetailsEntryDataList.length > 0) {
                swal({
                    title: "Confirm Save/Update",
                    text: "Sure You want to save/update the expenses details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((bulk) => {
                    if (bulk) {
                        this.getInvoiceUserValues();
                    }
                });

            } else {
                swal({
                    title: "Warning",
                    text: "Please Particular Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }

        }


    }
    getInvoiceUserValues() {

        this.enteredBillNumber = $("#"+this.pageId+"billNumber").val();
        let enteredBillDate = null;
        enteredBillDate = $("#"+this.pageId+"billDate").val();
        this.selectedBllDate = this.datePipe.transform(enteredBillDate, "yyyy-MM-dd");
        this.selectedVendorName = $("#"+this.pageId+"vendorName").val();
        this.enteredNewVendorName = $("#"+this.pageId+"newVendorName").val();
        this.finalVendorName = '';
        if (this.selectedVendorName == "Add New") {
            this.finalVendorName = $("#"+this.pageId+"newVendorName").val();
        } else {
            this.finalVendorName = $("#"+this.pageId+"vendorName").val();
        }
        let totalAmt = 0.0;
        let grandTot = 0.0;
        for (let i = 0; i < this.expensesDetailsEntryDataList.length; i++) {
            totalAmt = +totalAmt + +this.expensesDetailsEntryDataList[i].amount;
        }
        grandTot = Math.round(totalAmt);

        this.lrDtoSaveParticular = new LRDto();
        this.lrDtoSaveParticular.id = this.billAutoId;
        this.lrDtoSaveParticular.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoSaveParticular.billNumber = this.enteredBillNumber;
        this.lrDtoSaveParticular.billDate = this.selectedBllDate;
        this.lrDtoSaveParticular.supplierName = this.finalVendorName;
        this.lrDtoSaveParticular.grandTotal = grandTot;
        this.lrDtoSaveParticular.lrDtos = this.getEnteredItemDetails();
        this.lrDtoSaveParticular.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSaveParticular.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoSaveParticular);

        this.addExpensesDetails();
    }




    getEnteredItemDetails() {
        this.particularDetailsLRDto = [];
        this.enteredBillNumber = $("#"+this.pageId+"billNumber").val();
        let enteredBillDate = null;
        enteredBillDate = $("#"+this.pageId+"billDate").val();
        this.selectedBllDate = this.datePipe.transform(enteredBillDate, "yyyy-MM-dd");

        for (let i = 0; i < this.expensesDetailsEntryDataList.length; i++) {
            let maintenance = this.expensesDetailsEntryDataList[i];
            this.lrDtoValidateParticular = new LRDto();
            this.lrDtoValidateParticular.commodityName = maintenance.particulars;
            this.lrDtoValidateParticular.billNumber = this.enteredBillNumber;
            this.lrDtoValidateParticular.billDate = this.selectedBllDate;
            this.lrDtoValidateParticular.qty = maintenance.quantity;
            this.lrDtoValidateParticular.unldgUnit = maintenance.unit;
            this.lrDtoValidateParticular.rate = maintenance.rate;
            this.lrDtoValidateParticular.totalAmount = maintenance.amount;
            this.lrDtoValidateParticular.branch = this.userDataDtoReturnSession.mainStation;
            this.particularDetailsLRDto.push(this.lrDtoValidateParticular);
        }
        return this.particularDetailsLRDto;
    }


    addExpensesDetails = () => {
        this.showSpinnerForAction = true;
        this.masterService.addExpensesDetails(this.lrDtoSaveParticular).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "success") {
                    this.clearAllFields();
                    this.gettingVendorList();
                    this.gettingItemList();
                    swal({
                        title: "Success",
                        text: "Expenses details saved successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                   
                } else {

                    swal({
                        title: "Failed",
                        text: "Problem occurred while saving the expenses details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Adding the Expenses  details", "info");
            }, () => console.log('done');
    };
    clearAllFields() {
        console.log('hh');
        this.showSpinnerForAction = false;
        this.showNewVendorName = false;
        this.showNewItemName = false;
        this.modelVendorName = '';
        this.modelItemName = '';
        this.selectedVendorName = '';
        this.selectedItemName = '';
        this.enteredBillNumber = '';
        this.selectedBllDate = '';
        this.enteredNewVendorName = '';
        this.enteredNewItemName = '';
        this.enteredQuantity = '';
        this.selectedUnit = '';
        this.enteredRate = '';
        this.enteredAmt = '';
        this.enteredGrandTotal = '';
        this.finalVendorName = '';
        this.finalItemName = '';
        this.modelBillDates = null;
        this.itemNameToShow = '';
        this.countOfParticulars = 0;
        this.sumOfParticulars = 0;
        this.setNewName = '';
        this.particularDetailsLRDto = [];
        this.lrDtoSaveParticular = new LRDto();
        this.lrDtoValidateParticular = new LRDto();
        this.billAutoId = 0;
        $("#"+this.pageId+"quantity").val('');
        $("#"+this.pageId+"unit").val('Liters');
        $("#"+this.pageId+"rate").val('');
        $("#"+this.pageId+"amount").val('');
        $("#"+this.pageId+"newItemName").val('');
        $("#"+this.pageId+"itemName").val('');
        this.editAmountVal = 0;
        this.editParticularsTableDetailsId = null;
        this.newAttributeToSetParticulars = {};
        $("#"+this.pageId+"expensesDetailsEntryId").DataTable().destroy();
        this.expensesDetailsEntryDataList=[];
        this.dtTriggerExpensesDetailsEntry.next();
        $("#"+this.pageId+"billNumber").val('');
        $("#"+this.pageId+"billDate").val('');
        $("#"+this.pageId+"newVendorName").val('');
        $("#"+this.pageId+"vendorName").val('');

    }
}