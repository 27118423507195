<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<!-- <div class="col-md-12 col-sm-12">
	<i title="Reload" class="fas fa-sync-alt fa xs cli"
		style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood; cursor: pointer;"
		(click)="getDetails()"></i>
</div> -->
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Truck Required For Trip
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}truckReqForTripTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsTruckRequiredForTrip"
			[dtTrigger]="dtTriggerTruckRequiredForTrip">

			<thead>
				<tr>
					<th>Destination</th>
					<th>Act Wgt</th>
					<th>Chg Wgt</th>
					<th>Avg Truck Req</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let truckRequiredForTripData of truckRequiredForTripDataList ">
					<td>{{ truckRequiredForTripData.stocksAt }}</td>
					<td>{{ truckRequiredForTripData.actualWeight }}</td>
					<td>{{ truckRequiredForTripData.chargedWeight }}</td>
					<td>{{ truckRequiredForTripData.avgTruckReq }}</td>
				</tr>
			</tbody>
			<!-- <tfoot style="background:#eadbba">
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
			</tfoot> -->
		</table>
	</div>
</div>
