import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';

export interface DialogData {
	animal: string;
	name: string;
}
@Component({
	selector: 'app-update-consignor-rate-for-all',
	templateUrl: './update-consignor-rate-for-all.component.html',
	styleUrls: ['./update-consignor-rate-for-all.component.css']
})
export class UpdateConsignorRateForAllComponent implements OnInit {
	pageId = "updcgrrateall";

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;

	/* For Source Drop Down Start */
	agentDetailsOptionsSet: AgentDetailsDto[];
	agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
	public agentNameModal: any;
	agentNameTA: Array<AgentDetailsDto> = [];
	focusAgentNameTA$ = new Subject<string>();
	agentNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAgentNameTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.agentNameTA
				: this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAgentName = (x: { source: string }) => x.source;

	/* Commodity Drop Down Start Here */
	rateMasterDto: RateMasterDto = new RateMasterDto();
	rateMasterDtoOptions: RateMasterDto[];
	rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
	public modelCommodityList: any;
	commodityListTA: Array<RateMasterDto> = [];
	focusCommodityListTA$ = new Subject<string>();
	searchCommodityList = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityListTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.commodityListTA
				: this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
	/* Commodity Drop Down End Here */

	/* For Destination Drop Down */
	destinationOptions: LRDto[];
	lrDtoToStation: LRDto = new LRDto();
	destinationDtoForAll: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { godownName: string }) => x.godownName;

	///For Source Main Booking Station
	agentDtoOptionSourceStation: AgentDetailsDto[];
	agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
	public modelMainBookingSource: any;
	mainBookingSourceTA: Array<AgentDetailsDto> = [];
	focusMainBookingSourceTA$ = new Subject<string>();
	searchMainBookingSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMainBookingSourceTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.mainBookingSourceTA
				: this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;

	selectedDestinationForCommodityPopUp: any;
	rateChangeTxtId = 'Rate';

	viewPercentageFieldPopUp: boolean = false;
	viewRateFieldPopUp: boolean = true;
	viewPerUnitFieldPopUp: boolean = true;

	rateMasterDtoGetUserValPopUp: RateMasterDto = new RateMasterDto();
	rateMasterDtoForSaveUpdate: RateMasterDto = new RateMasterDto();
	selectedDropDownMainStation: any;
	validateSelectedUpdateModePopUp: any;
	validateSelectedDestinationPopUp: any;
	validateSelectedSourcePopUp: any;
	validateSelectedCommodityPopUp: any;
	validateSelectedUnitPopUp: any;
	validateSelectedRatesFixedDisTextPopUp: any;
	validateSelectedRatesFixedDisNumberPopUp: any;
	validateSelectedUnitPercTextPopUp: any;
	validateSelectedUnitPercRateNumberPopUp: any;
	validateSelectedPerUnitPopUp: any;
	validateSelectedFixedByPopUp: any;
	validateSelectedEffectedFromPopUp: any;
	validateSelectedFixedOnPopUp: any;

	listConsigneeId: Array<Number> = [];
	viewSubRate = false;
	viewSaveBtn: boolean = true;
	viewActChgWgtField = false;
	subStationCheckStatus: boolean = false;

	selectedRateFor: any;
	enteredSourceForSave: any;
	enteredDestinationForSave: any;
	enteredDDChargeForSave: any;
	enteredCommodityForSave: any;
	enteredRateTypeForSave: any;
	enteredBasicRateForSave: any;
	enteredHamaliForSave: any;
	enteredGCChargeForSave: any;
	enteredAOCChargeForSave: any;
	enteredOtherChargeForSave: any;
	enteredRiskChargeForSave: any;
	enteredFixedBy: any;
	selectedEeffectDate: any;
	selectedFixedOn: any;
	enteredRCChargeForSave: any;
	enteredSCChargeForSave: any;
	enteredDestHamaliForSave: any;
	enteredBasicRateForSaveSub: any;
	enteredHamaliForSaveSub: any;
	enteredGCChargeForSaveSub: any;
	enteredAOCChargeForSaveSub: any;
	enteredOtherChargeForSaveSub: any;
	enteredRiskChargeForSaveSub: any;
	enteredFixedBySub: any;
	selectedEeffectDateSub: any;
	selectedFixedOnSub: any;
	enteredRCChargeForSaveSub: any;
	enteredSCChargeForSaveSub: any;
	enteredDestHamaliForSaveSub: any;
	enteredPerUnitForSave: any;
	enteredDDChargeForSaveSub: any;
	isMainCommodityValid = false;

	dtOptionsDestination: any;
	dtTriggerDestination: Subject<any> = new Subject();
	destinationDataList: any;
	lrDtoListDestinationChecked: Array<LRDto> = [];
	private newAttributeSetDestinationList: any = {};
	listDestination: Array<String> = [];

	viewBySuperAdmin: boolean = false;
	sourceView = false;

	dtTriggerCommodity: Subject<any> = new Subject();
	dtOptionsCommodity: any;
	commodityDataList: any;
	listCommodity: Array<String> = [];
	private newAttributeSetCommodityList: any = {};
	lrDtoLisCommodityChecked: Array<LRDto> = [];
	commodityType: string = 'specific';

	viewSpecificCommodity: boolean = true;
	destinationType: string = 'specific';
	viewSpecificDestination: boolean = true;
	dtOptionsSourcePartySpec: any;
	dtTriggerSourcePartySpec: Subject<any> = new Subject();
	private newAttributeSetSourceList: any = {};
	lrDtoListSourceChecked: Array<LRDto> = [];
	sourceType: string = 'specific';
	viewSpecificSource: boolean = true;
	listSource: Array<String> = [];
	constructor(private router: Router,
		private mastereadService: MasterReadService, private consignorService: ConsignorService,
		public dialogRef: MatDialogRef<UpdateConsignorRateForAllComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"CnorAdminRate ViewSrcAll") {
						this.viewBySuperAdmin = true;
					}

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgrRateMtr All Src View") {
						this.sourceView = true;
					}
				}
			}

			this.getDestinationDetails();
			this.getCommodityMasterDetailsList();
			this.getMainBookingSourceDetails();
			if (this.sourceView == false) {
				this.getAgentDetailList(this.userDataDtoReturnSession.mainStation);
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	ngOnInit(): void {
		this.dtOptionsDestination = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": true
		},
			this.dtOptionsCommodity = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 200,
				"scrollCollapse": true,
				"paging": false,
				"info": false
			},
			this.dtOptionsSourcePartySpec = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 200,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"searching": false

			}

	}
	ngOnDestroy(): void {
		this.dtTriggerDestination.unsubscribe();
		this.dtTriggerCommodity.unsubscribe();
		this.dtTriggerSourcePartySpec.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerDestination.next();
		this.dtTriggerCommodity.next();
		this.dtTriggerSourcePartySpec.next();
	}

	getDestinationMethod() {
		this.lrDtoToStation = new LRDto();
		this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
	}

	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.mastereadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
			(response) => {
				if (response.length > 0) {
					$("#" + this.pageId + "selectedDestinationTableId").DataTable().destroy();

					this.destinationDataList = [];
					this.destinationDataList = response;
					this.dtTriggerDestination.next();

					this.destinationOptions = [];
					this.destinationTA = [];

					this.destinationOptions = response;

					this.destinationDtoForAll = new LRDto();
					this.destinationDtoForAll.godownName = "ALL";
					this.destinationTA.push(this.destinationDtoForAll);

					for (let i = 0; i < this.destinationOptions.length; i++) {
						this.destinationTA.push(this.destinationOptions[i]);
					}
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');
	};

	getUserValuesForAgentDetailsList(mainSource) {
		this.agentDtoParam = new AgentDetailsDto();
		this.agentDtoParam.mode = "MainAndGrpRateMaster";
		this.agentDtoParam.status = "Working";
		this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
		this.agentDtoParam.mainStation = this.agentDtoParam.mainStation = mainSource;;
	}

	getAgentDetailList(mainSource) {
		this.showSpinnerForAction = true;
		$("#" + this.pageId + "agentName").val('');
		this.enteredSourceForSave = null;
		this.getUserValuesForAgentDetailsList(mainSource);
		this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
			(response) => {
				if (response.length == 0) {
					this.agentDetailsOptionsSet = [];
					this.agentNameTA = [];
				} else {
					this.agentDetailsOptionsSet = [];
					this.agentNameTA = [];
					this.agentDetailsOptionsSet = response;
					for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
						this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
					}
					this.dtTriggerSourcePartySpec.next();
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source Details", "info");
			},
			() => console.log('done');
	}

	getCommodityListRead() {
		this.rateMasterDto = new RateMasterDto();
		this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.rateMasterDto.mode = "mainConsigneeMaster";
	}

	getCommodityMasterDetailsList = () => {
		this.getCommodityListRead();
		this.mastereadService.getCommodityDetails(this.rateMasterDto).subscribe(
			(response) => {
				if (response) {
					console.log(response);
					if (response.length == 0) {
						this.rateMasterDtoOptions = [];
						this.commodityListTA = [];
						this.commodityDataList = [];
					} else {
						this.rateMasterDtoOptions = [];
						this.commodityListTA = [];

						this.rateMasterDtoOptions = response;
						this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
						this.rateMasterDtoCommodityListAllOption.commodityName = "All";
						this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
						for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
							this.commodityListTA.push(this.rateMasterDtoOptions[i]);
						}
						this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
						this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
						this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);

						//For Commodity Table added by imran 
						$("#" + this.pageId + "selectedCommodityTableId").DataTable().destroy();
						this.commodityDataList = [];
						this.commodityDataList = response;
						this.dtTriggerCommodity.next();

					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Commodity Master Details", "warning");
			},
			() => console.log('done');
	};

	searchUnitMethodPopUp(searchUnitPopUp: string) {
		if (searchUnitPopUp === 'rate') {
			this.viewRateFieldPopUp = true;
			this.viewPercentageFieldPopUp = false;

			this.viewPerUnitFieldPopUp = true;
		} else if (searchUnitPopUp === 'percentage') {
			this.viewRateFieldPopUp = false;
			this.viewPercentageFieldPopUp = true;

			this.viewPerUnitFieldPopUp = false;
		} else {
			this.viewRateFieldPopUp = false;
			this.viewPercentageFieldPopUp = false;
			this.viewPerUnitFieldPopUp = false;
		}
	}

	setOnload() {
		$("#" + this.pageId + "rateChangeTxtId").text('Rate');
	}

	searchRateMethodPopUp(searchRatePopUp: string) {
		if (searchRatePopUp === 'fixedRate') {
			this.rateChangeTxtId = 'Rate';
		} else if (searchRatePopUp === 'DiscRate') {
			this.rateChangeTxtId = 'Discount Rate (Rs)';
		} else {
			this.rateChangeTxtId = 'Rate';
		}
	}

	searchUnitPercMethodPopUp(searchUnitPercPopUp: string) {
		if (searchUnitPercPopUp === 'PLR') {

		} else if (searchUnitPercPopUp === 'PCM') {

		} else if (searchUnitPercPopUp === 'PFRT') {

		} else {

		}
	}

	SaveClickBtn() {
		swal({
			title: "Confirm Changes",
			text: "Sure You Want To Apply The Rate Changes...This changes cannot be Undo...",
			icon: "info",
			buttons: ["No", "Yes"]
		}).then((isConfirm) => {
			if (isConfirm) {
				this.validateBeforeSave();
			} else {
				console.log("No");
			}
		});
	}

	validateBeforeSave() {
		this.enteredSourceForSave = null;
		this.selectedRateFor = null;
		this.enteredCommodityForSave = null;
		this.enteredDestinationForSave = null;
		this.enteredRateTypeForSave = null;
		this.enteredPerUnitForSave = null;
		this.enteredBasicRateForSave = null;
		this.enteredHamaliForSave = null;
		this.enteredGCChargeForSave = null;
		this.enteredAOCChargeForSave = null;
		this.enteredDDChargeForSave = null;
		this.enteredOtherChargeForSave = null;
		this.enteredRiskChargeForSave = null;
		this.enteredFixedBy = null;
		this.selectedEeffectDate = null;
		this.selectedFixedOn = null;
		this.enteredRCChargeForSave = null;
		this.enteredSCChargeForSave = null;
		this.enteredDestHamaliForSave = null;
		this.enteredBasicRateForSaveSub = null;
		this.enteredHamaliForSaveSub = null;
		this.enteredGCChargeForSaveSub = null;
		this.enteredAOCChargeForSaveSub = null;
		this.enteredDDChargeForSaveSub = null;
		this.enteredOtherChargeForSaveSub = null;
		this.enteredRiskChargeForSaveSub = null;
		this.enteredFixedBySub = null;
		this.selectedEeffectDateSub = null;
		this.selectedFixedOnSub = null;
		this.enteredRCChargeForSaveSub = null;
		this.enteredSCChargeForSaveSub = null;
		this.enteredDestHamaliForSaveSub = null;


		this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
		this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
		this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
		this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
		this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
		this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
		this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
		this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
		this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
		this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
		this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
		this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
		this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
		this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
		this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
		this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
		this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
		this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
		this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
		this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSub").val();
		this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
		this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSub").val();
		this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSub").val();
		this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSub").val();
		this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSub").val();
		this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSub").val();
		this.enteredFixedBySub = $("#" + this.pageId + "fixedBySub").val();
		this.selectedEeffectDateSub = $("#" + this.pageId + "effectFromDateSub").val();
		this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSub").val();
		this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
		this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
		this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();

		console.log("this.subStationCheckStatus");
		console.log(this.subStationCheckStatus);
		//Removed source validation as changed the source to table to select multiple on 17052024
		if ((this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
			|| this.enteredRiskChargeForSave == null || this.enteredRiskChargeForSave == undefined || this.enteredRiskChargeForSave == ""
			|| this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
			|| this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
			|| this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "")) {
			swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
		} else if ((this.subStationCheckStatus == true) && (this.enteredBasicRateForSaveSub == null || this.enteredBasicRateForSaveSub == undefined || this.enteredBasicRateForSaveSub == ""
			|| this.enteredRiskChargeForSaveSub == null || this.enteredRiskChargeForSaveSub == undefined || this.enteredRiskChargeForSaveSub == ""
			|| this.enteredFixedBySub == null || this.enteredFixedBySub == undefined || this.enteredFixedBySub == ""
			|| this.selectedEeffectDateSub == null || this.selectedEeffectDateSub == undefined || this.selectedEeffectDateSub == ""
			|| this.selectedFixedOnSub == null || this.selectedFixedOnSub == undefined || this.selectedFixedOnSub == "")) {
			swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
		} else if ((this.commodityType == 'specific') && (this.lrDtoLisCommodityChecked == null || this.lrDtoLisCommodityChecked == undefined ||
			this.lrDtoLisCommodityChecked.length == 0)) {
			swal("Mandatory Field", "Please select the Commodity!", "warning");
			return false;
		} else if ((this.destinationType == 'specific') && (this.lrDtoListDestinationChecked == null || this.lrDtoListDestinationChecked == undefined ||
			this.lrDtoListDestinationChecked.length == 0)) {
			swal("Mandatory Field", "Please select Destination!", "warning");
			return false;
		} else if ((this.sourceType == 'specific') && (this.lrDtoListSourceChecked == null || this.lrDtoListSourceChecked == undefined ||
			this.lrDtoListSourceChecked.length == 0)) {
			swal("Mandatory Field", "Please select Source!", "warning");
			return false;
		} else {
			//commented after confirmation with asrar bhaiya because of passing multiple commodity on 17052024
			// this.validateMainCommoditySelection();
			// if (this.isMainCommodityValid) {
			// 	this.confirmSave();
			// }
			this.confirmSave();
		}
	}

	ClearClickBtn() {
		this.clearFieldPopUp();
	}

	clearFieldPopUp() {
		this.selectedDestinationForCommodityPopUp = null;
		this.rateChangeTxtId = 'Rate';

		this.rateMasterDtoGetUserValPopUp = new RateMasterDto();

		this.validateSelectedUpdateModePopUp = null;
		this.validateSelectedDestinationPopUp = null;
		this.validateSelectedSourcePopUp = null;
		this.validateSelectedCommodityPopUp = null;
		this.validateSelectedUnitPopUp = null;
		this.validateSelectedRatesFixedDisTextPopUp = null;
		this.validateSelectedRatesFixedDisNumberPopUp = null;
		this.validateSelectedUnitPercTextPopUp = null;
		this.validateSelectedUnitPercRateNumberPopUp = null;
		this.validateSelectedPerUnitPopUp = null;
		this.validateSelectedFixedByPopUp = null;
		this.validateSelectedEffectedFromPopUp = null;
		this.validateSelectedFixedOnPopUp = null;

		this.enteredRateTypeForSave = null;
		$("#" + this.pageId + "searchByRateType").val('');
		this.enteredPerUnitForSave = null;
		$("#" + this.pageId + "perUnitId").val('');
		this.enteredBasicRateForSave = null;
		$("#" + this.pageId + "basicRateId").val('');
		this.enteredHamaliForSave = null;
		$("#" + this.pageId + "hamaliChgId").val('');
		this.enteredGCChargeForSave = null;
		$("#" + this.pageId + "gcChargeId").val('');
		this.enteredAOCChargeForSave = null;
		$("#" + this.pageId + "aocChargeId").val('');
		this.enteredDDChargeForSave = null;
		$("#" + this.pageId + "ddChargeId").val('');
		this.enteredOtherChargeForSave = null;
		$("#" + this.pageId + "otherChargeId").val('');
		this.enteredRiskChargeForSave = null;
		$("#" + this.pageId + "fovChargeId").val('');
		this.enteredFixedBy = null;
		$("#" + this.pageId + "fixedBy").val('');
		this.selectedEeffectDate = null;
		$("#" + this.pageId + "effectFromDate").val('');
		this.selectedFixedOn = null;
		$("#" + this.pageId + "fixedOnDate").val('');
		this.enteredRCChargeForSave = null;
		$("#" + this.pageId + "rcChargeId").val('');
		this.enteredSCChargeForSave = null;
		$("#" + this.pageId + "scChargeId").val('');
		this.enteredDestHamaliForSave = null;
		$("#" + this.pageId + "destHamaliChgId").val('');
		this.enteredBasicRateForSaveSub = null;
		$("#" + this.pageId + "basicRateIdSub").val('');
		this.enteredHamaliForSaveSub = null;
		$("#" + this.pageId + "hamaliChgIdSub").val('');
		this.enteredGCChargeForSaveSub = null;
		$("#" + this.pageId + "gcChargeIdSub").val('');
		this.enteredAOCChargeForSaveSub = null;
		$("#" + this.pageId + "aocChargeIdSub").val('');
		this.enteredDDChargeForSaveSub = null;
		$("#" + this.pageId + "ddChargeIdSub").val('');
		this.enteredOtherChargeForSaveSub = null;
		$("#" + this.pageId + "otherChargeIdSub").val('');
		this.enteredRiskChargeForSaveSub = null;
		$("#" + this.pageId + "fovChargeIdSub").val('');
		this.enteredFixedBySub = null;
		$("#" + this.pageId + "fixedBySub").val('');
		this.selectedEeffectDateSub = null;
		$("#" + this.pageId + "effectFromDateSub").val('');
		this.selectedFixedOnSub = null;
		$("#" + this.pageId + "fixedOnDateSub").val('');
		this.enteredRCChargeForSaveSub = null;
		$("#" + this.pageId + "rcChargeIdSub").val('');
		this.enteredSCChargeForSaveSub = null;
		$("#" + this.pageId + "scChargeIdSub").val('');
		this.enteredDestHamaliForSaveSub = null;
		$("#" + this.pageId + "destHamaliChgIdSub").val('');

		$("#" + this.pageId + "updateModePopUpId").val('Increase');
		$("#" + this.pageId + "stationType").val('Both');
		$("#" + this.pageId + "destinationPopUpId").val('');
		$("#" + this.pageId + "sourcePopUp").val('');
		$("#" + this.pageId + "commodityListPopUpId").val('');
		$("#" + this.pageId + "searchUnitPopUp").val('rate');
		$("#" + this.pageId + "searchRatePopUp").val('fixedRate');
		$("#" + this.pageId + "ratePercentagePopUpId").val('');
		$("#" + this.pageId + "searchUnitPercPopUp").val('PLR');
		$("#" + this.pageId + "percentageRatePopUpId").val('');
		$("#" + this.pageId + "perUnitPopUpId").val('Article');
		$("#" + this.pageId + "fixedByPopUpId").val('');
		$("#" + this.pageId + "effectFromDatePopUpId").val('');
		$("#" + this.pageId + "fixedOnDatePopUpId").val('');
	}

	onCancelClick(): void {
		this.clearFieldPopUp();
		setTimeout(() => {
			this.viewPercentageFieldPopUp = false;
			this.viewRateFieldPopUp = true;
			this.viewPerUnitFieldPopUp = true;
			this.dialogRef.close("Close");
		}, 500);
	}


	/*On Key Enter press start here  */
	onKeyPressFieldForBaseChargeAmt(event) {
		if (event == 13) { $("#" + this.pageId + "hamaliChgId").focus(); }
	}

	onKeyPressFieldForHamaliChargeAmt(event) {
		if (event == 13) { $("#" + this.pageId + "gcChargeId").focus(); }
	}

	onKeyPressFieldForGCChargeAmt(event) {
		if (event == 13) { $("#" + this.pageId + "aocChargeId").focus(); }
	}

	onKeyPressFieldForAoc(event) {
		if (event == 13) { $("#" + this.pageId + "otherChargeId").focus(); }
	}

	onKeyPressFieldForOther(event) {
		if (event == 13) { $("#" + this.pageId + "fovChargeId").focus(); }
	}

	onKeyPressFieldForFov(event) {
		if (event == 13) { $("#" + this.pageId + "fixedBy").focus(); }
	}

	onKeyPressFieldForBaseChargeAmtSub(event) {
		if (event == 13) { $("#" + this.pageId + "hamaliChgIdSub").focus(); }
	}

	onKeyPressFieldForHamaliChargeAmtSub(event) {
		if (event == 13) { $("#" + this.pageId + "gcChargeIdSub").focus(); }
	}

	onKeyPressFieldForGCChargeAmtSub(event) {
		if (event == 13) { $("#" + this.pageId + "aocChargeIdSub").focus(); }
	}

	onKeyPressFieldForAocSub(event) {
		if (event == 13) { $("#" + this.pageId + "otherChargeIdSub").focus(); }
	}

	onKeyPressFieldForOtherSub(event) {
		if (event == 13) { $("#" + this.pageId + "fovChargeIdSub").focus(); }
	}

	onKeyPressFieldForFovSub(event) {
		if (event == 13) { $("#" + this.pageId + "fixedBySub").focus(); }
	}
	/*On Key Enter press start here  */

	searchByRateTypeMode(searchByRateType: string) {
		// alert(searchByRateType);
		if (searchByRateType === 'Fixed') {
			this.viewActChgWgtField = false;
		} else if (searchByRateType === 'PerKg') {
			this.viewActChgWgtField = true;
		}
	}

	toggleSubStationCheckbox(event) {
		if (event.target.checked) {
			this.viewSubRate = true;
			this.subStationCheckStatus = true;
		} else {
			this.viewSubRate = false;
			this.subStationCheckStatus = false;
		}
	}
	clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
		this.modelCommodityList = e.item;
		$("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
	}

	clickListnerForSource(event) {
		this.agentNameModal = event.item;
		this.selectedDropDownMainStation = null;
		this.selectedDropDownMainStation = this.agentNameModal.mainstation
	}


	confirmSave() {
		this.saveConsignorAdminRateMasterDetails();
	}


	saveConsignorAdminRateMasterDetails() {
		this.rateMasterDtoForSaveUpdate = new RateMasterDto();
		this.enteredSourceForSave = null;
		this.selectedRateFor = null;
		this.enteredCommodityForSave = null;
		this.enteredDestinationForSave = null;
		this.enteredRateTypeForSave = null;
		this.enteredPerUnitForSave = null;
		this.enteredBasicRateForSave = null;
		this.enteredHamaliForSave = null;
		this.enteredGCChargeForSave = null;
		this.enteredAOCChargeForSave = null;
		this.enteredDDChargeForSave = null;
		this.enteredOtherChargeForSave = null;
		this.enteredRiskChargeForSave = null;
		this.enteredFixedBy = null;
		this.selectedEeffectDate = null;
		this.selectedFixedOn = null;
		this.enteredRCChargeForSave = null;
		this.enteredSCChargeForSave = null;
		this.enteredDestHamaliForSave = null;
		this.enteredBasicRateForSaveSub = null;
		this.enteredHamaliForSaveSub = null;
		this.enteredGCChargeForSaveSub = null;
		this.enteredAOCChargeForSaveSub = null;
		this.enteredDDChargeForSaveSub = null;
		this.enteredOtherChargeForSaveSub = null;
		this.enteredRiskChargeForSaveSub = null;
		this.enteredFixedBySub = null;
		this.selectedEeffectDateSub = null;
		this.selectedFixedOnSub = null;
		this.enteredRCChargeForSaveSub = null;
		this.enteredSCChargeForSaveSub = null;
		this.enteredDestHamaliForSaveSub = null;


		this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
		this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
		this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
		this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
		this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
		this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
		this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
		this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
		this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
		this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
		this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
		this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
		this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
		this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
		this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
		this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
		this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
		this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
		this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
		this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSub").val();
		this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
		this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSub").val();
		this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSub").val();
		this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSub").val();
		this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSub").val();
		this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSub").val();
		this.enteredFixedBySub = $("#" + this.pageId + "fixedBySub").val();
		this.selectedEeffectDateSub = $("#" + this.pageId + "effectFromDateSub").val();
		this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSub").val();
		console.log("this.subStationCheckStatus");
		console.log(this.subStationCheckStatus);
		this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
		this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
		this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();
		this.rateMasterDtoForSaveUpdate.source = this.enteredSourceForSave;
		this.rateMasterDtoForSaveUpdate.rateFor = "Commodity";

		if ($("#" + this.pageId + "updateModePopUpId").val() == "Increase") {
			this.rateMasterDtoForSaveUpdate.rptMode = "Add";
		} else if ($("#" + this.pageId + "updateModePopUpId").val() == "Decrease") {
			this.rateMasterDtoForSaveUpdate.rptMode = "Sub";
		}

		if ($("#" + this.pageId + "stationType").val() == "Mainstation") {
			this.rateMasterDtoForSaveUpdate.subStationRate = false;
			this.rateMasterDtoForSaveUpdate.searchType = "Mainstation";
		} else if ($("#" + this.pageId + "stationType").val() == "Substation") {
			this.rateMasterDtoForSaveUpdate.subStationRate = true;
			this.rateMasterDtoForSaveUpdate.searchType = "Substation";
		} else if ($("#" + this.pageId + "stationType").val() == "Both") {
			this.rateMasterDtoForSaveUpdate.searchType = "Both";
		}

		this.rateMasterDtoForSaveUpdate.consignorId = null;

		this.rateMasterDtoForSaveUpdate.commodityName = this.enteredCommodityForSave == null ? null : this.enteredCommodityForSave == undefined ? null : this.enteredCommodityForSave == "" ? null : this.enteredCommodityForSave;
		this.rateMasterDtoForSaveUpdate.destination = this.enteredDestinationForSave;
		this.rateMasterDtoForSaveUpdate.ratePerUnit = this.enteredRateTypeForSave;
		this.rateMasterDtoForSaveUpdate.perUnit = this.enteredPerUnitForSave == null ? null : this.enteredPerUnitForSave == undefined ? null : this.enteredPerUnitForSave;
		this.rateMasterDtoForSaveUpdate.basicCharge = this.enteredBasicRateForSave == null ? 0.0 : this.enteredBasicRateForSave == undefined ? 0.0 : this.enteredBasicRateForSave == "" ? 0.0 : this.enteredBasicRateForSave;
		this.rateMasterDtoForSaveUpdate.hamali = this.enteredHamaliForSave == null ? 0.0 : this.enteredHamaliForSave == undefined ? 0.0 : this.enteredHamaliForSave == "" ? 0.0 : this.enteredHamaliForSave;
		this.rateMasterDtoForSaveUpdate.gcCharge = this.enteredGCChargeForSave == null ? 0.0 : this.enteredGCChargeForSave == undefined ? 0.0 : this.enteredGCChargeForSave == "" ? 0.0 : this.enteredGCChargeForSave;
		this.rateMasterDtoForSaveUpdate.aocChargeAmt = this.enteredAOCChargeForSave == null ? 0.0 : this.enteredAOCChargeForSave == undefined ? 0.0 : this.enteredAOCChargeForSave == "" ? 0.0 : this.enteredAOCChargeForSave;
		this.rateMasterDtoForSaveUpdate.ddChargeAmt = this.enteredDDChargeForSave == null ? 0.0 : this.enteredDDChargeForSave == undefined ? 0.0 : this.enteredDDChargeForSave == "" ? 0.0 : this.enteredDDChargeForSave;
		this.rateMasterDtoForSaveUpdate.otherChgAmt = this.enteredOtherChargeForSave == null ? 0.0 : this.enteredOtherChargeForSave == undefined ? 0.0 : this.enteredOtherChargeForSave == "" ? 0.0 : this.enteredOtherChargeForSave;
		this.rateMasterDtoForSaveUpdate.riskChargeAmt = this.enteredRiskChargeForSave == null ? 0.0 : this.enteredRiskChargeForSave == undefined ? 0.0 : this.enteredRiskChargeForSave == "" ? 0.0 : this.enteredRiskChargeForSave;
		this.rateMasterDtoForSaveUpdate.fixedBy = this.enteredFixedBy == null ? 0.0 : this.enteredFixedBy == undefined ? 0.0 : this.enteredFixedBy == "" ? 0.0 : this.enteredFixedBy;
		this.rateMasterDtoForSaveUpdate.effectiveFromDate = this.selectedEeffectDate == null ? 0.0 : this.selectedEeffectDate == undefined ? 0.0 : this.selectedEeffectDate == "" ? 0.0 : this.selectedEeffectDate;
		this.rateMasterDtoForSaveUpdate.fixedDate = this.selectedFixedOn == null ? 0.0 : this.selectedFixedOn == undefined ? 0.0 : this.selectedFixedOn == "" ? 0.0 : this.selectedFixedOn;
		this.rateMasterDtoForSaveUpdate.receiptCharges = this.enteredRCChargeForSave == null ? 0.0 : this.enteredRCChargeForSave == undefined ? 0.0 : this.enteredRCChargeForSave == "" ? 0.0 : this.enteredRCChargeForSave;
		this.rateMasterDtoForSaveUpdate.serviceCharges = this.enteredSCChargeForSave == null ? 0.0 : this.enteredSCChargeForSave == undefined ? 0.0 : this.enteredSCChargeForSave == "" ? 0.0 : this.enteredSCChargeForSave;
		this.rateMasterDtoForSaveUpdate.destHamali = this.enteredDestHamaliForSave == null ? 0.0 : this.enteredDestHamaliForSave == undefined ? 0.0 : this.enteredDestHamaliForSave == "" ? 0.0 : this.enteredDestHamaliForSave;
		this.rateMasterDtoForSaveUpdate.basicChargeSub = this.enteredBasicRateForSaveSub == null ? 0.0 : this.enteredBasicRateForSaveSub == undefined ? 0.0 : this.enteredBasicRateForSaveSub == "" ? 0.0 : this.enteredBasicRateForSaveSub;
		this.rateMasterDtoForSaveUpdate.hamaliSub = this.enteredHamaliForSaveSub == null ? 0.0 : this.enteredHamaliForSaveSub == undefined ? 0.0 : this.enteredHamaliForSaveSub == "" ? 0.0 : this.enteredHamaliForSaveSub;
		this.rateMasterDtoForSaveUpdate.gcChargeSub = this.enteredGCChargeForSaveSub == null ? 0.0 : this.enteredGCChargeForSaveSub == undefined ? 0.0 : this.enteredGCChargeForSaveSub == "" ? 0.0 : this.enteredGCChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.aocChargeAmtSub = this.enteredAOCChargeForSaveSub == null ? 0.0 : this.enteredAOCChargeForSaveSub == undefined ? 0.0 : this.enteredAOCChargeForSaveSub == "" ? 0.0 : this.enteredAOCChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.ddChargeAmtSub = this.enteredDDChargeForSaveSub == null ? 0.0 : this.enteredDDChargeForSaveSub == undefined ? 0.0 : this.enteredDDChargeForSaveSub == "" ? 0.0 : this.enteredDDChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.otherChgAmtSub = this.enteredOtherChargeForSaveSub == null ? 0.0 : this.enteredOtherChargeForSaveSub == undefined ? 0.0 : this.enteredOtherChargeForSaveSub == "" ? 0.0 : this.enteredOtherChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.riskChargeAmtSub = this.enteredRiskChargeForSaveSub == null ? 0.0 : this.enteredRiskChargeForSaveSub == undefined ? 0.0 : this.enteredRiskChargeForSaveSub == "" ? 0.0 : this.enteredRiskChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.receiptChargesSub = this.enteredRCChargeForSaveSub == null ? 0.0 : this.enteredRCChargeForSaveSub == undefined ? 0.0 : this.enteredRCChargeForSaveSub == "" ? 0.0 : this.enteredRCChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.serviceChargesSub = this.enteredSCChargeForSaveSub == null ? 0.0 : this.enteredSCChargeForSaveSub == undefined ? 0.0 : this.enteredSCChargeForSaveSub == "" ? 0.0 : this.enteredSCChargeForSaveSub;
		this.rateMasterDtoForSaveUpdate.destHamaliSub = this.enteredDestHamaliForSaveSub == null ? 0.0 : this.enteredDestHamaliForSaveSub == undefined ? 0.0 : this.enteredDestHamaliForSaveSub == "" ? 0.0 : this.enteredDestHamaliForSaveSub;
		this.rateMasterDtoForSaveUpdate.fixedBySub = this.enteredFixedBySub == null ? 0.0 : this.enteredFixedBySub == undefined ? 0.0 : this.enteredFixedBySub == "" ? 0.0 : this.enteredFixedBySub;
		this.rateMasterDtoForSaveUpdate.effectiveFromDateSub = this.selectedEeffectDateSub == null ? 0.0 : this.selectedEeffectDateSub == undefined ? 0.0 : this.selectedEeffectDateSub == "" ? 0.0 : this.selectedEeffectDateSub;
		this.rateMasterDtoForSaveUpdate.fixedDateSub = this.selectedFixedOnSub == null ? 0.0 : this.selectedFixedOnSub == undefined ? 0.0 : this.selectedFixedOnSub == "" ? 0.0 : this.selectedFixedOnSub;
		this.rateMasterDtoForSaveUpdate.userName = this.userDataDtoReturnSession.userId;
		this.rateMasterDtoForSaveUpdate.companyId = this.userDataDtoReturnSession.companyId;
		this.rateMasterDtoForSaveUpdate.mainStation = this.selectedDropDownMainStation;


		this.listCommodity = [];
		if (this.commodityType == 'specific' && this.lrDtoLisCommodityChecked != null && this.lrDtoLisCommodityChecked != undefined &&
			this.lrDtoLisCommodityChecked.length > 0) {
			for (let i = 0; i < this.lrDtoLisCommodityChecked.length; i++) {
				this.listCommodity.push(this.lrDtoLisCommodityChecked[i].commodityName);
			}
		} else {
			this.listCommodity.push('All');
		}
		this.rateMasterDtoForSaveUpdate.listOfCommodity = this.listCommodity;
		//Changed for adding all destination by imran  on 17052024
		// this.listDestination = [];
		// if (this.lrDtoListDestinationChecked != null && this.lrDtoListDestinationChecked != undefined &&
		// 	this.lrDtoListDestinationChecked.length > 0) {
		// 	for (let i = 0; i < this.lrDtoListDestinationChecked.length; i++) {
		// 		this.listDestination.push(this.lrDtoListDestinationChecked[i].godownName);
		// 	}
		// }

		// this.rateMasterDtoForSaveUpdate.list = this.listDestination;
		this.listDestination = [];
		if (this.destinationType == 'all' && this.destinationDataList != null && this.destinationDataList != undefined &&
			this.destinationDataList.length > 0) {
			for (let i = 0; i < this.destinationDataList.length; i++) {
				this.listDestination.push(this.destinationDataList[i].godownName);
			}
		} else if (this.destinationType == 'specific' && this.lrDtoListDestinationChecked != null && this.lrDtoListDestinationChecked != undefined &&
			this.lrDtoListDestinationChecked.length > 0) {
			for (let i = 0; i < this.lrDtoListDestinationChecked.length; i++) {
				this.listDestination.push(this.lrDtoListDestinationChecked[i].godownName);
			}
		} else {
			this.listDestination.push(this.enteredDestinationForSave);
		}
		this.rateMasterDtoForSaveUpdate.list = this.listDestination;

		this.listSource = [];
		if (this.sourceType == 'all' && this.agentDetailsOptionsSet != null && this.agentDetailsOptionsSet != undefined &&
			this.agentDetailsOptionsSet.length > 0) {
			for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
				this.listSource.push(this.agentDetailsOptionsSet[i].source);
			}
		} else if (this.sourceType == 'specific' && this.lrDtoListSourceChecked != null && this.lrDtoListSourceChecked != undefined &&
			this.lrDtoListSourceChecked.length > 0) {
			for (let i = 0; i < this.lrDtoListSourceChecked.length; i++) {
				this.listSource.push(this.lrDtoListSourceChecked[i].source);
			}
		}
		this.rateMasterDtoForSaveUpdate.listOfSource = this.listSource;

		console.log(this.rateMasterDtoForSaveUpdate);
		this.showSpinnerForAction = true;

		this.consignorService.updateConsignorAdminRateAll(this.rateMasterDtoForSaveUpdate).subscribe(
			(response) => {
				console.log(response);
				if (response.status == "success") {
					swal("Success", " Consignor Rate updated successfully", "success");
				} else {
					swal("Error", "Problem occurred while updating Consignor Rate details", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating Consignor Rate details", "error");
			}, () => console.log('done');

	}

	validateMainCommoditySelection() {
		this.isMainCommodityValid = false;
		this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
		for (let i = 0; i < this.commodityListTA.length; i++) {
			if (this.commodityListTA[i].commodityName == this.enteredCommodityForSave) {
				this.isMainCommodityValid = true;
			}
		}
		if (this.isMainCommodityValid == false) {
			swal({
				title: "Not Allowed",
				text: "Selected Commodity " + this.enteredCommodityForSave + " is not valid, please check and select again!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}

	rowCheckBoxForDestination(e, destinationData, index) {
		if (e.currentTarget.checked) {
			this.newAttributeSetDestinationList = {};
			this.newAttributeSetDestinationList.godownName = destinationData.godownName;
			if (this.lrDtoListDestinationChecked == null ||
				this.lrDtoListDestinationChecked == undefined) {
				this.lrDtoListDestinationChecked = [];
			}
			if (this.lrDtoListDestinationChecked.length == 0) {
				this.lrDtoListDestinationChecked.push(this.newAttributeSetDestinationList);
			} else {
				const result = this.lrDtoListDestinationChecked.filter(lrDtoListSourceData =>
					lrDtoListSourceData.godownName === this.newAttributeSetDestinationList.godownName);
				if (result.length > 0) {
					swal("Duplicate Entry", "" + this.newAttributeSetDestinationList.godownName + " Already Exist", "warning");
				} else {
					this.lrDtoListDestinationChecked.push(this.newAttributeSetDestinationList);
				}
			}
		} else {
			this.lrDtoListDestinationChecked.splice(index, 1);
		}
		console.log(this.lrDtoListDestinationChecked);
	}

	//Click Listener For Source main Station
	clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelMainBookingSource = e.item;
		$("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
		this.getAgentDetailList(this.modelMainBookingSource.mainBookStations);
	}

	getMainBookingSourceDataDetails() {
		this.agentDtoSourceStation = new AgentDetailsDto();
		this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
	}
	getMainBookingSourceDetails() {
		this.getMainBookingSourceDataDetails();
		this.mastereadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
			(response) => {
				this.mainBookingSourceTA = [];
				this.agentDtoOptionSourceStation = [];
				this.agentDtoOptionSourceStation = response;
				for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
					this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
			},
			() => console.log('done');
	}
	//Added by imran for multiple commodity selection on 17052024
	rowCheckBoxForCommodity(e, commoditynData, index) {
		if (e.currentTarget.checked) {
			this.newAttributeSetCommodityList = {};
			this.newAttributeSetCommodityList.commodityName = commoditynData.commodityName;
			if (this.lrDtoLisCommodityChecked == null ||
				this.lrDtoLisCommodityChecked == undefined) {
				this.lrDtoLisCommodityChecked = [];
			}
			if (this.lrDtoLisCommodityChecked.length == 0) {
				this.lrDtoLisCommodityChecked.push(this.newAttributeSetCommodityList);
			} else {
				const result = this.lrDtoLisCommodityChecked.filter(commodityListData =>
					commodityListData.commodityName === this.newAttributeSetCommodityList.commodityName);
				if (result.length > 0) {
					swal("Duplicate Entry", "" + this.newAttributeSetCommodityList.commodityName + " Already Exist", "warning");
				} else {
					this.lrDtoLisCommodityChecked.push(this.newAttributeSetCommodityList);
				}
			}
		} else {
			this.lrDtoLisCommodityChecked.splice(index, 1);
		}
		console.log(this.lrDtoLisCommodityChecked);
	}

	commodityTypeSelected(selectedType: string) {
		this.newAttributeSetCommodityList = {};
		this.lrDtoLisCommodityChecked = [];
		$("#" + this.pageId + "#selectedDestination").prop("checked", false);
		this.commodityType = selectedType;
		if (selectedType == 'all') {

			this.viewSpecificCommodity = false;

			$("#" + this.pageId + "#selectedCommodity").prop("checked", false);

		} else {


			this.viewSpecificCommodity = true;

		}

	}

	destinationTypeSelected(selectedType: string) {

		this.newAttributeSetDestinationList = {};
		this.lrDtoListDestinationChecked = [];
		$("#" + this.pageId + "#selectedDestination").prop("checked", false);
		console.log(this.newAttributeSetDestinationList);
		console.log(this.lrDtoListDestinationChecked);
		this.destinationType = selectedType;
		if (selectedType == 'all') {

			this.viewSpecificDestination = false;

		} else {


			this.viewSpecificDestination = true;
		}

	}

	sourceTypeSelected(selectedType: string) {
		this.newAttributeSetSourceList = {};
		this.lrDtoListSourceChecked = [];
		$("#" + this.pageId + "#selectedSource").prop("checked", false);
		this.sourceType = selectedType;
		if (selectedType == 'all') {

			this.viewSpecificSource = false;

		} else {


			this.viewSpecificSource = true;
		}

	}

	rowCheckBoxForSource(e, agentDetails, index) {
		if (e.currentTarget.checked) {
			this.newAttributeSetSourceList = {};
			this.newAttributeSetSourceList.source = agentDetails.source;
			if (this.lrDtoListSourceChecked == null ||
				this.lrDtoListSourceChecked == undefined) {
				this.lrDtoListSourceChecked = [];
			}
			if (this.lrDtoListSourceChecked.length == 0) {
				this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
			} else {
				const result = this.lrDtoListSourceChecked.filter(lrDtoListSourceDataPartySpec =>
					lrDtoListSourceDataPartySpec.source === this.newAttributeSetSourceList.source);
				if (result.length > 0) {
					swal("Duplicate Entry", "" + this.newAttributeSetSourceList.godownName + " Already Exist", "warning");
				} else {
					this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
				}
			}
		} else {
			this.lrDtoListSourceChecked.splice(index, 1);
		}
		console.log(this.lrDtoListSourceChecked);
	}
}

