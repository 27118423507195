<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}partylesscheckuploadRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Party Less Check - Upload Type
                    </h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body" style="border-bottom: 1px solid orange;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                            <div class="col-sm-12 col-md-1">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input class="form-control" placeholder="From Date" name="fromDatesDateWise" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDatesDateWise" [(ngModel)]="fromDatesModal" autocomplete="off" (click)="fromDates.toggle()">
                                                        <!-- <div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-1">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input id="{{pageId}}toDatesDateWise" class="form-control" placeholder="To Date" name="toDatesDateWise" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off" (click)="toDates.toggle()">
                                                        <!-- <div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}destinationDateWise">
                                                        <!-- <div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-road"></i>
															</span>
														</div> -->
                                                        <input id="{{pageId}}destinationIdDateWise" type="text" class="form-control" (keyup)="clickListnerForDestinationDateWise($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-1">
                                                <div class="form-group ">
                                                    <div class="input-group ">
                                                        <!-- <div class="input-group-prepend ">
															<span class="input-group-text "> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div> -->
                                                        <input type="text" class="form-control " id="{{pageId}}statementNumber" (keypress)="onKeyPressListnerForStmtNo($event);" aria-describedby="basic-addon11" autocomplete="off" placeholder="Stmt No">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group ">
                                                    <div class="input-group ">
                                                        <!-- <div class="input-group-prepend ">
															<span class="input-group-text "> <i class="fas fa-user"></i>
															</span>
														</div> -->
                                                        <input type="text" class="form-control " id="{{pageId}}agentName" aria-describedby="basic-addon11 " placeholder="Agent Name" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <div class="form-group ">
                                                    <!-- <label>Upload Rate File</label> -->
                                                    <div class="input-group">
                                                        <input type="file" id="{{pageId}}uploadRateFileId" (change)="onFileChangedUploadRateFile($event)" #fileInputUploadRateFile />
                                                        <button type="button" class="btn btn-success m-r-2" id="{{pageId}}uploadBtn" (click)="uploadBtn();">Upload</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <button type="submit" class="btn btn-success m-r-2" id="{{pageId}}saveLessDetailsBtn" (click)="saveLessDetailsBtn()">Save/Update
                                                    Less Details</button>
                                                <button type="submit" class="btn btn-dark" id="{{pageId}}clearDetailsBtn" (click)="clearBtn();">Clear
                                                    Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-9 p-t-10">
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <div class="control">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Commodity</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
                                                </span>
                                            </div>
                                            <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8">
                                <!-- <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}selectAllBtn"
                                    (click)="checkUncheckAll();">
                                    Select All</button> -->
                                <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}singleRcBtn" (click)="rowSelectedRcMemoSingleLrDetailsDataOnEdit();">
                                    Single RC</button>
                                <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}singleRcBtn" (click)="rowSelectedRcMemoMultiLrDetailsDataOnEdit();">
                                    Multi RC</button>
                                <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}saveLessDetailsBtn" (click)="bulkLRRCMemoBtn();">Bulk
                                    RC</button>
                                <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}printAllBtnId" (click)="printAllBtn();">Print All
                                </button>
                                <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}saveLessDetailsBtn" (click)="printDiifLr();">Print
                                    Diff Lrs</button>

                                <!-- <button type="submit" class="btn btn-success m-r-8" id="{{pageId}}saveLessDetailsBtn"
                                    (click)="checkSelectedLrs();">Kamil
                                    Check</button> -->


                            </div>
                        </div>
                        <!--<div class="col-sm-12 col-md-12">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}printAllBtnId"
								(click)="printAllBtn();">Print All
								Details</button>
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveLessDetailsBtn"
								(click)="printDiifLr();">Print Difference Lrs</button>
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveLessDetailsBtn"
								(click)="bulkLRRCMemoBtn();">Bulk LR RC Memo</button>
						</div>-->
                        <div class="box-body">
                            <h6 class="card-title" style="padding: 0px 9px;">Less LR Details
                            </h6>
                            <table datatable id="{{pageId}}lessLRDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLessLrDetails" [dtTrigger]="dtTriggerLessLrDetails">

                                <thead>
                                    <tr>
                                        <!-- <th><input name='checkbox' type="checkbox" id="selectAll"
                                                (click)="checkSelectedLrs()"></th> -->
                                        <th>LR Number</th>
                                        <th>Source</th>
                                        <th>Consignee Name</th>
                                        <th>Art</th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                        <th>Act Less</th>
                                        <th>Ent Less</th>
                                        <th>Diff Less</th>
                                        <th>Remarks</th>
                                        <th>Commodity</th>
                                        <th [hidden]=true>Consignee Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lessLrDetailsData of lessLrDetailsDataList let i = index " (click)="selectRow(lessLrDetailsData,i);rowSelectedSetDetailsDataOnEdit(lessLrDetailsData);" style="cursor: pointer;">
                                        <!-- <td>
											<i style="cursor: pointer;" class="fas fa-pencil-alt"
												title="RC Memo Single Lr" (click)="selectRow(lessLrDetailsData,i);"></i>
											&nbsp;&nbsp;&nbsp;
											<i style="cursor: pointer;"
											class="fas fa-eye" title="View RC Memo Single Lr Details"
											(click)="rowSelectedSetDetailsDataOnEdit(lessLrDetailsData);"></i>
										</td> -->
                                        <!-- <td><input type="checkbox" [checked]="multiSelect" name="{{ some }}"
                                                id='selectedLr'
                                                (change)="rowCheckBoxClicked($event, lessLrDetailsData,i)" />
                                        </td> -->
                                        <td>{{ lessLrDetailsData.lrNumber }}</td>
                                        <td>{{ lessLrDetailsData.source }}</td>
                                        <td>{{ lessLrDetailsData.consigneeName }}</td>
                                        <td>{{ lessLrDetailsData.totalArticles }}</td>
                                        <td>{{ lessLrDetailsData.actualWeight }}</td>
                                        <td>{{ lessLrDetailsData.chargedWeight }}</td>
                                        <td>{{ lessLrDetailsData.discountAmount }}</td>
                                        <td>{{ lessLrDetailsData.enteredLessAmt }}</td>
                                        <td>{{ lessLrDetailsData.diffValue }}</td>
                                        <td>{{ lessLrDetailsData.remarks }}</td>
                                        <td>{{ lessLrDetailsData.subCommodity }}</td>
                                        <td [hidden]=true>{{ lessLrDetailsData.consigneeId }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <!-- <td></td> -->
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]=true></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                        <!-- <div class="box-body" style="padding-top: 20px;">
                            <h6 class="card-title" style="padding: 0px 9px;">Blocked Lrs Details
                            </h6>
                            <table datatable id="{{pageId}}blockLRDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBlockedLrsDetails" [dtTrigger]="dtTriggerBlockedLrsDetails">

                                <thead>
                                    <tr>
                                        <th>LR Number</th>
                                        <th>Source</th>
                                        <th>Consignee Name</th>
                                        <th>Art</th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                        <th>Act Less</th>
                                        <th>Ent Less</th>
                                        <th>Diff Less</th>
                                        <th>Remarks</th>
                                        <th>Commodity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let blockedLrsDetailsData of blockedLrsDetailsDataList ">
                                        <td>{{ blockedLrsDetailsData.lrNumber }}</td>
                                        <td>{{ blockedLrsDetailsData.source }}</td>
                                        <td>{{ blockedLrsDetailsData.consigneeName }}</td>
                                        <td>{{ blockedLrsDetailsData.articles }}</td>
                                        <td>{{ blockedLrsDetailsData.actualWeight }}</td>
                                        <td>{{ blockedLrsDetailsData.chargedWeight }}</td>
                                        <td>{{ blockedLrsDetailsData.discountAmount }}</td>
                                        <td>{{ blockedLrsDetailsData.enteredLessAmt }}</td>
                                        <td>{{ blockedLrsDetailsData.diffValue }}</td>
                                        <td>{{ blockedLrsDetailsData.remarks }}</td>
                                        <td>{{ blockedLrsDetailsData.subCommodity }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div> -->
                    </div>
                    <div class="col-md-3 vl p-t-10">
                        <h6 class="card-title" style="padding: 0px 9px;">Standard Rate Master</h6>
                        <div class="col-sm-12 col-md-12 ">
                            <div class="form-group ">
                                <div class="input-group ">
                                    <label>Rate</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="fas fa-rupee-sign "></i>
                                        </span>
                                    </div>
                                    <input type="number" class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}rate" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 ">
                            <div class="form-group ">
                                <div class="input-group ">
                                    <label>G.C. Charges</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="fas fa-rupee-sign "></i>
                                        </span>
                                    </div>
                                    <input type="number " class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}gcCharges" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>LC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " id="{{pageId}}lc" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali Src</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " id="{{pageId}}hamaliSrc" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>BC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}bc" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " id="{{pageId}}aoc" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>F O V</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="Yes" class="form-control " id="{{pageId}}fov" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " id="{{pageId}}dd" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <input type="text " placeholder="No" class="form-control " id="{{pageId}}others" aria-describedby="basic-addon11 " readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 ">
                            <div class="form-group ">
                                <div class="input-group ">
                                    <label>Source Station</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="fas fa-warehouse"></i>
                                        </span>
                                    </div>
                                    <input type="text " class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}sourceStation" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 ">
                            <div class="form-group ">
                                <div class="input-group ">
                                    <label>Per Unit</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="fas fa-weight"></i>
                                        </span>
                                    </div>
                                    <input type="text " class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}perUnit" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 ">
                            <div class="form-group ">
                                <div class="input-group ">
                                    <label>Discount</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="fas fa-rupee-sign "></i>
                                        </span>
                                    </div>
                                    <input type="number " class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}discount" readonly>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="col-sm-12 col-md-12">
                            <h6>Main Station Wise</h6>
                            <table datatable id="{{pageId}}stmtNoMainSrcWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStmtNoMainSrcWise" [dtTrigger]="dtTriggerStmtNoMainSrcWise">

                                <thead>
                                    <tr>
                                        <th>Station</th>
                                        <th>Tot Lrs</th>
                                        <th [hidden]="!viewAdminFields">Act Less</th>
                                        <th>Ent Less</th>
                                        <th [hidden]="!viewAdminFields">Diff Less</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let stmtNoMainSrcDetailsData of stmtNoMainSrcDetailsDataList ">
                                        <td>{{ stmtNoMainSrcDetailsData.mainstation }}</td>
                                        <td>{{ stmtNoMainSrcDetailsData.totalLrs }}</td>
                                        <td [hidden]="!viewAdminFields">{{ stmtNoMainSrcDetailsData.actualLessAmt }}
                                        </td>
                                        <td>{{ stmtNoMainSrcDetailsData.enteredLessAmt }}</td>
                                        <td [hidden]="!viewAdminFields">{{ stmtNoMainSrcDetailsData.diffValue }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                        <td></td>
                                        <td [hidden]="!viewAdminFields"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>