import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemoService } from "src/app/dataService/memo-service";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Subject, merge } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-billing-to-srd-collection-stock',
    templateUrl: './billing-to-srd-collection-stock.component.html',
    styleUrls: ['./billing-to-srd-collection-stock.component.css']
})
export class BillingToSrdCollectionStockComponent implements OnInit {
    closeResult: string;
    animal: string;
    name: string;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    @ViewChild('content') content: TemplateRef<any>;
    manualView = true;
    automaticView = false;
    lrDto: LRDto = new LRDto();
    userDataDto: UserDataDto = new UserDataDto();
    getDtlsCashMemoDto: CashMemoDto = new CashMemoDto();
    userDataDtoTransferMemo: UserDataDto = new UserDataDto();
    chkUserDtoTransferMemo: UserDataDto = new UserDataDto();
    userDataDtoFinishTransferMemo: UserDataDto = new UserDataDto();
    chkUserDataDtoFinishTransferMemo: UserDataDto = new UserDataDto();
    chkuserDataDtoFinishBtnTransferMemo: UserDataDto = new UserDataDto();
    chkUserDtoFinishBtn: UserDataDto = new UserDataDto();

    stationOptions: LRDto[];
    stationList: any;
    selectedStation: any;
    selectedEntryType: any;
    enteredMemoNumber: any
    enteredCashMemoFormat: any
    enteredCashMemoNumber: any;
    stationCodeDetails: any;
    memoFormat: any;
    memoNum = null;
    memoDetails: any;
    memoDetailsTransfer: any;
    updateMemoDetails: any;
    chkMemoDetails: any;
    model: any;
    getBatchNumber; any;
    getMode: any;
    dataForPopupFromCashmemoPendAndMissOtherDetails: any;
    dataForPopupFromCashmemoPendFromBinnyOtherDetails: any;
    showStation = true;
    listOfMemoNumber: Array<any> = [];
    controlStation = new FormControl();

    //Station 
    public modelStationName: any;
    @ViewChild('instanceStation') instanceStation: NgbTypeahead;
    stationTA: Array<any> = [];
    focusStationTA$ = new Subject<string>();
    entryStationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.stationTA
                : this.stationTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    pageId = "btscc";
    viewCustomPrintV1: boolean = false;
    dtTriggerTransfer: Subject<any> = new Subject();
    dtOptionsTransfer: any;
    private newAttribute: any = {};
    transferToCollectionDataList: Array<any> = [];
    transferToCollectionDataListPush: any;
    listOfMemoNumberToTransfer: Array<any> = [];

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef,
        private masterReadService: MasterReadService, private memoService: MemoService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.showStation = true;
            //            this.getMemoCode();
            this.dataForPopupFromCashmemoPendAndMissOtherDetails = JSON.parse(localStorage.getItem('cashmemoPendingAndMissingForBillingPopup'));
            this.dataForPopupFromCashmemoPendFromBinnyOtherDetails = JSON.parse(localStorage.getItem('cashmemoPendingFromBinnyForBillingPopup'));
            console.log(this.dataForPopupFromCashmemoPendAndMissOtherDetails);
            console.log(this.dataForPopupFromCashmemoPendFromBinnyOtherDetails);
            localStorage.clear();

            if (this.dataForPopupFromCashmemoPendAndMissOtherDetails != null) {
                this.model = this.dataForPopupFromCashmemoPendAndMissOtherDetails;
                this.getBatchNumber = this.dataForPopupFromCashmemoPendAndMissOtherDetails.batchNumber;
                this.getMode = 'transfer';
                this.showStation = false;
                this.getMemoCodeForDashBoard();
            } else if (this.dataForPopupFromCashmemoPendFromBinnyOtherDetails != null) {
                this.model = this.dataForPopupFromCashmemoPendFromBinnyOtherDetails;
                this.getBatchNumber = this.dataForPopupFromCashmemoPendFromBinnyOtherDetails.batchNumber;
                this.getMode = 'transferToGodown';
                this.showStation = true;
            } else {
                this.model = null;
                this.getBatchNumber = '';
                this.getMode = '';
            }

            this.dataForPopupFromCashmemoPendFromBinnyOtherDetails = '';
            this.dataForPopupFromCashmemoPendFromBinnyOtherDetails = '';
        }
    }
    ngOnInit() {
        this.dtOptionsTransfer = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html();
                $(api.column(5).footer()).html(totalAmt);
                $(api.column(6).footer()).html();
                $(api.column(7).footer()).html();
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerTransfer.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTriggerTransfer.next();

    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    entryTypeMode(entryType: string) {
        if (this.transferToCollectionDataList.length > 0) {
            swal({
                title: "Warning",
                text: "All the entered Memo will be remove if change the Entry Type, sure u want to change it",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((ok) => {
                if (ok) {
                    //this.clearFieldsFinal();
                    console.log(this.transferToCollectionDataList.length);
                    // for (let i = 0; i < this.transferToCollectionDataList.length; i++) {
                    //     console.log('transferToCollectionDataList');
                    //     console.log(i);
                    //     this.transferToCollectionDataList.splice(i, 1);
                    // }
                    this.transferToCollectionDataList = [];
                    $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
                    this.dtTriggerTransfer.next();
                    this.changeDetectorRef.detectChanges();
                    this.confirmEntryTypeChange(entryType);
                }
            });
        } else {
            this.confirmEntryTypeChange(entryType);
        }
    }
    confirmEntryTypeChange(entryType) {
        if (entryType === 'manual') {
            this.manualView = true;
            this.automaticView = false;
            window.setTimeout(function () {
                $("#btsccstations").focus();
            }, 100);
        } else if (entryType === 'automatic') {
            this.manualView = false;
            this.automaticView = true;
            window.setTimeout(function () {
                $("#btsccstations").focus();
            }, 100);
        } else {
            this.manualView = false;
            this.automaticView = false;
        }
    }

    getDestinationMethod() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.mode = "ALL";

    }
    getDestinationDetails() {
        this.getDestinationMethod();

        this.masterReadService.getDestinationForLREntryService(this.lrDto).subscribe(
            (response) => {
                if (response) {
                    this.stationOptions = response;
                    this.stationTA = [];
                    for (let i = 0; i < this.stationOptions.length; i++) {
                        this.stationTA.push(this.stationOptions[i].destination);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getDetailsForMemoCode() {
        this.userDataDto = new UserDataDto();
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.office = this.modelStationName;
    }
    getMemoCode() {
        this.getDetailsForMemoCode();
        this.masterReadService.getStationCodeService(this.userDataDto).subscribe(
            (response) => {
                if (response.length != 0) {

                    this.selectedEntryType = $("#" + this.pageId + "entryType").val();
                    if (this.selectedEntryType == "automatic") {
                        this.automaticView = true;
                        $("#" + this.pageId + "cashMemoNumber").focus();
                    } else {
                        this.stationCodeDetails = response;
                        if (this.stationCodeDetails.stationCode == null) {
                            $("#" + this.pageId + "cashMemoFormat").val('');
                        } else {
                            this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                            $("#" + this.pageId + "cashMemoFormat").val(this.memoFormat);
                            $("#" + this.pageId + "memoNumber").val('');
                            $("#" + this.pageId + "cashMemoNumber").val('');
                            $("#" + this.pageId + "lrNumber").val('');
                            $("#" + this.pageId + "memoAmount").val('');
                            window.setTimeout(function () {
                                $("#btsccmemoNumber").focus();
                            }, 100);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getDetailsForMemoCodeForDashBoard() {
        this.userDataDto = new UserDataDto();
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.office = this.userDataDtoReturnSession.office;
    }
    getMemoCodeForDashBoard() {
        this.getDetailsForMemoCodeForDashBoard();
        this.masterReadService.getStationCodeService(this.userDataDto).subscribe(
            (response) => {
                if (response.length != 0) {

                    this.selectedEntryType = $("#" + this.pageId + "entryType").val();
                    if (this.selectedEntryType == "automatic") {
                        this.automaticView = true;
                        $("#" + this.pageId + "cashMemoNumber").focus();
                    } else {
                        this.stationCodeDetails = response;
                        if (this.stationCodeDetails.stationCode == null) {
                            $("#" + this.pageId + "cashMemoFormat").val('');
                        } else {
                            this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                            $("#" + this.pageId + "cashMemoFormat").val(this.memoFormat);
                            $("#" + this.pageId + "memoNumber").val('');
                            $("#" + this.pageId + "cashMemoNumber").val('');
                            $("#" + this.pageId + "lrNumber").val('');
                            $("#" + this.pageId + "memoAmount").val('');
                            $("#" + this.pageId + "memoNumber").focus();
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getFocusMethod() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        if (this.selectedEntryType == "automatic") {
            window.setTimeout(function () {
                $("#btscccashMemoNumber").val('');
                $("#btscccashMemoNumber").focus();
                $("#btscclrNumber").val('');
                $("#btsccmemoAmount").val('')
            }, 1000);

        } else {
            window.setTimeout(function () {
                $("#btsccmemoNumber").val('');
                $("#btsccmemoNumber").focus();
                $("#btscclrNumber").val('');
                $("#btsccmemoAmount").val('')
            }, 1000);

        }
    }

    getMemoNumber(e) {
        if (e.keyCode == 13) {
            this.getMemoDetails();
        }
    }

    getMethodForMemoDtails() {
        this.getDtlsCashMemoDto = new CashMemoDto();
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredCashMemoFormat = $("#" + this.pageId + "cashMemoFormat").val();
        this.enteredCashMemoNumber = $("#" + this.pageId + "cashMemoNumber").val();

        this.memoNum = ''
        if (this.selectedEntryType == "automatic") {
            this.memoNum = this.enteredCashMemoNumber;
        } else {
            this.memoNum = this.enteredCashMemoFormat + "" + this.enteredMemoNumber;
            if (this.enteredMemoNumber == '') {
                this.memoNum = null;
            }
        }
        this.getDtlsCashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
        this.getDtlsCashMemoDto.memoNumber = this.memoNum;

    }

    getMemoDetails() {
        this.getMethodForMemoDtails();
        if (this.memoNum == null || this.memoNum == "") {
            swal({
                title: "Warning",
                text: "Memo Number is Mandatory!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,

            }).then(() => {
                this.getFocusMethod();
            })
        } else {
            this.memoService.getMemoDetailService(this.getDtlsCashMemoDto).subscribe(
                (response) => {
                    if (response) {
                        this.memoDetails = response;
                        if (this.memoDetails.cashMemoName == null && this.memoDetails.status == null && this.memoDetails.grandTotal == null) {
                            swal({
                                title: "Warning",
                                text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetails.cashMemoName == "Cash") {
                            swal({
                                title: "Warning",
                                text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Amount is collected",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetails.cashMemoName != "Cash") {
                            if (this.memoDetails.status == 'transfer' || this.memoDetails.status == 'transferToGodown' || this.memoDetails.status == 'missing') {
                                if (this.selectedEntryType == "automatic") {
                                    this.memoCheckCallAllow();
                                } else {
                                    $("#" + this.pageId + "memoAmount").val(this.memoDetails.grandTotal);
                                    $("#" + this.pageId + "lrNumber").val(this.memoDetails.gcNoteNumber);
                                    window.setTimeout(function () {
                                        $("#btscctransferBtn").focus();
                                    }, 100);
                                }
                            } else if (this.memoDetails.status == 'billing') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in Billing Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetails.status == 'collection') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " already in Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            } else if (this.memoDetails.status == 'completed') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Collection Closed",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            }

                        }

                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => swal({
                    title: "Server Error",
                    text: "While Getting Memo Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
                () => console.log('done');
        }
    }

    validateMemoOnTransfer() {
        this.getMethodForMemoDtails();
        if (this.memoNum == null) {
            swal({
                title: "Warning",
                text: "Memo Number is Mandatory!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,

            }).then(() => {
                this.getFocusMethod();
            })
        } else {
            this.memoService.getMemoDetailService(this.getDtlsCashMemoDto).subscribe(
                (response) => {
                    if (response) {
                        this.memoDetailsTransfer = response;
                        if (this.memoDetailsTransfer.cashMemoName == null && this.memoDetailsTransfer.status == null && this.memoDetailsTransfer.grandTotal == null) {
                            swal({
                                title: "Warning",
                                text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetailsTransfer.cashMemoName == "Cash") {
                            swal({
                                title: "Warning",
                                text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Amount is collected",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })

                        } else if (this.memoDetailsTransfer.cashMemoName != "Cash") {
                            if (this.memoDetailsTransfer.status == 'transfer' || this.memoDetailsTransfer.status == 'transferToGodown' || this.memoDetailsTransfer.status == 'missing') {
                                this.transferMemoMethod();
                            } else if (this.memoDetailsTransfer.status == 'billing') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in Billing Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            } else if (this.memoDetailsTransfer.status == 'collection') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " already in Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            } else if (this.memoDetailsTransfer.status == 'completed') {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Collection Closed",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            } else {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })

                            }

                        }

                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => swal({
                    title: "Server Error",
                    text: "While Getting Memo Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
                () => console.log('done');
        }
    }

    getMethodForTransferMemoDtails() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredCashMemoFormat = $("#" + this.pageId + "cashMemoFormat").val();
        this.enteredCashMemoNumber = $("#" + this.pageId + "cashMemoNumber").val();

        this.memoNum = ''
        if (this.selectedEntryType == "automatic") {
            this.memoNum = this.enteredCashMemoNumber;
        } else {
            this.memoNum = this.enteredCashMemoFormat + "" + this.enteredMemoNumber;
            if (this.enteredMemoNumber == '') {

                this.memoNum = null;

            }
        }
        this.userDataDtoTransferMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoTransferMemo.memoNumber = this.memoNum;
        this.userDataDtoTransferMemo.mode = "collection";
        this.userDataDtoTransferMemo.updatedBy = this.userDataDtoReturnSession.userId;
    }
    transferMemoMethod() {
        this.getMethodForTransferMemoDtails();

        this.memoService.updateMemoDetailService(this.userDataDtoTransferMemo).subscribe(
            (response) => {
                if (response) {
                    this.updateMemoDetails = response;

                    this.chkUserDtoTransferMemo = this.updateMemoDetails;
                    if (this.chkUserDtoTransferMemo.result == "exists") {
                        $("#" + this.pageId + "memoAmount").val('');
                        swal({
                            title: "Warning",
                            text: "Transfer To Collection CashMemo " + this.userDataDtoTransferMemo.memoNumber + " already transferd to collection!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,

                        }).then(() => {
                            this.getFocusMethod();
                        })

                    } else if (this.chkUserDtoTransferMemo.result == "notexists") {
                        swal({
                            title: "Warning",
                            text: "Transfer To Collection CashMemo " + this.userDataDtoTransferMemo.memoNumber + " does not exist!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,

                        }).then(() => {
                            this.getFocusMethod();
                        })

                    } else {
                        $("#" + this.pageId + "memoNumber").val('');
                        $("#" + this.pageId + "cashMemoNumber").val('');
                        $("#" + this.pageId + "lrNumber").val('');
                        $("#" + this.pageId + "memoAmount").val('');
                        this.getFocusMethod();
                    }

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Updating Memo Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }

    getMethodForTransferMemoDtailsBulk() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredCashMemoFormat = $("#" + this.pageId + "cashMemoFormat").val();
        this.enteredCashMemoNumber = $("#" + this.pageId + "cashMemoNumber").val();
        this.memoNum = ''
        if (this.selectedEntryType == "automatic") {
            this.memoNum = this.enteredCashMemoNumber;
        } else {
            this.memoNum = this.enteredCashMemoFormat + "" + this.enteredMemoNumber;
            if (this.enteredMemoNumber == '') {
                this.memoNum = null;
            }
        }
        this.listOfMemoNumberToTransfer = [];
        for (let i = 0; i < this.transferToCollectionDataList.length; i++) {
            this.listOfMemoNumberToTransfer.push(this.transferToCollectionDataList[i].memoNumber);
        }
        this.userDataDtoTransferMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoTransferMemo.memoNumber = this.memoNum;
        this.userDataDtoTransferMemo.mode = "collection";
        this.userDataDtoTransferMemo.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDtoTransferMemo.list = this.listOfMemoNumberToTransfer;
    }
    transferMemoMethodBulk() {
        this.getMethodForTransferMemoDtailsBulk();
        this.memoService.updateMemoTransferV2(this.userDataDtoTransferMemo).subscribe(
            (response) => {
                if (response) {
                    this.updateMemoDetails = response;
                    this.chkUserDtoTransferMemo = this.updateMemoDetails;
                    if (this.chkUserDtoTransferMemo.result == "success") {
                        swal({
                            title: "Success",
                            text: "Memo are transferred!",
                            icon: "info",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        })
                        this.clearFieldsFinal();
                    } else if (this.chkUserDtoTransferMemo.result == "failed") {
                        swal({
                            title: "Warning",
                            text: "Failed to transfer the Memo!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            this.getFocusMethod();
                        })
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Updating Memo Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }
    getMethodForFinishBtnTransfer() {
        this.chkuserDataDtoFinishBtnTransferMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.chkuserDataDtoFinishBtnTransferMemo.status = this.getMode;
        this.chkuserDataDtoFinishBtnTransferMemo.batchNumber = this.getBatchNumber;
        this.chkuserDataDtoFinishBtnTransferMemo.mainStation = this.userDataDtoReturnSession.mainStation;
    }
    finishBtnTransfer() {
        if (this.model != null) {
            this.getMethodForFinishBtnTransfer();
            this.memoService.chkRemCashMemoService(this.chkuserDataDtoFinishBtnTransferMemo).subscribe(
                (response) => {
                    if (response.length > 0) {
                        this.chkMemoDetails = response;

                        this.chkUserDtoFinishBtn = this.chkMemoDetails;
                        //                    this.finishTransfer()
                        console.log(this.chkUserDtoFinishBtn);
                        this.finishTransfer(this.chkUserDtoFinishBtn)
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => swal({
                    title: "Server Error",
                    text: "While Updating Memo Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
                () => console.log('done');
        }

    }

    getMethodForFinishTransfer(dataForMethodFinishTransfer) {
        this.userDataDtoFinishTransferMemo.mode = "missing";
        this.userDataDtoFinishTransferMemo.batchNumber = this.getBatchNumber;
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.chkMemoDetails.length; i++) {
            this.listOfMemoNumber.push(this.chkMemoDetails[i].memoNumber);
        }
        this.userDataDtoFinishTransferMemo.list = this.listOfMemoNumber;
        this.userDataDtoFinishTransferMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoFinishTransferMemo.updatedBy = this.userDataDtoReturnSession.userId;
        console.log(this.userDataDtoFinishTransferMemo);
    }
    finishTransfer(dataForFinishTransfer) {
        swal({
            title: "Memos Missing ",
            text: dataForFinishTransfer.length + " CashMemos are not transfered.R U want to mark as Missing?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.getMethodForFinishTransfer(dataForFinishTransfer);
                this.memoService.updateMemoDetailService(this.userDataDtoFinishTransferMemo).subscribe(
                    (response) => {
                        if (response) {
                            this.updateMemoDetails = response;
                            this.chkUserDataDtoFinishTransferMemo = this.updateMemoDetails;
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal({
                        title: "Server Error",
                        text: "While Updating Memo Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            } else {
                swal.close();
            }
        })
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        window.setTimeout(function () {
            inp._elementRef.nativeElement.focus();
        }, 1000);
    }
    getStationMemoCode(e: NgbTypeaheadSelectItemEvent) {
        this.modelStationName = e.item;
        this.getMemoCode();
    }

    entryTypeModeFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#btsccstations").focus();
            }, 100);
        }
    }

    memoCheckCallAllow() {
        if (this.transferToCollectionDataList.length > 0) {
            const checkMemoNumberInTable = this.transferToCollectionDataList.find(transferToCollectionData => transferToCollectionData.memoNumber === this.memoNum);
            console.log(checkMemoNumberInTable);

            if (checkMemoNumberInTable == undefined) {
                this.addInTable();
            } else {
                swal({
                    title: "Duplicate CashMemo",
                    text: "CashMemo " + this.memoNum + " already moved to transfer!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    swal.close();
                    this.getFocusMethod();
                });
            }

        } else {
            console.log('5')
            this.getFocusMethod();
            this.addInTable();
        }
    }

    addInTable() {
        console.log('addInTable');
        //console.log(this.memoDetails);
        this.newAttribute.memoNumber = this.memoDetails.memoNumber;
        this.newAttribute.lrNumber = this.memoDetails.lrNumber;
        this.newAttribute.totalArticles = this.memoDetails.totalArticles;
        this.newAttribute.consigneeName = this.memoDetails.consigneeName;
        this.newAttribute.consignor = this.memoDetails.consignor;
        this.newAttribute.amount = this.memoDetails.amount;
        this.newAttribute.collectionMan = this.memoDetails.collectionMan;
        $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
        if (this.transferToCollectionDataList == null) {
            this.transferToCollectionDataList = [];
        }
        if (this.transferToCollectionDataList.length == 0) {
            this.transferToCollectionDataList.push(this.newAttribute);
        } else {
            this.transferToCollectionDataList.push(this.newAttribute);
        }
        this.dtTriggerTransfer.next();
        // this.countOfLr = +this.countOfLr + 1;
        // this.sumOfTotAmt = +this.sumOfTotAmt + +this.newAttribute.amount;
        // this.sumOfTotArt = +this.sumOfTotArt + +this.newAttribute.totalArticles;
        this.newAttribute = {};
        $("#" + this.pageId + "cashMemoNumber").val('');
        $("#" + this.pageId + "cashMemoFormat").val('');
    }

    transferBtnValidation() {
        if (this.transferToCollectionDataList.length == 0) {
            swal({
                title: "Not Record",
                text: "No Memo to transfer!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                swal.close();
                this.getFocusMethod();
            });
        } else {
            swal({
                title: "Validation",
                text: "Sure U Want to Transfer the entered CashMemo!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((transfer) => {
                if (transfer) {
                    this.transferMemoMethodBulk();
                }
            });
        }
    }

    rowSelectedDeleteForTable(transferToCollectionData) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Row",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                let index = -1;
                for (let i = 0; i < this.transferToCollectionDataList.length; i++) {
                    if (this.transferToCollectionDataList[i].lrNumber === transferToCollectionData.lrNumber) {
                        index = i;
                        break;
                    }
                }
                if (index === -1) {
                    alert("Something gone wrong while removing the row");
                }
                this.transferToCollectionDataList.splice(index, 1);
                $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
                this.dtTriggerTransfer.next();
                this.changeDetectorRef.detectChanges();
            }

        });
    }

    clearFieldsFinal() {
        this.listOfMemoNumberToTransfer = [];
        this.userDataDtoTransferMemo = new UserDataDto();
        $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
        this.transferToCollectionDataList = [];
        this.dtTriggerTransfer.next();
        this.newAttribute = {};
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}