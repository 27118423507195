<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">


        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Consignor Master</h6>
            </div>
            <!--<form>-->
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="card-title">Party Details</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignor Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-user-circle"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}consignorName" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GST Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}gstNumber" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Mobile Number</label>
                                                    <!--<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-phone"></i>
														</span>
													</div>-->
                                                    <span style="cursor: pointer;" (click)="viewMobileNumber();">View</span>
                                                    <!--<input type="number" class="form-control" id="{{pageId}}mobileNumber"
															aria-describedby="basic-addon11" autocomplete="off">
															-->

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Office Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-phone"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="{{pageId}}officeNumber" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Email-Id</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}emailId" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Person</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}contactPerson" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Agent Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}agentName" aria-describedby="basic-addon11" autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title">Address Details</h6>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Address</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <textarea class="form-control " rows="1 " id="{{pageId}}address" autocomplete="off"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}area" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>City</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}city" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Pincode</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="{{pageId}}pincode" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="blockCFTCheckBox">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Disable Default CFT(In LR Booking)</label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxCft" (change)="cftSetupForConsignor($event)"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxCft">Disable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr style="width: 80%; border-top: none; margin: 3px;">
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-success m-r-10" (click)="validateUpdateFinishBtn();" id="{{pageId}}finishBtn" *ngIf="viewSaveUpdateBtn">Save/Update</button>
                                    <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-3 vl">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Search Criteria</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Search Mode</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-search"></i>
												</span>
                                            </div>
                                            <select class="custom-select col-12" id="{{pageId}}searchMode" name="searchMode" #searchMode (change)="searchModeMethod(searchMode.value)">
												<option value="normalSearch">Normal Search</option>
												<option value="advanceSearch">Advance Search</option>
                                                <option value="searchByGst">Search By Gst</option>
											</select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="normalView">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Search By</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-search"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchMethod(searchBy.value)">
													<option selected value='select'>Select.......</option>
													<option value="index">Index</option>
													<option value="commodity">Commodity</option>
													<option value="source">Source</option>
													<option value="srcncom">Source & Commodity</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="index" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Index</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input #index id="{{pageId}}indexId" name="indexId" type="text" class="form-control" [(ngModel)]="modelIndex" placeholder="Select Index" (selectItem)="indexDropDownListner($event)" [ngbTypeahead]="indexSearchTA" (focus)="focusIndexTA$.next($any($event).target.value)"
                                                        #instanceIndex="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="commodity" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Commodity</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="source" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}sourceId" (selectItem)="clickListnerForSource($event)" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                        placeholder="Select Source.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="advanceView">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}sourceAllAdvance" (selectItem)="clickListnerForSourceAllAdvance($event)" [(ngModel)]="modelSourceAllAdvance" [ngbTypeahead]="searchSourceAllAdvance" [resultFormatter]="formatterSourceAllAdvance" [inputFormatter]="formatterSourceAllAdvance"
                                                        (focus)="focusSourceAllAdvanceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" *ngIf="sourceSubStationView">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Select City</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-road"></i>
													</span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}sourceSubStation" (selectItem)="clickListnerForSourceSubStation($event)" [(ngModel)]="modelSourceSubStation" [ngbTypeahead]="searchSourceSubStation" [resultFormatter]="formatterSourceSubStation" [inputFormatter]="formatterSourceSubStation"
                                                    (focus)="focusSourceSubStationTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="enterGstNoView">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Enter GST Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}enterGstNo" aria-describedby="basic-addon11" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" *ngIf="viewMonitorPerfToggle">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Monitor Delivery Performance</label>

                                            <mat-slide-toggle [(ngModel)]="isMonitorDelPerformance" color="primary" (change)="onMonitorDelPerfToggleChange($event)">
                                            </mat-slide-toggle>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <button type="submit" class="btn btn-primary m-r-10" style='padding: 1px 4px;' id="{{pageId}}getDetailsTableBtn" (click)="getConsignorDetailsList()"><i
											class="fas fa-file-alt"></i>Get Details</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 vl">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                            <!--<div class="col-sm-12 col-md-6">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}getDetailsDD">
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class=" fas fa-check"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option value="1">Consignor Wise</option>
															<option value="2">GST Number Wise</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-6">
												<button type="submit" class="btn btn-primary m-r-10"
													style='padding: 1px 4px;' id="{{pageId}}getDetailsTableBtn"
													(click)="getConsignorDetailsList()"><i
														class="fas fa-file-alt"></i>Get Details</button>
											</div>-->
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}consignorMasterTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsignorMaster" [dtTrigger]="dtTriggerConsignorMaster">

                                                        <thead>
                                                            <tr>
                                                                <th>Consignor Name</th>
                                                                <th>Consignor GSTIN</th>
                                                                <th [hidden]="!hidden">Consignor Address</th>
                                                                <th [hidden]="!hidden">State/City</th>
                                                                <th [hidden]="!hidden">Mobile Number</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let consignorNameMasterData of consignorMasterDataList ">
                                                                <td>{{ consignorNameMasterData.consignorName }}</td>
                                                                <td>{{ consignorNameMasterData.gstNoConsignor }}</td>
                                                                <td [hidden]="!hidden">
                                                                    {{ consignorNameMasterData.address }}</td>
                                                                <td [hidden]="!hidden">
                                                                    {{ consignorNameMasterData.state }}</td>
                                                                <td [hidden]="!hidden">
                                                                    {{ consignorNameMasterData.mobileNumber }}</td>
                                                                <td><button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedGetConsignorDeatils(consignorNameMasterData);">
																		<i title="Consignor Details"
																			class="fas fa-pencil-alt"></i>
																	</button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--</form>-->
            <!-- Row -->
        </div>
    </div>
</div>