import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
//import { DatatableComponent } from '@swimlane/ngx-datatable';
//(DatatableComponent) table: DatatableComponent;
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends
//for drag and drop starts
import { DragulaModule } from 'ng2-dragula';
//for drag and drop ends

import { CommodityRoutes } from 'src/app/master/commodity/commodity.routing';
import { CommodityMasterComponent } from 'src/app/master/commodity/commodity-master/commodity-master.component';
import { TpMasterComponent } from 'src/app/master/commodity/tp-master/tp-master.component';
import { CommodityMergeComponent } from 'src/app/master/commodity/commodity-merge/commodity-merge.component';
import { MaterialModule } from "src/app/material.module";
import { CommodityBookingBlockComponent } from './commodity-booking-block/commodity-booking-block.component';

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( CommodityRoutes ),
         FormsModule,  NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, DragulaModule,MaterialModule],
    declarations: [
        CommodityMasterComponent,
        TpMasterComponent,
        CommodityMergeComponent, CommodityBookingBlockComponent]
} )
export class CommodityModule { }
