<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<!-- <div class="col-md-12 col-sm-12">
	<i title="Reload" class="fas fa-sync-alt fa xs cli"
		style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood; cursor: pointer;"
		(click)="getDetails()"></i>
</div> -->
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Pending Stocks SMS
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}pendingStockSmsTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsPendingStocksSms"
			[dtTrigger]="dtTriggerPendingStocksSms">
			<thead>
				<tr>
					<th>Consignee Name</th>
					<th>Mobile Number</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let pendingStocksSmsData of pendingStocksSmsDataList ">
					<td>{{ pendingStocksSmsData.consigneeName }}</td>
					<td>{{ pendingStocksSmsData.mobileNum }}</td>
					<td><button (click)="openMobile(contentMobile)" type="submit"
							class="btn_custome" id="{{pageId}}sendSmsBtn">Send SMS</button></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>


<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetails">
			<ng-template #contentMobile let-cancel="close" let-d="dismiss">
			<div class="modal-header">
				<h4>Update Mobile Number</h4>
				<button type="button" class="close" aria-label="Close"
					(click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}mobileNumberPopup">
									<label>Mobile Number</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i class="fas fa-phone"></i>
										</span>
									</div>
									<input type="number" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<button type="button" class="btn btn-outline-secondary"
							(click)="openPopup(content)">Send SMS</button>
						<button type="button" class="btn btn-outline-danger"
							(click)="cancel('Cancel click')">Cancel</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetailsTwo">
			<ng-template #content let-no="close" let-d="dismiss"> <!-- 			<div class="modal-header"> -->
			<!-- 				<button type="button" class="close" aria-label="Close" --> <!-- 					(click)="d('Cross click')"> -->
			<!-- 					<span aria-hidden="true">&times;</span> --> <!-- 				</button> -->
			<!-- 			</div> -->
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<h5 class="m-b-10">Sure You Want To Send The SMS?</h5>
						<button type="button" class="btn btn-outline-secondary"
							(click)="openModalSuccess(contentSuccess)">Yes</button>
						<button type="button" class="btn btn-outline-danger"
							(click)="no('No click')">No</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetailsThree">
			<ng-template #contentSuccess let-ok="close" let-d="dismiss">
			<!-- 			<div class="modal-header"> --> <!-- 				<button type="button" class="close" aria-label="Close" -->
			<!-- 					(click)="d('Cross click')"> --> <!-- 					<span aria-hidden="true">&times;</span> -->
			<!-- 				</button> --> <!-- 			</div> -->
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<h5 class="m-b-10">SMS send Successfully!!!</h5>
						<button type="button" class="btn btn-outline-secondary"
							(click)="ok('Ok click')">Ok</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>