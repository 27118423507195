<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Expenses Details Entry</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3 lineBottom_custom">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">



											<h6 style="padding: 10px;" class="card-title">Bill
												Details</h6>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Bill Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div>
														<input #billNumber type="text" id="{{pageId}}billNumber" name="billNumber"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Bill Date</label> <input class="form-control"
															id="{{pageId}}billDate" placeholder="yyyy-mm-dd" name="billDates"
															[(ngModel)]="modelBillDates" ngbDatepicker
															#billDates="ngbDatepicker">
														<div class="input-group-append" (click)="billDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>


											<!-- 											the first autocomplete starts -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Vendor Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text">
																<i class="ti-user"></i>
															</span>
														</div>
														<input #vendorName id="{{pageId}}vendorName" name="vendorName" type="text"
															class="form-control" [(ngModel)]="modelVendor"
															[ngbTypeahead]="searchVendor"
															(selectItem)="rowSelectedVendorName($event)"
															[resultFormatter]="formatterVendor"
															[inputFormatter]="formatterVendor"
															(focus)="focusVendorTA$.next($any($event).target.value)"
															placeholder="Select Vendor" />
													</div>
												</div>
											</div>
											<div *ngIf="showNewVendorName" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>New Vendor Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
														</div>
														<input type="text" #newVendorName id="{{pageId}}newVendorName"
															new="newVendorName" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<!-- 											the first autocomplete ends -->


											<hr>
											<h6 style="padding: 10px;" class="card-title">Item
												Details</h6>

											<!-- 											the second autocomplete starts  -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Item Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text">
																<i class="ti-user"></i>
															</span>
														</div>
														<input #itemName id="{{pageId}}itemName" name="itemName" type="text"
															class="form-control" [(ngModel)]="modelItem"
															[ngbTypeahead]="searchItem"
															(selectItem)="rowSelectedItemName($event)"
															[resultFormatter]="formatterItem"
															[inputFormatter]="formatterItem"
															(focus)="focusItemTA$.next($any($event).target.value)"
															placeholder="Select Item" />
													</div>
												</div>
											</div>
											<div *ngIf="showNewItemName" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>New Item Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
														</div>
														<input #newItemName type="text" id="{{pageId}}newItemName"
															[(ngModel)]="setNewName" name="newItemName"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<!-- 											the second autocomplete ends -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Quantity</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
														</div>
														<input #quantity type="number" id="{{pageId}}quantity" name="quantity"
															(keyup)="calculateAmount($event)" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<!-- 											the third autocomplete starts  -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Unit</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}unit" name="unit"
															#unit>
															<option selected value="Liters">Liters
															</option>
															<option value="Nos">Nos</option>

														</select>
													</div>
												</div>
											</div>
											<!-- 											the third autocomplete ends -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Rate</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input #rate type="number" id="{{pageId}}rate" name="rate"
															(keyup)="calculateAmount($event)" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Amount</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input #amount type="number" id="{{pageId}}amount" name="amount"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12" style="text-align: center; padding: 10px">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}nextItemBtn"
								(click)="validateAddInTable();">Next Item</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="card-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="row p-t-10">

								<div class="col-md-6">
									<h6 class="card-title">Particular Details</h6>


								</div>
								<div class="col-md-6">


								</div>
							</div>
							<div class="box-body">

								<table datatable class="table table-striped table-bordered row-border hover"
									id="{{pageId}}expensesDetailsEntryId" [dtOptions]="dtOptionsExpensesDetailsEntry"
									[dtTrigger]="dtTriggerExpensesDetailsEntry">


									<thead>
										<tr>
											<th>Particulars</th>
											<th>Quantity</th>
											<th>Unit</th>
											<th>Rate</th>
											<th>Amount</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let expensesDetailsEntryData of expensesDetailsEntryDataList  let i = index ">
											<td>{{ expensesDetailsEntryData.particulars }}</td>
											<td>{{ expensesDetailsEntryData.quantity }}</td>
											<td>{{ expensesDetailsEntryData.unit }}</td>
											<td>{{ expensesDetailsEntryData.rate }}</td>
											<td>{{ expensesDetailsEntryData.amount }}</td>
											<td>
												<i style=" cursor: pointer;" class="fas fa-pencil-alt"
													title="Edit Particlar Details"
													(click)="rowSelectedParticularDataOnEdit(expensesDetailsEntryData,i);"></i>
												&nbsp;&nbsp;
												<i style=" cursor: pointer;" class="fas fa-trash"
													title="Delete Particlar Details"
													(click)="deleteSelectedParticularDetails(expensesDetailsEntryData,i);"></i>
											</td>
										</tr>

									</tbody>
									<tfoot>
										<tr>
											<td style="text-align: left">{{countOfParticulars}}</td>
											<td></td>
											<td></td>
											<td></td>
											<td>{{sumOfParticulars}}</td>
											<td></td>
										</tr>
									</tfoot>
								</table>
							</div>

						</div>

						<div class="row" style="padding: 10px;">
							<div class="col-md-12">
								<h6 style="padding: 5px;" class="card-title">Total</h6>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<div class="input-group">
										<label>Grand Total</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
											</span>
										</div>
										<input type="number" id="{{pageId}}grandTotal" class="form-control"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
							<div class="col-md-4" style="text-align: center;">
								<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}finishBillhBtn"(click)="validateFinalBtn()">Finish
									Bill</button>
								<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearAllFields()">Clear</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>