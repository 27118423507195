<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}localTriSheetPopupId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">DOOR DELIVERY - Trip Sheet Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <!--                         <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintLocalTripSheetLrDetPopup()" id="{{pageId}}printAllBtn"> -->
                        <!-- 							<span><i class="fas fa-print">Print</i></span> -->
                        <!-- 						</button> -->
                        <div class="box-body">
                            <table datatable id="{{pageId}}localTripSheetLRPopUpTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTripSheetLRDetails" [dtTrigger]="dtTriggerTripSheetLRDetails">

                                <thead>
                                    <tr>
                                        <th>LR.NO</th>
                                        <th>MEMO NO</th>
                                        <th>SRC</th>
                                        <th>CONSIGNEE</th>
                                        <th>CONSIGNOR</th>
                                        <th>ART</th>
                                        <th>HAMALI GD</th>
                                        <th>HAMALI DD</th>
                                        <th>ACT.WGT</th>
                                        <th>CHG.WGT</th>
                                        <th>LR DD AMT</th>
                                        <th>BKNG DATE</th>
                                        <th>LDNG DATE</th>
                                        <th>UNLDNG DATE</th>
                                        <th>DISP W.IN DAYS</th>
                                        <th>ARR W.IN DAYS</th>
                                        <th>DELV W.IN DAYS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let localTripSheetLRRptData of localTripSheetLRRptDataList let i = index ">
                                        <td>{{localTripSheetLRRptData.lrNumber }}</td>
                                        <td>{{ localTripSheetLRRptData.memoNumber }}</td>
                                        <td>{{localTripSheetLRRptData.source }}</td>
                                        <td>{{localTripSheetLRRptData.consigneeName }}</td>
                                        <td>{{localTripSheetLRRptData.consignorName }}</td>
                                        <td>{{ localTripSheetLRRptData.totalArticles }}</td>
                                        <td>{{localTripSheetLRRptData.hamaliGD }}</td>
                                        <td>{{localTripSheetLRRptData.hamaliDD }}</td>
                                        <td>{{ localTripSheetLRRptData.actualWeight }}</td>
                                        <td>{{localTripSheetLRRptData.chargedWeight }}</td>
                                        <td>{{localTripSheetLRRptData.ddAmt }}</td>
                                        <td>{{ localTripSheetLRRptData.bookingDateStr }}</td>
                                        <td>{{localTripSheetLRRptData.loadingDateStr }}</td>
                                        <td>{{ localTripSheetLRRptData.unloadingDateStr }}</td>
                                        <td>{{localTripSheetLRRptData.dispatchedwithin }}</td>
                                        <td>{{ localTripSheetLRRptData.arrivalwithin }}</td>
                                        <td>{{localTripSheetLRRptData.deliveredwithin }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <!--POP UP START HERE-->
    <!--<div class="col-md-12">
			<div class="form-group">
				<div class="input-group" id="{{pageId}}">
					<ng-template #localTripSheetPopUpTemplate let-ok="close" let-d="dismiss">
						<div class="modal-header">
							<h5>Memo Less Description :</h5>
							<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<table datatable id="{{pageId}}localTripSheetLRPopUpTableId"
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsTripSheetLRDetails"
													[dtTrigger]="dtTriggerTripSheetLRDetails">
	
													<thead>
														<tr>
															<th>LR.No</th>
															<th>Memo Number</th>
															<th>Source</th>
															<th>CNSGNE NAME</th>
															<th>CNSGNR NAME</th>
															<th>ART</th>
															<th>HAMALI GD</th>
															<th>AC. WT</th>
															<th>CH. WT</th>
															<th>BKNG DT</th>
															<th>LDNG DT</th>
															<th>UNLDNG DT</th>
															<th>DISP W.IN</th>
															<th>ARR W.IN</th>
															<th>DELV W.IN</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let memoLessDescList of localTripSheetLRRptDataList let i = index ">
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
															<td>{{ memoLessDescList.memoNumber }}</td>
															<td>{{memoLessDescList.memoNumber }}</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</div><br>
										<div class="row">
											<div class="col-sm-12 col-md-2">
												<button style="float: right;" type="button" class="btn btn-dark"
													id="{{pageId}}cancelBtnId" >Cancel
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>-->

    <!--     <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1"> -->
    <!--         <app-custom-dynamic-printV1></app-custom-dynamic-printV1> -->
    <!--     </div> -->
</body>

</html>