<!-- <div class="row" style="text-align: center!important;">
	<div class="col-md-12">
		<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}generateBarcodeBtn" #generateBarcodeBtn
			(click)="uniqueCodeGenerator()">Generate Barcode</button>
	</div>
	<div class="col-md-12" id="{{pageId}}lrentryFormBarcodePrint"
		style='width:100%!important;height: auto;'>
		<table border='0' cellspacing='0' cellpadding='0'>
			<tr *ngFor="let barcodeObjectParent of PrintBarcodeList; let i = index" style="padding-top: 15px;">
				<td *ngFor="let barcodeObject of barcodeObjectParent" valign='top'
					style='vertical-align: top;margin-top: 0px;'>
					<p>
						<span style='font-size: 4.3rem;text-transform:uppercase;font-weight: bold;'>SRD Logistics Pvt.
							Ltd</span>
					</p>
					<br>
					<ngx-barcode6 style='margin-top: 0px;' [bc-format]="'CODE128'" [bc-width]="7" [bc-height]="120"
						[bc-font-size]="20" [bc-value]="barcodeObject.barcodeValue" [bc-display-value]="false">
					</ngx-barcode6>

					<br>
					<p>
						<span style='font-size: 3.3rem;font-weight: bold;'>{{barcodeObject.barcodeValue}}</span>
					</p>

				</td>
			</tr>

		</table>

	</div>
</div> -->
<div class="row" style="text-align: center!important;">
	<div class="col-md-12">
		<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}generateBarcodeBtn" #generateBarcodeBtn
			(click)="uniqueCodeGenerator()">Generate Barcode</button>
	</div>
</div>

<div class="row" id="{{pageId}}lrentryFormBarcodePrint"
	style='text-align: center!important;width:100%!important;height: auto;padding-top: 15px;'>
	<div class="col-md-4" *ngFor="let barcodeObjectParent of PrintBarcodeList; let i = index" valign='top'
		style='vertical-align: top;margin-top: 0px;'>
		<span *ngFor="let barcodeObject of barcodeObjectParent" valign='top'
			style='vertical-align: top;margin-top: 0px;'>
			<span style='font-size: 1.6rem;text-transform:uppercase;font-weight: bold;'>SRD Logistics Pvt.
				Ltd</span>
			<br>
			<ngx-barcode6 style='margin-top: 0px;' [bc-format]="'CODE128'" [bc-width]="4" [bc-height]="90"
				[bc-font-size]="15" [bc-value]="barcodeObject.barcodeValue" [bc-display-value]="false">
			</ngx-barcode6>
			<span style='font-size: 2rem;font-weight: bold;'>{{barcodeObject.barcodeValue}}</span>
		</span>
		<br>
		<br>
		<br>
	</div>
</div>