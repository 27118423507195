<html>

<head>
</head>

<body>
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showCommodityRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-question"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewReportType" name="viewReportType" #viewReportType (change)="viewReportTypeMode(viewReportType.value)">
                                                            <option selected value="old">OLD</option>
                                                            <option value="new">Rate Commodity</option>
                                                            <option value="actual">Actual Commodity</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-question"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
                                                            <option selected value="summary">Summary</option>
                                                            <option value="detail">Detail</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" id="{{pageId}}fromDates" ngbDatepicker #fromDates="ngbDatepicker" [(ngModel)]="fromDatesModal" autocomplete="off">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideshowDropDownSourceField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideshowDropDownDestinationField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity List</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                            [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="validateClearBtn();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <button type="submit" class="dt-button" style="margin-left: 74%;" [hidden]="!hideshowCommoditySummaryRptTable" (click)="customPrintCommodityRptSummary();" id="{{pageId}}printAllBtn">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 74%;" [hidden]="!hideshowCommodityDetailsRptTable" (click)="customPrintCommodityRptDetails();" id="{{pageId}}printAllBtn">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <div class="box-body" [hidden]="!hideshowCommoditySummaryRptTable">
                            <table datatable id="{{pageId}}commoditySummaryRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">
                                <thead>
                                    <tr>
                                        <th>Booking Item</th>
                                        <th>Lrs</th>
                                        <th>Art</th>
                                        <th>Actual Weight</th>
                                        <th>Booking Perc on Act Wt</th>
                                        <th>Charged Weight</th>
                                        <th>Booking Perc on Chg Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>F O V</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let smryData of summaryDataList ">
                                        <td>{{ smryData.column2 }}</td>
                                        <td>{{ smryData.totalLrs }}</td>
                                        <td>{{ smryData.totalArticles }}</td>
                                        <td>{{ smryData.actualWeight }}</td>
                                        <td>{{ smryData.actWtPercent }}</td>
                                        <td>{{ smryData.chargedWeight }}</td>
                                        <td>{{ smryData.chgWtPercent }}</td>
                                        <td>{{ smryData.toPay }}</td>
                                        <td>{{ smryData.paid }}</td>
                                        <td>{{ smryData.riskCharge }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body" [hidden]="!hideshowCommodityDetailsRptTable">
                            <table datatable id="{{pageId}}commodityDetailsRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
                                <thead>
                                    <tr>
                                        <th hidden=true>Main Commodity</th>
                                        <th>Booking Item</th>
                                        <th>Lrs</th>
                                        <th>Art</th>
                                        <th>Actual Weight</th>
                                        <th>Booking Perc on Act Wt</th>
                                        <th>Charged Weight</th>
                                        <th>Booking Perc on Chg Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>FOV</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detailData of detailDataList ">
                                        <td hidden=true>{{ detailData.mainCommodity }}</td>
                                        <td>{{ detailData.subCommodity }}</td>
                                        <td>{{ detailData.totalLrs }}</td>
                                        <td>{{ detailData.totalArticles }}</td>
                                        <td>{{ detailData.actualWeight }}</td>
                                        <td>{{ detailData.actWtPercent }}</td>
                                        <td>{{ detailData.chargedWeight }}</td>
                                        <td>{{ detailData.chgWtPercent }}</td>
                                        <td>{{ detailData.toPay }}</td>
                                        <td>{{ detailData.paid }}</td>
                                        <td>{{ detailData.riskCharge }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td hidden=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
        <app-commodity-report-details-print></app-commodity-report-details-print>
    </div>
</body>

</html>