<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showCommodityMisMachRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Commodity Mismatch Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															id="{{pageId}}fromDates" #fromDates="ngbDatepicker"
															[(ngModel)]="fromDatesModal" autocomplete="off">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker" [(ngModel)]="toDatesModal"
															autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text" class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="validateSearchBtn();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearBtn();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
										
								</div>
							</div>
						</div>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
												(click)="customPrintcomdtymismatchRpt()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}commodityMismatchRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsCommodityMismatchReport"
								[dtTrigger]="dtTriggerCommodityMismatchReport">
								<thead>
									<tr>
										<th>LR No</th>
										<th>Source</th>
										<th>Cash Memo No</th>
										<th>Consignee Name/Consignee</th>
										<th>LR Commodity</th>
										<th>Memo Commodity</th>
										<th>Act WT</th>
										<th>Chg WT</th>
										<th>Art</th>
										<th>Discount</th>
										<th>Memo Total</th>
										<th>Source User</th>
										<th>Des User</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let commodityMismatchReportData of commodityMismatchReportDataList ">
										<td>{{ commodityMismatchReportData.lrNumber }}</td>
										<td>{{ commodityMismatchReportData.source }}</td>
										<td>{{ commodityMismatchReportData.cashMemoNumber }}</td>
										<td>{{ commodityMismatchReportData.consigneeName }}</td>
										<td>{{ commodityMismatchReportData.commodityName }}</td>
										<td>{{ commodityMismatchReportData.desCommodityName }}</td>
										<td>{{ commodityMismatchReportData.actualWeight }}</td>
										<td>{{ commodityMismatchReportData.chargedWeight }}</td>
										<td>{{ commodityMismatchReportData.totalArticles }}</td>
										<td>{{ commodityMismatchReportData.discountAmount }}</td>
										<td>{{ commodityMismatchReportData.grandTotal }}</td>
										<td>{{ commodityMismatchReportData.srcUserName }}</td>
										<td>{{ commodityMismatchReportData.destUserName }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>