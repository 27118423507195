<div mat-dialog-content>
	<div class="row">

		<div class="col-sm-12 col-md-8">
			<div class="form-group">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text"> <i class="fas fa-search"></i>
						</span>
					</div>
					<input type="text" class="form-control" id="{{pageId}}multipleMobileNoConsignee"
						aria-describedby=" basic-addon11 " autocomplete="off" />
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="form-group">
				<div class="input-group">
					<a href="javascript:;" type='button' #addBtn style="padding: 1px 4px;"
						class="btn btn-icon-only yellow" (click)="addConsigneeMobileNoBtn();">
						<i class="fa fa-plus" title="Clear"></i>
					</a>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="form-group">
				<div class="input-group">
					<a href="javascript:;" type='button' #addBtn style="padding: 1px 4px;"
						class="btn btn-icon-only yellow" (click)="clearFieldBtn();">
						<i class="fa fa-times" title="Clear"></i>
					</a>
				</div>
			</div>
		</div>
	</div>


	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}consigneeMobileNoTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsConsigneeMultipleMobileNo"
								[dtTrigger]="dtTriggerConsigneeMultipleMobileNo">
								<thead>
									<tr>
										<th>Mobile No</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let gridConsigneeMobileNo of gridConsigneeMobileNoList let i = index">
										<td>{{ gridConsigneeMobileNo.mobileNo }}</td>
										<td><button style="padding: 1px 4px; background-color: #ffffff00;"
												class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
												(click)="deleteRowSelectedMobileNo(gridConsigneeMobileNo,i)">
												<i title="Remove" class="fas fa-trash"></i>
											</button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>