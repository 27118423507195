import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LRDto } from 'src/app/dto/LR-dto';
import { serverUrl } from "src/environments/environment";



@Injectable({
    providedIn: 'root'
})
export class LrReportService {
    //for Local
    //        private _baseUrl = "http://localhost:8080/srd/report/v1";
    //for QA
    //    private _baseUrl = "http://54.210.51.40:8080/srd/report/v1";
    //for Live
    //        private _baseUrl = "http://3.222.201.242:8080/srd/report/v1";

    private _baseUrl = serverUrl.webLrReport;

    constructor(
        private http: HttpClient,
    ) {
    }

    getLrDetailsForMultiplePrt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsForMultiplePrtApi`, lrDto);
    }

    getLRsShortExtraDtlsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRsShortExtraDtlsRptApi`, lrDto);
    }

    getLrHistoryRptDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrHistoryRptDetailsApi`, lrDto);
    }

    getBkgLrDisPatchRptService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBkgLrDisPatchRptServiceApi`, lrDto);
    }

    //GodownStockBkgRpt
    getGodownStocksRptBookingSummary(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getGodownStocksRptBookingSummaryApi`, lrDto);
    }
    getGodownStocksRptBookingDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getGodownStocksRptBookingDetailsApi`, lrDto);
    }

    getLrDetailsLCandBCSummary(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsLCandBCSummaryApi`, lrDto);
    }

    getLrDetailsLCandBCDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsLCandBCDetailsApi`, lrDto);
    }

    getOSStocksSummary(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getOSStocksSummaryApi`, lrDto);
    }

    getOSStocksDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getOSStocksDetailsApi`, lrDto);
    }

    getOsStocksPartyDetailsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getOsStocksPartyDetailsReportApi`, lrDto);
    }
    getPendingStocksDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPendingStocksDetailsApi`, lrDto);
    }

    getStorageStockRpt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStorageStockRptApi`, lrDto);
    }

    //Booking Agent Dispatch Performance Rpt Start
    getBookingAgentDispatchPeformanceSummaryRpt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingAgentDispatchPeformanceSummaryApi`, lrDto);
    }

    getBookingAgentDispatchPeformanceDetailsRpt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingAgentDispatchPeformanceDetailsApi`, lrDto);
    }
    //Booking Agent Dispatch Performance Rpt End

    //Service Tax Rpt Start

    getBookingServiceTaxBookingReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingServiceTaxBookingApi`, lrDto);
    }

    getBookingServiceTaxDestReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingServiceTaxDestApi`, lrDto);
    }

    getBookingServiceTaxSubstationReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingServiceTaxSubstationApi`, lrDto);
    }
    //Service Tax Rpt End

    getLRStatus(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRStatusApi`, lrDto);
    }
    getStockCheckReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStockCheckReportApi`, lrDto);
    }

    updateStockCheck(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateStockCheckApi`, lrDto);
    }

    getLROfSpecificStation(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLROfSpecificStationApi`, lrDto);
    }

    godownTransfer(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/godownTransferApi`, lrDto);
    }
    //Stock to cp
    getCPStockReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCPStocksApi`, lrDto);
    }

    updateCPStocks(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateCPStocksApi`, lrDto);
    }

    addStocksToCp(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addStocksToCPApi`, lrDto);
    }

    getLREnquiryAdvanceDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLREnquiryAdvanceDetailsApi`, lrDto);
    }

    getDetailsForValidityExtensionDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDetailsForValidityExtensionDetailsApi`, lrDto);
    }

    getMethodForExtensionHistoryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMethodForExtensionHistoryDetailsApi`, lrDto);
    }

    updateEwayBillExpiryDate(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEwayBillExpiryDateApi`, lrDto);
    }

    ignoreEwayBillExpiryDate(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/ignoreEwayBillExpiryDateApi`, lrDto);
    }

    getLorryHireStmtRpt(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLorryHireStmtRptApi`, hireSlipDto);
    }

    getFinalNoticeEmailDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getFinalNoticeEmailDetailsServiceApi`, lrDto);
    }

    getPendingStocksSMS(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPendingStocksSMSApi`, lrDto);
    }

    getUndeliveredDDLrs(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUndeliveredDDLrsApi`, lrDto);
    }

    getTruckNonAvlRptDtls(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTruckNonAvlRptDtlsApi`, lrDto);
    }

    getTruckNonAvlRptDtlsForSMS(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getTruckNonAvlRptDtlsForSMSApi`, lrDto);
    }

    updateMultiEwayBillExpiryDate(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateMultiEwayBillExpiryDateApi`, lrDto);
    }

    updateMultiEwayBillExpiryDateV2(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateMultiEwayBillExpiryDateDaoV2Api`, lrDto);
    }
    getBookingServiceTaxBookingLessDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingServiceTaxBookingLessDetailsApi`, lrDto);
    }

    getSrcLREntryRateCheckReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSrcLREntryRateCheckReportApi`, lrDto);
    }

    getMethodForAutoExtensionHistoryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMethodForAutoExtensionHistoryDetailsApi`, lrDto);
    }

    
}

