import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';

@Component({
    selector: 'app-incharge-master',
    templateUrl: './incharge-master.component.html',
    styleUrls: ['./incharge-master.component.css']
})
export class InchargeMasterComponent implements OnInit {

    dtTriggerInchargeMaster: Subject<any> = new Subject();
    dtOptionsInchargeMaster: any;
    pageId = "inmc";
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDtoSave: LRDto = new LRDto();
    enteredInchargeName = null;
    enteredMobileNumber = null;
    inchargeMasterDataList: LRDto[];

    constructor(private masterService: MasterService, private router: Router,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getInchargeDetailsMethod();
        }
    }

    ngOnInit(): void {
        this.dtOptionsInchargeMaster = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngOnDestroy(): void {
        //this.dtTriggerInchargeMaster.unsubscribe();
    }
    // ngAfterViewInit(): void {
    //     this.dtTriggerInchargeMaster.next();
    // }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateSetupDetails() {
        swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.saveSetupDetails();
            }
        })
    }
    userSetupDetails() {
        this.lrDtoSave = new LRDto();
        this.enteredInchargeName = $("#" + this.pageId + "inchargeName").val();
        this.enteredMobileNumber = $("#" + this.pageId + "mobileNumber").val();
        this.lrDtoSave.inchargeName = this.enteredInchargeName;
        this.lrDtoSave.mobileNum = this.enteredMobileNumber;
        this.lrDtoSave.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSave.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
    }

    saveSetupDetails = () => {
        this.userSetupDetails();
        this.masterService.setupInchargeDetails(this.lrDtoSave).
            subscribe((data) => {
                if (data.status == "Success") {
                    swal("Success", "Incharge details saved/updated successfully!", "info");
                    this.clearFields();
                    this.getInchargeDetailsMethod();
                } else {
                    swal("Failed", "Failed to save/update the Incharge details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the Incharge details!", "error");
            },
            () => console.log('done');
    }

    getInchargeDetailsData() {
        this.lrDtoSave = new LRDto();
        this.lrDtoSave.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
    }
    getInchargeDetailsMethod = () => {
        this.getInchargeDetailsData();
        this.masterService.getInchargeDetails(this.lrDtoSave).subscribe(
            (response) => {
                if (response) {
                    this.inchargeMasterDataList = response;
                }
                this.dtTriggerInchargeMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Incharge details", "info");
            },
            () => console.log('done');
    }

    clearFields() {
        this.enteredInchargeName = null;
        this.enteredMobileNumber = null;
        $("#" + this.pageId + "inchargeName").val('');
        $("#" + this.pageId + "mobileNumber").val('');
    }

}

