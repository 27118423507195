<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">SRD Labour Statement
						Generation</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<h6 class="card-title">Statement Period</h6>
										<div class="row">


											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Period</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDate" ngbDatepicker
															id="{{pageId}}fromDate" #fromDates="ngbDatepicker"
															autocomplete="off" [readonly]="!fromDateReadOnly">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Period</label> <input id="{{pageId}}toDate"
															class="form-control" placeholder="yyyy-mm-dd"
															name="toDate" ngbDatepicker #toDates="ngbDatepicker"
															autocomplete="off"
															(click)="toDates.toggle($event);validateToPeriodCalendar($event);">
														<div class="input-group-append"
															(click)="toDates.toggle($event);validateToPeriodCalendar($event);">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" (click)="validateGenerateBtn()"
								id="{{pageId}}generateStmtBtn" [disabled]="!generateBtnReadOnly"
								class="btn btn-success m-r-10">Generate</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<h6 class="card-title border_bottom">List of Loader Heads</h6>

						<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 15px;">Please
										Wait Loading....</h6>
								</div>
							</div>
						</div>
						<div class="box-body">
							<table datatable id="{{pageId}}labourStmtTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLabourStmtGeneration"
								[dtTrigger]="dtTriggerLabourStmtGeneration">

								<thead>
									<tr>
										<th>Loader Head</th>
										<th>Current Stmt From Date</th>
										<th>Current Stmt To Date</th>
										<th>Current Stmt Date</th>
										<th>Current Stmt No</th>
									</tr>
								</thead>
								<tbody>
									<tr style='cursor: pointer;'
										*ngFor="let labourStmtGenerationData of labourStmtGenerationDataList"
										(click)="selectedRowSetStmtGenDetails(labourStmtGenerationData);">
										<td>{{ labourStmtGenerationData.loaderName }}</td>
										<td>{{ labourStmtGenerationData.fromDate}}</td>
										<td>{{ labourStmtGenerationData.toDate }}</td>
										<td>{{ labourStmtGenerationData.stmtDateStr }}</td>
										<td>{{ labourStmtGenerationData.fortNightStmtNo }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>