import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject, Subscription } from 'rxjs';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { StockService } from 'src/app/dataService/stock-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-scheduled-trips-from',
    templateUrl: './scheduled-trips-from.component.html',
    styleUrls: ['./scheduled-trips-from.component.css']
})
export class ScheduledTripsFromComponent implements OnInit {

    scheduledTripsFromDataList: any;
    onDestroyUnsubscribtionScheduledTripsFrom: Subscription;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerScheduledTripsFrom: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsScheduledTripsFrom: any;
    hireSlipDto: HireSlipDto = new HireSlipDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    hireSlipDtoForSelectedRow: HireSlipDto = new HireSlipDto();
    hireSlipDtoForScheduleTripFromLocalStorage: any;
    pageId = "sctfc";
    consolidateEwaybillEnabled: boolean = false;
    requestDto: RequestDto = new RequestDto();

    constructor(private dashboardService: DashboardService, private router: Router,
        private stockService: StockService, public changeDetectorRef: ChangeDetectorRef, private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGeneration != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGeneration) {
                this.consolidateEwaybillEnabled = true;
            }
        }
    }

    ngOnInit(): void {
        this.dtOptionsScheduledTripsFrom = {
            dom: 'Bfrtip',
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerScheduledTripsFrom.next();
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerScheduledTripsFrom.unsubscribe();
    //     //this.onDestroyUnsubscribtionScheduledTripsFrom.unsubscribe();
    // }

    gridReconifgureOnReloadBtn() {
        this.getScheduledTripsFromDetailsList();
    }
    getScheduledTripsDetails() {
        this.hireSlipDto = new HireSlipDto();
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.status = "Scheduled";
        this.hireSlipDto.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDto.mode = "BMDB";
        this.hireSlipDto.role = this.userDataDtoReturnSession.role;
        this.hireSlipDto.stocksAt = this.userDataDtoReturnSession.stationType;
        this.hireSlipDto.officeType = this.userDataDtoReturnSession.officeType;
        this.hireSlipDto.type = "Source";
        this.hireSlipDto.fromstation = "Scheduled Trips Source";
    }

    getScheduledTripsFromDetailsList() {
        this.getScheduledTripsDetails();
        this.showSpinnerForAction = true;
        this.dashboardService.getScheduledTripsDetails(this.hireSlipDto).subscribe(
            (response) => {
                console.log(response);
                this.scheduledTripsFromDataList = [];
                //$("#"+this.pageId+"scheduleTripFromTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.scheduledTripsFromDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerScheduledTripsFrom.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Schedule Trip From Details", "warning");
            },
            () => console.log('done');
    };

    getSelectedRowOfHireslipDetails(scheduledTripsFromData) {
        this.hireSlipDtoForSelectedRow = new HireSlipDto();
        this.hireSlipDtoForSelectedRow.hireslipnumber = scheduledTripsFromData.hireslipnumber;
        this.hireSlipDtoForSelectedRow.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForSelectedRow.companyid = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.stockService.getHireSlipDetails(this.hireSlipDtoForSelectedRow).subscribe(
            (response) => {
                console.log(response.mode);
                console.log(response.type);
                if (response.status != null &&
                    response.status != "Scheduled") {
                    swal("Not Allowed", "This Invoice is in " + response.status +
                        " .Please Click the Refresh Symbol Below For Updates", "warning");
                } else {
                    this.hireSlipDtoForScheduleTripFromLocalStorage = null;
                    this.hireSlipDtoForScheduleTripFromLocalStorage = response;
                    if (this.userDataDtoReturnSession.role == "Administrator") {
                        this.hireSlipDtoForScheduleTripFromLocalStorage.mode = "view";
                    } else {
                        this.hireSlipDtoForScheduleTripFromLocalStorage.mode = "update";
                    }
                    this.hireSlipDtoForScheduleTripFromLocalStorage.type = "Source";
                    localStorage.clear();
                    localStorage.setItem('scheduledTripFromLocalStorage',
                        JSON.stringify(this.hireSlipDtoForScheduleTripFromLocalStorage));
                    this.router.navigate(['/trip-creation/stocksGrouping']);
                    console.log(localStorage);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Invoice Details", "warning")
            }, () => console.log('done');
    }

    refreshFTConsentStatus(scheduledTripsFromData) {
        this.hireSlipDtoForSelectedRow = new HireSlipDto();
        this.hireSlipDtoForSelectedRow.hireslipnumber = scheduledTripsFromData.hireslipnumber;
        this.hireSlipDtoForSelectedRow.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForSelectedRow.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoForSelectedRow.driverMobileNumber = scheduledTripsFromData.driverMobileNumber;
        this.showSpinnerForAction = true;
        this.dashboardService.refreshFTConsentStatus(this.hireSlipDtoForSelectedRow).subscribe(
            (response) => {
                if (response.status != null &&
                    response.status == "NoMobile") {
                    swal("Invalid Mobile Number", "Please update the correct Driver Mobile Number and try again!", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the consent details", "warning")
            }, () => console.log('done');
    }

    confirmGenerateConsolidateEwaybill(scheduledTripsFromData) {
        swal({
            title: "Confirm Generate",
            text: "Sure U Want to generate the Consolidate Eway Bill for the selected Hireslip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.generateConsolidateEwaybill(scheduledTripsFromData);
            } else {
                swal.close();
            }

        })
    }

    generateConsolidateEwaybill(scheduledTripsFromData) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = scheduledTripsFromData.hireslipnumber;
        this.showSpinnerForAction = true;
        this.ewaybillService.generateConsolidateEwaybill(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Completed", "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo, "info");
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1);
                    this.getScheduledTripsFromDetailsList();
                } else {
                    swal("Failed", "Failed to generate the Consolidated Eway bill for Hireslip No : " + scheduledTripsFromData.hireslipnumber + ". Please try again or contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 5 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    printCEWB(eWayBillNo) {
        if (eWayBillNo == null) {
            swal("Not Allowed", "Consolidated Eway Bill not found for this Trip, please verify it!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadConsolidatedEWBPdf(eWayBillNo, response.token1);
                    } else {
                        swal("Failed", "Failed to generate the token for Consolidated Eway Bill print request, please contact Admin!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to generate the token for Consolidated Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }

}
