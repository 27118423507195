import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { LRDto } from 'src/app/dto/LR-dto';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Router } from "@angular/router";
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import *  as moment from 'moment';

@Component({
    selector: 'app-destination-commission',
    templateUrl: './destination-commission.component.html',
    styleUrls: ['./destination-commission.component.css']
})
export class DestinationCommissionComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number; month: number };

    loadingIndicator = true;
    reorderable = true;
    fromDate: any;
    toDate: any;
    destination: any;
    showSpinnerForAction: any;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    // fromDate: NgbDateStruct;
    // toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    destAgentCommissionDetailsReportDataList: any;
    onDestroyUnsubscribtionDestAgentCommissionDetailsReport: Subscription;
    lrDtoToStn: LRDto = new LRDto();
    lrDtoToStnAll: LRDto = new LRDto();
    fortNgtSearchDto: FortNightStmtDto = new FortNightStmtDto();
    userDataDtoReturnSession: any;
    lrDtosToStnOptionsGet: any;
    isLoggedIn = true;
    actualWeight: number;
    freight: number;
    receivableAmount: number;
    commission: number;
    commissionReduced: number;
    actualCommissionPaid: number;


    



    controlMain = new FormControl();

    destinationOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'Alleppey' },
        { id: 3, label: 'Alwaye' },
        { id: 4, label: 'Aurangabad' }
    ];

    lrDtoDestinationStationAllOption: LRDto = new LRDto();
    destinationStationOptions: LRDto[];
    lrDtoDestinationStation: LRDto = new LRDto();

    public modelDestination: any;
    destinationSubStationNameTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSubStationNameTA
                : this.destinationSubStationNameTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;





    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDestAgentCommissionDetailsReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsDestAgentCommissionDetailsReport: any;
    pageId = "desco";

    

    constructor(private destAgentCommissionDetailsReport: MasterService, public changeDetectorRef: ChangeDetectorRef,
        private masterReadService: MasterReadService,private router: Router) {
            if (sessionStorage.length == 0) {
                this.isLoggedIn = false;
                swal({
                    title: "Session Expired",
                    text: "Please relogin to access the application!",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    this.logInPage();
                })
            }
            if (this.isLoggedIn) {
                this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                this.gettingToStationList();
            }


    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        this.dtOptionsDestAgentCommissionDetailsReport = {
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var actualWeight = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var freight = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var receivableAmount = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var commission = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var commissionReduced = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actualCommissionPaid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(actualWeight);
                $(api.column(6).footer()).html(freight);
                $(api.column(7).footer()).html(receivableAmount);
                $(api.column(8).footer()).html(commission);
                $(api.column(9).footer()).html(commissionReduced);
                $(api.column(10).footer()).html(actualCommissionPaid);
            },



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
           
        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.destAgentCommissionDetailsReport.getSummaryData()
        this.onDestroyUnsubscribtionDestAgentCommissionDetailsReport = this.getDataFrmServiceFrTable.subscribe(data => {
            this.destAgentCommissionDetailsReportDataList = data['data'];
            this.dtTriggerDestAgentCommissionDetailsReport.next();
        });


    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerDestAgentCommissionDetailsReport.unsubscribe();

        this.onDestroyUnsubscribtionDestAgentCommissionDetailsReport.unsubscribe();
    }

    gettingToStationDetails() {
        this.lrDtoToStn = new LRDto();
        this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStn.mode = "other";
        this.lrDtoToStn.reportMode = "subStation";
        console.log(this.lrDtoToStn);

    }
    gettingToStationList = () => {
        // this.showSpinnerForAction = true;
        this.gettingToStationDetails();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoToStn).subscribe(
            (response) => {
                // this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.destinationSubStationNameTA = [];
                    this.lrDtosToStnOptionsGet = [];
                    this.lrDtosToStnOptionsGet = response;
                    this.lrDtoToStnAll.destination = "All";
                    this.destinationSubStationNameTA.push(this.lrDtoToStnAll);
                    for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
                        this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);

                    }




                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                // this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    getDestCommissionSearch() {
        this.fortNgtSearchDto = new FortNightStmtDto();
        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        this.destination = $("#" + this.pageId + "destination").val();

        this.fortNgtSearchDto.fromPeriod = this.fromDate;
        this.fortNgtSearchDto.toPeriod = this.toDate;
        this.fortNgtSearchDto.destination = this.destination;

        console.log("this.fortNgtSearchDto");
        console.log(this.fortNgtSearchDto); 
    }

    getDestCommissionList = () => {
        this.getDestCommissionSearch();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestCommissionListSearch(this.fortNgtSearchDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.destAgentCommissionDetailsReportDataList = response;
                    $("#" + this.pageId + "destAgentCommissionDetailsReportId").DataTable().destroy();
                    this.dtTriggerDestAgentCommissionDetailsReport.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting TDS Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

}
