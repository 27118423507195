<html>

<head>
	<style>
		.imb_patch {
			text-align: center;
			background-color: orange;
			padding: 2px;
		}
	</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Party Less Checked And
						Unchecked Lr Details Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">


											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<!--<select class="custom-select col-12">
															<option selected>Select Destination</option>
															<option value="bangalore">Bangalore</option>
															<option value="chennai">Chennai</option>
															<option value="kerela">Kerela</option>
														</select>-->
														<input id="{{pageId}}destinationId" type="text" class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}fromDate">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<!-- 						<h6 class="imb_patch card-title text-white">Statement Less Checked And Pending Lrs</h6> -->
							<table datatable class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsStatementLessCheckedAndPendingLr"
								[dtTrigger]="dtTriggerStatementLessCheckedAndPendingLr">

								<thead>
									<tr>
										<th>Statement No</th>
										<th>Statement Date</th>
										<th>From Period</th>
										<th>To Period</th>
										<th>Total LRs</th>
										<th>Less Lrs</th>
										<th>Checked Lrs</th>
										<th>Uncheck Lrs</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let statementLessCheckedAndPendingLrData of statementLessCheckedAndPendingLrDataList ">
										<td>{{ statementLessCheckedAndPendingLrData.statementNo }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.statementDate }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.fromPeriod }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.toPeriod }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.totalLrs }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.lessLrs }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.checkedLrs }}</td>
										<td>{{ statementLessCheckedAndPendingLrData.uncheckLrs }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>

						</div>
						<div class="box-body" style="padding-top:20px;">
							<!-- 						<h6 class="imb_patch card-title text-white">LR Details</h6> -->
							<table datatable class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetailsTable" [dtTrigger]="dtTriggerLrDetailsTable">

								<thead>
									<tr>
										<th>LR Number</th>
										<th>Booking Date</th>
										<th>Source</th>
										<th>Actual Less</th>
										<th>Main Commodity</th>
										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsTableData of lrDetailsTableDataList ">
										<td>{{ lrDetailsTableData.lrNumber }}</td>
										<td>{{ lrDetailsTableData.bookingDate }}</td>
										<td>{{ lrDetailsTableData.source }}</td>
										<td>{{ lrDetailsTableData.actualLess }}</td>
										<td>{{ lrDetailsTableData.mainCommodity }}</td>
										<td>{{ lrDetailsTableData.remarks }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>



						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>