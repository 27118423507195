import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'filterUserGodown'
} )

export class UserGodownFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        //        return items.filter(
        //            item =>
        //                item['label']
        //                    .toLowerCase()
        //                    .includes( searchTerm.toLowerCase() ) === true
        //        );
        return items.filter(
            item => item['subStations'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,

            //                item => item['subStation'].toLowerCase()
            //                .includes( searchTerm.toLowerCase() ) === true

        );


        //        return items.filter( function( item: any ) {
        //            return JSON.stringify( item ).toLowerCase().includes( searchTerm.toLowerCase() );
        //        } );
    }

    //    transform( items: any[], field: string, value: string ): any[] {
    //        if ( !items ) return [];
    //        if ( !value || value.length == 0 ) return items;
    //        return items.filter( it =>
    //            it[field].toLowerCase().indexOf( value.toLowerCase() ) != -1 );
    //    }
}
@Pipe( {
    name: 'filterAgent'
} )
export class AgentFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }
        return items.filter(
            item => item['subStation'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filter'
} )
export class FilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['label'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterSupplierName'
} )
export class SupplierNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['supplierName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterAgentSorce'
} )
export class AgentSrcFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['subStation'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterTruckNumber'
} )
export class TruckNumberFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['truckNumber'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterStaffName'
} )
export class StaffNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['staffName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterDestinationFrmSrc'
} )
export class DestinationFrmSrcFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['source'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterFrmSource'
} )
export class SourceFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['subStations'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterConsignorName'
} )
export class ConsignorNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['consignorName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterDest'
} )
export class DestFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['destination'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterSourceForSumm'
} )
export class SourceSummNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['source'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterCollectioMan'
} )
export class CollectioManFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['collectioMan'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterState'
} )
export class StateFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['state'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterLoaderHead'
} )
export class LoaderHeadFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['loaderHead'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterPackName'
} )
export class PackNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['packNature'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterCommodityName'
} )
export class CommodityNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['commodityName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterGodownName'
} )
export class GodownNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['godownName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterBankName'
} )
export class BankNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['bankName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterDestination'
} )
export class DestinationFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['godownName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterConsigneeName'
} )
export class ConsigneeNameFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['consigneeName'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterDestList'
} )
export class DestListFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['listOfDestination'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterMultipleCommodity'
} )
export class MultipleCommodityFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['multipleCommodity'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}
@Pipe( {
    name: 'filterSubCommodity'
} )
export class SubCommodityFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['subCommodity'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}

@Pipe( {
    name: 'filterCollectionMan'
} )
export class CollectionManFilterPipe implements PipeTransform {
    transform( items: any[], searchTerm: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item => item['collectioMan'].toLowerCase()
                .includes( searchTerm.toLowerCase() ) === true,
        );
    }
}