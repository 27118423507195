import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MemoReport } from 'src/app/dataService/memo-report';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';

@Component({
	selector: 'app-collection-man-report',
	templateUrl: './local-challan-report.component.html',
	styleUrls: ['./local-challan-report.component.css']
})
export class LocalChallanReportComponent implements OnInit {

	getDataGcTakenByDataListTable: any;
	getDataloadingTable: any;
	getDataGcLeftToParty: any;
	getDataGcLeftToPartyStatementSummary: any;
	getDataServiceFrCollectionConsolidatedReportTable: any;
	getDataServiceFrLeftToPartyStatementTable: any;
	getDataServiceFrCashmemoStockSummaryTable: any;
	getDataServiceFrCashmemoStockDetailsTable: any;
	getDataServiceFrCashmemoStockPartyWiseTable: any;
	getDataServiceFrDailyCollectionDetailsTable: any;

	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	//Custom Print Footer
	memoAmountSummary: number;
	lessSummary: number;
	tdsSummary: number;
	claimSummary: number;
	chqAmtSummary: number;
	neftAmtSummary: number;
	cashAmtSummary: number;
	amtCollectedSummary: number;
	articleSummary: number;
	totalMemoAmountSummary: number;
	gcOutSummary: number;
	gcInSummary: number;
	gcBalanceSummary: number;
	gcAmountSummary1: number;
	gcAmountSummary2: number;
	gcAmountSummary3: number;
	pndMemosSummary: number;
	pendingMemoAmtSummary: number;
	totalMemoSummary: number;
	totalAmtSummary: number;
	totalStockLrsSummary: number;
	totalStockAmtSummary: number;
	totalAssignedLrsSummary: number;
	totalAssignedAmtSummary: number;
	totalToPartyLrsSummary: number;
	totalToPartyAmtSummary: number;
	noOfgcSummary: number;
	tdsLessSummary: number;
	claimLessSummary: number;
	neftImpsSummary: number;
	actWtSummary: number;
	chgWtSummary: number;
	toPaySummary: number;
	paidSummary: number;
	vehicleNoHeader: number;
	hireSummary: number;
	advanceSummary: number;
	gurWtSummary: number;
	kantaWtSummary: number;
	freightSummary: number;

	unloadingDataList: any;
	loadingDataList: any;
	gcLeftToPartyDataList: any;
	gcLeftToPartyStatementSummaryDataList: any;
	collectionConsolidatedReportDataList: any;
	leftToPartyStatementDataList: any;
	cashmemoStockSummaryDataList: any;
	cashmemoStockDetailsDataList: any;
	cashmemoStockPartyWiseDataList: any;

	onDestroyUnsubscribtionGcTakenBy: Subscription;
	onDestroyUnsubscribtionGcAmountCollected: Subscription;
	onDestroyUnsubscribtionGcLeftToParty: Subscription;
	onDestroyUnsubscribtionGcLeftToPartyStatementSummary: Subscription;
	onDestroyUnsubscribtionCollectionConsolidatedReport: Subscription;
	onDestroyUnsubscribtionLeftToPartyStatement: Subscription;
	onDestroyUnsubscribtionCashmemoStockSummary: Subscription;
	onDestroyUnsubscribtionCashmemoStockDetails: Subscription;
	onDestroyUnsubscribtionCashmemoStockPartyWise: Subscription;
	onDestroyUnsubscribtionDailyCollectionDetails: Subscription;
	//for the select option with filter starts

	control = new FormControl();

	partyOptions = [
		{ id: 1, label: 'AR Tiles' },
		{ id: 2, label: 'GK Enterprises' },
		{ id: 3, label: 'GQ Associates' },
	];

	collectionManOptions = [
		{ id: 1, label: 'Aamer' },
		{ id: 2, label: 'Adnan' },
		{ id: 3, label: 'Afzal' },
	];
	areaNameOptions = [
		{ id: 1, label: 'DD' },
		{ id: 2, label: 'Local' },
		{ id: 3, label: 'Long DD' },
	];

	//for the select option with filter ends
	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;

	searchByInvoiceNumber = true;
	searchByDate = false;
	viewSource = false;

	viewStationDestination = false;
	viewStationInvoice = false;

	viewChallanNo: boolean = true;
	viewDestination = false;

	viewFromDate: boolean = false;
	viewToDate = false;
	viewAgentName = false;
	viewChoudryName = false;
	viewAreaName = false;
	viewCashmemoStockOptions = false;
	viewSendSms = false;
	viewSearchOption = true;

	searchWithSummary = false;
	searchWithDetail = false;
	searchWithPartyWise = false;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerUnloadingTable: Subject<any> = new Subject();
	dtTriggerLoading: Subject<any> = new Subject();
	dtTriggerGcLeftToParty: Subject<any> = new Subject();
	dtTriggerGcLeftToPartyStatementSummary: Subject<any> = new Subject();
	dtTriggerCollectionConsolidatedReport: Subject<any> = new Subject();
	dtTriggerLeftToPartyStatement: Subject<any> = new Subject();
	dtTriggerCashmemoStockSummary: Subject<any> = new Subject();
	dtTriggerCashmemoStockDetails: Subject<any> = new Subject();
	dtTriggerCashmemoStockPartyWise: Subject<any> = new Subject();
	dtTriggerDailyCollectionDetails: Subject<any> = new Subject();

	dataTable: any;

	dtOptionsUnloading: any;
	dtOptionsLoading: any;
	dtOptionsGcLeftToParty: any;
	dtOptionsGcLeftToPartyStatementSummary: any;
	dtOptionsCollectionConsolidatedReport: any;
	dtOptionsLeftToPartyStatement: any;
	dtOptionsCashmemoStockSummary: any;
	dtOptionsCashmemoStockDetails: any;
	dtOptionsCashmemoStockPartyWise: any;
	dtOptionsDailyCollectionDetails: any;

	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	consigneeNameTATempForDest: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any;
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;
	destView = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	office: any;
	selecDestinationForTemp: any;
	//for collection Man
	collectionManAllOption: UserDataDto = new UserDataDto();
	collectionManTA: Array<UserDataDto> = [];
	collectionManTATemp: Array<UserDataDto> = [];
	collectionManTATempForDest: Array<UserDataDto> = [];

	focusCollectionManTA$ = new Subject<string>();
	collectionManSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCollectionManTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.collectionManTA
				: this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
	collectionManDataList: any;


	////Area

	lrDtoBranchArea: LRDto = new LRDto();
	lrDtoAreaAll: LRDto = new LRDto();
	branchAreaDataList: any;
	areaNameTA: Array<LRDto> = [];
	areaNameTATemp: Array<LRDto> = [];
	areaNameTempForDest: Array<LRDto> = [];
	focusAreaNameTA$ = new Subject<string>();
	areaNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAreaNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.areaNameTA
				: this.areaNameTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAreaName = (x: { godownName: string }) => x.godownName;
	//
	modelCollectionMan: any;
	modelAreaName: any;
	userDtoCollectionMan: UserDataDto = new UserDataDto();
	userDtoCollectionManAll: UserDataDto = new UserDataDto();
	showDaily = false;
	showTillDate = true;
	setTodayDateOnToDate: any;
	setTodayDateOnFromDate: any;
	selectedSearchBy: any;

	selectedFromDate: any;
	selectedToDate: any;
	selectedSmsNo: any;
	cashmemoDtoForDailyCollectionSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCTakenSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCLeftToPartySearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCAmountCollectedSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForColletionConsolidatedSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoCashMemoStockSummarySearchDetails: CashMemoDto = new CashMemoDto();
	showSmsBtn = false;
	unloadingTable = true;
	selectedCollectionMan: any;
	selectedConsigneeName: any;
	dailyCollectionDetailsTable = false;
	gcLeftToPartyTable = false;
	gcLeftToPartyStatementSummaryTable = false;
	loadingTable = false;
	leftToPartyStatementTable = false;
	collectionConsolidatedTable = false;
	cashmemoStockSummaryTable = false;
	cashmemoStockDetailsTable = false;
	cashmemoStockPartyWiseTable = false;
	cashMemoDtoForGCTakenPrint: CashMemoDto = new CashMemoDto();
	viewGCTakenPrint = false;
	viewCustomPrintV1 = false;
	showDest = 'CollectionManRpt DestView';
	toSetDestForConsignee: any;
	toSetDestForColMan: any;
	toSetDestForArea: any;
	cashMemoDtoForGCLeftToPartyPrint: CashMemoDto = new CashMemoDto();
	viewGCLeftToPartyPrint = false;
	collectionManSelected = null;
	pageMode = null;
	modalRefferenceLeftToPartyStmtPopUp: NgbModalRef;
	modalRefferenceLeftToPartyStmtClosePopUp: string;
	@ViewChild('leftToPartyStmtPopUpTemplate') private leftToPartyStmtPopUpTemplate;
	selectedLeftToPartyStmt1Details: File;
	selectedLeftToPartyStmt2Details: File;
	selectedLeftToPartyStmt3Details: File;
	isUploaded: boolean = false;
	cashMemoDtoForGCLeftToPartyAckRecUpdate: CashMemoDto = new CashMemoDto();
	ackReckPopupStmtNo: any;
	showSpinnerForActionForUpload = false;
	//Station 
	modelStation: any;
	stationTA: Array<PartyMasterDto> = [];
	focusStationTA$ = new Subject<string>();
	stationSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusStationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.stationTA
				: this.stationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterStation = (x: { destination: string }) => x.destination;
	////
	lrDtoForStation: LRDto = new LRDto();
	stationOptions: any;
	selectedAreaName: any;
	selectedSearchByStock: any;
	todayDate: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

	///For Choudry Name
	@ViewChild('instanceLoaderHead') instanceLoaderHead: NgbTypeahead;
	loaderHeadTA: Array<any> = [];
	focusLoaderHeadTA$ = new Subject<string>();
	loaderHeadSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusLoaderHeadTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.loaderHeadTA
				: this.loaderHeadTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterChoudharyName = (x: { loaderHead: string }) => x.loaderHead;
	hireDto: HireSlipDto = new HireSlipDto();
	controlLoaderHead = new FormControl();
	loaderHeadOptions: HireSlipDto[];
	updateUnLoaderHeadOptions: HireSlipDto[];
	hireDtoForUpdateUnloaderList: HireSlipDto = new HireSlipDto();
	lrDtoForUpdateUnChoudaryBtn: LRDto = new LRDto();
	lrDtoForUpdateUnChoudaryRet: LRDto = new LRDto();
	lrDtoForUpdateLoadChoudaryBtn: LRDto = new LRDto();
	lrDtoForUpdateLoadChoudaryRet: LRDto = new LRDto();
	//For Agent Name
	focusAgentNameTA$ = new Subject<string>();
	clickAgentNameTA$ = new Subject<string>();
	@ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
	agentNameTA: Array<any> = [];
	agentNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

		const inputFocus$ = this.focusAgentNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.agentNameTA
				: this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAgentName = (x: { subStation: string }) => x.subStation;
	agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
	controlAgentName = new FormControl();
	agentDetailsOptionsGet: AgentDetailsDto[];
	agentDetailsOptionsSet: any;
	@ViewChild("agentName") agentNameField: ElementRef;

	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	destinationDtoForAll: LRDto = new LRDto();
	agentNameDtoForAll: AgentDetailsDto = new AgentDetailsDto();
	hireDtoForLoaderNameAll: HireSlipDto = new HireSlipDto();

	destinationList: Array<any> = [];
	agentNameList: Array<any> = [];
	loaderNameList: Array<any> = [];
	enteredChallanNo: any;
	lrtDtoForChallan: LRDto = new LRDto();
	lrtDtoForChallanRet: any;
	challanAgentName: string;
	@ViewChild("challanNo") challanNoField: ElementRef;
	@ViewChild("challanTotLrs") challanTotLrsField: ElementRef;
	selectedAgentName: any;
	enteredChallanNoAt0: any;
	mainAdminStationGet: any;
	lrNumberChk: any;
	selectedLoaderName: any;
	lrDtoForSearch: LRDto = new LRDto();
	hireSlipDtoForDestination: HireSlipDto = new HireSlipDto();
	selectedChallanNo: any;
	searchBy: string;
	selectedsearcby: any;
	selectedsearcOption: any;
	lrDtoForDateWise: LRDto = new LRDto();
	lrDto: LRDto = new LRDto();
	setLrNo: any;
	setInvoiceNo: any;
	lrDtoForUpdateUnChoudary: LRDto = new LRDto();
	///For Choudry updateUnload Name

	updateUnloaderTA: Array<any> = [];
	focusUpdateUnloaderTA$ = new Subject<string>();
	updateUnloaderSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusUpdateUnloaderTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.updateUnloaderTA
				: this.updateUnloaderTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	selectedLrNumber: any;
	selectedUnloaderHead: any;
	agentNameHeder: any;
	choudharyNameHeader: any;
	isChoudharyEdit = false;
	showHyperLink = false;
	lrDtoForUpdateChallanNoBtn: LRDto = new LRDto();
	lrDtoForUpdateChallanResponse: LRDto = new LRDto();
	enterdChallanNo: any;
	pageId = "lcrpt";

	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService, private consigneeService: ConsigneeService, private memoReport: MemoReport, private modalService: NgbModal, private reportService: ReportService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
			this.toSetDestForConsignee = this.userDataDtoReturnSession.mainStation;
			this.toSetDestForColMan = this.userDataDtoReturnSession.mainStation;
			this.toSetDestForArea = this.userDataDtoReturnSession.mainStation;
			this.getStationDtails();
			this.selectTodayDate();

			this.getLoaderHeadListDetails();
			this.getAgentDetailList();
			this.getDestinationDetails();
			this.getUpateUnloaderDetails();


			/*if (this.showDest=='CollectionManRpt DestView') {
			this.destView=true;         
			}*/
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"CollectionManRpt DestView") {
						this.destView = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Collection SMS") {
						this.showSmsBtn = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Choudhary Edit") {
						this.isChoudharyEdit = true;
					}

				}
			}
			//this.destView=true;
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}

			if (this.isChoudharyEdit) {
				this.showHyperLink = true;
			} else {
				this.showHyperLink = false;
			}
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {

		var companyAddressDetls = this.address;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		//the first datatable starts
		this.dtOptionsUnloading = {

			dom: 'Bfrtip',

			buttons: [

				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnLocalChallanReport = null;

						if ($("#lcrptsearchByOptionId").val() == "challanNo") {
							returnLocalChallanReport = "Local Challan Details Report " + "-" + " Challan No : " + $("#lcrptchallanNo").val();
						} else {
							returnLocalChallanReport = "Local Challan Details Report " + ", " +
								"From Date : " + moment($("#lcrptfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lcrpttoDate").val()).format('DD-MM-YYYY') + ", " +
								"Agent Name : " + $("#lcrptagentName").val() + ", " +
								"Choudhary Name : " + $("#lcrptloaderHead").val();
						}




						return returnLocalChallanReport;
					},
					exportOptions: {
						columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 15, 16]
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};


				var totalArticles = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actualWeight = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWeight = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(3).footer()).html(totalArticles);
				$(api.column(4).footer()).html(actualWeight);
				$(api.column(5).footer()).html(chargedWeight);
				$(api.column(6).footer()).html(toPay);
				$(api.column(7).footer()).html(paid);
			}
		},

			this.dtOptionsLoading = {
				dom: 'Bfrtip',
				buttons: [

					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							var returnLocalChallanReport = null;


							returnLocalChallanReport = "Local Challan Details Report " + ", " +
								"From Date : " + moment($("#lcrptfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lcrpttoDate").val()).format('DD-MM-YYYY') + ", " +
								"Destination : " + $("#lcrptdestination").val();

							return returnLocalChallanReport;
						},
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
						},

					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};


					var toPay = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var totalAmt = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var totalhire = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var advance = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gurWeight = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var kantaWeight = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var actWeight = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(4).footer()).html(toPay);
					$(api.column(5).footer()).html(paid);
					$(api.column(6).footer()).html(totalAmt);
					$(api.column(7).footer()).html(totalhire);
					$(api.column(8).footer()).html(advance);
					$(api.column(9).footer()).html(gurWeight);
					$(api.column(10).footer()).html(kantaWeight);
					$(api.column(11).footer()).html(actWeight);
					$(api.column(12).footer()).html(chargedWt);



				}

			}
		//the second datatable ends
	}




	ngOnDestroy(): void {
		this.dtTriggerUnloadingTable.unsubscribe();
		this.dtTriggerLoading.unsubscribe();
		this.dtTriggerGcLeftToParty.unsubscribe();
		this.dtTriggerGcLeftToPartyStatementSummary.unsubscribe();
		this.dtTriggerCollectionConsolidatedReport.unsubscribe();
		this.dtTriggerLeftToPartyStatement.unsubscribe();
		this.dtTriggerCashmemoStockSummary.unsubscribe();
		this.dtTriggerCashmemoStockDetails.unsubscribe();
		this.dtTriggerCashmemoStockPartyWise.unsubscribe();
		this.dtTriggerDailyCollectionDetails.unsubscribe();

	}

	ngAfterViewInit(): void {
		this.dtTriggerUnloadingTable.next();
		this.dtTriggerLoading.next();
		this.dtTriggerGcLeftToParty.next();
		this.dtTriggerGcLeftToPartyStatementSummary.next();
		this.dtTriggerCollectionConsolidatedReport.next();
		this.dtTriggerLeftToPartyStatement.next();
		this.dtTriggerCashmemoStockSummary.next();
		this.dtTriggerCashmemoStockDetails.next();
		this.dtTriggerCashmemoStockPartyWise.next();
		this.dtTriggerDailyCollectionDetails.next();
	}

	clearAllTable() {

		this.dtTriggerDailyCollectionDetails.next();
		this.dtTriggerGcLeftToParty.next();
		this.dtTriggerUnloadingTable.next();
		this.dtTriggerGcLeftToPartyStatementSummary.next();
	}






	hideTable() {
		this.dailyCollectionDetailsTable = false;
		this.unloadingTable = false;
		this.gcLeftToPartyTable = false;
		this.gcLeftToPartyStatementSummaryTable = false;
		this.loadingTable = false;
		this.leftToPartyStatementTable = false;
		this.collectionConsolidatedTable = false;
		this.cashmemoStockSummaryTable = false;
		this.cashmemoStockDetailsTable = false;
		this.cashmemoStockPartyWiseTable = false;



		/*if (this.destView == true) {
			this.viewDestination = true;
			$("#"+this.pageId+"destination").val(this.userDataDtoReturnSession.mainStation);
			this.modelConsigneeName = '';
			this.consigneeNameTA = [];
			this.consigneeNameTA = this.consigneeNameTATempForDest;
			this.modelCollectionMan = '';
			this.collectionManTA = [];
			this.collectionManTA = this.collectionManTATempForDest;
			this.modelAreaName = '';
			this.areaNameTA = [];
			this.areaNameTA = this.areaNameTempForDest;
			window.setTimeout(function () {
				$("#"+this.pageId+"consigneeName").val('All');
				$("#"+this.pageId+"collectionMan").val('All');
				$("#"+this.pageId+"areaName").val('All');
			}, 100);

		} else {
			this.modelConsigneeName = '';
			this.consigneeNameTA = this.consigneeNameTATemp;
			this.modelCollectionMan = '';
			this.collectionManTA = this.collectionManTATemp;
			this.viewDestination = false;
			this.modelAreaName = '';
			this.areaNameTA = this.areaNameTATemp;
			window.setTimeout(function () {
				$("#"+this.pageId+"consigneeName").val('All');
				$("#"+this.pageId+"collectionMan").val('All');
				$("#"+this.pageId+"areaName").val('All');
			}, 100);

		}*/
	}


	//input field and datatable hide and show starts
	searchByMode(searchBy: string) {
		this.selectTodayDate();
		if (searchBy === 'UnLoading') {
			//input field hide and show starts
			this.viewChallanNo = true;

			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewAgentName = false;
			this.viewChoudryName = false;
			this.viewSearchOption = true;

			//input field hide and show ends

			this.viewDestination = false;

			// this.dailyCollectionDetailsTable=false;
			this.hideTable();
			this.showTillDate = true;
			this.showDaily = false;

			this.unloadingTable = true;
			this.viewSendSms = false;
			this.viewSearchOption = true;


		} else if (searchBy === 'loading') {
			//input field hide and show starts
			this.viewChallanNo = false;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewAgentName = false;
			this.viewChoudryName = false;
			this.viewDestination = true;
			//input field hide and show ends

			this.hideTable();
			this.showDaily = false;

			this.viewSendSms = false;
			this.loadingTable = true
			this.viewSearchOption = false;


		}
	}
	searchByOption(searchByOptionId: string) {
		if (searchByOptionId === 'challanNo') {
			this.viewChallanNo = true;
			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewAgentName = false;
			this.viewChoudryName = false;


		} else if (searchByOptionId == 'dateWise') {
			this.viewChallanNo = false;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewAgentName = true;
			this.viewChoudryName = true;
		}
	}
	//input field and datatable hide and show starts

	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker

	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}







	getDetailsForCollectionMasterRead(destShow) {
		this.userDtoCollectionMan = new UserDataDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.userDtoCollectionMan.branchId = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		}
		// this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;

		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}






	selectTodayDate() {
		this.setTodayDateOnToDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
		this.setTodayDateOnFromDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};

		$("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
		$("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
	}

	getStationInfo() {
		this.lrDtoForStation = new LRDto();
		this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForStation.mode = "ALL";
	}
	getStationDtails = () => {
		this.getStationInfo();
		this.masterReadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
			(response) => {
				if (response) {
					this.stationOptions = response;
					this.lrDtoForStation = new LRDto();
					this.stationTA = [];
					//	                    console.log(this.stationOptions.length);
					for (let i = 0; i < this.stationOptions.length; i++) {
						this.stationTA.push(this.stationOptions[i]);
					}
					console.log(this.stationTA, this.stationOptions);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Station Details", "info");
			}, () => console.log('done');
	};

	getLoaderHeadList() {
		this.hireDto.mainStation = this.userDataDtoReturnSession.mainStation;
		//  this.hireDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
		this.hireDto.companyid = this.userDataDtoReturnSession.companyId;
		//        console.log( 'mainstation has :' + this.hireDto.mainStation )
	}
	getLoaderHeadListDetails = () => {
		this.getLoaderHeadList();
		this.masterReadService.getLoaderHeadDetails(this.hireDto).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Loader records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.controlLoaderHead.reset();
					this.loaderHeadOptions = [];
					this.loaderHeadTA = [];
				} else {
					this.controlLoaderHead.reset();
					this.loaderHeadOptions = response;

					this.loaderHeadTA = [];
					this.hireDtoForLoaderNameAll.loaderHead = "ALL";
					this.loaderHeadTA.push(this.hireDtoForLoaderNameAll);
					this.loaderNameList = [];
					for (let i = 0; i < this.loaderHeadOptions.length; i++) {
						this.loaderHeadTA.push(this.loaderHeadOptions[i]);
						this.loaderNameList.push(this.loaderHeadOptions[i].loaderHead);
					}


				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	getUserValuesForAgentDetailsList() {
		this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
		this.agentDtoParam.column2 = 'Working';
		this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
		//this.agentDtoParam.mode = 'Sub';
		this.agentDtoParam.mode = 'mainBranch';
	}
	getAgentDetailList() {
		this.getUserValuesForAgentDetailsList();

		this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
			(response) => {
				if (response.length == 0) {
					console.log(response);
					swal({
						title: "Warning",
						text: "No Agents  records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.controlAgentName.reset();
					//                    this.agentDetailsOptionsGet = [];
					//                    this.agentDtoAll.subStation = "Add New";
					//                    this.agentDetailsOptionsGet.push( this.agentDtoAll )
				} else {
					this.agentDetailsOptionsGet = [];
					this.agentNameTA = [];
					this.agentDetailsOptionsGet = response;
					this.agentDetailsOptionsSet = response;
					//                   
					console.log(this.agentDetailsOptionsGet);
					this.agentNameList = [];
					this.agentNameDtoForAll.subStation = "ALL";
					this.agentNameTA.push(this.agentNameDtoForAll);

					for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
						//console.log( this.agentDetailsOptionsGet[i].subStation );
						this.agentNameTA.push(this.agentDetailsOptionsGet[i]);
						this.agentNameList.push(this.agentDetailsOptionsGet[i].subStation);
					}

				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	}

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.destinationOptions = [];
					this.destinationTA = [];
					this.destinationOptions = response;
					this.destinationDtoForAll.destination = "ALL";
					this.destinationTA.push(this.destinationDtoForAll);

					this.destinationList = [];
					for (let i = 0; i < this.destinationOptions.length; i++) {
						this.destinationTA.push(this.destinationOptions[i]);
						this.destinationList.push(this.destinationOptions[i].destination);

					}
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');
	};


	searchMethod() {
		this.selectedChallanNo = $("#" + this.pageId + "challanNo").val();
		this.selectedsearcby = $("#" + this.pageId + "searchBy").val();
		this.selectedsearcOption = $("#" + this.pageId + "searchByOptionId").val();
		this.selectedLoaderName = $("#" + this.pageId + "loaderHead").val();
		this.selectedAgentName = $("#" + this.pageId + "agentName").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.lrDtoForSearch = new LRDto();
		this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
		if ((this.selectedsearcby == "UnLoading")) {
			if ((this.selectedsearcOption == "challanNo") && ((this.selectedChallanNo == null) || (this.selectedChallanNo == undefined) ||
				(this.selectedChallanNo == ''))) {
				swal("Mandatory Fields", "Please Enter The Challan Number", "warning");
				return false;

			}
			else if ((this.selectedsearcby == "UnLoading") && (this.selectedsearcOption == "dateWise") && ((this.selectedFromDate == null) || (this.selectedFromDate == undefined) ||
				(this.selectedFromDate == '') || (this.selectedToDate == null) ||
				(this.selectedToDate == undefined) || (this.selectedToDate == '') || (this.selectedAgentName == null) ||
				(this.selectedAgentName == undefined) || (this.selectedAgentName == '') || (this.selectedLoaderName == null) ||
				(this.selectedLoaderName == undefined) || (this.selectedLoaderName == ''))) {
				swal("Mandatory Fields", "Please select the mandatory field", "warning");
				return false;
			} else {
				if ((this.selectedsearcby == "UnLoading") && (this.selectedsearcOption == "challanNo")) {
					this.lrDtoForSearch.mode = "challanNo";
					this.lrDtoForSearch.challanNo = this.selectedChallanNo;
					console.log(this.lrDtoForSearch.mode);
				}
				else if (this.selectedsearcby == "UnLoading" && this.selectedsearcOption == "dateWise") {
					this.lrDtoForSearch.fromDate = this.selectedFromDate;
					this.lrDtoForSearch.toDate = this.selectedToDate;
					this.lrDtoForSearch.agentName = this.selectedAgentName;
					this.lrDtoForSearch.loaderName = this.selectedLoaderName;
					this.lrDtoForSearch.fromdate = this.selectedFromDate;
					this.lrDtoForSearch.todate = this.selectedToDate;
					this.lrDtoForSearch.dateInDate = this.selectedToDate;
					this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
				}
				this.localChallanData();
			}
		} else if ((this.selectedsearcby == "loading")) {
			this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
			this.selectedToDate = $("#" + this.pageId + "toDate").val();
			this.selectedDestination = $("#" + this.pageId + "destination").val();
			if ((this.selectedFromDate == null) || (this.selectedFromDate == undefined) ||
				(this.selectedFromDate == '') || (this.selectedToDate == null) || (this.selectedToDate == '') || (this.selectedToDate == '') || (this.selectedDestination == null) || (this.selectedDestination == '') || (this.selectedDestination == '')) {
				swal("Mandatory Fields", "Please select the mandatory field", "warning");
				return false;

			}
			else {
				this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
				this.selectedToDate = $("#" + this.pageId + "toDate").val();
				this.selectedDestination = $("#" + this.pageId + "destination").val();
				this.hireSlipDtoForDestination = new HireSlipDto();
				if ((this.selectedDestination != null) && (this.selectedDestination != "ALL")) {
					this.hireSlipDtoForDestination.companyid = this.userDataDtoReturnSession.companyId;
					this.hireSlipDtoForDestination.mainStation = this.userDataDtoReturnSession.mainStation;
					this.hireSlipDtoForDestination.toStation = this.selectedDestination;
					this.hireSlipDtoForDestination.fromDate = this.selectedFromDate;
					this.hireSlipDtoForDestination.toDate = this.selectedToDate;

				} else {
					this.hireSlipDtoForDestination.fromDate = this.selectedFromDate;
					this.hireSlipDtoForDestination.toDate = this.selectedToDate;
					this.hireSlipDtoForDestination.mainStation = this.userDataDtoReturnSession.mainStation;
					this.hireSlipDtoForDestination.companyid = this.userDataDtoReturnSession.companyId;

				}

			}
			this.destinationWiseMethod();
		}

	}
	localChallanData() {
		this.showSpinnerForAction = true;
		//this.searchMethod();
		console.log(this.lrDtoForSearch);

		this.reportService.getLocalChallanDetailReport(this.lrDtoForSearch).subscribe(
			(response) => {
				$("#" + this.pageId + "unLoadingTable").DataTable().destroy();
				this.unloadingDataList = [];

				if (response.length > 0) {
					console.log(response);
					this.unloadingDataList = response;
					this.showSpinnerForAction = false;
				} else {
					this.unloadingDataList = [];
					this.showSpinnerForAction = false;
					swal("No Records", "No Records found for this search", "info");

				}
				this.dtTriggerUnloadingTable.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Details", "info");
			}, () => console.log('done');
	}
	destinationWiseMethod() {
		this.showSpinnerForAction = true;
		//this.searchMethod();
		console.log(this.hireSlipDtoForDestination);

		this.reportService.getBookingHireSlipRptDestinationWise(this.hireSlipDtoForDestination).subscribe(
			(response) => {
				$("#" + this.pageId + "loadingTableId").DataTable().destroy();
				this.loadingDataList = [];

				if (response.length > 0) {
					console.log(response);
					this.loadingDataList = response;
					this.showSpinnerForAction = false;
				} else {
					this.loadingDataList = [];
					this.showSpinnerForAction = false;
					swal("No Records", "No Records found for this search", "info");

				}
				this.dtTriggerLoading.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Details", "info");
			}, () => console.log('done');

	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	open2(content2, unloadingData) {
		this.setLrNo = null;
		this.setLrNo = unloadingData.lrNumber;
		this.modalService.open(content2, { centered: true }).result.then(
			result => {
				this.closeResult = `Closed with: ${result}`;
			},
			reason => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}
	open3(content3, loadingData) {
		this.setInvoiceNo = loadingData.hireslipnumber;
		this.modalService.open(content3, { centered: true }).result.then(
			result => {
				this.closeResult = `Closed with: ${result}`;
			},
			reason => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openChallanNoPopUp(contentChallanNo, unloadingData) {
		this.setLrNo = null;
		this.setLrNo = unloadingData.lrNumber;
		if (unloadingData.localTripNumber == null) {
			this.modalService.open(contentChallanNo, { centered: true }).result.then(
				result => {
					this.closeResult = `Closed with: ${result}`;
				},
				reason => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
		}
	}
	getUpdateUnloaderList() {
		//this.hireDto.mainStation = this.userDataDtoReturnSession.mainStation;
		this.hireDtoForUpdateUnloaderList.branch = this.userDataDtoReturnSession.mainAdminStation;
		console.log(this.hireDtoForUpdateUnloaderList.branch);
		this.hireDtoForUpdateUnloaderList.companyid = this.userDataDtoReturnSession.companyId;
		//        console.log( 'mainstation has :' + this.hireDto.mainStation )
	}
	getUpateUnloaderDetails = () => {
		this.getUpdateUnloaderList();
		this.masterReadService.getLoaderHeadsDetails(this.hireDtoForUpdateUnloaderList).subscribe(
			(response) => {
				console.log(response);
				if (response.length == 0) {

					swal({
						title: "Warning",
						text: "No Loader records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

					this.updateUnLoaderHeadOptions = [];
					this.updateUnloaderTA = [];
				} else {

					this.updateUnLoaderHeadOptions = response;

					this.updateUnloaderTA = [];
					for (let i = 0; i < this.updateUnLoaderHeadOptions.length; i++) {
						this.updateUnloaderTA.push(this.updateUnLoaderHeadOptions[i].loaderHead);
					}


				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};
	updateUnloaderChoudharyBtn() {
		this.selectedUnloaderHead = null;
		this.lrDtoForUpdateUnChoudaryBtn = new LRDto();
		this.selectedUnloaderHead = $("#" + this.pageId + "updateUnloaderId").val();


		if ((this.selectedUnloaderHead == null) || (this.selectedUnloaderHead == undefined) || (this.selectedUnloaderHead == '')) {
			swal({
				title: "Mandatory Fields",
				text: "Please Select the Unloader",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				$("#lcrptupdateUnloaderId").focus();
			});
		} else {
			swal({
				title: "Updating Unloader",
				text: "Are you sure want to Update Unloader '" + this.selectedUnloaderHead + "' for this Lr No: '" + this.setLrNo + "' ",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.lrDtoForUpdateUnChoudaryBtn.lrNumber = this.setLrNo;
					this.lrDtoForUpdateUnChoudaryBtn.mode = "Choudary";
					this.lrDtoForUpdateUnChoudaryBtn.unloaderHead = this.selectedUnloaderHead;
					this.lrDtoForUpdateUnChoudaryBtn.challanNo = this.selectedChallanNo;
					this.lrDtoForUpdateUnChoudaryBtn.userName = this.userDataDtoReturnSession.userName;
					this.updatUnloaderChoudaryMethod();
				}
			});
		}
	}

	updatUnloaderChoudaryMethod() {
		this.showSpinnerForAction = true;
		//this.searchMethod();
		console.log(this.lrDtoForUpdateUnChoudaryBtn);
		this.reportService.updateUnLoaderChoduaryNameService(this.lrDtoForUpdateUnChoudaryBtn).subscribe(
			(response) => {
				console.log(response);
				this.lrDtoForUpdateUnChoudaryRet = response;
				if (this.lrDtoForUpdateUnChoudaryRet.status == 'success') {
					this.setLrNo = null;
					this.selectedUnloaderHead = null;
					this.modalService.dismissAll();
					// swal("Success", "Unloader details upated successfully!", "info");
					swal({
						title: "Success",
						text: "Unloader details upated successfully!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.searchMethod();
					});

				} else if (this.lrDtoForUpdateUnChoudaryRet.status == 'stmt') {
					swal("Failed", "Labour hamali statement is generated for this LR, can't edit this!", "info");
				} else if (this.lrDtoForUpdateUnChoudaryRet.status == 'failed') {
					swal("Failed", "Failed to update the Unloader details!", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the unloader details", "error");
			}, () => console.log('done');
	}

	updateloaderChoudharyBtn() {
		this.selectedUnloaderHead = null;
		this.lrDtoForUpdateLoadChoudaryBtn = new LRDto();
		this.selectedUnloaderHead = $("#" + this.pageId + "updateUnloaderId").val();
		if ((this.selectedUnloaderHead == null) || (this.selectedUnloaderHead == undefined) || (this.selectedUnloaderHead == '')) {
			swal({
				title: "Mandatory Fields",
				text: "Please Select the Loader",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				$("#lcrptupdateUnloaderId").focus();
			});
		} else {
			swal({
				title: "Updating Loader",
				text: "Are you sure want to Update Loader '" + this.selectedUnloaderHead + "' for this Invoice No: '" + this.setInvoiceNo + "' ",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.lrDtoForUpdateLoadChoudaryBtn.invoiceNumber = this.setInvoiceNo;
					this.lrDtoForUpdateLoadChoudaryBtn.unloaderHead = this.selectedUnloaderHead;
					this.lrDtoForUpdateLoadChoudaryBtn.userName = this.userDataDtoReturnSession.userName;
					this.updateLoaderChoudaryMethod();
				}
			});


		}
	}

	updateLoaderChoudaryMethod() {
		console.log(this.lrDtoForUpdateLoadChoudaryBtn);
		this.reportService.updateLoaderChoduaryNameService(this.lrDtoForUpdateLoadChoudaryBtn).subscribe(
			(response) => {
				console.log(response);
				this.lrDtoForUpdateLoadChoudaryRet = response;
				if (this.lrDtoForUpdateLoadChoudaryRet.status == 'success') {
					this.setInvoiceNo = null;
					this.selectedUnloaderHead = null;
					this.modalService.dismissAll();
					swal({
						title: "Success",
						text: "Loader details upated successfully!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.searchMethod();
					});

					// swal("Success", "Loader details upated successfully!", "info");
					// this.searchMethod();
				} else if (this.lrDtoForUpdateLoadChoudaryRet.status == 'stmt') {
					swal("Failed", "Labour hamali statement is generated for this Invoice, can't edit this!", "info");
				} else if (this.lrDtoForUpdateLoadChoudaryRet.status == 'failed') {
					swal("Failed", "Failed to update the Loader details!", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the Loader details", "error");
			}, () => console.log('done');
	}


	customPrintUnloading() {
		if (this.unloadingDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Booking Date", "Agent Name", "Art", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "Local Challan Arrival", "Local Challan Kanta", "Choudhary Name", "Bay No", "Unloaded At"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 6, 5, 6, 6, 6, 6, 10, 10, 12, 8, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.articleSummary = this.actWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = 0;
			for (let i = 0; i < this.unloadingDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.unloadingDataList[i].lrNumber, this.unloadingDataList[i].bookingDateStr, this.unloadingDataList[i].agentName
					, this.unloadingDataList[i].totalArticles, this.unloadingDataList[i].actualWeight, this.unloadingDataList[i].chargedWeight, this.unloadingDataList[i].toPay,
				this.unloadingDataList[i].paid, this.unloadingDataList[i].arrivalDateStr, this.unloadingDataList[i].challanKantaWgt, this.unloadingDataList[i].unloaderHead, this.unloadingDataList[i].bayNo, this.unloadingDataList[i].bayUnloadingTime];

				//For Summary
				this.articleSummary = this.articleSummary + this.unloadingDataList[i].totalArticles;
				this.actWtSummary = this.actWtSummary + this.unloadingDataList[i].actualWeight;
				this.chgWtSummary = this.chgWtSummary + this.unloadingDataList[i].chargedWeight;
				this.toPaySummary = this.toPaySummary + this.unloadingDataList[i].toPay;
				this.paidSummary = this.paidSummary + this.unloadingDataList[i].paid;
				this.vehicleNoHeader = this.unloadingDataList[i].vehicleNumber;
				this.agentNameHeder = this.unloadingDataList[i].agentName;
				this.choudharyNameHeader = this.unloadingDataList[i].unloaderHead
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.unloadingDataList.length, "", "", this.articleSummary, this.actWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, "", "", "", "", "", ""];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			if (this.selectedChallanNo = null) {

			}
			this.selectedChallanNo = $("#" + this.pageId + "challanNo").val();


			if (this.selectedChallanNo == '' || this.selectedChallanNo == null || this.selectedChallanNo == undefined) {
				this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
			}

			else {
				this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Challan No", "Vehicle Number"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedChallanNo, this.vehicleNoHeader];
			}


			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}

			//Heading Logics For Staion
			this.selectedLoaderName = $("#" + this.pageId + "loaderHead").val();
			this.selectedAgentName = $("#" + this.pageId + "agentName").val();
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			if (this.selectedChallanNo == '' || this.selectedChallanNo == null || this.selectedChallanNo == undefined) {
				this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name", "Choudhary Name"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedAgentName, this.selectedLoaderName];
			} else {
				this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name", "Choudhary Name"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.agentNameHeder, this.choudharyNameHeader];
			}


			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Local Challan Details Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
	customPrintLoading() {
		if (this.loadingDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice No", "Date", "Truck No", "Truck Lgt", "To Pay", "Paid", "Freight", "Hire", "Advance", "Gur WT", "Kanta Wt", "Actual Wt", "Chg Wt", "Broker", "Loaded By", "Choudhary Name"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 5, 8, 6, 6, 4, 6, 6, 6, 5, 6, 5, 5, 8, 6, 8];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.toPaySummary = this.paidSummary = this.freightSummary = this.hireSummary = this.advanceSummary = this.gurWtSummary = this.kantaWtSummary = this.actWtSummary = this.chgWtSummary = 0;
			for (let i = 0; i < this.loadingDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.loadingDataList[i].hireslipnumber, this.loadingDataList[i].hireSlipDateStr, this.loadingDataList[i].vehicleNumber
					, this.loadingDataList[i].trkLgth, this.loadingDataList[i].toPay, this.loadingDataList[i].paid, this.loadingDataList[i].totalAmt,
				this.loadingDataList[i].totalhire, this.loadingDataList[i].advance, this.loadingDataList[i].gurWeight, this.loadingDataList[i].kantaWeight, this.loadingDataList[i].actWeight, this.loadingDataList[i].chargedWt, this.loadingDataList[i].suppliername, this.loadingDataList[i].loadedby, this.loadingDataList[i].loaderHead];

				//For Summary

				this.toPaySummary = this.toPaySummary + this.loadingDataList[i].toPay;
				this.paidSummary = this.paidSummary + this.loadingDataList[i].paid;
				this.freightSummary = this.freightSummary + this.loadingDataList[i].totalAmt;
				this.hireSummary = this.hireSummary + this.loadingDataList[i].totalhire;
				this.advanceSummary = this.advanceSummary + this.loadingDataList[i].advance;
				this.gurWtSummary = this.gurWtSummary + this.loadingDataList[i].gurWeight;
				this.kantaWtSummary = this.kantaWtSummary + this.loadingDataList[i].kantaWeight
				this.actWtSummary = this.actWtSummary + this.loadingDataList[i].actWeight;
				this.chgWtSummary = this.chgWtSummary + this.loadingDataList[i].chargedWt;
				this.vehicleNoHeader = this.loadingDataList[i].vehicleNumber
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.loadingDataList.length, "", "", "", this.toPaySummary, this.paidSummary, this.freightSummary, this.hireSummary, this.advanceSummary, this.gurWtSummary, this.kantaWtSummary, this.actWtSummary, this.chgWtSummary, "", "", ""];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];


			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}

			//Heading Logics For Staion
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.hireDtoForUpdateUnloaderList.branch];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Booking HireSlip Details Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
	clearConfirm() {
		swal({
			title: "Clearning Fields",
			text: "Are you sure want to clear",
			icon: "info",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((sureYesBtn) => {
			if (sureYesBtn) {
				this.clearMethod();
			}
		});
	}
	clearMethod() {
		this.selectedChallanNo = '';
		$("#" + this.pageId + "challanNo").val('');
		this.selectedsearcby = ('');
		$("#" + this.pageId + "searchBy").val('UnLoading');
		this.selectedsearcOption = ('');
		$("#" + this.pageId + "searchByOptionId").val('challanNo');
		this.selectedLoaderName = '';
		$("#" + this.pageId + "loaderHead").val('');
		this.selectedAgentName = '';
		$("#" + this.pageId + "agentName").val('');
		this.selectedFromDate = ('');
		$("#" + this.pageId + "fromDate").val('');
		this.selectedToDate = ('');
		$("#" + this.pageId + "toDate").val('');
		this.selectedDestination = ('');
		$("#" + this.pageId + "destination").val('');

		this.clearTable();
		this.viewChallanNo = true;
		this.viewFromDate = false;
		this.viewToDate = false;
		this.viewAgentName = false;
		this.viewChoudryName = false;
		this.setTodayDateOnFromDate = null;
		this.setTodayDateOnToDate = null;
	}
	clearTable() {
		$("#" + this.pageId + "unLoadingTable").DataTable().destroy();
		this.dtTriggerUnloadingTable.next();
		$("#" + this.pageId + "loadingTableId").DataTable().destroy();
		this.dtTriggerLoading.next();
		this.loadingDataList = [];
		this.unloadingDataList = [];
	}
	searchByModeFocus(e, searchBy: string) {
		if (e.keyCode == 13) {
			if (searchBy == 'UnLoading') {
				// this.searchByMode(searchBy);
				window.setTimeout(function () {
					$("#lcrptsearchByOptionId").focus();
				}, 100);

			} else {
				// this.searchByMode(searchBy); 
				window.setTimeout(function () {
					$("#lcrptfromDate").focus();
				}, 100);
			}

		}
	}

	searchByOptionFocus(e, searchByOptionId: string) {
		if (e.keyCode == 13) {
			if (searchByOptionId == 'challanNo') {
				// this.searchByOption(searchByOptionId);
				window.setTimeout(function () {
					$("#lcrptchallanNo").focus();
				}, 100);

			} else {
				// this.searchByOption(searchByOptionId);
				$("#lcrptfromDate").focus();
			}

		}
	}

	//For DD Amt
	challanNoEvent(e) {
		if (e.keyCode == 13) {
			window.setTimeout(function () {
				$("#lcrptsearchBtn").focus();
			}, 100);
		}
	}
	focusFromDateEnter(e) {
		if (e.keyCode == 13) {
			window.setTimeout(function () {
				$("#lcrpttoDate").focus();
			}, 100);
		}
	}
	focusFromDate() {
		window.setTimeout(function () {
			$("#lcrpttoDate").focus();
		}, 100);
	}

	focusToDateEnter(e) {
		if (e.keyCode == 13) {
			if (this.viewDestination == true) {
				window.setTimeout(function () {
					$("#lcrptdestination").focus();
				}, 100);
			} else {
				window.setTimeout(function () {
					$("#lcrptagentName").focus();
				}, 100);
			}
		}
	}
	focusToDate() {
		if (this.viewDestination == true) {
			window.setTimeout(function () {
				$("#lcrptdestination").focus();
			}, 100);
		} else {
			window.setTimeout(function () {
				$("#lcrptagentName").focus();
			}, 100);
		}

	}
	getAgentNameEvent(e) {
		if (e.keyCode == 13) {
			window.setTimeout(function () {
				$("#lcrptloaderHead").focus();
			}, 100);

		}
	}
	getLoaderHeadEvent(e) {
		if (e.keyCode == 13) {
			window.setTimeout(function () {
				$("#lcrptsearchBtn").focus();
			}, 100);

		}
	}
	getDestinationEvent(e) {
		if (e.keyCode == 13) {
			window.setTimeout(function () {
				$("#lcrptsearchBtn").focus();
			}, 100);

		}
	}

	updateChallanNoBtn() {
		this.enterdChallanNo = null;
		this.enterdChallanNo = $("#" + this.pageId + "updateChallonNoId").val();
		this.lrDtoForUpdateChallanNoBtn = new LRDto();
		if ((this.enterdChallanNo == null) || (this.enterdChallanNo == undefined) || (this.enterdChallanNo == '')) {
			swal({
				title: "Mandatory Fields",
				text: "Please Enter The Challan Number",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				$("#lcrptupdateChallonNoId").focus();
			});
		} else {
			swal({
				title: "Updating Challan No",
				text: "Are you sure want to Update Challan No '" + this.enterdChallanNo + "' for this LR No: '" + this.setLrNo + "' ",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.lrDtoForUpdateChallanNoBtn.lrNumber = this.setLrNo;
					this.lrDtoForUpdateUnChoudaryBtn.unloaderHead = this.selectedUnloaderHead;
					this.lrDtoForUpdateChallanNoBtn.mode = "Challan";
					this.lrDtoForUpdateChallanNoBtn.challanNo = this.enterdChallanNo;
					this.lrDtoForUpdateChallanNoBtn.userName = this.userDataDtoReturnSession.userName;
					this.updateChallanNoMethod();
				}
			});


		}
	}

	updateChallanNoMethod() {
		this.showSpinnerForAction = true;
		//this.searchMethod();
		// console.log("this.lrDtoForUpdateChallanNoBtn");
		console.log(this.lrDtoForUpdateChallanNoBtn);

		this.reportService.updateUnLoaderChoduaryNameService(this.lrDtoForUpdateChallanNoBtn).subscribe(
			(response) => {
				console.log(response);
				this.lrDtoForUpdateChallanResponse = response;
				if (this.lrDtoForUpdateChallanResponse.status == 'success') {
					this.setLrNo = null;
					this.enterdChallanNo = null;
					this.modalService.dismissAll();
					swal({
						title: "Success",
						text: "Challan Number updated successfully!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.searchMethod();
					});
				} else if (this.lrDtoForUpdateChallanResponse.status == 'stmt') {
					swal("Failed", "Labour hamali statement is generated for this LR, can't edit this!", "info");
				} else if (this.lrDtoForUpdateChallanResponse.status == 'failed') {
					swal("Failed", "Failed to update the Challan details!", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the Challan details", "error");
			}, () => console.log('done');
	}

	updateChallanLabourPopup(contentUpdateChallanLabour) {
		this.selectedChallanNo = $("#" + this.pageId + "challanNo").val();
		if (((this.selectedChallanNo == null) || (this.selectedChallanNo == undefined) ||
			(this.selectedChallanNo == ''))) {
			swal("Not Allowed", "Please enter the Challan Number to Update", "warning");
		} else {
			this.modalService.open(contentUpdateChallanLabour, { centered: true }).result.then(
				result => {
					this.closeResult = `Closed with: ${result}`;
				},
				reason => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
		}
	}

	updateUnloaderChoudharyForChallanBtn() {
		this.selectedUnloaderHead = null;
		this.lrDtoForUpdateUnChoudaryBtn = new LRDto();
		this.selectedUnloaderHead = $("#" + this.pageId + "updateUnloaderId").val();
		if ((this.selectedUnloaderHead == null) || (this.selectedUnloaderHead == undefined) || (this.selectedUnloaderHead == '')) {
			swal({
				title: "Mandatory Fields",
				text: "Please Select the Choudhary Name",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				$("#lcrptupdateUnloaderId").focus();
			});
		} else {
			swal({
				title: "Update Choudhary Name",
				text: "Are you sure want to Update Choudhary '" + this.selectedUnloaderHead + "' for All the LRs of this Challan No: '" + this.selectedChallanNo + "' ",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.lrDtoForUpdateUnChoudaryBtn.unloaderHead = this.selectedUnloaderHead;
					this.lrDtoForUpdateUnChoudaryBtn.challanNo = this.selectedChallanNo;
					this.lrDtoForUpdateUnChoudaryBtn.userName = this.userDataDtoReturnSession.userName;
					this.updatUnloaderChoudaryChallanMethod();
				}
			});
		}
	}

	updatUnloaderChoudaryChallanMethod() {
		this.showSpinnerForAction = true;
		console.log(this.lrDtoForUpdateUnChoudaryBtn);
		this.reportService.updateUnloaderChoduaryNameChallanService(this.lrDtoForUpdateUnChoudaryBtn).subscribe(
			(response) => {
				console.log(response);
				this.lrDtoForUpdateUnChoudaryRet = response;
				if (this.lrDtoForUpdateUnChoudaryRet.status == 'success') {
					this.selectedChallanNo = null;
					this.selectedUnloaderHead = null;
					$("#" + this.pageId + "updateUnloaderId").val("");
					this.modalService.dismissAll();
					swal({
						title: "Success",
						text: "Unloader details updated successfully!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					})
				} else if (this.lrDtoForUpdateUnChoudaryRet.status == 'failed') {
					swal("Failed", "Failed to update the Unloader details!", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the unloader details", "error");
			}, () => console.log('done');
	}
}





