import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { DragulaModule } from 'ng2-dragula';
import { ConsigneeMasterComponent } from 'src/app/master/consignee/consignee-master/consignee-master.component';
import { RateMasterComponent } from 'src/app/master/consignee/rate-master/rate-master.component';
import { ConsigneeAdminRateMasterComponent } from 'src/app/master/consignee/consignee-admin-rate-master/consignee-admin-rate-master.component';
import { ConsigneeMergeComponent } from 'src/app/master/consignee/consignee-merge/consignee-merge.component';
import { ConsigneeGroupingComponent } from 'src/app/master/consignee/consignee-grouping/consignee-grouping.component';
import { MaterialModule } from "src/app/material.module";
import { UpdateConsigneeAdminRateForAllComponent } from 'src/app/master/consignee/update-consignee-admin-rate-for-all/update-consignee-admin-rate-for-all.component';
import { ConsigneeMultipleMobileNumberDetailsComponent } from 'src/app/master/consignee/consignee-multiple-mobilenumber-details/consignee-multiple-mobilenumber-details.component';
import { ConsigneeMobileNumberUpdateDatatableComponent } from 'src/app/master/consignee/consignee-mobilenumber-update-datatable/consignee-mobilenumber-update-datatable.component';
import { ConsigneeActiveNonActiveDetailsComponent } from 'src/app/master/consignee/consignee-active-nonactive-details/consignee-active-nonactive-details.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { SpecificConsigneeDetailsComponent } from './specific-consignee-details/specific-consignee-details.component';
import { ConsigneeMobilenumberConfirmComponent } from './consignee-mobilenumber-confirm/consignee-mobilenumber-confirm.component';
import { UpdateRateIncreaseComponent } from './update-rate-increase/update-rate-increase.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(ConsigneeRoutes), 
        FormsModule,
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, DragulaModule, MaterialModule, CashmemoReportModule],
    declarations: [
        ConsigneeMasterComponent,
        RateMasterComponent,
        ConsigneeAdminRateMasterComponent,
        ConsigneeMergeComponent,
        ConsigneeGroupingComponent,
        UpdateConsigneeAdminRateForAllComponent,
        ConsigneeMultipleMobileNumberDetailsComponent,
        ConsigneeMobileNumberUpdateDatatableComponent,
        ConsigneeActiveNonActiveDetailsComponent, SpecificConsigneeDetailsComponent, ConsigneeMobilenumberConfirmComponent,
        UpdateRateIncreaseComponent
    ], entryComponents: [UpdateConsigneeAdminRateForAllComponent,
        ConsigneeMultipleMobileNumberDetailsComponent,
        ConsigneeMobileNumberUpdateDatatableComponent,
        ConsigneeActiveNonActiveDetailsComponent, UpdateRateIncreaseComponent],
    exports: [ConsigneeMergeComponent]
})
export class ConsigneeModule { }
