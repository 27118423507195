<!-- Row -->
<div class="row">

	<div class="col-lg-12">
		<div class="card">
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<h6 style="border-bottom: 1px solid orange;" class="card-title">
								Local</h6>
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsStockDetails"
								[dtTrigger]="dtTriggerStockDetails">
								<thead>
									<tr>
										<th>LR Number</th>
										<th>Consignee Name</th>
										<th>Destination</th>
										<th>Articles</th>
										<th>Actual Wgt</th>
										<th>Charge Wgt</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let stockDetailsData of stockDetailsDataList ">

										<td (click)="goToLrEnquiryPage()"><u class="hyperLink_td">{{
												stockDetailsData.contactNo }}</u></td>
										<td>{{ stockDetailsData.position }}</td>
										<td>{{ stockDetailsData.name }}</td>
										<td>{{ stockDetailsData.grade }}</td>
										<td>{{ stockDetailsData.name }}</td>
										<td>{{ stockDetailsData.name }}</td>
										<td>{{ stockDetailsData.name }}</td>

									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
