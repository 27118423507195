import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterDto } from 'src/app/dto/master-dto';
import { Router } from "@angular/router";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { MasterService } from 'src/app/dataService/master-service';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";

@Component({
    selector: 'app-vehicle-setup-master',
    templateUrl: './vehicle-setup-master.component.html',
    styleUrls: ['./vehicle-setup-master.component.css']
})
export class VehicleSetupMasterComponent implements OnInit {

    masterDto: MasterDto = new MasterDto();
    saveResp: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    truckDataDto: TruckDataDto = new TruckDataDto();
    truckDataDtoSaveRet: any;
    truckDataDeleteDto: TruckDataDto = new TruckDataDto();
    truckDataDtoDeleteRet: any;
    vehicleMasterDataList: any;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerVehicleMaster: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsVehicleMaster: any;
    pageId = "vesmc";

    constructor(private router: Router, private masterService: MasterService, private masterReadService: MasterReadService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getVehicleMasterList();
        }
    }

    ngOnInit(): void {
        this.dtOptionsVehicleMaster = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerVehicleMaster.unsubscribe();
    }

    getVehicleDetailsForRead() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.isActive = true;
    }
    getVehicleMasterList = () => {
        this.getVehicleDetailsForRead();
        this.masterReadService.getVehicleSetupMaster(this.truckDataDto).subscribe(
            (response) => {
                if (response) {
                    this.vehicleMasterDataList = response;
                    this.dtTriggerVehicleMaster.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Vehicle Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    validateVehicleDetails() {
        if (($("#" + this.pageId + "vehicleNumber").val() == null) || ($("#" + this.pageId + "vehicleNumber").val() == undefined) || ($("#" + this.pageId + "vehicleNumber").val() == "") ||
            ($("#" + this.pageId + "vehicleName").val() == null) || ($("#" + this.pageId + "vehicleName").val() == undefined) || ($("#" + this.pageId + "vehicleName").val() == "") ||
            ($("#" + this.pageId + "vehicleType").val() == null) || ($("#" + this.pageId + "vehicleType").val() == undefined) || ($("#" + this.pageId + "vehicleType").val() == "")) {
            swal("Mandatory Fields", "Please enter the mandatory fields to proceed!", "warning");
            return false;
        } else {
            this.saveUpdateVehicleDetails();
        }
    }
    getVehicleDetailsForSave() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.userId = this.userDataDtoReturnSession.userId;
        this.truckDataDto.office = this.userDataDtoReturnSession.office;
        this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainStation;

    }
    saveUpdateVehicleDetails = () => {
        this.getVehicleDetailsForSave();
        this.masterService.createVehicle(this.truckDataDto).
            subscribe((data) => {
                this.truckDataDtoSaveRet = data;
                if (this.truckDataDtoSaveRet.status == "persisted") {
                    swal({
                        title: "Success",
                        text: "Vehicle Details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getVehicleMasterList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Vehicle Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Vehicle Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        this.truckDataDto.truckNumber = null;
        this.truckDataDto.vehicleName = null;
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rowSelected(vehicleNumberData) {
        this.truckDataDto.truckNumber = vehicleNumberData.truckNumber;
        this.truckDataDto.vehicleName = vehicleNumberData.vehicleName;
        this.truckDataDto.truckType = vehicleNumberData.truckType;
    }

    validateDeleteRow() {
        this.deleteVehicleDetails();
    }

    getDeleteVehicleDetails() {
        this.truckDataDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDeleteDto.truckNumber = this.truckDataDto.truckNumber;
        this.truckDataDeleteDto.vehicleName = this.truckDataDto.vehicleName;
        this.truckDataDeleteDto.userId = this.userDataDtoReturnSession.userId;
    }

    deleteVehicleDetails = () => {
        this.getDeleteVehicleDetails();
        this.masterService.deleteVehicle(this.truckDataDeleteDto).
            subscribe((data) => {
                this.truckDataDtoDeleteRet = data;
                if (this.truckDataDtoDeleteRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Vehicle Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getVehicleMasterList();
                } else if (this.truckDataDtoDeleteRet.status == "not exist") {
                    swal({
                        title: "Success",
                        text: "Vehicle Details not exist",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    swal({
                        title: "Error",
                        text: "Error While Vehicle Driver Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Vehicle Driver Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
}
