<!-- Row -->
<div class="row" id="{{pageId}}statementVerRptId" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Statement Verification</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Statement No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}statementNo" class="form-control" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn();" id="{{pageId}}searchBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                    </div>
                </div>


                <div class="col-md-9 vl p-t-10">
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="card-body">
                        <div class="box-body">
                            <h6 class="card-title border_bottom">LR Summary Details</h6>
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintStatementVerRpt()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
                            <table datatable id="{{pageId}}statementVerificationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReport" [dtTrigger]="dtTriggerStatementReport">

                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Total Lr</th>
                                        <th>Total Article</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Service Tax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportData of statementReportDataList ">
                                        <td>{{ statementReportData.description }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="stmtVerificationPopUpBtnLink(stmtVerificationLrsDetailsPopUpTemplate,statementReportData)">
                                            {{ statementReportData.totalLrs }}</td>
                                        <!--<td>{{ statementReportData.totalLrs }}</td>-->
                                        <td>{{ statementReportData.totalArticles }}</td>
                                        <td>{{ statementReportData.totalActWgt }}</td>
                                        <td>{{ statementReportData.totalChgWgt }}</td>
                                        <td>{{ statementReportData.toPay }}</td>
                                        <td>{{ statementReportData.totalPaid }}</td>
                                        <td>{{ statementReportData.serviceTax }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #stmtVerificationLrsDetailsPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewStmtVerificationRptPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupStmtVerifRpt">
                                            <app-stmt-verification-lrs-details-report>
                                            </app-stmt-verification-lrs-details-report>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="button" style='background: green; width: auto; color: white; float: right;' class="btn btn-outline-dark" (click)="closeStmtVerificationRptPopUp()">Close</button>
                        </div>
                    </div>
                </div>
                <!--<div class="modal-footer">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<button type="button" style='background: green;width: 100%;color: white;'
							class="btn btn-outline-dark" (click)="closeStmtVerificationRptPopUp()">Close</button>
					</div>
					<div class="col-md-4"></div>
				</div>-->
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>