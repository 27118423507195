import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

@Component({
    selector: 'app-srd-labour-statement-generation',
    templateUrl: './srd-labour-statement-generation.component.html',
    styleUrls: ['./srd-labour-statement-generation.component.css']
})
export class SrdLabourStatementGenerationComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    labourStmtGenerationDataList: any;
    onDestroyUnsubscribtionLabourStmtGeneration: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLabourStmtGeneration: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLabourStmtGeneration: any;

    showSpinnerForAction = false;
    hireslipDtoLoader: HireSlipDto = new HireSlipDto();
    fortNightStmtDtoDateValidation: FortNightStmtDto = new FortNightStmtDto();
    hireslipDtoStmt: HireSlipDto = new HireSlipDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    selectedFromDate: any;
    selectedToDate: any;
    fromDateReadOnly: boolean = true;
    generateBtnReadOnly: boolean = false;
    setStationName: any;
    pageId = "slsgs";
    constructor(private labourStmtGenerationRpt: MasterService, private destStmtReport: DestStmtReport, private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            localStorage.clear();
            this.getLoaderHeadForStmt();
        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        this.dtOptionsLabourStmtGeneration = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends

            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }
    }

    getLoaderStmtUserValues() {
        this.hireslipDtoLoader = new HireSlipDto();
        this.hireslipDtoLoader.branch = this.userDataDtoReturnSession.mainStation;
        this.hireslipDtoLoader.companyid = this.userDataDtoReturnSession.companyId;
    }
    getLoaderHeadForStmt = () => {
        this.showSpinnerForAction = true;
        this.getLoaderStmtUserValues();
        this.destStmtReport.getLabourHamaliLastStmtDetails(this.hireslipDtoLoader).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "labourStmtTableId").DataTable().destroy();
                this.labourStmtGenerationDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.labourStmtGenerationDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLabourStmtGeneration.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Loader Details", "info");
            }, () => console.log('done');
    };

    getValidateDateUserValues() {
        this.fortNightStmtDtoDateValidation = new FortNightStmtDto();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.fortNightStmtDtoDateValidation.fromPeriod = this.selectedFromDate;
        this.fortNightStmtDtoDateValidation.toPeriod = this.selectedToDate;
    }
    validateStmtDate = () => {
        this.getValidateDateUserValues();
        this.destStmtReport.validateStmtDate(this.fortNightStmtDtoDateValidation).subscribe(
            (response) => {
                if (response.isTrue == false) {
                    swal({
                        title: "Invalid Period Selection",
                        text: "To-Period should be Before the Current Date!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    swal({
                        title: "Confirm Generate",
                        text: "Sure U Want To Generate The Statement",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            if (this.userDataDtoReturnSession.mainStation = "Delhi") {
                                this.generateStmts();
                            }
                        } else {
                            swal.close();
                        }

                    })
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While validating the date", "error");
            }, () => console.log('done');
    };

    getStmtGenerationUserValues() {
        this.hireslipDtoStmt = new HireSlipDto();
        this.hireslipDtoStmt.fromDateInDate = this.selectedFromDate;
        this.hireslipDtoStmt.toDateInDate = this.selectedToDate;
        this.hireslipDtoStmt.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireslipDtoStmt.branch = this.userDataDtoReturnSession.mainStation;
        this.hireslipDtoStmt.companyid = this.userDataDtoReturnSession.companyId;
        this.hireslipDtoStmt.loaderHead = this.setStationName;
    }
    generateStmts = () => {
        this.showSpinnerForAction = true;
        this.getStmtGenerationUserValues();
        this.destStmtReport.calculateSRDHamaliComms(this.hireslipDtoStmt).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == "Failed") {
                    swal("Error", "Problem occured while Generating the Statements", "error");

                } else if (response.status == "noComms") {
                    swal("Not Allowed", "Commission not yet set, please set The commission and try again!", "warning");

                } else if (response.status == "noRecords") {
                    swal("Not Found", "No Records Found", "warning");

                } else if (response.status == "Success") {
                    swal("Completed", "Statements Generated Successfully", "info");
                    this.clearFields();
                    this.getLoaderHeadForStmt();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem occured while Generating the Statements", "error");
            }, () => console.log('done');
    };

    ngOnDestroy(): void {
        this.dtTriggerLabourStmtGeneration.unsubscribe();
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    //for datePicker

    validateToPeriodCalendar($event) {
        if ($("#" + this.pageId + "fromDate").val() != null && $("#" + this.pageId + "fromDate").val() != undefined &&
            $("#" + this.pageId + "fromDate").val() != "") {
            this.generateBtnReadOnly = true;
        } else {
            this.generateBtnReadOnly = false;
        }
    }

    validateGenerateBtn() {
        if ($("#" + this.pageId + "fromDate").val() == null || $("#" + this.pageId + "fromDate").val() == undefined ||
            $("#" + this.pageId + "fromDate").val() == "" || $("#" + this.pageId + "toDate").val() == null ||
            $("#" + this.pageId + "toDate").val() == undefined || $("#" + this.pageId + "toDate").val() == "") {
            swal("Mandatory Field", "Please select the Mandatory Field", "warning");
            return false;
        } else {
            this.validateStmtDate();
        }
    }

    selectedRowSetStmtGenDetails(stmtGenerationData) {
        this.setStationName = null;
        this.selectedFromDate = null;
        this.selectedToDate = null;

        this.setStationName = stmtGenerationData.loaderName;
        this.selectedFromDate = stmtGenerationData.fromDate;
        this.selectedToDate = stmtGenerationData.toDate;

        this.fromDateReadOnly = false;
        this.generateBtnReadOnly = false;
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "fromDate").val('');
        if (stmtGenerationData.stmtDate != null &&
            stmtGenerationData.stmtDate != undefined) {
            $("#" + this.pageId + "fromDate").val(moment(stmtGenerationData.stmtDate).format("YYYY-MM-DD"));
        }

        if (stmtGenerationData.fortNightStmtNo == null) {
            this.fromDateReadOnly = true;
        }
    }

    clearFields() {
        this.setStationName = null;
        this.selectedFromDate = null;
        this.selectedToDate = null;
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "fromDate").val('');
        this.generateBtnReadOnly = false;
        this.fromDateReadOnly = true;
    }
}

