import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
    selector: 'app-agent-statement-rpt-lr-details',
    templateUrl: './agent-statement-rpt-lr-details.component.html',
    styleUrls: ['./agent-statement-rpt-lr-details.component.css']
})
export class AgentStatementRptLrDetailsComponent implements OnInit {

    getDataFrmServiceFrTable: any;

    statementReportLrDetailsDataList: any;
    statementReportLrAdditionDataList: any;
    statementReportLrDeductionDataList: any;

    onDestroyUnsubscribtionStatementReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerStatementReportLrDetails: Subject<any> = new Subject();
    dtTriggerStatementReportLrAdditionDetails: Subject<any> = new Subject();
    dtTriggerStatementReportLrDeductionDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsStatementReportLrDetails: any;
    dtOptionsStatementReportLrAdditionDetails: any;
    dtOptionsStatementReportLrDeductionDetails: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    stmtRptDetails_localStorage: any;

    stmtRptAdditionDetails_localStorage: any;
    stmtRptDeductionDetails_localStorage: any;

    additionValueLRDto: LRDto = new LRDto();
    additionValueLRDtoList: Array<LRDto> = [];

    deductionValueLRDto: LRDto = new LRDto();
    deductionValueLRDtoList: Array<LRDto> = [];

    validateStatementNo: any = null;
    validateDestination: any = null;

    showSpinnerForAction = false;

    lrDto: LRDto = new LRDto();
    //rateMasterDtoForLr : RateMasterDto = new RateMasterDto();
    viewStatementRptLRDetlsPrint = false;
    result: any;
    summaryTotalLrs: any;
    summaryTotalActWgt: any;
    summaryTotalChgWgt: any;
    summaryTotalLCChg: any;
    summaryTotalBCChg: any;

    //print
    ////
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    actWgtSummary: number;
    chgWgtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    lcChgSummary: number;
    bcChgSummary: number;
    incentiveSummary: number;
    penalitySummary: number;
    viewCustomPrintV1 = false;
    pageId = "srlrd";
    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private lrService: LrService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.stmtRptDetails_localStorage = JSON.parse(localStorage.getItem('popupAgentStmtRptLrDetls'));
            this.stmtRptAdditionDetails_localStorage = JSON.parse(localStorage.getItem('popupAgentStmtRptLrDetlsAddition'));
            this.stmtRptDeductionDetails_localStorage = JSON.parse(localStorage.getItem('popupAgentStmtRptLrDetlsDeduction'));
            localStorage.clear();
            console.log(this.stmtRptDetails_localStorage);
            //console.log(this.stmtRptAdditionDetails_localStorage.split("#"));

            if (this.stmtRptDetails_localStorage != null && this.stmtRptDetails_localStorage != undefined &&
                this.stmtRptDetails_localStorage != "") {
                this.validateStatementNo = this.stmtRptDetails_localStorage.stmtNo;
                this.validateDestination = this.stmtRptDetails_localStorage.agentName;
                this.setBookingServiceReport();
            }

        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var stmtNoPrint = this.stmtRptDetails_localStorage.stmtNo;
        var destinationPrint = this.stmtRptDetails_localStorage.agentName;
        var fromPeriodPrint = this.stmtRptDetails_localStorage.fromPeriod;
        var toPeriodPrint = this.stmtRptDetails_localStorage.toPeriod;
        this.dtOptionsStatementReportLrDetails = {
            dom: 'Bfrtip',
            //place holder in search/filter in datatable starts
            buttons: [
                /*  {
                      extend: 'print',
                      text: '<i class="fas fa-print"> Print LR Details</i>',
                      titleAttr: 'Print LR Details',
                      customize: function (win) {
                          $(win.document.body).css('font-size', '10pt');
                          $(win.document.body).find('th, td').
                              css('font-family', 'Arial, Helvetica, sans-serif')
                              .css('font-size', '12px').css('text-align', 'center');
                      },
                      footer: true,
                      exportOptions: {
  
                      },
                      messageTop: function () {
                          return "<br><table style='width: 100%;'>" +
                              "<tr>" +
                              "<td style='width: 22%;'>" +
                              "<table>" +
                              "<tr>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>Statement No</strong>" +
                              "</td>" +
                              "<td width='2%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>:</strong>" +
                              "</td>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<span style='font-size:15px;'>" + stmtNoPrint + "</span><br>" +
                              "</td>" +
                              "</tr>" +
                              "<tr>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>Destination&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                              "</td>" +
                              "<td width='2%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>:</strong>" +
                              "</td>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<span style='font-size:15px;'>" + destinationPrint + "</span><br>" +
                              "</td>" +
                              "</tr>" +
                              "<tr>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                              "</td>" +
                              "<td width='2%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>:</strong>" +
                              "</td>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<span style='font-size:15px;'>" + moment(fromPeriodPrint).format('DD-MM-YYYY') + "</span><br>" +
                              "</td>" +
                              "</tr>" +
                              "<tr>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                              "</td>" +
                              "<td width='2%' style='text-align:left !important;'>" +
                              "<strong style='font-size:15px;'>:</strong>" +
                              "</td>" +
                              "<td width='10%' style='text-align:left !important;'>" +
                              "<span style='font-size:15px;'>" + moment(toPeriodPrint).format('DD-MM-YYYY') + "</span><br>" +
                              "</td>" +
                              "</tr>" +
                              "</table>" +
                              "</td>" +
                              "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                              "<strong style='font-size:24px;'><u>Statement Report LR Details</u></strong><br>" +
                              //"<strong style='font-size:15px;'><u>Booking Chargeable / Unchargeable Service Tax</u></strong>" +
                              "</td>" +
                              "<td rowspan='3' align='left' style='width:22%;'>" +
                              "</td>" +
                              "</tr>" +
                              "</table><br>";
                      },
                      title: function () {
                          return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                              "<td align='left' style='width: 20%;'>" +
                              "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                              "</td>" +
                              "<td align='left' style='width: 30%;'>" +
                              "</td>" +
                              "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                              companyAddressDetls +
                              "</td>" +
                              "</tr></table>";
                      }
                  },*/
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel LR Details</i>',
                    titleAttr: 'Excel LR Details',
                    footer: true,
                    title: function () {
                        return "Booking Statement Report LR Details" +
                            "From Date : " + fromPeriodPrint + " -  " +
                            "To Date : " + toPeriodPrint + " - " +
                            "Destination : " + destinationPrint + " " +
                            "Statement No : " + stmtNoPrint + "";

                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chdWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lc = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var bc = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var incentive = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var penality = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chdWgt);
                $(api.column(6).footer()).html(topay);
                $(api.column(7).footer()).html(paid);
                $(api.column(8).footer()).html(lc);
                $(api.column(9).footer()).html(bc);
                $(api.column(13).footer()).html(incentive.toFixed());
                $(api.column(14).footer()).html(penality);
            }
        },
            this.dtOptionsStatementReportLrAdditionDetails = {
                dom: 'Bfrtip',
                //place holder in search/filter in datatable starts
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                "order": [[1, "desc"]],
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "bFilter": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var creditAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(creditAmt);
                }
            },
            this.dtOptionsStatementReportLrDeductionDetails = {
                dom: 'Bfrtip',
                //place holder in search/filter in datatable starts
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                "order": [[1, "desc"]],
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "bFilter": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var debitAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(debitAmt);
                }
            }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStatementReportLrDetails.next();
        this.dtTriggerStatementReportLrAdditionDetails.next();
        this.dtTriggerStatementReportLrDeductionDetails.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerStatementReportLrDetails.unsubscribe();
        this.dtTriggerStatementReportLrAdditionDetails.unsubscribe();
        this.dtTriggerStatementReportLrDeductionDetails.unsubscribe();
        // this.onDestroyUnsubscribtionStatementReport.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    setBookingServiceReport() {
        this.lrDto = new LRDto();
        this.lrDto.fortNightStmtNo = this.stmtRptDetails_localStorage.stmtNo;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        console.log(this.lrDto);
        this.lrService.getLrDetailsForBkgAgentStmt(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                this.statementReportLrDetailsDataList = [];
                $("#" + this.pageId + "statementRptLrDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.statementReportLrDetailsDataList = response;
                    setTimeout(() => {
                        this.dtTriggerStatementReportLrDetails.next();
                    }, 3000);
                    this.showSpinnerForAction = false;
                    this.setStatementRptLrDetlsAddition();
                    this.setStatementRptLrDetlsDeduction();
                } else {
                    setTimeout(() => {
                        this.dtTriggerStatementReportLrDetails.next();
                    }, 3000);
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                    this.showSpinnerForAction = false;
                }
                this.clickListnerForPrintStmtRptLr();
                this.customPrintBkgAgentStmtLr();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }

    setStatementRptLrDetlsAddition() {
        this.additionValueLRDtoList = [];
        this.statementReportLrAdditionDataList = [];
        $("#" + this.pageId + "statementRptAdditionTableId").DataTable().destroy();
        if (this.stmtRptAdditionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.stmtRptAdditionDetails_localStorage.length; i++) {
                var additionValue = this.stmtRptAdditionDetails_localStorage[i];
                var additionValueSplit = additionValue.split("_");
                this.additionValueLRDto = new LRDto();
                this.additionValueLRDto.description = additionValueSplit[0];
                this.additionValueLRDto.totalAmount = additionValueSplit[1];
                this.additionValueLRDtoList.push(this.additionValueLRDto);
                this.statementReportLrAdditionDataList.push(this.additionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerStatementReportLrAdditionDetails.next();
            }, 3000);
        } else {
            setTimeout(() => {
                this.dtTriggerStatementReportLrAdditionDetails.next();
            }, 3000);
        }
    }


    setStatementRptLrDetlsDeduction() {
        this.deductionValueLRDtoList = [];
        this.statementReportLrDeductionDataList = [];
        $("#" + this.pageId + "statementRptDeductionTableId").DataTable().destroy();
        if (this.stmtRptDeductionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.stmtRptDeductionDetails_localStorage.length; i++) {
                var deductionValue = this.stmtRptDeductionDetails_localStorage[i];
                var deductionValueSplit = deductionValue.split("_");
                this.deductionValueLRDto = new LRDto();
                this.deductionValueLRDto.description = deductionValueSplit[0];
                this.deductionValueLRDto.totalAmount = deductionValueSplit[1];
                this.statementReportLrDeductionDataList.push(this.deductionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerStatementReportLrDeductionDetails.next();
            }, 3000);

        } else {
            setTimeout(() => {
                this.dtTriggerStatementReportLrDeductionDetails.next();
            }, 3000);
        }
    }

    clickListnerForPrintStmtRptLr() {
        window.localStorage.clear();
        localStorage.clear();
        localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorageViaAgentStmt',
            JSON.stringify(this.statementReportLrAdditionDataList));
        localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorageViaAgentStmt',
            JSON.stringify(this.statementReportLrDeductionDataList));
        localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorageViaAgentStmt',
            JSON.stringify(this.stmtRptDetails_localStorage));
        /*localStorage.setItem('agentStmtLrDtlsPrintLocalStorage',
            JSON.stringify(this.statementReportLrDetailsDataList));*/
        this.stmtRptDetails_localStorage.stmtNo
        console.log(localStorage.size);
        // this.viewStatementRptLRDetlsPrint = true;
        // this.showSpinnerForAction = true;
        // window.addEventListener('afterprint', (onclick) => {
        //     if (this.viewStatementRptLRDetlsPrint) {
        //         this.viewStatementRptLRDetlsPrint = false;
        //         localStorage.clear();
        //     }
        //     this.showSpinnerForAction = false;
        // });
    }

    clickListnerForPrintStmtRptLr1() {
        window.localStorage.clear();
        localStorage.clear();
        localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.statementReportLrAdditionDataList));
        localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.statementReportLrDeductionDataList));
        localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.stmtRptDetails_localStorage));
        localStorage.setItem('agentStmtLrDtlsPrintLocalStorage',
            JSON.stringify(this.setHashMapValues()));
        this.stmtRptDetails_localStorage.stmtNo
        console.log(localStorage.size);
        this.viewStatementRptLRDetlsPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStatementRptLRDetlsPrint) {
                this.viewStatementRptLRDetlsPrint = false;
                localStorage.clear();
            }
        });
    }

    setHashMapValues() {
        this.summaryTotalLrs = 0;
        this.summaryTotalActWgt = 0;
        this.summaryTotalChgWgt = 0;
        this.summaryTotalLCChg = 0;
        this.summaryTotalBCChg = 0;
        for (let i = 0; i < this.statementReportLrDetailsDataList.length; i++) {
            this.summaryTotalLrs = +this.summaryTotalLrs + +1;
            this.summaryTotalActWgt = +this.summaryTotalActWgt + +this.statementReportLrDetailsDataList[i].actualWeight;
            this.summaryTotalChgWgt = +this.summaryTotalChgWgt + +this.statementReportLrDetailsDataList[i].chargedWeight;
            this.summaryTotalLCChg = +this.summaryTotalLCChg + +this.statementReportLrDetailsDataList[i].lcChg;
            this.summaryTotalBCChg = +this.summaryTotalBCChg + +this.statementReportLrDetailsDataList[i].bcChg;
        }

        var groups = new Set(this.statementReportLrDetailsDataList.map(item => item.source));
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totalLrs = this.statementReportLrDetailsDataList
                .filter(i => i.source === g).length
            //.reduce((sum, i) => +sum + +i.totalLrs, 0)
            const totalActWgt = this.statementReportLrDetailsDataList
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const totalChgWgt = this.statementReportLrDetailsDataList
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.chargedWeight, 0)
            const totalLCChg = this.statementReportLrDetailsDataList
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.lcChg, 0)
            const totalBCChg = this.statementReportLrDetailsDataList
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.bcChg, 0)

            this.result.push({
                source: g,
                values: this.statementReportLrDetailsDataList.filter(i => i.source === g),
                totalLRS: totalLrs,
                actualWeight: totalActWgt,
                chargedWeight: totalChgWgt,
                lcChg: totalLCChg,
                bcChg: totalBCChg,
            })
        })
        console.log(this.result);
        return this.result;
    }

    // //print 
    customPrintBkgAgentStmtLr() {
        if (this.statementReportLrDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            // localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Source", "Destination", "Commodity", "A.Wt", "C.Wt", "ToPay", "Paid", "LC", "BC", "Bkg Date", "Rec Date", "Diff", "Ince", "R.Comm"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [9, 10, 10, 16, 5, 5, 6, 4, 4, 3, 8, 8, 4, 4, 4];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.actWgtSummary = this.chgWgtSummary = this.toPaySummary = this.paidSummary = this.lcChgSummary = this.bcChgSummary = this.incentiveSummary = this.penalitySummary = 0;
            for (let i = 0; i < this.statementReportLrDetailsDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.statementReportLrDetailsDataList[i].lrNumber,
                    this.statementReportLrDetailsDataList[i].source,
                    this.statementReportLrDetailsDataList[i].destination,
                    this.statementReportLrDetailsDataList[i].description,
                    this.statementReportLrDetailsDataList[i].actualWeight,
                    this.statementReportLrDetailsDataList[i].chargedWeight,
                    this.statementReportLrDetailsDataList[i].toPay,
                    this.statementReportLrDetailsDataList[i].paid,
                    this.statementReportLrDetailsDataList[i].lcChg,
                    this.statementReportLrDetailsDataList[i].bcChg,
                    this.statementReportLrDetailsDataList[i].bookingDateStr,
                    this.statementReportLrDetailsDataList[i].enteredDateStr,
                    this.statementReportLrDetailsDataList[i].dispatchedwithin,
                    this.statementReportLrDetailsDataList[i].incentiveAmt,
                    this.statementReportLrDetailsDataList[i].penaltyAmt
                ];

                this.actWgtSummary = this.actWgtSummary + this.statementReportLrDetailsDataList[i].actualWeight;
                this.chgWgtSummary = this.chgWgtSummary + this.statementReportLrDetailsDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.statementReportLrDetailsDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.statementReportLrDetailsDataList[i].paid;
                this.lcChgSummary = this.lcChgSummary + this.statementReportLrDetailsDataList[i].lcChg;
                this.bcChgSummary = this.bcChgSummary + this.statementReportLrDetailsDataList[i].bcChg;
                this.incentiveSummary = this.incentiveSummary + this.statementReportLrDetailsDataList[i].incentiveAmt;
                this.penalitySummary = this.penalitySummary + this.statementReportLrDetailsDataList[i].penaltyAmt;
                //by kamil to round of the 
                console.log("this.incentiveSummary, this.penalitySummary");
                console.log(this.incentiveSummary, this.penalitySummary);
                let incentiveSummaryRoundOff = this.incentiveSummary == null ? 0 : this.incentiveSummary;
                let penalitySummaryRoundOff = this.penalitySummary == null ? 0 : this.penalitySummary;
                console.log("this.incentiveSummary, this.penalitySummary after Math Round >> ");
                console.log(incentiveSummaryRoundOff, penalitySummaryRoundOff);
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList =
                    ["Total : " + this.statementReportLrDetailsDataList.length, "", "", "",
                    this.actWgtSummary, this.chgWgtSummary, this.toPaySummary, this.paidSummary,
                    this.lcChgSummary, this.bcChgSummary, "", "", "", Math.round(incentiveSummaryRoundOff), Math.round(penalitySummaryRoundOff)];
            }


            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Stmt No"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.stmtRptDetails_localStorage.stmtNo];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }



            localStorage.setItem('printCashMemoDtoForCustomPrintListViaAgentStmt', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataListViaAgentStmt', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryListViaAgentStmt', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1ViaAgentStmt', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitleViaAgentStmt', "Booking Agent Statement Report LR Details");
            // this.viewCustomPrintV1 = true;
            // window.addEventListener('afterprint', (onclick) => {
            //     if (this.viewCustomPrintV1) {
            //         this.viewCustomPrintV1 = false;
            //         localStorage.clear();
            //     }
            // });
        }
    }

}
