<html>

<head>
	<style>
		.imb_patch {
			text-align: center;
			background-color: orange;
			padding: 2px;
		}
	</style>
</head>

<body>

	<!-- Row -->
	<div class="row" id="{{pageId}}bngOfcRptPrintView">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Bangalore Office Daily
						Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12" style="padding-bottom: 5px; border-bottom: 1px solid orange;">
						<div class="card">
							<div class="card-body">
								<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
									<div class="form-group">
										<div class="input-group">
											<mat-progress-bar mode="indeterminate" style="color: green;">
											</mat-progress-bar>
											<br>
											<h6 class="card-title" align='center'
												style="color: green; margin: auto; font-size: 18px;">
												Please Wait Loading Details.....</h6>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group">
														<label>Select Date</label> <input class="form-control"
															id="{{pageId}}selectDate" placeholder="yyyy-mm-dd"
															name="selectDates" ngbDatepicker
															#selectDates="ngbDatepicker">
														<div class="input-group-append" (click)="selectDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-3">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}selectRegion">
															<label>Select Region</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-map-marker-alt"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select Region" [formControl]="control"
																[appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
													<ng-template appAutocompleteContent>
														<ng-container
															*ngIf="(options | filter: control.value) as result">
															<app-option *ngFor="let option of result"
																[value]="option.label"> {{ option.label }} </app-option>
															<app-option class="no-result" *ngIf="!result.length">No
																result</app-option>
														</ng-container>
													</ng-template>
												</app-autocomplete>
											</div>

											<div class="col-md-3">
												<div ngbDropdown class="d-inline-block">
													<button style="padding: 0px 10px 0px 10px;"
														class="btn btn-outline-primary" id="{{pageId}}dropdownBasic1"
														ngbDropdownToggle>Action</button>
													<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
														<button type="submit" (click)="getDetails();"
															class="btn btn-success m-r-10 dropdown-item"
															id="{{pageId}}">Get
															Report</button>
														<button type="submit" (click)="printDetails();"
															class="btn btn-success m-r-10 dropdown-item"
															id="{{pageId}}">Print</button>
														<button (click)="exportDetails();"
															class="btn btn-success m-r-10 dropdown-item"
															id="{{pageId}}">Export</button>
														<button type="submit" class="btn btn-dark dropdown-item"
															id="{{pageId}}finishBtn"
															(click)="confirmFinish();">Finish</button>
														<!-- 														<button (click)="downloadMyFile()">download File -->
														<!-- 														</button> -->
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-7 vl p-t-10">
						<!-- 					style="border-bottom: 1px solid orange;"> -->
						<div class="box-body">
							<h6 class="imb_patch card-title text-white">Agent Due
								Details</h6>
							<!-- 							the first datatble starts -->
							<table datatable id="{{pageId}}agentDueTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsAgetnsDueDetails" [dtTrigger]="dtTriggerAgetnsDueDetails">

								<thead>
									<tr>
										<th>Station</th>
										<th>Old Dues</th>
										<th>D-Note</th>
										<th>Last Due</th>
										<th>D-Note</th>
										<th>Current Due</th>
										<th>D-Note</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let agetnsDueDetailsData of agetnsDueDetailsDataList ">
										<td>{{ agetnsDueDetailsData.destination }}</td>
										<td>{{ agetnsDueDetailsData.oldDuesFormatted }}</td>
										<td>{{ agetnsDueDetailsData.oldDuesDN }}</td>
										<td>{{ agetnsDueDetailsData.lastDuesFormatted }}</td>
										<td>{{ agetnsDueDetailsData.lastDueDN }}</td>
										<td>{{ agetnsDueDetailsData.currentDuesFormatted }}</td>
										<td>{{ agetnsDueDetailsData.currentDueDN }}</td>
										<td>{{ agetnsDueDetailsData.totalDuesFormatted }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<div class="col-md-5 vl p-t-10">
						<div class="box-body">
							<h6 class="imb_patch card-title text-white">Bank Details</h6>
							<table datatable id="{{pageId}}bankBalTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsBankMaster" [dtTrigger]="dtTriggerBankMaster">

								<thead>
									<tr>
										<th>Bank Name</th>
										<th>Cash</th>
										<th>Chq Amt</th>
										<th>Tot Balance</th>
										<th>Prov. Chq Amt</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let bankMasterData of bankMasterDataList ">
										<td>{{ bankMasterData.bankName }}</td>
										<td>{{ bankMasterData.paymentAmt }}</td>
										<td>{{ bankMasterData.chqAmt }}</td>
										<td>{{ bankMasterData.totalAmount }}</td>
										<td>{{ bankMasterData.proviAmt }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>

					<div class="col-md-6 vl p-t-10" style="padding-bottom: 10px;">
						<!-- 					border-bottom: 1px solid orange; -->
						<div class="box-body">
							<h6 class="imb_patch card-title text-white">Supplier Balance
								Details</h6>
							<table datatable class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsSupplierBalanceDetails"
								[dtTrigger]="dtTriggerSupplierBalanceDetails">

								<thead>
									<tr>
										<th>Supplier Name</th>
										<th>Balance Amt</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let supplierBalanceDetailsData of supplierBalanceDetailsDataList ">
										<td>{{ supplierBalanceDetailsData.supplierName }}</td>
										<td>{{ supplierBalanceDetailsData.balanceAmt }}</td>
									</tr>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<!-- 							the second datatable ends  -->
					<!-- 							the third datatable starts  -->


					<!-- 						the third datatable ends  -->
					<!-- 							the fourth datatable starts  -->

					<div class="col-md-3 vl p-t-10">
						<div class="box-body">
							<h6 class="imb_patch card-title text-white">PDC Details</h6>
							<table datatable id="{{pageId}}pdcTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsPdcDetails" [dtTrigger]="dtTriggerPdcDetails">

								<thead>
									<tr>
										<th>Station</th>
										<th>Balance Amt</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let pdcDetailsData of pdcDetailsDataList ">
										<td>{{ pdcDetailsData.source }}</td>
										<td>{{ pdcDetailsData.paid }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<!-- 							the fourth datatable ends  -->
					<!-- 							the fifth datatable starts  -->

					<div class="col-md-3 vl p-t-10">
						<div class="box-body">
							<div class="row" style="padding-bottom: 5px;">
								<div class="col-md-12">
									<h6 class="imb_patch card-title text-white">Email</h6>
								</div>
								<div class="col-md-12">
									<button type="submit" style="padding: 0px 10px 0px 10px;" class="btn btn-primary"
										id="{{pageId}}sendEmailBtn">Send Email</button>
								</div>
							</div>
							<table datatable class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsEmailId" [dtTrigger]="dtTriggerEmailId">

								<thead>
									<tr>
										<th>Email Id</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let emailIdData of listEmailIds ">
										<td>{{ emailIdData }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!-- 							the fifth datatable ends  -->



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewBngOfcCustomPrint" onafterprint="afterPrint()" id="{{pageId}}viewBngOfcCustomPrint">
		<custom-bangalore-ofc-print></custom-bangalore-ofc-print>
	</div>

</body>

</html>