<html>

<head>
</head>

<body>
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}agentLocalTrpId">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="dd-mm-yyyy" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showReportTypePerRights" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}reportType" name="reportType" #reportType (change)="reportTypeMode(reportType.value)">
                                                            <option selected value="">Select Report Type</option>
                                                            <option value="Received From">Received From</option>
                                                            <option value="Sent To">Sent To</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showFromStations" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}fromStation" type="text" class="form-control" [(ngModel)]="modelFromStation" [ngbTypeahead]="searchFromStation" [resultFormatter]="formatterFromStation" [inputFormatter]="formatterFromStation" (focus)="focusFromStationTA$.next($any($event).target.value)"
                                                            placeholder="Select From Station" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showToStations" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>To Station</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-road"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}deliveryArea" type="text" class="form-control" [(ngModel)]="modelToStation" [ngbTypeahead]="searchToStation" [resultFormatter]="formatterToStation" [inputFormatter]="formatterToStation" (focus)="focusToStationTA$.next($any($event).target.value)"
                                                                placeholder="Select To Station" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 100%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>

                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="row">

                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="dt-button" style="margin-left: 90%;" (click)="customPrintAgentLocalTrp()" id="{{pageId}}printAllBtn">
                                <span><i class="fas fa-print">Print</i></span>
                            </button>
                            <div class="col-md-12 vl">
                                <div class="col-md-12">

                                    <div class="box-body">
                                        <table datatable id="{{pageId}}agentlocaltrp" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentLocalTrip" [dtTrigger]="dtTriggerAgentLocalTrip">

                                            <thead>
                                                <tr>
                                                    <th>Trip Date</th>
                                                    <th>Stock In Date</th>
                                                    <th>Diff Date(Trip-Stock)</th>
                                                    <th>Vehicle No.</th>
                                                    <th>Trip No.</th>
                                                    <th>Trip From</th>
                                                    <th>Booking Center</th>
                                                    <!-- ASR_V1_CHG - starts -->
                                                    <th>Tot LRs</th>
                                                    <th>Tot Articles</th>
                                                    <th>Tot Act Wt</th>
                                                    <th>Tot Chg Wt</th>
                                                    <th [hidden]="!consolidateEwaybillEnabled">Eway Bill</th>
                                                    <!-- ASR_V1_CHG - ends -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let agentLocalTripData of agentLocalTripDataList ">
                                                    <td>{{ agentLocalTripData.tripDateStr }}</td>
                                                    <td>{{ agentLocalTripData.enteredDateStr }}</td>
                                                    <td>{{ agentLocalTripData.diff1 }}</td>
                                                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="agentLocalTripData.eWayBillNo != null">
                                                        {{ agentLocalTripData.vehicleNumber }}
                                                    </td>
                                                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="agentLocalTripData.eWayBillNo == null" style="cursor: pointer; color: blue;text-decoration: underline;">
                                                        <a (click)="vehicleUpdatePopUp
                                                        (contentVehicleUpdate,agentLocalTripData);" class="pointer"> <u>{{
                                                            agentLocalTripData.vehicleNumber }}</u></a>
                                                    </td>


                                                    <td (click)="agentlocaltripDescriptionPopUpBtnLink(agentlocaltripPopUpTemplate,agentLocalTripData)">
                                                        <u class="hyperLink_td">{{agentLocalTripData.localTripNumber}}</u>
                                                    </td>
                                                    <td>{{ agentLocalTripData.tripFrom }}</td>
                                                    <td>{{ agentLocalTripData.deliveryarea }}</td>
                                                    <td>{{ agentLocalTripData.totalLrs }}</td>
                                                    <td>{{ agentLocalTripData.totalArticles }}</td>
                                                    <!-- ASR_V1_CHG - starts -->
                                                    <td>{{ agentLocalTripData.actualWeight }}</td>
                                                    <td>{{ agentLocalTripData.chargedWeight }}</td>
                                                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="agentLocalTripData.eWayBillNo == null" title="Click To Generate Consolidated EWB" style="color: red; cursor: pointer; text-decoration: underline;" (click)="confirmGenerateConsolidateEwaybill(agentLocalTripData.id)">
                                                        Generate</td>
                                                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="agentLocalTripData.eWayBillNo != null" title="Click To Download Consolidated EWB" style="color: green; cursor: pointer; text-decoration: underline;" (click)="printCEWB(agentLocalTripData.eWayBillNo)">
                                                        {{ agentLocalTripData.eWayBillNo }}</td>
                                                    <!-- ASR_V1_CHG - ends -->

                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <!-- ASR_V1_CHG - starts -->
                                                    <td></td>
                                                    <td [hidden]="!consolidateEwaybillEnabled"></td>
                                                    <!-- ASR_V1_CHG - ends -->
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #agentlocaltripPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="margin-left: 975px;" type="button" class="btn btn-dark" id="{{pageId}}printBtnId" (click)="agentLocalTripPopUpPrint()">Print</button>
                                            <button type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="agentLocalTripPopUp()">Cancel</button>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewAgentLocalTriptionPopUp" id="{{pageId}}popupMemoLessRpt">
                                                <app-agent-local-trip-discription-report>
                                                </app-agent-local-trip-discription-report>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- vehicle update popup -->
    <ng-template #contentVehicleUpdate let-c="close " let-d="dismiss ">
        <div class="modal-header ">
            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Change Vehicle Number for Local Trip<b>
					{{setTripNo}}</b> </h6>

            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
				<span aria-hidden="true ">&times;</span>
			</button>
        </div>
        <div class="modal-body ">
            <div class="row ">
                <div class="col-md-12 ">
                    <div class="col-sm-12 col-md-12 ">
                        <div class="control ">
                            <div class="form-group ">
                                <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                    <label>Updated Vehicle Number</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="ti-home "></i>
										</span>
                                    </div>
                                    <input #vehicleNumberUpdate id="{{pageId}}vehicleNumberUpdateId" type="text" class="form-control" (selectItem)="clickListnerForVehicleNumberUpdate($event)" [(ngModel)]="modelVehicleNumberUpdate" [ngbTypeahead]="searchVehicleNumberUpdate" [resultFormatter]="formatterVehicleNumberUpdate"
                                        [inputFormatter]="formatterVehicleNumberUpdate" (focus)="focusVehicleNumberUpdateTA$.next($any($event).target.value)" placeholder="Select Vehicle Number" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <button type="submit " class="btn btn-dark " (click)="updateVehicleBtn() ">Update</button>
                        <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark " id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

    <div *ngIf="viewStockTripsheetPrint" onafterPopUp="afterPopUp()" id="{{pageId}}stockTripsheetPrintIdRpt">
        <app-stock-for-tripsheet-driver-print-rpt-v2>
        </app-stock-for-tripsheet-driver-print-rpt-v2>
    </div>

    <div *ngIf="viewStockTripsheetPrintOld" onafterPopUp="afterPopUp()" id="{{pageId}}stockTripsheetPrintIdRpt">
        <app-stock-for-tripsheet-driver-print-rpt>
        </app-stock-for-tripsheet-driver-print-rpt>
    </div>

</body>

</html>