import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbModalRef, ModalDismissReasons,NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { Router } from '@angular/router';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-route-mapping',
	templateUrl: './route-mapping.component.html',
	styleUrls: ['./route-mapping.component.css']
})
export class RouteMappingComponent implements OnInit {


	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends


	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerRouteMapping: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsRouteMapping: any;



	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	lrDto: LRDto = new LRDto();
	userDataDto: UserDataDto = new UserDataDto();
	showSpinnerForAction = false;
	

	

	lrDtoList: Array<LRDto> = [];


	lrDtoData: LRDto = new LRDto();

	
	cashMemoDtoStationsName: Array<any> = [];
	cashMemoDtoForStationNameList : CashMemoDto = new CashMemoDto();
	cashMemoDtoForStationNameTA:any;
	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	
	chequeAmtSummary: number;
	viewCustomPrintV1: boolean;

	//For Source Station

	
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
	pageId="rtmap";


	constructor(private chequeSearchRpt: ReportService, private router: Router,
		private memoReport: MemoReport, private masterReadService: MasterReadService,
		private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getSourceStationDetails();
			
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				//dtInstance.destroy();
			});
		});
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsRouteMapping = {
			//dom: 'lBfrtip',
			dom: 'Bfrtip',
			buttons: [
				
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					
					title: function () {
						return "Cheque Details Report - " +
							"Type : " + $("#rtmapchequeModeId").val() + " -  " +
							"Date : " + moment(new Date()).format('DD-MM-YYYY') + "";
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 250,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
			
			},
		}
	}
	ngOnDestroy(): void {
		this.dtTriggerRouteMapping.unsubscribe();
		//this.onDestroyUnsubscribtionChequeSearchReport.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerRouteMapping.next();
	}
	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	onKeyPressFieldForChequeReffNo(event) {
		if (event == 13) { $("#"+this.pageId+"searchBtn").focus(); }
	}

	sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#"+this.pageId+"sourceId").val(this.modelSource.subStations);
    }

	getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.mode = "All";
    }
    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log("Source");
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    //this.lrDtoSourceStationAllOption.subStations = "All";
                    //this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    };




//heading logics

stationList(){


this.cashMemoDtoStationsName = ["Chennai","Allepey","Banglore","Mumbai","Vellore"];

console.log(this.cashMemoDtoForCustomPrint);
for (let i = 0; i < this.cashMemoDtoStationsName.length; i++) {
	this.cashMemoDtoForStationNameList = new CashMemoDto();
	//this.cashMemoDtoForStationNameList.stationList = this.cashMemoDtoStationsName[i];	
	this.cashMemoDtoForStationNameTA.push(this.cashMemoDtoForStationNameList);
}
console.log(this.cashMemoDtoForCustomPrint);
}


}