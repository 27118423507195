<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}showAdvancePayemntRpt" *ngIf="isLoggedIn">
        <div *ngIf="showSpinnerForAction" class="col-md-8">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;">
                    </mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Please Wait Loading Details.....</h6>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate" placeholder="dd-mm-yyyy" name="fromDate" ngbDatepicker #fromDates="ngbDatepicker">
                                                <div class="input-group-append" (click)="fromDates.toggle()">
                                                    <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input class="form-control" id="{{pageId}}toDate" placeholder="dd-mm-yyyy" [(ngModel)]="setTodayDateOnToDate" name="toDate" ngbDatepicker #toDates="ngbDatepicker">
                                                <div class="input-group-append" (click)="toDates.toggle()">
                                                    <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                </div>
                                                <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                </div>
                                                <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                    placeholder="Select Destination" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}advancePaymentTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAdvancePayment" [dtTrigger]="dtTriggerAdvancePayment">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>Hireslip No</th>
                                                    <th>Payment Status</th>
                                                    <th>Hireslip Dt</th>
                                                    <th>Truck No</th>
                                                    <th>Owner Name</th>
                                                    <th>Supplier Name</th>
                                                    <th>Driver Name</th>
                                                    <th [hidden]="!showHireAdv">Adv</th>
                                                    <th [hidden]="!showHireBal">Bal</th>
                                                    <th [hidden]="!showHireAmt">Tot Hire</th>
                                                    <th>Payment Req At</th>
                                                    <th>Account Type</th>
                                                    <th>Account Name</th>
                                                    <th>Account Number</th>
                                                    <th>Bank Name</th>
                                                    <th>Branch Name</th>
                                                    <th>IFSC Code</th>
                                                    <th>Ac Proof Doc</th>
                                                    <th>Reason If Rejected</th>
                                                    <!-- <th>Cash</th>
                                                    <th>Bank</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let advancePaymentData of advancePaymentDataList ">
                                                    <td>
                                                        <button *ngIf="advancePaymentData.hireamtbaloktopaystatus == 'In Process'" style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setConfirmPayment(advancePaymentData);">
                                                            <i title="Confirm Payment" class="fas fa-edit"></i>
                                                        </button>
                                                        <button *ngIf="advancePaymentData.hireamtbaloktopaystatus == 'In Process'" style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}rejectPaymentBtn" (click)="rejectPaymentRequest(paymentRequestRejectPopup,advancePaymentData);">
                                                            <i title="Reject Request" class="fas fa-ban"></i>
                                                        </button>
                                                    </td>
                                                    <td>{{ advancePaymentData.fromStation }}</td>
                                                    <td>{{ advancePaymentData.toStation }}</td>
                                                    <td>{{ advancePaymentData.hireslipnumber }}</td>
                                                    <td *ngIf="advancePaymentData.hireamtbaloktopaystatus == 'In Process'" style="color: green;font-size: 15px;font-weight: bold;">{{ advancePaymentData.hireamtbaloktopaystatus }}</td>
                                                    <td *ngIf="advancePaymentData.hireamtbaloktopaystatus == 'Completed'" style="color: blue;font-size: 15px;font-weight: bold;">{{ advancePaymentData.hireamtbaloktopaystatus }}</td>
                                                    <td *ngIf="advancePaymentData.hireamtbaloktopaystatus == 'Rejected'" style="color: red;font-size: 15px;font-weight: bold;">{{ advancePaymentData.hireamtbaloktopaystatus }}</td>
                                                    <td>{{ advancePaymentData.hireSlipDateStr }}</td>
                                                    <td>{{ advancePaymentData.vehicleNumber }}</td>
                                                    <td>{{ advancePaymentData.brokerName }}</td>
                                                    <td>{{ advancePaymentData.supplierName }}</td>
                                                    <td>{{ advancePaymentData.drivername }}</td>
                                                    <td [hidden]="!showHireAdv">{{ advancePaymentData.advance }}</td>
                                                    <td [hidden]="!showHireBal">{{ advancePaymentData.balance }}</td>
                                                    <td [hidden]="!showHireAmt">{{ advancePaymentData.totalhire }}</td>
                                                    <td>{{ advancePaymentData.hireamtbaloktopayatStr }}</td>
                                                    <td>{{ advancePaymentData.acType }}</td>
                                                    <td>{{ advancePaymentData.acHolder }}</td>
                                                    <td>{{ advancePaymentData.acNumber }}</td>
                                                    <td>{{ advancePaymentData.bankname }}</td>
                                                    <td>{{ advancePaymentData.branchName }}</td>
                                                    <td>{{ advancePaymentData.ifscCode }}</td>
                                                    <td>
                                                        <a (click)="viewBankAcProofDoc(advancePaymentData);" style="cursor: pointer;"> <u>{{
                                                            advancePaymentData.hireamtbalbankacproofcopy ==
                                                                        null ? "NA" :
                                                                        advancePaymentData.hireamtbalbankacproofcopy ==
                                                                        "NA" ? "NA" : "View Bank Ac Doc" }}</u></a>
                                                    </td>
                                                    <td style="color: red;font-size: 15px;font-weight: bold;">{{ advancePaymentData.remarks }}</td>
                                                    <!-- <td>{{ advancePaymentData.cashAmt }}</td>
                                                    <td>{{ advancePaymentData.advTotalChqAmt }}</td> -->
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td [hidden]="!showHireAdv"></td>
                                                    <td [hidden]="!showHireBal"></td>
                                                    <td [hidden]="!showHireAmt"></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <!-- <td></td>
                                                    <td></td> -->
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 *ngIf="viewSelectedBalance" class="card-title" style="margin-left:20%">{{selectedHireslipNo}} - {{selectedTruckNo}} - Amount : {{selectedHireBalAmt}}</h5>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                                </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}payemntModeType" #payemntModeType (change)="payemntModeTypeListner(payemntModeType.value)">
                                                                <option value="Cash">Cash
                                                                </option>
                                                                <option selected value="Bank">Bank</option>
                                                            </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Transfer Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                                </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}transferMode" #transferMode (change)="transferModeListner(transferMode.value)">
                                                                <option selected value="RTGS">RTGS</option>
                                                                <option value="NEFT">NEFT</option>
                                                                <option value="SELF">SELF</option>
                                                            </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcNoField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                                </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}chequePdcNo" (keyup)="chequePdcNoEvent($event)" aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequeDueDateField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Due Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="chequeDueDates" ngbDatepicker #chequeDueDates="ngbDatepicker" id="{{pageId}}chequeDueDates" (keyup)="chqDueDateFocus($event)"
                                                            (ngModelChange)="focusChqDueDateCalendar()" [(ngModel)]="modelChequeDueDates" (keydown.arrowdown)="chequeDueDates.toggle();">
                                                        <div class="input-group-append" (click)="chequeDueDates.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>AC Type</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="fas fa-user"></i>
                                                                    </span>
                                                            </div>
                                                            <input #bankName id="{{pageId}}bankNameId" type="text" class="form-control" (selectItem)="clickListnerForBankName($event)" [(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                                                (focus)="focusBankNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="payingDates" ngbDatepicker #payingDates="ngbDatepicker" id="{{pageId}}payingDates" [(ngModel)]="modelPayingDates">
                                                        <div class="input-group-append" (click)="payingDates.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="viewCashChequePdcAmtField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                                </span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}cashChequePdcAmount" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForAmount($event);">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3" *ngIf="showBankAcDetails">
                                        <div class="card card-hover imb_custome">
                                            <div class="card-body" style="background-color: lightcyan;">
                                                <h5 class="card-title">Account Details</h5>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">Ac Type : <span>{{selectedAcType}}</span></label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">Ac No : <span>{{selectedAcNo}}</span></label>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">Bank : <span>{{selectedBankName}}</span></label>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">Ac Holder : <span>{{selectedAcName}}</span></label>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">IFSC : <span>{{selectedIfsc}}</span></label>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <label style="width: 100%;font-size: 15px;">Branch : <span>{{selectedBranch}}</span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-5">
                                        <table datatable id="{{pageId}}advancePaymentCashChequePdcTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAdvancePaymentCashChequePdc" [dtTrigger]="dtTriggerAdvancePaymentCashChequePdc">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let advancePaymentCashChequePdcData of advancePaymentChequePdcDataList let i = index">
                                                    <td>{{ advancePaymentCashChequePdcData.paymentType }}</td>
                                                    <td>{{ advancePaymentCashChequePdcData.amount }}</td>
                                                    <td>
                                                        <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Bank Details" (click)="rowSelectedBankDataOnEdit(advancePaymentCashChequePdcData,i);"></i> &nbsp;&nbsp;
                                                        <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Bank Details" (click)="deleteSelectedBankDetails(i);"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <button type="submit" class="btn btn-success m-r-10" title="Confirm Payment Details" (click)="addIntoFinalTableDetails();">Confirm
                                </button>
                            <button type="submit" class="btn btn-dark m-r-10" title="Clear Bank Details" (click)="overAllClearFieldBtn();">Clear
                                </button>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}bankPaymentDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBankPaymentDetails" [dtTrigger]="dtTriggerBankPaymentDetails">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Hireslip Number</th>
                                                    <th>Trnx Type</th>
                                                    <th>Beneficiary Code</th>
                                                    <th>Account Number</th>
                                                    <th>Bank Trnx Amount</th>
                                                    <th>Cash Trnx Amount</th>
                                                    <th>Beneficiary Name</th>
                                                    <th>Debit Stmt Narration</th>
                                                    <th>Chq/Trnx Date</th>
                                                    <th>IFSC Code</th>
                                                    <th>Beneficiary Email id</th>
                                                    <th [hidden]=true>Trnx Type Code</th>

                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let bankPaymentDetailsData of bankPaymentDetailsList ">
                                                    <td>
                                                        <!-- <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Details" (click)="rowSelectedBankPaymentDataOnEdit(bankPaymentDetailsData,i);"></i> &nbsp;&nbsp; -->
                                                        <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Details" (click)="deleteSelectedBankPaymentDetails(bankPaymentDetailsData);"></i>
                                                    </td>
                                                    <td>{{ bankPaymentDetailsData.hireslipnumber }}</td>
                                                    <td>{{ bankPaymentDetailsData.trnxType }}</td>
                                                    <td>{{ bankPaymentDetailsData.benfCode }}</td>
                                                    <td>{{ bankPaymentDetailsData.acNo }}</td>
                                                    <!-- <td>{{bankPaymentDetailsData.trnxBankAmt | number : '1.2-2'}}</td> -->
                                                    <td>{{ (bankPaymentDetailsData.trnxBankAmt | number : '1.2-2').replaceAll(',', '') }}</td>
                                                    <td>{{ (bankPaymentDetailsData.trnxCashAmt | number : '1.2-2').replaceAll(',', '') }}</td>
                                                    <td>{{ bankPaymentDetailsData.benfName }}</td>
                                                    <td>{{ bankPaymentDetailsData.debitStmtNarrt }}</td>
                                                    <td>{{bankPaymentDetailsData.trnxDate | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{ bankPaymentDetailsData.ifscCode }}</td>
                                                    <td>{{ bankPaymentDetailsData.benfEmail }}</td>
                                                    <td [hidden]=true>{{ bankPaymentDetailsData.trnxTypeCode }}</td>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                    <th [hidden]=true></th>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td [hidden]=true></td>

                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                    <td [hidden]=true></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-success m-r-10" title="Submit Payment Details" (click)="confirmBankSubmit();">Complete</button>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <ng-template #paymentRequestRejectPopup let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Specify the payment reject reason for :<b>
                    {{selectedHireslipNumberForReject}}</b> </h6>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Reason:</label>
                                    <textarea class="form-control" aria-describedby="basic-addon11" #rejectReason id="{{pageId}}rejectReason" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" (click)="confirmReject()">Submit</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</body>

</html>