//CHG-V-LR:01 - @Asrar chg, no consignee copy from all src (except delhi) to all dest, and of specific lr enabled then normal 3 copies @as per Musaddik bh req @20-02-2023
//CHG-V-LR:02 - @Asrar chg, no consignee copy from all src including delhi to all dest @as per Musaddik bh req @05-04-2023

import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { LrService } from 'src/app/dataService/lr-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { HireslipService } from "src/app/dataService/hireslip-service";
import *  as moment from 'moment';
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-automated-search',
    templateUrl: './automated-search.component.html',
    styleUrls: ['./automated-search.component.css']
})
export class AutomatedSearchComponent implements OnInit {

    automatedSearchDataList: any;
    model: NgbDateStruct;
    viewUser = false;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAutomatedSearch: Subject<any> = new Subject();
    dtOptionsAutomatedSearch: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    getUserNamesRead: any;
    showSpinnerForAction: any;
    userNameDtoAllOption: LRDto = new LRDto();
    //For UserName List
    userNameOptions: LRDto[];
    //lrDtoForUsersList: LRDto[];
    lrDtoForUsers: LRDto = new LRDto();

    userDtoForStation: UserDataDto = new UserDataDto();
    lrDtoForStation: LRDto = new LRDto();
    public modelUserName: any;
    userNameTA: Array<LRDto> = [];
    focusUserNameTA$ = new Subject<string>();
    searchUserName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusUserNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.userNameTA
                : this.userNameTA.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterUserName = (x: { userName: string }) => x.userName;
    public modelUserNameFotGet: any;
    bkgAdmin: boolean = false;
    getAdminStationList: any;
    lrDtoForOffice1: LRDto = new LRDto();
    adminstationReturn: any;
    list: string[];
    selectedUsers: any
    selectedDate: any;
    //list: Array<String>;
    lrDtoOffice: LRDto = new LRDto();
    enterSecondService: boolean = false;

    lrdto: LRDto = new LRDto;
    lrDtoForSearch: LRDto = new LRDto;
    lrDtoRePrint: LRDto = new LRDto;
    lrDtoRePrintReturn: any;
    reprint: any;
    showUserName: boolean = false;
    showRePrintBtn: boolean = false;
    selectedLRNo: any;
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

    artSummary: number
    chgWtSummary: number
    actWtSummary: number;
    automatedSearchData: any;
    lrDtoAutoLrPrint: LRDto = new LRDto();
    viewLrEntryPrint = false;
    getvalueForLrPrint: any;
    setValueForLrPrint: LRDto = new LRDto();
    automatedSearch = true;
    enteredLrNumber: any;
    pageId = "atmsc";

    constructor(private router: Router, private masterService: MasterService, private masterReadService: MasterReadService,
        private lrService: LrService, private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef,
        private datePipe: DatePipe) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Get All Automated Lr") {
                        this.bkgAdmin = true;
                    }
                    else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "LR Reprint") {
                        this.reprint = true;
                    }
                }
            }

            if (this.bkgAdmin) {
                this.showUserName = true;
            } else {
                this.showUserName = false;
            }

            if (this.reprint) {
                this.showRePrintBtn = true;
            } else {
                this.showRePrintBtn = false;
            }
            this.enterSecondService = false;
            this.getUserName();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        var groupColumnDestination = 0;
        var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        var dateformate = moment(todayDate).format('DD-MM-YYYY');
        this.dtOptionsAutomatedSearch = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Automated Search - " + dateformate;
                    },
                    exportOptions: {
                        columns: [0, 2, 3, 4, 5, 6]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var count = 0;
                api.column(groupColumnDestination, {
                    page: 'current'
                }).data().each(function (group, i) {
                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));

                            count = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .prop('outerHTML'));
                        count = 0;
                    }
                });
            },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalarticles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(1).footer()).html('Total : ' + data.length);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerAutomatedSearch.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerAutomatedSearch.next();
    }
    searchByMode(searchBy: string) {
        if (searchBy === 'date') {
            this.viewUser = false;
        } else if (searchBy === 'user') {
            this.viewUser = true;
        } else {
            this.viewUser = false;
        }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    userNameListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelUserNameFotGet = e.item;
    }
    getUserName() {
        this.lrDtoForUsers = new LRDto();
        if (this.userDataDtoReturnSession.stationList.length > 0) {
            this.list = [];
            for (let i = 0; i < this.userDataDtoReturnSession.stationList.length; i++) {
                this.list.push(this.userDataDtoReturnSession.stationList[i]);
            }
            this.lrDtoForUsers.list = this.list;
            this.enterSecondService = true;
        } else if (this.bkgAdmin) {
            this.userDtoForStation = new UserDataDto();
            this.userDtoForStation.office = this.userDataDtoReturnSession.office;
            this.userDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
            this.masterService.getAdminStationList(this.userDtoForStation).subscribe(
                (response) => {
                    if (response.length == 0) {
                        swal("Warning", "No Details found !", "warning");
                        return false;
                    } else {
                        console.log(response);
                        this.adminstationReturn = null;
                        this.adminstationReturn = response;
                        this.list = [];
                        for (let i = 0; i < this.adminstationReturn.stationList.length; i++) {
                            console.log(this.adminstationReturn.stationList[i]);
                            this.list.push(this.adminstationReturn.stationList[i]);
                        }
                        this.lrDtoForOffice1.list = this.list;
                        console.log(this.adminstationReturn);
                        this.masterReadService.getUserNameList(this.lrDtoForOffice1).subscribe(
                            (response) => {
                                console.log(response);
                                if (response.length == 0) {
                                    swal("Warning", "No Details found !", "warning");
                                    this.userNameOptions = [];
                                    this.userNameTA = [];
                                } else {
                                    this.userNameOptions = response;
                                    this.userNameTA = [];
                                    $("#" + this.pageId + "UserName").val('');
                                    this.userNameDtoAllOption.userName = "All";
                                    this.userNameTA.push(this.userNameDtoAllOption);
                                    for (let i = 0; i < this.userNameOptions.length; i++) {
                                        this.userNameTA.push(this.userNameOptions[i]);
                                    }
                                }
                            }),
                            (error) => {
                                swal("Error", "Server Problem Occurred While getting Admin StationList", "info");
                            }, () => console.log('done');
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                }, () => console.log('done');
            this.enterSecondService = false
        } else {
            this.list = [];
            this.list.push(this.userDataDtoReturnSession.office);
            this.lrDtoForUsers.list = this.list;
            this.enterSecondService = true;
        }
        if (this.enterSecondService) {
            this.masterReadService.getUserNameList(this.lrDtoForUsers).subscribe(
                (response) => {
                    console.log(response);
                    if (response.length == 0) {
                        swal("Warning", "No Details found !", "warning");
                        this.userNameOptions = [];
                        this.userNameTA = [];
                    } else {
                        this.userNameOptions = response;
                        this.userNameTA = [];
                        $("#" + this.pageId + "UserName").val('');
                        this.userNameDtoAllOption.userName = "All";
                        this.userNameTA.push(this.userNameDtoAllOption);
                        for (let i = 0; i < this.userNameOptions.length; i++) {
                            this.userNameTA.push(this.userNameOptions[i]);
                        }
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    swal("Error", "Server Problem Occurred While getting the consignee details", "info");
                }, () => console.log('done');
        }
    }
    searchMethod() {
        this.lrDtoForSearch = new LRDto;
        this.selectedUsers = $("#" + this.pageId + "userNameID").val();
        this.selectedDate = $("#" + this.pageId + "fromDate").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        if (this.enteredLrNumber != null && this.enteredLrNumber != undefined && this.enteredLrNumber != '') {
            if (this.bkgAdmin) {
                this.lrDtoForSearch.stationType = "bkgAdmin";
                this.lrDtoForSearch.branch = this.userDataDtoReturnSession.office;
                this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            } else {
                this.lrDtoForSearch.stationType = "bkg";
                this.lrDtoForSearch.branch = this.userDataDtoReturnSession.office;
                this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            }
            this.lrDtoForSearch.mode = "lrWise";
            this.lrDtoForSearch.lrNumber = this.enteredLrNumber;
        } else {
            if (this.bkgAdmin == true) {
                if (this.selectedUsers != null && this.selectedUsers != undefined && this.selectedUsers != '' &&
                    this.selectedUsers == "All") {
                    this.lrDtoForSearch.mode = "allLr";
                } else {
                    this.lrDtoForSearch.mode = "specificLr";
                    this.lrDtoForSearch.entryBy = this.selectedUsers;
                }
            }
            else {
                this.lrDtoForSearch.fromdate = this.selectedDate;
                this.lrDtoForSearch.mode = "specificLr";
                this.lrDtoForSearch.entryBy = this.userDataDtoReturnSession.userName;
            }
            this.lrDtoForSearch.fromdate = this.selectedDate;
            this.lrDtoForSearch.autoLRCode = this.userDataDtoReturnSession.autoLRCode;
        }
        this.automatedSearchDataListDetail();
    }

    automatedSearchDataListDetail() {
        this.showSpinnerForAction = true;
        this.lrService.getAutomatedLRDetails(this.lrDtoForSearch).subscribe(
            (response) => {
                $("#" + this.pageId + "autoSearchTable").DataTable().destroy();
                this.showSpinnerForAction = false;
                this.automatedSearchDataList = [];
                if (response.length > 0) {
                    this.automatedSearchDataList = response;
                } else {
                    this.automatedSearchDataList = [];
                    swal("No Records", "No Records found for this search", "info");
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerAutomatedSearch.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server Problem Occurred While getting the Lr Enquiry Advance Details", "info");
            }, () => console.log('done');
    }

    clearMethod() {
        $("#" + this.pageId + "autoSearchTable").DataTable().destroy();
        this.automatedSearchDataList = [];
        this.dtTriggerAutomatedSearch.next();
        $("#" + this.pageId + "userNameID").val('');
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "lrNumber").val('');
        this.modelUserName = null;
        this.selectedDate = '';
    }

    customPrintLrHistoryRpt() {
        if (this.automatedSearchDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Destination", "Consignor", "Consignee"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 30, 30];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.artSummary = this.chgWtSummary = this.actWtSummary = 0;
            for (let i = 0; i < this.automatedSearchDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.automatedSearchDataList[i].lrnumber, this.automatedSearchDataList[i].destination, this.automatedSearchDataList[i].consignorName, this.automatedSearchDataList[i].consigneeName];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.artSummary = this.artSummary + this.automatedSearchDataList[i].totalArticles;
                this.chgWtSummary = this.chgWtSummary + this.automatedSearchDataList[i].chargeWeight;
                this.actWtSummary = this.actWtSummary + this.automatedSearchDataList[i].actualWeight;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.automatedSearchDataList.length, "", "", ""];
            }
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date"];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["User Name"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrDtoForSearch.fromdate];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "User Lr Entry Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    lrRePrint(automatedSearchData) {
        this.selectedLRNo = automatedSearchData.lrnumber;
        this.getDataForLrPrint();
    }

    enableRePrint(automatedSearchData) {
        this.selectedLRNo = automatedSearchData.lrnumber;
        if (this.selectedLRNo == null || this.selectedLRNo == undefined || this.selectedLRNo == '') {
            swal("Lr No", "Please Select a Lr No", "info");
            return false;
        } else {
            this.lrDtoRePrint = new LRDto;
            this.lrDtoRePrint.lrNumber = this.selectedLRNo;
            this.lrDtoRePrint.userName = this.userDataDtoReturnSession.userId;
            this.lrService.updateLrPrintRights(this.lrDtoRePrint).subscribe(
                (response) => {
                    console.log(this.lrService.updateLrPrintRights);
                    if (response.status == "Success") {
                        swal("Re-print", "Successfully Updated", "info");
                        this.selectedLRNo = null;
                    } else if (response.status == "Notexist") {
                        swal("Re-print", "LR No Does Not Exist", "info");
                    }
                    else {
                        swal("Re-print", "Updation Failure");
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {

                    swal("Error", "Server Problem Occurred While getting the Lr Enquiry Advance Details", "info");
                }, () => console.log('done');
        }
    }

    getDataForLrPrint() {
        if (this.selectedLRNo == null || this.selectedLRNo == undefined || this.selectedLRNo == '') {
            swal("Lr No", "Please Select a Lr No", "info");
            return false;
        } else {
            this.lrDtoAutoLrPrint = new LRDto;
            this.lrDtoAutoLrPrint.lrNumber = this.selectedLRNo;
            this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;
            this.showSpinnerForAction = true;
            console.log(this.lrDtoAutoLrPrint);
            this.hireslipService.getAutoLrDetails(this.lrDtoAutoLrPrint).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    console.log(response);
                    if (response.lrNumber != '' && response.lrNumber != null) {
                        this.getvalueForLrPrint = '';
                        this.getvalueForLrPrint = response;
                        this.setValueForLrPrint = new LRDto;
                        this.setValueForLrPrint = this.getvalueForLrPrint;
                        this.setValueForLrPrint.lrEntryPrintMode = 'duplicate';

                        //CHG-V-LR:01
                        //CHG-V-LR:02
                         //revert to delhi again as per Musaddik bh
                        let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
                        if (MainAdminStation != null && MainAdminStation == 'delhi') {
                            this.printMethod(this.setValueForLrPrint);
                        } else {
                            if (this.setValueForLrPrint.ccCopyEnabled == true) {
                                this.printMethod(this.setValueForLrPrint);
                            } else {
                                this.printMethodV2(this.setValueForLrPrint);
                            }
                        }
                    } else {
                        swal("Empty", "No Data Found", "info");
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "", "info");
                }, () => console.log('done');
        }
    }

    printMethod(setValueForLrPrint) {
        swal({
            title: "Print Confirmation",
            text: "Sure You Want To Take All 3 Copies Print Out Of LR No:" + this.selectedLRNo,
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((All) => {
            if (All) {
                //For All 
                if ((this.getvalueForLrPrint.lrConsigneeCopy == false) && (this.getvalueForLrPrint.lrConsignorCopy == false) && (this.getvalueForLrPrint.lrLorryCopy == false)) {
                    this.printcopy(setValueForLrPrint, "ALL")
                } else {
                    swal("Alert", "LR Print is Blocked, Please Contact Booking Administrator", "info");
                }
            } else {
                if (this.getvalueForLrPrint.lrConsignorCopy == false) {
                    console.log('consignor');
                    this.printTypeMtdForConsignor(setValueForLrPrint, "CONSIGNOR COPY");
                } else {
                    if (this.getvalueForLrPrint.lrConsigneeCopy == false) {
                        console.log('consignee');
                        this.printTypeMtdForConsignee(setValueForLrPrint, "CONSIGNEE COPY");
                    } else {
                        if (this.getvalueForLrPrint.lrLorryCopy == false) {
                            console.log('lorry');
                            this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
                        }
                    }
                }
            }
        });
    }

    printMethodV2(setValueForLrPrint) {
        swal({
            title: "Print Confirmation",
            text: "Sure You Want To Take All 2 Copies Print Out Of LR No:" + this.selectedLRNo,
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((All) => {
            if (All) {
                //For All
                if ((this.getvalueForLrPrint.lrConsignorCopy == false) && (this.getvalueForLrPrint.lrLorryCopy == false)) {
                    this.printcopy(setValueForLrPrint, "CgnorLry")
                } else {
                    swal("Alert", "LR Print is Blocked, Please Contact Booking Administrator", "info");
                }
            } else {
                if (this.getvalueForLrPrint.lrConsignorCopy == false) {
                    console.log('consignor');
                    this.printTypeMtdForConsignor(setValueForLrPrint, "CONSIGNOR COPY");
                } else {
                    if (this.getvalueForLrPrint.lrLorryCopy == false) {
                        console.log('lorry');
                        this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
                    }
                }
            }
        });
    }

    printTypeMtdForConsignor(setValueForLrPrint, lRType) {
        swal({
            title: "Print Confirmation",
            text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.selectedLRNo,
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((consignor) => {
            if (consignor) {
                this.printcopy(setValueForLrPrint, lRType)
            } else {
                if (this.getvalueForLrPrint.lrConsigneeCopy == false) {
                    console.log('consignee');
                    this.printTypeMtdForConsignee(setValueForLrPrint, "CONSIGNEE COPY");
                } else {
                    if (this.getvalueForLrPrint.lrLorryCopy == false) {
                        console.log('lorry');
                        this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
                    }
                }
            }
        });
    }

    printTypeMtdForConsignee(setValueForLrPrint, lRType) {
        swal({
            title: "Print Confirmation",
            text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.selectedLRNo,
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((consignee) => {
            if (consignee) {
                this.printcopy(setValueForLrPrint, lRType)
            } else {
                if (this.getvalueForLrPrint.lrLorryCopy == false) {
                    console.log('lorry');
                    this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
                }
            }
        });
    }

    printTypeMtdForLorry(setValueForLrPrint, lRType) {
        swal({
            title: "Print Confirmation",
            text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.selectedLRNo,
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((lorry) => {
            if (lorry) {
                this.printcopy(setValueForLrPrint, lRType)
            } else {
            }
        });
    }

    printcopy(setValueForLrPrint, lRType) {
        console.log('printcopy -1');
        localStorage.clear();
        localStorage.setItem('printAfterSaveFromAutomatedSearchData', JSON.stringify(setValueForLrPrint));
        localStorage.setItem('printAfterSaveFromAutomatedSearchType', JSON.stringify(lRType));
        this.viewLrEntryPrint = true;
        this.automatedSearch = false;

        //
        // if (window.matchMedia) {
        //     var mediaQueryList = window.matchMedia('print');
        //     mediaQueryList.addListener(function (mql) {
        //         if (mql.matches) {
        //             console.log('beforePrint');
        //         } else {
        //             console.log('afterPrint');
        //         }
        //     });
        // }

        // window.onafterprint = function () {
        //     if (this.viewLrEntryPrint) {
        //         this.viewLrEntryPrint = false;
        //         this.automatedSearch = true;
        //         console.log('printcopy -2');
        //         console.log(this.viewLrEntryPrint);
        //         localStorage.clear();
        //         this.selectedLRNo = '';
        //         this.getvalueForLrPrint = '';
        //         this.setValueForLrPrint = new LRDto;
        //         window.close();
        //     }

        // }
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewLrEntryPrint) {
                this.viewLrEntryPrint = false;
                this.automatedSearch = true;
                console.log('printcopy -2');
                console.log(this.viewLrEntryPrint);
                localStorage.clear();
                this.selectedLRNo = '';
                this.getvalueForLrPrint = '';
                this.setValueForLrPrint = new LRDto;
                this.changeDetectorRef.detectChanges();
            }
        });
        this.changeDetectorRef.detectChanges();
        console.log('printcopy -3');
        console.log(this.viewLrEntryPrint);
        console.log('printcopy - 4');
    }
    checkLrNo(e, el) {
        if (e.keyCode == 13) {
            this.searchMethod();
        }
    }

}
