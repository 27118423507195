<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Company Common Charges Details</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Service Tax(%)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
													</div>
													<input #serviceTax
														(keyup)="fieldFocus($event, serviceTaxAmountLimit)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}serviceTax"
														name="serviceTax" [(ngModel)]="rateMasterDto.serviceTax">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Serviice Tax Amount Limit(Rs)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-rupee-sign"></i>
														</span>
													</div>
													<input #serviceTaxAmountLimit
														(keyup)="fieldFocus($event, tds)" type="number"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}serviceTaxAmountLimit" name="serviceTaxAmountLimit"
														[(ngModel)]="rateMasterDto.serviceTaxAmt">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>TDS(%)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
													</div>
													<input #tds (keyup)="fieldFocus($event, waitingHours)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}tds" name="tds"
														[(ngModel)]="rateMasterDto.tdsChg">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Waiting Hours(Use For Driver Rewarding)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-clock"></i>
														</span>
													</div>
													<input #waitingHours
														(keyup)="fieldFocus($event, maxActualWeight)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}waitingHours"
														name="waitingHours" [(ngModel)]="rateMasterDto.waitingHrs">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Max Actual Weight</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-weight"></i>
														</span>
													</div>
													<input #maxActualWeight
														(keyup)="fieldFocus($event, maxChargedWeight)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}maxActualWeight"
														name="maxActualWeight"
														[(ngModel)]="rateMasterDto.maxActWgt">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Max Charged Weight</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-weight"></i>
														</span>
													</div>
													<input #maxChargedWeight
														(keyup)="fieldFocus($event, rateChgWgt)" type="number"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}maxChargedWeight" name="maxChargedWeight"
														[(ngModel)]="rateMasterDto.maxChgWgt">
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Rate/ChgWgt</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-rupee-sign"></i>
														</span>
													</div>
													<input #rateChgWgt
														(keyup)="fieldFocus($event, ddMinimumAmountOnLrEntry)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}rateChgWgt"
														name="rateChgWgt"
														[(ngModel)]="rateMasterDto.ratePerChgWgt">
												</div>
											</div>
										</div>
										<!-- 										<div class="col-sm-12 col-md-12"> -->
										<!-- 											<div class="form-group"> -->
										<!-- 												<div class="input-group"> -->
										<!-- 													<label>Rate/ChgWgt</label> -->
										<!-- 													<div class="input-group-prepend"> -->
										<!-- 														<span class="input-group-text"> <i -->
										<!-- 															class=" fas fa-rupee-sign"></i> -->
										<!-- 														</span> -->
										<!-- 													</div> -->
										<!-- 													<input #ratePerChgWgt -->
										<!-- 														(keyup)="fieldFocus($event, ddMinimumAmountOnLrEntry)" -->
										<!-- 														type="number" class="form-control" -->
										<!-- 														aria-describedby="basic-addon11" name="rateChgWgt" -->
										<!-- 														id="{{pageId}}rateChgWgt" [(ngModel)]="rateMasterDto.ratePerChgWgt"> -->
										<!-- 												</div> -->
										<!-- 											</div> -->
										<!-- 										</div> -->

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>DD Minimum Amount On LR Entry</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-rupee-sign"></i>
														</span>
													</div>
													<input #ddMinimumAmountOnLrEntry
														(keyup)="fieldFocus($event, ddMinimumPercOnLrEntryOnChgWgt)"
														type="number" class="form-control"
														id="{{pageId}}ddMinimumAmountOnLrEntry"
														aria-describedby="basic-addon11"
														name="ddMinimumAmountOnLrEntry"
														[(ngModel)]="rateMasterDto.ddMinAmtOnLr">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>DD Minimum Perc On LR Entry(On Chg Wgt)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-rupee-sign"></i>
														</span>
													</div>
													<input #ddMinimumPercOnLrEntryOnChgWgt
														(keyup)="fieldFocus($event, ddFlowInLrEntry)"
														type="number" class="form-control"
														id="{{pageId}}ddMinimumPercOnLrEntryOnChgWgt"
														aria-describedby="basic-addon11"
														name="ddMinimumPercOnLrEntryOnChgWgt"
														[(ngModel)]="rateMasterDto.ddMinPercOnLr">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>DD Flow in LR Entry</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-weight"></i>
														</span>
													</div>
													<select #ddFlowInLrEntry class="custom-select col-12"
														id="{{pageId}}ddFlowInLrEntry" name="ddFlowInLrEntry"
														[(ngModel)]="rateMasterDto.isDDFlowApplicableOnLr">
														<option value="true">Yes</option>
														<option value="false">No</option>
													</select>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
							(click)="validateCommonCharges()">Save</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
							(click)="clearAll()">Clear</button>
					</div>
				</div>
				<div class="col-md-9 vl">
					<!-- <div class="row">
            <!-- column
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <form class="w-50 m-b-10">
                    <input type='text' class="form-control" placeholder='Type to filter the name column...' (keyup)='updateFilter($event)' />
                  </form>
                  <div class="card-header bg-info" style="background-color:#ffbd46!important;padding:5px;">
                    <h6 class="card-title text-white"> LR Details</h6>
                  </div>
                  <ngx-datatable #tables class='material striped' [columns]="columns" [columnMode]="'force'" [headerHeight]="25" [footerHeight]="25"
                    [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
                  </ngx-datatable>

                </div>
              </div>
            </div>
          </div> -->

					<!-- <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Datepicker in a popup</h3>
                <h6 class="card-subtitle">This is datepicker with popup</h6>
                <form class="form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary no-shadow" (click)="d.toggle()" type="button">
                          <i class="fa fa-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <!-- <hr/>
                <pre>Model: {{ model2 | json }}</pre> 
              </div>
            </div>
          </div> -->
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
