<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Automated Lr Hold Details</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-12 col-md-7">
							<div class="form-group">
								<div class="input-group ">
									<label>Total Lr's</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i class="icon-speech"></i>
										</span>
									</div>
									<input type="number" id="{{pageId}}totalLrs" class="form-control"
										[readonly]="readonlyLrNo" aria-describedby="basic-addon11">
								</div>
							</div>
						</div>

						<div class="col-sm-12 col-md-5">
							<button type="submit" class="btn btn-dark"
								style="padding: 1px; font-size: 12px" id="{{pageId}}searchBtn"
								[disabled]="readonlyLrNo" (click)="validateBtnSubmit();">Submit</button>
							<button type="submit" style="padding: 1px; font-size: 12px"
								class="btn btn-success m-r-10" id="{{pageId}}clearBtn"
								(click)="validateClear()">Clear</button>

						</div>
					</div>
					<div class="row">
						<div class="box-body">
							<h6>
								<strong>Multiple LR's Block</strong>
							</h6>
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails"
								[dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<th>LR Number</th>
										<th>Blocked By</th>
										<th>Blocked Date</th>

									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsData of lrDetailsDataList ">
										<td (click)="goToLrEntryPage(lrDetailsData)"><u
											class="hyperLink_td">{{ lrDetailsData.lrNumber }}</u></td>
										<td>{{ lrDetailsData.userName }}</td>
										<td>{{ lrDetailsData.bookingDate }}</td>

									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>

									</tr>
								</tfoot>
							</table>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<div mat-dialog-actions style="float: right;">

	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
