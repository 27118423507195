<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Final Notice Pending Email
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>

<!-- <div class="col-md-12 col-sm-12">
	<i title="Reload" class="fas fa-sync-alt fa xs cli"
		style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood; cursor: pointer;"
		(click)="getDetails()"></i>
</div> -->
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}finalNoticeEmailTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsFinalNoticePendingEmail"
			[dtTrigger]="dtTriggerFinalNoticePendingEmail">

			<thead>
				<tr>
					<th>Consignee Name</th>
					<th style="text-align: center;">Action</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let finalNoticePendingEmailData of finalNoticePendingEmailDataList ">
					<td>{{ finalNoticePendingEmailData.consigneeName }}</td>
					<td style="white-space: nowrap;"><button
							(click)="openModalPrint(contentPrint)" type="submit"
							class="btn_custome" id="{{pageId}}printBtn">Print</button>
						<button (click)="openModalEmail(contentEmail)" type="submit"
							class="btn_custome" id="{{pageId}}emailBtn">Email</button>
						<button (click)="openModalPrintAndEmail(contentPrintAndEmail)"
							type="submit" class="btn_custome" id="{{pageId}}printAndEmailBtn">Print&Email</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>


<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetails">
			<ng-template #contentPrint let-yes="close" let-no="close"
				let-d="dismiss"> <!-- 			<div class="modal-header"> -->

			<!-- 			</div> -->
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<h5 class="m-b-10">Sure You Want To Print</h5>
						<button type="button" class="btn btn-outline-secondary"
							(click)="yes('Yes click')">Yes</button>
						<button type="button" class="btn btn-outline-danger"
							(click)="no('No click')">No</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetailsTwo">
			<ng-template #contentEmail let-yes="close" let-no="close"
				let-d="dismiss">
			<div class="modal-header">

				<h5>- Email Id is In Complete in Consignee Master</h5>
			</div>
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<h5 class="m-b-10">You Want to Update</h5>
						<button type="button" class="btn btn-outline-secondary"
							(click)="yes('Yes click')">Yes</button>
						<button type="button" class="btn btn-outline-danger"
							(click)="no('No click')">No</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetailsThree">
			<ng-template #contentPrintAndEmail let-yes="close" let-no="close"
				let-d="dismiss"> <!-- 			<div class="modal-header"> -->
			<!-- 				<button type="button" class="close" aria-label="Close" --> <!-- 					(click)="d('Cross click')"> -->
			<!-- 					<span aria-hidden="true">&times;</span> --> <!-- 				</button> -->
			<!-- 			</div> -->
			<div class="modal-body">
				<div class="row" style="text-align: center;">
					<div class="col-md-12">
						<h5 class="m-b-10">Sure You Want To Print And Send Email</h5>
						<button type="button" class="btn btn-outline-secondary"
							(click)="yes('Yes click')">Yes</button>
						<button type="button" class="btn btn-outline-danger"
							(click)="no('No click')">No</button>
					</div>
				</div>
			</div>
			</ng-template>


		</div>
	</div>
</div>