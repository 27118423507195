<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLetterHeadRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Cashmemo LetterHead Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewBy" name="viewBy"
															#viewBy (change)="viewByMode(viewBy.value)">
															<option selected value="dateView">Date View</option>
															<option value="lrView">LR View</option>
															<option value="consigneeView">Consignee View</option>
															<option value="consignorView">Consignor View</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input id="{{pageId}}fromDate"
															class="form-control" placeholder="yyyy-mm-dd"
															name="fromDates" [(ngModel)]="fromDatesModal" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker" [(ngModel)]="toDatesModal">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewLrNoWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}lrNo">
														<label>LR No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-clipboard"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}lrNumber" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="showDest" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																(selectItem)="consigneeDestListener($event)"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="showGodown" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Godown</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}localDestinationId" type="text" class="form-control"
															[(ngModel)]="modelLocalDestination"
															[ngbTypeahead]="searchLocalDestination"
															[resultFormatter]="formatterLocalDestination"
															[inputFormatter]="formatterLocalDestination"
															(focus)="focusLocalDestinationTA$.next($any($event).target.value)"
															placeholder="Select Godown.." />
													</div>
												</div>
											</div>
											<div *ngIf="viewConsigneeWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}consigneeGstNo">
														<label>Consignee GST No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
														</div>
														<input #consigneeGSTNumber
															(keyup)="getConsigneeGstNumber($event)" type="text"
															id="{{pageId}}consigneeGSTNumber" class="form-control"
															placeholder="Enter Consignee GST Number"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewConsigneeWise" class="col-sm-12 col-md-12">

												<div class="form-group">
													<div class="input-group">
														<label>Consignee Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #consigneeName id="{{pageId}}consigneeName" name="consigneeName"
															type="text" class="form-control"
															placeholder="Select Consignee Name"
															[(ngModel)]="modelConsigneeName"
															[ngbTypeahead]="consigneeNameSearchTA"
															(selectItem)="rowSelectedConsignee($event)"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterConsigneeName"
															[inputFormatter]="formatterConsigneeName"
															(focus)="focusConsigneeNameTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>

											</div>
											<div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}consignorGstNo">
														<label>Consignor GST No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
														</div>
														<input #consignorGSTNumber
															placeholder="Enter Consignor GST Number"
															(keyup)="getConsignorGstNumber($event)" type="text"
															class="form-control" id="{{pageId}}consignorGSTNumber"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Consignor Index</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex"
															type="text" class="form-control"
															placeholder="Select Consignor Index"
															(selectItem)="consignorDropDownListner($event)"
															[ngbTypeahead]="consignorIndexSearchTA"
															(focus)="focusConsignorIndexTA$.next($any($event).target.value)"
															(click)="clickTA(instanceConsignorIndex)"
															#instanceConsignorIndex="ngbTypeahead" />
													</div>
												</div>
											</div>
											<div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">

												<div class="form-group">
													<div class="input-group">
														<label>Consignor Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #consignorName id="{{pageId}}consignorName" name="consignorName"
															type="text" class="form-control"
															placeholder="Select Consignor Name"
															[(ngModel)]="modelConsignorName"
															[ngbTypeahead]="consignorNameSearchTA"
															(selectItem)="rowSelectedConsignor($event)"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterConsignorName"
															[inputFormatter]="formatterConsignorName"
															(focus)="focusConsignorNameTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>

											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="searchMethod()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearMethod()">Clear</button>
						</div>
					</div>

					<!-- 					 <div class="col-md-9 vl p-t-10"> -->
					<!-- 						<div class="box-body"> -->
					<!-- 							<table datatable -->
					<!-- 								class="table table-striped table-bordered row-border hover" -->
					<!-- 								[dtOptions]="dtOptionsLetterHeadRpt" -->
					<!-- 								[dtTrigger]="dtTriggerLetterHeadRpt"> -->

					<!-- 								<thead> -->
					<!-- 									<tr> -->
					<!-- 										<th>LR Number</th> -->
					<!-- 										<th>Memo Number</th> -->
					<!-- 										<th>Memo Date</th> -->
					<!-- 										<th>Memo Amt</th> -->
					<!-- 										<th>Consignee</th> -->
					<!-- 										<th>Consignor</th> -->
					<!-- 										<th>Letter Heads</th> -->
					<!-- 									</tr> -->
					<!-- 								</thead> -->
					<!-- 								<tbody> -->
					<!-- 									<tr *ngFor="let letterHeadRptData of letterHeadRptDataList "> -->
					<!-- 										<td>{{ letterHeadRptData.gcNoteNumber }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.memoNumber }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.memoName }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.memoAmt }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.consigneeName }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.consignor }}</td> -->
					<!-- 										<td>{{ letterHeadRptData.imgURL }}</td> -->

					<!-- 									</tr> -->


					<!-- 									<tr *ngIf = "supList.length==0">
<!-- 														<td colspan="9">No Data To Display</td> -->
					<!-- 													</tr>-->

					<!-- 								</tbody> -->
					<!-- 							</table> -->
					<!-- 						</div> -->
					<!-- 					</div> -->

					<div class="col-md-9 vl">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<!-- 						popup status back starts -->
							<div class="col-md-12">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}popupDetailsLetterHead">
										<ng-template #contentLetterHead let-ok="close" let-d="dismiss">


											<div class="modal-header">
												<h5>Letter Head Recevied Date Setup</h5>
												<button type="button" class="close" aria-label="Close"
													(click)="d('Cross click')">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>

											<div class="modal-body">
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group">
																<label>Received Date</label> <input class="form-control"
																	id="{{pageId}}receivedDate" name="receivedDate"
																	[(ngModel)]="enteredReceivedDate" ngbDatepicker
																	#receivedDate="ngbDatepicker">
																<div class="input-group-append">
																	<button class="btn btn-outline-secondary calendar"
																		(click)="receivedDate.toggle()"
																		type="button"></button>
																</div>
															</div>
														</div>
													</div>


													<div class="col-md-12 p-t-10" style="text-align: right;">
														<button type="button" class="btn btn-outline-secondary"
															(click)="validateApplyBtnPopUp(contentLetterHead);">Apply</button>
														<button type="button" class="btn btn-outline-secondary"
															(click)="ok('Cancel click');closeBtnPopup();">Close</button>


													</div>
												</div>
											</div>
										</ng-template>
									</div>
								</div>
							</div>
							<!-- 														status back popup ends -->



							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8">
												<!-- 												<button type="submit" class="btn btn-primary m-r-10" -->
												<!-- 													style="padding: 1px 4px;" id="{{pageId}}getRetrunLrBtn" -->
												<!-- 													(click)="setReturnLr(contentLetterHead);"> -->
												<!-- 													<i class="fas fa-file-alt"></i> Return Lr -->
												<!-- 												</button> -->
											</div>
										</div>
										<table width=100%>
											<tr>
												<td width=80%>
													<h6 class="card-title">CashMemo LetterHead Report</h6>
												</td>
												<td width=20%> 
													<button type="submit" class="dt-button" style="margin-left: 60%;"
														(click)="customPrintLetterHeadRpt()" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
												</td>
											</tr>

										</table>

										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}letterHeadRptTable"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsLetterHeadRpt"
														[dtTrigger]="dtTriggerLetterHeadRpt">

														<thead>
															<tr>
																<th>LR Number</th>
																<th>Memo Number</th>
																<th>Memo Date</th>
																<th>Memo Amt</th>
																<th>Consignee</th>
																<th>Consignor</th>
																<th>Letter Heads</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let letterHeadRptData of letterHeadRptDataList; let i = index">
																<td>{{ letterHeadRptData.gcNoteNumber }}</td>
																<td>{{ letterHeadRptData.memoNumber }}</td>
																<td>{{ letterHeadRptData.memoName }}</td>
																<td>{{ letterHeadRptData.memoAmt }}</td>
																<td>{{ letterHeadRptData.consigneeName }}</td>
																<td>{{ letterHeadRptData.consignor }}</td>
																<!--
																	<td><a (click)="getImg(letterHeadRptData);" class="pointer">
																		<u>{{ letterHeadRptData.imgURL }}</u></a>
																</td>-->
																<td><a (click)="getImg(letterHeadRptData,letterHeadPopUpTemplate);"
																		class="pointer">
																		<u>{{ letterHeadRptData.imgURL == null ? "Letter
																			Head Document Image Not Available" :
																			"View Letter Head Document" }}</u></a>
																</td>
																<td><button type="submit" class="btn btn-primary m-r-10"
																		style="padding: 1px 4px;" id="{{pageId}}getRetrunLrBtn"
																		(click)="setReturnLr(contentLetterHead,letterHeadRptData);">
																		<i class="fas fa-file-alt"></i> Return Lr
																	</button></td>

															</tr>


															<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>


												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<!--POP UP START HERE-->
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #letterHeadPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h5>Letter Head Document Upload SetUp :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Letter Head Document</label>
													<input type="file" id="{{pageId}}letterHeadFileUpload"
														(change)="onFileChangedLetterHead($event)"
														#fileInputLetterHead />
												</div>
											</div>
										</div>
									</div><br>
									<div class="row">
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}uploadBtnId" (click)="uploadLetterHeadBtnPopUp()">Upload
											</button>
										</div>
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnId" (click)="closeLetterHeadBtnPopUp()">Cancel
											</button>
										</div>
										<!--<div class="col-sm-12 col-md-4">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}applyBtnId" (click)="applyLetterHeadBtnPopUp()">Apply
											</button>
										</div>-->
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>