<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Transfer Cash Memo</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">

                    <div class="col-md-9 p-t-10" style="margin-left: 10px;">
                        <div *ngIf="showSpinnerForAction" class="col-md-8">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCashmemoTransfer" [dtTrigger]="dtTriggerCashmemoTransfer">

                                <thead>
                                    <tr>
                                        <th>Cash Memo</th>
                                        <th>Lr Number</th>
                                        <th>Total Articles</th>
                                        <th>Consignee Name</th>
                                        <th>Consignor Name</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Collection Man</th>
                                        <th>Memo Type</th>
                                        <th>Delivery Mode</th>
                                        <th *ngIf="editBtnView">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cashmemoTransferData of cashmemoTransferDataList ">
                                        <td>{{ cashmemoTransferData.memoNumber }}</td>
                                        <td>{{ cashmemoTransferData.lrNumber }}</td>
                                        <td>{{ cashmemoTransferData.totalArticles }}</td>
                                        <td>{{ cashmemoTransferData.consigneeName }}</td>
                                        <td>{{ cashmemoTransferData.consignor }}</td>
                                        <td>{{ cashmemoTransferData.amount }}</td>
                                        <td>{{ cashmemoTransferData.status }}</td>
                                        <td>{{ cashmemoTransferData.collectionMan }}</td>
                                        <td>{{ cashmemoTransferData.memoType }}</td>
                                        <td>{{ cashmemoTransferData.deliveryType }}</td>
                                        <td *ngIf="editBtnView"><button type="submit" class="btn_custome" id="{{pageId}}editBtn">Edit</button></td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>

                    <div class="col-md-2 margin_bt">
                        <div class="box-body">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}transferBtn" (click)="transferBtn()">Transfer</button>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>