//Chg_LIE_V1_=Changed get service for getting only Loading data By Imran On 26062024

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from "src/app/dataService/lr-service";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-loading-invoice-edit',
    templateUrl: './loading-invoice-edit.component.html',
    styleUrls: ['./loading-invoice-edit.component.css']
})
export class LoadingInvoiceEditComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    lrDto: LRDto = new LRDto();
    invoiceNumber: any;
    lrDtoRet: LRDto = new LRDto();
    pageId = "ldginvedit";
    dtTriggerLoadingInvEdit: Subject<any> = new Subject();
    dtOptionsLoadingInvEdit: any;
    invoiceDetailsDataList: any;
    enteredInvoiceNumber: any;
    lrDtoForCheckedListDelete: Array<any> = [];
    selectedRows: any;
    lrDtoRemove: LRDto = new LRDto();
    lrDtosRemovingList: LRDto[];

    constructor(private router: Router, private modalService: NgbModal, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit() {
        this.dtOptionsLoadingInvEdit = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 310,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var actualWeight = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargedWeight = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalAmount = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArticles = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodsValue = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(actualWeight);
                $(api.column(6).footer()).html(chargedWeight);
                $(api.column(7).footer()).html(totalAmount);
                $(api.column(8).footer()).html(totalArticles);
                $(api.column(12).footer()).html(goodsValue);
            },
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerLoadingInvEdit.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerLoadingInvEdit.unsubscribe();
    }

    validateForInvoiceNo() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        if (this.invoiceNumber == null || this.invoiceNumber == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter Invoice Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.getLrDetailsOfInvoice();
        }
    }

    setLrDetailsofInvoice() {
        this.lrDto = new LRDto();
        this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.lrDto.invoiceNumber = this.enteredInvoiceNumber;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getLrDetailsOfInvoice = () => {
        this.setLrDetailsofInvoice();
        this.showSpinnerForAction = true;
        //Chg_LIE_V1
        // this.lrService.getLRList(this.lrDto).subscribe(
        this.lrService.getLrDetailsForLoadingInvEdit(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "loadingInvEditTableId").DataTable().destroy();
                this.invoiceDetailsDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No matching LR Details found for the given invoice/ Unable to display Invoice details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.invoiceDetailsDataList = response;
                    console.log("invno list");
                    console.log(this.invoiceDetailsDataList);
                }
                this.dtTriggerLoadingInvEdit.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Invoice LR details", "info");
            }, () => console.log('done');
    };

    rowCheckBoxInvoiceEditClicked(e) {
        this.selectedRows = [];
        for (let i = 0; i < this.invoiceDetailsDataList.length; i++) {
            const invoiceDetailsData = this.invoiceDetailsDataList[i];
            if (invoiceDetailsData.isSelected) {
                this.selectedRows.push(invoiceDetailsData);
            }
        }
        console.log('Selected Rows:', this.selectedRows);
        this.changeDetectorRef.detectChanges();
    }

    clearFields() {
        $("#" + this.pageId + "invoiceNumber").val('');
        $("#" + this.pageId + "loadingInvEditTableId").DataTable().destroy();
        this.invoiceDetailsDataList = [];
        this.dtTriggerLoadingInvEdit.next();
    }

    setForRemoveInvoiceDetails() {
        this.lrDtosRemovingList = [];
        for (let i = 0; i < this.selectedRows.length; i++) {
            this.lrDtoRemove = new LRDto();
            this.lrDtoRemove.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoRemove.lrNumber = this.selectedRows[i].lrNumber;
            this.lrDtoRemove.invoiceNumber = this.selectedRows[i].invoiceNumber;
            this.lrDtosRemovingList.push(this.lrDtoRemove);
        }
        // for (let i = 0; i < this.selectedRows.length; i++) {
        //     this.selectedRows.userName = this.userDataDtoReturnSession.userId;
        //     this.lrDtosRemovingList.push(this.selectedRows);
        // }
        console.log('Removing Rows:', this.lrDtosRemovingList);
    }

    removeFromInvoiceDetail() {
        if (!this.selectedRows || this.selectedRows.length == null || this.selectedRows.length == undefined
            || this.selectedRows.length == 0
        ) {
            swal({
                title: "Select Rows",
                text: "Please select at least one row to remove.",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $('#lsaecinvoiceNumber').focus();
                }, 100);

            });
        } else {
            swal({
                title: "Confirm Delete",
                text: "Sure U want to Delete Invoice Details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.setForRemoveInvoiceDetails();
                    this.lrService.removeLrFromInvoiceDetails(this.lrDtosRemovingList).subscribe(
                        (response) => {
                            if (response.status == "Success") {
                                swal("Success", "Selected details removed", "success");
                                this.getLrDetailsOfInvoice();
                            } else {
                                swal("Alert", "Error while removing from lists", "warning");
                            }
                            this.showSpinnerForAction = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            this.showSpinnerForAction = false;
                            swal("Error", "Server Error While removing from lists", "warning");
                        }, () => console.log('done');
                }
            });
        }
    }

}
