//CHG-V-LRPrint:01 - @Asrar chg, for Edappally append Ernakulam word also @as per Musaddik bh req @31-10-2022
//CHG-V-LRPrint:02 - @Asrar chg, no consignee copy from all src to chennai @as per Musaddik bh req @24-12-2022 - now implemented from all src to all dest @05/04/2023
//CHG-V-LRPrint:03 - @Asrar chg, remove the remarks @as per Musaddik bh req @14-03-2023
//CHG-V-LRPrint:04 - @Asrar chg, add message if cc copy enabled @Musaddik @05/04/2023
//CHG-V-LRPrint:05 - @Asrar chg, if cft range lr then don't print article size just print dots for identifcation @Musaddik @27/08/2023
//CHG-V-LRPrint:06 : Added size exeeded in remarks to shouw no discount in lr entry print (Addded By Imran On 03/08/2024)
//CHG-V-LRPrint:07 : Added New Party(NPSJ) In remarks (Addded By Imran On 13/08/2024)
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-lrentryform-print3',
    templateUrl: './lrentryform-print3.component.html',
    styleUrls: ['./lrentryform-print3.component.css']
})
export class LrEntryFormPrint3Component implements OnInit {

    PrintBarcode = [];
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    dataForPrint: any;
    resultDtos: LRDto = new LRDto();
    showPrintForOtherBooking = false;
    showPrintForLocalBooking = false;
    actualPrintMode = null;
    totPaid = '';
    totToPay = '';
    artSize = '';
    gatePass = '';
    ccAttach = '';
    eSugam = '';
    gateCcESugam = '';
    artChgWt = '';
    handleWitCare = '';
    toBeBilled = '';
    toBeBilled1 = '';
    unloadByCsn = '';
    eDecCom = '';
    gateCc = '';
    selfLr = '';
    showSelfLr = false;
    remakrsConsigneecopy = '';
    remakrsConsignorcopy = '';
    remakrsLorrycopy = '';

    address = '';
    addressSplit: Array<any> = [];
    address1 = '';
    address2 = '';
    city = '';
    pinCode = '';
    phone = '';
    cityPin = '';
    emailId = '';
    subCommodity = '';
    clerkName = '';
    message = '';
    printMode = '';
    //
    mainAddress = '';
    mainMobileNo = '';
    mainEmailId = '';
    transportId = '';
    sourcePrint = '';
    destinationPrint = '';
    entryByPrint = '';
    slash = '';

    ViewToPay = false;
    ViewPaid = false;
    mainStationForPrint = 'LrEntryPrintAll For Single Station';
    slashInCode = '';
    viewLrEntryPrintPage = true;
    lorryCopyShow = false;
    consigneeCopyShow = false;
    consignorCopyShow = false;
    actWgtToDisplay = "";
    chgWgtToDisplay = "";
    toPayGrandTotal: any;
    paidGrandTotal: any;
    dataForPrintforAutomatedSearch: any;
    dataTypeForAutomatedsearch: any;
    printCopyType: any;
    pageBreak1 = true;
    pageBreak2 = true;
    newEwayBillNo = '';
    dataForPrintForBkgDashBoard: any;
    dataTypeForBkgDashBoard: any;
    codChgMsg = '';
    eDecComDot = '';
    dataForPrintForLrEnquiry: any;
    dataTypeForLrEnquiry: any;
    showConsignorCopyWord = true;
    viewNormalConsigneeFont = false;
    viewSmallConsigneeFont = false;
    cneeMobileNo = null;
    viewEwayBillExempted = false;
    pageId = "lefpc";
    viewSingleEwayBill = true;
    viewMultiEwayBill = false;
    viewTwoEwayBill = false;
    newMultiEwayBillNo: any;
    viewEwayBillValidDate = false;
    remakrsOfferMsg = '';
    cftRangeApplicableLR: boolean = false;
    noDiscMsg = '';
    newPartyMessage = '';
    constructor(private router: Router, private datePipe: DatePipe, private http: HttpClient,
        private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                //                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForPrint = JSON.parse(localStorage.getItem('printAfterSaveForLrEntry'));
            this.cftRangeApplicableLR = JSON.parse(localStorage.getItem('printIsCftRangeLR'));
            this.dataForPrintforAutomatedSearch = JSON.parse(localStorage.getItem('printAfterSaveFromAutomatedSearchData'));
            this.dataTypeForAutomatedsearch = JSON.parse(localStorage.getItem('printAfterSaveFromAutomatedSearchType'));
            this.dataForPrintForBkgDashBoard = JSON.parse(localStorage.getItem('printAfterSaveFromBkgDashBoardData'));
            this.dataTypeForBkgDashBoard = JSON.parse(localStorage.getItem('printAfterSaveFromBkgDashBoardType'));
            this.dataForPrintForLrEnquiry = JSON.parse(localStorage.getItem('printAfterSaveFromLrEnquiryData'));
            this.dataTypeForLrEnquiry = JSON.parse(localStorage.getItem('printAfterSaveFromLrEnquiryType'));
            this.showConsignorCopyWord = true;
            localStorage.clear();
            if (this.dataForPrint != null) {
                this.resultDtos = this.dataForPrint;
                this.printCopyType = 'All';
            }
            if (this.dataForPrintforAutomatedSearch != null) {
                this.resultDtos = this.dataForPrintforAutomatedSearch;
                this.printCopyType = this.dataTypeForAutomatedsearch;
            }

            if (this.dataForPrintForBkgDashBoard != null) {
                this.resultDtos = this.dataForPrintForBkgDashBoard;
                this.printCopyType = this.dataTypeForBkgDashBoard;
            }

            if (this.dataForPrintForLrEnquiry != null) {
                this.resultDtos = this.dataForPrintForLrEnquiry;
                this.printCopyType = this.dataTypeForLrEnquiry;
            }


            console.log(this.dataForPrintforAutomatedSearch);
            console.log(this.printCopyType, this.resultDtos);
            this.PrintBarcode = [{
                barcode: this.resultDtos.lrNumber,
                Name: 'A'
            }];

            this.validatBeforePrint();
            this.consigneeNameFontSize();
            this.consigneeMobileNo();
            this.mainStationForPrint = this.resultDtos.mainstation.toLowerCase();
            //            console.log( this.mainStationForPrint );
            //            console.log( this.resultDtos );
            console.log('print3-1');
            if (this.dataTypeForBkgDashBoard == 'BkgDashBoard') {
                console.log('print3-2');
                window.setTimeout(function () {
                    //   window.print();
                }, 1000);
            } else if (this.dataTypeForLrEnquiry == 'LrEnquiry') {
                console.log('print3-3');
                window.setTimeout(function () {
                    //   window.print();
                }, 1000);
            } else {
                console.log('print3-4');
                window.setTimeout(function () {
                    window.scrollTo(0, 0);
                    window.print();
                }, 1000);
            }

            window.onafterprint = function () {
                console.log('print3-5');
                window.close();
            }

            window.addEventListener('afterprint', (event) => {
                if (this.viewLrEntryPrintPage) {
                    console.log('print3-6');
                    this.viewLrEntryPrintPage = false;
                    this.clearPrintFields();
                }
            });
            //            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
            //                for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
            //                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //                        "LrEntryPrintAll For Single Station" ) {
            //                        this.mainStationForPrint = 'LrEntryPrintAll For Single Station';
            //
            //                    }
            //                }
            //            }

        }
    }

    ngOnInit() {
    }
    validatBeforePrint() {
        ///New logic for eway bill no
        this.setEwaybillNologic();

        this.actualPrintMode = this.resultDtos.lrEntryPrintMode;

        //        console.log( this.actualPrintMode );
        //        this.printMode=this.actualPrintMode;
        // 05/10/2020
        // new logic as per Musaddik Bh, only in Consignee and Consignor Copy

        // as per Manoj/Musaddik bh req, for JM show both wgt, no logic
        // @17/06/2021
        if (this.userDataDtoReturnSession.office != null &&
            (this.userDataDtoReturnSession.office == "Dhared - JM" || this.userDataDtoReturnSession.office == "Udyog Nagar - JM")) {
            this.chgWgtToDisplay = "" + this.resultDtos.chargedWeight;
            this.actWgtToDisplay = "" + this.resultDtos.actualWeight;
        } else {
            if (this.resultDtos.actualWeight != this.resultDtos.chargedWeight) {
                this.chgWgtToDisplay = "" + this.resultDtos.chargedWeight;
            } else if (this.resultDtos.actualWeight == this.resultDtos.chargedWeight) {
                this.actWgtToDisplay = "" + this.resultDtos.actualWeight;
            }
        }

        if (this.resultDtos.isUnLoadByConsignee == true) {
            this.unloadByCsn = "Unloaded by consignee    ";
        }
        if (this.resultDtos.remarks != null
            && (this.resultDtos.remarks != "Select")) {
            //            this.unloadByCsn += "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>"
            //                + this.resultDtos.remarks + "</strong>";
            this.unloadByCsn += this.resultDtos.remarks;
        }

        if (this.resultDtos.consigneeMobileNo == null || this.resultDtos.consigneeMobileNo == '') {
            this.resultDtos.consigneeMobileNo = 'NA';
        } else {
            this.resultDtos.consigneeMobileNo = this.resultDtos.consigneeMobileNo;
        }

        if (this.resultDtos.consignorMobileNo == null || this.resultDtos.consignorMobileNo == '') {
            this.resultDtos.consignorMobileNo = 'NA';
        } else {
            this.resultDtos.consignorMobileNo = this.resultDtos.consignorMobileNo;
        }
        if (this.resultDtos.codChg == null) {
            this.resultDtos.codChg = 0;
        } else {
            this.resultDtos.codChg = this.resultDtos.codChg;
        }
        if (this.resultDtos.toBeBilled == true) {
            //Pending
            this.toBeBilled = "background='" + this.userDataDtoReturnSession.ip + "/img/to be.PNG'";
            this.toBeBilled1 = "background='" + this.userDataDtoReturnSession.ip + "/img/billed.PNG'";

        }
        if ((this.resultDtos.isHandWithCare == true) && (this.resultDtos.isCod == true)) {
            this.handleWitCare = "HANDLE WITH CARE - COD";
        } else if (this.resultDtos.isHandWithCare == true) {
            this.handleWitCare = "HANDLE WITH CARE";
        } else if (this.resultDtos.isCod == true) {
            this.handleWitCare = "COD";
        }

        if (this.resultDtos.gatePass == true) {
            this.gatePass = "EXCISE COPY ATTACHED ";
        }
        if (this.resultDtos.ccAttach) {

            this.ccAttach = " C.C.ATTACHED";
        }
        if (this.resultDtos.isCod == true) {
            this.codChgMsg = "COD CONSIGNMENT. PLEASE DO NOT DELIVER WITHOUT COLLECTING THE CONSIGNEE COPY ALONG WITH THE CHEQUE OF GOODS VALUE AND LR FREIGHT.";
        }

        if ((this.resultDtos.gatePass == true) && (this.resultDtos.ccAttach == true)) {
            this.slash = '/';
        }
        else {
            this.slash = '';
        }
        //        getKeralaDestination();
        this.eSugam = this.resultDtos.eWayBillNo == null ? "NA" : this.resultDtos.eWayBillNo == '' ? "NA" : this.resultDtos.eWayBillNo;
        if (this.resultDtos.isDoorDeliv == true) {
            this.eDecCom = "DOOR DELIVERY";
        }

        if (this.resultDtos.selfLr != null && this.resultDtos.selfLr == true) {
            this.selfLr = "SELF LR - DO NOT DELIVER WITHOUT CONSIGNEE COPY";
            this.showSelfLr = true;
        } else {
            this.showSelfLr = false;
        }
        if (this.resultDtos.selfLr != null && this.resultDtos.selfLr == true && this.resultDtos.isDoorDeliv == true) {
            this.eDecComDot = ".";
        }

        if (((this.handleWitCare != null) && (this.handleWitCare != '')) && ((this.resultDtos.selfLr != null) && (this.resultDtos.selfLr == true))) {
            this.slashInCode = ' / ';
        } else {
            this.slashInCode = '';
        }
        this.gateCcESugam = this.eSugam;
        this.gateCc = this.gatePass + this.slash + this.ccAttach;
        //        if ( this.gateCc.endsWith( "/" ) ) {
        //            //Pending
        ////                        this.gateCc = this.gateCc.substring( 0, ( this.gateCc.length() - 1 ) );
        //        }

        // destination address details
        //CHG-V-LRPrint:03
        //CHG-V-LRPrint:04
        if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'CgnorLry')) {
            // this.remakrsConsigneecopy = 'Consignee copy attached';
            this.remakrsConsigneecopy = '';
            this.remakrsLorrycopy = '';
            this.remakrsConsignorcopy = '';
        } else if (this.resultDtos.ccCopyEnabled == true) {
            this.remakrsConsigneecopy = '';
            this.remakrsLorrycopy = 'Do not deliver without collecting the Consignee Copy.';
            this.remakrsConsignorcopy = '';
        } else {
            this.remakrsConsigneecopy = '';
            this.remakrsLorrycopy = '';
            this.remakrsConsignorcopy = '';
        }

        if (this.resultDtos.isOfferLR) {
            this.remakrsOfferMsg = "This LR is in 50% offer scheme, please pay 50% amount of LR total Only.";
        }

        this.address = this.resultDtos.branchAddress == null ? "" : this.resultDtos.branchAddress;

        this.addressSplit = this.address.split(",");
        this.address1 = "";
        this.address2 = "";
        if (this.addressSplit.length > 0) {
            this.address2 = this.addressSplit[this.addressSplit.length - 1] + ",";
            for (let b = 0; b < this.addressSplit.length - 1; b++) {
                this.address1 += this.addressSplit[b] + ",";
            }

        } else {
            this.address1 = this.address;
        }
        this.city = this.resultDtos.branchCity == null ? "" : this.resultDtos.branchCity;
        this.pinCode = this.resultDtos.branchPincode == null ? "" : this.resultDtos.branchPincode.toString();
        this.phone = this.resultDtos.branchPhNo == null ? "NA" : this.resultDtos.branchPhNo
            .toString();
        this.cityPin = this.city + " - " + this.pinCode;
        this.emailId = this.resultDtos.branchEmailId == null ? "NA" : this.resultDtos.branchEmailId;
        console.log('working');
        console.log(this.resultDtos.multiArtSize);
        if (this.resultDtos.multiArtSize != null) {
            //CHG-V-LRPrint:05
            if (this.cftRangeApplicableLR) {
                this.artSize = '..';
            } else {
                this.artSize = this.resultDtos.multiArtSizeForPrint;
            }
            console.log('artSize' + this.artSize);
            console.log('cftRangeApplicableLR' + this.cftRangeApplicableLR);

            //            document.getElementById("multiArtPrint").innerHTML= this.resultDtos.multiArtSizeForPrint;
            //            this.artSize = "</br>VOLUME</br>" + this.artSize.replaceAll( "#", "</br>" );
            //            this.artSize = this.artSize.replaceAll( "_", " X " );

        }

        if (this.resultDtos.chgWtLr != null && (this.resultDtos.chgWtLr != 0)) {
            if (this.artSize == "") {
                this.artChgWt = this.resultDtos.chgWtLr + " Kgs";
            } else {
                this.artChgWt = "+" + this.resultDtos.chgWtLr + " Kgs";
            }
        }

        this.subCommodity = null;
        if (this.resultDtos.subCommodity == "NA") {
            this.subCommodity = "";
        } else {
            this.subCommodity = " (" + this.resultDtos.subCommodity + ")";
            //            console.log( this.resultDtos.subCommodity );
        }
        if (((this.resultDtos.privateMarker != null) && (this.resultDtos.privateMarker != '')) && ((this.resultDtos.billNumber != null) && (this.resultDtos.billNumber != ''))) {
            this.resultDtos.privateMarker = ' / ' + this.resultDtos.privateMarker;
        }
        this.clerkName = null;
        if (this.resultDtos.clerkName != null
            && this.resultDtos.clerkName == "") {
            this.clerkName = "";
        } else {
            this.clerkName = " / " + this.resultDtos.clerkName.toUpperCase();
        }
        this.message = null;
        if (this.actualPrintMode != null && this.actualPrintMode == "duplicate") {
            this.printMode = " DUPLICATE";
            this.message = "This is a duplicate copy, seal & signature required for authentication";
        } else {
            this.printMode = "";
            this.message = this.resultDtos.message;
        }


        if (this.resultDtos.toPay > 0) {
            this.ViewToPay = true;
            this.ViewPaid = false;
            this.toPayGrandTotal = this.resultDtos.grandTotal;
            this.paidGrandTotal = '';
        } else {
            this.ViewToPay = false;
            this.ViewPaid = true;
            this.toPayGrandTotal = '';
            this.paidGrandTotal = this.resultDtos.grandTotal;
        }
        //
        if (this.dataForPrint != null) {
            console.log('this.dataForPrint');
            if ((this.resultDtos.lrEntryMode != null) && (this.resultDtos.lrEntryMode != 'localBookingTrip')) {
                this.mainAddress = this.resultDtos.address == null ? "NA" : this.resultDtos.address;
                //            console.log( 'Main address :' + this.mainAddress );
                this.mainMobileNo = (this.resultDtos.mobileNum == null ? "NA" : this.resultDtos.mobileNum);
                this.mainEmailId = (this.resultDtos.emailId == null ? "NA" : this.resultDtos.emailId);
                this.transportId = (this.resultDtos.transportId == null ? "NA" : this.resultDtos.transportId);
                this.sourcePrint = this.resultDtos.source.toUpperCase();
                this.destinationPrint = this.resultDtos.destination.toUpperCase();
                //CHG-V-LRPrint:01
                if (this.destinationPrint == 'EDAPPALLY') {
                    this.destinationPrint = "EDAPPALLY(ERNAKULAM)";
                }
                this.entryByPrint = this.resultDtos.entryBy.toUpperCase();
                //            console.log( 'entry Print :' + this.entryByPrint );

                //CHG-V-LRPrint:02
                if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'ALL')) {
                    this.showPrintForOtherBooking = true;
                    this.lorryCopyShow = true;
                    this.consigneeCopyShow = true;
                    this.consignorCopyShow = true;
                    //                console.log( this.resultDtos.lrEntryCopyType );
                } else if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'CgnorLry')) {
                    this.showPrintForOtherBooking = true;
                    this.lorryCopyShow = true;
                    this.consigneeCopyShow = false;
                    this.consignorCopyShow = true;
                    this.pageBreak1 = false;
                    this.pageBreak2 = true;
                }
                // update in database

                //            if ( ( ( this.mainStationForPrint == 'LrEntryPrintAll For Single Station' ) && ( this.actualPrintMode == 'original' ) ) || ( ( this.mainStationForPrint == 'LrEntryPrintAll For Different Station' ) && ( this.actualPrintMode == 'duplicate' ) ) ) {
                //                if ( this.resultDtos.lrEntryCopyType = 'ALL' ) {
                //                    this.resultDtos.lrConsigneeCopy = true;
                //                    this.resultDtos.lrConsignorCopy = true;
                //                    this.resultDtos.lrLorryCopy = true;
                //                }
                //                this.updateLrCopyDetails();
                //            }
                console.log(this.resultDtos.mainstation.toLowerCase(), this.actualPrintMode);
                //CHG-V-LRPrint:02
                if (this.resultDtos.mainstation != null
                    && ((this.resultDtos.mainstation.toLowerCase() == 'mumbai' && this.actualPrintMode == 'original') || ((this.resultDtos.mainstation.toLowerCase() == 'delhi' || this.resultDtos.mainstation.toLowerCase() == 'mumbai') && this.actualPrintMode == 'duplicate'))) {
                    if (this.resultDtos.lrEntryCopyType == 'ALL') {
                        this.resultDtos.lrConsigneeCopy = true;
                        this.resultDtos.lrConsignorCopy = true;
                        this.resultDtos.lrLorryCopy = true;
                    } else if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'CgnorLry')) {
                        // this.resultDtos.lrConsigneeCopy = false;
                        this.resultDtos.lrConsigneeCopy = true;
                        this.resultDtos.lrConsignorCopy = true;
                        this.resultDtos.lrLorryCopy = true;
                    }
                    console.log('update');
                    this.updateLrCopyDetails();
                }

            } else {
                this.showPrintForLocalBooking = true;
            }
        }
        console.log('Final :' + this.newEwayBillNo);
        this.newEwayBillNo = this.newEwayBillNo;

        ///
        if (this.dataForPrintforAutomatedSearch != null) {

            this.mainAddress = this.resultDtos.address == null ? "NA" : this.resultDtos.address;
            //  console.log( 'Main address :' + this.mainAddress );
            console.log('this.dataForPrintforAutomatedSearch');
            this.mainMobileNo = (this.resultDtos.mobileNum == null ? "NA" : this.resultDtos.mobileNum);
            this.mainEmailId = (this.resultDtos.emailId == null ? "NA" : this.resultDtos.emailId);
            this.transportId = (this.resultDtos.transportId == null ? "NA" : this.resultDtos.transportId);
            this.sourcePrint = this.resultDtos.source.toUpperCase();
            this.destinationPrint = this.resultDtos.destination.toUpperCase();
            //CHG-V-LRPrint:01
            if (this.destinationPrint == 'EDAPPALLY') {
                this.destinationPrint = "EDAPPALLY(ERNAKULAM)";
            }
            this.entryByPrint = this.resultDtos.entryBy.toUpperCase();
            //            console.log( 'entry Print :' + this.entryByPrint );


            this.showPrintForOtherBooking = true;
            if (this.printCopyType == 'ALL') {
                this.lorryCopyShow = true;
                this.consigneeCopyShow = true;
                this.consignorCopyShow = true;
                this.pageBreak1 = true;
                this.pageBreak2 = true;
            } else if (this.printCopyType == 'LORRY COPY') {
                this.lorryCopyShow = true;
                this.consigneeCopyShow = false;
                this.consignorCopyShow = false;
                this.pageBreak1 = false;
                this.pageBreak2 = false;
            } else if (this.printCopyType == 'CONSIGNEE COPY') {
                this.lorryCopyShow = false;
                this.consigneeCopyShow = true;
                this.consignorCopyShow = false;
                this.pageBreak1 = false;
                this.pageBreak2 = false;
            } else if (this.printCopyType == 'CONSIGNOR COPY') {
                this.lorryCopyShow = false;
                this.consigneeCopyShow = false;
                this.consignorCopyShow = true;
                this.pageBreak1 = false;
                this.pageBreak2 = false;
            } else if (this.printCopyType == 'CgnorLry') {
                this.lorryCopyShow = true;
                this.consigneeCopyShow = false;
                this.consignorCopyShow = true;
                this.pageBreak1 = false;
                this.pageBreak2 = false;
            }

            //                console.log( this.resultDtos.lrEntryCopyType );
            //CHG-V-LRPrint:02
            if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'CgnorLry')) {
                // this.resultDtos.lrConsigneeCopy = false;
                this.resultDtos.lrConsigneeCopy = true;
                this.resultDtos.lrConsignorCopy = true;
                this.resultDtos.lrLorryCopy = true;
            } else {
                this.resultDtos.lrConsigneeCopy = true;
                this.resultDtos.lrConsignorCopy = true;
                this.resultDtos.lrLorryCopy = true;
            }

            this.updateLrCopyDetails();
            /* if ( this.resultDtos.mainstation != null
                 && ( ( this.resultDtos.mainstation.toLowerCase() == 'mumbai' && this.actualPrintMode == 'original' ) || ( ( this.resultDtos.mainstation.toLowerCase() == 'delhi' || this.resultDtos.mainstation.toLowerCase() == 'mumbai' ) && this.actualPrintMode == 'duplicate' ) ) ) {
                
                     this.resultDtos.lrConsigneeCopy = true;
                     this.resultDtos.lrConsignorCopy = true;
                     this.resultDtos.lrLorryCopy = true;
               
                 this.updateLrCopyDetails();
             }*/
        }

        if (this.dataForPrintForBkgDashBoard != null) {
            console.log('heredd');
            this.mainAddress = this.resultDtos.address == null ? "NA" : this.resultDtos.address;
            //  console.log( 'Main address :' + this.mainAddress );
            console.log('this.dataForPrintforAutomatedSearch');
            this.mainMobileNo = (this.resultDtos.mobileNum == null ? "NA" : this.resultDtos.mobileNum);
            this.mainEmailId = (this.resultDtos.emailId == null ? "NA" : this.resultDtos.emailId);
            this.transportId = (this.resultDtos.transportId == null ? "NA" : this.resultDtos.transportId);
            this.sourcePrint = this.resultDtos.source.toUpperCase();
            this.destinationPrint = this.resultDtos.destination.toUpperCase();
            //CHG-V-LRPrint:01
            if (this.destinationPrint == 'EDAPPALLY') {
                this.destinationPrint = "EDAPPALLY(ERNAKULAM)";
            }
            this.entryByPrint = this.resultDtos.entryBy.toUpperCase();
            //            console.log( 'entry Print :' + this.entryByPrint );
            this.showPrintForOtherBooking = true;
            //chg to show from Consignor copy to Lorry copy as per Fayaz Bh req to view both act&chg wgt@20/01/2022
            this.viewEwayBillValidDate = true;
            this.lorryCopyShow = true;
            this.consigneeCopyShow = false;
            this.consignorCopyShow = false;
            this.pageBreak1 = false;
            this.pageBreak2 = false;

            //CHG-V-LRPrint:02
            if ((this.resultDtos.lrEntryCopyType != null) && (this.resultDtos.lrEntryCopyType == 'CgnorLry')) {
                // this.resultDtos.lrConsigneeCopy = false;
                this.resultDtos.lrConsigneeCopy = true;
                this.resultDtos.lrConsignorCopy = true;
                this.resultDtos.lrLorryCopy = true;
            } else {
                this.resultDtos.lrConsigneeCopy = true;
                this.resultDtos.lrConsignorCopy = true;
                this.resultDtos.lrLorryCopy = true;
            }


            this.updateLrCopyDetails();

        }

        if (this.dataForPrintForLrEnquiry != null) {
            console.log('dataForPrintForLrEnquiry');
            this.mainAddress = this.resultDtos.address == null ? "NA" : this.resultDtos.address;
            this.mainMobileNo = (this.resultDtos.mobileNum == null ? "NA" : this.resultDtos.mobileNum);
            this.mainEmailId = (this.resultDtos.emailId == null ? "NA" : this.resultDtos.emailId);
            this.transportId = (this.resultDtos.transportId == null ? "NA" : this.resultDtos.transportId);
            this.sourcePrint = this.resultDtos.source.toUpperCase();
            this.destinationPrint = this.resultDtos.destination.toUpperCase();
            //CHG-V-LRPrint:01
            if (this.destinationPrint == 'EDAPPALLY') {
                this.destinationPrint = "EDAPPALLY(ERNAKULAM)";
            }
            this.entryByPrint = this.resultDtos.entryBy.toUpperCase();
            this.showConsignorCopyWord = false;
            this.showPrintForOtherBooking = true;
            this.lorryCopyShow = false;
            this.consigneeCopyShow = false;
            this.consignorCopyShow = true;
            this.pageBreak1 = false;
            this.pageBreak2 = false;

        }
        console.log("this.resultDtos");
        console.log(this.resultDtos);
        // the below code hardcoded to check 
        // this.resultDtos.isEwayBillExempted=true; 
        if (this.resultDtos.isEwayBillExempted == true) {
            this.viewEwayBillExempted = true;
        }

        //temp, need to remove the below code @07/12/2023
        // new remarks for all to all as per Fayaz bh on 01/01/2024
        console.log(this.destinationPrint);
        // if (this.destinationPrint == 'CHENNAI') {
        console.log('this.destinationPrint');
        this.remakrsOfferMsg = this.resultDtos.extnRemarks;
        // }
        console.log(this.remakrsOfferMsg);
        //CHG-V-LRPrint:06
        if (this.resultDtos.isSizeExceeded) {
            this.noDiscMsg = 'ND & Size Exceed';
        }
       //CHG-V-LRPrint:07
        if (this.resultDtos.isNewConsignee) {
            this.newPartyMessage = 'NPSJ';
        }
    }
    // update in database
    updateLrCopyDetails = () => {
        console.log('inside update method');
        this.masterReadService.updateLrCopyTypeDetails(this.resultDtos).subscribe(
            (response) => {
                if (response.length == 0) {
                    console.log('error')
                } else {
                    console.log('done')
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    setEwaybillNologic() {
        this.newMultiEwayBillNo = null;
        this.viewMultiEwayBill = false;
        this.viewTwoEwayBill = false;
        this.viewSingleEwayBill = true;

        if (this.resultDtos.listOfEWay != null && this.resultDtos.listOfEWay.length > 1) {
            this.viewSingleEwayBill = false;
            if (this.resultDtos.listOfEWay.length > 2) {
                this.viewMultiEwayBill = true;
                this.viewTwoEwayBill = false;
            } else {
                this.viewTwoEwayBill = true;
                this.viewMultiEwayBill = false;
            }
            for (let i = 0; i < this.resultDtos.listOfEWay.length; i++) {
                var ewsMultiple = this.resultDtos.listOfEWay[i];
                if (ewsMultiple != null && ewsMultiple != '') {
                    var setValMultiple = ewsMultiple.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
                    if (i == 0) {
                        this.newMultiEwayBillNo = setValMultiple;
                    } else {
                        this.newMultiEwayBillNo += ", " + setValMultiple;
                    }
                }
                console.log('multi : ' + this.newMultiEwayBillNo);
            }
            console.log('multiple : ' + this.newMultiEwayBillNo);
        } else if (this.resultDtos.eWayBillNo != null && this.resultDtos.eWayBillNo != '') {
            this.viewMultiEwayBill = false;
            this.viewTwoEwayBill = false;
            this.viewSingleEwayBill = true;
            /* var str = this.resultDtos.eWayBillNo;
             var parts = str.match(/.{1,4}/g);
             var new_val= parts.join(" ");
             this.newEwayBillNo =new_val;*/
            // console.log(this.newEwayBillNo);
            var ews = this.resultDtos.eWayBillNo;
            var setVal = ews.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
            console.log(this.newEwayBillNo, setVal);
            this.newEwayBillNo = setVal;
            console.log(this.newEwayBillNo);

        } else {
            this.newEwayBillNo = '';
            this.newMultiEwayBillNo = '';
            this.viewMultiEwayBill = false;
            this.viewTwoEwayBill = false;
            this.viewSingleEwayBill = true;
        }
    }
    consigneeNameFontSize() {
        let consigneeLength = 0;
        // console.log(consigneeLength);
        consigneeLength = this.resultDtos.consigneeName.length;
        // console.log(consigneeLength);
        if (consigneeLength <= 18) {
            this.viewNormalConsigneeFont = true;
        } else {
            this.viewSmallConsigneeFont = true;
        }
        // this.viewNormalConsigneeFont=true;
    }
    consigneeMobileNo() {
        //  console.log("this.resultDtos.consigneeMobileNo");
        //  console.log(this.resultDtos.consigneeMobileNo);
        this.cneeMobileNo = null;
        if (this.resultDtos.consigneeMobileNo != null && this.resultDtos.consigneeMobileNo != "") {
            let cneeMobileNoForSplit = this.resultDtos.consigneeMobileNo;
            // console.log("cneeMobileNoForSplit");
            // console.log(cneeMobileNoForSplit);
            let cneeMobileNoInArray = cneeMobileNoForSplit.split('#');
            // console.log("cneeMobileNoInArray");
            // console.log(cneeMobileNoInArray);
            this.cneeMobileNo = cneeMobileNoInArray[0];
            // console.log("this.cneeMobileNo");
            // console.log(this.cneeMobileNo);
        }
    }
    //For Clearing the Print Fields
    clearPrintFields() {

        this.dataForPrint = '';
        this.dataForPrintforAutomatedSearch = '';
        this.resultDtos = new LRDto();
        this.showPrintForOtherBooking = false;
        this.showPrintForLocalBooking = false;
        this.actualPrintMode = null;
        this.totPaid = '';
        this.totToPay = '';
        this.artSize = '';
        this.gatePass = '';
        this.ccAttach = '';
        this.eSugam = '';
        this.gateCcESugam = '';
        this.artChgWt = '';
        this.handleWitCare = '';
        this.toBeBilled = '';
        this.toBeBilled1 = '';
        this.unloadByCsn = '';
        this.eDecCom = '';
        this.gateCc = '';
        this.selfLr = '';
        this.showSelfLr = false;
        this.remakrsConsigneecopy = '';
        this.remakrsConsignorcopy = '';
        this.remakrsLorrycopy = '';

        this.address = '';
        this.addressSplit = [];
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.pinCode = '';
        this.phone = '';
        this.cityPin = '';
        this.emailId = '';
        this.subCommodity = '';
        this.clerkName = '';
        this.message = '';
        this.printMode = '';
        //
        this.mainAddress = '';
        this.mainMobileNo = '';
        this.mainEmailId = '';
        this.transportId = '';
        this.sourcePrint = '';
        this.destinationPrint = '';
        this.entryByPrint = '';
        this.slash = '';
        this.slashInCode = '';

        this.ViewToPay = false;
        this.ViewPaid = false;
        this.toPayGrandTotal = '';
        this.paidGrandTotal = '';
        this.lorryCopyShow = false;
        this.consigneeCopyShow = false;
        this.consignorCopyShow = false;
        this.actWgtToDisplay = "";
        this.chgWgtToDisplay = "";
        this.printCopyType = '';
        this.dataTypeForAutomatedsearch = '';
        this.newEwayBillNo = '';
        this.dataForPrintForBkgDashBoard = '';
        this.dataTypeForBkgDashBoard = '';
        this.codChgMsg = '';
        this.eDecComDot = '';
        this.dataForPrintForLrEnquiry = '';
        this.dataTypeForLrEnquiry = '';
        this.showConsignorCopyWord = true;
        this.viewNormalConsigneeFont = false;
        this.viewSmallConsigneeFont = false;
        this.viewEwayBillExempted = false;
        this.viewEwayBillValidDate = false;
        //        console.log( 'Its Cleared' );

    }
}
