<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}invoiceDetReportPopupId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Invoice Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <span>Truck No : {{validateTruckNumber}}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <span>Invoice No : {{validateInvoiceNumber}}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <span>From : {{validateSource}}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <span>To : {{validateDestination}}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <span>From Date : {{validateFromDate }}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <span>To Date : {{validateToDate}}</span>
                                            </div>
                                            <br> <br>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Group By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-warehouse"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}groupBy" name="groupBy" #groupBy (change)="groupByMode(groupBy.value)">
															<option selected value="destination">Destination</option>
															<option value="destinationSummary">Destination
																Summary</option>
															<option value="bookingDate">Booking Date</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<label>Gur Wt</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-warehouse"></i>
																</span>
															</div>
															<input type="text" class="form-control" id="{{pageId}}gurWtId"
															name="gurWtId" aria-describedby="basic-addon11"
															autoComplete="off">
														</div>
													</div>
												</div>-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <h6 class="card-title" [hidden]="!hideAndShowInvoiceDetailsDestinationTable">Destination Details
                        </h6>
                        <h6 class="card-title" [hidden]="!hideAndShowInvoiceDestinationSummaryTable">Destination Summary
                        </h6>
                        <h6 class="card-title" [hidden]="!hideAndShowInvoiceDetailsBookingDateTable">Particular BookingDate Details</h6>
                        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- 						<button type="submit" class="dt-button" style="margin-left: 84%;" -->
                        <!-- 							(click)="customPrintInvoiceDetPopupRpt()" id="{{pageId}}printAllBtn" -->
                        <!-- 							[hidden]="!hideAndShowInvoiceDetailsDestinationTable"> -->
                        <!-- 							<span><i class="fas fa-print">Print</i></span> -->
                        <!-- 						</button> -->
                        <div class="box-body" [hidden]="!hideAndShowInvoiceDetailsDestinationTable">
                            <table datatable id="{{pageId}}invoiceDetailsDestinationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceDetailsDestination" [dtTrigger]="dtTriggerInvoiceDetailsDestination">
                                <thead>
                                    <tr>
                                        <th>LR No</th>
                                        <th>SubStation</th>
                                        <th>E-Way Bill Status</th>
                                        <th>E-Way Bill No</th>
                                        <th>Article No</th>
                                        <th>Main Invoice</th>
                                        <th>Booking Date</th>
                                        <th>Booked From</th>
                                        <th>Consignee</th>
                                        <th>Consignor</th>
                                        <th>Destination</th>
                                        <th>Description</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>F O V</th>
                                        <th>Less Amt</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Value of Goods</th>
                                        <th>DD Amt</th>

                                        <th [hidden]="!hidePrint">PvtMark</th>
                                        <th [hidden]="!hidePrint">Case</th>
                                        <th [hidden]="!hidePrint">GCase</th>
                                        <th [hidden]="!hidePrint">Cart</th>
                                        <th [hidden]="!hidePrint">GCart</th>
                                        <th [hidden]="!hidePrint">Bundle</th>
                                        <th [hidden]="!hidePrint">Roll</th>
                                        <th>Consignee GST</th>
                                        <th>Consignor GST</th>
                                        <!--<th>Note **</th>-->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceDetailsData of invoiceDetailsDestinationDataList ">

                                        <td>{{ invoiceDetailsData.lrNumber }}</td>
                                        <td>{{ invoiceDetailsData.subStations }}</td>
                                        <td></td>
                                        <td>{{ invoiceDetailsData.eWayBillNo }}</td>
                                        <td>{{ invoiceDetailsData.totalArticles }}</td>
                                        <td>{{ invoiceDetailsData.invoiceNumber }}</td>
                                        <td>{{ invoiceDetailsData.bookingDateStr }}</td>
                                        <td>{{ invoiceDetailsData.bookedFrom }}</td>
                                        <td>{{ invoiceDetailsData.consigneeName }}</td>
                                        <td>{{ invoiceDetailsData.consignorName }}</td>
                                        <td>{{ invoiceDetailsData.destination }}</td>
                                        <td>{{ invoiceDetailsData.description }}</td>
                                        <td>{{ invoiceDetailsData.toPay }}</td>
                                        <td>{{ invoiceDetailsData.paid }}</td>
                                        <td>{{ invoiceDetailsData.riskCharge }}</td>
                                        <td>{{ invoiceDetailsData.discountAmount }}</td>
                                        <td>{{ invoiceDetailsData.actualWeight }}</td>
                                        <td>{{ invoiceDetailsData.chargedWeight }}</td>
                                        <td>{{ invoiceDetailsData.goodsValue }}</td>
                                        <td>{{ invoiceDetailsData.ddAmt }}</td>

                                        <td [hidden]="!hidePrint">{{ invoiceDetailsData.privateMarker }}</td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td>{{ invoiceDetailsData.gstNoConsignee }}</td>
                                        <td>{{ invoiceDetailsData.gstNoConsignor }}</td>
                                        <!--<td>{{ invoiceDetailsData.loaderName }}</td>-->
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td [hidden]="!hidePrint"></td>
                                        <td></td>
                                        <td></td>
                                        <!--<td></td>-->
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <button type="submit" class="dt-button" style="margin-left: 84%;" (click)="customPrintInvoiceDesSumPopupRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceDestinationSummaryTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                        <div class="box-body" [hidden]="!hideAndShowInvoiceDestinationSummaryTable">
                            <table datatable id="{{pageId}}invoiceDestinationSummaryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestinationSummary" [dtTrigger]="dtTriggerDestinationSummary">
                                <thead>
                                    <tr>
                                        <th>Station</th>
                                        <th>LRs</th>
                                        <th>Article</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>F O V</th>
                                        <th>Discount</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                        <th>Goods Value</th>
                                        <th>DD Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let destSubStationData of invoiceDestinationSummaryDataList ">
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="invoiceDetailsLrRptHyperLinkBtn(destSubStationData)">
                                            {{ destSubStationData.destination }}</td>
                                        <td>{{ destSubStationData.totalLrs }}</td>
                                        <td>{{ destSubStationData.totalArticles }}</td>
                                        <td>{{ destSubStationData.toPay }}</td>
                                        <td>{{ destSubStationData.paid }}</td>
                                        <td>{{ destSubStationData.riskCharge }}</td>
                                        <td>{{ destSubStationData.discountAmount }}</td>
                                        <td>{{ destSubStationData.actualWeight }}</td>
                                        <td>{{ destSubStationData.chargedWeight }}</td>
                                        <td>{{ destSubStationData.goodsValue }}</td>
                                        <td>{{ destSubStationData.ddAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <button type="submit" class="dt-button" style="margin-left: 84%;" (click)="customPrintInvoiceDetBookingPopupRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceDetailsBookingDateTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                        <div class="box-body" [hidden]="!hideAndShowInvoiceDetailsBookingDateTable">
                            <table datatable id="{{pageId}}invoiceDetailsBookingDateTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceDetailsBookingDate" [dtTrigger]="dtTriggerInvoiceDetailsBookingDate">
                                <thead>
                                    <tr>
                                        <th>Booking Date</th>
                                        <th>LR No</th>
                                        <th>Consignee Name</th>
                                        <th>Consignor Name</th>
                                        <th>Destination</th>
                                        <th>Invoice Number</th>
                                        <th>Article</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>F O V</th>
                                        <th>Less Amt</th>
                                        <th>DD Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceDetailsBookingDateData of invoiceDetailsBookingDateDataList ">
                                        <td>{{ invoiceDetailsBookingDateData.bookingDateStr }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.lrNumber }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.consigneeName }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.consignorName }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.destination }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.invoiceNumber }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.totalArticles }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.actualWeight }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.chargedWeight }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.toPay }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.paid }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.riskCharge }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.discountAmount }}</td>
                                        <td>{{ invoiceDetailsBookingDateData.ddAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>


</body>

</html>