<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Agent Expenses Report</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" id="{{pageId}}fromDates" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Agent Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getDestAgentStmtTrxAmt()">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearFields()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <h6 class="card-title border_bottom">Booking Agent Expenses Summary
                            </h6>
                            <table datatable id="{{pageId}}agentExpDetailsRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentExpensesReport" [dtTrigger]="dtTriggerAgentExpensesReport">

                                <thead>
                                    <tr>
                                        <th>Stmt No</th>
                                        <th>Total LRs</th>
                                        <th>Articles</th>
                                        <th>Actual Wt</th>
                                        <th>Charged Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>LC</th>
                                        <th>BC</th>
                                        <th>Incentive</th>
                                        <th>Penalty</th>
                                        <th>Commission</th>
                                        <th>Tempo Freight</th>
                                        <th>Hamali</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let agentExpensesRptData of agentExpensesRptDataList ">
                                        <td>{{ agentExpensesRptData.stmtNo }}</td>
                                        <td>{{ agentExpensesRptData.totalLrs }}</td>
                                        <td>{{ agentExpensesRptData.totalArticles }}</td>
                                        <td>{{ agentExpensesRptData.totalActWgt }}</td>
                                        <td>{{ agentExpensesRptData.totalChgWgt }}</td>
                                        <td>{{ agentExpensesRptData.toPay }}</td>
                                        <td>{{ agentExpensesRptData.totalPaid }}</td>
                                        <td>{{ agentExpensesRptData.lc }}</td>
                                        <td>{{ agentExpensesRptData.bc }}</td>
                                        <td>{{ agentExpensesRptData.incentive }}</td>
                                        <td>{{ agentExpensesRptData.penalty }}</td>
                                        <td>{{ agentExpensesRptData.commiosionAmt }}</td>
                                        <td>{{ agentExpensesRptData.tempoFrgtAmt }}</td>
                                        <td>{{ agentExpensesRptData.hamali }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>