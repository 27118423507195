<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Pending Stocks Details
						Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div  class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div  class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															[(ngModel)]="toDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Delivery Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-truck"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}deliveryType"
															name="searchBy" #searchBy>
															<option selected value="All">All</option>
															<option>Godown Delivery</option>
															<option>Door Delivery</option>
														</select>
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn"(click)="searchMethod();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearMethod();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8"></div>
										</div>
										<div class="row p-t-10">
											<div class="col-md-8">
												<h6 class="card-title">Pending Stocks Details</h6>
											</div>

										</div>

										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}pendingStocksDetailsRptId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsPendingStocksDetailsReport"
														[dtTrigger]="dtTriggerPendingStocksDetailsReport">

														<thead>
															<tr>
																<th>LR Number</th>
																<th>Booking Date</th>
																<th>Articles</th>
																<th>To Pay</th>
																<th>Paid</th>
																<th>Consignee Name</th>
																<th>Arrival Date</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let pendingStocksDetailsReportData of pendingStocksDetailsReportDataList ">
																<td>{{ pendingStocksDetailsReportData.lrNumber }}</td>
																<td>{{ pendingStocksDetailsReportData.bookingDateStr
																	}}</td>
																<td>{{ pendingStocksDetailsReportData.totalArticles }}</td>
																<td>{{ pendingStocksDetailsReportData.toPay }}</td>
																<td>{{ pendingStocksDetailsReportData.paid }}</td>
																<td>{{ pendingStocksDetailsReportData.consigneeName
																	}}</td>
																<td>{{ pendingStocksDetailsReportData.unLoadingDate
																	}}</td>
															</tr>

														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>

						</div>
					</div>



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>
</html>