import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { MasterService } from "src/app/dataService/master-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";

@Component({
	selector: 'app-godown-stocks-report',
	templateUrl: './godown-stocks-report.component.html',
	styleUrls: ['./godown-stocks-report.component.css']
})
export class GodownStocksReportComponent implements OnInit {

	godownStocksSummaryReportDataList: any;
	godownStocksDetailsReportDataList: any;
	godownStockReportDestinationWiseDataList: any;
	godownStocksReportPartyWiseDataList: any;
	godownStocksPartyDetailsReportDataList: any;
	model: NgbDateStruct;
	viewGodownName = false;
	viewPartyName = true;
	viewSource = false;
	viewDestination = false;
	viewTypeDetail = false;
	viewDate = false;

	partyViewType = false;
	partyViewTypeStockAt = false;
	partyViewTypeMainSource = false;
	ViewDays = false;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerGodownStocksSummaryReport: Subject<any> = new Subject();
	dtTriggerGodownStocksDetailsReport: Subject<any> = new Subject();
	dtTriggerGodownStockReportDestinationWise: Subject<any> = new Subject();
	dtTriggerGodownStocksReportPartyWise: Subject<any> = new Subject();
	dtTriggerGodownStocksPartyDetailsReport: Subject<any> = new Subject();

	dtOptionsGodownStocksSummaryReport: any;
	dtOptionGodownStocksDetailsReport: any;
	dtOptionGodownStockReportDestinationWise: any;
	dtOptionGodownStocksReportPartyWise: any;
	dtOptionGodownStocksPartyDetailsReport: any;
	
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	stockView = false;
	destView = false;
	srcSpec = false;
	partyView = false;
	viewSourceForBkg = false;
	showBkgSource = 'bkgSrc GodwonStockRpt';
	godownStocksSummaryReportTable = true;
	godownStocksDetailsReportTable = false;
	godownStockReportDestinationWiseTable = false;
	godownStocksReportPartyWiseSummaryTable = false;
	godownStocksReportPartyWiseDetailsTable = false;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any;
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;

	//For Stock In
	stockAtOptions: LRDto[];
	lrDtoStockAt: LRDto = new LRDto();
	public modelStockAt: any;
	stockAtTA: Array<LRDto> = [];
	focusStockAtTA$ = new Subject<string>();
	searchStockAt = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusStockAtTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.stockAtTA
				: this.stockAtTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterStockAt = (x: { destination: string }) => x.destination;
	/////
	lrDtoStockAtAll: LRDto = new LRDto();
	//// Main Src
	agentDtoForMainSrc: AgentDetailsDto = new AgentDetailsDto();
	agentDtoForMainSrcAll: AgentDetailsDto = new AgentDetailsDto();
	agentDtoOptionsForMainSrc: AgentDetailsDto[];

	public modelForMainSrc: any;
	mainSrcTA: Array<AgentDetailsDto> = [];
	focusMainSrcTA$ = new Subject<string>();
	searchMainSrcAt = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMainSrcTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.mainSrcTA
				: this.mainSrcTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMainSrc = (x: { mainBookStations: string }) => x.mainBookStations;

	//For Lcoal Godown
	localGodownOptions: LRDto[];
	lrDtoLocalGodown: LRDto = new LRDto();
	lrDtoLocalGodownAll: LRDto = new LRDto();
	public modelLocalGodown: any;
	localGodownTA: Array<LRDto> = [];
	focusLocalGodownTA$ = new Subject<string>();
	searchLocalGodown = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusLocalGodownTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.localGodownTA
				: this.localGodownTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterLocalGodown = (x: { godownName: string }) => x.godownName;
	/////
	srcLists: any;
	selectedSource: any;
	selectedSearchBy: any;
	selectedConsigneeName: any;
	searchBySummaryWiseDto: LRDto = new LRDto();
	selectedGodownName: any;
	setDestination: Array<any> = [];
	searchByDetailedWiseDto: LRDto = new LRDto();
	searchByDetailedDestWiseDto: LRDto = new LRDto();
	selectedDestDate: any;
	destDateModel: any;
	office: any;
	curtime: any;
	searchByPartyDetailsWiseDto: InvoiceDto = new InvoiceDto();
	selectedDays: any;
	selectedMainSrc: any;
	selectedStocksAt: any;
	setStocksAt: Array<any> = [];
	dataForPopup: any;
	searchPartyWiseTotalStatusDtoPopup: LRDto = new LRDto();
	searchByDetailedWiseDtoPopup: LRDto = new LRDto();

	//for custom print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	curDate: any;
	viewCustomPrintV1 = false;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	//Custom Print Footer
	summary1: number;
	summary2: number;
	summary3: number;
	summary4: number;
	summary5: number;
	summary6: number;
	//
	pageId = "gsrc";

	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.getConsigneeDetails(false);
			this.getMainSrcDetails();
			this.getLocalGodownDetails();
			this.curtime = this.datePipe.transform(new Date(), "h:mm a");
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Party Wise") {
						this.partyView = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"GodownsStksRpt StocksView") {
						this.stockView = true;
						this.getStockAtDetails();
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"GodownsStksRpt DestView") {
						this.destView = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"GodownsStksRpt SrcSpec") {
						this.srcSpec = true;
					}
					/*if (this.showBkgSource=='bkgSrc GodwonStockRpt') {
					 this.viewSourceForBkg = true;           
					}*/

				}
			}
			if (this.destView == true) {
				this.viewGodownName = false;
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
				this.viewGodownName = true;
			}

			if (this.partyView == true) {
				this.partyViewType = true;
			} else {
				this.partyViewType = false;
			}

			if (this.userDataDtoReturnSession.role == 'Booking Administrator') {
				this.viewSourceForBkg = true;
			}

			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
				this.getSourceList();
			} else {
				this.viewSource = false;
			}

			this.dataForPopup = JSON.parse(localStorage.getItem('godownStockOsReportPopup'));
			localStorage.clear();
			if (this.dataForPopup != null) {
				this.searchPartyWiseTotalStatusDtoPopup = new LRDto();
				this.searchPartyWiseTotalStatusDtoPopup = this.dataForPopup;
				if (this.searchPartyWiseTotalStatusDtoPopup != null) {
					this.searchByDetailedWiseMethodDetailsPopup();
				}
			}
			this.dataForPopup = '';

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}


	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var showDestView = this.destView;
		var showSrcView = this.viewSourceForBkg;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		var officePrt = this.office;
		var cuurentTimePrt = this.curtime;
		console.log(cuurentTimePrt);
		var groupColumn = 0;
		this.dtOptionsGodownStocksSummaryReport = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = null;
						if (showDestView) {
							returnSummaryExcel = "OutStanding Details Report As On Date : " + dateformate + " Destination : " + $("#gsrcdestination").val() + " Party Name : " + $("#gsrcconsigneeName").val();

						} else if (showSrcView) {
							returnSummaryExcel = "OutStanding Details Report  As On Date : " + dateformate + " Godown : " + $("#gsrcgodownName").val() + " Party Name : " + $("#gsrcconsigneeName").val() + " Source : " + $("#gsrcsource").val();
						} else {
							returnSummaryExcel = "OutStanding Details Report  As On Date : " + dateformate + " Godown : " + $("#gsrcgodownName").val() + " Party Name : " + $("#gsrcconsigneeName").val();
						}

						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalLrs = api.column(0).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var articles = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actualWgt = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + totalLrs);
				$(api.column(1).footer()).html(articles);
				$(api.column(2).footer()).html(toPay);
				$(api.column(3).footer()).html(paid);
				$(api.column(4).footer()).html(actualWgt);
				$(api.column(5).footer()).html(chargedWgt);
				$(api.column(6).footer()).html();

			}
		},
			//first datatable ends
			//second datatable starts 
			this.dtOptionGodownStocksDetailsReport = {

				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 2, 18, 19, 20,21]
						},
						title: function () {

							var returnSummaryExceDet = null;

							returnSummaryExceDet = "Stock Report-Godown Wise" + " Date : " + dateformate;

							return returnSummaryExceDet;
						}

					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var totalLrs = api.column(0).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var articles = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(articles);
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html(toPay);
					$(api.column(7).footer()).html(paid);
					$(api.column(8).footer()).html(actualWgt);
					$(api.column(9).footer()).html(chargedWgt);
					$(api.column(10).footer()).html();
					$(api.column(11).footer()).html();
					$(api.column(12).footer()).html();
					$(api.column(13).footer()).html();
					$(api.column(14).footer()).html();
					$(api.column(15).footer()).html();
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html();
					$(api.column(18).footer()).html();

				}
			},
			//second datatable ends
			//third datatable starts 

			this.dtOptionGodownStockReportDestinationWise = {

				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21]
						},
						title: function () {

							var returnSummaryExcelDest = null;
							var today = new Date();
							cuurentTimePrt = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

							returnSummaryExcelDest = "" + officePrt + " Godown Stock Report" + " Date As On : " + dateformate + " " + cuurentTimePrt;

							return returnSummaryExcelDest;
						}

					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var articles = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					$(api.column(0).footer()).html('Total : ');
					$(api.column(1).footer()).html(data.length);
					$(api.column(5).footer()).html(articles);
					$(api.column(7).footer()).html(toPay);
					$(api.column(8).footer()).html(paid);
					$(api.column(9).footer()).html(actualWgt);
					$(api.column(10).footer()).html(chargedWgt);
				},
				"drawCallback": function (settings) {
					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
					var last = null;

					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
					var last = null;
					var totalArt = 0;
					var totalToPay = 0;
					var totalPaid = 0;
					var totalActWt = 0;
					var totalChgWt = 0;
					var count = 0;
					//var pendingQty = 0;
					api.column(groupColumn, {
						page: 'current'
					}).data().each(function (group, i) {

						var val = api.row(api.row($(rows).eq(i)).index()).data();
						if (last !== group) {
							if (i != 0) {
								$(rows)
									.eq(i)
									.before(
										$(
											"<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
											{
												"class": "group",
												"data-id": group
											})
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": "Total"
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": + count
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totalArt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totalToPay
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totalPaid
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totalActWt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totalChgWt
												})).append($("<td></td>",
													{
														"colspan": 9,
														"text": ""
													}))
											.prop('outerHTML'));
								totalArt = 0;
								totalToPay = 0;
								totalPaid = 0;
								totalActWt = 0;
								totalChgWt = 0;
								count = 0;
								//pendingQty = 0;
							}
							//the below is for column name grouping (in this case we group for destination wise)
							$(rows)
								.eq(i)
								.before(
									$(
										"<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append(
											$("<td></td>",
												{
													"colspan": 20,
													"text": group
												})).prop('outerHTML'));
							last = group;
						}
						count++;
						totalArt += +val[5];
						totalToPay += +val[7];
						totalPaid += +val[8];
						totalActWt += +val[9];
						totalChgWt += +val[10];
						//pendingQty += val[6];
						if (i == (rows.length - 1)) {
							//the below is for Sumary details grouping 
							$(rows)
								.eq(i)
								.after(
									$(
										"<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": "Total"
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": + count
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totalArt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totalToPay
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totalPaid
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totalActWt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totalChgWt
											}))
										.append($("<td></td>",
											{
												"colspan": 9,
												"text": ""
											}))

										.prop('outerHTML'));
							totalArt = 0;
							totalToPay = 0;
							totalPaid = 0;
							totalActWt = 0;
							totalChgWt = 0;
							count = 0;
							//pendingQty = 0;
						}
					});
				}
			},
			//third datatable ends
			//fourth datatable starts 
			this.dtOptionGodownStocksReportPartyWise = {

				// the below code is for button export starts
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {

							var returnSummaryExcel = null;
							returnSummaryExcel = "Godown Stocks Report  For - " + officePrt;
							return returnSummaryExcel;
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var totalLrs = api.column(1).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var totalArt = api.column(2).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);




					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html(totalLrs);
					$(api.column(2).footer()).html(totalArt);
					$(api.column(3).footer()).html(toPay);
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();

				}
			},
			//fourth datatable ends
			//fifth datatable starts 

			this.dtOptionGodownStocksPartyDetailsReport = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 8, 3, 6, 7, 4, 5, 9, 10,11]
						},
						title: function () {

							var returnSummaryExcel = null;
							returnSummaryExcel = "Godown Stocks Report Party Details For - " + officePrt;
							return returnSummaryExcel;
						}

					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var articles = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html(articles);
					$(api.column(4).footer()).html(toPay);
					$(api.column(5).footer()).html(paid);
					$(api.column(6).footer()).html(actualWgt);
					$(api.column(7).footer()).html(chargedWgt);
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();

				}
			}

	}




	ngOnDestroy(): void {
		this.dtTriggerGodownStocksSummaryReport.unsubscribe();
		this.dtTriggerGodownStocksDetailsReport.unsubscribe();
		this.dtTriggerGodownStockReportDestinationWise.unsubscribe();
		this.dtTriggerGodownStocksReportPartyWise.unsubscribe();
		this.dtTriggerGodownStocksPartyDetailsReport.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerGodownStocksSummaryReport.next();
		this.dtTriggerGodownStocksDetailsReport.next();
		this.dtTriggerGodownStockReportDestinationWise.next();
		this.dtTriggerGodownStocksReportPartyWise.next();
		this.dtTriggerGodownStocksPartyDetailsReport.next();

	}


	viewTypeMode(viewType: string) {
		if (viewType === 'summary') {
			if (this.destView == true) {
				this.viewGodownName = false;
				this.viewDestination = true;
			} else {
				this.viewDestination = false;
				this.viewGodownName = true;
			}
			this.viewPartyName = true;
			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
			} else {
				this.viewSource = false;
			}
			this.viewTypeDetail = false;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = false;
			if (this.partyView == true) {
				this.partyViewType = true;
			} else {
				this.partyViewType = false;
			}
			this.partyViewTypeStockAt = false;
			this.partyViewTypeMainSource = false;
			this.ViewDays = false;
			this.viewDate = false;
			this.godownStocksSummaryReportTable = true;
			this.godownStocksDetailsReportTable = false;
			this.godownStockReportDestinationWiseTable = false;
			this.godownStocksReportPartyWiseSummaryTable = false;
			this.godownStocksReportPartyWiseDetailsTable = false;
			this.callServForTableDestroy();

		} else if (viewType === 'detail') {
			if (this.destView == true) {
				this.viewGodownName = false;
				this.viewDestination = true;
			} else {
				this.viewDestination = false;
				this.viewGodownName = true;
			}
			this.viewPartyName = true;
			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
			} else {
				this.viewSource = false;
			}
			this.viewTypeDetail = true;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = true;
			if (this.partyView == true) {
				this.partyViewType = true;
			} else {
				this.partyViewType = false;
			}
			this.partyViewTypeStockAt = false;
			this.partyViewTypeMainSource = false;
			this.ViewDays = false;
			this.viewDate = false;
			this.godownStocksSummaryReportTable = false;
			this.godownStocksDetailsReportTable = true;
			this.godownStockReportDestinationWiseTable = false;
			this.godownStocksReportPartyWiseSummaryTable = false;
			this.godownStocksReportPartyWiseDetailsTable = false;
			this.callServForTableDestroy();

		} else if (viewType === 'detailWithDestination') {
			if (this.destView == true) {
				this.viewGodownName = false;
				this.viewDestination = true;
			} else {
				this.viewDestination = false;
				this.viewGodownName = true;
			}
			this.viewPartyName = true;
			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
			} else {
				this.viewSource = false;
			}
			this.viewTypeDetail = true;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = true;
			if (this.partyView == true) {
				this.partyViewType = true;
			} else {
				this.partyViewType = false;
			}
			this.partyViewTypeStockAt = false;
			this.partyViewTypeMainSource = false;
			this.ViewDays = false;
			this.viewDate = true;
			this.godownStocksSummaryReportTable = false;
			this.godownStocksDetailsReportTable = false;
			this.godownStockReportDestinationWiseTable = true;
			this.godownStocksReportPartyWiseSummaryTable = false;
			this.godownStocksReportPartyWiseDetailsTable = false;
			this.callServForTableDestroy();
		} else if (viewType === 'partyViewTypeSummary') {
			this.viewGodownName = false;
			this.viewPartyName = true;
			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
			} else {
				this.viewSource = false;
			}
			this.viewDestination = false;
			this.viewTypeDetail = false;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = false;
			this.partyViewType = true;
			if (this.stockView == true) {
				this.partyViewTypeStockAt = true;
			} else {
				this.partyViewTypeStockAt = false;
			}

			this.partyViewTypeMainSource = true;
			this.ViewDays = false;
			this.viewDate = false;
			this.godownStocksSummaryReportTable = false;
			this.godownStocksDetailsReportTable = false;
			this.godownStockReportDestinationWiseTable = false;
			this.godownStocksReportPartyWiseSummaryTable = true;
			this.godownStocksReportPartyWiseDetailsTable = false;
			this.callServForTableDestroy();

		} else if (viewType === 'partyViewTypeDetail') {
			this.viewGodownName = false;
			this.viewPartyName = true;
			if (this.viewSourceForBkg == true) {
				this.viewSource = true;
			} else {
				this.viewSource = false;
			}
			this.viewDestination = false;
			this.viewTypeDetail = false;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = false;
			this.partyViewType = true;

			if (this.stockView == true) {
				this.partyViewTypeStockAt = true;
			} else {
				this.partyViewTypeStockAt = false;
			}

			this.partyViewTypeMainSource = true;
			this.ViewDays = true;
			this.viewDate = false;
			this.godownStocksSummaryReportTable = false;
			this.godownStocksDetailsReportTable = false;
			this.godownStockReportDestinationWiseTable = false;
			this.godownStocksReportPartyWiseSummaryTable = false;
			this.godownStocksReportPartyWiseDetailsTable = true;
			this.callServForTableDestroy();

		} else {
			this.viewGodownName = false;
			this.viewPartyName = false;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewTypeDetail = false;
			//         commented becaue the date and datatable(godown stock report destination wise) has to show when click on checkbox   this.ViewDate = false;
			this.partyViewType = false;
			this.partyViewTypeStockAt = false;
			this.partyViewTypeMainSource = false;
			this.ViewDays = false;
			this.viewDate = false;
			this.godownStocksSummaryReportTable = false;
			this.godownStocksDetailsReportTable = false;
			this.godownStockReportDestinationWiseTable = false;
			this.godownStocksReportPartyWiseSummaryTable = false;
			this.godownStocksReportPartyWiseDetailsTable = false;
			this.callServForTableDestroy();
		}
	}

	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				// Do your stuff
				//console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
				if (dtInstance.table().node().id == "godownStocksSummaryReportTableId") {
					console.log("a");
					this.godownStocksSummaryReportDataList = [];
					dtInstance.destroy();
					this.dtTriggerGodownStocksSummaryReport.next();
				} else if (dtInstance.table().node().id == "godownStocksDetailsReportTableId") {
					console.log("b");
					this.godownStocksDetailsReportDataList = [];
					dtInstance.destroy();
					this.dtTriggerGodownStocksDetailsReport.next();
				} else if (dtInstance.table().node().id == "godownStockReportDestinationWiseTableId") {
					console.log("c");
					this.godownStockReportDestinationWiseDataList = [];
					dtInstance.destroy();
					this.dtTriggerGodownStockReportDestinationWise.next();
				} else if (dtInstance.table().node().id == "godownStocksReportPartyWiseSummaryTableId") {
					console.log("c");
					this.godownStocksReportPartyWiseDataList = [];
					dtInstance.destroy();
					this.dtTriggerGodownStocksReportPartyWise.next();
				} else if (dtInstance.table().node().id == "godownStocksReportPartyWiseDetailsTableId") {
					console.log("c");
					this.godownStocksPartyDetailsReportDataList = [];
					dtInstance.destroy();
					this.dtTriggerGodownStocksPartyDetailsReport.next();
				}
			});
		});
	}
	callServForTableDestroy() {
		$("#" + this.pageId + "godownStocksSummaryReportTableId").DataTable().destroy();
		$("#" + this.pageId + "godownStocksDetailsReportTableId").DataTable().destroy();
		$("#" + this.pageId + "godownStockReportDestinationWiseTableId").DataTable().destroy();
		$("#" + this.pageId + "godownStocksReportPartyWiseSummaryTableId").DataTable().destroy();
		$("#" + this.pageId + "godownStocksReportPartyWiseDetailsTableId").DataTable().destroy();
		this.godownStocksSummaryReportDataList = [];
		this.godownStocksDetailsReportDataList = [];
		this.godownStockReportDestinationWiseDataList = [];
		this.godownStocksReportPartyWiseDataList = [];
		this.godownStocksPartyDetailsReportDataList = [];
		this.dtTriggerGodownStocksSummaryReport.next();
		this.dtTriggerGodownStocksDetailsReport.next();
		this.dtTriggerGodownStockReportDestinationWise.next();
		this.dtTriggerGodownStocksReportPartyWise.next();
		this.dtTriggerGodownStocksPartyDetailsReport.next();
		if (this.destView) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val('');
			window.setTimeout(function () {
				$("#" + this.pageId + "consigneeName").val('');
			}, 200);
			this.modelConsigneeName = '';
			this.consigneeNameTA = [];
		} else {
			this.consigneeNameTA = this.consigneeNameTATemp;
			$("#" + this.pageId + "consigneeName").val('All');
		}
	}
	///

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					$("#" + this.pageId + "consigneeName").val('All');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					this.consigneeNameTATemp = this.consigneeNameTA;

					$("#" + this.pageId + "consigneeName").val('All');

					console.log(this.consigneeNameTA);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.getConsigneeDetails(true);

	}
	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}

	getStockAtMethod() {
		this.lrDtoStockAt = new LRDto();
		this.lrDtoStockAt.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoStockAt.branch = this.userDataDtoReturnSession.office;
		// need to discuss 
		this.lrDtoStockAt.mainstation = this.userDataDtoReturnSession.mainStation;
		this.lrDtoStockAt.mode = "All";
	}
	getStockAtDetails() {
		this.getStockAtMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoStockAt).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					this.stockAtOptions = [];
					this.stockAtTA = [];
					if (response.length > 0) {
						this.stockAtOptions = response;
						console.log(this.stockAtOptions);
						this.stockAtTA = [];
						this.lrDtoStockAtAll.destination = 'All';
						this.stockAtTA.push(this.lrDtoStockAtAll);
						for (let i = 0; i < this.stockAtOptions.length; i++) {
							this.stockAtTA.push(this.stockAtOptions[i]);
						}
						window.setTimeout(function () {
							$("#" + this.pageId + "stockAt").val('All');
						}, 200);
					} else {
						this.lrDtoStockAtAll.destination = 'All';
						this.stockAtTA.push(this.lrDtoStockAtAll);
						window.setTimeout(function () {
							$("#" + this.pageId + "stockAt").val('All');
						}, 200);
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
			},
			() => console.log('done');

	};

	getMainSrcMethod() {
		this.agentDtoForMainSrc = new AgentDetailsDto();
		this.agentDtoForMainSrc.companyId = this.userDataDtoReturnSession.companyId;
	}
	getMainSrcDetails() {
		this.getMainSrcMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getMainBookingStationsDetails(this.agentDtoForMainSrc).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					this.agentDtoOptionsForMainSrc = [];

					this.mainSrcTA = [];
					if (response.length > 0) {
						this.agentDtoOptionsForMainSrc = response;
						console.log(this.agentDtoOptionsForMainSrc);
						this.mainSrcTA = [];
						this.agentDtoForMainSrcAll.mainBookStations = 'All';
						this.mainSrcTA.push(this.agentDtoForMainSrcAll);
						for (let i = 0; i < this.agentDtoOptionsForMainSrc.length; i++) {
							this.mainSrcTA.push(this.agentDtoOptionsForMainSrc[i]);
						}
						window.setTimeout(function () {
							$("#" + this.pageId + "mainSrc").val('All');
						}, 200);
					} else {
						this.agentDtoForMainSrcAll.mainBookStations = 'All';
						this.mainSrcTA.push(this.agentDtoForMainSrcAll);
						window.setTimeout(function () {
							$("#" + this.pageId + "mainSrc").val('All');
						}, 200);
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
			},
			() => console.log('done');

	};

	getLocalGodownMethod() {
		this.lrDtoLocalGodown = new LRDto();
		this.lrDtoLocalGodown.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoLocalGodown.branch = this.userDataDtoReturnSession.office;
		this.lrDtoLocalGodown.mode = 'active';

	}
	getLocalGodownDetails() {
		this.getLocalGodownMethod();
		this.showSpinnerForAction = true;
		this.masterService.getLocalGodowns(this.lrDtoLocalGodown).subscribe(
			(response) => {
				this.localGodownOptions = [];
				this.localGodownTA = [];
				this.showSpinnerForAction = false;
				if (response) {
					if (response.length > 0) {
						this.localGodownOptions = response;
						this.localGodownTA = [];
						this.lrDtoLocalGodownAll.godownName = 'All';
						this.localGodownTA.push(this.lrDtoLocalGodownAll);
						for (let i = 0; i < this.localGodownOptions.length; i++) {
							this.localGodownTA.push(this.localGodownOptions[i]);
						}
						window.setTimeout(function () {
							$("#" + this.pageId + "godownName").val('All');
						}, 200);

					} else {
						this.lrDtoLocalGodownAll.godownName = 'All';
						this.localGodownTA.push(this.lrDtoLocalGodownAll);
						window.setTimeout(function () {
							$("#" + this.pageId + "godownName").val('All');
						}, 200);
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Local Godowns Details", "info");
			},
			() => console.log('done');

	};

	getSourceList() {
		this.srcLists = this.userDataDtoReturnSession.stationList;
	}

	searchMethod() {
		this.selectedSearchBy = $("#" + this.pageId + "viewType").val();
		if (this.selectedSearchBy == 'summary') {
			this.searchBySummaryWiseMethodDetails();
		} else if (this.selectedSearchBy == 'detail') {
			this.searchByDetailedWiseMethodDetails();
		} else if (this.selectedSearchBy == 'detailWithDestination') {
			this.searchByDetailedDestinationWiseMethodDetails();
		} else if (this.selectedSearchBy == 'partyViewTypeDetail') {
			this.searchByPartyDetailsWiseMethodDetails();
		}
	}
	searchBySummaryWiseMethodDetails() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedSearchBy = $("#" + this.pageId + "viewType").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedGodownName = $("#" + this.pageId + "godownName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();

		this.searchBySummaryWiseDto = new LRDto();
		this.searchBySummaryWiseDto.consigneeName = this.selectedConsigneeName;
		this.searchBySummaryWiseDto.companyId = this.userDataDtoReturnSession.companyId;
		//this.searchBySummaryWiseDto.consigneeId= 0;
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchBySummaryWiseDto.consigneeId = this.selectedConsigneeId;
		}
		if (this.viewSourceForBkg == true) {
			if (this.selectedSource == null || this.selectedSource == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Source",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchBySummaryWiseDto.list = this.selectedSource;
			}
		} else if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.setDestination = [];
				this.setDestination.push(this.selectedDestination);
				this.searchBySummaryWiseDto.list = this.setDestination;
			}
		} else {
			this.setDestination = [];
			this.setDestination.push(this.userDataDtoReturnSession.office);
			this.searchBySummaryWiseDto.list = this.setDestination;
		}
		if (this.destView == false) {
			this.searchBySummaryWiseDto.godownName = this.selectedGodownName;
		}
		this.searchBySummaryWiseMethod();
		console.log(this.searchBySummaryWiseDto);
	}
	searchBySummaryWiseMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOSStocksSummary(this.searchBySummaryWiseDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "godownStocksSummaryReportTableId").DataTable().destroy();
				this.godownStocksSummaryReportDataList = [];
				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.godownStocksSummaryReportDataList = response;
					console.log(this.godownStocksSummaryReportDataList);
				}
				this.dtTriggerGodownStocksSummaryReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Summary View", "info");
			}, () => console.log('done');
	};

	searchByDetailedWiseMethodDetails() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedSearchBy = $("#" + this.pageId + "viewType").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedGodownName = $("#" + this.pageId + "godownName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();


		this.searchByDetailedWiseDto = new LRDto();
		this.searchByDetailedWiseDto.consigneeName = this.selectedConsigneeName;
		this.searchByDetailedWiseDto.companyId = this.userDataDtoReturnSession.companyId;
		//this.searchByDetailedWiseDto.consigneeId= 0;
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchByDetailedWiseDto.consigneeId = this.selectedConsigneeId;
		}
		if (this.viewSourceForBkg == true) {
			if (this.selectedSource == null || this.selectedSource == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Source",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchByDetailedWiseDto.list = this.selectedSource;
			}
		} else if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.setDestination = [];
				this.setDestination.push(this.selectedDestination);
				this.searchByDetailedWiseDto.list = this.setDestination;
			}
		} else {
			this.setDestination = [];
			this.setDestination.push(this.userDataDtoReturnSession.office);
			this.searchByDetailedWiseDto.list = this.setDestination;
		}
		if (this.destView == false) {
			this.searchByDetailedWiseDto.godownName = this.selectedGodownName;
		}
		this.searchByDetailedWiseMethod();
		console.log(this.searchByDetailedWiseDto);
	}
	searchByDetailedWiseMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOSStocksDetails(this.searchByDetailedWiseDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "godownStocksDetailsReportTableId").DataTable().destroy();
				this.godownStocksDetailsReportDataList = [];
				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.godownStocksDetailsReportDataList = response;
					console.log(this.godownStocksDetailsReportDataList);
				}
				this.dtTriggerGodownStocksDetailsReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Details View", "info");
			}, () => console.log('done');
	};


	searchByDetailedDestinationWiseMethodDetails() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedSearchBy = $("#" + this.pageId + "viewType").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedGodownName = $("#" + this.pageId + "godownName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDestDate = $("#" + this.pageId + "destDate").val();

		this.searchByDetailedDestWiseDto = new LRDto();
		this.searchByDetailedDestWiseDto.consigneeName = this.selectedConsigneeName;
		this.searchByDetailedDestWiseDto.bookingDate = this.selectedDestDate;
		this.searchByDetailedDestWiseDto.companyId = this.userDataDtoReturnSession.companyId;
		//this.searchByDetailedDestWiseDto.consigneeId= 0;
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchByDetailedDestWiseDto.consigneeId = this.selectedConsigneeId;
		}
		if (this.viewSourceForBkg == true) {
			if (this.selectedSource == null || this.selectedSource == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Source",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchByDetailedDestWiseDto.list = this.selectedSource;
			}
		} else if (this.partyView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.setDestination = [];
				this.setDestination.push(this.selectedDestination);
				this.searchByDetailedDestWiseDto.list = this.setDestination;
			}
		} else {
			this.setDestination = [];
			this.setDestination.push(this.userDataDtoReturnSession.office);
			this.searchByDetailedDestWiseDto.list = this.setDestination;
		}
		if (this.destView == false) {
			this.searchByDetailedDestWiseDto.godownName = this.selectedGodownName;
		}
		this.searchByDetailedDestinationWiseMethod();
		console.log(this.searchByDetailedDestWiseDto);
	}
	searchByDetailedDestinationWiseMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOSStocksDetails(this.searchByDetailedDestWiseDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "godownStockReportDestinationWiseTableId").DataTable().destroy();
				this.godownStockReportDestinationWiseDataList = [];
				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.godownStockReportDestinationWiseDataList = response;
					console.log(this.godownStockReportDestinationWiseDataList);
				}
				this.dtTriggerGodownStockReportDestinationWise.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Destination View", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.callServForTableDestroy();
		this.selectedSource = '';
		$("#" + this.pageId + "source").val('');
		this.selectedSearchBy = '';
		$("#" + this.pageId + "viewType").val('summary');
		this.selectedDestination = '';
		$("#" + this.pageId + "destination").val('');
		this.selectedGodownName = '';
		$("#" + this.pageId + "godownName").val('All');
		this.selectedConsigneeName = '';
		$("#" + this.pageId + "consigneeName").val('All');
		this.selectedDestDate = '';
		$("#" + this.pageId + "destDate").val('');
		this.setDestination = [];
		this.searchByDetailedDestWiseDto = new LRDto();
		this.searchByDetailedWiseDto = new LRDto();
		this.searchBySummaryWiseDto = new LRDto();
		this.showSpinnerForAction = false;
		this.selectedConsigneeName = '';
		this.selectedConsigneeId = 0;
		this.modelDestinationFotGet = '';
		this.modelConsigneeNameId = '';
		this.destDateModel = null;
		//
		if (this.destView == true) {
			this.viewGodownName = false;
			this.viewDestination = true;
		} else {
			this.viewDestination = false;
			this.viewGodownName = true;
		}
		this.viewPartyName = true;
		if (this.viewSourceForBkg == true) {
			this.viewSource = true;
		} else {
			this.viewSource = false;
		}
		this.viewTypeDetail = false;
		if (this.partyView == true) {
			this.partyViewType = true;
		} else {
			this.partyViewType = false;
		}
		this.partyViewTypeStockAt = false;
		this.partyViewTypeMainSource = false;
		this.ViewDays = false;
		this.viewDate = false;
		this.godownStocksSummaryReportTable = true;
		this.godownStocksDetailsReportTable = false;
		this.godownStockReportDestinationWiseTable = false;
		this.godownStocksReportPartyWiseSummaryTable = false;
		this.godownStocksReportPartyWiseDetailsTable = false;
		this.showSpinnerForAction = false;
		this.searchByPartyDetailsWiseDto = new InvoiceDto();
		this.selectedDays = '';
		this.selectedMainSrc = '';
		$("#" + this.pageId + "mainSrc").val('All');
		this.selectedStocksAt = '';
		if (this.stockView == true) {
			$("#" + this.pageId + "stockAt").val('All');
		}
		$("#" + this.pageId + "days").val('');
		this.setStocksAt = [];
		this.searchByDetailedWiseDtoPopup = new LRDto();
		localStorage.clear();
		this.searchPartyWiseTotalStatusDtoPopup = new LRDto();
		this.dataForPopup = '';
	}

	searchByPartyDetailsWiseMethodDetails() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedSearchBy = $("#" + this.pageId + "viewType").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedMainSrc = $("#" + this.pageId + "mainSrc").val();
		this.selectedStocksAt = $("#" + this.pageId + "stockAt").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDays = $("#" + this.pageId + "days").val();

		this.searchByPartyDetailsWiseDto = new InvoiceDto();
		this.searchByPartyDetailsWiseDto.consigneeName = this.selectedConsigneeName;
		this.searchByPartyDetailsWiseDto.companyId = this.userDataDtoReturnSession.companyId;
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchByPartyDetailsWiseDto.consigneeId = this.selectedConsigneeId;
		}
		if (this.stockView == true) {
			if (this.selectedStocksAt == null || this.selectedStocksAt == '' || this.selectedStocksAt == 'All') {
				this.setStocksAt = [];
				for (let i = 0; i < this.stockAtOptions.length; i++) {
					this.setStocksAt.push(this.stockAtOptions[i].destination);
				}
				this.searchByPartyDetailsWiseDto.list = this.setStocksAt;
				console.log(this.setStocksAt);
			} else {
				this.setStocksAt = [];
				this.setStocksAt.push(this.selectedStocksAt)
				this.searchByPartyDetailsWiseDto.list = this.setStocksAt;
			}


		} else {
			this.setStocksAt = [];
			this.setStocksAt.push(this.userDataDtoReturnSession.office);
			this.searchByPartyDetailsWiseDto.list = this.setStocksAt;

		}
		this.searchByPartyDetailsWiseDto.fromStation = this.selectedMainSrc;
		this.searchByPartyDetailsWiseDto.daysDiff = this.selectedDays;
		this.searchByPartyDetailsWiseMethod();
		console.log(this.searchByPartyDetailsWiseDto);
	}
	searchByPartyDetailsWiseMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOsStocksPartyDetailsReport(this.searchByPartyDetailsWiseDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "godownStocksReportPartyWiseDetailsTableId").DataTable().destroy();
				this.godownStocksPartyDetailsReportDataList = [];

				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.godownStocksPartyDetailsReportDataList = response;
					console.log(this.godownStocksPartyDetailsReportDataList);
				}
				this.dtTriggerGodownStocksPartyDetailsReport.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Party Details View", "info");
			}, () => console.log('done');
	};




	searchByDetailedWiseMethodDetailsPopup() {
		this.selectedConsigneeName = this.searchPartyWiseTotalStatusDtoPopup.consigneeName;
		this.selectedGodownName = 'All';
		this.searchByDetailedWiseDtoPopup = new LRDto();
		this.searchByDetailedWiseDtoPopup.consigneeName = this.selectedConsigneeName;
		this.searchByDetailedWiseDtoPopup.companyId = this.userDataDtoReturnSession.companyId;
		this.searchByDetailedWiseDtoPopup.consigneeId = this.searchPartyWiseTotalStatusDtoPopup.consigneeId;
		this.searchByDetailedWiseDtoPopup.list = this.searchPartyWiseTotalStatusDtoPopup.list;
		if (this.destView == false) {
			this.searchByDetailedWiseDtoPopup.godownName = this.selectedGodownName;
		}
		this.searchByDetailedWiseMethodPopup();
		console.log(this.searchByDetailedWiseDtoPopup);
	}
	searchByDetailedWiseMethodPopup = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOSStocksDetails(this.searchByDetailedWiseDtoPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (this.destView == true) {
					this.viewGodownName = false;
					this.viewDestination = true;
				} else {
					this.viewDestination = false;
					this.viewGodownName = true;
				}
				this.viewPartyName = true;
				if (this.viewSourceForBkg == true) {
					this.viewSource = true;
				} else {
					this.viewSource = false;
				}
				this.viewTypeDetail = true;
				if (this.partyView == true) {
					this.partyViewType = true;
				} else {
					this.partyViewType = false;
				}
				this.partyViewTypeStockAt = false;
				this.partyViewTypeMainSource = false;
				this.ViewDays = false;
				this.viewDate = false;
				this.godownStocksSummaryReportTable = false;
				this.godownStocksDetailsReportTable = true;
				this.godownStockReportDestinationWiseTable = false;
				this.godownStocksReportPartyWiseSummaryTable = false;
				this.godownStocksReportPartyWiseDetailsTable = false;
				//this.callServForTableDestroy();
				$("#" + this.pageId + "viewType").val('detail');
				this.selectedGodownName = 'All'
				$("#" + this.pageId + "godownName").val('All');

				this.selectedConsigneeName = this.searchPartyWiseTotalStatusDtoPopup.consigneeName;
				setTimeout(() => {
					this.setConsignee();
				},
					5000);
				this.selectedDestination = this.searchPartyWiseTotalStatusDtoPopup.destination;
				$("#" + this.pageId + "destination").val(this.selectedDestination);


				$("#" + this.pageId + "godownStocksDetailsReportTableId").DataTable().destroy();
				this.godownStocksDetailsReportDataList = [];
				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.godownStocksDetailsReportDataList = response;
					console.log(this.godownStocksDetailsReportDataList);
				}
				this.dtTriggerGodownStocksDetailsReport.next();
				$("#" + this.pageId + "consigneeName").val(this.searchPartyWiseTotalStatusDtoPopup.consigneeName);
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Details View", "info");
			}, () => console.log('done');
	};


	setConsignee() {
		$("#" + this.pageId + "consigneeName").val(this.searchPartyWiseTotalStatusDtoPopup.consigneeName);

	}

	customPrintStocksSummary() {
		if (this.godownStocksSummaryReportDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Godown Name", "Total Lrs", "Articles", "To Pay", "Paid", "Actual Weight", "Charged Weight"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 10, 10, 15, 10, 15, 15];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.godownStocksSummaryReportDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.godownStocksSummaryReportDataList[i].godownName, this.godownStocksSummaryReportDataList[i].totalLrs, this.godownStocksSummaryReportDataList[i].totalArticles, this.godownStocksSummaryReportDataList[i].toPay, this.godownStocksSummaryReportDataList[i].paid, this.godownStocksSummaryReportDataList[i].actualWeight, this.godownStocksSummaryReportDataList[i].chargedWeight];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.summary1 = this.summary1 + this.godownStocksSummaryReportDataList[i].totalLrs;
				this.summary2 = this.summary2 + this.godownStocksSummaryReportDataList[i].totalArticles;
				this.summary3 = this.summary3 + this.godownStocksSummaryReportDataList[i].toPay;
				this.summary4 = this.summary4 + this.godownStocksSummaryReportDataList[i].paid;
				this.summary5 = this.summary5 + this.godownStocksSummaryReportDataList[i].actualWeight;
				this.summary6 = this.summary6 + this.godownStocksSummaryReportDataList[i].chargedWeight;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.godownStocksSummaryReportDataList.length, this.summary1, this.summary2, this.summary3, this.summary4, this.summary5, this.summary6];
			//heading logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Godown Stock Summary Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintStocksDetails() {
		if (this.godownStocksDetailsReportDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Godown Name", "LR No", "Booking Date", "Agent", "Destination", "Art", "Desc", "ToPay", "Paid", "Act Wt", "Chg Wt", "Consignor", "Consignee", "Area", "Sub Station", "Del Type", "Bkg-Arr", "Cur-Arr"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [8, 5, 6, 6, 9, 3, 5, 5, 4, 4, 4, 8, 8, 5, 8, 4, 4, 4];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.godownStocksDetailsReportDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.godownStocksDetailsReportDataList[i].godownName, this.godownStocksDetailsReportDataList[i].lrNumber, this.godownStocksDetailsReportDataList[i].bookingDateStr, this.godownStocksDetailsReportDataList[i].agentName, this.godownStocksDetailsReportDataList[i].destination, this.godownStocksDetailsReportDataList[i].totalArticles, this.godownStocksDetailsReportDataList[i].description, this.godownStocksDetailsReportDataList[i].toPay, this.godownStocksDetailsReportDataList[i].paid, this.godownStocksDetailsReportDataList[i].actualWeight, this.godownStocksDetailsReportDataList[i].chargedWeight, this.godownStocksDetailsReportDataList[i].consignorName, this.godownStocksDetailsReportDataList[i].consigneeName, this.godownStocksDetailsReportDataList[i].areaOfDelivery, this.godownStocksDetailsReportDataList[i].subStations, this.godownStocksDetailsReportDataList[i].deliveryType, this.godownStocksDetailsReportDataList[i].dispatchedwithin, this.godownStocksDetailsReportDataList[i].arrivalwithin];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.summary1 = this.summary1 + this.godownStocksDetailsReportDataList[i].totalArticles;
				this.summary2 = this.summary2 + this.godownStocksDetailsReportDataList[i].toPay;
				this.summary3 = this.summary3 + this.godownStocksDetailsReportDataList[i].paid;
				this.summary4 = this.summary4 + this.godownStocksDetailsReportDataList[i].actualWeight;
				this.summary5 = this.summary5 + this.godownStocksDetailsReportDataList[i].chargedWeight;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total", this.godownStocksDetailsReportDataList.length, "", "", "", this.summary1, "", this.summary2, this.summary3, this.summary4, this.summary5, "", "", "", "", "", "", ""];
			//heading logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Godown Stock Detail Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

}
