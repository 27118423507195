import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { NgModule, ViewChild } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

//from the particular global folder starts
import { MasterService } from "src/app/dataService/master-service";
//from the particular global folder ends

//for datepicker starts
import {
    NgbDateStruct,
    NgbCalendar,
    NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import *  as moment from 'moment';
//for the select option with filter ends

import { DataTableDirective } from "angular-datatables";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";

import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { ElementRef } from "@angular/core";
import { LRDto } from "src/app/dto/LR-dto";
import { LrService } from 'src/app/dataService/lr-service';



@Component({
    selector: "app-lr-issue",
    templateUrl: "./lr-issue.component.html",
    styleUrls: ["./lr-issue.component.css"],
})
export class LrIssueComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number; month: number };
    getDataFrmServiceFrTable: any;
    lrIssueDataList: any;
    onDestroyUnsubscribtionLrIssue: Subscription;

    loadingIndicator = true;
    //summaryTable:any;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    dateDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    control = new FormControl();
    //    agentNameOptions = [
    //         { id: 1, label: 'AGRA' },
    //         { id: 2, label: 'Alang-BH' },
    //         { id: 3, label: 'ANDHERI' },
    //         { id: 3, label: 'BANDRA' }
    //     ];

    @ViewChildren(DataTableDirective)
    public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrIssue: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrIssue: any;


    //For Agent Name
    agentNameDtoForAll: AgentDetailsDto = new AgentDetailsDto();
    focusAgentNameTA$ = new Subject<string>();
    clickAgentNameTA$ = new Subject<string>();
    @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
    agentNameTA: Array<any> = [];
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    controlAgentName = new FormControl();
    agentDetailsOptionsGet: AgentDetailsDto[];
    agentDetailsOptionsSet: any;
    @ViewChild("agentName") agentNameField: ElementRef;

    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    superAdminview: boolean = false;

    agentNameList: Array<any> = [];
    /////
    isLoggedIn = true;
    address: any;
    showCode = '';
    lrDtoForOnLoad: LRDto = new LRDto();
    selectedAgentName: any;
    dateRange: any;
    selectedDate: any;
    enteredFromLr: any;
    enteredToLr: any;
    lrDtoForOnCheckLrExist: LRDto = new LRDto();
    enteredNoOfLrs: any;
    lrDtoForSave: LRDto = new LRDto();
    lrDtoForRemove: LRDto = new LRDto();
    lrAgentName: any;
    pageId="lricp";

    constructor(
        private lrIssueRpt: MasterService,
        private masterReadService: MasterReadService,
        private lrService: LrService,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.address =
                this.userDataDtoReturnSession.addressId == null
                    ? ""
                    : this.userDataDtoReturnSession.addressId;
            this.getAgentDetailList();
            if (this.userDataDtoReturnSession.isLrCode == true) {
                this.showCode = "(MUM)";
            }

        }

    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        this.dtOptionsLrIssue = {

            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5]
                },

                title: function () {

                    var returExtendHistroyDate = null;



                    returExtendHistroyDate = "LR Issue Details ";
                    return returExtendHistroyDate;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": false,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html();
                $(api.column(5).footer()).html();
                $(api.column(6).footer()).html();


            }

        };


    }

    ngOnDestroy(): void {
        this.dtTriggerLrIssue.unsubscribe();

        // this.onDestroyUnsubscribtionLrIssue.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerLrIssue.next();

    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate(),
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            (item) =>
                item[labelKey || "label"]
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends


    //For Agent Names
    getUserValuesForAgentDetailsList() {
        this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.agentDtoParam.column2 = 'Working';
        //  this.agentDtoParam.status = 'Working';

        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mode = 'Sub';
    }
    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    console.log(response);
                    swal({
                        title: "Warning",
                        text: "No Agents  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlAgentName.reset();
                    //                    this.agentDetailsOptionsGet = [];
                    //                    this.agentDtoAll.subStation = "Add New";
                    //                    this.agentDetailsOptionsGet.push( this.agentDtoAll )
                } else {
                    this.agentDetailsOptionsGet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsGet = response;
                    // this.agentDetailsOptionsSet = response;
                    // //                   
                    console.log(this.agentDetailsOptionsGet);
                    this.agentNameList = [];
                    // this.agentNameDtoForAll.subStation = "ALL";
                    // this.agentNameTA.push(this.agentNameDtoForAll);

                    for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
                        //console.log( this.agentDetailsOptionsGet[i].subStation );
                        this.agentNameTA.push(this.agentDetailsOptionsGet[i]);
                        this.agentNameList.push(this.agentDetailsOptionsGet[i].subStation);
                    }
                    this.getDetailsOnLoad();

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    getDetailsOnLoad() {
        this.lrDtoForOnLoad = new LRDto();
        this.lrDtoForOnLoad.list = this.agentNameList;
        this.lrDtoForOnLoad.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForOnLoad);
        this.getDetailsOnLoadMethod();

    }
    getDetailsOnLoadMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.getLRIssueDetails(this.lrDtoForOnLoad).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"lrIssueTableID").DataTable().destroy();
                this.lrIssueDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrIssueDataList = response;

                }
                this.dtTriggerLrIssue.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the LR Issue  details", "info");
            }, () => console.log('done');
    };

    // selectedRow(lrIssueData) {
    //     console.log(lrIssueData);
    //     $("#"+this.pageId+"agentName").val(lrIssueData.agentName);
    //     $("#"+this.pageId+"date").val(lrIssueData.dateInDate);
    //     $("#"+this.pageId+"fromLr").val(lrIssueData.fromLrNo);
    //     $("#"+this.pageId+"toLr").val(lrIssueData.toLrNo);
    //     $("#"+this.pageId+"numberOfLrs").val(lrIssueData.noOfLrs);
    // }

    btnValidationCheck() {
        this.enteredFromLr = $("#"+this.pageId+"fromLr").val();
        this.enteredToLr = $("#"+this.pageId+"toLr").val();

        if (this.enteredFromLr == null || this.enteredFromLr == '' || this.enteredToLr == null || this.enteredToLr == '') {
            swal({
                title: "Mandetory",
                text: "Please  Enter The Mandetory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            let fromLr = this.enteredFromLr;
            let toLr = this.enteredToLr;
            if (fromLr > toLr) {
                swal({
                    title: "Wrong Entry",
                    text: "To Value Should be Greater Than From Value ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                $("#"+this.pageId+"fromLr").val('');
                $("#"+this.pageId+"toLr").val('');
                window.setTimeout(function () {
                    $("#lricpfromLr").focus();
                }, 100);

            } else {
                this.btnSaveValidate();
            }

        }

    }
    btnSaveValidate() {
        this.enteredFromLr = $("#"+this.pageId+"fromLr").val();
        this.enteredToLr = $("#"+this.pageId+"toLr").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();
        this.selectedDate = $("#"+this.pageId+"date").val();
        this.enteredNoOfLrs = $("#"+this.pageId+"numberOfLrs").val();
        if (this.enteredFromLr == null || this.enteredFromLr == '' || this.enteredToLr == null || this.enteredToLr == '' || this.selectedAgentName == null || this.selectedAgentName == '' || this.selectedDate == null || this.selectedDate == '' || this.enteredNoOfLrs == null || this.enteredNoOfLrs == '') {
            swal({
                title: "Mandetory",
                text: "Please  Enter The Mandetory Fields ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.lrDtoForOnCheckLrExist = new LRDto();
            this.lrDtoForOnCheckLrExist.fromLrNo = this.enteredFromLr;
            this.lrDtoForOnCheckLrExist.toLrNo = this.enteredToLr;
            this.lrDtoForOnCheckLrExist.isLRCode = this.userDataDtoReturnSession.isLrCode;
            this.lrDtoForOnCheckLrExist.lrCode = this.userDataDtoReturnSession.lrCode;
            this.lrDtoForOnCheckLrExist.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.lrDtoForOnCheckLrExist);
            this.lrDtoForOnCheckLrExistMethod();
        }
    }

    lrDtoForOnCheckLrExistMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.chkLrRangeExist(this.lrDtoForOnCheckLrExist).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "Exist") {
                    swal({
                        title: "Not Allowed",
                        text: "Already Exist",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.saveDetails();
                }
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Checking the LR Issue details", "info");
            }, () => console.log('done');
            this.changeDetectorRef.detectChanges();
    };

    saveDetails() {

        this.enteredFromLr = $("#"+this.pageId+"fromLr").val();
        this.enteredToLr = $("#"+this.pageId+"toLr").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();
        this.selectedDate = $("#"+this.pageId+"date").val();
        this.enteredNoOfLrs = $("#"+this.pageId+"numberOfLrs").val();

        this.lrDtoForSave = new LRDto();
        this.lrDtoForSave.agentName = this.selectedAgentName;
        this.lrDtoForSave.dateInDate = this.selectedDate;
        this.lrDtoForSave.fromLrNo = this.enteredFromLr;
        this.lrDtoForSave.toLrNo = this.enteredToLr;
        this.lrDtoForSave.noOfLrs = this.enteredNoOfLrs;
        this.lrDtoForSave.isLRCode = this.userDataDtoReturnSession.isLrCode;
        this.lrDtoForSave.lrCode = (this.userDataDtoReturnSession.lrCode == "NA" ? null : this.userDataDtoReturnSession.lrCode == null ? null : this.userDataDtoReturnSession.lrCode == undefined ? null : this.userDataDtoReturnSession.lrCode == "" ? null : this.userDataDtoReturnSession.lrCode);
        // if(this.userDataDtoReturnSession.lrCode!=null){
        //     this.lrDtoForSave.lrCode = this.userDataDtoReturnSession.lrCode;
        // }

        this.lrDtoForSave.entryBy = this.userDataDtoReturnSession.userId;
        this.lrDtoForSave.lastUpdatedBy = this.userDataDtoReturnSession.userId;
        this.lrDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        if (this.userDataDtoReturnSession.isLrCode == true) {
            this.lrDtoForSave.withOut = false;
        } else {
            this.lrDtoForSave.withOut = true;
        }
        console.log(this.lrDtoForSave);
        this.saveMethod();

    }


    saveMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.addLRIssueDetails(this.lrDtoForSave).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "success") {
                    swal({
                        title: "Success",
                        text: "LR Range for This Agent Saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.getDetailsOnLoad();
                    this.clearFields();
                    // by kamil
                    window.setTimeout(function () {
                        $("#lricpagentName").focus();
                    }, 100);
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Adding  the LR Issue details", "info");
            }, () => console.log('done');
    };

    rowRemove(lrIssueData) {

        swal({
            title: "Remove",
            text: "Sure You want to remove the selected LR Range",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.getLrDetailsToRemove(lrIssueData);
            }
        });
    }

    getLrDetailsToRemove(lrIssueData) {
        this.lrDtoForRemove = new LRDto();
        this.lrDtoForRemove.id = lrIssueData.id;
        this.lrDtoForRemove.fromLrNo = lrIssueData.fromLrNo;
        this.lrDtoForRemove.toLrNo = lrIssueData.toLrNo;
        this.lrDtoForRemove.lrCode = lrIssueData.lrCode == "NA" ? null : lrIssueData.lrCode == null ? null : lrIssueData.lrCode == "" ? null : lrIssueData.lrCode;
        this.lrDtoForRemove.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForRemove);
        this.removeMethod();

    }


    removeMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.removeLRIssueDetails(this.lrDtoForRemove).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "removed") {
                    swal({
                        title: "Success",
                        text: "LR Range for This Agent Removed Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.getDetailsOnLoad();
                    this.clearFields();
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Removing  the LR Issue details", "info");
            }, () => console.log('done');
    };
    clearFields() {

        this.enteredFromLr = '';
        $("#"+this.pageId+"fromLr").val('');
        this.enteredToLr = '';
        $("#"+this.pageId+"toLr").val('');
        this.selectedAgentName = '';
        $("#"+this.pageId+"agentName").val('');
        this.selectedDate = '';
        $("#"+this.pageId+"date").val('');
        this.enteredNoOfLrs = '';
        $("#"+this.pageId+"numberOfLrs").val('');
        this.dateRange = null;

    }

    clearAll() {
        this.clearFields();
        this.showSpinnerForAction = false;
        this.lrDtoForRemove = new LRDto();
        this.lrDtoForSave = new LRDto();
        this.lrDtoForOnCheckLrExist = new LRDto();
        this.dateRange = null;
        this.getDetailsOnLoad();
    }

    dateFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#lricpfromLr").focus();
            }, 100);
        }
    }

    agentNameFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#lricpdate").focus();
            }, 100);
        }
    }
    fromLRNoFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#lricptoLr").focus();
            }, 100);
        }
    }
    toLRNoFocus(e) {
        if (e.keyCode == 13) {
            this.enteredFromLr = $("#"+this.pageId+"fromLr").val();
            this.enteredToLr = $("#"+this.pageId+"toLr").val();
            if (this.enteredFromLr == null || this.enteredFromLr == '' || this.enteredToLr == null || this.enteredToLr == '') {
                swal({
                    title: "Mandetory",
                    text: "Please Select From Lr and To Lr ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.validateBetweenLrNos();
            }

        }
    }

    validateBetweenLrNos() {
        this.enteredFromLr = $("#"+this.pageId+"fromLr").val();
        this.enteredToLr = $("#"+this.pageId+"toLr").val();
        let fromLr = this.enteredFromLr;
        let toLr = this.enteredToLr;
        if (fromLr > toLr) {
            swal({
                title: "Wrong Entry",
                text: "To Value Should be Greater Than From Value ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#"+this.pageId+"fromLr").val('');
            $("#"+this.pageId+"toLr").val('');
            window.setTimeout(function () {
                $("#lricpfromLr").focus();
            }, 100);

        } else {
            let balanceLrVal = 0;
            balanceLrVal = this.enteredToLr - this.enteredFromLr + 1;
            console.log(balanceLrVal);
            $("#"+this.pageId+"numberOfLrs").val(balanceLrVal);
            window.setTimeout(function () {
                $("#lricpsaveBtn").focus();
            }, 100);

        }

    }


}
