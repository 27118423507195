<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}lrIssueDetailsBalRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLRIssueDetails" [dtTrigger]="dtTriggerLRIssueDetails">
								<thead>
									<tr>
										<th>Date</th>
										<th>LR No</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let gridLRIssueDetailsList of gridLRIssueDetailsList">
										<td>{{ gridLRIssueDetailsList.arrivalDateStr }}</td>
										<td>{{ gridLRIssueDetailsList.lrNumber }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>