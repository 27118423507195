<html>

<head>
    <style>
        @media ( min-width: 167px) {
            .custom_Padding {
                padding-left: 85px;
            }
        }
        
        @media ( min-width: 767px) {
            .custom_Padding {
                padding-left: 20px;
            }
        }
        
        @media ( min-width: 1024px) {
            .custom_Padding {
                padding-left: 45px;
            }
        }
        
        @media ( min-width: 1200px) {
            .custom_Padding {
                padding-left: 75px;
            }
        }
    </style>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}nonavialOfTruckId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Truck Non Availability Details Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <!-- 											<div class="col-sm-12 col-md-12"> -->
                                            <!-- 												<div class="form-group"> -->
                                            <!-- 													<div class="input-group"> -->
                                            <!-- 														<label>Search By</label> -->
                                            <!-- 														<div class="input-group-prepend"> -->
                                            <!-- 															<span class="input-group-text"> <i -->
                                            <!-- 																class="fas fa-search"></i> -->
                                            <!-- 															</span> -->
                                            <!-- 														</div> -->
                                            <!-- 														<select class="custom-select col-12" id="{{pageId}}searchBy" -->
                                            <!-- 															name="searchBy" #searchBy -->
                                            <!-- 															(change)="searchByMode(searchBy.value)"> -->
                                            <!-- 															<option selected value="lrWise">LR Wise</option> -->
                                            <!-- 															<option value="partyWise">Party Wise</option> -->
                                            <!-- 														</select> -->
                                            <!-- 													</div> -->
                                            <!-- 												</div> -->
                                            <!-- 											</div> -->
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Till Date</label> <input class="form-control" id="{{pageId}}tillDate" placeholder="yyyy-mm-dd" name="tillDates" ngbDatepicker #tillDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="tillDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Agent Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}agentNameId" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="agentNameListener($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName" (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                                placeholder="Select Agent" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Source</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-box"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}sourceId" type="text" class="form-control" (selectItem)="clickListnerForSource($event)" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                                placeholder="Select Source.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-road"></i>
																</span>
                                                            </div>
                                                            <!--<input class="auto_selectOption input is-medium"
																placeholder="Select Destination" [formControl]="control"
																[appAutocomplete]="autocomplete">-->
                                                            <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getReportDetails();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="confirmSendSms();">SMS To
								Consignee</button>
                        </div>
                        <br>
                        <hr style="margin: 3px;">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div *ngIf="searchByPartyWise" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}smsSendingType">
                                                            <label>SMS Sending Type</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-box"></i>
																</span>
                                                            </div>
                                                            <!--<input class="auto_selectOption input is-medium"
																placeholder="Select Source">-->
                                                            <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
																<option selected value="started">Started</option>
																<option value="stopped">Stopped</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByPartyWise" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-road"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}partyWiseDestinationDropDownId" type="text" class="form-control" (selectItem)="clickListnerForPartyWiseDestinationDropDown($event)" [(ngModel)]="modelPartyWiseDestinationDropDown" [ngbTypeahead]="searchPartyWiseDestinationDropDown"
                                                                [resultFormatter]="formatterPartyWiseDestinationDropDown" [inputFormatter]="formatterPartyWiseDestinationDropDown" (focus)="focusPartyWiseDestinationDropDownTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByPartyWise" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Source List</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-box"></i>
																</span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}sourceListId" name="sourceListId">
																<option selected value="All">All</option>
																<option value="Delhi">Delhi</option>
																<option value="Punjab">Punjab</option>
																<option value="UP">UP</option>
																<option value="Haryana">Haryana</option>
																<option value="Mumbai">Mumbai</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom_Padding" [hidden]="! partyWiselTable">
                                                <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionPartyWise" [dtTrigger]="dtTriggerPartyWise">
                                                    <thead>
                                                        <tr>
                                                            <th>Source List</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let partyWiseData of partyWiseDataList ">
                                                            <td>{{ partyWiseData.sourceList }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintNonAvailOfTruck()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
                            <table datatable id="{{pageId}}nonTruckAvbTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDataTable" [dtTrigger]="dtTriggerDataTable">

                                <thead>
                                    <tr>
                                        <th>LR Number</th>
                                        <th>Articles</th>
                                        <th>Consignee Name</th>
                                        <th>Mobile No</th>
                                        <th>Contact Person</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dataTableData of dataTableDataList ">
                                        <td>{{ dataTableData.lrNumber }}</td>
                                        <td>{{ dataTableData.totalArticles }}</td>
                                        <td>{{ dataTableData.consigneeName }}</td>
                                        <td>{{ dataTableData.mobileNum }}</td>
                                        <td>{{ dataTableData.contactPerson }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>