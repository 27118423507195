<div class="page row system_responsive" id="{{pageId}}firstPrint">
	<div class="col-md-12">

		<div>
			<div>
				<table width='100%' border='0' cellspacing='0' cellpadding='0'>
					<tr>
						<td style="padding-top: 10px;" width='40%' valign='top'>
							<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
							<br> {{address}}
						</td>

						<td width='60%' valign='top'>
							<table width='100%' border='0' cellspacing='0' cellpadding='0'>
								<!--<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%'>
													<strong>Two Point Door Delivery LRs : </strong>
												</td>
												<td width='60%' style='border-bottom:1px solid #000;'>
													{{twoPtLrs}}
												</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>-->

								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='20%'><strong>From Date : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{fromDate}} </strong></td>
												<td width='20%'><strong>To Date : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{toDate}} </strong></td>
											</tr>
										</table>
									</td>
								</tr><br>
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='20%'><strong>Source : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{source}} </strong></td>
												<td width='20%'><strong>Destination : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{destination}} </strong></td>
											</tr>
										</table>
									</td>
								</tr><br>
							</table>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center;font-size: large;'>
					<strong><u>Commodity Report (Item Wise Details)</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<!--Second  row starts-->
		<!--<div class="container border">
			<table width='100%' border='0' cellpadding='0' cellspacing='0'>
				<tr>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;'
						valign='top' type='text'><strong> Note **</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> LR No</strong></td>
					<td width='4%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Art</strong></td>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Pvt.Mrk</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking Date</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking From</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Description</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignee</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignor</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Act Wgt</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Chg Wgt</strong></td>
					<td width='8%' align='center'
						style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;'
						valign='top' type='text'><strong>Goods Value</strong></td>
				</tr>
			</table>
		</div>-->
		<!--<div class="container border">
			<div>-->
		<table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
			<tr>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;'
					valign='top' type='text'><strong> Booking Item</strong></td>
				<td width='5%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='number'><strong> LRs</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='number'><strong> Art</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='number'><strong> Actual Weight</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong> (%) of Actual Weight</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong> Charged Weight </strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong> (%) of Charged Weight</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong>ToPay</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong>Paid</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
					valign='top' type='text'><strong>FOV</strong></td>
			</tr>
			<ng-container *ngFor="let item of result">
				<tr>
					<td colspan="10" style='border: 1px solid #000;'>
						<strong>{{item.mainCommodity}}</strong></td>
				</tr>
				<tr *ngFor="let value of item.values">
					<td width='15%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						{{value.subCommodity}}
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.totalLrs}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.totalArticles}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<span>{{value.actualWeight}}</span></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.actWtPercent}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.chargedWeight}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.chgWtPercent}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.toPay}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.paid}}</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						{{value.riskCharge}}</td>
				</tr>
				<tr>
					<td width='15%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						<strong> Total </strong> </td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{item.totalLrs}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{item.totalArticles}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{item.actualWeight}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{item.chargedWeight}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{item.toPay}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{item.paid}}</strong>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{item.riskCharge}}</strong></td>
				</tr>
			</ng-container>
			<br><br>
			<tr>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					<strong> Total </strong> </td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<strong> {{totArtNet}}</strong>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<strong>{{totActWgtNet}}</strong></td>
			</tr>
		</table>
		<!--/div>-->
		<!--</div>
		</div>-->
		<!--<br>
		<div class="container border">
			<table>
				<tr>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						<strong> Total </strong> </td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='4%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{totArtNet}}</strong>
					</td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totActWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totChgWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000; '>
						<strong>{{totGoodsValueNet}}</strong></td>
				</tr>
			</table>
		</div>-->
		<div class="row">
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-left: 20px; font-size: 17px; padding-top: 30px;'>
								<strong>HC-HANDLE WITH CARE, NW PRTY-NEW PARTY, COD </strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<br><br>
							<h6 style='margin-right: 20px; font-size: 17px; padding-top: 30px;'>
								<strong>Loading Clerk : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6><br><br>
							<h6 style='margin-right: 20px; font-size: 17px; padding-top: 30px;'>
								<strong>Incharge Sign : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>