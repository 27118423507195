import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
// /*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { AgentsRoutes } from 'src/app/report/agents/agents.routing';
import { DestinationCommissionComponent } from 'src/app/report/agents/destination-commission/destination-commission.component';
import { DestinationIncomeComponent } from 'src/app/report/agents/destination-income/destination-income.component';
import { StatementVerificationComponent } from 'src/app/report/agents/statement-verification/statement-verification.component';
import { RankingComponent } from 'src/app/report/agents/ranking/ranking.component';
import { MaterialModule } from "src/app/material.module";
import { StmtVerificationLrsDetailsReportComponent } from 'src/app/report/agents/stmt-verification-lrs-details-report/stmt-verification-lrs-details-report.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(AgentsRoutes), 
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatDialogModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule,CashmemoReportModule],
    declarations: [
    DestinationCommissionComponent,
    DestinationIncomeComponent,
    StatementVerificationComponent,
    RankingComponent,
    StmtVerificationLrsDetailsReportComponent]
})
export class AgentsModule { }
