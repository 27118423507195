import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { RequestDto } from 'src/app/dto/Request-dto';
import { MasterService } from 'src/app/dataService/master-service';

@Component({
    selector: 'app-lrdelete-masterpwd-setup',
    templateUrl: './lrdelete-masterpwd-setup.component.html',
    styleUrls: ['./lrdelete-masterpwd-setup.component.css']
})
export class LrdeleteMasterpwdSetupComponent implements OnInit {

    closeResult: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    requestDto: RequestDto = new RequestDto();
    passwordEntered: any;
    pageId = "ldmpsc";
    showSpinnerForAction = false;

    constructor(private modalService: NgbModal, private router: Router,
        public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    open2(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
    }

    validateSetup() {
        this.passwordEntered = $("#" + this.pageId + "masterPwd").val();
        if (this.passwordEntered == null || this.passwordEntered == '') {
            swal("Not Allowed", "Please enter the password to proceed!", "info");
        } else {
            swal({
                title: "Confirm Setup",
                text: "Sure U want to save/update the details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((Yes) => {
                if (Yes) {
                    this.confirmSave();
                }
            });
        }
    }

    confirmSave() {
        this.showSpinnerForAction = true;
        this.requestDto = new RequestDto();
        this.passwordEntered = $("#" + this.pageId + "masterPwd").val();
        this.requestDto.param1 = this.userDataDtoReturnSession.office;
        this.requestDto.companyId = this.userDataDtoReturnSession.companyId;
        this.requestDto.param2 = this.passwordEntered;
        this.requestDto.userId = this.userDataDtoReturnSession.userId;
        this.masterService.lrDeleteMasterPwdSetup(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Setup Done", "Successfully updated the details!", "info");
                    //this.resetFields();
                } else {
                    swal("Failed", "Failed to update the details, please try again or contact admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred!", "error");
            }, () => console.log('done');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    resetFields() {
        this.passwordEntered = null;
        $("#" + this.pageId + "masterPwd").val('');
    }

}
