import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';

@Component({
    selector: 'app-lr-entry-enable-consigneecopy',
    templateUrl: './lr-entry-enable-consigneecopy.component.html',
    styleUrls: ['./lr-entry-enable-consigneecopy.component.css']
})
export class LrEntryEnableConsigneecopyComponent implements OnInit {

    closeResult: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDtoUpdate: LRDto = new LRDto();
    lrNumberEntered: any;
    pageId = "lrecc";

    constructor(private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    open2(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
    }

    enableCCCopy() {
        swal({
            title: "Confirm Enable",
            text: "Sure you want to Enable the Consignee Copy Print for the entered LR No?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((Yes) => {
            if (Yes) {
                this.enableCCCopyMethod();
            }
        });
    }

    enableCCCopyMethod() {
        this.lrDtoUpdate = new LRDto();
        this.lrNumberEntered = $("#" + this.pageId + "lrNumber").val();
        this.lrDtoUpdate.lrNumber = this.lrNumberEntered;
        this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userName;
        this.masterReadService.enableLRCCCopyPrint(this.lrDtoUpdate).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Print Enabled", "Consignee Copy Print Enabled for the entered LR!", "info");
                    this.clearFields();
                } else if (response.status == "Failed") {
                    swal("Failed", "Failed to enable the Consignee Copy Print for the entered LR!", "error");
                } else if (response.status == "NoLR") {
                    swal("No LR", "Entered LR number not found, please check the LR Number!", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While updating the Details", "info");
            }, () => console.log('done');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    clearFields() {
        this.lrNumberEntered = null;
        $("#" + this.pageId + "lrNumber").val('');
        this.lrDtoUpdate = new LRDto();
    }

}
