<div *ngIf="firstPrintAdv" id="{{pageId}}firstPrint" class="page row system_responsive">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <!--Second  row starts-->
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="secondPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="secondPrintAdv" id="{{pageId}}secondPrint" class="page row system_responsive">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <!--Second  row starts-->
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="thirdPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="thirdPrintAdv" id="{{pageId}}thirdPrint" class="page row system_responsive">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <!--Second  row starts-->
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="fourthPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="fourthPrintAdv" id="{{pageId}}fourthPrint" class="page row system_responsive">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <!--Second  row starts-->
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>


        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="fifthPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="fifthPrintAdv" id="{{pageId}}fifthPrint" class="page row system_responsive">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <!--Second  row starts-->
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>


        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>