import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { PartyReportRoutes } from 'src/app/report/party-report/party-report.routing';
import { ConsignorReportComponent } from 'src/app/report/party-report/consignor-report/consignor-report.component';
import { ConsigneeReportComponent } from 'src/app/report/party-report/consignee-report/consignee-report.component';
import { BusinessStartReportComponent } from 'src/app/report/party-report/business-start-report/business-start-report.component';
import { BusinessStopReportComponent } from 'src/app/report/party-report/business-stop-report/business-stop-report.component';
import { PartyLessReportComponent } from 'src/app/report/party-report/party-less-report/party-less-report.component';
import { PartyWiseOsReportComponent } from 'src/app/report/party-report/party-wise-os-report/party-wise-os-report.component';
import { PartyWiseTotalStatusDetailsComponent } from 'src/app/report/party-report/party-wise-total-status-details/party-wise-total-status-details.component';
import { CommodityMismatchReportComponent } from 'src/app/report/party-report/commodity-mismatch-report/commodity-mismatch-report.component';
import { ConsigneeAdminRateReportComponent } from 'src/app/report/party-report/consignee-admin-rate-report/consignee-admin-rate-report.component';
import { RateHistoryReportComponent } from 'src/app/report/party-report/rate-history-report/rate-history-report.component';
import { AdminBusinessRateReportComponent } from 'src/app/report/party-report/admin-business-rate-report/admin-business-rate-report.component';
import { ConsigneeRateReportComponent } from 'src/app/report/party-report/consignee-rate-report/consignee-rate-report.component';
import { DiscontinuousDetailsReportComponent } from 'src/app/report/party-report/discontinuous-details-report/discontinuous-details-report.component';
import { RegularConsigneeReportComponent } from "src/app/report/party-report/regular-consignee-report/regular-consignee-report.component";
import { MaterialModule } from "src/app/material.module";
import { DragulaModule } from 'ng2-dragula';
import { LrReportModule } from 'src/app/report/lr-report/lr-report.module';
import { TransitLrsDetailsReportComponent } from 'src/app/report/party-report/transit-lrs-details-report/transit-lrs-details-report.component';
import { BusinessStartLRSDetailsReportComponent } from 'src/app/report/party-report/business-start-lrs-details-report/business-start-lrs-details-report.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { PartyWiseOsGroupingRptPrintComponent } from 'src/app/report/party-report/party-wise-os-grouping-rpt-print/party-wise-os-grouping-rpt-print.component';

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( PartyReportRoutes ),
         FormsModule,  
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, 
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, DragulaModule, 
        LrReportModule,CashmemoReportModule],
    declarations: [
        ConsignorReportComponent,
        ConsigneeReportComponent,
        BusinessStartReportComponent,
        BusinessStopReportComponent,
        PartyLessReportComponent,
        PartyWiseOsReportComponent,
        PartyWiseTotalStatusDetailsComponent,
        CommodityMismatchReportComponent,
        ConsigneeAdminRateReportComponent,
        RateHistoryReportComponent,
        AdminBusinessRateReportComponent,
        ConsigneeRateReportComponent,
        DiscontinuousDetailsReportComponent,
        RegularConsigneeReportComponent,
        TransitLrsDetailsReportComponent,
        BusinessStartLRSDetailsReportComponent,
        PartyWiseOsGroupingRptPrintComponent
    ]
} )
export class PartyReportModule { }