import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';

@Component({
    selector: 'app-lr-hold-reason',
    templateUrl: './lr-hold-reason.component.html',
    styleUrls: ['./lr-hold-reason.component.css']
})
export class LrHoldReasonComponent implements OnInit {

    closeResult: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDtoUpdate: LRDto = new LRDto();
    lrNumberEntered: any;
    holdReason: any;
    pageId = "lrecc";

    constructor(private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    open2(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
    }

    validateSave() {
        this.lrNumberEntered = $("#" + this.pageId + "lrNumber").val();
        this.holdReason = $("#" + this.pageId + "reason").val();
        if (this.lrNumberEntered == null || this.lrNumberEntered == '' || this.lrNumberEntered == undefined) {
            swal("Mandatory Field!", "Enter the LR number to proceed!", "warning");
        } else if (this.holdReason == null || this.holdReason == '' || this.holdReason == undefined) {
            swal("Mandatory Field!", "Enter the reason for hold!", "warning");
        } else {
            swal({
                title: "Confirm Submit",
                text: "Sure you want to update the hold reason for the entered LR No?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((Yes) => {
                if (Yes) {
                    this.submitHoldReason();
                }
            });
        }
    }

    submitHoldReason() {
        this.lrDtoUpdate = new LRDto();
        this.lrDtoUpdate.lrNumber = this.lrNumberEntered;
        this.lrDtoUpdate.reason = this.holdReason;
        this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userName;
        this.lrDtoUpdate.station = this.userDataDtoReturnSession.office;
        this.masterReadService.updateLRHoldReason(this.lrDtoUpdate).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Updated", "Hold reason updated for the entered LR!", "info");
                    this.clearFields();
                } else if (response.status == "Failed") {
                    swal("Failed", "Failed to update the reason for the entered LR!", "error");
                } else if (response.status == "NoLR") {
                    swal("No LR", "Entered LR number not found, please check the LR Number!", "warning");
                } else if (response.status == "NotStock") {
                    swal("Not Allowed", "Entered LR number not in stock, please verify again!", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While updating the Details", "info");
            }, () => console.log('done');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    clearFields() {
        this.lrNumberEntered = null;
        this.holdReason = null;
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "reason").val('');
        this.lrDtoUpdate = new LRDto();
    }

}
