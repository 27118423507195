<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Consignment Tracking</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
									<div class="form-group">
										<div class="input-group">
											<mat-progress-bar mode="indeterminate" style="color: green;">
											</mat-progress-bar>
											<br>
											<h6 class="card-title" align='center'
												style="color: green; margin: auto; font-size: 18px;">
												Please Wait Loading Details.....</h6>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group">
														<label>Track By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}trackBy"
															name="trackBy" #trackBy
															(change)="trackByMode(trackBy.value)">
															<option selected>Select track By</option>
															<option value="lrNumber">LR Number</option>
															<option value="invoiceNumber">Invoice Number</option>
															<option value="deviceId">Trip Id</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="trackByLrNumber" class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group">
														<label>LR Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}lrNumber" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="trackByInvoiceNumber" class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group">
														<label>Invoice Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}invoiceNumber" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="trackByDeviceId" class="col-sm-12 col-md-3">

												<div class="form-group">
													<div class="input-group">
														<label>Trip Id</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}deviceId" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>



											<div class="col-md-3" style="text-align: center;">
												<button type="submit" (click)="trackConsigment();"
													class="btn btn-success m-r-10" id="{{pageId}}btnTrack">Track
													Your Consignment</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 vl p-t-10">
						<agm-map [latitude]="lat" [longitude]="lng" [usePanning]="true"
							[zoom]="zoom" #map1> <agm-marker [latitude]="latLatest"
							[longitude]="lngLatest" [iconUrl]="'assets/images/TruckIcon.gif'">
						<agm-info-window [maxWidth]="500">
						<table style='border-collapse: collapse; width: 400px;'>
							<tr style='background-color: #f2f2f2'>
								<th
									style='text-align: center; padding: 8px; background-color: #f1a907; color: white;'>Description</th>
								<th
									style='text-align: center; padding: 8px; background-color: #f1a907; color: white;'>Details</th>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>Trip Id</td>
								<td style='text-align: center; padding: 8px;'>{{tripIdInfo}}</td>
							</tr>
							<tr style='background-color: #f9de92'>
								<td style='text-align: center; padding: 8px;'>Departure
									Date</td>
								<td style='text-align: center; padding: 8px;'>{{depDateInfo}}</td>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>Invoice
									Number</td>
								<td style='text-align: center; padding: 8px;'>{{invNoInfo}}</td>
							</tr>
							<tr style='background-color: #f9de92'>
								<td style='text-align: center; padding: 8px;'>Source</td>
								<td style='text-align: center; padding: 8px;'>{{sourceInfo}}</td>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>Destination</td>
								<td style='text-align: center; padding: 8px;'>{{destInfo}}</td>
							</tr>
							<tr style='background-color: #f9de92'>
								<td style='text-align: center; padding: 8px;'>Vehicle</td>
								<td style='text-align: center; padding: 8px;'>{{vehicleInfo}}</td>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>Driver Name /
									Contact</td>
								<td style='text-align: center; padding: 8px;'>{{driverInfo}}
									/ {{driverNo}}</td>
							</tr>
							<tr style='background-color: #f9de92'>
								<td style='text-align: center; padding: 8px;'>Last Location</td>
								<td style='text-align: center; padding: 8px;'>{{lastLocationInfo}}</td>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>Last Update</td>
								<td style='text-align: center; padding: 8px;'>{{lastUpdateInfo}}</td>
							</tr>
							<tr style='background-color: #f9de92'>
								<td style='text-align: center; padding: 8px;'>Distance
									Travelled</td>
								<td style='text-align: center; padding: 8px;'>{{distTravInfo}}</td>
							</tr>
							<tr style='background-color: #ffffff'>
								<td style='text-align: center; padding: 8px;'>ETA</td>
								<td style='text-align: center; padding: 8px;'>{{etaInfo}}</td>
							</tr>
						</table>
						</agm-info-window> </agm-marker> </agm-map>

					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>