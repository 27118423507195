<div mat-dialog-content id="{{pageId}}agentStockvisible">
    <div class="row p-t-10">
        <div class="col-md-12">
            <h6 id="{{pageId}}showHeader" style="margin-bottom: 0px;">Unloading LR Details For {{stmtNo}} - {{loaderHeadName}} , {{period}}.</h6>
        </div>
    </div>
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <h6><u>Loading Hamali Details:</u></h6>
                        <table datatable style="width: 100%;" id="{{pageId}}labourStmtLoadingHamaliTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLabourStmtLoadingHamali" [dtTrigger]="dtTriggerLabourStmtLoadingHamali">
                            <thead>
                                <tr>
                                    <th>Invoice Number </th>
                                    <th>Invoice Date</th>
                                    <th>Truck Number</th>
                                    <th>Kanta Wgt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let labourStmtLRReportData of labourStmtLoadingHamaliDataList ">
                                    <td>{{ labourStmtLRReportData.invoiceNumber }}</td>
                                    <td>{{ labourStmtLRReportData.hireslipDateStr }}</td>
                                    <td>{{ labourStmtLRReportData.vehicleNumber }}</td>
                                    <td>{{ labourStmtLRReportData.kantaWeight }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <h6><u>UnLoading Hamali Details:</u></h6>
                        <table datatable style="width: 100%;" id="{{pageId}}labourStmtUnLoadingHamaliTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLabourStmtUnLoadingHamali" [dtTrigger]="dtTriggerLabourStmtUnLoadingHamali">
                            <thead>
                                <tr>
                                    <th>Local Challan No </th>
                                    <th>Local Challan Date</th>
                                    <th>Temp No</th>
                                    <th>Agency Name</th>
                                    <th>Total Lrs</th>
                                    <th>Act Wgt</th>
                                    <th>Local Challan Kanta Wgt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let labourStmtLRReportData of labourStmtUnloadingReportDataList ">
                                    <td>{{ labourStmtLRReportData.challanNo }}</td>
                                    <td>{{ labourStmtLRReportData.arrivalDateStr }}</td>
                                    <td>{{ labourStmtLRReportData.vehicleNumber }}</td>
                                    <td>{{ labourStmtLRReportData.agentName }}</td>
                                    <td>{{ labourStmtLRReportData.totalLrs }}</td>
                                    <td>{{ labourStmtLRReportData.actualWeight }}</td>
                                    <td>{{ labourStmtLRReportData.challanKantaWgt }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 vl p-t-10">
            <div class="box-body">
                <h6><u>Addition:</u></h6>
                <table datatable id="{{pageId}}labourStatementRptAdditionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLabourStatementReportLrAdditionDetails" [dtTrigger]="dtTriggerLabourStatementReportLrAdditionDetails">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let statementReportLrAdditionData of labourStatementReportLrAdditionDataList ">
                            <td>{{ statementReportLrAdditionData.description }}</td>
                            <td>{{ statementReportLrAdditionData.totalAmount }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="box-body">
                <br>
                <h6><u>Deduction:</u></h6>
                <table datatable id="{{pageId}}labourStatementRptDeductionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLabourStatementReportLrDeductionDetails" [dtTrigger]="dtTriggerLabourStatementReportLrDeductionDetails">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Debit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let statementReportLrDeductionData of labourStatementReportLrDeductionDataList ">
                            <td>{{ statementReportLrDeductionData.description }}</td>
                            <td>{{ statementReportLrDeductionData.totalAmount }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;" id="{{pageId}}printvisible">
    <button class="btn btn-success m-r-10" mat-button (click)="onPrintClick();">Print</button>
    <button class="btn btn-outline-danger" mat-button (click)="onCancelClick();">Cancel</button>
</div>