import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ConsigneeService } from 'src/app/dataService/consignee-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends


@Component( {
    selector: 'app-consignee-grouping',
    templateUrl: './consignee-grouping.component.html',
    styleUrls: ['./consignee-grouping.component.css']
} )
export class ConsigneeGroupingComponent implements OnInit {

    //to insert value of selected row in table to input field starts 
    consigneeName: string;
    selectedUser: any;
    //to insert value of selected row in table to input field ends

    getDataFrmServiceFrRepeatedConsigneeListTable: any;
    getDataFrmServiceFrMergeConsigneeListTable: any;
    getDataFrmServiceFrConfirmedConsigneeListTable: any;

    repeatedConsigneeListDataList: any;
    mergeConsigneeListDataList: any;
    confirmedConsigneeListDataList: any;


    onDestroyUnsubscribtionRepeatedConsigneeList: Subscription;
    onDestroyUnsubscribtionMergeConsigneeList: Subscription;
    onDestroyUnsubscribtionConfirmedConsigneeList: Subscription;
    //for the select option with filter starts


    controlDestination = new FormControl();
    controlConsignorIndex = new FormControl();

    destinationOptions = [
        { id: 1, label: 'Agra' },
        { id: 2, label: 'Bangalore' },
        { id: 3, label: 'Chennai' },
        { id: 4, label: 'Delhi' },
        { id: 5, label: 'Erode' },
    ];

    consignorIndexOptions = [
        { id: 1, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }
    ];


    //for the select option with filter ends

    //for popup modal starts
    closeResultContent: string;
    //for popup modal ends




    //summaryTable:any;

    loadingIndicator = true;


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;



    dataTable: any;


    //

    dtTriggerRepeatedConsigneeList: Subject<any> = new Subject();
    dtTriggerMergeConsigneeList: Subject<any> = new Subject();
    dtTriggerConfirmedConsigneeList: Subject<any> = new Subject();

    //


    // @ViewChild('dataTable') table: ElementRef;
    dtOptionsRepeatedConsigneeList: any;
    dtOptionMergeConsigneeList: any;
    dtOptionConfirmedConsigneeList: any;
    pageId="cngc";

    constructor( private consigneeMerge: ConsigneeService, public changeDetectorRef : ChangeDetectorRef,
        //for popup modal starts
        private modalService: NgbModal
        //for popup modal ends                
    ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {

        //first datatable starts
        this.dtOptionsRepeatedConsigneeList = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }

            ],

            // the below code is for button export ends                          

            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends

            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 270,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            pageLength: 14,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends


        },//first datatable ends
            //second datatable starts 
            this.dtOptionMergeConsigneeList = {
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 250,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            //second datatable ends
            //third datatable starts

            this.dtOptionConfirmedConsigneeList = {
                // the below code is for button export starts
                dom: 'Bfrtip',

                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 270,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                pagingType: 'full_numbers',
                pageLength: 14,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            }


        //third datatable ends

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );

        //first service starts 
        this.getDataFrmServiceFrRepeatedConsigneeListTable = this.consigneeMerge.getSupplierMasterData()
        this.onDestroyUnsubscribtionRepeatedConsigneeList = this.getDataFrmServiceFrRepeatedConsigneeListTable.subscribe( data => {
            this.repeatedConsigneeListDataList = data['data'];
            this.dtTriggerRepeatedConsigneeList.next();
        } );
        //first service ends
        //second service starts 

        this.getDataFrmServiceFrMergeConsigneeListTable = this.consigneeMerge.getSummaryData();
        this.onDestroyUnsubscribtionMergeConsigneeList = this.getDataFrmServiceFrMergeConsigneeListTable.subscribe( data => {
            this.mergeConsigneeListDataList = data['data'];
            this.dtTriggerMergeConsigneeList.next();
        } );
        //second service ends
        //third service starts  
        this.getDataFrmServiceFrConfirmedConsigneeListTable = this.consigneeMerge.getSupplierMasterData();
        this.onDestroyUnsubscribtionConfirmedConsigneeList = this.getDataFrmServiceFrConfirmedConsigneeListTable.subscribe( data => {
            this.confirmedConsigneeListDataList = data['data'];
            this.dtTriggerConfirmedConsigneeList.next();
        } );
        //third service ends
        //the below code is for the getting data through json ends

    }



    ngOnDestroy(): void {
        this.dtTriggerRepeatedConsigneeList.unsubscribe();
        this.dtTriggerMergeConsigneeList.unsubscribe();
        this.dtTriggerConfirmedConsigneeList.unsubscribe();

        this.onDestroyUnsubscribtionRepeatedConsigneeList.unsubscribe();
        this.onDestroyUnsubscribtionMergeConsigneeList.unsubscribe();
        this.onDestroyUnsubscribtionConfirmedConsigneeList.unsubscribe();

    }
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected( confirmedConsigneeListData ) {
        this.selectedUser = confirmedConsigneeListData;
        this.consigneeName = this.selectedUser.name;
        console.log( this.selectedUser );
    }
    //to insert value of selected row in table to input field ends
    //for popup modal starts 

    openPopup( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }

    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

}
