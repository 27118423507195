import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";


@Component({
	selector: 'app-admin-business-rate-report',
	templateUrl: './admin-business-rate-report.component.html',
	styleUrls: ['./admin-business-rate-report.component.css']
})

export class AdminBusinessRateReportComponent implements OnInit {
	getDataFrmServiceFrRateForPartiesTable: any;
	getDataFrmServiceFrCommodityRateDetailsTable: any;
	rateForPartiesTableDataList: any;
	commodityRateDetailsTableDataList: any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends
	loadingIndicator = true;
	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	public modelDestinationFotGet: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerRateForPartiesTable: Subject<any> = new Subject();
	dtTriggerCommodityRateDetailsTable: Subject<any> = new Subject();

	dataTable: any;
	dtOptionsRateForPartiesTable: any;
	dtOptionsCommodityRateDetailsTable: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	viewDestination = false;
	destView = false;
	ShowDestForAdminBussRateRpt = 'Show Dest AdminBussRateRpt';
	address: any;
	office: any;
	fromDateModel: any;
	toDateModel: any;
	selectedFromDate: any;
	selectedToDate: any;
	selectedDestination: any;
	searchDetailsByRateDto: RateMasterDto = new RateMasterDto();
	searchDetailsByCommodityDto: RateMasterDto = new RateMasterDto();

	//print
	viewCustomPrintV1 = false;

	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;


	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	//Custom Print Footer
	summary1: number;
	summary2: number;
	summary3: number;
	summary4: number;
	summary5: number;
	summary6: number;
	pageId = "abrrc";


	constructor(private router: Router, private masterReadService: MasterReadService,
		private datePipe: DatePipe, private lrReportService: LrReportService,
		private masterService: MasterService, private consigneeService: ConsigneeService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;


			///Rights for show Show Source List
			//            if ( this.ShowDestForAdminBussRateRpt == "Show Dest AdminBussRateRpt" ) {
			//                this.viewDestination = true;
			//this.getDestinationDetails();         
			//} 

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"AdminBusinessRateRpt destView") {
						this.destView = true;
					}

				}
			}
			//need to comment this bel
			//  this.destView =true;
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				if (dtInstance.table().node().id == "rateForPartiesTableId") {
					console.log("a");
					this.rateForPartiesTableDataList = [];
					dtInstance.destroy();
					this.dtTriggerRateForPartiesTable.next();
				} else if (dtInstance.table().node().id == "commodityRateDetailsTableId") {
					console.log("b");
					this.commodityRateDetailsTableDataList = [];
					dtInstance.destroy();
					this.dtTriggerCommodityRateDetailsTable.next();
				}
			});
		});
	}





	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
		var mainStation = this.office;
		console.log(mainStation);
		var destVisible = false;
		if (this.destView == true) {
			destVisible = true;
		} else {
			destVisible = false;
		}

		this.dtOptionsRateForPartiesTable = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnSummaryExcel = null;
						if (destVisible == true) {

							returnSummaryExcel = "Consignee Admin Rate Report " +
								" From Date : " + moment($("#abrrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#abrrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#abrrcdestination").val();
						} else {

							returnSummaryExcel = "Consignee Admin Rate Report " +
								" From Date : " + moment($("#abrrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#abrrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + mainStation;
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],

			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},

			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalArticles = api.column(18).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var perDayArt = api.column(19).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalActWgt = api.column(20).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var perDayActWgt = api.column(21).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totalChgWgt = api.column(22).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var perDayChgWgt = api.column(23).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
				$(api.column(7).footer()).html();
				$(api.column(8).footer()).html();
				$(api.column(9).footer()).html();
				$(api.column(10).footer()).html();
				$(api.column(11).footer()).html();
				$(api.column(12).footer()).html();
				$(api.column(13).footer()).html();
				$(api.column(14).footer()).html();
				$(api.column(15).footer()).html();
				$(api.column(16).footer()).html();
				$(api.column(17).footer()).html();
				$(api.column(18).footer()).html(totalArticles);
				$(api.column(19).footer()).html(perDayArt);
				$(api.column(20).footer()).html(totalActWgt);
				$(api.column(21).footer()).html(perDayActWgt);
				$(api.column(22).footer()).html(totalChgWgt);
				$(api.column(23).footer()).html(perDayChgWgt);


			}
		},
			this.dtOptionsCommodityRateDetailsTable = {

				dom: 'Bfrtip',

				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							var returnSummaryExcel = null;
							if (destVisible == true) {
								returnSummaryExcel = "Consignee Admin Rate Report " +
									" From Date : " + moment($("#abrrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#abrrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#abrrcdestination").val();
							} else {
								returnSummaryExcel = "Consignee Admin Rate Report " +
									" From Date : " + moment($("#abrrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#abrrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + mainStation;
							}
							return returnSummaryExcel;
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				// the below code is for button export ends
				//place holder in search/filter in datatable starts
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": true,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var totalArticles = api.column(17).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var perDayArt = api.column(18).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var totalActWgt = api.column(19).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var perDayActWgt = api.column(20).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var totalChgWgt = api.column(21).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var perDayChgWgt = api.column(22).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();
					$(api.column(11).footer()).html();
					$(api.column(12).footer()).html();
					$(api.column(13).footer()).html();
					$(api.column(14).footer()).html();
					$(api.column(15).footer()).html();
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html(totalArticles);
					$(api.column(18).footer()).html(perDayArt);
					$(api.column(19).footer()).html(totalActWgt);
					$(api.column(20).footer()).html(perDayActWgt);
					$(api.column(21).footer()).html(totalChgWgt);
					$(api.column(22).footer()).html(perDayChgWgt);
					$(api.column(23).footer()).html();


				}
			}

	}




	ngOnDestroy(): void {
		this.dtTriggerRateForPartiesTable.unsubscribe();
		this.dtTriggerCommodityRateDetailsTable.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerRateForPartiesTable.next();
		this.dtTriggerCommodityRateDetailsTable.next();
	}

	clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelDestination = e.item;
		$("#" + this.pageId + "destination").val(this.modelDestination.destination);
	}

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}

					}
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;

				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};



	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	searchMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.searchDetailsByRateDto = new RateMasterDto();

		if (this.destView == true) {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '' || this.selectedDestination == null || this.selectedDestination == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date , Destination Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchDetailsByRateDto.destination = this.selectedDestination;
			}

		} else {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchDetailsByRateDto.destination = this.userDataDtoReturnSession.mainStation;
			}
		}
		this.searchDetailsByRateDto.fromDateStg = this.selectedFromDate;
		this.searchDetailsByRateDto.toDateStg = this.selectedToDate;
		this.searchDetailsByRateDto.fromDate = this.selectedFromDate;
		this.searchDetailsByRateDto.toDate = this.selectedToDate;
		console.log(this.searchDetailsByRateDto);
		this.searchDetailsForAdminBusinessRateRptForParty();
		// this.searchDetailsForAdminBusinessRateRptForCommodity();

	}
	searchDetailsForAdminBusinessRateRptForParty = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeAdminRateReportDetailsBusinessTrxRpt(this.searchDetailsByRateDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "rateForPartiesTableId").DataTable().destroy();
				this.rateForPartiesTableDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Rate Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.rateForPartiesTableDataList = response;
					console.log(this.rateForPartiesTableDataList);
					this.searchDetailsForAdminBusinessRateRptForCommodity();
				}
				this.dtTriggerRateForPartiesTable.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Rate For Parties details", "info");
			}, () => console.log('done');
	};

	searchDetailsForAdminBusinessRateRptForCommodity = () => {
		this.searchDetailsByCommodityDto = new RateMasterDto();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();

		this.searchDetailsByRateDto.fromDate = this.selectedFromDate;
		this.searchDetailsByRateDto.toDate = this.selectedToDate;
		this.searchDetailsByCommodityDto.city = this.selectedDestination;
		this.searchDetailsByCommodityDto.mode = "specific";
		this.searchDetailsByCommodityDto.rptModeId = 1;
		this.searchDetailsByCommodityDto.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeAdminRateDetails(this.searchDetailsByCommodityDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "commodityRateDetailsTableId").DataTable().destroy();
				this.commodityRateDetailsTableDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Commodity Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.commodityRateDetailsTableDataList = response;
					console.log(this.commodityRateDetailsTableDataList);
				}
				this.dtTriggerCommodityRateDetailsTable.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
			}, () => console.log('done');
	};


	validateClearBtn() {
		$("#" + this.pageId + "fromDate").val();
		$("#" + this.pageId + "toDate").val();
		$("#" + this.pageId + "destination").val();

		this.selectedFromDate = null;
		this.selectedToDate = null;
		this.selectedDestination = null;
		this.modelDestination = null;
		this.fromDateModel = null;
		this.toDateModel = null;

		$("#" + this.pageId + "rateForPartiesTableId").DataTable().destroy();
		this.rateForPartiesTableDataList = [];
		this.dtTriggerRateForPartiesTable.next();

		$("#" + this.pageId + "commodityRateDetailsTableId").DataTable().destroy();
		this.commodityRateDetailsTableDataList = [];
		this.dtTriggerCommodityRateDetailsTable.next();
	}

	customPrintAdminBussinessRateRpt() {
		if (this.commodityRateDetailsTableDataList == null ||
			this.commodityRateDetailsTableDataList == undefined ||
			this.commodityRateDetailsTableDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Source", "Commodity", "Rate",
				"Unit", "Des Ham", "G.C", "R.C", "S.C", "P Unit", "Deliv Type", "Eff.Date"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [13, 15, 8, 8, 8, 8, 8, 8, 8, 8, 8];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.commodityRateDetailsTableDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [
					this.commodityRateDetailsTableDataList[i].destination,
					this.commodityRateDetailsTableDataList[i].commodityName,
					this.commodityRateDetailsTableDataList[i].rate,
					this.commodityRateDetailsTableDataList[i].unit,
					this.commodityRateDetailsTableDataList[i].hamaliDestination,
					this.commodityRateDetailsTableDataList[i].gcCharge,
					this.commodityRateDetailsTableDataList[i].receiptCharges,
					this.commodityRateDetailsTableDataList[i].serviceCharges,
					this.commodityRateDetailsTableDataList[i].perUnit,
					this.commodityRateDetailsTableDataList[i].deliveryType,
					this.commodityRateDetailsTableDataList[i].effectiveFromDateStg
				];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				//this.summary1 = this.summary1 + this.commodityRateDetailsTableDataList[i].totalLrs;
				//this.summary2 = this.summary2 + this.commodityRateDetailsTableDataList[i].totallrsfromTripSheet;
				//this.summary3 = this.summary3 + this.commodityRateDetailsTableDataList[i].totalInvoice;
				this.cashMemoDtoForCustomPrintDataSummaryList = [
					"Total : " + this.commodityRateDetailsTableDataList.length,
					"", "", "", "", "", "", "", "", "", ""
				];
			}
			//heading logics For Date
			//this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
			//this.toDatePrint = $("#"+this.pageId+"toDates").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#" + this.pageId + "destination").val()];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Commodity Rate Details");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

}
