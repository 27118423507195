import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-partwise-cft-details-report',
    templateUrl: './partwise-cft-details-report.component.html',
    styleUrls: ['./partwise-cft-details-report.component.css']
})
export class PartwiseCFTDetailsReportComponent implements OnInit {



    lrDtoRet: any;
    destinationDataList: LRDto[];
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView: any;
    controlSource = new FormControl();
    controlDestination = new FormControl();
    viewConsigneeField: boolean;
    selectedPartyType: any;
    slabSetupDataAllList: LRDto[];

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCflSlabsSetup: Subject<any> = new Subject();
    dtTriggerCflSlabsAllSetup: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCflSlabsSetup: any;
    dtOptionsCflSlabsAllSetup: any;

    selectedFromStation: any;
    selectedToStation: any;

    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                : this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;

    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                : this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;
    pageId = "lhdsc";

    // source
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { source: string }) => x.source;

    /* For Destination Drop Down */
    destinationOptions: LRDto[];
    lrDtoToStation: LRDto = new LRDto();
    destinationDtoForAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { mainstation: string }) => x.mainstation;

    /* Commodity Drop Down */
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    enteredSourceForSave: any;
    enteredCommodityForSave: any;
    enteredDestinationForSave: any;
    lrDto: LRDto = new LRDto();
    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.timeSettingDetailsTable();
            this.getBranchList();
            this.getAgentDetailList();
            this.getCommodityMasterDetailsList();
            // this.activeRangeDetailsData();
        }
    }

    ngOnInit() {
    }




    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    timeSettingDetailsTable() {

        //the first datatable starts
        this.dtOptionsCflSlabsSetup = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
        },
            this.dtOptionsCflSlabsAllSetup = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        title: function () {
                            return "Partywise CFT Details Report";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                searching: false,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                pagingType: 'full_numbers',
                pageLength: 9,
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerCflSlabsSetup.unsubscribe();
        this.dtTriggerCflSlabsAllSetup.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerCflSlabsSetup.next();
        this.dtTriggerCflSlabsAllSetup.next();
    }




    getBranchList() {
        this.destinationDataList = []
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.mode = "activeDeliveryOfc";
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch).subscribe(
            (response) => {
                if (response) {
                    this.destinationTA = [];
                    if (response.length > 0) {
                        this.destinationDataList = response;
                        let lrDtoAllDest = new LRDto();
                        lrDtoAllDest.mainstation = "All";
                        this.destinationTA.push(lrDtoAllDest);
                        for (let i = 0; i < this.destinationDataList.length; i++) {
                            this.destinationTA.push(this.destinationDataList[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the the destination details", "info");
            },
            () => console.log('done');
    }

    clearFields() {
        this.lrDtoRet = null;

        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "commodityListId").val('');
        this.enteredSourceForSave = null;
        this.enteredDestinationForSave = null;
        this.enteredCommodityForSave = null;

        $("#" + this.pageId + "slabsTableId").DataTable().destroy();
        this.dtTriggerCflSlabsSetup.next();
    }

    activeRangeDetailsData() {
        this.lrDtoSearch = new LRDto();
        this.getActiveRangeDetailsData();
    }

    getActiveRangeDetailsData() {
        this.selectedPartyType = $("#" + this.pageId + "partyType").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        if (this.selectedPartyType == 'consigneeWise') {
            this.lrDtoSearch.partyType = "Consignee";
        } else if (this.selectedPartyType == 'consignorWise') {
            this.lrDtoSearch.partyType = "Consignor";
        }
        this.lrDtoSearch.destination = this.enteredDestinationForSave;
        console.log(this.enteredDestinationForSave)
        console.log(this.selectedPartyType)
        console.log(this.lrDtoSearch)


        this.masterService.getPartyWiseCFTDetailsService(this.lrDtoSearch).subscribe(
            (response) => {
                console.log(response);
                this.slabSetupDataAllList = [];
                $("#" + this.pageId + "slabSetupDataAllTableId").DataTable().destroy();
                this.slabSetupDataAllList = response;
                if (response.length == 0) {
                    swal("No Records", "No CFT details found!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerCflSlabsAllSetup.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the CFT details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }




    getUserValuesForAgentDetailsList() {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = "ALL";
    }

    getAgentDetailList() {
        $("#" + this.pageId + "agentName").val('');
        this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList();
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsSet = response;
                    this.agentDtoParam = new AgentDetailsDto();
                    this.agentDtoParam.source = "ALL";
                    this.agentNameTA.push(this.agentDtoParam);
                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getCommodityListRead() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "ALL";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Master Details", "warning");
            },
            () => console.log('done');
    }

    clearDetails() {
        this.clearFields();
    }

    searchMethod() {
        this.selectedPartyType = $("#" + this.pageId + "partyType").val();

        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();



        if (((this.selectedPartyType == null) || (this.selectedPartyType == undefined)) &&
            ((this.enteredDestinationForSave == null) || (this.enteredDestinationForSave == undefined))
        ) {
            swal("Mandatory Fields", "Please select the mandatory field", "warning");
            return false;

        } else {
            this.activeRangeDetailsData();
        }



    }

}
