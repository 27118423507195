<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}transferToColManId">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Transfer To Collection</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div *ngIf="memoTypeView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Transfer Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search	"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}transferType" name="transferType" #transferType (change)="transferTypeMode(transferType.value)">
															<option value="">Select Transfer Type</option>
															<option value="manualCashMemo">Manual Cash Memo</option>
															<option value="paymentCashMemo">Payment Cash
																Memo</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Staion</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-warehouse"></i>
															</span>
                                                        </div>
                                                        <input #station id="{{pageId}}station" name="station" type="text" class="form-control" placeholder="Select Station" [(ngModel)]="modelStationName" [ngbTypeahead]="stationSearchTA" (selectItem)="getMemoCode($event)" [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterStation" [inputFormatter]="formatterStation" (focus)="focusStationTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Entry Type :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <!-- <select class="custom-select col-12" id="{{pageId}}entryType"
															name="entryType" #entryType
															(change)="entryTypeMode(entryType.value)">
															<option value="manual">Manual</option>
															<option value="scan">Scan</option>
														</select> -->
                                                        <select class="custom-select col-12" id="{{pageId}}entryType" name="entryType" #entryType (change)="entryTypeMode(entryType.value)" [disabled]="isReadOnly">
															<option value="manual">Manual</option>
															<option selected value="scan">Scan</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="manualView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cash Memo :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}codeForManual" #codeForManual aria-describedby="basic-addon11">
                                                        <input id="{{pageId}}cashMemoManual" type="text" class="form-control" (keyup)="getMemoNumberForManual($event)" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="scanView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cash Memo :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}cashMemoAutomatic" (keyup)="getMemoNumberForAutomatic($event)" #cashMemoAutomatic aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-7 vl p-t-10">
                                        <div class="row">
                                            <!-- column -->
                                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <!-- <div class="row p-t-10">
															<div class="col-md-8"></div>
														</div> -->
                                                        <div class="row p-t-10">
                                                            <div class="col-md-8">
                                                                <h6 class="card-title">Transfer To Collection</h6>
                                                            </div>
                                                            <div class="col-md-4" style="text-align: right;">
                                                                <button type="submit" class="dt-button" (click)="customPrintTransToColMan()" id="{{pageId}}printAllBtn">
																	<span><i class="fas fa-print">Print</i></span>
																</button>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="box-body">
                                                                    <table datatable id="{{pageId}}transferToCollectionId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTransferToCollection" [dtTrigger]="dtTriggerTransferToCollection">

                                                                        <thead>
                                                                            <tr>
                                                                                <th>Cash Memo</th>
                                                                                <th>LR No</th>
                                                                                <th>Tot Art</th>
                                                                                <th>Consignee</th>
                                                                                <th>Consignor</th>
                                                                                <th>Amount</th>
                                                                                <th>Collection Man</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let transferToCollectionData of transferToCollectionDataList; let i = index ">
                                                                                <td>{{ transferToCollectionData.memoNumber }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.lrNumber }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.totalArticles }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.consigneeName }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.consignor }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.amount }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.collectionMan }}
                                                                                </td>
                                                                                <td>

                                                                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForTable(transferToCollectionData,i);">
																						<i title="Remove"
																							class="fas fa-trash"></i>
																					</button>
                                                                                </td>

                                                                            </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td style="text-align: left">Total : {{countOfLr}}
                                                                                </td>
                                                                                <td></td>
                                                                                <td style="text-align: left">
                                                                                    {{sumOfTotArt}}</td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style="text-align: left">
                                                                                    {{sumOfTotAmt}}</td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-2 margin_bt">
                                        <div class="box-body">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}transferBtn" (click)="transferBtnValidation();">Transfer</button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

</body>

</html>