import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { LrService } from 'src/app/dataService/lr-service';

@Component({
    selector: 'app-statement-rpt-lr-details-print',
    templateUrl: './statement-rpt-lr-details-print.component.html',
    styleUrls: ['./statement-rpt-lr-details-print.component.css']
})
export class StatementRptLrDetailsPrintComponent implements OnInit {

    getDataFrmServiceFrTable: any;



    onDestroyUnsubscribtionStatementReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dataTable: any;


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;


    lrDto: LRDto = new LRDto();
    stmtRptLrForAdditionLrPrintLocalStorage: any;
    stmtRptLrForDeductionLrPrintLocalStorage: any;
    stmtRptLrForGeneralLrPrintLocalStorage: any;
    stmtRptLrDtlsPrintLocalStorage: any;

    additionDataDatailList: any;
    deductionDataDetailList: any;

    destination: any = null;
    fromPeriod: any = null;
    toPeriod: any = null;
    stmtDateStr: any = null;
    stmtNo: any = null;

    summaryHashMap = new Map<String, FortNightStmtDto>();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    
    fortNightStmtDtoInvoice: FortNightStmtDto = new FortNightStmtDto();

    summaryAdditionTotAmt: number = 0;
    summaryDeductionTotAmt: number = 0;

    result: any;
    result1: any;
    result3: any;
    finalResult: any;
    summaryTotalLrs: any;
    summaryTotalActWgt: any;
    summaryTotalAmt: any;
    summaryTotalPaid: any;
    summaryTotalServTax: any;

    private newAttribute: any = {};
    private newAttributeForaValidate: any = {};
pageId="srldpc";
    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private destStmtReport: DestStmtReport,private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.stmtRptLrForAdditionLrPrintLocalStorage = JSON.parse(localStorage.getItem('stmtRptLrDtlsAdditionPrintLocalStorage'));
            this.stmtRptLrForDeductionLrPrintLocalStorage = JSON.parse(localStorage.getItem('stmtRptLrDtlsDeductionPrintLocalStorage'));
            this.stmtRptLrForGeneralLrPrintLocalStorage = JSON.parse(localStorage.getItem('stmtRptLrDtlsGeneralPrintLocalStorage'));
            //this.stmtRptLrDtlsPrintLocalStorage = JSON.parse(localStorage.getItem('stmtRptLrDtlsPrintLocalStorage'));
            console.log(this.stmtRptLrForAdditionLrPrintLocalStorage);
            console.log(this.stmtRptLrForDeductionLrPrintLocalStorage);
            console.log(this.stmtRptLrForGeneralLrPrintLocalStorage);
            //console.log(this.stmtRptLrDtlsPrintLocalStorage);
            if (this.stmtRptLrForAdditionLrPrintLocalStorage != null) {
                this.additionDataDatailList = this.stmtRptLrForAdditionLrPrintLocalStorage;
                for (let i = 0; i < this.additionDataDatailList.length; i++) {
                    var totalAmout = this.additionDataDatailList[i].totalAmount == null ? 0 : this.additionDataDatailList[i].totalAmount;
                    this.summaryAdditionTotAmt = +this.summaryAdditionTotAmt + +totalAmout;
                }
            }
            if (this.stmtRptLrForDeductionLrPrintLocalStorage != null) {
                this.deductionDataDetailList = this.stmtRptLrForDeductionLrPrintLocalStorage;
                for (let i = 0; i < this.deductionDataDetailList.length; i++) {
                    var totalAmout = this.deductionDataDetailList[i].totalAmount == null ? 0 : this.deductionDataDetailList[i].totalAmount;
                    this.summaryDeductionTotAmt = +this.summaryDeductionTotAmt + +totalAmout;
                }
            }
            if (this.stmtRptLrForGeneralLrPrintLocalStorage != null) {
                this.destination = this.stmtRptLrForGeneralLrPrintLocalStorage.destination;
                this.stmtNo = this.stmtRptLrForGeneralLrPrintLocalStorage.stmtId;
                this.fromPeriod = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.fromPeriod).format('DD-MM-YYYY');
                this.toPeriod = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.toPeriod).format('DD-MM-YYYY');
                this.stmtDateStr = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.stmtDate).format('DD-MM-YYYY');
            }
            if (this.stmtRptLrForGeneralLrPrintLocalStorage != null) {
                this.setBookingServicePrintSummary();
            }
            // let printElement = document.getElementById('stmtReptLrDetailsprintIds').innerHTML;    
            //console.log(printElement);
            /* window.setTimeout( function() {
                 window.print();
               }, 1000 );
               window.onafterprint = function() {
                   window.close();
               }
               localStorage.clear();
               window.addEventListener( 'afterprint', ( event ) => {
                  //this.clearField();
               } );*/
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

    }


    setBookingServicePrintSummary() {
    	this.fortNightStmtDtoInvoice = new FortNightStmtDto();
        this.fortNightStmtDtoInvoice.stmtId = this.stmtRptLrForGeneralLrPrintLocalStorage.stmtId;
        this.fortNightStmtDtoInvoice.companyId = this.userDataDtoReturnSession.companyId;
        this.lrService.getFortNightInvoice(this.fortNightStmtDtoInvoice).subscribe(
            (response) => {
                this.stmtRptLrDtlsPrintLocalStorage = [];
                if (response.length > 0) {
                    this.stmtRptLrDtlsPrintLocalStorage = response;
                    this.setHashMapValues();
                    /* */
                    window.setTimeout(function () {
                       window.print();
                    }, 0);

                    window.onafterprint = function () {
                        window.close();
                    }
                    localStorage.clear();
                    window.addEventListener('afterprint', (event) => {
                        localStorage.clear();
                    });
                } else {
                    this.stmtRptLrDtlsPrintLocalStorage = [];
                }
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }
    
    setHashMapValues() {
        console.log(this.stmtRptLrDtlsPrintLocalStorage);
        this.summaryTotalLrs = 0;
        this.summaryTotalActWgt = 0;
        this.summaryTotalAmt = 0;
        this.summaryTotalPaid = 0;
        this.summaryTotalServTax = 0;
        for (let i = 0; i < this.stmtRptLrDtlsPrintLocalStorage.length; i++) {
            this.summaryTotalLrs = +this.summaryTotalLrs + +this.stmtRptLrDtlsPrintLocalStorage[i].totalLrs;
            this.summaryTotalActWgt = +this.summaryTotalActWgt + +this.stmtRptLrDtlsPrintLocalStorage[i].totalActWgt;
            this.summaryTotalAmt = +this.summaryTotalAmt + +this.stmtRptLrDtlsPrintLocalStorage[i].toPay;
            this.summaryTotalPaid = +this.summaryTotalPaid + +this.stmtRptLrDtlsPrintLocalStorage[i].totalPaid;
            this.summaryTotalServTax = +this.summaryTotalServTax + +this.stmtRptLrDtlsPrintLocalStorage[i].serviceTaxAdd;
        }

        var groups = new Set(this.stmtRptLrDtlsPrintLocalStorage.map(item => item.branch).sort());
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totalActWgt = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.branch === g)
                .reduce((sum, i) => +sum + +i.totalActWgt, 0)
            const totalAmount = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.branch === g)
                .reduce((sum, i) => +sum + +i.toPay, 0)
            const totalPaid = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.branch === g)
                .reduce((sum, i) => +sum + +i.totalPaid, 0)
            const serviceTaxAdd = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.branch === g)
                .reduce((sum, i) => +sum + +i.serviceTaxAdd, 0)

            this.result.push({
            	branch: g,
                values: this.stmtRptLrDtlsPrintLocalStorage.filter(i => i.branch === g),
                totalActWgt: totalActWgt,
                totalAmount: totalAmount,
                totalPaid: totalPaid,
                serviceTaxAdd: serviceTaxAdd,
            })
        })

        this.result1 = [];
        for (let i = 0; i < this.result.length; i++) {
            this.newAttribute = {};
            this.newAttribute.branch = this.result[i].branch;
            this.newAttribute.totalActWgt = this.result[i].totalActWgt;
            this.newAttribute.totalAmount = this.result[i].totalAmount;
            this.newAttribute.totalPaid = this.result[i].totalPaid;
            this.newAttribute.serviceTaxAdd = this.result[i].serviceTaxAdd;
            var inv_No = null;
            var newInv_no = null;

            for (let j = 0; j < this.result[i].values.length; j++) {
                if (j == 0) {
                    inv_No = this.result[i].values[j].invoiceNumber;
                } else {
                    inv_No += "," + this.result[i].values[j].invoiceNumber;
                }
            }
            this.newAttribute.invoiceNumber = inv_No;
            this.result1.push(this.newAttribute);
        }
        console.log(this.result1);

        var list = [];
        this.finalResult = [];
        for (let i = 0; i < this.result1.length; i++) {
            var invoicNoSplit = this.result1[i].invoiceNumber.split(",");
            list = invoicNoSplit.filter(function (x, i, a) {
                return a.indexOf(x) == i;
            });
            this.newAttribute = {};
            this.newAttribute.branch = this.result1[i].branch;
            this.newAttribute.totalActWgt = this.result1[i].totalActWgt;
            this.newAttribute.totalAmount = this.result1[i].totalAmount;
            this.newAttribute.totalPaid = this.result1[i].totalPaid;
            this.newAttribute.serviceTaxAdd = this.result1[i].serviceTaxAdd;
            this.newAttribute.invoiceNumber = list;
            this.finalResult.push(this.newAttribute);
        }
        console.log(list);
    }
    
    /*setBookingServicePrintSummary() {
        this.lrDto = new LRDto();
        this.lrDto.fortNightStmtNo = this.stmtRptLrForGeneralLrPrintLocalStorage.stmtId;
        this.lrDto.destination = this.stmtRptLrForGeneralLrPrintLocalStorage.destination;
        this.lrDto.fromDate = this.stmtRptLrForGeneralLrPrintLocalStorage.fromPeriod;
        this.lrDto.toDate = this.stmtRptLrForGeneralLrPrintLocalStorage.toPeriod;
        this.lrDto.reportMode = "BookService";
        this.lrDto.mode = "destination";
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.destStmtReport.getBookingServiceTax(this.lrDto).subscribe(
            (response) => {
                this.stmtRptLrDtlsPrintLocalStorage = [];
                if (response.length > 0) {
                    this.stmtRptLrDtlsPrintLocalStorage = response;
                    this.setHashMapValues();
                    window.setTimeout(function () {
                       window.print();
                    }, 0);

                    window.onafterprint = function () {
                        window.close();
                    }
                    localStorage.clear();
                    window.addEventListener('afterprint', (event) => {
                        localStorage.clear();
                    });
                } else {
                    this.stmtRptLrDtlsPrintLocalStorage = [];
                }
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }

    setHashMapValues() {
        console.log(this.stmtRptLrDtlsPrintLocalStorage);
        this.summaryTotalLrs = 0;
        this.summaryTotalActWgt = 0;
        this.summaryTotalAmt = 0;
        this.summaryTotalPaid = 0;
        this.summaryTotalServTax = 0;
        for (let i = 0; i < this.stmtRptLrDtlsPrintLocalStorage.length; i++) {
            this.summaryTotalLrs = +this.summaryTotalLrs + +1;
            this.summaryTotalActWgt = +this.summaryTotalActWgt + +this.stmtRptLrDtlsPrintLocalStorage[i].actualWeight;
            this.summaryTotalAmt = +this.summaryTotalAmt + +this.stmtRptLrDtlsPrintLocalStorage[i].toPay;
            this.summaryTotalPaid = +this.summaryTotalPaid + +this.stmtRptLrDtlsPrintLocalStorage[i].paid;
            this.summaryTotalServTax = +this.summaryTotalServTax + +this.stmtRptLrDtlsPrintLocalStorage[i].servicetax;
        }

        var groups = new Set(this.stmtRptLrDtlsPrintLocalStorage.map(item => item.fromStation).sort());
        this.result = [];
        groups.forEach(g => {
            const totalActWgt = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const totalAmount = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.toPay, 0)
            const paid = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.paid, 0)
            const servicetax = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.servicetax, 0)

            this.result.push({
                fromStation: g,
                values: this.stmtRptLrDtlsPrintLocalStorage.filter(i => i.fromStation === g),
                actualWeight: totalActWgt,
                totalAmount: totalAmount,
                paid: paid,
                servicetax: servicetax,
            })
        })

        this.result1 = [];
        for (let i = 0; i < this.result.length; i++) {
            this.newAttribute = {};
            this.newAttribute.fromStation = this.result[i].fromStation;
            this.newAttribute.actualWeight = this.result[i].actualWeight;
            this.newAttribute.totalAmount = this.result[i].totalAmount;
            this.newAttribute.paid = this.result[i].paid;
            this.newAttribute.servicetax = this.result[i].servicetax;
            var inv_No = null;
            var newInv_no = null;

            for (let j = 0; j < this.result[i].values.length; j++) {
                if (j == 0) {
                    inv_No = this.result[i].values[j].invoiceNumber;
                } else {
                    inv_No += "," + this.result[i].values[j].invoiceNumber;
                }
            }
            this.newAttribute.invoiceNumber = inv_No;
            this.result1.push(this.newAttribute);
        }
        console.log(this.result1);

        var list = [];
        this.finalResult = [];
        for (let i = 0; i < this.result1.length; i++) {
            var invoicNoSplit = this.result1[i].invoiceNumber.split(",");
            list = invoicNoSplit.filter(function (x, i, a) {
                return a.indexOf(x) == i;
            });
            this.newAttribute = {};
            this.newAttribute.fromStation = this.result1[i].fromStation;
            this.newAttribute.actualWeight = this.result1[i].actualWeight;
            this.newAttribute.totalAmount = this.result1[i].totalAmount;
            this.newAttribute.paid = this.result1[i].paid;
            this.newAttribute.servicetax = this.result1[i].servicetax;
            this.newAttribute.invoiceNumber = list;
            this.finalResult.push(this.newAttribute);
        }
        console.log(list);
    }*/

}
