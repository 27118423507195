<!-- Row -->
<div class="row system_responsive" id="{{pageId}}agentTdsLdgDetailsId" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">TDS On Loading Details</h6>
            </div>
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class=" fas fa-road"></i>
													</span>
                                                </div>
                                                <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates" [(ngModel)]="fromDatesModal" autocomplete="off">
                                                <div class="input-group-append" (click)="fromDates.toggle()">
                                                    <span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal" autocomplete="off">
                                                <div class="input-group-append" (click)="toDates.toggle()">
                                                    <span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-dark" (click)="clearBtn();" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>

                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <!-- spinner start-->
                                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <!-- spinner end-->
                                <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintAmountReceivable()" id="{{pageId}}printAllBtn">
									<span><i class="fas fa-print">Print</i></span>
								</button>
                                <div class="box-body">
                                    <table datatable id="{{pageId}}bkgAgentTdsLoadingTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTdsLdg" [dtTrigger]="dtTriggerTdsLdg">
                                        <thead>
                                            <tr>
                                                <th>Agent Name</th>
                                                <th>TDS Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let tdsLdgData of tdsLdgDataList ">
                                                <td>{{ tdsLdgData.agentName }}</td>
                                                <td>{{ tdsLdgData.tdsadd }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>