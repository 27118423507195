import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//import { Subject, Subscription } from 'rxjs';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StockReport } from 'src/app/dataService/stock-report';
import { InvoiceDetailsComponent } from '../../hireslip-and-invoice-report/invoice-details/invoice-details.component';
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { ISubscription } from "rxjs/Subscription";

@Component({
    selector: 'delivery-unloaded-lr-detained',
    templateUrl: './delivery-unloaded-lr-detained.component.html',
    styleUrls: ['./delivery-unloaded-lr-detained.component.css']
})
export class DeliveryUnloadedLrDetainedComponent implements OnInit {


    unloadingReportDetainedDataList: any;

    notshowcolumn = true;
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    closeResult: string;
    // for popup modal ends

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerUnloadingReportDetained: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsUnloadingReportDetained: any;



    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;


    invoiceDto: InvoiceDto = new InvoiceDto();

    rightsForOffice = "Rights For Office";

    showSpinnerForAction = false;

    //invoice_Dto: InvoiceDto = new InvoiceDto();
    lr_Dto: LRDto = new LRDto();
    invoice_DtoLocalStorage: InvoiceDto = new InvoiceDto();
    pageId="dulrd";


    constructor(private stocksUnloadingRpt: ReportService, private modalService: NgbModal,
        private router: Router, private stockReport: StockReport, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.invoice_DtoLocalStorage = JSON.parse(localStorage.getItem('popupDeliveryUnloadedDetained'));
            localStorage.clear();
            if (this.invoice_DtoLocalStorage != null && this.invoice_DtoLocalStorage != undefined) {
                this.unloadingReportLrDetained(this.invoice_DtoLocalStorage);
            }
        }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsUnloadingReportDetained = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    exportOptions: {
                        columns: [0, 3, 10, 2, 11, 12, 13, 14, 15, 16]
                    },
                    messageTop: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                            "<u>Unloading Report</u><br><br>" +
                            "Printed On : " + moment(new Date()).format('DD-MM-YYYY') +
                            "<br><br>" +
                            "</td>" +
                            "</tr></table><br>"
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Unloading Report - " +
                            "Printed On : " + moment(new Date()).format('DD-MM-YYYY') + "";
                        //"From : " + companyFrom + " -  " +
                        //"To  : " + companyTo + "" + "";
                    },
                    /* exportOptions: {
                         columns: [0, 2, 9, 1, 10, 11,12,13,14,15]
                     },*/
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 330,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totalLrs = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(totalLrs);
            },
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            dtElement.dtInstance.then((dtInstance: any) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerUnloadingReportDetained.next();
    }

    ngAfterViewInit(): void {
        //this.dtTriggerUnloadingReportDetained.next();
    }



    unloadingReportLrDetained(invoice_DtoLocalStorage) {
        this.lr_Dto = new LRDto();
        this.lr_Dto.mode = invoice_DtoLocalStorage.mode;
        this.lr_Dto.invoiceNumber = invoice_DtoLocalStorage.invoiceNumber;
        this.lr_Dto.description = invoice_DtoLocalStorage.address;
        this.lr_Dto.commodityCode = invoice_DtoLocalStorage.commodityCode;
        this.lr_Dto.branch = this.userDataDtoReturnSession.mainStation;
        this.lr_Dto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lr_Dto);
        this.showSpinnerForAction = true;
        this.stockReport.getUnloadingReportLrDetailsDetainReport(this.lr_Dto).subscribe(
            (response) => {
                this.unloadingReportDetainedDataList = [];
                $("#"+this.pageId+"stockUnloadingDetainedTableId").DataTable().destroy();
                console.log(response);
                if (response.length > 0) {
                    this.unloadingReportDetainedDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "info");
                }
                this.dtTriggerUnloadingReportDetained.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting  unloading report lr Details", "error");
            },
            () => console.log('done');
    }

}
