import { TruckDataDto } from "./TruckData-dto";

export class InvoiceDto {
    invoiceNumber: string;
    invoiceDate: Date;
    totalLRs: number;
    fromStation: string;
    toStation: string;
    vehicleNumber: string;
    loadedby: string;
    remarks: string;
    enteredby: string;
    lastupdatedby: string;
    //    List<String> list;
    userName: string;
    financialYear: string;
    totalArticles: number;
    status: string;
    enteredDate: Date;
    stationType: string;
    //    List<String> listLrsRem;
    entrydate: Date;
    lastupdateddate: Date;
    driverLicenseNo: string;
    column1: string;
    column2: string;
    column3: string;
    departuredate: Date;
    scheduledDate: Date;
    arrivalDate: Date;
    departureTime: string;
    hireSlipNumber: string;
    driverName: string;
    stocksAt: string;
    branch: string;
    mode: string;
    actWeight: number;
    kantaWeight: number;
    gurWeight: number;
    size: number;
    //    List<String> listLrsShortage;
    //    List<String> listTotalLrs;
    article: number;
    chargedwt: number;
    topay: number;
    paid: number;
    destination: string;
    fromdate: string;
    todate: string;
    mainStation: string;
    officeType: string;
    deliveryPoints: string;
    noOfDelvPnts: number;
    //    Set<String> setList;
    entryCP: string;
    exitCP: string;
    expExitDate: Date;
    expExitDays: number;
    supplierName: string;
    ownerName: string;
    panNo: string;
    contactPerson: string;
    address: string;
    mobileNum: string;
    suppAddress: string;
    suppPhNo: string;
    driverLicState: string;
    driverLicStateCode: string;
    driverAdd: string;
    driverPinCode: string;
    vehiclePinCode: string;
    vehOwnerState: string;
    vehOwnerStateCode: string;
    entryCPCode: string;
    exitCPCode: string;
    bookingDate: Date;
    lrNumber: string;
    consigneeName: string;
    consigneeTin: string;
    consigneeState: string;
    consigneeStateCode: string;
    consigneeAdd: string;
    consignorName: string;
    consignorTin: string;
    consignorState: string;
    consignorStateCode: string;
    consignorAdd: string;
    commodityName: string;
    commodityDesc: string;
    commodityCode: string;
    goodsQuantity: number;
    goodsValue: number;
    supplierpanno: string;
    stockDate: Date;
    driverId: number;
    invDate: string;
    daysDiff: number;
    loaderHead: string;
    consigneeNo: number;
    consignee: string;
    shortGoods: string;
    excessGoods: string;
    openConditionGoods: string;
    hireslipdate: Date;
    arrivalTime: string;
    toDateInDate: Date;
    reportMode: string;
    consigneeId: number;
    sendEmail: boolean;
    //    HashMap<String, String> hashMap;
    officeNum: string;
    deliveryType: string;
    //    HashMap<String, List<String>> hashSLmap;
    remarkShtGoods: string;
    remarkExtGoods: string;
    remarkOpnGoods: string;
    multipleSource: string;
    multipleHamali: string;
    isAll: boolean;
    delivDateOnArrival: boolean;
    isUnldgDetailsFilled: boolean;
    belongsTo: string;
    driverRewardAmt: number;
    driverRewardHAmt: number;
    scanDate: Date;
    scanTime: string;
    timeTaken: string;
    companyId: string;
    //list: string[];
    //added at 20-02-2021
    listLrsRem: Array<String>;
    listLrsShortage: Array<String>;
    listTotalLrs: Array<String>;
    list: Array<String>;
    truckDataDto: TruckDataDto = new TruckDataDto();

    //10-03-2021 dashboard pinding hireslip gen
    loadingBayNo: string;
    loadingBayDate: Date;
    loadingBayTime: string;
    // bash bh
    isAocUploadOnStockIn: boolean;
    blockArrvNotification: boolean;
    inchargeName: string;
    balPaymentStatus: string;
    hireamtbalbankacproofcopy: string;
    acType: string;
    acNumber: string;
    bankName: string;
    acHolder: string;
    ifscCode: string;
    branchName: string;
    isGSTDetained:boolean;
}