import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { LRDto } from "src/app/dto/LR-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component( {
    selector: 'app-halt-and-dispatched-details',
    templateUrl: './halt-and-dispatched-details.component.html',
    styleUrls: ['./halt-and-dispatched-details.component.css']
} )
export class HaltAndDispatchedDetailsComponent implements OnInit {

    gettingDataFrmServiceFrHaltAndDispatchedDetailsTable: any;
    haltAndDispatchedDetailsDataList: any;
    onDestroyUnsubscribtionHaltAndDispatchedDetails: Subscription;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerHaltAndDispatchedDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsHaltAndDispatchedDetails: any;
    
    isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	hireSlipDtoSearch: HireSlipDto = new HireSlipDto();
	fromStation : any;
   	toStation : any;
    fromDateInDate : any;
	haltCount = 0;
	dispCount =0;
	dispPerc:any;
	haltPerc:any;
	totDispatchedPerc:any;
	totHaltPerc:any;

    lrDtoSourceOptions: LRDto[];
	lrDtoSourceAllOption: LRDto = new LRDto();
    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
  
   
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                :  this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
    
    destinationOptions: LRDto[];
    lrDtodestinationAllOption: LRDto = new LRDto();
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    pageId="haddt";

    constructor( private hireslipService: HireslipService,private masterReadService: MasterReadService, private router: Router, public changeDetectorRef : ChangeDetectorRef ) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
          	  this.getSourceDetails();
          	  this.getDestinationDetails();
            }, 500);
        }

    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }

    ngOnInit(): void {
        this.dtOptionsHaltAndDispatchedDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,            
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerHaltAndDispatchedDetails.unsubscribe();
    }
    
    ngAfterViewInit(): void {
        this.dtTriggerHaltAndDispatchedDetails.next();
    }
    
    getSourceDetailedList() {
			this.lrDtoSource.mode = 'Booking';
      		this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
      }

	  getSourceDetails() {
          this.getSourceDetailedList();
          this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource ).subscribe(
              ( response ) => {
                  if (response) {
                      if (response.length > 0) {
                      	  this.lrDtoSourceOptions = response;
                          this.sourceTA = [];
                          this.lrDtoSourceAllOption.subStations = "All";
                          this.sourceTA.push(this.lrDtoSourceAllOption);
                          for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
                              this.sourceTA.push( this.lrDtoSourceOptions[i] );
                          }
                      }
                  }
                  this.changeDetectorRef.detectChanges();
                }),(error) => {
            			this.showSpinnerForAction = false;
            		   swal("Error","Server Problem Occurred While getting the Source Details","info");
            		},
            		   () => console.log('done');
      }
	  
	    getDestinationMethod() {
	        this.lrDtoDestination = new LRDto();
	        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
	        this.lrDtoDestination.mode = "destinationOnly";
	    }
	    getDestinationDetails() {
	        this.showSpinnerForAction = true;
	        this.getDestinationMethod();
	        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
	            (response) => {
	                if (response.length > 0) {
	                    this.destinationOptions = response;
	                    this.destinationTA = [];
	                    this.lrDtodestinationAllOption.destination = "All";
                        this.destinationTA.push(this.lrDtodestinationAllOption);
	                    for (let i = 0; i < this.destinationOptions.length; i++) {
	                        this.destinationTA.push(this.destinationOptions[i]);
	                    }
	                    this.showSpinnerForAction = false;
	                } else {
	                    this.showSpinnerForAction = false;
	                }
                    this.changeDetectorRef.detectChanges();
	            }), (error) => {
	                this.showSpinnerForAction = false;
	                swal("Server Error", "While Getting destination details", "warning");
	            },
	            () => console.log('done');
	    }
	    
	    getUserValue() {
	        this.hireSlipDtoSearch = new HireSlipDto();
	        this.fromStation = $("#"+this.pageId+"source").val();
	        this.toStation = $("#"+this.pageId+"destinationId").val();
	        this.fromDateInDate = $("#"+this.pageId+"date").val();
	        
	        this.hireSlipDtoSearch.fromStation = this.fromStation;
	        this.hireSlipDtoSearch.toStation = this.toStation;
	        this.hireSlipDtoSearch.fromDateInDate = this.fromDateInDate;
	    }

	    getReportDetails() {
	    	this.getUserValue();
	        this.showSpinnerForAction = true;
	        this.hireslipService.getTruckHaltDispatchedDetails(this.hireSlipDtoSearch).subscribe(
	            (response) => {
	                this.haltAndDispatchedDetailsDataList = [];
	                $("#"+this.pageId+"haltAndDispRtTableId").DataTable().destroy();
	                if (response.length > 0) {
	                    this.haltAndDispatchedDetailsDataList = response;
	                } else {
	                    swal("Alert", "No Record Found", "warning");
	                }
	                this.dtTriggerHaltAndDispatchedDetails.next();
	                this.showSpinnerForAction = false;
	                this.calculateHaltDispPerc();
                    this.changeDetectorRef.detectChanges();
	            }), (error) => {
	                this.showSpinnerForAction = false;
	                swal("Error", "Server problem occurred while getting the details", "info");
	            }, () => console.log('done');
	    }
	  
	  logInPage() {
	        this.router.navigate(['/authentication/login']);
	    }
	  
	  clearFields() {
		  this.fromStation =null;
	      this.toStation = null;
	      this.fromDateInDate = null;
	      this.haltAndDispatchedDetailsDataList = [];
          $("#"+this.pageId+"haltAndDispRtTableId").DataTable().destroy();
          this.dtTriggerHaltAndDispatchedDetails.next();
	  }
	  
	  calculateHaltDispPerc(){
		 	this.haltCount = 0;
		 	this.dispCount =0;
		 	this.dispPerc=0;
		 	this.haltPerc=0;
		 	this.totDispatchedPerc=0;
		 	this.totHaltPerc=0;
		  for (let i = 0; i < this.haltAndDispatchedDetailsDataList.length; i++) {
			if (this.haltAndDispatchedDetailsDataList[i].remarks == "Dispatched") {
				this.dispCount++;
			} else if (this.haltAndDispatchedDetailsDataList[i].remarks== "Halt") {
				this.haltCount++;
			}
		}
	  if (this.dispCount > 0) {
			this.dispPerc = ((this.dispCount * 100) / this.haltAndDispatchedDetailsDataList.length);
			this.totDispatchedPerc = this.dispPerc+ "%";
		} else {
			this.totDispatchedPerc = "0%";
		}
		if (this.haltCount > 0) {
			this.haltPerc = ((this.haltCount * 100) / this.haltAndDispatchedDetailsDataList.length);
			this.totHaltPerc=this.haltPerc+ "%";
		} else {
			this.totHaltPerc = "0%"
		}
	  }
}
