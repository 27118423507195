import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-tds-loading',
    templateUrl: './tds-loading.component.html',
    styleUrls: ['./tds-loading.component.css']
})
export class TdsLoadingComponent implements OnInit {

    loadingIndicator = true;
    model: NgbDateStruct;
    date: { year: number; month: number };

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    getDataFrmServiceFrAmountReceivableTable: any;
    tdsLdgDataList: any;
    onDestroyUnsubscribtionAmountReceivable: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTdsLdg: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsTdsLdg: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    srcSupAdminTds = false;

    lrDtoSourceOptions: LRDto[];
	lrDtoSourceAllOption: LRDto = new LRDto();
    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
  
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                :  this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    fromDatesModal: any;
    toDatesModal: any;
    validateFromDate: any;
    validateToDate: any;
    validateSource: any;

    showSpinnerForAction = false;
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();

    //For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	totalTdsAmtPrint:number;
	pageId="tdslc";

    constructor(private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
         private destStmtReport: DestStmtReport, private datePipe: DatePipe,public dialog: MatDialog,private route: ActivatedRoute, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
          	  for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "BkgAgtTDSLdgHamRpt ShowAll" ) {
                        	this.srcSupAdminTds = true;
                      	break;
                    }
                }
            }
            this.getSourceDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsTdsLdg = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                            return " TDS On Loading Details Report- " +
                                "From Date : " + moment($("#tdslcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#tdslctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#tdslcsource").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                
                var totAmt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                
                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totAmt);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerTdsLdg.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerTdsLdg.unsubscribe();
    }

    getSourceDetailedList() {
		  if(this.srcSupAdminTds == true){
			this.lrDtoSource.mode = 'Booking';
		  }else{
			  this.lrDtoSource.city = this.userDataDtoReturnSession.mainAdminStation;
		  }
    		this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
    }

	  getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource ).subscribe(
            ( response ) => {
                if (response) {
                    if (response.length > 0) {
                    	  this.lrDtoSourceOptions = response;
                        this.sourceTA = [];
                        this.lrDtoSourceAllOption.subStations = "All";
                        this.sourceTA.push(this.lrDtoSourceAllOption);
                        for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
                            this.sourceTA.push( this.lrDtoSourceOptions[i] );
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
              }),(error) => {
          			this.showSpinnerForAction = false;
          		   swal("Error","Server Problem Occurred While getting the Source Details","info");
          		},
          		   () => console.log('done');
    }

    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.validateSource = null;
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"source").val('');
        this.validateFromDate = null;
        this.validateToDate = null;
        $("#"+this.pageId+"bkgAgentTdsLoadingTableId").DataTable().destroy();
        this.dtTriggerTdsLdg.next();
        this.tdsLdgDataList = [];
    }

    clearBtn() {
        this.clearField();
    }

    validateSearchBtn() {
            if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                ($("#"+this.pageId+"source").val() == null) || ($("#"+this.pageId+"source").val() == undefined) ||
                ($("#"+this.pageId+"source").val() == "")) {
                swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                return false;
            } else {
                this.getTdsLoadingReport();
            }
    }

    getUserValues() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateSource = $("#"+this.pageId+"source").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.agentName = this.validateSource;
        if(this.srcSupAdminTds == false){
        this.fortNightStmtDto.mainBranch = this.userDataDtoReturnSession.mainAdminStation;
        }
    }
    getTdsLoadingReport() {
        this.getUserValues();
        this.showSpinnerForAction = true;
        this.destStmtReport.getBkgAgtTDSLdgDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                this.tdsLdgDataList = [];
                $("#"+this.pageId+"bkgAgentTdsLoadingTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.tdsLdgDataList = response;
                    this.dtTriggerTdsLdg.next();
                } else {
                    swal("TDS Loading Report", "No datas found for this information!", "info");
                }
                
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            },
            () => console.log('done');
    }


    customPrintAmountReceivable() {
		if (this.tdsLdgDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name","TDS Amount"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [50,50];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//heading logics For Date
			this.validateFromDate=this.datePipe.transform($("#"+this.pageId+"fromDates").val(), "dd-MM-yyyy");
			this.validateToDate=this.datePipe.transform($("#"+this.pageId+"toDates").val(), "dd-MM-yyyy");
            //this.validateFromDate = $("#"+this.pageId+"fromDates").val();
            //this.validateToDate = $("#"+this.pageId+"toDates").val();
			
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [ this.validateFromDate,this.validateToDate ];

			///Heaading Logics For Party Name
            this.validateSource = $("#"+this.pageId+"source").val();
		
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [ this.validateSource ];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totalTdsAmtPrint=0;
			
			for (let i = 0; i < this.tdsLdgDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.tdsLdgDataList[i].agentName,this.tdsLdgDataList[i].tdsadd];
				
				this.totalTdsAmtPrint=this.totalTdsAmtPrint+this.tdsLdgDataList[i].tdsadd;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.tdsLdgDataList.length,this.totalTdsAmtPrint];
			
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Agent TDS On Loading Details Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}	
}
