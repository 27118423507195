<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 10px;">
		M-Less(%)
		<div mat-dialog-content>
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please wait updating the Less details.....</h6>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="box-body">

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Total To-pay</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" class="form-control" id="{{pageId}}toPay"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>M-Less Amount</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input (keyup)="mLessEvent($event)" (blur)="mLessBlur($event)"
													type="number" class="form-control" id="{{pageId}}mLessAmt"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>M-Less(%)</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" class="form-control" id="{{pageId}}mPerc"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<hr style="width: 80%; border-top: none; margin: 3px;">
				<div class="col-md-12" style="text-align: center;">
					<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
						(click)="confirmSave();">Save</button>
					<button type="submit" class="btn btn-dark" (click)="onCancelClick();"
						id="{{pageId}}clearBtn">Cancel</button>
				</div>
			</div>
		</div>
		<!-- 		<div mat-dialog-actions style="float: right;"> -->

		<!-- 			<button class="btn btn-outline-success" mat-button -->
		<!-- 				[mat-dialog-close]="true" cdkFocusInitial>Close</button> -->

		<!-- 		</div> -->