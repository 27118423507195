//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends

@Component( {
    selector: 'app-booking-cashmemo',
    templateUrl: './booking-cashmemo.component.html',
    styleUrls: ['./booking-cashmemo.component.css']
} )
export class BookingCashmemoComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    bkgCashMemoReportDataList: any;
    onDestroyUnsubscribtionBkgCashMemoReport: Subscription;


    controlMain = new FormControl();

    cityOptions = [
        { id: 1, label: 'ANDHERI' },
        { id: 2, label: 'BHIWANDI' },
        { id: 3, label: 'CHINCH BANDER' },
        { id: 4, label: 'DAMAN' }
    ];




    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBkgCashMemoReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsBkgCashMemoReport: any;


pageId="bkgcm";
    constructor( private bkgCashMemoReport: MasterService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    ngOnInit(): void {


        this.dtOptionsBkgCashMemoReport = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.bkgCashMemoReport.getSummaryData()
        this.onDestroyUnsubscribtionBkgCashMemoReport = this.getDataFrmServiceFrTable.subscribe( data => {
            this.bkgCashMemoReportDataList = data['data'];
            this.dtTriggerBkgCashMemoReport.next();
        } );


    }

    ngOnDestroy(): void {
        this.dtTriggerBkgCashMemoReport.unsubscribe();

        this.onDestroyUnsubscribtionBkgCashMemoReport.unsubscribe();
    }




    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

}