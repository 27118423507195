<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">LR Enquiry Advance</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="consigneeDestListener($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}partyName">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="rowSelectedConsignee($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                            [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input id="{{pageId}}fromDate" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 100%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>

                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <!-- column -->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 vl">
                            <div class="col-md-12">
                                <h6 class="card-title border_bottom">LR Details</h6>
                                <div class="box-body">
                                    <table datatable id="{{pageId}}lrAdvTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrAdvance" [dtTrigger]="dtTriggerLrAdvance">

                                        <thead>
                                            <tr>
                                                <th>LR No.</th>
                                                <th>Consignor</th>
                                                <th>Invoice Number</th>
                                                <th>Agent Name</th>
                                                <th>Articles</th>
                                                <th>Goods Type</th>
                                                <th>Status</th>
                                                <th>Act.Wgt</th>
                                                <th>Chg.Wgt</th>
                                                <th>B.Freight</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Goods Value</th>
                                                <th>F O V</th>
                                                <th>Memo Amt</th>
                                                <th>Memo Date</th>
                                                <th>Booking Date</th>
                                                <th>Entry Date</th>
                                                <th>Dep.Dt Frm 1st Pt.</th>
                                                <th>Arr.Dt Frm 1st Pt.</th>
                                                <th>Dep.Dt Frm 2nd Pt.</th>
                                                <th>Arr.Dt Frm 2nd Pt.</th>
                                                <th>Dep.Dt Frm 3rd Pt.</th>
                                                <th>Arr.Dt Frm 3rd Pt.</th>
                                                <th>Delivery Date</th>
                                                <th>Tot No.Of Days</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let lrAdvanceData of lrAdvanceDataList ">
                                                <td><a class="hyperLink_td" (click)="lrDetialsMethod(lrAdvanceData.lrNumber)"><u>{{
															lrAdvanceData.lrNumber }}</u></a></td>
                                                <td>{{ lrAdvanceData.consignorName}}</td>
                                                <td>{{ lrAdvanceData.invoiceNumber }}</td>
                                                <td>{{ lrAdvanceData.agentName }}</td>
                                                <td>{{ lrAdvanceData.totalArticles}}</td>
                                                <td>{{ lrAdvanceData.goodsType}}</td>
                                                <td>{{ lrAdvanceData.status}}</td>
                                                <td>{{ lrAdvanceData.actualWeight }}</td>
                                                <td>{{ lrAdvanceData.chargedWeight }}</td>
                                                <td>{{ lrAdvanceData.baseFreight }}</td>
                                                <td>{{ lrAdvanceData.toPay }}</td>
                                                <td>{{ lrAdvanceData.paid }}</td>
                                                <td>{{ lrAdvanceData.goodsValue }}</td>
                                                <td>{{ lrAdvanceData.riskCharge }}</td>
                                                <td>{{ lrAdvanceData.cashStxAmount }}</td>
                                                <td>{{ lrAdvanceData.memoDateStr }}</td>
                                                <td>{{ lrAdvanceData.bookingDateStr}}</td>
                                                <td>{{ lrAdvanceData.enteredDateStr }}</td>
                                                <td>{{ lrAdvanceData.departuredateStr }}</td>
                                                <td>{{ lrAdvanceData.arrivalDateStr}}</td>
                                                <td>{{ lrAdvanceData.depDateFrmTrnspDateStr }}</td>
                                                <td>{{ lrAdvanceData.arrDateAtTrnspDateStr }}</td>
                                                <td>{{ lrAdvanceData.depDateFrmTrnspDate2Str }}</td>
                                                <td>{{ lrAdvanceData.arrDateAtTrnspDate2Str }}</td>
                                                <td>{{ lrAdvanceData.deliveryDateStr }}</td>
                                                <td>{{ lrAdvanceData.diffDays }}</td>

                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>