<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Tracking Report -
						Consignment Live Details</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12 p-t-10">
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<table datatable id="{{pageId}}trackingRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsTrackingLiveReport"
								[dtTrigger]="dtTriggerTrackingLiveReport">

								<thead>
									<tr>
										<th>Trip Id</th>
										<th>Dep Date</th>
										<th>Invoice No</th>
										<th>Source</th>
										<th>Destination</th>
										<th>Vehicle</th>
										<th>Driver</th>
										<th>Driver No</th>
										<th>Supplier Name</th>
										<th>Last Location</th>
										<th>Last Updated</th>
										<th>ETA</th>
										<th>Track</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let trackingLiveReportData of trackingLiveReportDataList ">
										<td>{{ trackingLiveReportData.id }}</td>
										<td>{{ trackingLiveReportData.departuredateStr }}</td>
										<td>{{ trackingLiveReportData.invoiceNumber }}</td>
										<td>{{ trackingLiveReportData.source }}</td>
										<td>{{ trackingLiveReportData.destination }}</td>
										<td>{{ trackingLiveReportData.vehicleNumber }}</td>
										<td>{{ trackingLiveReportData.driverName }}</td>
										<td>{{ trackingLiveReportData.driverContactNum }}</td>
										<td>{{ trackingLiveReportData.supplierName }}</td>
										<td>{{ trackingLiveReportData.location }}</td>
										<td>{{ trackingLiveReportData.lastUpdatedDateStr }}</td>
										<td>{{ trackingLiveReportData.ftEtaStr }}</td>
										<td><button
												style="padding: 1px 4px; background-color: #ffffff00;"
												class="btn m-r-10" id="{{pageId}}trackBtn"
												(click)="trackInvoice(trackingLiveReportData);">
												<i title="Track" class="fas fa-edit"></i>
											</button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>