import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//for datepicker starts

const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for service starts
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { ReportService } from 'src/app/dataService/report-service';
import { HttpClient } from '@angular/common/http';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
//for service ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { MasterReadService } from "src/app/dataService/masterread-service";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserDataDto } from 'src/app/dto/UserData-dto';


@Component({
    selector: 'app-expenses-payment-followup-report',
    templateUrl: './expenses-payment-followup-report.component.html',
    styleUrls: ['./expenses-payment-followup-report.component.css']
})
export class ExpensesPaymentFollowupReportComponent implements OnInit {

    gettingDataFrmServiceFrExpensesPaymentFollowupReportTable: any;


    expensesPaymentFollowupReportDataList: any;


    onDestroyUnsubscribtionExpensesPaymentFollowupReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //for the select option with filter starts

    control = new FormControl();

    //// Vendor
    lrDtoVendor: LRDto = new LRDto();
    lrDtoVendorAddNew: LRDto = new LRDto();
    lrDtosVendorOptionsGet: LRDto[];
    vendorOptions: LRDto[];
    public modelVendor: any;
    vendorNameTA: Array<LRDto> = [];
    focusVendorTA$ = new Subject<string>();
    searchVendor = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVendorTA$;


        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vendorNameTA
                : this.vendorNameTA.filter(v => v.vendorNameAndItemName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVendor = (x: { vendorNameAndItemName: string }) => x.vendorNameAndItemName;



    //for the select option with filter ends



    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerExpensesPaymentFollowupReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsExpensesPaymentFollowupReport: any;
    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    selectedVendorName: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedType: any;
    lrDtoForSearch: LRDto = new LRDto();
    modalRefferencePaymentEntryPopUp: NgbModalRef;
    modalRefferencePaymentEntryClosePopUp: string;
    @ViewChild('paymentEntryPopUpTemplate') private paymentEntryPopUpTemplate;
    showSpinnerForActionForUpload = false;
    popupBillNumber: any;
    popupVendorName: any;
    showBankName = false;
    showRefNumber = false;
    showChqNumber = false;
    pageId="expfp";
    ////
    popupTotalBillAmt: any;
    selectedPaymentMode: any;
    popupAmtPaid: any;
    popupPaymentDate: any;
    setPaymentDate: any;
    popupBalanceAmt: any;
    popupChqNumber: any;
    popupRefNumber: any;
    popupBankName: any;
    selectedPaymentDate: any;
    ///
    userDataDto: UserDataDto = new UserDataDto();
    // bankNameOptions: UserDataDto[];
    public modelBankName: any;
    bankNameTA: Array<any> = [];
    focusBankNameTA$ = new Subject<string>();
    searchBankName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    bankNameOptions = [
        { id: 1, label: 'SBI' },
        { id: 2, label: 'HDFC' },
        { id: 3, label: 'ICICI' },
        { id: 4, label: 'AXIS' },
        { id: 5, label: 'BOI' },
        { id: 6, label: 'PNB' }

    ];
    lrDtoForConfirmPayment: LRDto = new LRDto();
    expensesId = 0;
    invoiceAmt = 0.0;
    btnPayEntryView = false;
    setDataVal: LRDto = new LRDto();
	
	
	grandBillAmt = 0;
	grandPaidAmt = 0;
	grandDueAmt = 0;
  //For Custom Print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;

  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  viewCustomPrintV1 = false;

localStorageOfExpensesIDFormDB:any;
setDBId=0;

    constructor(private reportService: ReportService, private modalService: NgbModal, private masterService: MasterService, private lrReportService: LrReportService, private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.gettingVendorList();
            this.selectTodayDate();
          //  this.getBankDetailsList();
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ExpPaymentEntry") {
                        this.btnPayEntryView = true;
                    }

                }
            }
			
			
			  this.localStorageOfExpensesIDFormDB = JSON.parse(localStorage.getItem('expensesOsPaymentFollowupDB'));
			   if (this.localStorageOfExpensesIDFormDB != null &&
                this.localStorageOfExpensesIDFormDB != undefined &&
                this.localStorageOfExpensesIDFormDB != '') {
                    console.log(this.localStorageOfExpensesIDFormDB);
                this.setDBId=this.localStorageOfExpensesIDFormDB;
				 this.searchMethodForExpensesTable("DBDetail", this.setDBId);
            }

        }

    }

    //for service ends
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#"+this.pageId+"fromDate").val(this.setTodayDateOnFromDate);
        $("#"+this.pageId+"toDate").val(this.setTodayDateOnToDate);
    }
    ngOnInit(): void {
        var showRow = this.btnPayEntryView;
        this.dtOptionsExpensesPaymentFollowupReport = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5]
                },
                title: function () {
                    var returExtendHistroyDate = null;
                    returExtendHistroyDate = "Expenses Payment Entries Details  " +
                        " From Date : " + moment($("#expfpfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#expfptoDate").val()).format('DD-MM-YYYY');
                    return returExtendHistroyDate;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                if (showRow == true) {
                    var billAmt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var due = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html();
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html(billAmt);
                    $(api.column(4).footer()).html(paid);
                    $(api.column(5).footer()).html(due);
                    $(api.column(6).footer()).html();
                } else {
                    var billAmt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var due = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html();
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html(billAmt);
                    $(api.column(4).footer()).html(paid);
                    $(api.column(5).footer()).html(due);

                }




            }
        }


    }


    ngOnDestroy(): void {
        this.dtTriggerExpensesPaymentFollowupReport.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerExpensesPaymentFollowupReport.next();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends

    //for the select option with filter ends
    gettingVendorDetails() {
        this.lrDtoVendor = new LRDto();
        this.lrDtoVendor.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoVendor.branch = this.userDataDtoReturnSession.mainStation;
        console.log(this.lrDtoVendor);
    }
    gettingVendorList = () => {
        this.showSpinnerForAction = true;
        this.gettingVendorDetails();
        this.masterService.getExpensesVendorDetails(this.lrDtoVendor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Vendor records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.vendorNameTA = [];
                    this.lrDtosVendorOptionsGet = [];
                    this.lrDtosVendorOptionsGet = response;

                    for (let i = 0; i < this.lrDtosVendorOptionsGet.length; i++) {
                        this.vendorNameTA.push(this.lrDtosVendorOptionsGet[i]);

                    }
                    console.log(this.vendorNameTA);

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Vendor Details", "warning");
            },
            () => console.log('done');
    };
    searchMethod() {
        this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
        this.selectedToDate = $("#"+this.pageId+"toDate").val();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "Please select From Date and To Date to get the details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            this.searchMethodForExpensesTable("DateWise", 0);
        }
    }

    searchMethodForExpensesTable(mode, id) {
        this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
        this.selectedToDate = $("#"+this.pageId+"toDate").val();
        this.selectedVendorName = $("#"+this.pageId+"vendorName").val();
        this.selectedType = $("#"+this.pageId+"type").val();
        this.lrDtoForSearch = new LRDto();
        if (mode != null && mode == "DateWise") {
            this.lrDtoForSearch.supplierName = this.selectedVendorName;
            this.lrDtoForSearch.mode = this.selectedType;
            this.lrDtoForSearch.fromDate = this.selectedFromDate;
            this.lrDtoForSearch.toDate = this.selectedToDate;
        }
        this.lrDtoForSearch.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoForSearch.reportMode = mode;
        this.lrDtoForSearch.id = id;
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForSearch);
        this.searchMethodForExpensesTableDetails();

    }

    searchMethodForExpensesTableDetails = () => {
        this.showSpinnerForAction = true;
        this.masterService.getExpensesDetailsReport(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"expensesPaymentFollowupReportId").DataTable().destroy();
                this.expensesPaymentFollowupReportDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.expensesPaymentFollowupReportDataList = response;
                }
                this.dtTriggerExpensesPaymentFollowupReport.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Expenses Report ", "info");
            }, () => console.log('done');
    };

    paymentEntryBtn(expensesPaymentFollowupReportData, paymentEntryPopUpTemplate) {
        this.popupBillNumber = expensesPaymentFollowupReportData.billNumber;
        this.setDataVal = new LRDto();
        this.setDataVal = expensesPaymentFollowupReportData;
        if (this.popupBillNumber != null) {
            this.expensesId = expensesPaymentFollowupReportData.id;
            this.invoiceAmt = expensesPaymentFollowupReportData.grandTotal == null ? 0.0 : expensesPaymentFollowupReportData.grandTotal;
            if (this.invoiceAmt > 0.0 || this.invoiceAmt > 0) {


                this.popupVendorName = expensesPaymentFollowupReportData.supplierName;
                this.setFieldsValues();
                this.modalRefferencePaymentEntryPopUp = this.modalService.open(paymentEntryPopUpTemplate,
                    { centered: true, windowClass: "myCustomModalClass" });
                this.modalRefferencePaymentEntryPopUp.result.then((result) => {
                    this.modalRefferencePaymentEntryClosePopUp = `Closed with: ${result}`;
                }, reason => {
                    this.modalRefferencePaymentEntryClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else {
                swal({
                    title: "Not Allowed",
                    text: "Total Bill Amount is Zero ! can not make payment entry, please recheck.",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            }

        } else {
            swal({
                title: "Empty Selection ",
                text: "Please select Bill for Payment Entry",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    closePaymentEntryPopUp() {
        this.modalRefferencePaymentEntryPopUp.close();
        this.popupBillNumber = '';
        this.popupVendorName = '';

    }


    searchByMode(paymentMode: string) {
        if (paymentMode === 'Cash') {
            this.showBankName = false;
            this.showRefNumber = false;
            this.showChqNumber = false;
            $("#"+this.pageId+"bankName").val('');
            $("#"+this.pageId+"referenceNumber").val('');
            $("#"+this.pageId+"chequeNumber").val('');
            

        } else if (paymentMode === 'Cheque') {
            this.showBankName = true;
            this.showRefNumber = false;
            this.showChqNumber = true;
            $("#"+this.pageId+"bankName").val('');
            $("#"+this.pageId+"referenceNumber").val('');
            $("#"+this.pageId+"chequeNumber").val('');
           // this.getBankDetailsList(); 
        } else if (paymentMode === 'NEFT') {
            this.showBankName = true;
            this.showRefNumber = true;
            this.showChqNumber = false;
            $("#"+this.pageId+"bankName").val('');
            $("#"+this.pageId+"referenceNumber").val('');
            $("#"+this.pageId+"chequeNumber").val('');
           // this.getBankDetailsList(); 
        }
    }

    // getBankDetailsList() {
    //     for (let i = 0; i < this.bankNameOptions.length; i++) {
    //         this.bankNameTA.push(this.bankNameOptions[i].label);
    //     }
    // }

    validateConfirmBtn() {
        this.selectedPaymentMode = $("#"+this.pageId+"paymentMode").val();
        this.popupChqNumber = $("#"+this.pageId+"chequeNumber").val();
        this.popupRefNumber = $("#"+this.pageId+"referenceNumber").val();
        this.popupBankName = $("#"+this.pageId+"bankName").val();
        this.selectedPaymentDate = $("#"+this.pageId+"paymentDate").val();

        if (this.selectedPaymentDate != null && this.selectedPaymentDate != "") {
            if (this.selectedPaymentMode != "" && this.selectedPaymentMode != null && this.selectedPaymentMode == "Cheque") {

                if (this.popupChqNumber == "" || this.popupChqNumber == null || this.popupBankName == "" || this.popupBankName == null) {
                    swal({
                        title: "Mandetory",
                        text: "Please select the Bank name and enter the cheque number",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {

                    this.getDetails();
                }
            } else if (this.selectedPaymentMode != "" && this.selectedPaymentMode != null && this.selectedPaymentMode == "NEFT") {

                if (this.popupRefNumber == "" || this.popupRefNumber == null || this.popupBankName == "" || this.popupBankName == null) {
                    swal({
                        title: "Mandetory",
                        text: "Please select the Bank name and enter the reference number",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.getDetails();

                }

            } else if (this.selectedPaymentMode != "" && this.selectedPaymentMode != null && this.selectedPaymentMode == "Cash") {
                this.getDetails();
            }

        } else {
            swal({
                title: "Mandetory",
                text: "Please select the Paymente Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }


    }
    getDetails() {

        swal({
            title: "Confirm Payment",
            text: "Sure You want to Add/Update the Payment Entries",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((set) => {
            if (set) {
                this.getInvoiceUserValues();
            }
        });

    }
    getInvoiceUserValues() {
        this.popupTotalBillAmt = $("#"+this.pageId+"totalBillAmt").val();
        this.popupAmtPaid = $("#"+this.pageId+"amountPaid").val();
        this.selectedPaymentMode = $("#"+this.pageId+"paymentMode").val();
        this.popupChqNumber = $("#"+this.pageId+"chequeNumber").val();
        this.popupRefNumber = $("#"+this.pageId+"referenceNumber").val();
        this.popupBankName = $("#"+this.pageId+"bankName").val();
        this.selectedPaymentDate = $("#"+this.pageId+"paymentDate").val();

        let billAmt = this.popupTotalBillAmt == null ? 0.0 : this.popupTotalBillAmt;
        let amtPaid = this.popupAmtPaid == null ? 0.0 : this.popupAmtPaid;

        if (billAmt < amtPaid) {

            swal({
                title: "Not Allowed",
                text: "Total Amount Paid is greater than Bill Amount !, Please recheck. ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });


        } else {
            this.lrDtoForConfirmPayment = new LRDto();
            this.lrDtoForConfirmPayment.id = this.expensesId;
            this.lrDtoForConfirmPayment.paymentMode = this.selectedPaymentMode;
            this.lrDtoForConfirmPayment.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoForConfirmPayment.totalAmount = amtPaid;
            this.lrDtoForConfirmPayment.dateInDate = this.selectedPaymentDate;
            this.lrDtoForConfirmPayment.chequeNumber = this.popupChqNumber;
            if(this.popupBankName !="selectBank" && this.popupBankName !=null && this.popupBankName !="" && this.popupBankName !=undefined){
                this.lrDtoForConfirmPayment.bankName = this.popupBankName;
            }
            this.lrDtoForConfirmPayment.remarks = this.popupRefNumber;
            this.lrDtoForConfirmPayment.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.lrDtoForConfirmPayment);
            this.updateExpensesDetails();
        }
    }

    updateExpensesDetails = () => {
        this.showSpinnerForActionForUpload = true;
        this.masterService.updateExpensesPaymentDetails(this.lrDtoForConfirmPayment).subscribe(
            (response) => {
                this.showSpinnerForActionForUpload = false;
                console.log(response);
                if (response.status == "success") {
                    swal({
                        title: "Payment Success",
                        text: "Bill Payment Entries done successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearPopupFields();
                    this.searchMethodForExpensesTable("DateWise", 0);
                } else {

                    swal({
                        title: "Payment Failed",
                        text: "Failed to Add/Update Bill Payment Entries",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForActionForUpload = false;
                swal("Error", "Server Problem Occurred While Adding/Updating the Payment Entries details", "info");
            }, () => console.log('done');
    };
    clearPopupFields() {
        this.lrDtoForConfirmPayment = new LRDto();
        this.popupTotalBillAmt = '';
        $("#"+this.pageId+"totalBillAmt").val('');
        this.popupAmtPaid = '';
        $("#"+this.pageId+"amountPaid").val('');
        this.selectedPaymentMode = '';
        $("#"+this.pageId+"paymentMode").val('Cash');
        this.popupChqNumber = '';
        $("#"+this.pageId+"chequeNumber").val('');
        this.popupRefNumber = '';
        $("#"+this.pageId+"referenceNumber").val('');
        this.popupBankName = '';
        $("#"+this.pageId+"bankName").val('');
        $("#"+this.pageId+"balanceAmt").val('');
        this.selectedPaymentDate = '';
        $("#"+this.pageId+"paymentDate").val('');
        this.setPaymentDate = null;
        this.showBankName = false;
        this.showRefNumber = false;
        this.showChqNumber = false;
        this.expensesId = 0;
        this.invoiceAmt = 0.0;

    }

    setFieldsValues() {
        console.log(this.setDataVal);
        this.selectedPaymentMode = (this.setDataVal.paymentMode == null ? "Cash" : this.setDataVal.paymentMode == '' ? "Cash" : this.setDataVal.paymentMode)
            this.selectedPaymentMode = this.selectedPaymentMode;
            if (this.selectedPaymentMode == "Cash") {
                this.showBankName = false;
                this.showRefNumber = false;
                this.showChqNumber = false;
            } else if (this.selectedPaymentMode == "Cheque") {
                this.showBankName = true;
                this.showRefNumber = false;
                this.showChqNumber = true;
            } if (this.selectedPaymentMode == "NEFT") {
                this.showBankName = true;
                this.showRefNumber = true;
                this.showChqNumber = false;
    
            }
        setTimeout(() => {
            if (this.selectedPaymentMode == "Cash") {
                $("#"+this.pageId+"paymentMode").val('Cash');
               
            } else if (this.selectedPaymentMode == "Cheque") {
                $("#"+this.pageId+"paymentMode").val('Cheque');
                
            } if (this.selectedPaymentMode == "NEFT") {
                $("#"+this.pageId+"paymentMode").val('NEFT');
            }
           
            $("#"+this.pageId+"totalBillAmt").val(this.setDataVal.grandTotal == null ? 0.0 : this.setDataVal.grandTotal);
            $("#"+this.pageId+"balanceAmt").val(this.setDataVal.baseFreight);
            $("#"+this.pageId+"amountPaid").val(this.setDataVal.totalAmount == null ? 0.0 : this.setDataVal.totalAmount);
            $("#"+this.pageId+"chequeNumber").val(this.setDataVal.chequeNumber);
           // this.getBankDetailsList();
            if(this.setDataVal.bankName !=null && this.setDataVal.bankName !=""){
                $("#"+this.pageId+"bankName").val(this.setDataVal.bankName);
            }else{
            //    this.getBankDetailsList(); 
            }
           
            $("#"+this.pageId+"referenceNumber").val(this.setDataVal.remarks);
            let paymentDt = this.setDataVal.dateInDate;
            console.log(paymentDt);
            if (paymentDt != null) {
                console.log('ddd');
                $("#"+this.pageId+"paymentDate").val(moment(paymentDt).format("YYYY-MM-DD"));
            }
           
        }, 1000);


    }
	
	printMethod() {
    this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
    this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
    this.selectedToDate = $("#"+this.pageId+"toDate").val();
    this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
   

    if (this.expensesPaymentFollowupReportDataList.length == 0) {
      swal({

        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
	
      this.cashMemoDtoForCustomPrintListColumnNames = ["Bill Number", "Bill Date", "Vendor Name", "Bill Amount", "Paid Amount", "Due Amount"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [10, 20, 30,  20,  10, 10];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      
	   this.grandBillAmt = 0;
	 this.grandPaidAmt = 0;
	 this.grandDueAmt = 0;
   
      for (let i = 0; i < this.expensesPaymentFollowupReportDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [this.expensesPaymentFollowupReportDataList[i].billNumber, this.expensesPaymentFollowupReportDataList[i].billDateStr, this.expensesPaymentFollowupReportDataList[i].supplierName, (this.expensesPaymentFollowupReportDataList[i].grandTotal==null ?0 :this.expensesPaymentFollowupReportDataList[i].grandTotal),(this.expensesPaymentFollowupReportDataList[i].totalAmount==null ?0 :this.expensesPaymentFollowupReportDataList[i].totalAmount), this.expensesPaymentFollowupReportDataList[i].baseFreight];

        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


        this.grandBillAmt = +this.grandBillAmt + +(this.expensesPaymentFollowupReportDataList[i].grandTotal==null ?0 :this.expensesPaymentFollowupReportDataList[i].grandTotal);
        this.grandPaidAmt = +this.grandPaidAmt + +(this.expensesPaymentFollowupReportDataList[i].totalAmount==null ?0 :this.expensesPaymentFollowupReportDataList[i].totalAmount);
        this.grandDueAmt = +this.grandDueAmt + +this.expensesPaymentFollowupReportDataList[i].baseFreight;
       



        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.expensesPaymentFollowupReportDataList.length, "", "", this.grandBillAmt, this.grandPaidAmt, this.grandDueAmt];
      }
      //heading logics For Date

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintListHeadingV2 = [];
  

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
        this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
      }
      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
    //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "Expenses Payment Entries Details ");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }
  
  clearAll(){
	$("#"+this.pageId+"expensesPaymentFollowupReportId").DataTable().destroy();
	this.expensesPaymentFollowupReportDataList = [];
	this.grandBillAmt = 0;
	this.grandPaidAmt = 0;
	this.grandDueAmt = 0;
	this.cashMemoDtoForCustomPrint = new CashMemoDto();
	this.cashMemoDtoForCustomPrintList = [];
	this.cashMemoDtoForCustomPrintListColumnNames = [];
	this.cashMemoDtoForCustomPrintListColumnWidths = [];
	this.cashMemoDtoForCustomPrintDataList = [];
	this.cashMemoDtoForCustomPrintData = new CashMemoDto();
	this.cashMemoDtoForCustomPrintListColumnValues = [];
	this.cashMemoDtoForCustomPrintDataSummaryList = [];
	this.viewCustomPrintV1 = false;
	this.cashMemoDtoForCustomPrintListHeadingV1 = [];
	this.cashMemoDtoForCustomPrintListHeadingV2 = [];
	this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
	this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
	this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
	this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
	this.clearPopupFields();
	this.lrDtoForConfirmPayment = new LRDto();
	this.selectTodayDate();
	this.selectedFromDate =''; 
	this.selectedToDate = '';
	this.selectedVendorName ='';
	$("#"+this.pageId+"vendorName").val('');
	this.selectedType = '';
	$("#"+this.pageId+"type").val('all');
	this.expensesId = 0;
	this.invoiceAmt = 0.0;
	this.showSpinnerForActionForUpload = false;
	this.showSpinnerForAction = false;
	this.dtTriggerExpensesPaymentFollowupReport.next();
	this.setDataVal = new LRDto();
	this.lrDtoForSearch = new LRDto();
	this.localStorageOfExpensesIDFormDB = null;
	this.setDBId=0;

  }
	
	

}