<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Loader Head Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}loaderName" name="loaderName" class="form-control" #loaderName aria-describedby="basic-addon11" [(ngModel)]="lrDtoSave.loaderName">&nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="number" id="{{pageId}}mobileNumber" name="mobileNumber" class="form-control" #mobileNumber aria-describedby="basic-addon11" [(ngModel)]="lrDtoSave.mobileNumber">&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateSetupDetails()">Save</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>
                <div class="col-md-8 vl">
                    <div class="col-md-10">
                        <div class="card">
                            <div class="card-body">
                                <div class="box-body">
                                    <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLoaderMaster" [dtTrigger]="dtTriggerLoaderMaster">
                                        <thead>
                                            <tr>
                                                <th>Loader Head Name</th>
                                                <th>Contact Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let loaderMasterData of loaderMasterDataList ">
                                                <td>{{ loaderMasterData.loaderHead }}</td>
                                                <td>{{ loaderMasterData.contactNumber }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>