<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Multiple E-way Details
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="box-body">
                            <table datatable id="{{pageId}}multipleEwayPopupTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMultiEway" [dtTrigger]="dtTriggerMultEway">
                                <thead>
                                    <tr>
                                        <th>E-way Bill No
                                        </th>
                                        <th>E-Way Bill Valid Upto
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let multiEwayTableData of multiEwayTableDataLists">
                                        <td>{{ multiEwayTableData.eWayBillNo }}
                                        </td>
                                        <td>{{ multiEwayTableData.ewayBillValidUptoStr }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>
