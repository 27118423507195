
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";

import swal from 'sweetalert';
import { MemoService } from 'src/app/dataService/memo-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
@Component({
    selector: 'app-bkg-left-toparty-details-print',
    templateUrl: './bkg-left-toparty-details-print.component.html',
    styleUrls: ['./bkg-left-toparty-details-print.component.css']
})
export class BkgLeftTopartyDetailsPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    dataForPrintAssignMemo: any;
    dataForPrintAssignMemoList: any;
    collectionMan: any;
    totalAmount: number;
    toPartyDetailsDataList: any;
    totalCount: any;
    totalArt: number;
    totalActWgt: number;
    totalChgWgt: number;
    totalBasic: number;
    totalSHamali: number;
    totalGcChg: number;
    totalDDamt: number;
    totalOthers: number;
    totalLcChg: number;
    totalRiskCharge: number;
    totalCodChg: number;
    totalPaid: number;
    memoAmount: number;
    address: any;
    image: any;
    dataForPrintGCTakenMemo: any;
    dataForPrintGCTakenMemoList: any;
    showAssignDate = false;
    pageId = "pfabpc";
    cashMemoDtoTable: CashMemoDto = new CashMemoDto();
    tpStmtNo: any;
    leftToPartyGeneralData: any;
    tpStmtFromDate = null;
    tpStmtToDate = null;
    toPartyName = null;
    toPartyAddress = null;
    toPartyGst = null;
    tpStmtDate = null;
    showStmtPeriod: boolean = true;
    showStmtDate: boolean = false;
    //account details
    acNo = null;
    acName = null;
    bankName = null;
    branchName = null;
    ifscCode = null;
    transportedId = null;
    panNo = null;
    paymentQrCode = null;
    upiCode = null;

    constructor(private router: Router, private datePipe: DatePipe, private memoService: MemoService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getAccountDetails();
            this.leftToPartyGeneralData = JSON.parse(localStorage.getItem('leftToPartyGeneralDetailsLocalStorage'));
            localStorage.clear();
            this.tpStmtNo = this.leftToPartyGeneralData.statementNo;
            this.tpStmtFromDate = this.leftToPartyGeneralData.fromdate;
            this.tpStmtToDate = this.leftToPartyGeneralData.todate;
            this.tpStmtDate = this.leftToPartyGeneralData.tpStmtDateStr;
            this.toPartyName = this.leftToPartyGeneralData.consignor;
            this.toPartyAddress = this.leftToPartyGeneralData.address;
            this.toPartyGst = this.leftToPartyGeneralData.gstNumber;
            if (this.tpStmtFromDate != null && this.tpStmtToDate) {
                this.showStmtDate = false;
                this.showStmtPeriod = true;
            } else {
                this.showStmtDate = true;
                this.showStmtPeriod = false;
            }

            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.image = this.userDataDtoReturnSession.ip;
            this.getTopartyDetailsBkgPaymentFollowup();
            this.validatBeforePrint();
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
    }

    clearField() {
        this.address = '';
        this.dataForPrintAssignMemo = '';
        this.dataForPrintAssignMemoList = '';
        this.collectionMan = '';
        this.totalAmount = 0;
        this.toPartyDetailsDataList = '';
        this.totalCount = 0;
        this.totalArt = 0;
        this.totalActWgt = 0;
        this.totalChgWgt = 0;
        this.totalBasic = 0;
        this.totalSHamali = 0;
        this.totalGcChg = 0;
        this.totalDDamt = 0;
        this.totalOthers = 0;
        this.totalLcChg = 0;
        this.totalRiskCharge = 0;
        this.totalCodChg = 0;
        this.totalPaid = 0;
        this.memoAmount = 0;
        this.dataForPrintGCTakenMemo = '';
        this.dataForPrintGCTakenMemoList = '';
        this.showAssignDate = false;
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    getTopartyDetailsBkgPaymentFollowup = () => {
        this.cashMemoDtoTable = new CashMemoDto();
        console.log('getTopartyDetailsBkgPaymentFollowup');
        console.log(this.tpStmtNo);
        this.cashMemoDtoTable.statementNo = this.tpStmtNo;
        this.cashMemoDtoTable.memoType = "StatementNo";
        this.memoService.getCashMemoUnAssgBkgService(this.cashMemoDtoTable).subscribe(
            (response) => {
                this.toPartyDetailsDataList = response;
                this.totalCount = response.length;
                this.totalArt = this.totalActWgt = this.totalChgWgt = this.totalBasic = this.totalSHamali = this.totalGcChg = this.totalDDamt = this.totalPaid = 0;
                this.totalOthers = 0;
                this.totalLcChg = 0;
                this.totalRiskCharge = 0;
                this.totalCodChg = 0;
                for (let i = 0; i < this.toPartyDetailsDataList.length; i++) {
                    this.totalArt = +this.totalArt + this.toPartyDetailsDataList[i].articles;
                    this.totalActWgt = +this.totalActWgt + this.toPartyDetailsDataList[i].actualWeight;
                    this.totalChgWgt = +this.totalChgWgt + this.toPartyDetailsDataList[i].chargedWeight;
                    this.totalBasic = +this.totalBasic + this.toPartyDetailsDataList[i].lorryFreight;
                    this.totalSHamali = +this.totalSHamali + (this.toPartyDetailsDataList[i].hamaligd == null ? 0 : this.toPartyDetailsDataList[i].hamaligd);
                    this.totalGcChg = +this.totalGcChg + (this.toPartyDetailsDataList[i].gcChg == null ? 0 : this.toPartyDetailsDataList[i].gcChg);
                    this.totalDDamt = +this.totalDDamt + (this.toPartyDetailsDataList[i].lrDDAmt == null ? 0 : this.toPartyDetailsDataList[i].lrDDAmt);

                    this.totalOthers = +this.totalOthers + (this.toPartyDetailsDataList[i].others == null ? 0 : this.toPartyDetailsDataList[i].others);
                    this.totalLcChg = +this.totalLcChg + (this.toPartyDetailsDataList[i].lcChg == null ? 0 : this.toPartyDetailsDataList[i].lcChg);
                    this.totalRiskCharge = +this.totalRiskCharge + (this.toPartyDetailsDataList[i].riskCharge == null ? 0 : this.toPartyDetailsDataList[i].riskCharge);
                    this.totalCodChg = +this.totalCodChg + (this.toPartyDetailsDataList[i].codChg == null ? 0 : this.toPartyDetailsDataList[i].codChg);

                    this.totalPaid = +this.totalPaid + (this.toPartyDetailsDataList[i].grandTotal == null ? 0 : this.toPartyDetailsDataList[i].grandTotal);
                }
                window.setTimeout(function () {
                    window.print();
                }, 0);
                window.onafterprint = function () {
                    window.close();
                }
                localStorage.clear();
                window.addEventListener('afterprint', (event) => {
                    this.clearField();
                });
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    //as of now hardcoded the details, will get from the backend in future
    getAccountDetails() {
        let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
        if (MainAdminStation != null && MainAdminStation == 'bangalore bkg head ofc') {
            this.acNo = "12062560000234";
            this.acName = "SRD Logistics Pvt Ltd";
            this.bankName = "HDFC Bank Ltd";
            this.branchName = "KH Road Bangalore";
            this.ifscCode = "HDFC0001206";
            this.transportedId = "29AAMCS7073E1Z0";
            this.panNo = "AAMCS7073E";
            this.paymentQrCode = "assets/images/PaidMemoQRPayment_V2.png";
            this.upiCode = "srdlogisticsprivatel.62504730@hdfcbank";
        } else if (MainAdminStation != null && MainAdminStation == 'mumbai') {
            this.acNo = "05222320000586";
            this.acName = "SRD Logistics Pvt Ltd";
            this.bankName = "HDFC Bank";
            this.branchName = "Mumbai Central";
            this.ifscCode = "HDFC0000522";
            this.transportedId = "29AAMCS7073E1Z0";
            this.panNo = "AAMCS7073E";
            this.paymentQrCode = "assets/images/PaidMemoQRPayment_MUM_V1.png";
            this.upiCode = "srdlogisticsprivatel.62504730@hdfcbank";
        } else if (MainAdminStation != null && MainAdminStation == 'gujarat') {
            this.acNo = "05222320000586";
            this.acName = "SRD Logistics Pvt Ltd";
            this.bankName = "HDFC Bank";
            this.branchName = "Mumbai Central";
            this.ifscCode = "HDFC0000522";
            this.transportedId = "29AAMCS7073E1Z0";
            this.panNo = "AAMCS7073E";
            this.paymentQrCode = "assets/images/PaidMemoQRPayment_MUM_V1.png";
            this.upiCode = "srdlogisticsprivatel.62504730@hdfcbank";
        }
    }
}
