<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Short & Extra Details</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						
						<div class="col-md-3">
							<h6>
								<strong>LR :</strong>&nbsp;&nbsp;&nbsp;<span  style="color: green;" id="{{pageId}}lrNumber"><b>{{listOfDataToGet}}</b></span>
<!-- 								<strong>Short & Extra Details</strong> -->
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails"
								[dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<th>Art</th>
										<th>Status</th>
										<th>Belongs To</th>
										<th>Invoice Number</th>
										<th>Vehicle Number</th>
										<th>Remarks</th>
										<th>Entered By</th>
										<th>Entered At</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsData of lrDetailsDataList ">
										<td>{{ lrDetailsData.lrShortage }}</td>
										<td>{{ lrDetailsData.status }}</td>
										<td>{{ lrDetailsData.destination }}</td>
										<td>{{ lrDetailsData.invoiceNumber }}</td>
										<td>{{ lrDetailsData.vehicleNumber }}</td>
										<td>{{ lrDetailsData.remarks }}</td>
										<td>{{ lrDetailsData.userName }}</td>
										<td>{{ lrDetailsData.enteredDate }}</td>
									</tr>


									<!-- <tr *ngIf="supList.length==0">
										<td colspan="9">No Data To Display</td>
									</tr>-->
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
