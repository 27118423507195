<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-10">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;SRD Labour Hamali Statement
			</h6>
	
		</div>
		<div class="col-md-2">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getLabourHamaliPendingStmtDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}labourStmtTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsSrdLabourHamaliStatement"
			[dtTrigger]="dtTriggerSrdLabourHamaliStatement">

			<thead>
				<tr>
					<th>Stmt No</th>
					<th>Loader Head</th>
					<th>Stmt Dt</th>
					<th>Amount</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let srdLabourHamaliStatementData of srdLabourHamaliStatementDataList ">
					<td (click)="goToLabourHamaliStatementDetailsPage(srdLabourHamaliStatementData)"><u
						class="hyperLink_td">{{ srdLabourHamaliStatementData.stmtNo }}</u></td>
					<td>{{ srdLabourHamaliStatementData.loaderHead }}</td>
					<td>{{ srdLabourHamaliStatementData.stmtDateStr }}</td>
					<td>{{ srdLabourHamaliStatementData.toBePaidAmt }}</td>
				</tr>


				<!--									<tr *ngIf = "supList.length==0">
 														<td colspan="9">No Data To Display</td>
															</tr>
		-->

			</tbody>
		</table>
	</div>
</div>
