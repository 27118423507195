import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { ConsignorService } from 'src/app/dataService/consignor-service';

@Component({
    selector: 'app-consignor-master-toparty',
    templateUrl: './consignor-master-toparty.component.html',
    styleUrls: ['./consignor-master-toparty.component.css']
})
export class ConsignorMasterTopartyComponent implements OnInit {

    dtTriggerConsignorMaster: Subject<any> = new Subject();
    dtOptionsConsignorMaster: any;
    pageId = "cmtpc";
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    enteredConsignorName = null;
    enteredGST = null;
    enteredContactPerson = null;
    enteredContactNumber = null;
    enteredAddress = null;
    enteredArea = null;
    enteredPincode = null;
    enteredCity = null;
    validateConsignorId: any;
    consignorMasterDataList: PartyMasterDto[];
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    showSpinnerForAction: boolean = false;

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef, private consignorService: ConsignorService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getTopartyDetailsMethod();
        }
    }

    ngOnInit(): void {
        this.dtOptionsConsignorMaster = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerConsignorMaster.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerConsignorMaster.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateSetupDetails() {
        swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.saveSetupDetails();
            }
        })
    }
    userSetupDetails() {
        this.partyMasterDto = new PartyMasterDto();
        this.enteredConsignorName = $("#" + this.pageId + "consignorName").val();
        this.enteredGST = $("#" + this.pageId + "gstNumber").val();
        this.enteredContactPerson = $("#" + this.pageId + "contactPerson").val();
        this.enteredContactNumber = $("#" + this.pageId + "officeNumber").val();
        this.enteredAddress = $("#" + this.pageId + "address").val();
        this.enteredArea = $("#" + this.pageId + "area").val();
        this.enteredPincode = $("#" + this.pageId + "pincode").val();
        this.enteredCity = $("#" + this.pageId + "city").val();

        this.partyMasterDto.consignorName = this.enteredConsignorName.toUpperCase();
        this.partyMasterDto.gstNoConsignor = this.enteredGST;
        this.partyMasterDto.address = this.enteredAddress;
        this.partyMasterDto.area = this.enteredArea;
        this.partyMasterDto.city = this.enteredCity;
        this.partyMasterDto.pincode = this.enteredPincode;
        this.partyMasterDto.contactPerson = this.enteredContactPerson;
        this.partyMasterDto.mobileNumber = this.enteredContactNumber;

        if (this.validateConsignorId == null || this.validateConsignorId == undefined ||
            this.validateConsignorId == "") {
            this.partyMasterDto.consignorId = 0;
        } else {
            this.partyMasterDto.consignorId = this.validateConsignorId;
        }
        this.partyMasterDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDto.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDto.branch = this.userDataDtoReturnSession.office;
    }

    saveSetupDetails = () => {
        this.userSetupDetails();
        this.consignorService.addConsignorTopartyDetails(this.partyMasterDto).
            subscribe((data) => {
                if (data.status == "Success") {
                    swal("Success", "Details saved/updated successfully!", "info");
                    this.clearFields();
                    this.getTopartyDetailsMethod();
                } else {
                    swal("Failed", "Failed to save/update the details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the details!", "error");
            },
            () => console.log('done');
    }

    getTopartyDetailsData() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDto.branch = this.userDataDtoReturnSession.office;
    }
    getTopartyDetailsMethod = () => {
        this.showSpinnerForAction = true;
        this.getTopartyDetailsData();
        this.consignorService.getConsignorTopartyDetails(this.partyMasterDto).subscribe(
            (response) => {
                if (response) {
                    this.consignorMasterDataList = response;
                }
                this.showSpinnerForAction = false;
                this.dtTriggerConsignorMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details", "info");
            },
            () => console.log('done');
    }

    rowSelectedGetConsignorDetails(consignorNameMasterData) {
        this.validateConsignorId = 0;
        this.validateConsignorId = consignorNameMasterData.consignorId;
        $("#" + this.pageId + "consignorName").val(consignorNameMasterData.consignorName);
        $("#" + this.pageId + "gstNumber").val(consignorNameMasterData.gstNoConsignor);
        $("#" + this.pageId + "officeNumber").val(consignorNameMasterData.mobileNumber);
        $("#" + this.pageId + "address").val(consignorNameMasterData.address);
        $("#" + this.pageId + "city").val(consignorNameMasterData.city);
        $("#" + this.pageId + "contactPerson").val(consignorNameMasterData.contactPerson);
        $("#" + this.pageId + "pincode").val(consignorNameMasterData.pincode);
        $("#" + this.pageId + "area").val(consignorNameMasterData.area);
        this.changeDetectorRef.detectChanges();
    }

    clearFields() {
        this.enteredConsignorName = null;
        this.enteredGST = null;
        this.enteredContactPerson = null;
        this.enteredContactNumber = null;
        this.enteredAddress = null;
        this.enteredArea = null;
        this.enteredPincode = null;
        this.enteredCity = null;
        this.validateConsignorId = 0;
        $("#" + this.pageId + "consignorName").val("");
        $("#" + this.pageId + "gstNumber").val("");
        $("#" + this.pageId + "contactPerson").val("");
        $("#" + this.pageId + "officeNumber").val("");
        $("#" + this.pageId + "address").val("");
        $("#" + this.pageId + "area").val("");
        $("#" + this.pageId + "pincode").val("");
        $("#" + this.pageId + "city").val("");
    }

}

