import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
//for the select option with filter ends
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterDto } from 'src/app/dto/master-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { from, merge } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import swal from 'sweetalert';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { LRIssueDetailsBalanceRptComponent } from 'src/app/report/lr-report/lr-issue-details-balance-rpt/lr-issue-details-balance-rpt.component';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';


@Component({
    selector: 'app-issue-balance',
    templateUrl: './issue-balance.component.html',
    styleUrls: ['./issue-balance.component.css']
})
export class IssueBalanceComponent implements OnInit {


    getDataFrmServiceFrTable: any;
    issueBalanceReportDataList: any;
    onDestroyUnsubscribtionIssueBalanceReport: Subscription;

    loadingIndicator = true;

    controlMain = new FormControl();

    agentNameOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'ANDHERI' },
        { id: 3, label: 'BHIWANDI' },
        { id: 4, label: 'DAMAN' }
    ];

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerIssueBalanceReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsIssueBalanceReport: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction: boolean = false;

    sourceOptions: AgentDetailsDto[];
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;

    superAdminView: boolean = false;

    lrDtoForSearch: LRDto = new LRDto();
    validateAgentName: any;

    viewCustomPrintV1 = false;

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;

    btnInsert: boolean = false;
    pageId="isblc";

    constructor(private issueBalanceReport: MasterService,
        private router: Router, private mastereadService: MasterReadService,
        public dialog: MatDialog,
        private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "btnInsert LRIssueDetails") {
                        this.btnInsert = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LrIssueBalRpt ShowAll") {
                        this.superAdminView = true;
                    }
                }
            }

            this.getSourceDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }



    ngOnInit(): void {


        this.dtOptionsIssueBalanceReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerIssueBalanceReport.unsubscribe();
       // this.onDestroyUnsubscribtionIssueBalanceReport.unsubscribe();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngAfterViewInit(): void {
        this.dtTriggerIssueBalanceReport.next();
    }

    clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#"+this.pageId+"source").val(this.modelSource.subStation);
    }

    getSourceDetails() {
        this.sourceAgentDto = new AgentDetailsDto();
        if (this.superAdminView) {
            this.sourceAgentDto.mode = "mainBranch";
            this.sourceAgentDto.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.sourceAgentDto.mode = "mainBranch";
            if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.sourceAgentDto.mainStation = this.userDataDtoReturnSession.mainStation;
            } else {
                this.sourceAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.sourceAgentDto.column2 = "working";
        this.getSourceMethod();
    }

    getSourceMethod = () => {
        this.showSpinnerForAction = true;
        this.mastereadService.getSourceListDetails(this.sourceAgentDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.sourceOptions = [];
                    this.sourceTA = [];
                } else {
                    this.sourceOptions = [];
                    this.sourceTA = [];
                    this.sourceOptions = response;
                    this.sourceAgentDtoAll = new AgentDetailsDto();
                    this.sourceAgentDtoAll.subStation = "All";
                    this.sourceTA.push(this.sourceAgentDtoAll);
                    for (let i = 0; i < this.sourceOptions.length; i++) {
                        this.sourceTA.push(this.sourceOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log("done");
    };

    validateSearchBtn() {
        if ($("#"+this.pageId+"source").val() == null || $("#"+this.pageId+"source").val() == undefined ||
            $("#"+this.pageId+"source").val() == "") {
            swal("Mandatory Field", "Please select agent name", "warning");
            return false;
        } else {
            this.setLRIssueBalanceRptDetails();
        }
    }

    setLRIssueBalanceRptDetails() {
        this.lrDtoForSearch = new LRDto();
        this.validateAgentName = $("#"+this.pageId+"source").val();

        if ($("#"+this.pageId+"source").val() == "All") {
            this.lrDtoForSearch.mode = "ALL";
        } else {
            this.lrDtoForSearch.mode = "SPECIFIC";
        }

        if (this.superAdminView == false) {
            if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainStation;
            } else {
                this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
        this.lrDtoForSearch.agentName = this.validateAgentName;
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getLRIssueBalRpDtls(this.lrDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                this.issueBalanceReportDataList = [];
                $("#"+this.pageId+"issueBalanceRptTableId").DataTable().destroy();
                if (response.length == 0) {
                    swal("No Data", "No Records  found for this search", "warning");
                } else {
                    this.issueBalanceReportDataList = response;
                }
                this.dtTriggerIssueBalanceReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the LR Issue Balance Details", "error");
            },
            () => console.log("done");
    }

    issueBalanceRptPopUpBtnLink(issueBalanceData) {
        console.log(issueBalanceData);
        localStorage.clear();
        localStorage.setItem('LRIssueBalRptPopUp', JSON.stringify(issueBalanceData));

        const dialogRef = this.dialog.open(LRIssueDetailsBalanceRptComponent, {
        });

        // dialogRef.afterClosed().subscribe(result => {
        //     console.log(`Dialog result: ${result}`);
        // });
    }

    clearBtn() {
        this.clearField();
    }

    clearField() {
        this.modelSource = null;
        $("#"+this.pageId+"source").val('');
        this.validateAgentName = null;

        this.issueBalanceReportDataList = [];
        $("#"+this.pageId+"issueBalanceRptTableId").DataTable().destroy();
        this.dtTriggerIssueBalanceReport.next();
    }

    customPrintLRIssueBal() {
        if (this.issueBalanceReportDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Total LR Issued", "Total LR Entered",
                "Remaining LR's"];
            //this.cashMemoDtoForCustomPrintListColumnWidths = [13, 8, 15, 8, 6, 5, 5, 5, 3, 5, 5,13,9];
            this.cashMemoDtoForCustomPrintListColumnWidths = [25, 25, 25, 25];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < this.issueBalanceReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.issueBalanceReportDataList[i].agentName,
                    this.issueBalanceReportDataList[i].totalLrs,
                    this.issueBalanceReportDataList[i].totallrsfromTripSheet,
                    this.issueBalanceReportDataList[i].totalInvoice
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + this.issueBalanceReportDataList[i].totalLrs;
                this.summary2 = this.summary2 + this.issueBalanceReportDataList[i].totallrsfromTripSheet;
                this.summary3 = this.summary3 + this.issueBalanceReportDataList[i].totalInvoice;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.issueBalanceReportDataList.length,
                this.summary1, this.summary2, this.summary3];
            }
            //heading logics For Date
            //this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
            //this.toDatePrint = $("#"+this.pageId+"toDates").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Agent Name"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#"+this.pageId+"source").val()];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "LR Issue Balance Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

}