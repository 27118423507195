<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
															<option selected value="sourceWise">Source Wise</option>
															<option value="destinationWise">Destination Wise</option>
															<option value="mainInvoice">Main Invoice</option>
															<option value="cityWise">City Wise</option>
															<option value="agentWise">Agent Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="yyyy-mm-dd" name="fromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDate.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewSource" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}destination">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowViaSourceField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Via</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}viaSourceId" type="text" class="form-control" [(ngModel)]="modelViaSourceStation" [ngbTypeahead]="searchViaSourceStation" (selectItem)="viaSourceStationListener($event)" [resultFormatter]="formatterViaSource" [inputFormatter]="formatterViaSource"
                                                            (focus)="focusViaSourceStationTA$.next($any($event).target.value)" placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewStationInvoice" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-warehouse"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchByStation" #searchByStation (change)="searchByMainInvoiceStationWiseMode(searchByStation.value)">
															<option selected value="mainStation">Main
																Station</option>
															<option value="subStation">Sub Station</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>




                                            <div *ngIf="viewCity" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Sources</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewAgent" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Booking Agent</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}station">
															<option selected>Select Booking Agent</option>
															<option value="all">All</option>
															<option value="agra">Agra</option>
															<option value="bawana">Bawana</option>
															<option value="budhpur">Budhpur</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" (click)="validateSearchBtn();" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <!-- 							the first datatble starts -->
                            <div [hidden]="!invoiceSrcWiseTable">
                                <table datatable id="{{pageId}}invoiceSrcWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSourceWise" [dtTrigger]="dtTriggerSourceWise">

                                    <thead>
                                        <tr>
                                            <th [hidden]=true>Main Agent</th>
                                            <th>Station</th>
                                            <th>Actual Weight</th>
                                            <th>Charged Weight</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>DD Amt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sourceWiseData of sourceWiseDataList ">
                                            <td [hidden]=true>{{ sourceWiseData.mainAgent }}</td>
                                            <td>{{ sourceWiseData.mainstation }}</td>
                                            <td>{{ sourceWiseData.actualWeight }}</td>
                                            <td>{{ sourceWiseData.chargedWeight }}</td>
                                            <td>{{ sourceWiseData.toPay }}</td>
                                            <td>{{ sourceWiseData.paid }}</td>
                                            <td>{{ sourceWiseData.ddAmt }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td [hidden]=true></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div [hidden]="!invoiceMainStationTable">
                                <table datatable id="{{pageId}}invoiceMainStationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceMainStation" [dtTrigger]="dtTriggerInvoiceMainStation">

                                    <thead>
                                        <tr>
                                            <th [hidden]=true>Destination</th>
                                            <th>Invoice No</th>
                                            <th>Lrs</th>
                                            <th>Invoice Date</th>
                                            <th>Art</th>
                                            <th>Actual Wgt</th>
                                            <th>Charged Wgt</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>FOV</th>
                                            <th>Less</th>
                                            <th>DD Amt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let invoiceMainStationData of invoiceMainStationDataList ">
                                            <td [hidden]=true>{{ invoiceMainStationData.destination }}
                                            </td>
                                            <td>{{ invoiceMainStationData.invoiceNumber }}</td>
                                            <td>{{ invoiceMainStationData.totalLrs }}</td>
                                            <td>{{ invoiceMainStationData.invoiceDateStr }}</td>
                                            <td>{{ invoiceMainStationData.totalArticles }}</td>
                                            <td>{{ invoiceMainStationData.actualWeight }}</td>
                                            <td>{{ invoiceMainStationData.chargedWeight }}</td>
                                            <td>{{ invoiceMainStationData.toPay }}</td>
                                            <td>{{ invoiceMainStationData.paid }}</td>
                                            <td>{{ invoiceMainStationData.riskCharge }}</td>
                                            <td>{{ invoiceMainStationData.discountAmount }}</td>
                                            <td>{{ invoiceMainStationData.ddAmt }}</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td [hidden]=true></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                            <!-- 						the third datatable ends  -->
                            <!-- 							the fourth datatable starts  -->

                            <div [hidden]="!invoiceSubStationTable">
                                <table datatable id="{{pageId}}invoiceSubStationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceSubStation" [dtTrigger]="dtTriggerInvoiceSubStation">

                                    <thead>
                                        <tr>
                                            <th [hidden]=true>Destination</th>
                                            <th>Invoice No</th>
                                            <th>Lrs</th>
                                            <th>Invoice Date</th>
                                            <th>Art</th>
                                            <th>Actual Wgt</th>
                                            <th>Charged Wgt</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>Less</th>
                                            <th>DD Amt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let invoiceSubStationData of invoiceSubStationDataList ">
                                            <td [hidden]=true>{{ invoiceSubStationData.destination }}
                                            </td>
                                            <td>{{ invoiceSubStationData.invoiceNumber }}</td>
                                            <td>{{ invoiceSubStationData.totalLrs }}</td>
                                            <td>{{ invoiceSubStationData.invoiceDateStr }}</td>
                                            <td>{{ invoiceSubStationData.totalArticles }}</td>
                                            <td>{{ invoiceSubStationData.actualWeight }}</td>
                                            <td>{{ invoiceSubStationData.chargedWeight }}</td>
                                            <td>{{ invoiceSubStationData.toPay }}</td>
                                            <td>{{ invoiceSubStationData.paid }}</td>
                                            <td>{{ invoiceSubStationData.discountAmount }}</td>
                                            <td>{{ invoiceSubStationData.ddAmt }}</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td [hidden]=true></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                            <!-- 							the fourth datatable ends  -->
                            <!-- 							the fifth datatable starts  -->

                            <div [hidden]="!cityWiseTable">
                                <table datatable id="{{pageId}}cityWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCityWise" [dtTrigger]="dtTriggerCityWise">

                                    <thead>
                                        <tr>
                                            <th>Station</th>
                                            <th>Actual Wgt</th>
                                            <th>Charged Wgt</th>
                                            <th>Total Art</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>FOV</th>
                                            <th>Goods Value</th>
                                            <th>DD Amt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cityWiseData of cityWiseDataList ">
                                            <td>{{ cityWiseData.mainstation }}</td>
                                            <td>{{ cityWiseData.actualWeight }}</td>
                                            <td>{{ cityWiseData.chargedWeight }}</td>
                                            <td>{{ cityWiseData.totalArticles }}</td>
                                            <td>{{ cityWiseData.toPay }}</td>
                                            <td>{{ cityWiseData.paid }}</td>
                                            <td>{{ cityWiseData.riskCharge }}</td>
                                            <td>{{ cityWiseData.goodsValue }}</td>
                                            <td>{{ cityWiseData.ddAmt }}</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <!-- 							the fifth datatable ends  -->



                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>