import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";



//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { StockService } from 'src/app/dataService/stock-service';
//from the particular global folder ends
//for datatable ends

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//for redirect to other page starts
import { Router } from '@angular/router';
//for redirect to other page ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends
@Component( {
    selector: 'app-trip-creation-hireslip-generation',
    templateUrl: './trip-creation-hireslip-generation.component.html',
    styleUrls: ['./trip-creation-hireslip-generation.component.css']
} )
export class TripCreationHireslipGenerationComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrGoodsShortAndExtraDetailsTable: any;


    goodsShortAndExtraDetailsDataList: any;


    onDestroyUnsubscribtionGoodsShortAndExtraDetails: Subscription;

    //for datatable ends
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends
    
  //for popup modal starts
    closeResultContentNotAssigned: string;
    
    //for the select option with filter starts
    controlTrackingDeviceId = new FormControl();
    controlDepartureTime = new FormControl();
    controlScheduledTime = new FormControl();

    departureTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' }
    ];
    scheduledTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' }
    ];
    trackingDeviceIdOptions = [
        { id: 1, label: '26682' },
        { id: 2, label: '26685' },
        { id: 3, label: '26737' },
        { id: 4, label: '29276' },
    ];


    //for the select option with filter ends
    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerGoodsShortAndExtraDetails: Subject<any> = new Subject();
    twoPointDoorDelivery= false
    dataTable: any;
    dtOptionsGoodsShortAndExtraDetails: any;
    pageId="tchgc";
    //for datatable ends

    constructor(/* for datatable starts */private tripCreationHireslipGenerationScreen: StockService, public changeDetectorRef : ChangeDetectorRef, /* for datatable endss */
        //for redirect to other page starts
        private router: Router,
        //for redirect to other page ends
      //for popup modal starts
        private modalService: NgbModal
        //for popup modal ends
    ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsGoodsShortAndExtraDetails = {

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.gettingDataFrmServiceFrGoodsShortAndExtraDetailsTable = this.tripCreationHireslipGenerationScreen.getSummaryData()
        this.onDestroyUnsubscribtionGoodsShortAndExtraDetails = this.gettingDataFrmServiceFrGoodsShortAndExtraDetailsTable.subscribe( data => {
            this.goodsShortAndExtraDetailsDataList = data['data'];
            this.dtTriggerGoodsShortAndExtraDetails.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerGoodsShortAndExtraDetails.unsubscribe();

        this.onDestroyUnsubscribtionGoodsShortAndExtraDetails.unsubscribe();
        //for datatable ends
    }

    //for datepicker starts
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker ends
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
    //for redirect to other page starts
    goToLrEnquiryPage() {
        this.router.navigate( ['/masters/transhipment/truckMaster'] );
    }
    //for redirect to other page starts
    
  //for popup modal starts 

    openNotAssignedPopup( contentProceed ) {
        this.modalService.open( contentProceed, { centered: true } ).result.then(
            resultContent => {
                this.closeResultContentNotAssigned = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentNotAssigned = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }
    


    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends
}
