<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Paid Left To Party Statement Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <strong>To Party&nbsp;:&nbsp;</strong>{{toPartyNameHeader}} &nbsp;<strong>Statement No
							&nbsp;:&nbsp;</strong>{{stmtNoHeader}}
                    </div>
                    <div class="col-md-12">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <div class="box-body">
                            <table datatable id="{{pageId}}leftToPartyStmtTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLeftToPartyStmtDts" [dtTrigger]="dtTriggerLeftToPartyStmtDts">
                                <thead>
                                    <tr>
                                        <th>LR Number</th>
                                        <th>Memo Number</th>
                                        <th>Consignor Name</th>
                                        <th>Consignee Name</th>
                                        <th>Booking Date</th>
                                        <th>Memo Amount</th>
                                        <th>Statement Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let leftToPartyPoupData of leftToPartyPoupDataList ">
                                        <td>{{ leftToPartyPoupData.lrNumber }}</td>
                                        <td>{{ leftToPartyPoupData.memoNumber }}</td>
                                        <td>{{ leftToPartyPoupData.consignor }}</td>
                                        <td>{{ leftToPartyPoupData.consigneeName }}</td>
                                        <td>{{ leftToPartyPoupData.bookingDateStr }}</td>
                                        <td>{{ leftToPartyPoupData.grandTotal }}</td>
                                        <td>{{ leftToPartyPoupData.status }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>