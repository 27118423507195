<html>
<head>
<style>
/* to fix the height of a single row in drag and drop starts */
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, .05);
	max-height: 24px;
}

.table-striped tbody tr:nth-of-type(even) {
	max-height: 24px;
}
/* to fix the height of a single row in drag and drop ends */
</style>
</head>

<body>

	<!-- Row -->
	<div class="row system_responsive">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Consignee Grouping</h6>
				</div>
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-4 ">
						<div class="card-body">

							<!-- <h6 class="card-title">Tempo Details</h6> -->
							<div class="row">
								<div class="col-md-12">
									<div class="box-body">
										<h6 class="card-title">Consignee List</h6>
										<!-- 							<the first datatable starts  -->
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsRepeatedConsigneeList"
											[dtTrigger]="dtTriggerRepeatedConsigneeList">

											<thead>
												<tr>
													<th>Consignee Name</th>
													<th>Gst Number</th>
												</tr>
											</thead>
											<tbody style="height: 40vh;" class='dragndrop'
												[dragula]='"first-bag"'>
												<tr
													*ngFor="let repeatedConsigneeListData of repeatedConsigneeListDataList ">
													<td>{{ repeatedConsigneeListData.name }}</td>
													<td>{{ repeatedConsigneeListData.grade }}</td>

												</tr>


												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 							the first datatable ends  -->



					<div class="col-md-4 vl">
						<div class="card-body">

							<!-- <h6 class="card-title">Tempo Details</h6> -->
							<div class="row">
								<!-- 							<the second datatable starts  -->
								<div class="col-md-12">
									<div class="box-body">
										<h6 class="card-title">Grouping Consignee</h6>
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionMergeConsigneeList"
											[dtTrigger]="dtTriggerMergeConsigneeList">
											<thead>
												<tr>
													<th>Consignee Name</th>
													<th>Gst Number</th>
												</tr>
											</thead>
											<tbody style="height: 40vh;" class='dragndrop'
												[dragula]='"first-bag"'>

												<tr
													*ngFor="let mergeConsigneeListData of mergeConsigneeListDataList ">
													<td>{{ mergeConsigneeListData.consigneeName }}</td>
													<td>{{ mergeConsigneeListData.gstNumber }}</td>
												</tr>

												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>

										</table>
									</div>
								</div>
								<!--							 the second datatable ends  -->
							</div>

							<div class="row p-t-10">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}consigneeName">
											<label>Consignee Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
											</div>
											<input type="text" [(ngModel)]="consigneeName"
												class="form-control" aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success" id="{{pageId}}groupBtn">Group</button>
							<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}removeBtn">Remove</button>
							<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<!-- 							the third datatable starts  -->
					<div class="col-md-4 vl">
						<div class="card-body">
							<div class="row"></div>
							<div class="row">

								<div class="col-sm-12 col-md-12">
									<div class="box-body">
										<div class="row">
											<!-- 										style="border-bottom: 1px solid orange;"> -->

											<div class="col-sm-12 col-md-8">
												<h6 class="card-title">Groupped Consignee Details</h6>
											</div>


											<!-- 											<div class="col-sm-12 col-md-4"> -->
											<!-- 												<button style="padding: 1px 4px;" type="submit" -->
											<!-- 													(click)="openPopup(content)" class="btn btn-dark m-r-10" -->
											<!-- 													id="{{pageId}}undoBtn">Undo</button> -->
											<!-- 											</div> -->
										</div>
										<div class="row p-t-10">
											<div class="col-sm-12 col-md-12">
												<table datatable
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionConfirmedConsigneeList"
													[dtTrigger]="dtTriggerConfirmedConsigneeList">
													<thead>
														<tr>
															<th>Consignee Name</th>
															<th>Gst Number</th>
														</tr>
													</thead>
													<tbody>

														<tr
															*ngFor="let confirmedConsigneeListData of confirmedConsigneeListDataList; let i = index"
															(click)="RowSelected(confirmedConsigneeListData);">
															<td>{{ confirmedConsigneeListData.name }}</td>
															<td>{{ confirmedConsigneeListData.contactNo }}</td>
														</tr>

														<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

													</tbody>

												</table>
											</div>
											<!-- 							the third datatable ends  -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
				<div class="col-md-12">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}popupDetailsTwo">
							<ng-template #content let-yes="close" let-no="close"
								let-ok="close" let-d="dismiss">
							<div class="modal-body">
								<div class="row" style="text-align: center;">
									<div class="col-md-12">
										<h5 class="m-b-10">Sure you want to UnConfirm the
											selected Consignees?</h5>
										<!-- 											<h5 class="m-b-10"> -->
										<!-- 											<i class="fas fa-exclamation-triangle"></i> Please select -->
										<!-- 											consignee name to Undo -->
										<!-- 										</h5> -->
									</div>
									<div class="col-md-12 p-t-10">
										<!-- 										<button type="button" class="btn btn-outline-secondary" -->
										<!-- 											(click)="ok('Yes click')">Ok</button> -->
										<button type="button" class="btn btn-outline-secondary"
											(click)="yes('Yes click')">Yes</button>
										<button type="button" class="btn btn-outline-danger"
											(click)="no('No click')">No</button>
									</div>
								</div>
							</div>
							</ng-template>


						</div>
					</div>
				</div>

			</div>
		</div>
	</div>

</body>

</html>