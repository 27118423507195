<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" id="{{pageId}}debitNoteRptId" *ngIf="isLoggedIn">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Debit Note Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">


											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="stmtNoWise">Statement
																Wise</option>
															<option value="dateWise">Date Wise</option>
														</select>
													</div>
												</div>
											</div>

											<div *ngIf="showStmtWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Statement No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}statementNo" name="statementNo"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>


											<div *ngIf="viewDestination" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="showDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="showDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															[(ngModel)]="toDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="showDateWise" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Debit Head</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}debitHead" type="text" class="form-control"
																[(ngModel)]="modelDebitHead"
																[ngbTypeahead]="searchDebitHead"
																(selectItem)="debitHeadListener($event)"
																[resultFormatter]="formatterDeebitHead"
																[inputFormatter]="formatterDeebitHead"
																(focus)="focusDebitHeadTA$.next($any($event).target.value)"
																placeholder="Select Debit" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewOtherDebit" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Sub Head</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}subHead" type="text" class="form-control"
																[(ngModel)]="modelDebitHeadOthers"
																[ngbTypeahead]="searchDebitHeadOthers"
																[resultFormatter]="formatterDebitHeadOthers"
																[inputFormatter]="formatterDebitHeadOthers"
																(focus)="focusDebitHeadOthersTA$.next($any($event).target.value)"
																placeholder="Select Sub Head" />
														</div>
													</div>
												</div>
											</div>



										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearMethod()">Clear</button>
						</div>
					</div>

					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<h6 class="card-title">Debit Note Report
											{{setTheDestOnTable}}</h6>

										<button type="submit" class="dt-button"
											style="margin-left: 92%;" (click)="customPrintdebitNote()"
											id="{{pageId}}printAllBtn">
											<span><i class="fas fa-print">Print</i></span>
										</button>
										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}debitNoteRptIdTable"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsDebitNoteRpt"
														[dtTrigger]="dtTriggerDebitNoteRpt">

														<thead>
															<tr>
																<th>Received Date</th>
																<th>Description</th>
																<th>Amount</th>
																<!-- 																<th>Statement Number</th> -->

															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let debitNoteRptData of debitNoteRptDataList ">
																<td>{{ debitNoteRptData.receivedDateStr }}</td>
																<td>{{ debitNoteRptData.description }}</td>
																<td>{{ debitNoteRptData.debit }}</td>
																<!-- 																<td>{{ debitNoteRptData.stmtNo }}</td> -->

															</tr>

														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<!-- 																<td></td> -->

															</tr>
														</tfoot>
													</table>


												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>

					</div>



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>