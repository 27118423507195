<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Station Type Info</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
									</span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            (click)="stationList()" placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-3">

                                                <hr style="width: 80%; border-top: none; margin: 3px;">
                                                <div class="col-md-12" style="text-align: center;">
                                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateApplyBtn()">Apply</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->

                        <br>
                        <div class="box-body">
                            <table datatable id="{{pageId}}routeMappingTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRouteMapping" [dtTrigger]="dtTriggerRouteMapping">
                                <thead>
                                    <tr>

                                        <th>Select</th>
                                        <th>Station Name</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr *ngFor="let dataprintcashMemoDtoForStationName of cashMemoDtoForStationNameList" style='font-size: 15px; margin-left: 100px;'>
                                        <td>
                                            <input type="checkbox" [checked]="multiSelect" id='selected' (change)="rowCheckBoxChecked($event, chequeDetailsReportData)" />
                                        </td>
                                  
                                        <td>{{cashMemoDtoForStationList.stationList}}</td>

                                    </tr> -->

                                    <tr>
                                        <td>
                                            <input type="checkbox" [checked]="multiSelect" id='selected' (change)="rowCheckBoxChecked($event, chequeDetailsReportData)" />
                                        </td>

                                        <td>Chennai</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>

    </div>



    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>