import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";

@Component({
    selector: 'app-bangalore-office',
    templateUrl: './bangalore-office.component.html',
    styleUrls: ['./bangalore-office.component.css']
})
export class BangaloreOfficeComponent implements OnInit {

    getDataAgetnsDueDetailsTable: any;
    getDataSupplierBalanceDetailsTable: any;
    getDataBankMaster: any;
    getDataPdcDetails: any;
    getDataEmailIdTable: any;

    agetnsDueDetailsDataList: any;
    agetnsDueDetailsDataListDto: FortNightStmtDto = new FortNightStmtDto();
    supplierBalanceDetailsDataList: any;
    bankMasterDataList: any;
    pdcDetailsDataList: any;
    emailIdDataList: any;

    onDestroyUnsubscribtionAgetnsDueDetails: Subscription;
    onDestroyUnsubscribtionSupplierBalanceDetails: Subscription;
    onDestroyUnsubscribtionBankMaster: Subscription;
    onDestroyUnsubscribtionPdcDetails: Subscription;
    onDestroyUnsubscribtionEmailId: Subscription;



    //for the select option with filter starts


    options = [
        { id: 1, label: 'South' },
        { id: 2, label: 'MH' },
    ];
    control = new FormControl();

    //for the select option with filter ends

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerAgetnsDueDetails: Subject<any> = new Subject();
    dtTriggerSupplierBalanceDetails: Subject<any> = new Subject();
    dtTriggerBankMaster: Subject<any> = new Subject();
    dtTriggerPdcDetails: Subject<any> = new Subject();
    dtTriggerEmailId: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsAgetnsDueDetails: any;
    dtOptionsSupplierBalanceDetails: any;
    dtOptionsBankMaster: any;
    dtOptionsPdcDetails: any;
    dtOptionsEmailId: any;

    selectedDate: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    lrDtoUserValue: LRDto = new LRDto();
    fortNightStmtDtoUserValue: FortNightStmtDto = new FortNightStmtDto();
    userDataDtoUserValue: UserDataDto = new UserDataDto();
    fortNightStmtDtoAddDues: FortNightStmtDto = new FortNightStmtDto();
    emailIds: any;
    listEmailIds: Array<String> = [];
    viewBngOfcCustomPrint: boolean;

    //summary
    oldDuesSummary: any;
    lastDuesSummary: any;
    currentDuesSummary: any;
    totalDuesSummary: any;
    totalPdcAmt: any;
    totalBankCash: any;
    totalBankChq: any;
    totalBankBal: any;
    totalBankProv: any;
    fortNightStmtDtoSummary: FortNightStmtDto = new FortNightStmtDto();

    partyMasterDtoExport: PartyMasterDto = new PartyMasterDto();
    pageId = "bngoc";
    constructor(private router: Router, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    ngOnInit(): void {
        //the first datatable starts
        this.dtOptionsAgetnsDueDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            pagingType: 'full_numbers',
            pageLength: 6,
            processing: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var oldDues = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var lastDues = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var currentDues = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(oldDues);
                $(api.column(3).footer()).html(lastDues);
                $(api.column(5).footer()).html(currentDues);
                $(api.column(7).footer()).html(totalAmt);
            }
        },
            //the first datatable ends
            //the second datatable starts 
            this.dtOptionsSupplierBalanceDetails = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                searching: false,
                pagingType: 'full_numbers',
                pageLength: 6,
                processing: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var balAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(balAmt);
                }
            },
            //the second datatable ends
            //the third datatable starts            
            this.dtOptionsBankMaster = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                searching: false,
                pagingType: 'full_numbers',
                pageLength: 6,
                processing: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var cash = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var chqAmt = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totBal = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var provChqAmt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(cash);
                    $(api.column(2).footer()).html(chqAmt);
                    $(api.column(3).footer()).html(totBal);
                    $(api.column(4).footer()).html(provChqAmt);

                }
            },
            //the third datatable ends
            //the fourth datatable starts
            this.dtOptionsPdcDetails = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                searching: false,
                pagingType: 'full_numbers',
                pageLength: 6,
                processing: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var balAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(balAmt);
                }
            },
            //the fourth datatable ends
            //the fifth datatable starts 
            this.dtOptionsEmailId = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                searching: false,
                pagingType: 'full_numbers',
                pageLength: 6,
                processing: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                "paging": false,
                "info": true
            }
        //the fifth datatable ends
    }

    ngOnDestroy(): void {
        this.dtTriggerAgetnsDueDetails.unsubscribe();
        this.dtTriggerSupplierBalanceDetails.unsubscribe();
        this.dtTriggerBankMaster.unsubscribe();
        this.dtTriggerPdcDetails.unsubscribe();
        this.dtTriggerEmailId.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerAgetnsDueDetails.next();
        this.dtTriggerSupplierBalanceDetails.next();
        this.dtTriggerBankMaster.next();
        this.dtTriggerPdcDetails.next();
        this.dtTriggerEmailId.next();
    }

    getDetails() {
        this.getAgentDueDetails();
        this.getBankBalanceDetails();
        this.getPDCDetails();
        this.getEmailIdDetails();
    }

    getAgentDueDetails = () => {
        this.showSpinnerForAction = true;
        this.selectedDate = $("#" + this.pageId + "selectDate").val();
        this.fortNightStmtDtoUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoUserValue.branchType = "Agent";
        this.fortNightStmtDtoUserValue.mainBranch = "South";
        this.fortNightStmtDtoUserValue.stmtDate = this.selectedDate;

        // this.destStmtReport.getDestAgentsDuesDetails(this.fortNightStmtDtoUserValue).subscribe(
        this.destStmtReport.getDestAgentsDuesDetailsNew(this.fortNightStmtDtoUserValue).subscribe(
            (response) => {
                $("#" + this.pageId + "agentDueTableId").DataTable().destroy();
                this.agetnsDueDetailsDataList = [];
                this.agetnsDueDetailsDataListDto = new FortNightStmtDto()
                if (response.stmtDtos.length == 0) {
                    swal({
                        title: "Bangalore Daily  Office Report",
                        text: "No Details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.agetnsDueDetailsDataListDto = response;
                    this.agetnsDueDetailsDataList = response.stmtDtos;
                }
                setTimeout(() => {
                    this.dtTriggerAgetnsDueDetails.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    };

    getBankBalanceDetails = () => {
        this.showSpinnerForAction = true;
        this.selectedDate = $("#" + this.pageId + "selectDate").val();
        this.userDataDtoUserValue = new UserDataDto();
        this.userDataDtoUserValue.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoUserValue.dateCreated = this.selectedDate;

        this.destStmtReport.getBankMasterDetailsForBngRpt(this.userDataDtoUserValue).subscribe(
            (response) => {
                $("#" + this.pageId + "bankBalTableId").DataTable().destroy();
                this.bankMasterDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Bangalore Daily  Office Report",
                        text: "No Bank Details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.bankMasterDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerBankMaster.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the bank details", "info");
            }, () => console.log('done');
    };

    getPDCDetails = () => {
        this.showSpinnerForAction = true;
        this.lrDtoUserValue = new LRDto();

        this.destStmtReport.getPdcRptDtls(this.lrDtoUserValue).subscribe(
            (response) => {
                $("#" + this.pageId + "pdcTableId").DataTable().destroy();
                this.pdcDetailsDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Bangalore Daily  Office Report",
                        text: "No PDC Details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.pdcDetailsDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerPdcDetails.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the pdc details", "info");
            }, () => console.log('done');
    };

    confirmFinish() {
        swal({
            title: "Confirmation",
            text: "Sure you want to finish the details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.addDestAgtDuesDailyUpdates();
                //console.log('dues : '+this.agetnsDueDetailsDataList);
                //console.log('dues : '+this.agetnsDueDetailsDataListDto.stmtDtos);
            }
        })
    }

    getUserValuesForDestAgtDuesDailyUpdated() {
        //console.log('dues : '+this.agetnsDueDetailsDataListDto.stmtDtos);
        this.fortNightStmtDtoAddDues = new FortNightStmtDto();
        this.fortNightStmtDtoAddDues.stmtDtos = this.agetnsDueDetailsDataListDto.stmtDtos;
        this.fortNightStmtDtoAddDues.userDataDtos = this.bankMasterDataList;
        this.fortNightStmtDtoAddDues.branchType = "Agent";
        this.fortNightStmtDtoAddDues.mainBranch = "South";
        this.fortNightStmtDtoAddDues.stmtDate = this.selectedDate;
        this.fortNightStmtDtoAddDues.createdBy = this.userDataDtoReturnSession.userId;
    }

    addDestAgtDuesDailyUpdates = () => {
        this.showSpinnerForAction = true;
        this.getUserValuesForDestAgtDuesDailyUpdated();

        this.destStmtReport.addDestAgtDuesDailyUpdated(this.fortNightStmtDtoAddDues).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Success", "Agent statement & bank details added/updated successfully!", "info");

                } else if (response.status == "Failed") {
                    swal("Failed", "Failed to add/update the details!", "info");

                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the details", "info");
            }, () => console.log('done');
    };

    getEmailIdDetails = () => {
        if (this.userDataDtoReturnSession.allEmailId != null) {
            this.emailIds = this.userDataDtoReturnSession.allEmailId.split("#");
            for (let i = 0; i < this.emailIds.length; i++) {
                this.listEmailIds.push(this.emailIds[i]);
            }
        }
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    prepareDetails() {
        this.oldDuesSummary = 0;
        this.lastDuesSummary = 0;
        this.currentDuesSummary = 0;
        this.totalDuesSummary = 0;
        this.totalPdcAmt = 0;
        this.totalBankCash = 0;
        this.totalBankChq = 0;
        this.totalBankBal = 0;
        this.totalBankProv = 0;

        this.fortNightStmtDtoSummary = new FortNightStmtDto();
        this.fortNightStmtDtoSummary.oldDuesFormatted = this.agetnsDueDetailsDataListDto.netOldDues;
        this.fortNightStmtDtoSummary.lastDuesFormatted = this.agetnsDueDetailsDataListDto.netLastDues;
        this.fortNightStmtDtoSummary.currentDuesFormatted = this.agetnsDueDetailsDataListDto.netCurrentDues;
        this.fortNightStmtDtoSummary.totalDuesFormatted = this.agetnsDueDetailsDataListDto.netTotDues;
        for (let i = 0; i < this.bankMasterDataList.length; i++) {
            this.totalBankCash += +(this.bankMasterDataList[i].paymentAmt == null ? 0 : this.bankMasterDataList[i].paymentAmt);;
            this.totalBankChq += +(this.bankMasterDataList[i].paymentAmt == null ? 0 : this.bankMasterDataList[i].chqAmt);
            this.totalBankBal += +(this.bankMasterDataList[i].paymentAmt == null ? 0 : this.bankMasterDataList[i].totalAmount);
            this.totalBankProv += +(this.bankMasterDataList[i].paymentAmt == null ? 0 : this.bankMasterDataList[i].proviAmt);
        }
        this.fortNightStmtDtoSummary.cashAmt = this.totalBankCash;
        this.fortNightStmtDtoSummary.chequeAmt = this.totalBankChq;
        this.fortNightStmtDtoSummary.totalAmount = this.totalBankBal;
        this.fortNightStmtDtoSummary.pdcAmt = this.totalBankProv;

        for (let i = 0; i < this.pdcDetailsDataList.length; i++) {
            this.totalPdcAmt += +(this.pdcDetailsDataList[i].paid == null ? 0 : this.pdcDetailsDataList[i].paid);
        }
        this.fortNightStmtDtoSummary.totPdcAmt = this.totalPdcAmt;

        localStorage.clear();
        localStorage.setItem('selectedDate', this.selectedDate);
        localStorage.setItem('agetnsDueDetailsDataListCustomPrint', JSON.stringify(this.agetnsDueDetailsDataList));
        localStorage.setItem('bankMasterDataListCustomPrint', JSON.stringify(this.bankMasterDataList));
        localStorage.setItem('pdcDetailsDataListCustomPrint', JSON.stringify(this.pdcDetailsDataList));
        localStorage.setItem('fortNightStmtDtoSummaryPrint', JSON.stringify(this.fortNightStmtDtoSummary));
    }
    printDetails() {
        this.prepareDetails();
        this.viewBngOfcCustomPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewBngOfcCustomPrint) {
                this.viewBngOfcCustomPrint = false;
                localStorage.clear();
            }
        });
    }

    exportDetails() {
        this.partyMasterDtoExport = new PartyMasterDto();
        this.partyMasterDtoExport.stmtDtos = this.agetnsDueDetailsDataList;
        this.partyMasterDtoExport.userDataDtos = this.bankMasterDataList;
        this.partyMasterDtoExport.lrDtos = this.pdcDetailsDataList;
        this.partyMasterDtoExport.fortNightStmtDto = this.fortNightStmtDtoSummary;
        this.partyMasterDtoExport.entryDate = this.selectedDate;

        this.destStmtReport.excelForBngDailyOfcRpt(this.partyMasterDtoExport).subscribe(
            (response) => {
                if (response.status == "Failed") {
                    swal("Failed", "Failed to export the details!", "info");
                } else {
                    //window.open('/assets/dowloadtemp/'+response.status, '_blank');
                    //window.open('/assets/dowloadtemp/BangaloreOfcDailyReport.xlsx', '_blank');
                    swal("Success", "Export done successfully!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the details", "info");
            }, () => console.log('done');
    }

    downloadMyFile() {
        /*const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'D:/zip_files/BangaloreOfcDailyReport.xlsx');
        link.setAttribute('download', 'BangaloreOfcDailyReport.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();*/
        window.open('/assets/dowloadtemp/BangaloreOfcDailyReport.xlsx', '_blank');
        //window.open('file:///d:\zip_files\BangaloreOfcDailyReport.xlsx', '_blank');
    }
}
