<div mat-dialog-content id="{{pageId}}labourStmtDetailsvisible">
    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Amount Paid To {{Statement}}</h6>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="card-title">Labour Statement Details</h6>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}destination" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}amount" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Paying Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="payingDates" ngbDatepicker #payingDates="ngbDatepicker" id="{{pageId}}payingDates" [(ngModel)]="modelPayingDates">
                                                        <div class="input-group-append" (click)="payingDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 vl p-t-10">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="card-title">Payment Mode</h6>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Mode :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-weight"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}payemntModeType" #payemntModeType (change)="payemntModeTypeListner(payemntModeType.value)">
															<option value="Cash">Cash
															</option>
															<option selected  value="Cheque">Cheque</option>
															<option value="PDC">PDC</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcNoField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}chequePdcNo" aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequeDueDateField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Due Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="chequeDueDates" ngbDatepicker #chequeDueDates="ngbDatepicker" id="{{pageId}}chequeDueDates" [(ngModel)]="modelChequeDueDates">
                                                        <div class="input-group-append" (click)="chequeDueDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #bankName id="{{pageId}}bankNameId" type="text" class="form-control" (selectItem)="clickListnerForBankName($event)" [(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                                                (focus)="focusBankNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="viewCashChequePdcAmtField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}cashChequePdcAmount" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForAmount($event);">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <button type="submit" class="btn btn-dark m-r-10" title="Clear Bank Details" style='float:right;' (click)="clearBankDetailsInTable();">Clear
												</button>&nbsp;&nbsp;
                                                <button type="submit" class="btn btn-success m-r-10" style='float:right;' title="Add Bank Details" (click)="addBankDetailsInTable();">Add
												</button>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <table datatable id="{{pageId}}bkgAgentStmtCashChequePdcTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBkgAgentStmtCashChequePdc" [dtTrigger]="dtTriggerBkgAgentStmtCashChequePdc">
                                                    <thead>
                                                        <tr>
                                                            <th>Payment Type</th>
                                                            <th>Cheque No</th>
                                                            <th>Amount</th>
                                                            <th>Bank Name</th>
                                                            <th>Due Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let bkgAgentStmtCashChequePdcData of 
														bkgAgentStmtCashChequePdcDataList let i = index">
                                                            <td>
                                                                {{ bkgAgentStmtCashChequePdcData.paymentType }}</td>
                                                            <td>{{ bkgAgentStmtCashChequePdcData.chequeNumber }}</td>
                                                            <td>{{ bkgAgentStmtCashChequePdcData.amount }}</td>
                                                            <td>{{ bkgAgentStmtCashChequePdcData.bankName }}</td>
                                                            <td>{{ bkgAgentStmtCashChequePdcData.pdcDueDate }}</td>
                                                            <td>
                                                                <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Bank Details" (click)="rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData,i);"></i> &nbsp;&nbsp;
                                                                <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Bank Details" (click)="deleteSelectedBankDetails(i);"></i>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 vl p-t-10">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h6 class="card-title">Summary</h6>
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Total Amount To Be Paid</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}totalAmountToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cash</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}summaryCashAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}summaryChequeAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>PDC</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}summaryPDCAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Balance To Be Paid</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}balanceToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <button type="submit" class="btn btn-dark" style='float:right;' title="Clear Bank Details" (click)="overAllClearFieldBtn();">Clear
												</button> &nbsp;&nbsp;
                                                <button type="submit" class="btn btn-success" title="Confirme Payment Details" style='float:right;' (click)="validateConfirmPaymentBtn();">Confirm
													Payment
												</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions style="float: right;" id="{{pageId}}printvisible">
        <button class="btn btn-outline-danger" mat-button (click)="onCancelClick()">Cancel</button>
    </div>
    <div *ngIf="viewPaymentVoucherPrint" onafterprint="afterPrint()" id="{{pageId}}viewPaymentVoucherRptV1">
        <app-payment-voucher-loading-unloading-print></app-payment-voucher-loading-unloading-print>
    </div>
    <div *ngIf="viewPaymentVoucherUnloadingPrint" onafterprint="afterPrint()" id="{{pageId}}viewPaymentVoucherRptUnloading">
        <app-payment-voucher-loading-unloading-print></app-payment-voucher-loading-unloading-print>
    </div>

</div>