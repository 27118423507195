<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Area Master</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-10">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Area Name *</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clipboard"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" aria-describedby="basic-addon11"
                                                id="{{pageId}}areaName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="text-align: center;">
                                    <button #saveBtn type="submit" class="btn btn-success m-r-10"
                                        (click)="confirmSave()" id="{{pageId}}saveBtn">Save</button>
                                    <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                        (click)="clearAll()">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 vl">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}areaDatatableId"
                                        class="table table-striped table-bordered row-border hover"
                                        [dtOptions]="dtOptionsArea" [dtTrigger]="dtTriggerArea">
                                        <thead>
                                            <tr>
                                                <th>Area Name</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let areaData of areaDataList">
                                                <td>{{ areaData.subStations }}</td>
                                                <td>{{ areaData.createdDateStr }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>