import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import {NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MemoReport } from 'src/app/dataService/memo-report';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component({
	selector: 'app-party-wise-os-report',
	templateUrl: './party-wise-os-report.component.html',
	styleUrls: ['./party-wise-os-report.component.css']
})
export class PartyWiseOsReportComponent implements OnInit {

	gettingDataFrmServiceFrSmryTable: any;
	gettingDataFrmServiceFrDetailTable: any;
	gettingDataFrmServiceFrOverallSmryTable: any;

	summaryDataList: any;
	detailDataList: any;
	overallSummaryDataList: any;

	onDestroyUnsubscribtionSmry: Subscription;
	onDestroyUnsubscribtionDetail: Subscription;
	onDestroyUnsubscribtionOverallSmry: Subscription;

	model: NgbDateStruct;
	model2;
	loadingIndicator = true;
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	viewTypeSummary = true;
	viewTypeDetail = false;
	viewTypeOverallSummary = false;
	summaryTable = true;
	detailTable = false;
	overallSummaryTable = false;
	asOnDateModel: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeName: any;
	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	//
	//for collection Man
	collectionManAllOption: UserDataDto = new UserDataDto();
	collectionManTA: Array<UserDataDto> = [];
	focusCollectionManTA$ = new Subject<string>();
	collectionManSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCollectionManTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.collectionManTA
				: this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
	collectionManDataList: any;

	//collection Man
	/*collectionManDataList: any;
	collectionManTA1: Array<any> = [];
	focusCMTA$ = new Subject<string>();
	cmSearchTA = ( text$: Observable<string> ) => {
		const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
		const inputFocus$ = this.focusCMTA$;
	
		return merge( debouncedText$, inputFocus$ ).pipe(
			map( term => ( term === '' ? this.collectionManTA
				:this.collectionManTA.filter(v => v.collectionMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	*/
	////Area
	lrDtoBranchArea: LRDto = new LRDto();
	lrDtoAreaAll: LRDto = new LRDto();
	branchAreaDataList: any;
	areaNameTA: Array<LRDto> = [];
	focusAreaNameTA$ = new Subject<string>();
	areaNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAreaNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.areaNameTA
				: this.areaNameTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAreaName = (x: { godownName: string }) => x.godownName;
	//
	modelCollectionMan: any;
	modelAreaName: any;
	userDtoCollectionMan: UserDataDto = new UserDataDto();
	userDtoCollectionManAll: UserDataDto = new UserDataDto();
	selectedAsOnDate: any;
	selectedViewType: any;
	searchBySummaryCashmemodto = new CashMemoDto();
	selectedCollectionMan: any;
	selectedAreaName: any;
	selectedConsigneeId: any;
	public modelConsigneeNameId: any;
	searchByDetailedCashmemodto = new CashMemoDto();
	searchByOverAllSummaryCashmemodto = new CashMemoDto();
	hideshowForKondiTransToCol = false;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	memoDtoForGCNotRecvPopUp = new CashMemoDto();
	memoDtoForSRDCollectionManStockRpt = new CashMemoDto();
	@ViewChild('gcnoteReceivedPopUpTemplate') private gcnoteReceivedPopUpTemplate;

	dtTriggerSummary: Subject<any> = new Subject();
	dtTriggerDetail: Subject<any> = new Subject();
	dtTriggerOverallSummary: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsSummary: any;
	dtOptionDetail: any;
	dtOptionsOverallSummary: any;
	searchByDetailedCashmemodtoPopup = new CashMemoDto();
	detailsFromPartyWiseTotalStatusRptPopup = new CashMemoDto();
	dataForPopup: any;

	//for grouping print 
	cashMemoDtoForGroupingPrintList = new CashMemoDto();
	cashMemoDtoForGrouupingPrintDataList: any;
	viewGroupingPrintPartywiseOs = false;
	//for custom print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	viewCustomPrintV1 = false;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	totalMemosummary: number;
	totalAmountsummary: number;
	articlesummary: number;
	memoAmtsummary: number;
	gcNotRecAmtsummary: number;
	trnsToCollMemosummary: number;
	trnsToCollAmtsummary: number;
	srdStockMemosummary: number;
	srdStockAmtsummary: number;
	colManSumMemosummary: number;
	colManSumAmtsummary: number;
	pageId = "pwosr";

	constructor(private memoLessRpt: ReportService, private router: Router,
		private memoReport: MemoReport, private masterReadService: MasterReadService, private modalService: NgbModal, private lrReportService: LrReportService, private consigneeService: ConsigneeService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getConsigneeDetails();
			this.getCollectionManDetailsList();
			this.getBranchAreaDetailsList();
			if (this.userDataDtoReturnSession.office != null &&
				this.userDataDtoReturnSession.office == "Konditope") {
				this.hideshowForKondiTransToCol = true;
			} else {
				this.hideshowForKondiTransToCol = false;
			}
			this.dataForPopup = JSON.parse(localStorage.getItem('memoDtoForPartyWiseOsReportPopup'));
			localStorage.clear();
			if (this.dataForPopup != null) {
				this.detailsFromPartyWiseTotalStatusRptPopup = new CashMemoDto();
				this.detailsFromPartyWiseTotalStatusRptPopup = this.dataForPopup;
				if (this.detailsFromPartyWiseTotalStatusRptPopup != null) {
					this.searchByDetailedMethodPopup();
				}
			}
			this.dataForPopup = '';

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				// Do your stuff
				//console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
				if (dtInstance.table().node().id == "summaryTableId") {
					console.log("a");
					this.summaryDataList = [];
					dtInstance.destroy();
					this.dtTriggerSummary.next();
				} else if (dtInstance.table().node().id == "detailsTableId") {
					console.log("b");
					this.detailDataList = [];
					dtInstance.destroy();
					this.dtTriggerDetail.next();
				} else if (dtInstance.table().node().id == "overallSummaryTableId") {
					console.log("c");
					this.overallSummaryDataList = [];
					dtInstance.destroy();
					this.dtTriggerOverallSummary.next();
				}
			});
		});
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var showOverAllSummary = false;
		if (this.hideshowForKondiTransToCol == true) {
			showOverAllSummary = true;
		} else {
			showOverAllSummary = false;
		}

		this.dtOptionsSummary = {

			dom: 'Bfrtip',
			buttons: [

				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "PartyWise OS Report - " +
							"As On : " + moment($("#pwosrasOnDate").val()).format('DD-MM-YYYY');
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 320,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalMemos = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var grandTotal = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totalArticles = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total :' + data.length);
				$(api.column(1).footer()).html(totalMemos);
				$(api.column(2).footer()).html(grandTotal);
				$(api.column(3).footer()).html(totalArticles);
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();

			}

		},

			this.dtOptionDetail = {

				dom: 'Bfrtip',

				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "PartyWise OutStanding Details Report - " +
								"As On : " + moment($("#pwosrasOnDate").val()).format('DD-MM-YYYY');
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 320,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var grandTotal = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var totalArticles = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html(grandTotal);
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(totalArticles);
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();

				}
			},

			this.dtOptionsOverallSummary = {

				dom: 'Bfrtip',

				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "PartyWise OutStanding OverAll Summary  Report - " +
								"As On : " + moment($("#pwosrasOnDate").val()).format('DD-MM-YYYY');
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 320,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					if (showOverAllSummary == true) {
						var billingMemos = api.column(0).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var billingTotal = api.column(1).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var transToCollMemos = api.column(2).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var transToCollTotal = api.column(3).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var transToCollFrmBinnyMemos = api.column(4).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var transToCollFrmBinnyTotal = api.column(5).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var srdStockMemos = api.column(6).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var srdStockTotal = api.column(7).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var collManStockMemos = api.column(8).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var collManStockTotal = api.column(9).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);


						$(api.column(0).footer()).html('Total : ' + billingMemos);
						$(api.column(1).footer()).html(billingTotal);
						$(api.column(2).footer()).html(transToCollMemos);
						$(api.column(3).footer()).html(transToCollTotal);
						$(api.column(4).footer()).html(transToCollFrmBinnyMemos);
						$(api.column(5).footer()).html(transToCollFrmBinnyTotal);
						$(api.column(6).footer()).html(srdStockMemos);
						$(api.column(7).footer()).html(srdStockTotal);
						$(api.column(8).footer()).html(collManStockMemos);
						$(api.column(9).footer()).html(collManStockTotal);


					} else {
						var billingMemos = api.column(0).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var billingTotal = api.column(1).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var transToCollMemos = api.column(2).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var transToCollTotal = api.column(3).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var srdStockMemos = api.column(4).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var srdStockTotal = api.column(5).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);
						var collManStockMemos = api.column(6).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						var collManStockTotal = api.column(7).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
							}, 0);

						$(api.column(0).footer()).html('Total : ' + billingMemos);
						$(api.column(1).footer()).html(billingTotal);
						$(api.column(2).footer()).html(transToCollMemos);
						$(api.column(3).footer()).html(transToCollTotal);
						$(api.column(4).footer()).html(srdStockMemos);
						$(api.column(5).footer()).html(srdStockTotal);
						$(api.column(6).footer()).html(collManStockMemos);
						$(api.column(7).footer()).html(collManStockTotal);


					}




				}
			}
	}

	ngOnDestroy(): void {
		this.dtTriggerSummary.unsubscribe();
		this.dtTriggerDetail.unsubscribe();
		this.dtTriggerOverallSummary.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerSummary.next();
		this.dtTriggerDetail.next();
		this.dtTriggerOverallSummary.next();

	}



	//datatablr hide / show        
	viewTypeMode(viewType: string) {
		if (viewType === 'summaryView') {
			this.viewTypeSummary = true;
			this.viewTypeDetail = false;
			this.viewTypeOverallSummary = false;
			this.summaryTable = true;
			this.detailTable = false;
			this.overallSummaryTable = false;
			$("#" + this.pageId + "summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#" + this.pageId + "detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
			this.overallSummaryDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerOverallSummary.next();


		} else if (viewType === 'detailView') {
			this.viewTypeSummary = false;
			this.viewTypeDetail = true;
			this.viewTypeOverallSummary = false;
			this.summaryTable = false;
			this.detailTable = true;
			this.overallSummaryTable = false;
			$("#" + this.pageId + "summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#" + this.pageId + "detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
			this.overallSummaryDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerOverallSummary.next();

		} else if (viewType === 'overallSummaryView') {
			this.viewTypeSummary = false;
			this.viewTypeDetail = false;
			this.viewTypeOverallSummary = true;
			this.summaryTable = false;
			this.detailTable = false;
			this.overallSummaryTable = true;
			$("#" + this.pageId + "summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#" + this.pageId + "detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
			this.overallSummaryDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerOverallSummary.next();

		} else {
			this.viewTypeSummary = false;
			this.viewTypeDetail = false;
			this.viewTypeOverallSummary = false;
			this.summaryTable = true;
			this.detailTable = false;
			this.overallSummaryTable = false;
			$("#" + this.pageId + "summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#" + this.pageId + "detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
			this.overallSummaryDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerOverallSummary.next();
		}
	}


	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	getConsigneeDetailsList() {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = () => {
		this.getConsigneeDetailsList();

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					$("#" + this.pageId + "consigneeName").val('All');
					console.log(this.consigneeNameTA);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	getDetailsForCollectionMasterRead() {
		this.userDtoCollectionMan = new UserDataDto();
		this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}

	getCollectionManDetailsList() {
		this.collectionManDataList = [];
		this.collectionManTA = [];
		this.getDetailsForCollectionMasterRead();
		this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
			(response) => {
				if (response) {
					console.log(response);
					this.collectionManDataList = response;
					console.log(this.collectionManDataList);
					this.collectionManTA = [];
					this.userDtoCollectionManAll.collectioMan = "All";
					this.collectionManTA.push(this.userDtoCollectionManAll);
					for (let i = 0; i < this.collectionManDataList.length; i++) {
						this.collectionManTA.push(this.collectionManDataList[i]);
					}
					$("#" + this.pageId + "collectionMan").val('All');
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Server Error",
				text: "Problem occur while getting Collection Man Details",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};

	getMethodForBranchArea() {
		this.lrDtoBranchArea = new LRDto();
		this.lrDtoBranchArea.branch = this.userDataDtoReturnSession.office;
		this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
		this.lrDtoBranchArea.companyId = this.userDataDtoReturnSession.companyId;

	}

	getBranchAreaDetailsList() {
		this.branchAreaDataList = [];
		this.areaNameTA = [];
		this.getMethodForBranchArea();
		this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchArea).subscribe(
			(response) => {
				if (response) {
					console.log(response);
					this.branchAreaDataList = response;
					console.log(this.branchAreaDataList);
					this.lrDtoAreaAll.godownName = "All";
					this.areaNameTA.push(this.lrDtoAreaAll);
					for (let i = 0; i < this.branchAreaDataList.length; i++) {
						this.areaNameTA.push(this.branchAreaDataList[i]);
					}
					$("#" + this.pageId + "areaName").val('All');
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => swal({
				title: "Server Error",
				text: "Problem occur while getting Area Details",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};

	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}

	searchMethod() {

		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.selectedViewType = $("#" + this.pageId + "viewType").val();
		if (this.selectedAsOnDate == null || this.selectedAsOnDate == '') {
			swal({
				title: "Mandatory Field",
				text: "Please select Date",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			if (this.selectedViewType == 'summaryView') {
				this.searchBySummaryMethod();
			} else if (this.selectedViewType == 'detailView') {
				this.searchByDetailedMethod();
			} else if (this.selectedViewType == 'overallSummaryView') {
				this.searchByOverAllSummaryMethod();
			}

		}
	}
	searchBySummaryMethodDetails() {
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedAreaName = $("#" + this.pageId + "areaName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		console.log(this.selectedConsigneeName);
		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.searchBySummaryCashmemodto = new CashMemoDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchBySummaryCashmemodto.consigneeName = this.selectedConsigneeName;
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
			this.searchBySummaryCashmemodto.consigneeId = this.selectedConsigneeId;
		} else {
			this.searchBySummaryCashmemodto.consigneeId = 0;
			this.searchBySummaryCashmemodto.consigneeName = 'ALL';
		}

		this.searchBySummaryCashmemodto.fromdate = this.selectedAsOnDate;
		this.searchBySummaryCashmemodto.collectionMan = this.selectedCollectionMan;
		this.searchBySummaryCashmemodto.area = this.selectedAreaName;
		this.searchBySummaryCashmemodto.destination = this.userDataDtoReturnSession.mainStation;
		this.searchBySummaryCashmemodto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchBySummaryCashmemodto);
	}

	searchBySummaryMethod = () => {
		this.searchBySummaryMethodDetails();
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyOSDetailsSummaryWise(this.searchBySummaryCashmemodto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "summaryTableId").DataTable().destroy();
				this.summaryDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.summaryDataList = response;
					console.log(this.summaryDataList);
				}
				this.dtTriggerSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Summary details", "info");
			}, () => console.log('done');
	};
	searchByDetailedMethodDetails() {
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedAreaName = $("#" + this.pageId + "areaName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		console.log(this.selectedConsigneeName);
		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.searchByDetailedCashmemodto = new CashMemoDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchByDetailedCashmemodto.consigneeName = this.selectedConsigneeName;
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
			this.searchByDetailedCashmemodto.consigneeId = this.selectedConsigneeId;
		} else {
			this.searchByDetailedCashmemodto.consigneeId = 0;
			this.searchByDetailedCashmemodto.consigneeName = 'ALL';
		}

		this.searchByDetailedCashmemodto.fromdate = this.selectedAsOnDate;
		this.searchByDetailedCashmemodto.collectionMan = this.selectedCollectionMan;
		this.searchByDetailedCashmemodto.area = this.selectedAreaName;
		this.searchByDetailedCashmemodto.destination = this.userDataDtoReturnSession.mainStation;
		this.searchByDetailedCashmemodto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchByDetailedCashmemodto);
	}

	searchByDetailedMethod = () => {
		this.searchByDetailedMethodDetails();
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyOSDetailsWise(this.searchByDetailedCashmemodto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "detailsTableId").DataTable().destroy();
				this.detailDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.detailDataList = response;
					console.log(this.detailDataList);
				}
				this.dtTriggerDetail.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise Detailed View", "info");
			}, () => console.log('done');
	};


	searchByOverAllSummaryDetails() {
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedAreaName = $("#" + this.pageId + "areaName").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		console.log(this.selectedConsigneeName);
		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.searchByOverAllSummaryCashmemodto = new CashMemoDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchByOverAllSummaryCashmemodto.consigneeName = this.selectedConsigneeName;
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
			this.searchByOverAllSummaryCashmemodto.consigneeId = this.selectedConsigneeId;
		} else {
			this.searchByOverAllSummaryCashmemodto.consigneeId = 0;
			this.searchByOverAllSummaryCashmemodto.consigneeName = 'ALL';
		}

		this.searchByOverAllSummaryCashmemodto.fromdate = this.selectedAsOnDate;
		this.searchByOverAllSummaryCashmemodto.collectionMan = this.selectedCollectionMan;
		this.searchByOverAllSummaryCashmemodto.area = this.selectedAreaName;
		this.searchByOverAllSummaryCashmemodto.destination = this.userDataDtoReturnSession.mainStation;
		this.searchByOverAllSummaryCashmemodto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchByOverAllSummaryCashmemodto);
	}

	searchByOverAllSummaryMethod = () => {
		this.searchByOverAllSummaryDetails();
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyOSOverAllSummaryView(this.searchByOverAllSummaryCashmemodto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
				this.overallSummaryDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.overallSummaryDataList = response;
					console.log(this.overallSummaryDataList);
				}
				this.dtTriggerOverallSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise OverAll Summary View", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		$("#" + this.pageId + "consigneeName").val('All');
		$("#" + this.pageId + "areaName").val('All');
		$("#" + this.pageId + "collectionMan").val('All');
		this.selectedCollectionMan = '';
		this.selectedAreaName = '';
		this.selectedConsigneeName = '';
		this.selectedAsOnDate = '';
		$("#" + this.pageId + "asOnDate").val('');
		this.searchBySummaryCashmemodto = new CashMemoDto();
		this.searchByOverAllSummaryCashmemodto = new CashMemoDto();
		this.searchByDetailedCashmemodto = new CashMemoDto();
		this.viewTypeSummary = true;
		this.summaryTable = true;
		this.detailTable = false;
		this.overallSummaryTable = false;
		$("#" + this.pageId + "summaryTableId").DataTable().destroy();
		this.summaryDataList = [];
		$("#" + this.pageId + "detailsTableId").DataTable().destroy();
		this.detailDataList = [];
		$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
		this.overallSummaryDataList = [];
		this.dtTriggerSummary.next();
		this.dtTriggerDetail.next();
		this.dtTriggerOverallSummary.next();
		this.asOnDateModel = '';
		this.selectedViewType = '';
		$("#" + this.pageId + "viewType").val('summaryView');
		this.showSpinnerForAction = false;
		this.selectedConsigneeId = '';
		this.modelConsigneeNameId = '';
		this.searchByDetailedCashmemodtoPopup = new CashMemoDto();
		this.detailsFromPartyWiseTotalStatusRptPopup = new CashMemoDto();
		this.dataForPopup = '';
		localStorage.clear();
	}


	getGcNotRec(overallSummaryDataL) {
		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.memoDtoForGCNotRecvPopUp = new CashMemoDto();
		this.memoDtoForGCNotRecvPopUp.mode = 'All';
		this.memoDtoForGCNotRecvPopUp.tillDate = this.selectedAsOnDate;
		localStorage.clear();
		localStorage.setItem('gcNotRecReportPopUp',
			JSON.stringify(this.memoDtoForGCNotRecvPopUp));
		//  window.location('/report/lr-report/gcNotReceivedReport');
		this.router.navigate(['/report/lr-report/gcNotReceivedReport']);
	}

	getOsRpt(overallSummaryDataL) {
		this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
		this.memoDtoForSRDCollectionManStockRpt = new CashMemoDto();
		this.memoDtoForSRDCollectionManStockRpt.mode = 'date';
		this.memoDtoForSRDCollectionManStockRpt.tillDateMode = "tillDate";
		this.memoDtoForSRDCollectionManStockRpt.fromdate = this.selectedAsOnDate;
		localStorage.clear();
		localStorage.setItem('srdCollectionManStockReport',
			JSON.stringify(this.memoDtoForSRDCollectionManStockRpt));
		this.router.navigate(['/report/cashmemo-report/srdCollectionStockReport']);
		// window.location('/report/cashmemo-report/srdCollectionStockReport');
	}

	searchByDetailedMethodDetailsPopUp() {
		this.viewTypeSummary = false;
		this.viewTypeDetail = true;
		this.viewTypeOverallSummary = false;
		this.summaryTable = false;
		this.detailTable = true;
		this.overallSummaryTable = false;

		$("#" + this.pageId + "summaryTableId").DataTable().destroy();
		this.summaryDataList = [];
		$("#" + this.pageId + "detailsTableId").DataTable().destroy();
		this.detailDataList = [];
		$("#" + this.pageId + "overallSummaryTableId").DataTable().destroy();
		this.overallSummaryDataList = [];
		this.dtTriggerSummary.next();
		this.dtTriggerDetail.next();
		this.dtTriggerOverallSummary.next();
		this.selectedConsigneeName = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeName;
		this.selectedAsOnDate = this.detailsFromPartyWiseTotalStatusRptPopup.fromdate;
		this.selectedConsigneeId = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeId;
		this.selectedCollectionMan = 'All'
		this.selectedAreaName = 'All'
		this.searchByDetailedCashmemodtoPopup = new CashMemoDto();
		this.searchByDetailedCashmemodtoPopup.consigneeName = this.selectedConsigneeName;
		this.searchByDetailedCashmemodtoPopup.consigneeId = this.selectedConsigneeId;
		this.searchByDetailedCashmemodtoPopup.fromdate = this.selectedAsOnDate;
		this.searchByDetailedCashmemodtoPopup.collectionMan = this.selectedCollectionMan;
		this.searchByDetailedCashmemodtoPopup.area = this.selectedAreaName;
		this.searchByDetailedCashmemodtoPopup.destination = this.detailsFromPartyWiseTotalStatusRptPopup.destination;
		this.searchByDetailedCashmemodtoPopup.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchByDetailedCashmemodtoPopup);
	}

	searchByDetailedMethodPopup = () => {
		this.searchByDetailedMethodDetailsPopUp();
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyOSDetailsWise(this.searchByDetailedCashmemodtoPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "collectionMan").val('All');
				$("#" + this.pageId + "areaName").val('All');
				this.selectedConsigneeName = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeName;
				$("#" + this.pageId + "consigneeName").val(this.selectedConsigneeName);
				console.log(this.selectedConsigneeName);
				$("#" + this.pageId + "asOnDate").val(this.searchByDetailedCashmemodtoPopup.fromdate);
				$("#" + this.pageId + "detailsTableId").DataTable().destroy();
				this.detailDataList = [];
				$("#" + this.pageId + "viewType").val('detailView');
				setTimeout(() => {
					this.setConsignee();
				},
					5000);
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.detailDataList = response;
					console.log(this.detailDataList);
				}
				this.dtTriggerDetail.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise Detailed View", "info");
			}, () => console.log('done');
	};

	setConsignee() {
		$("#" + this.pageId + "consigneeName").val(this.detailsFromPartyWiseTotalStatusRptPopup.consigneeName);

	}

	customPrintPartyWiseOsSumRpt() {
		if (this.summaryDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Party Name", "Total Memo", "Total Amount", "Art", "Area", "Collection Man"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [30, 13, 13, 10, 17, 17];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totalMemosummary = this.totalAmountsummary = this.articlesummary = 0;
			for (let i = 0; i < this.summaryDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].consigneeName, this.summaryDataList[i].totalMemos, this.summaryDataList[i].grandTotal, this.summaryDataList[i].totalArticles,
				this.summaryDataList[i].area, this.summaryDataList[i].collectionMan];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.totalMemosummary = this.totalMemosummary + this.summaryDataList[i].totalMemos;
				this.totalAmountsummary = this.totalAmountsummary + this.summaryDataList[i].grandTotal;
				this.articlesummary = this.articlesummary + this.summaryDataList[i].totalArticles;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, this.totalMemosummary, this.totalAmountsummary, this.articlesummary, "", ""];
			}
			//heading logics For Date

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchBySummaryCashmemodto.fromdate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "PartyWise OS Suumary Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
	customPrintPartyWiseOsDetailRpt() {
		if (this.detailDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Party", "Memo Number", "LR No", "Memo Amount", "Booking Date", "No Of Articles", "Memo Date", "Cur Date-Memo Date", "Area", "Collection", "Station"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [17, 15, 10, 8, 9, 8, 8, 9, 8, 8, 6];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.memoAmtsummary = this.articlesummary = 0;
			for (let i = 0; i < this.detailDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].consignee, this.detailDataList[i].memoNumber, this.detailDataList[i].lrNumber, this.detailDataList[i].amount,
				this.detailDataList[i].bookingDateStr, this.detailDataList[i].article, this.detailDataList[i].memoDateStr, this.detailDataList[i].days, this.detailDataList[i].area,
				this.detailDataList[i].collectionMan, this.detailDataList[i].status];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.memoAmtsummary = this.memoAmtsummary + this.detailDataList[i].amount;

				this.articlesummary = this.articlesummary + this.detailDataList[i].article;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", this.memoAmtsummary, "", this.articlesummary, "", "", "", "", ""];
			}
			//heading logics For Date

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchByDetailedCashmemodto.fromdate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "PartyWise OS Detail Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}



	customPrintPartyWiseOsOvrAllRpt() {
		if (this.overallSummaryDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["GC Not Rec(Memo)", "GC Not Rec(Amt)", "Transfer To Collection(Memo)", "Transfer To Collection(Amt)", "Srd Stock(Memo)", "Srd Stock(Amt)", "Collection Man Summary(Memo)", "Collection Man Summary(Amt)"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 15, 15, 10, 10, 15, 15];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.gcNotRecAmtsummary = this.trnsToCollMemosummary = this.trnsToCollAmtsummary =
				this.srdStockMemosummary = this.srdStockAmtsummary = this.colManSumMemosummary = this.colManSumAmtsummary = 0;
			for (let i = 0; i < this.overallSummaryDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.overallSummaryDataList[i].billingMemos, this.overallSummaryDataList[i].billingTotal, this.overallSummaryDataList[i].transToCollMemos, this.overallSummaryDataList[i].transToCollTotal, this.overallSummaryDataList[i].srdStockMemos, this.overallSummaryDataList[i].srdStockTotal,
				this.overallSummaryDataList[i].collManStockMemos, this.overallSummaryDataList[i].collManStockTotal];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.gcNotRecAmtsummary = this.gcNotRecAmtsummary + this.overallSummaryDataList[i].billingTotal;
				this.trnsToCollMemosummary = this.trnsToCollMemosummary + this.overallSummaryDataList[i].transToCollMemos;
				this.trnsToCollAmtsummary = this.trnsToCollAmtsummary + this.overallSummaryDataList[i].transToCollTotal;

				this.srdStockAmtsummary = this.srdStockAmtsummary + this.overallSummaryDataList[i].srdStockTotal;
				this.colManSumMemosummary = this.colManSumMemosummary + this.overallSummaryDataList[i].collManStockMemos;
				this.colManSumAmtsummary = this.colManSumAmtsummary + this.overallSummaryDataList[i].collManStockTotal;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.overallSummaryDataList.length, this.gcNotRecAmtsummary, this.trnsToCollMemosummary, this.trnsToCollAmtsummary, "", this.srdStockAmtsummary, this.colManSumMemosummary, this.colManSumAmtsummary];
			}
			//heading logics For Date

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchByOverAllSummaryCashmemodto.fromdate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "PartyWise OS Overall Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}


	groupingPrintPartyWiseOsDetailRpt() {
		if (this.detailDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.selectedAsOnDate = $("#" + this.pageId + "asOnDate").val();
			this.cashMemoDtoForGroupingPrintList = new CashMemoDto();
			this.cashMemoDtoForGroupingPrintList.fromDate = this.selectedAsOnDate;
			this.cashMemoDtoForGrouupingPrintDataList = this.detailDataList;

			localStorage.setItem('printCashMemoDtoForGrpPrintList', JSON.stringify(this.cashMemoDtoForGroupingPrintList));
			localStorage.setItem('printForGrpPartyWiseOsDataList', JSON.stringify(this.cashMemoDtoForGrouupingPrintDataList));
			this.viewGroupingPrintPartywiseOs = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewGroupingPrintPartywiseOs) {
					this.viewGroupingPrintPartywiseOs = false;
					localStorage.clear();
				}
			});
		}
	}

}

