<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row"  *ngIf="isLoggedIn" id="{{pageId}}showLrDispBkgReport">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">LR Dispatched Booking Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewType"
															name="viewType" #viewType
															(change)="viewTypeMode(viewType.value)">
															<option selected value="summary">Summary</option>
															<option value="detail">Detail</option>
															<option value="destination">Destination</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="ViewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="ViewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															[(ngModel)]="toDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewTypeDestination" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Date</label> <input id="{{pageId}}destDate"[(ngModel)]="destDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="dates" ngbDatepicker
															#dates="ngbDatepicker">
														<div class="input-group-append" (click)="dates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="showSource" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}source" type="text" class="form-control"
															[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
															[resultFormatter]="formatterSource"
															[inputFormatter]="formatterSource"
															(focus)="focusSourceTA$.next($any($event).target.value)"
															placeholder="Select Source" />


													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>






										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8"></div>
										</div>
										<div class="row p-t-10">
											<div class="col-md-8">
												<h6 class="card-title" [hidden]="!summaryTable">Booking
													LR Dispatched Report - Summary</h6>
												<h6 class="card-title" [hidden]="!detailTable">Booking
													LR Dispatched Report - Details</h6>
												<h6 class="card-title" [hidden]="!destinationTable">Booking
													LR Dispatched Report - Destination</h6>
											</div>
											<div class="col-md-4">
												<button type="submit" class="dt-button" style="margin-left: 74%;"
													(click)="customPrintLrDispBkgRptSummary()" id="{{pageId}}printAllBtn" 
													[hidden]="!summaryTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
												<button type="submit" class="dt-button" style="margin-left: 74%;"
													(click)="customPrintLrDispBkgRptDetail()" id="{{pageId}}printAllBtn" 
													[hidden]="!detailTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
												<button type="submit" class="dt-button" style="margin-left: 74%;"
													(click)="customPrintLrDispBkgRptDest()" id="{{pageId}}printAllBtn" 
													[hidden]="!destinationTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
											</div>

										</div>

										<div class="row">
											<div class="col-md-12">
													<div class="box-body" [hidden]="!summaryTable">
														<table datatable  id="{{pageId}}summaryTableId"
															class="table table-striped table-bordered row-border hover"
															[dtOptions]="dtOptionsSummary"
															[dtTrigger]="dtTriggerSummary">

															<thead>
																<tr>
																	<th>Entry Date</th>
																	<th>Hireslip Date</th>
																	<th>Total Lrs</th>
																	<th>Articles</th>
																	<th>Diff(Hire-Entry)</th>
																	<th>Act Wt</th>
																	<th>Chg Wt</th>

																</tr>
															</thead>
															<tbody>
																<tr *ngFor="let smryData of summaryDataList">
																	<td>{{ smryData.enteredDateStr }}</td>
																	<td>{{ smryData.hireslipDateStr }}</td>
																	<td>{{ smryData.totalLrs }}</td>
																	<td>{{ smryData.totalArticles }}</td>
																	<td>{{ smryData.dispatchedwithin }}</td>
																	<td>{{ smryData.actualWeight }}</td>
																	<td>{{ smryData.chargedWeight }}</td>
																</tr>

															</tbody>
															<tfoot>
																<tr>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr>
															</tfoot>

														</table>
													</div>
													
													<div class="box-body" [hidden]="!detailTable">
														<table datatable  id="{{pageId}}detailsTableId"
															class="table table-striped table-bordered row-border hover"
															[dtOptions]="dtOptionDetail"
															[dtTrigger]="dtTriggerDetail">
															<thead>
																<tr>
																	<th>Book Date</th>
																	<th>Entry Date</th>
																	<th>Hirelsip Date</th>
																	<th>Lr No</th>
																	<th>Articles</th>
																	<th>Source</th>
																	<th>Diff(Entry-Booking)</th>
																	<th>Diff(Hire-Entry)</th>
																	<th>Diff(Hire-Booking)</th>
																	<th>ActWt</th>
																	<th>ChgWt</th>

																</tr>
															</thead>
															<tbody>
																<tr *ngFor="let detailData of detailDataList">
																	<td>{{ detailData.bookingDateStr }}</td>
																	<td>{{ detailData.enteredDateStr }}</td>
																	<td>{{ detailData.hireslipDateStr }}</td>
																	<td>{{ detailData.lrNumber }}</td>
																	<td>{{ detailData.totalArticles }}</td>
																	<td>{{ detailData.agentName }}</td>
																	<td>{{ detailData.deliveredwithin }}</td>
																	<td>{{ detailData.dispatchedwithin }}</td>
																	<td>{{ detailData.diffDays }}</td>
																	<td>{{ detailData.actualWeight }}</td>
																	<td>{{ detailData.chargedWeight }}</td>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>

																</tr>
															</tfoot>

														</table>
													</div>
												<div class="box-body" [hidden]="!destinationTable">
													<table datatable id="{{pageId}}destinationTableId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsDestination"
														[dtTrigger]="dtTriggerDest">
														<thead>
															<tr>
																<th>Entry Date</th>
																<th>Tot Lrs</th>
																<th>Tot Art</th>
																<th>Diff(Hire-Entry)</th>
																<th>Diff(Hire-Book)</th>
																<th>ActWt</th>
																<th>ChgWt</th>
																<th>Destination</th>

															</tr>
														</thead>
														<tbody>
															<tr *ngFor="let destData of destDataList">
																<td>{{ destData.enteredDateStr }}</td>
																<td>{{ destData.totalLrs }}</td>
																<td>{{ destData.totalArticles }}</td>
																<td>{{ destData.dispatchedwithin }}</td>
																<td>{{ destData.dispatchedwithin1 }}</td>
																<td>{{ destData.actualWeight }}</td>
																<td>{{ destData.chargedWeight }}</td>
																<td>{{ destData.destination }}</td>
															</tr>
														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>
					</div>

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>