import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends


@Component( {
    selector: 'app-agent-setup',
    templateUrl: './agent-setup.component.html',
    styleUrls: ['./agent-setup.component.css']
} )
export class AgentSetupComponent implements OnInit {


    //for datatable starts
    gettingDataFrmServiceFrBookingAgentTable: any;


    bookingAgentDataList: any;


    onDestroyUnsubscribtionBookingAgent: Subscription;

    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;

    viewBookingAgent = true;
    viewDestinationAgent = false;
    viewMainStation = false;
    viewStationType = true;
    viewDestinationAgentSubStation = false;

    //for the select option with filter starts

    controlState = new FormControl();
    control = new FormControl();

    stateOptions = [
        { id: 1, label: 'Maharashtra' },
        { id: 2, label: 'Kerela' },
        { id: 3, label: 'Tamil Nadu' }
    ];

    mainStationNameOptions = [
        { id: 1, label: 'Agra' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Mumbai' }
    ];

    //for the select option with filter ends


    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBookingAgent: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsBookingAgent: any;
    pageId="asct";
    //for datatable ends

    constructor(/* for datatable starts */private bookingAgent: MasterService, public changeDetectorRef : ChangeDetectorRef /* for datatable endss */ ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsBookingAgent = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Agent Name',
                    data: 'agentName'
                },
                {
                    title: 'Station Name',
                    data: 'stationName'
                },
                {
                    title: 'Contact Person',
                    data: 'contactPerson'
                },
                {
                    title: 'Phone No',
                    data: 'phoneNo'
                },
                {
                    title: 'Address',
                    data: 'address'
                },
                {
                    title: 'City',
                    data: 'city'
                },
                {
                    title: 'State',
                    data: 'state'
                },
                {
                    title: 'Email Address',
                    data: 'emailAddress'
                },
                {
                    title: 'Agent Code',
                    data: 'agentCode'
                },
                {
                    title: 'Branch',
                    data: 'branch'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.gettingDataFrmServiceFrBookingAgentTable = this.bookingAgent.getSummaryData()
        this.onDestroyUnsubscribtionBookingAgent = this.gettingDataFrmServiceFrBookingAgentTable.subscribe( data => {
            this.bookingAgentDataList = data['data'];
            this.dtTriggerBookingAgent.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerBookingAgent.unsubscribe();

        this.onDestroyUnsubscribtionBookingAgent.unsubscribe();
        //for datatable ends
    }



    selectAgentTypeMode( selectAgentType: string ) {
        if ( selectAgentType === 'bookingAgent' ) {
            this.viewMainStation = false;
            this.viewBookingAgent = true;
            this.viewDestinationAgent = false;
            this.viewStationType = true;
            this.viewDestinationAgentSubStation = false;


        } else if ( selectAgentType === 'destinationAgent' ) {
            this.viewMainStation = false;
            this.viewBookingAgent = false;
            this.viewDestinationAgent = true;
            this.viewStationType = true;
            this.viewDestinationAgentSubStation = false;

        } else {
            this.viewMainStation = false;
            this.viewBookingAgent = false;
            this.viewDestinationAgent = false;
            this.viewStationType = false;
            this.viewDestinationAgentSubStation = false;
        }
    }
    selectStationTypeMode( selectStationType: string ) {
        if ( selectStationType === 'mainStationBooking' ) {
            this.viewMainStation = false;
            this.viewDestinationAgentSubStation = false;

        } else if ( selectStationType === 'subStationBooking' ) {
            this.viewMainStation = true;
            this.viewDestinationAgentSubStation = false;


        } else if ( selectStationType === 'mainStationDestination' ) {
            this.viewMainStation = false;
            this.viewDestinationAgentSubStation = false;

        }
        else if ( selectStationType === 'subStationDestination' ) {
            this.viewMainStation = false;
            this.viewDestinationAgentSubStation = true;

        } else {
            this.viewMainStation = false;
            this.viewDestinationAgentSubStation = false;

        }
    }
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends


}
