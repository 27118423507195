import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from "src/app/dataService/lr-service";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
@Component({
    selector: 'app-gst-Invoice-Detained',
    templateUrl: './gst-Invoice-Detained.component.html',
    styleUrls: ['./gst-Invoice-Detained.component.css']
})
export class GstInvoiceDetainedComponent implements OnInit {

    closeResult: string;
    otherView = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    lrDto: LRDto = new LRDto();

    invoiceNumber: any;
    remarks: any;
    lrDtoRet: LRDto = new LRDto();
    isReadOnly = false;
    lrDtoRetInv: LRDto = new LRDto();
    pageId = "ginvd";
    detainedDate: NgbDateStruct;
    selectedDetainedDate: any;
    constructor(private router: Router, private modalService: NgbModal, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef, private datePipe: DatePipe) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            console.log("this.userDataDtoReturnSession" + this.userDataDtoReturnSession);
            console.log("this.userDataDtoReturnSession.mainstation" + this.userDataDtoReturnSession.mainStation);

        }
    }
    //for popup modal starts 
    open2(content) {

        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends 


    ngOnInit() {
    }


    invoiceOnEnter(e) {
        if (e.keyCode == 13) {
            // this.status = $("#" + this.pageId + "marksAs option:selected").text();
            // if (this.status == 'Invoice Clear') {
            //     this.validateInvoice();
            // }
            //  for extra invEnter issue corrected.
            this.validateInvoice();
        }
    }

    validateInvoice() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        if (this.invoiceNumber == null || this.invoiceNumber == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter LR Invoice Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $('#ginvdinvoiceNumber').focus();
                }, 100);

            });

        } else {
            this.lrDto = new LRDto();
            this.lrDto.invoiceNumber = this.invoiceNumber;
            console.log("this.userDataDtoReturnSession" + this.userDataDtoReturnSession);
            console.log("this.userDataDtoReturnSession.mainstation" + this.userDataDtoReturnSession.mainStation);
            this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
            console.log("this.lrDto" + this.lrDto)
            this.showSpinnerForAction = true;
            this.lrService.getHireSlipDetailsForGSTInvDetained(this.lrDto).subscribe(
                (response) => {
                    console.log(response);
                    if (response.status == "NotExist") {
                        swal({
                            title: "Alert",
                            text: "No Invoice found, '" + this.invoiceNumber + "' Invoice No or Station does not belongs to " + this.userDataDtoReturnSession.mainStation
                                + " Please verify it!",
                            icon: "warning",
                            closeOnClickOutside: true,
                            closeOnEsc: true,
                        }).then(function () {
                            swal.close();
                            window.setTimeout(function () {
                                $('#ginvdinvoiceNumber').focus();
                                $("#ginvdinvoiceNumber").val('');
                            }, 100);

                        });
                        // swal("Alert", "No Invoice found, '" + this.invoiceNumber + "' "please" check the Invoice number!", "warning");
                        // $("#" + this.pageId + "truckNumber").val('');
                    } else {
                        this.lrDtoRetInv = new LRDto();
                        this.lrDtoRetInv = response;


                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error while getting the invoice details", "warning");
                }, () => console.log('done');
        }
    }

    getChangesValueDetails() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.remarks = $("#" + this.pageId + "remarks").val();
        let gstDetainedDate = $("#" + this.pageId + "detainedDate").val();
        this.selectedDetainedDate = this.datePipe.transform(gstDetainedDate, "yyyy-MM-dd");
        this.lrDto = new LRDto();
        this.lrDto.invoiceNumber = this.invoiceNumber;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.gstDetainedDate = this.selectedDetainedDate;
        this.lrDto.gstDetainedRemarks = this.remarks;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log("this.lrDto");
        console.log(this.lrDto);


    }
    submitChanges() {


        if (this.lrDto.invoiceNumber == null || this.lrDto.invoiceNumber == "" || this.lrDto.invoiceNumber == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please enter the Invoice Number to proceed!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $("#ginvdinvoiceNumber").val('');
                    $('#ginvdinvoiceNumber').focus();
                }, 100);

            });

        }
        else {
            this.confirmSubmitChanges();
        }

    }

    confirmSubmitChanges() {
        swal({
            title: "Confirm changes",
            text: "Sure U want to submit the entered details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.showSpinnerForAction = true;
                this.getChangesValueDetails();
                this.lrService.updateHireSlipGSTDetained(this.lrDto).subscribe(
                    (response) => {
                        if (response.status == "Success") {
                            swal("Success", "Entered details submitted", "success");
                            this.clearFields();
                        } else {
                            swal("Alert", "Error while submitting the changes", "warning");
                        }
                        this.showSpinnerForAction = false;
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Error While submitting the changes", "warning");
                    }, () => console.log('done');
            }
        });
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    clearFields() {
        this.lrDto = new LRDto();
        $("#" + this.pageId + "invoiceNumber").val('');
        $("#" + this.pageId + "remarks").val('');
        $("#" + this.pageId + "detainedDate").val('');
    }


    noOfArtOnEnter(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $('#ginvdremarks').focus();
            }, 100);
        }
    }

    invoiceNoBlur() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        if (this.invoiceNumber == null || this.invoiceNumber == '') {
            this.validateInvoice();
        }
    }

}
