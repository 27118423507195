import { ReportService } from "src/app/dataService/report-service";
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { MasterService } from "src/app/dataService/master-service";
import { Subject, Subscription, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-fov-exemption",
  templateUrl: "./fov-exemption.component.html",
  styleUrls: ["./fov-exemption.component.css"],
})
export class FovExemptionMasterComponent implements OnInit {

  model: NgbDateStruct;
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dtTriggerFovExmpCommodity: Subject<any> = new Subject();
  dtTriggerFovExmpConsignor: Subject<any> = new Subject();
  dtOptionsFovExmpCommodity: any;
  dtOptionFovExmpConsignor: any;

  //For Source
  sourceOptions: AgentDetailsDto[];
  lrDtoSource: AgentDetailsDto = new AgentDetailsDto();
  lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
  public modelSource: any;
  sourceTA: Array<AgentDetailsDto> = [];
  focusSourceTA$ = new Subject<string>();
  searchSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSourceTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.sourceTA
        : this.sourceTA.filter(v => v.agentName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSource = (x: { agentName: string }) => x.agentName;
  //sourceList
  controlAgentName = new FormControl();
  agentNameTA: Array<any> = [];
  agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
  @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
  focusAgentNameTA$ = new Subject<string>();
  clickAgentNameTA$ = new Subject<string>();
  agentNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickAgentNameTA$.pipe(filter(() => !this.instanceAgentName.isPopupOpen()));
    const inputFocus$ = this.focusAgentNameTA$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  //For Destination
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { destination: string }) => x.destination;
  //For consignor Index search
  public modelConsignorIndexSearchView: any;
  @ViewChild('instanceConsignorIndexSearchView') instanceConsignorIndexSearchView: NgbTypeahead;
  consignorIndexSearchViewTA: Array<any> = [];
  focusConsignorIndexSearchViewTA$ = new Subject<string>();
  consignorIndexSearchViewTypeTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorIndexSearchViewTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorIndexSearchViewTA
        : this.consignorIndexSearchViewTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  showSpinnerForAction = false;
  destinationDtoForAll: LRDto = new LRDto();
  destinationList: Array<any> = [];

  userDataDtoReturnSession: any;
  isLoggedIn = true;
  rateMasterDtoUserSearch: RateMasterDto = new RateMasterDto();
  fovExmpCommodityDataList: any;
  fovExmpConsignorDataList: any;
  enteredSource: any;
  enteredDest: any;
  enteredCommodity: any;
  enteredConsignorId: any;
  enteredFov: any;
  enteredEffecDate: any;
  selectedRateId = 0;
  searchBy: any;
  public modelConsignorName: any;
  lrDtoBkgSrcAll: LRDto = new LRDto();

  //For Commodity list
  rateMasterDto: RateMasterDto = new RateMasterDto();
  rateMasterDtoOptions: RateMasterDto[];
  rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
  public modelCommodityList: any;
  commodityListTA: Array<RateMasterDto> = [];
  focusCommodityListTA$ = new Subject<string>();
  searchCommodityList = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListTA
        : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

  //for ConsignorName
  consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
  consignorNameOptions: any;
  partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
  consignorNameTA: Array<PartyMasterDto> = [];
  focusConsignorNameTA$ = new Subject<string>();
  consignorNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorNameTA
        : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
  //consignee list
  partyDtoConsigneeName: PartyMasterDto = new PartyMasterDto();
  consigneeNameOptions: PartyMasterDto[];
  public modelConsigneeName: any;
  consigneeNameTA: Array<PartyMasterDto> = [];
  focusConsigneeNameTA$ = new Subject<string>();
  searchConsigneeName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeNameTA
        : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

  address: any;
  agentDetailsOptionsSet: AgentDetailsDto[];
  agentDetailsOptionsGet: AgentDetailsDto[];
  showSource = true;
  showDest = true;
  showCommodity = true;
  showFov = true;
  showEffectiveFromDate = true;
  showConsignorName = false;
  showCosigneeName = false;
  pageId = "fvexc";
  selectedIndexSearch: any;
  selectedDestinationForConsigneeName: any;
  validateSelectedConsigneeId: any;
  selectedRateView = "Consignor Wise Exemption Details";
  searchByRate = "consignorRate";

  //For Consignor Index
  consignorIndexOptions = [
    { id: 2, label: 'A' },
    { id: 2, label: 'B' },
    { id: 3, label: 'C' },
    { id: 4, label: 'D' },
    { id: 5, label: 'E' },
    { id: 6, label: 'F' },
    { id: 7, label: 'G' },
    { id: 8, label: 'H' },
    { id: 9, label: 'I' },
    { id: 10, label: 'J' },
    { id: 11, label: 'K' },
    { id: 12, label: 'L' },
    { id: 13, label: 'M' },
    { id: 14, label: 'N' },
    { id: 15, label: 'O' },
    { id: 16, label: 'P' },
    { id: 17, label: 'Q' },
    { id: 18, label: 'R' },
    { id: 19, label: 'S' },
    { id: 20, label: 'T' },
    { id: 21, label: 'U' },
    { id: 22, label: 'V' },
    { id: 23, label: 'W' },
    { id: 24, label: 'X' },
    { id: 25, label: 'Y' },
    { id: 26, label: 'Z' }];

  constructor(private masterReadService: MasterReadService, private router: Router, private masterService: MasterService,
    public changeDetectorRef: ChangeDetectorRef) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
      this.setConsginorIndex();
      this.getDestinationDetails();
      this.getAgentDetailList();
      this.getCommodityMasterDetailsList();
      setTimeout(() => {
        this.getFOVRatesForCommoditiesDetails();
        this.getFOVRatesForConsignorDetails();
      }, 500);
    }

  }
  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  ngOnInit(): void {
    this.dtOptionsFovExmpCommodity = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      processing: true,
      responsive: true,
      "scrollX": false,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": true,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
      }
    };
    this.dtOptionFovExmpConsignor = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      processing: true,
      //scroll in datatable starts
      responsive: true,
      "scrollX": false,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": true,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
      }
    };
  }

  ngOnDestroy(): void {
    this.dtTriggerFovExmpCommodity.unsubscribe();
    this.dtTriggerFovExmpConsignor.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerFovExmpCommodity.next();
    this.dtTriggerFovExmpConsignor.next();
  }

  sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelSource = e.item;
    $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
  }

  //For Destination Listener
  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelDestination = e.item;
    $("#" + this.pageId + "destination").val(this.modelDestination.destination);
    if (this.searchBy == 'consigneeWise') {
      this.getConsigneeDetails();
    }
  }

  ///listener FOr commodity List
  clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelCommodityList = e.item;
    $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    //this.validateCommodityListValue = this.modelCommodityList.commodityName
  }

  //for datepicker
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }

  //For Viewing Data
  searchByMode(searchBy: string) {
    if (searchBy === 'commodityWise') {
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = false;
      this.showCosigneeName = false;
      this.searchBy = searchBy;
    } else if (searchBy === 'consignorWise') {
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = true;
      this.showCosigneeName = false;
      this.searchBy = searchBy;
    } else if (searchBy === 'consigneeWise') {
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = false;
      this.showCosigneeName = true;
      this.searchBy = searchBy;
    }
  }

  searchByModeRate(searchBy: string) {
    if (searchBy === 'consignorRate') {
      this.selectedRateView = 'Consignor Wise Exemption Details';
      this.searchByRate = 'consignorRate';
    } else if (searchBy === 'consigneeRate') {
      this.selectedRateView = 'Consignee Wise Exemption Details';
      this.searchByRate = 'consigneeRate';
    }
    this.getFOVRatesForConsignorDetails();
  }

  //for datePicker
  //for Getting Agent
  getUserValuesForAgentDetailsList() {
    this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.agentDtoParam.column2 = 'Working';
    this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoParam.mode = 'Sub';
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Agents  records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlAgentName.reset();
        } else {
          this.agentDetailsOptionsGet = response;
          this.agentDetailsOptionsSet = response;
          this.agentNameTA = [];
          for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
            this.agentNameTA.push(this.agentDetailsOptionsGet[i].subStation);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.mode = "LrForm";
  }

  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
      (response) => {
        console.log(response);
        this.showSpinnerForAction = false;
        if (response) {
          if (response.length > 0) {
            this.destinationOptions = response;
            this.destinationTA = [];
            this.destinationDtoForAll.destination = "ALL";
            this.destinationTA.push(this.destinationDtoForAll);

            this.destinationList = [];
            for (let i = 0; i < this.destinationOptions.length; i++) {
              this.destinationTA.push(this.destinationOptions[i]);
              this.destinationList.push(this.destinationOptions[i].destination);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
      },
      () => console.log('done');
  };
  getCommodityListRead() {
    this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDto.mode = "mainConsigneeMaster";
  }

  getCommodityMasterDetailsList = () => {
    this.getCommodityListRead();
    this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          if (response.length == 0) {
            swal({
              title: "Warning",
              text: "No commodity details records found!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
          } else {
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
            this.rateMasterDtoOptions = response;

            this.rateMasterDtoCommodityListAllOption.commodityName = "ALL";
            this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);

            for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
              this.commodityListTA.push(this.rateMasterDtoOptions[i]);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Error",
        text: "Server Error While Getting Commodity Master Details",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  };

  getConsignorDetailsList() {
    this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
    //this.partyMasterDtoForconsignor.mode = "newConsignorByMainStation";
    this.partyMasterDtoForconsignor.mainStation = this.enteredSource;
    this.selectedIndexSearch = this.modelConsignorIndexSearchView;
    this.partyMasterDtoForconsignor.indexValue = this.selectedIndexSearch;
    console.log(this.partyMasterDtoForconsignor);
    if (this.selectedIndexSearch != null && this.selectedIndexSearch != '') {
      this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
      this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
      this.getConsignorDetails();
    }
  }

  getConsignorDetails = () => {
    this.showSpinnerForAction = true;
    this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
      (response) => {
        console.log(response);
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Consignor Details found !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.consignorNameOptions = [];
          this.consignorNameTA = [];
        } else {
          this.consignorNameOptions = response;
          this.consignorNameTA = [];
          $("#" + this.pageId + "consignorName").val('');
          this.consignorNamePartyDtoAllOption.consignorName = "All";
          this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
          for (let i = 0; i < this.consignorNameOptions.length; i++) {
            this.consignorNameTA.push(this.consignorNameOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the consignor details", "info");
      }, () => console.log('done');
  }

  getFOVRatesForCommoditiesDetails = () => {
    this.showSpinnerForAction = true;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.masterService.getFOVRatesForCommodities(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        $("#" + this.pageId + "fovExemptionCommodityTableId").DataTable().destroy();
        this.fovExmpCommodityDataList = [];
        if (response.length == 0) {
          swal({
            title: "FOV Exemption - Commodity Wise",
            text: "No Details found!",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.fovExmpCommodityDataList = response;
        }
        setTimeout(() => {
          this.dtTriggerFovExmpCommodity.next();
        }, 500);
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details", "info");
      }, () => console.log('done');
  }

  getFOVRatesForConsignorDetails = () => {
    this.showSpinnerForAction = true;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.rateMasterDtoUserSearch.rateType = this.searchByRate;
    this.masterService.getFOVRatesForConsignor(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        $("#" + this.pageId + "fovExemptionConsignorTableId").DataTable().destroy();
        this.fovExmpConsignorDataList = [];
        if (response.length == 0) {
          swal({
            title: "FOV Exemption - Consignor Wise",
            text: "No Details found!",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.fovExmpConsignorDataList = response;
        }
        setTimeout(() => {
          this.dtTriggerFovExmpConsignor.next();
        }, 500);
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details", "info");
      }, () => console.log('done');
  }

  confirmSave() {
    swal({
      title: "Confirmation",
      text: "Sure you want to save/update the changes?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.saveDetails();
      }
    })
  }

  getUserValueSave() {
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.enteredSource = $("#" + this.pageId + "agentName").val();
    this.enteredDest = $("#" + this.pageId + "destination").val();
    this.enteredCommodity = $("#" + this.pageId + "commodityListId").val();
    this.enteredFov = $("#" + this.pageId + "fovId").val();
    this.enteredEffecDate = $("#" + this.pageId + "fromDates").val();

    this.rateMasterDtoUserSearch.id = this.selectedRateId;
    this.rateMasterDtoUserSearch.effectiveFromDate = this.enteredEffecDate;
    this.rateMasterDtoUserSearch.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDtoUserSearch.source = this.enteredSource;
    this.rateMasterDtoUserSearch.destination = this.enteredDest;
    // this.rateMasterDtoUserSearch.consigneeId = 0;
    this.rateMasterDtoUserSearch.commodityName = this.enteredCommodity;
    this.rateMasterDtoUserSearch.fovPerc = this.enteredFov;
    if (this.searchBy == "consignorWise") {
      this.rateMasterDtoUserSearch.consignorId = this.enteredConsignorId;
    } else {
      this.rateMasterDtoUserSearch.consignorId = 0;
    }
    if (this.searchBy == "consigneeWise") {
      this.rateMasterDtoUserSearch.consigneeId = this.validateSelectedConsigneeId;
    } else {
      this.rateMasterDtoUserSearch.consigneeId = 0;
    }
  }

  saveDetails = () => {
    this.showSpinnerForAction = true;
    this.getUserValueSave();
    this.masterService.addUpdateFOVExemption(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        if (response.status == "Success") {
          swal("Success", "Details saved/updated successfully!", "info");
          this.clearFields();
          this.getFOVRatesForCommoditiesDetails();
          this.getFOVRatesForConsignorDetails();
        } else if (response.status == "Duplicate") {
          swal("Duplicate Entry", "Entered exemption criteria is already available, please choose different or verify again.", "info");
        } else if (response.status == "Failed") {
          swal("Failed", "Failed to save/update the details!", "info");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while saving the details", "info");
      }, () => console.log('done');
  };

  rowSelectedEdit(fovExmpCommodityData) {
    this.selectedRateId = fovExmpCommodityData.id;
    $("#" + this.pageId + "agentName").val(fovExmpCommodityData.source);
    $("#" + this.pageId + "destination").val(fovExmpCommodityData.destination);
    $("#" + this.pageId + "commodityListId").val(fovExmpCommodityData.commodityName);
    $("#" + this.pageId + "fovId").val(fovExmpCommodityData.fovPerc);
    $("#" + this.pageId + "fromDates").val(fovExmpCommodityData.effectiveFromDate);

    if (fovExmpCommodityData.consignorId > 0) {
      $("#" + this.pageId + "searchBy").val("consignorWise");
      $("#" + this.pageId + "consignorName").val(fovExmpCommodityData.consignorName);
      this.enteredConsignorId = fovExmpCommodityData.consignorId;
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = true;
      this.showCosigneeName = false;
      this.searchBy = "consignorWise";
    } else if (fovExmpCommodityData.consigneeId > 0) {
      $("#" + this.pageId + "searchBy").val("consigneeWise");
      $("#" + this.pageId + "consignorName").val(fovExmpCommodityData.consigneeName);
      this.validateSelectedConsigneeId = fovExmpCommodityData.consigneeId;
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = false;
      this.showCosigneeName = true;
      this.searchBy = 'consigneeWise';
    } else {
      $("#" + this.pageId + "searchBy").val("commodityWise");
      this.showSource = true;
      this.showDest = true;
      this.showCommodity = true;
      this.showFov = true;
      this.showEffectiveFromDate = true;
      this.showConsignorName = false;
      this.showCosigneeName = false;
      this.searchBy = "commodityWise";
    }
  }

  confirmDelete(fovExmpCommodityData) {
    swal({
      title: "Confirmation",
      text: "Sure you want to delete the details?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.confirmDeleteService(fovExmpCommodityData);
      }
    })
  }

  confirmDeleteService(fovExmpCommodityData) {
    this.enteredEffecDate = $("#" + this.pageId + "fromDates").val();
    this.selectedRateId = fovExmpCommodityData.id;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.rateMasterDtoUserSearch.id = this.selectedRateId;
    this.rateMasterDtoUserSearch.effectiveFromDate = this.enteredEffecDate;
    this.rateMasterDtoUserSearch.userName = this.userDataDtoReturnSession.userId;
    this.masterService.deleteFOVExemption(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        if (response.status == "Success") {
          swal("Success", "Details deleted successfully!", "info");
          this.getFOVRatesForCommoditiesDetails();
          this.getFOVRatesForConsignorDetails();
        } else if (response.status == "Failed") {
          swal("Failed", "Failed to delete the details!", "info");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while deleting the details", "info");
      }, () => console.log('done');
  }

  clickListnerForConsignor(event) {
    this.modelConsignorName = event.item;
    this.enteredConsignorId = this.modelConsignorName.consignorId;
  }

  clearFields() {
    this.selectedRateId = 0;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    $("#" + this.pageId + "agentName").val("");
    $("#" + this.pageId + "destination").val("");
    $("#" + this.pageId + "commodityListId").val("");
    $("#" + this.pageId + "fovId").val("");
    $("#" + this.pageId + "fromDates").val("");
    $("#" + this.pageId + "consignorName").val("");
    this.enteredConsignorId = 0;
    this.enteredSource = null;
    this.enteredDest = null;
    this.enteredCommodity = null;
    this.enteredFov = null;
    this.enteredEffecDate = null;
  }

  clickListnerForSource(event) {
    this.enteredSource = event.item;
    // if (this.searchBy == "consignorWise") {
    //   this.getConsignorDetailsList();
    // }
  }

  setConsginorIndex() {
    for (let i = 0; i < this.consignorIndexOptions.length; i++) {
      this.consignorIndexSearchViewTA.push(this.consignorIndexOptions[i].label);
    }
  }
  consignorSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
    this.modelConsignorIndexSearchView = e.item;
    console.log(this.modelConsignorIndexSearchView);
    if (this.modelConsignorIndexSearchView != 'ALL') {
      this.getConsignorDetailsList();
    }
  }

  getConsigneeDetailsList() {
    this.partyDtoConsigneeName = new PartyMasterDto();
    this.selectedDestinationForConsigneeName = $("#" + this.pageId + "destination").val();
    this.partyDtoConsigneeName.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoConsigneeName.office = this.selectedDestinationForConsigneeName;
    this.partyDtoConsigneeName.mode = "specific";
  }

  getConsigneeDetails = () => {
    this.getConsigneeDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsigneeMaster(this.partyDtoConsigneeName).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          this.consigneeNameOptions = [];
          this.consigneeNameTA = [];
        } else {
          this.consigneeNameOptions = [];
          this.consigneeNameTA = [];
          this.consigneeNameOptions = response;
          for (let i = 0; i < this.consigneeNameOptions.length; i++) {
            this.consigneeNameTA.push(this.consigneeNameOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the consignee details", "info");
      }, () => console.log('done');
  }

  clickListnerForConsigneeNameList(e: NgbTypeaheadSelectItemEvent) {
    this.modelConsigneeName = e.item;
    $("#" + this.pageId + "consigneeNameId").val(this.modelConsigneeName.consigneeName);
    this.validateSelectedConsigneeId = 0;
    this.validateSelectedConsigneeId = this.modelConsigneeName.consigneeId;
  }

}
