
import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
@Component({
    selector: 'app-loading-unloading-hamali-details-print',
    templateUrl: './loading-unloading-hamali-details-print.component.html',
    styleUrls: ['./loading-unloading-hamali-details-print.component.css']
})
export class LoadingUnloadingHamaliDetailsPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    address: any;
    lrDto_LocalStorage: LRDto = new LRDto();
    lrDto_LoadingDetailsPrint: any;
    lrDto_UnLoadingDetailsPrint: any;

    private newAttributeSetLoading: any = {};

    loadingKantaWgt: any;
    loadingTotalCount: any;

    unloadingTotalCount: any;
    unloadingTotalLrs: any;
    unloadingTotActWgt: any;
    unloadingKantaWgt: any

    stmtNo: any;
    stmtDate: any;
    period: any;
    loaderHad: any;

    loadingHamali: number;
    unloadingHamali: number;
    totLoadingHamaliAmt: number;
    totUnloadingHamaliAmt: number;
	pageId="luhpc";


    constructor(private router: Router, private datePipe: DatePipe,public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.lrDto_LocalStorage = JSON.parse(localStorage.getItem('labourStmtLoadingUnloadingPrintShow'));
            console.log(this);
            this.stmtNo = this.lrDto_LocalStorage.fortNightStmtNo;
            this.stmtDate = this.lrDto_LocalStorage.stmtDateStr;
            this.period = this.lrDto_LocalStorage.fromDate + " To " + this.lrDto_LocalStorage.toDate;
            this.loaderHad = this.lrDto_LocalStorage.loaderHead;
            this.lrDto_LoadingDetailsPrint = [];
            this.lrDto_UnLoadingDetailsPrint = [];
            this.lrDto_LoadingDetailsPrint = this.lrDto_LocalStorage.lrDtos;
            this.lrDto_UnLoadingDetailsPrint = this.lrDto_LocalStorage.lrDtoList;
            this.loadingHamali = 0;
            this.unloadingHamali = 0;
            this.totLoadingHamaliAmt = 0.0;
            this.totUnloadingHamaliAmt = 0.0;

            this.loadingHamali = this.lrDto_LocalStorage.loadingHamali;
            this.unloadingHamali = this.lrDto_LocalStorage.unLoadingHamali;
            this.totLoadingHamaliAmt = this.lrDto_LocalStorage.totLoadingHamaliAmt;
            this.totUnloadingHamaliAmt = this.lrDto_LocalStorage.totUnloadingHamaliAmt;

            this.loadingKantaWgt = 0.0;
            this.loadingTotalCount = 0.0;
            for (let i = 0; i < this.lrDto_LoadingDetailsPrint.length; i++) {
                this.loadingKantaWgt = +this.loadingKantaWgt + +this.lrDto_LoadingDetailsPrint[i].kantaWeight;
                this.loadingTotalCount++;
            }

            this.unloadingTotalLrs = 0.0;
            this.unloadingTotActWgt = 0.0;
            this.unloadingKantaWgt = 0.0
            this.unloadingTotalCount = 0.0;
            for (let i = 0; i < this.lrDto_UnLoadingDetailsPrint.length; i++) {
                this.unloadingTotalCount++;
                this.unloadingTotalLrs = +this.unloadingTotalLrs + +this.lrDto_UnLoadingDetailsPrint[i].totalLrs;
                this.unloadingTotActWgt = +this.unloadingTotActWgt + +this.lrDto_UnLoadingDetailsPrint[i].actualWeight;
                this.unloadingKantaWgt = +this.unloadingKantaWgt + +this.lrDto_UnLoadingDetailsPrint[i].challanKantaWgt;
            }

            window.setTimeout(function () {
                window.print();
            }, 0);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    clearField() {
        localStorage.clear();
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
