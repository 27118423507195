import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { TotalLessLrDetailsComponent } from "src/app/report/cashmemo-report/totalless-lrdetails/totalless-lrdetails.component";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-total-less',
    templateUrl: './total-less.component.html',
    styleUrls: ['./total-less.component.css']
})
export class TotalLessComponent implements OnInit {

    loadingIndicator = true;
    model: NgbDateStruct;
    date: { year: number; month: number };

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    getDataFrmServiceFrAmountReceivableTable: any;
    totalLessDataList: any;
    onDestroyUnsubscribtionAmountReceivable: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTotalLess: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsTotalLess: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    fromDatesModal: any;
    toDatesModal: any;
    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;

    showSpinnerForAction = false;
    hideShowDropDownDestination = false;
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();

    //For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	chequeSummary:number;
	amountRecSummary:number;
	cashSummary:number;
	pdcSummary:number;
	pageId="tlscc";

    constructor(private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
         private destStmtReport: DestStmtReport, private datePipe: DatePipe,public dialog: MatDialog,private route: ActivatedRoute, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var booleanDestination = this.hideShowDropDownDestination;
        this.dtOptionsTotalLess = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if (booleanDestination == true) {
                            return " Total Less Details Report- " +
                                "From Date : " + moment($("#tlsccfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#tlscctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#tlsccdestinationId").val() + "";
                        } else {
                            return " Total Less Details Report- " +
                                "From Date : " + moment($("#tlsccfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#tlscctoDates").val()).format('DD-MM-YYYY') + "";
                        }
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totLrs = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLess = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lessLrs = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var checkedLrs = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                var unCheckedLrs = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(totLrs);
                $(api.column(5).footer()).html(totAmt);
                $(api.column(6).footer()).html(totLess);
                $(api.column(7).footer()).html(lessLrs);
                $(api.column(8).footer()).html(checkedLrs);
                $(api.column(9).footer()).html(unCheckedLrs);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerTotalLess.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerTotalLess.unsubscribe();
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }


    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.modelDestination = null;
        this.validateDestination = null;
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"destinationId").val('');
        this.validateFromDate = null;
        this.validateToDate = null;
        $("#"+this.pageId+"totalLessTableId").DataTable().destroy();
        this.dtTriggerTotalLess.next();
        this.totalLessDataList = [];
        $("#"+this.pageId+"textId").text('');
    }

    clearBtn() {
        this.clearField();
    }

    validateSearchBtn() {
            if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                ($("#"+this.pageId+"destinationId").val() == null) || ($("#"+this.pageId+"destinationId").val() == undefined) ||
                ($("#"+this.pageId+"destinationId").val() == "")) {
                swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                return false;
            } else {
                this.getTotalLessReport();
            }
    }

    getTotalLessRptDetailsData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateDestination = $("#"+this.pageId+"destinationId").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDto.status = "StmtRpt";
        this.fortNightStmtDto.mode = "DEST";
        this.fortNightStmtDto.reportMode = "Chkd Ann Un Chkd Lrs";

        $("#"+this.pageId+"textId").text("Total Less Details For " + this.modelDestination.destination);
    }
    getTotalLessReport() {
        this.getTotalLessRptDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtLessCheckedAndUncheckedLrs(this.fortNightStmtDto).subscribe(
            (response) => {
                console.log(response);
                this.totalLessDataList = [];
                $("#"+this.pageId+"totalLessTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.totalLessDataList = response;
                    setTimeout(() => {
                    	this.dtTriggerTotalLess.next();
                     }, 1000);
                } else {
                    swal("Total Less Report", "No datas found for this information!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            },
            () => console.log('done');
    }
    
    totalLrsDetailsPopup(totalLessData,mode){
    	localStorage.clear();
		localStorage.setItem('totalLessDataPopup',JSON.stringify(totalLessData));
		localStorage.setItem('totalLessMode',JSON.stringify(mode));
    	const dialogRef = this.dialog.open( TotalLessLrDetailsComponent, {
			
		} );

	// dialogRef.afterClosed().subscribe( result => {
	// 	console.log( 'The dialog was closed' );
	// 	this.router.navigate( ['.'], { relativeTo: this.route } );
	// } );
    }


    customPrintAmountReceivable() {
		if (this.totalLessDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Received Date","Cash","Cheque","PDC","Received Amount"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [20,20,20,20,20];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//heading logics For Date
			this.validateFromDate=this.datePipe.transform($("#"+this.pageId+"fromDates").val(), "dd-MM-yyyy");
			this.validateToDate=this.datePipe.transform($("#"+this.pageId+"toDates").val(), "dd-MM-yyyy");
            //this.validateFromDate = $("#"+this.pageId+"fromDates").val();
            //this.validateToDate = $("#"+this.pageId+"toDates").val();
			
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [ this.validateFromDate,this.validateToDate ];

			///Heaading Logics For Party Name
            this.validateDestination = $("#"+this.pageId+"destinationId").val();
		
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [ this.validateDestination ];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashSummary=this.chequeSummary=this.pdcSummary=this.amountRecSummary=0;
			
			for (let i = 0; i < this.totalLessDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.totalLessDataList[i].receivedDateStr,this.totalLessDataList[i].cashAmt, 
				this.totalLessDataList[i].chequeAmt, this.totalLessDataList[i].pdcAmt,
				this.totalLessDataList[i].receivableAmt];
				
				/////////
				this.cashSummary=this.cashSummary+this.totalLessDataList[i].cashAmt;
				this.chequeSummary=this.chequeSummary+this.totalLessDataList[i].chequeAmt;
                this.pdcSummary=this.pdcSummary+this.totalLessDataList[i].pdcAmt;
				this.amountRecSummary=this.amountRecSummary+this.totalLessDataList[i].receivableAmt;
				
				/////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.totalLessDataList.length,this.cashSummary,this.chequeSummary,this.pdcSummary,this.amountRecSummary];
			
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Amount Receivable Details Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}	
}
