<div mat-dialog-content id="{{pageId}}unlaodingPageView">
	<div class="row">
		<div class="col-md-12">
			<h6 id="{{pageId}}showHeader" style="margin-bottom: 0px;">Unloading
				Sheet Details Of {{getInvoiceNumber}}</h6>
		</div>
	</div>
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">Status</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>
								<select #unloadingStatus id="{{pageId}}unloadingStatus" name="unloadingStatus"
									class="custom-select col-12" (change)="searchByStatus(unloadingStatus.value)">
									<option selected value='Select Status'>Select
										Status...</option>
									<option value="Extra">Extra</option>
									<option value="Open Condition">Open Condition</option>
									<option value="Short">Short</option>
									<option value="Wet Condition">Wet Condition</option>
									<option value="OK">OK</option>
								</select>
							</div>
						</div>
					</div>
					<div *ngIf="showForStatusNotOk" class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">Code</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>
								<input type="text" Placeholder="Code" class="form-control" id="{{pageId}}lrCode"
									(keyup)="codeFocus($event)" autocomplete="off" aria-describedby="basic-addon11">
							</div>
						</div>
					</div>
					<div *ngIf="showForStatusNotOk" class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">LR No.</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>
								<input type="text" Placeholder="LR Number" class="form-control"
									id="{{pageId}}unloadinglrNumber" (keyup)="lrNumberFocus($event)" autocomplete="off"
									aria-describedby="basic-addon11">
							</div>
						</div>
					</div>
					<div *ngIf="showForStatusNotOk" class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">Mention</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>
								<select id="{{pageId}}mention" name="mention" class="custom-select col-12"
									[disabled]=true>
									<option value="" selected>Select Mention</option>
									<option value="YES">YES</option>
									<option value="NO">NO</option>
								</select>
							</div>
						</div>
					</div>
					<div *ngIf="showForStatusNotOk" class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">Short.Art.</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>
								<input type="text" Placeholder="Shortage Article" class="form-control"
									id="{{pageId}}shortageArticle" (keyup)="articleFocus($event)" autocomplete="off"
									aria-describedby="basic-addon11">
							</div>
						</div>
					</div>
					<div *ngIf="showForStatusNotOk" class="col-sm-12 col-md-2">
						<div class="form-group">
							<label class="label_custome">Remark</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
								</div>

								<textarea #remark class="form-control" rows="2" id="{{pageId}}remark"
									(keyup)="remarksFocus($event)" name="remark" [(ngModel)]="remarkValue"></textarea>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="box-body">
						<table datatable style="width: 100%;" id="{{pageId}}unloadingTripDetailsId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsUnloadingTripDetails" [dtTrigger]="dtTriggerUnloadingTripDetails">

							<thead>
								<tr>
									<th>Status</th>
									<th>LR Number</th>
									<th>Actual Article</th>
									<th>Enter Article</th>
									<th>Remark</th>
									<th>Mention</th>
									<th>Action</th>

								</tr>
							</thead>
							<tbody>
								<tr style='cursor: pointer;'
									*ngFor="let unloadingTripDetailsData of unloadingTripDetailsDataList;let i = index">
									<td>{{ unloadingTripDetailsData.status }}</td>
									<td>{{ unloadingTripDetailsData.lrNumber }}</td>
									<td>{{ unloadingTripDetailsData.totalArticles }}</td>
									<td>{{ unloadingTripDetailsData.type }}</td>
									<td>{{ unloadingTripDetailsData.remarks }}</td>
									<td>{{ unloadingTripDetailsData.mode }}</td>
									<td>
										<button type="button" style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableEditBtn"
											(click)="rowSelectedEditForUnlaoding(unloadingTripDetailsData,i);">
											<i title="Edit" class="fas fa-pencil-alt"></i>
										</button>&nbsp;
										<button type="button" style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
											(click)="rowSelectedDeleteForUnlaoding(unloadingTripDetailsData,i);">
											<i title="Remove" class="fas fa-trash"></i>
										</button>
									</td>

								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!--<div class="row">
			<div class="col-md-12">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-12 col-md-5">
							<div class="form-group"></div>
							<button type="button" style='background: green; color: white;' class="btn btn-outline-dark"
								id="{{pageId}}okPopupBtnId" (click)="btnPopSave();">Save</button>

						</div>
						<div class="col-sm-12 col-md-5">
							<div class="form-group"></div>
							<button type="button" style='background: black; color: white;' class="btn btn-outline-dark"
								(click)="clearAll();">Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>-->
	</div>
</div>
<!--POP UP START HERE-->
<div class="col-md-12">
	<div class="form-group">
		<div class="input-group">
			<ng-template #tripDeliveryLrsDetailsPopUpTemplate let-ok="close" let-d="dismiss">
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<div class="row">
									<div *ngIf="showSpinnerForActionReasonPopup" class="col-md-12 p-t-10">
										<div class="form-group">
											<div class="input-group">
												<mat-progress-bar mode="indeterminate" style="color: green;">
												</mat-progress-bar>
												<br>
												<h6 class="card-title" align='center'
													style="color: green; margin: auto; font-size: 15px;">Please
													Wait Loading....</h6>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Reason</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-comments"></i>
														</span>
													</div>
													<input #reasonName id="{{pageId}}reasonNameId" type="text"
														class="form-control"
														(selectItem)="clickListnerForReasonName($event)"
														[(ngModel)]="modelReasonName" [ngbTypeahead]="searchReasonName"
														[resultFormatter]="formatterReasonName"
														[inputFormatter]="formatterReasonName"
														(keypress)="onKeyPressReasonNameDropDown($event);"
														(focus)="focusReasonNameTA$.next($any($event).target.value)" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div [hidden]="!viewNewReasonField" class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>New Reason</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-comments"></i>
														</span>
													</div>
													<input id="{{pageId}}newReasonNameId" type="text"
														class="form-control" autocomplete="off" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" style='background: green; color: white;' class="btn btn-outline-dark"
						[hidden]="!viewOkPopUpBtn" id="{{pageId}}okPopupBtnId"
						(click)="okBtnClickReasonPopUp()">Ok</button>
					<button type="button" style='background: green; color: white;' class="btn btn-outline-dark"
						[hidden]="!viewconfirmCancelPopUpBtn" id="{{pageId}}confirmCancelPopupBtnId"
						(click)="confirmCancelBtnClickReasonPopUp()">Confirm
						Cancel</button>
					<button type="button" style='background: black; color: white;' class="btn btn-outline-dark"
						(click)="closePopUp()">Cancel</button>
				</div>
			</ng-template>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;" id="{{pageId}}hideContent">
	<button type="button" style='background: green; color: white; width: auto;' class="btn btn-outline-dark"
		id="{{pageId}}okPopupBtnId" (click)="btnPopSave();">Save</button>
	<button type="button" style='background: black; color: white; width: auto;' class="btn btn-outline-dark"
		(click)="clearAll();">Clear</button>
	<button class="btn btn-danger" style='width: auto;' mat-button (click)="onCancelClick()">Cancel</button>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>