import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from "sweetalert";
import { ReportService } from "src/app/dataService/report-service";

@Component( {
    selector: 'app-agent-ranking',
    templateUrl: './agent-ranking.component.html',
    styleUrls: ['./agent-ranking.component.css']
} )
export class AgentRankingComponent implements  OnInit {

	agentRankingDataList: any;
    dtTriggerAgentRanking: Subject<any> = new Subject();
    dtOptionsAgentRanking: any;
    
    lrDtoForBtnSearch: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    isLoggedIn = true;
    pageId="agrac";

    constructor( private reportService: ReportService,private router: Router, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    ngOnInit(): void {
        this.dtOptionsAgentRanking = {
            dom: 'Bfrtip',
            // language: {
            //     search: "_INPUT_",
            //     searchPlaceholder: "Search..."
            // },
            buttons: [
  			],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
            // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerAgentRanking.unsubscribe();
    // }

  //for redirect to other page starts
    goToRankingPage() {
        this.router.navigate( ['/report/agents/ranking'] );
    }
  //for redirect to other page ends
  
      getUserValues() {
        this.lrDtoForBtnSearch = new LRDto();
        this.lrDtoForBtnSearch.mode = "ALL";
        this.lrDtoForBtnSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDtoForBtnSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForBtnSearch.id = 0;
    }

    getDetails = () => {
        this.showSpinnerForAction = true;
        this.getUserValues();
        this.reportService.getAgentRankingReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                //$("#"+this.pageId+"agentRankingTableId").DataTable().destroy();
                this.agentRankingDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Agent Ranking Details",
                        text: "No Records found for this search!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.agentRankingDataList = response;

                }
                // this.dtTriggerAgentRanking.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Ranking details", "info");
            }, () => console.log('done');
    };
    
    ngAfterViewInit(): void {
        this.dtTriggerAgentRanking.next();
    }
}

