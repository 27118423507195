import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends
import { CustomizationRoutes } from 'src/app/stock/customization/customization.routing';
import { ManualStockCheckComponent } from 'src/app/stock/customization/manual-stock-check/manual-stock-check.component';
import { MaterialModule } from "src/app/material.module";
//import { DashboardModule } from "src/app/dashboards/dashboard.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';




@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( CustomizationRoutes ), 
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule,CashmemoReportModule],
    declarations: [
        ManualStockCheckComponent,
    ]
} )
export class CustomizationModule { }
