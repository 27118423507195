<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row " *ngIf="isLoggedIn" id="{{pageId}}showLorryHireStmtRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Supplier Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}supplierName" type="text" class="form-control" [(ngModel)]="modelSupplierName" (selectItem)="rowSelectedSupplierName($event)" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName"
                                                            (focus)="focusSupplierNameTA$.next($any($event).target.value)" placeholder="Select Supplier" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="hidePannumber" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Pan Number*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-truck"></i>
															</span>
                                                        </div>
                                                        <input #supplierPanNumber id="{{pageId}}supplierPanNumber" [(ngModel)]="modelSupplierNamePaNumber" name="supplierPanNumber" type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <hr style="width: 80%; border-top: none; margin: 3px;">
                                        <div class="col-md-12" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getSearchMethod()">Search</button>
                                            <button type="submit" (click)="clearAll()" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                                        </div>
                                    </div>



                                    <div class="col-md-9 vl p-t-10">
                                        <div class="card-body">
                                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row p-t-10">

                                                <div class="col-md-6">
                                                    <h6 class="card-title">Hire Details For {{supplierNameSet}}</h6>


                                                </div>
                                                <div class="col-md-6">

                                                    <button type="submit" class="dt-button" style="margin-left:390px;" id="{{pageId}}printBtn" (click)="printMethodForLorryHireStmt();">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                </div>
                                            </div>
                                            <div class="box-body">

                                                <table datatable id="{{pageId}}lrryHireStmtId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLorryHireStmt" [dtTrigger]="dtTriggerLorryHireStmt">


                                                    <thead>
                                                        <tr>
                                                            <th>Truck No</th>
                                                            <th>Date</th>
                                                            <th>Hireslip No</th>
                                                            <th>Route</th>
                                                            <th>Total Hire</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let lorryHireStmtData of lorryHireStmtDataList ">
                                                            <td>{{ lorryHireStmtData.vehicleNumber }}</td>
                                                            <td>{{ lorryHireStmtData.hireSlipDateStr }}</td>
                                                            <td>{{ lorryHireStmtData.hireslipnumber }}</td>
                                                            <td>{{ lorryHireStmtData.column1 }}</td>
                                                            <td>{{ lorryHireStmtData.totalhire }}</td>
                                                        </tr>

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>