<html>
<head>
<style>
</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Send SMS Group Details</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-5">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Group Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="ti-user"></i>
												</span>
											</div>
											<input id="{{pageId}}grpName" type="text" name="grpName"
												class="form-control" [(ngModel)]="modelGrpName"
												[ngbTypeahead]="searchGrpName"
												(selectItem)="clickListnerForGrpName($event)"
												[resultFormatter]="formatterGrpName"
												[inputFormatter]="formatterGrpName"
												(focus)="focusGrpNameTA$.next($any($event).target.value)"
												placeholder="Select Group Name" />
										</div>
									</div>
								</div>
								<div *ngIf="addNewGroupName" class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>New Group Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-users"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}newGroupName" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Member Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}memberName" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Mobile Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-phone"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}mobileNumber" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" style="text-align: center;">
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}saveBtn" (click)="confirmSaveGrpDetails()">Save</button>
									<button type="submit" (click)="confirmDeleteGrp()"
										class="btn btn-dark" id="{{pageId}}clearBtn">Delete Group</button>
									<button type="submit" (click)="clearAllFields()"
										class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
								</div>
							</div>

						</div>

					</div>
					<div class="col-md-6 vl p-t-10">
						<div class="card-body">
							<div class="row">
								<div class="col-md-8">
									<h6 class="card-title">Contact Details</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="box-body">

										<table datatable id="{{pageId}}smsGrpDetailsTableId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsSendSmsGroupDetails"
											[dtTrigger]="dtTriggerSendSmsGroupDetails">
											<thead>
												<tr>
													<th>Name</th>
													<th>Mobile Number</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr
													*ngFor="let sendSmsGroupDetailsData of sendSmsGroupDetailsDataList">
													<td>{{ sendSmsGroupDetailsData.name }}</td>
													<td>{{ sendSmsGroupDetailsData.mobileNum }}</td>
													<td><button type="button"
															style="padding: 1px 4px; background-color: lightgrey;"
															class="btn m-r-10"
															(click)="rowSelected(sendSmsGroupDetailsData);">
															<i title="Edit" class="fas fa-edit"></i>
														</button>
														<button type="button"
															style="padding: 1px 4px; background-color: lightgrey;"
															class="btn m-r-10"
															(click)="confirmDeleteGrpDetails(sendSmsGroupDetailsData);">
															<i title="Delete" class="fas fa-trash"></i>
														</button></td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>

								<div class="col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}popupDetailsTwo">
											<ng-template #content let-ok="close" let-yes="close"
												let-no="close" let-d="dismiss">
											<div class="modal-body">
												<div class="row" style="text-align: center;">
													<div class="col-md-12">
														<h5 class="m-b-10">
															<i class="fas fa-exclamation-triangle"></i> Row Selection
															Is Mandatory
														</h5>
														<!-- 														<h5 class="m-b-10">Sure you want to delete</h5> -->
													</div>
													<div class="col-md-12 p-t-10">
														<button type="button" class="btn btn-outline-secondary"
															(click)="ok('Yes click')">Ok</button>
														<!-- 														<button type="button" class="btn btn-outline-secondary" -->
														<!-- 															(click)="yes('Yes click')">Yes</button> -->
														<!-- 														<button type="button" class="btn btn-outline-danger" -->
														<!-- 															(click)="no('No click')">No</button> -->
													</div>
												</div>
											</div>
											</ng-template>


										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>