<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Expenses Heads</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<h6 class="card-title">Other Expenses</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Expenses Heads</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
												</div>
												<input #expensesHeads (keyup)="fieldFocus($event, balance)"
													type="text" class="form-control"
													aria-describedby="basic-addon11" id="{{pageId}}expensesHeads"
													name="expensesHeads" [(ngModel)]="hireSlipDto.expensesHead">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}balance">
												<label>Balance</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #balance (keyup)="fieldFocus($event, saveBtn)"
													type="number" class="form-control"
													aria-describedby="basic-addon11" id="{{pageId}}balance"
													name="balance" [(ngModel)]="hireSlipDto.balance">
											</div>
										</div>
									</div>
								</div>
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" style="text-align: center;">
									<button #saveBtn type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}saveBtn" (click)="validateExpensesHeadDetails()">Save</button>
									<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
								</div>
							</div>
							<div class="col-md-9 vl">
								<div class="box-body">
									<!-- 									<h6 class="card-title border_bottom">Paid Report 1</h6> -->
									<table datatable
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsExpensesHeads"
										[dtTrigger]="dtTriggerExpensesHeads">

										<thead>
											<tr>
												<th>Description</th>
												<th>OS Balance</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let expensesHeadsData of expensesHeadsDataList "
												(click)="rowSelected(expensesHeadsData);">
												<td>{{ expensesHeadsData.expensesHead }}</td>
												<td>{{ expensesHeadsData.balance }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>
