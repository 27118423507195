import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { merge } from 'rxjs';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';

@Component({
    selector: 'app-office-report',
    templateUrl: './office-report.component.html',
    styleUrls: ['./office-report.component.css']
})
export class OfficeReportComponent implements OnInit {

    truckWiseDataList: any;
    destWiseDataList: any;
    model: NgbDateStruct;
    dtTriggerTruckWise: Subject<any> = new Subject();
    dtTriggerDestWise: Subject<any> = new Subject();
    dtOptionsTruckWise: any;
    dtOptionDestWise: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction: boolean = false;
    sourceAgentDtoForAll: AgentDetailsDto = new AgentDetailsDto();
    sourceList: any;
    sourceAgentDtoSelected: AgentDetailsDto = new AgentDetailsDto();
    sourceOptions: AgentDetailsDto[];
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();

    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationForAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    cbSourceViewSupAdmin: boolean = false;

    hireSlipDtoForDestinationWise: HireSlipDto = new HireSlipDto();
    validateSelectedDate: any;
    validateSelectedSource: any;
    validateSelectedDestination: any;

    listSrc: Array<String>;
    //listDest: Array<String>;
    listDest: string[];

    //print
    viewCustomPrintV1 = false;
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;

    pageId = "offrc";
    showHireColumns: Boolean = false;

    constructor(private router: Router, private mastereadService: MasterReadService,
        public dialog: MatDialog, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Source OfficeDlyRptSupAdmin") {
                        this.cbSourceViewSupAdmin = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Office Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
            this.getSourceDetails();
            this.getDestinationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    ngOnInit(): void {
        var exportHireTruckDetailsArray = [];
        if (this.showHireColumns) {
            exportHireTruckDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        } else {
            exportHireTruckDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 9, 10];
        }

        this.dtOptionsTruckWise = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: exportHireTruckDetailsArray
                    },
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
        this.dtOptionDestWise = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerTruckWise.next();
        this.dtTriggerDestWise.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerTruckWise.unsubscribe();
        this.dtTriggerDestWise.unsubscribe();
    }

    //for datepicker
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    //for datePicker

    clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "source").val(this.modelSource.subStation);
    }

    getSourceDetails() {
        if (this.cbSourceViewSupAdmin) {
            this.sourceAgentDto = new AgentDetailsDto();
            this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
            this.sourceAgentDto.mode = "Sub";
            this.sourceAgentDto.status = "ALL";
            this.showSpinnerForAction = true;
            this.mastereadService.getSourceListDetails(this.sourceAgentDto).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    if (response.length == 0) {
                        this.sourceOptions = [];
                        this.sourceTA = [];
                        this.sourceAgentDtoForAll = new AgentDetailsDto();
                        this.sourceAgentDtoForAll.subStation = "All";
                        this.sourceTA.push(this.sourceAgentDtoForAll);
                    } else {
                        this.sourceOptions = [];
                        this.sourceTA = [];
                        this.listSrc = [];
                        this.sourceOptions = response;
                        this.sourceAgentDtoForAll = new AgentDetailsDto();
                        this.sourceAgentDtoForAll.subStation = "All";
                        this.sourceTA.push(this.sourceAgentDtoForAll);
                        for (let i = 0; i < this.sourceOptions.length; i++) {
                            this.sourceTA.push(this.sourceOptions[i]);
                            this.listSrc.push(this.sourceOptions[i].subStation);
                        }
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred While getting the Source Details", "info");
                },
                () => console.log("done");
        } else {
            this.sourceList = this.userDataDtoReturnSession.stationList;
            this.sourceAgentDtoForAll = new AgentDetailsDto();
            this.sourceAgentDtoForAll.subStation = "All";
            this.sourceTA.push(this.sourceAgentDtoForAll);
            console.log(this.sourceList);
            if (this.sourceList != null &&
                this.sourceList != undefined &&
                this.sourceList.length > 0) {
                for (let i = 0; i < this.sourceList.length; i++) {
                    this.sourceAgentDtoSelected = new AgentDetailsDto();
                    this.sourceAgentDtoSelected.subStation = this.sourceList[i];
                    this.sourceTA.push(this.sourceAgentDtoSelected);
                }
            }
        }
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.mode = "other";
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;

    }
    getDestinationDetails = () => {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.listDest = [];
                    this.destinationOptions = response;
                    this.lrDtoDestinationForAll = new LRDto();
                    this.lrDtoDestinationForAll.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                        this.listDest.push(this.destinationOptions[i].destination);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.lrDtoDestinationForAll = new LRDto();
                    this.lrDtoDestinationForAll.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationForAll);
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };


    validateSearchBtn() {
        if ($("#" + this.pageId + "dates").val() == null || $("#" + this.pageId + "dates").val() == undefined ||
            $("#" + this.pageId + "dates").val() == "" || $("#" + this.pageId + "source").val() == null ||
            $("#" + this.pageId + "source").val() == undefined || $("#" + this.pageId + "source").val() == "" ||
            $("#" + this.pageId + "destinationId").val() == null || $("#" + this.pageId + "destinationId").val() == undefined ||
            $("#" + this.pageId + "destinationId").val() == "") {

        } else {
            this.gridReconfigureBookingOfficeDailyReportMain();
        }
    }




    gridDestinationWiseReconfigure() {
        this.hireSlipDtoForDestinationWise = new HireSlipDto();
        this.validateSelectedDate = $("#" + this.pageId + "dates").val();
        this.validateSelectedSource = $("#" + this.pageId + "source").val();
        this.validateSelectedDestination = $("#" + this.pageId + "destinationId").val();

        this.hireSlipDtoForDestinationWise.hireSlipDateStr = this.validateSelectedDate;
        this.hireSlipDtoForDestinationWise.toStation = this.validateSelectedDestination;
        this.hireSlipDtoForDestinationWise.fromStation = this.validateSelectedSource;
        if (this.cbSourceViewSupAdmin) {
            this.hireSlipDtoForDestinationWise.list = this.listSrc;
        } else {
            this.hireSlipDtoForDestinationWise.list = this.userDataDtoReturnSession.stationList;
        }
        this.hireSlipDtoForDestinationWise.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoForDestinationWise.listOfDest = this.listDest;
        this.showSpinnerForAction = true;
        this.lrService.getBookingOfficeDailyReportDestinationWise(this.hireSlipDtoForDestinationWise).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                this.destWiseDataList = [];
                $("#" + this.pageId + "destOfOfficeRptTable").DataTable().destroy();
                if (response.length > 0) {
                    this.destWiseDataList = response;
                }
                this.dtTriggerDestWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Office Daily Rpt Destination Details", "error");
            },
            () => console.log("done");
    };


    gridReconfigureBookingOfficeDailyReportMain() {
        this.hireSlipDtoForDestinationWise = new HireSlipDto();
        this.validateSelectedDate = $("#" + this.pageId + "dates").val();
        this.validateSelectedSource = $("#" + this.pageId + "source").val();
        this.validateSelectedDestination = $("#" + this.pageId + "destinationId").val();

        this.hireSlipDtoForDestinationWise.hireSlipDateStr = this.validateSelectedDate;
        this.hireSlipDtoForDestinationWise.hireslipdate = this.validateSelectedDate;
        this.hireSlipDtoForDestinationWise.toStation = this.validateSelectedDestination;
        this.hireSlipDtoForDestinationWise.fromStation = this.validateSelectedSource;
        if (this.cbSourceViewSupAdmin) {
            this.hireSlipDtoForDestinationWise.list = this.listSrc;
        } else {
            this.hireSlipDtoForDestinationWise.list = this.userDataDtoReturnSession.stationList;
        }
        this.hireSlipDtoForDestinationWise.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoForDestinationWise.listOfDest = this.listDest;
        this.showSpinnerForAction = true;
        this.lrService.getBookingOfficeDailyReportMainDetails(this.hireSlipDtoForDestinationWise).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                this.truckWiseDataList = [];
                $("#" + this.pageId + "officeRptTableId").DataTable().destroy();
                if (response.length == 0) {
                    this.dtTriggerTruckWise.next();
                    swal("No Data", "No Records  found for this search", "warning");
                } else {
                    this.truckWiseDataList = response;
                    this.dtTriggerTruckWise.next();
                    this.gridDestinationWiseReconfigure();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Office Daily Rpt Main Details", "error");
            },
            () => console.log("done");
    };

    validateClearBtn() {
        this.modelSource = null;
        this.modelDestination = null;
        this.validateSelectedDate = null;
        $("#" + this.pageId + "dates").val('');
        this.validateSelectedSource = null;
        $("#" + this.pageId + "source").val('');
        this.validateSelectedDestination = null;
        $("#" + this.pageId + "destinationId").val();

        this.truckWiseDataList = [];
        $("#" + this.pageId + "officeRptTableId").DataTable().destroy();
        this.dtTriggerTruckWise.next();

        this.destWiseDataList = [];
        $("#" + this.pageId + "destOfOfficeRptTable").DataTable().destroy();
        this.dtTriggerDestWise.next();
    }

}
