<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrHistoryRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Lr History Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="lrNo">LR Number</option>
															<option value="dateWise">Date Wise</option>
															<option value="userName">User Name</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="searchByLrNo" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>LR No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}lrNumber" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="searchByDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="searchByDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															[(ngModel)]="toDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="searchByUserName" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Station</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																(selectItem)="destinationChange($event)"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="searchByUserName" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>User Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input id="{{pageId}}userName" type="text" class="form-control"
															[(ngModel)]="modelUserName" [ngbTypeahead]="searchUserName"
															[resultFormatter]="formatterUserName"
															[inputFormatter]="formatterUserName"
															(focus)="focusUserNameTA$.next($any($event).target.value)"
															placeholder="Select User" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}mode">
															<option selected value=''>Select Type</option>
															<option value="All">ALL</option>
															<option value="edit">Edit</option>
															<option value="delete">Delete</option>
														</select>
													</div>
												</div>
											</div>





										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="searchMethod()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearMethod()">Clear</button>
						</div>
					</div>

					<!-- 					<div class="col-md-9 vl p-t-10"> -->
					<!-- 						<div class="box-body"> -->
					<!-- 							<table datatable -->
					<!-- 								class="table table-striped table-bordered row-border hover" -->
					<!-- 								[dtOptions]="dtOptionsLrHistoryRpt" -->
					<!-- 								[dtTrigger]="dtTriggerLrHistoryRpt"> -->

					<!-- 								<thead> -->
					<!-- 									<tr> -->
					<!-- 										<th>LR Number</th> -->
					<!-- 										<th>Type</th> -->
					<!-- 										<th>Art</th> -->
					<!-- 										<th>Booking Date</th> -->
					<!-- 										<th>Source</th> -->
					<!-- 										<th>Destination</th> -->
					<!-- 										<th>Agent</th> -->
					<!-- 										<th>Consignee Name</th> -->
					<!-- 										<th>Consignor Name</th> -->
					<!-- 										<th>Entered By</th> -->
					<!-- 										<th>Act Wt</th> -->
					<!-- 										<th>Chg Wt</th> -->
					<!-- 										<th>To Pay</th> -->
					<!-- 										<th>Paid</th> -->
					<!-- 									</tr> -->
					<!-- 								</thead> -->
					<!-- 								<tbody> -->
					<!-- 									<tr *ngFor="let lrHistoryRptData of lrHistoryRptDataList "> -->
					<!-- 										<td>{{ lrHistoryRptData.lrNumber }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.type }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.art }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.bookingDate }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.source }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.destination }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.agent }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.consigneeName }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.consignorName }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.enteredBy }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.actWt }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.chgWt }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.toPay }}</td> -->
					<!-- 										<td>{{ lrHistoryRptData.paid }}</td> -->
					<!-- 									</tr> -->


					<!-- 									<tr *ngIf = "supList.length==0">
<!-- 														<td colspan="9">No Data To Display</td> -->
					<!-- 													</tr>-->

					<!-- 								</tbody> -->
					<!-- 							</table> -->


					<!-- 						</div> -->
					<!-- 					</div> -->


					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">


										<table width="100%">
											<tr>
												<td width="80%">
													<h6 class="card-title">LR History Report</h6>
												</td>
												<td width="20%">
													<button type="submit" class="dt-button" style="margin-left: 60%;"
														(click)="customPrintLrHistoryRpt()" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
												</td>
											</tr>
										</table>


										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}lrHistoryRpt"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsLrHistoryRpt"
														[dtTrigger]="dtTriggerLrHistoryRpt">

														<thead>
															<tr>
																<th>LR Number</th>
																<th>Type</th>
																<th>Art</th>
																<th>Book Date</th>
																<th>Src</th>
																<th>Dest</th>
																<th>Agt</th>
																<th>Consignee</th>
																<th>Consignor</th>
																<th>Entered By</th>
																<th>Act Wt</th>
																<th>Chg Wt</th>
																<th>To Pay</th>
																<th>Paid</th>
															</tr>
														</thead>
														<tbody>
															<tr *ngFor="let lrHistoryRptData of lrHistoryRptDataList ">
																<td>{{ lrHistoryRptData.lrNumber }}</td>
																<td>{{ lrHistoryRptData.invoiceType }}</td>
																<td>{{ lrHistoryRptData.totalArticles }}</td>
																<td>{{ lrHistoryRptData.bookDate }}</td>
																<td>{{ lrHistoryRptData.source }}</td>
																<td>{{ lrHistoryRptData.destination }}</td>
																<td>{{ lrHistoryRptData.agentName }}</td>
																<td>{{ lrHistoryRptData.consigneeName }}</td>
																<td>{{ lrHistoryRptData.consignorName }}</td>
																<td>{{ lrHistoryRptData.userName }}</td>
																<td>{{ lrHistoryRptData.actualWeight }}</td>
																<td>{{ lrHistoryRptData.chargeWeight }}</td>
																<td>{{ lrHistoryRptData.toPay }}</td>
																<td>{{ lrHistoryRptData.paid }}</td>
															</tr>

														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>

															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>
					</div>



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>