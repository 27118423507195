import { Component, OnInit, ViewChildren, QueryList, OnDestroy,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { StockService } from 'src/app/dataService/stock-service';

//for datatable ends

@Component({
    selector: 'app-trips-on-transit',
    templateUrl: './trips-on-transit.component.html',
    styleUrls: ['./trips-on-transit.component.css']
})
export class TripsOnTransitComponent implements  OnInit {

    getDataTripsOnTransitFromDataTable: any;

    tripsOnTransitDataList: any;

    onDestroyUnsubscribtionTripsOnTransit: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTripsOnTransit: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsTripsOnTransit: any;

    hireSlipDto: HireSlipDto = new HireSlipDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    hireSlipDtoForSelectedRow: HireSlipDto = new HireSlipDto();
    hireSlipDtoForTripOnTransitLocalStorage: any;
    pageId="totc";

    constructor(private dashboardService: DashboardService, private router: Router,
        private stockService: StockService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    ngOnInit(): void {
        this.dtOptionsTripsOnTransit = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true        
               }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerTripsOnTransit.next();
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerTripsOnTransit.unsubscribe();
    //     //this.onDestroyUnsubscribtionTripsOnTransitFrom.unsubscribe();
    // }

    gridReconifgureOnReloadBtn() {
        this.getTripsOnTransitDetailsList();
    }

    getTripsOnTransitDetails() {
        this.hireSlipDto = new HireSlipDto();
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.status = "Transit";
        this.hireSlipDto.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDto.mode = "BMDB";
        this.hireSlipDto.role = this.userDataDtoReturnSession.role;
        this.hireSlipDto.stocksAt = this.userDataDtoReturnSession.stationType;
        this.hireSlipDto.officeType = this.userDataDtoReturnSession.officeType;
        this.hireSlipDto.type = "Both";
        this.hireSlipDto.fromstation = "Transit Trips";
        this.hireSlipDto.stationList = this.userDataDtoReturnSession.stationList;
    }

    getTripsOnTransitDetailsList() {
        this.getTripsOnTransitDetails();
        this.showSpinnerForAction = true;
        this.dashboardService.getScheduledTripsDetails(this.hireSlipDto).subscribe(
            (response) => {
                this.tripsOnTransitDataList = [];
                //$("#"+this.pageId+"tripOnTransitTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.tripsOnTransitDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerTripsOnTransit.next();
               this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Trip On Transit Details", "warning");
            },
            () => console.log('done');
    };

    getSelectedRowOfHireslipDetails(tripsOnTransitData) {
        this.hireSlipDtoForSelectedRow = new HireSlipDto();
        this.hireSlipDtoForSelectedRow.hireslipnumber = tripsOnTransitData.hireslipnumber;
        this.hireSlipDtoForSelectedRow.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForSelectedRow.companyid = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.stockService.getHireSlipDetails(this.hireSlipDtoForSelectedRow).subscribe(
            (response) => {
                console.log(response.mode);
                console.log(response.type);
                if (response.status != null &&
                    response.status != "Transit") {
                    swal("Not Allowed", "This Invoice is in " + response.status +
                        " .Please Click the Refresh Symbol Below For Updates", "warning");
                } else {
                    this.hireSlipDtoForTripOnTransitLocalStorage = null;
                    this.hireSlipDtoForTripOnTransitLocalStorage = response;
                    this.hireSlipDtoForTripOnTransitLocalStorage.mode = "view";
                    this.hireSlipDtoForTripOnTransitLocalStorage.type = "Destination";
                    localStorage.clear();
                    localStorage.setItem('tripOnTransitLocalStorage',
                        JSON.stringify(this.hireSlipDtoForTripOnTransitLocalStorage));
                    this.router.navigate(['/trip-creation/stocksGrouping']);
                    console.log(localStorage);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Invoice Details", "warning")
            }, () => console.log('done');
    };

}
