<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
				Please Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Booking Agent Statement
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="gridReconifgureOnReloadBtn()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}bookingAgentStmtDtlsTableId" class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsBookingAgentStatement" [dtTrigger]="dtTriggerBookingAgentStatement">
			<thead>
				<tr>
					<th>Agent Name</th>
					<th>Stmt Dt</th>
					<th>Amt To Be Paid</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let bookingAgentStatementData of bookingAgentStatementDataList ">
					<td style="color: blue; cursor: pointer; text-decoration: underline;"
						(click)="getBookingAgentStmtHyperLinkDetails(bookingAgentStatementData);">
						{{ bookingAgentStatementData.agentName }}
					</td>
					<td>{{ bookingAgentStatementData.stmtDateStr }}</td>
					<td>{{ bookingAgentStatementData.toBePaidAmt }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>