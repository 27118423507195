<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Bank Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> Bank Name </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-university"></i>
														</span>
                                                    </div>
                                                    <input #bankName (keyup)="fieldFocus($event, balanceInCash)" type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="userDataDto.bankName" id="{{pageId}}bankName" name="bankName">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Balance (In Cash)</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input #balanceInCash type="number" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="userDataDto.paymentAmt" id="{{pageId}}balanceInCash">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style="width: 80%;">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Cheque Details</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <!-- <label>Cheque Details</label> -->
                                                    <select class="custom-select col-12" [(ngModel)]="userDataDto.chqType" id="{{pageId}}chequeDetails">
														<option selected value="">Select Cheque Type...</option>
														<option value="Cheque">Cheque</option>
														<option value="Provision Cheque">Provision Cheque</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input type="number" id="{{pageId}}chequeNo" #chequeNo (keyup)="fieldFocus($event, chequeAmt)" [(ngModel)]="userDataDto.chqNumber" placeholder="Enter Cheque No Here...." class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input type="number" id="{{pageId}}chequeAmt" #chequeAmt (keyup)="fieldFocus($event, addBtnCheck)" [(ngModel)]="userDataDto.chqAmt" placeholder="Enter Amt here.." class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 pd_1_at_largeView" style="display: none;">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #hiddenIndexCheck type="number" id="{{pageId}}hiddenIndexCheck" name="hiddenIndexCheck" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <a href="javascript:;" type='button' id="{{pageId}}addInTableCheck" #addBtnCheck class="btn btn-icon-only yellow" (click)="addInTableCheck();"> <i class="fa fa-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtnCheck" (click)="rowSelectedDeleteCheck()">
														<i title="Remove" class="fas fa-trash"></i>
													</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2 pd_1_at_largeView">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}cc">
                                                    <button style="padding: 2px;" type="submit" class="btn btn-success m-r-10" id="{{pageId}}ccBtn" title="Cheque Clearance" (click)="checkClearenceValidate()">CC</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="box-body">
                                                    <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsChequeDetails" [dtTrigger]="dtTriggerChequeDetails">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <!-- 																<th>Cheque Number</th> -->
                                                                <th>Chq No</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let chequeDetailsData of chequeDetailsDataList;let i = index" (click)="chqRowSelected( chequeDetailsData,i );">

                                                                <td>{{ chequeDetailsData.chqType }}</td>
                                                                <td>{{ chequeDetailsData.chqNumber }}</td>
                                                                <td>{{ chequeDetailsData.chqAmt }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 vl">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">PDC Details</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <!-- <label>Cheque Details</label> -->
                                            <select class="custom-select col-12" [(ngModel)]="userDataDto.pdcType" id="{{pageId}}pdcType">
												<option selected value="">Select Type...</option>
												<option value="BDG">BDG</option>
												<option value="BNG">BNG</option>
												<option value="MDU">MDU</option>
											</select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="number" placeholder="Enter Cheque No Here...." id="{{pageId}}pdcChequeNo" #pdcChequeNo (keyup)="fieldFocus($event, pdcAmount)" [(ngModel)]="userDataDto.pdcChequeNo" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="number" id="{{pageId}}pdcAmount" #pdcAmount (keyup)="fieldFocus($event, addBtnPdc)" [(ngModel)]="userDataDto.pdcAmount" placeholder="Enter Amt here.." class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 pd_1_at_largeView" style="display: none;">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input #hiddenIndexPdc type="number" id="{{pageId}}hiddenIndexPdc" name="hiddenIndexPdc" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <a href="javascript:;" type='button' id="{{pageId}}addInTablePdc" #addBtnPdc class="btn btn-icon-only yellow" (click)="addInTablePdc();"> <i class="fa fa-plus"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtnPdc" (click)="rowSelectedDeletePdc()">
												<i title="Remove" class="fas fa-trash"></i>
											</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="card-body">
                                        <div class="box-body">
                                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPDCDetails" [dtTrigger]="dtTriggerPDCDetails">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Chq No</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let pdcDetailsData of pdcDetailsDataList; let j = index" (click)="pdcRowSelected( pdcDetailsData,j );">

                                                        <td>{{ pdcDetailsData.source }}</td>
                                                        <td>{{ pdcDetailsData.billNumber }}</td>
                                                        <td>{{ pdcDetailsData.paid }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateBankDetails()">Save</button>
                        <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                    </div>
                </div>
                <div class="col-md-6 vl ">
                    <div class="card-body">
                        <div class='row p-t-10'>
                            <div class="col-sm-12 col-md-8">
                                <h6 class="card-title border_bottom">Bank Master</h6>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <button style="padding: 2px;" type="submit" class="btn m-r-10" id="{{pageId}}bankMasterRemoveBtn" (click)="validateDeleteRow()">Remove</button>
                            </div>
                        </div>
                        <div class="box-body">
                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBankMaster" [dtTrigger]="dtTriggerBankMaster">

                                <thead>
                                    <tr>
                                        <th>Bank Name</th>
                                        <th>Amount(In Cash)</th>
                                        <th>Cheque Amount</th>
                                        <th>total Balance</th>
                                        <th>Provisional Cheque Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bankMasterData of bankMasterDataList" (click)="rowSelected( bankMasterData );">
                                        <td>{{ bankMasterData.bankName }}</td>
                                        <td>{{ bankMasterData.paymentAmt }}</td>
                                        <td>{{ bankMasterData.chqAmt }}</td>
                                        <td>{{ bankMasterData.totalAmount }}</td>
                                        <td>{{ bankMasterData.proviAmt }}</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>