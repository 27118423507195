<html>

<head>
</head>

<body>
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLocalChallanRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
                                                        </div>

                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)" (keydown.enter)="searchByModeFocus($event,searchBy.value)">
															<option selected value="UnLoading">Unloading</option>
															<option value="loading">Loading</option>
														</select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="viewSearchOption" class="col-sm-12 col-md-12">
                                                <div class="input-group">
                                                    <label>Search Option</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchByOptionId" name="searchByOptionId" #searchByOptionId (change)="searchByOption(searchByOptionId.value)" (keydown.enter)="searchByOptionFocus($event,searchByOptionId.value)">
														<option selected value="challanNo">Challan Number
														</option>
														<option value="dateWise">Date Wise</option>
													</select>
                                                </div>
                                            </div>
                                            <hr>
                                            <div *ngIf="viewChallanNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Challan No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="icon-speech"></i>
															</span>
                                                        </div>
                                                        <input #challanNo id="{{pageId}}challanNo" type="text" (keyup)="challanNoEvent($event)" class="form-control" aria-describedby="basic-addon11">
                                                    </div>

                                                </div>
                                            </div>

                                            <div *ngIf="viewFromDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker"
                                                            (click)="fromDates.toggle()" (keyup)="focusFromDateEnter($event)" (keydown.arrowdown)="fromDates.toggle();" (ngModelChange)="focusFromDate();">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewToDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="setTodayDateOnToDate" class="form-control" placeholder="yyyy-mm-dd" name="toDate" ngbDatepicker #toDate="ngbDatepicker" (keyup)="focusToDateEnter($event)"
                                                            (keydown.arrowdown)="toDate.toggle();" (ngModelChange)="focusToDate();">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- 											the first autocomplete starts -->
                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <label>Destination</label>

                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-home"></i>
																</span>
                                                            </div>

                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Station" (keyup)="getDestinationEvent($event)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewAgentName" class="col-sm-12 col-md-12">



                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <label>Agent Name</label>

                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-home"></i>
																</span>
                                                            </div>

                                                            <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="challanAgentName" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                                (focus)="focusAgentNameTA$.next($any($event).target.value)" #instanceAgentName="ngbTypeahead" (keyup)="getAgentNameEvent($event)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewChoudryName" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <label>Choudhary Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-home"></i>
																</span>
                                                            </div>

                                                            <input #loaderHead id="{{pageId}}loaderHead" type="text" class="form-control" placeholder="Select Choudhary" [ngbTypeahead]="loaderHeadSearchTA" (focus)="focusLoaderHeadTA$.next($any($event).target.value)" [resultFormatter]="formatterChoudharyName" [inputFormatter]="formatterChoudharyName"
                                                                #instanceLoaderHead="ngbTypeahead" (keyup)="getLoaderHeadEvent($event)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchChallanBtn" (click)="updateChallanLabourPopup(contentUpdateChallanLabour)">Challan Update</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearConfirm();">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-10"></div>
                                            <div class="col-md-2">
                                                <button type="submit" class="dt-button" style="margin-left: 42%;" (click)="customPrintUnloading()" id="{{pageId}}printAllBtn" [hidden]="!unloadingTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 42%;" (click)="customPrintLoading()" id="{{pageId}}printAllBtn" [hidden]="!loadingTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!unloadingTable">
                                                    <table datatable id="{{pageId}}unLoadingTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsUnloading" [dtTrigger]="dtTriggerUnloadingTable">
                                                        <thead>
                                                            <tr>
                                                                <th>LR Number</th>
                                                                <th>Booking Date</th>
                                                                <th>Agent Name</th>
                                                                <th>Art</th>
                                                                <th>Act Wgt</th>
                                                                <th>Chg Wgt</th>
                                                                <th>To Pay</th>
                                                                <th>Paid</th>
                                                                <th [hidden]="showHyperLink">Challan No</th>
                                                                <th [hidden]="!showHyperLink">Challan No</th>
                                                                <th>Vehicle No</th>
                                                                <th>Challan Arrival Date</th>
                                                                <th>Local Challan Kanta Wgt</th>
                                                                <th [hidden]="showHyperLink">Choudhary Name</th>
                                                                <th [hidden]="!showHyperLink">Choudhary Name</th>
                                                                <th>Bay No</th>
                                                                <th>Unloading At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let unloadingData of unloadingDataList ">
                                                                <td>{{ unloadingData.lrNumber }}</td>
                                                                <td>{{ unloadingData.bookingDateStr }}</td>
                                                                <td>{{ unloadingData.agentName }}</td>
                                                                <td>{{ unloadingData.totalArticles }}</td>
                                                                <td>{{ unloadingData.actualWeight }}</td>
                                                                <td>{{ unloadingData.chargedWeight }}</td>
                                                                <td>{{ unloadingData.toPay }}</td>
                                                                <td>{{ unloadingData.paid }}</td>
                                                                <td [hidden]="showHyperLink">{{ unloadingData.challanNo }}
                                                                </td>
                                                                <!-- <td *ngIf="viewLocalTripNo" [hidden]="showHyperLink"
																	style="cursor: pointer; color: blue;text-decoration: underline;"
																	(click)="openChallanNoPopUp(contentChallanNo,unloadingData)">
																	{{ unloadingData.challanNo }}</td> -->
                                                                <td [hidden]="!showHyperLink" style="cursor: pointer; color: blue;text-decoration: underline;" (click)="openChallanNoPopUp(contentChallanNo,unloadingData)">
                                                                    {{ unloadingData.challanNo }}</td>
                                                                <td>{{ unloadingData.vehicleNumber }}</td>
                                                                <td>{{ unloadingData.arrivalDateStr}}</td>
                                                                <td>{{ unloadingData.challanKantaWgt }}</td>
                                                                <td [hidden]="showHyperLink">
                                                                    {{unloadingData.unloaderHead }}</td>
                                                                <td [hidden]="!showHyperLink" style="cursor: pointer; color: blue;text-decoration: underline;" (click)="open2(content2,unloadingData)">{{ unloadingData.unloaderHead }}</td>
                                                                <td>{{ unloadingData.bayNo }}</td>
                                                                <td>{{ unloadingData.bayUnloadingTime }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>

                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="showHyperLink"></td>
                                                                <td [hidden]="!showHyperLink"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="showHyperLink"></td>
                                                                <td [hidden]="!showHyperLink"></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>



                                                <div class="box-body" [hidden]="!loadingTable">
                                                    <table datatable id="{{pageId}}loadingTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLoading" [dtTrigger]="dtTriggerLoading">

                                                        <thead>
                                                            <tr>
                                                                <th>Invoice No</th>
                                                                <th>Date</th>
                                                                <th>Truck No</th>
                                                                <th>Truck Lgt</th>
                                                                <th>To Pay</th>
                                                                <th>Paid</th>
                                                                <th>Freight</th>
                                                                <th>Hire</th>
                                                                <th>Advance</th>
                                                                <th>Gur WT</th>
                                                                <th>Kanta Wt</th>
                                                                <th>Actual Wt</th>
                                                                <th>Charged Wt</th>
                                                                <th>Broker</th>
                                                                <th>Truck Type</th>
                                                                <th>Loaded By</th>
                                                                <th [hidden]="showHyperLink">Choudhary Name</th>
                                                                <th [hidden]="!showHyperLink">Choudhary Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let loadingData of loadingDataList ">
                                                                <td>{{ loadingData.hireslipnumber }}</td>
                                                                <td>{{ loadingData.hireSlipDateStr }}</td>
                                                                <td>{{ loadingData.vehicleNumber }}</td>
                                                                <td>{{ loadingData.trkLgth }}</td>
                                                                <td>{{loadingData.toPay }}</td>
                                                                <td>{{loadingData.paid }}</td>
                                                                <td>{{ loadingData.totalAmt }}</td>
                                                                <td>{{ loadingData.totalhire }}</td>
                                                                <td>{{ loadingData.advance}}</td>
                                                                <td>{{ loadingData.gurWeight }}</td>
                                                                <td>{{ loadingData.kantaWeight }}</td>
                                                                <td>{{ loadingData.actWeight }}</td>
                                                                <td>{{ loadingData.chargedWt }}</td>
                                                                <td>{{ loadingData.suppliername }}</td>
                                                                <td>{{ "NA" }}</td>
                                                                <td>{{ loadingData.loadedby }}</td>

                                                                <td [hidden]="showHyperLink">{{ loadingData.loaderHead }}</td>
                                                                <td [hidden]="!showHyperLink" style="cursor: pointer; color: blue;text-decoration: underline;" (click)="open3(content3,loadingData)">{{ loadingData.loaderHead }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="showHyperLink"></td>
                                                                <td [hidden]="!showHyperLink"></td>


                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>









                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>



                </div>
                <!-- Row -->
            </div>
        </div>



    </div>
    <ng-template #content2 let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Change Choudhary Name For Lr Number :{{setLrNo}}
            </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Updated Choudhary Name</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
										</span>
                                        <input id="{{pageId}}updateUnloaderId" type="text" class="form-control" [ngbTypeahead]="updateUnloaderSearchTA" (focus)="focusUpdateUnloaderTA$.next($any($event).target.value)" placeholder="Select Station" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="updateUnloaderChoudharyBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #content3 let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Change Choudhary Name For Invoice No :{{setInvoiceNo}} </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Updated Choudhary Name</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
										</span>
                                    </div>

                                    <input id="{{pageId}}updateUnloaderId" type="text" class="form-control" [ngbTypeahead]="updateUnloaderSearchTA" (focus)="focusUpdateUnloaderTA$.next($any($event).target.value)" placeholder="Select Station" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" (click)="updateloaderChoudharyBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #contentChallanNo let-c="close" let-d="dismiss">

        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Change Challan No for LR Number :<b>
					{{setLrNo}}</b> </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Updated Challan No</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
										</span>
                                    </div>
                                    <input #challanNo id="{{pageId}}updateChallonNoId" type="text" class="form-control" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" (click)="updateChallanNoBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #contentUpdateChallanLabour let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Change Choudhary Name For Challan Number : {{selectedChallanNo}}
            </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Updated Choudhary Name</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
										</span>
                                        <input id="{{pageId}}updateUnloaderId" type="text" class="form-control" [ngbTypeahead]="updateUnloaderSearchTA" (focus)="focusUpdateUnloaderTA$.next($any($event).target.value)" placeholder="Select Choudhary" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="updateUnloaderChoudharyForChallanBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

</body>

</html>