import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { MasterService } from 'src/app/dataService/master-service';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service"; import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LrReportService } from 'src/app/dataService/lr-report-service';
@Component({
    selector: 'app-short-and-extra',
    templateUrl: './short-and-extra.component.html',
    styleUrls: ['./short-and-extra.component.css']
})
export class ShortAndExtraComponent implements OnInit {
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends


    loadingIndicator = true;
    // viewTypeDateWise = true;
    //viewTypeAllWise = true;



    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    shortAndExtraReportDataList: any;
    onDestroyUnsubscribtionShortAndExtraReport: Subscription;


    controlMainSource = new FormControl();
    controlMainDestination = new FormControl();


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerShortAndExtraReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsShortAndExtraReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtosSubStationOptions: any;
    lrDtoSubStation: LRDto = new LRDto();
    public modelSourceSubStationName: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceSubStationNameTA$ = new Subject<string>();
    sourceSubStationNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceSubStationNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceSubStationName = (x: { subStations: string }) => x.subStations;

    destinationOptions: LRDto[];
    lrDtoParticularSubStation: LRDto = new LRDto();
    lrDtoAllOptionForSubStation: LRDto = new LRDto();
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    showSpinnerForAction: boolean = false;
    isSourceView: boolean = false;
    hideShowSourceSubStation: boolean = false;
    rightsRoleForDestination = "Rights Role For Destination";

    validateFromDate: any = null;
    validateToDate: any = null;
    validateSourceSubStation: any = null;
    validateDestination: any = null;

    lrDto: LRDto = new LRDto();
    shortAndExtraReportReportDataList: any;
    fromDatesModal: any = null;
    toDatesModal: any = null;
    @ViewChild('destination') inputEl;
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	enterArtSummary: number;
	articlesSummary: number;
	
	viewCustomPrintV1: boolean;
	pageId="sanec";

    constructor(private shortAndExtraReport: MasterService,
        private router: Router, private masterReadService: MasterReadService,
        private lrReport: LrReportService, private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            for (let key in this.userDataDtoReturnSession.sortedMapFeatures) {
                //Whatever you want to do with key or obj[key]
                //console.log(key);
                if (key == 'Rights') {
                    for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                        //Whatever you want to do with key or obj[key]
                        // console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
                        if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == 'Source ShortExtraLR') {
                            //console.log('Source ShortExtraLR');
                            this.isSourceView = true;
                            this.hideShowSourceSubStation = true;
                            this.getUserGodownList();
                        }
                    }
                }
            }

            //
            /*if (this.rightsRoleForDestination != null &&
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination") {
            */

            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Super Administrator" ||
                this.userDataDtoReturnSession.role == "Transshipment Office" ||
                this.userDataDtoReturnSession.role == "Booking Manager" ||
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.getDestinationDetails();
            } else {
                this.modelDestination = null;
                this.lrDtoParticularSubStation = new LRDto();
                this.lrDtoParticularSubStation.destination = this.userDataDtoReturnSession.mainStation;
                this.destinationTA.push(this.lrDtoParticularSubStation);
            }

        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var booleanSourceViewPrint = this.isSourceView;
        this.dtOptionsShortAndExtraReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {

                        if (booleanSourceViewPrint == true) {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                "<u>LRs Short And Extra Report</u><br><br>" +
                                "Source : " + $("#sanecsourceSubStationNameId").val() + " - " + "Destination : " + $("#sanecdestinationId").val() + " - " +
                                "From Date : " + moment($("#sanecfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#sanectoDates").val()).format('DD-MM-YYYY') + "" +
                                "</td>" +
                                "</tr></table><br>"
                        } else {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                "<u>LRs Short And Extra Report</u><br><br>" +
                                "Destination : " + $("#sanecdestinationId").val() + " - " +
                                "From Date : " + moment($("#sanecfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#sanectoDates").val()).format('DD-MM-YYYY') + "" +
                                "</td>" +
                                "</tr></table><br>"
                        }
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if (booleanSourceViewPrint == true) {
                            return "LRs Short And Extra Report - " +
                                "Source : " + $("#sanecsourceSubStationNameId").val() + " - " + "Destination : " + $("#sanecdestinationId").val() + " - " +
                                "From Date : " + moment($("#sanecfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#sanectoDates").val()).format('DD-MM-YYYY') + "";
                        } else {
                            return "LRs Short And Extra Report - " +
                                "Destination : " + $("#sanecdestinationId").val() + " - " +
                                "From Date : " + moment($("#sanecfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#sanectoDates").val()).format('DD-MM-YYYY') + "";
                        }
                        /* return "LRs Short And Extra Report - " +
                             "From Date : " + moment($("#sanecfromDates").val()).format('DD-MM-YYYY') + " -  " +
                             "To Date : " + moment($("#sanectoDates").val()).format('DD-MM-YYYY') + "";
                             */
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 270,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalArticle = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var enterArt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalArticle);
                $(api.column(3).footer()).html(enterArt);
            },
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerShortAndExtraReport.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerShortAndExtraReport.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    /*viewTypeMode(viewType: string) {
        if (viewType === 'dateWise') {
            this.viewTypeDateWise = true;
            this.viewTypeAllWise = true;
        } else if (viewType === 'allWise') {
            this.viewTypeDateWise = false;
            this.viewTypeAllWise = true;
        } else {
            this.viewTypeDateWise = false;
            this.viewTypeAllWise = false;

        }
    }*/

    sourceSubStationDropDownListner(event) {
        this.modelSourceSubStationName = event.item;
        $("#"+this.pageId+"sourceSubStationNameId").val(event.item.subStations);
    }
    focus($event) {
        //this.modelDestination = '';
        /* console.log("ss");
         console.log($event);
         console.log($event == '');*/
        //$event).target.value

    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }

    getUserValuesForUserGodownList() {
        this.lrDtoSubStation = new LRDto();
        this.lrDtoSubStation.mode = "ALL";
        this.lrDtoSubStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    getUserGodownList() {
        this.showSpinnerForAction = true;
        this.getUserValuesForUserGodownList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSubStation).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.lrDtosSubStationOptions = [];
                    this.sourceSubStationNameTA = [];
                    this.lrDtosSubStationOptions = response;
                    for (let i = 0; i < this.lrDtosSubStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.lrDtosSubStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the SubStation Details", "error");
            },
            () => console.log('done');
    };

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    this.lrDtoAllOptionForSubStation = new LRDto();
                    this.lrDtoAllOptionForSubStation.destination = "ALL";
                    this.destinationTA.push(this.lrDtoAllOptionForSubStation);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "error");
            },
            () => console.log('done');
    };

    clearBtn() {
        this.lrDto = new LRDto();
        this.validateFromDate = null;
        this.validateToDate = null;
        this.validateSourceSubStation = null;
        this.validateDestination = null;
        this.fromDatesModal = null;
        this.toDatesModal = null;

        if (this.isSourceView == true) {
            this.modelSourceSubStationName = null;
            $("#"+this.pageId+"sourceSubStationNameId").val('');
        }
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"destinationId").val('');
        this.modelDestination = null;

        this.shortAndExtraReportReportDataList = [];
        $("#"+this.pageId+"lrShortAndExtraRptTableId").DataTable().destroy();
        this.dtTriggerShortAndExtraReport.next();
    }

    validateSearchBtn() {
        this.lrDto = new LRDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateSourceSubStation = $("#"+this.pageId+"sourceSubStationNameId").val();
        this.validateDestination = $("#"+this.pageId+"destinationId").val();

        this.lrDto.fromDate = this.validateFromDate;
        this.lrDto.toDate = this.validateToDate;
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;

        if ((this.validateFromDate == null) || (this.validateFromDate == undefined) ||
            (this.validateFromDate == "") || (this.validateToDate == null) ||
            (this.validateToDate == undefined) || (this.validateToDate == "")) {
            swal("Mandatory fields", "From date and to date are mandatory fields", "warning");
            return false;
        } else if (this.isSourceView == true) {
            this.getSourceView();
        } else {
            this.getDestinyView();
        }
    }

    getSourceView() {
        this.validateSourceSubStation = $("#"+this.pageId+"sourceSubStationNameId").val();
        this.validateDestination = $("#"+this.pageId+"destinationId").val();
        if (((this.validateDestination != null) && (this.validateDestination != undefined) &&
            (this.validateDestination != "") && (this.validateDestination != "ALL")) &&
            ((this.validateSourceSubStation != null) && (this.validateSourceSubStation != undefined) &&
                (this.validateSourceSubStation != ""))) {
            this.lrDto.mode = "DestSpec";
            this.lrDto.branch = this.validateSourceSubStation;
            this.lrDto.destination = this.validateDestination;
            this.getLRsShortExtraDetails();
        } else if (((this.validateDestination != null) && (this.validateDestination != undefined) &&
            (this.validateDestination != "") && (this.validateDestination == "ALL")) &&
            ((this.validateSourceSubStation != null) && (this.validateSourceSubStation != undefined) &&
                (this.validateSourceSubStation != ""))) {
            this.lrDto.mode = "dummy";
            this.lrDto.branch = this.validateSourceSubStation;
            this.lrDto.destination = this.validateDestination;
            this.getLRsShortExtraDetails();
        } else {
            swal("Mandatory Fields", "Destination & Source is Mandatory", "warning");
            return false;
        }
    }

    getDestinyView() {
        this.validateDestination = $("#"+this.pageId+"destinationId").val();
        if ((this.validateDestination != null) && (this.validateDestination != undefined) &&
            (this.validateDestination != "") && (this.validateDestination != "ALL")) {
            this.lrDto.mode = "DestSpec";
            this.lrDto.destination = this.validateDestination;
            this.getLRsShortExtraDetails();
        } else if ((this.validateDestination != null) && (this.validateDestination != undefined) &&
            (this.validateDestination != "") && (this.validateDestination == "ALL")) {
            this.lrDto.mode = "dummy";
            this.lrDto.destination = this.validateDestination;
            this.getLRsShortExtraDetails();
        } else {
            swal("Mandatory Fields", "Destination is Mandatory", "warning");
            return false;
        }
    }

    getLRsShortExtraDetails() {
        this.showSpinnerForAction = true;
        console.log(this.lrDto);
        this.lrReport.getLRsShortExtraDtlsReport(this.lrDto).subscribe(
            (response) => {
                //console.log(response);
                this.shortAndExtraReportReportDataList = [];
                $("#"+this.pageId+"lrShortAndExtraRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.showSpinnerForAction = false;
                    this.shortAndExtraReportReportDataList = response;
                } else {
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerShortAndExtraReport.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Short & Extra Details", "error");
            },
            () => console.log('done');
    }

    customPrintShortAndExtra()
    {
		if (this.shortAndExtraReportReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "LR No", "Act Art", "Enter Art", "Status", "Invoice No","Vehicle No","Remarks"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 8, 8, 9, 12,10,28];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//Heading Logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
          
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrDto.fromDate, this.lrDto.toDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			//Heading Logics For Staion
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source","Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDto.branch, this.lrDto.destination];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.articlesSummary = this.enterArtSummary= 0;
			for (let i = 0; i < this.shortAndExtraReportReportDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.shortAndExtraReportReportDataList[i].destination, this.shortAndExtraReportReportDataList[i].lrNumber,
				this.shortAndExtraReportReportDataList[i].totalArticles, this.shortAndExtraReportReportDataList[i].lrsShortage, this.shortAndExtraReportReportDataList[i].status,
				this.shortAndExtraReportReportDataList[i].invoiceNumber,
				this.shortAndExtraReportReportDataList[i].vehicleNumber,
					this.shortAndExtraReportReportDataList[i].remarks,];
				/////////
				
			
				
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.shortAndExtraReportReportDataList.length,"","","","","","",""];
			//heading logics For Date


			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];






			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "LRs Short And Extra Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}
}
