<!-- <div mat-dialog-content id="{{pageId}}agentStockvisible">
	<div class="row p-t-10">
		<div class="col-md-4">
			<h6 id="{{pageId}}showHeader"style="margin-bottom: 0px;">Agent Stocks Summary-
				{{getStocksAt}} {{viewType}}</h6>

		</div>
		<div class="col-md-8">
			<button type="submit" class="dt-button" id="{{pageId}}markUrgentBtn"style="margin-left: 55px;"
				(click)="markUrgentLrs();">
				<span><i class="fas fa-exclamation-triangle"> Mark Urgent</i></span>
			</button>
			<button type="submit" class="dt-button" style="margin-left: 10px;"
				id="{{pageId}}undoUrgentBtn" (click)="undoUrgentLrs();">
				<span><i class="fas fa-exclamation-triangle"> Undo Urgent</i></span>
			</button>
			<button type="submit" class="dt-button" id="{{pageId}}viewAllLrsBtn"
				(click)="viewAllLrs();">
				<span><i class="fas fa-file"> View All Lrs</i></span>
			</button>
			<button type="submit" class="dt-button" id="{{pageId}}viewUrgentLrsBtn"
				(click)="viewUrgentLrs();">
				<span><i class="fas fa-file"> View Urgent Lrs</i></span>
			</button>
			<button type="submit" class="dt-button"
			(click)="printOnClose()" id="{{pageId}}printAllBtn">
			<span><i class="fas fa-print">Print</i></span>
		</button>
		
		</div>

	</div>
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center'
						style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
	
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="box-body">
						<table datatable style="width: 100%;" id="{{pageId}}agentStockSummaryId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsAgentStockSummary"
							[dtTrigger]="dtTriggerAgentStockSummary">

							<thead>
								<tr>
									<th>Action </th>
									<th>Dlv Urg</th>
									<th>Lr No.</th>
									<th>Consignee</th>
									<th>Consignor</th>
									<th>BookingDate</th>
									<th>Entry Date</th>
									<th>(Cur Date-Act Entry Date)</th>
									<th>Unldg Date</th>
									<th>Urg Marked On</th>
									<th>Art.</th>
									<th>Source</th>
									<th>Destination</th>
									<th>Description</th>
									<th>ActWt</th>
									<th>ChgWt</th>
									<th>ToPay</th>
									<th>Paid</th>
									<th>Consignee Status</th>
									<th>Consignee Belongs To</th>
									<th>Stocks At</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let agentStockSummaryData of agentStockSummaryDataList ">
									<td><input type="checkbox" [checked]="multiSelect"
										id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, agentStockSummaryData)" /></td>
									<!-- 									<td>{{agentStockSummaryData.isNewConsignee == true ? "Urgent":"" }}</td> -->
<!--<td style="padding-left:20px;font-weight:700;">{{agentStockSummaryData.isNewConsignee == true ?
										"Urgent":(agentStockSummaryData.isUrgentDly == true &&
										agentStockSummaryData.isUrgentDlyLoaded == false )?"Urgent"
										:"Normal"}}</td>
									<td>{{ agentStockSummaryData.lrNumber }}</td>
									<td>{{ agentStockSummaryData.consigneeName }}</td>
									<td>{{ agentStockSummaryData.consignorName }}</td>
									<td>{{ agentStockSummaryData.bookingDateStr }}</td>
									<td>{{ agentStockSummaryData.actLrEntryDateStr }}</td>
									<td>{{ agentStockSummaryData.diffDays }}</td>
									<td>{{ agentStockSummaryData.unloadingDateStr ==null ?
										"NA" : agentStockSummaryData.unloadingDateStr }}</td>
									<td>{{ agentStockSummaryData.urgentDlyMarkedAtStr == null
										? "NA": agentStockSummaryData.urgentDlyMarkedAtStr}}</td>
									<td>{{ agentStockSummaryData.totalArticles }}</td>
									<td>{{ agentStockSummaryData.source }}</td>
									<td>{{ agentStockSummaryData.destination }}</td>
									<td>{{ agentStockSummaryData.description }}</td>
									<td>{{ agentStockSummaryData.actualWeight }}</td>
									<td>{{ agentStockSummaryData.chargedWeight }}</td>
									<td>{{ agentStockSummaryData.toPay }}</td>
									<td>{{ agentStockSummaryData.paid }}</td>
									<td>{{ agentStockSummaryData.isNewConsignee == true ?
										"NWPTY":""}}</td>
									<td>{{ agentStockSummaryData.partyArea==null ? "NA":
										agentStockSummaryData.partyArea}}</td>
									<td>{{ agentStockSummaryData.stocksAt }}</td>

								</tr>

							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>

								</tr>
							</tfoot>
						</table>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;" id="{{pageId}}printvisible">
	<button class="btn btn-outline-danger" mat-button
		(click)="onCancelClick()">Cancel</button>
</div> -->

<div class="row" id="{{pageId}}agentStockvisible">
    <div class="col-lg-12">
        <div class="card ">
            <!-- <div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Booking Agent Statement
					Details</h6>
			</div> -->
            <div class="row system_responsive" style="margin-bottom: 10px; padding: 5px; border: 1px solid darkcyan !important;">
                <div class="col-md-12">
                    <div class="row p-t-10">
                        <div class="col-md-4">
                            <h6 id="{{pageId}}showHeader" style="margin-bottom: 0px;">Agent Stocks Summary- {{getStocksAt}} {{viewType}}</h6>

                        </div>
                        <div class="col-md-8">
                            <button type="submit" class="dt-button" id="{{pageId}}markUrgentBtn" style="margin-left: 263px;" (click)="markUrgentLrs();">
								<span><i class="fas fa-exclamation-triangle"> Mark
										Urgent</i></span>
							</button>
                            <button type="submit" class="dt-button" style="margin-left: 10px;" id="{{pageId}}undoUrgentBtn" (click)="undoUrgentLrs();">
								<span><i class="fas fa-exclamation-triangle"> Undo
										Urgent</i></span>
							</button>
                            <button type="submit" class="dt-button" id="{{pageId}}viewAllLrsBtn" (click)="viewAllLrs();">
								<span><i class="fas fa-file"> View All Lrs</i></span>
							</button>
                            <button type="submit" class="dt-button" id="{{pageId}}viewUrgentLrsBtn" (click)="viewUrgentLrs();">
								<span><i class="fas fa-file"> View Urgent Lrs</i></span>
							</button>
                            <button type="submit" class="dt-button" (click)="viewAllLrsCustomPrint()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>

                        </div>

                    </div>
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="box-body">
                                        <table datatable style="width: 100%;" id="{{pageId}}agentStockSummaryId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentStockSummary" [dtTrigger]="dtTriggerAgentStockSummary">

                                            <thead>
                                                <tr>
                                                    <th [hidden]=true>Destination</th>
                                                    <th>Action</th>
                                                    <th>Dlv Urg</th>
                                                    <th>Lr No.</th>
                                                    <th>Consignee</th>
                                                    <th>Consignor</th>
                                                    <th>Bkg Date</th>
                                                    <th>Entry Date</th>
                                                    <th>(Cur Date-Act Entry Date)</th>
                                                    <th>Unldg Date</th>
                                                    <th>Urg Marked On</th>
                                                    <th>Art.</th>
                                                    <th>Source</th>
                                                    <th>Description</th>
                                                    <th>ActWt</th>
                                                    <th>ChgWt</th>
                                                    <th>ToPay</th>
                                                    <th>Paid</th>
                                                    <th>Consignee Status</th>
                                                    <th>Consignee Belongs To</th>
                                                    <th>Stocks At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let agentStockSummaryData of agentStockSummaryDataList ">
                                                    <td [hidden]=true>{{ agentStockSummaryData.destination }}</td>
                                                    <td><input type="checkbox" [checked]="multiSelect" id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, agentStockSummaryData)" /></td>
                                                    <!-- 									<td>{{agentStockSummaryData.isNewConsignee == true ? "Urgent":"" }}</td> -->
                                                    <td style="padding-left: 20px; font-weight: 700;">{{agentStockSummaryData.isNewConsignee == true ? "Urgent":(agentStockSummaryData.isUrgentDly == true && agentStockSummaryData.isUrgentDlyLoaded == false )?"Urgent" :"Normal"}}</td>
                                                    <td>{{ agentStockSummaryData.lrNumber }}</td>
                                                    <td>{{ agentStockSummaryData.consigneeName }}</td>
                                                    <td>{{ agentStockSummaryData.consignorName }}</td>
                                                    <td>{{ agentStockSummaryData.bookingDateStr }}</td>
                                                    <td>{{ agentStockSummaryData.actLrEntryDateStr }}</td>
                                                    <td>{{ agentStockSummaryData.diffDays }}</td>
                                                    <td>{{ agentStockSummaryData.unloadingDateStr ==null ? "NA" : agentStockSummaryData.unloadingDateStr }}</td>
                                                    <td>{{ agentStockSummaryData.urgentDlyMarkedAtStr == null ? "NA": agentStockSummaryData.urgentDlyMarkedAtStr}}</td>
                                                    <td>{{ agentStockSummaryData.totalArticles }}</td>
                                                    <td>{{ agentStockSummaryData.source }}</td>
                                                    <td>{{ agentStockSummaryData.description }}</td>
                                                    <td>{{ agentStockSummaryData.actualWeight }}</td>
                                                    <td>{{ agentStockSummaryData.chargedWeight }}</td>
                                                    <td>{{ agentStockSummaryData.toPay }}</td>
                                                    <td>{{ agentStockSummaryData.paid }}</td>
                                                    <td>{{ agentStockSummaryData.isNewConsignee == true ? "NWPTY":""}}
                                                    </td>
                                                    <td>{{ agentStockSummaryData.partyArea==null ? "NA": agentStockSummaryData.partyArea}}
                                                    </td>
                                                    <td>{{ agentStockSummaryData.stocksAt }}</td>

                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tfoot>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div mat-dialog-actions style="float: right;" id="{{pageId}}printvisible">
						<button class="btn btn-outline-danger" mat-button
							(click)="onCancelClick()">Cancel</button> 
					</div> -->


                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>


<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>