<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showAutoSearch">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Automated Search</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>LR Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class=" fas fa-clipboard"></i>
														</span>
                                                    </div>
                                                    <input #lrNumber type="text" id="{{pageId}}lrNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="checkLrNo($event,lrNumber.value)">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Date</label> <input id="{{pageId}}fromDate" class="form-control" placeholder="yyyy-mm-dd" name="dates" ngbDatepicker #dates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="dates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="showUserName">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}userName">
                                                    <label>User Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>

                                                    <input id="{{pageId}}userNameID" type="text" class="form-control" [(ngModel)]="modelUserName" (selectItem)="userNameListener($event)" [ngbTypeahead]="searchUserName" [resultFormatter]="formatterUserName" [inputFormatter]="formatterUserName" (focus)="focusUserNameTA$.next($any($event).target.value)"
                                                        placeholder="Select UserName" />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-5" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                        <button type="submit" class="btn btn-dark m-r-5" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                        <hr>
                        <!-- 						<button type="submit" class="btn btn-secondary" -->
                        <!-- 							id="{{pageId}}enableRePrintBtn" (click)="enableRePrint()" -->
                        <!-- 							*ngIf="showRePrintBtn">Enable Re-Print</button> -->
                    </div>
                </div>


                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="row p-t-10">
                                    <div class="col-md-8"></div>
                                    <div class="col-md-4">
                                        <!-- 										<button type="submit" class="dt-button" -->
                                        <!-- 											style="margin-left: 28%;" (click)="getDataForLrPrint()" -->
                                        <!-- 											id="{{pageId}}lrPrintBtn"> -->
                                        <!-- 											<span><i class="fas fa-print">LR Print</i></span> -->
                                        <!-- 										</button> -->
                                        <button type="submit" style="margin-left: 71%;" class="dt-button" (click)="customPrintLrHistoryRpt()" id="{{pageId}}printAllBtn">
											<span><i class="fas fa-print">Print</i></span>
										</button>

                                    </div>

                                </div>
                                <div class="card-body">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- 											<h6 class="card-title border_bottom">Automated LR Search -->
                                            <!-- 												Report</h6> -->
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}autoSearchTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAutomatedSearch" [dtTrigger]="dtTriggerAutomatedSearch">

                                                    <thead>
                                                        <tr>
                                                            <th [hidden]=true>Destination</th>
                                                            <th>Actions</th>
                                                            <th>LR No</th>
                                                            <th>LR Entry Time</th>
                                                            <th>Consignor</th>
                                                            <th>Consignee</th>
                                                            <th>User Name</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let automatedSearchData of automatedSearchDataList ">
                                                            <td [hidden]=true>{{ automatedSearchData.destination }}
                                                            </td>
                                                            <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}printLrBtn" (click)="lrRePrint(automatedSearchData);">
																	<i title="Print LR" class="fas fa-print"></i>
																</button>
                                                                <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}enableReprintLrBtn" (click)="enableRePrint(automatedSearchData);" *ngIf="showRePrintBtn">
																	<i title="Enable LR RePrint"
																		class="fas fa-edit"></i>
																</button>
                                                            </td>
                                                            <td><u class="hyperLink_td">{{
																	automatedSearchData.lrnumber }}</u></td>
                                                            <td>{{ automatedSearchData.bookingDateStr }}</td>
                                                            <td>{{ automatedSearchData.consignorName }}</td>
                                                            <td>{{ automatedSearchData.consigneeName }}</td>
                                                            <td>{{ automatedSearchData.userName }}</td>
                                                        </tr>

                                                        <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td [hidden]=true></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>
                                                    </tfoot>
                                                </table>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>
<div *ngIf="viewLrEntryPrint" onafterprint="afterPrint()" id="{{pageId}}printLrEntryForm">
    <app-lrentryform-print3></app-lrentryform-print3>
</div>