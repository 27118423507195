<table id="{{pageId}}firstPrint">
    <table width='100%'>
        <td width='50%'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo"></td>
        <td width='50%'>{{address}}</td>
    </table>

    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='table-layout: fixed; margin-top: 2%; margin-bottom: 2%;'>
        <td width='3%'></td>
        <td width='22%'>
            <tr *ngFor="let dataprintcashMemoDtoForCustomPrintListHeadingV1 of printcashMemoDtoForCustomPrintListHeadingV1" style='font-size: 20px; margin-left: 100px;'>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV1.printHeadingName}}</strong></td>
                <td><strong>&nbsp;&nbsp;:&nbsp;&nbsp;</strong></td>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV1.printHeadingValue}}</strong></td>
            </tr>
        </td>
        <td width='40%'>
            <tr>
                <td style='text-decoration: underline; font-size: 19px;' align='center'><strong style='margin-left: 90px;'>{{printTitle}}</strong></td>
            </tr>
        </td>
        <td width='35%'>
            <tr *ngFor="let dataprintcashMemoDtoForCustomPrintListHeadingV2 of printcashMemoDtoForCustomPrintListHeadingV2" align='right' style='font-size: 18px;'>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV2.printHeadingName}}</strong></td>
                <td><strong>&nbsp;:&nbsp;</strong></td>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV2.printHeadingValue}}</strong></td>
            </tr>
        </td>
    </table>

    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='table-layout: fixed;'>
        <tr>
            <td *ngFor="let dataPrintCashMemoDtoForCustomPrintList of printCashMemoDtoForCustomPrintList" width='{{dataPrintCashMemoDtoForCustomPrintList.columnWidth}}%' align='center' style='border-right: 1px solid #000; border-left: 1px solid #000; border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 18px;'><strong>{{dataPrintCashMemoDtoForCustomPrintList.columnName}}</strong></td>
        </tr>
        <tr *ngFor="let dataPrintCashMemoDtoForCustomPrintDataList of printCashMemoDtoForCustomPrintDataList">
            <td *ngFor="let dataPrintCashMemoDtoForCustomPrintDataList1 of dataPrintCashMemoDtoForCustomPrintDataList.cashMemoDtoForCustomPrintListColumnValues" align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; border-left: 1px solid #000; word-wrap: break-word; font-size: 18px; color: #000; font-weight: 600;'
                id='{{pageId}}appMemoNumberPrint'>
                {{dataPrintCashMemoDtoForCustomPrintDataList1}}</td>
        </tr>

        <tr>
            <td *ngFor="let dataPrintCashMemoDtoForCustomPrintDataSummaryList of printcashMemoDtoForCustomPrintDataSummaryList" align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; border-left: 1px solid #000; word-wrap: break-word;font-size: 18px;'
                valign='top' type='text'><strong>
					{{dataPrintCashMemoDtoForCustomPrintDataSummaryList}} </strong></td>
        </tr>
    </table>



    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='table-layout: fixed; margin-top: 2%; margin-bottom: 2%;'>
        <td width='70%'>
            <tr *ngFor="let dataprintcashMemoDtoForCustomPrintListHeadingV3 of printcashMemoDtoForCustomPrintListHeadingV3" style='font-size: 18px; margin-left: 100px;'>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV3.printHeadingName}}</strong></td>
            </tr>
        </td>

        <td width='30%' style='text-align: left;'>
            <tr *ngFor="let dataprintcashMemoDtoForCustomPrintListHeadingV4 of printcashMemoDtoForCustomPrintListHeadingV4" align='left' style='font-size: 18px;'>
                <td><strong>{{dataprintcashMemoDtoForCustomPrintListHeadingV4.printHeadingName}}</strong></td>
            </tr>
        </td>
    </table>
</table>