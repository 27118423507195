import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';



@Component({
    selector: 'app-party-less-checked-and-unchecked-lr-details',
    templateUrl: './party-less-checked-and-unchecked-lr-details.component.html',
    styleUrls: ['./party-less-checked-and-unchecked-lr-details.component.css']
})
export class PartyLessCheckedAndUncheckedLrDetailsComponent implements OnInit {


    getDataFrmServiceFrTable: any;
    getDataFrmServiceFrLrDetailsTable: any;

    statementLessCheckedAndPendingLrDataList: any;
    lrDetailsTableDataList: any;

    onDestroyUnsubscribtionStatementLessCheckedAndPendingLr: Subscription;
    onDestroyUnsubscribtionLrDetailsTable: Subscription;




    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerStatementLessCheckedAndPendingLr: Subject<any> = new Subject();
    dtTriggerLrDetailsTable: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsStatementLessCheckedAndPendingLr: any;
    dtOptionsLrDetailsTable: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
	pageId="plculd";

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {


        this.dtOptionsStatementLessCheckedAndPendingLr = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Statement No',
                    data: 'statementNo'
                },
                {
                    title: 'Statement Date',
                    data: 'statementDate'
                },
                {
                    title: 'From Period',
                    data: 'fromPeriod'
                },
                {
                    title: 'To Period',
                    data: 'toPeriod'
                },
                {
                    title: 'Total LRs',
                    data: 'totalLrs'
                },
                {
                    title: 'Less Lrs',
                    data: 'lessLrs'
                },
                {
                    title: 'Checked Lrs',
                    data: 'checkedLrs'
                }, {
                    title: 'Uncheck Lrs',
                    data: 'uncheckLrs'
                }
            ],
            //columns is used to for export and others endss
            // the below code is for button export starts
            //dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            /* buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
    
                }
    
    
            ],*/
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        this.dtOptionsLrDetailsTable = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'LR Number',
                    data: 'lrNumber'
                },
                {
                    title: 'Booking Date',
                    data: 'bookingDate'
                },
                {
                    title: 'Source',
                    data: 'source'
                },
                {
                    title: 'Actual Less',
                    data: 'actualLess'
                },
                {
                    title: 'Main Commodity',
                    data: 'mainCommodity'
                },
                {
                    title: 'Remarks',
                    data: 'remarks'
                }
            ],
            //columns is used to for export and others endss


            // the below code is for button export starts
            //dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            /* buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],*/
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStatementLessCheckedAndPendingLr.next();
        this.dtTriggerLrDetailsTable.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerStatementLessCheckedAndPendingLr.unsubscribe();
        this.dtTriggerLrDetailsTable.unsubscribe();
        //this.onDestroyUnsubscribtionStatementLessCheckedAndPendingLr.unsubscribe();
        //this.onDestroyUnsubscribtionLrDetailsTable.unsubscribe();
    }





    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
        // this.validateDestinationIdValue = this.modelDestination.destination;
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        //this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    //this.showSpinnerForAction = false;
                } else {
                    //this.showSpinnerForAction = false;
                }
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };
}
