<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showStcksUnRpt">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Stocks Unloading Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="custom-control custom-checkbox">
														<input type="checkbox" class="custom-control-input"
															id="{{pageId}}checkboxEnable"
															(change)="enableCheckBoxEvent($event)"><label
															class=" custom-control-label wtfull"
															for="{{pageId}}checkboxEnable">Enable</label>
													</div>
												</div>
											</div>
										</div>
										<div class="row" [hidden]="!hideAndShowEnableField">
											<div class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="custom-control custom-checkbox">
														<input type="checkbox" class="custom-control-input" value="All"
															id="{{pageId}}checkboxEnableAll"
															(change)="enableCheckBoxAllEvent($event)"><label
															class=" custom-control-label wtfull"
															for="{{pageId}}checkboxEnableAll">All</label>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-4">
												<div class="form-group">
													<div class="custom-control custom-checkbox">
														<input type="checkbox" class="custom-control-input"
															id="{{pageId}}checkboxEnableSpecific"
															(change)="enableCheckBoxSpecificEvent($event)"><label
															value="Specific" class=" custom-control-label wtfull"
															for="{{pageId}}checkboxEnableSpecific">Specific</label>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-5">
												<div class="form-group">
													<div class="input-group">
														<input type="text" class="form-control" id="{{pageId}}cheatCodeId"
															name="cheatCodeId" aria-describedby=" basic-addon11 "
															autocomplete="off" />
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="custom-control custom-checkbox">
														<input type="checkbox" class="custom-control-input"
															id="{{pageId}}checkboxCheckPostDetained"
															(change)="enableCheckPostDetainedEvent($event)">
														<label class=" custom-control-label wtfull"
															for="{{pageId}}checkboxCheckPostDetained">Check Post
															Detained</label>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12" [hidden]="hideAndShowInvoiceNumberField">
												<div class="form-group">
													<div class="input-group">
														<label>Invoice Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}invoiceNumber" class="form-control"
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>


											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label>
														<input class="form-control" placeholder="yyyy-mm-dd"
															id="{{pageId}}fromDates" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker" [(ngModel)]="fromDatesModal"
															autocomplete="off">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker" [(ngModel)]="toDatesModal"
															autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn()"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" (click)="clearBtn();"
								id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
												(click)="customPrintStockunloadRpt()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}stockUnloadingTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsUnloadingReport" [dtTrigger]="dtTriggerUnloadingReport">
								<thead>
									<tr>
										<th>Invoice Number</th>
										<th>Invoice Date</th>
										<th>Total LRs</th>
										<th>Source</th>
										<th>Destination</th>
										<th>Vehicle Number</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let unloadingReportData of unloadingReportDataList ">

										<td>
											<a (click)="stockUnloadingRptPopUpBtnLink
												(stockUnloadingPopupTemplate,unloadingReportData);" class="pointer">
												<u>{{ unloadingReportData.invoiceNumber }}</u></a>
										</td>
										<td>{{ unloadingReportData.invDate }}</td>
										<td>{{ unloadingReportData.totalLRs }}</td>
										<td>{{ unloadingReportData.fromStation }}</td>
										<td>{{ unloadingReportData.toStation }}</td>
										<td>{{ unloadingReportData.vehicleNumber }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<!-- popup modal starts -->
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group">
				<ng-template #stockUnloadingPopupTemplate let-c="close" let-d="dismiss">
					<div class="modal-header">
						<!--h6 class="modal-title" id="{{pageId}}modal-basic-title">LR Details</h6>-->
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div *ngIf="viewDeliveryUnldDetainedPopUp" onafterPopUp="afterPopUp()"
									id="{{pageId}}popupStockUnloadingDetained">
									<delivery-unloaded-lr-detained></delivery-unloaded-lr-detained>
								</div>
							</div>
							<div class="col-md-12">
								<div *ngIf="viewDeliveryUnldNotDetainedPopUp" onafterPopUp="afterPopUp()"
									id="{{pageId}}popupStockUnloadingNotDetained">
									<delivery-unloaded-lr-notdetained></delivery-unloaded-lr-notdetained>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<button type="button" class="btn btn-outline-dark"
								(click)="c('cancle click')">Cancel</button>
						</div>
						<div class="col-md-4"></div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
	<!--End PopuP-->
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>