import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';

import { ElementRef } from "@angular/core";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment  from 'moment';



@Component( {
    selector: 'app-pending-stocks-details-report',
    templateUrl: './pending-stocks-details-report.component.html',
    styleUrls: ['./pending-stocks-details-report.component.css']
} )
export class PendingStocksDetailsReportComponent implements OnInit {

    gettingDataFrmServiceFrPendingStocksDetailsReportTable: any;
    pendingStocksDetailsReportDataList: any;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    loadingIndicator = true;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerPendingStocksDetailsReport: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsPendingStocksDetailsReport: any;
	address:any;
    fromDateModel:any;
	toDateModel:any;
	selectedFromDate:any;
	selectedToDate:any;
	lrdtoFroSearch:LRDto =new LRDto();
	selectedDeliveryType:any;
    pageId="pndsd";
    
     constructor(private router: Router, private datePipe: DatePipe, private lrReportService: LrReportService, private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {

        	  if (sessionStorage.length == 0) {
                  this.isLoggedIn = false;
                  swal({
                      title: "Session Expired",
                      text: "Please relogin to access the application!",
                      icon: "error",
                      closeOnClickOutside: false,
                      closeOnEsc: false,
                  }).then(() => {
                      this.logInPage();
                  })
              }
              if (this.isLoggedIn) {
                  this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                  this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
              }
            
        }
        logInPage() {
            this.router.navigate(['/authentication/login']);
        }
      
		clickTA(inp) {
            inp._elementRef.nativeElement.value = '';
            inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
            inp._elementRef.nativeElement.focus();
        }
        
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }

    ngOnInit(): void {
	   var companyAddressDetls = this.address;
  		 this.dtOptionsPendingStocksDetailsReport = {                    
                  dom: 'Bfrtip',                   
                     buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize : function(win) {
						$(win.document.body).css('font-size','10pt');
						$(win.document.body).find('th, td').
						css('font-family','Arial, Helvetica, sans-serif')
						.css('font-size','13px').css('text-align','center');
					},
					footer : true,
					messageTop : function() {
						var returnVal=null;
						if($("#pndsddeliveryType").val()=='Godown Delivery' || $("#pndsddeliveryType").val()=='Door Delivery'){
							returnVal = "<br><table style='width: 100%;'>" +
										"<tr>" +
										"<td style='width: 30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment($("#pndsdfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment($("#pndsdtoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
										"<strong style='font-size:19px;'><u>Pending Stocks Details Report</u></strong><br>" +
										"</td>" +
										"<td align='left' style='width:40%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>Delivery Type</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + $("#pndsddeliveryType").val() + "</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"</tr>" +
										"</table><br>";
							}else{
							returnVal = "<br><table style='width: 100%;'>" +
										"<tr>" +
										"<td style='width: 30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment($("#pndsdfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment($("#pndsdtoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
										"<strong style='font-size:19px;'><u>Pending Stocks Details Report</u></strong><br>" +
										"</td>" +
										"<td align='left' style='width:40%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>Delivery Type</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>ALL</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"</tr>" +
										"</table><br>";
							}
						return returnVal;
						
					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
					
					var returnSummaryExcel=null;
					if($("#pndsddeliveryType").val()=='Godown Delivery' || $("#pndsddeliveryType").val()=='Door Delivery'){
							returnSummaryExcel = "Pending Stocks Details Report"+
										" From Date : "+ moment($("#pndsdfromDate").val()).format('DD-MM-YYYY') + " -  "+
										"To Date : " +  moment($("#pndsdtoDate").val()).format('DD-MM-YYYY')+" Delivery Type : "+$("#pndsddeliveryType").val();
					}else{
					returnSummaryExcel = "Pending Stocks Details Report"+
										" From Date : "+ moment($("#pndsdfromDate").val()).format('DD-MM-YYYY') + " -  "+
										"To Date : " +  moment($("#pndsdtoDate").val()).format('DD-MM-YYYY')+" Delivery Type : ALL";
					}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			], 
					 processing: true,
            			//scroll in datatable starts
            			responsive: true,
            			"scrollX": false,
            			"scrollY":300,
            			"scrollCollapse": true,
            			"paging": true,
            			"info": true,
              			"footerCallback": function ( row, data, start, end, display ) {
              				var api = this.api(), data;
              				// converting to interger to find total
              				var intVal = function ( i ) {
              					return typeof i === 'string' ?
              					+i.replace(/[\$,]/g, '') * 1:
              						typeof i === 'number' ?
              							i : 0;
              				};  
						var articles = api.column( 2 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
						var toPay = api.column( 3 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var paid = api.column( 4 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						$( api.column( 0 ).footer() ).html('Total : '+data.length);
						$( api.column( 1 ).footer() ).html();
						$( api.column( 2 ).footer() ).html(articles);
						$( api.column( 3 ).footer() ).html(toPay);
						$( api.column( 4 ).footer() ).html(paid);
						$( api.column( 5 ).footer() ).html();
						$( api.column( 5 ).footer() ).html();
							
                          }
                      
                }
           
        }

    ngOnDestroy(): void {
        this.dtTriggerPendingStocksDetailsReport.unsubscribe();
    }
	ngAfterViewInit(): void {
		this.dtTriggerPendingStocksDetailsReport.next();	
	}


    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }


    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }
	
	searchMethod(){
	
		this.selectedFromDate=$("#"+this.pageId+"fromDate").val();
		this.selectedToDate=$("#"+this.pageId+"toDate").val();
		this.selectedDeliveryType=$("#"+this.pageId+"deliveryType").val();
	
		if(this.selectedFromDate == null || this. selectedFromDate =='' || this.selectedToDate == null || this.selectedToDate ==''){	
			  swal( {
						title: "Mandatory Field",
						text: "From Date , To Date Is Mandatory Fields",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					} );
					return false;
				}else{
					this.lrdtoFroSearch= new  LRDto();
					this.lrdtoFroSearch.fromDate = this.selectedFromDate;
					this.lrdtoFroSearch.toDate = this.selectedToDate;	
					this.lrdtoFroSearch.deliveryType = this.selectedDeliveryType;
					this.lrdtoFroSearch.branch = this.userDataDtoReturnSession.office;
					this.lrdtoFroSearch.status = "Pending";
					this.lrdtoFroSearch.companyId =this.userDataDtoReturnSession.companyId;
					console.log(this.lrdtoFroSearch);
					this.searchPendingStockDetails();
				
				}
	
	}
	
			searchPendingStockDetails = () => {
    	         this.showSpinnerForAction = true;
    	         this.lrReportService.getPendingStocksDetails(this.lrdtoFroSearch).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  $("#"+this.pageId+"pendingStocksDetailsRptId").DataTable().destroy();
							this.pendingStocksDetailsReportDataList=[];
               
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No  Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.pendingStocksDetailsReportDataList=response;
    	               	  console.log(this.pendingStocksDetailsReportDataList);
    	                }
    	                 this.dtTriggerPendingStocksDetailsReport.next();
						 this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the  Pending Stock  details", "info");
                    }, () => console.log('done');
            };

			clearMethod(){
			 this.showSpinnerForAction = false;
			 this.lrdtoFroSearch= new  LRDto();
			 this.selectedFromDate='';
			 $("#"+this.pageId+"fromDate").val('');
			this.selectedToDate='';
			$("#"+this.pageId+"toDate").val('');
			this.selectedDeliveryType='';
			$("#"+this.pageId+"deliveryType").val('All');
			$("#"+this.pageId+"pendingStocksDetailsRptId").DataTable().destroy();
			this.pendingStocksDetailsReportDataList=[];
			this.dtTriggerPendingStocksDetailsReport.next();
			this.fromDateModel=null;
			this.toDateModel=null;
			}
}
