<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 p-t-5">
                                <h6 class="card-title">Address Setup</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <div class="form-group-prepend">
                                                    <span class="input-group-text"> <i
														class=" fas fa-hashtag"></i>
													</span>
                                                </div>
                                                <textarea #address class="form-control" rows="2" id="{{pageId}}address" name="address" name="address" [(ngModel)]="userDataDto.addressEditCompany"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Pincode</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-hashtag"></i>
													</span>
                                                </div>
                                                <input #pincode (keyup)="fieldFocus($event, teleNum)" type="number" id="{{pageId}}pincode" name="pincode" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="userDataDto.pinCode">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Telephone Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-phone"></i>
													</span>
                                                </div>
                                                <input #teleNum (keyup)="fieldFocus($event, city)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}teleNum" name="teleNum" [(ngModel)]="userDataDto.phoneEditCompany">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>City</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-map-marker-alt"></i>
													</span>
                                                </div>
                                                <input #city (keyup)="fieldFocus($event, faxNum)" type="text" class="form-control" id="{{pageId}}city" name="city" aria-describedby="basic-addon11" [(ngModel)]="userDataDto.city">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Fax Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
                                                </div>
                                                <input #faxNum (keyup)="fieldFocus($event, emailId)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}faxNum" name="faxNum" [(ngModel)]="userDataDto.faxNo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Email-Id</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
                                                </div>
                                                <input #emailId (keyup)="fieldFocus($event, password)" type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}emailId" name="emailId" [(ngModel)]="userDataDto.cmpEmailId">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Password</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-lock"></i>
													</span>
                                                </div>
                                                <input #password (keyup)="fieldFocus($event, cheatCode)" type="password" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}password" name="password" [(ngModel)]="userDataDto.cmpEmailPassword">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>:</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-question"></i>
													</span>
                                                </div>
                                                <input #cheatCode (keyup)="fieldFocus($event, memoTimePeriod)" type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}cheatCode" name="cheatCode" [(ngModel)]="userDataDto.cheatCode">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Memo Time Period</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-clock"></i>
													</span>
                                                </div>
                                                <input #memoTimePeriod (keyup)="fieldFocus($event, receiptCharges)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}memoTimePeriod" name="memoTimePeriod" [(ngModel)]="userDataDto.memoTimePeriod">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-3 vl p-t-5">
                                <h6 class="card-title">Charges Setup</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Receipt Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #receiptCharges (keyup)="fieldFocus($event, serviceCharges)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}receiptCharges" name="receiptCharges" [(ngModel)]="userDataDto.rcChg">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Service Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #serviceCharges (keyup)="fieldFocus($event, destHamaliPerArt)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}serviceCharges" name="serviceCharges" [(ngModel)]="userDataDto.scChg">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Dest.Hamali Per Art.(only for bkg memo)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-map-marker-alt"></i>
													</span>
                                                </div>
                                                <input #destHamaliPerArt (keyup)="fieldFocus($event, srdPerc)" type="number" class="form-control" placeholder="Use Only For Booking Memo" aria-describedby="basic-addon11" id="{{pageId}}destHamaliPerArt" name="destHamaliPerArt" [(ngModel)]="userDataDto.destHamaliPerArt">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="hideShowSRDPercInputField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>SRD Percentage</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #srdPerc (keyup)="fieldFocus($event, hamaliPerc)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srdPerc" name="srdPerc" [(ngModel)]="userDataDto.srdPercentage">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="hideShowHamaliPercInputField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali Percentage</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #hamaliPerc (keyup)="fieldFocus($event, unloadingChg)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}hamaliPerc" name="hamaliPerc" [(ngModel)]="userDataDto.hamaliPercentage">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unloading Charge(Door Delivery) </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #unloadingChg (keyup)="fieldFocus($event, unit)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChg" name="unloadingChg" [(ngModel)]="userDataDto.unloadingHamaliChg">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit(Door Delivery)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-weight"></i>
													</span>
                                                </div>
                                                <select #unit class="custom-select col-12" id="{{pageId}}unit" name="unit" [(ngModel)]="userDataDto.unit">
													<option value="">Select.....</option>
													<option value="Article">Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Charged Weight">Charged Weight</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unloading Charge(Godown Delivery) </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input #unloadingChg (keyup)="fieldFocus($event, unit)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChgGodownDelivery" name="unloadingChgGodownDelivery" [(ngModel)]="userDataDto.unloadingHamaliChgGD">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit(Godown Delivery)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-weight"></i>
													</span>
                                                </div>
                                                <select #unit class="custom-select col-12" id="{{pageId}}unitGodownDelivery" name="unitGodownDelivery" [(ngModel)]="userDataDto.unitGD">
													<option value="">Select.....</option>
													<option value="Article">Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Charged Weight">Charged Weight</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="showLoadingChgSetup">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Loading Charge(Per Article) </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                    </div>
                                                    <input #loadingChg (keyup)="fieldFocus($event, unit)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}loadingChg" name="loadingChg" [(ngModel)]="userDataDto.loadingChg">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Loading Charge Unit</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
														class="fas fa-weight"></i>
													</span>
                                                    </div>
                                                    <select #unitLoading class="custom-select col-12" id="{{pageId}}unitLoading" name="unitLoading" [(ngModel)]="userDataDto.unitLoading">
													<option value="">Select.....</option>
													<option value="Article">Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Charged Weight">Charged Weight</option>
												</select>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Service Charge(Other Area) </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                    </div>
                                                    <input #serviceChgOtherArea (keyup)="fieldFocus($event, unit)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}serviceChgOtherArea" name="serviceChgOtherArea" [(ngModel)]="userDataDto.serviceChgOtherArea">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Unit</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
														class="fas fa-weight"></i>
													</span>
                                                    </div>
                                                    <select #unitServiceChgOtherArea class="custom-select col-12" id="{{pageId}}unitServiceChgOtherArea" name="unitServiceChgOtherArea" [(ngModel)]="userDataDto.unitServiceChgOtherArea">
													<option value="">Select.....</option>
													<option value="Article">Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Charged Weight">Charged Weight</option>
												</select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 vl p-t-5">
                                <h6 class="card-title">Destination Memo - Charges Setup</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali DD Per Art.</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                    class="fas fa-rupee-sign"></i>
                                                </span>
                                                </div>
                                                <input #hamaliddPerArt type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}hamaliddPerArt" name="hamaliddPerArt" [(ngModel)]="userDataDto.hamaliddPerArt">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Demurrage Exemption Days</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                    class="fas fa-rupee-sign"></i>
                                                </span>
                                                </div>
                                                <input #demurrageExmpDays type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}demurrageExmpDays" name="demurrageExmpDays" [(ngModel)]="userDataDto.demurrageExmpDays">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Demurrage Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                    class="fas fa-rupee-sign"></i>
                                                </span>
                                                </div>
                                                <input #demurrageChg type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}demurrageChg" name="demurrageChg" [(ngModel)]="userDataDto.demurrageChg">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit(Demurrage)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                    class="fas fa-weight"></i>
                                                </span>
                                                </div>
                                                <select #demurrageUnit class="custom-select col-12" id="{{pageId}}demurrageUnit" name="demurrageUnit" [(ngModel)]="userDataDto.demurrageUnit">
                                                    <option selected value="Charged Weight">Charged Weight</option>
                                                    <option  value="Actual Weight">Actual Weight</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="card-title">Arrival WhatsApp/SMS Configuration</h6>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Stocks SMS</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
                                                    class="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                            <select #inStockSms class="custom-select col-12" id="{{pageId}}inStockSms" name="inStockSms" [(ngModel)]="userDataDto.inStockSms">
                                                <option [ngValue]="true">Yes</option>
                                                <option [ngValue]="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <h7 class="card-title" style="cursor: pointer; color: blue;" (click)="stockArrivalNotificationSetupPopup();"><u>Click For More Arrival WhatsApp/SMS Configuration</u></h7>

                                <h6 class="card-title">SMS & E-mail Configuration</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Remainder SMS Duration</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-clock"></i>
													</span>
                                                </div>
                                                <input #incentiveDays (keyup)="fieldFocus($event, inRemindSms)" type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}incentiveDays" name="incentiveDays" [(ngModel)]="userDataDto.smsDuration">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Remainder SMS</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
                                                </div>
                                                <select #inRemindSms class="custom-select col-12" id="{{pageId}}inRemindSms" name="inRemindSms" [(ngModel)]="userDataDto.inRemindSms">
													<option [ngValue]="true">Yes</option>
													<option [ngValue]="false">No</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Pending Stock SMS</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
                                                </div>
                                                <select #deliveryPendingStockSms class="custom-select col-12" id="{{pageId}}deliveryPendingStockSms" name="deliveryPendingStockSms" [(ngModel)]="userDataDto.deliveryPendingStockSms">
													<option [ngValue]="true">Yes</option>
													<option [ngValue]="false">No</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <!--Here Start New Change-->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-8">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-envelope"></i>
																</span>
                                                        </div>
                                                        <input #selectedEmail type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}selectedEmail" name="selectedEmail" [(ngModel)]="userDataDto.selectedEmail">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2" style="display:none;">
                                                <div class="form-group">
                                                    <div class="input-group">

                                                        <input #hiddenIndex type="number" id="{{pageId}}hiddenIndex" name="hiddenIndex" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn style="padding: 1px 4px;" class="btn btn-icon-only yellow" (click)="addInTable();">
                                                            <i class="fa fa-plus" title="Add"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <button style="padding: 1px 4px;  background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDelete()">
																<i title="Remove" class="fas fa-trash"></i>
															</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="box-body" style="text-align: center;">
                                                    <table datatable id="{{pageId}}editCompanyDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsEditCompany" [dtTrigger]="dtTriggerEditCompany">
                                                        <thead>
                                                            <tr>
                                                                <th>Email Id</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let editCompanyData of allEmails; let i = index" (click)="rowSelectedEdit(editCompanyData,i);">
                                                                <td>{{ editCompanyData }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Here End a new change-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 vl p-t-5">
                                <!--Here Start New Added-->
                                <div class="row">
                                    <h6 class="card-title">Other Charge Setup - Door Delivery</h6>
                                    <div class="col-sm-12 col-md-7">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
																	class="fas fa-home"></i>
																</span>
                                                </div>
                                                <input id="{{pageId}}otherChargeArea" type="text" class="form-control" (selectItem)="clickListnerForOtherChargeArea($event)" [(ngModel)]="modelOtherChargeArea" [ngbTypeahead]="searchOtherChargeArea" [resultFormatter]="formatterOtherChargeArea" [inputFormatter]="formatterOtherChargeArea"
                                                    (focus)="focusOtherChargeAreaTA$.next($any($event).target.value)" placeholder="Select the Area" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-5">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
																	class="fas fa-weight"></i>
																</span>
                                                </div>
                                                <select #basedOn class="custom-select col-12" id="{{pageId}}basedOnId" name="basedOnId" placeholder="Based On">
																<option value="">Select.....</option>
																<option value="Article">Article</option>
																<option value="Lumpsum">Lumpsum</option>
															</select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																	</span>
                                                </div>
                                                <input #otherChgAmt type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}otherChargeAmountId" name="otherChargeAmountId">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2" style="display:none;">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input #hiddenIndexOtherCharges type="number" id="{{pageId}}hiddenIndexOtherChgId" name="hiddenIndexOtherChgId" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <a href="javascript:;" type='button' id="{{pageId}}addInTableOtherChargeBtnId" #addBtn style="padding: 1px 4px;" class="btn btn-icon-only yellow" (click)="addInTableOtherCharge();">
                                                    <i class="fa fa-plus" title="Add"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="col-md-2">
											<div class="form-group">
												<div class="input-group">
													<button
														style="padding: 1px 4px;  background-color: #ffffff00;"
														class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
														(click)="rowSelectedOtherChargeOnDelete()">
														<i title="Remove" class="fas fa-trash"></i>
													</button>
												</div>
											</div>
										</div>-->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="box-body" style="text-align: center;">
                                            <table datatable id="{{pageId}}otherChargeDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsOtherChargeDatatable" [dtTrigger]="dtTriggerOtherChargeDatatable">
                                                <thead>
                                                    <tr>
                                                        <th>Area</th>
                                                        <th>Based On</th>
                                                        <th>Charges</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let editOtherChargeData of gridTosetOtherChargeList; let i = index" (click)="rowSelectedOtherChargeDataOnEdit(editOtherChargeData,i);">
                                                        <td>{{ editOtherChargeData.areaOfDelivery }}</td>
                                                        <td>{{ editOtherChargeData.ratePerUnit }}</td>
                                                        <td>{{ editOtherChargeData.chqAmt }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div><br>
                                <!-- Here End a new added-->
                                <div class="row">
                                    <div class="col-lg-12" style="text-align: end;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateEditCompanyDetails()">Save</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                    </div>
                                </div>
                                <!--<div class="row">
									<div class="col-sm-12 col-md-8">
										<div class="form-group">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
												</div>
												<input #selectedEmail type="text" class="form-control"
													aria-describedby="basic-addon11" id="{{pageId}}selectedEmail"
													name="selectedEmail"
													[(ngModel)]="userDataDto.selectedEmail">
											</div>
										</div>
									</div>
									<div class="col-md-4" style="display:none;" >
										<div class="form-group">
											<div class="input-group">

												<input  #hiddenIndex type="number" id="{{pageId}}hiddenIndex" name ="hiddenIndex" class="form-control"
													aria-describedby="basic-addon11">&nbsp;
											</div>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<div class="input-group">

												<a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn
													class="btn btn-icon-only yellow" (click)="addInTable();">
													<i class="fa fa-plus"></i>
												</a>
											</div>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<div class="input-group">

												<button
													style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
													(click)="rowSelectedDelete()">
													<i title="Remove" class="fas fa-trash"></i>
												</button>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="box-body" style="item-align: center;">
											<table datatable id="{{pageId}}editCompanyDatatableId"
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionsEditCompany"
												[dtTrigger]="dtTriggerEditCompany">
												<thead>
													<tr>
														<th>Email Id</th>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="let editCompanyData of allEmails; let i = index"
														(click)="rowSelectedEdit(editCompanyData,i);">
														<td>{{ editCompanyData }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>-->
                            </div>
                        </div>
                        <!--<div class="row">
							<hr style="width: 80%; border-top: none; margin: 3px;">
							<div class="col-lg-12" style="text-align: center;">
								<button type="submit" class="btn btn-success m-r-10"
									id="{{pageId}}saveBtn" (click)="validateEditCompanyDetails()">Save</button>
								<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
									(click)="clearAll()">Clear</button>
							</div>
						</div>-->
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>