import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-multiple-pack-nature',
	templateUrl: './multiple-pack-nature.component.html',
	styleUrls: ['./multiple-pack-nature.component.css']
})
export class MultiplePackNatureComponent implements OnInit {

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerMultiPackTable: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsMultiPackTable: any;
	multiPackNatureTableDataList: Array<any> = [];


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	multiPackNatureNewOptions: LRDto[];
	lrDtoFormultiPackNatureNew: LRDto = new LRDto();
	public modelMultiPackNatureNew: any;
	multiPackNatureTA: Array<LRDto> = [];
	focusMultiPackNatureNewTA$ = new Subject<string>();
	searchMultiPackNatureNew = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMultiPackNatureNewTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.multiPackNatureTA
				: this.multiPackNatureTA.filter(v => v.packNature.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMultiPackNatureNew = (x: { packNature: string }) => x.packNature;


	popUpOpenMultiPackNatureLrData: any;
	popUpOpenMultiplePackNatureListLocalStorage: any;

	private newAttributeSetMultiPackNatureLoadSet: any = {};
	private newAttributeMultiPack: any = {};


	lrtDtoForPackNature: LRDto = new LRDto();
	editTableIdMultiPack: any;
	pageId="mpnc";



	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<MultiplePackNatureComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getNatureOfPackList();
			this.popUpOpenMultiPackNatureLrData = JSON.parse(localStorage.getItem('MultiplePackNatureData'));
			this.popUpOpenMultiplePackNatureListLocalStorage = JSON.parse(localStorage.getItem('MultiplePackNatureListData'));
			localStorage.clear();
			if (this.popUpOpenMultiPackNatureLrData != null &&
				this.popUpOpenMultiPackNatureLrData != undefined &&
				this.popUpOpenMultiPackNatureLrData == "MultiplePackNatureData") {
				if (this.popUpOpenMultiplePackNatureListLocalStorage != null &&
					this.popUpOpenMultiplePackNatureListLocalStorage != undefined) {
					this.setMultiPackNatureDataTable();
				}
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsMultiPackTable = {
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			//place holder in search/filter in datatable ends
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 150,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": false,
			"info": true,
			pagingType: 'full_numbers',
			//pageLength: 9,
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerMultiPackTable.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerMultiPackTable.next();
	}

	/* on key press listner */

	onKeyPressListnerForArticle(event) {
		if (event == 13) {
			$("#"+this.pageId+"addInTablePackNature").focus();
		}
	}

	/* on key press listner */

	clickListnerForMultiPackNatureNew(e: NgbTypeaheadSelectItemEvent) {
		this.modelMultiPackNatureNew = e.item;
		//console.log(this.modelMultiPackNatureNew);
		$("#"+this.pageId+"multiPackNatureNew").val(this.modelMultiPackNatureNew.packNature);
	}

	getNatureOfPackList = () => {
		this.lrtDtoForPackNature = new LRDto();
		this.lrtDtoForPackNature.companyId = this.userDataDtoReturnSession.companyId;
		this.masterReadService.getNatureOfPack(this.lrtDtoForPackNature).subscribe(
			(response) => {
				console.log(response);
				this.multiPackNatureTA = [];
				this.multiPackNatureNewOptions = [];
				if (response.length > 0) {
					this.multiPackNatureNewOptions = response;
					for (let i = 0; i < this.multiPackNatureNewOptions.length; i++) {
						this.multiPackNatureTA.push(this.multiPackNatureNewOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Nature Of Pack Details", "warning")
			},
			() => console.log('done');
	};

	setMultiPackNatureDataTable() {
		var multiPAckNatureSplitHash = null;

		this.multiPackNatureTableDataList = [];
		$("#"+this.pageId+"multiPackNaturetimeTable").DataTable().destroy();
		console.log(this.popUpOpenMultiplePackNatureListLocalStorage);
		if (this.popUpOpenMultiplePackNatureListLocalStorage != null &&
			this.popUpOpenMultiplePackNatureListLocalStorage != undefined &&
			this.popUpOpenMultiplePackNatureListLocalStorage != "") {

			multiPAckNatureSplitHash = this.popUpOpenMultiplePackNatureListLocalStorage.split("#");
			console.log(multiPAckNatureSplitHash);
			//multiArtSizeSplitUnderScope = this.popUpOpenMultipleArticleSizeListLocalStorage.split("_");

			if (multiPAckNatureSplitHash.length > 0) {
				for (let i = 0; i < multiPAckNatureSplitHash.length; i++) {

					var multiPackNatureSplitUnderScope = [];
					multiPackNatureSplitUnderScope = multiPAckNatureSplitHash[i].split("_");
					console.log(multiPackNatureSplitUnderScope);
					if (multiPackNatureSplitUnderScope.length > 1) {
						this.newAttributeSetMultiPackNatureLoadSet = {};
						this.newAttributeSetMultiPackNatureLoadSet.multiplePackNatureNew = multiPackNatureSplitUnderScope[1];
						this.newAttributeSetMultiPackNatureLoadSet.multiplePackNatureArt = multiPackNatureSplitUnderScope[0];
						this.multiPackNatureTableDataList.push(this.newAttributeSetMultiPackNatureLoadSet);
					}
				}
				console.log(this.multiPackNatureTableDataList);
				this.dtTriggerMultiPackTable.next();
			}
		} else {
			this.dtTriggerMultiPackTable.next();
		}
	}


	addInTablePackNature() {
		this.newAttributeMultiPack.multiplePackNatureNew = $("#"+this.pageId+"multiPackNatureNew").val();
		this.newAttributeMultiPack.multiplePackNatureArt = $("#"+this.pageId+"multiPackNatureArtilces").val();

		if ((this.newAttributeMultiPack.multiplePackNatureNew != null) && (this.newAttributeMultiPack.multiplePackNatureNew != '') && (this.newAttributeMultiPack.multiplePackNatureArt != null) && (this.newAttributeMultiPack.multiplePackNatureArt != '')) {


			if (this.editTableIdMultiPack != null) {
				this.multiPackNatureTableDataList.splice(this.editTableIdMultiPack, 1);
			}
			if (this.multiPackNatureTableDataList == null) {
				this.multiPackNatureTableDataList = [];
			}

			if (this.multiPackNatureTableDataList.length == 0) {
				this.multiPackNatureTableDataList.push(this.newAttributeMultiPack);
			} else {

				let array = this.multiPackNatureTableDataList;
				this.multiPackNatureTableDataList.push(this.newAttributeMultiPack);


			}
			this.newAttributeMultiPack = {};
			$("#"+this.pageId+"multiPackNatureNew").val('');
			$("#"+this.pageId+"multiPackNatureArtilces").val('');
			$("#"+this.pageId+"hiddenIndexPackNature").val('');
			this.editTableIdMultiPack = null;
		} else {
			swal("Warning", "Please Enter the Mandetory Fields", "warning");
		}
	}

	rowSelectedEditPackNature(multiplePackNatureTableData, index) {
		console.log(multiplePackNatureTableData);
		$("#"+this.pageId+"multiPackNatureNew").val(multiplePackNatureTableData.multiplePackNatureNew);
		$("#"+this.pageId+"multiPackNatureArtilces").val(multiplePackNatureTableData.multiplePackNatureArt);

		$("#"+this.pageId+"hiddenIndexPackNature").val(index);
		this.editTableIdMultiPack = index;
	}

	rowSelectedDeletePackNature(index) {
		this.multiPackNatureTableDataList.splice(index, 1);
		$("#"+this.pageId+"multiPackNatureNew").val('');
		$("#"+this.pageId+"multiPackNatureArtilces").val('');
		$("#"+this.pageId+"hiddenIndexPackNature").val('');
	}



	onCancelClick(): void {
		this.dialogRef.close();
		if (this.multiPackNatureTableDataList != null &&
			this.multiPackNatureTableDataList != undefined &&
			this.multiPackNatureTableDataList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.multiPackNatureTableDataList));
		} else {
			this.multiPackNatureTableDataList = [];
			this.dialogRef.close(JSON.stringify(this.multiPackNatureTableDataList));
		}
	}

	onOkClickBtn(): void {
		this.dialogRef.close();
		/*if (this.gridConsigneeMobileNoList != null &&
			this.gridConsigneeMobileNoList != undefined &&
			this.gridConsigneeMobileNoList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		} else {
			this.gridConsigneeMobileNoList = [];
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		}*/
	}


}

