<html>

<!-- <head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head> -->

<body>
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Loading Invoice Edit</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <!-- <div class="card"> -->
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Invoice Number :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}invoiceNumber" class="form-control" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateForInvoiceNo()">Search</button>
                                            <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearFields()">Clear</button>
                                        </div>
                                        <h4 *ngIf="userDataDtoReturnSession.newPartyInvBlock" class="timeline-title" style="color: rgb(255, 0, 0); font-weight: bold; font-size: 14px;">
                                            *Red Color indicates for <br>new party</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="col-md-12 vl p-t-10">
                        <div class="box-body">
                            <table datatable id="{{pageId}}loadingInvEditTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLoadingInvEdit" [dtTrigger]="dtTriggerLoadingInvEdit">

                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Lr No</th>
                                        <th>Booking Date</th>
                                        <th>Destination</th>
                                        <th>Agent</th>
                                        <th>Actual Wgt</th>
                                        <th>Charged Wgt</th>
                                        <th>Freight Amt</th>
                                        <th>Articles</th>
                                        <th>Consignee Name</th>
                                        <th>Consignor Name</th>
                                        <th>Commodity</th>
                                        <th>Goods Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceDetailsData of invoiceDetailsDataList">
                                        <td>
                                            <input type="checkbox" [(ngModel)]="invoiceDetailsData.isSelected" id='selectedLr' (change)="rowCheckBoxInvoiceEditClicked()">
                                        </td>
                                        <!-- <td>{{ invoiceDetailsData.lrNumber }}</td> -->
                                        <td *ngIf="userDataDtoReturnSession.newPartyInvBlock&&invoiceDetailsData.remarks!=null && invoiceDetailsData.remarks!=undefined && invoiceDetailsData.remarks =='NW PRTY'" style="color: rgb(255, 6, 6); font-weight: bold; font-size: 14px;">
                                            {{ invoiceDetailsData.lrNumber }}
                                        </td>
                                        <td *ngIf="userDataDtoReturnSession.newPartyInvBlock==null||!userDataDtoReturnSession.newPartyInvBlock||invoiceDetailsData.remarks === null|| invoiceDetailsData.remarks === undefined|| invoiceDetailsData.remarks != 'NW PRTY'">
                                            {{ invoiceDetailsData.lrNumber }}
                                        </td>
                                        <td>{{ invoiceDetailsData.bookingDateStr }}</td>
                                        <td>{{ invoiceDetailsData.destination }}</td>
                                        <td>{{ invoiceDetailsData.agentId }}</td>
                                        <td>{{ invoiceDetailsData.actualWeight }}</td>
                                        <td>{{ invoiceDetailsData.chargedWeight }}</td>
                                        <td>{{ invoiceDetailsData.totalAmount }}</td>
                                        <td>{{ invoiceDetailsData.totalArticles }}</td>
                                        <td>{{ invoiceDetailsData.consigneeName }}</td>
                                        <td>{{ invoiceDetailsData.consignorName }}</td>
                                        <td>{{ invoiceDetailsData.description }}</td>
                                        <td>{{ invoiceDetailsData.goodsValue }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="row">
                            <div class="col-md-8"></div>
                            <div class="col-md-4 text-right">
                                <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}removeBtn" (click)="removeFromInvoiceDetail()">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>