<div *ngIf="showFirstPrint" class="page row system_responsive"
	id="{{pageId}}firstPrintForBkg" style="margin-bottom: 5px;">
	<div class="container border">
		<div class="col-md-12">
			<!--first row starts-->
			<div class="row" style="padding-top: 5px;">
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-7">
							<!--<img id="{{pageId}}barcodeImg" style="height: 70px;">-->
							<!-- For loacl -->
							<!--<img src='src/cashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
							<!-- For QA -->
							<!-- 							<img src='D:/SRD_BardcodeImg/destCashMemoBarcode/'+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->
							<!-- For Live -->
							<!--<img src='/home/ubuntu/SRDNewAppDeploymentFiles/SRD_BardcodeImg/destCashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
						</div>
						<div class="col-md-5">
							<h6 class="border_bottom dsply-ib">CASH/CREDIT MEMO</h6>
						</div>
					</div>
					<div class="row" style="padding-top: 5px; padding-bottom: 60px;"
						height="40">
						<div class="col-md-6" style="margin-left: -10px;">
							<div class="form-group">
								<div class="input-group">
									<h6>
										<strong><span [innerHTML]="addressLineOne"></span><span
											[innerHTML]="addressLineTwo"></span> </strong>
										<!-- 										<span [innerHTML]="address"></span> -->
									</h6>

								</div>
							</div>


						</div>
						<div class="col-md-6">
							<h6>
								<!-- 								<strong> Tel.: {{phone}}<br>Fax:: {{faxNo}}<br>Email -->
								<!-- 									: {{emailId}} -->
								<!-- 								</strong> -->
								<strong>Tel.No&nbsp;:<span [innerHTML]="phone"></span><br>Fax&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<span
									[innerHTML]="faxNo"></span><br>Email&nbsp;&nbsp;: <span
									[innerHTML]="emailId"></span></strong>
							</h6>
						</div>
						<!-- 						<div class="col-md-12" style="text-align: center;"> -->
						<!-- 							<h6> -->
						<!-- 								<strong> {{dupMsg}} </strong> -->
						<!-- 							</h6> -->
						<!-- 						</div> -->
					</div>
				</div>
				<div *ngIf="!showMain" class="col-md-4">

					<tr *ngFor="let PS of PrintBarcode">
						<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode> -->
						<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode6>
					</tr>
					<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
				</div>
			</div>

			<!--first row ends -->
			<!--seconde row Starts-->
			<div *ngIf="showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-12">
							<h6 style="font-size: 16px !important;">
								<strong>{{rateDtoForPrint.stationCode}}</strong><strong>
									TO </strong><strong>{{rateDtoForPrint.destination}}</strong>
							</h6>
						</div>

					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consignorState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.gstNoConsignor }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>MEMO</strong>
							</h6>
						</div>
						<div class="col-md-5" style="margin-left: -35px;">
							<h6 style="font-size: 15px !important;">
								<span
									style="margin-left: 0px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-3" style="margin-left: -30px;">
							<h6 style="font-size: 15px !important;">
								<span style="float: left; font-weight: bolder;">: </span><strong>
									{{ rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>

					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.gstNoConsignee}}</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--seconde row ends-->
			<div *ngIf="!showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>MEMO NO</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span
									style="margin-left: -10px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong> : {{rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>

				</div>
			</div>
			<!--seconde row ends-->
			<!--third row starts-->
			<div class="row border_top border_bottom">
				<div class="col-md-8 txt_ac">
					<h6>
						<strong>BOOKING DETAILS</strong>
					</h6>
				</div>
				<div class="col-md-4 txt_ac">
					<h6>
						<strong>AMOUNT CHARGES</strong>
					</h6>
				</div>
			</div>
			<!--third row ends-->
			<!--fourth row starts-->
			<div class="row">
				<div class="col-md-8 border_right">
					<div class="row">
						<div class="col-md-12">

							<div class="row" style="font-size: 15px !important;">
								<div class="col-md-3">
									<h6>
										<strong>G.C. Note No</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>
										: <strong>{{rateDtoForPrint.lrNumber}}</strong>
									</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Booking Date</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.enteredDate}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Place of Supply /<br> State
										</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.stationCode}} /
										{{rateDtoForPrint.consignorState}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Description</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.commodityName}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Value of Goods</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.goodsValue}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">


							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>No. Of Article</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.totalArticles}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Weight</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.chargedWeight}} Kgs</h6>
								</div>
							</div>

						</div>
					</div>

					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-6"
							style="font-size: 13px; padding: 15px 0px 10px 10px; margin-left: -10px; max-width: 100% !important; flex: 0 0 45%;">
							<h6 style="font-size: 13px;">GST is payable on Reverse
								Charge Mechanism</h6>
						</div>
						<div class="col-md-6"
							style="max-width: 100% !important; flex: 0 0 55%;">
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>Pan NO</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">
										: AAMCS7073E / <strong>SAC CODE</strong>: 996791
									</h6>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>CIN</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: U63090MH2009PTC189328</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>GSTIN No :</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: {{srdGstNO}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12 fontS_13 font_bold"
							style="margin-left: -10px;">
							<h6>
								<strong>{{bottomMsg1}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6 class="font_bold" style="font-size: 13px;">
								<strong>{{smsDetails}}</strong>
							</h6>
						</div>
					</div>
					<!-- 					<div class="row border_top" style="padding: 1px;"> -->
					<!-- 						<div class="col-md-12 fontS_13"> -->
					<!-- 							<h6> -->

					<!-- 							</h6> -->
					<!-- 						</div> -->
					<!-- 					</div> -->

				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-6 border_right">
							<div class="row" style="margin-left: -24px;">
								<div class="col-md-12">
									<h6>
										<strong>Lorry Freight</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Hamali</strong>
									</h6>
								</div>

								<div class="col-md-12">
									<h6>
										<strong>Receipt Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Service Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Dest.Hamali</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Less</strong>
									</h6>
								</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>Total Rs</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>CGST<span>&emsp;-&nbsp;{{rateDtoForPrint.cgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>SGST<span>&emsp;-&nbsp;{{rateDtoForPrint.sgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>IGST<span>&emsp;&nbsp;-&nbsp;{{rateDtoForPrint.igst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12" style="padding-top: 18px;">
									<h6 class="fontS_15">
										<strong>Grand Total</strong>
									</h6>
								</div>


							</div>
						</div>
						<div class="col-md-3 border_right" style="text-align: right;"
							id="{{pageId}}left">
							<div class="row">
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.frieghtAmt}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.hamaliGD}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.receiptCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.serviceCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.destHamali}}</h6>
								</div>
								<div class="col-md-12">{{empty}}</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>{{rateDtoForPrint.subTotal}}</strong>
									</h6>
								</div>
								<hr>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<hr>
								<div class="col-md-12" style="margin-top: 10px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.grandTotal}}</strong>
									</h6>
								</div>



							</div>
						</div>
						<div class="col-md-3 border_left" style="text-align: right;"
							id="{{pageId}}right">
							<div class="row">
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12">{{discount}}</div>
								<div class="col-md-12"></div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.cgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.sgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.igstamt}}</h6>
								</div>
								<div class="col-md-12" style="margin-top: 7px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.gstAmtTotal}}</strong>
									</h6>
								</div>


							</div>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6>
								<strong>IN Rupees : {{grandTotal}} {{roundAmt}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h5>
								<strong>FOR SRD LOGISTICS PVT.LTD.</strong>
							</h5>
							<h6 class="txt_ac fontS_12">
								<strong>{{rateDtoForPrint.entryBy}}</strong>
							</h6>
							<h6 class="fontS_12" style="float: right;">
								<strong>THANK YOU</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--fourth row ends-->
		</div>
	</div>
</div>
<!-- Row -->
<!-- first Print ends here  -->
<!-- 	<div> -->
<!-- 		<p style='overflow: visible; page-break-before: always;'></p> -->
<!-- 	</div> -->
<!-- <p style="page-break-after: always;">&nbsp;</p> -->
<!-- <p style="page-break-before: always;">&nbsp;</p> -->

<div *ngIf="showSecondPrint" class="page row system_responsive "
	id="{{pageId}}secondPrintForBkg" style="margin-bottom: 5px;">
	<div class="container border" style="page-break-before: always;">
		<div class="col-md-12">
			<!--first row starts-->
			<div class="row" style="padding-top: 5px;">
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-7">
							<!--<img id="{{pageId}}barcodeImg" style="height: 70px;">-->
							<!-- For loacl -->
							<!--<img src='src/cashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
							<!-- For QA -->
							<!-- 							<img src='D:/SRD_BardcodeImg/destCashMemoBarcode/'+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->
							<!-- For Live -->
							<!--<img src='/home/ubuntu/SRDNewAppDeploymentFiles/SRD_BardcodeImg/destCashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
						</div>
						<div class="col-md-5">
							<h6 class="border_bottom dsply-ib">CASH/CREDIT MEMO</h6>
						</div>
					</div>
					<div class="row" style="padding-top: 5px; padding-bottom: 60px;">
						<div class="col-md-6" style="margin-left: -10px;">
							<div class="form-group">
								<div class="input-group">
									<h6>
										<strong><span [innerHTML]="addressLineOne"></span><span
											[innerHTML]="addressLineTwo"></span> </strong>
										<!-- 										<span [innerHTML]="address"></span> -->
									</h6>

								</div>
							</div>


						</div>
						<div class="col-md-6">
							<h6>
								<!-- 								<strong> Tel.: {{phone}}<br>Fax:: {{faxNo}}<br>Email -->
								<!-- 									: {{emailId}} -->
								<!-- 								</strong> -->
								<strong>Tel.No&nbsp;:<span [innerHTML]="phone"></span><br>Fax&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<span
									[innerHTML]="faxNo"></span><br>Email&nbsp;&nbsp;: <span
									[innerHTML]="emailId"></span></strong>
							</h6>
						</div>
						<!-- 						<div class="col-md-12" style="text-align: center;"> -->
						<!-- 							<h6> -->
						<!-- 								<strong> {{dupMsg}} </strong> -->
						<!-- 							</h6> -->
						<!-- 						</div> -->
					</div>
				</div>
				<div *ngIf="!showMain" class="col-md-4">
					<tr *ngFor="let PS of PrintBarcode">
						<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode> -->
						<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode6>
					</tr>
					<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
				</div>
			</div>

			<!--first row ends -->
			<!--seconde row Starts-->
			<div *ngIf="showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-12">
							<h6 style="font-size: 16px !important;">
								<strong>{{rateDtoForPrint.stationCode}}</strong><strong>
									TO </strong><strong>{{rateDtoForPrint.destination}}</strong>
							</h6>
						</div>

					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consignorState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.gstNoConsignor }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>MEMO</strong>
							</h6>
						</div>
						<div class="col-md-5" style="margin-left: -35px;">
							<h6 style="font-size: 15px !important;">
								<span
									style="margin-left: 0px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-3" style="margin-left: -30px;">
							<h6 style="font-size: 15px !important;">
								<span style="float: left; font-weight: bolder;">: </span><strong>
									{{ rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>

					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.gstNoConsignee}}</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--seconde row ends-->
			<div *ngIf="!showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>MEMO NO</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span
									style="margin-left: -10px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong> : {{rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>

				</div>
			</div>
			<!--seconde row ends-->
			<!--third row starts-->
			<div class="row border_top border_bottom">
				<div class="col-md-8 txt_ac">
					<h6>
						<strong>BOOKING DETAILS</strong>
					</h6>
				</div>
				<div class="col-md-4 txt_ac">
					<h6>
						<strong>AMOUNT CHARGES</strong>
					</h6>
				</div>
			</div>
			<!--third row ends-->
			<!--fourth row starts-->
			<div class="row">
				<div class="col-md-8 border_right">
					<div class="row">
						<div class="col-md-12">

							<div class="row" style="font-size: 15px !important;">
								<div class="col-md-3">
									<h6>
										<strong>G.C. Note No</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>
										: <strong>{{rateDtoForPrint.lrNumber}}</strong>
									</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Booking Date</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.enteredDate}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Place of Supply /<br> State
										</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.stationCode}} /
										{{rateDtoForPrint.consignorState}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Description</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.commodityName}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Value of Goods</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.goodsValue}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">


							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>No. Of Article</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.totalArticles}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Weight</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.chargedWeight}} Kgs</h6>
								</div>
							</div>

						</div>
					</div>

					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-6"
							style="font-size: 13px; padding: 15px 0px 10px 10px; margin-left: -10px; max-width: 100% !important; flex: 0 0 45%;">
							<h6 style="font-size: 13px;">GST is payable on Reverse
								Charge Mechanism</h6>
						</div>
						<div class="col-md-6"
							style="max-width: 100% !important; flex: 0 0 55%;">
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>Pan NO</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">
										: AAMCS7073E / <strong>SAC CODE</strong>: 996791
									</h6>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>CIN</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: U63090MH2009PTC189328</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>GSTIN No :</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: {{srdGstNO}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12 fontS_13 font_bold"
							style="margin-left: -10px;">
							<h6>
								<strong>{{bottomMsg1}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6 class="font_bold" style="font-size: 13px;">
								<strong>{{smsDetails}}</strong>
							</h6>
						</div>
					</div>
					<!-- 					<div class="row border_top" style="padding: 1px;"> -->
					<!-- 						<div class="col-md-12 fontS_13"> -->
					<!-- 							<h6> -->

					<!-- 							</h6> -->
					<!-- 						</div> -->
					<!-- 					</div> -->

				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-6 border_right">
							<div class="row" style="margin-left: -24px;">
								<div class="col-md-12">
									<h6>
										<strong>Lorry Freight</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Hamali</strong>
									</h6>
								</div>

								<div class="col-md-12">
									<h6>
										<strong>Receipt Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Service Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Dest.Hamali</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Less</strong>
									</h6>
								</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>Total Rs</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>CGST<span>&emsp;-&nbsp;{{rateDtoForPrint.cgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>SGST<span>&emsp;-&nbsp;{{rateDtoForPrint.sgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>IGST<span>&emsp;&nbsp;-&nbsp;{{rateDtoForPrint.igst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12" style="padding-top: 18px;">
									<h6 class="fontS_15">
										<strong>Grand Total</strong>
									</h6>
								</div>


							</div>
						</div>
						<div class="col-md-3 border_right" style="text-align: right;"
							id="{{pageId}}left">
							<div class="row">
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.frieghtAmt}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.hamaliGD}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.receiptCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.serviceCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.destHamali}}</h6>
								</div>
								<div class="col-md-12">{{empty}}</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>{{rateDtoForPrint.subTotal}}</strong>
									</h6>
								</div>
								<hr>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<hr>
								<div class="col-md-12" style="margin-top: 10px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.grandTotal}}</strong>
									</h6>
								</div>



							</div>
						</div>
						<div class="col-md-3 border_left" style="text-align: right;"
							id="{{pageId}}right">
							<div class="row">
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12">{{discount}}</div>
								<div class="col-md-12"></div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.cgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.sgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.igstamt}}</h6>
								</div>
								<div class="col-md-12" style="margin-top: 7px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.gstAmtTotal}}</strong>
									</h6>
								</div>


							</div>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6>
								<strong>IN Rupees : {{grandTotal}} {{roundAmt}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h5>
								<strong>FOR SRD LOGISTICS PVT.LTD.</strong>
							</h5>
							<h6 class="txt_ac fontS_12">
								<strong>{{rateDtoForPrint.entryBy}}</strong>
							</h6>
							<h6 class="fontS_12" style="float: right;">
								<strong>THANK YOU</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--fourth row ends-->
		</div>
	</div>
</div>
<!-- Row -->
<!-- second Print Ends here  -->
<!-- <div style='float: left'> -->

<!-- 		<p style='overflow: hidden; page-break-before: always;'></p> -->

<!-- 	</div> -->
<div *ngIf="showThirdPrint" class="page row system_responsive "
	id="{{pageId}}thirdPrintForBkg" style="margin-bottom: 5px;">
	<div class="container border" style="page-break-before: always;">
		<div class="col-md-12">
			<!--first row starts-->
			<div class="row" style="padding-top: 5px;">
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-7">
							<!--<img id="{{pageId}}barcodeImg" style="height: 70px;">-->
							<!-- For loacl -->
							<!--<img src='src/cashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
							<!-- For QA -->
							<!-- 							<img src='D:/SRD_BardcodeImg/destCashMemoBarcode/'+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->
							<!-- For Live -->
							<!--<img src='/home/ubuntu/SRDNewAppDeploymentFiles/SRD_BardcodeImg/destCashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
						</div>
						<div class="col-md-5">
							<h6 class="border_bottom dsply-ib">CASH/CREDIT MEMO</h6>
						</div>
					</div>
					<div class="row" style="padding-top: 5px; padding-bottom: 60px;">
						<div class="col-md-6" style="margin-left: -10px;">
							<div class="form-group">
								<div class="input-group">
									<h6>
										<strong><span [innerHTML]="addressLineOne"></span><span
											[innerHTML]="addressLineTwo"></span> </strong>
										<!-- 										<span [innerHTML]="address"></span> -->
									</h6>

								</div>
							</div>


						</div>
						<div class="col-md-6">
							<h6>
								<!-- 								<strong> Tel.: {{phone}}<br>Fax:: {{faxNo}}<br>Email -->
								<!-- 									: {{emailId}} -->
								<!-- 								</strong> -->
								<strong>Tel.No&nbsp;:<span [innerHTML]="phone"></span><br>Fax&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:<span
									[innerHTML]="faxNo"></span><br>Email&nbsp;&nbsp;: <span
									[innerHTML]="emailId"></span></strong>
							</h6>
						</div>
						<!-- 						<div class="col-md-12" style="text-align: center;"> -->
						<!-- 							<h6> -->
						<!-- 								<strong> {{dupMsg}} </strong> -->
						<!-- 							</h6> -->
						<!-- 						</div> -->
					</div>
				</div>
				<div *ngIf="!showMain" class="col-md-4">
					<tr *ngFor="let PS of PrintBarcode">
						<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode> -->
						<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
							[bc-value]="PS.barcode" [bc-display-value]="true">
						</ngx-barcode6>
					</tr>
					<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
				</div>
			</div>

			<!--first row ends -->
			<!--seconde row Starts-->
			<div *ngIf="showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-12">
							<h6 style="font-size: 16px !important;">
								<strong>{{rateDtoForPrint.stationCode}}</strong><strong>
									TO </strong><strong>{{rateDtoForPrint.destination}}</strong>
							</h6>
						</div>

					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consignorState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.gstNoConsignor }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>MEMO</strong>
							</h6>
						</div>
						<div class="col-md-5" style="margin-left: -35px;">
							<h6 style="font-size: 15px !important;">
								<span
									style="margin-left: 0px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
						<div class="col-md-2">
							<h6 style="font-size: 15px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-3" style="margin-left: -30px;">
							<h6 style="font-size: 15px !important;">
								<span style="float: left; font-weight: bolder;">: </span><strong>
									{{ rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>

					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>ADDRESS</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeAdress }}</strong>
							</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeState}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>STATE CODE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.consigneeStateCode}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>GSTIN No</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{rateDtoForPrint.gstNoConsignee}}</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--seconde row ends-->
			<div *ngIf="!showMain" class="row">
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>MEMO NO</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span
									style="margin-left: -10px; font-weight: bolder; font-weight: bolder;">:
								</span><strong> {{rateDtoForPrint.cashMemoNumber}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNOR</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consignorName }}</strong>
							</h6>
						</div>
					</div>

				</div>
				<div class="col-md-6">
					<div class="row" style="padding-bottom: 10px;">
						<div class="col-md-3">
							<h6 style="font-size: 16px !important;">
								<strong>DATE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6 style="font-size: 16px !important;">
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong> : {{rateDtoForPrint.bookingDate}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<h6>
								<strong>CONSIGNEE</strong>
							</h6>
						</div>
						<div class="col-md-9" style="margin-left: -30px;">
							<h6>
								<span style="margin-left: -10px; font-weight: bolder;">:
								</span><strong>{{ rateDtoForPrint.consigneeName}}</strong>
							</h6>
						</div>
					</div>

				</div>
			</div>
			<!--seconde row ends-->
			<!--third row starts-->
			<div class="row border_top border_bottom">
				<div class="col-md-8 txt_ac">
					<h6>
						<strong>BOOKING DETAILS</strong>
					</h6>
				</div>
				<div class="col-md-4 txt_ac">
					<h6>
						<strong>AMOUNT CHARGES</strong>
					</h6>
				</div>
			</div>
			<!--third row ends-->
			<!--fourth row starts-->
			<div class="row">
				<div class="col-md-8 border_right">
					<div class="row">
						<div class="col-md-12">

							<div class="row" style="font-size: 15px !important;">
								<div class="col-md-3">
									<h6>
										<strong>G.C. Note No</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>
										: <strong>{{rateDtoForPrint.lrNumber}}</strong>
									</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Booking Date</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.enteredDate}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Place of Supply /<br> State
										</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.stationCode}} /
										{{rateDtoForPrint.consignorState}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Description</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.commodityName}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Value of Goods</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.goodsValue}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">


							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>No. Of Article</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.totalArticles}}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<h6>
										<strong>Weight</strong>
									</h6>
								</div>
								<div class="col-md-9" style="margin-left: -40px;">
									<h6>: {{rateDtoForPrint.chargedWeight}} Kgs</h6>
								</div>
							</div>

						</div>
					</div>

					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-6"
							style="font-size: 13px; padding: 15px 0px 10px 10px; margin-left: -10px; max-width: 100% !important; flex: 0 0 45%;">
							<h6 style="font-size: 13px;">GST is payable on Reverse
								Charge Mechanism</h6>
						</div>
						<div class="col-md-6"
							style="max-width: 100% !important; flex: 0 0 55%;">
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>Pan NO</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">
										: AAMCS7073E / <strong>SAC CODE</strong>: 996791
									</h6>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>CIN</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: U63090MH2009PTC189328</h6>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<h6 style="font-size: 12px;">
										<strong>GSTIN No :</strong>
									</h6>
								</div>
								<div class="col-md-8" style="margin-left: -7px;">
									<h6 style="font-size: 12px;">: {{srdGstNO}}</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12 fontS_13 font_bold"
							style="margin-left: -10px;">
							<h6>
								<strong>{{bottomMsg1}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top" style="padding: 1px;">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6 class="font_bold" style="font-size: 13px;">
								<strong>{{smsDetails}}</strong>
							</h6>
						</div>
					</div>
					<!-- 					<div class="row border_top" style="padding: 1px;"> -->
					<!-- 						<div class="col-md-12 fontS_13"> -->
					<!-- 							<h6> -->

					<!-- 							</h6> -->
					<!-- 						</div> -->
					<!-- 					</div> -->

				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-6 border_right">
							<div class="row" style="margin-left: -24px;">
								<div class="col-md-12">
									<h6>
										<strong>Lorry Freight</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Hamali</strong>
									</h6>
								</div>

								<div class="col-md-12">
									<h6>
										<strong>Receipt Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Service Chg</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Dest.Hamali</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>Less</strong>
									</h6>
								</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>Total Rs</strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>CGST<span>&emsp;-&nbsp;{{rateDtoForPrint.cgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>SGST<span>&emsp;-&nbsp;{{rateDtoForPrint.sgst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12">
									<h6>
										<strong>IGST<span>&emsp;&nbsp;-&nbsp;{{rateDtoForPrint.igst}}%</span></strong>
									</h6>
								</div>
								<div class="col-md-12" style="padding-top: 18px;">
									<h6 class="fontS_15">
										<strong>Grand Total</strong>
									</h6>
								</div>


							</div>
						</div>
						<div class="col-md-3 border_right" style="text-align: right;"
							id="{{pageId}}left">
							<div class="row">
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.frieghtAmt}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.hamaliGD}}</h6>
								</div>

								<div class="col-md-12">
									<h6>{{rateDtoForPrint.receiptCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.serviceCharge}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.destHamali}}</h6>
								</div>
								<div class="col-md-12">{{empty}}</div>
								<div class="col-md-12 fontS_15">
									<h6>
										<strong>{{rateDtoForPrint.subTotal}}</strong>
									</h6>
								</div>
								<hr>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<hr>
								<div class="col-md-12" style="margin-top: 10px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.grandTotal}}</strong>
									</h6>
								</div>



							</div>
						</div>
						<div class="col-md-3 border_left" style="text-align: right;"
							id="{{pageId}}right">
							<div class="row">
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12"></div>
								<div class="col-md-12">{{discount}}</div>
								<div class="col-md-12"></div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.cgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.sgstamt}}</h6>
								</div>
								<div class="col-md-12">
									<h6>{{rateDtoForPrint.igstamt}}</h6>
								</div>
								<div class="col-md-12" style="margin-top: 7px;">
									<h6 class="fontS_15">
										<strong>{{rateDtoForPrint.gstAmtTotal}}</strong>
									</h6>
								</div>


							</div>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h6>
								<strong>IN Rupees : {{grandTotal}} {{roundAmt}}</strong>
							</h6>
						</div>
					</div>
					<div class="row border_top">
						<div class="col-md-12" style="margin-left: -10px;">
							<h5>
								<strong>FOR SRD LOGISTICS PVT.LTD.</strong>
							</h5>
							<h6 class="txt_ac fontS_12">
								<strong>{{rateDtoForPrint.entryBy}}</strong>
							</h6>
							<h6 class="fontS_12" style="float: right;">
								<strong>THANK YOU</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<!--fourth row ends-->
		</div>
	</div>
</div>
<!-- Row -->
<!--  third print ends here  -->
