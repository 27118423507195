//for datepicker starts
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrService } from 'src/app/dataService/lr-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import *  as moment from 'moment';
//for the select option with filter ends
@Component({
    selector: 'app-agent-statement-payment-details',
    templateUrl: './agent-statement-payment-details.component.html',
    styleUrls: ['./agent-statement-payment-details.component.css']
})
export class AgentStatementPaymentDetailsComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    controlMain = new FormControl();

    dataTable: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerBkgAgentStmtCashChequePdc: Subject<any> = new Subject();
    dtOptionsBkgAgentStmtCashChequePdc: any;
    bkgAgentStmtCashChequePdcDataList: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction: boolean = false;

    userDataDto: UserDataDto = new UserDataDto();
    bankNameOptions: UserDataDto[];
    public modelBankName: any;
    bankNameTA: Array<UserDataDto> = [];
    focusBankNameTA$ = new Subject<string>();
    searchBankName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    viewCashChequePdcAmtField: boolean = true;
    viewChequePdcNoField: boolean = false;
    viewChequeDueDateField: boolean = false;
    viewChequePdcBankNameField: boolean = false;

    modelChequeDueDates: any;
    modelPayingDates: any;

    localStorageOfPaymentData: any;

    private newAttributeTosetBank: any = {};
    editBankTableDetailsId: any;

    validateBalAmtToBePaid: any;
    validateBalAmtCash: any;
    validateBalAmtCheque: any;
    validateBalAmtPdc: any;

    validateBalAmtForSave: any;
    validateSummCashAmtForSave: any;
    validateSummChequeAmtForSave: any;
    validateSummPdcAmtForSave: any;
    validatePayingDateForSave: any;
    validateStmtNoForSave: any;
    validateInitBalAmtForSave: any;
    resultDuplicate: any;

    fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();

    Statement: any;

pageId="aspdt";

    constructor(private agentCommodityScreen: MasterService, private modalService: NgbModal,
        private router: Router, private masterReadService: MasterReadService,
        private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.localStorageOfPaymentData = null;
            this.localStorageOfPaymentData = JSON.parse(localStorage.getItem('popupAgentStmtPaymentRptLrDetls'));
            if (this.localStorageOfPaymentData != null &&
                this.localStorageOfPaymentData != undefined) {
                this.Statement = this.localStorageOfPaymentData.agentName;
                this.filledValues(this.localStorageOfPaymentData);
            }
            this.getBankDetailsList();
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsBkgAgentStmtCashChequePdc = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var totalBankAmt = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalBankAmt
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": ""
                                                            }))
                                        .prop('outerHTML'));
                            totalBankAmt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    totalBankAmt += +val[2];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count

                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalBankAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        })).append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                    .prop('outerHTML'));
                        totalBankAmt = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerBkgAgentStmtCashChequePdc.unsubscribe();
        //this.onDestroyUnsubscribtionBkgAgentStmtReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        /*var strstring ="null#";
            var dd= strstring.replace("null#","A");
            console.log(dd);*/
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
        this.modelBankName = e.item;
        $("#"+this.pageId+"bankNameId").val(this.modelBankName.bankName);
        $("#"+this.pageId+"bankNameId").focusout();
    }

    getDetailsForBankMasterRead() {
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        //this.showSpinnerForAction = true;
        this.bankNameOptions = [];
        this.getDetailsForBankMasterRead();
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.bankNameOptions = response;
                        this.bankNameTA = [];
                        for (let i = 0; i < this.bankNameOptions.length; i++) {
                            this.bankNameTA.push(this.bankNameOptions[i]);
                        }
                        //this.showSpinnerForAction = false;
                    } else {
                        //this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Master Details", "error");
            },
            () => console.log('done');
    };




    payemntModeTypeListner(modeType: string) {
        if (modeType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;

            $("#"+this.pageId+"chequePdcNo").val('');
            $("#"+this.pageId+"chequeDueDates").val('');
            $("#"+this.pageId+"bankNameId").val('');
            $("#"+this.pageId+"cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;
            this.modelBankName = null;

        }
    }


    filledValues(localStorageOfPaymentData) {
        console.log(localStorageOfPaymentData);
        var chequeAmtSumm = 0.0, pdcAmtSmm = 0.0;


        var cashAmt = [];
        var chequeAmt = [], chequeNumber = [], cheqBankName = [];
        var pdcAmt = [], pdcNumber = [], pdcDueDate = [], pdcBankName = [];

        //cashAmt
        // cashAmt = localStorageOfPaymentData.cashAmt == null ? null
        //     : localStorageOfPaymentData.cashAmt.split("#");
        cashAmt = localStorageOfPaymentData.cashAmt == null ? null
            : localStorageOfPaymentData.cashAmt;

        //cheque
        chequeAmt = localStorageOfPaymentData.chequeAmtStr == null ? null
            : localStorageOfPaymentData.chequeAmtStr.split("#");
        chequeNumber = localStorageOfPaymentData.chequeNumber == null ? null
            : localStorageOfPaymentData.chequeNumber.split("#");
        cheqBankName = localStorageOfPaymentData.cheqBankName == null ? null
            : localStorageOfPaymentData.cheqBankName.split("#");

        //pdc
        pdcAmt = localStorageOfPaymentData.pdcAmtStr == null ? null
            : localStorageOfPaymentData.pdcAmtStr.split("#");
        pdcNumber = localStorageOfPaymentData.pdcNumber == null ? null
            : localStorageOfPaymentData.pdcNumber.split("#");
        pdcDueDate = localStorageOfPaymentData.pdcDueDateStr == null ? null
            : localStorageOfPaymentData.pdcDueDateStr.split("#");
        pdcBankName = localStorageOfPaymentData.pdcBankName == null ? null
            : localStorageOfPaymentData.pdcBankName.split("#");
        console.log(pdcAmt, chequeAmt, cashAmt);

        this.bkgAgentStmtCashChequePdcDataList = [];

        if (cashAmt != null && cashAmt != undefined) {
            this.newAttributeTosetBank = {};
            this.newAttributeTosetBank.paymentType = "Cash";
            this.newAttributeTosetBank.chequeNumber = null;
            this.newAttributeTosetBank.amount = cashAmt;
            this.newAttributeTosetBank.bankName = null;
            this.newAttributeTosetBank.pdcDueDate = null;
            this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
        }

        if (chequeAmt != null && chequeAmt != undefined &&
            chequeAmt.length > 0) {
            for (let i = 0; i < chequeAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "Cheque";
                this.newAttributeTosetBank.chequeNumber = chequeNumber[i];
                this.newAttributeTosetBank.amount = chequeAmt[i];
                this.newAttributeTosetBank.bankName = cheqBankName[i];
                this.newAttributeTosetBank.pdcDueDate = null;
                this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);

                chequeAmtSumm = +chequeAmtSumm + +chequeAmt[i];
            }
        }

        if (pdcAmt != null && pdcAmt != undefined &&
            pdcAmt.length > 0) {
            for (let i = 0; i < pdcAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "PDC";
                this.newAttributeTosetBank.chequeNumber = pdcNumber[i];
                this.newAttributeTosetBank.amount = pdcAmt[i];
                this.newAttributeTosetBank.bankName = pdcBankName[i];
                this.newAttributeTosetBank.pdcDueDate = pdcDueDate[i];
                this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);

                pdcAmtSmm = +pdcAmtSmm + +pdcAmt[i];
            }
        }
        $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        var balanceAmt = 0.0;
        this.showSpinnerForAction = true;
        setTimeout(() => {
            $("#"+this.pageId+"destination").val(localStorageOfPaymentData.stmtNo);
            $("#"+this.pageId+"amount").val(localStorageOfPaymentData.amtToBeReceived);
            $("#"+this.pageId+"payingDates").val(moment(new Date()).format("YYYY-MM-DD"));
            $("#"+this.pageId+"totalAmountToBePaid").val(localStorageOfPaymentData.amtToBeReceived);
            $("#"+this.pageId+"summaryCashAmt").val(localStorageOfPaymentData.cashAmt);
            $("#"+this.pageId+"summaryChequeAmt").val(chequeAmtSumm);
            $("#"+this.pageId+"summaryPDCAmt").val(pdcAmtSmm);
            balanceAmt = localStorageOfPaymentData.amtToBeReceived + -localStorageOfPaymentData.cashAmt + -chequeAmtSumm + -pdcAmtSmm;
            $("#"+this.pageId+"balanceToBePaid").val(balanceAmt);
            this.showSpinnerForAction = false;
        }, 2000);
    }

    balanceAmt() {
        var balanceAmt = 0.0;
        this.validateBalAmtToBePaid = 0;
        this.validateBalAmtCash = 0;
        this.validateBalAmtCheque = 0;
        this.validateBalAmtPdc = 0;

        this.validateBalAmtToBePaid = $("#"+this.pageId+"amount").val();
        this.validateBalAmtCash = $("#"+this.pageId+"summaryCashAmt").val();
        this.validateBalAmtCheque = $("#"+this.pageId+"summaryChequeAmt").val();
        this.validateBalAmtPdc = $("#"+this.pageId+"summaryPDCAmt").val();

        balanceAmt = +this.validateBalAmtToBePaid + -this.validateBalAmtCash + -this.validateBalAmtCheque + -this.validateBalAmtPdc;

        $("#"+this.pageId+"balanceToBePaid").val(balanceAmt);
    }

    calculateCashAmtSum() {
        var cashSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashSum = cashSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return cashSum;
    }

    calculateChequeAmtSum() {
        var chequeSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chequeSum = chequeSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return chequeSum;
    }

    calculatePdcAmtSum() {
        var pdcSum = 0.0;
        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcSum = pdcSum + +this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return pdcSum;
    }

    onKeyPressListnerForAmount(event) {
        if (event.charCode == 13) {
            $("#"+this.pageId+"incentiveToId").focus();
            this.balanceAmt();
        }
    }

    addBankDetailsInTable() {
        if (($("#"+this.pageId+"payemntModeType").val() == "Cash") &&
            ($("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter amount", "warning");
            return false;
        } else if (($("#"+this.pageId+"payemntModeType").val() == "Cheque") &&
            ($("#"+this.pageId+"chequePdcNo").val() == null || $("#"+this.pageId+"chequePdcNo").val() == undefined ||
                $("#"+this.pageId+"chequePdcNo").val() == "" || $("#"+this.pageId+"bankNameId").val() == null ||
                $("#"+this.pageId+"bankNameId").val() == undefined || $("#"+this.pageId+"bankNameId").val() == "" ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else if (($("#"+this.pageId+"payemntModeType").val() == "PDC") &&
            ($("#"+this.pageId+"chequePdcNo").val() == null || $("#"+this.pageId+"chequePdcNo").val() == undefined ||
                $("#"+this.pageId+"chequePdcNo").val() == "" || $("#"+this.pageId+"bankNameId").val() == null ||
                $("#"+this.pageId+"bankNameId").val() == undefined || $("#"+this.pageId+"bankNameId").val() == "" ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == null || $("#"+this.pageId+"cashChequePdcAmount").val() == undefined ||
                $("#"+this.pageId+"cashChequePdcAmount").val() == "" || $("#"+this.pageId+"chequeDueDates").val() == null ||
                $("#"+this.pageId+"chequeDueDates").val() == undefined || $("#"+this.pageId+"chequeDueDates").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else {
            this.addIntoTableOfBankDetails();
        }
    }

    addIntoTableOfBankDetails() {
        this.newAttributeTosetBank = {};
        this.newAttributeTosetBank.paymentType = $("#"+this.pageId+"payemntModeType").val();
        this.newAttributeTosetBank.chequeNumber = $("#"+this.pageId+"chequePdcNo").val();
        this.newAttributeTosetBank.amount = $("#"+this.pageId+"cashChequePdcAmount").val();
        this.newAttributeTosetBank.bankName = $("#"+this.pageId+"bankNameId").val();
        this.newAttributeTosetBank.pdcDueDate = $("#"+this.pageId+"chequeDueDates").val();
        //this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);


        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.bkgAgentStmtCashChequePdcDataList.splice(this.editBankTableDetailsId, 1);
        }

        if (this.bkgAgentStmtCashChequePdcDataList == null ||
            this.bkgAgentStmtCashChequePdcDataList == undefined ||
            this.bkgAgentStmtCashChequePdcDataList.length == 0) {
            this.bkgAgentStmtCashChequePdcDataList = [];
        }

        if (this.bkgAgentStmtCashChequePdcDataList.length == 0) {
            this.bkgAgentStmtCashChequePdcDataList = [];
            $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
            this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
            this.dtTriggerBkgAgentStmtCashChequePdc.next();
            this.clearFieldBank();
            $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
            $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
            $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
            this.balanceAmt();
        } else {
            if ($("#"+this.pageId+"payemntModeType").val() == "Cash") {
                const result = this.bkgAgentStmtCashChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Cash Amount Already Available ,Please Edit the Cash Amount and then Add", "warning");
                } else {
                    $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerBkgAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            } else {
                const result = this.bkgAgentStmtCashChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                    bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
                } else {
                    $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerBkgAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            }

            /* here start */
            /* const result = this.bkgAgentStmtCashChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                 bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                 bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
             if (result.length > 0) {
                 swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
             } else {
                 $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
                 this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                 this.dtTriggerBkgAgentStmtCashChequePdc.next();
                 this.clearFieldBank();
                 $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
                 $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
                 $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
                 this.balanceAmt();
             }*/
            /* here end */
        }
    }

    rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData, index) {
        this.editBankTableDetailsId = index;
        if (bkgAgentStmtCashChequePdcData.paymentType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("Cash");
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("Cheque");
                $("#"+this.pageId+"chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#"+this.pageId+"bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            this.showSpinnerForAction = true;
            setTimeout(() => {
                $("#"+this.pageId+"payemntModeType").val("PDC");
                $("#"+this.pageId+"chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#"+this.pageId+"bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#"+this.pageId+"chequeDueDates").val(bkgAgentStmtCashChequePdcData.pdcDueDate);
                $("#"+this.pageId+"cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
                this.showSpinnerForAction = false;
            }, 1000);
        }
    }

    deleteSelectedBankDetails(index) {
        $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.bkgAgentStmtCashChequePdcDataList.splice(index, 1);
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        $("#"+this.pageId+"summaryCashAmt").val(this.calculateCashAmtSum());
        $("#"+this.pageId+"summaryChequeAmt").val(this.calculateChequeAmtSum());
        $("#"+this.pageId+"summaryPDCAmt").val(this.calculatePdcAmtSum());
        this.balanceAmt();
    }


    clearFieldBank() {
        this.editBankTableDetailsId = null;
        this.newAttributeTosetBank = {};
        $("#"+this.pageId+"chequePdcNo").val('');
        $("#"+this.pageId+"chequeDueDates").val('');
        $("#"+this.pageId+"bankNameId").val('');
        $("#"+this.pageId+"cashChequePdcAmount").val('');

        this.modelChequeDueDates = null;
        this.modelBankName = null;
    }

    clearBankDetailsInTable() {
        this.clearFieldBank();
        $("#"+this.pageId+"payemntModeType").val('Cash');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = false;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = false;
    }


    /* save start here */
    validateConfirmPaymentBtn() {
        if ($("#"+this.pageId+"payingDates").val() == null || $("#"+this.pageId+"payingDates").val() == undefined ||
            $("#"+this.pageId+"payingDates").val() == "") {
            swal("Mandatory Field", "Please Select the Receiving Date", "warning");
            return false;
        } else {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Submit the Amount Details ?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.setBookingAgentAmtPaymentDetails();
                }
            });
        }
    }

    setBookingAgentAmtPaymentDetails() {
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = this.getUserValues();
        console.log(this.fortNightStmtDtoForReturnGetUserValue);
        this.showSpinnerForAction = true;
        this.lrService.bkgAgtAmtPayable(this.fortNightStmtDtoForReturnGetUserValue).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "success") {
                    swal("Alert", "Update Successfully", "success");
                    this.clearOverAllField();
                } else if (response.status == "notfound") {
                    swal("No Found", "No Record Found", "warning");
                } else if (response.status == "failed") {
                    swal("Failed", "Ooops...Problem occured", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Booking Agent Amt Payable", "error");
            },
            () => console.log('done');
    }

    getUserValues() {
        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();

        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validatePayingDateForSave = 0.0;
        this.validateStmtNoForSave = null;
        this.validateInitBalAmtForSave = 0.0;

        this.validateBalAmtForSave = $("#"+this.pageId+"balanceToBePaid").val();
        this.validateSummCashAmtForSave = $("#"+this.pageId+"summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#"+this.pageId+"summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#"+this.pageId+"summaryPDCAmt").val();
        this.validatePayingDateForSave = $("#"+this.pageId+"payingDates").val();
        this.validateStmtNoForSave = $("#"+this.pageId+"destination").val();
        this.validateInitBalAmtForSave = $("#"+this.pageId+"amount").val();

        this.fortNightStmtDtoForGetUserValue = this.setMultipleValue();

        this.fortNightStmtDtoForGetUserValue.initialBalanceAmt = this.validateInitBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.receivedDate = this.validatePayingDateForSave;
        this.fortNightStmtDtoForGetUserValue.chequeAmt = this.validateSummChequeAmtForSave;
        this.fortNightStmtDtoForGetUserValue.pdcAmt = this.validateSummPdcAmtForSave;
        this.fortNightStmtDtoForGetUserValue.balanceAmt = this.validateBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.updatedBy = this.userDataDtoReturnSession.userId;
        var totAmtPaid = 0.0;
        totAmtPaid = +this.fortNightStmtDtoForGetUserValue.cashAmt +
            +this.fortNightStmtDtoForGetUserValue.chequeAmt +
            +this.fortNightStmtDtoForGetUserValue.pdcAmt;
        this.fortNightStmtDtoForGetUserValue.totalPaid = totAmtPaid;
        this.fortNightStmtDtoForGetUserValue.stmtNo = this.validateStmtNoForSave;
        return this.fortNightStmtDtoForGetUserValue;
    }

    setMultipleValue() {
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();
        var cashAmtMultiple = null;
        var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
        var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;


        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chqNumMultiple = chqNumMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].chequeNumber;
                chqAmtMultiple = chqAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
                cheqBankNameMultiple = cheqBankNameMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcNumMultiple = pdcNumMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].chequeNumber;
                pdcAmtMultiple = pdcAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
                pdcDueDateMultiple = pdcDueDateMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].pdcDueDate;
                pdcBankNameMultiple = pdcBankNameMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.bkgAgentStmtCashChequePdcDataList.length; i++) {
            if (this.bkgAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashAmtMultiple = cashAmtMultiple + "#"
                    + this.bkgAgentStmtCashChequePdcDataList[i].amount;
            }
        }

        if (chqNumMultiple != null) {
            chqNumMultiple = chqNumMultiple.replace("null#", "");
        }
        if (chqAmtMultiple != null) {
            chqAmtMultiple = chqAmtMultiple.replace("null#", "");
        }
        if (pdcNumMultiple != null) {
            pdcNumMultiple = pdcNumMultiple.replace("null#", "");
        }
        if (pdcAmtMultiple != null) {
            pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
        }
        if (pdcDueDateMultiple != null) {
            pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
        }
        if (pdcBankNameMultiple != null) {
            pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
        }
        if (cheqBankNameMultiple != null) {
            cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
        }

        if (cashAmtMultiple != null) {
            cashAmtMultiple = cashAmtMultiple.replace("null#", "");
        }

        this.fortNightStmtDtoForMultipleValue.cashAmt = cashAmtMultiple;

        this.fortNightStmtDtoForMultipleValue.chequeNumber = chqNumMultiple;
        this.fortNightStmtDtoForMultipleValue.chequeAmtStr = chqAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.cheqBankName = cheqBankNameMultiple;

        this.fortNightStmtDtoForMultipleValue.pdcNumber = pdcNumMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcAmtStr = pdcAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcDueDateStr = pdcDueDateMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcBankName = pdcBankNameMultiple;
        return this.fortNightStmtDtoForMultipleValue;
    }

    overAllClearFieldBtn() {
        this.clearOverAllField();
    }
    clearOverAllField() {
        $("#"+this.pageId+"payemntModeType").val('Cash');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = false;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = false;
        this.clearFieldBank();

        this.modelChequeDueDates = null;
        this.modelPayingDates = null;

        this.localStorageOfPaymentData = null;

        this.newAttributeTosetBank = {};
        this.editBankTableDetailsId = null;

        this.validateBalAmtToBePaid = null;
        this.validateBalAmtCash = null;
        this.validateBalAmtCheque = null;
        this.validateBalAmtPdc = null;

        this.validateBalAmtForSave = null;
        this.validateSummCashAmtForSave = null;
        this.validateSummChequeAmtForSave = null;
        this.validateSummPdcAmtForSave = null;
        this.validatePayingDateForSave = null;
        this.validateStmtNoForSave = null;
        this.validateInitBalAmtForSave = null;
        this.resultDuplicate = null;

        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

        $("#"+this.pageId+"totalAmountToBePaid").val('');
        $("#"+this.pageId+"balanceToBePaid").val('');
        $("#"+this.pageId+"summaryCashAmt").val('');
        $("#"+this.pageId+"summaryChequeAmt").val('');
        $("#"+this.pageId+"summaryPDCAmt").val('');
        $("#"+this.pageId+"payingDates").val('');
        $("#"+this.pageId+"destination").val('');
        $("#"+this.pageId+"amount").val('');

        this.bkgAgentStmtCashChequePdcDataList = [];
        $("#"+this.pageId+"bkgAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerBkgAgentStmtCashChequePdc.next();
        this.Statement = '';
    }
}