import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//import { Subject, Subscription } from 'rxjs';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StockReport } from 'src/app/dataService/stock-report';
import { InvoiceDetailsComponent } from '../../hireslip-and-invoice-report/invoice-details/invoice-details.component';
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { ISubscription } from "rxjs/Subscription";

@Component({
    selector: 'app-stocks-unloading-report',
    templateUrl: './stocks-unloading-report.component.html',
    styleUrls: ['./stocks-unloading-report.component.css']
})
export class StocksUnloadingReportComponent implements OnInit {

    gettingDataFrmServiceFrUnloadingReportTable: any;

    unloadingReportDataList: any;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;

    //for datepicker ends
    // for popup modal starts
    closeResult: string;
    // for popup modal ends

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerUnloadingReport: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsUnloadingReport: any;

    hideAndShowEnableField: boolean = false;
    validateCheckboxEnable: boolean = false;
    hideAndShowInvoiceNumberField: boolean = false;
    validateInvoiceNumberCheckBox: boolean = false;
    validateFromDate: any = null;
    validatetoDate: any = null;
    validateInvoiceNumber: any = null;

    validateCheckBoxEnableAll: boolean = null;
    validateCheckBoxEnableSpecific: boolean = null;
    validateCheatCode: any = null;


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;


    invoiceDto: InvoiceDto = new InvoiceDto();
    fromDatesModal: any = null;
    toDatesModal: any = null;

    rightsForOffice = "Rights For Office";

    modalRefferenceStockUnloadingRptPopUp: NgbModalRef;
    modalRefferenceStockUnloadingRptClosePopUp: string;
    @ViewChild('stockUnloadingPopupTemplate') private stockUnloadingPopupTemplate;

    hideAndShowDetainedTable = false;
    hideAndShowNotDetainedTable = false;
    showSpinnerForAction = false;

    invoice_Dto: InvoiceDto = new InvoiceDto();
    viewDeliveryUnldDetainedPopUp = false;
    viewDeliveryUnldNotDetainedPopUp = false;

//For Custom Print
viewCustomPrintV1:any;

cashMemoDtoForCustomPrintListHeadingV1: any;
cashMemoDtoForCustomPrintListHeadingV2:any;
cashMemoDtoForCustomPrintListHeadingV3: any;
cashMemoDtoForCustomPrintListHeadingV4: any;

cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintList: any;
cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
cashMemoDtoForCustomPrintDataList: any;
cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
cashMemoDtoForCustomPrintDataSummaryList: any;

totLrSummary:number;
pageId="sunrc";

    constructor(private stocksUnloadingRpt: ReportService, private modalService: NgbModal,
        private router: Router, private stockReport: StockReport, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
        }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        // var booleanInvoiceCheckBox = this.validateInvoiceNumberCheckBox;
        //var invoice_no = $("#sunrcinvoiceNumber").val();
        this.dtOptionsUnloadingReport = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            }, buttons: [
                
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Stock Unloading Report - " +
                            "From Date : " + moment($("#sunrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#sunrctoDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
            },
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            dtElement.dtInstance.then((dtInstance: any) => {
                dtInstance.destroy();
                /*if (dtInstance.table().node().id == "consolidateMemoRptDetailsTableId") {
                }*/
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerUnloadingReport.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerUnloadingReport.next();
        //this.dtTriggerUnloadingReportDetained.next();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for popup modal starts 
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    stockUnloadingRptPopUpBtnLink(stockUnloadingPopupTemplate, unloadingReportData) {
        this.modalRefferenceStockUnloadingRptPopUp = this.modalService.open(stockUnloadingPopupTemplate,
            { centered: true, windowClass: "modalClassForPopUp" });
        this.modalRefferenceStockUnloadingRptPopUp.result.then((result) => {
            this.modalRefferenceStockUnloadingRptClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStockUnloadingRptClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        if (this.validateInvoiceNumberCheckBox == false) {
            this.setLocalStorageLRNotDetainedData(unloadingReportData);
        } else if (this.validateInvoiceNumberCheckBox == true) {
            this.setLocalStorageLRDetainedData(unloadingReportData);
        }

        /*  this.invoice_Dto = new InvoiceDto();
          this.validateFromDate = null;
          this.validatetoDate = null;
          this.validateCheatCode = null;
  
          if (this.validateInvoiceNumberCheckBox == false) {
              this.invoice_Dto.mode = "notDetain";
          } else if (this.validateInvoiceNumberCheckBox == true) {
              this.invoice_Dto.mode = "detain";
          }
          this.validateFromDate = $("#"+this.pageId+"fromDates").val();
          this.validatetoDate = $("#"+this.pageId+"toDates").val();
          this.validateCheatCode = $("#"+this.pageId+"cheatCodeId").val();
  
          this.invoice_Dto.invoiceNumber = unloadingReportData.invoiceNumber;
          if (this.validateCheckboxEnable == true) {
              if (this.validateCheckBoxEnableAll == true) {
                  this.invoice_Dto.address = "All";
              } else {
                  this.invoice_Dto.address = "Specific";
              }
          } else {
              this.invoice_Dto.address = "Default";
          }
          this.invoice_Dto.commodityCode = this.validateCheatCode;
          this.invoice_Dto.companyId = this.userDataDtoReturnSession.companyId;
          console.log(this.invoice_Dto);
  
          localStorage.clear();
          localStorage.setItem('popupDeliveryUnloadedDetained', JSON.stringify(this.invoice_Dto));
  
          this.viewDeliveryUnldDetainedPopUp = true;
          window.addEventListener('afterPopUp', (event) => {
              this.viewDeliveryUnldDetainedPopUp = false;
          });
  
         */
    }
    setLocalStorageLRDetainedData(unloadingReportData) {
        this.invoice_Dto = new InvoiceDto();
        this.validateFromDate = null;
        this.validatetoDate = null;
        this.validateCheatCode = null;

        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validatetoDate = $("#"+this.pageId+"toDates").val();
        this.validateCheatCode = $("#"+this.pageId+"cheatCodeId").val();

        if (this.validateCheckboxEnable == true) {
            if (this.validateCheckBoxEnableAll == true) {
                this.invoice_Dto.address = "All";
            } else {
                this.invoice_Dto.address = "Specific";
            }
        } else {
            this.invoice_Dto.address = "Default";
        }
        this.invoice_Dto.mode = "detain";
        this.invoice_Dto.invoiceNumber = unloadingReportData.invoiceNumber;
        this.invoice_Dto.commodityCode = this.validateCheatCode;
        this.invoice_Dto.companyId = this.userDataDtoReturnSession.companyId;
        localStorage.clear();
        localStorage.setItem('popupDeliveryUnloadedDetained', JSON.stringify(this.invoice_Dto));
        this.viewDeliveryUnldDetainedPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewDeliveryUnldDetainedPopUp = false;
        });
    }

    setLocalStorageLRNotDetainedData(unloadingReportData) {
        this.invoice_Dto = new InvoiceDto();
        this.validateFromDate = null;
        this.validatetoDate = null;
        this.validateCheatCode = null;

        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validatetoDate = $("#"+this.pageId+"toDates").val();
        this.validateCheatCode = $("#"+this.pageId+"cheatCodeId").val();

        if (this.validateCheckboxEnable == true) {
            if (this.validateCheckBoxEnableAll == true) {
                this.invoice_Dto.address = "All";
            } else {
                this.invoice_Dto.address = "Specific";
            }
        } else {
            this.invoice_Dto.address = "Default";
        }
        this.invoice_Dto.mode = "notDetain";
        this.invoice_Dto.invoiceNumber = unloadingReportData.invoiceNumber;
        this.invoice_Dto.commodityCode = this.validateCheatCode;
        this.invoice_Dto.companyId = this.userDataDtoReturnSession.companyId;
        localStorage.clear();
        localStorage.setItem('popupDeliveryUnloadedNotDetained', JSON.stringify(this.invoice_Dto));
        this.viewDeliveryUnldNotDetainedPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewDeliveryUnldNotDetainedPopUp = false;
        });
    }

    //for popup modal ends 

    enableCheckBoxEvent(values: any) {
        if (values.currentTarget.checked == true) {
            this.hideAndShowEnableField = true;
            this.validateCheckboxEnable = true;
            $("#"+this.pageId+"checkboxEnableAll").prop('checked', true);
            $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', false);
            this.validateCheckBoxEnableAll = true;
        } else {
            this.hideAndShowEnableField = false;
            this.validateCheckboxEnable = false;
            this.validateCheckBoxEnableAll = false;
        }
    }

    enableCheckPostDetainedEvent(values: any) {

        if (values.currentTarget.checked == true) {
            $("#"+this.pageId+"invoiceNumber").val('');
            this.hideAndShowInvoiceNumberField = true;
            this.validateInvoiceNumberCheckBox = true;
            //
            this.viewDeliveryUnldNotDetainedPopUp = false;
            this.viewDeliveryUnldDetainedPopUp = false;
        } else {
            $("#"+this.pageId+"invoiceNumber").val('');
            this.hideAndShowInvoiceNumberField = false;
            this.validateInvoiceNumberCheckBox = false;
            //
            this.viewDeliveryUnldNotDetainedPopUp = false;
            this.viewDeliveryUnldDetainedPopUp = false;
        }
    }


    clearBtn() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.validateFromDate = null;
        this.validatetoDate = null;
        this.validateInvoiceNumber = null;

        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"invoiceNumber").val('');

        $("#"+this.pageId+"checkboxEnable").prop('checked', false);
        $("#"+this.pageId+"checkboxEnableAll").prop('checked', false);
        $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', false);
        $("#"+this.pageId+"checkboxCheckPostDetained").prop('checked', false);

        $("#"+this.pageId+"stockUnloadingTableId").DataTable().destroy();
        this.unloadingReportDataList = [];
        this.dtTriggerUnloadingReport.next();

        this.hideAndShowEnableField = false;
        this.validateCheckboxEnable = false;
        this.hideAndShowInvoiceNumberField = false;
        this.validateInvoiceNumberCheckBox = false;

        this.viewDeliveryUnldNotDetainedPopUp = false;
        this.viewDeliveryUnldDetainedPopUp = false;
    }

    validateSearchBtn() {
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validatetoDate = $("#"+this.pageId+"toDates").val();
        this.validateInvoiceNumber = $("#"+this.pageId+"invoiceNumber").val();

        if (this.validateInvoiceNumberCheckBox == false) {
            if ((this.validateInvoiceNumber == null || this.validateInvoiceNumber == undefined ||
                this.validateInvoiceNumber == "") && (this.validateFromDate == null ||
                    this.validateFromDate == undefined || this.validateFromDate == "" ||
                    this.validatetoDate == null || this.validatetoDate == undefined ||
                    this.validatetoDate == "")) {
                swal("Mandatory Fields",
                    "Either invoice number or from date and to date are mandatory fields",
                    "warning");
                return false;
            } else {
                this.getStockUnloadingReportDataNotDetain();
            }
        } else if (this.validateInvoiceNumberCheckBox == true) {
            if ((this.validateFromDate == null || this.validateFromDate == undefined ||
                this.validateFromDate == "" || this.validatetoDate == null ||
                this.validatetoDate == undefined || this.validatetoDate == "")) {
                swal("Mandatory Fields",
                    "From date and To date are mandatory fields",
                    "warning");
                return false;
            } else {
                this.getStockUnloadingReportDataDetain();
            }
        }
    }

    getStockUnloadingReportDataNotDetain() {
        this.invoiceDto = new InvoiceDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validatetoDate = $("#"+this.pageId+"toDates").val();
        this.validateInvoiceNumber = $("#"+this.pageId+"invoiceNumber").val();
        if (this.validateFromDate != null && this.validateFromDate != undefined &&
            this.validateFromDate != "" && this.validatetoDate != null &&
            this.validatetoDate != undefined && this.validatetoDate != "") {
            this.invoiceDto.fromdate = this.validateFromDate;
            this.invoiceDto.todate = this.validatetoDate;
        } else if ((this.validateFromDate == null || this.validateFromDate == undefined ||
            this.validateFromDate == "" || this.validatetoDate == null ||
            this.validatetoDate == undefined || this.validatetoDate == "") &&
            (this.validateInvoiceNumber != null && this.validateInvoiceNumber != undefined &&
                this.validateInvoiceNumber != "")) {
            this.invoiceDto.invoiceNumber = this.validateInvoiceNumber;
        }

        //if(this.rightsForOffice == "Rights For Office"){
        if (this.userDataDtoReturnSession.office == "binnymills") {
            this.invoiceDto.toStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.invoiceDto.toStation = this.userDataDtoReturnSession.office;
        }
        this.invoiceDto.mode = "notDetain";
        this.invoiceDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.invoiceDto);
        this.showSpinnerForAction = true;
        this.stockReport.getUnloadingReportNotDetain(this.invoiceDto).subscribe(
            (response) => {
                this.unloadingReportDataList = [];
                $("#"+this.pageId+"stockUnloadingTableId").DataTable().destroy();
                if (response.length > 0) {
                    //console.log(response);
                    this.unloadingReportDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "info");
                }
                this.dtTriggerUnloadingReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting unloading report  Details", "error");
            }
        () => console.log('done');
    }

    getStockUnloadingReportDataDetain() {
        this.invoiceDto = new InvoiceDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validatetoDate = $("#"+this.pageId+"toDates").val();
        this.validateInvoiceNumber = $("#"+this.pageId+"invoiceNumber").val();
        if (this.validateFromDate != null && this.validateFromDate != undefined &&
            this.validateFromDate != "" && this.validatetoDate != null &&
            this.validatetoDate != undefined && this.validatetoDate != "") {
            this.invoiceDto.fromdate = this.validateFromDate;
            this.invoiceDto.todate = this.validatetoDate;
        }
        //if(this.rightsForOffice == "Rights For Office"){
        if (this.userDataDtoReturnSession.office == "binnymills") {
            this.invoiceDto.toStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.invoiceDto.toStation = this.userDataDtoReturnSession.office;
        }
        this.invoiceDto.mode = "detain";
        this.invoiceDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        console.log(this.invoiceDto);
        this.stockReport.getUnloadingReportDetain(this.invoiceDto).subscribe(
            (response) => {
                this.unloadingReportDataList = [];
                $("#"+this.pageId+"stockUnloadingTableId").DataTable().destroy();
                if (response.length > 0) {
                    // console.log(response);
                    this.unloadingReportDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "info");
                }
                this.dtTriggerUnloadingReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting  unloading report Details", "error");
            },
            () => console.log('done');
    }

    enableCheckBoxAllEvent(values: any) {
        if (values.currentTarget.checked == true) {
            this.validateCheckBoxEnableAll = true;
            this.validateCheckBoxEnableSpecific = false;
            $("#"+this.pageId+"checkboxEnableAll").prop('checked', true);
            $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', false);
        } else {
            this.validateCheckBoxEnableAll = false;
            this.validateCheckBoxEnableSpecific = false;
            $("#"+this.pageId+"checkboxEnableAll").prop('checked', false);
            $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', false);
        }
    }

    enableCheckBoxSpecificEvent(values: any) {
        if (values.currentTarget.checked == true) {
            this.validateCheckBoxEnableAll = false;
            this.validateCheckBoxEnableSpecific = true;
            $("#"+this.pageId+"checkboxEnableAll").prop('checked', false);
            $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', true);
        } else {
            this.validateCheckBoxEnableAll = false;
            this.validateCheckBoxEnableSpecific = false;
            $("#"+this.pageId+"checkboxEnableAll").prop('checked', false);
            $("#"+this.pageId+"checkboxEnableSpecific").prop('checked', false);
        }
    }
    customPrintStockunloadRpt()
    {
        if (this.unloadingReportDataList.length==0) {
            swal({
        
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }else{
        
                localStorage.clear();
                this.cashMemoDtoForCustomPrintList = [];
                this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice Number", "Invoice Date","Total Lrs", "Source", "Destination","Vehicle Number"];
                this.cashMemoDtoForCustomPrintListColumnWidths = [25,15,15,15,15,15];
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                    this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                    this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
                }
                this.cashMemoDtoForCustomPrintDataList = [];
                this.cashMemoDtoForCustomPrintDataSummaryList = [];
           
				
                this.totLrSummary=0
                for (let i = 0; i < this.unloadingReportDataList.length; i++) {
                    
                    this.cashMemoDtoForCustomPrintListColumnValues = [this.unloadingReportDataList[i].invoiceNumber, this.unloadingReportDataList[i].invDate,this.unloadingReportDataList[i].totalLRs,this.unloadingReportDataList[i].fromStation,
                    this.unloadingReportDataList[i].toStation,this.unloadingReportDataList[i].vehicleNumber,];
                    /////////
                    
                    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                    this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                    this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        
							this.totLrSummary=this.totLrSummary+this.unloadingReportDataList[i].totalLRs;
                            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.unloadingReportDataList.length,"",this.totLrSummary,"","",""];
       
					
                }
               
                
                //heading logics For Date
               
               
              //  this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
                this.cashMemoDtoForCustomPrintListHeadingV1 = [];
                this.cashMemoDtoForCustomPrintListHeadingV2 = [];
                this.cashMemoDtoForCustomPrintListHeadingV3 = [];
                this.cashMemoDtoForCustomPrintListHeadingV4 = [];
               
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"] ;
    
               
                
            
				 
         this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.invoiceDto.fromdate, this.invoiceDto.todate];

               
            
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
                }
               
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
                }
        
               
        
                localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
                localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
                localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
               
               
                localStorage.setItem('printTitle', "Stocks Unloading Report");
                this.viewCustomPrintV1 = true;
                
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewCustomPrintV1) {
                        this.viewCustomPrintV1 = false;
                        localStorage.clear();
                    }
                });
        
            
            
        }
}
}
