<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>

    <div>
        <!-- Row -->
        <div class="row" *ngIf="isLoggedIn" id="{{pageId}}agentLocalTrpPopupId">
            <div class="col-lg-12">
                <div class="card " style="border: 1px solid darkcyan !important;">
                    <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                        <h6 class="card-title text-white">Agent Local Trip Report</h6>
                    </div>
                    <div class="row">
                        <div class="column" style="width: 18%; padding-left: 17px;">
                            <p>
                                Trip Date : <label class="lable">{{
									lrdtoForAgentLocalTripLocalStorage.tripDateStr }}</label>
                            </p>
                        </div>
                        <div class="column" style="width: 25%">
                            <p>
                                Vehicle Number : <label class="lable">{{lrdtoForAgentLocalTripLocalStorage.vehicleNumber}}</label>
                            </p>

                        </div>
                        <div class="column" style="width: 35%">
                            <p>
                                Trip No : <label class="lable">{{
									lrdtoForAgentLocalTripLocalStorage.localTripNumber }}</label>
                            </p>
                        </div>
                        <div class="column" style="width: 22%">
                            <p>
                                Delivery Area : <label class="lable">{{
									lrdtoForAgentLocalTripLocalStorage.deliveryarea }}</label>
                            </p>

                        </div>
                    </div>

                </div>
                <br />
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->

                        <!--                         <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintAgentLocalTrpPopup()" id="{{pageId}}printAllBtn"> -->
                        <!-- 							<span><i class="fas fa-print">Print</i></span> -->
                        <!-- 						</button> -->
                        <div class="box-body">
                            <table datatable id="{{pageId}}agentLocalTripPopupTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentLocalTripPopupDetails" [dtTrigger]="dtTriggerAgentLocalTripPopupDetails">

                                <thead>
                                    <tr>
                                        <th>Booking Date</th>
                                        <th>LR Number</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Total Articles</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let agentLocalTripPopupList of agentLocalTripDataPopupList let i = index ">
                                        <td>{{agentLocalTripPopupList.bookingDateStr }}</td>
                                        <td>{{ agentLocalTripPopupList.lrNumber }}</td>
                                        <td>{{agentLocalTripPopupList.source }}</td>
                                        <td>{{ agentLocalTripPopupList.destination }}</td>
                                        <td>{{agentLocalTripPopupList.totalArticles }}</td>
                                        <td>{{ agentLocalTripPopupList.actualWeight }}</td>
                                        <td>{{agentLocalTripPopupList.chargedWeight }}</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>