import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component( {
    selector: 'app-block-lr-details',
    templateUrl: './block-lr-details.component.html',
    styleUrls: ['./block-lr-details.component.css']
} )
export class BlockLrDetailsComponent implements  OnInit {
    getDataBlockLrDetailsDataTable: any;

    blockLrDetailsDataList: any;

    onDestroyUnsubscribtionBlockLrDetails: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBlockLrDetails: Subject<any> = new Subject();

    dataTable: any;
	lrDtoForDetails:LRDto =  new LRDto();
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	lrDtoForBlockLrs:LRDto =  new LRDto();
	selectedRowReason:any;
    dtOptionsBlockLrDetails: any;
    reasonValue:any;
    showSpinnerForAction=false;
    pageId="bllrc";
    constructor( private dashboardService: DashboardService ,private route: ActivatedRoute,
        private router: Router, private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
    	  if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          }
      
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
   
	 ngOnInit(): void {

        this.dtOptionsBlockLrDetails = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
          
           
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerBlockLrDetails.unsubscribe();
      
    // }
    ngAfterViewInit(): void {
        this.dtTriggerBlockLrDetails.next();

    }
    
    gridReconifgureOnReloadBtn(){
		
		this.lrDtoForBlockLrs=   new LRDto();
		this.lrDtoForBlockLrs.destination=this.userDataDtoReturnSession.mainStation;
		this.lrDtoForBlockLrs.companyId=this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForBlockLrs);
		this.gridReconifgureDetails();
}
	gridReconifgureDetails= () => {
	         this.showSpinnerForAction = true;
	         this.dashboardService.getBlockLrDetails(this.lrDtoForBlockLrs).subscribe(
	             (response) => {
	            	 this.showSpinnerForAction=false;
					  //$("#"+this.pageId+"blockLrDetailsId").DataTable().destroy();
						this.blockLrDetailsDataList=[];
	                 if (response.length == 0) {
	                     swal({
	                         title: "Warning",
	                         text: "No Details found !",
	                         icon: "warning",
	                         closeOnClickOutside: false,
	                         closeOnEsc: false,
	                     });
	                     
	                 } else {
	               	  this.blockLrDetailsDataList=response;
	               	  console.log(this.blockLrDetailsDataList);
	                }
	            //   this.dtTriggerBlockLrDetails.next();
                  this.changeDetectorRef.detectChanges();
	             }), (error) => {
                     this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred While getting the Blocked Lr  Details", "info");
                }, () => console.log('done');
        };
        

        getReason(blockLrDetailsData) {
      	        this.selectedRowReason = '';
      	        this.selectedRowReason = blockLrDetailsData.reason;
      			console.log( this.selectedRowReason);
      	        if (this.selectedRowReason != null && this.selectedRowReason != '') {
      				this.reasonValue=this.selectedRowReason;
      				swal({
  						title: "Message",
  						text: ""+blockLrDetailsData.reason+"",
  						icon: "warning",
  						closeOnClickOutside: false,
  						closeOnEsc: false,
  					});
      				
      	        }else{
      					swal({
      						title: "Warning",
      						text: "No Reason Available To Show !",
      						icon: "warning",
      						closeOnClickOutside: false,
      						closeOnEsc: false,
      					});
      			}
      	    }
      

}

