//ASR_V1_CHG - 25/11/2021 - For single truck search
//Imran_Chg_V1 - 05032024- For pan validation
//Asrar_Jr_Chg_V1 : 08/05/2024 - For added to hide print in UI after click on cancel button
//Asrar_Jr_Chg_V2 : 09/05/2024 - As per instruction by Mr Musaddik bhai, we need printed details of COD (Cheque on delivery) LRs on hire slips as we previously showed short / extra details.
//Imran_SG_Chg_V2 : 25/06/2024 - Blocking invoice generation if the new party lrs are exists in remaining lrs//And removed timer for grid issue.
//Imran_SG_Chg_V3 : 04/07/2024 - Hiding hireamount details for both destination also if the the disablehireinv boolean is true from godwon table
//Imran_SG_Chg_V4 : 09/08/2024 - Blocking removing lrs for new party for both new and old invoice from dashbord (Added By Imran)
//Imran_SG_Chg_V5 : 12/08/2024 - Removed Ftstatus upfate after generating hireslip it should be updated only from dashboard
//Asrar_Jr_Chg_V6 : 23/09/2024 - Add prefix code for lrNumber
//Asrar_Jr_Chg_V7 : 25/09/2024 - Order by LR Number on showing Invoice print if "Bakoli" as per discuss
//Asrar_Jr_Chg_V8 : 27/09/2024 - Block to save Empty EwayBill Number
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbTypeaheadSelectItemEvent,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { StockService } from "src/app/dataService/stock-service";
import { LRDto } from "src/app/dto/LR-dto";
import { Router } from "@angular/router";
import swal from "sweetalert";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { DriverdetailsDto } from "src/app/dto/Driverdetails-dto";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { HamaliDetailReportComponent } from "src/app/trip-creation/hamali-detail-report/hamali-detail-report.component";
import * as moment from "moment";
import { MasterService } from "src/app/dataService/master-service";
import { MultiplePointDetailsComponent } from "src/app/trip-creation/multiple-point-details/multiple-point-details.component";
import { MultipleInvoiceLastPointDetailsComponent } from "src/app/trip-creation/multipleinvoice-lastpoint-details/multipleinvoice-lastpoint-details.component";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LrService } from "src/app/dataService/lr-service";
import { RequestDto } from "src/app/dto/Request-dto";
import { EwaybillService } from "src/app/dataService/ewaybill-service";
@Component({
  selector: "app-stocks-grouping",
  templateUrl: "./stocks-grouping.component.html",
  styleUrls: ["./stocks-grouping.component.css"],
})
export class StocksGroupingComponent implements OnInit {
  isLinear = false;
  @ViewChild("stepper") stepper: MatStepper;

  //login details
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  showSpinnerForAction = false;
  showSpinnerActionForMakeATrip = false;
  showSpinnerActionForInvoice = false;
  showSpinnerActionForHireslip = false;
  pageId = "sgc";
  fromStationLocation = "";
  toStationLocation = "";

  /*for datatable start */
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dataTable: any;
  /* 1 st level stock grouping start */
  dtOptionsStockSummary: any;
  dtOptionsStockDetails: any;

  dtTriggerStockSummary: Subject<any> = new Subject();
  dtTriggerStockDetails: Subject<any> = new Subject();

  stockSummaryDataList: any;
  stockDetailsDataList: any;

  onDestroyUnsubscribtionStockSummary: Subscription;
  onDestroyUnsubscribtionStockDetails: Subscription;
  /* 1 st level stock grouping end */

  /* 3 rd level make a trip start */
  eligibleLrsDataList: any;
  remainingLrsDataList: any;

  dtTriggerEligibleLrs: Subject<any> = new Subject();
  dtTriggerRemainingLrs: Subject<any> = new Subject();

  dtOptionsEligibleLrs: any;
  dtOptionRemainingLrs: any;

  onDestroyUnsubscribtionEligibleLrs: Subscription;
  onDestroyUnsubscribtionRemainingLrs: Subscription;
  /* 3 rd level make a trip end */

  /* 4 th level make a trip start */

  invoiceDataList: any;

  dtTriggerInvoice: Subject<any> = new Subject();

  dtOptionsInvoice: any;

  onDestroyUnsubscribtionInvoice: Subscription;

  /* 4 th level make a trip end */

  /* 5 th level Hireslip start */
  goodsShortAndExtraDetailsDataList: any;
  gridTosetInvoiceNumberList: Array<any> = [];

  dtTriggerGoodsShortAndExtraDetails: Subject<any> = new Subject();
  dtTriggerHireslipDetails: Subject<any> = new Subject();

  dtOptionsGoodsShortAndExtraDetails: any;
  dtOptionsHireslipDetails: any;

  onDestroyUnsubscribtionGoodsShortAndExtraDetails: Subscription;

  /* 5 th level Hireslip end */

  /*for datatable end */

  /* 1 st level stock grouping start */

  viewDeliveryManager = false;
  ewayBillAlert = false;
  viewEwayBillStatus = false;
  makeATripBtn: string;
  tripSheetBtn: string;
  makeAtripBtnHideShow = false;
  tripSheetBtnHideShow = false;
  nextTomakeAtripBtnHideShow = false;
  closeResult: string;

  lrDtoStockSummary: LRDto = new LRDto();
  lrDtoStocksDetails: LRDto = new LRDto();
  hireSlipDtoForInvoiceBlocking: HireSlipDto = new HireSlipDto();
  lrDtoDest: LRDto = new LRDto();
  selectedLrNumber: any;
  lrDtoLoadingSheetPrint: LRDto = new LRDto();

  /* Drop Down Field start  */
  tripToDestOptions: LRDto[];

  public modelTripToDestType: any;
  tripToDestTypeTA: Array<LRDto> = [];
  focusTripToDestTypeTA$ = new Subject<string>();
  searchTripToDestType = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusTripToDestTypeTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.tripToDestTypeTA
          : this.tripToDestTypeTA.filter(
            (v) =>
              v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterTripToDestType = (x: { destination: string }) => x.destination;
  /* Drop Down Field end  */

  /*InvoiceDetailsPage for localstorge dto*/
  lrDtoForStockGroupingLocalStorage: LRDto = new LRDto();

  /* Rights start */
  rightsToShowDatatableOfArticleGroupingColumn =
    "Rights For ArticleGrouping Show Datatable Column";
  rightsToShowMakeaTripButton = "Show make a trip option";
  rightsToShowTripSheetButton = "Show trip sheet option";
  rigthsForMakeTripBtnGenerateInvoice =
    "selected destination to generate invoice";
  /* Rights end */

  modalRefferenceOpenLocalTripWidgetPopUp: NgbModalRef;
  modalRefferenceOpenLocalTripWidgetClosePopUp: string;
  @ViewChild("openLocalTripPopUpWidget") private openLocalTripPopUpWidget;

  /* 1 st level stock grouping end */

  /* 2 nd level Invoice Details start */

  /* 2nd level Drop Down Field start  */
  loaderHeadHireslipDto: HireSlipDto = new HireSlipDto();
  loaderHeadOptions: HireSlipDto[];
  public modelLoaderHead: any;
  loaderHeadTA: Array<HireSlipDto> = [];
  focusLoaderHeadTA$ = new Subject<string>();
  searchLoaderHead = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusLoaderHeadTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.loaderHeadTA
          : this.loaderHeadTA.filter(
            (v) => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterLoaderHead = (x: { loaderHead: string }) => x.loaderHead;

  staffDetailsLrDto: LRDto = new LRDto();
  loaderStaffOption: LRDto[];
  public modelLoaderStaffName: any;
  loaderStaffNameTA: Array<LRDto> = [];
  focusLoaderStaffNameTA$ = new Subject<string>();
  searchLoaderStaffName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusLoaderStaffNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.loaderStaffNameTA
          : this.loaderStaffNameTA.filter(
            (v) => v.staffName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterLoaderStaffName = (x: { staffName: string }) => x.staffName;

  loaderInchargeLRDto: LRDto = new LRDto();
  loaderInchargeOptions: LRDto[];
  public modelLoaderIncharge: any;
  loaderInchargeTA: Array<LRDto> = [];
  focusLoaderInchargeTA$ = new Subject<string>();
  searchLoaderIncharge = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusLoaderInchargeTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.loaderInchargeTA
          : this.loaderInchargeTA.filter(
            (v) =>
              v.inchargeName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterLoaderIncharge = (x: { inchargeName: string }) => x.inchargeName;

  truckDataDtoForTruckNo: TruckDataDto = new TruckDataDto();
  truckNumberOptions: TruckDataDto[];
  public modelTruckNumber: any;
  truckNumberTA: Array<TruckDataDto> = [];
  focusTruckNumber$ = new Subject<string>();
  searchTruckNumber = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusTruckNumber$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.truckNumberTA
          : this.truckNumberTA.filter(
            (v) =>
              v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterTruckNumber = (x: { truckNumber: string }) => x.truckNumber;

  supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
  supplierOptions: SupplierDetailsDto[];
  public modelSupplierName: any;
  supplierNameTA: Array<SupplierDetailsDto> = [];
  focusSupplierNameTA$ = new Subject<string>();
  searchSupplierName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusSupplierNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.supplierNameTA
          : this.supplierNameTA.filter(
            (v) =>
              v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

  driverDetailsDto: DriverdetailsDto = new DriverdetailsDto();
  driverNameOptions: DriverdetailsDto[];
  public modelDriverName: any;
  driverNameTA: Array<DriverdetailsDto> = [];
  focusDriverNameTA$ = new Subject<string>();
  searchDriverName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDriverNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.driverNameTA
          : this.driverNameTA.filter(
            (v) => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };

  formatterDriverName = (x: { driverName: string }) => x.driverName;

  truckDataTPCPEntryDto: TruckDataDto = new TruckDataDto();
  entryCpOptions: TruckDataDto[];
  public modelEntryCP: any;
  entryCPTA: Array<TruckDataDto> = [];
  focusEntryCPTA$ = new Subject<string>();
  searchEntryCP = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusEntryCPTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.entryCPTA
          : this.entryCPTA.filter(
            (v) => v.cpName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterEntryCP = (x: { cpName: string }) => x.cpName;

  truckDataTPCPExitDto: TruckDataDto = new TruckDataDto();
  exitCpOptions: TruckDataDto[];
  public modelExitCP: any;
  exitCPTA: Array<TruckDataDto> = [];
  focusExitCPTA$ = new Subject<string>();
  searchExitCP = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusExitCPTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.exitCPTA
          : this.exitCPTA.filter(
            (v) => v.cpName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterExitCP = (x: { cpName: string }) => x.cpName;

  /* 2nd level Drop Down Field end  */

  /* Hash Map Of Invoice Details start */
  hashmapLoaderHead: Map<String, Number> = new Map<String, Number>();
  hashmapLoader: Map<String, Number> = new Map<String, Number>();
  hashmapTruckNumberIndex: Map<String, Number> = new Map<String, Number>();
  hashmapTruck: Map<String, TruckDataDto> = new Map<String, TruckDataDto>();
  hashMapSupplierIndex: Map<String, Number> = new Map<String, Number>();
  hashMapSupplier: Map<String, SupplierDetailsDto> = new Map<
    String,
    SupplierDetailsDto
  >();
  hashMapDriver: Map<String, DriverdetailsDto> = new Map<
    String,
    DriverdetailsDto
  >();
  hashMapDriverIndex: Map<Number, String> = new Map<Number, String>();
  hashMapDriverIndexNew: Map<Number, Number> = new Map<Number, Number>();
  hashMapEntryCPIndex: Map<String, Number> = new Map<String, Number>();
  hashMapExitCPIndex: Map<String, Number> = new Map<String, Number>();
  /* Hash Map Of Invoice Details end */

  /* entered validate start*/
  userValueTruckDataDto: TruckDataDto = new TruckDataDto();
  userValueTruckDto: TruckDataDto = new TruckDataDto();
  userValueDriverdetailsDto: DriverdetailsDto = new DriverdetailsDto();
  userValueSupplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();

  selectedTruckNumber: any;
  selectedDriverName: any;
  selectedSupplierName: any;
  selectedEntryCp: any;
  selectedExitCp: any;
  selectedEntryCpCode: any;
  selectedExitCpCode: any;
  selectedLoaderHead: any;
  selectedLoaderName: any;
  selectedLoaderIncharge: any;
  selectedLicNo: any;
  selectedRemarks: any;
  selectedDriverId: any;
  selectedExpExitDate: any;
  modelExpExitDate: any;
  selectedExpExitDays: any;
  driverNameForLicNoAutoFetch: any;

  selectedBayNumber: any;
  selectedBayLoadingDate: any;
  selectedBayLoadingTime: any;
  bayLoadingDateModel: any;

  onSelectEntryCpCode: any;
  onSelectExitCpCode: any;
  onSelectEntryDriverId: any;
  /* entered validate end*/

  nextToInvoiceDetailOfDarkBtnHideShow = false;
  nextToInvoiceDetailOfGreenBtnHideShow = false;

  /*MakeATripPage for localstorge dto*/
  lrDtoForInvoiceDetailLocalStorage: LRDto = new LRDto();

  pendingHireslipFromLocalStorage: any;
  showSpinnerForActionForInvoiceDetails: boolean = false;

  /* 2 nd level Invoice Details end */

  /* 3 rd level Make a Trip Details end */

  /* Drop Down Field */

  truckDataDtoForTruckCapacity: TruckDataDto = new TruckDataDto();
  truckCapacityOptions: TruckDataDto[];
  public modelTruckCapacity: any;
  truckCapacityTA: Array<TruckDataDto> = [];
  focusTruckCapacityTA$ = new Subject<string>();
  searchTruckCapacity = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusTruckCapacityTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.truckCapacityTA
          : this.truckCapacityTA.filter((v) => v.capacity)
        ).slice(0, 200)
      )
    );
  };
  formatterTruckCapacity = (x: { capacity: string }) => x.capacity;

  lrDtoBins: LRDto = new LRDto();
  lrdDtoBinSpecific: LRDto = new LRDto();
  lrdDtoBinSpecificAll: LRDto = new LRDto();
  binsOptions: LRDto[];
  public modelControlBins: any;
  controlBinTA: Array<LRDto> = [];
  focusControlBinsTA$ = new Subject<string>();
  searchControlBins = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusControlBinsTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.controlBinTA
          : this.controlBinTA.filter(
            (v) =>
              v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterControlBins = (x: { subStations: string }) => x.subStations;

  /* Drop Down Field */

  /* Rights start */

  // rightsToShowSpecificBinsDataForBkg = "MakeATrip Show Bins Data For Bkg";
  //rightsToShowBinsDataForTrx = "MakeATrip Show Bins Data For Trx";

  /* Rights end */

  hideshowControlsBins = false;
  hideshowTruckCapacity = false;
  truckCapcityLoadValue: any;
  controlBinsDestValue: any;
  stationType: any;
  lrNumber: any;
  resultList: any;
  enteredLrNumber: any;
  hashmapBinGetDest: any;
  firstFormGroup = "";
  secondFormGroup = "";
  thirdFormGroup = "";
  fourthFormGroup = "";
  fifthFormGroup = "";
  //hash
  hashmapBins: Map<String, String> = new Map<String, String>();

  lrDtoCallService: LRDto = new LRDto();
  lrDtoControlBinService: LRDto = new LRDto();
  lrDtoForCheckDestination: LRDto = new LRDto();
  lrDtoInvoiceLocalStorage: LRDto = new LRDto();

  selectedControBinsDataOfRemaingLrsList: any;
  selectedControBinsDataOfAllLrsList: any;

  viewNextDarkBtnOfInvoice = false;
  viewNextBtnOfInvoice = false;

  /* 3 rd level Make a Trip Details end */

  /* 4 th level Invoice end */

  lrDtoForInvoiceLoadingPrint: LRDto = new LRDto();
  hireSlipDtoForHireslip: HireSlipDto = new HireSlipDto();
  invoiceDtoForLoadingSheetAndHireslip: InvoiceDto = new InvoiceDto();
  summaryInvoiceForLrDtoPrint: LRDto = new LRDto();
  hireSlipDtoForCreateHireslipLocalStorage: HireSlipDto = new HireSlipDto();

  listOfLrDto: LRDto[] = [];

  listOfTotalLrs: Array<String> = new Array();
  listOfLrs: Array<String> = new Array();
  listOfLrShortage: Array<String> = new Array();
  checkInvoiceListLrs: Array<String> = new Array();

  bookingInvoiceListOfTotalLrs: Array<String> = new Array();
  bookingInvoiceListOfLrs: Array<String> = new Array();
  bookingInvoiceListOfLrShortage: Array<String> = new Array();
  bookingInvoiceForTotalArticle: any = 0;
  bookingInvoiceForHireslipDto: HireSlipDto = new HireSlipDto();
  bookingInvoiceForLrDto: LRDto = new LRDto();

  totalArticles: number;
  guranteeWeight: number = 0;
  totToPay: number;
  totPaid: number;

  viewInvoiceLoadingSheetPrint = false;
  viewInvoiceDetailsPrint = false;
  viewInvoiceSummaryPrint = false;

  //rightsForLoadingSheetBranch ="rights for loading sheet print branch";
  rightsForLoadingSheetOffice = "rights for loading sheet print office";
  // rightsForCreateHireslipBranch ="rights for create hireslip branch";
  rightsForCreateHireslipOffice = "rights for create hireslip office";
  rightsForOfficeTypeHideDummyLrPrint =
    "rights for officetype hide dummy lr print";
  rightsForofficeTypeBookingInvoiceDetailsPrint =
    "rights for officetype booking invoice details print";

  viewCreateHireslipDarkBtn = false;
  viewCreateHireslipGreenBtn = false;

  set: Set<String> = new Set<String>();

  //isHamaliSelect = false;
  //sourceHamaliEntered: any;
  //hamaliTypeEntered: any;
  //viewHamaliDetailPopup = false;

  ledgerHamaliListPopupReturn: any;
  hamaliSourceEntered: any;
  multipleSrcEntered: any;
  multipleHamaliEntered: any;

  viewCheckBoxInvoiceDummyLr = false;
  /* 4 th level Invoice end */

  /* 5 th level Hireslip Form start */

  //for popup modal starts
  closeResultContentNotAssigned: string;
  closeResultContentUpdateDriverMobileNo: string;

  modalRefferenceNewDriverMobileNo: NgbModalRef;
  modalRefferenceTruckMasterPopUp: NgbModalRef;
  modalRefferenceTruckMasterClosePopUp: string;

  modalRefferenceTruckOwnerPopUp: NgbModalRef;
  modalRefferenceTruckOwnerClosePopUp: string;
  @ViewChild("uploadTruckOwnerPopUp") private uploadTruckOwnerPopUp;

  modalRefferenceTruckDetailOfRCInsLicPopUp: NgbModalRef;
  modalRefferenceTruckDetailOfRCInsLicClosePopUp: string;
  @ViewChild("uploadRCInsLicForTruck") private uploadRCInsLicForTruck;

  //rights
  rightsForInvoiceTypeAndMainInvocie =
    "rights to show invoice type and main invoice for main and dummy";
  rightsForSMSHireslip =
    "rights for the sms binnymillsBinnymills and Konditope";

  /* Drop Down Start */

  truckTypeOptions: TruckDataDto[];
  hmTruckType: Map<Number, Number> = new Map<Number, Number>();
  hmTruckTypeDto: Map<Number, TruckDataDto> = new Map<Number, TruckDataDto>();

  truckTypeDataDto: TruckDataDto = new TruckDataDto();
  public modelTruckType: any;
  truckTypeTA: Array<TruckDataDto> = [];
  focusTruckTypeTA$ = new Subject<string>();
  searchTruckType = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusTruckTypeTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.truckTypeTA
          : this.truckTypeTA.filter(
            (v) => v.truckType.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterTruckType = (x: { truckType: string }) => x.truckType;

  /* lrDtoForMultipleInvoice: LRDto = new LRDto();
     lrDtoListMultipleInvOptions: LRDto[];
     public modelMultiInvoiceDest: any;
     multipleInvoiceStationTA: Array<LRDto> = [];
     focusMultiInvoiceDestTA$ = new Subject<string>();
     searchMultiInvoiceDest = (text$: Observable<string>) => {
         const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
         const inputFocus$ = this.focusMultiInvoiceDestTA$;
 
         return merge(debouncedText$, inputFocus$).pipe(
             map(term => (term === '' ? this.multipleInvoiceStationTA
                 : this.multipleInvoiceStationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
         );
     }
     formatterMultiInvoiceDest = (x: { destination: string }) => x.destination;*/

  /* Drop Down end */

  invoiceType: string;
  printMode: string;
  modeMain: string;

  isMainInvoice: boolean = false;
  isBookingStation: boolean = false;

  departureButtonHideShow = false;
  saveAndPrintButtonHideShow = false;
  hideShowIsLastPointDelivery = false;
  hideShowTwoPointDoorDelivery = false;
  hideAndShowInputFieldsForSelectedDestination = true;
  hideAndShowInputFieldsDeliveryPoint = true;
  //hideAndShowInputFields = true;
  //hideAndShowInputFieldsOne = true;

  hideAndShowPrintInvoiceBtn = false;
  hideAndShowPrintHireslipBtn = false;
  hideAndShowInvSummPrintBtn = false;

  validateDeliveryPtSingleChecked: any = false;
  validateDeliveryPtMultipleChecked: any = false;
  validateIslastPointDeliveryIdChecked: any = false;

  isFTTripReq: boolean = true;

  //print
  viewHireslipDetailsPrint: boolean = false;

  isMatched: boolean = false;

  /* Hash Map */
  hashmapEwayBill: Map<String, Number> = new Map<String, Number>();

  /*Single Dto */

  truckDataDtoLocalStorageTruckMaster: TruckDataDto = new TruckDataDto();

  hireSlipDtoForShortAndExtra: HireSlipDto = new HireSlipDto();
  hireSlipDtoGoodsTypes: HireSlipDto = new HireSlipDto();

  /* save & depart Single dto */
  hireSlipDtoUserValue: HireSlipDto = new HireSlipDto();
  hireSlipDtoSaveHireslip: HireSlipDto = new HireSlipDto();
  chkStatusHireSlipDto: HireSlipDto = new HireSlipDto();
  truckDataDtoValidateTruckType: TruckDataDto = new TruckDataDto();
  rcLicInscTruckDataDto: TruckDataDto = new TruckDataDto();
  rcLicInscTruckDataDtoReturn: any;
  truckDataDtoDepartRCLicPan: TruckDataDto = new TruckDataDto();
  truckDataDtoCheckPanIns: TruckDataDto = new TruckDataDto();
  hireSlipDtoForPrintInvoiceAlone: HireSlipDto = new HireSlipDto();

  //depart
  hireSlipDtoUserValueForDepartureMethod: HireSlipDto = new HireSlipDto();
  truckDataDtoForDepartureMethod: TruckDataDto = new TruckDataDto();
  hireSlipDtoUserValueTripDeparture: HireSlipDto = new HireSlipDto();
  lrDtoForSMS: LRDto = new LRDto();
  hireSlipDtoForInvoiceDetails: HireSlipDto = new HireSlipDto();
  hireSlipDtoBarCode1: HireSlipDto = new HireSlipDto();
  lrDtoForLrDetailsMhdPrint: LRDto = new LRDto();
  hireSlipDtoForLrDetailsMhd: HireSlipDto = new HireSlipDto();
  hireSlipDtoForPrintHireslip: HireSlipDto = new HireSlipDto();
  hireslipDtoForPrintHireslipReturn: any;
  lrDtoForPrintHireslipReturn: any;

  hireSlipDtoUserValueForPrintInvoiceBtn: HireSlipDto = new HireSlipDto();
  lrsPrintInvoiceBtn = new Array<String>();
  lrDtoPrintInvoiceBtn: LRDto = new LRDto();
  hireSlipDtoForBookingInvoicePrintMhd: HireSlipDto = new HireSlipDto();
  validateKantaWgtBookingInvoice: any;

  hireSlipDtoUserValueForPrintHireslipBtn: HireSlipDto = new HireSlipDto();
  hireSlipDtoBarCode: HireSlipDto = new HireSlipDto();
  hireSlipDtoForTimeDuratioMethod: HireSlipDto = new HireSlipDto();
  lrDtoForTimeDuratioReturn: any;
  hireslipDtoForTimeDuratioReturn: any;

  /*listDto */

  financialYearOnloadValidate: any;
  firstYearStgOnloadValidate: any;
  secondYearStgOnloadValidate: any;

  //pop up
  localStorageLorryTruckNo: any;

  invoiceNumberSetOnLoad: any;
  hireslipNumberSetOnLoad: any;
  invoiceDateSetOnLoad: any;
  sourceSetOnLoad: any;
  destinationSetOnLoad: any;
  numberOfLrsSetOnLoad: any;
  numberOfArticlesSetOnLoad: any;
  guaranteeWgtSetOnLoad: any;
  gurWeightTotalSetOnload: any;
  kantaWeightSetOnLoad: any;
  loadedBySetOnLoad: any;
  //Vehicle details
  lorryNumberSetOnLoad: any;
  lorryOwnerSetOnLoad: any;
  truckLengthSetOnLoad: any;
  contactPersonSetOnLoad: any;
  addressSetOnLoad: any;
  truckDriverNameSetOnLoad: any;
  driverLicNoSetOnLoad: any;
  driverMobileNoSetOnLoad: any;
  supplierNameTextSetOnLoad: any;
  panNoSetOnLoad: any;
  insuranceNoSetOnLoad: any;
  noOfEWBInConsolidatedSystemSetOnLoad: any;
  noOfEWBInConsolidatedManualSetOnLoad: any;
  consolidatedEwayBillSetOnLoad: any;
  confirmConsolidatedEwayBillSetOnLoad: any;
  //Billing details
  totalHireAmountSetOnLoad: number;
  advanceAmountSetOnLoad: number;
  balanceAmountSetOnLoad: number;
  touchingHireAmountSetOnLoad: number;
  payableAtSetOnLoad: any;
  payableAtDupSetOnLoad: any;
  remarksSetOnLoad: any;
  deliveryPointsSetOnLoad: any;
  baseHireAmtSetOnLoad: any;
  doorOpenAmtSetOnLoad: any;
  vehicleHeightAmtSetOnLoad: any;
  doorDeliveryAmtSetOnLoad: any;
  extraWeightAmtSetOnLoad: any;
  checkPostAmtSetOnLoad: any;
  otherChargeAmtSetOnLoad: any;

  shortageLrs: string;
  spiltLrs: any[];
  lrShrotageSpilt: any;
  //hireSlipDto : HireSlipDto = new HireSlipDto();
  twoPointDDCheckBox: any;
  twoPointsDoorDeliveryMultipleLrs: any;
  ewayBillNo: any;
  ewayBillNoConfirm: any;

  validateDepartureDate: any;
  validateScheduledDate: any;
  validateDepartureTime: any;
  validateScheduledTime: any;
  departureDate: any;
  scheduledDate: any;
  scheduledTime: any;
  departureTime: any;
  arrivalTime: any;
  currentDate: any;

  hireAmt: any;
  validateHireAmtForBaseHireAmt: any;
  validateHireAmtForDoorOpen: any;
  validateHireAmtForVehicleHeight: any;
  validateHireAmtForDoorDely: any;
  validateHireAmtForExtraWeight: any;
  validateHireAmtForCheckPost: any;
  validateHireAmtForotherChrg: any;

  isValid: boolean = false;
  truckDto: TruckDataDto = new TruckDataDto();
  totalGurWgt: number;
  calculateAalanceAmt: any = 0;
  validateTotalHireAmt: any = 0;
  validateAdvanceAmt: any = 0;
  truckTypeId: any;
  validateTruckTypeTotGurWgt: any = 0;
  goodsType: string;
  gurWgt: number;
  kantaWgt: number;
  suppName: string;
  listOfShortageLrs = new Array<String>();

  ewayLrs: any;
  countEwayLrs: any;
  countEwayLrsAbove50K: any;
  countEwayLrsBelow50K: any;
  ewayBillLrForLRDto: LRDto = new LRDto();
  returnEwayBillLrsLRDto: Array<LRDto> = [];
  goodsValueFind: any;
  goodsValueFind1: any;
  ewayBill: any;

  /*isReadOnlyInvoiceNo: boolean;
    isReadOnlyInvoiceDates: boolean;
    isReadOnlyHireslipNo: boolean;
    isReadOnlySource: boolean;
    isReadOnlyDestination: boolean;
    isReadOnlyNumberOfLrs: boolean;
    isReadOnlyTotalNoArticle: boolean;
    isReadOnlyLorryNo: boolean;
    isReadOnlyLorryOwner: boolean;
    isReadOnlyTruckLength: boolean;
    isReadOnlyLorryContactPerson: boolean;
    isReadOnlyOwnerAddress: boolean;
    isReadOnlyDriverName: boolean;
    isReadOnlyDriverLicNo: boolean;
    isReadOnlySupplierName: boolean;
    isReadOnlyPanNo: boolean;*/

  invoiceNumber: any;
  hireslipNumber: any;
  invoiceDate: any;
  source: any;
  destination: any;
  numberOfLrs: any;
  numberOfArticles: any;
  guaranteeWgt: any;
  gurWgtTotal: any;
  kantaWeight: any;
  loadedBy: any;
  //Vehicle details
  lorryNumber: any;
  lorryOwner: any;
  truckLength;
  contactPerson: any;
  address: any;
  truckDriverName: any;
  driverLicNo: any;
  driverMobileNo: any;
  supplierNameText: any;
  panNo: any;
  insuranceNo: any;
  noOfEWBInConsolidatedSystem: any;
  noOfEWBInConsolidatedManual: any;
  consolidatedEwayBill: any;
  confirmConsolidatedEwayBill: any;

  //Billing details
  totalHireAmount: any;
  advanceAmount: any;
  balanceAmount: any;
  touchingHire: any;
  payableAt: any;
  remarks: any;
  deliveryPoints: any;

  doorOpenAmount: any;
  vehiHeightAmount: any;
  doorDeliveryAmount: any;
  extraWeightAmount: any;
  chkPostAmount: any;
  otherChargesAmount: any;
  baseHireAmount: any;
  ewayBillNumber: any;
  twoPtDoorDelyLrs: any;
  selectedTruckTypeId: any;

  validateDeliveryPtSingleMult: any;
  printRewardYesNoValidate: any;

  listInvoice = new Array<String>();
  listOfInvoice = new Array<String>();
  lrsDetailsMhdForPrint = new Array<String>();

  departureTimeSplit: any[];

  validateInsPolNo: any;
  validateLorryNumber: any;
  validatePanNo: any;

  selectedFileTruckOwner: File;
  isUploadedTruckOwner: boolean = false;
  truckDataDtoTruckOwner: TruckDataDto = new TruckDataDto();

  selectedFileTruckDetailsForRC: File;
  selectedFileTruckDetailsForInsurance: File;
  selectedFileTruckDetailsForLicense: File;
  isUploadedPopUp: boolean = false;
  truckDataDtoFileUploadRcInsLic: TruckDataDto = new TruckDataDto();
  validatePopUpFileUploadTruckNumber: any;

  multipleDeliveryPointListPopupReturn: any;
  multipleDeliveryPointLocalStorage: any;
  firstPtDeliv: any;
  multipleInvoiceIsLastPointListPopupReturn: any;
  validateEwayBill: any;
  validateEwayBillConsoldateConfirm: any;
  invPrintAloneForLrs = new Array<String>();
  invPrintAloneForLRDto: LRDto = new LRDto();
  lrDtoFTRoutingExcep: LRDto = new LRDto();

  /* 4 th level */
  lrDtoHideDummyInvoice: LRDto = new LRDto();
  //listHideDummyInvoice = new Array<String>();
  listHideDummyInvoice: any;
  listOfLrsHideDummyInvoice: any;
  //listOfLrsHideDummyInvoice = new Array<String>();
  truckDataDtoForTruckTypeClick: TruckDataDto = new TruckDataDto();
  listMaintenancesHideDummyInvoice: any;
  bookingInvoiceForLrDtoReturn: any;

  //setHamaliDetailsList: any;
  private newAttributeSetHamaliDetailsList: any = {};
  ftHamali: boolean = false;
  /* 4 th level */

  /* 5 th level */
  hireSlipDtoForFtTrackingUserValue: HireSlipDto = new HireSlipDto();
  driverNumberChangeTemp: any;
  hireSlipDtoUserValueForDepartureMethodReturn: any;
  viewShortExtraDescription: any = "";
  hireSlipDtoForAddNoOfTruck: HireSlipDto = new HireSlipDto();
  hireSlipDtoForScheduleTime: HireSlipDto = new HireSlipDto();
  hireSlipDtoForScheduleTimeReturn: any;

  viewInvoiceDetailsPrint_Hireslip = false;
  viewInvoiceSummaryPrint_Hireslip = false;

  printInvoiceBtnHideShow: boolean = false;

  //
  scheduledTripFromLocalStorage_Hireslip: any;
  scheduledTripToLocalStorage_Hireslip: any;
  tripOnTransitFromLocalStorage_Hireslip: any;
  tripOnTransitLocalStorage_Hireslip: any;
  tripOnTransitToLocalStorage_Hireslip: any;

  stockForLocalBookingPassDestination: any;
  makeaLocalDestinatioObj: any;
  hireSlipDtoForDeparureDateTime: HireSlipDto = new HireSlipDto();
  hireSlipDtoForDeparureDateTimeReturn: any;
  /* 5 th level Hireslip Form end */

  //Basha
  tripScheduledToLocalStorage_HireslipRpt: any;
  resetBtnHideAndShow = true;
  startoverBtnHideAndShow = true;

  //For Custom Print
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingV3: any;
  cashMemoDtoForCustomPrintListHeadingV4: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;
  viewCustomPrintV1: boolean;
  validateFromDateIdValue: any = null;

  lrsSummary: number;
  articleSummary: number;
  acWtSummary: number;
  chgWtSummary: number;
  toPaySummary: number;
  paidSummary: number;
  fovSummary: number;
  discountSummary: number;
  serTaxSummary: number;
  lessAmountSummary: number;
  bayFlow = false;
  //ASR_V1_CHG
  vehicleNoSearch: any;
  //
  disableHireEntry: Boolean = false;
  viewHireEntry: Boolean = true;
  showDepartureDateIcon: Boolean = true;
  //Imran_Chg_V1
  panValidation = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  panNumberSearch: any;
  hireSlipDtoForPan: HireSlipDto = new HireSlipDto();
  isPanNoForTruckAvailable: Boolean = false;
  requestDto: RequestDto = new RequestDto();
  disableNextButton: boolean = false;
  blockPanAadharValidation = false;
  codLrDetailsList: any = [];
  codLrNumbers: string;
  prefixLrNumber: any;
  showLrCodeInputFields: any;
  showAutoLrCode: any;

  constructor(
    private modalService: NgbModal,
    private stockService: StockService,
    private router: Router,
    private masterReadService: MasterReadService,
    private hireslipService: HireslipService,
    public dialog: MatDialog,
    private masterService: MasterService,
    public changeDetectorRef: ChangeDetectorRef,
    private lrService: LrService,
    private ewaybillService: EwaybillService
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      /* rights start*/
      /* if (SRDWeb.sortedMapFeatures.get("Rights") != null) {
                    for (int i = 0; i < SRDWeb.sortedMapFeatures.get("Rights").size(); i++) {
                        if (SRDWeb.sortedMapFeatures.get("Rights").get(i) != null
                                && SRDWeb.sortedMapFeatures.get("Rights").get(i)
                                        .equalsIgnoreCase("Stocks Grouping Bins")) {
                            showBins = true;
                        }
                    }
                }*/
      /* rights end */
      /* 1 st level stock grouping start */

      /* if(this.rightsToShowDatatableOfArticleGroupingColumn == "Rights For ArticleGrouping Show Datatable Column"){ 
                 this.viewDeliveryManager=true;
              } */
      if (this.userDataDtoReturnSession.role == "Delivery Manager") {
        this.viewDeliveryManager = true;
      }
      if (this.userDataDtoReturnSession.ewayBillAlert) {
        this.ewayBillAlert = true;
        this.viewEwayBillStatus = true;
      }
      //this.userDataDtoReturnSession.isOpenTrip = true;
      if (
        this.userDataDtoReturnSession.isOpenTrip != null &&
        this.userDataDtoReturnSession.isOpenTrip
      ) {
        // alert(this.userDataDtoReturnSession.isOpenTrip);
        this.getDestInCombo();
      }
      if (
        JSON.parse(
          localStorage.getItem("pendingHireslipDetailsLocalStorage")
        ) == null &&
        JSON.parse(localStorage.getItem("scheduledTripFromLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("scheduledTripToLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("tripOnTransitFromLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("tripOnTransitLocalStorage")) == null &&
        JSON.parse(localStorage.getItem("tripOnTransitToLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("popupHireslipRpt")) == null
      ) {
        this.getArticlesStocksSummaryDetailsList();
      }

      /* 1 st level stock grouping end */

      /* 2 nd level invoice details start */
      this.pendingHireslipFromLocalStorage = null;
      this.pendingHireslipFromLocalStorage = JSON.parse(
        localStorage.getItem("pendingHireslipDetailsLocalStorage")
      );
      //localStorage.clear();
      //console.log("Pending_hireslip-gen");
      //console.log(this.pendingHireslipFromLocalStorage);

      if (
        JSON.parse(localStorage.getItem("tripOnTransitFromLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("tripOnTransitLocalStorage")) == null &&
        JSON.parse(localStorage.getItem("tripOnTransitToLocalStorage")) ==
        null &&
        JSON.parse(localStorage.getItem("popupHireslipRpt")) == null
      ) {
        this.getLoaderHeadListDetails();
        this.getStaffNameDetailsList();
        this.getInchargeDetailsMethod();
        //ASR_V1_CHG
        //this.getTruckMasterDetailsList();
        //
        this.getSupplierDetailsList();
        this.getDriverNameList();
        this.getTPCPEntryList();
        this.getTPCPExitList();
      }
      /* 2 nd level invoice details end */

      /* 3 rd level make a trip details start */

      // flow has change by asrar bhaiya as per discuss
      /*if (SRDWeb.role.equalsIgnoreCase("Booking Manager")) {
                comboBoxBins.setVisible(true);
                lblBins.setVisible(true);
            } else if (SRDWeb.role.equalsIgnoreCase("Transshipment Manager")
                    && (SRDWeb.office.equalsIgnoreCase("Binnymills"))) {
                comboBoxBins.setVisible(true);
                lblBins.setVisible(true);
            } else if (showBins) {
                comboBoxBins.setVisible(true);
                lblBins.setVisible(true);
            } else {
                comboBoxBins.setVisible(false);
                lblBins.setVisible(false);
            }
            */
      this.getTruckCapacityDetails();
      if (this.userDataDtoReturnSession.showStkGrpBins == true) {
        this.hideshowControlsBins = true;
      }

      /* 3 rd level make a trip details end */

      /*   4 th level invoie details start */
      //if(this.rightsForOfficeTypeHideDummyLrPrint != "rights for officetype hide dummy lr print"){
      if (this.userDataDtoReturnSession.officeType != "Booking Office") {
        this.viewCheckBoxInvoiceDummyLr = true;
      } else {
        this.viewCheckBoxInvoiceDummyLr = false;
      }

      /*   4 th level invoie details end */

      /*   5 th level Hireslip form start */
      /*if(this.rightsForInvoiceTypeAndMainInvocie == "rights to show invoice type and main invoice for main and dummy")
           {*/
      if (
        this.userDataDtoReturnSession.officeType != null &&
        this.userDataDtoReturnSession.officeType == "Booking Office"
      ) {
        this.invoiceType = "Main";
        this.isMainInvoice = true;
        this.isBookingStation = true;
        //hpLorry.setVisible(true);
      } else {
        this.isMainInvoice = false;
        this.invoiceType = "Dummy";
        //hpLorry.setVisible(false);
      }
      this.getTruckTypeDetails();

      this.financialYearOnloadValidate =
        this.userDataDtoReturnSession.financialYear;
      this.firstYearStgOnloadValidate =
        this.financialYearOnloadValidate.split("-")[0];
      this.secondYearStgOnloadValidate =
        this.financialYearOnloadValidate.split("-")[1];

      /* Local Storage Dashboard start here  */
      this.scheduledTripFromLocalStorage_Hireslip = null;
      this.scheduledTripToLocalStorage_Hireslip = null;
      this.tripOnTransitFromLocalStorage_Hireslip = null;
      this.tripOnTransitToLocalStorage_Hireslip = null;
      //basha
      this.tripScheduledToLocalStorage_HireslipRpt = null;

      this.scheduledTripFromLocalStorage_Hireslip = JSON.parse(
        localStorage.getItem("scheduledTripFromLocalStorage")
      );

      this.scheduledTripToLocalStorage_Hireslip = JSON.parse(
        localStorage.getItem("scheduledTripToLocalStorage")
      );

      this.tripOnTransitFromLocalStorage_Hireslip = JSON.parse(
        localStorage.getItem("tripOnTransitFromLocalStorage")
      );

      this.tripOnTransitLocalStorage_Hireslip = JSON.parse(
        localStorage.getItem("tripOnTransitLocalStorage")
      );

      this.tripOnTransitToLocalStorage_Hireslip = JSON.parse(
        localStorage.getItem("tripOnTransitToLocalStorage")
      );
      //basha
      this.tripScheduledToLocalStorage_HireslipRpt = JSON.parse(
        localStorage.getItem("popupHireslipRpt")
      );
      console.log("111" + this.tripOnTransitFromLocalStorage_Hireslip);

      console.log("222" + this.scheduledTripToLocalStorage_Hireslip);

      console.log("333" + this.scheduledTripFromLocalStorage_Hireslip);

      console.log("444" + this.tripOnTransitLocalStorage_Hireslip);

      console.log("555" + this.tripOnTransitToLocalStorage_Hireslip);

      console.log("666" + this.tripScheduledToLocalStorage_HireslipRpt);
      localStorage.clear();
      //console.log(localStorage);
      /*   5 th level Hireslip form end */
      // this.changeDetectorRef.detectChanges();

      if (
        this.userDataDtoReturnSession.bayFlowInLR != null &&
        this.userDataDtoReturnSession.bayFlowInLR
      ) {
        this.bayFlow = true;
      }

      //Imran_SG_Chg_V3
      // if (
      //   this.userDataDtoReturnSession.disableHireInGeneration != null &&
      //   this.userDataDtoReturnSession.disableHireInGeneration
      // ) {
      //   this.disableHireEntry = true;
      //   this.viewHireEntry = false;
      // }

      // if (
      //   this.scheduledTripFromLocalStorage_Hireslip.disableHireInGeneration != null &&
      //   this.scheduledTripFromLocalStorage_Hireslip.disableHireInGeneration
      // ) {
      //   this.disableHireEntry = true;
      //   this.viewHireEntry = false;
      // }
      if (
        this.userDataDtoReturnSession.blockLoadingForPANValidation != null &&
        this.userDataDtoReturnSession.blockLoadingForPANValidation
      ) {
        this.blockPanAadharValidation = true;
      }
      // Asrar_Jr_Chg_V6
      this.showAutoLrCode = this.userDataDtoReturnSession.autoLRCode;
      if (this.showAutoLrCode != null) {
        this.showLrCodeInputFields = this.showAutoLrCode;
      }
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngOnInit() {
    /* 1 st level stock grouping start */
    (this.dtOptionsStockSummary = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 230,
      scrollCollapse: true,
      pagingType: "full_numbers",
      // pageLength: 10,
      paging: false,
      info: false,
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };
        var totLrs = api
          .column(1)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var articles = api
          .column(2)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actWgt = api
          .column(3)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(4)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var totAmt = api
          .column(5)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(1).footer()).html(totLrs);
        $(api.column(2).footer()).html(articles);
        $(api.column(3).footer()).html(actWgt);
        $(api.column(4).footer()).html(chgWgt);
        $(api.column(5).footer()).html(totAmt);
      },
    }),
      (this.dtOptionsStockDetails = {
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: '<i class="fas fa-print"> Urgent LRs</i>',
            titleAttr: "Print Urgent LRs",
          },
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Urgent LRs</i>',
            titleAttr: "Excel Urgent LRs",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 230,
        scrollCollapse: true,
        pagingType: "full_numbers",
        // pageLength: 10,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };

          var articles = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(9)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(10)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var totAmt = api
            .column(11)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(8).footer()).html(articles);
          $(api.column(9).footer()).html(actWgt);
          $(api.column(10).footer()).html(chgWgt);
          $(api.column(11).footer()).html(totAmt);
        },
      });

    /* 1 st level stock grouping end */

    /* 3 rd level make a trip details start */
    (this.dtOptionsEligibleLrs = {
      dom: "Bfrtip",
      buttons: [],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 220,
      scrollCollapse: true,
      pagingType: "full_numbers",
      //pageLength: 8,
      paging: false,
      info: false,
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };

        var articles = api
          .column(4)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actWgt = api
          .column(5)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(6)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var toPay = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api
          .column(8)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var goodsvalue = api
          .column(9)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(4).footer()).html(articles);
        $(api.column(5).footer()).html(actWgt);
        $(api.column(6).footer()).html(chgWgt);
        $(api.column(7).footer()).html(toPay);
        $(api.column(8).footer()).html(paid);
        $(api.column(9).footer()).html(goodsvalue);
      },
    }),
      (this.dtOptionRemainingLrs = {
        dom: "Bfrtip",
        /*buttons: [
                      'excel', 'print'
                  ],*/
        buttons: [],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 220,
        scrollCollapse: true,
        pagingType: "full_numbers",
        //pageLength: 8,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };

          var articles = api
            .column(2)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var toPay = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paid = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var goodsvalue = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(2).footer()).html(articles);
          $(api.column(3).footer()).html(actWgt);
          $(api.column(4).footer()).html(chgWgt);
          $(api.column(5).footer()).html(toPay);
          $(api.column(6).footer()).html(paid);
          $(api.column(7).footer()).html(goodsvalue);
        },
      });
    /* 3 rd level make a trip details end */

    /* 4 th level invoice start */
    this.dtOptionsInvoice = {
      dom: "Bfrtip",
      /*buttons: [
                      'excel', 'print'
                  ],*/
      buttons: [],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 230,
      scrollCollapse: true,
      pagingType: "full_numbers",
      //pageLength: 8,
      paging: false,
      info: false,
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };

        var articles = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actWgt = api
          .column(10)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(11)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var toPay = api
          .column(12)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api
          .column(13)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var goodsvalue = api
          .column(14)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(7).footer()).html(articles);
        $(api.column(10).footer()).html(actWgt);
        $(api.column(11).footer()).html(chgWgt);
        $(api.column(12).footer()).html(toPay);
        $(api.column(13).footer()).html(paid);
        $(api.column(14).footer()).html(goodsvalue);
      },
    };
    /* 4 th invoice end */

    /* 5 th Hireslip start */
    (this.dtOptionsGoodsShortAndExtraDetails = {
      dom: "Bfrtip",
      buttons: [],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      //place holder in search/filter in datatable ends
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 280,
      scrollCollapse: true,
      //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
      paging: false,
      info: false,
    }),
      (this.dtOptionsHireslipDetails = {
        // the below code is for button export starts
        dom: "Bfrtip",
        buttons: [],
        //place holder in search/filter in datatable starts
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        //place holder in search/filter in datatable ends
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 280,
        scrollCollapse: true,
        //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        paging: false,
        info: false,
      });
    /* 5 th Hireslip end*/
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        // Do your stuff
        //dtInstance.destroy();
        //console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
        //console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
      });
    });
  }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {
    /* 1 st level stock grouping start */
    this.dtTriggerStockSummary.next();
    this.dtTriggerStockDetails.next();
    /* 1 st level stock grouping end */

    /* 5 th level stock grouping start */
    this.dtTriggerGoodsShortAndExtraDetails.next();
    this.dtTriggerHireslipDetails.next();
    /* 5 th level stock grouping end */
    /* 2 nd level Invoice details start*/
    if (
      this.pendingHireslipFromLocalStorage != null &&
      this.pendingHireslipFromLocalStorage != undefined
    ) {
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
        this.nextToInvoiceDetailOfDarkBtnHideShow = true;
        this.nextToInvoiceDetailOfGreenBtnHideShow = false;
        this.setPendingHireslipValueDetailsFromDashboard();
      }, 2000);
    }
    /* 2 nd level Invoice details end*/
    /* 5 th level stock grouping start */
    if (
      this.scheduledTripFromLocalStorage_Hireslip != null &&
      this.scheduledTripFromLocalStorage_Hireslip != undefined
    ) {
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.scheduledTripFromLocalStorage_Hireslip;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    if (
      this.scheduledTripToLocalStorage_Hireslip != null &&
      this.scheduledTripToLocalStorage_Hireslip != undefined
    ) {
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.scheduledTripToLocalStorage_Hireslip;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    if (
      this.tripOnTransitFromLocalStorage_Hireslip != null &&
      this.tripOnTransitFromLocalStorage_Hireslip != undefined
    ) {
      console.log(this.tripOnTransitFromLocalStorage_Hireslip);
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.tripOnTransitFromLocalStorage_Hireslip;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    if (
      this.tripOnTransitLocalStorage_Hireslip != null &&
      this.tripOnTransitLocalStorage_Hireslip != undefined
    ) {
      console.log(this.tripOnTransitLocalStorage_Hireslip);
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.tripOnTransitLocalStorage_Hireslip;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    if (
      this.tripOnTransitToLocalStorage_Hireslip != null &&
      this.tripOnTransitToLocalStorage_Hireslip != undefined
    ) {
      console.log(this.tripOnTransitToLocalStorage_Hireslip);
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.tripOnTransitToLocalStorage_Hireslip;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    //Basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      console.log(this.tripScheduledToLocalStorage_HireslipRpt);
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
        this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
        this.hireSlipDtoForCreateHireslipLocalStorage =
          this.tripScheduledToLocalStorage_HireslipRpt;
        this.setOnloadOnHireSlipDetails();
      }, 2000);
    }

    /* 5 th level stock grouping end */
  }

  /* 1 st level main stock grouping start */

  getArticlesStocksSummaryDetails() {
    this.lrDtoStockSummary = new LRDto();
    this.lrDtoStockSummary.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoStockSummary.branch = this.userDataDtoReturnSession.office;
    this.lrDtoStockSummary.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStockSummary.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStockSummary.destination =
      this.userDataDtoReturnSession.mainStation;
    this.lrDtoStockSummary.OfficeType =
      this.userDataDtoReturnSession.officeType;
    //console.log(this.lrDtoStockSummary);
  }

  getArticlesStocksSummaryDetailsList() {
    this.getArticlesStocksSummaryDetails();
    this.showSpinnerForAction = true;
    this.stockService
      .getArticlesStocksSummary(this.lrDtoStockSummary)
      .subscribe((response) => {
        //console.log(response);
        if (response.length > 0) {
          this.stockSummaryDataList = [];
          $("#" + this.pageId + "stockSummaryTableId")
            .DataTable()
            .destroy();
          this.stockSummaryDataList = response;
          this.dtTriggerStockSummary.next();
        } else {
          swal("Alert", "No Record Found", "warning");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Summary",
          "warning"
        );
      },
      () => console.log("done");
  }

  rowSelectedGetTripLRDeatils(stockSummaryTableData) {
    console.log(stockSummaryTableData);
    this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
    this.stockDetailsDataList = [];
    $("#" + this.pageId + "stockDetailsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerStockDetails.next();
    this.makeAtripBtnHideShow = false;
    this.nextTomakeAtripBtnHideShow = true;
    //if (stockSummaryTableData.totalLrs > 0) {
    this.lrDtoStocksDetails.destination = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
    this.lrDtoStocksDetails.subStations = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStocksDetails.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoStocksDetails.bayFlow = this.bayFlow;

    this.makeATripBtn = stockSummaryTableData.endDest;
    this.tripSheetBtn = stockSummaryTableData.endDest;

    this.getArticlesStocksDetailsList(this.lrDtoStocksDetails);
    //}
  }

  getArticlesStocksDetailsList(lrDtoStocksDetails) {
    //console.log(this.lrDtoStocksDetails);
    this.showSpinnerForAction = true;
    this.stockService
      .getArticlesStocks(this.lrDtoStocksDetails)
      .subscribe((response) => {
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "stockDetailsTableId")
          .DataTable()
          .destroy();
        if (response.length > 0) {
          console.log(response);
          this.stockDetailsDataList = response;
          setTimeout(() => {
            this.dtTriggerStockDetails.next();
          }, 2000);

          /*  if(this.rightsToShowMakeaTripButton =="Show make a trip option"){
                              this.makeAtripBtnHideShow=true;
                      }else if(this.rightsToShowTripSheetButton == "Show trip sheet option"){
                              this.tripSheetBtnHideShow=true;
                      }
                    */
          if (
            this.userDataDtoReturnSession.officeType == "Booking Office" ||
            (this.userDataDtoReturnSession.officeType ==
              "Transshipment Office" &&
              this.userDataDtoReturnSession.stationType == "Transshipment" &&
              !(this.userDataDtoReturnSession.office == "Binnymills"))
          ) {
            this.nextTomakeAtripBtnHideShow = true;
            //this.makeAtripBtnHideShow = true;
            this.tripSheetBtnHideShow = true;
          }
          if (
            this.lrDtoStocksDetails.stationType != null &&
            !(this.lrDtoStocksDetails.stationType == "") &&
            this.lrDtoStocksDetails.stationType == "local"
          ) {
            this.nextTomakeAtripBtnHideShow = false;
            //this.makeAtripBtnHideShow = false;
            this.tripSheetBtnHideShow = false;
          } else if (
            this.lrDtoStocksDetails.stationType != null &&
            !(this.lrDtoStocksDetails.stationType == "") &&
            (this.lrDtoStocksDetails.stationType == "Others" ||
              (this.lrDtoStocksDetails.stationType == "Godown Delivery" &&
                !(this.userDataDtoReturnSession.office == "Binnymills")))
          ) {
            this.nextTomakeAtripBtnHideShow = false;
            //this.makeAtripBtnHideShow = false;
            this.tripSheetBtnHideShow = false;
          } else if (
            this.lrDtoStocksDetails.stationType == "Godown Delivery" &&
            this.userDataDtoReturnSession.office == "Binnymills"
          ) {
            this.nextTomakeAtripBtnHideShow = true;
            //this.makeAtripBtnHideShow = true;
            this.tripSheetBtnHideShow = false;
          } else {
            this.nextTomakeAtripBtnHideShow = true;
            //this.makeAtripBtnHideShow = true;
            this.tripSheetBtnHideShow = false;
          }

          if (this.userDataDtoReturnSession.ewayBillAlert) {
            this.ewayBillAlertMsg(this.stockDetailsDataList);
          } else {
            this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
          }

          this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  ewayBillAlertMsg(dataForEway) {
    var count = 0;
    var ewayBillStatus = "Multiple Lr's, No EWB";
    var lrs = "";
    console.log("ewayBillAlertMsg");
    console.log(dataForEway.length);
    for (let i = 0; i < dataForEway.length; i++) {
      if (
        dataForEway[i].ewayBillStatus != null &&
        dataForEway[i].ewayBillStatus == ewayBillStatus
      ) {
        console.log(dataForEway[i].ewayBillStatus);
        if (count == 0) {
          lrs = dataForEway[i].lrNumber;
        } else {
          lrs += ", " + dataForEway[i].lrNumber;
        }
        count++;
      }
    }
    if (count >= 1) {
      swal({
        title:
          "LR Numbers without Eway Bill Number are listed below. Total:" +
          count +
          "",
        text: "" + lrs + "",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
      });
    } else {
      this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
    }
  }

  ewayBillAlertBlockMsg(dataForEway) {
    var count = 0;
    var ewayBillStatus = "Multiple Lr's, No EWB";
    var lrs = "";
    console.log("ewayBillAlertBlockMsg");
    console.log(dataForEway.length);
    for (let i = 0; i < dataForEway.length; i++) {
      if (
        dataForEway[i].ewayBillStatus != null &&
        dataForEway[i].ewayBillStatus == ewayBillStatus
      ) {
        console.log(dataForEway[i].ewayBillStatus);
        if (count == 0) {
          lrs = dataForEway[i].lrNumber;
        } else {
          lrs += ", " + dataForEway[i].lrNumber;
        }
        count++;
      }
    }
    if (count >= 1) {
      swal({
        title:
          "Not allowed for the Trip without E-Way bill number. Total : " +
          count +
          "",
        text:
          "LRs without Eway Bill Number are blocked to create the Trip, please verify the E-Way bill number for the listed LRs and take the necessary action to create the Trip. LR Numbers : " +
          lrs +
          "",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
      });
    } else {
      this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
    }
  }

  /*Button Click Method Make a trip */
  validateNextToMakeTripButton(tripToDestList) {
    //this.makeAtripBtnHideShow = true;
    //this.nextTomakeAtripBtnHideShow = false;
    this.validationInvoiceBlocking(tripToDestList);
  }

  /* validateInvoiceBlockingMakeTripButton(tripToDestList) {
         this.validationInvoiceBlocking(tripToDestList);
     }*/

  getInvoiceBlockingDto() {
    this.hireSlipDtoForInvoiceBlocking = new HireSlipDto();
    this.hireSlipDtoForInvoiceBlocking.companyid =
      this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoForInvoiceBlocking.branch =
      this.userDataDtoReturnSession.office;
  }

  validationInvoiceBlocking(tripToDestList) {
    this.getInvoiceBlockingDto();
    this.hireslipService
      .getUnclearedInvoives(this.hireSlipDtoForInvoiceBlocking)
      .subscribe((response) => {
        //console.log(response);
        var hireslipUncleared = "";
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            if (i == 0) {
              hireslipUncleared = response[i].hireslipnumber;
            } else {
              hireslipUncleared += ", " + response[i].hireslipnumber;
            }
          }
          swal({
            title: "Invoice creation blocked,Please update the Invoice(s) no.",
            text: "" + hireslipUncleared + " in LR short & extra",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.getMakeATripMethod(tripToDestList);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Invoice Blocking in Stock Summary",
          "warning"
        );
      },
      () => console.log("done");
  }

  /*getMakeATripMethod(tripToDestList) {
        if (this.makeATripBtn == "Others") {
            swal({
                title: "Trips For Other",
                text: "Can't generate Trips for UnAssigned Lrs",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            // this.userDataDtoReturnSession.isOpenTrip = true;
            if (this.userDataDtoReturnSession.isOpenTrip) {
                this.openTripToDestList(tripToDestList);
                //popupTripTo.show();
            } else {
                //this.myStepper.next();
                this.makeMainTrip(this.makeATripBtn);
                // stepper.next();
            }
        }
    }*/
  getMakeATripMethod(tripToDestList) {
    if (this.makeATripBtn == "Others") {
      swal({
        title: "Trips For Other",
        text: "Can't generate Trips for UnAssigned Lrs",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      //this.userDataDtoReturnSession.isOpenTrip = true;
      //alert(this.userDataDtoReturnSession.isOpenTrip);
      if (this.userDataDtoReturnSession.isOpenTrip) {
        this.openTripToDestList(tripToDestList);
        //popupTripTo.show();
      } else {
        this.makeAtripBtnHideShow = true;
        this.nextTomakeAtripBtnHideShow = false;
        swal(
          "OK",
          "Please Click The Make A Trip Button Again To Continue",
          "info"
        );
      }
    }
  }

  validateMakeATripButton() {
    this.makeMainTrip(this.makeATripBtn);
  }

  makeMainTrip(selectedDestination) {
    console.log("makeATripBtn");
    console.log(this.makeATripBtn);
    /* 
         if(this.rigthsForMakeTripBtnGenerateInvoice == "selected destination to generate invoice"){
            this.lrDtoLocalStorage.branch="Konditope";
        */
    if (
      this.makeATripBtn == "Godown Delivery" &&
      this.userDataDtoReturnSession.office == "Binnymills"
    ) {
      //FORMWARDIG TO NEXT PAGE PENDING
      // tabInvoiceGeneration.add(new InvoiceDetailsForTrip("Konditope",
      //"grouping", null, null, null, SRDWeb.office,
      //selectedDestination));
      this.lrDtoForStockGroupingLocalStorage = new LRDto();
      this.lrDtoForStockGroupingLocalStorage.branch = "Konditope";
      this.lrDtoForStockGroupingLocalStorage.mode = "grouping";
      this.lrDtoForStockGroupingLocalStorage.invoiceNumber = null;
      this.lrDtoForStockGroupingLocalStorage.guranteeWt = null;
      this.lrDtoForStockGroupingLocalStorage.truckDataDto = null;
      this.lrDtoForStockGroupingLocalStorage.source =
        this.userDataDtoReturnSession.office;
      this.lrDtoForStockGroupingLocalStorage.tripTo = "Konditope";
      this.toStationLocation = "Konditope";
    } else {
      //tabInvoiceGeneration.add(new InvoiceDetailsForTrip(
      //btnGenerateInvoice.getId(), "grouping", null, null, null,
      //SRDWeb.office, selectedDestination));
      this.lrDtoForStockGroupingLocalStorage = new LRDto();
      this.lrDtoForStockGroupingLocalStorage.branch = this.makeATripBtn;
      this.lrDtoForStockGroupingLocalStorage.mode = "grouping";
      this.lrDtoForStockGroupingLocalStorage.invoiceNumber = null;
      this.lrDtoForStockGroupingLocalStorage.guranteeWt = null;
      this.lrDtoForStockGroupingLocalStorage.truckDataDto = null;
      this.lrDtoForStockGroupingLocalStorage.source =
        this.userDataDtoReturnSession.office;
      this.lrDtoForStockGroupingLocalStorage.tripTo = selectedDestination;
      this.toStationLocation = selectedDestination;
    }

    /* 2 nd level invoice details start*/
    this.nextToInvoiceDetailOfDarkBtnHideShow = true;
    this.nextToInvoiceDetailOfGreenBtnHideShow = false;

    this.fromStationLocation = this.userDataDtoReturnSession.office;
    // this.toStationLocation = selectedDestination;
    /* 2 nd level invoice details emd*/
  }

  clickListnerForTripToDestType(e: NgbTypeaheadSelectItemEvent) {
    this.modelTripToDestType = e.item;
    console.log(this.modelTripToDestType);
    $("#" + this.pageId + "tripToDestType").val(
      this.modelTripToDestType.destination
    );
  }

  getDestInCombo() {
    this.lrDtoDest = new LRDto();
    this.lrDtoDest.mode = "desttrip";
    this.lrDtoDest.companyId = this.userDataDtoReturnSession.companyId;
    this.masterReadService
      .getDestinationForLREntryService(this.lrDtoDest)
      .subscribe((response) => {
        if (response) {
          //console.log(response);
          if (response.length == 0) {
            this.tripToDestOptions = [];
            this.tripToDestTypeTA = [];
            swal("Warning", "No Destination records found!", "warning");
          } else {
            this.tripToDestOptions = [];
            this.tripToDestTypeTA = [];
            this.tripToDestOptions = response;
            for (let i = 0; i < this.tripToDestOptions.length; i++) {
              this.tripToDestTypeTA.push(this.tripToDestOptions[i]);
            }
            console.log(this.tripToDestTypeTA);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Error",
          text: "Server Error While Getting getDestInCombo in Stock Summary",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  openTripToDestList(tripToDestList) {
    this.modalService.open(tripToDestList, { centered: true });
  }

  btnProceedTripTo(openLocalTripPopUpWidget) {
    var selectedTripToDest = $("#" + this.pageId + "tripToDestType").val();
    if (selectedTripToDest == null || selectedTripToDest == "") {
      swal({
        title: "Mandatory Field",
        text: "Please select Trip To option to proceed",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      var selectedDestType = this.modelTripToDestType.destinationType;
      if (selectedDestType == "Booking Office") {
        this.makeLocalTrip(selectedTripToDest, openLocalTripPopUpWidget);
      } else {
        //this.makeMainTrip(this.makeATripBtn);
        this.makeATripBtn = null;
        this.makeaLocalDestinatioObj = null;

        this.makeaLocalDestinatioObj = $(
          "#" + this.pageId + "tripToDestType"
        ).val();
        this.makeATripBtn = this.makeaLocalDestinatioObj;

        this.makeAtripBtnHideShow = true;
        this.nextTomakeAtripBtnHideShow = false;
        swal(
          "OK",
          "Please Click The Make A Trip Button Again To Continue",
          "info"
        );
      }
    }
  }

  makeLocalTrip(selectedTripToDest, openLocalTripPopUpWidget) {
    /*   this.modalRefferenceOpenLocalTripWidgetPopUp = this.modalService.open(this.openLocalTripPopUpWidget,
               { centered: true,windowClass: "modalClassForPopUpMakeALocalTrip"});
           this.modalRefferenceOpenLocalTripWidgetPopUp.result.then((result) => {
               this.modalRefferenceOpenLocalTripWidgetClosePopUp = `Closed with: ${result}`;
           }, reason => {
               this.modalRefferenceOpenLocalTripWidgetClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
           });*/
    this.stockForLocalBookingPassDestination = null;
    this.stockForLocalBookingPassDestination = selectedTripToDest;
    localStorage.clear();
    localStorage.setItem(
      "stockGroupingPageRedirect",
      JSON.stringify("Stock Grouping Page")
    );
    localStorage.setItem(
      "stockGroupingPageSelectedDestination",
      JSON.stringify(this.stockForLocalBookingPassDestination)
    );
    this.router.navigate(["/stock/transfer/stockForLocalBooking"]);

    //windowClass: "modalClassForPopUpMakeALocalTrip"

    //alert(selectedTripToDest);
    //PENDING WORK
    //popup = new Dialog();
    //popup.setHeading("Trip Schedule For " + btnGenerateInvoice.getId());
    // popup.add(new TripForLocal(btnGenerateInvoice.getId(), "localgrouping",
    //   null, "booking", selectedTripToDest, 0));
    // popup.setWidth(x - (x / 65));
    //popup.setHeight(600);
    //popup.setModal(true);
    //popup.setButtons("");
    //popup.show();
  }

  resetFeilds() {
    /* 1 st level  stock grouping end */
    //this.viewEwayBillStatus = false;
    //this.ewayBillAlert = false;
    //this.viewDeliveryManager = true;
    this.makeATripBtn = "";
    this.tripSheetBtn = "";
    this.lrDtoForStockGroupingLocalStorage = new LRDto();
    this.makeAtripBtnHideShow = false;
    this.tripSheetBtnHideShow = false;
    this.nextTomakeAtripBtnHideShow = false;
    this.modelTripToDestType = null;
    this.fromStationLocation = "";
    this.toStationLocation = "";
    /* 1 st level  stock grouping end */

    /* 2 nd level invoice details start*/
    this.nextToInvoiceDetailOfDarkBtnHideShow = false;
    this.nextToInvoiceDetailOfGreenBtnHideShow = false;
    /* 2 nd level  invoice details end*/
  }

  /* 1 st level main stock grouping end */

  /* 2 nd level main invoice details start*/

  /*For Select Method Of Drop Down START HERE*/
  clickListnerForLoaderIncharge(e: NgbTypeaheadSelectItemEvent) {
    this.modelLoaderHead = e.item;
    window.setTimeout(function () {
      $("#sgcloaderHead").focus();
    }, 100);
  }

  clickListnerForLoaderHead(e: NgbTypeaheadSelectItemEvent) {
    this.modelLoaderHead = e.item;
    window.setTimeout(function () {
      $("#sgcloaderStaffName").focus();
    }, 100);
  }

  clickListnerForLoaderStaffName(e: NgbTypeaheadSelectItemEvent) {
    this.modelLoaderStaffName = e.item;
    window.setTimeout(function () {
      //ASR_V1_CHG
      // $("#sgctruckNumber").focus();
      $("#sgctruckNumberSearchId").focus();
      //
    }, 100);
  }

  clickListnerForTruckNumber(e: NgbTypeaheadSelectItemEvent) {
    this.modelTruckNumber = e.item;
    $("#" + this.pageId + "panNumberSearchId").val(this.modelTruckNumber.tinNo);
    window.setTimeout(function () {
      $("#sgcsupplierName").focus();
    }, 100);
  }

  clickListnerForSupplier(e: NgbTypeaheadSelectItemEvent) {
    this.modelSupplierName = e.item;
    window.setTimeout(function () {
      //changed by imran for adding pan on 02032024
      // $("#sgcdriverName").focus();
      $("#" + this.pageId + "panNumberSearchId").focus();
    }, 100);
  }

  clickListnerForDriverName(e: NgbTypeaheadSelectItemEvent) {
    this.modelDriverName = e.item;
    $("#" + this.pageId + "licenceNumber").val("");
    $("#" + this.pageId + "licenceNumber").val(this.modelDriverName.licenceNo);
    $("#" + this.pageId + "licenceNumber").prop("readonly", true);
    $("#" + this.pageId + "bayNumber").focus();
    /* window.setTimeout(function () {
             $("#"+this.pageId+"bayNumber").focus();
             $("#"+this.pageId+"licenceNumber").prop('readonly', true);
         }, 100);*/
    this.onSelectEntryDriverId = null;
    this.onSelectEntryDriverId = this.modelDriverName.id;
  }

  clickListnerForEntryCP(e: NgbTypeaheadSelectItemEvent) {
    this.modelEntryCP = e.item;
    window.setTimeout(function () {
      $("#sgcexitCP").focus();
    }, 100);
    this.onSelectEntryCpCode = null;
    this.onSelectEntryCpCode = this.modelEntryCP.cpCode;
  }

  clickListnerForExitCP(e: NgbTypeaheadSelectItemEvent) {
    this.modelExitCP = e.item;
    window.setTimeout(function () {
      $("#sgcexpExitDates").focus();
    }, 100);
    this.onSelectExitCpCode = null;
    this.onSelectExitCpCode = this.modelExitCP.cpCode;
  }
  /*For Select Method Of Drop Down END HERE */

  /*On Key Press Function Start Here */

  onKeyPressFieldForExpDay(event) {
    if (event == 13) {
      $("#" + this.pageId + "remarks").focus();
    }
  }

  onKeyPressFieldForLicNo(event) {
    if (event == 13) {
      window.setTimeout(function () {
        $("#sgcbayNumber").focus();
      }, 100);
    }
  }
  onKeyPressFieldForBayNo(event) {
    if (event == 13) {
      window.setTimeout(function () {
        $("#sgcbayLoadingDates").focus();
      }, 100);
    }
  }
  onKeyPressFieldForBayDate(event) {
    if (event == 13) {
      window.setTimeout(function () {
        $("#sgcbayLoadingTime").focus();
      }, 100);
    }
  }
  onKeyPressFieldForBayTime(event) {
    if (event == 13) {
      window.setTimeout(function () {
        $("#sgcentryCP").focus();
      }, 100);
    }
  }

  /*On Key Press Function End Here */

  getLoaderHeadList() {
    this.loaderHeadHireslipDto = new HireSlipDto();
    if (
      this.userDataDtoReturnSession.officeType != null &&
      this.userDataDtoReturnSession.officeType == "Delivery Office"
    ) {
      this.loaderHeadHireslipDto.branch =
        this.userDataDtoReturnSession.mainStation;
      this.loaderHeadHireslipDto.mainStation =
        this.userDataDtoReturnSession.mainStation;
    } else {
      this.loaderHeadHireslipDto.branch =
        this.userDataDtoReturnSession.mainAdminStation;
      this.loaderHeadHireslipDto.mainStation =
        this.userDataDtoReturnSession.mainAdminStation;
    }
    this.loaderHeadHireslipDto.companyid =
      this.userDataDtoReturnSession.companyId;
  }

  getLoaderHeadListDetails = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getLoaderHeadList();
    this.masterReadService
      .getLoaderHeadsDetails(this.loaderHeadHireslipDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.loaderHeadOptions = [];
          this.loaderHeadTA = [];
          swal("Warning", "No Loader records found!", "warning");
        } else {
          this.loaderHeadOptions = [];
          this.loaderHeadTA = [];
          this.loaderHeadOptions = response;
          for (let i = 0; i < this.loaderHeadOptions.length; i++) {
            this.loaderHeadTA.push(this.loaderHeadOptions[i]);
            this.hashmapLoaderHead.set(
              this.loaderHeadOptions[i].loaderHead,
              i + 1
            );
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("done");
  };

  getInchargeDetailsData() {
    this.loaderInchargeLRDto = new LRDto();
    if (
      this.userDataDtoReturnSession.officeType != null &&
      this.userDataDtoReturnSession.officeType == "Delivery Office"
    ) {
      this.loaderInchargeLRDto.branch =
        this.userDataDtoReturnSession.mainStation;
    } else {
      this.loaderInchargeLRDto.branch =
        this.userDataDtoReturnSession.mainAdminStation;
    }
    this.loaderInchargeLRDto.companyId =
      this.userDataDtoReturnSession.companyId;
  }
  getInchargeDetailsMethod = () => {
    this.getInchargeDetailsData();
    this.masterService
      .getInchargeDetails(this.loaderInchargeLRDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.loaderInchargeOptions = [];
          this.loaderInchargeTA = [];
          swal("Warning", "No Loader Incharge records found!", "warning");
        } else {
          this.loaderInchargeOptions = [];
          this.loaderInchargeTA = [];
          this.loaderInchargeOptions = response;
          for (let i = 0; i < this.loaderInchargeOptions.length; i++) {
            this.loaderInchargeTA.push(this.loaderInchargeOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Problem Occurred While getting the Incharge details",
          "info"
        );
      },
      () => console.log("done");
  };

  getStaffDetailsForRead() {
    this.staffDetailsLrDto = new LRDto();
    this.staffDetailsLrDto.companyId = this.userDataDtoReturnSession.companyId;
    if (
      this.userDataDtoReturnSession.officeType != null &&
      this.userDataDtoReturnSession.officeType == "Delivery Office"
    ) {
      this.staffDetailsLrDto.branch = this.userDataDtoReturnSession.mainStation;
    } else {
      this.staffDetailsLrDto.branch =
        this.userDataDtoReturnSession.mainAdminStation;
    }
  }

  getStaffNameDetailsList = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getStaffDetailsForRead();
    this.masterService
      .getStaffDetails(this.staffDetailsLrDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.loaderStaffOption = [];
          this.loaderStaffNameTA = [];
          swal("Warning", "No Staff Name records found!", "warning");
        } else {
          this.loaderStaffOption = [];
          this.loaderStaffNameTA = [];
          this.loaderStaffOption = response;
          for (let i = 0; i < this.loaderStaffOption.length; i++) {
            this.loaderStaffNameTA.push(this.loaderStaffOption[i]);
            this.hashmapLoader.set(this.loaderStaffNameTA[i].staffName, i + 1);
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        //this.showSpinnerForActionForInvoiceDetails= false;
        swal(
          "Error",
          "Server Error While Getting Loader Name Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getDriverDetailsForReadTruckNo() {
    this.truckDataDtoForTruckNo = new TruckDataDto();
    this.truckDataDtoForTruckNo.companyId =
      this.userDataDtoReturnSession.companyId;
    this.truckDataDtoForTruckNo.status = "Working";
  }

  getTruckMasterDetailsList = () => {
    this.showSpinnerForActionForInvoiceDetails = true;
    this.getDriverDetailsForReadTruckNo();
    this.masterReadService
      .getTruck(this.truckDataDtoForTruckNo)
      .subscribe((response) => {
        if (response.length == 0) {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          swal("Warning", "No Truck Number records found!", "warning");
        } else {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          this.truckNumberOptions = response;
          console.log("t1 : " + response.length);
          for (let i = 0; i < this.truckNumberOptions.length; i++) {
            this.truckNumberTA.push(this.truckNumberOptions[i]);
            this.hashmapTruck.set(
              this.truckNumberOptions[i].truckNumber,
              this.truckNumberOptions[i]
            );
            this.hashmapTruckNumberIndex.set(
              this.truckNumberOptions[i].truckNumber,
              i + 1
            );
          }
          console.log("t2 : " + response.length);
        }
        this.showSpinnerForActionForInvoiceDetails = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForActionForInvoiceDetails = false;
        swal(
          "Error",
          "Server Error While Getting Truck Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getSupplierDetails() {
    this.supplierDetailsDto = new SupplierDetailsDto();
    this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
    //this.supplierDetailsDto.mode = "Working";
  }
  getSupplierDetailsList = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getSupplierDetails();
    this.masterReadService
      .getSuplierMaster(this.supplierDetailsDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          swal("Warning", "No Supplier Master records found!", "warning");
        } else {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          this.supplierOptions = response;

          for (let i = 0; i < this.supplierOptions.length; i++) {
            this.supplierNameTA.push(this.supplierOptions[i]);
            this.hashMapSupplier.set(
              this.supplierOptions[i].supplierName,
              this.supplierOptions[i]
            );
            this.hashMapSupplierIndex.set(
              this.supplierOptions[i].supplierName,
              i
            );
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        //this.showSpinnerForActionForInvoiceDetails= false;
        swal(
          "Error",
          "Server Error While Getting Supplier Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getDriverDetailsForRead() {
    this.driverDetailsDto = new DriverdetailsDto();
    this.driverDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
    this.driverDetailsDto.status = "Working";
    if (this.userDataDtoReturnSession.mainAdminStation != null) {
      this.driverDetailsDto.mode = "MainStation";
      this.driverDetailsDto.mainStation =
        this.userDataDtoReturnSession.mainAdminStation;
    } else {
      this.driverDetailsDto.mode = "Station";
      this.driverDetailsDto.mainStation = this.userDataDtoReturnSession.office;
    }
  }

  getDriverNameList = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getDriverDetailsForRead();
    this.masterReadService
      .getDriver(this.driverDetailsDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.driverNameOptions = [];
          this.driverNameTA = [];
          swal("Warning", "No Driver Master records found!", "warning");
        } else {
          this.driverNameOptions = [];
          this.driverNameTA = [];
          this.driverNameOptions = response;

          for (let i = 0; i < this.driverNameOptions.length; i++) {
            this.driverNameTA.push(this.driverNameOptions[i]);
            // this.driverNameTA.push( this.driverNameOptions[i].driverName,this.driverNameOptions[i]);
            this.hashMapDriver.set(
              this.driverNameOptions[i].driverName,
              this.driverNameOptions[i]
            );
            this.hashMapDriverIndex.set(
              this.driverNameOptions[i].id,
              this.driverNameOptions[i].driverName
            );
            this.hashMapDriverIndexNew.set(this.driverNameOptions[i].id, i);
            //console.log(this.driverNameTA.push( this.driverNameOptions[i].driverName),this.driverNameOptions[i]);
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        //this.showSpinnerForActionForInvoiceDetails= false;
        swal(
          "Error",
          "Server Error While Getting Driver Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getCheckPostEntryList() {
    this.truckDataTPCPEntryDto = new TruckDataDto();
    this.truckDataTPCPEntryDto.companyId =
      this.userDataDtoReturnSession.companyId;
    this.truckDataTPCPEntryDto.status = "Entry";
  }

  getTPCPEntryList = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getCheckPostEntryList();
    this.masterReadService
      .getTPCP(this.truckDataTPCPEntryDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.entryCpOptions = [];
          this.entryCPTA = [];
          swal("Warning", "No TPCP records found!", "warning");
        } else {
          this.entryCpOptions = [];
          this.entryCPTA = [];
          this.entryCpOptions = response;

          for (let i = 0; i < this.entryCpOptions.length; i++) {
            this.entryCPTA.push(this.entryCpOptions[i]);
            this.hashMapEntryCPIndex.set(this.entryCpOptions[i].cpName, i + 1);
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        //this.showSpinnerForActionForInvoiceDetails= false;
        swal(
          "Error",
          "Server Error While Getting Truck Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getCheckPostExitList() {
    this.truckDataTPCPExitDto = new TruckDataDto();
    this.truckDataTPCPExitDto.companyId =
      this.userDataDtoReturnSession.companyId;
    this.truckDataTPCPExitDto.status = "Exit";
  }
  getTPCPExitList = () => {
    //this.showSpinnerForActionForInvoiceDetails= true;
    this.getCheckPostExitList();
    this.masterReadService
      .getTPCP(this.truckDataTPCPExitDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.exitCpOptions = [];
          this.exitCPTA = [];
          swal("Warning", "No TPCP records found!", "warning");
        } else {
          this.exitCpOptions = [];
          this.exitCPTA = [];
          this.exitCpOptions = response;

          for (let i = 0; i < this.exitCpOptions.length; i++) {
            this.exitCPTA.push(this.exitCpOptions[i]);
            this.hashMapExitCPIndex.set(this.exitCpOptions[i].cpName, i + 1);
          }
        }
        //this.showSpinnerForActionForInvoiceDetails= false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        //this.showSpinnerForActionForInvoiceDetails= false;
        swal(
          "Error",
          "Server Error While Getting Truck Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };

  getUserValues() {
    this.userValueTruckDataDto = new TruckDataDto();

    this.selectedLoaderHead = $("#" + this.pageId + "loaderHead").val();
    this.selectedLoaderName = $("#" + this.pageId + "loaderStaffName").val();
    this.selectedLoaderIncharge = $("#" + this.pageId + "loaderIncharge").val();
    this.selectedRemarks = $("#" + this.pageId + "remarks").val();
    this.selectedLicNo = $("#" + this.pageId + "licenceNumber").val();
    this.selectedTruckNumber = $("#" + this.pageId + "truckNumber").val();
    this.selectedDriverName = $("#" + this.pageId + "driverName").val();

    /*if (this.modelDriverName.id != null &&
            this.modelDriverName.id != undefined) {
            this.selectedDriverId = this.modelDriverName.id;
        }*/
    this.selectedDriverId = this.onSelectEntryDriverId;
    this.selectedSupplierName = $("#" + this.pageId + "supplierName").val();
    this.selectedEntryCp = $("#" + this.pageId + "entryCP").val();
    this.selectedExitCp = $("#" + this.pageId + "exitCP").val();

    this.selectedEntryCpCode = this.onSelectEntryCpCode;
    this.selectedExitCpCode = this.onSelectExitCpCode;

    this.selectedExpExitDate = $("#" + this.pageId + "expExitDates").val();
    this.selectedExpExitDays = $("#" + this.pageId + "expExitDays").val();

    this.selectedBayNumber = $("#" + this.pageId + "bayNumber").val();
    this.selectedBayLoadingDate = $(
      "#" + this.pageId + "bayLoadingDates"
    ).val();
    this.selectedBayLoadingTime = $("#" + this.pageId + "bayLoadingTime").val();

    this.userValueTruckDataDto.loaderHead = this.selectedLoaderHead;
    this.userValueTruckDataDto.inchargeName = this.selectedLoaderIncharge;
    this.userValueTruckDataDto.loaderName = this.selectedLoaderName;
    this.userValueTruckDataDto.remarks = this.selectedRemarks;
    this.userValueTruckDataDto.licNo = this.selectedLicNo;

    //truckDtoTruck = hashMapTruck.get(listBoxTruck.getItemText(listBoxTruck.getSelectedIndex()));
    //setTimeout(() => {
    this.userValueTruckDto = new TruckDataDto();
    this.userValueTruckDto = this.hashmapTruck.get(this.selectedTruckNumber);
    //console.log(this.userValueTruckDto);

    console.log("truck No");
    console.log(this.selectedTruckNumber);
    console.log(this.userValueTruckDto);

    this.userValueTruckDataDto.truckNumber = this.selectedTruckNumber;
    this.userValueTruckDataDto.ownerName =
      this.userValueTruckDto.ownerName == null
        ? null
        : this.userValueTruckDto.ownerName;
    this.userValueTruckDataDto.panNo =
      this.userValueTruckDto.tinNo == null
        ? null
        : this.userValueTruckDto.tinNo;
    this.userValueTruckDataDto.contactPerson =
      this.userValueTruckDto.contactPerson == null
        ? null
        : this.userValueTruckDto.contactPerson;
    this.userValueTruckDataDto.address =
      this.userValueTruckDto.address == null
        ? null
        : this.userValueTruckDto.address;
    this.userValueTruckDataDto.ownerStateCode =
      this.userValueTruckDto.stateCode == null
        ? null
        : this.userValueTruckDto.stateCode;
    this.userValueTruckDataDto.ownerPincode =
      this.userValueTruckDto.pinCodeNumber == null
        ? null
        : this.userValueTruckDto.pinCodeNumber;
    // fayaz
    this.userValueTruckDataDto.trkLgth =
      this.userValueTruckDto.trkLgth == null
        ? null
        : this.userValueTruckDto.trkLgth;
    //}, 1000);

    //driverDto = hashMapDriver.get(listBoxDriver.getValue(listBoxDriver.getSelectedIndex()));
    this.userValueDriverdetailsDto = new DriverdetailsDto();
    this.userValueDriverdetailsDto = this.hashMapDriver.get(
      this.selectedDriverName
    );
    //console.log(this.userValueDriverdetailsDto);
    this.userValueTruckDataDto.driverName = this.selectedDriverName;
    this.userValueTruckDataDto.driverAdd =
      this.userValueDriverdetailsDto.address == null
        ? null
        : this.userValueDriverdetailsDto.address;
    this.userValueTruckDataDto.driverLicState =
      this.userValueDriverdetailsDto.DLIssState == null
        ? null
        : this.userValueDriverdetailsDto.DLIssState;
    this.userValueTruckDataDto.driverLicStateCode =
      this.userValueDriverdetailsDto.DLIssStateCode == null
        ? null
        : this.userValueDriverdetailsDto.DLIssStateCode;
    this.userValueTruckDataDto.driverPincode =
      this.userValueDriverdetailsDto.pin == null
        ? null
        : this.userValueDriverdetailsDto.pin;
    this.userValueTruckDataDto.driverMobNo =
      this.userValueDriverdetailsDto.mobileNo == null
        ? null
        : this.userValueDriverdetailsDto.mobileNo;
    this.userValueTruckDataDto.driverId = this.selectedDriverId;

    //supplierDto = hashMapSupplier.get(listBoxSupplier.getItemText(listBoxSupplier.getSelectedIndex()));
    this.userValueSupplierDetailsDto = new SupplierDetailsDto();
    this.userValueSupplierDetailsDto = this.hashMapSupplier.get(
      this.selectedSupplierName
    );
    this.userValueTruckDataDto.supplierName = this.selectedSupplierName;
    this.userValueTruckDataDto.suppAdd =
      this.userValueSupplierDetailsDto.address == null
        ? null
        : this.userValueSupplierDetailsDto.address;
    this.userValueTruckDataDto.suppPanNo =
      this.userValueSupplierDetailsDto.tinNumber == null
        ? null
        : this.userValueSupplierDetailsDto.tinNumber;
    this.userValueTruckDataDto.suppPhNo =
      this.userValueSupplierDetailsDto.contactNumber == null
        ? null
        : this.userValueSupplierDetailsDto.contactNumber;

    this.userValueTruckDataDto.entryCP = this.selectedEntryCp;
    this.userValueTruckDataDto.exitCP = this.selectedExitCp;
    this.userValueTruckDataDto.entryCPCode = this.selectedEntryCpCode;
    this.userValueTruckDataDto.exitCPCode = this.selectedExitCpCode;
    this.userValueTruckDataDto.expExitDate = this.selectedExpExitDate;
    this.userValueTruckDataDto.expExitDays = this.selectedExpExitDays;

    if (this.lrDtoForStockGroupingLocalStorage.truckDataDto != null) {
      this.userValueTruckDataDto.multipleSource =
        this.lrDtoForStockGroupingLocalStorage.truckDataDto.multipleSource ==
          null
          ? null
          : this.lrDtoForStockGroupingLocalStorage.truckDataDto.multipleSource;
      this.userValueTruckDataDto.multipleHamali =
        this.lrDtoForStockGroupingLocalStorage.truckDataDto.multipleHamali ==
          null
          ? null
          : this.lrDtoForStockGroupingLocalStorage.truckDataDto.multipleHamali;
    }
    this.userValueTruckDataDto.tripTo =
      this.lrDtoForStockGroupingLocalStorage.tripTo;

    if (
      $("#" + this.pageId + "bayNumber").val() != null &&
      $("#" + this.pageId + "bayNumber").val() == "Select Bay"
    ) {
      this.userValueTruckDataDto.bayNo = null;
    } else {
      this.userValueTruckDataDto.bayNo = this.selectedBayNumber;
    }

    this.userValueTruckDataDto.bayUnloadingDate = this.selectedBayLoadingDate;
    var bayLoadingTimeSplit = [];
    bayLoadingTimeSplit = this.selectedBayLoadingTime.split(":");
    //this.userValueTruckDataDto.bayUnloadingTime = this.selectedBayLoadingTime;
    this.userValueTruckDataDto.bayUnloadingTime =
      bayLoadingTimeSplit[0] + ":" + bayLoadingTimeSplit[1];
    console.log(this.lrDtoForStockGroupingLocalStorage.truckDataDto);

    return this.userValueTruckDataDto;
  }

  validateDarkNextBtn(truckMasterPopUp) {
    if (
      $("#" + this.pageId + "loaderHead").val() == null ||
      $("#" + this.pageId + "loaderHead").val() == undefined ||
      $("#" + this.pageId + "loaderHead").val() == "" ||
      $("#" + this.pageId + "loaderIncharge").val() == null ||
      $("#" + this.pageId + "loaderIncharge").val() == undefined ||
      $("#" + this.pageId + "loaderIncharge").val() == "" ||
      $("#" + this.pageId + "loaderStaffName").val() == null ||
      $("#" + this.pageId + "loaderStaffName").val() == undefined ||
      $("#" + this.pageId + "loaderStaffName").val() == "" ||
      $("#" + this.pageId + "truckNumber").val() == null ||
      $("#" + this.pageId + "truckNumber").val() == undefined ||
      $("#" + this.pageId + "truckNumber").val() == "" ||
      $("#" + this.pageId + "supplierName").val() == null ||
      $("#" + this.pageId + "supplierName").val() == undefined ||
      $("#" + this.pageId + "supplierName").val() == "" ||
      $("#" + this.pageId + "driverName").val() == null ||
      $("#" + this.pageId + "driverName").val() == undefined ||
      $("#" + this.pageId + "driverName").val() == "" ||
      $("#" + this.pageId + "bayNumber").val() == null ||
      $("#" + this.pageId + "bayNumber").val() == undefined ||
      $("#" + this.pageId + "bayNumber").val() == "Select Bay" ||
      $("#" + this.pageId + "bayNumber").val() == "" ||
      $("#" + this.pageId + "bayLoadingDates").val() == null ||
      $("#" + this.pageId + "bayLoadingDates").val() == undefined ||
      $("#" + this.pageId + "bayLoadingDates").val() == "" ||
      $("#" + this.pageId + "bayLoadingTime").val() == null ||
      $("#" + this.pageId + "bayLoadingTime").val() == undefined ||
      $("#" + this.pageId + "bayLoadingTime").val() == ""
    ) {
      swal("Mandatory Field", "Please enter the mandatory field", "warning");
      return false;
      //Imran_Chg_V1
    } else if (this.blockPanAadharValidation) {
      this.validatePanNoField(truckMasterPopUp);
    } else {
      swal("OK", "Please Click The Next Button Again To Continue", "info");
      this.nextToInvoiceDetailOfDarkBtnHideShow = false;
      this.nextToInvoiceDetailOfGreenBtnHideShow = true;
    }
  }

  validateNextMakeATrip() {
    // swal("You Are 50%  Reached");
    /* 2 nd level make a trip start */
    console.log(this.lrDtoForStockGroupingLocalStorage);
    this.lrDtoForInvoiceDetailLocalStorage = new LRDto();
    this.lrDtoForInvoiceDetailLocalStorage.branch =
      this.lrDtoForStockGroupingLocalStorage.branch;
    this.lrDtoForInvoiceDetailLocalStorage.mode =
      this.lrDtoForStockGroupingLocalStorage.mode;
    this.lrDtoForInvoiceDetailLocalStorage.invoiceNumber =
      this.lrDtoForStockGroupingLocalStorage.invoiceNumber;
    this.lrDtoForInvoiceDetailLocalStorage.guranteeWt =
      this.lrDtoForStockGroupingLocalStorage.guranteeWt;
    this.lrDtoForInvoiceDetailLocalStorage.truckDataDto = this.getUserValues();
    this.lrDtoForInvoiceDetailLocalStorage.source =
      this.lrDtoForStockGroupingLocalStorage.source;
    this.lrDtoForInvoiceDetailLocalStorage.tripTo =
      this.lrDtoForStockGroupingLocalStorage.tripTo;
    console.log(this.lrDtoForInvoiceDetailLocalStorage);

    /* 2 nd level make a trip end */

    /* 3 rd level make a trip start */
    this.viewNextDarkBtnOfInvoice = true;
    this.viewNextBtnOfInvoice = false;
    this.makeATripClearField();
    if (this.userDataDtoReturnSession.showStkGrpBins == true) {
      this.setBinsDataSpecificDetails();
    }
    /* else {
            this.getBinsDataDetails();
        }*/

    if (
      this.lrDtoForInvoiceDetailLocalStorage.mode != null &&
      this.lrDtoForInvoiceDetailLocalStorage.mode != undefined &&
      this.lrDtoForInvoiceDetailLocalStorage.mode == "hireslipGene"
    ) {
      this.callService("hireslipGene");
      this.hideshowTruckCapacity = false;
    } else {
      this.hideshowTruckCapacity = true;
    }
    /* 3 rd level make a trip end */
  }

  clearInvoiceDetailsBtn() {
    this.invoiceDetailsClearField();
  }

  invoiceDetailsClearField() {
    this.nextToInvoiceDetailOfDarkBtnHideShow = true;
    this.nextToInvoiceDetailOfGreenBtnHideShow = false;
    this.lrDtoForInvoiceDetailLocalStorage = new LRDto();

    this.selectedLoaderHead = null;
    this.selectedLoaderIncharge = null;
    this.selectedLoaderName = null;
    this.selectedRemarks = null;
    this.selectedLicNo = null;
    this.selectedTruckNumber = null;
    this.selectedDriverName = null;
    this.selectedDriverId = null;
    this.selectedSupplierName = null;
    this.selectedEntryCp = null;
    this.selectedExitCp = null;
    this.selectedEntryCpCode = null;
    this.selectedExitCpCode = null;
    this.selectedExpExitDate = null;
    this.selectedExpExitDays = null;
    this.modelExpExitDate = null;

    this.modelLoaderHead = null;
    this.modelLoaderStaffName = null;
    this.modelTruckNumber = null;
    this.modelSupplierName = null;
    this.modelDriverName = null;
    $("#" + this.pageId + "licenceNumber").val("");
    this.modelEntryCP = null;
    this.modelExitCP = null;
    $("#" + this.pageId + "expExitDays").val("");
    $("#" + this.pageId + "remarks").val("");

    this.selectedBayNumber = null;
    this.selectedBayLoadingDate = null;
    this.selectedBayLoadingTime = null;
    this.bayLoadingDateModel = null;
    $("#" + this.pageId + "bayLoadingTime").val("");
    $("#" + this.pageId + "bayLoadingDates").val("");
    $("#" + this.pageId + "bayNumber").val("Select Bay");
    this.onSelectExitCpCode = null;
    this.onSelectEntryCpCode = null;
    $("#" + this.pageId + "licenceNumber").prop("readonly", false);
    //Imran_Chg_V1
    this.isPanNoForTruckAvailable = false;
    //this.disableNextButton = false;
  }

  /* Dashboard Logic here start of pending hire slip */
  setPendingHireslipValueDetailsFromDashboard() {
    this.lrDtoForStockGroupingLocalStorage = new LRDto();
    this.lrDtoForStockGroupingLocalStorage =
      this.pendingHireslipFromLocalStorage;

    this.fromStationLocation = this.userDataDtoReturnSession.office;
    this.toStationLocation = this.lrDtoForStockGroupingLocalStorage.tripTo;

    $("#" + this.pageId + "loaderHead").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.loaderHead
    );
    $("#" + this.pageId + "loaderIncharge").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.inchargeName
    );
    $("#" + this.pageId + "loaderStaffName").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.loadedby
    );
    //ASR_V1_CHG
    //$("#" + this.pageId + "truckNumber").val(this.pendingHireslipFromLocalStorage.truckDataDto.vehicleNumber);
    $("#" + this.pageId + "truckNumberSearchId").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.vehicleNumber
    );
    this.getTruckMasterDetailsSingle();
    //
    $("#" + this.pageId + "supplierName").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.supplierName
    );
    $("#" + this.pageId + "driverName").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.driverName
    );
    $("#" + this.pageId + "licenceNumber").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.driverLicenseNo
    );
    $("#" + this.pageId + "entryCP").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.entryCP
    );
    $("#" + this.pageId + "exitCP").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.exitCP
    );
    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.expExitDate != null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.expExitDate != undefined
    ) {
      $("#" + this.pageId + "expExitDates").val(
        moment(
          this.pendingHireslipFromLocalStorage.truckDataDto.expExitDate
        ).format("YYYY-MM-DD")
      );
    }
    $("#" + this.pageId + "remarks").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.remarks
    );
    $("#" + this.pageId + "expExitDays").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.expExitDays
    );
    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayNo != null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayNo !=
      undefined
    ) {
      $("#" + this.pageId + "bayNumber").val(
        this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayNo
      );
    } else {
      $("#" + this.pageId + "bayNumber").val("Select Bay");
    }

    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayDate !=
      null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayDate !=
      undefined
    ) {
      $("#" + this.pageId + "bayLoadingDates").val(
        this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayDate
      );
    }
    $("#" + this.pageId + "bayLoadingTime").val(
      this.pendingHireslipFromLocalStorage.truckDataDto.loadingBayTime
    );
    this.onSelectEntryDriverId = null;
    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.driverId != null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.driverId != undefined
    ) {
      this.onSelectEntryDriverId =
        this.pendingHireslipFromLocalStorage.truckDataDto.driverId;
    }

    this.onSelectEntryCpCode = null;
    this.onSelectExitCpCode = null;
    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.entryCPCode != null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.entryCPCode != undefined
    ) {
      this.onSelectEntryCpCode =
        this.pendingHireslipFromLocalStorage.truckDataDto.entryCPCode;
    }
    if (
      this.pendingHireslipFromLocalStorage.truckDataDto.exitCPCode != null &&
      this.pendingHireslipFromLocalStorage.truckDataDto.exitCPCode != undefined
    ) {
      this.onSelectExitCpCode =
        this.pendingHireslipFromLocalStorage.truckDataDto.exitCPCode;
    }
    console.log(this.pendingHireslipFromLocalStorage);
    console.log(this.lrDtoForStockGroupingLocalStorage);
  }
  /* 2 nd level main invoice details end */

  /* 3 rd level main make a trip details start */

  clickListnerForTruckCapacity(e: NgbTypeaheadSelectItemEvent) {
    this.modelTruckCapacity = e.item;
    $("#" + this.pageId + "truckCapacity").val(
      this.modelTruckCapacity.capacity
    );
    /* this.lrDtoMakeATripLocalStorage = JSON.parse(localStorage.getItem('makeATripGenLocalStorage'));
         if (this.lrDtoMakeATripLocalStorage != null) {
             this.lrDtoLocalStorage = this.lrDtoMakeATripLocalStorage;
         }*/
    this.callService("filtered");
  }

  getTruckCapacityList() {
    this.truckDataDtoForTruckCapacity = new TruckDataDto();
    this.truckDataDtoForTruckCapacity.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  getTruckCapacityDetails = () => {
    this.getTruckCapacityList();
    this.masterReadService
      .getTruckCapacityDetails(this.truckDataDtoForTruckCapacity)
      .subscribe((response) => {
        if (response.length == 0) {
          this.truckCapacityOptions = [];
          this.truckCapacityTA = [];
          swal("Warning", "No Truck Capacity records found!", "warning");
        } else {
          this.truckCapacityOptions = [];
          this.truckCapacityTA = [];

          this.truckCapacityOptions = response;
          for (let i = 0; i < this.truckCapacityOptions.length; i++) {
            this.truckCapacityTA.push(this.truckCapacityOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Truck Capacity Details",
          "error"
        );
      },
      () => console.log("done");
  };

  getBinsData() {
    this.lrDtoBins = new LRDto();
    this.lrDtoBins.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoBins.branch = this.userDataDtoReturnSession.office;
    this.lrDtoBins.mode = "filtered";
  }

  getBinsDataDetails = () => {
    this.getBinsData();
    this.masterReadService
      .getBranchSubstations(this.lrDtoBins)
      .subscribe((response) => {
        if (response.length == 0) {
          this.binsOptions = [];
          this.controlBinTA = [];
          swal("Warning", "No Bins records found!", "warning");
        } else {
          this.binsOptions = [];
          this.controlBinTA = [];
          this.binsOptions = response;
          for (let i = 0; i < this.binsOptions.length; i++) {
            //this.controlBinTA.push( this.binsOptions[i].subStations );
            this.hashmapBins.set(
              this.binsOptions[i].subStations,
              this.binsOptions[i].stationType
            );
            console.log(this.lrDtoForInvoiceDetailLocalStorage.branch);
            if (
              !(
                this.binsOptions[i].subStations ==
                this.lrDtoForInvoiceDetailLocalStorage.branch
              )
            ) {
              if (
                !(
                  this.binsOptions[i].subStations == "Bangalore Delivery Godown"
                )
              ) {
                this.controlBinTA.push(this.binsOptions[i]);
              }
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Branch SubStations Details",
          "error"
        );
      },
      () => console.log("done");
  };

  clickListnerForControlBins(e: NgbTypeaheadSelectItemEvent) {
    this.modelControlBins = e.item;
    //console.log(this.modelControlBins);
    $("#" + this.pageId + "controlBins").val(this.modelControlBins.subStations);
    this.setControlBinsDest();
  }

  getBinsDataSpecific() {
    this.lrdDtoBinSpecific = new LRDto();
    this.lrdDtoBinSpecific.fromStation = this.userDataDtoReturnSession.office;
    this.lrdDtoBinSpecific.invoiceDL =
      this.lrDtoForInvoiceDetailLocalStorage.branch;
  }
  setBinsDataSpecificDetails() {
    this.getBinsDataSpecific();
    if (this.lrdDtoBinSpecific.invoiceDL != null) {
      this.showSpinnerActionForMakeATrip = true;
      this.masterReadService
        .getTripRoutingFeatureDetails(this.lrdDtoBinSpecific)
        .subscribe((response) => {
          //console.log("DATA");
          //console.log(response);
          if (response.length == 0) {
            this.binsOptions = [];
            this.controlBinTA = [];
            swal("Warning", "No Bins records found!", "warning");
          } else {
            this.binsOptions = [];
            this.controlBinTA = [];
            this.binsOptions = response;

            //All Need to update option here
            this.lrdDtoBinSpecificAll = new LRDto();
            this.lrdDtoBinSpecificAll.subStations = "All";
            this.controlBinTA.push(this.lrdDtoBinSpecificAll);
            for (let i = 0; i < this.binsOptions.length; i++) {
              this.hashmapBins.set(
                this.binsOptions[i].subStations,
                this.binsOptions[i].stationType
              );
              this.controlBinTA.push(this.binsOptions[i]);
            }
          }
          this.showSpinnerActionForMakeATrip = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerActionForMakeATrip = false;
          swal(
            "Error",
            "Server Error While Getting Trip Routing Feature Details",
            "error"
          );
        },
        () => console.log("done");
    }
  }

  callService(mode) {
    this.lrDtoCallService = new LRDto();
    this.truckCapcityLoadValue = null;

    this.lrDtoCallService.destination =
      this.lrDtoForInvoiceDetailLocalStorage.branch;
    this.lrDtoCallService.branch = this.userDataDtoReturnSession.office;
    this.lrDtoCallService.branchType = this.userDataDtoReturnSession.role;
    if (
      this.lrDtoForInvoiceDetailLocalStorage.branch != null &&
      this.lrDtoForInvoiceDetailLocalStorage.branch != undefined
    ) {
      this.hashmapBinGetDest = null;
      this.hashmapBinGetDest = this.hashmapBins.get(
        this.lrDtoForInvoiceDetailLocalStorage.branch
      );
      this.lrDtoCallService.stationType = this.hashmapBinGetDest;
    }
    this.lrDtoCallService.invoiceNumber =
      this.lrDtoForInvoiceDetailLocalStorage.invoiceNumber;
    this.lrDtoCallService.stocksAt = this.userDataDtoReturnSession.mainStation;

    this.lrDtoCallService.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoCallService.mode = mode;
    this.lrDtoCallService.bayFlow = this.bayFlow;
    // if (mode == "filtered" && this.hideshowControlsBins == true) {
    if (mode == "filtered") {
      // lrDto.setVehiCapacity(Double.valueOf(comboBox.getRawValue()) * 1000);
      this.truckCapcityLoadValue = $("#" + this.pageId + "truckCapacity").val();
      this.lrDtoCallService.vehiCapacity = this.truckCapcityLoadValue * 1000;
    }
    //console.log(this.lrDtoCallService);
    /*if(this.rightsForOfficeToDestStocksForInside == "office to show truck capacity dest stock for inside" && 
        this.lrDtoLocalStorage.branch =="Konditope"){*/
    if (
      this.userDataDtoReturnSession.office == "Binnymills" &&
      this.lrDtoForInvoiceDetailLocalStorage.branch == "Konditope"
    ) {
      this.showSpinnerActionForMakeATrip = true;
      this.stockService
        .getDestStocksForInside(this.lrDtoCallService)
        .subscribe((response) => {
          //console.log(response);
          for (let key in response) {
            if (key == "EligibleLRs") {
              this.eligibleLrsDataList = [];
              $("#" + this.pageId + "eligibleLrsTableId")
                .DataTable()
                .destroy();
              this.eligibleLrsDataList = response[key];
              this.dtTriggerEligibleLrs.next();
            } else if (key == "RemainingLRs") {
              this.remainingLrsDataList = [];
              $("#" + this.pageId + "remainingLrsTableId")
                .DataTable()
                .destroy();
              this.remainingLrsDataList = response[key];
              this.dtTriggerRemainingLrs.next();
            }
          }
          this.showSpinnerActionForMakeATrip = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerActionForMakeATrip = false;
          swal(
            "Error",
            "Server Error While Getting Dest Stocks For Inside Details",
            "error"
          );
        },
        () => console.log("done");
    } else {
      this.showSpinnerActionForMakeATrip = true;
      this.stockService
        .getDestStocks(this.lrDtoCallService)
        .subscribe((response) => {
          //console.log(response);
          for (let key in response) {
            //console.log(response[key]);
            if (key == "EligibleLRs") {
              this.eligibleLrsDataList = [];
              $("#" + this.pageId + "eligibleLrsTableId")
                .DataTable()
                .destroy();
              this.eligibleLrsDataList = response[key];
              this.dtTriggerEligibleLrs.next();
            } else if (key == "RemainingLRs") {
              this.remainingLrsDataList = [];
              $("#" + this.pageId + "remainingLrsTableId")
                .DataTable()
                .destroy();
              this.remainingLrsDataList = response[key];
              this.dtTriggerRemainingLrs.next();
            } else if (key == "EwayBlockLRs") {
              if (this.userDataDtoReturnSession.ewayBillAlert) {
                this.ewayBillAlertBlockMsg(response[key]);
              }
            }
          }
          this.showSpinnerActionForMakeATrip = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerActionForMakeATrip = false;
          swal(
            "Error",
            "Server Error While Getting Dest Stocks Details",
            "error"
          );
        },
        () => console.log("done");
    }
  }

  setControlBinsDest() {
    this.lrDtoControlBinService = new LRDto();
    this.truckCapcityLoadValue = null;
    this.controlBinsDestValue = null;
    this.stationType = null;

    this.truckCapcityLoadValue = $("#" + this.pageId + "truckCapacity").val();
    this.controlBinsDestValue = $("#" + this.pageId + "controlBins").val();
    this.lrDtoControlBinService.destination = this.controlBinsDestValue;
    this.lrDtoControlBinService.branch = this.userDataDtoReturnSession.office;
    this.lrDtoControlBinService.branchType = this.userDataDtoReturnSession.role;
    this.lrDtoControlBinService.mode = "ALL";

    if (this.controlBinsDestValue == "Others") {
      this.lrDtoControlBinService.stationType =
        this.userDataDtoReturnSession.stationType;
    } else {
      this.stationType = this.hashmapBins.get(this.controlBinsDestValue);
      this.lrDtoControlBinService.stationType = this.stationType;
    }

    if (this.truckCapcityLoadValue != "") {
      this.truckCapcityLoadValue = $("#" + this.pageId + "truckCapacity").val();
      this.lrDtoControlBinService.vehiCapacity =
        this.truckCapcityLoadValue * 1000;
    }
    this.showSpinnerActionForMakeATrip = true;
    this.stockService
      .getDestStocks(this.lrDtoControlBinService)
      .subscribe((response) => {
        //console.log(response);
        for (let key in response) {
          if (key == "AllLRs") {
            this.selectedControBinsDataOfAllLrsList = response[key];
          }
        }

        this.remainingLrsDataList = [];
        if (
          this.selectedControBinsDataOfAllLrsList != null &&
          this.selectedControBinsDataOfAllLrsList.length > 0
        ) {
          this.remainingLrsDataList = this.selectedControBinsDataOfAllLrsList;
        }
        console.log(this.remainingLrsDataList);
        console.log(this.eligibleLrsDataList);
        this.remainingLrsDataList = this.remainingLrsDataList.filter(
          (remainingLrsData) =>
            !this.eligibleLrsDataList.find(
              (eligibleLrsData) =>
                eligibleLrsData.lrNumber === remainingLrsData.lrNumber
            )
        );

        console.log(this.remainingLrsDataList);
        $("#" + this.pageId + "remainingLrsTableId")
          .DataTable()
          .destroy();
        this.dtTriggerRemainingLrs.next();
        this.showSpinnerActionForMakeATrip = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerActionForMakeATrip = false;
        swal(
          "Error",
          "Server Error While Getting Dest Stocks Details",
          "error"
        );
      },
      () => console.log("done");
  }

  keyEventHandlerLRNumber(event) {
    if (event == 13) {
      this.setLRNumberDetailsValueInDatatTable();
    }
  }

  setLRNumberDetailsValueInDatatTable() {
    this.lrNumber = null;
    this.lrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrNumber = this.lrNumber == null ? null : this.lrNumber.toUpperCase();

    //Asrar_Jr_Chg_V6
    this.prefixLrNumber = $("#" + this.pageId + "lrNumberPrefix").val();
    this.lrNumber = this.prefixLrNumber + this.lrNumber;
    console.log(this.lrNumber);
    //alert(this.lrNumber);
    // finding eligible & remaing lrs method
    var validateRemaingLrNumber = this.findRemainingLrsDup(
      this.remainingLrsDataList,
      this.lrNumber
    );
    var validateEligibleLrNumber = this.findEligibleLrs(
      this.eligibleLrsDataList,
      this.lrNumber
    );
    console.log("EligibleFind: " + validateEligibleLrNumber);
    console.log("RemaingFind: " + validateRemaingLrNumber);
    if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
      this.lrDtoForCheckDestination = new LRDto();
      this.lrDtoForCheckDestination.source =
        this.userDataDtoReturnSession.office;
      this.lrDtoForCheckDestination.lrNumber = this.lrNumber;
      this.lrDtoForCheckDestination.companyId =
        this.userDataDtoReturnSession.companyId;
      this.showSpinnerActionForMakeATrip = true;
      this.stockService
        .getLRNewTrip(this.lrDtoForCheckDestination)
        .subscribe((response) => {
          //console.log(response);
          if (response.destination == null) {
            swal("Alert", "LR number does not exist", "warning");
          } else {
            swal(
              "Alert",
              "This LR number not exist or not belongs to '" +
              response.destination +
              "'",
              "warning"
            );
            $("#" + this.pageId + "lrNumber").val("");
            if (
              this.userDataDtoReturnSession.mainAdminStation != null &&
              this.userDataDtoReturnSession.mainAdminStation == "Mumbai"
            ) {
              $("#" + this.pageId + "lrNumber").val("SRD");
            }
          }
          this.showSpinnerActionForMakeATrip = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerActionForMakeATrip = false;
          swal(
            "Error",
            "Server Error While Getting LR NewTrip Details",
            "error"
          );
        },
        () => console.log("done");
    } else if (validateEligibleLrNumber != null) {
      swal(
        "Make Invoice",
        "LRNumber '" + this.lrNumber + "'Already Transfered",
        "warning"
      );
    } else {
      this.setLrNumberEligibleAndRemaingLrs();
    }
  }

  findRemainingLrsDup(findRemaingLrs, lrNumber) {
    var checkingRemLrNumber = null;
    var remainLrNumberReturn = null;
    for (let i = 0; i < findRemaingLrs.length; i++) {
      checkingRemLrNumber = findRemaingLrs[i].lrNumber.toUpperCase();
      if (checkingRemLrNumber == lrNumber) {
        remainLrNumberReturn = checkingRemLrNumber;
        break;
      } else {
        remainLrNumberReturn = null;
      }
    }
    return remainLrNumberReturn;
  }

  findEligibleLrs(findEligibleLrs, lrNumber) {
    var checkingEligLrNumber = null;
    var eligibleLrNumberReturn = null;
    for (let i = 0; i < findEligibleLrs.length; i++) {
      checkingEligLrNumber = findEligibleLrs[i].lrNumber.toUpperCase();
      if (checkingEligLrNumber == lrNumber) {
        eligibleLrNumberReturn = checkingEligLrNumber;
        break;
      } else {
        eligibleLrNumberReturn = null;
      }
    }
    return eligibleLrNumberReturn;
  }

  setLrNumberEligibleAndRemaingLrs() {
    this.resultList = null;
    this.lrNumber = null;
    this.lrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrNumber = this.lrNumber == null ? null : this.lrNumber.toUpperCase();

    //Asrar_Jr_Chg_V6
    this.prefixLrNumber = $("#" + this.pageId + "lrNumberPrefix").val();
    this.lrNumber = this.prefixLrNumber + this.lrNumber;

    this.showSpinnerActionForMakeATrip = true;
    this.resultList = this.remainingLrsDataList.filter(
      (remainingLrsData) => remainingLrsData.lrNumber == this.lrNumber
    );
    if (this.resultList.length > 0) {
      for (let i = 0; i < this.resultList.length; i++) {
        this.eligibleLrsDataList.push(this.resultList[i]);
      }
      $("#" + this.pageId + "eligibleLrsTableId")
        .DataTable()
        .destroy();
      //Imran_SG_Chg_V2
      // setTimeout(() => {
      this.dtTriggerEligibleLrs.next();
      // }, 1000);

      const deletedContrat = this.remainingLrsDataList.find(
        (x) => x.lrNumber === this.lrNumber
      );
      this.remainingLrsDataList.splice(
        this.remainingLrsDataList.indexOf(deletedContrat),
        1
      );
      $("#" + this.pageId + "remainingLrsTableId")
        .DataTable()
        .destroy();
      //Imran_SG_Chg_V2
      // setTimeout(() => {
      this.dtTriggerRemainingLrs.next();
      // }, 1000);
      // console.log(deletedContrat);
      $("#" + this.pageId + "lrNumber").val("");
    }
    this.showSpinnerActionForMakeATrip = false;
  }

  clearBtnEligibleLrs() {
    $("#" + this.pageId + "eligibleLrsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerEligibleLrs.next();
    $("#" + this.pageId + "remainingLrsTableId")
      .DataTable()
      .destroy();
    for (let i = 0; i < this.eligibleLrsDataList.length; i++) {
      this.remainingLrsDataList.push(this.eligibleLrsDataList[i]);
    }
    this.eligibleLrsDataList = [];
    this.dtTriggerRemainingLrs.next();
  }

  resetDatatableBtn() {
    this.eligibleLrsDataList = [];
    $("#" + this.pageId + "eligibleLrsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerEligibleLrs.next();
    this.remainingLrsDataList = [];
    $("#" + this.pageId + "remainingLrsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerRemainingLrs.next();

    if (
      this.lrDtoForInvoiceDetailLocalStorage.mode != null &&
      this.lrDtoForInvoiceDetailLocalStorage.mode != undefined &&
      this.lrDtoForInvoiceDetailLocalStorage.mode == "hireslipGene"
    ) {
      this.callService("hireslipGene");
    }
  }

  rowSelectedEligibleLrDelete(selectedEligibleLrsRowData) {
    //Imran_SG_Chg_V4
    if ((this.userDataDtoReturnSession.newPartyInvBlock) && selectedEligibleLrsRowData.remarks != null && selectedEligibleLrsRowData.remarks == 'NW PRTY') {
      swal({
        title: "Not Allowed!",
        text: "You can't remove New Party Lr's!Please contact Admin",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      this.enteredLrNumber = null;
      this.enteredLrNumber = selectedEligibleLrsRowData.lrNumber;
      this.enteredLrNumber =
        this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

      // finding eligible & remaing lrs method
      this.showSpinnerActionForMakeATrip = true;
      var validateRemaingLrNumber = this.findRemainingLrsDup(
        this.remainingLrsDataList,
        this.enteredLrNumber
      );
      var validateEligibleLrNumber = this.findEligibleLrs(
        this.eligibleLrsDataList,
        this.enteredLrNumber
      );
      this.showSpinnerActionForMakeATrip = false;
      console.log("EligibleFind: " + validateEligibleLrNumber);
      console.log("RemaingFind: " + validateRemaingLrNumber);
      if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
        swal("Alert", "LR number does not exist", "warning");
      } else if (validateRemaingLrNumber != null) {
        swal(
          "Make Invoice",
          "LR Number '" +
          selectedEligibleLrsRowData.lrNumber +
          "'Already Transfered",
          "warning"
        );
      } else {
        this.resultList = null;
        this.showSpinnerActionForMakeATrip = true;
        this.resultList = this.eligibleLrsDataList.filter(
          (eligibleLrsData) =>
            eligibleLrsData.lrNumber == selectedEligibleLrsRowData.lrNumber
        );
        if (this.resultList.length > 0) {
          for (let i = 0; i < this.resultList.length; i++) {
            this.remainingLrsDataList.push(this.resultList[i]);
          }
          $("#" + this.pageId + "remainingLrsTableId")
            .DataTable()
            .destroy();
          //Imran_SG_Chg_V2
          // setTimeout(() => {
          this.dtTriggerRemainingLrs.next();
          // }, 1000);

          const deletedContrat = this.eligibleLrsDataList.find(
            (x) => x.lrNumber === selectedEligibleLrsRowData.lrNumber
          );
          this.eligibleLrsDataList.splice(
            this.eligibleLrsDataList.indexOf(deletedContrat),
            1
          );
          $("#" + this.pageId + "eligibleLrsTableId")
            .DataTable()
            .destroy();
          //Imran_SG_Chg_V2
          // setTimeout(() => {
          this.dtTriggerEligibleLrs.next();
          // }, 1000);
        }
        //Imran_SG_Chg_V2
        this.viewNextDarkBtnOfInvoice = true;
        this.viewNextBtnOfInvoice = false;
        this.showSpinnerActionForMakeATrip = false;

      }
    }
  }

  validateMoveAllBtnForMakeATrip() {
    //console.log(this.remainingLrsDataList);
    //console.log(this.remainingLrsDataList.length);
    this.showSpinnerActionForMakeATrip = true;
    for (let i = 0; i < this.remainingLrsDataList.length; i++) {
      this.eligibleLrsDataList.push(this.remainingLrsDataList[i]);
    }
    $("#" + this.pageId + "eligibleLrsTableId")
      .DataTable()
      .destroy();
    // setTimeout(() => {
    this.dtTriggerEligibleLrs.next();
    // }, 1000);
    this.remainingLrsDataList = [];
    $("#" + this.pageId + "remainingLrsTableId")
      .DataTable()
      .destroy();
    // setTimeout(() => {
    this.dtTriggerRemainingLrs.next();
    // }, 1000);
    this.showSpinnerActionForMakeATrip = false;
  }

  makeATripResetField() {
    this.remainingLrsDataList = [];
    this.eligibleLrsDataList = [];
    $("#" + this.pageId + "eligibleLrsTableId")
      .DataTable()
      .destroy();
    $("#" + this.pageId + "remainingLrsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerEligibleLrs.next();
    this.dtTriggerRemainingLrs.next();
    $("#" + this.pageId + "truckCapacity").val("");
    $("#" + this.pageId + "controlBins").val("");
    $("#" + this.pageId + "lrNumber").val("");

    this.modelTruckCapacity = null;
    this.modelControlBins = null;
    this.hashmapBinGetDest = null;
    this.truckCapcityLoadValue = null;
    this.controlBinsDestValue = null;
    this.stationType = null;
    this.lrNumber = null;
    this.resultList = null;
    this.enteredLrNumber = null;

    if (this.userDataDtoReturnSession.showStkGrpBins == true) {
      this.hideshowControlsBins = true;
    }

    this.viewNextDarkBtnOfInvoice = true;
    this.viewNextBtnOfInvoice = false;
  }
  makeATripClearField() {
    console.log(this.eligibleLrsDataList);
    if (
      this.eligibleLrsDataList == null ||
      this.eligibleLrsDataList == undefined ||
      this.eligibleLrsDataList.length == 0
    ) {
      this.remainingLrsDataList = [];
      this.eligibleLrsDataList = [];
      $("#" + this.pageId + "eligibleLrsTableId")
        .DataTable()
        .destroy();
      $("#" + this.pageId + "remainingLrsTableId")
        .DataTable()
        .destroy();
      this.dtTriggerEligibleLrs.next();
      this.dtTriggerRemainingLrs.next();
    }

    $("#" + this.pageId + "truckCapacity").val("");
    $("#" + this.pageId + "controlBins").val("");
    $("#" + this.pageId + "lrNumber").val("");

    this.modelTruckCapacity = null;
    this.modelControlBins = null;
  }

  backBtnOfMakeAtrip() {
    if (
      this.eligibleLrsDataList == null ||
      this.eligibleLrsDataList == undefined ||
      this.eligibleLrsDataList.length == 0
    ) {
      swal("Not Allowed", "No Lrs Found", "warning");
      return false;
    } else {
      this.nextToInvoiceDetailOfDarkBtnHideShow = true;
      this.nextToInvoiceDetailOfGreenBtnHideShow = false;
    }
  }

  validateNextDarkBtnForInvoiceDetails() {
    if (
      this.eligibleLrsDataList == null ||
      this.eligibleLrsDataList == undefined ||
      this.eligibleLrsDataList.length == 0
    ) {
      swal("Not Allowed", "No Lrs Found", "warning");
      return false;
    } else {
      //Imran_SG_Chg_V2
      var lrNumbers = [];
      var lrNumberView = null;
      var count = 0;

      for (let i = 0; i < this.remainingLrsDataList.length; i++) {
        if (this.remainingLrsDataList[i].remarks != null && this.remainingLrsDataList[i].remarks == 'NW PRTY') {

          lrNumbers.push(this.remainingLrsDataList[i].lrNumber);
          if (count == 0) {
            lrNumberView = this.remainingLrsDataList[i].lrNumber;
          } else {
            lrNumberView += ", " + this.remainingLrsDataList[i].lrNumber;
          }
          count++;

        }

      }
      console.log(lrNumbers);
      if (
        this.userDataDtoReturnSession.newPartyInvBlock &&
        this.lrDtoForStockGroupingLocalStorage.invoiceNumber == null &&
        lrNumbers != null &&
        lrNumbers.length > 0
      ) {
        swal({
          title:
            "Not Allowed! Below new party Lr's must load in the invoice. Total:" +
            count,
          text: "LR Number : " + lrNumbers,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
        return false;
      } else {
        this.viewNextDarkBtnOfInvoice = false;
        this.viewNextBtnOfInvoice = true;
        swal("OK", "Please Click The Next Button Again To Continue", "info");
      }


    }
  }

  validateNextBtnForInvoiceDetails() {
    console.log(this.eligibleLrsDataList);
    this.lrDtoInvoiceLocalStorage = new LRDto();
    this.truckCapcityLoadValue = null;
    this.truckCapcityLoadValue = $("#" + this.pageId + "truckCapacity").val();

    //this.lrDtoEligibleList
    this.lrDtoInvoiceLocalStorage.lrDtoEligible = this.eligibleLrsDataList;
    this.lrDtoInvoiceLocalStorage.branch =
      this.lrDtoForInvoiceDetailLocalStorage.branch == null
        ? null
        : this.lrDtoForInvoiceDetailLocalStorage.branch;
    this.lrDtoInvoiceLocalStorage.lrDtoRemains = this.remainingLrsDataList;
    this.lrDtoInvoiceLocalStorage.guranteeWt =
      this.lrDtoForInvoiceDetailLocalStorage.guranteeWt;
    this.lrDtoInvoiceLocalStorage.invoiceNumber =
      this.lrDtoForInvoiceDetailLocalStorage.invoiceNumber;
    if (
      this.truckCapcityLoadValue != null &&
      this.truckCapcityLoadValue != undefined
    ) {
      this.lrDtoInvoiceLocalStorage.loadingChg = this.truckCapcityLoadValue;
    }
    this.lrDtoInvoiceLocalStorage.truckDataDto =
      this.lrDtoForInvoiceDetailLocalStorage.truckDataDto;
    this.lrDtoInvoiceLocalStorage.source =
      this.lrDtoForInvoiceDetailLocalStorage.source;
    console.log(this.lrDtoInvoiceLocalStorage);

    //this.invoiceClearField();
    this.setOnloadOfInvoiceDetailsData();
  }

  /* 3 rd level main make a trip details end */

  /* 4 th level main Invoice start */

  invoiceClearField() {
    this.invoiceDataList = [];
    $("#" + this.pageId + "invoiceTableId")
      .DataTable()
      .destroy();
    this.dtTriggerInvoice.next();

    if (this.userDataDtoReturnSession.officeType != "Booking Office") {
      this.viewCheckBoxInvoiceDummyLr = true;
    } else {
      this.viewCheckBoxInvoiceDummyLr = false;
    }
  }

  backBtnOfInvoice() {
    this.viewNextDarkBtnOfInvoice = true;
    this.viewNextBtnOfInvoice = false;
  }

  setOnloadOfInvoiceDetailsData() {
    //console.log("HAI");
    //console.log(this.lrDtoInvoiceLocalStorage.lrDtoEligible);
    this.invoiceDataList = [];
    $("#" + this.pageId + "invoiceTableId")
      .DataTable()
      .destroy();
    for (
      let i = 0;
      i < this.lrDtoInvoiceLocalStorage.lrDtoEligible.length;
      i++
    ) {
      this.invoiceDataList.push(this.lrDtoInvoiceLocalStorage.lrDtoEligible[i]);
    }
    this.listMaintenancesHideDummyInvoice = [];
    for (let i = 0; i < this.invoiceDataList.length; i++) {
      if (this.invoiceDataList[i].lrDummyInv) {
        this.listMaintenancesHideDummyInvoice.push(this.invoiceDataList[i]);
      }
    }
    console.log(this.listMaintenancesHideDummyInvoice);
    setTimeout(() => {
      this.dtTriggerInvoice.next();
    }, 1000);
    //this.ftHamali = true;
    //this.setLdgHamaliValues();
  }
  checkSelectedLrDummtInvoice(event, invoiceData, index) {
    if (event.target.checked == true) {
      if (
        this.listMaintenancesHideDummyInvoice != null &&
        this.listMaintenancesHideDummyInvoice != undefined &&
        this.listMaintenancesHideDummyInvoice.length > 0
      ) {
        this.listMaintenancesHideDummyInvoice.push(invoiceData);
      } else {
        this.listMaintenancesHideDummyInvoice = [];
        this.listMaintenancesHideDummyInvoice.push(invoiceData);
      }
    } else {
      const deletedContrat = this.listMaintenancesHideDummyInvoice.find(
        (x) => x.lrNumber === invoiceData.lrNumber
      );
      this.listMaintenancesHideDummyInvoice.splice(
        this.listMaintenancesHideDummyInvoice.indexOf(deletedContrat),
        1
      );
      //console.log(deletedContrat);
    }
    //console.log(this.listMaintenancesHideDummyInvoice);
  }

  printLoadingSheet() {
    this.listOfLrDto = [];
    this.listOfLrs = new Array();
    this.listOfTotalLrs = new Array();
    this.listOfLrShortage = new Array();
    this.totalArticles = 0;
    for (
      let i = 0;
      i < this.lrDtoInvoiceLocalStorage.lrDtoEligible.length;
      i++
    ) {
      this.lrDtoForInvoiceLoadingPrint = new LRDto();
      this.lrDtoForInvoiceLoadingPrint.lrNumber =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber;
      this.lrDtoForInvoiceLoadingPrint.totalArticles =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].totalArticles;
      this.lrDtoForInvoiceLoadingPrint.bookingDateStr =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].bookingDateStr;
      this.lrDtoForInvoiceLoadingPrint.actualWeight =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].actualWeight;
      this.lrDtoForInvoiceLoadingPrint.chargedWeight =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].chargedWeight;
      this.lrDtoForInvoiceLoadingPrint.toPay =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].toPay;
      this.lrDtoForInvoiceLoadingPrint.agentName =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].agentName;
      this.lrDtoForInvoiceLoadingPrint.privateMarker =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].privateMarker;
      this.lrDtoForInvoiceLoadingPrint.isHandWithCare =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].isHandWithCare;
      //COD
      this.lrDtoForInvoiceLoadingPrint.isCod =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].isCod;
      this.lrDtoForInvoiceLoadingPrint.isNewConsignee =
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].isNewConsignee;
      this.listOfTotalLrs.push(
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
      );
      // avoid shortage Lrs
      if (
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status != null &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status == "Short" &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage > 0
      ) {
        this.listOfLrShortage.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber +
          "-" +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage
        );
      } else {
        this.listOfLrs.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
        );
        this.totalArticles =
          this.totalArticles +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].totalArticles;
        // Avoid Other booking LR
        //this.listOfLrShortage.push("1"); // for check we put here its updating are not
        /* if (this.userDataDtoReturnSession.officeType == "Booking Office") {
                     if (this.userDataDtoReturnSession.office == this.lrDtoInvoiceLocalStorage.source) {
                         if (this.userDataDtoReturnSession.office == this.lrDtoInvoiceLocalStorage.source) {
                             this.listOfLrDto.push(this.lrDtoForInvoiceLoadingPrint);
                         }
                     } else {
                         this.listOfLrDto.push(this.lrDtoForInvoiceLoadingPrint);
                     }
                 }*/
        if (this.userDataDtoReturnSession.officeType == "Booking Office") {
          //if (this.userDataDtoReturnSession.office == this.lrDtoInvoiceLocalStorage.source) {
          if (
            this.userDataDtoReturnSession.office ==
            this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].source
          ) {
            this.listOfLrDto.push(this.lrDtoForInvoiceLoadingPrint);
          }
        } else {
          this.listOfLrDto.push(this.lrDtoForInvoiceLoadingPrint);
        }
      }
    }

    this.invoiceDtoForLoadingSheetAndHireslip =
      this.getInvoiceDtoForLoadingSheetAndHireSlip();
    this.invoiceDtoForLoadingSheetAndHireslip.vehicleNumber =
      this.lrDtoInvoiceLocalStorage.truckDataDto.truckNumber;
    /*if(this.lrDtoForLocalStorage.branch !=null && this.lrDtoForLocalStorage.branch =="Godown Delivery" &&
         this.rightsForLoadingSheetOffice == "rights for loading sheet print office"){
         */
    if (
      this.lrDtoInvoiceLocalStorage.branch != null &&
      this.lrDtoInvoiceLocalStorage.branch == "Godown Delivery" &&
      this.userDataDtoReturnSession.office == "Binnymills"
    ) {
      this.invoiceDtoForLoadingSheetAndHireslip.toStation = "konditope";
    } else {
      this.invoiceDtoForLoadingSheetAndHireslip.toStation =
        this.lrDtoInvoiceLocalStorage.truckDataDto.tripTo;
    }
    this.invoiceDtoForLoadingSheetAndHireslip.truckDataDto =
      this.lrDtoInvoiceLocalStorage.truckDataDto;

    this.getLdgHamaliValues(this.invoiceDtoForLoadingSheetAndHireslip);
    this.callInvService(
      this.invoiceDtoForLoadingSheetAndHireslip,
      this.listOfLrDto
    );
  }

  getInvoiceDtoForLoadingSheetAndHireSlip() {
    this.invoiceDtoForLoadingSheetAndHireslip = new InvoiceDto();
    this.invoiceDtoForLoadingSheetAndHireslip.invoiceNumber =
      this.lrDtoInvoiceLocalStorage.invoiceNumber;
    this.invoiceDtoForLoadingSheetAndHireslip.listTotalLrs =
      this.listOfTotalLrs;
    this.invoiceDtoForLoadingSheetAndHireslip.list = this.listOfLrs;
    this.invoiceDtoForLoadingSheetAndHireslip.listLrsShortage =
      this.listOfLrShortage;
    this.invoiceDtoForLoadingSheetAndHireslip.totalLRs = this.listOfLrs.length;
    this.invoiceDtoForLoadingSheetAndHireslip.fromStation =
      this.lrDtoInvoiceLocalStorage.source;
    //PENDING NEED TO WORK
    //this.invoiceDto.loadedby = txtLoadedBy.getValue();
    this.invoiceDtoForLoadingSheetAndHireslip.enteredby =
      this.userDataDtoReturnSession.userId;
    this.invoiceDtoForLoadingSheetAndHireslip.lastupdatedby =
      this.userDataDtoReturnSession.userId;
    this.invoiceDtoForLoadingSheetAndHireslip.financialYear =
      this.userDataDtoReturnSession.financialYear;
    this.invoiceDtoForLoadingSheetAndHireslip.totalArticles =
      this.totalArticles;
    this.invoiceDtoForLoadingSheetAndHireslip.listLrsRem = this.chkInvoiceLrs(
      this.lrDtoInvoiceLocalStorage.lrDtoRemains
    );
    this.invoiceDtoForLoadingSheetAndHireslip.stocksAt =
      this.userDataDtoReturnSession.office;
    this.invoiceDtoForLoadingSheetAndHireslip.stationType =
      this.userDataDtoReturnSession.stationType;
    this.invoiceDtoForLoadingSheetAndHireslip.officeType =
      this.userDataDtoReturnSession.officeType;
    //alert(this.userDataDtoReturnSession.officeType);
    // alert(this.invoiceDtoForLoadingSheetAndHireslip.officeType);
    this.invoiceDtoForLoadingSheetAndHireslip.loaderHead =
      this.lrDtoInvoiceLocalStorage.truckDataDto.loaderHead;
    if (this.lrDtoInvoiceLocalStorage.loadingChg != null) {
      this.guranteeWeight = this.lrDtoInvoiceLocalStorage.loadingChg;
      this.invoiceDtoForLoadingSheetAndHireslip.gurWeight = this.guranteeWeight;
    } else if (this.lrDtoInvoiceLocalStorage.guranteeWt != null) {
      this.guranteeWeight = this.lrDtoInvoiceLocalStorage.guranteeWt;
      this.invoiceDtoForLoadingSheetAndHireslip.gurWeight =
        this.guranteeWeight * 1000;
    }
    this.invoiceDtoForLoadingSheetAndHireslip.companyId =
      this.userDataDtoReturnSession.companyId;
    return this.invoiceDtoForLoadingSheetAndHireslip;
  }

  chkInvoiceLrs(lrDtoRemains) {
    this.checkInvoiceListLrs = new Array();
    for (let i = 0; i < lrDtoRemains.length; i++) {
      console.log(lrDtoRemains[i].invoiceNumber);
      if (lrDtoRemains[i].invoiceNumber != null) {
        this.checkInvoiceListLrs.push(lrDtoRemains[i].lrNumber);
      }
    }
    return this.checkInvoiceListLrs;
  }

  callInvService(invoiceDto, listOfLrDto) {
    console.log(invoiceDto);
    console.log(listOfLrDto);
    this.showSpinnerActionForInvoice = true;
    this.stockService.addInvoiceDetails(invoiceDto).subscribe((response) => {
      this.showSpinnerActionForInvoice = false;
      // console.log(response);
      if (response.invoiceNumber != null) {
        invoiceDto.invoiceNumber = response.invoiceNumber;
        this.setHideLrInDummyInvoicePrint();
        swal({
          title: "Loading Sheet",
          text:
            "Invoice No : " +
            response.invoiceNumber +
            " Created.Do You Want To Print This Invoice?",
          icon: "info",
          buttons: ["No", "Yes"],
        }).then((isConfirm) => {
          if (isConfirm) {
            this.lrDtoLoadingSheetPrint = new LRDto();
            this.lrDtoLoadingSheetPrint.invoiceNumber = response.invoiceNumber;
            this.lrDtoLoadingSheetPrint.status = "Loading";
            //Asrar_Jr_Chg_V7
            this.lrDtoLoadingSheetPrint.source = this.userDataDtoReturnSession.office;
            console.log(this.lrDtoLoadingSheetPrint);
            this.lrService
              .getSortingLrsForLoadingSheet(this.lrDtoLoadingSheetPrint)
              .subscribe((lrListLdgPrint) => {
                //console.log(response);
                if (lrListLdgPrint.length == 0) {
                  swal("No Data", "No LR found to print", "warning");
                } else {
                  this.LoadingSheetBookingPrint(
                    invoiceDto,
                    lrListLdgPrint,
                    response.invoiceNumber
                  );
                }
                this.changeDetectorRef.detectChanges();
              }),
              (error) => {
                swal(
                  "Error",
                  "Error While Updating a LR Hide In Dummy Invoice",
                  "error"
                );
              },
              () => console.log("done");
          } else {
            this.invoiceSummaryMethod(response.invoiceNumber);
            // this.bookingInvoiceMethod(response.invoiceNumber);
          }
        });
      } else {
        swal(
          "Loading Sheet",
          "Problem Occurs While creating the Invoice..",
          "error"
        );
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerActionForInvoice = false;
        swal(
          "Server Error",
          "Problem Occurs While getting Add Invoice Details",
          "error"
        );
      },
      () => console.log("done");
  }

  setHideLrInDummyInvoicePrint() {
    //if(this.rightsForOfficeTypeHideDummyLrPrint != "rights for officetype hide dummy lr print"){
    if (this.userDataDtoReturnSession.officeType != "Booking Office") {
      this.lrDtoHideDummyInvoice = new LRDto();
      this.listHideDummyInvoice = [];
      this.listOfLrsHideDummyInvoice = [];

      this.listHideDummyInvoice = this.listMaintenancesHideDummyInvoice;
      console.log(this.listHideDummyInvoice);
      for (let i = 0; i < this.listHideDummyInvoice.length; i++) {
        this.listOfLrsHideDummyInvoice.push(
          this.listHideDummyInvoice[i].lrNumber
        );
      }
      console.log(this.listOfLrsHideDummyInvoice);
      this.lrDtoHideDummyInvoice.list = this.listOfLrsHideDummyInvoice;
      this.lrDtoHideDummyInvoice.userName =
        this.userDataDtoReturnSession.userId;
      if (
        this.lrDtoHideDummyInvoice.list.length > 0 &&
        this.lrDtoInvoiceLocalStorage.invoiceNumber != null &&
        this.lrDtoInvoiceLocalStorage.invoiceNumber != undefined
      ) {
        this.lrDtoHideDummyInvoice.invoiceNumber =
          this.lrDtoInvoiceLocalStorage.invoiceNumber;
        this.stockService
          .setLrHideInDummyInvoice(this.bookingInvoiceForLrDto)
          .subscribe((response) => {
            //console.log(response);
            if (response.status == "Failed") {
              swal(
                "Failed",
                "Failed to Updating a LR Hide In Dummy Invoice",
                "error"
              );
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) => {
            swal(
              "Error",
              "Error While Updating a LR Hide In Dummy Invoice",
              "error"
            );
          },
          () => console.log("done");
      }
    }
  }

  LoadingSheetBookingPrint(invoiceDtoPrint, listOfLrDtoPrint, invoice_no) {
    localStorage.clear();
    localStorage.setItem(
      "loadingSheetBookingForInvoiceDtoPrint",
      JSON.stringify(invoiceDtoPrint)
    );
    localStorage.setItem(
      "loadingSheetBookingForListLrDtoPrint",
      JSON.stringify(listOfLrDtoPrint)
    );
    this.viewInvoiceLoadingSheetPrint = true;
    window.addEventListener("afterprint", (onclick) => {
      if (this.viewInvoiceLoadingSheetPrint) {
        this.viewInvoiceLoadingSheetPrint = false;
        this.listOfLrDto = [];
        localStorage.clear();
        this.invoiceSummaryMethod(invoice_no);
        //this.bookingInvoiceMethod(invoice_no);
      }
    });
  }

  bookingInvoiceMethod(invoiceNumber) {
    this.bookingInvoiceListOfTotalLrs = new Array();
    this.bookingInvoiceListOfLrs = new Array();
    this.bookingInvoiceListOfLrShortage = new Array();
    this.bookingInvoiceForTotalArticle = 0;

    for (
      let i = 0;
      i < this.lrDtoInvoiceLocalStorage.lrDtoEligible.length;
      i++
    ) {
      this.bookingInvoiceListOfTotalLrs.push(
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
      );
      if (
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status != null &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status == "Short" &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage > 0
      ) {
        this.bookingInvoiceListOfLrShortage.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber +
          "-" +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage
        );
      } else {
        this.listOfLrs.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
        );
        this.totalArticles =
          this.totalArticles +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].totalArticles;
        /*PENDING WORK*/
        /*
                set.add(((modelStore.get(i).getDestination().substring(0, 1).toUpperCase()) + "" +
                 (modelStore.get(i).getDestination().substring(1).toLowerCase()).trim())); 
 
                this.set.add((this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].destination.substring(0,1).toUpperCase() +""+
                (this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].destination.substring(1).toLowerCase()).trim()));
                */
      }
    }
    //console.log("this.set");
    //console.log(this.set);
    this.bookingInvoiceForHireslipDto = new HireSlipDto();
    this.bookingInvoiceForHireslipDto.vehicleNumber =
      this.lrDtoInvoiceLocalStorage.truckDataDto.truckNumber;
    this.bookingInvoiceForHireslipDto.fromstation =
      this.lrDtoInvoiceLocalStorage.source;
    this.bookingInvoiceForHireslipDto.tostation =
      this.lrDtoInvoiceLocalStorage.branch;
    this.bookingInvoiceForHireslipDto.loadedby =
      this.lrDtoInvoiceLocalStorage.truckDataDto.loaderName;
    this.bookingInvoiceForHireslipDto.column2 =
      this.userDataDtoReturnSession.addressId;
    swal({
      title: "Booking Invoice",
      text: "Sure You Want To Print This Booking Invoice Report",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      /*yes isConfirm start */
      //hard code need to remove
      //invoiceNumber = 'NP/CLT/1920/30';
      if (isConfirm) {
        this.bookingInvoiceForLrDto = new LRDto();
        this.bookingInvoiceForLrDto.invoiceNumber = invoiceNumber;
        this.bookingInvoiceForLrDto.companyId =
          this.userDataDtoReturnSession.companyId;
        this.showSpinnerActionForInvoice = true;
        this.stockService
          .getBookingInvoiceDetails(this.bookingInvoiceForLrDto)
          .subscribe((response) => {
            this.showSpinnerActionForInvoice = false;
            this.bookingInvoiceForLrDtoReturn = null;
            this.bookingInvoiceForLrDtoReturn = response;
            //  console.log(("am here");
            //console.log(this.bookingInvoiceForLrDtoReturn);
            //console.log(response.length);
            //if(this.rightsForofficeTypeBookingInvoiceDetailsPrint == "rights for officetype booking invoice details print"){
            if (
              this.userDataDtoReturnSession.officeType != null &&
              this.userDataDtoReturnSession.officeType == "Booking Office"
            ) {
              if (this.bookingInvoiceForLrDtoReturn.length > 0) {
                if (response[0].kantaWeight != null) {
                  this.bookingInvoiceForHireslipDto.kantaWeight =
                    response[0].kantaWeight;
                }
                if (response[0].guranteeWt != null) {
                  this.bookingInvoiceForHireslipDto.gurWeight =
                    response[0].guranteeWt;
                }
                if (response[0].supplierName != null) {
                  this.bookingInvoiceForHireslipDto.suppliername =
                    response[0].supplierName;
                }
                if (response[0].goodsType == "Select Type") {
                  this.bookingInvoiceForHireslipDto.goodsType = "NA";
                } else {
                  this.bookingInvoiceForHireslipDto.goodsType =
                    response[0].goodsType;
                }
              }
              this.bookingInvoiceForHireslipDto.invoiceNumber = invoiceNumber;
              this.bookingInvoiceNewPrint(
                this.bookingInvoiceForHireslipDto,
                this.bookingInvoiceListOfLrShortage,
                response
              );
            } else {
              console.log("Pending NEED TO WORK");
              //Pending need to work
              //new BookingDummyInvoice(list,invoiceNumber,hireSlipDto,listOfLrsShortage,"print");
              this.bookingInvoiceForHireslipDto.invoiceNumber = invoiceNumber;
              this.bookingInvoiceNewPrint(
                this.bookingInvoiceForHireslipDto,
                this.bookingInvoiceListOfLrShortage,
                response
              );
              //this.overAllResetComplete();
              //this.stepper.reset();
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) => {
            this.showSpinnerActionForInvoice = false;
            swal(
              "Error",
              "Error While Getting a result of bookingInvoiceMethod",
              "error"
            );
          },
          () => console.log("done");
      } else {
        /*yes isConfirm End */
        /*No isConfirm start */
        this.overAllResetComplete();
        //this.stepper.reset();
        this.router.navigate(["/dashboard/classic"]);
        // this.invoiceSummaryMethod(invoiceNumber);
      }
      /*No isConfirm end */
    });
  }

  bookingInvoiceNewPrint(
    bookingInvoiceForHireslipDto,
    bookingInvoiceListOfLrShortage,
    bookingInvoiceForLrDto
  ) {
    localStorage.clear();
    localStorage.setItem(
      "invoiceDetailsForBookingHireslipDtoPrint",
      JSON.stringify(bookingInvoiceForHireslipDto)
    );
    localStorage.setItem(
      "invoiceDetailsForBookingListOfLrsShortPrint",
      JSON.stringify(bookingInvoiceListOfLrShortage)
    );
    localStorage.setItem(
      "invoiceDetailsForBookingLrDtoPrint",
      JSON.stringify(bookingInvoiceForLrDto)
    );
    this.viewInvoiceDetailsPrint = true;
    window.addEventListener("afterprint", (onclick) => {
      if (this.viewInvoiceDetailsPrint) {
        this.viewInvoiceDetailsPrint = false;
        this.bookingInvoiceListOfLrShortage = [];
        localStorage.clear();
        this.overAllResetComplete();
        //this.stepper.reset();
        this.router.navigate(["/dashboard/classic"]);
        this.changeDetectorRef.detectChanges();
      }
    });

    // by kamil on research for tab close
    // window.addEventListener('afterprint', (onclick) => {
    //     if (this.viewInvoiceDetailsPrint) {

    //         this.viewInvoiceDetailsPrint = false;
    //         this.bookingInvoiceListOfLrShortage = [];
    //         localStorage.clear();
    //         this.overAllResetComplete();
    //this.stepper.reset();
    // console.log("The Url Last Active");
    // this.router.events.subscribe((e) => {
    //     console.log(e);
    // })
    // let pageUrl =this.router.url;
    // console.log(this.router.url);
    // window.top.close();
    // this.disposeTab(pageUrl)
    // this.changeDetectorRef.detectChanges();
    // this.dashRedirect();

    //         }
    //     });
    // }
  }

  // by kamil on research for tab close
  // dashRedirect(){
  //     this.router.navigate(['/dashboard/classic']);
  // }

  // deactivateTabs(pageUrl) {
  //     pageUrl.forEach(pageUrl => (pageUrl.active = false));
  // }

  // disposeTab(pageUrl) {
  //     if (pageUrl.length > 1) {
  //         pageUrl = pageUrl.filter(item => item.key !== pageUrl.key);

  //         if (pageUrl.active) {
  //             this.deactivateTabs(pageUrl);
  //             // this.router.navigateByUrl(this.tabs[this.tabs.length - 1].route.path);
  //         }
  //     }
  // }

  invoiceSummaryMethod(invoiceNumber) {
    swal({
      title: "Invoice Summary",
      text: "Do You Want To Print Invoice Summary Report",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        //yes start
        this.summaryInvoiceForLrDtoPrint = new LRDto();
        this.summaryInvoiceForLrDtoPrint.invoiceNumber = invoiceNumber;
        this.summaryInvoiceForLrDtoPrint.fromStation =
          this.lrDtoInvoiceLocalStorage.source;
        this.summaryInvoiceForLrDtoPrint.toStation =
          this.lrDtoInvoiceLocalStorage.branch;
        this.summaryInvoiceForLrDtoPrint.companyId =
          this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoInvoiceLocalStorage.source);
        console.log(this.lrDtoInvoiceLocalStorage.branch);
        this.showSpinnerActionForInvoice = true;
        this.stockService
          .getInvoiceDestinationSummary(this.summaryInvoiceForLrDtoPrint)
          .subscribe((response) => {
            this.showSpinnerActionForInvoice = false;
            //console.log(response);
            if (response != null) {
              this.destInvoiceSummaryReportPrintOut(
                this.summaryInvoiceForLrDtoPrint,
                response,
                invoiceNumber
              );
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) => {
            this.showSpinnerActionForInvoice = false;
            swal(
              "Error",
              "Error Occur While Getting  result of Invoice Summary Method",
              "error"
            );
          },
          () => console.log("done");
      } else {
        this.bookingInvoiceMethod(invoiceNumber);
      }
    });
  }

  destInvoiceSummaryReportPrintOut(
    summaryInvoiceForLrDtoPrint,
    invoiceSummaryDataReturn,
    invoiceNumber
  ) {
    localStorage.clear();
    localStorage.setItem(
      "invoiceSummaryForLRDtoPrint",
      JSON.stringify(summaryInvoiceForLrDtoPrint)
    );
    localStorage.setItem(
      "invoiceSummaryForLRDtoListPrint",
      JSON.stringify(invoiceSummaryDataReturn)
    );
    this.viewInvoiceSummaryPrint = true;
    window.addEventListener("afterprint", (onclick) => {
      if (this.viewInvoiceSummaryPrint) {
        this.viewInvoiceSummaryPrint = false;
        summaryInvoiceForLrDtoPrint = new LRDto();
        invoiceSummaryDataReturn = [];
        localStorage.clear();
        this.bookingInvoiceMethod(invoiceNumber);
      }
    });
  }

  validateCreateTripSheetBtn() {
    this.listOfLrs = new Array();
    this.listOfTotalLrs = new Array();
    this.listOfLrShortage = new Array();
    this.totalArticles = 0;
    this.totToPay = 0;
    this.totPaid = 0;
    for (
      let i = 0;
      i < this.lrDtoInvoiceLocalStorage.lrDtoEligible.length;
      i++
    ) {
      this.listOfTotalLrs.push(
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
      );
      if (
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status != null &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].status == "Short" &&
        this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage > 0
      ) {
        this.listOfLrShortage.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber +
          "-" +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrsShortage
        );
      } else {
        this.listOfLrs.push(
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].lrNumber
        );
        this.totalArticles =
          this.totalArticles +
          this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].totalArticles;

        this.totToPay =
          this.totToPay +
            this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].toPay ==
            null
            ? 0.0
            : this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].toPay;
        this.totToPay =
          this.totPaid + this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].paid ==
            null
            ? 0.0
            : this.lrDtoInvoiceLocalStorage.lrDtoEligible[i].paid;
        //PENDING TO WORK
        /* set.add(((modelStore.get(i).getDestination()
                .substring(0, 1).toUpperCase())
                + "" + (modelStore.get(i).getDestination()
                .substring(1).toLowerCase()).trim()));*/
      }
    }

    this.invoiceDtoForLoadingSheetAndHireslip =
      this.getInvoiceDtoForLoadingSheetAndHireSlip();
    /*if(this.lrDtoForLocalStorage.branch !=null && this.lrDtoForLocalStorage.branch =="Godown Delivery" &&
        this.rightsForLoadingSheetOffice == "rights for loading sheet print office"){
        */
    if (
      this.lrDtoInvoiceLocalStorage.branch != null &&
      this.lrDtoInvoiceLocalStorage.branch == "Godown Delivery" &&
      this.userDataDtoReturnSession.office == "Binnymills"
    ) {
      this.invoiceDtoForLoadingSheetAndHireslip.toStation = "konditope";
    } else {
      this.invoiceDtoForLoadingSheetAndHireslip.toStation =
        this.lrDtoInvoiceLocalStorage.branch;
    }
    this.invoiceDtoForLoadingSheetAndHireslip.truckDataDto =
      this.lrDtoInvoiceLocalStorage.truckDataDto;
    this.hireSlipDtoForHireslip = this.getHireSlipDtoInfo();

    this.getLdgHamaliValues(this.invoiceDtoForLoadingSheetAndHireslip);
    this.callHireService(
      this.invoiceDtoForLoadingSheetAndHireslip,
      this.hireSlipDtoForHireslip
    );
  }

  getHireSlipDtoInfo() {
    this.hireSlipDtoForHireslip = new HireSlipDto();
    this.hireSlipDtoForHireslip.listTotalLrs = this.listOfTotalLrs;
    this.hireSlipDtoForHireslip.list = this.listOfLrs;
    this.hireSlipDtoForHireslip.listLrsShortage = this.listOfLrShortage;
    this.hireSlipDtoForHireslip.totalLRs = this.listOfLrs.length;
    this.hireSlipDtoForHireslip.fromstation =
      this.lrDtoInvoiceLocalStorage.source;

    this.hireSlipDtoForHireslip.enteredby =
      this.userDataDtoReturnSession.userId;
    this.hireSlipDtoForHireslip.lastupdatedby =
      this.userDataDtoReturnSession.userId;
    this.hireSlipDtoForHireslip.financialYear =
      this.userDataDtoReturnSession.financialYear;
    this.hireSlipDtoForHireslip.totalArticles = this.totalArticles;
    this.hireSlipDtoForHireslip.listLrsRem = this.chkInvoiceLrs(
      this.lrDtoInvoiceLocalStorage.lrDtoRemains
    );

    this.hireSlipDtoForHireslip.loadedby =
      this.lrDtoInvoiceLocalStorage.truckDataDto.loaderName;
    this.hireSlipDtoForHireslip.contactPerson =
      this.lrDtoInvoiceLocalStorage.truckDataDto.contactPerson;
    this.hireSlipDtoForHireslip.suppliername =
      this.lrDtoInvoiceLocalStorage.truckDataDto.supplierName;
    this.hireSlipDtoForHireslip.suppAddress =
      this.lrDtoInvoiceLocalStorage.truckDataDto.suppAdd;
    this.hireSlipDtoForHireslip.suppPhNo =
      this.lrDtoInvoiceLocalStorage.truckDataDto.suppPhNo;
    this.hireSlipDtoForHireslip.supplierpanno =
      this.lrDtoInvoiceLocalStorage.truckDataDto.suppPanNo;

    this.hireSlipDtoForHireslip.driverlicencenumber =
      this.lrDtoInvoiceLocalStorage.truckDataDto.licNo;
    this.hireSlipDtoForHireslip.driverLicState =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverLicState;
    this.hireSlipDtoForHireslip.driverLicStateCode =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverLicStateCode;
    this.hireSlipDtoForHireslip.drivername =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverName;
    this.hireSlipDtoForHireslip.driverAdd =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverAdd;
    this.hireSlipDtoForHireslip.driverPinCode =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverPincode;
    this.hireSlipDtoForHireslip.driverId =
      this.lrDtoInvoiceLocalStorage.truckDataDto.driverId;

    this.hireSlipDtoForHireslip.vehiCompPanNo =
      this.lrDtoInvoiceLocalStorage.truckDataDto.panNo;
    this.hireSlipDtoForHireslip.vehicleNumber =
      this.lrDtoInvoiceLocalStorage.truckDataDto.truckNumber;
    this.hireSlipDtoForHireslip.vehOwnerState =
      this.lrDtoInvoiceLocalStorage.truckDataDto.ownerRegState;
    this.hireSlipDtoForHireslip.vehOwnerStateCode =
      this.lrDtoInvoiceLocalStorage.truckDataDto.ownerStateCode;
    this.hireSlipDtoForHireslip.vehiCompName =
      this.lrDtoInvoiceLocalStorage.truckDataDto.ownerName;
    this.hireSlipDtoForHireslip.vehiCompAddress =
      this.lrDtoInvoiceLocalStorage.truckDataDto.address;
    this.hireSlipDtoForHireslip.vehiclePinCode =
      this.lrDtoInvoiceLocalStorage.truckDataDto.ownerPincode;
    this.hireSlipDtoForHireslip.trkLgth =
      this.lrDtoInvoiceLocalStorage.loadingChg;
    //this.hireSlipDtoForHireslip.trkLgth = this.lrDtoInvoiceLocalStorage.truckDataDto.trkLgth;

    this.hireSlipDtoForHireslip.entryCP =
      this.lrDtoInvoiceLocalStorage.truckDataDto.entryCP;
    this.hireSlipDtoForHireslip.entryCPCode =
      this.lrDtoInvoiceLocalStorage.truckDataDto.entryCPCode;
    this.hireSlipDtoForHireslip.toPay = this.totToPay;
    this.hireSlipDtoForHireslip.paid = this.totPaid;
    /*if(this.lrDtoForLocalStorage.branch !=null && this.lrDtoForLocalStorage.branch =="Godown Delivery" &&
           this.rightsForCreateHireslipOffice == "rights for create hireslip office"){*/
    if (
      this.lrDtoInvoiceLocalStorage.branch != null &&
      this.lrDtoInvoiceLocalStorage.branch == "Godown Delivery" &&
      this.userDataDtoReturnSession.office == "Binnymills"
    ) {
      this.hireSlipDtoForHireslip.tostation = "konditope";
    } else {
      this.hireSlipDtoForHireslip.tostation =
        this.lrDtoInvoiceLocalStorage.branch;
    }

    return this.hireSlipDtoForHireslip;
  }

  callHireService(invoiceDto, hireSlipDto) {
    console.log("CALL-HIRE");
    console.log(invoiceDto);
    console.log(hireSlipDto);
    //this.tripCreationInvoiceScreen.addInvoiceDetails( this.invoiceDto).subscribe(
    this.showSpinnerActionForInvoice = true;
    this.stockService.addInvoiceDetails(invoiceDto).subscribe((response) => {
      this.showSpinnerActionForInvoice = false;
      //console.log(response);
      if (response.invoiceNumber != null) {
        invoiceDto.invoiceNumber = response.invoiceNumber;
        swal(
          "Hireslip Creation",
          "Invoice No : " + response.invoiceNumber + " Created.",
          "success"
        );
        //PENDING NEED TO WORK
        /* 
                    setHideLrInDummyInvoicePrint();
                   invoiceDto.setInvoiceNumber(invoiceNum);
                 MessageBox.confirm("Confirm Hireslip Creation",
                 "Sure U Want to create the Hireslip",
                 if yes
                 hireSlipDto.setInvoiceNumber(invoiceNum);
                 hireSlipDto.setMode("add");
                     */
        swal({
          title: "Confirm Hireslip Creation",
          text: "Sure U Want to create the Hireslip",
          icon: "info",
          buttons: ["No", "Yes"],
        }).then((isConfirm) => {
          if (isConfirm) {
            hireSlipDto.invoiceNumber = response.invoiceNumber;
            hireSlipDto.status = response.status;
            hireSlipDto.mode = "add";
            this.hireSlipDtoForCreateHireslipLocalStorage = new HireSlipDto();
            this.hireSlipDtoForCreateHireslipLocalStorage = hireSlipDto;
            console.log(this.hireSlipDtoForCreateHireslipLocalStorage);
            this.stepper.selectedIndex = 4;
            this.setOnloadOnHireSlipDetails();
          } else {
            this.startOverBtn();
          }
        });
      } else {
        swal(
          "Loading Sheet",
          "Problem Occurs While creating the Invoice..",
          "error"
        );
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerActionForInvoice = false;
        swal(
          "Server Error",
          "Problem Occurs While getting add Invoice Details.",
          "error"
        );
      },
      () => console.log("done");
  }

  //pop up
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // PopUp Open Of Hamali Details Btn Click
  hamaliDetailsBtn() {
    localStorage.clear();
    localStorage.setItem(
      "HamaliPopUpOpen",
      JSON.stringify(this.newAttributeSetHamaliDetailsList)
    );
    const dialogRef = this.dialog.open(HamaliDetailReportComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      //console.log(JSON.parse(result));
      if ((result == null && result == undefined) || result == "Close") {
        this.ledgerHamaliListPopupReturn = [];
      } else {
        this.ledgerHamaliListPopupReturn = [];
        this.ledgerHamaliListPopupReturn = JSON.parse(result);
      }
      console.log(this.ledgerHamaliListPopupReturn);
    });
  }

  getLdgHamaliValues(invoiceDtoForLdgHamali) {
    this.multipleSrcEntered = null;
    this.hamaliSourceEntered = null;
    this.multipleHamaliEntered = null;
    if (
      this.ledgerHamaliListPopupReturn != null &&
      this.ledgerHamaliListPopupReturn != undefined &&
      this.ledgerHamaliListPopupReturn.length > 0
    ) {
      for (let i = 0; i < this.ledgerHamaliListPopupReturn.length; i++) {
        if (i == 0) {
          this.multipleSrcEntered = this.ledgerHamaliListPopupReturn[i].source;
          this.hamaliSourceEntered = this.ledgerHamaliListPopupReturn[i].source;
          this.multipleHamaliEntered =
            this.ledgerHamaliListPopupReturn[i].hamaliType +
            "_" +
            this.ledgerHamaliListPopupReturn[i].hamaliValue;
        } else if (
          !(
            this.multipleSrcEntered ==
            this.ledgerHamaliListPopupReturn[i].source
          )
        ) {
          this.multipleSrcEntered = this.ledgerHamaliListPopupReturn[i].source;
          this.hamaliSourceEntered +=
            "#" + this.ledgerHamaliListPopupReturn[i].source;
          this.multipleHamaliEntered +=
            "#" +
            this.ledgerHamaliListPopupReturn[i].hamaliType +
            "_" +
            this.ledgerHamaliListPopupReturn[i].hamaliValue;
        } else {
          this.multipleHamaliEntered +=
            "&" +
            this.ledgerHamaliListPopupReturn[i].hamaliType +
            "_" +
            this.ledgerHamaliListPopupReturn[i].hamaliValue;
        }
      }
    }
    invoiceDtoForLdgHamali.multipleSource = this.hamaliSourceEntered;
    invoiceDtoForLdgHamali.multipleHamali = this.multipleHamaliEntered;
  }

  // pending need to work
  setLdgHamaliValues() {
    var multipleSrc = [];
    var multipleHamali = [];
    this.newAttributeSetHamaliDetailsList = {};

    multipleSrc =
      this.lrDtoInvoiceLocalStorage.truckDataDto.multipleSource == null
        ? null
        : this.lrDtoInvoiceLocalStorage.truckDataDto.multipleSource.split("#");
    multipleHamali =
      this.lrDtoInvoiceLocalStorage.truckDataDto.multipleHamali == null
        ? null
        : this.lrDtoInvoiceLocalStorage.truckDataDto.multipleHamali.split("#");
    if (multipleSrc != null) {
      for (let i = 0; i < multipleHamali.length; i++) {
        var multipleHamaliSplit = [];
        multipleHamaliSplit = multipleHamali[i];
        console.log(multipleHamaliSplit);
        for (let j = 0; j < multipleHamaliSplit.length; j++) {
          this.newAttributeSetHamaliDetailsList.source = multipleSrc[i];
          this.newAttributeSetHamaliDetailsList.hamaliType =
            multipleHamaliSplit[j].split("_")[0];
          this.newAttributeSetHamaliDetailsList.hamaliValue =
            multipleHamaliSplit[j].split("_")[1];
        }
      }
    }
    console.log(this.newAttributeSetHamaliDetailsList);
  }

  /* 4 th level main Invoice end */

  /* 5 th level main Hireslip start */

  /* click Listner */
  clickListnerForTruckType(e: NgbTypeaheadSelectItemEvent) {
    console.log(e);
    this.modelTruckType = e.item;
    $("#" + this.pageId + "truckType").val(this.modelTruckType.truckType);
    $("#" + this.pageId + "truckType").focusout();
    this.selectedTruckTypeId = null;
    this.selectedTruckTypeId = this.modelTruckType.id;
    //this.validationForTruckType();
    this.truckDataDtoForTruckTypeClick = new TruckDataDto();
    this.truckDataDtoForTruckTypeClick =
      this.validateTruckTypeSelectionsOnGurWgt();
    if (
      $("#" + this.pageId + "truckType").val() == null ||
      $("#" + this.pageId + "truckType").val() == undefined ||
      $("#" + this.pageId + "truckType").val() == ""
    ) {
      swal("Not Allowed", "Please select Truck Type to proceed!", "warning");
      return false;
    } else if (!this.truckDataDtoForTruckTypeClick.isValid) {
      swal({
        title: "Not Allowed",
        text: "The Truck Type selected does not match with the Total GUR Wt entered, kindly select the appropriate Truck Type.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        setTimeout(() => {
          this.clearTruckTypeField();
        }, 500);
      });
      return false;
    } else {
      swal({
        title: "Confirm Truck Type",
        text:
          "Are you sure this is " +
          $("#" + this.pageId + "truckType").val() +
          "",
        icon: "info",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          // console.log(this.modelTruckType);
        } else {
          setTimeout(() => {
            this.clearTruckTypeField();
          }, 500);
        }
      });
    }
  }

  clearTruckTypeField() {
    this.modelTruckType = null;
    $("#" + this.pageId + "truckType").val("");
  }

  validationForTruckType() {
    this.truckDataDtoForTruckTypeClick = new TruckDataDto();
    this.truckDataDtoForTruckTypeClick =
      this.validateTruckTypeSelectionsOnGurWgt();
    if (
      $("#" + this.pageId + "truckType").val() == null ||
      $("#" + this.pageId + "truckType").val() == undefined ||
      $("#" + this.pageId + "truckType").val() == ""
    ) {
      swal("Not Allowed", "Please select Truck Type to proceed!", "warning");
      return false;
    } else if (!this.truckDataDtoForTruckTypeClick.isValid) {
      swal(
        "Not Allowed",
        "The Truck Type selected does not match with the Total GUR Wt entered, kindly select the appropriate Truck Type.",
        "warning"
      );
      $("#" + this.pageId + "truckType").val("");
      this.modelTruckType = null;
      return false;
    } else {
      swal({
        title: "Confirm Truck Type",
        text:
          "Are you sure this is " +
          $("#" + this.pageId + "truckType").val() +
          "",
        icon: "info",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          // console.log(this.modelTruckType);
        } else {
          $("#" + this.pageId + "truckType").val("");
        }
      });
    }
  }

  /*clickListnerForMultiInvoiceStation(e: NgbTypeaheadSelectItemEvent) {
        this.modelMultiInvoiceDest = e.item;
        console.log(this.modelMultiInvoiceDest);
        $("#"+this.pageId+"multiInvoiceDestinationId").val(this.modelMultiInvoiceDest.destination);
        this.getStationCodeMultiInvoice();
    }*/

  /*On Key Enter press start here  */
  onKeyPressFieldForInvoiceNo(event) {
    if (event == 13) {
      $("#" + this.pageId + "invoiceDates").focus();
    }
  }
  onKeyPressFieldForHireslipNo(event) {
    if (event == 13) {
      $("#" + this.pageId + "gurWeightTotal").focus();
    }
  }
  onKeyPressFieldForTruckTotalWgt(event) {
    if (event == 13) {
      $("#" + this.pageId + "guranteeWeight").focus();
    }
  }
  onKeyPressFieldForDriverMobileNo(event) {
    if (event == 13) {
      $("#" + this.pageId + "insuranceNo").focus();
    }
  }
  onKeyPressFieldForInsuranceNo(event) {
    if (event == 13) {
      $("#" + this.pageId + "baseHireAmt").focus();
    }
  }
  onKeyPressFieldForEWBSystem(event) {
    if (event == 13) {
      $("#" + this.pageId + "noOfNoEwbInConsolidatedManual").focus();
    }
  }
  onKeyPressFieldForEWBManual(event) {
    if (event == 13) {
      $("#" + this.pageId + "consolidatedEwayBill").focus();
    }
  }
  onKeyPressFieldForConsolidateEWB(event) {
    if (event == 13) {
      $("#" + this.pageId + "confirmConsolidatedEwayBill").focus();
    }
  }
  onKeyPressFieldForConfirmConsolidateEWB(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").focus();
    }
  }

  onKeyPressFieldForBalance(event) {
    if (event == 13) {
      $("#" + this.pageId + "touchingHire").focus();
    }
  }
  onKeyPressFieldForTouchingHire(event) {
    if (event == 13) {
      $("#" + this.pageId + "deliveryPoints").focus();
    }
  }

  onKeyPressFieldForBaseHireAmt(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "doorOpen").focus();
    }
  }

  baseHireAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "doorOpen").focus();
  }

  onKeyPressFieldForDoorOpen(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "vehicleHeight").focus();
    }
  }

  doorOpenAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "vehicleHeight").focus();
  }

  onKeyPressFieldForVehicleHgt(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "doorDelivery").focus();
    }
  }

  vehicleHeightAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "doorDelivery").focus();
  }

  onKeyPressFieldForDoorDely(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "extraWeight").focus();
    }
  }

  doordeliveryAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "extraWeight").focus();
  }

  onKeyPressFieldForExtraWgt(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "checkPost").focus();
    }
  }

  extraWgtAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "checkPost").focus();
  }

  onKeyPressFieldForCheckPost(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "otherCharges").focus();
    }
  }

  checkPostAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "otherCharges").focus();
  }

  onKeyPressFieldForOtherChrgs(event) {
    if (event == 13) {
      $("#" + this.pageId + "totalHire").val(this.calHireAmt());
      this.calculateBalanceAmt();
      $("#" + this.pageId + "totalHire").focus();
    }
  }

  otherChargeAmtChgBlur(event) {
    $("#" + this.pageId + "totalHire").val(this.calHireAmt());
    this.calculateBalanceAmt();
    $("#" + this.pageId + "totalHire").focus();
  }

  onKeyPressFieldForDriverNewMobileNo(event) {
    if (event == 13) {
      $("#" + this.pageId + "updateBtnId").focus();
    }
  }

  calHireAmt() {
    this.hireAmt = 0;
    this.validateHireAmtForBaseHireAmt =
      $("#" + this.pageId + "baseHireAmt").val() == null
        ? 0.0
        : $("#" + this.pageId + "baseHireAmt").val();
    this.validateHireAmtForDoorOpen =
      $("#" + this.pageId + "doorOpen").val() == null
        ? 0.0
        : $("#" + this.pageId + "doorOpen").val();
    this.validateHireAmtForVehicleHeight =
      $("#" + this.pageId + "vehicleHeight").val() == null
        ? 0.0
        : $("#" + this.pageId + "vehicleHeight").val();
    this.validateHireAmtForDoorDely =
      $("#" + this.pageId + "doorDelivery").val() == null
        ? 0.0
        : $("#" + this.pageId + "doorDelivery").val();
    this.validateHireAmtForExtraWeight =
      $("#" + this.pageId + "extraWeight").val() == null
        ? 0.0
        : $("#" + this.pageId + "extraWeight").val();
    this.validateHireAmtForCheckPost =
      $("#" + this.pageId + "checkPost").val() == null
        ? 0.0
        : $("#" + this.pageId + "checkPost").val();
    this.validateHireAmtForotherChrg =
      $("#" + this.pageId + "otherCharges").val() == null
        ? 0.0
        : $("#" + this.pageId + "otherCharges").val();

    this.hireAmt =
      +this.validateHireAmtForBaseHireAmt +
      +this.validateHireAmtForDoorOpen +
      +this.validateHireAmtForVehicleHeight +
      +this.validateHireAmtForDoorDely +
      +this.validateHireAmtForExtraWeight +
      +this.validateHireAmtForCheckPost +
      +this.validateHireAmtForotherChrg;
    return parseFloat(this.hireAmt);
  }

  eventHandlerGurWgt(event) {
    if (event == 13) {
      var guranteeWgt =
        $("#" + this.pageId + "guranteeWeight").val() == null
          ? 0
          : $("#" + this.pageId + "guranteeWeight").val();
      if (guranteeWgt < 14000) {
        swal({
          title: "Warning",
          text: "This Hire Slip Seems To Be Of 2 Point Delivery, Do You Want To Merge It?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then((isConfirm) => {
          if (isConfirm) {
            $("#" + this.pageId + "deliveryPointIdForSingle").prop(
              "checked",
              false
            );
            $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
              "checked",
              true
            );
            this.showMultipleDeliveryPointPopup();
          } else {
            $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
              "checked",
              false
            );
            $("#" + this.pageId + "deliveryPointIdForSingle").prop(
              "checked",
              true
            );
            $("#" + this.pageId + "kantaWeight").focus();
          }
        });
      }
    }
  }

  eventHandlerLoadedBy(event) {
    console.log(event);
    if (event == 13) {
      $("#" + this.pageId + "truckType").focus();
    }
  }

  eventHandlerKantaWgt(event) {
    if (event == 13) {
      if (this.isMainInvoice) {
        $("#" + this.pageId + "loadedBy").focus();
      } else {
        $("#" + this.pageId + "loadedBy").focus();
      }
    }
  }

  onKeyPressFieldForTotalHire(event) {
    if (event == 13) {
      this.calculateBalanceAmt();
      $("#" + this.pageId + "advance").focus();
    }
  }
  totalHireAmtChgBlur(event) {
    this.calculateBalanceAmt();
    $("#" + this.pageId + "advance").focus();
  }

  eventHandlerAdvance(event) {
    if (event == 13) {
      this.calculateBalanceAmt();
      $("#" + this.pageId + "balance").focus();
    }
  }

  advanceAmtChgBlur(event) {
    this.calculateBalanceAmt();
    $("#" + this.pageId + "balance").focus();
  }

  calculateBalanceAmt() {
    this.calculateAalanceAmt = 0;
    this.validateTotalHireAmt = 0;
    this.validateAdvanceAmt = 0;
    this.validateTotalHireAmt =
      $("#" + this.pageId + "totalHire").val() == null
        ? 0
        : $("#" + this.pageId + "totalHire").val();
    this.validateAdvanceAmt =
      $("#" + this.pageId + "advance").val() == null
        ? 0
        : $("#" + this.pageId + "advance").val();
    //console.log(this.validateTotalHireAmt + "-Chan-" + this.validateAdvanceAmt);
    this.calculateAalanceAmt =
      +this.validateTotalHireAmt + -this.validateAdvanceAmt;
    //console.log(this.calculateAalanceAmt);
    if (
      this.calculateAalanceAmt != null &&
      this.calculateAalanceAmt != 0 &&
      this.calculateAalanceAmt > 0
    ) {
      $("#" + this.pageId + "balance").val(this.calculateAalanceAmt);
    } else {
      $("#" + this.pageId + "balance").val(0);
    }
  }

  /* On Key Enter press end here */

  /* pop up flow start here */

  //for popup modal starts
  /* openNotAssignedPopup(contentProceed) {
         this.modalService.open(contentProceed, { centered: true }).result.then(
             resultContent => {
                 this.closeResultContentNotAssigned = `Closed with: ${resultContent}`;
             },
             reason => {
                 this.closeResultContentNotAssigned = `Dismissed ${this.getDismissReason(reason)}`;
             }
         );
     }*/

  isLastPointDeliveryLinkedHireslipListner(event) {
    console.log(event.target.checked);
    if (event.target.checked == true) {
      localStorage.clear();
      localStorage.setItem(
        "MultipleInvoiceLastPtOpen",
        JSON.stringify(this.multipleInvoiceIsLastPointListPopupReturn)
      );
      const dialogRef = this.dialog.open(
        MultipleInvoiceLastPointDetailsComponent,
        {}
      );

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        if (result != null) {
          var dataArray = JSON.parse(result);
          var arrayData = [];
          this.multipleInvoiceIsLastPointListPopupReturn = [];
          dataArray.forEach(function (item) {
            arrayData.push(item.cmMultiInvoice);
          });
          this.multipleInvoiceIsLastPointListPopupReturn = arrayData;
          console.log(this.multipleInvoiceIsLastPointListPopupReturn);
        }
        localStorage.clear();
      });
    }
  }

  addDriverBtnClickPopShow(hireSlipUpdateDriverNo) {
    this.modalRefferenceNewDriverMobileNo = this.modalService.open(
      hireSlipUpdateDriverNo,
      { centered: true }
    );
    this.modalRefferenceNewDriverMobileNo.result.then(
      (result) => {
        this.closeResultContentUpdateDriverMobileNo = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResultContentUpdateDriverMobileNo = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );
  }

  updateDriverNewMobileNoPopUpCreation() {
    if (
      $("#" + this.pageId + "driverNewMobileNumber").val() == null ||
      $("#" + this.pageId + "driverNewMobileNumber").val() == ""
    ) {
      alert("Please enter the New Driver Mobile Number");
      return false;
    } else {
      this.driverNumberChangeTemp = null;
      this.driverNumberChangeTemp = $(
        "#" + this.pageId + "driverMobileNo"
      ).val();
      $("#" + this.pageId + "driverMobileNo").val("");
      $("#" + this.pageId + "driverMobileNo").val(
        $("#" + this.pageId + "driverNewMobileNumber").val()
      );
      this.modalRefferenceNewDriverMobileNo.close();
    }
  }

  closeNewDriverPopupBtn() {
    $("#" + this.pageId + "driverNewMobileNumber").val("");
    this.modalRefferenceNewDriverMobileNo.close();
    this.driverNumberChangeTemp = null;
  }

  truckMasterInPopUpPage(truckMasterPopUp) {
    this.truckDataDtoLocalStorageTruckMaster = new TruckDataDto();
    console.log(this.isPanNoForTruckAvailable);
    console.log(this.selectedTruckNumber);
    //Imran_Chg_V1
    if (!this.isPanNoForTruckAvailable) {
      this.localStorageLorryTruckNo = this.selectedTruckNumber;
      console.log('1');
    } else {
      this.localStorageLorryTruckNo = $("#" + this.pageId + "lorryNumber").val();
      console.log(this.localStorageLorryTruckNo);
    }
    this.localStorageLorryTruckNo = $("#" + this.pageId + "lorryNumber").val();
    console.log(this.localStorageLorryTruckNo);

    this.truckDataDtoLocalStorageTruckMaster.truckNumber = this.localStorageLorryTruckNo;
    this.truckDataDtoLocalStorageTruckMaster.status = "update";
    localStorage.clear();
    localStorage.setItem("fromHireslipPageLocalStorage", "Hireslip");
    localStorage.setItem(
      "fromHireslipPassingTruckDataDtoIntoLocalStorage",
      JSON.stringify(this.truckDataDtoLocalStorageTruckMaster)
    );
    console.log(localStorage);

    this.modalRefferenceTruckMasterPopUp = this.modalService.open(
      truckMasterPopUp,
      { centered: true, windowClass: "myCustomModalClass" }
    );
    this.modalRefferenceTruckMasterPopUp.result.then(
      (result) => {
        this.modalRefferenceTruckMasterClosePopUp = `Closed with: ${result}`;
        //alert(1);
        // this.panAadharValidationService();
      },
      (reason) => {
        this.modalRefferenceTruckMasterClosePopUp = `Dismissed ${this.getDismissReason(
          reason
        )}`;
        //alert(2);
        // this.panAadharValidationService();
      }
    );
  }

  showMultipleDeliveryPointPopup() {
    localStorage.clear();
    localStorage.setItem(
      "DeliveryPointOpen",
      JSON.stringify("DeliveryPointOpen")
    );
    localStorage.setItem(
      "DeliveryPointHireslipLocalStorage",
      JSON.stringify(this.hireSlipDtoForCreateHireslipLocalStorage)
    );
    const dialogRef = this.dialog.open(MultiplePointDetailsComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if ((result != null && result == "OK") || result == "Close") {
        this.multipleDeliveryPointListPopupReturn = [];
        this.multipleDeliveryPointLocalStorage = null;
        this.firstPtDeliv = null;

        this.multipleDeliveryPointLocalStorage = JSON.parse(
          localStorage.getItem("DeliveryPointClose")
        );
        console.log(this.multipleDeliveryPointLocalStorage.listOfInvoice);
        this.multipleDeliveryPointListPopupReturn =
          this.multipleDeliveryPointLocalStorage.listOfInvoice;
        this.firstPtDeliv = this.multipleDeliveryPointLocalStorage.firstPntDelv;
        console.log(this.multipleDeliveryPointLocalStorage.firstPntDelv);
      }
    });
  }

  /* pop up flow end here */

  /*Check Box Click Start*/

  multiInvTripCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.hideShowIsLastPointDelivery = true;
    } else {
      this.hideShowIsLastPointDelivery = false;
    }
  }

  twoPointDDCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      this.hideShowTwoPointDoorDelivery = true;
    } else {
      this.hideShowTwoPointDoorDelivery = false;
    }
  }

  deliveryPointMultipleCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", false);
      this.showMultipleDeliveryPointPopup();
    } else {
      $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", true);
    }
  }
  deliveryPointSingleCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
        "checked",
        false
      );
    } else {
      $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", true);
    }
  }

  /*Check Box Click End*/

  /* Service start here */

  getTruckTypeDetails() {
    this.truckTypeDataDto = new TruckDataDto();
    this.truckTypeDataDto.companyId = this.userDataDtoReturnSession.companyId;
    this.truckTypeDataDto.mode = "active";
    this.masterReadService
      .getTruckTypeCommonDetails(this.truckTypeDataDto)
      .subscribe((response) => {
        //console.log(response);
        if (response.length == 0) {
          //swal("Warning","No Truck Type records found!","warning");
          this.truckTypeOptions = [];
          this.truckTypeTA = [];
        } else {
          this.truckTypeOptions = [];
          this.truckTypeTA = [];
          this.truckTypeOptions = response;
          for (let i = 0; i < this.truckTypeOptions.length; i++) {
            this.truckTypeTA.push(this.truckTypeOptions[i]);
            this.hmTruckType.set(this.truckTypeOptions[i].id, i + 1);
            this.hmTruckTypeDto.set(
              this.truckTypeOptions[i].id,
              this.truckTypeOptions[i]
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Truck Type Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  setOnloadOnHireSlipDetails() {
    //this.hireSlipDtoForCreateHireslipLocalStorage;
    console.log("check this onload>>>>>>>>>>>" + this.hireSlipDtoForCreateHireslipLocalStorage);
    //its for testing true we used in live it shd uncomments pls
    //this.userDataDtoReturnSession.isFTTrackingReq = true;
    if (
      this.userDataDtoReturnSession.isFTTrackingReq != null &&
      this.userDataDtoReturnSession.isFTTrackingReq
    ) {
      // departure btn block until tracking consent done @09/06/2020
      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.trackingFTConsentStatus !=
        null &&
        (this.hireSlipDtoForCreateHireslipLocalStorage
          .trackingFTConsentStatus == "Accepted" ||
          this.hireSlipDtoForCreateHireslipLocalStorage
            .trackingFTConsentStatus == "Cleared")
      ) {
        this.departureButtonHideShow = true;
      } else if (
        this.hireSlipDtoForCreateHireslipLocalStorage
          .trackingFTTrackingSupported != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage
          .trackingFTTrackingSupported == "unsupported"
      ) {
        this.departureButtonHideShow = true;
      } else {
        this.departureButtonHideShow = false;
      }
    } else {
      //alert("ok5" + this.userDataDtoReturnSession.isFTTrackingReq);
      this.isFTTripReq = false;
    }

    // based on the ftrouteexception @28/07/2020
    var sourceFt =
      this.hireSlipDtoForCreateHireslipLocalStorage.fromstation.toLowerCase();
    var destinationFt =
      this.hireSlipDtoForCreateHireslipLocalStorage.tostation.toLowerCase();
    if (
      this.userDataDtoReturnSession.lrDtosFTRouteExcep != null &&
      this.userDataDtoReturnSession.lrDtosFTRouteExcep != undefined &&
      this.userDataDtoReturnSession.lrDtosFTRouteExcep.length > 0
    ) {
      for (
        let k = 0;
        k < this.userDataDtoReturnSession.lrDtosFTRouteExcep.length;
        k++
      ) {
        //this.lrDtoFTRoutingExcep = new LRDto();
        this.lrDtoFTRoutingExcep =
          this.userDataDtoReturnSession.lrDtosFTRouteExcep[k];
        if (
          sourceFt == this.lrDtoFTRoutingExcep.source.toLowerCase() &&
          destinationFt == this.lrDtoFTRoutingExcep.destination.toLowerCase()
        ) {
          this.departureButtonHideShow = true;
          this.isFTTripReq = false;
          break;
        }
      }
    }
    console.log("HIRESLIP PAGE");
    console.log(this.userDataDtoReturnSession.lrDtosFTRouteExcep);
    // temp remove the mandatory since facing issues with FT consent
    // @14/09/2020, will remove this code once issue solve
    // blocked again on 18/09/2020, hence commented
    // depButton.setVisible(true);
    // new logic to enable dep when the consent starts time exceeded more
    // than 30mins, since facing issues with FT @26/09/2020
    console.log(this.hireSlipDtoForCreateHireslipLocalStorage.diffFtAllow);

    //commenting the below and must done the consent to depart as per Musaddik bh on 11/12/23
    // if (this.hireSlipDtoForCreateHireslipLocalStorage.diffFtAllow != null &&
    //     this.hireSlipDtoForCreateHireslipLocalStorage.diffFtAllow) {
    //     this.departureButtonHideShow = true;
    // }

    //its for testing true we used in live it shd comments pls
    //this.departureButtonHideShow = true;

    //PENDING WORK
    this.getGoodsTypes(
      this.hireSlipDtoForCreateHireslipLocalStorage.vehicleNumber
    );
    this.gurWgt =
      this.hireSlipDtoForCreateHireslipLocalStorage.gurWeight == null
        ? 0.0
        : this.hireSlipDtoForCreateHireslipLocalStorage.gurWeight;
    this.kantaWgt =
      this.hireSlipDtoForCreateHireslipLocalStorage.kantaWeight == null
        ? 0.0
        : this.hireSlipDtoForCreateHireslipLocalStorage.kantaWeight;
    this.suppName = this.hireSlipDtoForCreateHireslipLocalStorage.suppliername;
    this.listOfShortageLrs =
      this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage;
    this.printMode = this.hireSlipDtoForCreateHireslipLocalStorage.mode;
    //hpDesc.add(lbldescription);
    //hpDesc.add(lbldescriptionValue);
    //alert(this.hireSlipDtoLocalStorage.fromstation);
    //alert(this.hireSlipDtoLocalStorage.toStation);
    if (
      this.hireSlipDtoForCreateHireslipLocalStorage.fromstation == "KVH" &&
      this.hireSlipDtoForCreateHireslipLocalStorage.tostation != "Bangalore"
    ) {
      this.hideAndShowInputFieldsForSelectedDestination = true;
      this.hideAndShowInputFieldsDeliveryPoint = false;
    } else {
      this.hideAndShowInputFieldsForSelectedDestination = false;
      this.hideAndShowInputFieldsDeliveryPoint = true;
    }

    if (this.hireSlipDtoForCreateHireslipLocalStorage.invoiceNumber != null) {
      this.invoiceNumberSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.invoiceNumber;
      this.hireslipNumberSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.invoiceNumber;
      this.currentDate = moment(new Date()).format("YYYY-MM-DD");
      this.invoiceDateSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.invoicedate == null
          ? this.currentDate
          : moment(
            this.hireSlipDtoForCreateHireslipLocalStorage.invoicedate
          ).format("YYYY-MM-DD");
      this.sourceSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.fromstation;
      this.destinationSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.tostation;
      this.numberOfLrsSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.list.length;
      this.numberOfArticlesSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.totalArticles;
      this.guaranteeWgtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.gurWeight;
      this.gurWeightTotalSetOnload =
        this.hireSlipDtoForCreateHireslipLocalStorage.gurWeightTotal;
      this.kantaWeightSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.kantaWeight;
      this.loadedBySetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.loadedby;
      this.lorryNumberSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.vehicleNumber;
      this.lorryOwnerSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.vehiCompName;
      this.truckLengthSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.trkLgth;
      /*  if (this.hireSlipDtoForCreateHireslipLocalStorage.trkLgth != null &&
                  this.hireSlipDtoForCreateHireslipLocalStorage.trkLgth != undefined) {
                  this.truckLengthSetOnLoad = this.hireSlipDtoForCreateHireslipLocalStorage.trkLgth;
              } else {
                  this.truckLengthSetOnLoad = this.hireSlipDtoForCreateHireslipLocalStorage.gurWeight;
              }*/
      this.contactPersonSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.contactPerson;
      this.addressSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.vehiCompAddress;
      this.truckDriverNameSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.drivername;
      this.driverLicNoSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.driverlicencenumber;
      this.driverMobileNoSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.driverMobileNumber == null
          ? null
          : this.hireSlipDtoForCreateHireslipLocalStorage.driverMobileNumber;
      this.supplierNameTextSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.suppliername;
      this.panNoSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.vehiCompPanNo;
      this.insuranceNoSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.insuranceNumber;
      this.totalHireAmountSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.totalhire == null
          ? 0
          : this.hireSlipDtoForCreateHireslipLocalStorage.totalhire;
      this.advanceAmountSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.advance;
      this.balanceAmountSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.balance;
      this.touchingHireAmountSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.touchingHire;
      this.payableAtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.tostation;
      this.payableAtDupSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.tostation;
      this.remarksSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.remarks;
      this.deliveryPointsSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.deliveryPoints;
      this.twoPointDDCheckBox = $("#" + this.pageId + "twoPointDD").val();
      this.twoPointsDoorDeliveryMultipleLrs =
        this.hireSlipDtoForCreateHireslipLocalStorage.twoPtLrs;
      this.ewayBillNo = this.hireSlipDtoForCreateHireslipLocalStorage.ewayBill;
      this.ewayBillNoConfirm =
        this.hireSlipDtoForCreateHireslipLocalStorage.ewayBill;

      this.baseHireAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.baseHireAmt;
      this.doorOpenAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.doorOpen;
      this.vehicleHeightAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.vehiHeight;
      this.doorDeliveryAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.doorDelivery;
      this.extraWeightAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.extraWeight;
      this.checkPostAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.chkPost;
      this.otherChargeAmtSetOnLoad =
        this.hireSlipDtoForCreateHireslipLocalStorage.otherChgs;

      $("#" + this.pageId + "inoviceNumber").prop("readonly", true);
      $("#" + this.pageId + "invoiceDates").prop("readonly", true);
      $("#" + this.pageId + "hireslipNumber").prop("readonly", true);
      $("#" + this.pageId + "source").prop("readonly", true);
      $("#" + this.pageId + "destination").prop("readonly", true);
      $("#" + this.pageId + "numberOfLrs").prop("readonly", true);
      $("#" + this.pageId + "numberOfArticles").prop("readonly", true);
      $("#" + this.pageId + "lorryNumber").prop("readonly", true);
      $("#" + this.pageId + "lorryOwner").prop("readonly", true);
      $("#" + this.pageId + "truckLength").prop("readonly", true);
      $("#" + this.pageId + "contactPerson").prop("readonly", true);
      $("#" + this.pageId + "address").prop("readonly", true);
      $("#" + this.pageId + "driverNameText").prop("readonly", true);
      $("#" + this.pageId + "isReadOnlyDriverLicNo").prop("readonly", true);
      $("#" + this.pageId + "supplierNameText").prop("readonly", true);
      //commented as per logic not setting on load of pan & insurance 12042021
      //$("#"+this.pageId+"panNumber").prop('readonly', true);
      $("#" + this.pageId + "scheduleDate").prop("readonly", true);
      $("#" + this.pageId + "scheduleTime").prop("readonly", true);

      /* this.isReadOnlyInvoiceNo = true;
             this.isReadOnlyInvoiceDates = true;
             this.isReadOnlyHireslipNo = true;
             this.isReadOnlySource = true;
             this.isReadOnlyDestination = true;
             this.isReadOnlyNumberOfLrs = true;
             this.isReadOnlyTotalNoArticle = true;
             this.isReadOnlyLorryNo = true;
             this.isReadOnlyLorryOwner = true;
             this.isReadOnlyTruckLength = true;
             this.isReadOnlyLorryContactPerson = true;
             this.isReadOnlyOwnerAddress = true;
             this.isReadOnlyDriverName = true;
             this.isReadOnlyDriverLicNo = true;
             this.isReadOnlySupplierName = true;
             this.isReadOnlyPanNo = true;*/

      $("#" + this.pageId + "inoviceNumber").val(this.invoiceNumberSetOnLoad);
      $("#" + this.pageId + "invoiceDates").val(this.invoiceDateSetOnLoad);
      $("#" + this.pageId + "hireslipNumber").val(this.hireslipNumberSetOnLoad);
      $("#" + this.pageId + "source").val(this.sourceSetOnLoad);
      $("#" + this.pageId + "destination").val(this.destinationSetOnLoad);
      $("#" + this.pageId + "numberOfLrs").val(this.numberOfLrsSetOnLoad);
      $("#" + this.pageId + "numberOfArticles").val(
        this.numberOfArticlesSetOnLoad
      );
      $("#" + this.pageId + "guranteeWeight").val(this.guaranteeWgtSetOnLoad);
      $("#" + this.pageId + "gurWeightTotal").val(this.gurWeightTotalSetOnload);
      $("#" + this.pageId + "kantaWeight").val(this.kantaWeightSetOnLoad);
      $("#" + this.pageId + "loadedBy").val(this.loadedBySetOnLoad);
      $("#" + this.pageId + "lorryNumber").val(this.lorryNumberSetOnLoad);
      $("#" + this.pageId + "lorryOwner").val(this.lorryOwnerSetOnLoad);
      $("#" + this.pageId + "truckLength").val(this.truckLengthSetOnLoad);
      $("#" + this.pageId + "contactPerson").val(this.contactPersonSetOnLoad);
      $("#" + this.pageId + "address").val(this.addressSetOnLoad);
      $("#" + this.pageId + "driverNameText").val(
        this.truckDriverNameSetOnLoad
      );
      $("#" + this.pageId + "driverLicenseNo").val(this.driverLicNoSetOnLoad);
      if (this.driverMobileNoSetOnLoad != null) {
        $("#" + this.pageId + "driverMobileNo").val(
          this.driverMobileNoSetOnLoad
        );
      }
      $("#" + this.pageId + "supplierNameText").val(
        this.supplierNameTextSetOnLoad
      );
      //commented as per logic not setting on load of pan & insurance 12042021
      //$("#"+this.pageId+"panNumber").val(this.panNoSetOnLoad);
      //$("#"+this.pageId+"insuranceNo").val(this.insuranceNoSetOnLoad);

      //

      $("#" + this.pageId + "baseHireAmt").val(this.baseHireAmtSetOnLoad);
      $("#" + this.pageId + "doorOpen").val(this.doorOpenAmtSetOnLoad);
      $("#" + this.pageId + "vehicleHeight").val(
        this.vehicleHeightAmtSetOnLoad
      );
      $("#" + this.pageId + "doorDelivery").val(this.doorDeliveryAmtSetOnLoad);
      $("#" + this.pageId + "extraWeight").val(this.extraWeightAmtSetOnLoad);
      $("#" + this.pageId + "checkPost").val(this.checkPostAmtSetOnLoad);
      $("#" + this.pageId + "otherCharges").val(this.otherChargeAmtSetOnLoad);

      if (this.totalHireAmountSetOnLoad == null) {
        this.totalHireAmountSetOnLoad = 0.0;
        $("#" + this.pageId + "totalHire").val(this.totalHireAmountSetOnLoad);
      } else {
        $("#" + this.pageId + "totalHire").val(this.totalHireAmountSetOnLoad);
      }

      if (this.advanceAmountSetOnLoad == null) {
        this.advanceAmountSetOnLoad = 0.0;
        $("#" + this.pageId + "advance").val(this.advanceAmountSetOnLoad);
      } else {
        $("#" + this.pageId + "advance").val(this.advanceAmountSetOnLoad);
      }

      if (this.balanceAmountSetOnLoad == null) {
        this.balanceAmountSetOnLoad = 0.0;
        $("#" + this.pageId + "balance").val(this.balanceAmountSetOnLoad);
      } else {
        $("#" + this.pageId + "balance").val(this.balanceAmountSetOnLoad);
      }
      if (this.touchingHireAmountSetOnLoad == null) {
        this.touchingHireAmountSetOnLoad = 0.0;
        $("#" + this.pageId + "touchingHire").val(
          this.touchingHireAmountSetOnLoad
        );
      } else {
        $("#" + this.pageId + "touchingHire").val(
          this.touchingHireAmountSetOnLoad
        );
      }

      $("#" + this.pageId + "payableAt").val(this.payableAtSetOnLoad);
      $("#" + this.pageId + "hireslipRemarksId").val(this.remarksSetOnLoad);
      $("#" + this.pageId + "deliveryPoints").val(this.deliveryPointsSetOnLoad);

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.ewayBill != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.ewayBill != ""
      ) {
        $("#" + this.pageId + "consolidatedEwayBill").val(this.ewayBillNo);
        $("#" + this.pageId + "confirmConsolidatedEwayBill").val(
          this.ewayBillNoConfirm
        );
      }

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.twoPtLrs != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.twoPtLrs != ""
      ) {
        $("#" + this.pageId + "twoPointDD").prop("checked", true);
        this.hideShowTwoPointDoorDelivery = true;
        setTimeout(() => {
          $("#" + this.pageId + "twoPointsDoorDeliveryLrs").val(
            this.twoPointsDoorDeliveryMultipleLrs
          );
        }, 2000);
      }

      //PENDING WORK
      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage.length !=
        0
      ) {
        this.viewShortExtraDescription = true;
        this.shortageLrs = null;
        for (
          let i = 0;
          i <
          this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage.length;
          i++
        ) {
          this.spiltLrs =
            this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage[
              i
            ].split("-");
          if (this.spiltLrs[1] != null && this.spiltLrs[1] > 0) {
            this.lrShrotageSpilt =
              this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage[i];
            this.shortageLrs = this.shortageLrs + "," + this.lrShrotageSpilt;
          }
        }
        if (this.shortageLrs != null) {
          var messageDisplay = this.shortageLrs.replace("null,", "");
          $("#" + this.pageId + "shortExtraDescriptionId").text(
            "Shortage Articles: " + messageDisplay
          );
        }
      }

      /*Departure Date & Time Flow Start */
      if (this.hireSlipDtoForCreateHireslipLocalStorage.departuredate != null) {
        this.departureDate =
          this.hireSlipDtoForCreateHireslipLocalStorage.departuredate;
        $("#" + this.pageId + "departureDate").val(
          moment(this.departureDate).format("YYYY-MM-DD")
        );
        //$("#"+this.pageId+"departureDate").val(this.departureDate);
      } else {
        this.departureDate = new Date();
        $("#" + this.pageId + "departureDate").val(
          moment(this.departureDate).format("YYYY-MM-DD")
        );
      }

      if (this.hireSlipDtoForCreateHireslipLocalStorage.departureTime != null) {
        this.departureTime =
          this.hireSlipDtoForCreateHireslipLocalStorage.departureTime;
        $("#" + this.pageId + "departureTime").val(this.departureTime);
      }
      /*Departure Date & Time Flow End */

      /*Schedule Date & Time Flow Start */
      if (this.hireSlipDtoForCreateHireslipLocalStorage.scheduleddate != null) {
        this.scheduledDate =
          this.hireSlipDtoForCreateHireslipLocalStorage.scheduleddate;
        $("#" + this.pageId + "scheduleDate").val(this.scheduledDate);
      }

      if (this.hireSlipDtoForCreateHireslipLocalStorage.scheduleddate != null) {
        this.scheduledTime =
          this.hireSlipDtoForCreateHireslipLocalStorage.scheduledTime;
        $("#" + this.pageId + "scheduleTime").val(this.scheduledTime);
      }
      /*Schedule Date & Time Flow End */

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiPntDelv != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiPntDelv
      ) {
        $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
          "checked",
          true
        );
        $("#" + this.pageId + "deliveryPointIdForSingle").prop(
          "checked",
          false
        );
        //this.gridReconfigurMutlDevPt();
        this.multipleDeliveryPointListPopupReturn = null;
        this.multipleDeliveryPointListPopupReturn =
          this.hireSlipDtoForCreateHireslipLocalStorage.listOfInvoice;
        this.firstPtDeliv =
          this.hireSlipDtoForCreateHireslipLocalStorage.firstPntDelv;
      } else {
        $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", true);
        $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
          "checked",
          false
        );
      }

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.status != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.status == "Transit"
      ) {
        this.hideAndShowPrintInvoiceBtn = true;
        this.saveAndPrintButtonHideShow = false;
        this.departureButtonHideShow = false;
      } else {
        this.hideAndShowPrintInvoiceBtn = false;
        this.saveAndPrintButtonHideShow = true;
        /* this.saveAndPrintButtonHideShow = true;
                 this.departureButtonHideShow = true;*/
      }

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.reportMode != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.reportMode == "report"
      ) {
        this.hideAndShowPrintHireslipBtn = true;
        this.hideAndShowInvSummPrintBtn = true;
      } else {
        this.hideAndShowPrintHireslipBtn = false;
        this.hideAndShowInvSummPrintBtn = false;
      }

      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiInvFT != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiInvFT
      ) {
        $("#" + this.pageId + "isMultiInvoiceTripId").prop("checked", true);
        $("#" + this.pageId + "islastPointDeliveryId").prop("checked", true);
      } else {
        $("#" + this.pageId + "isMultiInvoiceTripId").prop("checked", false);
        $("#" + this.pageId + "islastPointDeliveryId").prop("checked", false);
      }

      //PENDING WORK
      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.isLastPtDelvFT != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.isLastPtDelvFT
      ) {
        this.hideShowIsLastPointDelivery = true;
        $("#" + this.pageId + "isMultiInvoiceTripId").prop("checked", true);
        this.gridReconfigurMultiInvoice();
        setTimeout(() => {
          $("#" + this.pageId + "islastPointDeliveryId").prop("checked", false);
          $("#" + this.pageId + "islastPointDeliveryId").prop("checked", true);
        }, 2000);
      } else {
        this.hideShowIsLastPointDelivery = false;
        this.multipleInvoiceIsLastPointListPopupReturn = [];
        $("#" + this.pageId + "isMultiInvoiceTripId").prop("checked", false);
        $("#" + this.pageId + "islastPointDeliveryId").prop("checked", false);
      }
      this.getShortExtraDetails();
    }
    this.getNoEwayBillLrDetails();

    //
    /*if (this.hireSlipDtoForCreateHireslipLocalStorage.status != null &&
            this.hireSlipDtoForCreateHireslipLocalStorage.status == "Pending") {
            $("#"+this.pageId+"driverMobileNo").prop('readonly', false);
        } else {
            $("#"+this.pageId+"driverMobileNo").prop('readonly', true);
        }*/

    //
    if (
      this.hireSlipDtoForCreateHireslipLocalStorage.mode != null &&
      this.hireSlipDtoForCreateHireslipLocalStorage.mode == "view"
    ) {
      this.setReadOnlyFields();
      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiPntDelv != null &&
        this.hireSlipDtoForCreateHireslipLocalStorage.isMultiPntDelv
      ) {
        $("#" + this.pageId + "deliveryPointIdForSingle").prop(
          "checked",
          false
        );
        $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
          "checked",
          true
        );
      } else {
        $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", true);
        $("#" + this.pageId + "deliveryPointIdForMultiple").prop(
          "checked",
          false
        );
      }
    }

    if (
      this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId != null &&
      this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId !=
      undefined &&
      this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId > 0
    ) {
      this.showSpinnerActionForHireslip = true;
      setTimeout(() => {
        var truckTypeCommonId = this.hmTruckTypeDto.get(
          this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId
        );
        //$("#"+this.pageId+"truckType").val(truckTypeCommonId.truckType);
        this.showSpinnerActionForHireslip = false;
      }, 2000);
    }
    // add a piece of code in last
    console.log("1 : " + this.isBookingStation);
    if (this.isBookingStation) {
      console.log(
        "2 : " + this.hireSlipDtoForCreateHireslipLocalStorage.status
      );
      if (
        this.hireSlipDtoForCreateHireslipLocalStorage.status == "Loading" ||
        this.hireSlipDtoForCreateHireslipLocalStorage.status == "Scheduled"
      ) {
        console.log(
          "3 : " + this.hireSlipDtoForCreateHireslipLocalStorage.status
        );
        this.setDepartureDateTime();
      }

      this.settingDefaultsForBooking();
    }
    //basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      console.log(this.tripScheduledToLocalStorage_HireslipRpt);
      this.hideAndShowInvSummPrintBtn = true;
      this.hideAndShowPrintHireslipBtn = true;
      this.hideAndShowPrintInvoiceBtn = true;
      this.departureButtonHideShow = false;
      this.saveAndPrintButtonHideShow = false;
      this.resetBtnHideAndShow = false;
      this.startoverBtnHideAndShow = false;
    } else {
      this.resetBtnHideAndShow = true;
      this.startoverBtnHideAndShow = true;
    }
    //Imran_SG_Chg_V3
    if (
      this.hireSlipDtoForCreateHireslipLocalStorage.disableHireInGeneration != null &&
      this.hireSlipDtoForCreateHireslipLocalStorage.disableHireInGeneration
    ) {
      this.disableHireEntry = true;
      this.viewHireEntry = false;
    }
  }

  setReadOnlyFields() {
    $("#" + this.pageId + "totalHire").prop("readonly", true);
    $("#" + this.pageId + "advance").prop("readonly", true);
    $("#" + this.pageId + "balance").prop("readonly", true);
    $("#" + this.pageId + "touchingHire").prop("readonly", true);

    $("#" + this.pageId + "payableAt").prop("readonly", true);
    $("#" + this.pageId + "hireslipRemarksId").prop("readonly", true);
    $("#" + this.pageId + "deliveryPoints").prop("readonly", true);
    $("#" + this.pageId + "deliveryPoints").prop("readonly", true);
    $("#" + this.pageId + "lorryNumber").prop("readonly", true);
    $("#" + this.pageId + "supplierNameText").prop("readonly", true);
    //$("#"+this.pageId+"panNumber").prop('readonly', true);
    $("#" + this.pageId + "contactPerson").prop("readonly", true);
    $("#" + this.pageId + "driverNameText").prop("readonly", true);
    $("#" + this.pageId + "driverLicenseNo").prop("readonly", true);
    $("#" + this.pageId + "departureDate").prop("readonly", true);
    this.showDepartureDateIcon = false;
    $("#" + this.pageId + "scheduleDate").prop("readonly", true);
    $("#" + this.pageId + "guranteeWeight").prop("readonly", true);
    $("#" + this.pageId + "gurWeightTotal").prop("readonly", true);

    $("#" + this.pageId + "kantaWeight").prop("readonly", true);
    $("#" + this.pageId + "driverMobileNo").prop("readonly", true);
    $("#" + this.pageId + "invoiceDates").prop("readonly", true);
    //$("#"+this.pageId+"invoiceCalenderId").prop('readonly', true);
    $("#" + this.pageId + "departureTime").prop("readonly", true);
    $("#" + this.pageId + "scheduleTime").prop("readonly", true);

    $("#" + this.pageId + "saveButton").prop("disabled", false);
    $("#" + this.pageId + "depButton").prop("disabled", false);
    //$("#"+this.pageId+"updateDriverBtnId").prop('disabled', true);

    $("#" + this.pageId + "baseHireAmt").prop("readonly", true);
    $("#" + this.pageId + "doorOpen").prop("readonly", true);
    $("#" + this.pageId + "vehicleHeight").prop("readonly", true);
    $("#" + this.pageId + "doorDelivery").prop("readonly", true);
    $("#" + this.pageId + "extraWeight").prop("readonly", true);
    $("#" + this.pageId + "checkPost").prop("readonly", true);
    $("#" + this.pageId + "otherCharges").prop("readonly", true);

    $("#" + this.pageId + "payableAt").prop("readonly", true);
    $("#" + this.pageId + "loadedBy").prop("readonly", true);
  }

  settingDefaultsForBooking() {
    $("#" + this.pageId + "departureDate").prop("readonly", true);
    this.showDepartureDateIcon = false;
    $("#" + this.pageId + "scheduleDate").prop("readonly", true);
  }

  getShortExtraDetails() {
    this.hireSlipDtoForShortAndExtra = new HireSlipDto();
    //Pending Need To Work   hireslip no this.hireslipNumber;
    //this.hireSlipDto.hireslipnumber ="NP/CLT/1920/30";
    this.hireSlipDtoForShortAndExtra.hireslipnumber =
      this.hireslipNumberSetOnLoad;
    this.hireSlipDtoForShortAndExtra.companyid =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .getLRShortageDtls(this.hireSlipDtoForShortAndExtra)
      .subscribe((response) => {
        this.goodsShortAndExtraDetailsDataList = [];
        $("#" + this.pageId + "goodsShortageExtraLrDetailsId")
          .DataTable()
          .destroy();
        if (response.length > 0) {
          //console.log(response);
          this.goodsShortAndExtraDetailsDataList = response;
          this.dtTriggerGoodsShortAndExtraDetails.next();
        } else {
          this.dtTriggerGoodsShortAndExtraDetails.next();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting getShortExtraDetails",
          "warning"
        );
      },
      () => console.log("done");
  }

  getNoEwayBillLrDetails() {
    this.ewayBillLrForLRDto = new LRDto();
    this.ewayLrs = "";
    this.countEwayLrs = 0;
    this.countEwayLrsAbove50K = 0;
    this.countEwayLrsBelow50K = 0;

    //this.ewayBillLrForLRDto.invoiceNumber ="NP/CLT/1920/30";
    this.ewayBillLrForLRDto.invoiceNumber =
      this.hireSlipDtoForCreateHireslipLocalStorage.invoiceNumber;
    this.ewayBillLrForLRDto.companyId = this.userDataDtoReturnSession.companyId;
    this.stockService
      .getLrsForNoEwayBillValidations(this.ewayBillLrForLRDto)
      .subscribe((response) => {
        //console.log("response");
        //console.log(response.length);
        //console.log(response);
        if (response != null) {
          this.returnEwayBillLrsLRDto = [];
          this.returnEwayBillLrsLRDto.push(response);
          this.hashmapEwayBill = new Map<String, Number>();
          this.goodsValueFind = null;
          for (let i = 0; i < this.returnEwayBillLrsLRDto.length; i++) {
            if (
              this.hashmapEwayBill.get(
                this.returnEwayBillLrsLRDto[i].gstNoConsignee
              ) == null
            ) {
              this.hashmapEwayBill.set(
                this.returnEwayBillLrsLRDto[i].gstNoConsignee,
                this.returnEwayBillLrsLRDto[i].goodsValue
              );
            } else {
              this.goodsValueFind = this.hashmapEwayBill.get(
                this.returnEwayBillLrsLRDto[i].gstNoConsignee
              );
              this.hashmapEwayBill.set(
                this.returnEwayBillLrsLRDto[i].gstNoConsignee,
                this.returnEwayBillLrsLRDto[i].goodsValue + this.goodsValueFind
              );
            }
          }
          this.goodsValueFind1 = null;
          this.ewayBill = null;
          for (let j = 0; j < this.returnEwayBillLrsLRDto.length; j++) {
            // logic for eway status @Dated : 26/12/2018
            this.goodsValueFind1 = this.hashmapEwayBill.get(
              this.returnEwayBillLrsLRDto[j].gstNoConsignee
            );
            this.ewayBill = this.hashmapEwayBill.get(
              this.returnEwayBillLrsLRDto[j].eWayBillNo
            );
            if (this.goodsValueFind1 >= 50000 && this.ewayBill == null) {
              if (this.countEwayLrs == 0) {
                this.ewayBill += this.returnEwayBillLrsLRDto[j].lrNumber;
              } else {
                this.ewayBill += ", " + this.returnEwayBillLrsLRDto[j].lrNumber;
              }
              this.countEwayLrs++;
            }
            // goods value greater than 50K and eway bill is not null
            // goods value lesser than 50K and eway bill is not null
            if (
              this.returnEwayBillLrsLRDto[j].goodsValue >= 50000 &&
              this.ewayBill != null
            ) {
              this.countEwayLrsAbove50K = +this.countEwayLrsAbove50K + +1;
            } else if (
              this.returnEwayBillLrsLRDto[j].goodsValue < 50000 &&
              this.ewayBill != null
            ) {
              this.countEwayLrsAbove50K = +this.countEwayLrsAbove50K + +1;
            }
          }
          $("#" + this.pageId + "noOfNoEwbInConsolidatedSystem").val(
            this.countEwayLrs
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting No EwayBill LR Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  getGoodsTypes(vehicleNumber) {
    this.hireSlipDtoGoodsTypes = new HireSlipDto();
    this.hireSlipDtoGoodsTypes.vehicleNumber = vehicleNumber;
    this.hireSlipDtoGoodsTypes.companyid =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .getTruckTypeFromTruckMaster(this.hireSlipDtoGoodsTypes)
      .subscribe((response) => {
        console.log(response.truckType);
        if (response.truckType == "Select Type") {
          this.goodsType = "NA";
        } else {
          this.goodsType = response.truckType;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "Server Error While Getting GoodsTypes", "warning");
      },
      () => console.log("done");
  }

  //Pending Need to work
  gridReconfigurMutlDevPt() {
    // No used inside pop up we put logic of this
    console.log(this.hireSlipDtoForCreateHireslipLocalStorage.hashMap);
    for (let [key, value] of Object.entries(
      this.hireSlipDtoForCreateHireslipLocalStorage.hashMap
    )) {
      console.log(key, value);
    }
  }

  gridReconfigurMultiInvoice() {
    this.multipleInvoiceIsLastPointListPopupReturn = [];
    if (this.hireSlipDtoForCreateHireslipLocalStorage.listOfDest != null) {
      for (
        let i = 0;
        i < this.hireSlipDtoForCreateHireslipLocalStorage.listOfDest.length;
        i++
      ) {
        this.multipleInvoiceIsLastPointListPopupReturn.push(
          this.hireSlipDtoForCreateHireslipLocalStorage.listOfDest[i]
        );
      }
    }
  }

  getUsersValue() {
    this.hireSlipDtoUserValue = new HireSlipDto();


    this.hireslipNumber = $("#" + this.pageId + "hireslipNumber").val();
    this.invoiceDate = $("#" + this.pageId + "invoiceDates").val();
    //this.invoiceDate = moment($("#"+this.pageId+"invoiceDate").val()).format('YYYY/MM/DD');
    this.source = $("#" + this.pageId + "source").val();
    this.destination = $("#" + this.pageId + "destination").val();
    this.invoiceNumber = $("#" + this.pageId + "inoviceNumber").val();
    this.lorryNumber = $("#" + this.pageId + "lorryNumber").val();
    this.lorryOwner = $("#" + this.pageId + "lorryOwner").val();

    this.panNo = $("#" + this.pageId + "panNumber").val();
    this.address = $("#" + this.pageId + "address").val();
    this.numberOfLrs = $("#" + this.pageId + "numberOfLrs").val();
    this.numberOfArticles = $("#" + this.pageId + "numberOfArticles").val();

    this.supplierNameText = $("#" + this.pageId + "supplierNameText").val();
    //this.panNo = $("#"+this.pageId+"supplierNameText").val();
    this.contactPerson = $("#" + this.pageId + "contactPerson").val();
    this.truckDriverName = $("#" + this.pageId + "driverNameText").val();
    this.driverLicNo = $("#" + this.pageId + "driverLicenseNo").val();
    this.driverMobileNo = $("#" + this.pageId + "driverMobileNo").val();

    this.payableAt = $("#" + this.pageId + "payableAt").val();
    this.guaranteeWgt = $("#" + this.pageId + "guranteeWeight").val();
    this.gurWgtTotal = $("#" + this.pageId + "gurWeightTotal").val();
    this.kantaWeight = $("#" + this.pageId + "kantaWeight").val();
    this.loadedBy = $("#" + this.pageId + "loadedBy").val();

    /* this.validateDepartureDT = $("#"+this.pageId+"departureDateTime").val();
         this.validateScheduledDT = $("#"+this.pageId+"scheduleDateTime").val();*/
    this.validateDepartureDate = $("#" + this.pageId + "departureDate").val();
    this.validateDepartureTime = $("#" + this.pageId + "departureTime").val();
    this.validateScheduledDate = $("#" + this.pageId + "scheduleDate").val();
    this.validateScheduledTime = $("#" + this.pageId + "scheduleTime").val();

    this.totalHireAmount = $("#" + this.pageId + "totalHire").val();
    this.advanceAmount = $("#" + this.pageId + "advance").val();
    this.balanceAmount = $("#" + this.pageId + "balance").val();
    this.touchingHire = $("#" + this.pageId + "touchingHire").val();
    this.remarks = $("#" + this.pageId + "hireslipRemarksId").val();
    this.deliveryPoints = $("#" + this.pageId + "deliveryPoints").val();
    this.truckLength = $("#" + this.pageId + "truckLength").val();

    this.baseHireAmount = $("#" + this.pageId + "baseHireAmt").val();
    this.doorOpenAmount = $("#" + this.pageId + "doorOpen").val();
    this.vehiHeightAmount = $("#" + this.pageId + "vehicleHeight").val();
    this.doorDeliveryAmount = $("#" + this.pageId + "doorDelivery").val();
    this.extraWeightAmount = $("#" + this.pageId + "extraWeight").val();
    this.chkPostAmount = $("#" + this.pageId + "checkPost").val();
    this.otherChargesAmount = $("#" + this.pageId + "otherCharges").val();
    this.ewayBillNumber = $("#" + this.pageId + "consolidatedEwayBill").val();
    this.twoPtDoorDelyLrs = $(
      "#" + this.pageId + "twoPointsDoorDeliveryLrs"
    ).val();

    this.hireSlipDtoUserValue.hireslipnumber = this.hireslipNumber;
    this.hireSlipDtoUserValue.hireSlipDateStr = this.invoiceDate;
    //this.hireSlipDtoUserValue.hireslipdate =  this.invoiceDate;
    this.hireSlipDtoUserValue.fromstation =
      this.hireSlipDtoForCreateHireslipLocalStorage.fromstation;
    this.hireSlipDtoUserValue.tostation = this.destination;
    this.hireSlipDtoUserValue.invoiceNumber = this.invoiceNumber;
    this.hireSlipDtoUserValue.vehicleNumber = this.lorryNumber;
    this.hireSlipDtoUserValue.vehiCompName = this.lorryOwner;

    this.hireSlipDtoUserValue.vehiCompPanNo = this.panNo;
    this.hireSlipDtoUserValue.vehiCompAddress = this.address;
    this.hireSlipDtoUserValue.totalLRs = this.numberOfLrs;
    this.hireSlipDtoUserValue.totalArticles =
      this.numberOfArticles == null ? 0 : this.numberOfArticles;

    this.hireSlipDtoUserValue.brokerName = this.supplierNameText;
    this.hireSlipDtoUserValue.supplierpanno = this.panNo;
    this.hireSlipDtoUserValue.contactPerson = this.contactPerson;
    this.hireSlipDtoUserValue.drivername = this.truckDriverName;
    this.hireSlipDtoUserValue.driverlicencenumber = this.driverLicNo;
    this.hireSlipDtoUserValue.driverMobileNumber = this.driverMobileNo;

    this.hireSlipDtoUserValue.driverId =
      this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
    this.hireSlipDtoUserValue.payableat = this.payableAt;
    this.hireSlipDtoUserValue.gurWeight =
      this.guaranteeWgt == null ? 0 : this.guaranteeWgt;
    this.hireSlipDtoUserValue.gurWeightTotal =
      this.gurWgtTotal == null ? 0 : this.gurWgtTotal;
    this.hireSlipDtoUserValue.kantaWeight =
      this.kantaWeight == null ? 0 : this.kantaWeight;
    this.hireSlipDtoUserValue.loadedby = this.loadedBy;

    this.arrivalTime = null;
    this.departureTime = null;
    this.scheduledTime = null;

    if (
      this.validateDepartureDate != null &&
      this.validateDepartureDate != ""
    ) {
      this.departureDate = moment(this.validateDepartureDate).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.validateDepartureTime != null &&
      this.validateDepartureTime != ""
    ) {
      this.departureTimeSplit = [];
      this.departureTimeSplit = this.validateDepartureTime.split(":");
      this.departureTime =
        this.departureTimeSplit[0] + ":" + this.departureTimeSplit[1];
    }
    if (
      this.validateScheduledDate != null &&
      this.validateScheduledDate != ""
    ) {
      this.scheduledDate = moment(this.validateScheduledDate).format(
        "YYYY-MM-DD"
      );
    }
    if (
      this.validateScheduledTime != null &&
      this.validateScheduledTime != ""
    ) {
      var arrivalTimeSplit = [];
      arrivalTimeSplit = this.validateScheduledTime.split(":");
      this.arrivalTime = arrivalTimeSplit[0] + ":" + arrivalTimeSplit[1];
    }

    this.hireSlipDtoUserValue.departureTime = this.departureTime;
    this.hireSlipDtoUserValue.scheduledTime = this.arrivalTime;
    this.hireSlipDtoUserValue.departuredateStr = this.departureDate;
    this.hireSlipDtoUserValue.scheduleddateStr = this.scheduledDate;
    // null exception time service as per bhaiya said so pls don't remove
    this.hireSlipDtoUserValue.departuredate = this.departureDate;

    this.hireSlipDtoUserValue.enteryby = this.userDataDtoReturnSession.userId;
    this.hireSlipDtoUserValue.financialyear =
      this.userDataDtoReturnSession.financialyear;
    this.hireSlipDtoUserValue.lastupdatedby =
      this.userDataDtoReturnSession.userId;
    this.hireSlipDtoUserValue.list =
      this.hireSlipDtoForCreateHireslipLocalStorage.list;
    this.hireSlipDtoUserValue.supId =
      this.hireSlipDtoForCreateHireslipLocalStorage.supId;
    this.hireSlipDtoUserValue.stocksAt = this.userDataDtoReturnSession.office;
    this.hireSlipDtoUserValue.companyName = "SRD Logistics Pvt.Ltd";
    if (this.hireSlipDtoForCreateHireslipLocalStorage.hireslipnumber != null) {
      this.hireSlipDtoUserValue.mode = "update";
    } else {
      this.hireSlipDtoUserValue.mode = "add";
    }

    if (
      this.hireSlipDtoUserValue.fromstation != null &&
      this.hireSlipDtoUserValue.fromstation == "KVH" &&
      !(this.destination == "Bangalore Delivery Godown")
    ) {
      this.hireSlipDtoUserValue.totalhire =
        this.totalHireAmount == null ? 0 : this.totalHireAmount;
      this.hireSlipDtoUserValue.advance =
        this.advanceAmount == null ? 0 : this.advanceAmount;
      this.hireSlipDtoUserValue.balance =
        (this.totalHireAmount == null ? 0 : this.totalHireAmount) -
        (this.advanceAmount == null ? 0 : this.advanceAmount);
      this.hireSlipDtoUserValue.remarks = this.remarks;
      this.hireSlipDtoUserValue.isMultiInvFT = $(
        "#" + this.pageId + "isMultiInvoiceTripId"
      ).is(":checked");
      this.hireSlipDtoUserValue.isLastPtDelvFT = $(
        "#" + this.pageId + "islastPointDeliveryId"
      ).is(":checked");
    } else {
      //pending total hiredup value totalHireAmountDup we same does here logic if else onload hide show
      this.hireSlipDtoUserValue.totalhire =
        this.totalHireAmount == null ? 0 : this.totalHireAmount;
      this.hireSlipDtoUserValue.advance =
        this.advanceAmount == null ? 0 : this.advanceAmount;
      this.hireSlipDtoUserValue.balance =
        (this.totalHireAmount == null ? 0 : this.totalHireAmount) -
        (this.advanceAmount == null ? 0 : this.advanceAmount);
      this.hireSlipDtoUserValue.remarks = this.remarks;
      this.hireSlipDtoUserValue.isMultiInvFT = $(
        "#" + this.pageId + "isMultiInvoiceTripId"
      ).is(":checked");
      this.hireSlipDtoUserValue.isLastPtDelvFT = $(
        "#" + this.pageId + "islastPointDeliveryId"
      ).is(":checked");
    }
    //alert($("#"+this.pageId+"isMultiInvoiceTripId").is(":checked"));
    //alert($("#"+this.pageId+"isLastPointDeliveryLinkedHireslipListner").is(":checked"));

    this.hireSlipDtoUserValue.deliveryPoints =
      this.deliveryPoints == null ? 1 : this.deliveryPoints;
    this.hireSlipDtoUserValue.doorOpen =
      this.doorOpenAmount == null ? 0 : this.doorOpenAmount;
    this.hireSlipDtoUserValue.vehiHeight =
      this.vehiHeightAmount == null ? 0 : this.vehiHeightAmount;
    this.hireSlipDtoUserValue.doorDelivery =
      this.doorDeliveryAmount == null ? 0 : this.doorDeliveryAmount;
    this.hireSlipDtoUserValue.extraWeight =
      this.extraWeightAmount == null ? 0 : this.extraWeightAmount;
    this.hireSlipDtoUserValue.chkPost =
      this.chkPostAmount == null ? 0 : this.chkPostAmount;
    this.hireSlipDtoUserValue.otherChgs =
      this.otherChargesAmount == null ? 0 : this.otherChargesAmount;
    this.hireSlipDtoUserValue.baseHireAmt =
      this.baseHireAmount == null ? 0 : this.baseHireAmount;

    //this.validateDeliveryPtSingleMult = $("#"+this.pageId+"deliveryPointIdForMultiple").val();
    this.validateDeliveryPtSingleMult = $(
      "#" + this.pageId + "deliveryPointIdForMultiple"
    ).is(":checked");
    if (this.validateDeliveryPtSingleMult == true) {
      this.hireSlipDtoUserValue.isMultiPntDelv = true;
      this.listInvoice = this.getInvoiceNumbers();
      this.hireSlipDtoUserValue.listOfInvoice = this.listInvoice;
      this.hireSlipDtoUserValue.firstPntDelv = this.firstPtDeliv;
    } else {
      this.hireSlipDtoUserValue.isMultiPntDelv = false;
    }
    if (
      $("#" + this.pageId + "departureTime").val() != null &&
      $("#" + this.pageId + "departureTime").val() != ""
    ) {
      this.departureTimeSplit = [];
      this.departureTimeSplit = this.validateDepartureTime.split(":");
      this.hireSlipDtoUserValue.depHours = this.departureTimeSplit[0];
      this.hireSlipDtoUserValue.depMins = this.departureTimeSplit[1];
      console.log(this.departureTimeSplit[0], this.departureTimeSplit[1]);
    }
    this.hireSlipDtoUserValue.column2 =
      this.hireSlipDtoForCreateHireslipLocalStorage.column2;
    this.hireSlipDtoUserValue.listLrsShortage =
      this.hireSlipDtoForCreateHireslipLocalStorage.listLrsShortage;
    this.hireSlipDtoUserValue.barcodeValue =
      this.hireSlipDtoForCreateHireslipLocalStorage.barcodeValue;

    // newly added
    this.hireSlipDtoUserValue.toPay =
      this.hireSlipDtoForCreateHireslipLocalStorage.toPay;
    this.hireSlipDtoUserValue.paid =
      this.hireSlipDtoForCreateHireslipLocalStorage.paid;
    this.hireSlipDtoUserValue.truckType = this.goodsType;
    // Niyamath
    this.hireSlipDtoUserValue.trkLgth =
      this.truckLength == null ? 0 : this.truckLength;
    this.printRewardYesNoValidate = $(
      "#" + this.pageId + "printRewardDetails"
    ).val();
    if (this.printRewardYesNoValidate == "yes") {
      this.hireSlipDtoUserValue.isRewarded = true;
    } else {
      this.hireSlipDtoUserValue.isRewarded = false;
    }
    //Not used this current new application  
    //this.hireSlipDtoUserValue.deviceId = null;this.hireSlipDtoUserValue.ewayBill = this.ewayBillNumber;

    //Asrar_Jr_Chg_V8
    // this.hireSlipDtoUserValue.ewayBill = this.ewayBillNumber;
    this.hireSlipDtoUserValue.ewayBill = this.ewayBillNumber == null ? null : this.ewayBillNumber == '' ? null
    : this.ewayBillNumber;
    this.hireSlipDtoUserValue.twoPtLrs = this.twoPtDoorDelyLrs;
    if (
      this.selectedTruckTypeId != null &&
      this.selectedTruckTypeId != undefined &&
      this.selectedTruckTypeId != ""
    ) {
      this.hireSlipDtoUserValue.truckTypeCommonId = this.selectedTruckTypeId;
    } else {
      this.hireSlipDtoUserValue.truckTypeCommonId =
        this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId;
    }
    console.log(this.multipleInvoiceIsLastPointListPopupReturn);
    this.hireSlipDtoUserValue.trackingFTTripId =
      this.hireSlipDtoForCreateHireslipLocalStorage.trackingFTTripId;
    this.hireSlipDtoUserValue.listOfDest =
      this.multipleInvoiceIsLastPointListPopupReturn;
    this.hireSlipDtoUserValue.isFTTripReq = this.isFTTripReq;
    this.hireSlipDtoUserValue.companyid =
      this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoUserValue.touchingHire =
      this.touchingHire == null ? 0 : this.touchingHire;
    //Imran_SG_Chg_V3 
    this.hireSlipDtoUserValue.disableHireInGeneration =
      this.hireSlipDtoForCreateHireslipLocalStorage.disableHireInGeneration;
    return this.hireSlipDtoUserValue;
  }

  getInvoiceNumbers() {
    this.listOfInvoice = new Array<String>();
    if (
      this.multipleDeliveryPointListPopupReturn != null &&
      this.multipleDeliveryPointListPopupReturn != undefined &&
      this.multipleDeliveryPointListPopupReturn.length > 0
    ) {
      for (
        let i = 0;
        i < this.multipleDeliveryPointListPopupReturn.length;
        i++
      ) {
        this.listOfInvoice.push(this.multipleDeliveryPointListPopupReturn[i]);
      }
    }
    return this.listOfInvoice;
  }
  /* Save function call start here  */

  saveBtn() {
    //this.saveHireslipDetailsMethod();
    if (
      $("#" + this.pageId + "driverMobileNo").val() == null ||
      $("#" + this.pageId + "driverMobileNo").val() == ""
    ) {
      swal("Mandatory", "Driver mobile number is Mandatory", "warning");
    } else if (
      $("#" + this.pageId + "departureTime").val() == null ||
      $("#" + this.pageId + "departureTime").val() == ""
    ) {
      swal("Mandatory", "Departure Time is Mandatory", "warning");
    } else {
      console.log($("#" + this.pageId + "departureDate").val());
      if ($("#" + this.pageId + "departureDate").val() != null) {
        //var currentYear = moment($("#"+this.pageId+"departureDate").val()).format('DD/MM/YYYY');
        //var depYear = (currentYear.split("-")[2]);
        var currentYear = moment(
          $("#" + this.pageId + "departureDate").val()
        ).format("YYYY");
        var depYear = currentYear;
        if (
          depYear == this.firstYearStgOnloadValidate ||
          depYear == this.secondYearStgOnloadValidate
        ) {
          if (this.invoiceType != null && this.invoiceType == "Dummy") {
            this.saveHireslipDetailsMethod();
          } else {
            this.getSaveRcLicInscDtls();
          }
        } else {
          swal("Mandatory", "Please Check The Departure Year", "warning");
        }
      }
    }
  }

  saveHireslipDetailsMethod() {
    //console.log("CHECKING");
    this.hireSlipDtoSaveHireslip = new HireSlipDto();
    this.chkStatusHireSlipDto = new HireSlipDto();

    this.hireSlipDtoSaveHireslip = this.getUsersValue();
    console.log(this.hireSlipDtoSaveHireslip);

    this.chkStatusHireSlipDto.companyid =
      this.userDataDtoReturnSession.companyId;
    this.chkStatusHireSlipDto.hireslipnumber = this.hireslipNumber;
    this.showSpinnerActionForHireslip = true;
    this.stockService
      .chkHireslipStatus(this.chkStatusHireSlipDto)
      .subscribe((response) => {
        if (response) {
          this.showSpinnerActionForHireslip = false;
          console.log(response.status);
          this.truckDataDtoValidateTruckType = new TruckDataDto();
          this.truckDataDtoValidateTruckType =
            this.validateTruckTypeSelectionsOnGurWgt();
          console.log(this.truckDataDtoValidateTruckType);
          if (response.status != null && response.status == "Transit") {
            swal("Warning", "Hireslip was already Departed", "warning");
          } else if (
            $("#" + this.pageId + "truckType").val() == null ||
            $("#" + this.pageId + "truckType").val() == "" ||
            $("#" + this.pageId + "truckType").val() == 0
          ) {
            swal(
              "Not Allowed",
              "Please select Truck Type to proceed!",
              "warning"
            );
          } else if (!this.truckDataDtoValidateTruckType.isValid) {
            swal(
              "Not Allowed",
              "The Truck Type selected does not match with the Total GUR Wt entered, kindly select the appropriate Truck Type.",
              "warning"
            );
          } else {
            /*  here addhireslipdetails service starting */
            this.showSpinnerActionForHireslip = true;
            this.stockService
              .addHireSlipDetails(this.hireSlipDtoSaveHireslip)
              .subscribe((response) => {
                this.showSpinnerActionForHireslip = false;
                //console.log(response);
                if (response.status == "Failed") {
                  swal(
                    "Failed",
                    "Failed to save/update add hire slip details",
                    "warning"
                  );
                  return false;
                } else if (response.status == "Success") {
                  console.log("am inside of saveHireslipDetailsMethod >> ");
                  this.printInvoiceAlone(
                    this.hireSlipDtoSaveHireslip,
                    response.hireslipnumber
                  );
                  if (
                    this.hireSlipDtoForCreateHireslipLocalStorage.fromstation ==
                    "KVH" &&
                    $("#" + this.pageId + "destination").val() != "Bangalore"
                  ) {
                    if (
                      !$("#" + this.pageId + "isMultiInvoiceTripId").is(
                        ":checked"
                      )
                    ) {
                      this.ftTrackingFlow();
                    } else if (
                      $("#" + this.pageId + "isMultiInvoiceTripId").is(
                        ":checked"
                      ) &&
                      $("#" + this.pageId + "islastPointDeliveryId").is(
                        ":checked"
                      )
                    ) {
                      this.ftTrackingFlow();
                    } else {
                      // this.overAllResetComplete();
                      //this.stepper.reset();
                      // this.router.navigate(['/dashboard/classic']);
                    }
                  } else {
                    //alert('ok2');
                    if (
                      !$("#" + this.pageId + "isMultiInvoiceTripId").is(
                        ":checked"
                      )
                    ) {
                      //alert('ok3');
                      this.ftTrackingFlow();
                    } else if (
                      $("#" + this.pageId + "isMultiInvoiceTripId").is(
                        ":checked"
                      ) &&
                      $("#" + this.pageId + "islastPointDeliveryId").is(
                        ":checked"
                      )
                    ) {
                      this.ftTrackingFlow();
                    } else {
                      //alert('ok4');
                      // this.overAllResetComplete();
                      //this.stepper.reset();
                      // this.router.navigate(['/dashboard/classic']);
                    }
                  }
                }
              }),
              (error) => {
                this.showSpinnerActionForHireslip = false;
                swal(
                  "Error",
                  "Server Error While Save Hireslip Details",
                  "warning"
                );
              },
              () => console.log("done");
          }
          /*  here addhireslipdetails service ending */
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerActionForHireslip = false;
        swal(
          "Error",
          "Server Error While Getting checking Hireslip Status",
          "warning"
        );
      },
      () => console.log("done");
  }

  validateTruckTypeSelectionsOnGurWgt() {
    //Pending work of comment line erroer shwg
    this.isValid = false;
    this.truckDto = new TruckDataDto();
    this.truckTypeId = null;
    // alert(this.selectedTruckTypeId);
    if (
      this.selectedTruckTypeId == null ||
      this.selectedTruckTypeId == undefined ||
      this.selectedTruckTypeId == ""
    ) {
      this.truckTypeId =
        this.hireSlipDtoForCreateHireslipLocalStorage.truckTypeCommonId;
    } else {
      this.truckTypeId = this.selectedTruckTypeId;
    }

    this.truckDto = this.hmTruckTypeDto.get(this.truckTypeId);
    console.log("Truck");
    console.log(this.truckDto);
    console.log(this.selectedTruckTypeId);
    this.validateTruckTypeTotGurWgt = $(
      "#" + this.pageId + "gurWeightTotal"
    ).val();
    if (this.truckDto != null) {
      if (
        this.validateTruckTypeTotGurWgt < this.truckDto.fromRange ||
        this.validateTruckTypeTotGurWgt > this.truckDto.toRange
      ) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      this.truckDto.isValid = this.isValid;
    }

    //temp enable to select any type hence hardcoded, later remove, as per Musaddik Bh req @29/01/2022
    this.truckDto.isValid = true;
    return this.truckDto;
  }

  getSaveRcLicInscDtls() {
    this.rcLicInscTruckDataDto = new TruckDataDto();

    // this.hireSlipDtoForCreateHireslipLocalStorage = JSON.parse(localStorage.getItem('createHireSlipLocalStorage'));
    console.log(this.hireSlipDtoForCreateHireslipLocalStorage);
    this.rcLicInscTruckDataDto.truckNumber =
      this.hireSlipDtoForCreateHireslipLocalStorage.vehicleNumber == null
        ? null
        : this.hireSlipDtoForCreateHireslipLocalStorage.vehicleNumber;
    this.rcLicInscTruckDataDto.driverId =
      this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
    this.rcLicInscTruckDataDto.companyId =
      this.hireSlipDtoForCreateHireslipLocalStorage.companyid;
    this.showSpinnerActionForHireslip = true;
    this.stockService
      .getRcLicInscDtls(this.rcLicInscTruckDataDto)
      .subscribe((response) => {
        this.showSpinnerActionForHireslip = false;
        this.rcLicInscTruckDataDtoReturn = null;
        this.rcLicInscTruckDataDtoReturn = response;
        //console.log(response);
        if (this.rcLicInscTruckDataDtoReturn == true) {
          this.chkPanInscDtls("Save");
        } else {
          swal({
            title: "Mandatory Docs",
            text: "RC/Pan/License Docs are not uploaded.Do You Want To Update",
            icon: "warning",
            buttons: ["No", "Yes"],
          }).then((isConfirm) => {
            if (isConfirm) {
              //Pop Up For RC LIC Ins File Upload
              this.modalRefferenceTruckDetailOfRCInsLicPopUp =
                this.modalService.open(this.uploadRCInsLicForTruck, {
                  centered: true,
                  windowClass: "modalClassForPopUpTruckOwner",
                });
              this.modalRefferenceTruckDetailOfRCInsLicPopUp.result.then(
                (result) => {
                  this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Closed with: ${result}`;
                },
                (reason) => {
                  this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                }
              );
            } else {
              swal({
                title: "Alert",
                text: "Pan/Insurance is not matching with Truck Master.Sure You Want To Save?",
                icon: "warning",
                buttons: ["No", "Yes"],
              }).then((isConfirm) => {
                if (isConfirm) {
                  this.saveHireslipDetailsMethod();
                } else {
                  $("#" + this.pageId + "panNumber").focus();
                }
              });
            }
          });
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Rc Lic Insc Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  /* Save function call end here  */

  chkPanInscDtls(mode_saveUpdateDepart) {
    this.truckDataDtoCheckPanIns = new TruckDataDto();
    this.validateLorryNumber = $("#" + this.pageId + "lorryNumber").val();
    this.validatePanNo = $("#" + this.pageId + "panNumber").val();
    this.validateInsPolNo = $("#" + this.pageId + "insuranceNo").val();
    this.truckDataDtoCheckPanIns.truckNumber = this.validateLorryNumber;
    this.truckDataDtoCheckPanIns.panNo = this.validatePanNo;
    this.truckDataDtoCheckPanIns.insPolNo = this.validateInsPolNo;
    this.truckDataDtoCheckPanIns.companyId =
      this.userDataDtoReturnSession.companyId;
    this.showSpinnerActionForHireslip = true;
    this.stockService
      .getPanInscDtls(this.truckDataDtoCheckPanIns)
      .subscribe((response) => {
        this.showSpinnerActionForHireslip = false;
        var isRcLicInscResult = response;
        this.modeMain = mode_saveUpdateDepart;
        if (mode_saveUpdateDepart != null && mode_saveUpdateDepart == "Save") {
          /*if mode_saveUpdateDepart save start */
          if (isRcLicInscResult) {
            this.masterService
              .setTruckOwnerDeclDetails(this.truckDataDtoCheckPanIns)
              .subscribe((response) => {
                //console.log(response);
                if (response == "failure") {
                  swal(
                    "Error",
                    "While Getting the result of setTruckOwnerDeclDetails",
                    "error"
                  );
                } else if (response == null) {
                  swal({
                    title: "Confirm Upload",
                    text: "Do you want to Upload the Truck Owner Declaration",
                    icon: "info",
                    buttons: ["No", "Yes"],
                  }).then((isConfirm) => {
                    if (isConfirm) {
                      this.modalRefferenceTruckOwnerPopUp =
                        this.modalService.open(this.uploadTruckOwnerPopUp, {
                          centered: true,
                          windowClass: "modalClassForPopUpTruckOwner",
                        });
                      this.modalRefferenceTruckOwnerPopUp.result.then(
                        (result) => {
                          this.modalRefferenceTruckOwnerClosePopUp = `Closed with: ${result}`;
                        },
                        (reason) => {
                          this.modalRefferenceTruckOwnerClosePopUp = `Dismissed ${this.getDismissReason(
                            reason
                          )}`;
                        }
                      );
                    } else {
                      /*save method call here */
                      this.saveHireslipDetailsMethod();
                    }
                  });
                }
              }),
              (error) =>
                swal(
                  "Error",
                  "Server Error While Getting chkPanInscDtls->setTruckOwnerDeclDetails",
                  "warning"
                ),
              () => console.log("done");
          } else {
            /* else start */
            swal({
              title: "Alert",
              text: "Pan/Insurance is not matching with Truck Master.Sure You Want To Save?",
              icon: "info",
              buttons: ["No", "Yes"],
            }).then((isConfirm) => {
              if (isConfirm) {
                this.masterService
                  .setTruckOwnerDeclDetails(this.truckDataDtoCheckPanIns)
                  .subscribe((response) => {
                    //console.log(response);
                    if (response == "failure") {
                      swal(
                        "Error",
                        "While Getting the result of setTruckOwnerDeclDetails",
                        "error"
                      );
                    } else if (response == null) {
                      swal({
                        title: "Confirm Upload",
                        text: "Do you want to Upload the Truck Owner Declaration",
                        icon: "info",
                        buttons: ["No", "Yes"],
                      }).then((isConfirm) => {
                        if (isConfirm) {
                          this.modalRefferenceTruckOwnerPopUp =
                            this.modalService.open(this.uploadTruckOwnerPopUp, {
                              centered: true,
                              windowClass: "modalClassForPopUpTruckOwner",
                            });
                          this.modalRefferenceTruckOwnerPopUp.result.then(
                            (result) => {
                              this.modalRefferenceTruckOwnerClosePopUp = `Closed with: ${result}`;
                            },
                            (reason) => {
                              this.modalRefferenceTruckOwnerClosePopUp = `Dismissed ${this.getDismissReason(
                                reason
                              )}`;
                            }
                          );
                        } else {
                          //save method call here
                          this.saveHireslipDetailsMethod();
                        }
                      });
                    } else {
                      //save method call here
                      this.saveHireslipDetailsMethod();
                    }
                  }),
                  (error) =>
                    swal(
                      "Error",
                      "Server Error While Getting chkPanInscDtls->setTruckOwnerDeclDetails",
                      "warning"
                    ),
                  () => console.log("done");
              } else {
                $("#" + this.pageId + "panNumber").focus();
              }
            });
          }
          /*else end */
        } //if mode_saveUpdateDepart save end && else if mode_saveUpdateDepart Depart start
        else if (
          mode_saveUpdateDepart != null &&
          mode_saveUpdateDepart == "Depart"
        ) {
          if (isRcLicInscResult) {
            this.masterService
              .setTruckOwnerDeclDetails(this.truckDataDtoCheckPanIns)
              .subscribe((response) => {
                //console.log(response);
                if (response == "failure") {
                  swal(
                    "Error",
                    "While Getting the result of setTruckOwnerDeclDetails",
                    "error"
                  );
                } else if (response == null) {
                  swal({
                    title: "Confirm Upload",
                    text: "Do you want to Upload the Truck Owner Declaration",
                    icon: "info",
                    buttons: ["No", "Yes"],
                  }).then((isConfirm) => {
                    if (isConfirm) {
                      this.modalRefferenceTruckOwnerPopUp =
                        this.modalService.open(this.uploadTruckOwnerPopUp, {
                          centered: true,
                          windowClass: "modalClassForPopUpTruckOwner",
                        });
                      this.modalRefferenceTruckOwnerPopUp.result.then(
                        (result) => {
                          this.modalRefferenceTruckOwnerClosePopUp = `Closed with: ${result}`;
                        },
                        (reason) => {
                          this.modalRefferenceTruckOwnerClosePopUp = `Dismissed ${this.getDismissReason(
                            reason
                          )}`;
                        }
                      );
                    } else {
                      //depart method call here
                      this.setDepartureMtd();
                    }
                  });
                } else {
                  //depart method call here
                  this.setDepartureMtd();
                }
              }),
              (error) =>
                swal(
                  "Error",
                  "Server Error While Getting chkPanInscDtls->setTruckOwnerDeclDetails",
                  "warning"
                ),
              () => console.log("done");
          } else {
            swal(
              "Alert",
              "Pan/Insurance is not matching with Truck Master.Please Check Pan/Insurance No",
              "warning"
            );
            $("#" + this.pageId + "panNumber").focus();
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerActionForHireslip = false;
        swal(
          "Error",
          "Server Error While Getting chkPanInscDtls->getPanInscDtls",
          "warning"
        );
      },
      () => console.log("done");
  }

  /* departure function call start here */

  departureButton() {
    var ewayValidationMsg = this.verifyEwayNumber();
    console.log($("#" + this.pageId + "islastPointDeliveryId").is(":checked"));
    console.log(this.multipleInvoiceIsLastPointListPopupReturn);
    if (
      $("#" + this.pageId + "islastPointDeliveryId").is(":checked") == true &&
      (this.multipleInvoiceIsLastPointListPopupReturn == null ||
        this.multipleInvoiceIsLastPointListPopupReturn == undefined ||
        this.multipleInvoiceIsLastPointListPopupReturn.length == 0)
    ) {
      swal(
        "Not Allowed",
        "For Last Point Delivery Invoice, must add atleast one Invoice which is loading along with this Invoice, please verify again and proceed.",
        "warning"
      );
      return false;
    } else if (ewayValidationMsg != null && ewayValidationMsg != "valid") {
      swal("Not Allowed", ewayValidationMsg, "warning");
      return false;
    } else if (
      $("#" + this.pageId + "departureTime").val() == null ||
      $("#" + this.pageId + "departureTime").val() == ""
    ) {
      swal("Mandatory", "Departure Time is Mandatory", "warning");
    } else {
      if ($("#" + this.pageId + "departureDate").val() != null) {
        //var currentYear = moment($("#"+this.pageId+"departureDate").val()).format('YYYY/MM/DD');
        var currentYear = moment(
          $("#" + this.pageId + "departureDate").val()
        ).format("YYYY");
        var depYear = currentYear;
        if (
          depYear == this.firstYearStgOnloadValidate ||
          depYear == this.secondYearStgOnloadValidate
        ) {
          if (
            $("#" + this.pageId + "deliveryPointIdForMultiple").is(
              ":checked"
            ) == true
          ) {
            var booleanIsAllowReturn = this.checkMultiPtInvoice();
            console.log(booleanIsAllowReturn);
            if (booleanIsAllowReturn) {
              if (this.invoiceType != null && this.invoiceType == "Dummy") {
                this.setDepartureMtd();
              } else {
                this.getDepartureRcLicInscDtls();
              }
            }
          } else {
            if (this.invoiceType != null && this.invoiceType == "Dummy") {
              this.setDepartureMtd();
            } else {
              this.getDepartureRcLicInscDtls();
            }
          }
        } else {
          swal("Warning", "Please Check The Departure Year", "warning");
        }
      }
    }
  }

  verifyEwayNumber() {
    var msg = null;

    if (
      $("#" + this.pageId + "consolidatedEwayBill").val() == null ||
      $("#" + this.pageId + "consolidatedEwayBill").val() == "" ||
      $("#" + this.pageId + "confirmConsolidatedEwayBill").val() == null ||
      $("#" + this.pageId + "confirmConsolidatedEwayBill").val() == ""
    ) {
      msg =
        "Consolidated Eway Bill and Confirm Consolidated Eway Bill is mandatory";
    } else {
      this.validateEwayBill = $(
        "#" + this.pageId + "consolidatedEwayBill"
      ).val();
      this.validateEwayBillConsoldateConfirm = $(
        "#" + this.pageId + "confirmConsolidatedEwayBill"
      ).val();
      if (this.validateEwayBill != this.validateEwayBillConsoldateConfirm) {
        msg =
          "Consolidated Eway Bill is not matched with Confirm Consolidated Eway Bill, please verify it!";
      } else if (
        !this.validateEwayBill.match("[0-9]{10}") ||
        !this.validateEwayBillConsoldateConfirm.match("[0-9]{10}")
      ) {
        msg = "Eway Bill should not exceed 10 digit, please verify it!";
      } else if (
        this.userDataDtoReturnSession.ewayNoBlockList != null &&
        this.userDataDtoReturnSession.ewayNoBlockList.length > 0
      ) {
        this.isMatched = false;
        for (
          let i = 0;
          i < this.userDataDtoReturnSession.ewayNoBlockList.length;
          i++
        ) {
          if (
            this.validateEwayBill ==
            this.userDataDtoReturnSession.ewayNoBlockList[i]
          ) {
            msg = "Eway Bill is not valid, please verify it!";
            this.isMatched = true;
            break;
          }
        }
        if (!this.isMatched) {
          msg = "valid";
        }
      } else {
        msg = "valid";
      }
    }
    return msg;
  }

  checkMultiPtInvoice() {
    var booleanChkMultiIsAllow = false;
    console.log(this.gridTosetInvoiceNumberList);
    if (this.gridTosetInvoiceNumberList.length == 0) {
      swal(
        "Warning",
        "For Multiple Pt Delivery Minimum 2 Invoices Required With 1st Pt Delivery Selected",
        "warning"
      );
      return false;
    } else if (this.gridTosetInvoiceNumberList.length == 1) {
      swal(
        "Warning",
        "Multiple Point Is Not Allowed For Single Invoice",
        "warning"
      );
    } else if (this.gridTosetInvoiceNumberList.length >= 2) {
      booleanChkMultiIsAllow = true;
    }
    return booleanChkMultiIsAllow;
  }

  setDepartureMtd() {
    this.hireSlipDtoUserValueForDepartureMethod = new HireSlipDto();
    this.hireSlipDtoUserValueForDepartureMethod = this.getUsersValue();
    this.stockService
      .chkHireslipStatus(this.hireSlipDtoUserValueForDepartureMethod)
      .subscribe((response) => {
        if (response) {
          console.log(response.status);
          this.truckDataDtoForDepartureMethod = new TruckDataDto();
          this.truckDataDtoForDepartureMethod =
            this.validateTruckTypeSelectionsOnGurWgt();
          //console.log(this.truckDataDtoForDepartureMethod);
          if (response.status != null && response.status == "Transit") {
            swal("Warning", "Hireslip was already Departed", "warning");
          } else if (
            $("#" + this.pageId + "truckType").val() == null ||
            $("#" + this.pageId + "truckType").val() == "" ||
            $("#" + this.pageId + "truckType").val() == 0
          ) {
            swal(
              "Not Allowed",
              "Please select Truck Type to proceed!",
              "warning"
            );
          } else if (!this.truckDataDtoForDepartureMethod.isValid) {
            swal(
              "Not Allowed",
              "The Truck Type selected does not match with the Total GUR Wt entered, kindly select the appropriate Truck Type.",
              "warning"
            );
          } else {
            this.stockService
              .addHireSlipDetails(this.hireSlipDtoUserValueForDepartureMethod)
              .subscribe((response) => {
                //console.log("CHECKC BARCode");
                //console.log(response.barcodeValue);
                if (response.status == "not persisted") {
                  swal("Alert", "Failure", "error");
                } else {
                  this.hireSlipDtoUserValueForDepartureMethodReturn = null;
                  this.hireSlipDtoUserValueForDepartureMethodReturn =
                    response.barcodeValue;

                  this.addNumberOfTrucksDetails(
                    this.hireSlipDtoUserValueForDepartureMethod.hireslipnumber
                  );

                  swal({
                    title: "Trip Departure",
                    text: "Sure U want to launch this trip",
                    icon: "info",
                    buttons: ["No", "Yes"],
                  }).then((isConfirm) => {
                    if (isConfirm) {
                      this.hireSlipDtoUserValueTripDeparture =
                        new HireSlipDto();
                      this.hireSlipDtoUserValueTripDeparture.hireslipnumber =
                        this.hireSlipDtoUserValueForDepartureMethod.hireslipnumber;
                      this.hireSlipDtoUserValueTripDeparture.status = "Transit";
                      this.hireSlipDtoUserValueTripDeparture.mode = "scheduled";
                      this.hireSlipDtoUserValueTripDeparture.fromstation =
                        this.hireSlipDtoUserValueForDepartureMethod.fromstation;
                      this.hireSlipDtoUserValueTripDeparture.tostation =
                        this.hireSlipDtoUserValueForDepartureMethod.tostation;
                      this.hireSlipDtoUserValueTripDeparture.branch =
                        this.userDataDtoReturnSession.stationType;
                      this.hireSlipDtoUserValueTripDeparture.listOfInvoice =
                        this.hireSlipDtoUserValueForDepartureMethod.listOfDest;
                      this.hireSlipDtoUserValueTripDeparture.listOfInvoice.push(
                        this.hireSlipDtoUserValueTripDeparture.hireslipnumber
                      );
                      this.hireSlipDtoUserValueTripDeparture.companyid =
                        this.userDataDtoReturnSession.companyId;
                      this.stockService
                        .updateHireSlipStatus(
                          this.hireSlipDtoUserValueTripDeparture
                        )
                        .subscribe((response) => {
                          if (response) {
                            //console.log(response);
                            if (
                              this.hireSlipDtoUserValueForDepartureMethod
                                .isMultiPntDelv &&
                              this.hireSlipDtoUserValueForDepartureMethod
                                .listOfInvoice.length > 0
                            ) {
                              for (
                                let i = 0;
                                i <
                                this.hireSlipDtoUserValueForDepartureMethod
                                  .listOfInvoice.length;
                                i++
                              ) {
                                //Pending Need to Work
                                this.getInvoiceDetails(
                                  this.hireSlipDtoUserValueForDepartureMethod
                                    .listOfInvoice[i]
                                );
                              }
                            } else {
                              this.generateBarcode(
                                this.hireSlipDtoUserValueForDepartureMethod
                                  .hireslipnumber,
                                this
                                  .hireSlipDtoUserValueForDepartureMethodReturn,
                                this.hireSlipDtoUserValueForDepartureMethod
                              );
                            }
                            // SMS
                            //if(rightsForSMSHireslip == "rights for the sms binnymillsBinnymills and Konditope"){
                            if (
                              this.hireSlipDtoUserValueForDepartureMethod
                                .fromstation == "BinnymillsBinnymills" &&
                              this.hireSlipDtoUserValueForDepartureMethod
                                .tostation == "Konditope"
                            ) {
                              //sms service here pending
                              this.lrDtoForSMS = new LRDto();
                              this.lrDtoForSMS.city = "Chennai";
                              this.lrDtoForSMS.companyId =
                                this.userDataDtoReturnSession.companyId;
                            }
                          }
                        }),
                        (error) =>
                          swal(
                            "Error",
                            "Server Error While Getting updateHireSlipStatus",
                            "warning"
                          ),
                        () => console.log("done");
                    } else {
                      this.overAllResetComplete();
                      //this.stepper.reset();
                      this.router.navigate(["/dashboard/classic"]);
                    }
                  });
                  /* start here addNumberOfTrucks*/
                  /*this.stockService.addNumberOfTrucks(this.hireSlipDtoUserValueForDepartureMethod).subscribe(
                                        (response) => {
                                        }), (error) =>
                                            swal("Error", "Server Error While Getting updateHireSlipStatus", "warning"),
                                        () => console.log('done');*/
                  /* end here addNumberOfTrucks*/
                }
              }),
              (error) =>
                swal(
                  "Error",
                  "Server Error While Getting adding HireSlipDetails",
                  "warning"
                ),
              () => console.log("done");
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal("Error", "Server Error While Getting Departure Mtd", "warning"),
      () => console.log("setDepartureMtd");
  }

  getInvoiceDetails(listOfInvoiceHireslipNo) {
    this.hireSlipDtoForInvoiceDetails = new HireSlipDto();
    this.hireSlipDtoForInvoiceDetails.hireslipnumber = listOfInvoiceHireslipNo;
    this.hireSlipDtoForInvoiceDetails.branch =
      this.userDataDtoReturnSession.office;
    this.hireSlipDtoForInvoiceDetails.companyid =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .getHireSlipDetails(this.hireSlipDtoForInvoiceDetails)
      .subscribe((response) => {
        //console.log(response);
        if (response.size > 0) {
          this.generateBarcode(
            listOfInvoiceHireslipNo,
            response.barcodeValue,
            response
          );
        } else {
          swal("Error", "Error While HireSlip Details", "warning");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal("Error", "Server Error While Getting Invoice Details", "warning"),
      () => console.log("done");
  }

  generateBarcode(
    hireslipNo,
    dtoRetBarcodeValue,
    hireSlipDtoUserValueForDepartureMethod
  ) {
    this.hireSlipDtoBarCode1 = new HireSlipDto();
    this.hireSlipDtoBarCode1 = hireSlipDtoUserValueForDepartureMethod;
    this.hireSlipDtoBarCode1.companyid =
      this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoBarCode1.hireslipnumber = hireslipNo;
    this.hireSlipDtoBarCode1.invoiceNumber = hireslipNo;
    this.hireSlipDtoBarCode1.companyImgUrl = "";
    this.hireSlipDtoBarCode1.barcodeImgUrl = "";
    swal({
      title: "Invoice Report",
      text: "Sure U Want to Print the Invoice Details for this Trip",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.getLrDetails(
          hireslipNo,
          this.hireSlipDtoBarCode1,
          dtoRetBarcodeValue
        );
      } else {
        this.printHireSlip(
          hireslipNo,
          hireSlipDtoUserValueForDepartureMethod,
          dtoRetBarcodeValue,
          this.listOfShortageLrs,
          null,
          this.gurWgt,
          this.suppName,
          this.goodsType
        );
        //printHireSlip(hireSlipNum,hireSlipDto,listOfShortageLrs);
      }
    });
  }

  getLrDetails(invoiceNUmber, hireslipBarCode_1, barcodeValue) {
    this.lrsDetailsMhdForPrint = new Array<String>();
    this.lrDtoForLrDetailsMhdPrint = new LRDto();
    this.hireSlipDtoForLrDetailsMhd = new HireSlipDto();
    this.hireSlipDtoForLrDetailsMhd = hireslipBarCode_1;

    this.lrsDetailsMhdForPrint =
      this.hireSlipDtoForCreateHireslipLocalStorage.list;
    this.lrDtoForLrDetailsMhdPrint.list = this.lrsDetailsMhdForPrint;
    // Hard Code Pls remove after solved
    //this.lrDtoForLrDetailsMhdPrint.invoiceNumber = 'NP/CLT/1920/30';
    this.lrDtoForLrDetailsMhdPrint.invoiceNumber =
      this.hireSlipDtoForLrDetailsMhd.invoiceNumber;
    this.lrDtoForLrDetailsMhdPrint.companyId =
      this.userDataDtoReturnSession.companyId;

    this.hireSlipDtoForLrDetailsMhd.suppliername = this.suppName;
    this.hireSlipDtoForLrDetailsMhd.gurWeight = this.gurWgt;
    this.hireSlipDtoForLrDetailsMhd.goodsType = this.goodsType;

    this.stockService
      .getBookingInvoiceDetails(this.lrDtoForLrDetailsMhdPrint)
      .subscribe((response) => {
        //console.log(response);
        /* if (this.hireSlipDtoForCreateHireslipLocalStorage.invoiceType == "Dummy") {
                     this.bookingInvoiceNewPrint_InHireslip(this.hireSlipDtoForLrDetailsMhd,
                         this.listOfShortageLrs, response, this.gurWgt, this.suppName, this.goodsType);
                 } else {
                     this.bookingInvoiceNewPrint_InHireslip(this.hireSlipDtoForLrDetailsMhd,
                         this.listOfShortageLrs, response, this.gurWgt, this.suppName, this.goodsType);
                 }
                 this.printHireSlip(invoiceNUmber, this.hireSlipDtoForLrDetailsMhd, barcodeValue);*/
        if (
          this.hireSlipDtoForCreateHireslipLocalStorage.invoiceType == "Dummy"
        ) {
          this.printHireSlip(
            invoiceNUmber,
            this.hireSlipDtoForLrDetailsMhd,
            barcodeValue,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType
          );
        } else {
          this.printHireSlip(
            invoiceNUmber,
            this.hireSlipDtoForLrDetailsMhd,
            barcodeValue,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => swal("Alert", "Error While Getting LR Details", "error"),
      () => console.log("done");
  }

  printHireSlip(
    invoiceNumber,
    hireslipDtoUserValuesLrDetailsMhd,
    barcodeValue,
    listOfShortageLrs,
    returnResponsBookingInvoiceDetails,
    gurWgt,
    suppName,
    goodsType
  ) {
    swal({
      title: "Print HireSlip",
      text:
        "Track No: " +
        hireslipDtoUserValuesLrDetailsMhd.hireslipNumber +
        " Do U Want to Print this HireSlip",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.printHireslipMethodForLRDetailsMhd(
          hireslipDtoUserValuesLrDetailsMhd,
          barcodeValue,
          returnResponsBookingInvoiceDetails,
          gurWgt,
          suppName,
          goodsType
        );
        //this.hireslipPrintMethod(hireSlipDtoBarCode,listOfShortageLrs,mode);
      }
    });
  }

  printHireslipMethodForLRDetailsMhd(
    hireslipDto,
    barcodeValue,
    returnResponsBookingInvoiceDetails,
    gurWgt,
    suppName,
    goodsType
  ) {
    this.hireSlipDtoForPrintHireslip = new HireSlipDto();
    this.hireSlipDtoForPrintHireslip = hireslipDto;
    this.hireSlipDtoForPrintHireslip.companyid =
      this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoForPrintHireslip.fromstation =
      this.userDataDtoReturnSession.office;
    this.hireSlipDtoForPrintHireslip.searchMode = "CODPrint";
    this.masterReadService
      .getTimeService(this.hireSlipDtoForPrintHireslip)
      .subscribe((response) => {
        //console.log(response);
        this.hireslipDtoForPrintHireslipReturn = response;
        console.log(this.hireSlipDtoForPrintHireslip.tostation);
        this.stockService
          .getGodownsDetails(this.hireSlipDtoForPrintHireslip)
          .subscribe((response) => {
            //console.log(response);
            this.lrDtoForPrintHireslipReturn = response;
            var address = this.lrDtoForPrintHireslipReturn.column2;
            if (address == null || this.lrDtoForPrintHireslipReturn.size == 0) {
              address = "";
            }
            this.stockService
              .getLRShortageDtls(this.hireSlipDtoForPrintHireslip)
              .subscribe((response) => {
                //console.log(response);
                var commomParameters = [address, this.printMode, barcodeValue];
                localStorage.clear();
                localStorage.setItem(
                  "hireslipDetailsPrintForHireslip_Dto",
                  JSON.stringify(this.hireSlipDtoForPrintHireslip)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForHireSlipDto",
                  JSON.stringify(this.hireslipDtoForPrintHireslipReturn)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintLRShortgeForHireslip_Dto",
                  JSON.stringify(response)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForLRShortgeList",
                  JSON.stringify(this.listOfShortageLrs)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForCommonParameters",
                  JSON.stringify(commomParameters)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintCodLrs",
                  JSON.stringify(this.lrDtoForPrintHireslipReturn)
                );
                this.viewHireslipDetailsPrint = true;
                window.addEventListener("afterprint", (onclick) => {
                  if (this.viewHireslipDetailsPrint) {
                    this.viewHireslipDetailsPrint = false;
                    this.bookingInvoiceNewPrint_InHireslip(
                      this.hireSlipDtoForLrDetailsMhd,
                      this.listOfShortageLrs,
                      returnResponsBookingInvoiceDetails,
                      gurWgt,
                      suppName,
                      goodsType,
                      "Continue"
                    );
                    /*this.overAllResetComplete();
                                        this.stepper.reset();*/
                  }
                });
                this.changeDetectorRef.detectChanges();
              }),
              (error) => console.log(error.json()),
              () =>
                console.log(
                  "printHireslipMethodForLRDetailsMhd->getLRShortageDtls"
                );
            this.changeDetectorRef.detectChanges();
          }),
          (error) => console.log(error.json()),
          () =>
            console.log(
              "printHireslipMethodForLRDetailsMhd->getGodownsDetails"
            );
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("printHireslipMethodForLRDetailsMhd ->getTimeService");
  }

  getDepartureRcLicInscDtls() {
    this.truckDataDtoDepartRCLicPan = new TruckDataDto();
    console.log(this.hireSlipDtoForCreateHireslipLocalStorage);
    this.truckDataDtoDepartRCLicPan.truckNumber =
      this.hireSlipDtoForCreateHireslipLocalStorage.vehicleNumber;
    this.truckDataDtoDepartRCLicPan.driverId =
      this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
    this.truckDataDtoDepartRCLicPan.companyId =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .getRcLicInscDtls(this.truckDataDtoDepartRCLicPan)
      .subscribe((response) => {
        //console.log(response);
        if (response) {
          this.chkPanInscDtls("Depart");
        } else {
          swal({
            title: "Mandatory Docs",
            text: "RC/Pan/License Docs are not uploaded.Do You Want To Update",
            icon: "warning",
            buttons: ["No", "Yes"],
          }).then((isConfirm) => {
            //}).then(function (isConfirm) {
            if (isConfirm) {
              /* this.modalRefferenceTruckDetailOfRCLicPanPopUp = this.modalService.open(this.uploadRCInsLicForTruck,
                                 { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
                             this.modalRefferenceTruckDetailOfRCLicPanPopUp.result.then((result) => {
                                 this.modalRefferenceTruckDetailOfRCLicPanClosePopUp = `Closed with: ${result}`;
                             }, reason => {
                                 this.modalRefferenceTruckDetailOfRCLicPanClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
                             });*/
              //Pop Up For RC LIC Ins File Upload
              this.modalRefferenceTruckDetailOfRCInsLicPopUp =
                this.modalService.open(this.uploadRCInsLicForTruck, {
                  centered: true,
                  windowClass: "modalClassForPopUpTruckOwner",
                });
              this.modalRefferenceTruckDetailOfRCInsLicPopUp.result.then(
                (result) => {
                  this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Closed with: ${result}`;
                },
                (reason) => {
                  this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                }
              );
            } else {
              swal({
                title: "Alert",
                text: "Pan/Insurance is not matching with Truck Master.Sure You Want To Depart?",
                icon: "warning",
                buttons: ["No", "Yes"],
              }).then((isConfirm) => {
                if (isConfirm) {
                  this.setDepartureMtd();
                  //this.saveHireslipDetailsMethod();
                } else {
                  $("#" + this.pageId + "panNumber").focus();
                }
              });
            }
          });
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Error",
          text: "Server Error While Getting getDepartureRcLicInscDtls",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }
  /* departure function call end here */

  printInvoiceButton() {
    swal({
      title: "Confirm Print",
      text: "Sure you want to Print the Invoice",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.hireSlipDtoUserValueForPrintInvoiceBtn = new HireSlipDto();
        this.hireSlipDtoUserValueForPrintInvoiceBtn = this.getUsersValue();
        this.printBookingInvoice(this.hireSlipDtoUserValueForPrintInvoiceBtn);
      }
    });
  }

  printBookingInvoice(hireSlipDtoForInvoicePrintBtn) {
    this.lrsPrintInvoiceBtn = new Array<String>();
    this.lrDtoPrintInvoiceBtn = new LRDto();
    this.hireSlipDtoForBookingInvoicePrintMhd = new HireSlipDto();

    this.hireSlipDtoForBookingInvoicePrintMhd = hireSlipDtoForInvoicePrintBtn;

    this.lrsPrintInvoiceBtn =
      this.hireSlipDtoForCreateHireslipLocalStorage.list;
    this.lrDtoPrintInvoiceBtn.list = this.lrsPrintInvoiceBtn;
    // Hard Code Pls remove after solved
    //this.lrDtoPrintInvoiceBtn.invoiceNumber = 'NP/CLT/1920/30';
    this.lrDtoPrintInvoiceBtn.invoiceNumber =
      this.hireSlipDtoForBookingInvoicePrintMhd.invoiceNumber;
    this.lrDtoPrintInvoiceBtn.companyId =
      this.userDataDtoReturnSession.companyId;
    this.validateKantaWgtBookingInvoice = null;
    this.validateKantaWgtBookingInvoice =
      $("#" + this.pageId + "kantaWeight").val() == null
        ? 0.0
        : $("#" + this.pageId + "kantaWeight").val();
    this.hireSlipDtoForBookingInvoicePrintMhd.kantaWeight =
      this.validateKantaWgtBookingInvoice;

    this.hireSlipDtoForBookingInvoicePrintMhd.suppliername = this.suppName;
    this.hireSlipDtoForBookingInvoicePrintMhd.gurWeight = this.gurWgt;
    this.hireSlipDtoForBookingInvoicePrintMhd.goodsType = this.goodsType;
    this.stockService
      .getBookingInvoiceDetails(this.lrDtoPrintInvoiceBtn)
      .subscribe((response) => {
        //console.log(response);
        if (
          this.hireSlipDtoForCreateHireslipLocalStorage.invoiceType == "Dummy"
        ) {
          //alert("Dummy Print Method Pending");
          this.bookingInvoiceNewPrint_InHireslip(
            this.hireSlipDtoForBookingInvoicePrintMhd,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType,
            null
          );
        } else {
          this.bookingInvoiceNewPrint_InHireslip(
            this.hireSlipDtoForBookingInvoicePrintMhd,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType,
            null
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("done");
  }

  bookingInvoiceNewPrint_InHireslip(
    hireSlipDtoForBookingInvoicePrint,
    invoiceListOfLrShortage,
    invoiceForLrDto,
    gurWgt,
    supplierName,
    goodsType,
    modes
  ) {
    localStorage.clear();
    console.log("hireSlipDtoForBookingInvoicePrint Set ");
    console.log(hireSlipDtoForBookingInvoicePrint);
    localStorage.setItem(
      "invoiceDetailsForBookingHireslipDtoPrint",
      JSON.stringify(hireSlipDtoForBookingInvoicePrint)
    );
    localStorage.setItem(
      "invoiceDetailsForBookingListOfLrsShortPrint",
      JSON.stringify(invoiceListOfLrShortage)
    );
    localStorage.setItem(
      "invoiceDetailsForBookingLrDtoPrint",
      JSON.stringify(invoiceForLrDto)
    );
    //basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      console.log("check");
      window.open("/trip-creation/invoiceDetailsPrint", "_blank");
      /*this.viewInvoiceDetailsPrint_Hireslip = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewInvoiceDetailsPrint_Hireslip) {
                    this.viewInvoiceDetailsPrint_Hireslip = false;
                    this.listOfShortageLrs = [];
                    localStorage.clear();
                }
            });*/
    } else {
      this.viewInvoiceDetailsPrint_Hireslip = true;
      window.addEventListener("afterprint", (onclick) => {
        if (this.viewInvoiceDetailsPrint_Hireslip) {
          this.viewInvoiceDetailsPrint_Hireslip = false;
          this.listOfShortageLrs = [];
          //localStorage.clear();

          //Asrar_Jr_Chg_V1
          this.changeDetectorRef.detectChanges();
          if (
            modes != null &&
            modes != undefined &&
            modes != "" &&
            modes == "Continue"
          ) {
            this.overAllResetComplete();
            //this.stepper.reset();
            this.router.navigate(["/dashboard/classic"]);
          }
          //by kamil

          if (modes == null) {
            this.overAllResetComplete();
            //this.stepper.reset();
            this.router.navigate(["/dashboard/classic"]);
          }
          //this.overAllResetComplete();
        }
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  printHireslipButton() {
    this.hireSlipDtoUserValueForPrintHireslipBtn = new HireSlipDto();
    this.hireSlipDtoUserValueForPrintHireslipBtn = this.getUsersValue();
    //this.getBarcode($("#"+this.pageId+"hireslipNumber").val(), this.hireSlipDtoUserValueForPrintHireslipBtn);
    //Need to Open This Comment below status
    if (
      this.hireSlipDtoForCreateHireslipLocalStorage.status == null ||
      this.hireSlipDtoForCreateHireslipLocalStorage.status == "Scheduled"
    ) {
      swal(
        "Not Allowed",
        "Not allow to Print the Hireslip before Departure",
        "warning"
      );
      return false;
    } else {
      this.getBarcode(
        $("#" + this.pageId + "hireslipNumber").val(),
        this.hireSlipDtoUserValueForPrintHireslipBtn
      );
    }

    /*  this.viewHireslipDetailsPrint = true;
          window.addEventListener('afterprint', (onclick) => {
              if (this.viewHireslipDetailsPrint) {
                  this.viewHireslipDetailsPrint = false;
                  //this.listOfShortageLrs =[];
              }
          });*/
  }

  getBarcode(hireSlipNum, hireSlipDto) {
    //alert("BArcode");
    this.hireSlipDtoBarCode = new HireSlipDto();
    this.hireSlipDtoBarCode = hireSlipDto;
    this.hireSlipDtoBarCode.companyid = this.userDataDtoReturnSession.companyId;

    /*this.tripCreationHireslipGenerationScreen.generateBarcode( this.hireSlipDtoBarCode).subscribe(
            ( response ) => {
                console.log("HAI");
                console.log(response);
                this.hireSlipDtoBarCode.invoiceNumber=hireSlipNum;
                this.hireSlipDtoBarCode.companyImgUrl="";
                this.hireSlipDtoBarCode.barcodeImgUrl="";
                this.hireSlip(this.hireSlipDtoBarCode,this.listOfShortageLrs);*/
    /* if(response !=null){
             hireSlipDto.invoiceNumber=hireSlipNum;
             hireSlipDto.companyImgUrl="";
             hireSlipDto.BarcodeImgUrl="";
             this.hireSlip(hireSlipDto,this.listOfShortageLrs);
         }else{
             swal("Alert","Failed to Generate a bar code","warning");
         }*/
    /*  }),
          ( error ) => console.log( error.json(),"HI" ),
          () => console.log( 'done' );*/
    //basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      this.hireSlipDtoBarCode.invoiceNumber =
        this.tripScheduledToLocalStorage_HireslipRpt.hireslipnumber;
      this.hireSlipDtoBarCode.hireslipnumber =
        this.tripScheduledToLocalStorage_HireslipRpt.hireslipnumber;
    } else {
      this.hireSlipDtoBarCode.invoiceNumber = hireSlipNum;
      this.hireSlipDtoBarCode.hireslipnumber = hireSlipNum;
    }

    this.hireSlipDtoBarCode.companyImgUrl = "";
    this.hireSlipDtoBarCode.barcodeImgUrl = "";
    this.hireSlip(this.hireSlipDtoBarCode);
  }

  hireSlip(hireSlipDtoBarCode) {
    swal({
      title: "Print HireSlip",
      text:
        "Track No: " +
        hireSlipDtoBarCode.hireslipNumber +
        " Do U Want to Print this HireSlip",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        console.log(hireSlipDtoBarCode);
        this.hireslipPrintMethod(hireSlipDtoBarCode);
      }
    });
  }

  hireslipPrintMethod(hireSlipDtoBarCode) {
    this.hireSlipDtoForTimeDuratioMethod = new HireSlipDto();
    this.hireSlipDtoForTimeDuratioMethod = hireSlipDtoBarCode;
    this.hireSlipDtoForTimeDuratioMethod.companyid =
      this.userDataDtoReturnSession.companyId;
    // this.hireSlipDtoForTimeDuratioMethod.fromstation = this.userDataDtoReturnSession.office;
    this.getCODLRDetails();
    this.masterReadService
      .getTimeService(this.hireSlipDtoForTimeDuratioMethod)
      .subscribe((response) => {
        //console.log(response);
        this.hireslipDtoForTimeDuratioReturn = response;
        console.log(this.hireSlipDtoForTimeDuratioMethod.tostation);
        this.stockService
          .getGodownsDetails(this.hireSlipDtoForTimeDuratioMethod)
          .subscribe((response) => {
            //console.log(response);
            this.lrDtoForTimeDuratioReturn = response;
            var address = this.lrDtoForTimeDuratioReturn.column2;
            // if (address == null || this.lrDtoForTimeDuratioReturn.length() == 0) {
            //     address = "";
            // }
            //Basha
            if (
              address == null ||
              this.lrDtoForTimeDuratioReturn == null ||
              this.lrDtoForTimeDuratioReturn == "undefined"
            ) {
              address = "";
            }
            this.stockService
              .getLRShortageDtls(this.hireSlipDtoForTimeDuratioMethod)
              .subscribe((response) => {
                localStorage.clear();
                //this.hireSlipDtoLocalStorage = JSON.parse(localStorage.getItem('createHireSlipLocalStorage'));
                //var mode =this.hireSlipDtoLocalStorage.mode;
                var commomParameters = [
                  address,
                  this.printMode,
                  hireSlipDtoBarCode.barcodeValue,
                ];
                localStorage.setItem(
                  "hireslipDetailsPrintForHireslip_Dto",
                  JSON.stringify(this.hireSlipDtoForTimeDuratioMethod)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForHireSlipDto",
                  JSON.stringify(this.hireslipDtoForTimeDuratioReturn)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintLRShortgeForHireslip_Dto",
                  JSON.stringify(response)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForLRShortgeList",
                  JSON.stringify(this.listOfShortageLrs)
                );
                localStorage.setItem(
                  "hireslipDetailsPrintForCommonParameters",
                  JSON.stringify(commomParameters)
                );
                //basha
                if (
                  this.tripScheduledToLocalStorage_HireslipRpt != null &&
                  this.tripScheduledToLocalStorage_HireslipRpt != undefined
                ) {
                  localStorage.setItem(
                    "hireslipDetailsPrintForInRptPrintHideBarcode",
                    JSON.stringify("HideBarcode")
                  );
                  console.log("checkhireslip");
                  //window.open('/trip-creation/hireslipDetailsPrint', '_blank');

                  //Asrar_Jr_Chg_V2
                  console.log("LocalStorage " + this.codLrNumbers);
                  localStorage.setItem(
                    "hireslipDetailsPrintForCODLR",
                    JSON.stringify(this.codLrNumbers)
                  );

                  this.viewHireslipDetailsPrint = true;
                  window.addEventListener("afterprint", (onclick) => {
                    if (this.viewHireslipDetailsPrint) {
                      this.viewHireslipDetailsPrint = false;
                      //this.listOfShortageLrs =[];
                    }
                  });
                } else {
                  this.viewHireslipDetailsPrint = true;
                  window.addEventListener("afterprint", (onclick) => {
                    if (this.viewHireslipDetailsPrint) {
                      this.viewHireslipDetailsPrint = false;
                      //this.listOfShortageLrs =[];
                    }
                  });
                }

              }),
              (error) => {
                swal(
                  "Error",
                  "Server Error While Getting Time Service",
                  "warning"
                );
              },
              () => console.log("getLRShortageDtls");
          }),
          (error) => {
            swal(
              "Error",
              "Server Error While Getting Godowns Details Service",
              "warning"
            );
          },
          () => console.log("getGodownsDetails");
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting LR Shortage Detaills",
          "warning"
        );
      },
      () => console.log("getTimeDurationDetails");
  }

  /* Truck Owner File Upload Code Start Here */
  onFileChangedTruckOwner(event) {
    this.selectedFileTruckOwner = event.target.files[0];
    console.log(this.selectedFileTruckOwner);
  }
  uploadFileOfTruckOwnerDetails() {
    this.isUploadedTruckOwner = false;
    if (
      $("#" + this.pageId + "truckOwnerFileUpload").val() != null &&
      $("#" + this.pageId + "truckOwnerFileUpload").val() != ""
    ) {
      if (
        this.selectedFileTruckOwner.type != null &&
        (this.selectedFileTruckOwner.type == "image/jpeg" ||
          this.selectedFileTruckOwner.type == "image/jpg" ||
          this.selectedFileTruckOwner.type == "image/png")
      ) {
        var splitTypeTruckOwner = this.selectedFileTruckOwner.type.split("/");
        var fileNameDecl =
          $("#" + this.pageId + "panNumber").val() +
          "_" +
          $("#" + this.pageId + "lorryNumber").val();
        const commonListTruckOwner =
          "vehicledriverdocs && truckownerdeclaration && ASRAR && " +
          splitTypeTruckOwner[1] +
          "&&" +
          fileNameDecl +
          "";
        const formData = new FormData();
        formData.append(commonListTruckOwner, commonListTruckOwner);
        formData.append(
          "myfileTruckOwner",
          this.selectedFileTruckOwner,
          this.selectedFileTruckOwner.name
        );
        this.masterReadService
          .saveRCInsurancePanForTruckMasterFileUpload(formData)
          .subscribe((response) => {
            if (response) {
              //console.log(response[0]);
              if (response[0] != "Error") {
                this.isUploadedTruckOwner = true;
                //String[] declFileParts = fileNameDecl.split("_");
                var declFileParts = fileNameDecl.split("_");
                this.truckDataDtoTruckOwner = new TruckDataDto();
                this.truckDataDtoTruckOwner.panNo = declFileParts[0];
                this.truckDataDtoTruckOwner.truckNumber = declFileParts[1];
                this.truckDataDtoTruckOwner.mode = "particularTruck";
                this.truckDataDtoTruckOwner.companyId =
                  this.userDataDtoReturnSession.companyId;
                this.truckDataDtoTruckOwner.financialYear =
                  this.userDataDtoReturnSession.financialYearSplit;

                if (this.isUploadedTruckOwner) {
                  if (
                    $("#" + this.pageId + "truckOwnerFileUpload").val() !=
                    null &&
                    $("#" + this.pageId + "truckOwnerFileUpload").val() != ""
                  ) {
                    const constImgUrlTruckOwner =
                      "http://vehicledriverdocs.s3.amazonaws.com/truckownerdeclaration/" +
                      fileNameDecl +
                      "_" +
                      $("#" + this.pageId + "truckOwnerFileUpload").val() +
                      "";
                    this.truckDataDtoTruckOwner.imgURLPan =
                      constImgUrlTruckOwner;
                  }
                  /* here starting masterReadService service */
                  this.masterReadService
                    .setTruckOwnerDetails(this.truckDataDtoTruckOwner)
                    .subscribe((response) => {
                      if (response) {
                        //console.log(this.modeMain);
                        if (this.modeMain != null && this.modeMain == "Save") {
                          this.modalRefferenceTruckOwnerPopUp.close();
                          //pending method here need to call
                          this.saveHireslipDetailsMethod();
                        } else if (
                          this.modeMain != null &&
                          this.modeMain == "Depart"
                        ) {
                          this.modalRefferenceTruckOwnerPopUp.close();
                          this.setDepartureMtd();
                        }
                      }
                    }),
                    (error) =>
                      swal(
                        "ERROR",
                        "Server Error While setTruckOwnerDetails",
                        "error"
                      ),
                    () => console.log("done");
                  /* here ending a masterReadService  service */
                }
              } else {
                swal(
                  "ERROR",
                  "Correct the Truck Owner File and Upload Again",
                  "error"
                );
              }
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) => {
            swal(
              "Error",
              "Server Error While Uploading a Truck Owner File",
              "warning"
            );
          },
          () => console.log("done");
      }
    } else {
      swal("Mandatory Field", "Please Browse the Image To Upload", "warning");
    }
  }
  /* Truck Owner File Upload Code Start End */

  /* Drive Of RC Lic Ins File Upload Code Start Here */

  testingPopUp(uploadRCInsLicForTruck) {
    this.modalRefferenceTruckDetailOfRCInsLicPopUp = this.modalService.open(
      uploadRCInsLicForTruck,
      { centered: true, windowClass: "modalClassForPopUpTruckOwner" }
    );
    this.modalRefferenceTruckDetailOfRCInsLicPopUp.result.then(
      (result) => {
        this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Closed with: ${result}`;
      },
      (reason) => {
        this.modalRefferenceTruckDetailOfRCInsLicClosePopUp = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );
  }

  onFileChangedTruckDetailPopUpRC(event) {
    this.selectedFileTruckDetailsForRC = event.target.files[0];
    console.log(this.selectedFileTruckDetailsForRC);
  }
  onFileChangedTruckDetailPopUpInsurance(event) {
    this.selectedFileTruckDetailsForInsurance = event.target.files[0];
    console.log(this.selectedFileTruckDetailsForInsurance);
  }
  onFileChangedTruckDetailPopUpLicense(event) {
    this.selectedFileTruckDetailsForLicense = event.target.files[0];
    console.log(this.selectedFileTruckDetailsForLicense);
  }

  /* @16062021 new Change as per due multiple upload at a time error so for avoid these 
    error we are split different diff for each upload btn & below we are commented 
    of prev btn upload if u have doubt */

  /* RC File Upload */
  uploadFileForTruckRCPopUpBtnUpload() {
    if (
      $("#" + this.pageId + "popUpTruckRcFileUpload").val() != null &&
      $("#" + this.pageId + "popUpTruckRcFileUpload").val() != undefined &&
      $("#" + this.pageId + "popUpTruckRcFileUpload").val() != ""
    ) {
      if (
        this.selectedFileTruckDetailsForRC.type != null &&
        (this.selectedFileTruckDetailsForRC.type == "image/jpeg" ||
          this.selectedFileTruckDetailsForRC.type == "image/jpg" ||
          this.selectedFileTruckDetailsForRC.type == "image/png")
      ) {
        var splitTypePopUpRc =
          this.selectedFileTruckDetailsForRC.type.split("/");
        const commonListRcPopUp =
          "vehicledriverdocs && documents && ASRAR && " +
          splitTypePopUpRc[1] +
          "&&" +
          $("#" + this.pageId + "lorryNumber").val() +
          "";
        const formData = new FormData();
        formData.append(commonListRcPopUp, commonListRcPopUp);
        formData.append(
          "myfileRC",
          this.selectedFileTruckDetailsForRC,
          this.selectedFileTruckDetailsForRC.name
        );
        this.masterReadService
          .saveRCInsurancePanForTruckMasterFileUpload(formData)
          .subscribe((response) => {
            if (response) {
              if (response[0] != "Error") {
                this.isUploadedPopUp = true;
                this.truckDataDtoFileUploadRcInsLic = new TruckDataDto();
                this.validatePopUpFileUploadTruckNumber = $(
                  "#" + this.pageId + "lorryNumber"
                ).val();
                this.truckDataDtoFileUploadRcInsLic.truckNumber =
                  this.validatePopUpFileUploadTruckNumber;
                this.truckDataDtoFileUploadRcInsLic.driverId =
                  this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
                this.truckDataDtoFileUploadRcInsLic.companyId =
                  this.userDataDtoReturnSession.companyId;
                if (this.isUploadedPopUp) {
                  if (
                    $("#" + this.pageId + "popUpTruckRcFileUpload").val() !=
                    null &&
                    $("#" + this.pageId + "popUpTruckRcFileUpload").val() != ""
                  ) {
                    const constImgUrlRcPopUp =
                      "http://vehicledriverdocs.s3.amazonaws.com/documents/" +
                      $("#" + this.pageId + "lorryNumber").val() +
                      "_" +
                      this.selectedFileTruckDetailsForRC.name +
                      "";
                    this.truckDataDtoFileUploadRcInsLic.imgURLRc =
                      constImgUrlRcPopUp;
                  }
                }
                this.stockService
                  .setRcLicInscDtls(this.truckDataDtoFileUploadRcInsLic)
                  .subscribe((response) => {
                    if (response) {
                      if (response) {
                        swal(
                          "Alert",
                          "Updated RC File Successfully",
                          "success"
                        );
                        $("#" + this.pageId + "popUpTruckRcFileUpload").val("");
                        //this.modalRefferenceTruckDetailOfRCInsLicPopUp.close();
                      } else {
                        swal("Alert", "Failed to Update the RC", "warning");
                      }
                    }
                  }),
                  (error) =>
                    swal(
                      "Error",
                      "Server Error While updateing a setRcLicInscDtls",
                      "error"
                    ),
                  () => console.log("done");
              } else {
                swal("ERROR", "Correct the File and Upload Again", "warning");
              }
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) =>
            swal("Error", "Server Error While Uploading a File", "error"),
          () => console.log("done");
      } else {
        swal(
          "Mandatory Field",
          "Only jpg & png image format are allowed to upload for RC File",
          "warning"
        );
      }
    } else {
      swal("Mandatory Field", "Please select the RC File", "warning");
    }
  }

  /* Insurance File Upload */
  uploadFileForTruckInsurancePopUpBtnUpload() {
    if (
      $("#" + this.pageId + "popUpTruckInsuranceFileUpload").val() != null &&
      $("#" + this.pageId + "popUpTruckInsuranceFileUpload").val() !=
      undefined &&
      $("#" + this.pageId + "popUpTruckInsuranceFileUpload").val() != ""
    ) {
      if (
        this.selectedFileTruckDetailsForInsurance.type != null &&
        (this.selectedFileTruckDetailsForInsurance.type == "image/jpeg" ||
          this.selectedFileTruckDetailsForInsurance.type == "image/jpg" ||
          this.selectedFileTruckDetailsForInsurance.type == "image/png")
      ) {
        var splitTypeInsurance =
          this.selectedFileTruckDetailsForInsurance.type.split("/");
        const commonListInsurancePopUp =
          "vehicledriverdocs && documents && ASRAR && " +
          splitTypeInsurance[1] +
          "&&" +
          $("#" + this.pageId + "lorryNumber").val() +
          "";
        const formData = new FormData();
        formData.append(commonListInsurancePopUp, commonListInsurancePopUp);
        formData.append(
          "myfileInsurance",
          this.selectedFileTruckDetailsForInsurance,
          this.selectedFileTruckDetailsForInsurance.name
        );
        this.masterReadService
          .saveRCInsurancePanForTruckMasterFileUpload(formData)
          .subscribe((response) => {
            if (response) {
              if (response[0] != "Error") {
                this.isUploadedPopUp = true;
                this.truckDataDtoFileUploadRcInsLic = new TruckDataDto();
                this.validatePopUpFileUploadTruckNumber = $(
                  "#" + this.pageId + "lorryNumber"
                ).val();
                this.truckDataDtoFileUploadRcInsLic.truckNumber =
                  this.validatePopUpFileUploadTruckNumber;
                this.truckDataDtoFileUploadRcInsLic.driverId =
                  this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
                this.truckDataDtoFileUploadRcInsLic.companyId =
                  this.userDataDtoReturnSession.companyId;
                if (this.isUploadedPopUp) {
                  if (
                    $(
                      "#" + this.pageId + "popUpTruckInsuranceFileUpload"
                    ).val() != null &&
                    $(
                      "#" + this.pageId + "popUpTruckInsuranceFileUpload"
                    ).val() != ""
                  ) {
                    const constImgUrlInsuransePopUp =
                      "http://vehicledriverdocs.s3.amazonaws.com/documents/" +
                      $("#" + this.pageId + "lorryNumber").val() +
                      "_" +
                      this.selectedFileTruckDetailsForInsurance.name +
                      "";
                    this.truckDataDtoFileUploadRcInsLic.imgURLIns =
                      constImgUrlInsuransePopUp;
                  }
                }
                this.stockService
                  .setRcLicInscDtls(this.truckDataDtoFileUploadRcInsLic)
                  .subscribe((response) => {
                    if (response) {
                      if (response) {
                        swal(
                          "Alert",
                          "Updated Insurance Successfully",
                          "success"
                        );
                        $(
                          "#" + this.pageId + "popUpTruckInsuranceFileUpload"
                        ).val("");
                        //this.modalRefferenceTruckDetailOfRCInsLicPopUp.close();
                      } else {
                        swal(
                          "Alert",
                          "Failed to Update the Insurance",
                          "warning"
                        );
                      }
                    }
                  }),
                  (error) =>
                    swal(
                      "Error",
                      "Server Error While updateing a setRcLicInscDtls",
                      "error"
                    ),
                  () => console.log("done");
              } else {
                swal("ERROR", "Correct the File and Upload Again", "warning");
              }
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) =>
            swal("Error", "Server Error While Uploading a File", "error"),
          () => console.log("done");
      } else {
        swal(
          "Mandatory Field",
          "Only jpg & png image format are allowed to upload for Insurance file",
          "warning"
        );
      }
    } else {
      swal("Mandatory Field", "Please select the Insurance File", "warning");
    }
  }

  /* License File Upload */
  uploadFileForTruckLicensePopUpBtnUpload() {
    if (
      $("#" + this.pageId + "popUpTruckLicenseFileUpload").val() != null &&
      $("#" + this.pageId + "popUpTruckLicenseFileUpload").val() != undefined &&
      $("#" + this.pageId + "popUpTruckLicenseFileUpload").val() != ""
    ) {
      if (
        this.selectedFileTruckDetailsForLicense.type != null &&
        (this.selectedFileTruckDetailsForLicense.type == "image/jpeg" ||
          this.selectedFileTruckDetailsForLicense.type == "image/jpg" ||
          this.selectedFileTruckDetailsForLicense.type == "image/png")
      ) {
        var splitTypeLicensePopUp =
          this.selectedFileTruckDetailsForLicense.type.split("/");
        const commonListLicensePopUp =
          "vehicledriverdocs && documents && ASRAR && " +
          splitTypeLicensePopUp[1] +
          "&&" +
          $("#" + this.pageId + "lorryNumber").val() +
          "";
        const formData = new FormData();
        formData.append(commonListLicensePopUp, commonListLicensePopUp);
        formData.append(
          "myfileLic",
          this.selectedFileTruckDetailsForLicense,
          this.selectedFileTruckDetailsForLicense.name
        );
        this.masterReadService
          .saveRCInsurancePanForTruckMasterFileUpload(formData)
          .subscribe((response) => {
            if (response) {
              if (response[0] != "Error") {
                this.isUploadedPopUp = true;
                this.truckDataDtoFileUploadRcInsLic = new TruckDataDto();
                this.validatePopUpFileUploadTruckNumber = $(
                  "#" + this.pageId + "lorryNumber"
                ).val();
                this.truckDataDtoFileUploadRcInsLic.truckNumber =
                  this.validatePopUpFileUploadTruckNumber;
                this.truckDataDtoFileUploadRcInsLic.driverId =
                  this.hireSlipDtoForCreateHireslipLocalStorage.driverId;
                this.truckDataDtoFileUploadRcInsLic.companyId =
                  this.userDataDtoReturnSession.companyId;
                if (this.isUploadedPopUp) {
                  if (
                    $(
                      "#" + this.pageId + "popUpTruckLicenseFileUpload"
                    ).val() != null &&
                    $(
                      "#" + this.pageId + "popUpTruckLicenseFileUpload"
                    ).val() != ""
                  ) {
                    const constImgUrlLicensePopUp =
                      "http://vehicledriverdocs.s3.amazonaws.com/documents/" +
                      $("#" + this.pageId + "lorryNumber").val() +
                      "_" +
                      this.selectedFileTruckDetailsForLicense.name +
                      "";
                    this.truckDataDtoFileUploadRcInsLic.imgURLLic =
                      constImgUrlLicensePopUp;
                  }
                }
                this.stockService
                  .setRcLicInscDtls(this.truckDataDtoFileUploadRcInsLic)
                  .subscribe((response) => {
                    if (response) {
                      if (response) {
                        swal(
                          "Alert",
                          "Updated License Successfully",
                          "success"
                        );
                        $(
                          "#" + this.pageId + "popUpTruckLicenseFileUpload"
                        ).val("");
                        //this.modalRefferenceTruckDetailOfRCInsLicPopUp.close();
                      } else {
                        swal(
                          "Alert",
                          "Failed to Update the License",
                          "warning"
                        );
                      }
                    }
                  }),
                  (error) =>
                    swal(
                      "Error",
                      "Server Error While updateing a setRcLicInscDtls",
                      "error"
                    ),
                  () => console.log("done");
              } else {
                swal("ERROR", "Correct the File and Upload Again", "error");
              }
            }
            this.changeDetectorRef.detectChanges();
          }),
          (error) =>
            swal(
              "Error",
              "Server Error While Uploading a License File",
              "error"
            ),
          () => console.log("done");
      } else {
        swal(
          "Mandatory Field",
          "Only jpg & png image format are allowed to upload for License file",
          "warning"
        );
      }
    } else {
      swal("Mandatory Field", "Please select the License File", "warning");
    }
  }

  /* new Change as per req multiple error @16062021 end*/

  printInvoiceAlone(hireslipDto, hireSlipNum) {
    this.hireSlipDtoForPrintInvoiceAlone = new HireSlipDto();

    this.hireSlipDtoForPrintInvoiceAlone = hireslipDto;
    this.hireSlipDtoForPrintInvoiceAlone.hireslipnumber = hireSlipNum;
    this.hireSlipDtoForPrintInvoiceAlone.invoiceNumber = hireSlipNum;
    this.hireSlipDtoForPrintInvoiceAlone.companyImgUrl = "";
    this.hireSlipDtoForPrintInvoiceAlone.barcodeImgUrl = "";

    swal({
      title: "Invoice Report",
      text: "Sure U Want to Print the Invoice Detials for this Trip",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.getLrDetailsForInvPrintAlone(
          hireSlipNum,
          this.hireSlipDtoForPrintInvoiceAlone
        );
      } else {
        this.overAllResetComplete();
        //this.stepper.selectedIndex = 0;
        this.router.navigate(["/dashboard/classic"]);
      }
    });
  }
  getLrDetailsForInvPrintAlone(invoiceNumber, hireslipDto) {
    this.invPrintAloneForLrs = [];
    this.invPrintAloneForLRDto = new LRDto();
    this.kantaWeight = $("#" + this.pageId + "kantaWeight").val();

    this.invPrintAloneForLrs =
      this.hireSlipDtoForCreateHireslipLocalStorage.list;
    this.invPrintAloneForLRDto.list = this.invPrintAloneForLrs;
    this.invPrintAloneForLRDto.invoiceNumber = invoiceNumber;
    this.invPrintAloneForLRDto.kantaWeight =
      this.kantaWeight == null ? 0 : this.kantaWeight;
    this.invPrintAloneForLRDto.companyId =
      this.userDataDtoReturnSession.companyId;
    this.showSpinnerActionForHireslip = true;
    this.stockService
      .getBookingInvoiceDetails(this.invPrintAloneForLRDto)
      .subscribe((response) => {
        this.showSpinnerActionForHireslip = false;
        //console.log(hireslipDto);
        hireslipDto.suppliername = this.suppName;
        hireslipDto.goodsType = this.goodsType;
        if (
          this.hireSlipDtoForCreateHireslipLocalStorage.invoiceType == "Dummy"
        ) {
          this.bookingInvoiceNewPrint_InHireslip(
            hireslipDto,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType,
            null
          );
          /*this.bookingInvoiceNewPrint_InHireslip(this.hireSlipDtoForBookingInvoicePrintMhd,
                        this.listOfShortageLrs, response, this.gurWgt, this.suppName, this.goodsType);*/
        } else {
          this.bookingInvoiceNewPrint_InHireslip(
            hireslipDto,
            this.listOfShortageLrs,
            response,
            this.gurWgt,
            this.suppName,
            this.goodsType,
            null
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerActionForHireslip = false;
        swal(
          "Error",
          "Server Error While Getting Booking Invoice Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  /* Drive Of RC Lic Ins File Upload Code End Here */

  /* ftTrackingFlow Start */

  ftTrackingFlow() {
    this.hireSlipDtoForFtTrackingUserValue = new HireSlipDto();
    this.hireSlipDtoForFtTrackingUserValue = this.getUsersValue();
    //alert('ok1' + this.isFTTripReq + "-" + this.hireSlipDtoForFtTrackingUserValue.trackingFTTripId);
    if (
      this.isFTTripReq &&
      this.hireSlipDtoForFtTrackingUserValue.trackingFTTripId == null
    ) {
      this.showSpinnerActionForHireslip = true;
      this.stockService
        .trackingIntgTripFT(this.hireSlipDtoForFtTrackingUserValue)
        .subscribe((response) => {
          this.showSpinnerActionForHireslip = false;
          //console.log(response);
          if (response.trackingFTStatus) {
            swal(
              "Success",
              "Tracking integration initiated successfully, to complete the Consent process," +
              response.trackingCarrierMsg +
              ".",
              "success"
            );
            // this.overAllResetComplete();
            //this.stepper.reset();
            // this.router.navigate(['/dashboard/classic']);
          } else {
            swal(
              "Failed",
              "Tracking Integration failed, please try again or contact Admin",
              "warning"
            );
            // this.overAllResetComplete();
            //this.stepper.reset();
            // this.router.navigate(['/dashboard/classic']);
          }
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerActionForHireslip = false;
          swal(
            "Error",
            "Server Error While Tracking Integration TripFT,please try again or contact Admin",
            "warning"
          );
        },
        () => console.log("done");
    } else if (
      this.isFTTripReq &&
      this.hireSlipDtoForFtTrackingUserValue.trackingFTTripId > 0 &&
      this.driverNumberChangeTemp != null &&
      this.hireSlipDtoForFtTrackingUserValue.driverMobileNumber !=
      this.driverNumberChangeTemp
    ) {
      //Imran_SG_Chg_V5
      // this.showSpinnerActionForHireslip = true;
      // this.stockService
      //   .trackingIntgTripFTUpdate(this.hireSlipDtoForFtTrackingUserValue)
      //   .subscribe((response) => {
      //     this.showSpinnerActionForHireslip = false;
      //     //console.log(response);
      //     if (response.trackingFTStatus) {
      //       swal(
      //         "Success",
      //         "Driver number updated successfully and integrated with the Trip, to complete the Consent process," +
      //         response.trackingCarrierMsg +
      //         ".",
      //         "success"
      //       );
      //       // this.overAllResetComplete();
      //       //this.stepper.reset();
      //       // this.router.navigate(['/dashboard/classic']);
      //     } else {
      //       swal(
      //         "Failed",
      //         "Failed to update the Driver number with Tracking, please try again or contact Admin",
      //         "warning"
      //       );
      //       // this.overAllResetComplete();
      //       //this.stepper.reset();
      //       // this.router.navigate(['/dashboard/classic']);
      //     }
      //     this.changeDetectorRef.detectChanges();
      //   }),
      //   (error) => {
      //     this.showSpinnerActionForHireslip = false;
      //     swal(
      //       "Error",
      //       "Failed to update the Driver number with Tracking, please try again or contact Admin",
      //       "warning"
      //     );
      //   },
      //   () => console.log("done");

    } else {
      // this.overAllResetComplete();
      //this.stepper.reset();
      // this.router.navigate(['/dashboard/classic']);
    }
  }

  /* ftTrackingFlow End */

  invoiceSummaryBtnForPrint() {
    this.summaryInvoiceForLrDtoPrint = new LRDto();
    //basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      console.log(this.tripScheduledToLocalStorage_HireslipRpt);
      this.hireslipNumberSetOnLoad =
        this.tripScheduledToLocalStorage_HireslipRpt.hireslipnumber;
      console.log("am here ");
      console.log(this.hireslipNumberSetOnLoad);
      console.log($("#" + this.pageId + "hireslipNumber").val());
    } else {
      this.hireslipNumberSetOnLoad = $(
        "#" + this.pageId + "hireslipNumber"
      ).val();
      console.log("am here 2");
    }
    //this.hireslipNumberSetOnLoad = 'NP/CLT/1920/30';
    this.summaryInvoiceForLrDtoPrint.invoiceNumber =
      this.hireslipNumberSetOnLoad;
    this.summaryInvoiceForLrDtoPrint.fromStation = this.sourceSetOnLoad;
    this.summaryInvoiceForLrDtoPrint.toStation = this.destinationSetOnLoad;
    this.summaryInvoiceForLrDtoPrint.companyId =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .getInvoiceDestinationSummary(this.summaryInvoiceForLrDtoPrint)
      .subscribe((response) => {
        //console.log(response);
        if (response != null) {
          this.destInvoiceSummaryReportPrintOut_HireslipPage(
            this.summaryInvoiceForLrDtoPrint,
            response
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("done");
  }
  destInvoiceSummaryReportPrintOut_HireslipPage(
    summaryInvoiceForLrDtoPrint,
    response
  ) {
    localStorage.clear();
    localStorage.setItem(
      "invoiceSummaryForLRDtoPrint",
      JSON.stringify(summaryInvoiceForLrDtoPrint)
    );
    localStorage.setItem(
      "invoiceSummaryForLRDtoListPrint",
      JSON.stringify(response)
    );
    //basha
    if (
      this.tripScheduledToLocalStorage_HireslipRpt != null &&
      this.tripScheduledToLocalStorage_HireslipRpt != undefined
    ) {
      console.log("check");
      this.viewInvoiceSummaryPrint_Hireslip = true;
      window.addEventListener("afterprint", (onclick) => {
        if (this.viewInvoiceSummaryPrint_Hireslip) {
          this.viewInvoiceSummaryPrint_Hireslip = false;
          summaryInvoiceForLrDtoPrint = new LRDto();
          response = [];
          localStorage.clear();
        }
      });
      //window.open('/trip-creation/invoiceSummaryPrint', '_blank');
      /*this.viewInvoiceSummaryPrint_Hireslip = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewInvoiceSummaryPrint_Hireslip) {
                    this.viewInvoiceSummaryPrint_Hireslip = false;
                    summaryInvoiceForLrDtoPrint = new LRDto();
                    response = [];
                    localStorage.clear();
                }
            });*/
    } else {
      this.viewInvoiceSummaryPrint_Hireslip = true;
      window.addEventListener("afterprint", (onclick) => {
        if (this.viewInvoiceSummaryPrint_Hireslip) {
          this.viewInvoiceSummaryPrint_Hireslip = false;
          summaryInvoiceForLrDtoPrint = new LRDto();
          response = [];
          localStorage.clear();
        }
      });
    }
  }

  addNumberOfTrucksDetails(hireslipnumber) {
    this.hireSlipDtoForAddNoOfTruck = new HireSlipDto();

    this.hireSlipDtoForAddNoOfTruck.hireslipnumber = hireslipnumber;
    this.hireSlipDtoForAddNoOfTruck.companyid =
      this.userDataDtoReturnSession.companyId;

    this.stockService
      .addNumberOfTrucks(this.hireSlipDtoUserValueForDepartureMethod)
      .subscribe((response) => {
        console.log(response);
        if (response.status == "failure") {
          swal("Failed", "Failed to add number of truck details", "error");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal(
          "Error",
          "Server Error While Getting adding Number Of Trucks",
          "warning"
        ),
      () => console.log("done");
  }

  departureTimeCalListner() {
    this.hireSlipDtoForScheduleTime = new HireSlipDto();

    this.destination = $("#" + this.pageId + "destination").val();
    this.validateDepartureTime = $("#" + this.pageId + "departureTime").val();
    this.validateDepartureDate = $("#" + this.pageId + "departureDate").val();

    this.hireSlipDtoForScheduleTime.fromstation =
      this.userDataDtoReturnSession.office;
    this.hireSlipDtoForScheduleTime.tostation = this.destination;
    this.hireSlipDtoForScheduleTime.companyid =
      this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoForScheduleTime.modeForScheduleDateTime =
      "AutoCalaculationDateTime";

    //this.hireSlipDtoForScheduleTime.departuredateStr = this.validateDepartureDate == null ? null : moment(this.validateDepartureDate).format("DD/MM/YYYY");
    this.hireSlipDtoForScheduleTime.departuredateStr =
      this.validateDepartureDate;
    this.hireSlipDtoForScheduleTime.departureTime = this.validateDepartureTime;

    this.masterReadService
      .getTimeService(this.hireSlipDtoForScheduleTime)
      .subscribe((response) => {
        this.hireSlipDtoForScheduleTimeReturn = null;
        this.hireSlipDtoForScheduleTimeReturn = response;
        console.log(response);
        if (this.hireSlipDtoForScheduleTimeReturn.size > 0) {
          $("#" + this.pageId + "scheduleDate").val(
            this.hireSlipDtoForScheduleTimeReturn.scheduleddateStr
          );
          $("#" + this.pageId + "scheduleTime").val(
            this.hireSlipDtoForScheduleTimeReturn.scheduledTime
          );
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal("Error", "Server Error While Getting TimeService", "warning"),
      () => console.log("done");
  }

  setDepartureDateTime() {
    this.hireSlipDtoForDeparureDateTime = new HireSlipDto();
    this.hireSlipDtoForDeparureDateTime.mainStation =
      this.userDataDtoReturnSession.mainAdminStation;
    this.hireSlipDtoForDeparureDateTime.companyid =
      this.userDataDtoReturnSession.companyId;
    this.stockService
      .determineDepDateTime(this.hireSlipDtoForDeparureDateTime)
      .subscribe((response) => {
        //console.log(response);
        this.hireSlipDtoForDeparureDateTimeReturn = null;
        this.hireSlipDtoForDeparureDateTimeReturn = response;
        if (
          this.hireSlipDtoForDeparureDateTimeReturn.departuredate != null &&
          this.hireSlipDtoForDeparureDateTimeReturn.departuredate != undefined
        ) {
          $("#" + this.pageId + "departureDate").val(
            this.hireSlipDtoForDeparureDateTimeReturn.departuredate == null
              ? null
              : moment(
                this.hireSlipDtoForDeparureDateTimeReturn.departuredate
              ).format("YYYY-MM-DD")
          );
          $("#" + this.pageId + "departureTime").val(
            this.hireSlipDtoForDeparureDateTimeReturn.departureTime
          );
          setTimeout(() => {
            this.departureTimeCalListner();
          }, 2000);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal(
          "Error",
          "Server Error While Getting Determine DepDateTime",
          "warning"
        ),
      () => console.log("done");
  }

  resetFeildHireslip() {
    localStorage.clear();
    if (
      this.userDataDtoReturnSession.officeType != null &&
      this.userDataDtoReturnSession.officeType == "Booking Office"
    ) {
      this.invoiceType = "Main";
      this.isMainInvoice = true;
      this.isBookingStation = true;
      //hpLorry.setVisible(true);
    } else {
      this.isMainInvoice = false;
      this.invoiceType = "Dummy";
      //hpLorry.setVisible(false);
    }
    this.financialYearOnloadValidate =
      this.userDataDtoReturnSession.financialYear;
    this.firstYearStgOnloadValidate =
      this.financialYearOnloadValidate.split("-")[0];
    this.secondYearStgOnloadValidate =
      this.financialYearOnloadValidate.split("-")[1];

    this.printMode = null;
    this.modeMain = null;

    this.departureButtonHideShow = false;
    this.saveAndPrintButtonHideShow = false;
    this.hideShowIsLastPointDelivery = false;
    this.hideShowTwoPointDoorDelivery = false;
    this.hideAndShowInputFieldsForSelectedDestination = true;
    this.hideAndShowInputFieldsDeliveryPoint = true;

    this.hideAndShowPrintInvoiceBtn = false;
    this.hideAndShowPrintHireslipBtn = false;
    this.hideAndShowInvSummPrintBtn = false;

    this.validateDeliveryPtSingleChecked = false;
    this.validateDeliveryPtMultipleChecked = false;
    this.validateIslastPointDeliveryIdChecked = false;

    this.isFTTripReq = true;

    //print
    this.viewHireslipDetailsPrint = false;

    this.isMatched = false;

    /* Hash Map */
    this.hashmapEwayBill = new Map<String, Number>();

    /*Single Dto */

    this.truckDataDtoLocalStorageTruckMaster = new TruckDataDto();

    this.hireSlipDtoForShortAndExtra = new HireSlipDto();
    this.hireSlipDtoGoodsTypes = new HireSlipDto();

    /* save & depart Single dto */
    this.hireSlipDtoUserValue = new HireSlipDto();
    this.hireSlipDtoSaveHireslip = new HireSlipDto();
    this.chkStatusHireSlipDto = new HireSlipDto();
    this.truckDataDtoValidateTruckType = new TruckDataDto();
    this.rcLicInscTruckDataDto = new TruckDataDto();
    this.truckDataDtoDepartRCLicPan = new TruckDataDto();
    this.truckDataDtoCheckPanIns = new TruckDataDto();
    this.hireSlipDtoForPrintInvoiceAlone = new HireSlipDto();

    //depart
    this.hireSlipDtoUserValueForDepartureMethod = new HireSlipDto();
    this.truckDataDtoForDepartureMethod = new TruckDataDto();
    this.hireSlipDtoUserValueTripDeparture = new HireSlipDto();
    this.lrDtoForSMS = new LRDto();
    this.hireSlipDtoForInvoiceDetails = new HireSlipDto();
    this.hireSlipDtoBarCode1 = new HireSlipDto();
    this.lrDtoForLrDetailsMhdPrint = new LRDto();
    this.hireSlipDtoForLrDetailsMhd = new HireSlipDto();
    this.hireSlipDtoForPrintHireslip = new HireSlipDto();
    this.hireslipDtoForPrintHireslipReturn = null;
    this.lrDtoForPrintHireslipReturn = null;

    this.hireSlipDtoUserValueForPrintInvoiceBtn = new HireSlipDto();
    this.lrsPrintInvoiceBtn = [];
    this.lrDtoPrintInvoiceBtn = new LRDto();
    this.hireSlipDtoForBookingInvoicePrintMhd = new HireSlipDto();
    this.validateKantaWgtBookingInvoice = null;

    this.hireSlipDtoUserValueForPrintHireslipBtn = new HireSlipDto();
    this.hireSlipDtoBarCode = new HireSlipDto();
    this.hireSlipDtoForTimeDuratioMethod = new HireSlipDto();
    this.lrDtoForTimeDuratioReturn = null;
    this.hireslipDtoForTimeDuratioReturn = null;
    /*listDto */
    //pop up
    this.localStorageLorryTruckNo = null;

    this.invoiceNumberSetOnLoad = null;
    this.hireslipNumberSetOnLoad = null;
    this.invoiceDateSetOnLoad = null;
    this.sourceSetOnLoad = null;
    this.destinationSetOnLoad = null;
    this.numberOfLrsSetOnLoad = null;
    this.numberOfArticlesSetOnLoad = null;
    this.guaranteeWgtSetOnLoad = null;
    this.gurWeightTotalSetOnload = null;
    this.kantaWeightSetOnLoad = null;
    this.loadedBySetOnLoad = null;
    //Vehicle details
    this.lorryNumberSetOnLoad = null;
    this.lorryOwnerSetOnLoad = null;
    this.truckLengthSetOnLoad = null;
    this.contactPersonSetOnLoad = null;
    this.addressSetOnLoad = null;
    this.truckDriverNameSetOnLoad = null;
    this.driverLicNoSetOnLoad = null;
    this.driverMobileNoSetOnLoad = null;
    this.supplierNameTextSetOnLoad = null;
    this.panNoSetOnLoad = null;
    this.insuranceNoSetOnLoad = null;
    this.doorOpenAmtSetOnLoad = null;
    this.vehicleHeightAmtSetOnLoad = null;
    this.doorDeliveryAmtSetOnLoad = null;
    this.extraWeightAmtSetOnLoad = null;
    this.checkPostAmtSetOnLoad = null;
    this.otherChargeAmtSetOnLoad = null;
    this.noOfEWBInConsolidatedSystemSetOnLoad = null;
    this.noOfEWBInConsolidatedManualSetOnLoad = null;
    this.consolidatedEwayBillSetOnLoad = null;
    this.confirmConsolidatedEwayBillSetOnLoad = null;
    //Billing details
    this.totalHireAmountSetOnLoad = null;
    this.advanceAmountSetOnLoad = null;
    this.balanceAmountSetOnLoad = null;
    this.touchingHireAmountSetOnLoad = null;
    this.payableAtSetOnLoad = null;
    this.payableAtDupSetOnLoad = null;
    this.remarksSetOnLoad = null;
    this.deliveryPointsSetOnLoad = null;

    this.shortageLrs = null;
    this.spiltLrs = [];
    this.lrShrotageSpilt = null;
    this.twoPointDDCheckBox = null;
    this.twoPointsDoorDeliveryMultipleLrs = null;
    this.ewayBillNo = null;
    this.ewayBillNoConfirm = null;

    this.validateDepartureDate = null;
    this.validateScheduledDate = null;
    this.validateDepartureTime = null;
    this.validateScheduledTime = null;
    this.departureDate = null;
    this.scheduledDate = null;
    this.scheduledTime = null;
    this.departureTime = null;
    this.arrivalTime = null;
    this.currentDate = null;

    this.hireAmt = null;
    this.validateHireAmtForBaseHireAmt = null;
    this.validateHireAmtForDoorOpen = null;
    this.validateHireAmtForVehicleHeight = null;
    this.validateHireAmtForDoorDely = null;
    this.validateHireAmtForExtraWeight = null;
    this.validateHireAmtForCheckPost = null;
    this.validateHireAmtForotherChrg = null;

    this.isValid = false;
    this.truckDto = new TruckDataDto();
    this.totalGurWgt = null;
    this.calculateAalanceAmt = null;
    this.validateTotalHireAmt = null;
    this.validateAdvanceAmt = null;
    this.truckTypeId = null;
    this.validateTruckTypeTotGurWgt = null;
    this.goodsType = null;
    this.gurWgt = null;
    this.kantaWgt = null;
    this.suppName = null;
    this.listOfShortageLrs = [];

    this.ewayLrs = null;
    this.countEwayLrs = null;
    this.countEwayLrsAbove50K = null;
    this.countEwayLrsBelow50K = null;
    this.ewayBillLrForLRDto = new LRDto();
    this.returnEwayBillLrsLRDto = [];
    this.goodsValueFind = null;
    this.goodsValueFind1 = null;
    this.ewayBill = null;

    /* this.isReadOnlyInvoiceNo = true;
         this.isReadOnlyInvoiceDates = false;
         this.isReadOnlyHireslipNo = false;
         this.isReadOnlySource = false;
         this.isReadOnlyDestination = false;
         this.isReadOnlyNumberOfLrs = false;
         this.isReadOnlyTotalNoArticle = false;
         this.isReadOnlyLorryNo = false;
         this.isReadOnlyLorryOwner = false;
         this.isReadOnlyTruckLength = false;
         this.isReadOnlyLorryContactPerson = false;
         this.isReadOnlyOwnerAddress = false;
         this.isReadOnlyDriverName = false;
         this.isReadOnlyDriverLicNo = false;
         this.isReadOnlySupplierName = false;
         this.isReadOnlyPanNo = false;*/

    this.invoiceNumber = null;
    this.hireslipNumber = null;
    this.invoiceDate = null;
    this.source = null;
    this.destination = null;
    this.numberOfLrs = null;
    this.numberOfArticles = null;
    this.guaranteeWgt = null;
    this.gurWgtTotal = null;
    this.kantaWeight = null;
    this.loadedBy = null;
    //Vehicle details
    this.lorryNumber = null;
    this.lorryOwner = null;
    this.truckLength = null;
    this.contactPerson = null;
    this.address = null;
    this.truckDriverName = null;
    this.driverLicNo = null;
    this.driverMobileNo = null;
    this.supplierNameText = null;
    this.panNo = null;
    this.insuranceNo = null;
    this.noOfEWBInConsolidatedSystem = null;
    this.noOfEWBInConsolidatedManual = null;
    this.consolidatedEwayBill = null;
    this.confirmConsolidatedEwayBill = null;

    //Billing details
    this.totalHireAmount = null;
    this.advanceAmount = null;
    this.balanceAmount = null;
    this.touchingHire = null;
    this.payableAt = null;
    this.remarks = null;
    this.deliveryPoints = null;

    this.doorOpenAmount = null;
    this.vehiHeightAmount = null;
    this.doorDeliveryAmount = null;
    this.extraWeightAmount = null;
    this.chkPostAmount = null;
    this.otherChargesAmount = null;
    this.baseHireAmount = null;
    this.ewayBillNumber = null;
    this.twoPtDoorDelyLrs = null;
    this.selectedTruckTypeId = null;

    this.validateDeliveryPtSingleMult = null;
    this.printRewardYesNoValidate = null;

    this.listInvoice = [];
    this.listOfInvoice = [];
    this.lrsDetailsMhdForPrint = [];

    this.departureTimeSplit = [];

    this.validateInsPolNo = null;
    this.validateLorryNumber = null;
    this.validatePanNo = null;

    this.selectedFileTruckOwner = null;
    this.isUploadedTruckOwner = false;
    this.truckDataDtoTruckOwner = new TruckDataDto();

    this.selectedFileTruckDetailsForRC = null;
    this.selectedFileTruckDetailsForInsurance = null;
    this.selectedFileTruckDetailsForLicense = null;
    this.isUploadedPopUp = false;
    this.truckDataDtoFileUploadRcInsLic = new TruckDataDto();
    this.validatePopUpFileUploadTruckNumber = null;

    this.multipleDeliveryPointListPopupReturn = null;
    this.firstPtDeliv = null;
    this.multipleInvoiceIsLastPointListPopupReturn = null;
    this.validateEwayBill = null;
    this.validateEwayBillConsoldateConfirm = null;
    this.invPrintAloneForLrs = [];
    this.invPrintAloneForLRDto = new LRDto();
    this.lrDtoFTRoutingExcep = new LRDto();

    this.rcLicInscTruckDataDtoReturn = null;
    $("input[type=text], textarea").val("");
    $("input[type=number]").val("");

    $("#" + this.pageId + "deliveryPointIdForSingle").prop("checked", true);
    $("#" + this.pageId + "deliveryPointIdForMultiple").prop("checked", false);
    $("#" + this.pageId + "twoPointDD").prop("checked", false);
    $("#" + this.pageId + "isMultiInvoiceTripId").prop("checked", false);
    $("#" + this.pageId + "islastPointDeliveryId").prop("checked", false);

    $("#" + this.pageId + "printRewardDetails").val("no");
    $("#" + this.pageId + "invoiceDates").val("");
    $("#" + this.pageId + "scheduleDate").val("");
    $("#" + this.pageId + "scheduleTime").val("");
    $("#" + this.pageId + "departureDate").val("");
    $("#" + this.pageId + "departureTime").val("");

    //reset field readonly
    $("#" + this.pageId + "totalHire").prop("readonly", false);
    $("#" + this.pageId + "advance").prop("readonly", false);
    $("#" + this.pageId + "balance").prop("readonly", false);
    $("#" + this.pageId + "touchingHire").prop("readonly", false);

    $("#" + this.pageId + "payableAt").prop("readonly", false);
    $("#" + this.pageId + "hireslipRemarksId").prop("readonly", false);
    $("#" + this.pageId + "deliveryPoints").prop("readonly", false);
    $("#" + this.pageId + "deliveryPoints").prop("readonly", false);
    $("#" + this.pageId + "lorryNumber").prop("readonly", false);
    $("#" + this.pageId + "supplierNameText").prop("readonly", false);
    $("#" + this.pageId + "panNumber").prop("readonly", false);
    $("#" + this.pageId + "contactPerson").prop("readonly", false);
    $("#" + this.pageId + "driverNameText").prop("readonly", false);
    $("#" + this.pageId + "driverLicenseNo").prop("readonly", false);
    $("#" + this.pageId + "departureDate").prop("readonly", false);
    this.showDepartureDateIcon = true;
    $("#" + this.pageId + "scheduleDate").prop("readonly", false);
    $("#" + this.pageId + "guranteeWeight").prop("readonly", false);
    $("#" + this.pageId + "gurWeightTotal").prop("readonly", false);

    $("#" + this.pageId + "kantaWeight").prop("readonly", false);
    $("#" + this.pageId + "driverMobileNo").prop("readonly", false);
    $("#" + this.pageId + "invoiceDates").prop("readonly", false);
    $("#" + this.pageId + "departureTime").prop("readonly", false);
    $("#" + this.pageId + "scheduleTime").prop("readonly", false);

    $("#" + this.pageId + "saveButton").prop("disabled", false);
    $("#" + this.pageId + "depButton").prop("disabled", false);
    //$("#"+this.pageId+"updateDriverBtnId").prop('disabled', false);

    $("#" + this.pageId + "baseHireAmt").prop("readonly", false);
    $("#" + this.pageId + "doorOpen").prop("readonly", false);
    $("#" + this.pageId + "vehicleHeight").prop("readonly", false);
    $("#" + this.pageId + "doorDelivery").prop("readonly", false);
    $("#" + this.pageId + "extraWeight").prop("readonly", false);
    $("#" + this.pageId + "checkPost").prop("readonly", false);
    $("#" + this.pageId + "otherCharges").prop("readonly", false);
    $("#" + this.pageId + "payableAt").prop("readonly", false);
    $("#" + this.pageId + "loadedBy").prop("readonly", false);

    $("#" + this.pageId + "inoviceNumber").prop("readonly", false);
    $("#" + this.pageId + "invoiceDates").prop("readonly", false);
    $("#" + this.pageId + "hireslipNumber").prop("readonly", false);
    $("#" + this.pageId + "source").prop("readonly", false);
    $("#" + this.pageId + "destination").prop("readonly", false);
    $("#" + this.pageId + "numberOfLrs").prop("readonly", false);
    $("#" + this.pageId + "numberOfArticles").prop("readonly", false);
    $("#" + this.pageId + "lorryNumber").prop("readonly", false);
    $("#" + this.pageId + "lorryOwner").prop("readonly", false);
    $("#" + this.pageId + "truckLength").prop("readonly", false);
    $("#" + this.pageId + "contactPerson").prop("readonly", false);
    $("#" + this.pageId + "address").prop("readonly", false);
    $("#" + this.pageId + "driverNameText").prop("readonly", false);
    $("#" + this.pageId + "isReadOnlyDriverLicNo").prop("readonly", false);
    $("#" + this.pageId + "supplierNameText").prop("readonly", false);
    $("#" + this.pageId + "panNumber").prop("readonly", false);

    this.viewShortExtraDescription = false;

    //4th
    this.lrDtoHideDummyInvoice = new LRDto();
    this.listHideDummyInvoice = [];
    this.listOfLrsHideDummyInvoice = [];
    this.truckDataDtoForTruckTypeClick = new TruckDataDto();
    this.listMaintenancesHideDummyInvoice = [];
    this.bookingInvoiceForLrDtoReturn = null;

    // 5
    this.hireSlipDtoForFtTrackingUserValue = new HireSlipDto();
    this.driverNumberChangeTemp = null;
    this.hireSlipDtoUserValueForDepartureMethodReturn = null;
    this.viewShortExtraDescription = "";
    this.hireSlipDtoForAddNoOfTruck = new HireSlipDto();
    this.hireSlipDtoForScheduleTime = new HireSlipDto();
    this.hireSlipDtoForScheduleTimeReturn = null;

    this.viewInvoiceDetailsPrint_Hireslip = false;
    this.viewInvoiceSummaryPrint_Hireslip = false;

    this.printInvoiceBtnHideShow = false;

    //
    this.scheduledTripFromLocalStorage_Hireslip = null;
    this.scheduledTripToLocalStorage_Hireslip = null;
    this.tripOnTransitFromLocalStorage_Hireslip = null;
    this.tripOnTransitLocalStorage_Hireslip = null;
    this.tripOnTransitToLocalStorage_Hireslip = null;
    this.tripScheduledToLocalStorage_HireslipRpt = null;
  }

  overAllResetComplete() {
    /* 1 st level stock grouping */
    //this.getArticlesStocksSummaryDetailsList();
    this.resetFeilds();
    this.stockDetailsDataList = [];
    $("#" + this.pageId + "stockDetailsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerStockDetails.next();
    /* 1 st level stock grouping */

    /* 2 nd level invoice details */
    this.invoiceDetailsClearField();
    /* 2 nd level invoice details */

    /* 3 th level make a trip */
    this.makeATripResetField();
    /* 3 th level make a trip */

    /* 4 th level invoice */
    this.invoiceClearField();
    /* 4 th level invoice */

    /* 5 th level Hireslip */
    this.resetFeildHireslip();
    /* 5 th level Hireslip */

    //
    this.pendingHireslipFromLocalStorage = null;
  }

  startOverFromHireslipBtn() {
    this.stepper.reset();
    this.overAllResetComplete();
    this.getArticlesStocksSummaryDetailsList();
  }

  resetFormHireslipBtnBtn() {
    swal({
      title: "Reset Confirm",
      text: "Sure U want to reset this Stock Grouping details",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((sureYesBtn) => {
      if (sureYesBtn) {
        this.stepper.reset();
        this.overAllResetComplete();
        this.getArticlesStocksSummaryDetailsList();
      }
    });
  }

  /* 5 th level main Hireslip detail start*/

  /* 5 th level main Hireslip detail end*/

  //Clear Form OverAll
  startOverBtn() {
    this.stepper.reset();
    this.overAllResetComplete();
    this.getArticlesStocksSummaryDetailsList();
  }

  overAllResetBtn() {
    swal({
      title: "Reset Confirm",
      text: "Sure U want to reset this Stock Grouping details",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((sureYesBtn) => {
      if (sureYesBtn) {
        this.stepper.reset();
        this.overAllResetComplete();
        this.getArticlesStocksSummaryDetailsList();
      }
    });
  }

  customPrintStocksSummaryRpt() {
    if (this.stockSummaryDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Destination",
        "Total LRs",
        "Tot Art",
        "Tot Act Wgt",
        "Tot Chg Wgt",
        "Total Amount",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [20, 15, 15, 15, 15, 20];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      //heading logics For Date

      this.validateFromDateIdValue = moment(new Date()).format("DD-MM-YYYY");
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        this.validateFromDateIdValue,
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      this.lrsSummary =
        this.articleSummary =
        this.acWtSummary =
        this.chgWtSummary =
        this.toPaySummary =
        0;

      for (let i = 0; i < this.stockSummaryDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.stockSummaryDataList[i].endDest,
          this.stockSummaryDataList[i].totalLrs,
          this.stockSummaryDataList[i].totalArticles,
          this.stockSummaryDataList[i].actualWeight,
          this.stockSummaryDataList[i].chargedWeight,
          this.stockSummaryDataList[i].totalAmount,
        ];

        this.lrsSummary =
          this.lrsSummary + this.stockSummaryDataList[i].totalLrs;
        this.articleSummary =
          this.articleSummary + this.stockSummaryDataList[i].totalArticles;
        this.acWtSummary =
          this.acWtSummary + this.stockSummaryDataList[i].actualWeight;
        this.chgWtSummary =
          this.chgWtSummary + this.stockSummaryDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.stockSummaryDataList[i].totalAmount;

        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.stockSummaryDataList.length,
        this.lrsSummary,
        this.articleSummary,
        this.acWtSummary,
        this.chgWtSummary,
        this.toPaySummary,
      ];

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );
      localStorage.setItem("printTitle", "Stocks Summary");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  customPrintStocksDetailsRpt() {
    if (this.stockDetailsDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnWidths = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Type",
        "E-Bill Status",
        "LR No",
        "Agent",
        "Marked On Src",
        "Consignee",
        "Dest",
        "Bkg Date",
        "Art",
        "A.Wgt",
        "C.Wgt",
        "Amt",
        "Notes",
        "EWay Bill No",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        8, 7, 9, 8, 6, 10, 8, 8, 3, 6, 6, 6, 6, 9,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      //heading logics For Date

      this.validateFromDateIdValue = moment(new Date()).format("DD-MM-YYYY");
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        this.validateFromDateIdValue,
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      this.articleSummary =
        this.acWtSummary =
        this.chgWtSummary =
        this.toPaySummary =
        0;

      for (let i = 0; i < this.stockDetailsDataList.length; i++) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.stockDetailsDataList[i].urgentDly,
          this.stockDetailsDataList[i].ewayBillStatus,
          this.stockDetailsDataList[i].lrNumber,
          this.stockDetailsDataList[i].agentName,
          this.stockDetailsDataList[i].urgentDlyMarkedAtStr,
          this.stockDetailsDataList[i].consigneeName,
          this.stockDetailsDataList[i].destination,
          this.stockDetailsDataList[i].bookingDateStr,
          this.stockDetailsDataList[i].totalArticles,
          this.stockDetailsDataList[i].actualWeight,
          this.stockDetailsDataList[i].chargedWeight,
          this.stockDetailsDataList[i].totalAmount,
          this.stockDetailsDataList[i].remarks,
          this.stockDetailsDataList[i].eWayBillNo,
        ];

        this.articleSummary =
          this.articleSummary + this.stockDetailsDataList[i].totalArticles;
        this.acWtSummary =
          this.acWtSummary + this.stockDetailsDataList[i].actualWeight;
        this.chgWtSummary =
          this.chgWtSummary + this.stockDetailsDataList[i].chargedWeight;
        this.toPaySummary =
          this.toPaySummary + this.stockDetailsDataList[i].totalAmount;

        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.stockDetailsDataList.length,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.articleSummary,
        this.acWtSummary,
        this.chgWtSummary,
        this.toPaySummary,
        "",
        "",
      ];

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );
      localStorage.setItem("printTitle", "Stocks Details");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  lrNumberToEnquiryForm(dataList) {
    this.selectedLrNumber = dataList.lrNumber;
    console.log(this.selectedLrNumber);
    localStorage.clear();

    // localStorage.setItem('lrNumberFromEwayBillValidation',
    // 	 JSON.stringify(this.selectedLrNumber));
    //         this.router.navigate(['/lr/enquiry/lrEnquiry']);

    localStorage.setItem("searchBy", "lrnumber");
    localStorage.setItem("enteredLrnumber", this.selectedLrNumber);
    this.router.navigate(["/lr/enquiry/lrEnquiry"]);
  }

  lrWithNoUnloadingDetailsAlertMsg(data) {
    if (data != null && data != undefined) {
      if (data[0].reason != null) {
        swal({
          title:
            "LR Numbers with No Unloading details filled, please update the Unloading details from the Dashboard",
          text: data[0].reason,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    }
  }

  //ASR_V1_CHG - Starts
  getTruckMasterDetailsSingleEvent(event) {
    if (event == 13) {
      this.getTruckMasterDetailsSingle();
    }
  }

  getTruckMasterDetailsSingle() {
    this.getTruckDetailsForReadSingle();
    this.showSpinnerForActionForInvoiceDetails = true;
    this.masterReadService
      .getTruck(this.truckDataDtoForTruckNo)
      .subscribe((response) => {
        if (response.length == 0) {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          swal("Warning", "No Truck Number records found!", "warning");
          $("#" + this.pageId + "truckNumber").val("");
        } else {
          this.truckNumberOptions = [];
          this.truckNumberTA = [];
          this.truckNumberOptions = response;
          console.log("t1-truckNo : " + response.length);
          for (let i = 0; i < this.truckNumberOptions.length; i++) {
            this.truckNumberTA.push(this.truckNumberOptions[i]);
            this.hashmapTruck.set(
              this.truckNumberOptions[i].truckNumber,
              this.truckNumberOptions[i]
            );
            this.hashmapTruckNumberIndex.set(
              this.truckNumberOptions[i].truckNumber,
              i + 1
            );
            $("#" + this.pageId + "truckNumber").val(
              this.truckNumberOptions[i].truckNumber
            );
            $("#" + this.pageId + "panNumberSearchId").val(
              this.truckNumberOptions[i].tinNo
            );
          }
        }
        this.showSpinnerForActionForInvoiceDetails = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForActionForInvoiceDetails = false;
        swal("Error", "Server Error While Getting Truck Details", "warning");
      },
      () => console.log("done");
  }

  getTruckDetailsForReadSingle() {
    this.vehicleNoSearch = $("#" + this.pageId + "truckNumberSearchId").val();

    this.truckDataDtoForTruckNo = new TruckDataDto();
    this.truckDataDtoForTruckNo.companyId =
      this.userDataDtoReturnSession.companyId;
    this.truckDataDtoForTruckNo.status = "Working";
    this.truckDataDtoForTruckNo.mode = "TruckNo";
    this.truckDataDtoForTruckNo.vehicleName = this.vehicleNoSearch;
  }
  //ASR_V1_CHG - End
  //Imran_Chg_V1 - Start
  // getPanNumberDetailsSingleEvent(event) {
  //   if (event == 13) {
  //     this.validatePanNoField();
  //   }
  // }

  confirmPanAadharLinkVerification(truckMasterPopUp) {
    swal({
      title: "Confirm Verification",
      text: "Sure you want to verify the Pan-Aadhar link?",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((remove) => {
      if (remove) {
        this.validatePanNoField(truckMasterPopUp);
      }
    });
  }

  validatePanNoField(truckMasterPopUp) {
    this.panNumberSearch = $("#" + this.pageId + "panNumberSearchId").val();
    if (!this.panValidation.test(this.panNumberSearch)) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.checkPanNumberExits(truckMasterPopUp);
    }
  }
  getPanNoDetailsDto() {
    this.hireSlipDtoForPan = new HireSlipDto();
    this.selectedTruckNumber = $("#" + this.pageId + "truckNumber").val();
    this.hireSlipDtoForPan.vehicleNumber = this.selectedTruckNumber;
    this.hireSlipDtoForPan.panNo = this.panNumberSearch;
  }
  checkPanNumberExits(truckMasterPopUp) {
    this.getPanNoDetailsDto();
    console.log(this.hireSlipDtoForPan);
    this.hireslipService.checkPanDetailsExists(this.hireSlipDtoForPan).subscribe((response) => {
      console.log(response);
      var hireslipUncleared = "";
      if (response != null) {
        if (response.status != null && response.status == "Exits") {
          console.log('exist');
          //logic to validate only for individual pan i.e., 4th char is 'p'
          const companyType = this.panNumberSearch.charAt(3);
          if (companyType == 'P' || companyType == 'p') {
            this.validatedIfPANAlreadyLinkedAvailable();
          } else {
            swal("OK", "Please Click The Next Button Again To Continue", "info");
            this.isPanNoForTruckAvailable = true;
            this.nextToInvoiceDetailOfDarkBtnHideShow = false;
            this.nextToInvoiceDetailOfGreenBtnHideShow = true;
          }
        } else {
          console.log('not exist');
          swal({
            title: "PAN Not Available",
            text: "Entered PAN is not exist with the selected Vehicle Number, please update it to continue!",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: {
              yes: {
                text: "OK",
                value: true,
                visible: true,
                className: "swal-button--confirm",
                closeModal: true,
              },
            },
          }).then((isConfirm) => {
            if (isConfirm) {
              this.truckMasterInPopUpPage(truckMasterPopUp);
            }
          });
          this.isPanNoForTruckAvailable = false;
          this.panNumberSearch = null;
        }
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Invoice Blocking in Stock Summary",
          "warning"
        );
      },
      () => console.log("done");
  }

  validatedIfPANAlreadyLinkedAvailable() {
    this.hireSlipDtoForPan = new HireSlipDto();
    this.hireSlipDtoForPan.panNo = this.panNumberSearch;

    this.hireslipService.checkPanAlreadyValidated(this.hireSlipDtoForPan).subscribe((response) => {
      if (response.status == null) {
        this.panAadharValidationService();
      } else if (response.status != null && response.status == "Y") {
        this.isPanNoForTruckAvailable = true;
        swal("Valid", "PAN-AADHAR already validated, please click the Next button again to continue", "info");
        this.nextToInvoiceDetailOfDarkBtnHideShow = false;
        this.nextToInvoiceDetailOfGreenBtnHideShow = true;
      } else {
        this.isPanNoForTruckAvailable = false;
        this.nextToInvoiceDetailOfDarkBtnHideShow = true;
        this.nextToInvoiceDetailOfGreenBtnHideShow = false;
        swal("Not Allowed", "This PAN details showing validation failed, please contact Admin if this is valid.", "warning");
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Serve Error", "Failed to validate the request, please try again or contact Admin!", "error");
      },
      () => console.log("done");


  }

  panAadharValidationService() {
    //console.log('inside panAadharValidationService : ' + this.pendingHireslipFromLocalStorage.invoiceNumber);
    // let selectedHireslip = this.pendingHireslipFromLocalStorage.invoiceNumber;
    this.requestDto = new RequestDto();
    this.requestDto.param1 = this.panNumberSearch;
    //Need to check this wheatr hireslip no getting after service call implementaion
    // this.requestDto.param2 = selectedHireslip == null ? null : selectedHireslip.toString();
    this.requestDto.param2 = null;
    this.requestDto.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDto.param5 = this.userDataDtoReturnSession.office;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.requestDto.type = 'Invoice';
    this.showSpinnerForAction = true;
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.validatePanAadharLinkVerification(this.requestDto).subscribe((response) => {
      if (response.token1 != null && response.token1 == "Y") {
        this.isPanNoForTruckAvailable = true;
        swal("Valid", response.token2 + ". Please click the Next button again to continue", "info");
        this.nextToInvoiceDetailOfDarkBtnHideShow = false;
        this.nextToInvoiceDetailOfGreenBtnHideShow = true;

        //this.disableNextButton = false;
        //$("#sgcdriverName").focus();
      } else {
        //this.disableNextButton = true;
        this.isPanNoForTruckAvailable = false;
        this.nextToInvoiceDetailOfDarkBtnHideShow = true;
        this.nextToInvoiceDetailOfGreenBtnHideShow = false;
        // this.panAadharNotLinkedDeduction();
        swal(
          "Invalid",
          "PAN-AADHAR link is not valid, please make sure you have linked this PAN with your Aadhar!",
          "warning"
        );
      }

      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to validate the request, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }
  //Added And   Commented for to getconfirmation for used in this page or not on 04032024
  //   panAadharNotLinkedDeduction() {
  //     this.tdsLabel = "20% TDS Deducted";
  //     console.log(this.localStorageOfPaymentData.advance);
  //     let deductionAmt = +((this.localStorageOfPaymentData.advance * 20) / 100).toFixed(0);
  //     console.log(deductionAmt);
  //     $("#" + this.pageId + "tdsDeducted").val(deductionAmt);
  //     this.localStorageOfPaymentData.tdsAmount = deductionAmt;
  //     this.localStorageOfPaymentData.tdsPercent = '20%';
  //     $("#" + this.pageId + "advancePayable").val((this.localStorageOfPaymentData.advance == null ? 0 : this.localStorageOfPaymentData.advance) + -(this.localStorageOfPaymentData.tdsAmount == null ? 0 : this.localStorageOfPaymentData.tdsAmount));
  //     this.balanceAmt();
  // }
  //End

  //Asrar_Jr_Chg_V2
  getCODLRDetails() {
    this.codLrNumbers = '';
    this.requestDto = new RequestDto();
    this.requestDto.param1 = this.invoiceNumber;
    this.stockService.getCODLRDetailLists(this.requestDto).subscribe(
      (response) => {
        this.codLrDetailsList = response;
        console.log("COD LR Details List");
        console.log(this.codLrDetailsList);
        if (this.codLrDetailsList.length > 0) {

          for (let i = 0; i < this.codLrDetailsList.length; i++) {
            this.codLrNumbers += this.codLrDetailsList[i].token1;
            if (i !== this.codLrDetailsList.length - 1) {
              this.codLrNumbers += ', ';
            }
          }
          console.log("LR Numbers: ", this.codLrNumbers);
        }
      },
      (error) => {
        swal(
          "Server Error",
          "Problem Occurs While getting add Hireslip Details.",
          "error"
        );
      },
    );
  }
}
