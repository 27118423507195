<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
    <div class="form-group">
        <div class="input-group">
            <mat-progress-bar mode="indeterminate" style="color: green;">
            </mat-progress-bar>
            <br>
            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                Please Wait Loading Details.....</h6>
        </div>
    </div>
</div>
<div class="col-md-12 col-sm-12">
    <div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
        <div class="col-md-8">
            <h6 class="card-title">
                <i class=" fas fa-file-alt"></i>&nbsp;Pending Hireslip Generation
            </h6>

        </div>
        <div class="col-md-4">
            <i title="Reload" class="fas fa-sync-alt fa xs cli" style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;" (click)="gridReconifgureOnReloadBtn()"></i>
        </div>
    </div>
</div>
<div class="col-md-12 col-sm-12">
    <div class="box-body">
        <table datatable id="{{pageId}}pendingHireslipGenerationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPendingHireslipGeneration" [dtTrigger]="dtTriggerPendingHireslipGeneration">
            <thead>
                <tr>
                    <th>Invoice Number</th>
                    <th>Destination</th>
                    <th>Created Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pendingHireslipGenerationData of pendingHireslipGenerationDataList ">
                    <td (click)="loadingSheetFlow(pendingHireslipGenerationData);"><u style="cursor: pointer;" class="hyperLink_td">{{
						pendingHireslipGenerationData.invoiceNumber }}</u></td>
                    <td>{{ pendingHireslipGenerationData.toStation }}</td>
                    <td>{{ pendingHireslipGenerationData.enteredDateStr }}</td>
                    <td>
                        &nbsp;&nbsp;<i title="Edit" style="cursor: pointer;" class="fas fa-pencil-alt" (click)="editPendingHireslipDetails(pendingHireslipGenerationData);"></i>
                        <!--&nbsp;&nbsp;&nbsp;
						<i title="Hire Slip" style="cursor: pointer;" class="fas fa-file-alt"
						(click)="createHireslipDetails(pendingHireslipGenerationData);"></i>-->
                    </td>
                    <!--<td>
						<i title="Edit" style="cursor: pointer;" class="fas fa-pencil-alt"></i>
						&nbsp;&nbsp;&nbsp;
						<i title="Hire Slip" style="cursor: pointer;" class=" fas fa-file-alt"></i>
					</td>-->
                </tr>
            </tbody>
        </table>
    </div>
</div>