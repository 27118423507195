<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Delayed E-Sugam</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}source">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Source</option>
															<option value="all">ALL</option>
															<option value="delhi">Delhi</option>
															<option value="mumbai">Mumbai</option>
															<option value="nagpur">Nagpur</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Destination</option>
															<option value="bangalore">Bangalore</option>
															<option value="chennai">Chennai</option>
															<option value="kerela">Kerela</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}consigneeName">
														<label>Consignee Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Consignee Name</option>
															<option value="aaIndustries">AA Industries</option>
															<option value="ayTradeLinks">AY Trade Links</option>
															<option value="asMarketing">AS Marketing</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}diffDays">
														<label>Diff Days</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											
											
											
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsDelayedESugam" [dtTrigger]="dtTriggerDelayedESugam">

								<thead>
									<tr>
										<th>LR Number</th>
										<th>Consignee Name</th>
										<th>Area</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
										<th>Diff Days</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let delayedESugamData of delayedESugamDataList ">
										<td>{{ delayedESugamData.lrNumber }}</td>
										<td>{{ delayedESugamData.consigneeName }}</td>
										<td>{{ delayedESugamData.area }}</td>
										<td>{{ delayedESugamData.actualWeight }}</td>
										<td>{{ delayedESugamData.chargedWeight }}</td>
										<td>{{ delayedESugamData.diffDays }}</td>
										<td>{{ delayedESugamData.status }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>