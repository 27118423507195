<head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showPaymentFollowup">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="card-title text-white">Payment FollowUp</h6>
                        </div>
                    </div>
                </div>
                <div class="row system_responsive" style="margin-bottom: 5px;">
                    <div class="col-md-12">

                        <div class="card-body">
                            <div class="row p-b-10" style="border-bottom: 1px solid #cebd9f;">
                                <div class="col-md-12">
                                    <!-- 								<button mat-raised-button (click)="isLinear = !isLinear" -->
                                    <!-- 									id="{{pageId}}toggle-linear">{{!isLinear ? 'Enable linear mode' -->
                                    <!-- 									: 'Disable linear mode'}}</button> -->
                                    <mat-horizontal-stepper [linear]="false" #stepper>
                                        <!-- 								one starts -->
                                        <mat-step [stepControl]="firstStepGroup">
                                            <form [formGroup]="firstFormGroup">
                                                <ng-template matStepLabel>Payment Followup</ng-template>

                                                <!-- 									<mat-form-field> <input matInput -->
                                                <!-- 										placeholder="Last name, First name" -->
                                                <!-- 										formControlName="firstCtrl" required> </mat-form-field> -->
                                                <!-- 									the payment followup starts -->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <!-- Row -->

                                                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" style="padding-top: 5px; margin-right: 160px;">
                                                                    <!-- 																<mat-spinner></mat-spinner> -->
                                                                    <mat-progress-bar mode="indeterminate" style="color: red;"></mat-progress-bar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                        Please Wait Loading Details.....</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- 												<div *ngIf="showSpinner" -->
                                                        <!-- 													class="col-sm-12 col-md-12"> -->
                                                        <!-- 													<div class="form-group"> -->
                                                        <!-- 														<div class="input-group" --
<!-- 															style="padding-top: 5px; margin-right: 160px;"> -->
                                                        <!-- 																															<mat-spinner></mat-spinner> -->
                                                        <!-- 															<mat-progress-bar mode="indeterminate" -->
                                                        <!-- 																style="color: red;"></mat-progress-bar> -->
                                                        <!-- 														</div> -->
                                                        <!-- 													</div> -->
                                                        <!-- 												</div> -->
                                                        <!-- 												<div *ngIf="showSpinner" -->
                                                        <!-- 													class="col-sm-12 col-md-12"> -->
                                                        <!-- 													<div class="form-group"> -->
                                                        <!-- 														<div class="input-group"> -->
                                                        <!-- 															<h6 class="card-title" align='center' -->
                                                        <!-- 																style="color: green; margin: auto; font-size: 18px;"> -->
                                                        <!-- 																Saving Payment Details Please Wait.....</h6> -->
                                                        <!-- 														</div> -->
                                                        <!-- 													</div> -->
                                                        <!-- 												</div> -->
                                                        <div class="card-body">
                                                            <div class="row" style="border-bottom: 1px solid #cebd9f;">
                                                                <!-- 											the first autocomplete starts -->
                                                                <!-- 														<div class="col-sm-12 col-md-2"> -->
                                                                <!-- 															<div class="control"> -->
                                                                <!-- 																<div class="form-group"> -->
                                                                <!-- 																	<label class="label_custome">Action Type</label> -->
                                                                <!-- 																	<div class="input-group"> -->
                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                <!-- 																				class="fas fa-search"></i> -->
                                                                <!-- 																			</span> -->
                                                                <!-- 																		</div> -->
                                                                <!-- 																		<input #actionType id="{{pageId}}actionType" autocomplete="off" -->
                                                                <!-- 																			class="auto_selectOption input is-medium" -->
                                                                <!-- 																			placeholder="Select Action Type" -->
                                                                <!-- 																			[formControl]="controlActionType" -->
                                                                <!-- 																			[appAutocomplete]="autocompleteActionType"> -->
                                                                <!-- 																	</div> -->
                                                                <!-- 																</div> -->
                                                                <!-- 															</div> -->
                                                                <!-- 															<app-autocomplete -->
                                                                <!-- 																#autocompleteActionType="appAutocomplete"> -->
                                                                <!-- 															<ng-template appAutocompleteContent> <ng-container -->
                                                                <!-- 																*ngIf="(actionTypeOptions | filter: controlActionType.value) as resultActionType"> -->
                                                                <!-- 															<app-option *ngFor="let option of resultActionType" -->
                                                                <!-- 																(click)="actionTypeFocus(option)" [value]="option.label"> -->
                                                                <!-- 															{{ option.label }} </app-option> <app-option class="no-result" -->
                                                                <!-- 																*ngIf="!resultActionType.length">No result</app-option> -->
                                                                <!-- 															</ng-container> </ng-template> </app-autocomplete> -->
                                                                <!-- 														</div> -->
                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="control">
                                                                        <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                                                                            <label class="label_custome">Action
                                                                                Type</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #actionType id="{{pageId}}actionType" name="actionType" type="text" class="form-control" placeholder="Select Action Type" (selectItem)="actionTypeFocus($event)" [ngbTypeahead]="actionTypeSearchTA" (focus)="focusActionTypeTA$.next($any($event).target.value)"
                                                                                    (click)="clickTA(instanceActionType)" #instanceActionType="ngbTypeahead" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- 											the first autocomplete ends -->

                                                                <!-- 											the second autocomplete starts -->
                                                                <!-- 														<div class="col-sm-12 col-md-2"> -->
                                                                <!-- 															<div class="control"> -->
                                                                <!-- 																<div class="form-group"> -->
                                                                <!-- 																	<label class="label_custome">Collection Man</label> -->
                                                                <!-- 																	<div class="input-group"> -->

                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                <!-- 																				class="fas fa-user"></i> -->
                                                                <!-- 																			</span> -->
                                                                <!-- 																		</div> -->
                                                                <!-- 																		<input #collectionMan id="{{pageId}}collectionMan" -->
                                                                <!-- 																			class="auto_selectOption input is-medium" -->
                                                                <!-- 																			placeholder="Select Collection Man" -->
                                                                <!-- 																			[formControl]="controlCollectionMan" -->
                                                                <!-- 																			[appAutocomplete]="autocompleteCollectionMan" -->
                                                                <!-- 																			[(ngModel)]="enteredCollectionMan"> -->
                                                                <!-- 																	</div> -->
                                                                <!-- 																</div> -->
                                                                <!-- 															</div> -->
                                                                <!-- 															<app-autocomplete -->
                                                                <!-- 																#autocompleteCollectionMan="appAutocomplete"> -->
                                                                <!-- 															<ng-template appAutocompleteContent> <ng-container -->
                                                                <!-- 																*ngIf="(collectionManOptions | filterCollectionMan: controlCollectionMan.value) as resultCollectionMan"> -->
                                                                <!-- 															<app-option *ngFor="let option of resultCollectionMan" -->
                                                                <!-- 																(click)="collectionManFocus(option,contentAcknowledgement)" -->
                                                                <!-- 																[value]="option.collectioMan"> {{ -->
                                                                <!-- 															option.collectioMan }} </app-option> <app-option class="no-result" -->
                                                                <!-- 																*ngIf="!resultCollectionMan.length">No -->
                                                                <!-- 															result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                <!-- 														</div> -->
                                                                <!-- 														<div class="col-sm-12 col-md-2"> -->
                                                                <!-- 															<div class="control"> -->
                                                                <!-- 																<div class="form-group" id="{{pageId}}scrollable-dropdown-menu"> -->
                                                                <!-- 																	<div class="input-group"> -->
                                                                <!-- 																		<label>Collection Man</label> -->

                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                <!-- 																				class="ti-home"></i> -->
                                                                <!-- 																			</span> -->
                                                                <!-- 																		</div> -->
                                                                <!-- 																		<input #collectionMan id="{{pageId}}collectionMan" type="text" -->
                                                                <!-- 																			class="form-control" [(ngModel)]="enteredCollectionMan" -->
                                                                <!-- 																			[ngbTypeahead]="collectionManSearchTA" -->
                                                                <!-- 																			(focus)="focusCollectionManTA$.next($any($event).target.value)" -->
                                                                <!-- 																			(click)="clickTA(instanceCollectionMan)" -->
                                                                <!-- 																			(click)="collectionManFocus(option,contentAcknowledgement)" -->
                                                                <!-- 																			#instanceCollectionMan="ngbTypeahead" /> -->
                                                                <!-- 																	</div> -->
                                                                <!-- 																</div> -->
                                                                <!-- 															</div> -->
                                                                <!-- 														</div> -->
                                                                <!-- 														<mat-spinner></mat-spinner> -->
                                                                <!-- 														   <div class="col-md-6"> -->
                                                                <!--         <div class="card"> -->
                                                                <!--             <div class="card-body"> -->

                                                                <!--                 <p><ngb-progressbar type="success" [value]="75"></ngb-progressbar></p> -->
                                                                <!--             </div> -->
                                                                <!--         </div> -->
                                                                <!--     </div> -->
                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="control">
                                                                        <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                                                                            <label class="label_custome">Collection
                                                                                Man</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-user"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #collectionMan id="{{pageId}}collectionMan" name="collectionMan" type="text" class="form-control" (selectItem)="collectionManFocus($event,contentAcknowledgement)" [(ngModel)]="enteredCollectionMan" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="collectionManSearchTA"
                                                                                    (focus)="focusCollectionManTA$.next($any($event).target.value)" (click)="clickTA(instanceCollectionMan)" #instanceCollectionMan="ngbTypeahead" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- 											the second autocomplete ends -->


                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Entry
                                                                                Type</label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="fas fa-search"></i>
                                                                                </span>
                                                                            </div>
                                                                            <select class="custom-select col-12" id="{{pageId}}entryType" name="entryType" #entryType (change)="entryTypeMode(entryType.value)">
                                                                                <option value="manual">Manual</option>
                                                                                <option value="automatic">Automatic
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- 											the third autocomplete starts -->
                                                                <!-- 														<div class="col-sm-12 col-md-2"> -->
                                                                <!-- 															<div class="control"> -->
                                                                <!-- 																<div class="form-group"> -->
                                                                <!-- 																	<label class="label_custome">Station</label> -->
                                                                <!-- 																	<div class="input-group" id="{{pageId}}station"> -->

                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                <!-- 																				class="fas fa-warehouse"></i> -->
                                                                <!-- 																			</span> -->
                                                                <!-- 																		</div> -->
                                                                <!-- 																		<input class="auto_selectOption input is-medium" -->
                                                                <!-- 																			placeholder="Select Station" -->
                                                                <!-- 																			[formControl]="controlStation" -->
                                                                <!-- 																			[appAutocomplete]="autocompleteStation"> -->
                                                                <!-- 																	</div> -->
                                                                <!-- 																</div> -->
                                                                <!-- 															</div> -->
                                                                <!-- 															<app-autocomplete #autocompleteStation="appAutocomplete"> -->
                                                                <!-- 															<ng-template appAutocompleteContent> <ng-container -->
                                                                <!-- 																*ngIf="(stationOptions | filterDest: controlStation.value) as resultStation"> -->
                                                                <!-- 															<app-option *ngFor="let option of resultStation" -->
                                                                <!-- 																(click)="getMemoCode(option,'main')" -->
                                                                <!-- 																[value]="option.destination"> {{ -->
                                                                <!-- 															option.destination }} </app-option> <app-option class="no-result" -->
                                                                <!-- 																*ngIf="!resultStation.length">No result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                <!-- 														</div> -->
                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="control">
                                                                        <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                            <label class="label_custome">Station</label>
                                                                            <div class="input-group">

                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-warehouse"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #station id="{{pageId}}station" name="station" type="text" class="form-control" (selectItem)="getMemoCode($event,'main')" [ngbTypeahead]="stationSearchTA" (focus)="focusStationTA$.next($any($event).target.value)" (click)="clickTA(instanceStation);"
                                                                                    #instanceStation="ngbTypeahead" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!-- 											the third autocomplete ends -->
                                                                <div *ngIf="manualView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group" id="{{pageId}}memoNumber">
                                                                            <label class="label_custome">Memo
                                                                                Number</label>
                                                                            <!-- 																	<div class="input-group-prepend"> -->
                                                                            <!-- 																		<span class="input-group-text"> <i -->
                                                                            <!-- 																			class="fas fa-file-alt"></i> -->
                                                                            <!-- 																		</span> -->
                                                                            <!-- 																	</div> -->
                                                                            <input #codeForManual type="text" id="{{pageId}}codeForManual" class="form-control" aria-describedby="basic-addon11">
                                                                            <input #memoNumberForManual type="text" id="{{pageId}}memoNumberForManual" (keyup)="getMemoNumberForManual($event,'keyEnter')" class="form-control" aria-describedby="basic-addon11">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="automaticView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Memo
                                                                                Number</label>
                                                                            <!-- 																	<div class="input-group-prepend"> -->
                                                                            <!-- 																		<span class="input-group-text"> <i -->
                                                                            <!-- 																			class="fas fa-file-alt"></i> -->
                                                                            <!-- 																		</span> -->
                                                                            <!-- 																	</div> -->
                                                                            <input #memoNumberForAutomatic type="text" id="{{pageId}}memoNumberForAutomatic" (keyup)="getMemoNumberForAutomatic($event,'keyEnter')" class="form-control" aria-describedby="basic-addon11">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <label>Date</label>
                                                                        <div class="input-group">
                                                                            <!-- 																<input class="form-control" placeholder="yyyy-mm-dd" -->
                                                                            <!-- 																	name="dates" ngbDatepicker #dates="ngbDatepicker"> -->
                                                                            <!-- 																<div class="input-group-append" (click)="dates.toggle()"> -->
                                                                            <!-- 																	<span class="input-group-text"> <i -->
                                                                            <!-- 																		class="fa fa-calendar"></i> -->
                                                                            <!-- 																	</span> -->
                                                                            <!-- 																</div> -->
                                                                            <input class="form-control" name="completedOnDate" id="{{pageId}}completedOnDate" [(ngModel)]="tdyCompletedOnDate" [ngModelOptions]="{standalone: true}" ngbDatepicker (click)="completedOnDate.toggle()" #completedOnDate="ngbDatepicker">

                                                                            <div class="input-group-append" (click)="completedOnDate.toggle()">
                                                                                <span class="input-group-text"> <i
                                                                                        class="fa fa-calendar"></i>
                                                                                </span>
                                                                            </div>
                                                                            <!-- 																	<input type="date" id="{{pageId}}completedOnDate" -->
                                                                            <!-- 																		name="completedOnDate" -->
                                                                            <!-- 																		[(ngModel)]="tdyCompletedOnDate" -->
                                                                            <!-- 																		placeholder="dd-mm-yyyy"> -->

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row  p-t-10">
                                                                <div class="col-md-6">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Collection Man Details
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}assingAllBtn" (click)="validateAllBtn();">ALL</button>
                                                                            </div>
                                                                        </div>
                                                                        <!-- 							<the first datatable starts  -->
                                                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPfCollectionManDetails" [dtTrigger]="dtTriggerPfCollectionManDetails">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Cashmemo</th>
                                                                                    <th>Lr Number</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Party Name</th>
                                                                                    <th>Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let pfCollectionManDetailsData of pfCollectionManDetailsDataList ">
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg == false'>
                                                                                        {{ pfCollectionManDetailsData.memoNumber }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg == false'>
                                                                                        {{ pfCollectionManDetailsData.lrNumber }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg == false'>
                                                                                        {{ pfCollectionManDetailsData.amount }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg == false'>
                                                                                        {{ pfCollectionManDetailsData.consigneeName }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg == false'>
                                                                                        {{ pfCollectionManDetailsData.column1 }}
                                                                                    </td>

                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg' bgcolor="lightgreen">{{ pfCollectionManDetailsData.memoNumber }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg' bgcolor="lightgreen">{{ pfCollectionManDetailsData.lrNumber }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg' bgcolor="lightgreen">{{ pfCollectionManDetailsData.amount }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg' bgcolor="lightgreen">{{ pfCollectionManDetailsData.consigneeName }}
                                                                                    </td>
                                                                                    <td *ngIf='pfCollectionManDetailsData.memoDirectAssg' bgcolor="lightgreen">{{ pfCollectionManDetailsData.column1 }}
                                                                                    </td>

                                                                                </tr>
                                                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">Total : {{countOFMemosCollectionTable}}
                                                                                    </td>
                                                                                    <td></td>
                                                                                    <td>{{sumOFMemosCollectionTable}}
                                                                                    </td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <!-- 							the first datatable ends  -->
                                                                <!-- 							<the second datatable starts  -->
                                                                <div class="col-md-6 vl">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Assigned Job Details
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}removeAllBtn" (click)="validateRemoveAllBtn();">Remove
                                                                                    All</button>

                                                                            </div>
                                                                        </div>
                                                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionPfAssignedJobDetails" [dtTrigger]="dtTriggerPfAssignedJobDetails">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Cashmemo</th>
                                                                                    <th>Lr Number</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Party Name</th>
                                                                                    <th>Status</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!-- class='dragndrop' [dragula]='"first-bag"'-->
                                                                                <tr *ngFor="let pfAssignedJobDetailsData of pfAssignedJobDetailsDataList let i = index ">
                                                                                    <td>{{ pfAssignedJobDetailsData.memoNumber }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.amount }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.consigneeName }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.column1 }}
                                                                                    </td>
                                                                                    <td><button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForAssignedTable(pfAssignedJobDetailsData,i);">
                                                                                            <i title="Remove"
                                                                                                class="fas fa-trash"></i>
                                                                                        </button></td>
                                                                                </tr>
                                                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">
                                                                                        {{countOfAssignLR}}</td>
                                                                                    <td></td>
                                                                                    <td>{{sumOfAssignLR}}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <!--							 the second datatable ends  -->
                                                            </div>

                                                            <!--  to open  popup use this 1. (click)="openNotAssignedPopup(contentNotAssigned)" 2. (click) ="openAssignedPopup(contentAssigned)"  3. (click)="openPopup(content)"-->
                                                            <!-- Row -->
                                                            <!-- 												<div class="col-md-12"> -->
                                                            <!-- 													<div class="form-group"> -->
                                                            <!-- 														<div class="input-group" id="{{pageId}}popupDetailsNotAssigned"> -->
                                                            <!-- 															<ng-template #contentNotAssigned let-ok="close"> -->

                                                            <!-- 															<div class="modal-body"> -->
                                                            <!-- 																<div class="row"> -->
                                                            <!-- 																	<div class="col-sm-12 col-md-12"> -->
                                                            <!-- 																		<h5>Empty Datas</h5> -->

                                                            <!-- 																	</div> -->
                                                            <!-- 																	<div style="text-align: center;" -->
                                                            <!-- 																		class="col-sm-12 col-md-12 p-t-10"> -->
                                                            <!-- 																		<h6> -->
                                                            <!-- 																			<i class="fas fa-exclamation-triangle" -->
                                                            <!-- 																				style="font-size: 20px"> No Cashmemo Found in -->
                                                            <!-- 																				Assigned Job!</i> -->
                                                            <!-- 																		</h6> -->
                                                            <!-- 																	</div> -->
                                                            <!-- 																	<div style="text-align: center;" -->
                                                            <!-- 																		class="col-md-12 p-t-10"> -->
                                                            <!-- 																		<button type="button" class="btn btn-outline-danger" -->
                                                            <!-- 																			(click)="ok('Ok click')">Ok</button> -->
                                                            <!-- 																	</div> -->
                                                            <!-- 																</div> -->
                                                            <!-- 															</div> -->
                                                            <!-- 															</ng-template> -->
                                                            <!-- 														</div> -->
                                                            <!-- 													</div> -->
                                                            <!-- 												</div> -->
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetails">

                                                                        <ng-template #content10 let-c="close" let-d="dismiss">
                                                                            <div class="modal-header">
                                                                                <h6 class="modal-title" id="{{pageId}}modal-basic-title">
                                                                                    Select Collection Man To Assign</h6>
                                                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <!-- 																			<div class="col-sm-12 col-md-8"> -->
                                                                                        <!-- 																				<div class="control"> -->
                                                                                        <!-- 																					<div class="form-group"> -->
                                                                                        <!-- 																						<div class="input-group"> -->
                                                                                        <!-- 																							<label>Assign To :</label> -->
                                                                                        <!-- 																							<div class="input-group-prepend"> -->
                                                                                        <!-- 																								<span class="input-group-text"> <i -->
                                                                                        <!-- 																									class="fas fa-map-marker-alt"></i> -->
                                                                                        <!-- 																								</span> -->
                                                                                        <!-- 																							</div> -->
                                                                                        <!-- 																							<input #collectionManAssign -->
                                                                                        <!-- 																								id="{{pageId}}collectionManAssign" -->
                                                                                        <!-- 																								class="auto_selectOption input is-medium" -->
                                                                                        <!-- 																								placeholder="Select Collection Man" -->
                                                                                        <!-- 																								[formControl]="controlCollectionManAssign" -->
                                                                                        <!-- 																								[appAutocomplete]="autocompleteCollectionManAssign" -->
                                                                                        <!-- 																								[(ngModel)]="enteredCollectionManAssign"> -->
                                                                                        <!-- 																						</div> -->
                                                                                        <!-- 																					</div> -->
                                                                                        <!-- 																				</div> -->

                                                                                        <!-- 																				<app-autocomplete -->
                                                                                        <!-- 																					#autocompleteCollectionManAssign="appAutocomplete"> -->
                                                                                        <!-- 																				<ng-template appAutocompleteContent> <ng-container -->
                                                                                        <!-- 																					*ngIf="(collectionManOptionsAssign | filterCollectionMan: controlCollectionManAssign.value) as resultCollectionManAssign"> -->
                                                                                        <!-- 																				<app-option -->
                                                                                        <!-- 																					*ngFor="let option of resultCollectionManAssign" -->
                                                                                        <!-- 																					[value]="option.collectioMan"> {{ -->
                                                                                        <!-- 																				option.collectioMan }} </app-option> <app-option -->
                                                                                        <!-- 																					class="no-result" -->
                                                                                        <!-- 																					*ngIf="!resultCollectionManAssign.length">No -->
                                                                                        <!-- 																				result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                                        <!-- 																			</div> -->
                                                                                        <div class="col-sm-12 col-md-8">
                                                                                            <div class="control">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Assign To
                                                                                                            :</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-map-marker-alt"></i>
                                                                                                            </span>
                                                                                                        </div>


                                                                                                        <input #collectionManAssign id="{{pageId}}collectionManAssign" type="text" class="form-control" placeholder="Select Collection Man" [ngbTypeahead]="collectionManAssignSearchTA" (focus)="focusCollectionManAssignTA$.next($any($event).target.value)" (click)="clickTA(instanceCollectionManAssign)"
                                                                                                            [(ngModel)]="enteredCollectionManAssign" [ngModelOptions]="{standalone: true}" #instanceCollectionManAssign="ngbTypeahead" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}closePopupBtn" (click)="d('Cross click')">Cancel</button>
                                                                                        </div>

                                                                                        <div #newConsigneeSaveBtn class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}newCollectionManAssignBtn" (click)="validateCollectionManAssign();d('Cross click')">Assign</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetails">

                                                                        <ng-template #content11 let-c="close" let-d="dismiss">
                                                                            <div class="modal-header">
                                                                                <h6 class="modal-title" id="{{pageId}}modal-basic-title">
                                                                                    Select To-Party
                                                                                </h6>
                                                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <!-- 																			<div class="col-sm-12 col-md-8"> -->
                                                                                        <!-- 																				<div class="control"> -->
                                                                                        <!-- 																					<div class="form-group"> -->
                                                                                        <!-- 																						<div class="input-group"> -->
                                                                                        <!-- 																							<label>Assign To :</label> -->
                                                                                        <!-- 																							<div class="input-group-prepend"> -->
                                                                                        <!-- 																								<span class="input-group-text"> <i -->
                                                                                        <!-- 																									class="fas fa-map-marker-alt"></i> -->
                                                                                        <!-- 																								</span> -->
                                                                                        <!-- 																							</div> -->
                                                                                        <!-- 																							<input #collectionManToParty -->
                                                                                        <!-- 																								id="{{pageId}}collectionManToParty" -->
                                                                                        <!-- 																								class="auto_selectOption input is-medium" -->
                                                                                        <!-- 																								placeholder="Select Collection Man" -->
                                                                                        <!-- 																								[formControl]="controlCollectionManToParty" -->
                                                                                        <!-- 																								[appAutocomplete]="autocompleteCollectionManToParty" -->
                                                                                        <!-- 																								[(ngModel)]="enteredCollectionManToParty"> -->
                                                                                        <!-- 																						</div> -->
                                                                                        <!-- 																					</div> -->
                                                                                        <!-- 																				</div> -->

                                                                                        <!-- 																				<app-autocomplete -->
                                                                                        <!-- 																					#autocompleteCollectionManToParty="appAutocomplete"> -->
                                                                                        <!-- 																				<ng-template appAutocompleteContent> <ng-container -->
                                                                                        <!-- 																					*ngIf="(collectionManOptionsToParty | filterCollectionMan: controlCollectionManToParty.value) as resultCollectionManToParty"> -->
                                                                                        <!-- 																				<app-option -->
                                                                                        <!-- 																					*ngFor="let option of resultCollectionManToParty" -->
                                                                                        <!-- 																					[value]="option.collectioMan"> {{ -->
                                                                                        <!-- 																				option.collectioMan }} </app-option> <app-option -->
                                                                                        <!-- 																					class="no-result" -->
                                                                                        <!-- 																					*ngIf="!resultCollectionManToParty.length">No -->
                                                                                        <!-- 																				result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                                        <!-- 																			</div> -->
                                                                                        <div class="col-sm-12 col-md-8">
                                                                                            <div class="control">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Assign To
                                                                                                            :</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-map-marker-alt"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <input #collectionManToParty id="{{pageId}}collectionManToParty" type="text" class="form-control" placeholder="Select Collection Man" [ngbTypeahead]="collectionManToPartySearchTA" (focus)="focusCollectionManToPartyTA$.next($any($event).target.value)"
                                                                                                            (click)="clickTA(instanceCollectionManToParty)" [(ngModel)]="enteredCollectionManToParty" [ngModelOptions]="{standalone: true}" #instanceCollectionManToParty="ngbTypeahead"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>

                                                                                        <div class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}closePopupBtnToParty" (click)="d('Cross click')">Cancel</button>
                                                                                        </div>

                                                                                        <div #newConsigneeSaveBtn class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}newCollectionManToPartyBtn" (click)="validateCollectionManToParty();d('Cross click')">OK</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetailsAssigned">
                                                                        <ng-template #contentAssigned let-yes="close" let-no="close">

                                                                            <div class="modal-body">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <h5>Assigning Memos</h5>
                                                                                    </div>
                                                                                    <div style="text-align: center;" class="col-sm-12 col-md-12 p-t-10">
                                                                                        <h6>
                                                                                            <i class="fas fa-question" style="font-size: 20px">
                                                                                                Sure You Want to Assign
                                                                                                the selected Memos to
                                                                                                <span>ANZAR</span>
                                                                                            </i>
                                                                                        </h6>
                                                                                    </div>
                                                                                    <div style="text-align: center;" class="col-md-12 p-t-10">
                                                                                        <button type="button" class="btn btn-outline-secondary" (click)="yes('Yes click')">Yes</button>
                                                                                        <button type="button" class="btn btn-outline-secondary" (click)="no('No click')">No</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetails">
                                                                        <ng-template #content let-cancel="close" let-d="dismiss">


                                                                            <div class="modal-header">
                                                                                <h5>Select Collection Man to Assign</h5>
                                                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>

                                                                            <div class="modal-body">
                                                                                <div class="row" style="text-align: center;">
                                                                                    <!-- 											the fourth autocomplete starts -->
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="control">
                                                                                            <div class="form-group">

                                                                                                <div class="input-group" id="{{pageId}}collectionMan">
                                                                                                    <label>Assign
                                                                                                        To</label>
                                                                                                    <div class="input-group-prepend">
                                                                                                        <span class="input-group-text">
                                                                                                            <i
                                                                                                                class="fas fa-user"></i>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <input class="auto_selectOption input is-medium" placeholder="Select Collection Man" [formControl]="controlCollectionMan" [appAutocomplete]="autocompleteCollectionMan">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <app-autocomplete #autocompleteCollectionMan="appAutocomplete">
                                                                                            <ng-template appAutocompleteContent>
                                                                                                <ng-container *ngIf="(collectionManOptions | filter: controlCollectionMan.value) as resultCollectionMan">
                                                                                                    <app-option *ngFor="let option of resultCollectionMan" [value]="option.label">
                                                                                                        {{ option.label }} </app-option>
                                                                                                    <app-option class="no-result" *ngIf="!resultCollectionMan.length">
                                                                                                        No result
                                                                                                    </app-option>
                                                                                                </ng-container>
                                                                                            </ng-template>
                                                                                        </app-autocomplete>
                                                                                    </div>
                                                                                    <!-- 											the fourth autocomplete ends -->

                                                                                    <div class="col-md-12 p-t-10">
                                                                                        <button type="button" class="btn btn-outline-secondary" (click)="openAssignedPopup(contentAssigned)" disabled>Assign</button>
                                                                                        <button type="button" class="btn btn-outline-danger" (click)="cancel('Cancel click')">Cancel</button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetailsAcknowledgement">
                                                                        <ng-template #contentAcknowledgement let-ack="close" let-status="close" let-cancel="close" let-d="dismiss">


                                                                            <div class="modal-header">
                                                                                <h5>Validate To-Party Statement</h5>
                                                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>

                                                                            <div class="modal-body">
                                                                                <div class="row" style="text-align: center;">
                                                                                    <h5>
                                                                                        Collection Man
                                                                                        <span>{{collectionManTP}}</span> have
                                                                                        <span>{{countTp}}</span> left to party statements pending for acknowledgement. Please clear these to proceed with the other process, else Contact Admin.
                                                                                    </h5>

                                                                                    <div class="col-md-12 p-t-10">
                                                                                        <!-- <button type="button" class="btn btn-outline-secondary" id="{{pageId}}acknowledgeNow" disabled>Acknowledge
																							Now</button>
                                                                                        <button type="button" class="btn btn-outline-secondary" id="{{pageId}}statusBackBtn" disabled>Status
																							Back</button>
                                                                                        <button type="button" class="btn btn-outline-dark" (click)="cancelTP();d('Cross click')">Cancel</button> -->

                                                                                        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')">OK</button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}popupDetailsCollectionMan">

                                                                        <ng-template #popupCollectionManAssign let-c="close" let-d="dismiss">
                                                                            <div class="modal-header">
                                                                                <h6 class="modal-title" id="{{pageId}}modal-basic-title">
                                                                                    Select Collection Man To Assign</h6>
                                                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <!-- 																			<div class="col-sm-12 col-md-8"> -->
                                                                                        <!-- 																				<div class="control"> -->
                                                                                        <!-- 																					<div class="form-group"> -->
                                                                                        <!-- 																						<div class="input-group"> -->
                                                                                        <!-- 																							<label>Assign To :</label> -->
                                                                                        <!-- 																							<div class="input-group-prepend"> -->
                                                                                        <!-- 																								<span class="input-group-text"> <i -->
                                                                                        <!-- 																									class="fas fa-map-marker-alt"></i> -->
                                                                                        <!-- 																								</span> -->
                                                                                        <!-- 																							</div> -->
                                                                                        <!-- 																							<input #collectionManAssign -->
                                                                                        <!-- 																								id="{{pageId}}collectionManAssign" -->
                                                                                        <!-- 																								class="auto_selectOption input is-medium" -->
                                                                                        <!-- 																								placeholder="Select Collection Man" -->
                                                                                        <!-- 																								[formControl]="controlCollectionManAssign" -->
                                                                                        <!-- 																								[appAutocomplete]="autocompleteCollectionManAssign" -->
                                                                                        <!-- 																								[(ngModel)]="enteredCollectionManAssign"> -->
                                                                                        <!-- 																						</div> -->
                                                                                        <!-- 																					</div> -->
                                                                                        <!-- 																				</div> -->

                                                                                        <!-- 																				<app-autocomplete -->
                                                                                        <!-- 																					#autocompleteCollectionManAssign="appAutocomplete"> -->
                                                                                        <!-- 																				<ng-template appAutocompleteContent> <ng-container -->
                                                                                        <!-- 																					*ngIf="(collectionManOptionsAssign | filterCollectionMan: controlCollectionManAssign.value) as resultCollectionManAssign"> -->
                                                                                        <!-- 																				<app-option -->
                                                                                        <!-- 																					*ngFor="let option of resultCollectionManAssign" -->
                                                                                        <!-- 																					[value]="option.collectioMan"> {{ -->
                                                                                        <!-- 																				option.collectioMan }} </app-option> <app-option -->
                                                                                        <!-- 																					class="no-result" -->
                                                                                        <!-- 																					*ngIf="!resultCollectionManAssign.length">No -->
                                                                                        <!-- 																				result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                                        <!-- 																			</div> -->
                                                                                        <div class="col-sm-12 col-md-8">
                                                                                            <div class="control">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Assign To
                                                                                                            :</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-map-marker-alt"></i>
                                                                                                            </span>
                                                                                                        </div>

                                                                                                        <!-- (click)="clickTA(collectionManStatusBackAssign)" -->
                                                                                                        <input #collectionManStatusBackAssign id="{{pageId}}collectionManStatusBackAssign" type="text" class="form-control" placeholder="Select Collection Man" [ngbTypeahead]="collectionManStatusBackAssignSearchTA" (focus)="focusCollectionManStatusBackAssignTA$.next($any($event).target.value)"
                                                                                                            [(ngModel)]="modelCollectionManStatusBackAssign" [ngModelOptions]="{standalone: true}" #collectionManStatusBackAssign="ngbTypeahead" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}closePopupBtn" (click)="d('Cross click')">Cancel</button>
                                                                                        </div>

                                                                                        <div #newConsigneeSaveBtn class="col-sm-12 col-md-12">
                                                                                            <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}newCollectionManAssignBtn" (click)="validateCollectionManStatusBackAssign();">Assign</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 									the payment followup ends -->
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <button *ngIf="viewFinishBtn" type="button" class="btn btn-dark m-r-10" (click)="collectionManFinish(content10,content11, popupCollectionManAssign)" id="{{pageId}}finishBtn">Finish</button>
                                                        <button *ngIf="showNextBtnInAssign" type="button" class="btn btn-dark m-r-10" id="{{pageId}}nextBtnInAssign" (click)="validateNextBtnInAssign()">Next</button>
                                                        <!-- 											<button class="btn btn-info m-r-10" -->
                                                        <!-- 												id="{{pageId}}resetInStockGroupingBtn" mat-button matStepperPrevious>Reset</button> -->
                                                        <button *ngIf="viewShowNexInAssingBtn2" id="{{pageId}}nextBtnAssign" class="btn btn-success m-r-10" id="{{pageId}}nextBtn" mat-button (click)="showTheConsigneeWiseDetails()" matStepperNext>Next</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </mat-step>
                                        <!-- 								one flow is ends -->
                                        <!-- 								 						 two flow is starts  -->
                                        <!-- 								the less entry starts -->
                                        <mat-step [stepControl]="secondStepGroup">
                                            <form [formGroup]="secondFormGroup">
                                                <ng-template matStepLabel>Less Entry</ng-template>
                                                <!-- 									<mat-form-field> <input matInput -->
                                                <!-- 										placeholder="Address" formControlName="secondCtrl" required> -->
                                                <!-- 									</mat-form-field> -->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <!-- Row -->


                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <!-- 																										the fifth autocomplete starts -->
                                                                    <!-- 																														<div class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																															<div class="control"> -->
                                                                    <!-- 																																<div class="form-group"> -->
                                                                    <!-- 																																	<div class="input-group"> -->
                                                                    <!-- 															<!-- 																			<label>Consignee Name</label> -->
                                                                    <!-- 															-->
                                                                    <!-- 															<!-- 																			<div class="input-group-prepend"> -->
                                                                    <!-- 															-->
                                                                    <!-- 															<!-- 																				<span class="input-group-text"> <i -->
                                                                    <!-- 															-->
                                                                    <!-- 															<!-- 																					class="fas fa-user"></i> -->
                                                                    <!-- 															-->
                                                                    <!-- 															<!-- 																				</span> -->
                                                                    <!-- 															-->
                                                                    <!-- 															<!-- 																			</div> -->
                                                                    <!-- 															-->
                                                                    <!-- 																																		<input #consigneeName -->
                                                                    <!-- 																																			class="auto_selectOption_consigneeName input is-medium" -->
                                                                    <!-- 																																			placeholder="Select Consignee Name" -->
                                                                    <!-- 																																			id="{{pageId}}consigneeName" name="consigneeName" -->
                                                                    <!-- 																																			[formControl]="controlConsigneeName" -->
                                                                    <!-- 																																			[appAutocomplete]="autocompleteConsigneeName"> -->
                                                                    <!-- 																																		<div class="input-group-postpend"> -->
                                                                    <!-- 																																			<span style="background-color: orange; color: #fff" -->
                                                                    <!-- 																																				class="input-group-text"> <i -->
                                                                    <!-- 																																				class="fas fa-undo" title="Relaod Consignee Name" -->
                                                                    <!-- 																																				style="cursor: pointer;" -->
                                                                    <!-- 																																				(click)="reloadConsignee()"></i> -->
                                                                    <!-- 																																			</span> -->
                                                                    <!-- 																																		</div> -->
                                                                    <!-- 																																	</div> -->
                                                                    <!-- 																																</div> -->
                                                                    <!-- 																															</div> -->
                                                                    <!-- 																															<app-autocomplete -->
                                                                    <!-- 																																#autocompleteConsigneeName="appAutocomplete"> -->
                                                                    <!-- 																															<ng-template appAutocompleteContent> <ng-container -->
                                                                    <!-- 																																*ngIf="(consigneeNameOptions | filterConsigneeName: controlConsigneeName.value) as resultConsigneeName"> -->
                                                                    <!-- 																															<app-option *ngFor="let option of resultConsigneeName" -->
                                                                    <!-- 																																(click)="rowSelectedConsignee(option)" -->
                                                                    <!-- 																																[value]="option.consigneeName"> {{ -->
                                                                    <!-- 																															option.consigneeName }} </app-option> <app-option class="no-result" -->
                                                                    <!-- 																																*ngIf="!resultConsigneeName.length">No -->
                                                                    <!-- 																															result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                    <!-- 																														</div> -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                                <div class="input-group">
                                                                                    <!-- 																			<input #consigneeName id="{{pageId}}consigneeName" -->
                                                                                    <!-- 																				name="consigneeName" type="text" -->
                                                                                    <!-- 																				class="form-control" -->
                                                                                    <!-- 																				placeholder="Select Consignee Name" -->
                                                                                    <!-- 																				[ngbTypeahead]="consigneeNameSearchTA" -->
                                                                                    <!-- 																				(focus)="focusConsigneeNameTA$.next($any($event).target.value)" -->
                                                                                    <!-- 																				(click)="clickTA(instanceConsigneeName)" -->
                                                                                    <!-- 																				(click)="rowSelectedConsignee(option)" -->
                                                                                    <!-- 																				#instanceConsigneeName="ngbTypeahead" /> -->
                                                                                    <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="rowSelectedConsignee($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                                                        [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)"
                                                                                        [ngModelOptions]="{standalone: true}" /><span style="background-color: orange; color: #fff"
                                                                                        class="input-group-text"> <i
                                                                                            class="fas fa-undo"
                                                                                            title="Relaod Consignee Name"
                                                                                            style="cursor: pointer;"
                                                                                            (click)="reloadConsignee()"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the fifth autocomplete ends -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Total Less</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-rupee-sign"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #totalLess type="number" id="{{pageId}}totalLess" name="totalLess" class="form-control" aria-describedby="basic-addon11" (keyup)="validateTotalLess($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 															<div class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="form-group"> -->
                                                                    <!-- 																	<div class="custom-control custom-checkbox"> -->
                                                                    <!-- 																		<input #tdsBulkEntry type="checkbox" class="custom-control-input" -->
                                                                    <!-- 																			id="{{pageId}}tdsBulkEntry" name="tdsBulkEntry" [(ngModel)]="viewByTdsBulkEntry"> -->
                                                                    <!-- 																		<label class=" custom-control-label wtfull " -->
                                                                    <!-- 																			for="tdsBulkEntry" (click)="openLessEntryTdsPopup(contentLessEntryTds)">TDS Bulk Entry</label> -->
                                                                    <!-- 																																				<a -->
                                                                    <!-- 																																				(click)="openLessEntryTdsPopup(contentLessEntryTds)">check -->
                                                                    <!-- 																																				1</a> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->
                                                                    <!-- 															</div> -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group" style="margin-bottom: 0px !important">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxTDS" #checkboxTDS (change)="toggleEditableForTDS($event, contentLessEntryTds)">
                                                                                <label class=" custom-control-label wtfull" for="{{pageId}}checkboxTDS">TDS Bulk
                                                                                    Entry</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}statusBack" #statusBack [(ngModel)]="viewByStatusBack" (change)="toggleEditableForStatuBack($event, contentLessEntryStatusBack)">
                                                                                <label class=" custom-control-label wtfull " for="{{pageId}}statusBack">Status
                                                                                    Back</label>
                                                                                <!-- 																		<a -->
                                                                                <!-- 																		(click)="openLessEntryStatusBackPopup(contentLessEntryStatusBack)">check -->
                                                                                <!-- 																		2</a> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- the popup modal for less entry starts -->
                                                                    <!--  to open  popup use this 4. (click)="openLessEntryTdsPopup(contentLessEntryTds)" 5. (click)="openLessEntryStatusBackPopup(contentLessEntryStatusBack)" -->
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group" id="{{pageId}}popupDetailsLessEntryTds">
                                                                                <ng-template #contentLessEntryTds let-ok="close" let-d="dismiss">
                                                                                    <div class="modal-header">
                                                                                        <h5>TDS Bulk Entry</h5>
                                                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                                            <span
                                                                                                aria-hidden="true">&times;</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                        <div class="row" style="">
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Total
                                                                                                            Memo</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}totalMemoForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Memo
                                                                                                            Amount</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}memoAmountForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Less</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-minus"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}lessForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>TDS
                                                                                                            Less(1.5%)</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
                                                                                                                <i
                                                                                                                    class="fas fa-percent"></i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}tdsLessTwoPer" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-md-12 p-t-10" style="text-align: right;">
                                                                                                <button type="button" class="btn btn-outline-secondary" (click)="tdsInsert()">Apply
                                                                                                    TDS Bulk
                                                                                                    Discount</button>
                                                                                                <button type="button" class="btn btn-outline-secondary" (click)="ok('Cancel click'); uncheck();">Close</button>


                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </div>
                                                                        </div>
                                                                    </div>







                                                                    <!-- the popup modal for less entry ends -->
                                                                </div>
                                                                <div class="col-md-3 vl">
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Entry Type</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <select class="custom-select col-12" id="{{pageId}}entryLessEntryType" name="entryLessEntryType" #entryLessEntryType (change)="entryLessEntryTypeMode(entryLessEntryType.value)">
                                                                                    <option value="entryLessManual">
                                                                                        Manual</option>
                                                                                    <option value="entryLessAutomatic">
                                                                                        Automatic</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the sixth autocomplete starts -->
                                                                    <!-- 															<div class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="control"> -->
                                                                    <!-- 																	<div class="form-group"> -->
                                                                    <!-- 																		<div class="input-group"> -->
                                                                    <!-- 																			<label>Station</label> -->
                                                                    <!-- 																			<div class="input-group-prepend"> -->
                                                                    <!-- 																				<span class="input-group-text"> <i -->
                                                                    <!-- 																					class="fas fa-warehouse"></i> -->
                                                                    <!-- 																				</span> -->
                                                                    <!-- 																			</div> -->
                                                                    <!-- 																			<input #entryLessStation -->
                                                                    <!-- 																				class="auto_selectOption input is-medium" -->
                                                                    <!-- 																				placeholder="Select Station" id="{{pageId}}entryLessStation" -->
                                                                    <!-- 																				[formControl]="controlStationEntry" -->
                                                                    <!-- 																				[appAutocomplete]="autocompleteStationEntry"> -->
                                                                    <!-- 																		</div> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->
                                                                    <!-- 																<app-autocomplete -->
                                                                    <!-- 																	#autocompleteStationEntry="appAutocomplete"> -->
                                                                    <!-- 																<ng-template appAutocompleteContent> <ng-container -->
                                                                    <!-- 																	*ngIf="(entryLessStationOptions | filterDest: controlStationEntry.value) as resultStationEntry"> -->
                                                                    <!-- 																<app-option *ngFor="let option of resultStationEntry" -->
                                                                    <!-- 																	(click)="getMemoCode(option,'entry')" -->
                                                                    <!-- 																	[value]="option.destination"> {{ -->
                                                                    <!-- 																option.destination }} </app-option> <app-option class="no-result" -->
                                                                    <!-- 																	*ngIf="!resultStationEntry.length">No -->
                                                                    <!-- 																result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                    <!-- 															</div> -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Station</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-warehouse"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input #entryLessStation id="{{pageId}}entryLessStation" type="text" placeholder="Select Station" class="form-control" (selectItem)="getMemoCode($event,'entry')" [(ngModel)]="modelStationForLess" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="entryLessStationSearchTA"
                                                                                        (focus)="focusEntryLessStationTA$.next($any($event).target.value)" (click)="clickTA(instanceEntryLessStation)" #instanceEntryLessStation="ngbTypeahead" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the sixth autocomplete ends -->
                                                                    <div *ngIf="entryLessManualView" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group" id="{{pageId}}entryLessMemoNumber">
                                                                                <label>Memo Number</label>
                                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                                <!-- 																				class="fas fa-file-alt"></i> -->
                                                                                <!-- 																			</span> -->
                                                                                <!-- 																		</div> -->
                                                                                <input type="text" #codeForManualLess type="text" id="{{pageId}}codeForManualLess" name="codeForManualLess" class="form-control" [(ngModel)]="modelStationForLessAmt" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11">
                                                                                <input #memoNumberForManualLess type="text" id="{{pageId}}memoNumberForManualLess" name="memoNumberForManualLess" type="text" class="form-control" (keyup)="getMemoNumberForEntryLess($event,'manual')" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="entryLessAutomaticView" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Memo Number</label>
                                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                                <!-- 																				class="fas fa-file-alt"></i> -->
                                                                                <!-- 																			</span> -->
                                                                                <!-- 																		</div> -->
                                                                                <input #memoNumberForAutomaticLess type="text" id="{{pageId}}memoNumberForAutomaticLess" name="memoNumberForAutomaticLess" (keyup)="getMemoNumberForEntryLess($event,'scan')" class="form-control" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- 											the sixth autocomplete starts -->
                                                                    <!-- <div class="col-sm-12 col-md-12">
																<div class="control">
																	<div class="form-group">
																		<div class="input-group">
																			<label>Less Type</label>
																			<div class="input-group-prepend">
																				<span class="input-group-text"> <i
																					class="fas fa-minus"></i>
																				</span>
																			</div>
																			<input #lessType
																				class="auto_selectOption input is-medium"
																				placeholder="Select Less Type" id="{{pageId}}lessType"
																				name="lessType" [formControl]="controlEntryLessType"
																				[appAutocomplete]="autocompleteEntryLessType">
																		</div>
																	</div>
																</div>
																<app-autocomplete
																	#autocompleteEntryLessType="appAutocomplete">
																<ng-template appAutocompleteContent> <ng-container
																	*ngIf="(entryLessTypeOptions | filter: controlEntryLessType.value) as resultEntryLessType">
																<app-option *ngFor="let option of resultEntryLessType"
																	(click)="rowSelectedLessType();" [value]="option.label">
																{{ option.label }} </app-option> <app-option class="no-result"
																	*ngIf="!resultEntryLessType.length">No
																result</app-option> </ng-container> </ng-template> </app-autocomplete>
															</div> -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                                                                                <div class="input-group">
                                                                                    <label>Less Type</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input #lessType id="{{pageId}}lessType" name="lessType" type="text" class="form-control" placeholder="Select Less Type" [(ngModel)]="modelLessType" [ngModelOptions]="{standalone: true}" (selectItem)="lessTypeFocus($event)" [ngbTypeahead]="lessTypeSearchTA"
                                                                                        (focus)="focusLessTypeTA$.next($any($event).target.value)" (click)="clickTA(instanceLessType)" #instanceLessType="ngbTypeahead" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the sixth autocomplete ends -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Amount</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-rupee-sign"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #entryLessAmount type="number" id="{{pageId}}entryLessAmount" name="entryLessAmount" class="form-control" (keyup)="validateLessAmount($event)" aria-describedby="basic-addon11">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12" style='display: none;'>
                                                                        <div class="form-group">
                                                                            <div class="input-group">

                                                                                <input type="number" id="{{pageId}}hiddenIndexForLessEntry" class="form-control" aria-describedby="basic-addon11">&nbsp;

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 vl">
                                                                    <div class="box-body">

                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Less Entries</h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <!-- 																		<button class="btn btn-danger m-r-10" -->
                                                                                <!-- 																			style="padding: 1px 4px;" id="{{pageId}}lessEntryRemoveBtn">Remove -->
                                                                                <!-- 																		</button> -->
                                                                            </div>
                                                                        </div>



                                                                        <!-- 							<the third datatable starts  -->
                                                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLessEntriesDataTable" [dtTrigger]="dtTriggerLessEntriesDataTable">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Memo Number</th>
                                                                                    <th>Memo Amount</th>
                                                                                    <th>Less Amount</th>
                                                                                    <th>Less Description</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let lessEntriesDataTableData of lessEntriesDataTableDataList; let i = index">
                                                                                    <!-- 																			<td>{{ lessEntriesDataTableData.memoNumber }}</td> -->
                                                                                    <!-- 																			<td>{{ lessEntriesDataTableData.memoAmount }}</td> -->
                                                                                    <!-- 																			<td>{{ lessEntriesDataTableData.lessAmount }}</td> -->
                                                                                    <!-- 																			<td>{{ lessEntriesDataTableData.lessDescription -->
                                                                                    <!-- 																				}}</td> -->
                                                                                    <td>{{ lessEntriesDataTableData.chqType }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.cashMemoAmt }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.chqAmount }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.chqNumber }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditForLessEntry(lessEntriesDataTableData,i);">
                                                                                            <i title="Edit"
                                                                                                class="fas fa-pencil-alt"></i>
                                                                                        </button>&nbsp;
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForLessEntry(lessEntriesDataTableData,i)">
                                                                                            <i title="Remove"
                                                                                                class="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">
                                                                                        {{countOfLessLR}}</td>
                                                                                    <td></td>
                                                                                    <td>{{sumOfLessAmt}}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                        <!-- 							<the third datatable ends  -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <!-- 											matStepperPrevious -->
                                                        <!-- 												<button type="button" class="btn btn-dark m-r-10" -->
                                                        <!-- 													id="{{pageId}}entryLessFinishBtn" mat-button>Finish</button> -->
                                                        <button *ngIf="!viewNextBtn" type="button" class="btn btn-dark m-r-10" id="{{pageId}}entryLessNextBtnCheck" (click)="validateNextBtn($event)">Next</button>
                                                        <button *ngIf="viewNextBtn" class="btn btn-success m-r-10" id="{{pageId}}nextToPaymentEntryBtn" (click)="setPaymentEntry()" mat-button matStepperNext>Next</button>

                                                    </div>


                                                </div>
                                            </form>
                                        </mat-step>
                                        <!-- 								two flow is end -->
                                        <!-- 								three flow is starts -->
                                        <mat-step [stepControl]="thirdStepGroup">
                                            <form [formGroup]="thirdFormGroup">
                                                <ng-template matStepLabel>Payment Entry</ng-template>
                                                <!-- 									<mat-form-field> <input matInput -->
                                                <!-- 										placeholder="Address" formControlName="secondCtrl" required> -->
                                                <!-- 									</mat-form-field> -->
                                                <div class="row">

                                                    <div class="col-md-12">
                                                        <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" style="padding-top: 5px; margin-right: 160px;">
                                                                    <mat-progress-bar mode="indeterminate" style="color: red;"></mat-progress-bar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                        Saving Payment Details Please Wait.....</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Row -->


                                                        <div class="card-body">
                                                            <div class="row p-t-10 p-b-10">
                                                                <div class="col-md-3">

                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Payment Mode</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <select class="custom-select col-12" id="{{pageId}}paymentMode" name="paymentMode" #paymentMode (change)="paymentModeMode(paymentMode.value)">
                                                                                    <option value="select" selected>
                                                                                        Select
                                                                                        Payment Mode</option>
                                                                                    <option value="cheque">Cheque/PDC
                                                                                    </option>
                                                                                    <option value="neft">NEFT/IMPS
                                                                                    </option>
                                                                                    <option value="cash">Cash</option>
                                                                                    <option value="debitacc">Debit
                                                                                        Account</option>
                                                                                    <option value="upi">UPI</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 															<div *ngIf="viewByConsigneeName" -->
                                                                    <!-- 																class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="control"> -->
                                                                    <!-- 																	<div class="form-group"> -->
                                                                    <!-- 																		<div class="input-group"> -->

                                                                    <!-- 																			<input #consigneeNameForPayment -->
                                                                    <!-- 																				class="auto_selectOption_consigneeName input is-medium" -->
                                                                    <!-- 																				placeholder="Select Consignee Name" -->
                                                                    <!-- 																				id="{{pageId}}consigneeNameForPayment" -->
                                                                    <!-- 																				name="consigneeNameForPayment" -->
                                                                    <!-- 																				[formControl]="controlConsigneeNamePayment" -->
                                                                    <!-- 																				[appAutocomplete]="autocompleteConsigneeNamePayment"> -->
                                                                    <!-- 																			<div class="input-group-postpend"> -->
                                                                    <!-- 																				<span style="background-color: orange; color: #fff" -->
                                                                    <!-- 																					class="input-group-text"> <i -->
                                                                    <!-- 																					class="fas fa-undo" title="Relaod Consignee Name" -->
                                                                    <!-- 																					style="cursor: pointer;" -->
                                                                    <!-- 																					(click)="reloadConsignee()"></i> -->
                                                                    <!-- 																				</span> -->
                                                                    <!-- 																			</div> -->
                                                                    <!-- 																		</div> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->
                                                                    <!-- 																<app-autocomplete -->
                                                                    <!-- 																	#autocompleteConsigneeNamePayment="appAutocomplete"> -->
                                                                    <!-- 																<ng-template appAutocompleteContent> <ng-container -->
                                                                    <!-- 																	*ngIf="(consigneeNameOptionsPayment | filterConsigneeName: controlConsigneeNamePayment.value) as resultConsigneeNamePayment"> -->
                                                                    <!-- 																<app-option -->
                                                                    <!-- 																	*ngFor="let option of resultConsigneeNamePayment" -->
                                                                    <!-- 																	(click)="rowSelectedConsigneePayment(option)" -->
                                                                    <!-- 																	[value]="option.consigneeName"> {{ -->
                                                                    <!-- 																option.consigneeName }} </app-option> <app-option class="no-result" -->
                                                                    <!-- 																	*ngIf="!resultConsigneeNamePayment.length">No -->
                                                                    <!-- 																result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                    <!-- 															</div> -->
                                                                    <div *ngIf="viewByConsigneeName" class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                                <div class="input-group">

                                                                                    <!-- 																			<div class="input-group-prepend"> -->
                                                                                    <!-- 																				<span class="input-group-text"> <i -->
                                                                                    <!-- 																					class="fas fa-user"></i> -->
                                                                                    <!-- 																				</span> -->
                                                                                    <!-- 																			</div> -->
                                                                                    <!-- 																																		<input #consigneeNameForPayment -->
                                                                                    <!-- 																																			id="{{pageId}}consigneeNameForPayment" -->
                                                                                    <!-- 																																			name="consigneeNameForPayment" type="text" -->
                                                                                    <!-- 																																			class="form-control" -->
                                                                                    <!-- 																																			placeholder="Select Consignee Name" -->
                                                                                    <!-- 																																			[ngbTypeahead]="consigneeNameForPaymentSearchTA" -->
                                                                                    <!-- 																																			(focus)="focusConsigneeNameForPaymentTA$.next($any($event).target.value)" -->
                                                                                    <!-- 																																			(click)="clickTA(instanceConsigneeNameForPayment)" -->
                                                                                    <!-- 																																			(click)="rowSelectedConsigneePayment(option)" -->
                                                                                    <!-- 																																			#instanceConsigneeNameForPayment="ngbTypeahead" /> -->
                                                                                    <input #consigneeNameForPayment id="{{pageId}}consigneeNameForPayment" name="consigneeNameForPayment" type="text" class="form-control" placeholder="Select Consignee" (selectItem)="rowSelectedConsigneePayment($event)" [(ngModel)]="modelConsigneeNameForPayment"
                                                                                        [ngModelOptions]="{standalone: true}" [ngbTypeahead]="consigneeNameForPaymentSearchTA" [resultFormatter]="formatterConsigneeNameForPayment" [inputFormatter]="formatterConsigneeNameForPayment"
                                                                                        (focus)="focusConsigneeNameForPaymentTA$.next($any($event).target.value)" /><span style="background-color: orange; color: #fff"
                                                                                        class="input-group-text"> <i
                                                                                            class="fas fa-undo"
                                                                                            title="Relaod Consignee Name"
                                                                                            style="cursor: pointer;"
                                                                                            (click)="reloadConsignee()"></i>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}statusBackPayment" #statusBackPayment [(ngModel)]="viewByStatusBackPayment" (change)="toggleEditableForStatuBackPayment($event, contentLessEntryStatusBack)">
                                                                                <label class=" custom-control-label wtfull " for="{{pageId}}statusBackPayment">Status
                                                                                    Back</label>
                                                                                <!-- 																		 <a -->
                                                                                <!-- 																		(click)="openLessEntryStatusBackPopup(contentLessEntryStatusBack)">check -->
                                                                                <!-- 																		3</a> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="viewByCash" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Total Amount</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-rupee-sign"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #totalAmountForPayment type="number" id="{{pageId}}totalAmountForPayment" class="form-control" (keyup)="validateTotalLess($event)" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="viewByChequeNumber" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Cheque Number</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-file-alt"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input type="text" id="{{pageId}}chequeNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="fieldFocusChqNo($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 															<div *ngIf="viewByChequeDate" class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="form-group"> -->
                                                                    <!-- 																	<div class="input-group"> -->
                                                                    <!-- 																		<label>Cheque Date</label> <input id="{{pageId}}chequeDate" -->
                                                                    <!-- 																			class="form-control" name="chequeDates" ngbDatepicker -->
                                                                    <!-- 																			#chequeDates="ngbDatepicker" -->
                                                                    <!-- 																			(click)="chequeDates.toggle();focusChqDate();"> -->
                                                                    <!-- 																		<div class="input-group-append" -->
                                                                    <!-- 																			(click)="chequeDates.toggle();focusChqDate();"> -->
                                                                    <!-- 																			<span class="input-group-text"> <i -->
                                                                    <!-- 																				class="fa fa-calendar"></i> -->
                                                                    <!-- 																			</span> -->
                                                                    <!-- 																		</div> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->
                                                                    <!-- 															</div> -->
                                                                    <div *ngIf="viewByChequeDate" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Cheque Date</label> <input id="{{pageId}}chequeDate" class="form-control" name="chequeDates" ngbDatepicker #chequeDates="ngbDatepicker" (keyup)="focusChqDateEnter($event)"
                                                                                    (ngModelChange)="focusChqDate($event);" (keydown.arrowdown)="chequeDates.toggle();">
                                                                                <div class="input-group-append" (click)="chequeDates.toggle();">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fa fa-calendar"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the seventh autocomplete ends -->
                                                                    <!-- 															<div *ngIf="viewByConsigneeBankName" -->
                                                                    <!-- 																class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="control"> -->
                                                                    <!-- 																	<div class="form-group"> -->
                                                                    <!-- 																		<div class="input-group"> -->
                                                                    <!-- 																																						<label>Consignee Bank Name</label> -->
                                                                    <!-- 																			<div class="input-group-prepend"> -->
                                                                    <!-- 																				<span class="input-group-text"> <i -->
                                                                    <!-- 																					class="fas fa-university"></i> -->
                                                                    <!-- 																				</span> -->
                                                                    <!-- 																			</div> -->
                                                                    <!-- 																			<input #consigneeBankName -->
                                                                    <!-- 																				class="auto_selectOption input is-medium" -->
                                                                    <!-- 																				placeholder="Select Bank" -->
                                                                    <!-- 																				[formControl]="controlConsigneeBankName" -->
                                                                    <!-- 																				id="{{pageId}}consigneeBankName" -->
                                                                    <!-- 																				[appAutocomplete]="autocompleteConsigneeBankName"> -->
                                                                    <!-- 																			<div class="input-group-postpend"> -->
                                                                    <!-- 																				<span style="background-color: orange; color: #fff" -->
                                                                    <!-- 																					class="input-group-text"> <i -->
                                                                    <!-- 																					class="fas fa-undo" title="Relaod Consignee Name" -->
                                                                    <!-- 																					style="cursor: pointer;" (click)="reloadBank()"></i> -->
                                                                    <!-- 																				</span> -->
                                                                    <!-- 																			</div> -->
                                                                    <!-- 																		</div> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->

                                                                    <!-- 																<app-autocomplete -->
                                                                    <!-- 																	#autocompleteConsigneeBankName="appAutocomplete"> -->
                                                                    <!-- 																<ng-template appAutocompleteContent> <ng-container -->
                                                                    <!-- 																	*ngIf="(consigneeBankNameArrayRet | filterBankName: controlConsigneeBankName.value) as resultConsigneeBankName"> -->
                                                                    <!-- 																<app-option -->
                                                                    <!-- 																	*ngFor="let option of resultConsigneeBankName" -->
                                                                    <!-- 																	(click)="partyBankMethod(option)" -->
                                                                    <!-- 																	[value]="option.bankName"> {{ -->
                                                                    <!-- 																option.bankName }} </app-option> <app-option class="no-result" -->
                                                                    <!-- 																	*ngIf="!resultConsigneeBankName.length">No -->
                                                                    <!-- 																result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                    <!-- 															</div> -->

                                                                    <div *ngIf="viewByConsigneeBankName" class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                                <div class="input-group">
                                                                                    <!-- 																		<label>Consignee Name</label> -->
                                                                                    <!-- 																			<div class="input-group-prepend"> -->
                                                                                    <!-- 																				<span class="input-group-text"> <i -->
                                                                                    <!-- 																					class="fas fa-university"></i> -->
                                                                                    <!-- 																				</span> -->
                                                                                    <!-- 																			</div> -->
                                                                                    <input #consigneeBankName id="{{pageId}}consigneeBankName" name="consigneeBankName" type="text" placeholder="Select Bank" (selectItem)="partyBankMethod($event)" [ngbTypeahead]="consigneeBankNameSearchTA" (focus)="focusConsigneeBankNameTA$.next($any($event).target.value)"
                                                                                        (click)="clickTA(instanceConsigneeBankName)" #instanceConsigneeBankName="ngbTypeahead" />
                                                                                    <span style="background-color: orange; color: #fff" class="input-group-text"> <i
                                                                                            class="fas fa-undo"
                                                                                            title="Relaod Bank Name"
                                                                                            style="cursor: pointer;"
                                                                                            (click)="reloadBank()"></i>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div *ngIf="viewNewBankName" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>New Bank Name</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-university"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #newBankName type="text" class="form-control" id="{{pageId}}newBankName" name="newBankName" (keyup)="fieldFocusNewBank($event)" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the seventh autocomplete ends -->
                                                                    <div *ngIf="viewByReferenceNumber" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Reference Number</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-file-alt"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #referenceNumber type="text" id="{{pageId}}referenceNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="fieldFocusRef($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="viewByCashs" class=col-md-12>
                                                                        <div class="card card-hover imb_custome">
                                                                            <div class="card-body" style="background-color: lightcyan;">
                                                                                <h5 class="card-title">Denomination Details
                                                                                </h5>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label class="">2000
                                                                                                    X:</label> <input type="number" id="{{pageId}}twoThousandRupeeCount"
                                                                                                    class="form-control" [(ngModel)]="twoThousandRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoThousandRupeeEvent($event)"
                                                                                                    (blur)="twoThousandRupeeBlur( $event)">
                                                                                                <input type="number" id="{{pageId}}twoThousandRupee" class="form-control" [(ngModel)]="twoThousandRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>500 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveHundredRupeeCount" [(ngModel)]="fiveHundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiveHundredRupeeEvent($event)" (blur)="fiveHundredRupeeBlur( $event)">
                                                                                                <input type="number" id="{{pageId}}fiveHundredRupee" class="form-control" [(ngModel)]="fiveHundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>200 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoHundredRupeeCount" [(ngModel)]="twoHundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoHundredRupeeEvent($event)" (blur)="twoHundredRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoHundredRupee" [(ngModel)]="twoHundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>100 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}hundredRupeeCount" [(ngModel)]="hundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="hundredRupeeEvent($event)" (blur)="hundredRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}hundredRupee" [(ngModel)]="hundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>50 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiftyRupeeCount" [(ngModel)]="fiftyRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiftyRupeeEvent($event)" (blur)="fiftyRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiftyRupee" aria-describedby="basic-addon11" [(ngModel)]="fiftyRupeeModel" [ngModelOptions]="{standalone: true}" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>20 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twentyRupeeCount" [(ngModel)]="twentyRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twentyRupeeEvent($event)" (blur)="twentyRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twentyRupee" aria-describedby="basic-addon11" [(ngModel)]="twentyRupeeModel" [ngModelOptions]="{standalone: true}" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>10 X
                                                                                                    :</label><input type="number" class="form-control" id="{{pageId}}tenRupeeCount"
                                                                                                    [(ngModel)]="tenRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="tenRupeeEvent($event)" (blur)="tenRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}tenRupee" [(ngModel)]="tenRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>5 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveRupeeCount" [(ngModel)]="fiveRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiveRupeeEvent($event)" (blur)="fiveRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveRupee" [(ngModel)]="fiveRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>2 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoRupeeCount" [(ngModel)]="twoRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoRupeeEvent($event)" (blur)="twoRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoRupee" [(ngModel)]="twoRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>1 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}oneRupeeCount" [(ngModel)]="oneRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="oneRupeeEvent($event)" (blur)="oneRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}oneRupee" [(ngModel)]="oneRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">

                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Total : </label>
                                                                                                <input #totalRupeeDenomination type="number" class="form-control" id="{{pageId}}totalRupeeDenomination" aria-describedby="basic-addon11" readonly>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- 																			<div *ngIf="showSpinner" class="col-sm-12 col-md-12"> -->
                                                                                    <!-- 																				<div class="form-group"> -->
                                                                                    <!-- 																					<div class="input-group" -->
                                                                                    <!-- 																						style="margin-right: 160px;"> -->
                                                                                    <!-- 																						<mat-spinner></mat-spinner> -->
                                                                                    <!-- 																					</div> -->
                                                                                    <!-- 																				</div> -->
                                                                                    <!-- 																			</div> -->
                                                                                    <!-- 																			<div *ngIf="showSpinner" class="col-sm-12 col-md-12"> -->
                                                                                    <!-- 																				<div class="form-group"> -->
                                                                                    <!-- 																					<div class="input-group"> -->
                                                                                    <!-- 																						<h6 class="card-title" style="color: blue;">Saving -->
                                                                                    <!-- 																							Payment details......</h6> -->
                                                                                    <!-- 																					</div> -->
                                                                                    <!-- 																				</div> -->
                                                                                    <!-- 																			</div> -->
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="showForCheckandNeft" class="col-md-3 vl">
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Entry Type</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <select class="custom-select col-12" id="{{pageId}}entryLessPaymentEntry" name="entryLessPaymentEntry" #entryLessPaymentEntry (change)="entryLessPaymentEntryMode(entryLessPaymentEntry.value)">
                                                                                    <option value="paymentLessManual">
                                                                                        Manual</option>
                                                                                    <option
                                                                                        value="paymentLessAutomatic">
                                                                                        Automatic</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the fifth autocomplete starts -->
                                                                    <!-- 															<div class="col-sm-12 col-md-12"> -->
                                                                    <!-- 																<div class="control"> -->
                                                                    <!-- 																	<div class="form-group"> -->
                                                                    <!-- 																		<div class="input-group"> -->
                                                                    <!-- 																			<label>Station</label> -->
                                                                    <!-- 																			<div class="input-group-prepend"> -->
                                                                    <!-- 																				<span class="input-group-text"> <i -->
                                                                    <!-- 																					class="fas fa-warehouse"></i> -->
                                                                    <!-- 																				</span> -->
                                                                    <!-- 																			</div> -->
                                                                    <!-- 																			<input #paymentLessStn -->
                                                                    <!-- 																				class="auto_selectOption input is-medium" -->
                                                                    <!-- 																				placeholder="Select Station" id="{{pageId}}paymentLessStn" -->
                                                                    <!-- 																				[formControl]="controlEntryLessStation" -->
                                                                    <!-- 																				[appAutocomplete]="autocompleteEntryLessStation"> -->
                                                                    <!-- 																		</div> -->
                                                                    <!-- 																	</div> -->
                                                                    <!-- 																</div> -->
                                                                    <!-- 																<app-autocomplete -->
                                                                    <!-- 																	#autocompleteEntryLessStation="appAutocomplete"> -->
                                                                    <!-- 																<ng-template appAutocompleteContent> <ng-container -->
                                                                    <!-- 																	*ngIf="(paymentLessStationOptions | filterDest: controlEntryLessStation.value) as resultEntryLessStation"> -->
                                                                    <!-- 																<app-option -->
                                                                    <!-- 																	*ngFor="let option of resultEntryLessStation" -->
                                                                    <!-- 																	(click)="getMemoCode(option,'payment')" -->
                                                                    <!-- 																	[value]="option.destination"> {{ -->
                                                                    <!-- 																option.destination }} </app-option> <app-option class="no-result" -->
                                                                    <!-- 																	*ngIf="!resultEntryLessStation.length">No -->
                                                                    <!-- 																result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                                    <!-- 															</div> -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Station</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-warehouse"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input #paymentLessStn id="{{pageId}}paymentLessStn" type="text" placeholder="Select Station" class="form-control" (selectItem)="getMemoCode($event,'payment')" [(ngModel)]="modelStationForPayment" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="paymentLessStnSearchTA"
                                                                                        (focus)="focusPaymentLessStnTA$.next($any($event).target.value)" (click)="clickTA(instancePaymentLessStn)" #instancePaymentLessStn="ngbTypeahead" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the fifth autocomplete ends -->
                                                                    <div *ngIf="paymentLessManualView" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Memo Number</label>
                                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                                <!-- 																				class="fas fa-file-alt"></i> -->
                                                                                <!-- 																			</span> -->
                                                                                <!-- 																		</div> -->
                                                                                <input type="text" #codeForManualLessPayment type="text" id="{{pageId}}codeForManualLessPayment" name="codeForManualLessPayment" [(ngModel)]="modelStationForPaymentAmt" [ngModelOptions]="{standalone: true}" class="form-control" aria-describedby="basic-addon11">
                                                                                <input #memoNumberForManualLessPayment type="text" id="{{pageId}}memoNumberForManualLessPayment" name="memoNumberForManualLessPayment" type="text" class="form-control" (keyup)="getMemoNumberForEntryLess($event,'manual')" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="paymentLessAutomaticView" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Memo Number</label>
                                                                                <!-- 																		<div class="input-group-prepend"> -->
                                                                                <!-- 																			<span class="input-group-text"> <i -->
                                                                                <!-- 																				class="fas fa-file-alt"></i> -->
                                                                                <!-- 																			</span> -->
                                                                                <!-- 																		</div> -->
                                                                                <input #memoNumberForAutomaticLessPayment type="text" id="{{pageId}}memoNumberForAutomaticLessPayment" name="memoNumberForAutomaticLessPayment" (keyup)="getMemoNumberForEntryLess($event,'scan')" class="form-control" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Amount</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-rupee-sign"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input type="number" id="{{pageId}}paymentLessAmount" class="form-control" (keyup)="validateLessAmount($event)" aria-describedby="basic-addon11">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12" style='display: none;'>
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <input type="number" id="{{pageId}}hiddenIndexForPaymentEntry" class="form-control" aria-describedby="basic-addon11">&nbsp;

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="showForCheckandNeft" class="col-md-6 vl">
                                                                    <div class="box-body">

                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Amount Entries
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <!-- 																		<button class="btn btn-danger m-r-10" -->
                                                                                <!-- 																			style="padding: 1px 4px;" id="{{pageId}}lessEntryRemoveBtn">Remove -->
                                                                                <!-- 																		</button> -->
                                                                            </div>
                                                                        </div>



                                                                        <!-- 							<the fifth datatable starts  -->
                                                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPaymentAmountEntries" [dtTrigger]="dtTriggerPaymentAmountEntries">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Memo Number</th>
                                                                                    <th>Memo Amount</th>
                                                                                    <th>Less Amount</th>
                                                                                    <th>Less Description</th>
                                                                                    <th>Balance Amount</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let paymentAmountEntriesData of paymentAmountEntriesDataList; let i = index">
                                                                                    <td>{{ paymentAmountEntriesData.chqType }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.cashMemoAmt }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.chqAmount }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.chqNumber }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.balance }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditForPaymentEntry(paymentAmountEntriesData,i);">
                                                                                            <i title="Edit"
                                                                                                class="fas fa-pencil-alt"></i>
                                                                                        </button>&nbsp;
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForPaymentEntry(paymentAmountEntriesData,i);">
                                                                                            <i title="Remove"
                                                                                                class="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">
                                                                                        {{countOfPaymentMemo}}</td>
                                                                                    <td>{{sumOfMemoAmtPayment}}</td>
                                                                                    <td>{{sumOfLessAmtPayment}}</td>
                                                                                    <td></td>
                                                                                    <td>{{sumOfBalanceAmtPayment}}</td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                        <!-- 							<the fifth datatable ends  -->
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showForCheckandNeft" class="row">
                                                    <div class="col-md-12" style="text-align: right; height: 150px;">
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <!-- 											matStepperPrevious -->
                                                        <button class="btn btn-dark m-r-10" type="button" mat-button matStepperPrevious (click)="callTempoForLess();">Back</button>
                                                        <button *ngIf="showNextBtn" type="button" class="btn btn-success m-r-10" id="{{pageId}}paymentLessNextBtnCheck" (click)="validateNextBtn($event)">Next</button>
                                                        <button class="btn btn-dark m-r-10" type="button" id="{{pageId}}paymentEntryFinishBtn" (click)="validatePaymentEntryFinishBtn($event)" mat-button>Finish</button>
                                                        <button *ngIf="showResetBtn" type="button" class="btn btn-success m-r-10" mat-button (click)="stepper.reset();resetAll()">Reset</button>
                                                        <!-- 											<button class="btn btn-success m-r-10" id="{{pageId}}nextToBtn" -->
                                                        <!-- 												mat-button matStepperNext>Next</button> -->
                                                    </div>
                                                </div>

                                                <!-- 									<div> -->
                                                <!-- 										<button mat-button matStepperPrevious>Back</button> -->
                                                <!-- 										<button mat-button matStepperNext>Next</button> -->
                                                <!-- 									</div> -->
                                            </form>
                                        </mat-step>
                                        <!-- 								three flow is ends -->
                                        <!-- 								four flow is starts -->
                                    </mat-horizontal-stepper>



                                </div>
                            </div>

                            <!-- 						popup status back starts -->
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group" id="{{pageId}}popupDetailsLessEntryStatusBack">
                                        <ng-template #contentLessEntryStatusBack let-ok="close" let-d="dismiss">


                                            <div class="modal-header">
                                                <h5>Status Back</h5>
                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div class="row" style="">
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Cashmemo Number</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-file-alt"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" #cashmemoNumberForStatusBack id="{{pageId}}cashmemoNumberForStatusBack" class="form-control" aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-12 p-t-10" style="text-align: right;">
                                                        <button type="button" class="btn btn-outline-secondary" (click)="validateStatusBackBtn();">Status Back</button>
                                                        <button type="button" class="btn btn-outline-secondary" (click)="ok('Cancel click');uncheckStatusBack();">Close</button>


                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <!-- 														status back popup ends -->
                            <!-- 								the datatable and amount summary starts -->

                            <div [hidden]="!consigneeWiseDetails" class="row p-t-10">
                                <div class="col-md-5">
                                    <div class="box-body">
                                        <h6 class="card-title">Consignee Wise Balance Details</h6>
                                        <!-- 							<the fourth datatable starts  -->
                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsigneeWiseBalanceDetails" [dtTrigger]="dtTriggerConsigneeWiseBalanceDetails">

                                            <thead>
                                                <tr>
                                                    <th>Consignee</th>
                                                    <th>Memos</th>
                                                    <th>Amount</th>
                                                    <th>Less</th>
                                                    <th>Cheque</th>
                                                    <th>NEFT</th>
                                                    <th>Debit A/c</th>
                                                    <th>UPI</th>
                                                    <th>Bal.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                <tr *ngFor="let consigneeWiseBalanceDetailsData of consigneeWiseBalanceDetailsDataList ">
                                                    <td>{{ consigneeWiseBalanceDetailsData.consigneeName }}
                                                    </td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.totalCashMemo }}
                                                    </td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.grandTotal }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.partyLessDed }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.advChqAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.neftAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.debitAcAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.upiAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.balance }}</td>
                                                </tr>
                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style="text-align: left">{{countOfConsingeeWiseMemo}}</td>
                                                    <td>{{sumOfConsingeeWiseTotalCashMemo}}</td>
                                                    <td>{{sumOfConsingeeWiseGrandTotal}}</td>
                                                    <td>{{sumOfConsingeeWisePartyLessDed}}</td>
                                                    <td>{{sumOfConsingeeWiseAdvChqAmt}}</td>
                                                    <td>{{sumOfConsingeeWiseNeftAmtl}}</td>
                                                    <td>{{sumOfConsingeeWiseDebitAcAmtl}}</td>
                                                    <td>{{sumOfConsingeeWiseUPIAmtl}}</td>
                                                    <td>{{sumOfConsingeeWiseBalance}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <!-- 							<the fourth datatable ends  -->
                                    </div>
                                </div>
                                <div class="col-md-7 vl">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="card card-hover imb_custome">
                                            <div class="card-body" style="background-color: lightcyan;">
                                                <h5 class="card-title">Amount Summary</h5>
                                                <div class="row">
                                                    <div class="col-md-5">

                                                        <div class="col-md-12">

                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-danger">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>Delhi Less :<span
                                                                    class="ml-auto font-16 text-muted">{{lblDelhiLessValue}}</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-12">

                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-warning">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>Mumbai Less:<span
                                                                    class="ml-auto font-16 text-muted">{{lblMumLessValue}}</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-12">

                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-danger">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>Gujarat Less :<span
                                                                    class="ml-auto font-16 text-muted">{{lblGujLessValue}}</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-12">

                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-inverse">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>Punjab Less :<span
                                                                    class="ml-auto font-16 text-muted">{{lblPjbLessValue}}</span>
                                                            </label>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-info">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>TDS Less : <span
                                                                    class="ml-auto font-16 text-muted">{{lblTDSLessValue}}</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="label_custome">
                                                                <div class=" cssaaa feed-icon bg-danger">
                                                                    <i class="mdi mdi-currency-inr"></i>
                                                                </div>Claim Less :<span
                                                                    class="ml-auto font-16 text-muted">{{lblClaimLessValue}}</span>
                                                            </label>
                                                        </div>




                                                    </div>
                                                    <div class="col-md-7 vl_custome">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-info">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Amount To Be Collected:<span
                                                                        class="ml-auto font-16 text-muted">{{amountToBeCollected}}</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-warning">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Less :<span *ngIf="!showLessLbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotLessSummaryValue}}</span><span
                                                                        *ngIf="showLessLbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForLessForDataTable();">{{lblTotLessSummaryValue}}</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-danger">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Amount In Cheque/PDC :<span
                                                                        *ngIf="!showChqLbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotChqAmtSummaryValue}}</span><span
                                                                        *ngIf="showChqLbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForChq();">{{lblTotChqAmtSummaryValue}}</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-inverse">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Amount In NEFT/IMPS :<span
                                                                        *ngIf="!showNEFTLbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotNeftAmtSummaryValue}}</span><span
                                                                        *ngIf="showNEFTLbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForNEFT();">{{lblTotNeftAmtSummaryValue}}</span>
                                                                </label>
                                                            </div>

                                                            <div class="col-md-12">

                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-info">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Amount In Debit A/c :<span
                                                                        *ngIf="!showDRAccLbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotDebtAcccAmtSummaryValue}}</span><span
                                                                        *ngIf="showDRAccLbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForDebtAcc();">{{lblTotDebtAcccAmtSummaryValue}}</span>
                                                                </label>
                                                            </div>

                                                            <div class="col-md-12">

                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-info">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Amount In UPI :<span *ngIf="!showUPILbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotUPIAmtSummaryValue}}</span><span
                                                                        *ngIf="showUPILbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForUPI();">{{lblTotUPIAmtSummaryValue}}</span>
                                                                </label>
                                                            </div>



                                                            <div class="col-md-12">
                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-info">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Total Amount In Cash : <span
                                                                        *ngIf="!showCashLbl"
                                                                        class="ml-auto font-16 text-muted">{{lblTotCashAmtSummaryValue}}</span>
                                                                    <span *ngIf="showCashLbl"
                                                                        class="ml-auto font-16 text-muted"
                                                                        style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
                                                                        (click)="callTempoForCash();">{{lblTotCashAmtSummaryValue}}</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="label_custome">
                                                                    <div class=" cssaaa feed-icon bg-danger">
                                                                        <i class="mdi mdi-currency-inr"></i>
                                                                    </div>Balance Amount :<span
                                                                        class="ml-auto font-16 text-muted">{{balanceAmountFinal}}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <!-- 													<li class="feed-item">-------------------------------</li> -->

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- <hr> -->
                            </div>

                        </div>
                    </div>
                    <!-- Row -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="viewAssignMemoPrint" onafterprint="afterPrint()" id="{{pageId}}printAssignedMemo">
        <app-payment-followup-assign-print></app-payment-followup-assign-print>
    </div>
    <div *ngIf="viewChallanRptPrint" onafterprint="afterPrint()" id="{{pageId}}printChallanRpt">
        <app-payment-followup-challan-print></app-payment-followup-challan-print>
    </div>
</body>