import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { Router } from '@angular/router';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-cheque-details-report',
	templateUrl: './cheque-details-report.component.html',
	styleUrls: ['./cheque-details-report.component.css']
})
export class ChequeDetailsReportComponent implements OnInit {

	gettingDataFrmServiceFrChequeSearchReportTable: any;


	chequeDetailsReportDataList: any;


	onDestroyUnsubscribtionChequeSearchReport: Subscription;

	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends


	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerChequeDetailsReport: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsChequeDetailsReport: any;

	bankNameOptions: UserDataDto[];
	public modelBankName: any;
	bankNameTA: Array<UserDataDto> = [];
	focusBankNameTA$ = new Subject<string>();
	searchBankName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusBankNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.bankNameTA
				: this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterBankName = (x: { bankName: string }) => x.bankName;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	validateChequeDate: any = null;
	validateChequeMode: any = null;
	lrDto: LRDto = new LRDto();
	userDataDto: UserDataDto = new UserDataDto();
	showSpinnerForAction = false;
	showHiddenChequeDate: boolean = false;
	bankMasterDataList: any;

	modalRefferenceBankPopUp: NgbModalRef;
	modalRefferenceBankClosePopUp: string;
	@ViewChild('chequeBankDetailsPopUpTemplate') private chequeBankDetailsPopUpTemplate;

	modalRefferenceChequeDatePopUp: NgbModalRef;
	modalRefferenceChequeDateClosePopUp: string;
	@ViewChild('changeChequeDateDetailsPopUpTemplate') private changeChequeDateDetailsPopUpTemplate;

	lrDtoList: Array<LRDto> = [];

	bankLrDtoList: Array<LRDto> = [];
	bankDtoList: Array<LRDto> = [];
	bankDtoListReturn: any;

	lrDtoData: LRDto = new LRDto();

	chequeDateUpdateLRDto: Array<LRDto> = [];
	chequeDateUpdateLRDtoReturn: any;
	chequeDateLrDtoList: Array<LRDto> = [];
	multiSelect: boolean = false;
	table: any;
	validateBankNameId: any = null;
	validateNewChequeDateId: any = null;
	chequeDatesModal: any = null;

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	
	chequeAmtSummary: number;
	viewCustomPrintV1: boolean;
	pageId="chdrc";



	constructor(private chequeSearchRpt: ReportService, private router: Router,
		private memoReport: MemoReport, private masterReadService: MasterReadService,
		private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getBankDetailsList();
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				//dtInstance.destroy();
			});
		});
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsChequeDetailsReport = {
			//dom: 'lBfrtip',
			dom: 'Bfrtip',
			buttons: [
				
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					exportOptions: {
						columns: [1, 2, 3, 5, 4, 5, 6]
					},
					title: function () {
						return "Cheque Details Report - " +
							"Type : " + $("#chdrcchequeModeId").val() + " -  " +
							"Date : " + moment(new Date()).format('DD-MM-YYYY') + "";
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 250,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var chqAmt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(3).footer()).html(chqAmt);
			},
		}
	}
	ngOnDestroy(): void {
		this.dtTriggerChequeDetailsReport.unsubscribe();
		//this.onDestroyUnsubscribtionChequeSearchReport.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerChequeDetailsReport.next();
	}
	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	onKeyPressFieldForChequeReffNo(event) {
		if (event == 13) { $("#"+this.pageId+"searchBtn").focus(); }
	}

	searchByType(searchBy: string) {
		if (searchBy == "Specific") {
			this.chequeDatesModal = null;
			this.showHiddenChequeDate = true;
		} else {
			this.chequeDatesModal = null;
			this.showHiddenChequeDate = false;
		}
	}
	clearChequeBtn() {
		//$("#"+this.pageId+"chequeModeId").val('ALL');
		this.validateChequeDate = null;
		this.validateChequeMode = null;
		this.chequeDatesModal = null;
		this.showHiddenChequeDate = false;
	}

	clearBtn() {
		$("#"+this.pageId+"chequeModeId").val('ALL');
		this.lrDtoList = [];
		this.clearBankFields();
		this.clearNewChequeDateFields();
		this.chequeDetailsReportDataList = [];
		$("#"+this.pageId+"chequeDetailsTableId").DataTable().destroy();
		this.dtTriggerChequeDetailsReport.next();
		this.validateChequeDate = null;
		this.validateChequeMode = null;
		this.chequeDatesModal = null;
		this.showHiddenChequeDate = false;
	}

	validateSearchBtn() {
		this.getChequeDetailsReportDataList();
	}
	getChequeDetailsData() {
		this.lrDto = new LRDto();
		this.lrDto.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;

		this.validateChequeDate = $("#"+this.pageId+"chequeDates").val();
		this.validateChequeMode = $("#"+this.pageId+"chequeModeId").val();
		this.lrDto.date = this.validateChequeDate;
		this.lrDto.mode = this.validateChequeMode;
	}

	getChequeDetailsReportDataList() {
		this.showSpinnerForAction = true;
		this.getChequeDetailsData();
		this.memoReport.getDestinationChequeDetailsReport(this.lrDto).subscribe(
			(response) => {
				this.lrDtoList = [];
				$("#"+this.pageId+"chequeDetailsTableId").DataTable().destroy();
				this.chequeDetailsReportDataList = [];
				if (response.length > 0) {
					this.showSpinnerForAction = false;
					console.log(response);
					this.chequeDetailsReportDataList = response;
				} else {
					this.showSpinnerForAction = false;
					swal("No Records", "No Records found for this search", "info");
				}
				this.dtTriggerChequeDetailsReport.next();
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the cheque Details", "info");
			},
			() => console.log('done');
	}

	clickListnerForBankName(event) {
		this.modelBankName = event.item;
		$("#"+this.pageId+"bankNameId").val(this.modelBankName.bankName);
	}

	getDetailsForBankMasterRead() {
		this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
		this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
		this.userDataDto.status = "Working";
	}

	getBankDetailsList = () => {
		this.showSpinnerForAction = true;
		this.bankNameOptions = [];
		this.getDetailsForBankMasterRead();
		this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
			(response) => {
				if (response) {
					if (response.length > 0) {
						this.bankNameOptions = response;
						this.bankNameTA = [];
						for (let i = 0; i < this.bankNameOptions.length; i++) {
							this.bankNameTA.push(this.bankNameOptions[i]);
						}
						this.showSpinnerForAction = false;
					} else {
						this.showSpinnerForAction = false;
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Bank Master Details", "error");
			},
			() => console.log('done');
	};

	//pop up
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	validateSendBankDetailsBtn(chequeBankDetailsPopUpTemplate) {
		console.log(this.lrDtoList);
		if (this.lrDtoList.length == 0) {
			swal("Mandatory Fields", "Please select the atleast one checkbox to proceed", "warning");
			return false;
		} else {
			this.modalRefferenceBankPopUp = this.modalService.open(chequeBankDetailsPopUpTemplate,
				{ centered: true, windowClass: "modalClassForPopUpTruckOwner" });
			this.modalRefferenceBankPopUp.result.then((result) => {
				this.modalRefferenceBankClosePopUp = `Closed with: ${result}`;
			}, reason => {
				this.modalRefferenceBankClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
			});
		}
	}
	rowCheckBoxChecked(e, chequeDetailsReportData) {
		if (e.currentTarget.checked) {
			if (this.lrDtoList.length == 0) {
				this.lrDtoList = [];
				this.lrDtoList.push(chequeDetailsReportData);
			} else {
				this.lrDtoList.push(chequeDetailsReportData);
			}
		} else {
			let index = this.lrDtoList.indexOf(chequeDetailsReportData);
			if (index > -1) {
				this.lrDtoList.splice(index, 1);
			}
		}
	}

	validateChangeChequeDateDetailsBtn(changeChequeDateDetailsPopUpTemplate) {
		if (this.lrDtoList.length == 0) {
			swal("Mandatory Fields", "Please select the atleast one checkbox to proceed", "warning");
			return false;
		} else {
			this.modalRefferenceChequeDatePopUp = this.modalService.open(changeChequeDateDetailsPopUpTemplate,
				{ centered: true, windowClass: "modalClassForPopUpTruckOwner" });
			this.modalRefferenceChequeDatePopUp.result.then((result) => {
				this.modalRefferenceChequeDateClosePopUp = `Closed with: ${result}`;
			}, reason => {
				this.modalRefferenceChequeDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
			});
		}
	}

	//send to bank 
	updateSendToBank() {
		this.bankDtoList = [];
		this.bankDtoList = this.getSelectedValuesBank();
		//console.log(this.bankDtoList);
		this.memoReport.updateDestinationChqSentToBankDetailsReport(this.bankDtoList).subscribe(
			(response) => {
				this.bankDtoListReturn = response;
				console.log(this.bankDtoListReturn.status);
				if (this.bankDtoListReturn.status != null && this.bankDtoListReturn.status == "Success") {
					this.clearBankFields();
					this.getChequeDetailsReportDataList();
					this.modalRefferenceBankPopUp.close();
					swal("Details Updated", "Bank details updated successfully!", "success");
				} else {
					swal("Error", "Problem occurred while updating bank details!", "warning");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.modalRefferenceBankPopUp.close();
				swal("Error", "Server Problem Occurred While updating a bank details", "info");
			},
			() => console.log('done');
	}

	getSelectedValuesBank() {
		this.bankLrDtoList = [];
		for (let i = 0; i < this.lrDtoList.length; i++) {
			this.lrDtoData = new LRDto();
			this.lrDtoData.destination = this.userDataDtoReturnSession.mainStation;
			this.lrDtoData.userName = this.userDataDtoReturnSession.userId;
			this.lrDtoData.chequeDate = this.lrDtoList[i].chequeDate;
			this.lrDtoData.chequeNumber = this.lrDtoList[i].chequeNumber;
			this.lrDtoData.bankName = this.lrDtoList[i].bankName;
			this.validateBankNameId = $("#"+this.pageId+"bankNameId").val();
			this.lrDtoData.bankLr = this.validateBankNameId;
			this.bankLrDtoList.push(this.lrDtoData);
		}
		return this.bankLrDtoList;
	}

	updateChequeDetailsRptPopUp() {
		this.validateBankNameId = $("#"+this.pageId+"bankNameId").val();
		if (this.validateBankNameId == null || this.validateBankNameId == undefined ||
			this.validateBankNameId == "") {
			swal("Mandatory Field", "Please select the Bank Name", "warning");
			return false;
		} else {
			this.updateSendToBank();
		}
	}
	clearBankFields() {
		this.lrDtoList = [];
		this.validateBankNameId = null;
		$("#"+this.pageId+"bankNameId").val('');
		this.modelBankName = null;
	}
	closeChequeDetailsRptPopUp() {
		this.modalRefferenceBankPopUp.close();
	}



	//change cheque date

	changeChequeDate() {
		this.chequeDateUpdateLRDto = [];
		this.chequeDateUpdateLRDto = this.getSelectedValues();
		console.log(this.chequeDateUpdateLRDto);
		this.memoReport.updateDestinationChequeDateDetails(this.chequeDateUpdateLRDto).subscribe(
			(response) => {
				this.chequeDateUpdateLRDtoReturn = response;
				console.log(this.chequeDateUpdateLRDtoReturn.status);
				if (this.chequeDateUpdateLRDtoReturn.status != null &&
					this.chequeDateUpdateLRDtoReturn.status == "Success") {
					this.getChequeDetailsReportDataList();
					this.clearNewChequeDateFields();
					this.modalRefferenceChequeDatePopUp.close();
					swal("Details Updated", "Cheque Date Updated successfully!", "success");
				} else {
					swal("Error", "Problem occurred while updating the cheque date!", "warning");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.modalRefferenceChequeDatePopUp.close();
				swal("Error", "Server Problem Occurred While updating the cheque date!", "info");
			},
			() => console.log('done');
	}

	getSelectedValues() {
		this.chequeDateLrDtoList = [];
		for (let i = 0; i < this.lrDtoList.length; i++) {
			this.lrDtoData = new LRDto();
			this.lrDtoData.destination = this.userDataDtoReturnSession.mainStation;
			this.lrDtoData.userName = this.userDataDtoReturnSession.userId;
			this.lrDtoData.chequeDate = this.lrDtoList[i].chequeDate;
			this.lrDtoData.chequeNumber = this.lrDtoList[i].chequeNumber;
			this.lrDtoData.bankName = this.lrDtoList[i].bankName;
			this.validateNewChequeDateId = $("#"+this.pageId+"changeNewChequeDates").val();
			console.log(this.validateNewChequeDateId);
			this.lrDtoData.dateInDate = this.validateNewChequeDateId;
			this.chequeDateLrDtoList.push(this.lrDtoData);
		}
		return this.chequeDateLrDtoList;
	}


	updateChequeDateDetailsRptPopUp() {
		this.validateNewChequeDateId = $("#"+this.pageId+"changeNewChequeDates").val();
		if (this.validateNewChequeDateId == null || this.validateNewChequeDateId == undefined ||
			this.validateNewChequeDateId == "") {
			swal("Mandatory Field", "Please select the new cheque date", "warning");
			return false;
		} else {
			this.changeChequeDate();
		}
	}

	clearNewChequeDateFields() {
		this.lrDtoList = [];
		this.validateNewChequeDateId = null;
		$("#"+this.pageId+"changeNewChequeDates").val('');
	}

	closeChequeDateDetailsRptPopUp() {
		this.modalRefferenceChequeDatePopUp.close();
	}


	customPrintChqDetailsRpt() {
		if (this.chequeDetailsReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
		localStorage.clear();
		this.cashMemoDtoForCustomPrintList = [];
		this.cashMemoDtoForCustomPrintListColumnNames = ["Cheque Number", "Cheque Date", "Cheque Amount", "Consignee Name", "Bank Name", "Collection Man"];
		this.cashMemoDtoForCustomPrintListColumnWidths = [12, 10, 12, 24, 22, 20];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
			this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
			this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
		}
		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];

		//Heading Logics For Date
		this.cashMemoDtoForCustomPrintListHeadingV1 = [];

		this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
		this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrDto.date];

		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();

			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
			this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

			this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
		}

		//Heading Logics For Staion
		this.cashMemoDtoForCustomPrintListHeadingV2 = [];
		this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Type"];
		this.cashMemoDtoForCustomPrintListHeadingValuesV2 = ["Specific"];

		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();

			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
			this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

			this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
		}

		this.chequeAmtSummary= 0;
		for (let i = 0; i < this.chequeDetailsReportDataList.length; i++) {
			this.cashMemoDtoForCustomPrintListColumnValues = [this.chequeDetailsReportDataList[i].chequeNumber, this.chequeDetailsReportDataList[i].chequeDate,
			this.chequeDetailsReportDataList[i].chqAmt, this.chequeDetailsReportDataList[i].consigneeName, this.chequeDetailsReportDataList[i].bankName,
			this.chequeDetailsReportDataList[i].collectionMan];
			/////////
			this.chequeAmtSummary = +this.chequeAmtSummary + this.chequeDetailsReportDataList[i].chqAmt;
			
			this.cashMemoDtoForCustomPrintData = new CashMemoDto();
			this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
			this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

		}
		this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.chequeDetailsReportDataList.length,"", this.chequeAmtSummary, "", "",""];
	


		this.cashMemoDtoForCustomPrintListHeadingV3 = [];
		this.cashMemoDtoForCustomPrintListHeadingV4 = [];






		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
			this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
		}



		localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
		localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
		localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
		localStorage.setItem('printTitle', "Cheque Details Report");
		this.viewCustomPrintV1 = true;

		window.addEventListener('afterprint', (onclick) => {
			if (this.viewCustomPrintV1) {
				this.viewCustomPrintV1 = false;
				localStorage.clear();
			}
		});



	}

}
}