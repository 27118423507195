
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';
@Component({
    selector: 'app-hireslip-advance-tds-voucher-print',
    templateUrl: './hireslip-advance-tds-voucher-print.component.html',
    styleUrls: ['./hireslip-advance-tds-voucher-print.component.css']
})
export class HireslipAadvanceTdsVoucherPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;

    dataForhireSlipLocalStorageRpt: any;

    hireslipNumber: any;
    invoiceNumber: any;
    invoicedate: any;

    supplierName: any;
    lorryNo: any;
    hireAmt: any;

    departureDate: any;
    departureTime: any;
    arrivalDate: any;
    arrivalTime: any;
    timeTakenToReach: any;

    drivername: any;
    rewardAmt: any;
    rewardTo: any;
    dateInDate: any;

    num: any;
    n: any;
    printAmt: any;

    hireslipdataForPrint: HireSlipDto = new HireSlipDto();

    address: any;
    image: any;
    showSupplier = false;
    toStation: any;
    paymentDate: any;
    hireslipdate: any;
    fromStation: any;
    chqNo: any;
    chqBankName: any;
    chqNoPdc: any;
    chqBankNamePdc: any;
    showChqWise = false;
    showCashWise = false;
    showPdcWise = false;
    totalHire: any;
    tdsPerc: any;
    paidAmt:any;
    rupees:any;
    totalAmt:any;
    tdsAmt:any;
    panNo:any;
    pageId="hatvp";
   


    constructor(private router: Router, private datePipe: DatePipe,public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForhireSlipLocalStorageRpt = JSON.parse(localStorage.getItem('tdsVoucherPrint'));
            console.log(this.dataForhireSlipLocalStorageRpt);

            if (this.dataForhireSlipLocalStorageRpt != null) {
                this.hireslipdataForPrint = this.dataForhireSlipLocalStorageRpt;
            }

            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

            this.image = this.userDataDtoReturnSession.ip;
            console.log(this.hireslipdataForPrint);

            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {

        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
        this.panNo=this.hireslipdataForPrint.panNo == null ? ' ' : this.hireslipdataForPrint.panNo;

        if (this.hireslipdataForPrint.companyName != null && this.hireslipdataForPrint.companyName != '') {
            this.supplierName = this.hireslipdataForPrint.companyName == null ? ' ' : this.hireslipdataForPrint.companyName;
            
        }
        if (this.hireslipdataForPrint.departuredate != null) {
            this.departureDate = this.datePipe.transform(this.hireslipdataForPrint.departuredate, "dd-MM-yyyy");
        } else {
            this.departureDate = '';
        }

        this.lorryNo = this.hireslipdataForPrint.vehicleNumber == null ? ' ' : this.hireslipdataForPrint.vehicleNumber;
        this.hireslipNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.dateInDate =this.datePipe.transform(new Date(), "dd-MM-yyyy");
        this.totalHire = this.hireslipdataForPrint.totalhire==null?0:this.hireslipdataForPrint.totalhire;
        this.tdsPerc = this.hireslipdataForPrint.tdsPercent==null?0 :this.hireslipdataForPrint.tdsPercent;  
        this.tdsAmt=this.hireslipdataForPrint.tdsAmount==null? 0 :this.hireslipdataForPrint.tdsAmount;

        //////
       
        

    }

    
    clearField() {
        this.address = '';
        this.invoiceNumber = '';
        this.hireslipNumber = '';
        this.supplierName = '';
        this.drivername = '';
        this.lorryNo = '';
        this.invoicedate = '';
        this.rewardAmt = 0.0;
        this.printAmt = '';
       this.departureDate = '';
        this.totalHire = 0.0;
        this.tdsPerc =0 ; 
        this.tdsAmt=0.0;

    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
