import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { Router } from '@angular/router';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment  from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from "@angular/common";

@Component( {
    selector: 'app-cheque-search-report',
    templateUrl: './cheque-search-report.component.html',
    styleUrls: ['./cheque-search-report.component.css']
} )
export class ChequeSearchReportComponent implements OnInit {

    gettingDataFrmServiceFrChequeSearchReportTable: any;


    chequeSearchReportDataList: any;


    onDestroyUnsubscribtionChequeSearchReport: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerChequeSearchReport: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsChequeSearchReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address:any;
    validateChequeOrRefNo:any=null;
	cashMemoDto: CashMemoDto = new CashMemoDto();
	showSpinnerForAction = false;
	
	//for custom print
  	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  	cashMemoDtoForCustomPrintList: any;
  	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  	cashMemoDtoForCustomPrintDataList: any;
  	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  	cashMemoDtoForCustomPrintDataSummaryList: any;
  	
  	curDate:any;
  	viewCustomPrintV1 = false;
  	fromDatePrint:any;
  	toDatePrint:any;
  	cashMemoDtoForCustomPrintListHeadingV1: any;
  	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  	//Custom Print Footer
  	summary1: number;
  	summary2: number;
  	summary3: number;
  	summary4: number;
  	summary5: number;
  	summary6: number;
  	//
  	pageId="chsrc";

    constructor( private chequeSearchRpt: ReportService, private router: Router, private datePipe: DatePipe,
        private memoReport: MemoReport, public changeDetectorRef : ChangeDetectorRef ) {
        if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
		}
    }

    logInPage() {
		this.router.navigate(['/authentication/login']);
	}

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api) => {
                // Do your stuff
                //dtInstance.destroy();
            } );
        } );
    }
    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsChequeSearchReport = {
            dom: 'Bfrtip',
            buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
						return "Cheque Report - " +
						"Cheque / Refference No : "+ $("#chsrcchequeOrReferencNumber").val() + " -  "+
						"Date : " +  moment(new Date()).format('DD-MM-YYYY') + "";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function ( row, data, start, end, display ) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function ( i ) {
					return typeof i === 'string' ?
					+i.replace(/[\$,]/g, '') * 1:
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var memoAmt = api.column( 5 ).data().reduce( 
					           function (a, b) {
						            return intVal(a) + intVal(b);
							   }, 0 );
				var memoLessAmt = api.column( 6 ).data().reduce( 
								function (a, b) {
									 return intVal(a) + intVal(b);
								}, 0 );	
				var tdsLessAmt = api.column( 7 ).data().reduce( 
								function (a, b) {
										 return intVal(a) + intVal(b);
								}, 0 );	
				var cliamLessAmt = api.column( 8 ).data().reduce( 
								   function (a, b) {
											 return intVal(a) + intVal(b);
									}, 0 );
				var chequeAmt = api.column( 9 ).data().reduce( 
									function (a, b) {
												 return intVal(a) + intVal(b);
									}, 0 );
				var neftAmt = api.column( 10 ).data().reduce( 
									function (a, b) {
											 return intVal(a) + intVal(b);
								}, 0 );			
					
				// Update footer by showing the total with the reference of the column index 
				$( api.column( 0 ).footer() ).html('Total : ' + data.length);
				//$( api.column( 1 ).footer() ).html(data.length);
				$( api.column( 5 ).footer() ).html(memoAmt);
				$( api.column( 6 ).footer() ).html(memoLessAmt);
				$( api.column( 7 ).footer() ).html(tdsLessAmt);
				$( api.column( 8 ).footer() ).html(cliamLessAmt);
				$( api.column( 9 ).footer() ).html(chequeAmt);
				$( api.column( 10 ).footer() ).html(neftAmt);
			},
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerChequeSearchReport.unsubscribe();

        //this.onDestroyUnsubscribtionChequeSearchReport.unsubscribe();
    }

    ngAfterViewInit(): void {
		this.dtTriggerChequeSearchReport.next();
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    
    onKeyPressFieldForChequeReffNo(event){
        if(event == 13) { $("#"+this.pageId+"searchBtn").focus(); }
    }

    clearChequeBtn(){
        $("#"+this.pageId+"chequeOrReferencNumber").val('');  
        this.validateChequeOrRefNo = null;
        this.chequeSearchReportDataList = [];
		$("#"+this.pageId+"chequeSearchTableId").DataTable().destroy();
		this.dtTriggerChequeSearchReport.next();
    }

    validateSearchBtn(){
        if($("#"+this.pageId+"chequeOrReferencNumber").val() == null || 
            $("#"+this.pageId+"chequeOrReferencNumber").val() == undefined || $("#"+this.pageId+"chequeOrReferencNumber").val() == ""){
           swal("Mandatory Field","Please enter the cheque number or refference number","warning");
           return false;
        }else{
            this.getChequeRefferenceDetailsList();
        }
    }
    getChequeRefferenceDataDetails(){
        this.cashMemoDto = new CashMemoDto();
        this.validateChequeOrRefNo = $("#"+this.pageId+"chequeOrReferencNumber").val()
        this.cashMemoDto.chqNumber = this.validateChequeOrRefNo;
        this.cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
        this.cashMemoDto.destination = this.userDataDtoReturnSession.mainStation;
        this.cashMemoDto.dateInDate = new Date();
    }

    getChequeRefferenceDetailsList(){
		this.showSpinnerForAction = true;
        this.getChequeRefferenceDataDetails();
        this.memoReport.getChequeDetailsReport(this.cashMemoDto).subscribe(
			(response) => {
				$("#"+this.pageId+"chequeSearchTableId").DataTable().destroy();
				this.chequeSearchReportDataList = [];
			   if(response.length > 0){
				this.showSpinnerForAction = false;
				this.chequeSearchReportDataList = response;
			   }else{
				  this.showSpinnerForAction = false;
                  swal("No Records","No Records found for this search","info");
			   }
			   this.dtTriggerChequeSearchReport.next();
			   this.changeDetectorRef.detectChanges();
		   }),
		   (error) => {
			this.showSpinnerForAction = false;
		   swal("Error","Server Problem Occurred While getting the cheque Details","info");
		},
		   () => console.log('done');
    }
    
    customPrintChqSearch() {
		if (this.chequeSearchReportDataList.length==0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
		localStorage.clear();
		this.cashMemoDtoForCustomPrintList = [];
		this.cashMemoDtoForCustomPrintListColumnNames = ["Memo Number","LR No","Source","Party Name","Coll.Clos Dt","Memo Amt","Memo Less","TDS Less","Claim Less","Chq Amt","NEFT Amt","Bank Name","Chq Date","Collection Man"];
		this.cashMemoDtoForCustomPrintListColumnWidths = [10,7,9,14,6,6,6,6,6,6,6,6,6,6];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
			this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
			this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
		}
		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];
		this.summary1=this.summary2=this.summary3=this.summary4=this.summary5=this.summary6=0;
		for (let i = 0; i < this.chequeSearchReportDataList.length; i++) {
			this.cashMemoDtoForCustomPrintData = new CashMemoDto();
			this.cashMemoDtoForCustomPrintListColumnValues = [this.chequeSearchReportDataList[i].memoNumber,this.chequeSearchReportDataList[i].lrNumber,this.chequeSearchReportDataList[i].source,this.chequeSearchReportDataList[i].consigneeName,this.chequeSearchReportDataList[i].completedOnStr,this.chequeSearchReportDataList[i].amount,this.chequeSearchReportDataList[i].less,this.chequeSearchReportDataList[i].tds,this.chequeSearchReportDataList[i].claim,this.chequeSearchReportDataList[i].chequeAmt,this.chequeSearchReportDataList[i].neftAmt,this.chequeSearchReportDataList[i].bankName,this.chequeSearchReportDataList[i].dateInDate,this.chequeSearchReportDataList[i].collectionMan];
			this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
			this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			
			this.summary1=this.summary1+this.chequeSearchReportDataList[i].amount;
			this.summary2=this.summary2+this.chequeSearchReportDataList[i].less;
			this.summary3=this.summary3+this.chequeSearchReportDataList[i].tds;
			this.summary4=this.summary4+this.chequeSearchReportDataList[i].claim;
			this.summary5=this.summary5+this.chequeSearchReportDataList[i].chequeAmt;
			this.summary6=this.summary6+this.chequeSearchReportDataList[i].neftAmt;
		}
		this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.chequeSearchReportDataList.length,"","","","",this.summary1,this.summary2,this.summary3,this.summary4,this.summary5,this.summary6,"","",""];
			//heading logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.curDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Chq/Ref.Number","Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.cashMemoDto.chqNumber,this.curDate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];	
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Cheque Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
}
