import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterService } from "src/app/dataService/master-service";
import { LatestNewsDto } from 'src/app/dto/LatestNews-dto';


@Component( {
    selector: 'app-latest-news',
    templateUrl: './latest-news.component.html',
    styleUrls: ['./latest-news.component.css']
} )
export class LatestNewsComponent implements OnInit {

    latestNewsDataList: any;
    dtTriggerLatestNews: Subject<any> = new Subject();
    dtOptionsLatestNews: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    latestNewsDtoUserValue: LatestNewsDto = new LatestNewsDto();
    selectedId = 0;
    enteredTitle : any;
    enteredMsg :any;
    enteredIsEnabled :any;
    msgPositionId : any;
	pageId="latnc";

    constructor(private router: Router,private masterService : MasterService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
            	this.getDetails();
            }, 1000);
        }

    }

    ngOnInit(): void {
        this.dtOptionsLatestNews = {
                        dom: 'Bfrtip',
            buttons: [
                {
                 extend: 'excel',
                 text: '<i class="fas fa-file-excel"> Excel</i>',
                 titleAttr: 'Excel',
                 exportOptions: {
                 columns: ':visible'
                  }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.latestNewsDtoUserValue = new LatestNewsDto();
		this.latestNewsDtoUserValue.isEnabled = true;
		
		this.masterService.getLatestNewsDetails(this.latestNewsDtoUserValue).subscribe(
			(response) => {
				$("#"+this.pageId+"latestNewsTableId").DataTable().destroy();
				this.latestNewsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Latest News Detail",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.latestNewsDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerLatestNews.next();
	            }, 1000);
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

	confirmSave(){
		swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes, it will immediately publish to mobile app?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveDetails();
            }
        })
	}
	
	saveDetails = () => {
		this.showSpinnerForAction = true;
		this.enteredTitle = $("#"+this.pageId+"title").val();
		this.enteredMsg = $("#"+this.pageId+"message").val();
		this.msgPositionId = $("#"+this.pageId+"position").val();
		this.enteredIsEnabled = true;
		
		
		this.latestNewsDtoUserValue = new LatestNewsDto();
		this.latestNewsDtoUserValue.latestNewsId = this.selectedId;
		this.latestNewsDtoUserValue.tittle = this.enteredTitle;
		this.latestNewsDtoUserValue.msg = this.enteredMsg;
		this.latestNewsDtoUserValue.isEnabled = this.enteredIsEnabled;
		this.latestNewsDtoUserValue.msgPositionId = this.msgPositionId;
		
		this.masterService.addLatestNewsDetails(this.latestNewsDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "Latest News details saved/updated successfully!", "info");
					this.clearFields();
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to save/update the Latest News details!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while saving the details", "info");
			}, () => console.log('done');
	};
	
	rowSelectedEdit(latestNewsData){
		this.selectedId = latestNewsData.latestNewsId;
		$("#"+this.pageId+"title").val(latestNewsData.tittle);
		$("#"+this.pageId+"message").val(latestNewsData.msg);
		$("#"+this.pageId+"position").val(latestNewsData.msgPositionId);
	}
	
	confirmDelete(latestNewsData){
		swal({
            title: "Confirmation",
            text: "Sure you want to delete the selected Latest News, it will immediately effect to mobile app?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.deleteLatestNews(latestNewsData);
            }
        })
	}
	
	deleteLatestNews(latestNewsData){
		this.showSpinnerForAction = true;
		this.selectedId = latestNewsData.latestNewsId;
		this.latestNewsDtoUserValue = new LatestNewsDto();
		this.latestNewsDtoUserValue.latestNewsId = this.selectedId;
		
		this.masterService.deleteLatestNewsDetails(this.latestNewsDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					this.selectedId = 0;
					swal("Success", "Selected Latest News details deleted successfully!", "info");
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to delete the Latest News details!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while deleting the details", "info");
			}, () => console.log('done');
	}
	
	clearFields(){
		this.selectedId = 0;
		$("#"+this.pageId+"title").val("");
		$("#"+this.pageId+"message").val("");
		$("#"+this.pageId+"position").val("");
		
		this.enteredTitle = null;
		this.enteredMsg = null;
		this.msgPositionId = 0;
		this.enteredIsEnabled = true;
		this.latestNewsDtoUserValue = new LatestNewsDto();
	}

    ngOnDestroy(): void {
        this.dtTriggerLatestNews.unsubscribe();
    }
    
    ngAfterViewInit(): void {
        this.dtTriggerLatestNews.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}