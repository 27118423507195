<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-10">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;DD Consignment Undelivered Details
			</h6>
	
		</div>
		<div class="col-md-2">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>

<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}doorDelvlrsUnDelvdTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsDoorDeliveryConsignmentNotDeliveredDetails"
			[dtTrigger]="dtTriggerDoorDeliveryConsignmentNotDeliveredDetails">

			<thead>
				<tr>

					<th>LR Number</th>
					<th>Destination</th>
					<th>Booking Station</th>
					<th>Stock In Dt</th>
					<th>Days In Stock At Dest</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let doorDeliveryConsignmentNotDeliveredDetailsData of doorDeliveryConsignmentNotDeliveredDetailsDataList ">

					<td (click)="goToLrEnquiryPage()"><u class="hyperLink_td">{{
							doorDeliveryConsignmentNotDeliveredDetailsData.lrNumber }}</u></td>
					<td>{{
						doorDeliveryConsignmentNotDeliveredDetailsData.destination }}</td>
					<td>{{ doorDeliveryConsignmentNotDeliveredDetailsData.source
						}}</td>
					<td>{{
						doorDeliveryConsignmentNotDeliveredDetailsData.unloadingDateStr }}</td>
					<td>{{ doorDeliveryConsignmentNotDeliveredDetailsData.diffDays
						}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
