import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-srd-collection-stock-report',
    templateUrl: './srd-collection-stock-report.component.html',
    styleUrls: ['./srd-collection-stock-report.component.css']
})
export class SrdCollectionStockReportComponent implements OnInit {

    gettingDataFrmServiceFrSrdCollectionStockReportTable: any;

    srdCollectionStockReportDataList: any;

    onDestroyUnsubscribtionSrdCollectionStockReport: Subscription;


    model: NgbDateStruct;
    model2;

    loadingIndicator = true;

    viewDate = true;
    searchByPartyWise = false;
    searchByAreaWise = false;
    searchByCollectionManWise = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //for the select option with filter starts

    control = new FormControl();

    consigneeNameOptions: PartyMasterDto[];
    areaOptions: LRDto[];
    collectionManOptions: UserDataDto[];
    //for the select option with filter ends

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerSrdCollectionStockReport: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsSrdCollectionStockReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;

    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    partyMasterDtoForconsigneeAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneePartyName: any;
    consigneePartyNameTA: Array<PartyMasterDto> = [];
    focusConsigneePartyNameTA$ = new Subject<string>();
    searchConsigneePartyName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneePartyNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneePartyNameTA
                : this.consigneePartyNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneePartyName = (x: { consigneeName: string }) => x.consigneeName;

    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoCollectionManAll: UserDataDto = new UserDataDto();
    public modelCollectionMan: any;
    collectionManTA: Array<UserDataDto> = [];
    focusCollectionManTA$ = new Subject<string>();
    searchCollectionMan = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManTA
                : this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;


    lrDtoBranchArea: LRDto = new LRDto();
    lrDtoBranchAreaAllOption: LRDto = new LRDto();
    public modelAreaName: any;
    areaNameTA: Array<LRDto> = [];
    focusAreaNameTA$ = new Subject<string>();
    searchAreaName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAreaNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.areaNameTA
                : this.areaNameTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAreaName = (x: { godownName: string }) => x.godownName;

    cashMemoDto: CashMemoDto = new CashMemoDto();

    validateConsigneePartyName: any = null;
    validateConsigneePartyId: any = 0;
    validateAreaName: any = null;
    validateAreaNameId: any;
    validateCollectionManName: any = null;
    validateCollectionManId: any = 0;
    validateFromDates: any = null;
    validateToDates: any = null;

    showSpinnerForAction = false;
    fromDatesModal: any = null;
    toDatesModal: any = null;
    memoDataForPopup: CashMemoDto = new CashMemoDto();
    dataForPopup: any;
    memoDataFromPartyWiseRptPopup: CashMemoDto = new CashMemoDto();
    
    //for custom print
  	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  	cashMemoDtoForCustomPrintList: any;
  	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  	cashMemoDtoForCustomPrintDataList: any;
  	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  	cashMemoDtoForCustomPrintDataSummaryList: any;
  	
  	viewCustomPrintV1 = false;
  	fromDatePrint:any;
  	toDatePrint:any;
  	cashMemoDtoForCustomPrintListHeadingV1: any;
  	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  	//Custom Print Footer
  	summary1: number;
  	summary2: number;
  	//
  	pageId="scsrc";

    constructor(private srdCollectionStockRpt: ReportService, private router: Router, private datePipe: DatePipe,
        private masterReadService: MasterReadService, private memoReport: MemoReport, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getConsigneeDetails();
            this.getCollectionManDetailsList();
            this.getBranchAreaDetailsList();
            this.dataForPopup = JSON.parse(localStorage.getItem('srdCollectionManStockReport'));
            localStorage.clear();
            if (this.dataForPopup != null) {
                this.memoDataFromPartyWiseRptPopup = new CashMemoDto();
                this.memoDataFromPartyWiseRptPopup = this.dataForPopup;
                if (this.memoDataFromPartyWiseRptPopup != null) {
                    this.searchBtnForOSCashmemoCollectionFromPartyWise();
                }
            }
            this.dataForPopup = '';

        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
    //for the select option with filter starts
    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }
        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }
    //for the select option with filter ends


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsSrdCollectionStockReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if ($("#scsrcsearchBy").val() == "partyWise") {
                            return "O/S Cash Memo In SRD Collection Department- " +
                                "From Date : " + moment($("#scsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#scsrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Party Name: " + $("#scsrcconsigneePartyNameId").val() + "";
                        } else if ($("#scsrcsearchBy").val() == "areaWise") {
                            return "O/S Cash Memo In SRD Collection Department- " +
                                "From Date : " + moment($("#scsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#scsrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Area Name : " + $("#scsrcareaNameId").val() + "";
                        } else if ($("#scsrcsearchBy").val() == "collectionManWise") {
                            return "O/S Cash Memo In SRD Collection Department- " +
                                "From Date : " + moment($("#scsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#scsrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Collection Man Name : " + $("#scsrccollectionManId").val() + "";
                        } else {
                            return "O/S Cash Memo In SRD Collection Department- " +
                                "From Date : " + moment($("#scsrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#scsrctoDates").val()).format('DD-MM-YYYY') + "";
                        }
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalAmt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var article = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                //$( api.column( 1 ).footer() ).html(data.length);
                $(api.column(2).footer()).html(totalAmt);
                $(api.column(4).footer()).html(article);
            },
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerSrdCollectionStockReport.unsubscribe();

        //this.onDestroyUnsubscribtionSrdCollectionStockReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerSrdCollectionStockReport.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    searchByMode(searchBy: string) {
        this.clearFileds();
        console.log(searchBy);
        if (searchBy === 'dateWise') {
            this.viewDate = true;
            this.searchByPartyWise = false;
            this.searchByAreaWise = false;
            this.searchByCollectionManWise = false;
        } else if (searchBy === 'partyWise') {
            this.viewDate = true;
            this.searchByPartyWise = true;
            this.searchByAreaWise = false;
            this.searchByCollectionManWise = false;
        } else if (searchBy === 'areaWise') {
            this.viewDate = true;
            this.searchByPartyWise = false;
            this.searchByAreaWise = true;
            this.searchByCollectionManWise = false;
        } else if (searchBy === 'collectionManWise') {
            this.viewDate = true;
            this.searchByPartyWise = false;
            this.searchByAreaWise = false;
            this.searchByCollectionManWise = true;
        } else {
            this.viewDate = false;
            this.searchByPartyWise = false;
            this.searchByAreaWise = false;
            this.searchByCollectionManWise = false;
        }
    }

    clearBtn() {
        this.clearFileds();
        $("#"+this.pageId+"searchBy").val('dateWise');
        $("#"+this.pageId+"srdCollectionStockDatataableId").DataTable().destroy();
        this.srdCollectionStockReportDataList = [];
        this.dtTriggerSrdCollectionStockReport.next();
        this.viewDate = true;
        this.searchByPartyWise = false;
        this.searchByAreaWise = false;
        this.searchByCollectionManWise = false;
        this.validateConsigneePartyName = null;
        this.validateConsigneePartyId = 0;
        this.validateAreaName = null;
        this.validateAreaNameId = null;
        this.validateCollectionManName = null;
        this.validateCollectionManId = 0;
        this.validateFromDates = null;
        this.validateToDates = null;
        this.memoDataForPopup = new CashMemoDto();
        this.memoDataFromPartyWiseRptPopup = new CashMemoDto();
        this.dataForPopup = '';
    }

    clearFileds() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        this.modelConsigneePartyName = '';
        this.modelAreaName = '';
        this.modelCollectionMan = '';
    }

    clickListnerForConsigneePartyName(event) {
        this.modelConsigneePartyName = event.item;
        // console.log(this.modelConsigneePartyName);
        $("#"+this.pageId+"consigneePartyNameId").val(this.modelConsigneePartyName.consigneeName);
        this.validateConsigneePartyName = this.modelConsigneePartyName.consigneeName;
        this.validateConsigneePartyId = this.modelConsigneePartyName.consigneeId;
    }

    clickListnerForCollectionMan(event) {
        this.modelCollectionMan = event.item;
        //console.log(this.modelCollectionMan);
        $("#"+this.pageId+"collectionManId").val(this.modelCollectionMan.collectioMan);
        this.validateCollectionManName = this.modelCollectionMan.collectioMan;
    }

    clickListnerForAreaName(event) {
        this.modelAreaName = event.item;
        //console.log(this.modelAreaName);
        $("#"+this.pageId+"areaNameId").val(this.modelAreaName.godownName);
        this.validateAreaName = this.modelAreaName.godownName;
    }

    getConsigneeDetailsList() {
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
    }
    getConsigneeDetails = () => {
        this.showSpinnerForAction = true;
        this.getConsigneeDetailsList();
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.showSpinnerForAction = false;
                    this.consigneeNameOptions = response;
                    this.consigneePartyNameTA = [];
                    //this.partyMasterDtoForconsigneeAllOption.consigneeName ="All";
                    //this.consigneePartyNameTA.push(this.partyMasterDtoForconsigneeAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneePartyNameTA.push(this.consigneeNameOptions[i]);
                    }
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting party details", "error");
            },
            () => console.log('done');
    };


    getDetailsForCollectionMasterRead() {
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";
    }

    getCollectionManDetailsList() {
        this.showSpinnerForAction = true;
        this.getDetailsForCollectionMasterRead();
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.showSpinnerForAction = false;
                    this.collectionManOptions = response;
                    this.collectionManTA = [];
                    //this.userDtoCollectionManAll.collectioMan = "All";
                    //this.collectionManTA.push(this.userDtoCollectionManAll);
                    for (let i = 0; i < this.collectionManOptions.length; i++) {
                        this.collectionManTA.push(this.collectionManOptions[i]);
                    }
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting collection man details", "error");
            },
            () => console.log('done');
    };

    getMethodForBranchArea() {
        this.lrDtoBranchArea = new LRDto();
        /* if(this.userDataDtoReturnSession.mainStation == "Chennai"){
          this.lrDtoBranchArea.mode = "Binnymills";
         }else{
          this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
         }*/
        this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchArea.companyId = this.userDataDtoReturnSession.companyId;
    }

    getBranchAreaDetailsList() {
        this.showSpinnerForAction = true;
        this.getMethodForBranchArea();
        this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchArea).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.showSpinnerForAction = false;
                        this.areaOptions = response;
                        this.areaNameTA = [];
                        //this.lrDtoBranchAreaAllOption.godownName = "All";
                        //this.areaNameTA.push(this.lrDtoBranchAreaAllOption);
                        for (let i = 0; i < this.areaOptions.length; i++) {
                            this.areaNameTA.push(this.areaOptions[i]);
                        }
                    } else {
                        this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Area Details", "error");
            },
            () => console.log('done');
    };

    searchBtnForOSCashmemoCollection() {
        this.cashMemoDto = new CashMemoDto();
        this.validateFromDates = $("#"+this.pageId+"fromDates").val();
        this.validateToDates = $("#"+this.pageId+"toDates").val();

        if (this.validateFromDates != null && this.validateFromDates != undefined &&
            this.validateToDates != null && this.validateToDates != undefined) {
            if (this.validateFromDates == this.validateToDates) {
                this.cashMemoDto.tillDateMode = "tillDate";
                this.cashMemoDto.fromdate = this.validateFromDates;
                //this.cashMemoDto.sameDate = this.validateFromDates;
            } else {
                this.cashMemoDto.tillDateMode = "notTillDate";
                this.cashMemoDto.fromdate = this.validateFromDates;
                this.cashMemoDto.todate = this.validateToDates;
            }
        }

        if ($("#"+this.pageId+"searchBy").val() != null && $("#"+this.pageId+"searchBy").val() != undefined &&
            $("#"+this.pageId+"searchBy").val() == "dateWise") {
            if ((this.validateFromDates == null) || (this.validateFromDates == undefined) ||
                (this.validateFromDates == "") || (this.validateToDates == null) ||
                (this.validateToDates == undefined) || (this.validateToDates == "")) {
                swal("Mandatory Fields", "From Date And To Date Are mandatory Fields", "warning");
                return false;
            } else {
                this.cashMemoDto.mode = "date";
            }
        } else if ($("#"+this.pageId+"searchBy").val() != null && $("#"+this.pageId+"searchBy").val() != undefined &&
            $("#"+this.pageId+"searchBy").val() == "partyWise") {
            if ((this.validateFromDates == null) || (this.validateFromDates == undefined) ||
                (this.validateFromDates == "") || (this.validateToDates == null) ||
                (this.validateToDates == undefined) || (this.validateToDates == "") ||
                (this.validateConsigneePartyName == null) || (this.validateConsigneePartyName == undefined) ||
                (this.validateConsigneePartyName == "")) {
                swal("Mandatory Fields", "From Date,To Date And Party Name Are Mandatory Fields", "warning");
                return false;
            } else {
                this.cashMemoDto.mode = "party";
                this.cashMemoDto.consigneeName = this.validateConsigneePartyName;
                this.cashMemoDto.consigneeId = this.validateConsigneePartyId;
            }
        } else if ($("#"+this.pageId+"searchBy").val() != null && $("#"+this.pageId+"searchBy").val() != undefined &&
            $("#"+this.pageId+"searchBy").val() == "areaWise") {
            if ((this.validateFromDates == null) || (this.validateFromDates == undefined) ||
                (this.validateFromDates == "") || (this.validateToDates == null) ||
                (this.validateToDates == undefined) || (this.validateToDates == "") ||
                (this.validateAreaName == null) || (this.validateAreaName == undefined) ||
                (this.validateAreaName == "")) {
                swal("Mandatory Fields", "From Date,To Date And Area Name Are Mandatory Fields", "warning");
                return false;
            } else {
                this.cashMemoDto.mode = "area";
                this.cashMemoDto.area = this.validateAreaName;
            }
        } else if ($("#"+this.pageId+"searchBy").val() != null && $("#"+this.pageId+"searchBy").val() != undefined &&
            $("#"+this.pageId+"searchBy").val() == "collectionManWise") {
            if ((this.validateFromDates == null) || (this.validateFromDates == undefined) ||
                (this.validateFromDates == "") || (this.validateToDates == null) ||
                (this.validateToDates == undefined) || (this.validateToDates == "") ||
                (this.validateCollectionManName == null) || (this.validateCollectionManName == undefined) ||
                (this.validateCollectionManName == "")) {
                swal("Mandatory Fields", "From Date,To Date And Collection Man Are Mandatory Fields", "warning");
                return false;
            } else {
                this.cashMemoDto.mode = "collMan";
                this.cashMemoDto.collectionMan = this.validateCollectionManName;
            }
        }
        this.cashMemoDto.destination = this.userDataDtoReturnSession.mainStation;
        this.cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
        this.cashMemoDto.status = "collection";
        //console.log(this.cashMemoDto);
        this.getOSCashmemoCollectionDeptDetailsList();
    }

    getOSCashmemoCollectionDeptDetailsList() {
        this.showSpinnerForAction = true;
        this.memoReport.getOSCashmemoCollectionDeptReport(this.cashMemoDto).subscribe(
            (response) => {
                $("#"+this.pageId+"srdCollectionStockDatataableId").DataTable().destroy();
                this.srdCollectionStockReportDataList = [];
                if (response.length > 0) {
                    //console.log(response);
                    this.showSpinnerForAction = false;
                    this.srdCollectionStockReportDataList = response;
                } else {
                    this.showSpinnerForAction = false;
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerSrdCollectionStockReport.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the OS Cash Memo Collection Man Details", "info");
            },
            () => console.log('done');
    }

    searchBtnForOSCashmemoCollectionFromPartyWise() {
        this.memoDataForPopup = new CashMemoDto();
        this.validateFromDates = this.memoDataFromPartyWiseRptPopup.fromdate;
        $("#"+this.pageId+"fromDates").val(this.validateFromDates);
        $("#"+this.pageId+"toDates").val(this.validateFromDates);
        this.memoDataForPopup.mode = this.memoDataFromPartyWiseRptPopup.mode;
        this.memoDataForPopup.tillDateMode = this.memoDataFromPartyWiseRptPopup.tillDateMode;
        this.memoDataForPopup.fromdate = this.memoDataFromPartyWiseRptPopup.fromdate;
        this.memoDataForPopup.destination = this.userDataDtoReturnSession.mainStation;
        this.memoDataForPopup.companyId = this.userDataDtoReturnSession.companyId;
        this.memoDataForPopup.status = "collection";
        console.log(this.memoDataForPopup);
        this.getOSCashmemoCollectionDeptDetailsListPartyWise();
    }

    getOSCashmemoCollectionDeptDetailsListPartyWise() {
        this.showSpinnerForAction = true;
        this.memoReport.getOSCashmemoCollectionDeptReport(this.memoDataForPopup).subscribe(
            (response) => {
                $("#"+this.pageId+"srdCollectionStockDatataableId").DataTable().destroy();
                this.srdCollectionStockReportDataList = [];
                this.validateFromDates = this.memoDataForPopup.fromdate;
                $("#"+this.pageId+"fromDates").val(this.validateFromDates);
                $("#"+this.pageId+"toDates").val(this.validateFromDates);
                if (response.length > 0) {
                    //console.log(response);
                    this.showSpinnerForAction = false;
                    this.srdCollectionStockReportDataList = response;
                } else {
                    this.showSpinnerForAction = false;
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerSrdCollectionStockReport.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the OS Cash Memo Collection Man Details", "info");
            },
            () => console.log('done');
    }
    customPrintCMSRDStk() {
		if (this.srdCollectionStockReportDataList.length==0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
		localStorage.clear();
		this.cashMemoDtoForCustomPrintList = [];
		this.cashMemoDtoForCustomPrintListColumnNames = ["Memo Number","Memo Date","Total Amount","LR Number","Article","Area","Party Name","Collection Man"];
		this.cashMemoDtoForCustomPrintListColumnWidths = [15,10,15,10,10,10,15,15];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
			this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
			this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
		}

		this.cashMemoDtoForCustomPrintDataList = [];

		this.cashMemoDtoForCustomPrintDataSummaryList = [];
		this.summary1=this.summary2=0;
		for (let i = 0; i < this.srdCollectionStockReportDataList.length; i++) {
			this.cashMemoDtoForCustomPrintData = new CashMemoDto();
			this.cashMemoDtoForCustomPrintListColumnValues = [this.srdCollectionStockReportDataList[i].memoNumber,this.srdCollectionStockReportDataList[i].memoDateStr,this.srdCollectionStockReportDataList[i].grandTotal,this.srdCollectionStockReportDataList[i].lrNumber,this.srdCollectionStockReportDataList[i].articles,this.srdCollectionStockReportDataList[i].area,this.srdCollectionStockReportDataList[i].consigneeName,this.srdCollectionStockReportDataList[i].collectionMan];
			this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
			this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			
			this.summary1=this.summary1+this.srdCollectionStockReportDataList[i].grandTotal;
			this.summary2=this.summary2+this.srdCollectionStockReportDataList[i].articles;
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.srdCollectionStockReportDataList.length,"",this.summary1,"",this.summary2,"","",""];	
		}
			//heading logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.fromDatePrint=this.datePipe.transform(this.cashMemoDto.fromdate, "dd-MM-yyyy");
			this.toDatePrint=this.datePipe.transform(this.cashMemoDto.todate, "dd-MM-yyyy");
			if(this.cashMemoDto.tillDateMode == "tillDate"){
				this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fromDatePrint];
			}else{
				this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fromDatePrint,this.toDatePrint];
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];	
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Cash Memo In SRD Collection Dept");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

}