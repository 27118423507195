import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends

@Component({
	selector: 'app-totalless-lrdetails',
	templateUrl: './totalless-lrdetails.component.html',
	styleUrls: ['./totalless-lrdetails.component.css']
})
export class TotalLessLrDetailsComponent implements OnInit {
	gettingDataFrmServiceFrLrDetailsTable: any;
	lrDetailsDataList: any;
	onDestroyUnsubscribtionLrDetails: Subscription;
	loadingIndicator = true;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLrDetails: Subject<any> = new Subject();

	dataTable: any;
	dtOptionsLrDetails: any;
	dataForPopupFromTransitDetails: any;
	dataForPopupFromTransitOtherDetails: any;

	fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
	showSpinnerForAction = false;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	selectedTotalLessData: any;
	selectedMode: any;
	pageId = "tlrdc";
	constructor(private destStmtReport: DestStmtReport, private router: Router,
		//for modal starts
		public dialogRef: MatDialogRef<TotalLessLrDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef
		//for modal ends
	) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.selectedTotalLessData = JSON.parse(localStorage.getItem('totalLessDataPopup'));
			this.selectedMode = JSON.parse(localStorage.getItem('totalLessMode'));
			if (this.selectedMode == 'statementWise') {
				this.getTotalLessLrStmtAllReport();
			} else {
				this.getTotalLessLrReport();
			}
		}
	}

	ngOnInit(): void {
		this.dtOptionsLrDetails = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnSummaryExcelCashmemoStockSum = null;
						returnSummaryExcelCashmemoStockSum = "  Lr Details ";
						return returnSummaryExcelCashmemoStockSum;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			pagingType: 'full_numbers',
			pageLength: 5,

			processing: true,
			"scrollX": true,
			"scrollY": 330,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var toPay = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var mlessAmtTot = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totAmt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totArt = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actWght = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chgWgt = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total');
				$(api.column(1).footer()).html(data.length);
				$(api.column(4).footer()).html(toPay);
				$(api.column(5).footer()).html(mlessAmtTot);
				$(api.column(6).footer()).html(totAmt);
				$(api.column(7).footer()).html(totArt);
				$(api.column(8).footer()).html(actWght);
				$(api.column(9).footer()).html(chgWgt);

			}
		}
	}

	getTotalLessRptLrDetailsData() {
		this.fortNightStmtDto = new FortNightStmtDto();
		this.fortNightStmtDto.stmtNo = this.selectedTotalLessData.stmtId;
		this.fortNightStmtDto.mode = this.selectedMode;
	}
	getTotalLessLrReport() {
		this.getTotalLessRptLrDetailsData();
		this.showSpinnerForAction = true;
		this.destStmtReport.getLessUncheckedLrDetails(this.fortNightStmtDto).subscribe(
			(response) => {
				$("#" + this.pageId + "lrDetailsId").DataTable().destroy();
				this.lrDetailsDataList = [];
				if (response.length > 0) {
					this.lrDetailsDataList = response;
				} else {
					this.dtTriggerLrDetails.next();
					swal("Total Less LR Report", "No datas found for this information!", "info");
				}

				setTimeout(() => {
					this.dtTriggerLrDetails.next();
				}, 1000);
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Server Error", "Error occurred while getting the details!", "error");
			},
			() => console.log('done');
	}

	getTotalLessRptLrDetailsStmtAllData() {
		this.fortNightStmtDto = new FortNightStmtDto();
		this.fortNightStmtDto.stmtNo = this.selectedTotalLessData.stmtId;
		this.fortNightStmtDto.mode = this.selectedMode;
		this.fortNightStmtDto.reportMode = "StmtLess";
		this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
	}

	getTotalLessLrStmtAllReport() {
		this.getTotalLessRptLrDetailsStmtAllData();
		this.showSpinnerForAction = true;
		this.destStmtReport.getLessUncheckedLrDetailsV2(this.fortNightStmtDto).subscribe(
			(response) => {
				this.lrDetailsDataList = [];
				if (response.length > 0) {
					this.lrDetailsDataList = response;
					setTimeout(() => {
						$("#" + this.pageId + "lrDetailsId").DataTable().destroy();
						this.dtTriggerLrDetails.next();
					}, 1000);
				} else {
					this.dtTriggerLrDetails.next();
					swal("Total Less LR Report", "No datas found for this information!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Server Error", "Error occurred while getting the details!", "error");
			},
			() => console.log('done');
	}


	ngOnDestroy(): void {
		this.dtTriggerLrDetails.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerLrDetails.next();

	}

	//for modal starts
	onCancelClick(): void {
		this.dialogRef.close();
	}
	//for modal ends
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
}
