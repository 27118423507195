
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
@Component({
    selector: 'app-invoice-loadingsheet-print',
    templateUrl: './invoice-loadingsheet-print.component.html',
    styleUrls: ['./invoice-loadingsheet-print.component.css']
})
export class InvoiceLoadingsheetPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    invoiceDto: InvoiceDto = new InvoiceDto();
    listOfLrDto: LRDto[] = [];
    truckNumber: any;
    source: any;
    date: any;
    toStation: any;
    listOfLrDtoDataTable: any = [];
    totalCount: any;
    handleWithCare: string;
    totWgt: number = 0;
    totPay: number = 0;
    address: any;
    image: any;
    pageId="ilsp";
    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.invoiceDto = JSON.parse(localStorage.getItem('loadingSheetBookingForInvoiceDtoPrint'));
            this.listOfLrDto = JSON.parse(localStorage.getItem('loadingSheetBookingForListLrDtoPrint'));
            console.log(this.invoiceDto);
            console.log(this.listOfLrDto);
            this.truckNumber = this.invoiceDto.vehicleNumber == null ? null : this.invoiceDto.vehicleNumber;
            this.source = this.invoiceDto.stocksAt == null ? "" : this.invoiceDto.stocksAt;
            this.date = moment(new Date()).format('DD-MM-YYYY');
            this.toStation = this.invoiceDto.toStation == null ? "" : this.invoiceDto.toStation;
            // this.listOfLrDtoDataTable = this.listOfLrDto;
            this.totalCount = this.listOfLrDto.length;
            this.validatBeforePrint();
            // this.image =this.userDataDtoReturnSession.ip;
            for (let i = 0; i < this.listOfLrDto.length; i++) {
                this.handleWithCare = "";
                if (this.listOfLrDto[i].isHandWithCare
                    && this.listOfLrDto[i].isNewConsignee
                    && this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "HC & NW PRTY & COD";
                } else if (this.listOfLrDto[i].isHandWithCare
                    && this.listOfLrDto[i].isNewConsignee
                    && !this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "HC & COD";
                } else if (!this.listOfLrDto[i].isHandWithCare
                    && this.listOfLrDto[i].isNewConsignee
                    && this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "NW PRTY & COD";
                } else if (this.listOfLrDto[i].isHandWithCare
                    && this.listOfLrDto[i].isNewConsignee
                    && !this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "Hc & NW PRTY";
                } else if (this.listOfLrDto[i].isHandWithCare
                    && !this.listOfLrDto[i].isNewConsignee
                    && !this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "Hc";
                } else if (!this.listOfLrDto[i].isHandWithCare
                    && this.listOfLrDto[i].isNewConsignee
                    && !this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "NW PRTY";
                } else if (!this.listOfLrDto[i].isHandWithCare
                    && !this.listOfLrDto[i].isNewConsignee
                    && this.listOfLrDto[i].isCod) {
                    this.handleWithCare = "COD";
                }
                this.listOfLrDto[i].description = this.handleWithCare;
                this.totWgt = +this.totWgt + +this.listOfLrDto[i].actualWeight;
                this.totPay = +this.totPay + +this.listOfLrDto[i].toPay;

                this.listOfLrDtoDataTable.push(this.listOfLrDto[i]);
            }

            window.setTimeout(function () {
                window.print();
            }, 0);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }




    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
    }

    clearField() {
        this.invoiceDto = new InvoiceDto();
        this.listOfLrDto = [];
        this.truckNumber = "";
        this.source = "";
        this.date = "";
        this.toStation = "";
        this.listOfLrDtoDataTable = [];
        this.totalCount = "";
        this.handleWithCare = "";
        this.totWgt = 0;
        this.totPay = 0;
        this.address = "";
        this.image = "";
        //document.getElementById("loadingsheetprint").innerHTML="";
        //document.all.item("firstPrint").innerHTML ="";
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
