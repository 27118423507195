import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Router } from "@angular/router";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrService } from "src/app/dataService/lr-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';

@Component({
    selector: 'app-cft-range-report',
    templateUrl: './cft-range-report.component.html',
    styleUrls: ['./cft-range-report.component.css']
})
export class CftRangeReportComponent implements OnInit {

    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    defaultGodownName: string;
    dailyBkgReportDataList: any;
    controlMainSource = new FormControl();
    controlMainDestination = new FormControl();
    lrDto: LRDto = new LRDto();
    lrDtoToStation: LRDto = new LRDto();
    lrDtoToRet: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    selectedSource: any;
    selectedDest: any;
    selectedFromDate: any;
    selectedToDate: any;
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    lrDtoToStationAll: LRDto = new LRDto();
    agentDetailsSourceOptions: AgentDetailsDto[];
    selectedLrNumber: any;
    srcForRpt = null;
    dtTriggerDailyBkgReport: Subject<any> = new Subject();
    showSpinnerForAction = false;

    sourceOption: Array<AgentDetailsDto> = [];
    agentDetailsDtoSource: AgentDetailsDto = new AgentDetailsDto();
    public modelDropDownSource: any;
    sourceTA: Array<AgentDetailsDto> = [];
    focusDropDownSourceTA$ = new Subject<string>();
    searchDropDownInputFieldSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownSourceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownSource = (x: { subStation: string }) => x.subStation;

    destinationOption: Array<LRDto> = [];
    lrDtoDestination: LRDto = new LRDto();
    public modelDropDownDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDropDownDestinationTA$ = new Subject<string>();
    searchDropDownInputFieldDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDestination = (x: { godownName: string }) => x.godownName;

    dtOptionsDailyBkgReport: any;
    pageId = "cftrc";

    constructor(private masterReadService: MasterReadService, private router: Router, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "CFT Range Rpt Show Src All") {
                        this.srcForRpt = "Daily Bkg Rpt Show Src All";
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "CFT Range Rpt Show Src Specific") {
                        this.srcForRpt = "Daily Bkg Rpt Show Src Specific";
                    }
                }
            }
            this.getToStationDetailsList();
            if (this.srcForRpt == "Daily Bkg Rpt Show Src All") {
                this.getAgentListDetails('SrcAll');
            } else if (this.srcForRpt == "Daily Bkg Rpt Show Src Specific") {
                this.getAgentListDetails('specific');
            } else {
                this.defaultGodownName = this.userDataDtoReturnSession.office;
                this.sourceOption = [];
                this.sourceTA = [];
                this.agentDtoAll.subStation = this.defaultGodownName;
                this.sourceTA.push(this.agentDtoAll);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsDailyBkgReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totArt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totArtBulky = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgtBulky = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgtBulky = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgtCft = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgtRange = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgtDiff = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var baseFrgt = api.column(20).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamali = api.column(21).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var gcChg = api.column(22).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lcChg = api.column(23).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var bcChg = api.column(24).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(25).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(26).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var insChg = api.column(27).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var others = api.column(28).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var dd = api.column(29).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cod = api.column(30).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total");
                $(api.column(1).footer()).html(data.length);
                $(api.column(7).footer()).html(totArt);
                $(api.column(8).footer()).html(totArtBulky);
                $(api.column(11).footer()).html(actWgtBulky);
                $(api.column(12).footer()).html(chgWgtBulky);
                $(api.column(13).footer()).html(actWgt);
                $(api.column(14).footer()).html(chgWgt);
                $(api.column(15).footer()).html(chgWgtCft);
                $(api.column(16).footer()).html(chgWgtRange);
                $(api.column(17).footer()).html(chgWgtDiff);
                $(api.column(18).footer()).html(topay);
                $(api.column(19).footer()).html(paid);

                $(api.column(20).footer()).html(baseFrgt);
                $(api.column(21).footer()).html(hamali);
                $(api.column(22).footer()).html(gcChg);
                $(api.column(23).footer()).html(lcChg);
                $(api.column(24).footer()).html(bcChg);
                $(api.column(25).footer()).html(fov);
                $(api.column(26).footer()).html(aoc);
                $(api.column(27).footer()).html(insChg);
                $(api.column(28).footer()).html(others);
                $(api.column(29).footer()).html(dd);
                $(api.column(30).footer()).html(cod);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerDailyBkgReport.unsubscribe();
    }

    getToStationDetails() {
        this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
        if (this.srcForRpt == "Daily Bkg Rpt Show Src All") {
            this.lrDtoToStation.mode = "";
        }
    }

    getToStationDetailsList = () => {
        this.getToStationDetails();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.destinationOption = [];
                    this.destinationTA = [];
                } else {
                    this.destinationOption = [];
                    this.destinationTA = [];
                    this.destinationOption = response;
                    this.lrDtoToStationAll.godownName = "ALL";
                    this.destinationTA.push(this.lrDtoToStationAll);
                    for (let i = 0; i < this.destinationOption.length; i++) {
                        this.destinationTA.push(this.destinationOption[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    getAgentListDetails(string) {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        if (string == "SrcAll") {
            this.agentDto.mode = "SrcAllForDlyBkgRpt";
        } else {
            this.agentDto.mode = "mainBranch";
            if (this.srcForRpt == 'Daily Bkg Rpt Show Src Specific') {
                this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
            } else {
                this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
        this.getAgentList();
    }

    getAgentList = () => {
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.sourceOption = [];
                    this.sourceTA = [];
                } else {
                    this.sourceOption = [];
                    this.sourceTA = [];
                    this.sourceOption = response;
                    if (this.srcForRpt == 'Daily Bkg Rpt Show Src All') {
                        this.agentDtoAll.subStation = "ALL";
                        this.sourceTA.push(this.agentDtoAll);
                    } else {
                        this.agentDtoAll.subStation = "ALL";
                        this.sourceTA.push(this.agentDtoAll);
                    }
                    for (let i = 0; i < this.sourceOption.length; i++) {
                        this.sourceTA.push(this.sourceOption[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server Error While Getting Source details", "warning");
            }, () => console.log('done');
    }

    validateBtnSearch() {
        if (($("#" + this.pageId + "dropDownInputFieldSource").val() == null || $("#" + this.pageId + "dropDownInputFieldSource").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldSource").val() == "") || ($("#" + this.pageId + "dropDownInputFieldDestination").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldDestination").val() == undefined) || ($("#" + this.pageId + "dropDownInputFieldDestination").val() == "")) {
            swal("Mandatory Selection", "Please select source and destination to proceed", "warning");
            return false;
        } else if ((($("#" + this.pageId + "fromDate").val() == null || $("#" + this.pageId + "fromDate").val() == undefined) ||
            ($("#" + this.pageId + "fromDate").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
            ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == ""))) {
            swal("Mandatory Selection", "Please select From and To Date to proceed", "warning");
            return false;
        }
        else {
            this.gridReconfigure();
        }
    }

    getSearchMethod() {
        this.selectedSource = $("#" + this.pageId + "dropDownInputFieldSource").val();
        this.selectedDest = $("#" + this.pageId + "dropDownInputFieldDestination").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        this.lrDto = new LRDto();
        this.lrDto.fromDate = this.selectedFromDate;
        this.lrDto.toDate = this.selectedToDate;
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDto.source = this.selectedSource;
        this.lrDto.destination = this.selectedDest;
        this.lrDto.reportMode = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.lrDto.cftRate = this.userDataDtoReturnSession.cftRate;
        this.lrDto.mode = 'Cft Report';
    }

    gridReconfigure = () => {
        this.showSpinnerForAction = true;
        this.getSearchMethod();
        this.lrService.getCftRangeLRBkgDetails(this.lrDto).subscribe(
            (response) => {
                this.dailyBkgReportDataList = [];
                $("#" + this.pageId + "dailyBkgLrsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.dailyBkgReportDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggerDailyBkgReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the LR details", "warning");
            }, () => console.log('done');
    };

    ngAfterViewInit(): void {
        this.dtTriggerDailyBkgReport.next();
    }
    clearAllFields() {
        $("#" + this.pageId + "dailyBkgLrsTableId").DataTable().destroy();
        this.dtTriggerDailyBkgReport.next();
        this.dailyBkgReportDataList = [];
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "dropDownInputFieldSource").val('');
        $("#" + this.pageId + "dropDownInputFieldDestination").val('');
        this.selectedSource = null;
        this.selectedDest = null;
        this.selectedFromDate = null;
        this.selectedToDate = null;
        this.lrDto = new LRDto();
    }
}