<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">

        <div>
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                    <tr>
                        <td style="padding-top: 10px;" width='40%' valign='top'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                            <br> {{address}}</td>

                        <td width='60%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>Collection
														Man&nbsp;:&nbsp;</strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{collectionMan}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <!--<br>-->
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>To
														Party &nbsp;:&nbsp; </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{consignee}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <!--<br>-->
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>Contact Person &nbsp;:&nbsp; </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{contactPerson}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <!--<br>-->
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>Contact Number &nbsp;:&nbsp; </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{contactNumber}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <!--<br>-->
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>Statement
														No&nbsp;:&nbsp; </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{stmtNo}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%' style='text-align: right; font-size: 19px;'><strong>Acknowledgment
														Date&nbsp;:&nbsp; </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000; font-size: 19px;'>
                                                    {{tpAckDate}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <!--<br>-->
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!--<br>-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='text-align: center; font-size: 20px;'>
                    <strong><u>Left To Party Statement Details</u></strong>
                </h6>
            </div>
        </div>
        <br>
        <!--Second  row starts-->
        <table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
            <tr>
                <td width='16%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000; font-size: 19px;' valign='top' type='text'><strong>Memo No</strong></td>
                <td width='12%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 19px;' valign='top' type='number'><strong>Memo Date</strong></td>
                <td width='12%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 19px;' valign='top' type='number'><strong>Assign Date</strong></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 19px;' valign='top' type='number'><strong>Memo Amt</strong></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 19px;' valign='top' type='number'><strong>LR No</strong></td>
                <td width='15%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000; font-size: 19px;' valign='top' type='text'><strong>Consignee Name</strong></td>
            </tr>
            <tr *ngFor="let printData of printDataForLeftToPartyList">
                <td width='16%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000; font-size: 19px;'>
                    {{printData.memoNumber}}</td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    {{printData.memoDateStr}}</td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    <span>{{printData.assignedDateStr}}</span>
                </td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    {{printData.amount}}</td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    {{printData.lrNumber}}</td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000; font-size: 19px;'>
                    {{printData.consigneeName}}</td>
            </tr>
            <tr>
                <td width='16%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000; font-size: 19px;'>
                    <strong> Total : {{countSummary}}</strong>
                </td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                </td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    <strong> </strong>
                </td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    <strong>{{memoAmountSummary}}</strong></td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 19px;'>
                    <strong></strong>
                </td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000; font-size: 19px;'>
                    <strong></strong>
                </td>
            </tr>
        </table>
        <br>
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td height='30' style='font-size: 19px;'>We have received {{countSummary}} cash memos for amounting <strong>
						Rs.{{memoAmountSummary}}</strong>. We will check the account and make the payment at the earliest.
                </td>
            </tr>
            <tr>
                <td height='30' align='right'>&nbsp;</td>
            </tr>
            <tr>
                <td height='30' align='right'><strong>Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong></td>
            </tr>
        </table>
        <br /> <br> <br> <br>
        <table width='35%' border='0' cellpadding='0' cellspacing='0' *ngIf="viewForSelectedDestination">
            <tr>
                <td width='30%' height='40' align='center'><strong><u>BANK
							DETAILS</u></strong></td>
            </tr>
        </table>
        <br>
        <table width='35%' border='0' cellpadding='0' cellspacing='0' *ngIf="viewForSelectedDestination">
            <tr>
                <td width='15%' height='20' align='left' style='border: 1px solid #000;'><strong>&nbsp;A/c
						Name</strong></td>
                <td width='20%' height='20' align='left' style='border: 1px solid #000; border-left: none;'><strong>&nbsp;SRD
						LOGISTICS PVT LTD</strong></td>
            </tr>
            <tr>
                <td width='15%' height='20' align='left' style='border: 1px solid #000;'><strong>&nbsp;A/c
						Number</strong></td>
                <td width='20%' height='20' align='left' style='border: 1px solid #000; border-left: none;'><strong>&nbsp;800120110000291</strong></td>
            </tr>
            <tr>
                <td width='15%' height='20' align='left' style='border: 1px solid #000;'><strong>&nbsp;Bank
						Name</strong></td>
                <td width='20%' height='20' align='left' style='border: 1px solid #000; border-left: none;'><strong>&nbsp;BANK
						OF INDIA</strong></td>
            </tr>
            <tr>
                <td width='15%' height='20' align='left' style='border: 1px solid #000;'><strong>&nbsp;IFSC
						Code</strong></td>
                <td width='20%' height='20' align='left' style='border: 1px solid #000; border-left: none;'><strong>&nbsp;BKID0008001</strong></td>
            </tr>
            <tr>
                <td width='15%' height='20' align='left' style='border: 1px solid #000;'><strong>&nbsp;Branch</strong></td>
                <td width='20%' height='20' align='left' style='border: 1px solid #000; border-left: none;'><strong>&nbsp;MOUNT
						ROAD, CHENNAI - 2</strong></td>
            </tr>
        </table>
    </div>
</div>