<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Commodity Details</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}commodityName">
													<label>Commodity Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-box "></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}commodityCode">
													<label>Commodity Code</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-hashtag"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}saveBtn">Save</button>
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn">Clear</button>
					</div>
				</div>
				<div class="col-md-6 vl">
					<div class="row">
						<!-- column -->
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="box-body">
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsTpMaster"
											[dtTrigger]="dtTriggerTpMaster">

											<thead>
												<tr>
													<th>Commodity Name</th>
													<th>Commodity Code</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let tpMasterData of tpMasterDataList ">
													<td>{{ tpMasterData.commodityName }}</td>
													<td>{{ tpMasterData.commodityCode }}</td>
												</tr>


												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>
										</table>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3"></div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
