import { NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';

import { MasterReadService } from "src/app/dataService/masterread-service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component( {
    selector: 'app-commission-master',
    templateUrl: './commission-master.component.html',
    styleUrls: ['./commission-master.component.css']
} )
export class CommissionMasterComponent implements OnInit {
    commissionMasterDataList: any;
    onDestroyUnsubscribtionCommissionMaster: Subscription;
    model: NgbDateStruct;
    date: { year: number; month: number };

    loadingIndicator = true;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCommissionMaster: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCommissionMaster: any;
    
    dtTriggerSlabDatatable: Subject<any> = new Subject();
    dtOptionSlabDatatable: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    fortNightStmtDtoUserValues: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoSaveUserValues: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoSlabUserValues: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoSaveRet: FortNightStmtDto = new FortNightStmtDto();
    selectedDestination:any;
    initialCommission:any;
    unit:any;
    serviceChg:any;
    slabDatatableDataListUpdated: any;
    slabDatatableDataList: any;
    isSelectedSlab = false;
    selectedIndexSlab:any;
    fromDaysSlab:any;
    toDaysSlab;
    commissionSlab;
    selectedCommissionId:any;
    pageId="commc";
    
    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    
    constructor( private router: Router, private masterReadService: MasterReadService,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.slabDatatableDataList=[];
            this.slabDatatableDataListUpdated =[];
            this.selectedCommissionId = 0;
            this.getDestinationDetails();
            this.getCommissionDetails();
        }

    }

        ngOnInit(): void {
        this.dtOptionsCommissionMaster = {
            dom: 'Bfrtip',
            buttons: [
                      {
                          extend: 'excel',
                          text: '<i class="fas fa-file-excel"> Excel</i>',
                          titleAttr: 'Excel',
                      }
                  ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        	},
                this.dtOptionSlabDatatable = {
                    dom: 'Bfrtip',
                    buttons: [
                    ],
                    processing: true,
                    responsive: true,
                    "scrollX": true,
                    "scrollY": 150,
                    "scrollCollapse": true,
                    "paging": false,
                    "info": false,
                }
    }

    ngOnDestroy(): void {
        this.dtTriggerCommissionMaster.unsubscribe();
        this.dtTriggerSlabDatatable.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerCommissionMaster.next();
        this.dtTriggerSlabDatatable.next();
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                } 
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    }
    
    getUserValues() {
		this.fortNightStmtDtoUserValues = new FortNightStmtDto();
		this.fortNightStmtDtoUserValues.mode = "all";
		this.fortNightStmtDtoUserValues.status = "InUse";
	}
    getCommissionDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.destStmtReport.getAgentCommissionDetails(this.fortNightStmtDtoUserValues).subscribe(
			(response) => {
				$("#"+this.pageId+"commissionDetailsId").DataTable().destroy();
				this.commissionMasterDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.commissionMasterDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerCommissionMaster.next();
	            }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Commission Details", "info");
			}, () => console.log('done');
	};
	
	saveCommissionDetails() {
    	swal({
            title: "Confirm Changes",
            text: "Sure U Want to Save/Update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
            	this.saveUpdateDetails();
            }
        });    	
    }
	
	saveUpdateUserValues() {
		this.fortNightStmtDtoSaveUserValues = new FortNightStmtDto();
		 this.selectedDestination=$("#"+this.pageId+"destinationId" ).val();
		 this.initialCommission=$("#"+this.pageId+"initialCommission" ).val();
		 this.unit=$("#"+this.pageId+"#unit" ).val();
		 this.serviceChg=$("#"+this.pageId+"serviceCharge" ).val();
		
		this.fortNightStmtDtoSaveUserValues.destination = this.selectedDestination;
		this.fortNightStmtDtoSaveUserValues.initialCommission = this.initialCommission;
		this.fortNightStmtDtoSaveUserValues.userId = this.userDataDtoReturnSession.userId;
		this.fortNightStmtDtoSaveUserValues.serviceChg = this.serviceChg;
		this.fortNightStmtDtoSaveUserValues.unit = this.unit;
		this.fortNightStmtDtoSaveUserValues.autoId = this.selectedCommissionId;
		this.fortNightStmtDtoSaveUserValues.companyId = this.userDataDtoReturnSession.companyId;
		this.fortNightStmtDtoSaveUserValues.stmtDtos = this.getSlabValues();
    }
	
	 getSlabValues(){
	        this.slabDatatableDataListUpdated =[];
	        for ( let i = 0; i < this.slabDatatableDataList.length; i++ ) {
	            this.fortNightStmtDtoSlabUserValues = new FortNightStmtDto();
	            this.fortNightStmtDtoSlabUserValues.fromDays = this.slabDatatableDataList[i].fromDays;
	            this.fortNightStmtDtoSlabUserValues.toDays = this.slabDatatableDataList[i].toDays;
	            this.fortNightStmtDtoSlabUserValues.commSlaps = this.slabDatatableDataList[i].commSlaps;
	            this.slabDatatableDataListUpdated.push(this.fortNightStmtDtoSlabUserValues);
	        }
	    return this.slabDatatableDataListUpdated;
	    }
	
	saveUpdateDetails = () => {
        this.saveUpdateUserValues();
        this.showSpinnerForAction = true;
        this.destStmtReport.addAgentCommissionDetails( this.fortNightStmtDtoSaveUserValues ).subscribe(( data ) => {
                this.fortNightStmtDtoSaveRet = data;
                if ( this.fortNightStmtDtoSaveRet.status == "Success" ) {
                    swal( {
                        title: "Success",
                        text: "Commission details saved/updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.showSpinnerForAction = false;
                    this.clearFields();
                    this.getCommissionDetails();
                } else {
                	this.showSpinnerForAction = false;
                    swal( {
                        title: "Error",
                        text: "Error while saving/updating the commission details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
        (error) => {
			this.showSpinnerForAction = false;
			swal("Error", "Server problem occurred while saving/updating the commission details", "error");
		}, () => console.log('done');
    };

	logInPage() {
	      this.router.navigate(['/authentication/login']);
	  }
	
	 rowSelectedEdit( slabDatatableData, index ) {
	        $("#"+this.pageId+"fromValue" ).val( slabDatatableData.fromDays );
	        $("#"+this.pageId+"toValue" ).val( slabDatatableData.toDays );
	        $("#"+this.pageId+"commissionValue" ).val( slabDatatableData.commSlaps);
	        this.isSelectedSlab=true;
	        this.selectedIndexSlab=index;
	    }
	 
	 addInTable(){
		 if (($("#"+this.pageId+"fromValue").val() == null) ||($("#"+this.pageId+"fromValue").val() == undefined) || ($("#"+this.pageId+"fromValue").val() == "")||
				 ($("#"+this.pageId+"toValue").val() == null) ||($("#"+this.pageId+"toValue").val() == undefined) || ($("#"+this.pageId+"toValue").val() == "")||
				 ($("#"+this.pageId+"commissionValue").val() == null) ||($("#"+this.pageId+"commissionValue").val() == undefined) || ($("#"+this.pageId+"commissionValue").val() == "")){
			 swal("Mandatory Field", "Please enter the details to proceed!", "warning");
             return false;
		 }else{
			 this.addInTableSlabs();
	 }
	 }
	 
	 addInTableSlabs(){
		 this.fortNightStmtDtoSlabUserValues = new FortNightStmtDto();
		 this.fromDaysSlab=$("#"+this.pageId+"fromValue" ).val();
		 this.toDaysSlab=$("#"+this.pageId+"toValue" ).val();
		 this.commissionSlab=$("#"+this.pageId+"commissionValue" ).val();
		 if(this.isSelectedSlab==false){
			 for ( let i = 0; i < this.slabDatatableDataList.length; i++ ) {
				 if(this.fromDaysSlab== this.slabDatatableDataList[i].fromDays && this.toDaysSlab== this.slabDatatableDataList[i].toDays &&
						 this.commissionSlab== this.slabDatatableDataList[i].commSlaps){
					 swal("Not Allowed", "Entered slab details already exists!", "info");
					 return;
				 }
			 }
	            this.fortNightStmtDtoSlabUserValues.fromDays = this.fromDaysSlab;
	            this.fortNightStmtDtoSlabUserValues.toDays = this.toDaysSlab;
	            this.fortNightStmtDtoSlabUserValues.commSlaps = this.commissionSlab;
	            this.slabDatatableDataList.push(this.fortNightStmtDtoSlabUserValues);
	         
	         $("#"+this.pageId+"slabTableId").DataTable().destroy();
	         setTimeout(() => {
	             this.dtTriggerSlabDatatable.next();
	             this.clearSlabFields();
	         }, 500);
		 } else{
			 	this.slabDatatableDataList.splice( this.selectedIndexSlab, 1 );
			 	this.fortNightStmtDtoSlabUserValues.fromDays = this.fromDaysSlab;
	            this.fortNightStmtDtoSlabUserValues.toDays = this.toDaysSlab;
	            this.fortNightStmtDtoSlabUserValues.commSlaps = this.commissionSlab;
	            this.slabDatatableDataList.push(this.fortNightStmtDtoSlabUserValues);
         
         $("#"+this.pageId+"slabTableId").DataTable().destroy();
         setTimeout(() => {
             this.dtTriggerSlabDatatable.next();
             this.clearSlabFields();
         }, 500);
		 }
	 }
	 
	 rowSelectedEditCommission( commissionMasterData, index ) {
		 	this.selectedCommissionId=0;
	        $("#"+this.pageId+"destinationId" ).val( commissionMasterData.destination );
	        $("#"+this.pageId+"initialCommission" ).val( commissionMasterData.initialCommission );
	        $("#"+this.pageId+"unit" ).val( commissionMasterData.unit);
	        $("#"+this.pageId+"serviceCharge" ).val( commissionMasterData.serviceChg);
	        this.slabDatatableDataList=[];
	        this.slabDatatableDataList=commissionMasterData.stmtDtos;
	        $("#"+this.pageId+"slabTableId").DataTable().destroy();
	        setTimeout(() => {
	 	        this.dtTriggerSlabDatatable.next();
	         }, 500);
	        this.selectedCommissionId=commissionMasterData.autoId;
	    }
	 
	 clearSlabFields(){
		 this.fromDaysSlab=null;
		 this.toDaysSlab=null;
		 this.commissionSlab=null;
		 $("#"+this.pageId+"fromValue" ).val("");
		 $("#"+this.pageId+"toValue" ).val("");
		 $("#"+this.pageId+"commissionValue" ).val("");
		 this.isSelectedSlab=false;
		 this.selectedIndexSlab=null;
	 }
	 
	 clearFields(){
		 this.clearSlabFields();
		 $("#"+this.pageId+"slabTableId").DataTable().destroy();
		 this.slabDatatableDataList=[];
         this.slabDatatableDataListUpdated =[];
         this.selectedCommissionId = 0;
         this.dtTriggerSlabDatatable.next();
		 $("#"+this.pageId+"destinationId" ).val("");
		 $("#"+this.pageId+"initialCommission" ).val("");
		 $("#"+this.pageId+"unit" ).val("Actual Weight");
		 $("#"+this.pageId+"serviceCharge" ).val("");
		 
		 this.selectedDestination=null;
		 this.initialCommission=null;
		 this.unit=null;
		 this.serviceChg=null;
	 }
}
