<body>
	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Barcode Scanning</h6>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<!-- spinner start-->
									<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<mat-progress-bar mode="indeterminate" style="color: green;">
												</mat-progress-bar>
												<br>
												<h6 class="card-title" align='center'
													style="color: green; margin: auto; font-size: 18px;">
													Please Wait Loading Details.....</h6>
											</div>
										</div>
									</div>
									<!-- spinner end-->
									<div class="col-sm-12 col-md-12"
										*ngIf="viewMessageOfBarcodeScanning">
										<!-- 										<h6 id="{{pageId}}barCodeScanMessage">878787</h6> -->
										<div class="form-group">
											<div class="input-group" style="text-align: center;">
												<h6 style="color: green;">
													<b style="text-align: center;">{{showStatus}}</b>
												</h6>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Scanning Code</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-clipboard"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													id="{{pageId}}inputFieldBarcodeScanning"
													#inputFieldBarcodeScanning onPaste="return true"
													autocomplete="off" [(ngModel)]="modelBarCodeScanningNo"
													[ngModelOptions]="{standalone: true}"
													(keypress)="getBarcodeScanningDetails($event);"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-12" [hidden]="!viewAlreadyReward">
										<h5 class="cstm_efftect" id="{{pageId}}isAlreadyRewardedHeadingId">
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>-->
				</div>
			</div>
		</div>
	</div>

</body>