<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Collection Man Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Collection Name </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}collectionName" aria-describedby="basic-addon11" [disabled]="collectionNameIsReadOnly">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <div class="form-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <textarea class="form-control" rows="2" id="{{pageId}}address" name="address"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>City</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}city" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-phone"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="{{pageId}}contactNumber" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="confirmSave()">Save</button>
                        <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="confirmClearBtn()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl">
                    <div class="row">
                        <!-- column -->
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}collectionManTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCollectionMan" [dtTrigger]="dtTriggerCollectionMan">

                                            <thead>
                                                <tr>
                                                    <th>Collection Man</th>
                                                    <th>Contact Number</th>
                                                    <th>Address</th>
                                                    <th>City</th>
                                                    <th>Action</th>
                                                    <th>Left To Party Block Enable?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let collectionManData of collectionManDataList">
                                                    <td>{{ collectionManData.collectioMan }}</td>
                                                    <td>{{ collectionManData.collManContct }}</td>
                                                    <td>{{ collectionManData.address }}</td>
                                                    <td>{{ collectionManData.city }}</td>
                                                    <td><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" (click)="rowSelected(collectionManData);">
															<i title="Edit CollectionMan" class="fas fa-edit"></i>
														</button>
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}deleteBtn">
															<i title="Remove" class="fas fa-trash"></i>
														</button>
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" (click)="assignMemoForCollectionMan(collectionManData);">
															<i title="Enable Assign" class="fas fa-check"></i>
														</button>
                                                    </td>
                                                    <td>
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" (click)="enableLeftToPartyAckCollectionManConfirm(collectionManData);">
															<i title="Enable Left To Party Block" class="fas fa-check"></i>Enable
														</button>
                                                    </td>
                                                </tr>


                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>