import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-consignor-mobilenumber-confirm',
    templateUrl: './consignor-mobilenumber-confirm.component.html',
    styleUrls: ['./consignor-mobilenumber-confirm.component.css']
})
export class ConsignorMobilenumberConfirmComponent implements OnInit {

    consigneeDataList: any;
    gstNumberDataList: any;
    consigneeNameDataList: any;
    destinationDataList: any;
    partyMasterDtoSearch: PartyMasterDto = new PartyMasterDto();
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    partyMasterDeleteDto: PartyMasterDto = new PartyMasterDto();
    partyMasterDtoCheckRet: any;
    partyMasterDtoSaveRet: any;
    partyMasterDtoDelelteRet: any;
    saveResp: string;


    selectedConsigneeName: any;
    selectedGstNumber: any;
    selectedCity: any;
    loadingIndicator = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    consingeeId = 0;
    gstNumber: any;
    consigneeName: any;
    gstNoConsignee: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSpecificConsigneeDetails: Subject<any> = new Subject();
    dtOptionsSpecificConsigneeDetails: any;
    validateSavePackName: any;
    validateDelete: any;
    pageId = "cmcct";
    showSpinnerForAction = false;
    selectedDestination = null;
    closeResult: string;
    modalRefferenceRejectPopUp: NgbModalRef;
    selectedId = 0;
    enteredRejectedReason = null;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === "" ? this.destinationTA : this.destinationTA.filter((v) => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;

    constructor(private masterService: MasterService, private masterReadService: MasterReadService, private router: Router,
        public changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Consignor Mobile Confirm All") {
                        this.getDestinationDetails();
                    } else {
                        this.destinationTA = [];
                        this.lrDtoDestinationAll = new LRDto();
                        this.lrDtoDestinationAll.destination = this.userDataDtoReturnSession.office;
                        this.destinationTA.push(this.lrDtoDestinationAll);
                    }
                }
            }
        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) {
            el.focus();
        }
    }

    ngOnInit(): void {
        this.dtOptionsSpecificConsigneeDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerSpecificConsigneeDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerSpecificConsigneeDetails.next();
    }

    getConsignorDetailsRead() {
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.partyMasterDtoSearch = new PartyMasterDto();
        this.partyMasterDtoSearch.destination = this.selectedDestination;
    }

    getConsignorMobileNoConfirmList = () => {
        this.getConsignorDetailsRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMobilenoConfirmDetails(this.partyMasterDtoSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.consigneeDataList = response;
                    if (this.consigneeDataList.length == 0) {
                        swal("Not Record", "No details found!", "info");
                    }
                    $("#" + this.pageId + "consigneeDetailsDatatableId").DataTable().destroy();
                    this.dtTriggerSpecificConsigneeDetails.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateConsignorMobileNoConfirm(consigneeData) {
        this.partyMasterDeleteDto = new PartyMasterDto();
        this.partyMasterDeleteDto.mobileNumber = consigneeData.mobileNum;
        this.showSpinnerForAction = true;
        this.masterReadService.validateConsignorMobilenoExistInConsigneeMaster(this.partyMasterDeleteDto).
            subscribe((data) => {
                this.showSpinnerForAction = false;
                if (data.status == "Exist") {
                    swal({
                        title: "Already Exist",
                        text: "This Mobile Number is already exist in Consignee database, sure you want to add/update the same for Consignor also?",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yes) => {
                        if (yes) {
                            this.updateConsignorNoDetails(consigneeData);
                        }
                    });
                } else if (data.status == "NotExist") {
                    this.confirmUpdate(consigneeData);
                } else {
                    swal("Error", "Error while validating the details ", "error");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal("Server Error", "Error while validating the details ", "error");
    }

    confirmUpdate(consigneeData) {
        swal({
            title: "Confirm Mobile Number",
            text: "Sure you want to Confirm the Mobile Number of the selected Consignor?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.updateConsignorNoDetails(consigneeData);
            }
        });
    }

    getUpdateConsignorDetails(consigneeData) {
        this.partyMasterDeleteDto = new PartyMasterDto();
        this.partyMasterDeleteDto.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDeleteDto.id = consigneeData.id;
        this.partyMasterDeleteDto.mode = 'Confirmed';
    }

    updateConsignorNoDetails = (consigneeData) => {
        this.getUpdateConsignorDetails(consigneeData);
        this.showSpinnerForAction = true;
        this.masterReadService.updateConsignorMobilenoConfirmDetailsService(this.partyMasterDeleteDto).
            subscribe((data) => {
                this.showSpinnerForAction = false;
                if (data.status == "success") {
                    swal("Success", "Selected Consignor Mobile Number is confirmed!", "info");
                    this.getConsignorMobileNoConfirmList();
                } else {
                    swal("Error", "Error while updating the details ", "error");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal("Server Error", "Error while updating the details ", "error");
    }

    validateConsignorMobileNoReject() {
        swal({
            title: "Reject Mobile Number",
            text: "Sure you want to reject the Mobile Number updates of the selected Consignor?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.rejectConsignorNoDetails();
            }
        });
    }

    getRejectConsignorMobileNoDetails() {
        this.partyMasterDeleteDto = new PartyMasterDto();
        this.partyMasterDeleteDto.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDeleteDto.id = this.selectedId;
        this.partyMasterDeleteDto.mode = 'Rejected';
        this.enteredRejectedReason = $("#" + this.pageId + "rejectReason").val();
        this.partyMasterDeleteDto.message = this.enteredRejectedReason;
    }

    rejectConsignorNoDetails = () => {
        this.getRejectConsignorMobileNoDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.updateConsignorMobilenoConfirmDetailsService(this.partyMasterDeleteDto).
            subscribe((data) => {
                this.showSpinnerForAction = false;
                if (data.status == "success") {
                    swal("Success", "Selected Consignor Mobile Number updates is rejected!", "info");
                    this.modalService.dismissAll();
                    this.getConsignorMobileNoConfirmList();
                } else {
                    swal("Error", "Error while updating the details ", "error");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal("Server Error", "Error while updating the details ", "error");
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe((response) => {
            if (response) {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    this.lrDtoDestinationAll = new LRDto();
                    this.lrDtoDestinationAll.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAll);
                    // for (let i = 0; i < this.destinationOptions.length; i++) {
                    //     this.destinationTA.push(this.destinationOptions[i]);
                    // }
                }
            }
            this.changeDetectorRef.detectChanges();
        }), (error) => {
            swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
        }, () => console.log("done");
    }

    rejectRequest(rejectPopup, consigneeData) {
        this.selectedId = consigneeData.id;
        this.modalService.open(rejectPopup, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeRejectPopUp() {
        this.modalRefferenceRejectPopUp.close();

    }

}
