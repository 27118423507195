import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
//for datepicker starts
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends

import { NgModule, ViewChild,ChangeDetectorRef} from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends


import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge, Observable } from 'rxjs';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { LrService } from 'src/app/dataService/lr-service';
//for the select option with filter ends
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import *  as moment from 'moment';
export interface DialogData {
    animal: string;
    name: string;
}
//for modal ends

@Component({
    selector: 'app-labour-statement-lr-details.component',
    templateUrl: './labour-statement-lr-details.component.html',
    styleUrls: ['./labour-statement-lr-details.component.css']
})
export class LabourStatementLRDetailsComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    loadingIndicator = true;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    getDataFrmServiceFrTable: any;
    labourStmtLRReportDataList: any;
    onDestroyUnsubscribtionLabourStmtReport: Subscription;

    controlMain = new FormControl();

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLabourStmtLRReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLabourStmtLRReport: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    labourStmtDataLocalStorageGet: any;

    loaderHeadName: any;
    period: any;
    stmtNo: any;

    lrDtoForSearch: LRDto = new LRDto();
    pageId="lslrc";
    
    constructor(private router: Router, private masterReadService: MasterReadService,
        private destStmtReport: DestStmtReport, private lrService: LrService,
        public dialogRef: MatDialogRef<LabourStatementLRDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.labourStmtDataLocalStorageGet = JSON.parse(localStorage.getItem('labourStmtTotalLrsDetails'));
            localStorage.clear();
            console.log(this.labourStmtDataLocalStorageGet);
            if (this.labourStmtDataLocalStorageGet != null &&
                this.labourStmtDataLocalStorageGet != undefined) {
                this.loaderHeadName = this.labourStmtDataLocalStorageGet.loaderHead;
                this.period = this.labourStmtDataLocalStorageGet.fromDate +
                    " To " + this.labourStmtDataLocalStorageGet.toDate;
                this.stmtNo = this.labourStmtDataLocalStorageGet.stmtNo;
                this.setLabourStmtLrDetailsDatatableList();
            }
        }
    }
    ngOnInit(): void {
        this.dtOptionsLabourStmtLRReport = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Un Loading LR Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var toatalArticle = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toatalActWgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toatalTopay = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toatalPaid = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toatalKantaWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(toatalArticle);
                $(api.column(2).footer()).html(toatalActWgt);
                $(api.column(3).footer()).html(toatalTopay);
                $(api.column(4).footer()).html(toatalPaid);
                $(api.column(7).footer()).html(toatalKantaWgt);
            }
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    ngOnDestroy(): void {
        this.dtTriggerLabourStmtLRReport.unsubscribe();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        //this.dtTriggerLabourStmtLRReport.next();
    }

    setLabourStmtLrDetailsDatatableList() {
        this.lrDtoForSearch = new LRDto();
        this.lrDtoForSearch.fortNightStmtNo = this.labourStmtDataLocalStorageGet.stmtNo;
        this.lrDtoForSearch.fromdate = this.labourStmtDataLocalStorageGet.fromPeriod;
        this.lrDtoForSearch.todate = this.labourStmtDataLocalStorageGet.toPeriod;
        this.lrDtoForSearch.loaderName = this.labourStmtDataLocalStorageGet.loaderHead;
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getLrdetailsForLBRHamaliStmtNewUnloadingDetails(this.lrDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.labourStmtLRReportDataList = [];
                $("#"+this.pageId+"labourStmtLRDetails").DataTable().destroy();
                if (response.length > 0) {
                    this.labourStmtLRReportDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                setTimeout(() => {
                    this.dtTriggerLabourStmtLRReport.next();
                    this.showSpinnerForAction = false;
                }, 500);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Statement Report Details", "info");
            }, () => console.log('done');
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

}
