<html>
<head>
<style>

/* for ipad and ipad pro */
@media ( min-width : 768px) and (max-width: 1200px) {
	.col-md-3 {
		flex: 0 0 50% !important;
		max-width: 100% !important;
	}
}
</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Door Delivery</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-4">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-10">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}departFrom">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDate" ngbDatepicker
															#fromDate="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDate.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-10">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															class="form-control" placeholder="yyyy-mm-dd"
															name="toDate" ngbDatepicker #toDate="ngbDatepicker">
														<div class="input-group-append" (click)="toDate.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-10">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}srdPercentage">
														<label>SRD%</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-handshake"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-10">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}hamaliPercentage">
														<label>Hamali%</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-handshake"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-10">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}partyName">
															<label>Party Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select Party" [formControl]="control"
																[appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(partyOptions | filter: control.value) as resultParty">
												<app-option *ngFor="let option of resultParty"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultParty.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>

											<div class="row" style="padding: 10px;">
												<div class="col-sm-12 col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}articles">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-clone"></i>
																</span>
															</div>
															<input type="number" placeholder="Articles"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}srdAmount">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" placeholder="SRD Amt"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}hamaliAmount">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" placeholder="Hamali Amt"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3" style="text-align: center;">
													<button type="submit" class="btn btn_custom btn-success"
														id="{{pageId}}addBtn" placement="left" ngbTooltip="Add">+</button>
													<button type="submit" class="btn btn_custom btn-dark"
														id="{{pageId}}removeBtn" placement="left" ngbTooltip="Remove">x</button>
												</div>
											</div>
											<div class="col-md-12">
												<div class="box-body">
													<!-- 							the first datatble starts -->
													<table datatable
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsPartyDetailsTable"
														[dtTrigger]="dtTriggerPartyDetailsTable">

														<thead>
															<tr>
																<th>Party Name</th>
																<th>Articles</th>
																<th>Amt to SRD</th>
																<th>Amt to Hamali</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let partyDetailsTableData of partyDetailsTableDataList ">
																<td>{{ partyDetailsTableData.partyName }}</td>
																<td>{{ partyDetailsTableData.articles }}</td>
																<td>{{ partyDetailsTableData.amtToSrd }}</td>
																<td>{{ partyDetailsTableData.amtToHamali }}</td>
															</tr>


															<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

														</tbody>
													</table>
												</div>
											</div>

											<!-- 											<div class="col-sm-12 col-md-12"> -->
											<!-- 												<div class="form-group"> -->
											<!-- 													<div class="input-group" id="{{pageId}}statementNumber"> -->
											<!-- 														<label>Statement Number</label> -->
											<!-- 														<div class="input-group-prepend"> -->
											<!-- 															<span class="input-group-text"> <i -->
											<!-- 																class="fas fa-file-alt"></i> -->
											<!-- 															</span> -->
											<!-- 														</div> -->
											<!-- 														<input type="text" class="form-control" -->
											<!-- 															aria-describedby="basic-addon11"> -->
											<!-- 													</div> -->
											<!-- 												</div> -->
											<!-- 											</div> -->








										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}createBtn">Create</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}printBtn">Print</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}printSummaryBtn">Print
								Summary</button>
						</div>
					</div>
					<!-- 							the second datatable starts  -->
					<div class="col-md-4 vl p-t-10">
						<div class="box-body">
							<h6 class="card-title border_bottom">Trip Details</h6>
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsTripDetails"
								[dtTrigger]="dtTriggerTripDetails">

								<thead>
									<tr>
										<th>Area</th>
										<th>No. Of Articles</th>
										<th>DD Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let tripDetailsData of tripDetailsDataList ">
										<td>{{ tripDetailsData.area }}</td>
										<td>{{ tripDetailsData.noOfArticles }}</td>
										<td>{{ tripDetailsData.ddAmount }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
							<!-- 							the second datatable ends  -->
							<!-- 							the third datatable starts  -->

							<div class="p-t-10" [hidden]="vehicleDetailsTable">
								<h6 class="card-title border_bottom">Vehicle Details</h6>
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsVehicleDetails"
									[dtTrigger]="dtTriggerVehicleDetails">

									<thead>
										<tr>
											<th>Vehicle Name</th>
											<th>Driver Name</th>
											<th>No. Of Trips</th>
											<th>No. Of Articles</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let vehicleDetailsData of vehicleDetailsDataList ">
											<td>{{ vehicleDetailsData.vehicleName }}</td>
											<td>{{ vehicleDetailsData.driverName }}</td>
											<td>{{ vehicleDetailsData.noOfTrips }}</td>
											<td>{{ vehicleDetailsData.noOfArticles }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
								</table>
							</div>
							<!-- 						the third datatable ends  -->
						</div>
					</div>
					<div class="col-md-4 p-t-10">
						<div class="box-body">
							<!-- 							the fourth datatable starts  -->

							<div [hidden]="loaderDetailsTable">
								<h6 class="card-title border_bottom">Loader Details</h6>
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsLoaderDetails"
									[dtTrigger]="dtTriggerLoaderDetails">

									<thead>
										<tr>
											<th>Delivery Man</th>
											<th>No. Of Trips</th>
											<th>Articles</th>
											<th>DD Amount</th>
											<th>Labour Hamali</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let loaderDetailsData of loaderDetailsDataList ">
											<td>{{ loaderDetailsData.deliveryMan }}</td>
											<td>{{ loaderDetailsData.noOfTrips }}</td>
											<td>{{ loaderDetailsData.articles }}</td>
											<td>{{ loaderDetailsData.ddAmount }}</td>
											<td>{{ loaderDetailsData.labourHamali }}</td>
											<td>{{ loaderDetailsData.action }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
								</table>
							</div>
							<!-- 							the fourth datatable ends  -->
							<!-- 							the fifth datatable starts  -->

							<div class="p-t-10" >
								<h6 class="card-title border_bottom">Hamali Details</h6>
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsHamaliDetails"
									[dtTrigger]="dtTriggerHamaliDetails">

									<thead>
										<tr>
											<th>Hamali Name</th>
											<th>No. Of Trips</th>
											<th>Articles</th>
											<th>Labour Hamali</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let hamaliDetailsData of hamaliDetailsDataList ">
											<td>{{ hamaliDetailsData.hamaliName }}</td>
											<td>{{ hamaliDetailsData.noOfTrips }}</td>
											<td>{{ hamaliDetailsData.articles }}</td>
											<td>{{ hamaliDetailsData.labourHamali }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
								</table>
							</div>
						</div>
						<!-- 							the fifth datatable ends  -->
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>