<h1 class="title_custom" mat-dialog-title>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<!-- spinner start-->
			<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
				<div class="form-group">
					<div class="input-group">
						<mat-progress-bar mode="indeterminate" style="color: green;">
						</mat-progress-bar>
						<br>
						<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
							Please
							Wait Loading Details.....</h6>
					</div>
				</div>
			</div>
			<!-- spinner end-->
			<div class="card">
				<div class="card-body">
					<div class="box-body">
						<table datatable id="{{pageId}}bookingLessDetailsReportPartyLessTableId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsBookingLessDetailsPartyLessReport"
							[dtTrigger]="dtTriggerBookingLessDetailsPartyLessReport">
							<thead>
								<tr>
									<th>S.No</th>
									<th>Main Station</th>
									<th>Source</th>
									<th>Booking Date</th>
									<th>LR No</th>
									<th>Commodity</th>
									<th>Consignee Name</th>
									<th>Invoice No</th>
									<th>Invoice Date</th>
									<th>Art</th>
									<th>A.Wgt</th>
									<th>C.Wgt</th>
									<th>Bs Frt</th>
									<th>To Pay</th>
									<th>Paid</th>
									<th>FOV Amt</th>
									<th>Discount</th>
									<th>Per Unit</th>
									<th>Rate Type</th>
									<th>Rate</th>
									<th>S.Hamali</th>
									<th>GC</th>
									<th>LC</th>
									<th>BC</th>
									<th>AOC</th>
									<th>DD</th>
									<th [hidden]=true>Remarks</th>
									<th [hidden]=true>SH</th>
									<th [hidden]=true>LC</th>
									<th [hidden]=true>BC</th>
									<th [hidden]=true>AOC</th>
									<th [hidden]=true>DD</th>
									<th [hidden]=true>GC</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let bookingLessDetailsReportData of bookingLessDetailsReportDataList let i = index">
									<td>{{bookingLessDetailsReportData.autoId}}</td>
									<td>{{ bookingLessDetailsReportData.mainstation }}</td>
									<td>{{ bookingLessDetailsReportData.source }}</td>
									<td>{{ bookingLessDetailsReportData.bookingDateStr }}</td>
									<td>{{ bookingLessDetailsReportData.lrNumber }}</td>
									<td>{{ bookingLessDetailsReportData.commodityName }}</td>
									<td>{{ bookingLessDetailsReportData.consigneeName }}</td>
									<td>{{ bookingLessDetailsReportData.invoiceNumber }}</td>
									<td>{{ bookingLessDetailsReportData.invoiceDateStr }}</td>
									<td>{{ bookingLessDetailsReportData.totalArticles }}</td>
									<td>{{ bookingLessDetailsReportData.actualWeight }}</td>
									<td>{{ bookingLessDetailsReportData.chargedWeight }}</td>
									<td>{{ bookingLessDetailsReportData.baseFreight }}</td>
									<td>{{ bookingLessDetailsReportData.toPay }}</td>
									<td>{{ bookingLessDetailsReportData.paid }}</td>
									<td>{{ bookingLessDetailsReportData.riskCharge }}</td>
									<td>{{ bookingLessDetailsReportData.discountAmount }}</td>
									<td>{{ bookingLessDetailsReportData.ratePerUnit }}</td>
									<td>{{ bookingLessDetailsReportData.mode }}</td>
									<!-- <td>{{ bookingLessDetailsReportData.rate }}</td> -->
									<td>{{ bookingLessDetailsReportData.rateStr }}</td>
									<!-- <td>{{ (bookingLessDetailsReportData.hamaliCharge ? 'Yes' : 'No') }}</td> -->
									<td>{{ bookingLessDetailsReportData.srcHamaliChgStr }}</td>
									<!-- <td>{{ bookingLessDetailsReportData.rateGcChg }}</td> -->
									<td>{{ bookingLessDetailsReportData.gcChgStr }}</td>
									<!-- <td>{{ (bookingLessDetailsReportData.riskChargeSet ? 'Yes' : 'No') }}
									</td>
									<td>{{ (bookingLessDetailsReportData.lcCharge ? 'Yes' : 'No') }}</td>
									<td>{{
										(bookingLessDetailsReportData.bcCharge ? 'Yes' : 'No') }}</td>
									<td>{{
										(bookingLessDetailsReportData.aoccharge ? 'Yes' : 'No') }}</td>
									<td>{{
										(bookingLessDetailsReportData.ddcharge ? 'Yes' : 'No') }}</td> -->

									<td>{{ bookingLessDetailsReportData.lcChgStr }}</td>
									<td>{{
										bookingLessDetailsReportData.bcChgStr }}</td>
									<td>{{
										bookingLessDetailsReportData.aocChgStr }}</td>
									<td>{{
										bookingLessDetailsReportData.ddChgStr }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.remarks }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.srcHamali }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.lcChg }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.bcChg }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.AOC }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.ddAmt }}</td>
									<td [hidden]=true>{{ bookingLessDetailsReportData.rateGcChg }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<!-- <td></td> -->
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
									<td [hidden]=true></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!-- 	<button class="btn btn-outline-danger" mat-button -->
	<!-- 		(click)="onCancelClick()">Cancel</button> -->
	<button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>