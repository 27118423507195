import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends



import { DataTableDirective } from "angular-datatables";
import { ActivatedRoute } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { Subject, Subscription, merge } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
    NgbModule,
    NgbTypeaheadSelectItemEvent,
  } from "@ng-bootstrap/ng-bootstrap";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterService } from "src/app/dataService/master-service";

@Component({
  selector: 'app-point-to-point-service-check',
  templateUrl: './point-to-point-service-check.component.html',
  styleUrls: ['./point-to-point-service-check.component.css']
})
export class PointToPointServiceCheckComponent implements OnInit{

    gettingDataFrmServiceFrRankingTable: any;


    RankingDataList: any;


    onDestroyUnsubscribtionRanking: Subscription;



    loadingIndicator = true;
    pageId="ptpsc";


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerRanking: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsRanking: any;
    public departmentsId;
    
    lrDtoUserValue: LRDto = new LRDto();


     ///For Agent Names

  agentNameOptions: AgentDetailsDto[];
  agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
  public modelAgentName: any;
  agentNameTA: Array<AgentDetailsDto> = [];
  focusAgentNameTA$ = new Subject<string>();
  searchAgentName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.agentNameTA
          : this.agentNameTA.filter(
              (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 200)
      )
    );
  };
  agentDto: AgentDetailsDto = new AgentDetailsDto();
  agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
  formatterAgentName = (x: { subStation: string }) => x.subStation;


  ///For Collection Center
   ///For Agent Names

   collectionCenterOptions: AgentDetailsDto[];
   collectionCenterAgentName: AgentDetailsDto = new AgentDetailsDto();
   public modelCollectionCenter: any;
   collectionCenterTA: Array<AgentDetailsDto> = [];
   focusCollectionCenterTA$ = new Subject<string>();
   searchCollectionCenter = (text$: Observable<string>) => {
     const debouncedText$ = text$.pipe(
       debounceTime(200),
       distinctUntilChanged()
     );
     const inputFocus$ = this.focusCollectionCenterTA$;
 
     return merge(debouncedText$, inputFocus$).pipe(
       map((term) =>
         (term === ""
           ? this.collectionCenterTA
           : this.collectionCenterTA.filter(
               (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
             )
         ).slice(0, 200)
       )
     );
   };
   
   formatterCollectionCenter = (x: { subStation: string }) => x.subStation;
 //For Destination
 destinationOptions: LRDto[];
 lrDtoDestination: LRDto = new LRDto();
 public modelDestination: any;
 destinationTA: Array<LRDto> = [];
 focusDestinationTA$ = new Subject<string>();
 searchDestination = (text$: Observable<string>) => {
     const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
     const inputFocus$ = this.focusDestinationTA$;

     return merge(debouncedText$, inputFocus$).pipe(
         map(term => (term === '' ? this.destinationTA
             : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
     );
 }
 formatterDestination = (x: { destination: string }) => x.destination;
 showSpinnerForAction = false;
 destinationDtoForAll: LRDto = new LRDto();
 destinationList: Array<any> = [];



  userDataDtoReturnSession: any;
  superAdminview: boolean = false;
 
  isLoggedIn = true;

  showAgent = true;
  showCollectionCenter=true;
  showDaysTaken= true;
  showHaltingDays=false;
  showDestination=false;
  dtTriggerPointToPointSerChk:any;
  dtOptionsPointToPointSerChk:any;


     //For Custom Print
cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintList: any;
cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
cashMemoDtoForCustomPrintDataList: any;
cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
cashMemoDtoForCustomPrintDataSummaryList: any;
viewCustomPrintV1: boolean;

//For Print Summary
actWghtSummary:number;
chgWghtSummary:number;
netAmtSummary:number;
additionSummary:number;
detuctSummary:number;
paidOnSummary:number;
cashSummary:number;
chequeSummary:number;
pdcSummary:number;

selectedFromStation : any;
selectedToStation : any;
p2pDataList :any;

    constructor( private rankingRpt: ReportService,
        private route: ActivatedRoute,
        private masterReadService: MasterReadService,
        private router: Router,private masterService : MasterService , public changeDetectorRef : ChangeDetectorRef) {

            
                if (sessionStorage.length == 0) {
                  this.isLoggedIn = false;
                  swal({
                    title: "Session Expired",
                    text: "Please relogin to access the application!",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                  }).then(() => {
                    this.logInPage();
                  });
                }
            
              // Rights For Agent
                if (this.isLoggedIn) {
                    this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                           }
            this.getAgentDetailList();  
            this.getDestinationDetails();
            setTimeout(() => {
            	this.getDetails("All");
            }, 1000);
    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
      }
    

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }


    ngOnInit(): void {


        this.dtOptionsPointToPointSerChk = {
          dom: 'Bfrtip',
          buttons: [
    
    
          ],
          processing: true,
          //scroll in datatable starts
          responsive: true,
          "scrollX": false,
          "scrollY": 300,
          "scrollCollapse": true,
          "paging": false,
          "info": false,
          "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;
            // converting to interger to find total
            var intVal = function (i) {
              return typeof i === 'string' ?
                +i.replace(/[\$,]/g, '') * 1 :
                typeof i === 'number' ?
                  i : 0;
            };
    
          }

        }

    }


    ngOnDestroy(): void {
        this.dtTriggerPointToPointSerChk.unsubscribe();
    }
    ngAfterViewInit(): void {
      this.dtTriggerPointToPointSerChk.next();
  }

    searchByMode( searchBy: string ) {
     
      if ( searchBy === 'fromAgent' ) {
         
          this.showAgent = true;
          this.showCollectionCenter=true;
          this.showDaysTaken= true;
          this.showHaltingDays=false;
          this.showDestination=false;
         

      } else if ( searchBy === 'atSource' ) {
         
        this.showAgent = false;
     
        this.showCollectionCenter=true;
          this.showDaysTaken= false;
          this.showDestination=false;
        this.showHaltingDays=true;
      }else if ( searchBy === 'toDestination' ) {
       
        this.showAgent = false;
        this.showCollectionCenter=true;
        this.showDaysTaken= true;
          this.showHaltingDays=false;
          this.showDestination=true;
        
       
    }else{
      this.showAgent = false;
      this.showCollectionCenter=true;
      this.showDaysTaken= true;
      this.showDestination=false;
      this.showHaltingDays=true;
    }
  }

 //For Agent Name Listener
 clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
    this.modelAgentName = e.item;
    $("#"+this.pageId+"agentName").val(this.modelAgentName.subStation);
  }
  //For Agent Names
  getUserValuesForAgentDetailsList() {
   this.agentDto.column2="Working";
      this.agentDto.mode = "Sub";
      this.agentDto.status = "All";
    
      
        this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      
    
    this.agentDto.companyId = this.userDataDtoReturnSession.companyId;

    
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getSourceListDetails(this.agentDto)
      .subscribe((response) => {
        this.agentNameTA = [];
        this.agentNameOptions = [];
        if (response.length > 0) {
          this.agentNameOptions = response;
          for (let i = 0; i < this.agentNameOptions.length; i++) {
            this.agentNameTA.push(this.agentNameOptions[i]);
            this.collectionCenterTA.push(this.agentNameOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the Source Details",
          "info"
        );
      },
      () => console.log("done");
  };
    //For Destination Listener
clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
  this.modelDestination = e.item;
  $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
}
getDestinationMethod() {
  this.lrDtoDestination = new LRDto();
   this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
   ///Mode Added
   this.lrDtoDestination.mode="desttrsp";
}

  getDestinationDetails() {
      this.getDestinationMethod();
      this.showSpinnerForAction = true;
      this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
       
          (response) => {
            console.log(response);
              this.showSpinnerForAction = false;
              if (response) {
                  //this.stationOptions = response;
                  if (response.length > 0) {
                      this.destinationOptions = response;
                      this.destinationTA = [];
                     
                    

                      this.destinationList = [];
                      for (let i = 0; i < this.destinationOptions.length; i++) {
                          this.destinationTA.push(this.destinationOptions[i]);
                          this.destinationList.push(this.destinationOptions[i].destination);
                      }

                  }
              }
              this.changeDetectorRef.detectChanges();
          }), (error) => {
              this.showSpinnerForAction = false;
              swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
          },
          () => console.log('done');

  };  
  
  getDetails = (mode) => {
		this.showSpinnerForAction = true;
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.mode = mode;
		this.lrDtoUserValue.fromStation = this.selectedFromStation;
		this.lrDtoUserValue.toStation = this.selectedToStation;
		
		this.masterService.getPoint2PointSCDetails(this.lrDtoUserValue).subscribe(
			(response) => {
				$("#"+this.pageId+"p2pTableId").DataTable().destroy();
				this.p2pDataList = [];
				if (response.length == 0) {
					swal({
						title: "Point To Point Service Check Detail",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.p2pDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerPointToPointSerChk.next();
	            }, 1000);
				this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	rowSelectedEdit(p2pData){
		
	}
	
	confirmDelete(p2pData){
		swal({
            title: "Confirmation",
            text: "Sure you want to delete the selected details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.deleteDetails(p2pData);
            }
        })
	}
	
	deleteDetails(p2pData){
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.fromStation = p2pData.fromStation;
		this.lrDtoUserValue.toStation = p2pData.toStation;
		
		this.masterService.deleteOurBranchDetails(this.lrDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "Selected details deleted successfully!", "info");
					this.clearFields();
					this.getDetails("All");
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to delete the details!", "info");
				}
				this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while deleting the details", "info");
			}, () => console.log('done');
	}
	
	clearFields(){
		this.lrDtoUserValue = new LRDto();
		this.selectedFromStation = null;
		this.selectedToStation = null;
	}

//For Custom Print
customPrintAgentRankingRpt() {
    if (this.RankingDataList.length == 0) {
        swal({
            title: "No records found to print",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    }
     else {
        localStorage.clear();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name","Actual Weight","Charged Weight","Net Amount","Addition","Deduction","Paid ON","Cash","Cheque","Pdc","Remarks"];
        this.cashMemoDtoForCustomPrintListColumnWidths = [15,8,8,8,10,8,8,9,8,8,10];
        for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
            this.cashMemoDtoForCustomPrint = new CashMemoDto();
            this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
            this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
            this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
        }
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];

    this.actWghtSummary=this.chgWghtSummary=this.netAmtSummary=this.additionSummary=this.detuctSummary=this.cashSummary=this.chequeSummary=this.pdcSummary=0;
        
        for (let i = 0; i < this.RankingDataList.length; i++) {
            this.cashMemoDtoForCustomPrintListColumnValues = [this.RankingDataList[i].agentName,this.RankingDataList[i].actualWeight, 
            this.RankingDataList[i].chargedWeight, this.RankingDataList[i].netAmount,
            this.RankingDataList[i].addition,this.RankingDataList[i].deduction,this.RankingDataList[i].paidOn,this.RankingDataList[i].cash,this.RankingDataList[i].cheque,
            this.RankingDataList[i].pdc,this.RankingDataList[i].remarks,];
            
            /////////
          
            this.actWghtSummary=this.actWghtSummary+this.RankingDataList[i].actualWeight;
            this.chgWghtSummary=this.chgWghtSummary+this.RankingDataList[i].chargedWeight;
            this.netAmtSummary=this.netAmtSummary+this.RankingDataList[i].netAmount;
            this.additionSummary=this.additionSummary+this.RankingDataList[i].addition;
            this.detuctSummary=this.detuctSummary+this.RankingDataList[i].deduction;
            this.cashSummary=this.cashSummary+this.RankingDataList[i].cash;
            this.chequeSummary=this.chequeSummary+this.RankingDataList[i].cheque;
            this.pdcSummary=this.pdcSummary+this.RankingDataList[i].pdc;            

            
            /////
            this.cashMemoDtoForCustomPrintData = new CashMemoDto();
            this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
            this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

        }
        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.RankingDataList.length,this.actWghtSummary,this.chgWghtSummary,this.netAmtSummary,
        this.additionSummary,this.detuctSummary,"",this.cashSummary,this.chequeSummary,this.pdcSummary,""];
    
        localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
        localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
        localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
        localStorage.setItem('printTitle', "Agent Ranking Report");
        this.viewCustomPrintV1 = true;

        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCustomPrintV1) {
                this.viewCustomPrintV1 = false;
                localStorage.clear();
            }
        });

    }
}
}
