import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends

@Component( {
    selector: 'app-source-stocks-summary',
    templateUrl: './source-stocks-summary.component.html',
    styleUrls: ['./source-stocks-summary.component.css']
} )
export class SourceStocksSummaryComponent implements  OnInit {

    getDataSourceStocksSummaryDataTable: any;

    sourceStocksSummaryDataList: any;

    onDestroyUnsubscribtionSourceStocksSummary: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSourceStocksSummary: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsSourceStocksSummary: any;

pageId="sstsc";
    constructor( private sourceStocksSummaryScreen: DashboardService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    ngOnInit(): void {
        this.dtOptionsSourceStocksSummary = {
        		dom: 'Bfrtip',
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                buttons: [
      			],
                searching: true,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerSourceStocksSummary.unsubscribe();

    //     this.onDestroyUnsubscribtionSourceStocksSummary.unsubscribe();
    // }

}
