
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";

@Component( {
    selector: 'custom-bangalore-ofc-print',
    templateUrl: './custom-bangalore-ofc-print.component.html',
    styleUrls: ['./custom-bangalore-ofc-print.component.css']
} )
export class CustomBangaloreOfcPrintComponent implements OnInit {
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    dataForPrintAssignMemo: any;
    dataForPrintAssignMemoList: any;
    collectionMan:any;
    totalAmount:number;
    pfCollectionManDetailsDataList:any;
    totalCount:any;
    memoAmount:number;
    address:any;
    image:any;
    
    selectedDate: any;
    agetnsDueDetailsDataListCustomPrint: any;
    bankMasterDataListCustomPrint: any;
    pdcDetailsDataListCustomPrint:any;
    fortNightStmtDtoSummaryPrint: FortNightStmtDto = new FortNightStmtDto();
    totalAgentDuesCount:any;
    
    printTitle:any;
    
    dataForPrintGCTakenMemoList: any;
    showAssignDate=false;
    columnsCount:any;
    lastStmtDueDate:any;
    curStmtDueDate:any;
    pageId="cbopc";

    constructor( private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
        }
        if ( this.isLoggedIn ) {

            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            
            this.printTitle=localStorage.getItem('printTitle');
			this.agetnsDueDetailsDataListCustomPrint = JSON.parse( localStorage.getItem( 'agetnsDueDetailsDataListCustomPrint' ) );
			this.bankMasterDataListCustomPrint = JSON.parse( localStorage.getItem( 'bankMasterDataListCustomPrint' ) );
			this.pdcDetailsDataListCustomPrint = JSON.parse( localStorage.getItem( 'pdcDetailsDataListCustomPrint' ) );
			this.fortNightStmtDtoSummaryPrint = JSON.parse( localStorage.getItem( 'fortNightStmtDtoSummaryPrint' ) );
            this.selectedDate=localStorage.getItem('selectedDate');
            this.selectedDate = this.datePipe.transform( this.selectedDate, "dd-MM-yyyy" );
            
            if(this.agetnsDueDetailsDataListCustomPrint.length>0){
            	this.lastStmtDueDate = this.agetnsDueDetailsDataListCustomPrint[0].currentDueDateStr;
            	this.curStmtDueDate = this.agetnsDueDetailsDataListCustomPrint[0].lastDueDateStr;
            }
            
            this.image =this.userDataDtoReturnSession.ip;
            

        	this.validatBeforePrint();
            window.setTimeout( function() {
       		window.print();
            	}, 1000 );
            window.onafterprint = function() {
               window.close();
            }
            localStorage.clear();
            window.addEventListener( 'afterprint', ( event ) => {
                this.clearField();
            } );
        }
    }

    ngOnInit() {

    }
    
    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
    }
   
    clearField() {
       this.address='';
       this.dataForPrintAssignMemo='';
       this.dataForPrintAssignMemoList='';
       this.collectionMan='';
       this.totalAmount=0;
       this.pfCollectionManDetailsDataList='';
       this.totalCount=0;
       this.memoAmount=0;
	   this.agetnsDueDetailsDataListCustomPrint=null;
	   this.bankMasterDataListCustomPrint=null;
	   this.pdcDetailsDataListCustomPrint=null;
	   this.selectedDate=null;
	   this.lastStmtDueDate = null;
	   this.curStmtDueDate = null;
    }
    
    calculateSummary(){
    	
    }


    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
}
