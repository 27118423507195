<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12">
                                                    <h6 class="card-title">Rate Details 1</h6>
                                                </div>

                                                <div class="col-sm-12 col-md-12" *ngIf="viewDestination">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Destination</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="consigneeDestListener($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                    (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Consignee Name<span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="selectedConsignee($event)"
                                                                [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showCneeDtlsTxt" class="col-sm-12 col-md-12" style="text-align: center;">
                                                    <h4> {{consigneeNameTxt}}</h4>
                                                    <h5> {{consigneeGstTxt}}</h5>
                                                    <h6> {{consigneeAddressTxt}}</h6>
                                                    <h6> Area : {{consigneeAreaTxt}}</h6>
                                                    <h6> Collection Man : {{consigneeCollectionManTxt}}</h6>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <!-- <label>Source<span class="mandatoryField_Style"> * </span></label> -->
                                                    <table datatable id="{{pageId}}sourceId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSourceList" [dtTrigger]="dtTriggerSourceList">

                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Source<span class="mandatoryField_Style"> * </span>
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody style="height: 40vh;">
                                                            <tr *ngFor="let sourceListData of sourceListDataList; let i of index">
                                                                <td><input type="checkbox" [checked]="multiSelect" id='{{sourceListData.agentCode}}' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, sourceListData)" />
                                                                </td>
                                                                <td>{{ sourceListData.source }}</td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Commodity<span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-warehouse"></i>
																</span>
                                                            </div>
                                                            <input #CommodityMainUnldg id="{{pageId}}commodity" type="text" placeholder="Select Commodity" class="form-control" [(ngModel)]="modelCommodity" [ngbTypeahead]="searchCommodity" [resultFormatter]="formatterCommodity" [inputFormatter]="formatterCommodity"
                                                                (focus)="focusCommodity$.next($any($event).target.value)" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 											the fourth autocomplete ends -->

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unit<span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-weight"></i>
																</span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}unitHideShow" name="unitHideShow" #unitHideShow (change)="unitMethod(unitHideShow.value)">
																<option selected value="Select Unit">Select.....
																</option>
																<option value="Rate">Rate</option>
																<option value="Discount">Discount</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="unitRate" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Rate <span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" #rate id="{{pageId}}rate" [(ngModel)]="modelRate" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="unitDiscount" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>On<span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-file-alt"></i>
																</span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}On">
																<option selected value="onLr">Lr</option>
																<option value="onTotal">Total</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="unitDiscount" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Rate (%)<span class="mandatoryField_Style"> *
																</span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="{{pageId}}ratePercentage" [(ngModel)]="modelRatePercentage" #ratePercentage aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12" style="padding-top:10px">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unloading Charge(DD) </label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input #unloadingChg type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChg" name="unloadingChg" [(ngModel)]="onLoadUnloadingChg">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unit(DD)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-weight"></i>
																</span>
                                                            </div>
                                                            <select #unit class="custom-select col-12" id="{{pageId}}unit" name="unit" [(ngModel)]="onLoadUnit">
																<option value="">Select.....</option>
																<option value="Article">Article</option>
																<option value="Actual Weight">Actual Weight</option>
																<option value="Charged Weight">Charged Weight</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unloading Charge(GD) </label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input #unloadingChg type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChgGodownDelivery" name="unloadingChgGodownDelivery" [(ngModel)]="onLoadUnloadingChgGodownDelivery">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unit(GD)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-weight"></i>
																</span>
                                                            </div>
                                                            <select #unit class="custom-select col-12" id="{{pageId}}unitGodownDelivery" name="unitGodownDelivery" [(ngModel)]="onLoadUnitGodownDelivery">
																<option value="">Select.....</option>
																<option value="Article">Article</option>
																<option value="Actual Weight">Actual Weight</option>
																<option value="Charged Weight">Charged Weight</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Rate Details 2</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Hamali Src</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}hamaliSrc" #hamaliSrc aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>G.C Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="{{pageId}}gcCharge" #gcCharge aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Hamali Dest (DD)</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}hamaliDestination" #hamaliDestination aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Receipt Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="rmcptreciptCharge" #reciptCharge aria-describedby="basic-addon11" [(ngModel)]="onLoadRcChg">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Service Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
														</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="rmcptserviceCharge" #serviceCharge aria-describedby="basic-addon11" [(ngModel)]="onLoadScChg">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Per Unit<span class="mandatoryField_Style"> * </span></label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}perUnit" #perUnit>
														<option selected>Select.....</option>
														<option value="Article">Article</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Chargable Weight">Chargable Weight</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>F.O.V<span class="mandatoryField_Style"> * </span></label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}fov">
														<option selected value="yes">Yes</option>
														<option value="no">No</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}ddRateHamaliLessCheckbox" (change)="toggleddRateHamaliLessCheckbox($event)"> <label class=" custom-control-label wtfull " for="{{pageId}}ddRateHamaliLessCheckbox">
                                                        Net Rate DD Hamali Less</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Status</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}status" #status>
														<option selected>Select.....</option>
														<option value="Cash">Cash</option>
														<option value="Account">Acount</option>
														<option value="Delivery">Delivery</option>
													</select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed By<span class="mandatoryField_Style"> * </span></label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
														</span>
                                                    </div>
                                                    <input #fixedBy id="{{pageId}}fixedBy" type="text" placeholder="Select Fixed By" class="form-control" [(ngModel)]="modelFixedBy" [ngbTypeahead]="searchFixedBy" [resultFormatter]="formatterFixedBy" [inputFormatter]="formatterFixedBy" (focus)="focusFixedBy$.next($any($event).target.value)"
                                                        autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Effect From<span class="mandatoryField_Style"> *
														</span></label> <input class="form-control" placeholder="yyyy-mm-dd" name="effectFroms" ngbDatepicker #effectFroms="ngbDatepicker" id="{{pageId}}effectFrom">
                                                    <div class="input-group-append" (click)="effectFroms.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed On<span class="mandatoryField_Style"> * </span></label>
                                                    <input id="{{pageId}}fixedOn" class="form-control" placeholder="yyyy-mm-dd" name="fixedOns" ngbDatepicker #fixedOns="ngbDatepicker" [(ngModel)]="tdyFixedOnDate">
                                                    <div class="input-group-append" (click)="fixedOns.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <h6 class="card-title">Other Charge Setup - Door Delivery</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-home"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}otherChargeArea" type="text" class="form-control" (selectItem)="clickListnerForOtherChargeArea($event)" [(ngModel)]="modelOtherChargeArea" [ngbTypeahead]="searchOtherChargeArea" [resultFormatter]="formatterOtherChargeArea" [inputFormatter]="formatterOtherChargeArea"
                                                            (focus)="focusOtherChargeAreaTA$.next($any($event).target.value)" placeholder="Select the Area" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-weight"></i>
															</span>
                                                        </div>
                                                        <select #basedOn class="custom-select col-12" id="{{pageId}}basedOnId" name="basedOnId" placeholder="Based On">
															<option value="">Select.....</option>
															<option value="Article">Article</option>
															<option value="Lumpsum">Lumpsum</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <input #otherChgAmt type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}otherChargeAmountId" name="otherChargeAmountId">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-md-2" style="display:none;">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #hiddenIndexOtherCharges type="number" id="{{pageId}}hiddenIndexOtherChgId" name="hiddenIndexOtherChgId" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <a href="javascript:;" type='button' id="{{pageId}}addInTableOtherChargeBtnId" #addBtn style="padding: 1px 4px;" class="btn btn-icon-only yellow" (click)="addInTableOtherCharge();">
                                                            <i class="fa fa-plus" title="Add"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <!-- <div class="col-sm-12 col-md-12">
                                                <div class="box-body" style="text-align: center;">
                                                    <table datatable id="{{pageId}}otherChargeDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsOtherChargeDatatable" [dtTrigger]="dtTriggerOtherChargeDatatable">
                                                        <thead>
                                                            <tr>
                                                                <th>Area</th>
                                                                <th>Based On</th>
                                                                <th>Charges</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let editOtherChargeData of gridTosetOtherChargeList; let i = index" (click)="rowSelectedOtherChargeDataOnEdit(editOtherChargeData,i);">
                                                                <td>{{ editOtherChargeData.areaOfDelivery }}</td>
                                                                <td>{{ editOtherChargeData.ratePerUnit }}</td>
                                                                <td>{{ editOtherChargeData.chqAmt }}</td>
                                                                <td>
                                                                    <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmOtherChgAreaDelete(editOtherChargeData)">
                                                                    <i title="Delete" class="fas fa-trash"></i>
                                                                </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <!-- (selectItem)="clickListnerForCommodityMainUnldg($event)" -->
                                            <div class="col-sm-12 col-md-12">
                                                <h6 class="card-title">Commodity</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-7">

                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #CommodityMainUnldg id="{{pageId}}CommodityMainUnldgId" type="text" class="form-control" [(ngModel)]="modelCommodityMainUnldg" [ngbTypeahead]="searchCommodityMainUnldg" [resultFormatter]="formatterCommodityMainUnldg" [inputFormatter]="formatterCommodityMainUnldg"
                                                                (focus)="focusCommodityMainUnldgTA$.next($any($event).target.value)" placeholder="Commodity" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="number" id="{{pageId}}unldnChg" placeholder="Unldg Chg" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <select class="custom-select col-12" id="{{pageId}}unitId">
															<option selected value="Unit">Unit</option>
															<option value="Article">Article</option>
															<option value="Actual Weight">Actual Weight</option>
															<option value="Chargable Weight">Chargable Weight
															</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <a href="javascript:;" type='button' #addBtn id="{{pageId}}addCommodityPlusBtnId" class="btn btn-icon-only yellow" (click)="addCommodityDetailsTable();">
                                                    <i class="fa fa-plus" title="Add"></i>
                                                </a>
                                                <a href="javascript:;" type='button' #addBtn class="btn btn-icon-only yellow" (click)="clearCommodityDetailsTable();">
                                                    <i class="fa fa-trash" title="Clear"></i>
                                                </a>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}unldgCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodity" [dtTrigger]="dtTriggerCommodity">

                                                        <thead>
                                                            <tr>
                                                                <th>Commodity</th>
                                                                <th>Unldg Chg</th>
                                                                <th>Unit</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let commodityData of commodityDataList let i=index">
                                                                <td>{{ commodityData.commodity }}</td>
                                                                <td>{{ commodityData.unldgChg }}</td>
                                                                <td>{{ commodityData.unit }}</td>
                                                                <td>
                                                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedEditUnldgCommodityDeatils(commodityData,i);">
																		<i title="Edit Unldg Commodity Details"
																			class="fas fa-pencil-alt"></i>
																	</button> &nbsp;&nbsp;
                                                                    <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="deleteRowSelectedUnldgCommodity(commodityData,i)">
																		<i title="Remove" class="fas fa-trash"></i>
																	</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="text-align: center; margin: 5px;">
                        <!-- <button type="submit" *ngIf="showSaveBtn" class="btn btn-success m-r-10"
							(click)="validationOnSave()" id="{{pageId}}saveBtn">Save</button> -->
                        <button type="submit" *ngIf="showSaveBtn" class="btn btn-success m-r-10" (click)="validationOnSave()" id="{{pageId}}saveBtn">Save</button>
                        <button type="submit" *ngIf="showUpdateBtn" class="btn btn-success m-r-10" (click)="confirmUpdate()" id="{{pageId}}updateBtn">Update</button>
                        <button type="submit" class="btn btn-dark m-r-10" (click)="confirmReset()" id="{{pageId}}resetBtn">Reset</button>
                        <button type="submit" *ngIf="showNewRateBtn" class="btn btn-success m-r-10" id="{{pageId}}newRate" (click)="newRateMtd()">New Rate</button>
                        <!-- <button type="submit" class="btn btn-primary m-r-10" id="{{pageId}}ddUpdateBtnId"
							(click)="ddUpdateMtd(ddUpdatePopUpTemplate)">DD Update</button> -->
                    </div>
                </div>
                <div class="col-md-6 vl">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #agentNameRptSearch id="{{pageId}}agentNameRptSearch" type="text" class="form-control" [(ngModel)]="agentNameRptSearchModal" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchRptSearchTA" [resultFormatter]="formatterAgentNameRptSearch"
                                                        [inputFormatter]="formatterAgentNameRptSearch" placeholder="Select Source" (focus)="focusAgentNameRptSearchTA$.next($any($event).target.value)" #instanceAgentNameRptSearch="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input #CommodityList id="{{pageId}}commodityListIdSearchRpt" type="text" class="form-control" [(ngModel)]="modelCommodityListSearchRpt" [ngbTypeahead]="searchCommodityListSearchRpt" [resultFormatter]="formatterCommodityListSearchRpt" [inputFormatter]="formatterCommodityListSearchRpt"
                                                    (focus)="focusCommodityListSearchRptTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consigneeIndex id="{{pageId}}consigneeIndex" name="consigneeIndex" type="text" class="form-control" placeholder="Select Consignee Index" (selectItem)="rowSelectedConsigneeIndex($event)" [ngbTypeahead]="consigneeIndexSearchTA" (focus)="focusConsigneeIndexTA$.next($any($event).target.value)"
                                                        #instanceConsigneeIndex="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2" style="text-align: center">
                                        <button type="submit" (click)="getRateDetails();" class="dt-button" style="margin-left: -70%;font-weight:bold;" id="{{pageId}}commodityRateBtn">Search</button>
                                    </div>

                                    <div class="col-md-2">
                                    </div>

                                    <div class="col-md-10" style="text-align: center">
                                        <button type="submit" class="dt-button" style="font-weight:bold;" id="{{pageId}}rateIncreaseBtnId" (click)="rateIncreaseUpdateMtd()">Rate Increase</button>
                                        <button type="submit" class="dt-button" style="font-weight:bold;" id="{{pageId}}gdUpdateBtnId" (click)="gdUpdateMtd(gdUpdatePopUpTemplate)">GD Update</button>
                                        <button type="submit" class="dt-button" style="font-weight:bold;" id="{{pageId}}ddUpdateBtnId" (click)="ddUpdateMtd(ddUpdatePopUpTemplate)">DD Update</button>
                                        <button type="submit" class="dt-button" style="font-weight:bold;" id="{{pageId}}areaOtherChgUpdateBtnId" (click)="areaOtherChgUpdateMtd(areaOtherChgUpdatePopUpTemplate)">Other Charge Update</button>
                                    </div>

                                    <!-- <div class="col-md-4" style="text-align: center">
                                        <button type="submit" class="dt-button" style="margin-left: -70%;font-weight:bold;" id="{{pageId}}areaOtherChgUpdateBtnId" (click)="areaOtherChgUpdateMtd(areaOtherChgUpdatePopUpTemplate)">Other Charge Update</button>
                                    </div> -->
                                </div>
                                <div class="box-body">
                                    <table datatable id="{{pageId}}consigneeTabelId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateMaster" [dtTrigger]="dtTriggerRateMaster">

                                        <thead>
                                            <tr>
                                                <th [hidden]=true>Consignee</th>
                                                <th>Source</th>
                                                <th>Commodity</th>
                                                <th>Rate</th>
                                                <th>Unit</th>
                                                <th>F O V</th>
                                                <th>Effective From</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let consigneeData of consigneeDataListForTable ">
                                                <td [hidden]=true>{{ consigneeData.consigneeName }}</td>
                                                <!-- <td>{{ consigneeData.gstConsignee }}</td> -->
                                                <td>{{ consigneeData.source }}</td>
                                                <td>{{ consigneeData.commodityName }}</td>
                                                <td>{{ consigneeData.rate }}</td>
                                                <td>{{ consigneeData.unit }}</td>
                                                <td>{{ consigneeData.riskCharge == true? "Yes" : "No" }}</td>
                                                <td>{{ consigneeData.effectiveFromDate }}</td>
                                                <td><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(consigneeData);">
														<i title="Edit" class="fas fa-edit"></i>
													</button>
                                                    <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(consigneeData)">
														<i title="Delete" class="fas fa-trash"></i>
													</button>
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td [hidden]=true></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- popup starts -->
                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}popupDetails">
                            <!-- <label>Invoice Number</label> -->
                            <ng-template #setRateAllPopup let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Invoice Details
                                    </h6>
                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
										<span aria-hidden="true">&times;</span>
									</button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label style="color: blue; font-size: 16px"><u><b>Invoice
																	Details</b> </u></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Invoice Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="icon-speech"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}invoiceNo" name="invoiceNo" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.invoiceNumber" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12"></div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group ">
                                                        <label>Company Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}companyName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompName" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group ">
                                                        <label>Vehicle Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}mainVehicleNo" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehicleNumber" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group ">
                                                        <label>Address</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class=" fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}vehicleCompanyAddress" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompAddress" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group ">
                                                        <label>City</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class=" fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}vehicleCompCity" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompCity" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Pincode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class=" fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input type="number" id="{{pageId}}pinCode" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompPincode" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Contact Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class=" fas fa-phone"></i>
															</span>
                                                        </div>
                                                        <input type="number" id="{{pageId}}vehicleCompContactNumber" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompContactNum" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Driver Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}driverName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.driverName" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Driver Con. No.</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class=" fas fa-phone"></i>
															</span>
                                                        </div>
                                                        <input type="number" id="{{pageId}}driverContactNumber" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.driverContactNum" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Broker Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}brokerName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.brokerName" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Generated By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}invoiceGeneratedBy" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.invoiceGeneratedBy" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Loader Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}loaderName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.loaderName" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label style="color: blue; font-size: 16px"><u><b>Date
																	Info</b> </u></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Departure Date</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}departureDate" name="departureDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.departuredateStr" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Departure Time</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-clock"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}departureTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.departureTime" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Schedule Date</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}scheduledDate" name="scheduledDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.scheduleddateStr" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Schedule Time</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-clock"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}scheduleTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.scheduledTime" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group ">
                                                        <label>Arrival Date</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}arrivalDate" name="arrivalDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.arrivalDateStr" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Arrival Time</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-clock"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}arrivalTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.arrivalTime" [readonly]="isReadOnly">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <button style="padding: 1px 4px; float: right; margin-top: 10pc;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <!-- <a style="cursor: pointer; color: blue;"
								(click)="openRateAllPopup(setRateAllPopup)"><u>{{showInvoiceNumber}}</u></a> -->
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}">
                            <ng-template #ddUpdatePopUpTemplate let-ok="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h5>Update Hamali Destination:</h5>
                                    <!--<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
											<span aria-hidden="true">&times;</span>
										</button> -->
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerForHamaliDd" class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                                </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Area</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-map-marker-alt"></i>
																	</span>
                                                                </div>
                                                                <input #area id="{{pageId}}area" type="text" class="form-control" (selectItem)="clickListnerForArea($event)" [(ngModel)]="modelArea" [ngbTypeahead]="searchArea" [resultFormatter]="formatterArea" [inputFormatter]="formatterArea" (focus)="focusAreaTA$.next($any($event).target.value)"
                                                                    placeholder="Area" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Current Hamali Dest</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}currentHamaliDestId" name="currentHamaliDestId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Update Hamali Dest</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}updateHamaliDestId" name="updateHamaliDestId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <!--<div class="col-sm-12 col-md-4"></div>
													<div class="col-sm-12 col-md-4">
													</div>-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}saveBtnId" (click)="saveNewHamaliDdPopUpBtn();">Save</button>
                                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}closeBtnId" (click)="closeNewCollectionManPopUpBtn();">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}">
                            <ng-template #areaOtherChgUpdatePopUpTemplate let-ok="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h5>Update Other Charges</h5>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerForHamaliDd" class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                                </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Area</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-map-marker-alt"></i>
																	</span>
                                                                </div>
                                                                <input #area id="{{pageId}}area" type="text" class="form-control" (selectItem)="clickListnerForArea($event)" [(ngModel)]="modelArea" [ngbTypeahead]="searchArea" [resultFormatter]="formatterArea" [inputFormatter]="formatterArea" (focus)="focusAreaTA$.next($any($event).target.value)"
                                                                    placeholder="Select Area" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Current Other Charges</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}currentAreaOtherChargesId" name="currentAreaOtherChargesId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>New Other Charges</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}updateAreaOtherChargesId" name="updateAreaOtherChargesId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12">
                                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}saveBtnId" (click)="updateAreaOtherChgPopUpBtn();">Update</button>
                                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}closeBtnId" (click)="closeAreaOtherChgUpdatePopUpBtn();">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}">
                            <ng-template #gdUpdatePopUpTemplate let-ok="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h5>GD Update:</h5>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerForHamaliDd" class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                                </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Area</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-map-marker-alt"></i>
																	</span>
                                                                </div>
                                                                <input #area id="{{pageId}}areaForGd" type="text" class="form-control" (selectItem)="clickListnerForArea($event)" [(ngModel)]="modelArea" [ngbTypeahead]="searchArea" [resultFormatter]="formatterArea" [inputFormatter]="formatterArea" (focus)="focusAreaTA$.next($any($event).target.value)"
                                                                    placeholder="Area" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Current GD Charges</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}currentGdChargesId" name="currentGdChargesId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Update GD Charges</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}updateGdChargesId" name="updateGdChargesId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12">
                                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}saveBtnId" (click)="saveNewHamaliGdPopUpBtn();">Save</button>
                                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}closeBtnId" (click)="closeGdPopUpBtn();">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>