<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Monthly Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															>
															<option selected value="direct">Direct</option>
															<option value="via">Via</option>
														</select>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}source">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Source</option>
															<option value="delhi">Delhi</option>
															<option value="mumbai">Mumbai</option>
															<option value="agra">Agra</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Destination</option>
															<option value="all">All</option>
															<option value="bangalore">Bangalore</option>
															<option value="chennai">Chennai</option>
															<option value="kerela">Kerela</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}financialYear">
														<label>Financial Year</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-calendar"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Financial Year</option>
															<option value="one">2017-2018</option>
															<option value="two">2018-2019</option>
															<option value="three">2019-2020</option>
														</select>
													</div>
												</div>
											</div>





										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsDirectMonthlyReport"
								[dtTrigger]="dtTriggerDirectMonthlyReport">

								<thead>
									<tr>
										<th>MONTH</th>
										<th>Tot Trucks</th>
										<th>TO PAY FREIGHT</th>
										<th>PAID FREIGHT</th>
										<th>TOTAL FREIGHT</th>
										<th>TOTAL HIRE</th>
										<th>ADVANCE</th>
										<th>GUR WT</th>
										<th>KANTA WT</th>
										<th>ACTUAL WT</th>
										<th>CHARGED WT</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let directMonthlyReportData of directMonthlyReportDataList ">
										<td>{{ directMonthlyReportData.month }}</td>
										<td>{{ directMonthlyReportData.totTrucks }}</td>
										<td>{{ directMonthlyReportData.toPayFreight }}</td>
										<td>{{ directMonthlyReportData.paidFreight }}</td>
										<td>{{ directMonthlyReportData.totalFreight }}</td>
										<td>{{ directMonthlyReportData.totalHire }}</td>
										<td>{{ directMonthlyReportData.advance }}</td>
										<td>{{ directMonthlyReportData.gurWt }}</td>
										<td>{{ directMonthlyReportData.kantaWt }}</td>
										<td>{{ directMonthlyReportData.actualWt }}</td>
										<td>{{ directMonthlyReportData.chargedWt }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
							<div>
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionViaMonthlyReport"
									[dtTrigger]="dtTriggerViaMonthlyReport">
									<thead>
										<tr>
											<th>MONTH</th>
											<th>TO PAY FREIGHT</th>
											<th>PAID FREIGHT</th>
											<th>TOTAL FREIGHT</th>
											<th>ACTUAL WT</th>
											<th>CHARGED WT</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let viaMonthlyReportData of viaMonthlyReportDataList ">
											<td>{{ viaMonthlyReportData.month }}</td>
											<td>{{ viaMonthlyReportData.toPayFreight }}</td>
											<td>{{ viaMonthlyReportData.paidFreight }}</td>
											<td>{{ viaMonthlyReportData.totalFreight }}</td>
											<td>{{ viaMonthlyReportData.actualWt }}</td>
											<td>{{ viaMonthlyReportData.chargedWt }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>

								</table>
							</div>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>