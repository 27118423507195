<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"
	(click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center'
						style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-8">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Pending Commodity
					</h6>
			
				</div>
				<div class="col-md-4">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id="{{pageId}}pendingCommodityId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsPendingCommodity"
			[dtTrigger]="dtTriggerPendingCommodity">

			<thead>
				<tr>
					<th>Consignee Name</th>
					<th>LR</th>
					<th style='padding-right: 80px;'>Commodity</th>
					<th style="text-align: center;">Is Confirm</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let pendingCommodityData of pendingCommodityDataList ">
					<td>{{ pendingCommodityData.consigneeName }}</td>
					<td>{{ pendingCommodityData.lrNumber }}</td>
					<td>{{ pendingCommodityData.mainCommodity }}</td>
					<td style="white-space: nowrap;"><button type="submit"
							(click)="openPopupFroYes(pendingCommodityData)" class="btn_custome" id="{{pageId}}yesBtn">Yes</button>
						<button type="submit"
							(click)="openGoodType(confirmCommodityPopUpTemplate,pendingCommodityData)"
							class="btn_custome" id="{{pageId}}noBtn">No</button></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- <div class="col-md-12"> -->
<!-- 	<div class="form-group"> -->
<!-- 		<div class="input-group" id="{{pageId}}popupDetailsTwo"> -->
<!-- 			<ng-template #content let-no="close" let-d="dismiss"> -->
<!-- 			<div class="modal-body"> -->
<!-- 				<div class="row" style="text-align: center;"> -->
<!-- 					<div class="col-md-12"> -->
<!-- 						<h5 class="m-b-10">Sure You Want To Send The SMS?</h5> -->
<!-- 						<button type="button" class="btn btn-outline-secondary" -->
<!-- 							(click)="openModalSuccess(contentSuccess)">Yes</button> -->
<!-- 						<button type="button" class="btn btn-outline-danger" -->
<!-- 							(click)="no('No click')">No</button> -->
<!-- 					</div> -->
<!-- 				</div> -->
<!-- 			</div> -->
<!-- 			</ng-template> -->


<!-- 		</div> -->
<!-- 	</div> -->
<!-- </div> -->

<!-- <div class="col-md-12"> -->
<!-- 	<div class="form-group"> -->
<!-- 		<div class="input-group" id="{{pageId}}popupDetailsThree"> -->
<!-- 			<ng-template #contentSuccess let-ok="close" let-d="dismiss"> -->
			
<!-- 			<div class="modal-body"> -->
<!-- 				<div class="row" style="text-align: center;"> -->
<!-- 					<div class="col-md-12"> -->
<!-- 						<h5 class="m-b-10">SMS send Successfully!!!</h5> -->
<!-- 						<button type="button" class="btn btn-outline-secondary" -->
<!-- 							(click)="ok('Ok click')">Ok</button> -->
<!-- 					</div> -->
<!-- 				</div> -->
<!-- 			</div> -->
<!-- 			</ng-template> -->
<!-- 		</div> -->
<!-- 	</div> -->
<!-- </div> -->


<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetailsConfirmCommodity">
			<ng-template #confirmCommodityPopUpTemplate let-ok="close"
				let-d="dismiss">
			<div class="modal-header">
				<h5>Commodity Details</h5>
				<button type="button" class="close" aria-label="Close"
					(click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<div class="modal-body">
				<h6 class="modal-title" id="{{pageId}}modal-basic-title">Please Select
					Different Commodity</h6>
				<br>
				<div class="row">
					<div *ngIf="showSpinnerForActionForPopup" class="col-md-12 p-t-10">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 15px;">Please
									Wait Loading....</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style="">
					<div class="col-sm-12 col-md-12">
						<div class="form-group">
							<div class="input-group">
								<label>Goods Type :</label>
								<div class="input-group-prepend">
									<span class="input-group-text"> <i
										class="fas fa-warehouse"></i>
									</span>
								</div>
								<input #CommodityMainUnldg id="{{pageId}}CommodityMainUnldgId" type="text"
									placeholder="Select Commodity"
									class="form-control" [(ngModel)]="modelCommodityMainUnldg"
									[ngbTypeahead]="searchCommodityMainUnldg"
									[resultFormatter]="formatterCommodityMainUnldg"
									[inputFormatter]="formatterCommodityMainUnldg"
									(focus)="focusCommodityMainUnldgTA$.next($any($event).target.value)"
									autocomplete="off" />
							</div>
						</div>
					</div>
					<div class="col-md-12 p-t-10" style="text-align: right;">
						<button type="button" class="btn btn-outline-dark"
							(click)="updateBtn()">OK</button>
						<button type="button" class="btn btn-outline-dark"
							(click)="closeBtnPopup();">Close</button>


					</div>
				</div>
			</div>
			</ng-template>
		</div>
	</div>
</div>

<!-- <div class="col-md-12"> -->
<!-- 	<div class="form-group"> -->
<!-- 		<div class="input-group" id="{{pageId}}popupDetails"> -->
<!-- 			<ng-template #contentGoodType let-a="close" let-b="close" -->
<!-- 				let-d="dismiss"> -->
<!-- 			<div class="modal-header"> -->
<!-- 				<h6 class="modal-title" id="{{pageId}}modal-basic-title">Commodity -->
<!-- 					Details</h6> -->
<!-- 				<button type="button" class="close" aria-label="Close" -->
<!-- 					(click)="d('Cross click')"> -->
<!-- 					<span aria-hidden="true">&times;</span> -->
<!-- 				</button> -->
<!-- 			</div> -->
<!-- 			<div class="modal-body"> -->
<!-- 				<div class="row"> -->
<!-- 					<div class="col-md-12"> -->
<!-- 						<h6 class="modal-title" id="{{pageId}}modal-basic-title">Please Select -->
<!-- 							Different Commodity</h6> -->
<!-- 						<div class="col-sm-12 col-md-12"> -->
<!-- 							<div class="control"> -->
<!-- 								<div class="form-group"> -->
<!-- 									<div class="input-group" id="{{pageId}}goodsType"> -->
<!-- 										<label>Goods Type</label> -->
<!-- 										<div class="input-group-prepend"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fas fa-question"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 										<div class="input-group"> -->

<!-- 											<input #CommodityMainUnldg id="{{pageId}}CommodityMainUnldgId" -->
<!-- 												type="text" class="form-control" -->
<!-- 												(selectItem)="clickListnerForCommodityMainUnldg($event)" -->
<!-- 												[(ngModel)]="modelCommodityMainUnldg" -->
<!-- 												[ngbTypeahead]="searchCommodityMainUnldg" -->
<!-- 												[resultFormatter]="formatterCommodityMainUnldg" -->
<!-- 												[inputFormatter]="formatterCommodityMainUnldg" -->
<!-- 												(focus)="focusCommodityMainUnldgTA$.next($any($event).target.value)" -->
<!-- 												autocomplete="off" /> -->
<!-- 										</div> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 							</div> -->
<!-- 						</div> -->

<!-- 					</div> -->
<!-- 				</div> -->
<!-- 			</div> -->
<!-- 			<div class="modal-footer"> -->
<!-- 				<button type="button" class="btn btn-outline-success" -->
<!-- 					(click)="a('Ok click')">Ok</button> -->
<!-- 				<button type="button" class="btn btn-outline-dark" -->
<!-- 					(click)="b('Close click')">Close</button> -->
<!-- 			</div> -->
<!-- 			</ng-template> -->
<!-- 		</div> -->
<!-- 	</div> -->
<!-- </div> -->