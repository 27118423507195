<h1 class="title_custom" mat-dialog-title>
    <h6 style="margin-bottom: 0px;">Trip Lr Details</h6>
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <!-- 						<div class="col-md-3"> -->
                        <!-- 							<div class="form-group"> -->
                        <!-- 								<div class="input-group"> -->
                        <!-- 									<label>Trip Date: </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label -->
                        <!-- 										id="{{pageId}}tripDate">26-02-2020</label> -->
                        <!-- 																		<div class="input-group-prepend"> -->
                        <!-- 																			<span class="input-group-text"> <i -->
                        <!-- 																				class="fas fa-file-alt"></i> -->
                        <!-- 																			</span> -->
                        <!-- 																		</div> -->
                        <!-- 									<input type="text" class="form-control" id="{{pageId}}tripDate" -->
                        <!-- 										placeHolder"26-02-2020" -->
                        <!-- 										aria-describedby="basic-addon11" -->
                        <!-- 										readonly> -->
                        <!-- 								</div> -->
                        <!-- 							</div> -->
                        <!-- 						</div> -->
                        <div class="col-md-3">
                            <h6>
                                <strong>Trip Date:</strong>&nbsp;&nbsp;&nbsp;<span id="{{pageId}}tripDate">{{listOfDataToGet.tripDate}}</span>



                            </h6>
                        </div>

                        <div class="col-md-3">
                            <h6>
                                <strong>Vehicle No:</strong>&nbsp;&nbsp;&nbsp;<span id="{{pageId}}vehicleNumber">{{listOfDataToGet.vehicleNumber}}</span>
                            </h6>
                        </div>
                        <div class="col-md-2">
                            <h6>
                                <strong>Trip No:</strong>&nbsp;&nbsp;&nbsp;<span id="{{pageId}}tripNumber">{{listOfDataToGet.tripNo}}</span>
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <h6>
                                <strong>Delivery Area:</strong>&nbsp;&nbsp;&nbsp;<span id="{{pageId}}deliveryArea">{{listOfDataToGet.deliveryarea}}</span>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="box-body">
                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                                <thead>
                                    <tr>
                                        <th>Booking Date</th>
                                        <th>LR Number</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Total Articles</th>
                                        <th>Actual Wt</th>
                                        <th>Charged Wt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                        <td>{{ lrDetailsData.bookingDate }}</td>
                                        <td>{{ lrDetailsData.lrNumber }}</td>
                                        <td>{{ lrDetailsData.source }}</td>
                                        <td>{{ lrDetailsData.destination }}</td>
                                        <td>{{ lrDetailsData.totalArticles }}</td>
                                        <td>{{ lrDetailsData.actualWeight }}</td>
                                        <td>{{ lrDetailsData.chargedWeight }}</td>
                                    </tr>


                                    <!-- <tr *ngIf="supList.length==0">
										<td colspan="9">No Data To Display</td>
									</tr>-->
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <button class="btn btn-outline-danger" mat-button (click)="onCancelClick()">Cancel</button>
    <button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>

</div>