<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showConsignorRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Consignor Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search With</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchWith" name="searchWith" #searchWith (change)="searchWithMode(searchWith.value)">
															<option selected value="newConsignor">New Consignor</option>
															<option value="oldConsignor">Old Consignor</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
															<option selected value="summary">Summary</option>
															<option value="detail">Detail</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="consigneeDestListener($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}partyName">
                                                            <label>Consignee Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="rowSelectedConsignee($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                                [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12" *ngIf="searchWithOldConsignor">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                            (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="!searchWithOldConsignor">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input #newConsignorIndex id="{{pageId}}newConsignorIndex" name="newConsignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="newConsignorDropDownListner($event)" [ngbTypeahead]="newConsignorIndexSearchTA" (focus)="focusNewConsignorIndexTA$.next($any($event).target.value)"
                                                            (click)="clickTA(instanceNewConsignorIndex)" #instanceNewConsignorIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" (selectItem)="rowSelectedConsignor($event)" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" [resultFormatter]="formatterConsignorName"
                                                            [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" placeholder="Select Consignor Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList" [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)"
                                                            autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="btnSearchValidate()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">

                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-t-10">
                                <div class="col-md-8">
                                    <h6 class="card-title" [hidden]="!summaryTable">Summary View
                                    </h6>
                                    <h6 class="card-title" [hidden]="!detailTable">Details View</h6>


                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="dt-button" [hidden]="!summaryTable" style="margin-left: 75%;" id="{{pageId}}printBtnForSummary" (click)="printMethodForSummaryWise();">
										<span><i class="fas fa-print">Print</i></span>
									</button>
                                    <button type="submit" class="dt-button" [hidden]="!detailTable" id="{{pageId}}printBtnForDetail" (click)="printMethodForDetailsWise();" style="margin-left: 75%;">
										<span><i class="fas fa-print">Print</i></span>
									</button>

                                </div>
                            </div>

                            <div class="box-body">
                                <div [hidden]="!summaryTable">
                                    <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}summaryTableId" [dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">

                                        <thead>
                                            <tr>
                                                <th>Consignor Name</th>
                                                <th>Total Lrs</th>
                                                <th>Consignor GST No</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Article</th>
                                                <th>Actual Weight</th>
                                                <th>Charged Weight</th>
                                                <th>F O V</th>
                                                <th>AOC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let smryData of summaryDataList ">
                                                <td>{{ smryData.consignorName }}</td>
                                                <td>{{ smryData.totalLrs }}</td>
                                                <td>{{ smryData.gstNoConsignor }}</td>
                                                <td>{{ smryData.toPay }}</td>
                                                <td>{{ smryData.paid }}</td>
                                                <td>{{ smryData.totalArticles }}</td>
                                                <td>{{ smryData.actualWeight }}</td>
                                                <td>{{ smryData.chargedWeight }}</td>
                                                <td>{{ smryData.riskCharge }}</td>
                                                <td>{{ smryData.aoc }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>

                            <div class="box-body">
                                <div [hidden]="!detailTable">
                                    <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}detailTableId" [dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
                                        <thead>
                                            <tr>
                                                <th>LR Number</th>
                                                <th>Destination</th>
                                                <th>Booking Date</th>
                                                <th>Invoice Number</th>
                                                <th>Consignee Name</th>
                                                <th>Consignor Name</th>
                                                <th>Consignor Gst</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Article</th>
                                                <th>Actual Weight</th>
                                                <th>Charged Weight</th>
                                                <th>F O V</th>
                                                <th>AOC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detailData of detailDataList ">

                                                <td>
                                                    <a style="color:red;cursor: pointer;" (click)="lrNumberToEnquiryForm(detailData)">
                                                        <u>{{ detailData.lrNumber }}</u></a>
                                                </td>
                                                <td>{{ detailData.destination }}</td>
                                                <td>{{ detailData.bookingDateStr }}</td>
                                                <td>{{ detailData.invoiceNumber }}</td>
                                                <td>{{ detailData.consigneeName }}</td>
                                                <td>{{ detailData.consignorName }}</td>
                                                <td>{{ detailData.gstNoConsignor }}</td>
                                                <td>{{ detailData.toPay }}</td>
                                                <td>{{ detailData.paid }}</td>
                                                <td>{{ detailData.totalArticles }}</td>
                                                <td>{{ detailData.actualWeight }}</td>
                                                <td>{{ detailData.chargedWeight }}</td>
                                                <td>{{ detailData.riskCharge }}</td>
                                                <td>{{ detailData.aoc }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>


                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>