
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { MemoReport } from 'src/app/dataService/memo-report';

@Component({
    selector: 'app-left-toparty-details-print',
    templateUrl: './left-toparty-details-print.component.html',
    styleUrls: ['./left-toparty-details-print.component.css']
})
export class LeftToPartyDetailsPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    address: any;

    leftToPartyCustomizePrintShowReturnStorageData: any;

    printDataForLeftToPartyList: any;
    printDataForLeftToPartySummary: any;
    memoAmountSummary: number = 0;
    countSummary: number = 0;
    viewForSelectedDestination = false;

    collectionMan: string;
    consignee: string;
    stmtNo: string;
    pageId = "ltdpc";
    contactPerson: string;
    contactNumber: string;
    tpAckDate: string;

    constructor(private router: Router, private memoReport: MemoReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.leftToPartyCustomizePrintShowReturnStorageData = JSON.parse(localStorage.getItem('leftToPartyCustomizePrintShowLocalStorage'));
            console.log(this.leftToPartyCustomizePrintShowReturnStorageData);

            if (this.leftToPartyCustomizePrintShowReturnStorageData != null &&
                this.leftToPartyCustomizePrintShowReturnStorageData != undefined &&
                this.leftToPartyCustomizePrintShowReturnStorageData != "") {
                this.gridLeftToPartyForPrintTpStmtDetails();
            }

            if (this.userDataDtoReturnSession.mainStation != null &&
                this.userDataDtoReturnSession.mainStation == "Chennai") {
                this.viewForSelectedDestination = true;
            } else {
                this.viewForSelectedDestination = false;
            }
            /* window.setTimeout(function () {
                 window.print();
             }, 0);
 
             window.onafterprint = function () {
                 window.close();
             }
             localStorage.clear();
             window.addEventListener('afterprint', (event) => {
                 this.clearField();
             });*/
        }
    }

    ngOnInit() {

    }

    clearField() {
        localStorage.clear();
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    gridLeftToPartyForPrintTpStmtDetails() {
        let cashMemoDto: CashMemoDto = new CashMemoDto();
        cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
        cashMemoDto.statementNo = this.leftToPartyCustomizePrintShowReturnStorageData;
        console.log(cashMemoDto);
        this.memoReport.getTPStmtDetails(cashMemoDto).subscribe(
            (response) => {
                console.log(response);
                this.printDataForLeftToPartyList = [];
                this.printDataForLeftToPartySummary = [];
                //this.printDataForLeftToPartyList = response;

                this.collectionMan = "";
                this.consignee = "";
                this.contactPerson = "";
                this.contactNumber = "";
                this.stmtNo = "";
                this.tpAckDate = "";
                this.memoAmountSummary = 0;
                this.countSummary = 0;
                for (let i = 0; i < response.length; i++) {
                    this.countSummary++;
                    if (i == 0) {
                        this.memoAmountSummary = response[i].amount;
                        this.collectionMan = response[i].collectionMan;
                        this.consignee = response[i].consignee;
                        this.stmtNo = response[i].statementNo;
                        this.contactPerson = response[i].contactPerson;
                        this.contactNumber = response[i].contactNumber;
                        this.tpAckDate = response[i].tpStmtAckDateStr;
                    } else {
                        this.memoAmountSummary = +this.memoAmountSummary + +response[i].amount;
                    }
                    this.printDataForLeftToPartyList.push(response[i]);
                }

                window.setTimeout(function () {
                    window.print();
                }, 0);

                window.onafterprint = function () {
                    window.close();
                }
                localStorage.clear();
                window.addEventListener('afterprint', (event) => {
                    this.clearField();
                });
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Failed", "Server problem occurred while getting TP Stmt Details", "error");
            }, () => console.log('done');
    }


}
