import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();

////
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';



import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import *  as moment  from 'moment';

@Component( {
    selector: 'app-manual-cashmemo-check',
    templateUrl: './manual-cashmemo-check.component.html',
    styleUrls: ['./manual-cashmemo-check.component.css']
} )
export class ManualCashmemoCheckComponent implements OnInit {
    gettingDataFrmServiceFrManualCashmemoCheckTable: any;
   // manualCashmemoCheckDataList: any;
    onDestroyUnsubscribtionManualCashmemoCheck: Subscription;
    loadingIndicator = true;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerManualCashmemoCheck: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsManualCashmemoCheck: any;
    manualView = false;
    automaticView = true;
	address:any;
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	modelStation:any;
	stationTA: Array<PartyMasterDto> = [];
	focusStationTA$ = new Subject<string>();
	selectedEntryType:any;
	memoFormat:any;
	modelStationName:any;
	selectedMemoNumberForManual:any;
	selectedMemoNumberForAutomatic:any;
	memoNum:any;
	searchCashmemoNumber:CashMemoDto= new CashMemoDto();
	stationForCode:any;
	selectedCodeForManual:any;
	manualCashmemoCheckDataList: Array<any> = [];
	    private newAttribute: any = {};
		textMemoCount='';
		memoCount=0;
		lblMemoCount=false;
	stationSearchTA = ( text$: Observable<string> ) => {
	    const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
	    const inputFocus$ = this.focusStationTA$;

	    return merge( debouncedText$, inputFocus$ ).pipe(
	        map( term => ( term === '' ? this.stationTA
				  : this.stationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
	    );
	}
	formatterStation = (x: { destination: string }) => x.destination;
	////
lrDtoForStation: LRDto = new LRDto();
stationOptions:any;
userDtoMemoCode:UserDataDto = new UserDataDto();
stationCodeDetails:any;
checkMemoNumber:CashMemoDto = new CashMemoDto();
checkMemoNumberForTable:CashMemoDto = new CashMemoDto();
responseOfCheckMemoNumber:any;
responseOfCheckMemoNumberForTable:any;
showStatus:any;

searchCashmemoNumberForTable:CashMemoDto= new CashMemoDto();
updateCashmemoNumberForTable:CashMemoDto= new CashMemoDto();
cashmemoDtoForPrint:CashMemoDto= new CashMemoDto();
	countOfLr = 0;
	sumOfTotAmt  = 0;
	sumOfTotArt = 0;
		setStation: Array<any> = [];
		selectionStation:any;
	manualCashmemoCheckPrintPopUp: NgbModalRef;
	manualCashmemoCheckPrintClosePopUp: string;
	getPrintData:any;
	@ViewChild('manualCashmemoCheckPrintPopUpTemplate') private manualCashmemoCheckPrintPopUpTemplate;
	viewManualCashmemoCheckPrintPopUp=false;
	isReadOnly:boolean;
	pageId="mncmc";
	
     constructor(private memoLessRpt: ReportService, private router: Router,
        private memoReport: MemoReport, private masterReadService: MasterReadService, private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getStationDtails();
			console.log("this.userDataDtoReturnSession.mainStation");
			console.log(this.userDataDtoReturnSession);
			if(this.userDataDtoReturnSession.mainStation=="Chennai" && this.userDataDtoReturnSession.role !="Administrator"){
				// $("#mncmcentryType").prop("disabled", true);
				this.isReadOnly=true;
			}else{
				// $("#mncmcentryType").prop( "disabled", false );
				this.isReadOnly=false;
			}
           
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnInit(): void {
    	var companyAddressDetls = this.address;
    	var mainStation =this.userDataDtoReturnSession.mainStation;
		
        this.dtOptionsManualCashmemoCheck = {

            dom: 'Bfrtip',
            buttons: [
      				
      			], 
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
              	  var totalAmt = api.column( 2 ).data().reduce( 
                		function (a, b) {
                					 return intVal(a) + intVal(b);
                		}, 0 );
                		var totalArt = api.column( 4 ).data().reduce( 
                		function (a, b) {
                					 return intVal(a) + intVal(b);
                		}, 0 );

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : '+data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html(totalAmt);
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html(totalArt);
                $(api.column(5).footer()).html();
                $(api.column(6).footer()).html();
                $(api.column(7).footer()).html();

            }

        }
    }

    ngOnDestroy(): void {
        this.dtTriggerManualCashmemoCheck.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTriggerManualCashmemoCheck.next();

    }
   
    entryTypeMode( entryType: string ) {
        if ( entryType === 'manual' ) {
            this.manualView = true;
            this.automaticView = false;
        } else if
      ( entryType === 'automatic' ) {
            this.manualView = false;
            this.automaticView = true;
        } else {
            this.manualView = false;
            this.automaticView = false;
        }
    }
    
    getStationInfo() {
        this.lrDtoForStation = new LRDto();
        this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoForStation.mode = "ALL";
    }
    getStationDtails = () => {
        this.getStationInfo();
        this.masterReadService.getDestinationForLREntryService( this.lrDtoForStation ).subscribe(
            ( response ) => {
                if ( response ) {
                    this.stationOptions = response;
                    this.lrDtoForStation = new LRDto();
                    this.stationTA = [];
//                    console.log(this.stationOptions.length);
                    for ( let i = 0; i < this.stationOptions.length; i++ ) {
                        this.stationTA.push( this.stationOptions[i] );
                    }
				console.log( this.stationTA,this.stationOptions);
                }
				this.changeDetectorRef.detectChanges();
            } ),(error) => {
						 swal("Error", "Server Error While Getting Station Details", "info");
					 }, () => console.log('done');
			 };

	getMemoCode( e: NgbTypeaheadSelectItemEvent ) {
		this.userDtoMemoCode = new UserDataDto();
		this.modelStation = e.item;
		this.userDtoMemoCode.companyId = this.userDataDtoReturnSession.companyId;
		this.stationForCode = this.modelStation.destination;
		this.userDtoMemoCode.office = this.stationForCode;
		console.log(this.userDtoMemoCode);
		this.getDetailsForMemoCode();
		$("#"+this.pageId+"checkBtn" ).prop( "disabled", false );
		$("#"+this.pageId+"unCheckBtn" ).prop( "disabled", false );
    }
	 getDetailsForMemoCode() {
        this.masterReadService.getStationCodeService( this.userDtoMemoCode ).subscribe(
            ( response ) => {
                if ( response.length != 0 ) {
                    this.stationCodeDetails = response;
                    this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
                  

                        if ( this.selectedEntryType == "automatic" ) {
                            this.manualView = false;
                            this.automaticView = true;
                            $("#mncmccashMemoAutomatic" ).focus();
                        } else {

                            if ( this.stationCodeDetails.stationCode == null ) {
                                $("#"+this.pageId+"codeForManual" ).val( '' );
                            } else {
                                //Basha Pending
                                this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                                //                                this.memoFormat = 'BNG/1920/';
                                $("#"+this.pageId+"codeForManual" ).val( this.memoFormat );
                                $("#"+this.pageId+"cashMemoManual" ).val( '' );
                                $("#"+this.pageId+"cashMemoAutomatic" ).val( '' );
                                $("#mncmccashMemoManual" ).focus();
                            }
                        }

                    this.userDtoMemoCode = new UserDataDto();
                }
				this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

	
	 getMemoNumberForManual(e) {
        if ( e.keyCode == 13 ) {
            this.selectedMemoNumberForManual = $("#"+this.pageId+"cashMemoManual" ).val();
            if ( this.selectedMemoNumberForManual != null && this.selectedMemoNumberForManual != '' ) {
                this.getManualCashMemoCheckMtd();
            }
        else{
			swal({
					title: "Warning",
					text: "Memo Number Empty",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();
				// $("#mncmccashMemoManual" ).focus();
				window.setTimeout(function () {
                               $("#mncmccashMemoManual" ).focus();
                            }, 100);
				 return false;
				});
		}
        }

    }
	
	 getMemoNumberForAutomatic( e) {
      /*  setTimeout(() => {
            this.showforAutomatic();
        },
            500 );*/
        
        if ( e.keyCode == 13 ) {
        	this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
	        if ( this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '' ) {
	            this.getManualCashMemoCheckMtd();
	        } else{
				swal({
						title: "Warning",
						text: "Memo Number Empty",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
					window.setTimeout(function () {
	                               $("#mncmccashMemoAutomatic" ).focus();
	                            }, 100);
					 return false;
					});
			}
        }
    }
	/*  showforAutomatic() {
	        this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
	        if ( this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '' ) {
	            this.getManualCashMemoCheckMtd();
	        }else{
				swal({
						title: "Warning",
						text: "Memo Number Empty",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						 window.setTimeout(function () {
                         	 $("#mncmccashMemoAutomatic" ).focus();
                        }, 100);
					 return false;
					});
			}
	    }*/
	getManualCashMemoCheckMtd(){
		this.memoNum = '';
        this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
        this.selectedCodeForManual = $("#"+this.pageId+"codeForManual" ).val();
        this.selectedMemoNumberForManual = $("#"+this.pageId+"cashMemoManual" ).val();
        this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
        if ( this.selectedEntryType == 'automatic' ) {
            this.memoNum = this.selectedMemoNumberForAutomatic;
           
        } else {
            this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
          
        }
		this.searchCashmemoNumber= new CashMemoDto();
		this.searchCashmemoNumber.memoNumber=this.memoNum;
		this.getManualCashMemoCheckMtdDetails();	
		  this.showSpinnerForAction = true;
	}
	getManualCashMemoCheckMtdDetails() {
        this.memoReport.getManualCashMemoCheckFirst( this.searchCashmemoNumber ).subscribe(
            ( response ) => {
            	console.log(response);
            	this.showSpinnerForAction = false;
            	$("#"+this.pageId+"finishBtn" ).prop( "disabled", true );
			  if(response.length > 0){
					this.showSpinnerForAction = false;
					this.checkMemoNumber = new CashMemoDto();
                    this.responseOfCheckMemoNumber = response;
                    this.checkMemoNumber = this.responseOfCheckMemoNumber;
                     console.log(this.checkMemoNumber);
					console.log(this.checkMemoNumber[0].lrNumber);
				if (this.checkMemoNumber[0].lrNumber !=null && this.checkMemoNumber[0].lrNumber !='' ) {
				   if(((this.checkMemoNumber[0].status == null ) &&(this.checkMemoNumber[0].column1 == null )&&(this.checkMemoNumber[0].memoCheck == null ))||(this.checkMemoNumber[0].memoCheck == null )){
					   swal({
						title: "Empty",
						text: "Cashmemo number not found",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
						}).then(() => {
						 this.clearMethod();
						
						});
				   }else{
					  
				   if((this.checkMemoNumber[0].memoCheck != null) && (this.checkMemoNumber[0].memoCheck == false)){
				     if((this.checkMemoNumber[0].status == 'collection') && (this.checkMemoNumber[0].column1 == 'Pending')){
					 // manualCollectionCheck();
					// insert into grid @Dated :
					// 29/08/2018
					//Pending
						this.gridReconfigure();
						$("#"+this.pageId+"memoAmount" ).val(this.checkMemoNumber[0].grandTotal);
						$("#"+this.pageId+"lrNumber" ).val(this.checkMemoNumber[0].lrNumber);
						$("#"+this.pageId+"finishBtn" ).prop( "disabled", false );
						$("#mncmcfinishBtn" ).focus();
						this.clearMethod1();
						this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
        
									if ( this.selectedEntryType == 'automatic' ) {
										this.updateMethod(this.memoNum);
												this.lblMemoCount=true;
												this.memoCount= +this.memoCount + +1 
												this.textMemoCount=" No. of cashmemos: "+this.memoCount;
												this.clearMethod();
           
								} 

					 }else{
					 this.showStatus='';
						 if(this.checkMemoNumber[0].status == 'billing'){
						  this.showStatus=' Cash Memo is in Billing Stock';
						 }else if(this.checkMemoNumber[0].status == 'transfer'){
						  this.showStatus=' Cash Memo not in SRD Collection Stock';
						 }else if((this.checkMemoNumber[0].status == 'collection') && (this.checkMemoNumber[0].column1 == 'completed')){
						  this.showStatus=' Collection Closed';
						 }else if((this.checkMemoNumber[0].status == 'cmstock') && (this.checkMemoNumber[0].column1 == 'Pending')){
						  this.showStatus=' Cash Memo is in Collection Man Stock';
						 }else if((this.checkMemoNumber[0].status == 'cmstock') && (this.checkMemoNumber[0].column1 == 'Assigned')){
						  this.showStatus=' Cash Memo assigned for Collection';
						 }else if(this.checkMemoNumber[0].status == 'to party'){
						  this.showStatus=' Cash Memo Left To-Party';
						 }else if(this.checkMemoNumber[0].status == 'completed'){
						  this.showStatus=' Collection Closed';
						 }else if(this.checkMemoNumber[0].status == 'transferToGodown'){
						  this.showStatus=' Cash Memo not in SRD Collection Stock';
						 }else if(this.checkMemoNumber[0].status == 'missing'){
						  this.showStatus=' Cash Memo is Missing';
						 }
						 swal({
							title: "Status",
							text: " "+this.showStatus,
							icon: "warning",
							closeOnClickOutside: false,
							closeOnEsc: false,
							}).then(() => {
							 this.clearMethod();
						
						});
					 }
				   
				   }else if((this.checkMemoNumber[0].memoCheck != null) && (this.checkMemoNumber[0].memoCheck == true)){
				    swal({
							title: "Status",
							text: "Cash Memo Number Already Checked",
							icon: "warning",
							closeOnClickOutside: false,
							closeOnEsc: false,
							}).then(() => {
							 this.clearMethod();
						
						});
				   
				   }
				   }
                   
                }else{
						swal({
							title: "Warning",
							text: "Cash memo number does not exist",
							icon: "error",
							closeOnClickOutside: false,
							closeOnEsc: false,
						}).then(() => {
						this.clearMethod();
						});
				}
			  }else{
				swal({
					title: "Warning",
					text: "Cash memo number does not exist",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
				this.clearMethod();
				});
			  
			  }
              this.changeDetectorRef.detectChanges();
            } ), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While Getting Memo Number Details", "info");
                    }, () => console.log('done');
    };
	
	clearMethod(){
			this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
			$("#"+this.pageId+"memoAmount" ).val('');
			$("#"+this.pageId+"lrNumber" ).val('');
			if ( this.selectedEntryType == 'automatic' ) {
				$("#"+this.pageId+"cashMemoAutomatic" ).val('');
				//$("#mncmccashMemoAutomatic" ).focus();
				window.setTimeout(function () {
                    $("#mncmccashMemoAutomatic" ).focus();
                 }, 100);
			} else {
				$("#"+this.pageId+"cashMemoManual" ).val(''); 
				window.setTimeout(function () {
                    $("#mncmccashMemoManual" ).focus();
                 }, 100);

			}
	}
	gridReconfigure(){
	if(this.manualCashmemoCheckDataList.length>0){
	 const checkMemoNumberInTable = this.manualCashmemoCheckDataList.find( manualCashmemoCheckData => manualCashmemoCheckData.memoNumber ===this.memoNum  );
           console.log( checkMemoNumberInTable );
       
        if ( checkMemoNumberInTable == undefined ) {
        
            this.checkingBeforeInsertInTableDetails();
        } else {
            
            swal( {
                title: "Warning",
                text: "Duplicate Memo Number",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
				swal.close();
				 this.clearMethod();
				 
				});
        }
        
	}else{
		console.log('5')
		 this.checkingBeforeInsertInTableDetails();
	}
	
	}
	 checkingBeforeInsertInTableDetails() {
        this.searchCashmemoNumberForTable =new CashMemoDto();
        this.searchCashmemoNumberForTable.memoNumber = this.memoNum;
        this.checkingBeforeInsertInTable();

    }
    checkingBeforeInsertInTable = () => {
        this.memoReport.getManualCashMemoCheckFirst( this.searchCashmemoNumberForTable ).subscribe(
            ( response ) => {
                if ( response.length == 0 ) {
					swal({
						title: "Alert",
						text: "No Datas Found For This Information",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
						}).then(() => {
						 this.clearMethod();
						});
                } else {
					this.checkMemoNumberForTable = new CashMemoDto();
                    this.responseOfCheckMemoNumberForTable = response;
                    this.checkMemoNumberForTable = this.responseOfCheckMemoNumberForTable;
					// implemented the below check as per chennai
								// requirements to avoid the insertion if the
								// status is assigned or to party
								// @Dated : 26/06/2018
					 if((this.checkMemoNumberForTable[0].status == 'to party') || (this.checkMemoNumberForTable[0].column1 == 'Assigned')){
					 // no insert into grid
					 console.log('7')
					 }else{
					 this.addInTable();
					 console.log('6')
					 }

                }
				this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };
	
	 addInTable() {
		console.log('8')
        this.newAttribute.memoNumber = this.checkMemoNumberForTable[0].memoNumber ;
        this.newAttribute.memoDateStr = this.checkMemoNumberForTable[0].memoDateStr 
        this.newAttribute.grandTotal =this.checkMemoNumberForTable[0].grandTotal 
        this.newAttribute.lrNumber = this.checkMemoNumberForTable[0].lrNumber 
        this.newAttribute.articles = this.checkMemoNumberForTable[0].articles 
        this.newAttribute.area = this.checkMemoNumberForTable[0].area 
        this.newAttribute.consigneeName = this.checkMemoNumberForTable[0].consigneeName 
        this.newAttribute.collectionMan = this.checkMemoNumberForTable[0].collectionMan 

        		 $("#"+this.pageId+"manualCashmemoCheckId").DataTable().destroy();
        if ( this.manualCashmemoCheckDataList == null ) {
            this.manualCashmemoCheckDataList = [];
        }
      
        
        if ( this.manualCashmemoCheckDataList.length == 0 ) {
            this.manualCashmemoCheckDataList.push( this.newAttribute );
        } else {
            this.manualCashmemoCheckDataList.push( this.newAttribute );
        }
    	this.dtTriggerManualCashmemoCheck.next();
        // console.log( this.manualCashmemoCheckDataList );

        this.countOfLr = +this.countOfLr + 1;
        this.sumOfTotAmt = +this.sumOfTotAmt + +this.newAttribute.grandTotal;
		this.sumOfTotArt = +this.sumOfTotArt + +this.newAttribute.articles;
        this.newAttribute = {};
       
       
       /* $("#"+this.pageId+"entryLessAmount" ).val( '' );
        this.selectedEntryType = $("#"+this.pageId+"entryLessEntryType" ).val();
        this.selectedCodeForManual = $("#"+this.pageId+"codeForManualLess" ).val();

        if ( this.selectedEntryType == 'entryLessAutomatic' ) {
            $("#"+this.pageId+"memoNumberForAutomaticLess" ).val( '' );
            $("#mncmcmemoNumberForAutomaticLess" ).focus();
        } else {
            $("#"+this.pageId+"memoNumberForManualLess" ).val( '' );
            $("#mncmcmemoNumberForManualLess" ).focus();
        }*/
       
    }
	
	clearMethod1(){
			this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
			if ( this.selectedEntryType == 'automatic' ) {
				$("#"+this.pageId+"cashMemoAutomatic" ).val('');
				$("#mncmccashMemoAutomatic" ).focus();
			} else {
				console.log('81')
				$("#"+this.pageId+"finishBtn" ).prop( "disabled", false );
				$("#mncmcfinishBtn" ).focus();
			}
	}
	finishMethod(){
		this.memoNum = '';
        this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
        this.selectedCodeForManual = $("#"+this.pageId+"codeForManual" ).val();
        this.selectedMemoNumberForManual = $("#"+this.pageId+"cashMemoManual" ).val();
        this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
        if ( this.selectedEntryType == 'automatic' ) {
            this.memoNum = this.selectedMemoNumberForAutomatic;
           
        } else {
            this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
          
        }
				this.updateMethod(this.memoNum);
				this.lblMemoCount=true;
				this.memoCount= +this.memoCount + +1; 
				this.textMemoCount=" No. of cashmemos: "+this.memoCount;
				this.clearMethod();
	}
	updateMethod(memoNum){
	this.updateCashmemoNumberForTable= new CashMemoDto();
	this.updateCashmemoNumberForTable.memoNumber =memoNum;
	this.updateCashmemoNumberForTable.enteredBy = this.userDataDtoReturnSession.userId;
	this.updateCashmemoNumberForTable.companyId = this.userDataDtoReturnSession.companyId;
	console.log(this.updateCashmemoNumberForTable);
	this.updateMethodDetails();
	}

	 updateMethodDetails = () => {
        this.memoReport.updateCashMemoCheck( this.updateCashmemoNumberForTable ).subscribe(
            ( response ) => {
                if ( response.status == 'updated' ) {
					
                } else {
					

                }
				this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };
	
	clearAllMethod(){
		this.showSpinnerForAction = false;
		this.memoFormat='',
		this.selectedMemoNumberForManual='';
		this.selectedMemoNumberForAutomatic='';
		this.memoNum='';
		this.textMemoCount='';
		this.memoCount=0;
		this.lblMemoCount=false;
		this.stationForCode='';
		this.selectedCodeForManual='';
		this.userDtoMemoCode = new UserDataDto();
		this.stationCodeDetails='';
		this.checkMemoNumber= new CashMemoDto();
		this.checkMemoNumberForTable = new CashMemoDto();
		this.responseOfCheckMemoNumber='';
		this.responseOfCheckMemoNumberForTable='';
		this.showStatus='';
		this.searchCashmemoNumberForTable= new CashMemoDto();
		this.updateCashmemoNumberForTable= new CashMemoDto();
		this.countOfLr = 0;
		this.sumOfTotAmt  = 0;
		this.sumOfTotArt = 0;
		$("#"+this.pageId+"manualCashmemoCheckId").DataTable().destroy();
		$("#"+this.pageId+"finishBtn" ).prop( "disabled", true );
		this.manualCashmemoCheckDataList = [];
		this.dtTriggerManualCashmemoCheck.next();
		this.modelStation='';
		 this.getPrintData='';
		 this.setStation=[];
		 this.cashmemoDtoForPrint= new CashMemoDto();
		 localStorage.clear();
		 this.viewManualCashmemoCheckPrintPopUp = false;
		
	}
	
	checkBtn(manualCashmemoCheckPrintPopUpTemplate){
	this.getCollectionCheckPrintOut("NotAll", true,manualCashmemoCheckPrintPopUpTemplate);
	}
	checkAllBtn(manualCashmemoCheckPrintPopUpTemplate){
	this.getCollectionCheckPrintOut("All", true,manualCashmemoCheckPrintPopUpTemplate);
	}
	unCheckBtn(manualCashmemoCheckPrintPopUpTemplate){
	this.getCollectionCheckPrintOut("NotAll",false,manualCashmemoCheckPrintPopUpTemplate);
	}
	unCheckAllBtn(manualCashmemoCheckPrintPopUpTemplate){
	this.getCollectionCheckPrintOut("All", false,manualCashmemoCheckPrintPopUpTemplate);
	}
	
	getCollectionCheckPrintOut( mode:string, memoChk:boolean,manualCashmemoCheckPrintPopUpTemplate){
		this.selectionStation= $("#"+this.pageId+"station" ).val();
		this.setStation=[];
		for ( let i = 0; i < this.stationOptions.length; i++ ) {
				this.setStation.push( this.stationOptions[i].destination );
			}
		console.log(this.setStation);
		this.cashmemoDtoForPrint= new CashMemoDto();
		this.cashmemoDtoForPrint.mode=mode;
		this.cashmemoDtoForPrint.destination=this.selectionStation;
		this.cashmemoDtoForPrint.status="collection";
		this.cashmemoDtoForPrint.column1="pending";
		this.cashmemoDtoForPrint.memoCheck=memoChk;
		this.cashmemoDtoForPrint.list= this.setStation;
		this.cashmemoDtoForPrint.companyId= this.userDataDtoReturnSession.companyId;
		console.log(this.cashmemoDtoForPrint);
		this.getCollectionCheckPrintOutDetails(manualCashmemoCheckPrintPopUpTemplate);
	}
	
	getCollectionCheckPrintOutDetails= (manualCashmemoCheckPrintPopUpTemplate) => {
    	         this.showSpinnerForAction = true;
    	         this.memoReport.getManualCashMemoCheckPrintOut(this.cashmemoDtoForPrint).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  console.log(response);
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Empty",
    	                         text: "No records found to print",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
						 this.getPrintData=response;
							this.manualCashmemoCheckPrintPopUp = this.modalService.open(manualCashmemoCheckPrintPopUpTemplate,
								{ centered: true,size: 'lg', windowClass: "modalClassForPopUpTruckOwner" });
							this.manualCashmemoCheckPrintPopUp.result.then((result) => {
								this.manualCashmemoCheckPrintClosePopUp = `Closed with: ${result}`;
							}, reason => {
								this.manualCashmemoCheckPrintClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
							});
							localStorage.clear();
							localStorage.setItem('popupManualCashmemoCheckDetails', JSON.stringify(this.cashmemoDtoForPrint));
							localStorage.setItem('popupManualCashmemoCheckPrintDetails', JSON.stringify(this.getPrintData));
							this.viewManualCashmemoCheckPrintPopUp = true;
							window.addEventListener('afterPopUp', (event) => {
								this.viewManualCashmemoCheckPrintPopUp = false;
							});
    	                }
						this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the details", "info");
                    }, () => console.log('done');
            };
			
	closeManualCashmemoCheckPrintPopUp() {
		this.manualCashmemoCheckPrintPopUp.close();
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}


