<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Consignee Rate Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy">
															<option selected value="collectionManWise">Collection
																Man Wise</option>
															<option value="sourceWise">Source Wise</option>
														</select>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Agent Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}agentName" type="text" class="form-control"
																[(ngModel)]="modelAgentName"
																[ngbTypeahead]="searchAgentName"
																(selectItem)="clickListnerForAgentName($event)"
																[resultFormatter]="formatterAgentName"
																[inputFormatter]="formatterAgentName"
																(focus)="focusAgentNameTA$.next($any($event).target.value)"
																placeholder="Select Agent Name" />
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Collection Man</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input #collectionMan id="{{pageId}}collectionMan"
															name="collectionMan" type="text" class="form-control"
															placeholder="Select Collection Man"
															[(ngModel)]="modelCollectionMan"
															[ngbTypeahead]="collectionManSearchTA"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterCollectionMan"
															[inputFormatter]="formatterCollectionMan"
															(focus)="focusCollectionManTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Less Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}lessType">
															<option selected>Select Less Type</option>
															<option value="rate">Rate</option>
															<option value="discount">Discount</option>
														</select>
													</div>
												</div>
											</div>


										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="getRateDetails()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearFields()">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>

							<table datatable id="{{pageId}}consigneeRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsConsigneeRateReport"
								[dtTrigger]="dtTriggerConsigneeRateReport">

								<thead>
									<tr>
										<th>Consignee Name</th>
										<th>Source</th>
										<th>Deliv Type</th>
										<th>Unit</th>
										<th>Src.Ham</th>
										<th>Des.Ham</th>
										<th>Collection Man</th>
										<th>Rate</th>
										<th>Receipt Chg</th>
										<th>Service Chg</th>
										<th>GC Chg</th>
										<th>Per Unit</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let consigneeRateReportData of consigneeRateReportDataList ">
										<td>{{ consigneeRateReportData.consignee }}</td>
										<td>{{ consigneeRateReportData.source }}</td>
										<td>{{ consigneeRateReportData.deliveryType }}</td>
										<td>{{ consigneeRateReportData.unit }}</td>
										<td>{{ consigneeRateReportData.hamaliSource }}</td>
										<td>{{ consigneeRateReportData.hamaliDestination }}</td>
										<td>{{ consigneeRateReportData.collectionMan }}</td>
										<td>{{ consigneeRateReportData.rate }}</td>
										<td>{{ consigneeRateReportData.receiptCharges }}</td>
										<td>{{ consigneeRateReportData.serviceCharges }}</td>
										<td>{{ consigneeRateReportData.gcCharge }}</td>
										<td>{{ consigneeRateReportData.perUnit }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
								<tfoot>
									<tr>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>