<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBusinessStartRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Business Start Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">


											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates"
															ngbDatepicker #fromDates="ngbDatepicker"
															[(ngModel)]="fromDatesModal" autocomplete="off">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker" [(ngModel)]="toDatesModal"
															autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
														</div>
														<input id="{{pageId}}sourceStationId" type="text" class="form-control"
															(selectItem)="clickListnerForSourceStation($event)"
															[(ngModel)]="modelSourceStation"
															[ngbTypeahead]="searchSourceStation"
															[resultFormatter]="formatterSourceStation"
															[inputFormatter]="formatterSourceStation"
															(focus)="focusSourceStationTA$.next($any($event).target.value)"
															placeholder="Select Source.." />
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text" class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn();"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" (click)="validateClearBtn();"
								id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
							
						</div>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
												(click)="customPrintBusinessStartRpt()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}businessStartRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsBusinessStartRpt" [dtTrigger]="dtTriggerBusinessStartRpt">
								<thead>
									<tr>
										<th>Consignee Name</th>
										<th>1st Booking Date</th>
										<th>Total LRs</th>
										<th>Total Articles</th>
										<th>Total Act Wt</th>
										<th>Total Chg Wt</th>
										<th>Total To Pay</th>
										<th>Total Paid</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let businessStartRptData of businessStartRptDataList ">
										<!--<td>{{ businessStartRptData.consigneeName }}</td>-->
										<td style="color:blue;cursor: pointer;text-decoration: underline;"
											(click)="businessStartReportPopUpBtnLink(businessStartRptSummaryDetailsPopUpTemplate,businessStartRptData)">
											{{ businessStartRptData.consigneeName }}
										</td>
										<td>{{ businessStartRptData.bookingDateStr }}</td>
										<td>{{ businessStartRptData.totalLrs }}</td>
										<td>{{ businessStartRptData.totalArticles }}</td>
										<td>{{ businessStartRptData.actualWeight }}</td>
										<td>{{ businessStartRptData.chargedWeight }}</td>
										<td>{{ businessStartRptData.toPay }}</td>
										<td>{{ businessStartRptData.paid }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<!--POP UP START HERE-->
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group">
				<ng-template #businessStartRptSummaryDetailsPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div *ngIf="viewBusinessStartSummaryRptPopUp" onafterPopUp="afterPopUp()"
												id="{{pageId}}popupBusinessStartSummaryRpt">
												<app-business-start-lrs-details-report>
												</app-business-start-lrs-details-report>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<button type="button" style='background: green;width: auto;color: white;float: right;'
									class="btn btn-outline-dark"
									(click)="closeBusinessStartSummaryRptPopUp()">Close</button>
							</div>
						</div>
					</div>
					<!--<div class="modal-footer">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<button type="button" style='background: green;width: 100%;color: white;'
								class="btn btn-outline-dark" (click)="closeStmtVerificationRptPopUp()">Close</button>
						</div>
						<div class="col-md-4"></div>
					</div>-->
				</ng-template>
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>