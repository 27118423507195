import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component( {
    selector: 'app-trip-creation-invoice-details',
    templateUrl: './trip-creation-invoice-details.component.html',
    styleUrls: ['./trip-creation-invoice-details.component.css']
} )
export class TripCreationInvoiceDetailsComponent implements OnInit {

    gettingDataFrmServiceFrExpensesDetailsEntryTable: any;


    expensesDetailsEntryDataList: any;


    onDestroyUnsubscribtionExpensesDetailsEntry: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //for the select option with filter starts

    controlLoaderHead = new FormControl();
    controlLoaderName = new FormControl();
    controlTruckNumber = new FormControl();
    controlSupplier = new FormControl();
    controlDriverName = new FormControl();
    controlEntryCp = new FormControl();
    controlExitCp = new FormControl();

    loaderHeadOptions = [
        { id: 1, label: 'Budhpur Labour' },
        { id: 2, label: 'Nangli Poona Labour' },
    ];

    loaderNameOptions = [
        { id: 1, label: 'Adhik' },
        { id: 2, label: 'Anoop' },
        { id: 3, label: 'Asif' },
        { id: 4, label: 'Ashok' }
    ];

    truckNumberOptions = [
        { id: 1, label: 'AB04UT5795' },
        { id: 2, label: 'AP02HT2216' },
        { id: 3, label: 'AP03AT2457' },
        { id: 4, label: 'AP03U8520' }
    ];
    supplierOptions = [
        { id: 1, label: 'A B Cargo Mover' },
        { id: 2, label: 'A B Cargo Movers' },
        { id: 3, label: 'A R C Logistics' },
        { id: 4, label: 'Anil Motors' }
    ];
    driverNameOptions = [
        { id: 1, label: 'Aamir' },
        { id: 2, label: 'Aarif' },
        { id: 3, label: 'Aadil' },
        { id: 3, label: 'Aalam' }
    ];
    entryCpOptions = [
        { id: 1, label: 'CP-Aland Road' },
        { id: 2, label: 'CP-Anmod' },
        { id: 3, label: 'CP-Bellary' }
    ];
    exitCpOptions = [
        { id: 1, label: 'CP-Aland Road' },
        { id: 2, label: 'CP-Anmod' },
        { id: 3, label: 'CP-Bellary' }
    ];


    //for the select option with filter ends



    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerExpensesDetailsEntry: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsExpensesDetailsEntry: any;
    pageId="tcidc";


    constructor( private expensesDetailsEntrys: ReportService , public changeDetectorRef : ChangeDetectorRef) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        this.dtOptionsExpensesDetailsEntry = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Particulars',
                    data: 'particulars'
                },
                {
                    title: 'Quantity',
                    data: 'quantity'
                },
                {
                    title: 'Unit',
                    data: 'unit'
                },
                {
                    title: 'Rate',
                    data: 'rate'
                },
                {
                    title: 'Amount',
                    data: 'amount'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                },
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                }

            ],

            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.gettingDataFrmServiceFrExpensesDetailsEntryTable = this.expensesDetailsEntrys.getSummaryData()
        this.onDestroyUnsubscribtionExpensesDetailsEntry = this.gettingDataFrmServiceFrExpensesDetailsEntryTable.subscribe( data => {
            this.expensesDetailsEntryDataList = data['data'];
            this.dtTriggerExpensesDetailsEntry.next();
        } );

        //the below code is for the getting data through json ends
    }

    ngOnDestroy(): void {
        this.dtTriggerExpensesDetailsEntry.unsubscribe();

        this.onDestroyUnsubscribtionExpensesDetailsEntry.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

//for the select option with filter ends
}