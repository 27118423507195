import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for redirect to other page starts
import { Router } from '@angular/router';
//for redirect to other page ends
import swal from 'sweetalert';
import { LrReportService } from "src/app/dataService/lr-report-service";
import { LRDto } from 'src/app/dto/LR-dto';
@Component( {
    selector: 'app-door-delivery-consignment-not-delivered-details',
    templateUrl: './door-delivery-consignment-not-delivered-details.component.html',
    styleUrls: ['./door-delivery-consignment-not-delivered-details.component.css']
} )
export class DoorDeliveryConsignmentNotDeliveredDetailsComponent implements  OnInit {

    doorDeliveryConsignmentNotDeliveredDetailsDataList: any;
    dtTriggerDoorDeliveryConsignmentNotDeliveredDetails: Subject<any> = new Subject();
    dtOptionsDoorDeliveryConsignmentNotDeliveredDetails: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
    showAllDest = false;
    pageId="ddcnc";
    
    constructor(private router: Router,private lrReportService : LrReportService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "All Dest DDLrs Undlvd") {
                        this.showAllDest = true;
                    }
                }
            }
        }

    }

    ngOnInit(): void {
        this.dtOptionsDoorDeliveryConsignmentNotDeliveredDetails = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }
    // ngOnDestroy(): void {
    //     this.dtTriggerDoorDeliveryConsignmentNotDeliveredDetails.unsubscribe();
    // }
    
    ngAfterViewInit(): void {
        this.dtTriggerDoorDeliveryConsignmentNotDeliveredDetails.next();
    }
    //for redirect to other page starts
    goToLrEnquiryPage() {
        this.router.navigate( ['/lr/enquiry/lrEnquiry'] );
    }
    //for redirect to other page starts
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.lrDtoUserValue = new LRDto();
		if(this.showAllDest == false){
			this.lrDtoUserValue.destination = this.userDataDtoReturnSession.mainStation;
		}
		this.lrReportService.getUndeliveredDDLrs(this.lrDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"doorDelvlrsUnDelvdTableId").DataTable().destroy();
				this.doorDeliveryConsignmentNotDeliveredDetailsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Door Delivery Consignment Undelivered",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.doorDeliveryConsignmentNotDeliveredDetailsDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerDoorDeliveryConsignmentNotDeliveredDetails.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
