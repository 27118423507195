import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for service starts
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { ReportService } from 'src/app/dataService/report-service';
import { HttpClient } from '@angular/common/http';
//for service ends

import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";

//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { DatePipe } from "@angular/common";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

//for the select option with filter ends
@Component({
    selector: 'app-loader-performance-report',
    templateUrl: './loader-performance-report.component.html',
    styleUrls: ['./loader-performance-report.component.css'],

})
export class LoaderPerformanceReportComponent implements OnInit {

    loaderPerformaceReportDataList: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLoaderPerformaceReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLoaderPerformaceReport: any;

    searchByHireslipWise = true;
    searchByLoaderWise = false;
    viewDate = false;
    searchByTruckWise = false;

    staffDetailsLrDto: LRDto = new LRDto();
    loaderStaffOption: LRDto[];
    public modelLoaderStaffName: any;
    loaderStaffNameTA: Array<LRDto> = [];
    focusLoaderStaffNameTA$ = new Subject<string>();
    searchLoaderStaffName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusLoaderStaffNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.loaderStaffNameTA
                : this.loaderStaffNameTA.filter(v => v.staffName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterLoaderStaffName = (x: { staffName: string }) => x.staffName;

    truckDataDtoForTruckNo: TruckDataDto = new TruckDataDto();
    truckNumberOptions: TruckDataDto[];
    public modelTruckNumber: any;
    truckNumberTA: Array<TruckDataDto> = [];
    focusTruckNumber$ = new Subject<string>();
    searchTruckNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusTruckNumber$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.truckNumberTA
                : this.truckNumberTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterTruckNumber = (x: { truckNumber: string }) => x.truckNumber;

    lrDtoForSearch: LRDto = new LRDto();
    validateSelectedInvoiceNo: any;
    validateSelectedFromDate: any;
    validateSelectedToDate: any;
    validateSelectedLoaderName: any;
    validateSelectedVehicleNo: any;

    //print
    viewCustomPrintV1 = false;

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;
    pageId="ldprc";

    constructor(private reportService: ReportService, private router: Router,
        private masterReadService: MasterReadService, private datePipe: DatePipe,
        private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStaffNameDetailsList();
            this.getTruckMasterDetailsList();
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnInit(): void {
        this.dtOptionsLoaderPerformaceReport = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var actWgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gurWt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var kantaWt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var toPay = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);



                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(actWgt);
                $(api.column(3).footer()).html(gurWt);
                $(api.column(4).footer()).html(kantaWt);
                $(api.column(5).footer()).html(totalAmt);
                $(api.column(6).footer()).html(toPay);
            },
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerLoaderPerformaceReport.unsubscribe();
        //this.onDestroyUnsubscribtionLoaderPerformaceReport.unsubscribe();
    }



    ngAfterViewInit(): void {
        this.dtTriggerLoaderPerformaceReport.next();
    }


    searchByMode(searchBy: string) {
        if (searchBy === 'Hireslip Wise') {
            this.searchByHireslipWise = true;
            this.searchByLoaderWise = false;
            this.viewDate = false;
            this.searchByTruckWise = false;

        } else if (searchBy === 'Loader Wise') {
            this.searchByHireslipWise = false;
            this.searchByLoaderWise = true;
            this.viewDate = true;
            this.searchByTruckWise = false;

        } else if (searchBy === 'Truck Wise') {
            this.searchByHireslipWise = false;
            this.searchByLoaderWise = false;
            this.viewDate = true;
            this.searchByTruckWise = true;

        } else {
            this.searchByHireslipWise = false;
            this.searchByLoaderWise = false;
            this.viewDate = false;
            this.searchByTruckWise = false;
        }
    }



    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    clickListnerForLoaderStaffName(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelLoaderStaffName = e.item;
        $("#"+this.pageId+"loaderStaffName").val(this.modelLoaderStaffName.staffName);
    }

    getStaffDetailsForRead() {
        this.staffDetailsLrDto = new LRDto();
        this.staffDetailsLrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStaffNameDetailsList = () => {
        this.getStaffDetailsForRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getStaffDetails(this.staffDetailsLrDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.loaderStaffOption = [];
                    this.loaderStaffNameTA = [];
                } else {
                    this.loaderStaffOption = [];
                    this.loaderStaffNameTA = [];
                    this.loaderStaffOption = response;
                    for (let i = 0; i < this.loaderStaffOption.length; i++) {
                        this.loaderStaffNameTA.push(this.loaderStaffOption[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Loader Name Details", "warning");
            },
            () => console.log('done');
    };

    clickListnerForTruckNumber(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelTruckNumber = e.item;
        $("#"+this.pageId+"truckNumber").val(this.modelTruckNumber.truckNumber);
    }


    getDriverDetailsForReadTruckNo() {
        this.truckDataDtoForTruckNo = new TruckDataDto();
        this.truckDataDtoForTruckNo.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDtoForTruckNo.status = "Working";
    }

    getTruckMasterDetailsList = () => {
        this.getDriverDetailsForReadTruckNo();
        this.showSpinnerForAction = true;
        this.masterReadService.getTruck(this.truckDataDtoForTruckNo).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                } else {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                    this.truckNumberOptions = response;
                    for (let i = 0; i < this.truckNumberOptions.length; i++) {
                        this.truckNumberTA.push(this.truckNumberOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Truck Master Details", "warning");
            },
            () => console.log('done');
    };


    validateSearchBtn() {
        this.getLoaderPerformanceDetailsList();
    }

    getLoaderPerformanceDetailsList() {
        this.lrDtoForSearch = new LRDto();
        this.validateSelectedInvoiceNo = $("#"+this.pageId+"hireslipNumber").val();
        this.validateSelectedFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateSelectedToDate = $("#"+this.pageId+"toDates").val();
        this.validateSelectedLoaderName = $("#"+this.pageId+"loaderStaffName").val();
        this.validateSelectedVehicleNo = $("#"+this.pageId+"truckNumber").val();

        this.lrDtoForSearch.fromDate = this.validateSelectedFromDate;
        this.lrDtoForSearch.toDate = this.validateSelectedToDate;
        if ($("#"+this.pageId+"searchBy").val() == "Hireslip Wise") {
            this.lrDtoForSearch.invoiceNumber = this.validateSelectedInvoiceNo;
        } else if ($("#"+this.pageId+"searchBy").val() == "Loader Wise") {
            this.lrDtoForSearch.loaderName = this.validateSelectedLoaderName;
        } else if ($("#"+this.pageId+"searchBy").val() == "Truck Wise") {
            this.lrDtoForSearch.vehicleNumber = this.validateSelectedVehicleNo;
        }
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getLoaderPerformRptDtls(this.lrDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.loaderPerformaceReportDataList = [];
                $("#"+this.pageId+"loaderPerformanceRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.loaderPerformaceReportDataList = response;
                } else {
                    swal("No Data", "No Records  found for this search", "warning");
                }
                this.dtTriggerLoaderPerformaceReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Loader Performance Details", "warning");
            },
            () => console.log('done');
    };

    clearAll() {
        this.clearField();
    }

    clearField() {
        $("#"+this.pageId+"searchBy").val('Hireslip Wise');
        this.searchByHireslipWise = true;
        this.searchByLoaderWise = false;
        this.viewDate = false;
        this.searchByTruckWise = false;

        $("#"+this.pageId+"hireslipNumber").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"loaderStaffName").val('');
        $("#"+this.pageId+"truckNumber").val('');
        $("#"+this.pageId+"fromDates").val('');

        this.lrDtoForSearch = new LRDto();
        this.validateSelectedInvoiceNo = null;
        this.validateSelectedFromDate = null;
        this.validateSelectedToDate = null;
        this.validateSelectedLoaderName = null;
        this.validateSelectedVehicleNo = null;

        this.modelTruckNumber = null;
        this.modelLoaderStaffName = null;

        this.loaderPerformaceReportDataList = [];
        $("#"+this.pageId+"loaderPerformanceRptTableId").DataTable().destroy();
        this.dtTriggerLoaderPerformaceReport.next();
    }


    customPrintLoaderPerfor() {
        if (this.loaderPerformaceReportDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["HireSlipNo", "Act Wt", "Gur Wt",
                "Kan Wt", "(Gur-Kan)Wt", "ToPay", "Remarks"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 10, 10, 10, 10, 35];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < this.loaderPerformaceReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.loaderPerformaceReportDataList[i].invoiceNumber,
                    this.loaderPerformaceReportDataList[i].actualWeight,
                    this.loaderPerformaceReportDataList[i].guranteeWt,
                    this.loaderPerformaceReportDataList[i].kantaWeight,
                    this.loaderPerformaceReportDataList[i].totalAmount,
                    this.loaderPerformaceReportDataList[i].toPay,
                    this.loaderPerformaceReportDataList[i].remarks
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + this.loaderPerformaceReportDataList[i].actualWeight;
                this.summary2 = this.summary2 + this.loaderPerformaceReportDataList[i].guranteeWt;
                this.summary3 = this.summary3 + this.loaderPerformaceReportDataList[i].kantaWeight;
                this.summary4 = this.summary3 + this.loaderPerformaceReportDataList[i].totalAmount;
                this.summary5 = this.summary3 + this.loaderPerformaceReportDataList[i].toPay;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.loaderPerformaceReportDataList.length,
                this.summary1, this.summary2, this.summary3, this.summary4, this.summary5, ""];
            }
            //heading logics For Date
            //this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
            //this.toDatePrint = $("#"+this.pageId+"toDates").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#"+this.pageId+"fromDates").val(), $("#"+this.pageId+"toDates").val()];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Loader Performance Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

}
