<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetails">
            <ng-template #content let-a="close" let-b="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">GST Invoice Detained</h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form>


                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Invoice Number :</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}invoiceNumber" class="form-control" (keyup)="invoiceOnEnter($event)" (blur)="invoiceNoBlur()" aria-describedby="basic-addon11" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Detained Date</label> <input class="form-control" id="{{pageId}}detainedDate" placeholder="dd-mm-yyyy" name="detainedDates" ngbDatepicker #detainedDates="ngbDatepicker">
                                            <div class="input-group-append" (click)="detainedDates.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Remarks :</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}remarks" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" id={{pageId}}confirmBtn (click)="submitChanges();">Save</button>

                    <button type="button" class="btn btn-outline-dark" (click)="b('Save click')">Cancel</button>
                </div>
            </ng-template>

            <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="open2(content)">Click Here</a>
        </div>
    </div>
</div>