import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';


@Component({
    selector: 'app-business-start-report',
    templateUrl: './business-start-report.component.html',
    styleUrls: ['./business-start-report.component.css']
})
export class BusinessStartReportComponent implements OnInit {

    getDataFrmServiceFrTable: any;

    businessStartRptDataList: any;

    onDestroyUnsubscribtionBusinessStartRpt: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBusinessStartRpt: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsBusinessStartRpt: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelSourceStation: any;
    sourceStationTA: Array<AgentDetailsDto> = [];
    focusSourceStationTA$ = new Subject<string>();
    searchSourceStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceStationTA
                : this.sourceStationTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceStation = (x: { mainBookStations: string }) => x.mainBookStations;

    destinationAll: boolean = false;
    showSpinnerForAction = false;

    fromDatesModal: any = null;
    toDatesModal: any = null;
    validateSourceIdValue: any = null;
    validateDestinationIdValue: any = null;
    validateFromDateIdValue: any = null;
    validateToDateIdValue: any = null;

    lrDtoForSearch: LRDto = new LRDto();
    lrDtoLocalStorage: LRDto = new LRDto();

    modalRefferenceBusinessStartReportPopUp: NgbModalRef;
    modalRefferenceBusinessStartReportClosePopUp: string;
    @ViewChild('businessStartRptSummaryDetailsPopUpTemplate') private businessStartRptSummaryDetailsPopUpTemplate;
    viewBusinessStartSummaryRptPopUp = false;

//For Custom Print
viewCustomPrintV1:any;

cashMemoDtoForCustomPrintListHeadingV1: any;
cashMemoDtoForCustomPrintListHeadingV2:any;
cashMemoDtoForCustomPrintListHeadingV3: any;
cashMemoDtoForCustomPrintListHeadingV4: any;

cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintList: any;
cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
cashMemoDtoForCustomPrintDataList: any;
cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
cashMemoDtoForCustomPrintDataSummaryList: any;

				
totLrsSummary:number;
totArtSummary:number;

totActWtSummary:number;
totChgWtSummary:number;
totToPaySummary:number;
totPaidSummary:number;
pageId="bstrc";
										   
										   
								


    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private consigneeService: ConsigneeService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "destinationAllCneeBusStopRpt") {
                        this.destinationAll = true;
                    }
                }
            }
            this.getDestinationDetails();
            this.getSourceDetails();
            //this.fromDatesModal = moment(new Date()).format("YYYY-MM-DD");
            //$("#"+this.pageId+"fromDates").val(moment(new Date()).format("YYYY-MM-DD"));
            //$("#"+this.pageId+"toDates").val(moment(new Date()).format("YYYY-MM-DD"));
        }


    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsBusinessStartRpt = {
            dom: 'Bfrtip',
            buttons: [
                
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "New Consignee Summary Report - " +
                            "From Date : " + moment($("#bstrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#bstrctoDates").val()).format('DD-MM-YYYY') + " - " +
                            "Source : " + $("#bstrcsourceStationId").val() + " - " +
                            "Destination : " + $("#bstrcdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var articles = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(3).footer()).html(articles);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(6).footer()).html(topay);
                $(api.column(7).footer()).html(paid);
            }
        }

    }

    ngAfterViewInit(): void {
        this.dtTriggerBusinessStartRpt.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerBusinessStartRpt.unsubscribe();
        //this.onDestroyUnsubscribtionBusinessStartRpt.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
        // this.validateDestinationIdValue = this.modelDestination.destination;
    }
    clickListnerForSourceStation(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSourceStation = e.item;
        $("#"+this.pageId+"sourceStationId").val(this.modelSourceStation.mainBookStations);
        // this.validateDestinationIdValue = this.modelDestination.destination;
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        if (this.destinationAll == true) {
            this.lrDtoDestination.mode = "LrForm";
        } else {
            this.lrDtoDestination.mode = "ALL";
            this.lrDtoDestination.mainstation = this.userDataDtoReturnSession.mainStation;
        }
        console.log(this.destinationAll);
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getSourceDetails() {
        this.getSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.sourceStationTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "All";
                    this.sourceStationTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.sourceStationTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
            },
            () => console.log('done');

    };
    validateClearBtn() {
        this.clearField();
        this.businessStartRptDataList = [];
        $("#"+this.pageId+"businessStartRptTableId").DataTable().destroy();
        this.dtTriggerBusinessStartRpt.next();
    }
    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.validateSourceIdValue = null;
        this.validateDestinationIdValue = null;
        this.validateFromDateIdValue = null;
        this.validateToDateIdValue = null;
        this.modelDestination = null;
        this.modelSourceStation = null;
    }

    validateSearchBtn() {
        this.validateSourceIdValue = $("#"+this.pageId+"sourceStationId").val();
        this.validateDestinationIdValue = $("#"+this.pageId+"destinationId").val();
        this.validateFromDateIdValue = $("#"+this.pageId+"fromDates").val();
        this.validateToDateIdValue = $("#"+this.pageId+"toDates").val();

        if ((this.validateFromDateIdValue == null) || (this.validateFromDateIdValue == undefined) ||
            (this.validateFromDateIdValue == "") || (this.validateToDateIdValue == null) ||
            (this.validateToDateIdValue == undefined) || (this.validateToDateIdValue == "") ||
            (this.validateSourceIdValue == null) || (this.validateSourceIdValue == undefined) ||
            (this.validateSourceIdValue == "") || (this.validateDestinationIdValue == null) ||
            (this.validateDestinationIdValue == undefined) || (this.validateDestinationIdValue == "")) {
            swal("Mandatory Fields", "Please select the mandatory field", "warning");
            return false;
        } else {
            this.setBusinessStartRptDetails();
        }
    }

    getBusinessStartRptDataDetails() {
        this.lrDtoForSearch = new LRDto();

        this.validateSourceIdValue = $("#"+this.pageId+"sourceStationId").val();
        this.validateDestinationIdValue = $("#"+this.pageId+"destinationId").val();
        this.validateFromDateIdValue = $("#"+this.pageId+"fromDates").val();
        this.validateToDateIdValue = $("#"+this.pageId+"toDates").val();

        this.lrDtoForSearch.fromDate = this.validateFromDateIdValue;
        this.lrDtoForSearch.toDate = this.validateToDateIdValue;
        this.lrDtoForSearch.source = this.validateSourceIdValue;
        this.lrDtoForSearch.destination = this.validateDestinationIdValue;
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
    }
    setBusinessStartRptDetails() {
        this.getBusinessStartRptDataDetails();
        this.showSpinnerForAction = true;
        //console.log(this.lrDtoForSearch);
        this.consigneeService.getBusinessStartReport(this.lrDtoForSearch).subscribe(
            (response) => {
                //console.log(response);
                this.businessStartRptDataList = [];
                $("#"+this.pageId+"businessStartRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.businessStartRptDataList = response;
                    setTimeout(() => {
                        this.dtTriggerBusinessStartRpt.next();
                    }, 3000);
                    this.showSpinnerForAction = false;
                } else {
                    swal("No Data", "No datas found for this information", "warning");
                    setTimeout(() => {
                        this.dtTriggerBusinessStartRpt.next();
                    }, 3000);
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the business start report  Details", "info");
            },
            () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    businessStartReportPopUpBtnLink(businessStartRptSummaryDetailsPopUpTemplate, businessStartRptData) {
        this.modalRefferenceBusinessStartReportPopUp = this.modalService.open(businessStartRptSummaryDetailsPopUpTemplate,
            { centered: true, size: "lg" });
        //{ centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceBusinessStartReportPopUp.result.then((result) => {
            this.modalRefferenceBusinessStartReportClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceBusinessStartReportClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.lrDtoLocalStorage = new LRDto();
        this.validateFromDateIdValue = null;
        this.validateToDateIdValue = null;
        this.validateFromDateIdValue = $("#"+this.pageId+"fromDates").val();
        this.validateToDateIdValue = $("#"+this.pageId+"toDates").val();
        this.lrDtoLocalStorage.fromDate = moment(this.validateFromDateIdValue).format("DD-MM-YYYY");
        this.lrDtoLocalStorage.toDate = moment(this.validateToDateIdValue).format("DD-MM-YYYY");
        this.lrDtoLocalStorage.consigneeId = businessStartRptData.consigneeId;
        this.lrDtoLocalStorage.consigneeName = businessStartRptData.consigneeName;
        localStorage.clear();
        localStorage.setItem('businessStartRptLrsDetailsPopUpTemplate', JSON.stringify(this.lrDtoLocalStorage));
        this.viewBusinessStartSummaryRptPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewBusinessStartSummaryRptPopUp = false;
        });
    }
    closeBusinessStartSummaryRptPopUp() {
        this.modalRefferenceBusinessStartReportPopUp.close();
    }


    customPrintBusinessStartRpt()
    {
        if (this.businessStartRptDataList.length==0) {
            swal({
        
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }else{
        
                localStorage.clear();
                this.cashMemoDtoForCustomPrintList = [];
                this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "1st Booking Date","Total LR's", "Total Articles", "Total Act wt","Total Chg wt","Total To Pay","Total Paid"];
                this.cashMemoDtoForCustomPrintListColumnWidths = [25,15,10,10,10,10,10,10];
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                    this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                    this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
                }
                this.cashMemoDtoForCustomPrintDataList = [];
                this.cashMemoDtoForCustomPrintDataSummaryList = [];
           
                this.totLrsSummary = this.totArtSummary = this.totActWtSummary =this.totChgWtSummary =this.totToPaySummary =this.totPaidSummary = 0;
                for (let i = 0; i < this.businessStartRptDataList.length; i++) {
                    
                    this.cashMemoDtoForCustomPrintListColumnValues = [this.businessStartRptDataList[i].consigneeName, this.businessStartRptDataList[i].bookingDateStr,this.businessStartRptDataList[i].totalLrs,this.businessStartRptDataList[i].totalArticles,
                    this.businessStartRptDataList[i].actualWeight,this.businessStartRptDataList[i].chargedWeight,
                                           this.businessStartRptDataList[i].toPay, this.businessStartRptDataList[i].toPay,];
                    /////////
                    
                    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                    this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                    this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        
       
                    this.totLrsSummary=this.totLrsSummary+this.businessStartRptDataList[i].totalLrs;           
                    this.totArtSummary=this.totArtSummary+this.businessStartRptDataList[i].totalArticles;
                    this.totActWtSummary=this.totActWtSummary+this.businessStartRptDataList[i].actualWeight;
                    this.totChgWtSummary=this.totChgWtSummary+this.businessStartRptDataList[i].chargedWeight;
                    this.totToPaySummary=this.totActWtSummary+this.businessStartRptDataList[i].toPay;
                    this.totPaidSummary=this.totChgWtSummary+this.businessStartRptDataList[i].paid;
                    this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.businessStartRptDataList.length,"",this.totLrsSummary,this.totArtSummary,this.totActWtSummary,this.totChgWtSummary,this.totToPaySummary ,this.totPaidSummary];
                }
               
                
                //heading logics For Date
               
               
              //  this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
                this.cashMemoDtoForCustomPrintListHeadingV1 = [];
                this.cashMemoDtoForCustomPrintListHeadingV2 = [];
                this.cashMemoDtoForCustomPrintListHeadingV3 = [];
                this.cashMemoDtoForCustomPrintListHeadingV4 = [];
               
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"] ;
                this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Address"] ;
       
                
           this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrDtoForSearch.fromDate, this.lrDtoForSearch.toDate];
           this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [  this.lrDtoForSearch.destination];
               
            
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
                }
               
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
                }
        
                //this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
                //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                    //this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    //this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                    //this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            //	}
                
                //this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
                //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
                //	this.cashMemoDtoForCustomPrint = new CashMemoDto();
                //	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
                //	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
                //}
        
                localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
                localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
                localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
               
                //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
                //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
                localStorage.setItem('printTitle', "New Consignee Summary Report");
                this.viewCustomPrintV1 = true;
                
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewCustomPrintV1) {
                        this.viewCustomPrintV1 = false;
                        localStorage.clear();
                    }
                });
        
            
            
        }
}
}
