<!-- Row -->
<div class="row">

	<div class="col-lg-12">
		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Stocks Between Godowns</h6>
			</div>
			<hr style="margin: 0px;">
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<h6 class="card-title">LR Info</h6>
							<div class="row">
								<div class="col-sm-12 col-md-3">
									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>From Godown</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-warehouse"></i>
													</span>
												</div>
												<input type="text" #fromGodown class="form-control" id="{{pageId}}fromGodown"
													placeholder="Select From Godown"
													(selectItem)="fromGodownDropDownListner($event,popModelNewGodownContent)"
													[(ngModel)]="modelFromGodown" [ngbTypeahead]="fromGodownSearchTA"
													[resultFormatter]="formatterFromGodown"
													[inputFormatter]="formatterFromGodown"
													(focus)="focusFromGodownTA$.next($any($event).target.value)" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-3">
									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>To Godown</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-warehouse"></i>
													</span>
												</div>
												<input type="text" #toGodown class="form-control" id="{{pageId}}toGodown"
													placeholder="Select To Godown"
													(selectItem)="toGodownDropDownListner($event,popModelNewGodownContent)"
													[(ngModel)]="modelToGodown" [ngbTypeahead]="toGodownSearchTA"
													[resultFormatter]="formatterToGodown"
													[inputFormatter]="formatterToGodown"
													(focus)="focusToGodownTA$.next($any($event).target.value)" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<div class="input-group">
											<label>LR Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
												</span>
											</div>
											<input type="text" class="form-control" aria-describedby="basic-addon11"
												id="{{pageId}}lrNumber" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}okBtn"
										(click)="validateLRNumberOKClickListnerBtn();">Ok</button>
									<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
										(click)="validateClearClickListnerBtn();">Clear</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr style="width: 98%; margin: 0px 15px;">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row p-t-5">
								<div class="col-md-10">
									<div class="box-body">
										<table id="{{pageId}}stockBetweenGodownTableId" datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsStocksBetweenGodown"
											[dtTrigger]="dtTriggerStocksBetweenGodown">
											<thead>
												<tr>
													<th>LR Number</th>
													<th>Total Articles</th>
													<th>Consignee Name</th>
													<th>Consignor Number</th>
													<th>Description</th>
													<th>Weight</th>
													<th>Amount</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr
													*ngFor="let stocksBetweenGodownData of stocksBetweenGodownDataList;let i = index  ">
													<td>{{ stocksBetweenGodownData.lrNumber }}</td>
													<td>{{ stocksBetweenGodownData.totalArticles }}</td>
													<td>{{ stocksBetweenGodownData.consigneeName }}</td>
													<td>{{ stocksBetweenGodownData.consignorName }}</td>
													<td>{{ stocksBetweenGodownData.description }}</td>
													<td>{{ stocksBetweenGodownData.actualWeight }}</td>
													<td>{{ stocksBetweenGodownData.totalAmount }}</td>
													<td>
														<button type="button"
															style="padding: 1px 4px; background-color: #ffffff00;"
															class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
															(click)="rowSelectedDeleteForTable(stocksBetweenGodownData,i);">
															<i title="Remove" class="fas fa-trash"></i>
														</button>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>{{countOfLr}}</td>
													<td>{{sumOfTotArticle}}</td>
													<td></td>
													<td></td>
													<td></td>
													<td>{{sumOfTotActWgt}}</td>
													<td>{{sumOfTotAmt}}</td>
													<td></td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
								<div class="col-md-2 margin_bt">
									<button type="submit" class="btn btn-success m-r-10"
										(click)="transferClickListnerBtn();" id="{{pageId}}transferBtnId">Transfer</button>
									<!--<button type="submit" class="btn btn-dark" (click)="remove();"
										id="{{pageId}}removeBtn">Remove</button>-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<!-- to open popup add this @(click)=" open( content )"@ -->

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group">
			<ng-template #popModelNewGodownContent let-save="close" let-cancel="close" let-d="dismiss">
				<div class="modal-header">
					<h6>New Godown</h6>
					<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row" style="text-align: center;">
						<div class="col-md-12">
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group">
										<label>Godown Name</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i class="fas fa-warehouse"></i>
											</span>
										</div>
										<input type="text" class="form-control" id="{{pageId}}newGodownName"
											aria-describedby="basic-addon11">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-secondary" (click)="saveNewGodownBtn();">Save</button>
					<button type="button" class="btn btn-outline-danger"
						(click)="cancel('Cancel click')">Cancel</button>
				</div>
			</ng-template>
		</div>
	</div>
</div>