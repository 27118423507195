import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
const my = new Date();
import { LRDto } from "src/app/dto/LR-dto";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";

@Component({
    selector: 'app-truck-report',
    templateUrl: './truck-rpt.component.html',
    styleUrls: ['./truck-rpt.component.css']
})
export class TruckReportComponent implements OnInit {

    truckRptDetailDataList: any;
    lorryHireRptDataList: any;
    supplierRptDataList: any;
    truckInvoiceRptDataList: any;
    model: NgbDateStruct;
    searchDate = true;
    viewDate = true;
    truckRptStationWiseTable = true;
    truckRptDetailWiseTable = false;
    lorryHireRptTable = false;
    supplierRptTable = false;
    truckInvoiceRptTable = false;
    viewSupplier = false;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    userDataDtoReturnSession: any;
    userRole = "Truck Rpt Show Src All";
    isLoggedIn = true;
    supplierNameOptions: SupplierDetailsDto[];
    supplierDetailsList: any;
    supplierNameAll: SupplierDetailsDto = new SupplierDetailsDto();
    lrDto: LRDto = new LRDto();
    hireSlipDto: HireSlipDto = new HireSlipDto();
    toStationNameOptions: LRDto[];
    toStationDetailsList: any;
    fromStation: any;
    fromStationArray: Array<any> = [];
    fromStationNameOptions: LRDto[];
    fromStationDetailsList: any;
    lrDtoFromStation: LRDto = new LRDto();
    lrDtoFromStationAll: LRDto = new LRDto();
    lrDtoToStationAll: LRDto = new LRDto();
    selectedSearchBy: any;
    selectedToStations: any;
    selectedDate: any;
    selectedSource: any;
    dateNeedToAdd: string;
    dateToAddInPrint: string;

    pageTitle: string;
    selectedFromDate: any;
    selectedToDate: any;
    selectedSupplier: any;
    viewSource: boolean;

    dtTriggerTruckDetailsRpt: Subject<any> = new Subject();
    dtTriggerLorryHireRpt: Subject<any> = new Subject();
    dtTriggerTruckInvoiceRpt: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsTruckDetailsRpt: any;
    dtOptionsLorryHireRpt: any;
    dtOptionsTruckInvoiceRpt: any;
    ///For Agent Names
    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.agentNameTA
                    : this.agentNameTA.filter(
                        (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    superAdminview: boolean = false;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    showSpinnerForAction = false;
    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];
    showSource = false;

    //For Sorce Main Stations
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { source: string }) => x.source;
    //    For From Station 
    lrDtoFromStn: LRDto = new LRDto();
    lrDtoFromStnAll: LRDto = new LRDto();
    lrDtosFromStnOptionsGet: LRDto[];
    srcListForBkgAdmin: LRDto = new LRDto();
    rightsOfTruckRpt: any;

    supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
    supplierDetailsDtoAll: SupplierDetailsDto = new SupplierDetailsDto();
    supplierOptions: SupplierDetailsDto[];
    public modelSupplierName: any;
    supplierNameTA: Array<SupplierDetailsDto> = [];
    focusSupplierNameTA$ = new Subject<string>();
    searchSupplierName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSupplierNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.supplierNameTA
                : this.supplierNameTA.filter(v => v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSupplierName = (x: { supplierName: string }) => x.supplierName;
    srcLists: any;
    srcList: LRDto = new LRDto();

    truckDetailsTable = true;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    hireSlipDtoForTruckDetailsWise: HireSlipDto = new HireSlipDto();
    hireSlipDtoForLorryHireWise: HireSlipDto = new HireSlipDto();
    hireSlipDtoForTruckInvoiceWise: HireSlipDto = new HireSlipDto();
    srcListsSet: Array<any> = [];
    destListSet: Array<any> = [];

    viewCustomPrintV1 = false;
    viewCustomPrintV2 = false;
    viewCustomPrintV3 = false;

    grandTotalArticles = 0;
    grandActWt = 0;
    grandChWt = 0;
    grandTotalhire = 0;
    grandKantWt = 0;
    grandGurWt = 0;
    grandAdvance = 0;
    grandToPay = 0;
    grandPaid = 0;
    grandFreightAmt = 0;
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    pageId = "trrpt";
    showHireColumns: Boolean = false;

    constructor(private router: Router, private masterReadService: MasterReadService,
        private hireslipService: HireslipService, private datePipe: DatePipe,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.gettingFromStationList();
            this.getSupplierDetailsList();
            this.selectTodayDate();
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Truck Rpt R1") {
                        // For Bkg Admin
                        this.showSource = true;
                        this.rightsOfTruckRpt = 'Truck Rpt R1';
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Truck Rpt R2") {
                        // For Super Admin
                        this.showSource = true;
                        this.rightsOfTruckRpt = 'Truck Rpt R2';
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Truck Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
            if (this.showSource == true) {
                this.viewSource = true;
            }
        }
    }

    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    getSupplierDetails() {
        this.supplierDetailsDto = new SupplierDetailsDto();
        this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.supplierDetailsDto.mode = "Working";
    }
    getSupplierDetailsList = () => {
        this.getSupplierDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getSuplierMaster(this.supplierDetailsDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    swal("Warning", "No Supplier Master records found!", "warning");
                } else {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    this.supplierOptions = response;
                    this.supplierDetailsDtoAll.supplierName = "All";
                    this.supplierNameTA.push(this.supplierDetailsDtoAll);
                    for (let i = 0; i < this.supplierOptions.length; i++) {
                        this.supplierNameTA.push(this.supplierOptions[i]);
                    }
                }
                //this.showSpinnerForActionForInvoiceDetails = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Supplier Master Details", "warning");
            },
            () => console.log('done');
    };


    ngOnInit(): void {
        var exportTruckDetailsArray = [], exportLorryHireDetailsArray = [];
        if (this.showHireColumns) {
            exportTruckDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
            exportLorryHireDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        } else {
            exportTruckDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17];
            exportLorryHireDetailsArray = [0, 1, 2, 3, 4, 5, 8, 9, 10, 11];
        }

        this.dtOptionsTruckDetailsRpt = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportTruckDetailsArray
                },
                title: function () {
                    var returnPaidDate = null;
                    returnPaidDate = "Truck Details Report " +
                        " From Date : " + moment($("#trrptfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#trrpttoDate").val()).format('DD-MM-YYYY') + " Source : " + $("#trrptsource").val() + " Destination: " + $("#trrpttoStationName").val();
                    return returnPaidDate;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var gurWght = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var kantaWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var topay = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var frg = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hire = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(gurWght);
                $(api.column(7).footer()).html(kantaWgt);
                $(api.column(8).footer()).html(actWgt);
                $(api.column(9).footer()).html(chgWgt);
                $(api.column(10).footer()).html(topay);
                $(api.column(11).footer()).html(paid);
                $(api.column(12).footer()).html(frg);
                $(api.column(13).footer()).html(hire);
            }
        }

        this.dtOptionsLorryHireRpt = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportLorryHireDetailsArray
                },
                title: function () {
                    var returnDate = null;
                    returnDate = "Lorry Hire Report " +
                        " From Date : " + moment($("#trrptfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#trrpttoDate").val()).format('DD-MM-YYYY') + " Source : " + $("#trrptsource").val() + " Destination: " + $("#trrpttoStationName").val() + " Supplier : " + $("#trrptsupplierName").val();
                    return returnDate;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var gurWght = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var kantaWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var tothire = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var advance = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(gurWght);
                $(api.column(5).footer()).html(kantaWgt);
                $(api.column(6).footer()).html(tothire);
                $(api.column(7).footer()).html(advance);
            }
        }

        this.dtOptionsTruckInvoiceRpt = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {
                    var returInvoiceDate = null;
                    returInvoiceDate = "Truck Invoice Report " +
                        " From Date : " + moment($("#trrptfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#trrpttoDate").val()).format('DD-MM-YYYY') + " Source : " + $("#trrptsource").val() + " Destination: " + $("#trrpttoStationName").val();
                    return returInvoiceDate;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var gurWght = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var topay = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(gurWght);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(6).footer()).html(topay);
                $(api.column(7).footer()).html(paid);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerTruckDetailsRpt.unsubscribe();
        this.dtTriggerLorryHireRpt.unsubscribe();
        this.dtTriggerTruckInvoiceRpt.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerTruckDetailsRpt.next();
        this.dtTriggerLorryHireRpt.next();
        this.dtTriggerTruckInvoiceRpt.next();
    }

    clearTable() {
        $("#" + this.pageId + "truckDetailsTableId").DataTable().destroy();
        this.truckRptDetailDataList = [];
        this.dtTriggerTruckDetailsRpt.next();

        $("#" + this.pageId + "lorryHireRptTableId").DataTable().destroy();
        this.lorryHireRptDataList = [];
        this.dtTriggerLorryHireRpt.next();

        $("#" + this.pageId + "truckInvoiceRptTableId").DataTable().destroy();
        this.truckInvoiceRptDataList = [];
        this.dtTriggerTruckInvoiceRpt.next();
    }
    hideTable() {
        this.truckDetailsTable = false;
        this.lorryHireRptTable = false;
        this.truckInvoiceRptTable = false;
    }

    searchByMode(searchBy: string) {
        if (searchBy === 'Truck Details Rpt Wise') {
            this.viewDate = true;
            this.viewSupplier = false;
            this.hideTable();
            this.clearTable();
            this.truckDetailsTable = true;
            this.selectTodayDate();
        } else if (searchBy === 'Lorry Hire Rpt Wise') {
            this.viewDate = true;
            this.viewSupplier = true;
            this.hideTable();
            this.clearTable();
            this.lorryHireRptTable = true;
            this.selectTodayDate();
        } else if (searchBy === 'Truck Invoice Rpt Wise') {
            this.viewDate = true;
            this.viewSupplier = false;
            this.hideTable();
            this.clearTable();
            this.truckInvoiceRptTable = true;
            this.selectTodayDate();
        } else {
            this.searchDate = false;
            this.viewDate = false;
            this.viewSupplier = false;
            this.hideTable();
            this.clearTable();
            this.truckDetailsTable = true;
        }
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "other";
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        // this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.destinationDtoForAll.destination = "ALL";
                        this.destinationTA.push(this.destinationDtoForAll);

                        this.destinationList = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                            this.destinationList.push(this.destinationOptions[i].destination);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    //From Station here need to check the stationList
    gettingFromStation() {
        if ((this.rightsOfTruckRpt == 'Truck Rpt R1') && (this.userDataDtoReturnSession.stationList != null)) {
            // this.getAgentListDetails();
            this.srcLists = this.userDataDtoReturnSession.stationList;
            this.srcList.source = this.srcLists;
            this.lrDtoFromStnAll.source = "ALL";
            this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
            for (let i = 0; i < this.srcList.source.length; i++) {
                this.srcListForBkgAdmin = new LRDto();
                this.srcListForBkgAdmin.source = this.srcList.source[i];
                console.log(this.srcListForBkgAdmin.source);
                this.sourceSubStationNameTA.push(this.srcListForBkgAdmin);
            }
        } else {
            this.gettingFromStationList();
        }
    }
    gettingFromStationDetails() {
        this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromStn.mode = "notStmtInvRpt";
    }
    gettingFromStationList = () => {
        this.gettingFromStationDetails();
        this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.sourceSubStationNameTA = [];
                    this.lrDtosFromStnOptionsGet = response;
                    this.lrDtoFromStnAll.source = "ALL";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
                        this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    getSearchMethod() {
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {

            if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Truck Details Rpt Wise') {
                this.searchMethodForTruckDetailsWise();
            } else if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Lorry Hire Rpt Wise') {
                this.searchMethodForLorryHireRptWise();
            } else if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Truck Invoice Rpt Wise') {
                this.searchMethodForTruckInvoiceWise();
            }
        }

    }

    searchMethodForTruckDetailsWise() {
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.hireSlipDtoForTruckDetailsWise = new HireSlipDto();
        this.hireSlipDtoForTruckDetailsWise.stationList = this.userDataDtoReturnSession.stationList;
        this.hireSlipDtoForTruckDetailsWise.role = this.userDataDtoReturnSession.role;
        this.hireSlipDtoForTruckDetailsWise.fromDate = this.selectedFromDate;
        this.hireSlipDtoForTruckDetailsWise.toDate = this.selectedToDate;
        this.hireSlipDtoForTruckDetailsWise.toStation = this.selectedToStations;
        this.hireSlipDtoForTruckDetailsWise.fromStation = this.userDataDtoReturnSession.office;

        this.srcListsSet = [];
        this.srcListsSet.push(this.hireSlipDtoForTruckDetailsWise.fromStation);
        this.hireSlipDtoForTruckDetailsWise.fromStationList = this.srcListsSet;

        if ((this.viewSource == true) && (this.selectedSource == 'ALL')) {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForTruckDetailsWise.fromStation = this.selectedSource;
                this.hireSlipDtoForTruckDetailsWise.fromStationList = this.userDataDtoReturnSession.stationList;

            }
        } else {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForTruckDetailsWise.fromStation = this.selectedSource;
                this.srcListsSet = [];
                this.srcListsSet.push(this.hireSlipDtoForTruckDetailsWise.fromStation);
                this.hireSlipDtoForTruckDetailsWise.fromStationList = this.srcListsSet;
            }
        }
        if (this.hireSlipDtoForTruckDetailsWise.toStation == 'ALL') {
            this.hireSlipDtoForTruckDetailsWise.toStationList = this.destinationList;
        } else {
            this.destListSet = [];
            this.destListSet.push(this.hireSlipDtoForTruckDetailsWise.toStation);
            this.hireSlipDtoForTruckDetailsWise.toStationList = this.destListSet;
        }
        this.getTruckDetailsRpt();
    }

    getTruckDetailsRpt = () => {
        this.showSpinnerForAction = true;
        this.hireslipService.getTruckRptForTruckDetailsWise(this.hireSlipDtoForTruckDetailsWise).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "truckDetailsTableId").DataTable().destroy();
                this.truckRptDetailDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.truckRptDetailDataList = response;
                }
                this.dtTriggerTruckDetailsRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Truck Details Report ", "info");
            }, () => console.log('done');
    };


    searchMethodForLorryHireRptWise() {
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedSupplier = $("#" + this.pageId + "supplierName").val();
        this.hireSlipDtoForLorryHireWise = new HireSlipDto();
        this.hireSlipDtoForLorryHireWise.stationList = this.userDataDtoReturnSession.stationList;
        this.hireSlipDtoForLorryHireWise.role = this.userDataDtoReturnSession.role;
        this.hireSlipDtoForLorryHireWise.fromDate = this.selectedFromDate;
        this.hireSlipDtoForLorryHireWise.toDate = this.selectedToDate;

        this.hireSlipDtoForLorryHireWise.toStation = this.selectedToStations;
        this.hireSlipDtoForLorryHireWise.fromStation = this.userDataDtoReturnSession.office;
        this.srcListsSet = [];
        this.srcListsSet.push(this.hireSlipDtoForLorryHireWise.fromStation);
        this.hireSlipDtoForLorryHireWise.fromStationList = this.srcListsSet;
        // this.rightsOfTruckRpt = 'Truck Rpt R1';

        if (this.selectedSupplier != null && this.selectedSupplier != '' && this.selectedSupplier != 'All') {
            this.hireSlipDtoForLorryHireWise.suppliername = this.selectedSupplier;
        }

        if ((this.viewSource == true) && (this.selectedSource == 'ALL')) {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForLorryHireWise.fromStation = this.selectedSource;
                this.hireSlipDtoForLorryHireWise.fromStationList = this.userDataDtoReturnSession.stationList;

            }
        } else {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForLorryHireWise.fromStation = this.selectedSource;
                this.srcListsSet = [];
                this.srcListsSet.push(this.hireSlipDtoForLorryHireWise.fromStation);
                this.hireSlipDtoForLorryHireWise.fromStationList = this.srcListsSet;
            }

        }

        if (this.hireSlipDtoForLorryHireWise.toStation == 'ALL') {
            this.hireSlipDtoForLorryHireWise.toStationList = this.destinationList;
        } else {
            this.destListSet = [];
            this.destListSet.push(this.hireSlipDtoForLorryHireWise.toStation);
            this.hireSlipDtoForLorryHireWise.toStationList = this.destListSet;
        }
        this.getLorryHireRpt();
    }

    getLorryHireRpt = () => {
        this.showSpinnerForAction = true;
        this.hireslipService.getTruckRptForLorryHireWise(this.hireSlipDtoForLorryHireWise).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "lorryHireRptTableId").DataTable().destroy();
                this.lorryHireRptDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lorryHireRptDataList = response;
                }
                this.dtTriggerLorryHireRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Lorry Hire Details Report ", "info");
            }, () => console.log('done');
    };

    searchMethodForTruckInvoiceWise() {
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.hireSlipDtoForTruckInvoiceWise = new HireSlipDto();
        this.hireSlipDtoForTruckInvoiceWise.stationList = this.userDataDtoReturnSession.stationList;
        this.hireSlipDtoForTruckInvoiceWise.role = this.userDataDtoReturnSession.role;
        this.hireSlipDtoForTruckInvoiceWise.fromDate = this.selectedFromDate;
        this.hireSlipDtoForTruckInvoiceWise.toDate = this.selectedToDate;
        this.hireSlipDtoForTruckInvoiceWise.toStation = this.selectedToStations;
        this.hireSlipDtoForTruckInvoiceWise.fromStation = this.userDataDtoReturnSession.office;
        this.srcListsSet = [];
        this.srcListsSet.push(this.hireSlipDtoForTruckInvoiceWise.fromStation);
        this.hireSlipDtoForTruckInvoiceWise.fromStationList = this.srcListsSet;

        if ((this.viewSource == true) && (this.selectedSource == 'ALL')) {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForTruckInvoiceWise.fromStation = this.selectedSource;
                this.hireSlipDtoForTruckInvoiceWise.fromStationList = this.userDataDtoReturnSession.stationList;
            }
        } else {
            if ((this.rightsOfTruckRpt == 'Truck Rpt R1') || (this.rightsOfTruckRpt == 'Truck Rpt R2')) {
                // For Bkg Admin And SuperAdmin
                this.hireSlipDtoForTruckInvoiceWise.fromStation = this.selectedSource;
                this.srcListsSet = [];
                this.srcListsSet.push(this.hireSlipDtoForTruckInvoiceWise.fromStation);
                this.hireSlipDtoForTruckInvoiceWise.fromStationList = this.srcListsSet;
            }
        }

        if (this.hireSlipDtoForTruckInvoiceWise.toStation == 'ALL') {
            this.hireSlipDtoForTruckInvoiceWise.toStationList = this.destinationList;
        } else {
            this.destListSet = [];
            this.destListSet.push(this.hireSlipDtoForTruckInvoiceWise.toStation);
            this.hireSlipDtoForTruckInvoiceWise.toStationList = this.destListSet;
        }
        this.getTruckInvoiceRpt();
    }

    getTruckInvoiceRpt = () => {
        this.showSpinnerForAction = true;
        this.hireslipService.getTruckRptForTruckInvoiceWise(this.hireSlipDtoForTruckInvoiceWise).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "truckInvoiceRptTableId").DataTable().destroy();
                this.truckInvoiceRptDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.truckInvoiceRptDataList = response;
                }
                this.dtTriggerTruckInvoiceRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Truck Invoice Details Report ", "info");
            }, () => console.log('done');
    };


    printRpt() {
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Truck Details Rpt Wise') {
            this.printMethodForTruckDetailsWise();
        } else if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Lorry Hire Rpt Wise') {
            this.printMethodForLorryHireRptWise();
        } else if (this.selectedSearchBy != null && this.selectedSearchBy !== '' && this.selectedSearchBy == 'Truck Invoice Rpt Wise') {
            this.printMethodForTruckInvoiceWise();
        }
    }

    printMethodForTruckDetailsWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        if (this.showSource == true) {
            this.selectedSource = $("#" + this.pageId + "source").val();
        } else {
            this.selectedSource = this.userDataDtoReturnSession.office;
        }
        if (this.truckRptDetailDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Truck No", "Station", "Dep. Date", "Dep. Time", "Supplier", "Gur. Wgt", "Kanta Wgt", "Act. Wgt", "Chg. Wgt", "To Pay", "Paid", "Freight", "Total Hire", "Lorry Type", "Truck Length", "Loaded By", "Loaded By"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [5, 10, 5, 5, 5, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandTotalhire = 0;
            this.grandKantWt = 0;
            this.grandGurWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandFreightAmt = 0;

            for (let i = 0; i < this.truckRptDetailDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.truckRptDetailDataList[i].vehicleNumber, this.truckRptDetailDataList[i].toStation, this.truckRptDetailDataList[i].departuredateStr, this.truckRptDetailDataList[i].departureTime, this.truckRptDetailDataList[i].suppliername, this.truckRptDetailDataList[i].gurWeight, this.truckRptDetailDataList[i].kantaWeight, this.truckRptDetailDataList[i].actWeight, this.truckRptDetailDataList[i].chargedWt, this.truckRptDetailDataList[i].toPay, this.truckRptDetailDataList[i].paid, this.truckRptDetailDataList[i].totalAmt, this.truckRptDetailDataList[i].totalhire, this.truckRptDetailDataList[i].truckType, this.truckRptDetailDataList[i].trkLgth, this.truckRptDetailDataList[i].loadedby, this.truckRptDetailDataList[i].remarks];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandActWt = +this.grandActWt + +this.truckRptDetailDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.truckRptDetailDataList[i].chargedWt;
                this.grandTotalhire = +this.grandTotalhire + +this.truckRptDetailDataList[i].totalhire;
                this.grandKantWt = +this.grandKantWt + +this.truckRptDetailDataList[i].kantaWeight;
                this.grandGurWt = +this.grandGurWt + +this.truckRptDetailDataList[i].gurWeight;
                this.grandToPay = +this.grandToPay + +this.truckRptDetailDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.truckRptDetailDataList[i].paid;
                this.grandFreightAmt = +this.grandFreightAmt + +this.truckRptDetailDataList[i].totalAmt;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.truckRptDetailDataList.length, "", "", "", "", this.grandGurWt, this.grandKantWt, this.grandActWt, this.grandChWt, this.grandToPay, this.grandPaid, this.grandFreightAmt, this.grandTotalhire, "", "", "", ""];
            }
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedToStations];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Truck Details Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    printMethodForLorryHireRptWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        this.selectedSupplier = $("#" + this.pageId + "supplierName").val();
        if (this.showSource == true) {
            this.selectedSource = $("#" + this.pageId + "source").val();
        } else {
            this.selectedSource = this.userDataDtoReturnSession.office;
        }
        if (this.lorryHireRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Station", "HireSlip No.", "Lorry No.", "Supplier", "Guarantee Wt", "Kanta Wt", "Total Hire", "Advance", "Departure Date", "Invoice No", "Invoice Date", "Lorry Type"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 5, 5, 5, 5, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalhire = 0;
            this.grandKantWt = 0;
            this.grandGurWt = 0;
            this.grandAdvance = 0;
            for (let i = 0; i < this.lorryHireRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireRptDataList[i].toStation, this.lorryHireRptDataList[i].hireslipnumber, this.lorryHireRptDataList[i].vehicleNumber, this.lorryHireRptDataList[i].suppliername, this.lorryHireRptDataList[i].gurWeight, this.lorryHireRptDataList[i].kantaWeight, this.lorryHireRptDataList[i].totalhire, this.lorryHireRptDataList[i].advance, this.lorryHireRptDataList[i].departuredateStr, this.lorryHireRptDataList[i].hireslipnumber, this.lorryHireRptDataList[i].hireSlipDateStr, this.lorryHireRptDataList[i].truckType];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalhire = +this.grandTotalhire + +this.lorryHireRptDataList[i].totalhire;
                this.grandKantWt = +this.grandKantWt + +this.lorryHireRptDataList[i].kantaWeight;
                this.grandGurWt = +this.grandGurWt + +this.lorryHireRptDataList[i].gurWeight;
                this.grandAdvance = +this.grandGurWt + +this.lorryHireRptDataList[i].advance;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireRptDataList.length, "", "", "", this.grandGurWt, this.grandKantWt, this.grandTotalhire, this.grandAdvance, "", "", "", ""];
            }
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "To Station", "Supplier "];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedToStations, this.selectedSupplier];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Lorry Hire Report");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }

    printMethodForTruckInvoiceWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToStations = $("#" + this.pageId + "toStationName").val();
        if (this.showSource == true) {
            this.selectedSource = $("#" + this.pageId + "source").val();
        } else {
            this.selectedSource = this.userDataDtoReturnSession.office;
        }
        if (this.truckInvoiceRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Truck No", "Invoice No", "Article", "Gur. Wgt", "Act. Wgt", "Chg. Wgt", "To Pay", "Paid", "Destination", "Invoice Date"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalArticles = 0;
            this.grandGurWt = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            for (let i = 0; i < this.truckInvoiceRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.truckInvoiceRptDataList[i].vehicleNumber, this.truckInvoiceRptDataList[i].invoiceNumber, this.truckInvoiceRptDataList[i].totalArticles, this.truckInvoiceRptDataList[i].gurWeight, this.truckInvoiceRptDataList[i].actWeight, this.truckInvoiceRptDataList[i].chargedWt, this.truckInvoiceRptDataList[i].toPay, this.truckInvoiceRptDataList[i].paid, this.truckInvoiceRptDataList[i].toStation, this.truckInvoiceRptDataList[i].hireSlipDateStr];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalArticles = +this.grandKantWt + +this.truckInvoiceRptDataList[i].totalArticles;
                this.grandGurWt = +this.grandGurWt + +this.truckInvoiceRptDataList[i].gurWeight;
                this.grandActWt = +this.grandActWt + +this.truckInvoiceRptDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.truckInvoiceRptDataList[i].chargedWt;
                this.grandToPay = +this.grandToPay + +this.truckInvoiceRptDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.truckInvoiceRptDataList[i].paid;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.truckInvoiceRptDataList.length, "", this.grandTotalArticles, this.grandGurWt, this.grandActWt, this.grandChWt, this.grandToPay, this.grandPaid, "", ""];
            }
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedToStations];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Truck Invoice Report");
            this.viewCustomPrintV3 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV3) {
                    this.viewCustomPrintV3 = false;
                    localStorage.clear();
                }
            });
        }
    }
    clearAll() {
        this.selectedToStations = '';
        this.selectedSearchBy = '';
        this.selectTodayDate();
        $("#" + this.pageId + "toStationName").val('');
        this.selectedSource = '';
        $("#" + this.pageId + "source").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
        this.selectedSupplier = '';
        $("#" + this.pageId + "supplierName").val('');
        this.hideTable();
        this.clearTable();
        localStorage.clear();
        this.viewCustomPrintV2 = false;
        this.viewCustomPrintV3 = false;
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.grandTotalArticles = 0;
        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandTotalhire = 0;
        this.grandKantWt = 0;
        this.grandGurWt = 0;
        this.grandAdvance = 0;
        this.grandToPay = 0;
        this.grandPaid = 0;
        this.grandFreightAmt = 0;
        this.hireSlipDtoForTruckDetailsWise = new HireSlipDto();
        this.hireSlipDtoForLorryHireWise = new HireSlipDto();
        this.hireSlipDtoForTruckInvoiceWise = new HireSlipDto();
        this.showSpinnerForAction = false;
        this.srcListsSet = [];
        this.destListSet = [];
        $("#" + this.pageId + "searchBy").val('Truck Details Rpt Wise');
        this.truckDetailsTable = true;
        this.viewDate = true;
        this.viewSupplier = false;
    }
}
