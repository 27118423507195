import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LrService } from 'src/app/dataService/lr-service';


@Component({
    selector: 'app-agent-statement-rpt-lr-details-print',
    templateUrl: './agent-statement-rpt-lr-details-print.component.html',
    styleUrls: ['./agent-statement-rpt-lr-details-print.component.css']
})
export class AgentStatementRptLrDetailsPrintComponent implements OnInit {

    getDataFrmServiceFrTable: any;



    onDestroyUnsubscribtionStatementReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dataTable: any;


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    phoneNo: any = null;
    emailId: any = null;
    faxNo: any = null;

    lrDto: LRDto = new LRDto();
    stmtRptLrForAdditionLrPrintLocalStorage: any;
    stmtRptLrForDeductionLrPrintLocalStorage: any;
    stmtRptLrForGeneralLrPrintLocalStorage: any;
    stmtRptLrDtlsPrintLocalStorage: any;

    additionDataDatailList: any;
    deductionDataDetailList: any;

    destination: any = null;
    fromPeriod: any = null;
    toPeriod: any = null;
    stmtDateStr: any = null;
    stmtNo: any = null;

    summaryHashMap = new Map<String, FortNightStmtDto>();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();

    summaryAdditionTotAmt: number = 0;
    summaryDeductionTotAmt: number = 0;


    result: any;
    summaryTotalLrs: any;
    summaryTotalActWgt: any;
    summaryTotalChgWgt: any;
    summaryTotalLCChg: any;
    summaryTotalBCChg: any;
    
    stmtRptLrForDashBoardLinkPrintLocalStorage: any;
    clrLoaclStorageObj:any;
pageId="asrldp";
    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private destStmtReport: DestStmtReport,
        private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.phoneNo = this.userDataDtoReturnSession.phoneNo == null ? '' : this.userDataDtoReturnSession.phoneNo;
            this.emailId = this.userDataDtoReturnSession.eMailAddress == null ? '' : this.userDataDtoReturnSession.eMailAddress;
            
            this.stmtRptLrForAdditionLrPrintLocalStorage = JSON.parse(localStorage.getItem('agentStmtLrDtlsAdditionPrintLocalStorage'));
            this.stmtRptLrForDeductionLrPrintLocalStorage = JSON.parse(localStorage.getItem('agentStmtLrDtlsDeductionPrintLocalStorage'));
            this.stmtRptLrForGeneralLrPrintLocalStorage = JSON.parse(localStorage.getItem('agentStmtLrDtlsGeneralPrintLocalStorage'));
            //this.stmtRptLrDtlsPrintLocalStorage = JSON.parse(localStorage.getItem('agentStmtLrDtlsPrintLocalStorage'));
            //this.result = JSON.parse(localStorage.getItem('agentStmtLrDtlsPrintLocalStorage'));
            this.stmtRptLrForDashBoardLinkPrintLocalStorage = JSON.parse(localStorage.getItem('bookingAgentStmtDashboard'));
            this.clrLoaclStorageObj=localStorage.getItem('clearLocalStorage');
            console.log(this.stmtRptLrForAdditionLrPrintLocalStorage);
            console.log(this.stmtRptLrForDeductionLrPrintLocalStorage);
            console.log(this.stmtRptLrForGeneralLrPrintLocalStorage);
            console.log(this.stmtRptLrDtlsPrintLocalStorage);
            
            if (this.stmtRptLrForAdditionLrPrintLocalStorage != null) {
                this.additionDataDatailList = this.stmtRptLrForAdditionLrPrintLocalStorage;
                for (let i = 0; i < this.additionDataDatailList.length; i++) {
                    var totalAmout = this.additionDataDatailList[i].totalAmount == null ? 0 : this.additionDataDatailList[i].totalAmount;
                    this.summaryAdditionTotAmt = +this.summaryAdditionTotAmt + +totalAmout;
                }
            }
            if (this.stmtRptLrForDeductionLrPrintLocalStorage != null) {
                this.deductionDataDetailList = this.stmtRptLrForDeductionLrPrintLocalStorage;
                for (let i = 0; i < this.deductionDataDetailList.length; i++) {
                    var totalAmout = this.deductionDataDetailList[i].totalAmount == null ? 0 : this.deductionDataDetailList[i].totalAmount;
                    this.summaryDeductionTotAmt = +this.summaryDeductionTotAmt + +totalAmout;
                }
            }
            if (this.stmtRptLrForGeneralLrPrintLocalStorage != null) {
                this.destination = this.stmtRptLrForGeneralLrPrintLocalStorage.agentName;
                this.stmtNo = this.stmtRptLrForGeneralLrPrintLocalStorage.stmtNo;
                this.fromPeriod = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.fromPeriod).format('DD-MM-YYYY');
                this.toPeriod = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.toPeriod).format('DD-MM-YYYY');
                this.stmtDateStr = moment(this.stmtRptLrForGeneralLrPrintLocalStorage.stmtDate).format('DD-MM-YYYY');
            }
            if (this.stmtRptLrForGeneralLrPrintLocalStorage != null) {
                this.setBookingServiceReport();

            }
            /*if (this.stmtRptLrDtlsPrintLocalStorage != null) {
                this.setHashMapValues();
            }*/

            /*  window.setTimeout(function () {
                  window.print();
              }, 0);
  
              window.onafterprint = function () {
                  window.close();
              }
              localStorage.clear();
              window.addEventListener('afterprint', (event) => {
                  localStorage.clear();
              });*/
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

    }


    setHashMapValues() {
        this.summaryTotalLrs = 0;
        this.summaryTotalActWgt = 0;
        this.summaryTotalChgWgt = 0;
        this.summaryTotalLCChg = 0;
        this.summaryTotalBCChg = 0;
        for (let i = 0; i < this.stmtRptLrDtlsPrintLocalStorage.length; i++) {
            this.summaryTotalLrs = +this.summaryTotalLrs + +1;
            this.summaryTotalActWgt = +this.summaryTotalActWgt + +this.stmtRptLrDtlsPrintLocalStorage[i].actualWeight;
            this.summaryTotalChgWgt = +this.summaryTotalChgWgt + +this.stmtRptLrDtlsPrintLocalStorage[i].chargedWeight;
            this.summaryTotalLCChg = +this.summaryTotalLCChg + +this.stmtRptLrDtlsPrintLocalStorage[i].lcChg;
            this.summaryTotalBCChg = +this.summaryTotalBCChg + +this.stmtRptLrDtlsPrintLocalStorage[i].bcChg;
        }

        var groups = new Set(this.stmtRptLrDtlsPrintLocalStorage.map(item => item.source));
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totalLrs = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.source === g).length
            //.reduce((sum, i) => +sum + +i.totalLrs, 0)
            const totalActWgt = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const totalChgWgt = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.chargedWeight, 0)
            const totalLCChg = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.lcChg, 0)
            const totalBCChg = this.stmtRptLrDtlsPrintLocalStorage
                .filter(i => i.source === g)
                .reduce((sum, i) => +sum + +i.bcChg, 0)

            this.result.push({
                source: g,
                values: this.stmtRptLrDtlsPrintLocalStorage.filter(i => i.source === g),
                totalLRS: totalLrs,
                actualWeight: totalActWgt,
                chargedWeight: totalChgWgt,
                lcChg: totalLCChg,
                bcChg: totalBCChg,
            })
        })
        console.log(this.result);
    }

    setBookingServiceReport() {
        this.lrDto = new LRDto();
        this.lrDto.fortNightStmtNo = this.stmtRptLrForGeneralLrPrintLocalStorage.stmtNo;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDto);
        this.lrService.getLrDetailsForBkgAgentStmt(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.stmtRptLrDtlsPrintLocalStorage = response;
                    this.setHashMapValues();
                    /* */
                    if (this.stmtRptLrForDashBoardLinkPrintLocalStorage != null &&
                        this.stmtRptLrForDashBoardLinkPrintLocalStorage != undefined &&
                        this.stmtRptLrForDashBoardLinkPrintLocalStorage != "" &&
                        this.stmtRptLrForDashBoardLinkPrintLocalStorage == "bookingAgentStmtDashboard") {
                        localStorage.clear();
                        console.log("ITS is Dashboard");
                        console.log(this.stmtRptLrForDashBoardLinkPrintLocalStorage);
                    } else {
                        window.setTimeout(function () {
                            window.print();
                        }, 0);

                        window.onafterprint = function () {
                            window.close();
                        }
                        if (this.clrLoaclStorageObj != "No" ){
                            console.log("Am In Open");
                            localStorage.clear();
                        }
                        
                        window.addEventListener('afterprint', (event) => {
                            if (this.clrLoaclStorageObj != "No" ){
                                console.log("Am In Event");
                                localStorage.clear();
                            }
                        });
                    }
                } else {
                    this.stmtRptLrDtlsPrintLocalStorage = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }
}
