<html>
<head>
<style>
</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Message On Cashmemo</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-6">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Message</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-users"></i>
												</span>
											</div>
											<textarea class="form-control" id="{{pageId}}message" rows="4"></textarea>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" style="text-align: center;">
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}saveBtn" (click)="confirmSave()">Save</button>
									<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>