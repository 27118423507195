//for datepicker starts
import {
  NgbDateStruct,
  NgbCalendar,
  NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";

  /*for popup*/ import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";

//for datepicker ends

//for datepicker starts
import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { NgModule, ViewChild } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ElementRef,ChangeDetectorRef } from "@angular/core";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from "src/app/dataService/master-service";
//from the particular global folder ends

import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
//for the select option with filter ends
const my = new Date();
//for datepicker ends
import { LRDto } from "src/app/dto/LR-dto";
//for the select option with filter ends

import { ReportService } from "src/app/dataService/report-service";
import { HttpClient } from '@angular/common/http';

import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

//for datepicker ends

//for datepicker ends

@Component({
  selector: "app-challan-issue",
  templateUrl: "./challan-issue.component.html",
  styleUrls: ["./challan-issue.component.css"],
})
export class ChallanIssueComponent implements OnInit {
  //for datepicker starts
  model: NgbDateStruct;
  model2;
  //for datepicker ends

  loadingIndicator = true;

  //for datePicker starts
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  //for datepicker ends

  getDataFrmServiceFrTable: any;
  challanIssueReportDataList: any;
  onDestroyUnsubscribtionChallanIssueReport: Subscription;

  controlMain = new FormControl();

  // agentNameOptions = [
  //     { id: 1, label: 'ALL' },
  //     { id: 2, label: 'ANDHERI' },
  //     { id: 3, label: 'BHIWANDI' },
  //     { id: 4, label: 'DAMAN' }
  // ];

  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;

  dtTriggerChallanIssueReport: Subject<any> = new Subject();

  dataTable: any;
  dtOptionsChallanIssueReport: any;

  ///For Agent Names

  agentNameOptions: AgentDetailsDto[];
  agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
  public modelAgentName: any;
  agentNameTA: Array<AgentDetailsDto> = [];
  focusAgentNameTA$ = new Subject<string>();
  searchAgentName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.agentNameTA
          : this.agentNameTA.filter(
            (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  agentDto: AgentDetailsDto = new AgentDetailsDto();
  agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
  formatterAgentName = (x: { subStation: string }) => x.subStation;
  userDataDtoReturnSession: any;
  superAdminview = false;
  showSpinnerForAction = false;
  isLoggedIn = true;
  address: any;

  setTodayDateOnToDate: any;
  setTodayDateOnFromDate: any;
  lrDtoForBtnSearch: LRDto = new LRDto();
  viewCustomPrintV1 = false;
  grandTotalLrs = 0;

  //For Custom Print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;


  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

  selectedAgent: any;
  selectedFromDatePrint: any;
  selectedToDatePrint: any;
  selectedFromDate: any;
  selectedToDate: any;
  pageId="clisc";
  constructor(
    private challanIssueReport: MasterService,
    private masterReadService: MasterReadService, private datePipe: DatePipe, private reportService: ReportService,
    private router: Router,public changeDetectorRef : ChangeDetectorRef
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }

    //Rights For Agent
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );

      this.address =
        this.userDataDtoReturnSession.addressId == null
          ? ""
          : this.userDataDtoReturnSession.addressId;

      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ChallanIssueRpt ShowAll") {
            this.superAdminview = true;
            console.log('aa');
            console.log(this.superAdminview);
          }
        }
      }
      this.getAgentDetailList();
      this.selectTodayDate();
      console.log(this.superAdminview);
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }
  selectTodayDate() {
    this.setTodayDateOnToDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
    this.setTodayDateOnFromDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };

    $("#"+this.pageId+"fromDate").val(this.setTodayDateOnFromDate);
    $("#"+this.pageId+"toDate").val(this.setTodayDateOnToDate);
  }
  ngOnInit(): void {

    this.dtOptionsChallanIssueReport = {
      dom: 'Bfrtip',
      buttons: [{
        extend: 'excel',
        text: '<i class="fas fa-file-excel"> Excel</i>',
        titleAttr: 'Excel',
        footer: true,
        title: function () {

          var returnPaidDest = null;

          returnPaidDest = "Challan Issue Report" +
            " From Date : " + moment($("#cliscfromDate").val()).format('DD-MM-YYYY') + " -  " +
            "To Date : " + moment($("#clisctoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#cliscagentName").val();
          return returnPaidDest;
        }
      }],
      processing: true,
      //scroll in datatable starts
      responsive: true,
      "scrollX": false,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };


        var totallr = api.column(4).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(0).footer()).html();
        $(api.column(1).footer()).html('Total : ' + data.length);
        $(api.column(2).footer()).html();
        $(api.column(3).footer()).html();
        $(api.column(4).footer()).html(totallr);


      }
    }
  }

  ngOnDestroy(): void {
    this.dtTriggerChallanIssueReport.unsubscribe();

    // this.onDestroyUnsubscribtionChallanIssueReport.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerChallanIssueReport.next();
  }

  //for datepicker
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };

  }

  //For datePicker

  //For Agent Name Listener
  clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
    this.modelAgentName = e.item;
    $("#"+this.pageId+"agentName").val(this.modelAgentName.subStation);
  }
  //For Agent Names
  getUserValuesForAgentDetailsList() {

    
    this.agentDto.column2 = "Working";
    this.agentDto.mode = "Sub";
    this.agentDto.status = "All";
    if (this.superAdminview == true) {

      this.agentDto.mode = "Sub";
      this.agentDto.status = "All";
    } else {

      this.agentDto.mode = "mainBranch";
      this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;

    }
    this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.agentDto);
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getSourceListDetails(this.agentDto)
      .subscribe((response) => {
        this.agentNameTA = [];
        this.agentNameOptions = [];
        if (response.length > 0) {
          this.agentNameOptions = response;
          this.agentDtoAll.subStation = "All";
          this.agentNameTA.push(this.agentDtoAll);
          for (let i = 0; i < this.agentNameOptions.length; i++) {
            //console.log( this.agentDetailsOptionsGet[i].subStation );
            this.agentNameTA.push(this.agentNameOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the agent  Details",
          "info"
        );
      },
      () => console.log("done");
  }
  getSearchMethod() {
    this.selectedAgent = $("#"+this.pageId+"agentName").val();
    this.selectedFromDatePrint = $("#"+this.pageId+"fromDate").val();
    this.selectedToDatePrint = $("#"+this.pageId+"toDate").val();
    if (this.selectedFromDatePrint == null || this.selectedFromDatePrint == '' || this.selectedToDatePrint == null || this.selectedToDatePrint == '') {
      swal({
        title: "Mandatory Field",
        text: "From Date , To Date Is Mandatory Fields",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    } else {

      this.lrDtoForBtnSearch = new LRDto();
      if (this.selectedAgent != null && this.selectedAgent == "All") {
        this.lrDtoForBtnSearch.mode = "ALL";
      } else {
        this.lrDtoForBtnSearch.mode = "SPECIFIC";
      }
      if (this.superAdminview == false) {
        this.lrDtoForBtnSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
      }
      this.lrDtoForBtnSearch.agentName = this.selectedAgent;
      this.lrDtoForBtnSearch.fromDate = this.selectedFromDatePrint;
      this.lrDtoForBtnSearch.toDate = this.selectedToDatePrint
      this.lrDtoForBtnSearch.companyId = this.userDataDtoReturnSession.companyId;
      console.log(this.lrDtoForBtnSearch);

      this.getSearchMethodDetails();
    }
  }
  getSearchMethodDetails = () => {
    this.showSpinnerForAction = true;
    this.reportService.getChallanIssueReportForLr(this.lrDtoForBtnSearch).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#"+this.pageId+"challanIssueReportTable").DataTable().destroy();
        this.challanIssueReportDataList = [];
        console.log(response);
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.challanIssueReportDataList = response;

        }
        this.dtTriggerChallanIssueReport.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Challan Issue Report details", "info");
      }, () => console.log('done');
  };

  clearAll() {

    $("#"+this.pageId+"fromDate").val('');
    $("#"+this.pageId+"toDate").val('');
    $("#"+this.pageId+"agentName").val('');
    this.setTodayDateOnFromDate = '';
    this.setTodayDateOnToDate = '';
    this.selectedFromDatePrint = '';
    this.selectedToDatePrint = '';
    this.selectedAgent = '';
    this.lrDtoForBtnSearch = new LRDto();

    //For Custom Print
    this.cashMemoDtoForCustomPrint = new CashMemoDto();
    this.cashMemoDtoForCustomPrintList = [];
    this.cashMemoDtoForCustomPrintListColumnNames = [];
    this.cashMemoDtoForCustomPrintListColumnWidths = [];
    this.cashMemoDtoForCustomPrintDataList = [];
    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
    this.cashMemoDtoForCustomPrintListColumnValues = [];
    this.cashMemoDtoForCustomPrintDataSummaryList = [];
    this.viewCustomPrintV1 = false;
    this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
    this.grandTotalLrs = 0;
    $("#"+this.pageId+"challanIssueReportTable").DataTable().destroy();
    this.challanIssueReportDataList = [];
    this.dtTriggerChallanIssueReport.next();
    this.showSpinnerForAction = false;
    localStorage.clear();
    this.selectTodayDate();
    this.selectedFromDate = '';
    this.selectedToDate = '';


  }
  printMethodForDateWise() {
    this.dateWisePrintMethod();

}
dateWisePrintMethod() {
    this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
    this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
    this.selectedToDate = $("#"+this.pageId+"toDate").val();
    this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
    this.selectedAgent = $("#"+this.pageId+"agentName").val();

    if (this.challanIssueReportDataList.length == 0) {
        swal({

            title: "No records found to print",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    } else {
        localStorage.clear();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Issue Date", "LR From", "LR To", "No Of LRS"];
        this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
        for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
            this.cashMemoDtoForCustomPrint = new CashMemoDto();
            this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
            this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
            this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
        }
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.grandTotalLrs = 0;


        for (let i = 0; i < this.challanIssueReportDataList.length; i++) {
            this.cashMemoDtoForCustomPrintData = new CashMemoDto();
            this.cashMemoDtoForCustomPrintListColumnValues = [this.challanIssueReportDataList[i].agentName, this.challanIssueReportDataList[i].dateInDate, this.challanIssueReportDataList[i].fromChallanNo, this.challanIssueReportDataList[i].toChallanNo, this.challanIssueReportDataList[i].noOfChallan];

            this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
            this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            this.grandTotalLrs = +this.grandTotalLrs + +this.challanIssueReportDataList[i].noOfChallan;

            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.challanIssueReportDataList.length, "", "", "", this.grandTotalLrs];
        }
        //heading logics For Date

        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];




        for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
            this.cashMemoDtoForCustomPrint = new CashMemoDto();
            this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
            this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
            this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
        }

        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent"];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedAgent];

        for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
            this.cashMemoDtoForCustomPrint = new CashMemoDto();
            this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
            this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
            this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
        }


        localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
        localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
        localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
        localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
        localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
        localStorage.setItem('printTitle', "Challan Issue Report");
        this.viewCustomPrintV1 = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCustomPrintV1) {
                this.viewCustomPrintV1 = false;
                localStorage.clear();
            }
        });
    }
}


}
