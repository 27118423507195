import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from "src/app/dataService/hireslip-service";
import { StockReport } from 'src/app/dataService/stock-report';

@Component({
    selector: 'app-get-advance-consent',
    templateUrl: './get-advance-consent.component.html',
    styleUrls: ['./get-advance-consent.component.css']
})
export class GetAdvanceConsentComponent implements OnInit {

    closeResult: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    hireslipDtoSearch: HireSlipDto = new HireSlipDto();
    driverNumberEntered: any;
    pageId = "gadc";
    showSpinnerForAction = false;

    constructor(private modalService: NgbModal, private router: Router, private hireslipService: HireslipService,
        public changeDetectorRef: ChangeDetectorRef, private stockReport: StockReport) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    open2(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
    }

    confirmAdvanceConsent() {
        this.driverNumberEntered = $("#" + this.pageId + "driverNumber").val();
        if (this.driverNumberEntered == null || this.driverNumberEntered == '') {
            swal("Not Allowed", "Please enter the Driver Mobile Number to initiate the Consent Process!", "info");
        } else {
            swal({
                title: "Confirm Advance Consent",
                text: "Sure U want to initiate the Advance Consent Process for the Entered Driver Number?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((Yes) => {
                if (Yes) {
                    this.initiateConsent();
                }
            });
        }
    }

    initiateConsent() {
        this.showSpinnerForAction = true;
        this.hireslipDtoSearch = new HireSlipDto();
        this.driverNumberEntered = $("#" + this.pageId + "driverNumber").val();
        this.hireslipDtoSearch.driverMobileNumber = this.driverNumberEntered;
        this.stockReport.advanceConsentProcessFTService(this.hireslipDtoSearch).subscribe(
            (response) => {
                if (response.trackingFTConsentStatus == "Pending" || response.trackingFTConsentStatus == "Accepted") {
                    swal("Consent Initiated", "Advance Consent Process initiated successfully, please follow the instructions from received call/message to complete the process.", "info");
                    this.resetFields();
                } else {
                    swal("Failed", "Failed to process the Advance Consent, please try again or contact admin!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While processing the Advance Consent", "info");
            }, () => console.log('done');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    resetFields() {
        this.driverNumberEntered = null;
        $("#" + this.pageId + "driverNumber").val('');
    }

}
