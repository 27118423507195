<html>
<head>

</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Source Charges Setup</h6>
				</div>
				<div class="row system_responsive">
					<div class="col-md-12">

						<div class="card-body">

							<!-- <h6 class="card-title">Tempo Details</h6> -->
							<div class="row">
								<div class="col-md-3">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}source">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Source" [formControl]="control"
															[appAutocomplete]="autocomplete">
													</div>
												</div>
											</div>
											<app-autocomplete #autocomplete="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(sourceOptions | filter: control.value) as resultSource">
											<app-option *ngFor="let option of resultSource"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultSource.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the first autocomplete ends -->

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}hamaliSource">
													<label>Source Hamali</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-rupee-sign"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}gcCharge">
													<label>G.C Charge</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-rupee-sign"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-md-12" style="text-align: center">
											<button type="submit" class="btn btn-success m-r-10"
												id="{{pageId}}saveBtn">Save</button>
											<button type="submit" class="btn btn-dark m-r-10"
												id="{{pageId}}resetBtn">Reset</button>
										</div>
									</div>
								</div>
								<div class="col-md-5 vl">
									<div class="box-body">
										<h6 class="card-title">Charges Details</h6>
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsSourceChargesSetup"
											[dtTrigger]="dtTriggerSourceChargesSetup">
											<thead>
												<tr>
													<th>Booking Station</th>
													<th>Source Hamali</th>
													<th>GC Charge</th>
												</tr>
											</thead>
											<tbody>
												<tr
													*ngFor="let sourceChargesSetupData of sourceChargesSetupDataList ">
													<td>{{ sourceChargesSetupData.grade }}</td>
													<td>{{ sourceChargesSetupData.position }}</td>
													<td>{{ sourceChargesSetupData.position }}</td>
												</tr>
												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>