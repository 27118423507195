import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { LrService } from 'src/app/dataService/lr-service';
import { LRDto } from 'src/app/dto/LR-dto';
import *  as moment from 'moment';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-agent-statement-generation',
    templateUrl: './agent-statement-generation.component.html',
    styleUrls: ['./agent-statement-generation.component.css']
})
export class AgentStatementGenerationComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    agentStmtGenerationDataList: any;
    onDestroyUnsubscribtionAgentStmtGeneration: Subscription;
    model: NgbDateStruct;
    model2;
    loadingIndicator = true;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAgentStmtGeneration: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsAgentStmtGeneration: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction: boolean = false;

    lrdtoForGetAgentStmtGenDetails: LRDto = new LRDto();
    fortNightStmtDtoForGenerateValidate: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForGenerateValidateReturn: FortNightStmtDto = new FortNightStmtDto();
    rateMasterDtoForGetUerValue: RateMasterDto = new RateMasterDto();
    rateMasterDtoForGetUerValueReturn: RateMasterDto = new RateMasterDto();
    //listOfAgntSelected: Array<String>;
    //list: Array<String>;
    listOfAgntSelected: Array<String> = new Array();
    list: Array<String> = new Array();

    validateFromPeriod: any;
    validateToPeriod: any;
    setStationName: any;
    setFromPeriod: any;
    setToPeriod: any;
    modalFromPeriod: any = null;
    modalToPeriod: any = null;

    fromDateReadOnly: boolean = true;
    generateBtnReadOnly: boolean = false;
    pageId = "asgc";
    constructor(private router: Router, private lrService: LrService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.setAgentStatementGenerationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsAgentStmtGeneration = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngAfterViewInit(): void {
        this.dtTriggerAgentStmtGeneration.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerAgentStmtGeneration.unsubscribe();
        //this.onDestroyUnsubscribtionAgentStmtGeneration.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    getAgentStatementGenerationDetails() {
        this.lrdtoForGetAgentStmtGenDetails = new LRDto();
        if (this.userDataDtoReturnSession.mainStation == "Delhi") {
            this.lrdtoForGetAgentStmtGenDetails.stationType = "Agent";
        } else {
            this.lrdtoForGetAgentStmtGenDetails.stationType = "Self";
        }

        this.lrdtoForGetAgentStmtGenDetails.mode = "bkgAgntStat";
        this.lrdtoForGetAgentStmtGenDetails.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrdtoForGetAgentStmtGenDetails.companyId = this.userDataDtoReturnSession.companyId;
    }

    setAgentStatementGenerationDetails() {
        this.getAgentStatementGenerationDetails();
        this.showSpinnerForAction = true;
        this.lrService.getBkgStationForStmt(this.lrdtoForGetAgentStmtGenDetails).subscribe(
            (response) => {
                console.log(response);
                this.agentStmtGenerationDataList = [];
                $("#" + this.pageId + "agentStatementGeneTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.agentStmtGenerationDataList = response;
                } else {
                    swal(" Booking Agents", "No Record Found", "warning");
                }
                this.dtTriggerAgentStmtGeneration.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    }

    selectedRowSetAgentStmtGenDetails(agentStmtGenerationData) {
        this.setStationName = null;
        this.setFromPeriod = null;
        this.setToPeriod = null;

        this.setStationName = agentStmtGenerationData.source;
        this.setFromPeriod = agentStmtGenerationData.fromDate;
        this.setToPeriod = agentStmtGenerationData.toDate;

        this.fromDateReadOnly = false;
        this.generateBtnReadOnly = false;
        $("#" + this.pageId + "toDates").val('');
        $("#" + this.pageId + "fromDates").val('');
        if (agentStmtGenerationData.stmtDateStr != null &&
            agentStmtGenerationData.stmtDateStr != undefined) {
            $("#" + this.pageId + "fromDates").val(agentStmtGenerationData.stmtDate);
        }

        if (agentStmtGenerationData.fortNightStmtNo == null) {
            this.fromDateReadOnly = true;
        }
    }

    validateToPeriodCalendar($event) {
        if ($("#" + this.pageId + "fromDates").val() != null && $("#" + this.pageId + "fromDates").val() != undefined &&
            $("#" + this.pageId + "fromDates").val() != "") {
            this.generateBtnReadOnly = true;
        } else {
            this.generateBtnReadOnly = false;
        }
    }

    validateGenerateBtn() {
        if ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
            $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
            $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "") {
            swal("Mandatory Field", "Please select the Mandatory Field", "warning");
            return false;
        } else {
            this.validateStmtDate();
        }
    }

    validateStmtDate() {
        this.fortNightStmtDtoForGenerateValidate = new FortNightStmtDto();
        this.validateFromPeriod = null;
        this.validateToPeriod = null;

        this.validateFromPeriod = $("#" + this.pageId + "fromDates").val();
        this.validateToPeriod = $("#" + this.pageId + "toDates").val();

        this.fortNightStmtDtoForGenerateValidate.fromPeriod = this.validateFromPeriod;
        this.fortNightStmtDtoForGenerateValidate.toPeriod = this.validateToPeriod;
        this.fortNightStmtDtoForGenerateValidate.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.validateStmtDate(this.fortNightStmtDtoForGenerateValidate).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.fortNightStmtDtoForGenerateValidateReturn = null;
                this.fortNightStmtDtoForGenerateValidateReturn = response;
                if (!this.fortNightStmtDtoForGenerateValidateReturn.isTrue) {
                    swal("Invalid Period Selection", "To-Period should be Before the Current Date", "warning");
                    return false;
                } else {
                    swal({
                        title: "Confirm Generate",
                        text: this.setStationName + " - Period : " +
                            moment($("#" + this.pageId + "fromDates").val()).format("DD-MM-YYYY") + " To " +
                            moment($("#" + this.pageId + "toDates").val()).format("DD-MM-YYYY") +
                            " Sure U Want To Generate The Statement",
                        icon: "info",
                        buttons: ["No", "Yes"]
                    }).then((isConfirmYes) => {
                        if (isConfirmYes) {
                            this.setBookingAgentStmtDetail();
                        }
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While generating the statement!", "error");
            }, () => console.log('done');
    }

    setBookingAgentStmtDetail() {
        this.rateMasterDtoForGetUerValueReturn = new RateMasterDto();
        this.rateMasterDtoForGetUerValueReturn = this.getUserValues();
        this.showSpinnerForAction = true;
        this.lrService.generateBkgAgtStmt(this.rateMasterDtoForGetUerValueReturn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "success") {
                    swal("Completed", "Statements Generated Successfully", "success");
                    this.clearField();
                    this.setAgentStatementGenerationDetails();
                } else if (response.status == "noComms") {
                    swal("Empty Master", "Commission Not Yet Set...Please Set The Commission First", "info");
                } else if (response.status == "failed") {
                    swal("Error", "Ooops...Problem occured while Generating the Statements", "error");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Generate Booking Agent Statement Details", "info");
            }, () => console.log('done');
    }

    getUserValues() {
        this.rateMasterDtoForGetUerValue = new RateMasterDto();
        this.listOfAgntSelected = new Array();
        this.list = new Array();
        this.validateFromPeriod = null;
        this.validateToPeriod = null;

        this.validateFromPeriod = $("#" + this.pageId + "fromDates").val();
        this.validateToPeriod = $("#" + this.pageId + "toDates").val();

        this.list.push(this.setStationName);
        for (let i = 0; i < this.list.length; i++) {
            this.listOfAgntSelected.push(this.list[i]);
        }
        this.rateMasterDtoForGetUerValue.list = this.listOfAgntSelected;
        this.rateMasterDtoForGetUerValue.fromDate = this.validateFromPeriod;
        this.rateMasterDtoForGetUerValue.toDate = this.validateToPeriod;
        this.rateMasterDtoForGetUerValue.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoForGetUerValue.station = this.userDataDtoReturnSession.office;
        this.rateMasterDtoForGetUerValue.companyId = this.userDataDtoReturnSession.companyId;
        return this.rateMasterDtoForGetUerValue;
    }

    validateClearBtn() {
        this.clearField();
    }

    clearField() {
        this.modalFromPeriod = null;
        this.modalToPeriod = null;

        this.lrdtoForGetAgentStmtGenDetails = new LRDto();
        this.fortNightStmtDtoForGenerateValidate = new FortNightStmtDto();
        this.fortNightStmtDtoForGenerateValidateReturn = new FortNightStmtDto();
        this.rateMasterDtoForGetUerValue = new RateMasterDto();
        this.rateMasterDtoForGetUerValueReturn = new RateMasterDto();
        this.listOfAgntSelected = [];
        this.list = [];


        this.validateFromPeriod = null;
        this.validateToPeriod = null;
        this.setStationName = null;
        this.setFromPeriod = null;
        this.setToPeriod = null;

        $("#" + this.pageId + "toDates").val('');
        $("#" + this.pageId + "fromDates").val('');

        this.fromDateReadOnly = true;
        this.generateBtnReadOnly = false;
    }
}
