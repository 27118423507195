<html>

<head> </head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showConsigneeRpt">
        <div class="col-lg-12">
            <div class="card" style="border: 1px solid darkcyan !important">
                <div class="row system_responsive" style="margin-bottom: 10px">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">

                                        <div class="row">
                                            <!-- <div class="col-sm-12 col-md-12" *ngIf="showAgentCB">
												<div class="form-group">
													<div class="input-group">
														<label>Agent Wise View</label>

														<div class="custom-control custom-checkbox">
															<input type="checkbox" class="custom-control-input"
																id="{{pageId}}checkboxCOD" #checkboxCOD
																(keyup)="fieldFocus($event, paymentType)"
																(change)="toggleEditableForCOD($event)" />
															<label class="custom-control-label wtfull"
																style="padding-top: 15px" for="checkboxCOD"></label>
														</div>
													</div>
												</div>
											</div> -->

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
																<i class="fas fa-question"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
															<option selected value="summary">
																Summary
															</option>
															<option value="detail">Detail</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source </label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                          (selectItem)="rowSelectedForSource($event)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="showorhideDestination">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="consigneeDestListener($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" [hidden]="!consigneeNameForDetails">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}partyName">
                                                            <label>Consignee Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" (selectItem)="rowSelectedConsignee($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA"
                                                                [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" [hidden]="!consigneeNameForSummary">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class='row p-t-10'>
                                                            <div class="col-sm-12 col-md-12">
                                                            </div>
                                                        </div>
                                                        <div class="box-body">
                                                            <table datatable id="{{pageId}}consigneeNameDataTableId"
                                                                class="table table-striped table-bordered row-border hover"
                                                                [dtOptions]="dtOptionsConsigneeNames"
                                                                [dtTrigger]="dtTriggerConsigneeNames">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Action</th>
                                                                        <th>Consignee Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let consigneeNames of consigneeNamesList let i = index">
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                [checked]="multiSelect"
                                                                                [(ngModel)]="consigneeNames.isSelected"
                                                                                id='selectedLr'
                                                                                (change)="checkBoxForConsigneeNameClicked($event, consigneeNames,i)" />
                                                                        </td>
                                                                        <td>{{ consigneeNames.consigneeName }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                            (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="rowSelectedConsignor($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                            [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="showAgent">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Agent Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
																<i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName" (focus)=" focusAgentNameTA$.next($any($event).target.value)"
                                                            placeholder="Select Agent Name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px" />
                        <div class="col-md-12" style="text-align: center">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">
								Search
							</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">
								Clear
							</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-t-10">
                                <div class="col-md-8">
                                    <h6 class="card-title" [hidden]="!consigneeSummaryTable">Summary View
                                    </h6>
                                    <h6 class="card-title" [hidden]="!detailTable">Details View</h6>


                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="dt-button" [hidden]="!printForSummaryTable" style="margin-left: 75%;" id="{{pageId}}printBtnForSummary" (click)="pritnSummaryWise()">
										<span><i class="fas fa-print">Print</i></span>
									</button>
                                    <button type="submit" class="dt-button" [hidden]="!detailTable" id="{{pageId}}printBtnForDetail" style="margin-left: 75%;" (click)="pritnDetailsWise()">
										<span><i class="fas fa-print">Print</i></span>
									</button>

                                </div>
                            </div>
                            <div class="box-body">
                                <div [hidden]="!consigneeSummaryTable">
                                    <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}summaryTableId" [dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">
                                        <thead>
                                            <tr>
                                                <th [hidden]=true>Source</th>
                                                <th>Consignee Name</th>
                                                <th>Total Lrs</th>
                                                <th>Consignee GST No</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Article</th>
                                                <th>Actual Weight</th>
                                                <th>Charged Weight</th>
                                                <th>Goods Value</th>
                                                <th>F O V</th>
                                                <th>IS Rate Set</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let smryData of summaryDataList">
                                                <td [hidden]=true>{{ smryData.agentId }}</td>
                                                <td>{{ smryData.consigneeName }}</td>
                                                <td>{{ smryData.totalLrs }}</td>
                                                <td>{{ smryData.gstNoConsignee }}</td>
                                                <td>{{ smryData.toPay }}</td>
                                                <td>{{ smryData.paid }}</td>
                                                <td>{{ smryData.totalArticles }}</td>
                                                <td>{{ smryData.actualWeight }}</td>
                                                <td>{{ smryData.chargedWeight }}</td>
                                                <td>{{ smryData.goodsValue }}</td>
                                                <td>{{ smryData.riskCharge }}</td>
                                                <td>{{ smryData.isAdminRateSet == true? "Yes" : "No" }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td [hidden]=true></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>

                            <div class="box-body">
                                <div [hidden]="!consigneeSummaryTableWithAllOption">
                                    <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}summaryTableIdWithAllOption" [dtOptions]="dtOptionsSummaryWithAllOption" [dtTrigger]="dtTriggerSummaryWithAllOption">
                                        <thead>
                                            <tr>
                                                <th [hidden]=true>Main Station</th>
                                                <th>Source</th>
                                                <th>Consignee Name</th>
                                                <th>Total Lrs</th>
                                                <th>Consignee GST No</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Article</th>
                                                <th>Actual Weight</th>
                                                <th>Charged Weight</th>
                                                <th>Goods Value</th>
                                                <th>F O V</th>
                                                <th>IS Rate Set</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let smryData of summaryDataList">
                                                <td [hidden]=true>{{ smryData.groupStation }}</td>
                                                <td>{{ smryData.agentId }}</td>
                                                <td>{{ smryData.consigneeName }}</td>
                                                <td>{{ smryData.totalLrs }}</td>
                                                <td>{{ smryData.gstNoConsignee }}</td>
                                                <td>{{ smryData.toPay }}</td>
                                                <td>{{ smryData.paid }}</td>
                                                <td>{{ smryData.totalArticles }}</td>
                                                <td>{{ smryData.actualWeight }}</td>
                                                <td>{{ smryData.chargedWeight }}</td>
                                                <td>{{ smryData.goodsValue }}</td>
                                                <td>{{ smryData.riskCharge }}</td>
                                                <td>{{ smryData.isAdminRateSet == true? "Yes" : "No" }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td [hidden]=true></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>

                            <div class="box-body">
                                <div [hidden]="!detailTable">
                                    <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}detailTableId" [dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
                                        <thead>
                                            <tr>
                                                <th [hidden]=true>Source</th>
                                                <th>LR Number</th>
                                                <th>Booking Date</th>
                                                <th>Invoice No</th>
                                                <th>Consignee Name</th>
                                                <th>Consignor Name</th>
                                                <th>Consignee GST</th>
                                                <th>Commodity Name</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Article</th>
                                                <th>Act Wgt</th>
                                                <th>Chg Wgt</th>
                                                <th>LC</th>
                                                <th>BC</th>
                                                <th>CashMemo Amt</th>
                                                <th>Unloading At</th>
                                                <th>Arr Wtn</th>
                                                <th>Delivery Date</th>
                                                <th>Del Wtn</th>
                                                <th>AOC</th>
                                                <th>Goods Value</th>
                                                <th>F O V</th>
                                                <th>Is Rate Set</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detailData of detailDataList">
                                                <td [hidden]=true>{{ detailData.agentId }}</td>
                                                <td>{{ detailData.lrNumber }}</td>
                                                <td>{{ detailData.bookingDateStr }}</td>
                                                <td>{{ detailData.invoiceNumber }}</td>
                                                <td>{{ detailData.consigneeName }}</td>
                                                <td>{{ detailData.consignorName }}</td>
                                                <td>{{ detailData.gstNoConsignee }}</td>
                                                <td>{{ detailData.commodityName }}</td>
                                                <td>{{ detailData.toPay }}</td>
                                                <td>{{ detailData.paid }}</td>
                                                <td>{{ detailData.totalArticles }}</td>
                                                <td>{{ detailData.actualWeight }}</td>
                                                <td>{{ detailData.chargedWeight }}</td>
                                                <td>{{ detailData.lcChg }}</td>
                                                <td>{{ detailData.bcChg }}</td>
                                                <td>{{ detailData.cashAmt }}</td>
                                                <td>{{ detailData.arrivalDateStr }}</td>
                                                <td>{{ detailData.arrivalwithin }}</td>
                                                <td>{{ detailData.deliveryDateStr }}</td>
                                                <td>{{ detailData.deliveredwithin }}</td>
                                                <td>{{ detailData.aoc }}</td>
                                                <td>{{ detailData.goodsValue }}</td>
                                                <td>{{ detailData.riskCharge }}</td>
                                                <td>{{ detailData.isAdminRateSet == true? "Yes" : "No" }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td [hidden]=true></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>