<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">

        <div>
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                    <tr>
                        <td style="padding-top: 10px;" width='60%' valign='top'>
                            <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                        </td>
                        <td style="padding-top: 10px;" width='40%' valign='top'>
                            {{address}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-5">
                <h6 style='text-align:left; font-size: large;'>
                    <strong>As On Date:  </strong>{{asOnDate}}
                    <!-- <strong>To Date:  </strong>{{todayDate}} -->
                </h6>
            </div>
            <div class="col-md-7">
                <h6 style='text-align:left ; font-size: large;'>
                    <strong><u>PartyWise OutStanding Details Report</u></strong>
                </h6>
            </div>

        </div>
        <br>
        <table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
            <tr>
                <td width='14%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;' valign='top' type='text'><strong> Memo Number</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Lr No</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Memo Amount</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Booking Date</strong></td>
                <td width='8%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> No Of Articles</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Memo Date</strong></td>
                <td width='8%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Cur Date - Memo Date</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Area</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Collection Man</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Status</strong></td>
            </tr>
            <ng-container *ngFor="let item of result">
                <tr>
                    <td colspan="12" style='border: 1px solid #000;'><strong>{{item.consignee}}</strong></td>
                </tr>
                <tr *ngFor="let value of item.values">
                    <td width='14%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <span>{{value.memoNumber}}</span>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.lrNumber}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.amount}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <span>{{value.bookingDateStr}}</span>
                    </td>
                    <td width='8%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.article}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.memoDateStr}}</td>
                    <td width='8%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.days}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.area}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.collectionMan}}</td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.status}}</td>

                </tr>
                <tr>
                    <td width='14%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <strong> Total </strong>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.values.length}}</strong>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.amount}}</strong>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='8%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.article}}</strong>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='8%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                </tr>
            </ng-container>
            <br>
            <br>
            <tr>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                    <strong> Grand Total </strong>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totMemoNo}}</strong>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totMemoAmt}}</strong>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totArtical}}</strong>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>

            </tr>
        </table>
    </div>
</div>