import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
    animal: string;
    name: string;
}
//for modal ends
@Component( {
    selector: 'app-edit-consignee-details',
    templateUrl: './edit-consignee-details.component.html',
    styleUrls: ['./edit-consignee-details.component.css']
} )
export class EditConsigneeDetailsComponent implements OnInit {

    closeResult: string;
    areaYes = false;
    //for the select option with filter starts


    controlArea = new FormControl();
    controlCollectionMan = new FormControl();
    controlState = new FormControl();

    areaOptions = [
        { id: 1, label: 'Add New', value: 'addNew' },
        { id: 2, label: 'Agra', value: 'Agra' },
        { id: 3, label: 'Bawana', value: 'Bawana' },
    ];

    collectionManOptions = [
        { id: 1, label: 'Delhi' },
        { id: 2, label: 'Mumbai' },
        { id: 3, label: 'Agra' },
    ];
    stateOptions = [
        { id: 1, label: 'Bangalore' },
        { id: 2, label: 'Chennai' },
        { id: 3, label: 'Kerela' },
    ];

    //for the select option with filter ends
	pageId="edcdc";
    constructor(
        //for modal starts
        public dialogRef: MatDialogRef<EditConsigneeDetailsComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) { }

    ngOnInit() {
    }


    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
    //for modal starts
    onFinishClick(): void {
        this.dialogRef.close();
    }
    onFinishAndConfirmClick(): void {
        this.dialogRef.close();
    }
    onCancelClick(): void {
        this.dialogRef.close();
    }
    //for modal ends
    areaMode( area: string ) {
            if ( area === 'addNew' ) {
                this.areaYes = true;
            } else {
                this.areaYes = false;
            }
        }
}
