import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { FormControl } from '@angular/forms';
import { MemoService } from "src/app/dataService/memo-service";
import { Inject } from '@angular/core';
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
export interface DialogData {
    listOfDataToPass: string[];
}

import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
    selector: 'app-booking-cashmemo-popup',
    templateUrl: './booking-cashmemo-popup.component.html',
    styleUrls: ['./booking-cashmemo-popup.component.css']
})
export class BookingCashmemoPopupComponent {
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    closeResult: string;
    lrDtoBkgCashmemo: LRDto = new LRDto();
    lrDtoBkgCashmemoInfo: LRDto = new LRDto();
    dataForBkgCashmemo: any;
    getLrNumber: any;
    getMode: any;
    showEdit = false;
    mainstation = null;
    lrDtoBkgCashmemoInfoRet: any;
    lrDtoBkgCashmemoInfoSet: LRDto = new LRDto();
    isMatched = false;
    userRole = 'Bkg Cashmemo To Generate Memo For Bkg Manager';
    printDto: LRDto = new LRDto();
    isGSTMemo = false;
    enteredBookingDate: any;
    enteredPaid: any;
    enteredServiceChg: any;
    enteredReceiptChg: any;
    enteredDiscount: any;
    enteredDestHamali: any;
    enteredDestHamaliPerArt: any;
    enteredSubTotal: any;
    showServiceTaxAmt = false;
    showServiceTax = false;
    enteredServiceTax: any;
    getServiceTax = 0.0
    stPerc = 0.0;
    amtLimit = 0.0;
    serviceTaxAmountCalc = 0.0;
    doubleSubtotal = 0.0;
    grandTotal = 0.0;
    enteredServiceTaxAmt: any;
    doubleServiceTaxAmt = 0.0;
    showGst = false;
    gstAmt = 0.0;
    enteredCGSTAmt: any;
    enteredSGSTAmt: any;
    enteredIGSTAmt: any;
    enteredCGSTPerc: any;
    enteredSGSTPerc: any;
    enteredIGSTPerc: any;
    gstTotalAmt = 0.0;
    setDestHamali = 0.0;
    getDestHamaliPerArt: any;
    enteredArticles: any;
    getArticles = 0.0;
    showDiscount = false;
    viewDiscount = '';
    isReadOnly = false;
    isReadOnlyForEdit = false;
    lrDtoBkgCashmemoSave: LRDto = new LRDto();
    lrNumberPrint = null;
    enteredGrandTotalAmt: any;
    enteredGSTAmt: any;
    lrDtoSaveRet: any;
    lrDtoSaveSet: LRDto = new LRDto();
    @ViewChild("lrNumber") lrNumberField: ElementRef;
    @ViewChild("receiptChargesForBkg") receiptChargesField: ElementRef;
    @ViewChild("destHamaliPerArt") destHamaliPerArtField: ElementRef;
    @ViewChild("destinationHamaliForBkg") destinationHamaliField: ElementRef;
    @ViewChild("discountForBkg") discountField: ElementRef;
    @ViewChild("serviceTax") serviceTaxField: ElementRef;
    @ViewChild("savenprintBtn") savenprintBtnField: ElementRef;
    lrDtoBkgCashmemoDetailsForPrint: LRDto = new LRDto();
    lrDtoBkgCashmemoDetailsForPrintRet: any;
    lrDtoBkgCashmemoDetailsForPrintSet: LRDto = new LRDto();
    showAsSave = false;
    lrDtoForBookingAutoGst: LRDto = new LRDto();
    stationList = [];
    enteredLrNumber: any;
    getCgstPerc: any;
    getCgstAmt: any;
    getSgstPerc: any;
    getSgstAmt: any;
    getIgstPerc: any;
    getIgstAmt: any;
    getGstTotalAmount: any;
    viewLrEntryBkgCashmemoPrintAutoGStPopup = false;
    showBkg = true;
    viewEdit = '';
    getServiceChargesForBkg: any;
    getReceiptChargesForBkg: any;
    getDestHamaliPerArtForBkg: any;
    getDestinationHamaliForBkg: any;
    getSubTotalForBkg: any;
    getDiscountForBkg: any;
    showSpinnerForAction = false;
    pageId="bcpb";
    constructor(
        private http: HttpClient,
        private router: Router,
        private reportService: ReportService, private modalService: NgbModal, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
        private masterReadService: MasterReadService,
        private memoService: MemoService,
        public dialogRef: MatDialogRef<BookingCashmemoPopupComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForBkgCashmemo = JSON.parse(localStorage.getItem('showBkgCashmemo'));
            this.lrDtoBkgCashmemo = new LRDto();
            this.lrDtoBkgCashmemo = this.dataForBkgCashmemo;
            this.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            if (this.lrDtoBkgCashmemo == null) {

            } else {
                this.getLrNumber = this.lrDtoBkgCashmemo.lrNumber;
                $("#"+this.pageId+"lrNumber").val(this.getLrNumber);
                this.enteredLrNumber = this.getLrNumber;
                this.getMode = this.lrDtoBkgCashmemo.mode;

                //                console.log( this.lrDtoBkgCashmemo );
                localStorage.clear();
                //if (this.getMode != null && this.getMode == 'lrEntry') {
                    this.showEdit = true;
                //}
                if (this.getMode == 'bkgCashmemo') {
                    this.showAsSave = true;
                }
                this.getBkgLrValues();
            }

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "CashMemoBkg Edit") {
                        this.showEdit = true;
                    }
                }
            }
            //            if ( this.viewEdit = 'Show Edit in Bkg Cashmemo' ) {
            //                this.showEdit = true;
            //            }

            //For testing 
            //            this.getLrNumber = this.enteredLrNumber;
            if (this.mainstation != null
                && ((this.mainstation == 'Mumbai') || (this.mainstation == 'Gujarat') || (this.mainstation == 'MUMBAI') || (this.mainstation == 'GUJARAT'))) {

                this.showDiscount = true;
            }
            //            if ( this.viewDiscount = 'Show Discount in Bkg Cashmemo' ) {
            //                this.showDiscount = true;
            //            }
            this.setReadOnly();
        }

    }
    ngOnInit() {
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    getLrInfo(e) {
        if (e.keyCode == 13) {
            //            this.getLrNumber = $( "#lrNumber" ).val();
            this.getLrNumber = this.enteredLrNumber;
            if (this.getLrNumber == null || this.getLrNumber == '') {
                swal({
                    title: "Alert",
                    text: "Please enter Lr number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.clearFields();
                this.setReadOnly();
                this.setFieldsValue();
            }

        }
    }
    getBkgLrValues() {
        if (this.getLrNumber != null && this.getLrNumber != '') {
            //                Pending
            this.clearFields();
            $("#"+this.pageId+"lrNumber").val(this.getLrNumber);
            this.setFieldsValue();
        }
    }
    lrDetails() {
        //        this.getLrNumber = $( "#lrNumber" ).val();
        this.getLrNumber = this.enteredLrNumber;
        if (this.getLrNumber == null || this.getLrNumber == '') {
            swal({
                title: "Alert",
                text: "Please enter Lr number",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.clearFields();
            this.setReadOnly();
            this.setFieldsValue();
        }
    }

    serviceChargeBlur(e) {
        //        alert('b1');
        this.enteredServiceChg = $("#"+this.pageId+"serviceChargesForBkg").val();
        if (this.enteredServiceChg != null || this.enteredServiceChg != '') {
            //            alert('b2');
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            this.receiptChargesField.nativeElement.focus();
        }

    }
    receiptTaxBlur(e) {
        this.enteredReceiptChg = $("#"+this.pageId+"receiptChargesForBkg").val();
        if (this.enteredReceiptChg != null || this.enteredReceiptChg != '') {
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            this.destHamaliPerArtField.nativeElement.focus();
        }

    }
    destHamaliPerArtBlur(e) {

        this.enteredDestHamaliPerArt = $("#"+this.pageId+"destHamaliPerArt").val();
        if (this.enteredDestHamaliPerArt != null || this.enteredDestHamaliPerArt != '') {
            this.calculateDestHamali();
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            this.destinationHamaliField.nativeElement.focus();
        }
    }
    destHamali(e) {

        this.enteredDestHamali = $("#"+this.pageId+"destinationHamaliForBkg").val();
        if (this.enteredDestHamali != null || this.enteredDestHamali != '') {
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            //            this.discountField.nativeElement.focus();
            $("#"+this.pageId+"discountForBkg").focus();

        }

    }

    discountBlur(e) {

        this.enteredDiscount = $("#"+this.pageId+"discountForBkg").val();
        if (this.enteredDiscount != null || this.enteredDiscount != '') {
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            //            this.serviceTaxField.nativeElement.focus();
        }

    }
    serviceTaxBlur(e) {
        this.enteredServiceTax = $("#"+this.pageId+"serviceTax").val();
        if (this.enteredServiceTax != null || this.enteredServiceTax != '') {
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                this.logicForGST();
            }
            this.savenprintBtnField.nativeElement.focus();
        }
    }

    // for Btn Clear 
    validateClear() {
        this.setFieldsValue();
        this.clearFields();
        $("#"+this.pageId+"lrNumber").val('');
        this.lrNumberField.nativeElement.focus();

    }

    //For Btn Edit
    validateEdit() {
        this.isReadOnlyForEdit = false;
    }

    setFieldsValue = () => {
        this.lrDtoBkgCashmemoInfo = new LRDto();
        if (this.getLrNumber != null && this.getLrNumber != '') {
            this.lrDtoBkgCashmemoInfo.lrNumber = this.getLrNumber;
        } else {
            //            this.getLrNumber = $( "#lrNumber" ).val();
            this.getLrNumber = this.enteredLrNumber;
            this.lrDtoBkgCashmemoInfo.lrNumber = this.getLrNumber;
        }
        this.lrDtoBkgCashmemoInfo.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;

        this.memoService.getCashMemoBkgDetails(this.lrDtoBkgCashmemoInfo).subscribe(
            (response) => {
                //Pending 
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Oops Problem while Getting Data",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    //Pending
                    this.lrDtoBkgCashmemoInfoRet = response;
                    this.lrDtoBkgCashmemoInfoSet = this.lrDtoBkgCashmemoInfoRet;
                    //                    console.log( this.lrDtoBkgCashmemoInfoSet );

                    if (this.lrDtoBkgCashmemoInfoSet.status != null && this.lrDtoBkgCashmemoInfoSet.status == 'NoData') {
                        swal({
                            title: "Warning",
                            text: "LR Not Found",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.lrDtoBkgCashmemoInfoSet.status != null && this.lrDtoBkgCashmemoInfoSet.status == 'success') {
                        if (this.lrDtoBkgCashmemoInfoSet.source != null) {
                            //                            if ( this.userRole == "Bkg Cashmemo To Generate Memo For Bkg Admin" ) {
                            //                                this.isMatched = false;
                            //                                //Pending
                            //                                this.stationList = this.userDataDtoReturnSession.stationList;
                            //                                console.log(this.stationList);
                            //                                for ( let i = 0; i < this.stationList.length; i++ ) {
                            //                                    if ( this.lrDtoBkgCashmemoInfoSet.source == this.userDataDtoReturnSession.stationList[i] ) {
                            //                                        this.isMatched = true;
                            //                                    }
                            //                                }
                            //                                this.lrDtoBkgCashmemoInfoSet.isTrue = this.isMatched;
                            //                                alert(this.lrDtoBkgCashmemoInfoSet.isTrue);
                            //                                this.IsGenerateCashMemo();
                            //                            } else if ( this.userRole == "Bkg Cashmemo To Generate Memo For Bkg Manager" ) {
                            //                                this.isMatched = false;
                            //                                if ( this.lrDtoBkgCashmemoInfoSet.source == this.userDataDtoReturnSession.office ) {
                            //                                    this.isMatched = true;
                            //                                }
                            //                                this.lrDtoBkgCashmemoInfoSet.isTrue = this.isMatched;
                            //                                this.IsGenerateCashMemo();
                            //                            }
                            this.userRole = this.userDataDtoReturnSession.role;
                            if ((this.userRole != null) && (this.userRole == 'Booking Administrator')) {
                                this.isMatched = false;
                                //Pending
                                this.stationList = this.userDataDtoReturnSession.stationList;
                                //                                console.log(this.stationList);
                                for (let i = 0; i < this.stationList.length; i++) {
                                    if (this.lrDtoBkgCashmemoInfoSet.source == this.userDataDtoReturnSession.stationList[i]) {
                                        this.isMatched = true;
                                    }
                                }
                                this.lrDtoBkgCashmemoInfoSet.isTrue = this.isMatched;
                                //                                alert(this.lrDtoBkgCashmemoInfoSet.isTrue);
                                this.IsGenerateCashMemo();
                            } else if ((this.userRole != null) && (this.userRole == 'Booking Manager')) {
                                this.isMatched = false;
                                if (this.lrDtoBkgCashmemoInfoSet.source == this.userDataDtoReturnSession.office) {
                                    this.isMatched = true;
                                }
                                this.lrDtoBkgCashmemoInfoSet.isTrue = this.isMatched;
                                this.IsGenerateCashMemo();
                            }
                        }

                    } else if (this.lrDtoBkgCashmemoInfoSet.status != null && this.lrDtoBkgCashmemoInfoSet.status == 'failed') {
                        this.showSpinnerForAction = false;
                        swal({
                            title: "Error",
                            text: "Oops Problem while Getting Data",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }


                }
                this.changeDetectorRef.detectChanges();

            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    IsGenerateCashMemo() {
        if (this.lrDtoBkgCashmemoInfoSet.isTrue == true) {
            this.generateCashMemo();
        } else {

            swal({
                title: "Alert",
                text: "You Are Not Allowed ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });


        }
    }
    generateCashMemo() {
        if ((this.lrDtoBkgCashmemoInfoSet.paid != null) && (this.lrDtoBkgCashmemoInfoSet.paid > 0)) {
            this.printDto = new LRDto();
            this.printDto = this.lrDtoBkgCashmemoInfoSet;
            //            txtHamali.focus();
            $("#"+this.pageId+"lrNumber").val(this.getLrNumber);
            $("#"+this.pageId+"memoNo").val(this.lrDtoBkgCashmemoInfoSet.cashMemoNumber);
            $("#"+this.pageId+"serviceChargesForBkg").val(this.lrDtoBkgCashmemoInfoSet.serviceCharge);
            $("#"+this.pageId+"receiptChargesForBkg").val(this.lrDtoBkgCashmemoInfoSet.receiptCharge);
            $("#"+this.pageId+"destHamaliPerArt").val(this.lrDtoBkgCashmemoInfoSet.destHamaliPerArt);
            $("#"+this.pageId+"destinationHamaliForBkg").val(this.lrDtoBkgCashmemoInfoSet.destHamali);
            //Pending
            $("#"+this.pageId+"discountForBkg").val(this.lrDtoBkgCashmemoInfoSet.discountAmount);
            $("#"+this.pageId+"serviceTax").val(this.lrDtoBkgCashmemoInfoSet.serviceTax);
            $("#"+this.pageId+"serviceTaxAmt").val(this.lrDtoBkgCashmemoInfoSet.servTaxAmt);
            //
            $("#"+this.pageId+"subTotal").val(this.lrDtoBkgCashmemoInfoSet.subTotal);
            $("#"+this.pageId+"grandTotal").val(this.lrDtoBkgCashmemoInfoSet.grandTotal);
            //Pending
            //            this.enteredBookingDate = this.lrDtoBkgCashmemoInfoSet.bookingDate;
            this.enteredBookingDate = this.datePipe.transform(this.lrDtoBkgCashmemoInfoSet.bookingDate, "dd-MM-yyyy");
            //Pending booking date need to be set and 
            $("#"+this.pageId+"bookingDateForBkg").val(this.enteredBookingDate);
            //            console.log( this.lrDtoBkgCashmemoInfoSet.bookingDate );
            $("#"+this.pageId+"commodityForBkg").val(this.lrDtoBkgCashmemoInfoSet.commodityName);
            $("#"+this.pageId+"sourceStation").val(this.lrDtoBkgCashmemoInfoSet.source);
            $("#"+this.pageId+"destinationForBkg").val(this.lrDtoBkgCashmemoInfoSet.destination);
            $("#"+this.pageId+"agentNameForBkg").val(this.lrDtoBkgCashmemoInfoSet.agentName);
            $("#"+this.pageId+"consignorNameForBkg").val(this.lrDtoBkgCashmemoInfoSet.consignorName);
            $("#"+this.pageId+"consigneeNameForBkg").val(this.lrDtoBkgCashmemoInfoSet.consigneeName);
            $("#"+this.pageId+"noOfArticle").val(this.lrDtoBkgCashmemoInfoSet.totalArticles);
            $("#"+this.pageId+"actualWeightForBkg").val(this.lrDtoBkgCashmemoInfoSet.actualWeight);
            $("#"+this.pageId+"chargedWeightForBkg").val(this.lrDtoBkgCashmemoInfoSet.chargedWeight);
            $("#"+this.pageId+"paidForBkg").val(this.lrDtoBkgCashmemoInfoSet.paid);
            this.isGSTMemo = this.lrDtoBkgCashmemoInfoSet.isGSTMemo;
            //            console.log( this.isGSTMemo );
            this.grandSubSum();
            if (this.isGSTMemo == false) {
                this.grandSum();
            } else {
                //Pending
                this.logicForGST();
            }
        } else {
            swal({
                title: "Not Allowed",
                text: "Paid Amount Is Zero",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#"+this.pageId+"lrNumber").val('');

            this.lrNumberField.nativeElement.focus();
        }
    }


    grandSubSum() {
        //        alert( 'aaa' );
        this.enteredPaid = $("#"+this.pageId+"paidForBkg").val();
        this.enteredServiceChg = $("#"+this.pageId+"serviceChargesForBkg").val();
        this.enteredReceiptChg = $("#"+this.pageId+"receiptChargesForBkg").val();
        if (this.showDiscount == true) {
            this.enteredDiscount = $("#"+this.pageId+"discountForBkg").val();
        } else {
            this.enteredDiscount = 0;
        }
        this.enteredDestHamali = $("#"+this.pageId+"destinationHamaliForBkg").val();
        this.enteredDestHamaliPerArt = $("#"+this.pageId+"destHamaliPerArt").val();
        this.enteredSubTotal = 0;

        //        this.enteredServiceChg =this.enteredServiceChg == null ? 0.0 : this.enteredServiceChg;
        //        this.enteredReceiptChg =this.enteredReceiptChg == null ? 0.0 : this.enteredReceiptChg;
        //        this.enteredDestHamali =this.enteredDestHamali == null ? 0.0 : this.enteredDestHamali;
        //        this.enteredDiscount =this.enteredDiscount == null ? 0.0 : this.enteredDiscount;
        //        alert( 'eaa1' + this.enteredPaid );
        //        alert( 'eaa2' + this.enteredServiceChg );
        //        alert( 'eaa3' + this.enteredReceiptChg );
        //        alert( 'eaa4' + this.enteredDiscount );
        //        alert( 'eaa5' + this.enteredDestHamali );
        //        alert( 'eaa6' + this.enteredDestHamaliPerArt );
        if ((this.enteredPaid != null) && (this.enteredServiceChg != null) && (this.enteredReceiptChg != null) && (this.enteredDiscount != null) && (this.enteredDestHamali != null) && (this.enteredDestHamaliPerArt != null)) {

            this.enteredSubTotal = (+(this.enteredPaid) + +(this.enteredServiceChg == null ? 0.0 : this.enteredServiceChg) + +(this.enteredReceiptChg == null ? 0.0 : this.enteredReceiptChg) + +(this.enteredDestHamali == null ? 0.0 : this.enteredDestHamali) + -(this.enteredDiscount == null ? 0.0 : this.enteredDiscount));

            //            alert( 'sub : ' + this.enteredSubTotal );
            this.getSubTotalForBkg = this.enteredSubTotal;

        }
    }
    grandSum() {
        //Pending 
        //        ftGst.setVisible(false);
        //        nfGSTTotal.setVisible(false);
        this.showServiceTaxAmt = true;
        this.showServiceTax = true;
        $("#"+this.pageId+"serviceTaxAmt").val('');

        this.enteredServiceTax = $("#"+this.pageId+"serviceTax").val();
        this.getServiceTax = this.enteredServiceTax;
        this.enteredSubTotal = $("#"+this.pageId+"subTotal").val();
        this.doubleSubtotal = this.enteredSubTotal;
        this.enteredServiceTaxAmt = $("#"+this.pageId+"serviceTaxAmt").val();
        this.doubleServiceTaxAmt = this.enteredSubTotal;

        if ((this.enteredServiceTax != null) && (this.enteredServiceTax != '') && (this.enteredSubTotal != null) && (this.enteredSubTotal != '')) {
            this.stPerc = this.getServiceTax;
            this.amtLimit = this.userDataDtoReturnSession.servTaxAmt;
            if (this.doubleSubtotal >= this.amtLimit) {
                if (this.stPerc == 0.0) {
                    //                    $( "#serviceTaxAmt" ).val('0.0');
                    //                    $( "#serviceTax" ).val('0');
                    $("#"+this.pageId+"serviceTaxAmt").val(0.0);
                    $("#"+this.pageId+"serviceTax").val(0);

                } else {
                    this.serviceTaxAmountCalc = (this.doubleSubtotal * this.stPerc) / 100;
                    $("#"+this.pageId+"serviceTaxAmt").val(this.serviceTaxAmountCalc);

                }
            } else {
                $("#"+this.pageId+"serviceTaxAmt").val(0.0);
                $("#"+this.pageId+"serviceTax").val(0);
            }

            this.enteredServiceTaxAmt = $("#"+this.pageId+"serviceTaxAmt").val();
            this.doubleServiceTaxAmt = this.enteredSubTotal;

            this.grandTotal = +this.doubleSubtotal + +this.doubleServiceTaxAmt;
            $("#"+this.pageId+"grandTotal").val(this.grandTotal);
        }



    }
    logicForGST() {
        //        alert( 'hai' );
        $("#"+this.pageId+"cgstPerc").val('');
        $("#"+this.pageId+"cgstAmt").val('');
        $("#"+this.pageId+"sgstPerc").val('');
        $("#"+this.pageId+"sgstAmt").val('');
        $("#"+this.pageId+"igstPerc").val('');
        $("#"+this.pageId+"igstAmt").val('');
        $("#"+this.pageId+"gstTotal").val('');
        this.showGst = true;
        this.showServiceTaxAmt = false;
        this.showServiceTax = false;
        this.amtLimit = this.userDataDtoReturnSession.serviceTaxAmt;
        //        console.log( this.amtLimit );
        this.enteredSubTotal = this.getSubTotalForBkg;
        this.doubleSubtotal = this.enteredSubTotal;
        this.enteredPaid = $("#"+this.pageId+"paidForBkg").val();

        this.enteredCGSTAmt = $("#"+this.pageId+"cgstAmt").val();
        this.enteredSGSTAmt = $("#"+this.pageId+"sgstAmt").val();
        this.enteredIGSTAmt = $("#"+this.pageId+"igstAmt").val();

        this.gstAmt = 0.0;




        if (this.enteredPaid == null || this.enteredPaid == '' || this.enteredPaid == 0
            || this.enteredPaid == 0.0) {
            if (this.doubleSubtotal >= this.amtLimit) {
                this.gstAmt = (this.doubleSubtotal * 5) / 100;
                //                On edit this show in the pop up
                $("#"+this.pageId+"cgstPerc").val(0);
                $("#"+this.pageId+"cgstAmt").val(0);
                $("#"+this.pageId+"sgstPerc").val(0);
                $("#"+this.pageId+"sgstAmt").val(0);
                $("#"+this.pageId+"igstPerc").val(5);
                $("#"+this.pageId+"igstAmt").val(this.gstAmt);
                //                On load this show in the pop up
                this.getCgstPerc = 0;
                this.getCgstAmt = 0;
                this.getSgstPerc = 0;
                this.getSgstAmt = 0;
                this.getIgstPerc = 2.5;
                this.getIgstAmt = this.gstAmt;

            }
        } else {
            //            alert( 'entered :' + this.enteredSubTotal );
            //            alert( 'amount :' + this.amtLimit );
            if (this.doubleSubtotal >= this.amtLimit) {
                this.gstAmt = (this.doubleSubtotal * 2.5) / 100;
                //                alert( 'entered1' );
                //                On edit this show in the pop up
                this.getCgstPerc = 2.5;
                this.getCgstAmt = this.gstAmt;
                this.getSgstPerc = 2.5;
                this.getSgstAmt = this.gstAmt;
                this.getIgstPerc = 0;
                this.getIgstAmt = 0;
                //                On load this show in the pop up
                $("#"+this.pageId+"cgstPerc").val(2.5);
                $("#"+this.pageId+"cgstAmt").val(this.gstAmt);
                $("#"+this.pageId+"sgstPerc").val(2.5);
                $("#"+this.pageId+"sgstAmt").val(this.gstAmt);
                $("#"+this.pageId+"igstPerc").val(0);
                $("#"+this.pageId+"igstAmt").val(0);
            }
        }
        this.gstTotalAmt = 0.0;
        //        this.gstTotalAmt = ( +( this.enteredCGSTAmt == null ? 0.0 : this.enteredCGSTAmt == '' ? 0.0 : this.enteredCGSTAmt )
        //            + +( this.enteredSGSTAmt == null ? 0.0 : this.enteredSGSTAmt == '' ? 0.0 : this.enteredSGSTAmt )
        //            + +( this.enteredIGSTAmt == null ? 0.0 : this.enteredIGSTAmt == '' ? 0.0 : this.enteredIGSTAmt ) )
        //        this.gstTotalAmt = ( +(this.getCgstAmt) ++(this.getSgstAmt) ++(this.getIgstAmt));
        this.gstTotalAmt = (+(this.getCgstAmt == null ? 0.0 : this.getCgstAmt == '' ? 0.0 : this.getCgstAmt) + +(this.getSgstAmt == null ? 0.0 : this.getSgstAmt == '' ? 0.0 : this.getSgstAmt) + +(this.getIgstAmt == null ? 0.0 : this.getIgstAmt == '' ? 0.0 : this.getIgstAmt));
        $("#"+this.pageId+"gstTotal").val(this.gstTotalAmt);
        //        alert('00000000 :'+this.gstTotalAmt);
        this.getGstTotalAmount = this.gstTotalAmt;

        this.grandTotal = this.doubleSubtotal;
        $("#"+this.pageId+"grandTotal").val(this.grandTotal);

    }

    calculateDestHamali() {
        this.enteredArticles = $("#"+this.pageId+"noOfArticle").val();
        this.getArticles = this.enteredArticles;
        this.enteredDestHamaliPerArt = $("#"+this.pageId+"destHamaliPerArt").val();
        this.getDestHamaliPerArt = this.enteredDestHamaliPerArt;
        //        console.log('111111'+ this.enteredArticles );
        //        console.log( this.getArticles );
        //        console.log( this.enteredDestHamaliPerArt );
        //        console.log( this.getDestHamaliPerArt );
        if (this.enteredDestHamali != null
            && this.enteredDestHamali != '' && this.enteredDestHamaliPerArt != null
            && this.enteredDestHamaliPerArt != '') {
            this.setDestHamali = this.getDestHamaliPerArt * this.getArticles;

            $("#"+this.pageId+"destinationHamaliForBkg").val(this.setDestHamali);
            this.setDestHamali
            //            console.log(  this.setDestHamali);

        }
    }


    setReadOnly() {
        this.isReadOnly = true;
        this.isReadOnlyForEdit = true;
    }


    validateSave() {
        //        this.getLrNumber = $( "#lrNumber" ).val();
        this.getLrNumber = this.enteredLrNumber;


        if (this.getLrNumber == null || this.getLrNumber == '') {
            swal({
                title: "Warning",
                text: "Please Enter LR No",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            this.enteredServiceChg = $("#"+this.pageId+"serviceChargesForBkg").val();
            this.enteredReceiptChg = $("#"+this.pageId+"receiptChargesForBkg").val();
            this.enteredDiscount = $("#"+this.pageId+"discountForBkg").val();
            this.enteredDestHamali = $("#"+this.pageId+"destinationHamaliForBkg").val();
            this.enteredDestHamaliPerArt = $("#"+this.pageId+"destHamaliPerArt").val();
            this.enteredArticles = $("#"+this.pageId+"noOfArticle").val();
            this.enteredSubTotal = $("#"+this.pageId+"subTotal").val();
            this.enteredServiceTax = $("#"+this.pageId+"serviceTax").val();
            this.enteredServiceTaxAmt = $("#"+this.pageId+"serviceTaxAmt").val();
            this.enteredGrandTotalAmt = $("#"+this.pageId+"grandTotal").val();
            this.enteredGSTAmt = $("#"+this.pageId+"gstTotal").val();
            this.enteredCGSTPerc = $("#"+this.pageId+"cgstPerc").val();
            this.enteredSGSTPerc = $("#"+this.pageId+"sgstPerc").val();
            this.enteredIGSTPerc = $("#"+this.pageId+"igstPerc").val();
            this.enteredCGSTAmt = $("#"+this.pageId+"cgstAmt").val();
            this.enteredSGSTAmt = $("#"+this.pageId+"sgstAmt").val();
            this.enteredIGSTAmt = $("#"+this.pageId+"igstAmt").val();

            this.lrDtoBkgCashmemoSave = new LRDto();
            this.lrDtoBkgCashmemoSave.lrNumber = this.getLrNumber;
            this.lrNumberPrint = this.lrDtoBkgCashmemoSave.lrNumber;
            this.lrDtoBkgCashmemoSave.lastUpdatedBy = this.userDataDtoReturnSession.userId;
            this.lrDtoBkgCashmemoSave.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoBkgCashmemoSave.serviceCharge = (this.enteredServiceChg == null ? 0.0 : this.enteredServiceChg == '' ? 0.0 : this.enteredServiceChg);
            this.lrDtoBkgCashmemoSave.receiptCharge = (this.enteredReceiptChg == null ? 0.0 : this.enteredReceiptChg == '' ? 0.0 : this.enteredReceiptChg);
            this.lrDtoBkgCashmemoSave.destHamali = (this.enteredDestHamali == null ? 0.0 : this.enteredDestHamali == '' ? 0.0 : this.enteredDestHamali);
            this.lrDtoBkgCashmemoSave.destHamaliPerArt = (this.enteredDestHamaliPerArt == null ? 0.0 : this.enteredDestHamaliPerArt == '' ? 0.0 : this.enteredDestHamaliPerArt);
            this.lrDtoBkgCashmemoSave.discountAmount = (this.enteredDiscount == null ? 0.0 : this.enteredDiscount == '' ? 0.0 : this.enteredDiscount);
            this.lrDtoBkgCashmemoSave.subTotal = (this.enteredSubTotal == null ? 0.0 : this.enteredSubTotal == '' ? 0.0 : this.enteredSubTotal);
            this.lrDtoBkgCashmemoSave.grandTotal = (this.enteredGrandTotalAmt == null ? 0.0 : this.enteredGrandTotalAmt == '' ? 0.0 : this.enteredGrandTotalAmt);
            this.lrDtoBkgCashmemoSave.serviceTax = (this.enteredServiceTax == null ? 0.0 : this.enteredServiceTax == '' ? 0.0 : this.enteredServiceTax);
            this.lrDtoBkgCashmemoSave.servTaxAmt = (this.enteredServiceTaxAmt == null ? 0.0 : this.enteredServiceTaxAmt == '' ? 0.0 : this.enteredServiceTaxAmt);
            this.lrDtoBkgCashmemoSave.igst = (this.enteredIGSTPerc == null ? 0.0 : this.enteredIGSTPerc == '' ? 0.0 : this.enteredIGSTPerc);
            this.lrDtoBkgCashmemoSave.igstamt = (this.enteredIGSTAmt == null ? 0.0 : this.enteredIGSTAmt == '' ? 0.0 : this.enteredIGSTAmt);
            this.lrDtoBkgCashmemoSave.cgst = (this.enteredCGSTPerc == null ? 0.0 : this.enteredCGSTPerc == '' ? 0.0 : this.enteredCGSTPerc);
            this.lrDtoBkgCashmemoSave.cgstamt = (this.enteredCGSTAmt == null ? 0.0 : this.enteredCGSTAmt == '' ? 0.0 : this.enteredCGSTAmt);
            this.lrDtoBkgCashmemoSave.sgst = (this.enteredSGSTPerc == null ? 0.0 : this.enteredSGSTPerc == '' ? 0.0 : this.enteredSGSTPerc);
            this.lrDtoBkgCashmemoSave.sgstamt = (this.enteredSGSTAmt == null ? 0.0 : this.enteredSGSTAmt == '' ? 0.0 : this.enteredSGSTAmt);
            this.lrDtoBkgCashmemoSave.gstAmtTotal = (this.enteredGSTAmt == null ? 0.0 : this.enteredGSTAmt == '' ? 0.0 : this.enteredGSTAmt);

            this.saveBkgCashmemo();

        }

    }

    saveBkgCashmemo = () => {
        //        alert( '1' + this.lrNumberPrint );
        this.memoService.updatedBkgCashMemo(this.lrDtoBkgCashmemoSave).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Oops Problem Occured In Server",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    //                    alert( '2' + this.lrNumberPrint );
                    this.clearFields();
                    //                    alert( '3' + this.lrNumberPrint );
                    this.setReadOnly();
                    this.lrDtoSaveRet = response;
                    this.lrDtoSaveSet = new LRDto();
                    this.lrDtoSaveSet = this.lrDtoSaveRet;
                    if (this.lrDtoSaveSet.status != null && this.lrDtoSaveSet.status != '' && this.lrDtoSaveSet.status == 'success') {
                        swal({
                            title: "Success",
                            text: "LR Updated Successfully ",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,

                        }).then(() => {
                            $("#"+this.pageId+"lrNumber").val('');
                            this.lrNumberField.nativeElement.focus();
                        });
                        swal({
                            title: "Print Booking CashMemo",
                            text: "Sure U Want to Print this Booking Cashmemo",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                            buttons: ["No", "Yes"],
                        }).then((setPrint) => {
                            if (setPrint) {
                                this.setPrintDetails();
                            }else{
                            	this.dialogRef.close();
                            }
                        });
                    } else if (this.lrDtoSaveSet.status != null && this.lrDtoSaveSet.status != '' && this.lrDtoSaveSet.status == 'NoData') {
                        swal({
                            title: "Result",
                            text: "LR Not Found",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                    } else if (this.lrDtoSaveSet.status != null && this.lrDtoSaveSet.status != '' && this.lrDtoSaveSet.status == 'failed') {
                        swal({
                            title: "Warning",
                            text: "LR Failed To Update",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }


                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    setPrintDetails = () => {
        //        alert( '4' + this.lrNumberPrint );
        this.lrDtoBkgCashmemoDetailsForPrint = new LRDto();
        this.lrDtoBkgCashmemoDetailsForPrint.lrNumber = this.lrNumberPrint;
        this.lrNumberPrint = null;
        this.lrDtoBkgCashmemoDetailsForPrint.companyId = this.userDataDtoReturnSession.companyId;
        //        console.log( this.lrDtoBkgCashmemoDetailsForPrint.lrNumber );
        //        console.log( this.lrDtoBkgCashmemoDetailsForPrint.companyId );

        this.memoService.getCashMemoBkgDetails(this.lrDtoBkgCashmemoDetailsForPrint).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Oops Problem while Getting Data",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoBkgCashmemoDetailsForPrintRet = response;
                    this.lrDtoBkgCashmemoDetailsForPrintSet = this.lrDtoBkgCashmemoDetailsForPrintRet;

                    if (this.lrDtoBkgCashmemoDetailsForPrintSet.status != null && this.lrDtoBkgCashmemoDetailsForPrintSet.status == 'NoData') {
                        swal({
                            title: "Warning",
                            text: "LR Not Found",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.lrDtoBkgCashmemoDetailsForPrintSet.status != null && this.lrDtoBkgCashmemoDetailsForPrintSet.status == 'success') {

                        if ((this.lrDtoBkgCashmemoDetailsForPrintSet.paid != null) && (this.lrDtoBkgCashmemoDetailsForPrintSet.paid > 0)) {
                            this.printDto = new LRDto();
                            this.printDto = this.lrDtoBkgCashmemoDetailsForPrintSet;
                            this.printDto.entryBy = this.userDataDtoReturnSession.userId;

                            //                            alert( 'am here 1' );
                            //                            alert(this.isGSTMemo);
                            if (this.isGSTMemo == true) {
                                //                                alert( 'am here 2' );
                                //Pending
                                this.showBkgCashmemoPaidAutoGst();

                            } else if (this.isGSTMemo == false) {
                                //                                new CashMemoBkgPrintAuto(
                                //                                        printDto,
                                //                                        SRDWeb.mainAdminStation);
                            }
                            //Pending
                            //                            if ( ( this.getLrNumber != null ) && ( this.getLrNumber != '' )
                            //                                && ( this.getMode != 'bkgCashmemo)) {
                            //                                        
                            //                                     Pending
                            //                            LREntryForm.dialog
                            //                                .hide();
                            //                            LREntryForm.dialog
                            //                                .removeAll();
                            //                            LREntryForm
                            //                                .setPrintLrs( LREntryForm.resultDtos );
                            //                        }
                        } else {
                            swal({
                                title: "Not Allowed",
                                text: "Paid Amount Is Zero",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });

                        }
                    } else if (this.lrDtoBkgCashmemoDetailsForPrintSet.status != null && this.lrDtoBkgCashmemoDetailsForPrintSet.status == 'failed') {
                        swal({
                            title: "Error",
                            text: "Oops Problem while Getting Data",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    clearFields() {
        //        alert( 'aaadfsadfasd' );
        console.log('clear');
        this.lrDtoBkgCashmemo = new LRDto();
        this.lrDtoBkgCashmemoInfo = new LRDto();
        this.dataForBkgCashmemo = '';
        //Pending
        //        this.getLrNumber = '';
        this.getMode = '';
        this.lrDtoBkgCashmemoInfoRet = '';
        this.lrDtoBkgCashmemoInfoSet = new LRDto();
        this.isMatched = false;
        this.printDto = new LRDto();
        //        this.isGSTMemo = false;
        this.enteredBookingDate = '';
        this.enteredPaid = '';
        this.enteredServiceChg = '';
        this.enteredReceiptChg = '';
        this.enteredDiscount = '';
        this.enteredDestHamali = '';
        this.enteredDestHamaliPerArt = '';
        this.enteredSubTotal = '';
        this.showServiceTaxAmt = false;
        this.showServiceTax = false;
        this.enteredServiceTax = '';
        this.getServiceTax = 0.0
        this.stPerc = 0.0;
        this.amtLimit = 0.0;
        this.serviceTaxAmountCalc = 0.0;
        this.doubleSubtotal = 0.0;
        this.grandTotal = 0.0;
        this.enteredServiceTaxAmt = '';
        this.doubleServiceTaxAmt = 0.0;
        this.showGst = false;
        this.gstAmt = 0.0;
        this.enteredCGSTAmt = '';
        this.enteredSGSTAmt = '';
        this.enteredIGSTAmt = '';
        this.enteredCGSTPerc = '';
        this.enteredSGSTPerc = '';
        this.enteredIGSTPerc = '';
        this.gstTotalAmt = 0.0;
        this.setDestHamali = 0.0;
        this.getDestHamaliPerArt = '';
        this.enteredArticles = '';
        this.getArticles = 0.0;
        //        this.showDiscount = false;
        this.isReadOnly = false;
        this.isReadOnlyForEdit = false;
        this.lrDtoBkgCashmemoSave = new LRDto();
        //        Pending
        //        this.lrNumberPrint = null;
        this.enteredGrandTotalAmt = '';
        this.enteredGSTAmt = '';
        this.lrDtoSaveRet = '';
        this.lrDtoSaveSet = new LRDto();
        this.lrDtoBkgCashmemoDetailsForPrint = new LRDto();
        this.lrDtoBkgCashmemoDetailsForPrintRet = '';
        this.lrDtoBkgCashmemoDetailsForPrintSet = new LRDto();
        this.getCgstPerc = '';
        this.getCgstAmt = '';
        this.getSgstPerc = '';
        this.getSgstAmt = '';
        this.getIgstPerc = '';
        this.getIgstAmt = '';
        this.getGstTotalAmount = '';
        this.viewLrEntryBkgCashmemoPrintAutoGStPopup = false;
        this.getServiceChargesForBkg = '';
        this.getReceiptChargesForBkg = '';
        this.getDestHamaliPerArtForBkg = '';
        this.getDestinationHamaliForBkg = '';
        this.getSubTotalForBkg = '';
        this.getDiscountForBkg = '';
        $("#"+this.pageId+"lrNumber").val('');
        $("#"+this.pageId+"memoNo").val('');
        $("#"+this.pageId+"serviceChargesForBkg").val('');
        $("#"+this.pageId+"receiptChargesForBkg").val('');
        $("#"+this.pageId+"destHamaliPerArt").val('');
        $("#"+this.pageId+"destinationHamaliForBkg").val('');
        //Pending
        $("#"+this.pageId+"discountForBkg").val('');
        $("#"+this.pageId+"serviceTax").val('');
        $("#"+this.pageId+"serviceTaxAmt").val('');
        //
        $("#"+this.pageId+"subTotal").val('');
        $("#"+this.pageId+"grandTotal").val('');
        $("#"+this.pageId+"bookingDateForBkg").val('');
       
        $("#"+this.pageId+"commodityForBkg").val('');
        $("#"+this.pageId+"sourceStation").val('');
        $("#"+this.pageId+"destinationForBkg").val('');
        $("#"+this.pageId+"agentNameForBkg").val('');
        $("#"+this.pageId+"consignorNameForBkg").val('');
        $("#"+this.pageId+"consigneeNameForBkg").val('');
        $("#"+this.pageId+"noOfArticle").val('');
        $("#"+this.pageId+"actualWeightForBkg").val('');
        $("#"+this.pageId+"chargedWeightForBkg").val('');
        $("#"+this.pageId+"paidForBkg").val('');
           $("#"+this.pageId+"cgstPerc").val('');
            $("#"+this.pageId+"cgstAmt").val('');
            $("#"+this.pageId+"sgstPerc").val('');
            $("#"+this.pageId+"sgstAmt").val('');
            $("#"+this.pageId+"igstPerc").val('');
            $("#"+this.pageId+"igstAmt").val('');
            $("#"+this.pageId+"gstTotal").val('');

    }

    showBkgCashmemoPaidAutoGst(): void {
        this.printDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDtoForBookingAutoGst = this.printDto;
        localStorage.clear();
        localStorage.setItem('printBkgCashmemoAutoGst', JSON.stringify(this.lrDtoForBookingAutoGst));
        this.dialogRef.close();
    }
    
    onCancelClick(): void {
    	this.dialogRef.close();
	}
}
