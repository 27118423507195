import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { MasterService } from "src/app/dataService/master-service";
import { Subject, merge } from "rxjs";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-cft-exemption",
  templateUrl: "./cft-exemption.component.html",
  styleUrls: ["./cft-exemption.component.css"],
})
export class CftExemptionMasterComponent implements OnInit {

  dtTriggerCftExmpCommodity: Subject<any> = new Subject();
  dtOptionsCftExmpCommodity: any;

  public modelSource: any;
  //sourceList
  controlAgentName = new FormControl();
  agentNameTA: Array<any> = [];
  agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
  @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
  focusAgentNameTA$ = new Subject<string>();
  clickAgentNameTA$ = new Subject<string>();
  agentNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickAgentNameTA$.pipe(filter(() => !this.instanceAgentName.isPopupOpen()));
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  //For Destination
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { destination: string }) => x.destination;
  showSpinnerForAction = false;
  destinationDtoForAll: LRDto = new LRDto();
  destinationList: Array<any> = [];

  userDataDtoReturnSession: any;
  isLoggedIn = true;
  rateMasterDtoUserSearch: RateMasterDto = new RateMasterDto();
  cftExmpCommodityDataList: any;
  enteredSource: any;
  enteredDest: any;
  enteredCommodity: any;
  enteredConsignorId: any;
  enteredChgWgt: any;
  enteredEffecDate: any;
  selectedRateId = 0;
  lrDtoBkgSrcAll: LRDto = new LRDto();

  //For Commodity list
  rateMasterDto: RateMasterDto = new RateMasterDto();
  rateMasterDtoOptions: RateMasterDto[];
  rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
  public modelCommodityList: any;
  commodityListTA: Array<RateMasterDto> = [];
  focusCommodityListTA$ = new Subject<string>();
  searchCommodityList = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListTA
        : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
  agentDetailsOptionsSet: AgentDetailsDto[];
  agentDetailsOptionsGet: AgentDetailsDto[];
  pageId = "cftexc";

  constructor(private masterReadService: MasterReadService, private router: Router, private masterService: MasterService,
    public changeDetectorRef: ChangeDetectorRef) {

    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.getDestinationDetails();
      this.getAgentDetailList();
      this.getCommodityMasterDetailsList();

      setTimeout(() => {
        this.getCFTExemptionForCommoditiesDetails();
      }, 500);
    }
  }

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  ngOnInit(): void {
    this.dtOptionsCftExmpCommodity = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      processing: true,
      responsive: true,
      "scrollX": false,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": true,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
      }
    };
  }

  ngOnDestroy(): void {
    this.dtTriggerCftExmpCommodity.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerCftExmpCommodity.next();
  }

  sourceListener(e: NgbTypeaheadSelectItemEvent) {
    this.modelSource = e.item;
    $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
  }

  //For Destination Listener
  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
    this.modelDestination = e.item;
    $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
  }

  ///listener FOr commodity List
  clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
    this.modelCommodityList = e.item;
    $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    //this.validateCommodityListValue = this.modelCommodityList.commodityName
  }

  //for Getting Agent
  getUserValuesForAgentDetailsList() {
    this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.agentDtoParam.column2 = 'Working';
    this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoParam.mode = 'Sub';
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Agents  records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlAgentName.reset();
        } else {
          this.agentDetailsOptionsGet = response;
          this.agentDetailsOptionsSet = response;
          console.log('ok : ' + this.agentDetailsOptionsGet);
          this.agentNameTA = [];
          for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
            this.agentNameTA.push(this.agentDetailsOptionsGet[i].subStation);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.mode = "LrForm";
  }

  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
      (response) => {
        console.log(response);
        this.showSpinnerForAction = false;
        if (response) {
          if (response.length > 0) {
            this.destinationOptions = response;
            this.destinationTA = [];
            this.destinationDtoForAll.destination = "ALL";
            this.destinationTA.push(this.destinationDtoForAll);
            this.destinationList = [];
            for (let i = 0; i < this.destinationOptions.length; i++) {
              this.destinationTA.push(this.destinationOptions[i]);
              this.destinationList.push(this.destinationOptions[i].destination);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
      },
      () => console.log('done');
  };

  getCommodityListRead() {
    this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDto.mode = "mainConsigneeMaster";
  }

  getCommodityMasterDetailsList = () => {
    this.getCommodityListRead();
    this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          if (response.length == 0) {
            swal({
              title: "Warning",
              text: "No commodity details records found!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
          } else {
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
            this.rateMasterDtoOptions = response;
            for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
              this.commodityListTA.push(this.rateMasterDtoOptions[i]);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Error",
        text: "Server Error While Getting Commodity Master Details",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  }

  getCFTExemptionForCommoditiesDetails = () => {
    this.showSpinnerForAction = true;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.rateMasterDtoUserSearch.source = this.userDataDtoReturnSession.mainAdminStation;
    this.masterService.getCftExemptionDetails(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        $("#" + this.pageId + "cftExemptionCommodityTableId").DataTable().destroy();
        this.cftExmpCommodityDataList = [];
        if (response.length == 0) {
          swal({
            title: "CFT Exemption - Commodity Wise",
            text: "No Details found!",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.cftExmpCommodityDataList = response;
        }
        setTimeout(() => {
          this.dtTriggerCftExmpCommodity.next();
        }, 500);
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details", "info");
      }, () => console.log('done');
  }

  confirmSave() {
    swal({
      title: "Confirmation",
      text: "Sure you want to save/update the changes?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.saveDetails();
      }
    })
  }

  getUserValueSave() {
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.enteredSource = $("#" + this.pageId + "agentName").val();
    this.enteredDest = $("#" + this.pageId + "destination").val();
    this.enteredCommodity = $("#" + this.pageId + "commodityListId").val();
    this.enteredChgWgt = $("#" + this.pageId + "chargedWt").val();

    this.rateMasterDtoUserSearch.id = this.selectedRateId;
    this.rateMasterDtoUserSearch.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDtoUserSearch.source = this.enteredSource;
    this.rateMasterDtoUserSearch.destination = this.enteredDest;
    this.rateMasterDtoUserSearch.commodityName = this.enteredCommodity;
    this.rateMasterDtoUserSearch.chargedWgt = this.enteredChgWgt;
  }

  saveDetails = () => {
    this.showSpinnerForAction = true;
    this.getUserValueSave();
    this.masterService.addUpdateCftExemptionDetails(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        if (response.status == "Success") {
          swal("Success", "Details saved/updated successfully!", "info");
          this.clearFields();
          this.getCFTExemptionForCommoditiesDetails();
        } else if (response.status == "Duplicate") {
          swal("Duplicate Entry", "Entered exemption criteria is already available, please choose different or verify again.", "info");
        } else if (response.status == "Failed") {
          swal("Failed", "Failed to save/update the details!", "info");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while saving the details", "info");
      }, () => console.log('done');
  };

  rowSelectedEdit(cftExmpCommodityData) {
    this.selectedRateId = cftExmpCommodityData.id;
    $("#" + this.pageId + "agentName").val(cftExmpCommodityData.source);
    $("#" + this.pageId + "destination").val(cftExmpCommodityData.destination);
    $("#" + this.pageId + "commodityListId").val(cftExmpCommodityData.commodityName);
    $("#" + this.pageId + "chargedWt").val(cftExmpCommodityData.chargedWgt);
  }

  confirmDelete(cftExmpCommodityData) {
    swal({
      title: "Confirmation",
      text: "Sure you want to delete the details?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.confirmDeleteService(cftExmpCommodityData);
      }
    })
  }

  confirmDeleteService(cftExmpCommodityData) {
    this.selectedRateId = cftExmpCommodityData.id;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    this.rateMasterDtoUserSearch.id = this.selectedRateId;
    this.rateMasterDtoUserSearch.userName = this.userDataDtoReturnSession.userId;
    this.masterService.deleteCftExemptionDetails(this.rateMasterDtoUserSearch).subscribe(
      (response) => {
        if (response.status == "Success") {
          swal("Success", "Details deleted successfully!", "info");
          this.getCFTExemptionForCommoditiesDetails();
        } else if (response.status == "Failed") {
          swal("Failed", "Failed to delete the details!", "info");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while deleting the details", "info");
      }, () => console.log('done');
  }

  clickListnerForSource(event) {
    this.enteredSource = event.item;
  }

  clearFields() {
    this.selectedRateId = 0;
    this.rateMasterDtoUserSearch = new RateMasterDto();
    $("#" + this.pageId + "agentName").val("");
    $("#" + this.pageId + "destination").val("");
    $("#" + this.pageId + "commodityListId").val("");
    $("#" + this.pageId + "chargedWt").val("");
    $("#" + this.pageId + "fromDates").val("");
    $("#" + this.pageId + "consignorName").val("");
    this.enteredConsignorId = 0;
    this.enteredSource = null;
    this.enteredDest = null;
    this.enteredCommodity = null;
    this.enteredChgWgt = null;
    this.enteredEffecDate = null;
  }

}
