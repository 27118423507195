<div class="row" *ngIf="isLoggedIn" id="{{pageId}}gstSearch">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center'
                                            style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GST Number</label>
                                                    <input type="text" id="{{pageId}}searchGstNo" class="form-control"
                                                        placeholder="Search by GST Number"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10"
                                            (click)="validateForGstNo();" id="{{pageId}}searchBtn">Search</button>
                                        <button type="submit" class="btn btn-dark" (click)="clearFields();"
                                            id="{{pageId}}clearBtn">Clear</button>
                                    </div>
                                </div>

                                <div class="col-md-9 vl p-t-10">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="row ">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">GST Number</label>
                                                        <div class="input-group">
                                                        </div>
                                                        <h6 style="font-size: 16px;">{{ gstNumber }}</h6>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">Trade Name</label>
                                                        <div class="input-group">
                                                        </div>
                                                        <h6 style="font-size: 16px;">{{ tradeName }}</h6>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">Legal Name</label>
                                                        <div class="input-group">
                                                        </div>
                                                        <h6 style="font-size: 16px;">{{ legalName }}</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row ">
                                                <div class="col-md-4 mt-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">GST Status</label>
                                                        <div class="input-group">
                                                        </div>
                                                        <h6 style="font-size: 16px;">{{ status }}</h6>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mt-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 18px;">Address</label>
                                                        <div class="input-group">
                                                        </div>
                                                        <h6 style="font-size: 16px;">{{ address1 }}</h6>
                                                        <h6 style="font-size: 16px;">{{ address2 }}</h6>
                                                        <h6 style="font-size: 16px;">{{ pincode }}</h6>
                                                        <h6 style="font-size: 16px;" *ngIf="showStateCode">State Code : {{ stateCode
                                                            }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>