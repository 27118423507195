import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { StockService } from 'src/app/dataService/stock-service';
//from the particular global folder ends
//for datatable ends



@Component( {
    selector: 'app-trip-creation-invoice',
    templateUrl: './trip-creation-invoice.component.html',
    styleUrls: ['./trip-creation-invoice.component.css']
} )
export class TripCreationInvoiceComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrInvoiceTable: any;


    invoiceDataList: any;


    onDestroyUnsubscribtionInvoice: Subscription;

    //for datatable ends

    //summaryTable:any;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerInvoice: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsInvoice: any;
    pageId="tcic";
    //for datatable ends

    constructor(/* for datatable starts */private tripCreationInvoiceScreen: StockService, public changeDetectorRef : ChangeDetectorRef /* for datatable endss */ ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsInvoice = {

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                },
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                }

            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            pageLength: 8,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.gettingDataFrmServiceFrInvoiceTable = this.tripCreationInvoiceScreen.getStocksSummaryData()
        this.onDestroyUnsubscribtionInvoice = this.gettingDataFrmServiceFrInvoiceTable.subscribe( data => {
            this.invoiceDataList = data['data'];
            this.dtTriggerInvoice.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerInvoice.unsubscribe();

        this.onDestroyUnsubscribtionInvoice.unsubscribe();
        //for datatable ends
    }


}
