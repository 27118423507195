import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//for datatable starts
import { Subject, Subscription,merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
//for the select option with filter ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal,NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends

import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { map } from 'rxjs/operators';
import { MasterDto } from 'src/app/dto/master-dto';
@Component( {
    selector: 'app-send-sms-group-details',
    templateUrl: './send-sms-group-details.component.html',
    styleUrls: ['./send-sms-group-details.component.css']
} )
export class SendSmsGroupDetailsComponent implements OnInit {


    sendSmsGroupDetailsDataList: any;
    onDestroyUnsubscribtionSendSmsGroupDetails: Subscription;

    selectedUser: any;
    selectedMobileNo: any;
	selectedId:any;
	newGrpName:any;

    addNewGroupName = false;
    modelGrpNameFotGet:any;
    modelGrpName=""
    isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;

    dtTriggerSendSmsGroupDetails: Subject<any> = new Subject();
    dtOptionsSendSmsGroupDetails: any;

    lrDtoSearch: LRDto = new LRDto();
    lrDtoSearchRet: LRDto = new LRDto();
    lrDtoGrpNameAllOption: LRDto = new LRDto();
    
    lrDtoGrpNameOptions: LRDto[];
    grpNameTA: Array<LRDto> = [];
    focusGrpNameTA$ = new Subject<string>();
    searchGrpName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusGrpNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.grpNameTA
                :  this.grpNameTA.filter(v => v.groupName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterGrpName = (x: { groupName: string }) => x.groupName;
	pageId="ssgdc";

    constructor( private masterReadService: MasterReadService, private router: Router, public changeDetectorRef : ChangeDetectorRef) {
    	  if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
          	  this.getGroupList();
            }, 1000);
        }

      }
    ngOnInit(): void {
        this.dtOptionsSendSmsGroupDetails = {
            dom: 'Bfrtip',
            buttons: [
               {
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                exportOptions: {
                columns: ':visible'
                }
                 }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
            	var api = this.api(), data;
                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerSendSmsGroupDetails.unsubscribe();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    ngAfterViewInit(): void {
        this.dtTriggerSendSmsGroupDetails.next();
    }


    //hide and show input fields starts
    groupNameMode(controlGroupName: string ) {
        if ( controlGroupName === 'addNew' ) {
            this.addNewGroupName = true;
        } else {
            this.addNewGroupName = false;
        }
    }
    //hide and show input fields ends

    //to insert value of selected row in table to input field starts
    rowSelected( sendSmsGroupDetailsData ) {
    	this.selectedId = 0;
    	this.selectedUser = sendSmsGroupDetailsData.name;
    	this.selectedMobileNo = sendSmsGroupDetailsData.mobileNum;
    	this.selectedId = sendSmsGroupDetailsData.id;
    	$("#"+this.pageId+"memberName").val(this.selectedUser);
    	$("#"+this.pageId+"mobileNumber").val(this.selectedMobileNo);
    }
    
     getGroupList() {
    	 this.lrDtoSearch = new LRDto();
       	 this.lrDtoSearch.mainstation = this.userDataDtoReturnSession.mainStation;
          this.masterReadService.getSmsGroupHead(this.lrDtoSearch ).subscribe(
              ( response ) => {
                  if (response) {
                      if (response.length > 0) {
                      	  this.lrDtoGrpNameOptions = response;
                          this.grpNameTA = [];
                          this.lrDtoGrpNameAllOption.groupName = "Add New";
                          this.grpNameTA.push(this.lrDtoGrpNameAllOption);
                          for ( let i = 0; i < this.lrDtoGrpNameOptions.length; i++ ) {
                              this.grpNameTA.push( this.lrDtoGrpNameOptions[i]);
                          }
                      }
                  }
				  this.changeDetectorRef.detectChanges();
                }),(error) => {
            			this.showSpinnerForAction = false;
            		   swal("Error","Server Problem Occurred While getting the Group Name Details","info");
            		},
            		   () => console.log('done');
      }
     
     clickListnerForGrpName(e: NgbTypeaheadSelectItemEvent) {
 		this.modelGrpNameFotGet = e.item.groupName;
 		if ( this.modelGrpNameFotGet == 'Add New' ) {
            this.addNewGroupName = true;
        } else {
            this.addNewGroupName = false;
            this.getGrpMemberDetails();
        }
 	}
     
	    getGrpMemberDetails = () => {
	    	this.lrDtoSearch = new LRDto();
	      	this.lrDtoSearch.groupName = this.modelGrpNameFotGet;
			this.showSpinnerForAction = true;
			this.masterReadService.getSmsGroupDetails(this.lrDtoSearch).subscribe(
				(response) => {
					$("#"+this.pageId+"smsGrpDetailsTableId").DataTable().destroy();
					this.sendSmsGroupDetailsDataList = [];
					if (response.length == 0) {
						swal({
							title: "No record",
							text: "No Details found!",
							icon: "info",
							closeOnClickOutside: false,
							closeOnEsc: false,
						});

					} else {
						this.sendSmsGroupDetailsDataList = response;
					}
					setTimeout(() => {
						this.dtTriggerSendSmsGroupDetails.next();
		            }, 500);
					this.showSpinnerForAction = false;
					this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred while getting the details", "info");
				}, () => console.log('done');
		}
	    
	    confirmSaveGrpDetails(){
	    	swal({
	            title: "Confirmation",
	            text: "Sure you want to save/update the changes?",
	            icon: "info",
	            closeOnClickOutside: false,
	            closeOnEsc: false,
	            buttons: ["No", "Yes"],
	        }).then((yesBtn) => {
	            if (yesBtn) {
	            	if ( this.modelGrpNameFotGet === 'Add New' ) {
	            		this.addGrp();
	            	}else{
	            	this.addGrpMemberDetails(this.modelGrpNameFotGet);
	            	}
	            }
	        })
	    }
	    
	    addGrp = () => {
	    	this.lrDtoSearch = new LRDto();
	    	this.newGrpName = $("#"+this.pageId+"newGroupName").val();
	      	this.lrDtoSearch.groupName = this.newGrpName;
	      	this.lrDtoSearch.mainstation = this.userDataDtoReturnSession.mainStation;
	      	
	      	this.lrDtoSearchRet = new LRDto();
			this.showSpinnerForAction = true;
			this.masterReadService.addSmsGroupName(this.lrDtoSearch).subscribe(
				(response) => {
					this.lrDtoSearchRet = response;
					if (this.lrDtoSearchRet.status == "Success") {
						this.addGrpMemberDetails(this.lrDtoSearch.groupName);
					} else if (this.lrDtoSearchRet.status == "Failed") {
						swal("Error", "Failed to add/update group member details!", "error");
					}
					setTimeout(() => {
						this.getGroupList();
		            }, 500);
					this.showSpinnerForAction = false;
					this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred", "info");
				}, () => console.log('done');
		}
	    
	    addGrpMemberDetails = (grpName) => {
	    	this.lrDtoSearch = new LRDto();
	      	this.lrDtoSearch.groupName = grpName;
	      	
	      	this.selectedUser = $("#"+this.pageId+"memberName").val();
	    	this.selectedMobileNo = $("#"+this.pageId+"mobileNumber").val();
	    	
	      	this.lrDtoSearch.name = this.selectedUser;
	      	this.lrDtoSearch.mobileNum = this.selectedMobileNo;
	      	this.lrDtoSearch.id = this.selectedId;
	      	
	      	this.lrDtoSearchRet = new LRDto();
			this.showSpinnerForAction = true;
			this.masterReadService.addSmsGroupDetails(this.lrDtoSearch).subscribe(
				(response) => {
					this.lrDtoSearchRet = response;
					if (this.lrDtoSearchRet.status == "Success") {
						swal("Success", "Group member added/updated successfully!", "info");
						this.clearGrpDetailsFields();
					} else if (this.lrDtoSearchRet.status == "Failed") {
						swal("Error", "Failed to add/update group member details!", "error");
					}
					setTimeout(() => {
						this.getGrpMemberDetails();
		            }, 500);
					this.showSpinnerForAction = false;
					this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred", "info");
				}, () => console.log('done');
		}
	    
	    confirmDeleteGrpDetails(sendSmsGroupDetailsData){
	    	swal({
	            title: "Confirmation",
	            text: "Sure you want to delete the selected details?",
	            icon: "info",
	            closeOnClickOutside: false,
	            closeOnEsc: false,
	            buttons: ["No", "Yes"],
	        }).then((yesBtn) => {
	            if (yesBtn) {
	            	this.deleteGrpMemberDetails(sendSmsGroupDetailsData);
	            }
	        })
	    }
	    
	    deleteGrpMemberDetails = (sendSmsGroupDetailsData) => {
	    	this.lrDtoSearch = new LRDto();
	      	this.lrDtoSearch.id = sendSmsGroupDetailsData.id;
	      	
	      	this.lrDtoSearchRet = new LRDto();
			this.showSpinnerForAction = true;
			this.masterReadService.deleteSmsGroupDetails(this.lrDtoSearch).subscribe(
				(response) => {
					this.lrDtoSearchRet = response;
					if (this.lrDtoSearchRet.status == "Success") {
						swal("Success", "Selected Group member details deleted successfully!", "info");
						this.clearGrpDetailsFields();
					} else if (this.lrDtoSearchRet.status == "Failed") {
						swal("Error", "Failed to delete the group member details!", "error");
					}
					setTimeout(() => {
						this.getGrpMemberDetails();
		            }, 500);
					this.showSpinnerForAction = false;
					this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred", "info");
				}, () => console.log('done');
		}
	    
	    confirmDeleteGrp(){
	    	swal({
	            title: "Confirmation",
	            text: "Sure you want to delete the selected group name with its entire details?",
	            icon: "info",
	            closeOnClickOutside: false,
	            closeOnEsc: false,
	            buttons: ["No", "Yes"],
	        }).then((yesBtn) => {
	            if (yesBtn) {
	            	this.deleteGrp();
	            }
	        })
	    }
	    
	    deleteGrp = () => {
	    	this.lrDtoSearch = new LRDto();
	      	this.lrDtoSearch.groupName = this.modelGrpNameFotGet;
	      	
	      	this.lrDtoSearchRet = new LRDto();
			this.showSpinnerForAction = true;
			this.masterReadService.deleteGroupName(this.lrDtoSearch).subscribe(
				(response) => {
					this.lrDtoSearchRet = response;
					if (this.lrDtoSearchRet.status == "Success") {
						swal("Success", "Selected Group deleted successfully!", "info");
						this.clearAllFields();
					} else if (this.lrDtoSearchRet.status == "Failed") {
						swal("Error", "Failed to delete the group!", "error");
					}
					this.showSpinnerForAction = false;
					this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred", "info");
				}, () => console.log('done');
		}
	    
	   clearGrpDetailsFields(){
		this.lrDtoSearch = new LRDto();
		this.selectedUser = null;
	    this.selectedMobileNo = null;
	    this.selectedId =0;
		$("#"+this.pageId+"memberName").val("");
		$("#"+this.pageId+"mobileNumber").val("");
	   }
	   
	   clearAllFields(){
			this.lrDtoSearch = new LRDto();
			this.selectedUser = null;
		    this.selectedMobileNo = null;
		    this.selectedId =0;
			$("#"+this.pageId+"memberName").val("");
			$("#"+this.pageId+"mobileNumber").val("");
			$("#"+this.pageId+"smsGrpDetailsTableId").DataTable().destroy();
			this.sendSmsGroupDetailsDataList = [];
			setTimeout(() => {
				this.dtTriggerSendSmsGroupDetails.next();
				
            }, 500);
		   }

}
