import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
import { LrService } from 'src/app/dataService/lr-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-lr-issue-details-balance-rpt',
	templateUrl: './lr-issue-details-balance-rpt.component.html',
	styleUrls: ['./lr-issue-details-balance-rpt.component.css']
})
export class LRIssueDetailsBalanceRptComponent implements OnInit {

	gridLRIssueDetailsList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLRIssueDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsLRIssueDetails: any;


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	popUpOpenLRIssueDetailsData: any;

	lrDto: LRDto = new LRDto();
	pageId="lribc";

	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService, private lrService: LrService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<LRIssueDetailsBalanceRptComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenLRIssueDetailsData = JSON.parse(localStorage.getItem('LRIssueBalRptPopUp'));
			localStorage.clear();
			console.log(this.popUpOpenLRIssueDetailsData);
			if (this.popUpOpenLRIssueDetailsData != null &&
				this.popUpOpenLRIssueDetailsData != undefined &&
				this.popUpOpenLRIssueDetailsData != "") {
				this.setLRIssueDetailsBalanceData();
			}

		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsLRIssueDetails = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
			},
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerConsignorMultipleMobileNo.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerLRIssueDetails.next();
	}

	setLRIssueDetailsBalanceData() {
		this.lrDto = new LRDto();
		this.lrDto.agentName = this.popUpOpenLRIssueDetailsData.agentName;
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.lrService.getLrissueAgentDetails(this.lrDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				this.gridLRIssueDetailsList = [];
				$("#"+this.pageId+"lrIssueDetailsBalRptTableId").DataTable().destroy();
				if (response.length == 0) {
					swal("No Data", "No Records  found for this search", "warning");
				} else {
					this.gridLRIssueDetailsList = response;
				}
				this.dtTriggerLRIssueDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the LR Issue Balance Details", "error");
			},
			() => console.log("done");
	}


	onCancelClick(): void {
		this.dialogRef.close("Close");
	}

}

