import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { StatementReportsRoutes } from 'src/app/report/statement-reports/statement-reports.routing';
import { StatementRptComponent } from 'src/app/report/statement-reports/statement-rpt/statement-rpt.component';
import { PartyLessCheckedAndUncheckedLrDetailsComponent } from 'src/app/report/statement-reports/party-less-checked-and-unchecked-lr-details/party-less-checked-and-unchecked-lr-details.component';
import { InvoiceReportComponent } from 'src/app/report/statement-reports/invoice-report/invoice-report.component';
import { StatementRptLrDetailsComponent } from 'src/app/report/statement-reports/statement-rpt-lr-details/statement-rpt-lr-details.component';
import { MaterialModule } from "src/app/material.module";
import { StatementRptLrDetailsPrintComponent } from 'src/app/report/statement-reports/statement-rpt-lr-details-print/statement-rpt-lr-details-print.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(StatementReportsRoutes),
         FormsModule,  NgbModule, DataTablesModule, MaterialModule,CashmemoReportModule],
    exports: [StatementRptLrDetailsPrintComponent],
    declarations: [
        StatementRptComponent,

        PartyLessCheckedAndUncheckedLrDetailsComponent,

        InvoiceReportComponent,

        StatementRptLrDetailsComponent,

        StatementRptLrDetailsPrintComponent]
})
export class StatementReportsModule { }