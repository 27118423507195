<!--<h1 mat-dialog-title>Consignee Rate Master-Setting Rate For All</h1>-->
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<!-- spinner start-->
				<div *ngIf="showSpinnerForAction" class="col-md-12">
					<div class="form-group">
						<div class="input-group">
							<mat-progress-bar mode="indeterminate" style="color: green;">
							</mat-progress-bar>
							<br>
							<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
								Please Wait Loading Details.....</h6>
						</div>
					</div>
				</div>
				<!-- spinner end-->
				<div class="col-md-6">
					<div class="card-body">
						<div class="row">
							<!--<h6 class="card-title">Rate Details-I</h6>-->
							<div class="col-lg-12">
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Update Mode</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
												</div>
												<select class="custom-select col-12" #updateModePopUpId
													id="{{pageId}}updateModePopUpId">
													<option selected value="Increase">Increase</option>
													<option value="Decrease">Decrease</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Destination</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
													</div>
													<input #destinationPopUpId id="{{pageId}}destinationPopUpId" type="text"
														class="form-control"
														(selectItem)="clickListnerForDestinationPopUpList($event)"
														[(ngModel)]="modelDestinationPopUp"
														[ngbTypeahead]="searchDestinationPopUp"
														[resultFormatter]="formatterDestinationPopUp"
														[inputFormatter]="formatterDestinationPopUp"
														(focus)="focusDestinationPopUpTA$.next($any($event).target.value)"
														autocomplete="off" placeholder="Select Destination.." />
												</div>
											</div>
										</div>
									</div>
									<!--<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
													</div>
													<input id="{{pageId}}sourcePopUp" type="text" class="form-control"
														[(ngModel)]="modelSourcePopUp"
														[ngbTypeahead]="searchSourcePopUp"
														(selectItem)="clickListnerForSourcePopUp($event)"
														[resultFormatter]="formatterSourcePopUp"
														[inputFormatter]="formatterSourcePopUp"
														(focus)="focusSourcePopUpTA$.next($any($event).target.value)"
														placeholder="Select Source" />
												</div>
											</div>
										</div>
									</div>-->
									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Commodity</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
																class="fas fa-clipboard-list"></i>
														</span>
													</div>
													<input #CommodityListPopUp id="{{pageId}}commodityListPopUpId" type="text"
														class="form-control"
														(selectItem)="clickListnerForCommodityListPopUp($event)"
														[(ngModel)]="modelCommodityListPopUp"
														[ngbTypeahead]="searchCommodityListPopUp"
														[resultFormatter]="formatterCommodityListPopUp"
														[inputFormatter]="formatterCommodityListPopUp"
														(focus)="focusCommodityListPopUpTA$.next($any($event).target.value)"
														autocomplete="off" placeholder="Select Commodity.." />
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Unit</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
												</div>
												<select class="custom-select col-12" #searchUnitPopUp
													id="{{pageId}}searchUnitPopUp"
													(change)="searchUnitMethodPopUp(searchUnitPopUp.value)">
													<option selected value="rate">Rate</option>
													<option value="percentage">Percentage</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12" *ngIf="viewRateFieldPopUp">
										<div class="form-group">
											<div class="input-group">
												<label>Rates</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
												</div>
												<select class="custom-select col-12" #searchRatePopUp
													id="{{pageId}}searchRatePopUp"
													(change)="searchRateMethodPopUp(searchRatePopUp.value)">
													<option selected value="fixedRate">Fixed Rate</option>
													<option value="DiscRate">Discount Rate</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12" *ngIf="viewRateFieldPopUp">
										<div class="form-group">
											<div class="input-group">
												<label>{{rateChangeTxtId}}</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" class="form-control" id="{{pageId}}ratePercentagePopUpId"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12" *ngIf="viewPercentageFieldPopUp">
										<div class="form-group">
											<div class="input-group">
												<label>Unit Percentage</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
												</div>
												<select class="custom-select col-12" #searchUnitPercPopUp
													id="{{pageId}}searchUnitPercPopUp" name="searchUnitPercPopUp"
													(change)="searchUnitPercMethodPopUp(searchUnitPercPopUp.value)">
													<option selected value="PLR">P tot LR</option>
													<option value="PCM">P tot CM</option>
													<option value="PFRT">P bs FRT</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12" *ngIf="viewPercentageFieldPopUp">
										<div class="form-group">
											<div class="input-group">
												<label>Rate(%)</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" class="form-control" id="{{pageId}}percentageRatePopUpId"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12" *ngIf="viewPerUnitFieldPopUp">
										<div class="form-group">
											<div class="input-group">
												<label>Per Unit</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
												</div>
												<select class="custom-select col-12" id="{{pageId}}perUnitPopUpId">
													<option value="Article" selected>Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Chargable Weight">Chargable Weight</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Fixed By</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
												</div>
												<input type="text" class="form-control" aria-describedby="basic-addon11"
													id="{{pageId}}fixedByPopUpId">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Effect From</label> <input class="form-control"
													id="{{pageId}}effectFromDatePopUpId" placeholder="yyyy-mm-dd"
													name="effectFromDatePopUpId" ngbDatepicker
													#effectFromDatePopUpId="ngbDatepicker">
												<div class="input-group-append"
													(click)="effectFromDatePopUpId.toggle()">
													<span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Fixed On</label> <input id="{{pageId}}fixedOnDatePopUpId"
													class="form-control" placeholder="yyyy-mm-dd"
													name="fixedOnDatePopUpId" ngbDatepicker
													#fixedOnDatePopUpId="ngbDatepicker">
												<div class="input-group-append" (click)="fixedOnDatePopUpId.toggle()">
													<span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--col-md-6-1-end-->
				<div class="col-md-6">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class='row p-t-10'>
									<div class="col-sm-12 col-md-12">
										<!--<h6 class="card-title border_bottom">Select multiple
											Parties to set the Rate</h6>-->
									</div>
								</div>
								<div class="box-body">
									<table datatable id="{{pageId}}selectedPartyNameDestinationTableId"
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsPartyRate" [dtTrigger]="dtTriggerPartyRate">
										<thead>
											<tr>
												<th>Action</th>
												<th>Consignee Name</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let partyRateData of partyRateDataList let i = index">
												<td><input type="checkbox" [checked]="multiSelect" id='selected'
														(change)="rowCheckBoxCheckedForConsignee($event, partyRateData,i)" />
												</td>
												<td>{{ partyRateData.consigneeName }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class='row p-t-10'>
									<div class="col-sm-12 col-md-12">
										<!--<h6 class="card-title border_bottom">Select multiple
											Parties to set the Rate</h6>-->
									</div>
								</div>
								<div class="box-body">
									<table datatable id="{{pageId}}selectedSourceTableId"
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsSource" [dtTrigger]="dtTriggerSource">
										<thead>
											<tr>
												<th>Action</th>
												<th>Source</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let sourceData of sourceDataList let i = index">
												<td><input type="checkbox" [checked]="multiSelect" id='selected'
														(change)="rowCheckBoxForSource($event, sourceData,i)" />
												</td>
												<td>{{ sourceData.subStation }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

				</div>
				<!--col-md-6-end-2-->
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-primary" mat-button (click)="ClearClickBtn();">Clear</button>
	<button class="btn btn-primary" mat-button (click)="SaveClickBtn();">Save</button>
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>