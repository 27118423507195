<html>

<head>
</head>

<body>
    <div class="row" id="{{pageId}}localTriSheetId" *ngIf="isLoggedIn">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                            name="searchBy" #searchBy
                                                            (change)="searchByMode(searchBy.value)">
                                                            <option selected value="all">All</option>
                                                            <option value="vehicleNumber">Vehicle Number</option>
                                                            <option value="area">Area</option>
                                                            <option value="party">Party</option>
                                                            <option value="deliveryman"
                                                                [hidden]="!viewToShowDeliveryManField">Delivery Man
                                                            </option>
                                                            <option value="driverwise"
                                                                [hidden]="!viewToShowDeliveryManField">Driver Wise
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByVehicleNumber" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}vehicleNumber">
                                                        <label>Vehicle Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-truck"></i>
                                                            </span>
                                                        </div>
                                                        <input #vehicleNumber id="{{pageId}}vehicleNumberId" type="text"
                                                            class="form-control"
                                                            (selectItem)="clickListnerForVehicleNumber($event)"
                                                            [(ngModel)]="modelVehicleNumber"
                                                            [ngbTypeahead]="searchVehicleNumber"
                                                            [resultFormatter]="formatterVehicleNumber"
                                                            [inputFormatter]="formatterVehicleNumber"
                                                            (focus)="focusVehicleNumberTA$.next($any($event).target.value)"
                                                            placeholder="Select Vehicle Number... " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewAreaName" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Area</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-map-marker-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input #areaName id="{{pageId}}areaNameId" type="text"
                                                            class="form-control"
                                                            (selectItem)="clickListnerForAreaName($event)"
                                                            [(ngModel)]="modelAreaName" [ngbTypeahead]="searchAreaName"
                                                            [resultFormatter]="formatterAreaName"
                                                            [inputFormatter]="formatterAreaName"
                                                            (focus)="focusAreaNameTA$.next($any($event).target.value)"
                                                            placeholder="Select Area... " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByParty" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Party</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #partyName id="{{pageId}}consigneePartyNameId"
                                                            type="text" class="form-control"
                                                            (selectItem)="clickListnerForConsigneePartyName($event)"
                                                            [(ngModel)]="modelConsigneePartyName"
                                                            [ngbTypeahead]="searchConsigneePartyName"
                                                            [resultFormatter]="formatterConsigneePartyName"
                                                            [inputFormatter]="formatterConsigneePartyName"
                                                            (focus)="focusConsigneePartyNameTA$.next($any($event).target.value)"
                                                            placeholder="Select Party... " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByDeliveryman" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Delivery Man</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #labourHeadNameDropDown
                                                                id="{{pageId}}dropDownInputFieldLabourHeadName"
                                                                type="text" class="form-control"
                                                                [(ngModel)]="modelDropDownLabourHeadName"
                                                                [ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
                                                                [resultFormatter]="formatterDropDownLabourHeadName"
                                                                [inputFormatter]="formatterDropDownLabourHeadName"
                                                                placeholder="Select Labour Head Name"
                                                                (focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchByDriver" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Driver Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #driverNameDropDown
                                                                id="{{pageId}}dropDownInputFieldDriverName" type="text"
                                                                class="form-control"
                                                                (selectItem)="clickListnerForDropDownInputFieldDriverName($event)"
                                                                [(ngModel)]="modelDropDownDriverName"
                                                                [ngbTypeahead]="searchDropDownInputFieldDriverName"
                                                                [resultFormatter]="formatterDropDownDriverName"
                                                                [inputFormatter]="formatterDropDownDriverName"
                                                                placeholder="Select Driver Name"
                                                                (focus)="focusDropDownDriverNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control"
                                                            placeholder="yyyy-mm-dd" id="{{pageId}}fromDates"
                                                            name="fromDates" ngbDatepicker #fromDates="ngbDatepicker"
                                                            [(ngModel)]="fromDatesModal" autocomplete="off">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates"
                                                            class="form-control" placeholder="yyyy-mm-dd" name="toDates"
                                                            ngbDatepicker #toDates="ngbDatepicker"
                                                            [(ngModel)]="toDatesModal" autocomplete="off">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" (click)="clearBtn();"
                                id="{{pageId}}clearBtn">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="dt-button" style="margin-left: 92%;"
                            (click)="customPrintLocalTrpShtRptTable()" id="{{pageId}}printAllBtn"
                            [hidden]="!gcLeftToPartyTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;"
                            (click)="customPrintLocalTrpShtRptByPartyTable()" id="{{pageId}}printAllBtn"
                            [hidden]="!localTripSheetRptByPartyTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <!-- spinner end-->
                        <div class="box-body" [hidden]="!gcLeftToPartyTable">
                            <table datatable id="{{pageId}}localTripSheetRptTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsGcLeftToParty" [dtTrigger]="dtTriggerLocalTripSheetRpt">
                                <thead>
                                    <tr>
                                        <th>Trip Id</th>
                                        <th>Vehicle Name</th>
                                        <th *ngIf="!isDriverEditEnabled">Veh No</th>
                                        <th *ngIf="isDriverEditEnabled">Veh No</th>
                                        <th>Trip Date</th>
                                        <th>Del Area</th>
                                        <th>LRs</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th *ngIf="!isDriverEditEnabled">Driver Name</th>
                                        <th *ngIf="isDriverEditEnabled">Driver Name</th>
                                        <th>Tot Amount</th>
                                        <th>Hamali</th>
                                        <th>Memo GD Hamali</th>
                                        <th>LR DD Amt</th>
                                        <th *ngIf="!isDriverEditEnabled">Delivery Man</th>
                                        <th *ngIf="isDriverEditEnabled">Delivery Man</th>
                                        <th>Office</th>
                                        <th>Hamalis Name</th>
                                        <th>Prepared By</th>
                                        <th *ngIf="isTripDeleteEnabled">Action</th>
                                        <th [hidden]="!consolidateEwaybillEnabled">Eway Bill</th>
                                        <th>DC Ewaybills</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let localTripSheetRptData of localTripSheetRptDataList ">
                                        <td>{{ localTripSheetRptData.id }}</td>
                                        <td>
                                            {{ localTripSheetRptData.transportName }}
                                        </td>
                                        <td *ngIf="!isDriverEditEnabled">
                                            {{ localTripSheetRptData.vehiclenumber }}
                                        </td>
                                        <td *ngIf="isDriverEditEnabled"
                                            style="cursor: pointer; color: blue;text-decoration: underline;">
                                            <a (click)="vehicleUpdatePopUp
											(contentVehicleUpdate,localTripSheetRptData);" class="pointer"> <u>{{
                                                    localTripSheetRptData.vehiclenumber }}</u></a>
                                        </td>

                                        <td>
                                            <a (click)="localTripSheetListner
											(localTripSheetPopUpTemplate,localTripSheetRptData);" class="pointer"> <u>{{
                                                    localTripSheetRptData.tripDateStr }}</u></a>
                                        </td>
                                        <td>{{ localTripSheetRptData.deliveryarea }}</td>
                                        <td>{{ localTripSheetRptData.totallrs }}</td>
                                        <td>{{ localTripSheetRptData.totalarticles }}</td>
                                        <td>{{ localTripSheetRptData.actWeight }}</td>
                                        <td>{{ localTripSheetRptData.chgWeight }}</td>
                                        <td *ngIf="!isDriverEditEnabled">
                                            {{ localTripSheetRptData.drivername }}</td>
                                        <td *ngIf="isDriverEditEnabled"
                                            style="cursor: pointer; color: blue;text-decoration: underline; "
                                            (click)="driverUpdatePopUp(contentDriverUpdate,localTripSheetRptData) ">
                                            {{ localTripSheetRptData.drivername }}</td>
                                        <td>{{ localTripSheetRptData.totalamount }}</td>
                                        <td>{{ localTripSheetRptData.ddAmt }}</td>
                                        <td>{{ localTripSheetRptData.hamaliGD }}</td>
                                        <td>{{ localTripSheetRptData.lrDDAmt }}</td>
                                        <td *ngIf="!isDriverEditEnabled">
                                            {{ localTripSheetRptData.deliveryMan }}</td>
                                        <td *ngIf="isDriverEditEnabled"
                                            style="cursor: pointer; color: blue;text-decoration: underline; "
                                            (click)="deliveryManUpdatePopUp(contentDeliverManUpdate,localTripSheetRptData) ">
                                            {{ localTripSheetRptData.deliveryMan }}</td>
                                        <td>{{ localTripSheetRptData.office }}</td>
                                        <td>
                                            <a (click)="localTripSheetHamaliNameListner (localTripSheetHamalisNamePopUpTemplate,localTripSheetRptData); "
                                                class="pointer "> <u>View</u></a>
                                        </td>
                                        <td>{{ localTripSheetRptData.userName }}</td>
                                        <td *ngIf="isTripDeleteEnabled">
                                            <button type="button" style="padding: 1px 4px; background-color: #ffffff00;"
                                                class="btn m-r-10" id="{{pageId}}deleteTripBtn"
                                                (click)="deletedLocalTrip(localTripSheetRptData);">
                                                <i title="Delete Trip" class="fa fa-trash"></i>
                                            </button>
                                        </td>

                                        <td [hidden]="!consolidateEwaybillEnabled"
                                            *ngIf="localTripSheetRptData.ewayBill == null"
                                            title="Click To Generate Consolidated EWB"
                                            style="color: red; cursor: pointer; text-decoration: underline;"
                                            (click)="confirmGenerateConsolidateEwaybill(localTripSheetRptData.id)">
                                            Generate</td>
                                        <td [hidden]="!consolidateEwaybillEnabled"
                                            *ngIf="localTripSheetRptData.ewayBill != null"
                                            title="Click To Download Consolidated EWB"
                                            style="color: green; cursor: pointer; text-decoration: underline;"
                                            (click)="printCEWB(localTripSheetRptData.ewayBill)">
                                            {{ localTripSheetRptData.ewayBill }}</td>
                                        <td title="Click to Generate/View DC details"
                                            style="color: red; cursor: pointer; text-decoration: underline;"
                                            (click)="dcEwaybillDetailsDialog(contentDCPopup,localTripSheetRptData)">
                                            Generate</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="!isDriverEditEnabled"></td>
                                        <td *ngIf="isDriverEditEnabled"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="!isDriverEditEnabled"></td>
                                        <td *ngIf="isDriverEditEnabled"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="!isDriverEditEnabled"></td>
                                        <td *ngIf="isDriverEditEnabled"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="isTripDeleteEnabled"></td>
                                        <td [hidden]="!consolidateEwaybillEnabled"></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body " [hidden]="! localTripSheetRptByPartyTable ">
                            <table datatable id="{{pageId}}localTripSheetRptByPartyTableId "
                                class="table table-striped table-bordered row-border hover "
                                [dtOptions]="dtOptionLocalTripSheetRptByParty "
                                [dtTrigger]="dtTriggerLocalTripSheetRptByParty ">
                                <thead>
                                    <tr>
                                        <th>Trip Date</th>
                                        <th>Vehicle No</th>
                                        <th>Source</th>
                                        <th>L.R.NO</th>
                                        <th>CNSGR NAME</th>
                                        <th>ART</th>
                                        <th>HAM GD</th>
                                        <th>ACT WT</th>
                                        <th>CHG WT</th>
                                        <th>BKNG DT</th>
                                        <th>UNLDNG DT</th>
                                        <th>ARR</th>
                                        <th>DELV</th>
                                        <th>Driver Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let localTripSheetRptByPartyData of localTripSheetRptByPartyDataList ">
                                        <td>{{ localTripSheetRptByPartyData.tripDateStr }}</td>

                                        <td>
                                            <a (click)="localTripSheetListner (localTripSheetPopUpTemplate,localTripSheetRptByPartyData); "
                                                class="pointer "> <u>{{
                                                    localTripSheetRptByPartyData.vehiclenumber }}</u></a>
                                        </td>
                                        <td>{{ localTripSheetRptByPartyData.source }}</td>
                                        <td>{{ localTripSheetRptByPartyData.lrNumber }}</td>
                                        <td>{{ localTripSheetRptByPartyData.consignorName }}</td>
                                        <td>{{ localTripSheetRptByPartyData.totalarticles }}</td>
                                        <td>{{ localTripSheetRptByPartyData.hamaliGD }}</td>
                                        <td>{{ localTripSheetRptByPartyData.actWeight }}</td>
                                        <td>{{ localTripSheetRptByPartyData.chgWeight }}</td>
                                        <td>{{ localTripSheetRptByPartyData.bookingDateStr }}</td>
                                        <td>{{ localTripSheetRptByPartyData.unloadingDateStr }}</td>
                                        <td>{{ localTripSheetRptByPartyData.arrivalwithin }}</td>
                                        <td>{{ localTripSheetRptByPartyData.deliveredwithin }}</td>
                                        <td>{{ localTripSheetRptByPartyData.drivername }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>

                        <div class="box-body " [hidden]="!driverwiseTable ">
                            <table datatable id="{{pageId}}localTripSheetRptTableDriverwiseId "
                                class="table table-striped table-bordered row-border hover "
                                [dtOptions]="dtOptionsDriverwise" [dtTrigger]="dtTriggerLocalTripSheetRptDriverwise">
                                <thead>
                                    <tr>
                                        <th>Trip Id</th>
                                        <th>Veh Name</th>
                                        <th>Veh No</th>
                                        <th>Trip Date</th>
                                        <th>Del Area</th>
                                        <th>LRs</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Driver Name</th>
                                        <th>Tot Amount</th>
                                        <th>Hamali</th>
                                        <th>Morning Trip</th>
                                        <th>Office</th>
                                        <th>Hamalis Name</th>
                                        <th>Prepared By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let localTripSheetRptData of localTripSheetRptDataList ">
                                        <td>{{ localTripSheetRptData.id }}</td>
                                        <td>{{localTripSheetRptData.transportName }}</td>
                                        <td>
                                            <a (click)="localTripSheetListner (localTripSheetPopUpTemplate,localTripSheetRptData); "
                                                class="pointer "> <u>{{
                                                    localTripSheetRptData.vehiclenumber }}</u></a>
                                        </td>
                                        <td>{{ localTripSheetRptData.tripDateStr }}</td>
                                        <td>{{ localTripSheetRptData.deliveryarea }}</td>
                                        <td>{{ localTripSheetRptData.totallrs }}</td>
                                        <td>{{ localTripSheetRptData.totalarticles }}</td>
                                        <td>{{ localTripSheetRptData.actWeight }}</td>
                                        <td>{{ localTripSheetRptData.chgWeight }}</td>
                                        <td>{{ localTripSheetRptData.drivername }}</td>
                                        <td>{{ localTripSheetRptData.totalamount }}</td>
                                        <td>{{ localTripSheetRptData.ddAmt }}</td>
                                        <td *ngIf="localTripSheetRptData.isMrngTrip == true"
                                            style="color: darkgreen; font-weight: bold; font-size: 14px;">
                                            Yes
                                        </td>
                                        <td *ngIf="localTripSheetRptData.isMrngTrip != true">
                                            No
                                        </td>
                                        <td>{{ localTripSheetRptData.office }}</td>
                                        <td>
                                            <a (click)="localTripSheetHamaliNameListner (localTripSheetHamalisNamePopUpTemplate,localTripSheetRptData); "
                                                class="pointer "> <u>View</u></a>
                                        </td>
                                        <td>{{ localTripSheetRptData.userName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <!--POP UP START HERE-->
    <div class="col-md-12 ">
        <div class="form-group ">
            <div class="input-group ">
                <ng-template #localTripSheetPopUpTemplate let-ok="close " let-d="dismiss ">
                    <!--<div class="modal-header ">
						<button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
							<span aria-hidden="true ">&times;</span>
						</button>
					</div>-->
                    <div class="modal-body ">
                        <div class="row ">
                            <div class="col-md-12 ">
                                <div class="card-body ">
                                    <div class="row ">
                                        <div class="col-sm-12 col-md-12 ">
                                            <button style="margin-left: 975px; " type="button " class="btn btn-dark "
                                                id="{{pageId}}printBtnId "
                                                (click)="printLocalTripSheet() ">Print</button>
                                            <button type="button " class="btn btn-dark " id="{{pageId}}cancelBtnId "
                                                (click)="closePopUpBtn() ">Close</button>
                                        </div>
                                        <div class="col-sm-12 col-md-12 ">
                                            <div *ngIf="viewLocalTripSheetLrDetailsPopUp " onafterPopUp="afterPopUp() "
                                                id="{{pageId}}popupLocalTripSheet ">
                                                <app-local-trip-sheet-lr-details></app-local-trip-sheet-lr-details>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 									<br> -->
                                    <!-- 									<div class="row "> -->
                                    <!-- 										<div class="col-sm-12 col-md-4 "></div> -->
                                    <!-- 										<div class="col-sm-12 col-md-4 "> -->
                                    <!-- 											<button style="float: center; " type="button " class="btn btn-dark " -->
                                    <!-- 												(click)="closePopUpBtn(); " id="{{pageId}}cancelBtnId ">Cancel -->
                                    <!-- 											</button> -->
                                    <!-- 										</div> -->
                                    <!-- 										<div class="col-sm-12 col-md-4 "></div> -->
                                    <!-- 									</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-md-12 ">
        <div class="form-group ">
            <div class="input-group ">
                <ng-template #localTripSheetHamalisNamePopUpTemplate let-ok="close " let-d="dismiss ">
                    <div class="modal-header ">
                        <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                            <span aria-hidden="true ">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body ">
                        <div class="row ">
                            <div class="col-md-12 ">
                                <div class="card-body ">
                                    <div class="row ">
                                        <div class="col-sm-12 col-md-12 ">
                                            <mat-card *ngFor="let hamali of labourHamaliNamesList ">
                                                <mat-card-content>
                                                    <p>{{hamali.hamaliNames}}</p>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer ">
                        <button type="button " class="btn btn-default " (click)="onCloseHandled() ">Close</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- driver update popup -->
    <ng-template #contentDriverUpdate let-c="close " let-d="dismiss ">
        <div class="modal-header ">
            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Change Driver Name for Local Trip<b>
                    {{setTripNo}}</b> </h6>

            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                <span aria-hidden="true ">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row ">
                <div class="col-md-12 ">
                    <div class="col-sm-12 col-md-12 ">
                        <div class="control ">
                            <div class="form-group ">
                                <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                    <label>Updated Driver Name</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="ti-home "></i>
                                        </span>
                                    </div>
                                    <input #driverNameUpdateDropDown id="{{pageId}}dropDownInputFieldDriverNameUpdate"
                                        type="text" class="form-control"
                                        (selectItem)="clickListnerForDropDownInputFieldDriverNameUpdate($event)"
                                        [(ngModel)]="modelDropDownDriverNameUpdate"
                                        [ngbTypeahead]="searchDropDownInputFieldDriverNameUpdate"
                                        [resultFormatter]="formatterDropDownDriverNameUpdate"
                                        [inputFormatter]="formatterDropDownDriverNameUpdate"
                                        placeholder="Select Driver Name"
                                        (focus)="focusDropDownDriverNameUpdateTA$.next($any($event).target.value) " />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <button type="submit " class="btn btn-dark " (click)="updateDriverNameBtn() ">Update</button>
                        <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark "
                            id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- vehicle update popup -->
    <ng-template #contentVehicleUpdate let-c="close " let-d="dismiss ">
        <div class="modal-header ">
            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Change Vehicle Number for Local Trip<b>
                    {{setTripNo}}</b> </h6>

            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                <span aria-hidden="true ">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row ">
                <div class="col-md-12 ">
                    <div class="col-sm-12 col-md-12 ">
                        <div class="control ">
                            <div class="form-group ">
                                <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                    <label>Updated Vehicle Number</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="ti-home "></i>
                                        </span>
                                    </div>
                                    <input #vehicleNumberUpdate id="{{pageId}}vehicleNumberUpdateId" type="text"
                                        class="form-control" (selectItem)="clickListnerForVehicleNumberUpdate($event)"
                                        [(ngModel)]="modelVehicleNumberUpdate"
                                        [ngbTypeahead]="searchVehicleNumberUpdate"
                                        [resultFormatter]="formatterVehicleNumberUpdate"
                                        [inputFormatter]="formatterVehicleNumberUpdate"
                                        (focus)="focusVehicleNumberUpdateTA$.next($any($event).target.value)"
                                        placeholder="Select Vehicle Number" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <button type="submit " class="btn btn-dark " (click)="updateVehicleBtn() ">Update</button>
                        <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark "
                            id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- delivery man update popup -->
    <ng-template #contentDeliverManUpdate let-c="close " let-d="dismiss ">
        <div class="modal-header ">
            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Change Delivery Man for Local Trip<b>
                    {{setTripNo}}</b> </h6>
            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                <span aria-hidden="true ">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row ">
                <div class="col-md-12 ">
                    <div class="col-sm-12 col-md-12 ">
                        <div class="control ">
                            <div class="form-group ">
                                <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                    <label>Updated Delivery Man</label>
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text "> <i class="ti-home "></i>
                                        </span>
                                    </div>
                                    <input #labourHeadNameUpdateDropDown
                                        id="{{pageId}}dropDownInputFieldLabourHeadNameUpdate" type="text"
                                        class="form-control" [(ngModel)]="modelDropDownLabourHeadNameUpdate"
                                        [ngbTypeahead]="searchDropDownInputFieldLabourHeadNameUpdate"
                                        [resultFormatter]="formatterDropDownLabourHeadNameUpdate"
                                        [inputFormatter]="formatterDropDownLabourHeadNameUpdate"
                                        placeholder="Select Delivery Man"
                                        (focus)="focusDropDownLabourHeadNameTAUpdate$.next($any($event).target.value)" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <button type="submit " class="btn btn-dark " (click)="updateDeliveryManBtn() ">Update</button>
                        <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark "
                            id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- dc popup -->
    <ng-template #contentDCPopup let-c="close " let-d="dismiss ">
        <div class="modal-header ">
            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">DC Generated Ewaybills<b>
                    {{setTripNo}}</b> </h6>
            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                <span aria-hidden="true ">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row ">
                <div class="col-md-12 ">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Vehicle Number</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}vehicleNumberForDC"
                                    #vehicleNumberForDC aria-describedby="basic-addon11">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <div class="control ">
                            <div class="row">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}localTripExpiredEwayBillTableId"
                                        class="table table-striped table-bordered row-border hover"
                                        [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">
                                        <thead>
                                            <tr>
                                                <th>LR Number</th>
                                                <th>Eway Bill Number</th>
                                                <th>Validity Date</th>
                                                <th>DC Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let dcData of dcDataList ">
                                                <td>{{ dcData.lrNumber }}</td>
                                                <td>{{ dcData.eWayBillNo }}</td>
                                                <td>{{ dcData.ewayBillValidUptoStr }}</td>
                                                <td *ngIf="dcData.ewayBillDCNo == null"
                                                    title="Click To Generate Delivery Challan"
                                                    style="color: red; cursor: pointer; text-decoration: underline;"
                                                    (click)="validateDCGeneration(dcData)">
                                                    Generate DC</td>
                                                <td *ngIf="dcData.ewayBillDCNo != null"
                                                    title="Click To Download Delivery Challan"
                                                    style="color: green; cursor: pointer; text-decoration: underline;"
                                                    (click)="printDCEwaybill(dcData.ewayBillDCNo)">
                                                    {{ dcData.ewayBillDCNo }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 ">
                        <button type="submit " class="btn btn-dark " (click)="updateDeliveryManBtn() ">Update</button>
                        <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark "
                            id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="viewCustomPrintV1 " onafterprint="afterPrint() " id="{{pageId}}viewCustomPrintV1 ">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>