
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { LrService } from 'src/app/dataService/lr-service';
@Component({
    selector: 'app-commodity-report-details-print',
    templateUrl: './commodity-report-details-print.component.html',
    styleUrls: ['./commodity-report-details-print.component.css']
})
export class CommodityReportDetailsPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    generalInformation: any;
    lrDto: LRDto[] = [];
    lrDtoForGetData: LRDto = new LRDto();
    address: any;

    serTax: any;
    totLrsNet: any = 0;
    totArtNet: any = 0;
    totToPayNet: any = 0;
    totPaidNet: any = 0;
    totSerTaxNet: any = 0;
    totActWgtNet: any = 0;
    totChgWgtNet: any = 0;
    totGoodsValueNet: any = 0;


    lrDtoDataList: any;
    result: any;

    fromDate: any;
    toDate: any;
    source: any;
    destination: any;
    pageId="cmrdc";

    constructor(private router: Router, private datePipe: DatePipe, private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            //this.lrDto = JSON.parse(localStorage.getItem('printCommodityDetailsPrintData'));
            this.lrDtoForGetData = JSON.parse(localStorage.getItem('printCommodityDetailsPrintData'));
            console.log(this.lrDto);

            if (this.lrDtoForGetData != null && this.lrDtoForGetData != undefined) {
                this.fromDate = this.lrDtoForGetData.fromDate;
                this.toDate= this.lrDtoForGetData.toDate;
                this.source= this.lrDtoForGetData.source;
                this.destination= this.lrDtoForGetData.destination;
                this.searchCommodityDetailRpt();
                /* for (let i = 0; i < this.lrDto.length; i++) {
                    
                     this.serTax = this.lrDto[i].serviceTax == null ? 0 : this.lrDto[i].serviceTax;
                     this.totLrsNet = +this.totLrsNet + +1;
                     this.totArtNet = +this.totArtNet + + this.lrDto[i].totalArticles;
                     this.totToPayNet = +this.totToPayNet + + this.lrDto[i].toPay;
                     this.totPaidNet = +this.totPaidNet + + this.lrDto[i].paid;
                     this.totSerTaxNet = +this.totSerTaxNet + + this.serTax;
                     this.totActWgtNet = +this.totActWgtNet + + this.lrDto[i].actualWeight;
                     this.totChgWgtNet = +this.totChgWgtNet + + this.lrDto[i].chargedWeight;
                     this.totGoodsValueNet = +this.totGoodsValueNet + + this.lrDto[i].goodsValue;
                 }*/
            }

            /* this.lrDtoDataList = [];
             if (this.lrDto != null && this.lrDto != undefined && this.lrDto.length > 0) {
                 if (this.lrDto.length > 0) {
                     this.lrDtoDataList = this.lrDto;
                     console.log(this.lrDtoDataList);
                     this.searchCommodityDetailRpt();
                 }
             }*/

            /* window.setTimeout(function () {
                 window.print();
             }, 0);
 
             window.onafterprint = function () {
                 window.close();
             }
             localStorage.clear();
             window.addEventListener('afterprint', (event) => {
                 this.clearField();
             });*/
        }
    }

    ngOnInit() {

    }

    searchCommodityDetailRpt() {
        this.lrService.getCommodityItemWiseDetailsReport(this.lrDtoForGetData).subscribe(
            (response) => {
                console.log(response);
                this.lrDtoDataList = [];
                if (response.length > 0) {
                    this.setCommodityDetailsPrintData(response);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting Commodity ItemWise Details", "info");
            },
            () => console.log('done');
    }

    setCommodityDetailsPrintData(responseReturnData) {
        this.lrDtoDataList = [];
        this.lrDtoDataList = responseReturnData;
        var groups = new Set(this.lrDtoDataList.map(item => item.mainCommodity));
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totalLrs = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.totalLrs, 0)
            const totArticle = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.totalArticles, 0)
            const actWgt = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const chgWgt = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.chargedWeight, 0)
            const toPay = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.toPay, 0)
            const paid = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.paid, 0)
            const riskCharge = this.lrDtoDataList
                .filter(i => i.mainCommodity === g)
                .reduce((sum, i) => +sum + +i.riskCharge, 0)

            this.result.push({
                mainCommodity: g,
                values: this.lrDtoDataList.filter(i => i.mainCommodity === g),
                totalLrs: totalLrs,
                totalArticles: totArticle,
                actualWeight: actWgt,
                chargedWeight: chgWgt,
                toPay: toPay,
                paid: paid,
                riskCharge: riskCharge
            })
        })

        window.setTimeout(function () {
            window.print();
        }, 0);

        window.onafterprint = function () {
            window.close();
        }
        localStorage.clear();
        window.addEventListener('afterprint', (event) => {
            this.clearField();
        });
    }


    validatBeforePrint() {

    }

    clearField() {
        this.lrDto = [];
        this.lrDtoDataList = [];
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
