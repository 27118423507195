import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";
import { HireSlipDto } from '../dto/HireSlip-dto';
import { InvoiceDto } from '../dto/Invoice-dto';
import { ResponseDto } from '../dto/Response-dto';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private _baseUrl = serverUrl.webDashboard;
    //private _url = "./assets/data/supplier-master.json";

    constructor(
        private http: HttpClient,
    ) {
    }

    getSupplierMasterData(): Observable<SupplierModel[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<SupplierModel[]>('./assets/data/supplier-master.json');


    }

    getSummaryData(): Observable<any[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');

    }

    getCashMamoBatch(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCashMamoBatchApi`, cashMemoDto);
    }

    getRemMemosLrDetailsInCollectionDept(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getRemMemosLrDetailsInCollectionDeptApi`, lrDto);
    }

    getCashMamoBatchTrnsToGdw(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCashMamoBatchTrnsToGdwApi`, cashMemoDto);
    }


    getCollectionDetailsLeftToPartyPendAckStmtDashboard(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsLeftToPartyPendAckStmtDashboardApi`, cashMemoDto);
    }

    getTPStmtDetails(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTPStmtDetailsApi`, cashMemoDto);
    }

    getBlockLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBlockLrDetailsApi`, lrDto);
    }

    getCODLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCODLrDetailsApi`, lrDto);
    }

    updateCODPaymentDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateCODPaymentDetailsApi`, lrDto);
    }

    getPendingHireSlipFromOtherStations(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getPendingHireSlipFromOtherStationsApi`, hireSlipDto);
    }
    getLRsShortage(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRsShortageApi`, lrDto);
    }

    deleteLRShortage(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteLRShortageApi`, lrDto);
    }

    getAgentStockSummaryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentStockSummaryDetailsApi`, lrDto);
    }

    getAgentStockSummaryDetailsV2(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentStockSummaryDetailsV2Api`, lrDto);
    }

    getAgentStockReportDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentStockReportDetailsApi`, lrDto);
    }

    getAgentStockReportDetailsGrpMainSrc(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentStockReportDetailsGrpMainSrcApi`, lrDto);
    }

    markLrUrgentDelivery(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/markLrUrgentDeliveryApi`, lrDto);
    }

    getPendingHamaliMemos(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getPendingHamaliMemosApi`, cashMemoDto);
    }

    updateCashmemoDetailsForHamali(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateCashmemoDetailsForHamaliApi`, cashMemoDto);
    }

    getPendingLocalTrips(cashMemoDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPendingLocalTripsApi`, cashMemoDto);
    }
    //Local Trip Status Update
    updateLRStatusDeliveryAsDelivered(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLRStatusDeliveryAsDeliveredApi`, lrDto);
    }

    updateMultipleLRStatusDelivered(lrDtos: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateMultipleLRStatusDeliveredApi`, lrDtos);
    }

    updateLRStatusDeliveryAsUnDelivered(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLRStatusDeliveryAsUnDeliveredApi`, lrDto);
    }
    updateLRStatusDeliveryAsTripCancelled(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLRStatusDeliveryAsTripCancelledApi`, lrDto);
    }

    updateLocalTrips(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLocalTripsApi`, lrDto);
    }

    getPendingCommodity(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPendingCommodityApi`, lrDto);
    }

    getScheduledTripsDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getScheduledTripsDetailsApi`, hireSlipDto);
    }

    getPendingHireSlipGeneration(invoiceDto: Object): Observable<InvoiceDto[]> {
        return this.http.post<InvoiceDto[]>(`${this._baseUrl}/getPendingHireSlipGenerationApi`, invoiceDto);
    }

    getScheduledLocalTrips(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getScheduledLocalTrips`, hireSlipDto);
    }

    refreshFTConsentStatus(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/refreshFTConsentStatusApi`, hireSlipDto);
    }

    getBookingAgentStatementDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getBookingAgentStatementDetailsApi`, rateMasterDto);
    }
    getAgentStockDestinationSummaryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentStockDestinationSummaryDetailsApi`, lrDto);
    }
    getFtConsentStatusAllowed(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getFtConsentStatusAllowedApi`, requestDto);
    }
}
