import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
//import { DatatableComponent } from '@swimlane/ngx-datatable';
//(DatatableComponent) table: DatatableComponent;
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends


import { TranshipmentRoutes } from 'src/app/master/transhipment/transhipment.routing';
import { DriverMasterComponent } from 'src/app/master/transhipment/driver-master/driver-master.component';
import { VehicleSetupMasterComponent } from 'src/app/master/transhipment/vehicle-setup-master/vehicle-setup-master.component';
import { TruckMasterComponent } from 'src/app/master/transhipment/truck-master/truck-master.component';
import { LorryHireDeductionSetupComponent } from 'src/app/master/transhipment/lorry-hire-deduction-setup/lorry-hire-deduction-setup.component';
import { NatureOfPackComponent } from 'src/app/master/transhipment/nature-of-pack/nature-of-pack.component';
import { ExpensesPaymentFollowupReportComponent } from 'src/app/master/transhipment/expenses-payment-followup-report/expenses-payment-followup-report.component';
import { TrackingLiveReportComponent } from 'src/app/master/transhipment/tracking-live-report/tracking-live-report.component';
import { PointToPointServiceCheckComponent } from 'src/app/master/transhipment/point-to-point-service-check/point-to-point-service-check.component';
import { GetHireslipBarcodeValueComponent } from 'src/app/master/transhipment/get-hireslip-barcode-value/get-hireslip-barcode-value.component';
import { AgentTempoMasterComponent } from 'src/app/master/transhipment/agent-tempo-master/agent-tempo-master.component';
import { DatePipe } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { VehicleAreaChargeSetupComponent } from './vehicle-area-charge-setup/vehicle-area-charge-setup.component';
import { GetAdvanceConsentComponent } from './get-advance-consent/get-advance-consent.component';
@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(TranshipmentRoutes), 
        FormsModule,

        NgbModule, DataTablesModule, MatFormFieldModule,
        MatInputModule, MatAutocompleteModule,
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, CashmemoReportModule],
    declarations: [
        DriverMasterComponent,
        VehicleSetupMasterComponent,
        TruckMasterComponent,
        LorryHireDeductionSetupComponent,
        NatureOfPackComponent,
        ExpensesPaymentFollowupReportComponent,
        TrackingLiveReportComponent,
        PointToPointServiceCheckComponent,
        GetHireslipBarcodeValueComponent,
        AgentTempoMasterComponent,
        VehicleAreaChargeSetupComponent,
        GetAdvanceConsentComponent],
    providers: [
        DatePipe
    ],
    exports: [TruckMasterComponent],
})
export class TranshipmentModule { }
