<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Lr Details For {{getInvoiceNumber}}</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div class="box-body">
						<table datatable id="{{pageId}}lrDetailsId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

							<thead>
								<tr>
									<th>LR Number</th>
									<th>Articles</th>
									<th>Booking Date</th>
									<th>Description</th>
									<th>To Pay</th>
									<th>Paid</th>
									<th>Actual Weight</th>
									<th>Charged Weight</th>
									<th>Goods Value</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let lrDetailsData of lrDetailsDataList ">
									<td>{{ lrDetailsData.lrNumber }}</td>
									<td>{{ lrDetailsData.totalArticles }}</td>
									<td>{{ lrDetailsData.bookingDateStr }}</td>
									<td>{{ lrDetailsData.description }}</td>
									<td>{{ lrDetailsData.toPay }}</td>
									<td>{{ lrDetailsData.paid }}</td>
									<td>{{ lrDetailsData.actualWeight }}</td>
									<td>{{ lrDetailsData.chargedWeight }}</td>
									<td>{{ lrDetailsData.goodsValue }}</td>
								</tr>
							</tbody>
							<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										
									</tr>
								</tfoot>
						</table>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
<!-- 	<button class="btn btn-outline-danger" mat-button -->
<!-- 		(click)="onCancelClick()">Cancel</button> -->
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
