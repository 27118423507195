<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showOfficeRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Office Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <!-- spinner start-->
                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <!-- spinner end-->
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Date</label> <input class="form-control" id="{{pageId}}dates" placeholder="yyyy-mm-dd" name="dates" ngbDatepicker #dates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="dates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="clickListnerForSource($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="validateClearBtn();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-5 vl p-t-10">
                        <!--<button type="submit" class="dt-button" style="margin-left: 92%;"
							(click)="customPrintOfficeRptBtn();" id="{{pageId}}printAllBtn">
							<span><i class="fas fa-print">Print</i></span>
						</button>-->
                        <div class="box-body">
                            <table datatable id="{{pageId}}officeRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckWise" [dtTrigger]="dtTriggerTruckWise">

                                <thead>
                                    <tr>
                                        <th>To Station</th>
                                        <th>Truck Number</th>
                                        <th>Supplier</th>
                                        <th>Gur Wt</th>
                                        <th>Kanta Wt</th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                        <th>Freight</th>
                                        <th [hidden]="!showHireColumns">Hire</th>
                                        <th>Remarks</th>
                                        <th>Loaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let truckWiseData of truckWiseDataList ">
                                        <td>{{ truckWiseData.toStation }}</td>
                                        <td>{{ truckWiseData.vehicleNumber }}</td>
                                        <td>{{ truckWiseData.suppliername }}</td>
                                        <td>{{ truckWiseData.gurWeight }}</td>
                                        <td>{{ truckWiseData.kantaWeight }}</td>
                                        <td>{{ truckWiseData.actWeight }}</td>
                                        <td>{{ truckWiseData.chargedWt }}</td>
                                        <td>{{ truckWiseData.totalAmt }}</td>
                                        <td [hidden]="!showHireColumns">{{ truckWiseData.totalhire }}</td>
                                        <td>{{ truckWiseData.remarks }}</td>
                                        <td>{{ truckWiseData.loadedby }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-4 vl p-t-10">
                        <div class="box-body">
                            <div [hidden]="destWiseTable">
                                <table datatable id="{{pageId}}destOfOfficeRptTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDestWise" [dtTrigger]="dtTriggerDestWise">
                                    <thead>
                                        <tr>
                                            <th>Truck No</th>
                                            <th>Act Wt</th>
                                            <th>Chg Wt</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let destWiseData of destWiseDataList ">
                                            <td>{{ destWiseData.vehicleNumber }}</td>
                                            <td>{{ destWiseData.actWeight }}</td>
                                            <td>{{ destWiseData.chargedWt }}</td>
                                            <td>{{ destWiseData.toPay }}</td>
                                            <td>{{ destWiseData.paid }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>