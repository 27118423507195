//CHG-V-LT:01 - @Asrar chg, block local trip creation if all lrs of the the previous local trip of the same area not delivered. @Sadakath : Date"18/09/2023
//CHG-V-LT:02 - @Asrar Jr. - Logic for early morning trip flow @15/06/2024

import { FormGroup, } from '@angular/forms';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { LRDto } from 'src/app/dto/LR-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-stocks-for-tripsheet',
    templateUrl: './stocks-for-tripsheet.component.html',
    styleUrls: ['./stocks-for-tripsheet.component.css']
})
export class StocksForTripsheetComponent implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;

    linear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    showSpinnerForTripScheduleAction = false;
    showSpinnerForTripDetailsAction = false;
    pageId = "sftc";
    dataTable: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    onDestroyUnsubscribtionStockSummary: Subscription;
    onDestroyUnsubscribtionStockDetails: Subscription;

    dtOptionsStockSummary: any;
    dtOptionsStockDetails: any;

    dtTriggerStockSummary: Subject<any> = new Subject();
    dtTriggerStockDetails: Subject<any> = new Subject();

    stockSummaryDataList: any;
    stockDetailsDataList: any;
    //Stock Summary & Details Table end

    //Stock eligible & remaining lr Table start

    onDestroyUnsubscribtionEligibleLrs: Subscription;
    onDestroyUnsubscribtionRemainingLrs: Subscription;

    dtTriggerEligibleLrs: Subject<any> = new Subject();
    dtTriggerRemainingLrs: Subject<any> = new Subject();

    dtOptionsEligibleLrs: any;
    dtOptionRemainingLrs: any;

    eligibleLrsDataList: any;
    remainingLrsDataList: any;

    //Stock eligible & remaining lr Table end

    //for datatable end

    rightsToShowDeliveryMan = "StockForTripsheet ShowDelvryMan";
    showDeliveryMan = false;
    viewBinsStockMsg: any = null;
    viewLocalTripsheetMsg: any = null;

    lrDtoArticleSummary: LRDto = new LRDto();
    lrDtoStocksDetails: LRDto = new LRDto();
    //lrDtoPassLocalStorage: LRDto = new LRDto();
    lrDtoPassTripSchedulePage: LRDto = new LRDto();

    makeAtripBtnHideShow = false;
    tripSheetBlackBtnHideShow = false;
    tripSheetBtnHideShow = false;

    makeATripBtnId: string;
    tripSheetBtnId: string;

    //1 st stock for trip sheet level - end

    // 2 nd stock for trip sheet level - start 

    // Drop Down Field Start
    lrDtoBranchBinsAll: LRDto = new LRDto();
    branchBinsOption: Array<LRDto> = [];
    lrDtoBranchBins: LRDto = new LRDto();
    public modelDropDownBins: any;
    branchBinsTA: Array<LRDto> = [];
    focusDropDownBinsTA$ = new Subject<string>();
    searchDropDownInputFieldBins = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownBinsTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.branchBinsTA
                : this.branchBinsTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownBins = (x: { godownName: string }) => x.godownName;

    // Drop Down Field End

    //stockTripSheetReturnLocalStorage: LRDto = new LRDto();
    lrDtoForScheduleTripsheetReturn: LRDto = new LRDto();
    lrDtoControlBinService: LRDto = new LRDto();
    //lrDtoCreateATripDetailLocalStorage: LRDto = new LRDto();
    lrDtoCreateATripDetail: LRDto = new LRDto();
    //Dashboard
    lrDtoFromDashboardScheduleLocalTrip: LRDto = new LRDto();
    lrDtoForScduleLrDashboardLocalTrip: LRDto = new LRDto();


    enteredStockBinsDestination: any = null;
    enteredLrNumber: any = null;
    selectedControBinsDataOfAllLrsList: any = [];
    resultList: any = null;

    viewNextDarkBtnForScheduleTrip = false;
    viewNextGreenBtnForScheduleTrip = false;

    /* 2 nd stock for trip sheet level - end*/

    /* 3 rd stock for trip sheet level - start */

    truckDataDtoNewVehicleNumberSave: TruckDataDto = new TruckDataDto();
    truckDataDtoNewVehicleNumberSaveReturn: any = null;

    vehicleNumberOption: Array<TruckDataDto> = [];
    truckDataDtoVehicleNumber: TruckDataDto = new TruckDataDto();
    truckDataDtoAddNewVehicleNumber: TruckDataDto = new TruckDataDto();
    vehicleNumberTA: Array<any> = [];
    focusDropDownVehicleNumberTA$ = new Subject<string>();
    searchDropDownInputFieldVehicleNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownVehicleNumberTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberTA
                : this.vehicleNumberTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //formatterDropDownVehicleNumber = (x: { truckNumber: string }) => x.truckNumber;

    vehicleNumberFilterTA: Array<any> = [];
    focusDropDownVehicleNumberFilterTA$ = new Subject<string>();
    searchDropDownInputFieldVehicleNumberFilter = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownVehicleNumberFilterTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberFilterTA
                : this.vehicleNumberFilterTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //formatterDropDownVehicleNumberFilter = (x: { truckNumber: string }) => x.truckNumber;

    driverNameOption: Array<DriverdetailsDto> = [];
    driverDataDtoDriverName: DriverdetailsDto = new DriverdetailsDto();
    public modelDropDownDriverName: any;
    driverNameTA: Array<DriverdetailsDto> = [];
    focusDropDownDriverNameTA$ = new Subject<string>();
    searchDropDownInputFieldDriverName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDriverNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameTA
                : this.driverNameTA.filter(v => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDriverName = (x: { driverName: string }) => x.driverName;

    labourNameOption: Array<LRDto> = [];
    lrDtoLabourName: LRDto = new LRDto();
    public modelDropDownLabourName: any;
    labourNameTA: Array<LRDto> = [];
    focusDropDownLabourNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourNameTA
                : this.labourNameTA.filter(v => v.loaderName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourName = (x: { loaderName: string }) => x.loaderName;

    labourHeadNameOption: Array<HireSlipDto> = [];
    hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

    modalRefferenceVehicleNumberPopUp: NgbModalRef;
    modalRefferenceVehicleNumberClosePopUp: string;
    @ViewChild('vehicleNumberPopUpTemplate') private vehicleNumberPopUpTemplate;

    enteredPopUpNewVehicleNumber: any = null;
    eneterdPopUpNewVehicleName: any = null;

    enteredlabourNameListOfHamaliAdd: any = null;
    enteredlabourHeadNameListOfHamaliAdd: any = null;

    viewToShowDeliveryManField = false;
    viewScheduleTripBtn = false;

    lrDtoSetTripDetails: LRDto = new LRDto();
    lrDtoSetTripDetailsReturn: LRDto = new LRDto();
    lrdtoNewTripCreateAndPrint: LRDto = new LRDto();
    lrdtoUpdateTripCreateAndPrint: LRDto = new LRDto();
    lrdtoForAddNewScheduleTrip: LRDto = new LRDto();
    lrdtoForUpdateScheduleTrip: LRDto = new LRDto();
    listOfLrs: string[];

    validateTotArticles: number;
    validateTotAmt: number;
    validateActWgt: number;
    validateChgWgt: number;
    validateDdAmt: number;
    validateTripsheetHamaliAmt: number;

    enteredVehicleNumber: any;
    enteredDriverName: any;
    enteredDriverMobileNo: any;
    enteredLabourHamaliName: any;
    enteredLoaderHeadName: any;
    enteredTransportName: any;

    hashmapVehicle: Map<String, String> = new Map<String, String>();
    viewStockTripsheetPrintDest = false;
    requestDto: RequestDto = new RequestDto();
    consolidateEwaybillEnabled: boolean = false;
    selectedVehicleForFilter: any = null;
    destLocalTripBlockForPrevTripNotCleared: boolean = false;
    listOfExpiredEwaybills: Array<String> = [];
    checkBoxForIsMrngTrip: any;

    constructor(private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService,
        private hireslipService: HireslipService, private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef,
        private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            console.log('this.userDataDtoReturnSession');
            console.log(this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip);
            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip) {
                this.consolidateEwaybillEnabled = true;
            }

            if (this.userDataDtoReturnSession.destLocalTripBlock != null && this.userDataDtoReturnSession.destLocalTripBlock) {
                this.destLocalTripBlockForPrevTripNotCleared = true;
            }
            /*1 st stock for trip sheet level - start*/
            this.viewBinsStockMsg = "Bins Stock";
            this.viewLocalTripsheetMsg = "";
            /*if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "StockForTripsheet ShowDelvryMan") {
                        this.showDeliveryMan = true;
                    }
                }
            }*/

            this.setTripCrationForArticlesStocksSummaryDetailsList();

            /*1 st stock for trip sheet level - end*/

            /*2 nd stock for trip sheet level - start*/

            this.setBranchAreaDetailsList();

            /*2 nd stock for trip sheet level - end*/

            /*3 rd stock for trip sheet level - start*/
            /* 
            if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
             */
            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.viewToShowDeliveryManField = true;
                this.setLabourHeadNameDetailsList();
            } else {
                this.viewToShowDeliveryManField = false;
            }

            this.setVehicleDetailsList();
            this.setDriverNameDetailsList();
            this.setLabourNameDetailsList();

            /*3 rd stock for trip sheet level - end*/

            /*2 nd stock for trip sheet level - start*/
            this.lrDtoFromDashboardScheduleLocalTrip = JSON.parse(localStorage.getItem('stockForTripsheetDashboardLocalStorage'));
            localStorage.clear();
            console.log(this.lrDtoFromDashboardScheduleLocalTrip);

            /*2 nd stock for trip sheet level - end*/
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit() {
        /*1 st stock for trip sheet level - start*/
        this.dtOptionsStockSummary = {
            dom: 'Bfrtip',
            buttons: [
            ],
            order: [[1, 'asc']],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            // pageLength: 6,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totLrs);
                $(api.column(2).footer()).html(articles);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(5).footer()).html(totAmt);
            }
        }
        this.dtOptionsStockDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 5,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(articles);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(6).footer()).html(totAmt);
            }
        }
        /*1 st stock for trip sheet level - end*/
        /*2 nd stock for trip sheet level - start*/
        this.dtOptionsEligibleLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            pageLength: 5,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(articles);
                $(api.column(5).footer()).html(actWgt);
                $(api.column(6).footer()).html(chgWgt);
                $(api.column(7).footer()).html(totAmt);
            }
        }, this.dtOptionRemainingLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            pageLength: 5,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(articles);
                $(api.column(5).footer()).html(actWgt);
                $(api.column(6).footer()).html(chgWgt);
                $(api.column(7).footer()).html(totAmt);
            }
        }
        /*2 nd stock for trip sheet level - end*/
    }

    ngAfterViewInit(): void {
        /*1 st stock for trip sheet level - start*/
        this.dtTriggerStockSummary.next();
        this.dtTriggerStockDetails.next();
        /*1 st stock for trip sheet level - end*/

        /*2 nd stock for trip sheet level - start*/
        //this.dtTriggerEligibleLrs.next();
        //this.dtTriggerRemainingLrs.next();
        /*2 nd stock for trip sheet level - end*/

        if (this.lrDtoFromDashboardScheduleLocalTrip != null &&
            this.lrDtoFromDashboardScheduleLocalTrip != undefined) {
            this.setDashboardFroScheduleLocalTripSheet();
        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        /*1 st stock for trip sheet level - start*/
        this.dtTriggerStockSummary.unsubscribe();
        this.dtTriggerStockDetails.unsubscribe();
        /*1 st stock for trip sheet level - end*/
        /*2 nd stock for trip sheet level - start*/
        this.dtTriggerEligibleLrs.unsubscribe();
        this.dtTriggerRemainingLrs.unsubscribe();
        /*2 nd stock for trip sheet level - end*/
    }

    /*  Main 1 st stock for trip sheet level - start*/
    getTripCrationForArticlesStocksSummaryDetails() {
        this.lrDtoArticleSummary = new LRDto();
        this.lrDtoArticleSummary.branch = this.userDataDtoReturnSession.office;
        this.lrDtoArticleSummary.branchType = this.userDataDtoReturnSession.stationType;
        this.lrDtoArticleSummary.companyId = this.userDataDtoReturnSession.companyId;
    }
    setTripCrationForArticlesStocksSummaryDetailsList() {
        this.getTripCrationForArticlesStocksSummaryDetails();
        this.showSpinnerForAction = true;
        this.hireslipService.getArticlesStocksSummForDestination(this.lrDtoArticleSummary).subscribe(
            (response) => {
                console.log(response);
                this.stockSummaryDataList = [];
                $("#" + this.pageId + "tripSheetForStockSummaryTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stockSummaryDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerStockSummary.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Summary Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    rowSelectedGetTripLRDeatils(stockSummaryTableData) {
        this.lrDtoStocksDetails = new LRDto();
        this.viewBinsStockMsg = stockSummaryTableData.endDest;
        this.viewLocalTripsheetMsg = stockSummaryTableData.endDest;
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "tripSheetForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
        if (stockSummaryTableData.totalLrs > 0) {
            this.makeATripBtnId = stockSummaryTableData.endDest;
            this.tripSheetBtnId = stockSummaryTableData.endDest;

            this.lrDtoStocksDetails.destination = stockSummaryTableData.endDest;
            this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
            this.lrDtoStocksDetails.subStations = stockSummaryTableData.endDest;
            this.lrDtoStocksDetails.branchType = this.userDataDtoReturnSession.stationType;
            this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;

            //CHG-V-LT:01
            if (this.destLocalTripBlockForPrevTripNotCleared) {
                this.validatePrevTripsClreared(this.lrDtoStocksDetails.destination);
            } else {
                this.getTripCreationForArticlesStocksDetailsList(this.lrDtoStocksDetails);
            }
        }
    }

    getTripCreationForArticlesStocksDetailsList(lrDtoStocksDetails) {
        this.showSpinnerForAction = true;
        this.hireslipService.getArticlesStocksForDestination(lrDtoStocksDetails).subscribe(
            (response) => {
                console.log(response);
                this.stockDetailsDataList = [];
                $("#" + this.pageId + "tripSheetForStockDetailsTableId").DataTable().destroy();
                this.stockDetailsDataList = response;
                setTimeout(() => {
                    this.dtTriggerStockDetails.next();
                }, 1000);

                if (response.length == 0) {
                    this.makeAtripBtnHideShow = true;
                    //this.tripSheetBtnHideShow = true;
                    this.tripSheetBlackBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "local")) {
                    this.makeAtripBtnHideShow = false;
                    //this.tripSheetBtnHideShow = true;
                    this.tripSheetBlackBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "Others")) {
                    this.makeAtripBtnHideShow = false;
                    //this.tripSheetBtnHideShow = false;
                    this.tripSheetBlackBtnHideShow = false;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "UnDelivered")) {
                    this.makeAtripBtnHideShow = false;
                    //this.tripSheetBtnHideShow = false;
                    this.tripSheetBlackBtnHideShow = false;
                } else {
                    this.makeAtripBtnHideShow = true;
                    //this.tripSheetBtnHideShow = false;
                    this.tripSheetBlackBtnHideShow = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Details Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    tripSheetBlackBtnClickListner() {
        console.log(this.tripSheetBtnId);
        if (this.tripSheetBtnId != null && this.tripSheetBtnId == "Others") {
            swal("Trips For Other", "Can't generate Trips for UnAssigned Lrs", "warning");
            return false;
        } else {
            this.tripSheetBtnHideShow = true;
            this.tripSheetBlackBtnHideShow = false;
            swal("OK", "Please Click The Trip Sheet button again to continue", "info");
        }
    }

    tripSheetBtnClickListner() {
        console.log(this.tripSheetBtnId);
        /* 
        if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
        */
        if (this.userDataDtoReturnSession.office != null &&
            this.userDataDtoReturnSession.office == "Binnymills") {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage.destination = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.mode = "localgrouping";
            this.lrDtoPassTripSchedulePage.invoiceNumber = null;
            this.lrDtoPassTripSchedulePage.tripId = 0;
        } else {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage.destination = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.mode = "localgrouping";
            this.lrDtoPassTripSchedulePage.invoiceNumber = null;
            this.lrDtoPassTripSchedulePage.module = "destination";
            this.lrDtoPassTripSchedulePage.selectedTripTo = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.tripId = 0;
        }
        this.showSpinnerForTripScheduleAction = true;
        this.clearFieldForStockTripSheetSchedule();
        this.showSpinnerForTripScheduleAction = false;
        $("#" + this.pageId + "dropDownInputFieldBins").val(this.tripSheetBtnId);
        setTimeout(() => {
            this.setControlBinsDestinationDetails('bins');
        }, 2000);
        this.changeDetectorRef.detectChanges();
    }
    clearFieldForStockTripSheetSchedule() {
        /* 2 nd stock for trip sheet level - start */
        this.viewNextDarkBtnForScheduleTrip = true;
        this.viewNextGreenBtnForScheduleTrip = false;
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        this.dtTriggerEligibleLrs.next();
        this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = "";
        $("#" + this.pageId + "lrNumber").val('');
        /* 2 nd stock for trip sheet level - end */
    }

    /* Main 1 st stock for trip sheet level - end*/

    /* Main 2 nd stock for trip sheet level - start*/

    onKeyPressListnerForLRNumber($event) {
        if ($event.charCode == 13) {
            console.log($event.charCode);
            if ($("#" + this.pageId + "lrNumber").val() == null || $("#" + this.pageId + "lrNumber").val() == undefined ||
                $("#" + this.pageId + "lrNumber").val() == "") {
                swal("LR Number", "Please Enter The LR Number Field", "warning");
                return false;
            } else {
                this.setLRNumberDetailsValueInDatatTable();
            }

        }
    }

    onKeyPressListnerForBinsDestination($event) {
        if ($event.charCode == 13) {
            this.setControlBinsDestinationDetails('bins');
        }
    }

    clickListnerForDropDownInputFieldBins(event) {
        this.modelDropDownBins = event.item;
        $("#" + this.pageId + "dropDownInputFieldBins").val(this.modelDropDownBins.godownName);
        console.log(this.modelDropDownBins);
        this.setControlBinsDestinationDetails('bins');
    }

    getMethodForBranchArea() {
        this.lrDtoBranchBins = new LRDto();
        this.lrDtoBranchBins.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchBins.companyId = this.userDataDtoReturnSession.companyId;
    }

    setBranchAreaDetailsList() {
        this.getMethodForBranchArea();
        this.showSpinnerForTripScheduleAction = true;
        this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchBins).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.branchBinsOption = response;
                    for (let i = 0; i < this.branchBinsOption.length; i++) {
                        this.branchBinsTA.push(this.branchBinsOption[i]);
                    }

                    this.lrDtoBranchBinsAll = new LRDto();
                    this.lrDtoBranchBinsAll.godownName = "UnDelivered";
                    this.branchBinsTA.push(this.lrDtoBranchBinsAll);
                    //hashmap concept pending here
                    this.showSpinnerForTripScheduleAction = false;
                } else {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.showSpinnerForTripScheduleAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripScheduleAction = false;
                swal("Server Error", "Problem occur while getting Area Details", "error");
            },
            () => console.log('done');
    };

    setControlBinsDestinationDetails(type) {
        this.lrDtoControlBinService = new LRDto();
        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        this.lrDtoForScheduleTripsheetReturn = this.lrDtoPassTripSchedulePage;

        console.log(this.lrDtoForScheduleTripsheetReturn);
        this.enteredStockBinsDestination = $("#" + this.pageId + "dropDownInputFieldBins").val();
        this.lrDtoControlBinService.destination = this.enteredStockBinsDestination;
        this.lrDtoControlBinService.branch = this.userDataDtoReturnSession.office;
        this.lrDtoControlBinService.branchType = this.userDataDtoReturnSession.role;
        this.lrDtoControlBinService.stationType = "local";
        this.lrDtoControlBinService.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoControlBinService.mode = type;
        this.selectedVehicleForFilter = $("#" + this.pageId + "dropDownInputFieldVehicleNumberFilter").val();
        console.log(this.selectedVehicleForFilter);
        this.lrDtoControlBinService.vehicleNumber = this.selectedVehicleForFilter;
        this.showSpinnerForTripScheduleAction = true;
        this.hireslipService.getArticlesStocksForDestination(this.lrDtoControlBinService).subscribe(
            (response) => {
                console.log(response);
                this.remainingLrsDataList = [];
                this.selectedControBinsDataOfAllLrsList = [];
                this.selectedControBinsDataOfAllLrsList = response;

                if (this.selectedControBinsDataOfAllLrsList != null &&
                    this.selectedControBinsDataOfAllLrsList.length > 0) {
                    this.remainingLrsDataList = this.selectedControBinsDataOfAllLrsList;
                }
                console.log(this.remainingLrsDataList);
                console.log(this.eligibleLrsDataList);
                if (this.eligibleLrsDataList == null || this.eligibleLrsDataList == undefined ||
                    this.eligibleLrsDataList == "" || this.eligibleLrsDataList.length == 0) {
                    this.eligibleLrsDataList = [];
                }
                this.remainingLrsDataList = this.remainingLrsDataList.filter(remainingLrsData =>
                    !this.eligibleLrsDataList.find(eligibleLrsData =>
                        (eligibleLrsData.lrNumber === remainingLrsData.lrNumber)));

                console.log(this.remainingLrsDataList);
                $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerRemainingLrs.next();
                }, 3000);
                this.showSpinnerForTripScheduleAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForTripScheduleAction = false;
                swal("Server Error", "Problem occur while getting  Stock Details Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    setLRNumberDetailsValueInDatatTable() {
        this.enteredLrNumber = null;
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        // finding eligible & remaing lrs method
        this.showSpinnerForTripScheduleAction = true;
        var validateRemaingLrNumber = this.findRemainingLrsDup(this.remainingLrsDataList, this.enteredLrNumber);
        var validateEligibleLrNumber = this.findEligibleLrs(this.eligibleLrsDataList, this.enteredLrNumber);
        this.showSpinnerForTripScheduleAction = false;
        console.log("EligibleFind: " + validateEligibleLrNumber);
        console.log("RemaingFind: " + validateRemaingLrNumber);
        if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
            swal("Alert", "LR number does not exist", "warning");
            $("#" + this.pageId + "lrNumber").focus();
        } else if (validateEligibleLrNumber != null) {
            swal("Make Invoice", "LR Number '" + $("#" + this.pageId + "lrNumber").val() + "'Already Transfered", "warning");
            $("#" + this.pageId + "lrNumber").focus();
        } else {
            this.setLrNumberEligibleAndRemaingLrs();
            $("#" + this.pageId + "lrNumber").val('');
            $("#" + this.pageId + "lrNumber").focus();
        }
    }

    findRemainingLrsDup(findRemaingLrs, lrNumber) {
        var checkingRemLrNumber = null;
        var remainLrNumberReturn = null;
        for (let i = 0; i < findRemaingLrs.length; i++) {
            checkingRemLrNumber = findRemaingLrs[i].lrNumber;
            if (checkingRemLrNumber == lrNumber) {
                remainLrNumberReturn = checkingRemLrNumber;
                break;
            } else {
                remainLrNumberReturn = null;
            }
        }
        return remainLrNumberReturn;
    }

    findEligibleLrs(findEligibleLrs, lrNumber) {
        var checkingEligLrNumber = null;
        var eligibleLrNumberReturn = null;
        for (let i = 0; i < findEligibleLrs.length; i++) {
            checkingEligLrNumber = findEligibleLrs[i].lrNumber;
            if (checkingEligLrNumber == lrNumber) {
                eligibleLrNumberReturn = checkingEligLrNumber;
                break;
            } else {
                eligibleLrNumberReturn = null;
            }
        }
        return eligibleLrNumberReturn;
    }

    setLrNumberEligibleAndRemaingLrs() {
        this.resultList = null;
        this.enteredLrNumber = null;
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        this.showSpinnerForTripScheduleAction = true;
        this.resultList = this.remainingLrsDataList.filter(remainingLrsData =>
            remainingLrsData.lrNumber == this.enteredLrNumber);
        if (this.resultList.length > 0) {
            for (let i = 0; i < this.resultList.length; i++) {
                this.eligibleLrsDataList.push(this.resultList[i]);
            }
            $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerEligibleLrs.next();
            }, 1000);

            const deletedContrat = this.remainingLrsDataList.find(x => x.lrNumber === this.enteredLrNumber);
            this.remainingLrsDataList.splice(this.remainingLrsDataList.indexOf(deletedContrat), 1);
            $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerRemainingLrs.next();
            }, 1000);
            // console.log(deletedContrat);
        }
        this.showSpinnerForTripScheduleAction = false;
    }
    validateMoveAllBtnForScheduleTrip() {
        console.log(this.remainingLrsDataList);
        console.log(this.remainingLrsDataList.length);
        this.showSpinnerForTripScheduleAction = true;
        for (let i = 0; i < this.remainingLrsDataList.length; i++) {
            this.eligibleLrsDataList.push(this.remainingLrsDataList[i]);
        }
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerEligibleLrs.next();
        }, 1000);
        this.remainingLrsDataList = [];
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerRemainingLrs.next();
        }, 1000);
        this.showSpinnerForTripScheduleAction = false;
    }

    rowSelectedEligibleLrDelete(selectedEligibleLrsRowData) {
        this.enteredLrNumber = null;
        this.enteredLrNumber = selectedEligibleLrsRowData.lrNumber;
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        // finding eligible & remaing lrs method
        this.showSpinnerForTripScheduleAction = true;
        var validateRemaingLrNumber = this.findRemainingLrsDup(this.remainingLrsDataList, this.enteredLrNumber);
        var validateEligibleLrNumber = this.findEligibleLrs(this.eligibleLrsDataList, this.enteredLrNumber);
        this.showSpinnerForTripScheduleAction = false;
        console.log("EligibleFind: " + validateEligibleLrNumber);
        console.log("RemaingFind: " + validateRemaingLrNumber);
        if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
            swal("Alert", "LR number does not exist", "warning");
        } else if (validateRemaingLrNumber != null) {
            swal("Make Invoice", "LR Number '" + selectedEligibleLrsRowData.lrNumber + "'Already Transfered", "warning");
        } else {
            this.resultList = null;
            this.showSpinnerForTripScheduleAction = true;
            this.resultList = this.eligibleLrsDataList.filter(eligibleLrsData =>
                eligibleLrsData.lrNumber == selectedEligibleLrsRowData.lrNumber);
            if (this.resultList.length > 0) {
                for (let i = 0; i < this.resultList.length; i++) {
                    this.remainingLrsDataList.push(this.resultList[i]);
                }
                $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerRemainingLrs.next();
                }, 1000);

                const deletedContrat = this.eligibleLrsDataList.find(x => x.lrNumber === selectedEligibleLrsRowData.lrNumber);
                this.eligibleLrsDataList.splice(this.eligibleLrsDataList.indexOf(deletedContrat), 1);
                $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerEligibleLrs.next();
                }, 1000);
            }
            this.showSpinnerForTripScheduleAction = false;
        }
    }

    stockTripClearBtn() {
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        this.dtTriggerEligibleLrs.next();
        this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = null;
        $("#" + this.pageId + "lrNumber").val('');

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
    }
    backBtnForStockTripSheet() {
        /*1nd stock for trip sheet level - end */
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "tripSheetForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.viewBinsStockMsg = "Bins Stock";
        this.viewLocalTripsheetMsg = "";

        this.makeAtripBtnHideShow = false;
        this.tripSheetBlackBtnHideShow = false;
        this.tripSheetBtnHideShow = false;

        this.makeATripBtnId = null;
        this.tripSheetBtnId = null;
        /*1nd stock for trip sheet level - end */
    }
    resetBtnTripSheduleFrom() {
        swal({
            title: "Reset Confirm",
            text: "Sure U want to reset this Trip details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.stepper.reset();
                this.clearAllField();
                this.setTripCrationForArticlesStocksSummaryDetailsList();
            }
        });

    }

    validateNextBtnForScheduleTrip() {
        if (this.eligibleLrsDataList == null || this.eligibleLrsDataList == undefined ||
            this.eligibleLrsDataList == "" || this.eligibleLrsDataList.length == 0) {
            swal("Not Allowed", "No Lrs Found", "warning");
            return false;
        } else {
            this.viewNextDarkBtnForScheduleTrip = false;
            this.viewNextGreenBtnForScheduleTrip = true;
            swal("OK", "Please Click The Next button again to continue", "info");
        }
    }

    scheduleForNextTripBtn() {
        this.lrDtoCreateATripDetail = new LRDto();
        this.lrDtoCreateATripDetail.lrDtoEligible = this.eligibleLrsDataList;
        /*3 rd stock for trip sheet level - start */
        this.viewScheduleTripBtn = true;
        /*3 rd stock for trip sheet level - end */
        this.selectedVehicleForFilter = $("#" + this.pageId + "dropDownInputFieldVehicleNumberFilter").val();
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val(this.selectedVehicleForFilter);
        // if (this.selectedVehicleForFilter != null && this.selectedVehicleForFilter != undefined) {
        //     $("#" + this.pageId + 'dropDownInputFieldVehicleNumber').prop('disabled', true);
        // }
    }

    /* Main 2 nd stock for trip sheet level - end */

    /* Main 3 rd stock for trip sheet level - start */

    clickListnerForDropDownInputFieldVehicleNumber(event, vehicleNumberPopUpTemplate) {
        if ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "Add New") {
            this.vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate);
        }
    }

    clickListnerForDropDownInputFieldVehicleNumberFilter(event) {
        setTimeout(() => {
            this.setControlBinsDestinationDetails('vehiclebase');
        }, 500);
    }

    getVehicleNumberDetails() {
        this.truckDataDtoVehicleNumber = new TruckDataDto();
        this.truckDataDtoVehicleNumber.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDtoVehicleNumber.isActive = true;
        this.truckDataDtoVehicleNumber.companyId = this.userDataDtoReturnSession.companyId;
    }

    setVehicleDetailsList() {
        this.getVehicleNumberDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getVehicleSetupMaster(this.truckDataDtoVehicleNumber).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.vehicleNumberFilterTA = [];
                    this.vehicleNumberOption = response;

                    if (this.userDataDtoReturnSession.mainStation != "Chennai") {
                        // this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                        //this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                        this.vehicleNumberTA.push('Add New');
                    }

                    for (let i = 0; i < this.vehicleNumberOption.length; i++) {
                        this.vehicleNumberTA.push(this.vehicleNumberOption[i].truckNumber);
                        this.vehicleNumberFilterTA.push(this.vehicleNumberOption[i].truckNumber);
                        this.hashmapVehicle.set(this.vehicleNumberOption[i].truckNumber,
                            this.vehicleNumberOption[i].vehicleName);
                    }
                    //hashmap concept pending here
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.vehicleNumberFilterTA = [];
                    if (this.userDataDtoReturnSession.mainStation != "Chennai") {
                        // this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                        // this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                        this.vehicleNumberTA.push('Add New');
                    }
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    clickListnerForDropDownInputFieldDriverName(event) {
        this.modelDropDownDriverName = event.item;
        $("#" + this.pageId + "dropDownInputFieldDriverName").val(this.modelDropDownDriverName.driverName);
        console.log(this.modelDropDownDriverName);
        $("#" + this.pageId + "inputFieldDriverConatctNumber").val(this.modelDropDownDriverName.mobileNo == null ? null :
            this.modelDropDownDriverName.mobileNo == undefined ? null :
                this.modelDropDownDriverName.mobileNo);
    }

    getDriverNameDetails() {
        this.driverDataDtoDriverName = new DriverdetailsDto();
        this.driverDataDtoDriverName.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDataDtoDriverName.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDataDtoDriverName.mode = "MainStation";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.driverDataDtoDriverName.mode = "Station";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.office;

        }
    }

    setDriverNameDetailsList() {
        this.getDriverNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.driverNameOption = response;
                    for (let i = 0; i < this.driverNameOption.length; i++) {
                        this.driverNameTA.push(this.driverNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForDropDownLabourName(event) {
        if (event.charCode == 13) {
            this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourName").val();
            //$("#"+this.pageId+"hamalis").focus();
            if ($("#" + this.pageId + "hamalis").val() != null &&
                $("#" + this.pageId + "hamalis").val() != undefined &&
                $("#" + this.pageId + "hamalis").val() != "") {
                this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                    this.enteredlabourNameListOfHamaliAdd;
            }
            $("#" + this.pageId + "hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
                this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                    this.enteredlabourNameListOfHamaliAdd);
            this.modelDropDownLabourName = null;
            $("#" + this.pageId + "dropDownInputFieldLabourName").val('');
        }

    }
    clickListnerForDropDownInputFieldLabourName(event) {
        this.modelDropDownLabourName = event.item;
        $("#" + this.pageId + "dropDownInputFieldLabourName").val(this.modelDropDownLabourName.loaderName);
        this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourName").val();
        //$("#"+this.pageId+"hamalis").focus();
        if ($("#" + this.pageId + "hamalis").val() != null &&
            $("#" + this.pageId + "hamalis").val() != undefined &&
            $("#" + this.pageId + "hamalis").val() != "") {
            this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                this.enteredlabourNameListOfHamaliAdd;
        }
        $("#" + this.pageId + "hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
            this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                this.enteredlabourNameListOfHamaliAdd);
        this.modelDropDownLabourName = null;
        $("#" + this.pageId + "dropDownInputFieldLabourName").val('');
    }

    getLabourNameDetails() {
        this.lrDtoLabourName = new LRDto();
        this.lrDtoLabourName.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLabourName.companyId = this.userDataDtoReturnSession.companyId;
    }

    setLabourNameDetailsList() {
        this.getLabourNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getLabourDetails(this.lrDtoLabourName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.labourNameOption = response;
                    for (let i = 0; i < this.labourNameOption.length; i++) {
                        this.labourNameTA.push(this.labourNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForDropDownLabourHead(event) {
        if (event.charCode == 13) {
            this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
            //$("#"+this.pageId+"hamalis").focus();
            if ($("#" + this.pageId + "hamalis").val() != null &&
                $("#" + this.pageId + "hamalis").val() != undefined &&
                $("#" + this.pageId + "hamalis").val() != "") {
                this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                    this.enteredlabourHeadNameListOfHamaliAdd;
            }
            $("#" + this.pageId + "hamalis").val(this.enteredlabourHeadNameListOfHamaliAdd == null ? null :
                this.enteredlabourHeadNameListOfHamaliAdd == undefined ? null :
                    this.enteredlabourHeadNameListOfHamaliAdd);
            this.modelDropDownLabourHeadName = null;
            $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
        }
    }
    clickListnerForDropDownInputFieldLabourHeadName(event) {
        this.modelDropDownLabourHeadName = event.item;
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val(this.modelDropDownLabourHeadName.loaderHead);

        this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
        //$("#"+this.pageId+"hamalis").focus();
        if ($("#" + this.pageId + "hamalis").val() != null &&
            $("#" + this.pageId + "hamalis").val() != undefined &&
            $("#" + this.pageId + "hamalis").val() != "") {
            this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                this.enteredlabourHeadNameListOfHamaliAdd;
        }
        $("#" + this.pageId + "hamalis").val(this.enteredlabourHeadNameListOfHamaliAdd == null ? null :
            this.enteredlabourHeadNameListOfHamaliAdd == undefined ? null :
                this.enteredlabourHeadNameListOfHamaliAdd);
        this.modelDropDownLabourHeadName = null;
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
    }

    getLabourHeadNameDetails() {
        this.hireSlipDtoLabourHeadName = new HireSlipDto();
        this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.getLabourHeadNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate) {
        this.modalRefferenceVehicleNumberPopUp = this.modalService.open(vehicleNumberPopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceVehicleNumberPopUp.result.then((result) => {
            this.modalRefferenceVehicleNumberClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceVehicleNumberClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    closePopUpVehiclePopup() {
        $("#" + this.pageId + "addNewVehicleNumberId").val('');
        $("#" + this.pageId + "addNewVehicleNameId").val('');
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
        this.modalRefferenceVehicleNumberPopUp.close();
    }

    resetBtnVehicleNumberPopup() {
        $("#" + this.pageId + "addNewVehicleNumberId").val('');
        $("#" + this.pageId + "addNewVehicleNameId").val('');
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
    }

    saveBtnVehicleNumberPopup() {

        if ($("#" + this.pageId + "addNewVehicleNumberId").val() == null || $("#" + this.pageId + "addNewVehicleNumberId").val() == undefined ||
            $("#" + this.pageId + "addNewVehicleNumberId").val() == "" || $("#" + this.pageId + "addNewVehicleNameId").val() == null ||
            $("#" + this.pageId + "addNewVehicleNameId").val() == undefined || $("#" + this.pageId + "addNewVehicleNameId").val() == "") {
            swal("Mandatory Fields", "Please enter the mandatory mields", "warning");
            return false;
        } else {
            this.truckDataDtoNewVehicleNumberSave = new TruckDataDto();
            this.enteredPopUpNewVehicleNumber = $("#" + this.pageId + "addNewVehicleNumberId").val();
            this.eneterdPopUpNewVehicleName = $("#" + this.pageId + "addNewVehicleNameId").val();
            this.truckDataDtoNewVehicleNumberSave.truckNumber = this.enteredPopUpNewVehicleNumber;
            this.truckDataDtoNewVehicleNumberSave.vehicleName = this.eneterdPopUpNewVehicleName;
            this.truckDataDtoNewVehicleNumberSave.office = this.userDataDtoReturnSession.office;
            this.truckDataDtoNewVehicleNumberSave.mainStation = this.userDataDtoReturnSession.mainStation;
            this.truckDataDtoNewVehicleNumberSave.companyId = this.userDataDtoReturnSession.companyId;

            this.masterService.createVehicle(this.truckDataDtoNewVehicleNumberSave).subscribe(
                (response) => {
                    console.log(response);
                    this.truckDataDtoNewVehicleNumberSaveReturn = null;
                    this.truckDataDtoNewVehicleNumberSaveReturn = response;
                    if (this.truckDataDtoNewVehicleNumberSaveReturn.status == "persisted") {
                        $("#" + this.pageId + "addNewVehicleNumberId").val('');
                        $("#" + this.pageId + "addNewVehicleNameId").val('');
                        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
                        this.modalRefferenceVehicleNumberPopUp.close();
                        this.setVehicleDetailsList();
                        swal("Sucessfully Added", "Vehicle Details Added Sucessfully", "success");
                        //this.showSpinnerForAction = false;
                    } else {
                        swal("Failure", "Failed to Create Vehicle Details", "error");
                        //this.showSpinnerForAction = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    //this.showSpinnerForAction = false;
                    swal("Server Error", "Problem occur while create Vehicle Setup ", "error");
                },
                () => console.log('done');
        }
    }

    validateCreateTripSheet() {
        if (this.viewToShowDeliveryManField && (($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == ""))) {
            swal("Mandatory Field", "Please select Delivery Man", "warning");
            return false;
        } else if (($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "") ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == "")) {
            swal("Mandatory Field", "Vehicle Number & Driver Name is Mandatory Field", "warning");
            return false;
        } else {
            swal({
                title: "Trip Sheet",
                text: "Sure U want to Create this Trip",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.lrDtoSetTripDetailsReturn = new LRDto();
                    this.lrDtoSetTripDetailsReturn = this.getTripDetails("Pending");
                    console.log(this.lrDtoPassTripSchedulePage.tripId);
                    // its value cmg from 1 page when trip create  1 st level
                    if (this.lrDtoPassTripSchedulePage.tripId != null &&
                        this.lrDtoPassTripSchedulePage.tripId > 0) {
                        this.lrDtoSetTripDetailsReturn.tripId = this.lrDtoPassTripSchedulePage.tripId;
                        this.departUpdatedTrip(this.lrDtoSetTripDetailsReturn);
                    } else {
                        this.departNewTrip(this.lrDtoSetTripDetailsReturn);
                    }
                }
            });
        }
    }

    getTripDetails(status) {
        console.log(this.lrDtoCreateATripDetail);
        this.lrDtoSetTripDetails = new LRDto();
        this.listOfLrs = [];
        this.validateTotArticles = 0;
        this.validateTotAmt = 0.0;
        this.validateActWgt = 0.0;
        this.validateChgWgt = 0.0;
        this.validateDdAmt = 0.0;
        this.validateTripsheetHamaliAmt = 0.0;

        this.enteredVehicleNumber = null;
        this.enteredDriverName = null;
        this.enteredDriverMobileNo = null;
        this.enteredLabourHamaliName = null;
        this.enteredLoaderHeadName = null;

        for (let i = 0; i < this.lrDtoCreateATripDetail.lrDtoEligible.length; i++) {
            this.listOfLrs.push(this.lrDtoCreateATripDetail.lrDtoEligible[i].lrNumber);

            this.validateTotArticles = +this.validateTotArticles + +this.lrDtoCreateATripDetail.lrDtoEligible[i].totalArticles;
            this.validateTotAmt = +this.validateTotAmt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].totalAmount;
            this.validateActWgt = +this.validateActWgt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].actualWeight;
            this.validateChgWgt = +this.validateChgWgt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].chargedWeight;
            this.validateDdAmt = +this.validateDdAmt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].memoHamaliDD;
            this.validateTripsheetHamaliAmt = +this.validateTripsheetHamaliAmt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].tripsheetHamali;
        }
        this.enteredVehicleNumber = $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val();
        this.enteredDriverName = $("#" + this.pageId + "dropDownInputFieldDriverName").val();
        this.enteredDriverMobileNo = $("#" + this.pageId + "inputFieldDriverConatctNumber").val();
        this.enteredLabourHamaliName = $("#" + this.pageId + "hamalis").val();

        this.lrDtoSetTripDetails.list = this.listOfLrs;
        this.lrDtoSetTripDetails.totalArticles = this.validateTotArticles;
        this.lrDtoSetTripDetails.totalAmount = this.validateTotAmt;
        this.lrDtoSetTripDetails.actualWeight = this.validateActWgt;
        this.lrDtoSetTripDetails.chargedWeight = this.validateChgWgt;
        this.lrDtoSetTripDetails.hamaliDD = this.validateDdAmt + +this.validateTripsheetHamaliAmt;
        console.log('validateTripsheetHamaliAmt');
        console.log(this.validateDdAmt);
        console.log(this.validateTripsheetHamaliAmt);
        console.log(this.lrDtoSetTripDetails.hamaliDD);

        this.lrDtoSetTripDetails.vehicleNumber = this.enteredVehicleNumber;
        this.enteredTransportName = this.hashmapVehicle.get(this.enteredVehicleNumber);
        console.log(this.enteredTransportName);
        this.lrDtoSetTripDetails.transportName = this.enteredTransportName;
        // its value cmg from 1 page when trip create  1 st level
        /*  if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
           */
        if (this.userDataDtoReturnSession.office != null &&
            this.userDataDtoReturnSession.office == "Binnymills") {
            this.lrDtoSetTripDetails.areaOfDelivery = this.lrDtoPassTripSchedulePage.destination;
        } else {
            this.lrDtoSetTripDetails.areaOfDelivery = this.lrDtoPassTripSchedulePage.selectedTripTo;
        }

        this.lrDtoSetTripDetails.branch = this.userDataDtoReturnSession.office;
        this.lrDtoSetTripDetails.totalLrs = this.lrDtoCreateATripDetail.lrDtoEligible.length;
        this.lrDtoSetTripDetails.entryBy = this.userDataDtoReturnSession.userId;
        this.lrDtoSetTripDetails.driverName = this.enteredDriverName;
        this.lrDtoSetTripDetails.status = status;
        this.lrDtoSetTripDetails.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSetTripDetails.labourHamaliStmt = this.enteredLabourHamaliName;
        this.lrDtoSetTripDetails.mobileNum = this.enteredDriverMobileNo;
        this.lrDtoSetTripDetails.isOpenTrip = this.userDataDtoReturnSession.isOpenTrip;

        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.lrDtoSetTripDetails.timeLogin = true;
        } else {
            this.lrDtoSetTripDetails.timeLogin = false;
        }
        // its value cmg from 1 page when trip create  1 st level
        this.lrDtoSetTripDetails.mode = this.lrDtoPassTripSchedulePage.module;
        this.lrDtoSetTripDetails.companyId = this.userDataDtoReturnSession.companyId;

        if (this.viewToShowDeliveryManField == true) {
            this.enteredLoaderHeadName = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
            this.lrDtoSetTripDetails.loaderName = this.enteredLoaderHeadName;
        }

        //CHG-V-LT:02
        this.checkBoxForIsMrngTrip = $("#" + this.pageId + "isMrngTrip").is(":checked");
        this.lrDtoSetTripDetails.isTrue = this.checkBoxForIsMrngTrip;

        console.log(this.lrDtoSetTripDetails);
        return this.lrDtoSetTripDetails;
    }

    departNewTrip(lrdtoNewTripCreate) {
        console.log('lrdtoNewTripCreate');
        console.log(lrdtoNewTripCreate);

        this.enteredVehicleNumber = $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val();
        console.log(this.enteredVehicleNumber);
        if (this.enteredVehicleNumber == null || this.enteredVehicleNumber == '') {
            swal("Not Allowed", "Please select the Vehicle to proceed! ", "warning");
        } else {
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(/\s/g, "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace("-", "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(".", "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(",", "");
            console.log(this.enteredVehicleNumber);
            if (this.enteredVehicleNumber.length < 8 || this.enteredVehicleNumber.length > 11) {
                swal("Invalid Vehicle Number", "Selected Vehicle Number is invalid, please verify it ", "warning");
            } else {
                var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                if (format.test(this.enteredVehicleNumber)) {
                    swal("Invalid Vehicle Number", "Selected Vehicle number is not valid, please verify it ", "warning");
                } else {
                    console.log('Valid Vehicle Number');
                    this.lrdtoNewTripCreateAndPrint = new LRDto();
                    this.lrdtoNewTripCreateAndPrint = lrdtoNewTripCreate;
                    this.showSpinnerForTripDetailsAction = true;
                    $("#" + this.pageId + "createTripSheetBtn").prop('disabled', true);
                    this.hireslipService.addTripSheetDetails(this.lrdtoNewTripCreateAndPrint).subscribe(
                        (response) => {
                            if (response.status != null && response.status == "Success") {
                                this.lrdtoNewTripCreateAndPrint.tripNo = response.tripNo;
                                this.lrdtoNewTripCreateAndPrint.tripDate = response.tripDate;
                                this.lrdtoNewTripCreateAndPrint.localTripNumber = response.localTripNumber;
                                this.lrdtoNewTripCreateAndPrint.lrDtoEligible = this.eligibleLrsDataList;
                                this.lrdtoNewTripCreateAndPrint.id = response.id;
                                console.log('lrdtoNewTripCreate-1');
                                console.log(response.id);
                                if (this.consolidateEwaybillEnabled) {
                                    console.log('this.consolidateEwaybillEnabled');
                                    this.confirmGenerateConsolidateEwaybill(response.id);
                                } else {
                                    this.printTripsheetMethod(null);
                                }
                            } else {
                                $("#" + this.pageId + "createTripSheetBtn").prop('disabled', false);
                                swal("Server Error", "Failed to  Create A TripSheet Details ", "error");
                            }
                            this.showSpinnerForTripDetailsAction = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            this.showSpinnerForTripDetailsAction = false;
                            $("#" + this.pageId + "createTripSheetBtn").prop('disabled', false);
                            swal("Server Error", "Problem occur while adding a  TripSheet Details ", "error");
                        },
                        () => console.log('done');
                }
            }
        }



    }

    printTripsheetMethod(ewayBill) {
        swal({
            title: "Print Trip Sheet",
            text: "Sure U want to print this Trip Sheet",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureTripPrintYesBtn) => {
            if (sureTripPrintYesBtn) {
                localStorage.clear();
                this.lrdtoNewTripCreateAndPrint.eWayBillNo = ewayBill;
                localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(this.lrdtoNewTripCreateAndPrint));
                this.printMtd();
                // setTimeout(() => {                           //<<<---using ()=> syntax
                //     this.viewStockTripsheetPrintDest = false;
                //     localStorage.clear();
                //     this.clearAllField();
                // }, 2000);
                /* window.addEventListener('afterPrint', (event) => {
                     this.viewStockTripsheetPrint = false;
                     localStorage.clear();
                     this.clearAllField();
                 });*/

            } else {
                this.clearAllField();
            }
            this.changeDetectorRef.detectChanges();
        })
    }


    departUpdatedTrip(lrdtoUpdateTrip) {
        console.log(lrdtoUpdateTrip);
        this.lrdtoUpdateTripCreateAndPrint = new LRDto();
        this.lrdtoUpdateTripCreateAndPrint = lrdtoUpdateTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.updateDestLocalTripSheetDetails(this.lrdtoUpdateTripCreateAndPrint).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    this.lrdtoUpdateTripCreateAndPrint.tripNo = response.tripNo;
                    this.lrdtoUpdateTripCreateAndPrint.tripDate = response.tripDate;
                    //this.lrdtoUpdateTripCreateAndPrint.localTripNumber = response.localTripNumber;
                    this.lrdtoUpdateTripCreateAndPrint.lrDtoEligible = this.eligibleLrsDataList;
                    swal({
                        title: "Print Trip Sheet",
                        text: "Sure U want to print this Trip Sheet",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((sureTripPrintYes) => {
                        if (sureTripPrintYes) {
                            localStorage.clear();
                            localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(this.lrdtoUpdateTripCreateAndPrint));
                            this.printMtd();
                            // setTimeout(() => {                           //<<<---using ()=> syntax
                            //     this.viewStockTripsheetPrintDest = false;
                            //     localStorage.clear();
                            //     this.clearAllField();
                            // }, 2000);
                            /* window.addEventListener('afterPrint', (event) => {
                                 this.viewStockTripsheetPrint = false;
                                 localStorage.clear();
                                 this.clearAllField();
                             });*/
                        } else {
                            this.clearAllField();
                        }
                    });
                } else {
                    swal("Server Error", "Failed to  Update A TripSheet Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while Update a  TripSheet Details ", "error");
            },
            () => console.log('done');
    }

    printMtd() {
        this.viewStockTripsheetPrintDest = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStockTripsheetPrintDest) {
                this.viewStockTripsheetPrintDest = false;
                this.changeDetectorRef.detectChanges();
                localStorage.clear();
                this.clearAllField();
            }
        });
        this.changeDetectorRef.detectChanges();
    }



    validateScheduleTripSheet() {
        if (($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "") ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == "")) {
            swal("Mandatory Field", "Vehicle Number & Driver Name is Mandatory Field", "warning");
            return false;
        } else {
            swal({
                title: "Trip Sheet",
                text: "Sure U want to Schedule this Trip",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.lrDtoSetTripDetailsReturn = new LRDto();
                    this.lrDtoSetTripDetailsReturn = this.getTripDetails("Scheduled");
                    console.log(this.lrDtoPassTripSchedulePage.tripId);
                    // its value cmg from 1 page when trip create  1 st level
                    if (this.lrDtoPassTripSchedulePage.tripId != null &&
                        this.lrDtoPassTripSchedulePage.tripId > 0) {
                        this.lrDtoSetTripDetailsReturn.tripId = this.lrDtoPassTripSchedulePage.tripId;
                        this.scheduleUpdateTrip(this.lrDtoSetTripDetailsReturn);
                    } else {
                        this.scheduleAddTrip(this.lrDtoSetTripDetailsReturn);
                    }
                }
            });
        }
    }

    scheduleAddTrip(lrdtoAddScheduleTrip) {
        this.lrdtoForAddNewScheduleTrip = new LRDto();
        this.lrdtoForAddNewScheduleTrip = lrdtoAddScheduleTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.addTripSheetDetails(this.lrdtoForAddNewScheduleTrip).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    swal("Trip Scheduled", "Trip Scheduled successfully", "success");
                    this.clearAllField();
                } else {
                    swal("Server Error", "Failed to  Create Schedule TripSheet Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while adding a  Schedule TripSheet Details ", "error");
            },
            () => console.log('done');
    }

    scheduleUpdateTrip(lrdtoUpdateScheduleTrip) {
        this.lrdtoForUpdateScheduleTrip = new LRDto();
        this.lrdtoForUpdateScheduleTrip = lrdtoUpdateScheduleTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.updateDestLocalTripSheetDetails(this.lrdtoForUpdateScheduleTrip).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    swal("Trip Scheduled", "Trip Scheduled successfully", "success");
                    this.clearAllField();
                } else {
                    swal("Server Error", "Failed to  Update A Schedule Trip Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while Update A Trip Details ", "error");
            },
            () => console.log('done');

    }

    resetBtnTripDetailsFrom() {
        swal({
            title: "Reset Confirm",
            text: "Sure U want to reset this Trip details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.stepper.reset();
                this.clearAllField();
                this.setTripCrationForArticlesStocksSummaryDetailsList();
            }
        });

    }

    startOverBtnTripDetailsFrom() {
        this.stepper.reset();
        this.clearAllField();
        this.setTripCrationForArticlesStocksSummaryDetailsList();
    }

    backBtnForStockTripSheetDetails() {
        /*2nd stock for trip sheet level - end */
        /*  if (this.eligibleLrsDataList.length == null ||
              this.eligibleLrsDataList.length == undefined ||
              this.eligibleLrsDataList.length == 0) {*/
        if (this.eligibleLrsDataList == null ||
            this.eligibleLrsDataList == undefined ||
            this.eligibleLrsDataList.length == 0) {

            this.remainingLrsDataList = [];
            this.eligibleLrsDataList = [];
            $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
            $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
            this.dtTriggerEligibleLrs.next();
            this.dtTriggerRemainingLrs.next();
            this.modelDropDownBins = null;
        }

        $("#" + this.pageId + "lrNumber").val('');
        this.viewNextDarkBtnForScheduleTrip = true;
        this.viewNextGreenBtnForScheduleTrip = false;

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        this.lrDtoFromDashboardScheduleLocalTrip = new LRDto();
        /*2nd stock for trip sheet level - end */
    }

    clearAllField() {
        /*1nd stock for trip sheet level - end */
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "tripSheetForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.viewBinsStockMsg = "Bins Stock";
        this.viewLocalTripsheetMsg = "";

        this.makeAtripBtnHideShow = false;
        this.tripSheetBlackBtnHideShow = false;
        this.tripSheetBtnHideShow = false;


        this.makeATripBtnId = null;
        this.tripSheetBtnId = null;
        /*1nd stock for trip sheet level - end */

        /*2nd stock for trip sheet level - end */
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        //this.dtTriggerEligibleLrs.next();
        //this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = null;

        this.viewNextDarkBtnForScheduleTrip = false;
        this.viewNextGreenBtnForScheduleTrip = false;

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        this.lrDtoFromDashboardScheduleLocalTrip = new LRDto();
        /*2nd stock for trip sheet level - end */

        /* 3 rd stock for trip sheet level - start*/
        this.viewScheduleTripBtn = false;
        this.modelDropDownDriverName = null;
        $("#" + this.pageId + "inputFieldDriverConatctNumber").val('');
        this.modelDropDownLabourHeadName = null;
        this.modelDropDownLabourName = null;
        $("#" + this.pageId + "hamalis").val('');
        /* 3 rd stock for trip sheet level - end */
        $("#" + this.pageId + "dropDownInputFieldVehicleNumberFilter").val('');
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
        this.selectedVehicleForFilter = null;
        $("#" + this.pageId + "isMrngTrip").prop('checked', false);
        localStorage.clear();
    }

    /* Main 3 rd stock for trip sheet level - start */

    /*2nd level dashboard start */
    setDashboardFroScheduleLocalTripSheet() {
        this.stepper.selectedIndex = 1;
        /* 
        if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
        */
        if (this.userDataDtoReturnSession.office != null &&
            this.userDataDtoReturnSession.office == "Binnymills") {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage = this.lrDtoFromDashboardScheduleLocalTrip;
        } else {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage = this.lrDtoFromDashboardScheduleLocalTrip;
        }
        this.viewLocalTripsheetMsg = this.lrDtoFromDashboardScheduleLocalTrip.destination;
        this.showSpinnerForTripScheduleAction = true;
        this.clearFieldForStockTripSheetSchedule();
        this.showSpinnerForTripScheduleAction = false;
        setTimeout(() => {
            this.getScheduledLRDetailsFromDashboard();
        }, 2000);
    }

    getScheduledLRDetailsFromDashboard() {
        //console.log("checking");
        //console.log(this.lrDtoPassTripSchedulePage);
        this.lrDtoForScduleLrDashboardLocalTrip = new LRDto();
        this.lrDtoForScduleLrDashboardLocalTrip.tripId = this.lrDtoPassTripSchedulePage.tripId;
        this.lrDtoForScduleLrDashboardLocalTrip.status = "Scheduled";
        this.lrDtoForScduleLrDashboardLocalTrip.godownName = this.userDataDtoReturnSession.office;
        this.lrDtoForScduleLrDashboardLocalTrip.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.getLocalTripsLrsDetails(this.lrDtoForScduleLrDashboardLocalTrip).subscribe(
            (response) => {
                //console.log(response);
                this.eligibleLrsDataList = [];
                $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.eligibleLrsDataList = response;
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    swal("Alert", "No Record Found", "warning");
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.dtTriggerEligibleLrs.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting local trip lrs Details ", "error");
            },
            () => console.log('done');
    }

    confirmGenerateConsolidateEwaybill(tripId) {
        swal({
            title: "Confirm Generate",
            text: "You Want to generate the Consolidate Eway Bill for this Trip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                //this.generateConsolidateEwaybill(tripId);
                this.validateEwaybillExpiryDateForConsolidatedgeneration(tripId);
            } else {
                swal.close();
                this.printTripsheetMethod(null);
            }

        })
    }

    validateEwaybillExpiryDateForConsolidatedgeneration(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Dest';
        this.showSpinnerForAction = true;
        this.ewaybillService.validateEwaybillExpiryDateForDestLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    var expiredEwaybills = this.invalidExpiryEwayBillsIteration(response);
                    swal({
                        title: "Eway bill Issue",
                        text: "The following Ewaybill with LR numbers are expired/Invalid and this will not include in the Consolidated Ewaybill generation, do you want to proceed?" + expiredEwaybills,
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.generateConsolidateEwaybill(tripId);
                        } else {
                            swal.close();
                        }
                    })
                } else {
                    this.generateConsolidateEwaybill(tripId);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Failed", "Failed to validate the Eway bill expiry!", "error");
            }, () => console.log('done');
    }

    invalidExpiryEwayBillsIteration(lrDtos) {
        var ewayBills = "";
        this.listOfExpiredEwaybills = [];
        for (let i = 0; i < lrDtos.length; i++) {
            if (i == 0) {
                ewayBills = lrDtos[i].lrNumber + "-" + lrDtos[i].eWayBillNo + "-" + lrDtos[i].ewayBillValidUptoStr;
            } else {
                ewayBills = ewayBills + ", " + lrDtos[i].lrNumber + "-" + lrDtos[i].eWayBillNo + "-" + lrDtos[i].ewayBillValidUptoStr;
            }
            this.listOfExpiredEwaybills.push(lrDtos[i].eWayBillNo);
        }
        return ewayBills;
    }

    generateConsolidateEwaybill(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Dest';
        this.requestDto.list = this.listOfExpiredEwaybills;
        this.showSpinnerForAction = true;
        this.ewaybillService.generateConsolidateEwaybillLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal({
                        title: "Completed",
                        text: "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo + ". Want to take Local Tripsheet Print?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.printTripsheetMethod(response.ewayBillNo);
                        }
                    })
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1);
                } else if (response.status == "No record") {
                    swal({
                        title: "No Eway Bill",
                        text: "No Eway bills found to generate the Consolidated Eway bill, please contact Admin if any! Want to take Local Tripsheet Print?",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.printTripsheetMethod(null);
                        }
                    })
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed to generate the Consolidated Eway bill, please try again or contact Admin! Want to take Local Tripsheet Print?",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.printTripsheetMethod(null);
                        }
                    })
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 5 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    validatePrevTripsClreared(area) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = this.userDataDtoReturnSession.office;
        this.requestDto.param2 = area;
        this.showSpinnerForAction = true;
        this.hireslipService.validateDestinationLocalTripCleared(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Notcleared") {
                    swal("Not Allowed", "Previous Trips of the area " + area + " not cleared yet, please delivered all the LRs of the previous Trips to generate the next Trip!", "warning");
                } else {
                    this.getTripCreationForArticlesStocksDetailsList(this.lrDtoStocksDetails);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while validating the Trips!", "error")
            }, () => console.log('done');
    }
}

