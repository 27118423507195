<div mat-dialog-content style="max-width: 600px; max-height: 400px;">
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <!-- <div style="padding: 10px 0;">
                    <h5 style="font-weight: bold; margin: 0;">Rate Increase:</h5>
                </div> -->
                <div class="modal-body">
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Updating Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card-body" style="padding: 0;">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate Type<span class="mandatoryField_Style"> *
                                                    </span></label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}rateType" #rateType>
                                                    <option selected value="Discount">Discount</option>
                                                    <option value="Rate">Rate</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <table datatable id="{{pageId}}sourceForUpdateRateIncrease"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsSourceListForRateIncPopup"
                                            [dtTrigger]="dtTriggerSourceListForRateIncPopup">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Source<span class="mandatoryField_Style"> * </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody style="height: 40vh;">
                                                <tr
                                                    *ngFor="let sourceListDataForRateIncPopup of sourceListDataList; let i of index">
                                                    <td><input type="checkbox" [checked]="multiSelect"
                                                            id='{{sourceListDataForRateIncPopup.agentCode}}'
                                                            style="margin-left: 20px"
                                                            (change)="rowCheckBoxCheckedForRateIncPopup($event, sourceListDataForRateIncPopup)" />
                                                    </td>
                                                    <td>{{ sourceListDataForRateIncPopup.source }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    &nbsp;
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Increase Charge<span class="mandatoryField_Style"> *
                                                    </span></label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    aria-describedby="basic-addon11" id="{{pageId}}increaseChargeId"
                                                    name="increaseChargeId">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Effective From<span class="mandatoryField_Style"> *
                                                    </span></label> <input class="form-control" placeholder="yyyy-mm-dd" name="effectiveFromDate" ngbDatepicker #effectiveFromDate="ngbDatepicker" id="{{pageId}}effectiveFromDate">
                                                <div class="input-group-append" (click)="effectiveFromDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <button class="btn btn-primary" mat-button (click)="updateRateIncreasePopUpBtn();">Update</button>
    <button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>