import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard/classic',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
    },
    // master menu starts here
    {
        path: '/dashboard/classic',
        title: 'Masters',
        icon: 'mdi mdi-account',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            // master submenu starts here
            //            {
            //                path: '',
            //                title: 'Dashboard Popup Test',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // communication submenu starts here
            //                    {
            //                        path: '/dashboard/lrDetailsOfRemainingCashMemos',
            //                        title: 'Lr Details Of Remaining CashMemos',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/dashboard/editConsigneeDetails',
            //                        title: 'Edit Consignee Details',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/dashboard/pendingLrDetails',
            //                        title: 'Pending Lr Details',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/dashboard/lrDetails',
            //                        title: 'Lr Details',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/dashboard/labourHamaliStatementDetails',
            //                        title: 'Labour Hamali Statement Details',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // communication submenu ends here
            //            },
            /// below code is commented by basha
            //            {
            //                path: '',
            //                title: 'Communication',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // communication submenu starts here
            //                    {
            //                        path: '',
            //                        title: 'Message',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/communication/sendSmsGroupDetails',
            //                        title: 'Send SMS Group Details',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // communication submenu ends here
            //            },
                        {
                            path: '',
                            title: 'Company',
                            icon: 'mdi mdi-adjust',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
                                // company submenu starts here
            //                    {
            //                        path: '/masters/company/commonCharges',
            //                        title: 'Common Charges',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/masters/company/editCompany',
                                    title: 'Edit Company ',
                                    icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/masters/company/userCreation',
            //                        title: 'User Creation',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/company/expensesDetailsEntry',
            //                        title: 'Expenses Details Entry',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    //                    {
            //                    //                        path:  '/masters/company/staffMaster',
            //                    //                        title: 'Staff Master',
            //                    //                        icon: 'mdi mdi-adjust',
            //                    //                        class: '',
            //                    //                        ddclass: '',
            //                    //                        extralink: false,
            //                    //                        submenu: []
            //                    //                    },
            //                    {
            //                        path: '/masters/company/consignmentsClaimSettlementEntry',
            //                        title: 'Consignments Claim Settlement Entry',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/company/userFeaturesCustomization',
            //                        title: 'User Features Customization',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/company/sourceChargesSetup',
            //                        title: 'Source Charges Setup',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
                            ]
            //                // company submenu ends here
                        },
                        {
                            path: '',
                            title: 'Consignee',
                            icon: 'mdi mdi-adjust',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
                                // consignee submenu starts here
                                {
                                    path: '/masters/consignee/consigneeMaster',
                                    title: 'Consignee Master ',
                                    icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/masters/consignee/consigneeMerge',
            //                        title: 'Consignee Merge',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/consignee/rateMaster',
            //                        title: 'Rate Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/consignee/consigneeAdminRateMaster',
            //                        title: 'C/nee Admin Rate Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/consignee/consigneeGrouping',
            //                        title: 'C/nee Grouping',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
                            ]
            //                // consignee submenu ends here
                        },
            //            {
            //                path: '',
            //                title: 'Consignor',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // consignor submenu starts here
            //                    {
            //                        path: '/masters/consignor/consignorMaster',
            //                        title: 'Consignor Master ',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/consignor/consignorMerge',
            //                        title: 'Consignor Merge',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // consignor submenu ends here
            //            },
                        {
                            path: '',
                            title: 'Supplier ',
                            icon: 'mdi mdi-adjust',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
                                // supplier submenu starts here
                                {
                                    path: '/masters/supplier/suppliermaster',
                                    title: 'Supplier Master',
                                   icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
                            ]
            //                // supplier submenu ends here
                        },
                        {
                            path: '',
                            title: 'Transhipment',
                            icon: 'mdi mdi-adjust',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
                                // transhipment submenu starts here
                                {
                                    path: '/masters/transhipment/driverMaster',
                                    title: 'Driver Master',
                                    icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/masters/transhipment/vehicleSetupMaster',
            //                        title: 'Vehicle Setup Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/masters/transhipment/truckMaster',
                                    title: 'Truck Master',
                                    icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
//             {
            //                        path: '/masters/transhipment/agentTempoMaster',
            //                        title: 'Agent Tempo Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/transhipment/lorryHireDeductionSetupMaster',
            //                        title: 'Lorry Hire Deduction Setup',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/masters/transhipment/natureOfPack',
                                    title: 'Nature Of Pack',
                                    icon: 'mdi mdi-adjust',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/masters/transhipment/expensesPaymentFollowupReport',
            //                        title: 'Expenses Payment Followup',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/transhipment/trackingLiveReport',
            //                        title: 'Tracking Live Report',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    //                    {
            //                    //                        path: '/masters/transhipment/pointToPointServiceCheck',
            //                    //                        title: 'Point To Point Service Check',
            //                    //                        icon: 'mdi mdi-adjust',
            //                    //                        class: '',
            //                    //                        ddclass: '',
            //                    //                        extralink: false,
            //                    //                        submenu: []
            //                    //                    },
            //                    {
            //                        path: '/masters/transhipment/getHireslipBarcodeValue',
            //                        title: 'Get Hireslip Barcode Value',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                            ]
            //                // transhipment submenu ends here
                        },
            //            {
            //                path: '',
            //                title: 'Commodity',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // commodity submenu starts here
            //                    {
            //                        path: '/masters/commodity/commodityMaster',
            //                        title: 'Commodity Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/commodity/tpMaster',
            //                        title: 'TP Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/commodity/commodityMerge',
            //                        title: 'Commodity Merge',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // commodity submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Fixing',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Fixing submenu starts here
            //                    {
            //                        path: '/masters/fixing/bankMaster',
            //                        title: 'Bank Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/fixing/collectionMan',
            //                        title: 'Collection',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/fixing/debitNoteMaster',
            //                        title: 'Debit Note Master',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/fixing/expensesHeads',
            //                        title: 'Expenses Heads',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/masters/fixing/tdsDetailsEntry',
            //                        title: 'TDS Details Entry',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Fixing submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Trip',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Trip submenu starts here
            //                    {
            //                        path: '/masters/trip/time',
            //                        title: 'Time',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Trip submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Details',
            //                icon: 'mdi mdi-adjust',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // details submenu starts here
            //                    {
            //                        path: '/masters/details/agentSetup',
            //                        title: 'Agent Setup',
            //                        icon: 'mdi mdi-adjust',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //
            //                ]
            //                // details submenu ends here
            //            }
        ]
        // master submenu starts here
    },
    // master menu ends here
    // cashmemo starts here
    {
        path: '/dashboard/classic',
        title: 'CashMemo',
        icon: 'mdi mdi-cash-multiple',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            // cashmemo submenu starts here
            {
                path: '',
                title: 'Generation',
                icon: 'mdi mdi-email',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [
                    // Generation submenu starts here
                    /// below code is commented by basha
                    //                    {
                    //                        path: '/cashmemo/generation/dummyCashmemo',
                    //                        title: 'Dummy',
                    //                        icon: 'mdi mdi-email',
                    //                        class: '',
                    //                        ddclass: '',
                    //                        extralink: false,
                    //                        submenu: []
                    //                    },
                    //                    {
                    //                        path: '/cashmemo/generation/bookingCashmemo',
                    //                        title: 'Booking Cash Memo ',
                    //                        icon: 'mdi mdi-email',
                    //                        class: '',
                    //                        ddclass: '',
                    //                        extralink: false,
                    //                        submenu: []
                    //                    },
                    {
                        path: '/cashmemo/generation/generateCashmemo',
                        title: 'Generate Cash Memo ',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    }

                ]
                // Generation submenu ends here
            },
            {
                path: '',
                title: 'Transfer',
                icon: 'mdi mdi-email',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [
                    // Transfer submenu starts here

                    {
                        path: '/cashmemo/transfer-cashmemo/transferToCollection',
                        title: 'Transfer To Collection',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/cashmemo/transfer-cashmemo/cashmemoTransfer',
                        title: 'Cashmemo Transfer',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/cashmemo/transfer-cashmemo/billingToSrdCollectionStock',
                        //                        path: '',
                        title: 'Billing To SRD Collection Stock',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    },
                    /// below code is commented by basha
                    //                    {
                    //                        path: '/cashmemo/transfer-cashmemo/manualCashmemoCheck',
                    //                        title: 'Manual Cashmemo Check',
                    //                        icon: 'mdi mdi-email',
                    //                        class: '',
                    //                        ddclass: '',
                    //                        extralink: false,
                    //                        submenu: []
                    //                    },
                    //                    {
                    //                        path: '/cashmemo/transfer-cashmemo/collectionManCashmemoStockCheck',
                    //                        title: 'Collection Man Cashmemo Stock Check',
                    //                        icon: 'mdi mdi-email',
                    //                        class: '',
                    //                        ddclass: '',
                    //                        extralink: false,
                    //                        submenu: []
                    //                    },
                    {
                        path: '/cashmemo/transfer-cashmemo/collectionManStockTransfer',
                        title: 'Collection Man Stock Transfer',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/cashmemo/transfer-cashmemo/collectionManToCollectionManStockTransfer',
                        title: 'Collection Man To Collection Man Stock Transfer',
                        icon: 'mdi mdi-email',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    }

                ]
                // Transfer submenu ends here
            },//removed,coma here
            //Cashmemo Block Starts here
            /// below code is commented by basha
            //            {
            //                path: '/cashmemo/cashmemoBlock',
            //                title: 'Cashmemo Block',
            //                icon: 'mdi mdi-email',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            },
            //            //Cashmemo Block Ends Here
            //            //Payment Followup Starts here
                        {
                            path: '/cashmemo/paymentFollowup',
                            title: 'Payment Followup',
                            icon: 'mdi mdi-email',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: []
                        }//remove coma here
            //Payment Followup Ends Here
        ]
        // cashmemo submenu ends here
    },
    // cashmemo menu ends here
    // Fortnight Statement menu starts here
    {
        path: '/dashboard/classic',
        title: 'Fortnight Statement',
        icon: 'mdi mdi-widgets',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            // Fortnight Statement submenu starts here
            /// below code is commented by basha
            //            {
            //                path: '',
            //                title: 'Agent',
            //                icon: 'mdi mdi-equal',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Agent submenu starts here
            //                    {
            //                        path: '/fortnightStatement/agent/commissionmaster',
            //                        title: 'Commission Master',
            //                        icon: 'mdi mdi-message-bulleted',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/agent/amountReceivableFromAgent',
            //                        title: 'Amount Receivable From Agent',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/agent/commodityratemaster',
            //                        title: 'Commodity Rate Master',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/agent/destinationAgentStatementGeneration',
            //                        title: 'Destination Agent Statement Generation',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Agent submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Booking',
            //                icon: 'mdi mdi-message-bulleted',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    //                     Booking submenu starts here
            //                    {
            //                        path: '/fortnightStatement/booking/agentCommissionMaster',
            //                        title: 'Agent Commission Master',
            //                        icon: 'mdi mdi-message-bulleted',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/booking/agentStatementGeneration',
            //                        title: 'Agent Statement Generation',
            //                        icon: 'mdi mdi-message-bulleted',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Booking submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Others',
            //                icon: 'mdi mdi-view-carousel',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Others submenu ends here
            //                    {
            //                        path: '/fortnightStatement/others/partyLessCheckUploadType',
            //                        title: 'Party Less Check - Upload Type',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '',
            //                        title: 'Party Less Check',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/others/balancingDifferenceTallyReport',
            //                        title: 'Balancing Different Tally Report',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/others/debitNoteStmt',
            //                        title: 'Debit Note Stmt',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Others submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Hamali',
            //                icon: 'mdi mdi-view-carousel',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Hamali submenu ends here
            //                    {
            //                        path: '/fortnightStatement/hamali-fortnightStatement/srdlabourmaster',
            //                        title: 'SRD Labour Master',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/hamali-fortnightStatement/labourcommissionsetupmumbai',
            //                        title: 'Labour Commission Setup Mumbai',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/fortnightStatement/hamali-fortnightStatement/srdLabourStatementGeneration',
            //                        title: 'Srd Labour Statement Generation',
            //                        icon: 'mdi mdi-view-carousel',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Hamali submenu ends here
            //            },
        ]
        // Fortnight Statement submenu ends here
    },
    // Fortnight Statement menu ends here
    // Lr menu starts here
    {
        path: '/dashboard/classic',
        title: 'LR',
        icon: 'mdi mdi-collage',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            /// below code is commented by basha
            // LR submenu starts here
            //            {
            //                path: '',
            //                title: 'Edit',
            //                icon: 'mdi mdi-priority-low',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Edit submenu starts here
            //                    {
            //                        path: '/lr/edit/invoicelredit',
            //                        title: 'Invoice LR Edit',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/lr/edit/lredit',
            //                        title: 'LR Edit',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/lr/edit/lrsShortAndExtra',
            //                        title: 'LRs Short & Extra',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Edit submenu ends here
            //            },
                        {
                            path: '',
                            title: 'Enquiry',
                            icon: 'mdi mdi-rounded-corner',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
            //                    // Enquiry submenu starts here
            //                    {
            //                        path: '/lr/enquiry/lrAdvance',
            //                        title: 'LR Advance',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/lr/enquiry/lrEnquiry',
                                    title: 'LR Enquiry Form',
                                    icon: 'mdi mdi-priority-low',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                }
//                                ,remove coma
            //                    {
            //                        path: '/lr/enquiry/consigneeDetails',
            //                        title: 'Consignee Details',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/lr/enquiry/lrDeliveryStatus',
            //                        title: 'LR Delivery Status',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
                           ,{
                                path: '/lr/enquiry/gstSearch',
                                title: 'GST Search',
                                icon: 'mdi mdi-priority-low',
                                class: '',
                                ddclass: '',
                                extralink: false,
                                submenu: []
                            }
                            ]
                            // Enquiry submenu ends here
            },
            //
            //            {
            //                path: '',
            //                title: 'Party Report',
            //                icon: 'mdi mdi-shape-plus',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    //Party Report submenu starts here
            //                    {
            //
            //                        path: '/report/party-report/partyLessReport',
            //                        title: 'Party Less',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/party-report/partyWiseOsReport',
            //                        title: 'Party Wise OS',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/party-report/partyWiseTotalStatusDetails',
            //                        title: 'Party Wise Total Status Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/party-report/commodityMismatchReport',
            //                        title: 'Commodity Mismatch Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/party-report/consigneeAdminRateReport',
            //                        title: 'Consignee Admin Rate Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/party-report/rateHistoryReport',
            //                        title: 'Consignee Rate History',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/party-report/adminBusinessRateReport',
            //                        title: 'Consignee Admin Business Rate Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/party-report/consigneeRateReport',
            //                        title: 'Consignee Rate Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/party-report/discontinuousDetailsReport',
            //                        title: 'Discontinuous Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/party-report/regularConsigneeReport',
            //                        title: 'Regular Consignee Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                //Party Report submenu ends here
            //            },
            {
                path: '',
                title: 'Entry',
                icon: 'mdi mdi-select-all',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [
                    //                     Entry submenu starts here
                    {
                        path: '/lr/entry/lrentry',
                        title: 'LR Entry Form',
                        icon: 'mdi mdi-priority-low',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: []
                    }//removed,coma here
                    //                    {
                    //                        path: '/lr/entry/missAgent',
                    //                        title: 'Miss Agent',
                    //                        icon: 'mdi mdi-priority-low',
                    //                        class: '',
                    //                        ddclass: '',
                    //                        extralink: false,
                    //                        submenu: []
                    //                    },
                ]
                // Entry submenu ends here
            }//removed , coma here
            //            {
            //                path: '',
            //                title: 'Issue',
            //                icon: 'mdi mdi-shape-plus',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Issue submenu starts here
            //                    {
            //                        path: '/lr/issue/challan',
            //                        title: 'Challan',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //                        path: '/lr/issue/lrissue',
            //                        title: 'LR Issue',
            //                        icon: 'mdi mdi-priority-low',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                ]
            //                // Issue submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'LR Report',
            //                icon: 'mdi mdi-shape-plus',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    //LR Report submenu starts here
            //                    {
            //
            //                        path: '/report/lr-report/delayedESugam',
            //                        title: 'Delayed E-Sugam',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/lorryHireBalance',
            //                        title: 'Lorry Hire Balance',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/lrPerformanceReport',
            //                        title: 'Performance',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/lrRpt',
            //                        title: 'LR Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/gcNotReceivedReport',
            //                        title: 'G.C. Not Received',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
        ]
        //LR Report submenu ends here
    },
    {
                    path: '',
                    title: 'Others Report',
                    icon: 'mdi mdi-shape-plus',
                    class: '',
                    ddclass: '',
                    extralink: false,
                    submenu: [
                        //Others Report submenu starts here
    //                    //                          {
    //                    //                        
    //                    //                        path: '/report/others-report/salesTaxReport',
    //                    //                        title: 'Sales Tax Report',
    //                    //                        icon: 'mdi mdi-cart',
    //                    //                        class: '',
    //                    //                        ddclass: '',
    //                    //                        extralink: false,
    //                    //                        submenu: []
    //                    //                    },
    //                    {
    //
    //                        path: '/report/others-report/driverRewardedReport',
    //                        title: 'Driver Rewarded Report',
    //                        icon: 'mdi mdi-cart',
    //                        class: '',
    //                        ddclass: '',
    //                        extralink: false,
    //                        submenu: []
    //                    },
    //                    {
    //                        path: '/report/others-report/tempoPerformanceReport',
    //                        title: 'Tempo Performance Report',
    //                        icon: 'mdi mdi-cart',
    //                        class: '',
    //                        ddclass: '',
    //                        extralink: false,
    //                        submenu: []
    //                    },
    //                    {
    //                        path: '/report/others-report/vehicleTracking',
    //                        title: 'Vehicle Tracking',
    //                        icon: 'mdi mdi-cart',
    //                        class: '',
    //                        ddclass: '',
    //                        extralink: false,
    //                        submenu: []
    //                    },
                        {
                            path: '/report/others-report/chequeSearchReport',
                            title: 'Cheque Search Details',
                            icon: 'mdi mdi-cart',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: []
                        },
                        {
                            path: '/report/others-report/chequeDetailsReport',
                            title: 'Cheque Details',
                            icon: 'mdi mdi-cart',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: []
                        }, {
                            path: '/report/others-report/partywiseCFTDetailsReport',
                            title: 'Partywise CFT Details Report',
                            icon: 'mdi mdi-cart',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: []
                        },
    //
                    ]
    //                //Others Report submenu ends here
                },
    //
    //            {
    //                path: '',
    //                title: 'Truck Report',
    //                icon: 'mdi mdi-cart',
    //                class: '',
    //                ddclass: '',
    //                extralink: false,
    //                submenu: [
    //                    // Truck submenu starts here
    //                    {
    //                        path: '/report/truck-report/nonAvailabilityOfTruckReport',
    //                        title: 'Non Availability Of Truck',
    //                        icon: 'mdi mdi-border-none',
    //                        class: '',
    //                        ddclass: '',
    //                        extralink: false,
    //                        submenu: []
    //                    },
    //
    //                    // Truck submenu ends here
    //                ]
    //            },
                {
                    path: '',
                    title: 'Cashmemo Report',
                    icon: 'mdi mdi-cart',
                    class: '',
                    ddclass: '',
                    extralink: false,
                    submenu: [
                        // Cashmemo submenu starts here
                        {
                            path: '/report/cashmemo-report/cashMemoInCollectionManStock',
                           title: 'Cash Memo In Collection Man Stock',
                            icon: 'mdi mdi-cart',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: []
                        }
                        // Cashmemo submenu ends here
                    ]
                },
           // ]
    // LR submenu ends here
        //},
    // LR menu ends here
    // Mobile Apps menu starts here
    {
        path: '/dashboard/classic',
        title: 'Mobile Apps',
        icon: 'mdi mdi-border-none',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            /// below code is commented by basha
            //            {
            //                path: '/mobileApps/aboutus',
            //                title: 'About Us',
            //                icon: 'mdi mdi-border-none',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            },
            //            {
            //                path: '/mobileApps/latestnews',
            //                title: 'Latest News',
            //                icon: 'mdi mdi-border-left',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            },
            //            {
            //                path: '',
            //                title: 'Mobile App User Report',
            //                icon: 'mdi mdi-border-vertical',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            },
            //            {
            //                path: '',
            //                title: 'Our Branches',
            //                icon: 'mdi mdi-border-vertical',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            },
            //            {
            //                path: '',
            //                title: 'Route Mapping',
            //                icon: 'mdi mdi-border-vertical',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: []
            //            }
        ]
    },
    // Mobile Apps menu ends here
    // Stocks menu starts here
    {
        path: '/dashboard/classic',
        title: 'Stocks',
        icon: 'mdi mdi-file',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            /// below code is commented by basha
            //            {
            //                path: '',
            //                title: 'Customization',
            //                icon: 'mdi mdi-cards-variant',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Customization submenu starts here
            //                    {
            //                        path: '/stock/customization/manualStockCheck',
            //                        title: 'Manual Stock Check',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/trip-creation/stocksGrouping',
            //                        title: 'Stocks Grouping',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Customization submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Driver',
            //                icon: 'mdi mdi-cards-variant',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Driver submenu starts here
            //                    {
            //                        path: '/stock/driver/rewarding',
            //                        title: 'Rewarding',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Driver submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Payments',
            //                icon: 'mdi mdi-cards-variant',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Payment submenu starts here
            //                    {
            //                        path: '/stock/payment/hireslipAdvance',
            //                        title: 'Hireslip Advance',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/stock/payment/hireslipBalance',
            //                        title: 'Hireslip Balance',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Payment submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Transfer',
            //                icon: 'mdi mdi-cards-variant',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Transfer submenu starts here
            //                    {
            //                        path: '/stock/transfer/shipmentstatus',
            //                        title: 'Shipment Status',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/stock/transfer/stocksToCp',
            //                        title: 'Stocks To Cp',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/stock/transfer/stocksBetweenGodown',
            //                        title: 'Stocks Between Godown',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/stocks-for-tripsheet-page/stocksForTripsheet',
            //                        title: 'Stocks For Tripsheet',
            //                        icon: 'mdi mdi-blur',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Transfer submenu ends here
            //            }
        ]
    },
    // Stocks menu ends here
    // Reports menu starts here
    {
        path: '/dashboard/classic',
        title: 'Reports',
        icon: 'mdi mdi-file',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [
            /// below code is commented by basha
            //            {
            //                path: '',
            //                title: 'Agents',
            //                icon: 'mdi mdi-cards-variant',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Agent submenu starts here
            //                    {
            //                        path: '/report/agents/statementVerification',
            //                        title: 'Statement Verification',
            //                        icon: 'mdi mdi-cards-variant',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/agents/destinationCommission',
            //                        title: 'Destination Commission',
            //                        icon: 'mdi mdi-cards-variant',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/agents/destinationIncome',
            //                        title: 'Destination Income',
            //                        icon: 'mdi mdi-cards-variant',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/agents/ranking',
            //                        title: 'Ranking',
            //                        icon: 'mdi mdi-cards-variant',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Agent submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Booking',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Booking submenu starts here
            //                    {
            //
            //                        path: '/report/bookings/agentExpenses',
            //                        title: 'Agent Express',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/bookings/bookingAgentReport',
            //                        title: 'Booking Agent Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/bookings/memo',
            //                        title: 'Memo',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/bookings/officeHireslip',
            //                        title: 'Office Hireslip',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/bookings/memoInvoice',
            //                        title: 'Memo Invoice',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/bookings/agentStatement',
            //                        title: 'Agent Statement',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/bookings/agentLocalTrip',
            //                        title: 'Agent Local Trip',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Booking submenu ends here
            //            },
                        {
                            path: '',
                            title: 'Cashmemo',
                            icon: 'mdi mdi-cart',
                            class: '',
                            ddclass: '',
                            extralink: false,
                            submenu: [
                                // Cashmemo submenu starts here
            //                    {
            //                        path: '/report/cashmemo-report/amountreceivable',
            //                        title: 'Amount Receivable',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/cashmemo-report/tobebilledparty',
            //                        title: 'To Be Billed Party',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/cashmemo-report/paidreport',
            //                        title: 'Paid Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/report/cashmemo-report/cashmemoreport',
                                    title: 'Cash Memo Report',
                                    icon: 'mdi mdi-cart',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/report/cashmemo-report/bookingcashmemo',
            //                        title: 'Booking Cashmemo',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/cashmemo-report/bookingcashmemobyinvoice',
            //                        title: 'Booking Cashmemo By Invoice',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                                {
                                    path: '/report/cashmemo-report/cashmemoDetails',
                                    title: 'Cashmemo Details',
                                    icon: 'mdi mdi-cart',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            //                    {
            //                        path: '/report/cashmemo-report/letterHeadReport',
            //                        title: 'LetterHead Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/cashmemo-report/collectionManReport',
            //                        title: 'Collection Man',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
                               {
                                    path: '/report/cashmemo-report/srdCollectionStockReport',
                                    title: 'SRD Collection Stock Report',
                                    icon: 'mdi mdi-cart',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                  submenu: []
                               },
                                {
                                    path: '/report/cashmemo-report/memoLessSearchReport',
                                    title: 'Memo Less Details',
                                    icon: 'mdi mdi-cart',
                                    class: '',
                                    ddclass: '',
                                    extralink: false,
                                    submenu: []
                                },
            
                            ]
            //                // Cashmemo submenu ends here
                        },
            //            {
            //                path: '',
            //                title: 'Daily',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Daily submenu starts here
            //                    {
            //
            //                        path: '/report/daily-report/dailyBookingReport',
            //                        title: 'Daily Booking',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/daily-report/officeReport',
            //                        title: 'Office Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/daily-report/bangaloreOffice',
            //                        title: 'Bangalore Office',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/daily-report/doorDelivery',
            //                        title: 'Door Delivery',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Daily submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Hamali',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Hamali submenu starts here
            //                    {
            //                        path: '/report/hamali-report/brokerreport',
            //                        title: 'Broker Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //                        path: '/report/hamali-report/labourStatementReport',
            //                        title: 'Labour Statement Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Hamali submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Hireslip/Invoice',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Hireslip/Invoice submenu starts here but for third submenu its were not working so we put it by split the menu
            //                    //the below is comment for third submenu it has to research so put on hold Starts 
            //                    //                    {
            //                    //                        path: '',
            //                    //                        title: 'Invoice',
            //                    //                        icon: 'mdi mdi-cart',
            //                    //                        class: '',
            //                    //                        ddclass: '',
            //                    //                        extralink: false,
            //                    //                        submenu: [
            //                    //                            //Invoice submenu Starts 
            //                    //                            {
            //                    //                                path: '/report/hireslip-and-invoice-report/invoiceDetails',
            //                    //                                title: 'Invice Details',
            //                    //                                icon: 'mdi mdi-cart',
            //                    //                                class: '',
            //                    //                                ddclass: '',
            //                    //                                extralink: false,
            //                    //                                submenu: []
            //                    //                            },
            //                    //                            //Invoice submenu ends
            //                    //
            //                    //                        ]
            //                    //                    },
            //                    //the below is comment for third submenu it has to research so put on hold Endss
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/invoiceDetails',
            //                        title: 'Invoice Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/hireslipDetails',
            //                        title: 'Hireslip Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/advancePayment',
            //                        title: 'Advance Payment',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/hireslipUnloadingReport',
            //                        title: 'Hireslip Unloading Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/transhipmentTrip',
            //                        title: 'Transhipment Trip',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/hireslipHistoryReport',
            //                        title: 'Hireslip History Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/hireslip-and-invoice-report/localTripSheet',
            //                        title: 'Local Trip Sheet',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Hireslip/Invoice submenu ends here
            //            },
            //
            //            {
            //                path: '',
            //                title: 'Lr',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // LR submenu starts here
            //                    {
            //                        path: '/report/lr-report/issueBalance',
            //                        title: 'Issue Balance',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //                        path: '/report/lr-report/lrIssueReport',
            //                        title: 'LR Issue Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //                        path: '/report/lr-report/challanIssueReport',
            //                        title: 'Challan Issue',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //                        path: '/report/lr-report/shortAndExtraReport',
            //                        title: 'Short And Extra',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/lr-report/lrDispatchBookingReport',
            //                        title: 'LR Dispatch Booking Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/automatedSearch',
            //                        title: 'Automated Search',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/lr-report/shortAndExtraConflict',
            //                        title: 'Short And Extra Conflict',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/lr-report/lrHistoryReport',
            //                        title: 'Lr History Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/lr-report/lorryHireStatement',
            //                        title: 'Lorry Hire Statement',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //
            //                ]
            //                // LR submenu ends here
            //            },
            //
            //
            //            {
            //                path: '',
            //                title: 'Debit',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // debit submenu starts here
            //                    {
            //
            //                        path: '/report/debit/summary',
            //                        title: 'Summary',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/debit/debitNoteReport',
            //                        title: 'Debit Note Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // debit submenu ends here
            //            },
            //
            //            {
            //                path: '',
            //                title: 'Stocks',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Stocks submenu starts here
            //                    {
            //                        path: '/report/stocks-report/stocksInTakenReport',
            //                        title: 'Stocks In Taken Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/commodityReport',
            //                        title: 'Commodity Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/godownStocksBookingReport',
            //                        title: 'Godown Stocks Booking',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/godownStocksReport',
            //                        title: 'Godown Stocks',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/pendingStocksDetailsReport',
            //                        title: 'Pending Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/stocksUnloadingReport',
            //                        title: 'Stocks Unloading Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/stocks-report/storageReport',
            //                        title: 'Storage Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Stocks submenu ends here
            //            },
            //
            //            {
            //                path: '',
            //                title: 'Truck',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Truck submenu starts here
            //                    {
            //
            //                        path: '/report/truck-report/hireDetailsByDestinationReport',
            //                        title: 'Hire Details By Destination',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/truck-report/performanceReport',
            //                        title: 'Performance Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/truck-report/truckReport',
            //                        title: 'Truck Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/truck-report/truckOwnerDeclarationReport',
            //                        title: 'Truck Owner Declaration Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/truck-report/haltAndDispatchedDetails',
            //                        title: 'Halt And Dispatched Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //
            //                ]
            //                // Truck submenu ends here
            //            }, {
            //                path: '',
            //                title: 'Party',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Party submenu starts here
            //                    {
            //
            //                        path: '/report/party-report/consignorReport',
            //                        title: 'Consignor',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/party-report/consigneeReport',
            //                        title: 'Consignee Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/party-report/businessStopReport',
            //                        title: 'Business Stop Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }, {
            //
            //                        path: '/report/party-report/businessStartReport',
            //                        title: 'Business Start Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Party submenu ends here
            //            },
            //
            //            {
            //                path: '',
            //                title: 'Other',
            //                icon: 'mdi mdi-cart',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    // Others submenu starts here
            //                    {
            //                        path: '/report/others-report/lcAndBcReport',
            //                        title: 'Lc And Bc Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/others-report/loaderPerformanceReport',
            //                        title: 'Loader Performance Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //                        path: '/report/others-report/monthlyReport',
            //                        title: 'Monthly Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                // Others submenu ends here
            //            },
            //            {
            //                path: '',
            //                title: 'Statement',
            //                icon: 'mdi mdi-shape-plus',
            //                class: '',
            //                ddclass: '',
            //                extralink: false,
            //                submenu: [
            //                    //Statement Report submenu starts here
            //
            //                    {
            //
            //                        path: '/report/statement-reports/statementRpt',
            //                        title: 'Statement Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/statement-reports/partyLessCheckedAndUncheckedLrDetails',
            //                        title: 'Party Less Checked And Unchecked Lr Details',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    },
            //                    {
            //
            //                        path: '/report/statement-reports/invoiceReport',
            //                        title: 'Invoice Report',
            //                        icon: 'mdi mdi-cart',
            //                        class: '',
            //                        ddclass: '',
            //                        extralink: false,
            //                        submenu: []
            //                    }
            //                ]
            //                //Statement Report submenu ends here
            //            }
        ]
    },
    // Reports menu ends here
];
