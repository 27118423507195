import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends


import { TruckReportRoutes } from 'src/app/report/truck-report/truck-report.routing';
import { HireDetailsByDestinationComponent } from 'src/app/report/truck-report/hire-details-by-destination/hire-details-by-destination.component';
import { PerformanceReportComponent } from 'src/app/report/truck-report/performance-report/performance-report.component';
import { TruckReportComponent } from 'src/app/report/truck-report/truck-rpt/truck-rpt.component';
import { TruckOwnerDeclarationReportComponent } from 'src/app/report/truck-report/truck-owner-declaration-report/truck-owner-declaration-report.component';$
import { HaltAndDispatchedDetailsComponent } from 'src/app/report/truck-report/halt-and-dispatched-details/halt-and-dispatched-details.component';
import { NonAvailabilityOfTruckReportComponent } from 'src/app/report/truck-report/non-availability-of-truck/non-availability-of-truck-report.component';
import { MaterialModule } from "src/app/material.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(TruckReportRoutes), 
        FormsModule,  NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule,CashmemoReportModule],
    declarations: [
HireDetailsByDestinationComponent,
PerformanceReportComponent,
TruckReportComponent,
TruckOwnerDeclarationReportComponent,
HaltAndDispatchedDetailsComponent,
NonAvailabilityOfTruckReportComponent,

    ]
})
export class TruckReportModule { }