import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for datepicker starts
const my = new Date();
//for datepicker ends
import { DataTableDirective } from "angular-datatables";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge,Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
  selector: 'app-srd-labour-master',
  templateUrl: './srd-labour-master.component.html',
  styleUrls: ['./srd-labour-master.component.css']
})
export class SrdLabourMasterComponent implements OnInit {
    
    getDataFrmServiceFrTable: any;
srdLabourDataList: any;
onDestroyUnsubscribtionSrdLabour: Subscription;
    
  addNewLoaderName = false;
  model: NgbDateStruct;
  date: { year: number; month: number };

  loadingIndicator = true;
  
  @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

  dtTriggerSrdLabour: Subject<any> = new Subject();
  dataTable: any;
  dtOptionsSrdLabour: any;
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  showSpinnerForAction = false;
  hireSlipDtoLabourCommission: HireSlipDto = new HireSlipDto();
  hireSlipDtoCommissionSave: HireSlipDto = new HireSlipDto();
  selectedLoaderHead:any;
  ldgHamali:any;
  ldgHamUnit:any;
  unLdgHamali:any;
  unLdgHamUnit:any;
  commsId:any;
  pageId="slms";
  labourHeadNameOption: Array<HireSlipDto> = [];
  hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
  public modelDropDownLabourHeadName: any;
  labourHeadNameTA: Array<HireSlipDto> = [];
  focusDropDownLabourHeadNameTA$ = new Subject<string>();
  searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

      return merge(debouncedText$, inputFocus$).pipe(
          map(term => (term === '' ? this.labourHeadNameTA
              : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
      );
  }
  formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

  constructor( private router: Router, private masterReadService: MasterReadService,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef ) {

	  if (sessionStorage.length == 0) {
          this.isLoggedIn = false;
          swal({
              title: "Session Expired",
              text: "Please relogin to access the application!",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
          }).then(() => {
              this.logInPage();
          })
          //sweet alert ends
      }
      if (this.isLoggedIn) {
          // userdata get through from login starts
          this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          setTimeout(() => {
          	 this.setLabourHeadNameDetailsList();
          	 this.getLoaderHamaliCommsDetails();
          }, 1000);
         
      }
  }

  ngOnInit(): void {

      this.dtOptionsSrdLabour = {
          //};
          //columns is used to for export and others starts
        


          //columns is used to for export and others endss


          // the below code is for button export starts
          dom: 'Bfrtip',
          /*buttons: [
                    'excel', 'print'
                ],*/
          buttons: [
//              {
//                  extend: 'excel',
//                  text: '<i class="fas fa-file-excel"> Excel</i>',
//                  titleAttr: 'Excel',
//                  exportOptions: {
//                      columns: ':visible'
//                  }
//              },
//              {
//                  extend: 'print',
//                  text: '<i class="fas fa-print"> Print</i>',
//                  titleAttr: 'Print',
//
//              }


          ],

          // the below code is for button export ends


          //place holder in search/filter in datatable starts
          language: {
              search: "_INPUT_",
              searchPlaceholder: "Search..."
          },
          //place holder in search/filter in datatable ends
          processing: true,
          //scroll in datatable starts
          responsive: true,
          "scrollX": true,
          "scrollY": 380,
          "scrollCollapse": true,
          //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
          "paging": false,
          "info": false,
          //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
          //scroll in datatable ends

      }


  }

  ngAfterViewInit(): void {
      this.dtTriggerSrdLabour.next();
  }
  
  ngOnDestroy(): void {
      this.dtTriggerSrdLabour.unsubscribe();
  }
  loaderNameMethod(loaderValue: string) {
    if (loaderValue === 'addNew') {
      this.addNewLoaderName = true;
    } else {
      this.addNewLoaderName = false;
    }
  }
  
  getLabourHeadNameDetails() {
      this.hireSlipDtoLabourHeadName = new HireSlipDto();
      this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainAdminStation;
      this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
  }

  setLabourHeadNameDetailsList() {
      this.getLabourHeadNameDetails();
      this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
          (response) => {
              console.log("loader : "+response);
              if (response.length > 0) {
                  this.labourHeadNameOption = [];
                  this.labourHeadNameTA = [];
                  this.labourHeadNameOption = response;
                  for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                      this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                  }
              } else {
                  this.labourHeadNameOption = [];
                  this.labourHeadNameTA = [];
              }
              this.changeDetectorRef.detectChanges();
          }), (error) => {
              swal("Server Error", "Problem occur while getting Loader Heads details", "error");
          },
          () => console.log('done');
  }
  
  getLoaderHamaliCommsDetailsUserValues() {
		this.hireSlipDtoLabourCommission = new HireSlipDto();
		this.hireSlipDtoLabourCommission.status = "InUse";
		this.hireSlipDtoLabourCommission.mode = "Status";
	}
  getLoaderHamaliCommsDetails = () => {
		this.showSpinnerForAction = true;
		this.getLoaderHamaliCommsDetailsUserValues();
		this.destStmtReport.getLoaderHamaliCommsDetails(this.hireSlipDtoLabourCommission).subscribe(
			(response) => {
				$("#"+this.pageId+"labourCommissionTableId").DataTable().destroy();
				this.srdLabourDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.srdLabourDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerSrdLabour.next();
	            }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Commission Details", "info");
			}, () => console.log('done');
	};
	
	validateSave() {
		//alert($("#"+this.pageId+"dropDownInputFieldLabourHeadName").val()+'-'+$("#"+this.pageId+"loadingHamali").val()+'-'+$("#"+this.pageId+"unitLoading").val()+'-'+$("#"+this.pageId+"unloadingHamali").val()+'-'+$("#"+this.pageId+"unitUnloading").val());
		if ($("#"+this.pageId+"dropDownInputFieldLabourHeadName").val() == null|| $("#"+this.pageId+"dropDownInputFieldLabourHeadName").val() == undefined ||
	            $("#"+this.pageId+"dropDownInputFieldLabourHeadName").val() == ""|| $("#"+this.pageId+"loadingHamali").val() == null ||
	            $("#"+this.pageId+"loadingHamali").val() == undefined || $("#"+this.pageId+"loadingHamali").val() == ""||$("#"+this.pageId+"unitLoading").val() == null 
	            || $("#"+this.pageId+"unitLoading").val() == undefined ||
	            $("#"+this.pageId+"unitLoading").val() == ""|| $("#"+this.pageId+"unloadingHamali").val() == null ||
	            $("#"+this.pageId+"unloadingHamali").val() == undefined || $("#"+this.pageId+"unloadingHamali").val() == ""|| $("#"+this.pageId+"unitUnloading").val() == null ||
	            $("#"+this.pageId+"unitUnloading").val() == undefined || $("#"+this.pageId+"unitUnloading").val() == ""){
			swal("Mandatory Field", "Please select the Mandatory Field", "warning");
            return false;
		}else {
            this.addCommissionDetails();
        }
    }
	
	addCommissionUserValues() {
		this.selectedLoaderHead=$("#"+this.pageId+"dropDownInputFieldLabourHeadName").val();
		this.ldgHamali=$("#"+this.pageId+"loadingHamali").val();
		this.ldgHamUnit=$("#"+this.pageId+"unitLoading").val();
		this.unLdgHamali=$("#"+this.pageId+"unloadingHamali").val();
		this.unLdgHamUnit=$("#"+this.pageId+"unitUnloading").val();
		
		this.hireSlipDtoCommissionSave = new HireSlipDto();
		this.hireSlipDtoCommissionSave.loaderHead = this.selectedLoaderHead;
		this.hireSlipDtoCommissionSave.loadingHamali = this.ldgHamali;
		this.hireSlipDtoCommissionSave.loadingHamaliUnit = this.ldgHamUnit;
		this.hireSlipDtoCommissionSave.unloadingHamali = this.unLdgHamali;
		this.hireSlipDtoCommissionSave.unloadingHamaliUnit = this.unLdgHamUnit;
		this.hireSlipDtoCommissionSave.commsId = this.commsId;
		this.hireSlipDtoCommissionSave.branch = this.userDataDtoReturnSession.mainStation;
		this.hireSlipDtoCommissionSave.lastupdatedby = this.userDataDtoReturnSession.userId;
	}
	addCommissionDetails = () => {
		this.showSpinnerForAction = true;
		this.addCommissionUserValues();
		this.destStmtReport.addLoaderHamaliCommsDetails(this.hireSlipDtoCommissionSave).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == "Failed") {
					swal("Error", "Problem occured while adding the Commission details!", "error");

				}else if (response.status == "Success") {
					swal("Completed", "Commission details added/updated successfully!", "info");
					this.clearFields();
				this.getLoaderHamaliCommsDetails();
				} 
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occured while adding the Commission details", "error");
			}, () => console.log('done');
	};
	
	
	
	selectedRowDetails(srdLabourData) {
		this.clearFields();
		$("#"+this.pageId+"dropDownInputFieldLabourHeadName").val(srdLabourData.loaderHead);
		$("#"+this.pageId+"loadingHamali").val(srdLabourData.loadingHamali);
		$("#"+this.pageId+"unitLoading").val(srdLabourData.loadingHamaliUnit);
		$("#"+this.pageId+"unloadingHamali").val(srdLabourData.unloadingHamali);
		$("#"+this.pageId+"unitUnloading").val(srdLabourData.unloadingHamaliUnit);
		this.commsId=srdLabourData.commsId;
		//alert(this.commsId);
    }
	
	clearFields(){
		this.selectedLoaderHead=null;
		this.ldgHamali=null;
		this.ldgHamUnit=null;
		this.unLdgHamali=null;
		this.unLdgHamUnit=null;
		this.commsId=null;
		
		$("#"+this.pageId+"dropDownInputFieldLabourHeadName").val('');
		$("#"+this.pageId+"loadingHamali").val('');
		$("#"+this.pageId+"unitLoading").val('');
		$("#"+this.pageId+"unloadingHamali").val('');
		$("#"+this.pageId+"unitUnloading").val('');
	}

  logInPage() {
      this.router.navigate(['/authentication/login']);
  }

}
