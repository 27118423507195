<!-- Row -->
<div class="row system_responsive">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">About Us Details</h6>
			</div>
			<div class="row" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-2"></div>
							<!-- <h6 class="card-title">Tempo Details</h6> -->
							<div class="col-md-8">
								<div class="row">
									<div class="col-md-2"></div>
									<div class="col-sm-12 col-md-8" style="text-align: center;">
										<div class="form-group">
											<label>Title</label>
											<div class="input-group">

												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
												</div>
												<input type="text" id="{{pageId}}title" class="form-control"
													aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
									<div class="col-md-2"></div>
									<div class="col-sm-12 col-md-12" style="text-align: center;">
										<div class="form-group">
											<div class="form-group">
												<label>Message</label>
												<div class="form-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-envelope"></i>
													</span>
												</div>
												<textarea class="form-control" id="{{pageId}}message"
													rows="14"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-2"></div>
						</div>
					</div>
				</div>
				<hr style="width: 80%; border-top: none; margin: 3px;">
				<div class="col-md-12" style="text-align: center;">
					<button type="submit" class="btn btn-success m-r-10"
						id="{{pageId}}saveBtn" (click)="confirmSave();">Save</button>
				</div>

				<div class="col-md-12" style="text-align: center;">
					<button type="submit" class="btn btn-success m-r-10"
						id="{{pageId}}erpcrmBtn" (click)="confirmErpCrmFlow();">ERP-CRM</button>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>