<!-- Row -->
<div class="row">

    <div class="col-lg-12">


        <div class="card " style="border: 1px solid darkcyan !important;">
            <hr style="margin: 0px;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">

                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-road"></i>
													</span>
                                                </div>
                                                <input #destinationPopUpId id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestinationList($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                    (focus)="focusDestinationTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Destination.." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showAmtFieldOnEdit" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Amount</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}amount" autocomplete="off" readonly>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Receiving Date</label> <input class="form-control" id="{{pageId}}receivingDate"
												placeholder="yyyy-mm-dd" name="receivingDate" ngbDatepicker
												[(ngModel)]="modelReceviDates" #receivingDate="ngbDatepicker"
												autocomplete="off">
											<div class="input-group-append" (click)="receivingDate.toggle()">
												<span class="input-group-text"> <i class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>-->

                            </div>
                        </div>
                        <!-- <hr> -->
                    </div>
                </div>
                <div class="col-md-6 vl p-t-10">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="card-title">Payment Mode</h6>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Receiving Date</label> <input class="form-control" id="{{pageId}}receivingDate" placeholder="yyyy-mm-dd" name="receivingDate" ngbDatepicker [(ngModel)]="modelReceviDates" #receivingDate="ngbDatepicker"
                                                            autocomplete="off">
                                                        <div class="input-group-append" (click)="receivingDate.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}payemntModeType" #payemntModeType (change)="payemntModeTypeListner(payemntModeType.value)">
														<option selected value="Cash">Cash
														</option>
														<option value="Cheque">Cheque</option>
														<option value="PDC">PDC</option>
													</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcNoField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}chequePdcNo" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForChequeNo($event);">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequeDueDateField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Due Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="chequeDueDates" ngbDatepicker #chequeDueDates="ngbDatepicker" id="{{pageId}}chequeDueDates" [(ngModel)]="modelChequeDueDates">
                                                        <div class="input-group-append" (click)="chequeDueDates.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                            </div>
                                                            <input id="{{pageId}}bankNameId" type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForBankName($event);" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="viewCashChequePdcAmtField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}cashChequePdcAmount" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForAmount($event);">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <button type="submit" class="btn btn-dark m-r-10" title="Clear Bank Details" id="{{pageId}}clearBAnkBtnId" style='float:right;' (click)="clearBankDetailsInTable();">Clear
											</button>&nbsp;&nbsp;
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}addBankBtnId" style='float:right;' title="Add Bank Details" (click)="addBankDetailsInTable();">Add
											</button>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <table datatable id="{{pageId}}amountReceivableForAgentStmtCashChequePdcTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAmountReceivableForAgentStmtCashChequePdc" [dtTrigger]="dtTriggerAmountReceivableForAgentStmtCashChequePdc">
                                                <thead>
                                                    <tr>
                                                        <th>Payment Type</th>
                                                        <th>Cheque No</th>
                                                        <th>Amount</th>
                                                        <th>Bank Name</th>
                                                        <th>Due Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let amountReceivableForAgentStmtCashChequePdcData of 
													amountReceivableForAgentStmtCashChequePdcDataList let i = index">
                                                        <td>
                                                            {{ amountReceivableForAgentStmtCashChequePdcData.paymentType }}
                                                        </td>
                                                        <td>{{ amountReceivableForAgentStmtCashChequePdcData.chequeNumber }}
                                                        </td>
                                                        <td>{{ amountReceivableForAgentStmtCashChequePdcData.amount }}
                                                        </td>
                                                        <td>{{ amountReceivableForAgentStmtCashChequePdcData.bankName }}
                                                        </td>
                                                        <td>{{ amountReceivableForAgentStmtCashChequePdcData.pdcDueDate }}
                                                        </td>
                                                        <td>
                                                            <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Details" (click)="rowSelectedBankDetailsDataOnEdit(amountReceivableForAgentStmtCashChequePdcData,i);"></i> &nbsp;&nbsp;
                                                            <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Details" (click)="deleteSelectedBankDetails(i);"></i>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 vl p-t-10">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="card-title">Summary</h6>
                                    <div class="row">

                                        <div *ngIf="showAmtFieldOnEdit" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Total Amount To Be Received</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}totalAmountToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Cash</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}summaryCashAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Cheque Amount</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}summaryChequeAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>PDC Amount</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}summaryPDCAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showAmtFieldOnEdit" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Balance To Be Received</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}balanceToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <button type="submit" class="btn btn-dark m-r-10" style='float:right;' title="Clear Details" (click)="overAllClearFieldBtn();">Clear
											</button> &nbsp;&nbsp;
                                            <button *ngIf="showSaveBtn" type="submit" class="btn btn-success m-r-10" title="Confirm Payment Details" style='float:right;' (click)="validateSavePaymentBtn();">Save
											</button>
                                            <button *ngIf="showUpdateBtn" type="submit" class="btn btn-success m-r-10" title="Update Payment Details" style='float:right;' (click)="validateUpdatePaymentBtn();">Update
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>