<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" id="{{pageId}}hireSlipUnloadingReportId" *ngIf="isLoggedIn">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Hireslip Unloading Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="hireslipNumberWise">Hireslip
																Number</option>
															<option value="destinationWise">Destination</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="searchByHireslipNo" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Hireslip Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															id="{{pageId}}hireslipNumber" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>


											<div *ngIf="searchByDestination" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="searchByDestination" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															[(ngModel)]="toDateModel" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="searchByDestination" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input id="{{pageId}}destination" type="text" class="form-control"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearMethod();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<button type="submit" class="dt-button" style="margin-left: 90%;"
								(click)="customPrintHireSlipUnloadRpt()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}hireslipUnloadingRptId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsHireslipUnloadingRpt"
														[dtTrigger]="dtTriggerHireslipUnloadingRpt">

														<thead>
															<tr>
																<th>Hireslip No</th>
																<th>Hireslip Date</th>
																<th>Booking Station</th>
																<th>Total Articles</th>
																<th>Gurantee Weight</th>
																<th>Kanta Weight</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let hireslipUnloadingRptData of hireslipUnloadingRptDataList ">
																<td><a style="color: red; cursor: pointer;"
																	(click)="unloadingSheetDetailsPopUpBtnLink(unloadingSheetDetailsPopUpTemplate,hireslipUnloadingRptData)">
																		<u>{{ hireslipUnloadingRptData.hireslipnumber }}</u>
																</a></td>
																<td>{{ hireslipUnloadingRptData.hireSlipDateStr }}</td>
																<td>{{ hireslipUnloadingRptData.fromStation }}</td>
																<td>{{ hireslipUnloadingRptData.totalArticles }}</td>
																<td>{{ hireslipUnloadingRptData.gurWeight }}</td>
																<td>{{ hireslipUnloadingRptData.kantaWeight }}</td>
															</tr>
														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>

						</div>
					</div>



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #unloadingSheetDetailsPopUpTemplate let-ok="close"
					let-d="dismiss"> <!--<div class="modal-header">
						<h5>Memo Less Description :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>-->
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-8"></div>
									<div class="col-sm-12 col-md-4">
										<button style="float: right;" type="button"
											class="btn btn-dark" id="{{pageId}}printBtnId"
											(click)="unloadingRptPopupGrp()">Print</button>
										<button style="float: right;" type="button"
											class="btn btn-dark" id="{{pageId}}cancelBtnId"
											(click)="closeUnloadingSheetDetailsPopUp()">Cancel</button>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div *ngIf="viewUnloadingSheetDetailsPopUp"
											onafterPopUp="afterPopUp()"
											id="{{pageId}}popupUnloadingSheetDetailsRpt">
											<app-hireslip-unloading-sheet-details-report>
											</app-hireslip-unloading-sheet-details-report>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</ng-template>
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>

	<div *ngIf="viewUnloadingRptPopupGrpPrint" onafterprint="afterPrint()"
		id="{{pageId}}viewUnloadingRptPopupGrpPrintId">
		<app-hireslip-unloading-sheet-details-report-print></app-hireslip-unloading-sheet-details-report-print>
		>
	</div>
</body>

</html>