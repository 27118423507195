import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

import { MasterReadService } from 'src/app/dataService/masterread-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { LrService } from 'src/app/dataService/lr-service';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';

@Component({
    selector: 'app-broker-report',
    templateUrl: './broker-report.component.html',
    styleUrls: ['./broker-report.component.css']
})
export class BrokerReportComponent implements OnInit {

    loadingIndicator = true;
    groupByHireslipDateRows = [];

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBrokerDetailsRptHireslipDateDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsBrokerDetailsRptHireslipDateDetails: any;
    brokerReportDataList: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction = false;
    showSpinnerForAction1 = true;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    sourceOptions: AgentDetailsDto[];
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;

    isDestView: boolean = false;
    isSrcView: boolean = false;

    viewSourceField = false;
    viewDestinationField = false;

    listOfSource: string[];
    listOfSourceSelect: string[];

    hireslipDtoForSearch: HireSlipDto = new HireSlipDto();
    hireslipDtoForPrint: HireSlipDto = new HireSlipDto();
    hireslipDtoForPrintReturn: any;
    validateSelectedFromDate: any;
    validateSelectedToDate: any;
    validateSelectedToStation: any;

    viewCustomPrintV1 = false;

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;
    pageId="brkrc";

    constructor(private agentStmtGenerationRpt: ReportService, private router: Router,
        private mastereadService: MasterReadService, private memoReport: MemoReport,
        private lrService: LrService, private consigneeService: ConsigneeService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BroRpt srcAll") {
                        this.isSrcView = true;
                        this.viewSourceField = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BroRpt destView") {
                        this.isDestView = true;
                        this.viewDestinationField = true;
                    }
                }
            }

            if (this.isSrcView) {
                this.getSourceMethod();
            } else {
                this.getSourceList();
            }

            if (this.isDestView) {
                this.getDestinationDetails();
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit() {

        this.dtOptionsBrokerDetailsRptHireslipDateDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel All</i>',
                    titleAttr: 'Summary Export',
                    title: function () {
                        return "Broker Report - " +
                            " Source: " + $("#brkrcsource").val() + " -  " +
                            "Destination : " + $("#brkrcdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel Difference Lrs</i>',
                    titleAttr: 'Details Export',
                    title: function () {
                        return "Broker Report - " +
                            " Source: " + $("#brkrcsource").val() + " -  " +
                            "Destination : " + $("#brkrcdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                },
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var countNoOfTruck = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalHireAmt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(countNoOfTruck);
                $(api.column(4).footer()).html(totalHireAmt);
            }
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerBrokerDetailsRptHireslipDateDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerBrokerDetailsRptHireslipDateDetails.next();
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.mode = "LrForm";
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;

    }
    getDestinationDetails = () => {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;

                    this.lrDtoDestinationAll = new LRDto();
                    this.lrDtoDestinationAll.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.destinationTA = [];
                    this.lrDtoDestinationAll = new LRDto();
                    this.lrDtoDestinationAll.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAll);
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();				   
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };


    clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#"+this.pageId+"source").val(this.modelSource.subStation);
    }

    getSourceList() {
        this.sourceTA = [];
        this.listOfSource = [];
        console.log(this.userDataDtoReturnSession.stationList);
        this.listOfSource.push("All");
        this.listOfSource.push(this.userDataDtoReturnSession.mainStation);
        for (let i = 0; i < this.userDataDtoReturnSession.stationList.length; i++) {
            this.sourceTA.push(this.userDataDtoReturnSession.stationList[i]);
            this.listOfSource.push(this.userDataDtoReturnSession.stationList[i]);
        }
        console.log(this.sourceTA);
        console.log(this.listOfSource);
    }

    getSourceDetails() {
        this.sourceAgentDto = new AgentDetailsDto();
        this.sourceAgentDto.mode = "Sub";
        this.sourceAgentDto.status = "ALL";
        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getSourceMethod = () => {
        this.getSourceDetails();
        this.showSpinnerForAction = true;
        this.mastereadService.getSourceListDetails(this.sourceAgentDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.sourceOptions = [];
                    this.sourceTA = [];
                    this.listOfSource = [];
                    this.sourceAgentDtoAll = new AgentDetailsDto();
                    this.sourceAgentDtoAll.subStation = "All";
                    this.sourceTA.push(this.sourceAgentDtoAll);
                    this.listOfSource.push(this.sourceAgentDtoAll.subStation);
                } else {
                    this.sourceOptions = [];
                    this.sourceTA = [];
                    this.listOfSource = [];
                    this.sourceOptions = response;
                    this.sourceAgentDtoAll = new AgentDetailsDto();
                    this.sourceAgentDtoAll.subStation = "All";
                    this.sourceTA.push(this.sourceAgentDtoAll);
                    for (let i = 0; i < this.sourceOptions.length; i++) {
                        this.sourceTA.push(this.sourceOptions[i]);
                        this.listOfSource.push(this.sourceOptions[i].subStation);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log("done");
    };

    validateSearchBtn() {
        this.getBrokerTotalReport();
    }

    getBrokerTotalReport() {
        this.hireslipDtoForSearch = new HireSlipDto();
        this.listOfSourceSelect = [];
        this.validateSelectedFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateSelectedToDate = $("#"+this.pageId+"toDates").val();
        this.validateSelectedToStation = $("#"+this.pageId+"destinationId").val();

        if (this.isSrcView) {
            if ($("#"+this.pageId+"source").val() != null && $("#"+this.pageId+"source").val() != undefined &&
                $("#"+this.pageId+"source").val() != "" && $("#"+this.pageId+"source").val() == "All") {
                this.listOfSourceSelect = this.listOfSource;
            } else {
                for (let i = 0; i < this.listOfSource.length; i++) {
                    if ($("#"+this.pageId+"source").val() == this.listOfSource[i]) {
                        this.listOfSourceSelect.push(this.listOfSource[i]);
                        break;
                    }
                }
            }
        } else {
            this.listOfSourceSelect = this.listOfSource;
        }

        this.hireslipDtoForSearch.fromDate = this.validateSelectedFromDate;
        this.hireslipDtoForSearch.toDate = this.validateSelectedToDate;
        this.hireslipDtoForSearch.listOfSource = this.listOfSourceSelect;
        if (this.isDestView) {
            this.hireslipDtoForSearch.toStation = this.validateSelectedToStation;
        } else {
            this.hireslipDtoForSearch.toStation = this.userDataDtoReturnSession.mainStation;
        }

        this.hireslipDtoForSearch.companyid = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getBrokerReport(this.hireslipDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.brokerReportDataList = [];
                $("#"+this.pageId+"brokerRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.brokerReportDataList = response;
                } else {
                    swal("Empty", "No datas found for this information for group by destination", "warning");
                }
                this.dtTriggerBrokerDetailsRptHireslipDateDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();				
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Broker Report Details", "info");
            },
            () => console.log("done");
    }

    clearBtn() {
        this.clearField();
    }
    clearField() {
        this.modelDestination = null;
        this.modelSource = null;

        this.validateSelectedFromDate = null;
        $("#"+this.pageId+"fromDates").val('');
        this.validateSelectedToDate = null;
        $("#"+this.pageId+"toDates").val('');
        this.validateSelectedToStation = null;
        $("#"+this.pageId+"destinationId").val('');
        $("#"+this.pageId+"source").val('');

        this.listOfSourceSelect = [];

        this.brokerReportDataList = [];
        $("#"+this.pageId+"brokerRptTableId").DataTable().destroy();
        this.dtTriggerBrokerDetailsRptHireslipDateDetails.next();
    }

    validateSummaryPrintBtn() {
        this.hireslipDtoForPrint = new HireSlipDto();
        this.listOfSourceSelect = [];
        this.validateSelectedFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateSelectedToDate = $("#"+this.pageId+"toDates").val();
        this.validateSelectedToStation = $("#"+this.pageId+"destinationId").val();

        if (this.isSrcView) {
            if ($("#"+this.pageId+"source").val() != null && $("#"+this.pageId+"source").val() != undefined &&
                $("#"+this.pageId+"source").val() != "" && $("#"+this.pageId+"source").val() == "All") {
                this.listOfSourceSelect = this.listOfSource;
            } else {
                for (let i = 0; i < this.listOfSource.length; i++) {
                    if ($("#"+this.pageId+"source").val() == this.listOfSource[i]) {
                        this.listOfSourceSelect.push(this.listOfSource[i]);
                        break;
                    }
                }
            }
        } else {
            this.listOfSourceSelect = this.listOfSource;
        }

        this.hireslipDtoForPrint.fromDate = this.validateSelectedFromDate;
        this.hireslipDtoForPrint.toDate = this.validateSelectedToDate;
        this.hireslipDtoForPrint.listOfSource = this.listOfSourceSelect;
        if (this.isDestView) {
            this.hireslipDtoForPrint.toStation = this.validateSelectedToStation;
        } else {
            this.hireslipDtoForPrint.toStation = this.userDataDtoReturnSession.mainStation;
        }
        this.hireslipDtoForPrint.fromStation = this.userDataDtoReturnSession.mainStation;
        this.hireslipDtoForPrint.companyid = this.userDataDtoReturnSession.companyId;
        this.hireslipDtoForPrint.mode = "Summary";
        this.showSpinnerForAction = true;
        this.lrService.getAvgBrokerReport(this.hireslipDtoForPrint).subscribe(
            (response) => {
                console.log(response);
                this.hireslipDtoForPrintReturn = [];
                this.hireslipDtoForPrintReturn = response;
                this.setAvgBrokerRptPrintCustomize();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();				
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Avg Broker Report Details", "info");
            },
            () => console.log("done");
    }

    setAvgBrokerRptPrintCustomize() {
        if (this.hireslipDtoForPrintReturn.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Supplier Name", "To Station",
                "Total Trucks", "Average", "Truck Type"];
            //this.cashMemoDtoForCustomPrintListColumnWidths = [13, 8, 15, 8, 6, 5, 5, 5, 3, 5, 5,13,9];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < this.hireslipDtoForPrintReturn.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.hireslipDtoForPrintReturn[i].suppliername,
                    this.hireslipDtoForPrintReturn[i].toStation,
                    this.hireslipDtoForPrintReturn[i].countTrucks,
                    this.hireslipDtoForPrintReturn[i].totalhire,
                    this.hireslipDtoForPrintReturn[i].truckType
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                //this.summary1 = this.summary1 + this.hireslipDtoForPrintReturn[i].totalLrs;
                //this.summary2 = this.summary2 + this.hireslipDtoForPrintReturn[i].totallrsfromTripSheet;
                //this.summary3 = this.summary3 + this.hireslipDtoForPrintReturn[i].totalInvoice;

            }

            this.cashMemoDtoForCustomPrintDataSummaryList = [
                "Total : " + this.hireslipDtoForPrintReturn.length,
                "", "", "", ""];

            //heading logics For Date
            //this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
            //this.toDatePrint = $("#"+this.pageId+"toDates").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            if (this.isDestView) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Source", "Destination"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#"+this.pageId+"source").val(), $("#"+this.pageId+"destinationId").val()];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Source"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#"+this.pageId+"source").val()];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Broker Summary Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}
