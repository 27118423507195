import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef,ChangeDetectorRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component({
  selector: 'app-short-and-extra-conflict',
  templateUrl: './short-and-extra-conflict.component.html',
  styleUrls: ['./short-and-extra-conflict.component.css']
})
export class ShortAndExtraConflictComponent implements OnInit {


    getDataFrmServiceFrTable: any;

    shortAndExtraConflictDataList: any;

    onDestroyUnsubscribtionShortAndExtraConflict: Subscription;



    //summaryTable:any;
    
    loadingIndicator = true;

    

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerShortAndExtraConflict: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsShortAndExtraConflict: any;

	pageId="snecc";

    constructor( private shortAndExtraConflictRpt: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        this.dtOptionsShortAndExtraConflict = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'LR Number',
                    data: 'lrNumber'
                },
                {
                    title: 'invoice No.',
                    data: 'invoiceNo'
                },
                {
                    title: 'Status',
                    data: 'status'
                },
                {
                    title: 'Src Article',
                    data: 'srcArticle'
                },
                {
                    title: 'Src User',
                    data: 'srcuser'
                },
                {
                    title: 'Src Office',
                    data: 'srcOffice'
                }, {
                    title: 'Dest Article',
                    data: 'destArticle'
                }, {
                    title: 'Dest User',
                    data: 'destUser'
                },{
                    title: 'Dest Office',
                    data: 'destOffice'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends





        }








        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.shortAndExtraConflictRpt.getSummaryData()
        this.onDestroyUnsubscribtionShortAndExtraConflict = this.getDataFrmServiceFrTable.subscribe( data => {
            this.shortAndExtraConflictDataList = data['data'];
            this.dtTriggerShortAndExtraConflict.next();
        } );



        //the below code is for the getting data through json ends



    }




    ngOnDestroy(): void {
        this.dtTriggerShortAndExtraConflict.unsubscribe();

        this.onDestroyUnsubscribtionShortAndExtraConflict.unsubscribe();
    }





   
}
