import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";

@Component({
	selector: 'app-stocks-destination-summary',
	templateUrl: './stocks-destination-summary.component.html',
	styleUrls: ['./stocks-destination-summary.component.css']
})
export class StocksDestinationSummaryComponent implements OnInit {

	stocksDestinationSummaryDataList: any;
	dtTriggerStocksDestinationSummary: Subject<any> = new Subject();
	dtOptionsStocksDestinationSummary: any;
	address: any;
	office: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	lrDtoForStocksSum: LRDto = new LRDto();
	lrDtoForWithMainStation: LRDto = new LRDto();
	lrDtoForWithMainStationRet: LRDto = new LRDto();
	lrDtoForWithOutMainStation: LRDto = new LRDto();
	lrDtoForWithOutMainStationRet: LRDto = new LRDto();
	lrDtoForWithOutMainStationRetForPopup: LRDto = new LRDto();
	lrDtoForWithMainStationRetForPopup: LRDto = new LRDto();
	responseForWithoutMainStation: any;
	responseForWithMainStation; any;
	showColumnPrint = 'LrShortExtraPrt SourceGroup';
	selectedRowMainStation: any;
	// for print starts
	viewCustomPrintV1 = false;
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintDataSummaryList: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	title: any;
	// for print ends

	pageId = "stsmc";
	reportMode = null;

	constructor(private dashboardService: DashboardService, public dialog: MatDialog,
		private router: Router, public changeDetectorRef: ChangeDetectorRef
	) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.office;
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"LrShortExtraPrt SourceGroup") {
						// this.showPrint = true;
					}

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"StocksSummary SrcSpecf") {
						this.reportMode = 'SrcSpecf';
					}

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"StocksSummary SrcSpecfGrp") {
						this.reportMode = 'SrcSpecfGrp';
					}
				}
			}
			//here we dont need rights in this page
			/* if(this.showColumn=='LrShortExtraPrt SourceGroup'){
				 this.showPrint = true;
			 }else{
				 this.showPrint = false;
			 }*/
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	ngOnInit(): void {

		this.dtOptionsStocksDestinationSummary = {
			dom: 'Bfrtip',
			// language: {
			//     search: "_INPUT_",
			//     searchPlaceholder: "Search..."
			// },
			buttons: [
			],
			searching: false,
			pagingType: 'full_numbers',
			processing: true,
			"scrollX": true,
			"scrollY": 170,
			"scrollCollapse": true,
			"paging": false,
			"info": true
			// destroy:true
		}
	}

	// ngOnDestroy(): void {
	//     this.dtTriggerStocksDestinationSummary.unsubscribe();

	// }
	ngAfterViewInit(): void {
		this.dtTriggerStocksDestinationSummary.next();
	}
	gridReconifgureOnReloadBtn() {

		this.lrDtoForStocksSum = new LRDto();
		this.lrDtoForStocksSum.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForStocksSum.OfficeType = this.userDataDtoReturnSession.office;
		this.lrDtoForStocksSum.branchType = this.userDataDtoReturnSession.officeType;
		this.lrDtoForStocksSum.rights = null;
		this.lrDtoForStocksSum.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForStocksSum.listOfStation = this.userDataDtoReturnSession.stationList;
		this.lrDtoForStocksSum.mode = this.reportMode;
		// this.lrDtoForStocksSum.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForStocksSum.branch = 'Bakoli';
		console.log(this.lrDtoForStocksSum);
		this.gridReconifgureDetails();
	}
	gridReconifgureDetails = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockDestinationSummaryDetails(this.lrDtoForStocksSum).subscribe(
			//this.dashboardService.getAgentStockSummaryDetailsV2(this.lrDtoForStocksSum).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				//$("#"+this.pageId+"stocksDestinationSummaryId").DataTable().destroy();
				this.stocksDestinationSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.stocksDestinationSummaryDataList = response;
					console.log(this.stocksDestinationSummaryDataList);
				}
				//   this.dtTriggerStocksDestinationSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Stocks Summary Details", "info");
			}, () => console.log('done');
	};

	getAgentInfo(stocksDestinationSummaryData) {


		this.redirectToLRDetailsWithoutMainStationPage(stocksDestinationSummaryData);


	}

	redirectToLRDetailsWithoutMainStationPage(stocksDestinationSummaryData) {
		this.lrDtoForWithOutMainStationRetForPopup = new LRDto();
		//For Agent StockReport other Details 
		this.lrDtoForWithOutMainStationRetForPopup.stocksAt = stocksDestinationSummaryData.stocksAt;
		this.lrDtoForWithOutMainStationRetForPopup.destination = stocksDestinationSummaryData.destination;
		this.lrDtoForWithOutMainStationRetForPopup.rights = null;
		this.lrDtoForWithOutMainStationRetForPopup.mainstation = stocksDestinationSummaryData.mainstation;
		this.lrDtoForWithOutMainStationRetForPopup.status = 'DestinationWise';
		this.lrDtoForWithOutMainStationRetForPopup.mode = 'Dashboard';
		console.log(this.lrDtoForWithOutMainStationRetForPopup.mainstation);
		localStorage.clear();
		localStorage.setItem('stocksSummaryWithoutMainStationForPopup', JSON.stringify(this.lrDtoForWithOutMainStationRetForPopup));
		this.router.navigate(['/dashboard/agentStockReport']);
	}

	

	getAgentDetailsWithMainStation(stocksDestinationSummaryData) {
		this.lrDtoForWithMainStation = new LRDto();
		this.lrDtoForWithMainStation.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForWithMainStation.stocksAt = stocksDestinationSummaryData.stocksAt;
		this.lrDtoForWithMainStation.rights = null;
		this.lrDtoForWithMainStation.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForWithMainStation);
		this.getAgentDetailsWithMainStationInfo(stocksDestinationSummaryData);
		this.lrDtoForWithMainStation.stationType = this.userDataDtoReturnSession.officeType;
	}
	getAgentDetailsWithMainStationInfo = (stocksDestinationSummaryData) => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockReportDetailsGrpMainSrc(this.lrDtoForWithMainStation).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {

					this.responseForWithMainStation = response;
					//For Agent StockReport Data Table
					this.lrDtoForWithMainStationRet = new LRDto();
					this.lrDtoForWithMainStationRet = this.responseForWithMainStation;
					console.log(this.lrDtoForWithMainStationRet);
					//For Agent StockReport other Details 
					this.lrDtoForWithMainStationRetForPopup = new LRDto();
					this.lrDtoForWithMainStationRetForPopup.stocksAt = stocksDestinationSummaryData.stocksAt;
					this.lrDtoForWithMainStationRetForPopup.rights = null;
					this.lrDtoForWithMainStationRetForPopup.mainstation = stocksDestinationSummaryData.mainstation;
					this.lrDtoForWithMainStationRetForPopup.status = 'Main';
					console.log(this.lrDtoForWithMainStationRetForPopup.mainstation);
					localStorage.clear();
					localStorage.setItem('stocksDestinationSummaryWithoutMainStationForPopup',
						JSON.stringify(this.lrDtoForWithMainStationRetForPopup));
					localStorage.setItem('stocksDestinationSummaryWithoutMainStationOtherPopup',
						JSON.stringify(this.lrDtoForWithMainStationRet));
					this.router.navigate(['/dashboard/agentStockReport']);
					//   const dialogRef = this.dialog.open( AgentStockReportComponent, {

					// } );
					// dialogRef.afterClosed().subscribe(result => {
					// 	console.log('The dialog was closed');

					// 	let printValue = localStorage.getItem('printNow');
					// 	console.log("printValue >> ");
					// 	console.log(printValue);
					// 	if (printValue === "Print For SS"){
					// 		this.cashMemoDtoForCustomPrintList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintDataList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintDataListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintDataSummaryList = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintDataSummaryListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintListHeadingV1 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV1ForSS' ) );
					// 	this.title = localStorage.getItem('printTitleForSS');
					// 	console.log("this.cashMemoDtoForCustomPrintList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintList);
					// 	console.log("this.cashMemoDtoForCustomPrintDataList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintDataList);
					// 	console.log("this.cashMemoDtoForCustomPrintDataSummaryList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintDataSummaryList);
					// 	console.log("this.cashMemoDtoForCustomPrintListHeadingV1 >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintListHeadingV1);
					// 	console.log("this.title >> ");
					// 	console.log(this.title);
					// 	localStorage.clear();
					// 	localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
					// 	localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
					// 	localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
					// 	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
					// 	localStorage.setItem('printTitle', this.title );
					// 	this.viewCustomPrintV1 = true;
					// 	window.addEventListener('afterprint', (onclick) => {
					// 		if (this.viewCustomPrintV1) {
					// 			this.viewCustomPrintV1 = false;
					// 			localStorage.clear();

					// 		}
					// 	});
					// 	}

					// 	this.changeDetectorRef.detectChanges();
					// });

					// dialogRef.afterClosed().subscribe( result => {
					// 	console.log( 'The dialog was closed' );
					// 	this.router.navigate( ['.'], { relativeTo: this.route } );
					// } );


				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Stocks  Details", "info");
			}, () => console.log('done');
	};

	getAgentDetailsWithOutMainStation(stocksDestinationSummaryData) {
		this.lrDtoForWithOutMainStation = new LRDto();
		this.lrDtoForWithOutMainStation.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForWithOutMainStation.stocksAt = stocksDestinationSummaryData.stocksAt;
		this.lrDtoForWithOutMainStation.rights = null;
		this.lrDtoForWithOutMainStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForWithOutMainStation.stationType = this.userDataDtoReturnSession.officeType;
		console.log(this.lrDtoForWithOutMainStation);
		this.getAgentDetailsWithOutMainStationInfo(stocksDestinationSummaryData);
	}
	getAgentDetailsWithOutMainStationInfo = (stocksDestinationSummaryData) => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockReportDetails(this.lrDtoForWithOutMainStation).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.responseForWithoutMainStation = response;
					this.lrDtoForWithOutMainStationRet = new LRDto();
					//For Agent StockReport Data Table
					this.lrDtoForWithOutMainStationRet = this.responseForWithoutMainStation;
					console.log(this.lrDtoForWithOutMainStationRet);
					this.lrDtoForWithOutMainStationRetForPopup = new LRDto();
					//For Agent StockReport other Details 
					this.lrDtoForWithOutMainStationRetForPopup.stocksAt = stocksDestinationSummaryData.stocksAt;
					this.lrDtoForWithOutMainStationRetForPopup.rights = null;
					this.lrDtoForWithOutMainStationRetForPopup.mainstation = stocksDestinationSummaryData.mainstation;
					this.lrDtoForWithMainStationRetForPopup.status = 'Local';
					console.log(this.lrDtoForWithOutMainStationRetForPopup.mainstation);
					localStorage.clear();
					localStorage.setItem('stocksDestinationSummaryWithoutMainStationForPopup',
						JSON.stringify(this.lrDtoForWithOutMainStationRetForPopup));
					localStorage.setItem('stocksDestinationSummaryWithoutMainStationOtherPopup',
						JSON.stringify(this.lrDtoForWithOutMainStationRet));

					//   const dialogRef = this.dialog.open( AgentStockReportComponent, {

					// } );
					this.router.navigate(['/dashboard/agentStockReport']);
					// dialogRef.afterClosed().subscribe(result => {
					// 	console.log('The dialog was closed');

					// 	let printValue = localStorage.getItem('printNow');
					// 	console.log("printValue >> ");
					// 	console.log(printValue);
					// 	if (printValue === "Print For SS"){
					// 		this.cashMemoDtoForCustomPrintList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintDataList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintDataListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintDataSummaryList = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintDataSummaryListForSS' ) );
					// 	this.cashMemoDtoForCustomPrintListHeadingV1 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV1ForSS' ) );
					// 	this.title = localStorage.getItem('printTitleForSS');
					// 	console.log("this.cashMemoDtoForCustomPrintList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintList);
					// 	console.log("this.cashMemoDtoForCustomPrintDataList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintDataList);
					// 	console.log("this.cashMemoDtoForCustomPrintDataSummaryList >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintDataSummaryList);
					// 	console.log("this.cashMemoDtoForCustomPrintListHeadingV1 >> ");
					// 	console.log(this.cashMemoDtoForCustomPrintListHeadingV1);
					// 	console.log("this.title >> ");
					// 	console.log(this.title);
					// 	localStorage.clear();
					// 	localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
					// 	localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
					// 	localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
					// 	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
					// 	localStorage.setItem('printTitle', this.title );
					// 	this.viewCustomPrintV1 = true;
					// 	window.addEventListener('afterprint', (onclick) => {
					// 		if (this.viewCustomPrintV1) {
					// 			this.viewCustomPrintV1 = false;
					// 			localStorage.clear();

					// 		}
					// 	});
					// 	}

					// 	this.changeDetectorRef.detectChanges();
					// });

					// dialogRef.afterClosed().subscribe( result => {
					// 	console.log( 'The dialog was closed' );
					// 	this.router.navigate( ['.'], { relativeTo: this.route } );
					// } );

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Stocks  Details", "info");
			}, () => console.log('done');
	};


}
