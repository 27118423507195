import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";


@Injectable({
  providedIn: 'root'
})
export class SuplierService {
    
    //private _url = "./assets/data/supplier-master.json";
    
  constructor(
    private http: HttpClient,
  ) {
  }

  getAllData(): Observable<SupplierModel[]> {
    //return this.http.get<SupplierModel[]>(this._url);
    return this.http.get<SupplierModel[]>('./assets/data/supplier-master.json');
    

  }
    
 

}

