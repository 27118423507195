//Chg_GNEWB_1 : dummyCompanyName comments on GST portal Api service for get Null exception (24-08-2024)
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { DatePipe } from '@angular/common';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { Subject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-generate-new-ewb',
    templateUrl: './generate-new-ewb.component.html',
    styleUrls: ['./generate-new-ewb.component.css']
})
export class GenerateNewEWBComponent implements OnInit {
    isLoggedIn = true;
    userDataDtoReturnSession: any;

    generateNewEWBForm = true;
    pageId = "gnewb";
    enteredGstNo: any;
    showSpinnerForAction = false;
    gstNumber: any;
    consignorStatus: any;
    consignorAddress1: any;
    consignorAddress2: any;
    consignorPincode: any;
    consignorStateCode: any;
    consignorShowStateCode: boolean = false;
    consignorLegalName: any;
    lrtDtoRespForGstNo: any;
    responseDtoForGstApi: ResponseDto = new ResponseDto();
    consignorTradeName: any;

    consigneeStatus: any;
    consigneeAddress1: any;
    consigneeAddress2: any;
    consigneePincode: any;
    consigneeStateCode: any;
    consigneeShowStateCode: boolean = false;
    consigneeLegalName: any;
    consigneeTradeName: any;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];

    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
    lrtDtoForSave: LRDto = new LRDto();
    totArticles: any;
    selectedNewCommodity: any;
    enteredSubCommodity: any;
    enteredGoodsValue: any;
    goodsValue = 0.0;
    enteredBillNumber: any;
    enteredBillDate: any;
    selectedconsignorGSTNumber: any;
    selectedconsigneeGSTNumber: any;
    selectedCommonCommodity: any;
    showSpinner = false;
    lrDtoSaveRet: any;
    enteredHSNCode: any;
    enteredCgstPerc: any;
    enteredSgstPerc: any;
    enteredCessPerc: any;
    enteredIgstPerc: any;
    enteredCgstAmt: any;
    enteredSgstAmt: any;
    enteredIgstAmt: any;
    enteredCessAmt: any;
    enteredReason: any;
    enteredNonAdvolCessPerc: any;
    enteredNonAdvolCessAmt: any;
    enteredOtherInvAmount: any;
    constructor(private router: Router, public dialog: MatDialog,
        public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService,
        private ewaybillService: EwaybillService, private datePipe: DatePipe) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getCommodityMasterDetailsList();
        }

    }

    ngOnInit() {
        console.log("ngOnInit....");
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateForGstNo(type, e) {
        if (e.keyCode == 13) {
            if (type != null && type == 'consignee') {
                this.enteredGstNo = $("#" + this.pageId + "consigneeGstNumber").val();
            } else if (type != null && type == 'consignor') {
                this.enteredGstNo = $("#" + this.pageId + "consignorGstNumber").val();
            }
            if (this.enteredGstNo == null || this.enteredGstNo == '' || this.enteredGstNo == undefined) {
                swal({
                    title: "Mandatory Fields",
                    text: "Please Enter Gst Number!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                })
            } else {
                this.getDetailsFromEwayBillSearchByGstNoMethod(type);
            }
        }
    }
    setGstNumber(type) {
        this.responseDtoForGstApi = new ResponseDto();
        if (type != null && type == 'consignee') {
            this.enteredGstNo = $("#" + this.pageId + "consigneeGstNumber").val();
        } else if (type != null && type == 'consignor') {
            this.enteredGstNo = $("#" + this.pageId + "consignorGstNumber").val();
        }

        this.responseDtoForGstApi.token1 = this.enteredGstNo;
    }
    getDetailsFromEwayBillSearchByGstNoMethod(type) {
        this.setGstNumber(type);
        this.showSpinnerForAction = true;
        this.ewaybillService.getDetailsFromEwayBillSearchByGstNo(this.responseDtoForGstApi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoRespForGstNo = response;
                console.log("lrtDtoRespForGstNo");
                console.log(this.lrtDtoRespForGstNo);
                if (this.lrtDtoRespForGstNo.respStatus == 1) {

                    this.gstNumber = this.lrtDtoRespForGstNo.gstNoConsignee;
                    if (type != null && type == 'consignee') {
                        this.consigneeShowStateCode = true;
                        //Chg_GNEWB_1
                        // this.consigneeLegalName = this.lrtDtoRespForGstNo.dummyCompanyName
                        this.consigneeLegalName = this.lrtDtoRespForGstNo.name

                        this.consigneeTradeName = this.lrtDtoRespForGstNo.name;
                        this.consigneeStatus = this.lrtDtoRespForGstNo.status;
                        this.consigneeAddress1 = this.lrtDtoRespForGstNo.address1;
                        this.consigneeAddress2 = this.lrtDtoRespForGstNo.address2;
                        this.consigneePincode = this.lrtDtoRespForGstNo.pinCode;
                        this.consigneeStateCode = this.lrtDtoRespForGstNo.fromState;
                    } else if (type != null && type == 'consignor') {
                        this.consignorShowStateCode = true;
                        //Chg_GNEWB_1
                        // this.consignorLegalName = this.lrtDtoRespForGstNo.dummyCompanyName
                        this.consignorLegalName = this.lrtDtoRespForGstNo.name

                        this.consignorTradeName = this.lrtDtoRespForGstNo.name;
                        this.consignorStatus = this.lrtDtoRespForGstNo.status;
                        this.consignorAddress1 = this.lrtDtoRespForGstNo.address1;
                        this.consignorAddress2 = this.lrtDtoRespForGstNo.address2;
                        this.consignorPincode = this.lrtDtoRespForGstNo.pinCode;
                        this.consignorStateCode = this.lrtDtoRespForGstNo.fromState;
                    }

                } else {
                    // this.clearFields();
                    swal({
                        title: "Warning",
                        text: "No details found for the given GST Number!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details from the Gst Number", "warning");
            }, () => console.log('done');
    }

    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
        //this.validateCommodityListValue = this.modelCommodityList.commodityName
    }

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.destination = null;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.status = "Working";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;

                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        // $("#" + this.pageId + "commodityListId").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    ///For Eway bill
    validateEwayBillGenerate() {
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        this.selectedNewCommodity = $("#" + this.pageId + "commodityListId").val();
        this.enteredSubCommodity = $("#" + this.pageId + "subCommodity").val();
        this.enteredHSNCode = $("#" + this.pageId + "hsnCode").val();
        this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
        this.goodsValue = (this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
            : this.enteredGoodsValue);

        this.enteredBillNumber = $("#" + this.pageId + "billNo").val();
        let enterBillDate = null;
        this.enteredBillDate = $("#" + this.pageId + "billDate").val();
        //this.enteredBillDate = this.datePipe.transform(enterBillDate, "yyyy-MM-dd");
        this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGstNumber").val();
        this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGstNumber").val();
        this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val();
        this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val();
        this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val();
        this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val();
        this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();
        this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
        this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
        this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
        this.enteredCessAmt = $("#" + this.pageId + "cessAmt").val();
        this.enteredNonAdvolCessAmt = $("#" + this.pageId + "nonAdvolCessAmt").val();
        this.enteredOtherInvAmount = $("#" + this.pageId + "otherInvAmount").val();
        this.lrtDtoForSave = new LRDto();
        this.lrtDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.lrtDtoForSave.totalArticles = this.totArticles;
        this.lrtDtoForSave.commonCommodity = this.selectedNewCommodity;
        this.lrtDtoForSave.subCommodity = this.enteredSubCommodity;
        this.lrtDtoForSave.code = this.enteredHSNCode;
        this.lrtDtoForSave.goodsValue = this.goodsValue;
        this.lrtDtoForSave.billNumber = this.enteredBillNumber;
        this.lrtDtoForSave.branch = this.userDataDtoReturnSession.office;
        this.lrtDtoForSave.goodsValue = this.goodsValue;
        this.lrtDtoForSave.billNumber = this.enteredBillNumber;
        // this.lrtDtoForSave.billDateStr = this.enteredBillDate;
        this.lrtDtoForSave.billDate = this.enteredBillDate;
        this.lrtDtoForSave.gstNoConsignor = this.selectedconsignorGSTNumber;
        this.lrtDtoForSave.consignorName = this.consignorTradeName;
        this.lrtDtoForSave.consignorAdress = this.consignorAddress1;
        this.lrtDtoForSave.consignorAdress2 = this.consignorAddress2;
        this.lrtDtoForSave.consignorPlace = this.consignorAddress2;
        this.lrtDtoForSave.consignorStateCode = this.consignorStateCode;
        this.lrtDtoForSave.consignorPincode = this.consignorPincode;

        this.lrtDtoForSave.gstNoConsignee = this.selectedconsigneeGSTNumber;
        this.lrtDtoForSave.consigneeName = this.consigneeTradeName;
        this.lrtDtoForSave.consigneeAdress = this.consigneeAddress1;
        this.lrtDtoForSave.consigneeAdress2 = this.consigneeAddress2;
        this.lrtDtoForSave.consigneePlace = this.consigneeAddress2;
        this.lrtDtoForSave.consigneeStateCode = this.consigneeStateCode;
        this.lrtDtoForSave.consigneePincode = this.consigneePincode;

        this.lrtDtoForSave.cgst = this.enteredCgstPerc;
        this.lrtDtoForSave.sgst = this.enteredSgstPerc;
        this.lrtDtoForSave.igst = this.enteredIgstPerc;

        this.lrtDtoForSave.cgstamt = this.enteredCgstAmt;
        this.lrtDtoForSave.sgstamt = this.enteredSgstAmt;
        this.lrtDtoForSave.igstamt = this.enteredIgstAmt;

        this.lrtDtoForSave.cessRate = this.enteredCessPerc;
        this.lrtDtoForSave.cessAmount = this.enteredCessAmt;
        this.lrtDtoForSave.cessNonAdvolRate = this.enteredNonAdvolCessPerc;
        this.lrtDtoForSave.cessAmount = this.enteredNonAdvolCessAmt;
        this.lrtDtoForSave.OthersCharge = this.enteredOtherInvAmount;

        this.generateEwayBill();
    }
    confirmGenerateEwayBill = () => {
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        this.selectedNewCommodity = $("#" + this.pageId + "commodityListId").val();
        this.enteredSubCommodity = $("#" + this.pageId + "subCommodity").val();
        this.enteredHSNCode = $("#" + this.pageId + "hsnCode").val();
        this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
        this.goodsValue = (this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
            : this.enteredGoodsValue);
        this.enteredBillNumber = $("#" + this.pageId + "billNo").val();
        this.enteredBillDate = $("#" + this.pageId + "billDate").val();
        this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGstNumber").val();
        this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGstNumber").val();
        this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val();
        this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val();
        this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val();
        this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val();
        this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();
        this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
        this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
        this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
        this.enteredCessAmt = $("#" + this.pageId + "cessAmt").val();
        this.enteredNonAdvolCessAmt = $("#" + this.pageId + "nonAdvolCessAmt").val();
        this.enteredOtherInvAmount = $("#" + this.pageId + "otherInvAmount").val();

        if (this.enteredBillDate == null || this.enteredBillDate == '' || this.enteredBillNumber == null ||
            this.enteredBillNumber == '' || this.enteredCessAmt == null || this.enteredCessAmt == '' ||
            this.enteredCessPerc == null || this.enteredCessPerc == '' || this.enteredCgstAmt == null ||
            this.enteredCgstAmt == '' || this.enteredCgstPerc == null || this.enteredCgstPerc == '' ||
            this.enteredGoodsValue == null || this.enteredGoodsValue == '' || this.enteredHSNCode == null || this.enteredHSNCode == '' ||
            this.enteredIgstAmt == null || this.enteredIgstAmt == '' || this.enteredIgstPerc == null ||
            this.enteredIgstPerc == '' || this.enteredNonAdvolCessAmt == null || this.enteredNonAdvolCessAmt == '' ||
            this.enteredNonAdvolCessPerc == null || this.enteredNonAdvolCessPerc == '' || this.enteredOtherInvAmount == null ||
            this.enteredOtherInvAmount == '' ||
            this.enteredSgstAmt == null || this.enteredSgstAmt == '' ||
            this.enteredSubCommodity == null || this.enteredSubCommodity == '' ||
            this.consignorTradeName == null || this.consignorTradeName == '' ||
            this.consigneeTradeName == null || this.consigneeTradeName == '' ||
            this.totArticles == null || this.totArticles == '' ||
            this.selectedNewCommodity == null || this.selectedNewCommodity == '') {

            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory fields*",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            swal({
                title: "Confirm",
                text: "Sure you want to Generate E Way Bill",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.validateEwayBillGenerate();
                } else {
                    swal.close();
                }

            });
        }
    }
    generateEwayBill = () => {
        let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
        this.showSpinner = true;
        this.lrtDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        console.log("this.lrtDtoForSave");
        console.log(this.lrtDtoForSave);
        this.ewaybillService.generateNewEwayBill_V2(this.lrtDtoForSave).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.showSpinner = false;
                    swal({
                        title: "Alert",
                        text: "Error while Adding E way bill",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.showSpinner = false;
                    this.lrDtoSaveRet = response;
                    if (this.lrDtoSaveRet.status == null || this.lrDtoSaveRet.status == '') {
                        swal({
                            title: "Alert",
                            text: "Error While Adding LRDetails",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.lrDtoSaveRet.status != null
                        && (this.lrDtoSaveRet.status ==
                            "failed")) {
                        swal({
                            title: "Alert",
                            text: "Error While Saving E way bill",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "E way bill generated Successfully",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            this.clearfields();

                        });
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    grandSum() {
        let cgstAmount = 0.0, sgstAmount = 0.0, igstAmount = 0.0, cessAmount = 0.0, nonAdvolCessAmt = 0.0;
        this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
        this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val();
        this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val();
        this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val();
        this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val();
        this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();

        cgstAmount = ((this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue) * (this.enteredCgstPerc == null ? 0.0 : this.enteredCgstPerc)) / 100;
        sgstAmount = ((this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue) * (this.enteredSgstPerc == null ? 0.0 : this.enteredSgstPerc)) / 100;
        igstAmount = ((this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue) * (this.enteredIgstPerc == null ? 0.0 : this.enteredIgstPerc)) / 100;
        cessAmount = ((this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue) * (this.enteredCessPerc == null ? 0.0 : this.enteredCessPerc)) / 100;
        nonAdvolCessAmt = ((this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue) * (this.enteredNonAdvolCessPerc == null ? 0.0 : this.enteredNonAdvolCessPerc)) / 100;
        $("#" + this.pageId + "cgstAmt").val(cgstAmount.toFixed(2));
        $("#" + this.pageId + "sgstAmt").val(sgstAmount.toFixed(2));
        $("#" + this.pageId + "igstAmt").val(igstAmount.toFixed(2));
        $("#" + this.pageId + "cessAmt").val(cessAmount.toFixed(2));
        $("#" + this.pageId + "nonAdvolCessAmt").val(nonAdvolCessAmt.toFixed(2));
    }
    clearfields() {
        $("#" + this.pageId + "noOfArticles").val('');
        $("#" + this.pageId + "commodityListId").val('');
        $("#" + this.pageId + "subCommodity").val('');
        $("#" + this.pageId + "hsnCode").val('');
        $("#" + this.pageId + "goodsValue").val('');
        $("#" + this.pageId + "billNo").val('');
        $("#" + this.pageId + "billDate").val('');
        // $("#" + this.pageId + "consignorGSTNumber").val('');
        // $("#" + this.pageId + "consigneeGSTNumber").val('');
        $("#" + this.pageId + "cgstPerc").val('');
        $("#" + this.pageId + "sgstPerc").val('');
        $("#" + this.pageId + "igstPerc").val('');
        $("#" + this.pageId + "cessPerc").val('');
        $("#" + this.pageId + "nonAdvolCessPerc").val('');
        $("#" + this.pageId + "cgstAmt").val('');
        $("#" + this.pageId + "sgstAmt").val('');
        $("#" + this.pageId + "igstAmt").val('');
        $("#" + this.pageId + "cessAmt").val('');
        $("#" + this.pageId + "nonAdvolCessAmt").val('');
        $("#" + this.pageId + "otherInvAmount").val('');
        $("#" + this.pageId + "reason").val('');

        $("#" + this.pageId + "consignorGstNumber").val('');
        $("#" + this.pageId + "consigneeGstNumber").val('');

        this.consigneeLegalName = '';
        this.consigneeTradeName = '';
        this.consigneeStatus = '';
        this.consigneeAddress1 = '';
        this.consigneeAddress2 = '';
        this.consigneePincode = '';
        this.consigneeStateCode = '';

        this.consignorLegalName = '';
        this.consignorTradeName = '';
        this.consignorStatus = '';
        this.consignorAddress1 = '';
        this.consignorAddress2 = '';
        this.consignorPincode = '';
        this.consignorStateCode = '';
    }
}