<div class="row m-t-30 m-b-20">
	<!-- col -->
	<div class="col-md-12">
		<div class="row">
			<!-- col -->
			<div class="col-sm-12 col-md-3">
				<div class="info d-flex align-items-center">
					<div class="m-r-10">
						<i class="fas fa-file-alt text-white display-5 op-5"></i>
					</div>
					<div>
						<h3 class="text-white m-b-0">{{curStmtValue}}</h3>
						<span class="ftSize text-white op-5">Current Statement</span>
					</div>
				</div>
			</div>
			<!-- col -->
			<!-- col -->
			<div class="col-sm-12 col-md-3">
				<div class="info d-flex align-items-center">
					<div class="m-r-10">
						<i class="fas fa-rupee-sign text-white display-5 op-5"></i>
					</div>
					<div>
						<h3 class="text-white m-b-0">{{agentBalValue}}</h3>
						<span class="ftSize text-white op-5">Due Amount</span>
					</div>
				</div>
			</div>
			<!-- col -->
			<!-- col -->
			<div class="col-sm-12 col-md-3">
				<div class="info d-flex align-items-center">
					<div class="m-r-10">
						<i class="far fa-file-alt text-white display-5 op-5"></i>
					</div>
					<div>
						<h3 class="text-white m-b-0">0</h3>
						<span class="ftSize text-white op-5">LRs in Sales Tax</span>
					</div>
				</div>
			</div>
			<!-- col -->
		</div>
	</div>
</div>
