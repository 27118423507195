import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from '@angular/router';

@Component( {
    selector: 'app-message-memo',
    templateUrl: './message-memo.component.html',
    styleUrls: ['./message-memo.component.css']
} )
export class MessageMemoComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
    lrDtoUserRet : LRDto = new LRDto();
    hireSlipDtoSave : HireSlipDto = new HireSlipDto();
    hireSlipDtoSaveRet : HireSlipDto = new HireSlipDto();
    enteredMsg : any;
    pageId="msgmc";
    
    constructor(private router: Router,private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
           
            setTimeout(() => {
            	 this.getDetails();
            }, 1000);
        }
    }
    
    ngOnInit(): void {
    	
    }
    
    getUserValues() {
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.mainstation = this.userDataDtoReturnSession.mainStation;
	}
    getDetails = () => {
		this.getUserValues();
		this.masterReadService.getMsgDispInCMService(this.lrDtoUserValue).subscribe(
			(response) => {
				if (response == null) {
					swal({
						title: "Message On Cashmemo",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.lrDtoUserRet = response;
					$("#"+this.pageId+"message").val(this.lrDtoUserRet.message);
				}
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	}
    
    confirmSave(){
    	swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveDetails();
            }
        })
    }
    
    getUserSaveValues() {
    	this.enteredMsg = $("#"+this.pageId+"message").val();
		this.hireSlipDtoSave = new HireSlipDto();
		this.hireSlipDtoSave.branch = this.userDataDtoReturnSession.mainStation;
		this.hireSlipDtoSave.messageText = this.enteredMsg;
		this.hireSlipDtoSave.lastupdatedby = this.userDataDtoReturnSession.userId;
		this.hireSlipDtoSave.isApplicable = true;
	}
    saveDetails = () => {
		this.getUserSaveValues();
		this.masterReadService.addTextMessage(this.hireSlipDtoSave).subscribe(
			(response) => {
				this.hireSlipDtoSaveRet = response;
				if (this.hireSlipDtoSaveRet.status == "Success") {
					swal("Message On Cashmemo", "Successfully saved/updated the message", "info");
				} else if (this.hireSlipDtoSaveRet.status == "Failed"){
					swal("Message On Cashmemo", "Failed to saved/updated the message", "error");
				}
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server problem occurred while saving the details", "info");
			}, () => console.log('done');
	}
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
