import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";

@Component( {
	selector: 'app-fortnight-statement-summary',
    templateUrl: './fortnight-statement-summary.component.html',
    styleUrls: ['./fortnight-statement-summary.component.css']
} )
export class FortnightStatementSummaryComponent implements  OnInit {

	fortnightStatementSmryDataList: any;
	dtTriggerFortnightStatementSmry: Subject<any> = new Subject();
    dtOptionsFortnightStatementSmry: any;
    
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    fortNightStmtDtoUserValue: FortNightStmtDto = new FortNightStmtDto();
    pageId="fssuc";

    constructor(private router: Router,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    ngOnInit(): void {
        this.dtOptionsFortnightStatementSmry = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    getPendingStmtDetailsUserValues() {
		this.fortNightStmtDtoUserValue = new FortNightStmtDto();
		this.fortNightStmtDtoUserValue.status = "Pending";
		this.fortNightStmtDtoUserValue.mode = "all";
		this.fortNightStmtDtoUserValue.companyId = this.userDataDtoReturnSession.companyId;
	}
    getPendingStmtDetails = () => {
		this.showSpinnerForAction = true;
		this.getPendingStmtDetailsUserValues();
		this.destStmtReport.getFortNightStmt(this.fortNightStmtDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"destStmtDBTableId").DataTable().destroy();
				this.fortnightStatementSmryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.fortnightStatementSmryDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerFortnightStatementSmry.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Statement Details", "info");
			}, () => console.log('done');
	};

    // ngOnDestroy(): void {
    //     this.dtTriggerFortnightStatementSmry.unsubscribe();
    // }
    ngAfterViewInit(): void {
        this.dtTriggerFortnightStatementSmry.next();
    }
    logInPage() {
      this.router.navigate(['/authentication/login']);
  }
    goToStatementDetailsPage(fortnightStatementSmryData) {
    	localStorage.clear();
        localStorage.setItem('fortnightStatementSmryData', JSON.stringify(fortnightStatementSmryData));
        this.router.navigate( ['/dashboard/fortnightStatementDetails'] );
    }
}
