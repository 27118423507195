
<div mat-dialog-content id="{{pageId}}agentStockvisible">
		<div class="row p-t-10">
			<div class="col-md-12">
				<h6 id="{{pageId}}showHeader"style="margin-bottom: 0px;">Unloading LR Details For
					{{stmtNo}} - {{loaderHeadName}} , {{period}}.</h6>
	
			</div>
		</div>
		<div class="row">
			<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
				<div class="form-group">
					<div class="input-group">
						<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
						<br>
						<h6 class="card-title" align='center'
							style="color: green; margin: auto; font-size: 15px;">Please
							Wait Loading....</h6>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
		
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="box-body">
							<table datatable style="width: 100%;" id="{{pageId}}labourStmtLRDetails"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLabourStmtLRReport"
								[dtTrigger]="dtTriggerLabourStmtLRReport">
	
								<thead>
									<tr>
										<th>LR Number </th>
										<th>Article</th>
										<th>Act Wgt</th>
										<th>To Pay</th>
										<th>Paid</th>
										<th>Local Challan No</th>
										<th>Local Challan Arrival</th>
										<th>Local Challan Kanta Wgt</th>
										<th>Temp No</th>
										<th>Agency Name</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let labourStmtLRReportData of labourStmtLRReportDataList ">
										<td>{{ labourStmtLRReportData.lrNumber }}</td>
										<td>{{ labourStmtLRReportData.totalArticles }}</td>
										<td>{{ labourStmtLRReportData.actualWeight }}</td>
										<td>{{ labourStmtLRReportData.toPay }}</td>
										<td>{{ labourStmtLRReportData.paid }}</td>
										<td>{{ labourStmtLRReportData.challanNo }}</td>
										<td>{{ labourStmtLRReportData.arrivalDateStr }}</td>
										<td>{{ labourStmtLRReportData.challanKantaWgt }}</td>
										<td>{{ labourStmtLRReportData.vehicleNumber }}</td>
										<td>{{ labourStmtLRReportData.agentName }}</td>
									</tr>
	
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
	
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions style="float: right;" id="{{pageId}}printvisible">
		<button class="btn btn-outline-danger" mat-button
			(click)="onCancelClick()">Cancel</button>
	</div>
	