import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";

import { IssueRoutes } from 'src/app/lr/issue/issue.routing';

import { ChallanComponent } from 'src/app/lr/issue/challan/challan.component';
import { LrIssueComponent } from 'src/app/lr/issue/lr-issue/lr-issue.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { MaterialModule } from "src/app/material.module";


@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( IssueRoutes ),
         FormsModule,  NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, CashmemoReportModule, MaterialModule],
    declarations: [
        ChallanComponent,
        LrIssueComponent,
    ]
} )
export class IssueModule { }
