<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Consignee Details</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<h6 class="card-title">Consignee Details Enquiry</h6>
										<div class="row">
											<!-- 											the second autocomplete starts  -->
											<div class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}destination">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-road"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select Destination" [formControl]="control"
																[appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(destinationOptions | filter: control.value) as resultDestination">
												<app-option *ngFor="let option of resultDestination"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultDestination.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>
											<!-- 											the second autocomplete ends -->

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchType"
															name="searchType">
															<option selected value="consigneeName">Consignee
																Name</option>
															<option value="mobileNo">Mobile No</option>
														</select>
													</div>
												</div>
											</div>


											<!-- 											the first autocomplete starts -->
											<div class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}partyName">
															<label>Party Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select Party" [formControl]="control"
																[appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(partyOptions | filter: control.value) as resultParty">
												<app-option *ngFor="let option of resultParty"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultParty.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>
											<!-- 											the first autocomplete ends -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
						</div>
						<br>
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<h6 class="card-title">Consignee Details</h6>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}mobileNo">
														<label>Mobile No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-phone"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}officeNo">
														<label>Office No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-phone-square"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}email">
														<label>Email</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-envelope"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<!-- 							the first datatble starts -->
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsGodownStockSmryDetails"
								[dtTrigger]="dtTriggerGodownStockSmryDetails">

								<thead>
									<tr>
										<th>Consignee Name</th>
										<th>Total Lrs</th>
										<th>Articles</th>
										<th>To-Pay</th>
										<th>Paid</th>
										<th>Act Wght</th>
										<th>Chg Wght</th>
										<th>Stock At</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let godownStockSmryDetailsData of godownStockSmryDetailsDataList ">
										<td>{{ godownStockSmryDetailsData.consigneeName }}</td>
										<td>{{ godownStockSmryDetailsData.totalLrs }}</td>
										<td>{{ godownStockSmryDetailsData.articles }}</td>
										<td>{{ godownStockSmryDetailsData.toPay }}</td>
										<td>{{ godownStockSmryDetailsData.paid }}</td>
										<td>{{ godownStockSmryDetailsData.actWght }}</td>
										<td>{{ godownStockSmryDetailsData.chgWght }}</td>
										<td>{{ godownStockSmryDetailsData.stocksAt }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
							<!-- 							the first datatble ends -->
							<!-- 							the second datatable starts  -->

							<div class="p-t-10">
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsConsigneeOsDetails"
									[dtTrigger]="dtTriggerConsigneeOsDetails">

									<thead>
										<tr>
											<th>Consignee Name</th>
											<th>Total Memo</th>
											<th>Total Amount</th>
											<th>Total Article</th>
											<th>Area</th>
											<th>Collection Man</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let consigneeOsDetailsData of consigneeOsDetailsDataList ">
											<td>{{ consigneeOsDetailsData.consigneeName }}</td>
											<td>{{ consigneeOsDetailsData.totalMemo }}</td>
											<td>{{ consigneeOsDetailsData.totalAmount }}</td>
											<td>{{ consigneeOsDetailsData.totalArticle }}</td>
											<td>{{ consigneeOsDetailsData.area }}</td>
											<td>{{ consigneeOsDetailsData.collectionMan }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
								</table>
							</div>
							<!-- 							the second datatable ends  -->
							<!-- 							the third datatable starts  -->

							<div class="p-t-10">
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsConsigneeRateDetails"
									[dtTrigger]="dtTriggerConsigneeRateDetails">

									<thead>
										<tr>
											<th>Consignee Name</th>
											<th>Source</th>
											<th>Deliv Type</th>
											<th>Unit</th>
											<th>Src.Ham</th>
											<th>Des.Ham</th>
											<th>Rate</th>
											<th>Receipt Chg</th>
											<th>Service Chg</th>
											<th>GC Chg</th>
											<th>Per Unit</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let consigneeRateDetailsData of consigneeRateDetailsDataList ">
											<td>{{ consigneeRateDetailsData.consigneeName }}</td>
											<td>{{ consigneeRateDetailsData.source }}</td>
											<td>{{ consigneeRateDetailsData.delivType }}</td>
											<td>{{ consigneeRateDetailsData.unit }}</td>
											<td>{{ consigneeRateDetailsData.srcHam }}</td>
											<td>{{ consigneeRateDetailsData.desHam }}</td>
											<td>{{ consigneeRateDetailsData.rate }}</td>
											<td>{{ consigneeRateDetailsData.receiptChg }}</td>
											<td>{{ consigneeRateDetailsData.serviceChg }}</td>
											<td>{{ consigneeRateDetailsData.gcChg }}</td>
											<td>{{ consigneeRateDetailsData.perUnit }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
								</table>
							</div>
							<!-- 						the third datatable ends  -->
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>