<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px;padding: 2px 5px;background-color: burlywood;" (click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-8">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Transit Lrs
					</h6>
			
				</div>
				<div class="col-md-4">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id="{{pageId}}transitLrsId" class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsTransitLrs" [dtTrigger]="dtTriggerTransitLrs">

			<thead>
				<tr>
					<th>Hireslip Number</th>
					<th>Destination</th>
					<th>Departure Date</th>
					<th>Scheduled Date</th>

				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let transitLrsData of transitLrsDataList ">
					<td (click)="hireslipDialog(transitLrsData)"><u class="hyperLink_td">{{
							transitLrsData.hireslipnumber }}</u></td>
					<td>{{ transitLrsData.tostation }}</td>
					<td>{{ transitLrsData.departuredateStr }}</td>
					<td>{{ transitLrsData.scheduleddateStr }}</td>
				</tr>


				<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

			</tbody>
		</table>
	</div>
</div>