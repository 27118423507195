<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"
	(click)="gridReconifgureOnReloadBtn()" area-hidden="true"></i> -->
<div class="col-md-12 col-sm-12">
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
                <div class="col-md-8">
                    <h6 class="card-title">
                        <i class=" fas fa-file-alt"></i>&nbsp;COD LR Details
                    </h6>

                </div>
                <div class="col-md-4">
                    <i title="Reload" class="fas fa-sync-alt fa xs cli" style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;" (click)="gridReconifgureOnReloadBtn()"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="box-body">
        <table datatable id="{{pageId}}codLrDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCodLrDetails" [dtTrigger]="dtTriggerCodLrDetails">

            <thead>
                <tr>
                    <th>LR Number</th>
                    <th>Consignee Name</th>
                    <th>Goods Value</th>
                    <th>Source</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let codLrDetailsData of codLrDetailsDataList">
                    <!-- 					<td (click)="goToLrEnquiryPage()"><u class="hyperLink_td">{{ -->
                    <!-- 							codLrDetailsData.lrNumber }}</u></td> -->
                    <td>{{ codLrDetailsData.lrNumber }}</td>
                    <td>{{ codLrDetailsData.consigneeName }}</td>
                    <td>{{ codLrDetailsData.goodsValue }}</td>
                    <td>{{ codLrDetailsData.source }}</td>
                    <td><i title="COD Payment Entry" (click)="open2(codLrDetailsData,confirmPaymentPopUpTemplate)" style="cursor: pointer;" class="far fa-money-bill-alt"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetailsConfirmPayment">
            <ng-template #confirmPaymentPopUpTemplate let-ok="close" let-d="dismiss">


                <div class="modal-header">
                    <h5>Payment Entries</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
                </div>

                <div class="modal-body">
                    <div class="row" style="">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Payment Mode :</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-search"></i>
										</span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}paymentMode" name="paymentMode" #paymentMode (change)="paymentModeType(paymentMode.value)">
										<option value="cheque">Cheque/PDC</option>
										<option value="neft">NEFT/IMPS</option>
									</select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Bank Name :</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
										</span>
                                    </div>
                                    <input #consigneeBankName id="{{pageId}}consigneeBankName" style="width: 53%;" name="consigneeBankName" type="text" placeholder="Select Bank" [(ngModel)]="modelBankName" [ngbTypeahead]="consigneeBankNameSearchTA" [ngModelOptions]="{standalone: true}"
                                        [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName" (focus)="focusConsigneeBankNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                </div>
                            </div>
                        </div>

                        <div *ngIf="chequeView" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cheque Number :</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
										</span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}chequeNumber" name="chequeNumber" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="chequeView" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cheque Date :</label> <input id="{{pageId}}chequeDate" [(ngModel)]="checkDateModel" name="chequeDate" ng-change="vm.dateString = (dateObj | date: 'dd/dd/yyyy')" class="form-control" (click)="e.toggle()" placeholder="yyyy-mm-dd"
                                        name="dp" [(ngModel)]="model" ngbDatepicker #e="ngbDatepicker">
                                    <div class="input-group-append" (click)="e.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="neftView" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Reference Number :</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
										</span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}refNo" name="refNo" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>



                        <div class="col-md-12 p-t-10" style="text-align: right;">
                            <button type="button" class="btn btn-outline-dark" (click)="updateBtn()">Update
								Payment</button>
                            <button type="button" class="btn btn-outline-dark" (click)="clearFields()">Clear</button>
                            <button type="button" class="btn btn-outline-dark" (click)="closeBtnPopup();">Close</button>


                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- <div class="col-md-12"> -->
<!-- 	<div class="form-group"> -->
<!-- 		<div class="input-group" id="{{pageId}}popupDetails"> -->
<!-- 			<ng-template #content let-c="close" let-d="dismiss"> -->
<!-- 			<div class="modal-header"> -->
<!-- 				<h6 class="modal-title" id="{{pageId}}modal-basic-title">Payment Entries</h6> -->
<!-- 				<button type="button" class="close" aria-label="Close" -->
<!-- 					(click)="d('Cross click')"> -->
<!-- 					<span aria-hidden="true">&times;</span> -->
<!-- 				</button> -->
<!-- 			</div> -->
<!-- 			<div class="modal-body"> -->
<!-- 				<div class="row"> -->
<!-- 					<div class="col-md-12"> -->
<!-- 						<form> -->
<!-- 							<div class="col-sm-12 col-md-12"> -->
<!-- 								<div class="form-group"> -->
<!-- 									<div class="input-group"> -->
<!-- 										<label>Payment Mode :</label> -->
<!-- 										<div class="input-group-prepend"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fas fa-search"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 										<select class="custom-select col-12" id="{{pageId}}paymentMode" -->
<!-- 											name="paymentMode" #paymentMode -->
<!-- 											(change)="paymentModeType(paymentMode.value)"> -->
<!-- 											<option value="cheque">Cheque/PDC</option> -->
<!-- 											<option value="neft">NEFT/IMPS</option> -->
<!-- 										</select> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 							</div> -->
<!-- 							<div class="col-sm-12 col-md-12"> -->
<!-- 								<div class="form-group"> -->
<!-- 									<div class="input-group"> -->
<!-- 										<label>Bank Name :</label> -->
<!-- 										<div class="input-group-prepend"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fas fa-warehouse"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 										<input #consigneeBankName id="{{pageId}}consigneeBankName" -->
<!-- 											style="width: 53%;" name="consigneeBankName" type="text" -->
<!-- 											placeholder="Select Bank" [(ngModel)]="modelBankName" -->
<!-- 											[ngbTypeahead]="consigneeBankNameSearchTA" -->
<!-- 											[ngModelOptions]="{standalone: true}" -->
<!-- 											[resultFormatter]="formatterBankName" -->
<!-- 											[inputFormatter]="formatterBankName" -->
<!-- 											(focus)="focusConsigneeBankNameTA$.next($any($event).target.value)" -->
<!-- 											[ngModelOptions]="{standalone: true}" /> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 							</div> -->


<!-- 							<div *ngIf="chequeView"> -->
<!-- 								<div class="form-group col-sm-12 col-md-12"> -->
<!-- 									<div class="input-group"> -->
<!-- 										<label>Cheque Number :</label> -->
<!-- 										<div class="input-group-prepend"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fas fa-file-alt"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 										<input type="text" class="form-control" id="{{pageId}}chequeNumber" -->
<!-- 											name="chequeNumber" aria-describedby="basic-addon11"> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 								<div class="form-group col-sm-12 col-md-12"> -->
<!-- 									<div class="input-group"> -->
<!-- 										<label>Cheque Date</label> <input id="{{pageId}}chequeDate" -->
<!-- 											name="chequeDate" -->
<!-- 											ng-change="vm.dateString = (dateObj | date: 'dd/dd/yyyy')" -->
<!-- 											class="form-control" (click)="e.toggle()" -->
<!-- 											placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" -->
<!-- 											ngbDatepicker #e="ngbDatepicker"> -->
<!-- 										<div class="input-group-append" (click)="e.toggle()"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fa fa-calendar"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 							</div> -->

<!-- 							<div *ngIf="neftView" class="col-sm-12 col-md-12"> -->
<!-- 								<div class="form-group"> -->
<!-- 									<div class="input-group"> -->
<!-- 										<label>Reference Number :</label> -->
<!-- 										<div class="input-group-prepend"> -->
<!-- 											<span class="input-group-text"> <i -->
<!-- 												class="fas fa-file-alt"></i> -->
<!-- 											</span> -->
<!-- 										</div> -->
<!-- 										<input type="text" class="form-control" id="{{pageId}}refNo" -->
<!-- 											name="refNo" aria-describedby="basic-addon11"> -->
<!-- 									</div> -->
<!-- 								</div> -->
<!-- 							</div> -->


<!-- 						</form> -->
<!-- 					</div> -->
<!-- 				</div> -->
<!-- 			</div> -->
<!-- 			<div class="modal-footer"> -->
<!-- 				<button type="button" class="btn btn-outline-dark" -->
<!-- 					(click)="updateBtn()">Update Payment</button> -->
<!-- 				<button type="button" class="btn btn-outline-dark" -->
<!-- 					(click)="clearFields()">Clear</button> -->
<!-- 				<button type="button" class="btn btn-outline-dark" -->
<!-- 					(click)="c('Save click')">Close</button> -->
<!-- 			</div> -->
<!-- 			</ng-template> -->
<!-- 		</div> -->
<!-- 	</div> -->
<!-- </div> -->