import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { BookingRoutes } from 'src/app/report/bookings/bookings.routing';
import { BookingAgentReportComponent } from 'src/app/report/bookings/booking-agent-report/booking-agent-report.component';
import { AgentExpensesComponent } from 'src/app/report/bookings/agent-expenses/agent-expenses.component';
import { MemoComponent } from 'src/app/report/bookings/memo/memo.component';
import { OfficeHireslipComponent } from 'src/app/report/bookings/office-hireslip/office-hireslip.component';
import { MemoInvoiceComponent } from 'src/app/report/bookings/memo-invoice/memo-invoice.component';
import { AgentStatementComponent } from 'src/app/report/bookings/agent-statement/agent-statement.component';
import { AgentLocalTripComponent } from 'src/app/report/bookings/agent-local-trip/agent-local-trip.component';
import { TripLrDetailsComponent } from "src/app/report/bookings/trip-lr-details/trip-lr-details.component";
import { AgentStatementRptLrDetailsComponent } from "src/app/report/bookings/agent-statement-rpt-lr-details/agent-statement-rpt-lr-details.component";
import { MaterialModule } from "src/app/material.module";
import { AgentStatementRptLrDetailsPrintComponent } from "src/app/report/bookings/agent-statement-rpt-lr-details-print/agent-statement-rpt-lr-details-print.component";
import { AgentStatementPaymentDetailsComponent } from "src/app/report/bookings/agent-statement-payment-details/agent-statement-payment-details.component";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { AgentLocalTripPopupComponent } from 'src/app/report/bookings/agent-local-trip-popup/agent-local-trip-popup.component';
import { TdsLoadingComponent } from 'src/app/report/bookings/tds-loading/tds-loading.component';
@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( BookingRoutes ), 
        FormsModule, 
         NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
          MatDialogModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule,
          MaterialModule,CashmemoReportModule
        ],
    declarations: [
        BookingAgentReportComponent,
        AgentExpensesComponent,
        MemoComponent,
        OfficeHireslipComponent,
        MemoInvoiceComponent,
        AgentStatementComponent,
        AgentLocalTripComponent,
        TripLrDetailsComponent,
        AgentStatementRptLrDetailsComponent,
        AgentStatementRptLrDetailsPrintComponent,
        AgentStatementPaymentDetailsComponent,
        AgentLocalTripPopupComponent,
        TdsLoadingComponent],
    entryComponents: [TripLrDetailsComponent],
    // providers: [
    //     AgentStatementRptLrDetailsComponent
    // ],
    exports: [AgentStatementRptLrDetailsPrintComponent]
} )
export class BookingsModule { }


