import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LRDto } from "src/app/dto/LR-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";
import { FortNightStmtDto } from '../dto/FortNightStmt-dto';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";

@Injectable({
    providedIn: 'root'
})
export class DestStmtReport {

    private _baseUrl = serverUrl.webDestStmtRpt;

    constructor(private http: HttpClient) {

    }

    chkAgentAutority(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/chkAgentAutorityApi`, fortNightStmtDto);
    }

    getDebitNoteReportDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDebitNoteReportDetailsApi`, fortNightStmtDto);
    }


    getAmtRecFromAgentDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getAmtRecFromAgentDetailsApi`, fortNightStmtDto);
    }

    getStmtVerificationDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getStmtVerificationDetailsApi`, fortNightStmtDto);
    }

    getStmtVerificationDetailsLrs(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStmtVerificationDetailsLrsApi`, lrDto);
    }

    getStatementReportDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getStatementReportDetailsApi`, fortNightStmtDto);
    }

    getBookingServiceTax(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingServiceTaxApi`, lrDto);
    }

    getLabourHamaliLastStmtDetails(hireSlipDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLabourHamaliLastStmtDetailsApi`, hireSlipDto);
    }

    validateStmtDate(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/validateStmtDateApi`, fortNightStmtDto);
    }

    calculateSRDHamaliComms(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/calculateSRDHamaliCommsApi`, hireSlipDto);
    }

    addLoaderHamaliCommsDetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/addLoaderHamaliCommsDetailsApi`, hireSlipDto);
    }

    getLoaderHamaliCommsDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLoaderHamaliCommsDetailsApi`, hireSlipDto);
    }

    getLabourHamaliPendingStmtDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLabourHamaliPendingStmtDetailsApi`, hireSlipDto);
    }

    getLabourHamaliStatementReportDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getLabourHamaliStatementReportDetailsApi`, fortNightStmtDto);
    }

    updateLBTHamaliStmt(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateLBTHamaliStmtApi`, hireSlipDto);
    }

    getAgentCommissionDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getAgentCommissionDetailsApi`, fortNightStmtDto);
    }

    addAgentCommissionDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/addAgentCommissionDetailsApi`, fortNightStmtDto);
    }

    getStationForStmt(lRDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStationForStmtApi`, lRDto);
    }

    addFortnightStmtDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/addFortnightStmtDetailsApi`, fortNightStmtDto);
    }

    getFortNightStmt(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getFortNightStmtApi`, fortNightStmtDto);
    }

    getFortNightInvoice(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getFortNightInvoiceApi`, fortNightStmtDto);
    }

    updateFortNightStmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/updateFortNightStmtApi`, fortNightStmtDto);
    }

    getDestAgentStmtTrxAmt(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDestAgentStmtTrxAmtApi`, fortNightStmtDto);
    }

    getBookingAgentExpensesReport(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getBookingAgentExpensesReportApi`, fortNightStmtDto);
    }

    getStmtLessCheckedAndUncheckedLrs(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getStmtLessCheckedAndUncheckedLrsApi`, fortNightStmtDto);
    }

    getLessUncheckedLrDetails(fortNightStmtDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLessUncheckedLrDetailsApi`, fortNightStmtDto);
    }

    getLessUncheckedLrDetailsV2(fortNightStmtDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLessUncheckedLrDetailsV2Api`, fortNightStmtDto);
    }

    getBkgAgtTDSLdgDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getBkgAgtTDSLdgDetailsApi`, fortNightStmtDto);
    }

    getAgnetAmountBalance(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getAgnetAmountBalanceApi`, fortNightStmtDto);
    }

    getBkgAgtStmtDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getBkgAgtStmtDetailsApi`, rateMasterDto);
    }

    // bng ofc daily rpt - starts
    getDestAgentsDuesDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getDestAgentsDuesDetailsApi`, fortNightStmtDto);
    }

    getDestAgentsDuesDetailsNew(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getDestAgentsDuesDetailsNewApi`, fortNightStmtDto);
    }

    getBankMasterDetailsForBngRpt(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getBankMasterDetailsForBngRptApi`, userDataDto);
    }

    getPdcRptDtls(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPdcRptDtlsApi`, lrDto);
    }

    addDestAgtDuesDailyUpdated(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/addDestAgtDuesDailyUpdatedApi`, fortNightStmtDto);
    }

    excelForBngDailyOfcRpt(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/excelForBngDailyOfcRptApi`, partyMasterDto);
    }
    // bng ofc daily rpt - ends

    sendEmailDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/sendEmailDetailsApi`, lrDto);
    }
    getLrDetailsForBkgAgentStmt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsForBkgAgentStmtApi`, lrDto);
    }

    updateBkgAgntStmt(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateBkgAgntStmtApi`, rateMasterDto);
    }

    updateMPercStmtLessDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/updateMPercStmtLessDetailsApi`, fortNightStmtDto);
    }

    getDestStmtDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getDestStmtDetailsApi`, fortNightStmtDto);
    }

    getLrLessCheckDetailsSrcGrouped(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrLessCheckDetailsSrcGroupedApi`, lrDto);
    }

    getDiscountAmtMemoLrEntry(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getDiscountAmtMemoLrEntryApi`, lrDto);
    }

    getLrStatusPartyLess(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrStatusPartyLessApi`, lrDto);
    }

    partyLessCrossCheck(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/partyLessCrossCheckApi`, lrDto);
    }
    getStmtNoDateWise(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getStmtNoDateWiseApi`, fortNightStmtDto);
    }

    getLessCheckedBlockLrDetails(fortNightStmtDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLessCheckedBlockLrDetailsApi`, fortNightStmtDto);
    }

    getAmtRecFromAgentDetailsEdit(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getAmtRecFromAgentDetailsEditApi`, fortNightStmtDto);
    }

    getLessUncheckedLrDatewise(fortNightStmtDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLessUncheckedLrDatewiseDetailsApi`, fortNightStmtDto);
    }

    getEnteredLessLrsDatewise(fortNightStmtDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getEnteredLessLrsDatewiseDetailsApi`, fortNightStmtDto);
    }

    getInvoiceDetailsDateWiseDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsDateWiseDetailsApi`, lrDto);
    }

    getStmtNoWise(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getStmtNoWiseApi`, fortNightStmtDto);
    }
}
