<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showGodwnStckBkgRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxAgentWise" (change)="agentWiseCheckBoxEvent($event)"><label class=" custom-control-label wtfull" for="{{pageId}}checkboxAgentWise">Agent Wise</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
															<option selected value="summary">Summary</option>
															<option value="detail">Detail</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12" [hidden]="!hideshowSourceDropDown">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destinationId" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="destinationListener($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <div class="box-body" [hidden]="!hideshowSummaryTable">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintGodwonStcSumBkgRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideshowSummaryTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                            <!-- the first datatble starts -->
                            <!---<p>Summary</p>-->
                            <table datatable id="{{pageId}}summaryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Book Date</th>
                                        <th>Entry Date</th>
                                        <th>Total Lrs</th>
                                        <th>Articles</th>
                                        <th>Diff(Entry - Booking)</th>
                                        <th>Diff(Current - Entry)</th>
                                        <th>Diff(Current - Booking) </th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let smryData of summaryDataList ">
                                        <td [hidden]=true>{{ smryData.bookingDateStr }}</td>
                                        <td>{{ smryData.enteredDateStr }}</td>
                                        <td>{{ smryData.totalLrs }}</td>
                                        <td>{{ smryData.totalArticles }}</td>
                                        <td>{{ smryData.dispatchedwithin }}</td>
                                        <td>{{ smryData.arrivalwithin }}</td>
                                        <td>{{ smryData.diffDays }}</td>
                                        <td>{{ smryData.actualWeight }}</td>
                                        <td>{{ smryData.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- the first datatble ends -->
                        <!-- <the second datatable starts  -->
                        <div class="box-body" [hidden]="!hideshowDetailTable">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintGodwonStcDetRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideshowDetailTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                            <!--<p>Detaildd</p>-->
                            <table datatable id="{{pageId}}detailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Booking Date</th>
                                        <th>Entry Date</th>
                                        <th>LR No</th>
                                        <th>Articles</th>
                                        <th>Commodity</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Diff(Entry - Booking)</th>
                                        <th>Diff(Current - Entry)</th>
                                        <th>Diff(Current - Booking) </th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let detailData of detailDataList ">
                                        <td [hidden]=true>{{ detailData.bookingDateStr }}</td>
                                        <td>{{ detailData.enteredDateStr }}</td>
                                        <td>{{ detailData.lrNumber }}</td>
                                        <td>{{ detailData.totalArticles }}</td>
                                        <td>{{ detailData.commodityName }}</td>
                                        <td>{{ detailData.agentName }}</td>
                                        <td>{{ detailData.destination }}</td>
                                        <td>{{ detailData.dispatchedwithin }}</td>
                                        <td>{{ detailData.arrivalwithin }}</td>
                                        <td>{{ detailData.diffDays }}</td>
                                        <td>{{ detailData.actualWeight }}</td>
                                        <td>{{ detailData.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!--the second datatable ends  -->
                        <!-- the third datatable starts  -->
                        <div class="box-body" [hidden]="!hideshowSummaryChbxTable">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintGodwonStcBkgRptChkBox()" id="{{pageId}}printAllBtn" [hidden]="!hideshowSummaryChbxTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                            <!--<p>Summary Check</p>-->
                            <table datatable id="{{pageId}}summaryCheckBoxTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummaryChkbx" [dtTrigger]="dtTriggerSummaryChkbx">

                                <thead>
                                    <tr>
                                        <th [hidden]=true>Source</th>
                                        <th>Entry Date</th>
                                        <th>Total Lrs</th>
                                        <th>Book Date</th>
                                        <th>Articles</th>
                                        <th>Diff(Entry - Booking)</th>
                                        <th>Diff(Current - Entry)</th>
                                        <th>Diff(Current - Booking) </th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let smryDataChkbx of summaryChkbxDataList ">
                                        <td [hidden]=true>{{ smryDataChkbx.source }}</td>
                                        <td>{{ smryDataChkbx.enteredDateStr }}</td>
                                        <td>{{ smryDataChkbx.totalLrs }}</td>
                                        <td>{{ smryDataChkbx.bookingDateStr }}</td>
                                        <td>{{ smryDataChkbx.totalArticles }}</td>
                                        <td>{{ smryDataChkbx.dispatchedwithin }}</td>
                                        <td>{{ smryDataChkbx.arrivalwithin }}</td>
                                        <td>{{ smryDataChkbx.diffDays }}</td>
                                        <td>{{ smryDataChkbx.actualWeight }}</td>
                                        <td>{{ smryDataChkbx.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- the third datatable ends  -->
                        <!-- the fourth datatable starts  -->
                        <div class="box-body" [hidden]="!hideshowDetailChbxTable">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintGodwonStcDetRptChkBox()" id="{{pageId}}printAllBtn" [hidden]="!hideshowDetailChbxTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                            <!--<p>Details Check</p>-->
                            <table datatable id="{{pageId}}detailsCheckBoxTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDetailChkbx" [dtTrigger]="dtTriggerDetailChkbx">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Source</th>
                                        <th>Entry Date</th>
                                        <th>Book Date</th>
                                        <th>LR No</th>
                                        <th>Articles</th>
                                        <th>Commodity</th>
                                        <th>Destination</th>
                                        <th>Diff(Entry - Booking)</th>
                                        <th>Diff(Current - Entry)</th>
                                        <th>Diff(Current - Booking) </th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detailDataChkbx of detailChkbxDataList ">
                                        <td [hidden]=true>{{ detailDataChkbx.agentName }}</td>
                                        <td>{{ detailDataChkbx.enteredDateStr }}</td>
                                        <td>{{ detailDataChkbx.bookingDateStr }}</td>
                                        <td>{{ detailDataChkbx.lrNumber }}</td>
                                        <td>{{ detailDataChkbx.totalArticles }}</td>
                                        <td>{{ detailDataChkbx.commodityName }}</td>
                                        <td>{{ detailDataChkbx.destination }}</td>
                                        <td>{{ detailDataChkbx.dispatchedwithin }}</td>
                                        <td>{{ detailDataChkbx.arrivalwithin }}</td>
                                        <td>{{ detailDataChkbx.diffDays }}</td>
                                        <td>{{ detailDataChkbx.actualWeight }}</td>
                                        <td>{{ detailDataChkbx.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>