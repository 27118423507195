<!-- Row -->

<body>
	<div class="row" *ngIf="isLoggedIn">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Dummy Cashmemo</h6>
				</div>
				<!-- 			<form> -->
				<div class="row system_responsive" style="margin-bottom: 0px;">
					<div class="col-md-12">
						<div class="card" style="margin: 0px;">
							<div class="card-body">
								<div class="row">
									<div class="col-md-3">
										<h6 class="card-title">LR Details</h6>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>LR No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-clipboard"></i>
															</span>
														</div>
														<input type="text" class="form-control" id="{{pageId}}lrNumber"
															(keyup)="lrNumberOnEnter($event)" #lrNumber
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Memo No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-clipboard"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, lrNumber)" #memoNumber
															id="{{pageId}}memoNumber" aria-describedby="basic-addon11"
															autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Memo Date</label> <input id="{{pageId}}memoDates"
															class="form-control" placeholder="yyy-mm-dd"
															name="memoDates" ngbDatepicker #memoDates="ngbDatepicker"
															[(ngModel)]="memoDatesModal" autocomplete="off">
														<div class="input-group-append"
															(click)="memoDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-warehouse"></i>
																</span>
															</div>
															<input id="{{pageId}}destinationId" type="text"
																class="form-control"
																(selectItem)="clickListnerForDestination($event)"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination.." />
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Booking Date</label> <input id="{{pageId}}bookingDate"
															class="form-control" placeholder="yyy-mm-dd"
															name="bookingDates" ngbDatepicker
															#bookingDates="ngbDatepicker"
															[(ngModel)]="bookingDatesModal" autocomplete="off">
														<div class="input-group-append"
															(click)="bookingDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Invoice Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-clipboard"></i>
															</span>
														</div>
														<input type="text" class="form-control" id="{{pageId}}invoiceNumber"
															#invoiceNumber (keyup)="fieldFocus($event, commodity)"
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Invoice Date</label> <input id="{{pageId}}invoiceDate"
															class="form-control" placeholder="dd-mm-yyyy"
															name="invoiceDates" ngbDatepicker
															#invoiceDates="ngbDatepicker"
															[(ngModel)]="invoiceDatesModal" autocomplete="off">
														<div class="input-group-append"
															(click)="invoiceDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Commodity</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-clipboard-list"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, sourceStation)"
															id="{{pageId}}commodity" #commodity
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source Station</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-home"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, agentName)"
															id="{{pageId}}sourceStation" #sourceStation
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Agent</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, noOfArticle)" id="{{pageId}}agentName"
															#agent aria-describedby="basic-addon11"
															autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>No.Of Article</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-clone"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, actualWeight)"
															id="{{pageId}}noOfArticle" #noOfArticle
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Actual Weight </label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-weight"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="fieldFocus($event, chargedWeight)"
															id="{{pageId}}actualWeight" #actualWeight
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Charged Weight</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-weight"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="fieldFocus($event, consignorName)"
															id="{{pageId}}chargedWeight" #chargedWeight
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>

										</div>
									</div>
									<!-- <1&(^%%*&&^%&^%^&&^&^^&^***&*&&&&*) -->
									<div class="col-md-3 vl">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<h6 class="card-title">Consignor Details</h6>
												<div class="form-group">
													<div class="input-group">
														<label>Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consignorAddress)"
															id="{{pageId}}consignorName" #consignorName
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Address</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-hashtag"></i>
															</span>
														</div>
														<textarea class="form-control" rows="1"
															(keyup)="fieldFocus($event, consignorState)"
															id="{{pageId}}consignorAddress" #consignorAddress></textarea>
													</div>
												</div>
											</div>
											<!-- 										<div class="col-sm-12 col-md-12"> -->
											<!-- 											<div class="form-group"> -->
											<!-- 												<div class="input-group" id="{{pageId}}consignorState"> -->
											<!-- 													<label>State</label> -->
											<!-- 													<div class="input-group-prepend"> -->
											<!-- 														<span class="input-group-text"> <i -->
											<!-- 															class="fas fa-map-marker-alt"></i> -->
											<!-- 														</span> -->
											<!-- 													</div> -->
											<!-- 													<select class="custom-select col-12"> -->
											<!-- 														<option selected>Tamil Nadu</option> -->
											<!-- 														<option value="1">Karnataka</option> -->
											<!-- 													</select> -->
											<!-- 												</div> -->
											<!-- 											</div> -->
											<!-- 										</div> -->
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>State</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
																</span>
															</div>
															<input #consignorStateName id="{{pageId}}consignorStateNameId"
																type="text" class="form-control"
																(selectItem)="clickListnerForConsignorStateName($event)"
																[(ngModel)]="modelConsignorStateName"
																[ngbTypeahead]="searchConsignorStateName"
																[resultFormatter]="formatterConsignorStateName"
																[inputFormatter]="formatterConsignorStateName"
																(focus)="focusConsignorStateNameTA$.next($any($event).target.value)"
																autocomplete="off" placeholder="Select State"
																(keyup)="fieldFocus($event, consignorStateCode)" />
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>State Code</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consignorGstNumber)"
															id="{{pageId}}consignorStateCode" #consignorStateCode
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>GST Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consignorMobileNumber)"
															id="{{pageId}}consignorGstNumber" #consignorGstNumber
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Mobile Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-phone"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consigneeName)"
															id="{{pageId}}consignorMobileNumber" #consignorMobileNumber
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
										</div>
										<hr style="width: 90%;">
										<h6 class="card-title">Consignee Details</h6>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consigneeAddress)"
															id="{{pageId}}consigneeName" #consigneeName
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Address</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-hashtag"></i>
															</span>
														</div>
														<textarea class="form-control" rows="1"
															(keyup)="fieldFocus($event, consigneeState)"
															id="{{pageId}}consigneeAddress" #consigneeAddress></textarea>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>State</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
																</span>
															</div>
															<input #consigneeStateName id="{{pageId}}consigneeStateNameId"
																type="text" class="form-control"
																(selectItem)="clickListnerForConsigneeStateName($event)"
																[(ngModel)]="modelConsigneeStateName"
																[ngbTypeahead]="searchConsigneeStateName"
																[resultFormatter]="formatterConsigneeStateName"
																[inputFormatter]="formatterConsigneeStateName"
																(focus)="focusConsigneeStateNameTA$.next($any($event).target.value)"
																autocomplete="off" placeholder="Select State"
																(keyup)="fieldFocus($event, consigneeStateCode)" />
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>State Code</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consigneeGstNumber)"
															id="{{pageId}}consigneeStateCode" #consigneeStateCode
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>GST Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, consigneeMobileNumber)"
															id="{{pageId}}consigneeGstNumber" #consigneeGstNumber
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Mobile Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-phone"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, deliveryMode)"
															id="{{pageId}}consigneeMobileNumber" #consigneeMobileNumber
															aria-describedby="basic-addon11" autocomplete="off">
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 				2()*%&^&^*&#^*(#^$(#&())) -->
									<div class="col-md-3 vl">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Pay</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="toPayFocus($event)" id="{{pageId}}toPay" #toPay
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Paid</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="paidFocus($event)" id="{{pageId}}paid" #paid
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
										</div>
										<hr style="width: 90%;">
										<h6 class="card-title">Billing Details</h6>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Delivery Mode</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="icon-home"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}deliveryMode"
															name="deliveryMode" #deliveryMode
															(change)="deliveryMethod(deliveryMode.value)">
															<option selected value="Godown Delivery">GD</option>
															<option value="Door Delivery">DD</option>
														</select>
													</div>
												</div>
											</div>
											<!--<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Pay</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="toPayFocus($event)" id="{{pageId}}toPay" #toPay
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Paid</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="paidFocus($event)" id="{{pageId}}paid" #paid
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>-->
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Local Charge</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="localChrgFocus($event)" id="{{pageId}}localCharge"
															#localCharge (change)="localChrgBlur($event)"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="hamaliDDView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Hamali DD</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="hamaliDdFocus($event)" id="{{pageId}}hamaliDd" #hamaliDd
															aria-describedby="basic-addon11"
															(change)="hamaliDdBlur($event)">
													</div>
												</div>
											</div>
											<div *ngIf="hamaliGDView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Hamlai GD</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="hamaliGdFocus($event)" id="{{pageId}}hamaliGd" #hamaliGd
															aria-describedby="basic-addon11"
															(change)="hamaliGdBlur($event)">
													</div>
												</div>
											</div>
											<div *ngIf="unloadingChgViewe" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Unloading Charges</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="unloadingChgFocus($event)" id="{{pageId}}unloadingCharges"
															#unloadingCharges aria-describedby="basic-addon11"
															(change)="unloadingChgBlur($event)">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Receipt Charge</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="receiptChgFocus($event)" id="{{pageId}}reciptCharges"
															#reciptCharges aria-describedby="basic-addon11"
															(change)="receiptChgBlur($event)">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Service Charge</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="serviceChgFocus($event)" id="{{pageId}}serviceCharges"
															#serviceCharges aria-describedby="basic-addon11"
															(change)="serviceChgBlur($event)">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Demurrage</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="demurrageFocus($event)" id="{{pageId}}demurrage"
															#demurrage aria-describedby="basic-addon11"
															(change)="demurrageBlur($event)">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Others</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control" id="{{pageId}}others"
															(keyup)="othersFocus($event)" #others
															(change)="othersBlur($event)"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Discount</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															(keyup)="discountFocus($event)" id="{{pageId}}discount" #discount
															aria-describedby="basic-addon11"
															(change)="discountBlur($event)">
													</div>
												</div>
											</div>
											<!-- 										<div class="col-sm-12 col-md-12"> -->
											<!-- 											<div class="form-group"> -->
											<!-- 												<div class="input-group"> -->
											<!-- 													<label>Sub Total</label> -->
											<!-- 													<div class="input-group-prepend"> -->
											<!-- 														<span class="input-group-text"> <i -->
											<!-- 															class="fas fa-rupee-sign"></i> -->
											<!-- 														</span> -->
											<!-- 													</div> -->
											<!-- 													<input type="number" class="form-control" id="{{pageId}}subTotal" -->
											<!-- 														#subTotal aria-describedby="basic-addon11"> -->
											<!-- 												</div> -->
											<!-- 											</div> -->
											<!-- 										</div> -->
											<div class="col-sm-12 col-md-12 ">
												<div class="form-group ">
													<div class="input-group ">
														<label>Grand Total</label>
														<div class="input-group-prepend ">
															<span class="input-group-text "> <i
																class="fas fa-rupee-sign "></i>
															</span>
														</div>
														<input type="number" class="form-control " id="{{pageId}}grandTotal"
															#grandTotal aria-describedby="basic-addon11 " readonly>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 				359658a98&*((*&&$(&(*%$*(&$()&%()))))) -->
									<div class="col-md-3 vl">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<h6 class="card-title">GST Details</h6>
												<div class="form-group">
													<div class="input-group">
														<label>CGST</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11" id="{{pageId}}cgst" #cgst>&nbsp;
														<input type="number" class="form-control" id="{{pageId}}cgstAmt"
															#cgstAmt aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>SGST</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11" id="{{pageId}}sgst" #sgst>&nbsp;
														<input type="number" class="form-control" id="{{pageId}}sgstAmt"
															#sgstAmt aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>IGST</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11" id="{{pageId}}igst" #igst>&nbsp;
														<input type="number" class="form-control" id="{{pageId}}igstAmt"
															#igstAmt aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>GST Total</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control" #gstTotal
															id="{{pageId}}gstTotal" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<hr style="width: 80%;">
											<div class="col-sm-12 col-md-12">
												<h6 class="card-title">Collection Details</h6>
												<div class="form-group">
													<div class="input-group">
														<label>Collection Man</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input #collectionMan id="{{pageId}}collectionManId" type="text"
															class="form-control"
															(selectItem)="clickListnerForCollectionMan($event)"
															[(ngModel)]="modelCollectionMan"
															[ngbTypeahead]="searchCollectionMan"
															[resultFormatter]="formatterCollectionMan"
															[inputFormatter]="formatterCollectionMan"
															(focus)="focusCollectionManTA$.next($any($event).target.value)"
															(keyup)="collectionManMethod($any($event).target.value,$event)"
															autocomplete="off" />
													</div>
												</div>
											</div>
											<div *ngIf="viewNewCollectionMans"
												class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>New Collection Man</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															id="{{pageId}}newCollectionMan" #newCollectionMan
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Area </label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-home"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, godown)" id="{{pageId}}area" #area
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Godown</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-home"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															(keyup)="fieldFocus($event, savenprintBtn)" id="{{pageId}}godown"
															#godown aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-md-12" style="text-align: center;">
												<br> <br>
												<button type="submit" class="btn btn-dark"
													id="{{pageId}}savenprintBtn" #savenprintBtn (click)="btnSave()">Save
													& Print</button>
												<button type="submit" class="btn btn-success m-r-10"
													#clearBtn id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
											</div>
										</div>
									</div>
									<!-- 				end 45039582689 -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCashMemoPrint" onafterprint="afterPrint()"
		id="{{pageId}}printDummyCashMemo">
		<app-cashmemo-genrate-print></app-cashmemo-genrate-print>
	</div>
</body>