<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Vehicle Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-truck"></i>
                                                        </span>
                                                    </div>
                                                    <input #vehicleType id="{{pageId}}vehicleType" type="text" class="form-control" (selectItem)="clickListnerForVehicleType($event)" [(ngModel)]="modelVehicleType" [ngbTypeahead]="searchVehicleType" [resultFormatter]="formatterVehicleType" [inputFormatter]="formatterVehicleType"
                                                        (focus)="focusVehicleTypeTA$.next($any($event).target.value)" placeholder="Select Type " />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                    <label>Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                        class="fas fa-map-marker-alt"></i>
                                                                    </span>
                                                    </div>
                                                    <input id="{{pageId}}otherChargeArea" type="text" class="form-control" (selectItem)="clickListnerForOtherChargeArea($event)" [(ngModel)]="modelOtherChargeArea" [ngbTypeahead]="searchOtherChargeArea" [resultFormatter]="formatterOtherChargeArea" [inputFormatter]="formatterOtherChargeArea"
                                                        (focus)="focusOtherChargeAreaTA$.next($any($event).target.value)" placeholder="Select the Area" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Driver Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                    </span>
                                                    </div>
                                                    <input #otherChgAmt type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}otherChgAmt" name="otherChgAmt">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Early Morning Trip Extra Amount</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                    </span>
                                                    </div>
                                                    <input #earlyMrngTripExtAmt type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}earlyMrngTripExtAmt" name="earlyMrngTripExtAmt">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12" style="text-align: end;">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateSaveDetails()">Save</button>
                                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="box-body" style="text-align: center;">
                                <table datatable id="{{pageId}}otherChargeDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsOtherChargeDatatable" [dtTrigger]="dtTriggerOtherChargeDatatable">
                                    <thead>
                                        <tr>
                                            <th>Vehicle Type</th>
                                            <th>Area</th>
                                            <th>Driver Charges</th>
                                            <th>Early Morning Trip Extra Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let areaChargeDetailsData of areaChargeDetailsDataList; let i = index" (click)="rowSelectedOtherChargeDataOnEdit(areaChargeDetailsData,i);">
                                            <td>{{ areaChargeDetailsData.truckType }}</td>
                                            <td>{{ areaChargeDetailsData.area }}</td>
                                            <td>{{ areaChargeDetailsData.driverChg }}</td>
                                            <td>{{ areaChargeDetailsData.mrngTripExtAmt }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div><br>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>