export class OurBranchesDto {
    branchId: number;
    branchName: string;
    address: string;
    area: string;
    city: string;
    pincode: string;
    contactPerson: string;
    contactNumber1: string;
    contactNumber2: string;
    fax: string;
    email: string;
    bookingOfficeMobile: number;
    deliveryOfficeMobile: number;
    stationType: number;
    bookingOffice: number;
    transhipmentOffice: number;
    collectionOffice: number;
    deliveryOffice: number;
    enterdate: string;
    enterby: string;
    column: string;
    routeId: number;
    fromCity: string;
    toCity: string;
    mode: string;
    latitude: string;
    longitude: string;
	status:string
}