import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MasterService } from "src/app/dataService/master-service";

@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerArea: Subject<any> = new Subject();
    dtOptionsArea: any;
    areaDataList: any;
    pageId = "aract";
    lrDto: LRDto = new LRDto();
    showSpinnerForAction: any;
    lrDtoSearch: any;
    lrDtoSaveRet: any;
    areaName; any;
    substations: any;

    constructor(private http: HttpClient, private masterReadService: MasterReadService, private masterService: MasterService,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getAreaList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsArea = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }

    }

    ngOnDestroy(): void {
        this.dtTriggerArea.unsubscribe();
    }

    getAreaDetailsRead() {
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSearch.office = this.userDataDtoReturnSession.office;
        this.lrDtoSearch.branchType = "local";
        // lrDto.setCompanyId(SRDWeb.companyid);

        console.log("this.lrDtoSearch");
        console.log(this.lrDtoSearch);
    }

    getAreaList = () => {
        this.getAreaDetailsRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getAreaDetails(this.lrDtoSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.areaDataList = response;
                    console.log("this.areaDataList");
                    console.log(this.areaDataList);
                    $("#" + this.pageId + "areaDatatableId").DataTable().destroy();
                    this.dtTriggerArea.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Area Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    saveArea() {
        this.lrDto = new LRDto();
        this.areaName = $("#" + this.pageId + "areaName").val();
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.office = this.userDataDtoReturnSession.office;
        this.lrDto.subStations = this.areaName;
        this.lrDto.branchType = "local";
        console.log("this.lrDto");
        console.log(this.lrDto);
    }

    saveAreaDetails = () => {
        this.saveArea();
        this.masterService.saveAreaDetail(this.lrDto).
            subscribe((data) => {
                this.lrDtoSaveRet = data;
                console.log("this.lrDtoSaveRet");
                console.log(this.lrDtoSaveRet);
                if (this.lrDtoSaveRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "Area Name saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else if (this.lrDtoSaveRet.status == "Exist") {
                    swal("Duplicate Entry", "Entered Area Name is already Exist", "info");
                }  else {
                    swal({
                        title: "Error",
                        text: "Error While Save Area Name",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Save Area Name",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    confirmSave() {
        this.areaName = $("#" + this.pageId + "areaName").val();
        if (this.areaName == null || this.areaName == '' || this.areaName == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please entered Area Name! ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Save",
                text: "Sure want to Save",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((saveYes) => {
                if (saveYes) {
                    this.saveAreaDetails();
                }
            });
        }

    }

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        this.areaName = null;
    }


}