import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
import { DatePipe } from "@angular/common";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-agent-stock-report',
	templateUrl: './agent-stock-report.component.html',
	styleUrls: ['./agent-stock-report.component.css']
})
export class AgentStockReportComponent implements OnInit {

	//for popup modal starts
	closeResultDatatable: string;
	//for popup modal ends
	//for datatable starts
	gettingDataFrmServiceFrPendingLrDetailsTable: any;


	agentStockSummaryDataList: any;
	validationForUnCheckLrs: any;


	onDestroyUnsubscribtionPendingLrDetails: Subscription;

	//for datatable ends

	//summaryTable:any;

	loadingIndicator = true;

	//for datatable starts
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerAgentStockSummary: Subject<any> = new Subject();

	dataTable: any;
	dtOptionsAgentStockSummary: any;
	dataForPopupFromAgtStcSum: any;
	dataForPopupFromAgtStcSumOtherDetails: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	getStocksAt: any;
	viewType = 'All Lrs';

	setUrgentLrDto: LRDto = new LRDto();
	setUndoLrDto: LRDto = new LRDto();
	lrDtoForWithMainStation: LRDto = new LRDto();
	lrDtoForWithOutMainStation: LRDto = new LRDto();
	showSpinnerForAction = false;
	lrDtoList: Array<any> = [];
	getMainOrLocal: any;
	detailsDashboardMode: string = 'No';
	office: any;
	curtime: any;
	todayDate: any;

	///For Custom Print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

	//
	artSummary: number;
	actWtSummary: number;
	chgWtSummary: number;
	toPaySummary: number;
	paidSummary: number;
	viewCustomPrintV1 = false;

	//for datatable ends
	pageId = "agrc";
	destination: any;
	lrDtoForWithDestination: LRDto = new LRDto();
	constructor(/* for datatable starts */private dashboardService: DashboardService, private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef,/* for datatable endss */
		//for modal starts
		// public dialogRef: MatDialogRef<AgentStockReportComponent>,
		// @Inject(MAT_DIALOG_DATA) public data: DialogData
		//for modal ends
	) {
		console.log(localStorage);

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.dataForPopupFromAgtStcSum = JSON.parse(localStorage.getItem('stocksSummaryWithoutMainStationForPopup'));
			// this.dataForPopupFromAgtStcSumOtherDetails = JSON.parse(localStorage.getItem('stocksSummaryWithoutMainStationOtherPopup'));
			this.curtime = this.datePipe.transform(new Date(), "h:mm a");
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			localStorage.clear();
			console.log('1');

			if (this.dataForPopupFromAgtStcSum != null) {
				this.getStocksAt = this.dataForPopupFromAgtStcSum.stocksAt;
				this.getMainOrLocal = this.dataForPopupFromAgtStcSum.status;
				this.detailsDashboardMode = this.dataForPopupFromAgtStcSum.mode;
				this.destination=this.dataForPopupFromAgtStcSum.destination;
				console.log(this.getStocksAt, this.getMainOrLocal);
				console.log("Destination"+ this.destination);

				if (this.getMainOrLocal != null && this.getMainOrLocal == 'Main') {
					this.getAgentDetailsWithMainStation();
				}
				else if (this.getMainOrLocal != null && this.getMainOrLocal == 'DestinationWise') {
					this.getAgentDetailsWithDestination();
				} else {
					this.getAgentDetailsWithOutMainStation();
				}
			}

			this.dataForPopupFromAgtStcSumOtherDetails = [];
			this.dataForPopupFromAgtStcSum = '';
			// this.viewAllLrsCustomPrint();
		}

	}
	/* for datatable starts */
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	/* for datatable ends */
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var stocksat = this.getStocksAt;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		var officePrt = this.office;
		var cuurentTimePrt = this.curtime;
		var groupColumnDestination = 0;
		console.log(cuurentTimePrt);
		this.dtOptionsAgentStockSummary = {
			dom: 'Bfrtip',
			buttons: [
				/*{
					extend: 'print',
					text: '<i class="fas fa-print">Print</i>',
					titleAttr: 'Print',
					customize : function(win) {
						$(win.document.body).css('font-size','10pt');
						$(win.document.body).find('th, td').
						css('font-family','Arial, Helvetica, sans-serif')
						.css('font-size','13px').css('text-align','center');
					},
					footer : true,
					exportOptions: {
							columns: [2,11,12,5,6,1,9,7,10,13,14,15,16,17,3,4,18]
							},
					messageTop : function() {
						var returnValOverAll=null;
						var today = new Date();
						 cuurentTimePrt = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
							returnValOverAll ="<br><table style='width: 100%;'>" +
												"<tr>" +
												"<td style='width: 20%;'>" +
												"<table>" +
												"<tr>" +
												"<td width='8%' style='text-align:left !important;'>" +
												"<strong style='font-size:15px;'>Date As On &nbsp;&nbsp;</strong>" +
												"</td>" +
												"<td width='2%' style='text-align:left !important;'>" +
												"<strong style='font-size:15px;'>:</strong>" +
												"</td>" +
												"<td width='10%' style='text-align:left !important;'>" +
												"<strong style='font-size:15px;'>" + dateformate+" "+cuurentTimePrt+"</strong>" +
												"</td>" +
												"</tr>" +
												"</table>" +
												"</td>" +
												"<td rowspan='2'style='width:60%;word-wrap: break-word;text-align:left !important;'align='left'>" +
												"<strong style='font-size:23px;'><u> "+stocksat+" - Stock Report Details </u></strong><br>" +
												"</td>" +
												"<td  align='left' style='width:20%;'>" +
												"</td>" +
												"</tr>" +
												"</table><br>";
								return returnValOverAll;
								
						},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},*/
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					exportOptions: {
						columns: [2, 11, 12, 5, 6, 1, 9, 7, 10, 13, 14, 15, 16, 17, 3, 4, 18]
					},
					title: function () {
						var today = new Date();
						cuurentTimePrt = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
						var returnSummaryExcelCashmemoStockSum = null;
						returnSummaryExcelCashmemoStockSum = stocksat + " - Stock Report Details As On : " + dateformate + " " + cuurentTimePrt;

						return returnSummaryExcelCashmemoStockSum;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			pagingType: 'full_numbers',
			//pageLength: 5,
			processing: true,
			"scrollX": true,
			"scrollCollapse": true,
			responsive: true,
			"scrollY": 380,
			"paging": false,
			"info": false,
			"drawCallback": function (settings) {
				var api = this.api();
				var rows = api.rows({
					page: 'current'
				}).nodes();
				var last = null;
				var count = 0;
				var articles = 0;
				var actWgt = 0;
				var chgWgt = 0;
				var toPay = 0;
				var paid = 0;
				api.column(groupColumnDestination, {
					page: 'current'
				}).data().each(function (group, i) {

					var val = api.row(api.row($(rows).eq(i)).index()).data();
					if (last !== group) {
						if (i != 0) {
							$(rows)
								.eq(i)
								.before(
									$(
										"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": "Total : " + count
											}))
										.append($("<td></td>",
											{
												"colspan": 7,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": articles
											}))
										.append($("<td></td>",
											{
												"colspan": 2,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": actWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": chgWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": toPay
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": paid
											}))
										.append($("<td></td>",
											{
												"colspan": 3,
												"text": ""
											}))
										.prop('outerHTML'));

							count = 0;
							articles = 0;
							actWgt = 0;
							chgWgt = 0;
							toPay = 0;
							paid = 0;
						}
						$(rows)
							.eq(i)
							.before(
								$(
									"<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
									{
										"class": "group",
										"data-id": group
									})
									.append(
										$("<td></td>",
											{
												"colspan": 21,
												"text": group
											})).prop('outerHTML'));
						last = group;
					}
					count++;
					articles += +val[11];
					actWgt += +val[14];
					chgWgt += +val[15];
					toPay += +val[16];
					paid += +val[17];

					if (i == (rows.length - 1)) {

						$(rows)
							.eq(i)
							.after(
								$(
									"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
									{
										"class": "group",
										"data-id": group
									})
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": ""
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": ""
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": "Total : " + count
										}))
									.append($("<td></td>",
										{
											"colspan": 7,
											"text": ""
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": articles
										}))
									.append($("<td></td>",
										{
											"colspan": 2,
											"text": ""
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": actWgt
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": chgWgt
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": toPay
										}))
									.append($("<td></td>",
										{
											"colspan": 1,
											"text": paid
										}))
									.append($("<td></td>",
										{
											"colspan": 3,
											"text": ""
										}))
									.prop('outerHTML'));
						count = 0;
						articles = 0;
						actWgt = 0;
						chgWgt = 0;
						toPay = 0;
						paid = 0;
					}
				});
			},
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totArt = api.column(11).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actWgt = api.column(14).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chgWt = api.column(15).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(16).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(17).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html();
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html('Total : ' + data.length);
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
				$(api.column(7).footer()).html();
				$(api.column(8).footer()).html();
				$(api.column(9).footer()).html();
				$(api.column(11).footer()).html(totArt);
				$(api.column(11).footer()).html();
				$(api.column(12).footer()).html();
				$(api.column(13).footer()).html();
				$(api.column(14).footer()).html(actWgt);
				$(api.column(15).footer()).html(chgWt);
				$(api.column(16).footer()).html(toPay);
				$(api.column(17).footer()).html(paid);
				$(api.column(18).footer()).html();
				$(api.column(19).footer()).html();
				$(api.column(20).footer()).html();

			}
		}


	}

	ngOnDestroy(): void {
		this.dtTriggerAgentStockSummary.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerAgentStockSummary.next();

	}

	//for modal starts
	onCancelClick(): void {
		// this.dialogRef.close();

		// this.viewCustomPrintV1 = true;
		// window.addEventListener('afterprint', (onclick) => {
		// 	if (this.viewCustomPrintV1) {
		// 		this.viewCustomPrintV1 = false;
		// 		localStorage.clear();
		// 	}
		// });
	}
	//for modal ends
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	markUrgentLrs() {
		if (this.lrDtoList.length == 0) {
			swal({
				title: "Warning",
				text: "No records selected to mark as Urgent loading ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.setUrgentLrDto = new LRDto();
			this.setUrgentLrDto.list = this.lrDtoList;
			this.setUrgentLrDto.isUrgentDly = true;
			console.log(this.setUrgentLrDto);
			this.markUrgentLrsInfo();
		}


	}

	markUrgentLrsInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.markLrUrgentDelivery(this.setUrgentLrDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				if (response.status == 'Success') {

					swal({
						title: "Urgent LRs",
						text: "Selected Lrs are marked as Urgent loading ",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.getAgentInfo();
				} else {
					swal({
						title: "Urgent LRs",
						text: "Failed to marked the Selected Lrs as Urgent loading",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.getAgentInfo();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server error occurred while marked the Selected Lrs as Urgent loading", "info");
			}, () => console.log('done');
	};
	undoUrgentLrs() {
		if (this.lrDtoList.length == 0) {
			swal({
				title: "Warning",
				text: "No  Lrs Selected to  Remove From Urgent loading ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.setUndoLrDto = new LRDto();
			this.setUndoLrDto.list = this.lrDtoList;
			this.setUndoLrDto.isUrgentDly = false;
			console.log(this.setUndoLrDto);
			this.undoUrgentLrsInfo();
		}
	}
	undoUrgentLrsInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.markLrUrgentDelivery(this.setUndoLrDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				if (response.status == 'Success') {

					swal({
						title: "Undo LRs",
						text: "Selected Lrs are removed From Urgent Loading ",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.getAgentInfo();
				} else {
					swal({
						title: "Undo LRs",
						text: "Failed to marked the Selected Lrs as removed From Urgent Loading",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.getAgentInfo();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while removing the Urgent Loading Lrs", "info");
			}, () => console.log('done');
	};
	viewAllLrs() {
		this.viewType = 'All Lrs';
		$("#" + this.pageId + "agentStockSummaryId").DataTable().destroy();
		this.agentStockSummaryDataList = [];
		this.agentStockSummaryDataList = this.validationForUnCheckLrs;
		this.dtTriggerAgentStockSummary.next();
	}

	viewUrgentLrs() {
		this.viewType = 'Urgent Lrs';
		$("#" + this.pageId + "agentStockSummaryId").DataTable().destroy();
		this.agentStockSummaryDataList = [];
		for (let i = 0; i < this.validationForUnCheckLrs.length; i++) {
			if ((this.validationForUnCheckLrs[i].isUrgentDly == true && this.validationForUnCheckLrs[i].isUrgentDlyLoaded == false) || (this.validationForUnCheckLrs[i].isNewConsignee == true)) {
				this.agentStockSummaryDataList.push(this.validationForUnCheckLrs[i]);
			}
		}
		this.dtTriggerAgentStockSummary.next();
		console.log(this.agentStockSummaryDataList);

	}

	rowCheckBoxChecked(e, agentStockSummaryData) {
		if (e.currentTarget.checked) {
			if (this.lrDtoList.length == 0) {
				this.lrDtoList = [];
				this.lrDtoList.push(agentStockSummaryData.lrNumber);
			} else {
				this.lrDtoList.push(agentStockSummaryData.lrNumber);
			}
		} else {
			let index = this.lrDtoList.indexOf(agentStockSummaryData.lrNumber);
			if (index > -1) {
				this.lrDtoList.splice(index, 1);
			}
		}
		console.log(this.lrDtoList);
	}


	getAgentInfo() {
		this.lrDtoList = [];
		console.log(this.getMainOrLocal);
		if (this.getMainOrLocal != null && this.getMainOrLocal == 'Main') {
			this.getAgentDetailsWithMainStation();
		} else {
			this.getAgentDetailsWithOutMainStation();
		}

	}
	getAgentDetailsWithMainStation() {

		this.lrDtoForWithMainStation = new LRDto();
		this.lrDtoForWithMainStation.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForWithMainStation.stocksAt = this.getStocksAt;
		this.lrDtoForWithMainStation.rights = null;
		this.lrDtoForWithMainStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForWithMainStation.stationType = this.userDataDtoReturnSession.officeType;
		console.log(this.lrDtoForWithMainStation);
		this.getAgentDetailsWithMainStationInfo();
	}
	getAgentDetailsWithMainStationInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockReportDetailsGrpMainSrc(this.lrDtoForWithMainStation).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "agentStockSummaryId").DataTable().destroy();
				this.agentStockSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.agentStockSummaryDataList = response;
					this.validationForUnCheckLrs = response;
					console.log(this.agentStockSummaryDataList);
				}
				this.dtTriggerAgentStockSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Stocks Details", "info");
			}, () => console.log('done');
	};

	getAgentDetailsWithOutMainStation() {
		this.lrDtoForWithOutMainStation = new LRDto();
		this.lrDtoForWithOutMainStation.destination = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForWithOutMainStation.stocksAt = this.getStocksAt;
		this.lrDtoForWithOutMainStation.rights = null;
		this.lrDtoForWithOutMainStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForWithOutMainStation.stationType = this.userDataDtoReturnSession.officeType;
		console.log(this.lrDtoForWithOutMainStation);
		this.getAgentDetailsWithOutMainStationInfo();
	}
	getAgentDetailsWithOutMainStationInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockReportDetails(this.lrDtoForWithOutMainStation).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "agentStockSummaryId").DataTable().destroy();
				this.agentStockSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.agentStockSummaryDataList = response;
					this.validationForUnCheckLrs = response;
					console.log(this.agentStockSummaryDataList);
					if (this.detailsDashboardMode == 'No') {
						this.viewAllLrsCustomPrint();
					}
				}
				this.dtTriggerAgentStockSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Stocks  Details", "info");
			}, () => console.log('done');
	};


	viewAllLrsCustomPrint() {

		localStorage.clear();
		this.cashMemoDtoForCustomPrintList = [];

		this.cashMemoDtoForCustomPrintListColumnNames = ["LR No.", "Source", "Destination", "Bkg Date", "Ent Date", "D Urg", "Urg Mrk On", "Diff", "Art", "Descrip", "Act Wt", "Chg Wt", "To Pay", "Paid", "Cnsgne", "Cnsgnor", "Cnsgne Sts"];
		this.cashMemoDtoForCustomPrintListColumnWidths = [9, 7, 9, 6, 7, 3, 6, 5, 3, 7, 4, 4, 6, 4, 8, 8, 4];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
			this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
			this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
		}

		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];
		this.artSummary = this.actWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = 0;
		for (let i = 0; i < this.agentStockSummaryDataList.length; i++) {
			let dlvurg = (this.agentStockSummaryDataList[i].isNewConsignee == true ?
				"Urg" : (this.agentStockSummaryDataList[i].isUrgentDly == true &&
					this.agentStockSummaryDataList[i].isUrgentDlyLoaded == false) ? "Urg"
					: "Nor");
			console.log(dlvurg);
			let cnssts = (this.agentStockSummaryDataList[i].isNewConsignee == true ?
				"NWPTY" : "");
			console.log(cnssts);
			this.cashMemoDtoForCustomPrintListColumnValues = [this.agentStockSummaryDataList[i].lrNumber, this.agentStockSummaryDataList[i].source, this.agentStockSummaryDataList[i].destination, this.agentStockSummaryDataList[i].bookingDateStr, this.agentStockSummaryDataList[i].actLrEntryDateStr, dlvurg, this.agentStockSummaryDataList[i].urgentDlyMarkedAtStr,
			this.agentStockSummaryDataList[i].diffDays, this.agentStockSummaryDataList[i].totalArticles, this.agentStockSummaryDataList[i].description, this.agentStockSummaryDataList[i].actualWeight, this.agentStockSummaryDataList[i].chargedWeight, this.agentStockSummaryDataList[i].toPay, this.agentStockSummaryDataList[i].paid, this.agentStockSummaryDataList[i].consigneeName, this.agentStockSummaryDataList[i].consignorName, cnssts];

			this.artSummary = this.artSummary + this.agentStockSummaryDataList[i].totalArticles;
			this.actWtSummary = this.actWtSummary + this.agentStockSummaryDataList[i].actualWeight;
			this.chgWtSummary = this.chgWtSummary + this.agentStockSummaryDataList[i].chargedWeight;
			this.toPaySummary = this.toPaySummary + this.agentStockSummaryDataList[i].toPay;
			this.paidSummary = this.paidSummary + this.agentStockSummaryDataList[i].paid;
			this.cashMemoDtoForCustomPrintData = new CashMemoDto();


			this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
			this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentStockSummaryDataList.length, "", "", "", "", "", "", "", this.artSummary, "", this.actWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, "", "", ""]
		}

		//heading logics
		this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy h:mm a");
		//this.curtime=this.datePipe.transform(new Date(), "h:mm a");
		this.cashMemoDtoForCustomPrintListHeadingV1 = [];

		this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
		this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
			this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
			this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
		}
		localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
		localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
		localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
		localStorage.setItem('printTitle', this.getStocksAt + " - Stock Report Details");
		//window.open('/report/cashmemo-report/customDynamicPrintV1', '_blank');
		this.viewCustomPrintV1 = true;
		window.addEventListener('afterprint', (onclick) => {
			if (this.viewCustomPrintV1) {
				this.viewCustomPrintV1 = false;
				localStorage.clear();
			}
		});


	}
	// printOnClose(){
	// 	this.viewAllLrsCustomPrint();
	// localStorage.setItem('screenName', "Stock Summary Dashboard");
	// ?this.dialogRef.close();

	// this.viewCustomPrintV1 = true;
	// window.addEventListener('afterprint', (onclick) => {
	// 	if (this.viewCustomPrintV1) {
	// 		this.viewCustomPrintV1 = false;
	// 		localStorage.clear();
	// 	}
	// });
	// }

	getAgentDetailsWithDestination() {
		this.lrDtoForWithDestination = new LRDto();
		this.lrDtoForWithDestination.destination = this.destination;
		this.lrDtoForWithDestination.stocksAt = this.getStocksAt;
		this.lrDtoForWithDestination.rights = null;
		this.lrDtoForWithDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForWithDestination.stationType = 'Delivery Office';
		console.log(this.lrDtoForWithDestination);
		this.getAgentDetailsWithDestinationInfo();
	}
	getAgentDetailsWithDestinationInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getAgentStockReportDetails(this.lrDtoForWithDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "agentStockSummaryId").DataTable().destroy();
				this.agentStockSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.agentStockSummaryDataList = response;
					this.validationForUnCheckLrs = response;
					console.log(this.agentStockSummaryDataList);
					if (this.detailsDashboardMode == 'No') {
						this.viewAllLrsCustomPrint();
					}
				}
				this.dtTriggerAgentStockSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Stocks  Details", "info");
			}, () => console.log('done');
	};

}
/*(this.agentStockSummaryDataList[i].isNewConsignee == true ?
	"Urgent":(this.agentStockSummaryDataList[i].isUrgentDly == true &&
	this.agentStockSummaryDataList[i].isUrgentDlyLoaded == false )?"Urgent"
	:"Normal")*/