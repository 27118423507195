<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"
	(click)="gridReconifgureOnReloadBtn()" area-hidden="true"></i> -->
<div class="col-md-12 col-sm-12">
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
                <div class="col-md-8">
                    <h6 class="card-title">
                        <i class=" fas fa-file-alt"></i>&nbsp;Stocks Summary
                    </h6>

                </div>
                <div class="col-md-4">
                    <i title="Reload" class="fas fa-sync-alt fa xs cli" style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;" (click)="gridReconifgureOnReloadBtn()"></i>
                </div>
            </div>
        </div>

    </div>
    <div class="box-body">
        <table datatable id="{{pageId}}stocksSummaryId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStocksSummary" [dtTrigger]="dtTriggerStocksSummary">

            <thead>
                <tr>
                    <th>Source</th>
                    <th>Act Wt</th>
                    <th>Chg Wt</th>
                </tr>
            </thead>
            <tbody>
                <tr class="cli" (click)="getAgentInfo(stocksSummaryData);" *ngFor="let stocksSummaryData of stocksSummaryDataList ">
                    <td>{{ stocksSummaryData.stocksAt }}</td>
                    <td>{{ stocksSummaryData.actualWeight }}</td>
                    <td>{{ stocksSummaryData.chargedWeight }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div> -->