import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';

@Component({
    selector: 'app-lorry-hire-balance-rpt-print',
    templateUrl: './lorry-hire-balance-rpt-print.component.html',
    styleUrls: ['./lorry-hire-balance-rpt-print.component.css']
})
export class LorryHireBalanceReportPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    dataForLorryHireBalanceRpt: any;
    invoicedate: any;
    invoiceNumber: any;
    hireslipNumber: any;
    supplierName: any;
    drivername: any;
    brokerName: any;
    lorryNo: any;
    bankName: any;
    chqNo: any;
    chqDate: any;
    lorryHireAmt: any;
    lessAdvance: any;
    lessLateArrival: any;
    lessUnloadHamali: any;
    lessMunshi: any;
    mainBookingStation: any
    toStation: any;
    n: any;
    num: any;
    totalAmount: any;
    printAmt: any;

    hireslipdataForPrint: HireSlipDto = new HireSlipDto();

    address: any;
    image: any;
    pageId = "lhbrp";
    showHireColumns: Boolean = false;

    constructor(private router: Router, private datePipe: DatePipe,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BalPayment Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
            this.dataForLorryHireBalanceRpt = JSON.parse(localStorage.getItem('lorryHireBalanceVoucherPrint'));
            if (this.dataForLorryHireBalanceRpt != null) {
                this.hireslipdataForPrint = this.dataForLorryHireBalanceRpt;
            }
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.image = this.userDataDtoReturnSession.ip;
            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {

        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
        this.mainBookingStation = this.hireslipdataForPrint.mainBookingStation == null ? ' ' : this.hireslipdataForPrint.mainBookingStation;
        this.toStation = this.hireslipdataForPrint.toStation == null ? ' ' : this.hireslipdataForPrint.toStation;
        this.invoiceNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.hireslipNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.supplierName = this.hireslipdataForPrint.suppliername == null ? ' ' : this.hireslipdataForPrint.suppliername;
        this.drivername = this.hireslipdataForPrint.drivername == null ? ' ' : this.hireslipdataForPrint.drivername;
        this.brokerName = this.hireslipdataForPrint.brokerName == null ? ' ' : this.hireslipdataForPrint.brokerName;
        this.lorryNo = this.hireslipdataForPrint.vehicleNumber == null ? ' ' : this.hireslipdataForPrint.vehicleNumber;

        if (this.hireslipdataForPrint.invoicedate != null) {
            this.invoicedate = this.datePipe.transform(this.hireslipdataForPrint.invoicedate, "dd-MM-yyyy");
        } else {
            this.invoicedate = '';
        }
        this.bankName = this.hireslipdataForPrint.bankname == null ? ' ' : this.hireslipdataForPrint.bankname;

        this.chqNo = this.hireslipdataForPrint.chequeno == null ? ' ' : this.hireslipdataForPrint.chequeno;


        if (this.hireslipdataForPrint.chequedate != null) {
            this.chqDate = this.datePipe.transform(this.hireslipdataForPrint.chequedate, "dd-MM-yyyy");
        } else {
            this.chqDate = '';
        }
        this.lorryHireAmt = this.hireslipdataForPrint.totalhire == null ? 0.0 : this.hireslipdataForPrint.totalhire;
        this.lessAdvance = this.hireslipdataForPrint.advance == null ? 0.0 : this.hireslipdataForPrint.advance;
        this.lessLateArrival = this.hireslipdataForPrint.lesslatearrival == null ? 0.0 : this.hireslipdataForPrint.lesslatearrival;
        this.lessUnloadHamali = this.hireslipdataForPrint.lessunloadhamali == null ? 0.0 : this.hireslipdataForPrint.lessunloadhamali;
        this.lessMunshi = this.hireslipdataForPrint.lessmunishiana == null ? 0.0 : this.hireslipdataForPrint.lessmunishiana;
        this.calculateTotalAmt();
        if (this.totalAmount == 0 || this.totalAmount == 0.0) {
            this.printAmt = "ZERO";
        } else {
            this.printAmt = this.inWords();
        }
    }
    calculateTotalAmt() {
        this.totalAmount = +this.lorryHireAmt + -this.lessAdvance + -this.lessLateArrival + -this.lessUnloadHamali + -this.lessMunshi;
    }
    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = this.totalAmount;
        //        console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(
            /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }
    clearField() {
        this.address = '';
        this.dataForLorryHireBalanceRpt = '';
        this.mainBookingStation = '';
        this.toStation = '';
        this.invoiceNumber = '';
        this.hireslipNumber = '';
        this.supplierName = '';
        this.drivername = '';
        this.brokerName = '';
        this.lorryNo = '';
        this.invoicedate = '';
        this.bankName = '';
        this.chqNo == '';
        this.chqDate = '';
        this.lorryHireAmt = 0.0;
        this.lessAdvance = 0.0;
        this.lessLateArrival = 0.0;
        this.lessUnloadHamali = 0.0;
        this.lessMunshi = 0.0;
        this.totalAmount = 0.0;
        this.printAmt = '';

    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
