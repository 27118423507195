<!-- Row -->

<body>
    <div class="row system_responsive" style="margin-bottom: 10px;" id="{{pageId}}showHireBlnRpt" *ngIf="isLoggedIn">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <hr style="margin: 0px;">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                                </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12" [hidden]="!viewAlreadyReward">
                                        <h5 class="cstm_efftect" id="{{pageId}}isAlreadyRewardedHeadingId">
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="card-body">
                            <h6 class="card-title">Trip Details</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Scan Code</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}scanCode" autocomplete="off" (keypress)="getScanCodeFieldsDetails($event,hireslipBalancePopUpTemplate);" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Hireslip Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}hireslipNumber" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyHireslipNo">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Invoice Date</label> <input id="{{pageId}}invoiceDates" class="form-control" placeholder="yyyy-mm-dd" name="invoiceDates" ngbDatepicker [(ngModel)]="invoiceDatesModal" #invoiceDates="ngbDatepicker" autocomplete="off"
                                                [readonly]="isReadonlyInvoiceDate">
                                            <div class="input-group-append" (click)="invoiceDates.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
												</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Source</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-box"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}source" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlySource">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Destination</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-road"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}destination" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyDestination">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <hr> -->
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card-body">
                            <h6 class="card-title">Driver Details</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Vehicle Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-truck"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}vehicleNumber" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyVehicleNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Supplier Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}supplierName" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlySupplierName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Broker Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}brokerName" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyBrokerName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Driver Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}driverName" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyDriverName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Mobile Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-phone"></i>
												</span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}mobileNumber" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyMobileNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>License Number </label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-id-card"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}licenseNumber" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyLicenseNumber">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card-body">
                            <h6 class="card-title">Time Details</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Departure Date</label> <input id="{{pageId}}departureDates" class="form-control" placeholder="yyyy-mm-dd" name="departureDates" ngbDatepicker [(ngModel)]="departureDatesModal" #departureDates="ngbDatepicker"
                                                autocomplete="off" [readonly]="isReadonlyDepartureDate">
                                            <div class="input-group-append" (click)="departureDates.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
												</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="col-sm-12 col-md-12">
								<div class="control">
									<div class="form-group">
										<div class="input-group">
											<label>Departure Time</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-clock"></i>
												</span>
											</div>
											<input class="auto_selectOption input is-medium" id="{{pageId}}departureTime"
												placeholder="Select Departure Time" [formControl]="controlDepartureTime"
												[appAutocomplete]="autocompleteDepartureTime">
										</div>
									</div>
								</div>

								<app-autocomplete #autocompleteDepartureTime="appAutocomplete">
									<ng-template appAutocompleteContent>
										<ng-container
											*ngIf="(departureTimeOptions | filter: controlDepartureTime.value) as resultDepartureTime">
											<app-option *ngFor="let option of resultDepartureTime"
												[value]="option.label"> {{ option.label }} </app-option>
											<app-option class="no-result" *ngIf="!resultDepartureTime.length">No
												result</app-option>
										</ng-container>
									</ng-template>
								</app-autocomplete>
							</div>-->
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group ">
                                            <label>Departure Time</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
												</span>
                                            </div>
                                            <input type="time" class="form-control " id="{{pageId}}departureTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyDepartureTime">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Scheduled Date</label> <input class="form-control" id="{{pageId}}scheduledDates" placeholder="yyyy-mm-dd" name="scheduledDates" ngbDatepicker [(ngModel)]="scheduledDatesModal" #scheduledDates="ngbDatepicker"
                                                autocomplete="off" [readonly]="isReadonlyScheduledDate">
                                            <div class="input-group-append" (click)="scheduledDates.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
												</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group ">
                                            <label>Scheduled Time</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
												</span>
                                            </div>
                                            <input type="time" class="form-control " id="{{pageId}}scheduledTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyScheduledTime">
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="col-sm-12 col-md-12">
								<div class="control">
									<div class="form-group">
										<div class="input-group">
											<label>Scheduled Time</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-clock"></i>
												</span>
											</div>
											<input class="auto_selectOption input is-medium" id="{{pageId}}scheduledTime"
												placeholder="Select Scheduled Time" [formControl]="controlScheduledTime"
												[appAutocomplete]="autocompleteScheduledTime">
										</div>
									</div>
								</div>
								<app-autocomplete #autocompleteScheduledTime="appAutocomplete">
									<ng-template appAutocompleteContent>
										<ng-container
											*ngIf="(scheduledTimeOptions | filter: controlScheduledTime.value) as resultScheduledTime">
											<app-option *ngFor="let option of resultScheduledTime"
												[value]="option.label"> {{ option.label }} </app-option>
											<app-option class="no-result" *ngIf="!resultScheduledTime.length">No
												result</app-option>
										</ng-container>
									</ng-template>
								</app-autocomplete>
							</div>-->

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Arrival Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}arrivalDates" name="arrivalDates" ngbDatepicker #arrivalDates="ngbDatepicker" [(ngModel)]="arrivalDatesModal" autocomplete="off"
                                                [readonly]="isReadonlyArrivalDate">
                                            <div class="input-group-append" (click)="arrivalDates.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
												</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group ">
                                            <label>Arrival Time</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
												</span>
                                            </div>
                                            <input type="time" class="form-control " id="{{pageId}}arrivalTime" aria-describedby="basic-addon11 " autocomplete="off" [readonly]="isReadonlyArrivalTime">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card-body">
                            <!-- </div>style="background-color:#f2f9fb;"> -->
                            <h6 class="card-title">Billing Detail</h6>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Time Taken To Reached</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clock"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}timeTakenToReached" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyTimeTakenToReached">
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="!showHireColumns" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Hire Amount</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}hireAmount" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyHireAmount">
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="!showHireColumns" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Advance</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" id="{{pageId}}advance" class="form-control" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyAdvanceAmount">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Deduction</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" id="{{pageId}}deduction" class="form-control" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyDeductionAmount">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Balance</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" id="{{pageId}}balance" class="form-control" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyBalanceAmount">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Payable At</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}payableAt" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyPayableAt">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card-body">
                            <h6 style="border-bottom: 1px solid orange;" class="card-title">Goods Short & Extra Details</h6>
                            <!-- 							<div class="row"> -->
                            <div class="box-body">
                                <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsHireslipBalance" [dtTrigger]="dtTriggerHireslipBalance">
                                    <thead>
                                        <tr>
                                            <th>LR No</th>
                                            <th>Art</th>
                                            <th>Extra</th>
                                            <th>Open</th>
                                            <th>Short</th>
                                            <th>Wet</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let hireslipBalanceData of hireslipBalanceDataList ">
                                            <td>{{ hireslipBalanceData.lrNo }}</td>
                                            <td>{{ hireslipBalanceData.art }}</td>
                                            <td>{{ hireslipBalanceData.extra }}</td>
                                            <td>{{ hireslipBalanceData.open }}</td>
                                            <td>{{ hireslipBalanceData.short }}</td>
                                            <td>{{ hireslipBalanceData.wet }}</td>
                                            <td>{{ hireslipBalanceData.remarks }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 vl">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- 							<button type="submit" class="btn btn-success m-r-10" -->
                                    <!-- 								id="{{pageId}}deductionBtn">Deduction</button> -->
                                    <h6 style="border-bottom: 1px solid orange;" class="card-title">Deduction</h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card-body">
                                        <h6 class="card-title">Deduction Details</h6>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Less Late Arrival</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}lessLateArrival" aria-describedby="basic-addon11" [readonly]="isReadonlyLessLateArrival" autocomplete="off" (keypress)="lessLateArrivalFocus($event)" (change)="lessLateArrivalBlur($event)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Less Unload Hamali</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}lessUnloadHamali" [readonly]="isReadonlylessUnloadHamali" autocomplete="off" aria-describedby="basic-addon11" (keypress)="lessUnloadHamaliFocus($event)" (change)="lessUnloadHamaliBlur($event)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Less Munishiana</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}lessMunishiana" aria-describedby="basic-addon11" [readonly]="isReadonlyLessMunishiana" autocomplete="off" (keypress)="lessMunishianaFocus($event)" (change)="lessMunishianaBlur($event)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <hr> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="card-body">
                                        <h6 class="card-title">Bank Details</h6>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}paymentMode" name="paymentMode" #paymentMode (change)="paymentModeMode(paymentMode.value)">
															<option selected value="select payment">Select Payment Mode
															</option>
															<option value="cash">Cash</option>
															<option value="cheque">Cheque/PDC</option>
															<option value="neft">NEFT/IMPS</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewByChequeNumber" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}chequeNumber" [readonly]="isReadonlyChequeNumber" [(ngModel)]='chequeNumber' aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewByChequeDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Date</label> <input id="{{pageId}}chequeDates" class="form-control" placeholder="yyyy-mm-dd" name="chequeDates" ngbDatepicker [(ngModel)]='chequeDatesModal' #chequeDates="ngbDatepicker"
                                                            autocomplete="off" [readonly]="isReadonlyChequeDates">
                                                        <div class="input-group-append" (click)="chequeDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewByConsigneeBankName" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Consignee Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-university"></i>
																</span>
                                                            </div>
                                                            <input type="text" #bankName class="form-control" id='{{pageId}}bankName' placeholder="Select Bank" (selectItem)="bankNameDropDownListner($event)" [(ngModel)]='modelBankName' [ngbTypeahead]="bankNameSearchTA" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                                                (focus)="focusBankNameTA$.next($any($event).target.value)" [readonly]="isReadonlyBankName" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div *ngIf="viewNewBankNameField" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>New Bank Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control" id="{{pageId}}newBankNameId"
															#newBankName aria-describedby="basic-addon11">
													</div>
												</div>
											</div>-->
                                            <div *ngIf="viewByReferenceNumber" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Reference Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}referenceNumber" aria-describedby="basic-addon11" [(ngModel)]='ngModalRefferenceNumber' [readonly]="isReadonlyRefference" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <hr> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="text-align: center;">
                                    <!--	<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}deductionBtn">Deduction
									</button>-->
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}confirmPaymentBtn" [hidden]="!viewConfirmPaymentBtn" (click)="confirmPaymentClickBtn();">Confirm
										Payment</button>
                                    <button type="submit" class="btn btn-dark" id="{{pageId}}printBtn" [hidden]="!viewPrintBtn" (click)="printClickBtn();">Print</button>
                                    <!--<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}okBtn">OK</button>
									<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn">Clear</button>-->
                                    <!--<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}cancelBtn">Cancel</button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Row -->
                </div>
            </div>
        </div>
    </div>
    <!--POP UP START HERE-->
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #hireslipBalancePopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Fileupload :</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Pan</label>
                                                    <input type="file" id="{{pageId}}panFileUpload" (change)="onFileChangedPan($event)" #fileInputPan />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success m-r-10 mr-auto" id="{{pageId}}uploadPopUpBtn" (click)="popupUploadClickBtn();">Upload</button>
                        <button type="submit" class="btn btn-dark m-r-10 mr-auto" id="{{pageId}}cancelBtn" (click)="popupCancelClickBtn();">Cancel</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <!--POP UP END HERE-->
    <div *ngIf="viewVoucher " onafterprint="afterPrint()" id="{{pageId}}printVoucher">
        <app-lorry-hire-balance-rpt-print></app-lorry-hire-balance-rpt-print>
    </div>
</body>