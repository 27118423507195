import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";

@Component({
    selector: 'app-non-availability-of-truck-report',
    templateUrl: './non-availability-of-truck-report.component.html',
    styleUrls: ['./non-availability-of-truck-report.component.css']
})
export class NonAvailabilityOfTruckReportComponent implements OnInit {

    gettingDataFrmServiceFrDataTableTable: any;
    gettingDataFrmServiceFrPartyWiseTable: any;

    dataTableDataList: any;
    partyWiseDataList: any;

    onDestroyUnsubscribtionDataTable: Subscription;
    onDestroyUnsubscribtionPartyWise: Subscription;


    //for the select option with filter starts

    control = new FormControl();

    agentOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'Agra' },
        { id: 3, label: 'Bawana' },
    ];

    /* sourceOptions = [
      { id: 1, label: 'Delhi' },
      { id: 2, label: 'Mumbai' },
      { id: 3, label: 'Agra' },
  ];
destinationOptions = [
       { id: 1, label: 'Bangalore' },
       { id: 2, label: 'Chennai' },
       { id: 3, label: 'Kerela' },
   ];*/

    smsSendingTypeOptions = [
        { id: 1, label: 'Started' },
        { id: 2, label: 'Stopped' }
    ];

    partyWiseDestinationOptions = [
        { id: 1, label: 'Bangalore' },
        { id: 2, label: 'Chennai' },
        { id: 3, label: 'Kerela' },
    ];

    //for the select option with filter ends


    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    searchByLrWise = true;
    searchByPartyWise = false;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDataTable: Subject<any> = new Subject();
    dtTriggerPartyWise: Subject<any> = new Subject();



    dataTable: any;
    dtOptionsDataTable: any;
    dtOptionPartyWise: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;

    lrDtoAgentNameAllOption: LRDto = new LRDto();
    agentNameOptions: LRDto[];
    lrDtoAgentName: LRDto = new LRDto();
    public modelAgentName: any;
    agentNameTA: Array<LRDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStations: string }) => x.subStations;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    partyWiseDestinationDropDownOptions: LRDto[];
    lrDtoPartyWiseDestinationDropDown: LRDto = new LRDto();
    public modelPartyWiseDestinationDropDown: any;
    partyWiseDestinationDropDownTA: Array<LRDto> = [];
    focusPartyWiseDestinationDropDownTA$ = new Subject<string>();
    searchPartyWiseDestinationDropDown = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusPartyWiseDestinationDropDownTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.partyWiseDestinationDropDownTA
                : this.partyWiseDestinationDropDownTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterPartyWiseDestinationDropDown = (x: { destination: string }) => x.destination;


    lrDtoSourceSpecific: LRDto = new LRDto();
    lrDtoSourceSpecificList: Array<LRDto> = [];

    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    superAdminView: boolean = false;
    showSpinnerForAction: boolean;
    lrDtoUserSearchValue: LRDto = new LRDto();
    selectedAgent : any;
    selectedSource : any;
    selectedDest : any;
    selectedDate : any;
    listLrs: Array<String> = [];
    
  //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    avgActWtSummary:number;
    avgChgWtSummary:number;
    pageId="naotr";

    constructor(private godownStocksBookingRpt: ReportService, private router: Router,
        private memoReport: MemoReport, private masterReadService: MasterReadService,
        private modalService: NgbModal, private lrReport: LrReportService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "TruckNonAvlRpt DestView") {
                        this.superAdminView = true;// superadmin only 
                    }
                }
            }
        }
        this.getAgentNameDetails();
        this.getFromStationMethod();
        this.getDestinationDetails();

    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

        this.dtOptionsDataTable = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'LR',
                    data: 'lr'
                },
                {
                    title: 'Art',
                    data: 'art'
                },
                {
                    title: 'Consignee',
                    data: 'consignee'
                },
                {
                    title: 'Mobile',
                    data: 'mobile'
                },
                {
                    title: 'Contact Person',
                    data: 'contactPerson'
                }
            ],
            //columns is used to for export and others endss
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }

        this.dtOptionPartyWise = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Source List',
                    data: 'sourceList'
                }],
            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }
                //

            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 180,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerDataTable.next();
        this.dtTriggerPartyWise.next();
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerDataTable.unsubscribe();
        this.dtTriggerPartyWise.unsubscribe();
        //this.onDestroyUnsubscribtionDataTable.unsubscribe();
        //this.onDestroyUnsubscribtionPartyWise.unsubscribe();
    }

    //for second datatable with checkbox hide and show
    searchByMode(searchBy: string) {
        if (searchBy === 'lrWise') {
            this.searchByLrWise = true;
            this.searchByPartyWise = false;

        } else if (searchBy === 'partyWise') {
            this.searchByLrWise = true;
            this.searchByPartyWise = true;

        } else {
            this.searchByLrWise = false;
            this.searchByPartyWise = false;
        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends

    agentNameListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelAgentName = e.item;
        $("#"+this.pageId+"agentNameId").val(this.modelAgentName.subStations);
    }
    clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#"+this.pageId+"sourceId").val(this.modelSource.destination);
    }
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }

    getAgentNameData() {
        this.lrDtoAgentName = new LRDto();
        this.lrDtoAgentName.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoAgentName.reportMode = "InclMainStation";
        console.log(this.superAdminView);
        if (this.superAdminView == true) {
            this.lrDtoAgentName.mode = "Booking";
        } else {
            //if(this.rightsForRole !=null && this.rightsForRole == "rights for Booking Administrator"){
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoAgentName.city = this.userDataDtoReturnSession.mainStation;
            } else {
                this.lrDtoAgentName.city = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
    }
    getAgentNameDetails() {
        this.getAgentNameData();
        //this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoAgentName).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.agentNameTA = [];
                    this.agentNameOptions = [];
                    this.agentNameOptions = response;
                    this.lrDtoAgentNameAllOption.subStations = "All";
                    this.agentNameTA.push(this.lrDtoAgentNameAllOption);
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                    $("#"+this.pageId+"agentNameId").val('All');
                    //this.showSpinnerForAction = false;
                } else {
                    this.lrDtoAgentNameAllOption.subStations = "All";
                    this.agentNameTA.push(this.lrDtoAgentNameAllOption);
                    $("#"+this.pageId+"agentNameId").val('All');
                    // this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');

    };
    getFromStationMethod() {

        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Booking Administrator" &&
            this.userDataDtoReturnSession.stationList != "") {
            for (let i = 0; i < this.userDataDtoReturnSession.stationList.length; i++) {
                this.lrDtoSourceSpecific = new LRDto();
                this.lrDtoSourceSpecific.subStations = this.userDataDtoReturnSession.stationList[i];
                this.lrDtoSourceSpecificList.push(this.lrDtoSourceSpecific);
            }
            for (let i = 0; i < this.lrDtoSourceSpecificList.length; i++) {
                this.sourceTA.push(this.lrDtoSourceSpecificList[i]);
            }
        } else {
            this.lrDtoSource = new LRDto();
            this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoSource.reportMode = "InclMainStation";
            console.log(this.superAdminView);
            if (this.superAdminView == true) {
                this.lrDtoSource.mode = "Booking";
            } else {
                //if(this.rightsForRole !=null && this.rightsForRole == "rights for Booking Administrator"){
                if (this.userDataDtoReturnSession.role != null &&
                    this.userDataDtoReturnSession.role == "Booking Administrator") {
                    this.lrDtoSource.city = this.userDataDtoReturnSession.mainStation;
                } else {
                    this.lrDtoSource.city = this.userDataDtoReturnSession.mainAdminStation;
                }
            }
            this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
                (response) => {
                    if (response.length > 0) {
                        this.sourceTA = [];
                        this.sourceOptions = [];
                        this.sourceOptions = response;
                        for (let i = 0; i < this.sourceOptions.length; i++) {
                            this.sourceTA.push(this.sourceOptions[i]);
                        }
                        //this.showSpinnerForAction = false;
                    } else {
                        this.sourceTA = [];
                        this.sourceOptions = [];
                        //this.showSpinnerForAction = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    //this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred While getting the Source Details", "info");
                },
                () => console.log('done');
        }
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        //this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    $("#"+this.pageId+"destinationId").val('All');

                    this.partyWiseDestinationDropDownOptions = [];
                    this.partyWiseDestinationDropDownTA = [];
                    this.partyWiseDestinationDropDownOptions = response;
                    for (let i = 0; i < this.partyWiseDestinationDropDownOptions.length; i++) {
                        this.partyWiseDestinationDropDownTA.push(this.partyWiseDestinationDropDownOptions[i]);
                    }
                    //this.showSpinnerForAction = false;
                } else {
                    //this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };
    
    getReportDetails = () => {
		this.showSpinnerForAction = true;
		this.selectedDate = $("#"+this.pageId+"tillDate").val();
		this.selectedAgent = $("#"+this.pageId+"agentNameId").val();
		this.selectedSource = $("#"+this.pageId+"sourceId").val();
		this.selectedDest = $("#"+this.pageId+"destinationId").val();
		
		this.lrDtoUserSearchValue = new LRDto();
		this.lrDtoUserSearchValue.date = this.selectedDate;
		this.lrDtoUserSearchValue.agentName = this.selectedAgent;
		this.lrDtoUserSearchValue.source = this.selectedSource;
		this.lrDtoUserSearchValue.destination = this.selectedDest;
		
		this.lrReport.getTruckNonAvlRptDtls(this.lrDtoUserSearchValue).subscribe(
			(response) => {
				$("#"+this.pageId+"nonTruckAvbTableId").DataTable().destroy();
				this.dataTableDataList = [];
				if (response.length == 0) {
					swal({
						title: "Non Availability Of Truck",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.dataTableDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerDataTable.next();
	            }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the details", "info");
			}, () => console.log('done');
	};
	
	confirmSendSms(){
		swal({
            title: "Confirmation",
            text: "Sure you want to send sms to the Consignee about Non Availability Of Truck?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.prepareSMS();
            }
        })
	}
	
	prepareSMS(){
		this.showSpinnerForAction = true;
		this.listLrs = [];
		for (let i = 0; i < this.dataTableDataList.length; i++) {
			this.listLrs.push(this.dataTableDataList[i].lrNumber);
		}
		this.lrDtoUserSearchValue = new LRDto();
		this.lrDtoUserSearchValue.list = this.listLrs;
		this.sendSMS();
	}
	sendSMS() {
		this.lrReport.getTruckNonAvlRptDtlsForSMS(this.lrDtoUserSearchValue).subscribe(
				(response) => {
					if(response.status == "send"){
						swal("SMS Sent", "SMS sent successfully!", "info");
					}else if(response.status == "notsend"){
						swal("Failed", "SMS send failed!", "error");
					}else if(response.status == "Failed"){
						swal("Failed", "SMS send failed!", "error");
					}else if(response.status == "Nodata"){
						swal("Failed", "No proper mobile number/data found to send the SMS!", "warning");
					}
					this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
				}), (error) => {
					this.showSpinnerForAction = false;
					swal("Error", "Server problem occurred while sending the sms", "error");
				}, () => console.log('done');
	}
	
	//Custom Print
    customPrintNonAvailOfTruck(){
       if (this.dataTableDataList.length == 0) {
           swal({

               title: "No records found to print",
               icon: "warning",
               closeOnClickOutside: false,
               closeOnEsc: false,
           });
       } else {
           localStorage.clear();
           this.cashMemoDtoForCustomPrintList = [];
           this.cashMemoDtoForCustomPrintListColumnNames = ["LR", "Art","Consignee", "Mobile", "Contact Person"];
           this.cashMemoDtoForCustomPrintListColumnWidths = [20,15,25,15,25];
           for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
               this.cashMemoDtoForCustomPrint = new CashMemoDto();
               this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
               this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
               this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
           }
           this.cashMemoDtoForCustomPrintDataList = [];
           this.cashMemoDtoForCustomPrintDataSummaryList = [];


        
           this.avgActWtSummary = this.avgChgWtSummary = 0;

           for (let i = 0; i < this.dataTableDataList.length; i++) {
               this.cashMemoDtoForCustomPrintListColumnValues = [this.dataTableDataList[i].lr, this.dataTableDataList[i].art,
               this.dataTableDataList[i].consignee, this.dataTableDataList[i].mobile,this.dataTableDataList[i].contactPerson];
               /////
               this.cashMemoDtoForCustomPrintData = new CashMemoDto();
               this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
               this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

           }
           this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.dataTableDataList.length,"", "","",""];

           localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
           localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
           localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
          // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
         
           localStorage.setItem('printTitle', "Non Availability Of Truck Report");
           this.viewCustomPrintV1 = true;

           window.addEventListener('afterprint', (onclick) => {
               if (this.viewCustomPrintV1) {
                   this.viewCustomPrintV1 = false;
                   localStorage.clear();
               }
           });

       }
   }
}
