import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LRDto } from 'src/app/dto/LR-dto';
import { serverUrl } from "src/environments/environment";
import { GeneralDto } from '../dto/General-dto';
import { DriverdetailsDto } from '../dto/Driverdetails-dto';



@Injectable({
    providedIn: 'root'
})
export class ReportService {
    //for Local
    //        private _baseUrl = "http://localhost:8080/srd/report/v1";
    //for QA
    //    private _baseUrl = "http://54.210.51.40:8080/srd/report/v1";
    //for Live
    //        private _baseUrl = "http://3.222.201.242:8080/srd/report/v1";

    private _baseUrl = serverUrl.webReport;

    constructor(
        private http: HttpClient,
    ) {
    }

    getPaidRpt(lrDto: Object): Observable<Object> {
        return this.http.post(`${this._baseUrl}/getPaidReportDetails`, lrDto);
    }

    getLorryHireStmtService(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLorryHireStmtDetails`, hireSlipDto);
    }

    getSummaryData(): Observable<any[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');

    }

    getDailyBookingReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getdailybookingreportdetails`, lrDto);
    }

    getLRIssueReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlrissuereportdetails`, lrDto);
    }
    getLoaderPerformanceService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLoaderPerformanceDetails`, lrDto);
    }


    getStaffDetailsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStaffDetails`, lrDto);
    }

    getBookingStationsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingStationsDetails`, lrDto);
    }

    getLocalTripDetailsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLocalTripDetails`, lrDto);
    }
    getTruckReportDetailsService(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckReportDetails`, hireSlipDto);
    }

    getHireslipNumberRpt(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/gethireslipnumberreport`, hireSlipDto);
    }

    getHireslipSummaryWiseRpt(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/gethireslipsummarywiserpt`, hireSlipDto);
    }

    getCashmemoDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getcashmemodetails`, lrDto);
    }

    getDummyInvoiceDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getdummyinvoicedetails`, lrDto);
    }

    getLocalTripsDetailsForLrEnquiry(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlocaltripsdetailsforlrenquiry`, lrDto);
    }

    getLRsShortage(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlrsshortage`, lrDto);
    }

    getLrEnquiryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlrenquirydetails`, lrDto);
    }

    getLrEnquiryStatus(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlrenquirystatus`, lrDto);
    }


    ///
    getLocalChallanDetailReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLocalChallanDetailReportApi`, lrDto);
    }


    getBookingHireSlipRptDestinationWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingHireSlipRptDestinationWiseApi`, lrDto);
    }


    updateUnLoaderChoduaryNameService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateUnloaderChoduaryNameApi`, lrDto);
    }
    updateLoaderChoduaryNameService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLoaderChoduaryNameApi`, lrDto);
    }

    getBkgLocalTripLrDetails(lrDto: Object): Observable<LRDto[]> {
        console.log(lrDto);
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBkgLocalTripLrDetailsApi`, lrDto);

    }

    getHireslipDateWiseRpt(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getHireslipDateWiseRptApi`, hireSlipDto);
    }

    getPaidRptForCashmemo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPaidRptForCashmemoApi`, lrDto);
    }

    getLRIssueReportForLr(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRIssueReportForLrApi`, lrDto);
    }

    getChallanIssueReportForLr(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getChallanIssueReportForLrApi`, lrDto);
    }

    getAgentRankingReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentRankingReportApi`, lrDto);
    }

    getConsignorNamesReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getConsignorNamesReportApi`, lrDto);
    }

    getConsigneeNamesReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getConsigneeNamesReportApi`, lrDto);
    }

    getAgentDestinationWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentDestinationWiseApi`, lrDto);
    }
    getBookingAgentCommodityReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingAgentCommodityReportApi`, lrDto);
    }
    getMainBkgAgentReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMainBkgAgentReportApi`, lrDto);
    }

    getRepeatedCommodity(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getRepeatedCommodityApi`, lrDto);
    }

    commdityMerge(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/commdityMergeApi`, lrDto);
    }


    getOfficeHireslipRptDestinationwise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getOfficeHireslipRptDestinationwiseApi`, hireSlipDto);
    }
    getOfficeHireslipRptMultiDestinationwise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getOfficeHireslipRptMultiDestinationwiseApi`, hireSlipDto);
    }

    getBookingHireSlipRptAllDestination(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingHireSlipRptAllDestinationApi`, hireSlipDto);
    }

    getBookingHireSlipRptMonthlyWise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingHireSlipRptMonthlyWiseApi`, hireSlipDto);
    }

    getBookingHireslipRptSummaryWiseLR(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingHireslipRptSummaryWiseLRApi`, hireSlipDto);
    }

    getBookingHireslipRptSummaryWiseHire(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingHireslipRptSummaryWiseHireApi`, hireSlipDto);
    }

    getlrDwsWgtReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getlrDwsWgtReportApi`, lrDto);
    }
    updateUnloaderChoduaryNameChallanService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateUnloaderChoduaryNameChallanApi`, lrDto);
    }
    createExcelFileService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/createExcelFileApi`, lrDto);
    }

    updateHireslipHireDetails(hireSlipDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/updateHireslipHireDetailsApi`, hireSlipDto);
    }

    updateLocalTripDriver(driverdetailsDto: Object): Observable<DriverdetailsDto> {
        return this.http.post<DriverdetailsDto>(`${this._baseUrl}/updateLocalTripDriverApi`, driverdetailsDto);
    }

    updateLocalTripVehicle(driverdetailsDto: Object): Observable<DriverdetailsDto> {
        return this.http.post<DriverdetailsDto>(`${this._baseUrl}/updateLocalTripVehicleApi`, driverdetailsDto);
    }

    deleteLocalTripService(driverdetailsDto: Object): Observable<DriverdetailsDto> {
        return this.http.post<DriverdetailsDto>(`${this._baseUrl}/deleteLocalTripServiceApi`, driverdetailsDto);
    }

    getPaidRptSummary(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPaidRptSummaryApi`, lrDto);
    }

    updateLocalTripDeliveryMan(driverdetailsDto: Object): Observable<DriverdetailsDto> {
        return this.http.post<DriverdetailsDto>(`${this._baseUrl}/updateLocalTripDeliveryManApi`, driverdetailsDto);
    }

    getMultiEwayBillNoDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMultiEwayBillNoDetailsApi`, lrDto);
    } 
}

