import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import swal from 'sweetalert';
import { Router, ActivatedRoute } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LabourStatementLoadingUnloadingComponent } from 'src/app/report/hamali-report/labour-statement-loading-unloading/labour-statement-loading-unloading.component';
import { MatDialog } from '@angular/material/dialog';
import { LabourStatementLRDetailsComponent } from 'src/app/report/hamali-report/labour-statement-lr-details/labour-statement-lr-details.component';
@Component({
    selector: 'app-labour-hamali-statement-details',
    templateUrl: './labour-hamali-statement-details.component.html',
    styleUrls: ['./labour-hamali-statement-details.component.css']
})
export class LabourHamaliStatementDetailsComponent implements OnInit {

    gettingDataFrmServiceFrLrDetailsTable: any;
    gettingDataFrmServiceFrAdditionDatatableTable: any;
    gettingDataFrmServiceFrDeductionDatatableTable: any;

    lrDetailsDataList: any;
    additionDatatableDataList: any;
    deductionDatatableDataList: any;

    onDestroyUnsubscribtionLrDetails: Subscription;
    onDestroyUnsubscribtionAdditionDatatable: Subscription;
    onDestroyUnsubscribtionDeductionDatatable: Subscription;

    //summaryTable:any;

    loadingIndicator = true;

    //@ViewChildren(DataTableDirective)
    //dtElements: QueryList<DataTableDirective>;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    //@ViewChild(DialogComponent) dialogComponent: DialogComponent;


    dtTriggerLrDetails: Subject<any> = new Subject();
    dtTriggerAdditionDatatable: Subject<any> = new Subject();
    dtTriggerDeductionDatatable: Subject<any> = new Subject();


    // @ViewChild('dataTable') table: ElementRef;
    dataTable: any;

    dtOptionsLrDetails: any;
    dtOptionAdditionDatatable: any;
    dtOptionDeductionDatatable: any;
    //firstDtOptions: DataTables.Settings = {};
    

    fortNightStmtDtoUserValues: FortNightStmtDto = new FortNightStmtDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    hireSlipDtoSelectedData: HireSlipDto = new HireSlipDto();
    hireSlipDtoUpdate: HireSlipDto = new HireSlipDto();
    deductionDatatableDataListUpdated: any;
    additionDatatableDataListUpdated: any;
    hireSlipDtoAddDed: HireSlipDto = new HireSlipDto();
    hireSlipDtoReturn: HireSlipDto = new HireSlipDto();
    toBePaidAmt: any;
    descReadOnly = false;
    enteredDesc: any;
    enteredValue: any;
    descIsSelectedAdd = false;
    selectedIndexAdd: any;
    descIsSelectedDed = false;
    selectedIndexDed: any;
    totAddition = null;
    totDed = null;
    viewLabourHamaliLoadUnLoadDetlsPrint = false;
    pageId="lhsdc";
    constructor(private router: Router, private destStmtReport: DestStmtReport,
        public dialog: MatDialog, private route: ActivatedRoute, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            this.hireSlipDtoSelectedData = new HireSlipDto();
            this.hireSlipDtoSelectedData = JSON.parse(localStorage.getItem('srdLabourHamaliStatementData'));
            this.additionDatatableDataList = [];
            this.additionDatatableDataList = this.hireSlipDtoSelectedData.hireSlipDtoList;
            this.deductionDatatableDataList = [];
            this.deductionDatatableDataList = this.hireSlipDtoSelectedData.listTimeSettings;
            this.toBePaidAmt = this.hireSlipDtoSelectedData.toBePaidAmt;
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
                console.log(this.hireSlipDtoSelectedData);
                this.getLabourHamaliPendingStmtDetails();
            }, 500);
        }

    }

    ngOnInit(): void {
        //first datatable starts 
        this.dtOptionsLrDetails = {

            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                }, 
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        },
            //first datatable ends
            //second datatable starts 
            this.dtOptionAdditionDatatable = {
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(total);
                }
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            //second datatable ends
            //third datatable starts 
            this.dtOptionDeductionDatatable = {
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(total);
                }
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            }

    }




    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
        this.dtTriggerAdditionDatatable.unsubscribe();
        this.dtTriggerDeductionDatatable.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();
        this.dtTriggerAdditionDatatable.next();
        this.dtTriggerDeductionDatatable.next();
    }

    getUserValues() {
        this.fortNightStmtDtoUserValues = new FortNightStmtDto();
        this.fortNightStmtDtoUserValues.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDtoUserValues.mode = "STMTDB";
        this.fortNightStmtDtoUserValues.stmtNo = this.hireSlipDtoSelectedData.stmtNo;
        this.fortNightStmtDtoUserValues.mainBranch = this.userDataDtoReturnSession.mainStation;
    }
    getLabourHamaliPendingStmtDetails = () => {
        this.showSpinnerForAction = true;
        this.getUserValues();
        this.destStmtReport.getLabourHamaliStatementReportDetails(this.fortNightStmtDtoUserValues).subscribe(
            (response) => {
                console.log(response);
                $("#"+this.pageId+"labourStmtDetailsId").DataTable().destroy();
                this.lrDetailsDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrDetailsDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrDetails.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Labour Statement Details", "info");
            }, () => console.log('done');
    };

    saveLabourStmtDetails(mode) {
        swal({
            title: "Confirm Changes",
            text: "Sure U Want to Update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.saveUpdateStmtDetails(mode);
            }
        });
    }

    updateStmtUserValues(mode) {
        this.hireSlipDtoUpdate = new HireSlipDto();
        this.hireSlipDtoUpdate.stmtNo = this.hireSlipDtoSelectedData.stmtNo;
        this.hireSlipDtoUpdate.mode = mode;
        this.hireSlipDtoUpdate.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoUpdate.status = "AmtPaid";
        this.hireSlipDtoUpdate.hireSlipDtoList = this.getAdditionValues();
        this.hireSlipDtoUpdate.listTimeSettings = this.getDeductionValues();
    }

    getAdditionValues() {
        this.additionDatatableDataListUpdated = [];
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.hireSlipDtoAddDed = new HireSlipDto();
            this.hireSlipDtoAddDed.remarks = this.additionDatatableDataList[i].remarks;
            this.hireSlipDtoAddDed.totalAmt = this.additionDatatableDataList[i].totalAmt;
            this.additionDatatableDataListUpdated.push(this.hireSlipDtoAddDed);
        }
        return this.additionDatatableDataListUpdated;
    }

    getDeductionValues() {
        this.deductionDatatableDataListUpdated = [];
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.hireSlipDtoAddDed = new HireSlipDto();
            this.hireSlipDtoAddDed.remarks = this.deductionDatatableDataList[i].remarks;
            this.hireSlipDtoAddDed.totalAmt = this.deductionDatatableDataList[i].totalAmt;
            this.deductionDatatableDataListUpdated.push(this.hireSlipDtoAddDed);
        }
        return this.deductionDatatableDataListUpdated;
    }


    saveUpdateStmtDetails = (mode) => {
        this.updateStmtUserValues(mode);
        this.showSpinnerForAction = true;
        this.destStmtReport.updateLBTHamaliStmt(this.hireSlipDtoUpdate).subscribe((data) => {
            this.hireSlipDtoReturn = data;
            if (this.hireSlipDtoReturn.status == "Success") {
                swal({
                    title: "Success",
                    text: "Labour statement details Saved/Updated Successfully",
                    icon: "success",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                this.showSpinnerForAction = false;
                this.router.navigate(['/dashboard/classic']);
            } else {
                this.showSpinnerForAction = false;
                swal({
                    title: "Error",
                    text: "Error While Saving the labour statement details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }
            this.changeDetectorRef.detectChanges();
        }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving the Labour Statement Details", "info");
            }, () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rowSelectedEditAddition(additionDatatableData, index) {
        $("#"+this.pageId+"description").val(additionDatatableData.remarks);
        $("#"+this.pageId+"value").val(additionDatatableData.totalAmt);
        $("#"+this.pageId+"commonDescription").val("Addition");
        this.descReadOnly = true;
        this.descIsSelectedAdd = true;
        this.selectedIndexAdd = index;
    }
    rowSelectedEditDeduction(deductionDatatableData, index) {
        $("#"+this.pageId+"description").val(deductionDatatableData.remarks);
        $("#"+this.pageId+"value").val(deductionDatatableData.totalAmt);
        $("#"+this.pageId+"commonDescription").val("Deduction");
        this.descReadOnly = true;
        this.descIsSelectedDed = true;
        this.selectedIndexDed = index;
    }

    addInTable() {
        if (($("#"+this.pageId+"description").val() == null) || ($("#"+this.pageId+"description").val() == undefined) || ($("#"+this.pageId+"description").val() == "") ||
            ($("#"+this.pageId+"value").val() == null) || ($("#"+this.pageId+"value").val() == undefined) || ($("#"+this.pageId+"value").val() == "")) {
            swal("Mandatory Field", "Please enter the details to proceed!", "warning");
            return false;
        } else {

            if ($("#"+this.pageId+"commonDescription").val() == "Addition") {
                this.addInTableAddition();
            } else {
                this.addInTableDeduction();
            }
        }
    }

    addInTableAddition() {
        this.hireSlipDtoAddDed = new HireSlipDto();
        this.enteredDesc = $("#"+this.pageId+"description").val();
        this.enteredValue = $("#"+this.pageId+"value").val();
        if (this.descIsSelectedAdd == false) {
            for (let i = 0; i < this.additionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.additionDatatableDataList[i].remarks.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.hireSlipDtoAddDed.remarks = this.enteredDesc;
            this.hireSlipDtoAddDed.totalAmt = this.enteredValue;
            this.additionDatatableDataList.push(this.hireSlipDtoAddDed);

            $("#"+this.pageId+"additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.additionDatatableDataList.splice(this.selectedIndexAdd, 1);
            this.hireSlipDtoAddDed.remarks = this.enteredDesc;
            this.hireSlipDtoAddDed.totalAmt = this.enteredValue;
            this.additionDatatableDataList.push(this.hireSlipDtoAddDed);

            $("#"+this.pageId+"additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    addInTableDeduction() {
        this.hireSlipDtoAddDed = new HireSlipDto();
        this.enteredDesc = $("#"+this.pageId+"description").val();
        this.enteredValue = $("#"+this.pageId+"value").val();
        if (this.descIsSelectedDed == false) {
            for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.deductionDatatableDataList[i].remarks.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.hireSlipDtoAddDed.remarks = this.enteredDesc;
            this.hireSlipDtoAddDed.totalAmt = this.enteredValue;
            this.deductionDatatableDataList.push(this.hireSlipDtoAddDed);

            $("#"+this.pageId+"deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.deductionDatatableDataList.splice(this.selectedIndexDed, 1);
            this.hireSlipDtoAddDed.remarks = this.enteredDesc;
            this.hireSlipDtoAddDed.totalAmt = this.enteredValue;
            this.deductionDatatableDataList.push(this.hireSlipDtoAddDed);

            $("#"+this.pageId+"deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    rowSelectedDeleteAddition(additionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.additionDatatableDataList.splice(index, 1);
                $("#"+this.pageId+"additionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerAdditionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    rowSelectedDeleteDed(deductionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.deductionDatatableDataList.splice(index, 1);
                $("#"+this.pageId+"deductionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerDeductionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    totalAmtToBePaid() {
        this.totAddition = 0;
        this.totDed = 0;
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.totAddition += +this.additionDatatableDataList[i].totalAmt;
        }
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.totDed += +this.deductionDatatableDataList[i].totalAmt;
        }
        this.toBePaidAmt = +(this.totAddition) - (this.totDed);
    }

    clearAddDedFields() {
        this.enteredDesc = null;
        this.enteredValue = null;
        $("#"+this.pageId+"description").val("");
        $("#"+this.pageId+"value").val("");
        $("#"+this.pageId+"commonDescription").val("Addition");
        this.descReadOnly = false;
        this.descIsSelectedAdd = false;
        this.selectedIndexAdd = null;
        this.descIsSelectedDed = false;
        this.selectedIndexDed = null;
        this.totAddition = 0;
        this.totDed = 0;
    }

    /* HERE STRAT */

    getLabourHamaliStmtNoDetails(lrDetailsData) {
        console.log(lrDetailsData);
        var additionValue = null, deductionValue = null;

        additionValue = lrDetailsData.multipleAdd;
        deductionValue = lrDetailsData.multipleDed;

        var additionValueSplit: Array<String> = [];
        var deductionValueSpilt: Array<String> = [];
        var lrdetailsDataPopUp: any;
        lrdetailsDataPopUp = lrDetailsData;
        lrdetailsDataPopUp.fromDate = lrDetailsData.fromPeriodStr;
        lrdetailsDataPopUp.toDate = lrDetailsData.toPeriodStr;

        if (additionValue != null && additionValue != undefined && additionValue != "") {
            additionValueSplit = additionValue.split("#");
        }

        if (deductionValue != null && deductionValue != undefined && deductionValue != "") {
            deductionValueSpilt = deductionValue.split("#");
        }

        localStorage.clear();
        localStorage.setItem('labourStmtLoadingUnloadingDetails',
            JSON.stringify(lrdetailsDataPopUp));
        localStorage.setItem('popupLabourStmtRptLrDetlsAddition', JSON.stringify(additionValueSplit));
        localStorage.setItem('popupLabourStmtRptLrDetlsDeduction', JSON.stringify(deductionValueSpilt));
        const dialogRef = this.dialog.open(LabourStatementLoadingUnloadingComponent, {
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.clear();
            console.log('The dialog was closed');
            console.log(JSON.parse(result));
            // this.router.navigate(['.'], { relativeTo: this.route });

            localStorage.clear();
            localStorage.setItem('labourStmtLoadingUnloadingPrintShow',
                JSON.stringify(JSON.parse(result)));
            this.viewLabourHamaliLoadUnLoadDetlsPrint = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewLabourHamaliLoadUnLoadDetlsPrint) {
                    this.viewLabourHamaliLoadUnLoadDetlsPrint = false;
                    localStorage.clear();
                }
            });
            this.changeDetectorRef.detectChanges();
        });
        
    }

    getLabourHamaliTotalLrsDetails(lrDetailsData) {
        var lrdetailsDataForUnloadingPopUp: any;
        lrdetailsDataForUnloadingPopUp = lrDetailsData;
        lrdetailsDataForUnloadingPopUp.fromDate = lrDetailsData.fromPeriodStr;
        lrdetailsDataForUnloadingPopUp.toDate = lrDetailsData.toPeriodStr;

        localStorage.clear();
        localStorage.setItem('labourStmtTotalLrsDetails',
            JSON.stringify(lrDetailsData));
        const dialogRef = this.dialog.open(LabourStatementLRDetailsComponent, {
        });
        // dialogRef.afterClosed().subscribe(result => {
        //     console.log('The dialog was closed');
        //     this.router.navigate(['.'], { relativeTo: this.route });
        // });
    }

}

