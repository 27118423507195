<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Agent Commission Master</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-6 vl">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="card-title">Commission Setup I</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Agent Name</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="fas fa-user"></i>
																	</span>
                                                                </div>
                                                                <input id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="clickListnerForAgentName($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                                    (focus)="focusAgentNameTA$.next($any($event).target.value)" placeholder="Select Agent Name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>TDS On Commission(%)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="{{pageId}}tdsOnCommission" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Monthly Amount</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="{{pageId}}monthlyAmount" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>unit</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-weight"></i>
																</span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}unitOfReward">
																<option selected value="Select Unit">Select Unit
																</option>
																<option value="Fixed">Fixed</option>
																<option value="Articles">Articles</option>
																<option value="Actual Weight">Actual Weight</option>
																<option value="Charged Weight">Charged Weight</option>
																<option value="LR">LR</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Tempo Freight(%)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="{{pageId}}tempoFreight" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>unit</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-weight"></i>
																</span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}unitOfTempFreight">
																<option selected value="Select Unit">Select Unit
																</option>
																<option value="Articles">Articles</option>
																<option value="Actual Weight">Actual Weight</option>
																<option value="Charged Weight">Charged Weight</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>TDS On Loading Hamali(%)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="{{pageId}}tdsOnLoadingHamali" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Commission Setup II</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Incentive Unit :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}incentiveUnit">
														<option selected value="Select Unit">Select Unit</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Charged Weight">Charged Weight</option>
														<option value="LR">LR</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h6 class="card-title">Incentive (Days)</h6>
                                            <!--<div class="row">
												<div class="col-sm-12 col-md-4">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}from">
															<label class="wtfull">From</label>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-4">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}to">
															<label class="wtfull">To</label>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-4">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}amount">
															<label class="wtfull">Com(%)</label>
														</div>
													</div>
												</div>
											</div>-->
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" id="{{pageId}}incentiveFromId" aria-describedby="basic-addon11" placeholder="From" (keypress)="onKeyPressListnerIncentiveFrom($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" aria-describedby="basic-addon11" placeholder="To" id="{{pageId}}incentiveToId" (keypress)="onKeyPressListnerIncentiveTo($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" aria-describedby="basic-addon11" placeholder="Com(%)" id="{{pageId}}incentiveCommId" (keypress)="onKeyPressListnerIncentiveComm($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <i style=" cursor: pointer;" placement="top" ngbTooltip="Clear" class="fas fa-times" (click)="clearBtnIncentive();"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="box-body">
                                                                        <table datatable id="{{pageId}}incentiveDaysTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsIncentiveDays" [dtTrigger]="dtTriggerIncentiveDays">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>From</th>
                                                                                    <th>To</th>
                                                                                    <th>Com(%)</th>
                                                                                    <th><i class="fas fa-trash"></i>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr style=" cursor: pointer;" *ngFor="let incentiveDaysData of incentiveDaysDataList let i = index" (click)="rowSelectedIncentiveDataOnEdit(incentiveDaysData,i);">
                                                                                    <td>{{ incentiveDaysData.from }}
                                                                                    </td>
                                                                                    <td>{{ incentiveDaysData.to }}
                                                                                    </td>
                                                                                    <td>{{ incentiveDaysData.comm }}
                                                                                    </td>
                                                                                    <td><i style=" cursor: pointer;" placement="left" ngbTooltip="Remove" class="fas fa-trash" (click)="deleteSelectedIncentive(i);"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Penalty Unit :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}penaltyUnit">
														<option selected value="Select Unit">Select Unit</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Charged Weight">Charged Weight</option>
														<option value="LR">LR</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h6 class="card-title">Penalty (Days)</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}penalityFromId" placeholder="From" (keypress)="onKeyPressListnerFrom($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" id="{{pageId}}penalityToId" aria-describedby="basic-addon11" placeholder="To" (keypress)="onKeyPressListnerTo($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}penalityCommId" placeholder="Com(%)" (keypress)="onKeyPressListnerCommission($event);">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <i style=" cursor: pointer;" placement="top" ngbTooltip="Clear" class="fas fa-times" (click)="clearBtnPenalty();"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="box-body">
                                                                        <table datatable id="{{pageId}}penaltyDaysTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPenaltyDays" [dtTrigger]="dtTriggerPenaltyDays">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>From</th>
                                                                                    <th>To</th>
                                                                                    <th>Com(%)</th>
                                                                                    <th><i class="fas fa-trash"></i>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr style=" cursor: pointer;" *ngFor="let penaltyDaysData of penaltyDaysDataList let i = index" (click)="rowSelectedPenalityDataOnEdit(penaltyDaysData,i);">
                                                                                    <td>{{ penaltyDaysData.from }}
                                                                                    </td>
                                                                                    <td>{{ penaltyDaysData.to }}
                                                                                    </td>
                                                                                    <td>{{ penaltyDaysData.comm }}
                                                                                    </td>
                                                                                    <td><i style=" cursor: pointer;" placement="left" ngbTooltip="Remove" class="fas fa-trash" (click)="deleteSelectedPenality(i);"></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Apply Tempo :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}tempoPenalty">
														<option value="Yes">Yes</option>
														<option value="No">No</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr style="width: 95%;">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class='row'>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <select class="custom-select col-12" id="{{pageId}}commissionUnitId">
														<option value="Commission">Commission</option>
														<option value="Loading Hamali">Loading Hamali</option>
														<option value="UnLoading Hamali">UnLoading Hamali</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #CommodityList id="{{pageId}}commodityName" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList" [inputFormatter]="formatterCommodityList"
                                                            (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <select class="custom-select col-12" id="{{pageId}}commissionFieldWgt">
														<option value="Select">Select</option>
														<option value="Articles">Articles</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Charged Weight">Charged Weight</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div class="input-group">

                                                    <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}commissionValueId" (keypress)="onKeyPressListnerForCommissionValue($event);">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <i style="cursor: pointer;" placement="right" ngbTooltip="Add" id="{{pageId}}addCommissionBtn" (click)="addCommodityBtn();" class="fas fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <i style="cursor: pointer;" placement="right" ngbTooltip="Remove" id="{{pageId}}removeCommodityBtn;" (click)="removeBtnClickListner();" class="fas fa-trash"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}commodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodity" [dtTrigger]="dtTriggerCommodity">
                                                    <thead>
                                                        <tr>
                                                            <th>Commission/Hamali</th>
                                                            <th>Commodity</th>
                                                            <th>Unit</th>
                                                            <th>Value</th>
                                                            <th><i class="fas fa-trash"></i>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style=" cursor: pointer;" *ngFor="let commodityData of commodityDataList let i = index" (click)="rowSelectedCommodityDataOnEdit(commodityData,i);">
                                                            <td>{{ commodityData.commissionUnit }}</td>
                                                            <td>{{ commodityData.commodityName }}</td>
                                                            <td>{{ commodityData.commissionFieldWgt }}</td>
                                                            <td>{{ commodityData.commissionValue }}</td>
                                                            <td><i style=" cursor: pointer;" placement="left" ngbTooltip="Remove" class="fas fa-trash" (click)="deleteSelectedCommodity(i);"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr style="width: 95%;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}applyBtn" (click)="validateApplyBtnListner();">Apply</button>
                            <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}resetBtn" (click)="clearOverAllResetBtn();">Reset</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 vl">
                    <div class="row">
                        <!-- column -->
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}agentCommissionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentCommission" [dtTrigger]="dtTriggerAgentCommission">

                                                    <thead>
                                                        <tr>
                                                            <th>Agent Name</th>
                                                            <th>TDS Comm</th>
                                                            <th>Monthly Amount</th>
                                                            <th>Unit</th>
                                                            <th>Tempo Freight</th>
                                                            <th>Unit</th>

                                                            <th style="display: none;">Com</th>
                                                            <th style="display: none;">Unit</th>
                                                            <th style="display: none;">Loading Hamali</th>
                                                            <th style="display: none;">Unit</th>
                                                            <th style="display: none;">Tds Loading Hamali</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style="cursor: pointer;" (click)="rowSelectedAgnetCommisionDetails(agentCommissionData);" *ngFor="let agentCommissionData of agentCommissionDataList ">
                                                            <td>{{ agentCommissionData.agentName }}</td>
                                                            <td>{{ agentCommissionData.tdsChg }}</td>
                                                            <td>{{ agentCommissionData.mnthAmt }}</td>
                                                            <td>{{ agentCommissionData.mnthAmtUnit }}</td>
                                                            <td>{{ agentCommissionData.tempoFrgt }}</td>
                                                            <td>{{ agentCommissionData.unitTempoFrgt }}</td>

                                                            <td style="display: none;">
                                                                {{ agentCommissionData.multipleComms }}</td>
                                                            <td style="display: none;">
                                                                {{ agentCommissionData.multipleCommsUnit }}</td>
                                                            <td style="display: none;">
                                                                {{ agentCommissionData.multipleLdgHamComms }}</td>
                                                            <td style="display: none;">
                                                                {{ agentCommissionData.multipleLdgHamUnit }}</td>
                                                            <td style="display: none;">
                                                                {{ agentCommissionData.tdsOnLdg }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>