<!--<html>

<head>
	<style>
		.imb_patch {
			text-align: center;
			background-color: orange;
			padding: 2px;
		}
	</style>
</head>

<body>-->
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-university"></i>
								</span>
							</div>
							<input id="{{pageId}}multipleDeliveryPointDestinationId" type="text" class="form-control"
								(selectItem)="clickListnerForMultipleDeliveryPointStation($event)"
								[(ngModel)]="modelMultipleDeliveryPointDestination"
								[ngbTypeahead]="searchMultipleDeliveryPointDest"
								[resultFormatter]="formatterMultipleDeliveryPointDest"
								[inputFormatter]="formatterMultipleDeliveryPointDest"
								(focus)="focusMultipleDeliveryPointDestTA$.next($any($event).target.value)" />
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-search"></i>
								</span>
							</div>
							<input type="text" class="form-control" id="{{pageId}}deliveryPointMultipleFormat"
								aria-describedby=" basic-addon11 " autocomplete="off" />
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="control">
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-university"></i>
									</span>
								</div>
								<input type="number" class="form-control" id="{{pageId}}deliveryPointMultipleSearch"
									aria-describedby=" basic-addon11 "
									(keypress)="keyEventHandlerMultiDeliveryPointSearch($event.keyCode)"
									autocomplete="off" />
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<div class="input-group">
							<a href="javascript:;" type='button' #addBtn style="padding: 1px 4px;"
								class="btn btn-icon-only yellow" (click)="clearFieldBtn();">
								<i class="fa fa-times" title="Clear"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}deliveryPointMultipleTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsDeliveryPointMultiple"
								[dtTrigger]="dtTriggerDeliveryPointMultiple">
								<thead>
									<tr>
										<th>Delivery Area</th>
										<th>Invoice Number</th>
										<th>Delivery Points</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let gridDeliveryPointData of gridDeliveryPointMultiple let i = index">
										<td>{{ gridDeliveryPointData.deliveryarea }}</td>
										<td>{{ gridDeliveryPointData.invoiceNumber }}</td>
										<td>
											<input type="checkbox" id="radioBtnMultiplePointId"
											name="radioBtnMultiplePointId"
												[(ngModel)]="gridDeliveryPointData.deliveryPoints">
										</td>
										<td><button style="padding: 1px 4px; background-color: #ffffff00;"
												class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
												(click)="deleteRowSelectedMultipleDeliveryPt(gridDeliveryPointData,i)">
												<i title="Remove" class="fas fa-trash"></i>
											</button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>
<!--</body>

</html>-->