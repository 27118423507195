import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserDataDto } from "src/app/dto/UserData-dto";
import swal from 'sweetalert';
import { MasterService } from 'src/app/dataService/master-service';
@Component({
	selector: 'app-edit-profile',
	templateUrl: './edit-profile.component.html',
	styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
	closeResult: string;
	animal: string;
	name: string;
	isLoggedIn = true;
	userDataDtoReturnSession: any;

	@ViewChild('content') content: TemplateRef<any>;
	constructor(private modalService: NgbModal,
		public dialog: MatDialog,
		private router: Router, public changeDetectorRef: ChangeDetectorRef

	) {
		{

			if (sessionStorage.length == 0) {
				this.isLoggedIn = false;
				swal({
					title: "Session Expired",
					text: "Please relogin to access the application!",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					this.logInPage();
				})
			}
			if (this.isLoggedIn) {
				this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

			}
		}
	}
	open2(content) {
		this.modalService.open(content, { centered: true }).result.then(
			result => {
				this.closeResult = `Closed with: ${result}`;
			},
			reason => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	ngOnInit() {

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
}

@Component({
	selector: 'app-edit-profile-popup',
	templateUrl: 'edit-profile.popup.component.html',
})
export class EditProfilePopupComponent {
	closeResult: string;
	userDataDtoReturnSession: any;
	isLoggedIn = true;
	userDataDto: UserDataDto = new UserDataDto();
	curPwd: any;
	newPwd: any;
	userDtoForUpdate: UserDataDto = new UserDataDto();
	showSpinnerForAction = false;
	pageId = "edpc";

	constructor(public dialogRef: MatDialogRef<EditProfilePopupComponent>,
		private router: Router, private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef
	) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}

		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
		}
	}

	ngOnInit() {

	}

	fieldFocus(e, el) {
		if (e.keyCode == 13) { // press A
			el.focus();
		}
	}

	onTransferClick(): void {
		this.dialogRef.close();
	}

	onCancelClick(): void {
		this.dialogRef.close();
		this.clearFields();
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	confirmChangePassword() {
		swal({
			title: "Confirm Changes",
			text: "Sure U Want to change the password?",
			icon: "info",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((sureYesBtn) => {
			if (sureYesBtn) {
				this.changePassword();
			}
		});
	}

	changePassword() {
		this.curPwd = $("#" + this.pageId + "curPwd").val();
		this.newPwd = $("#" + this.pageId + "newPwd").val();
		if (this.curPwd == '' || this.curPwd == null || this.curPwd == 'undefine' || this.newPwd == '' || this.newPwd == null || this.newPwd == 'undefine') {
			swal({
				title: "Not allowed",
				text: "Please Enter current and new password",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			})
			return false;
		} else {
			this.userDtoForUpdate = new UserDataDto();
			this.userDtoForUpdate.name = this.userDataDtoReturnSession.userId;
			this.userDtoForUpdate.userPasswd = this.curPwd;
			this.userDtoForUpdate.newPassword = this.newPwd;
			this.userDtoForUpdate.office = this.userDataDtoReturnSession.office;
			this.userDtoForUpdate.companyId = this.userDataDtoReturnSession.companyId;
		}
		this.changePasswordService();

	}
	changePasswordService = () => {
		this.showSpinnerForAction = true;
		this.masterService.changePassword(this.userDtoForUpdate).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == 'failed') {
					swal({
						title: "Failed",
						text: "Failed to update the details!",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else if (response.status == 'invalid') {
					swal({
						title: "warning",
						text: "Invalid User/Password, please verify and try again!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else if (response.status == 'valid') {
					swal({
						title: "Success",
						text: "Password changed successfully!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.dialogRef.close();
						this.logInPage();
					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Updating", "info");
			}, () => console.log('done');
	};

	clearFields() {
		this.curPwd = '';
		this.newPwd = '';
		$("#" + this.pageId + "curPwd").val('');
		$("#" + this.pageId + "newPwd").val('');
		this.userDtoForUpdate = new UserDataDto();
	}

}