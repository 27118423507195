<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">

        <div>
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                    <tr>
                        <td style="padding-top: 10px;" width='40%' valign='top'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                            <br> {{address}}
                        </td>

                        <td width='60%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='40%'><strong>Two Point Door
                                                        Delivery LRs : </strong></td>
                                                <td width='60%' style='border-bottom: 1px solid #000;'>
                                                    {{twoPtLrs}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>Truck No : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{vehicleNumber}} </strong>
                                                </td>
                                                <td width='20%'><strong>Supplier : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{supplierName}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>Invoice No : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{invoiceNum}} </strong>
                                                </td>
                                                <td width='20%'><strong>Truck Type : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{goodsType}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>From : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{fromStation}} </strong>
                                                </td>
                                                <td width='20%'><strong>To : </strong></td>
                                                <td width='30%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{toStation}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='15%'><strong>Dated : </strong></td>
                                                <td width='15%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{invoiceDate}} </strong>
                                                </td>
                                                <td width='15%'><strong>Gur.Wgt : </strong></td>
                                                <td width='15%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{gurWgt}}
                                                    </strong></td>
                                                <td width='15%'><strong>Kanta Wgt : </strong></td>
                                                <td width='15%' style='border-bottom: 1px solid #000;'>
                                                    <strong>{{kantaWgt}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <h6 style='text-align: center; font-size: large;'>
                    <strong><u>{{toStation}} Booking Invoice</u></strong>
                </h6>
            </div>
        </div>
        <br>
        <!--Second  row starts-->
        <!--<div class="container border">
			<table width='100%' border='0' cellpadding='0' cellspacing='0'>
				<tr>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;'
						valign='top' type='text'><strong> Note **</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> LR No</strong></td>
					<td width='4%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Art</strong></td>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Pvt.Mrk</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking Date</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking From</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Description</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignee</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignor</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Act Wgt</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Chg Wgt</strong></td>
					<td width='8%' align='center'
						style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;'
						valign='top' type='text'><strong>Goods Value</strong></td>
				</tr>
			</table>
		</div>-->
        <!--<div class="container border">
			<div>-->
        <table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
            <tr>
                <td width='9%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;' valign='top' type='text'><strong> Note **</strong></td>
                <td width='9%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> LR No</strong></td>
                <td width='3%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Art</strong></td>
                <td width='7%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Pvt.Mrk</strong></td>
                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Booking Date</strong></td>
                <td width='11%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Booking From</strong></td>
                <td width='11%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> Description</strong></td>
                <td width='14%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong>Consignee</strong></td>
                <td width='13%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong>Consignor</strong></td>
                <td width='4%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong>AW</strong></td>
                <td width='4%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong>CW</strong></td>
                <!-- <td width='7%' align='center'
                    style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000;'
                    valign='top' type='text'><strong>Goods Value</strong></td> -->
                <td width='5%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000;' valign='top' type='text'><strong>e-Inv</strong></td>
            </tr>
            <ng-container *ngFor="let item of result">
                <tr>
                    <td colspan="12" style='border: 1px solid #000;'>
                        <ng-container *ngIf="item.destination != item.deliveryPoints">
                            <strong>{{ item.destination }} - {{item.deliveryPoints}}</strong>
                        </ng-container>
                        <ng-container *ngIf="item.destination == item.deliveryPoints">
                            <strong>{{ item.deliveryPoints }}</strong>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngFor="let value of item.values" [ngClass]="{'hcRowHighlight': value.isHandWithCarePrint||value.isNewConsignee}">
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <!-- <span>{{value.isHandWithCareStr}}</span>  -->
                        <span>{{value.isHandWithCare}}</span>
                        <span>{{value.isBankLr}}
                        </span> <span>{{value.selfLrStr}}</span>
                    </td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.lrNumber}}</td>
                    <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.totalArticles}}</td>
                    <td width='7%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <span>{{value.privateMarker}}</span>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.bookingDateStr}}</td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.bookedFrom}}</td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.description}}</td>
                    <td width='14%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.consigneeName}}</td>
                    <td width='13%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.consignorName}}</td>
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.actualWeight}}</td>
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.chargedWeight}}</td>
                    <!-- <td width='7%' align='center'
                        style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
                        {{value.goodsValue}}</td> -->
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
                        {{value.consignorEInvoiceEnable}}</td>
                </tr>
                <tr>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <strong> Total </strong>
                    </td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.totalArticles}}</strong>
                    </td>
                    <td width='7%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='14%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='13%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.actualWeight}}</strong>
                    </td>
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.chargedWeight}}</strong>
                    </td>
                    <!-- <td width='7%' align='center'
                        style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
                        <strong>{{item.goodsValue}}</strong>
                    </td> -->
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
                    </td>
                </tr>
            </ng-container>
            <br>
            <br>
            <tr>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                    <strong> Total </strong>
                </td>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totLrsNet}}</strong>
                </td>
                <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totArtNet}}</strong>
                </td>
                <td width='7%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='14%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='13%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totActWgtNet}}</strong>
                </td>
                <td width='4%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totChgWgtNet}}</strong>
                </td>
                <!-- <td width='7%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
                    <strong>{{totGoodsValueNet}}</strong>
                </td> -->
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
            </tr>
        </table>
        <!--/div>-->
        <!--</div>
		</div>-->
        <!--<br>
		<div class="container border">
			<table>
				<tr>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						<strong> Total </strong> </td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='4%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{totArtNet}}</strong>
					</td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totActWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totChgWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000; '>
						<strong>{{totGoodsValueNet}}</strong></td>
				</tr>
			</table>
		</div>-->
        <div class="row">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-left: 20px; font-size: 17px; padding-top: 30px;'>
                                <strong>HC-HANDLE WITH CARE, NW PRTY-NEW PARTY, COD </strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <br>
                            <br>
                            <h6 style='margin-right: 20px; font-size: 17px; padding-top: 30px;'>
                                <strong>Loading Clerk : </strong> <strong> <u
                                        style='border-bottom: 1px solid black; padding-left: 200px;'>
                                    </u>
                                </strong>
                            </h6>
                            <br>
                            <br>
                            <h6 style='margin-right: 20px; font-size: 17px; padding-top: 30px;'>
                                <strong>Incharge Sign : </strong> <strong> <u
                                        style='border-bottom: 1px solid black; padding-left: 200px;'>
                                    </u>
                                </strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>