//Asrar_Jr_Chg_V1 : 09/05/2024 - As per instruction by Mr Musaddik bhai, we need printed details of COD (Cheque on delivery) LRs on hire slips as we previously showed short / extra details.
//Imran_Ch_V2 : 08/07/2024 - Changed hireamount details block from session source to hirenumber source
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import *  as moment from 'moment';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { ResponseDto } from 'src/app/dto/Response-dto';
@Component({
    selector: 'app-hireslip-details-print',
    templateUrl: './hireslip-details-print.component.html',
    styleUrls: ['./hireslip-details-print.component.css']
})
export class HireslipDetailsPrintComponent implements OnInit {

    @ViewChild('hireslipPrint') printEl: ElementRef;
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    address: any;
    //summaryLRDto: LRDto = new LRDto();
    listOfCodLrDto: LRDto = new LRDto();
    hireSlipDto: HireSlipDto = new HireSlipDto();
    hireSlipDtoTimeDuration: HireSlipDto = new HireSlipDto();
    hireSlipDtoFor_LRShortage: HireSlipDto[] = [];
    listOfShortageLrs = new Array<String>();
    commonList: any[];
    fromStation: any;
    toStation: any;
    hireslipNumber: any;
    invoiceNumber: any;
    ewayBillNo: any;
    invoiceDate: any;
    invoiceDateStr: any;
    lorryNumber: any;
    panNo: any;
    lorryOwnerName: any;
    lorryType: any;
    truckLength: any;
    lorry_address: any;
    brokerName: any;
    brokerPanNo: any;
    totalLrs: any;
    totalArticles: any;
    guranteeWgt: any;
    kantaWgt: any;
    companyName: any;
    totalHire: any;
    advanceAmt: any;
    balanceAmt: any;
    payableAt: any;
    toPay: any;
    driverName: any;
    driverPhoneNo: any;
    driverLicNo: any;
    departureDate: any;
    departureDateStr: any;
    departureTime: any;
    remarks: any;
    messageText: any;
    lrShortage: any;
    validateLrShortage: any;
    splitTimeDetails: string[];
    splitEachTimeDetails: string[];
    fromTime1: any;
    toTime1: any;
    rewardingAmount1: any;
    fromTime2: any;
    toTime2: any;
    rewardingAmount2: any;
    fromTime3: any;
    toTime3: any;
    rewardingAmount3: any;
    datatableForLrsShortage: any;
    hideShowLRsExtraTable: boolean = false;
    hideShowForRewardTable: boolean = false;
    hideShowForLRShortageTable: boolean = false;
    hideShowForCodLrsTable: boolean = false;
    num: any;
    n: any;

    hideShowForDoorCharge: boolean = false;
    hideShowForVehicleHeight: boolean = false;
    hideShowForDoorDelivery: boolean = false;
    hideShowForExtraWeight: boolean = false;
    hideShowForCheckPost: boolean = false;
    hideShowForOtherCharges: boolean = false;
    doorCharge: any;
    vehicleHeight: any;
    doorDelivery: any;
    extraWeight: any;
    checkPost: any;
    otherCharges: any;
    printBarcode = [];
    barcodeValue: any = null;
    hideShowBarcode = true;
    barcodeView: any;
    pageId = "hdpc";

    CODLrNumbers: any;
    hideShowCODLR: boolean = false;

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.hireSlipDto = JSON.parse(localStorage.getItem('hireslipDetailsPrintForHireslip_Dto'));
            this.hireSlipDtoTimeDuration = JSON.parse(localStorage.getItem('hireslipDetailsPrintForHireSlipDto'));
            this.hireSlipDtoFor_LRShortage = JSON.parse(localStorage.getItem('hireslipDetailsPrintLRShortgeForHireslip_Dto'));
            this.listOfShortageLrs = JSON.parse(localStorage.getItem('hireslipDetailsPrintForLRShortgeList'));
            this.commonList = JSON.parse(localStorage.getItem('hireslipDetailsPrintForCommonParameters'));
            this.listOfCodLrDto = JSON.parse(localStorage.getItem('hireslipDetailsPrintCodLrs'));
            //Basha
            this.barcodeView = JSON.parse(localStorage.getItem('hireslipDetailsPrintForInRptPrintHideBarcode'));

            //Asrar_Jr_Chg_V1
            this.CODLrNumbers = JSON.parse(localStorage.getItem('hireslipDetailsPrintForCODLR'));
            console.log("PrintpageLrs" + this.CODLrNumbers);
            localStorage.clear();
            console.log(this.hireSlipDto);
            console.log(this.hireSlipDtoTimeDuration);
            console.log(this.hireSlipDtoFor_LRShortage);
            console.log(this.listOfShortageLrs);
            console.log(this.commonList);

            this.fromStation = this.hireSlipDto.fromstation == null ? "NA" : this.hireSlipDto.fromstation;
            this.toStation = this.hireSlipDto.tostation == null ? "NA" : this.hireSlipDto.tostation;
            this.hireslipNumber = this.hireSlipDto.hireslipnumber == null ? "NA" : this.hireSlipDto.hireslipnumber;
            this.invoiceNumber = this.hireSlipDto.invoiceNumber == null ? "NA" : this.hireSlipDto.invoiceNumber;
            this.ewayBillNo = this.hireSlipDto.ewayBill == null ? "NA" : this.hireSlipDto.ewayBill;

            this.invoiceDateStr = this.hireSlipDto.hireSlipDateStr == null ? "NA" :
                moment(this.hireSlipDto.hireSlipDateStr).format('DD-MM-YYYY');

            this.invoiceDate = this.hireSlipDto.hireSlipDateStr == null ? "NA" : this.hireSlipDto.hireSlipDateStr;
            this.lorryNumber = this.hireSlipDto.vehicleNumber == null ? "NA" : this.hireSlipDto.vehicleNumber;
            this.panNo = this.hireSlipDto.vehiCompPanNo == null ? "NA" : this.hireSlipDto.vehiCompPanNo;
            this.lorryOwnerName = this.hireSlipDto.vehiCompName == null ? "NA" : this.hireSlipDto.vehiCompName;
            this.lorryType = this.hireSlipDto.truckType == null ? "NA" : this.hireSlipDto.truckType;
            this.truckLength = this.hireSlipDto.trkLgth == null ? "NA" : this.hireSlipDto.trkLgth;
            this.lorry_address = this.hireSlipDto.vehiCompAddress == null ? "NA" : this.hireSlipDto.vehiCompAddress;
            this.brokerName = this.hireSlipDto.brokerName == null ? "NA" : this.hireSlipDto.brokerName;
            this.brokerPanNo = this.hireSlipDto.brokerPanNo == null ? "NA" : this.hireSlipDto.brokerPanNo;
            this.totalLrs = this.hireSlipDto.totalLRs == null ? "NA" : this.hireSlipDto.totalLRs;
            this.totalArticles = this.hireSlipDto.totalArticles == null ? "NA" : this.hireSlipDto.totalArticles;
            this.guranteeWgt = this.hireSlipDto.gurWeight == null ? "NA" : this.hireSlipDto.gurWeight;
            this.kantaWgt = this.hireSlipDto.kantaWeight == null ? "NA" : this.hireSlipDto.kantaWeight;
            this.companyName = this.hireSlipDto.companyName == null ? "NA" : this.hireSlipDto.companyName;

            // set hire details to zero as per hire hide/show flow @09/03/2022
            //Imran_Ch_V2
            // if (this.userDataDtoReturnSession.disableHireInGeneration != null && this.userDataDtoReturnSession.disableHireInGeneration) {
                if (this.hireSlipDto.disableHireInGeneration != null && this.hireSlipDto.disableHireInGeneration) {
                
                this.totalHire = 0.0;
                this.advanceAmt = 0.0;
                this.balanceAmt = 0.0;
            } else if (this.hireSlipDto.tostation != null && this.hireSlipDto.tostation == "Chennai") {
                this.totalHire = 0.0;
                this.advanceAmt = 0.0;
                this.balanceAmt = 0.0;
            } else {
                this.totalHire = this.hireSlipDto.totalhire == null ? "NA" : this.hireSlipDto.totalhire;
                this.advanceAmt = this.hireSlipDto.advance == null ? "NA" : this.hireSlipDto.advance;
                this.balanceAmt = this.hireSlipDto.balance == null ? "NA" : this.hireSlipDto.balance;
            }
            this.payableAt = this.hireSlipDto.payableat == null ? "NA" : this.hireSlipDto.payableat;

            if (this.hireSlipDto.toPay == 0.0 || this.hireSlipDto.toPay == 0) {
                this.toPay = "ZERO";
            } else {
                this.toPay = this.inWords();
            }
            this.driverName = this.hireSlipDto.drivername == null ? "NA" : this.hireSlipDto.drivername;
            this.driverPhoneNo = this.hireSlipDto.driverMobileNumber == null ? "NA" : this.hireSlipDto.driverMobileNumber;
            this.driverLicNo = this.hireSlipDto.driverlicencenumber == null ? "NA" : this.hireSlipDto.driverlicencenumber;
            this.departureDate = this.hireSlipDto.departuredateStr == null ? "NA" : this.hireSlipDto.departuredateStr;

            this.departureDateStr = this.hireSlipDto.departuredateStr == null ? "NA" :
                moment(this.hireSlipDto.departuredateStr).format('DD-MM-YYYY');

            this.departureTime = this.hireSlipDto.departureTime == null ? "NA" : this.hireSlipDto.departureTime;
            this.remarks = this.hireSlipDto.remarks == null ? "NA" : this.hireSlipDto.remarks;
            this.messageText = this.hireSlipDto.messageText == null ? "NA" : this.hireSlipDto.messageText;

            //barcode print 
            this.barcodeValue = this.commonList[2];
            this.printBarcode = [{
                barcode: this.barcodeValue,
                Name: 'A'
            }
            ];
            console.log(this.printBarcode);
            /* this.printBarcode = [
                 {
                     SerialId: 585885,
                     Name: 'A'
                 }
             ];*/

            for (let i = 0; i < this.listOfShortageLrs.length; i++) {
                this.lrShortage = this.lrShortage + "," + this.listOfShortageLrs[i];
            }
            this.validateLrShortage = (this.lrShortage == null ? "" : this.lrShortage.replace("null,", ""));

            //
            if (this.hireSlipDtoTimeDuration.column1 != null) {
                this.splitTimeDetails = (this.hireSlipDtoTimeDuration.column1).split(",");
                console.log(this.splitTimeDetails);
                this.splitEachTimeDetails = null;
                var size = this.splitTimeDetails.length;
                console.log(this.splitTimeDetails + "size" + size);
                switch (size) {
                    case 1:
                        this.splitEachTimeDetails = this.splitTimeDetails[0].split("_");
                        this.fromTime1 = this.splitEachTimeDetails[0];
                        this.toTime1 = this.splitEachTimeDetails[1];
                        this.rewardingAmount1 = this.splitEachTimeDetails[2];
                        break;
                    case 2:
                        this.splitEachTimeDetails = this.splitTimeDetails[0].split("_");
                        this.fromTime1 = this.splitEachTimeDetails[0];
                        this.toTime1 = this.splitEachTimeDetails[1];
                        this.rewardingAmount1 = this.splitEachTimeDetails[2];

                        this.splitEachTimeDetails = this.splitTimeDetails[1].split("_");
                        this.fromTime2 = this.splitEachTimeDetails[0];
                        this.toTime2 = this.splitEachTimeDetails[1];
                        this.rewardingAmount2 = this.splitEachTimeDetails[2];
                        break;
                    case 3:
                        this.splitEachTimeDetails = this.splitTimeDetails[0].split("_");
                        this.fromTime1 = this.splitEachTimeDetails[0];
                        this.toTime1 = this.splitEachTimeDetails[1];
                        this.rewardingAmount1 = this.splitEachTimeDetails[2];

                        this.splitEachTimeDetails = this.splitTimeDetails[1].split("_");
                        this.fromTime2 = this.splitEachTimeDetails[0];
                        this.toTime2 = this.splitEachTimeDetails[1];
                        this.rewardingAmount2 = this.splitEachTimeDetails[2];

                        this.splitEachTimeDetails = this.splitTimeDetails[2].split("_");
                        this.fromTime3 = this.splitEachTimeDetails[0];
                        this.toTime3 = this.splitEachTimeDetails[1];
                        this.rewardingAmount3 = this.splitEachTimeDetails[2];
                        break;
                    default:
                        this.splitEachTimeDetails = null;
                        break;
                }
            }
            //temp disabled this due to error in hireslip print
            //for (let i = 0; i < this.hireSlipDtoFor_LRShortage.length; i++) {
            //this.datatableForLrsShortage.push(this.hireSlipDtoFor_LRShortage[i]);
            //}

            //lrs shortage
            if (this.listOfShortageLrs.length > 0) {
                this.hideShowLRsExtraTable = true;
            }
            //lrs reward amount details
            //if (this.hireSlipDtoTimeDuration.column1) {
            if (this.hireSlipDto.isRewarded) {
                this.hideShowForRewardTable = true;
            }

            if (this.hireSlipDtoFor_LRShortage.length > 0) {
                this.hideShowForLRShortageTable = true;
            }

            // for COD lrs
            if (this.listOfCodLrDto != null && this.listOfCodLrDto.lrDtos.length > 0) {
                this.hideShowForCodLrsTable = true;
            }

            if ((this.hireSlipDto.fromstation != null) &&
                this.hireSlipDto.fromstation == "KVH" &&
                (this.hireSlipDto.tostation != null) &&
                (!(this.hireSlipDto.tostation == "Bangalore"))) {

                if (this.hireSlipDto.doorOpen > 0) {
                    this.hideShowForDoorCharge = true;
                    this.doorCharge = this.hireSlipDto.doorOpen;
                }
                if (this.hireSlipDto.vehiHeight > 0) {
                    this.hideShowForVehicleHeight = true;
                    this.vehicleHeight = this.hireSlipDto.vehiHeight;
                }
                if (this.hireSlipDto.doorDelivery > 0) {
                    this.hideShowForDoorDelivery = true;
                    this.doorDelivery = this.hireSlipDto.doorDelivery;
                }
                if (this.hireSlipDto.extraWeight > 0) {
                    this.hideShowForExtraWeight = true;
                    this.extraWeight = this.hireSlipDto.extraWeight;
                }
                if (this.hireSlipDto.chkPost > 0) {
                    this.hideShowForCheckPost = true;
                    this.checkPost = this.hireSlipDto.chkPost;
                }
                if (this.hireSlipDto.otherChgs > 0) {
                    this.hideShowForOtherCharges = true;
                    this.otherCharges = this.hireSlipDto.otherChgs;
                }
            }

            console.log("HAI-Hireslip Print");
            console.log(this.printBarcode);
            //basha 
            if (this.barcodeView == "HideBarcode") {
                this.hideShowBarcode = false;
            } else {
                this.hideShowBarcode = true;
            }

            //Asrar_Jr_Chg_V1
            if (this.CODLrNumbers != null && this.CODLrNumbers != '' && this.CODLrNumbers != undefined) {
                this.hideShowCODLR = true;
            } else {
                this.hideShowCODLR = false;
            }
            console.log("COD Print")
            console.log(this.CODLrNumbers)

            window.setTimeout(function () {
                window.print();
            }, 0);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });

        }
    }

    ngOnInit() {

    }




    validatBeforePrint() {

    }

    clearField() {
        this.hideShowLRsExtraTable = false;
        this.hideShowForRewardTable = false;
        this.hideShowForLRShortageTable = false;
        this.hideShowForCodLrsTable = false;
        this.hideShowForDoorCharge = false;
        this.hideShowForVehicleHeight = false;
        this.hideShowForDoorDelivery = false;
        this.hideShowForExtraWeight = false;
        this.hideShowForCheckPost = false;
        this.hideShowForOtherCharges = false;
        this.hideShowCODLR = false;
    }

    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        //        this.num = this.hireSlipDto.toPay == null ? 0 : this.hireSlipDto.toPay == undefined ? 0 : this.hireSlipDto.toPay;
        this.num = this.balanceAmt == null ? 0 : this.balanceAmt == undefined ? 0 : this.balanceAmt;
        //        console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(
            /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }



    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
