import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-update-rate-increase',
    templateUrl: './update-rate-increase.component.html',
    styleUrls: ['./update-rate-increase.component.css']
})
export class UpdateRateIncreaseComponent implements OnInit {

    pageId = "updrtinc";
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    agentDtoSrc: AgentDetailsDto = new AgentDetailsDto();
    showSpinnerForAction = false;
    lrDtoCheckedListForSourceRateIncPopup: Array<LRDto> = [];
    sourceListDataList: any;
    dtTriggerSourceListForRateIncPopup: Subject<any> = new Subject();
    rateMasterDtoNewForRateInc: RateMasterDto = new RateMasterDto();
    selecetedRateType: any;
    enteredIncreaseCharge: any;
    dtOptionsSourceListForRateIncPopup: any;
    enteredEffectiveFromDate: any;
    multiSelect = false;


    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameRptSearchModal: any;
    agentNameRptSearchTA: Array<AgentDetailsDto> = [];
    focusAgentNameRptSearchTA$ = new Subject<string>();
    agentNameSearchRptSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameRptSearchTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameRptSearchTA
                : this.agentNameRptSearchTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentNameRptSearch = (x: { source: string }) => x.source;

    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private consigneeService: ConsigneeService,
        public dialogRef: MatDialogRef<UpdateRateIncreaseComponent>) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.gettingSrcList();
        }
    }

    ngOnInit() {
        this.dtOptionsSourceListForRateIncPopup = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerSourceListForRateIncPopup.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerSourceListForRateIncPopup.unsubscribe();
    }

    detailsForSrc() {
        this.agentDtoSrc = new AgentDetailsDto();
        this.agentDtoSrc.mode = "MainAndGrpRateMaster";
        this.agentDtoSrc.status = "Working";
        this.agentDtoSrc.companyId = this.userDataDtoReturnSession.companyId;
    }
    gettingSrcList = () => {
        this.detailsForSrc();
        // this.showSpinnerForAction = true;
        this.masterService.getAgentNamesGrouping(this.agentDtoSrc)
            .subscribe(
                (response) => {
                    this.lrDtoCheckedListForSourceRateIncPopup = [];
                    $("#" + this.pageId + "sourceForUpdateRateIncrease").DataTable().destroy();
                    this.sourceListDataList = [];
                    this.agentNameRptSearchTA = [];

                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No Source records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.sourceListDataList = response;
                        // console.log("this.sourceListDataList >> Getting Response");
                        // console.log(this.sourceListDataList);
                        // this.srcPopUp

                        this.agentDtoParam = new AgentDetailsDto();
                        this.agentDtoParam.source = "ALL";
                        this.agentNameRptSearchTA.push(this.agentDtoParam);
                        for (let i = 0; i < this.sourceListDataList.length; i++) {
                            this.agentNameRptSearchTA.push(this.sourceListDataList[i]);
                        }
                        // this.showSpinnerForAction = false;
                    }
                    this.dtTriggerSourceListForRateIncPopup.next();
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    // this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred While getting the Source List", "info");
                }, () => console.log('done');
    };

    rowCheckBoxCheckedForRateIncPopup(e, sourceListDataForRateIncPopup) {
        console.log("sourceListDataForRateIncPopup");
        console.log(sourceListDataForRateIncPopup);
        if (e.currentTarget.checked) {
            if (this.lrDtoCheckedListForSourceRateIncPopup.length == 0) {
                this.lrDtoCheckedListForSourceRateIncPopup = [];
                this.lrDtoCheckedListForSourceRateIncPopup.push(sourceListDataForRateIncPopup);
            } else {
                this.lrDtoCheckedListForSourceRateIncPopup.push(sourceListDataForRateIncPopup);
            }
        } else {
            let index = this.lrDtoCheckedListForSourceRateIncPopup.indexOf(sourceListDataForRateIncPopup);
            if (index > -1) {
                this.lrDtoCheckedListForSourceRateIncPopup.splice(index, 1);
            }
        }
        console.log(this.lrDtoCheckedListForSourceRateIncPopup);
    }

    updateRateIncreasePopUpBtn() {
        if ($("#" + this.pageId + "rateType").val() == null
            || $("#" + this.pageId + "rateType").val() == undefined ||
            $("#" + this.pageId + "rateType").val() == "" || $("#" + this.pageId + "increaseChargeId").val() == null
            || $("#" + this.pageId + "increaseChargeId").val() == undefined || $("#" + this.pageId + "increaseChargeId").val() == ""
            || $("#" + this.pageId + "effectiveFromDate").val() == null || $("#" + this.pageId + "effectiveFromDate").val() == undefined
            || $("#" + this.pageId + "effectiveFromDate").val() == ""
            || this.lrDtoCheckedListForSourceRateIncPopup == null || this.lrDtoCheckedListForSourceRateIncPopup.length == 0 ) {
            swal("Mandatory Field", "Please select the all are mandatory feilds to update Rate ", "warning");
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to Update the Rate details for the selected Source?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.addNewRateIncreaseDetails();
                }
            })
        }
    }

    addNewRateIncreaseDetailsForPopup() {
        this.rateMasterDtoNewForRateInc = new RateMasterDto();
        this.selecetedRateType = null;
        this.enteredIncreaseCharge = null;
        this.selecetedRateType = $("#" + this.pageId + "rateType").val();
        this.enteredIncreaseCharge = $("#" + this.pageId + "increaseChargeId").val();
        this.enteredEffectiveFromDate = $("#" + this.pageId + "effectiveFromDate").val();
        this.rateMasterDtoNewForRateInc.city = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoNewForRateInc.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoNewForRateInc.rateType = this.selecetedRateType;
        this.rateMasterDtoNewForRateInc.rate = this.enteredIncreaseCharge;
        this.rateMasterDtoNewForRateInc.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoNewForRateInc.effectiveFromDate = this.enteredEffectiveFromDate;

        if (this.lrDtoCheckedListForSourceRateIncPopup != null && this.lrDtoCheckedListForSourceRateIncPopup.length > 0) {
            this.rateMasterDtoNewForRateInc.listOfSource = [];
            for (let i = 0; i < this.lrDtoCheckedListForSourceRateIncPopup.length; i++) {
                console.log(this.lrDtoCheckedListForSourceRateIncPopup[i].source);
                this.rateMasterDtoNewForRateInc.listOfSource.push(this.lrDtoCheckedListForSourceRateIncPopup[i].source);
            }
        }
        console.log(this.rateMasterDtoNewForRateInc);
    }

    addNewRateIncreaseDetails() {
        this.addNewRateIncreaseDetailsForPopup();
        this.showSpinnerForAction = true;
        this.consigneeService.updateRateSourceWise(this.rateMasterDtoNewForRateInc).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "success") {
                    swal("Success", "Rate Details Updated Successfully", "success");
                    this.onCancelClick();
                } else {
                    swal("Failed", "Failed To Update Rate Details", "warning");
                }
                this.changeDetectorRef.detectChanges();
                this.showSpinnerForAction = false;
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Updating Rate Details", "warning");
            },
            () => console.log('done');
    }

    onCancelClick(): void {
        this.dialogRef.close("Close");
    }

}