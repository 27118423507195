
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { MasterService } from 'src/app/dataService/master-service';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { MemoService } from 'src/app/dataService/memo-service';
import { LrReportService } from 'src/app/dataService/lr-report-service';


@Component({
    selector: 'app-stocks-between-godown',
    templateUrl: './stocks-between-godown.component.html',
    styleUrls: ['./stocks-between-godown.component.css']
})
export class StocksBetweenGodownComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrStocksToCpTable: any;


    stocksBetweenGodownDataList: any;
    removestocksBetweenGodownDataList: any;


    onDestroyUnsubscribtionStocksToCp: Subscription;

    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;
pageId="sbgc";

    //for the select option with filter starts
    closeResult: string;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerStocksBetweenGodown: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsStocksBetweenGodown: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    fromGodownOptions: LRDto[];
    public modelFromGodown: any;
    lrDtoFromGodown: LRDto = new LRDto();
    lrDtoAddNewFromGodown: LRDto = new LRDto();
    fromGodownTA: Array<LRDto> = [];
    focusFromGodownTA$ = new Subject<string>();
    fromGodownSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromGodownTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromGodownTA
                : this.fromGodownTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromGodown = (x: { godownName: string }) => x.godownName;

    toGodownOptions: LRDto[];
    public modelToGodown: any;
    lrDtoAddNewToGodown: LRDto = new LRDto();
    toGodownTA: Array<LRDto> = [];
    focusToGodownTA$ = new Subject<string>();
    toGodownSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToGodownTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toGodownTA
                : this.toGodownTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterToGodown = (x: { godownName: string }) => x.godownName;

    modalRefferencecStockBetweenGodownPopUp: NgbModalRef;
    modalRefferenceStockBetweenGodownClosePopUp: string;
    @ViewChild('popModelNewGodownContent') private popModelNewGodownContent;

    lrDtoNewGodown: LRDto = new LRDto();
    validateNewGodownFieldValue: any = null;

    lrDtoForValidateLRNumber: LRDto = new LRDto();
    lrDtoReturn: LRDto = new LRDto();
    lrDtoForTransferBtnForLRNumber: LRDto = new LRDto();
    //listOfLrs: Array<String> = [];
    listOfLrs: string[];

    validateLRNumberFieldValue: any = null;
    validateFromGodownName: any = null;
    validateToGodownName: any = null;
    private newAttribute: any = {};
    countOfLr: any = null;
    sumOfTotArticle: any = null;
    sumOfTotActWgt: any = null;
    sumOfTotAmt: any = null;

    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, private modalService: NgbModal,
        private memoService: MemoService, private hireslipService: HireslipService,
        private lrReport: LrReportService, public changeDetectorRef : ChangeDetectorRef) {

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.setFromGowdownDetailsList();
        }
    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsStocksBetweenGodown = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 230,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
        }
        //this.dtTriggerStocksToCp.next();
    }
    ngAfterViewInit(): void {
        this.dtTriggerStocksBetweenGodown.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerStocksBetweenGodown.unsubscribe();
    }


    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    //for popup modal starts 

    fromGodownDropDownListner(event, popModelNewGodownContent) {
        this.modelFromGodown = event.item.godownName;
        $("#"+this.pageId+"fromGodown").val(event.item.godownName);
        if ($("#"+this.pageId+"fromGodown").val() == "Add New") {
            this.addNewFromToGodowmPopup(popModelNewGodownContent);
        }
    }

    toGodownDropDownListner(event, popModelNewGodownContent) {
        this.modelToGodown = event.item.godownName;
        $("#"+this.pageId+"toGodown").val(event.item.godownName);
        if ($("#"+this.pageId+"toGodown").val() == "Add New") {
            this.addNewFromToGodowmPopup(popModelNewGodownContent);
        }
    }

    getFromGowdownDetailsData() {
        this.lrDtoFromGodown = new LRDto();
        this.lrDtoFromGodown.branch = this.userDataDtoReturnSession.office;
        this.lrDtoFromGodown.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromGodown.mode = "active";
    }

    setFromGowdownDetailsList = () => {
        this.getFromGowdownDetailsData();
        this.showSpinnerForAction = true;
        this.masterService.getLocalGodowns(this.lrDtoFromGodown).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.fromGodownOptions = [];
                    this.fromGodownTA = [];
                    this.fromGodownOptions = response;
                    this.lrDtoAddNewFromGodown = new LRDto();
                    this.lrDtoAddNewFromGodown.godownName = "Add New";
                    this.fromGodownTA.push(this.lrDtoAddNewFromGodown);
                    for (let i = 0; i < this.fromGodownOptions.length; i++) {
                        this.fromGodownTA.push(this.fromGodownOptions[i]);
                    }

                    this.toGodownOptions = [];
                    this.toGodownTA = [];
                    this.toGodownOptions = response;
                    this.lrDtoAddNewToGodown = new LRDto();
                    this.lrDtoAddNewToGodown.godownName = "Add New";
                    this.toGodownTA.push(this.lrDtoAddNewToGodown);
                    for (let i = 0; i < this.toGodownOptions.length; i++) {
                        this.toGodownTA.push(this.toGodownOptions[i]);
                    }
                } else {
                    this.fromGodownOptions = [];
                    this.fromGodownTA = [];

                    this.toGodownOptions = [];
                    this.toGodownTA = [];
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Name Details", "warning");
            },
            () => console.log('done');
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    addNewFromToGodowmPopup(popModelNewGodownContent) {
        this.modalRefferencecStockBetweenGodownPopUp = this.modalService.open(popModelNewGodownContent,
            { centered: true, size: "sm" });
        this.modalRefferencecStockBetweenGodownPopUp.result.then((result) => {
            this.modalRefferenceStockBetweenGodownClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStockBetweenGodownClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    saveNewGodownBtn() {
        if ($("#"+this.pageId+"newGodownName").val() == null || $("#"+this.pageId+"newGodownName").val() == undefined ||
            $("#"+this.pageId+"newGodownName").val() == "") {
            swal("Mandatory Field", "Please enter the Godown Name Field", "warning");
            return false;
        } else {
            this.setNewGodownNameDetails();
        }
    }

    setNewGodownNameDetails() {
        this.lrDtoNewGodown = new LRDto();

        this.validateNewGodownFieldValue = $("#"+this.pageId+"newGodownName").val();

        this.lrDtoNewGodown.godownName = this.validateNewGodownFieldValue;
        this.lrDtoNewGodown.subStations = this.userDataDtoReturnSession.office;
        this.lrDtoNewGodown.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoNewGodown.companyId = this.userDataDtoReturnSession.companyId;
        this.masterService.addLocalGodowns(this.lrDtoNewGodown).subscribe(
            (response) => {
                //console.log(response);
                if (response.status == "Suceess") {
                    swal("Suceess", "New Godown Name Save Successfully", "success");
                    this.modalRefferencecStockBetweenGodownPopUp.close();
                    this.setFromGowdownDetailsList();
                    $("#"+this.pageId+"newGodownName").val('');
                    this.validateNewGodownFieldValue = null;
                } else if (response.status == "Duplicate") {
                    swal("Duplicate Entry", "" + $("#"+this.pageId+"newGodownName").val() + "  Name Already Exist", "warning");
                    return false;
                } else {
                    swal("Failed", "Failed to save new godown name", "error");
                    return false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting  addLocalGodowns", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };
    validateClearClickListnerBtn() {
        this.stocksBetweenGodownDataList = [];
        $("#"+this.pageId+"stockBetweenGodownTableId").DataTable().destroy();
        this.dtTriggerStocksBetweenGodown.next();
        this.clearField();
    }
    clearField() {
        $("#"+this.pageId+"lrNumber").val('');
        $("#"+this.pageId+"fromGodown").val('');
        $("#"+this.pageId+"toGodown").val('');
        this.validateLRNumberFieldValue = null;
        this.validateToGodownName = null;
        this.validateFromGodownName = null;
        this.modelFromGodown = null;
        this.modelToGodown = null;
        this.sumOfTotArticle = 0;
        this.sumOfTotActWgt = 0;
        this.sumOfTotAmt = 0;
        this.countOfLr = 0
    }
    validateLRNumberOKClickListnerBtn() {
        if ($("#"+this.pageId+"lrNumber").val() == null || $("#"+this.pageId+"lrNumber").val() == undefined ||
            $("#"+this.pageId+"lrNumber").val() == "") {
            swal("Mandatory Field", "Please enter the LR Number Field", "warning");
            return false;
        } else {
            this.validateLRNumberDataDetails();
        }
    }

    validateLRNumberDataDetails() {
        this.lrDtoForValidateLRNumber = new LRDto();
        this.validateLRNumberFieldValue = $("#"+this.pageId+"lrNumber").val();
        this.lrDtoForValidateLRNumber.lrNumber = this.validateLRNumberFieldValue;
        this.lrDtoForValidateLRNumber.branch = this.userDataDtoReturnSession.office;
        this.lrDtoForValidateLRNumber.stocksAt = this.userDataDtoReturnSession.office;
        this.lrDtoForValidateLRNumber.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrReport.getLROfSpecificStation(this.lrDtoForValidateLRNumber).subscribe(
            (response) => {
                console.log(response);
                if (response.size == 0) {
                    swal("LR Not Found", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " not found!", "warning");
                } else if (response.status == "InCP") {
                    swal("LR In Checkpost", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " is in " + response.checkPostName + "", "warning");
                } else if (response.status == "Delivered") {
                    swal("LR Delivered", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " Delivered ", "warning");
                } else {
                    this.lrDtoReturn = new LRDto();
                    this.lrDtoReturn = response;
                    this.gridReconfigure(this.lrDtoReturn);
                }
                $("#"+this.pageId+"lrNumber").val('');
                $("#"+this.pageId+"lrNumber").focus();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  getLROfSpecificStation", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    gridReconfigure(lrDtoReturn) {
        console.log(this.stocksBetweenGodownDataList);
        //if (this.stocksBetweenGodownDataList.length > 0) {
        if (this.stocksBetweenGodownDataList != undefined) {
            const checkLrNumberInTable = this.stocksBetweenGodownDataList.find(manualLRNumberCheckData => manualLRNumberCheckData.lrNumber === $("#"+this.pageId+"lrNumber").val());
            console.log(checkLrNumberInTable);
            if (checkLrNumberInTable == undefined) {
                this.addInTable(lrDtoReturn);
            } else {
                swal("Warning", "Duplicate LR Number", "warning");
                return false;
            }
        } else {
            this.addInTable(lrDtoReturn);
        }
    }
    addInTable(lrDtoReturn) {
        this.newAttribute.lrNumber = lrDtoReturn.lrNumber;
        this.newAttribute.totalArticles = lrDtoReturn.totalArticles;
        this.newAttribute.consigneeName = lrDtoReturn.consigneeName;
        this.newAttribute.consignorName = lrDtoReturn.consignorName;
        this.newAttribute.description = lrDtoReturn.description;
        this.newAttribute.actualWeight = lrDtoReturn.actualWeight;
        this.newAttribute.totalAmount = lrDtoReturn.totalAmount;
        console.log(this.newAttribute.lrNumber);
        $("#"+this.pageId+"stockBetweenGodownTableId").DataTable().destroy();

        if (this.stocksBetweenGodownDataList == null) {
            this.stocksBetweenGodownDataList = [];
        }

        if (this.stocksBetweenGodownDataList.length == 0) {
            this.stocksBetweenGodownDataList.push(this.newAttribute);
        } else {
            this.stocksBetweenGodownDataList.push(this.newAttribute);
        }
        this.dtTriggerStocksBetweenGodown.next();

        this.countOfLr = +this.countOfLr + 1;
        this.sumOfTotArticle = +this.sumOfTotArticle + +this.newAttribute.totalArticles;
        this.sumOfTotActWgt = +this.sumOfTotActWgt + +this.newAttribute.actualWeight;
        this.sumOfTotAmt = +this.sumOfTotAmt + +this.newAttribute.totalAmount;

        this.newAttribute = {};
    }

    rowSelectedDeleteForTable(stocksBetweenGodownData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Row",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.stocksBetweenGodownDataList.splice(index, 1);
                $("#"+this.pageId+"stockBetweenGodownTableId").DataTable().destroy();
                this.dtTriggerStocksBetweenGodown.next();
                console.log(this.stocksBetweenGodownDataList);
                this.sumOfTotArticle = 0;
                this.sumOfTotActWgt = 0;
                this.sumOfTotAmt = 0;
                this.countOfLr = 0;
                for (let i = 0; i < this.stocksBetweenGodownDataList.length; i++) {
                    this.countOfLr = +this.countOfLr + 1;
                    this.sumOfTotArticle = +this.sumOfTotArticle + +this.stocksBetweenGodownDataList[i].totalArticles;
                    this.sumOfTotActWgt = +this.sumOfTotActWgt + +this.stocksBetweenGodownDataList[i].actualWeight;
                    this.sumOfTotAmt = +this.sumOfTotAmt + +this.stocksBetweenGodownDataList[i].totalAmount;
                }
                this.newAttribute = {};
            }

        });
    }

    transferClickListnerBtn() {
        if ($("#"+this.pageId+"toGodown").val() == null || $("#"+this.pageId+"toGodown").val() == undefined ||
            $("#"+this.pageId+"toGodown").val() == "") {
            swal("Mandatory Field", "Please enter the to godown field", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Transfer",
                text: "Are U sure want to transfer selected Lrs From Godown  " + $("#"+this.pageId+"fromGodown").val() + " To Godown  " + $("#"+this.pageId+"toGodown").val() + "",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesTransferBtn) => {
                if (yesTransferBtn) {
                    this.setTransferLrDetailData();
                }
            });
        }
    };

    setTransferLrDetailData() {
        this.lrDtoForTransferBtnForLRNumber = new LRDto();
        this.validateToGodownName = $("#"+this.pageId+"toGodown").val();
        this.listOfLrs = [];
        for (let i = 0; i < this.stocksBetweenGodownDataList.length; i++) {
            this.listOfLrs.push(this.stocksBetweenGodownDataList[i].lrNumber);
        }
        this.lrDtoForTransferBtnForLRNumber.list = this.listOfLrs;
        this.lrDtoForTransferBtnForLRNumber.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForTransferBtnForLRNumber.godownName = this.validateToGodownName;
        this.lrDtoForTransferBtnForLRNumber.stocksAt = this.userDataDtoReturnSession.office;
        this.lrDtoForTransferBtnForLRNumber.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForTransferBtnForLRNumber);
        this.showSpinnerForAction = true;
        this.lrReport.godownTransfer(this.lrDtoForTransferBtnForLRNumber).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Success") {
                    swal("Success", "Selected Lrs Godown Transfer Save Successfully", "success");
                    this.stocksBetweenGodownDataList = [];
                    $("#"+this.pageId+""+this.pageId+"stockBetweenGodownTableId").DataTable().destroy();
                    this.dtTriggerStocksBetweenGodown.next();
                    this.clearField();
                    this.showSpinnerForAction = false;
                } else {
                    swal("Success", "Failed to save  godown Transfer", "success");
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  godownTransfer", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }



}