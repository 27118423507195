<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>
    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Consignee Admin Rate Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body" style="border-bottom: 1px solid orange;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">


                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="clearMethod();">Clear</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showSpinnerForAction" class="col-md-12 vl p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 vl p-t-10">
                        <div class="box-body">
                            <!-- 						<h6 class="imb_patch card-title text-white">Statement Less Checked And Pending Lrs</h6> -->
                            <table datatable id="{{pageId}}consigneeAdminRateForPartiesTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsigneeAdminRateReport" [dtTrigger]="dtTriggerConsigneeAdminRateReport">

                                <thead>
                                    <tr>
                                        <th>Consignee Name</th>
                                        <th>Commodity</th>
                                        <th>Source</th>
                                        <th>Unit</th>
                                        <th>Per Unit</th>
                                        <th>Rate</th>
                                        <th>Gc Chg</th>
                                        <th>Receipt Chg</th>
                                        <th>Service Chg</th>
                                        <th>D.Ham</th>
                                        <th>Unloading Charge(DD)</th>
                                        <th>Unit(DD)</th>
                                        <th>Unloading Charge(GD)</th>
                                        <th>Unit(GD)</th>
                                        <th>S.Hamali</th>
                                        <th>Lc Chg</th>
                                        <th>Bc Chg</th>
                                        <th>AOC Chg</th>
                                        <th>FOV</th>
                                        <th>DD</th>
                                        <th>Others</th>
                                        <th>Collection Man</th>
                                        <th>Delivery Type</th>
                                        <th>Eff. From</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let consigneeAdminRateReportData of consigneeAdminRateReportDataList ">
                                        <td>{{ consigneeAdminRateReportData.consignee }}</td>
                                        <td>{{ consigneeAdminRateReportData.commodityName }}</td>
                                        <td>{{ consigneeAdminRateReportData.source }}</td>
                                        <td>{{ consigneeAdminRateReportData.unit }}</td>
                                        <td>{{ consigneeAdminRateReportData.perUnit }}</td>
                                        <td>{{ consigneeAdminRateReportData.rate }}</td>
                                        <td>{{ consigneeAdminRateReportData.gcCharge }}</td>
                                        <td>{{ consigneeAdminRateReportData.receiptCharges }}</td>
                                        <td>{{ consigneeAdminRateReportData.serviceCharges }}</td>
                                        <td>{{ consigneeAdminRateReportData.hamaliDestination }}</td>
                                        <td>{{ consigneeAdminRateReportData.unloadingChg }}</td>
                                        <td>{{ consigneeAdminRateReportData.unloadingChgUnit }}</td>
                                        <td>{{ consigneeAdminRateReportData.unloadingChgGd }}</td>
                                        <td>{{ consigneeAdminRateReportData.unloadingChgUnitGd }}</td>
                                        <td>{{ consigneeAdminRateReportData.sHamali }}</td>
                                        <td>{{ consigneeAdminRateReportData.lcChg == true ? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.bcChg == true ? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.aocChg == true? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.riskCharge == true ? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.dd == true ? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.others == true ? "Yes" : "No" }}</td>
                                        <td>{{ consigneeAdminRateReportData.collectionMan == null ? "NA" : consigneeAdminRateReportData.collectionMan }}
                                        </td>
                                        <td>{{ consigneeAdminRateReportData.deliveryType == null ? "NA" : consigneeAdminRateReportData.deliveryType}}
                                        </td>
                                        <td>{{ consigneeAdminRateReportData.effectiveFromDate }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="box-body" style="padding-top: 20px;">
                            <!-- 						<h6 class="imb_patch card-title text-white">LR Details</h6> -->
                            <table datatable id="{{pageId}}consigneeCommodityRateDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodityRateDetailsTable" [dtTrigger]="dtTriggerCommodityRateDetailsTable">

                                <thead>
                                    <tr>
                                        <th>Commodity</th>
                                        <th>Source</th>
                                        <th>Unit</th>
                                        <th>Per Unit</th>
                                        <th>Rate</th>
                                        <th>Gc Chg</th>
                                        <th>Receipt Chg</th>
                                        <th>Service Chg</th>
                                        <th>D.Ham</th>
                                        <th>Unloading Charge(DD)</th>
                                        <th>Unit(DD)</th>
                                        <th>Unloading Charge(GD)</th>
                                        <th>Unit(GD)</th>
                                        <th>S.Hamali</th>
                                        <th>Lc Chg</th>
                                        <th>Bc Chg</th>
                                        <th>AOC Chg</th>
                                        <th>FOV</th>
                                        <th>DD</th>
                                        <th>Others</th>
                                        <th>Delivery Type</th>
                                        <th>Eff. From</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let commodityRateDetailsTableData of commodityRateDetailsTableDataList ">
                                        <td>{{ commodityRateDetailsTableData.commodityName }}</td>
                                        <td>{{ commodityRateDetailsTableData.source }}</td>
                                        <td>{{ commodityRateDetailsTableData.unit }}</td>
                                        <td>{{ commodityRateDetailsTableData.perUnit }}</td>
                                        <td>{{ commodityRateDetailsTableData.rate }}</td>
                                        <td>{{ commodityRateDetailsTableData.gcCharge }}</td>
                                        <td>{{ commodityRateDetailsTableData.receiptCharges }}</td>
                                        <td>{{ commodityRateDetailsTableData.serviceCharges }}</td>
                                        <td>{{ commodityRateDetailsTableData.hamaliDestination }}</td>
                                        <td>{{ commodityRateDetailsTableData.unloadingChg }}</td>
                                        <td>{{ commodityRateDetailsTableData.unloadingChgUnit }}</td>
                                        <td>{{ commodityRateDetailsTableData.unloadingChgGd }}</td>
                                        <td>{{ commodityRateDetailsTableData.unloadingChgUnitGd }}</td>
                                        <td>{{ commodityRateDetailsTableData.shamaliChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.lcChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.bcChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.aocChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.riskChargeStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.ddChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.otherChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.deliveryType == null ? "NA" : commodityRateDetailsTableData.deliveryType}}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.effectiveFromDateStg }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>