import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';


@Component({
    selector: 'app-stocks-for-tripsheet-page-trip-details',
    templateUrl: './stocks-for-tripsheet-page-trip-details.component.html',
    styleUrls: ['./stocks-for-tripsheet-page-trip-details.component.css']
})
export class StocksForTripsheetPageTripDetailsComponent implements OnInit {
    gettingDataFrmServiceFrExpensesDetailsEntryTable: any;


    expensesDetailsEntryDataList: any;


    onDestroyUnsubscribtionExpensesDetailsEntry: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //for the select option with filter starts
    addNewDriverName = false;
    addNewVehicleNumber = false;
    addNewLabourName = false;
    //for the select option with filter ends

pageId="stptd";

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerExpensesDetailsEntry: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsExpensesDetailsEntry: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    truckDataDtoNewVehicleNumberSave: TruckDataDto = new TruckDataDto();
    truckDataDtoNewVehicleNumberSaveReturn: any = null;

    vehicleNumberOption: Array<TruckDataDto> = [];
    truckDataDtoVehicleNumber: TruckDataDto = new TruckDataDto();
    truckDataDtoAddNewVehicleNumber: TruckDataDto = new TruckDataDto();
    public modelDropDownVehicleNumber: any;
    vehicleNumberTA: Array<TruckDataDto> = [];
    focusDropDownVehicleNumberTA$ = new Subject<string>();
    searchDropDownInputFieldVehicleNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownVehicleNumberTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberTA
                : this.vehicleNumberTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownVehicleNumber = (x: { truckNumber: string }) => x.truckNumber;

    driverNameOption: Array<DriverdetailsDto> = [];
    driverDataDtoDriverName: DriverdetailsDto = new DriverdetailsDto();
    public modelDropDownDriverName: any;
    driverNameTA: Array<DriverdetailsDto> = [];
    focusDropDownDriverNameTA$ = new Subject<string>();
    searchDropDownInputFieldDriverName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDriverNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameTA
                : this.driverNameTA.filter(v => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDriverName = (x: { driverName: string }) => x.driverName;

    labourNameOption: Array<LRDto> = [];
    lrDtoLabourName: LRDto = new LRDto();
    public modelDropDownLabourName: any;
    labourNameTA: Array<LRDto> = [];
    focusDropDownLabourNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourNameTA
                : this.labourNameTA.filter(v => v.loaderName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourName = (x: { loaderName: string }) => x.loaderName;

    labourHeadNameOption: Array<HireSlipDto> = [];
    lrDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

    modalRefferenceVehicleNumberPopUp: NgbModalRef;
    modalRefferenceVehicleNumberClosePopUp: string;
    @ViewChild('vehicleNumberPopUpTemplate') private vehicleNumberPopUpTemplate;

    enteredPopUpNewVehicleNumber: any = null;
    eneterdPopUpNewVehicleName: any = null;

    enteredlabourNameListOfHamaliAdd: any = null;

    viewToShowDeliveryManField = false;
    viewScheduleTripBtn = false;
    viewCreateTripSheetBtn = false;
    viewStockTripsheetPrint=false;


    constructor(private expensesDetailsEntrys: ReportService, private router: Router,
        private masterReadService: MasterReadService, private modalService: NgbModal,
        private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }

        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.viewToShowDeliveryManField = true;
                this.setLabourHeadNameDetailsList();
            } else {
                this.viewToShowDeliveryManField = false;
            }
            this.setVehicleDetailsList();
            this.setDriverNameDetailsList();
            this.setLabourNameDetailsList();

            if (this.userDataDtoReturnSession.mainStation == "Chennai") {
                this.viewScheduleTripBtn = true;
            }else{
                this.viewScheduleTripBtn = false;
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }







    ngOnInit(): void {


        this.dtOptionsExpensesDetailsEntry = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Particulars',
                    data: 'particulars'
                },
                {
                    title: 'Quantity',
                    data: 'quantity'
                },
                {
                    title: 'Unit',
                    data: 'unit'
                },
                {
                    title: 'Rate',
                    data: 'rate'
                },
                {
                    title: 'Amount',
                    data: 'amount'
                }
            ],
            dom: 'Bfrtip',
            buttons: [],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerExpensesDetailsEntry.unsubscribe();

        //this.onDestroyUnsubscribtionExpensesDetailsEntry.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    //for the select option with filter ends

    clickListnerForDropDownInputFieldVehicleNumber(event, vehicleNumberPopUpTemplate) {
        this.modelDropDownVehicleNumber = event.item;
        $("#"+this.pageId+"dropDownInputFieldVehicleNumber").val(this.modelDropDownVehicleNumber.truckNumber);
        if ($("#"+this.pageId+"dropDownInputFieldVehicleNumber").val() == "Add New") {
            this.vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate);
        } else {
            this.modalRefferenceVehicleNumberPopUp.close();
        }
    }

    vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate) {
        this.modalRefferenceVehicleNumberPopUp = this.modalService.open(vehicleNumberPopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceVehicleNumberPopUp.result.then((result) => {
            this.modalRefferenceVehicleNumberClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceVehicleNumberClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    closePopUpVehiclePopup() {
        $("#"+this.pageId+"addNewVehicleNumberId").val('');
        $("#"+this.pageId+"addNewVehicleNameId").val('');
        $("#"+this.pageId+"dropDownInputFieldVehicleNumber").val('');
        this.modelDropDownVehicleNumber = null;
        this.modalRefferenceVehicleNumberPopUp.close();
    }

    resetBtnVehicleNumberPopup() {
        $("#"+this.pageId+"addNewVehicleNumberId").val('');
        $("#"+this.pageId+"addNewVehicleNameId").val('');
        $("#"+this.pageId+"dropDownInputFieldVehicleNumber").val('');
        this.modelDropDownVehicleNumber = null;
    }

    saveBtnVehicleNumberPopup() {

        if ($("#"+this.pageId+"addNewVehicleNumberId").val() == null || $("#"+this.pageId+"addNewVehicleNumberId").val() == undefined ||
            $("#"+this.pageId+"addNewVehicleNumberId").val() == "" || $("#"+this.pageId+"addNewVehicleNameId").val() == null ||
            $("#"+this.pageId+"addNewVehicleNameId").val() == undefined || $("#"+this.pageId+"addNewVehicleNameId").val() == "") {
            swal("Mandatory Fields", "Please enter the mandatory mields", "warning");
            return false;
        } else {
            this.truckDataDtoNewVehicleNumberSave = new TruckDataDto();
            this.enteredPopUpNewVehicleNumber = $("#"+this.pageId+"addNewVehicleNumberId").val();
            this.eneterdPopUpNewVehicleName = $("#"+this.pageId+"addNewVehicleNameId").val();
            this.truckDataDtoNewVehicleNumberSave.truckNumber = this.enteredPopUpNewVehicleNumber;
            this.truckDataDtoNewVehicleNumberSave.vehicleName = this.eneterdPopUpNewVehicleName;
            this.truckDataDtoNewVehicleNumberSave.office = this.userDataDtoReturnSession.office;
            this.truckDataDtoNewVehicleNumberSave.mainStation = this.userDataDtoReturnSession.mainStation;
            this.truckDataDtoNewVehicleNumberSave.companyId = this.userDataDtoReturnSession.companyId;

            this.masterService.createVehicle(this.truckDataDtoNewVehicleNumberSave).subscribe(
                (response) => {
                    console.log(response);
                    this.truckDataDtoNewVehicleNumberSaveReturn = null;
                    this.truckDataDtoNewVehicleNumberSaveReturn = response;
                    if (this.truckDataDtoNewVehicleNumberSaveReturn.status == "persisted") {
                        $("#"+this.pageId+"addNewVehicleNumberId").val('');
                        $("#"+this.pageId+"addNewVehicleNameId").val('');
                        $("#"+this.pageId+"dropDownInputFieldVehicleNumber").val('');
                        this.modelDropDownVehicleNumber = null;
                        this.modalRefferenceVehicleNumberPopUp.close();
                        this.setVehicleDetailsList();
                        swal("Sucessfully Added", "Vehicle Details Added Sucessfully", "success");
                        //this.showSpinnerForAction = false;
                    } else {
                        swal("Failure", "Failed to Create Vehicle Details", "error");
                        //this.showSpinnerForAction = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    //this.showSpinnerForAction = false;
                    swal("Server Error", "Problem occur while create Vehicle Setup ", "error");
                },
                () => console.log('done');
        }
    }






    getVehicleNumberDetails() {
        this.truckDataDtoVehicleNumber = new TruckDataDto();
        this.truckDataDtoVehicleNumber.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDtoVehicleNumber.isActive = true;
        this.truckDataDtoVehicleNumber.companyId = this.userDataDtoReturnSession.companyId;
    }

    setVehicleDetailsList() {
        this.showSpinnerForAction = true;
        this.getVehicleNumberDetails();
        this.masterReadService.getVehicleSetupMaster(this.truckDataDtoVehicleNumber).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.vehicleNumberOption = response;
                    this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                    this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                    this.vehicleNumberTA.push(this.truckDataDtoAddNewVehicleNumber);
                    for (let i = 0; i < this.vehicleNumberOption.length; i++) {
                        this.vehicleNumberTA.push(this.vehicleNumberOption[i]);
                    }
                    //hashmap concept pending here
                    this.showSpinnerForAction = false;
                } else {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                    this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                    this.vehicleNumberTA.push(this.truckDataDtoAddNewVehicleNumber);
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    clickListnerForDropDownInputFieldDriverName(event) {
        this.modelDropDownDriverName = event.item;
        $("#"+this.pageId+"dropDownInputFieldDriverName").val(this.modelDropDownDriverName.driverName);
        console.log(this.modelDropDownDriverName);
        $("#"+this.pageId+"inputFieldDriverConatctNumber").val(this.modelDropDownDriverName.mobileNo == null ? null :
            this.modelDropDownDriverName.mobileNo == undefined ? null :
                this.modelDropDownDriverName.mobileNo);


    }

    getDriverNameDetails() {
        this.driverDataDtoDriverName = new DriverdetailsDto();
        this.driverDataDtoDriverName.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDataDtoDriverName.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDataDtoDriverName.mode = "MainStation";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.driverDataDtoDriverName.mode = "Station";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.office;

        }
    }

    setDriverNameDetailsList() {
        this.showSpinnerForAction = true;
        this.getDriverNameDetails();
        this.masterReadService.getDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.driverNameOption = response;
                    for (let i = 0; i < this.driverNameOption.length; i++) {
                        this.driverNameTA.push(this.driverNameOption[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForDropDownLabourName(event) {
        console.log(event);
        if (event.charCode == 13) {
            this.enteredlabourNameListOfHamaliAdd = $("#"+this.pageId+"dropDownInputFieldLabourName").val();
            $("#"+this.pageId+"hamalis").focus();
            if ($("#"+this.pageId+"hamalis").val() != null &&
                $("#"+this.pageId+"hamalis").val() != undefined &&
                $("#"+this.pageId+"hamalis").val() != "") {
                this.enteredlabourNameListOfHamaliAdd = $("#"+this.pageId+"hamalis").val() + "," +
                    this.enteredlabourNameListOfHamaliAdd;
            }
            $("#"+this.pageId+"hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
                this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                    this.enteredlabourNameListOfHamaliAdd);
        }

    }
    clickListnerForDropDownInputFieldLabourName(event) {
        this.modelDropDownLabourName = event.item;
        $("#"+this.pageId+"dropDownInputFieldLabourName").val(this.modelDropDownLabourName.loaderName);
        this.enteredlabourNameListOfHamaliAdd = $("#"+this.pageId+"dropDownInputFieldLabourName").val();
        $("#"+this.pageId+"hamalis").focus();
        if ($("#"+this.pageId+"hamalis").val() != null &&
            $("#"+this.pageId+"hamalis").val() != undefined &&
            $("#"+this.pageId+"hamalis").val() != "") {
            this.enteredlabourNameListOfHamaliAdd = $("#"+this.pageId+"hamalis").val() + "," +
                this.enteredlabourNameListOfHamaliAdd;
        }
        $("#"+this.pageId+"hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
            this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                this.enteredlabourNameListOfHamaliAdd);
    }

    getLabourNameDetails() {
        this.lrDtoLabourName = new LRDto();
        this.lrDtoLabourName.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLabourName.companyId = this.userDataDtoReturnSession.companyId;
    }

    setLabourNameDetailsList() {
        this.showSpinnerForAction = true;
        this.getLabourNameDetails();
        this.masterReadService.getLabourDetails(this.lrDtoLabourName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.labourNameOption = response;
                    for (let i = 0; i < this.labourNameOption.length; i++) {
                        this.labourNameTA.push(this.labourNameOption[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    clickListnerForDropDownInputFieldLabourHeadName(event) {
        this.modelDropDownLabourHeadName = event.item;
        $("#"+this.pageId+"dropDownInputFieldLabourHeadName").val(this.modelDropDownLabourHeadName.loaderHead);
    }

    getLabourHeadNameDetails() {
        this.lrDtoLabourHeadName = new HireSlipDto();
        this.lrDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.showSpinnerForAction = true;
        this.getLabourHeadNameDetails();
        this.masterReadService.getLoaderHeadsDetails(this.lrDtoLabourHeadName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    };

    clickResetBtn(){
        localStorage.clear();
        localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(""));
        this.viewStockTripsheetPrint = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewStockTripsheetPrint = false;
            localStorage.clear();
        });
    }
}