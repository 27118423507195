<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Edit Consignee Details</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-lg-12">
							<h6 class="card-title">Basic Information</h6>
							<div class="row">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}consigneeName">
											<label>Consignee Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}city">
											<label>City</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-map-marker-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}contactPerson">
											<label>Contact Person</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}mobileNumber">
											<label>Mobile Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-phone"></i>
												</span>
											</div>
											<input type="number" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6 vl">
			<div class="card">
				<div class="card-body">
					<h6 class="card-title">Detailed Info</h6>
					<div class="row">
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}gstNumber">
									<label>GST Number</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i
											class="fas fa-hashtag"></i>
										</span>
									</div>
									<input type="text" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}officeNumber">
									<label>Office Number</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i class="fas fa-phone"></i>
										</span>
									</div>
									<input type="number" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}email">
									<label>Email</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i
											class="fas fa-envelope"></i>
										</span>
									</div>
									<input type="text" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group">
									<label>Delivery Type</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i class="fas fa-truck"></i>
										</span>
									</div>
									<select class="custom-select col-12" id="{{pageId}}deliveryType">
										<option selected>Select.....</option>
										<option value="doorDelivery">Door Delivery</option>
										<option value="godownDelivery">Godown Delivery</option>
									</select>
								</div>
							</div>
						</div>
						<!-- 									fas fa-plus -->
						<!-- 											the first autocomplete starts -->
						<div class="col-sm-12 col-md-12">
							<div class="control">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}area">
										<label>Area</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-map-marker-alt"></i>
											</span>
										</div>
										<input class="auto_selectOption input is-medium"
											placeholder="Select Area" [formControl]="controlArea"
											[appAutocomplete]="autocompleteArea" id="{{pageId}}area" name="area"
											#area (change)="areaMode(controlArea.value)">
									</div>
								</div>
							</div>
							<app-autocomplete #autocompleteArea="appAutocomplete">
							<ng-template appAutocompleteContent> <ng-container
								*ngIf="(areaOptions | filter: controlArea.value) as resultArea">
							<app-option *ngFor="let option of resultArea"
								[value]="option.label"> {{ option.label }} </app-option> <app-option
								class="no-result" *ngIf="!resultArea.length">No
							result</app-option> </ng-container> </ng-template> </app-autocomplete>
						</div>
						<!-- 											the first autocomplete ends -->
						<div *ngIf="areaYes" class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}newArea">
									<label>New Area</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i
											class="fas fa-map-marker-alt"></i>
										</span>
									</div>
									<input type="text" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<!-- 											the second autocomplete starts  -->
						<div class="col-sm-12 col-md-12">
							<div class="control">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}collectionMan">
										<label>Collection Man</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i class="fas fa-user"></i>
											</span>
										</div>
										<input class="auto_selectOption input is-medium"
											placeholder="Select Collection Man"
											[formControl]="controlCollectionMan"
											[appAutocomplete]="autocompleteCollectionMan">
									</div>
								</div>
							</div>
							<app-autocomplete #autocompleteCollectionMan="appAutocomplete">
							<ng-template appAutocompleteContent> <ng-container
								*ngIf="(collectionManOptions | filter: controlCollectionMan.value) as resultCollectionMan">
							<app-option *ngFor="let option of resultCollectionMan"
								[value]="option.label"> {{ option.label }} </app-option> <app-option
								class="no-result" *ngIf="!resultCollectionMan.length">No
							result</app-option> </ng-container> </ng-template> </app-autocomplete>
						</div>
						<!-- 											the second autocomplete ends -->
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}newCollectionMan">
									<label>New Collection Man</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i class="fas fa-user"></i>
										</span>
									</div>
									<input type="text" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}address">
									<label>Address</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i
											class="fas fa-hashtag"></i>
										</span>
									</div>
									<input type="text" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
						<!-- 											the third autocomplete starts  -->
						<div class="col-sm-12 col-md-12">
							<div class="control">
								<div class="form-group">
									<div class="input-group" id="{{pageId}}state">
										<label>State</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i
												class="fas fa-map-marker-alt"></i>
											</span>
										</div>
										<input class="auto_selectOption input is-medium"
											placeholder="Select State" [formControl]="controlState"
											[appAutocomplete]="autocompleteState">
									</div>
								</div>
							</div>
							<app-autocomplete #autocompleteState="appAutocomplete">
							<ng-template appAutocompleteContent> <ng-container
								*ngIf="(stateOptions | filter: controlState.value) as resultState">
							<app-option *ngFor="let option of resultState"
								[value]="option.label"> {{ option.label }} </app-option> <app-option
								class="no-result" *ngIf="!resultState.length">No
							result</app-option> </ng-container> </ng-template> </app-autocomplete>
						</div>
						<!-- 											the third autocomplete ends -->
						<div class="col-sm-12 col-md-12">
							<div class="form-group">
								<div class="input-group" id="{{pageId}}pincode">
									<label>Pincode</label>
									<div class="input-group-prepend">
										<span class="input-group-text"> <i
											class="fas fa-hashtag"></i>
										</span>
									</div>
									<input type="number" class="form-control"
										aria-describedby="basic-addon11">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-outline-success" mat-button
		(click)="onFinishClick()">Finish</button>
	<button class="btn btn-outline-success" mat-button
		(click)="onFinishAndConfirmClick()">Finish&Confirm</button>
	<button class="btn btn-outline-danger" mat-button
		(click)="onCancelClick()">Cancel</button>
</div>
