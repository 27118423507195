import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { BillingToSrdCollectionStockComponent } from 'src/app/cashmemo/transfer-cashmemo/billing-to-srd-collection-stock/billing-to-srd-collection-stock.component';
import { TransferToCollectionComponent } from 'src/app/cashmemo/transfer-cashmemo/transfer-to-collection/transfer-to-collection.component';
import { CashmemoTransferComponent } from 'src/app/cashmemo/transfer-cashmemo/cashmemo-transfer/cashmemo-transfer.component';
import { ManualCashmemoCheckComponent } from 'src/app/cashmemo/transfer-cashmemo/manual-cashmemo-check/manual-cashmemo-check.component';
import { CollectionManCashmemoStockCheckComponent } from 'src/app/cashmemo/transfer-cashmemo/collection-man-cashmemo-stock-check/collection-man-cashmemo-stock-check.component';
import { CollectionManStockTransferComponent } from 'src/app/cashmemo/transfer-cashmemo/collection-man-stock-transfer/collection-man-stock-transfer.component';
import { CollectionManToCollectionManStockTransferComponent, CollectionManToCollectionManStockTransferPopupComponent } from 'src/app/cashmemo/transfer-cashmemo/collection-man-to-collection-man-stock-transfer/collection-man-to-collection-man-stock-transfer.component';
import { ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent } from 'src/app/cashmemo/transfer-cashmemo/manual-cashmemo-check-and-collectionman-stock-check-print/manual-cashmemo-check-and-collectionman-stock-check-print.component';
import { DragulaModule } from 'ng2-dragula';
import { MaterialModule } from "src/app/material.module";
import { CollectionManStocksPrintComponent } from 'src/app/cashmemo/transfer-cashmemo/collectionman-stocks-print/collectionman-stocks-print.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, DataTablesModule, MatDialogModule, MaterialModule, DragulaModule, CashmemoReportModule],
    declarations: [
        BillingToSrdCollectionStockComponent,
        TransferToCollectionComponent,
        CashmemoTransferComponent,
        ManualCashmemoCheckComponent,
        CollectionManCashmemoStockCheckComponent,
        CollectionManStockTransferComponent,
        CollectionManToCollectionManStockTransferComponent,
        CollectionManToCollectionManStockTransferPopupComponent,
        ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent,
        CollectionManStocksPrintComponent
    ],
    entryComponents: [BillingToSrdCollectionStockComponent, CollectionManStockTransferComponent, CollectionManToCollectionManStockTransferPopupComponent],

})
export class TransferCashmemoModule { }
