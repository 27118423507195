import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
//for drag and drop starts
import { DragulaModule } from 'ng2-dragula';
//for drag and drop ends

import { SupplierRoutes } from './supplier.routing';
import { SupplierMasterComponent } from './supplier-master/supplier-master.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgxPrintModule } from 'ngx-print';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from "src/app/material.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( SupplierRoutes ), 
        FormsModule,  
        MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, 
        MatTableModule, MatSortModule, MatIconModule, MatMenuModule, NgxPrintModule, DataTablesModule, 
        DragulaModule, AutocompleteModule, MatAutocompleteModule, NgbModule,MaterialModule,CashmemoReportModule],
    declarations: [
        SupplierMasterComponent]
} )
export class SupplierModule { }
