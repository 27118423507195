import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends
//for datatable ends
import { LRDto } from "src/app/dto/LR-dto";
//for redirect to other page starts
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, from, merge } from 'rxjs';
import { NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
@Component( {
    selector: 'app-pending-commodity',
    templateUrl: './pending-commodity.component.html',
    styleUrls: ['./pending-commodity.component.css']
} )
export class PendingCommodityComponent implements  OnInit {

    //for popup modal starts
    closeResultMobile: string;
    closeResultContent: string;
    closeResultSuccess: string;
    //for popup modal ends
    getDataPendingCommodityDataTable: any;

    pendingCommodityDataList: any;

    onDestroyUnsubscribtionPendingCommodity: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
	lrDtoForDetails:LRDto=  new LRDto();
    dtTriggerPendingCommodity: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsPendingCommodity: any;
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	showSpinnerForAction=false;

    control = new FormControl();
    goodsTypeOptions = [
        { id: 1, label: 'Footwear' },
        { id: 2, label: 'Fan' },
        { id: 3, label: 'Helemt' },
        { id: 3, label: 'M S Pipe' }
    ];
    rateMasterDto: RateMasterDto = new RateMasterDto();

	rateMasterDtoOptions: RateMasterDto[];
	rateMasterDtoCommodityUnldAddNew: RateMasterDto = new RateMasterDto();
	public modelCommodityMainUnldg: any;
	modelCommodityMainUnldgTA: Array<RateMasterDto> = [];
	focusCommodityMainUnldgTA$ = new Subject<string>();
	searchCommodityMainUnldg = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityMainUnldgTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelCommodityMainUnldgTA
				: this.modelCommodityMainUnldgTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityMainUnldg = (x: { commodityName: string }) => x.commodityName;
	
	selectedCommodityName:any;
	modalRefferenceCommodityPopUp: NgbModalRef;
    modalRefferenceCommodityClosePopUp: string;
    @ViewChild('confirmPaymentPopUpTemplate') private confirmPaymentPopUpTemplate;
	lrDtoForUpdate:LRDto=  new LRDto();
	
	selectedRowLRNumber:any;
	selectedRowCommodity:any;
	showSpinnerForActionForPopup=false;
	lrDtoForUpdateRet:any;
	selectedRowLrForYesBtn:any;
	lrDtoForUpdateForYes:LRDto=  new LRDto();
	lrDtoForUpdateRetForYesBtn:any;
	pageId="pndcc";
	
    constructor(  private dashboardService: DashboardService,private masterreadService: MasterReadService,
        public dialog: MatDialog,
        private route: ActivatedRoute, private modalService: NgbModal,
        private router: Router,private memoService: MemoService, public changeDetectorRef : ChangeDetectorRef
    ) {

  if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          	this.getCommodityMasterDetailsList();
          }
    }
 logInPage() {
        this.router.navigate(['/authentication/login']);
    }

	ngOnInit(): void {

        this.dtOptionsPendingCommodity = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
          
           
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerPendingCommodity.unsubscribe();
    // }
    
    ngAfterViewInit(): void {
        this.dtTriggerPendingCommodity.next();
    }
   
    openPopup( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }

    openModalSuccess( contentSuccess ) {
        this.modalService.open( contentSuccess, { centered: true } ).result.then(
            resultSuccess => {
                this.closeResultSuccess = `Closed with: ${resultSuccess}`;
            },
            reason => {
                this.closeResultSuccess = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }
    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    //for the select option with filter ends
	gridReconifgureOnReloadBtn(){
		this.lrDtoForDetails=  new LRDto();
		this.lrDtoForDetails.destination=this.userDataDtoReturnSession.mainStation;
		this.lrDtoForDetails.companyId=this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForDetails);
		this.gridReconifgureDetails();
	}
	gridReconifgureDetails= () => {
    	        this.showSpinnerForAction = true;
    	         this.dashboardService.getPendingCommodity(this.lrDtoForDetails).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  //$("#"+this.pageId+"pendingCommodityId").DataTable().destroy();
							this.pendingCommodityDataList=[];
							  if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.pendingCommodityDataList=response;
    	               	  console.log(this.pendingCommodityDataList);
    	                }
    	            //   this.dtTriggerPendingCommodity.next();
					  this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the  Pending Commodity Details", "info");
                    }, () => console.log('done');
            };
            
            getCommodityListRead() {
        		this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        		this.rateMasterDto.destination = null;
                this.rateMasterDto.mode = "mainConsigneeMaster";
                this.rateMasterDto.status = "Working";
            }

            getCommodityMasterDetailsList = () => {
                //        console.log( this.lrDto.companyId );
                this.getCommodityListRead();
                this.masterreadService.getCommodityDetails( this.rateMasterDto ).subscribe(
                    ( response ) => {
                        if ( response ) {
        					console.log(response);
                         
        					if ( response.length == 0 ) {
                                swal( {
                                    title: "Warning",
                                    text: "No commodity details records found!",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                } );
                                this.rateMasterDtoOptions = [];
        						this.modelCommodityMainUnldgTA = [];
        						
        						
                            } else {
                                this.rateMasterDtoOptions = response;
        						this.modelCommodityMainUnldgTA = [];

                                for ( let i = 0; i < this.rateMasterDtoOptions.length; i++ ) {
                                    this.modelCommodityMainUnldgTA.push(this.rateMasterDtoOptions[i]);
        						}
        						
        						
                            }
                        }
						this.changeDetectorRef.detectChanges();
                    } ), ( error ) => swal( {
                        title: "Error",
                        text: "Server Error While Getting Commodity Master Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } ),
                    () => console.log( 'done' );
            };
      openGoodType( confirmPaymentPopUpTemplate, pendingCommodityData) {
       /* this.modalService.open( contentGoodType, { centered: true } ).result.then(
            resultMobile => {
                this.closeResultMobile = `Closed with: ${resultMobile}`;
            },
            reason => {
                this.closeResultMobile = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );*/
		this.selectedRowCommodity='';
		this.selectedRowLRNumber='';
		this.selectedRowLRNumber =pendingCommodityData.lrNumber;
        this.selectedRowCommodity =pendingCommodityData.mainCommodity;
		if(this.selectedRowLRNumber !=null || this.selectedRowLRNumber !=''  ){
			this.modalRefferenceCommodityPopUp = this.modalService.open(confirmPaymentPopUpTemplate,
                { centered: true });
            this.modalRefferenceCommodityPopUp.result.then((result) => {
                this.modalRefferenceCommodityClosePopUp = `Closed with: ${result}`;
            }, reason => {
                this.modalRefferenceCommodityClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
            });
		}
		  
    }
    //update on No btn click
      updateBtn(){
		this.updateCommodityDetails();
	}
            
         updateCommodityDetails(){
        	 this.selectedCommodityName='';
			this.selectedCommodityName=$("#"+this.pageId+"CommodityMainUnldgId").val();
			if(this.selectedCommodityName ==null ||this.selectedCommodityName ==''|| this.selectedRowLRNumber==null || this.selectedRowLRNumber=='' ||  this.selectedRowCommodity ==null ||  this.selectedRowCommodity =='' ){
				  swal({
						 title: "warning",
						 text: "Please select the Mandetory Fields",
						 icon: "warning",
						 closeOnClickOutside: false,
						 closeOnEsc: false,
					 });
					 return false;
				}
			this.lrDtoForUpdate=  new LRDto();
			this.lrDtoForUpdate.lrNumber = this.selectedRowLRNumber;
			this.lrDtoForUpdate.commodityName = this.selectedCommodityName
			this.lrDtoForUpdate.commodityCode =  this.selectedRowCommodity;
			this.lrDtoForUpdate.companyId = this.userDataDtoReturnSession.companyId;
			this.lrDtoForUpdate.mode = "UpdateConsignee";
			console.log(this.lrDtoForUpdate);
			this.updateCommodityDetailsInfo();
       	}
       	updateCommodityDetailsInfo= () => {
           	        this.showSpinnerForActionForPopup = true;
           	        this.memoService.updateCommodityDtlsService(this.lrDtoForUpdate).subscribe(
           	             (response) => {
           	            	 this.showSpinnerForActionForPopup=false;
							 console.log(response);
							this.lrDtoForUpdateRet= '';
							this.lrDtoForUpdateRet=response;
           	                 if ( this.lrDtoForUpdateRet.status == 'success' ) {
           	                	 	this.selectedCommodityName='';
									this.selectedRowCommodity='';
									this.selectedRowLRNumber='';
									this.modelCommodityMainUnldg='';
									$("#"+this.pageId+"CommodityMainUnldgId").val('');
           	                	this.lrDtoForUpdate=  new LRDto();
           	                	this.modalRefferenceCommodityPopUp.close();
           	                     swal({
           	                         title: "Success",
           	                         text: "Commodity details updated successfully",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
           	                     this.gridReconifgureOnReloadBtn();
           	                }else{
							swal({
           	                         title: "Failed to update",
           	                         text: "Problem occurred while updating the Commodity details",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
							}
           	             this.changeDetectorRef.detectChanges();
           	             }), (error) => {
                                this.showSpinnerForActionForPopup = false;
                               swal("Error", "Server problem occurred while the Commodity details", "info");
                           }, () => console.log('done');
                   };
				   
	openPopupFroYes(pendingCommodityData){
		this.selectedRowLrForYesBtn=pendingCommodityData.lrNumber;
		if(this.selectedRowLrForYesBtn !=null && this.selectedRowLrForYesBtn !=''){
		 swal( {
                    title: "Warning",
                    text: "Sure You Want to Confirm ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                } ).then(( lessType ) => {
                    if ( lessType ) {
                    	this.updateYesBtnMethod();
                    } else {                       

                    }
                } );
		}
	}
	 //update on Yes btn click
	updateYesBtnMethod(){
			if( this.selectedRowLrForYesBtn =='' ||  this.selectedRowLrForYesBtn ==null){
				  swal({
						 title: "warning",
						 text: "Please Select the Row",
						 icon: "warning",
						 closeOnClickOutside: false,
						 closeOnEsc: false,
					 });
					 return false;
				}
			this.lrDtoForUpdateForYes=  new LRDto();
			this.lrDtoForUpdateForYes.lrNumber = this.selectedRowLrForYesBtn;
			this.lrDtoForUpdateForYes.companyId = this.userDataDtoReturnSession.companyId;		
			console.log(this.lrDtoForUpdateForYes);
			this.updateYesBtnMethodInfo();
			 this.showSpinnerForAction = true;
       	}
       	updateYesBtnMethodInfo= () => {
           	       
           	        this.memoService.updateCommodityDtlsService(this.lrDtoForUpdateForYes).subscribe(
           	             (response) => {
           	            	 this.showSpinnerForAction = false;
							 console.log(response);
							this.lrDtoForUpdateRetForYesBtn= '';
							this.lrDtoForUpdateRetForYesBtn=response;
							this.selectedRowLrForYesBtn='';
           	                this.lrDtoForUpdateForYes=  new LRDto();
           	                 if ( this.lrDtoForUpdateRetForYesBtn.status == 'success' ) {
           	                     swal({
           	                         title: "Success",
           	                         text: "Commodity details updated successfully",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
           	                     this.gridReconifgureOnReloadBtn();
           	                }else{
							swal({
           	                         title: "Failed to update",
           	                         text: "Problem occurred while updating the Commodity details",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
							}
           	             this.changeDetectorRef.detectChanges();
           	             }), (error) => {
                                this.showSpinnerForActionForPopup = false;
                               swal("Error", "Server problem occurred while the Commodity details", "info");
                           }, () => console.log('done');
                   };
                   closeBtnPopup(){
               		this.selectedCommodityName='';
               		this.selectedRowCommodity='';
               		this.selectedRowLRNumber='';
               		this.lrDtoForUpdate=  new LRDto();
               		this.modalRefferenceCommodityPopUp.close();
               	}
               	
	
}

