//rwd_chg_1 : Added two feilds ftArrivalDate and ftArrivalTime (Asrar Jr / 14-06-2024)
//Rwd_chg_2 : Checked arrival time is null from api if null means scan code flow opened (IMRAN - 06/07/2024)
//Rwd_chg_3 : The old popup for sunday wise reward has been removed and moved to new scan code popup (IMRAN - 06/07/2024)
//rwd_chg_4 : Call method on press enter button and fixed issue when press a key (Asrar Jr / 11-07-2024)
//rwd_chg_5 : show message if isFtAutoReached column data is true (Asrar Jr / 12-07-2024)
//rwd_chg_6 : Removed rights for rewarding button as per discussion (Imran / 29-08-2024)
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import *  as moment from 'moment';

@Component({
    selector: 'app-rewarding',
    templateUrl: './rewarding.component.html',
    styleUrls: ['./rewarding.component.css']
})
export class RewardingComponent implements OnInit {

    model: NgbDateStruct;
    pageId = "rwdc";
    public selectForm: FormGroup;

    departureTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' },
        { id: 7, label: '01:30' },
        { id: 8, label: '01:45' },
        { id: 9, label: '02:00' },
        { id: 10, label: '02:15' },
        { id: 11, label: '02:30' },
        { id: 12, label: '02:45' },
        { id: 13, label: '03:00' },
        { id: 14, label: '03:15' },
        { id: 15, label: '03:30' },
        { id: 16, label: '03:45' },
        { id: 17, label: '04:00' },
        { id: 18, label: '04:15' },
        { id: 19, label: '04:30' },
        { id: 20, label: '04:45' },
        { id: 21, label: '05:00' },
        { id: 22, label: '05:15' },
        { id: 23, label: '05:30' },
        { id: 24, label: '05:45' },
        { id: 25, label: '06:00' },
        { id: 26, label: '06:15' },
        { id: 27, label: '06:30' },
        { id: 28, label: '06:45' },
        { id: 29, label: '07:00' },
        { id: 30, label: '07:15' },
        { id: 31, label: '07:30' },
        { id: 32, label: '07:45' },
        { id: 33, label: '08:00' },
        { id: 34, label: '08:15' },
        { id: 35, label: '08:30' },
        { id: 36, label: '08:45' },
        { id: 37, label: '09:00' },
        { id: 38, label: '09:15' },
        { id: 39, label: '09:30' },
        { id: 40, label: '09:45' },
        { id: 41, label: '10:00' },
        { id: 42, label: '10:15' },
        { id: 43, label: '10:30' },
        { id: 44, label: '10:45' },
        { id: 45, label: '11:00' },
        { id: 46, label: '11:15' },
        { id: 47, label: '11:30' },
        { id: 48, label: '11:45' },
        { id: 49, label: '12:00' },
        { id: 50, label: '12:15' },
        { id: 51, label: '12:30' },
        { id: 52, label: '12:45' },
        { id: 53, label: '13:00' },
        { id: 54, label: '13:15' },
        { id: 55, label: '13:30' },
        { id: 56, label: '13:45' },
        { id: 57, label: '14:00' },
        { id: 58, label: '14:15' },
        { id: 59, label: '14:30' },
        { id: 60, label: '14:45' },
        { id: 61, label: '15:00' },
        { id: 62, label: '15:15' },
        { id: 63, label: '15:30' },
        { id: 64, label: '15:45' },
        { id: 65, label: '16:00' },
        { id: 66, label: '16:15' },
        { id: 67, label: '16:30' },
        { id: 68, label: '16:45' },
        { id: 69, label: '17:00' },
        { id: 70, label: '17:15' },
        { id: 71, label: '17:30' },
        { id: 72, label: '17:45' },
        { id: 73, label: '18:00' },
        { id: 74, label: '18:15' },
        { id: 75, label: '18:30' },
        { id: 76, label: '18:45' },
        { id: 77, label: '19:00' },
        { id: 78, label: '19:15' },
        { id: 79, label: '19:30' },
        { id: 80, label: '19:45' },
        { id: 81, label: '20:00' },
        { id: 82, label: '20:15' },
        { id: 83, label: '20:30' },
        { id: 84, label: '20:45' },
        { id: 85, label: '21:00' },
        { id: 86, label: '21:15' },
        { id: 87, label: '21:30' },
        { id: 88, label: '21:45' },
        { id: 89, label: '22:00' },
        { id: 90, label: '22:15' },
        { id: 91, label: '22:30' },
        { id: 92, label: '22:45' },
        { id: 93, label: '23:00' },
        { id: 94, label: '23:15' },
        { id: 95, label: '23:30' },
        { id: 96, label: '23:45' }
    ];
    scheduledTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' },
        { id: 7, label: '01:30' },
        { id: 8, label: '01:45' },
        { id: 9, label: '02:00' },
        { id: 10, label: '02:15' },
        { id: 11, label: '02:30' },
        { id: 12, label: '02:45' },
        { id: 13, label: '03:00' },
        { id: 14, label: '03:15' },
        { id: 15, label: '03:30' },
        { id: 16, label: '03:45' },
        { id: 17, label: '04:00' },
        { id: 18, label: '04:15' },
        { id: 19, label: '04:30' },
        { id: 20, label: '04:45' },
        { id: 21, label: '05:00' },
        { id: 22, label: '05:15' },
        { id: 23, label: '05:30' },
        { id: 24, label: '05:45' },
        { id: 25, label: '06:00' },
        { id: 26, label: '06:15' },
        { id: 27, label: '06:30' },
        { id: 28, label: '06:45' },
        { id: 29, label: '07:00' },
        { id: 30, label: '07:15' },
        { id: 31, label: '07:30' },
        { id: 32, label: '07:45' },
        { id: 33, label: '08:00' },
        { id: 34, label: '08:15' },
        { id: 35, label: '08:30' },
        { id: 36, label: '08:45' },
        { id: 37, label: '09:00' },
        { id: 38, label: '09:15' },
        { id: 39, label: '09:30' },
        { id: 40, label: '09:45' },
        { id: 41, label: '10:00' },
        { id: 42, label: '10:15' },
        { id: 43, label: '10:30' },
        { id: 44, label: '10:45' },
        { id: 45, label: '11:00' },
        { id: 46, label: '11:15' },
        { id: 47, label: '11:30' },
        { id: 48, label: '11:45' },
        { id: 49, label: '12:00' },
        { id: 50, label: '12:15' },
        { id: 51, label: '12:30' },
        { id: 52, label: '12:45' },
        { id: 53, label: '13:00' },
        { id: 54, label: '13:15' },
        { id: 55, label: '13:30' },
        { id: 56, label: '13:45' },
        { id: 57, label: '14:00' },
        { id: 58, label: '14:15' },
        { id: 59, label: '14:30' },
        { id: 60, label: '14:45' },
        { id: 61, label: '15:00' },
        { id: 62, label: '15:15' },
        { id: 63, label: '15:30' },
        { id: 64, label: '15:45' },
        { id: 65, label: '16:00' },
        { id: 66, label: '16:15' },
        { id: 67, label: '16:30' },
        { id: 68, label: '16:45' },
        { id: 69, label: '17:00' },
        { id: 70, label: '17:15' },
        { id: 71, label: '17:30' },
        { id: 72, label: '17:45' },
        { id: 73, label: '18:00' },
        { id: 74, label: '18:15' },
        { id: 75, label: '18:30' },
        { id: 76, label: '18:45' },
        { id: 77, label: '19:00' },
        { id: 78, label: '19:15' },
        { id: 79, label: '19:30' },
        { id: 80, label: '19:45' },
        { id: 81, label: '20:00' },
        { id: 82, label: '20:15' },
        { id: 83, label: '20:30' },
        { id: 84, label: '20:45' },
        { id: 85, label: '21:00' },
        { id: 86, label: '21:15' },
        { id: 87, label: '21:30' },
        { id: 88, label: '21:45' },
        { id: 89, label: '22:00' },
        { id: 90, label: '22:15' },
        { id: 91, label: '22:30' },
        { id: 92, label: '22:45' },
        { id: 93, label: '23:00' },
        { id: 94, label: '23:15' },
        { id: 95, label: '23:30' },
        { id: 96, label: '23:45' }
    ];
    control = new FormControl();

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    isManualScanCode: boolean = false;
    viewScanCodeBtn: boolean = false;
    viewConfirmPaymentBtn: boolean = false;
    viewPrintBtn: boolean = false;
    viewAlreadyReward: boolean = false;
    viewScanCodeField: boolean = false;

    validateScanCodeHireslipNo: any = null;
    validateHireslipNo: any = null;

    invoiceDatesModal: any = null;
    departureDatesModal: any = null;
    scheduledDatesModal: any = null;
    arrivalDatesModal: any = null;

    isReadonlyHireslipNo = false;
    isReadonlyInvoiceDate = false;
    isReadonlySource = false;
    isReadonlyDestination = false;
    isReadonlyHireAmount = false;
    isReadonlyVehicleNumber = false;
    isReadonlySupplierName = false;
    isReadonlyDriverName = false;
    isReadonlyMobileNumber = false;
    isReadonlyLicenseNumber = false;
    isReadonlyDepartureDate = false;
    isReadonlyDepartureTime = false;
    isReadonlyScheduledDate = false;
    isReadonlyScheduledTime = false;
    isReadonlyArrivalDate = false;
    isReadonlyArrivalTime = false;
    isReadonlyPreferredDuration = false;
    isReadonlyBufferTime = false;
    isReadonlyTimeTakenToReached = false;
    isReadonlyRewardAmount = false;

    enteredHireSlipNumber: any = null;
    enteredDepartureDate: any = null;
    enteredDepartureTime: any = null;
    enteredArrivalDate: any = null;
    enteredArrivalTime: any = null;

    enteredForHireslipNumberPrint: any = null;
    enteredForInvoiceDatePrint: any = null;
    enteredForSourcePrint: any = null;
    enteredForDestinationPrint: any = null;
    enteredForHireAmountPrint: any = null;
    enteredForVehicleNumberPrint: any = null;
    enteredForSupplierNamePrint: any = null;
    enteredForDriverNamePrint: any = null;
    enteredForMobileNumberPrint: any = null;
    enteredForLicensePrint: any = null;
    enteredForDepartureDatePrint: any = null;
    enteredForDepartureTimePrint: any = null;
    enteredForScheduleDatePrint: any = null;
    enteredForScheduleTimePrint: any = null;
    enteredForArrivalDatePrint: any = null;
    enteredForArrivalTimePrint: any = null;
    enteredForPrefferedDurationPrint: any = null;
    enteredForBufferTimePrint: any = null;
    enteredForTimeTakenToReachedPrint: any = null;
    enteredForRewardAmountPrint: any = null;
    currentDate: any = null;

    hireSlipDto: HireSlipDto = new HireSlipDto();
    hireSlipDtoForTimeDiff: HireSlipDto = new HireSlipDto();
    hireSlipDtoForPrintAndConfirmPayment: HireSlipDto = new HireSlipDto();

    hireSlipDtoForPrint: HireSlipDto = new HireSlipDto();
    hireSlipDtoForConfirmPayment: HireSlipDto = new HireSlipDto();

    hireSlipDtoReturn: any = null;
    viewRewardingVoucher: boolean = false;

    modalRefferenceRewardingPopUp: NgbModalRef;
    modalRefferenceRewardingClosePopUp: string;
    isReadonlyFtArrivalDate = false;
    isReadonlyFtArrivalTime = false;
    enteredFtArrivalDate: any = null;
    enteredFtArrivalTime: any = null;
    isReadonlyTimeTakenToReachedFt = false;
    @ViewChild('rewardingPopUpTemplate') private rewardingPopUpTemplate;

    checkboxWithExcludeSundayYesNo: boolean = false;

    //rightsForRewardingPrintShow = "Rights To Show Only Rewarding Print For Selected Role";
    rightsForDeliveryManager = "Rights To Show Delivery Manager";
    rightsForBillingManager = "Rights To Show Billing Manager";
    rightsForTransshipmentManager = "Rights To Show Transshipment Manager";
    rightsForSuperAdministrator = "Rights To Show Super Administrator";
    showHireColumns: Boolean = false;
    @ViewChild('scanCodeForRewardPopUpTemplate') private scanCodeForRewardPopUpTemplate;
    modalRefferenceScanCodePopUp: NgbModalRef;
    modalRefferenceScanCodeClosePopUp: string;
    showSundayWiseRewardFields: boolean = false;
    showMessage: boolean = false;

    constructor(private router: Router, private modalService: NgbModal,
        private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.viewScanCodeField = true;
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Barcode Scanning Manual") {
                        this.isManualScanCode = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Barcode Scan Button") {
                        this.viewScanCodeBtn = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Rewarding Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }
        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    ngOnInit() {
        //this.isManualScanCode = true;
    }

    getScanDetailsClickBtn(scanCodeForRewardPopUpTemplate) {
        this.scanningProcess(scanCodeForRewardPopUpTemplate);
    }
    getScanCodeFieldsDetails(event, scanCodeForRewardPopUpTemplate) {
        if (this.isManualScanCode == false) {
            window.setTimeout(function () {
                $("#" + this.pageId + "scanCode").val('');
                $("#" + this.pageId + '#scanCode').focus();
            }, 1000);
        }
        console.log(event.charCode + "" + this.isManualScanCode);
        console.log($("#" + this.pageId + "scanCode").val());
        if ((event.charCode == 10) &&
            (this.isManualScanCode == false)) {
            swal("Alert", "Please Scan Code Using Scanner", "warning");
            return false;
        } else if (event.charCode == 13) {
            this.scanningProcess(scanCodeForRewardPopUpTemplate);
        }
    }
    scanningProcess(scanCodeForRewardPopUpTemplate) {
        this.hireSlipDto = new HireSlipDto();
        this.validateScanCodeHireslipNo = $("#" + this.pageId + "scanCode").val();
        this.validateHireslipNo = $("#" + this.pageId + "hireslipNumber").val();
        this.hireSlipDto.hireslipnumber = this.validateHireslipNo;
        this.hireSlipDto.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDto.barcodeValue = this.validateScanCodeHireslipNo;
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.setScanningProcessDetails(scanCodeForRewardPopUpTemplate);
    }
    setScanningProcessDetails(scanCodeForRewardPopUpTemplate) {
        this.showSpinnerForAction = true;
        this.hireslipService.getHireSlipDetailsForRewarding(this.hireSlipDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.hireSlipDtoReturn = null;
                this.hireSlipDtoReturn = response;
                //console.log(response);
                //console.log(this.hireSlipDtoReturn);
                if (response.size == 0 || response.status == "Pending") {
                    swal("Not Allowed", "Please Scan Again", "warning");
                    this.clearAllFields();
                    this.commonClearFieldFalse();

                } else {
                    if (response.status == "Scheduled") {
                        swal("Result Not Found", "" + $("#" + this.pageId + "hireslipNumber").val() + " is Scheduled to Transit", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();

                    } else if ((!(response.branch.toLowerCase() == response.tostation.toLowerCase())) &&
                        (!(this.userDataDtoReturnSession.office.toLowerCase() == response.tostation.toLowerCase()) &&
                            this.isManualScanCode == false)) {
                        swal("Not Allowed", "You Are Not Allowed to see the " + response.tostation + " Informations", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();

                    } else if (response.firstPntDelv != null &&
                        (!(response.tostation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()))) {
                        swal("Not Allowed", "Reward Allowed Only at " + response.firstPntDelv + " Because" + response.firstPntDelv + " is 1st Point Delivery", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();

                    } else if (response.deliveryPoints == 2 &&
                        ((response.tostation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()))) {
                        this.clearAllFields();
                        this.setValues(response);
                        this.showFieldForDeliveryPointsTwo(response, scanCodeForRewardPopUpTemplate);
                        console.log("DeliveryPt");
                        this.modalRefferenceScanCodePopUp.close();
                    } else {
                        this.clearAllFields();
                        this.setValues(response);
                        this.showFieldForDeliveryPointsTwo(response, scanCodeForRewardPopUpTemplate);
                        console.log("No DeliveryPt");
                        this.modalRefferenceScanCodePopUp.close();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  HireSlipDetailsForRewarding", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    commonClearFieldFalse() {
        this.viewAlreadyReward = false;
        this.viewConfirmPaymentBtn = false;
        this.viewPrintBtn = false;
        $("#" + this.pageId + "scanCode").val('');
        $("#" + this.pageId + "scanCode").focus();
    }
    showFieldOfAlreadyRewardAndPrint() {
        this.viewAlreadyReward = true;
        this.viewConfirmPaymentBtn = false;
        this.viewPrintBtn = true;
        this.viewScanCodeField = false;
        this.viewScanCodeBtn = false;
    }
    showFieldOfAlreadyRewardAndConfirmPayBtn() {
        this.viewAlreadyReward = true;
        this.viewConfirmPaymentBtn = true;
        this.viewPrintBtn = false;
        this.viewScanCodeField = false;
        this.viewScanCodeBtn = false;
    }
    showFieldForDeliveryPointsTwo(response, mode) {
        if (response.isRewarded != null && response.isRewarded &&
            response.isLryBalancePaid != null &&
            (!(response.isLryBalancePaid))) {
            this.showFieldOfAlreadyRewardAndPrint();
            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('Already Rewarded');
        } else if (response.isScanned != null && response.isScanned &&
            (response.isRewarded != null && (!response.isRewarded))) {
            this.showFieldOfAlreadyRewardAndConfirmPayBtn();
            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('Already Rewarded');
            this.getReachedDuration();
        } else if (response.isLryBalancePaid != null &&
            response.isLryBalancePaid &&
            response.isRewarded != null &&
            (!(response.isRewarded))) {
            this.showFieldOfAlreadyRewardAndConfirmPayBtn();
            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('HireSlip Balance Amount Already Paid');
            this.getReachedDuration();
        } else if (response.isRewarded != null &&
            response.isRewarded &&
            response.isLryBalancePaid != null &&
            response.isLryBalancePaid) {
            this.showFieldOfAlreadyRewardAndPrint();
            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('HireSlip Balance And Reward Amount Already Paid');
        } else {
            if ((mode != null && mode == "hireslipNoWise") && (moment(new Date()).format("dddd") == "Monday") &&
                (response.sundayWorking != null) &&
                (response.sundayWorking == "No")) {
                //popup.show();
                //Rwd_chg_3
                // this.rewardingPopupOpenLink(rewardingPopUpTemplate);
                this.showSundayWiseRewardFields = true;
                this.getReachedDuration();
            } else {
                this.getReachedDuration();
            }
            //radNo.setValue(true);
            $("#" + this.pageId + "checkboxWithExcludeSundayNo").prop('checked', true);
            this.viewAlreadyReward = false;
            this.viewConfirmPaymentBtn = true;
            this.viewPrintBtn = false;
            this.viewScanCodeField = false;
            this.viewScanCodeBtn = false;
        }
        /*if (!(this.rightsForDeliveryManager == "Rights To Show Delivery Manager") &&
            (!(this.rightsForBillingManager == "Rights To Show Billing Manager")) &&
            (!(this.rightsForTransshipmentManager == "Rights To Show Transshipment Manager") &&
                (!(this.rightsForSuperAdministrator == "Rights To Show Super Administrator")))) {
        */
        //rightsForRewardingPrintShow = "Rights To Show Only Rewarding Print For Selected Role";
        //rwd_chg_6
        // if (!(this.userDataDtoReturnSession.role == "Delivery Manager") &&
        //     (!(this.userDataDtoReturnSession.role == "Billing Manager")) &&
        //     (!(this.userDataDtoReturnSession.role == "Transshipment Manager") &&
        //         (!(this.userDataDtoReturnSession.role == "Super Administrator")))) {
        //     this.viewConfirmPaymentBtn = false;
        //     this.viewPrintBtn = true;
        // }
    }
    clearAllFields() {
        $("#" + this.pageId + "hireslipNumber").val('');
        $("#" + this.pageId + "invoiceDates").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "hireAmount").val('');
        $("#" + this.pageId + "vehicleNumber").val('');
        $("#" + this.pageId + "supplierName").val('');
        $("#" + this.pageId + "driverName").val('');
        $("#" + this.pageId + "mobileNumber").val('');
        $("#" + this.pageId + "licenseNumber").val('');
        $("#" + this.pageId + "departureDates").val('');
        $("#" + this.pageId + "departureTime").val('');
        $("#" + this.pageId + "scheduledDates").val('');
        $("#" + this.pageId + "scheduledTime").val('');
        $("#" + this.pageId + "arrivalDates").val('');
        $("#" + this.pageId + "arrivalTime").val('');
        $("#" + this.pageId + "preferredDuration").val('');
        $("#" + this.pageId + "bufferTime").val('');
        $("#" + this.pageId + "timeTakenToReached").val('');
        $("#" + this.pageId + "rewardAmount").val('');
        $("#" + this.pageId + "ftArrivalDates").val('');
        $("#" + this.pageId + "ftArrivalTime").val('');
        $("#" + this.pageId + "timeTakenToReachedFt").val('');

        this.invoiceDatesModal = null;
        this.departureDatesModal = null;
        this.scheduledDatesModal = null;
        this.arrivalDatesModal = null;

        //this.validateScanCodeHireslipNo = null;
        this.validateHireslipNo = null;
        this.viewScanCodeField = true;

        this.isReadonlyHireslipNo = false;
        this.isReadonlyInvoiceDate = false;
        this.isReadonlySource = false;
        this.isReadonlyDestination = false;
        this.isReadonlyHireAmount = false;
        this.isReadonlyVehicleNumber = false;
        this.isReadonlySupplierName = false;
        this.isReadonlyDriverName = false;
        this.isReadonlyMobileNumber = false;
        this.isReadonlyLicenseNumber = false;
        this.isReadonlyDepartureDate = false;
        this.isReadonlyDepartureTime = false;
        this.isReadonlyScheduledDate = false;
        this.isReadonlyScheduledTime = false;
        this.isReadonlyArrivalDate = false;
        this.isReadonlyArrivalTime = false;
        this.isReadonlyPreferredDuration = false;
        this.isReadonlyBufferTime = false;
        this.isReadonlyTimeTakenToReached = false;
        this.isReadonlyRewardAmount = false;
        this.isReadonlyFtArrivalDate = false;
        this.isReadonlyFtArrivalTime = false;
        this.isReadonlyTimeTakenToReachedFt = false;
        this.showSundayWiseRewardFields = false;
        //for ft clear object becayse fields removed by imran on 06/07/2024
        this.enteredFtArrivalDate = null;
        this.enteredFtArrivalTime = null;
        this.showMessage = false;
    }

    setValues(hireslipValueDtoReturn) {
        $("#" + this.pageId + "hireslipNumber").val(hireslipValueDtoReturn.hireslipnumber);
        $("#" + this.pageId + "invoiceDates").val(hireslipValueDtoReturn.invoicedate == null ? null : moment(hireslipValueDtoReturn.invoicedate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "source").val(hireslipValueDtoReturn.fromstation);
        $("#" + this.pageId + "destination").val(hireslipValueDtoReturn.tostation);
        $("#" + this.pageId + "hireAmount").val(hireslipValueDtoReturn.totalhire);
        $("#" + this.pageId + "vehicleNumber").val(hireslipValueDtoReturn.vehicleNumber);
        // $("#" + this.pageId + "supplierName").val(hireslipValueDtoReturn.vehiCompName);
        $("#" + this.pageId + "supplierName").val(hireslipValueDtoReturn.suppliername);
        $("#" + this.pageId + "driverName").val(hireslipValueDtoReturn.drivername);
        $("#" + this.pageId + "mobileNumber").val(hireslipValueDtoReturn.driverMobileNumber);
        $("#" + this.pageId + "licenseNumber").val(hireslipValueDtoReturn.driverlicencenumber);
        $("#" + this.pageId + "departureDates").val(hireslipValueDtoReturn.departuredate == null ? null : moment(hireslipValueDtoReturn.departuredate).format("YYYY-MM-DD"));
        //$("#"+this.pageId+"departureTime").val(hireslipValueDtoReturn.departureTime == null ? null : moment(hireslipValueDtoReturn.departureTime, "HH:mm").format("hh:mm"));
        $("#" + this.pageId + "departureTime").val(hireslipValueDtoReturn.departureTime);
        $("#" + this.pageId + "scheduledDates").val(hireslipValueDtoReturn.scheduleddate == null ? null : moment(hireslipValueDtoReturn.scheduleddate).format("YYYY-MM-DD"));
        // $("#"+this.pageId+"scheduledTime").val(hireslipValueDtoReturn.scheduledTime == null ? null : moment(hireslipValueDtoReturn.scheduledTime, "HH:mm").format("hh:mm"));
        $("#" + this.pageId + "scheduledTime").val(hireslipValueDtoReturn.scheduledTime);
        $("#" + this.pageId + "arrivalDates").val(hireslipValueDtoReturn.arrivaldate == null ? null : moment(hireslipValueDtoReturn.arrivaldate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "arrivalTime").val(hireslipValueDtoReturn.arrivalTime);
        //$("#"+this.pageId+"arrivalTime").val(hireslipValueDtoReturn.arrivalTime == null ? null : moment(hireslipValueDtoReturn.arrivalTime, "HH:mm").format("hh:mm"));
        $("#" + this.pageId + "preferredDuration").val(hireslipValueDtoReturn.durationInHours);
        if (hireslipValueDtoReturn.buffTimeInHr != null &&
            hireslipValueDtoReturn.buffTimeInMin != null) {
            $("#" + this.pageId + "bufferTime").val(hireslipValueDtoReturn.buffTimeInHr + ":" + hireslipValueDtoReturn.buffTimeInMin);
        }

        if (hireslipValueDtoReturn.isRewarded != null && hireslipValueDtoReturn.isRewarded) {
            $("#" + this.pageId + "preferredDuration").val(hireslipValueDtoReturn.preferredDuration);
            $("#" + this.pageId + "bufferTime").val(hireslipValueDtoReturn.bufferTime);
        }
        if (hireslipValueDtoReturn.driverrewardingAmt != null) {
            $("#" + this.pageId + "timeTakenToReached").val(hireslipValueDtoReturn.column3);
            $("#" + this.pageId + "rewardAmount").val(hireslipValueDtoReturn.driverrewardingAmt);
            $("#" + this.pageId + "timeTakenToReachedFt").val(hireslipValueDtoReturn.ftReachedDuration);
        }

        //rwd_chg_1
        //Commented because this fields removed from ui and now paasing data in ftdate and time opbject by imran on 06/07/2024 
        this.enteredFtArrivalDate = null;
        this.enteredFtArrivalTime = null;
        $("#" + this.pageId + "ftArrivalDates").val(hireslipValueDtoReturn.ftArrivalDate == null ? null : moment(hireslipValueDtoReturn.ftArrivalDate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "ftArrivalTime").val(hireslipValueDtoReturn.ftArrivalTime);
        this.enteredFtArrivalDate = (hireslipValueDtoReturn.ftArrivalDate == null ? null : moment(hireslipValueDtoReturn.ftArrivalDate).format("YYYY-MM-DD"));
        this.enteredFtArrivalTime = hireslipValueDtoReturn.ftArrivalTime;
        console.log("AAAAA");

        // rwd_chg_5
        if (hireslipValueDtoReturn.trackingFTStatus != null && hireslipValueDtoReturn.trackingFTStatus) {
            this.showMessage = true;
        } else {
            this.showMessage = false;
        }

        console.log(hireslipValueDtoReturn);

        this.isReadonlyHireslipNo = true;
        this.isReadonlyInvoiceDate = true;
        this.isReadonlySource = true;
        this.isReadonlyDestination = true;
        this.isReadonlyHireAmount = true;
        this.isReadonlyVehicleNumber = true;
        this.isReadonlySupplierName = true;
        this.isReadonlyDriverName = true;
        this.isReadonlyMobileNumber = true;
        this.isReadonlyLicenseNumber = true;
        this.isReadonlyDepartureDate = true;
        this.isReadonlyDepartureTime = true;
        this.isReadonlyScheduledDate = true;
        this.isReadonlyScheduledTime = true;
        this.isReadonlyArrivalDate = true;
        this.isReadonlyArrivalTime = true;
        this.isReadonlyPreferredDuration = true;
        this.isReadonlyBufferTime = true;
        this.isReadonlyTimeTakenToReached = true;
        this.isReadonlyRewardAmount = true;
        this.isReadonlyFtArrivalDate = true;
        this.isReadonlyFtArrivalTime = true;
        this.isReadonlyTimeTakenToReachedFt = true;
    }

    getReachedDuration() {
        //console.log("Check Time");
        this.hireSlipDtoForTimeDiff = new HireSlipDto();
        this.enteredDepartureDate = $("#" + this.pageId + "departureDates").val();
        this.enteredDepartureTime = $("#" + this.pageId + "departureTime").val();
        this.enteredArrivalDate = $("#" + this.pageId + "arrivalDates").val();
        this.enteredArrivalTime = $("#" + this.pageId + "arrivalTime").val();
        this.enteredHireSlipNumber = $("#" + this.pageId + "hireslipNumber").val();

        //rwd_chg_1
        ////Commented because this fields removed from ui and now paasing data in ftdate and time opbject by imran on 06/07/2024 
        // this.enteredFtArrivalDate = $("#" + this.pageId + "ftArrivalDates").val();
        // this.enteredFtArrivalTime = $("#" + this.pageId + "ftArrivalTime").val();

        this.hireSlipDtoForTimeDiff.departuredate = this.enteredDepartureDate;
        this.hireSlipDtoForTimeDiff.departureTime = this.enteredDepartureTime;
        this.hireSlipDtoForTimeDiff.arrivaldate = this.enteredArrivalDate;
        this.hireSlipDtoForTimeDiff.arrivalTime = this.enteredArrivalTime;
        this.hireSlipDtoForTimeDiff.hireslipnumber = this.enteredHireSlipNumber;

        //rwd_chg_1
        this.hireSlipDtoForTimeDiff.ftArrivalDate = this.enteredFtArrivalDate;
        this.hireSlipDtoForTimeDiff.ftArrivalTime = this.enteredFtArrivalTime;

        console.log($("#" + this.pageId + "checkboxWithExcludeSundayYes").is(':checked'));
        if ($("#" + this.pageId + "checkboxWithExcludeSundayYes").is(':checked') == true) {
            this.checkboxWithExcludeSundayYesNo = true;
        } else {
            this.checkboxWithExcludeSundayYesNo = false;
        }
        this.hireSlipDtoForTimeDiff.excludeSunday = this.checkboxWithExcludeSundayYesNo;
        this.hireSlipDtoForTimeDiff.bufferedTimeInHours = this.hireSlipDtoReturn.bufferedTimeInHours;
        this.hireSlipDtoForTimeDiff.column1 = this.hireSlipDtoReturn.column1;
        this.hireSlipDtoForTimeDiff.companyid = this.userDataDtoReturnSession.companyId;
        console.log(this.hireSlipDtoForTimeDiff);
        this.showSpinnerForAction = true;
        //console.log("Check Time 1");
        this.hireslipService.getTimeDifference(this.hireSlipDtoForTimeDiff).subscribe(
            (response) => {
                console.log("Time");
                console.log(response);
                $("#" + this.pageId + "timeTakenToReached").val(response.reachedDuration);
                $("#" + this.pageId + "rewardAmount").val(response.amount);

                //rwd_chg_1
                console.log(response.ftReachedDuration);
                $("#" + this.pageId + "timeTakenToReachedFt").val(response.ftReachedDuration);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  TimeDifference", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    confirmPaymentClickBtn() {
        swal({
            title: "Confirm Payment",
            text: "Sure u  Want to Confirm the Payment  Details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesConfirmPaymentBtn) => {
            if (yesConfirmPaymentBtn) {
                this.setConfirmPaymentDetails();
            }
        });

    }

    setConfirmPaymentDetails() {
        this.hireSlipDtoForConfirmPayment = new HireSlipDto();
        this.hireSlipDtoForConfirmPayment = this.getFieldValues("confirm");
        console.log(this.hireSlipDtoForConfirmPayment);
        this.hireslipService.updateDriverRewarding(this.hireSlipDtoForConfirmPayment).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    swal({
                        title: "Print Rewarding Voucher",
                        text: "Do U Want to Print the Rewarding Voucher for",
                        // text: "Sure you want to Print ?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            localStorage.clear();
                            localStorage.setItem('rewardingVoucherPrint', JSON.stringify(this.hireSlipDtoForConfirmPayment));
                            this.viewRewardingVoucher = true;
                            this.changeDetectorRef.detectChanges();
                            window.addEventListener('afterprint', (onclick) => {
                                if (this.viewRewardingVoucher) {
                                    this.viewRewardingVoucher = false;
                                    this.hireSlipDtoForConfirmPayment = new HireSlipDto();
                                    localStorage.clear();
                                    this.viewPrintBtn = false;
                                    this.clearAllFields();
                                    this.commonClearFieldFalse();
                                    this.changeDetectorRef.detectChanges();
                                }
                            });
                        } else {
                            this.clearAllFields();
                            this.commonClearFieldFalse();
                            this.changeDetectorRef.detectChanges();
                        }
                    });
                } else if (response.status != null && response.status == "Failed") {
                    swal("Warning", "Driver Rewarded Details Not Saved", "warning");
                } else {
                    swal("Warning", "Driver Rewarded Details Not Saved", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Server Error", "Problem occur while getting  updateDriverRewarding", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    printClickBtn() {
        if (this.hireSlipDtoReturn.isRewarded != null && !this.hireSlipDtoReturn.isRewarded) {
            swal("Please Reward", "" + this.hireSlipDtoReturn.hireslipnumber + " Is Not Yet Rewarded", "warning");
            return false;
        } else {
            this.hireSlipDtoForPrint = new HireSlipDto();
            this.hireSlipDtoForPrint = this.getFieldValues("print");

            localStorage.clear();
            localStorage.setItem('rewardingVoucherPrint', JSON.stringify(this.hireSlipDtoForPrint));
            this.viewRewardingVoucher = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewRewardingVoucher) {
                    this.viewRewardingVoucher = false;
                    this.hireSlipDtoForPrint = new HireSlipDto();
                    localStorage.clear();
                    this.viewPrintBtn = false;
                    this.clearAllFields();
                    this.commonClearFieldFalse();
                }
            });
        }
    }

    getFieldValues(mode) {
        this.hireSlipDtoForPrintAndConfirmPayment = new HireSlipDto();

        this.enteredForHireslipNumberPrint = $("#" + this.pageId + "hireslipNumber").val();
        this.enteredForInvoiceDatePrint = $("#" + this.pageId + "invoiceDates").val();
        this.enteredForSourcePrint = $("#" + this.pageId + "source").val();
        this.enteredForDestinationPrint = $("#" + this.pageId + "destination").val();
        this.enteredForHireAmountPrint = $("#" + this.pageId + "hireAmount").val();
        this.enteredForVehicleNumberPrint = $("#" + this.pageId + "vehicleNumber").val();
        this.enteredForSupplierNamePrint = $("#" + this.pageId + "supplierName").val();
        this.enteredForDriverNamePrint = $("#" + this.pageId + "driverName").val();
        this.enteredForMobileNumberPrint = $("#" + this.pageId + "mobileNumber").val();
        this.enteredForLicensePrint = $("#" + this.pageId + "licenseNumber").val();
        this.enteredForDepartureDatePrint = $("#" + this.pageId + "departureDates").val();
        this.enteredForDepartureTimePrint = $("#" + this.pageId + "departureTime").val();
        this.enteredForScheduleDatePrint = $("#" + this.pageId + "scheduledDates").val();
        this.enteredForScheduleTimePrint = $("#" + this.pageId + "scheduledTime").val();
        this.enteredForArrivalDatePrint = $("#" + this.pageId + "arrivalDates").val();
        this.enteredForArrivalTimePrint = $("#" + this.pageId + "arrivalTime").val();
        this.enteredForPrefferedDurationPrint = $("#" + this.pageId + "preferredDuration").val();
        this.enteredForBufferTimePrint = $("#" + this.pageId + "bufferTime").val();
        this.enteredForTimeTakenToReachedPrint = $("#" + this.pageId + "timeTakenToReached").val();
        this.enteredForRewardAmountPrint = $("#" + this.pageId + "rewardAmount").val();

        this.hireSlipDtoForPrintAndConfirmPayment.hireslipnumber = this.enteredForHireslipNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.hireslipdate = this.enteredForInvoiceDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.fromstation = this.enteredForSourcePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.tostation = this.enteredForDestinationPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.totalhire = this.enteredForHireAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.vehicleNumber = this.enteredForVehicleNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.vehiCompName = this.enteredForSupplierNamePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.drivername = this.enteredForDriverNamePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.driverMobileNumber = this.enteredForMobileNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.driverlicencenumber = this.enteredForLicensePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.departuredate = this.enteredForDepartureDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.departureTime = this.enteredForDepartureTimePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.scheduleddate = this.enteredForScheduleDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.scheduledTime = this.enteredForScheduleTimePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.arrivaldate = this.enteredForArrivalDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.arrivalTime = this.enteredForArrivalTimePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.bufferedTimeInHours = this.enteredForBufferTimePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.driverrewardingAmt = this.enteredForRewardAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.status = "Rewarded";
        this.hireSlipDtoForPrintAndConfirmPayment.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoForPrintAndConfirmPayment.column3 = this.enteredForTimeTakenToReachedPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.mode = "Reward";
        this.hireSlipDtoForPrintAndConfirmPayment.preferredDuration = this.enteredForPrefferedDurationPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.bufferTime = this.enteredForBufferTimePrint;

        var arrTimeSplit = this.enteredForTimeTakenToReachedPrint.split(":");
        this.hireSlipDtoForPrintAndConfirmPayment.durationInHours = arrTimeSplit[0];

        var inMins = (arrTimeSplit[0] * 60);
        if (arrTimeSplit.length > 1) {
            inMins = inMins + +arrTimeSplit[1];
        }
        this.hireSlipDtoForPrintAndConfirmPayment.inMinutes = inMins;
        if (mode != null && mode == "confirm") {
            this.currentDate = moment(new Date()).format("YYYY-MM-DD");
            this.hireSlipDtoForPrintAndConfirmPayment.fromDateInDate = this.currentDate;
        } else if (mode != null && mode == "print") {
            this.hireSlipDtoForPrintAndConfirmPayment.fromDateInDate = this.hireSlipDtoReturn.fromDateInDate;
        }
        return this.hireSlipDtoForPrintAndConfirmPayment;
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    rewardingPopupOpenLink(rewardingPopUpTemplate) {
        this.modalRefferenceRewardingPopUp = this.modalService.open(rewardingPopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceRewardingPopUp.result.then((result) => {
            this.modalRefferenceRewardingClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceRewardingClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    excludeSundayOkClickBtn() {
        this.getReachedDuration();
        //Rwd_chg_3 
        // this.modalRefferenceRewardingPopUp.close();
    }
    excludeSundayCancelClickBtn() {
        this.modalRefferenceRewardingPopUp.close();
    }
    excludeSundayYesCheckBoxEvent(values: any) {
        if (values.currentTarget.checked == true) {
            $("#" + this.pageId + "checkboxWithExcludeSundayYes").prop('checked', true);
            $("#" + this.pageId + "checkboxWithExcludeSundayNo").prop('checked', false);
        } else {
            $("#" + this.pageId + "checkboxWithExcludeSundayYes").prop('checked', false);
            $("#" + this.pageId + "checkboxWithExcludeSundayNo").prop('checked', true);
        }
    }

    excludeSundayNoCheckBoxEvent(values: any) {
        if (values.currentTarget.checked == true) {
            $("#" + this.pageId + "checkboxWithExcludeSundayYes").prop('checked', false);
            $("#" + this.pageId + "checkboxWithExcludeSundayNo").prop('checked', true);
        } else {
            $("#" + this.pageId + "checkboxWithExcludeSundayYes").prop('checked', true);
            $("#" + this.pageId + "checkboxWithExcludeSundayNo").prop('checked', false);
        }
    }

    scanCodePaste(event) {
        if (this.isManualScanCode == false) {
            $("#" + this.pageId + "scanCode").val('');
            swal("Alert", "Please Scan Code Using Scanner", "warning");
        }
    }

    getHireSlipNoRewardDto() {
        this.hireSlipDto = new HireSlipDto();
        this.validateHireslipNo = $("#" + this.pageId + "hireslipNumberGet").val();
        this.hireSlipDto.hireslipnumber = this.validateHireslipNo;
        this.hireSlipDto.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.mode = 'searchByHireSlipNo';

    }
    getHireslipNumberRewardDetails(scanCodeForRewardPopUpTemplate) {
        this.getHireSlipNoRewardDto();
        this.showSpinnerForAction = true;
        this.hireslipService.getHireSlipDetailsForRewarding(this.hireSlipDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.hireSlipDtoReturn = null;
                this.hireSlipDtoReturn = response;
                //console.log(response);
                //console.log(this.hireSlipDtoReturn);

                if (response.size == 0 || response.status == "Pending") {
                    swal("Not Allowed", "Please Scan Again", "warning");
                    this.clearAllFields();
                    this.commonClearFieldFalse();

                }
                //Rwd_chg_2
                else if (response.arrivaldate == null) {
                    if ((moment(new Date()).format("dddd") == "Monday") &&
                        (response.sundayWorking != null) &&
                        (response.sundayWorking == "No")) {
                        //popup.show();
                        //Rwd_chg_3
                        // this.rewardingPopupOpenLink(rewardingPopUpTemplate);
                        this.showSundayWiseRewardFields = true;
                    }
                    this.openScanCodePopup(scanCodeForRewardPopUpTemplate);
                } else {
                    if (response.status == "Scheduled") {
                        swal("Result Not Found", "" + $("#" + this.pageId + "hireslipNumber").val() + " is Scheduled to Transit", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();
                    } else if ((!(response.branch.toLowerCase() == response.tostation.toLowerCase())) &&
                        (!(this.userDataDtoReturnSession.office.toLowerCase() == response.tostation.toLowerCase()) &&
                            this.isManualScanCode == false)) {
                        swal("Not Allowed", "You Are Not Allowed to see the " + response.tostation + " Informations", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();
                    } else if (response.firstPntDelv != null &&
                        (!(response.tostation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()))) {
                        swal("Not Allowed", "Reward Allowed Only at " + response.firstPntDelv + " Because" + response.firstPntDelv + " is 1st Point Delivery", "warning");
                        this.clearAllFields();
                        this.commonClearFieldFalse();
                    } else if (response.deliveryPoints == 2 &&
                        ((response.tostation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()))) {
                        this.clearAllFields();
                        this.setValues(response);
                        this.showFieldForDeliveryPointsTwo(response, "hireslipNoWise");
                        console.log("DeliveryPt");
                    } else {
                        this.clearAllFields();
                        this.setValues(response);
                        this.showFieldForDeliveryPointsTwo(response, "hireslipNoWise");
                        console.log("No DeliveryPt");
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  HireSlipDetailsForRewarding", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }
    openScanCodePopup(scanCodeForRewardPopUpTemplate) {
        this.modalRefferenceScanCodePopUp = this.modalService.open(scanCodeForRewardPopUpTemplate,
            { centered: true, size: "lg" });
        this.modalRefferenceScanCodePopUp.result.then((result) => {
            this.modalRefferenceScanCodeClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceScanCodeClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    scanCodePopupCloseBtn() {
        this.modalRefferenceScanCodePopUp.close();
    }

    resetFields() {
        this.clearAllFields();
        this.commonClearFieldFalse();
        this.changeDetectorRef.detectChanges();
    }

    // rwd_chg_4
    onkeyPressListnerHireslipNumber(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "hireslipNumberGet").val() == null || $("#" + this.pageId + "hireslipNumberGet").val() == undefined ||
                $("#" + this.pageId + "hireslipNumberGet").val() == "") {
                swal("Mandatory Field", "Please enter Hireslip Number", "warning");
                return false;
            } else {
                this.getHireslipNumberRewardDetails(this.scanCodeForRewardPopUpTemplate);
            }
        }
    }

}
