<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Reward Time Setups</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-8 vl">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="card-title">Trip Duration</h6>
                                            <div class="row">

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Source</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="ti-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}source" type="text" name="source" class="form-control" [(ngModel)]="modelFrom" [ngbTypeahead]="searchFrom" [resultFormatter]="formatterFrom" [inputFormatter]="formatterFrom" (focus)="focusFromTA$.next($any($event).target.value)" placeholder="Select Source"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="ti-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" name="destination" class="form-control" [(ngModel)]="modelTo" [ngbTypeahead]="searchTo" [resultFormatter]="formatterTo" [inputFormatter]="formatterTo" (focus)="focusToTA$.next($any($event).target.value)" placeholder="Select Destination"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12" style="text-align: center;">
                                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}getDetailsBtn" (click)="getTimeList()">Get
														Details</button>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Expected Time To Reach(Hrs)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-clock"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" (keyup)="fieldFocus($event, bufferTimeInHrs)" id="{{pageId}}expectedTimeToReach" #expectedTimeToReach aria-describedby="basic-addon11" [(ngModel)]="hireSlipDto.durationInHours">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Buffer Time(In Hrs)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-clock"></i>
																</span>
                                                            </div>
                                                            <input type="text" class="form-control" placeholder="Hours" aria-describedby="basic-addon11" id="{{pageId}}bufferTimeInHrs" (keyup)="fieldFocus($event, bufferTimeInMins)" #bufferTimeInHrs [(ngModel)]="hireSlipDto.buffTimeInHr">&nbsp;
                                                            <input type="text" class="form-control" placeholder="Minutes" aria-describedby="basic-addon11" (keyup)="fieldFocus($event, timeToReach)" #bufferTimeInMins id="{{pageId}}bufferTimeInMins" [(ngModel)]="hireSlipDto.buffTimeInMin">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Time To Reach(Days)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-calendar-alt"></i>
																</span>
                                                            </div>
                                                            <input type="number" class="form-control" #timeToReach id="{{pageId}}timeToReach" (keyup)="fieldFocus($event, fromTime)" aria-describedby="basic-addon11" [(ngModel)]="hireSlipDto.daysToReach">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 vl">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="card-title">Time Setting</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}from">
                                                            <label class="wtfull">From Hrs</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}to">
                                                            <label class="wtfull">To Hrs</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}amount">
                                                            <label class="wtfull">Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
                                                            <input type="number" id="{{pageId}}fromTime" name="fromTime" class="form-control" #fromTime (keyup)="fieldFocus($event, toTime)" aria-describedby="basic-addon11" [(ngModel)]="hireSlipDto.fromTime">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
                                                            <input type="number" id="{{pageId}}toTime" name="toTime" class="form-control" #toTime (keyup)="fieldFocus($event, rewardingAmt)" aria-describedby="basic-addon11" [(ngModel)]="hireSlipDto.toTime">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-5">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-rupee-sign"></i>
                            </span>
                          </div> -->
                                                            <input type="number" id="{{pageId}}rewardingAmt" name="rewardingAmt" class="form-control" aria-describedby="basic-addon11" #rewardingAmt (keyup)="fieldFocus($event, addBtn)" [(ngModel)]="hireSlipDto.rewardAmt">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-5" style='display: none'>
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-rupee-sign"></i>
                            </span>
                          </div> -->
                                                            <input type="number" id="{{pageId}}hiddenIndex" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-1">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <!-- 																<span -->
                                                                <!-- 																	style="background-color: orange; color: #fff; cursor: pointer;" -->
                                                                <!-- 																	class="input-group-text"> <i title="Add" #addBtn -->
                                                                <!-- 																	(click)="addInTable();" -->
                                                                <!-- 																	class="fas fa-plus"></i> 																	(click)="addRow( timeSettingEntryTableDataList );" -->
                                                                <!-- 																</span> -->
                                                                <a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn class="btn btn-icon-only yellow" (click)="addInTable();"> <i class="fa fa-plus"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="box-body">
                                                        <!-- 							the first datatble starts -->
                                                        <table datatable id="{{pageId}}timeTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTimeSettingEntryTable" [dtTrigger]="dtTriggerTimeSettingEntryTable">

                                                            <thead>
                                                                <tr>
                                                                    <th>From Time</th>
                                                                    <th>To Time</th>
                                                                    <th>Rewarding Amount</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let timeSettingEntryTableData of timeSettingEntryTableDataList; let i = index">
                                                                    <td>{{ timeSettingEntryTableData.fromTime }}</td>
                                                                    <td>{{ timeSettingEntryTableData.toTime }}</td>
                                                                    <td>{{ timeSettingEntryTableData.rewardAmt }}</td>
                                                                    <td>
                                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEdit(timeSettingEntryTableData,i);">
																			<i title="Edit" class="fas fa-pencil-alt"></i>
																		</button>&nbsp;
                                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDelete(i)">
																			<i title="Remove" class="fas fa-trash"></i>
																		</button>
                                                                    </td>
                                                                </tr>


                                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 95%;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" #saveBtn (click)="validateTimeDetails()">Save</button>
                            <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}removeBtn" (click)="validateDeleteRow()">Remove</button>
                            <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn">Clear</button>
                            <!-- 							<button type="submit" class="btn btn-success m-r-10" -->
                            <!-- 								(click)="getTimeList()" id="{{pageId}}clearBtn">Get</button> -->

                        </div>
                    </div>
                </div>

                <div class="col-md-4 vl"></div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>