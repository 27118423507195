import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { Invoice } from 'src/app/dataService/invoice';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';

@Component({
    selector: 'app-invoice-details-lr-summary',
    templateUrl: './invoice-details-lr-summary.component.html',
    styleUrls: ['./invoice-details-lr-summary.component.css']
})
export class InvoiceDetailsLRSummaryComponent implements OnInit {


    getDataInvoiceDetailsDataListTable: any;
    getDataDestSubStationTable: any;
    getDataInvoiceMainStation: any;
    getDataInvoiceSubStation: any;
    getDataServiceFrConsolidatedInvoiceTable: any;

    invoiceDetailsDestinationDataList: any;
    invoiceDestinationSummaryDataList: any;
    invoiceDetailsBookingDateDataList: any;

    onDestroyUnsubscribtionInvoiceDetails: Subscription;
    onDestroyUnsubscribtionDestSubStation: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;



    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerInvoiceDetailsDestination: Subject<any> = new Subject();
    dtTriggerDestinationSummary: Subject<any> = new Subject();
    dtTriggerInvoiceDetailsBookingDate: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsInvoiceDetailsDestination: any;
    dtOptionsDestinationSummary: any;
    dtOptionsInvoiceDetailsBookingDate: any;


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    showSpinnerForAction = false;

    hideAndShowInvoiceDetailsDestinationTable = false;
    hideAndShowInvoiceDestinationSummaryTable = false;
    hideAndShowInvoiceDetailsBookingDateTable = false;

    lrDtoFor_LocalStorage: LRDto = new LRDto();
    lrDto: LRDto = new LRDto();
    lrDtoPopUpDestination: LRDto = new LRDto();

    validateTruckNumber: any = null;
    validateInvoiceNumber: any = null;
    validateSource: any = null;
    validateDestination: any = null;
    validateFromDate: any = null;
    validateToDate: any = null;
    validateLoaderName: any = null;
    validateGurWT: any = null;
    validateKantaWT: any = null;
    validateSuppName: any = null;
    validateGoodsType: any = null;
    validateInvoiceDate: any = null;

    hireslipdtoGetDetailsForPopup: HireSlipDto = new HireSlipDto();

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    artSummary: number;
    toPaySummary: number;
    paidSummary: number;
    fovSummary: number;
    actWgtSummary: number;
    chgWtSummary: number;
    valOfGoodsSummary: number;
    discountSummary: number;
    lrsSummary: number;
    lessAmtSummary: number;
    pageId = "inlrc";

    constructor(private invoiceDetailsRpt: Invoice,
        private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.hideAndShowInvoiceDetailsDestinationTable = true;
            this.lrDtoFor_LocalStorage = JSON.parse(localStorage.getItem('invoiceDetailsRptLrsDetailsPopUpTemplate'));
            localStorage.clear();
            //this.clearField();
            if (this.lrDtoFor_LocalStorage != null) {

                this.validateTruckNumber = this.lrDtoFor_LocalStorage.vehicleNumber;
                this.validateInvoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
                this.validateSource = this.lrDtoFor_LocalStorage.source;
                this.validateDestination = this.lrDtoFor_LocalStorage.destination;
                this.validateFromDate = this.lrDtoFor_LocalStorage.fromDate == null ? "" : moment(this.lrDtoFor_LocalStorage.fromDate).format("DD-MM-YYYY");
                this.validateToDate = this.lrDtoFor_LocalStorage.toDate == null ? "" : moment(this.lrDtoFor_LocalStorage.toDate).format("DD-MM-YYYY");
                this.validateLoaderName = this.lrDtoFor_LocalStorage.loaderName;
                this.validateInvoiceDate = this.lrDtoFor_LocalStorage.invoiceDateStr;
                this.setInvoiceDetailsOfDestinationLrs();

            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var truckNoPrint = this.validateTruckNumber;
        var InvNoPrint = this.validateInvoiceNumber;
        var sourcePrint = this.validateSource;
        var destPrint = this.validateDestination;
        var loadedByPrint = this.validateLoaderName;
        var gurWt = this.validateGurWT;
        var inv_datePrint = this.validateInvoiceDate;
        this.dtOptionsInvoiceDetailsDestination = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Booking Invoice Report - ";

                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 330,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(0).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var articles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPayAmt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paidAmt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fovAmt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var discAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodsValueAmt = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totddAmt = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                //$(api.column(3).footer()).html(totalLrs);
                $(api.column(4).footer()).html(articles);
                $(api.column(12).footer()).html(toPayAmt);
                $(api.column(13).footer()).html(paidAmt);
                $(api.column(14).footer()).html(fovAmt);
                $(api.column(15).footer()).html(discAmt.toFixed());
                $(api.column(16).footer()).html(actWgt);
                $(api.column(17).footer()).html(chgWgt);
                $(api.column(18).footer()).html(goodsValueAmt);
                $(api.column(19).footer()).html(totddAmt);
            }
        },
            this.dtOptionsDestinationSummary = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return " Particular BookingDate Details Report - ";

                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 330,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var goodsValueAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totddAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(totalLrs);
                    $(api.column(2).footer()).html(articles);
                    $(api.column(3).footer()).html(toPayAmt);
                    $(api.column(4).footer()).html(paidAmt);
                    $(api.column(5).footer()).html(fovAmt);
                    $(api.column(6).footer()).html(discAmt.toFixed());
                    $(api.column(7).footer()).html(actWgt);
                    $(api.column(8).footer()).html(chgWgt);
                    $(api.column(9).footer()).html(goodsValueAmt);
                    $(api.column(10).footer()).html(totddAmt);

                }
            },
            this.dtOptionsInvoiceDetailsBookingDate = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 330,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totddAmt = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(totalLrs);
                    $(api.column(6).footer()).html(articles);
                    $(api.column(7).footer()).html(actWgt);
                    $(api.column(8).footer()).html(chgWgt);
                    $(api.column(9).footer()).html(toPayAmt);
                    $(api.column(10).footer()).html(paidAmt);
                    $(api.column(11).footer()).html(fovAmt);
                    $(api.column(12).footer()).html(discAmt.toFixed());
                    $(api.column(13).footer()).html(totddAmt);

                }
            }
    }


    ngAfterViewInit(): void {
        this.dtTriggerInvoiceDetailsDestination.next();
        this.dtTriggerDestinationSummary.next();
        this.dtTriggerInvoiceDetailsBookingDate.next();
    }


    ngOnDestroy(): void {
        this.dtTriggerInvoiceDetailsDestination.unsubscribe();
        this.dtTriggerDestinationSummary.unsubscribe();
        this.dtTriggerInvoiceDetailsBookingDate.unsubscribe();
    }

    groupByMode(groupBy: string) {
        if (groupBy === 'destination') {
            this.hideAndShowInvoiceDetailsDestinationTable = true;
            this.hideAndShowInvoiceDestinationSummaryTable = false;
            this.hideAndShowInvoiceDetailsBookingDateTable = false;

            this.invoiceDetailsDestinationDataList = [];
            $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetailsDestination.next();

            this.invoiceDestinationSummaryDataList = [];
            $("#" + this.pageId + "invoiceDestinationSummaryTableId").DataTable().destroy();

            this.invoiceDetailsBookingDateDataList = [];
            $("#" + this.pageId + "invoiceDetailsBookingDateTableId").DataTable().destroy();

            //service calling
            this.setInvoiceDetailsOfDestinationLrs();
        } else if (groupBy === 'destinationSummary') {
            this.hideAndShowInvoiceDetailsDestinationTable = false;
            this.hideAndShowInvoiceDestinationSummaryTable = true;
            this.hideAndShowInvoiceDetailsBookingDateTable = false;

            this.invoiceDestinationSummaryDataList = [];
            $("#" + this.pageId + "invoiceDestinationSummaryTableId").DataTable().destroy();
            this.dtTriggerDestinationSummary.next();

            this.invoiceDetailsDestinationDataList = [];
            $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
            this.invoiceDetailsBookingDateDataList = [];
            $("#" + this.pageId + "invoiceDetailsBookingDateTableId").DataTable().destroy();

            //service calling
            this.setInvoiceDetailsOfDestinationSummaryLrs();
        } else if (groupBy === 'bookingDate') {
            this.hideAndShowInvoiceDetailsDestinationTable = false;
            this.hideAndShowInvoiceDestinationSummaryTable = false;
            this.hideAndShowInvoiceDetailsBookingDateTable = true;

            this.invoiceDetailsBookingDateDataList = [];
            $("#" + this.pageId + "invoiceDetailsBookingDateTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetailsBookingDate.next();

            this.invoiceDetailsDestinationDataList = [];
            $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
            this.invoiceDestinationSummaryDataList = [];
            $("#" + this.pageId + "invoiceDestinationSummaryTableId").DataTable().destroy();

            //service calling
            this.setInvoiceDetailsOfBookingDate();
        }
    }

    setInvoiceDetailsOfDestinationLrs() {
        this.lrDto = new LRDto();
        this.lrDto.invoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
        if (this.lrDtoFor_LocalStorage.mode != "mainInvoice") {
            this.lrDto.mode = "invRpt";
        } else {
            this.lrDto.mode = "";
        }
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        console.log(this.lrDto);
        this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
                this.invoiceDetailsDestinationDataList = [];
                if (response.length > 0) {
                    this.invoiceDetailsDestinationDataList = response;
                    //call for print setup
                    //this.customPrintInvoiceDetPopupRpt();
                    this.customPrintInvoiceDetPopupRptV2();
                    //this.validateGurWT = 1000;
                    this.validateGurWT = response[0].guranteeWt;
                    this.validateKantaWT = response[0].kantaWeight;
                    this.validateSuppName = response[0].supplierName;
                    this.validateGoodsType = null;
                    if (response[0].goodsType == "Select Type") {
                        this.validateGoodsType = "NA";
                    } else {
                        this.validateGoodsType = response[0].goodsType;
                    }
                    $("#" + this.pageId + "gurWtId").val(this.validateGurWT);
                    console.log(this.validateGurWT);
                    //this.dtTriggerStmtVerificationLrsSummaryReport.next();
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsDestination.next();
                    }, 3000);

                } else {
                    //this.dtTriggerStmtVerificationLrsSummaryReport.next();
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsDestination.next();
                    }, 3000);
                    swal("Warning", "No  Details found !", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Invoice Destination LR's Details", "info");
            }, () => console.log('done');
    }

    setInvoiceDetailsOfDestinationSummaryLrs() {
        this.lrDto = new LRDto();
        this.lrDto.invoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
        if (this.lrDtoFor_LocalStorage.mode != "mainInvoice") {
            this.lrDto.mode = "invRpt";
        } else {
            this.lrDto.mode = "";
        }
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDestinationSummary(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "invoiceDestinationSummaryTableId").DataTable().destroy();
                this.invoiceDestinationSummaryDataList = [];
                if (response.length > 0) {
                    this.invoiceDestinationSummaryDataList = response;
                    setTimeout(() => {
                        this.dtTriggerDestinationSummary.next();
                    }, 3000);
                } else {
                    setTimeout(() => {
                        this.dtTriggerDestinationSummary.next();
                    }, 3000);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Invoice Destination LR's Summary Details", "info");
            }, () => console.log('done');
    }

    setInvoiceDetailsOfBookingDate() {
        this.lrDto = new LRDto();
        this.lrDto.invoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
        if (this.lrDtoFor_LocalStorage.mode != "mainInvoice") {
            this.lrDto.mode = "invRpt";
        } else {
            this.lrDto.mode = "";
        }
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "invoiceDetailsBookingDateTableId").DataTable().destroy();
                this.invoiceDetailsBookingDateDataList = [];
                if (response.length > 0) {
                    this.invoiceDetailsBookingDateDataList = response;
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsBookingDate.next();
                    }, 3000);
                } else {
                    //this.dtTriggerStmtVerificationLrsSummaryReport.next();
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsBookingDate.next();
                    }, 3000);
                    swal("Warning", "No  Details found !", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Invoice Destination Booking Date LR's Details", "info");
            }, () => console.log('done');
    }

    invoiceDetailsLrRptHyperLinkBtn(destSubStationData) {
        //console.log(destSubStationData);
        $("#" + this.pageId + "groupBy").val('destination');
        this.hideAndShowInvoiceDetailsDestinationTable = true;
        this.hideAndShowInvoiceDestinationSummaryTable = false;
        this.hideAndShowInvoiceDetailsBookingDateTable = false;
        this.invoiceDetailsDestinationDataList = [];
        $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
        this.dtTriggerInvoiceDetailsDestination.next();

        $("#" + this.pageId + "invoiceDestinationSummaryTableId").DataTable().destroy();
        this.invoiceDestinationSummaryDataList = [];

        this.lrDtoPopUpDestination = new LRDto();
        this.lrDtoPopUpDestination.invoiceNumber = destSubStationData.invoiceNumber;
        this.lrDtoPopUpDestination.deliveryPoints = destSubStationData.destination;
        if (this.lrDtoFor_LocalStorage.mode != "mainInvoice") {
            this.lrDtoPopUpDestination.mode = "invRptDest";
        } else {
            this.lrDtoPopUpDestination.mode = "";
        }
        this.lrDtoPopUpDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.setInvoiceDetailsOfSpecificDestinationLrs();
    }

    setInvoiceDetailsOfSpecificDestinationLrs() {
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDtoPopUpDestination).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "invoiceDetailsDestinationTableId").DataTable().destroy();
                this.invoiceDetailsDestinationDataList = [];
                if (response.length > 0) {
                    this.invoiceDetailsDestinationDataList = response;
                    //call for print setup
                    // this.customPrintInvoiceDetPopupRpt();
                    this.customPrintInvoiceDetPopupRptV2();
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsDestination.next();
                    }, 3000);
                } else {
                    setTimeout(() => {
                        this.dtTriggerInvoiceDetailsDestination.next();
                    }, 3000);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Invoice Destination LR's Details", "info");
            }, () => console.log('done');
    }

    clearField() {
        $("#" + this.pageId + "truckNumberPopUpId").val('');
        $("#" + this.pageId + "invoiceNumberId").val('');
        $("#" + this.pageId + "fromStationPopUpId").val('');
        $("#" + this.pageId + "toStationPopUpId").val('');
    }

    customPrintInvoiceDetPopupRptV2() {
        this.hireslipdtoGetDetailsForPopup = new HireSlipDto();
        this.hireslipdtoGetDetailsForPopup.vehicleNumber = this.lrDtoFor_LocalStorage.vehicleNumber;
        this.hireslipdtoGetDetailsForPopup.suppliername = this.lrDtoFor_LocalStorage.supplierName;
        this.hireslipdtoGetDetailsForPopup.invoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
        this.hireslipdtoGetDetailsForPopup.fromstation = this.lrDtoFor_LocalStorage.source;
        this.hireslipdtoGetDetailsForPopup.tostation = this.lrDtoFor_LocalStorage.destination;
        this.hireslipdtoGetDetailsForPopup.gurWeight = this.lrDtoFor_LocalStorage.guranteeWt;
        this.hireslipdtoGetDetailsForPopup.kantaWeight = this.lrDtoFor_LocalStorage.kantaWeight;
        this.hireslipdtoGetDetailsForPopup.loadedby = this.lrDtoFor_LocalStorage.loaderName;
        this.hireslipdtoGetDetailsForPopup.invoiceDtStr = this.lrDtoFor_LocalStorage.invoiceDateStr;
        this.hireslipdtoGetDetailsForPopup.truckType = this.lrDtoFor_LocalStorage.truckType;

        localStorage.clear();
        localStorage.setItem('invoiceDetailsForBookingHireslipDtoPrint',
            JSON.stringify(this.hireslipdtoGetDetailsForPopup));
        localStorage.setItem('invoiceDetailsForBookingListOfLrsShortPrint',
            JSON.stringify(this.hireslipdtoGetDetailsForPopup.listLrsShortage));
        localStorage.setItem('invoiceDetailsForBookingLrDtoPrint', JSON.stringify(this.invoiceDetailsDestinationDataList));
    }

    customPrintInvoiceDetPopupRpt() {
        if (this.invoiceDetailsDestinationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Sub Station", "Art", "Bkg Date", "Main Invoice", "Description", "Consignee", "To Pay", "Paid", "FOV", "A.Wgt", "C.Wgt", "Goods Value"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 11, 3, 8, 12, 10, 12, 6, 5, 4, 6, 6, 7];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            //heading logics
            this.validateTruckNumber = this.lrDtoFor_LocalStorage.vehicleNumber;
            this.validateInvoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Truck", "Invoice"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateTruckNumber, this.validateInvoiceNumber];
            this.validateSource = this.lrDtoFor_LocalStorage.source;
            this.validateDestination = this.lrDtoFor_LocalStorage.destination;
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSource, this.validateDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.artSummary = this.toPaySummary = this.paidSummary = this.fovSummary = this.actWgtSummary = this.chgWtSummary = this.valOfGoodsSummary = 0;

            for (let i = 0; i < this.invoiceDetailsDestinationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceDetailsDestinationDataList[i].lrNumber, this.invoiceDetailsDestinationDataList[i].subStations,
                this.invoiceDetailsDestinationDataList[i].totalArticles, this.invoiceDetailsDestinationDataList[i].bookingDateStr,
                this.invoiceDetailsDestinationDataList[i].invoiceNumber, this.invoiceDetailsDestinationDataList[i].description, this.invoiceDetailsDestinationDataList[i].consigneeName,
                this.invoiceDetailsDestinationDataList[i].toPay, this.invoiceDetailsDestinationDataList[i].paid, this.invoiceDetailsDestinationDataList[i].riskCharge
                    , this.invoiceDetailsDestinationDataList[i].actualWeight, this.invoiceDetailsDestinationDataList[i].chargedWeight, this.invoiceDetailsDestinationDataList[i].goodsValue];
                this.artSummary = this.artSummary + this.invoiceDetailsDestinationDataList[i].totalArticles;
                this.toPaySummary = this.toPaySummary + this.invoiceDetailsDestinationDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.invoiceDetailsDestinationDataList[i].paid;
                this.fovSummary = this.fovSummary + this.invoiceDetailsDestinationDataList[i].riskCharge;
                this.actWgtSummary = this.actWgtSummary + this.invoiceDetailsDestinationDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.invoiceDetailsDestinationDataList[i].chargedWeight;
                this.valOfGoodsSummary = this.valOfGoodsSummary + this.invoiceDetailsDestinationDataList[i].goodsValue;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceDetailsDestinationDataList.length, "", this.artSummary, "", "", "", "", this.toPaySummary, this.paidSummary, this.fovSummary, this.actWgtSummary, this.chgWtSummary, this.valOfGoodsSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking Invoice Report");

            /*this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });*/

        }
    }
    customPrintInvoiceDesSumPopupRpt() {
        if (this.invoiceDestinationSummaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Station", "LRs", "Article", "To Pay", "Paid", "F O V", "Discount", "Actual Weight", "Charged Weight", "Goods Value"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics
            this.validateTruckNumber = this.lrDtoFor_LocalStorage.vehicleNumber;
            this.validateInvoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Truck Number", "Invoice Number"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateTruckNumber, this.validateInvoiceNumber];
            this.validateSource = this.lrDtoFor_LocalStorage.source;
            this.validateDestination = this.lrDtoFor_LocalStorage.destination;
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSource, this.validateDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.artSummary = this.toPaySummary = this.paidSummary = this.fovSummary = this.discountSummary = this.actWgtSummary = this.chgWtSummary = this.valOfGoodsSummary = 0;

            for (let i = 0; i < this.invoiceDestinationSummaryDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceDestinationSummaryDataList[i].destination, this.invoiceDestinationSummaryDataList[i].totalLrs,
                this.invoiceDestinationSummaryDataList[i].totalArticles, this.invoiceDestinationSummaryDataList[i].toPay,
                this.invoiceDestinationSummaryDataList[i].paid, this.invoiceDestinationSummaryDataList[i].riskCharge, this.invoiceDestinationSummaryDataList[i].discountAmount,
                this.invoiceDestinationSummaryDataList[i].actualWeight, this.invoiceDestinationSummaryDataList[i].chargedWeight, this.invoiceDestinationSummaryDataList[i].goodsValue];
                this.lrsSummary = this.lrsSummary + this.invoiceDestinationSummaryDataList[i].totalLrs;
                this.artSummary = this.artSummary + this.invoiceDestinationSummaryDataList[i].totalLrs;
                this.toPaySummary = this.toPaySummary + this.invoiceDestinationSummaryDataList[i].totalArticles;
                this.paidSummary = this.paidSummary + this.invoiceDestinationSummaryDataList[i].toPay;
                this.fovSummary = this.fovSummary + this.invoiceDestinationSummaryDataList[i].totalLrs;
                this.actWgtSummary = this.actWgtSummary + this.invoiceDestinationSummaryDataList[i].totalArticles;
                this.chgWtSummary = this.chgWtSummary + this.invoiceDestinationSummaryDataList[i].toPay;
                this.valOfGoodsSummary = this.valOfGoodsSummary + this.invoiceDestinationSummaryDataList[i].toPay;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceDestinationSummaryDataList.length, this.lrsSummary, this.artSummary, this.toPaySummary, this.paidSummary, this.fovSummary, this.actWgtSummary, this.chgWtSummary, this.valOfGoodsSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Particular Booking Date Details");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }


    customPrintInvoiceDetBookingPopupRpt() {
        if (this.invoiceDetailsBookingDateDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Date", "LR No", "Consignee Name", "Consignor Name", "Destination", "Invoice Number", "Article", "Actual Weight", "Charged Weight", "To Pay", "Paid", "F O V", "Less Amt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [8, 8, 12, 12, 8, 10, 6, 6, 6, 5, 5, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics
            this.validateTruckNumber = this.lrDtoFor_LocalStorage.vehicleNumber;
            this.validateInvoiceNumber = this.lrDtoFor_LocalStorage.invoiceNumber;
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Truck Number", "Invoice Number"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateTruckNumber, this.validateInvoiceNumber];
            this.validateSource = this.lrDtoFor_LocalStorage.source;
            this.validateDestination = this.lrDtoFor_LocalStorage.destination;
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSource, this.validateDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.artSummary = this.actWgtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.fovSummary = this.lessAmtSummary = 0;

            for (let i = 0; i < this.invoiceDetailsBookingDateDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceDetailsBookingDateDataList[i].bookingDateStr, this.invoiceDetailsBookingDateDataList[i].lrNumber,
                this.invoiceDetailsBookingDateDataList[i].consigneeName, this.invoiceDetailsBookingDateDataList[i].consignorName,
                this.invoiceDetailsBookingDateDataList[i].destination, this.invoiceDetailsBookingDateDataList[i].invoiceNumber, this.invoiceDetailsBookingDateDataList[i].totalArticles,
                this.invoiceDetailsBookingDateDataList[i].actualWeight, this.invoiceDetailsBookingDateDataList[i].chargedWeight, this.invoiceDetailsBookingDateDataList[i].toPay,
                this.invoiceDetailsBookingDateDataList[i].paid, this.invoiceDetailsBookingDateDataList[i].riskCharge, this.invoiceDetailsBookingDateDataList[i].discountAmount];
                this.artSummary = this.artSummary + this.invoiceDetailsBookingDateDataList[i].totalArticles;
                this.actWgtSummary = this.actWgtSummary + this.invoiceDetailsBookingDateDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.invoiceDetailsBookingDateDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.invoiceDetailsBookingDateDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.invoiceDetailsBookingDateDataList[i].paid;
                this.fovSummary = this.fovSummary + this.invoiceDetailsBookingDateDataList[i].riskCharge;
                this.lessAmtSummary = this.lessAmtSummary + this.invoiceDetailsBookingDateDataList[i].discountAmount;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceDetailsBookingDateDataList.length, "", "", "", "", "", this.artSummary, this.actWgtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.fovSummary, this.chgWtSummary, this.toPaySummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Invoice Detail Booking");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }
}
