import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { DriverRoutes } from 'src/app/stock/driver/driver.routing';
import { RewardingComponent } from 'src/app/stock/driver/rewarding/rewarding.component';
import { RewardingVoucherReportPrintComponent } from 'src/app/stock/driver/rewarding-voucher-rpt-print/rewarding-voucher-rpt-print.component';
import { BarcodeScanningComponent } from 'src/app/stock/driver/barcode-scanning/barcode-scanning.component';
import { DatePipe } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { RewardingNewVersionComponent } from 'src/app/stock/driver/rewarding-new-version/rewarding-new-version.component';

@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(DriverRoutes),
         FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule],
    declarations: [
        RewardingComponent,
        RewardingVoucherReportPrintComponent, BarcodeScanningComponent,RewardingNewVersionComponent],
        providers: [
        DatePipe
    ],
    exports: [BarcodeScanningComponent]
})
export class DriverModule { }
