
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';
@Component({
    selector: 'app-payment-voucher-loading-unloading-print',
    templateUrl: './payment-voucher-loading-unloading-print.component.html',
    styleUrls: ['./payment-voucher-loading-unloading-print.component.css']
})
export class PaymentVoucherLoadingUnloadingPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;

    num: any;
    n: any;
    printAmt: any;

    loadingUnloadingDataPaymentVoucher: any;
    loadingUnloadingType: any;
    loadingUnloadingHireslipDtoReturn: HireSlipDto[];
    unit: any;
    hamaliPerc: any;
    wgt: any;
    hamaliAmt: any;
    loaderHead: any;
    fromPeriod: any;
    toPeriod: any;

    hireslipdataForPrint: HireSlipDto = new HireSlipDto();

    address: any;
    image: any;
    pageId = "pvlup";
    firstPrintUldg = true;
    secondPrintUldg = false;
    thirdPrintUldg = false;

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.loadingUnloadingDataPaymentVoucher = JSON.parse(localStorage.getItem('paymentVoucherDatePassLocalStorage'));
            this.loadingUnloadingHireslipDtoReturn = JSON.parse(localStorage.getItem('paymentVoucherDatePassHireslipDtoLocalStorage'));
            this.loadingUnloadingType = localStorage.getItem('paymentVoucherDatePassTypeLocalStorage');
            console.log(this.loadingUnloadingDataPaymentVoucher);
            console.log(this.loadingUnloadingType);
            console.log('list voucher : ' + this.loadingUnloadingHireslipDtoReturn.length);
            if (this.loadingUnloadingHireslipDtoReturn.length == 2) {
                this.secondPrintUldg = true;
                this.thirdPrintUldg = false;
                console.log('list voucher 2');
            } else if (this.loadingUnloadingHireslipDtoReturn.length == 3) {
                this.secondPrintUldg = true;
                this.thirdPrintUldg = true;
                console.log('list voucher 3');
            }
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.validatBeforePrint();
            /**/window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
        this.unit = null;
        this.fromPeriod = null;
        this.loaderHead = null;
        this.toPeriod = null;
        this.hamaliPerc = 0.0;
        this.wgt = 0.0;
        this.hamaliAmt = 0.0;
        if (this.loadingUnloadingType == "Loading") {
            this.unit = this.loadingUnloadingDataPaymentVoucher.loadingHamaliUnit;
            this.hamaliPerc = this.loadingUnloadingDataPaymentVoucher.loadingHamali;
            this.wgt = this.loadingUnloadingDataPaymentVoucher.totalKantaWgt;
        } else if (this.loadingUnloadingType == "UnLoading") {
            this.unit = this.loadingUnloadingDataPaymentVoucher.unLoadingHamaliUnit;
            this.hamaliPerc = this.loadingUnloadingDataPaymentVoucher.unLoadingHamali;
            this.wgt = this.loadingUnloadingDataPaymentVoucher.totalActWgt;
        }
        this.hamaliAmt = this.loadingUnloadingHireslipDtoReturn[0].totalAmt;

        this.loaderHead = this.loadingUnloadingDataPaymentVoucher.loaderHead;
        this.fromPeriod = this.loadingUnloadingDataPaymentVoucher.fromDate;
        this.toPeriod = this.loadingUnloadingDataPaymentVoucher.toDate;
        /* this.rewardTo = this.hireslipdataForPrint.fromstation == null ? ' ' : this.hireslipdataForPrint.fromstation;
           this.dateInDate = this.datePipe.transform(this.hireslipdataForPrint.fromDateInDate, "dd-MM-yyyy");
           */
        if (this.hamaliAmt == null || this.hamaliAmt == undefined ||
            this.hamaliAmt == 0 || this.hamaliAmt == 0.0) {
            this.printAmt = "ZERO";
        } else {
            this.printAmt = this.inWords();
        }
    }

    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = this.hamaliAmt;
        //        console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(
            /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }
    clearField() {
        this.unit = null;
        this.fromPeriod = null;
        this.loaderHead = null;
        this.toPeriod = null;
        this.hamaliPerc = 0.0;
        this.wgt = 0.0;
        this.hamaliAmt = 0.0;
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
