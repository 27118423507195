<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Agent Statement Generation</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<!-- spinner start-->
					<div *ngIf="showSpinnerForAction" class="col-md-12">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;">
								</mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<!-- spinner end-->
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<h6 class="card-title">Statement Period</h6>
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Period</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															id="{{pageId}}fromDates" #fromDates="ngbDatepicker" autocomplete="off"
															[(ngModel)]="modalFromPeriod"
															[readonly]="!fromDateReadOnly">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Period</label> <input id="{{pageId}}toDates"
															class="form-control" placeholder="yyyy-mm-dd" name="toDates"
															ngbDatepicker #toDates="ngbDatepicker" autocomplete="off"
															(click)="toDates.toggle($event);validateToPeriodCalendar($event);"
															[(ngModel)]="modalToPeriod">
														<div class="input-group-append"
															(click)="toDates.toggle($event);validateToPeriodCalendar($event);">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}generateBtn"
								[disabled]="!generateBtnReadOnly" (click)="validateGenerateBtn();">Generate</button>
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn"
								 (click)="validateClearBtn();">Clear</button>
						</div>
					</div>

					<div class="col-md-9 vl p-t-10">
						<h6 class="card-title border_bottom">List of Booking Agents</h6>
						<div class="box-body">
							<table datatable id="{{pageId}}agentStatementGeneTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsAgentStmtGeneration" [dtTrigger]="dtTriggerAgentStmtGeneration">
								<thead>
									<tr>
										<!--<th><input type="checkbox" class="editor-active"></th>-->
										<th>Station Name</th>
										<th>Current Stmt From Date.</th>
										<th>Current Stmt To Date</th>
										<th>Current Stmt Date</th>
										<th>Current Stmt No</th>
									</tr>
								</thead>
								<tbody>
									<tr style='cursor: pointer;'
										*ngFor="let agentStmtGenerationData of agentStmtGenerationDataList "
										(click)="selectedRowSetAgentStmtGenDetails(agentStmtGenerationData);">
										<!--<td></td>-->
										<td>{{ agentStmtGenerationData.source }}</td>
										<td>{{ agentStmtGenerationData.fromDate}}</td>
										<td>{{ agentStmtGenerationData.toDate }}</td>
										<td>{{ agentStmtGenerationData.stmtDateStr }}</td>
										<td>{{ agentStmtGenerationData.fortNightStmtNo }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>