import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
const my = new Date();
import { ModalDismissReasons, NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MemoService } from 'src/app/dataService/memo-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';

@Component({
    selector: 'app-booking-paid-collection-report',
    templateUrl: './booking-paid-collection-report.component.html',
    styleUrls: ['./booking-paid-collection-report.component.css']
})
export class BookingPaidCollectionReportComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    viewDateWise = true;
    viewTillDateWise = false;
    viewReportmode = true;
    viewConsignor = true;
    viewTpNo = false;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    consignorNameSearchViewOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndexSearch: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedTillDate: any;
    selectedAgentSearch: any;
    selectedStmtNoSearch: any;
    enteredTpCode: any;
    enteredTpNo: any;
    showSpinnerForAction = false;
    showAgentDropdown = false;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    fromStationDataListOptions: any;
    lrDtoSearch: LRDto = new LRDto();
    cashMemoDtoSearch: CashMemoDto = new CashMemoDto();
    selectedConsignorIdSearchView: any;
    public modelConsignorNameSearchView: any;
    selectedViewType: any;
    toPartyLRDetailsDataList: Array<any> = [];
    dtTriggertoPartyLRDetails: Subject<any> = new Subject();
    dtOptionstoPartyLRDetails: any;
    toPartyLRSummaryDataList: Array<any> = [];
    dtTriggertoPartyLRSummary: Subject<any> = new Subject();
    dtOptionstoPartyLRSummary: any;
    amtCollectedDetailsDataList: Array<any> = [];
    dtTriggerAmtCollectedDetails: Subject<any> = new Subject();
    dtOptionstoAmtCollectedDetails: any;
    viewCustomPrintV1 = false;
    viewTopartyDetailTable = true;
    viewTopartySummayTable = false;
    viewAmtCollectedDetailsTable = false;
    userDtoMemoCode: UserDataDto = new UserDataDto();
    stationCodeDetails: any;
    tpFormat = null;
    pageId = "bpcrc"
    lrDtoBkgCashmemoForPrint: LRDto = new LRDto();
    lrDtoBkgCashmemoForPrintReturn: LRDto = new LRDto();
    lrDtoBkgCashmemoForPrintReturnList: LRDto[];
    listLrForMemoPrint: Array<any> = [];

    //For consignor Index search
    public modelConsignorIndexSearchView: any;
    @ViewChild('instanceConsignorIndexSearchView') instanceConsignorIndexSearchView: NgbTypeahead;
    consignorIndexSearchViewTA: Array<any> = [];
    focusConsignorIndexSearchViewTA$ = new Subject<string>();
    consignorIndexSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexSearchViewTA
                : this.consignorIndexSearchViewTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName search
    consignorNameSearchViewTA: Array<PartyMasterDto> = [];
    focusConsignorNameSearchViewTA$ = new Subject<string>();
    consignorNameSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameSearchViewTA
                : this.consignorNameSearchViewTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorNameSearchView = (x: { consignorName: string }) => x.consignorName;

    //For From Station
    public modelAgentSearchView: any;
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { subStation: string }) => x.subStation;

    //For Consignor Index
    consignorIndexOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    printToSentStmtNo: string;
    modalRefferenceLeftToPartyStmtPopUp: NgbModalRef;
    modalRefferenceLeftToPartyStmtClosePopUp: string;
    viewPrintPopupLeftToParty: boolean = false;
    viewLeftToPartyCustomPrintV1: boolean = false;
    viewLeftToPartyCustomPrintV2: boolean = false;
    viewAllMemoPrint: boolean = false;
    leftToPartyStatementDataLocalStorage: any;
    searchSrcListInLoop: Array<any> = [];
    sentSrcList: Array<any> = [];

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService,
        private memoService: MemoService, private modalService: NgbModal) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Bkg Payment Followup Show Agent") {
                        this.showAgentDropdown = true;
                    }
                }
            }
            this.getAgentNamesFromStations();
            this.setConsginorIndex();
            //default
            this.selectedViewType = "leftToParty";
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionstoPartyLRDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 360,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totArt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totActWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBasicFrgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSrcHamali = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totGcChg = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totFov = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLc = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBc = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totOthers = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totDDAmt = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLRAmt = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSc = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totRc = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totDesHamali = api.column(20).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totMemoAmt = api.column(21).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total");
                $(api.column(1).footer()).html(data.length);
                $(api.column(6).footer()).html(totArt);
                $(api.column(7).footer()).html(totActWgt);
                $(api.column(8).footer()).html(totChgWgt);
                $(api.column(9).footer()).html(totBasicFrgt);
                $(api.column(10).footer()).html(totSrcHamali);
                $(api.column(11).footer()).html(totGcChg);
                $(api.column(12).footer()).html(totFov);
                $(api.column(13).footer()).html(totLc);
                $(api.column(14).footer()).html(totBc);
                $(api.column(15).footer()).html(totOthers);
                $(api.column(16).footer()).html(totDDAmt);
                $(api.column(17).footer()).html(totLRAmt);
                $(api.column(18).footer()).html(totSc);
                $(api.column(19).footer()).html(totRc);
                $(api.column(20).footer()).html(totDesHamali);
                $(api.column(21).footer()).html(totMemoAmt);
            }
        }
        this.dtOptionstoPartyLRSummary = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 360,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totLRs = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totArt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totActWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBasicFrgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSrcHamali = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totGcChg = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totFov = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLc = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBc = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totOthers = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totDDAmt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLRAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSc = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totRc = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totDesHamali = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totMemoAmt = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total");
                $(api.column(1).footer()).html(data.length);
                $(api.column(3).footer()).html(totLRs);
                $(api.column(4).footer()).html(totArt);
                $(api.column(5).footer()).html(totActWgt);
                $(api.column(6).footer()).html(totChgWgt);
                $(api.column(7).footer()).html(totBasicFrgt);
                $(api.column(8).footer()).html(totSrcHamali);
                $(api.column(9).footer()).html(totGcChg);
                $(api.column(10).footer()).html(totFov);
                $(api.column(11).footer()).html(totLc);
                $(api.column(12).footer()).html(totBc);
                $(api.column(13).footer()).html(totOthers);
                $(api.column(14).footer()).html(totDDAmt);
                $(api.column(15).footer()).html(totLRAmt);
                $(api.column(16).footer()).html(totSc);
                $(api.column(17).footer()).html(totRc);
                $(api.column(18).footer()).html(totDesHamali);
                $(api.column(19).footer()).html(totMemoAmt);
            }
        }
        this.dtOptionstoAmtCollectedDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 360,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totMemoAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totArt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLessAmt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totCash = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChq = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totNeft = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totUpi = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total");
                $(api.column(1).footer()).html(data.length);
                $(api.column(5).footer()).html(totMemoAmt);
                $(api.column(10).footer()).html(totArt);
                $(api.column(11).footer()).html(totLessAmt);
                $(api.column(13).footer()).html(totCash);
                $(api.column(14).footer()).html(totChq);
                $(api.column(15).footer()).html(totNeft);
                $(api.column(16).footer()).html(totUpi);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggertoPartyLRDetails.next();
        this.dtTriggertoPartyLRSummary.next();
        this.dtTriggerAmtCollectedDetails.next();
    }
    ngOnDestroy(): void {
        this.dtTriggertoPartyLRDetails.unsubscribe();
        this.dtTriggertoPartyLRSummary.unsubscribe();
        this.dtTriggerAmtCollectedDetails.unsubscribe();
    }

    reportModeMode(reportMode: string) {
        if (reportMode === 'rptModeTillDate') {
            this.viewDateWise = false;
            this.viewTillDateWise = true;
        } else if (reportMode === 'rptModeDateWise') {
            this.viewDateWise = true;
            this.viewTillDateWise = false;
        }
    }

    searchByMode(searchBy: string) {
        this.selectTodayDate();
        this.selectedViewType = searchBy;
        if (searchBy === 'leftToParty') {
            this.viewTpNo = false;
            this.viewConsignor = true;
            this.viewTopartyDetailTable = true;
            this.viewTopartySummayTable = false;
            this.viewAmtCollectedDetailsTable = false;
            this.viewReportmode = true;
            this.viewDateWise = true;
            // this.toPartyLRDetailsDataList = [];
            // $("#" + this.pageId + "toPartyLRDetailsTable").DataTable().destroy();
            this.dtTriggertoPartyLRDetails.next();
        } else if (searchBy === 'leftToPartySummary') {
            this.viewTpNo = false;
            this.viewConsignor = true;
            this.viewTopartyDetailTable = false;
            this.viewTopartySummayTable = true;
            this.viewAmtCollectedDetailsTable = false;
            this.viewReportmode = true;
            this.viewDateWise = true;
            this.toPartyLRSummaryDataList = [];
            $("#" + this.pageId + "toPartyLRSummaryTable").DataTable().destroy();
            this.dtTriggertoPartyLRSummary.next();
        } else if (searchBy === 'toPartyStmtNo') {
            this.viewTpNo = true;
            this.viewConsignor = false;
            this.viewTopartyDetailTable = false;
            this.viewTopartySummayTable = true;
            this.viewAmtCollectedDetailsTable = false;
            this.viewDateWise = false;
            this.viewReportmode = false;
            this.toPartyLRSummaryDataList = [];
            $("#" + this.pageId + "toPartyLRSummaryTable").DataTable().destroy();
            this.dtTriggertoPartyLRSummary.next();
        } else if (searchBy === 'amountCollected') {
            this.viewTpNo = false;
            this.viewConsignor = true;
            this.viewTopartyDetailTable = false;
            this.viewTopartySummayTable = false;
            this.viewAmtCollectedDetailsTable = true;
            this.viewReportmode = false;
            this.viewDateWise = true;
        }
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }

    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexSearchViewTA.push(this.consignorIndexOptions[i].label);
        }
    }
    consignorSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndexSearchView = e.item;
        console.log(this.modelConsignorIndexSearchView);
        if (this.modelConsignorIndexSearchView != 'ALL') {
            this.getConsignorDetailsSearchViewList();
        }
    }

    getConsignorDetailsSearchViewList() {
        this.consignorNameSearchViewOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndexSearch = this.modelConsignorIndexSearchView;
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndexSearch;
        if (this.selectedIndexSearch != null && this.selectedIndexSearch != '') {
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "index";
            this.getConsignorDetailsSearchView();
        }
    }
    getConsignorDetailsSearchView = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameSearchViewOptions = [];
                    this.consignorNameSearchViewTA = [];
                } else {
                    this.consignorNameSearchViewOptions = response;
                    this.consignorNameSearchViewTA = [];
                    for (let i = 0; i < this.consignorNameSearchViewOptions.length; i++) {
                        this.consignorNameSearchViewTA.push(this.consignorNameSearchViewOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    getFromStationsRead(string) {
        this.fromAgentDto.mode = "mainBranch";
        this.fromAgentDto.status = "Working";
        this.fromAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fromAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.fromAgentDto.column2 = "working";
        if (string == "Self") {
            this.fromAgentDto.column3 = "Self";
        } else if (string == "Agent") {
            this.fromAgentDto.column3 = "Agent";
        }
    }

    getAgentNamesFromStations = () => {
        if (!this.showAgentDropdown) {
            this.fromStationTA = [];
            this.agentDtoFromStationAll.subStation = this.userDataDtoReturnSession.office;
            this.fromStationTA.push(this.agentDtoFromStationAll);
        } else {
            this.getFromStationsRead(String);
            this.masterReadService.getSourceListDetails(this.fromAgentDto).subscribe(
                (response) => {
                    this.fromStationDataListOptions = response;
                    this.fromStationTA = [];
                    this.searchSrcListInLoop = [];
                    this.agentDtoFromStationAll.subStation = "ALL";
                    this.fromStationTA.push(this.agentDtoFromStationAll);
                    for (let i = 0; i < this.fromStationDataListOptions.length; i++) {
                        this.fromStationTA.push(this.fromStationDataListOptions[i]);
                        this.searchSrcListInLoop.push(this.fromStationDataListOptions[i].subStation);
                    }
                }),
                (error) => {
                    swal("Error", "Server Problem Occurred While getting the Agent details", "info");
                },
                () => console.log('done');
        }
    }

    rowSelectedConsignorSearchView(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsignorNameSearchView = e.item;
        this.selectedConsignorIdSearchView = this.modelConsignorNameSearchView.consignorId;
    }

    searchMethod() {
        this.getToPartyLrDetailsInfo();
    }

    getToPartyLrDetailsInfo() {
        this.selectedFromDate = $("#" + this.pageId + "fromDates").val();
        this.selectedToDate = $("#" + this.pageId + "toDates").val();
        this.selectedAgentSearch = $("#" + this.pageId + "fromStation").val();
        // this.selectedTillDate = $("#" + this.pageId + "tillDate").val();

        this.selectedStmtNoSearch = null;
        this.enteredTpCode = $("#" + this.pageId + "tpCode").val();
        this.enteredTpNo = $("#" + this.pageId + "tpNo").val();
        this.selectedStmtNoSearch = this.enteredTpCode + "" + this.enteredTpNo;

        this.cashMemoDtoSearch = new CashMemoDto();
        this.cashMemoDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        console.log("getToPartyLrDetailsInfo");
        console.log(this.selectedViewType);

        this.cashMemoDtoSearch.statementNo = this.selectedStmtNoSearch;
        this.cashMemoDtoSearch.fromdate = this.selectedFromDate;
        this.cashMemoDtoSearch.todate = this.selectedToDate;
        this.cashMemoDtoSearch.consignor = this.modelConsignorIndexSearchView;
        this.cashMemoDtoSearch.consignorId = this.selectedConsignorIdSearchView;
        this.cashMemoDtoSearch.source = this.selectedAgentSearch;

        if (this.selectedAgentSearch == '' || this.selectedAgentSearch == null || this.selectedAgentSearch == 'ALL') {
            this.sentSrcList = [];
            this.sentSrcList = this.searchSrcListInLoop;
            this.cashMemoDtoSearch.list = this.sentSrcList;
        } else {
            this.sentSrcList = [];
            this.sentSrcList.push(this.selectedAgentSearch);
            this.cashMemoDtoSearch.list = this.sentSrcList;
        }

        // this.cashMemoDtoSearch.tillDate = this.selectedTillDate;
        if (this.viewDateWise) {
            this.cashMemoDtoSearch.mode = "Datewise";
        } else {
            this.cashMemoDtoSearch.mode = "tillDate";
        }
        console.log(this.selectedFromDate);
        console.log(this.selectedToDate);
        console.log(this.selectedAgentSearch);
        console.log(this.modelConsignorIndexSearchView);
        console.log(this.selectedStmtNoSearch);
        if (this.selectedViewType == "toPartyStmtNo") {
            console.log('if : ' + this.selectedStmtNoSearch);
        } else if (this.selectedViewType === "toPartyStmtNo") {
            console.log('else : ' + this.selectedStmtNoSearch);
        }

        if (this.selectedViewType === "leftToParty") {
            if (this.viewDateWise && (this.selectedFromDate == null || this.selectedFromDate == '' ||
                this.selectedToDate == null || this.selectedToDate == '' ||
                this.selectedAgentSearch == null || this.selectedAgentSearch == '' ||
                this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '')) {
                swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
            } else if (this.selectedAgentSearch == null || this.selectedAgentSearch == '' ||
                this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '') {
                swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
            } else {
                this.cashMemoDtoSearch.memoType = "To Party";
                this.cashMemoDtoSearch.status = "To Party";
                this.getToPartyLrDetails();
            }
        } else if (this.selectedViewType === "leftToPartySummary") {
            if (this.viewDateWise && (this.selectedFromDate == null || this.selectedFromDate == '' ||
                this.selectedToDate == null || this.selectedToDate == '' ||
                this.selectedAgentSearch == null || this.selectedAgentSearch == '' ||
                this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '')) {
                swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
            } else if (this.selectedAgentSearch == null || this.selectedAgentSearch == '' ||
                this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '') {
                swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
            } else {
                this.cashMemoDtoSearch.memoType = "To Party Summary";
                this.cashMemoDtoSearch.status = "To Party";
                this.getToPartySummary();
            }
        } else if (this.selectedViewType === "toPartyStmtNo") {
            if (this.selectedAgentSearch == null || this.selectedAgentSearch == ''
                || this.selectedStmtNoSearch == null || this.selectedStmtNoSearch == '') {
                swal("Not Allowed", "Please select/enter the mandatory fields to get the details!", "warning");
            } else {
                this.cashMemoDtoSearch.memoType = "To Party StmtNo";
                this.cashMemoDtoSearch.status = "To Party";
                this.getToPartySummary();
            }
        } else if (this.selectedViewType === "amountCollected") {
            if (this.selectedFromDate == null || this.selectedFromDate == '' ||
                this.selectedToDate == null || this.selectedToDate == '' ||
                this.selectedAgentSearch == null || this.selectedAgentSearch == ''
                || this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '') {
                swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
            } else {
                this.cashMemoDtoSearch.memoType = "Amount Collected";
                this.cashMemoDtoSearch.status = "completed";
                this.getAmountCollectedDetails();
            }
        }
    }

    getToPartyLrDetails = () => {
        this.showSpinnerForAction = true;
        this.memoService.getCashMemoUnAssgBkgService(this.cashMemoDtoSearch).subscribe(
            (response) => {
                this.toPartyLRDetailsDataList = [];
                $("#" + this.pageId + "toPartyLRDetailsTable").DataTable().destroy();
                if (response.length > 0) {
                    this.toPartyLRDetailsDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggertoPartyLRDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the toparty details", "warning");
            }, () => console.log('done');
    }

    getToPartySummary = () => {
        this.showSpinnerForAction = true;
        this.memoService.getBkgPaidFollowupReport(this.cashMemoDtoSearch).subscribe(
            (response) => {
                this.toPartyLRSummaryDataList = [];
                $("#" + this.pageId + "toPartyLRSummaryTable").DataTable().destroy();
                if (response.length > 0) {
                    this.toPartyLRSummaryDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggertoPartyLRSummary.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the toparty summary", "warning");
            }, () => console.log('done');
    }

    getAmountCollectedDetails = () => {
        this.showSpinnerForAction = true;
        this.memoService.getBkgPaidFollowupReport(this.cashMemoDtoSearch).subscribe(
            (response) => {
                this.amtCollectedDetailsDataList = [];
                $("#" + this.pageId + "amtCollectedDetailsTable").DataTable().destroy();
                if (response.length > 0) {
                    this.amtCollectedDetailsDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggerAmtCollectedDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the amount collected details!", "warning");
            }, () => console.log('done');
    }

    agentSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentSearchView = e.item;
        if (this.modelAgentSearchView.subStation != 'ALL') {
            this.getStationCode(this.modelAgentSearchView.subStation);
        }
    }

    getStationCode(agentName) {
        this.userDtoMemoCode = new UserDataDto();
        this.userDtoMemoCode.office = agentName;
        this.masterReadService.getStationCodeService(this.userDtoMemoCode).subscribe(
            (response) => {
                if (response.length != 0) {
                    this.stationCodeDetails = response;
                    this.tpFormat = "SRD/" + this.stationCodeDetails.stationCode + "/";
                    $("#" + this.pageId + "tpCode").val(this.tpFormat);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station Code",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    gotoLeftToPartyPopUPLinkData(toPartyLRSummaryData, printPreviewStmtPopUpPopUpTemplate) {
        //console.log(toPartyLRSummaryData);
        this.printToSentStmtNo = null;
        this.printToSentStmtNo = toPartyLRSummaryData.statementNo;
        this.modalRefferenceLeftToPartyStmtPopUp = this.modalService.open(printPreviewStmtPopUpPopUpTemplate,
            { centered: true, size: "lg" });
        this.modalRefferenceLeftToPartyStmtPopUp.result.then((result) => {
            this.modalRefferenceLeftToPartyStmtClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceLeftToPartyStmtClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.leftToPartyStatementDataLocalStorage = null;
        this.leftToPartyStatementDataLocalStorage = toPartyLRSummaryData;
        localStorage.clear();
        localStorage.setItem('leftToPartyGeneralDetailsLocalStorage',
            JSON.stringify(this.leftToPartyStatementDataLocalStorage));

        this.viewPrintPopupLeftToParty = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewPrintPopupLeftToParty) {
                this.viewPrintPopupLeftToParty = false;
                localStorage.clear();
            }
        });
    }

    closeLeftToPartyStmtBtnPopUp() {
        this.modalRefferenceLeftToPartyStmtPopUp.close();
    }

    print1PopUpBtn() {
        this.modalRefferenceLeftToPartyStmtPopUp.close();
        localStorage.clear();
        localStorage.setItem('leftToPartyGeneralDetailsLocalStorage',
            JSON.stringify(this.leftToPartyStatementDataLocalStorage));
        this.viewLeftToPartyCustomPrintV1 = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewLeftToPartyCustomPrintV1) {
                this.viewLeftToPartyCustomPrintV1 = false;
                localStorage.clear();
            }
        });
    };

    print2PopUpBtn() {
        this.modalRefferenceLeftToPartyStmtPopUp.close();
        localStorage.clear();
        localStorage.setItem('leftToPartyGeneralDetailsLocalStorage',
            JSON.stringify(this.leftToPartyStatementDataLocalStorage));
        this.viewLeftToPartyCustomPrintV2 = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewLeftToPartyCustomPrintV2) {
                this.viewLeftToPartyCustomPrintV2 = false;
                localStorage.clear();
            }
        });
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    printAllMemoBtn() {
        this.lrDtoBkgCashmemoForPrint = new LRDto();
        this.lrDtoBkgCashmemoForPrint.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBkgCashmemoForPrint.fortNightStmtNo = this.printToSentStmtNo;

        this.modalRefferenceLeftToPartyStmtPopUp.close();
        this.showSpinnerForAction = true;

        this.memoService.getCashMemoBkgDetailsList(this.lrDtoBkgCashmemoForPrint).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Oops Problem while Getting Data",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoBkgCashmemoForPrintReturnList = response;
                    localStorage.clear();
                    localStorage.setItem('printBkgCashmemoAutoGstList',
                        JSON.stringify(this.lrDtoBkgCashmemoForPrintReturnList));
                    this.viewAllMemoPrint = true;
                    window.addEventListener('afterprint', (onclick) => {
                        if (this.viewAllMemoPrint) {
                            this.viewAllMemoPrint = false;
                            localStorage.clear();
                        }
                    });
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }
}
