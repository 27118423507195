<div *ngIf="firstPrintUldg" id="{{pageId}}firstPrint" class="page row system_responsive" style="font-size: 20px;" id="{{pageId}}paymentVoucherLoadingUnloadingPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                <br>{{address}}
            </div>
            <div class="col-md-5">
                <h6 style='float: right;'>
                    Date : {{todayDate}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 30px!important;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td width='9%' height='23'><strong>&nbsp;&nbsp;DEBIT/CREDIT</strong></td>
                <td width='83%' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp; {{loadingUnloadingType}} Hamali </td>
                <td width='8%'>A/c.and</td>
            </tr>
            <tr>
                <td height='24'><strong>&nbsp;&nbsp;Pay</strong></td>
                <td colspan='2' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp;{{loaderHead}}
                </td>
            </tr>
        </table>
        <br />
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td height='54' style='border:1px solid #000;border-right:none;border-bottom:none;'>
                    <strong>&nbsp;&nbsp;
						On Account Of : </strong></td>
                <td style='border:1px solid #000;border-left:none;border-bottom:none; text-decoration:underline;'>
                    Hamali Paid for the Period of : {{fromPeriod}} To : {{toPeriod}} &nbsp;On&nbsp; {{unit}} - {{wgt}} &nbsp;(Kgs) at the Rate of {{hamaliPerc}} (%)
                </td>
                <td align='center' style='border:1px solid #000; border-left:none;border-bottom:none;'>
                    <strong>Rs.</strong><br /> {{hamaliAmt}}
                </td>
            </tr>
            <tr>
                <td width='13%' style='border:1px solid #000;border-right:none;'><strong>&nbsp;&nbsp;</strong></td>
                <td width='67%' height='41' align='center' style='border:1px solid #000;border-left:none; '>
                    <strong>Total</strong></td>
                <td width='20%' align='center' style='border:1px solid #000; border-left:none;'><strong>
						{{hamaliAmt}}</strong>
                </td>
            </tr>
        </table>
        <br>
        <hr /><br>
        <table width='100%' border='0' cellspacing='0' cellpadding='0' style='border:1px solid #000;'>
            <tr>
                <td width='22%' height='40'> <br /> &nbsp;Received the sum of Rupees: &nbsp;</td>
                <td width='78%' style='border-bottom:1px dotted #000000;'><br /> {{printAmt}} </td>
                <!-- Only -->
            </tr>
            <tr>
                <td height='41' colspan='2'><br />&nbsp;&nbsp;&nbsp;From <strong>SRD Logistics Pvt. Ltd.</strong></td>
            </tr>
        </table><br />

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>&nbsp;&nbsp;&nbsp;<strong>Cashier Signature</strong></td>
                <td align='right'><strong>Receiver Signature</strong> &nbsp;&nbsp;&nbsp;</td>
            </tr>
        </table>
    </div>
</div>

<p *ngIf="secondPrintUldg" style='page-break-after: always;'></p>

<div *ngIf="secondPrintUldg" id="{{pageId}}secondPrint" class="page row system_responsive" style="font-size: 20px;" id="{{pageId}}paymentVoucherLoadingUnloadingPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                <br>{{address}}
            </div>
            <div class="col-md-5">
                <h6 style='float: right;'>
                    Date : {{todayDate}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 30px!important;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td width='9%' height='23'><strong>&nbsp;&nbsp;DEBIT/CREDIT</strong></td>
                <td width='83%' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp; {{loadingUnloadingType}} Hamali </td>
                <td width='8%'>A/c.and</td>
            </tr>
            <tr>
                <td height='24'><strong>&nbsp;&nbsp;Pay</strong></td>
                <td colspan='2' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp;{{loaderHead}}
                </td>
            </tr>
        </table>
        <br />
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td height='54' style='border:1px solid #000;border-right:none;border-bottom:none;'>
                    <strong>&nbsp;&nbsp;
						On Account Of : </strong></td>
                <td style='border:1px solid #000;border-left:none;border-bottom:none; text-decoration:underline;'>
                    Hamali Paid for the Period of : {{fromPeriod}} To : {{toPeriod}} &nbsp;On&nbsp; {{unit}} - {{wgt}} &nbsp;(Kgs) at the Rate of {{hamaliPerc}} (%)
                </td>
                <td align='center' style='border:1px solid #000; border-left:none;border-bottom:none;'>
                    <strong>Rs.</strong><br /> {{hamaliAmt}}
                </td>
            </tr>
            <tr>
                <td width='13%' style='border:1px solid #000;border-right:none;'><strong>&nbsp;&nbsp;</strong></td>
                <td width='67%' height='41' align='center' style='border:1px solid #000;border-left:none; '>
                    <strong>Total</strong></td>
                <td width='20%' align='center' style='border:1px solid #000; border-left:none;'><strong>
						{{hamaliAmt}}</strong>
                </td>
            </tr>
        </table>
        <br>
        <hr /><br>
        <table width='100%' border='0' cellspacing='0' cellpadding='0' style='border:1px solid #000;'>
            <tr>
                <td width='22%' height='40'> <br /> &nbsp;Received the sum of Rupees: &nbsp;</td>
                <td width='78%' style='border-bottom:1px dotted #000000;'><br /> {{printAmt}} </td>
                <!-- Only -->
            </tr>
            <tr>
                <td height='41' colspan='2'><br />&nbsp;&nbsp;&nbsp;From <strong>SRD Logistics Pvt. Ltd.</strong></td>
            </tr>
        </table><br />

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>&nbsp;&nbsp;&nbsp;<strong>Cashier Signature</strong></td>
                <td align='right'><strong>Receiver Signature</strong> &nbsp;&nbsp;&nbsp;</td>
            </tr>
        </table>
    </div>
</div>

<p *ngIf="thirdPrintUldg" style='page-break-after: always;'></p>

<div *ngIf="thirdPrintUldg" id="{{pageId}}thirdPrint" class="page row system_responsive" style="font-size: 20px;" id="{{pageId}}paymentVoucherLoadingUnloadingPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                <br>{{address}}
            </div>
            <div class="col-md-5">
                <h6 style='float: right;'>
                    Date : {{todayDate}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 30px!important;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td width='9%' height='23'><strong>&nbsp;&nbsp;DEBIT/CREDIT</strong></td>
                <td width='83%' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp; {{loadingUnloadingType}} Hamali </td>
                <td width='8%'>A/c.and</td>
            </tr>
            <tr>
                <td height='24'><strong>&nbsp;&nbsp;Pay</strong></td>
                <td colspan='2' style=' border-bottom:1px dotted #000;'>&nbsp;&nbsp;{{loaderHead}}
                </td>
            </tr>
        </table>
        <br />
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td height='54' style='border:1px solid #000;border-right:none;border-bottom:none;'>
                    <strong>&nbsp;&nbsp;
						On Account Of : </strong></td>
                <td style='border:1px solid #000;border-left:none;border-bottom:none; text-decoration:underline;'>
                    Hamali Paid for the Period of : {{fromPeriod}} To : {{toPeriod}} &nbsp;On&nbsp; {{unit}} - {{wgt}} &nbsp;(Kgs) at the Rate of {{hamaliPerc}} (%)
                </td>
                <td align='center' style='border:1px solid #000; border-left:none;border-bottom:none;'>
                    <strong>Rs.</strong><br /> {{hamaliAmt}}
                </td>
            </tr>
            <tr>
                <td width='13%' style='border:1px solid #000;border-right:none;'><strong>&nbsp;&nbsp;</strong></td>
                <td width='67%' height='41' align='center' style='border:1px solid #000;border-left:none; '>
                    <strong>Total</strong></td>
                <td width='20%' align='center' style='border:1px solid #000; border-left:none;'><strong>
						{{hamaliAmt}}</strong>
                </td>
            </tr>
        </table>
        <br>
        <hr /><br>
        <table width='100%' border='0' cellspacing='0' cellpadding='0' style='border:1px solid #000;'>
            <tr>
                <td width='22%' height='40'> <br /> &nbsp;Received the sum of Rupees: &nbsp;</td>
                <td width='78%' style='border-bottom:1px dotted #000000;'><br /> {{printAmt}} </td>
                <!-- Only -->
            </tr>
            <tr>
                <td height='41' colspan='2'><br />&nbsp;&nbsp;&nbsp;From <strong>SRD Logistics Pvt. Ltd.</strong></td>
            </tr>
        </table><br />

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>&nbsp;&nbsp;&nbsp;<strong>Cashier Signature</strong></td>
                <td align='right'><strong>Receiver Signature</strong> &nbsp;&nbsp;&nbsp;</td>
            </tr>
        </table>
    </div>
</div>