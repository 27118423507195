<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsSourceStocksSummary"
			[dtTrigger]="dtTriggerSourceStocksSummary">

			<thead>
				<tr>
					<th>Source</th>
					<th>Act Wt</th>
					<th>Chg Wt</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let sourceStocksSummaryData of sourceStocksSummaryDataList ">
					<td>{{ sourceStocksSummaryData.name }}</td>
					<td>{{ sourceStocksSummaryData.symbol }}</td>
					<td>{{ sourceStocksSummaryData.grade }}</td>
				</tr>


				<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

			</tbody>
		</table>
	</div>
</div>
