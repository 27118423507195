<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->

        <div class="row">
            <div class="col-md-4">
                <h6 style='margin-left: 20px; font-size: 20px; padding-top: 30px;'>
                    <strong>G.C Left to Party : </strong> <strong>{{collectionMan}}</strong>
                </h6>
            </div>
            <div class="col-md-5" align='center'>
                <h6 style='margin-right: 20px; font-size: 20px; padding-top: 30px;'>
                    <strong><u>G.C Left To Party Report</u></strong>
                </h6>
            </div>
            <div class="col-md-3" align='right'>
                <h6 style='margin-right: 20px; font-size: 20px; padding-top: 30px;'>
                    <strong>Date : </strong> <strong>{{todayDate}}</strong>
                </h6>
            </div>
        </div>
        <!--Second  row starts-->
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <tr>
                        <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Memo No.</strong></td>
                        <td width='11%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Memo Date</strong></td>
                        <td width='11%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Assign Date</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Memo Amount</strong></td>
                        <td width='11%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> LR No.</strong></td>
                        <td width='31%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Consignee Name</strong></td>
                        <td width='11%' align='center' style=' border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Statement No.</strong></td>
                    </tr>
                    <tr *ngFor="let pfCollectionManDetailsData of pfCollectionManDetailsDataList">
                        <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appMemoNumberPrint'><strong>
								{{pfCollectionManDetailsData.memoNumber}} </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appEnteredDatePrint'><strong>
								{{this.datePipe.transform( pfCollectionManDetailsData.memoDate, "dd-MM-yyyy" )}} </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appEnteredDatePrint'><strong>
								{{this.datePipe.transform( pfCollectionManDetailsData.assignedDate, "dd-MM-yyyy" )}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appAmountPrint'><strong>
								{{pfCollectionManDetailsData.amount}} </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appLrNumberPrint'><strong>
								{{pfCollectionManDetailsData.lrNumber}} </strong></td>
                        <td width='31%' align='center' style='border-top: 1px solid #000;border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appPartyNamePrint'><strong>
								{{pfCollectionManDetailsData.consigneeName}}</strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appLrNumberPrint'><strong>
								{{pfCollectionManDetailsData.statementNo}} </strong></td>
                    </tr>
                    <tr>
                        <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Total : </strong><strong>
								{{totalCount}} </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> {{totalAmount}}</strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                        <td width='31%' align='center' style='border-top: 1px solid #000;border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> </strong></td>
                        <td width='11%' align='center' style='border-top: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong></strong></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h6 style='font-size: 20px; margin-left: 20px;'>
                    <strong>Received the above number of G.C for collection
						amounting to Rs.</strong> <strong>{{totalAmount}}</strong><strong>.
						I will render the account in the evening.</strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-16">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12" align='right'>
                <h6 style='font-size: 20px; padding-top: 22px;'>
                    <strong> Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>