import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterService } from "src/app/dataService/master-service";
import { AboutUsDto } from 'src/app/dto/AboutUs-dto';
import { LRDto } from 'src/app/dto/LR-dto';

@Component( {
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css']
} )
export class AboutUsComponent implements OnInit {

	isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    aboutUsDtoUserValue: AboutUsDto = new AboutUsDto();
    aboutUsDataList:any;
    aboutUsId = 0;
    
    enteredTitle : any;
    enteredMsg : any;
	pageId="abtus";
	
	lrDtoUser: LRDto = new LRDto();
    
	constructor(private router: Router,private masterService : MasterService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDetails();
        }

    }


    ngOnInit() {
    }
    
    getDetails = () => {
    	this.aboutUsId = 0;
		this.showSpinnerForAction = true;
		this.aboutUsDtoUserValue = new AboutUsDto();
		this.masterService.getAboutUsDetails(this.aboutUsDtoUserValue).subscribe(
			(response) => {
				this.aboutUsDataList = [];
				if (response.length == 0) {
					swal({
						title: "About Us",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.aboutUsDataList = response;
				setTimeout(() => {
					$("#"+this.pageId+"title").val(this.aboutUsDataList[0].tittle);
					$("#"+this.pageId+"message").val(this.aboutUsDataList[0].bodyMsg);
					this.aboutUsId = this.aboutUsDataList[0].aboutUsId;
	            }, 1000);
			}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	confirmSave(){
		swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes, it will immediately publish to mobile app?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveDetails();
            }
        })
	}
	
	saveDetails = () => {
		this.showSpinnerForAction = true;
		this.enteredTitle = $("#"+this.pageId+"title").val();
		this.enteredMsg = $("#"+this.pageId+"message").val();
		
		this.aboutUsDtoUserValue = new AboutUsDto();
		this.aboutUsDtoUserValue.aboutUsId = this.aboutUsId;
		this.aboutUsDtoUserValue.tittle = this.enteredTitle;
		this.aboutUsDtoUserValue.bodyMsg = this.enteredMsg;
		
		this.masterService.addAboutUsDetails(this.aboutUsDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					this.aboutUsId = 0;
					swal("Success", "About Us details save/update successfully!", "info");

				} else if (response.status == "Failed") {
					swal("Failed", "Failed to save/update the About Us details!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while saving the details", "info");
			}, () => console.log('done');
	};
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    
    //testing
    confirmErpCrmFlow = () => {
		this.showSpinnerForAction = true;
		this.lrDtoUser = new LRDto();
		this.masterService.salesforceErpToCrm(this.lrDtoUser).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "ERP-CRM done!", "info");

				} else {
					swal("Failed", "Failed !", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred", "info");
			}, () => console.log('done');
	};

}
