import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component({
  selector: 'app-delayed-e-sugam',
  templateUrl: './delayed-e-sugam.component.html',
  styleUrls: ['./delayed-e-sugam.component.css']
})
export class DelayedESugamComponent implements OnInit {




    getDataFrmServiceFrDelayedESugamTable: any;


delayedESugamDataList: any; 

onDestroyUnsubscribtionDelayedESugam:  Subscription;



loadingIndicator = true;




@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

        dtTriggerDelayedESugam: Subject<any> = new Subject();
     
      
        dataTable: any;


        dtOptionsDelayedESugam: any;
	pageId="dlesc";

        constructor(private delayedESugam:ReportService, public changeDetectorRef : ChangeDetectorRef){
          
            
        }
        
        rerender(): void {
            this.dtElements.forEach((dtElement: DataTableDirective) => {
              dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
      dtInstance.destroy();
              });
            });
          }

        

        
        
        
        
        ngOnInit(): void {
            
            
            this.dtOptionsDelayedESugam = {  
                    //};
                            //columns is used to for export and others starts
                    columns: [
                              {
                                  title: 'LR Number',
                                    data: 'lrNumber'
                                },
                                {
                                    title: 'Consignee Name',
                                    data: 'consigneeName'
                                },
                                {
                                    title: 'Area',
                                    data: 'area'
                                }, {
                                  title: 'Actual Weight',
                                    data: 'actualWeight'
                                },
                                {
                                    title: 'Charged Weight',
                                    data: 'chargedWeight'
                                },
                                {
                                    title: 'Diff Days',
                                    data: 'diffDays'
                                },
                                {
                                    title: 'Status',
                                    data: 'status'
                                }
                    ],        
                    
                    
                  //columns is used to for export and others endss
                    
                    
                    // the below code is for button export starts
                        dom: 'Bfrtip',
                    /*buttons: [
                              'excel', 'print'
                          ],*/
                        buttons: [
                                      {
                                          extend: 'excel',
                                          text:      '<i class="fas fa-file-excel"> Excel</i>',
                                          titleAttr: 'Excel',
                                          exportOptions: {
                                              columns: ':visible'
                                          }
                                      },
                                      {
                                          extend: 'print',
                                          text:      '<i class="fas fa-print"> Print</i>',
                                          titleAttr: 'Print',

                                      }
                              ],    
                       // the below code is for button export ends
                          //place holder in search/filter in datatable starts
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                        //place holder in search/filter in datatable ends  
                          processing: true,
                        //scroll in datatable starts
                          responsive: true,
                          "scrollX": true,
                          "scrollY": 380,
                          "scrollCollapse": true,
                              //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                                  "paging": false,
                                  "info": false,
                               //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                        //scroll in datatable ends
                }

            
          //the below code is for the getting data through json starts
//            this.supplierList.getAllData().subscribe(data => {
//                this.lrDispatchBknRptList = data['data'];
//                this.dtTriggerDelayedESugam.next();
//                } );
            this.getDataFrmServiceFrDelayedESugamTable=this.delayedESugam.getSummaryData()
            this.onDestroyUnsubscribtionDelayedESugam= this.getDataFrmServiceFrDelayedESugamTable.subscribe(data => {
                this.delayedESugamDataList = data['data'];
                this.dtTriggerDelayedESugam.next();
                } );
            
           
           
          //the below code is for the getting data through json ends
          
         
            
        }
        
        
      
     
        ngOnDestroy(): void {
            this.dtTriggerDelayedESugam.unsubscribe();

            this.onDestroyUnsubscribtionDelayedESugam.unsubscribe();

          }
        
        
}

