<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Labour Commission Setup
					Mumbai</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12">
											<!-- <h4 class="card-title">Tempo Details</h4> -->
											<div class="row">
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}loaderName">
															<label>Loader Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input type="text" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
											</div>
											<div class="row" style="padding: 10px;">
												<!-- <div class="card-header bg-info" style="background-color:orange!important;padding:5px;"> -->
												<h6>Select Source For Group</h6>
												<!-- </div> -->
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}purna"> <label
																	class=" custom-control-label wtfull " for="{{pageId}}purna">Purna</label>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}vapi"> <label
																	class=" custom-control-label wtfull " for="{{pageId}}vapi">Vapi</label>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}vasai"> <label
																	class=" custom-control-label wtfull " for="{{pageId}}vasai">Vasai</label>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}andheri"> <label
																	class=" custom-control-label wtfull " for="{{pageId}}andheri">Andheri</label>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}operaHouse"> <label
																	class=" custom-control-label wtfull " for="{{pageId}}operaHouse">Opera
																	House</label>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="custom-control custom-checkbox">
																<input type="checkbox" class="custom-control-input"
																	id="{{pageId}}chinchBunder"> <label
																	class=" custom-control-label wtfull "
																	for="{{pageId}}chinchBunder">Chinch Bunder</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 vl">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<!-- loading hamali starts -->
										<div class="col-md-12">
											<div class="row">
												<div class="col-sm-12 col-md-12">
													<h6>Loading Hamali</h6>
													<div class="form-group">
														<div class="input-group" id="{{pageId}}defaultWeightForActualWeight">
															<label>Default Rate(for Act Wgt) Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}newLoader">
															<label>Weight In Tonn For Order LR</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<!-- loading Hamali ends -->
												<!-- slabs starts below -->
												<h6>Slabs For Heavy LR</h6>
												<div class="row">
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="From Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="To Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-rupee-sign"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="Rate" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="From Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="To Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-rupee-sign"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="Rate" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="From Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-clock"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="To Wgt" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
													<div class="col-sm-3 col-md-4">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}">
																<!-- <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-rupee-sign"></i>
                            </span>
                          </div> -->
																<input type="number" class="form-control"
																	placeholder="Rate" aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
												</div>
												<!-- slabs ends -->
												<!-- thappi starts below -->
												<div class="col-sm-12 col-md-12">
													<h6>Thappi</h6>
													<div class="form-group">
														<div class="input-group" id="{{pageId}}thappiRateForTonn">
															<label>Thappi(Rate For Tonn)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>

												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}thappiRateForSecondLoading">
															<label>Thappi(Rate For 2nd Loading)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<select class="custom-select col-12">
																<option selected>Select Unit...</option>
																<option value="1">Kanta Weight</option>
																<option value="2">Actual Weight</option>
																<option value="3">Charged Weight</option>
															</select>
														</div>
													</div>
												</div>

												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}warfare">
															<label>Warfare(Rs)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>

												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}daysWork">
															<label>Days Work (In A Week)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<select class="custom-select col-12">
																<option selected>1</option>
																<option value="1">5</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}levi">
															<label>Levi(%)</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class=" fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>



											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}applyBtn">Apply</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
				</div>
				<div class="col-md-6 vl">
					<h6 class="card-title border_bottom">Commission Details</h6>
					<div class="box-body">
						<table datatable
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsLabourCommission" [dtTrigger]="dtTriggerLabourCommission">

							<thead>
								<tr>
									<th>Loader Name</th>
									<th>Warfair</th>
									<th>Levi</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let labourCommissionData of labourCommissionDataList ">
									<td>{{ labourCommissionData.loaderName }}</td>
									<td>{{ labourCommissionData.warfair}}</td>
									<td>{{ labourCommissionData.levi }}</td>
								</tr>


								<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

							</tbody>
						</table>


					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
