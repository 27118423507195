import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();
import { Component, OnInit, ChangeDetectorRef} from '@angular/core';

import { Subject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from "src/app/dataService/lr-service";
import *  as moment from 'moment';
import { MasterReadService } from "src/app/dataService/masterread-service";

@Component( {
    selector: 'app-booking-cashmemo-by-invoice',
    templateUrl: './booking-cashmemo-by-invoice.component.html',
    styleUrls: ['./booking-cashmemo-by-invoice.component.css']
} )
export class BookingCashmemoByInvoiceComponent implements OnInit {


    //for datepicker starts
    model: NgbDateStruct;
    
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    srcForRpt = null;
    defaultGodownName: string;
    sourceOption: Array<AgentDetailsDto> = [];
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
	bkgCashmemoByInvReportDataList: any;

    selectedSource: any;
    selectedFromDate:any;
    selectedToDate:any;
    lrDto: LRDto = new LRDto();

    dtTriggerBkgCashMemoInvoiceReport: Subject<any> = new Subject();
    dtOptionsBkgCashMemoInvoiceReport: any;
    showSpinnerForAction = false;
    fromStationDataListOptions: any;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    fromStationList: Array<any> = [];

	  //For From Station
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { subStation: string }) => x.subStation;
    pageId="bcbinv";
    constructor(private router: Router,private lrService: LrService, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.getAgentNamesFromStations();
        	}
    	}
    
    ngOnInit(): void {
        this.dtOptionsBkgCashMemoInvoiceReport = {
            
            dom: 'Bfrtip',
                  buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "Booking Cashmemo By Invoice Report - " +
                                "From Date : " + moment($("#bcbinvfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bcbinvtoDate").val()).format('DD-MM-YYYY') + "";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalBaseFrgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                var totalRc = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalSc = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalDestHamali = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                
                var totalActualWeight = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                var totalChargedWeight = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                var totalSubTotal = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                var totalDiscountAmount = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                var totalTotalAmount = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(8).footer()).html(totalBaseFrgt);
                $(api.column(9).footer()).html(totalRc);
                $(api.column(10).footer()).html(totalSc);
                $(api.column(11).footer()).html(totalDestHamali);
                $(api.column(12).footer()).html(totalActualWeight);
                $(api.column(13).footer()).html(totalChargedWeight);
                $(api.column(14).footer()).html(totalSubTotal);
                $(api.column(15).footer()).html(totalDiscountAmount);
                $(api.column(16).footer()).html(totalTotalAmount);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerBkgCashMemoInvoiceReport.unsubscribe();
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    getFromStationsRead(string) {

        this.fromAgentDto.mode = "mainBranch";
        this.fromAgentDto.status = "Working";
        this.fromAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fromAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.fromAgentDto.column2 = "working";
        if (string == "Self") {
            this.fromAgentDto.column3 = "Self";
        } else if (string == "Agent") {
            this.fromAgentDto.column3 = "Agent";
        }
        console.log(this.fromAgentDto);
        // this.fromStationMethod();
    }
    getAgentNamesFromStations = () => {
        this.getFromStationsRead(String);
        this.masterReadService.getSourceListDetails(this.fromAgentDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.fromStationDataListOptions = response;
                    this.fromStationTA = [];
                    this.agentDtoFromStationAll.subStation = "ALL";
                    this.fromStationTA.push(this.agentDtoFromStationAll);
                    this.fromStationList = [];
                    console.log(this.fromStationDataListOptions);
                    for (let i = 0; i < this.fromStationDataListOptions.length; i++) {
                        this.fromStationTA.push(this.fromStationDataListOptions[i]);
                        this.fromStationList.push(this.fromStationDataListOptions[i].subStation);

                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Getting From Stations Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    validateBtnSearch() {
    	if (($("#"+this.pageId+"fromStation").val() == null || $("#"+this.pageId+"fromStation").val() == undefined) ||
    			($("#"+this.pageId+"fromStation").val() == "")) {
    			swal("Mandatory Selection", "Please select source and destination to proceed", "warning");
    			return false;
    		}else if ((($("#"+this.pageId+"fromDate").val() == null || $("#"+this.pageId+"fromDate").val() == undefined) ||
        			($("#"+this.pageId+"fromDate").val() == "") || ($("#"+this.pageId+"toDate").val() == null) ||
        			($("#"+this.pageId+"toDate").val() == undefined) || ($("#"+this.pageId+"toDate").val() == ""))) {
        			swal("Mandatory Selection", "Please select From and To Date to proceed", "warning");
        			return false;
        		}
    	else {
    			this.gridReconfigure();
    		}
    }

    getSearchMethod() {
    	this.lrDto = new LRDto();
		this.selectedSource = $("#"+this.pageId+"fromStation").val();
        this.lrDto.mode = "bkgCashmemoSearchByInvoice";
        this.selectedFromDate=$("#"+this.pageId+"fromDate" ).val();
    	this.selectedToDate=$( "#"+this.pageId+"toDate" ).val();
        this.lrDto.fromDate =  this.selectedFromDate;
        this.lrDto.toDate =  this.selectedToDate;
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDto.source = this.selectedSource;
        console.log('1'+this.fromStationList);
        if (this.selectedSource != null && this.selectedSource == "ALL" && this.selectedSource != '') {
        	console.log('2'+this.fromStationList);
            this.lrDto.listFromStations = this.fromStationList;
        } else {
            this.lrDto.fromStation = this.selectedSource;
        }
      
        this.lrDto.reportMode = this.userDataDtoReturnSession.mainAdminStation;
    }


    gridReconfigure = () => {
    	this.showSpinnerForAction = true;
        this.getSearchMethod();
        		this.lrService.getBkgCashmemoService( this.lrDto ).subscribe(
            ( response ) => {
            	this.bkgCashmemoByInvReportDataList=[];
            	$("#"+this.pageId+"bkgCashmemoByInvoiceTableId").DataTable().destroy();
                console.log(response);
            	 if (response.length > 0) {
                     this.bkgCashmemoByInvReportDataList = response;
                     console.log("table >>" + this.bkgCashmemoByInvReportDataList);
                 } else {
                     swal("Alert", "No Data Found", "warning");
                 }
                   this.dtTriggerBkgCashMemoInvoiceReport.next();
                   this.showSpinnerForAction = false;
                   this.changeDetectorRef.detectChanges();
            } ), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the details", "warning");
            }, () => console.log('done');
    };
    
    ngAfterViewInit(): void {
        this.dtTriggerBkgCashMemoInvoiceReport.next();
    }
    clearAllFields() {
    	$("#"+this.pageId+"bkgCashmemoByInvoiceTableId").DataTable().destroy();
		this.dtTriggerBkgCashMemoInvoiceReport.next();
		this.bkgCashmemoByInvReportDataList=[];
		$("#"+this.pageId+"fromDate").val('');
		$("#"+this.pageId+"toDate").val('');
		$("#"+this.pageId+"fromStation").val('');
	
		this.selectedSource=null;
		this.selectedFromDate=null;
		this.selectedToDate=null;
		this.lrDto = new LRDto();
    }
    clearAll() {
		this.clearAllFields();
	}


    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }


  }