import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import swal from "sweetalert";
import * as XLSX from "xlsx";

@Component({
  selector: "app-invoice-loading-sheet",
  templateUrl: "./invoice-loading-sheet.component.html",
  styleUrls: ["./invoice-loading-sheet.component.css"],
})
export class InvoiceLoadingSheetComponent implements OnInit {

  isLoggedIn = true;
  userDataDtoReturnSession: any;
  summaryPosition = 'bottom';
  invoiceLoadingSheetTemp: any = [];
  stockLrListTemp: any = [];
  // @ViewChild(DatatableComponent) stockLrTable: DatatableComponent;
  @ViewChild('stockLrTable') stockLrTable: DatatableComponent;
  selectedLoadingInvoiceNumber: any;

  constructor(private router: Router) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });

    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.selectedLoadingInvoiceNumber = localStorage.getItem('loadingSheetInvoiceNumber');
      console.log(this.selectedLoadingInvoiceNumber);
    }
  }
  ngOnInit() {
    this.invoiceLoadingSheetTemp = this.invoiceLoadingSheet;
    this.stockLrListTemp = this.stockLrList;
  }
  logInPage() {
    this.router.navigate(["/authentication/login2"]);
  }
  public invoiceLoadingSheet = [
    { autoId: 1, bookingDate: '1-12-2023', lrNo: 'G457871', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 2, bookingDate: '2-12-2023', lrNo: 'G457872', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 3, bookingDate: '3-12-2023', lrNo: 'G457873', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 4, bookingDate: '4-12-2023', lrNo: 'G457874', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 5, bookingDate: '5-12-2023', lrNo: 'G457875', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 6, bookingDate: '6-12-2023', lrNo: 'G457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },

  ]
  public stockLrList = [
    { autoId: 1, bookingDate: '1-12-2023', lrNo: 'G4578712', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 177, destination: "walaja", loadingArticle: 0, notes: "" },
    { autoId: 2, bookingDate: '2-12-2023', lrNo: 'G4578722', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 3, bookingDate: '3-12-2023', lrNo: 'G4578733', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 4, bookingDate: '4-12-2023', lrNo: 'G457874', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 5, bookingDate: '5-12-2023', lrNo: 'G457875', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 6, bookingDate: '6-12-2023', lrNo: 'G457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 7, bookingDate: '7-12-2023', lrNo: 'G457877', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 8, bookingDate: '8-12-2023', lrNo: 'G457878', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 9, bookingDate: '9-12-2023', lrNo: 'G457879', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 1, bookingDate: '10-12-2023', lrNo: 'A457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 1, bookingDate: '1-12-2023', lrNo: 'B457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 2, bookingDate: '2-12-2023', lrNo: 'C457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 3, bookingDate: '3-12-2023', lrNo: 'D457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 4, bookingDate: '4-12-2023', lrNo: 'E457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 5, bookingDate: '5-12-2023', lrNo: 'F457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 6, bookingDate: '6-12-2023', lrNo: 'H457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 7, bookingDate: '7-12-2023', lrNo: 'I457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 8, bookingDate: '8-12-2023', lrNo: 'J457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 9, bookingDate: '9-12-2023', lrNo: 'K457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },
    { autoId: 1, bookingDate: '10-12-2023', lrNo: 'L457876', bookedFrom: 'Dhared - JM', actualWeight: 751, cWgt: 755, pvtMak: 'MH', article: 15, destination: "vellore", loadingArticle: 0, notes: "" },

  ]


  onMinusButtonClick(row: any) {
    // Decrement the article value
    if (row.loadingArticle > 0) {
      row.loadingArticle = row.loadingArticle - 1;
      console.log("minus", row);
    } else {
      console.log("lessthan zero")
    }
  }

  onPlusButtonClick(row: any, value: any) {
    if (row.loadingArticle < row.article) {
      let loadingArticle = parseInt(row.loadingArticle);
      row.loadingArticle = loadingArticle + value;
      console.log("plus", row);
    } else {
      console.log("exaust")
      swal({
        title: "Exaust?",
        text: "Loading Article is Reached Equal to Actual Article!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,

      })
    }
  }

  get actualWeight() {
    const actualWeight = this.invoiceLoadingSheet.reduce((sum, item) => sum + item.actualWeight, 0);
    return actualWeight.toFixed(2);
  }
  get cWeight() {
    const cWgt = this.invoiceLoadingSheet.reduce((sum, item) => sum + item.cWgt, 0);
    return cWgt.toFixed(2);
  }
  get article() {
    const article = this.invoiceLoadingSheet.reduce((sum, item) => sum + item.article, 0);
    return article.toFixed(2);
  }
  articleKeyPress(row) {
    console.log("hi")
  }
  deleteInvoiceLoading(row) {
    console.log("row", row)
    const lrNo = row.lrNo;
    swal({
      title: "Confirm Delete?",
      text: "Sure You Want to Remove the LR from the Invoice Loading Sheet?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.invoiceLoadingSheet = this.invoiceLoadingSheet.filter(item => item.lrNo !== lrNo);
      }
    });
    console.log("check after del", this.invoiceLoadingSheet)
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.invoiceLoadingSheet = this.invoiceLoadingSheetTemp.filter(index => (
      Object.values(index).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().indexOf(val) !== -1 || !val;
        } else if (typeof value === 'number') {
          return value.toString().indexOf(val) !== -1 || !val;
        } else {
          return false;
        }
      })
    ));
  }
  updateFilterStockLr(event) {
    const val = event.target.value.toLowerCase();
    this.stockLrList = this.stockLrListTemp.filter(index => (
      Object.values(index).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().indexOf(val) !== -1 || !val;
        } else if (typeof value === 'number') {
          return value.toString().indexOf(val) !== -1 || !val;
        } else {
          return false;
        }
      })
    ));
  }
  get actualWeightStock() {
    const actualWeight = this.stockLrList.reduce((sum, item) => sum + item.actualWeight, 0);
    return actualWeight.toFixed(2);
  }
  get cWeightStock() {
    const cWgt = this.stockLrList.reduce((sum, item) => sum + item.cWgt, 0);
    return cWgt.toFixed(2);
  }
  get articleStock() {
    const article = this.stockLrList.reduce((sum, item) => sum + item.article, 0);
    return article.toFixed(2);
  }
  toggleExpandGroup(group) {
    console.log("Toggled Expand Group!", group);
    this.stockLrTable.groupHeader.toggleExpandGroup(group);
  }
  addStockLr(row) {
    // Check if the lrno is already present in the invoiceLoadingSheet
    const isDuplicate = this.invoiceLoadingSheet.some(item => item.lrNo === row.lrNo);

    if (isDuplicate) {
      // Show SweetAlert if the lrno is a duplicate
      swal({
        title: "Duplicate",
        text: "LR No Already Exists in Invoice Loading Sheet ?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      })
    } else {
      // Add the row to invoiceLoadingSheet if lrno is unique
      this.invoiceLoadingSheet.push(row);
      //this.toastr.success('LR No added successfully!', 'Success');
      console.log("After Add", this.invoiceLoadingSheet)
      const indexToRemove = this.stockLrList.findIndex(item => item.lrNo === row.lrNo);

      // Remove the item from stockLrList if found
      if (indexToRemove !== -1) {
        this.stockLrList.splice(indexToRemove, 1);
        console.log("After Remove", this.stockLrList);
        // this.cdr.detectChanges();
        this.stockLrList = [...this.stockLrList];
      }
    }
  }
  confirmExportInvoiceLoading() {
    if (
      this.invoiceLoadingSheet != null &&
      this.invoiceLoadingSheet != undefined &&
      this.invoiceLoadingSheet.length > 0
    ) {
      console.log(this.invoiceLoadingSheet);
      this.exportExcelInvoiceLoading();
    } else {
      swal({
        title: "Empty",
        text: "No Data To export",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
        () => console.log("done");
    }
  }
  exportExcelInvoiceLoading(): void {

    let Heading2 = [
      [
        "Booking Date",
        "LR No",
        "Destination",
        "Booked From",

        "A.Wgt",
        "C.Wgt",
        "Pvt Mak",
        "Article",
        "Notes"
      ],
    ];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const boldStyle = { bold: true };

    XLSX.utils.sheet_add_json(ws, Heading2, { origin: "A2", skipHeader: true });

    const dataRows = this.invoiceLoadingSheet.map((e) => [
      e.bookingDate,
      e.lrNo,
      e.destination,
      e.bookedFrom,
      e.actualWeight,
      e.cWgt,
      e.pvtMak,
      e.article,
      e.notes

    ]);
    let actualWeight = 0, cWgt = 0, article = 0;
    this.invoiceLoadingSheet.forEach(item => {
      actualWeight += item.actualWeight;
      cWgt += item.cWgt;
      article += item.article;
    });
    const summaryRow = ["Total :" + dataRows.length, "", "", "", actualWeight, cWgt, "", article, ""];
    // summaryRow[0] = dataRows.length;
    dataRows.push(summaryRow);
    // const lastRow = XLSX.utils.sheet_add_aoa(ws, [summaryRow], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: -1 });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "Invoice Loading Sheet.xlsx");
  }

  confirmExportStockLr() {
    if (
      this.stockLrList != null &&
      this.stockLrList != undefined &&
      this.stockLrList.length > 0
    ) {
      console.log(this.stockLrList);
      this.exportExcelStockLr();
    } else {
      swal({
        title: "Empty",
        text: "No Data To export",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
        () => console.log("done");
    }
  }
  exportExcelStockLr(): void {

    let Heading2 = [
      [
        "Booking Date",
        "LR No",
        "Destination",
        "Booked From",

        "A.Wgt",
        "C.Wgt",
        "Pvt Mak",
        "Article",
        "Notes"
      ],
    ];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const boldStyle = { bold: true };

    XLSX.utils.sheet_add_json(ws, Heading2, { origin: "A2", skipHeader: true });

    const dataRows = this.stockLrList.map((e) => [
      e.bookingDate,
      e.lrNo,
      e.destination,
      e.bookedFrom,
      e.actualWeight,
      e.cWgt,
      e.pvtMak,
      e.article,
      e.notes

    ]);
    let actualWeight = 0, cWgt = 0, article = 0;
    this.stockLrList.forEach(item => {
      actualWeight += item.actualWeight;
      cWgt += item.cWgt;
      article += item.article;
    });
    const summaryRow = ["Total :" + dataRows.length, "", "", "", actualWeight, cWgt, "", article, ""];
    // summaryRow[0] = dataRows.length;
    dataRows.push(summaryRow);
    // const lastRow = XLSX.utils.sheet_add_aoa(ws, [summaryRow], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: -1 });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "Stock LR Sheet.xlsx");
  }

}
