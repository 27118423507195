//Chg_GSTSrh_1 : dummyCompanyName comments on GST portal Api service for get Null exception (24-08-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ResponseDto } from 'src/app/dto/Response-dto';
import swal from 'sweetalert';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';

@Component({
    selector: 'app-gst-search',
    templateUrl: './gst-search.component.html',
    styleUrls: ['./gst-search.component.css']
})

export class GstSearchComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "gstSearch";
    gstSearch = true;
    isLoggedIn = true;
    businessName: any;
    tradeName: any;
    registrationDate: any;
    businessConstitution: any;
    taxpayerType: any;
    gstStatus: any;
    address: any;
    otherAddress: any;
    businessPlace: any;
    responseDtoForGstApi: ResponseDto = new ResponseDto();
    enteredGstNo: any;
    lrtDtoRespForGstNo: any;
    showSpinnerForAction = false;
    gstNumber: any;
    status: any;
    address1: any;
    address2: any;
    pincode: any;
    stateCode: any;
    showStateCode: boolean = false;
    legalName: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    constructor(
        private masterReadService: MasterReadService, private router: Router,
        private ewaybillService: EwaybillService, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    setGstNumber() {
        this.responseDtoForGstApi = new ResponseDto();
        this.enteredGstNo = $("#" + this.pageId + "searchGstNo").val();
        this.responseDtoForGstApi.token1 = this.enteredGstNo;
    }

    getDetailsFromEwayBillSearchByGstNoMethod() {
        this.setGstNumber();
        this.showSpinnerForAction = true;
        this.ewaybillService.getDetailsFromEwayBillSearchByGstNo(this.responseDtoForGstApi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoRespForGstNo = response;
                console.log("lrtDtoRespForGstNo");
                console.log(this.lrtDtoRespForGstNo);
                if (this.lrtDtoRespForGstNo.respStatus == 1) {
                    this.showStateCode = true;
                    this.gstNumber = this.lrtDtoRespForGstNo.gstNoConsignee;
                    //
                    // this.legalName = this.lrtDtoRespForGstNo.dummyCompanyName
                    this.legalName = this.lrtDtoRespForGstNo.name

                    this.tradeName = this.lrtDtoRespForGstNo.name;
                    this.status = this.lrtDtoRespForGstNo.status;
                    this.address1 = this.lrtDtoRespForGstNo.address1;
                    this.address2 = this.lrtDtoRespForGstNo.address2;
                    this.pincode = this.lrtDtoRespForGstNo.pinCode;
                    this.stateCode = this.lrtDtoRespForGstNo.fromState;
                } else {
                    this.clearFields();
                    swal({
                        title: "Warning",
                        text: "No details found for the given GST Number!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details from the Gst Number", "warning");
            }, () => console.log('done');
    }

    validateForGstNo() {
        this.enteredGstNo = $("#" + this.pageId + "searchGstNo").val();
        if (this.enteredGstNo == null || this.enteredGstNo == '' || this.enteredGstNo == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter Gst Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.getDetailsFromEwayBillSearchByGstNoMethod();
        }
    }

    clearFields() {
       this.enteredGstNo = "";
       this.gstNumber = "";
       this.tradeName = "";
       this.legalName = "";
       this.status = "";
       this.address = "";
       this.address1 = "";
       this.address2 = "";
       this.pincode = "";
       this.stateCode = "";
    }
}
