import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { DragulaModule } from 'ng2-dragula';
import { MaterialModule } from "src/app/material.module";
import { CashmemoBlockComponent, CashmemoBlockPopupComponent } from 'src/app/cashmemo/cashmemo-block/cashmemo-block.component';
import { PaymentFollowupComponent } from 'src/app/cashmemo/payment-followup/payment-followup.component';
import { PaymentFollowupAssignPrintComponent } from 'src/app/cashmemo/payment-followup-assign-print/payment-followup-assign-print.component';
import { PaymentFollowupChallanPrintComponent } from 'src/app/cashmemo/payment-followup-challan-print/payment-followup-challan-print.component';
import { PaymentFollowupBookingComponent } from './payment-followup-booking/payment-followup-booking.component';
import { BkgLeftTopartyDetailsPrintV1Component } from './bkg-left-toparty-details-printV1/bkg-left-toparty-details-printV1.component';
import { BkgPaymentFollowupChallanPrintComponent } from './bkg-payment-followup-challan-print/bkg-payment-followup-challan-print.component';

@NgModule({
    imports: [CommonModule,
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, DragulaModule],
    declarations: [
        CashmemoBlockComponent,
        PaymentFollowupComponent,
        PaymentFollowupAssignPrintComponent,
        PaymentFollowupChallanPrintComponent,
        CashmemoBlockPopupComponent,
        PaymentFollowupBookingComponent, BkgLeftTopartyDetailsPrintV1Component, BkgPaymentFollowupChallanPrintComponent],
    exports: [PaymentFollowupAssignPrintComponent],
    entryComponents: [CashmemoBlockPopupComponent]
})
export class CashmemoModule { }
