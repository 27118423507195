import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-multipleinvoice-lastpoint-details',
	templateUrl: './multipleinvoice-lastpoint-details.component.html',
	styleUrls: ['./multipleinvoice-lastpoint-details.component.css']
})
export class MultipleInvoiceLastPointDetailsComponent implements OnInit {

	gridTosetInvoiceNumberList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerHireslipDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsHireslipDetails: any;


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	lrDtoForMultipleInvoice: LRDto = new LRDto();
	lrDtoListMultipleInvOptions: LRDto[];
	public modelMultiInvoiceDest: any;
	multipleInvoiceStationTA: Array<LRDto> = [];
	focusMultiInvoiceDestTA$ = new Subject<string>();
	searchMultiInvoiceDest = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMultiInvoiceDestTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.multipleInvoiceStationTA
				: this.multipleInvoiceStationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMultiInvoiceDest = (x: { destination: string }) => x.destination;

	multipleInvoiceLastPointLocalStorage: any;
	multiInvoiceDestination: any;
	userDataDtoReturnStationCode: any;
	invocieFormat: any;
	multiInvoiceNumberFormatForPopUp: any;
	multiInvoiceNumberForPopUp: any;


	private newAttributeTosetInvoiceNo: any = {};

	userDataDtoStationCode: UserDataDto = new UserDataDto();
	hireSlipDtoMultiInvoicePopup: HireSlipDto = new HireSlipDto();

	listOfInvoice: Array<String>;
	listOfInvoiceReturn: Array<String>;
pageId="mildc";



	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<MultipleInvoiceLastPointDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.multipleInvoiceLastPointLocalStorage = JSON.parse(localStorage.getItem('MultipleInvoiceLastPtOpen'));
			localStorage.clear();
			this.getDestinationMultiInvoice();
			console.log(this.multipleInvoiceLastPointLocalStorage);
			for (let i = 0; i < this.multipleInvoiceLastPointLocalStorage.length; i++) {
				console.log(this.multipleInvoiceLastPointLocalStorage[i]);
				if (i == 0) {
					this.gridTosetInvoiceNumberList = [];
					this.newAttributeTosetInvoiceNo.cmMultiInvoice = this.multipleInvoiceLastPointLocalStorage[i];
					this.gridTosetInvoiceNumberList.push(this.newAttributeTosetInvoiceNo);
				} else {
					this.newAttributeTosetInvoiceNo.cmMultiInvoice = this.multipleInvoiceLastPointLocalStorage[i];
					this.gridTosetInvoiceNumberList.push(this.newAttributeTosetInvoiceNo);
				}
			}
			//this.dtTriggerHireslipDetails.next();
		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsHireslipDetails = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				//$(api.column(0).footer()).html('Total : ' + data.length);
				//$(api.column(1).footer()).html(discAmt);
			},
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerHireslipDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerHireslipDetails.next();
	}


	clickListnerForMultiInvoiceStation(e: NgbTypeaheadSelectItemEvent) {
		this.modelMultiInvoiceDest = e.item;
		console.log(this.modelMultiInvoiceDest);
		$("#"+this.pageId+"multiInvoiceDestinationId").val(this.modelMultiInvoiceDest.destination);
		this.getStationCodeMultiInvoice();
	}

	getDestinationMultiInvoice() {
		this.lrDtoForMultipleInvoice = new LRDto();
		this.lrDtoForMultipleInvoice.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForMultipleInvoice.mode = "Other";
		this.lrDtoForMultipleInvoice.companyId = this.userDataDtoReturnSession.companyId;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoForMultipleInvoice).subscribe(
			(response) => {
				if (response.length == 0) {
					this.lrDtoListMultipleInvOptions = [];
					this.multipleInvoiceStationTA = [];
				} else {
					this.lrDtoListMultipleInvOptions = [];
					this.multipleInvoiceStationTA = [];
					this.lrDtoListMultipleInvOptions = response;
					for (let i = 0; i < this.lrDtoListMultipleInvOptions.length; i++) {
						this.multipleInvoiceStationTA.push(this.lrDtoListMultipleInvOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Destination Multi Invoice", "warning");
			},
			() => console.log('done');
	};

	getStationCodeMultiInvoice() {
		this.userDataDtoStationCode = new UserDataDto();
		this.multiInvoiceDestination = $("#"+this.pageId+"multiInvoiceDestinationId").val();
		this.userDataDtoStationCode.office = this.multiInvoiceDestination;
		//alert(this.multiInvoiceDestination);
		this.userDataDtoStationCode.companyId = this.userDataDtoReturnSession.companyId;
		this.masterReadService.getStationCodeService(this.userDataDtoStationCode).subscribe(
			(response) => {
				this.userDataDtoReturnStationCode = response;
				if (response) {
					this.invocieFormat = this.userDataDtoReturnSession.stationCode + "/"
						+ this.userDataDtoReturnStationCode.stationCode + "/"
						+ this.userDataDtoReturnSession.financialYearSplit + "/";
					$("#"+this.pageId+"multiInvoicePopupInvoiceFormat").val(this.invocieFormat);
					//pending work
					//txtMultiInvoicePopupInvoiceFormat.setReadOnly(false);
					$("#"+this.pageId+"multiInvoicePopUpNo").focus();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Error",
				text: "Server Error While Getting getDestinationMultiInvoice",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	}

	keyEventHandlerMultiInvoicePopUp(event) {
		if (event == 13) {
			if ($("#"+this.pageId+"multiInvoicePopUpNo").val() == null || $("#"+this.pageId+"multiInvoicePopUpNo").val() == "") {
				swal("Empty Data", "Please enter the Invoice Number", "warning");
				$("#"+this.pageId+"multiInvoicePopUpNo").focus();
			} else {
				this.hireSlipDtoMultiInvoicePopup = new HireSlipDto();
				this.multiInvoiceNumberFormatForPopUp = $("#"+this.pageId+"multiInvoicePopupInvoiceFormat").val();
				this.multiInvoiceNumberForPopUp = $("#"+this.pageId+"multiInvoicePopUpNo").val();
				this.hireSlipDtoMultiInvoicePopup.invoiceNumber = this.multiInvoiceNumberFormatForPopUp + ""
					+ this.multiInvoiceNumberForPopUp;
				this.hireSlipDtoMultiInvoicePopup.fromStation = this.userDataDtoReturnSession.mainStation;

				this.stockService.checkInvoiceForMultiPtDeliv(this.hireSlipDtoMultiInvoicePopup).subscribe(
					(response) => {
						if (response) {
							console.log(response.status);
							//response.status = "allow";
							if (response.status != null && response.status == "allow") {
								this.reconfigureAddMultiInvoice();
								$("#"+this.pageId+"multiInvoicePopUpNo").val('');
							} else if (response.status != null && response.status == "block") {
								swal("Not Allowed",
									"Invoice Number is Not Allowed, please enter the valid Invoice Number",
									"warning");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => swal({
						title: "Error",
						text: "Server Error While Getting getStationCodeService",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}),
					() => console.log('done');
			}
		}
	}

	reconfigureAddMultiInvoice() {
		this.newAttributeTosetInvoiceNo.cmMultiInvoice = $("#"+this.pageId+"multiInvoicePopupInvoiceFormat").val() + "" + $("#"+this.pageId+"multiInvoicePopUpNo").val();
		if (this.gridTosetInvoiceNumberList == null) {
			this.gridTosetInvoiceNumberList = [];
		}
		if (this.gridTosetInvoiceNumberList.length == 0) {
			$("#"+this.pageId+"multipleInvoiceLastPointTableId").DataTable().destroy();
			this.gridTosetInvoiceNumberList.push(this.newAttributeTosetInvoiceNo);
			this.dtTriggerHireslipDetails.next();
		} else {
			const result = this.gridTosetInvoiceNumberList.filter(multiInvoiceNoData => multiInvoiceNoData.cmMultiInvoice === this.newAttributeTosetInvoiceNo.cmMultiInvoice);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Invoice Number " + this.newAttributeTosetInvoiceNo.cmMultiInvoice + " Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#"+this.pageId+"multipleInvoiceLastPointTableId").DataTable().destroy();
				this.gridTosetInvoiceNumberList.push(this.newAttributeTosetInvoiceNo);
				this.dtTriggerHireslipDetails.next();
			}
		}
		this.newAttributeTosetInvoiceNo = {};
	}

	rowSelectedDelete(multipleInvoiceNoLinkedHireslip, index) {
		this.gridTosetInvoiceNumberList.splice(index, 1);
		$("#"+this.pageId+"multiInvoicePopUpNo").val('');
	}

	getInvoiceNumbersMultiInvoice() {
		this.listOfInvoice = [];
		if (this.gridTosetInvoiceNumberList != null &&
			this.gridTosetInvoiceNumberList != undefined &&
			this.gridTosetInvoiceNumberList.length > 0) {
			for (let i = 0; i < this.gridTosetInvoiceNumberList.length; i++) {
				this.listOfInvoice.push(this.gridTosetInvoiceNumberList[i]);
			}
		}
		console.log(this.gridTosetInvoiceNumberList);
		console.log(this.listOfInvoice);
		return this.listOfInvoice;
	}

	onMultipleInvoiceOkClick() {
		//this.listOfInvoice = [];
		this.listOfInvoice = this.getInvoiceNumbersMultiInvoice();
		if (this.listOfInvoice.length == 0) {
			swal("Warning", "Please add atleast one Invoice to proceed.", "warning");
			return false;
		} else {
			console.log(this.listOfInvoice);
			this.dialogRef.close(JSON.stringify(this.listOfInvoice));
			//this.dialogRef.close(JSON.stringify(this.gridTosetInvoiceNumberList));
			this.clearField();
		}
	}



	onCancelClick(): void {
		/*this.listOfInvoice = [];
		this.listOfInvoiceReturn = [];
		this.dialogRef.close(JSON.stringify(this.listOfInvoice));
		this.clearField();*/
		if (this.gridTosetInvoiceNumberList != null &&
			this.gridTosetInvoiceNumberList != undefined &&
			this.gridTosetInvoiceNumberList.length > 0) {
			//this.listOfInvoice = [];
			//this.listOfInvoiceReturn = [];
			this.dialogRef.close(JSON.stringify(this.listOfInvoice));
			this.clearField();
		} else {
			this.listOfInvoice = [];
			this.listOfInvoiceReturn = [];
			this.dialogRef.close(JSON.stringify(this.listOfInvoice));
			this.clearField();
		}
	}
	clearField() {
		this.multipleInvoiceLastPointLocalStorage = null;
		this.multiInvoiceDestination = null;
		this.userDataDtoReturnStationCode = null;
		this.invocieFormat = null;
		this.multiInvoiceNumberFormatForPopUp = null;
		this.multiInvoiceNumberForPopUp = null;


		this.newAttributeTosetInvoiceNo = {};

		this.userDataDtoStationCode = new UserDataDto();
		this.hireSlipDtoMultiInvoicePopup = new HireSlipDto();

		this.listOfInvoiceReturn = [];
		this.listOfInvoice = [];
	}
}

