
<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"(click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center'
						style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-8">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Block Lr Details
					</h6>
			
				</div>
				<div class="col-md-4">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id='{{pageId}}blockLrDetailsId'
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsBlockLrDetails"
			[dtTrigger]="dtTriggerBlockLrDetails">

			<thead>
				<tr>
					<th>LR Number</th>
					<th style='padding-right:100px;'>Consignee Name</th>
					<th>Booking Date</th>
					<th>Reason</th>

				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let blockLrDetailsData of blockLrDetailsDataList ">
					<td>{{ blockLrDetailsData.lrNumber }}</td>
					<td>{{ blockLrDetailsData.consigneeName }}</td>
					<td>{{ blockLrDetailsData.bookingDateStr }}</td>
					<td><button type="button"
							style="padding: 1px 4px; background-color: #ffffff00;"
							class="btn m-r-10" id="{{pageId}}tableReasonBtn"
							(click)="getReason(blockLrDetailsData);">
							 <i title="Message" class="fas fa-envelope"></i>
						</button></td>

				</tr>
			</tbody>
		</table>
	</div>
</div>