<!-- <button type="button" style="margin-left: 350px; margin-top: -80px;" -->
<!-- 	class="dt-button" id="{{pageId}}tableReloadBtn" (click)="gridReconifgureOnReloadBtn()"> -->
<!-- 	<i title="Reload" class="fas fa-undo"></i> -->
<!-- </button> -->
<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px;padding: 2px 5px;background-color: burlywood;" (click)="gridReconifgureOnReloadBtn()"
	area-hidden="true"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
			<div class="col-md-11">
				<h6 class="card-title">
					<i class=" fas fa-file-alt"></i>&nbsp;Cash Memo Pending From Binnymills
				</h6>
		
			</div>
			<div class="col-md-1">
			<i title="Reload" class="fas fa-sync-alt fa xs cli"
				style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
				(click)="gridReconifgureOnReloadBtn()"></i>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id="{{pageId}}cashMemoPendingFromBinnymillsId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsCashMemoPendingFromBinnymills" [dtTrigger]="dtTriggerCashMemoPendingFromBinnymills">

			<thead>
				<tr>
					<th>Batch</th>
					<th>Rem. Memos</th>
					<th>Created By&nbsp;&nbsp;&nbsp;</th>
					<th>Created Date</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let cashMemoPendingFromBinnymillsData of cashMemoPendingFromBinnymillsDataList">
					<td (click)="batchDialog(cashMemoPendingFromBinnymillsData)"><u class="hyperLink_td">{{
							cashMemoPendingFromBinnymillsData.batchNumber }}</u></td>
					<td (click)="remMemosDialog(cashMemoPendingFromBinnymillsData)"><u class="hyperLink_td">{{
							cashMemoPendingFromBinnymillsData.remMemos }}</u></td>
					<td>{{ cashMemoPendingFromBinnymillsData.enteredBy }}</td>
					<td>{{ cashMemoPendingFromBinnymillsData.enterDate }}</td>
				</tr>

			</tbody>
		</table>
	</div>
</div>