import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';



@Component( {
    selector: 'app-vehicle-tracking',
    templateUrl: './vehicle-tracking.component.html',
    styleUrls: ['./vehicle-tracking.component.css']
} )
export class VehicleTrackingComponent implements OnInit {

    loadingIndicator = true;

    //hide and show starts
    trackByLrNumber = false;
    trackByInvoiceNumber = false;
    trackByDeviceId = false;
    //hide and show ends
    //for the select option with filter starts

    control = new FormControl();

	latLatest:any;
    lngLatest:any;
    //for map starts 
    @ViewChild( 'map1' ) map1;

    //lat = -34.397;
    //lng = 150.644;
    lat = 20.5937;
    lng = 78.9629;
    latA = -34.754764;
    lngA = 149.736246;
    zoom = 6;

    styles: any = [
        {
            featureType: 'all',
            stylers: [
                {
                    saturation: -80
                }
            ]
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    hue: '#00ffee'
                },
                {
                    saturation: 50
                }
            ]
        },
        {
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        }
    ];
    
    tripIdInfo : any;
    depDateInfo : any;
    invNoInfo : any;
    sourceInfo : any;
    destInfo : any;
    vehicleInfo : any;
    driverInfo : any;
    driverNo : any;
    lastLocationInfo : any;
    lastUpdateInfo : any;
    distTravInfo : any;
    etaInfo:any;
    //for map ends


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
    ftTrackAll: boolean = false;
    enteredSearchBy:any;
    trackBySearch:any;
    trackingLiveReportDataList:any;
    trackingLiveReportDataLocal:any;
    searchModeRedirect : any;
    lrEnquiryLrNo : any;
    pageId="vhltr";

    constructor(private router: Router,private hireslipService : HireslipService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.trackingLiveReportDataLocal=JSON.parse(localStorage.getItem('trackingLiveReportData'));
            this.searchModeRedirect = localStorage.getItem('searchMode');
            this.lrEnquiryLrNo = localStorage.getItem('enteredLrnumber');
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "FTTrackingAll") {
                        this.ftTrackAll = true;
                    }
                }
            }
            if(this.searchModeRedirect != null && this.searchModeRedirect == "tripId"){
            setTimeout(() => {
            	this.lrDtoUserValue = new LRDto();
            	this.lrDtoUserValue.reportMode = "tripId";
    			this.lrDtoUserValue.mode = "tripId";
    			this.lrDtoUserValue.id = this.trackingLiveReportDataLocal.id;
            	this.trackByLROrTripId();
            }, 1000);
            }else if(this.searchModeRedirect != null && this.searchModeRedirect == "lrnumber"){
                setTimeout(() => {
                	this.lrDtoUserValue = new LRDto();
        			this.lrDtoUserValue.reportMode = "LR";
        			this.lrDtoUserValue.mode = "LR";
        			this.lrDtoUserValue.lrNumber = this.lrEnquiryLrNo;
                	this.trackByLROrTripId();
                }, 1000);
                }
        }

    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
    
    trackConsigment() {
    	this.getDetails();
    }

    //hide and show starts
    trackByMode( trackBy: string ) {
    	this.enteredSearchBy=null;
    	this.trackBySearch=trackBy;
        if ( trackBy === 'lrNumber' ) {
            this.trackByLrNumber = true;
            this.trackByInvoiceNumber = false;
            this.trackByDeviceId = false;

        } else if ( trackBy === 'invoiceNumber' ) {
            this.trackByLrNumber = false;
            this.trackByInvoiceNumber = true;
            this.trackByDeviceId = false;

        } else if ( trackBy === 'deviceId' ) {
            this.trackByLrNumber = false;
            this.trackByInvoiceNumber = false;
            this.trackByDeviceId = true;

        } else {
            this.trackByLrNumber = false;
            this.trackByInvoiceNumber = false;
            this.trackByDeviceId = false;
        }
    }
    //hide and show ends

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoUserValue.status = "Stock";
		this.lrDtoUserValue.branch = this.userDataDtoReturnSession.mainStation;
		this.lrDtoUserValue.isTrue = this.ftTrackAll;
		
		if ( this.trackBySearch === 'lrNumber' ) {
			this.enteredSearchBy=$("#"+this.pageId+"lrNumber").val();
			this.lrDtoUserValue.reportMode = "LR";
			this.lrDtoUserValue.mode = "LR";
			this.lrDtoUserValue.lrNumber = this.enteredSearchBy;
			this.trackByLROrTripId();
		}else if ( this.trackBySearch === 'deviceId' ) {
			this.enteredSearchBy=$("#"+this.pageId+"deviceId").val();
			this.lrDtoUserValue.reportMode = "tripId";
			this.lrDtoUserValue.mode = "tripId";
			this.lrDtoUserValue.lrNumber = this.enteredSearchBy;
			this.lrDtoUserValue.id = this.enteredSearchBy;
			this.trackByLROrTripId();
		}else if ( this.trackBySearch === 'invoiceNumber' ) {
			this.enteredSearchBy=$("#"+this.pageId+"invoiceNumber").val();
			this.lrDtoUserValue.reportMode = "Invoice";
			this.lrDtoUserValue.mode = "Invoice";
			this.lrDtoUserValue.lrNumber = this.enteredSearchBy;
			this.trackByInvoice();
		}
	}
    
    trackByInvoice() {
    	this.hireslipService.getTrackingDetailsFTHireslips(this.lrDtoUserValue).subscribe(
    			(response) => {
    				this.trackingLiveReportDataList = [];
    				if (response.length == 0) {
    					swal({
    						title: "Live Tracking Map",
    						text: "No Details found!",
    						icon: "info",
    						closeOnClickOutside: false,
    						closeOnEsc: false,
    					});

    				} else {
    					setTimeout(() => {
    					this.trackingLiveReportDataList = response;
    					this.latLatest = this.trackingLiveReportDataList[0].lat;
    				    this.lngLatest = this.trackingLiveReportDataList[0].lng;
    				    console.log("lat : "+this.latLatest+"-"+this.lngLatest);
    				    this.setInfoValues(this.trackingLiveReportDataList[0]);
    	            }, 1000);
    			}
    				this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
    			}), (error) => {
    				this.showSpinnerForAction = false;
    				swal("Error", "Server Problem Occurred While getting the details", "info");
    			}, () => console.log('done');
    }
    
    trackByLROrTripId() {
    	this.hireslipService.getTrackingDetailsFT(this.lrDtoUserValue).subscribe(
    			(response) => {
    				this.trackingLiveReportDataList = [];
    				if (response.length == 0) {
    					swal({
    						title: "Live Tracking Map",
    						text: "No Details found!",
    						icon: "info",
    						closeOnClickOutside: false,
    						closeOnEsc: false,
    					});

    				} else {
    					setTimeout(() => {
    					this.trackingLiveReportDataList = response;
    					this.latLatest = this.trackingLiveReportDataList[0].lat;
    				    this.lngLatest = this.trackingLiveReportDataList[0].lng;
    				    console.log("lat : "+this.latLatest+"-"+this.lngLatest);
    				    this.setInfoValues(this.trackingLiveReportDataList[0]);
    	            }, 1000);
    			}
    				this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
    			}), (error) => {
    				this.showSpinnerForAction = false;
    				swal("Error", "Server Problem Occurred While getting the details", "info");
    			}, () => console.log('done');
    }
    
    setInfoValues(trackingLiveReportData){
    	this.tripIdInfo = trackingLiveReportData.id;
	    this.depDateInfo = trackingLiveReportData.departuredateStr;
	    this.invNoInfo = trackingLiveReportData.invoiceNumber;
	    this.sourceInfo = trackingLiveReportData.fromStation;
	    this.destInfo = trackingLiveReportData.toStation;
	    this.vehicleInfo = trackingLiveReportData.vehicleNumber;
	    this.driverInfo = trackingLiveReportData.driverName;
	    this.driverNo = trackingLiveReportData.driverContactNum;
	    this.lastLocationInfo = trackingLiveReportData.location;
	    this.lastUpdateInfo = trackingLiveReportData.lastUpdatedDateStr;
	    this.distTravInfo = "NA";
	    this.etaInfo = trackingLiveReportData.ftEtaStr;
    }
	
	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
