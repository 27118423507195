import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
//for datepicker starts 
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//for datepicker ends 
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss 
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datepicker starts
const my = new Date();


@Component( {
    selector: 'app-lr-delivery-status',
    templateUrl: './lr-delivery-status.component.html',
    styleUrls: ['./lr-delivery-status.component.css']
} )
export class LrDeliveryStatusComponent implements OnInit {
    closeResult: string;
    unDeliveredView = false;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    deliveryDate: NgbDateStruct;
    pageId="ldsc";
    constructor( private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef ) { }
    control = new FormControl();
    reasonForReturnOptions = [
        { id: 1, label: 'ADD NEW' },
        { id: 2, label: 'Door Locked' },
        { id: 3, label: 'Vehicle Repair' },
        { id: 3, label: 'over load' }
    ];
    //for popup modal starts 
    open2( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }
    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends 
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends

    ngOnInit() {
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker



    // the below is for show and hide

    markAsMode( markAs: string ) {
        if ( markAs === 'delivered' ) {
            this.unDeliveredView = false;
        } else if
  ( markAs === 'unDelivered' ) {
            this.unDeliveredView = true;
        }
    }


}
