//comments section
//CHG-V-SHPST:01 - @Asrar chg, stock arrival logic changed, now validate from edit company and stocknotifcation table to check if its not block @23/11/2023
//CHG-V-SHPST:02 - @Asrar chg, stock arrival logic changed, send notfication only to the stocksat destination lrs.
//CHG-V-SHPST:03 - @Imran chg, Blocked whatsapp sms flow for gst detained hireslip added on 21062024

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrService } from 'src/app/dataService/lr-service';
import { UnloadingSheetDetailsComponent } from 'src/app/stock/transfer/unloading-sheet-details/unloading-sheet-details.component';
import { UserDataDto } from 'src/app/dto/UserData-dto';

@Component({
	selector: 'app-shipment-status',
	templateUrl: './shipment-status.component.html',
	styleUrls: ['./shipment-status.component.css']
})
export class ShipmentStatusComponent implements OnInit {

	goodsShortAndExtraDetailsDataList: any;
	invoiceDetailsDataList: any;
	pageId = "ssc";
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerGoodsShortAndExtraDetails: Subject<any> = new Subject();
	dtTriggerInvoiceDetails: Subject<any> = new Subject();
	dtOptionsGoodsShortAndExtraDetails: any;
	dtOptionsInvoiceDetails: any;
	address: any;
	office: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	sendStockSms: any;
	//For Lcoal Godown
	localGodownOptions: LRDto[];
	lrDtoLocalGodown: LRDto = new LRDto();
	lrDtoLocalGodownAll: LRDto = new LRDto();
	public modelLocalGodown: any;
	localGodownTA: Array<LRDto> = [];
	focusLocalGodownTA$ = new Subject<string>();
	searchLocalGodown = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusLocalGodownTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.localGodownTA
				: this.localGodownTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterLocalGodown = (x: { godownName: string }) => x.godownName;
	/////
	modalRefferenceGodownPopUp: NgbModalRef;
	modalRefferenceGodownClosePopUp: string;
	@ViewChild('confirmGodownPopUpTemplate') private confirmGodownPopUpTemplate;
	viewNewGodown = false;
	selectedGodownName: any;
	enteredInvoiceNumber: any;
	invoicedtoForSearchOtherDetails: InvoiceDto = new InvoiceDto();
	invoicedtoForSearchOtherDetailsReturn: any;
	invoicedtoForSearchOtherDetailsRet: InvoiceDto = new InvoiceDto();
	invoiceGoodsDto: InvoiceDto = new InvoiceDto();
	unloadAt = null;
	//showBtnUnload='';
	showBtnUnload = 'ShowBtnUnload ShipmentStatus';
	unblockUnldgEdit = false;
	unblockUnldg = false;
	contentPanel = '';
	lrDtoToGetLrdetails: LRDto = new LRDto();
	lrdtoInvoiceRet: any;
	lrDtosDup: Array<LRDto> = [];
	hireSlipDtoToGetShortExtra: HireSlipDto = new HireSlipDto();
	partyMasterDtoConsigneeMerge: PartyMasterDto = new PartyMasterDto();
	hireSlipsBlkForUnLoadRpt: HireSlipDto = new HireSlipDto();
	hireSlipsBlkForUnLoadRptRet: any;
	hireSlipsBlkForUnLoadRptReturn: HireSlipDto = new HireSlipDto();
	isMergedEnable = false;
	showMergedEnable = 'ShowMergeEnable ShipmentStatus';
	modalRefferenceUnloadingSheetDetailsPopUp: NgbModalRef;
	modalRefferenceUnloadingSheetDetailsClosePopUp: string;
	@ViewChild('unloadingSheetDetailsPopUpTemplate') private unloadingSheetDetailsPopUpTemplate;
	viewUnloadingSheetDetailsPopUp = false;
	updateService = false;
	lrDtoNewGodown: LRDto = new LRDto();
	validateNewGodownFieldValue: any;
	invoiceDtoUpdateShipment: InvoiceDto = new InvoiceDto();

	listOfLRNumber: Array<any> = [];
	selectedStatus: any;
	spinnerForGodown = false;
	returnMessageFinish = null;
	hireslipdtoForUnload: HireSlipDto = new HireSlipDto();
	hireSlipUnLoadRptDtlsRet: any;
	hireslipdtoForPopUp: HireSlipDto = new HireSlipDto();

	modalRefferenceConsigneeMergeDetailsPopUp: NgbModalRef;
	modalRefferenceConsigneeMergeDetailsClosePopUp: string;
	@ViewChild('consigneeMergePopUpTemplate') private consigneeMergePopUpTemplate;
	viewConsigneeMergePopUp = false
	invoiceDtoForConsigneeMergePopup: InvoiceDto = new InvoiceDto();
	sendSmsLrdto: LRDto = new LRDto();
	sendSmsLrdtoForOthers: LRDto = new LRDto();
	lrdtoForSpecificStn: LRDto = new LRDto();
	mainStn: any;
	smsStatus: any;
	lrdtoForSpecificStnRet: Array<LRDto> = [];
	lrdtoGetPrepareSMSRevisedList: Array<LRDto> = [];
	lrdtoGetPrepareSMSRevised: LRDto = new LRDto();
	lrdtoGetPrepareSMSRevisedRet: Array<LRDto> = [];
	lrdtoSendMultipltSMSMethod: LRDto = new LRDto();
	lrdtoSendMultipltSMSMethodList: Array<LRDto> = [];
	lrdtoSendMultipltSMSMethodSet: LRDto = new LRDto();

	selectedLoaderHead: any;
	selectedBayNumber: any;
	enteredBayUnloadingDate: any;
	selectedBayUnloadingTime: any;
	showNewUnloader = false;
	public modelUnloaderName: any;
	selectedUnloaderNameEvent: any;

	//unloader dropdown
	loaderHeadHireslipDtoNew: HireSlipDto = new HireSlipDto();
	loaderHeadHireslipDto: HireSlipDto = new HireSlipDto();
	loaderHeadOptions: HireSlipDto[];
	loaderHeadTA: Array<any> = [];
	focusLoaderHeadTA$ = new Subject<string>();
	loaderHeadSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusLoaderHeadTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.loaderHeadTA
				: this.loaderHeadTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterLoaderHead = (x: { loaderHead: string }) => x.loaderHead;

	//for AOC upload
	modalRefferenceAOCLRPopUp: NgbModalRef;
	modalRefferenceAOCLRClosePopUp: string;
	selectedAOCDetails: any;
	dtTriggerAocLrs: Subject<any> = new Subject();
	dtOptionsAocLrs: any;
	aocLrDataList: any;
	showSpinnerForActionForAocLrs = false;
	aocLrUploadReq = false;
	modalRefferenceAOCLRUploadPopUp: NgbModalRef;
	modalRefferenceAOCLRUploadClosePopUp: string;
	showSpinnerForActionForUpload = false;
	selectedAocLrForUpload: any;
	userDataDto: UserDataDto = new UserDataDto();

	constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private datePipe: DatePipe,
		private masterService: MasterService, private hireslipService: HireslipService, private modalService: NgbModal,
		private lrService: LrService, private consigneeService: ConsigneeService, public changeDetectorRef: ChangeDetectorRef,
		private masterReadService: MasterReadService) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.mainStn = this.userDataDtoReturnSession.mainStation == null ? '' : this.userDataDtoReturnSession.mainStation;
			this.sendStockSms = this.userDataDtoReturnSession.sendStockSms == null ? true : this.userDataDtoReturnSession.sendStockSms;
			this.getLocalGodownDetails();
			this.getLoaderHeadListDetails();
			this.disableMthod();
			console.log(this.userDataDtoReturnSession.delivDateOnArrival);

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Unblock Edit Unloading Details") {
						this.unblockUnldgEdit = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"ShowBtnUnload ShipmentStatus") {
						this.unblockUnldg = true;
					}
					/*if (this.showBtnUnload=='ShowBtnUnload ShipmentStatus') {
					  this.unblockUnldg=true;         
					  }*/

					/*if (this.showMergedEnable=='ShowMergeEnable ShipmentStatus') {
					   this.isMergedEnable=true;         
					   }*/
					// As of now isMergedEnable is default set as true as per Asrar bhai 
					this.isMergedEnable = true;
					//
					if (this.userDataDtoReturnSession.office == 'KVH') {
						this.updateService = true;
					}


				}

			}



		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	/* for datatable starts */
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}
	/* for datatable ends */


	ngOnInit(): void {
		var groupColumnDestination = 0;
		this.dtOptionsGoodsShortAndExtraDetails = {
			dom: 'Bfrtip',
			buttons: [
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var art = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var exta = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var open = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var short = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var wet = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(art);
				$(api.column(2).footer()).html(exta);
				$(api.column(3).footer()).html(open);
				$(api.column(4).footer()).html(short);
				$(api.column(5).footer()).html(wet);
				$(api.column(6).footer()).html();

			}
		},
			this.dtOptionsInvoiceDetails = {
				dom: 'Bfrtip',
				buttons: [
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 320,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"drawCallback": function (settings) {
					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
					var last = null;

					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();

					var totArt = 0;
					var actWgt = 0;
					var chgWgt = 0;
					var toPayAmt = 0;
					var paidAmt = 0;
					var count = 0;
					api.column(groupColumnDestination, {
						page: 'current'
					}).data().each(function (group, i) {

						var val = api.row(api.row($(rows).eq(i)).index()).data();
						if (last !== group) {
							if (i != 0) {
								$(rows)
									.eq(i)
									.before(
										$(
											"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
											{
												"class": "group",
												"data-id": group
											})
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": "Total : " + count
												}))
											.append($("<td></td>",
												{
													"colspan": 3,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": totArt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": actWgt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": chgWgt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": toPayAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": paidAmt
												}))
											.prop('outerHTML'));
								count = 0;
								totArt = 0;
								actWgt = 0;
								chgWgt = 0;
								toPayAmt = 0;
								paidAmt = 0;
							}
							$(rows)
								.eq(i)
								.before(
									$(
										"<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append(
											$("<td></td>",
												{
													"colspan": 9,
													"text": group
												})).prop('outerHTML'));
							last = group;
						}
						count++;
						totArt += +val[5];
						actWgt += +val[6];
						chgWgt += +val[7];
						toPayAmt += +val[8];
						paidAmt += +val[9];
						if (i == (rows.length - 1)) {
							$(rows)
								.eq(i)
								.after(
									$(
										"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": "Total : " + count
											}))
										.append($("<td></td>",
											{
												"colspan": 3,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": totArt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": actWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": chgWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": toPayAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": paidAmt
											}))
										.prop('outerHTML'));
							count = 0;
							totArt = 0;
							actWgt = 0;
							chgWgt = 0;
							toPayAmt = 0;
							paidAmt = 0;
						}
					});
				},
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var art = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actWgt = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chgWgt = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(1).footer()).html('Total : ' + data.length);
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(art);
					$(api.column(6).footer()).html(actWgt);
					$(api.column(7).footer()).html(chgWgt);
					$(api.column(8).footer()).html(toPay);
					$(api.column(9).footer()).html(paid);
				}
			},
			this.dtOptionsAocLrs = {
				dom: 'Bfrtip',
				buttons: [
				],
				searching: false,
				pagingType: 'full_numbers',
				processing: true,
				"scrollX": true,
				"scrollY": 170,
				"scrollCollapse": true,
				"paging": false,
				"info": true
			}
	}

	ngOnDestroy(): void {
		this.dtTriggerGoodsShortAndExtraDetails.unsubscribe();
		this.dtTriggerInvoiceDetails.unsubscribe();
		this.dtTriggerAocLrs.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerGoodsShortAndExtraDetails.next();
		this.dtTriggerInvoiceDetails.next();
		this.dtTriggerAocLrs.next();
	}


	getLocalGodownMethod() {
		this.lrDtoLocalGodown = new LRDto();
		this.lrDtoLocalGodown.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoLocalGodown.branch = this.userDataDtoReturnSession.office;
		this.lrDtoLocalGodown.mode = 'active';

	}
	getLocalGodownDetails() {
		this.getLocalGodownMethod();
		this.showSpinnerForAction = true;
		this.masterService.getLocalGodowns(this.lrDtoLocalGodown).subscribe(
			(response) => {
				this.localGodownOptions = [];
				this.localGodownTA = [];
				this.showSpinnerForAction = false;
				if (response) {
					if (response.length > 0) {
						this.localGodownOptions = response;
						this.localGodownTA = [];
						this.lrDtoLocalGodownAll.godownName = 'ADD NEW';
						this.localGodownTA.push(this.lrDtoLocalGodownAll);
						for (let i = 0; i < this.localGodownOptions.length; i++) {
							this.localGodownTA.push(this.localGodownOptions[i]);
						}
						window.setTimeout(function () {
							$("#" + this.pageId + "godownName").val('ADD NEW');
						}, 200);

					} else {
						this.lrDtoLocalGodownAll.godownName = '';
						this.localGodownTA.push(this.lrDtoLocalGodownAll);
						window.setTimeout(function () {
							$("#" + this.pageId + "godownName").val('');
						}, 200);
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Local Godowns Details", "info");
			},
			() => console.log('done');

	};

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}


	//For Pop up
	godownNameMethod(e: NgbTypeaheadSelectItemEvent) {
		this.modelLocalGodown = e.item;
		console.log(this.modelLocalGodown);
		this.selectedGodownName = this.modelLocalGodown.godownName;
		if (this.selectedGodownName == 'ADD NEW') {
			this.viewNewGodown = true;
			window.setTimeout(function () {
				$("#" + this.pageId + "newGodownName").focus();
			}, 100);

		} else {
			this.viewNewGodown = false;
		}

	}

	closeBtnPopup() {
		this.modalRefferenceAOCLRUploadPopUp.close();
	}
	//Get Hireslip details 
	getInvoiceDetails(e, unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate) {
		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.aocLrUploadReq = false;
		if (e.keyCode == 13) {
			if (this.enteredInvoiceNumber != null && this.enteredInvoiceNumber != '') {
				this.getInvoiceDetailsInfo(unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate);
			} else {
				swal({
					title: "Warning",
					text: "Please Enter Invoice Number",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();
					window.setTimeout(function () {
						$("#" + this.pageId + "invoiceNumber").focus();
					}, 100);
					return false;
				});
			}
		}
	}
	disableMthod() {
		console.log('hh');
		$("#" + this.pageId + "source").prop("disabled", false);
		$("#" + this.pageId + "invoiceDestination").prop("disabled", false);
		$("#" + this.pageId + "hireSlipNumber").prop("disabled", false);
		$("#" + this.pageId + "lorryNumber").prop("disabled", false);
		$("#" + this.pageId + "departureDate").prop("disabled", false);
		$("#" + this.pageId + "departureTime").prop("disabled", false);
		$("#" + this.pageId + "driverName").prop("disabled", false);
	}
	getInvoiceDetailsInfo(unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate) {
		this.onInvoiceEnteredFields();
		$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
		this.invoicedtoForSearchOtherDetails = new InvoiceDto();
		this.invoicedtoForSearchOtherDetails.invoiceNumber = this.enteredInvoiceNumber;
		this.invoicedtoForSearchOtherDetails.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.invoicedtoForSearchOtherDetails);
		this.getInvoiceDetailsService(unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate);
		this.getTableClear();
	}
	getInvoiceDetailsService = (unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate) => {
		this.showSpinnerForAction = true;
		this.hireslipService.getInvoiceDetails(this.invoicedtoForSearchOtherDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('getInvoiceDetailsService');
				console.log(response);
				this.invoicedtoForSearchOtherDetailsReturn = '';
				this.invoicedtoForSearchOtherDetailsReturn = response;
				this.invoicedtoForSearchOtherDetailsRet = new InvoiceDto();
				console.log(this.invoicedtoForSearchOtherDetailsReturn);
				this.invoicedtoForSearchOtherDetailsRet = this.invoicedtoForSearchOtherDetailsReturn;
				this.invoiceGoodsDto = new InvoiceDto();
				this.invoiceGoodsDto = this.invoicedtoForSearchOtherDetailsReturn;
				console.log(this.invoiceGoodsDto);
				this.unloadAt = this.invoicedtoForSearchOtherDetailsRet.toStation;
				console.log(this.invoicedtoForSearchOtherDetailsRet.toStation, this.userDataDtoReturnSession.mainStation, this.userDataDtoReturnSession.office);
				let toStationLowerCase = this.invoicedtoForSearchOtherDetailsRet.toStation.toLowerCase();
				let mainStationLowerCase = this.userDataDtoReturnSession.mainStation.toLowerCase();
				let officeLowerCase = this.userDataDtoReturnSession.office.toLowerCase();
				console.log(toStationLowerCase, mainStationLowerCase, officeLowerCase);
				if ((this.invoicedtoForSearchOtherDetailsRet.size == 0) || (this.invoicedtoForSearchOtherDetailsRet.status == 'Loading')) {
					swal({
						title: "LR Shipment",
						text: "Invoice Number" + this.enteredInvoiceNumber + " not found",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					//updateButton.setEnabled(false);
					$("#" + this.pageId + "updateBtn").prop("disabled", true);
					//Basha 
					/*} else if ((this.invoicedtoForSearchOtherDetailsRet.toStation != null) && (this.invoicedtoForSearchOtherDetailsRet.toStation != this.userDataDtoReturnSession.mainStation) && (this.invoicedtoForSearchOtherDetailsRet.toStation != this.userDataDtoReturnSession.office)) {*/

				} else if ((toStationLowerCase != null) && (toStationLowerCase != mainStationLowerCase) && (toStationLowerCase != officeLowerCase)) {
					swal({
						title: "LR Shipment",
						text: "Not Allowed",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

					// updateButton.setEnabled(false);
					$("#" + this.pageId + "updateBtn").prop("disabled", true);
					/*if (SRDWeb.role
									.equalsIgnoreCase(loginConstants
											.Super_Administrator())) {
								btnUnLoadRpt
										.setEnabled(true);
							} else if (unblockUnldgEdit
									&& (SRDWeb.belongsToRegion
											.equalsIgnoreCase(invoiceDto
													.getBelongsTo()))) {
								btnUnLoadRpt
										.setEnabled(true);
							}*/
					//For superadmin new right is added as  unblockUnldg
					if (this.unblockUnldg == true) {
						//btnUnLoadRpt.setEnabled(true);
						console.log('for superadmin');
						$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", false);
					} else if ((this.unblockUnldgEdit == true) && (this.userDataDtoReturnSession.belongsToRegion == this.invoicedtoForSearchOtherDetailsRet.belongsTo)) {
						//btnUnLoadRpt.setEnabled(true);
						$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", false);
					}


				} else {
					this.contentPanel = 'Invoice Details -' + this.invoicedtoForSearchOtherDetailsRet.status;
					this.aocLrUploadReq = this.invoicedtoForSearchOtherDetailsRet.isAocUploadOnStockIn
					if ((this.invoicedtoForSearchOtherDetailsRet.status) != null
						&& (this.invoicedtoForSearchOtherDetailsRet.status != 'Transit')) {
						console.log('it is not transit');
						$("#" + this.pageId + "updateBtn").prop("disabled", true);
						//updateButton.setEnabled(false);
						this.getInvoiceLrsDetails(this.invoicedtoForSearchOtherDetailsRet);

					} else {
						this.chkPendingMergeCgnee(this.invoicedtoForSearchOtherDetailsRet, unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate);
						console.log('it is  transit');
					}

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Failed To Load the Invoice Details....Please Try Again", "info");
			}, () => console.log('done');
	};


	onInvoiceEnteredFields() {
		$("#" + this.pageId + "status").val('');
		this.clearFields();
		//lrDtosDup.clear();
		this.unloadAt = null;
		this.lrDtosDup = [];
		//storeLrs.removeAll();
		//gridInvoiceList.reconfigure(storeLrs, ShipmentStatus);
		$("#" + this.pageId + "updateBtn").prop("disabled", false);
		this.contentPanel = 'Invoice Details -';
	}

	clearFields() {
		$("#" + this.pageId + "source").val('');
		$("#" + this.pageId + "invoiceDestination").val('');
		$("#" + this.pageId + "hireSlipNumber").val('');
		$("#" + this.pageId + "lorryNumber").val('');
		$("#" + this.pageId + "departureDate").val('');
		$("#" + this.pageId + "departureTime").val('');
		$("#" + this.pageId + "driverName").val('');
	}

	getTableClear() {
		$("#" + this.pageId + "invoiceDetailsId").DataTable().destroy();
		this.invoiceDetailsDataList = [];
		this.dtTriggerInvoiceDetails.next();
		$("#" + this.pageId + "goodsShortAndExtraDetailsId").DataTable().destroy();
		this.goodsShortAndExtraDetailsDataList = [];
		this.dtTriggerGoodsShortAndExtraDetails.next();
	}
	//For getting the getInvoiceLrsDetails
	getInvoiceLrsDetails(invoicedtoForSearchOtherDetailsRet) {
		$("#" + this.pageId + "source").val(invoicedtoForSearchOtherDetailsRet.fromStation);
		$("#" + this.pageId + "invoiceDestination").val(invoicedtoForSearchOtherDetailsRet.toStation);
		$("#" + this.pageId + "hireSlipNumber").val(invoicedtoForSearchOtherDetailsRet.hireSlipNumber);
		$("#" + this.pageId + "lorryNumber").val(invoicedtoForSearchOtherDetailsRet.vehicleNumber);
		$("#" + this.pageId + "departureDate").val(invoicedtoForSearchOtherDetailsRet.departuredate == null ? null : invoicedtoForSearchOtherDetailsRet.departuredate);
		$("#" + this.pageId + "departureTime").val(invoicedtoForSearchOtherDetailsRet.departureTime);
		$("#" + this.pageId + "driverName").val(invoicedtoForSearchOtherDetailsRet.driverName);

		//set unloading details
		$("#" + this.pageId + "loaderHead").val(invoicedtoForSearchOtherDetailsRet.loaderHead);
		$("#" + this.pageId + "bayNumber").val(invoicedtoForSearchOtherDetailsRet.loadingBayNo);
		$("#" + this.pageId + "bayLoadingDates").val(invoicedtoForSearchOtherDetailsRet.loadingBayDate);
		$("#" + this.pageId + "bayLoadingTime").val(invoicedtoForSearchOtherDetailsRet.loadingBayTime);

		$("#" + this.pageId + "source").prop("disabled", true);
		$("#" + this.pageId + "invoiceDestination").prop("disabled", true);
		$("#" + this.pageId + "hireSlipNumber").prop("disabled", true);
		$("#" + this.pageId + "lorryNumber").prop("disabled", true);
		$("#" + this.pageId + "departureDate").prop("disabled", true);
		$("#" + this.pageId + "departureTime").prop("disabled", true);
		$("#" + this.pageId + "driverName").prop("disabled", true);

		let retStatus = invoicedtoForSearchOtherDetailsRet.status.toLowerCase();
		if (retStatus == 'transit') {
			// nothing
		} if (retStatus == 'compound') {
			$("#" + this.pageId + "status").val('compound');
			$("#" + this.pageId + "updateBtn").prop("disabled", false);
		} else if (retStatus == 'stock') {
			$("#" + this.pageId + "status").val('stock');
			if (invoicedtoForSearchOtherDetailsRet.isUnldgDetailsFilled == true) {
				$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
			} else {
				$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", false);
			}

		}



		this.lrDtoToGetLrdetails = new LRDto();
		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.lrDtoToGetLrdetails.mode = "shipmentstatus";
		this.lrDtoToGetLrdetails.additional = "shipment";
		this.lrDtoToGetLrdetails.invoiceNumber = this.enteredInvoiceNumber;
		this.lrDtoToGetLrdetails.branch = this.userDataDtoReturnSession.office;
		this.lrDtoToGetLrdetails.branchType = this.userDataDtoReturnSession.stationType;
		this.lrDtoToGetLrdetails.destination = invoicedtoForSearchOtherDetailsRet.toStation;
		this.lrDtoToGetLrdetails.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoToGetLrdetails);

		this.getLrDetailsOfInvoice();

	}
	getLrDetailsOfInvoice = () => {
		this.showSpinnerForAction = true;
		this.lrService.getLRList(this.lrDtoToGetLrdetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "invoiceDetailsId").DataTable().destroy();
				this.invoiceDetailsDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No LR  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.invoiceDetailsDataList = response;

					this.lrDtosDup = [];
					this.lrdtoInvoiceRet = [];
					this.lrdtoInvoiceRet = response;
					this.lrDtosDup = response;

					this.getShortExtraDetails();
				}
				this.dtTriggerInvoiceDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Invoice LR details", "info");
			}, () => console.log('done');
	};
	//For getting the getShortExtraDetails
	getShortExtraDetails() {
		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.hireSlipDtoToGetShortExtra = new HireSlipDto();
		this.hireSlipDtoToGetShortExtra.hireslipnumber = this.enteredInvoiceNumber;
		this.hireSlipDtoToGetShortExtra.companyid = this.userDataDtoReturnSession.companyId;
		console.log(this.hireSlipDtoToGetShortExtra);
		this.getShortExtraInfo();
	}
	getShortExtraInfo = () => {
		this.showSpinnerForAction = true;
		this.lrService.getLRShortageDtls(this.hireSlipDtoToGetShortExtra).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "goodsShortAndExtraDetailsId").DataTable().destroy();
				this.goodsShortAndExtraDetailsDataList = [];
				console.log('getShortExtraInfo');
				console.log(response);
				if (response.length == 0) {
					/*swal({
						title: "Warning",
						text: "No  Short or Extra LR Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});*/

				} else {
					this.goodsShortAndExtraDetailsDataList = response;
					console.log(this.goodsShortAndExtraDetailsDataList);

				}
				this.dtTriggerGoodsShortAndExtraDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Short and Extra  details", "info");
			}, () => console.log('done');
	};
	//For getting the chkPendingMergeCgnee
	chkPendingMergeCgnee(invoiceDto, unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate) {
		this.partyMasterDtoConsigneeMerge = new PartyMasterDto();
		this.partyMasterDtoConsigneeMerge.invoiceNumber = this.enteredInvoiceNumber;
		this.partyMasterDtoConsigneeMerge.mode = 'specificInvoice';
		this.partyMasterDtoConsigneeMerge.office = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoConsigneeMerge.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoConsigneeMerge);
		this.chkPendingMergeCgneeInfo(invoiceDto, unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate);
	}
	chkPendingMergeCgneeInfo = (invoiceDto, unloadingSheetDetailsPopUpTemplate, consigneeMergePopUpTemplate) => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRepeatDetails(this.partyMasterDtoConsigneeMerge).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('chkPendingMergeCgneeInfo');
				console.log(response);
				if (response.length > 0 && this.isMergedEnable == true) {
					swal({
						title: "Consignee Merge",
						text: "Invoice Consignee's Required to be Merge First",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();

						this.modalRefferenceConsigneeMergeDetailsPopUp = this.modalService.open(consigneeMergePopUpTemplate,
							{ centered: true, windowClass: "myCustomModalClass" });
						this.modalRefferenceConsigneeMergeDetailsPopUp.result.then((result) => {
							this.modalRefferenceConsigneeMergeDetailsClosePopUp = `Closed with: ${result}`;
						}, reason => {
							this.modalRefferenceConsigneeMergeDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
						});
						this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
						this.invoiceDtoForConsigneeMergePopup = new InvoiceDto();
						this.invoiceDtoForConsigneeMergePopup.invoiceNumber = this.enteredInvoiceNumber;
						this.invoiceDtoForConsigneeMergePopup.mode = "shipmentstatus";
						localStorage.clear();
						localStorage.setItem('popupShipmentStatusForMerge', JSON.stringify(this.invoiceDtoForConsigneeMergePopup));
						this.viewConsigneeMergePopUp = true;
						window.addEventListener('afterPopUp', (event) => {
							this.viewConsigneeMergePopUp = false;
						});
					});



				} else {
					this.getHireSlipsBlkForUnLoadRpt(invoiceDto, unloadingSheetDetailsPopUpTemplate);
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Invoice Consignee's merge  details", "info");
			}, () => console.log('done');
	};

	closeConsigneeMergeDetailsPopUp() {
		this.modalRefferenceConsigneeMergeDetailsPopUp.close();
	}

	//For getting the getHireSlipsBlkForUnLoadRpt
	getHireSlipsBlkForUnLoadRpt(invoiceDto, unloadingSheetDetailsPopUpTemplate) {
		this.hireSlipsBlkForUnLoadRpt = new HireSlipDto();
		this.hireSlipsBlkForUnLoadRpt.toStation = invoiceDto.toStation;
		this.hireSlipsBlkForUnLoadRpt.companyid = this.userDataDtoReturnSession.companyId;
		console.log(this.hireSlipsBlkForUnLoadRpt);
		this.getHireSlipsBlkForUnLoadRptInfo(invoiceDto, unloadingSheetDetailsPopUpTemplate);
	}

	getHireSlipsBlkForUnLoadRptInfo = (invoiceDto, unloadingSheetDetailsPopUpTemplate) => {
		this.showSpinnerForAction = true;
		this.hireslipService.getHireSlipsBlkForUnLoadRpt(this.hireSlipsBlkForUnLoadRpt).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('getHireSlipsBlkForUnLoadRptInfo');
				console.log(response);
				this.hireSlipsBlkForUnLoadRptRet = '';
				this.hireSlipsBlkForUnLoadRptRet = response;
				this.hireSlipsBlkForUnLoadRptReturn = new HireSlipDto();
				this.hireSlipsBlkForUnLoadRptReturn = this.hireSlipsBlkForUnLoadRptRet;
				if (this.hireSlipsBlkForUnLoadRptReturn.status != null && (this.hireSlipsBlkForUnLoadRptReturn.status == 'Block')) {
					swal({
						title: "Shipment Status is Blocked",
						text: "Due to Unloading Report is Incomplete For HireSlip No-" + this.hireSlipsBlkForUnLoadRptReturn.invoiceNumber + ", You Have To Update",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

					swal({
						title: "Shipment Status is Blocked",
						text: "Due to Unloading Report is Incomplete For HireSlip No-" + this.hireSlipsBlkForUnLoadRptReturn.invoiceNumber + ", You Have To Update",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();

						$("#" + this.pageId + "invoiceNumber").val(this.hireSlipsBlkForUnLoadRptReturn.invoiceNumber);

						//Pending-done
						/*this.hireslipdtoForUnload=new HireSlipDto();
						this.hireslipdtoForUnload.hireslipnumber=this.enteredInvoiceNumber;
						this.hireslipdtoForUnload.companyid=this.userDataDtoReturnSession.companyId;
						this.showSpinnerForAction = true;
						this.hireslipService.getHireSlipUnLoadRptDtls(this.hireslipdtoForUnload).subscribe(
							(response) => {
								this.showSpinnerForAction = false;
								this.hireSlipUnLoadRptDtlsRet=[];
								this.hireSlipUnLoadRptDtlsRet=response;
								console.log(this.hireSlipUnLoadRptDtlsRet);
								
								
							}), (error) => {
								this.showSpinnerForAction = false;
								swal("Error", "Server Problem Occurred While getting HireSlip UnLoad Rpt Dtls Info", "info");
							}, () => console.log('done');*/
						//Popup part start
						/*this.modalRefferenceUnloadingSheetDetailsPopUp = this.modalService.open(unloadingSheetDetailsPopUpTemplate,
							{ centered: true, windowClass: "myCustomModalClass" });
						this.modalRefferenceUnloadingSheetDetailsPopUp.result.then((result) => {
							this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Closed with: ${result}`;
						}, reason => {
							this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
						});*/
						this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
						this.hireslipdtoForPopUp = new HireSlipDto();
						this.hireslipdtoForPopUp.hireslipnumber = this.enteredInvoiceNumber;
						localStorage.clear();
						localStorage.setItem('popupShipmentStatus', JSON.stringify(this.hireslipdtoForPopUp));
						const dialogRef = this.dialog.open(UnloadingSheetDetailsComponent, {

						});

						dialogRef.afterClosed().subscribe(result => {
							console.log('The dialog was closed');
							$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
							// this.router.navigate( ['.'], { relativeTo: this.route } );
						});


						/*if(this.hireSlipUnLoadRptDtlsRet !=null){
								localStorage.setItem('popupShipmentStatusDataList', JSON.stringify(this.hireSlipUnLoadRptDtlsRet));
							}*/
						/*this.viewUnloadingSheetDetailsPopUp = true;
						window.addEventListener('afterPopUp', (event) => {
							this.viewUnloadingSheetDetailsPopUp = false;
						});*/

					});

				} else if (this.hireSlipsBlkForUnLoadRptReturn.status != null && (this.hireSlipsBlkForUnLoadRptReturn.status == 'unBlock')) {
					this.getInvoiceLrsDetails(invoiceDto);
					$("#" + this.pageId + "updateBtn").prop("disabled", false);

				}
				this.changeDetectorRef.detectChanges();



			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Short and Extra  details", "info");
			}, () => console.log('done');
	};

	setUnloadingPopup(unloadingSheetDetailsPopUpTemplate, hireSlipsBlkForUnLoadRptReturn) {

		/*const dialogRef = this.dialog.open(unloadingSheetDetailsPopUpTemplate, {
		
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			console.log(result);
			if (result != null && result != undefined &&
				result == "No Trip") {
				//  this.gridReconifgureOnReloadBtn();
			}
			this.router.navigate(['.'], { relativeTo: this.route });
		});*/

		this.modalRefferenceUnloadingSheetDetailsPopUp = this.modalService.open(unloadingSheetDetailsPopUpTemplate,
			{ centered: true, windowClass: "myCustomModalClass" });
		this.modalRefferenceUnloadingSheetDetailsPopUp.result.then((result) => {
			this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});


		localStorage.clear();
		localStorage.setItem('popupShipmentStatus', JSON.stringify(hireSlipsBlkForUnLoadRptReturn));
		this.viewUnloadingSheetDetailsPopUp = true;
		window.addEventListener('afterPopUp', (event) => {
			this.viewUnloadingSheetDetailsPopUp = false;
		});

	}


	closeUnloadingSheetDetailsPopUp() {
		this.modalRefferenceUnloadingSheetDetailsPopUp.close();
		$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
	}

	//On Update Button Click
	// popup for godown is shown only if it is stock and for kvh even it is stock it wont show the godwon popup
	updateMethod(confirmGodownPopUpTemplate, aocLrPopUpTemplate) {
		this.selectedStatus = $("#" + this.pageId + "status").val();
		if (this.invoiceDetailsDataList == 0) {
			//No lrs in lr details table
			swal({
				title: "No LRs",
				text: "No LRs Found, Can not take into Stocks.Please ReEnter it ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});

		} else {
			if (this.selectedStatus == 'stock') {
				//validate for aoc lrs upload
				if (this.aocLrUploadReq) {
					this.aocLrsPopup(aocLrPopUpTemplate, confirmGodownPopUpTemplate);
				} else {
					if (this.updateService == true) {
						this.updateStatus();
					} else {
						this.modalRefferenceGodownPopUp = this.modalService.open(confirmGodownPopUpTemplate,
							{ centered: true });
						this.modalRefferenceGodownPopUp.result.then((result) => {
							this.modalRefferenceGodownClosePopUp = `Closed with: ${result}`;
						}, reason => {
							this.modalRefferenceGodownClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
						});
					}
				}
			} else {
				console.log('compound');
				this.updateStatus();
			}

		}
	}

	updateStatus() {
		console.log();

		this.selectedGodownName = $("#" + this.pageId + "godownName").val();
		if (this.selectedGodownName == 'ADD NEW') {

			this.setNewGodownNameDetails();

		} else {

			console.log('compound or other godwnName is selected');
			this.updateShipmentStatus(this.selectedGodownName);
		}
	}
	// Saving new Godown Name
	setNewGodownNameDetails() {
		this.lrDtoNewGodown = new LRDto();
		this.validateNewGodownFieldValue = $("#" + this.pageId + "newGodownName").val();
		this.lrDtoNewGodown.godownName = this.validateNewGodownFieldValue;
		this.lrDtoNewGodown.subStations = this.userDataDtoReturnSession.office;
		this.lrDtoNewGodown.userName = this.userDataDtoReturnSession.userId;
		this.lrDtoNewGodown.companyId = this.userDataDtoReturnSession.companyId;
		this.spinnerForGodown = true;
		this.masterService.addLocalGodowns(this.lrDtoNewGodown).subscribe(
			(response) => {
				console.log(response);
				this.spinnerForGodown = false;
				if (response.status == "Suceess") {

					swal({
						title: "Success",
						text: "New Godown Name Save Successfully",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {

						this.updateShipmentStatus(this.validateNewGodownFieldValue);
						$("#" + this.pageId + "newGodownName").val('');
						this.validateNewGodownFieldValue = null;
					});


				} else if (response.status == "Duplicate") {
					swal("Duplicate Entry", "Godown " + $("#" + this.pageId + "newGodownName").val() + "  Name Already Exist", "warning");
					return false;
				} else {
					swal("Failed", "Failed to save new godown name", "error");
					return false;
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.spinnerForGodown = false;
				swal("Server Error", "Problem occur while getting  addLocalGodowns", "warning");
				console.log(error.json())
			},
			() => console.log('done');
	};
	updateShipmentStatus(godownName) {
		this.selectedStatus = $("#" + this.pageId + "status").val();
		let status = '';
		this.smsStatus = '';
		if (this.selectedStatus == 'compound') {
			status = 'Compound';
			this.smsStatus = 'Compound';
		} else if (this.selectedStatus == 'stock' && this.updateService == true) {
			status = 'Stock';
			this.smsStatus = 'Stock';
		} else {
			this.modalRefferenceGodownPopUp.close();
			status = 'Stock';
			this.smsStatus = 'Stock';
		}

		swal({
			title: "Shipment Status",
			text: "Sure U want to move this invoice to " + status,
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((update) => {
			if (update) {
				this.updateShipmentStatusMethod(godownName, status);

			} else {
				//do nothing
			}
		});

	}
	updateShipmentStatusMethod(godownName, status) {

		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();

		if (this.showNewUnloader) {
			this.selectedLoaderHead = $("#" + this.pageId + "newUnloader").val();
		} else {
			this.selectedLoaderHead = $("#" + this.pageId + "loaderHead").val();
		}
		this.selectedBayNumber = $("#" + this.pageId + "bayNumber").val();
		let enterBayUnloadingDate = $("#" + this.pageId + "bayLoadingDates").val();
		this.enteredBayUnloadingDate = this.datePipe.transform(enterBayUnloadingDate, "yyyy-MM-dd");
		this.selectedBayUnloadingTime = $("#" + this.pageId + "bayLoadingTime").val();

		this.listOfLRNumber = [];
		for (let i = 0; i < this.invoiceDetailsDataList.length; i++) {
			this.listOfLRNumber.push(this.invoiceDetailsDataList[i].lrNumber);
		}
		this.selectedStatus = $("#" + this.pageId + "status").val();
		let lrStatus = '';
		if (this.selectedStatus == 'compound') {
			lrStatus = 'Compound';
		} else if (this.selectedStatus == 'stock') {
			lrStatus = 'Pending';
		}
		if (this.selectedStatus == '' || this.selectedStatus == null ||
			this.selectedLoaderHead == '' || this.selectedLoaderHead == null ||
			this.selectedBayNumber == '' || this.selectedBayNumber == null ||
			this.enteredBayUnloadingDate == '' || this.enteredBayUnloadingDate == null ||
			this.selectedBayUnloadingTime == '' || this.selectedBayUnloadingTime == null) {
			swal({
				title: "Mandatory Field",
				text: "Please Select the Mandatory(*) fields ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		}
		else {
			this.invoiceDtoUpdateShipment = new InvoiceDto();
			this.invoiceDtoUpdateShipment.hireSlipNumber = this.enteredInvoiceNumber;
			this.invoiceDtoUpdateShipment.invoiceNumber = this.enteredInvoiceNumber;
			this.invoiceDtoUpdateShipment.list = this.listOfLRNumber;
			this.invoiceDtoUpdateShipment.status = lrStatus;
			this.invoiceDtoUpdateShipment.stocksAt = this.userDataDtoReturnSession.office;
			this.invoiceDtoUpdateShipment.branch = godownName;
			this.invoiceDtoUpdateShipment.stationType = this.userDataDtoReturnSession.stationType;
			this.invoiceDtoUpdateShipment.mainStation = this.userDataDtoReturnSession.mainStation;
			this.invoiceDtoUpdateShipment.lastupdatedby = this.userDataDtoReturnSession.office;
			this.invoiceDtoUpdateShipment.delivDateOnArrival = this.userDataDtoReturnSession.delivDateOnArrival;
			this.invoiceDtoUpdateShipment.companyId = this.userDataDtoReturnSession.companyId;



			this.invoiceDtoUpdateShipment.loadingBayNo = this.selectedBayNumber;
			this.invoiceDtoUpdateShipment.loadingBayDate = this.enteredBayUnloadingDate;
			this.invoiceDtoUpdateShipment.loadingBayTime = this.selectedBayUnloadingTime;
			this.invoiceDtoUpdateShipment.loaderHead = this.selectedLoaderHead;

			console.log(this.invoiceDtoUpdateShipment);
			this.setUpdateShipmentInfo(status);
		}

	}

	setUpdateShipmentInfo = (status) => {
		this.showSpinnerForAction = true;
		console.log(this.invoiceDtoUpdateShipment);
		this.hireslipService.updateShipmentStatus(this.invoiceDtoUpdateShipment).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				if (response.status == 'Success') {
					this.clearFields();
					$("#" + this.pageId + "status").val('');
					$("#" + this.pageId + "invoiceNumber").val('');
					$("#" + this.pageId + "invoiceNumber").focus();
					$("#" + this.pageId + "updateBtn").prop("disabled", true);
					this.contentPanel = 'Invoice Details';
					//Pending
					//LRShortage.gridReconfigure();		
					//TransitTrips.gridReconfigure();
					this.getTableClear();
					this.invoiceDetailsDataList = [];
					this.selectedGodownName = ''
					$("#" + this.pageId + "godownName").val('');
					this.validateNewGodownFieldValue = '';
					$("#" + this.pageId + "newGodownName").val('');
					this.viewNewGodown = false;
					this.showNewUnloader = false;
					this.selectedUnloaderNameEvent = null;

					this.selectedLoaderHead = null;
					this.selectedBayNumber = null;
					this.enteredBayUnloadingDate = null;
					this.selectedBayUnloadingTime = null;

					$("#" + this.pageId + "bayNumber").val('');
					$("#" + this.pageId + "bayLoadingDates").val('');
					$("#" + this.pageId + "bayLoadingTime").val('');
					$("#" + this.pageId + "newUnloader").val('');
					$("#" + this.pageId + "loaderHead").val('');
					this.aocLrUploadReq = false;

					this.getLoaderHeadListDetails();

					swal({
						title: "Success",
						text: "Lrs Successfully Moved to  " + status,
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					console.log('sms');

					// if (this.sendStockSms) {
					// 	console.log('sms:1');
					// 	//validate if the invoice is stop to send sms/whatsapp notification @05/09/2022
					// 	if (this.invoicedtoForSearchOtherDetailsRet.blockArrvNotification == false) {
					// 		this.sentSmsFlow();
					// 	}
					// }

					//CHG-V-SHPST:01
					console.log(this.invoiceGoodsDto);
					//CHG-V-SHPST:03
					if (!this.invoiceGoodsDto.isGSTDetained) {
						this.validateSendStockNotification();
					}


				} else if (response.status == 'NotExist') {

					swal({
						title: "Not Exist ",
						text: "Invoice not Exist",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					swal({
						title: "Server Error ",
						text: "Problem Occured While Updating Records",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Updating Shipment Status", "info");
			}, () => console.log('done');
	};

	updateBtn() {
		this.selectedGodownName = $("#" + this.pageId + "godownName").val();
		this.validateNewGodownFieldValue = $("#" + this.pageId + "newGodownName").val();
		if (this.selectedGodownName == '' || this.selectedGodownName == null) {
			swal({
				title: "Mandatory Field",
				text: "Please Select the Godown ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else if (this.selectedGodownName == 'ADD NEW' && (this.validateNewGodownFieldValue == null || this.validateNewGodownFieldValue == '')) {
			swal({
				title: "Mandatory Field",
				text: "Please Enter  the Godown ",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;

		} else {
			this.updateStatus();
		}

	}
	//To get Hireslip Unloading lr Details
	unLoadMethod(unloadingSheetDetailsPopUpTemplate) {
		//Popup part start
		/*	this.modalRefferenceUnloadingSheetDetailsPopUp = this.modalService.open(unloadingSheetDetailsPopUpTemplate,
				{ centered: true, windowClass: "myCustomModalClass" });
			this.modalRefferenceUnloadingSheetDetailsPopUp.result.then((result) => {
				this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Closed with: ${result}`;
			}, reason => {
				this.modalRefferenceUnloadingSheetDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
			});
			this.enteredInvoiceNumber = $("#"+this.pageId+"invoiceNumber").val();
			this.hireslipdtoForPopUp=new HireSlipDto();
			this.hireslipdtoForPopUp.hireslipnumber=this.enteredInvoiceNumber;
			localStorage.clear();
			localStorage.setItem('popupShipmentStatus', JSON.stringify(this.hireslipdtoForPopUp));
		/*	if(this.hireSlipUnLoadRptDtlsRet !=null){
				localStorage.setItem('popupShipmentStatusDataList', JSON.stringify(this.hireSlipUnLoadRptDtlsRet));
			}
			this.viewUnloadingSheetDetailsPopUp = true;
			window.addEventListener('afterPopUp', (event) => {
				this.viewUnloadingSheetDetailsPopUp = false;
			});*/
		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.hireslipdtoForPopUp = new HireSlipDto();
		this.hireslipdtoForPopUp.hireslipnumber = this.enteredInvoiceNumber;
		localStorage.clear();
		localStorage.setItem('popupShipmentStatus', JSON.stringify(this.hireslipdtoForPopUp));
		const dialogRef = this.dialog.open(UnloadingSheetDetailsComponent, {

		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
			// this.router.navigate( ['.'], { relativeTo: this.route } );
		});


		console.log(this.hireSlipUnLoadRptDtlsRet);
	}
	clearAll() {
		this.lrDtosDup = [];
		this.lrdtoInvoiceRet = [];
		this.enteredInvoiceNumber = '';
		this.selectedGodownName = '';
		$("#" + this.pageId + "invoiceNumber").val('');
		this.disableMthod();
		$("#" + this.pageId + "status").val('');
		$("#" + this.pageId + "invoiceNumber").val('');
		$("#" + this.pageId + "invoiceNumber").focus();
		$("#" + this.pageId + "updateBtn").prop("disabled", true);
		this.contentPanel = 'Invoice Details';
		this.getTableClear();
		this.invoiceDetailsDataList = [];
		this.selectedGodownName = ''
		$("#" + this.pageId + "godownName").val('');
		this.validateNewGodownFieldValue = '';
		$("#" + this.pageId + "newGodownName").val('');
		this.viewNewGodown = false;
		this.listOfLRNumber = [];
		this.showSpinnerForAction = false;
		this.hireslipdtoForPopUp = new HireSlipDto();
		localStorage.clear();
		$("#" + this.pageId + "btnUnLoadRpt").prop("disabled", true);
		this.invoiceGoodsDto = new InvoiceDto();
		this.invoicedtoForSearchOtherDetailsReturn = '';
		this.invoicedtoForSearchOtherDetailsRet = new InvoiceDto();
		this.unloadAt = null;
		this.lrDtoToGetLrdetails = new LRDto();
		this.clearFields();
		this.hireSlipDtoToGetShortExtra = new HireSlipDto();
		this.partyMasterDtoConsigneeMerge = new PartyMasterDto();
		this.invoiceDtoForConsigneeMergePopup = new InvoiceDto();
		this.hireSlipsBlkForUnLoadRpt = new HireSlipDto();
		this.hireSlipsBlkForUnLoadRptRet = '';
		this.hireSlipsBlkForUnLoadRptReturn = new HireSlipDto();
		this.selectedStatus = '';
		this.lrDtoNewGodown = new LRDto();
		this.invoiceDtoUpdateShipment = new InvoiceDto();
		this.sendSmsLrdto = new LRDto();
		this.sendSmsLrdtoForOthers = new LRDto();
		this.lrdtoForSpecificStnRet = [];
		this.lrdtoForSpecificStn = new LRDto();
		this.lrdtoGetPrepareSMSRevised = new LRDto();
		this.lrdtoGetPrepareSMSRevisedRet = [];
		this.lrdtoSendMultipltSMSMethodList = [];
		this.lrdtoGetPrepareSMSRevisedList = [];
		this.lrdtoSendMultipltSMSMethodSet = new LRDto();

	}
	//For SMS Flow
	sentSmsFlow() {
		console.log('inside sentSmsFlow');
		if (this.smsStatus != null && this.smsStatus != '' && this.smsStatus == 'Stock') {
			if (this.office != null && this.office == 'Binnymills') {
				this.smsFlowForBinny();
			} else {
				this.smsFlowForOtherStn();
			}

		}
	}
	//Sms Flow For Binny
	smsFlowForBinny() {
		console.log('Inside smsFlowForBinny');
		this.sendSmsLrdto = new LRDto();
		this.sendSmsLrdto.city = 'Chennai';
		this.sendSmsLrdto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.sendSmsLrdto);
		this.getSmsStatusMethodForBinny();
	}

	getSmsStatusMethodForBinny = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getSmsStatus(this.sendSmsLrdto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('Inside getSmsStatusMethodForBinny');
				console.log(response);
				let smsFlowForBinny = response;
				if (smsFlowForBinny.sendMsg == true) {
					console.log('binnySMS True');
					this.specificStationSMS();
				} else {

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting Sms Status ", "info");
			}, () => console.log('done');
	}
	smsFlowForOtherStn() {
		console.log('Inside smsFlowForOtherStn');
		this.sendSmsLrdtoForOthers = new LRDto();
		if (this.mainStn != null && this.mainStn == 'Chennai') {
			this.sendSmsLrdtoForOthers.city = 'Chennai';
		} else {
			this.sendSmsLrdtoForOthers.city = this.userDataDtoReturnSession.mainStation;
		}
		this.sendSmsLrdtoForOthers.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.sendSmsLrdtoForOthers);
		this.getSmsStatusMethodForOthers();
	}
	getSmsStatusMethodForOthers = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getSmsStatus(this.sendSmsLrdtoForOthers).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('Inside getSmsStatusMethodForOthers');
				console.log(response);
				let smsFlowForOthers = response;
				if (smsFlowForOthers.sendMsg == true) {
					this.getPrepareSMSRevised(this.lrDtosDup);
				} else {

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting Sms Status ", "info");
			}, () => console.log('done');
	}

	specificStationSMS() {
		console.log('Inside specificStationSMS');
		console.log(this.enteredInvoiceNumber);
		//this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.lrdtoForSpecificStn = new LRDto();
		this.lrdtoForSpecificStn.mode = 'ForGD';
		this.lrdtoForSpecificStn.areaOfDelivery = 'BINNY GODOWN DELIVERY';
		this.lrdtoForSpecificStn.invoiceNumber = this.enteredInvoiceNumber;
		this.lrdtoForSpecificStn.companyId = this.userDataDtoReturnSession.companyId;
		this.specificStationSMSInfo();
		console.log(this.lrdtoForSpecificStn);
	}
	specificStationSMSInfo = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getPartiesForSMS(this.lrdtoForSpecificStn).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('Inside specificStationSMSInfo');
				console.log(response);
				this.lrdtoForSpecificStnRet = [];
				this.lrdtoForSpecificStnRet = response;
				if (this.lrdtoForSpecificStnRet.length > 0) {
					this.getPrepareSMSRevised(this.lrdtoForSpecificStnRet);

				} else {

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting specific Station SMS Info ", "info");
			}, () => console.log('done');
	};

	getPrepareSMSRevised = (lrdtoForPrepareSmsRevised) => {
		console.log('Inside of getPrepareSMSRevised');
		this.showSpinnerForAction = true;
		this.lrdtoGetPrepareSMSRevisedList = [];
		for (let i = 0; i < lrdtoForPrepareSmsRevised.length; i++) {
			//CHG-V-SHPST:02
			let mainStation = this.userDataDtoReturnSession.mainStation.toLowerCase();
			let destination = lrdtoForPrepareSmsRevised[i].destination.toLowerCase();
			console.log(mainStation + " - " + destination);
			if (mainStation == destination) {
				this.lrdtoGetPrepareSMSRevised = new LRDto();
				this.lrdtoGetPrepareSMSRevised.agentId = lrdtoForPrepareSmsRevised[i].agentId;
				this.lrdtoGetPrepareSMSRevised.source = lrdtoForPrepareSmsRevised[i].source;
				this.lrdtoGetPrepareSMSRevised.destination = lrdtoForPrepareSmsRevised[i].destination;
				this.lrdtoGetPrepareSMSRevised.lrNumber = lrdtoForPrepareSmsRevised[i].lrNumber;
				this.lrdtoGetPrepareSMSRevised.totalArticles = lrdtoForPrepareSmsRevised[i].totalArticles;
				this.lrdtoGetPrepareSMSRevised.consignorName = lrdtoForPrepareSmsRevised[i].consignorName;
				this.lrdtoGetPrepareSMSRevised.mobileNum = lrdtoForPrepareSmsRevised[i].mobileNum;
				this.lrdtoGetPrepareSMSRevised.bookingDate = lrdtoForPrepareSmsRevised[i].bookingDate;
				this.lrdtoGetPrepareSMSRevised.companyId = this.userDataDtoReturnSession.companyId;
				this.lrdtoGetPrepareSMSRevised.consignorMobileNo = lrdtoForPrepareSmsRevised[i].consignorMobileNo;
				this.lrdtoGetPrepareSMSRevisedList.push(this.lrdtoGetPrepareSMSRevised);
			}
		}
		console.log(this.lrdtoGetPrepareSMSRevisedList);
		this.lrService.getETAForTrip(this.lrdtoGetPrepareSMSRevisedList).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('Inside getPrepareSMSRevised');
				console.log(response);
				this.lrdtoGetPrepareSMSRevisedRet = [];
				this.lrdtoGetPrepareSMSRevisedRet = response;
				this.sendMultipltSMSMethod(this.lrdtoGetPrepareSMSRevisedRet);
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting Sms Status ", "info");
			}, () => console.log('done');
	};
	sendMultipltSMSMethod = (lrdtoFromGetPrepareSMSRevisedRet) => {
		this.lrdtoSendMultipltSMSMethodList = [];
		console.log('Inside of sendMultipltSMSMethod');
		console.log(lrdtoFromGetPrepareSMSRevisedRet);
		for (let i = 0; i < lrdtoFromGetPrepareSMSRevisedRet.length; i++) {
			this.lrdtoSendMultipltSMSMethod = new LRDto();
			this.lrdtoSendMultipltSMSMethod.message = lrdtoFromGetPrepareSMSRevisedRet[i].message;
			this.lrdtoSendMultipltSMSMethod.mobileNum = lrdtoFromGetPrepareSMSRevisedRet[i].mobileNum;

			this.lrdtoSendMultipltSMSMethod.lrNumber = lrdtoFromGetPrepareSMSRevisedRet[i].lrNumber;
			this.lrdtoSendMultipltSMSMethod.totalArticles = lrdtoFromGetPrepareSMSRevisedRet[i].totalArticles;
			this.lrdtoSendMultipltSMSMethod.source = lrdtoFromGetPrepareSMSRevisedRet[i].source;
			this.lrdtoSendMultipltSMSMethod.destination = lrdtoFromGetPrepareSMSRevisedRet[i].destination;
			this.lrdtoSendMultipltSMSMethod.remarks = lrdtoFromGetPrepareSMSRevisedRet[i].remarks;
			this.lrdtoSendMultipltSMSMethod.consignorName = lrdtoFromGetPrepareSMSRevisedRet[i].consignorName;
			this.lrdtoSendMultipltSMSMethod.consignorMobileNo = lrdtoFromGetPrepareSMSRevisedRet[i].consignorMobileNo;
			this.lrdtoSendMultipltSMSMethodList.push(this.lrdtoSendMultipltSMSMethod);
		}
		this.lrdtoSendMultipltSMSMethodSet = new LRDto();
		this.lrdtoSendMultipltSMSMethodSet.lrDtos = this.lrdtoSendMultipltSMSMethodList;
		this.lrdtoSendMultipltSMSMethodSet.companyId = this.userDataDtoReturnSession.companyId;
		this.lrdtoSendMultipltSMSMethodSet.credentials = this.userDataDtoReturnSession.credentials;
		//console.log(this.lrdtoSendMultipltSMSMethodSet);
		//14/04/2023 - started
		console.log('sendWhatsAppMsg');
		this.showSpinnerForAction = true;
		this.sendWhatsAppMsg();

		//stop sms since whatsapp already started @25/04/2023
		// this.hireslipService.sendMultipltSMS(this.lrdtoSendMultipltSMSMethodSet).subscribe(
		// 	(response) => {
		// 		this.showSpinnerForAction = false;
		// 		console.log('Inside getPrepareSMSRevised');
		// 		console.log(response);
		// 		let sendMultipltSMSMethodRet = response;
		// 		console.log(sendMultipltSMSMethodRet.status);
		// 		if (sendMultipltSMSMethodRet.status == 'send') {
		// 			this.lrDtosDup = [];
		// 			this.unloadAt = '';
		// 			this.smsStatus = '';
		// 		} else {
		// 			this.lrDtosDup = [];
		// 			this.unloadAt = '';
		// 			this.smsStatus = '';

		// 		}
		// 		this.changeDetectorRef.detectChanges();
		// 		//temp stop due to whatsapp issue @08/02/2023
		// 		//14/04/2023 - started
		// 		if (this.invoicedtoForSearchOtherDetailsRet.blockArrvNotification == false) {
		// 			this.sendWhatsAppMsg();
		// 		}

		// 	}), (error) => {
		// 		this.showSpinnerForAction = false;
		// 		swal("Error", "Server Problem Occurred While getting Send Multiple sms Status ", "info");
		// 	}, () => console.log('done');
	}

	sendWhatsAppMsg() {
		this.hireslipService.sendGoodsArrivalWhatsApp(this.lrdtoSendMultipltSMSMethodSet).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log('Inside sendWhatsAppMsg');
				this.lrDtosDup = [];
				this.unloadAt = '';
				this.smsStatus = '';
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While sendWhatsAppMsg ", "info");
			}, () => console.log('done');
	}

	getLoaderHeadList() {
		this.loaderHeadHireslipDto = new HireSlipDto();
		this.loaderHeadHireslipDto.branch = this.userDataDtoReturnSession.mainStation;
		this.loaderHeadHireslipDto.companyid = this.userDataDtoReturnSession.companyId;
	}

	getLoaderHeadListDetails = () => {
		this.getLoaderHeadList();
		this.masterReadService.getLoaderHeadsDetails(this.loaderHeadHireslipDto).subscribe(
			(response) => {
				this.loaderHeadOptions = [];
				this.loaderHeadOptions = response;

				this.loaderHeadTA = [];
				this.loaderHeadHireslipDtoNew = new HireSlipDto();
				this.loaderHeadHireslipDtoNew.loaderHead = "Add New"
				this.loaderHeadOptions.push(this.loaderHeadHireslipDtoNew);

				for (let i = 0; i < this.loaderHeadOptions.length; i++) {
					this.loaderHeadTA.push(this.loaderHeadOptions[i]);
				}

				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	selectedUnloader(e: NgbTypeaheadSelectItemEvent) {
		//alert('ok');
		this.modelUnloaderName = e.item;
		this.selectedUnloaderNameEvent = this.modelUnloaderName.loaderHead;
		//alert(this.modelUnloaderName.loaderHead);
		if (this.selectedUnloaderNameEvent != null && this.selectedUnloaderNameEvent == "Add New") {
			this.showNewUnloader = true;
		} else {
			this.showNewUnloader = false;
		}
	}

	aocLrsPopup(aocLrPopUpTemplate, confirmGodownPopUpTemplate) {
		this.lrDtoToGetLrdetails = new LRDto();
		this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
		this.lrDtoToGetLrdetails.invoiceNumber = this.enteredInvoiceNumber;
		this.lrDtoToGetLrdetails.companyId = this.userDataDtoReturnSession.companyId;
		this.getAocLrDetailsOfInvoice(aocLrPopUpTemplate, confirmGodownPopUpTemplate);
	}

	getAocLrDetailsOfInvoice = (aocLrPopUpTemplate, confirmGodownPopUpTemplate) => {
		this.showSpinnerForActionForAocLrs = true;
		this.lrService.getAOCLRList(this.lrDtoToGetLrdetails).subscribe(
			(response) => {
				this.showSpinnerForActionForAocLrs = false;
				$("#" + this.pageId + "aocLrsId").DataTable().destroy();
				this.aocLrDataList = [];

				if (response.length == 0) {
					if (this.updateService == true) {
						this.updateStatus();
					} else {
						this.modalRefferenceGodownPopUp = this.modalService.open(confirmGodownPopUpTemplate,
							{ centered: true });
						this.modalRefferenceGodownPopUp.result.then((result) => {
							this.modalRefferenceGodownClosePopUp = `Closed with: ${result}`;
						}, reason => {
							this.modalRefferenceGodownClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
						});
					}
					if (this.modalRefferenceAOCLRPopUp != null) {
						this.modalRefferenceAOCLRPopUp.close();
					}
				} else {
					this.aocLrDataList = response;
					console.log('popup');
					console.log(this.modalRefferenceAOCLRPopUp);

					if (this.modalRefferenceAOCLRPopUp == null) {
						this.modalRefferenceAOCLRPopUp = this.modalService.open(aocLrPopUpTemplate,
							{ centered: true });
						this.modalRefferenceAOCLRPopUp.result.then((result) => {
							this.modalRefferenceAOCLRClosePopUp = `Closed with: ${result}`;
						}, reason => {
							this.modalRefferenceAOCLRClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
						});
					}
				}
				this.dtTriggerAocLrs.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the AOC LR details", "info");
			}, () => console.log('done');
	};

	getImg(aocLrData, aocLrUploadPopUpTemplate) {

		// this.truckNumberUpload = '';
		// this.panNumberUpload = '';
		// this.financialYear = this.userDataDtoReturnSession.financialYearSplit;

		// this.truckNumberUpload = truckOwnerData.vehicleNumber;
		// this.fileName = '';
		// // fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
		// if ($("#" + this.pageId + "uploadBy").val() == "uploadByPanNumber") {
		// 	this.fileName = this.panNumberUpload + "_" + this.financialYear;
		// } else {
		// 	this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
		// }
		// console.log(this.fileName);
		// console.log(this.financialYear);
		this.selectedAocLrForUpload = aocLrData.lrNumber;
		console.log(this.selectedAocLrForUpload);
		this.modalRefferenceAOCLRUploadPopUp = this.modalService.open(aocLrUploadPopUpTemplate,
			{ centered: true });
		this.modalRefferenceAOCLRUploadPopUp.result.then((result) => {
			this.modalRefferenceAOCLRUploadClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceAOCLRUploadClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	onFileChangedAocLr(event) {
		this.selectedAOCDetails = event.target.files[0];
		console.log(this.selectedAOCDetails);
		console.log(event.target.files.length);
		console.log(event.target.files[1]);
	}

	uploadBtnPopUp(aocLrPopUpTemplate, confirmGodownPopUpTemplate) {
		console.log($("#" + this.pageId + "aocLrFileUpload").val());
		if ($("#" + this.pageId + "aocLrFileUpload").val() != null && $("#" + this.pageId + "aocLrFileUpload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			console.log(this.selectedAOCDetails.type);
			if ((this.selectedAOCDetails.type != null &&
				(this.selectedAOCDetails.type == "image/jpeg" ||
					this.selectedAOCDetails.type == "image/jpg" ||
					this.selectedAOCDetails.type == "image/png" ||
					this.selectedAOCDetails.type == "application/pdf"))) {
				var splitType = this.selectedAOCDetails.type.split("/");
				const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&" + this.selectedAocLrForUpload + "&&" + splitType[1] + "&&" + this.selectedAocLrForUpload + "";
				const formData = new FormData();
				formData.append(commonListAdvancePayment, commonListAdvancePayment);
				formData.append('aoclruplaod', this.selectedAOCDetails, this.selectedAOCDetails.name);

				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							console.log(response);
							console.log(response[0].url);
							this.showSpinnerForActionForUpload = false;
							if (response[0] != "Error") {
								swal("File Upload", "File Uploaded Succcessfully", "success");
								this.updateAocLrUploadService(response[0].url, aocLrPopUpTemplate, confirmGodownPopUpTemplate);
							} else {
								swal("ERROR", "Correct the File and Upload Again", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}
	}

	closeUploadBtnPopUp() {
		this.modalRefferenceAOCLRUploadPopUp.close();
		$("#" + this.pageId + "aocLrFileUpload").val('');
		this.selectedAOCDetails = null;
		this.showSpinnerForActionForUpload = false;
		this.selectedAocLrForUpload = null;
	}

	closeBtnAocPopUp() {
		//alert('ok');
		this.modalRefferenceAOCLRPopUp.close();
		this.modalRefferenceAOCLRPopUp == null
	}

	updateAocLrUploadService(url, aocLrPopUpTemplate, confirmGodownPopUpTemplate) {
		this.lrDtoToGetLrdetails = new LRDto();
		this.lrDtoToGetLrdetails.lrNumber = this.selectedAocLrForUpload;
		this.lrDtoToGetLrdetails.userName = this.userDataDtoReturnSession.userId;
		this.lrDtoToGetLrdetails.name = url;
		this.masterReadService.updateAocLrUploadStatus(this.lrDtoToGetLrdetails).subscribe(
			(response) => {
				this.closeUploadBtnPopUp();
				this.aocLrsPopup(aocLrPopUpTemplate, confirmGodownPopUpTemplate);
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While updating the AOC LR details", "info");
			}, () => console.log('done');
	}

	//CHG-V-SHPST:01
	//false means not blocked
	validateSendStockNotification() {
		console.log('validateSendStockNotification:1');
		this.userDataDto = new UserDataDto();
		this.userDataDto.city = this.userDataDtoReturnSession.mainStation;
		this.userDataDto.invoiceNumber = this.invoicedtoForSearchOtherDetailsRet.hireSlipNumber;

		this.masterService.validateStockArrivalNotificationSend(this.userDataDto).subscribe(
			(response) => {
				console.log(response.sendStockSms);
				if (response.sendStockSms == false) {
					this.sentSmsFlow();
				}
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While sending the arrival notifications!", "erro");
			}, () => console.log('done');


	}
}
