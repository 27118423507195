<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Agent Tempo Master</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<!-- <h6 class="card-title">Tempo Details</h6> -->
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Truck Number*</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-truck"></i>
													</span>
												</div>
												<input #truckNumber (keyup)="fieldFocus($event, ownerName)"
													id="{{pageId}}truckNumber" name="truckNumber" type="text"
													(keyup)="checkTempoNumber($event)" class="form-control"
													aria-describedby="basic-addon11" #numberTender="ngModel"
													[(ngModel)]="truckDataDto.truckNumber" minlength="9"
													pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{9,}$" required /> <small
													[hidden]="numberTender.valid" class="errorLabel">
													Please Enter the alphanumeric value. </small>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Owner Name</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input #ownerName (keyup)="fieldFocus($event,panNumber)"
													type="text" class="form-control" id="{{pageId}}ownerName"
													name="ownerName" aria-describedby="basic-addon11"
													[(ngModel)]="truckDataDto.ownerName">
											</div>
										</div>
									</div>

									<!-- 											the first autocomplete starts -->

									<!-- 											the first autocomplete ends -->

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>PAN(Enter PAN as AAAAA9999A, if you don't
													have PAN)</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-id-card"></i>
													</span>
												</div>
												<input #panNumber
													(keyup)="fieldFocus($event, truckCapacityInTones)"
													type="text" class="form-control" id="{{pageId}}panNumber"
													name="panNumber" aria-describedby="basic-addon11"
													[(ngModel)]="truckDataDto.panNo">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Truck Capacity (In Tones)*</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-truck"></i>
													</span>
												</div>
												<input #truckCapacityInTones
													(keyup)="fieldFocus($event, saveBtn)" type="number"
													class="form-control" id="{{pageId}}truckCapacityInTones"
													name="truckCapacityInTones"
													aria-describedby="basic-addon11"
													[(ngModel)]="truckDataDto.truckCapcityDouble">
											</div>
										</div>
									</div>
								</div>
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" #saveBtn style="text-align: center;">
									<button #saveBtn type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}saveBtn" (click)="validateAgentTempoDetails();">Save</button>
									<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
										(click)="clearAll()">Clear</button>
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}deleteBtn" (click)="validateDeleteRow()">Delete</button>
								</div>
							</div>


							<div class="col-md-9 vl">
								<div class="row">
									<!-- column -->
									<div class="col-md-12">
										<div class="card">
											<div class="card-body">
												<div class="row p-t-10">
													<div class="col-md-8">
														<h6 class="card-title">Agent Tempo Master</h6>
													</div>
													<div class="col-md-4">
														<button type="submit" class="btn btn-primary m-r-10"
															style="padding: 1px 4px;" id="{{pageId}}getDetailsBtn"
															(click)="getAgentTempoMasterDetailsList()">
															<i class="fas fa-file-alt"></i> Get Details
														</button>
													</div>
												</div>

												<div class="row">
													<div class="col-md-12">
														<div class="box-body">
															<table datatable
																class="table table-striped table-bordered row-border hover"
																[dtOptions]="dtOptionsAgentTempoMaster"
																[dtTrigger]="dtTriggerAgentTempoMaster">

																<thead>
																	<tr>
																		<th>Truck Number</th>
																		<th>Owner Name</th>
																		<th>PAN Number</th>
																		<th>Truck Capacity</th>

																	</tr>
																</thead>
																<tbody>
																	<tr
																		*ngFor="let agentTempoMasterData of agentTempoMasterDataList "
																		(click)="rowSelected(agentTempoMasterData);">
																		<td>{{ agentTempoMasterData.truckNumber }}</td>
																		<td>{{ agentTempoMasterData.ownerName }}</td>
																		<td>{{ agentTempoMasterData.panNo }}</td>
																		<td>{{ agentTempoMasterData.truckCapacity }}</td>
																	</tr>
																	<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

																</tbody>
																<!-- 											<tfoot> -->
																<!-- 												<tr> -->
																<!-- 													<td style="text-align: left">Total</td> -->
																<!-- 													<td></td> -->
																<!-- 													<td></td> -->
																<!-- 													<td></td> -->
																<!-- 													<td></td> -->
																<!-- 													<td></td> -->
																<!-- 													<td></td> -->
																<!-- 												</tr> -->
																<!-- 											</tfoot> -->
															</table>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
