<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Booking Cashmemo By Invoice</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group">
													<label>From Date</label> <input id="{{pageId}}fromDate"
														class="form-control" placeholder="yyyy-mm-dd"
														name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="fromDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDate"
														class="form-control" placeholder="yyyy-mm-dd"
														name="toDates" ngbDatepicker #toDates="ngbDatepicker">
													<div class="input-group-append" (click)="toDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<!-- <div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}city">
														<label>City</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Agent" [formControl]="controlMain"
															[appAutocomplete]="autocompleteCity">
													</div>
												</div>
											</div>

											<app-autocomplete #autocompleteCity="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(cityOptions | filter: controlMain.value) as resultCity">
											<app-option *ngFor="let option of resultCity"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultCity.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div> -->
										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
													</div>
													<input id="{{pageId}}fromStation" type="text"
														class="form-control" [(ngModel)]="modelFromStation"
														[ngbTypeahead]="searchFromStation"
														[resultFormatter]="formatterFromStation"
														[inputFormatter]="formatterFromStation"
														(focus)="focusFromStationTA$.next($any($event).target.value)"
														placeholder="Select From Station" />


												</div>
											</div>
										</div>
										<div class="col-md-3" style="text-align: center;">
											<button type="submit" class="btn btn-success m-r-10"
												id="{{pageId}}searchBtn" (click)="gridReconfigure();">Search</button>
											<button type="submit" class="btn btn-dark"
												id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-12 vl p-t-10">
					<div class="card-body" style="border-top: 1px solid orange;">
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<table datatable id="{{pageId}}bkgCashmemoByInvoiceTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsBkgCashMemoInvoiceReport"
								[dtTrigger]="dtTriggerBkgCashMemoInvoiceReport">

								<thead>
									<tr>
										<th>Invoice No</th>
										<th>Cashmemo</th>
										<th>LR Number</th>
										<th>Entered Date</th>
										<th>Consignor Name</th>
										<th>Consignee Name</th>
										<th>Source</th>
										<th>Destination</th>
										<th>Paid Amt</th>
										<th>RC</th>
										<th>SC</th>
										<th>D.Hamali</th>
										<th>Act Wgt</th>
										<th>Chg Wgt</th>
										<th>S.Total</th>
										<th>Less Amt</th>
										<th>Total Amt</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let bkgCashMemoInvoiceReportData of bkgCashmemoByInvReportDataList ">
										<td>{{ bkgCashMemoInvoiceReportData.invoiceNumber }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.memoNumber }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.lrNumber }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.date }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.consignorName }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.consigneeName }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.source }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.destination }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.frieghtAmt }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.receiptCharge }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.serviceCharge }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.destHamali }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.actualWeight }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.chargedWeight }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.subTotal }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.discountAmount }}</td>
										<td>{{ bkgCashMemoInvoiceReportData.totalAmount }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>

				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>