
<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"(click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
<div class="row">
	<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
		<div class="form-group">
			<div class="input-group">
				<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
				<br>
				<h6 class="card-title" align='center'
					style="color: green; margin: auto; font-size: 15px;">Please
					Wait Loading....</h6>
			</div>
		</div>
	</div>
	<div class="col-md-12 col-sm-12">
		<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
			<div class="col-md-10">
				<h6 class="card-title">
					<i class=" fas fa-file-alt"></i>&nbsp;Expenses OS Payment Followup
				</h6>
		
			</div>
			<div class="col-md-2">
			<i title="Reload" class="fas fa-sync-alt fa xs cli"
				style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
				(click)="gridReconifgureOnReloadBtn()"></i>
			</div>
		</div>
	</div>
</div>
<div class="box-body">
	<table datatable id="{{pageId}}expensesOsPaymentFollowupId"
		class="table table-striped table-bordered row-border hover"
		[dtOptions]="dtOptionsExpensesOsPaymentFollowup"
		[dtTrigger]="dtTriggerExpensesOsPaymentFollowup">

		<thead>
			<tr>
				<th>Bill No</th>
				<th>Date</th>
				<th>Vendor Name</th>
				<th>Bill Amt</th>
				<th>Created By</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let expensesOsPaymentFollowupData of expensesOsPaymentFollowupDataList">
				
				<td>
						<a style="color:red;cursor: pointer;"
							(click)="expensesRpt(expensesOsPaymentFollowupData)">
							<u>{{ expensesOsPaymentFollowupData.billNumber}}</u></a>
					</td>
				<td>{{ expensesOsPaymentFollowupData.billDateStr }}</td>
				<td>{{ expensesOsPaymentFollowupData.supplierName }}</td>
				<td>{{  expensesOsPaymentFollowupData.grandTotal==null ?0: expensesOsPaymentFollowupData.grandTotal }}</td>
				<td>{{ expensesOsPaymentFollowupData.userName }}</td>
			</tr>
		</tbody>
	</table>
</div>
</div>