<!-- Row -->
<div class="row system_responsive">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Hire Details By Deatination</h6>
            </div>
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <!-- <h6 class="card-title">Tempo Details</h6> -->
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}month">
                                                    <label>Month</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-calendar-alt"></i>
														</span>
                                                    </div>
                                                    <input class="auto_selectOption input is-medium" placeholder="Select Month" [formControl]="controlMonth" [appAutocomplete]="autocompleteMonth">
                                                </div>
                                            </div>
                                        </div>

                                        <app-autocomplete #autocompleteMonth="appAutocomplete">
                                            <ng-template appAutocompleteContent>
                                                <ng-container *ngIf="(monthOptions | filter: controlMonth.value) as resultMonth">
                                                    <app-option *ngFor="let option of resultMonth" [value]="option.label"> {{ option.label }} </app-option>
                                                    <app-option class="no-result" *ngIf="!resultMonth.length">No result
                                                    </app-option>
                                                </ng-container>
                                            </ng-template>
                                        </app-autocomplete>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}monthPart">
                                                <label>Month Part</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class=" fas fa-calendar-alt"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12">
													<option selected>Select Month Part...</option>
													<option value="firstHalf">First Half</option>
													<option value="secondHalf">Second Half</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}source">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input class="auto_selectOption input is-medium" placeholder="Select Source" [formControl]="controlSource" [appAutocomplete]="autocompleteSource">
                                                </div>
                                            </div>
                                        </div>

                                        <app-autocomplete #autocompleteSource="appAutocomplete">
                                            <ng-template appAutocompleteContent>
                                                <ng-container *ngIf="(sourceOptions | filter: controlSource.value) as resultSource">
                                                    <app-option *ngFor="let option of resultSource" [value]="option.label"> {{ option.label }} </app-option>
                                                    <app-option class="no-result" *ngIf="!resultSource.length">No result
                                                    </app-option>
                                                </ng-container>
                                            </ng-template>
                                        </app-autocomplete>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>

                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <h6 class="card-title border_bottom">Hire Details By Deatination
                                </h6>
                                <div class="box-body">
                                    <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsHireDetailsByDestination" [dtTrigger]="dtTriggerHireDetailsByDestination">

                                        <thead>
                                            <tr>
                                                <th>Destination</th>
                                                <th>Freight</th>
                                                <th>Hire</th>
                                                <th>Gur Wt</th>
                                                <th>Kan Wt</th>
                                                <th>Act Wt</th>
                                                <th>Chrg Wt</th>
                                                <th>Frt/Kg</th>
                                                <th>Hire/Kg</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let hireDetailsByDestinationData of hireDetailsByDestinationDataList ">
                                                <td>{{ hireDetailsByDestinationData.destination }}</td>
                                                <td>{{ hireDetailsByDestinationData.freight }}</td>
                                                <td>{{ hireDetailsByDestinationData.hire }}</td>
                                                <td>{{ hireDetailsByDestinationData.gurWt }}</td>
                                                <td>{{ hireDetailsByDestinationData.kanWt }}</td>
                                                <td>{{ hireDetailsByDestinationData.actWt }}</td>
                                                <td>{{ hireDetailsByDestinationData.chrgWt }}</td>
                                                <td>{{ hireDetailsByDestinationData.frtPerKg }}</td>
                                                <td>{{ hireDetailsByDestinationData.hirePerKg }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>