<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}officeHireslipRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Office Hireslip Report</h6>
            </div>
            <div class="row " style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Search By</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
														<option selected value="destinationWise">Destination
															Wise</option>
														<option value="allDestination">All Destination</option>
														<option value="monthlyWise">Monthly Wise</option>
														<option value="summaryReport">Summary Report</option>
														<option value="multiDeliveryWise">Multiple Point Delivery Wise
														</option>

													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showFromDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="showToDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div *ngIf="sourceView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                        [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="sourceAdminView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                        placeholder="Select Source" />
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="searchByDestinationWise" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showYear" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Financial Year</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input #yearlyIndex id="{{pageId}}yearlyIndex" name="yearlyIndex" type="text" class="form-control" placeholder="Select Financial Year" [ngbTypeahead]="yearlyIndexSearchTA" (focus)="focusYearlyIndexTA$.next($any($event).target.value)" (click)="clickTA(instanceYearlyIndex)"
                                                        #instanceYearlyIndex="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                    </div>
                </div>

                <div class="col-md-9 vl p-t-10">
                    <div class="box-body">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-10">
                            <div class="col-md-8">
                                <h6 class="card-title" [hidden]="!showForSingleDestWise">Booking Hireslip Report - Destination Wise
                                </h6>
                                <h6 class="card-title" [hidden]="!showForMultiDestWise">Booking Hireslip Report - Multiple Point Delivery Destination Wise
                                </h6>
                                <h6 class="card-title" [hidden]="!searchByAllDestinationTable">Booking Hireslip Report - All Destination Report
                                </h6>
                                <h6 class="card-title" [hidden]="!searchByMonthlyWiseTable">Booking Hireslip Report - Monthly Wise
                                </h6>
                                <h6 class="card-title" [hidden]="!searchBySummaryReportTable">Booking Hireslip Report - Summary Wise
                                </h6>



                            </div>
                            <div class="col-md-4">

                                <button type="submit" class="dt-button" [hidden]="!showForSingleDestWise" style="margin-left: 75%;" id="{{pageId}}printBtnForSingleDest" (click)="pritnDestinationWise()">
									<span><i class="fas fa-print">Print</i></span>
								</button>
                                <button type="submit" class="dt-button" [hidden]="!showForMultiDestWise" id="{{pageId}}printBtnForMultiDest" style="margin-left: 75%;" (click)="pritnMultiDestinationWise()">
									<span><i class="fas fa-print">Print</i></span>
								</button>
                                <button type="submit" class="dt-button" [hidden]="!searchByAllDestinationTable" style="margin-left: 75%;" id="{{pageId}}printBtnForAlDest" (click)="pritnAllDestinationWise()">
									<span><i class="fas fa-print">Print</i></span>
								</button>
                                <button type="submit" class="dt-button" [hidden]="!searchByMonthlyWiseTable" id="{{pageId}}printBtnForMonthlyDest" style="margin-left: 75%;" (click)="pritnMonthlyWise()">
									<span><i class="fas fa-print">Print</i></span>
								</button>
                                <button type="submit" class="dt-button" [hidden]="!searchBySummaryReportTable" id="{{pageId}}printBtnForSummary1" (click)="pritnSummary1Wise()">
									<span><i class="fas fa-print">Print Summary 1</i></span>
								</button>
                                <button type="submit" class="dt-button" [hidden]="!searchBySummaryReportTable" id="{{pageId}}printBtnForSummary2" (click)="pritnSummary2Wise()">
									<span><i class="fas fa-print">Print Summary 2</i></span>
								</button>


                            </div>
                        </div>
                        <div class="box-body">
                            <div [hidden]="!searchByDestinationWiseTable">
                                <table datatable id="{{pageId}}searchByDestinationWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestinationWise" [dtTrigger]="dtTriggerDestinationWise">
                                    <thead>
                                        <tr>
                                            <th [hidden]=true>Destination</th>
                                            <th>Invoice Number</th>
                                            <th>Date</th>
                                            <th>Truck No.</th>
                                            <th>Truck LGT</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>Freight</th>
                                            <th [hidden]="!showHireColumns">Tot Hire</th>
                                            <th [hidden]="!showHireColumns">Advance</th>
                                            <th>Gur Wt</th>
                                            <th>Kanta Wt</th>
                                            <th>Actual Wt</th>
                                            <th>Charged Wt</th>
                                            <th>Broker</th>
                                            <th>Truck Type</th>
                                            <th>Loading Incharge</th>
                                            <th>Loader Head</th>
                                            <th>Loader Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let destinationWiseData of destinationWiseDataList ">
                                            <td [hidden]=true>{{ destinationWiseData.twoPtGrouping }}</td>
                                            <td>{{ destinationWiseData.hireslipnumber }}</td>
                                            <td>{{destinationWiseData.hireSlipDateStr}}</td>
                                            <td>{{ destinationWiseData.vehicleNumber }}</td>
                                            <td>{{ destinationWiseData.trkLgth }}</td>
                                            <td>{{ destinationWiseData.toPay }}</td>
                                            <td>{{ destinationWiseData.paid }}</td>
                                            <td>{{ destinationWiseData.totalAmt }}</td>
                                            <td [hidden]="!showHireColumns">{{ destinationWiseData.totalhire }}</td>
                                            <td [hidden]="!showHireColumns">{{ destinationWiseData.advance }}</td>
                                            <td>{{destinationWiseData.gurWeight}}</td>
                                            <td>{{ destinationWiseData.kantaWeight }}</td>
                                            <td>{{ destinationWiseData.actWeight }}</td>
                                            <td>{{ destinationWiseData.chargedWt }}</td>
                                            <td>{{ destinationWiseData.suppliername }}</td>
                                            <td style="cursor: pointer; color: blue;text-decoration: underline;" (click)="open2(truckTypeUpdatePopup,destinationWiseData)">{{ destinationWiseData.truckTypeCommon }}</td>
                                            <td>{{ destinationWiseData.inchargeName }}</td>
                                            <td>{{ destinationWiseData.loaderHead }}</td>
                                            <td>{{ destinationWiseData.loadedby }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td [hidden]=true></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!searchByAllDestinationTable">
                                <table datatable id="{{pageId}}searchByAllDestinationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAllDestination" [dtTrigger]="dtTriggerAllDestination">
                                    <thead>
                                        <tr>
                                            <th>Destination</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>FREIGHT</th>
                                            <th [hidden]="!showHireColumns">HIRE</th>
                                            <th [hidden]="!showHireColumns">ADVANCE</th>
                                            <th>Gur Wt</th>
                                            <th>Kanta Wt</th>
                                            <th>Actual Wt</th>
                                            <th>Charged Wt</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let allDestinationData of allDestinationDataList ">
                                            <td>{{ allDestinationData.toStation }}</td>
                                            <td>{{allDestinationData.toPay}}</td>
                                            <td>{{ allDestinationData.paid }}</td>
                                            <td>{{ allDestinationData.totalAmt }}</td>
                                            <td [hidden]="!showHireColumns">{{ allDestinationData.totalhire }}</td>
                                            <td [hidden]="!showHireColumns">{{ allDestinationData.advance }}</td>
                                            <td>{{ allDestinationData.gurWeight }}</td>
                                            <td>{{ allDestinationData.kantaWeight }}</td>
                                            <td>{{ allDestinationData.actWeight }}</td>
                                            <td>{{allDestinationData.chargedWt}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!searchByMonthlyWiseTable">
                                <table datatable id="{{pageId}}searchByMonthlyWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMonthlyWise" [dtTrigger]="dtTriggerMonthlyWise">

                                    <thead>
                                        <tr>
                                            <th>Source</th>
                                            <th>Month</th>
                                            <th>ToPay Freight</th>
                                            <th>Paid Freight</th>
                                            <th>Total Freight</th>
                                            <th [hidden]="!showHireColumns">Total Hire</th>
                                            <th [hidden]="!showHireColumns">Advance</th>
                                            <th>Gur Wt</th>
                                            <th>Kanta Wt</th>
                                            <th>Actual Wt</th>
                                            <th>Charged Wt</th>
                                            <th>Destination</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let monthlyWiseData of monthlyWiseDataList ">
                                            <td>{{monthlyWiseData.fromStation}}</td>
                                            <td>{{monthlyWiseData.monthName}}</td>
                                            <td>{{monthlyWiseData.toPay==null ?0 :monthlyWiseData.toPay}}</td>
                                            <td>{{ monthlyWiseData.paid==null ?0 :monthlyWiseData.paid }}</td>
                                            <td>{{ monthlyWiseData.totalAmt==null ?0 :monthlyWiseData.totalAmt }}</td>
                                            <td [hidden]="!showHireColumns">{{ monthlyWiseData.totalhire==null ?0 :monthlyWiseData.totalhire }}</td>
                                            <td [hidden]="!showHireColumns">{{ monthlyWiseData.advance==null ?0 :monthlyWiseData.advance }}</td>
                                            <td>{{ monthlyWiseData.gurWeight==null ?0 :monthlyWiseData.gurWeight }}</td>
                                            <td>{{ monthlyWiseData.kantaWeight==null ?0 :monthlyWiseData.kantaWeight }}</td>
                                            <td>{{ monthlyWiseData.actWeight==null ?0 :monthlyWiseData.actWeight }}</td>
                                            <td>{{ monthlyWiseData.chargedWt==null ?0 :monthlyWiseData.chargedWt }}</td>
                                            <td>{{monthlyWiseData.toStation}}</td>

                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!searchBySummaryReportTable">
                                <table datatable id="{{pageId}}searchBySummaryReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummaryReport" [dtTrigger]="dtTriggerSummaryReport">

                                    <thead>
                                        <tr>
                                            <th>Destination</th>
                                            <th>To Pay</th>
                                            <th>Actual Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let summaryReportData of summaryReportDataList ">
                                            <td>{{ summaryReportData.fromStation }}</td>
                                            <td>{{summaryReportData.toPay}}</td>
                                            <td>{{ summaryReportData.actWeight }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="box-body">
                            <div [hidden]="!searchBySummaryReportTable">
                                <table datatable id="{{pageId}}searchBySummaryReportTable2" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummaryReport2" [dtTrigger]="dtTriggerSummaryReport2">

                                    <thead>
                                        <tr>
                                            <th>Destination</th>
                                            <th [hidden]="!showHireColumns">Hire</th>
                                            <th>Kanta Weight</th>
                                            <th>Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let summaryReport2Data of summaryReport2DataList ">
                                            <td>{{ summaryReport2Data.toStation }}</td>
                                            <td [hidden]="!showHireColumns">{{summaryReport2Data.totalhire}}</td>
                                            <td>{{ summaryReport2Data.kantaWeight }}</td>
                                            <td>{{ summaryReport2Data.paid }}</td>

                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td [hidden]="!showHireColumns"></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<ng-template #truckTypeUpdatePopup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Change Truck Type For Invoice Number {{setInvoiceNo}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <label>Updated Truck Type</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i
                                        class="fas fa-boxes"></i>
                                </span>
                            </div>
                            <input id="{{pageId}}truckType" type="text" class="form-control" (selectItem)="clickListnerForTruckType($event)" [(ngModel)]="modelTruckType" [ngbTypeahead]="searchTruckType" [resultFormatter]="formatterTruckType" [inputFormatter]="formatterTruckType"
                                (focus)="focusTruckTypeTA$.next($any($event).target.value)" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12">
                    <button type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="updateTruckTypeBtn()">Update</button>
                    <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>
<div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>