<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}generateNewEWBForm">
    <div class="col-lg-6 col-md-6 vl">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row ">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12">
                    <h4 style="font-size: 16px;">Consignor Details</h4>
                    <div class="form-group">
                        <div class="input-group">
                            <label>Consignor Gst No</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                </span>
                            </div>
                            <input type="text" id="{{pageId}}consignorGstNumber" (keyup)="validateForGstNo('consignor',$event);" class="form-control" aria-describedby="basic-addon11">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 vl p-t-10">

                    <div class="row ">
                        <div class="col-md-12">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Trade Name</label>

                                        <span style="font-size: 12px;">{{ consignorTradeName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Legal Name</label>

                                        <span style="font-size: 12px;">{{ consignorLegalName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Address</label>

                                        <span style="font-size: 12px;">{{ consignorAddress1 }},{{ consignorAddress2 }}{{
                                            consignorPincode ? ', ' + consignorPincode : '' }}</span>
                                        <!-- <label style="font-size: 14px;" *ngIf="consignorShowStateCode">State Code :
                                        </label>
                                        <span style="font-size: 12px;" *ngIf="consignorShowStateCode">{{
                                            consignorStateCode }}</span> -->
                                        <!-- <h6 style="font-size: 12px;" *ngIf="consignorShowStateCode">State Code : {{
                                            consignorStateCode }} -->
                                        <!-- </h6> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;" *ngIf="consignorShowStateCode">State Code :
                                        </label>

                                        <span style="font-size: 12px;" *ngIf="consignorShowStateCode">{{
                                            consignorStateCode }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <div class="col-sm-12 col-md-12">
                    <h4 style="font-size: 16px;">Consignee Details</h4>
                    <div class="form-group">
                        <div class="input-group">
                            <label>Consignee Gst No*</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                </span>
                            </div>
                            <input type="text" id="{{pageId}}consigneeGstNumber" (keyup)="validateForGstNo('consignee',$event);" class="form-control" aria-describedby="basic-addon11">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 vl p-t-10">

                    <div class="row ">
                        <div class="col-md-12">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Trade Name* : </label>

                                        <span style="font-size: 12px;">{{ consigneeTradeName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Legal Name *: </label>

                                        <span style="font-size: 12px;">{{ consigneeLegalName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;">Address* : </label>

                                        <span style="font-size: 12px;">{{ consigneeAddress1 }},{{ consigneeAddress2 }}{{
                                            consigneePincode ? ', ' + consigneePincode : '' }}</span>
                                        <!-- <label style="font-size: 14px;" *ngIf="consigneeShowStateCode">State Code :
                                        </label> -->
                                        <!-- <span style="font-size: 12px;" *ngIf="consigneeShowStateCode">{{
                                            consigneeStateCode }}</span> -->
                                        <!-- <h6 style="font-size: 12px;" *ngIf="consigneeShowStateCode">State Code : {{ consigneeStateCode }}
                                        </h6> -->

                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label style="font-size: 14px;" *ngIf="consigneeShowStateCode">State Code* :
                                        </label>

                                        <span style="font-size: 12px;" *ngIf="consigneeShowStateCode">{{
                                            consigneeStateCode }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-lg-6 col-md-6 vl">
        <h6 class="card-title">Consignment Details</h6>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Doc No*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                        </span>
                    </div>
                    <input #billNo id="{{pageId}}billNo" (keyup)="fieldFocus($event, billDate)" type="text" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Doc Date*</label>
                    <div class="input-group-append" (click)="billDates.toggle()">
                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                        </span>
                    </div>

                    <input class="form-control" name="billDate" id="{{pageId}}billDate" [(ngModel)]="tdyBillDate" ngbDatepicker (keydown.arrowdown)="billDates.toggle();" (click)="billDates.toggle()" #billDates="ngbDatepicker">

                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Commodity Name*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-box"></i>
                        </span>
                    </div>
                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Commodity Description*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class=" fas fa-file-alt"></i>
                        </span>
                    </div>
                    <input #subCommodity id="{{pageId}}subCommodity" (keyup)="fieldFocus($event, actualWeight)" type="text" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>No.Of Articles*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-clone"></i>
                        </span>
                    </div>
                    <input #noOfArticles id="{{pageId}}noOfArticles" type="number" (keyup)="fieldFocus($event, hsnCode)" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>HSN Code*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                        </span>
                    </div>
                    <input #hsnCode id="{{pageId}}hsnCode" (keyup)="fieldFocus($event, goodsValue)" type="text" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Goods Value*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input #goodsValue id="{{pageId}}goodsValue" (keyup)="fieldFocus($event,reason)" type="number" class="form-control" aria-describedby="basic-addon11" (blur)="grandSum()">
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Reason*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                        </span>
                    </div>
                    <input #reason id="{{pageId}}reason" (keyup)="fieldFocus($event, billDate)" type="text" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>


        <h6 class="card-title">GST Details</h6>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>CGST*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input type="number" id="{{pageId}}cgstPerc" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getCgstPerc" (blur)="grandSum()">&nbsp; <input type="number" id="{{pageId}}cgstAmt" class="form-control" aria-describedby="basic-addon11"
                        [(ngModel)]="getCgstAmt" disabled>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>SGST*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input type="number" id="{{pageId}}sgstPerc" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getSgstPerc" (blur)="grandSum()">&nbsp; <input type="number" id="{{pageId}}sgstAmt" class="form-control" aria-describedby="basic-addon11"
                        [(ngModel)]="getSgstAmt" disabled>
                </div>
            </div>
        </div>


        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>IGST*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input type="number" id="{{pageId}}igstPerc" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getIgstPerc" (blur)="grandSum()">&nbsp; <input type="number" id="{{pageId}}igstAmt" class="form-control" aria-describedby="basic-addon11"
                        [(ngModel)]="getIgstAmt" disabled>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>CESS Advol*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input type="number" id="{{pageId}}cessPerc" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getCessPerc" (blur)="grandSum()">&nbsp; <input type="number" id="{{pageId}}cessAmt" class="form-control" aria-describedby="basic-addon11"
                        [(ngModel)]="getCessAmt" disabled>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>CESS Non Advol*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input type="number" id="{{pageId}}nonAdvolCessPerc" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getNonAdvolCessPerc" (blur)="grandSum()">&nbsp;
                    <input type="number" id="{{pageId}}nonAdvolCessAmt" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="getNonAdvolCessAmt" disabled>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <label>Other Inv Amount*</label>
                    <div class="input-group-prepend">
                        <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                        </span>
                    </div>
                    <input #otherInvAmount id="{{pageId}}otherInvAmount" (keyup)="fieldFocus($event,reason)" type="number" class="form-control" aria-describedby="basic-addon11">
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}resetBtn" (click)="clearfields();">Reset</button>
        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}genEwayBillBtn" #nextBtn (click)="confirmGenerateEwayBill()">Generate EWB</button>
    </div>
</div>