import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';

export interface DialogData {
    listOfDataToPass: string[];
}
@Component({
    selector: 'app-mless-check-popup',
    templateUrl: './mless-check-popup.component.html',
    styleUrls: ['./mless-check-popup.component.css']
})
export class MlessCheckPopupComponent implements OnInit {


    userDataDtoReturnSession: any;

    lrDetailsDataList: any;
    list: string[];
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    mlessPerc: any;
    mlessAmt: any;
    selectedStmtNo: string;
    showSpinnerForAction = false;
    stmtTotalLrAmt: any;
    mPerc: any;
    mPercAmt: any;
    pageId = "mcpcs";

    constructor(private destStmtReport: DestStmtReport, public dialogRef: MatDialogRef<MlessCheckPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.selectedStmtNo = localStorage.getItem('mLessStmtNo');

    }

    ngOnInit(): void {
        this.getMLessDetails();
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    confirmSave() {
        swal({
            title: "Confirmation",
            text: "Sure you want to update the M(%) less details to all the LRs in this statement?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.updateMLessDetails();
            }
        })
    }

    updateMLessUpdateUserDetails() {
        this.mlessPerc = $("#" + this.pageId + "mPerc").val();
        this.mlessAmt = $("#" + this.pageId + "mLessAmt").val();

        this.fortNightStmtDto = new FortNightStmtDto();
        this.fortNightStmtDto.stmtNo = this.selectedStmtNo;
        this.fortNightStmtDto.mLessPrec = this.mlessPerc;
        this.fortNightStmtDto.mLessAmt = this.mlessAmt;
        this.fortNightStmtDto.updatedBy = this.userDataDtoReturnSession.userId;
    }

    updateMLessDetails() {
        this.updateMLessUpdateUserDetails();
        this.showSpinnerForAction = true;
        this.destStmtReport.updateMPercStmtLessDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("M-Less Check", "Less updation completed successfully!", "info");
                } else {
                    swal("M-Less Check", "Failed to update the Less details!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to update the Less details!", "error");
            },
            () => console.log('done');
    }

    getMLessUpdateUserDetails() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.fortNightStmtDto.stmtNo = this.selectedStmtNo;
        this.fortNightStmtDto.mode = "StmtNo";
    }

    getMLessDetails() {
        this.getMLessUpdateUserDetails();
        this.showSpinnerForAction = true;
        this.destStmtReport.getDestStmtDetails(this.fortNightStmtDto).subscribe(
            (response) => {

                if (response.size > 0) {
                    $("#" + this.pageId + "toPay").val(response.totalAmount);
                    $("#" + this.pageId + "mPerc").val(response.mLessPrec);
                    $("#" + this.pageId + "mLessAmt").val(response.mLessAmt);
                } else {
                    swal("M-Less Check", "No M-Less details found!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to get the M-Less details!", "error");
            },
            () => console.log('done');
    }

    mLessEvent(e) {
        if (e.keyCode == 13) {
            //this.calculateMPercLess();
            this.calculateMPercLessV2();
        }
    }

    mLessBlur(e) {
        //this.calculateMPercLess();
        this.calculateMPercLessV2();
    }

    calculateMPercLess() {
        this.stmtTotalLrAmt = $("#" + this.pageId + "toPay").val();
        this.mPerc = $("#" + this.pageId + "mPerc").val();
        this.mPercAmt = (this.stmtTotalLrAmt * this.mPerc) / 100;
        $("#" + this.pageId + "mLessAmt").val(Math.round(this.mPercAmt));
    }

    calculateMPercLessV2() {
        this.stmtTotalLrAmt = $("#" + this.pageId + "toPay").val();
        this.mPercAmt = $("#" + this.pageId + "mLessAmt").val();
        this.mPerc = (this.mPercAmt / this.stmtTotalLrAmt) * 100;
        $("#" + this.pageId + "mPerc").val(this.mPerc);
    }
}
