import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { ConsigneeService } from 'src/app/dataService/consignee-service';

export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-update-consignee-admin-rate-for-all',
	templateUrl: './update-consignee-admin-rate-for-all.component.html',
	styleUrls: ['./update-consignee-admin-rate-for-all.component.css']
})
export class UpdateConsigneeAdminRateForAllComponent implements OnInit {

	gridLRIssueDetailsList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLRIssueDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsLRIssueDetails: any;
	pageId = "upcac";


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	//popUpOpenLRIssueDetailsData: any;
	//lrDto: LRDto = new LRDto();

	dtOptionsPartyRate: any;
	dtTriggerPartyRate: Subject<any> = new Subject();
	rateMasterDtoOnLoadGridReconfi: RateMasterDto = new RateMasterDto();
	validateGridReconfDestination: any;
	partyRateDataList: any;

	dtOptionsSource: any;
	dtTriggerSource: Subject<any> = new Subject();
	sourceDataList: any;


	lrDtoDestinationPopUp: LRDto = new LRDto();
	destinationOptionsPopUp: LRDto[];
	public modelDestinationPopUp: any;
	destinationPopUpTA: Array<LRDto> = [];
	focusDestinationPopUpTA$ = new Subject<string>();
	searchDestinationPopUp = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationPopUpTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationPopUpTA
				: this.destinationPopUpTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestinationPopUp = (x: { destination: string }) => x.destination;

	sourceOptionsPopUp: AgentDetailsDto[];
	sourceAgentDtoPopUp: AgentDetailsDto = new AgentDetailsDto();
	lrDtoSourceAllOptionPopUp: AgentDetailsDto = new AgentDetailsDto();
	public modelSourcePopUp: any;
	sourcePopUpTA: Array<AgentDetailsDto> = [];
	focusSourcePopUpTA$ = new Subject<string>();
	searchSourcePopUp = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourcePopUpTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourcePopUpTA
				: this.sourcePopUpTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

		);
	}
	formatterSourcePopUp = (x: { subStation: string }) => x.subStation;


	rateMasterDtoPopUp: RateMasterDto = new RateMasterDto();
	rateMasterDtoOptionsPopUp: RateMasterDto[];
	rateMasterDtoCommodityListAllOptionPopUp: RateMasterDto = new RateMasterDto();
	public modelCommodityListPopUp: any;
	commodityListPopUpTA: Array<RateMasterDto> = [];
	focusCommodityListPopUpTA$ = new Subject<string>();
	searchCommodityListPopUp = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityListPopUpTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.commodityListPopUpTA
				: this.commodityListPopUpTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityListPopUp = (x: { commodityName: string }) => x.commodityName;

	selectedDestinationForCommodityPopUp: any;
	rateChangeTxtId = 'Rate';

	viewPercentageFieldPopUp: boolean = false;
	viewRateFieldPopUp: boolean = true;
	viewPerUnitFieldPopUp: boolean = true;

	rateMasterDtoGetUserValPopUp: RateMasterDto = new RateMasterDto();

	validateSelectedUpdateModePopUp: any;
	validateSelectedDestinationPopUp: any;
	validateSelectedSourcePopUp: any;
	validateSelectedCommodityPopUp: any;
	validateSelectedUnitPopUp: any;
	validateSelectedRatesFixedDisTextPopUp: any;
	validateSelectedRatesFixedDisNumberPopUp: any;
	validateSelectedUnitPercTextPopUp: any;
	validateSelectedUnitPercRateNumberPopUp: any;
	validateSelectedPerUnitPopUp: any;
	validateSelectedFixedByPopUp: any;
	validateSelectedEffectedFromPopUp: any;
	validateSelectedFixedOnPopUp: any;

	lrDtoListSourceChecked: Array<LRDto> = [];
	private newAttributeSetSourceList: any = {};

	lrDtoListConsigneeChecked: Array<LRDto> = [];
	private newAttributeSetConsigneeList: any = {};

	listSource: Array<String> = [];
	listConsigneeId: Array<Number> = [];

	constructor(private memoLessRpt: ReportService, private router: Router,
		private mastereadService: MasterReadService, private memoReport: MemoReport,
		private lrService: LrService, private consigneeService: ConsigneeService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<UpdateConsigneeAdminRateForAllComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			//this.popUpOpenLRIssueDetailsData = JSON.parse(localStorage.getItem('LRIssueBalRptPopUp'));
			//localStorage.clear();

			this.getDestinationDetails();
			this.getSourceDetails();
			this.gridReconfigureSource();
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsPartyRate = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 100,
			"scrollCollapse": true,
			"paging": false,
			"info": true
		},
			this.dtOptionsSource = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 100,
				"scrollCollapse": true,
				"paging": false,
				"info": true
			}

	}


	ngOnDestroy(): void {
		this.dtTriggerPartyRate.unsubscribe();
		this.dtTriggerSource.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerPartyRate.next();
		this.dtTriggerSource.next();
	}

	clickListnerForDestinationPopUpList(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationPopUp = e.item;
		$("#" + this.pageId + "destinationPopUpId").val(this.modelDestinationPopUp.destination);
		this.getCommodityMasterDetailsList();
		this.gridReconfigureForParty();
	}

	clickListnerForSourcePopUp(e: NgbTypeaheadSelectItemEvent) {
		this.modelSourcePopUp = e.item;
		$("#" + this.pageId + "sourcePopUp").val(this.modelSourcePopUp.subStation);
	}

	clickListnerForCommodityListPopUp(e: NgbTypeaheadSelectItemEvent) {
		this.modelCommodityListPopUp = e.item;
		$("#" + this.pageId + "commodityListPopUpId").val(this.modelCommodityListPopUp.commodityName);
	}

	getDestinationMethod() {
		this.lrDtoDestinationPopUp = new LRDto();
		this.lrDtoDestinationPopUp.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestinationPopUp.mode = "LrForm";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.mastereadService.getDestinationForLREntryService(this.lrDtoDestinationPopUp)
			.subscribe((response) => {
				this.showSpinnerForAction = false;
				if (response.length > 0) {
					this.destinationOptionsPopUp = [];
					this.destinationOptionsPopUp = response;
					this.destinationPopUpTA = [];
					for (let i = 0; i < this.destinationOptionsPopUp.length; i++) {
						this.destinationPopUpTA.push(this.destinationOptionsPopUp[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log("done");
	};



	getSourceDetails() {
		this.sourceAgentDtoPopUp = new AgentDetailsDto();
		this.sourceAgentDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
		this.sourceAgentDtoPopUp.mode = "Sub";
		this.sourceAgentDtoPopUp.status = "ALL";
		this.getSourceMethod();
	}

	getSourceMethod = () => {
		this.showSpinnerForAction = true;
		this.mastereadService.getSourceListDetails(this.sourceAgentDtoPopUp).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					this.sourceOptionsPopUp = [];
					this.sourcePopUpTA = [];
				} else {
					this.sourceOptionsPopUp = [];
					this.sourcePopUpTA = [];
					this.sourceOptionsPopUp = response;
					for (let i = 0; i < this.sourceOptionsPopUp.length; i++) {
						this.sourcePopUpTA.push(this.sourceOptionsPopUp[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source Details", "info");
			},
			() => console.log("done");
	};



	getCommodityMasterDetailsList = () => {
		//this.getCommodityListRead();
		this.rateMasterDtoPopUp = new RateMasterDto();
		this.selectedDestinationForCommodityPopUp = $("#" + this.pageId + "destinationPopUpId").val();
		this.rateMasterDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
		this.rateMasterDtoPopUp.mode = "mainConsigneeMaster";
		this.rateMasterDtoPopUp.destination = this.selectedDestinationForCommodityPopUp;
		this.mastereadService.getCommodityDetails(this.rateMasterDtoPopUp).subscribe(
			(response) => {
				if (response) {
					//console.log(response);
					if (response.length == 0) {
						this.rateMasterDtoOptionsPopUp = [];
						this.commodityListPopUpTA = [];

						this.rateMasterDtoCommodityListAllOptionPopUp = new RateMasterDto();
						this.rateMasterDtoCommodityListAllOptionPopUp.commodityName = "All";
						this.commodityListPopUpTA.push(this.rateMasterDtoCommodityListAllOptionPopUp);
					} else {
						this.rateMasterDtoOptionsPopUp = [];
						this.commodityListPopUpTA = [];

						this.rateMasterDtoOptionsPopUp = response;

						this.rateMasterDtoCommodityListAllOptionPopUp = new RateMasterDto();
						this.rateMasterDtoCommodityListAllOptionPopUp.commodityName = "All";
						this.commodityListPopUpTA.push(this.rateMasterDtoCommodityListAllOptionPopUp);

						for (let i = 0; i < this.rateMasterDtoOptionsPopUp.length; i++) {
							this.commodityListPopUpTA.push(this.rateMasterDtoOptionsPopUp[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Error",
				text: "Server Error While Getting Commodity Master Details",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};

	searchUnitMethodPopUp(searchUnitPopUp: string) {
		if (searchUnitPopUp === 'rate') {
			this.viewRateFieldPopUp = true;
			this.viewPercentageFieldPopUp = false;

			this.viewPerUnitFieldPopUp = true;
		} else if (searchUnitPopUp === 'percentage') {
			this.viewRateFieldPopUp = false;
			this.viewPercentageFieldPopUp = true;

			this.viewPerUnitFieldPopUp = false;
		} else {
			this.viewRateFieldPopUp = false;
			this.viewPercentageFieldPopUp = false;
			this.viewPerUnitFieldPopUp = false;
		}
	}

	setOnload() {
		$("#" + this.pageId + "rateChangeTxtId").text('Rate');
	}

	searchRateMethodPopUp(searchRatePopUp: string) {
		if (searchRatePopUp === 'fixedRate') {
			this.rateChangeTxtId = 'Rate';
		} else if (searchRatePopUp === 'DiscRate') {
			this.rateChangeTxtId = 'Discount Rate (Rs)';
		} else {
			this.rateChangeTxtId = 'Rate';
		}
	}

	searchUnitPercMethodPopUp(searchUnitPercPopUp: string) {
		if (searchUnitPercPopUp === 'PLR') {

		} else if (searchUnitPercPopUp === 'PCM') {

		} else if (searchUnitPercPopUp === 'PFRT') {

		} else {

		}
	}

	SaveClickBtn() {
		if ($("#" + this.pageId + "destinationPopUpId").val() == null || $("#" + this.pageId + "destinationPopUpId").val() == undefined ||
			$("#" + this.pageId + "destinationPopUpId").val() == "" || $("#" + this.pageId + "commodityListPopUpId").val() == null ||
			$("#" + this.pageId + "commodityListPopUpId").val() == undefined || $("#" + this.pageId + "commodityListPopUpId").val() == "") {
			swal("Mandatory Field", "Please select the Commodity to Apply the Changes", "warning");
			return false;
		} else if (this.lrDtoListSourceChecked == null || this.lrDtoListSourceChecked == undefined ||
			this.lrDtoListSourceChecked.length == 0) {
			swal("Mandatory Field", "Please select the Source to Apply the Changes", "warning");
			return false;
		} else {
			swal({
				title: "Confirm Changes",
				text: "Sure You Want To Apply The Rate Changes...This changes cannot be Undo...",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.getUserValueForAll();
				} else {
					console.log("No");
				}
			});
		}
	}

	getUserValueForAll() {
		this.rateMasterDtoGetUserValPopUp = new RateMasterDto();


		this.validateSelectedUpdateModePopUp = null;
		this.validateSelectedDestinationPopUp = null;
		this.validateSelectedSourcePopUp = null;
		this.validateSelectedCommodityPopUp = null;
		this.validateSelectedUnitPopUp = null;
		this.validateSelectedRatesFixedDisTextPopUp = null;
		this.validateSelectedRatesFixedDisNumberPopUp = null;
		this.validateSelectedUnitPercTextPopUp = null;
		this.validateSelectedUnitPercRateNumberPopUp = null;
		this.validateSelectedPerUnitPopUp = null;
		this.validateSelectedFixedByPopUp = null;
		this.validateSelectedEffectedFromPopUp = null;
		this.validateSelectedFixedOnPopUp = null;

		this.validateSelectedUpdateModePopUp = $("#" + this.pageId + "updateModePopUpId").val();
		this.validateSelectedDestinationPopUp = $("#" + this.pageId + "destinationPopUpId").val();
		this.validateSelectedSourcePopUp = $("#" + this.pageId + "sourcePopUp").val();
		this.validateSelectedCommodityPopUp = $("#" + this.pageId + "commodityListPopUpId").val();
		this.validateSelectedUnitPopUp = $("#" + this.pageId + "searchUnitPopUp").val();
		this.validateSelectedRatesFixedDisTextPopUp = $("#" + this.pageId + "searchRatePopUp").val();
		this.validateSelectedRatesFixedDisNumberPopUp = $("#" + this.pageId + "ratePercentagePopUpId").val();
		this.validateSelectedUnitPercTextPopUp = $("#" + this.pageId + "searchUnitPercPopUp").val();
		this.validateSelectedUnitPercRateNumberPopUp = $("#" + this.pageId + "percentageRatePopUpId").val();
		this.validateSelectedPerUnitPopUp = $("#" + this.pageId + "perUnitPopUpId").val();
		this.validateSelectedFixedByPopUp = $("#" + this.pageId + "fixedByPopUpId").val();
		this.validateSelectedEffectedFromPopUp = $("#" + this.pageId + "effectFromDatePopUpId").val();
		this.validateSelectedFixedOnPopUp = $("#" + this.pageId + "fixedOnDatePopUpId").val();

		this.rateMasterDtoGetUserValPopUp.mode = "adminRateMaster";
		if ($("#" + this.pageId + "updateModePopUpId").val() == "Increase") {
			this.rateMasterDtoGetUserValPopUp.rptMode = "Add";
		} else if ($("#" + this.pageId + "updateModePopUpId").val() == "Decrease") {
			this.rateMasterDtoGetUserValPopUp.rptMode = "Sub";
		}

		this.rateMasterDtoGetUserValPopUp.destination = this.validateSelectedDestinationPopUp;
		this.rateMasterDtoGetUserValPopUp.source = this.validateSelectedSourcePopUp;
		this.rateMasterDtoGetUserValPopUp.commodityName = this.validateSelectedCommodityPopUp;
		if ($("#" + this.pageId + "searchUnitPopUp").val() == "percentage") {
			this.rateMasterDtoGetUserValPopUp.unit = "Discount";
			this.rateMasterDtoGetUserValPopUp.rate = this.validateSelectedRatesFixedDisNumberPopUp;
		} else if ($("#" + this.pageId + "searchUnitPopUp").val() == "rate") {
			this.rateMasterDtoGetUserValPopUp.unit = "Rate";
			this.rateMasterDtoGetUserValPopUp.rate = this.validateSelectedRatesFixedDisNumberPopUp;
		}

		this.rateMasterDtoGetUserValPopUp.fixedBy = this.validateSelectedFixedByPopUp;
		this.rateMasterDtoGetUserValPopUp.effectiveFromDate = this.validateSelectedEffectedFromPopUp;
		this.rateMasterDtoGetUserValPopUp.fixedDate = this.validateSelectedFixedOnPopUp;

		if ($("#" + this.pageId + "searchUnitPercPopUp").val() == "PLR") {
			this.rateMasterDtoGetUserValPopUp.column2 = "onLr";
		} else if ($("#" + this.pageId + "searchUnitPercPopUp").val() == "onTotal") {
			this.rateMasterDtoGetUserValPopUp.column2 = "onLr";
		} else if ($("#" + this.pageId + "searchUnitPercPopUp").val() == "PFRT") {
			this.rateMasterDtoGetUserValPopUp.column2 = "onBaseFrgt";
		}

		if ($("#" + this.pageId + "searchUnitPopUp").val() == "rate") {
			if ($("#" + this.pageId + "searchRatePopUp").val() == "DiscRate") {
				this.rateMasterDtoGetUserValPopUp.isFixedRate = false;
			} else {
				this.rateMasterDtoGetUserValPopUp.isFixedRate = true;
			}
		}

		this.rateMasterDtoGetUserValPopUp.perUnit = this.validateSelectedPerUnitPopUp;
		this.rateMasterDtoGetUserValPopUp.lastModifiedBy = this.userDataDtoReturnSession.userId;
		this.rateMasterDtoGetUserValPopUp.companyId = this.userDataDtoReturnSession.companyId;

		this.listSource = [];
		if (this.lrDtoListSourceChecked != null && this.lrDtoListSourceChecked != undefined &&
			this.lrDtoListSourceChecked.length > 0) {
			for (let i = 0; i < this.lrDtoListSourceChecked.length; i++) {
				this.listSource.push(this.lrDtoListSourceChecked[i].subStation);
			}
		}

		this.rateMasterDtoGetUserValPopUp.list = this.listSource;

		this.listConsigneeId = [];
		if (this.lrDtoListConsigneeChecked != null && this.lrDtoListConsigneeChecked != undefined &&
			this.lrDtoListConsigneeChecked.length > 0) {
			for (let i = 0; i < this.lrDtoListConsigneeChecked.length; i++) {
				this.listConsigneeId.push(this.lrDtoListConsigneeChecked[i].consigneeId);
			}
		}
		this.rateMasterDtoGetUserValPopUp.listOfConsigneId = this.listConsigneeId;
		console.log(this.rateMasterDtoGetUserValPopUp);
		this.showSpinnerForAction = true;
		this.consigneeService.updateConsigneeRateForAll(this.rateMasterDtoGetUserValPopUp).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.status == "persisted") {
					this.clearFieldPopUp();
					this.dialogRef.close("Save");
				} else if (response.status == "empty") {
					this.clearFieldPopUp();
					this.dialogRef.close("Empty");
				} else {
					swal("Error", "Failed While Updating the Rate Changes...", "warning");
					//this.dialogRef.close("Close");
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
			}, () => console.log('done');
	}

	ClearClickBtn() {
		this.clearFieldPopUp();
	}

	clearFieldPopUp() {
		this.selectedDestinationForCommodityPopUp = null;
		this.rateChangeTxtId = 'Rate';

		this.rateMasterDtoGetUserValPopUp = new RateMasterDto();

		this.validateSelectedUpdateModePopUp = null;
		this.validateSelectedDestinationPopUp = null;
		this.validateSelectedSourcePopUp = null;
		this.validateSelectedCommodityPopUp = null;
		this.validateSelectedUnitPopUp = null;
		this.validateSelectedRatesFixedDisTextPopUp = null;
		this.validateSelectedRatesFixedDisNumberPopUp = null;
		this.validateSelectedUnitPercTextPopUp = null;
		this.validateSelectedUnitPercRateNumberPopUp = null;
		this.validateSelectedPerUnitPopUp = null;
		this.validateSelectedFixedByPopUp = null;
		this.validateSelectedEffectedFromPopUp = null;
		this.validateSelectedFixedOnPopUp = null;

		$("#" + this.pageId + "updateModePopUpId").val('Increase');
		$("#" + this.pageId + "destinationPopUpId").val('');
		$("#" + this.pageId + "sourcePopUp").val('');
		$("#" + this.pageId + "commodityListPopUpId").val('');
		$("#" + this.pageId + "searchUnitPopUp").val('rate');
		$("#" + this.pageId + "searchRatePopUp").val('fixedRate');
		$("#" + this.pageId + "ratePercentagePopUpId").val('');
		$("#" + this.pageId + "searchUnitPercPopUp").val('PLR');
		$("#" + this.pageId + "percentageRatePopUpId").val('');
		$("#" + this.pageId + "perUnitPopUpId").val('Article');
		$("#" + this.pageId + "fixedByPopUpId").val('');
		$("#" + this.pageId + "effectFromDatePopUpId").val('');
		$("#" + this.pageId + "fixedOnDatePopUpId").val('');

		setTimeout(() => {
			this.viewPercentageFieldPopUp = false;
			this.viewRateFieldPopUp = true;
			this.viewPerUnitFieldPopUp = true;
		}, 1000);
	}

	onCancelClick(): void {
		this.dialogRef.close("Close");
	}

	gridReconfigureForParty() {
		this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
		this.validateGridReconfDestination = $("#" + this.pageId + "destinationPopUpId").val();
		this.rateMasterDtoOnLoadGridReconfi.city = this.validateGridReconfDestination;
		this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
		this.rateMasterDtoOnLoadGridReconfi.rptModeId = 0;
		this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeAdminRateMasterRateForAllPartiesDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
			(response) => {
				$("#" + this.pageId + "selectedPartyNameDestinationTableId").DataTable().destroy();
				this.partyRateDataList = [];
				if (response.length == 0) {
					swal("Warning", "No Party Details found !", "warning");
				} else {
					this.partyRateDataList = response;
				}
				this.dtTriggerPartyRate.next();
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party details", "info");
			}, () => console.log('done');
	}

	gridReconfigureSource = () => {
		this.sourceAgentDtoPopUp = new AgentDetailsDto();
		this.sourceAgentDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
		this.sourceAgentDtoPopUp.mode = "Sub";
		this.sourceAgentDtoPopUp.status = "ALL";
		this.showSpinnerForAction = true;
		this.mastereadService.getSourceListDetails(this.sourceAgentDtoPopUp).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				this.sourceDataList = [];
				$("#" + this.pageId + "selectedSourceTableId").DataTable().destroy();
				if (response.length == 0) {
					swal("Warning", "No Source Details found !", "warning");
				} else {
					this.sourceDataList = response;
				}
				this.dtTriggerSource.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source Details", "info");
			},
			() => console.log("done");
	};

	rowCheckBoxForSource(e, sourceData, index) {
		if (e.currentTarget.checked) {
			this.newAttributeSetSourceList = {};
			this.newAttributeSetSourceList.subStation = sourceData.subStation;
			if (this.lrDtoListSourceChecked == null ||
				this.lrDtoListSourceChecked == undefined) {
				this.lrDtoListSourceChecked = [];
			}
			if (this.lrDtoListSourceChecked.length == 0) {
				this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
			} else {
				const result = this.lrDtoListSourceChecked.filter(lrDtoListSourceData =>
					lrDtoListSourceData.subStation === this.newAttributeSetSourceList.subStation);
				if (result.length > 0) {
					swal("Duplicate Entry", "" + this.newAttributeSetSourceList.subStation + " Already Exist", "warning");
				} else {
					this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
				}
			}
		} else {
			this.lrDtoListSourceChecked.splice(index, 1);
		}
		console.log(this.lrDtoListSourceChecked);
	}

	rowCheckBoxCheckedForConsignee(e, partyRateData, index) {
		console.log(partyRateData);
		if (e.currentTarget.checked) {
			this.newAttributeSetConsigneeList = {};
			this.newAttributeSetConsigneeList.consigneeId = partyRateData.consigneeId;
			this.newAttributeSetConsigneeList.consigneeName = partyRateData.consigneeName;
			if (this.lrDtoListConsigneeChecked == null ||
				this.lrDtoListConsigneeChecked == undefined) {
				this.lrDtoListConsigneeChecked = [];
			}
			if (this.lrDtoListConsigneeChecked.length == 0) {
				this.lrDtoListConsigneeChecked.push(this.newAttributeSetConsigneeList);
			} else {
				const result = this.lrDtoListConsigneeChecked.filter(lrDtoListConsigneeData =>
					lrDtoListConsigneeData.consigneeId === this.newAttributeSetConsigneeList.consigneeId);
				if (result.length > 0) {
					swal("Duplicate Entry", "" + this.newAttributeSetConsigneeList.consigneeName + " Already Exist", "warning");
				} else {
					this.lrDtoListConsigneeChecked.push(this.newAttributeSetConsigneeList);
				}
			}
		} else {
			this.lrDtoListConsigneeChecked.splice(index, 1);
		}
		console.log(this.lrDtoListConsigneeChecked);

	}

}

