import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
// for datatable ends
//service starts
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
//we used any keyword so comment
//import { ConsigneedetailsModel } from 'src/app/models/Consigneedetails-model';
//import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
//import { MasterDto } from 'src/app/dto/master-dto';
import { LRDto } from 'src/app/dto/LR-dto';
//import { PartyModel } from "src/app/models/party-model";
//service ends

import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
@Component( {
    selector: 'app-nature-of-pack',
    templateUrl: './nature-of-pack.component.html',
    styleUrls: ['./nature-of-pack.component.css']
} )
export class NatureOfPackComponent implements OnInit {

    //for datatable starts
    //    gettingDataFrmServiceFrNatureOfPackTable: any;

    natureOfPackDataList: any;
    lrDto: LRDto = new LRDto();
lrDeleteDto: LRDto = new LRDto();
    lrDtoCheckRet: any;
    lrDtoSaveRet: any;
    lrDtoDelelteRet: any;

    //    onDestroyUnsubscribtionNatureOfPack: Subscription;

    //service starts      
    //    partyModel: PartyModel = new PartyModel();

    private baseUrl = '/api/v1/createconsignee';
    //    masterDto: MasterDto = new MasterDto();
    //    consigneeDetailsModel: ConsigneedetailsModel = new ConsigneedetailsModel();
    saveResp: string;
    //    service ends
    selectedName: string;
    //for datatable ends

    loadingIndicator = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    packId = 0;


    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerNatureOfPack: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsNatureOfPack: any;

    //for datatable ends
    validateSavePackName:any;
    pageId="nfpct";
    constructor(/* for datatable starts */private masterService: MasterService, /* for datatable endss */
        //          service starts
        private masterReadService: MasterReadService,
        private http: HttpClient,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
        //            service ends        
    ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.natureOfPackDatatable();
            this.getNatureOfPackList();
        }
    }

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */


    ngOnInit(): void {



    }

    natureOfPackDatatable() {
        //for datatable starts
        this.dtOptionsNatureOfPack = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Name',
                    data: 'name'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }
            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //          this.supplierList.getAllData().subscribe(data => {
        //              this.lrDispatchBknRptList = data['data'];
        //              this.dtTriggerSummary.next();
        //              } );
        //        this.gettingDataFrmServiceFrNatureOfPackTable = this.natureOfPack.getSummaryData()
        //        this.onDestroyUnsubscribtionNatureOfPack = this.gettingDataFrmServiceFrNatureOfPackTable.subscribe( data => {
        //            this.natureOfPackDataList = data['data'];
        //            this.dtTriggerNatureOfPack.next();
        //        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerNatureOfPack.unsubscribe();

        //        this.onDestroyUnsubscribtionNatureOfPack.unsubscribe();
        //for datatable ends
    }
    //  service starts
    getNatureOfPackListRead() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }
    //    getValuesForNatureOfPackList() {
    //        var masterDto = {
    //            'mainStation': 'Delhi',
    //            'mainAdminStation': 'Budhpur'
    //        }
    //        return masterDto;
    //    }

    getNatureOfPackList = () => {
        //        console.log( this.lrDto.companyId );
        this.getNatureOfPackListRead();
        this.masterReadService.getNatureOfPack( this.lrDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    // console.log( response );
                    this.natureOfPackDataList = response;
                    $("#"+this.pageId+"natureOfPackDatatableId").DataTable().destroy();
                    this.dtTriggerNatureOfPack.next();
                    //                    console.log( this.natureOfPackDataList[0] );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Nature Of Pack Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    //    getNatureOfPackList = () => {
    //        /*let apiUrlConsigneeModel = `http://localhost:8080/srd/master/v1/returnNatureOfPackModel`;
    //        this.http.get(apiUrlConsigneeModel).subscribe(
    //            (response) => {
    //
    //                this.natureOfPackDataList = response.json();
    //
    //            }),
    //            (error) => console.log(error.json()),
    //            () => console.log('done');*/
    //
    //
    //
    //        let apiUrlNatureOfPackDetails = `http://localhost:8080/srd/master/v1/getNatureOfPack`;
    //        this.http.get( apiUrlNatureOfPackDetails, {
    //            params: {
    //                //                masterDto: this.getNatureOfPackList(),
    //                //                city: 'Chennai',
    //            },
    //        } ).subscribe(
    //            ( response ) => {
    //                if ( response.json().length == 0 ) {
    //                    alert( 'No records found for the selected city!' );
    //                } else {
    //                    this.natureOfPackDataList = response.json();
    //                    this.dtTriggerNatureOfPack.next();
    //                    console.log( this.natureOfPackDataList[0] );
    //                }
    //            } ),
    //            ( error ) => console.log( "edfdf" + error.json() ),
    //            () => console.log( 'done' );
    //
    //    };
    //    service ends
    //to insert value of selected row in table to input field starts
    rowSelected( natureOfPackData ) {
        this.selectedName = natureOfPackData.packNature;
        this.packId = natureOfPackData.id;
        //        console.log( this.selectedName );
    }
    //to insert value of selected row in table to input field ends


savePackOfNatureBtn(){
    this.validateSavePackName =this.selectedName;
    console.log(this.validateSavePackName);
    if(this.validateSavePackName == null || this.validateSavePackName == undefined || this.validateSavePackName ==""){
        swal("Mandetory Field","Please Enter the Mandetory Field","warning");
        return false;
    }else{
        this.checkNatureOfPackDetails();
    }
}
    checkPackDetails() {
            this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDto.userName = this.userDataDtoReturnSession.office;
            this.lrDto.mode = "packMaster";
            this.lrDto.packName = this.selectedName;
            this.lrDto.id = this.packId;
    }
    
    checkNatureOfPackDetails = () => {
        this.checkPackDetails();
        this.masterService.checkPackName( this.lrDto ).
            subscribe(( data ) => {
                this.lrDtoCheckRet = data;
                console.log( this.lrDtoCheckRet.status );
                if ( this.lrDtoCheckRet.status == "exist" ) {
                    swal( {
                        title: "Error",
                        text: "This Pack Name Already Exist",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                } else if ( this.lrDtoCheckRet.status == "notExist" ) {
                    this.savePackNature();
                } else {
                    swal( {
                        title: "Error",
                        text: "Server Error While Saving Pack Name Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Pack Name Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    savePackNature = () => {
        this.checkPackDetails();
        this.masterService.createNatureOfPack( this.lrDto ).
            subscribe(( data ) => {
                this.lrDtoSaveRet = data;
                //console.log( this.lrDtoSaveRet.status );
                if ( this.lrDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Pack Name Details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                    this.getNatureOfPackList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Pack Name Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Pack Name Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.packId = 0;
        this.selectedName=null;
    }
    ngAfterViewInit() { }

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
    
    validateDeleteRow(){
        this.deleteNatureOfPackDetails();
    }
    
    getDeleteNatureOfPackDetails(){
        this.lrDeleteDto.companyId=this.userDataDtoReturnSession.companyId;
        this.lrDeleteDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDeleteDto.id = this.packId;
        this.lrDeleteDto.mode = "packMaster";
    }
    
    deleteNatureOfPackDetails = () => {
        this.getDeleteNatureOfPackDetails();
        this.masterService.deleteNatureOfPack( this.lrDeleteDto ).
            subscribe(( data ) => {
                this.lrDtoDelelteRet = data;
                if ( this.lrDtoDelelteRet.status == "deleted" ) {
                    swal( {
                        title: "Success",
                        text: "Nature Of Pack Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                   this.getNatureOfPackList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Deleting Nature Of Pack Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Deleting Nature Of Pack Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    
}
