import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component( {
    selector: 'app-balancing-difference-tally-report',
    templateUrl: './balancing-difference-tally-report.component.html',
    styleUrls: ['./balancing-difference-tally-report.component.css']
} )
export class BalancingDifferenceTallyReportComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    agentStmtAmountDataList: any;
    onDestroyUnsubscribtionAgentStmtAmount: Subscription;
    loadingIndicator = true;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAgentStmtAmount: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsAgentStmtAmount: any;
    pageId="bdtrc";
    fortNightStmtDtoUserValues: FortNightStmtDto = new FortNightStmtDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    selectedReg:any;
    
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
   
    totStmtAmt :number;
    totAmtRec:number;
    totDebitAmt:number;
    totBalAmt:number;
    viewCustomPrintV1: boolean;

    constructor(private router: Router,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }
    ngOnInit(): void {
        this.dtOptionsAgentStmtAmount = {
               dom: 'Bfrtip',
            buttons: [
                   {
                                    extend: 'excel',
                                    text: '<i class="fas fa-file-excel"> Excel</i>',
                                    titleAttr: 'Excel',
                                    exportOptions: {
                                        columns: ':visible'
                                    }
                                }
            ],

            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalStmtAmt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                
                var totalAmtRec = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                var totalDebitRec = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                var balanceAmt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalStmtAmt);
                $(api.column(2).footer()).html(totalAmtRec);
                $(api.column(3).footer()).html(totalDebitRec);
                $(api.column(4).footer()).html(balanceAmt);
            }

        }
    }
    
    getUserValues() {
		this.fortNightStmtDtoUserValues = new FortNightStmtDto();
		this.selectedReg=$("#"+this.pageId+"transferType").val();
		this.fortNightStmtDtoUserValues.branchType = "Agent";
		this.fortNightStmtDtoUserValues.mainBranch =this.selectedReg;
	}
    getDestAgentStmtTrxAmt = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.destStmtReport.getDestAgentStmtTrxAmt(this.fortNightStmtDtoUserValues).subscribe(
			(response) => {
				$("#"+this.pageId+"destAgentBalDetailsTableId").DataTable().destroy();
				this.agentStmtAmountDataList = [];
				if (response.length == 0) {
					swal({
						title: "No record",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.agentStmtAmountDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerAgentStmtAmount.next();
	            }, 500);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the details", "info");
			}, () => console.log('done');
	};
    
    ngAfterViewInit(): void {
        this.dtTriggerAgentStmtAmount.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerAgentStmtAmount.unsubscribe();
    }
    
    logInPage() {
	      this.router.navigate(['/authentication/login']);
	  }
    
    customPrintAgentBalDetails() {
        if (this.agentStmtAmountDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Total Stmt Amt", "Tot Amt Rec", "Tot Debit Note Amt","Balance Amt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Region"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedReg];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            this.totStmtAmt = this.totAmtRec=this.totDebitAmt =this.totBalAmt=0;
            for (let i = 0; i < this.agentStmtAmountDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentStmtAmountDataList[i].destination, this.agentStmtAmountDataList[i].totalAmount,
                                                                  this.agentStmtAmountDataList[i].totalAmtPaid,this.agentStmtAmountDataList[i].debitTotal,
                                                                  this.agentStmtAmountDataList[i].balanceAmt];

                this.totStmtAmt = this.totStmtAmt + this.agentStmtAmountDataList[i].totalAmount;
                this.totAmtRec = this.totAmtRec + this.agentStmtAmountDataList[i].totalAmtPaid;
                this.totDebitAmt = this.totDebitAmt + this.agentStmtAmountDataList[i].debitTotal;
                this.totBalAmt = this.totBalAmt + this.agentStmtAmountDataList[i].balanceAmt;

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentStmtAmountDataList.length,  this.totStmtAmt,this.totAmtRec, this.totDebitAmt,this.totBalAmt];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Agent Statement Balance As On ");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}

