import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component({
	selector: 'app-rate-history-report',
	templateUrl: './rate-history-report.component.html',
	styleUrls: ['./rate-history-report.component.css']
})
export class RateHistoryReportComponent implements OnInit {

	rateHistoryReportPartyWiseDataList: any;
	rateHistoryReportCommodityWiseDataList: any;
	model: NgbDateStruct;
	viewParty = true;
	viewCommodity = false;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerRateHistoryReportPartyWise: Subject<any> = new Subject();
	dtTriggerRateHistoryReportCommodityWise: Subject<any> = new Subject();
	dtOptionsRateHistoryReportPartyWise: any;
	dtOptionsRateHistoryReportCommodityWise: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	///
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any
	modelConsigneeName: any;

	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;
	address: any;
	office: any;
	destView = false;
	viewDestination = false;
	selectedIndex: any;
	selectedConsigneeName: any;
	consigneeNameInTable: any;
	searchConsigneeDto: PartyMasterDto = new PartyMasterDto();
	ShowDestForConsigneeRateHistory = 'Show Dest In RateHistoryRpt'
	//For Source
	sourceOptions: LRDto[];
	lrDtoSource: LRDto = new LRDto();
	public modelSource: any;
	sourceTA: Array<LRDto> = [];

	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourceTA
				: this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSource = (x: { subStations: string }) => x.subStations;
	//
	lrDtoSourceOptions: LRDto[];
	lrDtoSourceAllOption: LRDto = new LRDto();
	partyWiseTable = true;
	commodityWiseTable = false;
	///For Commodity
	rateMasterDtoCommodity: RateMasterDto = new RateMasterDto();
	rateMasterDtoCommodityOptions: RateMasterDto[];
	public modelCommodityMain: any;
	modelCommodityMainTA: Array<RateMasterDto> = [];
	modelCommodityMainTATemp: Array<RateMasterDto> = [];
	focusCommodityMainTA$ = new Subject<string>();
	searchCommodityMain = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityMainTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelCommodityMainTA
				: this.modelCommodityMainTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityMain = (x: { commodityName: string }) => x.commodityName;
	///
	selectedSearchType: any;
	fromDateModel: any;
	toDateModel: any;
	selectedFromDate: any;
	selectedToDate: any;
	selectedCommodityName: any;
	SearchByPartyWiseRateMasterDto: RateMasterDto = new RateMasterDto();
	SearchByCommodityWiseRateMasterDto: RateMasterDto = new RateMasterDto();
	selectedSource: any;
	dataForPopup: any;
	searchPartyWiseTotalStatusDtoPopup: RateMasterDto = new RateMasterDto();
	SearchByPartyWiseRateMasterDtoPopUp: RateMasterDto = new RateMasterDto();

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	pageId = "rhrc";

	constructor(private router: Router, private masterReadService: MasterReadService,
		private consigneeService: ConsigneeService,
		public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;

			this.getCommodityMasterDetailsList(false);
			this.getSourceDetails();

			///Rights for show Show Dest List
			//            if ( this.ShowDestForConsigneeRateHistory == "Show Dest In RateHistoryRpt" ) {
			//                this.viewDestination = true;
			//this.getDestinationDetails();         
			//} 

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Dest ConsignRateHistortRpt") {
						this.destView = true;
					}

				}
			}
			//this.destView =true;
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}

			this.dataForPopup = JSON.parse(localStorage.getItem('rateHistroyReportPopup'));
			localStorage.clear();
			if (this.dataForPopup != null) {
				this.searchPartyWiseTotalStatusDtoPopup = new RateMasterDto();
				this.searchPartyWiseTotalStatusDtoPopup = this.dataForPopup;
				if (this.searchPartyWiseTotalStatusDtoPopup != null) {
					this.searchByPartyWiseMethodDetailsPopup();
				}
			} else {
				this.getConsigneeDetails(false);
			}
			this.dataForPopup = '';

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				// Do your stuff
				//console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
				if (dtInstance.table().node().id == "partyWiseId") {
					console.log("a");
					this.rateHistoryReportPartyWiseDataList = [];
					dtInstance.destroy();
					this.dtTriggerRateHistoryReportPartyWise.next();
				} else if (dtInstance.table().node().id == "commodityWiseId") {
					console.log("b");
					this.rateHistoryReportCommodityWiseDataList = [];
					dtInstance.destroy();
					this.dtTriggerRateHistoryReportCommodityWise.next();
				}
			});
		});
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var mainStation = this.userDataDtoReturnSession.mainStation;
		var destVisible = false;
		if (this.destView == true) {
			destVisible = true;
		} else {
			destVisible = false;
		}

		this.dtOptionsRateHistoryReportPartyWise = {

			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize: function (win) {
						$(win.document.body).css('font-size', '10pt');
						$(win.document.body).find('th, td').
							css('font-family', 'Arial, Helvetica, sans-serif')
							.css('font-size', '13px').css('text-align', 'center');
					},
					footer: true,
					exportOptions: {
						columns: [8, 12, 2, 0, 1, 5, 6, 3, 4, 7, 9, 21, 10, 11]
					},
					messageTop: function () {
						var returnVal = null;
						if (destVisible == true) {
							returnVal = "<br><table style='width: 100%;'>" +
								"<tr>" +
								"<td style='width: 30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
								"</td>" +
								"</tr>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
								"<strong style='font-size:25px;'><u>Party History Report</u></strong><br>" +
								"</td>" +
								"<td align='left' style='width:40%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Party Name</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='32%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + $("#rhrcconsigneeName").val() + "</span><br>" +
								"</td>" +
								"</tr>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Destination</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
								"<span style='font-size:15px;'>" + $("#rhrcdestination").val() + "</span><br>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"</tr>" +
								"</table><br>";
						} else {
							returnVal = "<br><table style='width: 100%;'>" +
								"<tr>" +
								"<td style='width: 30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
								"</td>" +
								"</tr>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
								"<strong style='font-size:25px;'><u>Party History Report</u></strong><br>" +
								"</td>" +
								"<td align='left' style='width:40%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Party Name</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='32%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + $("#rhrcconsigneeName").val() + "</span><br>" +
								"</td>" +
								"</tr>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Destination</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
								"<span style='font-size:15px;'>" + mainStation + "</span><br>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"</tr>" +
								"</table><br>";
						}
						return returnVal;


					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					title: function () {

						var returnSummaryExcel = null;
						if (destVisible == true) {
							returnSummaryExcel = "Party History Report" +
								"From Date : " + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("rhrctoDate").val()).format('DD-MM-YYYY') + " Party Name : " + $("rhrcconsigneeName").val() + " Destination : " + $("#rhrcdestination").val();
						} else {
							returnSummaryExcel = "Party History Report" +
								"From Date : " + moment($("rhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + " Party Name : " + $("#rhrcconsigneeName").val() + " Destination : " + mainStation;
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 380,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": false,
			"info": true,
			
		},
			this.dtOptionsRateHistoryReportCommodityWise = {

				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'print',
						text: '<i class="fas fa-print"> Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
						exportOptions: {
							columns: [11, 2, 0, 1, 5, 6, 3, 4, 7, 8, 20, 9, 10]
						},

						messageTop: function () {
							var returnValCom = null;
							if (destVisible == true) {

								returnValCom = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:35%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Party History Report - Commodity Wise</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:35%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Commodity</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#rhrccommodity").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Destination</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + $("#rhrcdestination").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							} else {

								returnValCom = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:35%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Party History Report - Commodity Wise</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:35%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Commodity</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#rhrccommodity").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Destination</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + mainStation + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							}
							return returnValCom;

						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						title: function () {

							var returnSummaryExcel = null;
							if (destVisible == true) {
								returnSummaryExcel = "Party History Report - Commodity Wise" +
									"From Date : " + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + " Commodity : " + $("#rhrccommodity").val() + " Destination : " + $("#rhrcdestination").val();
							} else {
								returnSummaryExcel = "Party History Report - Commodity Wise" +
									"From Date : " + moment($("#rhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#rhrctoDate").val()).format('DD-MM-YYYY') + " Commodity : " + $("#rhrccommodity").val() + " Destination : " + mainStation;
							}
							return returnSummaryExcel;
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 380,
				"scrollCollapse": true,
				//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
				"paging": false,
				"info": true,

			}
	}

	ngOnDestroy(): void {
		this.dtTriggerRateHistoryReportPartyWise.unsubscribe();
		this.dtTriggerRateHistoryReportCommodityWise.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerRateHistoryReportPartyWise.next();
		this.dtTriggerRateHistoryReportCommodityWise.next();

	}
	searchByMode(searchBy: string) {
		if (searchBy === 'partyWise') {
			this.viewParty = true;
			this.viewCommodity = false;
			this.partyWiseTable = true;
			this.commodityWiseTable = false;
			if (this.destView == true) {
				this.viewDestination = true;
				$("#" + this.pageId + "destination").val('');
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.consigneeNameTA = [];
				this.modelCommodityMainTA = [];
			} else {
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.modelCommodityMainTA = this.modelCommodityMainTATemp;
				this.consigneeNameTA = this.consigneeNameTATemp;
				this.viewDestination = false;
			}
			$("#" + this.pageId + "consigneeName").val('');
			$("#" + this.pageId + "commodity").val('');

			this.callMethodToClearTable();
		} else if (searchBy === 'commodityWise') {
			this.viewParty = false;
			this.viewCommodity = true;
			this.partyWiseTable = false;
			this.commodityWiseTable = true;
			if (this.destView == true) {
				this.viewDestination = true;
				$("#" + this.pageId + "destination").val('');
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.consigneeNameTA = [];
				this.modelCommodityMainTA = [];
			} else {
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.modelCommodityMainTA = this.modelCommodityMainTATemp;
				this.consigneeNameTA = this.consigneeNameTATemp;
				this.viewDestination = false;
			}
			$("#" + this.pageId + "consigneeName").val('');
			$("#" + this.pageId + "commodity").val('');
			this.callMethodToClearTable();
		} else {
			this.viewParty = false;
			this.viewCommodity = false;
			this.partyWiseTable = false;
			this.commodityWiseTable = false;
			if (this.destView == true) {
				this.viewDestination = true;
				$("#" + this.pageId + "destination").val('');
				$("#" + this.pageId + "consigneeName").val('');
				$("#" + this.pageId + "commodity").val('');
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.consigneeNameTA = [];
				this.modelCommodityMainTA = [];
			} else {
				this.modelConsigneeName = '';
				this.modelCommodityMain = '';
				this.modelCommodityMainTA = this.modelCommodityMainTATemp;
				this.consigneeNameTA = this.consigneeNameTATemp;
				$("#" + this.pageId + "commodity").val('');
				$("#" + this.pageId + "consigneeName").val('');
				this.viewDestination = false;
			}
			this.callMethodToClearTable();

		}
	}
	callMethodToClearTable() {
		$("#" + this.pageId + "partyWiseId").DataTable().destroy();
		this.rateHistoryReportPartyWiseDataList = [];
		$("#" + this.pageId + "commodityWiseId").DataTable().destroy();
		this.rateHistoryReportCommodityWiseDataList = [];
		this.dtTriggerRateHistoryReportPartyWise.next();
		this.dtTriggerRateHistoryReportCommodityWise.next();
	}



	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker



	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}

					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					this.consigneeNameTATemp = this.consigneeNameTA;
					console.log(this.consigneeNameTA);
					if (destShow) {
						$("#" + this.pageId + "consigneeName").focus();
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.selectedSearchType = $("#" + this.pageId + "searchBy").val();
		if (this.selectedSearchType == 'partyWise') {
			this.getConsigneeDetails(true);
		} else {
			this.getCommodityMasterDetailsList(true);
		}
	}
	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}

	getSourceDetailedList() {
		this.lrDtoSource.mode = 'Booking';
		this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoSource);
	}

	getSourceDetails() {
		this.getSourceDetailedList();
		this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
			(response) => {
				if (response) {
					if (response.length > 0) {
						this.lrDtoSourceOptions = response;
						console.log(this.lrDtoSourceOptions);
						this.sourceTA = [];
						this.lrDtoSourceAllOption.subStations = "All";
						this.sourceTA.push(this.lrDtoSourceAllOption);
						for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
							this.sourceTA.push(this.lrDtoSourceOptions[i]);
						}

						$("#" + this.pageId + "source").val('All');
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Agent Details", "info");
			},
			() => console.log('done');

	};
	getCommodityListRead(destShow) {
		this.selectedDestination = this.modelDestinationFotGet;
		this.rateMasterDtoCommodity = new RateMasterDto();
		this.rateMasterDtoCommodity.companyId = this.userDataDtoReturnSession.companyId;
		if (destShow) {
			this.rateMasterDtoCommodity.destination = this.selectedDestination.destination;
			//this.rateMasterDtoCommodity.destination = null;
			console.log(this.selectedDestination);
		} else {
			this.rateMasterDtoCommodity.destination = this.userDataDtoReturnSession.mainStation;
		}
		this.rateMasterDtoCommodity.mode = "mainConsigneeMaster";
		this.rateMasterDtoCommodity.status = "Working";
		console.log(this.rateMasterDtoCommodity);
	}

	getCommodityMasterDetailsList = (destShow) => {
		//        console.log( this.lrDto.companyId );
		this.getCommodityListRead(destShow);
		this.showSpinnerForAction = true;
		this.masterReadService.getCommodityDetails(this.rateMasterDtoCommodity).subscribe(
			(response) => {
				if (response) {
					this.showSpinnerForAction = false;
					if (response.length == 0) {
						swal({
							title: "Warning",
							text: "No commodity details records found!",
							icon: "warning",
							closeOnClickOutside: false,
							closeOnEsc: false,
						});

						this.rateMasterDtoCommodityOptions = [];
						this.modelCommodityMainTA = [];
					} else {

						this.rateMasterDtoCommodityOptions = response;
						this.modelCommodityMainTA = [];

						for (let i = 0; i < this.rateMasterDtoCommodityOptions.length; i++) {
							this.modelCommodityMainTA.push(this.rateMasterDtoCommodityOptions[i]);
						}
						this.modelCommodityMainTATemp = this.modelCommodityMainTA;
						if (destShow) {
							$("#" + this.pageId + "commodity").focus();
						}
						console.log(this.modelCommodityMainTA);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Comodity Details", "info");
			},
			() => console.log('done');

	};
	searchMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedSearchType = $("#" + this.pageId + "searchBy").val();

		if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
			swal({
				title: "Mandatory Field",
				text: "Please select Date",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			if (this.selectedSearchType == 'partyWise') {
				this.searchByPartyWiseMethod();
			} else if (this.selectedSearchType == 'commodityWise') {
				this.searchByCommodityWiseMethod();
			}

		}
	}

	searchByPartyWiseMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedConsigneeId = $("#" + this.pageId + "consigneeName").val();
		this.selectedSource = $("#" + this.pageId + "source").val();

		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
		} else {
			swal({
				title: "Mandatory Field",
				text: "Please Select Party",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		}
		if (this.destView == true) {
			if (this.selectedDestination == null && this.selectedDestination == '' && this.selectedDestination == 'All') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		}
		this.SearchByPartyWiseRateMasterDto = new RateMasterDto();
		this.SearchByPartyWiseRateMasterDto.fromDateStg = this.selectedFromDate;
		this.SearchByPartyWiseRateMasterDto.toDateStg = this.selectedToDate;
		this.SearchByPartyWiseRateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.SearchByPartyWiseRateMasterDto.consigneeId = this.selectedConsigneeId;
		this.SearchByPartyWiseRateMasterDto.consigneeName = this.selectedConsigneeName;
		this.SearchByPartyWiseRateMasterDto.source = this.selectedSource;
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != 'All') {
				this.SearchByPartyWiseRateMasterDto.destination = this.selectedDestination;
			} else {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		} else {
			this.SearchByPartyWiseRateMasterDto.destination = this.userDataDtoReturnSession.mainStation;
		}

		this.searchByPartyWiseDetailsMethod();
		console.log(this.SearchByPartyWiseRateMasterDto);

	}
	searchByPartyWiseDetailsMethod = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRateHistDtlsRptPartyWise(this.SearchByPartyWiseRateMasterDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "partyWiseId").DataTable().destroy();
				this.rateHistoryReportPartyWiseDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.rateHistoryReportPartyWiseDataList = response;
					console.log(this.rateHistoryReportPartyWiseDataList);
				}
				this.dtTriggerRateHistoryReportPartyWise.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise View", "info");
			}, () => console.log('done');
	};


	searchByCommodityWiseMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCommodityName = $("#" + this.pageId + "commodity").val();
		this.selectedSource = $("#" + this.pageId + "source").val();

		if (this.selectedCommodityName == null && this.selectedCommodityName == '' && this.selectedCommodityName == 'All') {
			swal({
				title: "Mandatory Field",
				text: "Please Select Commodity",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		}
		if (this.destView == true) {
			if (this.selectedDestination == null && this.selectedDestination == '' && this.selectedDestination == 'All') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		}
		this.SearchByCommodityWiseRateMasterDto = new RateMasterDto();
		this.SearchByCommodityWiseRateMasterDto.fromDateStg = this.selectedFromDate;
		this.SearchByCommodityWiseRateMasterDto.toDateStg = this.selectedToDate;
		this.SearchByCommodityWiseRateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.SearchByCommodityWiseRateMasterDto.source = this.selectedSource;
		this.SearchByCommodityWiseRateMasterDto.commodityName = this.selectedCommodityName;
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != 'All') {
				this.SearchByCommodityWiseRateMasterDto.destination = this.selectedDestination;
			} else {
				swal({
					title: "Mandatory Field",
					text: "Please Select Destination",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		} else {
			this.SearchByCommodityWiseRateMasterDto.destination = this.userDataDtoReturnSession.mainStation;
		}

		this.searchByCommodityWiseDetailsMethod();
		console.log(this.SearchByCommodityWiseRateMasterDto);

	}
	searchByCommodityWiseDetailsMethod = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRateHistDtlsRptCommodityWise(this.SearchByCommodityWiseRateMasterDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "commodityWiseId").DataTable().destroy();
				this.rateHistoryReportCommodityWiseDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.rateHistoryReportCommodityWiseDataList = response;
					console.log(this.rateHistoryReportCommodityWiseDataList);
				}
				this.dtTriggerRateHistoryReportCommodityWise.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Commodity Wise  View", "info");
			}, () => console.log('done');
	};

	searchByPartyWiseMethodDetailsPopup() {

		this.SearchByPartyWiseRateMasterDtoPopUp = new RateMasterDto();
		this.SearchByPartyWiseRateMasterDtoPopUp.fromDateStg = this.searchPartyWiseTotalStatusDtoPopup.fromDateStg;
		this.SearchByPartyWiseRateMasterDtoPopUp.toDateStg = this.searchPartyWiseTotalStatusDtoPopup.toDateStg;
		this.SearchByPartyWiseRateMasterDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
		this.SearchByPartyWiseRateMasterDtoPopUp.consigneeId = this.searchPartyWiseTotalStatusDtoPopup.consigneeId;
		this.SearchByPartyWiseRateMasterDtoPopUp.consigneeName = this.searchPartyWiseTotalStatusDtoPopup.consigneeName;
		this.SearchByPartyWiseRateMasterDtoPopUp.source = this.searchPartyWiseTotalStatusDtoPopup.source;
		this.SearchByPartyWiseRateMasterDtoPopUp.destination = this.searchPartyWiseTotalStatusDtoPopup.destination;
		this.searchByPartyWiseDetailsMethodPopup();
		console.log(this.SearchByPartyWiseRateMasterDtoPopUp);

	}
	searchByPartyWiseDetailsMethodPopup = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRateHistDtlsRptPartyWise(this.SearchByPartyWiseRateMasterDtoPopUp).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "partyWiseId").DataTable().destroy();
				this.rateHistoryReportPartyWiseDataList = [];
				this.selectedFromDate = this.searchPartyWiseTotalStatusDtoPopup.fromDateStg;
				$("#" + this.pageId + "fromDate").val(this.selectedFromDate);
				this.selectedToDate = this.searchPartyWiseTotalStatusDtoPopup.toDateStg;
				$("#" + this.pageId + "toDate").val(this.selectedToDate);
				this.selectedDestination = this.searchPartyWiseTotalStatusDtoPopup.destination;
				$("#" + this.pageId + "destination").val(this.selectedDestination);
				this.selectedConsigneeName = this.searchPartyWiseTotalStatusDtoPopup.consigneeName;
				$("#" + this.pageId + "consigneeName").val(this.selectedConsigneeName);
				$("#" + this.pageId + "source").val('All');
				this.selectedConsigneeId = this.searchPartyWiseTotalStatusDtoPopup.consigneeId;
				this.viewParty = true;
				this.viewCommodity = false;
				this.partyWiseTable = true;
				this.commodityWiseTable = false;
				if (this.destView == true) {
					this.viewDestination = true;
					this.selectedDestination = this.searchPartyWiseTotalStatusDtoPopup.destination;
					$("#" + this.pageId + "destination").val(this.selectedDestination);
				} else {
					this.viewDestination = false;
				}
				setTimeout(() => {
					this.setConsignee();
				},
					5000);

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.rateHistoryReportPartyWiseDataList = response;
					console.log(this.rateHistoryReportPartyWiseDataList);
				}
				this.dtTriggerRateHistoryReportPartyWise.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise View", "info");
			}, () => console.log('done');
	};

	setConsignee() {
		$("#" + this.pageId + "consigneeName").val(this.searchPartyWiseTotalStatusDtoPopup.consigneeName);

	}

	clearMethod() {
		this.callMethodToClearTable();
		this.selectedSource = '';
		$("#" + this.pageId + "source").val('All');
		this.selectedSearchType = '';
		$("#" + this.pageId + "searchBy").val('partyWise');
		this.selectedDestination = '';
		$("#" + this.pageId + "destination").val('');
		this.selectedFromDate = '';
		$("#" + this.pageId + "fromDate").val('');
		this.selectedToDate = '';
		$("#" + this.pageId + "toDate").val('');
		this.selectedCommodityName = '';
		this.selectedConsigneeName = '';
		this.showSpinnerForAction = false;
		this.selectedConsigneeId = 0;
		this.modelDestinationFotGet = '';
		this.modelConsigneeNameId = '';
		this.fromDateModel = null;
		this.toDateModel = null;
		this.modelConsigneeName = '';
		this.modelCommodityMain = '';
		//

		if (this.destView == true) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val('');
			this.consigneeNameTA = [];
			this.modelCommodityMainTA = [];
		} else {
			this.modelCommodityMainTA = this.modelCommodityMainTATemp
			this.consigneeNameTA = this.consigneeNameTATemp;
			this.viewDestination = false;
		}
		$("#" + this.pageId + "consigneeName").val('');
		$("#" + this.pageId + "commodity").val('');
		this.viewParty = true;
		this.viewCommodity = false;
		this.partyWiseTable = true;
		this.commodityWiseTable = false;
		this.showSpinnerForAction = false;
		this.SearchByCommodityWiseRateMasterDto = new RateMasterDto();
		this.SearchByPartyWiseRateMasterDto = new RateMasterDto();
		this.searchPartyWiseTotalStatusDtoPopup = new RateMasterDto();
		localStorage.clear();
		this.SearchByPartyWiseRateMasterDtoPopUp = new RateMasterDto();
		this.dataForPopup = '';
	}

	customPrintRateHistoryPartyRpt() {
		if (this.rateHistoryReportPartyWiseDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Commodity", "Source", "Rate", "Unit", "Des Ham", "S.Ham", "G.C", "R.C", "S.C", "P Unit", "Fixed By", "Fixed On", "Effect From", "Effect To"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 8, 6, 7, 6, 6, 5, 5, 5, 5, 8, 8, 8, 7];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}


			for (let i = 0; i < this.rateHistoryReportPartyWiseDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.rateHistoryReportPartyWiseDataList[i].commodityName, this.rateHistoryReportPartyWiseDataList[i].sourceCode,
				this.rateHistoryReportPartyWiseDataList[i].rate, this.rateHistoryReportPartyWiseDataList[i].column2, this.rateHistoryReportPartyWiseDataList[i].hamaliDestination,
				this.rateHistoryReportPartyWiseDataList[i].hamaliSource, this.rateHistoryReportPartyWiseDataList[i].gcCharge, this.rateHistoryReportPartyWiseDataList[i].receiptCharges,
				this.rateHistoryReportPartyWiseDataList[i].serviceCharges, this.rateHistoryReportPartyWiseDataList[i].perUnit, this.rateHistoryReportPartyWiseDataList[i].fixedBy, this.rateHistoryReportPartyWiseDataList[i].column3, this.rateHistoryReportPartyWiseDataList[i].effectiveFromDateStg, this.rateHistoryReportPartyWiseDataList[i].effectiveToDateSrg];
				////






				/////////

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.rateHistoryReportPartyWiseDataList.length, "", "", "", "", "", "", "", "", "", "", "", "", ""];

			//heading logics For Date
			this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
			this.selectedToDate = $("#" + this.pageId + "toDate").val();

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];


			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date,To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

			///Heaading Logics For Party Name
			this.selectedDestination = $("#" + this.pageId + "destination").val();
			this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignee", "Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedConsigneeName, this.selectedDestination];


			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Party History Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}


	customPrintRateHistoryCommodityRpt() {
		if (this.rateHistoryReportCommodityWiseDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Source", "Rate", "Unit", "Des Ham", "S.Ham", "G.C", "R.C", "S.C", "P Unit", "Fixed By", "Fixed On", "Effect From", "Effect To"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [11, 9, 9, 8, 7, 6, 6, 6, 6, 8, 9, 9, 7];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}


			for (let i = 0; i < this.rateHistoryReportCommodityWiseDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.rateHistoryReportCommodityWiseDataList[i].sourceCode, this.rateHistoryReportCommodityWiseDataList[i].rate,
				this.rateHistoryReportCommodityWiseDataList[i].column2, this.rateHistoryReportCommodityWiseDataList[i].hamaliDestination,
				this.rateHistoryReportCommodityWiseDataList[i].hamaliSource, this.rateHistoryReportCommodityWiseDataList[i].gcCharge, this.rateHistoryReportCommodityWiseDataList[i].receiptCharges,
				this.rateHistoryReportCommodityWiseDataList[i].serviceCharges, this.rateHistoryReportCommodityWiseDataList[i].perUnit, this.rateHistoryReportCommodityWiseDataList[i].fixedBy, this.rateHistoryReportCommodityWiseDataList[i].column3, this.rateHistoryReportCommodityWiseDataList[i].effectiveFromDateStg, this.rateHistoryReportCommodityWiseDataList[i].effectiveToDateSrg];
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.rateHistoryReportCommodityWiseDataList.length, "", "", "", "", "", "", "", "", "", "", "", ""];
			//heading logics For Date
			this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
			this.selectedToDate = $("#" + this.pageId + "toDate").val();

			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date,To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

			///Heaading Logics For Party Name
			this.selectedDestination = $("#" + this.pageId + "destination").val();
			this.selectedCommodityName = $("#" + this.pageId + "commodity").val();
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Commodity", "Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedCommodityName, this.selectedDestination];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Party History Report-Commodity Wise");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}
}

