<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewBy" name="viewBy" #viewBy (change)="viewByMode(viewBy.value)">
															<option selected value="sourceView">Source View</option>
															<option value="consigneeView">Consignee View</option>
															<option value="consignorView">Consignor View</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input id="{{pageId}}fromDate" [(ngModel)]="fromDateModel" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="toDateModel" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showSource" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                        />


                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showDest" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="consigneeDestListener($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewConsigneeWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consigneeGstNo">
                                                        <label>Consignee GST No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input #consigneeGSTNumber (keyup)="getConsigneeGstNumber($event)" type="text" id="{{pageId}}consigneeGSTNumber" class="form-control" placeholder="Enter Consignee GST Number" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewConsigneeWise" class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consignorGstNo">
                                                        <label>Consignor GST No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input #consignorGSTNumber placeholder="Enter Consignor GST Number" (keyup)="getConsignorGstNumber($event)" type="text" class="form-control" id="{{pageId}}consignorGSTNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                            (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewConsignorWise" class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="rowSelectedConsignor($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <table width=100%>
                                            <td width=80%>
                                                <h6 class="card-title">LR Report</h6>

                                            </td>
                                            <td width=20%>

                                                <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrintLrRpt()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
                                            </td>

                                        </table>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}lrRptTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrReport" [dtTrigger]="dtTriggerLrReport">

                                                        <thead>
                                                            <tr>
                                                                <th>LR Number</th>
                                                                <th>Agent Name</th>
                                                                <th>Consignee</th>
                                                                <th>Consignor</th>
                                                                <th>Articles</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Base Freight</th>
                                                                <th>Hamali</th>
                                                                <th>AOC</th>
                                                                <th>Lc Charge</th>
                                                                <th>Bc Charge</th>
                                                                <th>Insurance</th>
                                                                <th>Gc Charges</th>
                                                                <th>Others</th>
                                                                <th>DD Amt</th>
                                                                <th>To Pay</th>
                                                                <th>Paid</th>
                                                                <th>F O V</th>
                                                                <th>Goods Value</th>
                                                                <th>Book Date</th>
                                                                <th>Destination</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let lrReportData of lrReportDataList ">
                                                                <td>{{ lrReportData.lrNumber }}</td>
                                                                <td>{{ lrReportData.agentName }}</td>
                                                                <td>{{ lrReportData.consigneeName }}</td>
                                                                <td>{{ lrReportData.consignorName }}</td>
                                                                <td>{{ lrReportData.totalArticles }}</td>
                                                                <td>{{ lrReportData.actualWeight }}</td>
                                                                <td>{{ lrReportData.chargedWeight }}</td>
                                                                <td>{{ lrReportData.baseFreight }}</td>
                                                                <td>{{ lrReportData.hamali }}</td>
                                                                <td>{{ lrReportData.aoc }}</td>
                                                                <td>{{ lrReportData.lcChg }}</td>
                                                                <td>{{ lrReportData.bcChg }}</td>
                                                                <td>{{ lrReportData.insurance }}</td>
                                                                <td>{{ lrReportData.gcCharge }}</td>
                                                                <td>{{ lrReportData.others }}</td>
                                                                <td>{{ lrReportData.ddAmt }}</td>
                                                                <td>{{ lrReportData.toPay }}</td>
                                                                <td>{{ lrReportData.paid }}</td>
                                                                <td>{{ lrReportData.riskCharge }}</td>
                                                                <td>{{ lrReportData.goodsValue }}</td>
                                                                <td>{{ lrReportData.bookingDateStr }}</td>
                                                                <td>{{ lrReportData.destination }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>