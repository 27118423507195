import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
    selector: 'app-agent-expenses',
    templateUrl: './agent-expenses.component.html',
    styleUrls: ['./agent-expenses.component.css']
})
export class AgentExpensesComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number; month: number };
    loadingIndicator = true;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    agentExpensesRptDataList: any;
    onDestroyUnsubscribtionAgentExpensesReport: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAgentExpensesReport: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsAgentExpensesReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    srcSupAdmin = false;
    fortNightStmtDtoUserValues: FortNightStmtDto = new FortNightStmtDto();
    validateFromDate: any;
    validateToDate: any;
    validateAgent: any;

    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
    pageId = "agexp";
    constructor(private masterReadService: MasterReadService, private router: Router, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "AgtExpensesRpt ShowAll") {
                        this.srcSupAdmin = true;
                        break;
                    }
                }
            }
            setTimeout(() => {
                this.getSourceDetails();
            }, 1000);
        }

    }

    ngOnInit(): void {
        this.dtOptionsAgentExpensesReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }


            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalActWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalChgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalTopay = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalPaid = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalLc = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalBC = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalIncentives = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(9) + intVal(b);
                    }, 0);

                var totalPenalty = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalCommission = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalTempoFrgt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalHamali = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);



                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html(totalActWgt);
                $(api.column(4).footer()).html(totalChgWgt);
                $(api.column(5).footer()).html(totalTopay);
                $(api.column(6).footer()).html(totalPaid);
                $(api.column(7).footer()).html(totalLc);
                $(api.column(8).footer()).html(totalBC);
                $(api.column(9).footer()).html(totalIncentives);
                $(api.column(10).footer()).html(totalPenalty);
                $(api.column(11).footer()).html(totalCommission);
                $(api.column(12).footer()).html(totalTempoFrgt);
                $(api.column(13).footer()).html(totalHamali);
            }
        }
    }

    getSourceDetailedList() {
        if (this.srcSupAdmin == true) {
            this.lrDtoSource.mode = 'Booking';
        } else {
            this.lrDtoSource.city = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
    }

    getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.lrDtoSourceOptions = response;
                        this.sourceTA = [];
                        this.lrDtoSourceAllOption.subStations = "All";
                        this.sourceTA.push(this.lrDtoSourceAllOption);
                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.sourceTA.push(this.lrDtoSourceOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getUserValues() {
        this.fortNightStmtDtoUserValues = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateAgent = $("#" + this.pageId + "source").val();

        this.fortNightStmtDtoUserValues.fromDate = this.validateFromDate;
        this.fortNightStmtDtoUserValues.toDate = this.validateToDate;
        this.fortNightStmtDtoUserValues.agentName = this.validateAgent;
        if (this.srcSupAdmin == true) {
            this.fortNightStmtDtoUserValues.mainBranch = null;
        } else {
            this.fortNightStmtDtoUserValues.mainBranch = this.userDataDtoReturnSession.mainAdminStation;
        }
    }
    getDestAgentStmtTrxAmt = () => {
        this.showSpinnerForAction = true;
        this.getUserValues();
        this.destStmtReport.getBookingAgentExpensesReport(this.fortNightStmtDtoUserValues).subscribe(
            (response) => {
                $("#" + this.pageId + "agentExpDetailsRptTableId").DataTable().destroy();
                this.agentExpensesRptDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "No record",
                        text: "No Details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.agentExpensesRptDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerAgentExpensesReport.next();
                }, 500);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while getting the details", "info");
            }, () => console.log('done');
    }

    ngOnDestroy(): void {
        this.dtTriggerAgentExpensesReport.unsubscribe();
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    ngAfterViewInit(): void {
        this.dtTriggerAgentExpensesReport.next();
    }

    clearFields() {
        this.agentExpensesRptDataList = [];
        setTimeout(() => {
            this.dtTriggerAgentExpensesReport.next();
        }, 500);
        this.fortNightStmtDtoUserValues = new FortNightStmtDto();
        this.validateFromDate = null;
        this.validateToDate = null;
        this.validateAgent = null;
        $("#" + this.pageId + "fromDates").val("");
        $("#" + this.pageId + "toDates").val("");
        $("#" + this.pageId + "source").val("");
    }


}
