<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showPaidRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Report Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-question"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}reportType" name="reportType" #reportType (change)="reportTypeMode(reportType.value)">
													<option selected value="Detail">Detail</option>
													<option value="Summary">Summary</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewTypeShow" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>View Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-question"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
													<option selected value="dummy">Date Wise</option>
													<option value="PartyWise">Party Wise</option>
													<!--<option value="AgentWise">Agent Wise</option>-->
													<option value="DestWise">Destination Wise</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Period</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate" placeholder="dd-mm-yyyy" name="fromPeriods" ngbDatepicker #fromPeriods="ngbDatepicker"
                                                    required>
                                                <div class="input-group-append" (click)="fromPeriods.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Period</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toPeriods" ngbDatepicker #toPeriods="ngbDatepicker">
                                                <div class="input-group-append" (click)="toPeriods.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewTypeDestinationWise" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>

                                                    <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewTypeAgentWise" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="ti-user"></i>
													</span>
                                                </div>
                                                <input id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="clickListnerForAgentName($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                    (focus)="focusAgentNameTA$.next($any($event).target.value)" placeholder="Select Agent Name" />
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignor Index</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-road"></i>
													</span>
                                                </div>
                                                <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                    (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}consignorGstNo">
                                                <label>Consignor GST No</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-hashtag"></i>
													</span>
                                                </div>
                                                <input #consignorGSTNumber placeholder="Enter Consignor GST Number" (keyup)="getConsignorGstNumber($event)" type="text" class="form-control" id="{{pageId}}consignorGSTNumber" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">

                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignor Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="rowSelectedConsignor($event)"
                                                    [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateBtnSearch()">Search</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                    </div>


                                </div>
                            </div>

                            <div class="col-md-9 vl">
                                <div *ngIf="showSpinnerForAction" class="col-md-8">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- column -->
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row p-t-10">
                                                    <div class="col-md-8">
                                                        <!-- 																											<h6 class="card-title">Paid Report</h6> -->
                                                    </div>
                                                    <div class="col-md-4" style="display: none;">
                                                        <div class="form-group">
                                                            <div class="input-group">

                                                                <input #hiddenLrNumber type="text" id="{{pageId}}hiddenLrNumber" name="hiddenLrNumber" class="form-control" aria-describedby="basic-addon11">&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <!-- 														<button type="submit" class="btn btn-primary m-r-10" -->
                                                        <!-- 															style="padding: 1px 4px; margin-left: 70px;" -->
                                                        <!-- 															id="{{pageId}}generateMemo" (click)="generateMemo()"> -->
                                                        <!-- 															<i class="fas fa-file-alt"></i> Generate Memo -->
                                                        <!-- 														</button> -->
                                                        <button type="submit" style="margin-left: 71%;" class="dt-button" [hidden]="!paidReportDateNdPartyWiseTable" id="{{pageId}}printBtnForDateWise" (click)="printMethodForDateAndPartyWise();">
															<span><i class="fas fa-print">Print</i></span>
														</button>
                                                        <button type="submit" style="margin-left: 71%;" class="dt-button" [hidden]="!paidReportAgentNdDestinationTable" id="{{pageId}}printBtnForDestWise" (click)="printMethodForDestWise();">
															<span><i class="fas fa-print">Print</i></span>
														</button>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="box-body" [hidden]="!paidReportDateNdPartyWiseTable">
                                                            <table datatable id="{{pageId}}paidReportDateNdPartyWiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPaidReportDateNdPartyWise" [dtTrigger]="dtTriggerPaidReportDateNdPartyWise">

                                                                <thead>
                                                                    <tr>
                                                                        <th>Action</th>
                                                                        <th>LR No</th>
                                                                        <th>Articles</th>
                                                                        <th>Act Wt</th>
                                                                        <th>Chg Wt</th>
                                                                        <th>Consignor</th>
                                                                        <th>Consignee</th>
                                                                        <th>Booking Date</th>
                                                                        <th>Entered Date</th>
                                                                        <th>Bill Number</th>
                                                                        <!-- <th>Ser Tax</th> -->
                                                                        <th>Paid</th>
                                                                        <th>FOV</th>
                                                                        <th>Memo Number</th>
                                                                        <th>Memo Amount</th>
                                                                        <th>Is Print?</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let paidReportDateNdPartyWiseData of paidReportDateNdPartyWiseDataList">
                                                                        <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}printLrBtn" (click)="rowSelectedToGenrtMemoParty(paidReportDateNdPartyWiseData);">
																				<i title="Print Memo"
																					class="fas fa-print"></i>
																			</button></td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.lrNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.totalArticles }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.actualWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.chargedWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.consignorName }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.consigneeName }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.bookingDateStr }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.enteredDateStr }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.billNumber }}
                                                                        </td>
                                                                        <!-- <td>{{ paidReportDateNdPartyWiseData.serviceTax
																			}}</td> -->

                                                                        <td>{{ paidReportDateNdPartyWiseData.paid }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.riskCharge }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.memoNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.grandTotal }}
                                                                        </td>
                                                                        <td>{{ paidReportDateNdPartyWiseData.isPrintStr }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="box-body" [hidden]="!paidReportAgentNdDestinationTable">

                                                            <table datatable id="{{pageId}}paidReportAgentNdDestinationId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPaidReportAgentNdDestination" [dtTrigger]="dtTriggerPaidReportAgentNdDestination">

                                                                <thead>
                                                                    <tr>
                                                                        <th>Action</th>
                                                                        <th>LR Number</th>
                                                                        <th>Art</th>
                                                                        <th>Act Wt</th>
                                                                        <th>Chg Wt</th>
                                                                        <th>Booking Date</th>
                                                                        <th>Entered Date</th>
                                                                        <th>Consignor</th>
                                                                        <th>Consignee</th>
                                                                        <th>Bill Number</th>
                                                                        <th>Invoice No</th>
                                                                        <th>Destination</th>
                                                                        <th>Paid</th>
                                                                        <th>FOV</th>
                                                                        <!-- <th>Ser. Tax</th> -->
                                                                        <th>Source</th>
                                                                        <th>Memo Number</th>
                                                                        <th>Memo Amount</th>
                                                                        <th>Is Print</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let paidReportAgentNdDestinationData of paidReportAgentNdDestinationDataList">
                                                                        <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}printLrBtn" (click)="rowSelectedToGenrtMemoAgent(paidReportAgentNdDestinationData);">
																				<i title="Print Memo"
																					class="fas fa-print"></i>
																			</button></td>
                                                                        <td>{{ paidReportAgentNdDestinationData.lrNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.totalArticles }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.actualWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.chargedWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.bookingDateStr }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.enteredDateStr }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.consignorName }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.consigneeName }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.billNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.invoiceNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.destination }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.paid }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.riskCharge }}
                                                                        </td>
                                                                        <!-- <td>{{
																			paidReportAgentNdDestinationData.serviceTax
																			}}</td> -->

                                                                        <td>{{ paidReportAgentNdDestinationData.agentName }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.memoNumber }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.grandTotal }}
                                                                        </td>
                                                                        <td>{{ paidReportAgentNdDestinationData.isPrintStr }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <!-- SecondTable ends-->
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="box-body" [hidden]="!paidReportSummaryTable">
                                                            <table datatable id="{{pageId}}paidReportSummaryId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPaidReportSummary" [dtTrigger]="dtTriggerPaidReportSummary">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Agent Name</th>
                                                                        <th>LRs</th>
                                                                        <th>Art</th>
                                                                        <th>Act Wt</th>
                                                                        <th>Chg Wt</th>
                                                                        <th>Base Frgt</th>
                                                                        <th>Hamali</th>
                                                                        <th>GC</th>
                                                                        <th>LC</th>
                                                                        <th>BC</th>
                                                                        <th>AOC</th>
                                                                        <th>FOV</th>
                                                                        <th>DD</th>
                                                                        <th>COD</th>
                                                                        <th>LR Amt</th>
                                                                        <th>RC</th>
                                                                        <th>SC</th>
                                                                        <th>D.Hamali</th>
                                                                        <th>Less</th>
                                                                        <th>Memo Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let paidReportSummaryData of paidReportSummaryDataList">
                                                                        <td>{{ paidReportSummaryData.agentId }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.totalLrs }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.articles }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.actualWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.chargedWeight }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.baseFreight }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.hamali }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.gcCharge }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.lcChg }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.bcChg }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.AOC }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.riskCharge }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.ddAmt }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.codChg }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.paid }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.receiptCharge }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.serviceCharge }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.destHamali }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.discountAmount }}
                                                                        </td>
                                                                        <td>{{ paidReportSummaryData.grandTotal }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>
<div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>
<div *ngIf="viewCustomPrintV3" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV3">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>