import { ReportService } from 'src/app/dataService/report-service';
const my = new Date();
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { FormControl } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime"
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
    selector: 'app-tds-details-entry',
    templateUrl: './tds-details-entry.component.html',
    styleUrls: ['./tds-details-entry.component.css']
})
export class TdsDetailsEntryComponent implements OnInit {
    tdsDetailsEntryTableDataList: any;
    lrDtoForCertNoDtls: LRDto = new LRDto();
    certValidationDto: LRDto = new LRDto();
    lrDtoSearch: LRDto = new LRDto();
    cashMemoDtoTDCDiffAmt: CashMemoDto = new CashMemoDto();
    selectedPaymentMode: any;
    selectedTdsLessDiscountType; any;
    isLoggedIn = true;
    lrDtoSaveRet: any;
    saveResp: string;
    validateSavetds: any;
    viewChequeNumber = false;
    viewChequeDate = false;
    viewBankName = false;
    viewReferenceNumber = false;
    viewPaymentMode = false;
    viewDifferenceAmount = true;
    viewTdsDocument = true;
    model: NgbDateStruct;
    model2;
    lrDto: LRDto = new LRDto();
    control = new FormControl();
    userDataDtoReturnSession: any;
    certificateNumber: any;
    tdsLessDiscountType: any;
    toDate: any;
    fromDate: any;
    settlementDate: any;
    remarks: any;
    chequeNumber: any;
    bankName: any;
    chqDate: any;
    tdsAmount: any;
    tdsAmountInputField: any;
    differenceAmount: any;
    tdsDocument: any;
    refrenceNumber: any;
    partyId: any;
    paymentMode: any;
    enteredCertNumber: any;
    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    modelConsigneeName: any;
    searchFromDate: any;
    searchToDate: any;
    showSpinnerForAction = false;
    showSpinnerForAction1 = false;
    showSpinnerForAction2 = false;
    cashMemoDtoTdsDiffReturn: any;
    selectedFileTdsDocument: File;
    isUploaded: boolean = false;
    formData: any;
    id: 0;

    hoveredDate: NgbDateStruct;
    // fromDate: NgbDateStruct;
    // toDate: NgbDateStruct;
    closeResult: string;
    chequeDate: NgbDateStruct;

    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTdsDetailsEntryTable: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsTdsDetailsEntryTable: any;
    pageId = "tsdec";

    // paymentModes = [
    //     { id: 1, paymentMode: 'Cash' },
    //     { id: 2, paymentMode: 'Cheque/PDC' },
    //     { id: 2, paymentMode: 'NEFT/IMPS' },
    // ];

    constructor(private tdsDetailsEntryRpt: ReportService, private http: HttpClient,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService,
        private router: Router, private masterReadService: MasterReadService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getConsigneeDetails();
        }
    }

    ngOnInit(): void {
        this.dtOptionsTdsDetailsEntryTable = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerTdsDetailsEntryTable.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    getUserValuesForTdsEntryMasterList() {
        var masterDto = {
            'city': 'Chennai',
            'gstNumber': '33AABPA9230H2Z8'
        }
        return masterDto;
    }

    getTdsEntryMasterDetailsList = () => {
        let apiUrlConsignorDetails = `http://localhost:8080/srd/master/v1/tdsEntryDetails`;
        this.http.get(apiUrlConsignorDetails, {
            params: {
                // masterDto: this.getUserValuesForTdsEntryMasterList(),
                city: 'Chennai',
            },
        }).subscribe(
            (response) => {
                if (response == 0) {
                    alert('No records found for the selected city!');
                } else {
                    this.tdsDetailsEntryTableDataList = response;
                    this.dtTriggerTdsDetailsEntryTable.next();
                    console.log(this.tdsDetailsEntryTableDataList);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    hideAndShowMethod() {
        let paymentMode = this.selectedPaymentMode;
        if (paymentMode == "Cheque/PDC") {
            this.viewChequeNumber = true;
            this.viewChequeDate = true;
            this.viewBankName = true;
            this.viewReferenceNumber = false;

        } else if (paymentMode == "NEFT/IMPS") {
            this.viewChequeNumber = false;
            this.viewChequeDate = false;
            this.viewBankName = true;
            this.viewReferenceNumber = true;
        } else {
            this.viewChequeNumber = false;
            this.viewChequeDate = false;
            this.viewBankName = false;
            this.viewReferenceNumber = false;

        }
    }

    tdsLessOptionsMethod() {
        let tdsLessDiscountType = this.selectedTdsLessDiscountType;
        if (tdsLessDiscountType == "tdsLessDiscountedInCashmemo") {
            this.viewPaymentMode = false;
            this.viewDifferenceAmount = true;
            this.viewTdsDocument = true;

        } else if (tdsLessDiscountType == "tdsLessNotDiscountedInCashmemo") {
            this.viewPaymentMode = true;
            this.viewDifferenceAmount = false;
            this.viewTdsDocument = false;

        } else {
            this.viewPaymentMode = false;
            this.viewDifferenceAmount = false;
            this.viewTdsDocument = false;


        }
    }

    validateSave() {
        this.certificateNumber = $("#" + this.pageId + "certificateNumber").val();
        //this.partyId = $("#" + this.pageId + "consigneeId").val();
        this.paymentMode = $("#" + this.pageId + "paymentMode").val();
        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        this.settlementDate = $("#" + this.pageId + "settlementDate").val();
        this.remarks = $("#" + this.pageId + "remarks").val();
        this.chequeNumber = $("#" + this.pageId + "chequeNumber").val();
        this.chqDate = $("#" + this.pageId + "chqDate").val();
        this.bankName = $("#" + this.pageId + "bankName").val();
        this.tdsAmountInputField = $("#" + this.pageId + "tdsAmountInputField").val();
        this.differenceAmount = $("#" + this.pageId + "differenceAmount").val();
        this.tdsDocument = $("#" + this.pageId + "tdsDocument").val();

        let tdsLessDiscountType = this.selectedTdsLessDiscountType;
        if (tdsLessDiscountType == "tdsLessDiscountedInCashmemo") {

            if (
                this.certificateNumber == '' || this.certificateNumber == null
                || this.partyId == '' || this.partyId == null
                || this.fromDate == '' || this.fromDate == null
                || this.toDate == '' || this.toDate == null
                || this.settlementDate == '' || this.settlementDate == null
                || this.remarks == '' || this.remarks == null
                || this.tdsAmountInputField == '' || this.tdsAmountInputField == null
                || this.differenceAmount == '' || this.differenceAmount == null
                || this.tdsDocument == '' || this.tdsDocument == null) {
                swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!  less discount", "warning");
            }
            else {
                swal({
                    title: "Confirm Add/Update",
                    text: "Sure you want to Add/Update the entered TDS Details Entry?",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((yes) => {
                    if (yes) {
                        this.saveTdsDetailsEntry();
                    }
                });
            }
        } else if (tdsLessDiscountType == "tdsLessNotDiscountedInCashmemo") {

            let paymentMode = this.selectedPaymentMode;
            if (paymentMode == "Cash") {
                if (this.certificateNumber == '' || this.certificateNumber == null
                    || this.partyId == '' || this.partyId == null
                    || this.paymentMode == '' || this.paymentMode == null
                    || this.fromDate == '' || this.fromDate == null
                    || this.toDate == '' || this.toDate == null
                    || this.settlementDate == '' || this.settlementDate == null
                    || this.remarks == '' || this.remarks == null
                    || this.tdsAmountInputField == '' || this.tdsAmountInputField == null) {
                    swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!  less discount", "warning");

                }
                else {
                    swal({
                        title: "Confirm Add/Update",
                        text: "Sure you want to Add/Update the entered TDS Details Entry?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yes) => {
                        if (yes) {
                            this.saveTdsDetailsEntry();
                        }
                    });
                }

            }
            else if (paymentMode == "Cheque/PDC") {
                if (this.certificateNumber == '' || this.certificateNumber == null
                    || this.partyId == '' || this.partyId == null
                    || this.paymentMode == '' || this.paymentMode == null
                    || this.fromDate == '' || this.fromDate == null
                    || this.toDate == '' || this.toDate == null
                    || this.settlementDate == '' || this.settlementDate == null
                    || this.remarks == '' || this.remarks == null
                    || this.chequeNumber == '' || this.chequeNumber == null
                    || this.chqDate == '' || this.chqDate == null
                    || this.bankName == '' || this.bankName == null
                    || this.tdsAmountInputField == '' || this.tdsAmountInputField == null) {
                    swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!  less discount", "warning");

                }
                else {
                    swal({
                        title: "Confirm Add/Update",
                        text: "Sure you want to Add/Update the entered TDS Details Entry?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yes) => {
                        if (yes) {
                            this.saveTdsDetailsEntry();
                        }
                    });
                }

            } else if (paymentMode == "NEFT/IMPS") {
                if (this.certificateNumber == '' || this.certificateNumber == null
                    || this.partyId == '' || this.partyId == null
                    || this.paymentMode == '' || this.paymentMode == null
                    || this.fromDate == '' || this.fromDate == null
                    || this.toDate == '' || this.toDate == null
                    || this.settlementDate == '' || this.settlementDate == null
                    || this.remarks == '' || this.remarks == null
                    || this.bankName == '' || this.bankName == null
                    || this.tdsAmountInputField == '' || this.tdsAmountInputField == null
                    || this.refrenceNumber == '' || this.refrenceNumber == null) {
                    swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!not diiscount ", "warning");

                }
                else {
                    swal({
                        title: "Confirm Add/Update",
                        text: "Sure you want to Add/Update the entered TDS Details Entry?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yes) => {
                        if (yes) {
                            this.saveTdsDetailsEntry();
                        }
                    });
                }
            }
        }


    }

    tdsDetailsEntrySaveMethod() {
        this.lrDto = new LRDto();
        this.certificateNumber = $("#" + this.pageId + "certificateNumber").val();
        // this.selectedTdsLessDiscountType = $("#" + this.pageId + "selectedTdsLessDiscountType").val();
        this.paymentMode = $("#" + this.pageId + "paymentMode").val();
        //this.partyId = $("#" + this.pageId + "consigneeId").val();
        this.tdsAmountInputField = $("#" + this.pageId + "tdsAmountInputField").val();
        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        this.settlementDate = $("#" + this.pageId + "settlementDate").val();
        this.chequeNumber = $("#" + this.pageId + "chequeNumber").val();
        this.chqDate = $("#" + this.pageId + "chqDate").val();
        this.bankName = $("#" + this.pageId + "bankName").val();
        this.differenceAmount = $("#" + this.pageId + "differenceAmount").val();
        this.tdsDocument = $("#" + this.pageId + "tdsDocument").val();
        this.refrenceNumber = $("#" + this.pageId + "refrenceNumber").val();
        this.remarks = $("#" + this.pageId + "remarks").val();

        if (this.isUploaded) {
            if ($("#"+this.pageId+"tdsDocumentFileUpload").val() != null && $("#"+this.pageId+"tdsDocumentFileUpload").val() != "") {
                const constImgUrlTds = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#"+this.pageId+"certificateNumber").val() + "_" + this.selectedFileTdsDocument.name + "";
                this.lrDto.imgURLTDS = constImgUrlTds;
                this.lrDto.imgUploadedTDS = true;
            }
        } else {
            this.lrDto.imgUploadedTDS = false;
        }



        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.office = this.userDataDtoReturnSession.office;
        this.lrDto.tdsCertfNo = this.certificateNumber;
        // this.lrDto.tdsLessDiscountType = this.selectedTdsLessDiscountType;
        this.lrDto.partyId = this.partyId;
        console.log("this.partyId")
        console.log(this.partyId)
        this.lrDto.settlementAmt = this.tdsAmountInputField;
        this.lrDto.settlementMode = this.paymentMode;
        this.lrDto.fromdate = this.fromDate;
        this.lrDto.todate = this.toDate;
        this.lrDto.settlementDate = this.settlementDate;
        this.lrDto.chequeNumber = this.chequeNumber;
        this.lrDto.chequeDate = this.chqDate;
        this.lrDto.bankName = this.bankName;
        // this.lrDto.partyId = this.partyId;
        this.lrDto.diffValue = this.differenceAmount;
        this.lrDto.docStatusTds = this.tdsDocument;
        this.lrDto.refNumber = this.refrenceNumber;
        this.lrDto.remarks = this.remarks;


        // this.lrDto.id = this.id;
        console.log("this.lrdto");
        console.log(this.lrDto);
    }

    saveTdsDetailsEntry = () => {
        this.tdsDetailsEntrySaveMethod();
        this.masterService.saveTdsDetailsEntryDetails(this.lrDto).
            subscribe((data) => {
                this.lrDtoSaveRet = data;
                if (this.lrDtoSaveRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "TDS Details Entry saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Save of TDS Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Save TDS Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');




    };

    certNumberOnEnter(e) {
        //if (e.keyCode == 13) {
        this.checkCertificateNoDetails();
        //}
    }

    checkMethodForCertificateNoDtls() {
        this.lrDtoForCertNoDtls = new LRDto();
        this.enteredCertNumber = $("#" + this.pageId + "certificateNumber").val();
        this.lrDtoForCertNoDtls.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForCertNoDtls.tdsCertfNo = this.enteredCertNumber;
    }
    checkCertificateNoDetails() {
        this.checkMethodForCertificateNoDtls();
        this.masterService.checkCertNo(this.lrDtoForCertNoDtls).subscribe(
            (response) => {
                if (response) {
                    console.log('certValidationDto');
                    this.certValidationDto = new LRDto();
                    this.certValidationDto = response;
                    if (this.certValidationDto.isCertNo == false) {
                    } else {
                        swal({
                            title: "Not Allowed",
                            text: "Certificate Number : " + this.enteredCertNumber + " is already exist, please verify the certificate number!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            swal.close();
                            window.setTimeout(function () {
                                $("#" + this.pageId + "certificateNumber").val('');
                                $("#" + this.pageId + 'certificateNumber').focus();
                            }, 100);
                        })
                    }
                }

                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while checking Entered Certificate Number Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getConsigneeDetailsList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
    }
    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        // this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                // this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeId").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                // this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A 
            el.focus();
        }
    }
    consigneeDropDownListner(event) {
        this.partyId = event.item.consigneeId;
    }

    getTdsDetailsRead() {
        this.lrDtoSearch = new LRDto();
        this.searchFromDate = $("#" + this.pageId + "searchFromDate").val();
        this.searchToDate = $("#" + this.pageId + "searchToDate").val();

        this.lrDtoSearch.fromdate = this.searchFromDate;
        this.lrDtoSearch.todate = this.searchToDate;
    }

    getTdsDetailsList = () => {
        this.getTdsDetailsRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getTdsDetailsEntrySearch(this.lrDtoSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.tdsDetailsEntryTableDataList = response;
                    $("#" + this.pageId + "tdsDetailsDatatableId").DataTable().destroy();
                    this.dtTriggerTdsDetailsEntryTable.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting TDS Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        this.fromDate = $("#" + this.pageId + "fromDate").val('');
        this.toDate = $("#" + this.pageId + "toDate").val('');
        this.settlementDate = $("#" + this.pageId + "settlementDate").val('');
        this.paymentMode = $("#" + this.pageId + "paymentMode").val('');
        this.certificateNumber = null;
        this.paymentMode = null;
        this.fromDate = null;
        this.toDate = null;
        this.settlementDate = null;
        this.remarks = null;
        this.chequeNumber = null;
        this.chqDate = null;
        this.bankName = null;
        this.tdsAmountInputField = null;
        this.differenceAmount = null;
        this.tdsDocument = null;
        this.fromDate = '';
        this.toDate = '';
        this.settlementDate = '';
        this.selectedPaymentMode = null;
        this.paymentMode = '';
        this.partyId = 0;
        $("#"+this.pageId+"tdsDocumentFileUpload").val('');
    }

    getTdsButton() {
        this.cashMemoDtoTDCDiffAmt = new CashMemoDto();
        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        // this.partyId = $("#" + this.pageId + "consigneeId").val();

        this.cashMemoDtoTDCDiffAmt.fromDate = this.fromDate;
        this.cashMemoDtoTDCDiffAmt.toDate = this.toDate;
        this.cashMemoDtoTDCDiffAmt.consigneeId = this.partyId;

        console.log("this.cashMemoDtoTDCDiffAmt");
        console.log(this.cashMemoDtoTDCDiffAmt);
    }

    getTdsButtonList = () => {
        this.getTdsButton();
        this.showSpinnerForAction1 = true;
        this.masterReadService.getTotalMemoLessAmountForTDSEntry(this.cashMemoDtoTDCDiffAmt).subscribe(
            (response) => {
                this.showSpinnerForAction1 = false;
                if (response) {
                //     swal({
                //         title: "Success",
                //         text: "Total of Diff Amount",
                //         icon: "success",
                //         closeOnClickOutside: false,
                //         closeOnEsc: false,
                //     });
                // } else {
                    this.cashMemoDtoTdsDiffReturn = response;
                    if (this.cashMemoDtoTdsDiffReturn.length > 0) {
                        console.log(this.cashMemoDtoTdsDiffReturn[0].less);
                        $("#" + this.pageId + "differenceAmount").val(this.cashMemoDtoTdsDiffReturn[0].less);
                    }
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Found of TDS Diff Amt",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    validateTdsButton() {

        this.fromDate = $("#" + this.pageId + "fromDate").val();
        this.toDate = $("#" + this.pageId + "toDate").val();
        // this.partyId = $("#" + this.pageId + "consigneeId").val();


        if (this.fromDate == '' || this.fromDate == null
            || this.toDate == '' || this.toDate == null
            || this.partyId == '' || this.partyId == null) {
            swal("Not Allowed", "Please select the fromDate,toDate,partId they are mandatory fields to proceed!", "warning");
        }
        else {
            
             this.getTdsButtonList();
            
        }
    }

    onFileChangedTdsDocument(event) {
        this.selectedFileTdsDocument = event.target.files[0];
        // console.log(this.selectedFileDriverDetailsForLicense);
    }
    uploadFileForTdsDocumentBtnUpload() {
        if ($("#"+this.pageId+"tdsDocumentFileUpload").val() != null && $("#"+this.pageId+"tdsDocumentFileUpload").val() != "") {
            if ((this.selectedFileTdsDocument.type != null &&
                (this.selectedFileTdsDocument.type == "image/jpeg" ||
                    this.selectedFileTdsDocument.type == "image/jpg" ||
                    this.selectedFileTdsDocument.type == "image/png"))) {
                var splitTypeDriverLic = this.selectedFileTdsDocument.type.split("/");
                const commonListTdsDocument = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeDriverLic[1] + "&&" + $("#"+this.pageId+"certificateNumber").val() + "";
                const formData = new FormData();
                formData.append(commonListTdsDocument, commonListTdsDocument);
                formData.append('myfileTds', this.selectedFileTdsDocument, this.selectedFileTdsDocument.name);
                this.showSpinnerForAction2 = true;
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        this.showSpinnerForAction2 = false;
                        if (response) {
                            if (response[0] != "Error") {
                                this.isUploaded = true;
                                swal("File Upload", "File Uploaded Succesfully", "success");
                            } else {
                                swal("ERROR", "Correct the File and Upload Again", "warning");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal("Error", "Server Error While Uploading a File", "error"),
                    () => console.log('done');
            } else {
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload for Tds File", "warning");
            }
        }
    }

    getTdsDocImg(tdsDetailsEntryTableData) {
        console.log(tdsDetailsEntryTableData);
        if (tdsDetailsEntryTableData.imgURLTDS == null || tdsDetailsEntryTableData.imgURLTDS == undefined ||
            tdsDetailsEntryTableData.imgURLTDS == "NA") {
            swal("TdsDoc", "TDS Not Uploaded", "info");
            return false;
        } else {
            window.open(tdsDetailsEntryTableData.imgURLTDS, '_blank');
        }
    }
}
