export class DriverdetailsDto {
    driverName: string;
    licenceNo: string;
    mobileNo: string;
    address: string;
    DLIssState: string;
    pin: string;
    id: number;
    mode: string;
    panNo: string;
    DLIssStateCode: string;
    imgURL: string;
    imgUploaded: boolean;
    mainStation: string;
    companyId: string;
    userId: string;
    status: string;
    vehicleNumber: string;
    loaderHead: string;
}