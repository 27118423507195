import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends

@Component({
	selector: 'app-collectionman-stocks-print',
	templateUrl: './collectionman-stocks-print.component.html',
	styleUrls: ['./collectionman-stocks-print.component.css']
})
export class CollectionManStocksPrintComponent implements OnInit {

	//for popup modal starts
	closeResultDatatable: string;
	//for popup modal ends

	//for datatable starts
	gettingDataFrmServiceFrPendingLrDetailsTable: any;
	collectionManDetailsDataList: any;
	onDestroyUnsubscribtionPendingLrDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerCollectionManStockPrintDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsCollectionManStockPrintDetails: any;
	//for datatable ends


	loadingIndicator = true;


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	collectionManStockPrintDataLocalStorage: any;
	totalCount: number;
	totalAmount: number;
	totalArticle: number;

	//for datatable ends
	pageId="cmspc";

	constructor(private pendingLrDetailsScreen: DashboardService, private router: Router) {
		console.log(localStorage);
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.collectionManStockPrintDataLocalStorage = JSON.parse(localStorage.getItem('collectionManStockPrint'));
			localStorage.clear();
			console.log(this.collectionManStockPrintDataLocalStorage);
			if (this.collectionManStockPrintDataLocalStorage != null &&
				this.collectionManStockPrintDataLocalStorage != undefined &&
				this.collectionManStockPrintDataLocalStorage != "") {
				this.collectionManDetailsDataList = [];
				this.collectionManDetailsDataList = this.collectionManStockPrintDataLocalStorage;
				this.totalCount = 0;
				this.totalAmount = 0.0;
				this.totalArticle = 0.0;
				for (let i = 0; i < this.collectionManDetailsDataList.length; i++) {
					this.totalCount = i + +1;
					this.totalAmount = +this.totalAmount + +this.collectionManDetailsDataList[i].grandTotal;
					this.totalArticle = +this.totalArticle + +this.collectionManDetailsDataList[i].totalArticles;
				}
			}
			window.setTimeout(function () {
				window.print();
			}, 1000);
			window.onafterprint = function () {
				window.close();
			}
			localStorage.clear();
			window.addEventListener('afterprint', (event) => {
				this.clearField();
			});
		}

	}
	/* for datatable starts */
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	/* for datatable ends */
	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.dtTriggerCollectionManStockPrintDetails.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerCollectionManStockPrintDetails.next();

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clearField() {
		this.collectionManDetailsDataList = [];
		localStorage.clear();
		this.totalCount = 0;
		this.totalAmount = 0.0;
		this.totalArticle = 0.0;
	}

}
