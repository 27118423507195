import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { LrService } from 'src/app/dataService/lr-service';
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-challan',
    templateUrl: './challan.component.html',
    styleUrls: ['./challan.component.css']
})
export class ChallanComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    chellanDetailsDataList: any;
    onDestroyUnsubscribtionChellanDetails: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    dateDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    control = new FormControl();

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerChellanDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsChellanDetails: any;

    //For Agent Name
    agentNameDtoForAll: AgentDetailsDto = new AgentDetailsDto();
    focusAgentNameTA$ = new Subject<string>();
    clickAgentNameTA$ = new Subject<string>();
    @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
    agentNameTA: Array<any> = [];
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    controlAgentName = new FormControl();
    agentDetailsOptionsGet: AgentDetailsDto[];
    agentDetailsOptionsSet: any;
    @ViewChild("agentName") agentNameField: ElementRef;

    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    superAdminview: boolean = false;

    agentNameList: Array<any> = [];
    address: any;
    isLoggedIn = true;
    showCode = '';
    dateRange: any;
    enteredFromChallanNo: any;
    enteredToChallanNo: any;
    lrDtoForOnLoad: LRDto = new LRDto();
    selectedAgentName: any;
    selectedDate: any;
    lrDtoForOnCheckChallanExist: LRDto = new LRDto();
    enteredNoOfChallans: any;
    lrDtoForSave: LRDto = new LRDto();
    lrDtoForRemove: LRDto = new LRDto();
    lrAgentName: any;
    pageId="chncp";
    constructor(private chellanDetailsRpt: MasterService,
        private masterReadService: MasterReadService,
        private lrService: LrService, private datePipe: DatePipe,
        private router: Router, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.address =
                this.userDataDtoReturnSession.addressId == null
                    ? ""
                    : this.userDataDtoReturnSession.addressId;
            this.getAgentDetailList();
        }
    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {


        this.dtOptionsChellanDetails = {


            dom: 'Bfrtip',

            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: [0, 1, 2, 3, 4]
                },

                title: function () {

                    var returExtendHistroyDate = null;



                    returExtendHistroyDate = "Challan Issue Details ";
                    return returExtendHistroyDate;
                }
            }

            ],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html();
                $(api.column(5).footer()).html();



            }


        }
    }




    ngOnDestroy(): void {
        this.dtTriggerChellanDetails.unsubscribe();

        //  this.onDestroyUnsubscribtionChellanDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerChellanDetails.next();


    }





    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends

    //For Agent Names
    getUserValuesForAgentDetailsList() {
        this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.agentDtoParam.column2 = 'Working';
        //  this.agentDtoParam.status = 'Working';

        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mode = 'Sub';
    }
    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();

        this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    console.log(response);
                    swal({
                        title: "Warning",
                        text: "No Agents  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlAgentName.reset();
                    //                    this.agentDetailsOptionsGet = [];
                    //                    this.agentDtoAll.subStation = "Add New";
                    //                    this.agentDetailsOptionsGet.push( this.agentDtoAll )
                } else {
                    this.agentDetailsOptionsGet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsGet = response;
                    this.agentDetailsOptionsSet = response;
                    //                   
                    console.log(this.agentDetailsOptionsGet);
                    this.agentNameList = [];
                    // this.agentNameDtoForAll.subStation = "ALL";
                    // this.agentNameTA.push(this.agentNameDtoForAll);

                    for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
                        //console.log( this.agentDetailsOptionsGet[i].subStation );
                        this.agentNameTA.push(this.agentDetailsOptionsGet[i]);
                        this.agentNameList.push(this.agentDetailsOptionsGet[i].subStation);
                    }
                    this.getDetailsOnLoad();

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    dateFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#chncpfromChallanNumber").focus();
            }, 100);
        }
    }
    agentNameFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#chncpdate").focus();
            }, 100);
        }
    }
    fromChallanNoFocus(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#chncptoChallanNumber").focus();
            }, 100);
        }
    }
    toChallanNoFocus(e) {
        if (e.keyCode == 13) {
            this.enteredFromChallanNo = $("#"+this.pageId+"fromChallanNumber").val();
            this.enteredToChallanNo = $("#"+this.pageId+"toChallanNumber").val();
            if (this.enteredFromChallanNo == null || this.enteredFromChallanNo == '' || this.enteredToChallanNo == null || this.enteredToChallanNo == '') {
                swal({
                    title: "Mandetory",
                    text: "Please Select From Challan and To Challan  ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.validateBetweenLrNos();
            }

        }
    }
    validateBetweenLrNos() {
        this.enteredFromChallanNo = $("#"+this.pageId+"fromChallanNumber").val();
        this.enteredToChallanNo = $("#"+this.pageId+"toChallanNumber").val();
        let fromChallan = this.enteredFromChallanNo;
        let toChallan = this.enteredToChallanNo;
        if (fromChallan > toChallan) {
            swal({
                title: "Wrong Entry",
                text: "To Value Should be Greater Than From Value ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#"+this.pageId+"fromChallanNumber").val('');
            $("#"+this.pageId+"toChallanNumber").val('');
            window.setTimeout(function () {
                $("#chncpfromChallanNumber").focus();
            }, 100);

        } else {
            let balanceChallanVal = 0;
            balanceChallanVal = this.enteredToChallanNo - this.enteredFromChallanNo + 1;
            console.log(balanceChallanVal);
            $("#"+this.pageId+"numberOfChallans").val(balanceChallanVal);
            window.setTimeout(function () {
                $("#chncpsaveBtn").focus();
            }, 100);
        }
    }



    getDetailsOnLoad() {
        this.lrDtoForOnLoad = new LRDto();
        this.lrDtoForOnLoad.list = this.agentNameList;
        this.lrDtoForOnLoad.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForOnLoad);
        this.getDetailsOnLoadMethod();

    }
    getDetailsOnLoadMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.getChallanIssueDetails(this.lrDtoForOnLoad).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"chellanDetailsId").DataTable().destroy();
                this.chellanDetailsDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.chellanDetailsDataList = response;

                }
                this.dtTriggerChellanDetails.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Challan Issue  details", "info");
            }, () => console.log('done');
    };

    btnValidationCheck() {
        this.enteredFromChallanNo = $("#"+this.pageId+"fromChallanNumber").val();
        this.enteredToChallanNo = $("#"+this.pageId+"toChallanNumber").val();
        if (this.enteredFromChallanNo == null || this.enteredFromChallanNo == '' || this.enteredToChallanNo == null || this.enteredToChallanNo == '') {
            swal({
                title: "Mandetory",
                text: "Please Select From Challan and To Challan  ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            let fromChallan = this.enteredFromChallanNo;
            let toChallan = this.enteredToChallanNo;
            if (fromChallan > toChallan) {
                swal({
                    title: "Wrong Entry",
                    text: "To Value Should be Greater Than From Value ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                $("#"+this.pageId+"fromChallanNumber").val('');
                $("#"+this.pageId+"toChallanNumber").val('');
                window.setTimeout(function () {
                    $("#chncpfromChallanNumber").focus();
                }, 100);

            } else {
                this.btnSaveValidate();
            }

        }

    }
    btnSaveValidate() {
        this.enteredFromChallanNo = $("#"+this.pageId+"fromChallanNumber").val();
        this.enteredToChallanNo = $("#"+this.pageId+"toChallanNumber").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();
        this.selectedDate = $("#"+this.pageId+"date").val();
        this.enteredNoOfChallans = $("#"+this.pageId+"numberOfChallans").val();

        if (this.enteredFromChallanNo == null || this.enteredFromChallanNo == '' || this.enteredToChallanNo == null || this.enteredToChallanNo == '' || this.selectedAgentName == null || this.selectedAgentName == '' || this.selectedDate == null || this.selectedDate == '' || this.enteredNoOfChallans == null || this.enteredNoOfChallans == '') {
            swal({
                title: "Mandetory",
                text: "Please enter the Mandetory Fields ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.lrDtoForOnCheckChallanExist = new LRDto();
            this.lrDtoForOnCheckChallanExist.fromChallanNo = this.enteredFromChallanNo;
            this.lrDtoForOnCheckChallanExist.toChallanNo = this.enteredToChallanNo;
            this.lrDtoForOnCheckChallanExist.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.lrDtoForOnCheckChallanExist);
            this.lrDtoForOnCheckChallanExistMethod();
        }
    }

    lrDtoForOnCheckChallanExistMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.chkChallanRangeExist(this.lrDtoForOnCheckChallanExist).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "Exist") {
                    swal({
                        title: "Not Allowed",
                        text: "Already Exist",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.saveDetails();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Checking the LR Issue details", "info");
            }, () => console.log('done');
    };

    saveDetails() {

        this.enteredFromChallanNo = $("#"+this.pageId+"fromChallanNumber").val();
        this.enteredToChallanNo = $("#"+this.pageId+"toChallanNumber").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();
        this.selectedDate = $("#"+this.pageId+"date").val();
        this.enteredNoOfChallans = $("#"+this.pageId+"numberOfChallans").val();

        this.lrDtoForSave = new LRDto();
        this.lrDtoForSave.agentName = this.selectedAgentName;
        this.lrDtoForSave.dateInDate = this.selectedDate;
        this.lrDtoForSave.fromChallanNo = this.enteredFromChallanNo;
        this.lrDtoForSave.toChallanNo = this.enteredToChallanNo;
        this.lrDtoForSave.noOfChallan = this.enteredNoOfChallans;
        this.lrDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForSave.dateInDate = new Date();

        console.log(this.lrDtoForSave);
        this.saveMethod();

    }
    saveMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.addChallanIssueDetails(this.lrDtoForSave).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "success") {
                    swal({
                        title: "Success",
                        text: "Challan Range for This Agent Saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.getDetailsOnLoad();
                    this.clearFields();
                    // by kamil
                    window.setTimeout(function () {
                        $("#chncpagentName").focus();
                    }, 100);
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Adding  the Challan Issue details", "info");
            }, () => console.log('done');
    };

    clearFields() {
        this.enteredFromChallanNo = '';
        $("#"+this.pageId+"fromChallanNumber").val('');
        this.enteredToChallanNo = '';
        $("#"+this.pageId+"toChallanNumber").val('');
        this.selectedAgentName = '';
        $("#"+this.pageId+"agentName").val('');
        this.selectedDate = '';
        $("#"+this.pageId+"date").val('');
        this.enteredNoOfChallans = '';
        $("#"+this.pageId+"numberOfChallans").val('');
        this.dateRange = null;
    }

    rowRemove(chellanDetailsData) {

        swal({
            title: "Remove",
            text: "Sure U Want to Remove the Selected Challan Range",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.getChallanDetailsToRemove(chellanDetailsData);
            }
        });
    }

    getChallanDetailsToRemove(chellanDetailsData) {
        this.lrDtoForRemove = new LRDto();
        this.lrDtoForRemove.id = chellanDetailsData.id;
        this.lrDtoForRemove.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForRemove);
        this.removeMethod();

    }


    removeMethod = () => {
        this.showSpinnerForAction = true;
        this.lrService.removeChallanissueDetails(this.lrDtoForRemove).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "removed") {
                    swal({
                        title: "Success",
                        text: "Challan Range for This Agent Removed Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.getDetailsOnLoad();
                    this.clearFields();
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Removing  the Challan  Issue details", "info");
            }, () => console.log('done');
    };

    clearAll() {
        this.clearFields();
        this.showSpinnerForAction = false;
        this.lrDtoForRemove = new LRDto();
        this.lrDtoForSave = new LRDto();
        this.lrDtoForOnCheckChallanExist = new LRDto();
        this.dateRange = null;
        this.getDetailsOnLoad();
    }
   

}
