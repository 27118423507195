import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";

import { LrDetailsOfRemainingCashMemosComponent } from "src/app/dashboards/dashboards-popup/lr-details-of-remaining-cash-memos/lr-details-of-remaining-cash-memos.component";


//import {Subscription} from 'rxjs';
//for modal ends
@Component( {
    selector: 'app-cashmemo-pending-from-binnymills',
    templateUrl: './cashmemo-pending-from-binnymills.component.html',
    styleUrls: ['./cashmemo-pending-from-binnymills.component.css']
} )
export class CashmemoPendingFromBinnymillsComponent implements  OnInit {
    getDataCashMemoPendingFromBinnymillsDataTable: any;
    cashMemoPendingFromBinnymillsDataList: any;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerCashMemoPendingFromBinnymills: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCashMemoPendingFromBinnymills: any;
    //for modal starts
    animal: string;
    name: string;
    routeQueryParams$: Subscription;
    //for modal ends
	cashmemoDtoReload:CashMemoDto =  new CashMemoDto();
	lrDtoForDetails:LRDto =  new LRDto();
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	lrDtoForRemMos:LRDto =  new LRDto();
	responseForRemMos:any;
	cashmemoDtoForBillling:CashMemoDto =  new CashMemoDto();
	showSpinnerForAction=false;
	pageId="cpfbc";
   constructor(
        private dashboardService: DashboardService,
        //for modal starts
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
        //for modal ends        
    ) {
    	  if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          }
       /* this.routeQueryParams$ = route.queryParams.subscribe( params => {
            if ( params['dialog'] ) {
                this.remMemosDialog();
            }
        } );*/
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

ngOnInit(): void {

        this.dtOptionsCashMemoPendingFromBinnymills = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
          
           
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerCashMemoPendingFromBinnymills.unsubscribe();
    //   //  this.routeQueryParams$.unsubscribe();
    //     //for modal ends
    // }
    ngAfterViewInit(): void {
        this.dtTriggerCashMemoPendingFromBinnymills.next();

    }
	
	remMemosDialog(cashMemoPendingFromBinnymillsData): void {
    	if(cashMemoPendingFromBinnymillsData.remMemos !=0){
		this.getRemMemosLrDetailsInCollectionDept();	 
    	}
    }
    
   //for modal ends
	
	gridReconifgureOnReloadBtn(){
			this.cashmemoDtoReload=  new CashMemoDto();
			this.cashmemoDtoReload.destination=this.userDataDtoReturnSession.mainStation;
			this.cashmemoDtoReload.list=['transferToGodown'];
			this.cashmemoDtoReload.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.cashmemoDtoReload);
			this.gridReconifgureDetails();
	}
	gridReconifgureDetails= () => {
		   this.showSpinnerForAction = true;
    	         this.dashboardService.getCashMamoBatchTrnsToGdw(this.cashmemoDtoReload).subscribe(
    	             (response) => {
    	             this.showSpinnerForAction=false;
						 // $("#"+this.pageId+"cashMemoPendingFromBinnymillsId").DataTable().destroy();
							this.cashMemoPendingFromBinnymillsDataList=[];
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.cashMemoPendingFromBinnymillsDataList=response;
    	               	  console.log(this.cashMemoPendingFromBinnymillsDataList);
    	                }
    	            //   this.dtTriggerCashMemoPendingFromBinnymills.next();
					  this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the cashMemo Pending And Missing Details", "info");
                    }, () => console.log('done');
            };
            
         
		   
	batchDialog(cashMemoPendingFromBinnymillsData): void {
    	if(cashMemoPendingFromBinnymillsData.batchNumber !=0){
			this.cashmemoDtoForBillling = new CashMemoDto();
			this.cashmemoDtoForBillling =cashMemoPendingFromBinnymillsData;
			localStorage.clear();
			localStorage.setItem('cashmemoPendingFromBinnyForBillingPopup',
										JSON.stringify(this.cashmemoDtoForBillling));
           	 this.router.navigate(['/cashmemo/transfer-cashmemo/billingToSrdCollectionStock']);
    	}
    }
			
	getRemMemosLrDetailsInCollectionDept(){
		
			this.lrDtoForDetails=  new LRDto();
			this.lrDtoForDetails.destination=this.userDataDtoReturnSession.mainStation;
			this.lrDtoForDetails.godownName=this.userDataDtoReturnSession.office;
			this.lrDtoForDetails.list=['transferToGodown'];
			this.lrDtoForDetails.mode='transferToGodown';
			this.lrDtoForDetails.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.lrDtoForDetails);
			this.getRemMemosLrDetailsInCollectionDeptDetails();
	}
	getRemMemosLrDetailsInCollectionDeptDetails= () => {
    	         this.showSpinnerForAction = true;
    	         this.dashboardService.getRemMemosLrDetailsInCollectionDept(this.lrDtoForDetails).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
						 
						 this.lrDtoForRemMos= new LRDto();
						 this.responseForRemMos= response;
						 this.lrDtoForRemMos=this.responseForRemMos;
						 console.log( this.lrDtoForRemMos);
						 localStorage.clear();
							localStorage.setItem('cashmemoPendingFromBinnyPopup',
										JSON.stringify(this.lrDtoForRemMos));
    	               
							  const dialogRef = this.dialog.open( LrDetailsOfRemainingCashMemosComponent, {
							
							} );

						// dialogRef.afterClosed().subscribe( result => {
						// 	console.log( 'The dialog was closed' );
						// 	this.router.navigate( ['.'], { relativeTo: this.route } );
						// } );
							 
    	                }
						this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the cashMemo Pending From Binnymills Details", "info");
                    }, () => console.log('done');
            };
}
