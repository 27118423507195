<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBkgAgentDispPerReport">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Booking Agent Dispatch
						Performance Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewType"
															name="viewType" #viewType
															(change)="viewTypeMode(viewType.value)">
															<option selected value="summary">Summary</option>
															<option value="detail">Detail</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" id="{{pageId}}fromDates"
															ngbDatepicker #fromDates="ngbDatepicker"
															[(ngModel)]="fromDatesModal" autocomplete="off">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates"
															class="form-control" placeholder="yyyy-mm-dd"
															name="toDates" ngbDatepicker #toDates="ngbDatepicker"
															[(ngModel)]="toDatesModal" autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12"
												[hidden]="!hideshowDropDownSourceField">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<input id="{{pageId}}sourceId" type="text" class="form-control"
															[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
															(selectItem)="sourceListener($event)"
															[resultFormatter]="formatterSource"
															[inputFormatter]="formatterSource"
															(focus)="focusSourceTA$.next($any($event).target.value)"
															placeholder="Select Source" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text" class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="validateClearBtn();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<h6
							[hidden]="!hideshowBookingAgentDispatchPerformanceSummaryRptTable">
							Booking Agent Dispatch Performance - Summary</h6>
						<h6
							[hidden]="!hideshowBookingAgentDispatchPerformanceDetailsRptTable">
							Booking Agent Dispatch Performance - Details</h6>
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>

						<button type="submit" class="dt-button" style="margin-left: 74%;"
							(click)="customPrintBookingAgentDispPerSummary()"
							id="{{pageId}}printAllBtn"
							[hidden]="!hideshowBookingAgentDispatchPerformanceSummaryRptTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>


						<button type="submit" class="dt-button" style="margin-left: 74%;"
							(click)="customPrintBookingAgentDispPerDetail()" id="{{pageId}}printAllBtn"
							[hidden]="!hideshowBookingAgentDispatchPerformanceDetailsRptTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>

						<div class="box-body"
							[hidden]="!hideshowBookingAgentDispatchPerformanceSummaryRptTable">
							<table datatable
								id="{{pageId}}bookingAgentDispatchPerformanceSummaryRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">
								<thead>
									<tr>
										<th>Agent</th>
										<th>Booking Date</th>
										<th>Total LRs</th>
										<th>Articles</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let smryData of summaryDataList ">
										<td>{{ smryData.agentName }}</td>
										<td>{{ smryData.bookingDateStr }}</td>
										<td>{{ smryData.totalLrs }}</td>
										<td>{{ smryData.totalArticles }}</td>
										<td>{{ smryData.actualWeight }}</td>
										<td>{{ smryData.chargedWeight }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
						<div class="box-body"
							[hidden]="!hideshowBookingAgentDispatchPerformanceDetailsRptTable">
							<table datatable
								id="{{pageId}}bookingAgentDispatchPerformanceDetailsRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
								<thead>
									<tr>
										<th>Agent Name</th>
										<th>LR Number</th>
										<th>Diff(Entry-Bkg)</th>
										<th>Booking Date</th>
										<th>Entry Date</th>
										<th>Bill Date</th>
										<th>Diff(Bkg-Bill)</th>
										<th>Diff(Entry-Bill)</th>
										<th>Collection Centre</th>
										<th>Destination</th>
										<th>Articles</th>

										<th>Act Wt</th>
										<th>Chg Wt</th>
										<th>Consignee Name</th>
										<th>Consignor Name</th>
										<th>Commodity Name</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let detailData of detailDataList ">
										<td>{{ detailData.agentName }}</td>
										<td>{{ detailData.lrNumber }}</td>
										<td>{{ detailData.dispatchedwithin }}</td>
										<td>{{ detailData.bookingDateStr }}</td>
										<td>{{ detailData.enteredDateStr }}</td>
										<td>{{ detailData.billDateStr }}</td>
										<td>{{ detailData.diffBkgBillDate }}</td>
										<td>{{ detailData.diffEntryBillDate }}</td>
										<td>{{ detailData.source }}</td>
										<td>{{ detailData.destination }}</td>
										<td>{{ detailData.totalArticles }}</td>

										<td>{{ detailData.actualWeight }}</td>
										<td>{{ detailData.chargedWeight }}</td>
										<td>{{ detailData.consigneeName }}</td>
										<td>{{ detailData.consignorName }}</td>
										<td>{{ detailData.commodityName }}</td>
										<td>{{ detailData.status }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>

										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>

</body>

</html>