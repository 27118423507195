<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Commodity Fieldset</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-3">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Search By</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-search"></i>
														</span>
													</div>
													<select #searchBy id="{{pageId}}searchBy" name="searchBy"
														[(ngModel)]="rateMasterDto.searchType"
														(change)="seachBy(searchBy.value)"
														class="custom-select col-12">
														<option selected>Select Type</option>
														<option value="AddNew">Add New</option>
														<option value="All">All</option>
														<option value="Completed">Completed</option>
														<option value="Partial">Partial</option>
													</select>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Commodity</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-box"></i>
														</span>
													</div>
													<input #commodity id="{{pageId}}commodityName" name="commodityName"
														type="text" class="form-control"
														aria-describedby="basic-addon11"
														[(ngModel)]="rateMasterDto.commodityName">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Commodity Type</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-search"></i>
														</span>
													</div>
													<select #commodityType class="custom-select col-12"
														id="{{pageId}}commodityType" name="commodityType"
														[(ngModel)]="rateMasterDto.selectedCommodityType"
														(change)="commodityTypeMode(commodityType.value)">
														<option value="mainCommodity">Main Commodity</option>
														<option value="subCommodity">Sub Commodity</option>
													</select>
												</div>
											</div>
										</div>
										<div *ngIf="commodityView" class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Commodity List</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Heads" id="{{pageId}}commodityList"
															name="commodityList" [formControl]="controlCommodityList"
															[appAutocomplete]="autoCommodityList"
															[(ngModel)]="rateMasterDto.selectedCommodity">
													</div>
												</div>
											</div>
											<app-autocomplete #autoCommodityList="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(commodityListOptions | filter: controlCommodityList.value) as resultCommodityList">
											<app-option *ngFor="let option of resultCommodityList"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultCommodityList.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input"
														id="{{pageId}}multipleArticleSize"> <label
														class="custom-control-label wtfull"
														for="{{pageId}}multipleArticleSize">No. Multiple Article
														Size</label>
												</div>
											</div>
										</div>
										<!-- 										it showing error so commented -->
										<!-- 										<div class="col-sm-12 col-md-12"> -->
										<!-- 											<div class="form-group"> -->
										<!-- 												<div class="custom-control custom-checkbox"> -->
										<!-- 													<input type="checkbox" class="custom-control-input" -->
										<!-- 														id="{{pageId}}multipleArticleSize" name="multipleArticleSize"> -->
										<!-- 													<label class="custom-control-label wtfull" -->
										<!-- 														for="multipleArticleSize" -->
										<!-- 														[(ngModel)]="rateMasterDto.isMultiArtSize" -->
										<!-- 														ng-true-value="true" ng-false-value="false">No. -->
										<!-- 														Multiple Article Size</label> -->
										<!-- 												</div> -->
										<!-- 											</div> -->
										<!-- 										</div> -->
									</div>
									<hr style="width: 80%; border-top: none; margin: 3px;">
									<div class="col-md-12" style="text-align: center;">
										<button *ngIf="saveBtnShow" type="submit"
											class="btn btn-success m-r-10" id="{{pageId}}updateBtn"
											(click)="validateCommodityDetails()">Save</button>
										<button *ngIf="saveBtnHide" type="submit"
											class="btn btn-success m-r-10" id="{{pageId}}updateBtn"
											(click)="validateUpdateCommodityDetails()">Update</button>
										<button *ngIf="saveBtnHide" type="submit" class="btn btn-dark"
											id="{{pageId}}removeBtn" (click)="validateDeleteRow()">Remove</button>
									</div>

								</div>
								<div class="col-md-9 vl ">
									<h6 class="card-title">Commodity Master</h6>
									<div *ngIf="commodityMasterTable" class="col-md-12">
										<div class="box-body">
											<table datatable
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionsCommodityMaster"
												[dtTrigger]="dtTriggerCommodityMaster">

												<thead>
													<tr>
														<th>Main Commodity</th>
														<th>Commodity</th>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="let commodityMasterData of commodityMasterDataList">
														<td>{{ commodityMasterData.commodityName }}</td>
														<td>{{ commodityMasterData.subCommodity }}</td>
													</tr>
												</tbody>
											</table>


										</div>
									</div>
									<div *ngIf="commodityMasterPartialTable" class="col-md-12">
										<div class="box-body">
											<table datatable
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionsCommodityMasterPartial"
												[dtTrigger]="dtTriggerCommodityMasterPartial">

												<thead>
													<tr>
														<th>Commodity</th>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="let commodityMasterPartialData of commodityMasterPartialDataList "
														(click)="rowSelected(commodityMasterPartialData);">
														<td>{{ commodityMasterPartialData.subCommodity }}</td>
													</tr>
												</tbody>
											</table>

										</div>
									</div>

								</div>


							</div>
						</div>
					</div>

				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>
