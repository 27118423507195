import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { NotfoundComponent } from 'src/app/authentication/404/not-found.component';
import { LockComponent } from 'src/app/authentication/lock/lock.component';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { Login2Component } from 'src/app/authentication/login2/login2.component';
import { SignupComponent } from 'src/app/authentication/signup/signup.component';
import { Signup2Component } from 'src/app/authentication/signup2/signup2.component';

import { AuthenticationRoutes } from 'src/app/authentication/authentication.routing';
import { SpinnerComponent } from './shared/spinner.component';

@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(AuthenticationRoutes),
        NgbModule, FormsModule
    ],
    declarations: [
        // NotfoundComponent,
        // LoginComponent,
        // SignupComponent,
        // LockComponent,
        // Login2Component,
        // Signup2Component,
        // SpinnerComponent
    ]
})
export class AuthenticationModule { }
