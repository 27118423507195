import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge, Observable } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { QueryList } from "@angular/core";
import { ViewChildren } from "@angular/core";
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { MemoReport } from 'src/app/dataService/memo-report';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-godown-stocks-booking-report',
    templateUrl: './godown-stocks-booking-report.component.html',
    styleUrls: ['./godown-stocks-booking-report.component.css']
})
export class GodownStocksBookingReportComponent implements OnInit {

    gettingDataFrmServiceFrSmryTable: any;
    gettingDataFrmServiceFrDetailTable: any;
    gettingDataFrmServiceFrSmryChkbxTable: any;
    gettingDataFrmServiceFrDetailChkbxTable: any;

    summaryDataList: any;
    detailDataList: any;
    summaryChkbxDataList: any;
    detailChkbxDataList: any;


    onDestroyUnsubscribtionSmry: Subscription;
    onDestroyUnsubscribtionDetail: Subscription;
    onDestroyUnsubscribtionSmryChkbx: Subscription;
    onDestroyUnsubscribtionDetailChkbx: Subscription;

    //summaryTable:any;


    loadingIndicator = true;
    viewTypeSummary = true;
    ViewDate = true;
    viewTypeDetail = false;




    //@ViewChildren(DataTableDirective)
    //dtElements: QueryList<DataTableDirective>;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    //@ViewChild(DialogComponent) dialogComponent: DialogComponent;
    an;

    viewModel: any;
    //supList: SupplierModel[] = [];
    //

    dtTriggerSummary: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    dtTriggerSummaryChkbx: Subject<any> = new Subject();
    dtTriggerDetailChkbx: Subject<any> = new Subject();

    //


    // @ViewChild('dataTable') table: ElementRef;
    dataTable: any;
    dtOptionsSummary: any;
    dtOptionDetail: any;
    dtOptionsSummaryChkbx: any;
    dtOptionDetailChkbx: any;
    //firstDtOptions: DataTables.Settings = {};


    //secondDtOptions: DataTables.Settings = {};
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;

    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { destination: string }) => x.destination;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationLrDtoAllOption: LRDto = new LRDto();
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    cbSourceView = false;
    hideshowSourceDropDown = false;
    validateAgentWiseCheckBoxValue = false;

    //datatable hideshow
    hideshowSummaryTable = false;
    hideshowDetailTable = false;
    hideshowSummaryChbxTable = false;
    hideshowDetailChbxTable = false;

    validateSourceIdValue: any = null;
    validateDestinationIdValue: any = null;
    validateViewTypeId: any = null;

    lrDtoSummary: LRDto = new LRDto();
    lrDtoDetails: LRDto = new LRDto();
    lrDtoAgentSummary: LRDto = new LRDto();
    lrDtoAgentDetails: LRDto = new LRDto();

    showSpinnerForAction = false;

    //for custom print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    curDate: any;
    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;

    totLrsummary: number;
    artsummary: number;
    actWtsummary: number;
    chgWtsummary: number;
    pageId = "gsbrc";

    constructor(private godownStocksBookingRpt: ReportService, private router: Router,
        private memoReport: MemoReport, private masterReadService: MasterReadService,
        private modalService: NgbModal, private lrReport: LrReportService, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            for (let key in this.userDataDtoReturnSession.sortedMapFeatures) {
                //Whatever you want to do with key or obj[key]
                if (key == 'Rights') {
                    for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                        //Whatever you want to do with key or obj[key]
                        if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == 'Source BkgGodStks') {
                            this.cbSourceView = true;
                            this.hideshowSourceDropDown = true;
                            if (this.cbSourceView == true) {
                                if (this.hideshowSourceDropDown == true) {
                                    this.getSourceStationDetails();
                                }
                            }
                        }
                    }
                }
            }
            this.getDestinationDetails();
            this.hideshowSummaryTable = true;
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    ngOnInit(): void {
        var groupColumn = 0;
        var companyAddressDetls = this.address;
        // if (this.cbSourceView == true) {
        var booleanCheckSource = this.cbSourceView;
        var companyOffice = this.userDataDtoReturnSession.office;
        //}
        //first datatable starts 
        this.dtOptionsSummary = {
            dom: 'Bfrtip',
            buttons: [
                /* {
                     extend: 'print',
                     text: '<i class="fas fa-print"> Print</i>',
                     titleAttr: 'Print',
                     customize: function (win) {
                         $(win.document.body).css('font-size', '10pt');
                         $(win.document.body).find('th, td').
                             css('font-family', 'Arial, Helvetica, sans-serif')
                             .css('font-size', '12px').css('text-align', 'center');
                     },
                     footer: true,
                     messageTop: function () {
                         if (booleanCheckSource == true) {
                             return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                 "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                 "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                 "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                 "<br><br>" +
                                 "From : " + $("#gsbrcsourceId").val() + " -  " +
                                 "To  : $("#gsbrcdestinationId").val() + "" +
                                 "</td>" +
                                 "</tr></table><br>"
                         } else {
                             return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                 "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                 "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                 "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                 "<br><br>" +
                                 "From : " + companyOffice + " -  " +
                                 "To  : " + $("#gsbrcdestinationId").val() + "" +
                                 "</td>" +
                                 "</tr></table><br>"
                         }
                     },
                     title: function () {
                         return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                             "<td align='left' style='width: 20%;'>" +
                             "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                             "</td>" +
                             "<td align='left' style='width: 30%;'>" +
                             "</td>" +
                             "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                             companyAddressDetls +
                             "</td>" +
                             "</tr></table>";
                     }
                 },*/
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if (booleanCheckSource == true) {
                            return "Godown Stocks Report-Booking(Summary) - " +
                                "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                "From : " + $("#gsbrcsourceId").val() + " -  " +
                                "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                        } else {
                            return "Godown Stocks Report-Booking(Summary) - " +
                                "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                " From : " + companyOffice + " -  " +
                                "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                        }

                    },
                    exportOptions: {
                        columns: [1, 0, 2, 3, 4, 5, 6, 7, 8]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var article = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cgdWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                // $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(3).footer()).html(article);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(cgdWgt);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var totalLrs = 0;
                var article = 0;
                var actWgt = 0;
                var cgdWgt = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": article
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 3,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": actWgt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": cgdWgt
                                                        }))
                                        .prop('outerHTML'));
                            totalLrs = 0;
                            article = 0;
                            actWgt = 0;
                            cgdWgt = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 9,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    totalLrs += +val[2];
                    article += +val[3];
                    actWgt += +val[7];
                    cgdWgt += +val[8];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalLrs
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": article
                                        })).append($("<td></td>",
                                            {
                                                "colspan": 3,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": cgdWgt
                                                    }))
                                    .prop('outerHTML'));
                        totalLrs = 0;
                        article = 0;
                        actWgt = 0;
                        cgdWgt = 0;
                    }
                });
            }
        },
            //first datatable ends
            //second datatable starts 
            this.dtOptionDetail = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'print',
                        text: '<i class="fas fa-print"> Print</i>',
                        titleAttr: 'Print',
                        customize: function (win) {
                            $(win.document.body).css('font-size', '10pt');
                            $(win.document.body).find('th, td').
                                css('font-family', 'Arial, Helvetica, sans-serif')
                                .css('font-size', '12px').css('text-align', 'center');
                        },
                        footer: true,
                        exportOptions: {
                            columns: [0, 1, 2, 5, 3, 4, 6, 7, 8, 9, 10]
                        },
                        messageTop: function () {
                            if (booleanCheckSource == true) {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Details)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            } else {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + companyOffice + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            }
                        },
                        title: function () {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='left' style='width: 20%;'>" +
                                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                                "</td>" +
                                "<td align='left' style='width: 30%;'>" +
                                "</td>" +
                                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                                companyAddressDetls +
                                "</td>" +
                                "</tr></table>";
                        }
                    },
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if (booleanCheckSource == true) {
                                return "Godown Stocks Report-Booking(Summary) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                            } else {
                                return "Godown Stocks Report-Booking(Details) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    " From : " + companyOffice + " -  " +
                                    "To  : " + $("gsbrcdestinationId").val() + "" + "";
                            }

                        },
                        exportOptions: {
                            /*columns: [0, 1, 2, 5, 3, 4, 6, 7, 8, 9, 10]*/
                            columns: ':visible'
                        },
                    },
                    // {
                    //     extend: 'colvis',
                    //     postfixButtons: [
                    //         {
                    //             extend: 'colvisRestore',
                    //             text: 'Restore'
                    //         }
                    //     ],
                    //     buttons: [{
                    //         extend: 'columnsToggle',
                    //         // columns: [1, 2, 3, 4, 5]
                    //     }],
                    // }

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var article = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var cgdWgt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    // $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(data.length);
                    $(api.column(3).footer()).html(article);
                    $(api.column(10).footer()).html(actWgt);
                    $(api.column(11).footer()).html(cgdWgt);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totalLrs = 0;
                    var article = 0;
                    var actWgt = 0;
                    var cgdWgt = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": article
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 6,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": actWgt
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": cgdWgt
                                                            }))
                                            .prop('outerHTML'));
                                article = 0;
                                actWgt = 0;
                                cgdWgt = 0;
                                totalLrs = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 11,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }

                        totalLrs++;
                        article += +val[3];
                        actWgt += +val[10];
                        cgdWgt += +val[11];

                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": article
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 6,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": actWgt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": cgdWgt
                                                        }))
                                        .prop('outerHTML'));
                            article = 0;
                            actWgt = 0;
                            cgdWgt = 0;
                            totalLrs = 0;
                        }
                    });
                }
            },
            //second datatable ends
            //third datatable starts 
            this.dtOptionsSummaryChkbx = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'print',
                        text: '<i class="fas fa-print"> Print</i>',
                        titleAttr: 'Print',
                        customize: function (win) {
                            $(win.document.body).css('font-size', '10pt');
                            $(win.document.body).find('th, td').
                                css('font-family', 'Arial, Helvetica, sans-serif')
                                .css('font-size', '12px').css('text-align', 'center');
                        },
                        footer: true,
                        exportOptions: {
                            columns: [0, 1, 3, 2, 4, 5, 6, 7, 8, 9]
                        },
                        messageTop: function () {
                            if (booleanCheckSource == true) {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            } else {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + companyOffice + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            }
                        },
                        title: function () {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='left' style='width: 20%;'>" +
                                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                                "</td>" +
                                "<td align='left' style='width: 30%;'>" +
                                "</td>" +
                                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                                companyAddressDetls +
                                "</td>" +
                                "</tr></table>";
                        }
                    },
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if (booleanCheckSource == true) {
                                return "Godown Stocks Report-Booking(Summary) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                            } else {
                                return "Godown Stocks Report-Booking(Summary) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    " From : " + companyOffice + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                            }

                        },
                        /* exportOptions: {
                              columns: [0, 1, 3, 2, 4, 5, 6, 7, 8, 9]
                         },*/
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totalLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var article = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var cgdWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    // $(api.column(1).footer()).html('Total : ' + data.length);

                    $(api.column(2).footer()).html(totalLrs);
                    $(api.column(4).footer()).html(article);
                    $(api.column(8).footer()).html(actWgt);
                    $(api.column(9).footer()).html(cgdWgt);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totalLrs = 0;
                    var article = 0;
                    var actWgt = 0;
                    var cgdWgt = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": article
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 3,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": actWgt
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": cgdWgt
                                                            }))
                                            .prop('outerHTML'));
                                article = 0;
                                actWgt = 0;
                                cgdWgt = 0;
                                totalLrs = 0;
                                //pendingQty = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 10,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        totalLrs += +val[2];
                        article += +val[4];
                        actWgt += +val[8];
                        cgdWgt += +val[9];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": article
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 3,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": actWgt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": cgdWgt
                                                        }))
                                        .prop('outerHTML'));
                            article = 0;
                            actWgt = 0;
                            cgdWgt = 0;
                            totalLrs = 0;
                        }
                    });
                }
            },
            //third datatable ends
            //fourth datatable starts
            this.dtOptionDetailChkbx = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'print',
                        text: '<i class="fas fa-print"> Print</i>',
                        titleAttr: 'Print',
                        customize: function (win) {
                            $(win.document.body).css('font-size', '10pt');
                            $(win.document.body).find('th, td').
                                css('font-family', 'Arial, Helvetica, sans-serif')
                                .css('font-size', '12px').css('text-align', 'center');
                        },
                        footer: true,
                        exportOptions: {
                            columns: [0, 1, 2, 3, 6, 4, 5, 7, 8, 9, 10, 11]
                        },
                        messageTop: function () {
                            if (booleanCheckSource == true) {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Details)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            } else {
                                return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                    "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                    "<u>Godown Stocks Report-Booking(Summary)</u><br><br>" +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "<br><br>" +
                                    "From : " + companyOffice + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" +
                                    "</td>" +
                                    "</tr></table><br>"
                            }
                        },
                        title: function () {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='left' style='width: 20%;'>" +
                                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                                "</td>" +
                                "<td align='left' style='width: 30%;'>" +
                                "</td>" +
                                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                                companyAddressDetls +
                                "</td>" +
                                "</tr></table>";
                        }
                    },
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if (booleanCheckSource == true) {
                                return "Godown Stocks Report-Booking(Details) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    " From : " + $("#gsbrcsourceId").val() + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                            } else {
                                return "Godown Stocks Report-Booking(Summary) - " +
                                    "Date : " + moment(new Date()).format('DD-MM-YYYY') +
                                    "From : " + companyOffice + " -  " +
                                    "To  : " + $("#gsbrcdestinationId").val() + "" + "";
                            }

                        },
                        /*  exportOptions: {
                              columns: [0, 1, 2, 3, 4 , 6, 5, 7, 8, 9, 10, 11]
                          },*/
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var article = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var cgdWgt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    // $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(3).footer()).html(data.length);
                    $(api.column(4).footer()).html(article);
                    $(api.column(10).footer()).html(actWgt);
                    $(api.column(11).footer()).html(cgdWgt);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var article = 0;
                    var actWgt = 0;
                    var cgdWgt = 0;
                    var totalLrs = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 2,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalLrs
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": article
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 5,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": actWgt
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": cgdWgt
                                                            }))
                                            .prop('outerHTML'));
                                article = 0;
                                actWgt = 0;
                                cgdWgt = 0;
                                totalLrs = 0;

                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 12,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        totalLrs++;
                        article += +val[4];
                        actWgt += +val[10];
                        cgdWgt += +val[11];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 2,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": article
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 5,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": actWgt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": cgdWgt
                                                        }))
                                        .prop('outerHTML'));
                            article = 0;
                            actWgt = 0;
                            cgdWgt = 0;
                            totalLrs = 0;
                        }
                    });
                }
            }
        //fourth datatable ends
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngAfterViewInit(): void {
        this.dtTriggerSummary.next();
        //this.dtTriggerUnloadingReportDetained.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerSummary.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
        this.dtTriggerSummaryChkbx.unsubscribe();
        this.dtTriggerDetailChkbx.unsubscribe();

        // this.onDestroyUnsubscribtionSmry.unsubscribe();
        // this.onDestroyUnsubscribtionDetail.unsubscribe();
        // this.onDestroyUnsubscribtionSmryChkbx.unsubscribe();
        // this.onDestroyUnsubscribtionDetailChkbx.unsubscribe();

    }
    clearBtn() {
        this.clearFeild();
    }
    clearFeild() {
        this.validateAgentWiseCheckBoxValue = false;
        this.destinationLrDtoAllOption = new LRDto();
        $("#" + this.pageId + "viewType").val('summary');
        $("#" + this.pageId + "checkboxAgentWise").prop('checked', false);

        this.validateSourceIdValue = null;
        this.validateDestinationIdValue = null;
        this.validateViewTypeId = null;
        this.modelSource = null;
        this.modelDestination = null;
        //
        this.hideshowSummaryTable = true;
        this.hideshowDetailTable = false;
        this.hideshowSummaryChbxTable = false;
        this.hideshowDetailChbxTable = false;

        $("#" + this.pageId + "summaryTableId").DataTable().destroy();
        this.summaryDataList = [];
        this.dtTriggerSummary.next();
    }
    agentWiseCheckBoxEvent(values: any) {
        if (values.currentTarget.checked == true) {
            this.validateAgentWiseCheckBoxValue = true;
            $("#" + this.pageId + "viewType").val('summary');
            this.validateSourceIdValue = null;
            this.validateDestinationIdValue = null;
            // this.validateViewTypeId = null;
            this.modelSource = null;
            this.modelDestination = null;

            //
            this.hideshowSummaryTable = false;
            this.hideshowDetailTable = false;
            this.hideshowSummaryChbxTable = true;
            this.hideshowDetailChbxTable = false;

            $("#" + this.pageId + "summaryCheckBoxTableId").DataTable().destroy();
            this.summaryChkbxDataList = [];
            this.dtTriggerSummaryChkbx.next();
        } else if (values.currentTarget.checked == false) {
            this.validateAgentWiseCheckBoxValue = false;
            $("#" + this.pageId + "viewType").val('summary');
            this.validateSourceIdValue = null;
            this.validateDestinationIdValue = null;
            //this.validateViewTypeId = null;
            this.modelSource = null;
            this.modelDestination = null;

            this.destinationTA = [];
            this.destinationLrDtoAllOption = new LRDto();
            this.destinationLrDtoAllOption.destination = "ALL";
            this.destinationTA.push(this.destinationLrDtoAllOption);
            this.destinationLrDtoAllOption = new LRDto();
            for (let i = 0; i < this.destinationOptions.length; i++) {
                this.destinationTA.push(this.destinationOptions[i]);
            }
            //
            this.hideshowSummaryTable = true;
            this.hideshowDetailTable = false;
            this.hideshowSummaryChbxTable = false;
            this.hideshowDetailChbxTable = false;

            $("#" + this.pageId + "summaryTableId").DataTable().destroy();
            this.summaryDataList = [];
            this.dtTriggerSummary.next();
        }
    }

    viewTypeMode(viewType: string) {
        console.log(viewType);
        if (viewType === 'summary') {
            if (this.validateAgentWiseCheckBoxValue == true) {
                this.destinationTA = [];
                this.destinationLrDtoAllOption = new LRDto();
                this.destinationLrDtoAllOption.destination = "ALL";
                this.destinationTA.push(this.destinationLrDtoAllOption);
                for (let i = 0; i < this.destinationOptions.length; i++) {
                    this.destinationTA.push(this.destinationOptions[i]);
                }
                //
                this.hideshowSummaryTable = false;
                this.hideshowDetailTable = false;
                this.hideshowSummaryChbxTable = true;
                this.hideshowDetailChbxTable = false;

                this.validateSourceIdValue = null;
                this.validateDestinationIdValue = null;
                //this.validateViewTypeId = null;
                this.modelSource = null;
                this.modelDestination = null;

                $("#" + this.pageId + "summaryCheckBoxTableId").DataTable().destroy();
                this.summaryChkbxDataList = [];
                this.dtTriggerSummaryChkbx.next();
            } else if (this.validateAgentWiseCheckBoxValue == false) {
                this.destinationTA = [];
                this.destinationLrDtoAllOption = new LRDto();
                this.destinationLrDtoAllOption.destination = "ALL";
                this.destinationTA.push(this.destinationLrDtoAllOption);
                for (let i = 0; i < this.destinationOptions.length; i++) {
                    this.destinationTA.push(this.destinationOptions[i]);
                }
                //
                this.hideshowSummaryTable = true;
                this.hideshowDetailTable = false;
                this.hideshowSummaryChbxTable = false;
                this.hideshowDetailChbxTable = false;

                this.validateSourceIdValue = null;
                this.validateDestinationIdValue = null;
                //this.validateViewTypeId = null;
                this.modelSource = null;
                this.modelDestination = null;

                $("#" + this.pageId + "summaryTableId").DataTable().destroy();
                this.summaryDataList = [];
                this.dtTriggerSummary.next();
            }
        } else if (viewType === 'detail') {
            if (this.validateAgentWiseCheckBoxValue == true) {
                this.destinationTA = [];
                this.destinationLrDtoAllOption = new LRDto();
                this.destinationLrDtoAllOption.destination = "All"
                this.destinationTA.push(this.destinationLrDtoAllOption);
                for (let i = 0; i < this.destinationOptions.length; i++) {
                    this.destinationTA.push(this.destinationOptions[i]);
                }
                //
                this.hideshowSummaryTable = false;
                this.hideshowDetailTable = false;
                this.hideshowSummaryChbxTable = false;
                this.hideshowDetailChbxTable = true;

                this.validateSourceIdValue = null;
                this.validateDestinationIdValue = null;
                //this.validateViewTypeId = null;
                this.modelSource = null;
                this.modelDestination = null;

                $("#" + this.pageId + "detailsCheckBoxTableId").DataTable().destroy();
                this.detailChkbxDataList = [];
                this.dtTriggerDetailChkbx.next();
            } else if (this.validateAgentWiseCheckBoxValue == false) {
                this.destinationTA = [];
                this.destinationLrDtoAllOption = new LRDto();
                this.destinationLrDtoAllOption.destination = "ALL";
                this.destinationTA.push(this.destinationLrDtoAllOption);
                for (let i = 0; i < this.destinationOptions.length; i++) {
                    this.destinationTA.push(this.destinationOptions[i]);
                }
                //
                this.hideshowSummaryTable = false;
                this.hideshowDetailTable = true;
                this.hideshowSummaryChbxTable = false;
                this.hideshowDetailChbxTable = false;

                this.validateSourceIdValue = null;
                this.validateDestinationIdValue = null;
                //this.validateViewTypeId = null;
                this.modelSource = null;
                this.modelDestination = null;

                $("#" + this.pageId + "detailsTableId").DataTable().destroy();
                this.detailDataList = [];
                this.dtTriggerDetail.next();
            }
        }
    }


    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.destination);
        this.validateSourceIdValue = this.modelSource.destination;

    }

    destinationListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        this.validateDestinationIdValue = this.modelDestination.destination;
    }

    getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.branch = this.userDataDtoReturnSession.office;
        this.lrDtoSourceStation.mode = "logininfo";
    }
    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoSourceStation).subscribe(
            (response) => {
                //console.log(response);
                if (response.length > 0) {
                    this.sourceStationOptions = response;
                    this.sourceTA = [];
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.sourceStationOptions = [];
                    this.sourceTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');

    };

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];

                    this.destinationLrDtoAllOption = new LRDto();
                    this.destinationLrDtoAllOption.destination = "ALL";
                    this.destinationTA.push(this.destinationLrDtoAllOption);

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.destinationOptions = [];
                    this.destinationTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    };

    validateSearchBtn() {
        this.validateViewTypeId = $("#" + this.pageId + "viewType").val();

        if (this.cbSourceView == true) {
            this.sourceModeMtd();
        } else {
            this.destinyModeMtd();
        }
    }

    sourceModeMtd() {
        if (this.hideshowSourceDropDown == true) {
            if (($("#" + this.pageId + "sourceId").val() == null) || ($("#" + this.pageId + "sourceId").val() == undefined) ||
                ($("#" + this.pageId + "sourceId").val() == "") || ($("#" + this.pageId + "destinationId").val() == null) ||
                ($("#" + this.pageId + "destinationId").val() == undefined) || ($("#" + this.pageId + "destinationId").val() == "")) {
                swal("Mandatory Field", "Source & Destination is Mandatory Field", "warning");
                return false;
            } else {
                this.searchMtd();
            }
        } else {
            if (($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
                ($("#" + this.pageId + "destinationId").val() == "")) {
                swal("Mandatory Field", "Destination is Mandatory Field", "warning");
                return false;
            } else {
                this.searchMtd();
            }
        }
    }

    destinyModeMtd() {
        if (($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
            ($("#" + this.pageId + "destinationId").val() == "")) {
            swal("Mandatory Field", "Destination is Mandatory Field", "warning");
            return false;
        } else {
            this.searchMtd();
        }
    }

    searchMtd() {
        if ($("#" + this.pageId + "viewType").val() == "detail") {
            if (this.validateAgentWiseCheckBoxValue == true) {
                this.setGodownStockBookingAgentDetailsDatatable();
            } else {
                this.setGodownStockBookingDetailsDatatable();
            }
        } else if ($("#" + this.pageId + "viewType").val() == "summary") {
            if (this.validateAgentWiseCheckBoxValue == true) {
                this.setGodownStockBookingAgentSummaryDatatable();
            } else {
                this.setGodownStockBookingSummaryDatatable();
            }
        }
    }

    getGodownStockBookingSummaryData() {
        this.lrDtoSummary = new LRDto();
        this.lrDtoSummary.mode = "summary";
        if ((this.cbSourceView == true) && ((this.validateSourceIdValue != null) ||
            (this.validateSourceIdValue != undefined) || (this.validateSourceIdValue != ""))) {
            this.lrDtoSummary.stocksAt = this.validateSourceIdValue;
        } else {
            this.lrDtoSummary.stocksAt = this.userDataDtoReturnSession.office;
        }
        this.lrDtoSummary.toStation = this.validateDestinationIdValue;
    }
    setGodownStockBookingSummaryDatatable() {
        this.getGodownStockBookingSummaryData();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoSummary);
        this.lrReport.getGodownStocksRptBookingSummary(this.lrDtoSummary).subscribe(
            (response) => {
                console.log(response);
                this.summaryDataList = [];
                $("#" + this.pageId + "summaryTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.summaryDataList = response;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                }
                this.dtTriggerSummary.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Godown Stock Summary Details", "error");
            },
            () => console.log('done');
    };

    getGodownStockBookingDetailsData() {
        this.lrDtoDetails = new LRDto();
        this.lrDtoDetails.mode = "Details";
        if ((this.cbSourceView == true) && ((this.validateSourceIdValue != null) ||
            (this.validateSourceIdValue != undefined) || (this.validateSourceIdValue != ""))) {
            this.lrDtoDetails.stocksAt = this.validateSourceIdValue;
        } else {
            this.lrDtoDetails.stocksAt = this.userDataDtoReturnSession.office;
        }
        this.lrDtoDetails.toStation = this.validateDestinationIdValue;
    }

    setGodownStockBookingDetailsDatatable() {
        this.getGodownStockBookingDetailsData();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoDetails);
        this.lrReport.getGodownStocksRptBookingDetails(this.lrDtoDetails).subscribe(
            (response) => {
                //console.log("Detail");
                this.detailDataList = [];
                $("#" + this.pageId + "detailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.detailDataList = response;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                }
                this.dtTriggerDetail.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Godown Stock Details", "error");
            },
            () => console.log('done');
    };

    getGodownStockBookingAgentSummaryData() {
        this.lrDtoAgentSummary = new LRDto();
        this.lrDtoAgentSummary.mode = "AgtSummary";
        if ((this.cbSourceView == true) && ((this.validateSourceIdValue != null) ||
            (this.validateSourceIdValue != undefined) || (this.validateSourceIdValue != ""))) {
            this.lrDtoAgentSummary.stocksAt = this.validateSourceIdValue;
        } else {
            this.lrDtoAgentSummary.stocksAt = this.userDataDtoReturnSession.office;
        }
        this.lrDtoAgentSummary.toStation = this.validateDestinationIdValue;
    }
    setGodownStockBookingAgentSummaryDatatable() {
        this.getGodownStockBookingAgentSummaryData();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoAgentSummary);
        this.lrReport.getGodownStocksRptBookingSummary(this.lrDtoAgentSummary).subscribe(
            (response) => {
                // console.log("Summary Agent");
                console.log(response);
                this.summaryChkbxDataList = [];
                $("#" + this.pageId + "summaryCheckBoxTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.summaryChkbxDataList = response;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                }
                this.dtTriggerSummaryChkbx.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Godown Stock Agent Summary Details", "error");
            },
            () => console.log('done');
    };

    getGodownStockBookingAgentDetailsData() {
        this.lrDtoAgentDetails = new LRDto();
        this.lrDtoAgentDetails.mode = "AgtDetails";
        if ((this.cbSourceView == true) && ((this.validateSourceIdValue != null) ||
            (this.validateSourceIdValue != undefined) || (this.validateSourceIdValue != ""))) {
            this.lrDtoAgentDetails.stocksAt = this.validateSourceIdValue;
        } else {
            this.lrDtoAgentDetails.stocksAt = this.userDataDtoReturnSession.office;
        }
        if ((this.validateDestinationIdValue == null) || (this.validateDestinationIdValue == "") ||
            (this.validateDestinationIdValue == undefined) || (this.validateDestinationIdValue == "All")) {
            this.lrDtoAgentDetails.toStation = "All";
            this.lrDtoAgentDetails.reportMode = "";
        } else {
            this.lrDtoAgentDetails.toStation = this.validateDestinationIdValue;
            this.lrDtoAgentDetails.reportMode = "destSpec";
        }

    }

    setGodownStockBookingAgentDetailsDatatable() {
        this.getGodownStockBookingAgentDetailsData();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoAgentDetails);
        this.lrReport.getGodownStocksRptBookingDetails(this.lrDtoAgentDetails).subscribe(
            (response) => {
                // console.log("Details Agent");
                this.detailChkbxDataList = [];
                $("#" + this.pageId + "detailsCheckBoxTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.detailChkbxDataList = response;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                }
                this.dtTriggerDetailChkbx.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Godown Stock Agent Details", "error");
            },
            () => console.log('done');
    };
    customPrintGodwonStcSumBkgRpt() {
        if (this.summaryDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Book Date", "Total Lrs", "Articles", "Diff(Entry-Booking)", "Diff(Current-Entry)", "Diff(Current-Booking)", "Act Wt", "Chg Wt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 9, 12, 10, 9, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totLrsummary = this.artsummary = this.actWtsummary = this.chgWtsummary = 0;
            for (let i = 0; i < this.summaryDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].enteredDateStr, this.summaryDataList[i].bookingDateStr, this.summaryDataList[i].totalLrs, this.summaryDataList[i].totalArticles, this.summaryDataList[i].dispatchedwithin, this.summaryDataList[i].arrivalwithin, this.summaryDataList[i].diffDays, this.summaryDataList[i].actualWeight, this.summaryDataList[i].chargedWeight];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.totLrsummary = this.totLrsummary + this.summaryDataList[i].totalLrs;
                this.artsummary = this.artsummary + this.summaryDataList[i].totalArticles;
                this.actWtsummary = this.actWtsummary + this.summaryDataList[i].actualWeight;
                this.chgWtsummary = this.chgWtsummary + this.summaryDataList[i].chargedWeight;

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, "", this.totLrsummary, this.artsummary, "", "", "", this.actWtsummary, this.chgWtsummary];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            //Heading Logics For Station
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceIdValue, this.lrDtoSummary.toStation];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Godown Stock Booking Summary Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    customPrintGodwonStcDetRpt() {
        if (this.detailDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Lr No", "Source", "Art", "Commodity", "Diff(Entry-Booking)", "Diff(Current-Entry)", "Diff(Current-Booking)", "Act Wt", "Chg Wt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [9, 8, 6, 6, 10, 13, 13, 13, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totLrsummary = this.artsummary = this.actWtsummary = this.chgWtsummary = 0;
            for (let i = 0; i < this.detailDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].enteredDateStr, this.detailDataList[i].lrNumber, this.detailDataList[i].agentName, this.detailDataList[i].totalArticles, this.detailDataList[i].commodityName, this.detailDataList[i].dispatchedwithin, this.detailDataList[i].arrivalwithin, this.detailDataList[i].diffDays, this.detailDataList[i].actualWeight, this.detailDataList[i].chargedWeight];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.artsummary = this.artsummary + this.detailDataList[i].totalArticles;
                this.actWtsummary = this.actWtsummary + this.detailDataList[i].actualWeight;
                this.chgWtsummary = this.chgWtsummary + this.detailDataList[i].chargedWeight;

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", this.artsummary, "", "", "", "", this.actWtsummary, this.chgWtsummary];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            //Heading Logics For Station
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceIdValue, this.validateDestinationIdValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Godown Stock Booking Summary Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }


    customPrintGodwonStcBkgRptChkBox() {
        if (this.summaryChkbxDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Book Date", "Total Lrs", "Articles", "Diff(Entry-Booking)", "Diff(Current-Entry)", "Diff(Current-Booking)", "Act Wt", "Chg Wt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 9, 12, 10, 9, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totLrsummary = this.artsummary = this.actWtsummary = this.chgWtsummary = 0;
            for (let i = 0; i < this.summaryChkbxDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryChkbxDataList[i].enteredDateStr, this.summaryChkbxDataList[i].bookingDateStr, this.summaryChkbxDataList[i].totalLrs, this.summaryChkbxDataList[i].totalArticles, this.summaryChkbxDataList[i].dispatchedwithin, this.summaryChkbxDataList[i].arrivalwithin, this.summaryChkbxDataList[i].diffDays, this.summaryChkbxDataList[i].actualWeight, this.summaryChkbxDataList[i].chargedWeight];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.totLrsummary = this.totLrsummary + this.summaryChkbxDataList[i].totalLrs;
                this.artsummary = this.artsummary + this.summaryChkbxDataList[i].totalArticles;
                this.actWtsummary = this.actWtsummary + this.summaryChkbxDataList[i].actualWeight;
                this.chgWtsummary = this.chgWtsummary + this.summaryChkbxDataList[i].chargedWeight;

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryChkbxDataList.length, "", this.totLrsummary, this.artsummary, "", "", "", this.actWtsummary, this.chgWtsummary];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            //Heading Logics For Station
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceIdValue, this.validateDestinationIdValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Godown Stock Report Booking(Summary)");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    customPrintGodwonStcDetRptChkBox() {
        if (this.detailChkbxDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Lr No", "Source", "Articles", "Commodity", "Diff(Entry-Booking)", "Diff(Current-Entry)", "Diff(Current-Booking)", "Act Wt", "Chg Wt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 9, 12, 10, 9, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totLrsummary = this.artsummary = this.actWtsummary = this.chgWtsummary = 0;
            for (let i = 0; i < this.detailChkbxDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.detailChkbxDataList[i].enteredDateStr, this.detailChkbxDataList[i].lrNumber, this.detailChkbxDataList[i].agentName, this.detailChkbxDataList[i].totalArticles, this.detailChkbxDataList[i].commodityName, this.detailChkbxDataList[i].dispatchedwithin, this.detailChkbxDataList[i].arrivalwithin, this.detailChkbxDataList[i].diffDays, this.detailChkbxDataList[i].actualWeight, this.detailChkbxDataList[i].chargedWeight];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.totLrsummary = this.totLrsummary + this.detailChkbxDataList[i].totalLrs;
                this.artsummary = this.artsummary + this.detailChkbxDataList[i].totalArticles;
                this.actWtsummary = this.actWtsummary + this.detailChkbxDataList[i].actualWeight;
                this.chgWtsummary = this.chgWtsummary + this.detailChkbxDataList[i].chargedWeight;

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total" + this.detailChkbxDataList.length, "", "", this.artsummary, "", "", "", "", this.actWtsummary, this.chgWtsummary];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.curDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.curDate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            //Heading Logics For Station
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceIdValue, this.validateDestinationIdValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Godown Stock Report Booking(Details)");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}