import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { ReportService } from 'src/app/dataService/report-service';

@Component({
	selector: 'app-party-wise-total-status-details',
	templateUrl: './party-wise-total-status-details.component.html',
	styleUrls: ['./party-wise-total-status-details.component.css']
})
export class PartyWiseTotalStatusDetailsComponent implements OnInit {

	partyWiseTotalStatusDetailsDataList: any;
	dtTriggerPartyWiseTotalStatusDetails: Subject<any> = new Subject();
	dtOptionsPartyWiseTotalStatusDetails: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	///
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;
	address: any;
	office: any;
	destView = false;
	viewDestination = false;
	selectedIndex: any;
	selectedConsigneeName: any;
	consigneeNameInTable: any;
	searchConsigneeDto: PartyMasterDto = new PartyMasterDto();
	ShowDestForPartyWiseTotalStatusRpt = 'Show Dest In PartyWiseTotalStatusRpt'
	memoDtoForPartyWiseOsPopup = new CashMemoDto();
	lrDtoForGodownStockOsPopup: LRDto = new LRDto();
	setDestination: Array<any> = [];
	invoiceDtoForTransitLRsPopup: InvoiceDto = new InvoiceDto();
	partyWiseRateMasterDtoPopUp = new RateMasterDto();

	//For Custom Print
	viewCustomPrintV1: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;

	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	pageId = "pwtsd";

	partyWiseRateTable = false;
	dtOptionsRateHistoryReportPartyWise: any;
	dtTriggerRateHistoryReportPartyWise: Subject<any> = new Subject();
	rateHistoryReportPartyWiseDataList: any;

	partywiseOSDetailTable = false;
	dtOptionPartywiseOSDetail: any;
	dtTriggerPartywiseOSDetail: Subject<any> = new Subject();
	detailDataPartywiseOSList: any;

	partywiseTransitLrsDetailTable = false;
	dtTriggerTransitLrsDetailsReport: Subject<any> = new Subject();
	dtOptionsTransitLrsDetailsDetailsReport: any;
	transitLrsDetailsReportDataList: any;

	godownStocksDetailsReportTable = false;
	dtOptionGodownStocksDetailsReport: any;
	dtTriggerGodownStocksDetailsReport: Subject<any> = new Subject();
	godownStocksDetailsReportDataList: any;

	lrDtoForSearch: LRDto = new LRDto();
	consigneeBusinessDetailsReportTable = false;
	dtOptionBusinessDetail: any;
	dtTriggerBusinessDetail: Subject<any> = new Subject();
	businessDetailDataList: any;
	selectedFromDate: any;
	selectedToDate: any;

	constructor(private router: Router, private masterReadService: MasterReadService,
		private datePipe: DatePipe, private consigneeService: ConsigneeService,
		public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService,
		private reportService: ReportService) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.getConsigneeDetails(false);

			///Rights for show Show Source List
			//            if ( this.ShowDestForPartyWiseTotalStatusRpt == "Show Dest In PartyWiseTotalStatusRpt" ) {
			//                this.viewDestination = true;
			//this.getDestinationDetails();         
			//} 
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"PartyWiseTotalStatusRpt destView") {
						this.destView = true;
					}
				}
			}
			//need to comment this bel
			//this.destView =true;
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}
	ngOnInit(): void {
		var mainStation = this.userDataDtoReturnSession.mainStation;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		var destVisible = false;
		if (this.destView == true) {
			destVisible = true;
		} else {
			destVisible = false;
		}
		var groupColumn = 0;
		this.dtOptionsPartyWiseTotalStatusDetails = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = null;
						if (destVisible == true) {
							returnSummaryExcel = "Party Wise Total Status Report" +
								"From Date : " + moment($("#pwtsdfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#pwtsdtoDate").val()).format('DD-MM-YYYY') + " Party Name : " + $("#pwtsdconsigneeName").val() + " Destination : " + $("#pwtsddestination").val();
						} else {
							returnSummaryExcel = "Party Wise Total Status Report" +
								"From Date : " + moment($("#pwtsdfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#pwtsdtoDate").val()).format('DD-MM-YYYY') + " Party Name : " + $("#pwtsdconsigneeName").val() + " Destination : " + mainStation;
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": false,
			"scrollY": 100,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"searching": false
		},
			this.dtOptionsRateHistoryReportPartyWise = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "Rate History Details";
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 150,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"searching": false
			},
			this.dtOptionPartywiseOSDetail = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "Party OutStanding Details - " +
								"As On : " + moment($("#pwosrasOnDate").val()).format('DD-MM-YYYY');
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 150,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"searching": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var grandTotal = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var totalArticles = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html(grandTotal);
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(totalArticles);
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();

				}
			},
			this.dtOptionsTransitLrsDetailsDetailsReport = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "Transit LR Details";
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				processing: true,
				responsive: true,
				"scrollX": false,
				"scrollY": 150,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"searching": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var articles = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var topay = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(articles);
					$(api.column(5).footer()).html(topay);
					$(api.column(6).footer()).html(paid);
					$(api.column(7).footer()).html(actualWgt);
					$(api.column(8).footer()).html(chargedWgt);
				}
			},
			this.dtOptionGodownStocksDetailsReport = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 2, 18, 19, 20]
						},
						title: function () {
							var returnSummaryExceDet = null;
							returnSummaryExceDet = "Stock Details - " + "Date : " + dateformate;
							return returnSummaryExceDet;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 150,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"searching": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var articles = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var toPay = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var paid = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(articles);
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html(toPay);
					$(api.column(7).footer()).html(paid);
					$(api.column(8).footer()).html(actualWgt);
					$(api.column(9).footer()).html(chargedWgt);
					$(api.column(10).footer()).html();
					$(api.column(11).footer()).html();
					$(api.column(12).footer()).html();
					$(api.column(13).footer()).html();
					$(api.column(14).footer()).html();
					$(api.column(15).footer()).html();
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html();
					$(api.column(18).footer()).html();
				}
			},
			this.dtOptionBusinessDetail = {
				dom: "Bfrtip",
				buttons: [{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					exportOptions: {
						columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
					},
					title: function () {
						var returSummary = null;
						returSummary = "Consignee Detailed Report " +
							" From Date : " + moment($("#cnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
							"To Date : " + moment($("#cnrctoDate").val()).format('DD-MM-YYYY');
						return returSummary;
					}
				}],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search...",
				},
				processing: true,
				responsive: true,
				scrollX: true,
				scrollY: 150,
				scrollCollapse: true,
				paging: false,
				info: false,
				"searching": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var toPay = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var paid = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var art = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var actWgt = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					var chgWgt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var memoAmt = api.column(15).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var aoc = api.column(20).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var goosdVal = api.column(21).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var fov = api.column(22).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var lc = api.column(13).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var bc = api.column(14).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(1).footer()).html('Total : ' + data.length);
					$(api.column(8).footer()).html(toPay);
					$(api.column(9).footer()).html(paid);
					$(api.column(10).footer()).html(art);
					$(api.column(11).footer()).html(actWgt);
					$(api.column(12).footer()).html(chgWgt);
					$(api.column(13).footer()).html(lc);
					$(api.column(14).footer()).html(bc);
					$(api.column(15).footer()).html(memoAmt);
					$(api.column(20).footer()).html(aoc);
					$(api.column(21).footer()).html(goosdVal);
					$(api.column(22).footer()).html(fov);
				},
				"drawCallback": function (settings) {
					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
					var last = null;
					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
					var last = null;
					var toPay = 0;
					var paid = 0;
					var art = 0;
					var actWgt = 0;
					var chgWgt = 0;
					var memoAmt = 0;
					var aoc = 0;
					var goodsVal = 0;
					var fov = 0;
					var lc = 0;
					var bc = 0;
					var count = 0;
					//var pendingQty = 0;
					api.column(groupColumn, {
						page: 'current'
					}).data().each(function (group, i) {
						var val = api.row(api.row($(rows).eq(i)).index()).data();
						if (last !== group) {
							if (i != 0) {
								$(rows)
									.eq(i)
									.before(
										$(
											"<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
											{
												"class": "group",
												"data-id": group
											})
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": "Total : " + count
												}))
											.append($("<td></td>",
												{
													"colspan": 6,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": toPay
												}))

											.append($("<td></td>",
												{
													"colspan": 1,
													"text": paid
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": art
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": actWgt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": chgWgt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": lc
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": bc
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": memoAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 4,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": aoc
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": goodsVal
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": fov
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": ""
												}))
											.prop('outerHTML'));
								toPay = 0;
								paid = 0;
								art = 0;
								actWgt = 0;
								chgWgt = 0;
								memoAmt = 0;
								aoc = 0;
								goodsVal = 0;
								fov = 0;
								lc = 0;
								bc = 0;
								count = 0;
								//pendingQty = 0;
							}
							//the below is for column name grouping (in this case we group for destination wise)
							$(rows)
								.eq(i)
								.before(
									$(
										"<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append(
											$("<td></td>",
												{
													"colspan": 24,
													"text": group
												})).prop('outerHTML'));
							last = group;
						}
						count++;

						toPay += +val[8];
						paid += +val[9];
						art += +val[10];
						actWgt += +val[11];
						chgWgt += +val[12];
						memoAmt += +val[15];
						aoc += +val[20];
						goodsVal += +val[21];
						fov += +val[22];
						lc += +val[13];
						bc += +val[14];
						if (i == (rows.length - 1)) {
							//the below is for Sumary details grouping 
							$(rows)
								.eq(i)
								.after(
									$(
										"<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": "Total : " + count
											}))
										.append($("<td></td>",
											{
												"colspan": 6,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": toPay
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": paid
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": art
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": actWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": chgWgt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": lc
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": bc
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": memoAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 4,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": aoc
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": goodsVal
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": fov
											}))

										.append($("<td></td>",
											{
												"colspan": 1,
												"text": ""
											}))
										.prop('outerHTML'));
							toPay = 0;
							paid = 0;
							art = 0;
							actWgt = 0;
							chgWgt = 0;
							memoAmt = 0;
							aoc = 0;
							goodsVal = 0;
							fov = 0;
							lc = 0;
							bc = 0;
							count = 0;
							//pendingQty = 0;
						}
					});
				}
			}
	}
	ngOnDestroy(): void {
		this.dtTriggerPartyWiseTotalStatusDetails.unsubscribe();
		this.dtTriggerRateHistoryReportPartyWise.unsubscribe();
		this.dtTriggerPartywiseOSDetail.unsubscribe();
		this.dtTriggerTransitLrsDetailsReport.unsubscribe();
		this.dtTriggerGodownStocksDetailsReport.unsubscribe();
		this.dtTriggerBusinessDetail.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerPartyWiseTotalStatusDetails.next();
		this.dtTriggerRateHistoryReportPartyWise.next();
		this.dtTriggerPartywiseOSDetail.next();
		this.dtTriggerTransitLrsDetailsReport.next();
		this.dtTriggerGodownStocksDetailsReport.next();
		this.dtTriggerBusinessDetail.next();
	}
	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}

					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');
	};
	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					this.consigneeNameTATemp = this.consigneeNameTA;
					console.log(this.consigneeNameTA);
					if (destShow) {
						$("#" + this.pageId + "consigneeName").focus();
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.getConsigneeDetails(true);
	}
	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}
	searchMethod() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.searchConsigneeDto = new PartyMasterDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchConsigneeDto.consigneeName = this.selectedConsigneeName;
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
			this.searchConsigneeDto.consigneeId = this.selectedConsigneeId;
		} else {
			swal({
				title: "Warning",
				text: "Please Select Consignee !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		}
		if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				this.searchConsigneeDto.mainStation = this.userDataDtoReturnSession.mainStation;
			} else {
				this.searchConsigneeDto.mainStation = this.selectedDestination;
			}
		} else {
			this.searchConsigneeDto.mainStation = this.userDataDtoReturnSession.mainStation;
		}
		//this.searchConsigneeDto.mainStation=this.userDataDtoReturnSession.mainStation;
		this.searchConsigneeDto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchConsigneeDto);
		this.searchConsigneeMethod();
		this.consigneeNameInTable = this.searchConsigneeDto.consigneeName;
	}
	searchConsigneeMethod = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeOverallStatus(this.searchConsigneeDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "partyWiseTotalStatusDetailsId").DataTable().destroy();
				this.partyWiseTotalStatusDetailsDataList = [];
				this.rateHistoryReportPartyWiseDataList = [];
				this.detailDataPartywiseOSList = [];
				this.transitLrsDetailsReportDataList = [];
				this.godownStocksDetailsReportDataList = [];

				this.partyWiseTotalStatusDetailsDataList = response;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.dtTriggerPartyWiseTotalStatusDetails.next();

				this.partyWiseRateTable = false;
				this.partywiseOSDetailTable = false;
				this.partywiseTransitLrsDetailTable = false;
				this.godownStocksDetailsReportTable = false;
				this.consigneeBusinessDetailsReportTable = false;

				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	}

	getPartyWiseOsAmtRpt() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		console.log(this.selectedConsigneeName);
		this.memoDtoForPartyWiseOsPopup = new CashMemoDto();
		this.memoDtoForPartyWiseOsPopup.fromdate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		this.memoDtoForPartyWiseOsPopup.consigneeName = this.selectedConsigneeName;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
		this.memoDtoForPartyWiseOsPopup.consigneeId = this.selectedConsigneeId;
		if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				this.memoDtoForPartyWiseOsPopup.destination = this.userDataDtoReturnSession.mainStation;
			} else {
				this.memoDtoForPartyWiseOsPopup.destination = this.selectedDestination;
			}
			console.log(this.selectedDestination);
		} else {
			this.memoDtoForPartyWiseOsPopup.destination = this.userDataDtoReturnSession.mainStation;
		}
		this.memoDtoForPartyWiseOsPopup.companyId = this.userDataDtoReturnSession.companyId;
		this.partyWiseRateTable = false;
		this.partywiseOSDetailTable = true;
		this.partywiseTransitLrsDetailTable = false;
		this.godownStocksDetailsReportTable = false;
		this.consigneeBusinessDetailsReportTable = false;
		this.searchByDetailedMethod();
	}

	searchByDetailedMethod = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyOSDetailsWise(this.memoDtoForPartyWiseOsPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "detailsTableId").DataTable().destroy();
				this.detailDataPartywiseOSList = [];
				this.detailDataPartywiseOSList = response;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.dtTriggerPartywiseOSDetail.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party Wise Detailed View", "info");
			}, () => console.log('done');
	}

	getGodownStockRpt() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
		console.log(this.selectedConsigneeName);
		this.lrDtoForGodownStockOsPopup = new LRDto();
		this.lrDtoForGodownStockOsPopup.consigneeName = this.selectedConsigneeName;
		this.lrDtoForGodownStockOsPopup.consigneeId = this.selectedConsigneeId;

		if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				this.setDestination = [];
				this.setDestination.push(this.userDataDtoReturnSession.mainStation);
				this.lrDtoForGodownStockOsPopup.list = this.setDestination;
				this.lrDtoForGodownStockOsPopup.destination = this.userDataDtoReturnSession.mainStation;
			} else {
				this.setDestination = [];
				this.setDestination.push(this.selectedDestination);
				this.lrDtoForGodownStockOsPopup.list = this.setDestination;
				this.lrDtoForGodownStockOsPopup.destination = this.selectedDestination;
			}
		} else {
			this.setDestination = [];
			this.setDestination.push(this.userDataDtoReturnSession.mainStation);
			this.lrDtoForGodownStockOsPopup.list = this.setDestination;
			this.lrDtoForGodownStockOsPopup.destination = this.userDataDtoReturnSession.mainStation;
		}
		this.lrDtoForGodownStockOsPopup.companyId = this.userDataDtoReturnSession.companyId;
		this.partyWiseRateTable = false;
		this.partywiseOSDetailTable = false;
		this.partywiseTransitLrsDetailTable = false;
		this.godownStocksDetailsReportTable = true;
		this.consigneeBusinessDetailsReportTable = false;
		this.searchByDetailedWiseMethod();
	}
	searchByDetailedWiseMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getOSStocksDetails(this.lrDtoForGodownStockOsPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "godownStocksDetailsReportTableId").DataTable().destroy();
				this.godownStocksDetailsReportDataList = [];
				this.godownStocksDetailsReportDataList = response;
				if (response.length == 0) {
					swal({
						title: "Info",
						text: "No Stock details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.dtTriggerGodownStocksDetailsReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the stock details View", "error");
			}, () => console.log('done');
	};

	getTransitLrsRpt() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;

		this.invoiceDtoForTransitLRsPopup = new InvoiceDto();
		this.invoiceDtoForTransitLRsPopup.consigneeName = this.selectedConsigneeName;
		this.invoiceDtoForTransitLRsPopup.consigneeId = this.selectedConsigneeId;
		this.invoiceDtoForTransitLRsPopup.status = "Transit";

		this.partyWiseRateTable = false;
		this.partywiseOSDetailTable = false;
		this.partywiseTransitLrsDetailTable = true;
		this.godownStocksDetailsReportTable = false;
		this.consigneeBusinessDetailsReportTable = false;
		this.searchByDetailedMethodPopup();
	}

	searchByDetailedMethodPopup = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyTransitLrDetail(this.invoiceDtoForTransitLRsPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "transitLrsDetailsId").DataTable().destroy();
				this.transitLrsDetailsReportDataList = [];
				this.transitLrsDetailsReportDataList = response;
				if (response.length == 0) {
					swal({
						title: "Info",
						text: "No tranist Lrs found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				}
				this.dtTriggerTransitLrsDetailsReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Transit LRs View", "info");
			}, () => console.log('done');
	};

	getRateHistory() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
		console.log(this.selectedConsigneeName);
		this.partyWiseRateMasterDtoPopUp = new RateMasterDto();
		this.partyWiseRateMasterDtoPopUp.consigneeName = this.selectedConsigneeName;
		this.partyWiseRateMasterDtoPopUp.consigneeId = this.selectedConsigneeId;
		if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '') {
				this.partyWiseRateMasterDtoPopUp.destination = this.userDataDtoReturnSession.mainStation;
			} else {
				this.partyWiseRateMasterDtoPopUp.destination = this.selectedDestination;
			}
		} else {
			this.partyWiseRateMasterDtoPopUp.destination = this.userDataDtoReturnSession.mainStation;
		}
		this.partyWiseRateMasterDtoPopUp.fromDateStg = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		this.partyWiseRateMasterDtoPopUp.toDateStg = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		this.partyWiseRateTable = true;
		this.partywiseOSDetailTable = false;
		this.partywiseTransitLrsDetailTable = false;
		this.godownStocksDetailsReportTable = false;
		this.consigneeBusinessDetailsReportTable = false;
		this.searchByPartyWiseRateDetailsMethod();
	}
	searchByPartyWiseRateDetailsMethod = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRateHistDtlsRptPartyWise(this.partyWiseRateMasterDtoPopUp).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "partyWiseId").DataTable().destroy();
				this.rateHistoryReportPartyWiseDataList = [];
				this.rateHistoryReportPartyWiseDataList = response;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Rate details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.dtTriggerRateHistoryReportPartyWise.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party rate details", "info");
			}, () => console.log('done');
	}

	businessDetailsSearchMethod() {
		this.lrDtoForSearch = new LRDto();
		this.lrDtoForSearch.reportMode = "details";
		this.lrDtoForSearch.consigneeId = this.modelConsigneeNameId.consigneeId;
		this.lrDtoForSearch.dateMode = "defaultDate";

		this.partyWiseRateTable = false;
		this.partywiseOSDetailTable = false;
		this.partywiseTransitLrsDetailTable = false;
		this.godownStocksDetailsReportTable = false;
		this.consigneeBusinessDetailsReportTable = true;
		this.consigneeBusinessSearchDetails();
	}

	consigneeBusinessSearchDetails = () => {
		this.showSpinnerForAction = true;
		this.reportService.getConsigneeNamesReport(this.lrDtoForSearch).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "businessDetailsReportTableId").DataTable().destroy();
				this.businessDetailDataList = [];
				this.businessDetailDataList = response;
				if (response.length == 0) {
					swal({
						title: "Info",
						text: "No Records found for this search!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.dtTriggerBusinessDetail.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Consignee Business details! ", "info");
			}, () => console.log('done');
	}

	businessDetailsSearchDatewiseMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
			swal({
				title: "Mandatory Field",
				text: "Please select From Date,To Date  to get the  details!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.lrDtoForSearch = new LRDto();
			this.lrDtoForSearch.reportMode = "details";
			this.lrDtoForSearch.consigneeId = this.modelConsigneeNameId.consigneeId;
			this.lrDtoForSearch.dateMode = "defaultDatewise";
			this.lrDtoForSearch.fromDate = this.selectedFromDate;
			this.lrDtoForSearch.toDate = this.selectedToDate;

			this.partyWiseRateTable = false;
			this.partywiseOSDetailTable = false;
			this.partywiseTransitLrsDetailTable = false;
			this.godownStocksDetailsReportTable = false;
			this.consigneeBusinessDetailsReportTable = true;
			this.consigneeBusinessSearchDetails();
		}
	}

	clearMethod() {
		this.selectedDestination = '';
		this.selectedConsigneeName = '';
		this.showSpinnerForAction = false;
		this.selectedConsigneeId = 0;
		this.modelDestinationFotGet = '';
		this.modelConsigneeNameId = '';
		if (this.destView == true) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val('');
			$("#" + this.pageId + "consigneeName").val('');
			this.modelConsigneeName = '';
			this.consigneeNameTA = [];
		} else {
			this.consigneeNameTA = this.consigneeNameTATemp;
			$("#" + this.pageId + "consigneeName").val('');
			this.viewDestination = false;
		}
		this.partyWiseRateMasterDtoPopUp = new RateMasterDto();
		this.invoiceDtoForTransitLRsPopup = new InvoiceDto();
		this.lrDtoForGodownStockOsPopup = new LRDto();
		this.memoDtoForPartyWiseOsPopup = new CashMemoDto();
		$("#" + this.pageId + "partyWiseTotalStatusDetailsId").DataTable().destroy();
		this.partyWiseTotalStatusDetailsDataList = [];
		this.dtTriggerPartyWiseTotalStatusDetails.next();
		this.searchConsigneeDto = new PartyMasterDto();
	}

	customPrintPartyWiseTotStsRpt() {
		if (this.partyWiseTotalStatusDetailsDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["GST Number", "Address", "Pin Code", "Contact Number", "Contact Person", "Mobile", "OS Amount", "Left Amount", "OS LR Stocks", "Transit Lrs"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [20, 10, 8, 8, 8, 8, 8, 8, 9, 8];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			for (let i = 0; i < this.partyWiseTotalStatusDetailsDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.partyWiseTotalStatusDetailsDataList[i].gstNoConsignee, this.partyWiseTotalStatusDetailsDataList[i].address,
				this.partyWiseTotalStatusDetailsDataList[i].pincode, this.partyWiseTotalStatusDetailsDataList[i].officeNumber, this.partyWiseTotalStatusDetailsDataList[i].contactPerson,
				this.partyWiseTotalStatusDetailsDataList[i].mobileNumber, this.partyWiseTotalStatusDetailsDataList[i].grandTotal,
				this.partyWiseTotalStatusDetailsDataList[i].leftamount, this.partyWiseTotalStatusDetailsDataList[i].totalLrs,
				this.partyWiseTotalStatusDetailsDataList[i].transitLrs];
				/////////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.partyWiseTotalStatusDetailsDataList.length, "", "", "", "", "", "", "", "", ""];
			//heading logics For Date
			//this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];
			//this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"] ;
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Party Name", "Destination"];
			// this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [ this.searchLetterHeadRateDto.fromDateStg, this.searchLetterHeadRateDto.toDateStg];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.searchConsigneeDto.consigneeName, this.searchConsigneeDto.mainStation];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}
			//this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
			//for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
			//this.cashMemoDtoForCustomPrint = new CashMemoDto();
			//this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
			//this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			//	}

			//this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
			//for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
			//	this.cashMemoDtoForCustomPrint = new CashMemoDto();
			//	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
			//	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
			//}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "CashMemo LetterHead Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
}
