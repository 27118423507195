<html>

<head> </head>

<body>
	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrDwsWgtRpt">
		<div class="col-lg-12">
			<div class="card" style="border: 1px solid darkcyan !important">
				<div class="card-header bg-info hide-header" style="background-color: orange !important; padding: 5px">
					<h6 class="card-title text-white">LR DWS Weight Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text">
																<i class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewType"
															name="viewType" #viewType
															(change)="viewTypeMode(viewType.value)">
															<option selected value="bookingDateWise">
																Booking Date Wise
															</option>
															<option value="entryDateWise">
																Check-In Date Wise
															</option>
															<option value="challanDateWise">Challan Date Wise</option>
															<option value="challanNoWise">Challan No Wise</option>
														</select>
													</div>
												</div>
											</div>

											<div *ngIf="viewTypeOther" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" placeholder="dd-mm-yyyy"
															name="fromDate" [(ngModel)]="setTodayDateOnFromDate"
															ngbDatepicker #fromDate="ngbDatepicker">
														<div class="input-group-append" (click)="fromDate.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="viewTypeOther" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate"
															class="form-control" [(ngModel)]="setTodayDateOnToDate"
															placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker
															#toDate="ngbDatepicker">
														<div class="input-group-append" (click)="toDate.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewTypeOther" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source </label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}source" type="text" class="form-control"
															[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
															[resultFormatter]="formatterSource"
															[inputFormatter]="formatterSource"
															(focus)="focusSourceTA$.next($any($event).target.value)"
															placeholder="Select Source" />


													</div>
												</div>
											</div>
											<div *ngIf="viewTypeOther" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text"
																class="form-control" [(ngModel)]="modelDestination"
																(selectItem)="destinationListener($event)"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />

														</div>
													</div>
												</div>
											</div>
											<div *ngIf="viewTypeChallan" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Challan Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class=" fas fa-clipboard"></i>
															</span>
														</div>
														<input #challanNumber type="text" id="{{pageId}}challanNumber"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>


										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px" />
						<div class="col-md-12" style="text-align: center">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="searchMethod()">
								Search
							</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearValidaton()">
								Clear
							</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<!-- <div class="row p-t-10">
								<div class="col-md-8">
									<h6 class="card-title" [hidden]="!consigneeSummaryTable">Summary View
									</h6>
									<h6 class="card-title" [hidden]="!detailTable">Details View</h6>


								</div>
								<div class="col-md-4">
									<button type="submit" class="dt-button" [hidden]="!consigneeSummaryTable"
										style="margin-left: 75%;" id="{{pageId}}printBtnForSummary" (click)="pritnSummaryWise()">
										<span><i class="fas fa-print">Print</i></span>
									</button>
									<button type="submit" class="dt-button" [hidden]="!detailTable"
										id="{{pageId}}printBtnForDetail" style="margin-left: 75%;"(click)="pritnDetailsWise()">
										<span><i class="fas fa-print">Print</i></span>
									</button>

								</div>
							</div> -->
							<div class="box-body">

								<table datatable class="table table-striped table-bordered row-border hover"
									id="{{pageId}}lrDwsWgtReportTableId" [dtOptions]="dtOptionsLrDwsWgt"
									[dtTrigger]="dtTriggerLrDwsWgt">
									<thead>
										<tr>
											<th>LR Number</th>
											<th>Booking Date</th>
											<th>Source</th>
											<th>Destination</th>
											<th>Challan No</th>
											<th>Sys Size</th>
											<th>Sys Act Wt</th>
											<th>Sys Chg Wt</th>
											<th>Sys Art</th>
											<th>Dws Size</th>
											<th>Dws Act Wt</th>
											<th>Dws Chg Wt</th>
											<th>Dws Art</th>
											<th>Diff Act Wt</th>
											<th>Per Art Act Wt Diff</th>
											<th>Diff Act Wt %</th>
											<th>Diff Chg Wt</th>
											<th>Diff Art</th>
											<th>Sys Vol CFT(A)</th>
											<th>DWS Vol CFT(B)</th>
											<th>Diff Vol(A-B)</th>
											<th>Sys Tot Size(C)</th>
											<th>DWS Tot Size(D)</th>
											<th>Diff Size(C-D)</th>
											<th>Overall Diff</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let lrDwsWgtData of lrDwsWgtDataList">
											<td>{{ lrDwsWgtData.lrNumber }}</td>
											<td>{{ lrDwsWgtData.bookingDateStr }}</td>
											<td>{{ lrDwsWgtData.source }}</td>
											<td>{{ lrDwsWgtData.destination }}</td>
											<td>{{ lrDwsWgtData.challanNo }}</td>
											<td>
												<span *ngIf="lrDwsWgtData.listOfmultipleArt == null">NA</span>
												<span *ngIf="lrDwsWgtData.listOfmultipleArt != null"
													(click)="rowSelectedSysSizeViewMtd(lrDwsWgtData,multipleArtSizePopUpTemplate);"><u
														style="cursor: pointer;color:blue;">View Size</u></span>
											</td>
											<td>{{ lrDwsWgtData.actualWeight }}</td>
											<td>{{ lrDwsWgtData.chargedWeight }}</td>
											<td>{{ lrDwsWgtData.totalArticles }}</td>
											<td>
												<span *ngIf="lrDwsWgtData.dwsListOfmultipleArt == null">NA</span>
												<span *ngIf="lrDwsWgtData.dwsListOfmultipleArt != null"
													(click)="rowSelectedDwsSizeViewMtd(lrDwsWgtData,multipleArtSizePopUpTemplate);"><u
														style="cursor: pointer;color:blue;">View Size</u></span>
											</td>
											<td>{{ lrDwsWgtData.dwsweight }}</td>
											<td>{{ lrDwsWgtData.dwsvolumeweight }}</td>
											<td>{{ lrDwsWgtData.dwsawbTotal }}</td>
											<td>{{ lrDwsWgtData.diffActualWeight }}</td>
											<td>{{ lrDwsWgtData.perArtActWgtDiff }}</td>
											<td>{{ lrDwsWgtData.diffActWgtPerc }}</td>
											<td>{{ lrDwsWgtData.diffChargedWeight }}</td>
											<td>{{ lrDwsWgtData.diffTotalArticles }}</td>
											<td>{{ lrDwsWgtData.sysVolumeCftTot }}</td>
											<td>{{ lrDwsWgtData.dwsVolumeCftTot }}</td>
											<td>{{ lrDwsWgtData.diff10 }}</td>
											<td>{{ lrDwsWgtData.sysTotSizeTot }}</td>
											<td>{{ lrDwsWgtData.dwsTotSizeTot }}</td>
											<td>{{ lrDwsWgtData.diff11 }}</td>
											<td>
												<span
													(click)="rowSelectedOverallDiffViewMtd(lrDwsWgtData,multipleArtSizeV3PopUpTemplate);"><u
														style="cursor: pointer;color:blue;">View Diff</u></span>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tfoot>

								</table>
							</div>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}ddds">
				<ng-template #multipleArtSizePopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h6>Multiple Article Size Details</h6>
					</div>
					<div class="modal-body" style="padding-top:0px;">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div *ngIf="viewMultipleArtSizePopUp" onafterPopUp="afterPopUp()"
												id="{{pageId}}popupMultipleArtSize">
												<app-multiple-article-size-V2-popup>
												</app-multiple-article-size-V2-popup>
											</div>
										</div>
									</div>
									<br>
									<div class="row">
										<div class="col-sm-12 col-md-4"></div>
										<div class="col-sm-12 col-md-2">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnIdForMultipleArtSize"
												(click)="closeMultipleArtSizePopUp()">Close</button>
										</div>
										<div class="col-sm-12 col-md-4"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}dddsV3">
				<ng-template #multipleArtSizeV3PopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h6>Overall Article Size Details (System-DWS)</h6>
					</div>
					<div class="modal-body" style="padding-top:0px;">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div *ngIf="viewMultipleArtSizeV3PopUp" onafterPopUp="afterPopUp()"
												id="{{pageId}}popupMultipleArtSizeV3">
												<app-multiple-article-size-V3-popup>
												</app-multiple-article-size-V3-popup>
											</div>
										</div>
									</div>
									<br>
									<div class="row">
										<div class="col-sm-12 col-md-4"></div>
										<div class="col-sm-12 col-md-2">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnIdForMultipleArtSizeV3"
												(click)="closeMultipleArtSizeV3PopUp()">Close</button>
										</div>
										<div class="col-sm-12 col-md-4"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>


	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>

</body>

</html>