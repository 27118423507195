import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription, Observable } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterDto } from 'src/app/dto/master-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { from, merge } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import swal from 'sweetalert';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { MasterService } from 'src/app/dataService/master-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
  selector: 'app-lr-performance-report',
  templateUrl: './lr-performance-report.component.html',
  styleUrls: ['./lr-performance-report.component.css']
})
export class LrPerformanceReportComponent implements OnInit {



  getDataFrmServiceFrLrPerformanceReportTable: any;


  lrPerformanceReportDataList: any;

  onDestroyUnsubscribtionLrPerformanceReport: Subscription;



  //for datepicker starts
  model: NgbDateStruct;
  model2;
  //for datepicker ends

  loadingIndicator = true;


  //for datePicker starts
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  closeResult: string;
  //for datepicker ends


  @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

  dtTriggerLrPerformanceReport: Subject<any> = new Subject();


  dataTable: any;

  dtOptionsLrPerformanceReport: any;

  userDataDtoReturnSession: any;
  isLoggedIn = true;
  showSpinnerForAction: boolean = false;

  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { destination: string }) => x.destination;

  public modelConsigneeName: any;
  consigneeNameOptions: PartyMasterDto[];
  partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
  consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
  consigneeNameTA: Array<PartyMasterDto> = [];
  focusConsigneeNameTA$ = new Subject<string>();
  consigneeNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeNameTA
        : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

  isDestView: boolean = false;
  viewDestinationField: boolean = false;

  lrDtoForSearch: LRDto = new LRDto();
  validateConsigneeDestination: any;
  validateSelectedConsigneeId: any;
  validateSelectedConsigneeName: any;
  validateSelectedDestination: any;
  validateSelectedFromDate: any;
  validateSelectedToDate: any;

  viewCustomPrintV1 = false;

  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;


  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  //Custom Print Footer
  summary1: number;
  summary2: number;
  summary3: number;
  summary4: number;
  summary5: number;
  summary6: number;
  pageId="lrprc";

  constructor(private issueBalanceReport: MasterService,
    private router: Router, private mastereadService: MasterReadService,
    public dialog: MatDialog,
    private lrService: LrService,
    public changeDetectorRef : ChangeDetectorRef) {

    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Dest LrPerFormRpt") {
            this.isDestView = true;
          }
        }
      }

      if (this.isDestView) {
        this.viewDestinationField = true;
        this.getDestinationDetails();
      } else {
        this.viewDestinationField = false;
      }


    }

  }



  logInPage() {
    this.router.navigate(['/authentication/login']);
  }




  ngOnInit(): void {


    this.dtOptionsLrPerformanceReport = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      //place holder in search/filter in datatable ends  
      processing: true,
      //scroll in datatable starts
      responsive: true,
      "scrollX": true,
      "scrollY": 380,
      "scrollCollapse": true,
      //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        // computing column Total of the complete result 
        var article = api.column(5).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var hd = api.column(6).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var gd = api.column(7).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actwt = api.column(8).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var chgwt = api.column(9).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);


        // Update footer by showing the total with the reference of the column index 
        $(api.column(0).footer()).html('Total : ' + data.length);
        $(api.column(5).footer()).html(article);
        $(api.column(6).footer()).html(hd);
        $(api.column(7).footer()).html(gd);
        $(api.column(7).footer()).html(actwt);
        $(api.column(7).footer()).html(chgwt);
      },
    }
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }

  ngAfterViewInit(): void {
    this.dtTriggerLrPerformanceReport.next();
  }


  ngOnDestroy(): void {
    this.dtTriggerLrPerformanceReport.unsubscribe();

    //this.onDestroyUnsubscribtionLrPerformanceReport.unsubscribe();

  }



  //for datepicker
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }

  //for datePicker

  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelDestination = e.item;
    $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    if ($("#"+this.pageId+"destinationId").val() != null &&
      $("#"+this.pageId+"destinationId").val() != undefined &&
      $("#"+this.pageId+"destinationId").val() != "") {
      this.getConsigneeDetails();
    }
  }

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.mode = "NotBkgAdmin";
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;

  }
  getDestinationDetails = () => {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
      (response) => {
        if (response.length > 0) {
          this.destinationTA = [];
          this.destinationOptions = [];
          this.destinationOptions = response;
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
          }
          this.showSpinnerForAction = false;
        } else {
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Server Error", "While Getting destination details", "warning");
      },
      () => console.log('done');
  };

  consigneeDropDownListner(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelConsigneeName = e.item;
    $("#"+this.pageId+"consigneeName").val(this.modelConsigneeName.consigneeName);
    this.validateSelectedConsigneeId = null;
    this.validateSelectedConsigneeId = this.modelConsigneeName.consigneeId;
    console.log(this.modelConsigneeName);
  }

  getConsigneeDetailsList() {

    this.partyMasterDtoForconsignee = new PartyMasterDto();
    this.validateConsigneeDestination = $("#"+this.pageId+"destinationId").val();
    if (this.isDestView) {
      this.partyMasterDtoForconsignee.office = this.validateConsigneeDestination;
      this.partyMasterDtoForconsignee.branch = this.validateConsigneeDestination;
    } else {
      this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
      this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
    }

    this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForconsignee.mode = "specific";
  }
  getConsigneeDetails = () => {
    this.getConsigneeDetailsList();
    this.showSpinnerForAction = true;
    this.mastereadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          this.consigneeNameOptions = [];
          this.consigneeNameTA = [];
          this.consigneeNamePartyDtoAllOption.consigneeName = "All";
          this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
        } else {
          this.consigneeNameOptions = [];
          this.consigneeNameOptions = response;
          this.consigneeNameTA = [];
          this.consigneeNamePartyDtoAllOption.consigneeName = "All";
          this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
          for (let i = 0; i < this.consigneeNameOptions.length; i++) {
            this.consigneeNameTA.push(this.consigneeNameOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the consignee details", "info");
      }, () => console.log('done');
  };
  validateSearchBtn() {
    if (this.isDestView) {
      if ($("#"+this.pageId+"destinationId").val() == null || $("#"+this.pageId+"destinationId").val() == undefined ||
        $("#"+this.pageId+"destinationId").val() == "" || $("#"+this.pageId+"fromDates").val() == null ||
        $("#"+this.pageId+"fromDates").val() == undefined || $("#"+this.pageId+"fromDates").val() == "" ||
        $("#"+this.pageId+"toDates").val() == null || $("#"+this.pageId+"toDates").val() == undefined ||
        $("#"+this.pageId+"toDates").val() == "" || $("#"+this.pageId+"consigneeName").val() == null ||
        $("#"+this.pageId+"consigneeName").val() == undefined || $("#"+this.pageId+"consigneeName").val() == "") {
        swal("Warning", "Consignee,Destination,From Date & To Date is Mandatory Field", "warning");
        return false;
      } else {
        this.setLRPerformanceDetailsRpt();
      }
    } else {
      if ($("#"+this.pageId+"fromDates").val() == null ||
        $("#"+this.pageId+"fromDates").val() == undefined || $("#"+this.pageId+"fromDates").val() == "" ||
        $("#"+this.pageId+"toDates").val() == null || $("#"+this.pageId+"toDates").val() == undefined ||
        $("#"+this.pageId+"toDates").val() == "" || $("#"+this.pageId+"consigneeName").val() == null ||
        $("#"+this.pageId+"consigneeName").val() == undefined || $("#"+this.pageId+"consigneeName").val() == "") {
        swal("Warning", "From Date & To Date & Consignee is Mandatory Field", "warning");
        return false;
      } else {
        this.setLRPerformanceDetailsRpt();
      }
    }
  }
  setLRPerformanceDetailsRpt() {
    this.lrDtoForSearch = new LRDto();
    this.validateSelectedConsigneeName = $("#"+this.pageId+"consigneeName").val();
    this.validateSelectedDestination = $("#"+this.pageId+"destinationId").val();
    this.validateSelectedFromDate = $("#"+this.pageId+"fromDates").val();
    this.validateSelectedToDate = $("#"+this.pageId+"toDates").val();

    this.lrDtoForSearch.fromDate = this.validateSelectedFromDate;
    this.lrDtoForSearch.toDate = this.validateSelectedToDate;
    if (this.isDestView) {
      this.lrDtoForSearch.destination = this.validateSelectedDestination;
    } else {
      this.lrDtoForSearch.destination = this.userDataDtoReturnSession.mainStation;
    }
    this.lrDtoForSearch.consigneeName = this.validateSelectedConsigneeName;
    if (this.validateSelectedConsigneeName != null &&
      this.validateSelectedConsigneeName != undefined &&
      this.validateSelectedConsigneeName != "" &&
      this.validateSelectedConsigneeName == "All") {
      this.validateSelectedConsigneeId = 0;
      this.lrDtoForSearch.consigneeId = this.validateSelectedConsigneeId;
    } else {
      this.lrDtoForSearch.consigneeId = this.validateSelectedConsigneeId;
    }
    this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
    this.showSpinnerForAction = true;
    this.lrService.getLrPerformRptDetails(this.lrDtoForSearch).subscribe(
      (response) => {
        console.log(response);
        this.showSpinnerForAction = false;
        this.lrPerformanceReportDataList = [];
        $("#"+this.pageId+"lrPerformanceTableId").DataTable().destroy();
        if (response.length == 0) {
          swal("No Data", "No Records  found for this search", "warning");
        } else {
          this.lrPerformanceReportDataList = response;
        }
        this.dtTriggerLrPerformanceReport.next();
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the LR Performance details", "info");
      }, () => console.log('done');
  }

  clearBtn() {
    this.clearField();
  }
  clearField() {
    this.lrPerformanceReportDataList = [];
    $("#"+this.pageId+"lrPerformanceTableId").DataTable().destroy();
    this.dtTriggerLrPerformanceReport.next();

    this.lrDtoForSearch = new LRDto();
    this.validateConsigneeDestination = null;
    this.validateSelectedConsigneeId = 0;
    this.validateSelectedConsigneeName = null;
    this.validateSelectedDestination = null;
    this.validateSelectedFromDate = null;
    this.validateSelectedToDate = null;

    $("#"+this.pageId+"consigneeName").val('');
    $("#"+this.pageId+"destinationId").val('');
    $("#"+this.pageId+"fromDates").val('');
    $("#"+this.pageId+"toDates").val('');

    this.modelConsigneeName = null;
    this.modelDestination = null;
  }

  customPrintLRPerformance() {
    if (this.lrPerformanceReportDataList.length == 0) {
      swal({

        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Consignee", "Art", "GD",
        "DD", "AW", "CW", "BKNG", "LDNG", "UNLDNG", "SRC", "Dis.W", "ARR.W", "Deli.W"];
      //this.cashMemoDtoForCustomPrintListColumnWidths = [13, 8, 15, 8, 6, 5, 5, 5, 3, 5, 5,13,9];
      this.cashMemoDtoForCustomPrintListColumnWidths = [8, 15, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintDataList = [];

      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
      for (let i = 0; i < this.lrPerformanceReportDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.lrPerformanceReportDataList[i].lrNumber,
          this.lrPerformanceReportDataList[i].consigneeName,
          this.lrPerformanceReportDataList[i].totalArticles,
          this.lrPerformanceReportDataList[i].hamaliGD,
          this.lrPerformanceReportDataList[i].hamaliDD,
          this.lrPerformanceReportDataList[i].actualWeight,
          this.lrPerformanceReportDataList[i].chargedWeight,
          this.lrPerformanceReportDataList[i].bookingDateStr,
          this.lrPerformanceReportDataList[i].loadingDateStr,
          this.lrPerformanceReportDataList[i].fromDate,
          this.lrPerformanceReportDataList[i].source,
          this.lrPerformanceReportDataList[i].dispatchedwithin,
          this.lrPerformanceReportDataList[i].arrivalwithin,
          this.lrPerformanceReportDataList[i].deliveredwithin
        ];
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

        this.summary1 = this.summary1 + this.lrPerformanceReportDataList[i].totalArticles;
        this.summary2 = this.summary2 + this.lrPerformanceReportDataList[i].hamaliGD;
        this.summary3 = this.summary3 + this.lrPerformanceReportDataList[i].hamaliDD;
        this.summary4 = this.summary4 + this.lrPerformanceReportDataList[i].actualWeight;
        this.summary5 = this.summary5 + this.lrPerformanceReportDataList[i].chargedWeight;
        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrPerformanceReportDataList.length,
          "", this.summary1, this.summary2, this.summary3, this.summary4, this.summary5, "", "", "", "", "", "", ""];
      }
      //heading logics For Date
      this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
      this.toDatePrint = $("#"+this.pageId+"toDates").val();
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fromDatePrint, this.toDatePrint];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }
      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "LR Performance Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

}

