import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-multiple-point-details',
	templateUrl: './multiple-point-details.component.html',
	styleUrls: ['./multiple-point-details.component.css']
})
export class MultiplePointDetailsComponent implements OnInit {

	gridDeliveryPointMultiple: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerDeliveryPointMultiple: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsDeliveryPointMultiple: any;


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	popUpOpenMultipleDeliveryPointData: any;


	/* DROP DOWN */

	lrDtoForMultipleInvoice: LRDto = new LRDto();
	lrDtoListMultipleDeliveryPointOptions: LRDto[];
	public modelMultipleDeliveryPointDestination: any;
	MultipleDeliveryPointStationTA: Array<LRDto> = [];
	focusMultipleDeliveryPointDestTA$ = new Subject<string>();
	searchMultipleDeliveryPointDest = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMultipleDeliveryPointDestTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.MultipleDeliveryPointStationTA
				: this.MultipleDeliveryPointStationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMultipleDeliveryPointDest = (x: { destination: string }) => x.destination;

	/* DROP DOWN */
	userDataDtoMultipleDeliveryPointStationCode: UserDataDto = new UserDataDto();
	hireSlipDtoForDeliveryPointMultiple: HireSlipDto = new HireSlipDto();
	userDataDtoReturnMultipleDeliveryPointStationCode: any;
	multiDeliveryPointDestination: any;
	searchFormatForMultipleDeliveryPoint: any;
	multipleDeliveryPointFormat: any;
	multipleDeliveryPointSearch: any;

	private newAttributeToSetMultipleDeliveryPoint: any = {};
	firstPtDelivPopup: any;

	editMultipleDeliveryPointTableDetailsId: any = null;
	hireslipLocalStorageDeliveryPoint: any;

	hireDto: HireSlipDto = new HireSlipDto();
	hireDtoList: HireSlipDto[];
	listOfInvoice: Array<String>;

	onloadGridDeliveryPointMultiple: any;
pageId="mpdc";
	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<MultiplePointDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenMultipleDeliveryPointData = JSON.parse(localStorage.getItem('DeliveryPointOpen'));
			this.hireslipLocalStorageDeliveryPoint = JSON.parse(localStorage.getItem('DeliveryPointHireslipLocalStorage'));
			localStorage.clear();
			console.log(this.popUpOpenMultipleDeliveryPointData);
			console.log(this.hireslipLocalStorageDeliveryPoint);
			this.getDestinationMultiInvoice();

			if (this.hireslipLocalStorageDeliveryPoint.listOfInvoice.length > 0) {
				var arrayData = [];
				this.gridDeliveryPointMultiple = [];
				for (let [key, value] of Object.entries(this.hireslipLocalStorageDeliveryPoint.hashMap)) {
					this.newAttributeToSetMultipleDeliveryPoint = {};
					this.newAttributeToSetMultipleDeliveryPoint.invoiceNumber = value;
					this.newAttributeToSetMultipleDeliveryPoint.deliveryarea = key;
					if (this.newAttributeToSetMultipleDeliveryPoint.deliveryarea == this.hireslipLocalStorageDeliveryPoint.firstPntDelv) {
						this.newAttributeToSetMultipleDeliveryPoint.deliveryPoints = true;
					} else {
						this.newAttributeToSetMultipleDeliveryPoint.deliveryPoints = false;
					}
					arrayData.push(this.newAttributeToSetMultipleDeliveryPoint)
				}
				this.gridDeliveryPointMultiple = arrayData;
			} else {
				this.gridDefaultInvAdd();
			}

		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsDeliveryPointMultiple = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
			},
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerDeliveryPointMultiple.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerDeliveryPointMultiple.next();
	}



	clickListnerForMultipleDeliveryPointStation(e: NgbTypeaheadSelectItemEvent) {
		this.modelMultipleDeliveryPointDestination = e.item;
		console.log(this.modelMultipleDeliveryPointDestination);
		$("#"+this.pageId+"multipleDeliveryPointDestinationId").val(this.modelMultipleDeliveryPointDestination.destination);
		this.getStationCodeMultiInvoice();
	}

	getDestinationMultiInvoice() {
		this.lrDtoForMultipleInvoice = new LRDto();
		this.lrDtoForMultipleInvoice.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForMultipleInvoice.mode = "Other";
		this.lrDtoForMultipleInvoice.companyId = this.userDataDtoReturnSession.companyId;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoForMultipleInvoice).subscribe(
			(response) => {
				if (response.length == 0) {
					//swal("Warning", "No Destination Multi Invoice records found!", "warning");
					this.lrDtoListMultipleDeliveryPointOptions = [];
					this.MultipleDeliveryPointStationTA = [];
				} else {
					this.lrDtoListMultipleDeliveryPointOptions = [];
					this.MultipleDeliveryPointStationTA = [];
					this.lrDtoListMultipleDeliveryPointOptions = response;
					for (let i = 0; i < this.lrDtoListMultipleDeliveryPointOptions.length; i++) {
						this.MultipleDeliveryPointStationTA.push(this.lrDtoListMultipleDeliveryPointOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Destination Multi Invoice", "warning");
			},
			() => console.log('done');
	};

	getStationCodeMultiInvoice() {
		this.userDataDtoMultipleDeliveryPointStationCode = new UserDataDto();
		this.multiDeliveryPointDestination = $("#"+this.pageId+"multipleDeliveryPointDestinationId").val();
		this.userDataDtoMultipleDeliveryPointStationCode.office = this.multiDeliveryPointDestination;
		//alert(this.multiInvoiceDestination);
		this.userDataDtoMultipleDeliveryPointStationCode.companyId = this.userDataDtoReturnSession.companyId;
		this.masterReadService.getStationCodeService(this.userDataDtoMultipleDeliveryPointStationCode).subscribe(
			(response) => {
				this.userDataDtoReturnMultipleDeliveryPointStationCode = response;
				if (response) {
					this.searchFormatForMultipleDeliveryPoint = null;
					this.searchFormatForMultipleDeliveryPoint = this.userDataDtoReturnSession.stationCode + "/"
						+ this.userDataDtoReturnMultipleDeliveryPointStationCode.stationCode + "/"
						+ this.userDataDtoReturnSession.financialYearSplit + "/";
					$("#"+this.pageId+"deliveryPointMultipleFormat").val(this.searchFormatForMultipleDeliveryPoint);
					//pending work
					//txtMultiInvoicePopupInvoiceFormat.setReadOnly(false);
					$("#"+this.pageId+"deliveryPointMultipleSearch").focus();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Error",
				text: "Server Error While Getting Destination Multi Invoice",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	}

	gridDefaultInvAdd() {
		var invoiceNumber = this.hireslipLocalStorageDeliveryPoint.invoiceNumber;
		if (this.gridDeliveryPointMultiple == null ||
			this.gridDeliveryPointMultiple == undefined ||
			this.gridDeliveryPointMultiple.length == 0 &&
			this.hireslipLocalStorageDeliveryPoint.tostation) {
			this.gridDeliveryPointMultiple = [];
			this.newAttributeToSetMultipleDeliveryPoint.invoiceNumber = invoiceNumber;
			this.newAttributeToSetMultipleDeliveryPoint.deliveryarea = this.hireslipLocalStorageDeliveryPoint.tostation;
			this.gridDeliveryPointMultiple.push(this.newAttributeToSetMultipleDeliveryPoint);
		}
		this.newAttributeToSetMultipleDeliveryPoint = {};
	}

	keyEventHandlerMultiDeliveryPointSearch(event) {
		if (event == 13) {
			if ($("#"+this.pageId+"deliveryPointMultipleSearch").val() == null ||
				$("#"+this.pageId+"deliveryPointMultipleSearch").val() == undefined ||
				$("#"+this.pageId+"deliveryPointMultipleSearch").val() == "") {
				swal("Empty Data", "Please enter the Invoice Number", "warning");
				$("#"+this.pageId+"deliveryPointMultipleSearch").focus();
			} else {
				this.hireSlipDtoForDeliveryPointMultiple = new HireSlipDto();
				this.multipleDeliveryPointFormat = $("#"+this.pageId+"deliveryPointMultipleFormat").val();
				this.multipleDeliveryPointSearch = $("#"+this.pageId+"deliveryPointMultipleSearch").val();
				this.hireSlipDtoForDeliveryPointMultiple.invoiceNumber = this.multipleDeliveryPointFormat + ""
					+ this.multipleDeliveryPointSearch;
				this.hireSlipDtoForDeliveryPointMultiple.fromStation = this.userDataDtoReturnSession.mainStation;

				this.stockService.checkInvoiceForMultiPtDeliv(this.hireSlipDtoForDeliveryPointMultiple).subscribe(
					(response) => {
						if (response) {
							console.log(response.status);
							//response.status = "allow";
							if (response.status != null && response.status == "allow") {
								this.reconfigureAddMultiDeliveryPointInvoice();
								$("#"+this.pageId+"deliveryPointMultipleSearch").val('');
							} else if (response.status != null && response.status == "block") {
								swal("Not Allowed",
									"Invoice Number is Not Allowed",
									"warning");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						swal("Error", "Server Error While Getting Station Code Service", "warning");
					}, () => console.log('done');
			}
		}
	}

	reconfigureAddMultiDeliveryPointInvoice() {
		this.newAttributeToSetMultipleDeliveryPoint.invoiceNumber = $("#"+this.pageId+"deliveryPointMultipleFormat").val() + "" +
			$("#"+this.pageId+"deliveryPointMultipleSearch").val();
		this.newAttributeToSetMultipleDeliveryPoint.deliveryarea = $("#"+this.pageId+"multipleDeliveryPointDestinationId").val();
		this.newAttributeToSetMultipleDeliveryPoint.deliveryPoints = false;
		if (this.gridDeliveryPointMultiple == null ||
			this.gridDeliveryPointMultiple == undefined) {
			this.gridDeliveryPointMultiple = [];
		}
		if (this.gridDeliveryPointMultiple.length == 0) {
			$("#"+this.pageId+"deliveryPointMultipleTableId").DataTable().destroy();
			this.gridDeliveryPointMultiple.push(this.newAttributeToSetMultipleDeliveryPoint);
			this.dtTriggerDeliveryPointMultiple.next();
		} else {
			const result = this.gridDeliveryPointMultiple.filter(multiInvoiceNoData =>
				multiInvoiceNoData.invoiceNumber === this.newAttributeToSetMultipleDeliveryPoint.invoiceNumber);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Invoice Number " + this.newAttributeToSetMultipleDeliveryPoint.invoiceNumber + " Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#"+this.pageId+"deliveryPointMultipleTableId").DataTable().destroy();
				this.gridDeliveryPointMultiple.push(this.newAttributeToSetMultipleDeliveryPoint);
				this.dtTriggerDeliveryPointMultiple.next();
			}
		}
		this.newAttributeToSetMultipleDeliveryPoint = {};
	}

	deleteRowSelectedMultipleDeliveryPt(gridDeliveryPointData, index) {
		this.gridDeliveryPointMultiple.splice(index, 1);
		$("#"+this.pageId+"deliveryPointMultipleSearch").val('');
	}

	radioButtonListnerDeliveryPoint(event, selectedCheckBoxData, index) {
		/*console.log(selectedCheckBoxData);
		console.log(event.target.value);
		console.log(this.firstPtDelivPopup);
		this.firstPtDelivPopup = null;
		console.log(this.firstPtDelivPopup);
		console.log(event);
		if (selectedCheckBoxData.deliveryPoints == true) {
			alert("HH");
			console.log(selectedCheckBoxData.deliveryarea);
			this.firstPtDelivPopup = selectedCheckBoxData.deliveryarea;
		}
		console.log(this.firstPtDelivPopup);*/
	}


	onOkClickBtn() {
		if (this.gridDeliveryPointMultiple == null ||
			this.gridDeliveryPointMultiple == undefined ||
			this.gridDeliveryPointMultiple.length == 0) {
			swal("Warning", "Please add atleast one Invoice to proceed.", "warning");
			return false;
		} else {
			this.hireDtoList = [];
			this.listOfInvoice = [];
			this.firstPtDelivPopup = null;
			for (let i = 0; i < this.gridDeliveryPointMultiple.length; i++) {
				if (this.gridDeliveryPointMultiple[i].deliveryPoints == true) {
					this.newAttributeToSetMultipleDeliveryPoint.deliveryPoints = true;
					this.firstPtDelivPopup = this.gridDeliveryPointMultiple[i].deliveryarea
				}
				this.listOfInvoice.push(this.gridDeliveryPointMultiple[i].invoiceNumber);
			}
			this.hireDto = new HireSlipDto();
			this.hireDto.listOfInvoice = this.listOfInvoice;
			this.hireDto.firstPntDelv = this.firstPtDelivPopup;
		}

		var number_of_checked_checkbox = $("input[name=radioBtnMultiplePointId]:checked").length;

		localStorage.clear();
		localStorage.setItem('DeliveryPointClose', JSON.stringify(this.hireDto));
		if (number_of_checked_checkbox < 1) {
			swal("Alert", "Please select atleast one check box to proceed", "warning");
			return false;
		} else if (number_of_checked_checkbox > 1) {
			swal("Alert", "You have selected multiple check, so please select one check box to proceed", "warning");
			return false;
		} else {
			this.dialogRef.close("OK");
		}
		this.popUpOpenMultipleDeliveryPointData = null;
		$("#"+this.pageId+"deliveryPointMultipleSearch").val('');
		$("#"+this.pageId+"deliveryPointMultipleFormat").val('');
		this.modelMultipleDeliveryPointDestination = null;
		this.clearField();
	}



	onCancelClick() {
		if (this.gridDeliveryPointMultiple == null ||
			this.gridDeliveryPointMultiple == undefined ||
			this.gridDeliveryPointMultiple.length == 0) {
			this.hireDto = new HireSlipDto();
			this.gridDeliveryPointMultiple = [];
		} else {
			this.hireDto = new HireSlipDto();
			this.hireDtoList = [];
			this.listOfInvoice = [];
			this.firstPtDelivPopup = null;
			for (let i = 0; i < this.gridDeliveryPointMultiple.length; i++) {
				if (this.gridDeliveryPointMultiple[i].deliveryPoints == true) {
					this.newAttributeToSetMultipleDeliveryPoint.deliveryPoints = true;
					this.firstPtDelivPopup = this.gridDeliveryPointMultiple[i].deliveryarea
				}
				this.listOfInvoice.push(this.gridDeliveryPointMultiple[i].invoiceNumber);
			}
			this.hireDto = new HireSlipDto();
			this.hireDto.listOfInvoice = this.listOfInvoice;
			this.hireDto.firstPntDelv = this.firstPtDelivPopup;
		}

		var number_of_checked_checkbox = $("input[name=radioBtnMultiplePointId]:checked").length;

		localStorage.clear();
		localStorage.setItem('DeliveryPointClose', JSON.stringify(this.hireDto));
		if (number_of_checked_checkbox < 1) {
			swal("Alert", "Please select atleast one check box to proceed", "warning");
			return false;
		} else if (number_of_checked_checkbox > 1) {
			swal("Alert", "You have selected multiple check, so please select one check box to proceed", "warning");
			return false;
		} else {
			this.dialogRef.close("OK");
		}
		this.popUpOpenMultipleDeliveryPointData = null;
		$("#"+this.pageId+"deliveryPointMultipleSearch").val('');
		$("#"+this.pageId+"deliveryPointMultipleFormat").val('');
		this.modelMultipleDeliveryPointDestination = null;
		this.clearField();
	}

	clearFieldBtn() {
		$("#"+this.pageId+"deliveryPointMultipleSearch").val('');
		$("#"+this.pageId+"deliveryPointMultipleFormat").val('');
		this.modelMultipleDeliveryPointDestination = null;
	}

	clearField() {
		this.userDataDtoMultipleDeliveryPointStationCode = new UserDataDto();
		this.hireSlipDtoForDeliveryPointMultiple = new HireSlipDto();
		this.userDataDtoReturnMultipleDeliveryPointStationCode = null;
		this.multiDeliveryPointDestination = null;
		this.searchFormatForMultipleDeliveryPoint = null;
		this.multipleDeliveryPointFormat = null;
		this.multipleDeliveryPointSearch = null;

		this.newAttributeToSetMultipleDeliveryPoint = {};
		this.firstPtDelivPopup = null;

		this.editMultipleDeliveryPointTableDetailsId = null;
		this.hireslipLocalStorageDeliveryPoint = null;
	}




}

