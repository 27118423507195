import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { MasterService } from "src/app/dataService/master-service";
import { OurBranchesDto } from 'src/app/dto/OurBranches-dto';


@Component( {
    selector: 'app-our-branches',
    templateUrl: './our-branches.component.html',
    styleUrls: ['./our-branches.component.css']
} )
export class OurBranchesComponent implements OnInit {

	ourBranchesDataList: any;
    dtTriggerOurBranches: Subject<any> = new Subject();
    dtOptionsOurBranches: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    ourBranchesDtoUserValue: OurBranchesDto = new OurBranchesDto();
    
    selectedId = 0;
    enteredBranchName : any;
    enteredAddress :any;
    enteredArea :any;
    enteredCity : any;
    
    enteredPincode : any;
    enteredContactPerson :any;
    enteredContNo1 :any;
    enteredContNo2 : any;
    
    enteredFax : any;
    enteredEmailId :any;
    enteredLatitude :any;
    enteredLongitude : any;
    selectedBranchType:any;
    selectedStationType:any;
	pageId="ourbc";

    constructor(private router: Router,private masterService : MasterService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
            	this.getDetails();
            }, 1000);
        }

    }

    ngOnInit(): void {
        this.dtOptionsOurBranches = {
                        dom: 'Bfrtip',
            buttons: [
                {
                 extend: 'excel',
                 text: '<i class="fas fa-file-excel"> Excel</i>',
                 titleAttr: 'Excel',
                 exportOptions: {
                 columns: ':visible'
                  }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.ourBranchesDtoUserValue = new OurBranchesDto();
		this.masterService.getOurBranchDetails(this.ourBranchesDtoUserValue).subscribe(
			(response) => {
				$("#"+this.pageId+"ourBranchesTableId").DataTable().destroy();
				this.ourBranchesDataList = [];
				if (response.length == 0) {
					swal({
						title: "Our Branches Detail",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.ourBranchesDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerOurBranches.next();
	            }, 1000);
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

	confirmSave(){
		swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes, it will immediately publish to mobile app?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.saveDetails();
            }
        })
	}
	
	saveDetails = () => {
		this.showSpinnerForAction = true;
		this.ourBranchesDtoUserValue = new OurBranchesDto();
		
		this.enteredBranchName = $("#"+this.pageId+"branchName").val();
		this.enteredAddress = $("#"+this.pageId+"address").val();
		this.enteredArea = $("#"+this.pageId+"area").val();
		this.enteredCity = $("#"+this.pageId+"city").val();
		this.enteredPincode = $("#"+this.pageId+"pincode").val();
		this.enteredContactPerson = $("#"+this.pageId+"contactPerson").val();
		this.enteredContNo1 = $("#"+this.pageId+"contNo1").val();
		this.enteredContNo2 = $("#"+this.pageId+"contNo2").val();
		this.enteredFax = $("#"+this.pageId+"fax").val();
		this.enteredEmailId = $("#"+this.pageId+"emailId").val();
		this.enteredLatitude = $("#"+this.pageId+"latitude").val();
		this.enteredLongitude = $("#"+this.pageId+"longitude").val();
		this.selectedBranchType = $("#"+this.pageId+"branchType").val();
		
		if(this.selectedBranchType == "Sub Station"){
			this.ourBranchesDtoUserValue.stationType = 0;
		}else{
			this.ourBranchesDtoUserValue.stationType = 1;
		}
		
		this.selectedStationType = 1;
		this.ourBranchesDtoUserValue.branchId = this.selectedId;
		this.ourBranchesDtoUserValue.branchName = this.enteredBranchName;
		this.ourBranchesDtoUserValue.address = this.enteredAddress;
		this.ourBranchesDtoUserValue.area = this.enteredArea;
		this.ourBranchesDtoUserValue.city = this.enteredCity;
		this.ourBranchesDtoUserValue.pincode = this.enteredPincode;
		this.ourBranchesDtoUserValue.contactPerson = this.enteredContactPerson;
		this.ourBranchesDtoUserValue.contactNumber1 = this.enteredContNo1;
		this.ourBranchesDtoUserValue.contactNumber2 = this.enteredContNo2;
		this.ourBranchesDtoUserValue.fax = this.enteredFax;
		this.ourBranchesDtoUserValue.email = this.enteredEmailId;
		this.ourBranchesDtoUserValue.latitude = this.enteredLatitude;
		this.ourBranchesDtoUserValue.longitude = this.enteredLongitude;
		this.ourBranchesDtoUserValue.bookingOffice = this.selectedStationType;
		
		
		this.masterService.addOurBranchDetails(this.ourBranchesDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					swal("Success", "Our Branches details saved/updated successfully!", "info");
					this.clearFields();
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to save/update the Our Branches details!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while saving the details", "info");
			}, () => console.log('done');
	};
	
	rowSelectedEdit(ourBranchesData){
		this.selectedId = ourBranchesData.branchId;
		$("#"+this.pageId+"branchName").val(ourBranchesData.branchName);
		$("#"+this.pageId+"address").val(ourBranchesData.address);
		$("#"+this.pageId+"area").val(ourBranchesData.area);
		$("#"+this.pageId+"city").val(ourBranchesData.city);
		$("#"+this.pageId+"pincode").val(ourBranchesData.pincode);
		$("#"+this.pageId+"contactPerson").val(ourBranchesData.contactPerson);
		$("#"+this.pageId+"contNo1").val(ourBranchesData.contactNumber1);
		$("#"+this.pageId+"contNo2").val(ourBranchesData.contactNumber2);
		$("#"+this.pageId+"fax").val(ourBranchesData.fax);
		$("#"+this.pageId+"emailId").val(ourBranchesData.email);
		$("#"+this.pageId+"latitude").val(ourBranchesData.latitude);
		$("#"+this.pageId+"longitude").val(ourBranchesData.longitude);
		
		if(ourBranchesData.stationType == 0){
			$("#"+this.pageId+"branchType").val("Sub Station");
		}else{
			$("#"+this.pageId+"branchType").val("Main Station");
		}
	}
	
	confirmDelete(ourBranchesData){
		swal({
            title: "Confirmation",
            text: "Sure you want to delete the selected Our Branch details, it will immediately effect to mobile app?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
            	this.deleteOurBranch(ourBranchesData);
            }
        })
	}
	
	deleteOurBranch(ourBranchesData){
		this.showSpinnerForAction = true;
		this.selectedId = ourBranchesData.branchId;
		this.ourBranchesDtoUserValue = new OurBranchesDto();
		this.ourBranchesDtoUserValue.branchId = this.selectedId;
		
		this.masterService.deleteOurBranchDetails(this.ourBranchesDtoUserValue).subscribe(
			(response) => {
				if (response.status == "Success") {
					this.selectedId = 0;
					swal("Success", "Selected Our Branch details deleted successfully!", "info");
					this.clearFields();
					this.getDetails();
				} else if (response.status == "Failed") {
					swal("Failed", "Failed to delete the Our Branch details!", "info");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while deleting the details", "info");
			}, () => console.log('done');
	}
	
	clearFields(){
		this.selectedId = 0;
		$("#"+this.pageId+"branchName").val("");
		$("#"+this.pageId+"address").val("");
		$("#"+this.pageId+"area").val("");
		$("#"+this.pageId+"city").val("");
		$("#"+this.pageId+"pincode").val("");
		$("#"+this.pageId+"contactPerson").val("");
		$("#"+this.pageId+"contNo1").val("");
		$("#"+this.pageId+"contNo2").val("");
		$("#"+this.pageId+"fax").val("");
		$("#"+this.pageId+"emailId").val("");
		$("#"+this.pageId+"latitude").val("");
		$("#"+this.pageId+"longitude").val("");
		$("#"+this.pageId+"branchType").val("");
		
		this.enteredBranchName = null;
		this.enteredAddress = null;
		this.enteredArea = null;
		this.enteredCity = null;
		this.enteredPincode = null;
		this.enteredContactPerson = null;
		this.enteredContNo1 = null;
		this.enteredContNo2 = null;
		this.enteredFax = null;
		this.enteredEmailId = null;
		this.enteredLatitude = null;
		this.enteredLongitude = null;
		this.selectedBranchType = null;
		this.selectedStationType = 1;
	}

    ngOnDestroy(): void {
        this.dtTriggerOurBranches.unsubscribe();
    }
    
    ngAfterViewInit(): void {
        this.dtTriggerOurBranches.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}