<div *ngIf="showPrintForOtherBooking">
    <div *ngIf="consigneeCopyShow" id="{{pageId}}firstPrint" style='font-family: Arial; font-size: 12px; margin-top: 0px;vertical-align: top;' width='100%' height='100%'>

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='height: 25px;'>
                        <tr>
                            <td align='left' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong>ISO
                                        9001 : 2008</strong></span></td>
                            <td align='center' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong><u>GOODS
                                            CONSIGNMENT NOTE</u></strong></span></td>
                            <!-- 						<td align='right' width='35%' valign='top' -->
                            <!-- 							style='vertical-align: top'><img -->
                            <!-- 							src='http://127.0.0.1:8443/srdlogistics/img/barcode/failed' -->
                            <!-- 							' width='210px' height='20px' /></td> -->
                            <td *ngFor="let PS of PrintBarcode" valign='top' align='right' width='30%' style='vertical-align: top;margin-top: -15px;'>
                                <!-- <ngx-barcode style='margin-top: -15px;' [bc-width]="2.7" [bc-height]="20"
									[bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
								</ngx-barcode> -->
                                <ngx-barcode6 style='margin-top: -15px;' [bc-format]="'CODE128'" [bc-width]="2.7" [bc-height]="20" [bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
                                </ngx-barcode6>
                            </td>
                        </tr>
                    </table>
                    <table width='60%' cellpadding='0' cellspacing='0' style='height: 50px;'>
                        <tr>
                            <td width='30%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <strong><span style='font-size: 13px;' [innerHTML]="mainAddress"></span> </strong>
                                </div>
                            </td>
                            <td width='4%'>&nbsp;</td>
                            <td width='26%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <span style='font-size: 13px;'> <strong>Tel.No.<span [innerHTML]="mainMobileNo">
                                            </span><br /> <span [innerHTML]="mainEmailId"></span><br />CIN: U63090MH2009PTC189328
                                    </strong>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!--	<br /> -->
                    <table width='100%' cellpadding='0' cellspacing='0' style='margin-bottom: 4px;'>
                        <tr>
                            <td width='25%' style='height: 20px; font-size: 21px;'>
                                <strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}<br />DATE
                                    : &nbsp;{{resultDtos.date}}
                                </strong>
                            </td>
                            <td align='center' width='40%' style='font-size: 21px;'><strong>{{sourcePrint}}
                                    TO {{destinationPrint}}<br /> <span style='font-size: 13px;'>
                                        <table width='100%' cellpadding='0' cellspacing='0'>
                                            <tr>
                                                <td align='center' width='100%' style='font-size: 12px; height: 20px;'>
                                                    <strong>{{eDecCom}}{{eDecComDot}}&nbsp;{{selfLr}}&nbsp;{{printMode}}</strong>
                            </td>
                        </tr>
                    </table>
                    </span>
                    </strong>
                </td>
                <td width='35%'></td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <!-- 					<tr> -->
            <!-- 						<td width='50%' rowspan='3' style='vertical-align: top;'><table -->
            <!-- 								cellpadding='0' cellspacing='0' style='width: 100%;'> -->
            <tr>
                <td width='70%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='29%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNOR</strong>
                            </td>
                            <td width='1%' align='left' valign='top' height='28px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td width='71%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consignorName }}<br />
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 10%; height: 27px; text-align: center; border-right: none; border-bottom: none; font-size: 12px;'>
                </td>
                <td style='width: 10%; height: 27px; border-right: none; border-bottom: none;'></td>
                <td style='text-align: center; width: 10%; height: 27px; border-bottom: none; font-size: 12px;'>
                </td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <tr>
                <td width='50%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' height='250px' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='27px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignor}}<br />Ph.&nbsp;{{resultDtos.consignorMobileNo}}
                                            </span></td>
                        </tr><br />
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignor {{partyTypeConsignor}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignor }}</td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->

                        <tr>
                            <td width='40%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNEE</strong>
                            </td>
                            <td width='2%' valign='top' align='left' height='27px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td *ngIf="viewNormalConsigneeFont" width='58%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                            <td *ngIf="viewSmallConsigneeFont" width='58%' valign='top' style='font-size: 16px; padding-top:7px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->


                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='28px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{cneeMobileNo}}
                                            </span></td>
                        </tr><br />


                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignee {{partyTypeConsignee}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignee }}</td>
                        </tr>
                        <!-- 									<br /> -->
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>No.of Articles</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{resultDtos.totalArticles}}</strong>&nbsp;({{resultDtos.packNature}})
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Said
                                                to Contain</strong></td>
                            <td width='2%' valign='top' align='left' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{resultDtos.commodityName}}{{subCommodity}}</td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>Value of Goods</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>Rs {{resultDtos.goodsValue}}/-
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-way Bill No</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>

                            <td *ngIf="viewSingleEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewTwoEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewMultiEwayBill" width='58%' valign='top' height='23px' style='font-size: 13px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>
                        </tr>
                        <tr *ngIf="viewEwayBillExempted">
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-Way Bill Exempted</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>

                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 20%; height: 30px; text-align: center; border: 1px solid #000; border-right: none; font-size: 16px;' colspan='2'><strong>WEIGHT (In
                                    Kgs.)</strong><br />Actual&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charged
                </td>
                <td style='width: 10%; height: 30px; border: 1px solid #000; border-right: none;'></td>
                <td style='text-align: center; width: 20%; height: 30px; border: 1px solid #000; font-size: 16px;' colspan='2'><strong>FREIGHT (Rs)</strong><br /> To Pay&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paid
                </td>
            </tr>
            <tr>
                <td style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none; align: center;'>
                    <span style='font-size: 14px;'><strong>{{actWgtToDisplay}}
                                    </strong></span>
                </td>
                <td style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none;'>
                    <span style='font-size: 14px;'><strong>
                                        {{chgWgtToDisplay}}</strong></span>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Base Freight
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>
                                Hamali</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Statistical Charges
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Others</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>L.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>B.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>A.O.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>F O V</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>DD</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>COD</td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 10px; text-align: center; width: 10%; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 16px;' rowspan='2'>
                    <table align='center'>

                    </table>
                </td>
                <!-- 									</td> -->
            </tr>
            <tr>
                <td width='20%' valign='top' style='text-align: center; height: 100px; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; font-size: 11px;' colspan='2'>
                    <h6 [innerHTML]="artSize"></h6>
                    <h6>{{artChgWt}}</h6>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='65px' cellpadding='0' cellspacing='0'>
                        <td style="text-align: left; width: 50%; height: 48px; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 1px; padding-left: 2px;" colspan="2">
                            <span style="font-size: 16px;"><strong>Remarks :</strong></span>
                            <span style="font-size: 13px;">
                                            <ng-container *ngIf="remakrsConsigneecopy">
                                                <span style="font-size: 13px;">
                                                    {{remakrsConsigneecopy}}
                                                    <ng-container
                                                        *ngIf="gateCc || unloadByCsn || codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="gateCc">
                                <span style="font-size: 13px;">
                                                    {{gateCc}}
                                                    <ng-container
                                                        *ngIf="unloadByCsn || codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>

                            </ng-container>
                            <ng-container *ngIf="unloadByCsn">
                                <span style="font-size: 13px;">
                                                    {{unloadByCsn}}
                                                    <ng-container *ngIf="codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="codChgMsg">
                                <span style="font-size: 13px;">
                                                    {{codChgMsg}}
                                                    <ng-container *ngIf="handleWitCare || noDiscMsg">,
                                                    </ng-container>

                                                </span>
                            </ng-container>
                            <ng-container *ngIf="handleWitCare">
                                <span style="font-size: 13px;">
                                                    <strong>{{handleWitCare}}</strong>
                                                    <ng-container *ngIf="noDiscMsg">, </ng-container>
                                                </span>
                            </ng-container>


                            <ng-container *ngIf="noDiscMsg">
                                <span style="font-size: 13px;">
                                                    <strong>{{noDiscMsg}}</strong>

                                                </span></ng-container>


                            </span>
                            <span style="font-size: 16px;"><strong>{{remakrsOfferMsg}}</strong></span>
                        </td>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 45px; border: 1px solid #000; border-right: none; font-size: 16px; padding-top: 10px;' colspan='2'><strong>Bill No. / Pvt. Mark<br /></strong>{{resultDtos.billNumber}} {{resultDtos.privateMarker}}
                </td>
                <td style='font-size: 16px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 13px;'>
                    <strong>TOTAL</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{toPayGrandTotal}}</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{paidGrandTotal}}</strong>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='115px' cellpadding='0' cellspacing='0'>
                        <tr>
                            <td style='text-align: center; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 0px; padding-left: 0px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%;'>
                                    <tr>
                                        <td style='border-bottom: 1px solid black; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>CONSIGNEE
                                                                COPY</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='border-top: none; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}</strong></span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <!-- 123 -->
                            <td style='text-align: left; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 1px; padding-left: 2px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%; height: 100%;'>


                                    <tr>
                                        <td style='text-align: left; vertical-align: top; padding-top: 1px; padding-left: 2px;'>
                                            <span style='font-size: 10px;'><strong>{{message}}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='text-align: center;  vertical-align: bottom; padding-top: 1px; padding-left: 2px;color: red;'>
                                            <ng-container *ngIf="newPartyMessage">
                                                <span style="font-size: 21px;">
                                                                <strong>{{newPartyMessage}}</strong>
                                                            </span>
                                            </ng-container>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 35px; border: 1px solid #000; border-right: none; border-top: none; font-size: 12px; padding-top: 10px;' colspan='2' rowspan='2'><strong>Consignee Seal &
                                    Sign on Delivery</strong></td>
                <td style='font-size: 14px; text-align: center; width: 30%; height: 35px; border: 1px solid #000; border-top: none; vertical-align: top; padding-top: 10px;' colspan='3' rowspan='2'><strong>FOR SRD LOGISTICS
                                    Pvt.Ltd.</strong><br /> <strong>Transporter ID</strong><br /> <span>
                                    {{transportId}}</span><br />
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{entryByPrint}}{{clerkName}}
                </td>
            </tr>
        </table>
        </table>
    </div>
    <p *ngIf="pageBreak1" style='page-break-after: always;'></p>
    <div *ngIf="consignorCopyShow" id="{{pageId}}secondPrint" style='font-family: Arial; font-size: 12px; margin-top: 0px;' width='100%' height='100%'>

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='height: 25px'>
                        <tr>
                            <td align='left' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong>ISO
                                        9001 : 2008</strong></span></td>
                            <td align='center' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong><u>GOODS
                                            CONSIGNMENT NOTE</u></strong></span></td>
                            <!-- 						<td align='right' width='35%' valign='top' -->
                            <!-- 							style='vertical-align: top'><img -->
                            <!-- 							src='http://127.0.0.1:8443/srdlogistics/img/barcode/failed' -->
                            <!-- 							' width='210px' height='20px' /></td> -->
                            <td *ngFor="let PS of PrintBarcode" valign='top' align='right' width='30%' style='vertical-align: top;margin-top: -15px;'>
                                <!-- <ngx-barcode style='margin-top: -15px;' [bc-width]="2.7" [bc-height]="20"
									[bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
								</ngx-barcode> -->
                                <ngx-barcode6 style='margin-top: -15px;' [bc-format]="'CODE128'" [bc-width]="2.7" [bc-height]="20" [bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
                                </ngx-barcode6>
                            </td>
                        </tr>
                    </table>
                    <table width='60%' cellpadding='0' cellspacing='0' style='height: 50px;'>
                        <tr>
                            <td width='30%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <strong><span style='font-size: 13px;' [innerHTML]="mainAddress"></span> </strong>
                                </div>
                            </td>
                            <td width='4%'>&nbsp;</td>
                            <td width='26%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <span style='font-size: 13px;'> <strong>Tel.No.<span [innerHTML]="mainMobileNo">
                                            </span><br /> <span [innerHTML]="mainEmailId"></span><br />CIN: U63090MH2009PTC189328
                                    </strong>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!-- <br /> -->
                    <table width='100%' cellpadding='0' cellspacing='0' style='margin-bottom: 8px;'>
                        <tr>
                            <td width='25%' style='height: 20px; font-size: 21px;'>
                                <strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}<br />DATE
                                    : &nbsp;{{resultDtos.date}}
                                </strong>
                            </td>
                            <td align='center' width='40%' style='font-size: 21px;'><strong>{{sourcePrint}}
                                    TO {{destinationPrint}}<br /> <span style='font-size: 13px;'>
                                        <table width='100%' cellpadding='0' cellspacing='0'>
                                            <tr>
                                                <td align='center' width='100%' style='font-size: 12px; height: 20px;'>
                                                    <strong>{{eDecCom}}{{eDecComDot}}&nbsp;{{selfLr}}</strong>
                            </td>
                        </tr>
                    </table>
                    </span>
                    </strong>
                </td>
                <td width='35%'></td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <!-- 					<tr> -->
            <!-- 						<td width='50%' rowspan='3' style='vertical-align: top;'><table -->
            <!-- 								cellpadding='0' cellspacing='0' style='width: 100%;'> -->
            <tr>
                <td width='70%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='29%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNOR</strong>
                            </td>
                            <td width='1%' align='left' valign='top' height='28px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td width='71%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consignorName }}<br />
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 10%; height: 27px; text-align: center; border-right: none; border-bottom: none; font-size: 12px;'>
                </td>
                <td style='width: 10%; height: 27px; border-right: none; border-bottom: none;'></td>
                <td style='text-align: center; width: 10%; height: 27px; border-bottom: none; font-size: 12px;'>
                </td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <tr>
                <td width='50%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' height='250px' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='27px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignor}}<br />Ph.&nbsp;{{resultDtos.consignorMobileNo}}
                                            </span></td>
                        </tr><br />
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignor {{partyTypeConsignor}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignor }}</td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->

                        <tr>
                            <td width='40%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNEE</strong>
                            </td>
                            <td width='2%' valign='top' align='left' height='27px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td *ngIf="viewNormalConsigneeFont" width='58%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                            <td *ngIf="viewSmallConsigneeFont" width='58%' valign='top' style='font-size: 16px; padding-top:7px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->


                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='28px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{cneeMobileNo}}
                                            </span></td>
                        </tr><br />


                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignee {{partyTypeConsignee}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignee }}</td>
                        </tr>
                        <!-- 									<br /> -->
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>No.of Articles</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{resultDtos.totalArticles}}</strong>&nbsp;({{resultDtos.packNature}})
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Said
                                                to Contain</strong></td>
                            <td width='2%' valign='top' align='left' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{resultDtos.commodityName}}{{subCommodity}}</td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>Value of Goods</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>Rs {{resultDtos.goodsValue}}/-
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-way Bill No</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td *ngIf="viewSingleEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewTwoEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewMultiEwayBill" width='58%' valign='top' height='23px' style='font-size: 13px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>
                        </tr>
                        <tr *ngIf="viewEwayBillExempted">
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-Way Bill Exempted</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>

                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 20%; height: 30px; text-align: center; border: 1px solid #000; border-right: none; font-size: 16px;' colspan='2'><strong>WEIGHT (In
                                    Kgs.)</strong><br />Actual&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charged
                </td>
                <td style='width: 10%; height: 30px; border: 1px solid #000; border-right: none;'></td>
                <td style='text-align: center; width: 20%; height: 30px; border: 1px solid #000; font-size: 16px;' colspan='2'><strong>FREIGHT (Rs)</strong><br /> To Pay&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paid
                </td>
            </tr>
            <tr>
                <td *ngIf="showConsignorCopyWord" style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none; align: center;'>
                    <span style='font-size: 14px;'><strong>{{actWgtToDisplay}}
                                    </strong></span>
                </td>
                <td *ngIf="showConsignorCopyWord" style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none;'>
                    <span style='font-size: 14px;'><strong>
                                        {{chgWgtToDisplay}}</strong></span>
                </td>
                <td *ngIf="!showConsignorCopyWord" style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none; align: center;'>
                    <span style='font-size: 14px;'><strong>{{resultDtos.actualWeight }}
                                    </strong></span>
                </td>
                <td *ngIf="!showConsignorCopyWord" style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none;'>
                    <span style='font-size: 14px;'><strong>
                                        {{resultDtos.chargedWeight }}</strong></span>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Base Freight
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>
                                Hamali</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Statistical Charges
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Others</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>L.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>B.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>A.O.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>F O V</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>DD</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>COD</td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 10px; text-align: center; width: 10%; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 16px;' rowspan='2'>
                    <table align='center'>

                    </table>
                </td>
                <!-- 									</td> -->
            </tr>
            <tr>
                <td width='20%' valign='top' style='text-align: center; height: 100px; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; font-size: 11px;' colspan='2'>
                    <h6 [innerHTML]="artSize"></h6>
                    <h6>{{artChgWt}}</h6>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='65px' cellpadding='0' cellspacing='0'>
                        <td style="text-align: left; width: 50%; height: 48px; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 1px; padding-left: 2px;" colspan="2">
                            <span style="font-size: 16px;"><strong>Remarks :</strong></span>
                            <span style="font-size: 13px;">
                                            <ng-container *ngIf="remakrsConsignorcopy">
                                                <span style="font-size: 13px;">
                                                    {{remakrsConsignorcopy}}
                                                    <ng-container
                                                        *ngIf="gateCc || unloadByCsn || codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="gateCc">
                                <span style="font-size: 13px;">
                                                    {{gateCc}}
                                                    <ng-container
                                                        *ngIf="unloadByCsn || codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="unloadByCsn">
                                <span style="font-size: 13px;">
                                                    {{unloadByCsn}}
                                                    <ng-container *ngIf="codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="codChgMsg">
                                <span style="font-size: 13px;">
                                                    {{codChgMsg}}
                                                    <ng-container *ngIf="handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="handleWitCare">
                                <span style="font-size: 13px;">
                                                    <strong>{{handleWitCare}}</strong>
                                                    <ng-container *ngIf="noDiscMsg">, </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="noDiscMsg">
                                <span style="font-size: 13px;">
                                                    <strong>{{noDiscMsg}}</strong>

                                                </span>
                            </ng-container>

                            </span>
                            <span style="font-size: 16px;"><strong>{{remakrsOfferMsg}}</strong></span>
                        </td>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 45px; border: 1px solid #000; border-right: none; font-size: 16px; padding-top: 10px;' colspan='2'><strong>Bill No. / Pvt. Mark<br /></strong>{{resultDtos.billNumber}} {{resultDtos.privateMarker}}
                </td>
                <td style='font-size: 16px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 13px;'>
                    <strong>TOTAL</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{toPayGrandTotal}}</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{paidGrandTotal}}</strong>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='115px' cellpadding='0' cellspacing='0'>
                        <tr>
                            <td style='text-align: center; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 0px; padding-left: 0px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%;'>
                                    <tr>
                                        <td *ngIf="showConsignorCopyWord" style='border-bottom: 1px solid black; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>CONSIGNOR
                                                                COPY</strong></span>
                                        </td>
                                        <td *ngIf="!showConsignorCopyWord" style='border-bottom: 1px solid black; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 17px;'><strong></strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='border-top: none; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}</strong></span>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                            <!-- <td
                                            style='text-align: left; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; vertical-align: top; padding-top: 1px; padding-left: 2px;'>
                                            <span
                                                style='font-size: 10px;'><strong>{{resultDtos.message}}</strong></span>
                                        </td> -->
                            <!-- 123 -->
                            <td style='text-align: left; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 1px; padding-left: 2px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%;height: 100%;'>


                                    <tr>
                                        <td style='text-align: left;vertical-align: top; padding-top: 1px; padding-left: 2px;'>
                                            <span style='font-size: 10px;'><strong>{{resultDtos.message}}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='text-align: center; vertical-align: bottom; padding-top: 1px; padding-left: 2px;color: red;'>
                                            <ng-container *ngIf="newPartyMessage">
                                                <span style="font-size: 21px;color: red;">
                                                                <strong>{{newPartyMessage}}</strong>
                                                            </span>
                                            </ng-container>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 35px; border: 1px solid #000; border-right: none; border-top: none; font-size: 12px; padding-top: 10px;' colspan='2' rowspan='2'><strong>Consignee Seal &
                                    Sign on Delivery</strong></td>
                <td style='font-size: 14px; text-align: center; width: 30%; height: 35px; border: 1px solid #000; border-top: none; vertical-align: top; padding-top: 10px;' colspan='3' rowspan='2'><strong>FOR SRD LOGISTICS
                                    Pvt.Ltd.</strong><br /> <strong>Transporter ID</strong><br /> <span>
                                    {{transportId}}</span><br />
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{entryByPrint}}{{clerkName}}
                </td>
            </tr>
        </table>
        </table>
    </div>
    <p *ngIf="pageBreak2" style='page-break-after: always;'></p>
    <div *ngIf="lorryCopyShow" id="{{pageId}}thirdPrint" style='font-family: Arial; font-size: 12px; margin-top: 0px;' width='100%' height='100%'>

        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' style='height: 25px'>
                        <tr>
                            <td align='left' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong>ISO
                                        9001 : 2008</strong></span></td>
                            <td align='center' width='30%' valign='top' style='vertical-align: top'><span style='font-size: 16px;'><strong><u>GOODS
                                            CONSIGNMENT NOTE</u></strong></span></td>
                            <!-- 						<td align='right' width='35%' valign='top' -->
                            <!-- 							style='vertical-align: top'><img -->
                            <!-- 							src='http://127.0.0.1:8443/srdlogistics/img/barcode/failed' -->
                            <!-- 							' width='210px' height='20px' /></td> -->
                            <td *ngFor="let PS of PrintBarcode" valign='top' align='right' width='30%' style='vertical-align: top;margin-top: -15px;'>
                                <!-- <ngx-barcode style='margin-top: -15px;' [bc-width]="2.7" [bc-height]="20"
									[bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
								</ngx-barcode> -->
                                <ngx-barcode6 style='margin-top: -15px;' [bc-format]="'CODE128'" [bc-width]="2.7" [bc-height]="20" [bc-font-size]="15" [bc-value]="PS.barcode" [bc-display-value]="false">
                                </ngx-barcode6>
                            </td>
                        </tr>
                    </table>
                    <table width='60%' cellpadding='0' cellspacing='0' style='height: 50px;'>
                        <tr>
                            <td width='30%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <strong><span style='font-size: 13px;' [innerHTML]="mainAddress"></span> </strong>
                                </div>
                            </td>
                            <td width='4%'>&nbsp;</td>
                            <td width='26%' valign='top' style='height: 50px;'>
                                <div style='line-height: 13px; text-align: left;'>
                                    <span style='font-size: 13px;'> <strong>Tel.No.<span [innerHTML]="mainMobileNo">
                                            </span><br /> <span [innerHTML]="mainEmailId"></span><br />CIN: U63090MH2009PTC189328
                                    </strong>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!-- <br /> -->
                    <table width='100%' cellpadding='0' cellspacing='0' style='margin-bottom: 8px;'>
                        <tr>
                            <td width='25%' style='height: 20px; font-size: 21px;'>
                                <strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}<br />DATE
                                    : &nbsp;{{resultDtos.date}}
                                </strong>
                            </td>
                            <td align='center' width='40%' style='font-size: 21px;'><strong>{{sourcePrint}}
                                    TO {{destinationPrint}}<br /> <span style='font-size: 13px;'>
                                        <table width='100%' cellpadding='0' cellspacing='0'>
                                            <tr>
                                                <td align='center' width='100%' style='font-size: 12px; height: 20px;'>
                                                    <strong>{{eDecCom}}{{eDecComDot}}&nbsp;{{selfLr}}</strong>
                            </td>
                        </tr>
                    </table>
                    </span>
                    </strong>
                </td>
                <td width='35%'></td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <!-- 					<tr> -->
            <!-- 						<td width='50%' rowspan='3' style='vertical-align: top;'><table -->
            <!-- 								cellpadding='0' cellspacing='0' style='width: 100%;'> -->
            <tr>
                <td width='70%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='29%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNOR</strong>
                            </td>
                            <td width='1%' align='left' valign='top' height='28px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td width='71%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consignorName }}<br />
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 10%; height: 27px; text-align: center; border-right: none; border-bottom: none; font-size: 12px;'>
                </td>
                <td style='width: 10%; height: 27px; border-right: none; border-bottom: none;'></td>
                <td style='text-align: center; width: 10%; height: 27px; border-bottom: none; font-size: 12px;'>
                </td>
            </tr>
        </table>
        <table cellpadding='0' cellspacing='0' style='width: 100%;'>
            <tr>
                <td width='50%' rowspan='3' style='vertical-align: top;'>
                    <table width='100%' height='250px' cellpadding='0' cellspacing='0' border='0'>
                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='27px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignor}}<br />Ph.&nbsp;{{resultDtos.consignorMobileNo}}
                                            </span></td>
                        </tr><br />
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignor {{partyTypeConsignor}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignor }}</td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->

                        <tr>
                            <td width='40%' valign='top' style='font-size: 21px; height: 27px;'>
                                <strong>CONSIGNEE</strong>
                            </td>
                            <td width='2%' valign='top' align='left' height='27px'><strong style='font-size: 21px;'>:&nbsp;</strong></td>
                            <td *ngIf="viewNormalConsigneeFont" width='58%' valign='top' style='font-size: 21px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                            <td *ngIf="viewSmallConsigneeFont" width='58%' valign='top' style='font-size: 16px;  padding-top:7px; height: 27px;'>{{ resultDtos.consigneeName }}<br />
                            </td>
                        </tr>
                        <!--<tr>
										<td width='40%' valign='top'
											style='font-size: 22px; height: 28px;'><strong>CONSIGNEE</strong></td>
										<td width='2%' valign='top' align='left' height='28px'><strong
											style='font-size: 22px;'>:&nbsp;</strong></td>
										<td width='58%' valign='top'
											style='font-size: 22px; height: 28px;'>{{
											resultDtos.consigneeName }}<br /> <span
											style='font-size: 16px;'>{{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{resultDtos.consigneeMobileNo}}
										</span>
										</td>
									</tr> -->


                        <tr>
                            <td width='40%' valign='top' style='font-size: 19px; height: 28px;'></td>
                            <td width='2%' align='left' valign='top' height='28px'><strong style='font-size: 19px;'></strong></td>
                            <td width='58%' valign='top' style='font-size: 16px;line-height:15px;'><span style='font-size: 16px;'>
                                                {{resultDtos.addressConsignee}}<br />Ph.&nbsp;{{cneeMobileNo}}
                                            </span></td>
                        </tr><br />


                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Consignee {{partyTypeConsignee}} No.</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{ resultDtos.gstNoConsignee }}</td>
                        </tr>
                        <!-- 									<br /> -->
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>No.of Articles</strong></td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{resultDtos.totalArticles}}</strong>&nbsp;({{resultDtos.packNature}})
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px'><strong style='font-size: 16px;'>Said
                                                to Contain</strong></td>
                            <td width='2%' valign='top' align='left' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{resultDtos.commodityName}}{{subCommodity}}</td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>Value of Goods</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>Rs {{resultDtos.goodsValue}}/-
                            </td>
                        </tr>
                        <tr>
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-way Bill No</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td *ngIf="viewSingleEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewTwoEwayBill" width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>

                            <td *ngIf="viewMultiEwayBill" width='58%' valign='top' height='23px' style='font-size: 13px;'>
                                <strong>{{newMultiEwayBillNo}}</strong>
                            </td>
                        </tr>

                        <tr *ngIf="viewEwayBillValidDate">
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-Way Bill Validity</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'><strong style='font-size: 16px;'>:&nbsp;</strong></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>
                                {{resultDtos.ewayBillValidUptoStr}}
                            </td>
                        </tr>
                        <tr *ngIf="viewEwayBillExempted">
                            <td width='40%' valign='top' height='23px' style='font-size: 16px;'>
                                <strong>E-Way Bill Exempted</strong>
                            </td>
                            <td width='2%' align='left' valign='top' height='23px'></td>
                            <td width='58%' valign='top' height='23px' style='font-size: 16px;'>

                            </td>
                        </tr>
                    </table>
                </td>
                <td style='width: 20%; height: 30px; text-align: center; border: 1px solid #000; border-right: none; font-size: 16px;' colspan='2'><strong>WEIGHT (In
                                    Kgs.)</strong><br />Actual&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charged
                </td>
                <td style='width: 10%; height: 30px; border: 1px solid #000; border-right: none;'></td>
                <td style='text-align: center; width: 20%; height: 30px; border: 1px solid #000; font-size: 16px;' colspan='2'><strong>FREIGHT (Rs)</strong><br /> To Pay&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paid
                </td>
            </tr>
            <tr>
                <td style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none; align: center;'>
                    <span style='font-size: 14px;'><strong>{{resultDtos.actualWeight }}
                                    </strong></span>
                </td>
                <td style='text-align: center; width: 10%; padding-top: 4px; height: 25px; border: 1px solid #000; border-top: none; border-right: none;'>
                    <span style='font-size: 14px;'><strong>
                                        {{resultDtos.chargedWeight }}</strong></span>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Base Freight
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>
                                Hamali</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Statistical Charges
                            </td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>Others</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>L.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>B.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>A.O.C</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>F O V</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>DD</td>
                        </tr>
                        <tr>
                            <td style='font-size: 14px; text-align: center; line-height: 26px;'>COD</td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewToPay" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 14px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-top: none; border-bottom: none; vertical-align: top; line-height: 26px;' rowspan='2'>
                    <table align='center'>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.frieghtAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.hamali}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 51px;'>
                                {{resultDtos.statisticalCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.others}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.lcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.bcChg}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.aoc}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.riskCharge}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.ddAmt}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="ViewPaid" style='font-size: 14px; text-align: center; line-height: 26px;'>
                                {{resultDtos.codChg }}
                            </td>
                        </tr>
                    </table>
                </td>
                <td style='font-size: 10px; text-align: center; width: 10%; border-top: none; border-right: none; border-bottom: none; vertical-align: top; line-height: 16px;' rowspan='2'>
                    <table align='center'>

                    </table>
                </td>
                <!-- 									</td> -->
            </tr>
            <tr>
                <td width='20%' valign='top' style='text-align: center; height: 100px; border: 1px solid #000; border-top: none; border-right: none; border-bottom: none; font-size: 11px;' colspan='2'>
                    <h6 [innerHTML]="artSize"></h6>
                    <h6>{{artChgWt}}</h6>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='65px' cellpadding='0' cellspacing='0'>
                        <td style="text-align: left; width: 50%; height: 48px; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 1px; padding-left: 2px;" colspan="2">
                            <span style="font-size: 16px;"><strong>Remarks :</strong></span>
                            <span style="font-size: 13px;">
                                            <ng-container *ngIf="remakrsLorrycopy">
                                                <span style="font-size: 13px;">
                                                    {{remakrsLorrycopy}}
                                                    <ng-container
                                                        *ngIf="gateCc || unloadByCsn || codChgMsg || handleWitCare || noDiscMsg ">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="gateCc">
                                <span style="font-size: 13px;">
                                                    {{gateCc}}
                                                    <ng-container
                                                        *ngIf="unloadByCsn || codChgMsg || handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="unloadByCsn">
                                <span style="font-size: 13px;">
                                                    {{unloadByCsn}}
                                                    <ng-container *ngIf="codChgMsg || handleWitCare || noDiscMsg ">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="codChgMsg">
                                <span style="font-size: 13px;">
                                                    {{codChgMsg}}
                                                    <ng-container *ngIf="handleWitCare || noDiscMsg">,
                                                    </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="handleWitCare">
                                <span style="font-size: 13px;">
                                                    <strong>{{handleWitCare}}</strong>
                                                    <ng-container *ngIf="noDiscMsg">, </ng-container>
                                                </span>
                            </ng-container>
                            <ng-container *ngIf="noDiscMsg">
                                <span style="font-size: 13px;">
                                                    <strong>{{noDiscMsg}}</strong>

                                                </span>
                            </ng-container>


                            </span>
                            <span style="font-size: 16px;"><strong>{{remakrsOfferMsg}}</strong></span>
                        </td>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 45px; border: 1px solid #000; border-right: none; font-size: 16px; padding-top: 10px;' colspan='2'><strong>Bill No. / Pvt. Mark<br /></strong>{{resultDtos.billNumber}} {{resultDtos.privateMarker}}
                </td>
                <td style='font-size: 16px; text-align: center; font-weight: bold; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top; padding-top: 13px;'>
                    <strong>TOTAL</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; border-right: none; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{toPayGrandTotal}}</strong>
                </td>
                <td style='font-size: 17px; text-align: center; width: 10%; border: 1px solid #000; vertical-align: top;  padding-top: 15px;'>
                    <strong>{{paidGrandTotal}}</strong>
                </td>
            </tr>
            <tr>
                <td width='50%' style='vertical-align: top;'>
                    <table width='100%' height='115px' cellpadding='0' cellspacing='0'>
                        <tr>
                            <td style='text-align: center; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 0px; padding-left: 0px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%;'>
                                    <tr>
                                        <td style='border-bottom: 1px solid black; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>LORRY
                                                                COPY</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='border-top: none; text-align: center; padding-top: 12px; padding-bottom: 12px;'>
                                            <span style='font-size: 21px;'><strong>G.C.No.&nbsp;{{resultDtos.lrNumber}}</strong></span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <!-- <td
                                            style='text-align: left; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; vertical-align: top; padding-top: 1px; padding-left: 2px;'>
                                            <span
                                                style='font-size: 10px;'><strong>{{resultDtos.message}}</strong></span>
                                        </td> -->
                            <td style='text-align: left; width: 25%; height: 35px; border: 1px solid #000; border-right: none; border-top: none; padding-top: 1px; padding-left: 2px;'>
                                <table cellpadding='0' cellspacing='0' style='width: 100%;height: 100%;'>


                                    <tr>
                                        <td style='text-align: left;  vertical-align: top; padding-top: 1px; padding-left: 2px;'>
                                            <span style='font-size: 10px;'><strong>{{resultDtos.message}}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='text-align: center;   vertical-align: bottom; padding-top: 1px; padding-left: 2px;color: red;'>
                                            <ng-container *ngIf="newPartyMessage">
                                                <span style="font-size: 21px;">
                                                                <strong>{{newPartyMessage}}</strong>
                                                            </span>
                                            </ng-container>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
                <td width='20%' valign='top' style='text-align: center; height: 35px; border: 1px solid #000; border-right: none; border-top: none; font-size: 12px; padding-top: 10px;' colspan='2' rowspan='2'><strong>Consignee Seal &
                                    Sign on Delivery</strong></td>
                <td style='font-size: 14px; text-align: center; width: 30%; height: 35px; border: 1px solid #000; border-top: none; vertical-align: top; padding-top: 10px;' colspan='3' rowspan='2'><strong>FOR SRD LOGISTICS
                                    Pvt.Ltd.</strong><br /> <strong>Transporter ID</strong><br /> <span>
                                    {{transportId}}</span><br />
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{entryByPrint}}{{clerkName}}
                </td>
            </tr>
        </table>
        </table>
    </div>
</div>