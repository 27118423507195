<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showDisConDetailsRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Discontinuous Parties
						Details Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">

											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
													</div>
													<input #source id="{{pageId}}source" name="source" type="text"
														class="form-control" placeholder="Select Source"
														[(ngModel)]="modelForMainSrc"
														[ngbTypeahead]="searchMainSrcAt"
														[ngModelOptions]="{standalone: true}"
														[resultFormatter]="formatterMainSrc"
														[inputFormatter]="formatterMainSrc"
														(focus)="focusMainSrcTA$.next($any($event).target.value)"
														[ngModelOptions]="{standalone: true}" />
												</div>
											</div>
										</div>
											<div *ngIf="viewDestination" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																[ngbTypeahead]="searchDestination"
																(selectItem)="consigneeDestListener($event)"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Periods(Days):</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-calendar"></i>
															</span>
														</div>
														<input type="number" id="{{pageId}}periodDay" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>

											<!-- 											the first autocomplete starts -->
											<div class="col-sm-12 col-md-12">

												<div class="form-group">
													<div class="input-group">
														<label>Consignee Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input #consigneeName id="{{pageId}}consigneeName"
															name="consigneeName" type="text" class="form-control"
															placeholder="Select Consignee Name"
															[(ngModel)]="modelConsigneeName"
															[ngbTypeahead]="consigneeNameSearchTA"
															(selectItem)="rowSelectedConsignee($event)"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterConsigneeName"
															[inputFormatter]="formatterConsigneeName"
															(focus)="focusConsigneeNameTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>

											</div>
											<!-- 											the first autocomplete ends -->





										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn"(click)="searchMethod();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearMethod()">Clear</button>
						</div>
					</div>


					<div class="col-md-9 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8"></div>
										</div>
										
										<table width=100%>
											<td width=80%>
												<h6 class="card-title">Discontinue Parties Details
													Report</h6>
											</td>
											<td width=20%>

												<button type="submit" class="dt-button" style="margin-left: 60%;"
												(click)="customPrintDisConDetailsRpt()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
											</td>

										</table>

										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}discontinuousDetailsReportId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsDiscontinuousDetailsReport"
														[dtTrigger]="dtTriggerDiscontinuousDetailsReport">

														<thead>
															<tr>
																<th>Consignee Name</th>
																<th>Address</th>
																<th>Mobile No.</th>
																<th>GST No</th>
																<th>Contact Person</th>
																<th>Book Date</th>
																<th>Source</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let discontinuousDetailsReportData of discontinuousDetailsReportDataList ">
																<td>{{ discontinuousDetailsReportData.consigneeName }}</td>
																<td>{{ discontinuousDetailsReportData.address }}</td>
																<td>{{ discontinuousDetailsReportData.mobileNum }}</td>
																<td>{{ discontinuousDetailsReportData.gstNoConsignee }}</td>
																<td>{{ discontinuousDetailsReportData.contactPerson
																	}}</td>
																<td>{{ discontinuousDetailsReportData.bookingDateStr  }}</td>
																<td>{{ discontinuousDetailsReportData.source }}</td>
															</tr>

														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																
															</tr>
														</tfoot>
													</table>

												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>

					</div>`

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>