<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">
            <div class="card" style="border: 1px solid darkcyan !important">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px">
                    <h6 class="card-title text-white">Exemption Criteria</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
															<option selected value="commodityWise">Commodity
																Wise</option>
															<option value="consignorWise">Consignor Wise</option>
															<option value="consigneeWise">Consignee Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showSource" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <label>Source</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="ti-home"></i>
																</span>
                                                            </div>
                                                            <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="challanAgentName" (selectItem)="clickListnerForSource($event)" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                                #instanceAgentName="ngbTypeahead" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showConsignorName" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorIndexSearchView id="{{pageId}}consignorIndexSearchView" name="consignorIndexSearchView" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorSearchViewDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchViewTypeTA"
                                                            (focus)="focusConsignorIndexSearchViewTA$.next($any($event).target.value)" #instanceConsignorIndexSearchView="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showConsignorName" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="clickListnerForConsignor($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div *ngIf="showDest" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>

                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showCosigneeName" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Consignee Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input #consigneeNameId id="{{pageId}}consigneeNameId" type="text" class="form-control" (selectItem)="clickListnerForConsigneeNameList($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="searchConsigneeName" [resultFormatter]="formatterConsigneeName"
                                                                [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Consignee Name.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showCommodity" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                            [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showFov" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>FOV(%)</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}fovId" #FOV name="fov" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showEffectiveFromDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Effective From Date</label> <input id="{{pageId}}fromDates" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" [(ngModel)]="fromDatesModal"
                                                            autocomplete="off">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px" />
                        <div class="col-md-12" style="text-align: center">
                            <button type="submit" (click)="confirmSave();" class="btn btn-success m-r-10" id="{{pageId}}saveBtn">Save</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">
								Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="col-md-12">
                                    <h6 class="card-title border_bottom">Commodity Wise Exemption Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}fovExemptionCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsFovExmpCommodity" [dtTrigger]="dtTriggerFovExmpCommodity">
                                            <thead>
                                                <tr>
                                                    <th>Commodity Name</th>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>FOV</th>
                                                    <th>Effective From Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let fovExmpCommodityData of fovExmpCommodityDataList">
                                                    <td>{{ fovExmpCommodityData.commodityName }}</td>
                                                    <td>{{ fovExmpCommodityData.source }}</td>
                                                    <td>{{ fovExmpCommodityData.destination }}</td>
                                                    <td>{{ fovExmpCommodityData.fovPerc }}</td>
                                                    <td>{{ fovExmpCommodityData.effectiveFromDateStg }}</td>
                                                    <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(fovExmpCommodityData);">
															<i title="View" class="fas fa-edit"></i>
														</button>
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(fovExmpCommodityData)">
															<i title="Remove" class="fas fa-trash"></i>
														</button></td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr>
                                <div class="col-md-12">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-group">
                                                    <h6 class="card-title border_bottom">{{selectedRateView}}</h6>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <select class="custom-select col-12" id="{{pageId}}searchByRate" name="searchByRate" #searchByRate (change)="searchByModeRate(searchByRate.value)">
															<option value="consignorRate">Consignor Rate</option>
															<option value="consigneeRate">Consignee Rate</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="box-body">

                                            <table datatable id="{{pageId}}fovExemptionConsignorTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionFovExmpConsignor" [dtTrigger]="dtTriggerFovExmpConsignor">
                                                <thead>
                                                    <tr>
                                                        <th>Consignor Name</th>
                                                        <th>Commodity Name</th>
                                                        <th>Source</th>
                                                        <th>Destination</th>
                                                        <th>Consignee Name</th>
                                                        <th>FOV</th>
                                                        <th>Effective From Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let fovExmpConsignorData of fovExmpConsignorDataList">
                                                        <td>{{ fovExmpConsignorData.consignorName }}</td>
                                                        <td>{{ fovExmpConsignorData.commodityName }}</td>
                                                        <td>{{ fovExmpConsignorData.source }}</td>
                                                        <td>{{ fovExmpConsignorData.destination }}</td>
                                                        <td>{{ fovExmpConsignorData.consigneeName }}</td>
                                                        <td>{{ fovExmpConsignorData.fovPerc }}</td>
                                                        <td>{{ fovExmpConsignorData.effectiveFromDateStg }}</td>
                                                        <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(fovExmpConsignorData);">
																<i title="View" class="fas fa-edit"></i>
															</button>
                                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(fovExmpConsignorData)">
																<i title="Remove" class="fas fa-trash"></i>
															</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>