<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">
		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">User Features Customization</h6>
			</div>
			<div class="row system_responsive">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Station</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-warehouse"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}station" class="form-control"
															[(ngModel)]="station" [ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Station"
															(selectItem)="getUserCreationDetails($event)" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Station Type</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-warehouse"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}stationType"
														aria-describedby="basic-addon11" readonly>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>User Id</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<!-- <input #role name="userId" id="{{pageId}}userId" type="text"
															class="form-control" [(ngModel)]="modelUserId"
															placeholder="Select User Id..."
															[ngbTypeahead]="stationSearchTA"
															(focus)="focusStationTA$.next($any($event).target.value)"
															#instanceIndex="ngbTypeahead" /> -->

														<input type="text" id="{{pageId}}userId" class="form-control"
															[(ngModel)]="modelUserId" [ngbTypeahead]="stationSearchTA"
															[resultFormatter]="formatterUserId"
															[inputFormatter]="formatterUserId"
															(focus)="focusStationTA$.next($any($event).target.value)"
															placeholder="Select User Id..."
															(selectItem)="stateSearchViewDropDownListner($event)"
															(change)="getAppModuleDetails()"/> 
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>User Role</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="mdi mdi-tie"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}userRole"
														aria-describedby="basic-addon11" readonly>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-9 vl p-t-10">
					<div class="box-body">
						<table datatable id="{{pageId}}userFeaturesCustomizationTableId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsUserFeaturesCustomization"
							[dtTrigger]="dtTriggerUserFeaturesCustomization">
							<thead>
								<tr>
									<th>Action</th>
									<th>Module</th>
									<th>Screen Name</th>
									<th>Sub Module1</th>
									<th>Sub Module2</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let appModuleData of appModuleList ">
									<td>
										<input type="checkbox"
											[(ngModel)]="appModuleData.isSelected" id='selectedLr'
											(change)="checkBoxForAppModuleDataClicked()" />
									</td>
									<td>{{ appModuleData.module }}</td>
									<td>{{ appModuleData.screenName }}</td>
									<td>{{ appModuleData.subModule1 }}</td>
									<td>{{ appModuleData.subModule2 }}</td>
								</tr>
							</tbody>
						</table>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateAppUserDetails()">Save</button>
							<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearBtn()" >Clear</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>