//CHG_VER01_Changed tostation and mainstation condition to lowercase because if in different case means it had been blocked By Imran on 15/07/2024

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import *  as moment from 'moment';
import { MasterService } from 'src/app/dataService/master-service';

@Component({
    selector: 'app-hireslip-balance',
    templateUrl: './hireslip-balance.component.html',
    styleUrls: ['./hireslip-balance.component.css']
})
export class HireslipBalanceComponent implements OnInit {

    viewByChequeNumber = false;
    viewByChequeDate = false;
    viewByConsigneeBankName = false;
    viewByReferenceNumber = false;
    model: NgbDateStruct;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerHireslipBalance: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsHireslipBalance: any;
    pageId = "hbc";
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    srcSupAdmin: boolean = false;
    srcBkgAdmin: boolean = false;

    viewConfirmPaymentBtn = false;
    viewPrintBtn = false;
    viewAlreadyReward = false;


    hireSlipDtoScanningCode: HireSlipDto = new HireSlipDto();
    hireSlipDtoScanningCodeReturn: HireSlipDto = new HireSlipDto();
    hireSlipDtoForTimeDiff: HireSlipDto = new HireSlipDto();
    hireSlipDtoForPrintAndConfirmPayment: HireSlipDto = new HireSlipDto();
    hireSlipDtoPrintVoucher: HireSlipDto = new HireSlipDto();

    hireSlipDtoForPrint: HireSlipDto = new HireSlipDto();
    hireSlipDtoForConfirmPayment: HireSlipDto = new HireSlipDto();

    isAllowed: boolean = false;
    listOfStation: Array<String> = [];
    totDed: any = null;
    balanceAmt: any = null;

    enteredScanCodeValue: any = null;
    enteredHireslipNumberValue: any = null;

    invoiceDatesModal: any = null;
    departureDatesModal: any = null;
    scheduledDatesModal: any = null;
    arrivalDatesModal: any = null;
    public chequeDatesModal: any;
    public chequeNumber: any;
    public ngModalRefferenceNumber: any;

    validateLessLateArrival: any = null;
    validatelessUnloadHamali: any = null;
    validatelessMunishiana: any = null;
    validateHireAmt: any = null;
    validateAdvanceAmt: any = null;
    validateDeductionAmt: any = null;

    enteredHireSlipNumber: any = null;
    enteredDepartureDate: any = null;
    enteredDepartureTime: any = null;
    enteredArrivalDate: any = null;
    enteredArrivalTime: any = null;

    enteredScanCodeNumberPrint: any = null;
    enteredHireSlipNumberPrint: any = null;
    enteredForInvoiceDatePrint: any = null;
    enteredForHireAmountPrint: any = null;
    enteredForBalanceAmountPrint: any = null;
    enteredForAdvanceAmountPrint: any = null;
    enteredForPayableAtPrint: any = null;
    enteredForVehicleNumberPrint: any = null;
    enteredForSupplierNamePrint: any = null;
    enteredForBrokerNamePrint: any = null;
    enteredForDriverNamePrint: any = null;
    enteredForDeductionAmountPrint: any = null;
    enteredForLessLateArrivalPrint: any = null;
    enteredForLessMunishianaPrint: any = null;
    enteredForLessunloadhamaliPrint: any = null;
    enteredForBankNamePrint: any = null;
    enteredForChequeNumberPrint: any = null;
    enteredForRefferenceNumberPrint: any = null;
    enteredForChequeDatePrint: any = null;
    enteredForLessAmtDeductionPrint: any = null;
    enteredForArrivalDatePrint: any = null;
    enteredForArrivalTimePrint: any = null;
    enteredForTimeTakenToReachedPrint: any = null;

    isReadonlyHireslipNo = false;
    isReadonlyInvoiceDate = false;
    isReadonlySource = false;
    isReadonlyDestination = false;
    isReadonlyVehicleNumber = false;
    isReadonlySupplierName = false;
    isReadonlyBrokerName = false;
    isReadonlyDriverName = false;
    isReadonlyMobileNumber = false;
    isReadonlyLicenseNumber = false;
    isReadonlyDepartureDate = false;
    isReadonlyDepartureTime = false;
    isReadonlyScheduledDate = false;
    isReadonlyScheduledTime = false;
    isReadonlyArrivalDate = false;
    isReadonlyArrivalTime = false;
    isReadonlyTimeTakenToReached = false;
    isReadonlyHireAmount = false;
    isReadonlyAdvanceAmount = false;
    isReadonlyDeductionAmount = false;
    isReadonlyBalanceAmount = false;
    isReadonlyPayableAt = false;

    isReadonlyLessLateArrival = false;
    isReadonlylessUnloadHamali = false;
    isReadonlyLessMunishiana = false;
    //isReadonlyPaymentMode = false;
    isReadonlyChequeNumber = false;
    isReadonlyChequeDates = false;
    isReadonlyBankName = false;
    isReadonlyRefference = false;

    modalRefferenceHireslipBalancePopUp: NgbModalRef;
    modalRefferenceHireslipBalanceClosePopUp: string;
    @ViewChild('hireslipBalancePopUpTemplate') private hireslipBalancePopUpTemplate;

    selectedPanDetails: File;
    validateVehicleNoForUpload: any = null;
    isUploaded: boolean = false;
    hireSlipDtoPanFileUpload: HireSlipDto = new HireSlipDto();

    viewNewBankNameField = false;

    bankNameOptions: UserDataDto[];
    public modelBankName: any;
    userDataDto: UserDataDto = new UserDataDto();
    userDataDtoAddNewBank: UserDataDto = new UserDataDto();
    bankNameTA: Array<UserDataDto> = [];
    public setBankName: any;
    focusBankNameTA$ = new Subject<string>();
    bankNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    viewVoucher = false;

    rightsForShowOnlyPrintToSelectRole = "Rights To Show Only Print For Selected Role";
    /*rightsForDeliveryManager = "Rights To Show Delivery Manager";
    rightsForBillingManager = "Rights To Show Billing Manager";
    rightsForTransshipmentManager = "Rights To Show Transshipment Manager";
    rightsForAdministrator = "Rights To Show Administrator";
    rightsForBookingAdministrator = "Rights To Show Booking Administrator";
    rightsForBookingManager = "Rights To Show Booking Manager";*/

    consigneeBankNameRetrive: any;
    showHireColumns: Boolean = false;

    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, private modalService: NgbModal,
        private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef) {

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BalPayment Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }

            this.getBankDetailsList();
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsHireslipBalance = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
        }
    }
    ngAfterViewInit() {
        this.dtTriggerHireslipBalance.next();
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerHireslipBalance.unsubscribe();
        //this.onDestroyUnsubscribtionHireslipBalance.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }


    paymentModeMode(paymentMode: string) {
        if (paymentMode === 'cash') {
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;

            this.modelBankName = null;
            //$("#"+this.pageId+"newBankNameId").val('');
        } else if (paymentMode === 'cheque') {
            this.viewByChequeNumber = true;
            this.viewByChequeDate = true;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = false;

            this.modelBankName = null;
            //$("#"+this.pageId+"newBankNameId").val('');
        }
        else if (paymentMode === 'neft') {
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = true;

            this.modelBankName = null;
            //$("#"+this.pageId+"newBankNameId").val('');
        } else {
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;

            this.modelBankName = null;
            //$("#"+this.pageId+"newBankNameId").val('');
        }
    }
    //for hide and show ends

    getScanCodeFieldsDetails(event, hireslipBalancePopUpTemplate) {

        if (event.charCode == 13) {
            this.scanningProcess(hireslipBalancePopUpTemplate);
        }
    }

    scanningProcess(hireslipBalancePopUpTemplate) {
        this.resetReadOnlyFields();
        this.clearFields();
        this.hireSlipDtoScanningCode = new HireSlipDto();

        this.enteredScanCodeValue = $("#" + this.pageId + "scanCode").val();
        this.enteredHireslipNumberValue = $("#" + this.pageId + "hireslipNumber").val();

        this.hireSlipDtoScanningCode.hireslipnumber = this.enteredHireslipNumberValue;
        this.hireSlipDtoScanningCode.barcodeValue = this.enteredScanCodeValue;
        this.hireSlipDtoScanningCode.mode = "hirebal";
        this.hireSlipDtoScanningCode.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoScanningCode.companyid = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.hireslipService.getHireSlipDetailsForRewarding(this.hireSlipDtoScanningCode).subscribe(
            (response) => {
                this.hireSlipDtoScanningCodeReturn = new HireSlipDto();
                this.hireSlipDtoScanningCodeReturn = response;
                //console.log(response);
                //console.log(this.hireSlipDtoScanningCodeReturn);
                $("#" + this.pageId + "hireslipNumber").val(response.hireslipnumber);

                if (response.size != null && response.size == 0) {
                    swal("Result Not Found", "No Result Found", "warning");
                } else {
                    if (response.status == "Scheduled" || response.status == "Transit") {
                        swal("Not Allowed", "" + $("#" + this.pageId + "hireslipNumber").val() + " is in " + response.status + " , payment can be done only after taken into stock.", "warning");
                        this.clearFields();
                        this.commonClearFieldFalse();
                    } else if (((!this.chkSourceValidation(response.fromstation))) &&
                        //CHG_VER01
                        ((!(response.branch.toLowerCase() == response.tostation.toLowerCase())) &&
                            (!(this.userDataDtoReturnSession.office.toLowerCase() == response.tostation.toLowerCase())))) {
                        swal("Not Allowed", "You Are Not Allowed to see the " + response.tostation + " Informations", "");
                        this.clearFields();
                        this.commonClearFieldFalse();
                    } else {
                        this.clearFields();
                        var deductionAmout = 0;
                        $("#" + this.pageId + "deduction").val(0.0);
                        this.setValues(response);
                        if (response.invoiceType != null && response.invoiceType == "Main") {
                            swal({
                                title: "Pan Document Is Not Uploaded",
                                text: "Do U Want to Upload ",
                                icon: "info",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                                buttons: ["No", "Yes"],
                            }).then((yesBtn) => {
                                if (yesBtn) {
                                    this.fileUploadPanPopupOpenLink(hireslipBalancePopUpTemplate);
                                }
                            });
                        }
                        this.getShortExtraDetails();
                        this.getReachedDuration();
                        if (response.isRewarded != null && response.isRewarded &&
                            response.isLryBalancePaid != null && (!(response.isLryBalancePaid))) {
                            this.showFieldAlreadyRewardAndConfirmPayment();
                            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('Already Rewarded');
                            //console.log("1");
                        } else if (response.isLryBalancePaid != null && response.isLryBalancePaid &&
                            response.isRewarded != null && (!(response.isRewarded))) {
                            this.showFieldAlreadyRewardAndPrint();
                            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('HireSlip Balance Amount Already Paid');
                            this.setReadOnlyFields();
                            //console.log("2");
                        } else if (response.isRewarded != null && response.isRewarded &&
                            response.isLryBalancePaid != null && response.isLryBalancePaid) {
                            this.showFieldAlreadyRewardAndPrint();
                            $("#" + this.pageId + "isAlreadyRewardedHeadingId").text('HireSlip Balance And Reward Amount Already Paid');
                            this.setReadOnlyFields();
                            this.getReachedDuration();
                            //console.log("3");
                        } else {
                            this.viewAlreadyReward = false;
                            this.viewConfirmPaymentBtn = true;
                            this.viewPrintBtn = false;
                            //console.log("4");
                        }
                        /*if (!(this.rightsForDeliveryManager == "Rights To Show Delivery Manager") &&
                            (!(this.rightsForBillingManager == "Rights To Show Billing Manager")) &&
                            (!(this.rightsForTransshipmentManager == "Rights To Show Transshipment Manager")) &&
                            (!(this.rightsForAdministrator == "Rights To Show Administrator")) &&
                            (!(this.rightsForBookingAdministrator == "Rights To Show Booking Administrator")) &&
                            (!(this.rightsForBookingManager == "Rights To Show Booking Manager"))) {
                            */
                        /* 
                        if (!(this.rightsForShowOnlyPrintToSelectRole == "Rights To Show Only Print For Selected Role")) {
                         */
                        if (!(this.userDataDtoReturnSession.role == "Delivery Manager") &&
                            (!(this.userDataDtoReturnSession.role == "Billing Manager")) &&
                            (!(this.userDataDtoReturnSession.role == "Transshipment Manager")) &&
                            (!(this.userDataDtoReturnSession.role == "Administrator")) &&
                            (!(this.userDataDtoReturnSession.role == "Booking Administrator")) &&
                            (!(this.userDataDtoReturnSession.role == "Booking Manager"))) {

                            //this.viewAlreadyReward = false;
                            this.viewConfirmPaymentBtn = false;
                            this.viewPrintBtn = true;
                            this.setReadOnlyFields();
                        }
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  HireSlipDetailsForRewarding", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }
    clearFields() {
        $("#" + this.pageId + "hireslipNumber").val('');
        $("#" + this.pageId + "invoiceDates").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "vehicleNumber").val('');
        $("#" + this.pageId + "supplierName").val('');
        $("#" + this.pageId + "brokerName").val('');
        $("#" + this.pageId + "driverName").val('');
        $("#" + this.pageId + "mobileNumber").val('');
        $("#" + this.pageId + "licenseNumber").val('');
        $("#" + this.pageId + "departureDates").val('');
        $("#" + this.pageId + "departureTime").val('');
        $("#" + this.pageId + "scheduledDates").val('');
        $("#" + this.pageId + "scheduledTime").val('');
        $("#" + this.pageId + "arrivalDates").val('');
        $("#" + this.pageId + "arrivalTime").val('');
        $("#" + this.pageId + "timeTakenToReached").val('');
        $("#" + this.pageId + "hireAmount").val('');
        $("#" + this.pageId + "advance").val('');
        $("#" + this.pageId + "deduction").val('');
        $("#" + this.pageId + "balance").val('');
        $("#" + this.pageId + "payableAt").val('');

        $("#" + this.pageId + "lessLateArrival").val('');
        $("#" + this.pageId + "lessUnloadHamali").val('');
        $("#" + this.pageId + "lessMunishiana").val('');

        this.modelBankName = null;
        $("#" + this.pageId + "bankName").val('');
        //$("#"+this.pageId+"newBankNameId").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "chequeDates").val('');
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "paymentMode").val('select payment');
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;


        this.invoiceDatesModal = null;
        this.departureDatesModal = null;
        this.scheduledDatesModal = null;
        this.arrivalDatesModal = null;
        this.chequeDatesModal = '';
        this.ngModalRefferenceNumber = '';

        this.isReadonlyHireslipNo = false;
        this.isReadonlyInvoiceDate = false;
        this.isReadonlySource = false;
        this.isReadonlyDestination = false;
        this.isReadonlyVehicleNumber = false;
        this.isReadonlySupplierName = false;
        this.isReadonlyBrokerName = false;
        this.isReadonlyDriverName = false;
        this.isReadonlyMobileNumber = false;
        this.isReadonlyLicenseNumber = false;
        this.isReadonlyDepartureDate = false;
        this.isReadonlyDepartureTime = false;
        this.isReadonlyScheduledDate = false;
        this.isReadonlyScheduledTime = false;
        this.isReadonlyArrivalDate = false;
        this.isReadonlyArrivalTime = false;
        this.isReadonlyTimeTakenToReached = false;
        this.isReadonlyHireAmount = false;
        this.isReadonlyAdvanceAmount = false;
        this.isReadonlyDeductionAmount = false;
        this.isReadonlyBalanceAmount = false;
        this.isReadonlyPayableAt = false;

        this.isReadonlyLessLateArrival = false;
        this.isReadonlylessUnloadHamali = false;
        this.isReadonlyLessMunishiana = false;
        //isReadonlyPaymentMode = false;
        this.isReadonlyChequeNumber = false;
        this.isReadonlyChequeDates = false;
        this.isReadonlyBankName = false;
        this.isReadonlyRefference = false;


        //this.viewNewBankNameField = false;

        this.enteredScanCodeNumberPrint = null;
        this.enteredHireSlipNumberPrint = null;
        this.enteredForInvoiceDatePrint = null;
        this.enteredForHireAmountPrint = null;
        this.enteredForBalanceAmountPrint = null;
        this.enteredForAdvanceAmountPrint = null;
        this.enteredForPayableAtPrint = null;
        this.enteredForVehicleNumberPrint = null;
        this.enteredForSupplierNamePrint = null;
        this.enteredForBrokerNamePrint = null;
        this.enteredForDriverNamePrint = null;
        this.enteredForDeductionAmountPrint = null;
        this.enteredForLessLateArrivalPrint = null;
        this.enteredForLessMunishianaPrint = null;
        this.enteredForLessunloadhamaliPrint = null;
        this.enteredForBankNamePrint = null;
        this.enteredForChequeNumberPrint = null;
        this.enteredForRefferenceNumberPrint = null;
        this.enteredForChequeDatePrint = null;
        this.enteredForLessAmtDeductionPrint = null;
        this.enteredForArrivalDatePrint = null;
        this.enteredForArrivalTimePrint = null;
        this.enteredForTimeTakenToReachedPrint = null;
    }
    resetReadOnlyFields() {
        this.isReadonlyBankName = false;
        this.isReadonlyChequeNumber = false;
        this.isReadonlyRefference = false;
        this.isReadonlyChequeDates = false;
        this.isReadonlyLessLateArrival = false;
        this.isReadonlylessUnloadHamali = false;
        this.isReadonlyLessMunishiana = false;
        //isReadonlyPaymentMode = false; 
    }
    setReadOnlyFields() {
        this.isReadonlyBankName = true;
        this.isReadonlyChequeNumber = true;
        this.isReadonlyRefference = true;
        this.isReadonlyChequeDates = false;
        this.isReadonlyLessLateArrival = true;
        this.isReadonlylessUnloadHamali = true;
        this.isReadonlyLessMunishiana = true;
    }


    commonClearFieldFalse() {
        this.viewAlreadyReward = false;
        this.viewConfirmPaymentBtn = false;
        this.viewPrintBtn = false;
        $("#" + this.pageId + "scanCode").val('');
        $("#" + this.pageId + "scanCode").focus();
    }
    showFieldAlreadyRewardAndConfirmPayment() {
        this.viewAlreadyReward = true;
        this.viewConfirmPaymentBtn = true;
        this.viewPrintBtn = false;
    }
    showFieldAlreadyRewardAndPrint() {
        this.viewAlreadyReward = true;
        this.viewConfirmPaymentBtn = false;
        this.viewPrintBtn = true;
    }

    chkSourceValidation(source) {
        this.isAllowed = false;
        this.listOfStation = [];
        this.listOfStation = this.userDataDtoReturnSession.stationList;

        if (this.listOfStation.length == 0) {
            this.listOfStation = this.userDataDtoReturnSession.mainStation;
        }

        for (let i = 0; i < this.listOfStation.length; i++) {
            if (source == this.listOfStation[i]) {
                this.isAllowed = true;
                break;
            }
        }
        console.log(this.isAllowed);
        return this.isAllowed;
    }

    setValues(responseHireslipDto) {
        console.log(responseHireslipDto);
        $("#" + this.pageId + "hireslipNumber").val(responseHireslipDto.hireslipnumber);
        $("#" + this.pageId + "invoiceDates").val(responseHireslipDto.invoicedate == null ? null : moment(responseHireslipDto.invoicedate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "source").val(responseHireslipDto.fromstation);
        $("#" + this.pageId + "destination").val(responseHireslipDto.tostation);
        $("#" + this.pageId + "vehicleNumber").val(responseHireslipDto.vehicleNumber);
        $("#" + this.pageId + "supplierName").val(responseHireslipDto.vehiCompName);
        $("#" + this.pageId + "brokerName").val(responseHireslipDto.suppliername);
        $("#" + this.pageId + "driverName").val(responseHireslipDto.drivername);
        $("#" + this.pageId + "mobileNumber").val(responseHireslipDto.driverMobileNumber);
        $("#" + this.pageId + "licenseNumber").val(responseHireslipDto.driverlicencenumber);
        $("#" + this.pageId + "departureDates").val(responseHireslipDto.departuredate == null ? null : moment(responseHireslipDto.departuredate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "departureTime").val(responseHireslipDto.departureTime);
        $("#" + this.pageId + "scheduledDates").val(responseHireslipDto.scheduleddate == null ? null : moment(responseHireslipDto.scheduleddate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "scheduledTime").val(responseHireslipDto.scheduledTime);
        $("#" + this.pageId + "arrivalDates").val(responseHireslipDto.arrivaldate == null ? null : moment(responseHireslipDto.arrivaldate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "arrivalTime").val(responseHireslipDto.arrivalTime);
        $("#" + this.pageId + "timeTakenToReached").val(responseHireslipDto.column3);
        $("#" + this.pageId + "hireAmount").val(responseHireslipDto.totalhire);
        $("#" + this.pageId + "advance").val(responseHireslipDto.advance);
        if (responseHireslipDto.isLryBalancePaid != null && (!responseHireslipDto.isLryBalancePaid)) {
            this.calDedAmt();
            $("#" + this.pageId + "deduction").val(responseHireslipDto.lesslatearrival == null ? 0.0 : responseHireslipDto.lesslatearrival == undefined ? 0.0 : responseHireslipDto.lesslatearrival);
        } else {
            $("#" + this.pageId + "deduction").val(responseHireslipDto.deductionAmt == null ? 0.0 : responseHireslipDto.deductionAmt == undefined ? 0.0 : responseHireslipDto.deductionAmt);
        }
        $("#" + this.pageId + "balance").val(responseHireslipDto.balance);
        $("#" + this.pageId + "payableAt").val(responseHireslipDto.payableat);

        $("#" + this.pageId + "lessLateArrival").val(responseHireslipDto.lesslatearrival == null ? 0.0 : responseHireslipDto.lesslatearrival == undefined ? 0.0 : responseHireslipDto.lesslatearrival);
        $("#" + this.pageId + "lessUnloadHamali").val(responseHireslipDto.lessunloadhamali == null ? 0.0 : responseHireslipDto.lessunloadhamali == undefined ? 0.0 : responseHireslipDto.lessunloadhamali)
        $("#" + this.pageId + "lessMunishiana").val(responseHireslipDto.lessmunishiana == null ? 0.0 : responseHireslipDto.lessmunishiana == undefined ? 0.0 : responseHireslipDto.lessmunishiana)

        if (responseHireslipDto.balPaymentType != null &&
            responseHireslipDto.balPaymentType == "Cash") {
            $("#" + this.pageId + "paymentMode").val('cash');
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
        } else if (responseHireslipDto.balPaymentType != null &&
            responseHireslipDto.balPaymentType == "Cheque") {

            this.viewByChequeNumber = true;
            this.viewByChequeDate = true;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = false;
            this.setBankName = responseHireslipDto.bankname;

            $("#" + this.pageId + "paymentMode").val('cheque');
            this.modelBankName = responseHireslipDto.bankname;
            this.chequeNumber = responseHireslipDto.chequeno;
            this.chequeDatesModal = (responseHireslipDto.chequedate == null ? null : moment(responseHireslipDto.chequedate).format("YYYY-MM-DD"));
            //responseHireslipDto.chequedate;
            console.log(this.modelBankName + "-" + this.chequeNumber + "-" + this.chequeDatesModal);
            $("#" + this.pageId + "bankName").val(this.modelBankName);
            // $( "#bankName" ).val( responseHireslipDto.bankname );
            $("#" + this.pageId + "chequeNumber").val(this.chequeNumber);
            $("#" + this.pageId + "chequeDates").val(this.chequeDatesModal);
            //$("#"+this.pageId+"#bankName" ).val( this.setBankName);
        } else if (responseHireslipDto.balPaymentType != null &&
            responseHireslipDto.balPaymentType == "Neft") {
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = true;
            $("#" + this.pageId + "paymentMode").val('neft');
            $("#" + this.pageId + "bankName").val(responseHireslipDto.bankname);
            //$("#"+this.pageId+"referenceNumber").val(responseHireslipDto.chequeno);
            this.ngModalRefferenceNumber = responseHireslipDto.chequeno;
            $("#" + this.pageId + "chequeDates").val(responseHireslipDto.chequedate);
        } else {
            $("#" + this.pageId + "paymentMode").val('select payment');
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
        }
        this.isReadonlyHireslipNo = true;
        this.isReadonlyInvoiceDate = true;
        this.isReadonlySource = true;
        this.isReadonlyDestination = true;
        this.isReadonlyVehicleNumber = true;
        this.isReadonlySupplierName = true;
        this.isReadonlyBrokerName = true;
        this.isReadonlyDriverName = true;
        this.isReadonlyMobileNumber = true;
        this.isReadonlyLicenseNumber = true;
        this.isReadonlyDepartureDate = true;
        this.isReadonlyDepartureTime = true;
        this.isReadonlyScheduledDate = true;
        this.isReadonlyScheduledTime = true;
        this.isReadonlyArrivalDate = true;
        this.isReadonlyArrivalTime = true;
        this.isReadonlyTimeTakenToReached = true;
        this.isReadonlyHireAmount = true;
        this.isReadonlyAdvanceAmount = true;
        this.isReadonlyDeductionAmount = true;
        this.isReadonlyBalanceAmount = true;
        this.isReadonlyPayableAt = true;
    }
    lessLateArrivalFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "lessUnloadHamali").focus();
            this.calDedAmt();
        }
    }
    lessLateArrivalBlur(e) {
        this.calDedAmt();
    }

    lessUnloadHamaliFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "lessMunishiana").focus();
            this.calDedAmt();
        }
    }
    lessUnloadHamaliBlur(e) {
        this.calDedAmt();
    }

    lessMunishianaFocus(e) {
        if (e.keyCode == 13) {
            this.calDedAmt();
        }
    }
    lessMunishianaBlur(e) {
        this.calDedAmt();
    }

    calDedAmt() {
        this.totDed = 0.0;
        this.validateLessLateArrival = ($("#" + this.pageId + "lessLateArrival").val() == null ? 0.0 : $("#" + this.pageId + "lessLateArrival").val());
        this.validatelessUnloadHamali = ($("#" + this.pageId + "lessUnloadHamali").val() == null ? 0.0 : $("#" + this.pageId + "lessUnloadHamali").val());
        this.validatelessMunishiana = ($("#" + this.pageId + "lessMunishiana").val() == null ? 0.0 : $("#" + this.pageId + "lessMunishiana").val());
        this.totDed = +this.validateLessLateArrival + +this.validatelessUnloadHamali + +this.validatelessMunishiana;

        $("#" + this.pageId + "deduction").val(this.totDed);
        //console.log(this.totDed);
        this.balanceAmt = 0.0;
        this.validateHireAmt = ($("#" + this.pageId + "hireAmount").val() == null ? 0.0 : $("#" + this.pageId + "hireAmount").val());
        this.validateAdvanceAmt = ($("#" + this.pageId + "advance").val() == null ? 0.0 : $("#" + this.pageId + "advance").val());
        this.validateDeductionAmt = ($("#" + this.pageId + "deduction").val() == null ? 0.0 : $("#" + this.pageId + "deduction").val());

        this.balanceAmt = ((+(this.validateHireAmt) + -(this.validateAdvanceAmt) + -(this.validateDeductionAmt)));
        $("#" + this.pageId + "balance").val(this.balanceAmt);
    }

    getReachedDuration() {
        this.hireSlipDtoForTimeDiff = new HireSlipDto();
        this.enteredDepartureDate = $("#" + this.pageId + "departureDates").val();
        this.enteredDepartureTime = $("#" + this.pageId + "departureTime").val();
        this.enteredArrivalDate = $("#" + this.pageId + "arrivalDates").val();
        this.enteredArrivalTime = $("#" + this.pageId + "arrivalTime").val();
        this.enteredHireSlipNumber = $("#" + this.pageId + "hireslipNumber").val();

        this.hireSlipDtoForTimeDiff.departuredate = this.enteredDepartureDate;
        this.hireSlipDtoForTimeDiff.departureTime = this.enteredDepartureTime;
        this.hireSlipDtoForTimeDiff.arrivaldate = this.enteredArrivalDate;
        this.hireSlipDtoForTimeDiff.arrivalTime = this.enteredArrivalTime;
        this.hireSlipDtoForTimeDiff.hireslipnumber = this.enteredHireSlipNumber;


        this.hireSlipDtoForTimeDiff.excludeSunday = false;
        this.hireSlipDtoForTimeDiff.bufferedTimeInHours = this.hireSlipDtoScanningCodeReturn.bufferedTimeInHours;
        this.hireSlipDtoForTimeDiff.column1 = this.hireSlipDtoScanningCodeReturn.column1;
        this.hireSlipDtoForTimeDiff.companyid = this.userDataDtoReturnSession.companyId;
        console.log(this.hireSlipDtoForTimeDiff);
        this.hireslipService.getTimeDifference(this.hireSlipDtoForTimeDiff).subscribe(
            (response) => {
                //console.log(response);
                $("#" + this.pageId + "timeTakenToReached").val(response.reachedDuration);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Server Error", "Problem occur while getting  TimeDifference", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    getShortExtraDetails() {

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    fileUploadPanPopupOpenLink(hireslipBalancePopUpTemplate) {
        this.modalRefferenceHireslipBalancePopUp = this.modalService.open(hireslipBalancePopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceHireslipBalancePopUp.result.then((result) => {
            this.modalRefferenceHireslipBalanceClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceHireslipBalanceClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    popupUploadClickBtn() {
        this.uploadPanBtnPopUp();
    }

    popupCancelClickBtn() {
        this.modalRefferenceHireslipBalancePopUp.close();
    }

    onFileChangedPan(event) {
        this.selectedPanDetails = event.target.files[0];
    }

    uploadPanBtnPopUp() {
        if ($("#" + this.pageId + "panFileUpload").val() != null &&
            $("#" + this.pageId + "panFileUpload").val() != undefined &&
            $("#" + this.pageId + "panFileUpload").val() != "") {
            if ((this.selectedPanDetails.type != null) &&
                (this.selectedPanDetails.type == "image/jpeg" ||
                    this.selectedPanDetails.type == "image/jpg" ||
                    this.selectedPanDetails.type == "image/png")) {

                var splitTypePan = this.selectedPanDetails.type.split("/");
                const commonListPan = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypePan[1] + "&&" + $("#" + this.pageId + "vehicleNumber").val() + "";
                // const commonListPan = "vehicledriverdocs&&documents&&ASRAR&&" + this.validateVehicleNoForUpload + "&&" + splitTypePan[1] + "";
                const formData = new FormData();
                formData.append(commonListPan, commonListPan);
                formData.append('myfile', this.selectedPanDetails, this.selectedPanDetails.name);
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploaded = true;
                                this.setPanDoc();
                            } else {
                                swal("ERROR", "Correct the Pan File and Upload Again", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal({
                        title: "Error",
                        text: "Server Error While Uploading a Pan file",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            }
        } else {
            swal("Mandatory Field", "Only jpg & png image format are allowed to upload for Pan Document File", "warning");
        }
    }

    setPanDoc() {
        if (this.isUploaded && $("#" + this.pageId + "vehicleNumber").val() != null) {
            this.hireSlipDtoPanFileUpload = new HireSlipDto();
            this.validateVehicleNoForUpload = $("#" + this.pageId + "vehicleNumber").val();

            this.hireSlipDtoPanFileUpload.vehicleNumber = this.validateVehicleNoForUpload;
            if ($("#" + this.pageId + "panFileUpload").val() != null && $("#" + this.pageId + "panFileUpload").val() != "") {
                const constImgUrlPan = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#" + this.pageId + "vehicleNumber").val() + "_" + this.selectedPanDetails.name + "";
                this.hireSlipDtoPanFileUpload.imgURLPan = constImgUrlPan;
            }

            this.masterService.setPanTruckMasterDtls(this.hireSlipDtoPanFileUpload).
                subscribe((response) => {
                    if (response.status == "success") {
                        swal("Success", "Pan Details Saved/Updated Successfully", "success");
                        this.isUploaded = false;
                        this.modalRefferenceHireslipBalancePopUp.close();
                    } else {
                        swal("Error", "Error While Saving Pan Details", "warning");
                        return false;
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    swal("Error", "Server Error While Saving Pan Details", "warning");
                },
                () => console.log('done');
        }
    }
    confirmPaymentClickBtn() {
        swal({
            title: "Confirm Payment",
            text: "Sure U Want to confirm the Payment",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.setHireSlipDetailsForRewarding();
            }
        });
    }

    setHireSlipDetailsForRewarding() {
        this.hireSlipDtoForConfirmPayment = new HireSlipDto();
        this.hireSlipDtoForConfirmPayment = this.getFieldValues();
        console.log(this.hireSlipDtoForConfirmPayment);
        this.showSpinnerForAction = true;
        this.hireslipService.updateDriverRewardingHireBalance(this.hireSlipDtoForConfirmPayment).subscribe(
            (response) => {
                //console.log(response);
                swal({
                    title: "Print Voucher",
                    text: "Do U Want to Print the HireSilpBalance Payment Voucher for " + $("#" + this.pageId + "hireslipNumber").val() + "",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((yesBtn) => {
                    this.clearFields();
                    $("#" + this.pageId + "hireslipNumber").val('');
                    $("#" + this.pageId + "scanCode").val('');
                    //clearPopupFields();
                    this.viewConfirmPaymentBtn = false;
                    this.viewPrintBtn = false;
                    if (yesBtn) {
                        localStorage.clear();
                        localStorage.setItem('lorryHireBalanceVoucherPrint', JSON.stringify(this.hireSlipDtoForConfirmPayment));
                        this.viewVoucher = true;
                        window.addEventListener('afterprint', (onclick) => {
                            if (this.viewVoucher) {
                                this.viewVoucher = false;
                                this.hireSlipDtoForConfirmPayment = new HireSlipDto();
                                localStorage.clear();
                                this.viewPrintBtn = false;
                                this.clearFields();
                                this.commonClearFieldFalse();
                            }
                        });
                    }
                });
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  getHireSlipDetailsForRewarding", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }
    printClickBtn() {
        swal({
            title: "Print Voucher",
            text: "Do U Want to Print the HireSilpBalance Payment Voucher for " + $("#" + this.pageId + "hireslipNumber").val() + "",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.hireSlipDtoForPrint = new HireSlipDto();
                this.hireSlipDtoForPrint = this.getFieldValues();
                //console.log("Cofirm");
                localStorage.clear();
                localStorage.setItem('lorryHireBalanceVoucherPrint', JSON.stringify(this.hireSlipDtoForPrint));
                this.viewVoucher = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewVoucher) {
                        this.viewVoucher = false;
                        this.hireSlipDtoForPrint = new HireSlipDto();
                        localStorage.clear();
                        this.viewPrintBtn = false;
                        this.clearFields();
                        this.commonClearFieldFalse();
                    }
                });
            } /*else {
                this.clearFields();
                $("#"+this.pageId+"hireslipNumber").val('');
                $("#"+this.pageId+"scanCode").val('');
                this.viewConfirmPaymentBtn = false;
                this.viewPrintBtn = false;
                this.viewAlreadyReward = false;
            }*/
        });
    }

    getFieldValues() {
        this.hireSlipDtoForPrintAndConfirmPayment = new HireSlipDto();

        this.enteredScanCodeNumberPrint = $("#" + this.pageId + "scanCode").val();
        this.enteredHireSlipNumberPrint = $("#" + this.pageId + "hireslipNumber").val();
        this.enteredForInvoiceDatePrint = $("#" + this.pageId + "invoiceDates").val();
        this.enteredForHireAmountPrint = $("#" + this.pageId + "hireAmount").val();
        this.enteredForBalanceAmountPrint = $("#" + this.pageId + "balance").val();
        this.enteredForAdvanceAmountPrint = $("#" + this.pageId + "advance").val();
        this.enteredForPayableAtPrint = $("#" + this.pageId + "payableAt").val();
        this.enteredForVehicleNumberPrint = $("#" + this.pageId + "vehicleNumber").val();
        this.enteredForSupplierNamePrint = $("#" + this.pageId + "supplierName").val();
        this.enteredForBrokerNamePrint = $("#" + this.pageId + "brokerName").val();
        this.enteredForDriverNamePrint = $("#" + this.pageId + "driverName").val();
        this.enteredForDeductionAmountPrint = $("#" + this.pageId + "deduction").val();
        this.enteredForLessLateArrivalPrint = $("#" + this.pageId + "lessLateArrival").val();
        this.enteredForLessMunishianaPrint = $("#" + this.pageId + "lessMunishiana").val();
        this.enteredForLessunloadhamaliPrint = $("#" + this.pageId + "lessUnloadHamali").val();
        this.enteredForTimeTakenToReachedPrint = $("#" + this.pageId + "timeTakenToReached").val();
        this.enteredForArrivalDatePrint = $("#" + this.pageId + "arrivalDates").val();
        this.enteredForArrivalTimePrint = $("#" + this.pageId + "arrivalTime").val();

        var arrTimeSplit = this.enteredForTimeTakenToReachedPrint.split(":");

        var inMins = (arrTimeSplit[0] * 60);
        if (arrTimeSplit.length > 1) {
            inMins = inMins + +arrTimeSplit[1];
        }

        this.hireSlipDtoForPrintAndConfirmPayment.hireslipnumber = this.enteredHireSlipNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.barcodeValue = this.enteredScanCodeNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoForPrintAndConfirmPayment.totalhire = this.enteredForHireAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.balance = this.enteredForBalanceAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.advance = this.enteredForAdvanceAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.payableat = this.enteredForPayableAtPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.status = "BalancePaid";
        this.hireSlipDtoForPrintAndConfirmPayment.mode = "HireBalance";
        this.hireSlipDtoForPrintAndConfirmPayment.invoicedate = this.enteredForInvoiceDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.suppliername = this.enteredForSupplierNamePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.brokerName = this.enteredForBrokerNamePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.vehicleNumber = this.enteredForVehicleNumberPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.drivername = this.enteredForDriverNamePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.deductionAmt = this.enteredForDeductionAmountPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.lesslatearrival = this.enteredForLessLateArrivalPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.lessmunishiana = this.enteredForLessMunishianaPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.lessunloadhamali = this.enteredForLessunloadhamaliPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.arrivaldate = this.enteredForArrivalDatePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.arrivalTime = this.enteredForArrivalTimePrint;
        this.hireSlipDtoForPrintAndConfirmPayment.column3 = this.enteredForTimeTakenToReachedPrint;
        this.hireSlipDtoForPrintAndConfirmPayment.reportMode = "FromPayment";
        this.hireSlipDtoForPrintAndConfirmPayment.inMinutes = inMins;

        if ($("#" + this.pageId + "paymentMode").val() != null && $("#" + this.pageId + "paymentMode").val() == "cash") {
            this.hireSlipDtoForPrintAndConfirmPayment.balPaymentType = "Cash";
        } else if ($("#" + this.pageId + "paymentMode").val() != null && $("#" + this.pageId + "paymentMode").val() == "cheque") {
            this.enteredForChequeNumberPrint = $("#" + this.pageId + "chequeNumber").val();
            this.enteredForChequeDatePrint = $("#" + this.pageId + "chequeDates").val();
            this.enteredForBankNamePrint = $("#" + this.pageId + "bankName").val();

            this.hireSlipDtoForPrintAndConfirmPayment.bankname = this.enteredForBankNamePrint;
            this.hireSlipDtoForPrintAndConfirmPayment.chequeno = this.enteredForChequeNumberPrint;
            this.hireSlipDtoForPrintAndConfirmPayment.chequedate = this.enteredForChequeDatePrint;
            this.hireSlipDtoForPrintAndConfirmPayment.balPaymentType = "Cheque";
        } else if ($("#" + this.pageId + "paymentMode").val() != null && $("#" + this.pageId + "paymentMode").val() == "neft") {
            this.enteredForRefferenceNumberPrint = $("#" + this.pageId + "referenceNumber").val();
            this.enteredForBankNamePrint = $("#" + this.pageId + "bankName").val();

            this.hireSlipDtoForPrintAndConfirmPayment.bankname = this.enteredForBankNamePrint;
            this.hireSlipDtoForPrintAndConfirmPayment.chequeno = this.enteredForRefferenceNumberPrint;
            this.hireSlipDtoForPrintAndConfirmPayment.balPaymentType = "Neft";
        }
        this.hireSlipDtoForPrintAndConfirmPayment.companyid = this.userDataDtoReturnSession.companyId;

        if (this.enteredHireSlipNumberPrint != null) {
            this.hireSlipDtoPrintVoucher = new HireSlipDto();
            this.hireSlipDtoPrintVoucher.hireslipnumber = this.enteredHireSlipNumberPrint;
            this.hireSlipDtoPrintVoucher.companyid = this.userDataDtoReturnSession.companyId;
            this.hireslipService.getHireSlipDetailsForVoucher(this.hireSlipDtoPrintVoucher).subscribe(
                (response) => {
                    //console.log(response);
                    if (response.length > 0) {
                        this.hireSlipDtoForPrintAndConfirmPayment.mainBookingStation = response[0].mainBookingStation;
                        this.hireSlipDtoForPrintAndConfirmPayment.toStation = response[0].toStation;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {

                    swal("Error", "Server Problem Occurred While Getting the hireslip details for print voucher", "info");
                }, () => console.log('done');
        }

        return this.hireSlipDtoForPrintAndConfirmPayment;
    }

    bankNameDropDownListner(event) {
        this.modelBankName = event.item.bankName;
        $("#" + this.pageId + "bankName").val(event.item.bankName);
        /* if ($("#"+this.pageId+"bankName").val() == "Add New") {
             $("#"+this.pageId+"newBankNameId").val('');
             this.viewNewBankNameField = true;
         } else {
             $("#"+this.pageId+"newBankNameId").val('');
             this.viewNewBankNameField = false;
         }*/
    }

    getDetailsForBankMasterRead() {
        this.userDataDto = new UserDataDto();
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        this.getDetailsForBankMasterRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.bankNameOptions = [];
                    this.bankNameTA = [];
                    this.bankNameOptions = response;
                    this.userDataDtoAddNewBank = new UserDataDto();
                    // this.userDataDtoAddNewBank.bankName = "Add New";
                    // this.bankNameTA.push(this.userDataDtoAddNewBank);
                    for (let i = 0; i < this.bankNameOptions.length; i++) {
                        this.bankNameTA.push(this.bankNameOptions[i]);
                    }
                } else {
                    this.bankNameOptions = [];
                    this.bankNameTA = [];
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Name Details", "warning");
            },
            () => console.log('done');
    };
}
