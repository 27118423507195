<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Statement Report LR Details For - {{validateStatementNo}} - {{validateDestination}}
                    </h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-9">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <br>
                            <!--<button style='float: right;' class="button dt-button" id="{{pageId}}printStmtRptLrBtnId"
								(click)="clickListnerForPrintStmtRptLr()">
								<i class="fas fa-print"> &nbsp;Print</i></button>-->
                            <table datatable id="{{pageId}}statementRptLrDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrDetails" [dtTrigger]="dtTriggerStatementReportLrDetails">
                                <thead>
                                    <tr>
                                        <th>LR No</th>
                                        <th>Invoice No</th>
                                        <th>Articles No</th>
                                        <th>Consignor Name</th>
                                        <th>Consignee Name</th>
                                        <th>Actual Weight</th>
                                        <th>To Pay Amt</th>
                                        <th>Paid Amt</th>
                                        <th>DD Amt</th>
                                        <th>Remarks</th>
                                        <th [hidden]='!hideShowForPrint'>Destination</th>
                                        <th [hidden]='!hideShowForPrint'>Invoice Date</th>
                                        <th [hidden]='!hideShowForPrint'>Charged Weight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrDetailsData of statementReportLrDetailsDataList ">
                                        <td>{{ statementReportLrDetailsData.lrNumber }}</td>
                                        <td>{{ statementReportLrDetailsData.invoiceNumber }}</td>
                                        <td>{{ statementReportLrDetailsData.totalArticles }}</td>
                                        <td>{{ statementReportLrDetailsData.consignorName }}</td>
                                        <td>{{ statementReportLrDetailsData.consigneeName }}</td>
                                        <td>{{ statementReportLrDetailsData.actualWeight }}</td>
                                        <td>{{ statementReportLrDetailsData.toPay }}</td>
                                        <td>{{ statementReportLrDetailsData.paid }}</td>
                                        <td>{{ statementReportLrDetailsData.ddAmt }}</td>
                                        <td *ngIf="statementReportLrDetailsData.ddAmt == 0"></td>
                                        <td *ngIf="statementReportLrDetailsData.ddAmt > 0">DD mentioned in LR therefore no crossing chgs credited</td>
                                        <td [hidden]='!hideShowForPrint'>{{ statementReportLrDetailsData.destination }}
                                        </td>
                                        <td [hidden]='!hideShowForPrint'>
                                            {{ statementReportLrDetailsData.invoiceDateStr }}</td>
                                        <td [hidden]='!hideShowForPrint'>
                                            {{ statementReportLrDetailsData.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]='!hideShowForPrint'></td>
                                        <td [hidden]='!hideShowForPrint'></td>
                                        <td [hidden]='!hideShowForPrint'></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-3 vl p-t-10">
                        <div class="box-body">
                            <h6><u>Addition:</u></h6>
                            <table datatable id="{{pageId}}statementRptAdditionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrAdditionDetails" [dtTrigger]="dtTriggerStatementReportLrAdditionDetails">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrAdditionData of statementReportLrAdditionDataList ">
                                        <td>{{ statementReportLrAdditionData.description }}</td>
                                        <td>{{ statementReportLrAdditionData.totalAmount }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body">
                            <h6><u>Deduction:</u></h6>
                            <table datatable id="{{pageId}}statementRptDeductionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrDeductionDetails" [dtTrigger]="dtTriggerStatementReportLrDeductionDetails">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Debit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrDeductionData of statementReportLrDeductionDataList ">
                                        <td>{{ statementReportLrDeductionData.description }}</td>
                                        <td>{{ statementReportLrDeductionData.totalAmount }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <!--<div *ngIf="viewStatementRptLRDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printStmtRptLRDtlsId">
		<app-statement-rpt-lr-details-print></app-statement-rpt-lr-details-print>
	</div>-->
</body>

</html>