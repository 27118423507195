<html>

<head>
</head>

<body>
	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card">
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-12 lineBottom_custom">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12" *ngIf="showSpinnerForAction">
									<!-- spinner start-->
									<div class="form-group">
										<div class="input-group">
											<mat-progress-bar mode="indeterminate" style="color: green;">
											</mat-progress-bar>
											<br>
											<h6 class="card-title" align='center'
												style="color: green; margin: auto; font-size: 18px;">
												Please Wait Loading Details.....</h6>
										</div>
									</div>
									<!-- spinner end-->
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<h6 class="card-title bbtitle">Vehicle Details</h6>
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Vehicle</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-truck"></i>
															</span>
														</div>
														<input #vehicleNumberDropDown
															id="{{pageId}}dropDownInputFieldVehicleNumber" type="text"
															class="form-control"
															(selectItem)="clickListnerForDropDownInputFieldVehicleNumber($event,vehicleNumberPopUpTemplate)"
															[(ngModel)]="modelDropDownVehicleNumber"
															[ngbTypeahead]="searchDropDownInputFieldVehicleNumber"
															[resultFormatter]="formatterDropDownVehicleNumber"
															[inputFormatter]="formatterDropDownVehicleNumber"
															placeholder="Please Select The Vehicle Number"
															(focus)="focusDropDownVehicleNumberTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
										<!-- 											the first autocomplete ends -->
										<div *ngIf="addNewVehicleNumber" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}addVehicleNumber">
													<label>Add Vehicle Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-truck"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div *ngIf="addNewVehicleNumber" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}vehicleName">
													<label>Vehicle Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-id-card"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 vl">
									<div class="row">
										<!-- 											the second autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<h6 class="card-title bbtitle">Driver Details</h6>
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Driver Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input #driverNameDropDown id="{{pageId}}dropDownInputFieldDriverName"
															type="text" class="form-control"
															(selectItem)="clickListnerForDropDownInputFieldDriverName($event)"
															[(ngModel)]="modelDropDownDriverName"
															[ngbTypeahead]="searchDropDownInputFieldDriverName"
															[resultFormatter]="formatterDropDownDriverName"
															[inputFormatter]="formatterDropDownDriverName"
															placeholder="Please Select The Driver Name"
															(focus)="focusDropDownDriverNameTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Contact Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input id="{{pageId}}inputFieldDriverConatctNumber" type="text"
															class="form-control" placeholder="Contact Number"
															autocomplete="off" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4 vl">
									<h6 class="card-title bbtitle">Labour Details</h6>
									<div class="row">
										<div class="col-sm-12 col-md-12" [hidden]="!viewToShowDeliveryManField">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Delivery Man</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input #labourHeadNameDropDown
															id="{{pageId}}dropDownInputFieldLabourHeadName" type="text"
															class="form-control"
															(selectItem)="clickListnerForDropDownInputFieldLabourHeadName($event)"
															[(ngModel)]="modelDropDownLabourHeadName"
															[ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
															[resultFormatter]="formatterDropDownLabourHeadName"
															[inputFormatter]="formatterDropDownLabourHeadName"
															placeholder="Please Select Labour Head Name"
															(focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Labour Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input #labourNameDropDown id="{{pageId}}dropDownInputFieldLabourName"
															type="text" class="form-control"
															(selectItem)="clickListnerForDropDownInputFieldLabourName($event)"
															[(ngModel)]="modelDropDownLabourName"
															[ngbTypeahead]="searchDropDownInputFieldLabourName"
															[resultFormatter]="formatterDropDownLabourName"
															[inputFormatter]="formatterDropDownLabourName"
															placeholder="Please Select The Labour Name"
															(keypress)="onKeyPressListnerForDropDownLabourName($event);"
															(focus)="focusDropDownLabourNameTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="form-group">
													<label>Hamalis</label>
													<div class="form-group-prepend">
														<span class="input-group-text"> <i
																class="fas fa-notes-medical"></i>
														</span>
													</div>
													<textarea class="form-control" rows="4" id="{{pageId}}hamalis"
														readonly></textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12" style="text-align: right;">
									<!--<button class="button_custome btn btn-primary m-r-10"
												id="{{pageId}}startFromStocksGroupingBtn" mat-button>Start
												Over</button>
									<button class="btn btn-dark m-r-10 button_custome" id="{{pageId}}backToInvoiceDetailsBtn"
										mat-button matStepperPrevious>Back</button>-->
									<button class="btn btn-success m-r-10 button_custome"
										[hidden]="!viewScheduleTripBtn" id="{{pageId}}scheduleTripBtn">Schedule
										Trip</button>
									<!--[hidden]="!viewCreateTripSheetBtn"-->
									<button class="btn btn-success m-r-10 button_custome"
										id="{{pageId}}createTripSheetBtn ">Create Trip
										Sheet</button>
									<button class="button_custome btn btn-info m-r-10" id="{{pageId}}resetTripBtn"
										(click)="clickResetBtn();">Reset</button>
									<button class="button_custome btn btn-info m-r-10"
										id="{{pageId}}cancelTripBtn">Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<!--POP UP START HERE-->
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group">
				<ng-template #vehicleNumberPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h4 class="modal-title">Add New Vehicle!</h4>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Add Vehicle Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-truck"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}addNewVehicleNumberId"
														aria-describedby="basic-addon11" autocomplete="off">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Vehicle Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-id-card"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}addNewVehicleNameId"
														aria-describedby="basic-addon11" autocomplete="off">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark"
							(click)="saveBtnVehicleNumberPopup();">Save</button>
						<button type="button" class="btn btn-outline-dark"
							(click)="resetBtnVehicleNumberPopup();">Reset</button>
						<button type="button" class="btn btn-outline-dark"
							(click)="closePopUpVehiclePopup();">Cancel</button>
					</div>
				</ng-template>
			</div>
		</div>
	</div>

	<div *ngIf="viewStockTripsheetPrint" onafterPopUp="afterPopUp()" id="{{pageId}}stockTripsheetPrintId">
		<app-stock-for-tripsheet-driver-print>
		</app-stock-for-tripsheet-driver-print>
	</div>
</body>

</html>