<table id="{{pageId}}firstPrint">
	<table width='100%'>
		<td width='70%'><img src="assets/images/srdLogisticPrintLogo.png"
			alt="SRDLogo"></td>
		<td width='30%'>{{address}}</td>
	</table>
</table>

<table width='100%' border='0' cellspacing='0' cellpadding='0'>
	<tr>
		<td width='100%' height='40' align='center'><span
			style="text-decoration: underline 2px solid #000; font-size: 20px; font-weight: bold;">BANGALORE
				OFFICE DAILY REPORT - Date : {{selectedDate}} </span></td>
	</tr>
</table>



<span
	style="text-decoration: underline 2px solid #000; font-size: 20px; font-weight: bold;">Agent
	Due Details</span>
<table width='100%' border='0' cellspacing='0' cellpadding='0'
	style='margin-top: 2%; margin-bottom: 2%;'>
	<tr>
		<td width='10%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'><strong>Station</strong></td>
		<td width='17%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Old
				Dues</strong></td>
		<td width='7%' align='center'
			style='border: 1px solid #000; border-left: none; border-bottom: none;'><strong>DN</strong></td>
		<td width='19%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Last
				Due-[{{lastStmtDueDate}}]</strong></td>
		<td width='6%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'><strong>DN</strong></td>
		<td width='16%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Current
				Due-[{{curStmtDueDate}}]</strong></td>
		<td width='7%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'><strong>DN</strong></td>
		<td width='18%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'><strong>Total
				Dues</strong></td>
	</tr>

	<tr
		*ngFor="let dataAgetnsDueDetailsDataListCustomPrint of agetnsDueDetailsDataListCustomPrint">

		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'>{{dataAgetnsDueDetailsDataListCustomPrint.destination}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataAgetnsDueDetailsDataListCustomPrint.oldDuesFormatted}}</td>
		<td align='center'
			style='border: 1px solid #000; border-left: none; border-bottom: none;'>{{dataAgetnsDueDetailsDataListCustomPrint.oldDuesDN}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataAgetnsDueDetailsDataListCustomPrint.lastDuesFormatted}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'>{{dataAgetnsDueDetailsDataListCustomPrint.lastDueDN}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataAgetnsDueDetailsDataListCustomPrint.currentDuesFormatted}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'>{{dataAgetnsDueDetailsDataListCustomPrint.currentDueDN}}</td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: none;'>{{dataAgetnsDueDetailsDataListCustomPrint.totalDuesFormatted}}</td>
	</tr>
	<tr>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000;'><strong>Total
				: {{agetnsDueDetailsDataListCustomPrint.length}}</strong></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.oldDuesFormatted}}</strong></td>
		<td align='center'
			style='border: 1px solid #000; border-left: none; border-bottom: 1px solid #000;'></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.lastDuesFormatted}}</strong></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.currentDuesFormatted}}</strong></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'></td>
		<td align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.totalDuesFormatted}}</strong></td>
	</tr>

</table>


<span
	style="text-decoration: underline 2px solid #000; font-size: 20px; font-weight: bold;">Bank
	Details</span>
<table width='100%' border='0' cellspacing='0' cellpadding='0'>

	<tr>
		<td width='25%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'><strong>Bank
				Name</strong></td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Cash</strong></td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Cheque
				Amt</strong></td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Total
				Balance</strong></td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Prov
				Cheque Amt</strong></td>
	</tr>

	<tr
		*ngFor="let dataBankMasterDataListCustomPrint of bankMasterDataListCustomPrint">
		<td width='30%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'>{{dataBankMasterDataListCustomPrint.bankName}}
		</td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataBankMasterDataListCustomPrint.paymentAmt}}</td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataBankMasterDataListCustomPrint.chqAmt}}
		</td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataBankMasterDataListCustomPrint.totalAmount}}</td>
		<td width='15%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataBankMasterDataListCustomPrint.proviAmt}}
		</td>
	</tr>
	<tr>
		<td width='15%' align='center'
			style='border-right: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000; border-top: 1px solid #000;'><strong>Total
				: {{bankMasterDataListCustomPrint.length}}</strong></td>
		<td width='15%' align='center'
			style='border-right: 1px solid #000; border-bottom: 1px solid #000; border-top: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.cashAmt}}</strong></td>
		<td width='15%' align='center'
			style='border-right: 1px solid #000; border-bottom: 1px solid #000; border-top: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.chequeAmt}}</strong></td>
		<td width='15%' align='center'
			style='border-right: 1px solid #000; border-bottom: 1px solid #000; border-top: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.totalAmount}}</strong></td>
		<td width='15%' align='center'
			style='border-right: 1px solid #000; border-bottom: 1px solid #000; border-top: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.pdcAmt}}</strong></td>
	</tr>
</table>

<span
	style="text-decoration: underline 2px solid #000; font-size: 20px; font-weight: bold;">Post
	Dated Cheque</span>

<table width='100%' border='0' cellspacing='0' cellpadding='0'>
	<td width='50%' border='0' cellspacing='0' cellpadding='0'>
	<tr>
		<td width='20%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'><strong>Station
				Name</strong></td>
		<td width='30%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'><strong>Balance
				Amount</strong></td>
	</tr>
	<tr
		*ngFor="let dataPdcDetailsDataListCustomPrint of pdcDetailsDataListCustomPrint">
		<td width='20%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000;'>{{dataPdcDetailsDataListCustomPrint.source}}</td>
		<td width='30%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000;'>{{dataPdcDetailsDataListCustomPrint.paid}}</td>
	</tr>
	<tr>
		<td width='20%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000;'><strong>Total
				: {{pdcDetailsDataListCustomPrint.length}}</strong></td>
		<td width='30%' align='center'
			style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;'><strong>{{fortNightStmtDtoSummaryPrint.totPdcAmt}}</strong></td>
	</tr>
	</td>
</table>