<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Consignments Claim
						Settlement Entry</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-3">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">

										<div class="col-sm-12 col-md-12">
											<h6 class="card-title">Entry Type</h6>
											<div class="form-group">
												<div class="input-group">
													<label>LR Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-file-alt"></i>
														</span>
													</div>
													<input #lrNumber type="text" id="{{pageId}}lrNumber" name="lrNumber"
														class="form-control" aria-describedby="basic-addon11"
														[(ngModel)]="lrDto.lrNumber">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Destination</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-road"></i>
														</span>
													</div>
													<input #destination type="text" id="{{pageId}}destination"
														[(ngModel)]="lrDto.destination" name="destination"
														class="form-control" aria-describedby="basic-addon11"
														readonly>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>No. Of Articles</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-clone"></i>
														</span>
													</div>
													<input #totalArticles type="text" id="{{pageId}}totalArticles"
														[(ngModel)]="lrDto.totalArticles" name="totalArticles"
														class="form-control" aria-describedby="basic-addon11"
														readonly>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Goods Value</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-pound-sign"></i>
														</span>
													</div>
													<input #goodsValue type="text" id="{{pageId}}goodsValue"
														[(ngModel)]="lrDto.goodsValue" name="goodsValue"
														class="form-control" aria-describedby="basic-addon11"
														readonly>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Settlement Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" name="settlementDates"
														ngbDatepicker #settlementDates="ngbDatepicker"
														id="{{pageId}}settlementDate" [(ngModel)]="lrDto.settlementDate">
													<div class="input-group-append"
														(click)="settlementDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Payment Mode</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-search"></i>
														</span>
													</div>
													<select class="custom-select col-12" id="{{pageId}}paymentMode"
														name="paymentMode" #paymentMode
														(change)="paymentModeMethod(paymentMode.value)"
														[(ngModel)]="lrDto.settlementMode">
														<option value="Cash" selected>Cash</option>
														<option value="Cheque">Cheque</option>
													</select>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Amount</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-rupee-sign"></i>
														</span>
													</div>
													<input #amount type="number" id="{{pageId}}amount" name="amount"
														class="form-control" aria-describedby="basic-addon11"
														[(ngModel)]="lrDto.settlementAmt">
												</div>
											</div>
										</div>

										<div *ngIf="cheque" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}chequeDate">
													<label>Cheque Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" name="chequeDates" ngbDatepicker
														#chequeDates="ngbDatepicker"
														[(ngModel)]="lrDto.chequeDate">
													<div class="input-group-append"
														(click)="chequeDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div *ngIf="cheque" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Cheque No</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-file-alt"></i>
														</span>
													</div>
													<input type="text" id="{{pageId}}chequeNumber" name="chequeNumber"
														class="form-control" aria-describedby="basic-addon11"
														[(ngModel)]="lrDto.chequeNumber" #chequeNumber
														(keyup)="fieldFocus($event, bankName)">
												</div>
											</div>
										</div>
										<div *ngIf="cheque" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Bank Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-university"></i>
														</span>
													</div>
													<input type="text" id="{{pageId}}bankName" name="bankName"
														class="form-control" aria-describedby="basic-addon11"
														[(ngModel)]="lrDto.bankName" #bankName
														(keyup)="fieldFocus($event, remark)">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Remark</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-hashtag"></i>
														</span>
													</div>
													<textarea class="form-control" id="{{pageId}}remark" name="remark"
														rows="3" [(ngModel)]="lrDto.remarks" #remark></textarea>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-12"
										style="text-align: center; padding: 10px">
										<button type="submit" class="btn btn-success m-r-10"
											id="{{pageId}}doneBtn" (click)="validateConsignmentClaim()">Done</button>
										<button type="submit" class="btn btn-dark m-r-10"
											id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
									</div>
								</div>
								<!-- 								dsfsdkfmasdlkfnmasdlf -->

								<div class="col-md-9 vl">
									<div class="card-body">
										<div class="row">
											<!-- 											the first autocomplete starts -->
											<div class="col-sm-12 col-md-3">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}source">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-box"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																[(ngModel)]="lrDto.source" placeholder="Select Source"
																[formControl]="control" [appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(sourceOptions | filter: control.value) as resultSource">
												<app-option *ngFor="let option of resultSource"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultSource.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>
											<!-- 											the first autocomplete ends -->

											<div class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}formDate">
														<input class="form-control" placeholder="yyyy-mm-dd"
															name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-3">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}toDate">
														<input class="form-control" placeholder="yyyy-mm-dd"
															name="toDates" ngbDatepicker #toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-3">
												<button style="padding: 1px 4px;" type="submit"
													(click)="getConsignmentsClaimsSettlementEntryList()"
													class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
											</div>
										</div>
										<div class="row">
											<div class="col-lg-12">
												<!-- 											 style="border-bottom: 1px solid orange;" -->
												<h6 class="card-title">Consignment Claim Settlement
													Entry Details</h6>
												<div class="box-body">
													<table datatable
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsConsignmentsClaimSettlementEntry"
														[dtTrigger]="dtTriggerConsignmentsClaimSettlementEntry">

														<thead>
															<tr>
																<th>LR No</th>
																<th>Consignee Name</th>
																<th>Gst Number</th>
																<th>Settlement Date</th>
																<th>Payment Mode</th>
																<th>Amount</th>
																<th>Cheque Date</th>
																<th>Cheque No</th>
																<th>Bank Name</th>
																<th>Claim Docs</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let consignmentsEntryData of consignmentsClaimSettlementEntryDataList "
																(click)=" rowSelected( consignmentsEntryData )">
																<td>{{ consignmentsEntryData.lrNumber }}</td>
																<td>{{ consignmentsEntryData.consigneeName }}</td>
																<td>{{ consignmentsEntryData.gstNoConsignee }}</td>
																<td>{{ consignmentsEntryData.settlementDate }}</td>
																<td>{{ consignmentsEntryData.settlementMode }}</td>
																<td>{{ consignmentsEntryData.settlementAmt }}</td>
																<td>{{ consignmentsEntryData.chequeDate }}</td>
																<td>{{ consignmentsEntryData.chequeNumber }}</td>
																<td>{{ consignmentsEntryData.bankName }}</td>
																<td>{{ consignmentsEntryData.imgURLTDS }}</td>
															</tr>


															<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

														</tbody>
													</table>

												</div>
											</div>
										</div>
									</div>
								</div>



							</div>
						</div>

					</div>

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>