import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";
import { InvoiceDto } from '../dto/Invoice-dto';
import { TripSheetDto } from '../dto/TripSheet-dto';


@Injectable({
    providedIn: 'root'
})
export class Invoice {

    private _baseUrl = serverUrl.webInvoice;

    constructor(private http: HttpClient) {

    }

    getTripSheetDetails(tripSheetDto: Object): Observable<TripSheetDto[]> {
        return this.http.post<TripSheetDto[]>(`${this._baseUrl}/getTripSheetDetailsApi`, tripSheetDto);
    }

    getPopUpTripSheetDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPopUpTripSheetDetailsApi`, lrDto);
    }


    //Invoice Details Report
    getInvoiceDetailsInvoiceNo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsInvoiceNoApi`, lrDto);
    }

    getInvoiceDetailsDateWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsDateWiseApi`, lrDto);
    }

    getInvoiceDetailsSourceWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsSourceWiseApi`, lrDto);
    }

    getInvoiceDetailsDestinationWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsDestinationWiseApi`, lrDto);
    }
    getInvoiceDetailsDestinationWiseSubStation(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsDestinationWiseSubStationApi`, lrDto);
    }

    getInvoiceDetailsDateStation(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsDateStationApi`, lrDto);
    }

    getInvoiceDestinationSummary(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDestinationSummaryApi`, lrDto);
    }
    
    getInvoiceDetailsMainInvoiceService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceDetailsMainInvoiceServiceApi`, lrDto);
    }
    
    getMainInvoiceLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMainInvoiceLrDetailsApi`, lrDto);
    }
    
    //stmt invoice rpts
    getStatementInvoiceReportSouceWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStatementInvoiceReportSouceWiseApi`, lrDto);
    }
    
    getStatementInvoiceReportBkgAgentWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStatementInvoiceReportBkgAgentWiseApi`, lrDto);
    }
    
    getStatementInvoiceReportCityWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStatementInvoiceReportCityWiseApi`, lrDto);
    }
    //

}

