//CHG-V-CNM:02 - @Imran chg, Blocked CFT Checkbox for all users except mussadik bhai On 03/05/2024 By Imran
//CHG-V-CNM:03 - @Asrar Jr Chg, Save/Update button show based on Rights on 05/09/2024 
import { NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MasterDto } from 'src/app/dto/master-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Subject, merge } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import swal from 'sweetalert';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import { ConsignorMultipleMobileNumberDetailsComponent } from 'src/app/master/consignor/consignor-multiple-mobilenumber-details/consignor-multiple-mobilenumber-details.component';
import { CflFormulaSetupConsignorComponent } from '../../fixing/cfl-formula-setup-consignor/cfl-formula-setup-consignor.component';
@Component({
    selector: 'app-consignor-master',
    templateUrl: './consignor-master.component.html',
    styleUrls: ['./consignor-master.component.css']
})
export class ConsignorMasterComponent implements OnInit {

    masterDto: MasterDto = new MasterDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    index = true;
    commodity = false;
    source = false;
    sourceAll = false;
    others = false;
    normalView = true;
    advanceView = false;

    consignorMasterDataList: any;
    loadingIndicator = true;
    pageId = "conms";


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerConsignorMaster: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsConsignorMaster: any;
    showSpinnerForAction: boolean = false;

    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusSourceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === "" ? this.sourceTA : this.sourceTA.filter(
                    (v) => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 200)
            )
        );
    };
    formatterSource = (x: { mainBookStations: string }) => x.mainBookStations;

    agentDtoOptionSourceStationAdvance: AgentDetailsDto[];
    agentDtoSourceStationAdvance: AgentDetailsDto = new AgentDetailsDto();
    agentDtoSourceStationAdvanceAll: AgentDetailsDto = new AgentDetailsDto();
    public modelSourceAllAdvance: any;
    sourceAdvanceTA: Array<AgentDetailsDto> = [];
    focusSourceAllAdvanceTA$ = new Subject<string>();
    searchSourceAllAdvance = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusSourceAllAdvanceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === "" ? this.sourceAdvanceTA : this.sourceAdvanceTA.filter(
                    (v) => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 200)
            )
        );
    };
    formatterSourceAllAdvance = (x: { mainBookStations: string }) => x.mainBookStations;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    indexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    public modelIndex: any;
    @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
    indexTA: Array<any> = [];
    focusIndexTA$ = new Subject<string>();
    indexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.indexTA
                : this.indexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }


    lrDtoForBookingSourceSubStation: LRDto = new LRDto();
    agentDtoOptionSourceSubStation: LRDto[];
    agentDtoSourceSubStationAll: LRDto = new LRDto();
    public modelSourceSubStation: any;
    sourceSubStationTA: Array<LRDto> = [];
    focusSourceSubStationTA$ = new Subject<string>();
    searchSourceSubStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceSubStationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === "" ? this.sourceSubStationTA : this.sourceSubStationTA.filter(
                    (v) => v.branch.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 200)
            )
        );
    };
    formatterSourceSubStation = (x: { branch: string }) => x.branch;

    partyMasterDtoForSave: PartyMasterDto = new PartyMasterDto();
    partyMasterDtoForSearch: PartyMasterDto = new PartyMasterDto();

    validateConsignorNameSave: any;
    validateConsignorId: any;
    validateGstNo: any;
    validateMobileNo: any;
    validateOfficeNo: any;
    validateEmailId: any;
    validateContactPerson: any;
    validateAgentName: any;
    validateAddress: any;
    validateArea: any;
    validateCity: any;
    validatePincode: any;

    validateIndexValue: any;
    validateCommodityDropDown: any;
    validateNormalSourceDropDown: any;
    validateSourceCityDropDown: any;
    validateSourceAdvanceDropDown: any;
    validateSourceSubStation: any;

    sourceSubStationView: boolean = false;

    consignorMobileNoList: any;
    returnListOfMobileNumber: any;
    disableCftForSave: any;
    blockCFTCheckBox: boolean = false;
    enterGstNoView = false;
    validateEnteredGstNo: any;
    isMonitorDelPerformance: boolean = false;
    viewMonitorPerfToggle: boolean = false;
    rightsViewMonitorPerfToggle: boolean = false;
    viewSaveUpdateBtn: boolean = false;

    constructor(private consignorService: ConsignorService, private mastereadService: MasterReadService,
        private router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.consignorMasterDataTable();
            this.setSourceDetails();
            this.setSourceAdvanceDetails();
            this.getCommodityMasterDetailsList();
            this.setIndex();
            //CHG-V-CNM:02
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Partyperformance Monitor") {
                        this.blockCFTCheckBox = true;
                    }
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Partyperformance Monitor") {
                        this.rightsViewMonitorPerfToggle = true;
                    }
                    //CHG-V-CNM:03
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ConsignorMaster AddUpdt") {
						this.viewSaveUpdateBtn = true;
					}
                    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
                }
            }
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {

    }

    consignorMasterDataTable() {
        this.dtOptionsConsignorMaster = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel">Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Consignor Details Report - ";
                    },
                    exportOptions: {
                        columns: [1, 0, 2, 3, 4]
                    }
                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            //pageLength: 9,
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerConsignorMaster.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerConsignorMaster.unsubscribe();
    }
    //  service starts
    /*  getPartyDetailsForRead() {
          this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
      }
  
  
      getConsignorDetailsList = () => {
          this.getPartyDetailsForRead();
          this.mastereadService.getConsignorMaster(this.partyMasterDto).subscribe(
              (response) => {
                  if (response) {
                      console.log(response);
                      this.consignorMasterDataList = response;
                      this.dtTriggerConsignorMaster.next();
                      //  console.log( this.consignorMasterDataList[5] );
                  }
              }), (error) => swal({
                  title: "Error",
                  text: "Server Error While Getting Consignor Master Details",
                  icon: "warning",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }),
              () => console.log('done');
      };*/

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    searchMethod(searchType: string) {
        if (searchType === 'index') {
            this.index = true;
            this.commodity = false;
            this.source = false;
        } else if (searchType === 'commodity') {
            this.index = false;
            this.commodity = true;
            this.source = false;
        } else if (searchType === 'source') {
            this.index = true;
            this.commodity = false;
            this.source = true;

        } else if (searchType === 'srcncom') {
            this.index = false;
            this.commodity = true;
            this.source = true;

        } else {
            this.index = false;
            this.commodity = false;
            this.source = false;
        }
    }

    searchModeMethod(searchTypes: string) {
        if (searchTypes === 'normalSearch') {
            this.normalView = true;
            this.advanceView = false;
            this.enterGstNoView = false;

            this.sourceSubStationView = false;
        } else if (searchTypes === 'advanceSearch') {
            this.advanceView = true;
            this.normalView = false;

            this.sourceSubStationView = false;
            this.enterGstNoView = false;
        } else if (searchTypes === 'searchByGst') {
            this.advanceView = false;
            this.normalView = false;

            this.sourceSubStationView = false;
            this.enterGstNoView = true;
        } else {
            this.advanceView = false;
            this.normalView = true;

            this.sourceSubStationView = false;
            this.enterGstNoView = false;

        }
    }

    // drop Down 

    clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.mainBookStations);
    }

    getSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    setSourceDetails() {
        this.getSourceDataDetails();
        this.showSpinnerForAction = true;
        this.mastereadService.getMainBookingStationsDetails(this.agentDtoSourceStation)
            .subscribe((response) => {
                console.log(response);
                if (response.length > 0) {
                    this.sourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.sourceTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    console.log(this.sourceTA);
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log("done");
    }

    clickListnerForSourceAllAdvance(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSourceAllAdvance = e.item;
        $("#" + this.pageId + "sourceAllAdvance").val(this.modelSourceAllAdvance.mainBookStations);
        if ($("#" + this.pageId + "searchMode").val() == "advanceSearch" &&
            $("#" + this.pageId + "sourceAllAdvance").val() == "GUJARAT") {
            this.sourceSubStationView = true;
            this.getBookingSource();
        } else {
            this.sourceSubStationView = false;
        }

    }
    getSourceAdvanceDataDetails() {
        this.agentDtoSourceStationAdvance = new AgentDetailsDto();
        this.agentDtoSourceStationAdvance.companyId = this.userDataDtoReturnSession.companyId;
    }

    setSourceAdvanceDetails() {
        this.getSourceAdvanceDataDetails();
        this.showSpinnerForAction = true;
        this.mastereadService.getMainBookingStationsDetails(this.agentDtoSourceStationAdvance)
            .subscribe((response) => {
                console.log(response);
                if (response.length > 0) {
                    this.sourceAdvanceTA = [];
                    this.agentDtoOptionSourceStationAdvance = [];
                    this.agentDtoOptionSourceStationAdvance = response;
                    //this.agentDtoSourceStationAdvanceAll = new AgentDetailsDto();
                    //this.agentDtoSourceStationAdvanceAll.mainBookStations = "All";
                    //this.sourceAdvanceTA.push(this.agentDtoSourceStationAdvanceAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStationAdvance.length; i++) {
                        this.sourceAdvanceTA.push(this.agentDtoOptionSourceStationAdvance[i]);
                    }
                    console.log(this.sourceAdvanceTA);
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log("done");
    }


    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    }
    getCommodityMasterDetailsList = () => {
        //this.getCommodityListRead();
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        //this.rateMasterDto.destination = this.selectedDestinationForCommodity;
        this.mastereadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    indexDropDownListner(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelIndex = e.item;
        $("#" + this.pageId + "indexId").val(this.modelIndex.label);
    }

    setIndex() {
        for (let i = 0; i < this.indexOptions.length; i++) {
            this.indexTA.push(this.indexOptions[i].label);
        }
    }


    clickListnerForSourceSubStation(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSourceSubStation = e.item;
        $("#" + this.pageId + "sourceSubStation").val(this.modelSourceSubStation.branch);
    }

    getBookingSource() {
        this.lrDtoForBookingSourceSubStation = new LRDto();
        this.validateSourceCityDropDown = $("#" + this.pageId + "sourceAllAdvance").val();
        this.lrDtoForBookingSourceSubStation.city = this.validateSourceCityDropDown;
        this.lrDtoForBookingSourceSubStation.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.mastereadService.getBookingMainStations(this.lrDtoForBookingSourceSubStation).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.agentDtoOptionSourceSubStation = [];
                    this.sourceSubStationTA = [];
                } else {
                    this.agentDtoOptionSourceSubStation = [];
                    this.sourceSubStationTA = [];
                    this.agentDtoOptionSourceSubStation = response;
                    for (let i = 0; i < this.agentDtoOptionSourceSubStation.length; i++) {
                        this.sourceSubStationTA.push(this.agentDtoOptionSourceSubStation[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Booking Main Station Details", "warning")
            },
            () => console.log('done');
    }

    validateUpdateFinishBtn() {
        this.addConsignorDetails();
    }

    addConsignorDetails() {
        this.partyMasterDtoForSave = new PartyMasterDto();
        this.disableCftForSave = null;

        this.validateConsignorNameSave = $("#" + this.pageId + "consignorName").val();
        //this.validateConsignorId = $("#"+this.pageId+"consignorName").val();
        this.validateGstNo = $("#" + this.pageId + "gstNumber").val();
        this.validateMobileNo = $("#" + this.pageId + "mobileNumber").val();
        this.validateOfficeNo = $("#" + this.pageId + "officeNumber").val();
        this.validateEmailId = $("#" + this.pageId + "emailId").val();
        this.validateContactPerson = $("#" + this.pageId + "contactPerson").val();
        this.validateAgentName = $("#" + this.pageId + "agentName").val();
        this.validateAddress = $("#" + this.pageId + "address").val();
        this.validateArea = $("#" + this.pageId + "area").val();
        this.validateCity = $("#" + this.pageId + "city").val();
        this.validatePincode = $("#" + this.pageId + "pincode").val();

        this.partyMasterDtoForSave.consignorName = this.validateConsignorNameSave.toUpperCase();
        if (this.validateConsignorId == null || this.validateConsignorId == undefined ||
            this.validateConsignorId == "") {
            this.partyMasterDtoForSave.consignorId = 0;
        } else {
            this.partyMasterDtoForSave.consignorId = this.validateConsignorId;
        }
        this.partyMasterDtoForSave.address = this.validateAddress;
        this.partyMasterDtoForSave.area = this.validateArea;
        this.partyMasterDtoForSave.state = this.validateCity;
        this.partyMasterDtoForSave.pincode = this.validatePincode;
        this.partyMasterDtoForSave.gstNoConsignor = this.validateGstNo;
        this.disableCftForSave = $("#" + this.pageId + "checkboxCft").is(":checked");
        var mobileNoNew = null;
        for (let i = 0; i < this.returnListOfMobileNumber.length; i++) {
            if (i == 0) {
                mobileNoNew = this.returnListOfMobileNumber[i];
            } else {
                mobileNoNew += "," + this.returnListOfMobileNumber[i];
            }

            /* mobileNoNew = mobileNoNew + ","
                 + this.returnListOfMobileNumber[i].mobileNo;*/
        }
        if (mobileNoNew != null && mobileNoNew.endsWith(",")) {
            mobileNoNew = mobileNoNew.replace(", ", "");
        }

        if (mobileNoNew != null) {
            mobileNoNew = mobileNoNew.replace("null,", "");
            mobileNoNew = mobileNoNew.replace(",,", ",");
        }

        this.partyMasterDtoForSave.mobileNumber = mobileNoNew;
        this.partyMasterDtoForSave.officeNumber = this.validateOfficeNo;
        this.partyMasterDtoForSave.email = this.validateEmailId;
        this.partyMasterDtoForSave.contactPerson = this.validateContactPerson;
        this.partyMasterDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDtoForSave.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.partyMasterDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForSave.disableDefaultCft = this.disableCftForSave;
        this.partyMasterDto.isPerformanceMonitor = this.isMonitorDelPerformance;
        console.log(this.partyMasterDtoForSave);
        this.showSpinnerForAction = true;
        this.consignorService.addConsignorDetails(this.partyMasterDtoForSave).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Success", "Consignor Details Save Successfully", "success");
                    this.clearField();
                } else {
                    swal("Failed", "Failed to save consignor details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Adding Consignor Details", "warning");
            },
            () => console.log('done');
    }

    getConsignorDetailsList() {
        this.partyMasterDtoForSearch = new PartyMasterDto();
        this.validateIndexValue = null;
        this.validateCommodityDropDown = null;
        this.validateNormalSourceDropDown = null;
        this.validateSourceAdvanceDropDown = null;
        this.validateSourceSubStation = null;

        this.partyMasterDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        if (($("#" + this.pageId + "searchMode").val() == "normalSearch") &&
            ($("#" + this.pageId + "searchBy").val() == "select" || $("#" + this.pageId + "searchBy").val() == "index")) {

            this.validateIndexValue = $("#" + this.pageId + "indexId").val();
            if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
                this.validateIndexValue == "") {
                this.validateIndexValue = "A";
                this.partyMasterDtoForSearch.indexValue = this.validateIndexValue;
            } else {
                this.partyMasterDtoForSearch.indexValue = this.validateIndexValue;
            }
            this.partyMasterDtoForSearch.mode = "dummy";

            if (this.partyMasterDtoForSearch.indexValue != null &&
                this.partyMasterDtoForSearch.indexValue != undefined) {
                if (this.partyMasterDtoForSearch.indexValue == "Others") {
                    this.partyMasterDtoForSearch.mode = "IndexSplCharacters";
                } else {
                    this.partyMasterDtoForSearch.mode = "Index";
                }
            }
        } else if (($("#" + this.pageId + "searchMode").val() == "normalSearch") &&
            ($("#" + this.pageId + "searchBy").val() == "commodity")) {

            this.validateCommodityDropDown = $("#" + this.pageId + "commodityListId").val();
            this.partyMasterDtoForSearch.mode = "ByCommodity";
            this.partyMasterDtoForSearch.multipleCommodity = this.validateCommodityDropDown;
        } else if (($("#" + this.pageId + "searchMode").val() == "normalSearch") &&
            ($("#" + this.pageId + "searchBy").val() == "source")) {
            this.validateNormalSourceDropDown = $("#" + this.pageId + "sourceId").val();
            this.validateIndexValue = $("#" + this.pageId + "indexId").val();

            if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
                this.validateIndexValue == "") {
                this.validateIndexValue = "A";
                this.partyMasterDtoForSearch.indexValue = this.validateIndexValue;
            } else {
                this.partyMasterDtoForSearch.indexValue = this.validateIndexValue;
            }
            this.partyMasterDtoForSearch.office = this.validateNormalSourceDropDown;
            this.partyMasterDtoForSearch.mode = "byConsignorMainstation";

        } else if (($("#" + this.pageId + "searchMode").val() == "normalSearch") &&
            ($("#" + this.pageId + "searchBy").val() == "srcncom")) {
            this.validateCommodityDropDown = $("#" + this.pageId + "commodityListId").val();
            this.validateNormalSourceDropDown = $("#" + this.pageId + "sourceId").val();

            this.partyMasterDtoForSearch.mode = "ByCommodity";
            this.partyMasterDtoForSearch.status = "sourceAndCommodity";
            this.partyMasterDtoForSearch.multipleCommodity = this.validateCommodityDropDown;
            this.partyMasterDtoForSearch.office = this.validateNormalSourceDropDown;
        } else if ($("#" + this.pageId + "searchMode").val() == "advanceSearch") {
            this.validateSourceAdvanceDropDown = $("#" + this.pageId + "sourceAllAdvance").val();
            this.validateSourceSubStation = $("#" + this.pageId + "sourceSubStation").val();

            this.partyMasterDtoForSearch.mode = "AdvanceSearch";
            this.partyMasterDtoForSearch.mainStation = this.validateSourceAdvanceDropDown;
            this.partyMasterDtoForSearch.city = this.validateSourceSubStation;
        } else if ($("#" + this.pageId + "searchMode").val() == "searchByGst") {
            this.validateEnteredGstNo = $("#" + this.pageId + "enterGstNo").val();

            this.partyMasterDtoForSearch.mode = "GstSearch";
            this.partyMasterDtoForSearch.gstNoConsignor = this.validateEnteredGstNo;
        }
        this.showSpinnerForAction = true;
        this.consignorService.getConsignorDetailsMaster(this.partyMasterDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                this.consignorMasterDataList = [];
                $("#" + this.pageId + "consignorMasterTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.consignorMasterDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerConsignorMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Adding Consignor Details", "warning");
            },
            () => console.log('done');
    }

    rowSelectedGetConsignorDeatils(consignorNameMasterData) {
        if (this.rightsViewMonitorPerfToggle) {
            this.viewMonitorPerfToggle = true;
        } else {
            this.viewMonitorPerfToggle = false;
        }

        console.log(consignorNameMasterData);
        this.showSpinnerForAction = true;
        this.validateConsignorId = 0;
        this.consignorMobileNoList = null;
        // setTimeout(() => {
        this.validateConsignorId = consignorNameMasterData.consignorId;
        this.consignorMobileNoList = consignorNameMasterData.mobileNumber;

        $("#" + this.pageId + "consignorName").val(consignorNameMasterData.consignorName);
        $("#" + this.pageId + "gstNumber").val(consignorNameMasterData.gstNoConsignor);
        $("#" + this.pageId + "officeNumber").val(consignorNameMasterData.officeNumber);
        $("#" + this.pageId + "agentName").val(consignorNameMasterData.office);
        $("#" + this.pageId + "address").val(consignorNameMasterData.address);
        $("#" + this.pageId + "city").val(consignorNameMasterData.state);

        $("#" + this.pageId + "contactPerson").val(consignorNameMasterData.contactPerson);
        $("#" + this.pageId + "officeNumber").val(consignorNameMasterData.officeNumber);
        $("#" + this.pageId + "emailId").val(consignorNameMasterData.email);
        $("#" + this.pageId + "pincode").val(consignorNameMasterData.pincode);
        $("#" + this.pageId + "area").val(consignorNameMasterData.area);
        if (consignorNameMasterData.disableDefaultCft != null && consignorNameMasterData.disableDefaultCft) {
            $("#" + this.pageId + "checkboxCft").prop('checked', true);
        } else {
            $("#" + this.pageId + "checkboxCft").prop('checked', false);
        }
        this.isMonitorDelPerformance = consignorNameMasterData.isPerformanceMonitor;
        this.showSpinnerForAction = false;
        // }, 1000);
        this.changeDetectorRef.detectChanges();
    }

    viewMobileNumber() {
        localStorage.clear();
        localStorage.setItem('ConsignorMobileNo', JSON.stringify("ConsignorMobileNo"));
        if (this.consignorMobileNoList != null &&
            this.consignorMobileNoList != undefined &&
            this.consignorMobileNoList.length > 0) {
            localStorage.setItem('ConsignorMobileNoLocalStorage', JSON.stringify(this.consignorMobileNoList));
        }

        const dialogRef = this.dialog.open(ConsignorMultipleMobileNumberDetailsComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
            this.returnListOfMobileNumber = [];
            if (result != null && result != undefined &&
                result.length > 0) {
                let obj = JSON.parse(result);
                var dataSetConsignorMobileNo = null;
                for (let i = 0; i < obj.length; i++) {
                    this.returnListOfMobileNumber.push(obj[i].mobileNo);
                }
                console.log(dataSetConsignorMobileNo);
                console.log(this.returnListOfMobileNumber);
            } else {
                this.returnListOfMobileNumber = [];
                console.log(this.returnListOfMobileNumber);
            }
            console.log(`Dialog result: ${result}`);
        });

    }

    clearField() {
        this.index = true;
        this.commodity = false;
        this.source = false;
        this.consignorMasterDataList = [];
        $("#" + this.pageId + "consignorMasterTableId").DataTable().destroy();
        this.dtTriggerConsignorMaster.next();

        $("#" + this.pageId + "searchMode").val('normalSearch');
        $("#" + this.pageId + "searchBy").val('select');
        this.modelIndex = null;
        this.modelCommodityList = null;
        this.modelSource = null;
        this.modelSourceAllAdvance = null;
        this.modelSourceSubStation = null;

        this.sourceSubStationView = false;
        $("#" + this.pageId + "pincode").val('');
        $("#" + this.pageId + "city").val('');
        $("#" + this.pageId + "area").val('');
        $("#" + this.pageId + "address").val('');
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "contactPerson").val('');
        $("#" + this.pageId + "emailId").val('');
        $("#" + this.pageId + "officeNumber").val('');
        $("#" + this.pageId + "gstNumber").val('');
        $("#" + this.pageId + "consignorName").val('');

        this.validateConsignorNameSave = null;
        this.validateConsignorId = 0;
        this.validateGstNo = null;
        this.validateMobileNo = null;
        this.validateOfficeNo = null;
        this.validateEmailId = null;
        this.validateContactPerson = null;
        this.validateAgentName = null;
        this.validateAddress = null;
        this.validateArea = null;
        this.validateCity = null;
        this.validatePincode = null;
        this.disableCftForSave = null;
        $("#" + this.pageId + "checkboxCft").prop('checked', false);

        this.validateIndexValue = null;
        this.validateCommodityDropDown = null;
        this.validateNormalSourceDropDown = null;
        this.validateSourceCityDropDown = null;
        this.validateSourceAdvanceDropDown = null;
        this.validateSourceSubStation = null;
        this.isMonitorDelPerformance = false;
        this.viewMonitorPerfToggle = false;
        this.rightsViewMonitorPerfToggle = false;
    }
    clearBtn() {
        this.clearField();
    }
    cftSetupForConsignor(event) {
        if (this.validateConsignorId == null || this.validateConsignorId == 0) {
            swal("Not Allowed", "Please select Consignor!", "warning");
        } else {
            console.log('cftSetupForConsignor');
            if (event.target.checked) {
                console.log('cftSetupForConsignor - true');
                localStorage.clear();
                localStorage.setItem('screenname', "source");
                localStorage.setItem('cftconsignorid', this.validateConsignorId);
                const dialogRef = this.dialog.open(CflFormulaSetupConsignorComponent, {
                });

                dialogRef.afterClosed().subscribe(result => {
                    console.log(`Dialog result: ${result}`);
                    if (result == null || result == undefined || result == "Close") {
                        console.log(`Dialog result: ${result}`);
                    } else {
                        console.log(`Dialog result: ${result}`);
                    }
                });
            } else {
                console.log('cftSetupForConsignor - false');
            }
        }
    }
    onMonitorDelPerfToggleChange(event) {
        console.log(event);
        this.isMonitorDelPerformance = event.checked;
        console.log(this.isMonitorDelPerformance);
        if (this.isMonitorDelPerformance) {
            swal({
                title: "Confirm Update",
                text: "Sure you want to put this consignee to monitor",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.updatePartyMonitorPerformance(event.checked);
                } else {
                    swal.close();
                }

            })
        }
    }
    updatePartyMonitorPerformance(checked) {
        this.validateConsignorNameSave = $("#" + this.pageId + "consignorName").val();
        this.partyMasterDtoForSave = new PartyMasterDto();

        if (this.validateConsignorId == null || this.validateConsignorId == undefined ||
            this.validateConsignorId == "") {
            this.partyMasterDtoForSave.consignorId = 0;
        } else {
            this.partyMasterDtoForSave.consignorId = this.validateConsignorId;
        }
        this.partyMasterDtoForSave.isPerformanceMonitor = checked;
        this.partyMasterDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.consignorService.updatePartyPerfMonitor(this.partyMasterDtoForSave).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Success", "Consignor Details Save Successfully", "success");

                } else {
                    swal("Failed", "Failed to update consignor details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Adding Consignor Details", "warning");
            },
            () => console.log('done');
    }

}



