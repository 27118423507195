<html>

<head>
	<style>
		/* for ipad and ipad pro */
		@media (min-width : 768px) and (max-width: 1200px) {

			.col-md-3,
			.cusWidthIpadndIpadpro {
				flex: 0 0 50% !important;
				max-width: 100% !important;
			}
		}
	</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">TDS Details Component</h6>
				</div>
				<form>
					<div class="row system_responsive" style="margin-bottom: 10px;">
						<div *ngIf="showSpinnerForAction1" class="col-md-12">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div *ngIf="showSpinnerForAction2" class="col-md-12">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
										Please Wait Uploading Details.....</h6>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="card">
								<div class="card-body">
									<div class="row" style="padding: 0px;">
										<div class="col-lg-12">
											<!-- <h6 class="card-title">Tempo Details</h6> -->
											<div class="row">
												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>Certificate Number</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-file-alt"></i>
																</span>
															</div>
															<input #certificateNumber
																(change)="certNumberOnEnter($event)" type="text"
																class="form-control" aria-describedby="basic-addon11"
																id="{{pageId}}certificateNumber">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>TDS Less Discount Type</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-search"></i>
																</span>
															</div>
															<select class="custom-select col-12"
																id="{{pageId}}tdsLessDiscountType"
																name="tdsLessDiscountType"
																(change)="tdsLessOptionsMethod()"
																[(ngModel)]="selectedTdsLessDiscountType" #tdsLessDiscountType>
																<option selected value="tdsLessDiscountedInCashmemo">TDS
																	Less Discounted In Cashmemo</option>
																<option value="tdsLessNotDiscountedInCashmemo">TDS
																	Less Not Discounted In Cashmemo</option>
															</select>
															
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<!-- <div class="control"> -->
													<div class="form-group">
														<div class="input-group" id="{{pageId}}partyName">
															<label>Party Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input #consigneeName id="{{pageId}}consigneeId"
																name="consigneeName" type="text" class="form-control"
																placeholder="Select Party Name"
																(selectItem)="consigneeDropDownListner($event)"
																[(ngModel)]="modelConsigneeName"
																[ngbTypeahead]="consigneeNameSearchTA"
																[ngModelOptions]="{standalone: true}"
																[resultFormatter]="formatterConsigneeName"
																[inputFormatter]="formatterConsigneeName"
																(focus)="focusConsigneeNameTA$.next($any($event).target.value)"
																[ngModelOptions]="{standalone: true}" />
														</div>
													</div>
													<!-- </div> -->
												</div>


												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>TDS Amount</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input #tdsAmountInputField type="number"
																class="form-control" aria-describedby="basic-addon11"
																id="{{pageId}}tdsAmountInputField">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>TDS From Date</label>
															<input id="{{pageId}}fromDate" class="form-control"
																placeholder="From Date" name="fromDate" ngbDatepicker
																#fromDate="ngbDatepicker">
															<div class="input-group-append" (click)="fromDate.toggle()">
																<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>TDS To Date</label>
															<input id="{{pageId}}toDate" class="form-control"
																placeholder="To Date" name="toDate" ngbDatepicker
																#toDate="ngbDatepicker">
															<div class="input-group-append" (click)="toDate.toggle()">
																<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-12" style="text-align: center;">
													<button style="padding: 0px;" type="submit"
														class="btn btn_custom btn-success"
														id="{{pageId}}tdsAmountBtn" (click)="validateTdsButton()">TDS Button
													</button>
												</div>

												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewDifferenceAmount">
														<div class="input-group">
															<label>Difference Amount(TDS Amount-Deducted TDS
																Amount):</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11"
																id="{{pageId}}differenceAmount" readonly>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewPaymentMode">
														<div class="input-group">
															<label>Payment Mode:</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-file-alt"></i>
																</span>
															</div>
															<select class="custom-select col-12"
																name="{{pageId}}paymentMode"
																(change)="hideAndShowMethod(paymentMode.value)"
																[(ngModel)]="selectedPaymentMode"
																id="{{pageId}}paymentMode">
																<option selected value="Cash">Cash</option>
																<option value="Cheque/PDC">Cheque/PDC</option>
																<option value="NEFT/IMPS">NEFT/IMPS</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewChequeNumber">
														<div class="input-group">
															<label>Cheque Number:</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-clipboard"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11"
																id="{{pageId}}chequeNumber">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewChequeDate">
														<div class="input-group">
															<label>Cheque Date:</label>
															<input class="form-control" placeholder="Cheque Date"
																name="chequeDate" ngbDatepicker
																#chequeDate="ngbDatepicker" id="{{pageId}}chqDate">

															<div class="input-group-append"
																(click)="chequeDate.toggle()">
																<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																</span>

															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewBankName">
														<div class="input-group">
															<label>Bank Name::</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-university"></i>
																</span>
															</div>
															<input type="text" class="form-control"
																aria-describedby="basic-addon11"
																id="{{pageId}}bankName">
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewReferenceNumber">
														<div class="input-group">
															<label>Refrence Number:</label>
															<div class="input-group-append">
																<span class="input-group-text"> <i
																		class="fas fa-clipboard"></i>
																</span>
															</div>
															<input type="number" class="form-control"
																aria-describedby="basic-addon11"
																id="{{pageId}}refrenceNumber">
														</div>
													</div>
												</div>


												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label>Settlement Date</label> <input
																id="{{pageId}}settlementDate" class="form-control"
																placeholder="yyyy-mm-dd" name="settlementDate"
																ngbDatepicker #settlementDate="ngbDatepicker">
															<div class="input-group-append"
																(click)="settlementDate.toggle()">
																<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-10">
													<div class="form-group" *ngIf="viewTdsDocument">
														<div class="input-group">
															<label>TDS Document</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-file-alt"></i>
																</span>
															</div>
															<input type="text" class="form-control"
																aria-describedby="basic-addon11"
																id="{{pageId}}tdsDocument">
														</div>
														<div class="col-sm-12 col-md-12">
															<div class="form-group">
																<div class="input-group">
																	<input type="file" id="{{pageId}}tdsDocumentFileUpload"
																		(change)="onFileChangedTdsDocument($event)"/> 
																</div>
															</div>
														</div>
														<div class="col-md-12 cusWidthIpadndIpadpro"
															style="text-align: center;">
															<button style="padding: 0px;" type="submit"
																class="btn btn_custom btn-success"
																id="{{pageId}}uploadBtn" (click)="uploadFileForTdsDocumentBtnUpload()">Upload</button>
														</div>
													</div>
												</div>
												<!-- 											<div class="col-sm-12 col-md-10"> -->
												<!-- 												<div class="card"> -->
												<!-- 													<div class="card-body"> -->
												<!-- 														<h4 class="card-title">Textarea</h4> -->
												<!-- 														<form class="m-t-20"> -->
												<!-- 															<div class="form-group"> -->
												<!-- 																<textarea class="form-control" rows="3"></textarea> -->
												<!-- 															</div> -->
												<!-- 														</form> -->
												<!-- 													</div> -->
												<!-- 												</div> -->
												<!-- 											</div> -->

												<div class="col-sm-12 col-md-10">
													<div class="form-group">
														<div class="input-group">
															<label class="wt80">Remarks</label>
															<input type="text" class="form-control"
																id="{{pageId}}remarks">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class=" fas fa-hashtag"></i>
																</span>
															</div>
															<!-- <textarea class="form-control" rows="3"></textarea> -->
														</div>
													</div>

												</div>


											</div>
										</div>
									</div>
								</div>
							</div>
							<hr style="width: 80%; border-top: none; margin: 3px;">
							<div class="col-md-12" style="text-align: center;">
								<button #saveBtn type="submit" class="btn btn-success m-r-10" (click)="validateSave()"
									id="{{pageId}}saveBtn">Save</button>
								<button type="submit" class="btn btn-dark" (click)="clearAll()" id="{{pageId}}clearBtn">Clear</button>
							</div>
						</div>
						<!-- 							the second datatable starts  -->
						<div class="col-md-8 vl p-t-10">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="box-body">
								<!-- 							<h6 class="card-title border_bottom">Trip Details</h6> -->
								<div class="row" style="padding: 10px;">
									<div class="col-sm-12 col-md-3">
										<div class="form-group">
											<div class="input-group">
												<input id="{{pageId}}searchFromDate" class="form-control"
													placeholder="yyyy-mm-dd" name="searchFromDate" ngbDatepicker
													#searchFromDate="ngbDatepicker">
												<div class="input-group-append" (click)="searchFromDate.toggle()">
													<span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-3">
										<div class="form-group">
											<div class="input-group">
												<input id="{{pageId}}searchToDate" class="form-control"
													placeholder="yyyy-mm-dd" name="searchToDate" ngbDatepicker
													#searchToDate="ngbDatepicker">
												<div class="input-group-append" (click)="searchToDate.toggle()">
													<span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-2">
										<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
											(click)="getTdsDetailsList()">Search</button>
									</div>
									<div class="col-sm-12 col-md-4">
										<div class="form-group">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input"
													id="{{pageId}}tdsDocPending"> <label
													class=" custom-control-label wtfull "
													for="{{pageId}}tdsDocPending">TDS
													Doc Pending</label>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="box-body">
											<!-- 							the first datatble starts -->
											<table datatable id="{{pageId}}tdsDetailsDatatableId"
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionsTdsDetailsEntryTable"
												[dtTrigger]="dtTriggerTdsDetailsEntryTable">

												<thead>
													<tr>
														<th>Party Name</th>
														<th>TDS Certf.No.</th>
														<th>TDS Amount</th>
														<th>From Period</th>
														<th>To Period</th>
														<th>Payment Mode</th>
														<th>Bank Name</th>
														<th>Chq No.</th>
														<th>Chq.Date</th>
														<th>Ref.No</th>
														<th>Setlm.Date</th>
														<th>Remarks</th>
														<th>TDS Docs. Copy</th>
													</tr>
												</thead>
												<tbody>
													<tr
														*ngFor="let tdsDetailsEntryTableData of tdsDetailsEntryTableDataList ">
														<td>{{ tdsDetailsEntryTableData.consigneeName }}</td>
														<td>{{ tdsDetailsEntryTableData.tdsCertfNo
															}}</td>
														<td>{{ tdsDetailsEntryTableData.settlementAmt }}</td>
														<td>{{ tdsDetailsEntryTableData.fromDate }}</td>
														<td>{{ tdsDetailsEntryTableData.toDate }}</td>
														<td>{{ tdsDetailsEntryTableData.settlementMode }}</td>
														<td>{{ tdsDetailsEntryTableData.bankName }}</td>
														<td>{{ tdsDetailsEntryTableData.chequeNumber }}</td>
														<td>{{ tdsDetailsEntryTableData.chequeDate }}</td>
														<td>{{ tdsDetailsEntryTableData.refNumber }}</td>
														<td>{{ tdsDetailsEntryTableData.settlementDate }}</td>
														<td>{{ tdsDetailsEntryTableData.remarks }}</td>
														<!-- <td>{{ tdsDetailsEntryTableData.imgURLTDS }}</td> -->
														<td>
															<a style='cursor: pointer; color: blue;' (click)="getTdsDocImg(tdsDetailsEntryTableData);" class="pointer">
																<u>{{ tdsDetailsEntryTableData.imgURLTDS == null ?
																	"TDS Not
																	Uploaded" : "View Tds Document"
																	}}</u>
															</a>
														</td>
													</tr>


													<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</form>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>