import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

@Component({
    selector: 'app-commodity-report',
    templateUrl: './commodity-report.component.html',
    styleUrls: ['./commodity-report.component.css']
})
export class CommodityReportComponent implements OnInit {
    summaryDataList: any;
    detailDataList: any;
    model: NgbDateStruct;
    loadingIndicator = true;
    viewTypeSummary = true;
    viewTypeDetail = false;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerSummary: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsSummary: any;
    dtOptionDetail: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    showSpinnerForAction = false;

    hideshowCommoditySummaryRptTable = false;
    hideshowCommodityDetailsRptTable = false;

    lrDto: LRDto = new LRDto();


    hideshowDropDownSourceField = false;
    hideshowDropDownDestinationField = false;
    //hideshowDropDownCommodityListField = false;

    sourceAll: boolean = false;
    sourceSpecific: boolean = false;
    destination: boolean = false;
    city: boolean = false;
    superAdminView: boolean = false;
    mode: string = null;

    toDatesModal: any = null;
    fromDatesModal: any = null;
    validateSourceSubStationValue: any = null;
    //validateDestinationValue: any = null;
    validateCommodityListValue: any = null;

    validateFromDateValue: any = null;
    valiateToDateValue: any = null;
    validateSourceValue: any = null;
    validateDestinationValue: any = null;
    validateCommodityNammeValue: any = null;

    listOfSource = new Array<String>();


    rightsForRole = "rights for Booking Administrator";

    //
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    footerTotalLRs: any;
    footerTotalArticle: any;
    footerActWgt: any;
    footerChgWgt: any;
    footerToPay: any;
    footerPaid: any;
    footerRiskChg: any;
    printFromDate: any;
    printToDate: any;
    printSource: any;
    printDest: any;
    viewCustomPrintV1: boolean = false;
    viewCustomPrintV2: boolean = false;
    pageId = "cmdrc";

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private lrService: LrService,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.hideshowCommoditySummaryRptTable = true;


            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "cbSourceAll-CommRpt") {
                        this.hideshowDropDownSourceField = true;
                        this.sourceAll = true;
                        this.mode = "All";
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "cbSourceSpecific-CommRpt") {
                        this.hideshowDropDownSourceField = true;
                        this.sourceSpecific = true;
                        this.mode = "specific";
                        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "cbDest-CommRpt") {
                        this.hideshowDropDownDestinationField = true;
                        this.destination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "City CommRpt") {
                        this.city = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CommodityRpt ShowAll") {
                        this.superAdminView = true;// superadmin only 
                    }
                    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
                }
            }

            this.getDestinationDetails();
            this.getSourceStationDetails();
            this.getCommodityMasterDetailsList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsSummary = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 330,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(2).footer()).html(articles);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(7).footer()).html(toPay);
                $(api.column(8).footer()).html(paid);
                $(api.column(9).footer()).html(fov);
            }
            //scroll in datatable ends
        }, this.dtOptionDetail = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 330,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var count = 0;
                var lrs = 0;
                var art = 0;
                var actWgt = 0;
                var chgWgt = 0;
                var topay = 0;
                var paid = 0;
                var fov = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {
                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total-" + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": lrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": art
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + topay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))
                                        .prop('outerHTML'));

                            count = 0;
                            lrs = 0;
                            art = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            topay = 0;
                            paid = 0;
                            fov = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 10,
                                                "text": (group)
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    lrs += +val[2];
                    art += +val[3];
                    actWgt += +val[4];
                    chgWgt += +val[6];
                    topay += +val[8];
                    paid += +val[9];
                    fov += +val[10];
                    if (i == (rows.length - 1)) {
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total-" + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": lrs
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": art
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": actWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": chgWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": topay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": fov
                                        }))
                                    .prop('outerHTML'));
                        count = 0;
                        lrs = 0;
                        art = 0;
                        actWgt = 0;
                        chgWgt = 0;
                        topay = 0;
                        paid = 0;
                        fov = 0;
                    }
                });
            },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var articles = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(3).footer()).html(articles);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(6).footer()).html(chgWgt);
                $(api.column(8).footer()).html(toPay);
                $(api.column(9).footer()).html(paid);
                $(api.column(10).footer()).html(fov);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerSummary.next();
        //this.dtTriggerDetail.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerSummary.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }


    viewTypeMode(viewType: string) {
        if (viewType === 'summary') {
            this.hideshowCommoditySummaryRptTable = true;
            this.hideshowCommodityDetailsRptTable = false;

            $("#" + this.pageId + "commoditySummaryRptTableId").DataTable().destroy();
            this.summaryDataList = [];
            this.dtTriggerSummary.next();

            this.lrDto.reportMode = "item";
            this.modelDestination = null;
            $("#" + this.pageId + "destinationId").val('All');
            this.modelSource = null;
            $("#" + this.pageId + "sourceId").val('All');
            this.modelCommodityList = null;
            $("#" + this.pageId + "commodityListId").val('All');
            this.toDatesModal = null;
            this.fromDatesModal = null;

            this.hideshowDropDownSourceField = true;
            if (this.destination == true) {
                this.hideshowDropDownDestinationField = true;
            } else {
                this.hideshowDropDownDestinationField = false;
            }
        } else if (viewType === 'detail') {
            this.hideshowCommoditySummaryRptTable = false;
            this.hideshowCommodityDetailsRptTable = true;

            $("#" + this.pageId + "commodityDetailsRptTableId").DataTable().destroy();
            this.detailDataList = [];
            this.dtTriggerDetail.next();

            this.lrDto.reportMode = "detail";
            this.modelDestination = null;
            $("#" + this.pageId + "destinationId").val('All');
            this.modelSource = null;
            $("#" + this.pageId + "sourceId").val('All');
            this.modelCommodityList = null;
            $("#" + this.pageId + "commodityListId").val('All');
            this.toDatesModal = null;
            this.fromDatesModal = null;

            this.hideshowDropDownSourceField = true;
            if (this.destination == true) {
                this.hideshowDropDownDestinationField = true;
            } else {
                this.hideshowDropDownDestinationField = false;
            }
        }
    }

    viewReportTypeMode(viewType: string) {
        if (viewType === 'old') {
            $("#" + this.pageId + "fromDates").val();
            $("#" + this.pageId + "toDates").val('');
            this.toDatesModal = null;
            this.fromDatesModal = null;
        } else if (viewType === 'new') {
            $("#" + this.pageId + "fromDates").val();
            $("#" + this.pageId + "toDates").val('');
            this.toDatesModal = null;
            this.fromDatesModal = null;
        }
    }

    validateClearBtn() {
        $("#" + this.pageId + "viewType").val('summary');
        this.clearField();
    }


    clearField() {
        this.hideshowCommoditySummaryRptTable = true;
        this.hideshowCommodityDetailsRptTable = false;
        this.detailDataList = [];
        $("#" + this.pageId + "commoditySummaryRptTableId").DataTable().destroy();
        this.summaryDataList = [];
        this.dtTriggerSummary.next();

        this.toDatesModal = null;
        this.fromDatesModal = null;
        this.validateSourceSubStationValue = null;
        this.validateDestinationValue = null;
        this.validateCommodityListValue = null;
        this.validateFromDateValue = null;
        this.valiateToDateValue = null;
    }

    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
        //this.validateSourceSubStationValue = this.modelSource.subStations;

    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        // this.validateDestinationIdValue = this.modelDestination.destination;
    }

    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
        //this.validateCommodityListValue = this.modelCommodityList.commodityName
    }


    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    this.lrDtoDestinationAllOption.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAllOption);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    $("#" + this.pageId + "destinationId").val('All');
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.reportMode = "InclMainStation";
        console.log(this.superAdminView);
        if (this.superAdminView == true) {
            this.lrDtoSourceStation.mode = "Booking";
        } else {
            //if(this.rightsForRole !=null && this.rightsForRole == "rights for Booking Administrator"){
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainStation;
            } else {
                this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
    }
    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.listOfSource = [];
                    this.sourceStationOptions = response;
                    this.lrDtoSourceStationAllOption.subStations = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    // Create instances of LRDto for each hardcoded value

                    let lrDtoNonDelhi = new LRDto();
                    lrDtoNonDelhi.subStations = "Non Delhi";
                    let lrDtoDelhiNonDelhi = new LRDto();
                    lrDtoDelhiNonDelhi.subStations = "Delhi & Non Delhi";


                    this.sourceSubStationNameTA.push(lrDtoNonDelhi);
                    this.sourceSubStationNameTA.push(lrDtoDelhiNonDelhi);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                        this.listOfSource.push(this.sourceStationOptions[i].source);
                    }
                    $("#" + this.pageId + "sourceId").val('All');
                    this.showSpinnerForAction = false;
                } else {
                    this.sourceSubStationNameTA = [];
                    this.lrDtoSourceStationAllOption.subStations = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    $("#" + this.pageId + "sourceId").val('All');
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');

    };

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.destination = null;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.status = "Working";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption.commodityName = 'All';
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        $("#" + this.pageId + "commodityListId").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    validateSearchBtn() {
        if ($("#" + this.pageId + "viewType").val() == "summary") {
            this.searchCommoditySummaryRpt();
        } else if ($("#" + this.pageId + "viewType").val() == "detail") {
            this.searchCommodityDetailRpt();
        }
    }

    searchCommoditySummaryRpt() {
        this.lrDto = new LRDto();
        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateSourceValue = $("#" + this.pageId + "sourceId").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        this.validateCommodityNammeValue = $("#" + this.pageId + "commodityListId").val();

        if ($("#" + this.pageId + "fromDates").val() != null && $("#" + this.pageId + "fromDates").val() != undefined &&
            $("#" + this.pageId + "toDates").val() != null && $("#" + this.pageId + "toDates").val() != undefined) {
            this.lrDto.fromDate = this.validateFromDateValue;
            this.lrDto.toDate = this.valiateToDateValue;
        }

        if (this.sourceSpecific && !this.sourceAll) {
            this.lrDto.mode = "specific";
            this.lrDto.list = this.userDataDtoReturnSession.stationList;
            this.lrDto.source = this.validateSourceValue;
        } else if (this.sourceAll && !this.sourceSpecific) {
            this.lrDto.mode = "ALL";
            this.lrDto.source = this.validateSourceValue;
        } else if (!this.sourceAll && !this.sourceSpecific) {
            this.lrDto.mode = "ALL";
            this.lrDto.source = this.userDataDtoReturnSession.mainStation;
        }

        if (!this.sourceAll && !this.sourceSpecific) {
            this.lrDto.source = this.userDataDtoReturnSession.mainStation;
            this.lrDto.mode = "specific";
        } else {
            if ($("#" + this.pageId + "sourceId").val() == "All") {
                this.lrDto.mode = "ALL";
                this.lrDto.list = this.listOfSource;
            } else {
                this.lrDto.mode = "specific";
                this.lrDto.source = this.validateSourceValue;
            }
        }

        if ($("#" + this.pageId + "sourceId").val() == "Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = this.validateSourceValue;
        } else if ($("#" + this.pageId + "sourceId").val() == "Mumbai") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = this.validateSourceValue;
        } else if ($("#" + this.pageId + "sourceId").val() == "Non Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = "Non Delhi";
        } else if ($("#" + this.pageId + "sourceId").val() == "Delhi & Non Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = "Delhi & Non Delhi";
        }

        if (this.destination) {
            this.lrDto.destination = this.validateDestinationValue;
        } else {
            this.lrDto.destination = this.userDataDtoReturnSession.mainStation;
        }

        this.lrDto.commodityName = this.validateCommodityNammeValue;

        if ($("#" + this.pageId + "viewReportType").val() == "old") {
            this.lrDto.id = 0;
        } else if ($("#" + this.pageId + "viewReportType").val() == "new") {
            this.lrDto.id = 1;
        } else if ($("#" + this.pageId + "viewReportType").val() == "actual") {
            this.lrDto.id = 2;
        }
        this.showSpinnerForAction = true;
        this.lrService.getCommodityItemWiseReport(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.summaryDataList = [];
                $("#" + this.pageId + "commoditySummaryRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.summaryDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerSummary.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Commodity ItemWise Details", "info");
            },
            () => console.log('done');
    }

    searchCommodityDetailRpt() {
        this.lrDto = new LRDto();
        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateSourceValue = $("#" + this.pageId + "sourceId").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        this.validateCommodityNammeValue = $("#" + this.pageId + "commodityListId").val();

        if ($("#" + this.pageId + "fromDates").val() != null && $("#" + this.pageId + "fromDates").val() != undefined &&
            $("#" + this.pageId + "toDates").val() != null && $("#" + this.pageId + "toDates").val() != undefined) {
            this.lrDto.fromDate = this.validateFromDateValue;
            this.lrDto.toDate = this.valiateToDateValue;
        }

        if (this.sourceSpecific && !this.sourceAll) {
            this.lrDto.mode = "specific";
            this.lrDto.list = this.userDataDtoReturnSession.stationList;
            this.lrDto.source = this.validateSourceValue;
        } else if (this.sourceAll && !this.sourceSpecific) {
            this.lrDto.mode = "ALL";
            this.lrDto.source = this.validateSourceValue;
        } else if (!this.sourceAll && !this.sourceSpecific) {
            this.lrDto.mode = "ALL";
            this.lrDto.source = this.userDataDtoReturnSession.mainStation;
        }

        if (!this.sourceAll && !this.sourceSpecific) {
            this.lrDto.source = this.userDataDtoReturnSession.mainStation;
            this.lrDto.mode = "specific";
        } else {
            if ($("#" + this.pageId + "sourceId").val() == "All") {
                this.lrDto.mode = "ALL";
                this.lrDto.list = this.listOfSource;
            } else {
                this.lrDto.mode = "specific";
                this.lrDto.source = this.validateSourceValue;
            }
        }

        if ($("#" + this.pageId + "sourceId").val() == "Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = this.validateSourceValue;
        } else if ($("#" + this.pageId + "sourceId").val() == "Mumbai") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = this.validateSourceValue;
        } else if ($("#" + this.pageId + "sourceId").val() == "Non Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = "Non Delhi";
        } else if ($("#" + this.pageId + "sourceId").val() == "Delhi & Non Delhi") {
            this.lrDto.mode = "batchSource";
            this.lrDto.source = "Delhi & Non Delhi";
        }

        if (this.destination) {
            this.lrDto.destination = this.validateDestinationValue;
        } else {
            this.lrDto.destination = this.userDataDtoReturnSession.mainStation;
        }

        this.lrDto.commodityName = this.validateCommodityNammeValue;
        if ($("#" + this.pageId + "viewReportType").val() == "old") {
            this.lrDto.id = 0;
        } else if ($("#" + this.pageId + "viewReportType").val() == "new") {
            this.lrDto.id = 1;
        } else if ($("#" + this.pageId + "viewReportType").val() == "actual") {
            this.lrDto.id = 2;
        }
        this.showSpinnerForAction = true;
        this.lrService.getCommodityItemWiseDetailsReport(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.detailDataList = [];
                $("#" + this.pageId + "commodityDetailsRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.detailDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerDetail.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Commodity ItemWise Details", "info");
            },
            () => console.log('done');
    }

    customPrintCommodityRptSummary() {
        if (this.summaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            //this.summaryDataList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Item", "LRs", "Art",
                "Actual Weight", "% Of Act Wt", "Charged Weight", "% of Chg Wt",
                "To Pay", "Paid", "F O V"];

            this.cashMemoDtoForCustomPrintListColumnWidths = [15, 5, 10, 10, 10, 10, 10, 10, 10, 10];
            this.cashMemoDtoForCustomPrintList = [];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            //footer 
            this.footerTotalLRs = this.footerTotalArticle = this.footerActWgt = 0;
            this.footerChgWgt = this.footerToPay = this.footerPaid = this.footerRiskChg = 0;

            for (let i = 0; i < this.summaryDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues =
                    [this.summaryDataList[i].column2,
                    this.summaryDataList[i].totalLrs,
                    this.summaryDataList[i].totalArticles,
                    this.summaryDataList[i].actualWeight,
                    this.summaryDataList[i].actWtPercent,
                    this.summaryDataList[i].chargedWeight,
                    this.summaryDataList[i].chgWtPercent,
                    this.summaryDataList[i].toPay,
                    this.summaryDataList[i].paid,
                    this.summaryDataList[i].riskCharge
                    ];

                this.footerTotalLRs = +this.footerTotalLRs + this.summaryDataList[i].totalLrs;
                this.footerTotalArticle = +this.footerTotalArticle + this.summaryDataList[i].totalArticles;
                this.footerActWgt = +this.footerActWgt + this.summaryDataList[i].actualWeight;
                this.footerChgWgt = +this.footerChgWgt + this.summaryDataList[i].chargedWeight;
                this.footerToPay = +this.footerToPay + this.summaryDataList[i].toPay;
                this.footerPaid = +this.footerPaid + this.summaryDataList[i].paid;
                this.footerRiskChg = +this.footerRiskChg + this.summaryDataList[i].riskCharge;

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }

            this.cashMemoDtoForCustomPrintDataSummaryList =
                ["Total : " + this.summaryDataList.length,
                this.footerTotalLRs, this.footerTotalArticle,
                this.footerActWgt, "", this.footerChgWgt, "",
                this.footerToPay, this.footerPaid, this.footerRiskChg];

            //heading logics For Date
            this.printFromDate = $("#" + this.pageId + "fromDates").val();
            this.printToDate = $("#" + this.pageId + "toDates").val();
            this.printSource = $("#" + this.pageId + "sourceId").val();
            this.printDest = $("#" + this.pageId + "destinationId").val();

            //this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#" + this.pageId + "fromDates").val(), $("#" + this.pageId + "toDates").val()];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [$("#" + this.pageId + "sourceId").val(), $("#" + this.pageId + "destinationId").val()];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }



            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Commodity Report (Item Wise)");

            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    customPrintCommodityRptDetails() {
        localStorage.clear();
        localStorage.setItem('printCommodityDetailsPrintData', JSON.stringify(this.lrDto));
        this.viewCustomPrintV2 = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCustomPrintV2) {
                this.viewCustomPrintV2 = false;
                localStorage.clear();
            }
        });
    }
}
