import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { FormControl } from '@angular/forms';
import { Observable, Subject, merge } from 'rxjs';
import { map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { DatePipe } from "@angular/common";
import { MemoService } from "src/app/dataService/memo-service";
import *  as moment from 'moment';
import { UserDataDto } from 'src/app/dto/UserData-dto';

@Component({
    selector: 'app-dummy-cashmemo',
    templateUrl: './dummy-cashmemo.component.html',
    styleUrls: ['./dummy-cashmemo.component.css']
})
export class DummyCashmemoComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    unloadingChgViewe = false;
    hamaliGDView = false;
    hamaliDDView = false;
    //stationRights = "dummy cashmemo show unloading Charge";
    rightsForDummyCashmemo = "Rights For Dummy Cashmemo";
    viewUnloadingCharge = false;
    inLaserPrint = false;
    lrDtoMsg: LRDto = new LRDto();
    messageList: any;
    message: string;
    printShow = false;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    stateDetailsList: any;

    gstAmt: number;
    gstRoundedAmt: number;
    tempraverDateHardCoded: any;
    subTotal: any;
    selectedDeliveryMode: any;

    subTotalValue: number;
    gstTotalAmt: number;
    lrDtoDest: LRDto = new LRDto();
    rateMasterDto: RateMasterDto = new RateMasterDto();
    createdDummyCashmemoDetails: any;
    createdDummyCashmemo: RateMasterDto = new RateMasterDto()

    serviceTaxAmountCalc: any;
    //for save starts
    amountLimit: any
    enteredMemoNumber: any;
    enteredLrNumber: any;
    selectedDestination: any;
    enteredBookingDate: any;
    enteredInvoiceNumber: any;
    enteredInvoiceDate: any;
    enteredCommodity: any;
    enteredSourceStation: any;
    enteredAgent: any;
    enteredNoOfArticle: any;
    enteredActualWeight: any;
    enteredChargedWeight: any;
    enteredConsignorName: any;
    enteredConsignorAddress: any;
    selectedConsignorState: any;
    enteredConsignorStateCode: any;
    enteredConsignorGstNumber: any;
    enteredConsignorMobileNumber: any;
    enteredConsigneeName: any;
    enteredConsigneeAddress: any;
    selectedConsigneeState: any;
    enteredConsigneeStateCode: any;
    enteredConsigneeGstNumber: any;
    enteredConsigneeMobileNumber: any;
    enteredToPay: any;
    enteredPaid: any
    enteredLocalCharge: any;
    enteredHamaliDd: any;
    enteredHamaliGd: any;
    enteredUnloadingCharges: any
    enteredReceiptCharge: any;
    enteredServiceCharge: any;
    enteredDemurrage: any;
    enteredDiscount: any;
    enteredSubTotal: any;
    enteredGrandTotal: any;
    enteredCgst: any;
    enteredCgstAmt: any;
    enteredSgst: any;
    enteredSgstAmt: any;
    enteredIgst: any;
    enteredIgstAmt: any;
    enteredGstTotal: any;
    enteredCollectionMan: any;
    enteredArea: any;
    enteredGodown: any;
    enteredOtherCharge: any;
    todayDate: any;
    enteredMemoDate: any;
    //for save ends


    controlDestination = new FormControl();
    controlConsignorState = new FormControl();
    controlConsigneeState = new FormControl();


    //for datepicker starts
    model: NgbDateStruct;
    model2;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    partyMasterDtoState: PartyMasterDto = new PartyMasterDto();

    consigneeStateOptions: PartyMasterDto[];
    public modelConsigneeStateName: any;
    consigneeStateNameTA: Array<PartyMasterDto> = [];
    focusConsigneeStateNameTA$ = new Subject<string>();
    searchConsigneeStateName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeStateNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeStateNameTA
                : this.consigneeStateNameTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeStateName = (x: { state: string }) => x.state;

    consignorStateOptions: PartyMasterDto[];
    public modelConsignorStateName: any;
    consignorStateNameTA: Array<PartyMasterDto> = [];
    focusConsignorStateNameTA$ = new Subject<string>();
    searchConsignorStateName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorStateNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorStateNameTA
                : this.consignorStateNameTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorStateName = (x: { state: string }) => x.state;

    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoCollectionManAddNew: UserDataDto = new UserDataDto();
    userDataDtoOptions: UserDataDto[];
    public modelCollectionMan: any;
    modelCollectionManTA: Array<UserDataDto> = [];
    focusCollectionManTA$ = new Subject<string>();
    searchCollectionMan = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelCollectionManTA
                : this.modelCollectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;


    validateEnteredLrNumber: any = null;
    lrDtoForGetLrNoDtls: LRDto = new LRDto();
    responseOfGetLrNumberDtls: any;
    returnLrNumberDtls: LRDto = new LRDto();
    viewNewCollectionMans = false;
    bookingDatesModal: any = null;
    memoDatesModal: any = null;
    invoiceDatesModal: any = null;
    viewCashMemoPrint = false;
    pageId = "dumcc";
    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router, private datePipe: DatePipe, private memoService: MemoService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.inLaserPrint = this.userDataDtoReturnSession.inLaserCashMemo;

            /*if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "") {
                    }

                }
            }*/
            console.log(this.userDataDtoReturnSession.mainStation);
            //its applicable only for chennai
            // if (this.rightsForDummyCashmemo == "Rights For Dummy Cashmemo") {
            if (this.userDataDtoReturnSession.mainStation != null &&
                this.userDataDtoReturnSession.mainStation == "Chennai") {
                this.viewUnloadingCharge = true;
                console.log("if");
            } else {
                this.viewUnloadingCharge = false;
                console.log("else");
            }

            if (this.viewUnloadingCharge == true) {
                this.unloadingChgViewe = true;
                this.hamaliGDView = false;
                this.hamaliDDView = false;
            } else {
                this.unloadingChgViewe = false;
                this.hamaliGDView = true;
                this.hamaliDDView = true;
            }
            this.getMessageInPrint();
            this.getStateDetails();
            this.getDestinationDetails();
            this.getCollectionManDetailsList();
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.amountLimit = this.userDataDtoReturnSession.serviceTaxAmt;
            this.tempraverDateHardCoded = null;

        }
    }

    getConsignorDtlsMtd(option) {
        $("#" + this.pageId + "consignorStateCode").val(option.code);
        $("#" + this.pageId + "consignorStateCode").focus();
    }

    getConsigneeDetailsMtd(data) {
        $("#" + this.pageId + "consigneeStateCode").val(data.code);
        $("#" + this.pageId + "consigneeStateCode").focus();
    }
    ngOnInit() {

    }
    deliveryMethod(deliveryTypes: string) {
        if (deliveryTypes === 'Door Delivery') {
            if (this.viewUnloadingCharge == true) {
                this.hamaliGDView = false;
                this.hamaliDDView = true;
            } else {
                this.hamaliGDView = true;
                this.hamaliDDView = true;
            }

        } else {
            if (this.viewUnloadingCharge == true) {
                this.hamaliGDView = false;
                this.hamaliDDView = false;
            } else {
                this.hamaliGDView = true;
                this.hamaliDDView = true;
            }

        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) {
            el.focus();
        }
    }

    toPayFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "paid").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();
            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    //    we have introduce paidFocus
    paidFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "localCharge").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();
            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    localChrgFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            //            el.focus();
            if (this.viewUnloadingCharge == true && this.selectedDeliveryMode == "Door Delivery") {
                this.setSubTotalChennai();
                $("#" + this.pageId + "hamaliDd").focus();
            } else if (this.viewUnloadingCharge == true && this.selectedDeliveryMode == "Godown Delivery") {
                this.setSubTotalChennai();
                $("#" + this.pageId + "unloadingCharges").focus();
            }
            else {
                this.setSubTotal();
                $("#" + this.pageId + "hamaliDd").focus();
            }
            this.logicForGST();
        }
    }
    localChrgBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (this.viewUnloadingCharge == true && this.selectedDeliveryMode == "Door Delivery") {
            this.setSubTotalChennai();
            //$("#"+this.pageId+"hamaliDd").focus();
        } else if (this.viewUnloadingCharge == true && this.selectedDeliveryMode == "Godown Delivery") {
            this.setSubTotalChennai();
            //$("#"+this.pageId+"unloadingCharges").focus();
        }
        else {
            this.setSubTotal();
            // $("#"+this.pageId+"hamaliDd").focus();
        }
        this.logicForGST();
    }
    hamaliDdFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            //            el.focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();
                $("#" + this.pageId + "unloadingCharges").focus();
            } else {
                this.setSubTotal();
                $("#" + this.pageId + "hamaliGd").focus();
            }
            this.logicForGST();
        }
    }
    hamaliDdBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();
            //$("#"+this.pageId+"unloadingCharges").focus();
        } else {
            this.setSubTotal();
            //$("#"+this.pageId+"hamaliGd").focus();
        }
        this.logicForGST();
    }

    unloadingChgFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            this.setSubTotalChennai();
            $("#" + this.pageId + "reciptCharges").focus();
            this.logicForGST();
        }
    }

    unloadingChgBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        this.setSubTotalChennai();
        //$("#"+this.pageId+"reciptCharges").focus();
        this.logicForGST();
    }

    hamaliGdFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "reciptCharges").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    hamaliGdBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        //$("#"+this.pageId+"reciptCharges").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }

    receiptChgFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "serviceCharges").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }

    receiptChgBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        //$("#"+this.pageId+"serviceCharges").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }

    serviceChgFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "demurrage").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    serviceChgBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        //$("#"+this.pageId+"demurrage").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }

    demurrageFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "others").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    demurrageBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        //$("#"+this.pageId+"others").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }

    discountFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "collectionManId").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }

    discountBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        // $("#"+this.pageId+"collectionManId").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }


    // added @19-01-2021
    othersFocus(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        if (e.keyCode == 13) {
            $("#" + this.pageId + "discount").focus();
            if (this.viewUnloadingCharge == true) {
                this.setSubTotalChennai();

            } else {
                this.setSubTotal();
            }
            this.logicForGST();
        }
    }
    othersBlur(e) {
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        //$("#"+this.pageId+"discount").focus();
        if (this.viewUnloadingCharge == true) {
            this.setSubTotalChennai();

        } else {
            this.setSubTotal();
        }
        this.logicForGST();
    }

    bookingDateFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "bookingDates").toggle();
            $("#" + this.pageId + "invoiceNumber").focus();
        }
    }
    invoiceDateFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "invoiceDates").toggle();
            $("#" + this.pageId + "commodity").focus();
        }
    }


    getMethodForgetMessageInPrint() {
        this.lrDtoMsg.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoMsg.mainstation = this.userDataDtoReturnSession.mainStation;
    }
    getMessageInPrint() {
        this.getMethodForgetMessageInPrint();
        this.masterReadService.getMsgDispInCMService(this.lrDtoMsg).subscribe(
            (response) => {
                if (response) {
                    //                    console.log( "Before Details >> " + this.message + " And " + this.printShow );
                    this.messageList = response;
                    this.message = this.messageList.message;
                    this.printShow = this.messageList.isLrInCp;
                    //                    console.log( "After Details >> " + this.message + " And " + this.printShow );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Message In Print Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }




    setSubTotal() {
        this.enteredToPay = $("#" + this.pageId + "toPay").val();
        this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
        this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
        this.enteredReceiptCharge = $("#" + this.pageId + "reciptCharges").val();
        this.enteredServiceCharge = $("#" + this.pageId + "serviceCharges").val();
        this.enteredDemurrage = $("#" + this.pageId + "demurrage").val();
        this.enteredDiscount = $("#" + this.pageId + "discount").val();

        this.enteredOtherCharge = $("#" + this.pageId + "others").val();
        //        this.subTotal = $("#"+this.pageId+"subTotal" ).val();

        this.subTotalValue = +this.enteredToPay + +this.enteredLocalCharge + +this.enteredHamaliGd + +this.enteredReceiptCharge + +this.enteredServiceCharge + +this.enteredDemurrage + +this.enteredOtherCharge + -this.enteredDiscount;

        //        console.log( this.subTotalValue );
        //        console.log( "the calculation" + +this.enteredToPay + +this.enteredLocalCharge + +this.enteredHamaliGd + +this.enteredReceiptCharge + +this.enteredServiceCharge + +this.enteredDemurrage + -this.enteredDiscount );
        //        this.subTotal = $("#"+this.pageId+"subTotal" ).val( this.subTotalValue );

        $("#" + this.pageId + "grandTotal").val(this.subTotalValue);
        //Service Tax coded is not created 

    }



    setSubTotalChennai() {
        this.enteredToPay = $("#" + this.pageId + "toPay").val();
        this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
        this.enteredUnloadingCharges = $("#" + this.pageId + "unloadingCharges").val();
        this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
        this.enteredReceiptCharge = $("#" + this.pageId + "reciptCharges").val();
        this.enteredServiceCharge = $("#" + this.pageId + "serviceCharges").val();
        this.enteredDemurrage = $("#" + this.pageId + "demurrage").val();
        this.enteredDiscount = $("#" + this.pageId + "discount").val();
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        this.enteredOtherCharge = $("#" + this.pageId + "others").val();

        if (this.selectedDeliveryMode != "Door Delivery") {
            this.enteredHamaliDd = '0';
        } else {
            this.enteredHamaliDd;
        }
        //                this.subTotal = $("#"+this.pageId+"subTotal" ).val();

        //this.subTotalValue = +this.enteredToPay + +this.enteredLocalCharge + +this.enteredUnloadingCharges + +this.enteredHamaliDd + +this.enteredReceiptCharge + +this.enteredServiceCharge + +this.enteredDemurrage + -this.enteredDiscount;
        this.subTotalValue = +this.enteredToPay + +this.enteredLocalCharge + +this.enteredUnloadingCharges + +this.enteredHamaliDd + +this.enteredReceiptCharge + +this.enteredServiceCharge + +this.enteredDemurrage + +this.enteredOtherCharge + -this.enteredDiscount;

        //        console.log( this.subTotalValue );
        //        console.log( "the calculation" + +this.enteredToPay + +this.enteredLocalCharge + +this.enteredUnloadingCharges + +this.enteredHamaliDd + +this.enteredReceiptCharge + +this.enteredServiceCharge + +this.enteredDemurrage + -this.enteredDiscount );
        //        this.subTotal = $("#"+this.pageId+"subTotal" ).val( this.subTotalValue );

        $("#" + this.pageId + "grandTotal").val(this.subTotalValue);
        //Service Tax coded is not created 
        //        if ( this.subTotal >= this.amountLimit ) {
        //            this.serviceTaxAmountCalc = ( this.subTotal * this.amountLimit ) / 100;
        //        }
    }

    logicForGST() {

        this.enteredPaid = $("#" + this.pageId + "paid").val();
        this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();

        if (this.enteredPaid == null || this.enteredPaid == 0 || this.enteredPaid == 0.0) {
            //            console.log( "greater than amt>> " + this.amountLimit );
            if ((this.enteredGrandTotal) >= (this.amountLimit)) {
                this.gstAmt = (this.enteredGrandTotal * 5) / 100;
                //                console.log( "the Gst Amount>> " + this.gstAmt );
                //                this.enteredIgst = '5';
                //                this.enteredCgst = '0';
                //                this.enteredSgst = '0';
                //
                //                this.enteredIgstAmt = this.gstAmt;
                //                this.enteredCgstAmt = '0';
                //                this.enteredSgstAmt = '0';
                this.gstRoundedAmt = Math.round(this.gstAmt);
                $("#" + this.pageId + "igst").val(5);
                $("#" + this.pageId + "cgst").val(0);
                $("#" + this.pageId + "sgst").val(0);
                $("#" + this.pageId + "igstAmt").val(this.gstRoundedAmt);
                $("#" + this.pageId + "cgstAmt").val(0);
                $("#" + this.pageId + "sgstAmt").val(0);

            }
        } else {
            if (this.enteredGrandTotal >= this.amountLimit) {
                this.gstAmt = (this.enteredGrandTotal * 2.5) / 100;
                //                console.log( "the Gst Amount>> " + this.gstAmt );
                //                this.enteredCgst = '2.5';
                //                this.enteredSgst = '2.5';
                //                this.enteredIgst = '0';
                //                this.enteredCgstAmt = this.gstAmt;
                //                this.enteredSgstAmt = this.gstAmt;
                //                this.enteredIgstAmt = '0';
                this.gstRoundedAmt = Math.round(this.gstAmt);
                $("#" + this.pageId + "cgst").val(2.5);
                $("#" + this.pageId + "sgst").val(2.5);
                $("#" + this.pageId + "igst").val(0);
                $("#" + this.pageId + "cgstAmt").val(this.gstRoundedAmt);
                $("#" + this.pageId + "sgstAmt").val(this.gstRoundedAmt);
                $("#" + this.pageId + "igstAmt").val(0);


            }
        }

        this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
        this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
        this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
        //        Math.round( this.enteredCgstAmt );
        //        Math.round( this.enteredSgstAmt );
        //        Math.round( this.enteredIgstAmt );

        this.gstTotalAmt = +this.enteredCgstAmt + +this.enteredSgstAmt + +this.enteredIgstAmt;
        $("#" + this.pageId + "gstTotal").val(this.gstTotalAmt);
        //        console.log( "grand total GST>> " + +this.enteredCgstAmt + +this.enteredSgstAmt + +this.enteredIgstAmt );
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    clickListnerForConsignorStateName(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsignorStateName = e.item;
        //console.log(this.modelConsignorStateName);
        $("#" + this.pageId + "consignorStateNameId").val(this.modelConsignorStateName.state);
        //$("#"+this.pageId+"consignorStateCode").val(this.modelConsignorStateName.stateCode);
    }
    clickListnerForConsigneeStateName(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsigneeStateName = e.item;
        $("#" + this.pageId + "consigneeStateNameId").val(this.modelConsigneeStateName.state);
        // $("#"+this.pageId+"consigneeStateCode").val(this.modelConsigneeStateName.stateCode);
    }
    clickListnerForCollectionMan(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCollectionMan = e.item;
        $("#" + this.pageId + "collectionManId").val(this.modelCollectionMan.collectioMan);
        if ($("#" + this.pageId + "collectionManId").val() == 'Add New') {
            this.viewNewCollectionMans = true;
            $("#" + this.pageId + "newCollectionMan").focus();
        } else {
            this.viewNewCollectionMans = false;
            $("#" + this.pageId + "area").focus();
        }
    }
    getDestinationMethod() {
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "destinationOnly";
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;

    }
    getDestinationDetails = () => {
        this.getDestinationMethod();
        //this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    //this.showSpinnerForAction = false;
                } else {
                    //this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                //this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getMethodForgetStateDetails() {
        this.partyMasterDtoState = new PartyMasterDto();
        this.partyMasterDtoState.companyId = this.userDataDtoReturnSession.companyId;

    }
    getStateDetails() {
        this.getMethodForgetStateDetails();
        this.masterReadService.getStateDetailsService(this.partyMasterDtoState).subscribe(
            (response) => {
                if (response.length > 0) {
                    //Consignee StateDropDown
                    this.consigneeStateOptions = [];
                    this.consigneeStateNameTA = [];
                    //Consignor StateDropDown
                    this.consignorStateOptions = [];
                    this.consignorStateNameTA = [];
                    //Consignee StateDropDown
                    this.consigneeStateOptions = response;
                    for (let i = 0; i < this.consigneeStateOptions.length; i++) {
                        this.consigneeStateNameTA.push(this.consigneeStateOptions[i]);
                    }
                    //Consignor StateDropDown
                    this.consignorStateOptions = response;
                    for (let i = 0; i < this.consignorStateOptions.length; i++) {
                        this.consignorStateNameTA.push(this.consignorStateOptions[i]);
                    }
                } else {
                    //Consignee StateDropDown
                    this.consigneeStateOptions = [];
                    this.consigneeStateNameTA = [];
                    //Consignor StateDropDown
                    this.consignorStateOptions = [];
                    this.consignorStateNameTA = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) =>
                swal({
                    title: "Error",
                    text: "Server Error While Getting State  Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
            () => console.log('done');
    };

    getDetailsForCollectionMasterRead() {
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";
    }

    getCollectionManDetailsList() {
        this.getDetailsForCollectionMasterRead();
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if (response) {
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No collection man details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.userDataDtoOptions = [];
                        this.modelCollectionManTA = [];
                    } else {
                        this.userDataDtoOptions = [];
                        this.modelCollectionManTA = [];
                        this.userDataDtoOptions = response;
                        this.userDtoCollectionManAddNew.collectioMan = "Add New";
                        this.modelCollectionManTA.push(this.userDtoCollectionManAddNew);
                        for (let i = 0; i < this.userDataDtoOptions.length; i++) {
                            this.modelCollectionManTA.push(this.userDataDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while getting Collection Man Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    collectionManMethod(dataForCollectionMan, e) {
        if (e.keyCode == 13) {
            if (dataForCollectionMan == 'ADD NEW') {
                this.viewNewCollectionMans = true;
                $("#" + this.pageId + "newCollectionMan").focus();
            } else {
                this.viewNewCollectionMans = false;
                $("#" + this.pageId + "area").focus();
            }
        }
    }

    getUserValues() {
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.selectedDestination = $("#" + this.pageId + "destinationId").val();
        this.enteredBookingDate = $("#" + this.pageId + "bookingDate").val();
        this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.enteredInvoiceDate = $("#" + this.pageId + "invoiceDate").val();
        this.enteredCommodity = $("#" + this.pageId + "commodity").val();
        this.enteredSourceStation = $("#" + this.pageId + "sourceStation").val();
        this.enteredAgent = $("#" + this.pageId + "agentName").val();
        this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
        this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
        this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
        this.enteredConsignorName = $("#" + this.pageId + "consignorName").val();
        this.enteredConsignorAddress = $("#" + this.pageId + "consignorAddress").val();
        this.selectedConsignorState = $("#" + this.pageId + "consignorStateNameId").val();
        this.enteredConsignorStateCode = $("#" + this.pageId + "consignorStateCode").val();
        this.enteredConsignorGstNumber = $("#" + this.pageId + "consignorGstNumber").val();
        this.enteredConsignorMobileNumber = $("#" + this.pageId + "consignorMobileNumber").val();
        this.enteredConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.enteredConsigneeAddress = $("#" + this.pageId + "consigneeAddress").val();
        this.selectedConsigneeState = $("#" + this.pageId + "consigneeStateNameId").val();
        this.enteredConsigneeStateCode = $("#" + this.pageId + "consigneeStateCode").val();
        this.enteredConsigneeGstNumber = $("#" + this.pageId + "consigneeGstNumber").val();
        this.enteredConsigneeMobileNumber = $("#" + this.pageId + "consigneeMobileNumber").val();
        this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
        this.enteredToPay = $("#" + this.pageId + "toPay").val();
        this.enteredPaid = $("#" + this.pageId + "paid").val();
        this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
        this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
        this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
        this.enteredUnloadingCharges = $("#" + this.pageId + "unloadingCharges").val();
        this.enteredReceiptCharge = $("#" + this.pageId + "reciptCharges").val();
        this.enteredServiceCharge = $("#" + this.pageId + "serviceCharges").val();
        this.enteredDemurrage = $("#" + this.pageId + "demurrage").val();
        this.enteredDiscount = $("#" + this.pageId + "discount").val();
        this.enteredSubTotal = $("#" + this.pageId + "subTotal").val();
        this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();
        this.enteredCgst = $("#" + this.pageId + "cgst").val();
        this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
        this.enteredSgst = $("#" + this.pageId + "sgst").val();
        this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
        this.enteredIgst = $("#" + this.pageId + "igst").val();
        this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
        this.enteredGstTotal = $("#" + this.pageId + "gstTotal").val();
        //this.enteredCollectionMan = $("#"+this.pageId+"collectionManId").val();
        this.enteredArea = $("#" + this.pageId + "area").val();
        this.enteredGodown = $("#" + this.pageId + "godown").val();
        this.enteredCollectionMan = null;
        if ($("#" + this.pageId + "collectionManId").val() != null && $("#" + this.pageId + "collectionManId").val() != undefined &&
            $("#" + this.pageId + "collectionManId").val() != "" && $("#" + this.pageId + "collectionManId").val() == "Add New") {
            this.enteredCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
        } else {
            this.enteredCollectionMan = $("#" + this.pageId + "collectionManId").val();
        }

        this.enteredOtherCharge = $("#" + this.pageId + "others").val();
        this.enteredMemoDate = $("#" + this.pageId + "memoDates").val();
        //        $("#"+this.pageId+"").val();
        //        Set to Dto to save Starts
        this.rateMasterDto.memoNumber = this.enteredMemoNumber;
        this.rateMasterDto.gcNoteNumber = this.enteredLrNumber;
        this.rateMasterDto.agentName = this.enteredAgent;
        this.rateMasterDto.area = this.enteredArea;
        this.rateMasterDto.bookingDate = this.enteredBookingDate;
        //this.rateMasterDto.bookingDate = this.tempraverDateHardCoded;
        this.rateMasterDto.collectionMan = this.enteredCollectionMan;
        this.rateMasterDto.commodityName = this.enteredCommodity;
        this.rateMasterDto.consignee = this.enteredConsigneeName;
        this.rateMasterDto.consignor = this.enteredConsignorName;
        this.rateMasterDto.destination = this.selectedDestination;
        this.rateMasterDto.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDto.financialYear = this.userDataDtoReturnSession.financialYear;
        this.rateMasterDto.invoiceDate = this.enteredInvoiceDate;
        //this.rateMasterDto.invoiceDate = this.tempraverDateHardCoded;
        this.rateMasterDto.invoiceNumber = this.enteredInvoiceNumber;
        this.rateMasterDto.source = this.enteredSourceStation;
        this.rateMasterDto.godown = this.enteredGodown;
        //        sending but not using in impl starts
        this.rateMasterDto.weight = this.enteredChargedWeight;
        this.rateMasterDto.gstAmtTotal = this.enteredGstTotal == null ? 0 : this.enteredGstTotal;
        //      sending but not using in impl ends
        if (this.enteredDemurrage != null && this.enteredDemurrage != undefined &&
            this.enteredDemurrage != "") {
            this.rateMasterDto.demurrage = this.enteredDemurrage;
        } else {
            this.rateMasterDto.demurrage = "0";
        }
        if (this.enteredDiscount != null && this.enteredDiscount != undefined &&
            this.enteredDiscount != "") {
            this.rateMasterDto.discount = this.enteredDiscount;
        } else {
            this.rateMasterDto.discount = 0;
        }
        if (this.enteredGrandTotal != null && this.enteredGrandTotal != undefined &&
            this.enteredGrandTotal != "") {
            this.rateMasterDto.grandTotal = this.enteredGrandTotal;
        } else {
            this.rateMasterDto.grandTotal = 0;
        }
        if (this.enteredHamaliDd != null && this.enteredHamaliDd != undefined &&
            this.enteredHamaliDd != "") {
            this.rateMasterDto.hamaliDD = this.enteredHamaliDd;
        } else {
            this.rateMasterDto.hamaliDD = 0;
        }
        if (this.enteredHamaliGd != null && this.enteredHamaliGd != undefined &&
            this.enteredHamaliGd != "") {
            this.rateMasterDto.hamaligd = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
        } else {
            this.rateMasterDto.hamaligd = 0;
        }
        if (this.enteredLocalCharge != null && this.enteredLocalCharge != undefined &&
            this.enteredLocalCharge != "") {
            this.rateMasterDto.localCharge = this.enteredLocalCharge;
        } else {
            this.rateMasterDto.localCharge = 0;
        }
        this.rateMasterDto.totalArticles = this.enteredNoOfArticle == null ? 0 : this.enteredNoOfArticle; //having doubt string to int / int to string
        this.rateMasterDto.totalPaid = this.enteredPaid == null ? 0 : this.enteredPaid;

        if (this.enteredReceiptCharge != null && this.enteredReceiptCharge != undefined &&
            this.enteredReceiptCharge != "") {
            this.rateMasterDto.receiptCharges = this.enteredReceiptCharge;
        } else {
            this.rateMasterDto.receiptCharges = 0;
        }
        if (this.enteredServiceCharge != null && this.enteredServiceCharge != undefined &&
            this.enteredServiceCharge != "") {
            this.rateMasterDto.serviceCharges = this.enteredServiceCharge;
        } else {
            this.rateMasterDto.serviceCharges = 0;
        }
        //      Service Tax and Service Tax Amount is not added because now we are using GST
        //this.rateMasterDto.subTotal = this.enteredSubTotal;
        this.rateMasterDto.subTotal = this.enteredGrandTotal == null ? 0 : this.enteredGrandTotal;
        this.rateMasterDto.totalToPay = this.enteredToPay == null ? 0 : this.enteredToPay;

        if (this.enteredOtherCharge != null && this.enteredOtherCharge != undefined &&
            this.enteredOtherCharge != "") {
            this.rateMasterDto.otherChgAmt = this.enteredOtherCharge;
        } else {
            this.rateMasterDto.otherChgAmt = 0;
        }

        // // only for print
        // rateMasterDto.setInPrint(true);
        this.rateMasterDto.cashMemoType = "";
        //        this.rateMasterDto.memoDate = this.todayDate;
        //this.rateMasterDto.memoDate = this.tempraverDateHardCoded;
        this.rateMasterDto.memoDateStr = this.enteredMemoDate;
        this.rateMasterDto.enteredBy = this.userDataDtoReturnSession.userId;
        this.rateMasterDto.column1 = this.message;
        this.rateMasterDto.inPrint = this.printShow;

        if (this.enteredToPay == null) {
            this.rateMasterDto.lorryFreight = 0.0;
        } else {
            this.rateMasterDto.lorryFreight = this.enteredToPay;
        }
        if (this.enteredPaid == null) {
            this.rateMasterDto.paid = 0.0;
        } else {
            this.rateMasterDto.paid = this.enteredPaid;
        }

        if (this.selectedDeliveryMode == "Godown Delivery") {
            this.rateMasterDto.deliveryMode = "GD";
        } else {
            this.rateMasterDto.deliveryMode = "DD";
        }

        if (this.viewUnloadingCharge == true) {
            if (this.enteredUnloadingCharges != null && this.enteredUnloadingCharges != undefined &&
                this.enteredUnloadingCharges != "") {
                this.rateMasterDto.unloadingChg = this.enteredUnloadingCharges;
            } else {
                this.rateMasterDto.unloadingChg = 0.0;
            }
        } else {
            this.rateMasterDto.unloadingChg = 0.0;
        }

        // GST
        this.rateMasterDto.IGST = this.enteredIgst == null ? 0 : this.enteredIgst;
        this.rateMasterDto.IGSTAmt = this.enteredIgstAmt == null ? 0 : this.enteredIgstAmt;
        this.rateMasterDto.CGST = this.enteredCgst == null ? 0 : this.enteredCgst;
        this.rateMasterDto.CGSTAmt = this.enteredCgstAmt == null ? 0 : this.enteredCgstAmt;
        this.rateMasterDto.SGST = this.enteredSgst == null ? 0 : this.enteredSgst;
        this.rateMasterDto.SGSTAmt = this.enteredSgstAmt == null ? 0 : this.enteredSgstAmt;

        this.rateMasterDto.igstamt = this.enteredIgstAmt == null ? 0 : this.enteredIgstAmt;
        this.rateMasterDto.cgstamt = this.enteredCgstAmt == null ? 0 : this.enteredCgstAmt;
        this.rateMasterDto.sgstamt = this.enteredSgstAmt == null ? 0 : this.enteredSgstAmt;
        this.rateMasterDto.igst = this.enteredIgst == null ? 0 : this.enteredIgst;
        this.rateMasterDto.cgst = this.enteredCgst == null ? 0 : this.enteredCgst;
        this.rateMasterDto.sgst = this.enteredSgst == null ? 0 : this.enteredSgst;


        this.rateMasterDto.consignorAddress = this.enteredConsignorAddress;
        this.rateMasterDto.consignorState = this.selectedConsignorState;
        this.rateMasterDto.consignorStateCode = this.enteredConsignorStateCode;
        this.rateMasterDto.gstConsgnor = this.enteredConsignorGstNumber;
        this.rateMasterDto.consignorMobileNo = this.enteredConsignorMobileNumber;
        this.rateMasterDto.consigneeAddress = this.enteredConsigneeAddress;
        this.rateMasterDto.consigneeState = this.selectedConsigneeState;
        this.rateMasterDto.consigneeStateCode = this.enteredConsigneeStateCode;
        this.rateMasterDto.gstConsignee = this.enteredConsigneeGstNumber;
        this.rateMasterDto.consigneeMobileNo = this.enteredConsigneeMobileNumber;
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        //      Set to Dto to save Ends
    }

    btnSave = () => {

        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.enteredMemoDate = $("#" + this.pageId + "memoDates").val();
        if ((this.enteredLrNumber == null) || (this.enteredLrNumber == undefined) ||
            (this.enteredLrNumber == "") || (this.enteredMemoDate == null) ||
            (this.enteredMemoDate == undefined) || (this.enteredMemoDate == "")) {
            swal({
                title: "Mandatory Field",
                text: "Plese enter LR Number And Memo Date",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            });
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to Save ?",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.getUserValues();
                    console.log(this.rateMasterDto);
                    this.memoService.createDummyCashMemoService(this.rateMasterDto).subscribe(
                        (response) => {
                            this.createdDummyCashmemoDetails = [];
                            this.createdDummyCashmemoDetails = response;
                            this.createdDummyCashmemo = this.createdDummyCashmemoDetails;
                            if (this.createdDummyCashmemo.status != "failed") {
                                swal({
                                    title: "Dummy Cashmemo Number",
                                    text: "Dummy Cashmemo Number Save Successfully",
                                    // text: "Sure you want to Print ?",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                    buttons: ["No", "Yes"],
                                }).then((yesBtn) => {
                                    if (yesBtn) {
                                        this.printMemo();
                                    } else {
                                        this.clearAllAfterSave();
                                    }
                                });
                            } else {
                                swal("Dummy Cashmemo Number", "Failed To Save Dummy Cashmemo Number", "warning");
                            }
                            this.changeDetectorRef.detectChanges();
                        }), (error) => swal({
                            title: "Error",
                            text: "Server Error While Creating Dummy Cash Memo",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }),
                        () => console.log('done');
                } /*else {
                    console.log("Dismiss Sure Yes Btn");
                }*/
            })

        }
    };
    printMemo() {
        this.getUserValues();
        localStorage.clear();
        localStorage.setItem('printAfterSave', JSON.stringify(this.rateMasterDto));
        this.viewCashMemoPrint = true;
        window.addEventListener('afterprint', (event) => {
            this.viewCashMemoPrint = false;
        });
        this.clearAllAfterSave();
        this.changeDetectorRef.detectChanges();
    }
    clearAll() {
        swal({
            title: "Sure!!!",
            text: "Sure you want to Clear All the Fields",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesClearBtn) => {
            if (yesClearBtn) {
                $('input[type="text"],input[type="number"]').val('');
                this.controlDestination.reset();
                this.controlConsignorState.reset();
                this.controlConsigneeState.reset();
                $("#" + this.pageId + "deliveryMode").val("Godown Delivery");
                if (this.viewUnloadingCharge == true) {
                    this.hamaliDDView = false;
                }
                this.clearAllAfterSave();
            }
        })
    }

    clearAllAfterSave() {
        $('input[type="text"],input[type="number"]').val('');
        //        form().reset();
        this.controlDestination.reset();
        this.controlConsignorState.reset();
        this.controlConsigneeState.reset();
        $("#" + this.pageId + "deliveryMode").val("Godown Delivery");
        if (this.viewUnloadingCharge == true) {
            this.hamaliDDView = false;
        }
        $("#" + this.pageId + "consigneeAddress").val('');
        $("#" + this.pageId + "consignorAddress").val('');
        this.bookingDatesModal = null;
        this.invoiceDatesModal = null;
        $("#" + this.pageId + "bookingDate").val('');
        $("#" + this.pageId + "invoiceDate").val('');
        this.modelDestination = null;
        this.modelCollectionMan = null;
        this.modelConsigneeStateName = null;
        this.modelConsignorStateName = null;
        this.viewNewCollectionMans = false;
        this.memoDatesModal = null;
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    lrNumberOnEnter(e) {
        if (e.keyCode == 13) {
            this.lrNumberGetDtls()
        }
    }

    lrNumberGetDtls() {
        this.validateEnteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        console.log(this.validateEnteredLrNumber);
        if (this.validateEnteredLrNumber == null || this.validateEnteredLrNumber == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter LR Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $("#" + this.pageId + 'lrNumber').focus();
                }, 100);

            });
        } else {
            this.getLrNumberDetails();
        }
    }

    getMethodForGetLrNumberDtls() {
        this.lrDtoForGetLrNoDtls = new LRDto();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.lrDtoForGetLrNoDtls.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForGetLrNoDtls.lrNumber = this.enteredLrNumber;
        this.lrDtoForGetLrNoDtls.stocksAt = this.userDataDtoReturnSession.office;
    }

    getLrNumberDetails() {
        this.getMethodForGetLrNumberDtls();
        this.memoService.getLrDetailsForCashMemoService(this.lrDtoForGetLrNoDtls).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal("Record Not Found", "No records found for Entered Lr Number Details", "warning");
                } else {
                    this.returnLrNumberDtls = new LRDto();
                    this.responseOfGetLrNumberDtls = response;
                    this.setLrFieldsDetails(this.responseOfGetLrNumberDtls);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) =>
                swal("Server Error", "Problem occur while getting Entered Lr Number Details", "error"),
            () => console.log('done');
    }

    clearFields() {
        $('input[type="text"],input[type="number"]').val('');
        this.clearAllAfterSave();
    }

    setLrFieldsDetails(dataFroLrFields) {
        console.log(dataFroLrFields);
        //LR Details
        $("#" + this.pageId + "destinationId").val(dataFroLrFields.destination);
        $("#" + this.pageId + "bookingDate").val(moment(dataFroLrFields.bookingDate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "invoiceNumber").val(dataFroLrFields.invoiceNumber);
        $("#" + this.pageId + "invoiceDate").val(moment(dataFroLrFields.invoiceDate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "commodity").val(dataFroLrFields.column2);
        $("#" + this.pageId + "sourceStation").val(dataFroLrFields.source);
        $("#" + this.pageId + "agentName").val(dataFroLrFields.agentName);
        $("#" + this.pageId + "noOfArticle").val(dataFroLrFields.totalArticles);
        $("#" + this.pageId + "actualWeight").val(dataFroLrFields.actualWeight);
        $("#" + this.pageId + "chargedWeight").val(dataFroLrFields.chargedWeight);


        //Consignor Details
        $("#" + this.pageId + "consignorName").val(dataFroLrFields.consignorName);
        $("#" + this.pageId + "consignorAddress").val(dataFroLrFields.consignorAdress);
        $("#" + this.pageId + "consignorStateNameId").val(dataFroLrFields.consignorState);
        $("#" + this.pageId + "consignorStateCode").val(dataFroLrFields.consignorStateCode);
        $("#" + this.pageId + "consignorGstNumber").val(dataFroLrFields.gstNoConsignor);
        $("#" + this.pageId + "consignorMobileNumber").val(dataFroLrFields.consignorMobileNo);
        //Consignee Details
        $("#" + this.pageId + "consigneeName").val(dataFroLrFields.consigneeName);
        $("#" + this.pageId + "consigneeAddress").val(dataFroLrFields.consigneeAdress);
        $("#" + this.pageId + "consigneeStateNameId").val(dataFroLrFields.consigneeState);
        $("#" + this.pageId + "consigneeStateCode").val(dataFroLrFields.consigneeStateCode);
        $("#" + this.pageId + "consigneeGstNumber").val(dataFroLrFields.gstNoConsignee);
        $("#" + this.pageId + "consigneeMobileNumber").val(dataFroLrFields.consigneeMobileNo);
        //Billing Details
        $("#" + this.pageId + "toPay").val(dataFroLrFields.toPay);
        $("#" + this.pageId + "paid").val(dataFroLrFields.paid);

        //memo details
        $("#" + this.pageId + "memoDates").val(moment(dataFroLrFields.cashMemoDate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "memoNumber").val(dataFroLrFields.cashMemoNumber);
        //alert(dataFroLrFields.consignorMobileNo+"-"+dataFroLrFields.consigneeMobileNo);
    }
}
