import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends

@Component( {
    selector: 'app-hire-details-by-destination',
    templateUrl: './hire-details-by-destination.component.html',
    styleUrls: ['./hire-details-by-destination.component.css']
} )
export class HireDetailsByDestinationComponent implements OnInit {

    loadingIndicator = true;

    getDataFrmServiceFrHireDetailsByDestinationTable: any;
    hireDetailsByDestinationDataList: any;
    onDestroyUnsubscribtionHireDetailsByDestination: Subscription;


    controlMonth = new FormControl();
    controlSource = new FormControl();

    monthOptions = [
        { id: 1, label: 'January' },
        { id: 2, label: 'February' },
        { id: 3, label: 'March' },
        { id: 4, label: 'April' },
        { id: 5, label: 'May' },
        { id: 6, label: 'June' },
        { id: 7, label: 'July' },
        { id: 8, label: 'August' },
        { id: 9, label: 'September' },
        { id: 10, label: 'October' },
        { id: 11, label: 'November' },
        { id: 12, label: 'December' }
    ];


    sourceOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'Alleppey' },
        { id: 3, label: 'Alwaye' },
        { id: 4, label: 'Aurangabad' }
    ];



    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerHireDetailsByDestination: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsHireDetailsByDestination: any;

    pageId="hdbdr";

    constructor( private hireDetailsByDestinationScreen: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    ngOnInit(): void {


        this.dtOptionsHireDetailsByDestination = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrHireDetailsByDestinationTable = this.hireDetailsByDestinationScreen.getSummaryData()
        this.onDestroyUnsubscribtionHireDetailsByDestination = this.getDataFrmServiceFrHireDetailsByDestinationTable.subscribe( data => {
            this.hireDetailsByDestinationDataList = data['data'];
            this.dtTriggerHireDetailsByDestination.next();
        } );


    }

    ngOnDestroy(): void {
        this.dtTriggerHireDetailsByDestination.unsubscribe();

        this.onDestroyUnsubscribtionHireDetailsByDestination.unsubscribe();
    }

}
