import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';

import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
@Component({
	selector: 'app-business-start-lrs-details-report',
	templateUrl: './business-start-lrs-details-report.component.html',
	styleUrls: ['./business-start-lrs-details-report.component.css']
})
export class BusinessStartLRSDetailsReportComponent implements OnInit {

	gettingDataFrmServiceFrDiscontinuousDetailsReportTable: any;


	businessStartRptLrsSummaryList: any;
	loadingIndicator = true;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerBusinessStartLrsSummaryReport: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsBusinessStartLrsSummaryReport: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;

	lrDtoLocalStorage: LRDto = new LRDto();
	lrDto: LRDto = new LRDto();
	address: any;
	consigneeNameOnLoadSet: any = null;
	fromDateOnLoadSet: any = null;
	toDateOnLoadSet: any = null;
	pageId="bsldr";


	constructor(private router: Router, private datePipe: DatePipe,
		private consigneeService: ConsigneeService, private destStmtReport: DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.lrDtoLocalStorage = JSON.parse(localStorage.getItem('businessStartRptLrsDetailsPopUpTemplate'));
			localStorage.clear();

			if (this.lrDtoLocalStorage != null) {
				this.consigneeNameOnLoadSet = this.lrDtoLocalStorage.consigneeName;
				this.fromDateOnLoadSet = this.lrDtoLocalStorage.fromDate;
				this.toDateOnLoadSet = this.lrDtoLocalStorage.toDate;
				console.log(this.consigneeNameOnLoadSet);
				this.setBusinessStartRptOfLrsSummary();
			}
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var consigneeNamePrint = this.consigneeNameOnLoadSet;
		var fromDatePrint = this.fromDateOnLoadSet;
		var toDatePrint = this.toDateOnLoadSet;
		this.dtOptionsBusinessStartLrsSummaryReport = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize: function (win) {
						$(win.document.body).css('font-size', '10pt');
						$(win.document.body).find('th, td').
							css('font-family', 'Arial, Helvetica, sans-serif')
							.css('font-size', '12px').css('text-align', 'center');
					},
					footer: true,
					messageTop: function () {
						return "<br><table style='width: 100%;'>" +
							"<tr>" +
							"<td style='width: 22%;'>" +
							"<table>" +
							"<tr>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>From Date</strong>" +
							"</td>" +
							"<td width='2%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>:</strong>" +
							"</td>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<span style='font-size:15px;'>" + fromDatePrint + "</span><br>" +
							"</td>" +
							"</tr>" +
							"<tr>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
							"</td>" +
							"<td width='2%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>:</strong>" +
							"</td>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<span style='font-size:15px;'>" + toDatePrint + "</span><br>" +
							"</td>" +
							"</tr>" +
							"</table>" +
							"</td>" +
							"<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
							"<strong style='font-size:24px;'><u>" + consigneeNamePrint + " Details </u></strong><br>" +
							"</td>" +
							"<td rowspan='3' align='left' style='width:22%;'>" +
							"</td>" +
							"</tr>" +
							"</table><br>";
					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "New Consignee Summary Report" +
							"Form Date : " + fromDatePrint + " - " +
							"To Date : " + toDatePrint + "";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			// the below code is for button export ends
			//place holder in search/filter in datatable starts
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			//place holder in search/filter in datatable ends
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 250,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var articles = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actWgt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chdWgt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var toPay = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(4).footer()).html(articles);
				$(api.column(5).footer()).html(actWgt);
				$(api.column(6).footer()).html(chdWgt);
				$(api.column(7).footer()).html(toPay);
				$(api.column(8).footer()).html(paid);
			}
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerBusinessStartLrsSummaryReport.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerBusinessStartLrsSummaryReport.next();
	}

	setBusinessStartRptOfLrsSummary() {
		this.lrDto = new LRDto();
		this.lrDto.consigneeId = this.lrDtoLocalStorage.consigneeId;
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.consigneeService.getBusinessStartindividualReport(this.lrDto).subscribe(
			(response) => {
				console.log(response);
				$("#"+this.pageId+"businessStartLrsSummaryTableId").DataTable().destroy();
				this.businessStartRptLrsSummaryList = [];
				if (response.length > 0) {
					this.businessStartRptLrsSummaryList = response;
					//this.dtTriggerStmtVerificationLrsSummaryReport.next();
					setTimeout(() => {
						this.dtTriggerBusinessStartLrsSummaryReport.next();
					}, 5000);
				} else {
					//this.dtTriggerStmtVerificationLrsSummaryReport.next();
					setTimeout(() => {
						this.dtTriggerBusinessStartLrsSummaryReport.next();
					}, 5000);
					swal("Warning", "No  Details found !", "warning");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Business Start Report of LR's Details", "info");
			}, () => console.log('done');
	}

}