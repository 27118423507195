<html>

<head>
<style>
.imb_patch {
	text-align: center;
	background-color: orange;
	padding: 2px;
}
</style>
</head>

<body>
	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Unloading Sheet Details Of
						{{hireslipNumberFromDto}}</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-12">
							<div class="form-group">
								<div class="input-group">
									<br> <br>
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>

									<h6 class="card-title" align='center'
										style="color: green; margin-bottom: 10px; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}unloadingSheetDetailsId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsUnloadingSheetDetails"
								[dtTrigger]="dtTriggerUnloadingSheetDetails">

								<thead>
									<tr>
										<th>Status</th>
										<th>LR Number</th>
										<th>Act Art</th>
										<th>Entered Art</th>
										<th>Remarks</th>
										<th>Mention</th>

									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let unloadingSheetDetailsData of unloadingSheetDetailsDataList let i = index ">
										<td>{{ unloadingSheetDetailsData.status }}</td>
										<td>{{unloadingSheetDetailsData.lrNumber }}</td>
										<td>{{ unloadingSheetDetailsData.articles }}</td>
										<td>{{ unloadingSheetDetailsData.totalArticles }}</td>
										<td>{{ unloadingSheetDetailsData.remarks }}</td>
										<td>{{ unloadingSheetDetailsData.description }}</td>

									</tr>
								</tbody>
								<!-- 								<tfoot> -->
								<!-- 									<tr> -->
								<!-- 										<td></td> -->
								<!-- 										<td></td> -->
								<!-- 										<td></td> -->
								<!-- 										<td></td> -->
								<!-- 										<td></td> -->
								<!-- 										<td></td> -->
								<!-- 									</tr> -->
								<!-- 								</tfoot> -->
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>

</html>