import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
    selector: 'app-statement-rpt-lr-details',
    templateUrl: './statement-rpt-lr-details.component.html',
    styleUrls: ['./statement-rpt-lr-details.component.css']
})
export class StatementRptLrDetailsComponent implements OnInit {

    statementReportLrDetailsDataList: any;
    statementReportLrAdditionDataList: any;
    statementReportLrDeductionDataList: any;
    onDestroyUnsubscribtionStatementReport: Subscription;
    model: NgbDateStruct;
    model2;
    loadingIndicator = true;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerStatementReportLrDetails: Subject<any> = new Subject();
    dtTriggerStatementReportLrAdditionDetails: Subject<any> = new Subject();
    dtTriggerStatementReportLrDeductionDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsStatementReportLrDetails: any;
    dtOptionsStatementReportLrAdditionDetails: any;
    dtOptionsStatementReportLrDeductionDetails: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    stmtRptDetails_localStorage: any;
    stmtRptAdditionDetails_localStorage: any;
    stmtRptDeductionDetails_localStorage: any;
    additionValueLRDto: LRDto = new LRDto();
    additionValueLRDtoList: Array<LRDto> = [];
    deductionValueLRDto: LRDto = new LRDto();
    deductionValueLRDtoList: Array<LRDto> = [];
    validateStatementNo: any = null;
    validateDestination: any = null;
    hideShowForPrint = false;
    showSpinnerForAction = false;
    lrDto: LRDto = new LRDto();
    viewStatementRptLRDetlsPrint = false;
    pageId = "srldc";

    constructor(private router: Router, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.stmtRptDetails_localStorage = JSON.parse(localStorage.getItem('popupStmtRptLrDetls'));
            this.stmtRptAdditionDetails_localStorage = JSON.parse(localStorage.getItem('popupStmtRptLrDetlsAddition'));
            this.stmtRptDeductionDetails_localStorage = JSON.parse(localStorage.getItem('popupStmtRptLrDetlsDeduction'));
            localStorage.clear();
            // console.log(this.stmtRptDetails_localStorage);
            //console.log(this.stmtRptAdditionDetails_localStorage.split("#"));

            if (this.stmtRptDetails_localStorage != null && this.stmtRptDetails_localStorage != undefined &&
                this.stmtRptDetails_localStorage != "") {
                this.validateStatementNo = this.stmtRptDetails_localStorage.stmtId;
                this.validateDestination = this.stmtRptDetails_localStorage.destination;
                this.setBookingServiceReport();
            }
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var stmtNoPrint = this.stmtRptDetails_localStorage.stmtId;
        var destinationPrint = this.stmtRptDetails_localStorage.destination;
        var fromPeriodPrint = this.stmtRptDetails_localStorage.fromPeriod;
        var toPeriodPrint = this.stmtRptDetails_localStorage.toPeriod;
        this.dtOptionsStatementReportLrDetails = {
            dom: 'Bfrtip',
            //place holder in search/filter in datatable starts
            buttons: [
                /* {
                     extend: 'print',
                     text: '<i class="fas fa-print"> Print LR Details</i>',
                     titleAttr: 'Print LR Details',
                     customize: function (win) {
                         $(win.document.body).css('font-size', '10pt');
                         $(win.document.body).find('th, td').
                             css('font-family', 'Arial, Helvetica, sans-serif')
                             .css('font-size', '12px').css('text-align', 'center');
                     },
                     footer: true,
                     exportOptions: {
                         columns: [0, 9, 2, 4, 1, 10, 6, 7, 5, 11, 8]
                     },
                     messageTop: function () {
                         return "<br><table style='width: 100%;'>" +
                             "<tr>" +
                             "<td style='width: 22%;'>" +
                             "<table>" +
                             "<tr>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>Statement No</strong>" +
                             "</td>" +
                             "<td width='2%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>:</strong>" +
                             "</td>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<span style='font-size:15px;'>" + stmtNoPrint + "</span><br>" +
                             "</td>" +
                             "</tr>" +
                             "<tr>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>Destination&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                             "</td>" +
                             "<td width='2%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>:</strong>" +
                             "</td>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<span style='font-size:15px;'>" + destinationPrint + "</span><br>" +
                             "</td>" +
                             "</tr>" +
                             "<tr>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                             "</td>" +
                             "<td width='2%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>:</strong>" +
                             "</td>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<span style='font-size:15px;'>" + moment(fromPeriodPrint).format('DD-MM-YYYY') + "</span><br>" +
                             "</td>" +
                             "</tr>" +
                             "<tr>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                             "</td>" +
                             "<td width='2%' style='text-align:left !important;'>" +
                             "<strong style='font-size:15px;'>:</strong>" +
                             "</td>" +
                             "<td width='10%' style='text-align:left !important;'>" +
                             "<span style='font-size:15px;'>" + moment(toPeriodPrint).format('DD-MM-YYYY') + "</span><br>" +
                             "</td>" +
                             "</tr>" +
                             "</table>" +
                             "</td>" +
                             "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                             "<strong style='font-size:24px;'><u>Statement Report LR Details</u></strong><br>" +
                             //"<strong style='font-size:15px;'><u>Booking Chargeable / Unchargeable Service Tax</u></strong>" +
                             "</td>" +
                             "<td rowspan='3' align='left' style='width:22%;'>" +
                             "</td>" +
                             "</tr>" +
                             "</table><br>";
                     },
                     title: function () {
                         return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                             "<td align='left' style='width: 20%;'>" +
                             "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                             "</td>" +
                             "<td align='left' style='width: 30%;'>" +
                             "</td>" +
                             "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                             companyAddressDetls +
                             "</td>" +
                             "</tr></table>";
                     }
                 },*/
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel LR Details</i>',
                    titleAttr: 'Excel LR Details',
                    footer: true,
                    title: function () {
                        return "Statement Report LR Details" +
                            "From Date : " + fromPeriodPrint + " -  " +
                            "To Date : " + toPeriodPrint + " - " +
                            "Destination : " + destinationPrint + "" +
                            "Statement No : " + stmtNoPrint + "";

                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var article = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var servTax = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chdWgt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(article);
                $(api.column(5).footer()).html(actWgt);
                $(api.column(6).footer()).html(topay);
                $(api.column(7).footer()).html(paid);
                $(api.column(8).footer()).html(servTax);
                $(api.column(12).footer()).html(chdWgt);
            }
        },
            this.dtOptionsStatementReportLrAdditionDetails = {
                dom: 'Bfrtip',
                //place holder in search/filter in datatable starts
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                "order": [[1, "desc"]],
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "bFilter": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var creditAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(creditAmt);
                }
            },
            this.dtOptionsStatementReportLrDeductionDetails = {
                dom: 'Bfrtip',
                //place holder in search/filter in datatable starts
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                "order": [[1, "desc"]],
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "bFilter": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var debitAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(debitAmt);
                }
            }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStatementReportLrDetails.next();
        this.dtTriggerStatementReportLrAdditionDetails.next();
        this.dtTriggerStatementReportLrDeductionDetails.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerStatementReportLrDetails.unsubscribe();
        this.dtTriggerStatementReportLrAdditionDetails.unsubscribe();
        this.dtTriggerStatementReportLrDeductionDetails.unsubscribe();
        // this.onDestroyUnsubscribtionStatementReport.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    setBookingServiceReport() {
        this.lrDto = new LRDto();
        this.lrDto.fortNightStmtNo = this.stmtRptDetails_localStorage.stmtId;
        this.lrDto.destination = this.stmtRptDetails_localStorage.destination;
        this.lrDto.fromDate = this.stmtRptDetails_localStorage.fromPeriod;
        this.lrDto.toDate = this.stmtRptDetails_localStorage.toPeriod;
        this.lrDto.reportMode = "BookService";
        this.lrDto.mode = "destination";
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        //console.log(this.lrDto);
        this.destStmtReport.getBookingServiceTax(this.lrDto).subscribe(
            (response) => {
                //console.log(response);
                this.statementReportLrDetailsDataList = [];
                $("#" + this.pageId + "statementRptLrDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.statementReportLrDetailsDataList = response;
                    setTimeout(() => {
                        this.dtTriggerStatementReportLrDetails.next();
                    }, 3000);
                    this.showSpinnerForAction = false;
                    this.setStatementRptLrDetlsAddition();
                    this.setStatementRptLrDetlsDeduction();
                } else {
                    setTimeout(() => {
                        this.dtTriggerStatementReportLrDetails.next();
                    }, 3000);
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }

    setStatementRptLrDetlsAddition() {
        this.additionValueLRDtoList = [];
        this.statementReportLrAdditionDataList = [];
        $("#" + this.pageId + "statementRptAdditionTableId").DataTable().destroy();
        if (this.stmtRptAdditionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.stmtRptAdditionDetails_localStorage.length; i++) {
                var additionValue = this.stmtRptAdditionDetails_localStorage[i];
                var additionValueSplit = additionValue.split("_");
                this.additionValueLRDto = new LRDto();
                this.additionValueLRDto.description = additionValueSplit[0];
                this.additionValueLRDto.totalAmount = additionValueSplit[1];
                this.additionValueLRDtoList.push(this.additionValueLRDto);
                this.statementReportLrAdditionDataList.push(this.additionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerStatementReportLrAdditionDetails.next();
            }, 3000);
        } else {
            setTimeout(() => {
                this.dtTriggerStatementReportLrAdditionDetails.next();
            }, 3000);
        }
    }


    setStatementRptLrDetlsDeduction() {
        this.deductionValueLRDtoList = [];
        this.statementReportLrDeductionDataList = [];
        $("#" + this.pageId + "statementRptDeductionTableId").DataTable().destroy();
        if (this.stmtRptDeductionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.stmtRptDeductionDetails_localStorage.length; i++) {
                var deductionValue = this.stmtRptDeductionDetails_localStorage[i];
                var deductionValueSplit = deductionValue.split("_");
                this.deductionValueLRDto = new LRDto();
                this.deductionValueLRDto.description = deductionValueSplit[0];
                this.deductionValueLRDto.totalAmount = deductionValueSplit[1];
                this.statementReportLrDeductionDataList.push(this.deductionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerStatementReportLrDeductionDetails.next();
            }, 3000);

        } else {
            setTimeout(() => {
                this.dtTriggerStatementReportLrDeductionDetails.next();
            }, 3000);
        }
    }

    /*  clickListnerForPrintStmtRptLr() {
          localStorage.clear();
          localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
              JSON.stringify(this.statementReportLrAdditionDataList));
          localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
              JSON.stringify(this.statementReportLrDeductionDataList));
          localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
              JSON.stringify(this.stmtRptDetails_localStorage));
          localStorage.setItem('stmtRptLrDtlsPrintLocalStorage',
              JSON.stringify(this.statementReportLrDetailsDataList));
          this.viewStatementRptLRDetlsPrint = true;
          window.addEventListener('afterprint', (onclick) => {
              if (this.viewStatementRptLRDetlsPrint) {
                  this.viewStatementRptLRDetlsPrint = false;
                  //this.invoiceDto = new InvoiceDto();
                  //this.listOfLrDto =  [];
                  localStorage.clear();
              }
          });
      }*/

}
