<!-- Row -->
<div class="row">

	<div class="col-lg-12">
		<div class="card">
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<h6 style="border-bottom: 1px solid orange;" class="card-title">Stocks
								Summary</h6>
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsStockSummary"
								[dtTrigger]="dtTriggerStockSummary">
								<thead>
									<tr>
										<th>Total LRs</th>
										<th>Destination</th>
										<th>Articles</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let stockSummaryData of stockSummaryDataList ">
										<td>{{ stockSummaryData.totalLrs }}</td>
										<td>{{ stockSummaryData.destination }}</td>
										<td>{{ stockSummaryData.articles }}</td>
										<td>{{ stockSummaryData.actualWeight }}</td>
										<td>{{ stockSummaryData.chargedWeight }}</td>
										<td>{{ stockSummaryData.amount }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
