import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//service starts
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
//we used any keyword so comment
import { MasterDto } from 'src/app/dto/master-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import swal from 'sweetalert';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
//sweet alert ends


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'my-auth-token'
    })
};
//service ends
@Component({
    selector: 'app-driver-master',
    templateUrl: './driver-master.component.html',
    styleUrls: ['./driver-master.component.css']
})
export class DriverMasterComponent implements OnInit {
    @ViewChild("pin") pinField: ElementRef;
    //for datatable starts
    //    gettingDataFrmServiceFrDriverNameTable: any;


    driverNameDataList: any;
    //service starts      
    //    partyModel: PartyModel = new PartyModel();

    private baseUrl = '/api/v1/createconsignee';
    masterDto: MasterDto = new MasterDto();
    driverDetailsDto: DriverdetailsDto = new DriverdetailsDto();
    driverDetailsDeleteDto: DriverdetailsDto = new DriverdetailsDto();
    driverDetailsDtoSaveRet: any;
    driverDetailsDeleteRet: any;
    userDataDtoReturnSession: any;
    driverDetailsDtoGetResult: any;
    isLoggedIn = true;
    pageId="drimc";


    //    consigneeDetailsModel: ConsigneedetailsModel = new ConsigneedetailsModel();
    //    service ends

    //    onDestroyUnsubscribtionDriverName: Subscription;

    //for datatable ends
    pinfocus(): void {
        this.pinField.nativeElement.focus();
    }
    loadingIndicator = true;

    //for datatable starts
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDriverName: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsDriverName: any;
    selectedDriverId = 0;

    //for datatable ends

    //for the select option with filter starts

    control = new FormControl();

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    dlIssueStateOptions = [
        { id: 1, label: 'Assam' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Tamil Nadu' },
    ];

    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    controlRegState = new FormControl();
    partyMasterDtoOptions: PartyMasterDto[];
    public modelRegState: any;
    modelRegStateTA: Array<PartyMasterDto> = [];
    focusRegStateTA$ = new Subject<string>();
    searchRegState = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusRegStateTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelRegStateTA
                : this.modelRegStateTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterRegState = (x: { state: string }) => x.state;

    validateStateName_save: any;
    regStateName: any;
    regStateCode: any;
    rowSelectedStateName: any;
    //for the select option with filter ends
    selectedFileDriverDetailsForLicense: File;
    isUploaded: boolean = false;
    showSpinnerForAction: boolean = false;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;


    constructor(/* for datatable starts */private masterService: MasterService,/* for datatable endss */
        //            service starts
        private masteRreadService: MasterReadService,
        private http: HttpClient,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
        //            service ends

    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStatesMethod();
        }


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    /* for datatable ends */


    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsDriverName = {
            //};
            //columns is used to for export and others starts
            //            columns: [
            //                {
            //                    title: 'Driver Name',
            //                    data: 'driverName'
            //                },
            //                {
            //                    title: 'License No',
            //                    data: 'licenseNo'
            //                },
            //                {
            //                    title: 'Mobile',
            //                    data: 'mobile'
            //                },
            //                {
            //                    title: 'Address',
            //                    data: 'address'
            //                },
            //                {
            //                    title: 'DL Issue State',
            //                    data: 'dlIssueState'
            //                },
            //                {
            //                    title: 'Pin',
            //                    data: 'pin'
            //                },
            //                {
            //                    title: 'Licence',
            //                    data: 'licence'
            //                }
            //            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
                  buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        exportOptions: {
                            columns: ':visible'
    
                        }
                    },
                   
                ],




            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 310,
            "scrollCollapse": true,
            //pagination starts
            "paging": false,
            "info": true,
            "pagingType": 'full_numbers',
            //"pageLength": 25,
            //pagination ends

            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //        this.supplierList.getAllData().subscribe(data => {
        //            this.lrDispatchBknRptList = data['data'];
        //            this.dtTriggerSummary.next();
        //            } );
        //        this.gettingDataFrmServiceFrDriverNameTable = this.driverMaster.getSummaryData()
        //        this.onDestroyUnsubscribtionDriverName = this.gettingDataFrmServiceFrDriverNameTable.subscribe( data => {
        //            this.driverNameDataList = data['data'];
        //            this.dtTriggerDriverName.next();
        //        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerDriverName.unsubscribe();

        //        this.onDestroyUnsubscribtionDriverName.unsubscribe();
        //for datatable ends
    }

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends


    //  service starts
    //    getValuesForDriverNameList() {
    //        var masterDto = {
    //            'mainStation': 'Delhi',
    //            'mainAdminStation': 'Budhpur'
    //        }
    //        return masterDto;
    //    }
    getDriverDetailsForRead() {
        this.driverDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDetailsDto.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDetailsDto.mode = "MainStation";
            this.driverDetailsDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.driverDetailsDto.mode = "Station";
            this.driverDetailsDto.mainStation = this.userDataDtoReturnSession.office;

        }
    }


    getDriverNameList = () => {
        //        console.log( this.driverDetailsDto.companyId + "," + this.driverDetailsDto.mainStation + "," + this.driverDetailsDto.status );
        this.getDriverDetailsForRead();
        this.showSpinnerForAction = true;
        this.masteRreadService.getDriver(this.driverDetailsDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    console.log(response);
                    this.driverDetailsDtoGetResult = response;
                    $("#"+this.pageId+"driverMasterDatatabelId").DataTable().destroy();
                    this.dtTriggerDriverName.next();
                    //                    console.log( this.driverDetailsDtoGetResult[5] );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Driver Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    //    service ends
    //for the select option with filter ends


    validateDriverDetails() {

        var validateDriverName = this.driverDetailsDto.driverName;
        var validateDriverLicNo = this.driverDetailsDto.licenceNo;
        var validateDriverMobileNumber = this.driverDetailsDto.mobileNo;
        var validateDriverAddress = this.driverDetailsDto.address;
        var validateDriverPincode = this.driverDetailsDto.pin;
        this.validateStateName_save = $("#"+this.pageId+"regStateName").val();

        if ((validateDriverName == null) || (validateDriverName == undefined) || (validateDriverName == '') ||
            (validateDriverLicNo == null) || (validateDriverLicNo == undefined) || (validateDriverLicNo == '') ||
            (validateDriverMobileNumber == null) || (validateDriverMobileNumber == undefined) ||
            (validateDriverMobileNumber == '') || (validateDriverAddress == null) || (validateDriverAddress == undefined) ||
            (validateDriverAddress == '') || (validateDriverPincode == null) || (validateDriverPincode == undefined) ||
            (validateDriverPincode == '') || (this.validateStateName_save == null) ||
            (this.validateStateName_save == undefined) || (this.validateStateName_save == '')) {
            swal("Mandatory Field", "Please Enter the Mandetory Fields", "warning");
            return false;
        } else {
            this.saveUpdateDriverDetails();
        }
    }
    getDriverDetailsForSave() {
        this.driverDetailsDto.status = "Working";
        this.driverDetailsDto.id = this.selectedDriverId;
        this.driverDetailsDto.userId = this.userDataDtoReturnSession.userId;
        this.driverDetailsDto.companyId = this.userDataDtoReturnSession.companyId;

        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDetailsDto.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.driverDetailsDto.mainStation = this.userDataDtoReturnSession.office;
        }

        if ($("#"+this.pageId+"regStateName").val() != null && $("#"+this.pageId+"regStateName").val() != "" && $("#"+this.pageId+"regStateName").val() != "NA") {
            this.regStateName = $("#"+this.pageId+"regStateName").val();
            this.driverDetailsDto.DLIssState = this.regStateName;
            if (this.modelRegState != null && this.modelRegState != undefined) {
                this.driverDetailsDto.DLIssStateCode = this.modelRegState.stateCode;
            }
        }
        if (this.isUploaded) {
            if ($("#"+this.pageId+"driverLicenseFileUpload").val() != null && $("#"+this.pageId+"driverLicenseFileUpload").val() != "") {
                const constImgUrlLic = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#"+this.pageId+"licenceNo").val() + "_" + this.selectedFileDriverDetailsForLicense.name + "";
                this.driverDetailsDto.imgURL = constImgUrlLic;
                this.driverDetailsDto.imgUploaded = true;
            }
        } else {
            this.driverDetailsDto.imgUploaded = false;
        }

    }

    saveUpdateDriverDetails = () => {
        this.getDriverDetailsForSave();
        console.log(this.driverDetailsDto)
        this.masterService.createDriver(this.driverDetailsDto).
            subscribe((data) => {
                this.driverDetailsDtoSaveRet = data;
                if (this.driverDetailsDtoSaveRet.status == "persisted") {
                    swal({
                        title: "Success",
                        text: "Driver Details Saved/Update Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getDriverNameList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Driver Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Driver Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        //  $( "#dlIssueState" ).val( 'Select State' );
        $("#"+this.pageId+"address").val('');
        this.selectedDriverId = 0;
        this.regStateName = null;
        this.regStateCode = null;
        this.validateStateName_save = null;
        this.rowSelectedStateName = null;
        this.isUploaded = false;
        $("#"+this.pageId+"driverLicenseFileUpload").val('');
        //        console.log( this.selectedDriverId );
    }

    //to insert value of selected row in table to input field starts
    rowSelected(driverNameData) {
        console.log(driverNameData);
        this.driverDetailsDto.driverName = driverNameData.driverName;
        this.driverDetailsDto.licenceNo = driverNameData.licenceNo;
        this.driverDetailsDto.mobileNo = driverNameData.mobileNo;
        this.driverDetailsDto.address = driverNameData.address;
        //this.driverDetailsDto.DLIssState = driverNameData.DLIssState;
        this.driverDetailsDto.pin = driverNameData.pin;
        this.driverDetailsDto.imgURL = driverNameData.imgURLIMB;
        this.selectedDriverId = driverNameData.id;
        //        console.log( driverNameData.id );
        //        console.log( this.selectedDriverId );
        if (driverNameData.dlissState != null && driverNameData.dlissState != "") {
            this.rowSelectedStateName = driverNameData.dlissState;
            $("#"+this.pageId+"regStateName").val(this.rowSelectedStateName);
        }
    }
    //to insert value of selected row in table to input field ends

    ngAfterViewInit() {
        this.dtTriggerDriverName.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateDeleteRow() {
        this.deleteDriverDetails();
    }

    getDeleteDriverDetails() {
        this.driverDetailsDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDetailsDeleteDto.driverName = this.driverDetailsDto.driverName
        this.driverDetailsDeleteDto.userId = this.userDataDtoReturnSession.userId;
        this.driverDetailsDeleteDto.id = this.selectedDriverId;

    }

    deleteDriverDetails = () => {
        this.getDeleteDriverDetails();
        this.masterService.deleteDriver(this.driverDetailsDeleteDto).
            subscribe((data) => {
                this.driverDetailsDeleteRet = data;
                if (this.driverDetailsDeleteRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Driver Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getDriverNameList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Deleting Driver Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Deleting Driver Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    clickListnerForRegState(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelRegState = e.item;
        console.log(this.modelRegState);
        $("#"+this.pageId+"regStateName").val(this.modelRegState.state);
        $("#"+this.pageId+"pincode").focus();
    }

    getStatesMethod() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masteRreadService.getStateDetailsService(this.partyMasterDto).subscribe(
            (response) => {
                if (response) {
                    // console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No getStatesMethod records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = [];
                        this.modelRegStateTA = [];
                    } else {
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = response;
                        this.modelRegStateTA = [];
                        for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
                            this.modelRegStateTA.push(this.partyMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting getStatesMethod",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }
    focusRegStateTA(e) {
        console.log(e);
        if (e.keyCode == 13) {
            $("#"+this.pageId+"pincode").focus();
        }
    }

    onFileChangedDriverLicense(event) {
        this.selectedFileDriverDetailsForLicense = event.target.files[0];
        // console.log(this.selectedFileDriverDetailsForLicense);
    }
    uploadFileForDriverLicBtnUpload() {
        if ($("#"+this.pageId+"driverLicenseFileUpload").val() != null && $("#"+this.pageId+"driverLicenseFileUpload").val() != "") {
            if ((this.selectedFileDriverDetailsForLicense.type != null &&
                (this.selectedFileDriverDetailsForLicense.type == "image/jpeg" ||
                    this.selectedFileDriverDetailsForLicense.type == "image/jpg" ||
                    this.selectedFileDriverDetailsForLicense.type == "image/png"))) {
                var splitTypeDriverLic = this.selectedFileDriverDetailsForLicense.type.split("/");
                const commonListDriverLic = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeDriverLic[1] + "&&" + $("#"+this.pageId+"licenceNo").val() + "";
                const formData = new FormData();
                formData.append(commonListDriverLic, commonListDriverLic);
                formData.append('myfileLic', this.selectedFileDriverDetailsForLicense, this.selectedFileDriverDetailsForLicense.name);
                this.masteRreadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                this.isUploaded = true;
                                swal("File Upload", "File Uploaded Succesfully", "success");
                            } else {
                                swal("ERROR", "Correct the File and Upload Again", "warning");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal("Error", "Server Error While Uploading a File", "error"),
                    () => console.log('done');
            } else {
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload for RC File", "warning");
            }
        }
    }

    getLicenceImg(driverNameData) {
        console.log(driverNameData);
        if (driverNameData.imgURL == null || driverNameData.imgURL == undefined ||
            driverNameData.imgURL == "NA") {
            swal("Licence", "Licence Not Uploaded", "info");
            return false;
        } else {
            window.open(driverNameData.imgURL, '_blank');
        }
    }

    customPrintDriverMaster() {
        if (this.driverDetailsDtoGetResult.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Driver Name", "Licence No", "Mobile", "Address", "DL Issue State", "Pin", "Licence"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [15, 15, 10, 25, 15, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            for (let i = 0; i < this.driverDetailsDtoGetResult.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.driverDetailsDtoGetResult[i].driverName, this.driverDetailsDtoGetResult[i].licenceNo, this.driverDetailsDtoGetResult[i].mobileNo
                    , this.driverDetailsDtoGetResult[i].address, this.driverDetailsDtoGetResult[i].dlissState, this.driverDetailsDtoGetResult[i].pin, this.driverDetailsDtoGetResult[i].imgURLIMB];



                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.driverDetailsDtoGetResult.length, "", "", "", "", "", ""];
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Driver Master");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}
