import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from 'src/app/dataService/masterread-service';
//import { NgxImageCompressService } from "ngx-image-compress";
// declare var ImageCompressor: any;
// const compressor = new ImageCompressor();

@Component({
    selector: 'app-cashmemo-pod-upload',
    templateUrl: './cashmemo-pod-upload.component.html',
    styleUrls: ['./cashmemo-pod-upload.component.css']
})
export class CashmemoPodUploadComponent implements OnInit {

    pageId = "cmpod";
    showSpinnerForActionForUpload = false;
    isLoggedIn = true;
    urls = new Array<string>();
    selectedFiles: File[] = [];
    selectedFileCDetails: any;
    formData = new FormData();
    blockedFiles: string;

    constructor(public changeDetectorRef: ChangeDetectorRef, private router: Router,
        private masterReadService: MasterReadService,
        // private imageCompress: NgxImageCompressService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
    }

    ngOnInit(): void {

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    onFileChanged(event) {
        console.log(event.target.files.length);
        this.urls = [];
        this.blockedFiles = null;
        let files = event.target.files;
        if (files) {
            for (let file of files) {
                //console.log("Size in bytes of the uploaded image was:", this.imageCompress.byteCount(file));
                //log / access file size in bytes
                console.log(file.size + ' Bytes');

                //log / access file size in Mb
                let fileSize = file.size / 1024 / 1024;
                console.log(file.size / 1024 / 1024 + ' MB');
                if (fileSize < 1) {
                    this.selectedFiles.push(file);
                    let reader = new FileReader();
                    reader.onload = (e: any) => {
                        this.urls.push(e.target.result);
                    }
                    reader.readAsDataURL(file);
                    this.changeDetectorRef.detectChanges();
                } else {
                    this.blockedFiles = this.blockedFiles + " & " + file.name;
                }
                // this.selectedFiles.push(compressor.compress(file, { quality: .5 }));
                // this.imageCompress
                //     .compressFile(file, 50, 50) // 50% ratio, 50% quality
                //     .then(
                //         (compressedImage) => {
                //             console.log("Size in bytes after compression is now:", this.imageCompress.byteCount(compressedImage));
                //         }
                //     );
            }
        }
        console.log('blocked');
        console.log(this.blockedFiles);
        console.log(this.selectedFiles.length);
        if (this.blockedFiles != null) {
            swal("Warning", "Listed files size are more than 1MB, please reduce the size and upload again!" + this.blockedFiles, "warning");
        }
    }

    removeImage(i) {
        this.urls.splice(i, 1);
    }

    uploadFilesBtn() {
        if ($("#" + this.pageId + "podLrFileUpload").val() != null && $("#" + this.pageId + "podLrFileUpload").val() != "") {
            this.showSpinnerForActionForUpload = true;
            const commonList = "vehicledriverdocs&&truckownerdeclaration";
            this.formData.append(commonList, commonList);
            let count = 0;
            console.log('file');
            for (let i = 0; i < this.selectedFiles.length; i++) {
                count++;
                this.selectedFileCDetails = this.selectedFiles[i];

                //log / access file size in bytes
                console.log(this.selectedFileCDetails.size + ' Bytes');

                //log / access file size in Mb
                console.log(this.selectedFileCDetails.size / 1024 / 1024 + ' MB');

                this.formData.append('uploadedFile' + count, this.selectedFileCDetails, this.selectedFileCDetails.name);
            }
            this.masterReadService.uploadFileMemoPod(this.formData).subscribe(
                (response) => {
                    if (response) {
                        this.showSpinnerForActionForUpload = false;
                        if (response[0] != "Error") {
                            this.clearFields();
                            swal("File Upload", "File Uploaded Succcessfully", "success");
                        } else {
                            swal("ERROR", "Correct the File and Upload Again", "error");
                        }
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForActionForUpload = false;
                    swal("Error", "Server Error While Uploading File", "info");
                }, () => console.log('done');

        }
    }

    clearFields() {
        $("#" + this.pageId + "podLrFileUpload").val('');
        this.selectedFileCDetails = null;
        this.selectedFiles = [];
        this.formData = new FormData();
    }
}