<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Halt And Dispatched Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Date</label> <input id="{{pageId}}date" class="form-control" placeholder="yyyy-mm-dd" name="dates" ngbDatepicker #dates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="dates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Agent Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-road"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destinationId" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getReportDetails()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearFields()">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <table datatable id="{{pageId}}haltAndDispRtTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsHaltAndDispatchedDetails" [dtTrigger]="dtTriggerHaltAndDispatchedDetails">

                                <thead>
                                    <tr>
                                        <th>Truck Number</th>
                                        <th>Broker Name</th>
                                        <th>Departure Date</th>
                                        <th>Invoice Number</th>
                                        <th>Loader Name</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let haltAndDispatchedDetailsData of haltAndDispatchedDetailsDataList ">
                                        <td>{{ haltAndDispatchedDetailsData.vehicleNumber }}</td>
                                        <td>{{ haltAndDispatchedDetailsData.suppliername }}</td>
                                        <td>{{ haltAndDispatchedDetailsData.hireSlipDateStr }}</td>
                                        <td>{{ haltAndDispatchedDetailsData.hireslipnumber }}</td>
                                        <td>{{ haltAndDispatchedDetailsData.loadedby }}</td>
                                        <td>{{ haltAndDispatchedDetailsData.remarks }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>


                        </div>
                        <div class="row srd_patch m-l-10 m-r-10">
                            <div class="col-md-6">
                                <p style="margin-bottom: 1px;" class="card-title m-b-1">Dispatched</p>
                            </div>
                            <div class="col-md-6" style="text-align: center;">
                                <p style="margin-bottom: 1px;" class="card-title">{{totDispatchedPerc}}</p>
                            </div>
                        </div>

                        <div class="row srd_patch m-l-10 m-r-10">
                            <div class="col-md-6">
                                <p style="margin-bottom: 1px;" class="card-title m-b-1">Halt</p>
                            </div>
                            <div class="col-md-6" style="text-align: center;">
                                <p style="margin-bottom: 1px;" class="card-title">{{totHaltPerc}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>