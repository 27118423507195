<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}driverMasterId">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Driver Master</h6>
			</div>

			<div class="row system_responsive" style="margin-bottom: 10px;">
				<!-- spinner start-->
				<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<mat-progress-bar mode="indeterminate" style="color: green;">
							</mat-progress-bar>
							<br>
							<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
								Please Wait Loading Details.....</h6>
						</div>
					</div>
				</div>
				<!-- spinner end-->
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Driver Name*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
													</div>
													<input #driverName (keyup)="fieldFocus($event, licenceNo)"
														type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}driverName"
														name="driverName" [(ngModel)]="driverDetailsDto.driverName">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>License Number*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-id-card"></i>
														</span>
													</div>
													<input #licenceNo (keyup)="fieldFocus($event, mobileNo)" type="text"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}licenceNo" name="licenceNo"
														[(ngModel)]="driverDetailsDto.licenceNo">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Mobile Number*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-phone"></i>
														</span>
													</div>
													<input #mobileNo (keyup)="fieldFocus($event, address)" type="number"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}mobileNo" name="mobileNo"
														[(ngModel)]="driverDetailsDto.mobileNo">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Address*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-hashtag"></i>
														</span>
													</div>
													<textarea #address (keyup)="fieldFocus($event, state)"
														class="form-control " rows="1" id="{{pageId}}address" name="address"
														[(ngModel)]="driverDetailsDto.address"></textarea>
												</div>
											</div>
										</div>
										<!-- 											the first autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>DL Issue State*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input #stateName id="{{pageId}}regStateName" type="text"
															class="form-control"
															(selectItem)="clickListnerForRegState($event)"
															[(ngModel)]="modelRegState" [ngbTypeahead]="searchRegState"
															[resultFormatter]="formatterRegState"
															[inputFormatter]="formatterRegState"
															(focus)="focusRegStateTA$.next($any($event).target.value)"
															autocomplete="off" (keyup)="fieldFocus($event, pin)" />
													</div>
												</div>
											</div>
										</div>
										<!-- 											the first autocomplete ends -->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Pincode*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-hashtag"></i>
														</span>
													</div>
													<input #pin (keyup)="fieldFocus($event, imgURL)" type="number"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}pincode" name="pincode" [(ngModel)]="driverDetailsDto.pin">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>License*</label>
													<!--<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-id-card"></i>
														</span>
													</div>-->
													<!--<input #imgURL (keyup)="fieldFocus($event, saveBtns)"
														type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}imgURL" name="imgURL"
														[(ngModel)]="driverDetailsDto.imgURL">-->
													<input type="file" id="{{pageId}}driverLicenseFileUpload"
														(change)="onFileChangedDriverLicense($event)"
														#fileInputDriverMasterForLicense />
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<button type="button" class="btn btn-dark" id="{{pageId}}updateBtnId"
												(click)="uploadFileForDriverLicBtnUpload()">Upload
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button #saveBtns type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtns"
							(click)="validateDriverDetails();">Save/Update</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}deleteBtn"
							(click)="validateDeleteRow()">Delete</button>
					</div>
				</div>
				<div class="col-md-9 vl">
					<div class="row p-t-10">
						<div class="col-md-8">
							<button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px;"
								id="{{pageId}}getDetailsBtn" (click)="getDriverNameList()">
								<i class="fas fa-file-alt"></i> Get Details of Driver Master
							</button>
						</div>
					</div>
					<div class="row p-t-10">
						<div class="col-md-8">
							<h6 class="card-title">Driver Master</h6>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12">
								<button type="submit" class="dt-button" style="margin-left: 91%;" (click)="customPrintDriverMaster()" id="{{pageId}}printAllBtn">
										<span><i class="fas fa-print">Print</i></span>
									</button>
							<div class="card">
								<div class="card-body">
									<div class="box-body">
										<table datatable id="{{pageId}}driverMasterDatatabelId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsDriverName" [dtTrigger]="dtTriggerDriverName">

											<thead>
												<tr>
													<th>Driver Name</th>
													<th>License No</th>
													<th>Mobile</th>
													<th>Address</th>
													<th>DL Issue State</th>
													<th>Pin</th>
													<th>Licence</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let driverNameData of driverDetailsDtoGetResult"
													(click)="rowSelected(driverNameData);">
													<td>{{ driverNameData.driverName }}</td>
													<td>{{ driverNameData.licenceNo }}</td>
													<td>{{ driverNameData.mobileNo }}</td>
													<td>{{ driverNameData.address }}</td>
													<td>{{ driverNameData.dlissState }}</td>
													<td>{{ driverNameData.pin }}</td>
													<td>
														<a style='cursor: pointer;color: blue;'
															(click)="getLicenceImg(driverNameData);" class="pointer">
															<u>{{ driverNameData.imgURL == null ? "Licence Not Uploaded" :
																						driverNameData.imgURL == "NA" ? "Licence Not Uploaded" :
																									"View Licence Document" }}</u></a>
														{{ driverNameData.imgURLIMB }}</td>
												</tr>


												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>
											<!-- 										<tfoot> -->
											<!-- 											<tr> -->
											<!-- 												<td style="text-align: left">Total</td> -->
											<!-- 												<td></td> -->
											<!-- 												<td></td> -->
											<!-- 												<td></td> -->
											<!-- 												<td></td> -->
											<!-- 												<td></td> -->
											<!-- 												<td></td> -->
											<!-- 											</tr> -->
											<!-- 										</tfoot> -->
										</table>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>