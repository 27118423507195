import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends

@Component({
	selector: 'app-lr-details',
	templateUrl: './lr-details.component.html',
	styleUrls: ['./lr-details.component.css']
})
export class LrDetailsComponent implements OnInit {

	//for datatable starts
	gettingDataFrmServiceFrLrDetailsTable: any;


	lrDetailsDataList: any;


	onDestroyUnsubscribtionLrDetails: Subscription;


	//for datatable ends

	//summaryTable:any;

	loadingIndicator = true;

	//for datatable starts
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerLrDetails: Subject<any> = new Subject();

	dataTable: any;
	dtOptionsLrDetails: any;
	dataForPopupFromTransitDetails: any;
	dataForPopupFromTransitOtherDetails: any;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	getInvoiceNumber: any;
	pageId="lrdc";

	constructor(/* for datatable starts */private pendingLrDetailsScreen: DashboardService, private router: Router,/* for datatable endss */
		//for modal starts
		public dialogRef: MatDialogRef<LrDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
		//for modal ends
	) {
		console.log(localStorage);

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.dataForPopupFromTransitDetails = JSON.parse(localStorage.getItem('transitLrsInvoiceNumberPopup'));
			this.dataForPopupFromTransitOtherDetails = JSON.parse(localStorage.getItem('transitLrsPopup'));
			localStorage.clear();
			console.log('1');
			console.log(this.dataForPopupFromTransitOtherDetails, this.dataForPopupFromTransitDetails);

			///For Transit Lr's
			if (this.dataForPopupFromTransitOtherDetails != null) {
				$("#"+this.pageId+"lrDetailsId").DataTable().destroy();
				this.lrDetailsDataList = [];
				this.lrDetailsDataList = this.dataForPopupFromTransitOtherDetails;
				this.dtTriggerLrDetails.next();
				console.log(this.lrDetailsDataList);

			}

			if (this.dataForPopupFromTransitDetails != null) {
				this.getInvoiceNumber = this.dataForPopupFromTransitDetails.invoiceNumber;
			}

			this.dataForPopupFromTransitOtherDetails = [];
			this.dataForPopupFromTransitDetails = '';

		}

	}

	/* for datatable starts */
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}
	/* for datatable ends */
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsLrDetails = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnSummaryExcelCashmemoStockSum = null;
						returnSummaryExcelCashmemoStockSum = "  Lr Details ";
						return returnSummaryExcelCashmemoStockSum;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			pagingType: 'full_numbers',
			pageLength: 5,

			processing: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totArt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actWght = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chgWgt = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var goodsVal = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(totArt);
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html(toPay);
				$(api.column(5).footer()).html(paid);
				$(api.column(6).footer()).html(actWght);
				$(api.column(7).footer()).html(chgWgt);
				$(api.column(8).footer()).html(goodsVal);

			}
		}


	}

	ngOnDestroy(): void {
		this.dtTriggerLrDetails.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerLrDetails.next();

	}

	//for modal starts
	onCancelClick(): void {
		this.dialogRef.close();
	}
	//for modal ends
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
}
