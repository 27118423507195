import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { Observable, Subject, merge } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

@Component({
    selector: 'app-consignor-merge',
    templateUrl: './consignor-merge.component.html',
    styleUrls: ['./consignor-merge.component.css']
})
export class ConsignorMergeComponent implements OnInit {
    consignorName: string;
    selectedUser: any;
    repeatedConsignorListDataList: any;
    confirmedConsignorListDataList: any;
    closeResultContent: string;

    dtTriggerRepeatedConsignorList: Subject<any> = new Subject();
    dtTriggerMergeConsignorList: Subject<any> = new Subject();
    dtTriggerConfirmedConsignorList: Subject<any> = new Subject();

    dtOptionsRepeatedConsignorList: any;
    dtOptionMergeConsignorList: any;
    dtOptionConfirmedConsignorList: any;
    pageId = "comgc";

    //new starts
    consignorIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    showSpinnerForAction = false;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndex: any;
    consignorNameOptions: any;
    public modelConsignorNameId: any;
    selectedConsignorId: any;
    selectedSource: any;
    partyDtoIdList: Array<PartyMasterDto> = [];
    partyDtoIdListForConfirmBtn: Array<PartyMasterDto> = [];
    partyMasterDtoConfrimBtnConsignor: PartyMasterDto = new PartyMasterDto();
    mergeReturnDto: any;
    selectedRowForUndo: any;
    oldCgnorName: any;
    oldConsignorId = 0;
    partyMasterDtoUndoBtn: PartyMasterDto = new PartyMasterDto();
    private newAttributeForMerge: any = {};
    mergeConsignorListDataList: Array<any> = [];
    private newAttributeForRepeat: any = {};
    selectedConsignorName: any;
    partyDtoIdListForMergeBtn: Array<PartyMasterDto> = [];
    partyMasterDtoMergeBtnList: PartyMasterDto = new PartyMasterDto();

    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    public modelConsignorIndexConfirmed: any;
    @ViewChild('instanceConsignorIndexConfirmed') instanceConsignorIndexConfirmed: NgbTypeahead;
    consignorIndexTAConfirmed: Array<any> = [];
    focusConsignorIndexTAConfirmed$ = new Subject<string>();
    consignorIndexSearchTAConfirmed = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTAConfirmed$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTAConfirmed
                : this.consignorIndexTAConfirmed.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    //Main Src
    agentDtoForMainSrc: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionsForMainSrc: AgentDetailsDto[];
    public modelForMainSrc: any;
    mainSrcTA: Array<AgentDetailsDto> = [];
    focusMainSrcTA$ = new Subject<string>();
    searchMainSrcAt = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainSrcTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainSrcTA
                : this.mainSrcTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainSrc = (x: { mainBookStations: string }) => x.mainBookStations;

    public modelForMainSrcConfirmed: any;
    mainSrcTAConfirmed: Array<AgentDetailsDto> = [];
    focusMainSrcTAConfirmed$ = new Subject<string>();
    searchMainSrcAtConfirmed = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainSrcTAConfirmed$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainSrcTAConfirmed
                : this.mainSrcTAConfirmed.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainSrcConfirmed = (x: { mainBookStations: string }) => x.mainBookStations;

    public modelForMainSrcMerge: any;
    mainSrcTAMerge: Array<AgentDetailsDto> = [];
    focusMainSrcTAMerge$ = new Subject<string>();
    searchMainSrcAtMerge = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainSrcTAMerge$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainSrcTAMerge
                : this.mainSrcTAMerge.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainSrcMerge = (x: { mainBookStations: string }) => x.mainBookStations;

    //for ConsignorName
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;

    constructor(private consignorMerge: ConsignorService, public changeDetectorRef: ChangeDetectorRef,
        private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService, private consignorService: ConsignorService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.setConsginorIndex();
            this.getMainSrcDetails();
        }
    }
    ngOnInit(): void {
        this.dtOptionsRepeatedConsignorList = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
        },
            this.dtOptionMergeConsignorList = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 330,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            },
            this.dtOptionConfirmedConsignorList = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerRepeatedConsignorList.unsubscribe();
        this.dtTriggerMergeConsignorList.unsubscribe();
        this.dtTriggerConfirmedConsignorList.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerRepeatedConsignorList.next();
        this.dtTriggerMergeConsignorList.next();
        this.dtTriggerConfirmedConsignorList.next();
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected(confirmedConsignorListData) {
        this.selectedUser = confirmedConsignorListData;
        this.consignorName = this.selectedUser.name;
        console.log(this.selectedUser.name);
    }
    //to insert value of selected row in table to input field ends


    //for popup modal starts 

    openPopup(content) {
        this.modalService.open(content, { centered: true }).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

    // new starts
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
            this.consignorIndexTAConfirmed.push(this.consignorIndexOptions[i].label);
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }

    getMainSrcMethod() {
        this.agentDtoForMainSrc = new AgentDetailsDto();
        this.agentDtoForMainSrc.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainSrcDetails() {
        this.getMainSrcMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoForMainSrc).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.agentDtoOptionsForMainSrc = [];
                    this.mainSrcTA = [];
                    this.mainSrcTAConfirmed = [];
                    this.mainSrcTAMerge = [];
                    if (response.length > 0) {
                        this.agentDtoOptionsForMainSrc = response;
                        console.log(this.agentDtoOptionsForMainSrc);
                        this.mainSrcTA = [];
                        this.mainSrcTAConfirmed = [];
                        this.mainSrcTAMerge = [];
                        for (let i = 0; i < this.agentDtoOptionsForMainSrc.length; i++) {
                            this.mainSrcTA.push(this.agentDtoOptionsForMainSrc[i]);
                            this.mainSrcTAConfirmed.push(this.agentDtoOptionsForMainSrc[i]);
                            this.mainSrcTAMerge.push(this.agentDtoOptionsForMainSrc[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
            },
            () => console.log('done');
    }

    consignorDropDownListnerPending(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsListPending();
    }

    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
    }

    getConsignorDetailsListPending() {
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;
        this.partyMasterDtoForconsignor.isConfirmed = false;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.mainStation = this.selectedSource;
        this.getConsignorDetails();
    }

    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.consignorService.getConsignorRepeatDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.repeatedConsignorListDataList = [];
                $("#" + this.pageId + "repeatedConsignorId").DataTable().destroy();
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.repeatedConsignorListDataList = response;
                }
                this.dtTriggerRepeatedConsignorList.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    consignorDropDownListnerConfirmed(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndexConfirmed = e.item;
        this.getConsignorDetailsListConfirmed();
    }

    getConsignorDetailsListConfirmed() {
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndexConfirmed;
        this.selectedSource = $("#" + this.pageId + "sourceConfirmed").val();
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;
        this.partyMasterDtoForconsignor.isConfirmed = true;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.mainStation = this.selectedSource;
        this.getConsignorDetailsConfirmed();
    }
    getConsignorDetailsConfirmed = () => {
        this.showSpinnerForAction = true;
        this.consignorService.getConsignorRepeatDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.confirmedConsignorListDataList = [];
                $("#" + this.pageId + "confirmedConsigorTableId").DataTable().destroy();
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.confirmedConsignorListDataList = response;
                }
                this.dtTriggerConfirmedConsignorList.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    rowCheckBoxChecked(e, repeatedConsignorListData) {
        if (e.currentTarget.checked) {
            if (this.partyDtoIdList.length == 0) {
                this.partyDtoIdList = [];
                this.partyDtoIdList.push(repeatedConsignorListData);
            } else {
                this.partyDtoIdList.push(repeatedConsignorListData);
            }
        } else {
            let index = this.partyDtoIdList.indexOf(repeatedConsignorListData);
            if (index > -1) {
                this.partyDtoIdList.splice(index, 1);
            }
        }
        console.log(this.partyDtoIdList);
    }

    //On Confirm Btn Clicked
    confrimBtnMethod() {
        if (this.partyDtoIdList.length == 0) {
            swal({
                title: "Empty Selection",
                text: "Please select Consignor name to confirm!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Individual",
                text: "Sure you want to Confirm the selected consignor as an Individual?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((update) => {
                if (update) {
                    this.getUserValuesForConfirm();
                } else {
                    //do nothing
                }
            });
        }
    }

    getUserValuesForConfirm() {
        this.partyDtoIdListForConfirmBtn = [];
        for (let i = 0; i < this.partyDtoIdList.length; i++) {
            this.partyMasterDtoConfrimBtnConsignor = new PartyMasterDto();
            this.partyMasterDtoConfrimBtnConsignor.userName = this.userDataDtoReturnSession.userId;
            this.partyMasterDtoConfrimBtnConsignor.consignorId = this.partyDtoIdList[i].consignorId;
            this.partyMasterDtoConfrimBtnConsignor.consignorName = this.partyDtoIdList[i].consignorName;
            this.partyDtoIdListForConfirmBtn.push(this.partyMasterDtoConfrimBtnConsignor);
        }
        console.log(this.partyDtoIdListForConfirmBtn);
        this.getUserValuesForConfirmInfo();
    }
    getUserValuesForConfirmInfo = () => {
        this.showSpinnerForAction = true;
        this.consignorService.consignorConfirmed(this.partyDtoIdListForConfirmBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.mergeReturnDto = response;
                if (this.mergeReturnDto.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Selected Consignors confirmed successfully!",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.partyDtoIdList = [];
                    this.partyDtoIdListForConfirmBtn = [];
                    this.getConsignorDetailsListPending();
                    this.getConsignorDetailsListConfirmed();
                } else {
                    swal({
                        title: "Failed",
                        text: "Problem occured while confirm the consignor",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While  the Confirmed Consignor  details", "info");
            }, () => console.log('done');
    };

    rowSelectedForUndo(confirmedConsignorListData) {
        this.selectedRowForUndo = '';
        this.selectedRowForUndo = confirmedConsignorListData;
        if (confirmedConsignorListData.consignorName != null) {
            this.consignorName = this.selectedRowForUndo.consignorName;
            $("#" + this.pageId + "consignorNameConfirmed").val(this.selectedRowForUndo.consignorName);
            this.oldCgnorName = this.selectedRowForUndo.consignorName;
            this.oldConsignorId = this.selectedRowForUndo.consignorId;
        }
    }
    //On Undo Btn Clicked
    undoBtnMethod() {
        if (this.selectedRowForUndo.consignorName == '' || this.selectedRowForUndo.consignorName == null) {
            swal({
                title: "Empty Selection",
                text: "Please select Consignor name to Undo",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Undo",
                text: "Sure you want to put back the selected Consignor in Unconfirm list?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((update) => {
                if (update) {
                    this.getUserValuesForUndo();
                } else {
                    //do nothing
                }
            });

        }
    }
    getUserValuesForUndo() {
        this.partyMasterDtoUndoBtn = new PartyMasterDto();
        this.partyMasterDtoUndoBtn.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDtoUndoBtn.consignorId = this.oldConsignorId;
        this.partyMasterDtoUndoBtn.companyId = this.userDataDtoReturnSession.companyId;
        this.getUserValuesForUndoInfo();
    }
    getUserValuesForUndoInfo = () => {
        this.showSpinnerForAction = true;
        this.consignorService.undoCgnorMerge(this.partyMasterDtoUndoBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.mergeReturnDto = response;
                if (this.mergeReturnDto.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Undo done for the selected Consignor!",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#" + this.pageId + "consignorNameConfirmed").val('');
                    this.oldCgnorName = '';
                    this.oldConsignorId = 0;
                    this.partyDtoIdList = [];
                    this.selectedRowForUndo = '';
                    this.getConsignorDetailsListConfirmed();
                    this.getConsignorDetailsListPending();
                } else if (this.mergeReturnDto.status == 'NotExist') {
                    swal({
                        title: "Failed",
                        text: "Consignor does not exist!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    swal({
                        title: "Failed",
                        text: "Problem occur while Undo the Consignor!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while the Undo Consignor!", "info");
            }, () => console.log('done');
    }

    moveToMergeMethod() {
        if (this.partyDtoIdList.length == 0) {
            swal({
                title: "Empty Selection",
                text: "Please select consignor name to Move",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.addInMergeTable();
        }
    }
    addInMergeTable() {
        this.newAttributeForMerge = {};
        for (let i = 0; i < this.partyDtoIdList.length; i++) {
            console.log(this.partyDtoIdList[i].consignorId);
            this.newAttributeForMerge.consignorName = this.partyDtoIdList[i].consignorName;
            this.newAttributeForMerge.consignorId = this.partyDtoIdList[i].consignorId;
            this.newAttributeForMerge.gstNoConsignor = this.partyDtoIdList[i].gstNoConsignor;
            $("#" + this.pageId + "mergeConsignorId").DataTable().destroy();
            if (this.mergeConsignorListDataList == null) {
                this.mergeConsignorListDataList = [];
            }
            if (this.mergeConsignorListDataList.length == 0) {
                this.mergeConsignorListDataList.push(this.newAttributeForMerge);
            } else {
                this.mergeConsignorListDataList.push(this.newAttributeForMerge);
            }
            //For Removing
            let index = -1;
            for (let j = 0; j < this.repeatedConsignorListDataList.length; j++) {
                if (this.repeatedConsignorListDataList[j].consignorId === this.partyDtoIdList[i].consignorId) {
                    index = j;
                    break;
                }
            }
            if (index === -1) {
                alert("Something gone wrong");
            }
            this.repeatedConsignorListDataList.splice(index, 1);
            this.newAttributeForMerge = {};
        }
        this.partyDtoIdList = [];
        this.dtTriggerMergeConsignorList.next();
        $("#" + this.pageId + "repeatedConsignorId").DataTable().destroy();
        this.dtTriggerRepeatedConsignorList.next();
    }

    // For Delete From Merge Table
    rowSelectedDeleteForMerge(mergeConsignorListData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Row",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.mergeConsignorListDataList.splice(index, 1);
                $("#" + this.pageId + "mergeConsignorId").DataTable().destroy();
                this.dtTriggerMergeConsignorList.next();
                $("#" + this.pageId + "consignorNameConfirmed").val('');
                this.oldCgnorName = '';
                this.oldConsignorId = 0;
                this.addInRepeatedConsigneeTable(mergeConsignorListData);
            }
        });
    }

    addInRepeatedConsigneeTable(mergeConsignorListData) {
        this.newAttributeForRepeat = {};

        this.newAttributeForRepeat.consignorName = mergeConsignorListData.consignorName;
        this.newAttributeForRepeat.consignorId = mergeConsignorListData.consignorId;
        this.newAttributeForRepeat.gstNoConsignor = mergeConsignorListData.gstNoConsignor;
        $("#" + this.pageId + "repeatedConsignorId").DataTable().destroy();
        if (this.repeatedConsignorListDataList == null) {
            this.repeatedConsignorListDataList = [];
        }
        if (this.repeatedConsignorListDataList.length == 0) {
            this.repeatedConsignorListDataList.push(this.newAttributeForRepeat);
        } else {
            this.repeatedConsignorListDataList.push(this.newAttributeForRepeat);
        }
        this.newAttributeForRepeat = {};
        this.partyDtoIdList = [];
        this.dtTriggerRepeatedConsignorList.next();
    }

    mergeBtnMethod() {
        this.selectedConsignorName = $("#" + this.pageId + "consignorNameConfirmed").val();
        if (this.mergeConsignorListDataList.length == 0 || this.selectedConsignorName == null || this.selectedConsignorName == '' || this.selectedConsignorName == undefined) {
            swal({
                title: "Empty Selection",
                text: "Please select consignor name to merge",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Merge",
                text: "Sure you want to Merge the selected consignor",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((update) => {
                if (update) {
                    this.getUserValuesForMerge();
                } else {
                    //do nothing
                }
            });
        }
    }

    getUserValuesForMerge() {
        this.partyDtoIdListForMergeBtn = [];
        this.selectedConsignorName = $("#" + this.pageId + "consignorNameConfirmed").val()

        if (this.selectedConsignorName != null && this.selectedConsignorName != '') {
            this.selectedConsignorName = this.selectedConsignorName.trim().split(' ').filter(item => item.length > 0).join(' ');
        }
        for (let i = 0; i < this.mergeConsignorListDataList.length; i++) {
            this.partyMasterDtoMergeBtnList = new PartyMasterDto();
            this.partyMasterDtoMergeBtnList.oldCgnorName = this.oldCgnorName;
            this.partyMasterDtoMergeBtnList.oldConsignorId = this.oldConsignorId;

            this.partyMasterDtoMergeBtnList.userName = this.userDataDtoReturnSession.userId;
            this.partyMasterDtoMergeBtnList.consignorName = this.mergeConsignorListDataList[i].consignorName;
            this.partyMasterDtoMergeBtnList.consignorId = this.mergeConsignorListDataList[i].consignorId;
            this.partyDtoIdListForMergeBtn.push(this.partyMasterDtoMergeBtnList);
        }
        this.doMerge();
    }

    doMerge = () => {
        this.showSpinnerForAction = true;
        this.consignorService.consignorMerge(this.partyDtoIdListForMergeBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.mergeReturnDto = response;
                if (this.mergeReturnDto.status == 'Success') {
                    $("#" + this.pageId + "mergeConsignorId").DataTable().destroy();
                    this.mergeConsignorListDataList = [];
                    this.dtTriggerMergeConsignorList.next();
                    $("#" + this.pageId + "consignorNameConfirmed").val('');
                    this.oldCgnorName = '';
                    this.oldConsignorId = 0;
                    this.partyDtoIdList = [];
                    this.partyDtoIdListForMergeBtn = [];
                    this.selectedConsignorName = '';
                    this.consignorName = '';
                    swal({
                        title: "Success",
                        text: "Selected Consignors merged successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                    this.getConsignorDetailsListPending();
                    this.getConsignorDetailsListConfirmed();

                } else if (this.mergeReturnDto.status == 'Failed') {
                    swal({
                        title: "Failed",
                        text: "Problem occured while merging the consignor details!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem occured while merging the consignor details!", "info");
            }, () => console.log('done');
    };
}
