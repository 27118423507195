<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}viewShipmentStatus">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <div class="row">
                    <div class="col-md-4">
                        <h6 class="card-title text-white">Shipment Status</h6>
                    </div>
                    <div class="col-md-8 btn_bootstrap" style='text-align: right;'>
                        <button type="submit" class="customCss btn btn-secondary" id="{{pageId}}btnUnLoadRpt" (click)="unLoadMethod(unloadingSheetDetailsPopUpTemplate)" disabled>Get UnLoading
							Report</button>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-4 vl">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Invoice Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-clipboard"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}invoiceNumber" name="invoiceNumber" class="form-control" (keyup)="getInvoiceDetails($event,unloadingSheetDetailsPopUpTemplate,consigneeMergePopUpTemplate)" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Source</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-box"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}source" name="source" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-road"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}invoiceDestination" name="invoiceDestination" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Hire Slip Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-clipboard"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}hireSlipNumber" name="hireSlipNumber" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Lorry Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-truck"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}lorryNumber" name="lorryNumber" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Departure Date</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-calendar"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}departureDate" name="departureDate" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Departure Time</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-clock"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}departureTime" name="departureTime" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Driver Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}driverName" name="driverName" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- adding unloading details @Asrar - 19/08/2021 -->

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Unloader Head<span class="mandatoryField_Style">
																		* </span></label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="ti-home"></i>
																	</span>
                                                                </div>

                                                                <input #loaderHead id="{{pageId}}loaderHead" type="text" class="form-control" placeholder="Select Unloader" [ngbTypeahead]="loaderHeadSearchTA" (focus)="focusLoaderHeadTA$.next($any($event).target.value)" [resultFormatter]="formatterLoaderHead" [inputFormatter]="formatterLoaderHead"
                                                                    (selectItem)="selectedUnloader($event)" [(ngModel)]="modelUnloaderName" #instanceLoaderHead="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="showNewUnloader" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>New Unloader</label><span class="mandatoryField_Style"> * </span>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input type="text" id="{{pageId}}newUnloader" name="newUnloader" class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bay No <span class="mandatoryField_Style">
																	* </span>
															</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-search"></i>
																</span>
                                                            </div>
                                                            <!--(change)="selectBayNo(bayNumber.value)"-->
                                                            <select class="custom-select col-12" id="{{pageId}}bayNumber" name="bayNumber" #bayNumber (keypress)="onKeyPressFieldForBayNo($event.keyCode)">
																<option selected value="Select Bay">Select Bay</option>
																<option value="Bay 1">Bay 1</option>
																<option value="Bay 2">Bay 2</option>
																<option value="Bay 3">Bay 3</option>
																<option value="Bay 4">Bay 4</option>
																<option value="Bay 5">Bay 5</option>
																<option value="Bay 6">Bay 6</option>
																<option value="Bay 7">Bay 7</option>
																<option value="Bay 8">Bay 8</option>
																<option value="Bay 9">Bay 9</option>
																<option value="Bay 10">Bay 10</option>
																<option value="Bay 11">Bay 11</option>
																<option value="Bay 12">Bay 12</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Unloading Date <span class="mandatoryField_Style"> *
																</span>
															</label> <input class="form-control" id="{{pageId}}bayLoadingDates" [(ngModel)]="bayLoadingDateModel" placeholder="yyyy-mm-dd" name="bayLoadingDates" ngbDatepicker #bayLoadingDates="ngbDatepicker"
                                                                autocomplete="off" (keypress)="onKeyPressFieldForBayDate($event.keyCode)">
                                                            <div class="input-group-append" (click)="bayLoadingDates.toggle()">
                                                                <span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 ">
                                                    <div class="form-group ">
                                                        <div class="input-group ">
                                                            <label>Unloading Time <span class="mandatoryField_Style"> *
																</span>
															</label>
                                                            <div class="input-group-prepend ">
                                                                <span class="input-group-text "> <i
																		class="fas fa-clock"></i>
																</span>
                                                            </div>
                                                            <input type="time" class="form-control " id="{{pageId}}bayLoadingTime" autocomplete="off" aria-describedby="basic-addon11" (keypress)="onKeyPressFieldForBayTime($event.keyCode)">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Status<span class="mandatoryField_Style">
																	* </span></label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																		class="fas fa-clipboard"></i>
																</span>
                                                            </div>
                                                            <select id="{{pageId}}status" name="status" class="custom-select col-12">
																<option selected value=''>Select Status...</option>
																<option value="compound">Compound</option>
																<option value="stock">Stock</option>
															</select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" style="text-align: center;">
                                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateBtn" (click)="updateMethod(confirmGodownPopUpTemplate,aocLrPopUpTemplate)" disabled>Update</button>
                                                    <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style="width: 95%;">
                            <div class="col-md-12">

                                <div class="box-body">
                                    <h6 style="border-bottom: 1px solid orange;" class="card-title">Goods Short & Extra Details</h6>
                                    <table datatable id="{{pageId}}goodsShortAndExtraDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsGoodsShortAndExtraDetails" [dtTrigger]="dtTriggerGoodsShortAndExtraDetails">

                                        <thead>
                                            <tr>
                                                <th>LR No</th>
                                                <th>Art</th>
                                                <th>Extra</th>
                                                <th>Open</th>
                                                <th>Short</th>
                                                <th>Wet</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let goodsShortAndExtraDetailsData of goodsShortAndExtraDetailsDataList ">
                                                <td>{{ goodsShortAndExtraDetailsData.lrNumber }}</td>
                                                <td>{{ goodsShortAndExtraDetailsData.totalArticles }}</td>
                                                <td>{{ goodsShortAndExtraDetailsData.excessGoods }}</td>
                                                <td>{{ goodsShortAndExtraDetailsData.openConditionGoods }}
                                                </td>
                                                <td>{{ goodsShortAndExtraDetailsData.shortGoods }}</td>
                                                <td>{{ goodsShortAndExtraDetailsData.wetConditionGoods }}
                                                </td>
                                                <td>{{ goodsShortAndExtraDetailsData.remarks }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>


                                            </tr>
                                        </tfoot>


                                    </table>


                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-md-8 vl">
                    <div class="row">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- column-->
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="box-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <h6 class="card-title">{{contentPanel}}</h6>

                                            </div>
                                            <div class="col-md-4">
                                                <!-- 												<button type="submit" class="dt-button" -->
                                                <!-- 													style="margin-left: 210px;"id="{{pageId}}btnUnLoadRpt" disabled> -->
                                                <!-- 													<span><i class="fas fa-print">Get UnLoading Report</i></span> -->
                                                <!-- 												</button> -->
                                                <!-- 												<button type="submit" class="btn btn-success m-r-10" -->
                                                <!-- 														id="{{pageId}}updateBtn" -->
                                                <!-- 												(click)="godownPopup(confirmGodownPopUpTemplate)" disabled>Get UnLoading Report</button> -->
                                            </div>

                                        </div>
                                        <table datatable id="{{pageId}}invoiceDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceDetails" [dtTrigger]="dtTriggerInvoiceDetails">

                                            <thead>
                                                <tr>
                                                    <th [hidden]=true>Destination</th>
                                                    <th>LR Number</th>
                                                    <th>Goods Description</th>
                                                    <th>Source Name</th>
                                                    <th>Bkg Dt</th>
                                                    <th>Art</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let invoiceDetailsData of invoiceDetailsDataList ">
                                                    <td [hidden]=true>{{ invoiceDetailsData.destination }}</td>
                                                    <td>{{ invoiceDetailsData.lrNumber }}</td>
                                                    <td>{{ invoiceDetailsData.description }}</td>
                                                    <td>{{ invoiceDetailsData.agentId }}</td>
                                                    <td>{{ invoiceDetailsData.bookingDateStr }}</td>
                                                    <td>{{ invoiceDetailsData.totalArticles }}</td>
                                                    <td>{{ invoiceDetailsData.actualWeight }}</td>
                                                    <td>{{ invoiceDetailsData.chargedWeight }}</td>
                                                    <td>{{ invoiceDetailsData.toPay }}</td>
                                                    <td>{{ invoiceDetailsData.paid }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tfoot>


                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetailsConfirmGodown">
            <ng-template #confirmGodownPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>New Godown</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
                </div>

                <div class="modal-body">
                    <!-- 				<h6 class="modal-title" id="{{pageId}}modal-basic-title">Please Select -->
                    <!-- 					Godown</h6> -->
                    <br>
                    <div class="row">
                        <div *ngIf="spinnerForGodown" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait While Saving Godown.....</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Godown Name</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
										</span>
                                    </div>
                                    <input id="{{pageId}}godownName" type="text" class="form-control" [(ngModel)]="modelLocalGodown" (selectItem)="godownNameMethod($event)" [ngbTypeahead]="searchLocalGodown" [resultFormatter]="formatterLocalGodown" [inputFormatter]="formatterLocalGodown"
                                        (focus)="focusLocalGodownTA$.next($any($event).target.value)" placeholder="Select Godown Name" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="viewNewGodown" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>New Godown Name :</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
										</span>
                                    </div>
                                    <input #newGodownName type="text" class="form-control" id="{{pageId}}newGodownName" name="newGodownName" aria-describedby="basic-addon11">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 p-t-10" style="text-align: right;">
                            <button type="button" class="btn btn-outline-dark" (click)="updateBtn()">Save</button>
                            <button type="button" class="btn btn-outline-dark" (click)="closeBtnPopup();">Close</button>


                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12" id="{{pageId}}hideUnloadingSheet">
    <div class="form-group">
        <div class="input-group">
            <ng-template #unloadingSheetDetailsPopUpTemplate let-ok="close" let-d="dismiss">
                <!--<div class="modal-header">
						<h5>Memo Less Description :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewUnloadingSheetDetailsPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupUnloadingSheetDetails">
                                            <app-unloading-sheet-details></app-unloading-sheet-details>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4"></div>
                                    <div class="col-sm-12 col-md-2">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeUnloadingSheetDetailsPopUp()">Cancel1</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #consigneeMergePopUpTemplate let-ok="close" let-d="dismiss">
                <!--<div class="modal-header">
						<h5>Memo Less Description :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewConsigneeMergePopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupConsigneeMerge">
                                            <app-consignee-merge></app-consignee-merge>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4"></div>
                                    <div class="col-sm-12 col-md-2">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnIdForMerge" (click)="closeConsigneeMergeDetailsPopUp()">Cancel</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #aocLrPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>AOC LRs</h5>
                    <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button> -->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForActionForAocLrs" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please wait loading AOC LR details....</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <table datatable id="{{pageId}}aocLrsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAocLrs" [dtTrigger]="dtTriggerAocLrs">
                                    <thead>
                                        <tr>
                                            <th>LR Number</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let aocLrData of aocLrDataList ">
                                            <td>{{ aocLrData.lrNumber }}</td>
                                            <td style="cursor: pointer; color: blue;">
                                                <a (click)="getImg(aocLrData,aocLrUploadPopUpTemplate);">
                                                    <u>{{ aocLrData.imgURLPan != null ? "View
                                                        Declaration" : "Upload" }}</u>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnPopupId" (click)="closeBtnAocPopUp()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #aocLrUploadPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>AOC File Upload</h5>
                    <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button> -->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Uploading Documnents....</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <h6 class="card-title" style="color: green; font-size: 13px;">
                                        File size must be less than or equal to 1 MB</h6>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label> File :</label> <input type="file" id="{{pageId}}aocLrFileUpload" (change)="onFileChangedAocLr($event)" #fileInputAocLr />
                                                <!-- <label> File :</label> <input type="file" multiple id="{{pageId}}aocLrFileUpload" (change)="onFileChangedAocLr($event)" #fileInputAocLr /> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadBtnPopUp(aocLrPopUpTemplate, confirmGodownPopUpTemplate)">Upload</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeBtnPopUp()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>