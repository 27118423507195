import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { SendSmsGroupDetailsComponent } from 'src/app/master/communication/send-sms-group-details/send-sms-group-details.component';
import { MessageMemoComponent } from 'src/app/master/communication/message-memo/message-memo.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { MaterialModule } from "src/app/material.module";

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( CommunicationRoutes ),
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule],
    declarations: [
        SendSmsGroupDetailsComponent, MessageMemoComponent, SendSmsComponent]
})
export class CommunicationModule { }
