import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends
//for redirect to other page starts
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
//for redirect to other page ends
@Component( {
    selector: 'app-pending-hamali-memos',
    templateUrl: './pending-hamali-memos.component.html',
    styleUrls: ['./pending-hamali-memos.component.css']
} )
export class PendingHamaliMemosComponent implements  OnInit {

    //for popup modal starts
    closeResult: string;
    //for popup modal ends
    getDataPendingHamaliMemosDataTable: any;

    pendingHamaliMemosDataList: any;

    onDestroyUnsubscribtionPendingHamaliMemos: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerPendingHamaliMemos: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsPendingHamaliMemos: any;
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	cashmemoDtoForUpdate:CashMemoDto =  new CashMemoDto();
	showSpinnerForAction=false;
	cashmemoDtoReload:CashMemoDto =  new CashMemoDto();
	selectedMemoNumber:any;
pageId="pnhmc";
  
	
	constructor(
        private dashboardService: DashboardService,
        //for modal starts
        public dialog: MatDialog,
        private route: ActivatedRoute, private modalService: NgbModal,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
        //for modal ends        
    ) {
    	  if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          }
      
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

 
	ngOnInit(): void {

        this.dtOptionsPendingHamaliMemos = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
          
           
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerPendingHamaliMemos.unsubscribe();
    // }
    ngAfterViewInit(): void {
        this.dtTriggerPendingHamaliMemos.next();

    }
	

    goToLrEnquiryPage() {
        this.router.navigate( ['/lr/enquiry/lrEnquiry'] );
    }
    //for redirect to other page starts
	
	gridReconifgureOnReloadBtn(){
		
			this.cashmemoDtoReload=  new CashMemoDto();
			this.cashmemoDtoReload.destination=this.userDataDtoReturnSession.mainStation;
			this.cashmemoDtoReload.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.cashmemoDtoReload);
			this.gridReconifgureDetails();
	}
	gridReconifgureDetails= () => {
    	        this.showSpinnerForAction = true;
    	         this.dashboardService.getPendingHamaliMemos(this.cashmemoDtoReload).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  //$("#"+this.pageId+"pendingHamaliMemosId").DataTable().destroy();
							this.pendingHamaliMemosDataList=[];
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.pendingHamaliMemosDataList=response;
    	               	  console.log(this.pendingHamaliMemosDataList);
    	                }
    	            //   this.dtTriggerPendingHamaliMemos.next();
					 this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the cashMemo Pending Hamali Memos Details", "info");
                    }, () => console.log('done');
            };
//for popup modal starts 
    open2( content,pendingHamaliMemosData ) {
		this.selectedMemoNumber='';
		this.selectedMemoNumber=pendingHamaliMemosData.memoNumber;
	if(this.selectedMemoNumber !=null && this.selectedMemoNumber !=''){
		  this.modalService.open( content, { centered: true } ).result.then(
				result => {
					this.closeResult = `Closed with: ${result}`;
				},
				reason => {
					this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
				}
			);
	}else{
		swal({
				 title: "Warning",
				 text: "No MemoNumber found !",
				 icon: "warning",
				 closeOnClickOutside: false,
				 closeOnEsc: false,
			 });
	}
	
    }
    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends
	//For Update
	updateMemo(){
			this.cashmemoDtoForUpdate=  new CashMemoDto();
			this.cashmemoDtoForUpdate.memoNumber=this.selectedMemoNumber;
			this.cashmemoDtoForUpdate.destination=this.userDataDtoReturnSession.mainStation;
			this.cashmemoDtoForUpdate.userName=this.userDataDtoReturnSession.userId;
			this.cashmemoDtoForUpdate.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.cashmemoDtoForUpdate);
			this.updateMemoDetails();
	}
	updateMemoDetails= () => {
    	        this.showSpinnerForAction = true;
    	         this.dashboardService.updateCashmemoDetailsForHamali(this.cashmemoDtoForUpdate).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
    	                  if ( response.status == 'Success' ) {
           	                     swal({
           	                         title: "Success",
           	                         text: "Memo Number Updated Successfully",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
           	                     this.gridReconifgureOnReloadBtn();
           	                 } else if(response.status == 'Success'){
							     swal({
           	                         title: "Failed to update",
           	                         text: "Memo Number Does Not Exist",
           	                         icon: "warning",
           	                         closeOnClickOutside: false,
           	                         closeOnEsc: false,
           	                     });
           	               
           	                }else{
								swal({
									 title: "Failed to update",
									 text: "Problem occurred while updating the Pending Hamali details",
									 icon: "warning",
									 closeOnClickOutside: false,
									 closeOnEsc: false,
									 });
							}
							this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the CashMemo Pending Hamali Details", "info");
                    }, () => console.log('done');
            };
            
            
}
