<div class="page row system_responsive" id="{{pageId}}firstPrint">
	<div class="col-md-12">
		<!--first row starts-->
		<div class="row" style="padding-top: 5px;" height='30px'>
			<div class="col-md-7">
				<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
			</div>
			<div class="col-md-5">
				<h6>
					{{address}}<br>
				</h6>
			</div>
		</div>
		<!--first row starts-->
		<div class="row" style="padding-top: 5px;" height='30px'>
			<div class="col-md-12">
				<h6 style='text-align: center;'>Transferred To Collection Man Stock</h6>
			</div>
		</div>
		<!--Second  row starts-->
		<table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
			<tr>
				<td width='17%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-left: 1px solid black;border-top: 1px solid black;'
					valign='top' type='text'><strong>Memo Number</strong></td>
				<td width='10%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;'
					valign='top' type='number'><strong> LR No</strong></td>
				<td width='8%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;'
					valign='top' type='number'><strong> Articles</strong></td>
				<td width='19%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;'
					valign='top' type='number'><strong> Consignee Name</strong></td>
				<td width='19%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;'
					valign='top' type='number'><strong> Consignor Name</strong></td>
				<td width='9%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;'
					valign='top' type='number'><strong> Memo Amount</strong></td>
				<td width='18%' align='center'
					style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid black;border-right: 1px solid black;'
					valign='top' type='text'><strong> Collection Man</strong></td>
			</tr>
			<tr *ngFor="let collectionManDetailsDataList of collectionManDetailsDataList">
				<td width='17%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000; border-left: 1px solid #000;'
					valign='top' type='text' id='{{pageId}}appMemoNumberPrint'><span>
						{{collectionManDetailsDataList.memoNumber}} </span></td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='number' id='{{pageId}}appEnteredDatePrint'><span>
						{{collectionManDetailsDataList.gcNoteNumber}}
					</span></td>
				<td width='8%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='number' id='{{pageId}}appAmountPrint'><span>
						{{collectionManDetailsDataList.totalArticles}} </span></td>
				<td width='19%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='number' id='{{pageId}}appAmountPrint'><span>
						{{collectionManDetailsDataList.consignee}} </span></td>
				<td width='19%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='number' id='{{pageId}}appAmountPrint'><span>
						{{collectionManDetailsDataList.consignor}} </span></td>
				<td width='9%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='text' id='{{pageId}}appLrNumberPrint'><span>
						{{collectionManDetailsDataList.grandTotal}} </span></td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;border-left: 1px solid #000;'
					valign='top' type='text' id='{{pageId}}appPartyNamePrint'><span>
						{{collectionManDetailsDataList.collectionMan}}</span></td>
			</tr>
			<tr>
				<td width='17%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;border-left: 1px solid black;'
					valign='top' type='text'><strong> Total : </strong><strong>
						{{totalCount}} </strong></td>
				<td width='10%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;'
					valign='top' type='number'><strong> </strong></td>
				<td width='8%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;'
					valign='top' type='number'><strong>{{totalArticle}} </strong></td>
				<td width='19%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;'
					valign='top' type='number'><strong></strong></td>
				<td width='19%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;'
					valign='top' type='number'><strong> </strong></td>
				<td width='9%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;'
					valign='top' type='number'><strong> {{totalAmount}}</strong></td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; word-wrap: break-word;border-bottom: 1px solid black;border-right: 1px solid #000;'
					valign='top' type='text'><strong> </strong></td>
			</tr>
		</table>
	</div>
</div>