import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends


@Component( {
    selector: 'app-booking-stations-stocks-summary',
    templateUrl: './booking-stations-stocks-summary.component.html',
    styleUrls: ['./booking-stations-stocks-summary.component.css']
} )
export class BookingStationsStocksSummaryComponent implements  OnInit {

    getDataBookingStationsStocksSummaryDataTable: any;

    bookingStationsStocksSummaryDataList: any;

    onDestroyUnsubscribtionBookingStationsStocksSummary: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBookingStationsStocksSummary: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsBookingStationsStocksSummary: any;
    
 	pageId="bkssc";
	
    constructor( private bookingAgentStocksSummaryScreen: DashboardService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    ngOnInit(): void {
        //      the fourteen datatable starts
        this.dtOptionsBookingStationsStocksSummary = {
            //};
            //columns is used to for export and others starts
            columns: [

                {
                    title: 'Source',
                    data: 'name'
                },
                {
                    title: 'Act Wt',
                    data: 'symbol'
                },
                {
                    title: 'Chg Wt',
                    data: 'grade'
                }
            ],

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //                    //                                exportOptions: {
                //                    //                                    columns: ':visible'
                //                    //                                }
                //                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            //pagination in datatable starts
            pagingType: 'full_numbers',
            pageLength: 5,
            //pagination in datatable ends
            processing: true,
            //scroll in datatable starts
            "scrollX": true,
            "scrollCollapse": true,
            //scroll in datatable ends
        }
        //      the fourteen datatable ends
        //fourteen service starts 
        this.getDataBookingStationsStocksSummaryDataTable = this.bookingAgentStocksSummaryScreen.getSupplierMasterData()
        this.onDestroyUnsubscribtionBookingStationsStocksSummary = this.getDataBookingStationsStocksSummaryDataTable.subscribe( data => {
            this.bookingStationsStocksSummaryDataList = data['data'];
            this.dtTriggerBookingStationsStocksSummary.next();
        } );
        //fourteen service ends
    }

    ngOnDestroy(): void {
        this.dtTriggerBookingStationsStocksSummary.unsubscribe();

        this.onDestroyUnsubscribtionBookingStationsStocksSummary.unsubscribe();
    }

}
