<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Party Wise CFT Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">

                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Party Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}partyType" name="partyType" #partyType>
                                                        <option selected value="consigneeWise">Consignee</option>
                                                        <option value="consignorWise">Consignor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="agentNameModal" (selectItem)="clickListnerForSource($event)" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName" placeholder="Select Source" (focus)="focusAgentNameTA$.next($any($event).target.value)" #instanceAgentName="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-12" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>

                                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearConfirm();">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 vl">
                    <div class="row">
                        <div class="col-md-12 vl">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="card-title">CFT Details</h6>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}slabSetupDataAllTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCflSlabsAllSetup" [dtTrigger]="dtTriggerCflSlabsAllSetup">
                                                    <thead>
                                                        <tr>
                                                            <th>Party Name</th>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Commodity</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let slabSetupDataAll of slabSetupDataAllList; let i = index">
                                                            <td>{{ slabSetupDataAll.partyName }}</td>
                                                            <td>{{ slabSetupDataAll.source }}</td>
                                                            <td>{{ slabSetupDataAll.destination }}</td>
                                                            <td>{{ slabSetupDataAll.commodityName }}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>