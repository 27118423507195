<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}agentRankingRptId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Agent Ranking Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}agentName">
                                                        <label>Agent Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
																<i class="ti-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}agentName" type="text" name="agentName" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="clickListnerForAgentName($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)" placeholder="Select Agent Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Previous</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select id="{{pageId}}previous" class="custom-select col-12">
															<option selected>Select Previous</option>
															<option value="currentStatement">Current Statement</option>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
															<option value="5">5</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                        </div>
                    </div>



                    <div class="col-md-9 vl p-t-10">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-t-10">

                                <div class="col-md-8">
                                    <h6 class="card-title">Agent Ranking Report</h6>
                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="dt-button" style="margin-left:225px;" (click)="customPrintAgentRankingRpt()" id="{{pageId}}printAllBtn">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                                </div>
                            </div>
                            <div class="box-body">

                                <table datatable id="{{pageId}}rankingTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRanking" [dtTrigger]="dtTriggerRanking">

                                    <thead>
                                        <tr>
                                            <th>Agent Name</th>
                                            <th>Actual Weight</th>
                                            <th>Charged Weight</th>
                                            <th>Net Amount</th>
                                            <th>Addition</th>
                                            <th>Deduction</th>
                                            <th>Paid ON</th>
                                            <th>Cash</th>
                                            <th>Cheque</th>
                                            <th>Pdc</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let RankingData of RankingDataList ">
                                            <td>{{ RankingData.agentName }}</td>
                                            <td>{{ RankingData.actualWeight }}</td>
                                            <td>{{ RankingData.chargedWeight }}</td>
                                            <td>{{ RankingData.toPay }}</td>
                                            <td>{{ RankingData.lcChg }}</td>
                                            <td>{{ RankingData.bcChg }}</td>
                                            <td>{{ RankingData.bookingDateStr }}</td>
                                            <td>{{ RankingData.cashAmt }}</td>
                                            <td>{{ RankingData.chqAmt }}</td>
                                            <td>{{ RankingData.penaltyAmt }}</td>
                                            <td>{{ RankingData.remarks }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>




                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>