import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';

@Component({
    selector: 'app-rewarding-voucher-rpt-print',
    templateUrl: './rewarding-voucher-rpt-print.component.html',
    styleUrls: ['./rewarding-voucher-rpt-print.component.css']
})
export class RewardingVoucherReportPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;

    dataForhireSlipLocalStorageRpt: any;

    hireslipNumber: any;
    invoiceNumber: any;
    invoicedate: any;

    supplierName: any;
    lorryNo: any;
    hireAmt: any;

    departureDate: any;
    departureTime: any;
    arrivalDate: any;
    arrivalTime: any;
    timeTakenToReach: any;

    drivername: any;
    rewardAmt: any;
    rewardTo: any;
    dateInDate: any;

    num: any;
    n: any;
    printAmt: any;

    hireslipdataForPrint: HireSlipDto = new HireSlipDto();

    address: any;
    image: any;
    pageId = "rvpc";
    showHireColumns: Boolean = false;

    constructor(private router: Router, private datePipe: DatePipe,
        public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForhireSlipLocalStorageRpt = JSON.parse(localStorage.getItem('rewardingVoucherPrint'));
            if (this.dataForhireSlipLocalStorageRpt != null) {
                this.hireslipdataForPrint = this.dataForhireSlipLocalStorageRpt;
            }
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Rewarding Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }

            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.image = this.userDataDtoReturnSession.ip;
            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;

        this.hireslipNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.invoiceNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.supplierName = this.hireslipdataForPrint.vehiCompName == null ? ' ' : this.hireslipdataForPrint.vehiCompName;
        this.lorryNo = this.hireslipdataForPrint.vehicleNumber == null ? ' ' : this.hireslipdataForPrint.vehicleNumber;
        this.hireAmt = this.hireslipdataForPrint.totalhire == null ? ' ' : this.hireslipdataForPrint.totalhire;

        if (this.hireslipdataForPrint.hireslipdate != null) {
            this.invoicedate = this.datePipe.transform(this.hireslipdataForPrint.hireslipdate, "dd-MM-yyyy");
        } else {
            this.invoicedate = '';
        }

        if (this.hireslipdataForPrint.departuredate != null) {
            this.departureDate = this.datePipe.transform(this.hireslipdataForPrint.departuredate, "dd-MM-yyyy");
        } else {
            this.departureDate = '';
        }
        this.departureTime = this.hireslipdataForPrint.departureTime == null ? ' ' : this.hireslipdataForPrint.departureTime;

        if (this.hireslipdataForPrint.arrivaldate != null) {
            this.arrivalDate = this.datePipe.transform(this.hireslipdataForPrint.arrivaldate, "dd-MM-yyyy");
        } else {
            this.arrivalDate = '';
        }
        this.arrivalTime = this.hireslipdataForPrint.arrivalTime == null ? ' ' : this.hireslipdataForPrint.arrivalTime;

        this.timeTakenToReach = this.hireslipdataForPrint.column3 == null ? ' ' : this.hireslipdataForPrint.column3;
        this.rewardAmt = this.hireslipdataForPrint.driverrewardingAmt == null ? ' ' : this.hireslipdataForPrint.driverrewardingAmt;

        this.drivername = this.hireslipdataForPrint.drivername == null ? ' ' : this.hireslipdataForPrint.drivername;
        this.rewardTo = this.hireslipdataForPrint.fromstation == null ? ' ' : this.hireslipdataForPrint.fromstation;
        this.dateInDate = this.datePipe.transform(this.hireslipdataForPrint.fromDateInDate, "dd-MM-yyyy");
        if (this.rewardAmt == 0 || this.rewardAmt == 0.0) {
            this.printAmt = "ZERO";
        } else {
            this.printAmt = this.inWords();
        }
    }

    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = this.rewardAmt;
        //        console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(
            /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }
    clearField() {
        this.address = '';
        this.invoiceNumber = '';
        this.hireslipNumber = '';
        this.supplierName = '';
        this.drivername = '';
        this.lorryNo = '';
        this.invoicedate = '';
        this.rewardAmt = 0.0;
        this.printAmt = '';
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
