import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { MasterService } from 'src/app/dataService/master-service';
@Component({
    selector: 'app-debit-note-stmt',
    templateUrl: './debit-note-stmt.component.html',
    styleUrls: ['./debit-note-stmt.component.css']
})
export class DebitNoteStmtComponent implements OnInit {

    debitNoteStmtDataList: Array<any> = [];
    receivedDate: NgbDateStruct;
    closeResult: string;
    editTableId: null;
    selectedDescription: any;
    enteredValue: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    private newAttributeMultiEway: any = {};
    dtTriggerDebitNoteStmt: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsDebitNoteStmt: any;
    public modelDebitHeadForGet: any;
    public modelDebitSubHeadForGet: any;
    viewOtherDebit = false;
    showSpinnerForAction = false;
    readonlyDebitHead = false;
    readonlyDebitSubHead = false;
    enteredDescription = null;
    stmtDtoForFortnightDtls: FortNightStmtDto = new FortNightStmtDto();
    stmtDtoForFortnightDtlsResponse: FortNightStmtDto = new FortNightStmtDto();
    editSelected = false;
    fortNightStmtFinishBtnDto: FortNightStmtDto = new FortNightStmtDto();
    enteredStmtNo: any;
    enteredBranch: any;
    enteredFromPeriod: any;
    enteredToPeriod: any;
    selectedReceivedDate: any;
    stmtDtoAmt: FortNightStmtDto = new FortNightStmtDto();
    listOfDest: Array<String> = [];
    mutipleDed: any = [];
    splitMultipleDed: any = [];
    pageId = "dnsc";

    selectedFromPeriod: any;
    selectedToPeriod: any;
    selectedRecDate: any;

    constructor(
        private router: Router,
        private masterReadService: MasterReadService,
        private masterService: MasterService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDebitNoteMasterList();
            this.getDebitNoteMasterForOthersList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    /////For Debit Head
    debitHeadOptions: FortNightStmtDto[];
    fortNightStmtDebitHeadDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDebitHeadSelectOptions: FortNightStmtDto = new FortNightStmtDto();
    public modelDebitHead: any;
    debitHeadTA: Array<FortNightStmtDto> = [];
    focusDebitHeadTA$ = new Subject<string>();
    searchDebitHead = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDebitHeadTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.debitHeadTA
                : this.debitHeadTA.filter(v => v.mainDebitHeads.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDeebitHead = (x: { mainDebitHeads: string }) => x.mainDebitHeads;
    //// For OTher Debit Heads
    debitHeadOthersOptions: FortNightStmtDto[];
    fortNightStmtDebitHeadOthersDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDebitHeadOtherSelectOptions: FortNightStmtDto = new FortNightStmtDto();
    public modelDebitHeadOthers: any;
    debitHeadOthersTA: Array<FortNightStmtDto> = [];
    focusDebitHeadOthersTA$ = new Subject<string>();
    searchDebitHeadOthers = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDebitHeadOthersTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.debitHeadOthersTA
                : this.debitHeadOthersTA.filter(v => v.subDebitHeads.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDebitHeadOthers = (x: { subDebitHeads: string }) => x.subDebitHeads;

    ngOnInit(): void {
        this.dtOptionsDebitNoteStmt = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": false,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totalAmt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total');
                $(api.column(1).footer()).html(totalAmt);

            },
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerDebitNoteStmt.unsubscribe();
    }

    checkStmtNo(e) {
        if (e.keyCode == 13) {
            this.searchMethod();
        }
    }

    searchMethod() {
        $("#" + this.pageId + "debitHead").val('');
        this.modelDebitHead = null;
        this.viewOtherDebit = false;
        this.modelDebitHeadOthers = null;
        $("#" + this.pageId + "description").val('');
        $("#" + this.pageId + "value").val('');
        $("#" + this.pageId + "receivedDate").val('');
        this.editSelected = false;
        this.readonlyDebitHead = false;
        this.getFortnightDetails();
    }

    getFortnightDetails() {
        this.enteredStmtNo = $("#" + this.pageId + "statementNo").val();
        this.stmtDtoForFortnightDtls = new FortNightStmtDto();
        this.stmtDtoForFortnightDtls.status = "Pending";
        this.stmtDtoForFortnightDtls.mode = "specific";
        this.stmtDtoForFortnightDtls.stmtId = this.enteredStmtNo;
        this.stmtDtoForFortnightDtls.companyId = this.userDataDtoReturnSession.companyId;
        console.log("this.stmtDtoForFortnightDtls");
        console.log(this.stmtDtoForFortnightDtls);
        this.masterService.getDebitNoteDetails(this.stmtDtoForFortnightDtls).subscribe(
            (response) => {
                this.showSpinnerForAction = false;


                if (response.length > 0) {
                    this.clearFields();
                    this.debitNoteStmtDataList = [];
                    $("#" + this.pageId + "debitHeadTableId").DataTable().destroy();
                    this.stmtDtoForFortnightDtlsResponse = new FortNightStmtDto();
                    this.stmtDtoForFortnightDtlsResponse = response[0];
                    console.log(this.stmtDtoForFortnightDtlsResponse);
                    console.log("this.stmtDtoForFortnightDtlsResponse");
                    $("#" + this.pageId + "branch").val(this.stmtDtoForFortnightDtlsResponse.destination);
                    this.selectedFromPeriod = this.stmtDtoForFortnightDtlsResponse.fromPeriod;
                    this.selectedToPeriod = this.stmtDtoForFortnightDtlsResponse.toPeriod;
                    this.selectedReceivedDate = this.stmtDtoForFortnightDtlsResponse.receivedDate;
                    $("#" + this.pageId + "fromPeriod").val(this.selectedFromPeriod);
                    $("#" + this.pageId + "toPeriod").val(this.selectedToPeriod);
                    $("#" + this.pageId + "receivedDate").val(this.selectedReceivedDate);
                    // 		List<FortNightStmtDto> dtoss = dtosNew;
                    // 		gridReconfigure(dto2, dtoss);
                    this.debitNoteStmtDataList = this.stmtDtoForFortnightDtlsResponse.stmtDtos;


                } else {
                    swal({
                        title: "Result Not Found",
                        text: "No Result Found For Statement Number :" + this.enteredStmtNo,
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        // grid.reconfigure(store, cm);
                        this.clearFields();
                    })
                }
                this.dtTriggerDebitNoteStmt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Stmt details", "info");
            }, () => console.log('done');

    }

    fouckValueMtd(e) {
        if (e.keyCode == 13) {
            this.chkEnteredValue();
        }
    }

    chkEnteredValue() {
        var debitHeadVar = $("#" + this.pageId + "debitHead").val();
        var subHeadVar = $("#" + this.pageId + "subHead").val();
        this.selectedDescription = $("#" + this.pageId + "description").val();
        this.enteredValue = $("#" + this.pageId + "value").val();
        console.log("debitHeadVar, subHeadVar, this.selectedDescription, this.enteredValue");
        console.log(debitHeadVar, subHeadVar, this.selectedDescription, this.enteredValue);
        if (this.selectedDescription == '' || this.selectedDescription == null || this.selectedDescription == undefined || this.selectedDescription == 'Select'
            || this.enteredValue == '' || this.enteredValue == null || this.enteredValue == undefined) {
            swal({
                title: "Mandatory Field",
                text: "Select Description and Value",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if ((debitHeadVar == 'Others') && (subHeadVar == null || subHeadVar == undefined || subHeadVar == 'Select'
            || subHeadVar == '')) {
            swal({
                title: "Mandatory Field",
                text: "Please Select Sub Heads",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.chkDuplicateDescription();
        }
    }
    chkDuplicateDescription() {
        // this.enteredDescription = $("#" + this.pageId + "description").val();
        // var validateRemaingDescription = this.findEwayDup(this.debitNoteStmtDataList, this.enteredDescription);
        // if ((validateRemaingDescription == this.enteredDescription) && (this.editSelected == false)) {
        //     swal("Duplicate Entry", this.enteredDescription + "' Already Exist", "warning");
        // } else {
        //     this.addIntable();
        // }

        this.addIntable();
    }

    findEwayDup(debitNoteStmtDataList, description) {
        var checkingRemDescription = null;
        var remainDescriptionReturn = null;

        for (let i = 0; i < debitNoteStmtDataList.length; i++) {
            checkingRemDescription = debitNoteStmtDataList[i].description;
            if (checkingRemDescription == description) {
                remainDescriptionReturn = checkingRemDescription;
                break;
            } else {
                remainDescriptionReturn = null;
            }
        }

        return remainDescriptionReturn;
    }

    addIntable() {
        this.newAttributeMultiEway.description = $("#" + this.pageId + "description").val();
        this.newAttributeMultiEway.debit = $("#" + this.pageId + "value").val();
        if (this.editTableId != null) {
            this.debitNoteStmtDataList.splice(this.editTableId, 1);
        }
        if (this.debitNoteStmtDataList == null) {
            this.debitNoteStmtDataList = [];
        }
        console.log("this.newAttributeMultiEway");
        console.log(this.newAttributeMultiEway);
        $("#" + this.pageId + "debitHeadTableId").DataTable().destroy();
        if (this.debitNoteStmtDataList.length == 0) {
            this.debitNoteStmtDataList.push(this.newAttributeMultiEway);
            this.editSelected = false;
        } else {
            let array = this.debitNoteStmtDataList;
            this.debitNoteStmtDataList.push(this.newAttributeMultiEway);
            this.editSelected = false;
        }
        this.dtTriggerDebitNoteStmt.next();
        this.newAttributeMultiEway = {};
        $("#" + this.pageId + "description").val('');
        $("#" + this.pageId + "value").val('');
        $("#" + this.pageId + "debitHead").val('');
        this.readonlyDebitHead = false;
        this.viewOtherDebit = false;
        this.modelDebitHead = null;
        this.modelDebitHeadOthers = null;
        window.setTimeout(function () {
            $("#dnscdebitHead").focus();
        }, 100);
        this.editTableId = null;
    }
    rowSelectedEdit(debitNoteStmtData, index) {
        console.log("debitNoteStmtData");
        console.log(debitNoteStmtData);
        this.editSelected = true;
        if (debitNoteStmtData.mainDebitHeads == "Others") {
            this.viewOtherDebit = true;
            $("#" + this.pageId + "subHead").val(debitNoteStmtData.description);
        } else {
            this.viewOtherDebit = false;
        }
        $("#" + this.pageId + "debitHead").val(debitNoteStmtData.description);
        $("#" + this.pageId + "description").val(debitNoteStmtData.description);
        $("#" + this.pageId + "value").val(debitNoteStmtData.debit);
        this.editTableId = index;
        this.readonlyDebitHead = true;
        this.readonlyDebitSubHead = true;
        this.changeDetectorRef.detectChanges();
    }

    rowSelectedDelete(debitNoteStmtData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure U Want To Remove",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesRemove) => {
            if (yesRemove) {
                this.removeSelectedRowMth(index);
            }
        });
    }
    removeSelectedRowMth(index) {
        console.log("this.debitNoteStmtDataList >> Before >>");
        console.log(this.debitNoteStmtDataList);
        $("#" + this.pageId + "debitHeadTableId").DataTable().destroy();

        this.debitNoteStmtDataList.splice(index, 1);
        $("#" + this.pageId + "debitHead").val('');
        $("#" + this.pageId + "subHead").val('');
        $("#" + this.pageId + "description").val('');
        $("#" + this.pageId + "value").val('');
        $("#" + this.pageId + "hiddenIndex").val('');
        this.modelDebitHead = null;
        this.modelDebitHeadOthers = null;
        console.log("this.debitNoteStmtDataList >> After >> ");
        console.log(this.debitNoteStmtDataList);

        this.dtTriggerDebitNoteStmt.next();

        this.changeDetectorRef.detectChanges();
    }
    getDebitNoteMasterListRead() {
        this.fortNightStmtDebitHeadDto = new FortNightStmtDto();
        this.fortNightStmtDebitHeadDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDebitHeadDto.reportMode = "listBox";
    }


    getDebitNoteMasterList = () => {
        this.getDebitNoteMasterListRead();
        this.masterReadService.getDebitNoteMaster(this.fortNightStmtDebitHeadDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {

                    if (response.length > 0) {
                        this.debitHeadOptions = response;
                        this.debitHeadTA = [];
                        this.fortNightStmtDebitHeadSelectOptions.mainDebitHeads = 'Select';
                        this.debitHeadTA.push(this.fortNightStmtDebitHeadSelectOptions);
                        for (let i = 0; i < this.debitHeadOptions.length; i++) {
                            this.debitHeadTA.push(this.debitHeadOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Debit  Head details", "info");
            }, () => console.log('done');
    };

    debitHeadListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDebitHeadForGet = e.item;
        if (this.modelDebitHeadForGet.mainDebitHeads == "Others") {
            this.viewOtherDebit = true;

            window.setTimeout(function () {
                $("#dnscsubHead").focus();
            }, 100);
        } else {
            $("#" + this.pageId + "description").val(this.modelDebitHeadForGet.mainDebitHeads);
            this.viewOtherDebit = false;
            this.modelDebitHeadOthers = null;
            window.setTimeout(function () {
                $("#dnscvalue").focus();
            }, 100);
        }

    }
    debitHeadFocus(e) {
        if (e.keyCode == 13) {
            if (this.viewOtherDebit == true) {
                window.setTimeout(function () {
                    $("#dnscsubHead").focus();
                }, 100);
            } else {
                window.setTimeout(function () {
                    $("#dnscvalue").focus();
                }, 100);
            }
        }
    }

    debitSubHeadListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDebitSubHeadForGet = e.item;
        console.log("this.modelDebitSubHeadForGet");
        console.log(this.modelDebitSubHeadForGet);
        if (this.viewOtherDebit == true) {
            $("#" + this.pageId + "description").val(this.modelDebitSubHeadForGet.subDebitHeads);
            window.setTimeout(function () {
                $("#dnscvalue").focus();
            }, 100);
        }

    }
    subHeadFocus(e) {
        if (e.keyCode == 13) {
            if (this.viewOtherDebit == true) {
                window.setTimeout(function () {
                    $("#dnscvalue").focus();
                }, 100);
            }
        }
    }

    getDebitNoteMasterListForOthersRead() {
        this.fortNightStmtDebitHeadOthersDto = new FortNightStmtDto();
        this.fortNightStmtDebitHeadOthersDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDebitHeadOthersDto.reportMode = "subHeads";
        this.fortNightStmtDebitHeadOthersDto.mainDebitHeads = "Others";
        console.log(this.fortNightStmtDebitHeadOthersDto);
    }


    getDebitNoteMasterForOthersList = () => {
        this.getDebitNoteMasterListForOthersRead();
        this.masterReadService.getDebitNoteMaster(this.fortNightStmtDebitHeadOthersDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {

                    if (response.length > 0) {
                        this.debitHeadOthersOptions = response;
                        this.debitHeadOthersTA = [];
                        this.fortNightStmtDebitHeadOtherSelectOptions.subDebitHeads = 'Select';
                        this.debitHeadOthersTA.push(this.fortNightStmtDebitHeadOtherSelectOptions);
                        for (let i = 0; i < this.debitHeadOthersOptions.length; i++) {
                            this.debitHeadOthersTA.push(this.debitHeadOthersOptions[i]);
                        }
                        console.log(this.debitHeadOthersTA);
                    }
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Sub  Head details", "info");
            }, () => console.log('done');
    };



    clearMethod() {
        this.fortNightStmtDebitHeadDto = new FortNightStmtDto();
        this.modelDebitHead = 'Select';
        this.modelDebitHeadOthers = '';
        $("#" + this.pageId + "debitHead").val('Select');
        $("#" + this.pageId + "subHead").val('');
        this.viewOtherDebit = false;
        this.modelDebitHeadForGet = '';
        this.modelDebitSubHeadForGet = '';
        this.showSpinnerForAction = false;
        this.readonlyDebitHead = false;
        this.readonlyDebitSubHead = false;
        this.stmtDtoForFortnightDtls = new FortNightStmtDto();
        this.enteredStmtNo = null;
    }

    clearFields() {
        $("#" + this.pageId + "branch").val('');
        $("#" + this.pageId + "fromPeriod").val('');
        $("#" + this.pageId + "toPeriod").val('');
    }

    finishBtnMtd() {
        this.enteredStmtNo = null;
        this.selectedReceivedDate = null;
        this.enteredStmtNo = $("#" + this.pageId + "statementNo").val();
        this.selectedReceivedDate = $("#" + this.pageId + "receivedDate").val();

        if (this.debitNoteStmtDataList.length == 0) {
            swal({
                title: "Empty Values",
                text: "No Debit Note Found",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.enteredStmtNo == null || this.enteredStmtNo == '' || this.enteredStmtNo == undefined ||
            this.selectedReceivedDate == null || this.selectedReceivedDate == '' || this.selectedReceivedDate == undefined) {
            swal({
                title: "Warning",
                text: "Received Date and Statement No is Mandatory Field ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Finish",
                text: "Sure U Want To Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesFinish) => {
                if (yesFinish) {
                    this.getUserValues();
                }
            });
        }

    }

    getUserValues() {
        this.enteredStmtNo = null;
        this.enteredBranch = null;
        this.enteredFromPeriod = null;
        this.enteredToPeriod = null;
        this.selectedReceivedDate = null;

        this.enteredStmtNo = $("#" + this.pageId + "statementNo").val();
        this.enteredBranch = $("#" + this.pageId + "branch").val();
        this.enteredFromPeriod = $("#" + this.pageId + "fromPeriod").val();
        this.enteredToPeriod = $("#" + this.pageId + "toPeriod").val();
        this.selectedReceivedDate = $("#" + this.pageId + "receivedDate").val();

        this.fortNightStmtFinishBtnDto = new FortNightStmtDto();
        this.fortNightStmtFinishBtnDto.stmtId = this.enteredStmtNo;
        this.fortNightStmtFinishBtnDto.destination = this.enteredBranch;
        this.fortNightStmtFinishBtnDto.fromPeriod = this.enteredFromPeriod;
        this.fortNightStmtFinishBtnDto.toPeriod = this.enteredToPeriod;
        this.fortNightStmtFinishBtnDto.receivedDate = this.selectedReceivedDate;
        this.fortNightStmtFinishBtnDto.updatedBy = this.userDataDtoReturnSession.userId;
        this.fortNightStmtFinishBtnDto.status = "Pending";
        this.fortNightStmtFinishBtnDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtFinishBtnDto.stmtDtos = this.debitNoteStmtDataList;
        // this.fortNightStmtFinishBtnDto.miscellaneous
        // this.fortNightStmtFinishBtnDto.multipleDed
        // this.fortNightStmtFinishBtnDto.totalAmount
        console.log("this.fortNightStmtFinishBtnDto");
        console.log(this.fortNightStmtFinishBtnDto);
        this.masterService.addDebitNoteDetails(this.fortNightStmtFinishBtnDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;

                if (response.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Debit Note Saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearMtdOnSuccess();
                    })
                }

                this.dtTriggerDebitNoteStmt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Saving", "info");
            }, () => console.log('done');
    }
    clearMtdOnSuccess() {
        this.stmtDtoAmt = new FortNightStmtDto();
        this.listOfDest = [];
        this.listOfDest.push(this.enteredBranch);
        this.stmtDtoAmt.list = this.listOfDest;
        this.stmtDtoAmt.destination = this.enteredBranch;
        this.stmtDtoAmt.companyId = this.userDataDtoReturnSession.companyId;
        this.setAgtBalance(this.stmtDtoAmt);
        this.updateDestStmtBalAmt(this.stmtDtoAmt);
        // store.removeAll();
        // grid.reconfigure(store,cm);
        this.clearFields();
        $("#" + this.pageId + "branch").val('');
        $("#" + this.pageId + "statementNo").val('');
        $("#" + this.pageId + "receivedDate").val('');
        $("#" + this.pageId + "debitHead").val('');
        this.modelDebitHead = null;
        this.viewOtherDebit = false;
        this.modelDebitHeadOthers = null;
        $("#" + this.pageId + "subHead").val('');
        $("#" + this.pageId + "description").val('');
        $("#" + this.pageId + "value").val('');
        this.debitNoteStmtDataList = [];
        $("#" + this.pageId + "debitHeadTableId").DataTable().destroy();
        this.changeDetectorRef.detectChanges();
    }
    setAgtBalance(stmtDtoAmt) {
        console.log("stmtDtoAmt >> setAgtBalance");
        console.log(stmtDtoAmt);
        this.masterService.setDestAgentBalAmt(stmtDtoAmt).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == 'Failed') {
                    swal({
                        title: "Error",
                        text: "oops... Problem occurred in setAgtBalance ",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Saving Agt Balance", "info");
            }, () => console.log('done');

    }

    updateDestStmtBalAmt(stmtDtoAmt) {
        console.log("stmtDtoAmt >> updateDestStmtBalAmt");
        console.log(stmtDtoAmt);
        this.masterService.updateDestStmtBalAmt(stmtDtoAmt).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == 'Failed') {
                    swal({
                        title: "Error",
                        text: "oops... Problem occurred in updateDestStmtBalAmt ",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Saving Dest Stmt Bal Amt", "info");
            }, () => console.log('done');

    }

    ngAfterViewInit(): void {
        this.dtTriggerDebitNoteStmt.next();
    }

}
