<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Nature of Pack</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}packName">
													<label>Pack Name*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-box"></i>
														</span>
													</div>
													<input #packName (keyup)="fieldFocus($event, saveBtn)"
														type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}packName"
														name="packName" [(ngModel)]="selectedName">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button #saveBtn type="submit" class="btn btn-success m-r-10"
							(click)="savePackOfNatureBtn()" id="{{pageId}}saveBtn">Save/Update</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
						<button type="submit" class="btn btn-success m-r-10"
							id="{{pageId}}deleteBtn" (click)="validateDeleteRow()">Delete</button>
					</div>
				</div>
				<div class="col-md-4 vl">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="box-body">
									<table datatable id="{{pageId}}natureOfPackDatatableId"
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsNatureOfPack"
										[dtTrigger]="dtTriggerNatureOfPack">

										<thead>
											<tr>
												<th>Name</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let natureOfPackData of natureOfPackDataList"
												(click)="rowSelected(natureOfPackData);">
												<td>{{ natureOfPackData.packNature }}</td>
											</tr>


											<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<!-- Row -->
		</div>
	</div>
</div>
