import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';


@Component({
    selector: 'app-cashmemo-bkg-print-toparty',
    templateUrl: './cashmemo-bkg-print-toparty.component.html',
    styleUrls: ['./cashmemo-bkg-print-toparty.component.css']
})
export class CashmemoBkgPrintTopartyComponent implements OnInit {

    data: any;
    todayDate: any;
    memoDtoForPrint: LRDto = new LRDto();
    example: string;
    userDataDtoReturnSession: any;
    srdGstNO = '';
    smsDetails = '';
    mainstation = '';
    showMain = true;
    dateConvert: any;
    dateConvertBkg: any;
    discount = 0;
    showDetails = '';
    setLrAddress = '';

    //    memoTime = '';
    roundOffView = false;
    roundAmt: any;
    bottomMsg1 = '';
    address: string;
    addressComaSplit: string[];
    addressLineOne: string = '';
    addressLineTwo: string = '';
    //    city: string;
    //    pinCode: string;
    phone: string;
    //    cityPin: string;
    faxNo: string;
    emailId: string;
    grandTotal: any;
    num: any;
    n: any;
    isLoggedIn = true;
    barcodeImg: any;
    dataForPrintMemoList: LRDto[];
    viewBkgCashMemoPrintPage = true;
    empty = '';
    showFirstPrint = false;
    showSecondPrint = false;
    showThirdPrint = false;
    PrintBarcode = [];
    pageId = "ckpac";

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForPrintMemoList = JSON.parse(localStorage.getItem('printBkgCashmemoAutoGstList'));


            // this.PrintBarcode = [{
            //     barcode: this.rateDtoForPrint.cashMemoNumber,
            //     Name: 'A'
            // }];


            // if (this.mainstation != null
            //     && ((this.mainstation.toLowerCase() == 'mumbai')
            //         || (this.mainstation.toLowerCase() == 'delhi') || (this.mainstation.toLowerCase() == 'gujarat'))) {
            //     this.showMain = true;
            // } else {
            //     this.showMain = false;
            // }

            this.validatBeforePrint();
            
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();

            window.addEventListener('afterprint', (event) => {
                if (this.viewBkgCashMemoPrintPage) {
                    this.viewBkgCashMemoPrintPage = false;
                    this.clearField();
                }
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {
        for (let i = 0; i < this.dataForPrintMemoList.length; i++) {

            this.srdGstNO = this.userDataDtoReturnSession.cmpGstNo;
            if (this.srdGstNO == null || this.srdGstNO == '') {
                this.srdGstNO = '';
            } else {
                this.srdGstNO = this.srdGstNO;
            }
            this.dataForPrintMemoList[i].consignorState = this.dataForPrintMemoList[i].consignorState == null ? "NA" : this.dataForPrintMemoList[i].consignorState == '' ? "NA" : this.dataForPrintMemoList[i].consignorState.toUpperCase();
            this.dataForPrintMemoList[i].consignorStateCode = this.dataForPrintMemoList[i].consignorStateCode == null ? "NA" : this.dataForPrintMemoList[i].consignorStateCode == '' ? "NA" : this.dataForPrintMemoList[i].consignorStateCode.toUpperCase();
            this.dataForPrintMemoList[i].consigneeState = this.dataForPrintMemoList[i].consigneeState == null ? "NA" : this.dataForPrintMemoList[i].consigneeState == '' ? "NA" : this.dataForPrintMemoList[i].consigneeState.toUpperCase();
            this.dataForPrintMemoList[i].consigneeStateCode = this.dataForPrintMemoList[i].consigneeStateCode == null ? "NA" : this.dataForPrintMemoList[i].consigneeStateCode == '' ? "NA" : this.dataForPrintMemoList[i].consigneeStateCode.toUpperCase();
            this.dataForPrintMemoList[i].entryBy = this.dataForPrintMemoList[i].entryBy == null ? "NA" : this.dataForPrintMemoList[i].entryBy == '' ? "NA" : this.dataForPrintMemoList[i].entryBy.toUpperCase();

            if (this.dataForPrintMemoList[i].stationCode == null || this.dataForPrintMemoList[i].stationCode == '') {
                this.dataForPrintMemoList[i].stationCode = 'NA';
            } else {
                this.dataForPrintMemoList[i].stationCode = this.dataForPrintMemoList[i].stationCode;
            }

            if (this.dataForPrintMemoList[i].consignorState == null || this.dataForPrintMemoList[i].consignorState == '') {
                this.dataForPrintMemoList[i].consignorState = 'NA';
            } else {
                this.dataForPrintMemoList[i].consignorState = this.dataForPrintMemoList[i].consignorState;
            }

            if (this.dataForPrintMemoList[i].bookingDate == null) {
                this.dateConvert = 'NA';
                this.dataForPrintMemoList[i].bookingDate = this.dateConvert;
            } else {
                this.dateConvert = this.datePipe.transform(this.dataForPrintMemoList[i].bookingDate, "dd-MM-yyyy");
                this.dataForPrintMemoList[i].bookingDate = this.dateConvert;
            }

            if (this.dataForPrintMemoList[i].enteredDate == null) {
                this.dateConvertBkg = 'NA';
                this.dataForPrintMemoList[i].enteredDate = this.dateConvertBkg;
            } else {
                this.dateConvertBkg = this.datePipe.transform(this.dataForPrintMemoList[i].enteredDate, "dd-MM-yyyy");
                this.dataForPrintMemoList[i].enteredDate = this.dateConvertBkg;
            }

            if (this.mainstation != null
                && ((this.mainstation.toLowerCase() == 'mumbai')
                    || (this.mainstation.toLowerCase() == 'gujarat'))) {
                this.showFirstPrint = true;
                this.showSecondPrint = true;
                this.showThirdPrint = true;
            } else {
                this.showFirstPrint = true;
                this.showSecondPrint = false;
                this.showThirdPrint = false;
            }
            if (this.dataForPrintMemoList[i].frieghtAmt == null) {
                this.dataForPrintMemoList[i].frieghtAmt = 0;
            } else {
                this.dataForPrintMemoList[i].frieghtAmt = Math.round(this.dataForPrintMemoList[i].frieghtAmt);
            }
            if (this.dataForPrintMemoList[i].discountAmount == 0) {
                this.discount = 0;
            } else {
                this.discount = this.dataForPrintMemoList[i].discountAmount;
            }
            this.empty = '';
            if (this.dataForPrintMemoList[i].hamaliGD == null) {
                this.dataForPrintMemoList[i].hamaliGD = 0;
            } else {
                this.dataForPrintMemoList[i].hamaliGD = Math.round(this.dataForPrintMemoList[i].hamaliGD);
            }

            if (this.dataForPrintMemoList[i].receiptCharge == null) {
                this.dataForPrintMemoList[i].receiptCharge = 0;
            } else {
                this.dataForPrintMemoList[i].receiptCharge = Math.round(this.dataForPrintMemoList[i].receiptCharge);
            }

            if (this.dataForPrintMemoList[i].serviceCharge == null) {
                this.dataForPrintMemoList[i].serviceCharge = 0;
            } else {
                this.dataForPrintMemoList[i].serviceCharge = Math.round(this.dataForPrintMemoList[i].serviceCharge);
            }

            if (this.dataForPrintMemoList[i].destHamali == null) {
                this.dataForPrintMemoList[i].destHamali = 0;
            } else {
                this.dataForPrintMemoList[i].destHamali = Math.round(this.dataForPrintMemoList[i].destHamali);
            }

            if (this.dataForPrintMemoList[i].subTotal == null) {
                this.dataForPrintMemoList[i].subTotal = 0;
            } else {
                this.dataForPrintMemoList[i].subTotal = Math.round(this.dataForPrintMemoList[i].subTotal);
            }

            if (this.dataForPrintMemoList[i].cgstamt == null) {
                this.dataForPrintMemoList[i].cgstamt = 0;
            } else {
                this.dataForPrintMemoList[i].cgstamt = Math.round(this.dataForPrintMemoList[i].cgstamt);
            }

            if (this.dataForPrintMemoList[i].sgstamt == null) {
                this.dataForPrintMemoList[i].sgstamt = 0;
            } else {
                this.dataForPrintMemoList[i].sgstamt = Math.round(this.dataForPrintMemoList[i].sgstamt);
            }

            if (this.dataForPrintMemoList[i].igstamt == null) {
                this.dataForPrintMemoList[i].igstamt = 0;
            } else {
                this.dataForPrintMemoList[i].igstamt = Math.round(this.dataForPrintMemoList[i].igstamt);
            }

            if (this.dataForPrintMemoList[i].gstAmtTotal == null) {
                this.dataForPrintMemoList[i].gstAmtTotal = 0;
            } else {
                this.dataForPrintMemoList[i].gstAmtTotal = Math.round(this.dataForPrintMemoList[i].gstAmtTotal);
            }
            this.bottomMsg1 = "Track Your Consignment from Anywhere at Anytime. Just enter Your LR.NO at www.srdlogistics.com to get Status Of Your Consignment.";
            //console.log( this.bankDetails );
            // this.smsDetails = 'To Receive SMS On Arrival Of Your Consignment, please update your Cell Number Or Contact 07899686234. Please download SRD Mobile APP in Google Play Store for Tracking Your Consignment.';
            this.smsDetails = 'To receive WhatsApp/SMS on booking & arrival of your consignment, kindly update your cell no. by sending a Whatsapp message to +919697952233. Please download SRD mobile app on your phone to track Your Consignment on the go.';

            this.mainstation = this.dataForPrintMemoList[i].mainstation;
            if (this.mainstation != null
                && ((this.mainstation.toLowerCase() == 'mumbai')
                    || (this.mainstation.toLowerCase() == 'delhi') || (this.mainstation.toLowerCase() == 'gujarat'))) {
                this.address = this.dataForPrintMemoList[i].address;
                this.phone = this.dataForPrintMemoList[i].mobileNum;
                this.emailId = this.dataForPrintMemoList[i].emailId;
                this.faxNo = this.dataForPrintMemoList[i].faxNo;

            } else {
                this.address = this.userDataDtoReturnSession.addressId == null ? "N/A" : this.userDataDtoReturnSession.addressId;
                this.phone = this.userDataDtoReturnSession.phoneNo == null ? "N/A" : this.userDataDtoReturnSession.phoneNo;
                this.emailId = this.userDataDtoReturnSession.cmpEmailId == null ? "N/A" : this.userDataDtoReturnSession.cmpEmailId;
                this.faxNo = this.userDataDtoReturnSession.faxNo == null ? "N/A" : this.userDataDtoReturnSession.faxNo;
            }

            //For Address
            this.addressComaSplit = this.address.split(",");
            this.addressLineOne = '';
            this.addressLineTwo = '';
            if (this.addressComaSplit.length > 0) {
                this.addressLineTwo = this.addressComaSplit[this.addressComaSplit.length - 1] + ",";
                for (let b = 0; b < this.addressComaSplit.length - 1; b++) {
                    this.addressLineOne += this.addressComaSplit[b] + ",";
                }
            } else {
                this.addressLineOne = this.address;
            }
            if (this.dataForPrintMemoList[i].grandTotal == 0.0 || this.dataForPrintMemoList[i].grandTotal == 0) {
                this.grandTotal = "Zero";
            } else {
                this.grandTotal = this.inWords(this.dataForPrintMemoList[i].grandTotal);
            }

            if (this.roundOffView == true) {
                this.roundAmt = ("Rounded Of " + this.dataForPrintMemoList[i].roundOffAmt == null ? 0.0 : this.dataForPrintMemoList[i].roundOffAmt);
            }
        }
    }

    inWords(grandTot) {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = grandTot;
        //                console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }

    clearField() {
        this.roundOffView = false;
        this.roundAmt = '';
        this.bottomMsg1 = '';
        this.address = "";
        this.addressComaSplit = [];
        this.addressLineOne = '';
        this.addressLineTwo = '';
        this.phone = '';
        this.faxNo = '';
        this.emailId = '';
        this.grandTotal = '';
        this.num = '';
        this.n = '';
        this.dateConvert = '';
        this.barcodeImg = '';
        this.dataForPrintMemoList = [];
        this.srdGstNO = '';
        this.smsDetails = '';
        this.mainstation = '';
        this.showMain = true;
        this.dateConvert = '';
        this.dateConvertBkg = '';
        this.discount = 0;
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
