<html>

<head>
<style>
/* for ipad and ipad pro */
@media ( min-width : 768px) and (max-width: 1200px) {
	.col-md-3 {
		flex: 0 0 50% !important;
		max-width: 100% !important;
	}
}
</style>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Tempo Performance Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-6">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body" style="border-bottom: 1px solid orange;">
									<div class="row">
										<div class="col-lg-12">
											<!-- <h6 class="card-title">Tempo Details</h6> -->
											<div class="row">
												<div class="col-md-5">
													<div class="form-group">
														<div class="input-group">
															<label>Report Date</label> <input class="form-control"
																id="{{pageId}}reportDate" placeholder="yyyy-mm-dd"
																name="reportDates" ngbDatepicker
																#reportDates="ngbDatepicker">
															<div class="input-group-append"
																(click)="reportDates.toggle()">
																<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-5">
													<button type="submit" class="btn btn-success m-r-10"
														(click)="getRptBtn()" id="{{pageId}}getReportBtn">Get
														Report</button>
													<button type="submit" class="btn btn-dark"
														(click)="clearFields()" id="{{pageId}}clearBtn">Clear</button>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div class="col-md-12 p-t-10">
							<div class="box-body">
								<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
									<div class="form-group">
										<div class="input-group">
											<mat-progress-bar mode="indeterminate" style="color: green;">
											</mat-progress-bar>
											<br>
											<h6 class="card-title" align='center'
												style="color: green; margin: auto; font-size: 18px;">
												Please Wait Loading Details.....</h6>
										</div>
									</div>
								</div>
								<!-- 							the first datatble starts -->
								<table datatable id="{{pageId}}tempoPerfTableId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsTripDetails"
									[dtTrigger]="dtTriggerTripDetails">

									<thead>
										<tr>
											<th>Tempo No</th>
											<th>Driver Name</th>
											<th>Tot Trips</th>
											<th>Tot Lrs</th>
											<th>Tot Art</th>
											<th>Act Wgt</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let tripDetailsData of tripDetailsDataList ">
											<td>{{ tripDetailsData.vehiclenumber }}</td>
											<td>{{ tripDetailsData.drivername }}</td>
											<td>{{ tripDetailsData.tripNo }}</td>
											<td>{{ tripDetailsData.totallrs }}</td>
											<td>{{ tripDetailsData.totalarticles }}</td>
											<td>{{ tripDetailsData.actWeight }}</td>
										</tr>
									</tbody>

									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tfoot>

								</table>
							</div>
						</div>

					</div>
					<div class="col-md-6 vl">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-12">
											<!-- <h6 class="card-title">Tempo Details</h6> -->
											<div class="row">
												<div class="col-md-5">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}startKm">
															<label>Km Entry</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-hourglass-start"></i>
																</span>
															</div>
															<input type="number" placeholder="Start KM"
																class="form-control" (keyup)="kmEntryFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}endKm">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-hourglass-end"></i>
																</span>
															</div>
															<input type="number" placeholder="End KM"
																class="form-control" (keyup)="endKmFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}kmRun">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-hourglass"></i>
																</span>
															</div>
															<input type="number" placeholder="KM Run"
																class="form-control" (keyup)="kmRunFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}tempoDescription"
                                                                name="tempoDescription" #tempoDescription
                                                                (change)="tempoDescriptionMode(tempoDescription.value)">
                                                                <option selected>Select Description</option>
                                                                <option value="tempAddNew">Add New</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> -->
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
																</span>
															</div>
															<input id="{{pageId}}tempoDescription" type="text"
																class="form-control"
																(change)="tempoDescriptionMode(searchTempoDescription)"
																[(ngModel)]="modelTempoDescription"
																[ngbTypeahead]="searchTempoDescription"
																[resultFormatter]="formatterTempoDescription"
																[inputFormatter]="formatterTempoDescription"
																(focus)="focusTempoDescriptionTA$.next($any($event).target.value)"
																placeholder="Select Description" />
														</div>

													</div>
												</div>
												<div *ngIf="viewTempoAddNew" class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}tempoAddNewDesc">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-plus"></i>
																</span>
															</div>
															<input type="text" placeholder="Add New"
																class="form-control"
																(keyup)="tempoAddNewDescFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}tempoValue">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" placeholder="Value"
																class="form-control" (keyup)="tempoValueFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group">
															<a href="javascript:;" type='button' id="{{pageId}}addInTableTempo"
																#addBtn style="padding: 1px 4px;"
																class="btn btn-icon-only yellow" (click)="addInTable();">
																<i class="fa fa-plus" title="Add"></i>
															</a> <a href="javascript:;" type='button'
																id="{{pageId}}removeInTableTempo" #addBtn
																style="padding: 1px 4px; color: black;"
																class="btn btn-icon-only yellow"> <i
																class="fa fa-trash-alt" title="Remove"></i>
															</a>
														</div>
													</div>
												</div>

												<!-- <div class="col-md-3">
                                                    <button type="submit" class="btn btn-dark" id="{{pageId}}tempoRemoveBtn">Remove</button>
                                                </div> -->

											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<h6 style="text-align: center" class="card-title">Tempo
										Specific Entries</h6>
								</div>
								<div class="col-md-8" style="border-top: 1px solid orange;">
								</div>
							</div>
							<div class="box-body p-t-10"
								style="border-bottom: 1px solid orange;">
								<!-- 							<the second datatable starts  -->
								<div [hidden]="tempoEntriesTable">
									<div class="box-body p-t-10">
										<div [hidden]="additionTable">
											<table datatable id="{{pageId}}additionTableId"
												class="table table-striped table-bordered row-border hover"
												[dtOptions]="dtOptionTempoEntries"
												[dtTrigger]="dtTriggerTempoEntries">
												<thead>
													<tr>
														<th>Description</th>
														<th>Value</th>
													</tr>
												</thead>
												<tbody>

													<tr
														*ngFor="let tempoEntriesData of tempoEntriesDataList ; let i = index">
														<td>{{ tempoEntriesData.description }}</td>
														<td>{{ tempoEntriesData.totalAmount }}</td>
													</tr>
												</tbody>
												<tfoot>
													<tr>
														<td></td>
														<td></td>
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
								</div>


								<div class="col-md-12 p-t-10 p-b-10 " style="text-align: right;">
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}nextBtn">Next</button>
								</div>
							</div>
						</div>
						<!-- </div> -->

						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-12">
											<!-- <h6 class="card-title">Tempo Details</h6> -->
											<div class="row">
												<!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                            <select class="custom-select col-12" id="{{pageId}}commonDescription"
                                                                name="commonDescription" #commonDescription
                                                                (change)="commonDescriptionMode(commonDescription.value)">
                                                                <option selected>Select Description</option>
                                                                <option value="cmnAddNew">Add New</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> -->
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
																</span>
															</div>
															<input id="{{pageId}}commonDescription" type="text"
																class="form-control"
																(change)="commonDescriptionMode(searchCommonDescription)"
																[(ngModel)]="modelCommonDescription"
																[ngbTypeahead]="searchCommonDescription"
																[resultFormatter]="formatterCommonDescription"
																[inputFormatter]="formatterCommonDescription"
																(focus)="focusCommonDescriptionTA$.next($any($event).target.value)"
																placeholder="Select Description" />
														</div>

													</div>
												</div>
												<div *ngIf="viewCommonAddNew" class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}commonAddNew">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-plus"></i>
																</span>
															</div>
															<input type="text" placeholder="Add New"
																class="form-control" (keyup)="commonAddNewFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}commonValue">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
																</span>
															</div>
															<input type="number" placeholder="Value"
																class="form-control" (keyup)="commonValueFocus($event)"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
												<div class="col-md-2">
													<div class="form-group">
														<div class="input-group">
															<a href="javascript:;" type='button'
																id="{{pageId}}addInTableCommon" #addBtn style="padding: 1px 4px;"
																class="btn btn-icon-only yellow" (click)="addInTable();">
																<i class="fa fa-plus" title="Add"></i>
															</a> <a href="javascript:;" type='button'
																id="{{pageId}}removeBtnCommon" #addBtn
																style="padding: 1px 4px; color: black;"
																class="btn btn-icon-only yellow"> <i
																class="fa fa-trash-alt" title="Remove"></i>
															</a>
														</div>
													</div>
												</div>
												<!-- <div class="col-md-3">
                                                    <button type="submit" class="btn btn-dark" id="{{pageId}}commonRemoveBtn">Remove</button>
                                                </div> -->

											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 							the third datatable starts  -->
							<div class="row">
								<div class="col-md-4">
									<h6 style="text-align: center" class="card-title">Common
										Entries</h6>
								</div>
								<div class="col-md-8" style="border-top: 1px solid orange;">
								</div>
							</div>
							<div class="box-body p-t-10">
								<div class="p-t-10">
									<table datatable
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionCommonEntries"
										[dtTrigger]="dtTriggerCommonEntries">
										<thead>
											<tr>
												<th>Description</th>
												<th>Value</th>
											</tr>
										</thead>
										<tbody>

											<tr *ngFor="let commonEntriesData of commonEntriesDataList ">
												<td>{{ commonEntriesData.description }}</td>
												<td>{{ commonEntriesData.value }}</td>
											</tr>

											<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

										</tbody>
									</table>
								</div>
								<!-- 							the third datatable ends  -->

							</div>
						</div>
						<div class="col-md-12 p-t-10 p-b-10 " style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}updateBtn">Update</button>
						</div>

					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>