import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

import { MobileAppsRoutes } from 'src/app/mobileApps/mobileApps.routing';
import { AboutUsComponent } from 'src/app/mobileApps/about-us/about-us.component';
import { LatestNewsComponent } from 'src/app/mobileApps/latest-news/latest-news.component';
import { OurBranchesComponent } from 'src/app/mobileApps/our-branches/our-branches.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { MaterialModule } from "src/app/material.module";
import { RouteMappingComponent } from 'src/app/mobileApps/route-mapping/route-mapping.component';


@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( MobileAppsRoutes ),
         FormsModule,  NgbModule, DataTablesModule,CashmemoReportModule,MaterialModule],
    declarations: [AboutUsComponent, LatestNewsComponent,OurBranchesComponent,RouteMappingComponent
    ]
} )
export class MobileAppsModule { }
