<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div *ngIf="showSpinnerForAction" class="col-md-8">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                        name="searchBy" #searchBy 
                                                        (change)="searchByMode(searchBy.value)">
                                                        <option value="">Select Type....</option>
                                                        <option value="Days">Stop based on Days</option>
                                                        <option value="Stock Date">Stop based on Stock Date</option>
                                                        <option value="Invoice">Stop based on Invoice</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showDestination" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #destinationID id="{{pageId}}destinationID" type="text" class="form-control" [(ngModel)]="modelDropDownDestination" [ngbTypeahead]="searchDropDownInputFieldDestination" [resultFormatter]="formatterDropDownDestination"
                                                        [inputFormatter]="formatterDropDownDestination" placeholder="Select Destination" (focus)="focusDropDownDestinationTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <h6 class="card-title">Select Days To Stop Arrival Notification</h6> -->
                                        <div class="col-sm-12 col-md-12" *ngIf="viewDays">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxSunday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}checkboxSunday">Sunday</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockMonday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockMonday">Monday</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewDays">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockTuesday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockTuesday">Tuesday</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockWednesday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockWednesday">Wednesday</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewDays">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockThursday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockThursday">Thursday</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockFriday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockFriday">Friday</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewDays">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="col-sm-6 col-md-6">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}cbStockSaturday"> <label
                                                                class="custom-control-label wtfull"
                                                                for="{{pageId}}cbStockSaturday">Saturday</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <h6 class="card-title">Other Configuration</h6> -->
                                        <div class="col-sm-12 col-md-12" *ngIf="viewStockDate">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Stop For Stock Date</label>
                                                    <div class="input-group-append"
                                                        (click)="stopStockNotificationDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"
                                                                style='cursor: pointer;'></i>
                                                        </span>
                                                    </div>

                                                    <input class="form-control" id="{{pageId}}stopStockNotificationDate"
                                                        name="stopStockNotificationDate" ngbDatepicker
                                                        placeholder="dd-mm-yyyy"
                                                        #stopStockNotificationDate="ngbDatepicker"
                                                        (keydown.arrowdown)="stopStockNotificationDate.toggle();"
                                                        (click)="stopStockNotificationDate.toggle();">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewInvoice">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Stop For Invoice</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input #stopStockNotificationInvoice type="text"
                                                        class="form-control" id="{{pageId}}stopStockNotificationInvoice"
                                                        name="stopStockNotificationInvoice"
                                                        aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <hr style="width: 95%;">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}saveBtn" #saveBtn
                                                    (click)="searchMethod()">Save
                                                    Details</button>
                                                <button class="btn btn-danger" mat-button
                                                    (click)="onCancelClick();">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-7">
                                    <div class="row">
                                        <div class="col-md-12 vl">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h6 class="card-title">Recent Stop Invoice/Stock Date
                                                                Details</h6>
                                                            <div class="box-body">
                                                                <table datatable
                                                                    id="{{pageId}}stopNotificationDetailsTableId"
                                                                    class="table table-striped table-bordered row-border hover"
                                                                    [dtOptions]="dtOptionsStopNotification"
                                                                    [dtTrigger]="dtTriggerStopNotification">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Type</th>
                                                                            <th>Invoice Number</th>
                                                                            <th>Stock Date</th>
                                                                            <th>Updated At</th>
                                                                            <th>Updated By</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let stockNotificationStopData of stockNotificationStopDetails; let i = index">
                                                                            <td>{{ stockNotificationStopData.type }}
                                                                            </td>
                                                                            <td>{{
                                                                                stockNotificationStopData.invoiceNumber
                                                                                }}</td>
                                                                            <td>{{ stockNotificationStopData.invoiceDate
                                                                                }}</td>
                                                                            <td>{{ stockNotificationStopData.dateStr }}
                                                                            </td>
                                                                            <td>{{ stockNotificationStopData.createdBy
                                                                                }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>