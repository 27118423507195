import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';

import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from "src/app/dataService/dest-stmt-report";


@Component({
	selector: 'app-debit-note-report',
	templateUrl: './debit-note-report.component.html',
	styleUrls: ['./debit-note-report.component.css']
})
export class DebitNoteReportComponent implements OnInit {
	getDataFrmServiceFrDebitNoteRptTable: any;
	debitNoteRptDataList: any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends
	loadingIndicator = true;
	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	public modelDestinationFotGet: any;
	fromDateModel: any;
	toDateModel: any;

	selectedDestination: any;
	address: any;
	office: any;


	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerDebitNoteRpt: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsDebitNoteRpt: any;
	viewDestination = false;
	destView = false;

	/////For Debit Head
	debitHeadOptions: FortNightStmtDto[];
	fortNightStmtDebitHeadDto: FortNightStmtDto = new FortNightStmtDto();
	fortNightStmtDebitHeadAllOptions: FortNightStmtDto = new FortNightStmtDto();
	public modelDebitHead: any;
	debitHeadTA: Array<FortNightStmtDto> = [];
	focusDebitHeadTA$ = new Subject<string>();
	searchDebitHead = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDebitHeadTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.debitHeadTA
				: this.debitHeadTA.filter(v => v.mainDebitHeads.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDeebitHead = (x: { mainDebitHeads: string }) => x.mainDebitHeads;
	//// For OTher Debit Heads
	debitHeadOthersOptions: FortNightStmtDto[];
	fortNightStmtDebitHeadOthersDto: FortNightStmtDto = new FortNightStmtDto();
	public modelDebitHeadOthers: any;
	debitHeadOthersTA: Array<FortNightStmtDto> = [];
	focusDebitHeadOthersTA$ = new Subject<string>();
	searchDebitHeadOthers = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDebitHeadOthersTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.debitHeadOthersTA
				: this.debitHeadOthersTA.filter(v => v.subDebitHeads.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDebitHeadOthers = (x: { subDebitHeads: string }) => x.subDebitHeads;
	/////
	public modelDebitHeadForGet: any;
	showStmtWise = true;
	showDateWise = false;
	viewOtherDebit = false;
	selectedSearchBy: any;
	searchByFortNightDto: FortNightStmtDto = new FortNightStmtDto();
	searchByAuthorityFortNightDto: FortNightStmtDto = new FortNightStmtDto();
	setDescription: any;
	selectedFromDate: any;
	selectedToDate: any;
	enteredStatementNumber: any;
	selectedDebitHead: any;
	selectedSubHead: any;
	setTheDestOnTable: any;
	showDest = 'DebitNoteRpt DestView';

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	amountSummary: number;
	pageId="dbnrc";
	
	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService, private destStmtReport: DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.getDebitNoteMasterList();
			this.getDebitNoteMasterForOthersList();
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"DebitNoteRpt DestView") {
						//New Rights not given
						this.destView = true;
					}

				}
			}
			/*if (this.showDest=='DebitNoteRpt DestView') {
					this.destView=true;         
					}*/


			/* if ( this.userDataDtoReturnSession.role != null
						&& this.userDataDtoReturnSession.role =="Super Administrator") {
					this.destView =true;
				}*/
			if (this.destView == true) {
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}



		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}
	ngAfterViewInit(): void {
		this.dtTriggerDebitNoteRpt.next();
	}
	ngOnDestroy(): void {
		this.dtTriggerDebitNoteRpt.unsubscribe();
	}


	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var mainStation = this.office;
		console.log(mainStation);
		var destVisible = false;
		if (this.destView == true) {
			destVisible = true;
		} else {
			destVisible = false;
		}
		this.dtOptionsDebitNoteRpt = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = null;
						if (destVisible == true) {
							if ($("#dbnrcsearchBy").val() == 'stmtNoWise') {
								returnSummaryExcel = "Debit Note Report" +
									" Statement Number : " + $("#dbnrcstatementNo").val();
							} else {
								returnSummaryExcel = "Debit Note Report" +
									" From Date : " + moment($("#dbnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#dbnrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#dbnrcdestination").val() + " Debit Head : " + $("#dbnrcdebitHead").val();
							}

						} else {
							if ($("#dbnrcsearchBy").val() == 'stmtNoWise') {
								returnSummaryExcel = "Debit Note Report" +
									" Statement Number : " + $("#dbnrcstatementNo").val();
							} else {
								returnSummaryExcel = "Debit Note Report" +
									" From Date : " + moment($("#dbnrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#dbnrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + mainStation + " Debit Head : " + $("#dbnrcdebitHead").val();
							}
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": false,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var debit = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(debit);
				//$( api.column( 3 ).footer() ).html();
			}

		}

	}
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}
	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}


					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	}

	getDebitNoteMasterListRead() {
		this.fortNightStmtDebitHeadDto = new FortNightStmtDto();
		this.fortNightStmtDebitHeadDto.companyId = this.userDataDtoReturnSession.companyId;
		this.fortNightStmtDebitHeadDto.reportMode = "listBox";
	}


	getDebitNoteMasterList = () => {
		this.getDebitNoteMasterListRead();
		this.masterReadService.getDebitNoteMaster(this.fortNightStmtDebitHeadDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {

					if (response.length > 0) {
						this.debitHeadOptions = response;
						this.debitHeadTA = [];
						this.fortNightStmtDebitHeadAllOptions.mainDebitHeads = 'All';
						this.debitHeadTA.push(this.fortNightStmtDebitHeadAllOptions);
						for (let i = 0; i < this.debitHeadOptions.length; i++) {
							this.debitHeadTA.push(this.debitHeadOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Debit  Head details", "info");
			}, () => console.log('done');
	};

	debitHeadListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDebitHeadForGet = e.item;
		if (this.modelDebitHeadForGet.mainDebitHeads == "Others") {
			this.viewOtherDebit = true;
		} else {
			this.viewOtherDebit = false;
		}

	}

	getDebitNoteMasterListForOthersRead() {
		this.fortNightStmtDebitHeadOthersDto = new FortNightStmtDto();
		this.fortNightStmtDebitHeadOthersDto.companyId = this.userDataDtoReturnSession.companyId;
		this.fortNightStmtDebitHeadOthersDto.reportMode = "subHeads";
		this.fortNightStmtDebitHeadOthersDto.mainDebitHeads = "Others";
		console.log(this.fortNightStmtDebitHeadOthersDto);
	}


	getDebitNoteMasterForOthersList = () => {
		this.getDebitNoteMasterListForOthersRead();
		this.masterReadService.getDebitNoteMaster(this.fortNightStmtDebitHeadOthersDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {

					if (response.length > 0) {
						this.debitHeadOthersOptions = response;
						this.debitHeadOthersTA = [];
						for (let i = 0; i < this.debitHeadOthersOptions.length; i++) {
							this.debitHeadOthersTA.push(this.debitHeadOthersOptions[i]);
						}
						console.log(this.debitHeadOthersTA);
					}
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Sub  Head details", "info");
			}, () => console.log('done');
	};

	searchByMode(searchBy: string) {
		if (searchBy === 'stmtNoWise') {
			this.showStmtWise = true;
			this.showDateWise = false;
			$("#"+this.pageId+"fromDate").val('');
			$("#"+this.pageId+"toDate").val('');
			$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
			this.debitNoteRptDataList = [];
			this.dtTriggerDebitNoteRpt.next();
			$("#"+this.pageId+"statementNo").val('');
			this.viewDestination = false;
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;

		} else if (searchBy === 'dateWise') {
			this.showStmtWise = false;
			this.showDateWise = true;
			$("#"+this.pageId+"fromDate").val('');
			$("#"+this.pageId+"toDate").val('');
			$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
			this.debitNoteRptDataList = [];
			this.dtTriggerDebitNoteRpt.next();
			$("#"+this.pageId+"statementNo").val('');
			if (this.destView == true) {
				this.viewDestination = true;
				$("#"+this.pageId+"destination").val('');
			} else {
				this.viewDestination = false;
			}
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;

		} else {
			this.showStmtWise = false;
			this.showDateWise = false;
			$("#"+this.pageId+"fromDate").val('');
			$("#"+this.pageId+"toDate").val('');
			$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
			this.debitNoteRptDataList = [];
			this.dtTriggerDebitNoteRpt.next();
			$("#"+this.pageId+"statementNo").val('');
			if (this.destView == true) {
				this.viewDestination = true;
				$("#"+this.pageId+"destination").val('');
			} else {
				this.viewDestination = false;
			}
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;

		}
	}

	searchMethod() {
		this.selectedSearchBy = $("#"+this.pageId+"searchBy").val();
		this.enteredStatementNumber = $("#"+this.pageId+"statementNo").val();
		this.selectedDebitHead = $("#"+this.pageId+"debitHead").val();
		this.selectedSubHead = $("#"+this.pageId+"subHead").val();
		this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
		this.selectedToDate = $("#"+this.pageId+"toDate").val();
		this.selectedDestination = $("#"+this.pageId+"destination").val();
		this.searchByFortNightDto = new FortNightStmtDto();

		if (this.enteredStatementNumber != null && this.enteredStatementNumber != '') {
			this.searchByFortNightDto.mode = 'STMT';
		} else {
			this.searchByFortNightDto.mode = 'DEST';
		}

		if (this.destView == true) {
			this.setTheDestOnTable = " For " + this.selectedDestination;
		} else {
			this.setTheDestOnTable = " For " + this.userDataDtoReturnSession.office;
		}




		if (this.selectedDebitHead != null && this.selectedDebitHead != '' && this.selectedDebitHead == 'All') {
			this.searchByFortNightDto.reportMode = 'ALL';
		} else if (this.selectedDebitHead != null && this.selectedDebitHead != '' && this.selectedDebitHead != 'All') {
			this.searchByFortNightDto.reportMode = 'specific';
		} else {
			this.searchByFortNightDto.reportMode = 'select';
		}

		if (this.selectedDebitHead != null && this.selectedDebitHead != '' && this.selectedDebitHead == 'miscellaneous') {
			this.searchByFortNightDto.modeForMiscellaneous = 'miscellaneous';
		} else {
			this.searchByFortNightDto.modeForMiscellaneous = null;
		}

		if (this.selectedSearchBy == "stmtNoWise") {
			if (this.enteredStatementNumber == null || this.enteredStatementNumber == '') {
				swal({
					title: "Warning",
					text: "Please Enter Statement Number!",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				if (this.userDataDtoReturnSession.role != null
					&& this.userDataDtoReturnSession.role == "Super Administrator") {
					this.searchByFortNightDto.stmtNo = this.enteredStatementNumber;
					if ((this.searchByFortNightDto.reportMode == 'ALL') || (this.searchByFortNightDto.reportMode == 'specific')) {
						this.getDebitDetailsForListBox();
					} else if (this.searchByFortNightDto.reportMode == 'select') {
						this.getDebitDetails();
					}
				} else {
					this.searchByFortNightDto.stmtNo = this.enteredStatementNumber;
					this.checkForAuthority();
				}
			}
			this.setTheDestOnTable = '';
		} else if (this.selectedSearchBy == "dateWise") {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				if (this.userDataDtoReturnSession.role != null
					&& this.userDataDtoReturnSession.role == "Super Administrator") {
					if (this.selectedDestination != null || this.selectedDestination != '') {
						this.searchByFortNightDto.destination = this.selectedDestination;
					} else {
						this.searchByFortNightDto.destination = this.userDataDtoReturnSession.office;
					}
				} else {
					this.searchByFortNightDto.destination = this.userDataDtoReturnSession.office;
				}

				this.searchByFortNightDto.fromDate = this.selectedFromDate;
				this.searchByFortNightDto.toDate = this.selectedToDate;
				if ((this.searchByFortNightDto.reportMode == 'ALL') || (this.searchByFortNightDto.reportMode == 'specific')) {
					this.getDebitDetailsForListBox();
				} else if (this.searchByFortNightDto.reportMode == 'select') {
					this.getDebitDetails();
				}

			}

		}
	}

	checkForAuthority() {
		this.enteredStatementNumber = $("#"+this.pageId+"statementNo").val();
		this.selectedDebitHead = $("#"+this.pageId+"debitHead").val();
		this.searchByAuthorityFortNightDto = new FortNightStmtDto();
		this.searchByAuthorityFortNightDto.stmtNo = this.enteredStatementNumber;
		this.searchByAuthorityFortNightDto.branch = this.userDataDtoReturnSession.office;
		this.searchByAuthorityFortNightDto.status = 'DebitRpt';
		if (this.selectedDebitHead != null && this.selectedDebitHead != '' && this.selectedDebitHead == 'All') {
			this.searchByAuthorityFortNightDto.reportMode = 'ALL';
		} else if (this.selectedDebitHead != null && this.selectedDebitHead != '' && this.selectedDebitHead != 'All') {
			this.searchByAuthorityFortNightDto.reportMode = 'specific';
		} else {
			this.searchByAuthorityFortNightDto.reportMode = 'select';
		}
		this.searchForAuthorization();
		console.log(this.searchByAuthorityFortNightDto);
	}

	searchForAuthorization = () => {
		this.destStmtReport.chkAgentAutority(this.searchByAuthorityFortNightDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == 'notExist') {
					swal({
						title: "No Result Found",
						text: "No Result Found " + this.enteredStatementNumber,
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
					this.debitNoteRptDataList = [];
					this.dtTriggerDebitNoteRpt.next();

				} else if (response.status == 'unauthorized') {
					swal({
						title: "UnAuthorized",
						text: "U are not authorized to view this informations",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
					this.debitNoteRptDataList = [];
					this.dtTriggerDebitNoteRpt.next();

				} else {
					if ((this.searchByAuthorityFortNightDto.reportMode == 'ALL') || (this.searchByAuthorityFortNightDto.reportMode == 'specific')) {
						this.getDebitDetailsForListBox();
					} else if (this.searchByAuthorityFortNightDto.reportMode == 'select') {
						this.getDebitDetails();
					}
				}


				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred  While Checking Authorization of Debit head Details", "info");
			}, () => console.log('done');
	};

	getDebitDetailsForListBox = () => {
		this.showSpinnerForAction = true;
		this.setDescription = $("#"+this.pageId+"debitHead").val();
		if (this.viewOtherDebit == true) {
			this.setDescription = $("#"+this.pageId+"subHead").val();
		}
		if (this.setDescription == null || this.setDescription == '') {
			this.setDescription = 'NA';
		}
		this.searchByFortNightDto.description = this.setDescription;
		console.log(this.searchByFortNightDto);

		this.destStmtReport.getDebitNoteReportDetails(this.searchByFortNightDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
				this.debitNoteRptDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.debitNoteRptDataList = response;
					console.log(this.debitNoteRptDataList);
				}
				this.dtTriggerDebitNoteRpt.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Debit Head details", "info");
			}, () => console.log('done');
	};

	getDebitDetails = () => {
		this.showSpinnerForAction = true;
		this.setDescription = $("#"+this.pageId+"debitHead").val();
		if (this.viewOtherDebit == true) {
			this.setDescription = $("#"+this.pageId+"subHead").val();
		}
		if (this.setDescription == null || this.setDescription == '') {
			this.setDescription = 'NA';
		}
		this.searchByFortNightDto.description = this.setDescription;
		console.log(this.searchByFortNightDto);

		this.destStmtReport.getDebitNoteReportDetails(this.searchByFortNightDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
				this.debitNoteRptDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.debitNoteRptDataList = response;
					console.log(this.debitNoteRptDataList);
				}
				this.dtTriggerDebitNoteRpt.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Debit Head details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.setDescription = 'NA';
		this.showStmtWise = true;
		$("#"+this.pageId+"searchBy").val('stmtNoWise');
		this.showDateWise = false;
		$("#"+this.pageId+"fromDate").val('');
		$("#"+this.pageId+"toDate").val('');
		$("#"+this.pageId+"debitNoteRptIdTable").DataTable().destroy();
		this.debitNoteRptDataList = [];
		this.dtTriggerDebitNoteRpt.next();
		$("#"+this.pageId+"statementNo").val('');
		this.viewDestination = false;
		this.modelDestination = '';
		this.fromDateModel = null;
		this.toDateModel = null;
		this.searchByAuthorityFortNightDto = new FortNightStmtDto();
		this.fortNightStmtDebitHeadDto = new FortNightStmtDto();
		this.searchByFortNightDto = new FortNightStmtDto();
		this.enteredStatementNumber = '';
		$("#"+this.pageId+"statementNo").val('');
		this.selectedDebitHead = '';
		this.modelDebitHead = 'All';
		this.modelDebitHeadOthers = '';
		$("#"+this.pageId+"debitHead").val('All');
		$("#"+this.pageId+"subHead").val('');
		this.selectedSubHead =
			this.selectedFromDate = '';
		this.selectedToDate = '';
		this.selectedDestination = '';
		this.viewOtherDebit = false;
		this.setTheDestOnTable = '';
		$("#"+this.pageId+"destination").val(this.office);
		this.modelDebitHeadForGet = '';
		this.showSpinnerForAction = false;


	}
	customPrintdebitNote() {
		if (this.debitNoteRptDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Received Date", "Description", "Amount"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [35, 35, 30];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//heading logics For Date
			if ($("#"+this.pageId+"searchBy").val() == 'stmtNoWise') {
				this.selectedFromDate = "NA";
				this.selectedToDate = "NA";
				this.selectedDebitHead = "NA";
				this.selectedDestination = "NA";
				this.cashMemoDtoForCustomPrintListHeadingV1 = [];
				this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Statement No"];
				this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#"+this.pageId+"statementNo").val()];
			}else{
				this.selectedFromDate=this.datePipe.transform($("#"+this.pageId+"fromDate").val(), "dd-MM-yyyy");
			this.selectedToDate=this.datePipe.transform($("#"+this.pageId+"toDate").val(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
			///Heaading Logics For Party Name
			this.selectedDebitHead = $("#"+this.pageId+"debitHead").val();
			this.selectedDestination = $("#"+this.pageId+"destination").val();
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination","Debit Head"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedDestination,this.selectedDebitHead];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			this.amountSummary = 0;
			for (let i = 0; i < this.debitNoteRptDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.debitNoteRptDataList[i].receivedDateStr, this.debitNoteRptDataList[i].description,
				this.debitNoteRptDataList[i].debit];
				this.amountSummary = this.amountSummary + this.debitNoteRptDataList[i].debit;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.debitNoteRptDataList.length, "", this.amountSummary];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Debit Note Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}

}

