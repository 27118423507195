import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { BrokerReportComponent } from 'src/app/report/hamali-report/broker-report/broker-report.component';
import { LabourStatementReportComponent } from 'src/app/report/hamali-report/labour-statement-report/labour-statement-report.component';
import { MaterialModule } from "src/app/material.module";
import { LabourStatementLRDetailsComponent } from 'src/app/report/hamali-report/labour-statement-lr-details/labour-statement-lr-details.component';
import { LabourStatementLoadingUnloadingComponent } from 'src/app/report/hamali-report/labour-statement-loading-unloading/labour-statement-loading-unloading.component';
import { LabourStatementPaymentDetailsComponent } from 'src/app/report/hamali-report/labour-statement-payment-details/labour-statement-payment-details.component';
import { PaymentVoucherLoadingUnloadingPrintComponent } from 'src/app/report/hamali-report/payment-voucher-loading-unloading-print/payment-voucher-loading-unloading-print.component';
import { LrReportModule } from 'src/app/report/lr-report/lr-report.module';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { LoadingUnloadingHamaliDetailsPrintComponent } from 'src/app/report/hamali-report/loading-unloading-hamali-details-print/loading-unloading-hamali-details-print.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( HamaliRoutes ), 
        FormsModule, NgbModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatDialogModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, DataTablesModule, MaterialModule, LrReportModule, CashmemoReportModule],
    declarations: [
        BrokerReportComponent, LabourStatementReportComponent, LabourStatementLRDetailsComponent, LabourStatementLoadingUnloadingComponent, LabourStatementPaymentDetailsComponent,
        PaymentVoucherLoadingUnloadingPrintComponent, LoadingUnloadingHamaliDetailsPrintComponent
    ], entryComponents: [LabourStatementLRDetailsComponent, LabourStatementLoadingUnloadingComponent,
        LabourStatementPaymentDetailsComponent, LoadingUnloadingHamaliDetailsPrintComponent],
    exports: [LabourStatementLoadingUnloadingComponent, LabourStatementLRDetailsComponent, LoadingUnloadingHamaliDetailsPrintComponent]
})
export class HamaliReportModule { }