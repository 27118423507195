import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

import { GenerationRoutes } from 'src/app/cashmemo/generation/generation.routing';


import { BookingCashmemoComponent } from 'src/app/cashmemo/generation/booking-cashmemo/booking-cashmemo.component';
import { GenerateCashmemoComponent } from 'src/app/cashmemo/generation/generate-cashmemo/generate-cashmemo.component';
import { DummyCashmemoComponent } from 'src/app/cashmemo/generation/dummy-cashmemo/dummy-cashmemo.component';
import { CashmemoGeneratePrintComponent } from "src/app/cashmemo/generation/cashmemo-genrate-print/cashmemo-genrate-print.component";
import { CashmemoBkgPrintAutoGSTComponent } from 'src/app/lr/entry/cashmemo-bkg-print-autogst/cashmemo-bkg-print-autogst.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { MaterialModule } from "src/app/material.module";
import { NgxBarcode6Module } from 'ngx-barcode6';
import { CashmemoGeneratePrintQRComponent } from './cashmemo-genrate-print-qr/cashmemo-genrate-print-qr.component';


@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(GenerationRoutes), 
        FormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, NgbModule, DataTablesModule, NgxBarcodeModule, MaterialModule, NgxBarcode6Module],
    declarations: [
        BookingCashmemoComponent,
        GenerateCashmemoComponent,
        DummyCashmemoComponent,
        CashmemoGeneratePrintComponent,
        CashmemoBkgPrintAutoGSTComponent,
        CashmemoGeneratePrintQRComponent
    ],
    exports: [
        BookingCashmemoComponent,
        CashmemoBkgPrintAutoGSTComponent
    ],
})
export class GenerationModule { }
