<!-- Row -->
<div class="row">
	<div class="col-lg-12">
		<div class="card">
			<div class="row">
				<!-- spinner start-->
				<div *ngIf="showSpinnerForAction" class="col-md-12">
					<div class="form-group">
						<div class="input-group">
							<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
							<br>
							<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
								Please Wait Loading Details.....</h6>
						</div>
					</div>
				</div>
				<!-- spinner end-->
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="card-body">
						<div class="box-body">
							<h6 style="border-bottom: 1px solid orange;" class="card-title">Stocks
								Summary</h6>
							<table datatable id="{{pageId}}tripSheetForStockSummaryTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsStockSummary" [dtTrigger]="dtTriggerStockSummary">
								<thead>
									<tr>
										<th>Tot LRs</th>
										<th>Destination</th>
										<th>Art</th>
										<th>Act Wgt</th>
										<th>Chg Wgt</th>
										<th>Amount</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let stockSummaryData of stockSummaryDataList ">
										<td>{{ stockSummaryData.totalLrs }}</td>
										<td>{{ stockSummaryData.endDest }}</td>
										<td>{{ stockSummaryData.totalArticles }}</td>
										<td>{{ stockSummaryData.actualWeight }}</td>
										<td>{{ stockSummaryData.chargedWeight }}</td>
										<td>{{ stockSummaryData.totalAmount }}</td>
										<td><button type="button" style="padding: 1px 4px; background-color: lightgrey;"
												class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn"
												(click)="rowSelectedGetTripLRDeatils(stockSummaryData);">
												<i title="Get Total LR Details" class="fas fa-eye"></i>
											</button></td>
									</tr>
								</tbody>
								<tbody>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card-body">
						<div class="box-body">
							<h6 id="{{pageId}}viewBinsStockMsgId" style="border-bottom: 1px solid orange;" class="card-title">
								{{viewBinsStockMsg}}</h6>
							<table datatable id="{{pageId}}tripSheetForStockDetailsTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsStockDetails" [dtTrigger]="dtTriggerStockDetails">
								<thead>
									<tr>
										<th>LR No</th>
										<th>Consignee</th>
										<th>Destination</th>
										<th>Articles</th>
										<th>Act Wgt</th>
										<th>Chg Wgt</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let stockDetailsData of stockDetailsDataList ">

										<td>{{stockDetailsData.lrNumber }}</td>
										<td>{{ stockDetailsData.consigneeName }}</td>
										<td>{{ stockDetailsData.destination }}</td>
										<td>{{ stockDetailsData.totalArticles }}</td>
										<td>{{ stockDetailsData.actualWeight }}</td>
										<td>{{ stockDetailsData.chargedWeight }}</td>
										<td>{{ stockDetailsData.totalAmount }}</td>

									</tr>
								</tbody>
								<!---->
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<div class="col-md-12" style="text-align: right;">
					<button class="btn btn-info m-r-10" id="{{pageId}}resetInStocksForTripsheetBtn" mat-button
						matStepperPrevious>Reset</button>
					<button class="btn btn-success m-r-10" id="{{pageId}}makeATripBtnId" mat-button matStepperNext
						*ngIf="makeAtripBtnHideShow">Make a Trip
						sheet</button>
					<button class="btn btn-success m-r-10" id="{{pageId}}tripSheetBtnId" *ngIf="tripSheetBtnHideShow" mat-button
						matStepperNext (click)="tripSheetBtnClickListner();">
						Trip Sheet
					</button>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>