<html>
<!-- Row -->
<div class="row"  *ngIf="isLoggedIn" id="{{pageId}}showLrShortAndExtraRpt">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Short And Extra Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<!--<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>View Type</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-question"></i>
														</span>
													</div>
													<select class="custom-select col-12" id="{{pageId}}viewType" name="viewType"
														#viewType (change)="viewTypeMode(viewType.value)">
														<option selected value="dateWise">Date Wise</option>
														<option value="allWise">All</option>

													</select>
												</div>
											</div>
										</div>-->
										<!--<div *ngIf="viewTypeDateWise" class="col-sm-12 col-md-12">-->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>From Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates"
														ngbDatepicker #fromDates="ngbDatepicker"
														[(ngModel)]="fromDatesModal" autocomplete="off">
													<div class="input-group-append" (click)="fromDates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<!--<div *ngIf="viewTypeDateWise" class="col-sm-12 col-md-12">-->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
														placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
														#toDates="ngbDatepicker" [(ngModel)]="toDatesModal"
														autocomplete="off">
													<div class="input-group-append" (click)="toDates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<!--<div *ngIf="viewTypeAllWise" class="col-sm-12 col-md-12">-->
										<div [hidden]="!hideShowSourceSubStation" class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #sourceSubStationName id="{{pageId}}sourceSubStationNameId"
															name="sourceSubStationNameId" type="text"
															class="form-control" placeholder="Select Source Name"
															(selectItem)="sourceSubStationDropDownListner($event)"
															[(ngModel)]="modelSourceSubStationName"
															[ngbTypeahead]="sourceSubStationNameSearchTA"
															[resultFormatter]="formatterSourceSubStationName"
															[inputFormatter]="formatterSourceSubStationName"
															(focus)="focusSourceSubStationNameTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #destination id="{{pageId}}destinationId" type="text"
															class="form-control"
															(selectItem)="clickListnerForDestination($event,input)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value);focus($event.target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn();"
							id="{{pageId}}searchBtn">Search</button>
						<button type="submit" (click)="clearBtn();" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
					</div>
				</div>


				<div class="col-md-9 vl p-t-10">
					<!-- spinner start-->
					<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<!-- spinner end-->
					<table width="100%">
						<tr>
							<td width="80%">
								<h6 class="card-title border_bottom">LRs Short And Extra
									Report</h6>
							</td>
							<td width="20%">
								<button type="submit" class="dt-button" style="margin-left: 55%;"
									(click)="customPrintShortAndExtra()" id="{{pageId}}printAllBtn">
									<span><i class="fas fa-print">Print</i></span>
								</button>
							</td>
						</tr>
					</table>
					<div class="card-body">
						<div class="box-body">

							<table datatable id="{{pageId}}lrShortAndExtraRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsShortAndExtraReport" [dtTrigger]="dtTriggerShortAndExtraReport">

								<thead>
									<tr>
										<th>Destination</th>
										<th>LR No</th>
										<th>Actual Article</th>
										<th>Enter Art</th>
										<th>Status</th>
										<th>Invoice No.</th>
										<th>Vehicle No</th>
										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let shortAndExtraReportData of shortAndExtraReportReportDataList ">
										<td>{{ shortAndExtraReportData.destination }}</td>
										<td>{{ shortAndExtraReportData.lrNumber }}</td>
										<td>{{ shortAndExtraReportData.totalArticles }}</td>
										<td>{{ shortAndExtraReportData.lrsShortage }}</td>
										<td>{{ shortAndExtraReportData.status }}</td>
										<td>{{ shortAndExtraReportData.invoiceNumber }}</td>
										<td>{{ shortAndExtraReportData.vehicleNumber }}</td>
										<td>{{ shortAndExtraReportData.remarks }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>

								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
	