import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component( {
    selector: 'app-consignee-rate-report',
    templateUrl: './consignee-rate-report.component.html',
    styleUrls: ['./consignee-rate-report.component.css']
} )
export class ConsigneeRateReportComponent implements OnInit {

    gettingDataFrmServiceFrConsigneeRateReportTable: any;

    consigneeRateReportDataList: any;

    onDestroyUnsubscribtionConsigneeRateReport: Subscription;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerConsigneeRateReport: Subject<any> = new Subject();
    modelCollectionMan=""
    dataTable: any;
    dtOptionsConsigneeRateReport: any;
    userDtoCollectionManAll: UserDataDto = new UserDataDto();
    userDtoCollectionMan: UserDataDto = new UserDataDto();
    rateMasterDtoForSearch: RateMasterDto = new RateMasterDto();
    selectedSource:any;
    selectedCollectionMan:any;
    selectedCommodity:any;
    selectedType:any;
    
    isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	
  //for collection Man
  	collectionManAllOption: UserDataDto = new UserDataDto();
  	collectionManTA: Array<UserDataDto> = [];
  	collectionManTATemp: Array<UserDataDto> = [];
  	collectionManTATempForDest: Array<UserDataDto> = [];

  	focusCollectionManTA$ = new Subject<string>();
  	collectionManSearchTA = (text$: Observable<string>) => {
  		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  		const inputFocus$ = this.focusCollectionManTA$;

  		return merge(debouncedText$, inputFocus$).pipe(
  			map(term => (term === '' ? this.collectionManTA
  				: this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
  		);
  	}
  	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
  	collectionManDataList: any;
  	
  	agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    agentDtoForAgentNameAll: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    pageId="cnrrc";


    constructor(private consigneeService: ConsigneeService,private masterReadService: MasterReadService, private router: Router , public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
          	  for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "AgtExpensesRpt ShowAll" ) {
                      	break;
                    }
                }
            }
            setTimeout(() => {
          	  this.getCollectionManDetailsList();
          	  this.getAgentDetailList();
            }, 500);
        }
    }
    
    ngOnInit(): void {
        this.dtOptionsConsigneeRateReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,            
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerConsigneeRateReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerConsigneeRateReport.next();
    }

	getCollectionManDetailsList() {
		this.collectionManDataList = [];
		this.collectionManTA = [];
		this.getDetailsForCollectionMasterRead();
		this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Collection Man Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.collectionManDataList = [];
					this.collectionManTA = [];
					this.userDtoCollectionManAll.collectioMan = "All";
					this.collectionManTA.push(this.userDtoCollectionManAll);
					$("#"+this.pageId+"collectionMan").val('All');
				} else {
					this.collectionManDataList = response;
					this.collectionManTA = [];
					this.userDtoCollectionManAll.collectioMan = "All";
					this.collectionManTA.push(this.userDtoCollectionManAll);
					for (let i = 0; i < this.collectionManDataList.length; i++) {
						this.collectionManTA.push(this.collectionManDataList[i]);
					}
					$("#"+this.pageId+"collectionMan").val('All');
				}
                this.changeDetectorRef.detectChanges();

			}), (error) => {
				swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
			}, () => console.log('done');
	}
	
	getDetailsForCollectionMasterRead() {
		this.userDtoCollectionMan = new UserDataDto();
		this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}
	
    getUserValuesForAgentDetailsList() {
        this.agentDtoForAgentName = new AgentDetailsDto();
        this.agentDtoForAgentName.mode = 'Sub';
        this.agentDtoForAgentName.status = 'All';
        this.agentDtoForAgentName.companyId = this.userDataDtoReturnSession.companyId;
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDtoForAgentName).subscribe(
            (response) => {
                console.log(response);
                this.agentNameTA = [];
                this.agentNameOptions = [];
                this.agentDtoForAgentNameAll = new AgentDetailsDto();
                this.agentDtoForAgentNameAll.subStation = "All"
                this.agentNameTA.push(this.agentDtoForAgentNameAll);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    }

    clickListnerForAgentName($event){

    }
    
    getUserValue() {
        this.rateMasterDtoForSearch = new RateMasterDto();
        this.selectedSource = $("#"+this.pageId+"agentName").val();
        this.selectedCollectionMan = $("#"+this.pageId+"collectionMan").val();
        this.selectedCommodity = "All";
        this.selectedType = $("#"+this.pageId+"lessType").val();
        
        this.rateMasterDtoForSearch.source = this.selectedSource;
        this.rateMasterDtoForSearch.collectionMan = this.selectedCollectionMan;
        this.rateMasterDtoForSearch.mode = null;
        this.rateMasterDtoForSearch.destination = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoForSearch.commidtyForRpt = this.selectedCommodity;
        this.rateMasterDtoForSearch.ratePerUnit = this.selectedType;
        this.rateMasterDtoForSearch.DD = true;
    }

    getRateDetails() {
    	this.getUserValue();
        this.showSpinnerForAction = true;
        this.consigneeService.getConsigneeRateReportDetails(this.rateMasterDtoForSearch).subscribe(
            (response) => {
                this.consigneeRateReportDataList = [];
                $("#"+this.pageId+"consigneeRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.consigneeRateReportDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerConsigneeRateReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }
    
    clearFields(){
    	this.rateMasterDtoForSearch = new RateMasterDto();
        this.selectedSource = null;
        this.selectedCollectionMan = null;
        this.selectedCommodity = null;
        this.selectedType = null;
        
        this.consigneeRateReportDataList = [];
        $("#"+this.pageId+"consigneeRptTableId").DataTable().destroy();
        this.dtTriggerConsigneeRateReport.next();
    }
	
	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}