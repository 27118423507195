<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli" style="margin-left: 20px;margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;" (click)="gridReconifgureOnReloadBtn()" area-hidden="true"></i> -->
<div class="col-md-12 col-sm-12" id="{{pageId}}lrsShortAndExtId" *ngIf="isLoggedIn">
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                </div>
            </div>
        </div>
            <div class="col-md-8" style="padding: 5px 10px;padding-bottom: 0px;">
                <h6 class="card-title">
                    <i class=" fas fa-file-alt"></i>&nbsp;LRs Short & Extra
                </h6>
        
            </div>
            <div class="col-md-4" style="padding: 5px 10px;padding-bottom: 0px;float: right;">
                <i title="Print" class="fas fa-print" style="margin-bottom: 10px;padding: 2px 5px;cursor: pointer;" (click)="customPrintLrsShortAndExtraRpt()" ></i>
                &nbsp;<i title="Reload" class="fas fa-sync-alt fa xs cli"
                style="margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
                (click)="gridReconifgureOnReloadBtn()"></i>
            </div>

        <!-- <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintLrsShortAndExtraRpt()" id="{{pageId}}printAllBtn">
			<span><i class="fas fa-print">Print</i></span>
		</button> -->
    </div>
    <div class="box-body">
        <table datatable id="{{pageId}}lrsShortAndExtraId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrsShortAndExtra" [dtTrigger]="dtTriggerLrsShortAndExtra">

            <thead>
                <tr>
                    <th>LR No.</th>
                    <th>Art</th>
                    <th>Status</th>
                    <th>Belongs To</th>
                    <th>Invoice Number</th>
                    <th>Vehicle Number</th>
                    <th>Remarks</th>
                    <th>Action</th>
                    <th style='display:none;'>Source</th>
                    <th style='display:none;'>Actual Art</th>
                    <th style='display:none;'>Enter Art</th>


                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lrsShortAndExtraData of lrsShortAndExtraDataList ">
                    <td>{{ lrsShortAndExtraData.lrNumber }}</td>
                    <td>{{ lrsShortAndExtraData.lrsShortage }}</td>
                    <td>{{ lrsShortAndExtraData.status }}</td>
                    <td>{{ lrsShortAndExtraData.destination }}</td>
                    <td>{{ lrsShortAndExtraData.invoiceNumber }}</td>
                    <td>{{ lrsShortAndExtraData.vehicleNumber }}</td>
                    <td>{{ lrsShortAndExtraData.remarks }}</td>
                    <!-- 					<td><button type="submit" class="btn" id="{{pageId}}deleteBtn">Delete</button></td> -->
                    <td><button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="deleteRow(lrsShortAndExtraData);">
							<i title="Delete" class="fas fa-trash"></i>
						</button></td>
                    <td style='display:none;'>{{ lrsShortAndExtraData.source }}</td>
                    <td style='display:none;'>{{ lrsShortAndExtraData.totalArticles }}</td>
                    <td style='display:none;'>{{ lrsShortAndExtraData.lrsShortage }}</td>

                </tr>
            </tbody>
            <!--<tfoot>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td style='display:none;'></td>
					<td style='display:none;'></td>
					<td style='display:none;'></td>
				</tr>
			</tfoot>-->
        </table>
    </div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>