import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();

////
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';



import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';



@Component({
	selector: 'app-lr-dispatch-booking-report',
	templateUrl: './lr-dispatch-booking-report.component.html',
	styleUrls: ['./lr-dispatch-booking-report.component.css']
})
export class LrDispatchBookingReportComponent implements OnInit {

	gettingDataFrmServiceFrSmryTable: any;
	gettingDataFrmServiceFrDetailTable: any;
	gettingDataFrmServiceFrDestTable: any;
	summaryDataList: any;
	detailDataList: any;
	destDataList: any;


	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;
	viewTypeSummary = true;
	ViewDate = true;
	viewTypeDetail = false;
	viewTypeDestination = false;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends

	//@ViewChildren(DataTableDirective)
	//dtElements: QueryList<DataTableDirective>;

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	//@ViewChild(DialogComponent) dialogComponent: DialogComponent;


	viewModel: any;
	//supList: SupplierModel[] = [];
	//

	dtTriggerSummary: Subject<any> = new Subject();
	dtTriggerDetail: Subject<any> = new Subject();
	dtTriggerDest: Subject<any> = new Subject();
	//


	// @ViewChild('dataTable') table: ElementRef;
	dataTable: any;
	dtOptionsSummary: any;
	dtOptionDetail: any;
	//firstDtOptions: DataTables.Settings = {};
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	dtOptionsDestination: any;
	//secondDtOptions: DataTables.Settings = {};
	showSpinnerForAction: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	address: any;
	fromDateModel: any;
	toDateModel: any;
	destDateModel: any;
	///Destination
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//source
	//For Source
	public modelSource: any;
	sourceTA: Array<LRDto> = [];
	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourceTA
				: this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

		);
	}
	formatterSource = (x: { subStations: string }) => x.subStations;
	lrDtoSource: LRDto = new LRDto();
	lrDtoSourceOptions: LRDto[];
	summaryTable = true;
	detailTable = false;
	destinationTable = false;
	cbSourceViewSupAdmin = false;
	cbSourceViewBkgAdmin = false;
	showSource = false;
	showSourceListForBkgDis = 'Show Source List For Bkg Dispatch';
	selectedView: any;
	lrDtoDestinationAllOpt: LRDto = new LRDto();
	searchLrDispatchRptDto: LRDto = new LRDto();
	selectedDestination: any;
	selectedSource: any;
	selectedFromDate: any;
	selectedToDate: any;
	selectedDestDate: any;
	//For Custom Print

	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	totalLrsSummary: number;
	articlesSummary: number;
	actWtSummary: number;
	chdWtSummary: number;
	viewCustomPrintV1: boolean;
	////
	pageId="lrdbc";
	constructor(private memoLessRpt: ReportService, private router: Router,
		private memoReport: MemoReport, private masterReadService: MasterReadService, 
		private modalService: NgbModal, private lrReportService: LrReportService, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getDestinationDetails();

			///Rights for show Show Source List
			//            if ( this.showSourceListForBkgDis == "Show Source List For Bkg Dispatch" ) {
			//                this.showSource = true;
			//this.getSourceDetails();

			//} 


			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Source BkgLrDispSupAdmin") {
						this.showSource = true;
						this.cbSourceViewSupAdmin = true;
						this.cbSourceViewBkgAdmin = false;
						this.getSourceDetails();
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Source BkgLrDispBkgAdmin") {
						this.showSource = true;
						this.cbSourceViewBkgAdmin = true;
						this.cbSourceViewSupAdmin = false;
						this.getSourceDetails();
					}

				}
			}

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var subSource = null
		if (this.cbSourceViewSupAdmin == true || this.cbSourceViewBkgAdmin == true) {
			subSource = null;
		} else {
			subSource = this.userDataDtoReturnSession.office;
		}

		this.dtOptionsSummary = {
			dom: 'Bfrtip',
			buttons: [
				
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = null;
						if (subSource != null) {
							returnSummaryExcel = "Booking LR Dispatched Report(Summary)" +
								"From Date : " + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + " From : " + subSource + " To : " + $("#lrdbcdestination").val();
						} else {
							returnSummaryExcel = "Booking LR Dispatched Report(Summary)" +
								"From Date : " + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + "  From : " + $("#lrdbcsource").val() + " To : " + $("#lrdbcdestination").val();
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalLrs = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var articles = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actualWgt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(totalLrs);
				$(api.column(3).footer()).html(articles);
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html(actualWgt);
				$(api.column(6).footer()).html(chargedWgt);

			}
		},

			this.dtOptionDetail = {
				dom: 'Bfrtip',

				buttons: [
					{
						extend: 'print',
						text: '<i class="fas fa-print"> Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '12px').css('text-align', 'center');
						},
						footer: true,
						messageTop: function () {
							var returnDetailsPrint = null;
							if (subSource != null) {
								returnDetailsPrint = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Booking LR Dispatched Report(Details)</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + subSource + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcdestination").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							} else {

								returnDetailsPrint = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Booking LR Dispatched Report(Details)</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcsource").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcdestination").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							}
							return returnDetailsPrint;

						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {

							var returnDetailsExcel = null;
							if (subSource != null) {
								returnDetailsExcel = "Booking LR Dispatched Report(Details)" +
									"From Date : " + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + " From : " + subSource + " To : " + $("#lrdbcdestination").val();
							} else {
								returnDetailsExcel = "Booking LR Dispatched Report(Details)" +
									"From Date : " + moment($("#lrdbcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#lrdbctoDate").val()).format('DD-MM-YYYY') + "  From : " + $("#lrdbcsource").val() + " To : " + $("#lrdbcdestination").val();
							}
							return returnDetailsExcel;
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],

				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": true,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var articles = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(articles);
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html(actualWgt);
					$(api.column(10).footer()).html(chargedWgt);
				}

			},
			this.dtOptionsDestination = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'print',
						text: '<i class="fas fa-print"> Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '12px').css('text-align', 'center');
						},
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 5, 6, 7]
						},
						messageTop: function () {
							var returnDestWisePrint = null;
							if (subSource != null) {

								returnDestWisePrint = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbcdestDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'></span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Booking LR Dispatched Report(Destination Wise)</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + subSource + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcdestination").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							} else {

								returnDestWisePrint = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#lrdbcdestDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'></span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:18px;'><u>Booking LR Dispatched Report(Destination Wise)</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcsource").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'>" + $("#lrdbcdestination").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							}
							return returnDestWisePrint;

						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 5, 6, 7]
						},
						title: function () {

							var returnDestWiseExcel = null;
							if (subSource != null) {
								returnDestWiseExcel = "Booking LR Dispatched Report(Destination Wise)" +
									" Date : " + moment($("#lrdbcdestDate").val()).format('DD-MM-YYYY') + " From : " + subSource + " To : " + $("#lrdbcdestination").val();
							} else {
								returnDestWiseExcel = "Booking LR Dispatched Report(Destination Wise)" +
									" Date : " + moment($("#lrdbcdestDate").val()).format('DD-MM-YYYY') + "  From : " + $("#lrdbcsource").val() + " To : " + $("#lrdbcdestination").val();
							}
							return returnDestWiseExcel;
						}

					}
				],

				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": true,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var totalLrs = api.column(1).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var articles = api.column(2).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var actualWgt = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chargedWgt = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html(totalLrs);
					$(api.column(2).footer()).html(articles);
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(actualWgt);
					$(api.column(6).footer()).html(chargedWgt);
					$(api.column(7).footer()).html();
				}

			}

	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				// Do your stuff
				//console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
				if (dtInstance.table().node().id == "summaryTableId") {
					console.log("a");
					this.summaryDataList = [];
					dtInstance.destroy();
					this.dtTriggerSummary.next();
				} else if (dtInstance.table().node().id == "detailsTableId") {
					console.log("b");
					this.detailDataList = [];
					dtInstance.destroy();
					this.dtTriggerDetail.next();
				} else if (dtInstance.table().node().id == "destinationTableId") {
					console.log("c");
					this.destDataList = [];
					dtInstance.destroy();
					this.dtTriggerDest.next();
				}
			});
		});
	}


	ngOnDestroy(): void {
		this.dtTriggerSummary.unsubscribe();
		this.dtTriggerDetail.unsubscribe();
		this.dtTriggerDest.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerSummary.next();
		this.dtTriggerDetail.next();
		this.dtTriggerDest.next();

	}
	viewTypeMode(viewType: string) {
		if (viewType === 'summary') {
			this.viewTypeSummary = true;
			this.ViewDate = true;
			this.viewTypeDetail = false;
			this.viewTypeDestination = false;
			this.summaryTable = true;
			this.detailTable = false;
			this.destinationTable = false;
			this.destinationTA = [];
			for (let i = 0; i < this.destinationOptions.length; i++) {
				this.destinationTA.push(this.destinationOptions[i]);
			}
			$("#"+this.pageId+"summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#"+this.pageId+"detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#"+this.pageId+"destinationTableId").DataTable().destroy();
			this.destDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerDest.next();

		} else if (viewType === 'detail') {
			this.viewTypeSummary = false;
			this.ViewDate = true;
			this.viewTypeDetail = true;
			this.viewTypeDestination = false;
			this.summaryTable = false;
			this.detailTable = true;
			this.destinationTable = false;
			this.destinationTA = [];
			for (let i = 0; i < this.destinationOptions.length; i++) {
				this.destinationTA.push(this.destinationOptions[i]);
			}
			$("#"+this.pageId+"summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#"+this.pageId+"detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#"+this.pageId+"destinationTableId").DataTable().destroy();
			this.destDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerDest.next();
		} else if (viewType === 'destination') {
			this.viewTypeSummary = false;
			this.ViewDate = false;
			this.viewTypeDetail = false;
			this.viewTypeDestination = true;
			this.summaryTable = false;
			this.detailTable = false;
			this.destinationTable = true;
			this.destinationTA = [];
			this.lrDtoDestinationAllOpt.destination = 'All';
			this.destinationTA.push(this.lrDtoDestinationAllOpt);
			for (let i = 0; i < this.destinationOptions.length; i++) {
				this.destinationTA.push(this.destinationOptions[i]);
			}
			$("#"+this.pageId+"summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#"+this.pageId+"detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#"+this.pageId+"destinationTableId").DataTable().destroy();
			this.destDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerDest.next();
		} else {
			this.viewTypeSummary = false;
			this.ViewDate = false;
			this.viewTypeDetail = false;
			this.viewTypeDestination = false;
			this.summaryTable = true;
			this.detailTable = false;
			this.destinationTable = false;
			this.destinationTA = [];
			for (let i = 0; i < this.destinationOptions.length; i++) {
				this.destinationTA.push(this.destinationOptions[i]);
			}
			$("#"+this.pageId+"summaryTableId").DataTable().destroy();
			this.summaryDataList = [];
			$("#"+this.pageId+"detailsTableId").DataTable().destroy();
			this.detailDataList = [];
			$("#"+this.pageId+"destinationTableId").DataTable().destroy();
			this.destDataList = [];
			this.dtTriggerSummary.next();
			this.dtTriggerDetail.next();
			this.dtTriggerDest.next();

		}
	}
	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}
	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getSourceList() {
		if (this.cbSourceViewSupAdmin == true) {
			this.lrDtoSource.mode = 'Booking';
		} else if (this.cbSourceViewBkgAdmin == true) {
			this.lrDtoSource.city = this.userDataDtoReturnSession.mainStation;
		}
		this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoSource);
	}

	getSourceDetails() {
		this.getSourceList();
		this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
			(response) => {
				this.lrDtoSourceOptions = response;
				console.log(this.lrDtoSourceOptions);
				if (response) {
					if (response.length > 0) {
						this.lrDtoSourceOptions = response;
						this.sourceTA = [];
						for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
							this.sourceTA.push(this.lrDtoSourceOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source Details", "info");
			},
			() => console.log('done');

	};



	searchMethod() {

		this.searchLrDispatchRptDto = new LRDto();
		this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
		this.selectedToDate = $("#"+this.pageId+"toDate").val();
		this.selectedDestDate = $("#"+this.pageId+"destDate").val();
		this.selectedDestination = $("#"+this.pageId+"destination").val();
		this.selectedSource = $("#"+this.pageId+"source").val();
		this.selectedView = $("#"+this.pageId+"viewType").val();
		console.log(this.selectedFromDate, this.selectedToDate, this.selectedDestDate);
		this.searchLrDispatchRptDto.companyId = this.userDataDtoReturnSession.companyId;
		if (this.selectedDestination == null || this.selectedDestination == '') {
			swal({
				title: "Mandatory Field",
				text: "Please select Destination",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		}

		if (this.cbSourceViewSupAdmin == true || this.cbSourceViewBkgAdmin == true) {
			if (this.selectedSource == null || this.selectedSource == '') {
				swal({
					title: "Mandatory Field",
					text: "Please Select Source",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false
			} else {
				// selected destination as source
				this.searchLrDispatchRptDto.source = this.selectedSource;
			}
		} else {
			// office as source
			this.searchLrDispatchRptDto.source = this.userDataDtoReturnSession.office;
		}
		if (this.selectedView == 'summary' || this.selectedView == 'detail') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date  Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchLrDispatchRptDto.fromDate = this.selectedFromDate;
				this.searchLrDispatchRptDto.toDate = this.selectedToDate;

			}

			this.searchLrDispatchRptDto.destination = this.selectedDestination;
		}
		if (this.selectedView == 'destination') {
			if (this.selectedDestDate == null || this.selectedDestDate == '') {
				swal({
					title: "Mandatory Field",
					text: "Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.searchLrDispatchRptDto.fromDate = this.selectedDestDate;

			}
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != 'All') {
				this.searchLrDispatchRptDto.reportMode = 'selected';
				this.searchLrDispatchRptDto.destination = this.selectedDestination;
			} else {
				this.searchLrDispatchRptDto.reportMode = 'ALL';
			}

		}

		if (this.selectedView == 'summary') {
			this.searchLrDispatchRptDto.mode = 'summary';
		} else if (this.selectedView == 'detail') {
			this.searchLrDispatchRptDto.mode = 'details';
		} else if (this.selectedView == 'destination') {
			this.searchLrDispatchRptDto.mode = 'destWise';
		}
		this.searchMethodGetDetails();

	}

	searchMethodGetDetails = () => {
		this.showSpinnerForAction = true;
		console.log(this.searchLrDispatchRptDto);
		this.lrReportService.getBkgLrDisPatchRptService(this.searchLrDispatchRptDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#"+this.pageId+"summaryTableId").DataTable().destroy();
				this.summaryDataList = [];
				$("#"+this.pageId+"detailsTableId").DataTable().destroy();
				this.detailDataList = [];
				$("#"+this.pageId+"destinationTableId").DataTable().destroy();
				this.destDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.selectedView = $("#"+this.pageId+"viewType").val();
					if (this.selectedView == 'summary') {
						this.summaryDataList = response;
					} else if (this.selectedView == 'detail') {
						this.detailDataList = response;
					} else if (this.selectedView == 'destination') {
						this.destDataList = response;
					}
				}
				this.dtTriggerSummary.next();
				this.dtTriggerDetail.next();
				this.dtTriggerDest.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		$("#"+this.pageId+"destination").val('');
		$("#"+this.pageId+"source").val('');
		this.searchLrDispatchRptDto = new LRDto();
		this.viewTypeSummary = true;
		this.ViewDate = true;
		this.viewTypeDetail = false;
		this.viewTypeDestination = false;
		this.summaryTable = true;
		this.detailTable = false;
		this.destinationTable = false;
		this.destinationTA = [];
		for (let i = 0; i < this.destinationOptions.length; i++) {
			this.destinationTA.push(this.destinationOptions[i]);
		}
		$("#"+this.pageId+"summaryTableId").DataTable().destroy();
		this.summaryDataList = [];
		$("#"+this.pageId+"detailsTableId").DataTable().destroy();
		this.detailDataList = [];
		$("#"+this.pageId+"destinationTableId").DataTable().destroy();
		this.destDataList = [];
		this.dtTriggerSummary.next();
		this.dtTriggerDetail.next();
		this.dtTriggerDest.next();

		$("#"+this.pageId+"destDate").val('');
		$("#"+this.pageId+"fromDate").val('');
		$("#"+this.pageId+"toDate").val('');
		this.fromDateModel = null;
		this.toDateModel = null;
		this.destDateModel = null;
		this.selectedView = '';
		$("#"+this.pageId+"viewType").val('summary');
		this.selectedFromDate = '';
		this.selectedToDate = '';
		this.selectedDestDate = '';
		this.selectedDestination = '';
		this.selectedSource = '';
		this.showSource = false;
		if (this.cbSourceViewSupAdmin == true || this.cbSourceViewBkgAdmin == true) {
			this.showSource = true;
		}
		this.showSpinnerForAction = false;
	}

	customPrintLrDispBkgRptSummary() {
		if (this.summaryDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Total Lrs", "Articles", "Diff(Hire-Entry)", "Act Wt", "Chg Wt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [17, 17, 17, 16, 16, 17];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//Heading Logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLrDispatchRptDto.fromDate, this.searchLrDispatchRptDto.toDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			//Heading Logics For Staion
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.searchLrDispatchRptDto.source, this.searchLrDispatchRptDto.destination];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totalLrsSummary = this.articlesSummary = this.actWtSummary = this.chdWtSummary = 0;
			for (let i = 0; i < this.summaryDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].enteredDateStr, this.summaryDataList[i].totalLrs,
				this.summaryDataList[i].totalArticles, this.summaryDataList[i].dispatchedwithin, this.summaryDataList[i].actualWeight,
				this.summaryDataList[i].chargedWeight];
				/////////
				this.totalLrsSummary = +this.totalLrsSummary + this.summaryDataList[i].totalLrs;
				this.articlesSummary = +this.articlesSummary + this.summaryDataList[i].totalArticles;
				this.actWtSummary = +this.actWtSummary + this.summaryDataList[i].actualWeight;
				this.chdWtSummary = +this.chdWtSummary + this.summaryDataList[i].chargedWeight;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, this.totalLrsSummary, this.articlesSummary, "", this.actWtSummary, this.chdWtSummary];
			//heading logics For Date


			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];






			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Booking LR DisPatch Report(Summary)");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}

	customPrintLrDispBkgRptDetail() {
		if (this.detailDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Date", "Entry Date","Hireslip Date", "Lr No", "Articles","Source","Diff(Entry-Book)", "Diff(Hire-Entry)", "Diff(Hire-Book)", "Act Wt", "Chg Wt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 8, 10, 9, 7, 7,12,11,12,7,7];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//Heading Logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLrDispatchRptDto.fromDate, this.searchLrDispatchRptDto.toDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			//Heading Logics For Staion
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.searchLrDispatchRptDto.source, this.searchLrDispatchRptDto.destination];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totalLrsSummary = this.articlesSummary = this.actWtSummary = this.chdWtSummary = 0;
			for (let i = 0; i < this.detailDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].bookingDateStr, this.detailDataList[i].enteredDateStr,
				this.detailDataList[i].hireslipDateStr,this.detailDataList[i].lrNumber, this.detailDataList[i].totalArticles,this.detailDataList[i].agentName,this.detailDataList[i].deliveredwithin,
				this.detailDataList[i].dispatchedwithin, this.detailDataList[i].diffDays, this.detailDataList[i].actualWeight, this.detailDataList[i].chargedWeight];
				/////////
				this.totalLrsSummary = +this.totalLrsSummary + this.detailDataList[i].totalLrs;
				this.articlesSummary = +this.articlesSummary + this.detailDataList[i].totalArticles;
				this.actWtSummary = +this.actWtSummary + this.detailDataList[i].actualWeight;
				this.chdWtSummary = +this.chdWtSummary + this.detailDataList[i].chargedWeight;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "","","",this.articlesSummary,"","","","",this.actWtSummary, this.chdWtSummary];
			//heading logics For Date


			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];






			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Booking LR DisPatch Report(Detail)");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}
	customPrintLrDispBkgRptDest() {
		if (this.destDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Entry Date", "Total Lrs", "Tot Art", "Diff(Hire-Entry)", "Act Wt", "Chg Wt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [17, 17, 17, 16, 16, 17];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//Heading Logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLrDispatchRptDto.fromDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			//Heading Logics For Staion
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.searchLrDispatchRptDto.source, this.searchLrDispatchRptDto.destination];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totalLrsSummary = this.articlesSummary = this.actWtSummary = this.chdWtSummary = 0;
			for (let i = 0; i < this.destDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.destDataList[i].enteredDateStr, this.destDataList[i].totalLrs,
				this.destDataList[i].totalArticles, this.destDataList[i].dispatchedwithin, this.destDataList[i].actualWeight,
				this.destDataList[i].chargedWeight];
				/////////
				this.totalLrsSummary = +this.totalLrsSummary + this.destDataList[i].totalLrs;
				this.articlesSummary = +this.articlesSummary + this.destDataList[i].totalArticles;
				this.actWtSummary = +this.actWtSummary + this.destDataList[i].actualWeight;
				this.chdWtSummary = +this.chdWtSummary + this.destDataList[i].chargedWeight;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.destDataList.length, this.totalLrsSummary, this.articlesSummary, "", this.actWtSummary, this.chdWtSummary];
			//heading logics For Date


			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];






			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Booking LR DisPatch Report(Destination Wise)");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}
}
