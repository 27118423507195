import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/*import { DragulaService, dragula } from "ng2-dragula";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material';*/
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";

@Component({
	selector: 'app-hireslip-unloading-sheet-details-report',
	templateUrl: './hireslip-unloading-sheet-details-report.component.html',
	styleUrls: ['./hireslip-unloading-sheet-details-report.component.css']
})
export class HireslipUnloadingSheetDetailsReportComponent implements OnInit {

	unloadingSheetDetailsDataList: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerUnloadingSheetDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsUnloadingSheetDetails: any;
	hireslipNumberFromDto:any;
	hireslipDtoSearchDetailsFromPopup:HireSlipDto = new HireSlipDto();
	hireslipDtoSearchDetails:HireSlipDto = new HireSlipDto();

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;
	cashMemoDtoLocalStorage:CashMemoDto = new CashMemoDto();
	cashMemoDto:CashMemoDto = new CashMemoDto();
	memoLessDescDataList: any;
	dtTriggerMemoLessDescriptionDetails: Subject<any> = new Subject();
	dataForPopup:any;
	dataForPopupOtherDetails:any;
	invoiceDtoForPrintFromPopup:InvoiceDto = new InvoiceDto();
	truckNumber:any;
	supplierName:any;
	invoiceNumber:any;
	invoiceDate:any; 
	driverName:any;
	driverMobile:any; 
		date:any;
	pageId="husdr";

	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private modalService: NgbModal, private hireslipService: HireslipService,public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
				this.dataForPopup = JSON.parse( localStorage.getItem( 'popupUnloadingSheetDetails' ) );
				this.dataForPopupOtherDetails = JSON.parse( localStorage.getItem( 'popupUnloadingSheetOtherPrintDetails' ) );
				localStorage.clear();
				if(this.dataForPopupOtherDetails !=null){
					this.invoiceDtoForPrintFromPopup = new InvoiceDto();
					this.invoiceDtoForPrintFromPopup = this.dataForPopupOtherDetails;
	        	
				}
				if(this.dataForPopup !=null){
					this.hireslipDtoSearchDetailsFromPopup = new HireSlipDto();
					this.hireslipDtoSearchDetailsFromPopup = this.dataForPopup;
	        	if(this.hireslipDtoSearchDetailsFromPopup !=null){
					this.searchByUnlaodingSheetDetailsPopup();
					}
				}
					this.dataForPopup='';
					this.dataForPopupOtherDetails='';
					this.hireslipNumberFromDto=this.hireslipDtoSearchDetailsFromPopup.hireslipnumber;
					
						this.truckNumber = this.invoiceDtoForPrintFromPopup.vehicleNumber;
						this.supplierName = this.invoiceDtoForPrintFromPopup.supplierName;
						this.invoiceNumber = this.invoiceDtoForPrintFromPopup.hireSlipNumber;
						this.invoiceDate = this.invoiceDtoForPrintFromPopup.hireslipdate;
						this.driverName = this.invoiceDtoForPrintFromPopup.driverName;
						this.driverMobile = this.invoiceDtoForPrintFromPopup.mobileNum;
						this.date = this.invoiceDtoForPrintFromPopup.toDateInDate;
		}

	}

	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
        var truckNumberPrt = this.truckNumber == null ? "NA" : this.truckNumber;
        var supplierNamePrt = this.supplierName == null ? "NA" : this.supplierName;
        var invoiceNumberPrt = this.invoiceNumber == null ? "NA" : this.invoiceNumber;
        var invoiceDatePrt = this.invoiceDate == null ? "NA" : this.invoiceDate;
        var driverNamePrt = this.driverName == null ? "NA" : this.driverName;
        var driverMobilePrt = this.driverMobile == null ? "NA" : this.driverMobile;
		var datePrt = this.date == null ? "NA" : this.date;
	
		this.dtOptionsUnloadingSheetDetails = {
			dom: 'Bfrtip',
			buttons: [
						{
							extend: 'excel',
							text: '<i class="fas fa-file-excel"> Excel</i>',
							titleAttr: 'Excel',
							footer : true,
							title : function() {
							
							var returnSummaryExcel=null;
							returnSummaryExcel = "Unloading Report";
								return returnSummaryExcel;
							},
							exportOptions: {
								columns: ':visible'
							}
						}
					],  
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": true
			
			/*"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var actArticles = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var enteredArticles = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(actArticles);
				$(api.column(2).footer()).html(enteredArticles);
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
			}*/
		}
	}


	ngOnDestroy(): void {
		this.dtTriggerUnloadingSheetDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
	//	this.dtTriggerUnloadingSheetDetails.next();
	}

	searchByUnlaodingSheetDetailsPopup() {
	this.showSpinnerForAction=true;
		this.hireslipDtoSearchDetails = new HireSlipDto();
		this.hireslipDtoSearchDetails.hireslipnumber = this.hireslipDtoSearchDetailsFromPopup.hireslipnumber;
		this.hireslipService.getHireSlipsUnLoadRptLRDetails(this.hireslipDtoSearchDetails).subscribe(
			(response) => {
				$("#"+this.pageId+"unloadingSheetDetailsId").DataTable().destroy();
                this.unloadingSheetDetailsDataList = [];
                if (response.length > 0) {
                    console.log(response);
                    this.unloadingSheetDetailsDataList = response;
                    this.showSpinnerForAction = false;
                    localStorage.clear();
                    localStorage.setItem('unloadingSheetDetailsDataListForPrint', JSON.stringify(this.unloadingSheetDetailsDataList));
                    localStorage.setItem('popupUnloadingSheetOtherPrintDetails', JSON.stringify(this.invoiceDtoForPrintFromPopup));
                } else {
                    this.unloadingSheetDetailsDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerUnloadingSheetDetails.next();
				this.showSpinnerForAction=false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
			this.showSpinnerForAction=false;
				swal("Error", "Server Problem Occurred While getting the Hireslip Unloading Sheet  Details", "info");
			},
			() => console.log('done');
	}
}

