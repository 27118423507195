import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import swal from 'sweetalert';
@Component({
    selector: 'app-hireslip-unloading-sheet-details-report-print',
    templateUrl: './hireslip-unloading-sheet-details-report-print.component.html',
    styleUrls: ['./hireslip-unloading-sheet-details-report-print.component.css']
})
export class HireslipUnloadingSheetDetailsReportPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    address: any;
    unloadingRptDataList: any;
    dataList: any;
    result: any;
    totCounts: any = 0;
    userName:string;
    totRows:0;
    invoiceDate:any;
    
    invoiceDtoForPrintFromPopup:InvoiceDto = new InvoiceDto();
    pageId="husrp"
    constructor(private router: Router, private datePipe: DatePipe,public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.userName=this.userDataDtoReturnSession.name;
            this.unloadingRptDataList = JSON.parse(localStorage.getItem('unloadingSheetDetailsDataListForPrint'));
            this.invoiceDtoForPrintFromPopup = JSON.parse( localStorage.getItem( 'popupUnloadingSheetOtherPrintDetails' ) );
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.invoiceDate = this.datePipe.transform(this.invoiceDtoForPrintFromPopup.hireslipdate, "dd-MM-yyyy");
            //console.log( moment(this.lrDto[0].invoiceDate).format('DD-MM-YYYY'));

            this.dataList = [];
            if (this.unloadingRptDataList.length > 0) {
                this.dataList = this.unloadingRptDataList;
                this.totCounts = this.unloadingRptDataList.length;
                this.setGroupingPrintData();
            }
            window.setTimeout(function () {
                window.print();
            }, 1000);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    setGroupingPrintData() {
        var groups = new Set(this.dataList.map(item => item.status));
        this.result = [];
        this.totRows=0;
        groups.forEach(g => {
            /* Each Summary Data Value */
            const lrNo = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) => +sum + +i.lrNumber, 0)
                
            const actArt = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) => +sum + +i.articles, 0)
                
            const entArt = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) => +sum + +i.totalArticles, 0)
                
            const remarks = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) => +sum + +i.remarks, 0)
                
            const mention = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) => +sum + +i.description, 0)
                
            const rows = this.dataList
                .filter(i => i.status === g)
                .reduce((sum, i) =>  i + +1, 0)
                this.totRows = rows;
            this.result.push({
            	status: g,
                values: this.unloadingRptDataList.filter(i => i.status === g),
                lrNumber: lrNo,
                articles: actArt,
                totalArticles: entArt,
                remarks: remarks,
                description: mention,
                totRows:rows,
            })
        })
    }

    clearField() {
        this.totCounts = 0;
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
