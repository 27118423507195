//PFCHG_V1 @Asrar Chg, for binnymills, can assigne memos which are in billing stock(GC Not rec)
//PFCHG_V2 @Asrar Chg, added 'Complete for Direct Assigned' option for specCollection
//PFCHG_V3 @Asrar Chg, option to view all payment lrs while closing the payment in bank mode @Yusuf bh @24/07/2023
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';

@Component({
    selector: 'app-payment-followup-booking',
    templateUrl: './payment-followup-booking.component.html',
    styleUrls: ['./payment-followup-booking.component.css']
})
export class PaymentFollowupBookingComponent implements OnInit {

    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    manualView = true;
    automaticView = false;
    entryLessManualView = true;
    entryLessAutomaticView = false;
    viewByCash = false;
    viewByCashs = false;
    viewByChequeNumber = false;
    viewByChequeDate = false;
    viewByConsigneeBankName = false;
    viewByReferenceNumber = false;
    paymentEntryManualView = true;
    paymentEntryAutomaticView = false;
    consignorWiseDetails = false;
    consignorName: string;
    selectedUser: any;
    getDataFrmServiceFrLessEntriesDataTableTable: any;
    getDataFrmServiceFrConsigneeWiseBalanceDetailsTable: any;
    getDataFrmServiceFrPaymentAmountEntriesDataTable: any;

    pendingLrsDataListForSearch: Array<any> = [];
    pfAssignedJobDetailsDataList: Array<any> = [];
    findModel: Array<any> = [];

    lessEntriesDataTableDataList: Array<any> = [];
    consignorWiseBalanceDetailsDataList: Array<any> = [];
    //for entry less ends
    //for Payment entry Starts
    paymentAmountEntriesDataList: Array<any> = [];
    //for Payment entry ends
    onDestroyUnsubscribtionPfCollectionManDetails: Subscription;
    onDestroyUnsubscribtionPfAssignedJobDetails: Subscription;
    //for entry less starts
    onDestroyUnsubscribtionLessEntriesDataTable: Subscription;
    onDestroyUnsubscribtionConsigneeWiseBalanceDetails: Subscription;
    //for entry less ends
    //for Payment entry Starts
    onDestroyUnsubscribtionPaymentAmountEntries: Subscription;
    //for Payment entry ends
    model: NgbDateStruct;

    controlActionType = new FormControl();
    controlStation = new FormControl();
    //for entry less starts
    controlConsigneeName = new FormControl();
    controlEntryLessStation = new FormControl();
    controlEntryLessType = new FormControl();
    //for entry less ends
    //for Payment entry Starts
    controlConsigneeBankName = new FormControl();
    controlStationEntry = new FormControl();
    controlConsigneeNamePayment = new FormControl();

    actionTypeOptions = [
        { id: 1, label: 'Complete' },
        { id: 2, label: 'To Party' },
        { id: 3, label: 'To Party Complete' }
    ];

    //for entry less starts
    consigneeNameOptions: any;
    consigneeNameOptionsPayment: any;

    entryLessStationOptions: LRDto[];
    paymentLessStationOptions: LRDto[];
    // entryLessTypeOptions
    entryLessTypeOptions = [
        { id: 1, label: 'FLAT DISCOUNT' },
        { id: 2, label: 'RATE DIFFERENCE' },
        { id: 3, label: 'WEIGHT DIFFERENCE' },
        { id: 4, label: 'DEMMURAGE' },
        { id: 5, label: 'UNLOADING' },
        { id: 5, label: 'DOOR DELIVERY' },
        { id: 5, label: 'FOV' },
        { id: 5, label: 'TDS' },
        { id: 5, label: 'CLAIM' },
        { id: 5, label: 'FOC' }
    ];
    //for entry less ends
    //for Payment entry Starts
    consigneeBankNameArrayRet: UserDataDto[];
    consigneeBankNameArray: any;
    //for popup modal starts
    closeResultContentNotAssigned: string;
    closeResultContentAssigned: string
    closeResultContent: string;
    closeResultContentAcknowledgement: string;
    closeResultForCollectionManAssign: string;

    //    for less entry starts
    closeResultContentLessEntryTds: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerPendingLrDetails: Subject<any> = new Subject();
    dtTriggerPfAssignedJobDetails: Subject<any> = new Subject();
    //for entry less starts
    dtTriggerLessEntriesDataTable: Subject<any> = new Subject();
    dtTriggerConsignorWiseBalanceDetails: Subject<any> = new Subject();
    //for entry less ends
    //for Payment entry Starts
    dtTriggerPaymentAmountEntries: Subject<any> = new Subject();
    dtTriggerSelectLRsForPayment: Subject<any> = new Subject();
    //for Payment entry ends
    dtOptionsPendingLrsDetails: any;
    dtOptionPfAssignedJobDetails: any;

    //for entry less starts
    dtOptionsLessEntriesDataTable: any;
    dtOptionsConsignorWiseBalanceDetails: any;
    //for entry less ends
    //for Payment entry Starts
    dtOptionsPaymentAmountEntries: any;
    dtOptionsSelectLRsForPayment: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    userDataDtoForCollectionMan: UserDataDto = new UserDataDto();
    userDataDtoForCollectionManRet: any;
    collectionManOptions: UserDataDto[];
    collectionManOptionsAssign: UserDataDto[];
    collectionManOptionsToParty: UserDataDto[];
    selectedCollectionMan: any;
    enteredCollectionMan: any;
    selectedCollectionManAssign: any;
    selectedActionType: any;
    cashMemoDtoTable: CashMemoDto = new CashMemoDto();
    collectionManLrDataList: any;
    cashMemoDtoForTPStmt: CashMemoDto = new CashMemoDto();
    lrDtoForStation: LRDto = new LRDto();
    stationOptions: LRDto[];
    userDtoMemoCode: UserDataDto = new UserDataDto();
    stationCodeDetails: any;
    memoFormat: any;
    selectedMemoNumberForAutomatic: any;

    searchedMemoInfo: CashMemoDto = new CashMemoDto();
    searchedMemoForMemoAlreadyExist: CashMemoDto = new CashMemoDto();
    searchedMemoForDup: CashMemoDto = new CashMemoDto();
    memoNum: any;
    modelForSearched: any;
    modelDup: any;
    modelAlreadyExist: any;
    modelAll: any;
    assingedDetailsDataList: CashMemoDto = new CashMemoDto();
    tempStoreDup: CashMemoDto = new CashMemoDto();
    getsearchInAssignedTableFoDup: CashMemoDto = new CashMemoDto();
    getsearchInAssignedTableForAlreadyExist: CashMemoDto = new CashMemoDto();
    letValueofPendingLrs: CashMemoDto = new CashMemoDto();
    letValueofSearchInAssignedTableFoDup: CashMemoDto = new CashMemoDto();
    letValueofSearchInAssignedTableFoAlreadyExist: CashMemoDto = new CashMemoDto();
    letValueofSearchInAssignedTableFoAlreadyExistParty: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    getValueofSearchInAssignedTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    setValueofSearchInAssignedTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    addInTable = true;
    enteredCollectionManAssign: any;
    userDataDtoForLRAssign: UserDataDto = new UserDataDto();
    listOfLrNumber: Array<any> = [];
    tempstore: Array<any> = [];
    userDataDtoForLRAssignSRDStock: UserDataDto = new UserDataDto();
    removeMaint: Array<any> = [];
    isExist = false;
    userDataDtoForLRToParty: UserDataDto = new UserDataDto();
    userDataDtoForLRToPartySRDStock: UserDataDto = new UserDataDto();
    consigneeeName: any;
    enteredCollectionManToParty: any;
    selectedCollectionManToParty: any;
    consigneeExist: Boolean;
    tempStoreForAllTrx: Array<any> = [];
    value = true;
    userDataDtoForLRFinish: UserDataDto = new UserDataDto();
    enteredCompletedOnDate: any;
    tdyCompletedOnDate: any;
    updateCloseCollection: any;
    cashMemoDtoForAssignPrint: CashMemoDto = new CashMemoDto();
    viewLeftToPartyCustomPrintV1 = false;
    showPaymentFollowup = true;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeOptions: any;
    consigneeOptionsRet: PartyMasterDto[];
    consigneeOptionsAdd: PartyMasterDto = new PartyMasterDto();
    selectedEntryTypeForLess: any;
    modelForLessMaintain: any;
    searchedMemoForMemoExistInTable: CashMemoDto = new CashMemoDto();
    getsearchInCollectionManTableForEntryLess: CashMemoDto = new CashMemoDto();
    letValueofSearchInAssignedTableFoEntryLess: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoEntryLessAny: Array<any> = [];
    selectedConsignor: any;
    selectedConsignorId: any;
    selectedTypeLess: any;
    nextEntryType: any;
    isDuplicateLess = false;
    searchedMemoForMemoExistInLessEntryTable: CashMemoDto = new CashMemoDto();
    searchedMemoForMemoExistInLessEntryTableAny: any;
    letValueofMemoExistInLessEntryTableAny: Array<any> = [];
    isEdit = false;
    maintenanceDiscArray: Array<any> = [];
    totalAmt = 0.0;
    totalBalAmt = 0.0;
    enteredAmt = 0.0;
    clickedRowAmt = 0.0;
    enteredTotalLess: any;
    enteredLessAmt: any;
    balanceAmountFinal = 0.0;
    amountToBeCollected = 0.0;
    letValueofMemoFrmAssingedTableAny: Array<any> = [];
    lrDtoForGroupStation: LRDto = new LRDto();
    lrDtoForGroupStationRet: any;
    lrDtoForGroupStationSet: LRDto = new LRDto();
    private newAttribute: any = {};
    private newAttributeForTDS: any = {};
    private newAttributeForPayment: any = {};
    private newAttributeForConsignorWiseBalance: any = {};
    editTableId = null;
    editTableIdForPayment = null;
    slashSplitMemo: Array<any> = [];
    maintenanceCompletedLessLrs: any;
    maintenanceCompletedLrs: any;
    countOfLessLR = 0;
    sumOfLessAmt = 0;
    editlessamount = 0;
    getConsingeewisememoDto: CashMemoDto = new CashMemoDto();
    getConsingeewisememoAny: any;
    isDuplicateBulkLess = false;
    tdsBulkTotMemoAmt = 0.0;
    listOfMemoNumber: Array<any> = [];
    cashMemoDtoForTDS: CashMemoDto = new CashMemoDto();
    cashMemoDtoForTDSRet: Array<any> = [];
    userDataDtosLessTemp: Array<any> = [];
    userDataDtosPaymentChqTemp: Array<any> = [];
    userDataDtosPaymentNeftTemp: Array<any> = [];
    userDataDtosPaymentUpiTemp: Array<any> = [];
    userDataDtoForStatusRet: any;
    lblLessValue: any;
    lblTDSLessValue: any;
    lblClaimLessValue: any;
    lblTotLessSummaryValue: any;
    lblTotChqAmtSummaryValue: any;
    lblTotNeftAmtSummaryValue: any;
    lblTotUpiAmtSummaryValue: any;
    nf2000: any;
    nf500: any;
    nf200: any;
    nf100: any;
    nf50: any;
    nf20: any;
    nf10: any;
    nf5: any;
    nf2: any;
    nf1: any;
    nf2000Count: any;
    nf500Count: any;
    nf200Count: any;
    nf100Count: any;
    nf50Count: any;
    nf20Count: any;
    nf10Count: any;
    nf5Count: any;
    nf2Count: any;
    nf1Count: any;
    lblTotCashAmtSummaryValue: any;
    userDataDtoForLess: Array<any> = [];
    userDataDtoForPayment: Array<any> = [];
    userDataDtoForCash: Array<any> = [];
    userDataDtoForLessDetails: UserDataDto = new UserDataDto();
    userDataDtoForPaymentDetails: UserDataDto = new UserDataDto();
    userDataDtoForPaymentCashDetails: UserDataDto = new UserDataDto();
    isLessClicked = false;
    isPaymentClicked = false;
    viewNextBtn = false;
    userDataDtoForBankDetails: UserDataDto = new UserDataDto();
    userDataDtoForBankDetailsRet: UserDataDto = new UserDataDto();
    viewNewBankName = false;
    selectedEntryTypeForPayment: any;
    paymentLessManualView = true;
    paymentLessAutomaticView = false;
    viewByConsigneeName = false;
    selectedPaymentMode: any;
    showNextBtn = false;
    payType: string;
    payMode: string;
    letValueofMemoExistInPaymentEntryTableAny: Array<any> = [];
    countOfAssignLR = 0;
    sumOfAssignLR = 0;
    userDataDtoForChq: UserDataDto = new UserDataDto();
    userDataDtoForChqRet; any;
    balanceAmtInAddingTable = 0;
    getChequeDate: any;
    countOfPaymentMemo = 0;
    sumOfMemoAmtPayment = 0;
    sumOfLessAmtPayment = 0;
    sumOfBalanceAmtPayment = 0;
    editMemoAmtForPayment = 0;
    editLessAmtForPayment = 0;
    editBalanceAmtForPayment = 0;
    selectedBankName: any;
    userDataDtoForBankName: UserDataDto = new UserDataDto();
    enteredNewBankName: any;
    enteredCheckNumber: any;
    enteredRefNumber: any;
    viewFinishBtn = true;
    viewShowNexInAssingBtn = false;
    viewShowNexInAssingBtn2 = false;
    showNextBtnInAssign = false;
    showForCheckandNeft = false;
    userDataDtoForFinishDetails: UserDataDto = new UserDataDto();
    isDuplicateChq = false;
    lrDtoForValidateChq: LRDto = new LRDto();
    retrunStatus: any;
    diffLessRet: any;
    validateChqRet: any;
    getChallanPrintValDto: CashMemoDto = new CashMemoDto();
    lessDescOpt: Array<any> = [];
    lessDescOptSent: Array<any> = [];
    viewChallanRptPrint = false;
    collectionManTP: any;
    countTp: any;
    stationForCode: any;
    consignorWiseBalancelDto: CashMemoDto = new CashMemoDto();
    consigneeWiseBalancelDtoForUpdate: CashMemoDto = new CashMemoDto();
    consignorWiseBalancelRet: any;
    countOfConsingeeWiseMemo = 0;
    sumOfConsingeeWiseTotalCashMemo = 0;
    sumOfConsingeeWiseGrandTotal = 0;
    sumOfConsingeeWisePartyLessDed = 0;
    sumOfConsingeeWiseAdvChqAmt = 0;
    sumOfConsingeeWiseNeftAmtl = 0;
    sumOfConsingeeWiseBalance = 0;
    countOFMemosCollectionTable = 0;
    sumOFMemosCollectionTable = 0;
    setCollectionDetails = 'new';
    setAssingedDetails = 'new';
    newAttForColTable: any = {};
    modelAllRemove: any;
    tempStoreForRemove: CashMemoDto = new CashMemoDto();
    consigneeWiseTrigger = 'new';
    showLessLbl = false;
    showChqLbl = false;
    showNEFTLbl = false;
    showUpiLbl = false;
    showCashLbl = false;
    getCashValDto: CashMemoDto = new CashMemoDto();
    userDataDtosPaymentCashTemp: Array<any> = [];
    grandTotalForCash: any;
    showCashLblAmt = false;
    getMainStation: any;
    showSpinner = false;
    showResetBtn = false;
    modelStationForPayment: any;
    modelStationForPaymentAmt: any;
    modelStationForLess: any;
    modelStationForLessAmt: any;
    showSpinnerForAction = false;
    setLessEntryDetails = 'new';
    setPaymentEntryDetails = 'new';
    // tdsLessMulti = 1.5;
    tdsLessMulti = 2;
    twoThousandRupeeCountModel: any;
    twoThousandRupeeModel: any;
    fiveHundredRupeeCountModel: any;
    fiveHundredRupeeModel: any;
    twoHundredRupeeCountModel: any;
    twoHundredRupeeModel: any;
    hundredRupeeCountModel: any;
    hundredRupeeModel: any;
    fiftyRupeeCountModel: any;
    fiftyRupeeModel: any;
    twentyRupeeCountModel: any;
    twentyRupeeModel: any;
    tenRupeeCountModel: any;
    tenRupeeModel: any;
    fiveRupeeCountModel: any;
    fiveRupeeModel: any;
    twoRupeeCountModel: any;
    twoRupeeModel: any;
    oneRupeeCountModel: any;
    oneRupeeModel: any;
    newPartyBankDetial: any;

    // public totalMemoForTDS: any;
    // public memoAmountForTDS: any;
    // public tdsLessTwoPer: any;

    //Consignee Bank 
    @ViewChild('instanceConsigneeBankName') instanceConsigneeBankName: NgbTypeahead;
    consigneeBankNameTA: Array<any> = [];
    focusConsigneeBankNameTA$ = new Subject<string>();
    consigneeBankNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeBankNameTA
                : this.consigneeBankNameTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //action Type TA
    public modelActionType: any;
    @ViewChild('instanceActionType') instanceActionType: NgbTypeahead;
    actionTypeTA: Array<any> = [];
    focusActionTypeTA$ = new Subject<string>();
    actionTypeSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusActionTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.actionTypeTA
                : this.actionTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //less Type TA
    public modelLessType: any;
    @ViewChild('instanceLessType') instanceLessType: NgbTypeahead;
    lessTypeTA: Array<any> = [];
    focusLessTypeTA$ = new Subject<string>();
    lessTypeSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusLessTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.lessTypeTA
                : this.lessTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    //
    //for Consignor toparty
    consignorMasterDataList: PartyMasterDto[];
    topartyNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    topartyNameTA: Array<PartyMasterDto> = [];
    topartyNameTATemp: Array<PartyMasterDto> = [];
    focusTopartyNameTA$ = new Subject<string>();
    topartyNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusTopartyNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.topartyNameTA
                : this.topartyNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterTopartyName = (x: { consignorName: string }) => x.consignorName;
    //
    //For consignor Index search
    public modelConsignorIndexSearchView: any;
    @ViewChild('instanceConsignorIndexSearchView') instanceConsignorIndexSearchView: NgbTypeahead;
    consignorIndexSearchViewTA: Array<any> = [];
    focusConsignorIndexSearchViewTA$ = new Subject<string>();
    consignorIndexSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexSearchViewTA
                : this.consignorIndexSearchViewTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName search
    consignorNameSearchViewTA: Array<PartyMasterDto> = [];
    focusConsignorNameSearchViewTA$ = new Subject<string>();
    consignorNameSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameSearchViewTA
                : this.consignorNameSearchViewTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorNameSearchView = (x: { consignorName: string }) => x.consignorName;

    //For From Station
    public modelAgentSearchView: any;
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { subStation: string }) => x.subStation;
    sentSrcList: Array<any> = [];
    searchSrcListInLoop: Array<any> = [];
    leftToPartyStatementDataLocalStorage: CashMemoDto = new CashMemoDto();
    generatedtpStmtNo = null;
    pendingLrsDataForSearchSelectedList: Array<any> = [];
    pendingLrsDataForSearchSelectedIndex: any;
    paymetSelectedLrsDataList: Array<any> = [];

    //For Consignor Index
    consignorIndexOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    pageId = "pmtfbv1c";
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndex: any;
    todayDate: any;
    memoDateCheck: Date;
    public modelConsignorName: any;
    public modelPartyBankName: any;
    public modelTopartyName: any;
    public modelConsignorNameSearchView: any;
    tpNoView = false;
    enteredTpCode = null;
    enteredTpNo = null;
    enteredStmtNo = null;
    tpFormat = null;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    selectedTopartyName: any;
    selectedTopartyId: any;
    selectedTopartyAddress: any;
    selectedTopartyGst: any;
    tppartyView = false;
    tppartyViewCommon = false;
    selectedConsignorIdSearchView: any;
    consignorNameSearchViewOptions: any;
    viewDateWiseField = false;
    fromStationDataListOptions: any;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    selectedFromDate: any;
    selectedToDate: any;
    selectedIndexSearch: any;
    selectedAgentSearch: any;
    showAgentDropdown = false;
    showTpStmtNo: boolean = false;
    countOfSelectedPaymentLRMemo = 0;
    sumOfSelectedPaymentLR = 0;
    pfAssignedLRsForPaymentList: Array<any> = [];
    totalAmountForPaymentEntries = 0.0;

    constructor(private _formBuilder: FormBuilder, private memoService: MemoService, private modalService: NgbModal,
        private masterReadService: MasterReadService, private router: Router, private datePipe: DatePipe,
        public dialog: MatDialog, private consignorService: ConsignorService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            console.log(this.userDataDtoReturnSession.office);
            console.log(this.userDataDtoReturnSession.mainStation);
            console.log(this.userDataDtoReturnSession.mainAdminStation);
            console.log(this.userDataDtoReturnSession);
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Bkg Payment Followup Show Agent") {
                        this.showAgentDropdown = true;
                    }
                }
            }

            this.setActionVal();
            this.setLessVal();
            this.getTopartyDetailsMethod();
            this.getAgentNamesFromStations();
            this.consignorWiseDetailsTable();
            this.setConsginorIndex();
            this.selectTodayCompletedOnDate();
            this.getPartyBankDetailsService();
            this.lessEntriesTable();
            this.paymentEntriesDataTable();
            this.selectedLrPaymentEntriesDataTable();
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    selectTodayCompletedOnDate() {
        this.tdyCompletedOnDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        $("#" + this.pageId + "completedOnDate").val(this.tdyCompletedOnDate);
    }

    ngOnInit(): void {
        this.firstFormGroup = this._formBuilder.group({
        });
        this.secondFormGroup = this._formBuilder.group({
        });
        this.thirdFormGroup = this._formBuilder.group({
        });
        this.fourthFormGroup = this._formBuilder.group({
        });

        this.dtOptionPfAssignedJobDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totAmt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(1).footer()).html(data.length);
                $(api.column(2).footer()).html(totAmt);
            }
        },

            this.dtOptionsPendingLrsDetails = {
                dom: 'Bfrtip',
                buttons: [
                ],
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                "info": false,
                paging: false,
                searching: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totAmt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(1).footer()).html(data.length);
                    $(api.column(3).footer()).html(totAmt);
                }
            }
    }
    ngAfterViewInit(): void {
        this.dtTriggerPendingLrDetails.next();
        this.dtTriggerPfAssignedJobDetails.next();
        this.dtTriggerLessEntriesDataTable.next();
        this.dtTriggerConsignorWiseBalanceDetails.next();
        this.dtTriggerPaymentAmountEntries.next();
        this.dtTriggerSelectLRsForPayment.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerPendingLrDetails.unsubscribe();
        this.dtTriggerPfAssignedJobDetails.unsubscribe();
        this.dtTriggerLessEntriesDataTable.unsubscribe();
        this.dtTriggerConsignorWiseBalanceDetails.unsubscribe();
        this.dtTriggerPaymentAmountEntries.unsubscribe();
        this.dtTriggerSelectLRsForPayment.unsubscribe();
    }
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }
        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected(confirmedConsignorListData) {
        this.selectedUser = confirmedConsignorListData;
        this.consignorName = this.selectedUser.name;
    }
    openNotAssignedPopup(contentNotAssigned) {
        this.modalService.open(contentNotAssigned, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentNotAssigned = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentNotAssigned = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    openAssignedPopup(contentAssigned) {
        this.modalService.open(contentAssigned, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentAssigned = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentAssigned = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    openPopup(content) {
        this.modalService.open(content, { centered: true }).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    openAcknowledgementPopup(contentAcknowledgement) {
        this.modalService.open(contentAcknowledgement, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentAcknowledgement = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentAcknowledgement = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    open10(content10) {
        this.modalService.open(content10, { centered: true }).result.then(
            result => {
                this.closeResultForCollectionManAssign = `Closed with: ${result}`;
            },
            reason => {
                this.closeResultForCollectionManAssign = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    open11(content11) {
        this.modalService.open(content11, { centered: true }).result.then(
            result => {
                this.closeResultForCollectionManAssign = `Closed with: ${result}`;
            },
            reason => {
                this.closeResultForCollectionManAssign = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    entryTypeMode(entryType: string) {
        if (entryType === 'manual') {
            this.manualView = true;
            this.automaticView = false;
        } else if
            (entryType === 'automatic') {
            this.manualView = false;
            this.automaticView = true;
        } else {
            this.manualView = false;
            this.automaticView = false;
        }
    }
    entryLessPaymentEntryMode(paymentLessEntryType: string) {
        if (paymentLessEntryType === 'paymentLessManual') {
            this.paymentLessManualView = true;
            this.paymentLessAutomaticView = false;
        } else if (paymentLessEntryType === 'paymentLessAutomatic') {
            this.paymentLessManualView = false;
            this.paymentLessAutomaticView = true;
        } else {
            this.paymentLessManualView = false;
            this.paymentLessAutomaticView = false;

        }
    }
    entryLessEntryTypeMode(entryLessPaymentEntry: string) {
        if (entryLessPaymentEntry === 'entryLessManual') {
            this.entryLessManualView = true;
            this.entryLessAutomaticView = false;

        } else if (entryLessPaymentEntry === 'entryLessAutomatic') {
            this.entryLessManualView = false;
            this.entryLessAutomaticView = true;
        } else {
            this.entryLessManualView = false;
            this.entryLessAutomaticView = false;
        }
    }

    paymentModeMode(paymentMode: string) {
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsignorId = 0;
        if (paymentMode === 'cash') {
            this.viewByCash = true;
            this.viewByCashs = true;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = false;
            this.showNextBtn = true;
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsignorId = 0;
            this.payType = "Cash";
            this.payMode = "completedcash";
            this.showForCheckandNeft = false;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.modelStationForPaymentAmt = this.memoFormat;

        } else if (paymentMode === 'cheque') {
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = true;
            this.viewByChequeDate = true;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "Cheque";
            this.payMode = "completedcheque";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsignorId = 0;
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelStationForPaymentAmt = this.memoFormat;
        } else if (paymentMode === 'neft') {
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = true;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "NEFT/IMPS";
            this.payMode = "completedneft";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsignorId = 0;
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelStationForPaymentAmt = this.memoFormat;
        } else if (paymentMode === 'upi') {
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = true;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "UPI";
            this.payMode = "completedupi";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsignorId = 0;
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelStationForPaymentAmt = this.memoFormat;
        } else {
            this.viewByCash = false;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = false;
            this.showNextBtn = true;
            this.payType = "";
            this.payMode = "";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsignorId = 0;
            this.showForCheckandNeft = false;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.modelStationForPaymentAmt = this.memoFormat;
        }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    goToCollectionManPage() {
        this.router.navigate(['/report/cashmemo-report/collectionManReport']);
    }
    showTheConsignorWiseDetails() {
        this.consignorWiseDetails = true;
        this.nextEntryType = 'Less Entry';
        this.modelStationForLessAmt = this.memoFormat;
        this.callForCompleteCollection();
    }
    setPaymentEntry() {
        this.nextEntryType = 'Payment Entry';
        this.updateLessDetails();
        // calculate total
        this.calculateAllTotalAndBalance();
        //Pending
        this.updateConsignorBalanceDetails();
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showUpiLbl = true;
        this.showCashLbl = true;
        //// newly added basha       
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.payType = "";
        this.payMode = "";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsignorId = 0;
        this.modelStationForPaymentAmt = this.memoFormat;
        $("#" + this.pageId + "paymentMode").val('select');
        this.pfAssignedLRsForPaymentList = this.pfAssignedJobDetailsDataList;
        this.totalAmountForPaymentEntries = this.amountToBeCollected;

    }
    setActionVal() {
        for (let i = 0; i < this.actionTypeOptions.length; i++) {
            this.actionTypeTA.push(this.actionTypeOptions[i].label);
        }
        //PFCHG_V2
        if (this.userDataDtoReturnSession.specCollectionFlow) {
            this.actionTypeTA.push("Complete for Direct Assigned");
        }
    }
    ///for Action Type
    actionTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelActionType = e.item;
        this.pendingLrsDataListForSearch = [];
        this.pfAssignedJobDetailsDataList = [];
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.newAttForColTable = {};
        this.tempstore = [];
        this.selectedActionType = this.modelActionType;

        $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
        this.dtTriggerPendingLrDetails.next();

        if (this.selectedActionType == null || this.selectedActionType == '') {
            swal({
                title: "Warning",
                text: "Please select Action Type to get the details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = true;
            this.viewShowNexInAssingBtn2 = false;
            this.showNextBtnInAssign = false;
            this.showTpStmtNo = false;
        } else if (this.selectedActionType === 'Complete') {
            this.showNextBtnInAssign = true;
            this.viewFinishBtn = false;
            this.viewShowNexInAssingBtn2 = false;
            this.tpNoView = false;
            this.tppartyView = false;
            this.viewDateWiseField = false;
            this.tppartyViewCommon = false;
            this.showTpStmtNo = false;
        } else if (this.selectedActionType === 'To Party') {
            this.showNextBtnInAssign = false;
            this.viewFinishBtn = true;
            this.viewShowNexInAssingBtn2 = false;
            this.tpNoView = false;
            this.tppartyView = true;
            this.tppartyViewCommon = true;
            this.viewDateWiseField = true;
            this.showTpStmtNo = false;
        } else if (this.selectedActionType === 'To Party Complete') {
            this.showNextBtnInAssign = true;
            this.viewFinishBtn = false;
            this.viewShowNexInAssingBtn2 = false;
            this.tpNoView = false;
            this.tppartyView = false;
            this.tppartyViewCommon = true;
            this.viewDateWiseField = false;
            this.showTpStmtNo = true;
            $("#pmtfbctpNo").focus();
        }
    }
    reportModeTypeListner(reportModeType: string) {
        if (reportModeType == "tillDate") {
            this.viewDateWiseField = false;
        } else if (reportModeType == "DateWise") {
            this.viewDateWiseField = true;
        }
    }

    setLessVal() {
        for (let i = 0; i < this.entryLessTypeOptions.length; i++) {
            this.lessTypeTA.push(this.entryLessTypeOptions[i].label);
        }
    }
    lessTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelLessType = e.item;
        console.log("this.modelLessType");
        console.log(this.modelLessType);
        this.selectedTypeLess = $("#" + this.pageId + "lessType").val();
        console.log("this.selectedTypeLess");
        console.log(this.selectedTypeLess);

        this.rowSelectedLessType();
    }

    validateNextBtnInAssign() {
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No LR Found in Assigned Job!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = true;
            this.viewShowNexInAssingBtn2 = false;
        } else if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            swal({
                title: "Mandetory",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = true;
            this.viewShowNexInAssingBtn2 = false;
        } else {
            swal({
                title: "OK",
                text: "Please Click Next button again to continue",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = false;
            this.viewShowNexInAssingBtn2 = true;
            $("#pmtfbcnextBtnAssign").focus();
        }
    }

    getPendingLrsGridInfo(status, stmtNo) {
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDates").val();
        this.selectedToDate = $("#" + this.pageId + "toDates").val();
        this.selectedAgentSearch = $("#" + this.pageId + "fromStation").val();

        this.cashMemoDtoTable = new CashMemoDto();
        this.cashMemoDtoTable.status = status;
        // this.cashMemoDtoTable.source = this.userDataDtoReturnSession.office;
        this.cashMemoDtoTable.companyId = this.userDataDtoReturnSession.companyId;
        this.cashMemoDtoTable.memoType = this.selectedActionType;
        this.cashMemoDtoTable.statementNo = stmtNo;

        this.cashMemoDtoTable.fromdate = this.selectedFromDate;
        this.cashMemoDtoTable.todate = this.selectedToDate;
        this.cashMemoDtoTable.consignor = this.selectedIndexSearch;
        this.cashMemoDtoTable.consignorId = this.selectedConsignorIdSearchView;
        this.cashMemoDtoTable.source = this.selectedAgentSearch;
        this.cashMemoDtoTable.mainStation = this.userDataDtoReturnSession.mainStation;
        if (this.selectedAgentSearch == '' || this.selectedAgentSearch == null || this.selectedAgentSearch == 'ALL') {
            this.sentSrcList = [];
            this.sentSrcList = this.searchSrcListInLoop;
            this.cashMemoDtoTable.list = this.sentSrcList;
        } else {
            this.sentSrcList = [];
            this.sentSrcList.push(this.selectedAgentSearch);
            this.cashMemoDtoTable.list = this.sentSrcList;
        }
        if (this.viewDateWiseField) {
            this.cashMemoDtoTable.mode = "Datewise";
        }
    }

    getPendingLrDetailsGridDtails(status, stmtNo) {
        this.showSpinnerForAction = true;
        this.getPendingLrsGridInfo(status, stmtNo);
        this.memoService.getCashMemoUnAssgBkgService(this.cashMemoDtoTable).subscribe(
            (response) => {
                this.pendingLrsDataListForSearch = [];
                //$("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.pendingLrsDataListForSearch = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                // this.dtTriggerPendingLrDetails.next();
                this.showSpinnerForAction = false;

                // if (this.setCollectionDetails == 'new') {
                $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
                this.dtTriggerPendingLrDetails.next();
                this.setCollectionDetails = 'old';
                // }

                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the LR details", "warning");
            }, () => console.log('done');
    }
    consignorWiseDetailsTable() {
        this.dtOptionsConsignorWiseBalanceDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false,
        }
    }

    lessEntriesTable() {
        this.dtOptionsLessEntriesDataTable = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false,
        }
    }

    paymentEntriesDataTable() {
        this.dtOptionsPaymentAmountEntries = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false
        }
    }
    selectedLrPaymentEntriesDataTable() {
        this.dtOptionsSelectLRsForPayment = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false
        }
    }
    getMemoNumberForAutomatic(e, keyEnter: string) {
        if (e.keyCode == 13) {
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
            if (this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '') {
                this.gridReconfigureAssg(keyEnter);
            }
        }
    }
    gridReconfigureAssg(keyEnter) {
        this.tempStoreDup = new CashMemoDto();
        this.memoNum = '';
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
        this.memoNum = this.selectedMemoNumberForAutomatic.toUpperCase();
        //Checking in Pending LRs dataList
        this.searchedMemoInfo = new CashMemoDto();
        this.searchedMemoInfo = this.searchInPendingLrsTable(this.memoNum);
        if (this.searchedMemoInfo.lrNumber == null || this.searchedMemoInfo.lrNumber == '') {
            this.modelForSearched = '';
        } else {
            this.modelForSearched = this.searchedMemoInfo.lrNumber;
        }
        //Checking in Assigned dataList-1
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoForDup = this.searchInPendingLrsTableFoDup(this.memoNum);
        if (this.searchedMemoForDup.lrNumber == null || this.searchedMemoForDup.lrNumber == '') {
            this.modelDup = '';
        } else {
            this.modelDup = this.searchedMemoForDup.lrNumber;
        }
        //Checking in Assigned dataList-2
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = this.searchInAssignedTableForAlreadyExist(this.memoNum);
        if (this.searchedMemoForMemoAlreadyExist.lrNumber == null || this.searchedMemoForMemoAlreadyExist.lrNumber == '') {
            this.modelAlreadyExist = '';
        } else {
            this.modelAlreadyExist = this.searchedMemoForMemoAlreadyExist.lrNumber;
        }
        if (keyEnter == 'keyEnter' && (this.modelForSearched == null || this.modelForSearched == '')) {
            swal({
                title: "LR Complete",
                text: "LR Number " + this.memoNum + " does not exist!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#pmtfbcmemoNumberForAutomatic").focus();
            });
            this.addInTable = false;
        } else if (this.modelAlreadyExist != null && this.modelAlreadyExist != '' && this.consigneeExist == false) {
            swal({
                title: "LR Complete",
                text: "LR Number " + this.memoNum + " already transfer!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#pmtfbcmemoNumberForAutomatic").focus();
            });
            this.addInTable = false;
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'To Party' && this.pfAssignedJobDetailsDataList.length > 0) {
            this.assingedDetailsDataList = this.pfAssignedJobDetailsDataList[0];
            if (this.assingedDetailsDataList.consignor != this.searchedMemoForMemoAlreadyExist.consignor) {
                swal({
                    title: "LR-To Party",
                    text: "Different Consignor's Memo can't be possible at once in To Party, please select the same Consignor's Memo!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else if (this.modelForSearched != this.modelDup) {
                if (keyEnter == 'keyEnter') {
                    this.addInTable = true;
                    this.tempStoreDup = this.searchedMemoInfo;
                }
            }
        } else if (this.modelForSearched != this.modelDup) {
            if (keyEnter == 'keyEnter') {
                this.addInTable = true;
                this.tempStoreDup = this.searchedMemoInfo;
            }
        }
        if (this.addInTable == true) {
            this.gridReconfigureAllRemove();
            if (this.pfAssignedJobDetailsDataList == null) {
                this.pfAssignedJobDetailsDataList = [];
            }
            // if (this.setAssingedDetails == 'new') {
            $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
            this.dtTriggerPfAssignedJobDetails.next();
            this.setAssingedDetails = 'old';
            // }
            this.pfAssignedJobDetailsDataList.push(this.tempStoreDup);
            // this.dtTriggerPfAssignedJobDetails.next();

            $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
            this.dtTriggerPendingLrDetails.next();
            this.changeDetectorRef.detectChanges();

            this.countOfAssignLR = +this.countOfAssignLR + 1;
            this.sumOfAssignLR = +this.sumOfAssignLR + +this.tempStoreDup.grandTotal;
            this.tempstore.push(this.tempStoreDup);
            this.tempStoreDup = new CashMemoDto();
            this.addInTable = false;
        } else {
            this.tempStoreDup = new CashMemoDto();
            this.addInTable = false;
        }
        $("#" + this.pageId + "memoNumberForAutomatic").val('');
        this.value = true;
    }

    searchInPendingLrsTable(selectedNum: string): CashMemoDto {
        this.letValueofPendingLrs = (this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === selectedNum));
        if (this.letValueofPendingLrs == null || this.letValueofPendingLrs == undefined) {
            this.letValueofPendingLrs = new CashMemoDto();
        } else {
            this.letValueofPendingLrs = this.letValueofPendingLrs;
        }
        return this.letValueofPendingLrs;
    }

    searchInPendingLrsTableFoDup(selectedNum: string) {
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInAssignedTableFoDup = new CashMemoDto();
        } else {
            this.getsearchInAssignedTableFoDup = this.enteredSearchInAssignedTableFoDup(selectedNum)
        }
        return this.getsearchInAssignedTableFoDup;
    }

    enteredSearchInAssignedTableFoDup(selectedNum: string): CashMemoDto {
        this.letValueofSearchInAssignedTableFoDup = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === selectedNum));
        if (this.letValueofSearchInAssignedTableFoDup == null || this.letValueofSearchInAssignedTableFoDup == undefined) {
            this.letValueofSearchInAssignedTableFoDup = new CashMemoDto();
        } else {
            this.letValueofSearchInAssignedTableFoDup = this.letValueofSearchInAssignedTableFoDup;
        }
        return this.letValueofSearchInAssignedTableFoDup;
    }

    searchInAssignedTableForAlreadyExist(selectedNum: string) {
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInAssignedTableForAlreadyExist = new CashMemoDto();
        } else {
            this.getsearchInAssignedTableForAlreadyExist = this.enteredSearchInAssignedTableForAlreadyExist(selectedNum)
        }
        return this.getsearchInAssignedTableForAlreadyExist;
    }
    enteredSearchInAssignedTableForAlreadyExist(selectedNum: string): CashMemoDto {
        this.consigneeExist = false;
        this.letValueofSearchInAssignedTableFoAlreadyExist = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === selectedNum));
        if (this.letValueofSearchInAssignedTableFoAlreadyExist == null || this.letValueofSearchInAssignedTableFoAlreadyExist == undefined) {
            this.selectedActionType = $("#" + this.pageId + "actionType").val();
            if (this.selectedActionType == 'To Party') {
                this.letValueofSearchInAssignedTableFoAlreadyExistParty = this.getConsigneeNameParty(selectedNum);
                this.consigneeeName = this.letValueofSearchInAssignedTableFoAlreadyExistParty.consignor;
                this.letValueofSearchInAssignedTableFoAlreadyExist = this.searchingToParty(this.consigneeeName);
            } else {
                this.letValueofSearchInAssignedTableFoAlreadyExist = new CashMemoDto();
            }
        } else {
            this.letValueofSearchInAssignedTableFoAlreadyExist = this.letValueofSearchInAssignedTableFoAlreadyExist;
        }
        return this.letValueofSearchInAssignedTableFoAlreadyExist;
    }

    searchingToParty(selectedConsigneeName: string): CashMemoDto {
        this.consigneeExist = false;
        this.getValueofSearchInAssignedTableFoAlreadyExistToParty = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.consignor === selectedConsigneeName));
        if (this.getValueofSearchInAssignedTableFoAlreadyExistToParty == null || this.getValueofSearchInAssignedTableFoAlreadyExistToParty == undefined) {
            this.getValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
            this.consigneeExist = false;
        } else {
            this.consigneeExist = true;
            this.getValueofSearchInAssignedTableFoAlreadyExistToParty = this.getValueofSearchInAssignedTableFoAlreadyExistToParty;
        }
        return this.getValueofSearchInAssignedTableFoAlreadyExistToParty;
    }

    getConsigneeNameParty(selectedNum: string): CashMemoDto {
        this.setValueofSearchInAssignedTableFoAlreadyExistToParty = (this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === selectedNum));
        if (this.setValueofSearchInAssignedTableFoAlreadyExistToParty == null || this.setValueofSearchInAssignedTableFoAlreadyExistToParty == undefined) {
            this.setValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
            this.consigneeExist = false;
        } else {
            this.consigneeExist = true;
            this.setValueofSearchInAssignedTableFoAlreadyExistToParty = this.setValueofSearchInAssignedTableFoAlreadyExistToParty;
        }

        return this.setValueofSearchInAssignedTableFoAlreadyExistToParty;
    }

    gridReconfigureAllRemove() {
        let lrNumberEntered = null;
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
        lrNumberEntered = this.selectedMemoNumberForAutomatic.toUpperCase();
        //Pending of date , remove btn,all btn, remove all btn, and reconfigure of datalist of both
        let index = -1;
        for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
            if (this.pendingLrsDataListForSearch[i].lrNumber === lrNumberEntered) {
                index = i;
                break;
            }
        }
        if (index === -1) {
            alert("Something gone wrong while remove");
        }
        this.pendingLrsDataListForSearch.splice(index, 1);
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
            this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
            this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pendingLrsDataListForSearch[i].grandTotal);
        }
    }
    //Btn Finish
    followupFinish() {
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedCollectionMan = this.enteredCollectionMan;

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No Cashmemo Found in Assigned Job!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedActionType == null || this.selectedActionType == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please select Action type",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedActionType != null && this.selectedActionType == 'To Party') {
            this.callForToPartyLR();
        } else if (this.selectedActionType != null && this.selectedActionType == 'Complete') {
            this.callForCompleteCollection();
        }
    }
    //For Update Memo
    updateTopartyLRInfo(userDataDtoForLR) {
        userDataDtoForLR.companyId = this.userDataDtoReturnSession.companyId;
        userDataDtoForLR.city = this.userDataDtoReturnSession.mainStation;
        userDataDtoForLR.updatedBy = this.userDataDtoReturnSession.userId;
        userDataDtoForLR.collectionFlow = this.userDataDtoReturnSession.collectionFlow;
        this.updateTopartyDetails(userDataDtoForLR);
    }

    updateTopartyDetails = (userDataDtoForLR) => {
        this.generatedtpStmtNo = null;
        this.showSpinnerForAction = true;
        this.memoService.updateTopartyLRDtlsBkg(userDataDtoForLR).subscribe(
            (response) => {
                if (response.length == 0) {
                    alert('');
                } else {
                    this.showSpinnerForAction = false;
                    this.userDataDtoForStatusRet = '';
                    this.userDataDtoForStatusRet = response;
                    console.log(this.userDataDtoForStatusRet.statementNo);
                    this.generatedtpStmtNo = this.userDataDtoForStatusRet.statementNo;
                    if (this.userDataDtoForStatusRet.result == 'success') {
                        swal({
                            title: "Success",
                            text: "Selected LRs marked as To-Party",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            this.setTopartyStmtprint(userDataDtoForLR);
                        });

                    } else {
                        swal({
                            title: "Server Error",
                            text: "Server problem occurred while process the To-Party LRs",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                    this.pfAssignedJobDetailsDataList = [];
                    $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
                    this.dtTriggerPfAssignedJobDetails.next();
                    //Pending
                    this.tempstore = [];
                    this.tempStoreDup = new CashMemoDto();
                    //Pending 
                    this.gridReconfigureAssg("other");
                    $("#" + this.pageId + "consigneeBankName").val('');
                    $("#" + this.pageId + "newBankName").val('');
                    this.controlConsigneeBankName.reset();
                    this.viewNewBankName = false;
                    this.clearAllAfterBtnFinish();
                    this.userDataDtoForStatusRet = '';
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    clearAllAfterBtnFinish() {
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedMemoNumberForAutomatic = '';
        this.modelForSearched = '';
        this.letValueofPendingLrs = new CashMemoDto();
        this.getsearchInAssignedTableFoDup = new CashMemoDto();
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoInfo = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoDup = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.getsearchInAssignedTableForAlreadyExist = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoAlreadyExist = new CashMemoDto();
        this.setValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
        this.getValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoAlreadyExistParty = new CashMemoDto();
        this.modelAlreadyExist = '';
        this.modelDup = '';
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.consigneeeName = '';
        this.consigneeExist = false;
        this.selectTodayCompletedOnDate();
    }
    clearToParty() {
        this.userDataDtoForLRToPartySRDStock = new UserDataDto();
        this.selectedCollectionManToParty = '';
        this.selectedTopartyId = 0;
        this.selectedTopartyName = null;
        this.selectedTopartyAddress = null;
        this.selectedTopartyGst = null;
        $("#" + this.pageId + "topartyName").val('');
        this.listOfLrNumber = [];
        this.userDataDtoForLRToParty = new UserDataDto();
        this.selectedCollectionMan = '';
        this.modelTopartyName = null;
        this.selectTodayCompletedOnDate();
        this.generatedtpStmtNo = null;
    }
    chkPendingMemosExist() {
        this.isExist = false;
        for (let i = 0; i < this.tempstore.length; i++) {
            if (this.tempstore[i].column1 != null && this.tempstore[i].column1 == 'Pending') {
                this.isExist = true;
                break;
            }
        }
        return this.isExist;
    }

    removePendingMemos() {
        swal({
            title: "Not Allowed",
            text: "Some Memos are still in Pending.For further processing, those memos sholud be Remove",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        }).then(() => {
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                let column1 = this.pfAssignedJobDetailsDataList[i].column1;
                if (column1 != null && column1 == 'Pending') {
                    this.removeMaint.push(this.pfAssignedJobDetailsDataList[i]);
                }
            }
            let index = -1;
            for (let i = 0; i < this.removeMaint.length; i++) {
                for (let j = 0; j < this.tempstore.length; j++) {
                    if (this.tempstore[j].lrNumber === this.removeMaint[i].lrNumber) {
                        index = j;
                    }
                    if (index === -1) {
                        alert("Something gone wrong1");
                    }
                    this.tempstore.splice(index, 1);
                }
            }
            for (let i = 0; i < this.removeMaint.length; i++) {
                for (let j = 0; j < this.pfAssignedJobDetailsDataList.length; j++) {
                    if (this.pfAssignedJobDetailsDataList[j].lrNumber === this.removeMaint[i].lrNumber) {
                        index = j;
                    }
                    if (index === -1) {
                        alert("Something gone wrong2");
                    }
                    this.pfAssignedJobDetailsDataList.splice(index, 1);
                }
            }

            this.countOfAssignLR = 0;
            this.sumOfAssignLR = 0;
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].grandTotal;
            }
            for (let i = 0; i < this.removeMaint.length; i++) {
                this.pendingLrsDataListForSearch.push(this.removeMaint[i]);
            }
            this.countOFMemosCollectionTable = 0;
            this.sumOFMemosCollectionTable = 0;
            for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
                this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pendingLrsDataListForSearch[i].grandTotal);
            }
        });
    }
    ///To Party 
    callForToPartyLR() {
        this.selectedTopartyName = $("#" + this.pageId + "topartyName").val();
        // if (this.selectedTopartyName == null || this.selectedTopartyName == '') {
        //     swal({
        //         title: "Mandatory Fields",
        //         text: "Please select To-Party Name to proceed!",
        //         icon: "warning",
        //         closeOnClickOutside: false,
        //         closeOnEsc: false,
        //     });
        // } else {
        swal({
            title: "LR To-Party",
            text: "Sure U Want to Mark the selected LRs As To-Party ",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((toPartyMemo) => {
            if (toPartyMemo) {
                this.userDataDtoForLRToParty = new UserDataDto();
                this.userDataDtoForLRToParty.mode = 'toParty';
                this.userDataDtoForLRToParty.topartyConsignorId = this.selectedTopartyId;
                this.userDataDtoForLRToParty.fromDate = this.selectedFromDate;
                this.userDataDtoForLRToParty.toDate = this.selectedToDate;
                this.listOfLrNumber = [];
                for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                    this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].lrNumber);
                }
                this.userDataDtoForLRToParty.list = this.listOfLrNumber;
                if (this.pfAssignedJobDetailsDataList[0].consignor != null && this.pfAssignedJobDetailsDataList[0].consignor != '') {
                    this.userDataDtoForLRToParty.consignorId = this.pfAssignedJobDetailsDataList[0].consignorId;

                    if (this.selectedTopartyName == null || this.selectedTopartyName == '') {
                        this.userDataDtoForLRToParty.partyName = this.pfAssignedJobDetailsDataList[0].consignor;
                    } else {
                        this.userDataDtoForLRToParty.partyName = this.selectedTopartyName;
                    }
                }
                this.updateTopartyLRInfo(this.userDataDtoForLRToParty);
            }
        });
        // }
    }
    //for Btn All
    validateAllBtn() {
        if (this.pendingLrsDataListForSearch.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No LR Found",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.gridTransferAllToAssg();
        }
    }

    //for Btn Selected
    validateSelectedBtn() {
        if (this.pendingLrsDataForSearchSelectedList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "Please select LR to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.gridTransferSelectedToAssg();
        }
    }

    //for Btn  Remove All
    validateRemoveAllBtn() {
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No LRs Found",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.gridTransferAllToUnAssg();
        }
    }
    gridTransferAllToUnAssg() {
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.pendingLrsDataListForSearch.push(this.pfAssignedJobDetailsDataList[i]);
        }
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.pfAssignedJobDetailsDataList = [];
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
            this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
            this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pendingLrsDataListForSearch[i].grandTotal);
        }
        $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
        this.dtTriggerPendingLrDetails.next();
        $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
        this.dtTriggerPfAssignedJobDetails.next();
        this.changeDetectorRef.detectChanges();
    }
    gridTransferAllToAssg() {
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
            this.modelAlreadyExist = null;
            this.modelAll = null;
            //Pending
            this.modelAll = (this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === this.pendingLrsDataListForSearch[i].lrNumber));
            if (this.pfAssignedJobDetailsDataList.length > 0) {
                this.modelAlreadyExist = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === this.pendingLrsDataListForSearch[i].lrNumber));
            }
            if ((this.modelAlreadyExist == null || this.modelAlreadyExist == undefined) && this.modelAll != null) {
                this.countOFMemosCollectionTable = this.countOFMemosCollectionTable - 1;
                this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable - +(this.pendingLrsDataListForSearch[i].grandTotal);
                if (this.setAssingedDetails == 'new') {
                    this.dtTriggerPfAssignedJobDetails.next();
                    this.setAssingedDetails = 'old';
                }
                this.pfAssignedJobDetailsDataList.push(this.pendingLrsDataListForSearch[i]);
                this.tempstore.push(this.pendingLrsDataListForSearch[i]);
                this.tempStoreForAllTrx.push(this.pendingLrsDataListForSearch[i]);
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pendingLrsDataListForSearch[i].grandTotal;
            }
        }
        for (let i = 0; i < this.tempStoreForAllTrx.length; i++) {
            this.pendingLrsDataListForSearch.splice(this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === this.tempStoreForAllTrx[i]), 1);
        }
        $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
        this.dtTriggerPendingLrDetails.next();
        $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
        this.dtTriggerPfAssignedJobDetails.next();
        this.changeDetectorRef.detectChanges();
    }

    gridTransferSelectedToAssg() {
        for (let i = 0; i < this.pendingLrsDataForSearchSelectedList.length; i++) {
            this.pfAssignedJobDetailsDataList.push(this.pendingLrsDataForSearchSelectedList[i]);
        }
        for (let i = 0; i < this.pendingLrsDataForSearchSelectedIndex.length; i++) {
            let lrNumber1 = this.pendingLrsDataForSearchSelectedList[i].lrNumber;
            for (let j = 0; j < this.pendingLrsDataListForSearch.length; j++) {
                let lrNumber2 = this.pendingLrsDataListForSearch[j].lrNumber;
                if (lrNumber1 == lrNumber2) {
                    let index = this.pendingLrsDataListForSearch.findIndex(x => x.lrNumber === this.pendingLrsDataForSearchSelectedList[i].lrNumber);
                    this.pendingLrsDataListForSearch.splice(index, 1);
                }
            }
        }
        $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerPendingLrDetails.next();
        }, 100);

        $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerPfAssignedJobDetails.next();
        }, 100);
        this.pendingLrsDataForSearchSelectedList = [];
        this.pendingLrsDataForSearchSelectedIndex = [];
    }

    // gridTransferSelectedToAssg() {
    //     this.countOfAssignLR = 0;
    //     this.sumOfAssignLR = 0;
    //     for (let i = 0; i < this.pendingLrsDataForSearchSelectedList.length; i++) {
    //         this.modelAlreadyExist = null;
    //         this.modelAll = null;
    //         this.modelAll = (this.pendingLrsDataForSearchSelectedList.find(pendingLrsDataForSearchSelected => pendingLrsDataForSearchSelected.lrNumber === this.pendingLrsDataForSearchSelectedList[i].lrNumber));
    //         if (this.pfAssignedJobDetailsDataList.length > 0) {
    //             this.modelAlreadyExist = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === this.pendingLrsDataForSearchSelectedList[i].lrNumber));
    //         }
    //         if ((this.modelAlreadyExist == null || this.modelAlreadyExist == undefined) && this.modelAll != null) {
    //             this.countOFMemosCollectionTable = this.countOFMemosCollectionTable - 1;
    //             this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable - +(this.pendingLrsDataForSearchSelectedList[i].grandTotal);
    //             if (this.setAssingedDetails == 'new') {
    //                 this.dtTriggerPfAssignedJobDetails.next();
    //                 this.setAssingedDetails = 'old';
    //             }
    //             this.pfAssignedJobDetailsDataList.push(this.pendingLrsDataForSearchSelectedList[i]);
    //             this.tempstore.push(this.pendingLrsDataForSearchSelectedList[i]);
    //             this.tempStoreForAllTrx.push(this.pendingLrsDataForSearchSelectedList[i]);
    //             this.countOfAssignLR = +this.countOfAssignLR + 1;
    //             this.sumOfAssignLR = +this.sumOfAssignLR + +this.pendingLrsDataForSearchSelectedList[i].grandTotal;
    //         }
    //     }
    //     for (let i = 0; i < this.tempStoreForAllTrx.length; i++) {
    //         this.pendingLrsDataListForSearch.splice(this.pendingLrsDataListForSearch.find(pendingLrsDataForSearchSelected => pendingLrsDataForSearchSelected.lrNumber === this.tempStoreForAllTrx[i]), 1);
    //         this.pendingLrsDataForSearchSelectedList.splice(this.pendingLrsDataForSearchSelectedList.find(pendingLrsDataForSearchSelected => pendingLrsDataForSearchSelected.lrNumber === this.tempStoreForAllTrx[i]), 1);
    //     }
    //     //$("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
    //     this.dtTriggerPendingLrDetails.next();
    //     $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
    //     this.dtTriggerPfAssignedJobDetails.next();
    //     this.changeDetectorRef.detectChanges();
    //     this.pendingLrsDataForSearchSelectedList = [];
    // }

    //For action type finish 
    callForClosingCollection() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        //        this.completedDate=
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();

        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            swal({
                title: "Mandetory",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Closing Collection",
                text: "Are U sure want to close the collection of " + this.selectedCollectionMan,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((finishMemo) => {
                if (finishMemo) {
                    //Pending
                    this.userDataDtoForLRFinish = new UserDataDto();
                    this.selectedCollectionMan = this.enteredCollectionMan;
                    this.userDataDtoForLRFinish.collectioMan = this.selectedCollectionMan;
                    this.userDataDtoForLRFinish.updatedBy = this.userDataDtoReturnSession.userId;
                    this.userDataDtoForLRFinish.city = this.userDataDtoReturnSession.mainStation;
                    this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
                    //                    console.log( this.enteredCompletedOnDate );
                    this.userDataDtoForLRFinish.completedOn = this.enteredCompletedOnDate;
                    this.userDataDtoForLRFinish.companyId = this.userDataDtoReturnSession.companyId;
                    this.updateCloseCollectionMethod(this.userDataDtoForLRFinish);
                }

            });
        }

    }


    updateCloseCollectionMethod = (userDataDtoForLRFinish) => {
        this.memoService.updateCloseCollectionService(userDataDtoForLRFinish).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Server Error ",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.updateCloseCollection = '';
                } else {
                    this.updateCloseCollection = response;
                    if (this.updateCloseCollection.status == 'success') {
                        swal({
                            title: "Success",
                            text: "Success",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.clearFinish();
                    } else {
                        swal({
                            title: "Error",
                            text: "Server Error ",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                    this.updateCloseCollection = '';
                    this.tempstore = [];
                    this.pfAssignedJobDetailsDataList = [];
                    this.clearAllAfterBtnFinish();

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    clearFinish() {
        this.userDataDtoForLRFinish = new UserDataDto();
        this.selectedCollectionMan = '';
        this.enteredCompletedOnDate = '';
        $("#" + this.pageId + "completedOnDate").val('');
        this.selectTodayCompletedOnDate();
        this.tpNoView = false;
    }
    reloadConsignee() {
        //this.getConsigneeDetails();
    }
    reloadBank() {
        this.getPartyBankDetailsService();
    }
    getMemoNumberForEntryLess(e, LessType: string) {
        if (e.keyCode == 13) {
            this.commonValidationsOnMemoEnter("manual");
        }
    }

    validateLessAmount(e) {
        if (e.keyCode == 13) {
            if (this.nextEntryType == 'Payment Entry') {
                this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
                if (this.enteredLessAmt == '' || this.enteredLessAmt == null) {
                    swal({
                        title: "Not Allowed",
                        text: "Please Enter Amount",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.enteredLessAmt = '';
                    this.commonValidationsOnMemoEnter("paymentLess");
                }

            } else if (this.nextEntryType == 'Less Entry') {
                this.enteredLessAmt = $("#" + this.pageId + "entryLessAmount").val();
                if (this.enteredLessAmt == '' || this.enteredLessAmt == null) {
                    swal({
                        title: "Not Allowed",
                        text: "Please Enter Amount",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.enteredLessAmt = '';
                    this.commonValidationsOnMemoEnter("amtLess");
                }

            }
        }
    }

    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsignorName = e.item;
        this.selectedConsignorId = this.modelConsignorName.consignorId;
        window.setTimeout(function () {
            $("#pmtfbctotalLess").focus();
        }, 100);
    }

    rowSelectedConsignorSearchView(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsignorNameSearchView = e.item;
        this.selectedConsignorIdSearchView = this.modelConsignorNameSearchView.consignorId;
        this.selectedTopartyAddress = this.modelConsignorNameSearchView.address;
        this.selectedTopartyGst = this.modelConsignorNameSearchView.gstNoConsignor;
    }

    commonValidationsOnMemoEnter(keyEnter) {
        this.tempStoreDup = new CashMemoDto();
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedMemoNumberForAutomatic = '';
        this.selectedActionType = $("#" + this.pageId + "actionType").val();

        if (this.nextEntryType == 'Less Entry') {
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLess").val();
            this.selectedConsignor = $("#" + this.pageId + "consignorName").val();
            this.selectedTypeLess = $("#" + this.pageId + "lessType").val();
            this.memoNum = this.selectedMemoNumberForAutomatic.toUpperCase();
        } else if (this.nextEntryType == 'Payment Entry') {
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
            this.selectedConsignor = $("#" + this.pageId + "consigneeNameForPayment").val();
            this.memoNum = this.selectedMemoNumberForAutomatic.toUpperCase();
        }
        this.searchedMemoForMemoExistInTable = this.searchedMemoForMemoExistInTableForLess(this.memoNum);

        if (this.searchedMemoForMemoExistInTable.lrNumber == null || this.searchedMemoForMemoExistInTable.lrNumber == '') {
            this.modelForLessMaintain = '';
        } else {
            this.modelForLessMaintain = this.searchedMemoForMemoExistInTable;
        }
        if (this.modelForLessMaintain == null || this.modelForLessMaintain == '') {
            swal({
                title: "Not Allowed",
                text: "LR Number " + this.memoNum + " does not exist!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.nextEntryType != '' && this.nextEntryType == 'Less Entry') {
            this.isDuplicateLess = false;
            const result = this.lessEntriesDataTableDataList.filter(lessEntriesDataTableData => lessEntriesDataTableData.chqType === this.memoNum);
            this.letValueofMemoExistInLessEntryTableAny = [];
            this.letValueofMemoExistInLessEntryTableAny = result;
            for (let i = 0; i < this.letValueofMemoExistInLessEntryTableAny.length; i++) {
                if (this.letValueofMemoExistInLessEntryTableAny != null
                    && this.letValueofMemoExistInLessEntryTableAny[i].chqNumber == this.selectedTypeLess
                    && !this.isEdit) {
                    this.isDuplicateLess = true;
                    swal({
                        title: "Not Allowed",
                        text: "Duplicate Less Entry LR Number '" + this.memoNum + "' - Less Type '" + this.selectedTypeLess + "'",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    break;
                }
            }

            if (!this.isDuplicateLess) {
                this.lessSpecifcValidations(this.modelForLessMaintain, this.memoNum, keyEnter);
            }
        } else if (this.nextEntryType != '' && this.nextEntryType == 'Payment Entry') {
            const paymentTableCheck = this.paymentAmountEntriesDataList.filter(paymentAmountEntriesData => paymentAmountEntriesData.chqType === this.memoNum);
            this.letValueofMemoExistInPaymentEntryTableAny = [];
            this.letValueofMemoExistInPaymentEntryTableAny = paymentTableCheck;
            if (this.isEdit == false && this.letValueofMemoExistInPaymentEntryTableAny.length > 0) {
                swal({
                    title: "Not Allowed",
                    text: "Duplicate Entry for the LR Number " + this.memoNum,
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.paymentSpecifcValidations(keyEnter);
            }
        }
    }



    searchedMemoForMemoExistInTableForLess(selectedNum: string) {
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInCollectionManTableForEntryLess = new CashMemoDto();
        } else {
            this.getsearchInCollectionManTableForEntryLess = this.enteredSearchInAssignedTableFoEnryLess(selectedNum)
        }
        return this.getsearchInCollectionManTableForEntryLess;
    }
    enteredSearchInAssignedTableFoEnryLess(selectedNum: string): CashMemoDto {
        this.letValueofSearchInAssignedTableFoEntryLess = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === selectedNum));
        if (this.letValueofSearchInAssignedTableFoEntryLess == null || this.letValueofSearchInAssignedTableFoDup == undefined) {
            this.letValueofSearchInAssignedTableFoEntryLess = new CashMemoDto();
        } else {
            this.letValueofSearchInAssignedTableFoEntryLess = this.letValueofSearchInAssignedTableFoEntryLess;
        }
        return this.letValueofSearchInAssignedTableFoEntryLess;
    }

    /// For checking the memo number exist in Less Entry table befor adding the new row 
    lessSpecifcValidations(modelForLessMaintain, memoNum, keyEnter) {
        let discount = 0.0;
        discount = modelForLessMaintain.discount == null ? 0.0 : modelForLessMaintain.discount;
        if (discount > 0) {
            const result = this.lessEntriesDataTableDataList.filter(lessEntriesDataTableData => lessEntriesDataTableData.chqType === memoNum);
            this.maintenanceDiscArray = [];
            if (result == undefined) {
                this.maintenanceDiscArray = [];
            } else {
                this.maintenanceDiscArray = result;
            }
            if (this.maintenanceDiscArray.length == 0) {
                swal({
                    title: "Already discounted",
                    text: "Discount already given for the LR Number " + memoNum + ", are you want to give additional less?",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((lessType) => {
                    if (lessType) {
                        if (keyEnter != null && keyEnter == 'amtLess') {
                            this.lessEntry();
                        } else {
                            window.setTimeout(function () {
                                $("#pmtfbclessType").focus();
                            }, 100);
                        }
                    } else {
                        $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
                        $("#" + this.pageId + "lessType").val('');
                        $("#pmtfbcmemoNumberForAutomaticLess").focus();
                    }
                });

            } else {
                if (keyEnter != null && keyEnter == 'amtLess') {
                    this.lessEntry();
                } else {
                    window.setTimeout(function () {
                        $("#pmtfbclessType").focus();
                    }, 100);
                }
            }
        } else {
            if (keyEnter != null && keyEnter == 'amtLess') {
                this.lessEntry();
            } else {
                window.setTimeout(function () {
                    $("#pmtfbclessType").focus();
                }, 100);
            }
        }
    }
    lessEntry() {
        this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();
        this.enteredLessAmt = $("#" + this.pageId + "entryLessAmount").val();
        this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
        this.totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.totalBalAmt = this.balanceAmountFinal;
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;

        if (this.isEdit) {
            //Pending
            this.clickedRowAmt = this.maintenanceCompletedLessLrs.chqAmount;
        }
        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            this.enteredAmt = +this.enteredAmt + +this.lessEntriesDataTableDataList[i].chqAmount;
        }
        this.enteredAmt = +this.enteredAmt + +this.enteredLessAmt;
        // deduct the clicked row when edit
        this.enteredAmt = +this.enteredAmt - +this.clickedRowAmt;
        // console.log( this.enteredAmt, this.totalAmt, this.totalBalAmt );
        if (this.enteredTotalLess == null || this.enteredTotalLess == '') {
            swal({
                title: "Not Allowed",
                text: "Please enter the total Less amount ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.enteredAmt > this.totalAmt) {
            swal({
                title: "Not Allowed",
                text: "Total entered amount exceed from the actual Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else if (this.enteredAmt > this.totalBalAmt) {
            swal({
                title: "Not Allowed",
                text: "Amount exceeded from the actual Bill Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#" + this.pageId + "entryLessAmount").val('');
        } else {
            this.insertRowLessEntry();
        }
    }

    validateTotalLess(e) {
        if (e.keyCode == 13) {
            if (this.nextEntryType == 'Less Entry') {
                if (!this.validateAmtWithBillBalTotal()) {
                    window.setTimeout(function () {
                        $("#pmtfbcmemoNumberForManualLess").focus();
                    }, 100);
                }
            } else if (this.nextEntryType == 'Payment Entry') {
                if (!this.validateAmtWithBillBalTotal()) {
                    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
                    if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                        window.setTimeout(function () {
                            $("#pmtfbctwoThousandRupeeCount").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                        window.setTimeout(function () {
                            $("#pmtfbcchequeNumber").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                        window.setTimeout(function () {
                            $("#" + this.pageId + "consigneeBankName").val('');
                            $("#" + this.pageId + "newBankName").val('');
                            $("#pmtfbcconsigneeBankName").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                        window.setTimeout(function () {
                            $("#" + this.pageId + "consigneeBankName").val('');
                            $("#" + this.pageId + "newBankName").val('');
                        }, 100);
                    }
                }
            }
        }
    }

    validateAmtWithBillBalTotal() {
        if (this.nextEntryType == 'Less Entry') {
            this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();
        } else if (this.nextEntryType == 'Payment Entry') {
            this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();
        }
        this.totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.totalBalAmt = this.balanceAmountFinal;
        if (this.isPaymentClicked) {
            return false;
        } else {
            if (this.totalAmt > this.totalBalAmt) {
                swal({
                    title: "Not Allowed",
                    text: "Total amount exceed from the actual Bill Balance Amount, please verify!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                $("#" + this.pageId + "totalLess").val('');
                return true;
            } else {
                return false;
            }
        }
    }
    insertRowLessEntry() {
        this.memoNum = '';
        this.selectedMemoNumberForAutomatic = '';
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLess").val();
        this.memoNum = this.selectedMemoNumberForAutomatic.toUpperCase();

        const checkAssignedTable = this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.lrNumber === this.memoNum);
        this.letValueofMemoFrmAssingedTableAny = [];
        if (checkAssignedTable == undefined) {
            this.letValueofMemoFrmAssingedTableAny = [];
        } else {
            this.letValueofMemoFrmAssingedTableAny = checkAssignedTable
        }
        this.addInLessEntryTable(this.letValueofMemoFrmAssingedTableAny, this.memoNum);
    }

    addInLessEntryTable(modelForLessMaintain, memoNum) {
        this.newAttribute.chqType = memoNum;
        this.newAttribute.chqNumber = $("#" + this.pageId + "lessType").val();
        this.newAttribute.chqAmount = $("#" + this.pageId + "entryLessAmount").val();
        this.newAttribute.cashMemoAmt = modelForLessMaintain.grandTotal;
        this.newAttribute.balance = 0.0;

        if (this.editTableId != null) {
            this.lessEntriesDataTableDataList.splice(this.editTableId, 1);
            this.countOfLessLR = +this.countOfLessLR - 1;
            this.sumOfLessAmt = +this.sumOfLessAmt - +this.editlessamount;
        }
        if (this.lessEntriesDataTableDataList == null) {
            this.lessEntriesDataTableDataList = [];
        }
        if (this.setLessEntryDetails == 'new') {
            this.dtTriggerLessEntriesDataTable.next();
            this.setLessEntryDetails = 'old';
        }
        if (this.lessEntriesDataTableDataList.length == 0) {
            this.lessEntriesDataTableDataList.push(this.newAttribute);
        } else {
            this.lessEntriesDataTableDataList.push(this.newAttribute);
        }
        this.countOfLessLR = +this.countOfLessLR + 1;
        this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttribute.chqAmount;

        this.newAttribute = {};
        this.isEdit = false;
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
        $("#pmtfbcmemoNumberForAutomaticLess").focus();
        $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
        this.editTableId = null;
        ///Pending 
        this.maintenanceCompletedLessLrs = [];
        this.editlessamount = 0;
        this.lrDtoForGroupStationRet = '';
        this.lrDtoForGroupStationSet = new LRDto();
        this.controlEntryLessType.reset();
    }

    callForCompleteCollection() {
        if (this.chkPendingMemosExist()) {
            //Pending
            this.removePendingMemos();
        } else {
            //Pending
            this.getTotalAmtOfCompleteLrs();
            this.getConsignorWiseBalanceDetails();
        }
    }
    getConsignorWiseBalanceDetails() {
        this.consignorWiseBalancelDto = new CashMemoDto();
        this.consignorWiseBalancelDto.consigneeWiseMemo = this.pfAssignedJobDetailsDataList;
        this.consignorWiseBalancelDto.consigneeWiseMode = "new";
        console.log('getConsignorWiseBalanceDetails');
        this.getConsignorWiseBalanceInfo();
    }
    getConsignorWiseBalanceInfo = () => {
        console.log('getConsignorWiseBalanceDetails -1');
        this.memoService.getConsignorWiseBalanceDet(this.consignorWiseBalancelDto).subscribe(
            (response) => {
                if (response.length == 0) {
                } else {
                    this.consignorWiseBalancelRet = response;
                    this.setValInConsignorWiseBalanceDetails();
                    if (this.consigneeWiseTrigger == 'new') {
                        this.dtTriggerConsignorWiseBalanceDetails.next();
                        this.consigneeWiseTrigger = 'old'
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    setValInConsignorWiseBalanceDetails() {
        this.consignorWiseBalanceDetailsDataList = [];
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;


        for (let key in this.consignorWiseBalancelRet.hashMapConsigneeBalance) {
            //Whatever you want to do with key or obj[key]
            //  console.log( key );
            //  console.log( this.consignorWiseBalancelRet.hashMapConsigneeBalance[key] );
            if (key != '') {
                this.newAttributeForConsignorWiseBalance = {};
                this.newAttributeForConsignorWiseBalance.consignor = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].consignor;
                this.newAttributeForConsignorWiseBalance.totalCashMemo = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].size;
                this.newAttributeForConsignorWiseBalance.grandTotal = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].grandTotal;
                this.newAttributeForConsignorWiseBalance.partyLessDed = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].less;
                this.newAttributeForConsignorWiseBalance.advChqAmt = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].chequeAmt;
                this.newAttributeForConsignorWiseBalance.neftAmt = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].neftAmt;
                this.newAttributeForConsignorWiseBalance.balance = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].balanceAmt;
                this.newAttributeForConsignorWiseBalance.consignorId = this.consignorWiseBalancelRet.hashMapConsigneeBalance[key].consignorId;
                this.countOfConsingeeWiseMemo = +this.countOfConsingeeWiseMemo + 1;
                this.sumOfConsingeeWiseTotalCashMemo = +this.sumOfConsingeeWiseTotalCashMemo + +this.newAttributeForConsignorWiseBalance.totalCashMemo;
                this.sumOfConsingeeWiseGrandTotal = +this.sumOfConsingeeWiseGrandTotal + +this.newAttributeForConsignorWiseBalance.grandTotal;
                this.sumOfConsingeeWisePartyLessDed = +this.sumOfConsingeeWisePartyLessDed + +this.newAttributeForConsignorWiseBalance.partyLessDed;
                this.sumOfConsingeeWiseAdvChqAmt = +this.sumOfConsingeeWiseAdvChqAmt + +this.newAttributeForConsignorWiseBalance.advChqAmt;
                this.sumOfConsingeeWiseNeftAmtl = +this.sumOfConsingeeWiseNeftAmtl + +this.newAttributeForConsignorWiseBalance.neftAmt;
                this.sumOfConsingeeWiseBalance = +this.sumOfConsingeeWiseBalance + +this.newAttributeForConsignorWiseBalance.balance;

                this.consignorWiseBalanceDetailsDataList.push(this.newAttributeForConsignorWiseBalance);
            }
        }
    }

    updateConsignorBalanceDetails() {
        this.getConsignorWiseBalanceUpdateDetails();
    }
    getConsignorWiseBalanceUpdateDetails() {
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseMemo = this.pfAssignedJobDetailsDataList;
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseBalanceDtails = this.consignorWiseBalanceDetailsDataList;
        this.consigneeWiseBalancelDtoForUpdate.listLessDetails = this.userDataDtosLessTemp;
        this.consigneeWiseBalancelDtoForUpdate.listChqDetails = this.userDataDtosPaymentChqTemp;

        this.consigneeWiseBalancelDtoForUpdate.listNEFTDetails = this.userDataDtosPaymentNeftTemp;
        this.consigneeWiseBalancelDtoForUpdate.listUpiDetails = this.userDataDtosPaymentUpiTemp;
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseMode = "update";
        //console.log( this.userDataDtosLessTemp, this.userDataDtosPaymentChqTemp );
        this.getConsignorWiseBalanceUpdate();
    }
    getConsignorWiseBalanceUpdate = () => {
        console.log('getConsignorWiseBalanceUpdate');
        this.memoService.getConsignorWiseBalanceDet(this.consigneeWiseBalancelDtoForUpdate).subscribe(
            (response) => {
                if (response.length == 0) {

                } else {
                    this.consignorWiseBalancelRet = [];
                    this.consignorWiseBalancelRet = response;
                    this.setValInConsignorWiseBalanceDetails();
                    if (this.consigneeWiseTrigger == 'new') {
                        this.dtTriggerConsignorWiseBalanceDetails.next();
                        this.consigneeWiseTrigger = 'old'
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    clearConsigneewiseDetails() {
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;
        this.consignorWiseBalanceDetailsDataList = [];
        this.consignorWiseBalancelRet = [];
        this.newAttributeForConsignorWiseBalance = {};
        this.consignorWiseBalancelDto = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();

    }

    getTotalAmtOfCompleteLrs() {
        let totAmt = 0.0;
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            totAmt = +totAmt + +this.pfAssignedJobDetailsDataList[i].grandTotal;
        }
        this.balanceAmountFinal = totAmt;
        this.amountToBeCollected = totAmt;
        //  console.log( this.balanceAmountFinal, this.amountToBeCollected );
    }



    rowSelectedEditForLessEntry(lessEntriesDataTableData, index) {
        $("#" + this.pageId + "lessType").val(lessEntriesDataTableData.chqNumber);
        $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.chqAmount);
        //For manual 
        let selectedMemo = lessEntriesDataTableData.chqType;
        this.slashSplitMemo = selectedMemo.split("/");
        let destSplit = this.slashSplitMemo[0];
        let yearSplit = this.slashSplitMemo[1];
        let memoNumberSplit = this.slashSplitMemo[2];
        //   console.log( selectedMemo, this.slashSplitMemo, destSplit, yearSplit, memoNumberSplit );
        let memoNumberCombine = destSplit + "/" + yearSplit + "/";

        //        $("#"+this.pageId+"lessType" ).val( lessEntriesDataTableData.chqNumber );
        $("#" + this.pageId + "codeForManualLess").val(memoNumberCombine);
        $("#" + this.pageId + "memoNumberForManualLess").val(memoNumberSplit);
        this.modelStationForLessAmt = memoNumberCombine;

        //////
        //For Automatic
        $("#" + this.pageId + "memoNumberForAutomaticLess").val(lessEntriesDataTableData.chqType);
        /////

        $("#" + this.pageId + "hiddenIndexForLessEntry").val(index);
        this.editTableId = index;
        ///
        this.maintenanceCompletedLessLrs = [];
        this.maintenanceCompletedLessLrs = lessEntriesDataTableData;
        //   console.log( this.maintenanceCompletedLessLrs );
        this.isEdit = true;

        //Set as manual as per old logic
        $("#" + this.pageId + "entryLessEntryType").val('entryLessManual');
        this.entryLessManualView = true;
        this.entryLessAutomaticView = false;
        if (this.nextEntryType == 'Payment Entry') {
            $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.balance);
        } else {
            $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.chqAmount);
        }
        this.editlessamount = 0;
        this.editlessamount = lessEntriesDataTableData.chqAmount;
        $("#" + this.pageId + "consignorName").val(lessEntriesDataTableData.consignor);
        this.selectedConsignorId = lessEntriesDataTableData.consignorId;
        //   console.log( this.selectedConsignorId );
    }
    rowSelectedDeleteForLessEntry(lessEntriesDataTableData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected less entry",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                //  console.log( this.lessEntriesDataTableDataList.length );
                this.lessEntriesDataTableDataList.splice(index, 1);
                $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
                //  console.log( this.lessEntriesDataTableDataList.length );
                this.countOfLessLR = +this.countOfLessLR - 1;
                this.sumOfLessAmt = +this.sumOfLessAmt - +lessEntriesDataTableData.chqAmount;
                $("#" + this.pageId + "entryLessAmount").val('');
                $("#" + this.pageId + "lessType").val('');
                $("#" + this.pageId + "memoNumberForManualLess").val('');
                $("#pmtfbcmemoNumberForManualLess").focus();
                this.editTableId = null;
                this.maintenanceCompletedLessLrs = [];
                this.isEdit = false;
                this.controlEntryLessType.reset();
            }

        });
    }
    //    for less entry starts

    toggleEditableForTDS(event, contentLessEntryTds) {
        if (event.target.checked) {
            this.openLessEntryTdsPopup(contentLessEntryTds)
        } else {
            this.resetTDSBulkFields();
        }
    }

    // TDS Process starts form here
    openLessEntryTdsPopup(contentLessEntryTds) {
        this.selectedConsignor = $("#" + this.pageId + "consignorName").val();
        if (this.selectedConsignor == '' || this.selectedConsignor == null) {
            swal({
                title: "Mandatory Field",
                text: "Please select Consignor Name to get TDS Bulk details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            //Pending
            $("#" + this.pageId + "checkboxTDS").prop("checked", false);

        } else {
            this.isDuplicateBulkLess = false;
            for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
                let consignorId = this.lessEntriesDataTableDataList[i].consignorId;
                let lessDesc = this.lessEntriesDataTableDataList[i].chqNumber;
                if ((this.selectedConsignorId == consignorId)
                    && (lessDesc == 'TDS')) {
                    this.isDuplicateBulkLess = true;
                    break;
                }
            }

            if (this.isDuplicateBulkLess) {
                swal({
                    title: "Not Allowed",
                    text: "TDS Less entry is already done for this Consignor Memo, please verify again!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                this.resetTDSBulkFields();
            } else {
                this.tdsBulkEntryflow();
                this.modalService.open(contentLessEntryTds, { centered: true }).result.then(
                    resultContent => {
                        this.closeResultContentLessEntryTds = `Closed with: ${resultContent}`;
                    },
                    reason => {
                        this.closeResultContentLessEntryTds = `Dismissed ${this.getDismissReason(reason)}`;
                    }
                );
            }
        }

    }

    resetTDSBulkFields() {
        $("#" + this.pageId + "totalMemoForTDS").val('');
        $("#" + this.pageId + "memoAmountForTDS").val('');
        $("#" + this.pageId + "tdsLessTwoPer").val('');
        $("#" + this.pageId + "lessForTDS").val('');
        //Pending
        $("#" + this.pageId + "checkboxTDS").prop("checked", false);
        //        need to clear the cashMemoDtoForTDSRet is  Pending
        this.cashMemoDtoForTDSRet = [];
    }

    tdsBulkEntryflow() {
        this.tdsBulkTotMemoAmt = 0.0;
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            let consignorId = this.pfAssignedJobDetailsDataList[i].consignorId;
            if (consignorId == this.selectedConsignorId) {
                this.tdsBulkTotMemoAmt = +this.tdsBulkTotMemoAmt + +this.pfAssignedJobDetailsDataList[i].grandTotal;
                this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].lrNumber);
            }
        }
        if (this.listOfMemoNumber.length == 0) {
            swal({
                title: "TDS Bulk Entry",
                text: "No LRs to calculate TDS bulk details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.resetTDSBulkFields();
        } else {
            this.cashMemoDtoForTDS = new CashMemoDto();
            this.cashMemoDtoForTDS.list = this.listOfMemoNumber;
            // this.calculateTDSAmount();
            this.getTotLessAmt(this.cashMemoDtoForTDS);
        }
    }

    getTotLessAmt = (cashMemoDtoForTDS) => {
        this.memoService.getTotalMemoLessAmountBkg(cashMemoDtoForTDS).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "TDS Bulk Entry",
                        text: "Server problem occurred while calculating the TDS bulk details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.cashMemoDtoForTDS = new CashMemoDto();
                } else {
                    this.cashMemoDtoForTDSRet = response;
                    let tdsBulkAmt = 0.0;
                    let less = 0.0;
                    for (let i = 0; i < this.cashMemoDtoForTDSRet.length; i++) {
                        let balAfterDedLess = +this.cashMemoDtoForTDSRet[i].grandTotal - +this.cashMemoDtoForTDSRet[i].less;
                        let calTds = Math.round((balAfterDedLess * this.tdsLessMulti) / 100);
                        tdsBulkAmt = +tdsBulkAmt + +calTds;
                        less = +less + +this.cashMemoDtoForTDSRet[i].less;
                    }
                    $("#" + this.pageId + "lessForTDS").val(less);
                    $("#" + this.pageId + "tdsLessTwoPer").val(tdsBulkAmt);
                    $("#" + this.pageId + "totalMemoForTDS").val(this.listOfMemoNumber.length);
                    $("#" + this.pageId + "memoAmountForTDS").val(this.tdsBulkTotMemoAmt);
                    this.tdsBulkTotMemoAmt = 0.0;
                    this.listOfMemoNumber = [];
                    tdsBulkAmt = 0.0;
                    less = 0.0;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    // calculateTDSAmount() {
    //     console.log('calculateTDSAmount-1');
    //     this.memoAmountForTDS = this.tdsBulkTotMemoAmt;
    //     this.tdsLessTwoPer = 0.0;
    //     this.totalMemoForTDS = this.listOfMemoNumber.length;

    //     let calTds = Math.round((this.tdsBulkTotMemoAmt * this.tdsLessMulti) / 100);
    //     this.tdsLessTwoPer = +this.tdsLessTwoPer + +calTds;


    //     console.log(this.memoAmountForTDS);
    //     console.log(this.totalMemoForTDS);
    //     console.log(this.tdsLessTwoPer);
    //     this.tdsBulkTotMemoAmt = 0.0;
    //     this.listOfMemoNumber = [];
    // }

    tdsInsert() {
        swal({
            title: "Confirm Apply",
            text: "Sure you want to apply the TDS bulk entries?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((insert) => {
            if (insert) {
                this.tdsBulkEntryInsert();
                this.resetTDSBulkFields();
            }
        });
    }

    uncheck() {
        this.resetTDSBulkFields();
    }

    tdsBulkEntryInsert() {
        console.log('tdsBulkEntryInsert');
        console.log(this.cashMemoDtoForTDSRet);
        console.log(this.cashMemoDtoForTDSRet.length);
        console.log(this.lessEntriesDataTableDataList);
        for (let i = 0; i < this.cashMemoDtoForTDSRet.length; i++) {
            console.log('tdsBulkEntryInsert inside for');
            console.log(this.cashMemoDtoForTDSRet);
            let balAfterDedLess = +this.cashMemoDtoForTDSRet[i].grandTotal - +this.cashMemoDtoForTDSRet[i].less;
            let calTds = Math.round((balAfterDedLess * this.tdsLessMulti) / 100);
            //            console.log(balAfterDedLess);
            //            console.log(calTds);
            this.newAttributeForTDS.chqType = this.cashMemoDtoForTDSRet[i].memoNumber;
            this.newAttributeForTDS.chqNumber = "TDS";
            this.newAttributeForTDS.chqAmount = calTds;
            this.newAttributeForTDS.cashMemoAmt = this.cashMemoDtoForTDSRet[i].grandTotal;
            this.newAttributeForTDS.balance = 0.0;
            this.newAttributeForTDS.mainStation = "NA";
            this.newAttributeForTDS.consignorId = this.cashMemoDtoForTDSRet[i].consigneeId;
            this.newAttributeForTDS.consignor = this.cashMemoDtoForTDSRet[i].consigneeName;

            if (this.lessEntriesDataTableDataList == null) {
                this.lessEntriesDataTableDataList = [];
            }

            if (this.lessEntriesDataTableDataList.length == 0) {
                this.lessEntriesDataTableDataList.push(this.newAttributeForTDS);
            } else {
                this.lessEntriesDataTableDataList.push(this.newAttributeForTDS);
            }
            this.countOfLessLR = +this.countOfLessLR + 1;
            this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttributeForTDS.chqAmount;
            this.newAttributeForTDS = {};
            this.cashMemoDtoForTDS = new CashMemoDto();
        }
        console.log(this.lessEntriesDataTableDataList);
    }

    calculateAllTotalAndBalance() {
        this.calculateTotalLessWithDifferentTypes();
        this.calculateTotalChequePayment();
        this.calculateTotalNeftPayment();
        this.calculateTotalUpiPayment();
        this.calculateTotalCashPayment();

        let totalAmt = 0.0, totalLess = 0.0, totalChq = 0.0, totalNeft = 0.0, totalCash = 0.0, balanceAmt = 0.0, totalUpi = 0.0;
        totalAmt = this.amountToBeCollected == null ? 0.0 : this.amountToBeCollected;
        totalLess = this.lblTotLessSummaryValue == null ? 0.0 : this.lblTotLessSummaryValue == '' ? 0.0 : this.lblTotLessSummaryValue;
        totalChq = this.lblTotChqAmtSummaryValue == null ? 0.0 : this.lblTotChqAmtSummaryValue == '' ? 0.0 : this.lblTotChqAmtSummaryValue;
        totalNeft = this.lblTotNeftAmtSummaryValue == null ? 0.0 : this.lblTotNeftAmtSummaryValue == '' ? 0.0 : this.lblTotNeftAmtSummaryValue;
        totalUpi = this.lblTotUpiAmtSummaryValue == null ? 0.0 : this.lblTotUpiAmtSummaryValue == '' ? 0.0 : this.lblTotUpiAmtSummaryValue;
        totalCash = this.lblTotCashAmtSummaryValue == null ? 0.0 : this.lblTotCashAmtSummaryValue == '' ? 0.0 : this.lblTotCashAmtSummaryValue;
        balanceAmt = +totalAmt - +(+totalLess + +totalChq + +totalNeft + +totalUpi + +totalCash);
        this.balanceAmountFinal = balanceAmt;

    }
    calculateTotalLessWithDifferentTypes() {
        let amtLess = 0.0, amtLessTDS = 0.0, amtLessClaim = 0.0, amtLessSrc = 0.0;
        for (let i = 0; i < this.userDataDtosLessTemp.length; i++) {
            if (this.userDataDtosLessTemp[i].paymentType == 'TDS') {
                amtLessTDS = +amtLessTDS + +this.userDataDtosLessTemp[i].paymentAmt;
            } else if (this.userDataDtosLessTemp[i].paymentType == 'CLAIM') {
                amtLessClaim = +amtLessClaim + +this.userDataDtosLessTemp[i].paymentAmt;
            } else {
                amtLessSrc = +amtLessSrc + +this.userDataDtosLessTemp[i].paymentAmt;
            }
            amtLess = +amtLess + +this.userDataDtosLessTemp[i].paymentAmt;
        }
        this.lblLessValue = amtLessSrc;
        this.lblTDSLessValue = amtLessTDS;
        this.lblClaimLessValue = amtLessClaim;
        this.lblTotLessSummaryValue = amtLess;
    }

    calculateTotalChequePayment() {
        let amtRecChq = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++) {
            amtRecChq = +amtRecChq + +this.userDataDtosPaymentChqTemp[i].paymentAmt;
        }
        this.lblTotChqAmtSummaryValue = amtRecChq;
    }

    calculateTotalNeftPayment() {
        let amtRecNeft = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++) {
            amtRecNeft = +amtRecNeft + +this.userDataDtosPaymentNeftTemp[i].paymentAmt;
        }
        this.lblTotNeftAmtSummaryValue = amtRecNeft;
    }

    calculateTotalUpiPayment() {
        let amtRecUpi = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentUpiTemp.length; i++) {
            amtRecUpi = +amtRecUpi + +this.userDataDtosPaymentUpiTemp[i].paymentAmt;
        }
        this.lblTotUpiAmtSummaryValue = amtRecUpi;
    }

    calculateTotalCashPayment() {
        let totAmtInCash = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        totAmtInCash = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        if (this.showCashLblAmt) {
            this.lblTotCashAmtSummaryValue = this.getCashValDto.grandTotal;

        } else {
            this.lblTotCashAmtSummaryValue = totAmtInCash;
        }
    }
    // modified the logic, all the entries will be store in temp
    // dto(userDataDtosLessTemp) and will only go to DB when click on finish,
    // hence disabled the below service
    // @Dated : 10/09/2018
    updateLessDetails() {
        this.getLessLRDetails();
        swal({
            title: "Memo Less",
            text: "Less details updated temporarily, please note that it will only be save permanently when click on Finish",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
        //Pending
        this.clearLessFields();
    }
    clearLessFields() {
        this.isEdit = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.controlConsigneeName.reset();
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        this.payMode = null;
        this.payType = null;
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.controlEntryLessType.reset();
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "totalLess").val('');
        this.controlConsigneeNamePayment.reset();
        this.paymentAmountEntriesDataList = [];
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsignorId = 0;
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        //Pending still the less page values need to cleared 
        //        listStoreCompleteLrs.removeAll();
        //        gridCompleteLrs.reconfigure(listStoreCompleteLrs, cmCompleteLrs);
        //        listStoreCompleteLessLrs.removeAll();
        //        gridCompleteLessLrs.reconfigure(listStoreCompleteLessLrs,
        //                cmCompleteLessLrs);
    }

    getLessLRDetails() {
        //Pending


        if (this.isLessClicked) {

            this.userDataDtosLessTemp = [];
            this.isLessClicked = false;
        }

        this.userDataDtoForLess = [];
        //        this.userDataDtoListForLess = [];

        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            let maintenance = this.lessEntriesDataTableDataList[i];
            //      console.log( maintenance );
            this.userDataDtoForLessDetails = new UserDataDto();
            this.userDataDtoForLessDetails.memoNumber = maintenance.chqType;
            this.userDataDtoForLessDetails.paymentType = maintenance.chqNumber;
            this.userDataDtoForLessDetails.paymentAmt = maintenance.chqAmount;
            this.userDataDtoForLessDetails.updatedBy = this.userDataDtoReturnSession.userId;
            this.userDataDtoForLessDetails.bankId = 0;
            this.userDataDtoForLessDetails.office = this.userDataDtoReturnSession.mainStation;
            this.userDataDtoForLessDetails.amount = maintenance.cashMemoAmt;
            this.userDataDtoForLessDetails.consignorId = maintenance.consignorId;
            this.userDataDtoForLessDetails.partyName = maintenance.consignor;
            this.userDataDtoForLessDetails.mainStation = maintenance.mainStation;
            this.userDataDtoForLess.push(this.userDataDtoForLessDetails);
            //Pending need to check this below new dto list no need of this object userDataDtoListForLess may delete this 
            //            this.userDataDtoListForLess.push( this.userDataDtoForLessDetails );
            // add the entry details in temp file
            this.userDataDtosLessTemp.push(this.userDataDtoForLessDetails);
        }
        //    console.log( this.userDataDtoForLess );
        //  console.log( this.userDataDtosLessTemp );
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsignorId = 0;
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        return this.userDataDtoForLess;
    }
    validateTotalLessEnteredWithActulLessOnNext() {
        this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();

        let totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        let enteredAmt = 0.0;
        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            enteredAmt = +enteredAmt + +this.lessEntriesDataTableDataList[i].chqAmount;
        }
        if (enteredAmt < totalAmt) {
            swal({
                title: "Not Allowed",
                text: "Total entered less entries amount are not tallied with the Total Less, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return true;
        } else {
            return false;
        }
    }
    validateNextBtn(e) {
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        if (this.nextEntryType == 'Less Entry') {
            if (this.validateTotalLessEnteredWithActulLessOnNext()) {
            } else {
                swal({
                    title: "Confirm Changes",
                    text: "Sure you want to add the Less details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((next) => {
                    if (next) {
                        swal({
                            title: "OK",
                            text: "Please Click Next button again to continue",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.viewNextBtn = true;
                        this.showLessLbl = false;
                    }

                });
            }
        } else if (this.nextEntryType == 'Payment Entry') {
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                swal({
                    title: "Confirm Changes",
                    text: "Sure you want to add the Cash Payment details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((cash) => {
                    if (cash) {
                        this.updateCashAmt();
                        this.calculateTotalCashPayment();
                        // calculate total
                        this.calculateAllTotalAndBalance();
                    }

                });
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No Cheque/PDC Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the Cheque/PDC Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateChqDetails('Cheque/PDC');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsignorBalanceDetails();
                        }

                    });
                }
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No NEFT/IMPS Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the NEFT/IMPS Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateNeftDetails('NEFT/IMPS');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsignorBalanceDetails();
                        }

                    });
                }
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No UPI Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the UPI Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateUpiDetails('UPI');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsignorBalanceDetails();
                        }

                    });
                }
            }
        }
    }

    getMethodForgetPartyBankDetailsService() {
        this.userDataDtoForBankDetails = new UserDataDto();
        this.userDataDtoForBankDetails.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForBankDetails.companyId = this.userDataDtoReturnSession.companyId;
    }

    getPartyBankDetailsService() {
        this.getMethodForgetPartyBankDetailsService();
        this.masterReadService.getPartyBankDetailsService(this.userDataDtoForBankDetails).subscribe(
            (response) => {
                this.controlConsigneeBankName.reset();
                this.consigneeBankNameArray = [];
                this.consigneeBankNameArray = response;
                this.consigneeBankNameArrayRet = this.consigneeBankNameArray;
                this.userDataDtoForBankDetailsRet.bankName = "ADD NEW BANK";
                this.consigneeBankNameArrayRet.push(this.userDataDtoForBankDetailsRet);
                this.consigneeBankNameTA = [];
                for (let i = 0; i < this.consigneeBankNameArrayRet.length; i++) {
                    this.consigneeBankNameTA.push(this.consigneeBankNameArrayRet[i].bankName);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while getting Party Bank Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    partyBankMethod(e: NgbTypeaheadSelectItemEvent) {
        this.modelPartyBankName = e.item;
        this.selectedBankName = this.modelPartyBankName;
        if (this.selectedBankName == 'ADD NEW BANK') {
            this.viewNewBankName = true;
            $("#pmtfbcnewBankName").focus();
        } else {
            this.viewNewBankName = false;
            this.validateDuplicateChqEntry();
        }
    }
    validateDuplicateChqEntry = () => {
        this.enteredCheckNumber = $("#" + this.pageId + "chequeNumber").val();
        this.selectedBankName = this.modelPartyBankName;
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        this.enteredCheckNumber = $("#" + this.pageId + "chequeNumber").val();
        this.lrDtoForValidateChq = new LRDto();
        this.lrDtoForValidateChq.chequeNumber = this.enteredCheckNumber;
        this.lrDtoForValidateChq.bankName = this.selectedBankName;
        this.memoService.validateChqNumberWithBank(this.lrDtoForValidateChq).subscribe(
            (response) => {
                if (response != null) {
                    if (response.length == 0) {
                        swal({
                            title: "Server Error",
                            text: "Server problem occurred while Validating Cheque number",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.validateChqRet = response;
                        if (this.validateChqRet.size > 0) {
                            this.isDuplicateChq = true;
                            if (this.isDuplicateChq == true) {
                                swal({
                                    title: "Not Allowed",
                                    text: "Cheque Number " + this.enteredCheckNumber + " with Bank Name " + this.selectedBankName + " already exist, please verify!",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                });
                                $("#" + this.pageId + "chequeNumber").val('');
                            }
                        } else {
                            this.isDuplicateChq = false;
                        }
                        if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                            window.setTimeout(function () {
                                $("#pmtfbcmemoNumberForManualLessPayment").focus();
                            }, 100);
                        } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                            window.setTimeout(function () {
                                $("#pmtfbcreferenceNumber").focus();
                            }, 100);
                        } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                            window.setTimeout(function () {
                                $("#pmtfbcreferenceNumber").focus();
                            }, 100);
                        }
                    }
                } else {
                    this.isDuplicateChq = false;
                    if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                        window.setTimeout(function () {
                            $("#pmtfbcmemoNumberForManualLessPayment").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                        window.setTimeout(function () {
                            $("#pmtfbcreferenceNumber").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                        window.setTimeout(function () {
                            $("#pmtfbcreferenceNumber").focus();
                        }, 100);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    }

    fieldFocusNewBank(e) {
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        if (e.keyCode == 13) {
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                window.setTimeout(function () {
                    $("#pmtfbcpaymentLessStn").focus();
                }, 100);
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                window.setTimeout(function () {
                    $("#pmtfbcreferenceNumber").focus();
                }, 100);
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                window.setTimeout(function () {
                    $("#pmtfbcreferenceNumber").focus();
                }, 100);
            }
        }
    }
    focusChqDate(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfbcconsigneeBankName").focus();
            }, 100);
        }
    }
    focusChqDateEnter(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfbcconsigneeBankName").focus();
            }, 100);
        }
    }
    rowSelectedLessType() {

        window.setTimeout(function () {
            $("#pmtfbcentryLessAmount").focus();
        }, 100);
    }
    fieldFocusChqNo(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfbcchequeDate").focus();
            }, 100);
        }
    }
    fieldFocusRef(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfbcmemoNumberForManualLessPayment").focus();
            }, 100);
        }
    }
    validateCashWithAmount() {
        let totAmt = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        //   console.log( this.nf2000 );
        totAmt = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        //    console.log( totAmt );
        this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();
        this.enteredTotalLess = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        //    console.log( totAmt, this.enteredTotalLess );
        if (totAmt > this.enteredTotalLess) {
            swal({
                title: "Not Allowed",
                text: "Entered Cash amount exceed from the Total Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return true;
        } else {
            return false;
        }
    }

    calculateDenominationTotalAmt() {
        let totAmt = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        //   console.log( this.nf2000 );
        totAmt = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        //    console.log( totAmt );
        return totAmt;
    }

    twoThousandRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
            this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
            $("#" + this.pageId + "twoThousandRupee").val(this.nf2000Count * 2000);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoThousandRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbcfiveHundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoThousandRupeeBlur(e) {
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        $("#" + this.pageId + "twoThousandRupee").val(this.nf2000Count * 2000);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoThousandRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }

    fiveHundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
            this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
            $("#" + this.pageId + "fiveHundredRupee").val(this.nf500Count * 500);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiveHundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbctwoHundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiveHundredRupeeBlur(e) {
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0.0 : this.nf500Count;
        $("#" + this.pageId + "fiveHundredRupee").val(this.nf500Count * 500);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiveHundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    twoHundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
            this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
            $("#" + this.pageId + "twoHundredRupee").val(this.nf200Count * 200);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoHundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbchundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoHundredRupeeBlur(e) {
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        $("#" + this.pageId + "twoHundredRupee").val(this.nf200Count * 200);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoHundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);

        }
    }

    hundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
            this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
            $("#" + this.pageId + "hundredRupee").val(this.nf100Count * 100);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "hundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbcfiftyRupeeCount").focus();
                }, 100);

            }

        }
    }
    hundredRupeeBlur(e) {
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        $("#" + this.pageId + "hundredRupee").val(this.nf100Count * 100);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "hundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    fiftyRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
            this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
            $("#" + this.pageId + "fiftyRupee").val(this.nf50Count * 50);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiftyRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbctwentyRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiftyRupeeBlur(e) {
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        $("#" + this.pageId + "fiftyRupee").val(this.nf50Count * 50);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiftyRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            window.setTimeout(function () {
                $("#pmtfbctwentyRupeeCount").focus();
            }, 100);

        }
    }

    twentyRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
            this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
            $("#" + this.pageId + "twentyRupee").val(this.nf20Count * 20);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twentyRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbctenRupeeCount").focus();
                }, 100);

            }

        }
    }
    twentyRupeeBlur(e) {
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        $("#" + this.pageId + "twentyRupee").val(this.nf20Count * 20);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twentyRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    tenRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
            this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
            $("#" + this.pageId + "tenRupee").val(this.nf10Count * 10);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "tenRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbcfiveRupeeCount").focus();
                }, 100);

            }

        }
    }
    tenRupeeBlur(e) {
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        $("#" + this.pageId + "tenRupee").val(this.nf10Count * 10);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "tenRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            window.setTimeout(function () {
                $("#pmtfbcfiveRupeeCount").focus();
            }, 100);

        }
    }

    fiveRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
            this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
            $("#" + this.pageId + "fiveRupee").val(this.nf5Count * 5);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiveRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbctwoRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiveRupeeBlur(e) {
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        $("#" + this.pageId + "fiveRupee").val(this.nf5Count * 5);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiveRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }


    twoRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
            this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
            $("#" + this.pageId + "twoRupee").val(this.nf2Count * 2);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfbconeRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoRupeeBlur(e) {
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        $("#" + this.pageId + "twoRupee").val(this.nf2Count * 2);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }

    oneRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
            this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;
            $("#" + this.pageId + "oneRupee").val(this.nf1Count * 1);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "oneRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);

            }

        }
    }
    oneRupeeBlur(e) {
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;
        $("#" + this.pageId + "oneRupee").val(this.nf1Count * 1);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "oneRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }
    paymentSpecifcValidations(keyEnter) {
        let enteredAmt = 0.0;
        this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();
        this.enteredTotalLess = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
        this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
        let totalAmt = this.enteredTotalLess;
        let memoNum = null;
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
        this.selectedConsignor = $("#" + this.pageId + "consigneeNameForPayment").val();
        memoNum = this.selectedMemoNumberForAutomatic.toUpperCase();
        if (this.enteredLessAmt == '' || this.enteredLessAmt == null || this.enteredLessAmt == 0.0) {
            this.insertRowPaymentEntry(memoNum, keyEnter);
        } else {
            if (this.isEdit) {
                this.clickedRowAmt = this.maintenanceCompletedLrs.chqAmount;
            }
            for (let i = 0; i < this.paymentAmountEntriesDataList.length; i++) {
                enteredAmt = +enteredAmt + +this.paymentAmountEntriesDataList[i].chqAmount;
            }
            enteredAmt = +enteredAmt + +this.enteredLessAmt;
            enteredAmt = +enteredAmt - +this.clickedRowAmt;
            if (this.isPaymentClicked) {
                if (enteredAmt > totalAmt) {
                    swal({
                        title: "Not Allowed",
                        text: "Total entered amount exceed from the actual Amount, please verify!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.insertRowPaymentEntry(memoNum, keyEnter);
                }
            } else {
                if (enteredAmt > totalAmt) {
                    swal({
                        title: "Not Allowed",
                        text: "Total entered amount exceed from the actual Amount, please verify!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.totalBalAmt = this.balanceAmountFinal;
                    if (enteredAmt > this.totalBalAmt) {
                        swal({
                            title: "Not Allowed",
                            text: "Amount exceeded from the actual Bill Amount, please verify!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        $("#" + this.pageId + "paymentLessAmount").val('');
                        $("#pmtfbcpaymentLessAmount").focus();
                    } else {
                        this.insertRowPaymentEntry(memoNum, keyEnter);
                    }
                }
            }
        }
    }
    insertRowPaymentEntry(memoNum, keyEnter) {
        this.userDataDtoForChq = new UserDataDto();
        this.listOfMemoNumber = [];
        this.listOfMemoNumber.push(memoNum);
        this.userDataDtoForChq.list = this.listOfMemoNumber;
        this.userDataDtoForChq.memoNumber = memoNum;
        this.userDataDtoForChq.companyId = this.userDataDtoReturnSession.companyId;
        this.getLessDetails(this.userDataDtoForChq, keyEnter);
    }

    getLessDetails = (userDataDtoForChq, keyEnter) => {
        this.memoService.getMemoStatusDetailsBkgPaymentFollowup(userDataDtoForChq).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Server Error",
                        text: "Server problem occurred!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.userDataDtoForChqRet = response;
                    for (let i = 0; i < this.userDataDtoForChqRet.length; i++) {
                        if (this.userDataDtoForChqRet[i].status == 'completed') {
                            swal({
                                title: "Duplicate Entry",
                                text: "Payment entry is already done for this LR, if want to edit, click on the corresponding Total beside!",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
                            this.selectedMemoNumberForAutomatic = this.selectedMemoNumberForAutomatic.toUpperCase();
                            window.setTimeout(function () {
                                $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
                                $("#pmtfbcmemoNumberForAutomaticLessPayment").focus();
                            }, 100);
                        } else {
                            window.setTimeout(function () {
                                $("#pmtfbcmemoNumberForManualLessPayment").focus();
                            }, 100);

                            // for checking Pending added by basha bcoz after the memonumber enter key it directly added in table so i did this code to prevent adding in table
                            let totalLess = 0.0;
                            for (let j = 0; j < this.userDataDtosLessTemp.length; j++) {
                                if (this.userDataDtosLessTemp[j].memoNumber == this.userDataDtoForChq.memoNumber) {
                                    totalLess = +totalLess + +this.userDataDtosLessTemp[j].paymentAmt;
                                }
                            }
                            // if its edit then consider nflessamt value
                            this.balanceAmtInAddingTable = +this.userDataDtoForChqRet[i].amount - +totalLess;
                            //                   console.log( this.balanceAmtInAddingTable, this.userDataDtoForChqRet[i].amount, totalLess );
                            if (this.isEdit) {
                                this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
                                this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
                                this.balanceAmtInAddingTable = this.enteredLessAmt;
                            }
                            this.addInPaymentTable(this.userDataDtoForChq.memoNumber, totalLess, this.userDataDtoForChqRet[i].amount);
                            //                            }
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    addInPaymentTable(memoNum, totalLess, amount) {
        this.getChequeDate = $("#" + this.pageId + "chequeDate").val();
        this.getMainStation = $("#" + this.pageId + "paymentLessStn").val();
        this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
        this.enteredNewBankName = $("#" + this.pageId + "newBankName").val();
        this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
        //   console.log( this.getChequeDate );
        this.newAttributeForPayment.chqType = memoNum;
        this.newAttributeForPayment.chqNumber = "NA"
        this.newAttributeForPayment.chqAmount = totalLess;
        this.newAttributeForPayment.cashMemoAmt = amount;
        this.newAttributeForPayment.balance = this.balanceAmtInAddingTable;
        this.newAttributeForPayment.mainStation = this.getMainStation;
        this.newAttributeForPayment.chequeno = $("#" + this.pageId + "chequeNumber").val();
        if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
            this.newAttributeForPayment.bankname = this.enteredNewBankName;
        } else {
            this.newAttributeForPayment.bankname = (this.selectedBankName == null ? null : this.selectedBankName == '' ? null : this.selectedBankName);
        }
        this.newAttributeForPayment.chequedate = this.getChequeDate;
        this.newAttributeForPayment.remarks = $("#" + this.pageId + "referenceNumber").val();
        this.newAttributeForPayment.consignorId = this.selectedConsignorId;
        this.newAttributeForPayment.consignor = $("#" + this.pageId + "consigneeNameForPayment").val();
        this.newAttributeForPayment.paymenttype = $("#" + this.pageId + "paymentMode").val();

        if (this.editTableIdForPayment != null) {
            this.paymentAmountEntriesDataList.splice(this.editTableIdForPayment, 1);
            this.countOfPaymentMemo = +this.countOfPaymentMemo - 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment - +this.editMemoAmtForPayment;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment - +this.editLessAmtForPayment;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment - +this.editBalanceAmtForPayment;
        }
        if (this.paymentAmountEntriesDataList == null) {
            this.paymentAmountEntriesDataList = [];
        }
        if (this.setPaymentEntryDetails == 'new') {
            this.dtTriggerPaymentAmountEntries.next();
            this.setPaymentEntryDetails = 'old';
        }
        if (this.paymentAmountEntriesDataList.length == 0) {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        } else {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        }
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        this.isEdit = false;
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
        $("#pmtfbcmemoNumberForAutomaticLessPayment").focus();
        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
        this.editTableIdForPayment = null;
    }

    rowSelectedEditForPaymentEntry(paymentAmountEntriesData, index) {
        $("#" + this.pageId + "entryLessPaymentEntry").val('paymentLessManual');
        $("#" + this.pageId + "consigneeNameForPayment").val(paymentAmountEntriesData.consignor);
        //        $("#"+this.pageId+"totalAmountForPayment" ).val( paymentAmountEntriesData.chqAmount );
        $("#" + this.pageId + "chequeNumber").val(paymentAmountEntriesData.chequeno);
        $("#" + this.pageId + "chequeDate").val(paymentAmountEntriesData.chequedate);
        $("#" + this.pageId + "consigneeBankName").val(paymentAmountEntriesData.bankname);
        $("#" + this.pageId + "paymentLessAmount").val(paymentAmountEntriesData.balance);
        $("#" + this.pageId + "referenceNumber").val(paymentAmountEntriesData.remarks);
        $("#" + this.pageId + "paymentLessStn").val(paymentAmountEntriesData.mainStation);
        this.paymentLessManualView = true;
        this.paymentLessAutomaticView = false;
        $("#" + this.pageId + "paymentMode").val(paymentAmountEntriesData.paymenttype);
        //        $("#"+this.pageId+"entryLessPaymentEntry" ).val( paymentAmountEntriesData.paymenttype );
        this.selectedConsignorId = paymentAmountEntriesData.consignorId;
        //////
        //For Automatic
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val(paymentAmountEntriesData.chqType);
        /////

        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val(index);
        this.editTableIdForPayment = index;
        ///
        this.maintenanceCompletedLrs = [];
        this.maintenanceCompletedLrs = paymentAmountEntriesData;
        //    console.log( this.maintenanceCompletedLrs );
        this.isEdit = true;
        //
        this.editMemoAmtForPayment = 0;
        this.editLessAmtForPayment = 0;
        this.editBalanceAmtForPayment = 0;
        this.editMemoAmtForPayment = paymentAmountEntriesData.cashMemoAmt;
        this.editLessAmtForPayment = paymentAmountEntriesData.chqAmount;
        this.editBalanceAmtForPayment = paymentAmountEntriesData.balance;
    }

    rowSelectedDeleteForPaymentEntry(paymentAmountEntriesData, index) {

        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Payment entry",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.paymentAmountEntriesDataList.splice(index, 1);
                this.isEdit = false;
                $("#" + this.pageId + "paymentLessAmount").val('');
                $("#pmtfbcmemoNumberForManualLessPayment").focus();
                this.maintenanceCompletedLrs = [];
                $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
                this.editTableIdForPayment = null;
                this.countOfPaymentMemo = +this.countOfPaymentMemo - 1;
                this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment - +paymentAmountEntriesData.cashMemoAmt;
                this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment - +paymentAmountEntriesData.chqAmount;
                this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment - +paymentAmountEntriesData.balance;

            }

        });
    }

    updateCashAmt() {
        if (this.isPaymentClicked) {
            this.userDataDtosPaymentCashTemp = [];
            this.isPaymentClicked = false;
        }
        this.grandTotalForCash = $("#" + this.pageId + "totalRupeeDenomination").val();
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();

        this.nf2000 = this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000;
        this.nf500 = this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500;
        this.nf200 = this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200;
        this.nf100 = this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100;
        this.nf50 = this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50;
        this.nf20 = this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20;
        this.nf10 = this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10;
        this.nf5 = this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5;
        this.nf2 = this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2;
        this.nf1 = this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1;

        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;

        this.getCashValDto = new CashMemoDto();
        this.getCashValDto.nfof2000Count = this.nf2000Count;
        this.getCashValDto.nfof500Count = this.nf500Count;
        this.getCashValDto.nfof200Count = this.nf200Count;
        this.getCashValDto.nfof100Count = this.nf100Count;
        this.getCashValDto.nfof50Count = this.nf50Count;
        this.getCashValDto.nfof20Count = this.nf20Count;
        this.getCashValDto.nfof10Count = this.nf10Count;
        this.getCashValDto.nfof5Count = this.nf5Count;
        this.getCashValDto.nfof2Count = this.nf2Count;
        this.getCashValDto.nfof1Count = this.nf1Count;
        this.getCashValDto.nfOF2000 = this.nf2000;
        this.getCashValDto.nfOF500 = this.nf500;
        this.getCashValDto.nfOF200 = this.nf200;
        this.getCashValDto.nfOF100 = this.nf100;
        this.getCashValDto.nfOF50 = this.nf50;
        this.getCashValDto.nfOF20 = this.nf20;
        this.getCashValDto.nfOF10 = this.nf10;
        this.getCashValDto.nfOF5 = this.nf5;
        this.getCashValDto.nfOF2 = this.nf2;
        this.getCashValDto.nfOF1 = this.nf1;
        this.getCashValDto.grandTotal = this.grandTotalForCash;
        this.userDataDtosPaymentCashTemp.push(this.getCashValDto);
        //    console.log( this.userDataDtosPaymentCashTemp );
        this.showCashLblAmt = true;
        swal({
            title: "Memo Payment",
            text: "Payment details updated temporarily, please note that it will only be save permanently when click on Finish",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    }

    updateChqDetails(type) {
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or Payment Mode is missing for LRs",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.getChqAndNeftLRDetails(type);
            this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
            // add new bank name
            if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
                this.addBankNames();
            } else {
                this.clearCompletePopupFieldsOnTypeChange();
            }
            this.resetPaymentMode();
            swal({
                title: "Memo Payment",
                text: "Payment details updated temporarily, please note that it will only be save permanently when click on Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    resetPaymentMode() {
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.payType = "";
        this.payMode = "";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "paymentMode").val('select');
        //        this.dtTriggerPaymentAmountEntries.unsubscribe();
        //        this.paymentEntriesDataTable();
    }
    addBankNames() {
        this.enteredNewBankName = $("#" + this.pageId + "newBankName").val();
        this.userDataDtoForBankName = new UserDataDto();
        this.userDataDtoForBankName.bankId = 0;
        this.userDataDtoForBankName.bankName = this.enteredNewBankName;
        this.userDataDtoForBankName.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForBankName.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoForBankName.createdBy = this.userDataDtoReturnSession.userId;
        this.addBankDetails();
    }

    //    addBankDetails = () => {
    //        this.memoService.addPartyBankMasterDetails( this.userDataDtoForBankName ).subscribe(
    //            ( response ) => {
    //                if ( response.length == 0 ) {
    //                    swal( {
    //                        title: "Server Error",
    //                        text: "Ooops...Error Occured While adding the new Bank Name",
    //                        icon: "error",
    //                        closeOnClickOutside: false,
    //                        closeOnEsc: false,
    //                    } );
    //                } else {
    //                    this.clearCompletePopupFieldsOnTypeChange();
    //                    this.getPartyBankDetailsService();
    //
    //                }
    //            } ),
    //            ( error ) => console.log( error.json() ),
    //            () => console.log( 'done' );
    //    };
    addBankDetails() {
        this.memoService.createNewPartyBankMasterService(this.userDataDtoForBankName).subscribe(
            (response) => {
                if (response) {
                    this.newPartyBankDetial = response;
                    if (this.newPartyBankDetial.status == "persisted") {
                        this.getPartyBankDetailsService();
                        this.clearCompletePopupFieldsOnTypeChange();
                    } else {
                        swal({
                            title: "Not Saved",
                            text: "Error Occured While adding the new Bank Name",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                    this.newPartyBankDetial = '';

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while Saving New Bank Name",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    getChqAndNeftLRDetails(type) {
        console.log(this.payType, this.payMode);
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or Payment Mode is missing for LRs",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
            this.userDataDtoForPayment = [];
            this.selectedCollectionMan = this.enteredCollectionMan;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
            if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
                this.enteredCompletedOnDate == null;
            } else {
                this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
            }
            for (let i = 0; i < this.paymentAmountEntriesDataList.length; i++) {
                this.userDataDtoForPaymentDetails = new UserDataDto();
                this.userDataDtoForPaymentDetails.memoNumber = this.paymentAmountEntriesDataList[i].chqType;
                this.userDataDtoForPaymentDetails.paymentAmt = this.paymentAmountEntriesDataList[i].balance;
                this.userDataDtoForPaymentDetails.collectioMan = this.selectedCollectionMan;
                this.userDataDtoForPaymentDetails.paymentType = this.payType;
                this.userDataDtoForPaymentDetails.chequeNumber = (this.paymentAmountEntriesDataList[i].chequeno == null ? null : this.paymentAmountEntriesDataList[i].chequeno == '' ? null : this.paymentAmountEntriesDataList[i].chequeno);
                this.userDataDtoForPaymentDetails.updatedBy = this.userDataDtoReturnSession.userId;
                this.userDataDtoForPaymentDetails.bankName = (this.paymentAmountEntriesDataList[i].bankname == null ? null : this.paymentAmountEntriesDataList[i].bankname == '' ? null : this.paymentAmountEntriesDataList[i].bankname);
                this.userDataDtoForPaymentDetails.chequeDate = this.paymentAmountEntriesDataList[i].chequedate;
                this.userDataDtoForPaymentDetails.completedOn = this.enteredCompletedOnDate;
                this.userDataDtoForPaymentDetails.features = (this.paymentAmountEntriesDataList[i].remarks == null ? null : this.paymentAmountEntriesDataList[i].remarks == '' ? null : this.paymentAmountEntriesDataList[i].remarks);
                // if received amt is less then balance amt then the remaining amt
                // will consider as cash

                let memoAmt = this.paymentAmountEntriesDataList[i].cashMemoAmt;
                let amtRec = this.paymentAmountEntriesDataList[i].balance;
                let lessAmt = this.paymentAmountEntriesDataList[i].chqAmount
                let balAsCash = +memoAmt - (+amtRec + +lessAmt);
                this.userDataDtoForPaymentDetails.balance = balAsCash;
                this.userDataDtoForPaymentDetails.mode = this.payMode;
                this.userDataDtoForPaymentDetails.amount = memoAmt;
                this.userDataDtoForPaymentDetails.chqAmt = lessAmt;
                this.userDataDtoForPaymentDetails.type = this.paymentAmountEntriesDataList[i].paymenttype;
                this.userDataDtoForPaymentDetails.partyName = this.paymentAmountEntriesDataList[i].consignor;
                this.userDataDtoForPaymentDetails.consignorId = this.paymentAmountEntriesDataList[i].consignorId;
                //Added By Basha
                this.userDataDtoForPaymentDetails.mainStation = this.paymentAmountEntriesDataList[i].mainStation;
                this.userDataDtoForPayment.push(this.userDataDtoForPaymentDetails);
                //Pending need to check this below new dto list no need of this object userDataDtoListForLess may delete this 
                // add the entry details in temp file
                if (type == 'Cheque/PDC') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentChqTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentChqTemp.push(this.userDataDtoForPaymentDetails);
                } else if (type == 'NEFT/IMPS') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentNeftTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentNeftTemp.push(this.userDataDtoForPaymentDetails);
                } else if (type == 'UPI') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentUpiTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentUpiTemp.push(this.userDataDtoForPaymentDetails);
                }
            }
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.selectedConsignorId = 0;
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
        }

        return this.userDataDtoForPayment;
    }
    clearCompletePopupFieldsOnTypeChange() {
        this.isEdit = false;
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.viewNewBankName = false;
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.controlConsigneeName.reset();
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.controlEntryLessType.reset();
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "totalLess").val('');
        this.controlConsigneeNamePayment.reset();
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getPartyBankDetailsService();

        //Pending still the less page values need to cleared 
        //        listStoreCompleteLrs.removeAll();
        //        gridCompleteLrs.reconfigure(listStoreCompleteLrs, cmCompleteLrs);
        //        listStoreCompleteLessLrs.removeAll();
        //        gridCompleteLessLrs.reconfigure(listStoreCompleteLessLrs,
        //                cmCompleteLessLrs);
    }

    clearCompletePopupCashFields() {
        this.nf2000Count = '';
        this.nf500Count = '';
        this.nf200Count = '';
        this.nf100Count = '';
        this.nf50Count = '';
        this.nf20Count = '';
        this.nf10Count = '';
        this.nf5Count = '';
        this.nf2Count = '';
        this.nf1Count = '';

        this.nf2000 = '';
        this.nf500 = '';
        this.nf200 = '';
        this.nf100 = '';
        this.nf50 = '';
        this.nf20 = '';
        this.nf10 = '';
        this.nf5 = '';
        this.nf2 = '';
        this.nf1 = '';
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        this.grandTotalForCash = '';
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "twoThousandRupee").val();
        $("#" + this.pageId + "fiveHundredRupee").val('');
        $("#" + this.pageId + "twoHundredRupee").val('');
        $("#" + this.pageId + "hundredRupee").val('');
        $("#" + this.pageId + "fiftyRupee").val('');
        $("#" + this.pageId + "twentyRupee").val('');
        $("#" + this.pageId + "tenRupee").val('');
        $("#" + this.pageId + "fiveRupee").val('');
        $("#" + this.pageId + "twoRupee").val('');
        $("#" + this.pageId + "oneRupee").val('');
        $("#" + this.pageId + "twoThousandRupeeCount").val('');
        $("#" + this.pageId + "fiveHundredRupeeCount").val('');
        $("#" + this.pageId + "twoHundredRupeeCount").val('');
        $("#" + this.pageId + "hundredRupeeCount").val('');
        $("#" + this.pageId + "fiftyRupeeCount").val('');
        $("#" + this.pageId + "twentyRupeeCount").val('');
        $("#" + this.pageId + "tenRupeeCount").val('');
        $("#" + this.pageId + "fiveRupeeCount").val('');
        $("#" + this.pageId + "twoRupeeCount").val('');
        $("#" + this.pageId + "oneRupeeCount").val('');

    }


    updateNeftDetails(type) {
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or PaymentMmode is missing for lr's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.getChqAndNeftLRDetails(type);
            this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
            // add new bank name
            if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
                this.addBankNames();
            } else {
                this.clearCompletePopupFieldsOnTypeChange();
            }
            this.resetPaymentMode();
            swal({
                title: "Memo Payment",
                text: "Payment details updated temporary, please note that it will only be save permanently when click on Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    updateUpiDetails(type) {
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or PaymentMmode is missing for lr's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.getChqAndNeftLRDetails(type);
            this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
            // add new bank name
            if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
                this.addBankNames();
            } else {
                this.clearCompletePopupFieldsOnTypeChange();
            }
            this.resetPaymentMode();
            swal({
                title: "Memo Payment",
                text: "Payment details updated temporary, please note that it will only be save permanently when click on Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }
    //// Finish Btn Process starts here
    validatePaymentEntryFinishBtn(e) {
        var balancedAmtInFinal = this.balanceAmountFinal;
        let cashAmt = this.calculateDenominationTotalAmt();
        if (balancedAmtInFinal > cashAmt) {
            swal({
                title: "Not Allowed",
                text: "Balance Amount should be NIL to finish this entry, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            swal({
                title: "Confirm Finish",
                text: "Sure you want to finish this collection transaction",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((finish) => {
                if (finish) {
                    $("#" + this.pageId + "paymentEntryFinishBtn").prop('disabled', true);
                    this.viewByCash = true;
                    this.viewByCashs = true
                    this.showSpinner = true;
                    this.callTempoForCash();
                    this.calculateAllTotalAndBalance();
                    this.setCashAmtModel();
                    this.finishCompleteEntries();
                    $("html, body").animate(
                        { scrollTop: 0 }, "medium");
                }
            });
        }
    }

    finishCompleteEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listLessDetails = this.userDataDtosLessTemp;
        this.finishCompleteDetails();
    }
    finishCompleteDetails = () => {
        this.memoService.updateBookingLessDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenChqEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Less",
                        text: "Server Problem occurred while updating the Less details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    finishPaymenChqEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentChqTemp;
        this.userDataDtoForFinishDetails.mode = 'completedcheque';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenChqDetails();
        console.log(this.userDataDtoForFinishDetails);
    }

    finishPaymenChqDetails = () => {
        this.memoService.updateBookingPaymentCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenNeftEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Cheque Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    finishPaymenNeftEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentNeftTemp;
        this.userDataDtoForFinishDetails.mode = 'completedneft';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenNeftDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    finishPaymenNeftDetails = () => {
        this.memoService.updateBookingPaymentCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenUpiEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Neft Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    finishPaymenUpiEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentUpiTemp;
        this.userDataDtoForFinishDetails.mode = 'completedupi';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenUpiDetails();
    }
    finishPaymenUpiDetails = () => {
        this.memoService.updateBookingPaymentCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.updateCashEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Upi Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    updateCashEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listCashDetails = this.getCashLRDetails();
        this.userDataDtoForFinishDetails.mode = 'completedcash';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.updateCashDetails();
        //    console.log( this.userDataDtoForFinishDetails );
    }
    updateCashDetails = () => {
        this.memoService.updateBookingPaymentCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.updateCollectionNumberOnComplete();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Cash Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    getCashLRDetails() {
        this.userDataDtoForCash = [];
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == null;
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
        }
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.userDataDtoForPaymentCashDetails = new UserDataDto();
            this.userDataDtoForPaymentCashDetails.memoNumber = this.pfAssignedJobDetailsDataList[i].lrNumber;
            this.userDataDtoForPaymentCashDetails.amount = this.pfAssignedJobDetailsDataList[i].grandTotal;
            this.userDataDtoForPaymentCashDetails.collectioMan = this.selectedCollectionMan;
            this.userDataDtoForPaymentCashDetails.paymentType = 'Cash';
            this.userDataDtoForPaymentCashDetails.completedOn = this.enteredCompletedOnDate;
            this.userDataDtoForPaymentCashDetails.updatedBy = this.userDataDtoReturnSession.userId;
            this.userDataDtoForPaymentCashDetails.mode = 'completedcash';
            this.userDataDtoForCash.push(this.userDataDtoForPaymentCashDetails);
        }
        //    console.log( this.userDataDtoForCash );
        return this.userDataDtoForCash;
    }
    updateCollectionNumberOnComplete() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == null;
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
        }
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.collectioMan = this.selectedCollectionMan;
        this.userDataDtoForFinishDetails.completedOn = this.enteredCompletedOnDate;
        this.userDataDtoForFinishDetails.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDtoForFinishDetails.city = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForFinishDetails.companyId = this.userDataDtoReturnSession.companyId;
        this.updateCollectionNumberOnCompleteDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    updateCollectionNumberOnCompleteDetails = () => {
        this.memoService.updateCloseCollectionService(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.updateCloseCollection = response;
                this.showSpinner = false;
                if (this.updateCloseCollection.status == 'success') {
                    swal({
                        title: "Memo Payment",
                        text: "Payment details updated successfully",
                        icon: "success",
                        closeOnClickOutside: true,
                        closeOnEsc: true,
                    }).then(() => {
                        this.getAllLessDetailsOnFinish();
                    });
                } else {
                    swal({
                        title: "Error",
                        text: "Server Problem occurred while closing the collection",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.updateCloseCollection = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    getAllLessDetailsOnFinish() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].lrNumber);
        }
        this.userDataDtoForFinishDetails.list = this.listOfMemoNumber;
        this.getAllLessDetailsOnFinishDetails();
    }

    getAllLessDetailsOnFinishDetails = () => {
        this.memoService.getDifferentMemoLessDetailsBasedOnLessDescBooking(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                if (response.length == 0) {
                } else {
                    this.diffLessRet = response;
                    this.setPringForChallanReport();
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    getChallanPrintValues() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == '';
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "dd-MM-yyyy");
        }
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
        this.nf2000 = this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000;
        this.nf500 = this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500;
        this.nf200 = this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200;
        this.nf100 = this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100;
        this.nf50 = this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50;
        this.nf20 = this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20;
        this.nf10 = this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10;
        this.nf5 = this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5;
        this.nf2 = this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2;
        this.nf1 = this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1;

        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;

        this.getChallanPrintValDto = new CashMemoDto();
        this.getChallanPrintValDto.totalLess = this.lblTotLessSummaryValue == '' ? null : this.lblTotLessSummaryValue;
        this.getChallanPrintValDto.collectionMan = this.selectedCollectionMan;
        this.getChallanPrintValDto.completedOn = this.enteredCompletedOnDate;
        this.getChallanPrintValDto.amtToBeCollected = this.amountToBeCollected;
        this.getChallanPrintValDto.srcLess = this.lblLessValue == '' ? null : this.lblLessValue;
        this.getChallanPrintValDto.tds = this.lblTDSLessValue == '' ? null : this.lblTDSLessValue;
        this.getChallanPrintValDto.claim = this.lblClaimLessValue == '' ? null : this.lblClaimLessValue;
        this.getChallanPrintValDto.totalChqAmt = this.lblTotChqAmtSummaryValue == '' ? null : this.lblTotChqAmtSummaryValue;
        this.getChallanPrintValDto.totalNeftAmt = this.lblTotNeftAmtSummaryValue == '' ? null : this.lblTotNeftAmtSummaryValue;
        this.getChallanPrintValDto.totalUpiAmt = this.lblTotUpiAmtSummaryValue == '' ? null : this.lblTotUpiAmtSummaryValue;
        this.getChallanPrintValDto.totalCashAmt = this.lblTotCashAmtSummaryValue == '' ? null : this.lblTotCashAmtSummaryValue;
        this.getChallanPrintValDto.totalCashAmt = this.lblTotCashAmtSummaryValue == '' ? null : this.lblTotCashAmtSummaryValue;
        this.getChallanPrintValDto.balanceAmt = this.balanceAmountFinal;
        this.getChallanPrintValDto.nfof2000Count = this.nf2000Count;
        this.getChallanPrintValDto.nfof500Count = this.nf500Count;
        this.getChallanPrintValDto.nfof200Count = this.nf200Count;
        this.getChallanPrintValDto.nfof100Count = this.nf100Count;
        this.getChallanPrintValDto.nfof50Count = this.nf50Count;
        this.getChallanPrintValDto.nfof20Count = this.nf20Count;
        this.getChallanPrintValDto.nfof10Count = this.nf10Count;
        this.getChallanPrintValDto.nfof5Count = this.nf5Count;
        this.getChallanPrintValDto.nfof2Count = this.nf2Count;
        this.getChallanPrintValDto.nfof1Count = this.nf1Count;
        this.getChallanPrintValDto.nfOF2000 = this.nf2000;
        this.getChallanPrintValDto.nfOF500 = this.nf500;
        this.getChallanPrintValDto.nfOF200 = this.nf200;
        this.getChallanPrintValDto.nfOF100 = this.nf100;
        this.getChallanPrintValDto.nfOF50 = this.nf50;
        this.getChallanPrintValDto.nfOF20 = this.nf20;
        this.getChallanPrintValDto.nfOF10 = this.nf10;
        this.getChallanPrintValDto.nfOF5 = this.nf5;
        this.getChallanPrintValDto.nfOF2 = this.nf2;
        this.getChallanPrintValDto.nfOF1 = this.nf1;
        this.lessDescOpt = this.entryLessTypeOptions;
        //        for ( let i = 0; i < this.lessDescOpt.length; i++ ) {
        //            this.lessDescOptSent.push(this.entryLessTypeOptions[i].label);
        //        }
        //   console.log( this.lessDescOptSent );
        //    console.log( this.lessDescOptSent.length );
        this.getChallanPrintValDto.lessDesc = this.lessDescOpt;
        this.getChallanPrintValDto.totalMemos = this.pfAssignedJobDetailsDataList.length;
        let totAmt = 0.0;
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            totAmt = +totAmt + +this.pfAssignedJobDetailsDataList[i].grandTotal;
        }
        this.getChallanPrintValDto.grandTotal = totAmt;
        return this.getChallanPrintValDto;
    }

    setPringForChallanReport() {
        this.getChallanPrintValues();
        localStorage.clear();
        localStorage.setItem('printAfterChallanMemo', JSON.stringify(this.getChallanPrintValDto));
        localStorage.setItem('printAfterChallanMemolist', JSON.stringify(this.diffLessRet));
        this.viewChallanRptPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewChallanRptPrint) {
                this.viewChallanRptPrint = false;
                this.getChallanPrintValDto = new CashMemoDto();
                this.diffLessRet = '';
                this.clearConsigneewiseDetails();
                this.clearCompletePopupCashFields();
                this.clearCompletePopupFieldsOnTypeChange();
                this.clearALLFieldsAfterCompelte();
                ///Pending Clear Part on Complete
                localStorage.clear();
            }
        });
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        // inp._elementRef.nativeElement.focus();
        window.setTimeout(function () {
            inp._elementRef.nativeElement.focus();
        }, 1000);
    }

    rowSelectedDeleteForAssignedTable(pfAssignedJobDetailsData) {
        //    console.log( pfAssignedJobDetailsData.lrNumber );
        this.gridReconfigureAssgRemove(pfAssignedJobDetailsData);
    }
    gridReconfigureAssgRemove(pfAssignedJobDetailsData) {
        if (this.value == false) {
            //       console.log( 'a' );
            let index = -1;
            for (let i = 0; i < this.tempstore.length; i++) {
                if (this.tempstore[i].lrNumber === pfAssignedJobDetailsData.lrNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                //alert("Something gone wrong");
            }
            this.tempstore.splice(index, 1);
            //Here instead of clearing the whole pfAssignedJobDetailsDataList i just removed the deleted memo and did the footer calculation
            this.modelAllRemove = (this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === pfAssignedJobDetailsData.lrNumber));
            if (this.modelAllRemove == null) {
                this.pendingLrsDataListForSearch.push(pfAssignedJobDetailsData);
                this.countOFMemosCollectionTable = 0;
                this.sumOFMemosCollectionTable = 0;
                for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
                    this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                    this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pendingLrsDataListForSearch[i].grandTotal);
                }

                let index = -1;
                for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                    if (this.pfAssignedJobDetailsDataList[i].lrNumber === pfAssignedJobDetailsData.lrNumber) {
                        index = i;
                        break;
                    }
                }
                if (index === -1) {
                    alert("Something gone wrong while removing the row");
                }
                //           console.log( this.pfAssignedJobDetailsDataList );

                this.pfAssignedJobDetailsDataList.splice(index, 1);

                //            console.log( this.pfAssignedJobDetailsDataList );
                this.countOfAssignLR = 0;
                this.sumOfAssignLR = 0;
                for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                    this.countOfAssignLR = +this.countOfAssignLR + 1;
                    this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].grandTotal;
                }


            }

        } else {
            //    console.log( 'b' );
            let index = -1;
            //For removing from tempstore
            for (let i = 0; i < this.tempstore.length; i++) {
                if (this.tempstore[i].lrNumber === pfAssignedJobDetailsData.lrNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                // alert("Something gone wrong");
            }
            this.tempstore.splice(index, 1);
            //For removing from pfAssignedJobDetailsDataList
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                if (this.pfAssignedJobDetailsDataList[i].lrNumber === pfAssignedJobDetailsData.lrNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                //alert("Something gone wrong");
            }
            this.pfAssignedJobDetailsDataList.splice(index, 1);
            //for foooter in Assign Table
            this.countOfAssignLR = 0;
            this.sumOfAssignLR = 0;
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].grandTotal;
            }

            this.modelAllRemove = (this.pendingLrsDataListForSearch.find(pendingLrsDataForSearch => pendingLrsDataForSearch.lrNumber === pfAssignedJobDetailsData.lrNumber));
            if (this.modelAllRemove == null) {
                this.pendingLrsDataListForSearch.push(pfAssignedJobDetailsData);

                //for foooter in Collection Table
                this.countOFMemosCollectionTable = 0;
                this.sumOFMemosCollectionTable = 0;
                for (let i = 0; i < this.pendingLrsDataListForSearch.length; i++) {
                    this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                    this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pendingLrsDataListForSearch[i].grandTotal);
                }

            }
        }

        $("#" + this.pageId + "pendingLrsTableId").DataTable().destroy();
        this.dtTriggerPendingLrDetails.next();
        $("#" + this.pageId + "assignedLrsTableId").DataTable().destroy();
        this.dtTriggerPfAssignedJobDetails.next();
    }
    callTempoForLess() {
        this.isLessClicked = false;
        this.viewNextBtn = false;
        this.showLessLbl = true;
        this.nextEntryType = 'Less Entry';
        //    console.log( 'sa1' );
        this.showChqLbl = false;
        this.showNEFTLbl = false;
        this.showUpiLbl = false;
        this.showCashLbl = false;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.lessEntriesDataTableDataList = [];
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
    }
    callTempoForLessForDataTable() {
        this.isLessClicked = true;
        this.getLessDetailsOnClickFromTemp();
    }
    getLessDetailsOnClickFromTemp() {
        //    console.log( '' );
        this.isEdit = false;
        let totalLess = this.lblTotLessSummaryValue == '' ? 0 : this.lblTotLessSummaryValue;
        $("#" + this.pageId + "totalLess").val(totalLess);
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
        this.lessEntriesDataTableDataList = [];
        this.newAttribute = {};
        // console.log( this.userDataDtosLessTemp );

        for (let i = 0; i < this.userDataDtosLessTemp.length; i++) {
            let balanceAmt = this.userDataDtosLessTemp[i].amount - (this.userDataDtosLessTemp[i].paymentAmt == null ? 0.0 : this.userDataDtosLessTemp[i].paymentAmt);
            this.newAttribute.chqType = this.userDataDtosLessTemp[i].lrNumber;
            this.newAttribute.chqNumber = this.userDataDtosLessTemp[i].paymentType;
            this.newAttribute.chqAmount = this.userDataDtosLessTemp[i].paymentAmt;
            this.newAttribute.cashMemoAmt = this.userDataDtosLessTemp[i].amount;
            this.newAttribute.balance = balanceAmt;
            this.newAttribute.mainStation = this.userDataDtosLessTemp[i].mainStation;
            this.newAttribute.consignorId = this.userDataDtosLessTemp[i].consignorId;
            this.newAttribute.consignor = this.userDataDtosLessTemp[i].partyName;
            this.lessEntriesDataTableDataList.push(this.newAttribute);
            this.countOfLessLR = +this.countOfLessLR + 1;
            this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttribute.chqAmount;
            this.newAttribute = {};
        }
    }
    callTempoForChq() {
        //   console.log( 'sa2' );
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = true;
        this.viewByChequeDate = true;
        this.viewByConsigneeBankName = true;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "Cheque";
        this.payMode = "completedcheque";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showUpiLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getChqDetailsOnClickFromTemp();
    }
    getChqDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotChqAmtSummaryValue == '' ? 0 : this.lblTotChqAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);

        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentChqTemp[i].lrNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentChqTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentChqTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentChqTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentChqTemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentChqTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentChqTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentChqTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentChqTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentChqTemp[i].features;
            this.newAttributeForPayment.consignorId = this.userDataDtosPaymentChqTemp[i].consignorId;
            this.newAttributeForPayment.consignor = this.userDataDtosPaymentChqTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentChqTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';
        }
        $("#" + this.pageId + "paymentMode").val('cheque');
    }

    callTempoForNEFT() {
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = true;
        this.viewByReferenceNumber = true;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "NEFT/IMPS";
        this.payMode = "completedneft";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showUpiLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getNEFTDetailsOnClickFromTemp();
    }
    getNEFTDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotNeftAmtSummaryValue == '' ? 0 : this.lblTotNeftAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentNeftTemp[i].lrNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentNeftTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentNeftTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentNeftTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentNeftTemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentNeftTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentNeftTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentNeftTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentNeftTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentNeftTemp[i].features;
            this.newAttributeForPayment.consignorId = this.userDataDtosPaymentNeftTemp[i].consignorId;
            this.newAttributeForPayment.consignor = this.userDataDtosPaymentNeftTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentNeftTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';

        }
        $("#" + this.pageId + "paymentMode").val('neft');
    }

    callTempoForUpi() {
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = true;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "UPI";
        this.payMode = "completedupi";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showUpiLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getUpiDetailsOnClickFromTemp();
    }
    getUpiDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotUpiAmtSummaryValue == '' ? 0 : this.lblTotUpiAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentUpiTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentUpiTemp[i].lrNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentUpiTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentUpiTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentUpiTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentUpiTemp[i].paymentAmt;
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentUpiTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentUpiTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentUpiTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentUpiTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentUpiTemp[i].features;
            this.newAttributeForPayment.consignorId = this.userDataDtosPaymentUpiTemp[i].consignorId;
            this.newAttributeForPayment.consignor = this.userDataDtosPaymentUpiTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentUpiTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';
        }
        $("#" + this.pageId + "paymentMode").val('neft');
    }


    callTempoForCash() {
        this.viewByCash = true;
        this.viewByCashs = true;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.controlConsigneeBankName.reset();
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsignorId = 0;
        this.payType = "Cash";
        this.payMode = "completedcash";
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "paymentMode").val('cash');
        this.isEdit = false;
        let totalLess = this.lblTotCashAmtSummaryValue == '' ? 0 : this.lblTotCashAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.setCashAmt();
    }


    setCashAmt() {
        for (let i = 0; i < this.userDataDtosPaymentCashTemp.length; i++) {
            this.twoThousandRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2000Count;
            $("#" + this.pageId + "twoThousandRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF2000);
            $("#" + this.pageId + "fiveHundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF500);
            $("#" + this.pageId + "twoHundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF200);
            $("#" + this.pageId + "hundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF100);
            $("#" + this.pageId + "fiftyRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF50);
            $("#" + this.pageId + "twentyRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF20);
            $("#" + this.pageId + "tenRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF10);
            $("#" + this.pageId + "fiveRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF5);
            $("#" + this.pageId + "twoRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF2);
            $("#" + this.pageId + "oneRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF1);
            $("#" + this.pageId + "twoThousandRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof2000Count);
            $("#" + this.pageId + "fiveHundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof500Count);
            $("#" + this.pageId + "twoHundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof200Count);
            $("#" + this.pageId + "hundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof100Count);
            $("#" + this.pageId + "fiftyRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof50Count);
            $("#" + this.pageId + "twentyRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof20Count);
            $("#" + this.pageId + "tenRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof10Count);
            $("#" + this.pageId + "fiveRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof5Count);
            $("#" + this.pageId + "twoRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof2Count);
            $("#" + this.pageId + "oneRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof1Count);
            $("#" + this.pageId + "totalRupeeDenomination").val(this.userDataDtosPaymentCashTemp[i].grandTotal);
        }
    }
    setCashAmtModel() {
        for (let i = 0; i < this.userDataDtosPaymentCashTemp.length; i++) {
            this.twoThousandRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2000Count;
            this.fiveHundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof500Count;
            this.twoHundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof200Count;
            this.hundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof100Count;
            this.fiftyRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof50Count;
            this.twentyRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof20Count;
            this.tenRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof10Count;
            this.fiveRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof5Count;
            this.twoRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2Count;
            this.oneRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof1Count;
            this.twoThousandRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF2000;
            this.fiveHundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF500;
            this.twoHundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF200;
            this.hundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF100;
            this.fiftyRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF50;
            this.twentyRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF20;
            this.tenRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF10;
            this.fiveRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF5;
            this.twoRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF2;
            this.oneRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF1;
        }
    }
    clearALLFieldsAfterCompelte() {
        this.pfAssignedJobDetailsDataList = [];
        this.tempstore = [];
        this.tempStoreDup = new CashMemoDto();
        $("#" + this.pageId + "consigneeBankName").val('');
        this.selectedConsignorId = 0;
        $("#" + this.pageId + "newBankName").val('');
        this.controlConsigneeBankName.reset();
        this.viewNewBankName = false;
        this.clearAllAfterBtnFinish();
        this.userDataDtoForStatusRet = '';
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedMemoNumberForAutomatic = '';
        this.modelForSearched = '';
        this.letValueofPendingLrs = new CashMemoDto();
        this.getsearchInAssignedTableFoDup = new CashMemoDto();
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoInfo = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoDup = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.getsearchInAssignedTableForAlreadyExist = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoAlreadyExist = new CashMemoDto();
        this.setValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
        this.getValueofSearchInAssignedTableFoAlreadyExistToParty = new CashMemoDto();
        this.letValueofSearchInAssignedTableFoAlreadyExistParty = new CashMemoDto();
        this.getCashValDto = new CashMemoDto();
        this.modelAlreadyExist = '';
        this.modelDup = '';
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.consigneeeName = '';
        this.consigneeExist = false;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.newAttForColTable = {};
        this.pendingLrsDataListForSearch = [];
        this.userDataDtoForLRAssign = new UserDataDto();
        this.selectedCollectionMan = '';
        this.selectedCollectionManAssign = '';
        this.listOfLrNumber = [];
        this.userDataDtoForLRAssignSRDStock = new UserDataDto();
        this.userDataDtoForLRToPartySRDStock = new UserDataDto();
        this.userDataDtoForLRToParty = new UserDataDto();
        this.userDataDtoForLRFinish = new UserDataDto();
        this.selectedCollectionMan = '';
        this.enteredCompletedOnDate = '';
        $("#" + this.pageId + "completedOnDate").val('');
        this.enteredTotalLess = '';
        $("#" + this.pageId + "totalLess").val('');
        this.enteredLessAmt = '';
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;
        this.totalAmt = 0.0;
        this.totalBalAmt = 0.0;
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;
        this.balanceAmountFinal = 0.0;
        this.amountToBeCollected = 0.0;
        this.letValueofMemoFrmAssingedTableAny = [];
        this.lrDtoForGroupStation = new LRDto();
        this.lrDtoForGroupStationRet = '';
        this.lrDtoForGroupStationSet = new LRDto();
        this.newAttribute = {};
        this.newAttributeForTDS = {};
        this.newAttributeForPayment = {};
        this.newAttributeForConsignorWiseBalance = {};
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.slashSplitMemo = [];
        this.maintenanceCompletedLessLrs = [];
        this.maintenanceCompletedLrs = [];
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
        this.editlessamount = 0;
        this.getConsingeewisememoDto = new CashMemoDto();
        this.getConsingeewisememoAny = '';
        this.isDuplicateBulkLess = false;
        this.tdsBulkTotMemoAmt = 0.0;
        this.listOfMemoNumber = [];
        this.cashMemoDtoForTDS = new CashMemoDto();
        this.cashMemoDtoForTDSRet = [];
        this.userDataDtosLessTemp = [];
        this.userDataDtosPaymentChqTemp = [];
        this.userDataDtosPaymentNeftTemp = [];
        this.userDataDtosPaymentUpiTemp = [];
        this.userDataDtoForStatusRet = '';
        this.lblLessValue = '';
        this.lblTDSLessValue = '';
        this.lblClaimLessValue = '';
        this.lblTotLessSummaryValue = '';
        this.lblTotChqAmtSummaryValue = '';
        this.lblTotNeftAmtSummaryValue = '';
        this.lblTotUpiAmtSummaryValue = '';
        this.lblTotCashAmtSummaryValue = '';
        this.userDataDtoForLess = [];
        this.userDataDtoForPayment = [];
        this.userDataDtoForCash = [];
        this.userDataDtoForLessDetails = new UserDataDto();
        this.userDataDtoForPaymentDetails = new UserDataDto();
        this.userDataDtoForPaymentCashDetails = new UserDataDto();
        this.isLessClicked = false;
        this.isPaymentClicked = false;
        this.viewNextBtn = false;
        this.userDataDtoForBankDetails = new UserDataDto();
        this.userDataDtoForBankDetailsRet = new UserDataDto();
        this.viewNewBankName = false;
        this.selectedEntryTypeForPayment = '';
        this.paymentLessManualView = true;
        this.paymentLessAutomaticView = false;
        this.viewByConsigneeName = false;
        this.selectedPaymentMode = '';
        this.showNextBtn = false;
        this.payType = '';
        this.payMode = '';
        this.letValueofMemoExistInPaymentEntryTableAny = [];
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.userDataDtoForChq = new UserDataDto();
        this.userDataDtoForChqRet = '';
        this.balanceAmtInAddingTable = 0;
        this.getChequeDate = '';
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.editMemoAmtForPayment = 0;
        this.editLessAmtForPayment = 0;
        this.editBalanceAmtForPayment = 0;
        this.selectedBankName = '';
        this.userDataDtoForBankName = new UserDataDto();
        this.enteredNewBankName = '';
        this.enteredCheckNumber = '';
        this.enteredRefNumber = '';
        this.viewFinishBtn = true;
        this.viewShowNexInAssingBtn = false;
        this.viewShowNexInAssingBtn2 = false;
        this.showNextBtnInAssign = false;
        this.showForCheckandNeft = false;
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.isDuplicateChq = false;
        this.lrDtoForValidateChq = new LRDto();
        this.retrunStatus = '';
        this.diffLessRet = '';
        this.validateChqRet = '';
        this.getChallanPrintValDto = new CashMemoDto();
        this.lessDescOpt = [];
        this.lessDescOptSent = [];
        this.viewChallanRptPrint = false;
        this.collectionManTP = '';
        this.countTp = '';
        this.stationForCode = '';
        this.consignorWiseBalancelDto = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();
        this.consignorWiseBalancelRet = '';
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.setCollectionDetails = 'old';
        this.setAssingedDetails = 'old';
        this.newAttForColTable = {};
        this.modelAllRemove = '';
        this.tempStoreForRemove = new CashMemoDto();
        this.consigneeWiseTrigger = 'old';
        this.showLessLbl = false;
        this.showChqLbl = false;
        this.showNEFTLbl = false;
        this.showUpiLbl = false;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.isLessClicked = true;
        this.isEdit = false;
        this.addInTable = false;
        this.viewNextBtn = false;
        this.grandTotalForCash = '';
        this.showCashLbl = false;
        this.userDataDtosPaymentCashTemp = [];
        this.showCashLblAmt = false;
        $("#" + this.pageId + "actionType").val('');
        $("#" + this.pageId + "collectionMan").val('');
        this.enteredCollectionMan = '';
        $("#" + this.pageId + "entryType").val('manual');
        $("#" + this.pageId + "station").val('');
        $("#" + this.pageId + "codeForManual").val('');
        $("#" + this.pageId + "memoNumberForManual").val('');
        $("#" + this.pageId + "memoNumberForAutomatic").val('');
        this.manualView = true;
        this.automaticView = false;
        $("#" + this.pageId + "completedOnDa t e").val('');
        this.tdyCompletedOnDate = '';
        $("#" + this.pageId + "collectionManAssig n ").val('');
        this.enteredCollectionManAssign = '';
        $("#" + this.pageId + "collectionManToParty").val('');
        this.enteredCollectionManToParty = '';
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "totalLess").val('');
        $("#" + this.pageId + "totalMemoForTDS").val('');
        $("#" + this.pageId + "memoAmountForTDS").val('');
        $("#" + this.pageId + "tdsLessTwoPer").val('');
        $("#" + this.pageId + "lessForTDS").val('');
        $("#" + this.pageId + "entryLessEntryType").val('entryLessManual');
        $("#" + this.pageId + "entryLessStation").val('');
        this.entryLessManualView = true;
        this.entryLessAutomaticView = false;
        $("#" + this.pageId + "codeForManualLess").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
        $("#" + this.pageId + "paymentMode").val('select');
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "entryLessPaymentEntry").val('paymentLessManual');
        $("#" + this.pageId + "paymentLessStn").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.modelConsignorName = '';
        this.showSpinner = false;
        this.selectTodayCompletedOnDate();
        this.showResetBtn = true;
        this.lessEntriesDataTableDataList = [];
        this.paymentAmountEntriesDataList = [];
        this.modelStationForPayment = '';
        this.modelStationForPaymentAmt = '';
        this.modelStationForLess = '';
        this.modelStationForLessAmt = '';
        this.setLessEntryDetails = 'old';
        this.setPaymentEntryDetails = 'old';
        this.modelPartyBankName = '';
        this.twoThousandRupeeCountModel = '';
        this.twoThousandRupeeModel = '';
        this.fiveHundredRupeeCountModel = '';
        this.fiveHundredRupeeModel = '';
        this.twoHundredRupeeCountModel = '';
        this.twoHundredRupeeModel = '';
        this.hundredRupeeCountModel = '';
        this.hundredRupeeModel = '';
        this.fiftyRupeeCountModel = '';
        this.fiftyRupeeModel = '';
        this.twentyRupeeCountModel = '';
        this.twentyRupeeModel = '';
        this.tenRupeeCountModel = '';
        this.tenRupeeModel = '';
        this.fiveRupeeCountModel = '';
        this.fiveRupeeModel = '';
        this.twoRupeeCountModel = '';
        this.twoRupeeModel = '';
        this.oneRupeeCountModel = '';
        this.oneRupeeModel = '';
        $("#" + this.pageId + "paymentEntryFinishBtn").prop('disabled', false);
    }

    resetAll() {
        this.clearALLFieldsAfterCompelte();
        this.consignorWiseDetails = false;
        this.showResetBtn = false;
        this.modelActionType = '';
        this.tpNoView = false;
    }

    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
            this.consignorIndexSearchViewTA.push(this.consignorIndexOptions[i].label);
        }
    }
    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;
        this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.office;
        if (this.selectedIndex != null && this.selectedIndex != '') {
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
            this.getConsignorDetails();
        }
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    getConsignorDetailsSearchViewList() {
        this.consignorNameSearchViewOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndexSearch = this.modelConsignorIndexSearchView;
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndexSearch;
        this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.office;
        if (this.selectedIndexSearch != null && this.selectedIndexSearch != '') {
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
            this.getConsignorDetailsSearchView();
        }
    }
    getConsignorDetailsSearchView = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameSearchViewOptions = [];
                    this.consignorNameSearchViewTA = [];
                } else {
                    this.consignorNameSearchViewOptions = response;
                    this.consignorNameSearchViewTA = [];
                    for (let i = 0; i < this.consignorNameSearchViewOptions.length; i++) {
                        this.consignorNameSearchViewTA.push(this.consignorNameSearchViewOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();
    }

    consignorSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndexSearchView = e.item;
        if (this.modelConsignorIndexSearchView != 'ALL') {
            this.getConsignorDetailsSearchViewList();
        }
    }

    getTopartyDetails() {
        this.enteredStmtNo = null;
        if (!this.tpNoView) {
            this.getPendingLrDetailsGridDtails('To Party', "ALL");
        } else {
            this.enteredTpCode = $("#" + this.pageId + "tpCode").val();
            this.enteredTpNo = $("#" + this.pageId + "tpNo").val();
            if (this.enteredTpCode == null || this.enteredTpCode == '' || this.enteredTpNo == null || this.enteredTpNo == '') {
                swal("Not Allowed", "Please enter Statement Number to proceed!", "info");
            } else {
                this.enteredStmtNo = this.enteredTpCode + "" + this.enteredTpNo;
                this.getPendingLrDetailsGridDtails('To Party', this.enteredStmtNo);
            }
        }
    }
    getStationCode(agentName) {
        this.userDtoMemoCode = new UserDataDto();
        this.userDtoMemoCode.office = agentName;
        this.masterReadService.getStationCodeService(this.userDtoMemoCode).subscribe(
            (response) => {
                if (response.length != 0) {
                    this.stationCodeDetails = response;
                    this.tpFormat = "SRD/" + this.stationCodeDetails.stationCode + "/";
                    $("#" + this.pageId + "tpCode").val(this.tpFormat);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station Code",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    getTopartyDetailsData() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDto.branch = this.userDataDtoReturnSession.office;
    }
    getTopartyDetailsMethod = () => {
        this.showSpinnerForAction = true;
        this.getTopartyDetailsData();
        this.consignorService.getConsignorTopartyDetails(this.partyMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.consignorMasterDataList = [];
                this.topartyNameTA = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No To-Party Details found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.consignorMasterDataList = response;
                    for (let i = 0; i < this.consignorMasterDataList.length; i++) {
                        this.topartyNameTA.push(this.consignorMasterDataList[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Toparty details", "info");
            },
            () => console.log('done');
    }

    rowSelectedToparty(e: NgbTypeaheadSelectItemEvent) {
        this.modelTopartyName = e.item;
        console.log('inside rowSelectedToparty');
        console.log(this.modelTopartyName);
        this.selectedTopartyId = this.modelTopartyName.consignorId;
        this.selectedTopartyAddress = this.modelTopartyName.address;
        this.selectedTopartyGst = this.modelTopartyName.gstNoConsignor;
        console.log(this.selectedTopartyId);
    }

    getFromStationsRead(string) {
        console.log("getFromStationsRead");
        console.log(this.userDataDtoReturnSession.mainAdminStation);
        this.fromAgentDto.mode = "mainBranch";
        this.fromAgentDto.status = "Working";
        this.fromAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fromAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.fromAgentDto.column2 = "working";
        if (string == "Self") {
            this.fromAgentDto.column3 = "Self";
        } else if (string == "Agent") {
            this.fromAgentDto.column3 = "Agent";
        }
    }

    getAgentNamesFromStations = () => {
        if (!this.showAgentDropdown) {
            this.fromStationTA = [];
            this.agentDtoFromStationAll.subStation = this.userDataDtoReturnSession.office;
            this.fromStationTA.push(this.agentDtoFromStationAll);
        } else {
            this.getFromStationsRead(String);
            this.masterReadService.getSourceListDetails(this.fromAgentDto).subscribe(
                (response) => {
                    this.fromStationDataListOptions = response;
                    this.fromStationTA = [];
                    this.searchSrcListInLoop = [];
                    this.agentDtoFromStationAll.subStation = "ALL";
                    this.fromStationTA.push(this.agentDtoFromStationAll);
                    for (let i = 0; i < this.fromStationDataListOptions.length; i++) {
                        this.fromStationTA.push(this.fromStationDataListOptions[i]);
                        this.searchSrcListInLoop.push(this.fromStationDataListOptions[i].subStation);
                    }
                }),
                (error) => {
                    swal("Error", "Server Problem Occurred While getting the Agent details", "info");
                },
                () => console.log('done');
        }
    }


    getLRDetailsForFollowup() {
        // alert('getLRDetailsForFollowup : ' + this.selectedActionType);
        if (this.selectedActionType === 'Complete') {
            this.getPendingLrDetailsGridDtails('Pending', null);
        } else if (this.selectedActionType === 'To Party') {
            this.getPendingLrDetailsGridDtails('Pending', null);
        } else if (this.selectedActionType === 'To Party Complete') {
            this.getTopartyDetails();
        }
    }

    agentSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentSearchView = e.item;
        this.enteredCollectionMan = this.modelAgentSearchView.subStation;
        if (this.modelAgentSearchView.subStation != 'ALL') {
            this.tpNoView = true;
            this.getStationCode(this.modelAgentSearchView.subStation);
        } else {
            this.tpNoView = false;
        }
    }

    setTopartyStmtprint(userDataDtoForLR) {
        console.log('inside setTopartyStmtprint');
        console.log(this.generatedtpStmtNo);
        this.leftToPartyStatementDataLocalStorage.tpStmtNo = this.generatedtpStmtNo;
        this.leftToPartyStatementDataLocalStorage.statementNo = this.generatedtpStmtNo;
        this.leftToPartyStatementDataLocalStorage.fromdate = userDataDtoForLR.fromDate;
        this.leftToPartyStatementDataLocalStorage.todate = userDataDtoForLR.toDate;

        this.leftToPartyStatementDataLocalStorage.consignor = this.userDataDtoForLRToParty.partyName;

        this.leftToPartyStatementDataLocalStorage.address = this.selectedTopartyAddress;
        this.leftToPartyStatementDataLocalStorage.gstNumber = this.selectedTopartyGst;
        localStorage.clear();
        localStorage.setItem('leftToPartyGeneralDetailsLocalStorage',
            JSON.stringify(this.leftToPartyStatementDataLocalStorage));
        console.log('this.leftToPartyStatementDataLocalStorage');
        console.log(this.leftToPartyStatementDataLocalStorage);
        this.viewLeftToPartyCustomPrintV1 = true;
        this.changeDetectorRef.detectChanges();
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewLeftToPartyCustomPrintV1) {
                this.viewLeftToPartyCustomPrintV1 = false;
                localStorage.clear();
                this.clearToParty();
            }
        });
    }

    rowCheckBoxChecked(e, pendingLrsDataForSearch, index) {
        if (e.currentTarget.checked) {
            if (this.pendingLrsDataForSearchSelectedList.length == 0) {
                this.pendingLrsDataForSearchSelectedList = [];
                this.pendingLrsDataForSearchSelectedIndex = [];
                this.pendingLrsDataForSearchSelectedList.push(pendingLrsDataForSearch);
                this.pendingLrsDataForSearchSelectedIndex.push(index);
            } else {
                this.pendingLrsDataForSearchSelectedList.push(pendingLrsDataForSearch);
                this.pendingLrsDataForSearchSelectedIndex.push(index);
            }
        } else {
            let index = this.pendingLrsDataForSearchSelectedList.indexOf(pendingLrsDataForSearch);
            if (index > -1) {
                this.pendingLrsDataForSearchSelectedList.splice(index, 1);
                this.pendingLrsDataForSearchSelectedIndex.splice(index, 1);
            }
        }
    }

    rowCheckBoxPaymentLrChecked(e, paymentAmountSelectedLrsData) {
        if (e.currentTarget.checked) {
            if (this.paymetSelectedLrsDataList.length == 0) {
                this.paymetSelectedLrsDataList = [];
                this.paymetSelectedLrsDataList.push(paymentAmountSelectedLrsData);
            } else {
                this.paymetSelectedLrsDataList.push(paymentAmountSelectedLrsData);
            }
        } else {
            let index = this.paymetSelectedLrsDataList.indexOf(paymentAmountSelectedLrsData);
            if (index > -1) {
                this.paymetSelectedLrsDataList.splice(index, 1);
            }
        }
        console.log('paymetSelectedLrsDataList');
        console.log(this.paymetSelectedLrsDataList);
    }

    //PFCHG_V3
    validatePaymentLrsAllBtn() {
        if (this.pfAssignedLRsForPaymentList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No LR Found",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.insertRowPaymentEntryPaymentAll();
        }
    }

    validatePaymentLrsSelectedBtn() {
        if (this.paymetSelectedLrsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "Please select LR to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.insertRowPaymentEntryPaymentSelected();
        }
    }

    insertRowPaymentEntryPaymentAll() {
        this.userDataDtoForChq = new UserDataDto();
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedLRsForPaymentList.length; i++) {
            this.listOfMemoNumber.push(this.pfAssignedLRsForPaymentList[i].lrNumber);
        }

        this.pfAssignedLRsForPaymentList = [];
        this.totalAmountForPaymentEntries = 0;
        $("#" + this.pageId + "amountEntriesTable").DataTable().destroy();
        this.dtTriggerPaymentAmountEntries.next();

        $("#" + this.pageId + "paymentLrSelected").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerSelectLRsForPayment.next();
        }, 100);

        this.userDataDtoForChq.list = this.listOfMemoNumber;
        this.userDataDtoForChq.companyId = this.userDataDtoReturnSession.companyId;
        this.getLessDetailsPayment(this.userDataDtoForChq);
    }

    insertRowPaymentEntryPaymentSelected() {
        this.userDataDtoForChq = new UserDataDto();
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.paymetSelectedLrsDataList.length; i++) {
            this.listOfMemoNumber.push(this.paymetSelectedLrsDataList[i].lrNumber);
        }

        for (let i = 0; i < this.paymetSelectedLrsDataList.length; i++) {
            let lrNumber1 = this.paymetSelectedLrsDataList[i].lrNumber;
            for (let j = 0; j < this.pfAssignedLRsForPaymentList.length; j++) {
                let lrNumber2 = this.pfAssignedLRsForPaymentList[j].lrNumber;
                if (lrNumber1 == lrNumber2) {
                    let index = this.pfAssignedLRsForPaymentList.findIndex(x => x.lrNumber === this.paymetSelectedLrsDataList[i].lrNumber);
                    this.pfAssignedLRsForPaymentList.splice(index, 1);
                }
            }
        }
        for (let k = 0; k < this.pfAssignedLRsForPaymentList.length; k++) {
            this.totalAmountForPaymentEntries = +this.totalAmountForPaymentEntries + +this.pfAssignedLRsForPaymentList[k].grandTotal;
        }

        // $("#" + this.pageId + "paymentLrSelected").DataTable().destroy();
        // setTimeout(() => {
        //     this.dtTriggerSelectLRsForPayment.next();
        // }, 100);

        $("#" + this.pageId + "amountEntriesTable").DataTable().destroy();
        this.dtTriggerPaymentAmountEntries.next();

        this.paymetSelectedLrsDataList = [];

        this.userDataDtoForChq.list = this.listOfMemoNumber;
        this.userDataDtoForChq.companyId = this.userDataDtoReturnSession.companyId;
        this.getLessDetailsPayment(this.userDataDtoForChq);
    }

    getLessDetailsPayment = (userDataDtoForChq) => {
        this.memoService.getMemoStatusDetailsBkgPaymentFollowup(userDataDtoForChq).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Server Error",
                        text: "Server problem occurred!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.userDataDtoForChqRet = response;
                    for (let i = 0; i < this.userDataDtoForChqRet.length; i++) {
                        if (this.userDataDtoForChqRet[i].status != 'completed') {
                            // for checking Pending added by basha bcoz after the memonumber enter key it directly added in table so i did this code to prevent adding in table
                            let totalLess = 0.0;
                            for (let j = 0; j < this.userDataDtosLessTemp.length; j++) {
                                if (this.userDataDtosLessTemp[j].memoNumber == this.userDataDtoForChqRet[i].lrNumber) {
                                    totalLess = +totalLess + +this.userDataDtosLessTemp[j].paymentAmt;
                                }
                            }
                            // if its edit then consider nflessamt value
                            this.balanceAmtInAddingTable = +this.userDataDtoForChqRet[i].amount - +totalLess;
                            //                   console.log( this.balanceAmtInAddingTable, this.userDataDtoForChqRet[i].amount, totalLess );
                            if (this.isEdit) {
                                this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
                                this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
                                this.balanceAmtInAddingTable = this.enteredLessAmt;
                            }
                            this.addInPaymentTablePayment(this.userDataDtoForChqRet[i].lrNumber, totalLess, this.userDataDtoForChqRet[i].amount);
                            //                            }
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    addInPaymentTablePayment(memoNum, totalLess, amount) {
        this.getChequeDate = $("#" + this.pageId + "chequeDate").val();
        this.getMainStation = $("#" + this.pageId + "paymentLessStn").val();
        this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
        this.enteredNewBankName = $("#" + this.pageId + "newBankName").val();
        this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
        //   console.log( this.getChequeDate );
        this.newAttributeForPayment.chqType = memoNum;
        this.newAttributeForPayment.chqNumber = "NA"
        this.newAttributeForPayment.chqAmount = totalLess;
        this.newAttributeForPayment.cashMemoAmt = amount;
        this.newAttributeForPayment.balance = this.balanceAmtInAddingTable;
        this.newAttributeForPayment.mainStation = this.getMainStation;
        this.newAttributeForPayment.chequeno = $("#" + this.pageId + "chequeNumber").val();
        if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
            this.newAttributeForPayment.bankname = this.enteredNewBankName;
        } else {
            this.newAttributeForPayment.bankname = (this.selectedBankName == null ? null : this.selectedBankName == '' ? null : this.selectedBankName);
        }
        this.newAttributeForPayment.chequedate = this.getChequeDate;
        this.newAttributeForPayment.remarks = $("#" + this.pageId + "referenceNumber").val();
        this.newAttributeForPayment.consignorId = this.selectedConsignorId;
        this.newAttributeForPayment.consignor = $("#" + this.pageId + "consigneeNameForPayment").val();
        this.newAttributeForPayment.paymenttype = $("#" + this.pageId + "paymentMode").val();

        if (this.editTableIdForPayment != null) {
            this.paymentAmountEntriesDataList.splice(this.editTableIdForPayment, 1);
            this.countOfPaymentMemo = +this.countOfPaymentMemo - 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment - +this.editMemoAmtForPayment;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment - +this.editLessAmtForPayment;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment - +this.editBalanceAmtForPayment;
        }
        if (this.paymentAmountEntriesDataList == null) {
            this.paymentAmountEntriesDataList = [];
        }
        if (this.setPaymentEntryDetails == 'new') {
            this.dtTriggerPaymentAmountEntries.next();
            this.setPaymentEntryDetails = 'old';
        }
        if (this.paymentAmountEntriesDataList.length == 0) {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        } else {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        }
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        this.isEdit = false;
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
        this.editTableIdForPayment = null;
    }
}
