<head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showPaymentFollowup">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 5px;">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row p-b-10" style="border-bottom: 1px solid #cebd9f;">
                                <div class="col-md-12">
                                    <mat-horizontal-stepper [linear]="false" #stepper>
                                        <mat-step [stepControl]="firstStepGroup">
                                            <form [formGroup]="firstFormGroup">
                                                <ng-template matStepLabel>Payment Followup</ng-template>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" style="padding-top: 5px; margin-right: 160px;">
                                                                    <!-- 																<mat-spinner></mat-spinner> -->
                                                                    <mat-progress-bar mode="indeterminate" style="color: red;"></mat-progress-bar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                        Please Wait Loading Details.....</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="row" style="border-bottom: 1px solid #cebd9f;">
                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="control">
                                                                        <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                                                                            <label class="label_custome">Action
																				Type</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-search"></i>
																					</span>
                                                                                </div>
                                                                                <input #actionType id="{{pageId}}actionType" name="actionType" type="text" class="form-control" placeholder="Select Action Type" (selectItem)="actionTypeFocus($event)" [ngbTypeahead]="actionTypeSearchTA" (focus)="focusActionTypeTA$.next($any($event).target.value)"
                                                                                    (click)="clickTA(instanceActionType)" #instanceActionType="ngbTypeahead" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Agent Name</label>
                                                                            <input id="{{pageId}}fromStation" type="text" class="form-control" [(ngModel)]="modelFromStation" [ngbTypeahead]="searchFromStation" [resultFormatter]="formatterFromStation" [inputFormatter]="formatterFromStation" (focus)="focusFromStationTA$.next($any($event).target.value)"
                                                                                placeholder="Select Agent" (selectItem)="agentSearchViewDropDownListner($event)" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="tppartyView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Search Mode</label>
                                                                            <select class="custom-select col-12" id="{{pageId}}reportModeType" #reportModeType (change)="reportModeTypeListner(reportModeType.value)">                
                                                                                <option selected value="DateWise">Date Wise</option>
                                                                                <option  value="tillDate">Till Date</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-12 col-md-2" *ngIf="viewDateWiseField">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates"
                                                                                [(ngModel)]="modelFromDate">
                                                                            <div class="input-group-append" (click)="fromDates.toggle()">
                                                                                <span class="input-group-text"> <i class="fa fa-calendar"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 col-md-2" *ngIf="viewDateWiseField">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker"
                                                                                [(ngModel)]="modelToDate">
                                                                            <div class="input-group-append" (click)="toDates.toggle()">
                                                                                <span class="input-group-text"> <i class="fa fa-calendar"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="tppartyView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Consignor Index</label>
                                                                            <input #consignorIndexSearchView id="{{pageId}}consignorIndexSearchView" name="consignorIndexSearchView" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorSearchViewDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchViewTypeTA"
                                                                                (focus)="focusConsignorIndexSearchViewTA$.next($any($event).target.value)" (click)="clickTA(instanceConsignorIndexSearchView)" #instanceConsignorIndexSearchView="ngbTypeahead"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="tppartyView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Consignor Name</label>
                                                                            <input #consignorNameSearchView id="{{pageId}}consignorNameSearchView" name="consignorNameSearchView" type="text" class="form-control" (selectItem)="rowSelectedConsignorSearchView($event)" [(ngModel)]="modelConsignorNameSearchView" [ngbTypeahead]="consignorNameSearchViewTypeTA"
                                                                                [resultFormatter]="formatterConsignorNameSearchView" [inputFormatter]="formatterConsignorNameSearchView" (focus)="focusConsignorNameSearchViewTA$.next($any($event).target.value)"
                                                                                placeholder="Select Consignor Name" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="tpNoView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">Statement No</label>
                                                                            <input #tpCode type="text" id="{{pageId}}tpCode" [(ngModel)]="tpCodeModel" class="form-control" aria-describedby="basic-addon11">
                                                                            <input #tpNo type="text" id="{{pageId}}tpNo" class="form-control" aria-describedby="basic-addon11">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <!-- <button type="button" style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" (click)="getLRDetailsForFollowup();">
                                                                                <i title="Get Details" class="fas fa-search"></i>
                                                                            </button> -->
                                                                            <div>
                                                                                <button type="button" class="btn btn-dark m-r-10" style="background-color:blueviolet;margin-top:20px;font-size:14px;" (click)="getLRDetailsForFollowup();">Get Details</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="tppartyView" class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">To Party Name</label>
                                                                            <input #topartyName id="{{pageId}}topartyName" name="topartyName" type="text" class="form-control" (selectItem)="rowSelectedToparty($event)" [(ngModel)]="modelTopartyName" [ngbTypeahead]="topartyNameSearchTA" [resultFormatter]="formatterTopartyName" [inputFormatter]="formatterTopartyName"
                                                                                (focus)="focusTopartyNameTA$.next($any($event).target.value)" placeholder="Select Toparty Name" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label class="label_custome">LR Number</label>
                                                                            <input #memoNumberForAutomatic type="text" id="{{pageId}}memoNumberForAutomatic" (keyup)="getMemoNumberForAutomatic($event,'keyEnter')" class="form-control" aria-describedby="basic-addon11">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-12 col-md-2">
                                                                    <div class="form-group">
                                                                        <label>Date</label>
                                                                        <div class="input-group">
                                                                            <input class="form-control" name="completedOnDate" id="{{pageId}}completedOnDate" [(ngModel)]="tdyCompletedOnDate" [ngModelOptions]="{standalone: true}" ngbDatepicker (click)="completedOnDate.toggle()" #completedOnDate="ngbDatepicker">

                                                                            <div class="input-group-append" (click)="completedOnDate.toggle()">
                                                                                <span class="input-group-text"> <i
																						class="fa fa-calendar"></i>
																				</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row  p-t-10">
                                                                <div class="col-md-6">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Pending LR Details
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}assingSelectedBtn" (click)="validateSelectedBtn();">Move Selected</button>
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}assingAllBtn" (click)="validateAllBtn();">ALL</button>
                                                                            </div>
                                                                        </div>
                                                                        <table datatable id="{{pageId}}pendingLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPendingLrsDetails" [dtTrigger]="dtTriggerPendingLrDetails">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Action</th>
                                                                                    <th>Cashmemo</th>
                                                                                    <th>LR Number</th>
                                                                                    <th>Amount</th>
                                                                                    <th [hidden]="!showTpStmtNo">Stmt No</th>
                                                                                    <th>Consignor Name</th>
                                                                                    <th>Agent Name</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let pendingLrsDataForSearch of pendingLrsDataListForSearch; let i=index ">
                                                                                    <td><input type="checkbox" [checked]="multiSelect" id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, pendingLrsDataForSearch,i)" />
                                                                                    </td>
                                                                                    <td>{{ pendingLrsDataForSearch.memoNumber }}
                                                                                    </td>
                                                                                    <td>{{ pendingLrsDataForSearch.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ pendingLrsDataForSearch.grandTotal }}
                                                                                    </td>
                                                                                    <td [hidden]="!showTpStmtNo">{{ pendingLrsDataForSearch.statementNo }}
                                                                                    </td>
                                                                                    <td>{{ pendingLrsDataForSearch.consignor }}
                                                                                    </td>
                                                                                    <td>{{ pendingLrsDataForSearch.agentName }}
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">Total</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td [hidden]="!showTpStmtNo"></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <!-- 							the first datatable ends  -->
                                                                <!-- 							<the second datatable starts  -->
                                                                <div class="col-md-6 vl">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Assigned Job Details
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}removeAllBtn" (click)="validateRemoveAllBtn();">Remove
																					All</button>

                                                                            </div>
                                                                        </div>
                                                                        <table datatable id="{{pageId}}assignedLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionPfAssignedJobDetails" [dtTrigger]="dtTriggerPfAssignedJobDetails">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Cashmemo</th>
                                                                                    <th>LR Number</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Consignor Name</th>
                                                                                    <th [hidden]="!showTpStmtNo">Stmt No</th>
                                                                                    <th>Agent Name</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let pfAssignedJobDetailsData of pfAssignedJobDetailsDataList let i = index ">
                                                                                    <td>{{ pfAssignedJobDetailsData.memoNumber }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.grandTotal }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.consignor }}
                                                                                    </td>
                                                                                    <td [hidden]="!showTpStmtNo">{{ pfAssignedJobDetailsData.statementNo }}
                                                                                    </td>
                                                                                    <td>{{ pfAssignedJobDetailsData.agentName }}
                                                                                    </td>
                                                                                    <td><button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForAssignedTable(pfAssignedJobDetailsData);">
																							<i title="Remove"
																								class="fas fa-trash"></i>
																						</button></td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">Total</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td [hidden]="!showTpStmtNo"></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <!--							 the second datatable ends  -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 									the payment followup ends -->
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <button *ngIf="viewFinishBtn" type="button" class="btn btn-dark m-r-10" (click)="followupFinish()" id="{{pageId}}finishBtn">Finish</button>
                                                        <button *ngIf="showNextBtnInAssign" type="button" class="btn btn-dark m-r-10" id="{{pageId}}nextBtnInAssign" (click)="validateNextBtnInAssign()">Next</button>
                                                        <button *ngIf="viewShowNexInAssingBtn2" id="{{pageId}}nextBtnAssign" class="btn btn-success m-r-10" id="{{pageId}}nextBtn" mat-button (click)="showTheConsignorWiseDetails()" matStepperNext>Next</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </mat-step>
                                        <!-- 								one flow is ends -->
                                        <!-- 								 						 two flow is starts  -->
                                        <!-- 								the less entry starts -->
                                        <mat-step [stepControl]="secondStepGroup">
                                            <form [formGroup]="secondFormGroup">
                                                <ng-template matStepLabel>Less Entry</ng-template>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <!-- Row -->
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Consignor Index</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                                                    (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Consignor Name</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" (selectItem)="rowSelectedConsignor($event)" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" [resultFormatter]="formatterConsignorName"
                                                                                    [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" placeholder="Select Consignor Name" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Total Less</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-rupee-sign"></i>
																					</span>
                                                                                </div>
                                                                                <input #totalLess type="number" id="{{pageId}}totalLess" name="totalLess" class="form-control" aria-describedby="basic-addon11" (keyup)="validateTotalLess($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group" style="margin-bottom: 0px !important">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxTDS" #checkboxTDS (change)="toggleEditableForTDS($event, contentLessEntryTds)">
                                                                                <label class=" custom-control-label wtfull" for="{{pageId}}checkboxTDS">TDS Bulk
																					Entry</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group" id="{{pageId}}popupDetailsLessEntryTds">
                                                                                <ng-template #contentLessEntryTds let-ok="close" let-d="dismiss">
                                                                                    <div class="modal-header">
                                                                                        <h5>TDS Bulk Entry</h5>
                                                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
																							<span
																								aria-hidden="true">&times;</span>
																						</button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                        <div class="row" style="">
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Total
																											Memo</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
																												<i
																													class="fas fa-rupee-sign"></i>
																											</span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}totalMemoForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Memo
																											Amount</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
																												<i
																													class="fas fa-rupee-sign"></i>
																											</span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}memoAmountForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>Less</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
																												<i
																													class="fas fa-minus"></i>
																											</span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}lessForTDS" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-sm-12 col-md-12">
                                                                                                <div class="form-group">
                                                                                                    <div class="input-group">
                                                                                                        <label>TDS
																											Less(2%)</label>
                                                                                                        <div class="input-group-prepend">
                                                                                                            <span class="input-group-text">
																												<i
																													class="fas fa-percent"></i>
																											</span>
                                                                                                        </div>
                                                                                                        <input type="number" id="{{pageId}}tdsLessTwoPer" class="form-control" aria-describedby="basic-addon11">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-12 p-t-10" style="text-align: right;">
                                                                                                <button type="button" class="btn btn-outline-secondary" (click)="tdsInsert()">Apply
																									TDS Bulk
																									Discount</button>
                                                                                                <button type="button" class="btn btn-outline-secondary" (click)="ok('Cancel click'); uncheck();">Close</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-3 vl">
                                                                    <!-- the sixth autocomplete ends -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>LR Number</label>
                                                                                <input #memoNumberForAutomaticLess type="text" id="{{pageId}}memoNumberForAutomaticLess" name="memoNumberForAutomaticLess" (keyup)="getMemoNumberForEntryLess($event,'scan')" class="form-control" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!--the sixth autocomplete starts -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                                                                                <div class="input-group">
                                                                                    <label>Less Type</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
																							<i class="fas fa-minus"></i>
																						</span>
                                                                                    </div>
                                                                                    <input #lessType id="{{pageId}}lessType" name="lessType" type="text" class="form-control" placeholder="Select Less Type" [(ngModel)]="modelLessType" [ngModelOptions]="{standalone: true}" (selectItem)="lessTypeFocus($event)" [ngbTypeahead]="lessTypeSearchTA"
                                                                                        (focus)="focusLessTypeTA$.next($any($event).target.value)" (click)="clickTA(instanceLessType)" #instanceLessType="ngbTypeahead" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the sixth autocomplete ends -->
                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Amount</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-rupee-sign"></i>
																					</span>
                                                                                </div>
                                                                                <input #entryLessAmount type="number" id="{{pageId}}entryLessAmount" name="entryLessAmount" class="form-control" (keyup)="validateLessAmount($event)" aria-describedby="basic-addon11">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-12" style='display: none;'>
                                                                        <div class="form-group">
                                                                            <div class="input-group">

                                                                                <input type="number" id="{{pageId}}hiddenIndexForLessEntry" class="form-control" aria-describedby="basic-addon11">&nbsp;

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 vl">
                                                                    <div class="box-body">

                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Less Entries</h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                            </div>
                                                                        </div>
                                                                        <!-- 							<the third datatable starts  -->
                                                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLessEntriesDataTable" [dtTrigger]="dtTriggerLessEntriesDataTable">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>LR Number</th>
                                                                                    <th>Memo Amount</th>
                                                                                    <th>Less Amount</th>
                                                                                    <th>Less Description</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let lessEntriesDataTableData of lessEntriesDataTableDataList; let i = index">
                                                                                    <td>{{ lessEntriesDataTableData.chqType }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.cashMemoAmt }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.chqAmount }}
                                                                                    </td>
                                                                                    <td>{{ lessEntriesDataTableData.chqNumber }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditForLessEntry(lessEntriesDataTableData,i);">
																							<i title="Edit"
																								class="fas fa-pencil-alt"></i>
																						</button>&nbsp;
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForLessEntry(lessEntriesDataTableData,i)">
																							<i title="Remove"
																								class="fas fa-trash"></i>
																						</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">
                                                                                        {{countOfLessLR}}</td>
                                                                                    <td></td>
                                                                                    <td>{{sumOfLessAmt}}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                        <!-- 							<the third datatable ends  -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <button *ngIf="!viewNextBtn" type="button" class="btn btn-dark m-r-10" id="{{pageId}}entryLessNextBtnCheck" (click)="validateNextBtn($event)">Next</button>
                                                        <button *ngIf="viewNextBtn" class="btn btn-success m-r-10" id="{{pageId}}nextToPaymentEntryBtn" (click)="setPaymentEntry()" mat-button matStepperNext>Next</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </mat-step>
                                        <!-- 								two flow is end -->
                                        <!-- 								three flow is starts -->
                                        <mat-step [stepControl]="thirdStepGroup">
                                            <form [formGroup]="thirdFormGroup">
                                                <ng-template matStepLabel>Payment Entry</ng-template>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" style="padding-top: 5px; margin-right: 160px;">
                                                                    <mat-progress-bar mode="indeterminate" style="color: red;"></mat-progress-bar>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                        Saving Payment Details Please Wait.....</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Row -->
                                                        <div class="card-body">
                                                            <div class="row p-t-10 p-b-10">
                                                                <div class="col-md-3">

                                                                    <div class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Payment Mode</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-search"></i>
																					</span>
                                                                                </div>
                                                                                <select class="custom-select col-12" id="{{pageId}}paymentMode" name="paymentMode" #paymentMode (change)="paymentModeMode(paymentMode.value)">
																					<option value="select" selected>
																						Select
																						Payment Mode</option>
																					<option value="cheque">Cheque/PDC
																					</option>
																					<option value="neft">NEFT/IMPS
																					</option>
                                                                                    <option value="upi">UPI
																					</option>
																					<option value="cash">Cash</option>
																				</select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="viewByCash" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Total Amount</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-rupee-sign"></i>
																					</span>
                                                                                </div>
                                                                                <input #totalAmountForPayment type="number" id="{{pageId}}totalAmountForPayment" class="form-control" (keyup)="validateTotalLess($event)" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="viewByChequeNumber" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Cheque Number</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-file-alt"></i>
																					</span>
                                                                                </div>
                                                                                <input type="text" id="{{pageId}}chequeNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="fieldFocusChqNo($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="viewByChequeDate" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Cheque Date</label> <input id="{{pageId}}chequeDate" class="form-control" name="chequeDates" ngbDatepicker #chequeDates="ngbDatepicker" (keyup)="focusChqDateEnter($event)"
                                                                                    (ngModelChange)="focusChqDate($event);" (keydown.arrowdown)="chequeDates.toggle();">
                                                                                <div class="input-group-append" (click)="chequeDates.toggle();">
                                                                                    <span class="input-group-text"> <i
																							class="fa fa-calendar"></i>
																					</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!--the seventh autocomplete ends -->
                                                                    <div *ngIf="viewByConsigneeBankName" class="col-sm-12 col-md-12">
                                                                        <div class="control">
                                                                            <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                                <div class="input-group">
                                                                                    <label>Bank Name</label>
                                                                                    <input #consigneeBankName id="{{pageId}}consigneeBankName" name="consigneeBankName" type="text" placeholder="Select Bank" (selectItem)="partyBankMethod($event)" [ngbTypeahead]="consigneeBankNameSearchTA" (focus)="focusConsigneeBankNameTA$.next($any($event).target.value)"
                                                                                        (click)="clickTA(instanceConsigneeBankName)" #instanceConsigneeBankName="ngbTypeahead" />
                                                                                    <span style="background-color: orange; color: #fff" class="input-group-text"> <i
																							class="fas fa-undo"
																							title="Relaod Bank Name"
																							style="cursor: pointer;"
																							(click)="reloadBank()"></i>
																					</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="viewNewBankName" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>New Bank Name</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-university"></i>
																					</span>
                                                                                </div>
                                                                                <input #newBankName type="text" class="form-control" id="{{pageId}}newBankName" name="newBankName" (keyup)="fieldFocusNewBank($event)" aria-describedby="basic-addon11">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- 											the seventh autocomplete ends -->
                                                                    <div *ngIf="viewByReferenceNumber" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <label>Reference Number</label>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
																							class="fas fa-file-alt"></i>
																					</span>
                                                                                </div>
                                                                                <input #referenceNumber type="text" id="{{pageId}}referenceNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="fieldFocusRef($event)">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="showForCheckandNeft" class="col-sm-12 col-md-12">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <h6 class="card-title">LR Detail Entries
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="showForCheckandNeft" class="col-sm-12 col-md-12">
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>LR Number</label>
                                                                                    <input #memoNumberForAutomaticLessPayment type="text" id="{{pageId}}memoNumberForAutomaticLessPayment" name="memoNumberForAutomaticLessPayment" (keyup)="getMemoNumberForEntryLess($event,'scan')" class="form-control" aria-describedby="basic-addon11">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Amount</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"> <i
                                                                                                class="fas fa-rupee-sign"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="number" id="{{pageId}}paymentLessAmount" class="form-control" (keyup)="validateLessAmount($event)" aria-describedby="basic-addon11">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12" style='display: none;'>
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <input type="number" id="{{pageId}}hiddenIndexForPaymentEntry" class="form-control" aria-describedby="basic-addon11">&nbsp;

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="viewByCashs" class=col-md-12>
                                                                        <div class="card card-hover imb_custome">
                                                                            <div class="card-body" style="background-color: lightcyan;">
                                                                                <h5 class="card-title">Denomination Details
                                                                                </h5>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label class="">2000
																									X:</label> <input type="number" id="{{pageId}}twoThousandRupeeCount" class="form-control" [(ngModel)]="twoThousandRupeeCountModel"
                                                                                                    [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoThousandRupeeEvent($event)" (blur)="twoThousandRupeeBlur( $event)">
                                                                                                <input type="number" id="{{pageId}}twoThousandRupee" class="form-control" [(ngModel)]="twoThousandRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>500 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveHundredRupeeCount" [(ngModel)]="fiveHundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiveHundredRupeeEvent($event)" (blur)="fiveHundredRupeeBlur( $event)">
                                                                                                <input type="number" id="{{pageId}}fiveHundredRupee" class="form-control" [(ngModel)]="fiveHundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>200 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoHundredRupeeCount" [(ngModel)]="twoHundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoHundredRupeeEvent($event)" (blur)="twoHundredRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoHundredRupee" [(ngModel)]="twoHundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>100 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}hundredRupeeCount" [(ngModel)]="hundredRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="hundredRupeeEvent($event)" (blur)="hundredRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}hundredRupee" [(ngModel)]="hundredRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>50 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiftyRupeeCount" [(ngModel)]="fiftyRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiftyRupeeEvent($event)" (blur)="fiftyRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiftyRupee" aria-describedby="basic-addon11" [(ngModel)]="fiftyRupeeModel" [ngModelOptions]="{standalone: true}" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>20 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twentyRupeeCount" [(ngModel)]="twentyRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twentyRupeeEvent($event)" (blur)="twentyRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twentyRupee" aria-describedby="basic-addon11" [(ngModel)]="twentyRupeeModel" [ngModelOptions]="{standalone: true}" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>10 X
																									:</label><input type="number" class="form-control" id="{{pageId}}tenRupeeCount" [(ngModel)]="tenRupeeCountModel" [ngModelOptions]="{standalone: true}"
                                                                                                    aria-describedby="basic-addon11" (keyup)="tenRupeeEvent($event)" (blur)="tenRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}tenRupee" [(ngModel)]="tenRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>5 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveRupeeCount" [(ngModel)]="fiveRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="fiveRupeeEvent($event)" (blur)="fiveRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}fiveRupee" [(ngModel)]="fiveRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>2 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoRupeeCount" [(ngModel)]="twoRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="twoRupeeEvent($event)" (blur)="twoRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}twoRupee" [(ngModel)]="twoRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>1 X :</label>
                                                                                                <input type="number" class="form-control" id="{{pageId}}oneRupeeCount" [(ngModel)]="oneRupeeCountModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" (keyup)="oneRupeeEvent($event)" (blur)="oneRupeeBlur( $event)">
                                                                                                <input type="number" class="form-control" id="{{pageId}}oneRupee" [(ngModel)]="oneRupeeModel" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon11" readonly>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">

                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Total : </label>
                                                                                                <input #totalRupeeDenomination type="number" class="form-control" id="{{pageId}}totalRupeeDenomination" aria-describedby="basic-addon11" readonly>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div [hidden]="!showForCheckandNeft" class="col-md-4 vl">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-6">
                                                                                <h6 class="card-title">Select LRs for Payment
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-6" style="text-align: right;">
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}paymentLrsSelectedBtn" (click)="validatePaymentLrsSelectedBtn();">Move Selected</button>
                                                                                <button type="button" class="btn btn-danger m-r-10" style="padding: 1px 4px;" id="{{pageId}}paymentLrsAllBtn" (click)="validatePaymentLrsAllBtn();">ALL</button>
                                                                            </div>
                                                                        </div>
                                                                        <table datatable id="{{pageId}}paymentLrSelected" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSelectLRsForPayment" [dtTrigger]="dtTriggerSelectLRsForPayment">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Action</th>
                                                                                    <th>LR Number</th>
                                                                                    <th>Memo Amount</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let paymentAmountSelectedLrsData of pfAssignedLRsForPaymentList; let i = index">
                                                                                    <td><input type="checkbox" [checked]="multiSelect" id='paymentLrSelected' style="margin-left: 20px" (change)="rowCheckBoxPaymentLrChecked($event, paymentAmountSelectedLrsData)"
                                                                                        />
                                                                                    </td>
                                                                                    <td>{{ paymentAmountSelectedLrsData.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountSelectedLrsData.grandTotal }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">Total</td>
                                                                                    <td>{{pfAssignedLRsForPaymentList.length}}</td>
                                                                                    <td>{{totalAmountForPaymentEntries}}</td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="showForCheckandNeft" class="col-md-5 vl">
                                                                    <div class="box-body">

                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Amount Entries
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                            </div>
                                                                        </div>
                                                                        <!-- the fifth datatable starts  -->
                                                                        <table datatable id="{{pageId}}amountEntriesTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPaymentAmountEntries" [dtTrigger]="dtTriggerPaymentAmountEntries">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>LR Number</th>
                                                                                    <th>Memo Amount</th>
                                                                                    <th>Less Amount</th>
                                                                                    <th>Less Description</th>
                                                                                    <th>Balance Amount</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                                                <tr *ngFor="let paymentAmountEntriesData of paymentAmountEntriesDataList; let i = index">
                                                                                    <td>{{ paymentAmountEntriesData.chqType }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.cashMemoAmt }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.chqAmount }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.chqNumber }}
                                                                                    </td>
                                                                                    <td>{{ paymentAmountEntriesData.balance }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditForPaymentEntry(paymentAmountEntriesData,i);">
																							<i title="Edit"
																								class="fas fa-pencil-alt"></i>
																						</button>&nbsp;
                                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForPaymentEntry(paymentAmountEntriesData,i);">
																							<i title="Remove"
																								class="fas fa-trash"></i>
																						</button>
                                                                                    </td>

                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td style="text-align: left">
                                                                                        {{countOfPaymentMemo}}</td>
                                                                                    <td>{{sumOfMemoAmtPayment}}</td>
                                                                                    <td>{{sumOfLessAmtPayment}}</td>
                                                                                    <td></td>
                                                                                    <td>{{sumOfBalanceAmtPayment}}</td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                        <!-- 							<the fifth datatable ends  -->
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showForCheckandNeft" class="row">
                                                    <div class="col-md-12" style="text-align: right; height: 150px;">
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <!-- 											matStepperPrevious -->
                                                        <button class="btn btn-dark m-r-10" type="button" mat-button matStepperPrevious (click)="callTempoForLess();">Back</button>
                                                        <button *ngIf="showNextBtn" type="button" class="btn btn-success m-r-10" id="{{pageId}}paymentLessNextBtnCheck" (click)="validateNextBtn($event)">Next</button>
                                                        <button class="btn btn-dark m-r-10" type="button" id="{{pageId}}paymentEntryFinishBtn" (click)="validatePaymentEntryFinishBtn($event)" mat-button>Finish</button>
                                                        <button *ngIf="showResetBtn" type="button" class="btn btn-success m-r-10" mat-button (click)="stepper.reset();resetAll()">Reset</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </mat-step>
                                        <!-- 								three flow is ends -->
                                        <!-- 								four flow is starts -->
                                    </mat-horizontal-stepper>
                                </div>
                            </div>

                            <!--the datatable and amount summary starts -->
                            <div [hidden]="!consignorWiseDetails" class="row p-t-10">
                                <div class="col-md-5">
                                    <div class="box-body">
                                        <h6 class="card-title">Consignor Wise Balance Details</h6>
                                        <!-- 							<the fourth datatable starts  -->
                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsignorWiseBalanceDetails" [dtTrigger]="dtTriggerConsignorWiseBalanceDetails">

                                            <thead>
                                                <tr>
                                                    <th>Consignor</th>
                                                    <th>LRs</th>
                                                    <th>Amount</th>
                                                    <th>Less</th>
                                                    <th>Cheque</th>
                                                    <th>NEFT</th>
                                                    <th>Bal.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!--  class='dragndrop' [dragula]='"first-bag"' -->
                                                <tr *ngFor="let consigneeWiseBalanceDetailsData of consignorWiseBalanceDetailsDataList ">
                                                    <td>{{ consigneeWiseBalanceDetailsData.consignor }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.totalCashMemo }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.grandTotal }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.partyLessDed }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.advChqAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.neftAmt }}</td>
                                                    <td>{{ consigneeWiseBalanceDetailsData.balance }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style="text-align: left">{{countOfConsingeeWiseMemo}}</td>
                                                    <td>{{sumOfConsingeeWiseTotalCashMemo}}</td>
                                                    <td>{{sumOfConsingeeWiseGrandTotal}}</td>
                                                    <td>{{sumOfConsingeeWisePartyLessDed}}</td>
                                                    <td>{{sumOfConsingeeWiseAdvChqAmt}}</td>
                                                    <td>{{sumOfConsingeeWiseNeftAmtl}}</td>
                                                    <td>{{sumOfConsingeeWiseBalance}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <!-- 							<the fourth datatable ends  -->
                                    </div>
                                </div>
                                <div class="col-md-7 vl">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="card card-hover imb_custome">
                                            <div class="card-body" style="background-color: lightcyan;">
                                                <h5 class="card-title">Amount Summary</h5>
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <div class="col-md-12">
                                                            <label class="label_custome">
																<div class=" cssaaa feed-icon bg-inverse">
																	<i class="mdi mdi-currency-inr"></i>
																</div>Less Amount:<span
																	class="ml-auto font-16 text-muted">{{lblLessValue}}</span>
															</label>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <label class="label_custome">
																<div class=" cssaaa feed-icon bg-info">
																	<i class="mdi mdi-currency-inr"></i>
																</div>TDS Less : <span
																	class="ml-auto font-16 text-muted">{{lblTDSLessValue}}</span>
															</label>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="label_custome">
																<div class=" cssaaa feed-icon bg-danger">
																	<i class="mdi mdi-currency-inr"></i>
																</div>Claim Less:<span
																	class="ml-auto font-16 text-muted">{{lblClaimLessValue}}</span>
															</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7 vl_custome">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-info">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Amount To Be Collected:<span
																		class="ml-auto font-16 text-muted">{{amountToBeCollected}}</span>
																</label>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-warning">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Total Less :<span *ngIf="!showLessLbl"
																		class="ml-auto font-16 text-muted">{{lblTotLessSummaryValue}}</span><span
																		*ngIf="showLessLbl"
																		class="ml-auto font-16 text-muted"
																		style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
																		(click)="callTempoForLessForDataTable();">{{lblTotLessSummaryValue}}</span>
																</label>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-danger">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Total Amount In Cheque/PDC :<span
																		*ngIf="!showChqLbl"
																		class="ml-auto font-16 text-muted">{{lblTotChqAmtSummaryValue}}</span><span
																		*ngIf="showChqLbl"
																		class="ml-auto font-16 text-muted"
																		style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
																		(click)="callTempoForChq();">{{lblTotChqAmtSummaryValue}}</span>
																</label>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-inverse">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Total Amount In NEFT/IMPS :<span
																		*ngIf="!showNEFTLbl"
																		class="ml-auto font-16 text-muted">{{lblTotNeftAmtSummaryValue}}</span><span
																		*ngIf="showNEFTLbl"
																		class="ml-auto font-16 text-muted"
																		style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
																		(click)="callTempoForNEFT();">{{lblTotNeftAmtSummaryValue}}</span>
																</label>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-inverse">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Total Amount In UPI :<span
																		*ngIf="!showUpiLbl"
																		class="ml-auto font-16 text-muted">{{lblTotUpiAmtSummaryValue}}</span><span
																		*ngIf="showUpiLbl"
																		class="ml-auto font-16 text-muted"
																		style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
																		(click)="callTempoForUpi();">{{lblTotUpiAmtSummaryValue}}</span>
																</label>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-info">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Total Amount In Cash : <span
																		*ngIf="!showCashLbl"
																		class="ml-auto font-16 text-muted">{{lblTotCashAmtSummaryValue}}</span>
																	<span *ngIf="showCashLbl"
																		class="ml-auto font-16 text-muted"
																		style="border-bottom: 1px solid; color: blue !important; cursor: pointer;"
																		(click)="callTempoForCash();">{{lblTotCashAmtSummaryValue}}</span>
																</label>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="label_custome">
																	<div class=" cssaaa feed-icon bg-danger">
																		<i class="mdi mdi-currency-inr"></i>
																	</div>Balance Amount :<span
																		class="ml-auto font-16 text-muted">{{balanceAmountFinal}}</span>
																</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- <hr> -->
                            </div>

                        </div>
                    </div>
                    <!-- Row -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="viewTopartystmtPrint" onafterprint="afterPrint()" id="{{pageId}}printTopartyStmt">
        <app-payment-followup-bkgtoparty-print></app-payment-followup-bkgtoparty-print>
    </div> -->

    <div *ngIf="viewLeftToPartyCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewLeftToPartyCustomPrintV1">
        <app-bkg-left-toparty-details-printV1></app-bkg-left-toparty-details-printV1>
    </div>

    <div *ngIf="viewChallanRptPrint" onafterprint="afterPrint()" id="{{pageId}}printChallanRpt">
        <app-bkg-payment-followup-challan-print></app-bkg-payment-followup-challan-print>
    </div>
</body>