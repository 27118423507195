<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
				Please Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-8">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Trips On Transit
			</h6>
	
		</div>
		<div class="col-md-4">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="gridReconifgureOnReloadBtn()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}tripOnTransitTableId" class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsTripsOnTransit" [dtTrigger]="dtTriggerTripsOnTransit">
			<thead>
				<tr>
					<th>Hireslip Number</th>
					<th>Destination</th>
					<th>Departure Date</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let tripsOnTransitData of tripsOnTransitDataList ">
					<!--<td>{{ tripsOnTransitData.hireslipnumber }}</td>-->
					<td style="color: blue; cursor: pointer; text-decoration: underline;"
						(click)="getSelectedRowOfHireslipDetails(tripsOnTransitData)">
						{{ tripsOnTransitData.hireslipnumber }}</td>
					<td>{{ tripsOnTransitData.toStation }}</td>
					<td>{{ tripsOnTransitData.departuredateStr +" "+tripsOnTransitData.departureTime  }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>