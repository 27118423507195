<!-- 			<button type="button" -->
<!-- 				style="margin-left: 350px;margin-top: -80px;background-color: burlywood;width: 20px;height: 20px;" -->
<!-- 				class="btn m-r-9" id="{{pageId}}tableReloadBtn" (click)="gridReconifgureOnReloadBtn()"> -->
<!-- 				<i title="Reload" class="fas fa-sync-alt fa xs"></i> -->
<!-- 			</button> -->
<!-- 			<i title="Reload" class="fas fa-sync-alt fa xs"style="margin-left: 350px;margin-top: -80px;background-color: burlywood;width: 20px;height: 20px;" -->
<!-- 				class="btn m-r-9" id="{{pageId}}tableReloadBtn" (click)="gridReconifgureOnReloadBtn()"></i> -->
<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px;padding: 2px 5px;background-color: burlywood;"
	(click)="gridReconifgureOnReloadBtn()"></i> -->
<!--<i title="Stock" class="fas fa-sync-alt fa xs cli" style="margin-left: 350px;padding: 2px 5px;background-color: burlywood;"(click)="stockTripSheetBtn()"></i>-->
<div class="col-md-12 col-sm-12">
	<!-- 	<div class="row"> -->
	<!-- 		<div class="col-md-12"> -->
	<!-- 			<button type="button" -->
	<!-- 				style="margin-left: 350px;margin-top: -80px;" -->
	<!-- 				class="btn m-r-10" id="{{pageId}}tableRemoveBtn"> -->
	<!-- 				<i title="Reload" class="fas fa-undo"></i> -->
	<!-- 			</button> -->
	<!-- 		</div> -->
	<!-- 	</div> -->
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-10">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Cash Memo Pending And Missing
					</h6>
			
				</div>
				<div class="col-md-2">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id="{{pageId}}cashMemoPendingAndMissingId" style='max-height:150px;'
			class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCashMemoPendingAndMissing"
			[dtTrigger]="dtTriggerCashMemoPendingAndMissing">

			<thead>
				<tr>
					<th>Batch</th>
					<th>Rem. Memos</th>
					<th>Created By&nbsp;&nbsp;&nbsp;</th>
					<th>Created Date</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let cashMemoPendingAndMissingData of cashMemoPendingAndMissingDataList">
					<td (click)="batchDialog(cashMemoPendingAndMissingData)"><u class="hyperLink_td">{{
							cashMemoPendingAndMissingData.batchNumber }}</u></td>
					<td (click)="remMemosDialog(cashMemoPendingAndMissingData)"><u class="hyperLink_td">{{
							cashMemoPendingAndMissingData.remMemos }}</u></td>
					<td>{{ cashMemoPendingAndMissingData.enteredBy }}</td>
					<td>{{ cashMemoPendingAndMissingData.enterDate }}</td>
				</tr>

			</tbody>
		</table>
	</div>
</div>