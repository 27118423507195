import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList , ChangeDetectorRef} from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { MasterService } from 'src/app/dataService/master-service';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
//for the select option with filter ends

@Component( {
    selector: 'app-commodity-master',
    templateUrl: './commodity-master.component.html',
    styleUrls: ['./commodity-master.component.css']
} )
export class CommodityMasterComponent implements OnInit {

    commodityView = false;
    //    gettingDataFrmServiceFrCommodityMasterTable: any;
    commodityData: any;
    commodityMasterDataList: any;
    commodityMasterPartialDataList: any;
    onDestroyUnsubscribtionCommodityMaster: Subscription;
    loadingIndicator = true;
    controlCommodityList = new FormControl();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoSaveRet: any;
    rateMasterDeleteDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoDeleteRet: any;
    rateMasterUpdateDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoUpdateRet: any;
    mainCommodity: string;
    type: string;
    subCommodity: string;
    commodityMasterTable = true;
    saveBtnShow=true;
    saveBtnHide=false;
    commodityMasterPartialTable = false;
    commodityListOptions = [
        { id: 1, label: '14' },
        { id: 2, label: 'ds' },
        { id: 3, label: 'ww' },
    ];
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerCommodityMaster: Subject<any> = new Subject();
    dtTriggerCommodityMasterPartial: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsCommodityMaster: any;
    dtOptionsCommodityMasterPartial: any;
    pageId="cmmc";

    constructor( private debitNoteMasterScreen: ReportService, private http: HttpClient,
        private router: Router,
        private masterService: MasterService, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

        }
    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }

    ngOnInit(): void {

    }

    commodityMasterDatatable() {
        this.dtOptionsCommodityMaster = {
            //};
            //columns is used to for export and others starts
            //columns is used to for export and others endss
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [

            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //    this.gettingDataFrmServiceFrCommodityMasterTable = this.debitNoteMasterScreen.getSummaryData()
        //    this.onDestroyUnsubscribtionCommodityMaster = this.gettingDataFrmServiceFrCommodityMasterTable.subscribe( data => {
        //        this.commodityMasterDataList = data['data'];
        //        this.dtTriggerCommodityMaster.next();
        //    } );

        //the below code is for the getting data through json ends
    }
    commodityMasterPartialDatatable() {
        this.dtOptionsCommodityMasterPartial = {
            //};
            //columns is used to for export and others starts
            //columns is used to for export and others endss
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [

            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
    }

    commodityTypeMode( commodityType: string ) {
        if ( commodityType === 'mainCommodity' ) {
            this.commodityView = false;
        } else if
      ( commodityType === 'subCommodity' ) {
            this.commodityView = true;
        } else {
            this.commodityView = false;
        }
    }
    seachBy( seachBy: string ) {
        
        //        if ( ( seachBy === 'All' ) || ( seachBy === 'Completed' ) ) {
        if ( seachBy === 'All' ) {
            this.commodityMasterTable = true;
            this.commodityMasterPartialTable = false;
//            alert( "In Side of All" );
            this.getCommodityMasterDetailsList();
            
            this.saveBtnShow = false;
            this.saveBtnHide = true;
        } else if ( seachBy === 'Completed' ) {
            this.commodityMasterTable = true;
            this.commodityMasterPartialTable = false;
//            alert( "In Side of Completed" );
            this.getCommodityMasterDetailsList();
            this.saveBtnShow = false;
            this.saveBtnHide = true;
        } else if
      ( seachBy === 'Partial' ) {
            this.commodityMasterTable = false;
            this.commodityMasterPartialTable = true;
//            alert( "In Side of Partial" );
            this.getCommodityMasterDetailsList();
            this.saveBtnShow = false;
            this.saveBtnHide = true;
        } else {
            this.commodityMasterTable = true;
            this.commodityMasterPartialTable = false;
            this.saveBtnShow = true;
            this.saveBtnHide = false;
        }
    }

/// For Save///////////    
    validateCommodityDetails() {
        this.saveUpdateCommodityDetails();
    }
    saveCommodityDetails() {

        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDto.isMultiArtSize = this.rateMasterDto.isMultiArtSize;
        //        console.log(this.rateMasterDto.isMultiArtSize);
        if ( this.rateMasterDto.searchType == "AddNew" ) {
            if ( this.rateMasterDto.selectedCommodityType == "mainCommodity" ) {
                this.rateMasterDto.commodityType = "Main";
                this.rateMasterDto.commodityName = this.rateMasterDto.commodityName;
                this.rateMasterDto.subCommodity = this.rateMasterDto.commodityName;
            } else if ( this.rateMasterDto.selectedCommodityType == "subCommodity" ) {
                this.rateMasterDto.commodityType = "Sub";
                this.rateMasterDto.subCommodity = this.rateMasterDto.commodityName;
                this.rateMasterDto.commodityName = this.rateMasterDto.selectedCommodity;
            }
            this.rateMasterDto.mode = "New";
            this.rateMasterDto.confirm = "Completed";
        }
       

    }
    saveUpdateCommodityDetails = () => {
        this.saveCommodityDetails();
        this.masterService.createCommodity( this.rateMasterDto ).
            subscribe(( data ) => {
                this.rateMasterDtoSaveRet = data;
                //                console.log( this.rateMasterDtoSaveRet.status );
                if ( this.rateMasterDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Commodity Details Saved Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving  Commodity Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Commodity Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    
    

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.mainCommodity = null;
        this.type = null;
        this.subCommodity = null;
        this.rateMasterDto.selectedCommodity = "";
        this.rateMasterDto.selectedCommodityType = "mainCommodity";
        this.commodityView = false;
    }

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = this.rateMasterDto.searchType;
        this.rateMasterDto.status = "Working";
    }

    getCommodityMasterDetailsList = () => {
        //        console.log( this.lrDto.companyId );
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails( this.rateMasterDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    //                    console.log( response );
                    this.commodityData = response;
                    if ( this.rateMasterDto.searchType == "Partial" ) {
                        this.commodityMasterPartialDataList = this.commodityData;
                        this.dtTriggerCommodityMasterPartial.next();
//                        console.log( this.commodityMasterPartialDataList[0] );
//                        console.log( "<<<Partial>>>" );
                    } else if ( ( this.rateMasterDto.searchType == "All" ) || ( this.rateMasterDto.searchType == "Completed" ) ) {
                        this.commodityMasterDataList = this.commodityData;
                        this.dtTriggerCommodityMaster.next();
//                        console.log( "<<<ALL_Completed>>>" );
                    }



                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );
                this.changeDetectorRef.detectChanges();

                //                }
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    //to insert value of selected row in table to input field starts
    //    rowSelected( natureOfPackData ) {
    //        this.selectedName = natureOfPackData.packNature;
    //        this.packId = natureOfPackData.id;
    //        //        console.log( this.selectedName );
    //    }
    //to insert value of selected row in table to input field ends
    ngOnDestroy(): void {
        this.dtTriggerCommodityMaster.unsubscribe();
        this.dtTriggerCommodityMasterPartial.unsubscribe();

        //        this.onDestroyUnsubscribtionCommodityMaster.unsubscribe();
    }

    //to insert value of selected row in table to input field starts
    rowSelected( commodityMasterPartialData ) {
        this.rateMasterDto.commodityName = commodityMasterPartialData.subCommodity;
        this.mainCommodity = commodityMasterPartialData.commodityName;
        this.type = commodityMasterPartialData.commodityType;
        this.subCommodity = commodityMasterPartialData.subCommodity;
//        console.log( 'this is ' + this.subCommodity );
        if ( this.type == "Main" ) {
            this.rateMasterDto.selectedCommodityType = "mainCommodity";
            this.commodityView = false;
            this.rateMasterDto.selectedCommodity = "";
        } else {
            this.rateMasterDto.selectedCommodityType = "subCommodity";
            this.commodityView = true;
            this.rateMasterDto.selectedCommodity = commodityMasterPartialData.commodityName;
        }



        //        console.log( this.selectedName );
    }
    //to insert value of selected row in table to input field ends
    
    //////////For Update
    validateUpdateCommodityDetails() {
        this.updateCommodityDetails();
    }
    updateGetCommodityDetails() {
        this.rateMasterUpdateDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterUpdateDto.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterUpdateDto.isMultiArtSize = this.rateMasterDto.isMultiArtSize;
            if ( this.rateMasterDto.selectedCommodityType == "mainCommodity" ) {
                this.rateMasterUpdateDto.commodityType = "Main";
                this.rateMasterUpdateDto.commodityName = this.rateMasterDto.commodityName;
                this.rateMasterUpdateDto.subCommodity = this.rateMasterDto.commodityName;
            } else if ( this.rateMasterDto.selectedCommodityType == "subCommodity" ) {
                this.rateMasterUpdateDto.commodityType = "Sub";
                this.rateMasterUpdateDto.subCommodity = this.rateMasterDto.commodityName;
                this.rateMasterUpdateDto.commodityName = this.rateMasterDto.selectedCommodity;
            }
            this.rateMasterUpdateDto.selectedCommodity = this.mainCommodity;
            this.rateMasterUpdateDto.description = this.type;
            this.rateMasterUpdateDto.column1 = this.subCommodity;
            this.rateMasterUpdateDto.mode = "Update";
            this.rateMasterUpdateDto.confirm = "Completed";
    }
   updateCommodityDetails = () => {
        this.updateGetCommodityDetails();
        this.masterService.updateCommodity( this.rateMasterUpdateDto ).
            subscribe(( data ) => {
                this.rateMasterDtoUpdateRet = data;
                //                console.log( this.rateMasterDtoSaveRet.status );
                if ( this.rateMasterDtoUpdateRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Commodity Details Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Updating  Commodity Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Updating Commodity Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    
    /////////For Delete////////
    validateDeleteRow() {
        this.deleteCommodityDetails();
    }

    getDeleteCommodityDetails() {
        this.rateMasterDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDeleteDto.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDeleteDto.selectedCommodity = this.mainCommodity;
        this.rateMasterDeleteDto.description = this.type;
        this.rateMasterDeleteDto.column1 = this.subCommodity;
        this.rateMasterDeleteDto.mode = "Delete";
//        console.log('This is delert'+ this.rateMasterDeleteDto.userName );

    }

    deleteCommodityDetails = () => {
        this.getDeleteCommodityDetails();
                this.masterService.deleteCommodity( this.rateMasterDeleteDto ).
                    subscribe(( data ) => {
                        this.rateMasterDtoDeleteRet = data;
                        if ( this.rateMasterDtoDeleteRet.status == "success" ) {
                            swal( {
                                title: "Success",
                                text: "Commodity Details Removed Successfully",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            } );
                            this.clearAll();
                            //                    this.getCommodityMasterDetailsList();
                        } else {
                            swal( {
                                title: "Error",
                                text: "Error While Deleting Commodity Details",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            } );
                        }
                    } ),
                    ( error ) => swal( {
                        title: "Error",
                        text: "Server Error While Deleting Commodity Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } ),
                    () => console.log( 'done' );
    };
}
