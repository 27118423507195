<h1 class="title_custom" mat-dialog-title>
    <!-- 	<h6 style="margin-bottom: 0px;">Lr Details For -->
    <!-- 		{{getInvoiceNumber}}</h6> -->
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <!-- spinner start-->
            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">Please Wait Loading Details.....</h6>
                    </div>
                </div>
            </div>
            <!-- spinner end-->
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <table datatable id="{{pageId}}lrDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                            <thead>
                                <tr>
                                    <th>Booking Date</th>
                                    <th>LR Number</th>
                                    <th>Agent</th>
                                    <th>Consignee</th>
                                    <th>To Pay</th>
                                    <th>M Less Amt</th>
                                    <th>Total Amt</th>
                                    <th>Articles</th>
                                    <th>Act Wgt</th>
                                    <th>Chg Wgt</th>
                                    <th>Commodity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                    <td>{{ lrDetailsData.bookingDateStr }}</td>
                                    <td>{{ lrDetailsData.lrNumber }}</td>
                                    <td>{{ lrDetailsData.agentName }}</td>
                                    <td>{{ lrDetailsData.consigneeName }}</td>
                                    <td>{{ lrDetailsData.toPay }}</td>
                                    <td>{{ lrDetailsData.mLessAmt }}</td>
                                    <td>{{ lrDetailsData.totalAmount }}</td>
                                    <td>{{ lrDetailsData.totalArticles }}</td>
                                    <td>{{ lrDetailsData.actualWeight }}</td>
                                    <td>{{ lrDetailsData.chargedWeight }}</td>
                                    <td>{{ lrDetailsData.commodityName }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <!-- 	<button class="btn btn-outline-danger" mat-button -->
    <!-- 		(click)="onCancelClick()">Cancel</button> -->
    <button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>