import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MemoService } from 'src/app/dataService/memo-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';

@Component({
    selector: 'app-booking-party-os-report',
    templateUrl: './booking-party-os-report.component.html',
    styleUrls: ['./booking-party-os-report.component.css']
})
export class BookingPartyOsReportComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    setTodayDateOnToDate: any;
    consignorNameSearchViewOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndexSearch: any;
    selectedFromDate: any;
    selectedAgentSearch: any;
    showSpinnerForAction = false;
    showAgentDropdown = false;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    fromStationDataListOptions: any;
    cashMemoDtoSearch: CashMemoDto = new CashMemoDto();
    selectedConsignorIdSearchView: any;
    public modelConsignorNameSearchView: any;
    selectedViewType: any;
    toPartyLRDetailsDataList: Array<any> = [];
    dtTriggertoPartyLRDetails: Subject<any> = new Subject();
    dtOptionstoPartyLRDetails: any;
    toPartyLRSummaryDataList: Array<any> = [];
    dtTriggertoPartyLRSummary: Subject<any> = new Subject();
    dtOptionstoPartyLRSummary: any;
    viewCustomPrintV1 = false;
    viewTopartyDetailTable = true;
    viewTopartySummayTable = false;
    setTodayDateOnFromDate: any;
    pageId = "bposrc"
    searchSrcListInLoop: Array<any> = [];
    sentSrcList: Array<any> = [];


    //For consignor Index search
    public modelConsignorIndexSearchView: any;
    @ViewChild('instanceConsignorIndexSearchView') instanceConsignorIndexSearchView: NgbTypeahead;
    consignorIndexSearchViewTA: Array<any> = [];
    focusConsignorIndexSearchViewTA$ = new Subject<string>();
    consignorIndexSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexSearchViewTA
                : this.consignorIndexSearchViewTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName search
    consignorNameSearchViewTA: Array<PartyMasterDto> = [];
    focusConsignorNameSearchViewTA$ = new Subject<string>();
    consignorNameSearchViewTypeTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameSearchViewTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameSearchViewTA
                : this.consignorNameSearchViewTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorNameSearchView = (x: { consignorName: string }) => x.consignorName;

    //For From Station
    public modelAgentSearchView: any;
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { subStation: string }) => x.subStation;

    //For Consignor Index
    consignorIndexOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    grandTotalArticles = 0;
    grandActWt = 0;
    grandChWt = 0;
    grandMemoAmt = 0;
    grandTotalLRs = 0;

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService,
        private memoService: MemoService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Bkg Payment Followup Show Agent") {
                        this.showAgentDropdown = true;
                    }
                }
            }
            this.getAgentNamesFromStations();
            this.setConsginorIndex();
            this.selectTodayDate();
            //default
            this.selectedViewType = "Detail";
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionstoPartyLRDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 360,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totArt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totActWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totMemoAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total : " + data.length);
                $(api.column(6).footer()).html(totArt);
                $(api.column(7).footer()).html(totActWgt);
                $(api.column(8).footer()).html(totChgWgt);
                $(api.column(9).footer()).html(totMemoAmt);
            }
        }
        this.dtOptionstoPartyLRSummary = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 360,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totLRs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totActWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totMemoAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html("Total : " + data.length);
                $(api.column(1).footer()).html(totLRs);
                $(api.column(2).footer()).html(totArt);
                $(api.column(3).footer()).html(totActWgt);
                $(api.column(4).footer()).html(totChgWgt);
                $(api.column(5).footer()).html(totMemoAmt);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggertoPartyLRDetails.next();
        this.dtTriggertoPartyLRSummary.next();
    }
    ngOnDestroy(): void {
        this.dtTriggertoPartyLRDetails.unsubscribe();
        this.dtTriggertoPartyLRSummary.unsubscribe();
    }

    reportModeMode(reportMode: string) {
        if (reportMode === 'rptModeDetailDate') {
            this.viewTopartyDetailTable = true;
            this.viewTopartySummayTable = false;
            this.selectedViewType = "Detail";
        } else if (reportMode === 'rptModeSummaryWise') {
            this.viewTopartyDetailTable = false;
            this.viewTopartySummayTable = true;
            this.selectedViewType = "Summary";
        }
    }

    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
    }

    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexSearchViewTA.push(this.consignorIndexOptions[i].label);
        }
    }
    consignorSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndexSearchView = e.item;
        console.log(this.modelConsignorIndexSearchView);
        if (this.modelConsignorIndexSearchView != 'ALL') {
            this.getConsignorDetailsSearchViewList();
        }
    }

    getConsignorDetailsSearchViewList() {
        this.consignorNameSearchViewOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndexSearch = this.modelConsignorIndexSearchView;
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndexSearch;
        if (this.selectedIndexSearch != null && this.selectedIndexSearch != '') {
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "index";
            this.getConsignorDetailsSearchView();
        }
    }
    getConsignorDetailsSearchView = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameSearchViewOptions = [];
                    this.consignorNameSearchViewTA = [];
                } else {
                    this.consignorNameSearchViewOptions = response;
                    this.consignorNameSearchViewTA = [];
                    for (let i = 0; i < this.consignorNameSearchViewOptions.length; i++) {
                        this.consignorNameSearchViewTA.push(this.consignorNameSearchViewOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    }

    getFromStationsRead(string) {
        this.fromAgentDto.mode = "mainBranch";
        this.fromAgentDto.status = "Working";
        this.fromAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fromAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.fromAgentDto.column2 = "working";
        if (string == "Self") {
            this.fromAgentDto.column3 = "Self";
        } else if (string == "Agent") {
            this.fromAgentDto.column3 = "Agent";
        }
    }

    getAgentNamesFromStations = () => {
        if (!this.showAgentDropdown) {
            this.fromStationTA = [];
            this.agentDtoFromStationAll.subStation = this.userDataDtoReturnSession.office;
            this.fromStationTA.push(this.agentDtoFromStationAll);
        } else {
            this.getFromStationsRead(String);
            this.masterReadService.getSourceListDetails(this.fromAgentDto).subscribe(
                (response) => {
                    this.fromStationDataListOptions = response;
                    this.fromStationTA = [];
                    this.searchSrcListInLoop = [];
                    this.agentDtoFromStationAll.subStation = "ALL";
                    this.fromStationTA.push(this.agentDtoFromStationAll);
                    for (let i = 0; i < this.fromStationDataListOptions.length; i++) {
                        this.fromStationTA.push(this.fromStationDataListOptions[i]);
                        this.searchSrcListInLoop.push(this.fromStationDataListOptions[i].subStation);
                    }
                }),
                (error) => {
                    swal("Error", "Server Problem Occurred While getting the Agent details", "info");
                },
                () => console.log('done');
        }
    }

    rowSelectedConsignorSearchView(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsignorNameSearchView = e.item;
        this.selectedConsignorIdSearchView = this.modelConsignorNameSearchView.consignorId;
    }

    searchMethod() {
        this.getToPartyLrDetailsInfo();
    }

    getToPartyLrDetailsInfo() {
        this.selectedFromDate = $("#" + this.pageId + "fromDates").val();
        this.selectedAgentSearch = $("#" + this.pageId + "fromStation").val();

        this.cashMemoDtoSearch = new CashMemoDto();
        this.cashMemoDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.cashMemoDtoSearch.fromDate = this.selectedFromDate;
        this.cashMemoDtoSearch.consignor = this.modelConsignorIndexSearchView;
        this.cashMemoDtoSearch.consignorId = this.selectedConsignorIdSearchView;
        this.cashMemoDtoSearch.source = this.selectedAgentSearch;
        this.cashMemoDtoSearch.mode = this.selectedViewType;

        if (this.selectedAgentSearch == '' || this.selectedAgentSearch == null || this.selectedAgentSearch == 'ALL') {
            this.sentSrcList = [];
            this.sentSrcList = this.searchSrcListInLoop;
            this.cashMemoDtoSearch.list = this.sentSrcList;
        } else {
            this.sentSrcList = [];
            this.sentSrcList.push(this.selectedAgentSearch);
            this.cashMemoDtoSearch.list = this.sentSrcList;
        }

        if (this.selectedFromDate == null || this.selectedFromDate == '' ||
            this.selectedAgentSearch == null || this.selectedAgentSearch == '' ||
            this.modelConsignorIndexSearchView == null || this.modelConsignorIndexSearchView == '') {
            swal("Not Allowed", "Please select the mandatory fields to get the details!", "warning");
        } else {
            if (this.selectedViewType == 'Detail') {
                this.getToPartyLrDetails();
            } else if (this.selectedViewType == 'Summary') {
                this.getToPartySummary();
            }
        }

    }

    getToPartyLrDetails = () => {
        this.showSpinnerForAction = true;
        this.memoService.getBkgPartyOsReport(this.cashMemoDtoSearch).subscribe(
            (response) => {
                this.toPartyLRDetailsDataList = [];
                $("#" + this.pageId + "toPartyLRDetailsTable").DataTable().destroy();
                if (response.length > 0) {
                    this.toPartyLRDetailsDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggertoPartyLRDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the details", "warning");
            }, () => console.log('done');
    }

    getToPartySummary = () => {
        this.showSpinnerForAction = true;
        this.memoService.getBkgPartyOsReport(this.cashMemoDtoSearch).subscribe(
            (response) => {
                this.toPartyLRSummaryDataList = [];
                $("#" + this.pageId + "toPartyLRSummaryTable").DataTable().destroy();
                if (response.length > 0) {
                    this.toPartyLRSummaryDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.dtTriggertoPartyLRSummary.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the details", "warning");
            }, () => console.log('done');
    }

    agentSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentSearchView = e.item;
    }

    customPrint() {
        if (this.selectedViewType == 'Detail') {
            this.customPrintDetails();
        } else if (this.selectedViewType == 'Summary') {
            this.customPrintSummary();
        }
    }

    customPrintDetails() {
        if (this.toPartyLRDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Consignor Name", "Consignee Name", "Agent Name", "Destination", "Booking Date", "LR No", "Art", "A.Wt", "C.Wt", "Memo Amt", "Status"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [14, 15, 12, 10, 10, 10, 4, 5, 5, 7, 8];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalArticles = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandMemoAmt = 0;

            for (let i = 0; i < this.toPartyLRDetailsDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.toPartyLRDetailsDataList[i].consignor, this.toPartyLRDetailsDataList[i].consigneeName,
                this.toPartyLRDetailsDataList[i].source, this.toPartyLRDetailsDataList[i].destination, this.toPartyLRDetailsDataList[i].bookingDateStr
                    , this.toPartyLRDetailsDataList[i].lrNumber, this.toPartyLRDetailsDataList[i].articles, this.toPartyLRDetailsDataList[i].actualWeight
                    , this.toPartyLRDetailsDataList[i].chargedWeight, this.toPartyLRDetailsDataList[i].grandTotal, this.toPartyLRDetailsDataList[i].status];
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalArticles = +this.grandTotalArticles + +this.toPartyLRDetailsDataList[i].articles;
                this.grandActWt = +this.grandActWt + +this.toPartyLRDetailsDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.toPartyLRDetailsDataList[i].chargedWeight;
                this.grandMemoAmt = +this.grandMemoAmt + +this.toPartyLRDetailsDataList[i].grandTotal;
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.toPartyLRDetailsDataList.length, "", "", "", "", "", this.grandTotalArticles, this.grandActWt, this.grandChWt, this.grandMemoAmt, ""];
            //heading logics For Date


            //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On", "Agent Name"];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignor Name"];

            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedAgentSearch];
            if (this.modelConsignorIndexSearchView != 'ALL') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.modelConsignorNameSearchView.consignorName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = ["ALL"];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

            localStorage.setItem('printTitle', "Booking Party OS - Details");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    customPrintSummary() {
        if (this.toPartyLRSummaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Consignor Name", "Total LRs", "Tot Art", "Tot Act Wt", "Tot Chg Wt", "Tot Memo Amt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [25, 15, 15, 15, 15, 15];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalLRs = 0;
            this.grandTotalArticles = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandMemoAmt = 0;

            for (let i = 0; i < this.toPartyLRSummaryDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.toPartyLRSummaryDataList[i].consignor, this.toPartyLRSummaryDataList[i].totalMemos,
                this.toPartyLRSummaryDataList[i].articles, this.toPartyLRSummaryDataList[i].actualWeight, this.toPartyLRSummaryDataList[i].chargedWeight
                    , this.toPartyLRSummaryDataList[i].grandTotal];
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalLRs = +this.grandTotalLRs + +this.toPartyLRSummaryDataList[i].totalMemos;
                this.grandTotalArticles = +this.grandTotalArticles + +this.toPartyLRSummaryDataList[i].articles;
                this.grandActWt = +this.grandActWt + +this.toPartyLRSummaryDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.toPartyLRSummaryDataList[i].chargedWeight;
                this.grandMemoAmt = +this.grandMemoAmt + +this.toPartyLRSummaryDataList[i].grandTotal;
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.toPartyLRSummaryDataList.length, this.grandTotalLRs, this.grandTotalArticles, this.grandActWt, this.grandChWt, this.grandMemoAmt];
            //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On", "Agent Name"];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignor Name"];

            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedAgentSearch];
            if (this.modelConsignorIndexSearchView != 'ALL') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.modelConsignorNameSearchView.consignorName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = ["ALL"];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

            localStorage.setItem('printTitle', "Booking Party OS - Summary");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

}
