//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends

@Component( {
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
} )
export class SummaryComponent implements OnInit {

    loadingIndicator = true;
    model: NgbDateStruct;
    date: { year: number; month: number };

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    debitNoteSummaryReportDataList: any;
    onDestroyUnsubscribtionDebitNoteSummaryReport: Subscription;


    controlMain = new FormControl();

    destinationOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'Alleppey' },
        { id: 3, label: 'Alwaye' },
        { id: 4, label: 'Aurangabad' }
    ];




    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDebitNoteSummaryReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsDebitNoteSummaryReport: any;
	pageId="sumco";


    constructor( private debitNoteSummaryReport: MasterService , public changeDetectorRef : ChangeDetectorRef) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    ngOnInit(): void {


        this.dtOptionsDebitNoteSummaryReport = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.debitNoteSummaryReport.getSummaryData()
        this.onDestroyUnsubscribtionDebitNoteSummaryReport = this.getDataFrmServiceFrTable.subscribe( data => {
            this.debitNoteSummaryReportDataList = data['data'];
            this.dtTriggerDebitNoteSummaryReport.next();
        } );


    }

    ngOnDestroy(): void {
        this.dtTriggerDebitNoteSummaryReport.unsubscribe();

        this.onDestroyUnsubscribtionDebitNoteSummaryReport.unsubscribe();
    }

}
