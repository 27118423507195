<html>

<head>
</head>

<body>


	<div class="row" *ngIf="isLoggedIn">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Trip ETA Setup</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">

										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															id="{{pageId}}mainBookingSourceId"
															(selectItem)="clickListnerForMainBookingSource($event)"
															[(ngModel)]="modelMainBookingSource"
															[ngbTypeahead]="searchMainBookingSource"
															[resultFormatter]="formatterMainBookingSource"
															[inputFormatter]="formatterMainBookingSource"
															(focus)="focusMainBookingSourceTA$.next($any($event).target.value)"
															placeholder="Select Source.." />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Destination</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>

															<input id="{{pageId}}destination" type="text" class="form-control"
																[(ngModel)]="modelDestination"
																(selectItem)="clickListnerForDestination($event)"
																[ngbTypeahead]="searchDestination"
																[resultFormatter]="formatterDestination"
																[inputFormatter]="formatterDestination"
																(focus)="focusDestinationTA$.next($any($event).target.value)"
																placeholder="Select Destination" />
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Expected Time To Reach(Days)</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}etaDays" class=" form-control "
															aria-describedby="basic-addon11 ">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 100%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
								(click)="confirmSave();">Save</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearFields();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="row">

							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12 vl">
								<div class="col-md-12">

									<div class="box-body">
										<table datatable id="{{pageId}}etaTableId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsEtaTrip" [dtTrigger]="dtTriggerEtaTrip">

											<thead>
												<tr>
													<th>Source</th>
													<th>Destination</th>
													<th>ETA(Days)</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let etaTripData of etaTripTripDataList ">

													<td>{{ etaTripData.fromStation }}</td>
													<td>{{ etaTripData.toStation }}</td>
													<td>{{ etaTripData.countTrucks }}</td>
													<td><button
															style="padding: 1px 4px; background-color: #ffffff00;"
															class="btn m-r-10" id="{{pageId}}editBtn"
															(click)="rowSelectedEdit(etaTripData);">
															<i title="View" class="fas fa-edit"></i>
														</button>
														<button
															style="padding: 1px 4px; background-color: #ffffff00;"
															class="btn m-r-10" id="{{pageId}}removeBtn"
															(click)="confirmDelete(etaTripData)">
															<i title="Remove" class="fas fa-trash"></i>
														</button></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

</body>

</html>