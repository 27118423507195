import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-per-kg-rate-setup',
    templateUrl: './per-kg-rate-setup.component.html',
    styleUrls: ['./per-kg-rate-setup.component.css']
})
export class PerKgRateSetupComponent implements OnInit {

    lrDtoSave: LRDto = new LRDto();
    destinationDataListSave: LRDto[];
    lrDtoRet: any;
    destinationDataList: LRDto[];

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView: any;
    lorryHireDeductionDataList: any;
    splitTimeDetails: any;
    commaSplitTime: any;
    undersCodeSplitTime: any;
    perKgRateDataLists: any = [];
    // perKgRateDataLists: any;
    controlSource = new FormControl();
    controlDestination = new FormControl();

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCflSlabsSetup: Subject<any> = new Subject();
    dtTriggerPerKgRateSetup: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCflSlabsSetup: any;
    dtOptionsPerKgRateSetup: any;

    selectedFromStation: any;
    selectedToStation: any;

    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                : this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;

    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                : this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;
    pageId = "perKgRt";

    // source
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { source: string }) => x.source;

    /* For Destination Drop Down */
    destinationOptions: LRDto[];
    lrDtoToStation: LRDto = new LRDto();
    destinationDtoForAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { mainstation: string }) => x.mainstation;

    /* Commodity Drop Down */
    // rateMasterDto: RateMasterDto = new RateMasterDto();
    // rateMasterDtoOptions: RateMasterDto[];
    // rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    // public modelCommodityList: any;
    // commodityListTA: Array<RateMasterDto> = [];
    // focusCommodityListTA$ = new Subject<string>();
    // searchCommodityList = (text$: Observable<string>) => {
    //     const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    //     const inputFocus$ = this.focusCommodityListTA$;

    //     return merge(debouncedText$, inputFocus$).pipe(
    //         map(term => (term === '' ? this.commodityListTA
    //             : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    //     );
    // }
    // formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    enteredSourceForSave: any;
    enteredCommodityForSave: any;
    enteredDestinationForSave: any;
    rateMasterDto: RateMasterDto = new RateMasterDto();
    enteredPerKgRangeLimit: any;
    rateMasterDtoRet: any;
    enteredPerKgRangeForSave: any;
    rateMasterDtoGet: RateMasterDto = new RateMasterDto();
    autoId: number = 0;
    rateMasterDtoDelete: RateMasterDto = new RateMasterDto();
    saveoredit = "Save";

    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.timeSettingDetailsTable();
            this.getBranchList();
            this.getAgentDetailList();
            // this.getCommodityMasterDetailsList();
            this.getPerKgRateDetails();
        }
    }

    ngOnInit() {
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    timeSettingDetailsTable() {
        this.dtOptionsCflSlabsSetup = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
        },
            this.dtOptionsPerKgRateSetup = {
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        title: function () {
                            return "CFT Details";
                        },
                        exportOptions: {
                            columns: [0, 1, 2]
                        }
                    }
                ],
                searching: false,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                pagingType: 'full_numbers',
                pageLength: 9,
                select: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                }
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerCflSlabsSetup.unsubscribe();
        this.dtTriggerPerKgRateSetup.unsubscribe();
    }
    ngAfterViewInit(): void {
        // this.dtTriggerCflSlabsSetup.next();
        // this.dtTriggerCflSlabsAllSetup.next();
    }

    getBranchList() {
        this.destinationDataList = []
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.mode = "activeDeliveryOfc";
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch).subscribe(
            (response) => {
                if (response) {
                    this.destinationTA = [];
                    if (response.length > 0) {
                        this.destinationDataList = response;
                        for (let i = 0; i < this.destinationDataList.length; i++) {
                            this.destinationTA.push(this.destinationDataList[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the the destination details", "info");
            },
            () => console.log('done');
    }

    getUserValuesForAgentDetailsList() {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = "ALL";
    }

    getAgentDetailList() {
        $("#" + this.pageId + "agentName").val('');
        this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList();
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsSet = response;
                    this.agentDtoParam = new AgentDetailsDto();
                    this.agentDtoParam.source = "ALL";
                    this.agentNameTA.push(this.agentDtoParam);
                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    // getCommodityListRead() {
    //     this.rateMasterDto = new RateMasterDto();
    //     this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    //     this.rateMasterDto.mode = "mainConsigneeMaster";
    // }

    // getCommodityMasterDetailsList = () => {
    //     this.getCommodityListRead();
    //     this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
    //         (response) => {
    //             if (response) {
    //                 console.log(response);
    //                 if (response.length == 0) {
    //                     this.rateMasterDtoOptions = [];
    //                     this.commodityListTA = [];
    //                 } else {
    //                     this.rateMasterDtoOptions = [];
    //                     this.commodityListTA = [];
    //                     this.rateMasterDtoOptions = response;
    //                     this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
    //                     this.rateMasterDtoCommodityListAllOption.commodityName = "ALL";
    //                     this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
    //                     for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
    //                         this.commodityListTA.push(this.rateMasterDtoOptions[i]);
    //                     }
    //                     this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
    //                     this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
    //                     this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
    //                 }
    //             }
    //             this.changeDetectorRef.detectChanges();
    //         }), (error) => {
    //             swal("Error", "Server Error While Getting Commodity Master Details", "warning");
    //         },
    //         () => console.log('done');
    // }

    clearDetails() {
        this.clearFields();
    }

    validateSetupDetails() {
        this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.enteredPerKgRangeForSave = $("#" + this.pageId + "perKGRangeLimit").val();

        if (this.enteredSourceForSave == '' || this.enteredSourceForSave == null || this.enteredSourceForSave == undefined
            || this.enteredDestinationForSave == '' || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined
            || this.enteredPerKgRangeForSave == '' || this.enteredPerKgRangeForSave == null || this.enteredPerKgRangeForSave == undefined) {
            swal("Not Allowed", "Please enter all the mandatory fields to proceed!", "warning");
        } else {
            swal({
                title: "Confirm Save/Update",
                text: "Sure you want to Save/Update the entered Per KG Rate details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.saveSetupDetails();
                }
            });
        }
    }

    userSetupDetails() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.source = this.enteredSourceForSave;
        this.rateMasterDto.destination = this.enteredDestinationForSave;
        this.rateMasterDto.perKgRangeLimit = this.enteredPerKgRangeForSave;
        this.rateMasterDto.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDto.id = this.autoId;
        this.rateMasterDto.saveOrEdit = this.saveoredit;
        console.log(this.rateMasterDto);
    }

    saveSetupDetails = () => {
        this.userSetupDetails();
        this.masterService.savePerKgRateSetupDetails(this.rateMasterDto).
            subscribe((data) => {
                this.lrDtoRet = data;
                if (this.lrDtoRet.status == "Success") {
                    swal("Success", "Per KG Rate details saved/updated successfully!", "info");
                    this.clearFields();
                    this.getPerKgRateDetails();
                } else if (this.lrDtoRet.status == "exist") {
                    swal({
                        title: "Duplicate Entry",
                        text: "Per KG Rate is already entered for this source and destination!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal("Failed", "Failed to save/update the Per KG Rate details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the Per KG Rate details!", "error");
            },
            () => console.log('done');
    }

    rowSelectedView(perKgRateDetails) {
        $("#" + this.pageId + "agentName").val(perKgRateDetails.source);
        $("#" + this.pageId + "destination").val(perKgRateDetails.destination);
        $("#" + this.pageId + "perKGRangeLimit").val(perKgRateDetails.perKgRangeLimit);
        this.autoId = perKgRateDetails.id;
        this.saveoredit = "Edit";
    }

    getPerKgRateDetailsData() {
        this.rateMasterDtoGet = new RateMasterDto();
    }

    getPerKgRateDetails() {
        this.getPerKgRateDetailsData();
        this.masterService.getPerKgRateSetupDetails(this.rateMasterDtoGet).subscribe(
            (response) => {
                console.log(response);
                this.perKgRateDataLists = [];
                $("#" + this.pageId + "perKgRateDataTable").DataTable().destroy();
                this.perKgRateDataLists = response;
                if (response.length == 0) {
                    swal("No Records", "No Per KG Rate details found!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerPerKgRateSetup.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the Per KG Rate details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    userDeleteDetails(perKgRateDetails) {
        this.rateMasterDtoDelete = new RateMasterDto();
        this.rateMasterDtoDelete.id = perKgRateDetails.id;
        console.log(this.rateMasterDtoDelete);
        this.deletePerKgRateDetails();
    }

    deletePerKgRateDetails = () => {
        swal({
            title: "Confirm Delete",
            text: "Sure you want to delete the seleted Per KG Rate details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.masterService.deletePerKgRateSetupDetails(this.rateMasterDtoDelete).
                    subscribe((data) => {
                        this.lrDtoRet = data;
                        if (this.lrDtoRet.status == "Success") {
                            swal("Success", "Per KG Rate details Deleted successfully!", "info");
                            this.getPerKgRateDetails();
                        } else {
                            swal("Failed", "Failed to delete the Per KG Rate details!", "error");
                        }
                    }), (error) => {
                        swal("Error", "Server problem occurred while deleting the Per KG Rate details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    clearFields() {
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "perKGRangeLimit").val('');
        this.autoId = 0;
        this.saveoredit = "Save";
    }

}
