<html>

<!--<head>
	<style>
		/* for ipad and ipad pro */
		@media (min-width : 768px) and (max-width: 1200px) {
			.col-md-3 {
				flex: 0 0 50% !important;
				max-width: 100% !important;
			}
		}
	</style>
</head>

<body>
-->
<!-- Row -->
<div class="row" id="{{pageId}}mianPageOfLabourHamaliStmtId">
	<div class="col-lg-12">
		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Labour Hamali Statement
					Details</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-6">
					<div class="col-md-12">
						<div class="card">

							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>

							<div class="card-body" style="border-bottom: 1px solid orange;">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="custom_font_Lable">Statement Number</label>
													<div class="input-group" id="{{pageId}}statementNumber">
														<label class="custom_font_Lables" style="font-size: 15px;">{{
																hireSlipDtoSelectedData.stmtNo }}</label>
													</div>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="custom_font_Lable">Period</label>
													<div class="input-group" id="{{pageId}}period">
														<label class="custom_font_Lables" style="font-size: 15px;">{{
																hireSlipDtoSelectedData }} To {{
																hireSlipDtoSelectedData }}</label>
													</div>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="custom_font_Lable">Account Statement
														Of</label>
													<div class="input-group" id="{{pageId}}accountStatement">
														<label class="custom_font_Lables" style="font-size: 15px;">{{
																hireSlipDtoSelectedData.loaderHead }}</label>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="col-md-12 p-t-10">
						<div class="box-body">
							<!-- 							the first datatble starts -->
							<table datatable id="{{pageId}}labourStmtDetailsId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<th>Stmt No</th>
										<th>Stmt Date</th>
										<th>Unloading Total Lrs</th>
										<th>Unloading Tot Act Wgt</th>
										<th>Loading Tot Kanta Weight</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsData of lrDetailsDataList ">
										<td style='cursor:pointer;color: blue;'
											(click)="getLabourHamaliStmtNoDetails(lrDetailsData);">
											{{ lrDetailsData.stmtNo }}</td>
										<td>{{ lrDetailsData.stmtDateStr }}</td>
										<td style='cursor:pointer;color: blue;'
											(click)="getLabourHamaliTotalLrsDetails(lrDetailsData);">
											{{ lrDetailsData.totalLrs }}</td>
										<td>{{ lrDetailsData.totalActWgt }}</td>
										<td>{{ lrDetailsData.totalKantaWgt }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="col-md-12 p-t-10" style="text-align: center">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateBtn"
							(click)="saveLabourStmtDetails('Pending')">Update</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}finishBtn"
							(click)="saveLabourStmtDetails('Finished')">Finish</button>
					</div>

				</div>
				<div class="col-md-6 vl">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-md-3">
												<div class="form-group">
													<div class="input-group">
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-plus"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}commonDescription"
															name="commonDescription">
															<option value="Addition" selected>Addition</option>
															<option value="Deduction">Deduction</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<div class="input-group">
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}description" [readonly]="descReadOnly"
															placeholder="Enter The Description" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<div class="input-group">
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" id="{{pageId}}value" placeholder="Value"
															class="form-control" aria-describedby="basic-addon11">
													</div>
												</div>
											</div>

											<div class="col-md-2">
												<div class="form-group">
													<div class="input-group">
														<a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn
															style="padding: 1px 4px;" class="btn btn-icon-only yellow"
															(click)="addInTable();">
															<i class="fa fa-plus" title="Add"></i>
														</a>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">Additions</h6>
							</div>
						</div>
						<div class="box-body p-t-10">
							<!-- 							<the second datatable starts  -->
							<div >
								<table datatable id="{{pageId}}additionTableId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionAdditionDatatable" [dtTrigger]="dtTriggerAdditionDatatable">
									<thead>
										<tr>
											<th>Description</th>
											<th>Credit(Rs)</th>
											<th>Debit(Rs)</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>

										<tr
											*ngFor="let additionDatatableData of additionDatatableDataList ; let i = index">
											<td>{{ additionDatatableData.remarks }}</td>
											<td>{{ additionDatatableData.totalAmt }}</td>
											<td>0.0</td>
											<td><button style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}additionEditBtn"
													(click)="rowSelectedEditAddition(additionDatatableData,i);">
													<i title="Edit" class="fas fa-edit"></i>
												</button>
												<button style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}additionRemoveBtn"
													(click)="rowSelectedDeleteAddition(additionDatatableData,i)">
													<i title="Remove" class="fas fa-trash"></i>
												</button></td>
										</tr>

										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tfoot>

								</table>
								<!--							 the second datatable ends  -->
							</div>
						</div>
					</div>

					<div class="col-md-12 p-t-10 p-b-10">
						<!-- 							the third datatable starts  -->
						<div class="row">
							<div class="col-md-12">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">Deductions</h6>
							</div>
						</div>
						<div class="box-body p-t-10">
							<div class="p-t-10">
								<table datatable id="{{pageId}}deductionTableId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionDeductionDatatable" [dtTrigger]="dtTriggerDeductionDatatable">
									<thead>
										<tr>
											<th>Description</th>
											<th>Credit(Rs)</th>
											<th>Debit(Rs)</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>

										<tr
											*ngFor="let deductionDatatableData of deductionDatatableDataList ; let j = index">
											<td>{{ deductionDatatableData.remarks }}</td>
											<td>0.0</td>
											<td>{{ deductionDatatableData.totalAmt }}</td>
											<td><button style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}dedEditBtn"
													(click)="rowSelectedEditDeduction(deductionDatatableData,j);">
													<i title="Edit" class="fas fa-edit"></i>
												</button>
												<button style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}dedRemoveBtn"
													(click)="rowSelectedDeleteDed(deductionDatatableData,j)">
													<i title="Remove" class="fas fa-trash"></i>
												</button></td>
										</tr>

										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</tfoot>
								</table>
							</div>
							<!-- 							the third datatable ends  -->

						</div>
					</div>
					<div class="row srd_patch m-l-10 m-r-10">
						<div class="col-md-6">
							<p style="margin-bottom: 1px;" class="card-title m-b-1">Amount
								To Be Paid</p>
						</div>
						<div class="col-md-6" style="text-align: center;">
							<p style="margin-bottom: 1px;" class="card-title">{{toBePaidAmt}}</p>
						</div>
					</div>

				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<div *ngIf="viewLabourHamaliLoadUnLoadDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printLabourHamaliLoadUnloadId" #printForShowStmtRptId>
	<app-loading-unloading-hamali-details-print></app-loading-unloading-hamali-details-print>
</div>
<!---</body>
</html>-->