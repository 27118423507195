import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
import { LrReportService } from 'src/app/dataService/lr-report-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for datatable ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";

@Component( {
    selector: 'app-final-notice-pending-email',
    templateUrl: './final-notice-pending-email.component.html',
    styleUrls: ['./final-notice-pending-email.component.css']
} )
export class FinalNoticePendingEmailComponent implements  OnInit {

    finalNoticePendingEmailDataList: any;
    dtTriggerFinalNoticePendingEmail: Subject<any> = new Subject();

    dtOptionsFinalNoticePendingEmail: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
    pageId="fnpec";

    constructor(private router: Router,private lrReportService : LrReportService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }
    
    ngOnInit(): void {
        this.dtOptionsFinalNoticePendingEmail = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerFinalNoticePendingEmail.unsubscribe();
    // }
    
    getUserValues() {
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.destination = this.userDataDtoReturnSession.mainStation;
	}
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.lrReportService.getFinalNoticeEmailDetails(this.lrDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"finalNoticeEmailTableId").DataTable().destroy();
				this.finalNoticePendingEmailDataList = [];
				if (response.length == 0) {
					swal({
						title: "Final Notice Pending Email",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.finalNoticePendingEmailDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerFinalNoticePendingEmail.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	ngAfterViewInit(): void {
        this.dtTriggerFinalNoticePendingEmail.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
