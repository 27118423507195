<div mat-dialog-content>
	<div class="row">
		<div class="col-lg-12">
			<div class="row">
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}artLbl">
							<label class="wtfull">Article</label>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}lengthLbl">
							<label class="wtfull">Length</label>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}breadthLbl">
							<label class="wtfull">Breadth</label>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}heightLbl">
							<label class="wtfull">Height</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">

							<input type="number" id="{{pageId}}multiArtarticle" name="multiArtarticle" class="form-control"
								#multiArtarticle (keypress)="keyPressListnerArticle($event)"
								aria-describedby="basic-addon11">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">

							<input type="number" id="{{pageId}}multiArtlength" name="multiArtlength" class="form-control"
								#multiArtlength (keypress)="keyPressListnerLength($event)"
								aria-describedby="basic-addon11">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">

							<input type="number" id="{{pageId}}multiArtbreadth" name="multiArtbreadth" class="form-control"
								aria-describedby="basic-addon11" #multiArtbreadth
								(keypress)="keyPressListnerBreadth($event)">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">

							<input type="number" id="{{pageId}}multiArtheight" name="multiArtheight" class="form-control"
								aria-describedby="basic-addon11" #multiArtheight
								(keyup)="keyPressListnerHieght($event)">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-5" style='display: none'>
					<div class="form-group">
						<div class="input-group">
							<input type="number" id="{{pageId}}hiddenIndex" class="form-control"
								aria-describedby="basic-addon11">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-1">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">

								<a href="javascript:;" type='button' id="{{pageId}}addInTable()" #addBtn
									class="btn btn-icon-only yellow" (click)="addInTable();"> <i class="fa fa-plus"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="box-body">
						<table datatable id="{{pageId}}multipleArticleTable"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsMultiArtTable" [dtTrigger]="dtTriggerMultiArtTable">

							<thead>
								<tr>
									<th>Article</th>
									<th>Length</th>
									<th>Breadth</th>
									<th>Height</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let multipleArtTableData of multiArtTableDataList let i = index">
									<td>{{
								multipleArtTableData.articlesInMultiple }}</td>
									<td>{{ multipleArtTableData.length }}</td>
									<td>{{ multipleArtTableData.breadth }}</td>
									<td>{{ multipleArtTableData.height }}</td>
									<td>
										<button style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableEditBtn"
											(click)="rowSelectedEdit(multipleArtTableData,i);">
											<i title="Edit" class="fas fa-pencil-alt"></i>
										</button>&nbsp;
										<button style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
											(click)="rowSelectedDelete(multipleArtTableData,i)">
											<i title="Remove" class="fas fa-trash"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>