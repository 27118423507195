<div *ngIf="isLoggedIn" class="page row system_responsive" id="{{pageId}}challanRePrint">
	<div class="container border">
		<div class="col-md-12">
			<!--first row starts-->
			<div class="row" style="padding-top: 5px;">

				<div class="col-md-5">
					<!-- 					<h6 style="font-size: 16px;" [innerHtml]="addressLineOne"> -->
					<!-- 						 {{addressLineOne}} -->
					<!-- 					</h6> -->
					<!-- 					<h6 style="font-size: 16px;"> -->
					<!-- 						 {{addressLineTwo}}  -->
					<!-- 					</h6> -->
					<h6 style='margin-left: 20px; font-size: 16px; word-wrap: break-word;'>
						{{this.address}}
					</h6>

				</div>
				<div class="col-md-7">
					<img style='margin-left: 270px;' src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
				</div>
			</div>
			<!--first row starts-->
			<div class="row">
				<div class="col-md-12" align='center'>
					<h6 style='margin-left: 20px; font-size: 24px;'>
						<strong><u>{{setMainStation}}</u></strong> <strong><u>
								COLLECTION REPORT</u></strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12" align='left'>
					<h6 style='margin-right: 20px; font-size: 22px;'>
						<strong>Date : </strong> <strong>{{completedOnDt}}</strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12" align='left'>
					<h6 style='margin-right: 20px; font-size: 22px;'>
						<strong>Collection Man : </strong>
						<strong>{{dataForPrintPaymentChallanList.collectionMan}}</strong>
					</h6>
				</div>
			</div>
			<div class="row" style='border-top: 1px solid #000;'>
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>CASH : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForPrintPaymentChallanList.cashAmt}}</strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>CHEQUE : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForPrintPaymentChallanList.chequeAmt}}</strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>NEFT/IMPS : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForPrintPaymentChallanList.neftAmt}}</strong>
					</h6>
				</div>
			</div>
			<div class="row"
				*ngFor="let dataForLessStation of dataForPrintPaymentChallanList.consigneeWiseBalanceDtails">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>PARTY LESS ({{dataForLessStation.mainStation}}) : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForLessStation.lessAmt}}</strong>
					</h6>
				</div>
			</div>
			<!-- <div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>PARTY LESS (MUMBAI) : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{this.getChallanPrintValDto.mumbaiLess}}</strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>PARTY LESS (GUJARAT) : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{this.getChallanPrintValDto.gujaratLess}}</strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>PARTY LESS (PUNJAB) : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{this.getChallanPrintValDto.punjabLess}}</strong>
					</h6>
				</div>
			</div> -->
			<div class="row" *ngFor="let lessDescOptData of dataForPrintPaymentChallanList.consigneeWiseMemo">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>{{lessDescOptData.lessDescription}} : </strong>
					</h6>
				</div>
				<div *ngIf="lessDescOptData.lessDescription == 'TDS' || lessDescOptData.lessDescription == 'CLAIM'"
					class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{lessDescOptData.lessAmt}}</strong>
					</h6>
				</div>
				<div *ngIf="lessDescOptData.lessDescription != 'TDS' && lessDescOptData.lessDescription != 'CLAIM'"
					class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px; margin-left: 390px;'>
						<strong>{{lessDescOptData.lessAmt}}</strong>
					</h6>
				</div>
			</div>

			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>OTHERS : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong></strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 21px; font-size: 18px; padding-top: 17px;'>
						<strong>TOTAL COLLECTION AMOUNT :</strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForPrintPaymentChallanList.grandTotal}} </strong>
					</h6>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5" align='left'>
					<h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
						<strong>TOTAL GC COLLECTED : </strong>
					</h6>
				</div>
				<div class="col-md-7" style='border-bottom: 1px solid #000;'>
					<h6 style='font-size: 20px; padding-top: 17px;' align='center'>
						<strong>{{dataForPrintPaymentChallanList.totalMemos}} </strong>
					</h6>
				</div>
			</div>
			<div class="row" style='border-bottom: 1px solid #000;'>
				<div class="col-md-12" align='left'></div>

			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;2000</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof2000Count==null?0:dataForPrintPaymentChallanList.nfof2000Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF2000==null?0:dataForPrintPaymentChallanList.nfOF2000}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;500</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof500Count==null?0:dataForPrintPaymentChallanList.nfof500Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF500==null?0:dataForPrintPaymentChallanList.nfOF500}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;200</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof200Count==null?0:dataForPrintPaymentChallanList.nfof200Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF200==null?0:dataForPrintPaymentChallanList.nfOF200}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;100</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof100Count==null?0:dataForPrintPaymentChallanList.nfof100Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF100==null?0:dataForPrintPaymentChallanList.nfOF100}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;50</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof50Count==null?0:dataForPrintPaymentChallanList.nfof50Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF50==null?0:dataForPrintPaymentChallanList.nfOF50}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;20</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof20Count==null?0:dataForPrintPaymentChallanList.nfof20Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF20==null?0:dataForPrintPaymentChallanList.nfOF20}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;10</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof10Count==null?0:dataForPrintPaymentChallanList.nfof10Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF10==null?0:dataForPrintPaymentChallanList.nfOF10}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;5</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof5Count==null?0:dataForPrintPaymentChallanList.nfof5Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF5==null?0:dataForPrintPaymentChallanList.nfOF5}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;2</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof2Count==null?0:dataForPrintPaymentChallanList.nfof2Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF2==null?0:dataForPrintPaymentChallanList.nfOF2}}</strong>
							</h6>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2">
							<h6 style='font-size: 15px;'>
								<strong>&nbsp;1</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> X </strong>
							</h6>
						</div>
						<div class="col-md-2" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfof1Count==null?0:dataForPrintPaymentChallanList.nfof1Count}}</strong>
							</h6>
						</div>
						<div class="col-md-1">
							<h6 style='font-size: 15px;'>
								<strong> = </strong>
							</h6>
						</div>
						<div class="col-md-4" style='border-bottom: 1px solid #000;'>
							<h6 style='font-size: 15px;' align='center'>
								<strong>{{dataForPrintPaymentChallanList.nfOF1==null?0:dataForPrintPaymentChallanList.nfOF1}}</strong>
							</h6>
						</div>
					</div>

				</div>
			</div>
			<div class="row">
				<div class="col-md-12" align='left'></div>
			</div>
		</div>
	</div>