import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends

@Component( {
    selector: 'app-source-charges-setup',
    templateUrl: './source-charges-setup.component.html',
    styleUrls: ['./source-charges-setup.component.css']
} )
export class SourceChargesSetupComponent implements OnInit {

    gettingDataFrmServiceFrSourceChargesSetupTable: any;


    sourceChargesSetupDataList: any;


    onDestroyUnsubscribtionSourceChargesSetup: Subscription;

    //for datatable ends

    loadingIndicator = true;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSourceChargesSetup: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsSourceChargesSetup: any;

    //for datatable ends

    //for the select option with filter starts

    control = new FormControl();

    sourceOptions = [
        { id: 1, label: 'Delhi' },
        { id: 2, label: 'Gujarat' },
        { id: 3, label: 'Mumbai' },
    ];

    //for the select option with filter ends
    pageId="scsc";


    constructor(/* for datatable starts */private sourceChargesSetupScreen: MasterService , public changeDetectorRef : ChangeDetectorRef/* for datatable endss */ ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */


    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsSourceChargesSetup = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 340,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            //            columnDefs: [
            //                { width: 10, targets: 0 },
            //                { width: 10, targets: 1 },
            //                { width: 10, targets: 2 },
            //            ],
            "footerCallback": function( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function( i ) {
                    return typeof i === 'string' ?
                        +i.replace( /[\$,]/g, '' ) * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };


                //                count starts
                var columnData = api
                    .column(
                    0 )
                    .data();

                //                var theColumnTotal = columnData
                //                    .reduce( function( a, b ) {
                //                        if ( isNaN( a ) ) {
                //                            return '';
                //                        } else {
                //                            a = parseFloat( a );
                //                        }
                //                        if ( isNaN( b ) ) {
                //                            return '';
                //                        } else {
                //                            b = parseFloat( b );
                //                        }
                //                        return ( a + b ).toFixed( 2 );
                //                    }, 0 );

                // Update footer
                $( api.column( 0 ).footer() ).html(
                    'Total : ' + columnData.count()
                );
                //                counts ends

            }
        }
        //the below code is for the getting data through json starts
        //        this.supplierList.getAllData().subscribe(data => {
        //            this.lrDispatchBknRptList = data['data'];
        //            this.dtTriggerSummary.next();
        //            } );
        this.gettingDataFrmServiceFrSourceChargesSetupTable = this.sourceChargesSetupScreen.getSummaryData()
        this.onDestroyUnsubscribtionSourceChargesSetup = this.gettingDataFrmServiceFrSourceChargesSetupTable.subscribe( data => {
            this.sourceChargesSetupDataList = data['data'];
            this.dtTriggerSourceChargesSetup.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerSourceChargesSetup.unsubscribe();

        this.onDestroyUnsubscribtionSourceChargesSetup.unsubscribe();
        //for datatable ends
    }

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends

}