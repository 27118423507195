import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import swal from 'sweetalert';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { MasterService } from 'src/app/dataService/master-service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';

@Component({
    selector: 'app-lr-booking-date-custom-change',
    templateUrl: './lr-booking-date-custom-change.component.html',
    styleUrls: ['./lr-booking-date-custom-change.component.css']
})

export class LrBookingDateCustomChangeComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "lrBkgDateCstmChg";
    gstSearch = true;
    isLoggedIn = true;
    enteredSourceForSave: any;
    private newAttribute: any = {};
    editTableId = null;
    lrBkgDateCustomChangeDetailLists: Array<any> = [];
    dtTriggerLrBookingDateCustomChange: Subject<any> = new Subject();
    indexPartySearchTA: Array<any> = [];
    lrBkgDateDetailsDataListSave: LRDto[];
    enteredFromTimeForSave: any;
    enteredToTimeForSave: any;
    enteredIncrementDayForSave: any;
    enteredWeekendDayForSave: any;
    enteredAddingDayForSave: any;
    lrDtoSave: LRDto = new LRDto();
    lrDtoRet: any;
    lrBkgDateDetailsDataAllList: Array<any> = [];
    dtTriggerLrBookingDateDetails: Subject<any> = new Subject();
    fromTimeModel: any;
    toTimeModel: any;
    incrementDaysModel: any;
    addingDaysModel: any;
    dtOptionsLrBookingDateCustomChange: any;
    // agentNameModalForHoliday: any;
    fromDateModel: any;
    toDateModel: any;
    consideredDateModel: any;
    lrDtoSaveForHoliday: LRDto = new LRDto();
    lrBkgDateCustomChangeForHolidayDetailLists: Array<any> = [];
    dtTriggerLrBookingDateForHolidayDetails: Subject<any> = new Subject();
    dtOptionsLrBookingDateForHolidayDetails: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedConsideredDate: any;
    autoId: number = 0;
    showSpinnerForAction = false;

    daysOptions = [
        { id: 1, day: 'Sunday' },
        { id: 2, day: 'Monday' },
        { id: 3, day: 'Tuesday' },
        { id: 4, day: 'Wednesday' },
        { id: 5, day: 'Thursday' },
        { id: 6, day: 'Friday' },
        { id: 7, day: 'Saturday' }];



    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { source: string }) => x.source;

    public modelWeekendDays: any;
    @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
    weekendDaysTA: Array<any> = [];
    focusWeekendDaysTA$ = new Subject<string>();
    weekendDaysSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusWeekendDaysTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.weekendDaysTA
                : this.weekendDaysTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    // agentDetailsOptionsSet: AgentDetailsDto[];
    // agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModalForHoliday: any;
    agentNameForHolidayTA: Array<AgentDetailsDto> = [];
    focusAgentNameForHolidayTA$ = new Subject<string>();
    agentNameForHolidaySearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameForHolidayTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentNameForHoliday = (x: { source: string }) => x.source;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    constructor(
        private masterReadService: MasterReadService, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getAgentDetailList();
            this.setWeekendDays();
            this.getLrBkgDateCustomChangeDetailsData();
            this.getLrBkgDateCustomChangeForHolidayDetailsData();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

        this.dtOptionsLrBookingDateCustomChange = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "LR Booking Date Custom Change Details";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5]
                    }
                }
            ],
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
            }
        },
            this.dtOptionsLrBookingDateForHolidayDetails = {
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        title: function () {
                            return "LR Booking Date Custom Change Details";
                        },
                        exportOptions: {
                            columns: [0, 1, 2, 3]
                        }
                    }
                ],
                searching: false,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                pagingType: 'full_numbers',
                pageLength: 9,
                select: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                }
            }

    }

    ngOnDestroy(): void {
        this.dtTriggerLrBookingDateCustomChange.unsubscribe();
        this.dtTriggerLrBookingDateForHolidayDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrBookingDateCustomChange.next();
        this.dtTriggerLrBookingDateForHolidayDetails.next();
    }

    getUserValuesForAgentDetailsList() {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = "ALL";
    }

    getAgentDetailList() {
        $("#" + this.pageId + "agentName").val('');
        this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList();
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsSet = response;
                    this.agentDtoParam = new AgentDetailsDto();
                    this.agentDtoParam.source = "ALL";
                    this.agentNameTA.push(this.agentDtoParam);
                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    validateForAddInTable() {
        if (this.agentNameModal == null || this.agentNameModal == undefined ||
            this.agentNameModal == "" || this.fromTimeModel == null ||
            this.fromTimeModel == undefined || this.fromTimeModel == "" ||
            this.toTimeModel == null || this.toTimeModel == undefined ||
            this.toTimeModel == "" || this.incrementDaysModel == null ||
            this.incrementDaysModel == undefined || this.incrementDaysModel == "" ||
            this.modelWeekendDays == null || this.modelWeekendDays == undefined ||
            this.modelWeekendDays == "" || this.addingDaysModel == null ||
            this.addingDaysModel == undefined || this.addingDaysModel == "") {
            swal(" Mandatory Field", "All fields are mandatory", "warning");
            return false;
        } else {
            this.addInTable();
        }
    }

    addInTable() {
        this.newAttribute.source = this.agentNameModal.source;
        this.newAttribute.startTime = this.fromTimeModel;
        this.newAttribute.endTime = this.toTimeModel;
        this.newAttribute.increamentDays = this.incrementDaysModel;
        this.newAttribute.weekendDays = this.modelWeekendDays;
        this.newAttribute.addingDays = this.addingDaysModel;

        if ((!this.newAttribute.source) || (!this.newAttribute.fromTime) || (!this.newAttribute.toTime)
            || (!this.newAttribute.increamentDays) || (!this.newAttribute.weekendDays) ||
            (!this.newAttribute.addingDays)) {
            if (this.editTableId != null) {
                this.lrBkgDateCustomChangeDetailLists.splice(this.editTableId, 1);
            }
            if (this.lrBkgDateCustomChangeDetailLists == null) {
                this.lrBkgDateCustomChangeDetailLists = [];
            }

            if (this.lrBkgDateCustomChangeDetailLists.length == 0) {
                this.lrBkgDateCustomChangeDetailLists.push(this.newAttribute);
            } else {

                let array = this.lrBkgDateCustomChangeDetailLists;
                this.lrBkgDateCustomChangeDetailLists.push(this.newAttribute);

            }
            this.dtTriggerLrBookingDateCustomChange.next();
            this.newAttribute = {};
            this.agentNameModal = null;
            this.fromTimeModel = null;
            this.toTimeModel = null;
            this.incrementDaysModel = null;
            this.modelWeekendDays = null;
            this.addingDaysModel = null;
            this.editTableId = null;
        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    setWeekendDays() {
        for (let i = 0; i < this.daysOptions.length; i++) {
            this.weekendDaysTA.push(this.daysOptions[i].day);
            this.indexPartySearchTA.push(this.daysOptions[i].day);
        }
    }

    rowSelectedEdit(lrBkgDateCustomChangeDetails, index) {
        this.editTableId = null;
        this.agentNameModal = new LRDto();
        this.agentNameModal.source = lrBkgDateCustomChangeDetails.source;
        this.fromTimeModel = lrBkgDateCustomChangeDetails.startTime;
        this.toTimeModel = lrBkgDateCustomChangeDetails.endTime;
        this.incrementDaysModel = lrBkgDateCustomChangeDetails.increamentDays;
        this.modelWeekendDays = lrBkgDateCustomChangeDetails.weekendDays;
        this.addingDaysModel = lrBkgDateCustomChangeDetails.addingDays;
        this.editTableId = index;
        console.log("index");
        console.log(this.editTableId);
    }

    validateForLrBookingDateCustomChangeSave() {
        swal({
            title: "Confirm Save",
            text: "Sure U want to Save Lr Booking Date Custom Change Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.saveLrBkgDateCustomChangesDetails();
            }
        });
    }

    setLrBkgDateCustomChangesDetails() {
        this.lrBkgDateDetailsDataListSave = [];
        for (let i = 0; i < this.lrBkgDateCustomChangeDetailLists.length; i++) {
            this.lrDtoSave = new LRDto();
            this.lrDtoSave.source = this.lrBkgDateCustomChangeDetailLists[i].source;
            this.lrDtoSave.startTime = this.lrBkgDateCustomChangeDetailLists[i].startTime;
            this.lrDtoSave.endTime = this.lrBkgDateCustomChangeDetailLists[i].endTime;
            this.lrDtoSave.increamentDays = this.lrBkgDateCustomChangeDetailLists[i].increamentDays;
            this.lrDtoSave.weekendDays = this.lrBkgDateCustomChangeDetailLists[i].weekendDays;
            this.lrDtoSave.addingDays = this.lrBkgDateCustomChangeDetailLists[i].addingDays;

            this.lrDtoSave.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoSave.mode = "General";
            this.lrBkgDateDetailsDataListSave.push(this.lrDtoSave);
        }
    }
    
    saveLrBkgDateCustomChangesDetails = () => {
        this.setLrBkgDateCustomChangesDetails();
        console.log("lrDtoSave");
        console.log(this.lrDtoSave);
        this.masterService.saveLrBkgDateCustomChangeDetails(this.lrBkgDateDetailsDataListSave).
            subscribe((data) => {
                this.lrDtoRet = data;
                if (this.lrDtoRet.status == "Success") {
                    swal("Success", "Lr Booking Date Details saved/updated successfully!", "info");
                    this.getLrBkgDateCustomChangeDetailsData();
                    this.clearFields();
                } else {
                    swal("Failed", "Failed to save/update the Lr Booking Date Details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the Lr Booking Date Details!", "error");
            },
            () => console.log('done');
    }

    getLrBkgDateCustomChangeDetailLists() {
        this.lrDtoSave = new LRDto();
        this.lrDtoSave.mode = "General";
    }

    getLrBkgDateCustomChangeDetailsData() {
        this.getLrBkgDateCustomChangeDetailLists();
        this.showSpinnerForAction = true;
        this.masterService.getLrBkgDateCustomChangeDetails(this.lrDtoSave).subscribe(
            (response) => {
                console.log(response);
                this.lrBkgDateCustomChangeDetailLists = [];
                $("#" + this.pageId + "lrBookingDateCustomChangeTableId").DataTable().destroy();
                this.lrBkgDateCustomChangeDetailLists = response;
                console.log("getLrBkgDateDetailsData");
                console.log(this.lrBkgDateCustomChangeDetailLists);
                if (response.length == 0) {
                    swal("No Records", "Lr Booking Date Details!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerLrBookingDateCustomChange.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while getting the Lr Booking Date Details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    clearFields() {
        this.lrBkgDateDetailsDataListSave = [];
        this.newAttribute = {};
        this.agentNameModal = null;
        this.fromTimeModel = null;
        this.toTimeModel = null;
        this.incrementDaysModel = null;
        this.modelWeekendDays = null;
        this.addingDaysModel = null;
        this.editTableId = null;
    }

    clearAll() {
        this.autoId = 0;
        $("#" + this.pageId + "agentNameForHoliday").val('')
        $("#" + this.pageId + "fromDate").val('')
        $("#" + this.pageId + "toDate").val('')
        $("#" + this.pageId + "consideredDate").val('')
    }

    validateSaveLrBkgDateCustomChangeForHolidayDetails() {
        if ($("#" + this.pageId + "agentNameForHoliday").val() == null || $("#" + this.pageId +
            "agentNameForHoliday").val() == undefined || $("#" + this.pageId + "agentNameForHoliday").val() == "" ||
            $("#" + this.pageId + "fromDate").val() == null || $("#" + this.pageId + "fromDate").val() == undefined ||
            $("#" + this.pageId + "fromDate").val() == "" || $("#" + this.pageId + "toDate").val() == null ||
            $("#" + this.pageId + "toDate").val() == undefined || $("#" + this.pageId + "toDate").val() == "" ||
            $("#" + this.pageId + "consideredDate").val() == null || $("#" + this.pageId + "consideredDate").val() == undefined ||
            $("#" + this.pageId + "consideredDate").val() == "") {
            swal(" Mandatory Field", "All fields are mandatory", "warning");
            return false;
        } else {
            this.saveLrBkgDateCustomChangeForHolidayDetails();
        }
    }

    setLrBkgDateCustomChangeForHoliday() {
        this.lrDtoSaveForHoliday = new LRDto();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedConsideredDate = $("#" + this.pageId + "consideredDate").val();

        this.lrDtoSaveForHoliday.source = this.agentNameModalForHoliday.source;
        this.lrDtoSaveForHoliday.fromdate = this.selectedFromDate
        this.lrDtoSaveForHoliday.todate = this.selectedToDate;
        this.lrDtoSaveForHoliday.dateInDate = this.selectedConsideredDate;
        this.lrDtoSaveForHoliday.mode = "Holiday";
        this.lrDtoSaveForHoliday.autoId = this.autoId;
        this.lrDtoSaveForHoliday.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoSaveForHoliday.companyId = this.userDataDtoReturnSession.companyId;
    }

    saveLrBkgDateCustomChangeForHolidayDetails = () => {
        swal({
            title: "Confirm Save",
            text: "Sure U want to Save Lr Booking Date Custom Change For Holiday Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.setLrBkgDateCustomChangeForHoliday();
                console.log("lrDtoSaveForHoliday");
                console.log(this.lrDtoSaveForHoliday);
                this.masterService.saveLrBkgDateCustomChangeForHolidayDetails(this.lrDtoSaveForHoliday).
                    subscribe((data) => {
                        this.lrDtoRet = data;
                        if (this.lrDtoRet.status == "Success") {
                            swal("Success", "Lr Booking Date For Holiday Details saved/updated successfully!", "info");
                            this.getLrBkgDateCustomChangeForHolidayDetailsData();
                            this.clearAll();
                        } else {
                            swal("Failed", "Failed to save/update the Lr Booking Date For Holiday Details!", "error");
                        }
                    }), (error) => {
                        swal("Error", "Server problem occurred while saving/updating the Lr Booking Date For Holiday Details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    getLrBkgDateCustomChangeForHolidayDetailLists() {
        this.lrDtoSaveForHoliday = new LRDto();
        this.lrDtoSaveForHoliday.mode = "Holiday";
    }

    getLrBkgDateCustomChangeForHolidayDetailsData() {
        this.getLrBkgDateCustomChangeForHolidayDetailLists();
        this.masterService.getLrBkgDateCustomChangeForHolidayDetails(this.lrDtoSaveForHoliday).subscribe(
            (response) => {
                console.log(response);
                this.lrBkgDateCustomChangeForHolidayDetailLists = [];
                $("#" + this.pageId + "lrBookingDateForHolidayDetailsTableId").DataTable().destroy();
                this.lrBkgDateCustomChangeForHolidayDetailLists = response;
                console.log("getLrBkgDateForHolidayDetailsData");
                console.log(this.lrBkgDateCustomChangeForHolidayDetailLists);
                if (response.length == 0) {
                    swal("No Records", "Lr Booking Date For Holiday Details!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerLrBookingDateForHolidayDetails.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the Lr Booking Date Details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    rowSelectedEditForHoliday(lrBkgDateCustomChangeDetailsForHoliday, index) {
        this.autoId = 0;
        this.agentNameModalForHoliday = new LRDto();
        this.agentNameModalForHoliday.source = lrBkgDateCustomChangeDetailsForHoliday.source;
        $("#" + this.pageId + "fromDate").val(lrBkgDateCustomChangeDetailsForHoliday.fromDate);
        $("#" + this.pageId + "toDate").val(lrBkgDateCustomChangeDetailsForHoliday.toDate);
        $("#" + this.pageId + "consideredDate").val(lrBkgDateCustomChangeDetailsForHoliday.date);
        this.autoId = lrBkgDateCustomChangeDetailsForHoliday.autoId;
    }
    
}
