import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { MaterialModule } from "src/app/material.module";
import { OthersRoutes } from 'src/app/fortnightStatement/others/others.routing';
import { PartyLessCheckUploadTypeComponent } from 'src/app/fortnightStatement/others/party-less-check-upload-type/party-less-check-upload-type.component';
import { BalancingDifferenceTallyReportComponent } from 'src/app/fortnightStatement/others/balancing-difference-tally-report/balancing-difference-tally-report.component';
import { DebitNoteStmtComponent } from 'src/app/fortnightStatement/others/debit-note-stmt/debit-note-stmt.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { LrReportModule } from 'src/app/report/lr-report/lr-report.module';
import { PartyLessCheckDetailsComponent } from 'src/app/fortnightStatement/others/party-less-check-details/party-less-check-details.component';
import { PartyLessCheckComponent } from 'src/app/fortnightStatement/others/party-less-check/party-less-check.component';
import { MlessCheckPopupComponent } from 'src/app/fortnightStatement/others/mless-check-popup/mless-check-popup.component';
import { PartyLessCheckDetailsV2Component } from './party-less-check-details-V2/party-less-check-details-V2.component';

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( OthersRoutes ),
         FormsModule,
          NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, 
         MatAutocompleteModule, ReactiveFormsModule,
          AutocompleteModule, DailyReportModule,CashmemoReportModule, MaterialModule,LrReportModule],
    declarations: [
        PartyLessCheckUploadTypeComponent, BalancingDifferenceTallyReportComponent,
         DebitNoteStmtComponent,PartyLessCheckComponent,PartyLessCheckDetailsComponent,
         MlessCheckPopupComponent, PartyLessCheckDetailsV2Component ],
    entryComponents:[PartyLessCheckDetailsComponent, MlessCheckPopupComponent, PartyLessCheckDetailsV2Component]
} )
export class OthersModule { }
