<!-- Row -->
<div class="row" id="{{pageId}}cashMemoRptId" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Cash Memo Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<!-- <h6 class="card-title">Tempo Details</h6> -->
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>From Date</label> <input class="form-control"
													placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates"
													ngbDatepicker #fromDates="ngbDatepicker"
													[(ngModel)]="fromDatesModal" autocomplete="off">
												<div class="input-group-append" (click)="fromDates.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>To Date</label> <input id="{{pageId}}toDates"
													class="form-control" placeholder="yyyy-mm-dd"
													name="toDates" ngbDatepicker #toDates="ngbDatepicker"
													[(ngModel)]="toDatesModal" autocomplete="off">
												<div class="input-group-append" (click)="toDates.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12"
										[hidden]="!hideShowDestinationDropDown">
										<div class="form-group">
											<div class="input-group">
												<label>Destination</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-road"></i>
													</span>
												</div>
												<input #destinationStation id="{{pageId}}destinationStationId"
													type="text" class="form-control"
													(selectItem)="clickListnerForDestinationStation($event)"
													[(ngModel)]="modelDestinationStation"
													[ngbTypeahead]="searchDestinationStation"
													[resultFormatter]="formatterDestinationStation"
													[inputFormatter]="formatterDestinationStation"
													(focus)="focusDestinationStationTA$.next($any($event).target.value)" />
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}partyName">
												<label>Party Name</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-user"></i>
													</span>
												</div>
												<!--<select class="custom-select col-12">
													<option selected>Select.....</option>
													<option value="aKCompany">A K Company</option>
													<option value="sJTraders">S J Traders</option>
													<option value="aRFootwear">A R Footwear</option>
												</select>-->
												<input #partyName id="{{pageId}}consigneePartyNameId" type="text"
													class="form-control"
													(selectItem)="clickListnerForConsigneePartyName($event)"
													[(ngModel)]="modelConsigneePartyName"
													[ngbTypeahead]="searchConsigneePartyName"
													[resultFormatter]="formatterConsigneePartyName"
													[inputFormatter]="formatterConsigneePartyName"
													(focus)="focusConsigneePartyNameTA$.next($any($event).target.value)" />
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Select Type</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-check"></i>
													</span>
												</div>
												<select class="custom-select col-12" id="{{pageId}}selectType">
													<option selected value="Select Type">Select
														Type.....</option>
													<option value="edit">Edit</option>
													<option value="delete">Delete</option>
												</select>
											</div>
										</div>
									</div>



								</div>
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" style="text-align: center;">
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}searchBtn" (click)="validateSearchBtn()">Search</button>
									<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
										(click)="clearBtn()">Clear</button>
								</div>
							</div>

							<div class="col-md-9 vl">
								<!-- spinner start-->
								<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
									<div class="form-group">
										<div class="input-group">
											<mat-progress-bar mode="indeterminate" style="color: green;">
											</mat-progress-bar>
											<br>
											<h6 class="card-title" align='center'
												style="color: green; margin: auto; font-size: 18px;">
												Please Wait Loading Details.....</h6>
										</div>
									</div>
								</div>
								<!-- spinner end-->
								<button type="submit" class="dt-button"
									style="margin-left: 55%;" (click)="customPrintCashMemoRpt()"
									id="{{pageId}}printAllBtn">
									<span><i class="fas fa-print">Print</i></span>
								</button>
								<div class="box-body">
									<!-- 									<h6 class="card-title border_bottom">Paid Report 1</h6> -->
									<table datatable id="{{pageId}}cashememoRptTableId"
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsCashememoRpt"
										[dtTrigger]="dtTriggerCashememoRpt">

                                        <thead>
                                            <tr>
                                                <th>LR No</th>
                                                <th>Memo No</th>
                                                <th>Memo Amt</th>
                                                <th>Source</th>
                                                <th>Modified By</th>
                                                <th>Modified Date</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cashememoRptData of cashememoRptDataList ">
                                                <!-- <td><a [routerLink]="" -->
                                                <td><a (click)="cashMemoHistoryPopUpBtnLink(cashMemoHistoryPopUpTemplate,cashememoRptData)">
														{{ cashememoRptData.gcNoteNumber }}</a></td>
												<td>{{ cashememoRptData.memoNumber }}</td>
												<td>{{ cashememoRptData.memoAmt }}</td>
												<td>{{ cashememoRptData.source }}</td>
												<td>{{ cashememoRptData.lastModifiedBy }}</td>
												<td>{{ cashememoRptData.lastModifiedDateStr }}</td>
												<td>{{ cashememoRptData.reason }}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
	<div class="form-group">
		<div class="input-group">
			<ng-template #cashMemoHistoryPopUpTemplate let-ok="close"
				let-d="dismiss"> <!--<div class="modal-header">
					<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>-->
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<div class="card-body">
							<div class="row">
								<div *ngIf="viewCashmemoDetailsRpt" class="col-sm-12 col-md-12">
									<app-cashmemo-details-report></app-cashmemo-details-report>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-sm-12 col-md-4"></div>
								<div class="col-sm-12 col-md-4">
									<button style="float: center;" type="button"
										class="btn btn-dark" id="{{pageId}}cancelBtnId"
										(click)="closeCashmemoRptPopUp()">Close</button>
								</div>
								<div class="col-sm-12 col-md-4"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</ng-template>
		</div>
	</div>
</div>
<!--End Pop Up-->
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
	id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>