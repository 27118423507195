export class FortNightStmtDto {
    fromDate: string;
    toDate: string;
    destination: string;
    lrNumber: string;
    invoiceNumber: string;
    totalLrs: number;
    totalInvoice: number;
    totalArticles: number;
    totalActWgt: number;
    totalChgWgt: number;
    totalAmount: number;
    totalPaid: number;
    souce: string;
    stmtId: string;
    fromPeriod: Date;
    toPeriod: Date;
    stmtPeriod: Date;
    serviceTaxAdd: number;
    TDSAdd: number;
    serviceChgAdd: number;
    chkPostExpAdd: number;
    otherAdd1: number;
    commissionDed: number;
    hireAmtDed: number;
    rewardingAmtDed: number;
    partyLessDed: number;
    crossingchgDed: number;
    otherDed1: number;
    grandTotal: number;
    receivableAmt: number;
    createdDate: Date;
    createdBy: string;
    updatedBy: string;
    updatedDate: Date;
    stmtDate: Date;
    status: string;
    initialCommission: number;
    commSlaps: string;
    fixedOn: Date;
    effectiveFrom: Date;
    effectiveTo: Date;
    fixedBy: string;
    mode: string;
    serviceChg: number;
    serviceTax: number;
    fortNightStmtDuration: string;
    unit: string;
    multipleAdd: string;
    multipleDed: string;
    initialBalanceAmt: number;
    cashAmt: number;
    chequeAmt: number;
    pdcAmt: number;
    receivedDate: Date;
    chequeNumber: string;
    chequeDate: Date;
    cheqBankName: string;
    pdcNumber: string;
    pdcDueDate: Date;
    pdcBankName: string;
    balanceAmt: number;
    stmtDuration: string;
    chequeAmtStr: string;
    pdcAmtStr: string;
    pdcDueDateStr: string;
    size: number;
    description: string;
    credit: number;
    debit: number;
    debitTotal: number;
    creditTotal: number;
    //    HashMap<String, FortNightStmtDto> hashMap;
    toPay: number;
    prevRecAmt: number;
    stmtNo: string;
    amtRecId: number;
    role: string;
    officeType: string;
    toDestination: string;
    dedAmount: number;
    daysLate: number;
    debitNoteId: number;
    branch: string;
    agentName: string;
    amtToBeReceived: number;
    searchMode: string;
    commiosionAmt: number;
    tempoFrgtAmt: number;
    hamali: number;
    lc: number;
    bc: number;
    phoneNum: string;
    emailId: string;
    removeAmt: number;
    autoId: number;
    reportMode: string;
    miscellaneous: string;
    miscellValue: string;
    modeForMiscellaneous: string;
    //    List<String> list;
    diff: number;
    perDayWgt: number;
    totalCommission: number;
    totalFreight: number;
    commReduced: number;
    actCommsPaid: number;
    chequeDateStr: string;
    oldBalance: number;
    totChequeAmt: number;
    totPdcAmt: number;
    dateOfPayment: Date;
    totalAmtPaid: number;
    loaderHead: string;
    totalKantaWgt: number;
    receiptChg: number;
    oldDues: number;
    oldDuesDN: string;
    lastDue: number;
    lastDueDN: string;
    currentDue: number;
    currentDueDN: string;
    lastDueDate: Date;
    currentDueDate: Date;
    mainDebitHeads: string;
    chkPostExp: number;
    claims: number;
    delhiPartyLess: number;
    doorDeliv: number;
    loadUnloadHamali: number;
    mumbaiPartyLess: number;
    sharingOfRent: number;
    tempoFreight: number;
    othersHead: number;
    incentive: number;
    penalty: number;
    lessLrs: number;
    checkedLrs: number;
    unCheckedLrs: number;
    actualLessAmt: number;
    enteredLessAmt: number;
    diffValue: number;
    stmttotalLrs: number;
    heading: string;
    oldDuesFormatted: string;
    totalDuesFormatted: string;
    lastDuesFormatted: string;
    currentDuesFormatted: string;
    //    List<FortNightStmtDto> stmtDtos;
    netOldDues: string;
    netLastDues: string;
    netCurrentDues: string;
    netTotDues: string;
    mLessPrec: number;
    mLessAmt: number;
    mLessAppliedOn: Date;
    mLessAppliedBy: string;
    companyId: string;
    userId: string;
    mainHeadList: string;
    subDebitHeads: string;
    mainHeadNew: string;
    subDebitNew: string;
	isTrue : boolean;
    
	//maaz15042021
	mainBranch:string;
	stmtDateStr:string;
    fromDays:string;
    toDays:string;
    stmtDtos: FortNightStmtDto[];
	stmtDtos1: string[];
	fromPeriodStr:string;
	toPeriodStr:string;
	branchType:string;
	//maaz 26042021
	isShowAll:boolean;
	dateOfPaymentStr:string;
	userDataDtos: string[];
	list: Array<String>;
    amtPaid:boolean;
    receivedDateStr:string;
    statementCopyURL:string;
    statementCopyURL2:string;
}