import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { LRDto } from "src/app/dto/LR-dto";
import { LrService } from 'src/app/dataService/lr-service';
import *  as moment from 'moment';

@Component( {
    selector: 'app-destination-agent-statement-generation',
    templateUrl: './destination-agent-statement-generation.component.html',
    styleUrls: ['./destination-agent-statement-generation.component.css']
} )
export class DestinationAgentStatementGenerationComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    destinationAgentStatementGenerationDataList: any;
    onDestroyUnsubscribtionDestinationAgentStatementGeneration: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    modalToPeriod:any ="";
    modalFromPeriod:any =""
    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    pageId="deasc";


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerDestinationAgentStatementGeneration: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsDestinationAgentStatementGeneration: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    
    lrDtoUserValues: LRDto = new LRDto();

    validateFromPeriod: any;
    validateToPeriod: any;
    setStationName: any;
    setFromPeriod: any;
    setToPeriod: any;
    
    fromDateReadOnly: boolean = true;
    generateBtnReadOnly: boolean = false;
    fortNightStmtDtoForGenerateValidate: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForGenerateValidateReturn: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForGenerateStmt: FortNightStmtDto = new FortNightStmtDto();
    
    constructor( private router: Router,private destStmtReport : DestStmtReport, private lrService: LrService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStmtDetails();
        }

    }
    ngOnInit(): void {
        this.dtOptionsDestinationAgentStatementGeneration = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }
    
    getUserValues() {
		this.lrDtoUserValues = new LRDto();
		this.lrDtoUserValues.stationType = "Agent";
	}
    getStmtDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.destStmtReport.getStationForStmt(this.lrDtoUserValues).subscribe(
			(response) => {
				$("#"+this.pageId+"stmtDetailsTableId").DataTable().destroy();
				this.destinationAgentStatementGenerationDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.destinationAgentStatementGenerationDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerDestinationAgentStatementGeneration.next();
	            }, 500);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the station statement details", "info");
			}, () => console.log('done');
	};
	
    ngOnDestroy(): void {
        this.dtTriggerDestinationAgentStatementGeneration.unsubscribe();
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    
    selectedRowSetAgentStmtGenDetails(agentStmtGenerationData) {
        this.setStationName = null;
        this.setFromPeriod = null;
        this.setToPeriod = null;

        this.setStationName = agentStmtGenerationData.source;
        this.setFromPeriod = agentStmtGenerationData.fromDate;
        this.setToPeriod = agentStmtGenerationData.toDate;

        this.fromDateReadOnly = false;
        this.generateBtnReadOnly = false;
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"fromDates").val('');
        if (agentStmtGenerationData.stmtDateStr != null &&
            agentStmtGenerationData.stmtDateStr != undefined) {
            $("#"+this.pageId+"fromDates").val(agentStmtGenerationData.stmtDate);
        }

        if (agentStmtGenerationData.fortNightStmtNo == null) {
            this.fromDateReadOnly = true;
        }
    }
    
    validateToPeriodCalendar($event) {
        if ($("#"+this.pageId+"fromDates").val() != null && $("#"+this.pageId+"fromDates").val() != undefined &&
            $("#"+this.pageId+"fromDates").val() != "") {
            this.generateBtnReadOnly = true;
        } else {
            this.generateBtnReadOnly = false;
        }
    }
    
    validateGenerateBtn() {
        if ($("#"+this.pageId+"fromDates").val() == null || $("#"+this.pageId+"fromDates").val() == undefined ||
            $("#"+this.pageId+"fromDates").val() == "" || $("#"+this.pageId+"toDates").val() == null ||
            $("#"+this.pageId+"toDates").val() == undefined || $("#"+this.pageId+"toDates").val() == "") {
            swal("Mandatory Field", "Please select the Mandatory Field", "warning");
            return false;
        } else {
            this.validateStmtDate();
        }
    }
    
    validateStmtDate() {
        this.fortNightStmtDtoForGenerateValidate = new FortNightStmtDto();
        this.validateFromPeriod = null;
        this.validateToPeriod = null;

        this.validateFromPeriod = $("#"+this.pageId+"fromDates").val();
        this.validateToPeriod = $("#"+this.pageId+"toDates").val();

        this.fortNightStmtDtoForGenerateValidate.fromPeriod = this.validateFromPeriod;
        this.fortNightStmtDtoForGenerateValidate.toPeriod = this.validateToPeriod;
        this.fortNightStmtDtoForGenerateValidate.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.validateStmtDate(this.fortNightStmtDtoForGenerateValidate).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.fortNightStmtDtoForGenerateValidateReturn = null;
                this.fortNightStmtDtoForGenerateValidateReturn = response;
                if (!this.fortNightStmtDtoForGenerateValidateReturn.isTrue) {
                    swal("Invalid Period Selection", "To-Period should be Before the Current Date", "warning");
                    return false;
                } else {
                    swal({
                        title: "Confirm Generate",
                        text: this.setStationName + " - Period : " +
                            moment($("#"+this.pageId+"fromDates").val()).format("DD-MM-YYYY") + " To " +
                            moment($("#"+this.pageId+"toDates").val()).format("DD-MM-YYYY") +
                            " Sure U Want To Generate The Statement",
                        icon: "info",
                        buttons: ["No", "Yes"]
                    }).then((isConfirmYes) => {
                        if (isConfirmYes) {
                            this.setDestinationAgentStmtDetail();
                        }
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While generating the statement!", "error");
            }, () => console.log('done');
    }
    
    setDestinationAgentStmtDetail() {
        this.getUserValuesForStmtGeneration();
        this.showSpinnerForAction = true;
        this.destStmtReport.addFortnightStmtDetails(this.fortNightStmtDtoForGenerateStmt).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "Success") {
                    swal("Completed", "Statement Generated Successfully", "success");
                    this.clearField();
                    this.getStmtDetails();
                } else if (response.status == "NoCommission") {
                    swal("No Commission", "Commission not yet set, please set the Agent Commission to proceed!", "info");
                } else if (response.status == "NoLrs") {
                    swal("No Lrs", "No Lrs found to generate the statement, please verify it!", "info");
                } else if (response.status == "failed") {
                    swal("Error", "Ooops...Problem occured while Generating the Statement", "error");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while generating the statement!", "error");
            }, () => console.log('done');
    }
    
    getUserValuesForStmtGeneration(){
    	this.fortNightStmtDtoForGenerateStmt = new FortNightStmtDto();
    	this.fortNightStmtDtoForGenerateStmt.agentName = this.setStationName;
    	this.fortNightStmtDtoForGenerateStmt.fromPeriod = this.validateFromPeriod;
        this.fortNightStmtDtoForGenerateStmt.toPeriod = this.validateToPeriod;
        this.fortNightStmtDtoForGenerateStmt.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDtoForGenerateStmt.userId = this.userDataDtoReturnSession.userId;
    }
    
    validateClearBtn() {
        this.clearField();
    }

    clearField() {
        this.lrDtoUserValues = new LRDto();
        this.fortNightStmtDtoForGenerateValidate = new FortNightStmtDto();
        this.fortNightStmtDtoForGenerateValidateReturn = new FortNightStmtDto();
        this.fortNightStmtDtoForGenerateStmt = new FortNightStmtDto();

        this.validateFromPeriod = null;
        this.validateToPeriod = null;
        this.setStationName = null;
        this.setFromPeriod = null;
        this.setToPeriod = null;

        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"fromDates").val('');

        this.fromDateReadOnly = true;
        this.generateBtnReadOnly = false;
    }
    
    logInPage() {
	      this.router.navigate(['/authentication/login']);
	  }
}


