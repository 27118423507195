<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBkgPaidCollectionRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewBy" name="viewBy" #viewBy (change)="searchByMode(viewBy.value)">
															<option selected value="leftToParty">Left to Party</option>
															<option value="leftToPartySummary">Left to Party Summary</option>
															<option value="toPartyStmtNo">To Party Stmt No</option>
															<option value="amountCollected">Amount Collected</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewReportmode" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}reportMode" name="reportMode" #reportMode (change)="reportModeMode(reportMode.value)">
															<option selected value="rptModeDateWise">Date
																Wise</option>
															<option value="rptModeTillDate">Till
																Date</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input id="{{pageId}}fromDates" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" [(ngModel)]="fromDatesModal" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="toDatesModal">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div *ngIf="viewTillDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Till Date</label> <input id="{{pageId}}tillDate" class="form-control" placeholder="yyyy-mm-dd" name="tillDate" [(ngModel)]="tillDateModal" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Agent Name</label>
                                                        <input id="{{pageId}}fromStation" type="text" class="form-control" [(ngModel)]="modelFromStation" [ngbTypeahead]="searchFromStation" [resultFormatter]="formatterFromStation" [inputFormatter]="formatterFromStation" (focus)="focusFromStationTA$.next($any($event).target.value)"
                                                            placeholder="Select Agent" (selectItem)="agentSearchViewDropDownListner($event)" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewConsignor" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consignorGstNo">
                                                        <label>Consignor GST No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input #consignorGSTNumber placeholder="Enter Consignor GST Number" (keyup)="getConsignorGstNumber($event)" type="text" class="form-control" id="{{pageId}}consignorGSTNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewConsignor" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <input #consignorIndexSearchView id="{{pageId}}consignorIndexSearchView" name="consignorIndexSearchView" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorSearchViewDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchViewTypeTA"
                                                            (focus)="focusConsignorIndexSearchViewTA$.next($any($event).target.value)" #instanceConsignorIndexSearchView="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewConsignor" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <input #consignorNameSearchView id="{{pageId}}consignorNameSearchView" name="consignorNameSearchView" type="text" class="form-control" (selectItem)="rowSelectedConsignorSearchView($event)" [(ngModel)]="modelConsignorNameSearchView" [ngbTypeahead]="consignorNameSearchViewTypeTA"
                                                            [resultFormatter]="formatterConsignorNameSearchView" [inputFormatter]="formatterConsignorNameSearchView" (focus)="focusConsignorNameSearchViewTA$.next($any($event).target.value)" placeholder="Select Consignor Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewTpNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label class="label_custome">Statement No</label>
                                                        <input #tpCode type="text" id="{{pageId}}tpCode" [(ngModel)]="tpCodeModel" class="form-control" aria-describedby="basic-addon11">
                                                        <input #tpNo type="text" id="{{pageId}}tpNo" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                            </div>
                                        </div>
                                        <table width=100%>
                                            <tr>
                                                <td width=80%>
                                                    <!-- <h6 class="card-title">CashMemo LetterHead Report</h6> -->
                                                </td>
                                                <td width=20%>
                                                    <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrintLetterHeadRpt()" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                </td>
                                            </tr>
                                        </table>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div [hidden]="!viewTopartyDetailTable" class="box-body">
                                                    <table datatable id="{{pageId}}toPartyLRDetailsTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionstoPartyLRDetails" [dtTrigger]="dtTriggertoPartyLRDetails">
                                                        <thead>
                                                            <tr>
                                                                <th>Toparty Stmt</th>
                                                                <th>Destination</th>
                                                                <th>LR No</th>
                                                                <th>Booking Date</th>
                                                                <th>Consignor Name</th>
                                                                <th>Consignee Name</th>
                                                                <th>Art</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Basic Frgt</th>
                                                                <th>Src Hamali</th>
                                                                <th>GC Chg</th>
                                                                <th>FOV</th>
                                                                <th>LC Chg</th>
                                                                <th>BC Chg</th>
                                                                <th>Others</th>
                                                                <th>DD Amt</th>
                                                                <th>LR Tot</th>
                                                                <th>S.Chg</th>
                                                                <th>R.Chg</th>
                                                                <th>Dest.Hamali</th>
                                                                <th>Memo Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let toPartyLRDetailsData of toPartyLRDetailsDataList;">
                                                                <td>{{ toPartyLRDetailsData.statementNo }}</td>
                                                                <td>{{ toPartyLRDetailsData.destination }}</td>
                                                                <td>{{ toPartyLRDetailsData.lrNumber }}</td>
                                                                <td>{{ toPartyLRDetailsData.bookingDateStr }}</td>
                                                                <td>{{ toPartyLRDetailsData.consignor }}</td>
                                                                <td>{{ toPartyLRDetailsData.consigneeName }}</td>
                                                                <td>{{ toPartyLRDetailsData.articles }}</td>
                                                                <td>{{ toPartyLRDetailsData.actualWeight }}</td>
                                                                <td>{{ toPartyLRDetailsData.chargedWeight}}</td>
                                                                <td>{{ toPartyLRDetailsData.lorryFreight }}</td>
                                                                <td>{{ toPartyLRDetailsData.hamaligd}}</td>
                                                                <td>{{ toPartyLRDetailsData.gcChg }}</td>
                                                                <td>{{ toPartyLRDetailsData.riskCharge }}</td>
                                                                <td>{{ toPartyLRDetailsData.lcChg }}</td>
                                                                <td>{{ toPartyLRDetailsData.bcChg }}</td>
                                                                <td>{{ toPartyLRDetailsData.others }}</td>
                                                                <td>{{ toPartyLRDetailsData.ddAmt }}</td>
                                                                <td>{{ toPartyLRDetailsData.amount }}</td>
                                                                <td>{{ toPartyLRDetailsData.serviceCharges }}</td>
                                                                <td>{{ toPartyLRDetailsData.receiptCharges }}</td>
                                                                <td>{{ toPartyLRDetailsData.hamaliDD }}</td>
                                                                <td>{{ toPartyLRDetailsData.grandTotal }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <!-- summary -->
                                                <div [hidden]="!viewTopartySummayTable" class="box-body">
                                                    <table datatable id="{{pageId}}toPartyLRSummaryTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionstoPartyLRSummary" [dtTrigger]="dtTriggertoPartyLRSummary">
                                                        <thead>
                                                            <tr>
                                                                <th>Stmt No</th>
                                                                <th>Stmt Date</th>
                                                                <th>Toparty Name</th>
                                                                <th>Total LRs</th>
                                                                <th>Tot Art</th>
                                                                <th>Tot Act Wt</th>
                                                                <th>Tot Chg Wt</th>
                                                                <th>Tot Basic Frgt</th>
                                                                <th>Tot Src Hamali</th>
                                                                <th>Tot GC Chg</th>
                                                                <th>Tot FOV</th>
                                                                <th>Tot LC Chg</th>
                                                                <th>Tot BC Chg</th>
                                                                <th>Tot Others</th>
                                                                <th>Tot DD Amt</th>
                                                                <th>Tot LR Amt</th>
                                                                <th>Tot S.Chg</th>
                                                                <th>Tot R.Chg</th>
                                                                <th>Tot Dest.Hamali</th>
                                                                <th>Tot Memo Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let toPartyLRSummaryData of toPartyLRSummaryDataList;">
                                                                <td (click)="gotoLeftToPartyPopUPLinkData(toPartyLRSummaryData,printPreviewStmtPopUpPopUpTemplate);">
                                                                    <u class="hyperLink_td">{{
																		toPartyLRSummaryData.statementNo }}</u>
                                                                </td>
                                                                <td>{{ toPartyLRSummaryData.tpStmtDateStr }}</td>
                                                                <td>{{ toPartyLRSummaryData.topartyName }}</td>
                                                                <td>{{ toPartyLRSummaryData.totalMemos }}</td>
                                                                <td>{{ toPartyLRSummaryData.articles }}</td>
                                                                <td>{{ toPartyLRSummaryData.actualWeight }}</td>
                                                                <td>{{ toPartyLRSummaryData.chargedWeight}}</td>
                                                                <td>{{ toPartyLRSummaryData.lorryFreight }}</td>
                                                                <td>{{ toPartyLRSummaryData.hamaligd}}</td>
                                                                <td>{{ toPartyLRSummaryData.gcChg }}</td>
                                                                <td>{{ toPartyLRSummaryData.riskCharge }}</td>
                                                                <td>{{ toPartyLRSummaryData.lcChg }}</td>
                                                                <td>{{ toPartyLRSummaryData.bcChg }}</td>
                                                                <td>{{ toPartyLRSummaryData.others }}</td>
                                                                <td>{{ toPartyLRSummaryData.ddAmt }}</td>
                                                                <td>{{ toPartyLRSummaryData.amount }}</td>
                                                                <td>{{ toPartyLRSummaryData.serviceCharges }}</td>
                                                                <td>{{ toPartyLRSummaryData.receiptCharges }}</td>
                                                                <td>{{ toPartyLRSummaryData.hamaliDD }}</td>
                                                                <td>{{ toPartyLRSummaryData.grandTotal }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <!-- amount collected -->
                                                <div [hidden]="!viewAmtCollectedDetailsTable" class="box-body">
                                                    <table datatable id="{{pageId}}amtCollectedDetailsTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionstoAmtCollectedDetails" [dtTrigger]="dtTriggerAmtCollectedDetails">
                                                        <thead>
                                                            <tr>
                                                                <th>LR Number</th>
                                                                <th>Destination</th>
                                                                <th>Booking Date</th>
                                                                <th>Consignor Name</th>
                                                                <th>Consignee Name</th>
                                                                <th>Memo Amt</th>
                                                                <th>Payment Type</th>
                                                                <th>To Party</th>
                                                                <th>TP Stmt Dt</th>
                                                                <th>TP Stmt No</th>
                                                                <th>Art</th>
                                                                <th>Less Amt</th>
                                                                <th>Less Description</th>
                                                                <th>Cash Amt</th>
                                                                <th>Chq Amt</th>
                                                                <th>NEFT Amt</th>
                                                                <th>UPI Amt</th>
                                                                <th>Cheque No</th>
                                                                <th>Ref No</th>
                                                                <th>Bank Name</th>
                                                                <th>Chq Dt</th>
                                                                <th>Collection Dt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let amtCollectedDetailsData of amtCollectedDetailsDataList;">
                                                                <td>{{ amtCollectedDetailsData.lrNumber }}</td>
                                                                <td>{{ amtCollectedDetailsData.destination }}</td>
                                                                <td>{{ amtCollectedDetailsData.bookingDateStr }}</td>
                                                                <td>{{ amtCollectedDetailsData.consignor }}</td>
                                                                <td>{{ amtCollectedDetailsData.consigneeName }}</td>
                                                                <td>{{ amtCollectedDetailsData.grandTotal }}</td>
                                                                <td>{{ amtCollectedDetailsData.mode }}</td>
                                                                <td>{{ amtCollectedDetailsData.topartyName }}</td>
                                                                <td>{{ amtCollectedDetailsData.tpStmtDateStr }}</td>
                                                                <td>{{ amtCollectedDetailsData.statementNo}}</td>
                                                                <td>{{ amtCollectedDetailsData.articles }}</td>
                                                                <td>{{ amtCollectedDetailsData.lessAmt}}</td>
                                                                <td>{{ amtCollectedDetailsData.lessDescription }}</td>
                                                                <td>{{ amtCollectedDetailsData.cashAmt }}</td>
                                                                <td>{{ amtCollectedDetailsData.chequeAmt }}</td>
                                                                <td>{{ amtCollectedDetailsData.neftAmt }}</td>
                                                                <td>{{ amtCollectedDetailsData.upiAmt }}</td>
                                                                <td>{{ amtCollectedDetailsData.chqNumber }}</td>
                                                                <td>{{ amtCollectedDetailsData.neftRefNo }}</td>
                                                                <td>{{ amtCollectedDetailsData.bankName }}</td>
                                                                <td>{{ amtCollectedDetailsData.date }}</td>
                                                                <td>{{ amtCollectedDetailsData.completedOnStr }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div class="col-md-12" id="{{pageId}}popupModalForLeftToParty">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #printPreviewStmtPopUpPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewPrintPopupLeftToParty" id="{{pageId}}popupPrintPreviewId">
                                                <app-bkg-left-to-party-statement-details>
                                                </app-bkg-left-to-party-statement-details>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeLeftToPartyStmtBtnPopUp();">Close</button>
                                            <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}cancelBtnId" (click)="printAllMemoBtn();">Print All Memo</button>
                                            <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}cancelBtnId" (click)="print2PopUpBtn();">Print2</button>
                                            <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}cancelBtnId" (click)="print1PopUpBtn();">Print1</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="viewLeftToPartyCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewLeftToPartyCustomPrintV1">
        <app-bkg-left-toparty-details-print></app-bkg-left-toparty-details-print>
    </div>

    <div *ngIf="viewLeftToPartyCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewLeftToPartyCustomPrintV2">
        <app-bkg-left-toparty-details-print2></app-bkg-left-toparty-details-print2>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

    <div *ngIf="viewAllMemoPrint" onafterprint="afterPrint()" id="{{pageId}}viewAllMemoPrint">
        <app-cashmemo-bkg-print-toparty></app-cashmemo-bkg-print-toparty>
    </div>
</body>

</html>