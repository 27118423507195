import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";

@Component( {
    selector: 'app-pending-consignee',
    templateUrl: './pending-consignee.component.html',
    styleUrls: ['./pending-consignee.component.css']
} )
export class PendingConsigneeComponent implements  OnInit {

    pendingConsigneeDataList: any;
    dtTriggerPendingConsignee: Subject<any> = new Subject();
    dtOptionsPendingConsignee: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    partyMasterDtoUserValue: PartyMasterDto = new PartyMasterDto();
pageId="pedcc";
    constructor(private router: Router,private consigneeService : ConsigneeService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }
    
    ngOnInit(): void {
        this.dtOptionsPendingConsignee = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerPendingConsignee.unsubscribe();
    // }
    
    getUserValues() {
		this.partyMasterDtoUserValue = new PartyMasterDto();
		this.partyMasterDtoUserValue.office = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoUserValue.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoUserValue.status = "Partial";
	}
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.consigneeService.getConsigneeDetailsPendingDB(this.partyMasterDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"pendingConsigneeTableId").DataTable().destroy();
				this.pendingConsigneeDataList = [];
				if (response.length == 0) {
					swal({
						title: "Pending Consignee",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.pendingConsigneeDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerPendingConsignee.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
               this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	ngAfterViewInit(): void {
        this.dtTriggerPendingConsignee.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }}
