export class MasterDto {
    memberName: string;
    city: string;
    gstNumber: string;
    office: string;
    companyId: string;
    selectedEmail: string;
    address: string;
    pincode: number;
    allEmailId: string;
    teleNum: number;
    faxNum: number;
    emailId: string;
    password: string;
    cheatCode: string;
    receiptCharges: number;
    serviceCharges: number;
    destHamaliPerArt: number;
    srdPerc: number;
    hamaliPerc: number;
    unloadingChg: number;
    perUnit: string;
    incentiveDays: number;
    inRemindSms: string;
    inStockSms: string;
    deliveryPendingStockSms: string;
    username: string;
    status: string;
}