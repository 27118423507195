import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

export interface DialogData {
    animal: string;
    name: string;
}
@Component({
    selector: 'app-update-consignor-rate-for-all-party',
    templateUrl: './update-consignor-rate-for-all-party.component.html',
    styleUrls: ['./update-consignor-rate-for-all-party.component.css']
})
export class UpdateConsignorRateForAllPartyComponent implements OnInit {
    pageId = "updcgrrateallpartyspec";

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;

    /* For Source Drop Down Start */
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();


    /* Commodity Drop Down Start Here */
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityListPartySpec: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
    /* Commodity Drop Down End Here */

    /* For Destination Drop Down */
    destinationOptions: LRDto[];
    lrDtoToStation: LRDto = new LRDto();
    destinationDtoForAll: LRDto = new LRDto();
    public modelDestinationPartySpec: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { godownName: string }) => x.godownName;

    ///For Source Main Booking Station
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSourcePartySpec: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;

    /* Consignee  Drop Down*/
    partyDtoConsigneeName: PartyMasterDto = new PartyMasterDto();
    consigneeNameOptions: PartyMasterDto[];
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    searchConsigneeName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    selectedDestinationForCommodityPopUp: any;
    rateChangeTxtId = 'Rate';

    viewPercentageFieldPopUp: boolean = false;
    viewRateFieldPopUp: boolean = true;
    viewPerUnitFieldPopUp: boolean = true;

    rateMasterDtoGetUserValPopUp: RateMasterDto = new RateMasterDto();
    rateMasterDtoForSaveUpdate: RateMasterDto = new RateMasterDto();
    selectedDropDownMainStation: any;
    validateSelectedUpdateModePopUp: any;
    validateSelectedDestinationPopUp: any;
    validateSelectedSourcePopUp: any;
    validateSelectedCommodityPopUp: any;
    validateSelectedUnitPopUp: any;
    validateSelectedRatesFixedDisTextPopUp: any;
    validateSelectedRatesFixedDisNumberPopUp: any;
    validateSelectedUnitPercTextPopUp: any;
    validateSelectedUnitPercRateNumberPopUp: any;
    validateSelectedPerUnitPopUp: any;
    validateSelectedFixedByPopUp: any;
    validateSelectedEffectedFromPopUp: any;
    validateSelectedFixedOnPopUp: any;

    listConsigneeId: Array<Number> = [];
    viewSubRate = false;
    viewSaveBtn: boolean = true;
    viewActChgWgtField = false;
    subStationCheckStatus: boolean = false;

    selectedRateFor: any;
    // enteredSourceForSave: any;
    enteredDestinationForSave: any;
    enteredDDChargeForSave: any;
    enteredCommodityForSave: any;
    enteredRateTypeForSave: any;
    enteredBasicRateForSave: any;
    enteredHamaliForSave: any;
    enteredGCChargeForSave: any;
    enteredAOCChargeForSave: any;
    enteredOtherChargeForSave: any;
    enteredRiskChargeForSave: any;
    enteredFixedBy: any;
    selectedEeffectDate: any;
    selectedFixedOn: any;
    enteredRCChargeForSave: any;
    enteredSCChargeForSave: any;
    enteredDestHamaliForSave: any;
    enteredBasicRateForSaveSub: any;
    enteredHamaliForSaveSub: any;
    enteredGCChargeForSaveSub: any;
    enteredAOCChargeForSaveSub: any;
    enteredOtherChargeForSaveSub: any;
    enteredRiskChargeForSaveSub: any;
    enteredFixedBySub: any;
    selectedFixedOnSub: any;
    enteredRCChargeForSaveSub: any;
    enteredSCChargeForSaveSub: any;
    enteredDestHamaliForSaveSub: any;
    enteredPerUnitForSave: any;
    enteredDDChargeForSaveSub: any;
    isMainCommodityValid = false;

    dtOptionsDestinationPartySpec: any;
    dtTriggerDestinationPartySpec: Subject<any> = new Subject();

    destinationDataList: any;
    lrDtoListDestinationChecked: Array<LRDto> = [];
    private newAttributeSetDestinationList: any = {};
    listDestination: Array<String> = [];
    listSource: Array<String> = [];
    viewBySuperAdmin: boolean = false;
    sourceView = false;
    selectedDestinationForConsigneeName: any;
    partyDtoConsigneeNameAll: PartyMasterDto = new PartyMasterDto();
    modelConsignee: any;
    modelConsignor: any;
    enteredConsigneeId: any;
    enteredConsignorId: any;
    viewConsignorDestinationRadio = false;
    viewSourcesPartySpec = false;
    viewSourcesPartyMulti: boolean = false;
    dtOptionsSourcePartySpec: any;
    dtTriggerSourcePartySpec: Subject<any> = new Subject();
    private newAttributeSetSourceList: any = {};
    lrDtoListSourceChecked: Array<LRDto> = [];
    selectedPartyType: any;

    public modelIndex: any;
    @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
    indexTA: Array<any> = [];
    focusIndexTA$ = new Subject<string>();
    indexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.indexTA
                : this.indexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    // index for party search
    public modelIndexPartySearch: any;
    @ViewChild('instanceIndexPartySearch') instanceIndexPartySearch: NgbTypeahead;
    indexPartySearchTA: Array<any> = [];
    focusIndexPartySearchTA$ = new Subject<string>();
    indexSearchPartySearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusIndexPartySearchTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.indexPartySearchTA
                : this.indexPartySearchTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    indexOptions = [
        { id: 1, label: 'ALL' },
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];
    /* Consignor  Drop Down*/
    validateIndexValue: any;
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    public modelConsignorName: any;
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;

    /* Consignor  Drop Down search party*/
    public modelConsignorNamePartySearch: any;
    consignorNamePartySearchTA: Array<PartyMasterDto> = [];
    focusConsignorNamePartySearchTA$ = new Subject<string>();
    consignorNameSearchPartySearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNamePartySearchTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNamePartySearchTA
                : this.consignorNamePartySearchTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorNamePartySearch = (x: { consignorName: string }) => x.consignorName;
    showSpinnerForConsignorName = false;
    modelMainBookingSource: any;
    viewConsigneeName: Boolean = true;
    viewConsignorName: Boolean = false
    destinationType: string = 'specific';
    viewConsignorSpecific: boolean = false;
    viewConsignorAllDestination: boolean = false;
    viewConsignorIndex: boolean = false;
    viewSpecificDestination: boolean = true;


    viewSpecificSource: boolean = true;
    dtTriggerCommodity: Subject<any> = new Subject();
    dtOptionsCommodity: any;
    commodityDataList: any;
    listCommodity: Array<String> = [];
    private newAttributeSetCommodityList: any = {};
    lrDtoLisCommodityChecked: Array<LRDto> = [];
    commodityType: string = 'specific';
    // viewAllCommodity: boolean=false;
    viewSpecificCommodity: boolean = true;
    sourceType: string = 'specific';
    constructor(private router: Router,
        private masterReadService: MasterReadService, private consignorService: ConsignorService,
        public dialogRef: MatDialogRef<UpdateConsignorRateForAllPartyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            console.log(data);
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "CnorAdminRate ViewSrcAll") {
                        this.viewBySuperAdmin = true;
                    }

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgrRateMtr All Src View") {
                        this.sourceView = true;
                    }
                }
            }

            this.getDestinationDetails();
            this.getCommodityMasterDetailsList();
            this.getMainBookingSourceDetails();
            // if (this.sourceView == false) {
            // this.getAgentDetailList(this.userDataDtoReturnSession.mainStation);
            // }
            this.setIndex();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    ngOnInit(): void {
        this.dtOptionsDestinationPartySpec = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "searching": false
        },
            this.dtOptionsSourcePartySpec = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 200,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "searching": false

            },
            this.dtOptionsCommodity = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 200,
                "scrollCollapse": true,
                "paging": false,
                "info": false
            }

    }

    ngOnDestroy(): void {
        this.dtTriggerDestinationPartySpec.unsubscribe();
        this.dtTriggerSourcePartySpec.unsubscribe();
        this.dtTriggerCommodity.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerDestinationPartySpec.next();
        this.dtTriggerSourcePartySpec.next();
        this.dtTriggerCommodity.next();
    }

    getDestinationMethod() {
        this.lrDtoToStation = new LRDto();
        this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
    }

    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
            (response) => {
                if (response.length > 0) {
                    $("#" + this.pageId + "selectedDestinationTableIdPartySpec").DataTable().destroy();

                    this.destinationDataList = [];
                    this.destinationDataList = response;
                    this.dtTriggerDestinationPartySpec.next();

                    this.destinationOptions = [];
                    this.destinationTA = [];

                    this.destinationOptions = response;

                    this.destinationDtoForAll = new LRDto();
                    this.destinationDtoForAll.godownName = "ALL";
                    this.destinationTA.push(this.destinationDtoForAll);

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');
    };

    getUserValuesForAgentDetailsList(mainSource) {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = this.agentDtoParam.mainStation = mainSource;;
    }

    getAgentDetailList(mainSource) {
        this.showSpinnerForAction = true;
        // $("#" + this.pageId + "agentNamePartySpec").val('');
        // this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList(mainSource);
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                $("#" + this.pageId + "selectedSourceTableIdPartySpec").DataTable().destroy();
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];

                } else {
                    this.agentDetailsOptionsSet = [];

                    this.agentDetailsOptionsSet = response;
                    console.log("Source");
                    console.log(this.agentDetailsOptionsSet);
                    this.dtTriggerSourcePartySpec.next();

                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getCommodityListRead() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.commodityDataList = [];
                    } else {
                        $("#" + this.pageId + "selectedCommodityTableId").DataTable().destroy();
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.commodityDataList = [];
                        this.commodityDataList = response;
                        this.dtTriggerCommodity.next();
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "All";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Master Details", "warning");
            },
            () => console.log('done');
    };

    searchUnitMethodPopUp(searchUnitPopUp: string) {
        if (searchUnitPopUp === 'rate') {
            this.viewRateFieldPopUp = true;
            this.viewPercentageFieldPopUp = false;

            this.viewPerUnitFieldPopUp = true;
        } else if (searchUnitPopUp === 'percentage') {
            this.viewRateFieldPopUp = false;
            this.viewPercentageFieldPopUp = true;

            this.viewPerUnitFieldPopUp = false;
        } else {
            this.viewRateFieldPopUp = false;
            this.viewPercentageFieldPopUp = false;
            this.viewPerUnitFieldPopUp = false;
        }
    }

    setOnload() {
        $("#" + this.pageId + "rateChangeTxtId").text('Rate');
    }

    searchRateMethodPopUp(searchRatePopUp: string) {
        if (searchRatePopUp === 'fixedRate') {
            this.rateChangeTxtId = 'Rate';
        } else if (searchRatePopUp === 'DiscRate') {
            this.rateChangeTxtId = 'Discount Rate (Rs)';
        } else {
            this.rateChangeTxtId = 'Rate';
        }
    }

    searchUnitPercMethodPopUp(searchUnitPercPopUp: string) {
        if (searchUnitPercPopUp === 'PLR') {

        } else if (searchUnitPercPopUp === 'PCM') {

        } else if (searchUnitPercPopUp === 'PFRT') {

        } else {

        }
    }

    SaveClickBtn() {
        swal({
            title: "Confirm Changes",
            text: "Sure You Want To Apply The Rate Changes...This changes cannot be Undo...",
            icon: "info",
            buttons: ["No", "Yes"]
        }).then((isConfirm) => {
            if (isConfirm) {
                this.validateBeforeSave();
            } else {
                console.log("No");
            }
        });
    }

    validateBeforeSave() {
        // this.enteredSourceForSave = null;
        this.selectedRateFor = null;
        this.enteredCommodityForSave = null;
        this.enteredDestinationForSave = null;
        this.enteredRateTypeForSave = null;
        this.enteredPerUnitForSave = null;
        this.enteredBasicRateForSave = null;
        this.enteredHamaliForSave = null;
        this.enteredGCChargeForSave = null;
        this.enteredAOCChargeForSave = null;
        this.enteredDDChargeForSave = null;
        this.enteredOtherChargeForSave = null;
        this.enteredRiskChargeForSave = null;
        this.enteredFixedBy = null;
        this.selectedEeffectDate = null;
        this.selectedFixedOn = null;
        this.enteredRCChargeForSave = null;
        this.enteredSCChargeForSave = null;
        this.enteredDestHamaliForSave = null;
        this.enteredBasicRateForSaveSub = null;
        this.enteredHamaliForSaveSub = null;
        this.enteredGCChargeForSaveSub = null;
        this.enteredAOCChargeForSaveSub = null;
        this.enteredDDChargeForSaveSub = null;
        this.enteredOtherChargeForSaveSub = null;
        this.enteredRiskChargeForSaveSub = null;
        this.enteredFixedBySub = null;
        this.selectedFixedOnSub = null;
        this.enteredRCChargeForSaveSub = null;
        this.enteredSCChargeForSaveSub = null;
        this.enteredDestHamaliForSaveSub = null;


        // this.enteredSourceForSave = $("#" + this.pageId + "agentNamePartySpec").val();
        this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
        this.enteredCommodityForSave = $("#" + this.pageId + "commodityListIdPartySpec").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateTypePartySpec").val();
        this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitIdPartySpec").val();
        this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateIdPartySpec").val();
        this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgIdPartySpec").val();
        this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeIdPartySpec").val();
        this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeIdPartySpec").val();
        this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeIdPartySpec").val();
        this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeIdPartySpec").val();
        this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeIdPartySpec").val();
        this.enteredFixedBy = $("#" + this.pageId + "fixedByPartySpec").val();
        this.selectedEeffectDate = $("#" + this.pageId + "effectFromDatePartySpec").val();
        this.selectedFixedOn = $("#" + this.pageId + "fixedOnDatePartySpec").val();
        this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
        this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
        this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
        this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSubPartySpec").val();
        this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
        this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSubPartySpec").val();
        this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSubPartySpec").val();
        this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSubPartySpec").val();
        this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSubPartySpec").val();
        this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSubPartySpec").val();
        this.enteredFixedBySub = $("#" + this.pageId + "fixedBySubPartySpec").val();
        this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSubPartySpec").val();
        this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
        this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
        this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();

        console.log("this.subStationCheckStatus");
        console.log(this.subStationCheckStatus);
        alert(this.sourceType)
        if (this.selectedPartyType != null && this.selectedPartyType != undefined && this.selectedPartyType == 'Consignor' && (this.enteredConsignorId == null || this.enteredConsignorId == 0)) {
            swal("Mandatory Fields", " Please select Consignor", "warning");
        } else if (this.selectedPartyType != null && this.selectedPartyType != undefined && this.selectedPartyType == 'Consignee' && (this.enteredConsigneeId == null || this.enteredConsigneeId == 0)) {
            swal("Mandatory Fields", " Please select Consignee", "warning");
        } else if ((this.commodityType == 'specific') && (this.lrDtoLisCommodityChecked == null || this.lrDtoLisCommodityChecked == undefined || this.lrDtoLisCommodityChecked.length == 0)) {
            swal("Mandatory Fields", " Please select Commodity", "warning");
        } else if ((this.destinationType == 'specific') && (this.lrDtoListDestinationChecked == null || this.lrDtoListDestinationChecked == undefined ||
            this.lrDtoListDestinationChecked.length == 0)) {
            swal("Mandatory Field", "Please select Destination!", "warning");
            return false;
        } else if ((this.sourceType == 'specific') && (this.lrDtoListSourceChecked == null || this.lrDtoListSourceChecked == undefined ||
            this.lrDtoListSourceChecked.length == 0)) {
            swal("Mandatory Field", "Please select Source!", "warning");
            return false;
        }
        else if (this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
            || this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
            || this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
            || this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "") {
            swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
        }
        else {
            // this.validateMainCommoditySelection();
            // if (this.isMainCommodityValid) {
            this.confirmSave();
            // }
        }
    }

    ClearClickBtn() {
        this.clearFieldPopUp();
    }

    clearFieldPopUp() {
        this.selectedDestinationForCommodityPopUp = null;
        this.rateChangeTxtId = 'Rate';

        this.rateMasterDtoGetUserValPopUp = new RateMasterDto();

        this.validateSelectedUpdateModePopUp = null;
        this.validateSelectedDestinationPopUp = null;
        this.validateSelectedSourcePopUp = null;
        this.validateSelectedCommodityPopUp = null;
        this.validateSelectedUnitPopUp = null;
        this.validateSelectedRatesFixedDisTextPopUp = null;
        this.validateSelectedRatesFixedDisNumberPopUp = null;
        this.validateSelectedUnitPercTextPopUp = null;
        this.validateSelectedUnitPercRateNumberPopUp = null;
        this.validateSelectedPerUnitPopUp = null;
        this.validateSelectedFixedByPopUp = null;
        this.validateSelectedEffectedFromPopUp = null;
        this.validateSelectedFixedOnPopUp = null;

        this.enteredRateTypeForSave = null;
        $("#" + this.pageId + "searchByRateType").val('');
        this.enteredPerUnitForSave = null;
        $("#" + this.pageId + "perUnitId").val('');
        this.enteredBasicRateForSave = null;
        $("#" + this.pageId + "basicRateId").val('');
        this.enteredHamaliForSave = null;
        $("#" + this.pageId + "hamaliChgId").val('');
        this.enteredGCChargeForSave = null;
        $("#" + this.pageId + "gcChargeId").val('');
        this.enteredAOCChargeForSave = null;
        $("#" + this.pageId + "aocChargeId").val('');
        this.enteredDDChargeForSave = null;
        $("#" + this.pageId + "ddChargeId").val('');
        this.enteredOtherChargeForSave = null;
        $("#" + this.pageId + "otherChargeId").val('');
        this.enteredRiskChargeForSave = null;
        $("#" + this.pageId + "fovChargeId").val('');
        this.enteredFixedBy = null;
        $("#" + this.pageId + "fixedBy").val('');
        this.selectedEeffectDate = null;
        $("#" + this.pageId + "effectFromDate").val('');
        this.selectedFixedOn = null;
        $("#" + this.pageId + "fixedOnDate").val('');
        this.enteredRCChargeForSave = null;
        $("#" + this.pageId + "rcChargeId").val('');
        this.enteredSCChargeForSave = null;
        $("#" + this.pageId + "scChargeId").val('');
        this.enteredDestHamaliForSave = null;
        $("#" + this.pageId + "destHamaliChgId").val('');
        this.enteredBasicRateForSaveSub = null;
        $("#" + this.pageId + "basicRateIdSub").val('');
        this.enteredHamaliForSaveSub = null;
        $("#" + this.pageId + "hamaliChgIdSub").val('');
        this.enteredGCChargeForSaveSub = null;
        $("#" + this.pageId + "gcChargeIdSub").val('');
        this.enteredAOCChargeForSaveSub = null;
        $("#" + this.pageId + "aocChargeIdSub").val('');
        this.enteredDDChargeForSaveSub = null;
        $("#" + this.pageId + "ddChargeIdSub").val('');
        this.enteredOtherChargeForSaveSub = null;
        $("#" + this.pageId + "otherChargeIdSub").val('');
        this.enteredRiskChargeForSaveSub = null;
        $("#" + this.pageId + "fovChargeIdSub").val('');
        this.enteredFixedBySub = null;
        $("#" + this.pageId + "fixedBySub").val('');
        this.selectedFixedOnSub = null;
        $("#" + this.pageId + "fixedOnDateSub").val('');
        this.enteredRCChargeForSaveSub = null;
        $("#" + this.pageId + "rcChargeIdSub").val('');
        this.enteredSCChargeForSaveSub = null;
        $("#" + this.pageId + "scChargeIdSub").val('');
        this.enteredDestHamaliForSaveSub = null;
        $("#" + this.pageId + "destHamaliChgIdSub").val('');

        $("#" + this.pageId + "updateModePopUpIdPartySpec").val('Increase');
        $("#" + this.pageId + "stationTypePartySpec").val('Both');
        $("#" + this.pageId + "destinationPopUpId").val('');
        $("#" + this.pageId + "sourcePopUp").val('');
        $("#" + this.pageId + "commodityListPopUpId").val('');
        $("#" + this.pageId + "searchUnitPopUp").val('rate');
        $("#" + this.pageId + "searchRatePopUp").val('fixedRate');
        $("#" + this.pageId + "ratePercentagePopUpId").val('');
        $("#" + this.pageId + "searchUnitPercPopUp").val('PLR');
        $("#" + this.pageId + "percentageRatePopUpId").val('');
        $("#" + this.pageId + "perUnitPopUpId").val('Article');
        $("#" + this.pageId + "fixedByPopUpId").val('');
        $("#" + this.pageId + "effectFromDatePopUpId").val('');
        $("#" + this.pageId + "fixedOnDatePopUpId").val('');
    }

    onCancelClick(): void {
        this.clearFieldPopUp();
        setTimeout(() => {
            this.viewPercentageFieldPopUp = false;
            this.viewRateFieldPopUp = true;
            this.viewPerUnitFieldPopUp = true;
            this.dialogRef.close("Close");
        }, 500);
    }


    /*On Key Enter press start here  */
    onKeyPressFieldForBaseChargeAmt(event) {
        if (event == 13) { $("#" + this.pageId + "hamaliChgIdPartySpec").focus(); }
    }

    onKeyPressFieldForHamaliChargeAmt(event) {
        if (event == 13) { $("#" + this.pageId + "gcChargeIdPartySpec").focus(); }
    }

    onKeyPressFieldForGCChargeAmt(event) {
        if (event == 13) { $("#" + this.pageId + "aocChargeIdPartySpec").focus(); }
    }

    onKeyPressFieldForAoc(event) {
        if (event == 13) { $("#" + this.pageId + "otherChargeIdPartySpec").focus(); }
    }

    onKeyPressFieldForOther(event) {
        if (event == 13) { $("#" + this.pageId + "fovChargeIdPartySpec").focus(); }
    }

    onKeyPressFieldForFov(event) {
        if (event == 13) { $("#" + this.pageId + "fixedByPartySpec").focus(); }
    }

    onKeyPressFieldForBaseChargeAmtSub(event) {
        if (event == 13) { $("#" + this.pageId + "hamaliChgIdSub").focus(); }
    }

    onKeyPressFieldForHamaliChargeAmtSub(event) {
        if (event == 13) { $("#" + this.pageId + "gcChargeIdSubPartySpec").focus(); }
    }

    onKeyPressFieldForGCChargeAmtSub(event) {
        if (event == 13) { $("#" + this.pageId + "aocChargeIdSubPartySpec").focus(); }
    }

    onKeyPressFieldForAocSub(event) {
        if (event == 13) { $("#" + this.pageId + "otherChargeIdSubPartySpec").focus(); }
    }

    onKeyPressFieldForOtherSub(event) {
        if (event == 13) { $("#" + this.pageId + "fovChargeIdSubPartySpec").focus(); }
    }

    onKeyPressFieldForFovSub(event) {
        if (event == 13) { $("#" + this.pageId + "fixedBySubPartySpec").focus(); }
    }
    /*On Key Enter press start here  */

    searchByRateTypeMode(searchByRateTypePartySpec: string) {
        // alert(searchByRateTypePartySpec);
        if (searchByRateTypePartySpec === 'Fixed') {
            this.viewActChgWgtField = false;
        } else if (searchByRateTypePartySpec === 'PerKg') {
            this.viewActChgWgtField = true;
        }
    }

    toggleSubStationCheckbox(event) {
        if (event.target.checked) {
            this.viewSubRate = true;
            this.subStationCheckStatus = true;
        } else {
            this.viewSubRate = false;
            this.subStationCheckStatus = false;
        }
    }
    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
        this.modelCommodityListPartySpec = e.item;
        $("#" + this.pageId + "commodityListIdPartySpec").val(this.modelCommodityListPartySpec.commodityName);
    }

    // clickListnerForSource(event) {
    //     this.agentNamePartySpecModal = event.item;
    //     this.selectedDropDownMainStation = null;
    //     this.selectedDropDownMainStation = this.agentNamePartySpecModal.mainstation
    // }


    confirmSave() {
        this.saveConsignorAdminRateMasterDetails();
    }


    saveConsignorAdminRateMasterDetails() {
        this.rateMasterDtoForSaveUpdate = new RateMasterDto();
        // this.enteredSourceForSave = null;
        this.selectedRateFor = null;
        this.enteredCommodityForSave = null;
        this.enteredDestinationForSave = null;
        this.enteredRateTypeForSave = null;
        this.enteredPerUnitForSave = null;
        this.enteredBasicRateForSave = null;
        this.enteredHamaliForSave = null;
        this.enteredGCChargeForSave = null;
        this.enteredAOCChargeForSave = null;
        this.enteredDDChargeForSave = null;
        this.enteredOtherChargeForSave = null;
        this.enteredRiskChargeForSave = null;
        this.enteredFixedBy = null;
        this.selectedEeffectDate = null;
        this.selectedFixedOn = null;
        this.enteredRCChargeForSave = null;
        this.enteredSCChargeForSave = null;
        this.enteredDestHamaliForSave = null;
        this.enteredBasicRateForSaveSub = null;
        this.enteredHamaliForSaveSub = null;
        this.enteredGCChargeForSaveSub = null;
        this.enteredAOCChargeForSaveSub = null;
        this.enteredDDChargeForSaveSub = null;
        this.enteredOtherChargeForSaveSub = null;
        this.enteredRiskChargeForSaveSub = null;
        this.enteredFixedBySub = null;
        this.selectedFixedOnSub = null;
        this.enteredRCChargeForSaveSub = null;
        this.enteredSCChargeForSaveSub = null;
        this.enteredDestHamaliForSaveSub = null;


        // this.enteredSourceForSave = $("#" + this.pageId + "agentNamePartySpec").val();
        this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
        this.enteredCommodityForSave = $("#" + this.pageId + "commodityListIdPartySpec").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateTypePartySpec").val();
        this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitIdPartySpec").val();
        this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateIdPartySpec").val();
        this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgIdPartySpec").val();
        this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeIdPartySpec").val();
        this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeIdPartySpec").val();
        this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeIdPartySpec").val();
        this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeIdPartySpec").val();
        this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeIdPartySpec").val();
        this.enteredFixedBy = $("#" + this.pageId + "fixedByPartySpec").val();
        this.selectedEeffectDate = $("#" + this.pageId + "effectFromDatePartySpec").val();
        this.selectedFixedOn = $("#" + this.pageId + "fixedOnDatePartySpec").val();
        this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
        this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
        this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
        this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSubPartySpec").val();
        this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
        this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSubPartySpec").val();
        this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSubPartySpec").val();
        this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSubPartySpec").val();
        this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSubPartySpec").val();
        this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSubPartySpec").val();
        this.enteredFixedBySub = $("#" + this.pageId + "fixedBySubPartySpec").val();
        this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSubPartySpec").val();
        console.log("this.subStationCheckStatus");
        console.log(this.subStationCheckStatus);
        this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
        this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
        this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();
        // this.rateMasterDtoForSaveUpdate.source = this.enteredSourceForSave;
        this.rateMasterDtoForSaveUpdate.rateFor = "Party";
        this.selectedPartyType = $("#" + this.pageId + "partyTypeIdSpec").val();
        if ($("#" + this.pageId + "updateModePopUpIdPartySpec").val() == "Increase") {
            this.rateMasterDtoForSaveUpdate.rptMode = "Add";
        } else if ($("#" + this.pageId + "updateModePopUpIdPartySpec").val() == "Decrease") {
            this.rateMasterDtoForSaveUpdate.rptMode = "Sub";
        }

        if ($("#" + this.pageId + "stationTypePartySpec").val() == "Mainstation") {
            this.rateMasterDtoForSaveUpdate.subStationRate = false;
            this.rateMasterDtoForSaveUpdate.searchType = "Mainstation";
        } else if ($("#" + this.pageId + "stationTypePartySpec").val() == "Substation") {
            this.rateMasterDtoForSaveUpdate.subStationRate = true;
            this.rateMasterDtoForSaveUpdate.searchType = "Substation";
        } else if ($("#" + this.pageId + "stationTypePartySpec").val() == "Both") {
            this.rateMasterDtoForSaveUpdate.searchType = "Both";
        }

        this.rateMasterDtoForSaveUpdate.consignorId = null;
        console.log("Consignorname" + this.modelConsignorName);
        // this.selectedConsignorId = this.modelConsignorName.consignorId;
        this.rateMasterDtoForSaveUpdate.consignorId = this.enteredConsignorId;

        this.rateMasterDtoForSaveUpdate.consignorName = this.modelConsignorName.consignorName;
        this.rateMasterDtoForSaveUpdate.commodityName = this.enteredCommodityForSave == null ? null : this.enteredCommodityForSave == undefined ? null : this.enteredCommodityForSave == "" ? null : this.enteredCommodityForSave;

        this.rateMasterDtoForSaveUpdate.ratePerUnit = this.enteredRateTypeForSave;
        this.rateMasterDtoForSaveUpdate.perUnit = this.enteredPerUnitForSave == null ? null : this.enteredPerUnitForSave == undefined ? null : this.enteredPerUnitForSave;
        this.rateMasterDtoForSaveUpdate.basicCharge = this.enteredBasicRateForSave == null ? 0.0 : this.enteredBasicRateForSave == undefined ? 0.0 : this.enteredBasicRateForSave == "" ? 0.0 : this.enteredBasicRateForSave;
        this.rateMasterDtoForSaveUpdate.hamali = this.enteredHamaliForSave == null ? 0.0 : this.enteredHamaliForSave == undefined ? 0.0 : this.enteredHamaliForSave == "" ? 0.0 : this.enteredHamaliForSave;
        this.rateMasterDtoForSaveUpdate.gcCharge = this.enteredGCChargeForSave == null ? 0.0 : this.enteredGCChargeForSave == undefined ? 0.0 : this.enteredGCChargeForSave == "" ? 0.0 : this.enteredGCChargeForSave;
        this.rateMasterDtoForSaveUpdate.aocChargeAmt = this.enteredAOCChargeForSave == null ? 0.0 : this.enteredAOCChargeForSave == undefined ? 0.0 : this.enteredAOCChargeForSave == "" ? 0.0 : this.enteredAOCChargeForSave;
        this.rateMasterDtoForSaveUpdate.ddChargeAmt = this.enteredDDChargeForSave == null ? 0.0 : this.enteredDDChargeForSave == undefined ? 0.0 : this.enteredDDChargeForSave == "" ? 0.0 : this.enteredDDChargeForSave;
        this.rateMasterDtoForSaveUpdate.otherChgAmt = this.enteredOtherChargeForSave == null ? 0.0 : this.enteredOtherChargeForSave == undefined ? 0.0 : this.enteredOtherChargeForSave == "" ? 0.0 : this.enteredOtherChargeForSave;
        this.rateMasterDtoForSaveUpdate.riskChargeAmt = this.enteredRiskChargeForSave == null ? 0.0 : this.enteredRiskChargeForSave == undefined ? 0.0 : this.enteredRiskChargeForSave == "" ? 0.0 : this.enteredRiskChargeForSave;
        this.rateMasterDtoForSaveUpdate.fixedBy = this.enteredFixedBy == null ? 0.0 : this.enteredFixedBy == undefined ? 0.0 : this.enteredFixedBy == "" ? 0.0 : this.enteredFixedBy;
        this.rateMasterDtoForSaveUpdate.effectiveFromDate = this.selectedEeffectDate == null ? 0.0 : this.selectedEeffectDate == undefined ? 0.0 : this.selectedEeffectDate == "" ? 0.0 : this.selectedEeffectDate;
        this.rateMasterDtoForSaveUpdate.fixedDate = this.selectedFixedOn == null ? 0.0 : this.selectedFixedOn == undefined ? 0.0 : this.selectedFixedOn == "" ? 0.0 : this.selectedFixedOn;
        this.rateMasterDtoForSaveUpdate.receiptCharges = this.enteredRCChargeForSave == null ? 0.0 : this.enteredRCChargeForSave == undefined ? 0.0 : this.enteredRCChargeForSave == "" ? 0.0 : this.enteredRCChargeForSave;
        this.rateMasterDtoForSaveUpdate.serviceCharges = this.enteredSCChargeForSave == null ? 0.0 : this.enteredSCChargeForSave == undefined ? 0.0 : this.enteredSCChargeForSave == "" ? 0.0 : this.enteredSCChargeForSave;
        this.rateMasterDtoForSaveUpdate.destHamali = this.enteredDestHamaliForSave == null ? 0.0 : this.enteredDestHamaliForSave == undefined ? 0.0 : this.enteredDestHamaliForSave == "" ? 0.0 : this.enteredDestHamaliForSave;
        this.rateMasterDtoForSaveUpdate.basicChargeSub = this.enteredBasicRateForSaveSub == null ? 0.0 : this.enteredBasicRateForSaveSub == undefined ? 0.0 : this.enteredBasicRateForSaveSub == "" ? 0.0 : this.enteredBasicRateForSaveSub;
        this.rateMasterDtoForSaveUpdate.hamaliSub = this.enteredHamaliForSaveSub == null ? 0.0 : this.enteredHamaliForSaveSub == undefined ? 0.0 : this.enteredHamaliForSaveSub == "" ? 0.0 : this.enteredHamaliForSaveSub;
        this.rateMasterDtoForSaveUpdate.gcChargeSub = this.enteredGCChargeForSaveSub == null ? 0.0 : this.enteredGCChargeForSaveSub == undefined ? 0.0 : this.enteredGCChargeForSaveSub == "" ? 0.0 : this.enteredGCChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.aocChargeAmtSub = this.enteredAOCChargeForSaveSub == null ? 0.0 : this.enteredAOCChargeForSaveSub == undefined ? 0.0 : this.enteredAOCChargeForSaveSub == "" ? 0.0 : this.enteredAOCChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.ddChargeAmtSub = this.enteredDDChargeForSaveSub == null ? 0.0 : this.enteredDDChargeForSaveSub == undefined ? 0.0 : this.enteredDDChargeForSaveSub == "" ? 0.0 : this.enteredDDChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.otherChgAmtSub = this.enteredOtherChargeForSaveSub == null ? 0.0 : this.enteredOtherChargeForSaveSub == undefined ? 0.0 : this.enteredOtherChargeForSaveSub == "" ? 0.0 : this.enteredOtherChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.riskChargeAmtSub = this.enteredRiskChargeForSaveSub == null ? 0.0 : this.enteredRiskChargeForSaveSub == undefined ? 0.0 : this.enteredRiskChargeForSaveSub == "" ? 0.0 : this.enteredRiskChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.receiptChargesSub = this.enteredRCChargeForSaveSub == null ? 0.0 : this.enteredRCChargeForSaveSub == undefined ? 0.0 : this.enteredRCChargeForSaveSub == "" ? 0.0 : this.enteredRCChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.serviceChargesSub = this.enteredSCChargeForSaveSub == null ? 0.0 : this.enteredSCChargeForSaveSub == undefined ? 0.0 : this.enteredSCChargeForSaveSub == "" ? 0.0 : this.enteredSCChargeForSaveSub;
        this.rateMasterDtoForSaveUpdate.destHamaliSub = this.enteredDestHamaliForSaveSub == null ? 0.0 : this.enteredDestHamaliForSaveSub == undefined ? 0.0 : this.enteredDestHamaliForSaveSub == "" ? 0.0 : this.enteredDestHamaliForSaveSub;
        this.rateMasterDtoForSaveUpdate.fixedBySub = this.enteredFixedBySub == null ? 0.0 : this.enteredFixedBySub == undefined ? 0.0 : this.enteredFixedBySub == "" ? 0.0 : this.enteredFixedBySub;
        this.rateMasterDtoForSaveUpdate.fixedDateSub = this.selectedFixedOnSub == null ? 0.0 : this.selectedFixedOnSub == undefined ? 0.0 : this.selectedFixedOnSub == "" ? 0.0 : this.selectedFixedOnSub;
        this.rateMasterDtoForSaveUpdate.userName = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoForSaveUpdate.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoForSaveUpdate.mainStation = this.selectedDropDownMainStation;
        this.rateMasterDtoForSaveUpdate.mode = this.selectedPartyType;
        //Changed for passing destination in list 14/05/2024 by imran
        // this.rateMasterDtoForSaveUpdate.destination = this.enteredDestinationForSave;
        this.listDestination = [];
        if (this.destinationType == 'all' && this.destinationDataList != null && this.destinationDataList != undefined &&
            this.destinationDataList.length > 0) {
            for (let i = 0; i < this.destinationDataList.length; i++) {
                this.listDestination.push(this.destinationDataList[i].godownName);
            }
        } else if (this.destinationType == 'specific' && this.lrDtoListDestinationChecked != null && this.lrDtoListDestinationChecked != undefined &&
            this.lrDtoListDestinationChecked.length > 0) {
            for (let i = 0; i < this.lrDtoListDestinationChecked.length; i++) {
                this.listDestination.push(this.lrDtoListDestinationChecked[i].godownName);
            }
        } else {
            this.listDestination.push(this.enteredDestinationForSave);
        }
        // if (this.lrDtoListDestinationChecked != null && this.lrDtoListDestinationChecked != undefined &&
        //     this.lrDtoListDestinationChecked.length > 0) {
        //     for (let i = 0; i < this.lrDtoListDestinationChecked.length; i++) {
        //         this.listDestination.push(this.lrDtoListDestinationChecked[i].godownName);
        //     }
        // } else {
        //     this.listDestination.push(this.enteredDestinationForSave);
        // }

        this.rateMasterDtoForSaveUpdate.list = this.listDestination;

        this.listSource = [];


        if (this.sourceType == 'all' && this.agentDetailsOptionsSet != null && this.agentDetailsOptionsSet != undefined &&
            this.agentDetailsOptionsSet.length > 0) {
            for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                this.listSource.push(this.agentDetailsOptionsSet[i].source);
            }
        } else if (this.sourceType == 'specific' && this.lrDtoListSourceChecked != null && this.lrDtoListSourceChecked != undefined &&
            this.lrDtoListSourceChecked.length > 0) {
            for (let i = 0; i < this.lrDtoListSourceChecked.length; i++) {
                this.listSource.push(this.lrDtoListSourceChecked[i].source);
            }
        }

        this.rateMasterDtoForSaveUpdate.listOfSource = this.listSource;
        this.listCommodity = [];
        if (this.commodityType == 'specific' && this.lrDtoLisCommodityChecked != null && this.lrDtoLisCommodityChecked != undefined &&
            this.lrDtoLisCommodityChecked.length > 0) {
            for (let i = 0; i < this.lrDtoLisCommodityChecked.length; i++) {
                this.listCommodity.push(this.lrDtoLisCommodityChecked[i].commodityName);
            }
        } else {
            this.listCommodity.push('All');
        }
        this.rateMasterDtoForSaveUpdate.listOfCommodity = this.listCommodity;
        console.log(this.rateMasterDtoForSaveUpdate);
        this.showSpinnerForAction = true;

        this.consignorService.updateConsignorAdminRateAllSpecficParty(this.rateMasterDtoForSaveUpdate).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "success") {
                    swal("Success", " Consignor Rate updated successfully", "success");
                    this.clearFieldPopUp();
                } else {
                    swal("Error", "Problem occurred while updating Consignor Rate details", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating Consignor Rate details", "error");
            }, () => console.log('done');

    }

    validateMainCommoditySelection() {
        this.isMainCommodityValid = false;
        this.enteredCommodityForSave = $("#" + this.pageId + "commodityListIdPartySpec").val();
        for (let i = 0; i < this.commodityListTA.length; i++) {
            if (this.commodityListTA[i].commodityName == this.enteredCommodityForSave) {
                this.isMainCommodityValid = true;
            }
        }
        if (this.isMainCommodityValid == false) {
            swal({
                title: "Not Allowed",
                text: "Selected Commodity " + this.enteredCommodityForSave + " is not valid, please check and select again!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    rowCheckBoxForDestination(e, destinationData, index) {
        if (e.currentTarget.checked) {
            this.newAttributeSetDestinationList = {};
            this.newAttributeSetDestinationList.godownName = destinationData.godownName;
            if (this.lrDtoListDestinationChecked == null ||
                this.lrDtoListDestinationChecked == undefined) {
                this.lrDtoListDestinationChecked = [];
            }
            if (this.lrDtoListDestinationChecked.length == 0) {
                this.lrDtoListDestinationChecked.push(this.newAttributeSetDestinationList);
            } else {
                const result = this.lrDtoListDestinationChecked.filter(lrDtoListSourceData =>
                    lrDtoListSourceData.godownName === this.newAttributeSetDestinationList.godownName);
                if (result.length > 0) {
                    swal("Duplicate Entry", "" + this.newAttributeSetDestinationList.godownName + " Already Exist", "warning");
                } else {
                    this.lrDtoListDestinationChecked.push(this.newAttributeSetDestinationList);
                }
            }
        } else {
            this.lrDtoListDestinationChecked.splice(index, 1);
        }
        console.log(this.lrDtoListDestinationChecked);
    }

    //Click Listener For Source main Station
    clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent) {
        this.modelMainBookingSourcePartySpec = e.item;
        $("#" + this.pageId + "mainBookingSourceIdPartySpec").val(this.modelMainBookingSourcePartySpec.mainBookStations);
        this.getAgentDetailList(this.modelMainBookingSourcePartySpec.mainBookStations);
    }

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                this.mainBookingSourceTA = [];
                this.agentDtoOptionSourceStation = [];
                this.agentDtoOptionSourceStation = response;
                for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
            },
            () => console.log('done');
    }

    // Asrar Jr

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.selectedPartyType = $("#" + this.pageId + "partyTypeIdSpec").val();
        // if (this.selectedPartyType == "Consignee") {
        this.modelDestinationPartySpec = e.item;
        this.selectedDestinationForConsigneeName = this.modelDestinationPartySpec.godownName;
        $("#" + this.pageId + "destinationId").val(this.modelDestinationPartySpec.godownName);
        this.getConsigneeDetails();
        // }
    }

    getConsigneeDetailsList() {
        this.partyDtoConsigneeName = new PartyMasterDto();
        //this.selectedDestinationForConsigneeName = $("#" + this.pageId + "destinationId").val();
        this.partyDtoConsigneeName.companyId = this.userDataDtoReturnSession.companyId;
        this.partyDtoConsigneeName.office = this.selectedDestinationForConsigneeName;
        this.partyDtoConsigneeName.mode = "specific";
    }

    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyDtoConsigneeName).subscribe(
            (response) => {
                this.consigneeNameOptions = [];
                this.consigneeNameTA = [];
                this.consigneeNameOptions = response;
                this.partyDtoConsigneeNameAll = new PartyMasterDto();
                this.partyDtoConsigneeNameAll.consigneeName = "ALL";
                this.consigneeNameTA.push(this.partyDtoConsigneeNameAll);
                for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                    this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    }

    clickListnerForConsigneeNameList(event) {
        this.modelConsignee = null;
        this.modelConsignee = event.item;
        this.modelConsigneeName = null;
        this.modelConsigneeName = this.modelConsignee.consigneeName;
        this.enteredConsigneeId = 0;
        this.enteredConsigneeId = this.modelConsignee.consigneeId;
    }

    // partyTypeMode(partyTypeIdSpec: string) {
    //     if (partyTypeIdSpec == 'Consignee') {
    //         this.viewConsigneeName = true;
    //         this.viewConsignorName = false;
    //         this.viewConsignorIndex = false;
    //         this.viewConsignorDestinationRadio = false;
    //         this.viewSourcesPartySpec = true;
    //         this.viewSourcesPartyMulti = true;
    //         //No need to do empty just need to clear checkbox by imran on 14052024
    //         //   this.destinationDataList = [];

    //         this.viewSpecificDestination = true;

    //         this.viewConsignorAllDestination = false;
    //         this.viewSpecificSource = false;

    //     } else if (partyTypeIdSpec == 'Consignor') {
    //         this.viewConsigneeName = false;
    //         this.viewConsignorName = true;
    //         this.viewConsignorDestinationRadio = true;
    //         this.viewSourcesPartySpec = true;
    //         this.viewConsignorIndex = true;

    //         this.viewSpecificDestination = true;
    //         this.viewSourcesPartyMulti = false;
    //         this.viewSpecificSource = true;
    //         if (this.destinationType == 'all') {
    //             this.viewConsignorAllDestination = true;

    //         } else {
    //             this.viewConsignorAllDestination = false;
    //         }
    //     }
    // }

    rowCheckBoxForSource(e, agentDetails, index) {
        if (e.currentTarget.checked) {
            this.newAttributeSetSourceList = {};
            this.newAttributeSetSourceList.source = agentDetails.source;
            if (this.lrDtoListSourceChecked == null ||
                this.lrDtoListSourceChecked == undefined) {
                this.lrDtoListSourceChecked = [];
            }
            if (this.lrDtoListSourceChecked.length == 0) {
                this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
            } else {
                const result = this.lrDtoListSourceChecked.filter(lrDtoListSourceDataPartySpec =>
                    lrDtoListSourceDataPartySpec.source === this.newAttributeSetSourceList.source);
                if (result.length > 0) {
                    swal("Duplicate Entry", "" + this.newAttributeSetSourceList.godownName + " Already Exist", "warning");
                } else {
                    this.lrDtoListSourceChecked.push(this.newAttributeSetSourceList);
                }
            }
        } else {
            this.lrDtoListSourceChecked.splice(index, 1);
        }
        console.log(this.lrDtoListSourceChecked);
    }



    setIndex() {
        for (let i = 0; i < this.indexOptions.length; i++) {
            this.indexTA.push(this.indexOptions[i].label);
            this.indexPartySearchTA.push(this.indexOptions[i].label);
        }
    }
    getConsignorDetailsList() {
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
        // this.partyMasterDtoForconsignor.mainStation = this.selectedSorceDropDown;
        // this.partyMasterDtoForconsignor.mainStation = this.selectedDropDownMainStation;

        if (this.sourceView == false) {
            this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            console.log("mainsource" + this.modelMainBookingSourcePartySpec);
            this.partyMasterDtoForconsignor.mainStation = this.modelMainBookingSourcePartySpec.mainBookStations;
        }

        this.validateIndexValue = null;

        this.validateIndexValue = $("#" + this.pageId + "indexId").val();
        console.log("this.validateIndexValue>>>>>>" + this.validateIndexValue)
        if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
            this.validateIndexValue == "") {
            this.validateIndexValue = "A";
            this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
        } else {
            this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
        }
        console.log("1111" + this.partyMasterDtoForconsignor);
        this.getConsignorDetails();
    }

    getConsignorDetails = () => {
        this.showSpinnerForConsignorName = true;
        this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                console.log(response);

                if (response.length == 0) {
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                } else {
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');

                    this.consignorNameOptions = response;
                    // this.consignorNamePartyDtoAllOption.consignorName = "All";
                    // this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                }
                this.showSpinnerForConsignorName = false;
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForConsignorName = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    destinationTypeSelected(selectedType: string) {

        this.newAttributeSetDestinationList = {};
        this.lrDtoListDestinationChecked = [];
        $("#" + this.pageId + "#selectedDestination").prop("checked", false);
        console.log(this.newAttributeSetDestinationList);
        console.log(this.lrDtoListDestinationChecked);
        this.destinationType = selectedType;
        if (selectedType == 'all') {

            this.viewSpecificDestination = false;

        } else {


            this.viewSpecificDestination = true;
        }

    }
    sourceTypeSelected(selectedType: string) {
        this.newAttributeSetSourceList = {};
        this.lrDtoListSourceChecked = [];
        $("#" + this.pageId + "#selectedSource").prop("checked", false);
        this.sourceType = selectedType;
        if (selectedType == 'all') {

            this.viewSpecificSource = false;

        } else {


            this.viewSpecificSource = true;
        }

    }
    indexDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelIndex = e.item;
        console.log('indexDropDownListner');
        console.log(this.modelIndex);
        $("#" + this.pageId + "indexId").val(this.modelIndex);
        if (this.modelIndex == 'ALL') {
            this.consignorNameOptions = [];
            this.consignorNameTA = [];
            this.consignorNamePartyDtoAllOption = new PartyMasterDto();
            $("#" + this.pageId + "consignorName").val('');
            this.consignorNamePartyDtoAllOption.consignorName = "ALL";
            this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
        } else {
            this.getConsignorDetailsList();
        }
    }

    indexDropDownListnerPartySearch(e: NgbTypeaheadSelectItemEvent) {
        this.modelIndexPartySearch = e.item;
        $("#" + this.pageId + "indexIdPartySearch").val(this.modelIndexPartySearch);
        this.getConsignorDetailsListPartySearch();
    }
    getConsignorDetailsListPartySearch() {
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
        // this.partyMasterDtoForconsignor.mainStation = this.selectedSorceDropDown;
        // this.partyMasterDtoForconsignor.mainStation = this.selectedDropDownMainStation;

        if (this.sourceView == false) {
            this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            console.log("mainsource2" + this.modelMainBookingSourcePartySpec)
            this.partyMasterDtoForconsignor.mainStation = this.modelMainBookingSourcePartySpec.mainBookStations;
        }

        this.validateIndexValue = null;
        this.validateIndexValue = $("#cnarmindexIdPartySearch").val();
        if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
            this.validateIndexValue == "") {
            this.validateIndexValue = "A";
            this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
        } else {
            this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
        }
        this.getConsignorDetailsPartySearch();
    }

    getConsignorDetailsPartySearch = () => {
        this.showSpinnerForConsignorName = true;
        this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.consignorNameOptions = [];
                    this.consignorNamePartySearchTA = [];
                    $("#" + this.pageId + "consignorNamePartySearch").val('');
                } else {
                    this.consignorNameOptions = [];
                    this.consignorNamePartySearchTA = [];
                    this.consignorNamePartyDtoAllOption = new PartyMasterDto();
                    $("#" + this.pageId + "consignorNamePartySearch").val('');

                    this.consignorNameOptions = response;
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNamePartySearchTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNamePartySearchTA.push(this.consignorNameOptions[i]);
                    }
                }
                this.showSpinnerForConsignorName = false;
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForConsignorName = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };
    clickListnerForConsignor(event) {
        this.modelConsignor = null;
        this.modelConsignor = event.item;
        this.modelConsignorName = null;
        this.modelConsignorName = this.modelConsignor.consignor;
        this.enteredConsignorId = 0;
        this.enteredConsignorId = this.modelConsignor.consignorId;
    }


    rowCheckBoxForCommodity(e, commoditynData, index) {
        if (e.currentTarget.checked) {
            this.newAttributeSetCommodityList = {};
            this.newAttributeSetCommodityList.commodityName = commoditynData.commodityName;
            if (this.lrDtoLisCommodityChecked == null ||
                this.lrDtoLisCommodityChecked == undefined) {
                this.lrDtoLisCommodityChecked = [];
            }
            if (this.lrDtoLisCommodityChecked.length == 0) {
                this.lrDtoLisCommodityChecked.push(this.newAttributeSetCommodityList);
            } else {
                const result = this.lrDtoLisCommodityChecked.filter(commodityListData =>
                    commodityListData.commodityName === this.newAttributeSetCommodityList.commodityName);
                if (result.length > 0) {
                    swal("Duplicate Entry", "" + this.newAttributeSetCommodityList.commodityName + " Already Exist", "warning");
                } else {
                    this.lrDtoLisCommodityChecked.push(this.newAttributeSetCommodityList);
                }
            }
        } else {
            this.lrDtoLisCommodityChecked.splice(index, 1);
        }
        console.log(this.lrDtoLisCommodityChecked);
    }
    commodityTypeSelected(selectedType: string) {
        this.newAttributeSetCommodityList = {};
        this.lrDtoLisCommodityChecked = [];
        $("#" + this.pageId + "#selectedDestination").prop("checked", false);
        this.commodityType = selectedType;
        if (selectedType == 'all') {

            this.viewSpecificCommodity = false;

            $("#" + this.pageId + "#selectedCommodity").prop("checked", false);

        } else {


            this.viewSpecificCommodity = true;

        }

    }


}


