import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MemoService } from 'src/app/dataService/memo-service';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { UserDataDto } from "src/app/dto/UserData-dto";

@Component({
    selector: 'app-cashmemo-transfer',
    templateUrl: './cashmemo-transfer.component.html',
    styleUrls: ['./cashmemo-transfer.component.css']
})
export class CashmemoTransferComponent implements OnInit {
    cashmemoTransferDataList: any;
    cashMemoDtoTable: CashMemoDto = new CashMemoDto();
    cashDtoReconfigureWhileTransfer: CashMemoDto = new CashMemoDto();
    cashmemoDetailWhileTransfer: any;
    cashDtoDetailWhileTransfer: CashMemoDto = new CashMemoDto();
    userDtoUpdateMemo: UserDataDto = new UserDataDto();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerCashmemoTransfer: Subject<any> = new Subject();
    dtOptionsCashmemoTransfer: any;
    manualView = true;
    scanView = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    stationRights = "cashmemo trf specific station";
    editBtnRights = "cashmemo trf show edit btn "
    editBtnView = false;
    officeRights = "cashmemo trf specific office";
    list: any;
    listOfMemos: Array<any> = [];
    cashmemoDetailWhileTransferRet: any;
    rerenderofTaable = false;
    pageId = "castc";
    showSpinnerForAction = false;

    constructor(private memoService: MemoService, private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.cashmemoTransferDataTable();
            this.getCashMemoUnAssgDetail();
            if (this.editBtnRights == "cashmemo trf show edit btn") {
                this.editBtnView = true;
            }
        }
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
                this.rerenderofTaable = true;
                this.dtTriggerCashmemoTransfer.next();
            });
        });
    }

    cashmemoTransferDataTable() {
        this.dtOptionsCashmemoTransfer = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }
    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this.dtTriggerCashmemoTransfer.unsubscribe();
    }

    ngAfterViewInit(): void {
    }

    getMethodForCashmemoTableDetailList() {
        this.cashMemoDtoTable.companyId = this.userDataDtoReturnSession.companyId;
        // logic changed, will get the billing memos except cash and DD @Dated :
        // 07/12/2017
        //        if ( this.stationRights = "cashmemo trf specific station" ) {
        //            this.cashMemoDtoTable.mode = "transferRevLogic";
        //        } else {
        //            this.cashMemoDtoTable.mode = "transfer";
        //        }

        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.cashMemoDtoTable.mode = "transferRevLogic";
        } else {
            this.cashMemoDtoTable.mode = "transfer";
        }
        this.cashMemoDtoTable.destination = this.userDataDtoReturnSession.mainStation;
        this.cashMemoDtoTable.godown = this.userDataDtoReturnSession.office;
    }

    getCashMemoUnAssgDetail() {
        this.showSpinnerForAction = true;
        this.getMethodForCashmemoTableDetailList();
        this.memoService.getCashMemoUnAssgDetailService(this.cashMemoDtoTable).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.cashmemoTransferDataList = response;
                    if (this.rerenderofTaable == false) {
                        this.dtTriggerCashmemoTransfer.next();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Nature Of Pack Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    transferBtn() {
        swal({
            title: "Billing To Collection",
            text: "Are you sure you want to Transfer",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.gridReconfigureWhileTransfer();
            } else {

            }
        })
    }

    getMethodForgridReconfigureWhileTransfer() {
        this.cashDtoReconfigureWhileTransfer.companyId = this.userDataDtoReturnSession.companyId;
        //        if ( this.stationRights = "cashmemo trf specific station" ) {
        //                    this.cashDtoReconfigureWhileTransfer.mode = "transferRevLogic";
        //                } else {
        //                    this.cashDtoReconfigureWhileTransfer.mode = "transfer";
        //                }

        // logic changed, will get the billing memos except cash and DD @Dated :
        // 07/12/2017
        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.cashDtoReconfigureWhileTransfer.mode = "transferRevLogic";
        } else {
            this.cashDtoReconfigureWhileTransfer.mode = "transfer";
        }
        this.cashDtoReconfigureWhileTransfer.destination = this.userDataDtoReturnSession.mainStation;
        this.cashDtoReconfigureWhileTransfer.godown = this.userDataDtoReturnSession.office;
    }

    gridReconfigureWhileTransfer() {
        this.showSpinnerForAction = true;
        this.getMethodForgridReconfigureWhileTransfer();
        this.memoService.getCashMemoUnAssgDetailService(this.cashDtoReconfigureWhileTransfer).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "No Records",
                        text: "No Memo Found To Transfer",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                } else {
                    this.cashmemoDetailWhileTransfer = response;
                    this.userDtoUpdateMemo.listOfResponse = this.cashmemoDetailWhileTransfer;
                    this.userDtoUpdateMemo.office = this.userDataDtoReturnSession.office;
                    if (this.userDataDtoReturnSession.office == "Binnymills") {
                        this.userDtoUpdateMemo.mode = "transferToGodown";
                    } else {
                        this.userDtoUpdateMemo.mode = "transfer";
                    }
                    this.userDtoUpdateMemo.companyId = this.userDataDtoReturnSession.companyId;
                    this.userDtoUpdateMemo.updatedBy = this.userDataDtoReturnSession.userId;
                    this.updateCashMemo(this.userDtoUpdateMemo);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    updateCashMemo(userDtoUpdateMemo) {
        this.showSpinnerForAction = true;
        this.memoService.updateMemoDetailService(this.userDtoUpdateMemo).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Server Error",
                        text: "While Updating Memo Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.cashmemoDetailWhileTransferRet = response;
                    if (this.cashmemoDetailWhileTransferRet.result == 'success') {
                        swal({
                            title: "Success",
                            text: "Cashmemo Transfered Successfully",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rerender();
                        this.getCashMemoUnAssgDetail();
                    } else {
                        swal({
                            title: "Server Error",
                            text: "While Updating Memo Details",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Updating Memo Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}


