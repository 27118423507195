import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";

@Component({
	selector: 'app-discontinuous-details-report',
	templateUrl: './discontinuous-details-report.component.html',
	styleUrls: ['./discontinuous-details-report.component.css']
})
export class DiscontinuousDetailsReportComponent implements OnInit {
	gettingDataFrmServiceFrDiscontinuousDetailsReportTable: any;
	discontinuousDetailsReportDataList: any;
	//summaryTable:any;
	loadingIndicator = true;
	//for the select option with filter starts
	control = new FormControl();

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerDiscontinuousDetailsReport: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsDiscontinuousDetailsReport: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	consigneeNameTATempForDest: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any;
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;
	viewDestination = false;
	destView = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	office: any;
	//// Main Src
	agentDtoForMainSrc: AgentDetailsDto = new AgentDetailsDto();
	agentDtoOptionsForMainSrc: AgentDetailsDto[];

	public modelForMainSrc: any;
	mainSrcTA: Array<AgentDetailsDto> = [];
	focusMainSrcTA$ = new Subject<string>();
	searchMainSrcAt = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMainSrcTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.mainSrcTA
				: this.mainSrcTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMainSrc = (x: { mainBookStations: string }) => x.mainBookStations;
	///
	selectedSource: any;
	selectedConsigneeName: any;
	lrDtoDiscontinousDetails: LRDto = new LRDto();
	selectedDays: any;
	selecDestinationForTemp: any;
	showDest = 'DiscontPartyRpt DestView';

	//For Custom Print
	viewCustomPrintV1: any;

	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;

	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	todayDate: string;
	pageId = "dsdrc";


	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService, private consigneeService: ConsigneeService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
			this.getConsigneeDetails(false);
			this.getMainSrcDetails();
			/*if (this.showDest=='DiscontPartyRpt DestView') {
			this.destView=true;         
			}*/
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"DiscontPartyRpt DestView") {
						this.destView = true;
					}

				}
			}
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnDestroy(): void {
		this.dtTriggerDiscontinuousDetailsReport.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerDiscontinuousDetailsReport.next();
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
		var mainStation = this.office;
		console.log(mainStation);
		var destVisible = false;
		if (this.destView == true) {
			destVisible = true;
		} else {
			destVisible = false;
		}
		this.dtOptionsDiscontinuousDetailsReport = {
			dom: 'Bfrtip',
			buttons: [

				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = null;
						if (destVisible == true) {
							returnSummaryExcel = "Discontinue Parties Details Report - " + $("#dsdrcdestination").val() + " Date : " + todayDate;
						} else {
							returnSummaryExcel = "Discontinue Parties Details Report - " + mainStation + " Date : " + todayDate;
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": false,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};



				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
			}

		}

	}


	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
						$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					$("#" + this.pageId + "consigneeName").val('All');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					this.selecDestinationForTemp = $("#" + this.pageId + "destination").val();
					if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
						this.consigneeNameTATempForDest = this.consigneeNameTA;
					}
					this.consigneeNameTATemp = this.consigneeNameTA;

					$("#" + this.pageId + "consigneeName").val('All');

					console.log(this.consigneeNameTA, this.consigneeNameTATemp);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.getConsigneeDetails(true);

	}
	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}

	getMainSrcMethod() {
		this.agentDtoForMainSrc = new AgentDetailsDto();
		this.agentDtoForMainSrc.companyId = this.userDataDtoReturnSession.companyId;
	}
	getMainSrcDetails() {
		this.getMainSrcMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getMainBookingStationsDetails(this.agentDtoForMainSrc).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					this.agentDtoOptionsForMainSrc = [];

					this.mainSrcTA = [];
					if (response.length > 0) {
						this.agentDtoOptionsForMainSrc = response;
						console.log(this.agentDtoOptionsForMainSrc);
						this.mainSrcTA = [];
						for (let i = 0; i < this.agentDtoOptionsForMainSrc.length; i++) {
							this.mainSrcTA.push(this.agentDtoOptionsForMainSrc[i]);
						}
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
			},
			() => console.log('done');

	};
	searchMethod() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedDays = $("#" + this.pageId + "periodDay").val();
		console.log(this.selectedSource);
		if (this.destView == true) {
			if (this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined) {
				swal({
					title: "Warning",
					text: "Please Select Destination !",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		}

		if (this.selectedSource == null || this.selectedSource == '' || this.selectedSource == undefined || this.selectedDays == null || this.selectedDays == '' || this.selectedDays == undefined) {
			swal({
				title: "Warning",
				text: "Please Select the Mandetory Fields !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {

			this.lrDtoDiscontinousDetails = new LRDto();
			if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
				this.lrDtoDiscontinousDetails.consigneeId = this.selectedConsigneeId
			} else {
				this.lrDtoDiscontinousDetails.consigneeId = 0;
			}
			this.lrDtoDiscontinousDetails.companyId = this.userDataDtoReturnSession.companyId;
			this.lrDtoDiscontinousDetails.source = this.selectedSource;
			this.lrDtoDiscontinousDetails.periodRange = this.selectedDays;
			if (this.destView == true) {
				this.lrDtoDiscontinousDetails.destination = this.selectedDestination;
			} else {
				this.lrDtoDiscontinousDetails.destination = this.userDataDtoReturnSession.mainStation;
			}
			console.log(this.lrDtoDiscontinousDetails);
			this.searchDiscontinousDetails();
		}


	}

	searchDiscontinousDetails = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getDiscontPartyRptDtls(this.lrDtoDiscontinousDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "discontinuousDetailsReportId").DataTable().destroy();
				this.discontinuousDetailsReportDataList = [];

				//this.callServForTableDestroy();
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.discontinuousDetailsReportDataList = response;
					console.log(this.discontinuousDetailsReportDataList);
				}
				this.dtTriggerDiscontinuousDetailsReport.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Party Details View", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		$("#" + this.pageId + "discontinuousDetailsReportId").DataTable().destroy();
		this.discontinuousDetailsReportDataList = [];
		this.dtTriggerDiscontinuousDetailsReport.next();
		this.lrDtoDiscontinousDetails = new LRDto();
		this.showSpinnerForAction = false;
		this.selectedSource = '';
		$("#" + this.pageId + "source").val('');
		this.modelForMainSrc = '';
		this.selectedDestination = '';
		this.selectedConsigneeName = '';
		this.selectedDays = '';
		$("#" + this.pageId + "periodDay").val('');
		this.selectedConsigneeId == 0;
		this.modelDestinationFotGet = '';
		if (this.destView == true) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
			this.consigneeNameTA = [];
			this.consigneeNameTA = this.consigneeNameTATempForDest;
			$("#" + this.pageId + "consigneeName").val('All');
		} else {
			this.consigneeNameTA = this.consigneeNameTATemp;
			this.viewDestination = false;
			$("#" + this.pageId + "consigneeName").val('All');
		}


	}
	customPrintDisConDetailsRpt() {
		if (this.discontinuousDetailsReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {

			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "Address", "Mobile No", "Tin No", "Contact Person", "Book Date", "Source"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [25, 20, 15, 10, 10, 10, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];


			for (let i = 0; i < this.discontinuousDetailsReportDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.discontinuousDetailsReportDataList[i].consigneeName, this.discontinuousDetailsReportDataList[i].address,
				this.discontinuousDetailsReportDataList[i].mobileNum, "null", this.discontinuousDetailsReportDataList[i].contactPerson,
				this.discontinuousDetailsReportDataList[i].bookingDateStr, this.discontinuousDetailsReportDataList[i].source,];
				/////////

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);



				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.discontinuousDetailsReportDataList.length, "", "", "", "", "", ""];
			}


			//heading logics For Date


			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];

			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Address"];


			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoDiscontinousDetails.destination];


			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			//this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
			//for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
			//this.cashMemoDtoForCustomPrint = new CashMemoDto();
			//this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
			//this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			//	}

			//this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
			//for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
			//	this.cashMemoDtoForCustomPrint = new CashMemoDto();
			//	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
			//	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
			//}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "DisContinous Parties Details Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}



}