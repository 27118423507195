<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Sales Tax Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}fromDate">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input
															id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd"
															name="toDates" ngbDatepicker #toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											
											




										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsSalesTaxReport" [dtTrigger]="dtTriggerSalesTaxReport">

								<thead>
									<tr>
										<th>CM#</th>
										<th>LR No</th>
										<th>Consignee</th>
										<th>Articles</th>
										<th>Freight</th>
										<th>GD</th>
										<th>RC</th>
										<th>Discount</th>
										<th>Dem</th>
										<th>SC</th>
										<th>Total</th>
										<th>G.Total</th>
										<th>Memo Type</th>
										<th>DD</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let salesTaxReportData of salesTaxReportDataList ">
										<td>{{ salesTaxReportData.cm }}</td>
										<td>{{ salesTaxReportData.lrNo }}</td>
										<td>{{ salesTaxReportData.consignee }}</td>
										<td>{{ salesTaxReportData.articles }}</td>
										<td>{{ salesTaxReportData.freight }}</td>
										<td>{{ salesTaxReportData.gd }}</td>
										<td>{{ salesTaxReportData.rc }}</td>
										<td>{{ salesTaxReportData.discount }}</td>
										<td>{{ salesTaxReportData.dem }}</td>
										<td>{{ salesTaxReportData.sc }}</td>
										<td>{{ salesTaxReportData.total }}</td>
										<td>{{ salesTaxReportData.gTotal }}</td>
										<td>{{ salesTaxReportData.memoType }}</td>
										<td>{{ salesTaxReportData.dd }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>