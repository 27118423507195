<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}showStatementRptLrDetails">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Booking Agent Statement Report LR Details For - {{validateStatementNo}} - {{validateDestination}}
                    </h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-9">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                            <!-- <div class="col-md-4">
                                <button type="submit" class="dt-button"
                                    style="margin-left: 74%;"
                                    (click)="customPrintGcAmtCollected()"
                                    id="{{pageId}}printAllBtn">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                
                            </div> -->
                        <div class="box-body">
                            <br>
                            <!-- <button style='float: right;' class="button dt-button" id="{{pageId}}printStmtRptLrBtnId"
								(click)="clickListnerForPrintStmtRptLr();">
								<i class="fas fa-print"> &nbsp;Print</i></button>
							<button type="submit" class="dt-button" style="margin-left: 74%;"
								(click)="customPrintBkgAgentStmtLr();" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print LR Details</i></span>
							</button> -->
                            <table datatable id="{{pageId}}statementRptLrDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrDetails" [dtTrigger]="dtTriggerStatementReportLrDetails">
                                <thead>
                                    <tr>
                                        <th>LR No</th>
                                        <th>Center</th>
                                        <th>Destination</th>
                                        <th>Commodity</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                        <th>To Pay Amt</th>
                                        <th>Paid Amt</th>
                                        <th>LC</th>
                                        <th>BC</th>
                                        <th>Booking Date</th>
                                        <th>Recevied Date</th>
                                        <th>Difference</th>
                                        <th>Incentive</th>
                                        <th>Penalty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrDetailsData of statementReportLrDetailsDataList ">
                                        <td>{{ statementReportLrDetailsData.lrNumber }}</td>
                                        <td>{{ statementReportLrDetailsData.source }}</td>
                                        <td>{{ statementReportLrDetailsData.destination }}</td>
                                        <td>{{ statementReportLrDetailsData.description }}</td>
                                        <td>{{ statementReportLrDetailsData.actualWeight }}</td>
                                        <td>{{ statementReportLrDetailsData.chargedWeight }}</td>
                                        <td>{{ statementReportLrDetailsData.toPay }}</td>
                                        <td>{{ statementReportLrDetailsData.paid }}</td>
                                        <td>{{ statementReportLrDetailsData.lcChg }}</td>
                                        <td>{{ statementReportLrDetailsData.bcChg }}</td>
                                        <td>{{ statementReportLrDetailsData.bookingDateStr }}</td>
                                        <td>{{ statementReportLrDetailsData.enteredDateStr }}</td>
                                        <td>{{ statementReportLrDetailsData.dispatchedwithin }}</td>
                                        <td>{{ statementReportLrDetailsData.incentiveAmt }}</td>
                                        <td>{{ statementReportLrDetailsData.penaltyAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-3 vl p-t-10">
                        <div class="box-body">
                            <h6><u>Addition:</u></h6>
                            <table datatable id="{{pageId}}statementRptAdditionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrAdditionDetails" [dtTrigger]="dtTriggerStatementReportLrAdditionDetails">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrAdditionData of statementReportLrAdditionDataList ">
                                        <td>{{ statementReportLrAdditionData.description }}</td>
                                        <td>{{ statementReportLrAdditionData.totalAmount }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body">
                            <h6><u>Deduction:</u></h6>
                            <table datatable id="{{pageId}}statementRptDeductionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStatementReportLrDeductionDetails" [dtTrigger]="dtTriggerStatementReportLrDeductionDetails">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Debit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let statementReportLrDeductionData of statementReportLrDeductionDataList ">
                                        <td>{{ statementReportLrDeductionData.description }}</td>
                                        <td>{{ statementReportLrDeductionData.totalAmount }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <!-- <div *ngIf="viewStatementRptLRDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printStmtRptLRDtlsId">
        <app-agent-statement-rpt-lr-details-print></app-agent-statement-rpt-lr-details-print>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div> -->
</body>

</html>