<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}cashmemoDetId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="{{pageId}}checkboxEnable"
                                                            (change)="enableCheckBoxEvent($event)"><label value="Enable"
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxEnable">Enable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [hidden]="!hideAndShowEnableField">
                                            <div class="col-sm-12 col-md-3">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" value="All"
                                                            id="{{pageId}}checkboxEnableAll"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxEnableAll">All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="{{pageId}}checkboxEnableSpecific"><label
                                                            value="Specific" class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxEnableSpecific">Specific</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-5">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}cheatCodeId" name="cheatCodeId"
                                                            aria-describedby=" basic-addon11 " autocomplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="{{pageId}}checkboxWithLetterHead"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxWithLetterHead">With
                                                            Letter Head</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--
												*ngIf="hideAndShowFromDateField"  *ngIf="hideAndShowToDateField"
												-->
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control"
                                                            placeholder="yyyy-mm-dd" id="{{pageId}}fromDates"
                                                            name="fromDates" ngbDatepicker #fromDates="ngbDatepicker"
                                                            autocomplete="off" [(ngModel)]="fromDatesModal">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates"
                                                            class="form-control" placeholder="yyyy-mm-dd" name="toDates"
                                                            ngbDatepicker #toDates="ngbDatepicker" autocomplete="off"
                                                            [(ngModel)]="toDatesModal">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowConsolidatedMemoRpt">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="consolidatedMemoRptCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxConsolidatedMemoRpt"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxConsolidatedMemoRpt">Consolidated
                                                            Memo Report</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12"
                                                [hidden]="!hideAndShowConsolidateMemoRptSrc">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="consolidatedMemoRptSrcCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxConsolidatedMemoRptSrc"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxConsolidatedMemoRptSrc">Consolidated
                                                            Memo Report Source</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12"
                                                [hidden]="!hideAndShowConsolidateMemoRptMainSrc">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="consolidatedMemoRptMainSrcCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxConsolidatedMemoRptMainSrc">
                                                        <label class="custom-control-label wtfull"
                                                            for="{{pageId}}checkboxConsolidatedMemoRptMainSrc">
                                                            Consolidated Memo Report Main Source Wise</label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowAllTransaction">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="allTranscationCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxAllTransaction"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxAllTransaction">All
                                                            Transaction</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="showAllLRDDLrsCheckbox">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="allTranscationShowLrDDEvent($event)"
                                                            id="{{pageId}}checkboxAllTransactionShowLrDD"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxAllTransactionShowLrDD">Show LR
                                                            DD</label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowMemoType">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Memo Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-rupee-sign"></i>
                                                            </span>
                                                        </div>
                                                        <select #searchBy class="custom-select col-12"
                                                            id="{{pageId}}memoTypeId"
                                                            (change)="searchByMode(searchBy.value)">
                                                            <option selected value="selected memo">Select
                                                                Memo</option>
                                                            <option value="cash">Cash</option>
                                                            <option value="os">OS</option>
                                                            <option value="pp">PP</option>
                                                            <option value="co">CO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [hidden]="!hideAndShowAreaCollectionManCheckBox">
                                            <div class="col-sm-12 col-md-6">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="areaCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxArea"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxArea">Area</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            (change)="collectionManCheckBoxEvent($event)"
                                                            id="{{pageId}}checkboxCollectionMan"><label
                                                            class=" custom-control-label wtfull"
                                                            for="{{pageId}}checkboxCollectionMan">Collection
                                                            Man</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div [hidden]="!searchByCollectionManWise" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Collection Man</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #collectionMan id="{{pageId}}collectionManId"
                                                                type="text" class="form-control"
                                                                (selectItem)="clickListnerForCollectionMan($event)"
                                                                [(ngModel)]="modelCollectionMan"
                                                                [ngbTypeahead]="searchCollectionMan"
                                                                [resultFormatter]="formatterCollectionMan"
                                                                [inputFormatter]="formatterCollectionMan"
                                                                (focus)="focusCollectionManTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!searchByAreaWise" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Delivery Area</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}deliveryAreaId" type="text"
                                                                class="form-control"
                                                                (selectItem)="clickListnerForDeliveryArea($event)"
                                                                [(ngModel)]="modelOtherDeliveryArea"
                                                                [ngbTypeahead]="searchDeliveryArea"
                                                                [resultFormatter]="formatterDeliveryArea"
                                                                [inputFormatter]="formatterDeliveryArea"
                                                                (focus)="focusDeliveryAreaTA$.next($any($event).target.value)"
                                                                placeholder="Select the Area" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!hideAndShowDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}destinationId" type="text"
                                                                class="form-control"
                                                                (selectItem)="clickListnerForDestination($event)"
                                                                [(ngModel)]="modelDestination"
                                                                [ngbTypeahead]="searchDestination"
                                                                [resultFormatter]="formatterDestination"
                                                                [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!hideAndShowLocalDestination" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Godown</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}localDestinationId" type="text"
                                                            class="form-control"
                                                            (selectItem)="clickListnerForLocalDestination($event)"
                                                            [(ngModel)]="modelLocalDestination"
                                                            [ngbTypeahead]="searchLocalDestination"
                                                            [resultFormatter]="formatterLocalDestination"
                                                            [inputFormatter]="formatterLocalDestination"
                                                            (focus)="focusLocalDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Godown.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                (click)="searchBtnForCashMemoDetails()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                (click)="clearBtn()">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="dt-button" style="margin-left: 91%;"
                            (click)="customPrintCashMemoDetails()" id="{{pageId}}printAllBtn"
                            [hidden]="!hideAndShowTableCashMemoDetails">
                            <span><i class="fas fa-print">Print1</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 91%;"
                            (click)="customPrintConsolidatedMemoRpt()" id="{{pageId}}printAllBtn"
                            [hidden]="!hideAndShowTableConsolidatedMemoRpt">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 91%;"
                            (click)="customPrintConsolidatedMemoRptSrc()" id="{{pageId}}printAllBtn"
                            [hidden]="!hideAndShowTableConsolidatedMemoRptSrc">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 91%;"
                            (click)="customPrintAllTransaction()" id="{{pageId}}printAllBtn"
                            [hidden]="!hideAndShowTableAllTransaction">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 91%;"
                            (click)="customPrintDefaultCashMemoDetailsRpt()" id="{{pageId}}printAllBtn"
                            [hidden]="!hideAndShowTableDefaultCashMemoDetailsRpt">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>

                        <!-- spinner end-->
                        <div class="box-body" [hidden]="!hideAndShowTableCashMemoDetails">
                            <table datatable id="{{pageId}}consolidateMemoRptDetailsTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsConsolidateMemoRpt" [dtTrigger]="dtTriggerConsolidateMemoRpt">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Memos</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chgd Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th [hidden]="!hideshowConsolidateRptDD">DD</th>
                                        <th>Unldg Chg</th>
                                        <th>{{columnLblGDLdgHamali}}</th>
                                        <th>Dem</th>
                                        <th>RC</th>
                                        <th>SC</th>
                                        <th>Other</th>
                                        <th>Disc</th>
                                        <th>S.Total</th>
                                        <th>G.Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cashmemoDetailData of cashmemoDetailDataList ">
                                        <td>{{ cashmemoDetailData.memoType }}</td>
                                        <td>{{ cashmemoDetailData.numberofmemos }}</td>
                                        <td>{{ cashmemoDetailData.totalArticles }}</td>
                                        <td>{{ cashmemoDetailData.actualWeight }}</td>
                                        <td>{{ cashmemoDetailData.chargedWeight }}</td>
                                        <td>{{ cashmemoDetailData.topay }}</td>
                                        <td>{{ cashmemoDetailData.paid }}</td>
                                        <td [hidden]="!hideshowConsolidateRptDD">{{ cashmemoDetailData.hamaliDD }}</td>
                                        <td>{{ cashmemoDetailData.unloadingHamali }}</td>
                                        <td>{{ cashmemoDetailData.hamaligd }}</td>
                                        <td>{{ cashmemoDetailData.demurrage }}</td>
                                        <td>{{ cashmemoDetailData.receiptCharges }}</td>
                                        <td>{{ cashmemoDetailData.serviceCharges }}</td>
                                        <td>{{ cashmemoDetailData.otherChgs }}</td>
                                        <td>{{ cashmemoDetailData.discount }}</td>
                                        <td>{{ cashmemoDetailData.subTotal }}</td>
                                        <td>{{ cashmemoDetailData.grandTotal }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!hideshowConsolidateRptDD"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- 							the first datatble ends -->
                        <!-- 							<the second datatable starts  -->
                        <div class="box-body" [hidden]="!hideAndShowTableConsolidatedMemoRpt">
                            <table datatable id="{{pageId}}consolidatedMemoSourceRptTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionConsolidatedMemoSourceRpt"
                                [dtTrigger]="dtTriggerConsolidatedMemoSourceRpt">
                                <thead>
                                    <tr>
                                        <th>State</th>
                                        <th>Memos</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chgd Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th [hidden]="!hideshowConsolidateRptDD">DD</th>
                                        <th>Unldg Chg</th>
                                        <th>{{columnLblGDLdgHamali}}</th>
                                        <th>Dem</th>
                                        <th>RC</th>
                                        <th>SC</th>
                                        <th>Others</th>
                                        <th>Disc</th>
                                        <th>S.Total</th>
                                        <th>G.Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let consolidatedMemoRptData of consolidatedMemoRptDataList ">
                                        <td>{{ consolidatedMemoRptData.mainStation }}</td>
                                        <td>{{ consolidatedMemoRptData.numberofmemos }}</td>
                                        <td>{{ consolidatedMemoRptData.totalArticles }}</td>
                                        <td>{{ consolidatedMemoRptData.actualWeight }}</td>
                                        <td>{{ consolidatedMemoRptData.chargedWeight }}</td>
                                        <td>{{ consolidatedMemoRptData.topay }}</td>
                                        <td>{{ consolidatedMemoRptData.paid }}</td>
                                        <td [hidden]="!hideshowConsolidateRptDD">{{ consolidatedMemoRptData.hamaliDD }}
                                        </td>
                                        <td>{{ consolidatedMemoRptData.unloadingHamali }}</td>
                                        <td>{{ consolidatedMemoRptData.hamaligd }}</td>
                                        <td>{{ consolidatedMemoRptData.demurrage }}</td>
                                        <td>{{ consolidatedMemoRptData.receiptCharges }}</td>
                                        <td>{{ consolidatedMemoRptData.serviceCharges }}</td>
                                        <td>{{ consolidatedMemoRptData.otherChgs }}</td>
                                        <td>{{ consolidatedMemoRptData.discount }}</td>
                                        <td>{{ consolidatedMemoRptData.subTotal }}</td>
                                        <td>{{ consolidatedMemoRptData.grandTotal }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!hideshowConsolidateRptDD"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!--the second datatable ends  -->
                        <!--the third datatable starts  -->
                        <div class="box-body" [hidden]="!hideAndShowTableConsolidatedMemoRptSrc">
                            <table datatable id="{{pageId}}consolidatedMemoRptMainSrcTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsConsolidatedMemoMainSourceWiseRpt"
                                [dtTrigger]="dtTriggerConsolidatedMemoMainSourceWiseRpt">

                                <thead>
                                    <tr>
                                        <th>State</th>
                                        <th>Memos</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chgd Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th [hidden]="!hideshowConsolidateRptDD">DD</th>
                                        <th>Unldg Chg</th>
                                        <th>{{columnLblGDLdgHamali}}</th>
                                        <th>Dem</th>
                                        <th>RC</th>
                                        <th>SC</th>
                                        <th>Others</th>
                                        <th>Disc</th>
                                        <th>S.Total</th>
                                        <th>G.Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let consolidatedMemoRptSrcData of consolidatedMemoRptSrcDataList ">
                                        <td>{{ consolidatedMemoRptSrcData.mainStation }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.numberofmemos }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.totalArticles }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.actualWeight }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.chargedWeight }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.topay }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.paid }}</td>
                                        <td [hidden]="!hideshowConsolidateRptDD">{{ consolidatedMemoRptSrcData.hamaliDD
                                            }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.unloadingHamali }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.hamaligd }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.demurrage }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.receiptCharges }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.serviceCharges }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.otherChgs }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.discount }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.subTotal }}</td>
                                        <td>{{ consolidatedMemoRptSrcData.grandTotal }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!hideshowConsolidateRptDD"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- the third datatable ends  -->
                        <!-- the fourth datatable starts  -->
                        <div class="box-body" [hidden]="!hideAndShowTableAllTransaction">
                            <table datatable name="allTransactionTableId" id="{{pageId}}allTransactionTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionAllTransaction" [dtTrigger]="dtTriggerAllTransaction">
                                <thead>
                                    <tr>
                                        <th>Cash Memo Date</th>
                                        <th>Cash Memo</th>
                                        <th>Agent Name</th>
                                        <th>LR No.</th>
                                        <th>Art</th>
                                        <th>Commodity Name</th>
                                        <th>Consignee</th>
                                        <th>Contact Number</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th [hidden]="!hideshowConsolidateRptDD">DD</th>
                                        <th>Unldg Chg</th>
                                        <th>{{columnLblGDLdgHamali}}</th>
                                        <th>RC</th>
                                        <th>Disc</th>
                                        <th>Dem.</th>
                                        <th>SC</th>
                                        <th>Total</th>
                                        <th>G.Total</th>
                                        <th>Type</th>
                                        <th>Area</th>
                                        <th [hidden]=true>FOV</th>
                                        <th [hidden]=!hideshowTripsheetHamaliAndLRDD>Tripsheet Hamali</th>
                                        <th [hidden]=!hideshowTripsheetHamaliAndLRDD>LR DD</th>
                                        <th>POD Copy</th>
                                        <th>CmAt MemoGeneration </th>
                                        <th>Godown Name</th>
                                        <th>Delivery Type</th>
                                        <th>Delivery Mode</th>
                                        <th>Vehicle Number</th>
                                        <th>Vehicle Name</th>
                                        <th>Invoice Date</th>
                                        <th>Booking Date</th>
                                        <th>Trip Id</th>
                                        <th>Less Parties</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let allTransactionData of allTransactionDataList ">
                                        <td>{{ allTransactionData.memoDateStr }}</td>
                                        <td>{{ allTransactionData.memoNumber }}</td>
                                        <td>{{ allTransactionData.agentName }}</td>
                                        <td>{{ allTransactionData.lrNumber }}</td>
                                        <td>{{ allTransactionData.totalArticles }}</td>
                                        <td>{{ allTransactionData.commodityName }}</td>
                                        <td>{{ allTransactionData.consigneeName }}</td>
                                        <td>{{ allTransactionData.contactNumber }}</td>
                                        <td>{{ allTransactionData.actualWeight }}</td>
                                        <td>{{ allTransactionData.chargedWeight }}</td>
                                        <td>{{ allTransactionData.topay }}</td>
                                        <td>{{ allTransactionData.paid }}</td>
                                        <td [hidden]="!hideshowConsolidateRptDD">{{ allTransactionData.hamaliDD }}</td>
                                        <td>{{ allTransactionData.unloadingHamali }}</td>
                                        <td>{{ allTransactionData.hamaligd }}</td>
                                        <td>{{ allTransactionData.receiptCharges }}</td>
                                        <td>{{ allTransactionData.discount }}</td>
                                        <td>{{ allTransactionData.demurrage }}</td>
                                        <td>{{ allTransactionData.serviceCharges }}</td>
                                        <td>{{ allTransactionData.subTotal }}</td>
                                        <td>{{ allTransactionData.grandTotal }}</td>
                                        <td>{{ allTransactionData.memoType }}</td>
                                        <td>{{ allTransactionData.area }}</td>
                                        <td [hidden]=true>{{ allTransactionData.riskCharge }}</td>
                                        <td [hidden]=!hideshowTripsheetHamaliAndLRDD>{{
                                            allTransactionData.tripsheetHamali }}</td>
                                        <td [hidden]=!hideshowTripsheetHamaliAndLRDD>{{ allTransactionData.lrDDAmt }}
                                        </td>
                                        <td style="cursor: pointer; color: blue;text-decoration: underline;"
                                            (click)="getLrPodCopy(allTransactionData.podlrpdfurl);">{{
                                            allTransactionData.remarks }}</td>
                                        <td>{{ allTransactionData.cmAtMemoGeneration }}</td>
                                        <td>{{ allTransactionData.deliverygodown }}</td>
                                        <td>{{ allTransactionData.collectiontype }}</td>
                                        <td>{{ allTransactionData.deliveryMode }}</td>
                                        <td>{{ allTransactionData.ddVehicleNo }}</td>
                                        <td>{{ allTransactionData.vehicleName }}</td>
                                        <td>{{ allTransactionData.date }}</td>
                                        <td>{{ allTransactionData.bookingDateStr }}</td>
                                        <td>{{ allTransactionData.tripId }}</td>
                                        <td *ngIf="allTransactionData.memoCheck== false">
                                            {{ allTransactionData.memoCheck== true ? 'Yes' : 'No'}}</td>
                                        <td *ngIf="allTransactionData.memoCheck== true" style='font-size: 13px; color: red; font-weight: bold;'>
                                            {{ allTransactionData.memoCheck== true ? 'Yes' : 'No'}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!hideshowConsolidateRptDD"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]=true></td>
                                        <td [hidden]=!hideshowTripsheetHamaliAndLRDD></td>
                                        <td [hidden]=!hideshowTripsheetHamaliAndLRDD></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- the fourth datatable ends  -->
                        <!-- the five datatable starts  -->
                        <div class="box-body" [hidden]="!hideAndShowTableDefaultCashMemoDetailsRpt">
                            <table datatable id="{{pageId}}DefaultCashMemoDetailsRptTableId"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionDefaultCashMemoDetailsRpt"
                                [dtTrigger]="dtTriggerDefaultCashMemoDetailsRpt">
                                <thead>
                                    <tr>
                                        <th>Memo No</th>
                                        <th>Party Name</th>
                                        <th>LR No.</th>
                                        <th>Art</th>
                                        <th>Commodity Name</th>
                                        <th>Area</th>
                                        <th>Memo Date</th>
                                        <th>Discount</th>
                                        <!--<th>Unldg Chg</th>-->
                                        <th>{{columnLblGDLdgHamali}}</th>
                                        <th>Amount</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th [hidden]="!hideshowConsolidateRptDD">DD</th>
                                        <th>Actual Weight</th>
                                        <th>Charged Weight</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let defaultCashmemoRptData of defaultCashmemoRptDataList ">
                                        <td>{{ defaultCashmemoRptData.memoNumber }}</td>
                                        <td>{{ defaultCashmemoRptData.consigneeName }}</td>
                                        <td>{{ defaultCashmemoRptData.lrNumber }}</td>
                                        <td>{{ defaultCashmemoRptData.totalArticles }}</td>
                                        <td>{{ defaultCashmemoRptData.commodityName }}</td>
                                        <td>{{ defaultCashmemoRptData.area }}</td>
                                        <td>{{ defaultCashmemoRptData.memoDateStr }}</td>
                                        <td>{{ defaultCashmemoRptData.discount }}</td>
                                        <td>{{ defaultCashmemoRptData.hamaligd }}</td>
                                        <td>{{ defaultCashmemoRptData.amount }}</td>
                                        <td>{{ defaultCashmemoRptData.topay }}</td>
                                        <td>{{ defaultCashmemoRptData.paid }}</td>
                                        <td [hidden]="!hideshowConsolidateRptDD">{{ defaultCashmemoRptData.hamaliDD }}
                                        </td>
                                        <td>{{ defaultCashmemoRptData.actualWeight }}</td>
                                        <td>{{ defaultCashmemoRptData.chargedWeight }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!hideshowConsolidateRptDD"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- the five datatable ends  -->
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

</body>

</html>