<body>
    <!-- Row -->
    <div class="row" id="{{pageId}}showRewardingRpt" *ngIf="isLoggedIn">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row">
                    <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- <div class="col-sm-12 col-md-6" [hidden]="!viewScanCodeField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Scan Code</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-clipboard"></i>
													</span>
                                                </div> -->
                                    <!-- <input type="text" class="form-control" id="{{pageId}}scanCode" onPaste="return false" autocomplete="off" (keypress)="getScanCodeFieldsDetails($event,rewardingPopUpTemplate);" aria-describedby="basic-addon11"> -->
                                    <!-- <input type="text" class="form-control" id="{{pageId}}scanCode" (paste)="scanCodePaste($event)" autocomplete="off" (keypress)="getScanCodeFieldsDetails($event,rewardingPopUpTemplate);" aria-describedby="basic-addon11">
                                            </div> -->
                                    <!--[hidden]="!viewScanCodeField"-->
                                    <!-- </div>
                                    </div> -->
                                    <div class="col-sm-12 col-md-6" [hidden]="!viewScanCodeField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hireslip Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-clipboard-list"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}hireslipNumberGet" aria-describedby="basic-addon11" autocomplete="off" (keypress)="getHireslipNumberRewardDetails($event,scanCodeForRewardPopUpTemplate);">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button type="submit" class="p_custom btn btn-success m-r-10" id="{{pageId}}getScanCodeBtn" [hidden]="!viewScanCodeBtn" (click)="getHireslipNumberRewardDetails(scanCodeForRewardPopUpTemplate);">Get
                                            Hireslip Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12" [hidden]="!viewAlreadyReward">
                                        <h5 class="cstm_efftect" id="{{pageId}}isAlreadyRewardedHeadingId">
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <i class="fas fa-clipboard-list"></i><div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-4">
									<div class="form-group">
										<div class="input-group">
											<label>Scan Code</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class=" fas fa-clipboard"></i>
												</span>
											</div>
											<input type="text" class="form-control" id="{{pageId}}scanCode"
												(keypress)="getScanCodeFieldsDetails($event);"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-8">
									<button type="submit" class="p_custom btn btn-success m-r-10" id="{{pageId}}getScanCodeBtn"
										[hidden]="!viewScanCodeBtn">Scan</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>-->
                <hr style="margin: 0px;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">Trip Details</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hireslip Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-clipboard-list"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}hireslipNumber" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlyHireslipNo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Invoice Date</label> <input id="{{pageId}}invoiceDates" class="form-control" placeholder="yyyy-mm-dd" name="invoiceDates" ngbDatepicker #invoiceDates="ngbDatepicker" [(ngModel)]="invoiceDatesModal"
                                                    autocomplete="off" [readonly]="isReadonlyInvoiceDate">
                                                <div class="input-group-append" (click)="invoiceDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-box"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}source" aria-describedby="basic-addon11" autocomplete="off" [readonly]="isReadonlySource">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}destination" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyDestination">
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="!showHireColumns" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hire Amount</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" id="{{pageId}}hireAmount" class="form-control" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyHireAmount">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <hr> -->
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">Driver Details</h6>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Vehicle Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}vehicleNumber" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyVehicleNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Supplier Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}supplierName" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlySupplierName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Driver Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}driverName" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyDriverName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Mobile Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-phone"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}mobileNumber" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyMobileNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>License Number </label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-id-card"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}licenseNumber" autocomplete="off" aria-describedby="basic-addon11" [readonly]="isReadonlyLicenseNumber">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">Time Details</h6>
                                <div class="row">

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Departure Date</label> <input id="{{pageId}}departureDates" class="form-control" placeholder="yyyy-mm-dd" name="departureDates" ngbDatepicker #departureDates="ngbDatepicker" [(ngModel)]="departureDatesModal"
                                                    autocomplete="off" [readonly]="isReadonlyDepartureDate">
                                                <div class="input-group-append" (click)="departureDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Departure Time</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}departureTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyDepartureTime">
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Departure Time</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-clock"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium" id="{{pageId}}departureTime"
														placeholder="Select Departure Time" [formControl]="control"
														autocomplete="off" [readonly]="isReadonlyDepartureTime"
														[appAutocomplete]="autocomplete">
												</div>
											</div>
										</div>
										<app-autocomplete #autocomplete="appAutocomplete">
											<ng-template appAutocompleteContent>
												<ng-container
													*ngIf="(departureTimeOptions | filter: control.value) as resultDepartureTime">
													<app-option *ngFor="let option of resultDepartureTime"
														[value]="option.label"> {{ option.label }} </app-option>
													<app-option class="no-result" *ngIf="!resultDepartureTime.length">No
														result</app-option>
												</ng-container>
											</ng-template>
										</app-autocomplete>
									</div>-->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Scheduled Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}scheduledDates" name="scheduledDates" ngbDatepicker #scheduledDates="ngbDatepicker" [(ngModel)]="scheduledDatesModal"
                                                    autocomplete="off" [readonly]="isReadonlyScheduledDate">
                                                <div class="input-group-append" (click)="scheduledDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Scheduled Time</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}scheduledTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyScheduledTime">
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Scheduled Time</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-clock"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium" id="{{pageId}}scheduledTime"
														placeholder="Select Scheduled Time" [formControl]="control"
														[appAutocomplete]="autocomplete" autocomplete="off">
												</div>
											</div>
										</div>
										<app-autocomplete #autocomplete="appAutocomplete">
											<ng-template appAutocompleteContent>
												<ng-container
													*ngIf="(scheduledTimeOptions | filter: control.value) as resultScheduledTime">
													<app-option *ngFor="let option of resultScheduledTime"
														[value]="option.label"> {{ option.label }} </app-option>
													<app-option class="no-result" *ngIf="!resultScheduledTime.length">No
														result</app-option>
												</ng-container>
											</ng-template>
										</app-autocomplete>
									</div>-->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Arrival Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}arrivalDates" name="arrivalDates" ngbDatepicker #arrivalDates="ngbDatepicker" [(ngModel)]="arrivalDatesModal"
                                                    autocomplete="off" [readonly]="isReadonlyArrivalDate">
                                                <div class="input-group-append" (click)="arrivalDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Arrival Time</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}arrivalTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyArrivalTime">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Arrival Date(Ft)</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}ftArrivalDates" name="ftArrivalDates" ngbDatepicker #ftArrivalDates="ngbDatepicker" [(ngModel)]="ftArrivalDatesModal"
                                                    autocomplete="off" [readonly]="isReadonlyFtArrivalDate">
                                                <div class="input-group-append" (click)="ftArrivalDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Arrival Time(Ft)</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                    </span>
                                                </div>
                                                <input type="time" class="form-control " id="{{pageId}}ftArrivalTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyFtArrivalTime">
                                            </div>
                                        </div>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <!-- </div>style="background-color:#f2f9fb;"> -->
                                <h6 class="card-title">Time Calculation</h6>
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group">
                                            <label>Preferred Duration(Hrs)</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}preferredDuration" class="form-control " autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyPreferredDuration">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group">
                                            <label>Buffer Time(Hrs)</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control " id="{{pageId}}bufferTime" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyBufferTime">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group">
                                            <label>Time Taken To Reached(Hrs)</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}timeTakenToReached" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyTimeTakenToReached">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group">
                                            <label>Time Taken To Reached(Hrs)-Ft</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-clock"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}timeTakenToReachedFt"
                                                autocomplete="off" aria-describedby="basic-addon11 "
                                                [readonly]="isReadonlyTimeTakenToReachedFt">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-md-12 ">
                                    <div class="form-group ">
                                        <div class="input-group ">
                                            <label>Reward Amount</label>
                                            <div class="input-group-prepend ">
                                                <span class="input-group-text "> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}rewardAmount" autocomplete="off" aria-describedby="basic-addon11 " [readonly]="isReadonlyRewardAmount">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}confirmPaymentBtn" [hidden]="!viewConfirmPaymentBtn" (click)="confirmPaymentClickBtn();">Confirm
                            Payment</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}printBtn" [hidden]="!viewPrintBtn" (click)="printClickBtn();">Print</button>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewRewardingVoucher " onafterprint="afterPrint()" id="{{pageId}}printRewardingVoucher">
        <app-rewarding-voucher-rpt-print></app-rewarding-voucher-rpt-print>
    </div>

    <!--POP UP START HERE-->
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #rewardingPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Option :</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <label>Exclude Sunday</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxWithExcludeSundayYes" (change)="excludeSundayYesCheckBoxEvent($event);"><label class=" custom-control-label wtfull" for="{{pageId}}checkboxWithExcludeSundayYes">Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxWithExcludeSundayNo" (change)="excludeSundayNoCheckBoxEvent($event);" checked>
                                                    <label class=" custom-control-label wtfull" for="{{pageId}}checkboxWithExcludeSundayNo">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}excludeSundayYesId" (click)="excludeSundayOkClickBtn();">OK</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}cancelBtn" (click)="excludeSundayCancelClickBtn();">Cancel</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <!--POP UP END HERE-->
    <!-- Scancode Popup -->
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #scanCodeForRewardPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="custom-modal-content">
                        <div class="modal-header">
                            <h5>Auto Arrival date not available please scan code</h5>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row" *ngIf="showSundayWiseRewardFields">
                                <div class="col-md-12">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group ">
                                                    <div class="input-group">
                                                        <label>Exclude Sunday</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxWithExcludeSundayYes" (change)="excludeSundayYesCheckBoxEvent($event);"><label class=" custom-control-label wtfull" for="{{pageId}}checkboxWithExcludeSundayYes">Yes</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxWithExcludeSundayNo" (change)="excludeSundayNoCheckBoxEvent($event);" checked>
                                                        <label class=" custom-control-label wtfull" for="{{pageId}}checkboxWithExcludeSundayNo">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Scan Code</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fas fa-clipboard"></i></span>
                                            </div>
                                            <div>
                                                <!-- Adjust this col class to change the width -->
                                                <input type="text" class="form-control" id="{{pageId}}scanCode" (paste)="scanCodePaste($event)" autocomplete="off" (keypress)="getScanCodeFieldsDetails($event,scanCodeForRewardPopUpTemplate);" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}getScanCodeBtn" (click)="getScanDetailsClickBtn(scanCodeForRewardPopUpTemplate);">Scan Code</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}scanCodePopupCloseBtnId" (click)="scanCodePopupCloseBtn();">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</body>