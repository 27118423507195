import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import { DatePipe } from "@angular/common";


@Component({
	selector: 'app-left-to-party-statement-details',
	templateUrl: './left-to-party-statement-details.component.html',
	styleUrls: ['./left-to-party-statement-details.component.css']
})
export class LeftToPartyStatementComponent implements OnInit {

	loadingIndicator = true;

	gettingDataFrmServiceFrMemoLessReportTable: any;
	onDestroyUnsubscribtionLeftToParty: Subscription;

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLeftToPartyStmtDts: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsLeftToPartyStmtDts: any;
	leftToPartyPoupDataList: any;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	showSpinnerForAction = false;

	leftToPartyGeneralData: any;

	collectionManHeader: string;
	stmtNoHeader: string;
	pageId = "lpstc";


	constructor(private router: Router, private memoReport: MemoReport, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.leftToPartyGeneralData = JSON.parse(localStorage.getItem('leftToPartyGeneralDetailsLocalStorage'));
			console.log(this.leftToPartyGeneralData);
			localStorage.clear();
			if (this.leftToPartyGeneralData != null &&
				this.leftToPartyGeneralData != undefined &&
				this.leftToPartyGeneralData != "") {
				this.collectionManHeader = this.leftToPartyGeneralData.collectionMan;
				this.stmtNoHeader = this.leftToPartyGeneralData.statementNo;
				this.gridReconfigureTpStmtDetails();
			}
		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	ngOnInit(): void {
		this.dtOptionsLeftToPartyStmtDts = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "Left To Party Statement Details - ";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//"iDisplayLength" : 5,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			//"sPaginationType" : "simple_numbers",
			//"sPaginationType" : "full_numbers",
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var memoAmt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(5).footer()).html(memoAmt);
			},
		}
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnDestroy(): void {
		this.dtTriggerLeftToPartyStmtDts.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerLeftToPartyStmtDts.next();
	}

	gridReconfigureTpStmtDetails() {
		let cashMemoDto: CashMemoDto = new CashMemoDto();
		cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
		cashMemoDto.statementNo = this.leftToPartyGeneralData.statementNo;
		this.showSpinnerForAction = true;
		console.log(cashMemoDto);
		this.memoReport.getTPStmtDetails(cashMemoDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				this.leftToPartyPoupDataList = [];
				$("#" + this.pageId + "leftToPartyStmtTableId").DataTable().destroy();
				if (response.length > 0) {
					this.leftToPartyPoupDataList = response;
				} else {
					swal("No Result", "No records found", "warning");
				}
				this.dtTriggerLeftToPartyStmtDts.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Failed", "Server problem occurred while getting TP Stmt Details", "error");
			}, () => console.log('done');
	}

}

