import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";

import { BookingRoutes } from 'src/app/fortnightStatement/booking/booking.routing';
import { AgentCommissionMasterComponent } from 'src/app/fortnightStatement/booking/agent-commission-master/agent-commission-master.component';
import { AgentStatementGenerationComponent } from 'src/app/fortnightStatement/booking/agent-statement-generation/agent-statement-generation.component';
import { MaterialModule } from "src/app/material.module";
//import { AgentStatementRptLrDetailsComponent } from "src/app/report/bookings/agent-statement-rpt-lr-details/agent-statement-rpt-lr-details.component";
//import { AgentStatementRptLrDetailsPrintComponent } from "src/app/report/bookings/agent-statement-rpt-lr-details-print/agent-statement-rpt-lr-details-print.component";
//import { AgentStatementPaymentDetailsComponent } from "src/app/report/bookings/agent-statement-payment-details/agent-statement-payment-details.component";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { BookingsModule } from 'src/app/report/bookings/bookings.module';

@NgModule( {
    imports: [CommonModule,
        //  RouterModule.forChild( BookingRoutes ),
          FormsModule,  NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule,MaterialModule,CashmemoReportModule,BookingsModule],
    declarations: [
        AgentCommissionMasterComponent,
        AgentStatementGenerationComponent
        //AgentStatementRptLrDetailsComponent,
        //AgentStatementRptLrDetailsPrintComponent,
        //AgentStatementPaymentDetailsComponent
    ]
} )
export class BookingModule { }
