import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';

@Component({
    selector: 'app-lr-rpt',
    templateUrl: './lr-rpt.component.html',
    styleUrls: ['./lr-rpt.component.css']
})
export class LrRptComponent implements OnInit {
    lrReportDataList: any;
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;

    showSpinnerForAction = false;
    viewDateWise = true;
    showSource = false;
    showDest = false;
    viewConsigneeWise = false;
    viewConsignorWise = false;
    isDestView = false;
    isSourceView = false;
    isSourceBkgView = false;
    isLoggedIn = true;
    fromDateModel: any;
    toDateModel: any;
    userDataDtoReturnSession: any;
    consignorIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];
    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedDestination: any;
    selectedIndex: any;
    selectedConsigneeGSTNumber: any;
    selectedConsignorGSTNumber: any;
    selectedConsigneeName: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedConsignorName: any;
    selectedSource: any;
    selectedViewBy: any;
    enteredLrNumber: any;
    sourceBkgOfficeAndAgent = 'List of Source Booking and Agent';
    showSourceList = 'Show Source List';
    showDestinationList = 'Show Destination List';
    selectedConsigneeId: any;
    selectedConsignorId: any;
    getSelectedRowMemoNumber: any;
    selectedRecDate: any;
    searchLetterHeadRateDto = new RateMasterDto();
    returnLrSetRateDto = new RateMasterDto();
    modalRefferenceContentLetterHeadPopUp: NgbModalRef;
    modalRefferenceContentLetterHeadClosePopUp: string;
    @ViewChild('contentLetterHead') private contentLetterHead;
    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    //for ConsigneeName
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consigneeNameTA: Array<PartyMasterDto> = [];
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;


    public modelDestinationFotGet: any;
    public modelConsigneeNameId: any;
    public modelConsignorNameId: any;
    modelConsigneeName: any;
    modelConsignorName: any;
    //For Source
    sourceOptions: AgentDetailsDto[];
    lrDtoSource: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;
    //sourceList
    srcLists: any;
    srcList: AgentDetailsDto = new AgentDetailsDto();
    ///
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    sourceDetailsOptionsGet: AgentDetailsDto[];


    searchLrRptDto = new LRDto();
    agentDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    agentDtoSourceAddOption: AgentDetailsDto = new AgentDetailsDto();
    setSource: Array<any> = [];
    address: any;

    /////


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrReport: Subject<any> = new Subject();


    dataTable: any;
    dtOptionsLrReport: any;

    //For Custom Print
    viewCustomPrintV1: any;

    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;

    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    artSummary: number;
    actWtSummary: number;
    chgWtSummary: number;
    baseFreightSummary: number;
    hamaliSummary: number;
    aocSummary: number;
    lcChgSummary: number;
    bcChgSummary: number;
    insuranceSummary: number;
    gcChgSummary: number;
    othersSummary: number;
    ddAmtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    pageId = "lrrtc";


    constructor(private memoLessRpt: ReportService, private router: Router,
        private memoReport: MemoReport, private masterReadService: MasterReadService,
        private modalService: NgbModal, private lrReportService: LrReportService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.setConsginorIndex();
            this.getConsigneeDetails(false);
            //  console.log( this.userDataDtoReturnSession.stationList);
            // this.getSourceList();


            ///Rights for show List of Source Booking and Agent 
            //            if ( this.sourceBkgOfficeAndAgent == "List of Source Booking and Agent" ) {
            //                this.showDest = true;
            //   this.getSourceList();
            //            } 


            ///Rights for show Show Source List
            //            if ( this.showSourceList == "Show Source List" ) {
            //                this.showDest = true;
            //this.getSourceDetails();

            //} 
            ///Rights for show Show Dest List
            //            if ( this.showDestinationList == "Show Dest List" ) {
            //                this.showDest = true;
            //} 

            // console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Source LrRptSupAdmin") {
                        this.isSourceView = true;
                        this.showSource = true;
                        this.getSourceDetails();
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Source LrRptBookAdmin") {
                        this.isSourceBkgView = true;
                        this.showSource = true;
                        this.getSourceList();
                    }

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Dest LrRptSupAdmin") {
                        this.isDestView = true;

                    }
                }
            }

            if (this.isDestView) {
                this.showDest = true;
                this.getDestinationDetails();
            }
            //Below code need to be commented
            /* this.showDest = true;
             this.isDestView = true;
             this.getDestinationDetails();
             this.isSourceBkgView = false;
             this.showSource = true;
            this.getSourceDetails();*/
            //this.isSourceBkgView = true;
            //this.showSource = true;
        }

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsLrReport = {
            dom: 'Bfrtip',
            buttons: [

                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if ($("#lrrtcviewBy").val() == 'sourceView') {
                            return "Lr Report - " +
                                "From Date : " + moment($("#lrrtcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#lrrtctoDate").val()).format('DD-MM-YYYY') + "";

                        } else if ($("#lrrtcviewBy").val() == 'consigneeView') {
                            return "Lr Report - " +
                                "From Date : " + moment($("#lrrtcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#lrrtctoDate").val()).format('DD-MM-YYYY') + " Consignee Name : " + $("#lrrtcconsigneeName").val();
                        } else if ($("#lrrtcviewBy").val() == 'consignorView') {
                            return "Lr Report - " +
                                "From Date : " + moment($("#lrrtcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#lrrtctoDate").val()).format('DD-MM-YYYY') + " Consignor Name : " + $("#lrrtcconsignorName").val();
                        }


                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var articles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actualWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargeWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var baseFreight = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamali = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lcChg = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var bcChg = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var insurence = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var gcChg = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var others = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var ddAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodsVal = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html(articles);
                $(api.column(5).footer()).html(actualWgt);
                $(api.column(6).footer()).html(chargeWgt);
                $(api.column(7).footer()).html(baseFreight);
                $(api.column(8).footer()).html(hamali);
                $(api.column(9).footer()).html(aoc);
                $(api.column(10).footer()).html(lcChg);
                $(api.column(11).footer()).html(bcChg);
                $(api.column(12).footer()).html(insurence);
                $(api.column(13).footer()).html(gcChg);
                $(api.column(14).footer()).html(others);
                $(api.column(15).footer()).html(ddAmt);
                $(api.column(16).footer()).html(toPay);
                $(api.column(17).footer()).html(paid);
                $(api.column(18).footer()).html(fov);
                $(api.column(19).footer()).html(goodsVal);
                $(api.column(20).footer()).html();
                $(api.column(21).footer()).html();


            }

        }
    }


    ngOnDestroy(): void {
        this.dtTriggerLrReport.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTriggerLrReport.next();

    }


    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }
    viewByMode(viewBy: string) {
        if (viewBy === 'sourceView') {
            this.viewDateWise = true;
            this.showSource = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = false;
            this.showDest = false;
            if (this.isDestView) {
                this.showDest = true;
            }
            if (this.isSourceView) {
                this.showSource = true;
            }
            if (this.isSourceBkgView) {
                this.showSource = true;
            }

        } else if (viewBy === 'consigneeView') {
            this.viewDateWise = true;
            this.showSource = false;
            this.viewConsigneeWise = true;
            this.viewConsignorWise = false;
            this.showDest = false;
            if (this.isDestView) {
                this.showDest = true;
                $("#" + this.pageId + "destination").val('');
                $("#" + this.pageId + "consigneeName").val('');
                this.modelConsigneeName = '';
                this.consigneeNameTA = [];
            } else {
                this.consigneeNameTA = this.consigneeNameTATemp;
                $("#" + this.pageId + "consigneeName").val('All');
            }


        } else if (viewBy === 'consignorView') {
            this.viewDateWise = true;
            this.showSource = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = true;
            this.showDest = false;
            if (this.isDestView) {
                this.showDest = true;
            }

            $("#" + this.pageId + "consignorName").val('');
            this.modelConsignorName = '';
            this.consignorNameTA = [];
        } else {
            this.viewDateWise = false;
            this.showSource = false;
            this.showDest = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = false;

        }
    }

    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }

    setSourceListFromSession() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedDestination = this.modelDestinationFotGet;
        if (destShow) {
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.consigneeNameTATemp = this.consigneeNameTA;

                    $("#" + this.pageId + "consigneeName").val('All');
                    if (this.selectedViewBy == 'consigneeView') {
                        $("#" + this.pageId + "consigneeGSTNumber").focus();
                    }
                    if (this.selectedViewBy == 'consignorView') {
                        $("#" + this.pageId + "consignorGSTNumber").focus();
                    }
                    console.log(this.consigneeNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();
    }

    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "Index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    window.setTimeout(function () {
                        $("#" + this.pageId + "consignorName").focus();
                    }, 100);
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    getConsigneeGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsigneeGSTNumber = $("#consigneeGSTNumber").val();
            console.log(this.selectedConsigneeGSTNumber);
            if (this.selectedConsigneeGSTNumber != null && this.selectedConsigneeGSTNumber != '') {
                this.getConsigneeDetailsWithGst();
                //                 
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignee GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#consigneeGSTNumber").focus();
                });
                this.consigneeNameOptions = [];
                this.consigneeNameTA = [];


            }

        }
    }

    getConsigneeDetailsWithGstList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedConsigneeGSTNumber = $("#consigneeGSTNumber").val();
        if (this.showDest) {
            this.selectedDestination = this.modelDestinationFotGet;
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.isGST = true;
        this.partyMasterDtoForconsignee.mode = "Tinnumber";
        this.partyMasterDtoForconsignee.gstNoConsignee = this.selectedConsigneeGSTNumber;
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetailsWithGst = () => {
        this.getConsigneeDetailsWithGstList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.selectedConsigneeId = '';
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    $("#consigneeName").val(this.consigneeNameOptions[0].consigneeName);
                    this.selectedConsigneeId = this.consigneeNameOptions[0].consigneeId;
                    console.log(this.consigneeNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };


    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedViewBy = $("#" + this.pageId + "viewBy").val();
        if (this.selectedViewBy == 'consigneeView') {
            this.getConsigneeDetails(true);
        }
        if (this.selectedViewBy == 'consignorView') {
            $("#" + this.pageId + "consignorGSTNumber").focus();
        }

    }

    //For Getting Consignor Details
    getConsignorGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
            //            console.log( this.selectedConsignorGSTNumber );
            if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {
                this.getConsignorDetailsForGst();
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignor GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#" + this.pageId + "consignorGSTNumber").focus();
                })
                this.consignorNameOptions = [];
                this.consignorNameTA = [];
            }

        }
    }

    getConsignorDetailsForGst() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();

        this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        //this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.isGST = true;
        this.partyMasterDtoForconsignor.mode = "LREntry";
        this.partyMasterDtoForconsignor.gstNoConsignor = this.selectedConsignorGSTNumber;
        this.getConsignorDetailsForGSTList();
    }
    getConsignorDetailsForGSTList = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.selectedConsignorId = '';
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    $("#" + this.pageId + "consignorName").val(this.consignorNameOptions[0].consignorName);
                    this.selectedConsignorId = this.consignorNameOptions[0].consignorId;
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
    }

    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
    }




    /// Get Agent Names
    /*   getAgentListDetails() {
           this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
           this.agentDto.column2 = "Working";
           this.agentDto.mode = "Sub";
           this.agentDto.status = "All";
        /*   if ( this.isAgentAll == "AgentAll" ) {
               this.agentDto.mode = "Sub";
               this.agentDto.status = "All";
           } else {
               this.agentDto.mode = "mainBranch";
                   this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
             
           }*/

    /*    this.getAgentList();
    }

    getAgentList = () => {
        this.masterReadService.getSourceListDetails( this.agentDto ).subscribe(
            ( response ) => {
                if ( response.length == 0 ) {
                    swal( {
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );

                } else {
                    this.agentDetailsOptionsGet = response;
                    console.log(this.agentDetailsOptionsGet);
                    this.agentDtoAll.subStation = "ALL";
                    this.agentDetailsOptionsGet.push( this.agentDtoAll );

                }
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };*/

    /// Get Agent Names
    getSourceDetails() {
        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.sourceAgentDto.column2 = "Working";
        this.sourceAgentDto.mode = "Sub";
        this.sourceAgentDto.status = "Working";
        this.getSourceMethod();
    }

    getSourceMethod = () => {
        this.masterReadService.getSourceListDetails(this.sourceAgentDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.sourceDetailsOptionsGet = response;
                    console.log(this.sourceDetailsOptionsGet);
                    this.sourceTA = [];
                    this.sourceAgentDtoAll.subStation = "All";
                    this.sourceTA.push(this.sourceAgentDtoAll);
                    for (let i = 0; i < this.sourceDetailsOptionsGet.length; i++) {
                        this.sourceTA.push(this.sourceDetailsOptionsGet[i]);
                    }

                }
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    getSourceList() {
        //   this.srcList="";
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStation = this.srcLists;
        // this.sourceDetailsListGet.subStation = this.srcLists;
        console.log(this.srcLists);
        console.log(this.srcList.subStation);
        this.sourceTA = [];
        this.agentDtoSourceAllOption.subStation = "All";
        this.sourceTA.push(this.agentDtoSourceAllOption);
        for (let i = 0; i < this.srcList.subStation.length; i++) {
            this.agentDtoSourceAddOption = new AgentDetailsDto();
            this.agentDtoSourceAddOption.subStation = this.srcList.subStation[i];
            this.sourceTA.push(this.agentDtoSourceAddOption);
        }
    }

    searchMethod() {

        this.searchLrRptDto = new LRDto();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        console.log(this.selectedFromDate, this.selectedToDate);
        this.selectedConsignorGSTNumber = $("#consignorGSTNumber").val();
        this.selectedConsigneeGSTNumber = $("#consigneeGSTNumber").val();
        this.selectedViewBy = $("#" + this.pageId + "viewBy").val();
        if (this.selectedFromDate != null && this.selectedFromDate != '' && this.selectedToDate != null && this.selectedToDate != '') {
            this.searchLrRptDto.consigneeName = this.selectedConsigneeName;
            this.searchLrRptDto.companyId = this.userDataDtoReturnSession.companyId;
            this.searchLrRptDto.consigneeId = 0;
            if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
                this.searchLrRptDto.consigneeId = this.selectedConsigneeId
            }
            this.searchLrRptDto.consignorId = 0;
            this.searchLrRptDto.gstNoConsignor = this.selectedConsignorGSTNumber;
            console.log(this.selectedConsignorId);

            if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                this.searchLrRptDto.consignorId = this.selectedConsignorId
                console.log(this.selectedConsignorId);
            }
            if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {

                this.searchLrRptDto.consignorName = this.selectedConsignorName;
                if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                    this.searchLrRptDto.consignorId = this.selectedConsignorId;
                    console.log(this.selectedConsignorId);
                }

            }
            this.searchLrRptDto.fromDate = this.selectedFromDate;
            this.searchLrRptDto.toDate = this.selectedToDate;

            if (this.selectedViewBy == 'sourceView') {
                this.searchLrRptDto.destination = this.userDataDtoReturnSession.mainStation;
                if (this.isDestView) {
                    if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                        this.searchLrRptDto.destination = this.selectedDestination;
                    } else {

                        swal({
                            title: "Mandatory Field",
                            text: "Please select Destination",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        return false;

                    }
                }
                console.log(this.selectedSource);
                if ((this.selectedSource != null) && (this.selectedSource != '')) {
                    // console.log('1');
                    if (this.selectedSource == 'All') {
                        //	  console.log('2');
                        if (this.isSourceBkgView) {
                            //  console.log('3');
                            this.searchLrRptDto.list = this.srcLists;//session
                            this.searchLrRptDto.mode = 'DateWiseSrcSpecific';
                        } else {
                            this.searchLrRptDto.mode = 'DateWiseSrcAll';

                        }
                    } else {
                        // console.log('4');c
                        this.setSource = [];
                        this.setSource.push(this.selectedSource);
                        //  console.log( this.setSource);
                        this.searchLrRptDto.list = this.setSource;
                        this.searchLrRptDto.mode = 'DateWiseSrcSpecific';
                    }
                } else {
                    if (this.isSourceBkgView) {
                        //  console.log('5');
                        this.searchLrRptDto.list = this.srcLists;//session
                        this.searchLrRptDto.mode = 'DateWiseSrcSpecific';
                    } else {
                        this.searchLrRptDto.mode = 'DateWiseSrcAll';
                    }
                }
                this.searchMethodGetDetails();
            }
            //ConsigneeView
            else if (this.selectedViewBy == 'consigneeView') {
                this.searchLrRptDto.destination = this.userDataDtoReturnSession.mainStation;


                if (this.searchLrRptDto.consigneeId == 0) {
                    swal({
                        title: "Mandatory Field",
                        text: "Please Select Consignee",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    if (this.isDestView) {
                        if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                            this.searchLrRptDto.destination = this.selectedDestination;
                            this.searchLrRptDto.mode = 'ConsigneeSpecific';
                            this.searchMethodGetDetails();

                        } else {
                            swal({
                                title: "Mandatory Field",
                                text: "Please select Destination",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                    } else {
                        this.searchLrRptDto.mode = 'ConsigneeSpecific';
                        this.searchMethodGetDetails();
                    }
                }



            }
            //Consignorview
            else if (this.selectedViewBy == 'consignorView') {
                console.log(this.isDestView, this.selectedDestination);

                this.searchLrRptDto.destination = this.userDataDtoReturnSession.mainStation;

                if (this.searchLrRptDto.consignorId == 0) {

                    swal({
                        title: "Mandatory Field",
                        text: "Please select Consignor",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    if (this.isDestView) {
                        if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                            this.searchLrRptDto.destination = this.selectedDestination;
                            this.searchLrRptDto.mode = 'ConsignorSpecific';
                            this.searchMethodGetDetails();
                        } else {
                            swal({
                                title: "Mandatory Field",
                                text: "Please select Destination",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });

                        }
                    } else {
                        this.searchLrRptDto.mode = 'ConsignorSpecific';
                        this.searchMethodGetDetails();
                    }
                }


            }

        } else {
            swal({
                title: "Mandatory Field",
                text: "From Date & To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    searchMethodGetDetails = () => {
        this.showSpinnerForAction = true;
        console.log(this.searchLrRptDto);
        this.lrReportService.getLrDetailsForMultiplePrt(this.searchLrRptDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "lrRptTable").DataTable().destroy();
                this.lrReportDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrReportDataList = response;
                    console.log(this.lrReportDataList);
                }
                this.dtTriggerLrReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };

    clearMethod() {
        $("#" + this.pageId + "lrRptTable").DataTable().destroy();
        this.lrReportDataList = [];
        this.dtTriggerLrReport.next();
        this.searchLrRptDto = new LRDto();
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "consignorGSTNumber").val('');
        $("#" + this.pageId + "consigneeGSTNumber").val('');
        this.fromDateModel = null;
        this.toDateModel = null;
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        $("#" + this.pageId + "viewBy").val('sourceView');
        this.setSource = [];
        this.selectedConsigneeName = '';
        this.selectedConsignorName = '';
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedConsignorGSTNumber = '';
        this.selectedConsigneeGSTNumber = '';
        this.selectedViewBy = '';
        this.selectedDestination = '';
        this.selectedSource = '';
        this.selectedConsigneeId = '';
        this.selectedConsignorId = '';
        this.viewDateWise = true;
        this.viewConsigneeWise = false;
        this.viewConsignorWise = false;
        this.showDest = false;
        this.showSource = false;
        this.modelConsigneeName = '';
        this.modelConsignorName = '';
        this.consignorNameTA = [];
        this.modelConsigneeNameId = '';
        this.modelConsignorNameId = '';
        this.modelDestinationFotGet = '';

        if (this.isDestView) {
            this.showDest = true;
            this.consigneeNameTA = [];
        } else {
            this.consigneeNameTA = this.consigneeNameTATemp;
            $("#" + this.pageId + "consigneeName").val('All');
        }
        if (this.isSourceView) {
            this.showSource = true;
        }
        if (this.isSourceBkgView) {
            this.showSource = true;
        }
        this.showSpinnerForAction = false;
    }



    customPrintLrRpt() {
        if (this.lrReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Agent Name", "Consignee", "Consignor", "Art", "Act Wt", "Chg wt", "Base Freight", "Hamali", "AOC", "Lc Chg", "Bc Chg", "Insurance", "Gc Chg", "Others", "DD Amt", "To Pay", "Paid"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [9, 6, 10, 9, 4, 5, 4, 6, 5, 4, 4, 4, 4, 6, 5, 4, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.artSummary = this.actWtSummary = this.chgWtSummary = this.baseFreightSummary = this.hamaliSummary = this.aocSummary = this.lcChgSummary = this.bcChgSummary = this.insuranceSummary = this.gcChgSummary = this.othersSummary = this.ddAmtSummary = this.toPaySummary = this.paidSummary = 0;

            for (let i = 0; i < this.lrReportDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.lrReportDataList[i].lrNumber, this.lrReportDataList[i].agentName, this.lrReportDataList[i].consigneeName, this.lrReportDataList[i].consignorName,
                this.lrReportDataList[i].totalArticles, this.lrReportDataList[i].actualWeight,
                this.lrReportDataList[i].chargedWeight, this.lrReportDataList[i].baseFreight, this.lrReportDataList[i].hamali, this.lrReportDataList[i].aoc, this.lrReportDataList[i].lcChg, this.lrReportDataList[i].bcChg, this.lrReportDataList[i].insurance, this.lrReportDataList[i].gcCharge, this.lrReportDataList[i].others, this.lrReportDataList[i].ddAmt, this.lrReportDataList[i].toPay, this.lrReportDataList[i].paid];
                /////////

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.artSummary = this.artSummary + this.lrReportDataList[i].totalArticles;
                this.actWtSummary = this.actWtSummary + this.lrReportDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.lrReportDataList[i].chargedWeight;
                this.baseFreightSummary = this.baseFreightSummary + this.lrReportDataList[i].baseFreight;
                this.hamaliSummary = this.hamaliSummary + this.lrReportDataList[i].hamali;
                this.aocSummary = this.aocSummary + this.lrReportDataList[i].aoc;
                this.lcChgSummary = this.lcChgSummary + this.lrReportDataList[i].lcChg;
                this.bcChgSummary = this.bcChgSummary + this.lrReportDataList[i].bcChg;
                this.insuranceSummary = this.insuranceSummary + this.lrReportDataList[i].insurance;
                this.gcChgSummary = this.gcChgSummary + this.lrReportDataList[i].gcCharge;
                this.othersSummary = this.othersSummary + this.lrReportDataList[i].others;
                this.ddAmtSummary = this.ddAmtSummary + this.lrReportDataList[i].ddAmt;
                this.toPaySummary = this.toPaySummary + this.lrReportDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.lrReportDataList[i].paid;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrReportDataList.length, "", "", "", this.artSummary, this.actWtSummary, this.chgWtSummary, this.baseFreightSummary, this.hamaliSummary, this.aocSummary, this.lcChgSummary, this.bcChgSummary, this.insuranceSummary, this.gcChgSummary, this.othersSummary, this.ddAmtSummary, this.toPaySummary, this.paidSummary];
            }


            //heading logics For Date


            //  this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingV3 = [];
            this.cashMemoDtoForCustomPrintListHeadingV4 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];




            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLrRptDto.fromDate, this.searchLrRptDto.toDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            //this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
            //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
            //this.cashMemoDtoForCustomPrint = new CashMemoDto();
            //this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
            //this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            //	}

            //this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
            //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
            //	this.cashMemoDtoForCustomPrint = new CashMemoDto();
            //	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
            //	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
            //}

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "LR Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });



        }
    }
}
