import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    listOfDataToPass: string[];
}

@Component({
    selector: 'app-pod-info-popup',
    templateUrl: './pod-info-popup.component.html',
    styleUrls: ['./pod-info-popup.component.css']
})
export class PodInfoPopupComponent implements OnInit {

    userDataDtoReturnSession: any;
    listOfDataToGet: any;
    pageId = "podc";
    signatureImg: any;
    podDoneBy: any;
    podDoneAt: any;

    constructor(public changeDetectorRef: ChangeDetectorRef, public dialogRef: MatDialogRef<PodInfoPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.listOfDataToGet = this.data.listOfDataToPass;
        this.setPodDetail();
    }


    ngOnInit(): void {

    }

    setPodDetail() {
        console.log('inside pod page');
        console.log(this.listOfDataToGet.podDoneBy);
        console.log(this.listOfDataToGet.podDoneAtStr);
        this.signatureImg = this.listOfDataToGet.viewPod;
        this.podDoneBy = this.listOfDataToGet.podDoneBy;
        this.podDoneAt = this.listOfDataToGet.podDoneAtStr;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}
