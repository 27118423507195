
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
@Component({
    selector: 'app-invoice-summary-print',
    templateUrl: './invoice-summary-print.component.html',
    styleUrls: ['./invoice-summary-print.component.css']
})
export class InvoiceSummaryPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    address: any;
    summaryLRDto: LRDto = new LRDto();
    listOfLrDto: LRDto[] = [];
    articles: any = 0;
    totalLrs: any = 0;
    actWt: any = 0;
    chgWt: any = 0;
    toPay: any = 0;
    paid: any = 0;
    servTax: any = 0;
    goodsValue: any=0;
    lessAmt: any = 0;
    riskChg: any = 0;
    listOfInvoiceSummaryDetailsData: any = [];
    vehicleNumber: any;
    invoiceNumber: any;
    fromStation: any;
    toStation: any;
    invoiceDate: any;
    totalCounts: any;
    loadedBy: any;
    pageId="ispc";
    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.summaryLRDto = JSON.parse(localStorage.getItem('invoiceSummaryForLRDtoPrint'));
            this.listOfLrDto = JSON.parse(localStorage.getItem('invoiceSummaryForLRDtoListPrint'));
            console.log("HI-Summary")
            console.log(this.summaryLRDto);
            console.log(this.listOfLrDto);
            if (this.listOfLrDto != null &&
                this.listOfLrDto != undefined &&
                this.listOfLrDto.length > 0) {

                this.vehicleNumber = this.listOfLrDto[0].vehicleNumber;
                this.totalCounts = this.listOfLrDto.length;
                this.loadedBy = this.listOfLrDto[0].loaderName;
            }

            if (this.summaryLRDto != null && this.summaryLRDto != undefined) {
                this.invoiceNumber = this.summaryLRDto.invoiceNumber;
                this.fromStation = this.summaryLRDto.fromStation;
                this.toStation = this.summaryLRDto.toStation;
                this.invoiceDate = moment(this.summaryLRDto.invoiceDate).format('DD-MM-YYYY');
            }

            this.listOfInvoiceSummaryDetailsData = [];
            if (this.listOfLrDto != null && this.listOfLrDto != undefined && this.listOfLrDto.length > 0) {
                for (let i = 0; i < this.listOfLrDto.length; i++) {
                    this.articles = +this.articles + +this.listOfLrDto[i].actualArt;
                    this.totalLrs = +this.totalLrs + +this.listOfLrDto[i].lrNumber;
                    this.actWt = +this.actWt + +this.listOfLrDto[i].actualWeight;
                    this.chgWt = +this.chgWt + +this.listOfLrDto[i].chargedWeight;
                    this.toPay = +this.toPay + +this.listOfLrDto[i].toPay;
                    this.paid = +this.paid + +this.listOfLrDto[i].paid;
                    this.servTax = +this.servTax + +this.listOfLrDto[i].serviceTax;
                    this.goodsValue = +this.goodsValue + +this.listOfLrDto[i].goodsValue;
                    this.lessAmt = +this.lessAmt + +this.listOfLrDto[i].discountAmount;
                    this.riskChg = +this.riskChg + +this.listOfLrDto[i].riskCharge;
                    this.listOfInvoiceSummaryDetailsData.push(this.listOfLrDto[i]);
                }
            }
            console.log("HAI-Invoice Summary");
            console.log(this.listOfInvoiceSummaryDetailsData);
            window.setTimeout(function () {
                window.print();
            }, 0);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });

        }
    }

    ngOnInit() {

    }




    validatBeforePrint() {
        
    }

    clearField() {
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
