<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card ">
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-2 ">
						<div class="card-body">
							<div class="row">
								<!-- 											the first autocomplete starts -->
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}truckCapacity">
												<!-- 												<label>Truck Capacity</label> -->
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-weight"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder="Select Truck Capacity"
													[formControl]="controlTruckCapacity"
													[appAutocomplete]="autocompleteTruckCapacity">
											</div>
										</div>
									</div>
									<app-autocomplete #autocompleteTruckCapacity="appAutocomplete">
									<ng-template appAutocompleteContent> <ng-container
										*ngIf="(truckCapacityOptions | filter: controlTruckCapacity.value) as resultTruckCapacity">
									<app-option *ngFor="let option of resultTruckCapacity"
										[value]="option.label"> {{ option.label }} </app-option> <app-option
										class="no-result" *ngIf="!resultTruckCapacity.length">No
									result</app-option> </ng-container> </ng-template> </app-autocomplete>
								</div>
								<!-- 											the first autocomplete ends -->
								<!-- 											the second autocomplete starts -->
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}bins">
												<!-- 												<label>Bins</label> -->
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-search"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder="Select Bins" [formControl]="controlBins"
													[appAutocomplete]="autocompleteBins">
											</div>
										</div>
									</div>
									<app-autocomplete #autocompleteBins="appAutocomplete">
									<ng-template appAutocompleteContent> <ng-container
										*ngIf="(binsOptions | filter: controlBins.value) as resultBins">
									<app-option *ngFor="let option of resultBins"
										[value]="option.label"> {{ option.label }} </app-option> <app-option
										class="no-result" *ngIf="!resultBins.length">No
									result</app-option> </ng-container> </ng-template> </app-autocomplete>
								</div>
								<!-- 											the second autocomplete ends -->

								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}lrNumber">
											<!-- 											<label>LR Number</label> -->
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-clipboard-list"></i>
												</span>
											</div>
											<input type="text" Placeholder="LR Number"
												class="form-control" aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
							</div>


						</div>
					</div>








					<div class="col-md-10 vl">
						<div class="card-body">


							<div class="row">
								<div class="col-md-6">
									<div class="box-body">
										<div class="row">
											<div class="col-sm-12 col-md-8">
												<h6 class="card-title">Eligible LRs</h6>
											</div>
											<div class="col-sm-12 col-md-4" style="text-align: right;">
												<button class="btn btn-primary m-r-10"
													style="padding: 1px 4px;" id="{{pageId}}clearAllBtn">Clear
													All</button>
											</div>
										</div>
										<!-- 							<the first datatable starts  -->
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsEligibleLrs"
											[dtTrigger]="dtTriggerEligibleLrs">

											<thead>
												<tr>
													<th>E-Way Bill Status</th>
													<th>E-Way Bill No</th>
													<th>LR Number</th>
													<th>Articles</th>
													<th>Act Wgt</th>
													<th>Chg Wgt</th>
													<th>To Pay</th>
													<th>Paid</th>
													<th>Goods Value</th>
													<th>Booking Date</th>
													<th>Consignee</th>
													<th>Destination</th>
												</tr>
											</thead>
											<tbody class='dragndrop' [dragula]='"first-bag"'>
												<tr *ngFor="let eligibleLrsData of eligibleLrsDataList ">
													<td>{{ eligibleLrsData.name }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
													<td>{{ eligibleLrsData.grade }}</td>
												</tr>


												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>
										</table>
									</div>
								</div>

								<!-- 							the first datatable ends  -->






								<!-- 							<the second datatable starts  -->
								<div class="col-md-6 vl">
									<div class="box-body">
										<div class="row">
											<div class="col-sm-12 col-md-8">
												<h6 class="card-title">Remaining LRs</h6>
											</div>
											<div class="col-sm-12 col-md-4" style="text-align: right;">
												<button class="btn btn-primary m-r-10"
													style="padding: 1px 4px;" id="{{pageId}}resetBtn">Reset</button>
											</div>
										</div>
										<table datatable
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionRemainingLrs"
											[dtTrigger]="dtTriggerRemainingLrs">
											<thead>
												<tr>
													<th>LR Number</th>
													<th>Articles</th>
													<th>Actual Weight</th>
													<th>Charged Weight</th>
													<th>To Pay</th>
													<th>Paid</th>
													<th>Goods Value</th>
													<th>Consignee</th>
													<th>Destination</th>
													<th>Booking Date</th>
												</tr>
											</thead>
											<tbody class='dragndrop' [dragula]='"first-bag"'>

												<tr *ngFor="let remainingLrsData of remainingLrsDataList ">
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.position }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
													<td>{{ remainingLrsData.name }}</td>
												</tr>

												<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

											</tbody>

										</table>
									</div>
								</div>
								<!--							 the second datatable ends  -->


							</div>
						</div>
					</div>











					<!--  to open  popup use this << (click)="openPopup(content)" >>  -->
					<!-- Row -->
					<div class="col-md-12">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}popupDetailsTwo">
								<ng-template #content let-save="close" let-cancel="close"
									let-d="dismiss">


								<div class="modal-header">
									<h5>Add New Truck Capacity</h5>
									<button type="button" class="close" aria-label="Close"
										(click)="d('Cross click')">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div class="modal-body">
									<div class="row" style="text-align: center;">
										<div class="col-sm-12 col-md-12">
											<h5>Add Truck Capacity</h5>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}truckCapacityPopup">
													<label>Truc Capacity (Tons)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-clipboard-list"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-md-12 p-t-10">
											<button type="button" class="btn btn-outline-secondary"
												(click)="save('Save click')">Save</button>
											<button type="button" class="btn btn-outline-danger"
												(click)="cancle('Cancel click')">Cancel</button>

										</div>
									</div>
								</div>
								</ng-template>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>

</html>