<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-md-12">
        <div class="row">
            <div *ngIf="viewDestination" class="col-sm-12 col-md-2">
                <div class="control">
                    <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                        <label class="label_custome">Destination</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i
                                                                class="fas fa-user"></i>
                                                            </span>
                            </div>
                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="consigneeDestListener($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-2">
                <div class="control">
                    <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                        <label>Consignee</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i
                                                    class="fas fa-user"></i>
                                                </span>
                            </div>
                            <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)"
                                [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-2">
                <div class="control">
                    <div class="form-group" id="{{pageId}}scrollable-dropdown-menu" style="position: static;">
                        <label></label>
                        <div class="input-group">
                            <div class="input-group-prepend"></div>
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Get Details</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
        <div class="form-group">
            <div class="input-group">
                <mat-progress-bar mode="indeterminate" style="color: green;">
                </mat-progress-bar>
                <br>
                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                    Please Wait Loading Details.....</h6>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
            <!-- column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}partyWiseTotalStatusDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPartyWiseTotalStatusDetails" [dtTrigger]="dtTriggerPartyWiseTotalStatusDetails">
                                        <thead>
                                            <tr>
                                                <th>GST Number</th>
                                                <th>Address</th>
                                                <th>Pincode</th>
                                                <th>Contact Number</th>
                                                <th>Contact Person</th>
                                                <th>Mobile</th>
                                                <th>Rate</th>
                                                <th>OS Amount</th>
                                                <th>Left Amount</th>
                                                <th>OS LR Stocks</th>
                                                <th>Transit Lrs</th>
                                                <th>Business</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partyWiseTotalStatusDetailsData of partyWiseTotalStatusDetailsDataList  ">
                                                <td>{{ partyWiseTotalStatusDetailsData.gstNoConsignee }}</td>
                                                <td>{{ partyWiseTotalStatusDetailsData.address }}</td>
                                                <td>{{ partyWiseTotalStatusDetailsData.pincode }}</td>
                                                <td>{{ partyWiseTotalStatusDetailsData.officeNumber }}
                                                </td>
                                                <td>{{ partyWiseTotalStatusDetailsData.contactPerson }}</td>
                                                <td>{{ partyWiseTotalStatusDetailsData.mobileNumber }}
                                                </td>
                                                <td><a class="pointer" style="color: blue;" (click)="getRateHistory();"><u><strong>Rate</strong></u></a></td>
                                                <td><a (click)="getPartyWiseOsAmtRpt();" class="pointer" style="color: blue;"><u><strong>{{
																				partyWiseTotalStatusDetailsData.grandTotal==null ? 0
																				: partyWiseTotalStatusDetailsData.grandTotal }}</strong></u></a></td>
                                                <td>{{ partyWiseTotalStatusDetailsData.leftamount }}
                                                </td>
                                                <td><a (click)="getGodownStockRpt();" class="pointer" style="color: blue;"><u><strong>{{
																			partyWiseTotalStatusDetailsData.totalLrs ==null ?0
																			:partyWiseTotalStatusDetailsData.totalLrs}}</strong></u></a></td>
                                                <td><a (click)="getTransitLrsRpt();" class="pointer" style="color: blue;"><u><strong>{{ partyWiseTotalStatusDetailsData.transitLrs==null ? 0 : partyWiseTotalStatusDetailsData.transitLrs
																	}}</strong></u></a></td>
                                                <td><a (click)="businessDetailsSearchMethod();" class="pointer" style="color: blue;"><u><strong>Business</strong></u></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- Rate History Rpt -->
                                <div [hidden]="!partyWiseRateTable" class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                                    <h6 class="card-title text-white">Rate Details</h6>
                                </div>
                                <div class="box-body" [hidden]="!partyWiseRateTable">
                                    <table datatable id="{{pageId}}partyWiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateHistoryReportPartyWise" [dtTrigger]="dtTriggerRateHistoryReportPartyWise">
                                        <thead>
                                            <tr>
                                                <th>Unit</th>
                                                <th>Des.Ham</th>
                                                <th>Rate</th>
                                                <th>R.C</th>
                                                <th>S.C</th>
                                                <th>S.Ham</th>
                                                <th>G.C</th>
                                                <th>P Unit</th>
                                                <th>Commodity</th>
                                                <th>Fixed By</th>
                                                <th>Effect From</th>
                                                <th>Effect To</th>
                                                <th>Source</th>
                                                <th>LC</th>
                                                <th>S.Ham</th>
                                                <th>BC</th>
                                                <th>AOC</th>
                                                <th>FOV</th>
                                                <th>DD</th>
                                                <th>Others</th>
                                                <th>Group By</th>
                                                <th style="display:none;">Fixed On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rateHistoryReportPartyWiseData of rateHistoryReportPartyWiseDataList ">
                                                <td>{{ rateHistoryReportPartyWiseData.column2 }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.hamaliDestination }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.rate }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.receiptCharges }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.serviceCharges }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.hamaliSource }}
                                                </td>
                                                <td>{{ rateHistoryReportPartyWiseData.gcCharge }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.perUnit== null ? "NA": rateHistoryReportPartyWiseData.perUnit== "" ? "NA":rateHistoryReportPartyWiseData.perUnit}}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.commodityName }}
                                                </td>
                                                <td>{{ rateHistoryReportPartyWiseData.fixedBy }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.effectiveFromDateStg}}
                                                </td>
                                                <td>{{ rateHistoryReportPartyWiseData.effectiveToDateSrg== null ? "NA": rateHistoryReportPartyWiseData.effectiveToDateSrg== "" ? "NA":rateHistoryReportPartyWiseData.effectiveToDateSrg}}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.sourceCode }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.lcChg == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.hamaliChg == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.bcChg == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.aoc == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.riskCharge == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.dd == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.others == true ?'Yes': 'No' }}</td>
                                                <td>{{ rateHistoryReportPartyWiseData.groupBy }}</td>
                                                <td style="display:none;">{{ rateHistoryReportPartyWiseData.column3 }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="display:none;"></td>
                                            </tr>

                                        </tfoot>
                                    </table>
                                </div>
                                <!-- Party wise os details -->
                                <div [hidden]="!partywiseOSDetailTable" class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                                    <h6 class="card-title text-white">Outstanding Memo Details</h6>
                                </div>
                                <div class="box-body" [hidden]="!partywiseOSDetailTable">
                                    <table datatable id="{{pageId}}detailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionPartywiseOSDetail" [dtTrigger]="dtTriggerPartywiseOSDetail">
                                        <thead>
                                            <tr>
                                                <th>Party</th>
                                                <th>Memo Number</th>
                                                <th>LR No</th>
                                                <th>Memo Amount</th>
                                                <th>Booking Date</th>
                                                <th>No Of Articles</th>
                                                <th>Memo Date</th>
                                                <th>Cur Date - Memo Date</th>
                                                <th>Area</th>
                                                <th>Collection Man</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detailData of detailDataPartywiseOSList ">
                                                <td>{{ detailData.consignee }}</td>
                                                <td>{{ detailData.memoNumber }}</td>
                                                <td>{{ detailData.lrNumber }}</td>
                                                <td>{{ detailData.amount }}</td>
                                                <td>{{ detailData.bookingDateStr }}</td>
                                                <td>{{ detailData.article }}</td>
                                                <td>{{ detailData.memoDateStr }}</td>
                                                <td>{{ detailData.days }}</td>
                                                <td>{{ detailData.area }}</td>
                                                <td>{{ detailData.collectionMan }}</td>
                                                <td>{{ detailData.status }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <!-- Transit Lrs -->
                                <div [hidden]="!partywiseTransitLrsDetailTable" class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                                    <h6 class="card-title text-white">Transit LR Details</h6>
                                </div>
                                <div class="box-body" [hidden]="!partywiseTransitLrsDetailTable">
                                    <table datatable id="{{pageId}}transitLrsDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTransitLrsDetailsDetailsReport" [dtTrigger]="dtTriggerTransitLrsDetailsReport">
                                        <thead>
                                            <tr>
                                                <th>Lr Number</th>
                                                <th>Booking Date</th>
                                                <th>Source</th>
                                                <th>Consignor Name</th>
                                                <th>Articles</th>
                                                <th>To Pay</th>
                                                <th>Paid</th>
                                                <th>Actual Weight</th>
                                                <th>Charged Weight</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let transitLrsDetailsReportData of transitLrsDetailsReportDataList  ">
                                                <td>{{transitLrsDetailsReportData.lrNumber }}</td>
                                                <td>{{ transitLrsDetailsReportData.bookingDateStr }}
                                                </td>
                                                <td>{{ transitLrsDetailsReportData.fromStation }}</td>
                                                <td>{{transitLrsDetailsReportData.consignorName }}</td>
                                                <td>{{ transitLrsDetailsReportData.totalArticles }}</td>
                                                <td>{{ transitLrsDetailsReportData.topay }}</td>
                                                <td>{{transitLrsDetailsReportData.paid }}</td>
                                                <td>{{ transitLrsDetailsReportData.actWeight }}</td>
                                                <td>{{ transitLrsDetailsReportData.chargedwt }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <!-- Stock Lrs -->
                                <div [hidden]="!godownStocksDetailsReportTable" class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                                    <h6 class="card-title text-white">Transit LR Details</h6>
                                </div>
                                <div class="box-body" [hidden]="!godownStocksDetailsReportTable">
                                    <table datatable id="{{pageId}}godownStocksDetailsReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionGodownStocksDetailsReport" [dtTrigger]="dtTriggerGodownStocksDetailsReport">
                                        <thead>
                                            <tr>
                                                <th>LR No</th>
                                                <th>Booking Date</th>
                                                <th>Source</th>
                                                <th>Agent</th>
                                                <th>Art</th>
                                                <th>Desc</th>
                                                <th>ToPay</th>
                                                <th>Paid</th>
                                                <th>ActWt</th>
                                                <th>ChgWt</th>
                                                <th>Destination</th>
                                                <th>Consignor</th>
                                                <th>Consignee</th>
                                                <th>Area</th>
                                                <th>Sub Station</th>
                                                <th>Del Type</th>
                                                <th>DiffInDate(Bkg-Arr)</th>
                                                <th>DiffInDate(Cur-Arr)</th>
                                                <th>Godown</th>
                                                <th [hidden]=true>E-Way Bill No</th>
                                                <th [hidden]=true>E-way Bill Valid Upto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let godownStocksDetailsReportData of godownStocksDetailsReportDataList ">
                                                <td>{{ godownStocksDetailsReportData.lrNumber }}</td>
                                                <td>{{ godownStocksDetailsReportData.bookingDateStr }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.source }}</td>
                                                <td>{{ godownStocksDetailsReportData.agentName }}</td>
                                                <td>{{ godownStocksDetailsReportData.totalArticles }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.description }}</td>
                                                <td>{{ godownStocksDetailsReportData.toPay }}</td>
                                                <td>{{ godownStocksDetailsReportData.paid }}</td>
                                                <td>{{ godownStocksDetailsReportData.actualWeight }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.chargedWeight }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.destination }}</td>
                                                <td>{{ godownStocksDetailsReportData.consignorName }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.consigneeName }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.areaOfDelivery }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.subStations }}</td>

                                                <td>{{ godownStocksDetailsReportData.deliveryType }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.dispatchedwithin }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.arrivalwithin }}
                                                </td>
                                                <td>{{ godownStocksDetailsReportData.godownName }}</td>
                                                <td [hidden]=true>{{ godownStocksDetailsReportData.eWayBillNo }}</td>
                                                <td [hidden]=true>{{ godownStocksDetailsReportData.ewayBillValidUptoStr }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td [hidden]=true></td>
                                                <td [hidden]=true></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                                <!-- Business Lrs -->
                                <div [hidden]="!consigneeBusinessDetailsReportTable" class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                                    <h6 class="card-title text-white">Business Details</h6>
                                </div>
                                <div class="col-md-12" [hidden]="!consigneeBusinessDetailsReportTable" style="margin-top: 5px;">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDate.toggle()">
                                                        <span class="input-group-text"> <i
                                                        class="fa fa-calendar"></i>
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-3">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDate.toggle()">
                                                        <span class="input-group-text"> <i
                                                        class="fa fa-calendar"></i>
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-2">
                                            <button type="submit" class="dt-button" id="{{pageId}}searchBusinessBtn" (click)="businessDetailsSearchDatewiseMethod()">
										<span><i>Search</i></span>
									</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-body" [hidden]="!consigneeBusinessDetailsReportTable">
                                    <div>
                                        <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}businessDetailsReportTableId" [dtOptions]="dtOptionBusinessDetail" [dtTrigger]="dtTriggerBusinessDetail">
                                            <thead>
                                                <tr>
                                                    <th [hidden]=true>Source</th>
                                                    <th>LR Number</th>
                                                    <th>Booking Date</th>
                                                    <th>Invoice No</th>
                                                    <th>Consignee Name</th>
                                                    <th>Consignor Name</th>
                                                    <th>Consignee GST</th>
                                                    <th>Commodity Name</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                    <th>Article</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>LC</th>
                                                    <th>BC</th>
                                                    <th>CashMemo Amt</th>
                                                    <th>Unloading At</th>
                                                    <th>Arr Wtn</th>
                                                    <th>Delivery Date</th>
                                                    <th>Del Wtn</th>
                                                    <th>AOC</th>
                                                    <th>Goods Value</th>
                                                    <th>F O V</th>
                                                    <th>Is Rate Set</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let detailData of businessDetailDataList">
                                                    <td [hidden]=true>{{ detailData.agentId }}</td>
                                                    <td>{{ detailData.lrNumber }}</td>
                                                    <td>{{ detailData.bookingDateStr }}</td>
                                                    <td>{{ detailData.invoiceNumber }}</td>
                                                    <td>{{ detailData.consigneeName }}</td>
                                                    <td>{{ detailData.consignorName }}</td>
                                                    <td>{{ detailData.gstNoConsignee }}</td>
                                                    <td>{{ detailData.commodityName }}</td>
                                                    <td>{{ detailData.toPay }}</td>
                                                    <td>{{ detailData.paid }}</td>
                                                    <td>{{ detailData.totalArticles }}</td>
                                                    <td>{{ detailData.actualWeight }}</td>
                                                    <td>{{ detailData.chargedWeight }}</td>
                                                    <td>{{ detailData.lcChg }}</td>
                                                    <td>{{ detailData.bcChg }}</td>
                                                    <td>{{ detailData.cashAmt }}</td>
                                                    <td>{{ detailData.arrivalDateStr }}</td>
                                                    <td>{{ detailData.arrivalwithin }}</td>
                                                    <td>{{ detailData.deliveryDateStr }}</td>
                                                    <td>{{ detailData.deliveredwithin }}</td>
                                                    <td>{{ detailData.aoc }}</td>
                                                    <td>{{ detailData.goodsValue }}</td>
                                                    <td>{{ detailData.riskCharge }}</td>
                                                    <td>{{ detailData.isAdminRateSet == true? "Yes" : "No" }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>

    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>