<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLRPerfRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">LR Performance Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<!-- spinner start-->
					<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;">
								</mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<!-- spinner end-->
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates"
															ngbDatepicker #fromDates="ngbDatepicker">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates" class="form-control"
															placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
															#toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12" *ngIf="viewDestinationField">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text" class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}partyName">
														<label>Party Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #consigneeName id="{{pageId}}consigneeName" name="consigneeName"
															type="text" class="form-control"
															placeholder="Select Consignee Name"
															(selectItem)="consigneeDropDownListner($event)"
															[(ngModel)]="modelConsigneeName"
															[ngbTypeahead]="consigneeNameSearchTA"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterConsigneeName"
															[inputFormatter]="formatterConsigneeName"
															(focus)="focusConsigneeNameTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>
											</div>


										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="validateSearchBtn();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearBtn();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<button type="submit" class="dt-button" style="margin-left: 92%;"
							(click)="customPrintLRPerformance();" id="{{pageId}}printAllBtn">
							<span><i class="fas fa-print">Print</i></span>
						</button>
						<div class="box-body">
							<table datatable id="{{pageId}}lrPerformanceTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrPerformanceReport" [dtTrigger]="dtTriggerLrPerformanceReport">

								<thead>
									<tr>
										<th>L.R.NO</th>
										<th>Memo NO</th>
										<th>Source</th>
										<th>CNSGNE NAME</th>
										<th>CNSGNR NAME</th>
										<th>ART</th>
										<th>HAMALI GD</th>
										<th>HAMALI DD</th>
										<th>AC. WT</th>
										<th>CH. WT</th>
										<th>BKNG DT</th>
										<th>LDNG DT</th>
										<th>UNLDNG DT</th>
										<th>DISP W.IN</th>
										<th>ARR W.IN</th>
										<th>DELV W.IN</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrPerformanceReportData of lrPerformanceReportDataList ">
										<td>{{ lrPerformanceReportData.lrNumber }}</td>
										<td>{{ lrPerformanceReportData.cashMemoNumber }}</td>
										<td>{{ lrPerformanceReportData.source }}</td>
										<td>{{ lrPerformanceReportData.consigneeName }}</td>
										<td>{{ lrPerformanceReportData.consignorName }}</td>
										<td>{{ lrPerformanceReportData.totalArticles }}</td>
										<td>{{ lrPerformanceReportData.hamaliGD }}</td>
										<td>{{ lrPerformanceReportData.hamaliDD }}</td>
										<td>{{ lrPerformanceReportData.actualWeight }}</td>
										<td>{{ lrPerformanceReportData.chargedWeight }}</td>
										<td>{{ lrPerformanceReportData.bookingDateStr }}</td>
										<td>{{ lrPerformanceReportData.loadingDateStr }}</td>
										<td>{{ lrPerformanceReportData.fromDate }}</td>
										<td>{{ lrPerformanceReportData.dispatchedwithin }}</td>
										<td>{{ lrPerformanceReportData.arrivalwithin }}</td>
										<td>{{ lrPerformanceReportData.deliveredwithin }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>