import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { DragulaModule } from 'ng2-dragula';
import { ConsignorMasterComponent } from 'src/app/master/consignor/consignor-master/consignor-master.component';
import { ConsignorMergeComponent } from 'src/app/master/consignor/consignor-merge/consignor-merge.component';
import { ConsignorMultipleMobileNumberDetailsComponent } from 'src/app/master/consignor/consignor-multiple-mobilenumber-details/consignor-multiple-mobilenumber-details.component';
import { MaterialModule } from "src/app/material.module";
import { ConsignorAdminRateMasterComponent } from './consignor-admin-rate-master/consignor-admin-rate-master.component';
import { UpdateConsignorRateForAllComponent } from 'src/app/master/consignor/update-consignor-rate-for-all/update-consignor-rate-for-all.component';
import { ConsignorMasterTopartyComponent } from './consignor-master-toparty/consignor-master-toparty.component';
import { ConsignorMobilenumberConfirmComponent } from './consignor-mobilenumber-confirm/consignor-mobilenumber-confirm.component';
import { UpdateConsignorRateForAllPartyComponent } from './update-consignor-rate-for-all-party/update-consignor-rate-for-all-party.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( ConsignorRoutes ),
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, DragulaModule, MaterialModule],
    declarations: [
        ConsignorMasterComponent,
        ConsignorMergeComponent,
        ConsignorMultipleMobileNumberDetailsComponent, ConsignorAdminRateMasterComponent,
        UpdateConsignorRateForAllComponent, ConsignorMasterTopartyComponent, ConsignorMobilenumberConfirmComponent,UpdateConsignorRateForAllPartyComponent],
    entryComponents: [ConsignorMultipleMobileNumberDetailsComponent, UpdateConsignorRateForAllComponent,
        UpdateConsignorRateForAllPartyComponent
    ]
})
export class ConsignorModule { }
