<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">

        <div>
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                    <tr>
                        <td style="padding-top: 10px;" width='40%' valign='top'>
                            <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                            <br> {{address}}
                        </td>

                        <td width='60%' valign='top'>
                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>Truck No : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{vehicleNumber}} </strong>
                                                </td>
                                                <td width='20%'><strong>Supplier : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{supplierName}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr><br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>Invoice No : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{invoiceNum}} </strong>
                                                </td>
                                                <td width='20%'><strong>Truck Type : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{goodsType}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr><br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='20%'><strong>From : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{fromStation}} </strong>
                                                </td>
                                                <td width='20%'><strong>To : </strong></td>
                                                <td width='30%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{toStation}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr><br>
                                <tr>
                                    <td>
                                        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                            <tr>
                                                <td width='15%'><strong>Dated : </strong></td>
                                                <td width='15%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{invoiceDate}} </strong>
                                                </td>
                                                <td width='15%'><strong>Gur.Wgt : </strong></td>
                                                <td width='15%' style='border-bottom:1px solid #000;'><strong>{{gurWgt}}
                                                    </strong></td>
                                                <td width='15%'><strong>Kanta Wgt : </strong></td>
                                                <td width='15%' style='border-bottom:1px solid #000;'>
                                                    <strong>{{kantaWgt}} </strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <h6 style='text-align: center;font-size: large;'>
                    <strong><u>Booking Invoice</u></strong>
                </h6>
            </div>
        </div>
        <br>
        <!--Second  row starts-->
        <table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
            <tr>
                <td width='9%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;' valign='top' type='text'><strong> Delivery Area</strong></td>
                <td width='9%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='number'><strong> LR No</strong></td>
                <td width='3%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='number'><strong> Art</strong></td>
                <td width='9%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong> Booking Date</strong></td>
                <td width='11%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong> Booked From</strong></td>
                <td width='12%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong> Desc</strong></td>
                <td width='12%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>Consignee</strong></td>
                <td width='5%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>Topay</strong></td>
                <td width='5%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>Paid</strong></td>
                <td width='5%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>FOV</strong></td>
                <td width='5%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>AW</strong></td>
                <td width='5%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;' valign='top' type='text'><strong>CW</strong></td>
                <!-- <td width='7%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;' valign='top' type='text'><strong>Goods Value</strong></td> -->
                <td width='4%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;' valign='top' type='text'><strong>e-Inv</strong></td>
                <td width='9%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;' valign='top' type='text'><strong> Note **</strong></td>
            </tr>
            <ng-container *ngFor="let item of result">
                <tr>
                    <td colspan="12" style='border: 1px solid #000;'>
                        <strong>{{item.deliveryPoints}}</strong>
                    </td>
                </tr>
                <tr *ngFor="let value of item.values" [ngClass]="{'hcRowHighlight': value.isHandWithCarePrint||value.isNewConsignee}">
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000;border-left: 1px solid #000; word-wrap: break-word;'>
                        {{value.areaOfDelivery}}</td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.lrNumber}}</td>
                    <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.totalArticles}}</td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.bookingDateStr}}</td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.bookedFrom}}</td>
                    <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.description}}</td>
                    <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.consigneeName}}</td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.toPay}}</td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.paid}}</td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.riskCharge}}</td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.actualWeight}}</td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.chargedWeight}}</td>
                    <!-- <td width='7%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;'>
                        {{value.goodsValue}}</td> -->
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;'>
                        {{value.consignorEInvoiceEnable}}</td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        
                        <!-- <span>{{value.isHandWithCareStr}}</span> -->
                        <span>{{value.isHandWithCare}}</span>
                        <span>{{value.isBankLr}} </span>
                        <span>{{value.selfLrStr}}</span>
                    </td>
                </tr>
                <tr>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <strong> Total </strong>
                    </td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.totalArticles}}</strong>
                    </td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.totalToPay}}</strong>
                    </td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.totalPaid}}</strong>
                    </td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.totalFov}}</strong>
                    </td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.actualWeight}}</strong>
                    </td>
                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong>{{item.chargedWeight}}</strong>
                    </td>
                    <!-- <td width='7%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;'>
                        <strong>{{item.goodsValue}}</strong>
                    </td> -->
                    <td width='4%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000;'>
                    </td>
                    <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                </tr>
            </ng-container>
            <br><br>
            <tr>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                    <strong> Total </strong>
                </td>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totLrsNet}}</strong>
                </td>
                <td width='3%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totArtNet}}</strong>
                </td>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='11%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='12%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totToPayNet}}</strong>
                </td>
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totPaidNet}}</strong>
                </td>
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totFOVNet}}</strong>
                </td>
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totActWgtNet}}</strong>
                </td>
                <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong>{{totChgWgtNet}}</strong>
                </td>
                <!-- <td width='7%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right:1px solid #000;'>
                    <strong>{{totGoodsValueNet}}</strong>
                </td> -->
                <td width='4%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right:1px solid #000;'>
                </td>
                <td width='9%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
            </tr>
        </table>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-left: 20px; font-size: 17px; padding-top: 30px;'>
                                <!-- <strong>HC-HANDLE WITH CARE, NW PRTY-NEW PARTY, COD </strong> -->
                                <strong>HC-HANDLE WITH CARE, NPSJ-NEW PARTY, COD </strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-right: 15px; font-size: 17px; padding-top: 20px;'>
                                <strong>Loaded By : {{loadedBy}}</strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>