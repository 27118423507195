import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//service starts
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
//we used any keyword so comment
//import { ConsigneedetailsModel } from 'src/app/models/Consigneedetails-model';
//import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { MasterDto } from 'src/app/dto/master-dto';
//import { PartyModel } from "src/app/models/party-model";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterService } from 'src/app/dataService/master-service';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { MasterReadService } from "src/app/dataService/masterread-service";

const httpOptions = {
    headers: new HttpHeaders( {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'my-auth-token'
    } )
};
//service ends
@Component( {
    selector: 'app-debit-note-master',
    templateUrl: './debit-note-master.component.html',
    styleUrls: ['./debit-note-master.component.css']
} )
export class DebitNoteMasterComponent implements OnInit {

    //service starts      
    //    partyModel: PartyModel = new PartyModel();

    private baseUrl = '/api/v1/createconsignee';
    masterDto: MasterDto = new MasterDto();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    //    consigneeDetailsModel: ConsigneedetailsModel = new ConsigneedetailsModel();
    //    service ends

    //    gettingDataFrmServiceFrDebitNoteMasterTable: any;
    debitNoteMasterDataList: any;
    fortNightStmtDtoSaveRet: any;
    //    onDestroyUnsubscribtionDebitNoteMaster: Subscription;
    loadingIndicator = true;
    controlMain = new FormControl();
    newMainHeadView = false;
    subHeadView = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    selectedMainHeadsList = null;
    mainHeadsNew = null;
    subHeads = null;

    mainHeadsOptions = [
        { id: 1, label: 'Add New' },
        { id: 2, label: 'Add Less' },
        { id: 3, label: 'Claims' },
        { id: 4, label: 'Delhi Office' },
        { id: 5, label: 'others' }
    ];
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerDebitNoteMaster: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsDebitNoteMaster: any;
    pageId="dnms";

    constructor( private masterReadService: MasterReadService, private http: HttpClient,
        private router: Router,
        private masterService: MasterService , public changeDetectorRef : ChangeDetectorRef) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.getDebitNoteMasterList();
        }
    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }

    ngOnInit(): void {
        this.dtOptionsDebitNoteMaster = {
            //};
            //columns is used to for export and others starts
            //columns is used to for export and others endss
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [

            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }

        //        this.gettingDataFrmServiceFrDebitNoteMasterTable = this.debitNoteMasterScreen.getSummaryData()
        //        this.onDestroyUnsubscribtionDebitNoteMaster = this.gettingDataFrmServiceFrDebitNoteMasterTable.subscribe( data => {
        //            this.debitNoteMasterDataList = data['data'];
        //            this.dtTriggerDebitNoteMaster.next();
        //        } );

        //the below code is for the getting data through json ends
    }
    ngOnDestroy(): void {
        this.dtTriggerDebitNoteMaster.unsubscribe();

        //        this.onDestroyUnsubscribtionDebitNoteMaster.unsubscribe();
    }

    //  service starts
    //  service starts
    getDebitNoteMasterListRead() {
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.reportMode = "gridDtls";
    }


    getDebitNoteMasterList = () => {
        this.getDebitNoteMasterListRead();
        this.masterReadService.getDebitNoteMaster( this.fortNightStmtDto ).subscribe(
            ( response ) => {
                if ( response ) {
//                    console.log( response );
                    this.debitNoteMasterDataList = response;
                    this.dtTriggerDebitNoteMaster.next();
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Debit Note Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    //    service ends
    //to insert value of selected row in table to input field starts
    //    rowSelected( natureOfPackData ) {
    //        this.selectedName = natureOfPackData.packNature;
    //        this.packId = natureOfPackData.id;
    //        console.log( this.selectedName );
    //    }
    //to insert value of selected row in table to input field ends
    //    service ends

    mainHeads( mainHead: string ) {
        if ( mainHead == 'Add New' ) {
            this.newMainHeadView = true;
            this.subHeadView = false;
            this.fortNightStmtDto.mainHeadNew = null;
            this.fortNightStmtDto.subDebitNew = null;
        } else if
      ( mainHead == 'Others' ) {
            this.newMainHeadView = false;
            this.subHeadView = true;
            this.fortNightStmtDto.mainHeadNew = null;
            this.fortNightStmtDto.subDebitNew = null;
        } else {
            this.newMainHeadView = false;
            this.subHeadView = false;
            this.fortNightStmtDto.mainHeadNew = null;
            this.fortNightStmtDto.subDebitNew = null;
        }
    }

    validateDebitNoteDetails() {
        this.saveUpdateDebitNoteDetails();
    }
    saveDebitNoteDetails() {

        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.userId = this.userDataDtoReturnSession.userId;
        this.selectedMainHeadsList = this.fortNightStmtDto.mainHeadList;
        this.mainHeadsNew = null;
        this.subHeads = null;
        if ( this.newMainHeadView ) {
            this.mainHeadsNew = this.fortNightStmtDto.mainHeadNew;
        }
        if ( this.subHeadView ) {
            this.subHeads = this.fortNightStmtDto.subDebitNew;
        }

        if ( this.selectedMainHeadsList != null && this.selectedMainHeadsList == "Add New" ) {
            if ( this.mainHeadsNew != null ) {
                this.fortNightStmtDto.mainDebitHeads = this.mainHeadsNew;
                this.fortNightStmtDto.subDebitHeads = this.mainHeadsNew;
            } else {
                alert(
                    "New Main Heads Is Mandatory Field" );
            }
        } else if ( this.selectedMainHeadsList != null
            && this.selectedMainHeadsList == "Others" ) {
            if ( this.subHeads != null ) {
                this.fortNightStmtDto.mainDebitHeads = this.selectedMainHeadsList;
                this.fortNightStmtDto.subDebitHeads = this.subHeads;
            } else {
                alert(
                    "Sub Heads Is Mandatory Field" );
            }
        } else if ( this.selectedMainHeadsList == null
            || this.selectedMainHeadsList == "" ) {
            alert(
                "Main Heads Is Mandatory Field" );
        }


    }

    saveUpdateDebitNoteDetails = () => {
        this.saveDebitNoteDetails();
        this.masterService.createDebitNote( this.fortNightStmtDto ).
            subscribe(( data ) => {
                this.fortNightStmtDtoSaveRet = data;
//                console.log( this.fortNightStmtDtoSaveRet.status );
                if ( this.fortNightStmtDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Debit Note Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else if ( this.fortNightStmtDtoSaveRet.status == "data found" ) {
                    swal( {
                        title: "Warning",
                        text: "Duplicate  Debit Note Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
                else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving  Debit Note Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Debit Note Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.mainHeadsNew = null;
        this.subHeads = null;
        this.newMainHeadView = false;
        this.subHeadView = false;
        this.fortNightStmtDto.mainHeadNew = null;
        this.fortNightStmtDto.subDebitNew = null;
        $("#"+this.pageId+"mainHead" ).val( '' );
    }

}
