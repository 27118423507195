///Chg_Ver_ST01_ Imran Chg_ Added a validation that we should allow after 5 mins to update the FT Conscent Status on 12082024


import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject } from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { StockService } from 'src/app/dataService/stock-service';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-scheduled-trips-to',
    templateUrl: './scheduled-trips-to.component.html',
    styleUrls: ['./scheduled-trips-to.component.css']
})
export class ScheduledTripsToComponent implements OnInit {

    scheduledTripsToDataList: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerScheduledTripsTo: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsScheduledTripsTo: any;
    hireSlipDto: HireSlipDto = new HireSlipDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    hireSlipDtoForSelectedRow: HireSlipDto = new HireSlipDto();
    hireSlipDtoForScheduleTripToLocalStorage: any;
    pageId = "scttc";
    requestDto: RequestDto = new RequestDto();
    consolidateEwaybillEnabled: boolean = false;

    constructor(private dashboardService: DashboardService, private router: Router,
        private stockService: StockService, public changeDetectorRef: ChangeDetectorRef, private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGeneration != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGeneration) {
                this.consolidateEwaybillEnabled = true;
            }
        }

    }

    ngOnInit(): void {
        this.dtOptionsScheduledTripsTo = {
            dom: 'Bfrtip',
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerScheduledTripsTo.next();
    }
    // ngOnDestroy(): void {
    //     this.dtTriggerScheduledTripsTo.unsubscribe();

    //     // this.onDestroyUnsubscribtionScheduledTripsTo.unsubscribe();
    // }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    gridReconifgureOnReloadBtn() {
        this.getScheduledTripsToDetailsList();
    }
    getScheduledTripsDetails() {
        this.hireSlipDto = new HireSlipDto();
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.status = "Scheduled";
        this.hireSlipDto.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDto.mode = "BMDB";
        this.hireSlipDto.role = this.userDataDtoReturnSession.role;
        this.hireSlipDto.stocksAt = this.userDataDtoReturnSession.stationType;
        this.hireSlipDto.officeType = this.userDataDtoReturnSession.officeType;
        this.hireSlipDto.type = "Both";
        this.hireSlipDto.fromstation = "Scheduled Trips";
        this.hireSlipDto.stationList = this.userDataDtoReturnSession.stationList;
    }

    getScheduledTripsToDetailsList() {
        this.getScheduledTripsDetails();
        this.showSpinnerForAction = true;
        this.dashboardService.getScheduledTripsDetails(this.hireSlipDto).subscribe(
            (response) => {
                //alert('ok');
                this.scheduledTripsToDataList = [];
                //$("#"+this.pageId+"scheduledTripsToTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.scheduledTripsToDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerScheduledTripsTo.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Schedule Trip To Details", "warning");
            },
            () => console.log('done');
    };

    getSelectedRowOfHireslipDetails(scheduledTripsFromData) {
        this.hireSlipDtoForSelectedRow = new HireSlipDto();
        this.hireSlipDtoForSelectedRow.hireslipnumber = scheduledTripsFromData.hireslipnumber;
        this.hireSlipDtoForSelectedRow.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForSelectedRow.companyid = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.stockService.getHireSlipDetails(this.hireSlipDtoForSelectedRow).subscribe(
            (response) => {
                console.log(response.mode);
                console.log(response.type);
                if (response.status != null &&
                    response.status != "Scheduled") {
                    swal("Not Allowed", "This Invoice is in " + response.status +
                        " .Please Click the Refresh Symbol Below For Updates", "warning");
                } else {
                    this.hireSlipDtoForScheduleTripToLocalStorage = null;
                    this.hireSlipDtoForScheduleTripToLocalStorage = response;
                    if (this.userDataDtoReturnSession.role == "Administrator") {
                        this.hireSlipDtoForScheduleTripToLocalStorage.mode = "view";
                    } else {
                        this.hireSlipDtoForScheduleTripToLocalStorage.mode = "update";
                    }
                    this.hireSlipDtoForScheduleTripToLocalStorage.type = "Both";
                    localStorage.clear();
                    localStorage.setItem('scheduledTripToLocalStorage',
                        JSON.stringify(this.hireSlipDtoForScheduleTripToLocalStorage));
                    this.router.navigate(['/trip-creation/stocksGrouping']);
                    console.log(localStorage);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Invoice Details", "warning")
            }, () => console.log('done');
    };

    refreshFTConsentStatus(scheduledTripsFromData) {
        this.hireSlipDtoForSelectedRow = new HireSlipDto();
        this.hireSlipDtoForSelectedRow.hireslipnumber = scheduledTripsFromData.hireslipnumber;
        this.hireSlipDtoForSelectedRow.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForSelectedRow.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoForSelectedRow.driverMobileNumber = scheduledTripsFromData.driverMobileNumber;
        this.showSpinnerForAction = true;
        this.dashboardService.refreshFTConsentStatus(this.hireSlipDtoForSelectedRow).subscribe(
            (response) => {
                if (response.status != null &&
                    response.status == "NoMobile") {
                    swal("Invalid Mobile Number", "Please update the correct Driver Mobile Number and try again!", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the consent details", "warning")
            }, () => console.log('done');
    }

    confirmGenerateConsolidateEwaybill(scheduledTripsFromData) {
        swal({
            title: "Confirm Generate",
            text: "Sure U Want to generate the Consolidate Eway Bill for the selected Hireslip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.generateConsolidateEwaybill(scheduledTripsFromData);
            } else {
                swal.close();
            }

        })
    }

    generateConsolidateEwaybill(scheduledTripsFromData) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = scheduledTripsFromData.hireslipnumber;
        this.showSpinnerForAction = true;
        this.ewaybillService.generateConsolidateEwaybill(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Completed", "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo, "info");
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1);
                    this.getScheduledTripsToDetailsList();
                } else {
                    swal("Failed", "Failed to generate the Consolidated Eway bill for Hireslip No : " + scheduledTripsFromData.hireslipnumber + ". Please try again or contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 5 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    printCEWB(eWayBillNo) {
        if (eWayBillNo == null) {
            swal("Not Allowed", "Consolidated Eway Bill not found for this Trip, please verify it!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadConsolidatedEWBPdf(eWayBillNo, response.token1);
                    } else {
                        swal("Failed", "Failed to generate the token for Consolidated Eway Bill print request, please contact Admin!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to generate the token for Consolidated Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }
    ///Chg_Ver_ST01
    validateFtStatusAllowedByDuration(scheduledTripsFromData) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = scheduledTripsFromData.hireslipnumber;
        this.showSpinnerForAction = true;
        this.dashboardService.getFtConsentStatusAllowed(this.requestDto).subscribe(
            (response) => {
                if (response.flag1 != null &&
                    response.flag1) {
                    swal("Not Allowed", "Please wait for 5 mins and try again!", "warning");
                } else {
                    this.refreshFTConsentStatus(scheduledTripsFromData);
                }

                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting the consent details", "warning")
            }, () => console.log('done');
    }

}
