import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { DebitRoutes } from 'src/app/report/debit/debit.routing';
import { SummaryComponent } from 'src/app/report/debit/summary/summary.component';
import { DebitNoteReportComponent } from 'src/app/report/debit/debit-note-report/debit-note-report.component';
import { MaterialModule } from "src/app/material.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';

@NgModule({
    imports: [CommonModule,
        //  RouterModule.forChild(DebitRoutes),
          FormsModule,  NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatDialogModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule,CashmemoReportModule],
    declarations: [
        
    SummaryComponent,
        
    DebitNoteReportComponent]
})
export class DebitModule { }
