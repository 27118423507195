import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'app-party-less-check-details',
    templateUrl: './party-less-check-details.component.html',
    styleUrls: ['./party-less-check-details.component.css']
})
export class PartyLessCheckDetailsComponent implements OnInit {


    getDataFrmServiceFrTable: any;
    lrDetailsDataList: any;
    onDestroyUnsubscribtionStatementReport: Subscription;

    loadingIndicator = true;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLrDetails: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    showSpinnerForAction = false;
    validateStatementNumber: any = null;

    searchByAuthorityFortNightDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightDtoLocalStorage: FortNightStmtDto = new FortNightStmtDto();

    modalRefferenceStmtVerifcationPopUp: NgbModalRef;
    modalRefferenceStmtVerifcationClosePopUp: string;
    @ViewChild('stmtVerificationLrsDetailsPopUpTemplate') private stmtVerificationLrsDetailsPopUpTemplate;

    viewStmtVerificationRptPopUp = false;
    validateLocalStorageStmtNo: any = null;

    rightsForStmtVerifRpt = "Right For Stmt Verification SuperAdmin"


    selectedTotalLessData: any;
    selectedMode: any;
    viewLessChkShow1 = false;
    miscelSelectedMode: string
    hideMLessColumn = false;
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    totLrSummary: number;
    totArtSummary: number;
    actWghtSummary: number;
    chgWghtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    servTaxSummary: number;
    pageId = "plcdc";

    constructor(private destStmtReport: DestStmtReport, private router: Router,
        public dialogRef: MatDialogRef<PartyLessCheckDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.selectedTotalLessData = JSON.parse(localStorage.getItem('totalLessDataPopup'));
            this.selectedMode = JSON.parse(localStorage.getItem('totalLessMode'));

            setTimeout(() => {
                if (this.selectedMode == 'statementWise') {
                    this.getTotalLessLrStmtAllReport();
                } else if (this.selectedMode == 'Entredlesslrs') {
                    this.getTotalLessLrStmtAllReport();
                    this.viewLessChkShow1 = true;
                } else if (this.selectedMode == 'statementlesslrsByAuto') {
                    this.miscelSelectedMode = 'statementlesslrsByAuto';
                    this.selectedMode = 'lesslrs';
                    this.getTotalLessLrStmtAllReport();
                    this.viewLessChkShow1 = true;
                }
                else {
                    this.getTotalLessLrReport();
                    this.hideMLessColumn = true;
                }
            }, 1000);
        }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {
        var exportTitle = null;
        if (this.selectedMode == 'statementWise') {
            exportTitle = "Statement Checked And Unchecked LRs Report";
        } else if (this.selectedMode == 'Entredlesslrs') {
            exportTitle = "Statement Entered LRs Report";
        } else if (this.selectedMode == 'statementlesslrsByAuto') {
            exportTitle = "Statement Automatated Less LRs Report";
        }

        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    title: function () {
                        return exportTitle;
                    },
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var baseFrgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var sHamali = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lcChg = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var bcChg = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aocChg = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fovChg = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var ddChg = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var gcChg = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var othChg = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var mlessAmtTot = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totArt = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWght = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actLess = api.column(20).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var entLess = api.column(21).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var diffLess = api.column(22).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total');
                $(api.column(1).footer()).html(data.length);
                $(api.column(4).footer()).html(baseFrgt);
                $(api.column(5).footer()).html(sHamali);
                $(api.column(6).footer()).html(lcChg);
                $(api.column(7).footer()).html(bcChg);
                $(api.column(8).footer()).html(aocChg);
                $(api.column(9).footer()).html(fovChg);
                $(api.column(10).footer()).html(ddChg);
                $(api.column(11).footer()).html(gcChg);
                $(api.column(12).footer()).html(othChg);
                $(api.column(13).footer()).html(toPay);
                $(api.column(14).footer()).html(mlessAmtTot);
                $(api.column(15).footer()).html(totAmt);
                $(api.column(16).footer()).html(totArt);
                $(api.column(17).footer()).html(actWght);
                $(api.column(18).footer()).html(chgWgt);
                $(api.column(20).footer()).html(actLess);
                $(api.column(21).footer()).html(entLess);
                $(api.column(22).footer()).html(diffLess);
            },
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    clearBtn() {
        this.clearField();
    }

    clearField() {
        $("#" + this.pageId + "statementNo").val('');
        $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
    }

    getTotalLessRptLrDetailsStmtAllData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.fortNightStmtDto.stmtNo = this.selectedTotalLessData.stmtId;
        this.fortNightStmtDto.mode = this.selectedMode;
        this.fortNightStmtDto.reportMode = "StmtLess";
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getTotalLessLrStmtAllReport() {
        this.getTotalLessRptLrDetailsStmtAllData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getLessUncheckedLrDetailsV2(this.fortNightStmtDto).subscribe(
            (response) => {
                this.lrDetailsDataList = [];
                $("#" + this.pageId + "lrDetailsId").DataTable().destroy();
                if (response.length > 0) {
                    this.lrDetailsDataList = response;
                    this.dtTriggerLrDetails.next();
                } else {
                    this.dtTriggerLrDetails.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While getting of Statement verfication Details", "info");
            }, () => console.log('done');
    }

    getTotalLessRptLrDetailsData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.fortNightStmtDto.stmtNo = this.selectedTotalLessData.stmtId;
        this.fortNightStmtDto.mode = this.selectedMode;
    }

    getTotalLessLrReport() {
        this.getTotalLessRptLrDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getLessUncheckedLrDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                this.lrDetailsDataList = [];
                $("#" + this.pageId + "lrDetailsId").DataTable().destroy();
                if (response.length > 0) {
                    this.lrDetailsDataList = response;
                    this.dtTriggerLrDetails.next();
                } else {
                    this.dtTriggerLrDetails.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While getting of Statement verfication Details", "info");
            }, () => console.log('done');
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

}
