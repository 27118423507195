<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px;margin-bottom: 10px;padding: 2px 5px;background-color: burlywood;" (click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-8">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Pending Hamali Memos
					</h6>
			
				</div>
				<div class="col-md-4">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id="{{pageId}}pendingHamaliMemosId" class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsPendingHamaliMemos" [dtTrigger]="dtTriggerPendingHamaliMemos">

			<thead>
				<tr>
					<th>Memo No</th>
					<th>LR No</th>
					<th>Consignee Name</th>
					<th>Memo Amt</th>
					<th>Hamali DD</th>
					<th>Memo Date</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let pendingHamaliMemosData of pendingHamaliMemosDataList ">
					<td>{{ pendingHamaliMemosData.memoNumber }}</td>
					<!-- 					<td (click)="goToLrEnquiryPage()"><u class="hyperLink_td">{{ -->
					<!-- 							pendingHamaliMemosData.position }}</u></td> -->
					<td>{{ pendingHamaliMemosData.lrNumber }}</td>
					<td>{{ pendingHamaliMemosData.consigneeName }}</td>
					<td>{{ pendingHamaliMemosData.grandTotal }}</td>
					<td>{{ pendingHamaliMemosData.hamaliDD }}</td>
					<td>{{ pendingHamaliMemosData.memoDateStr }}</td>
					<td><i (click)="open2(content,pendingHamaliMemosData)" title="Received" style="cursor: pointer;"
							class="fas fa-hand-holding-usd"></i></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="col-md-12">
	<div class="form-group">
		<div class="input-group" id="{{pageId}}popupDetails">
			<ng-template #content let-yes="close" let-no="close" let-d="dismiss">
				<div class="modal-body">
					<div class="row" style="text-align: center;">
						<div class="col-md-12">
							<h5 class="m-b-10">Sure You Want To Update The Memo?</h5>
							<button type="button" class="btn btn-outline-secondary"
								(click)="yes('Yes click');updateMemo();">Yes</button>
							<button type="button" class="btn btn-outline-danger" (click)="no('No click')">No</button>
						</div>
					</div>
				</div>
			</ng-template>


		</div>
	</div>
</div>