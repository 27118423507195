<html>

<!-- <head>
<link rel="stylesheet"  href="assets/dataTest/css/buttons.dataTables.min.css">
<link rel="stylesheet" href="assets/dataTest/css/jquery.dataTables.min.css">
<script src="assets/dataTest/js/buttons.flash.min.js"></script>>
<script src="assets/dataTest/js/buttons.html5.min.js"></script>>
<script src="assets/dataTest/js/buttons.print.min.js"></script>>
<script src="assets/dataTest/js/dataTables.buttons.min.js"></script>>
<script src="assets/dataTest/js/jquery-3.3.1.js"></script>>
<script src="assets/dataTest/js/jquery.dataTables.min.js"></script>>
<script src="assets/dataTest/js/jszip.min.js"></script>>
<script src="assets/dataTest/js/pdfmake.min.js"></script>>
<script src="assets/dataTest/js/vfs_fonts.js"></script>>
<script src="assets/dataTest/js/buttons.colVis.min.js"></script>
</head>-->

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn"id="{{pageId}}supplierMasterId">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Supplier Master</h6>
				</div>
				<!-- 				<form> -->
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<!-- spinner start-->
					<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;">
								</mat-progress-bar>
								<br>
								<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<!-- spinner end-->
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Supplier Name*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #supplierName (keyup)="fieldFocus($event, address)" type="text" class="form-control"
															aria-describedby="basic-addon11" id="{{pageId}}supplierName" name="supplierName"
															[(ngModel)]="truckDataDto.supplierName" [readonly]="readonlySupplierName"
															autocomplete="off" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Address*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-hashtag"></i>
															</span>
														</div>
														<textarea #address (keyup)="fieldFocus($event, city)" class="form-control " id="{{pageId}}address"
															rows="2" name="address" [(ngModel)]="truckDataDto.address" autocomplete="off"></textarea>

													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>City*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-hashtag"></i>
															</span>
														</div>
														<input #city (keyup)="fieldFocus($event, pinCode)" type="text" class="form-control"
															id="{{pageId}}city" name="city" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.city"
															autocomplete="off" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Pincode*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-hashtag"></i>
															</span>
														</div>
														<input #pinCode (keyup)="fieldFocus($event, regStateName)" type="number"
															class="form-control" id="{{pageId}}pinCode" name="pinCode" aria-describedby="basic-addon11"
															[(ngModel)]="truckDataDto.pinCodeNumber" autocomplete="off" />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>State*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input #regStateName id="{{pageId}}regStateName" type="text" class="form-control"
															(selectItem)="clickListnerForRegState($event)" [(ngModel)]="modelRegState"
															[ngbTypeahead]="searchRegState" [resultFormatter]="formatterRegState"
															[inputFormatter]="formatterRegState"
															(focus)="focusRegStateTA$.next($any($event).target.value)" autocomplete="off"
															(keyup)="fieldFocus($event, panNumber)" required autocomplete="off" />
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>PAN(Enter PAN as AAAAA9999A, if you don't
															have PAN)*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-id-card"></i>
															</span>
														</div>
														<input #panNumber (keyup)="fieldFocus($event, contactNumber)" type="text"
															class="form-control" id="{{pageId}}panNumber" name="panNumber" aria-describedby="basic-addon11"
															[(ngModel)]="truckDataDto.panNo" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Contact Number*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-phone"></i>
															</span>
														</div>
														<input #contactNumber (keyup)="fieldFocus($event, contactPerson)" type="number"
															class="form-control" id="{{pageId}}contactNumber" aria-describedby="basic-addon11"
															name="contactNumber" [(ngModel)]="truckDataDto.contactNo" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Contact Person</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #contactPerson (keyup)="fieldFocus($event, supplierCode)" type="text"
															class="form-control" id="{{pageId}}contactPerson" aria-describedby="basic-addon11"
															name="contactPerson" [(ngModel)]="truckDataDto.contactPerson" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Supplier Code*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input #supplierCode (keyup)="fieldFocus($event, saveBtn)" type="text" class="form-control"
															id="{{pageId}}supplierCode" name="supplierCode" aria-describedby="basic-addon11"
															[(ngModel)]="truckDataDto.suppCode" autocomplete="off">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button #saveBtn type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
								(click)="ValidateSupplierDetails()">Save/Update</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}deleteBtn"
								(click)="validateDeleteRow()">Delete</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
							<button type="submit" class="dt-button" style="margin-left: 91%;" (click)="customPrintSupplierMaster()" id="{{pageId}}printAllBtn">
									<span><i class="fas fa-print">Print</i></span>
								</button>
						<!--<div class="row">
							<!-- column -->
						<!--<div class="col-md-12">
								<div class="portlet light portlet-fit ">
									<div class="portlet-body">-->








						<!--<div class="card">
								 <div class="card-body" id="{{pageId}}print-section">
										<div class="col-md-8">
											<form class="w-50 m-b-10">
												<input type='text' class="form-control"
													placeholder='Type to filter the name column...'
													(keyup)='updateFilter($event)' />
											</form>
										</div>

										<div class="card-header bg-info"
											style="background-color: orange !important; padding: 5px;">
											<h6 class="card-title text-white">Supplier Master</h6>
										</div>
										<ngx-datatable #tables class='material striped'
											[columns]="columns" [columnMode]="'force'"
											[headerHeight]="40" [footerHeight]="25" [rowHeight]="'auto'"
											[limit]="7" [rows]='rows'> </ngx-datatable>-->


						<!--<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">-->
						<!-- 								<div class="container card" > -->

						<!-- 									<div class="card-body" id="{{pageId}}print-section" style="padding:0px!important; border:1px solid black;"> -->
						<!-- <!-- 										<div class="table-responsive"> -->
						<!-- 										<div class="card-header bg-info" -->
						<!-- 											style="background-color: #ffe6b9 !important; padding: 5px;margin-bottom:10px;"> -->
						<!-- 											<h6 class="card-title text-black">Supplier Master</h6> -->
						<!-- 										</div> -->
						<div class="box-body">
							<table datatable id="{{pageId}}suupplierMasteDatatable" class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsSupplierDetails" [dtTrigger]="dtTriggerSupplierDetails">

								<thead>
									<tr>
										<!-- 										<th></th> -->
										<th>Supplier Name</th>
										<th>Address</th>
										<th>City</th>
										<th>Pincode</th>
										<th>State</th>
										<th>Gst Number</th>
										<th>Contact Number</th>
										<th>Contact Person</th>
										<th>Supp Code</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lstOfData of supplierDetailsList " (click)="rowSelected(lstOfData);">
										<!-- 										<td></td> -->
										<td>{{ lstOfData.supplierName }}</td>
										<td>{{ lstOfData.address }}</td>
										<td>{{ lstOfData.city }}</td>
										<td>{{ lstOfData.pincode }}</td>
										<td>{{ lstOfData.state }}</td>
										<td>{{ lstOfData.tinNumber }}</td>
										<td>{{ lstOfData.contactNumber }}</td>
										<td>{{ lstOfData.contactPerson }}</td>
										<td>{{ lstOfData.suppCode }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
								<tfoot>
									<tr>
										<td style="text-align: left">Total</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
						<!-- 										</div> -->
						<!-- 									</div> -->
						<!-- 								</div> -->


						<!--	</div>
								</div>
							</div>

						</div>
						<!-- <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Datepicker in a popup</h3>
                <h6 class="card-subtitle">This is datepicker with popup</h6>
                <form class="form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary no-shadow" (click)="d.toggle()" type="button">
                          <i class="fa fa-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <!-- <hr/>
                <pre>Model: {{ model2 | json }}</pre> 
              </div>
            </div>
          </div> -->

						<!--<div class="row">
						<!-- column -->
						<!--<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="example-container">

										<mat-form-field appearance="outline" style="width:50%;"> <input
											matInput (keyup)="applyFilter($event.target.value)"
											placeholder="Filter" style="padding:10px; margin-top:5px;"> </mat-form-field>
										<table mat-table [dataSource]="dataSource" matSort>

											<!-- Name Column -->
						<!--<ng-container matColumnDef="name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
											<td mat-cell *matCellDef="let element">{{element.name}}</td>
											</ng-container>

											<!-- Position Column -->
						<!--<ng-container matColumnDef="position">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
											<td mat-cell *matCellDef="let element">
												{{element.position}}</td>
											</ng-container>

											<!-- Weight Column -->
						<!--<ng-container matColumnDef="weight">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
											<td mat-cell *matCellDef="let element">{{element.weight}}</td>
											</ng-container>

											<!-- Symbol Column -->
						<!--<ng-container matColumnDef="symbol">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</th>
											<td mat-cell *matCellDef="let element">{{element.symbol}}</td>
											</ng-container>

											<!-- grade Column -->
						<!--<ng-container matColumnDef="grade">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
											<td mat-cell *matCellDef="let element">{{element.grade}}</td>
											</ng-container>

											<!-- contactNo Column -->
						<!--<ng-container matColumnDef="contactNo">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Contact
												Number</th>
											<td mat-cell *matCellDef="let element">{{element.contactNo}}</td>
											</ng-container>

											<!-- age Column -->
						<!--<ng-container matColumnDef="age">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Age</th>
											<td mat-cell *matCellDef="let element">{{element.age}}</td>
											</ng-container>

											<!-- consignorName Column-->
						<!--<ng-container matColumnDef="consignorName">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Consignor
												Name</th>
											<td mat-cell *matCellDef="let element">{{element.consignorName}}</td>
											</ng-container>


											<!-- consigneeName Column-->
						<!--<ng-container matColumnDef="consigneeName">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Consignee
												Name</th>
											<td mat-cell *matCellDef="let element">{{element.consigneeName}}</td>
											</ng-container>


											<!-- Star Column -->
						<!--<ng-container matColumnDef="star">
											<th mat-header-cell *matHeaderCellDef></th>
											<td mat-cell *matCellDef="let element"><mat-icon></mat-icon>
											</td>
											</ng-container>

											<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
											<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
										</table>
										<mat-paginator  [pageSizeOptions]="[5, 10, 20]"
											showFirstLastButtons></mat-paginator>

									</div>
									<!--<div class="row">
									<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
    <mat-header-cell *matHeaderCellDef>{{ column.field }}

      <button class="grid-view-header-menu"
        mat-icon-button [matMenuTriggerFor]="menu" >
        <mat-icon >menu</mat-icon>
      </button>

      <mat-menu #menu>
        <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
        <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
      </mat-menu>

    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row[column.field] }}</mat-cell>
  </ng-container>

	<mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

	<!-- Group header -->
						<!--<ng-container matColumnDef="groupHeader">
		<mat-cell colspan="999" *matCellDef="let group">
      <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
      <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
			<strong>{{groupByColumns[group.level-1]}} = {{group[groupByColumns[group.level-1]]}} ({{group.totalCounts}})</strong>
      </mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

</mat-table>
									</div>
									
								</div>
							</div>
						</div>
					</div>
						<div class="col-md-4">
							<button printSectionId="print-section" ngxPrint>print</button>
						</div>-->
					</div>


					<!-- <div class="col-md-9" >
<div class="example-button-row">
  <button mat-raised-button>Basic</button>
  <button mat-raised-button color="primary">Primary</button>
  <button mat-raised-button color="accent">Accent</button>
  <button mat-raised-button color="warn">Warn</button>
  <button mat-raised-button disabled>Disabled</button>
  <a mat-raised-button routerLink=".">Link</a>
</div>
<router-outlet></router-outlet>
      </div>-->

				</div>
				<!-- 				</form> -->
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="supplierMasterId " onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
			<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>


</body>


</html>