import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component( {
    selector: 'app-driver-rewarded-report',
    templateUrl: './driver-rewarded-report.component.html',
    styleUrls: ['./driver-rewarded-report.component.css']
} )
export class DriverRewardedReportComponent implements OnInit {

    gettingDataFrmServiceFrDriverRewardedReportTable: any;


driverRewardedReportDataList: any;


onDestroyUnsubscribtionDriverRewardedReport: Subscription;



//summaryTable:any;
//for datepicker starts
model: NgbDateStruct;
model2;
//for datepicker ends

loadingIndicator = true;


//for datePicker starts
hoveredDate: NgbDateStruct;
fromDate: NgbDateStruct;
toDate: NgbDateStruct;
closeResult: string;
//for datepicker ends


@ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

dtTriggerDriverRewardedReport: Subject<any> = new Subject();

dataTable: any;
dtOptionsDriverRewardedReport: any;
pageId="drrc";


constructor( private driverRewardedRpt: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


}

rerender(): void {
    this.dtElements.forEach(( dtElement: DataTableDirective ) => {
        dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
            // Do your stuff
            dtInstance.destroy();
        } );
    } );
}







ngOnInit(): void {


    this.dtOptionsDriverRewardedReport = {
        //};
        //columns is used to for export and others starts
        columns: [
            {
                title: 'GSTIN/UIN Of Recipient',
                data: 'gstinUinOfRecipient'
            },
            {
                title: 'Invoice Number',
                data: 'inoviceNumber'
            },
            {
                title: 'Invoice Date',
                data: 'invoiceDate'
            },
            {
                title: 'Invoice Value',
                data: 'invoiceValue'
            },
            {
                title: 'Place Of Supply',
                data: 'placeOfSupply'
            },
            {
                title: 'Reverse Charge',
                data: 'reverseCharge'
            }, 
            {
                title: 'Invoice Type',
                data: 'invoiceType'
            },
            {
                title: 'E-Commerce GSTIN',
                data: 'eCommerceGstin'
            },
            {
                title: 'Rate',
                data: 'rate'
            },
            {
                title: 'Taxable Value',
                data: 'taxableValue'
            },
            {
                title: 'Cess Amount',
                data: 'cessAmount'
            }
        ],


        //columns is used to for export and others endss


        // the below code is for button export starts
        dom: 'Bfrtip',
        /*buttons: [
                  'excel', 'print'
              ],*/
        buttons: [
            {
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                exportOptions: {
                    columns: ':visible'
                }
            },
//            {
//                extend: 'print',
//                text: '<i class="fas fa-print"> Print</i>',
//                titleAttr: 'Print',
//
//            }
        ],

        // the below code is for button export ends                          
        //place holder in search/filter in datatable starts
        language: {
            search: "_INPUT_",
            searchPlaceholder: "Search..."
        },
        //place holder in search/filter in datatable ends

        processing: true,
        //scroll in datatable starts
        responsive: true,
        "scrollX": true,
        "scrollY": 380,
        "scrollCollapse": true,
        //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        "paging": false,
        "info": false,
        //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //scroll in datatable ends

    }


    //the below code is for the getting data through json starts
    //            this.supplierList.getAllData().subscribe(data => {
    //                this.lrDispatchBknRptList = data['data'];
    //                this.dtTriggerSummary.next();
    //                } );
    this.gettingDataFrmServiceFrDriverRewardedReportTable = this.driverRewardedRpt.getSummaryData()
    this.onDestroyUnsubscribtionDriverRewardedReport = this.gettingDataFrmServiceFrDriverRewardedReportTable.subscribe( data => {
        this.driverRewardedReportDataList = data['data'];
        this.dtTriggerDriverRewardedReport.next();
    } );



    //the below code is for the getting data through json ends



}




ngOnDestroy(): void {
    this.dtTriggerDriverRewardedReport.unsubscribe();

    this.onDestroyUnsubscribtionDriverRewardedReport.unsubscribe();
}







//for datepicker
// the selectToday is the method for  selecting todays'z date
selectToday() {
    this.model = {
        year: my.getFullYear(),
        month: my.getMonth() + 1,
        day: my.getDate()
    };
}

//for datePicker
}
