import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
@Component({
	selector: 'app-stmt-verification-lrs-details-report',
	templateUrl: './stmt-verification-lrs-details-report.component.html',
	styleUrls: ['./stmt-verification-lrs-details-report.component.css']
})
export class StmtVerificationLrsDetailsReportComponent implements OnInit {

	gettingDataFrmServiceFrDiscontinuousDetailsReportTable: any;


	stmtVerificationLrsSummaryList: any;
	loadingIndicator = true;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerStmtVerificationLrsSummaryReport: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsStmtVerificationLrsSummaryReport: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;

	fortNightStmtDtoLocalStorage: FortNightStmtDto = new FortNightStmtDto();
	lrDto: LRDto = new LRDto();
	address: any;
	stmtNo: any = null;
	pageId = "stvrlr";

	constructor(private router: Router, private datePipe: DatePipe,
		private consigneeService: ConsigneeService, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.fortNightStmtDtoLocalStorage = JSON.parse(localStorage.getItem('stmtVerificationLrsDetailsPopUpTemplate'));
			localStorage.clear();

			if (this.fortNightStmtDtoLocalStorage != null) {
				this.stmtNo = this.fortNightStmtDtoLocalStorage.stmtNo;
				//console.log(this.fortNightStmtDtoLocalStorage);
				this.setStatementVerificationOfLrsSummary();
			}
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var printStmtNo = this.stmtNo;
		this.dtOptionsStmtVerificationLrsSummaryReport = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize: function (win) {
						$(win.document.body).css('font-size', '10pt');
						$(win.document.body).find('th, td').
							css('font-family', 'Arial, Helvetica, sans-serif')
							.css('font-size', '12px').css('text-align', 'center');
					},
					footer: true,
					messageTop: function () {
						return "<br><table style='width: 100%;'>" +
							"<tr>" +
							"<td style='width: 22%;'>" +
							"<table>" +
							"<tr>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>Statement No</strong>" +
							"</td>" +
							"<td width='2%' style='text-align:left !important;'>" +
							"<strong style='font-size:15px;'>:</strong>" +
							"</td>" +
							"<td width='10%' style='text-align:left !important;'>" +
							"<span style='font-size:15px;'>" + printStmtNo + "</span><br>" +
							"</td>" +
							"</tr>" +
							"</table>" +
							"</td>" +
							"<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
							"<strong style='font-size:24px;'><u>Statement Verification LR Details Report</u></strong><br>" +
							"</td>" +
							"<td rowspan='3' align='left' style='width:22%;'>" +
							"</td>" +
							"</tr>" +
							"</table><br>";
					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return " Statement Verification LR Details Report- " +
							"Statement No : " + printStmtNo + "";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			// the below code is for button export ends
			//place holder in search/filter in datatable starts
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			//place holder in search/filter in datatable ends
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 380,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var articles = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var topay = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var serviceTax = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actualWgt = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var goodsValue = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(articles);
				$(api.column(4).footer()).html(topay);
				$(api.column(5).footer()).html(paid);
				$(api.column(6).footer()).html(serviceTax);
				$(api.column(7).footer()).html(actualWgt);
				$(api.column(8).footer()).html(chargedWgt);
				$(api.column(9).footer()).html(goodsValue);
			}
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerStmtVerificationLrsSummaryReport.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerStmtVerificationLrsSummaryReport.next();
	}

	setStatementVerificationOfLrsSummary() {
		this.lrDto = new LRDto();
		this.lrDto.fortNightStmtNo = this.fortNightStmtDtoLocalStorage.stmtNo;
		if (this.fortNightStmtDtoLocalStorage.description != null &&
			this.fortNightStmtDtoLocalStorage.description == "Amount Ask From Agent") {
			this.lrDto.mode = "All";
		} else if (this.fortNightStmtDtoLocalStorage.description != null &&
			this.fortNightStmtDtoLocalStorage.description == "Total Lrs In Stock") {
			this.lrDto.mode = "Stock";
		} else if (this.fortNightStmtDtoLocalStorage.description != null &&
			this.fortNightStmtDtoLocalStorage.description == "Total Lrs In Transit") {
			this.lrDto.mode = "Transit";
		}
		this.lrDto.status = "Transit";
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
		//console.log(this.lrDto);
		this.showSpinnerForAction = true;
		this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDto).subscribe(
			(response) => {
				//console.log(response);
				$("#" + this.pageId + "stmtVerificationLrsSummaryTableId").DataTable().destroy();
				this.stmtVerificationLrsSummaryList = [];
				if (response.length > 0) {
					this.stmtVerificationLrsSummaryList = response;
					//this.dtTriggerStmtVerificationLrsSummaryReport.next();
					setTimeout(() => {
						this.dtTriggerStmtVerificationLrsSummaryReport.next();
					}, 3000);
				} else {
					//this.dtTriggerStmtVerificationLrsSummaryReport.next();
					setTimeout(() => {
						this.dtTriggerStmtVerificationLrsSummaryReport.next();
					}, 3000);
					swal("Warning", "No  Details found !", "warning");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Statement Verification LR's Details", "info");
			}, () => console.log('done');
	}


}