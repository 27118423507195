<!-- Row -->
<div class="row">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">SRD Labour Master</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<!-- <h4 class="card-title">Tempo Details</h4> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Choudhary Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-user"></i>
														</span>
													</div>
													<input #labourHeadNameDropDown
														id="{{pageId}}dropDownInputFieldLabourHeadName" type="text"
														class="form-control"
														[(ngModel)]="modelDropDownLabourHeadName"
														[ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
														[resultFormatter]="formatterDropDownLabourHeadName"
														[inputFormatter]="formatterDropDownLabourHeadName"
														placeholder="Select Loader Head"
														(focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
												</div>
											</div>
										</div>
										<!-- 										<div *ngIf="addNewLoaderName" class="col-sm-12 col-md-12"> -->
										<!-- 											<div class="form-group"> -->
										<!-- 												<div class="input-group" id="{{pageId}}newLoader"> -->
										<!-- 													<label>New Loader</label> -->
										<!-- 													<div class="input-group-prepend"> -->
										<!-- 														<span class="input-group-text"> <i -->
										<!-- 															class=" fas fa-user"></i> -->
										<!-- 														</span> -->
										<!-- 													</div> -->
										<!-- 													<input type="text" class="form-control" -->
										<!-- 														aria-describedby="basic-addon11"> -->
										<!-- 												</div> -->
										<!-- 											</div> -->
										<!-- 										</div> -->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Loading Hamali</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-user"></i>
														</span>
													</div>
													<input id="{{pageId}}loadingHamali" type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Unit</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-weight"></i>
														</span>
													</div>
													<select id="{{pageId}}unitLoading" class="custom-select col-12">
														<option value="Kanta Weight">Kanta Weight</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Charged Weight">Charged Weight</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Unoading Hamali</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-user"></i>
														</span>
													</div>
													<input id="{{pageId}}unloadingHamali" type="text"
														class="form-control" aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Unit</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-weight"></i>
														</span>
													</div>
													<select id="{{pageId}}unitUnloading" class="custom-select col-12">
														<option value="Kanta Weight">Kanta Weight</option>
														<option value="Actual Weight">Actual Weight</option>
														<option value="Charged Weight">Charged Weight</option>
													</select>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}applyBtn"
							(click)="validateSave()">Apply</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
					</div>
				</div>
				<div class="col-md-9 vl">
					<h6 class="card-title border_bottom">Commission Details</h6>
					<div class="box-body">
						<table datatable id="{{pageId}}labourCommissionTableId"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsSrdLabour" [dtTrigger]="dtTriggerSrdLabour">

							<thead>
								<tr>
									<th>Loader Head</th>
									<th>Loading Hamali</th>
									<th>Loading Hamali Unit</th>
									<th>Unloading Hamali</th>
									<th>Unloading Hamali Unit</th>
									<th>Effective From</th>
								</tr>
							</thead>
							<tbody>
								<tr style='cursor: pointer;'
									*ngFor="let srdLabourData of srdLabourDataList"
									(click)="selectedRowDetails(srdLabourData);">
									<td>{{ srdLabourData.loaderHead }}</td>
									<td>{{ srdLabourData.loadingHamali}}</td>
									<td>{{ srdLabourData.loadingHamaliUnit }}</td>
									<td>{{ srdLabourData.unloadingHamali }}</td>
									<td>{{ srdLabourData.unloadingHamaliUnit }}</td>
									<td>{{ srdLabourData.fromDateInDate }}</td>

								</tr>


								<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

							</tbody>
						</table>


					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
