import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends



import { DetailsRoutes } from 'src/app/master/details/details.routing';
import { AgentSetupComponent } from 'src/app/master/details/agent-setup/agent-setup.component';
import { AgentSubstationMasterComponent } from './agent-substation-master/agent-substation-master.component';


@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild( DetailsRoutes ),
        FormsModule, NgbModule, DataTablesModule, MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, AutocompleteModule, DailyReportModule],
    declarations: [
        AgentSetupComponent,
        AgentSetupComponent, AgentSubstationMasterComponent,
    ]
})
export class DetailsModule { }
