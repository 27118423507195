<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBkgOSRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}reportMode" name="reportMode" #reportMode (change)="reportModeMode(reportMode.value)">
															<option selected value="rptModeDetailDate">Detail Wise</option>
                                                            <option value="rptModeSummaryWise">Summary Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>As On</label> <input id="{{pageId}}fromDates" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" [(ngModel)]="fromDatesModal" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Agent Name</label>
                                                        <input id="{{pageId}}fromStation" type="text" class="form-control" [(ngModel)]="modelFromStation" [ngbTypeahead]="searchFromStation" [resultFormatter]="formatterFromStation" [inputFormatter]="formatterFromStation" (focus)="focusFromStationTA$.next($any($event).target.value)"
                                                            placeholder="Select Agent" (selectItem)="agentSearchViewDropDownListner($event)" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consignorGstNo">
                                                        <label>Consignor GST No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input #consignorGSTNumber placeholder="Enter Consignor GST Number" (keyup)="getConsignorGstNumber($event)" type="text" class="form-control" id="{{pageId}}consignorGSTNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <input #consignorIndexSearchView id="{{pageId}}consignorIndexSearchView" name="consignorIndexSearchView" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorSearchViewDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchViewTypeTA"
                                                            (focus)="focusConsignorIndexSearchViewTA$.next($any($event).target.value)" #instanceConsignorIndexSearchView="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <input #consignorNameSearchView id="{{pageId}}consignorNameSearchView" name="consignorNameSearchView" type="text" class="form-control" (selectItem)="rowSelectedConsignorSearchView($event)" [(ngModel)]="modelConsignorNameSearchView" [ngbTypeahead]="consignorNameSearchViewTypeTA"
                                                            [resultFormatter]="formatterConsignorNameSearchView" [inputFormatter]="formatterConsignorNameSearchView" (focus)="focusConsignorNameSearchViewTA$.next($any($event).target.value)" placeholder="Select Consignor Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                            </div>
                                        </div>
                                        <table width=100%>
                                            <tr>
                                                <td width=80%>
                                                    <!-- <h6 class="card-title">CashMemo LetterHead Report</h6> -->
                                                </td>
                                                <td width=20%>
                                                    <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrint()" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                </td>
                                            </tr>
                                        </table>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div [hidden]="!viewTopartyDetailTable" class="box-body">
                                                    <table datatable id="{{pageId}}toPartyLRDetailsTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionstoPartyLRDetails" [dtTrigger]="dtTriggertoPartyLRDetails">
                                                        <thead>
                                                            <tr>
                                                                <th>Consignor Name</th>
                                                                <th>Consignee Name</th>
                                                                <th>Agent Name</th>
                                                                <th>Destination</th>
                                                                <th>Booking Date</th>
                                                                <th>LR No</th>
                                                                <th>Art</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Memo Amt</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let toPartyLRDetailsData of toPartyLRDetailsDataList;">
                                                                <td>{{ toPartyLRDetailsData.consignor }}</td>
                                                                <td>{{ toPartyLRDetailsData.consigneeName }}</td>
                                                                <td>{{ toPartyLRDetailsData.source }}</td>
                                                                <td>{{ toPartyLRDetailsData.destination }}</td>
                                                                <td>{{ toPartyLRDetailsData.bookingDateStr }}</td>
                                                                <td>{{ toPartyLRDetailsData.lrNumber }}</td>
                                                                <td>{{ toPartyLRDetailsData.articles }}</td>
                                                                <td>{{ toPartyLRDetailsData.actualWeight }}</td>
                                                                <td>{{ toPartyLRDetailsData.chargedWeight}}</td>
                                                                <td>{{ toPartyLRDetailsData.grandTotal }}</td>
                                                                <td>{{ toPartyLRDetailsData.status }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <!-- summary -->
                                                <div [hidden]="!viewTopartySummayTable" class="box-body">
                                                    <table datatable id="{{pageId}}toPartyLRSummaryTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionstoPartyLRSummary" [dtTrigger]="dtTriggertoPartyLRSummary">
                                                        <thead>
                                                            <tr>
                                                                <th>Consignor Name</th>
                                                                <th>Total LRs</th>
                                                                <th>Tot Art</th>
                                                                <th>Tot Act Wt</th>
                                                                <th>Tot Chg Wt</th>
                                                                <th>Tot Memo Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let toPartyLRSummaryData of toPartyLRSummaryDataList;">
                                                                <td>{{ toPartyLRSummaryData.consignor }}</td>
                                                                <td>{{ toPartyLRSummaryData.totalMemos }}</td>
                                                                <td>{{ toPartyLRSummaryData.articles }}</td>
                                                                <td>{{ toPartyLRSummaryData.actualWeight }}</td>
                                                                <td>{{ toPartyLRSummaryData.chargedWeight}}</td>
                                                                <td>{{ toPartyLRSummaryData.grandTotal }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>