import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MasterService } from 'src/app/dataService/master-service';
//for redirect to other page ends
@Component({
    selector: 'app-expenses-os-payment-followup',
    templateUrl: './expenses-os-payment-followup.component.html',
    styleUrls: ['./expenses-os-payment-followup.component.css']
})
export class ExpensesOsPaymentFollowupComponent implements OnInit {

    getDataExpensesOsPaymentFollowupDataTable: any;



    expensesOsPaymentFollowupDataList: any;



    onDestroyUnsubscribtionExpensesOsPaymentFollowup: Subscription;

    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    //dtTrigger: Subject = new Subject();

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;


    dtTriggerExpensesOsPaymentFollowup: Subject<any> = new Subject();

    dataTable: any;


    dtOptionsExpensesOsPaymentFollowup: any;

    lrDtoForDetails: LRDto = new LRDto();
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    lrDtoForBlockLrs: LRDto = new LRDto();
    selectedRowReason: any;
    dtOptionsBlockLrDetails: any;
    reasonValue: any;
    showSpinnerForAction = false;
    lrDtoForLoad: LRDto = new LRDto();
    selectedBillNumber: any;
pageId="expfc";
    
    constructor(private dashboardService: DashboardService, private route: ActivatedRoute,
        private router: Router, private modalService: NgbModal, private masterService: MasterService, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

        this.dtOptionsExpensesOsPaymentFollowup = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }


    }

    // ngOnDestroy(): void {
    //     this.dtTriggerExpensesOsPaymentFollowup.unsubscribe();

    // }
    ngAfterViewInit(): void {
        this.dtTriggerExpensesOsPaymentFollowup.next();

    }
    goToExpensesPaymentFollowupReportPage() {
        this.router.navigate(['/masters/transhipment/expensesPaymentFollowupReport']);
    }
    //for redirect to other page starts

    gridReconifgureOnReloadBtn() {

        this.lrDtoForLoad = new LRDto();
        this.lrDtoForLoad.mode = "UnPaid";
        this.lrDtoForLoad.reportMode = "DB";
        this.lrDtoForLoad.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoForLoad.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForBlockLrs);
        this.gridReconifgureDetails();
    }
    gridReconifgureDetails = () => {
        this.showSpinnerForAction = true;
        this.masterService.getExpensesDetailsReport(this.lrDtoForLoad).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                //$("#"+this.pageId+"expensesOsPaymentFollowupId").DataTable().destroy();
                this.expensesOsPaymentFollowupDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.expensesOsPaymentFollowupDataList = response;
                    console.log(this.expensesOsPaymentFollowupDataList);
                }
                // this.dtTriggerExpensesOsPaymentFollowup.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Expenses OS Payment Follow Up details   Details", "info");
            }, () => console.log('done');
    };
    expensesRpt(expensesOsPaymentFollowupData) {
        this.selectedBillNumber = expensesOsPaymentFollowupData.id;
        console.log(this.selectedBillNumber);
        localStorage.clear();
        localStorage.setItem('expensesOsPaymentFollowupDB', JSON.stringify(this.selectedBillNumber));
        this.router.navigate(['/masters/transhipment/expensesPaymentFollowupReport']);
    }


}

