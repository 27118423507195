import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router, NavigationExtras } from "@angular/router";
import swal from 'sweetalert';
import *  as moment from 'moment';

@Component({
    selector: 'app-party-wise-os-grouping-rpt-print',
    templateUrl: './party-wise-os-grouping-rpt-print.component.html',
    styleUrls: ['./party-wise-os-grouping-rpt-print.component.css']
})
export class PartyWiseOsGroupingRptPrintComponent implements OnInit {
    // todayDate: any;
    userDataDtoReturnSession: any;

    isLoggedIn = true;

    address: any;
    partyWiseOsDataListToPrintGroupingByPartyName: any;
    dataList: any;

    result: any;
    totMemoNo: any = 0;
    totMemoAmt: any = 0;
    totArtical: any = 0;

    userName: string;
    dataForPrintFromDto: any;

    asOnDate: any;
    pageId="posgp";

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.userName = this.userDataDtoReturnSession.name;
            this.partyWiseOsDataListToPrintGroupingByPartyName = JSON.parse(localStorage.getItem('printForGrpPartyWiseOsDataList'));
            this.dataForPrintFromDto = JSON.parse(localStorage.getItem('printCashMemoDtoForGrpPrintList'));
            // this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            // console.log(this.todayDate);
            //console.log( moment(this.lrDto[0].invoiceDate).format('DD-MM-YYYY'));
            //For GC Left to Party  from collection Man report 

            this.asOnDate = this.dataForPrintFromDto.fromDate;

            console.log(this.asOnDate);

            this.dataList = [];
            if (this.partyWiseOsDataListToPrintGroupingByPartyName.length > 0) {
                this.dataList = this.partyWiseOsDataListToPrintGroupingByPartyName;
                console.log(this.dataList);
                this.totMemoNo = this.partyWiseOsDataListToPrintGroupingByPartyName.length;
                console.log(this.totMemoNo);
                for (let i = 0; i < this.partyWiseOsDataListToPrintGroupingByPartyName.length; i++) {
                    this.totMemoAmt = +this.totMemoAmt + + this.partyWiseOsDataListToPrintGroupingByPartyName[i].amount;
                    this.totArtical= +this.totArtical + + this.partyWiseOsDataListToPrintGroupingByPartyName[i].article;
                }
                console.log(this.totMemoAmt + "<< >>" + this.totArtical);
                this.setGcTakenDetailsPrintData();
            }
            window.setTimeout(function () {
                window.print();
            }, 2000);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();


            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    setGcTakenDetailsPrintData() {
        var groups = new Set(this.dataList.map(item => item.consignee));
        this.result = [];
        
        groups.forEach(g => {
            /* Each Summary Data Value */
            const memoNo = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.memoNumber, 0)
            const lrNo = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.lrNumber, 0)
            const amt = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.amount, 0)
            const bkgDate = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.bookingDateStr, 0)
            const art = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.article, 0)
            const memoDate = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.memoDateStr, 0)
            const noOfDays = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.days, 0)
            const location = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.area, 0)
            const cltMan = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.collectionMan, 0)

            const statusResult = this.dataList
                .filter(i => i.consignee === g)
                .reduce((sum, i) => +sum + +i.status, 0)


            this.result.push({
                consignee: g,
                values: this.partyWiseOsDataListToPrintGroupingByPartyName.filter(i => i.consignee === g),
                memoNumber: memoNo,
                lrNumber: lrNo,
                amount: amt,
                bookingDate: bkgDate,
                article: art,
                memoDateStr: memoDate,
                days: noOfDays,
                area: location,
                collectionMan: cltMan,
                status: statusResult,


            })
        })
    }

    clearField() {

        this.totMemoNo = 0;
        this.totMemoAmt = 0;
        this.totArtical=0;
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
