<div class="auth-wrapper d-flex no-block justify-content-center align-items-center"
	style="background: url(assets/images/big/bgimg2.jpg) no-repeat center center;">
	<!-- bgimg1 -->

	<div class="auth-box">
		<div id="loginform" [ngClass]="{'d-none': recoverform}">
			<div class="logo">
				<span class="db">
					<h1 class="custome_effects_cmpy">
						<img src="assets/images/logofive.png" style="width: 100%;" alt="logo" />
						<!-- 				big/		logofive.png -->
					</h1>
				</span>
				<!-- 				<h5 class="font-medium m-b-20">Sign In to Admin</h5> -->
			</div>

			<!-- Form -->
			<div class="row" *ngIf="errorMsg">
				<div class="col-md-12">
					<ngb-alert class="custome_effAlert" (close)="errorMsg = null" [dismissible]="true">
						<strong>Error:</strong>
						<h5 class="custome_fs14">Incorrect Username or Password,
							please check login credential and try again.</h5>
					</ngb-alert>
				</div>
			</div>
			<div class="row" *ngIf="errorMsgTimeExpired">
				<div class="col-md-12">
					<ngb-alert class="custome_effAlert" (close)="errorMsgTimeExpired = null" [dismissible]="true">
						<strong>Time Expired:</strong>
						<h5 class="custome_fs14">Your Login Time has been expired, please
							contact Administrator</h5>
					</ngb-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12">

					<form class="form-horizontal m-t-20" id="loginform">
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
							</div>
							<input type="text" class="form-control form-control-lg" placeholder="Username"
								aria-label="Username" [(ngModel)]="userDataDto.userName" aria-describedby="basic-addon1"
								[ngModelOptions]="{standalone: true}">
						</div>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
							</div>
							<input type="password" class="form-control form-control-lg" placeholder="Password"
								aria-label="Password" [(ngModel)]="userDataDto.userPasswd"
								aria-describedby="basic-addon1" [ngModelOptions]="{standalone: true}">
						</div>
						<div class="form-group row">
							<div class="col-md-12">
								<div class="custom-control custom-checkbox">
									<a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover"
										class="text-white float-right"><i class="fa fa-lock m-r-5"></i> Forgot
										password?</a>
								</div>
							</div>
						</div>
						<div class="preloader" *ngIf="showSpinnerForAction">
							<div class="spinner">

								<div class="double-bounce1"></div>
								<div class="double-bounce2"></div>

							</div>
						</div>

						<div class="form-group text-center">
							<div class="col-xs-12 p-b-20">
								<button (click)="signIn()" class="btn btn-block btn-lg btn-info" type="submit">Log
									In</button>
							</div>
						</div>
						<!-- 						<div class="row"> -->
						<!-- 							<div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center"> -->
						<!-- 								<div class="social"> -->
						<!-- 									<a href="javascript:void(0)" class="btn btn-facebook" -->
						<!-- 										data-toggle="tooltip" title="" -->
						<!-- 										data-original-title="Login with Facebook"> <i -->
						<!-- 										aria-hidden="true" class="fab fa-facebook"></i> -->
						<!-- 									</a> <a href="javascript:void(0)" class="btn btn-googleplus" -->
						<!-- 										data-toggle="tooltip" title="" -->
						<!-- 										data-original-title="Login with Google"> <i -->
						<!-- 										aria-hidden="true" class="fab fa-google-plus"></i> -->
						<!-- 									</a> -->
						<!-- 								</div> -->
						<!-- 							</div> -->
						<!-- 						</div> -->
						<!-- 						<div class="form-group m-b-0 m-t-10"> -->
						<!-- 							<div class="col-sm-12 text-center"> -->
						<!-- 								Don't have an account? <a href="#/authentication/signup" -->
						<!-- 									class="text-info m-l-5"><b>Sign Up</b></a> -->
						<!-- 							</div> -->
						<!-- 						</div> -->
					</form>
				</div>
			</div>
		</div>
		<div id="recoverform" [ngClass]="{'d-block': recoverform}">
			<div class="logo">
				<span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
				<h5 class="font-medium m-b-20">Recover Password</h5>
				<span class="text-white">Enter your Email and instructions
					will be sent to you!</span>
			</div>
			<div class="row m-t-20">
				<!-- Form -->
				<form class="col-12">
					<!-- email -->
					<div class="form-group row">
						<div class="col-12">
							<input class="form-control form-control-lg" type="email" placeholder="Username">
							<!-- 		commented the required="" due to on back btn click it asking to fill the email -->
						</div>
					</div>
					<!-- pwd -->
					<div class="row m-t-20">
						<div class="col-4">
							<button class="btn btn-block btn-lg btn-dark" type="submit" name="action"
								(click)="showloginform()">Back</button>
						</div>
						<div class="col-4">
							<button class="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

</div>