import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import *  as moment from 'moment';
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingLrDetailsComponent } from "src/app/dashboards/dashboards-popup/pending-lr-details/pending-lr-details.component";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
//import {Subscription} from 'rxjs';
import { DatePipe } from "@angular/common";
//for modal ends
@Component({
	selector: 'app-lrs-short-and-extra',
	templateUrl: './lrs-short-and-extra.component.html',
	styleUrls: ['./lrs-short-and-extra.component.css']
})
export class LrsShortAndExtraComponent implements  OnInit {

	getDataLrsShortAndExtraDataTable: any;
	lrsShortAndExtraDataList: any;
	onDestroyUnsubscribtionLrsShortAndExtra: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLrsShortAndExtra: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsLrsShortAndExtra: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	showPrint = false;
	showColumnPrint = 'LrShortExtraPrt SourceGroup';
	lrDtoForGetDetails: LRDto = new LRDto();
	lrDtoForDelete: LRDto = new LRDto();
	selectedLrShortageId: any;
	listOfLrShortageId: Array<any> = [];
	address: any;
	office: any;

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	mainStation: any;
	pageId="lrsec";
	constructor(private dashboardService: DashboardService,
		//for modal starts
		public dialog: MatDialog,
		private route: ActivatedRoute, private datePipe: DatePipe,
		private router: Router, public changeDetectorRef : ChangeDetectorRef
		//for modal ends
	) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.office;
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"LrShortExtraPrt SourceGroup") {
						this.showPrint = true;
					}

				}
			}
			/* if(this.showColumn=='LrShortExtraPrt SourceGroup'){
				 this.showPrint = true;
			 }else{
				 this.showPrint = false;
			 }*/
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var mainStation = this.office;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		this.dtOptionsLrsShortAndExtra = {
			dom: 'Bfrtip',
			buttons: [
				// {
				// 	extend: 'print',
				// 	text: '<i class="fas fa-print">Print</i>',
				// 	titleAttr: 'Print',
				// 	customize: function (win) {
				// 		$(win.document.body).css('font-size', '10pt');
				// 		$(win.document.body).find('th, td').
				// 			css('font-family', 'Arial, Helvetica, sans-serif')
				// 			.css('font-size', '13px').css('text-align', 'center');
				// 	},
				// 	footer: true,
				// 	exportOptions: {
				// 		columns: [0, 9, 10, 2, 4, 5, 6, 2, 3]
				// 	},
				// 	messageTop: function () {
				// 		var returnValOverAll = null;
				// 		returnValOverAll = "<br><table style='width: 100%;'>" +
				// 			"<tr>" +
				// 			"<td style='width: 20%;'>" +
				// 			"<table>" +
				// 			"<tr>" +
				// 			"<td width='8%' style='text-align:left !important;'>" +
				// 			"<strong style='font-size:15px;'>Station </strong>" +
				// 			"</td>" +
				// 			"<td width='2%' style='text-align:left !important;'>" +
				// 			"<strong style='font-size:15px;'>:</strong>" +
				// 			"</td>" +
				// 			"<td width='10%' style='text-align:left !important;'>" +
				// 			"<strong style='font-size:15px;'>" + mainStation + "</strong>" +
				// 			"</td>" +
				// 			"</tr>" +
				// 			"</table>" +
				// 			"</td>" +
				// 			"<td rowspan='2'style='width:60%;word-wrap: break-word;text-align:left !important;'align='left'>" +
				// 			"<strong style='font-size:23px;'><u>LRs Short And Extra Details As On Date : " + dateformate + "</u></strong><br>" +
				// 			"</td>" +
				// 			"<td  align='left' style='width:20%;'>" +
				// 			"</td>" +
				// 			"</tr>" +
				// 			"</table><br>";
				// 		return returnValOverAll;

				// 	},
				// 	title: function () {
				// 		return "<table style='table-layout: fixed;width: 100%;'><tr>" +
				// 			"<td align='left' style='width: 20%;'>" +
				// 			"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
				// 			"</td>" +
				// 			"<td align='left' style='width: 30%;'>" +
				// 			"</td>" +
				// 			"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
				// 			companyAddressDetls +
				// 			"</td>" +
				// 			"</tr></table>";
				// 	}
				// }
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			pagingType: 'full_numbers',
			pageLength: 5,
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 150,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			// destroy : true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totArt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totActArt = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totEntArt = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(totArt);
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
				$(api.column(7).footer()).html();
				$(api.column(8).footer()).html();
				$(api.column(9).footer()).html(totActArt);
				$(api.column(10).footer()).html(totEntArt);
			}
		}

	}

	// ngOnDestroy(): void {
	// 	this.dtTriggerLrsShortAndExtra.unsubscribe();

	// }
	ngAfterViewInit(): void {
		this.dtTriggerLrsShortAndExtra.next();
	}
	gridReconifgureOnReloadBtn() {

		this.lrDtoForGetDetails = new LRDto();
		this.lrDtoForGetDetails.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForGetDetails.branchType = this.userDataDtoReturnSession.role;
		this.lrDtoForGetDetails.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForGetDetails);
		this.gridReconifgureDetails();
	}
	gridReconifgureDetails = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.getLRsShortage(this.lrDtoForGetDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				//$("#"+this.pageId+"lrsShortAndExtraId").DataTable().destroy();
				this.lrsShortAndExtraDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.lrsShortAndExtraDataList = response;
					console.log(this.lrsShortAndExtraDataList);
				}
				// this.dtTriggerLrsShortAndExtra.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Lr Short And Extra  Details", "info");
			}, () => console.log('done');
	};

	deleteRow(lrsShortAndExtraData) {
		this.selectedLrShortageId = '';
		this.selectedLrShortageId = lrsShortAndExtraData.id;
		console.log(this.selectedLrShortageId);
		if (this.selectedLrShortageId != null && this.selectedLrShortageId != '') {
			this.deleteRowDetails();
		}
	}

	deleteRowDetails() {
		this.lrDtoForDelete = new LRDto();
		this.listOfLrShortageId = [];
		this.listOfLrShortageId.push(this.selectedLrShortageId);
		this.lrDtoForDelete.listOfConsigneId = this.listOfLrShortageId;
		this.lrDtoForDelete.userName = this.userDataDtoReturnSession.userId
		this.lrDtoForDelete.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForDelete);
		this.deleteRowDetailsInfo();
	}
	deleteRowDetailsInfo = () => {
		this.showSpinnerForAction = true;
		this.dashboardService.deleteLRShortage(this.lrDtoForDelete).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == 'Success') {
					swal({
						title: "Success",
						text: "LR Deleted Successfully",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						this.gridReconifgureOnReloadBtn();
					});


				} else {
					swal({
						title: "Failed",
						text: "LR Not Deleted",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();

					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Deleting the Lr Short And Extra  Details", "info");
			}, () => console.log('done');
	};

	customPrintLrsShortAndExtraRpt() {
		if (this.lrsShortAndExtraDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Actual Art", "Enter Art", "Status", "Invoice Number", "Vehicle Number", "Remarks", "Belongs To"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 10, 10, 20, 15, 10, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}



			for (let i = 0; i < this.lrsShortAndExtraDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.lrsShortAndExtraDataList[i].lrNumber, this.lrsShortAndExtraDataList[i].totalArticles,
				this.lrsShortAndExtraDataList[i].bookingDateStr, this.lrsShortAndExtraDataList[i].description,
				this.lrsShortAndExtraDataList[i].toPay, this.lrsShortAndExtraDataList[i].paid, this.lrsShortAndExtraDataList[i].actualWeight,
				this.lrsShortAndExtraDataList[i].chargedWeight, this.lrsShortAndExtraDataList[i].goodsValue];


				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrsShortAndExtraDataList.length, "", "", "", "", "", "", "", ""];
			//heading logics For Date
			


			this.cashMemoDtoForCustomPrintListHeadingV1 = [];


			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Station"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [ this.office];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Lrs Short And Extra Details");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}



}
