<div class="row system_responsive">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Latest News Details</h6>
            </div>
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <!-- 						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10"> -->
                        <!-- 							<div class="form-group"> -->
                        <!-- 								<div class="input-group"> -->
                        <!-- 									<mat-progress-bar mode="indeterminate" style="color: green;"> -->
                        <!-- 									</mat-progress-bar> -->
                        <!-- 									<br> -->
                        <!-- 									<h6 class="card-title" align='center' -->
                        <!-- 										style="color: green; margin: auto; font-size: 18px;"> -->
                        <!-- 										Please Wait Loading Details.....</h6> -->
                        <!-- 								</div> -->
                        <!-- 							</div> -->
                        <!-- 						</div> -->
                        <div class="row">
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Title</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-envelope"></i>
													</span>
                                                </div>
                                                <input type="text" id="{{pageId}}title" class="form-control" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Position</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
														class="fas fa-list-ol"></i>
													</span>
                                                </div>
                                                <input type="number" id="{{pageId}}position" class="form-control" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <div class="form-group-prepend">
                                                    <span class="input-group-text"> <i
														class=" fas fa-envelope"></i>&nbsp;<span
														style="font-weight: bold;">Message</span>
                                                    </span>
                                                </div>
                                                <textarea class="form-control" id="{{pageId}}message" rows="14"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="confirmSave();">Save</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearFields();">Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7vl">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}latestNewsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLatestNews" [dtTrigger]="dtTriggerLatestNews">

                                        <thead>
                                            <tr>
                                                <th>Position</th>
                                                <th>Title</th>
                                                <th>Entered Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let latestNewsData of latestNewsDataList ">
                                                <td>{{ latestNewsData.msgPositionId }}</td>
                                                <td>{{ latestNewsData.tittle }}</td>
                                                <td>{{ latestNewsData.enteredDate }}</td>
                                                <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(latestNewsData);">
														<i title="View" class="fas fa-edit"></i>
													</button>
                                                    <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(latestNewsData)">
														<i title="Remove" class="fas fa-trash"></i>
													</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>