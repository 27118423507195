
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';

@Component( {
    selector: 'app-daily-bkg-rpt-print',
    templateUrl: './daily-bkg-rpt-print.component.html',
    styleUrls: ['./daily-bkg-rpt-print.component.css']
} )
export class DailyBkgRptPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    dataForPrintAssignMemo: any;
    dataForPrintAssignMemoList: any;
    collectionMan:any;
    totalAmount:number;
    pfCollectionManDetailsDataList:any;
    totalCount:any;
    memoAmount:number;
    address:any;
    image:any;
    
    printCashMemoDtoForCustomPrintList: any;
    printCashMemoDtoForCustomPrintDataList: any;
    printcashMemoDtoForCustomPrintDataSummaryList:any;
    printcashMemoDtoForCustomPrintListHeadingV1:any;
    printcashMemoDtoForCustomPrintListHeadingV2:any;
    printcashMemoDtoForCustomPrintListHeadingV3:any;
    printcashMemoDtoForCustomPrintListHeadingV4:any
    printTitle:any;
    
    dataForPrintGCTakenMemoList: any;
    showAssignDate=false;
    columnsCount:any;
    pageId="cdprc";

    constructor( private router: Router, private datePipe: DatePipe , public changeDetectorRef : ChangeDetectorRef) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }
        if ( this.isLoggedIn ) {

            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            //            this.dataForPrint = localStorage.getItem( 'print' );
            this.dataForPrintAssignMemo = JSON.parse( localStorage.getItem( 'printAfterAssignMemo' ) );
            this.dataForPrintAssignMemoList = JSON.parse( localStorage.getItem( 'printAfterAssignMemolist' ) );
			
			this.printCashMemoDtoForCustomPrintList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintList' ) );
			this.printCashMemoDtoForCustomPrintDataList = JSON.parse( localStorage.getItem( 'printCashMemoDtoForCustomPrintDataList' ) );
			this.printcashMemoDtoForCustomPrintDataSummaryList = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintDataSummaryList' ) );
            //this.dataForPrintGCTakenMemoList = JSON.parse( localStorage.getItem( 'printAfterAssignMemolistFromCollectionManRpt' ) );
            this.printcashMemoDtoForCustomPrintListHeadingV1 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV1' ) );
            this.printcashMemoDtoForCustomPrintListHeadingV2 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV2' ) );
            this.printcashMemoDtoForCustomPrintListHeadingV3 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV3' ) );
            this.printcashMemoDtoForCustomPrintListHeadingV4 = JSON.parse( localStorage.getItem( 'printcashMemoDtoForCustomPrintListHeadingV4' ) );
            this.printTitle=localStorage.getItem('printTitle');
     //       console.log( this.dataForPrintAssignMemo);
    //        console.log( this.dataForPrintAssignMemoList);
	
	//       console.log( this.dataForPrintGCTakenMemo);
    //        console.log( this.dataForPrintGCTakenMemoList);
            // for Payment Followup
            if ( this.dataForPrintAssignMemo != null ) {
                this.collectionMan = this.dataForPrintAssignMemo.collectionMan;
            } else{
                this.collectionMan='NA';
            }
            if ( this.dataForPrintAssignMemoList != null ) {
                this.pfCollectionManDetailsDataList=this.dataForPrintAssignMemoList;
            }
		/*	//For GC taken from collection Man report 
			
                this.collectionMan='NA';
            
            if ( this.dataForPrintGCTakenMemoList != null ) {
            	 this.showAssignDate=true;
                this.pfCollectionManDetailsDataList=this.dataForPrintGCTakenMemoList;
            }*/
			
			
            
            this.todayDate = this.datePipe.transform( new Date(), "dd-MM-yyyy" );
            if ( this.dataForPrintAssignMemoList != null ) {
            this.totalCount=this.dataForPrintAssignMemoList.length;
            this.totalAmount=0;
            this.memoAmount=0;
	            for ( let i = 0; i< this.dataForPrintAssignMemoList.length; i++) {
	                this.memoAmount = this.dataForPrintAssignMemoList[i].amount;
	                this.totalAmount= +this.totalAmount + +this.memoAmount;
	                
	            }
            }
            /*if ( this.dataForPrintGCTakenMemoList != null ) {
                this.totalCount=this.dataForPrintGCTakenMemoList.length;
                this.totalAmount=0;
                this.memoAmount=0;
	                for ( let i = 0; i< this.dataForPrintGCTakenMemoList.length; i++) {
	                    this.memoAmount = this.dataForPrintGCTakenMemoList[i].amount;
	                    this.totalAmount= +this.totalAmount + +this.memoAmount;
	                    
	                }
                }*/
            this.image =this.userDataDtoReturnSession.ip;
            

            this.validatBeforePrint();
            window.setTimeout( function() {
           window.print();
            }, 1000 );
            window.onafterprint = function() {
               window.close();
            }
            localStorage.clear();
            window.addEventListener( 'afterprint', ( event ) => {
                this.clearField();
            } );
        }
    }

    ngOnInit() {

    }
    
    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
    }



   
    clearField() {
       this.address='';
       this.dataForPrintAssignMemo='';
       this.dataForPrintAssignMemoList='';
       this.collectionMan='';
       this.totalAmount=0;
       this.pfCollectionManDetailsDataList='';
       this.totalCount=0;
       this.memoAmount=0;
	   this.printCashMemoDtoForCustomPrintList='';
	   this.printCashMemoDtoForCustomPrintDataList="";
	   this.dataForPrintGCTakenMemoList='';
	   this.showAssignDate=false;
    }


    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
}
