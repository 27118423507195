import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';

export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-multiple-article-size',
	templateUrl: './multiple-article-size.component.html',
	styleUrls: ['./multiple-article-size.component.css']
})
export class MultipleArticleSizeComponent implements OnInit {

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerMultiArtTable: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsMultiArtTable: any;
	multiArtTableDataList: Array<any> = [];
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	showSpinnerForAction = false;
	model: NgbDateStruct;
	model2;
	loadingIndicator = true;
	popUpOpenLREditData: any;
	popUpOpenLREditArticleData: any;
	popUpOpenMultipleArticleSizeListLocalStorage: any;
	private newAttributeSetMultiArtSizeoOnLoadSet: any = {};
	private newAttribute: any = {};
	editSelected = false;
	isBlock: boolean = false;
	enterArticles = 0;
	getArticles: any;
	totArticles: any;
	multiArtArticle: any;
	editTableId: null;
	lrtDtoForMultiArt: LRDto = new LRDto();
	lrtDtoForMultiArtRet: any;
	lrtDtoForMultiArtRetSet: LRDto = new LRDto();
	pageId = "masc";

	constructor(private router: Router, public dialogRef: MatDialogRef<MultipleArticleSizeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenLREditData = JSON.parse(localStorage.getItem('MultipleArticleSizeData'));
			this.popUpOpenLREditArticleData = JSON.parse(localStorage.getItem('MultipleArticleSizeTotalArticle'));
			this.popUpOpenMultipleArticleSizeListLocalStorage = JSON.parse(localStorage.getItem('MultipleArticleSizeListData'));
			localStorage.clear();
			if (this.popUpOpenLREditData != null &&
				this.popUpOpenLREditData != undefined &&
				this.popUpOpenLREditData == "MultipleArticleSizeData") {
				if (this.popUpOpenMultipleArticleSizeListLocalStorage != null &&
					this.popUpOpenMultipleArticleSizeListLocalStorage != undefined) {
					this.setMultiArtSizeDataTable();
				}
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsMultiArtTable = {
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			//place holder in search/filter in datatable ends
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 150,
			"scrollCollapse": true,
			//this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
			"paging": false,
			"info": true,
			pagingType: 'full_numbers',
			//pageLength: 9,
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerMultiArtTable.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerMultiArtTable.next();
	}

	/* on key press listner */

	keyPressListnerArticle(event) {
		if (event == 13) {
			$("#" + this.pageId + "multiArtlength").focus();
		}
	}

	keyPressListnerLength(event) {
		if (event == 13) {
			$("#" + this.pageId + "multiArtbreadth").focus();
		}
	}

	keyPressListnerBreadth(event) {
		if (event == 13) {
			$("#" + this.pageId + "multiArtheight").focus();
		}
	}

	keyPressListnerHieght(event) {
		if (event == 13) {
			$("#" + this.pageId + "addInTable").focus();
		}
	}

	/* on key press listner */

	setMultiArtSizeDataTable() {
		var multiArtSizeSplitHash = null;

		this.multiArtTableDataList = [];
		$("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
		console.log(this.popUpOpenMultipleArticleSizeListLocalStorage);
		if (this.popUpOpenMultipleArticleSizeListLocalStorage != null &&
			this.popUpOpenMultipleArticleSizeListLocalStorage != undefined &&
			this.popUpOpenMultipleArticleSizeListLocalStorage != "") {

			multiArtSizeSplitHash = this.popUpOpenMultipleArticleSizeListLocalStorage.split("#");
			console.log(multiArtSizeSplitHash);
			//multiArtSizeSplitUnderScope = this.popUpOpenMultipleArticleSizeListLocalStorage.split("_");

			if (multiArtSizeSplitHash.length > 0) {
				for (let i = 0; i < multiArtSizeSplitHash.length; i++) {

					var multiArtSizeSplitUnderScope = [];
					multiArtSizeSplitUnderScope = multiArtSizeSplitHash[i].split("_");
					console.log(multiArtSizeSplitUnderScope);
					if (multiArtSizeSplitUnderScope.length > 1) {
						this.newAttributeSetMultiArtSizeoOnLoadSet = {};
						this.newAttributeSetMultiArtSizeoOnLoadSet.articlesInMultiple = multiArtSizeSplitUnderScope[0];
						this.newAttributeSetMultiArtSizeoOnLoadSet.length = multiArtSizeSplitUnderScope[1];
						this.newAttributeSetMultiArtSizeoOnLoadSet.breadth = multiArtSizeSplitUnderScope[2];
						this.newAttributeSetMultiArtSizeoOnLoadSet.height = multiArtSizeSplitUnderScope[3];

						this.multiArtTableDataList.push(this.newAttributeSetMultiArtSizeoOnLoadSet);
					}
				}
				console.log(this.multiArtTableDataList);
				this.dtTriggerMultiArtTable.next();
			}
		} else {
			this.dtTriggerMultiArtTable.next();
		}
	}

	addInTable() {
		this.newAttribute.articlesInMultiple = $("#" + this.pageId + "multiArtarticle").val();
		this.newAttribute.length = $("#" + this.pageId + "multiArtlength").val();
		this.newAttribute.breadth = $("#" + this.pageId + "multiArtbreadth").val();
		this.newAttribute.height = $("#" + this.pageId + "multiArtheight").val();


		if (this.newAttribute.articlesInMultiple != '' && this.newAttribute.length != '' &&
			this.newAttribute.breadth != '' && this.newAttribute.height != '' &&
			this.newAttribute.articlesInMultiple != null && this.newAttribute.length != null &&
			this.newAttribute.breadth != null && this.newAttribute.height != null) {

			if (this.editSelected == true) {
				this.enterArticles = +this.enterArticles + -this.getArticles;
				this.editSelected = false;
			} else {
				this.editSelected = false;
			}
			this.isBlock = this.chkMulArtSize();
			if (this.isBlock == true) {
				swal("Alert", "Entered Articles Exceeds Actual Articles", "warning");
				$("#" + this.pageId + "multiArtarticle").val('');
				$("#" + this.pageId + "multiArtlength").val('');
				$("#" + this.pageId + "multiArtbreadth").val('');
				$("#" + this.pageId + "multiArtheight").val('');
			} else {
				if (this.editTableId != null) {
					this.multiArtTableDataList.splice(this.editTableId, 1);
				}
				if (this.multiArtTableDataList == null) {
					this.multiArtTableDataList = [];
				}

				if (this.multiArtTableDataList.length == 0) {
					this.multiArtTableDataList.push(this.newAttribute);
					this.newAttribute = {};
					$("#" + this.pageId + "multiArtarticle").val('');
					$("#" + this.pageId + "multiArtlength").val('');
					$("#" + this.pageId + "multiArtbreadth").val('');
					$("#" + this.pageId + "multiArtheight").val('');
					this.editTableId = null;
				} else {
					let array = this.multiArtTableDataList;
					this.multiArtTableDataList.push(this.newAttribute);
					this.newAttribute = {};
					$("#" + this.pageId + "multiArtarticle").val('');
					$("#" + this.pageId + "multiArtlength").val('');
					$("#" + this.pageId + "multiArtbreadth").val('');
					$("#" + this.pageId + "multiArtheight").val('');
					this.editTableId = null;
				}
			}
		}
		else {
			swal("Warning", "All Fields Are Mandetory", "warning");
		}
	}

	chkMulArtSize() {
		//here pending we need to get the foooter value of articles of Pop Up
		this.isBlock = false;
		this.totArticles = 0;
		this.enterArticles = 0;

		this.totArticles = this.popUpOpenLREditArticleData;
		console.log(this.totArticles);
		this.multiArtArticle = $("#" + this.pageId + "multiArtarticle").val();
		console.log('the Multi Artcicles :' + this.multiArtArticle);
		this.enterArticles = +this.enterArticles + +this.multiArtArticle;
		console.log('the Entered Artcicles :' + this.enterArticles);
		//enterArticles += nfMulArt.getValue().intValue();
		if (this.enterArticles > parseInt(this.totArticles)) {
			this.isBlock = true;
			//console.log( 'hee' );
			this.enterArticles = +this.enterArticles + -this.multiArtArticle;
			if (this.editTableId != null) {
				this.multiArtTableDataList.splice(this.editTableId, 1);
			}
			//console.log( 'if Block :' + this.enterArticles );
		} else {
			//console.log( 'see' );
			this.isBlock = false;
		}
		return this.isBlock;
	}

	rowSelectedEdit(multipleArtTableData, index) {
		this.editSelected = true;
		this.getArticles = multipleArtTableData.articlesInMultiple;
		$("#" + this.pageId + "multiArtarticle").val(multipleArtTableData.articlesInMultiple);
		$("#" + this.pageId + "multiArtlength").val(multipleArtTableData.length);
		$("#" + this.pageId + "multiArtbreadth").val(multipleArtTableData.breadth);
		$("#" + this.pageId + "multiArtheight").val(multipleArtTableData.height);
		$("#" + this.pageId + "hiddenIndex").val(index);
		this.editTableId = index;
	}

	rowSelectedDelete(multipleArtTableData, index) {
		this.multiArtTableDataList.splice(index, 1);
		this.getArticles = multipleArtTableData.articlesInMultiple;
		this.enterArticles = +this.enterArticles + -this.getArticles;
		$("#" + this.pageId + "multiArtarticle").val('');
		$("#" + this.pageId + "multiArtlength").val('');
		$("#" + this.pageId + "multiArtbreadth").val('');
		$("#" + this.pageId + "multiArtheight").val('');
		$("#" + this.pageId + "hiddenIndex").val('');
	}

	onCancelClick(): void {
		this.dialogRef.close();
		if (this.multiArtTableDataList != null &&
			this.multiArtTableDataList != undefined &&
			this.multiArtTableDataList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.multiArtTableDataList));
		} else {
			this.multiArtTableDataList = [];
			this.dialogRef.close(JSON.stringify(this.multiArtTableDataList));
		}
	}

	/*setMobileNoDataTable() {
		var mobileNoList = null;
		this.gridConsigneeMobileNoList = [];
		$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
		console.log(this.popUpOpenConsigneeMobileNoListLocalStorage);
		if (this.popUpOpenConsigneeMobileNoListLocalStorage != null &&
			this.popUpOpenConsigneeMobileNoListLocalStorage != undefined &&
			this.popUpOpenConsigneeMobileNoListLocalStorage != "") {

			mobileNoList = this.popUpOpenConsigneeMobileNoListLocalStorage.split("#");
			console.log(mobileNoList);
			if (mobileNoList.length > 0) {
				for (let i = 0; i < mobileNoList.length; i++) {
					this.newAttributeSetConsigneeMobileNoOnLoadSet = {};
					this.newAttributeSetConsigneeMobileNoOnLoadSet.mobileNo = mobileNoList[i];
					this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNoOnLoadSet);
				}
				console.log(this.gridConsigneeMobileNoList);
				this.dtTriggerConsigneeMultipleMobileNo.next();
			}
		} else {
			this.dtTriggerConsigneeMultipleMobileNo.next();
		}
	}

	addConsigneeMobileNoBtn() {
		if ($("#"+this.pageId+"multipleMobileNoConsignee").val() == null ||
			$("#"+this.pageId+"multipleMobileNoConsignee").val() == undefined ||
			$("#"+this.pageId+"multipleMobileNoConsignee").val() == "") {
			swal("Mandatory Field", "Please enter the Mobile Number", "warning");
			return false;
		} else {
			this.reconfigureAddMultiConsignorMobileNo();
		}

	}

	reconfigureAddMultiConsignorMobileNo() {
		this.newAttributeSetConsigneeMobileNo.mobileNo = $("#"+this.pageId+"multipleMobileNoConsignee").val();
		if (this.gridConsigneeMobileNoList == null) {
			this.gridConsigneeMobileNoList = [];
		}
		if (this.gridConsigneeMobileNoList.length == 0) {
			$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
			this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNo);
			this.dtTriggerConsigneeMultipleMobileNo.next();

			this.newAttributeSetConsigneeMobileNo = {};
			$("#"+this.pageId+"multipleMobileNoConsignee").val('');
		} else {
			const result = this.gridConsigneeMobileNoList.filter(gridConsigneeMobileNo =>
				gridConsigneeMobileNo.mobileNo === this.newAttributeSetConsigneeMobileNo.mobileNo);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Mobile Number " + this.newAttributeSetConsigneeMobileNo.mobileNo + " Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
				this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNo);
				this.dtTriggerConsigneeMultipleMobileNo.next();

				this.newAttributeSetConsigneeMobileNo = {};
				$("#"+this.pageId+"multipleMobileNoConsignee").val('');
			}
		}
	}

	deleteRowSelectedMobileNo(gridConsigneeMobileNo, index) {
		this.gridConsigneeMobileNoList.splice(index, 1);
		$("#"+this.pageId+"multipleMobileNoConsignee").val('');
		$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
		this.dtTriggerConsigneeMultipleMobileNo.next();
	}

	clearFieldBtn() {
		$("#"+this.pageId+"multipleMobileNoConsignee").val('');
	}
	*/



	onOkClickBtn(): void {
		this.dialogRef.close();
		/*if (this.gridConsigneeMobileNoList != null &&
			this.gridConsigneeMobileNoList != undefined &&
			this.gridConsigneeMobileNoList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		} else {
			this.gridConsigneeMobileNoList = [];
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		}*/
	}


}

