<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Memo Invoice</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>View Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-question"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
														<option selected value="invoiceNoWise">Invoice
															Number</option>
														<option value="dateWise">Date Wise</option>
														<option value="partyWise">Party Wise</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}gstNo">
                                                    <label>GST No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consignorName">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input class="auto_selectOption input is-medium" placeholder="Select Consignor" [formControl]="controlMain" [appAutocomplete]="autocompleteConsignorName">
                                                    </div>
                                                </div>
                                            </div>

                                            <app-autocomplete #autocompleteConsignorName="appAutocomplete">
                                                <ng-template appAutocompleteContent>
                                                    <ng-container *ngIf="(consignorNameOptions | filter: controlMain.value) as resultConsignorName">
                                                        <app-option *ngFor="let option of resultConsignorName" [value]="option.label"> {{ option.label }} </app-option>
                                                        <app-option class="no-result" *ngIf="!resultConsignorName.length">No result
                                                        </app-option>
                                                    </ng-container>
                                                </ng-template>
                                            </app-autocomplete>
                                        </div>
                                        <div *ngIf="viewTypeDateWise" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}fromDate">
                                                    <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewTypeDateWise" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                              <div class="input-group" id="{{pageId}}toDate">
                                <label>To Date</label>
                                <input ng-change="vm.dateString = (dateObj | date: 'dd/dd/yyyy')" class="form-control" (click)="d.toggle()" placeholder="yyyy-mm-dd"
                                  name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append" (click)="d.toggle()">
                                  <span class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>-->

                                        <!-- <div class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group" id="{{pageId}}source">
                          <label>Source</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-box"></i>
                            </span>
                          </div>
                           <select class="custom-select col-12">
                            <option selected>Select.....</option>
                            <option value="delhi">Delhi</option>
<option value="mumbai">Mumbai</option>
<option value="agra">Agra</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    
                    <div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group" id="{{pageId}}destination">
                          <label>Consignor Index</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class=" fas fa-road"></i>
                            </span>
                          </div>
                           <select class="custom-select col-12">
                            <option selected>Select.....</option>
                            <option value="1">A</option>
                            <option value="2">B</option>
                            <option value="3">C</option>
                            <option value="4">D</option>
                            <option value="5">E</option>
                            <option value="6">F</option>
                            <option value="7">G</option>
                          </select>
                        </div>
                      </div>
                    </div>-->
                                        <div *ngIf="viewTypeInvoiceNoWise" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}invoiceNumber">
                                                    <label>Invoice Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="box-body">
                            <h6 class="card-title border_bottom">Booking Memo Invoice Report
                            </h6>
                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMemoInvoiceReport" [dtTrigger]="dtTriggerMemoInvoiceReport">

                                <thead>
                                    <tr>
                                        <th>Invoice Number</th>
                                        <th>Consignor Name</th>
                                        <th>Invoice Date</th>
                                        <th>From Period</th>
                                        <th>To Period</th>
                                        <th>Tot LRs</th>
                                        <th>Tot Art</th>
                                        <th>Tot Chg Wt</th>
                                        <th>Tot Paid</th>
                                        <th>Ser.Tax</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let memoInvoiceReportData of memoInvoiceReportDataList ">
                                        <td>{{ memoInvoiceReportData.invoiceNumber }}</td>
                                        <td>{{ memoInvoiceReportData.consignorName }}</td>
                                        <td>{{ memoInvoiceReportData.invoiceDate }}</td>
                                        <td>{{ memoInvoiceReportData.fromPeriod }}</td>
                                        <td>{{ shortAndExtraReportData.toPeriod }}</td>
                                        <td>{{ shortAndExtraReportData.totalLrs }}</td>
                                        <td>{{ shortAndExtraReportData.totalArt }}</td>
                                        <td>{{ shortAndExtraReportData.totalChgWt }}</td>
                                        <td>{{ shortAndExtraReportData.totalPaid }}</td>
                                        <td>{{ shortAndExtraReportData.serTax }}</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>