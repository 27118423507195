<head>
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>

<!--<body>-->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showStockTripSheetMain">
	<div class="col-lg-12">
		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<div class="row">
					<div class="col-md-12">
						<h6 class="card-title text-white">Booking Local Trip</h6>
					</div>
				</div>
			</div>
			<div class="row system_responsive" style="margin-bottom: 5px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-12">
								<div class="row">
									<div class="col-md-2 ">
										<div class="card-body">
											<div class="row">
												<div class="col-sm-12 col-md-12">
													<div class="control">
														<div class="form-group">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-truck"></i>
																	</span>
																</div>
																<input #vehicleNumberDropDown
																	id="{{pageId}}dropDownInputFieldVehicleNumber"
																	type="text" class="form-control"
																	(selectItem)="clickListnerForDropDownInputFieldVehicleNumber($event,vehicleNumberPopUpTemplate)"
																	[(ngModel)]="modelDropDownVehicleNumber"
																	[ngbTypeahead]="searchDropDownInputFieldVehicleNumber"
																	[resultFormatter]="formatterDropDownVehicleNumber"
																	[inputFormatter]="formatterDropDownVehicleNumber"
																	placeholder="Select Vehicle Number"
																	(focus)="focusDropDownVehicleNumberTA$.next($any($event).target.value)" />
															</div>
														</div>
													</div>
												</div>

												<div class="col-sm-12 col-md-12">
													<div class="control">
														<div class="form-group">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="ti-user"></i>
																	</span>
																</div>
																<input #driverNameDropDown
																	id="{{pageId}}dropDownInputFieldDriverName"
																	type="text" class="form-control"
																	(selectItem)="clickListnerForDropDownInputFieldDriverName($event)"
																	[(ngModel)]="modelDropDownDriverName"
																	[ngbTypeahead]="searchDropDownInputFieldDriverName"
																	[resultFormatter]="formatterDropDownDriverName"
																	[inputFormatter]="formatterDropDownDriverName"
																	placeholder="Select Driver Name"
																	(focus)="focusDropDownDriverNameTA$.next($any($event).target.value)" />
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="control">
														<div class="form-group">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="ti-user"></i>
																	</span>
																</div>
																<input id="{{pageId}}inputFieldDriverConatctNumber"
																	type="text" class="form-control"
																	placeholder="Contact Number" autocomplete="off" />
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="control">
														<div class="form-group">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-search"></i>
																	</span>
																</div>
																<input #binsDropDown
																	id="{{pageId}}dropDownInputFieldBins" type="text"
																	class="form-control"
																	(selectItem)="clickListnerForDropDownInputFieldBins($event)"
																	[(ngModel)]="modelDropDownBins"
																	[ngbTypeahead]="searchDropDownInputFieldBins"
																	[resultFormatter]="formatterDropDownBins"
																	[inputFormatter]="formatterDropDownBins"
																	placeholder="Select the Bins"
																	(keypress)="onKeyPressListnerForBinsDestination($event);"
																	(focus)="focusDropDownBinsTA$.next($any($event).target.value)" />
															</div>
														</div>
													</div>
												</div>
												<div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-clipboard-list"></i>
																</span>
															</div>
															<input type="text" Placeholder="Scan Barcode"
																class="form-control" id="{{pageId}}lrNumber"
																autocomplete="off"
																(keypress)="onKeyPressListnerForLRNumber($event);"
																aria-describedby="basic-addon11">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-10 vl">
										<div class="card-body">
											<div class="row">
												<div class="col-md-6">
													<div class="box-body">
														<h6 class="card-title">Loading LRs</h6>
														<table datatable id="{{pageId}}eligibleLrsTableId"
															class="table table-striped table-bordered row-border hover"
															[dtOptions]="dtOptionsEligibleLrs"
															[dtTrigger]="dtTriggerEligibleLrs">
															<thead>
																<tr>
																	<th>Action</th>
																	<th>LR No</th>
																	<th>Tot Art</th>
																	<th>Scanned Art</th>
																	<th>Rem Art</th>
																	<th>Consignor</th>
																	<th>Consignee</th>
																	<th>Bkg Date</th>
																	<th>Act Wt</th>
																	<th>Chg Wt</th>
																	<th>Amount</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	*ngFor="let eligibleLrsData of eligibleLrsDataList ">
																	<td><button type="button"
																			style="padding: 1px 1px; background-color: lightgrey;"
																			class="btn m-r-10"
																			(click)="rowSelectedEligibleLrDelete(eligibleLrsData);">
																			<i title="Remove From Eligible Lrs"
																				class="fas fa-trash"></i>
																		</button></td>
																	<td>{{ eligibleLrsData.lrNumber }}</td>
																	<td>{{ eligibleLrsData.totalArticles }}</td>
																	<td>{{ eligibleLrsData.scannedArticles }}</td>
																	<td>{{ eligibleLrsData.remArticles }}</td>
																	<td>{{ eligibleLrsData.consignorName }}</td>
																	<td>{{ eligibleLrsData.consigneeName }}</td>
																	<td>{{ eligibleLrsData.bookingDateStr }}
																	</td>
																	<td>{{ eligibleLrsData.actualWeight }}</td>
																	<td>{{ eligibleLrsData.chargedWeight }}</td>
																	<td>{{ eligibleLrsData.totalAmount }}</td>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr>
															</tfoot>
														</table>
													</div>
												</div>

												<div class="col-md-6 vl">
													<div class="box-body">
														<h6 class="card-title">Stock LRs</h6>
														<table datatable id="{{pageId}}remainingLrsTableId"
															class="table table-striped table-bordered row-border hover"
															[dtOptions]="dtOptionRemainingLrs"
															[dtTrigger]="dtTriggerRemainingLrs">
															<thead>
																<tr>
																	<th>LR No</th>
																	<th>Articles</th>
																	<th>Consignor</th>
																	<th>Consignee</th>
																	<th>Bkg Date</th>
																	<th>Act Wt</th>
																	<th>Chg Wt</th>
																	<th>Amount</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	*ngFor="let remainingLrsData of remainingLrsDataList; let i=index ">
																	<td>{{ remainingLrsData.lrNumber }}</td>
																	<td>{{ remainingLrsData.totalArticles }}
																	</td>
																	<td>{{ remainingLrsData.consignorName }}
																	</td>
																	<td>{{ remainingLrsData.consigneeName }}
																	</td>
																	<td>{{ remainingLrsData.bookingDateStr }}
																	</td>
																	<td>{{ remainingLrsData.actualWeight }}</td>
																	<td>{{ remainingLrsData.chargedWeight }}
																	</td>
																	<td>{{ remainingLrsData.totalAmount }}</td>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr>
															</tfoot>
														</table>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12" style="text-align: right;">
										<button class="button_custome btn btn-info m-r-10"
											(click)="startOverBtnTripDetailsFrom()">Cancel</button>
										<button class="btn btn-success m-r-10 button_custome"
											id="{{pageId}}createTripSheetBtn"
											(click)="validateCreateTripSheet();">Create
											Trip</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Print Start-->
<div *ngIf="viewStockTripsheetPrint" onafterPopUp="afterPopUp()" id="{{pageId}}stockTripsheetPrintId">
	<app-stock-for-tripsheet-driver-print>
	</app-stock-for-tripsheet-driver-print>
</div>
<!--Print End -->

<!--POP UP START HERE-->
<div class="row">
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group">
				<ng-template #vehicleNumberPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h4 class="modal-title">Add New Vehicle</h4>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Add Vehicle Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-truck"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														id="{{pageId}}addNewVehicleNumberId"
														aria-describedby="basic-addon11" autocomplete="off">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Vehicle Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-id-card"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														id="{{pageId}}addNewVehicleNameId"
														aria-describedby="basic-addon11" autocomplete="off">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark"
							(click)="saveBtnVehicleNumberPopup();">Save</button>
						<button type="button" class="btn btn-outline-dark"
							(click)="resetBtnVehicleNumberPopup();">Reset</button>
						<button type="button" class="btn btn-outline-dark"
							(click)="closePopUpVehiclePopup();">Cancel</button>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<!--POP Up END HERE-->
<!--</body>-->