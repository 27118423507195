import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
@Component({
	selector: 'app-transit-lrs-details-report',
	templateUrl: './transit-lrs-details-report.component.html',
	styleUrls: ['./transit-lrs-details-report.component.css']
})
export class TransitLrsDetailsReportComponent implements OnInit {
	transitLrsDetailsReportDataList: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerTransitLrsDetailsReport: Subject<any> = new Subject();
	dtOptionsTransitLrsDetailsDetailsReport: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	dataForPopup: any;
	detailsFromPartyWiseTotalStatusRptPopup = new InvoiceDto();
	invoiceDtoForTransitLRsPopup = new InvoiceDto();
	consigneeNameInTable: any;
	pageId = "tlrsd";
	constructor(private router: Router, private consigneeService: ConsigneeService,
		public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.clearMethod();
			this.dataForPopup = JSON.parse(localStorage.getItem('transitLrsReportPopup'));
			localStorage.clear();
			if (this.dataForPopup != null) {
				this.detailsFromPartyWiseTotalStatusRptPopup = new InvoiceDto();
				this.detailsFromPartyWiseTotalStatusRptPopup = this.dataForPopup;
				if (this.detailsFromPartyWiseTotalStatusRptPopup != null) {
					this.searchTransitLrDetails();
				}
			}
			this.dataForPopup = '';
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}


	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsTransitLrsDetailsDetailsReport = {
			dom: 'Bfrtip',
			buttons: [],
			processing: true,
			responsive: true,
			"scrollX": false,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};


				var articles = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var topay = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actualWgt = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html(articles);
				$(api.column(5).footer()).html(topay);
				$(api.column(6).footer()).html(paid);
				$(api.column(7).footer()).html(actualWgt);
				$(api.column(8).footer()).html(chargedWgt);
			}

		}
	}

	ngOnDestroy(): void {
		this.dtTriggerTransitLrsDetailsReport.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerTransitLrsDetailsReport.next();
	}

	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}

	searchTransitLrDetails() {
		this.invoiceDtoForTransitLRsPopup = new InvoiceDto();
		this.invoiceDtoForTransitLRsPopup.consigneeName = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeName;
		this.invoiceDtoForTransitLRsPopup.consigneeId = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeId;
		//this.invoiceDtoForTransitLRsPopup.destination=this.detailsFromPartyWiseTotalStatusRptPopup.destination;
		this.invoiceDtoForTransitLRsPopup.status = "Transit";
		console.log(this.invoiceDtoForTransitLRsPopup);
		this.searchByDetailedMethodPopup();
	}
	searchByDetailedMethodPopup = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.getPartyTransitLrDetail(this.invoiceDtoForTransitLRsPopup).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "transitLrsDetailsId").DataTable().destroy();
				this.transitLrsDetailsReportDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.transitLrsDetailsReportDataList = response;
					console.log(this.transitLrsDetailsReportDataList);
				}
				setTimeout(() => {
					this.dtTriggerTransitLrsDetailsReport.next();
				},
					2000);

				this.consigneeNameInTable = this.detailsFromPartyWiseTotalStatusRptPopup.consigneeName;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Transit LR's View", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.invoiceDtoForTransitLRsPopup = new InvoiceDto();
		this.showSpinnerForAction = false;
		$("#" + this.pageId + "transitLrsDetailsId").DataTable().destroy();
		this.transitLrsDetailsReportDataList = [];
		this.dtTriggerTransitLrsDetailsReport.next();
		this.consigneeNameInTable = '';
	}
}