import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';

@Component({
    selector: 'app-staff-master',
    templateUrl: './staff-master.component.html',
    styleUrls: ['./staff-master.component.css']
})
export class StaffMasterComponent implements OnInit {

    dtTriggerStaffMaster: Subject<any> = new Subject();
    dtOptionsStaffMaster: any;
    pageId = "stmc";
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDtoSave: LRDto = new LRDto();
    enteredStaffName = null;
    staffMasterDataList: LRDto[];

    constructor(private masterService: MasterService, private router: Router,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStaffDetailsMethod();
        }
    }

    ngOnInit(): void {
        this.dtOptionsStaffMaster = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerStaffMaster.unsubscribe();
    }
    // ngAfterViewInit(): void {
    //     this.dtTriggerStaffMaster.next();
    // }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateSetupDetails() {
        swal({
            title: "Confirmation",
            text: "Sure you want to save/update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.saveSetupDetails();
            }
        })
    }

    userSetupDetails() {
        this.lrDtoSave = new LRDto();
        this.enteredStaffName = $("#" + this.pageId + "staffName").val();
        this.lrDtoSave.staffName = this.enteredStaffName;
        this.lrDtoSave.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSave.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
    }

    saveSetupDetails = () => {
        this.userSetupDetails();
        this.masterService.setupStaffDetails(this.lrDtoSave).
            subscribe((data) => {
                if (data.status == "Success") {
                    swal("Success", "Loader Name details saved/updated successfully!", "info");
                    this.clearFields();
                    this.getStaffDetailsMethod();
                } else {
                    swal("Failed", "Failed to save/update the Loader Name details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the Loader Name details!", "error");
            },
            () => console.log('done');
    }

    getStaffDetailsData() {
        this.lrDtoSave = new LRDto();
        this.lrDtoSave.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
    }
    getStaffDetailsMethod = () => {
        this.getStaffDetailsData();
        this.masterService.getStaffDetails(this.lrDtoSave).subscribe(
            (response) => {
                if (response) {
                    this.staffMasterDataList = response;
                    this.dtTriggerStaffMaster.next();
                }

                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Loader Name details", "info");
            },
            () => console.log('done');
    }

    clearFields() {
        this.enteredStaffName = null;
        $("#" + this.pageId + "staffName").val('');
    }

}

