import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { StockService } from 'src/app/dataService/stock-service';
//from the particular global folder ends
//for datatable ends

//for redirect to other page starts
import { Router } from '@angular/router';
//for redirect to other page ends


@Component( {
    selector: 'app-trip-creation-stock-details',
    templateUrl: './trip-creation-stock-details.component.html',
    styleUrls: ['./trip-creation-stock-details.component.css']
} )
export class TripCreationStockDetailsComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrStockDetailsTable: any;


    stockDetailsDataList: any;


    onDestroyUnsubscribtionStockDetails: Subscription;

    //for datatable ends

    //summaryTable:any;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerStockDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsStockDetails: any;
pageId="tcsdc";
    //for datatable ends

    constructor(/* for datatable starts */private stockDetailsScreen: StockService, public changeDetectorRef : ChangeDetectorRef,/* for datatable endss */
        //for redirect to other page starts
        private router: Router
        //for redirect to other page ends        

    ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsStockDetails = {

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Urgent LRs</i>',
                    titleAttr: 'Print Urgent LRs',

                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Urgent LRs</i>',
                    titleAttr: 'Excel Urgent LRs',
                    exportOptions: {
                        columns: ':visible'
                    }
                }


            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            pageLength: 8,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.gettingDataFrmServiceFrStockDetailsTable = this.stockDetailsScreen.getSupplierMasterData()
        this.onDestroyUnsubscribtionStockDetails = this.gettingDataFrmServiceFrStockDetailsTable.subscribe( data => {
            this.stockDetailsDataList = data['data'];
            this.dtTriggerStockDetails.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerStockDetails.unsubscribe();

        this.onDestroyUnsubscribtionStockDetails.unsubscribe();
        //for datatable ends
    }
    //for redirect to other page starts
    goToLrEnquiryPage() {
        this.router.navigate( ['/lr/enquiry/lrEnquiry'] );
    }
    //for redirect to other page starts

}