<div mat-dialog-content>
	<div class="row">
		<div class="col-lg-12">
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<label class="wtfull">PackNature</label>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">
							<label class="wtfull">Articles</label>
						</div>
					</div>
				</div>

			</div>
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="control">
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-user"></i>
									</span>
								</div>

								<input id="{{pageId}}multiPackNatureNew" type="text" class="form-control"
									[(ngModel)]="modelMultiPackNatureNew" [ngbTypeahead]="searchMultiPackNatureNew"
									(selectItem)="clickListnerForMultiPackNatureNew($event)"
									[resultFormatter]="formatterMultiPackNatureNew"
									[inputFormatter]="formatterMultiPackNatureNew"
									(focus)="focusMultiPackNatureNewTA$.next($any($event).target.value)"
									placeholder="Select Multi Pack Nanture"/>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="form-group">
						<div class="input-group">
							<input type="number" id="{{pageId}}multiPackNatureArtilces" name="multiPackNatureArtilces"
								class="form-control" aria-describedby="basic-addon11" #multiPackNatureArtilces
								(keypress)="onKeyPressListnerForArticle($event.keyCode)">&nbsp;
						</div>
					</div>
				</div>

				<div class="col-sm-12 col-md-5" style='display: none'>
					<div class="form-group">
						<div class="input-group">
							<input type="number" id="{{pageId}}hiddenIndexPackNature" class="form-control"
								aria-describedby="basic-addon11">&nbsp;
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-1">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<a href="javascript:;" type='button' id="{{pageId}}addInTablePackNature()" #addBtn
									class="btn btn-icon-only yellow" (click)="addInTablePackNature();"> <i
										class="fa fa-plus"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="box-body">
						<table datatable id="{{pageId}}multiPackNaturetimeTable"
							class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsMultiPackTable" [dtTrigger]="dtTriggerMultiPackTable">

							<thead>
								<tr>
									<th>PackNature</th>
									<th>Artilces</th>

									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let multiplePackNatureTableData of multiPackNatureTableDataList let j = index">
									<td>{{multiplePackNatureTableData.multiplePackNatureNew}}</td>
									<td>{{multiplePackNatureTableData.multiplePackNatureArt}}</td>
									<td>
										<button style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableEditBtn"
											(click)="rowSelectedEditPackNature(multiplePackNatureTableData,j);">
											<i title="Edit" class="fas fa-pencil-alt"></i>
										</button>&nbsp;
										<button style="padding: 1px 4px; background-color: #ffffff00;"
											class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
											(click)="rowSelectedDeletePackNature(multiplePackNatureTableData,j)">
											<i title="Remove" class="fas fa-trash"></i>
										</button>
									</td>
								</tr>
								<!-- 																						<td colspan="9">No Data To Display</td> -->
							</tbody>

						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>