<div class="page row system_responsive" id="{{pageId}}stmtReptLrDetailsprintIds">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-6">
                <h6>
                    <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                </h6>
            </div>
            <div class="col-md-6">
                <h6>{{address}}</h6>
            </div>
        </div>
        <table style='width: 100%;'>
            <tr>
                <td style='width: 30%;'>
                    <table>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Source</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{sourcePrint}}</span></td>
                        </tr>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Destination</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{destinationPrint}}</span></td>
                        </tr>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Vehicle Name</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{vehicleNamePrint}}</span></td>
                        </tr>
                    </table>
                </td>
                <td rowspan='3' style='width: 40%; word-wrap: break-word; text-align: center !important;'>
                    <strong style='font-size: 30px;'><u>Local Tripsheet</u></strong>
                </td>
                <td style='width: 30%;'>
                    <table>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Veh No</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{vehicleNumberPrint}}</span></td>
                        </tr>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Trip No</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{tripNoPrint}}</span></td>
                        </tr>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Date</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{tripDatePrint}}</span></td>
                        </tr>
                        <tr>
                            <td width='12%' style='text-align: left !important;'><strong style='font-size: 16px;'>Eway Bill</strong></td>
                            <td width='1%' style='text-align: left !important;'><strong style='font-size: 16px;'>:</strong></td>
                            <td width='17%' style='text-align: left !important;'><span style='font-size: 16px;'>{{ewayBillNo}}</span></td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <!--Second  table starts-->

        <!--<table width='100%' border='1' cellspacing='0' cellpadding='0'>
			<tr>
				<th width='5%' align='center' style='border:1px solid #000000; border-right:none;'>
					S.No</th>
				<th width='10%' align='center' style='border:1px solid #000000; border-right:none;'>
					LR No</th>
				<th width='20%' align='center' style='border:1px solid #000000; border-right:none;'>
					Consignee Name</th>
				<th width='15%' align='center' style='border:1px solid #000000; border-right:none;'>
					Destination</th>
				<th width='10%' align='center' style='border:1px solid #000000; border-right:none;'>
					Booking Date</th>
				<th width='7%' align='center' style='border:1px solid #000000; border-right:none;'>
					Article</th>
				<th width='6%' align='center' style='border:1px solid #000000; border-right:none;'>
					DD</th>
				<th width='7%' align='center' style='border:1px solid #000000; border-right:none;'>
					Pvt Mark</th>
				<th width='10%' align='center' style='border:1px solid #000000; border-right:none;'>
					Actual Weight</th>
				<th width='10%' height='50' align='center' style='border:1px solid #000000;'>Charged
					Weight</th>
			</tr>
			<tr *ngFor="let tripSheetForDriverData of tripSheetForDriverDataList ">
				<td width='5%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.size }}</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.lrNumber }}</td>
				<td width='20%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.consigneeName }}</td>
				<td width='15%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.destination }}</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.bookingDate }}</td>
				<td width='7%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.totalArticles }}</td>
				<td width='6%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.hamaliDD }}</td>
				<td width='7%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.privateMarker }}</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.actualWeight }}</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ tripSheetForDriverData.chargedWeight }}</td>
			</tr>
			<tr>
				<td colspan='2' width='15%' height='25' align='center' style='border:1px solid #000;'>
					{{this.totSizePrint}}</td>
				<td width='20%' height='25' align='center' style='border:1px solid #000;'>
				</td>
				<td width='15%' height='25' align='center' style='border:1px solid #000;'>
				</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
				</td>
				<td width='7%' height='25' align='center' style='border:1px solid #000;'>
					{{totArticlePrint}}</td>
				<td width='6%' height='25' align='center' style='border:1px solid #000;'>
					{{ totHamaliDDPrint }}</td>
				<td width='7%' height='25' align='center' style='border:1px solid #000;'>
				</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ totActWgtPrint }}</td>
				<td width='10%' height='25' align='center' style='border:1px solid #000;'>
					{{ totChgWgtPrint }}</td>
			</tr>
		</table>-->

        <!--<div class="container border">
			<div>-->
        <table width='100%' border='1' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
            <tr>
                <td width='5%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>S.No</strong></td>

                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Booking Date</strong></td>

                <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> LR No</strong></td>

                <td width='6%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Articles</strong></td>

                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Destination</strong></td>

                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> Pvt Mark</strong></td>

                <td width='25%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Unloading Marking</strong></td>

                <td width='7%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Act Wgt</strong></td>

                <td width='7%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Chg Wgt</strong></td>
            </tr>
            <ng-container *ngFor="let group of prepareDataForGrouping()">
                <tr>
                    <td colspan="12" style='border: 1px solid #000;'>
                        <strong>{{ group.destination }}</strong>
                    </td>

                </tr>


                <tr *ngFor="let tripSheetForDriverData of group.data">

                    <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span> {{tripSheetForDriverData.size}} </span></td>

                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.bookingDateStr}} </span></td>

                    <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span> {{tripSheetForDriverData.lrNumber}}
				</span></td>

                    <td width='6%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.totalArticles}} </span></td>

                    <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.destination}} </span></td>

                    <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.privateMarker}} </span></td>

                    <td width='25%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span> </span></td>

                    <td width='7%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.actualWeight}} </span></td>

                    <td width='7%' align='center' style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; height: 40px; padding-top: 17px; font-size: 17px;' valign='top'><span>
						{{tripSheetForDriverData.chargedWeight}}</span></td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td align='center'>{{ calculateTotalArticles(group.data) }}</td>
                    <td colspan="3"></td>

                    <td align='center'>{{ calculateTotalActualWeight(group.data) }}</td>
                    <td align='center'>{{ calculateTotalChargedWeight(group.data) }}</td>

                </tr>
            </ng-container>
            <tr>
                <td width='15%' colspan='2' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Total </strong><strong>
						{{totSizePrint}} </strong></td>
                <!--<td width='10%' align='center'
							style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;'
							valign='top' type='number'><strong> </strong></td>-->
                <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>

                <td width='6%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>{{totArticlePrint}}
				</strong></td>

                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>

                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>

                <td width='25%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>

                <td width='7%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>
						{{totActWgtPrint}}</strong></td>

                <td width='7%' align='center' style='border-top: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>{{totChgWgtPrint}} </strong></td>
            </tr>
        </table>
        <!--</div>
		</div>-->
        <!--Second  table end-->
        <!--Third table start-->
        <table width='100%' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td width='50%'>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <tr>
                    <td align='right'>
                        <table width='98%' border='0' cellpadding='0' cellspacing='0'>
                            <tr>
                                <td><strong>Labour Names:</strong><span style='font-size: 16px;'>&nbsp;{{labourHamaliStmtPrint}}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <td align='right'>
                    <table width='98%' border='0' cellpadding='0' cellspacing='0'>
                        <tr>
                            <td><strong>CHECKED BY</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><strong>SIGNATURE</strong></td>
                        </tr>
                    </table>
                </td>
                <td align='right'>
                    <table width='50%' border='0' cellpadding='0' cellspacing='0'>
                        <tr>
                            <td><strong>DRIVER </strong></td>
                        </tr>
                        <tr>
                            <td>{{driverNamePrint}}<br>{{driverMobileNoPrint}}
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><strong>SIGNATURE</strong></td>
                        </tr>
                    </table>
                </td>
        </table>
        <!--Third table end-->
    </div>
</div>