import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts

//from the particular global folder ends
//for datatable ends
//for popup modal ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
export interface DialogData {
    listOfDataToPass: string[];
}
import swal from 'sweetalert';
//for modal ends
@Component( {
    selector: 'app-trip-lr-details',
    templateUrl: './trip-lr-details.component.html',
    styleUrls: ['./trip-lr-details.component.css']
} )
export class TripLrDetailsComponent implements OnInit {

    //for datatable starts

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;

    lrDetailsDataList: any;
    //    listOfDataToGet: LRDto = new LRDto();
    listOfDataToGet: any;
    list: string[];
    pageTitle = "Local Booking LR Detail";



    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLrDetails: any;

    //for datatable ends
pageId="tlrdt";
    constructor(
            /* for datatable starts */private masterReadService: MasterReadService, /* for datatable ends */
        //for modal starts
        public dialogRef: MatDialogRef<TripLrDetailsComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) {
        this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

        this.listOfDataToGet = this.data.listOfDataToPass;

        //        console.log( "Finally >>> " + this.listOfDataToGet );
        this.getDetailsOfTripLrDetails();

    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsLrDetails = {
            //};



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: this.changeDataTableTitileForExcel(),
                    messageTop: this.chnageDataTableMessageTopExcel(),
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    title: this.changeDataTableTitileForPrint(),
                    //                    messageTop: this.chnageDataTableMessageTop(),
                    autoPrint: true,
                    footer: true,

                }
            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

            "footerCallback": function( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function( i ) {
                    return typeof i === 'string' ?
                        +i.replace( /[\$,]/g, '' ) * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };



                // Total over this page

                var pageTotals = api
                    .column( 2 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b )
                    }, 0 );
                $( api.column( 2 ).footer() ).html(
                    pageTotals
                );



                var pageTotals = api
                    .column( 6 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b )
                    }, 0 );
                $( api.column( 6 ).footer() ).html(
                    pageTotals
                );



                var pageTotals = api
                    .column( 7 )
                    .data()
                    .reduce( function( a, b ) {
                        return intVal( a ) + intVal( b )
                    }, 0 );
                $( api.column( 7 ).footer() ).html(
                    pageTotals
                );


                //                count starts
                var columnData = api
                    .column(
                    0 )
                    .data();

                // Update footer
                $( api.column( 0 ).footer() ).html(
                    columnData.count()
                );
                //                counts ends
            }
            //end footerCallback      
        }
    }
    //the below code is for the getting data through json starts
    //            this.supplierList.getAllData().subscribe(data => {
    //                this.lrDispatchBknRptList = data['data'];
    //                this.dtTriggerSummary.next();
    //                } );
    //        this.gettingDataFrmServiceFrLrDetailsTable = this.lrDetailsScreen.getSupplierMasterData()
    //        this.onDestroyUnsubscribtionLrDetails = this.gettingDataFrmServiceFrLrDetailsTable.subscribe( data => {
    //            this.lrDetailsDataList = data['data'];
    //            this.dtTriggerLrDetails.next();
    //        } );
    //for datatable ends
    //the below code is for the getting data through json ends





    changeDataTableTitileForPrint() {
        return '<div class="row">'
            + '<div class="col-md-7">'
            + '<img src="assets/images/srdLogisticPrintLogo.png">'
            + '</div>'
            + '<div class="col-md-5">'
            + '<p style="font-size:16px;">' + this.userDataDtoReturnSession.addressId
            + '</p>'
            + '</div>'
            + '</div>'
            + '<div class="row"  style="text-align:center">'
            + '<div class="col-md-12">'
            + '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' + this.pageTitle + '</span></h5>'
            + '</div>'
            + '</div>'
            + '<div class="row">'
            + '<div class="col-md-4">'
            + '<h6 style="font-weight:bold;">Trip Date: <span style="font-weight:400;">' + this.listOfDataToGet.tripDate + '</span></h6>'
            + '</div>'
            + '<div class="col-md-4">'
            + '<h6 style="font-weight:bold;">Agent Name: <span style="font-weight:400;">' + this.listOfDataToGet.tripFrom + '</span></h6>'
            + '</div>'
            + '<div class="col-md-4">'
            + '<h6 style="font-weight:bold;">Delivery Area: <span style="font-weight:400;">' + this.listOfDataToGet.deliveryarea + '</span></h6>'
            + '</div>'
            + '</div>'
            + '<div class="row">'
            + '<div class="col-md-3">'
            + '<h6 style="font-weight:bold;">Vehicle No: <span style="font-weight:400;">' + this.listOfDataToGet.vehicleNumber + '</span></h6>'
            + '</div>'
            + '<div class="col-md-3">'
            + '<h6 style="font-weight:bold;">Trip No: <span style="font-weight:400;">' + this.listOfDataToGet.tripNo + '</span></h6>'
            + '</div>'
            //            + '<div class="col-md-3">'
            //            + '<h6 style="font-weight:bold;">Total Lrs: <span style="font-weight:400;">' + this.totalLrs + '</span></h6>'
            //            + '</div>'
            //            + '<div class="col-md-3">'
            //            + '<h6 style="font-weight:bold;">Total Articles: <span style="font-weight:400;">' + this.totalArticles + '</span></h6>'
            //            + '</div>'
            + '</div>'
    }

    //    chnageDataTableMessageTop() {
    //        return '<span>'
    //            + '<div style="text-align:center;">'
    //            + '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' + this.pageTitle + '</span></h5>'
    //            + '<h6 style="font-weight:bold;">'
    //            + "Date: " + '<span style="font-weight:400;">' + this.todayDate + '</span>'
    //            + '</h6>'
    //            + '</div>'
    //            + '</span>';
    //
    //    }
    changeDataTableTitileForExcel() {
        return "SRDlogo"
            + this.userDataDtoReturnSession.addressId

    }
    chnageDataTableMessageTopExcel() {
        return + this.pageTitle
        //            + this.selectedFromDate
        //            + "                 "
        //            + this.selectedToDate

    }

    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerLrDetails.unsubscribe();

        //for datatable ends


    }

    //for modal starts
    onCancelClick(): void {
        this.dialogRef.close();
    }
    //for modal ends

    getDataForRead() {
        this.lrDto.id = this.listOfDataToGet.id;
        //        console.log( this.lrDto.id );
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;




        //        this.getAgentNamesToStations();
    }
    getDetailsOfTripLrDetails() {
        //        console.log( this.lrDto.companyId );
        this.getDataForRead();
        this.masterReadService.getTripLrService( this.lrDto ).subscribe(
            ( response ) => {

                if ( response.length == 0 ) {
                    swal( {
                        title: "Error",
                        text: "Np Records Founds!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } )
                } else {
                    this.lrDetailsDataList = response;
                    //                    console.log( this.lrDetailsDataList );
                    this.dtTriggerLrDetails.next();
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Trip LR Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );

    };
}
