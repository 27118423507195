export class TripSheetDto {
    vehiclenumber: string;
    fromdate: string;
    todate: string;
    tripdate: Date;
    deliveryarea: string;
    totallrs: number;
    totalarticles: number;
    drivername: string;
    totalamount: number;
    mode: string;
    area: string;
    partyname: string;
    id: number;
    tripNo: number;
    transportName: string;
    ddAmt: number;
    totalarticlesForVehi: number;
    ddAmtForVehi: number;
    totalamountForVehi: number;
    station: string;
    mainStation: string;
    totalPersons: number;
    amtPerHead: number;
    actWeight: number;
    chgWeight: number;
    HamaliAmt: number;
    srdAmt: number;
    consignorId: number;
    consigneeId: number;
    tripDateStr: string;
    office: string;
    reportMode: string;
    consigneeName: string;
    fromDatePrint: Date;
    toDatePrint: Date;
    fromStation: string;
    bookingDate: Date;
    unloadingDate: Date;
    arrivalwithin: number;
    deliveredwithin: number;
    HamaliGD: number;
    lrNumber: string;
    source: string;
    consignorName: string;
    hamaliNames: string;
    headDesc: string;
    headCategory: string;
    name: string;
    isActive: boolean;
    status: string;
    //    HashSet<String> hashSet;
    userName: string;
    date: Date;
    startKM: number;
    endKM: number;
    runKM: number;
    percentage: number;
    contactNumber: string;
    companyId: string;
    deliveryMan: string;
    ewayBill: string;
    lrDDAmt:number
}