<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <br>
        <div class="row">
            <table width='75%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;margin-left: 50px;'>
                <tr>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;Invoice Number</strong></td>
                    <td width='70%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{tpStmtNo}}</strong></td>
                </tr>
                <tr *ngIf="showStmtPeriod">
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;Invoice Period</strong></td>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{tpStmtFromDate}} To {{tpStmtToDate}}</strong></td>
                </tr>
                <tr *ngIf="showStmtDate">
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;Till Date</strong></td>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{tpStmtDate}}</strong></td>
                </tr>
                <tr>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;Consignor Name</strong></td>
                    <td width='70%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{toPartyName}}</strong></td>
                </tr>
                <tr>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;Address</strong></td>
                    <td width='70%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{toPartyAddress}}</strong></td>
                </tr>
                <tr>
                    <td width='30%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;GSTIN No</strong></td>
                    <td width='70%' style='border: 1px solid #000;word-wrap: break-word;'><strong>&nbsp;&nbsp;{{toPartyGst}}</strong></td>
                </tr>
            </table>
        </div>
        <br>
        <br>
        <!--Second  row starts-->
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <tr>
                        <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>Destination</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>LR No</strong></td>
                        <td width='30%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Consignee Name</strong></td>
                        <td width='5%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Art</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong>Act Wgt</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>Chg Wgt</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>Memo Amt</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>Bkg Date</strong></td>
                    </tr>
                    <tr *ngFor="let toPartyDetailsData of toPartyDetailsDataList">
                        <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appMemoNumberPrint'><strong>
								{{toPartyDetailsData.destination}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appEnteredDatePrint'><strong>
								{{toPartyDetailsData.lrNumber}} </strong></td>
                        <td width='30%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appAssignedDatePrint'><strong>
								{{toPartyDetailsData.consigneeName}} </strong></td>
                        <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='number' id='{{pageId}}appAmountPrint'><strong>
								{{toPartyDetailsData.articles}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appLrNumberPrint'><strong>
								{{toPartyDetailsData.actualWeight}} </strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appPartyNamePrint'><strong>
								{{toPartyDetailsData.chargedWeight}}</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appPartyNamePrint'><strong>
													{{toPartyDetailsData.grandTotal}}</strong></td>
                        <td width='10%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;' valign='top' type='text' id='{{pageId}}appPartyNamePrint'><strong>
							{{this.datePipe.transform( toPartyDetailsData.bookingDate, "dd-MM-yyyy" )}}</strong></td>
                    </tr>
                    <tr>
                        <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> Total : </strong><strong>
								{{totalCount}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                        <td width='30%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                        <td width='5%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> {{totalArt}}</strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='number'><strong> {{totalActWgt}}</strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>{{totalChgWgt}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong>{{totalPaid}} </strong></td>
                        <td width='10%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; word-wrap: break-word;' valign='top' type='text'><strong> </strong></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h6 style='font-size: 25px; margin-left: 40px;text-decoration:underline;'>
                    <strong>Terms</strong>
                </h6>
                <ul style="list-style-type: square;font-size: 20px;font-weight: bold;margin-left: 20px;">
                    <li>GST to be paid to authorities @5% on RCM basis.</li>
                    <li>Any discrepancy should be notified to us in writing within 7 days from the date of invoice.</li>
                </ul>
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-md-5">
                <h6 style='font-size: 25px; margin-left: 25px;text-decoration:underline;'>
                    <strong>Bank Details</strong>
                </h6>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <tr>
                        <td width='25%' align='center'><strong>Account Name</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{acName}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>Account No</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{acNo}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>Bank Name</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{bankName}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>Branch Name</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{branchName}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>IFSC Code</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{ifscCode}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>Tansporter Id</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{transportedId}}</strong></td>
                    </tr>
                    <tr>
                        <td width='25%' align='center'><strong>Pan No</strong></td>
                        <td width='5%' align='center'><strong>:</strong></td>
                        <td width='70%' align='center'><strong>{{panNo}}</strong></td>
                    </tr>
                </table>
            </div>
            <br>
            <br>
            <div class="col-md-7">
                <h6 style='font-size: 17px;text-decoration:underline;text-align:center;'>
                    <strong>Scan QR for Quick Payment</strong>
                </h6>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <tr>
                        <td align='center'><strong>UPI Id : {{upiCode}}</strong></td>
                    </tr>
                    <tr>
                        <img src={{paymentQrCode}} alt="QRCode" style="display: block;margin-left: auto;margin-right: auto;">
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>