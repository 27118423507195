<!-- Row -->
<div class="row"  *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Challan Issue</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
													<label>Agent Name</label>

													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="ti-home"></i>
														</span>
													</div>

													<input #agentName id="{{pageId}}agentName" type="text" class="form-control"
														placeholder="Select Agent" [(ngModel)]="challanAgentName"
														[ngModelOptions]="{standalone: true}"
														[ngbTypeahead]="agentNameSearchTA"
														[resultFormatter]="formatterAgentName"
														[inputFormatter]="formatterAgentName"
														(focus)="focusAgentNameTA$.next($any($event).target.value)"
														(keyup)="agentNameFocus($event)"
														#instanceAgentName="ngbTypeahead" />
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Date</label> <input id="{{pageId}}date" class="form-control"	[(ngModel)]="dateRange"(keyup)="dateFocus($event)"(keydown.arrowdown)="dateDates.toggle();"
														placeholder="yyyy-mm-dd" name="dateDates" ngbDatepicker
														#dateDates="ngbDatepicker">
													<div class="input-group-append" (click)="dateDates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" >
													<label>From :</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-road"></i>
														</span>
													</div>
													<input #fromChallanNumber type="text" class="form-control"id="{{pageId}}fromChallanNumber"	(keyup)="fromChallanNoFocus($event)"	maxlength="10"
														aria-describedby="basic-addon11">
												</div> 
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" >
													<label>To: </label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-road"></i>
														</span>
													</div>
													<input #toChallanNumber type="text" class="form-control"id="{{pageId}}toChallanNumber" (keyup)="toChallanNoFocus($event)"	maxlength="10"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>

									
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>No.Of Challans</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
													</div>
													<input #numberOfChallans type="number"id="{{pageId}}numberOfChallans"  class="form-control" aria-describedby="basic-addon11" readonly>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"(click)="btnValidationCheck();">Save</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearAll();">Clear</button>
					</div>
				</div>


				<div class="col-md-9 vl p-t-10">
					<div class="row">
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
								
									<div class="row">
										<div class="col-md-12">
											<h6 class="card-title border_bottom">Chellan Details</h6>
											<div class="box-body">
												<table datatable id="{{pageId}}chellanDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsChellanDetails" [dtTrigger]="dtTriggerChellanDetails">

													<thead>
														<tr>
															<th>Agent Name</th>
															<th>Date</th>
															<th>From Challan No</th>
															<th>To Challan No</th>
															<th>No of Challan</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let chellanDetailsData of chellanDetailsDataList ">
															<td>{{ chellanDetailsData.agentName }}</td>
															<td>{{ chellanDetailsData.bookingDateStr}}</td>
															<td>{{ chellanDetailsData.fromLrNo }}</td>
															<td>{{ chellanDetailsData.toLrNo }}</td>
															<td>{{ chellanDetailsData.noOfChallan }}</td>
															<td><button type="button"
																style="padding: 1px 4px; background-color: lightgrey;"
																class="btn m-r-10"
																(click)="rowRemove(chellanDetailsData);">
																<i title="Remove"
																	class="fas fa-trash"></i>
															</button></td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>
												</table>


											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>