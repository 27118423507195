<div mat-dialog-content>
	<div class="row">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Booking Cashmemo</h6>
				</div>
				<div class="row">
					<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 15px;">Please
									Wait Loading....</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-4">
										<div class="form-group">
											<div class="input-group">
												<label>LR Number</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-clipboard"></i>
													</span>
												</div>
												<input #lrNumber type="text" id="{{pageId}}lrNumber"
													class="form-control" aria-describedby="basic-addon11"
													(keyup)="getLrInfo($event)" [(ngModel)]="enteredLrNumber">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-8">
										<button type="submit" class="btn btn-success m-r-10"
											id="{{pageId}}getBtn" (click)="lrDetails()">Get Details</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-3"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<h6 class="card-title">LR Details</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Memo No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-clipboard"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby="basic-addon11" id="{{pageId}}memoNo"
													[readonly]="isReadOnly">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Booking Date</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-clipboard-list"></i>
													</span>
												</div>
												<input type="text" id="{{pageId}}bookingDateForBkg"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnly" [(ngModel)]="enteredBookingDate">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Commodity</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-clipboard-list"></i>
													</span>
												</div>
												<input type="text" id="{{pageId}}commodityForBkg" class="form-control"
													aria-describedby="basic-addon11" [readonly]="isReadOnly">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Source Station</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-home"></i>
													</span>
												</div>
												<input type="text" id="{{pageId}}sourceStation" class="form-control"
													aria-describedby="basic-addon11" [readonly]="isReadOnly">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Destination</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-road"></i>
													</span>
												</div>
												<input type="text" id="{{pageId}}destinationForBkg"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnly">
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <hr> -->
						</div>
					</div>
					<div class="col-md-3 vl">
						<div class="card">
							<div class="card-body">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Agent</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class=" fas fa-user"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}agentNameForBkg" class="form-control"
												aria-describedby="basic-addon11" [readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Consignor Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class=" fas fa-user"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}consignorNameForBkg"
												class="form-control" aria-describedby="basic-addon11"
												[readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Consignee Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class=" fas fa-user"></i>
												</span>
											</div>
											<input type="text" id="{{pageId}}consigneeNameForBkg"
												class="form-control" aria-describedby="basic-addon11"
												[readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>No.Of Article</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-clone"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}noOfArticle" class="form-control"
												aria-describedby="basic-addon11" [readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Actual Weight </label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-weight"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}actualWeightForBkg"
												class="form-control" aria-describedby="basic-addon11"
												[readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Charged Weight</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-weight"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}chargedWeightForBkg"
												class="form-control" aria-describedby="basic-addon11"
												[readonly]="isReadOnly">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Paid</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class=" fas fa-rupee-sign"></i>
												</span>
											</div>
											<input #paidForBkg type="number" id="{{pageId}}paidForBkg"
												class="form-control" aria-describedby="basic-addon11"
												[readonly]="isReadOnly">
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>



					<div class="col-md-3 vl">
						<div class="card">
							<div class="card-body">
								<h6 class="card-title">Billing Details</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Service Charges</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #serviceChargesForBkg type="number"
													id="{{pageId}}serviceChargesForBkg" class="form-control"
													aria-describedby="basic-addon11"
													[readonly]="isReadOnlyForEdit"
													(blur)="serviceChargeBlur($event)"
													[(ngModel)]="getServiceChargesForBkg">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Recipt Charges</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #receiptChargesForBkg type="number"
													id="{{pageId}}receiptChargesForBkg" class="form-control"
													aria-describedby="basic-addon11"
													[readonly]="isReadOnlyForEdit"
													(blur)="receiptTaxBlur($event)"
													[(ngModel)]="getReceiptChargesForBkg">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label> Dest. Hamali per Art</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #destHamaliPerArt type="number" id="{{pageId}}destHamaliPerArt"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnlyForEdit"
													(blur)="destHamaliPerArtBlur($event)"
													[(ngModel)]="getDestHamaliPerArtForBkg">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Destination Hamali </label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #destinationHamaliForBkg type="number"
													id="{{pageId}}destinationHamaliForBkg" class="form-control"
													aria-describedby="basic-addon11" [readonly]="isReadOnly"
													(blur)="destHamali($event)"
													[(ngModel)]="getDestinationHamaliForBkg">
											</div>
										</div>
									</div>
									<div *ngIf="showDiscount" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Discount</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #discountForBkg type="number" id="{{pageId}}discountForBkg"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnlyForEdit"
													(blur)="discountBlur($event)"
													[(ngModel)]="getDiscountForBkg">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Sub Total</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #subTotal type="number" id="{{pageId}}subTotal"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnly" [(ngModel)]="getSubTotalForBkg">
											</div>
										</div>
									</div>
									<div *ngIf="showServiceTax" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Service Tax</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input #serviceTax type="number" id="{{pageId}}serviceTax"
													class="form-control" aria-describedby="basic-addon11"
													[readonly]="isReadOnlyForEdit"
													(blur)="serviceTaxBlur($event)">
											</div>
										</div>
									</div>
									<div *ngIf="showServiceTaxAmt" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Service Tax Amt</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" id="{{pageId}}serviceTaxAmt" class="form-control"
													aria-describedby="basic-addon11" [readonly]="isReadOnly">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12 ">
										<div class="form-group ">
											<div class="input-group">
												<label>Grand Total</label>
												<div class="input-group-prepend ">
													<span class="input-group-text "> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="number" id="{{pageId}}grandTotal" class="form-control "
													aria-describedby="basic-addon11 " [readonly]="isReadOnly">
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div *ngIf="showGst" class="col-md-3 vl">
						<div class="card">
							<div class="card-body">
								<h6 class="card-title">GST Details</h6>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>CGST</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-rupee-sign"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}cgstPerc" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getCgstPerc">&nbsp;
											<input type="number" id="{{pageId}}cgstAmt" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getCgstAmt">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>SGST</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-rupee-sign"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}sgstPerc" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getSgstPerc">&nbsp;
											<input type="number" id="{{pageId}}sgstAmt" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getSgstAmt">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>IGST</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-rupee-sign"></i>
												</span>
											</div>
											<input type="number" id="{{pageId}}igstPerc" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getIgstPerc">&nbsp;
											<input type="number" id="{{pageId}}igstAmt" class="form-control"
												aria-describedby="basic-addon11" [(ngModel)]="getIgstAmt">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>GST Total</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-rupee-sign"></i>
												</span>
											</div>
											<input #gstTotal type="number" id="{{pageId}}gstTotal"
												class="form-control" aria-describedby="basic-addon11"
												[(ngModel)]="getGstTotalAmount">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-3"></div>
					<div class="col-md-6" style="text-align: center;">
						<button *ngIf="showEdit" type="submit"
							class="btn btn-success m-r-10" id="{{pageId}}editBtn"
							(click)="validateEdit()">Edit</button>
						<button #savenprintBtn *ngIf="showAsSave" type="submit"
							class="btn btn-dark" id="{{pageId}}savenprintBtn" (click)="validateSave()">Save</button>
						<button #savenprintBtn *ngIf="!showAsSave" type="submit"
							class="btn btn-dark" id="{{pageId}}savenprintBtn" (click)="validateSave()">Save
							& Print</button>
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn"
							(click)="validateClear()">Clear</button>
					</div>
					<div class="col-md-3"></div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>
