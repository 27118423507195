import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { DatePipe } from "@angular/common";
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

//for datatable ends

@Component({
    selector: 'app-booking-agent-statement',
    templateUrl: './booking-agent-statement.component.html',
    styleUrls: ['./booking-agent-statement.component.css']
})
export class BookingAgentStatementComponent implements  OnInit {
    getDataBookingAgentStatementDataTable: any;

    bookingAgentStatementDataList: any;

    onDestroyUnsubscribtionBookingAgentStatement: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBookingAgentStatement: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsBookingAgentStatement: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;

    rateMasterDto: RateMasterDto = new RateMasterDto();
pageId="bkasc";
    constructor(private dashboardService: DashboardService, public dialog: MatDialog,
        private route: ActivatedRoute, private router: Router, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        //  the ten datatable starts
        this.dtOptionsBookingAgentStatement = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                // destroy:true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalamt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalamt);
            }
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerBookingAgentStatement.unsubscribe();
    // }

    ngAfterViewInit(): void {
        this.dtTriggerBookingAgentStatement.next();
    }

    gridReconifgureOnReloadBtn() {
        this.setBookingAgentStmtDetailsList();
    }

    getBookingAgentStmtDetailsList() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.status = "Pending";
        this.rateMasterDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.rateMasterDto.mode = "allBkgAgtStmtDashboard";
    }

    setBookingAgentStmtDetailsList() {
        this.getBookingAgentStmtDetailsList();
        this.showSpinnerForAction = true;
        this.dashboardService.getBookingAgentStatementDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.bookingAgentStatementDataList = [];
                //$("#"+this.pageId+"bookingAgentStmtDtlsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.bookingAgentStatementDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerBookingAgentStatement.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Booking Agent Statement Details", "warning");
            },
            () => console.log('done');
    };

    getBookingAgentStmtHyperLinkDetails(bookingAgentStatementData) {
        console.log(bookingAgentStatementData);
        localStorage.clear();
        localStorage.setItem('bookingAgentStatementDataLocalStorage', JSON.stringify(bookingAgentStatementData));
        this.router.navigate(['/dashboard/bookingAgentStatementDetails']);
    }

}
