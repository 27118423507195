<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}bkgAgtRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Booking Agent Report</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <!-- <h6 class="card-title">Tempo Details</h6> -->
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}agentType" name="agentType" #agentType (change)="agentTypeMode(agentType.value)">
                                                    <option selected value="subAgent">Sub Agent</option>
                                                    <option value="mainAgent">Main Agent</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Search By</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">>
                                                    <option selected value="destinationWise">Destination
                                                        Wise</option>
                                                    <option value="summaryDetails">Summary Details</option>
                                                    <option value="toPayReport">ToPay Report</option>
                                                    <option value="paidReport">Paid Report</option>
                                                    <option value="agentWiseBookingReport">AgentWise
                                                        Booking Report</option>
                                                    <option value="agentWiseBookingReportItemWise">AgentWise
                                                        Booking Report(ItemWise)</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="agentTypeSubAgent" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Agent Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}subAgentName" name="subAgentName" type="text" class="form-control" [(ngModel)]="modelSubStationName" [ngbTypeahead]="searchSubAgentName" [resultFormatter]="formatterSubAgentName" [inputFormatter]="formatterSubAgentName" (focus)="focusSubAgentNameTA$.next($any($event).target.value)"
                                                        placeholder="Select Agent" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="agentTypeMainAgent" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Main Agent </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}mainAgentName" name="mainAgentName" type="text" class="form-control" [(ngModel)]="modelMainAgentName" [ngbTypeahead]="searchMainAgentName" [resultFormatter]="formatterMainAgentName" [inputFormatter]="formatterMainAgentName" (focus)="focusMainAgentNameTA$.next($any($event).target.value)"
                                                        placeholder="Select MainAgent" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                <div class="input-group-append" (click)="fromDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                <div class="input-group-append" (click)="toDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12" *ngIf="showSrc">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source(Collection Center)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-box"></i>
                                                    </span>
                                                </div>
                                                <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                />
                                            </div>

                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearch()">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="box-body">
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row p-t-10">
                            <div class="col-md-8">
                                <h6 class="card-title" [hidden]="!agentReportDestinationWiseDetailsTable">AGENT REPORT DESTINATION WISE DETAILS
                                </h6>
                                <h6 class="card-title" [hidden]="!agentReportSummaryDetaLilsTable">AGENT REPORT SUMMARY DETAILS
                                </h6>
                                <h6 class="card-title" [hidden]="!agentToPayReportDataListTable">AGENT TOPAY REPORT
                                </h6>
                                <h6 class="card-title" [hidden]="!agentPaidReportDataListTable">AGENT PAID REPORT
                                </h6>
                                <h6 class="card-title" [hidden]="!agentWiseBooingReportDataListTable">AgentWise Booking Report
                                </h6>
                                <h6 class="card-title" [hidden]="!itemWiseTable">AgentWise Booking Report(ItemWise)
                                </h6>


                            </div>
                            <div class="col-md-4">
                                <button type="submit" class="dt-button" [hidden]="!agentReportDestinationWiseDetailsTable" style="margin-left: 75%;" id="{{pageId}}printBtnForDetails" (click)="pritnDetailsWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                <button type="submit" class="dt-button" [hidden]="!agentReportSummaryDetaLilsTable" id="{{pageId}}printBtnForDetail" style="margin-left: 75%;" (click)="pritnSummaryWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                <button type="submit" class="dt-button" [hidden]="!agentToPayReportDataListTable" style="margin-left: 75%;" id="{{pageId}}printBtnForTopay" (click)="pritnToPayWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                <button type="submit" class="dt-button" [hidden]="!agentPaidReportDataListTable" id="{{pageId}}printBtnForPaid" style="margin-left: 75%;" (click)="pritnPaidWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                <button type="submit" class="dt-button" [hidden]="!agentWiseBooingReportDataListTable" style="margin-left: 75%;" id="{{pageId}}printBtnForAgentBkg" (click)="pritnAgentBkgWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>
                                <button type="submit" class="dt-button" [hidden]="!itemWiseTable" id="{{pageId}}printBtnForAgentBkgItem" style="margin-left: 75%;" (click)="pritnAgentBkgItemWise()">
                                    <span><i class="fas fa-print">Print</i></span>
                                </button>


                            </div>
                        </div>
                        <div class="box-body">
                            <div [hidden]="!agentReportDestinationWiseDetailsTable">
                                <table id="{{pageId}}agentReportDestinationWiseDetailsId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentReportDestinationWiseDetails" [dtTrigger]="dtTriggerAgentReportDestinationWiseDetails">
                                    <thead>
                                        <tr>
                                            <th>LR No</th>
                                            <th>Destination</th>
                                            <th>Actual Wt</th>
                                            <th>Charged Wt</th>
                                            <th>Articles</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentReportDestinationWiseDetailsData of agentReportDestinationWiseDetailsDataList ">
                                            <td>{{ agentReportDestinationWiseDetailsData.lrNumber }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.destination }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.actualWeight }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.chargedWeight }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.totalArticles }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.toPay }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.paid }}</td>
                                            <td>{{ agentReportDestinationWiseDetailsData.lcChg }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div class="box-body">
                            <div [hidden]="!agentReportSummaryDetaLilsTable">
                                <table id="{{pageId}}agentReportSummaryDetaLilsId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentReportSummaryDetails" [dtTrigger]="dtTriggerAgentReportSummaryDetails">
                                    <thead>
                                        <tr>
                                            <th>Station</th>
                                            <th>Actual Weight</th>
                                            <th>Charged Weight</th>
                                            <th>Articles</th>
                                            <th>To Pay</th>
                                            <th>Paid</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentReportSummaryDetailsData of agentReportSummaryDetailsDataList ">
                                            <td>{{ agentReportSummaryDetailsData.destination }}</td>
                                            <td>{{ agentReportSummaryDetailsData.actualWeight }}</td>
                                            <td>{{ agentReportSummaryDetailsData.chargedWeight }}</td>
                                            <td>{{ agentReportSummaryDetailsData.totalArticles }}</td>
                                            <td>{{ agentReportSummaryDetailsData.toPay }}</td>
                                            <td>{{ agentReportSummaryDetailsData.paid }}</td>
                                            <td>{{ agentReportSummaryDetailsData.lcChg }}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!agentToPayReportDataListTable">
                                <table id="{{pageId}}agentToPayReportId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentToPayReport" [dtTrigger]="dtTriggerAgentToPayReport">
                                    <thead>
                                        <tr>
                                            <th>Destination</th>
                                            <th>LR No</th>
                                            <th>Booking Date</th>
                                            <th>Invoice No</th>
                                            <th>Consignor Name</th>
                                            <th>Consignor GST No</th>
                                            <th>To Pay</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentToPayReportData of agentToPayReportDataList ">
                                            <td>{{ agentToPayReportData.destination }}</td>
                                            <td>{{ agentToPayReportData.lrNumber }}</td>
                                            <td>{{ agentToPayReportData.bookingDateStr }}</td>
                                            <td>{{ agentToPayReportData.invoiceNumber }}</td>
                                            <td>{{ agentToPayReportData.consignorName }}</td>
                                            <td>{{ agentToPayReportData.gstNoConsignor }}</td>
                                            <td>{{ agentToPayReportData.toPay }}</td>
                                            <td>{{ agentToPayReportData.lcChg }}</td>
                                        </tr>


                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div class="box-body">
                            <div [hidden]="!agentPaidReportDataListTable">
                                <table id="{{pageId}}agentPaidReportId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentPaidReport" [dtTrigger]="dtTriggerAgentPaidReport">
                                    <thead>
                                        <tr>
                                            <th>Destination</th>
                                            <th>LR No</th>
                                            <th>Booking Date</th>
                                            <th>Invoice No</th>
                                            <th>Consignor Name</th>
                                            <th>Paid</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentPaidReportData of agentPaidReportDataList ">
                                            <td>{{ agentPaidReportData.destination }}</td>
                                            <td>{{ agentPaidReportData.lrNumber }}</td>
                                            <td>{{ agentPaidReportData.bookingDateStr }}</td>
                                            <td>{{ agentPaidReportData.invoiceNumber }}</td>
                                            <td>{{ agentPaidReportData.consignorName }}</td>
                                            <td>{{ agentPaidReportData.paid }}</td>
                                            <td>{{ agentPaidReportData.lcChg }}</td>
                                        </tr>


                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!agentWiseBooingReportDataListTable">
                                <table id="{{pageId}}agentWiseBooingReportId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentWiseBooingReport" [dtTrigger]="dtTriggerAgentWiseBooingReport">
                                    <thead>
                                        <tr>
                                            <th>Booking Item</th>
                                            <th>Actual Weight</th>
                                            <th>% Of Booking</th>
                                            <th>Charged Weight</th>
                                            <th>% Of Booking</th>
                                            <th>Articles</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentWiseBooingReportData of agentWiseBooingReportDataList ">
                                            <td>{{ agentWiseBooingReportData.destination }}</td>
                                            <td>{{ agentWiseBooingReportData.actualWeight }}</td>
                                            <td>{{agentWiseBooingReportData.actWtPercent }}</td>
                                            <td>{{ agentWiseBooingReportData.chargedWeight }}</td>
                                            <td>{{agentWiseBooingReportData.chgWtPercent }}</td>
                                            <td>{{ agentWiseBooingReportData.totalArticles }}</td>
                                            <td>{{ agentWiseBooingReportData.lcChg }}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>

                        <div class="box-body">
                            <div [hidden]="!itemWiseTable">
                                <table id="{{pageId}}agentWiseBooingReportItemWiseId" datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAgentWiseBooingReportItemWise" [dtTrigger]="dtTriggerAgentWiseBooingReportItemWise">
                                    <thead>
                                        <tr>
                                            <th>Booking Item</th>
                                            <th>Actual Weight</th>
                                            <th>% Of Booking</th>
                                            <th>Charged Weight</th>
                                            <th>% Of Booking</th>
                                            <th>Articles</th>
                                            <th>Lc Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let agentWiseBooingReportItemWiseData of agentWiseBooingReportItemWiseDataList ">
                                            <td>{{ agentWiseBooingReportItemWiseData.destination }}</td>
                                            <td>{{ agentWiseBooingReportItemWiseData.actualWeight }}</td>
                                            <td>{{agentWiseBooingReportItemWiseData.actWtPercent}}</td>
                                            <td>{{ agentWiseBooingReportItemWiseData.chargedWeight}}</td>
                                            <td>{{agentWiseBooingReportItemWiseData.chgWtPercent}}</td>
                                            <td>{{ agentWiseBooingReportItemWiseData.totalArticles }}</td>
                                            <td>{{ agentWiseBooingReportItemWiseData.lcChg }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>


                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>




                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>