import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { LRDto } from "src/app/dto/LR-dto";

@Component({
    selector: 'app-multiple-article-size-V3-popup',
    templateUrl: './multiple-article-size-V3-popup.component.html',
    styleUrls: ['./multiple-article-size-V3-popup.component.css']
})
export class MultipleArticleSizeV3PopupComponent implements OnInit {
    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    lrDetailsDataList: any;
    listOfDataToGet: any;
    listOfDataToGetDWS: any;
    list: string[];
    splitArticlesDetails: string;
    commaSplitArticle: any;
    undersCodeSplitTime: string[];
    articlesDataList: any;
    articlesDataListDWS: any;
    pageId = "maspv3c";
    loadingIndicator = true;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;


    constructor(
        public changeDetectorRef: ChangeDetectorRef
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.listOfDataToGet = JSON.parse(localStorage.getItem('popupListOfMultipleArticles'));
        this.listOfDataToGetDWS = JSON.parse(localStorage.getItem('popupListOfMultipleArticlesDWS'));
        this.setValuesInTable();
    }

    ngOnInit(): void {
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            buttons: [],
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": false,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totLength = api.column(0).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBreadth = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totHeight = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totVolumnCft = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSize = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totLengthDWS = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totBreadthDWS = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totHeightDWS = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totVolumnCftDWS = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totSizeDWS = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html(totLength.toFixed(0));
                $(api.column(1).footer()).html(totBreadth.toFixed(0));
                $(api.column(2).footer()).html(totHeight.toFixed(0));
                $(api.column(3).footer()).html(totVolumnCft.toFixed(0));
                $(api.column(4).footer()).html(totSize.toFixed(0));

                $(api.column(5).footer()).html(totLengthDWS.toFixed(0));
                $(api.column(6).footer()).html(totBreadthDWS.toFixed(0));
                $(api.column(7).footer()).html(totHeightDWS.toFixed(0));
                $(api.column(8).footer()).html(totVolumnCftDWS.toFixed(0));
                $(api.column(9).footer()).html(totSizeDWS.toFixed(0));
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();
    }

    setValuesInTable() {
        this.articlesDataList = this.listOfDataToGet
        this.articlesDataListDWS = this.listOfDataToGetDWS
    }
}
