//for datepicker starts
import {
    NgbDateStruct,
    NgbCalendar,
    NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";

  /*for popup*/ import {
    NgbModal,
    ModalDismissReasons,
    NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";

//for datepicker ends

//for datepicker starts
import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { NgModule, ViewChild,ChangeDetectorRef} from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ElementRef } from "@angular/core";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from "src/app/dataService/master-service";
//from the particular global folder ends

import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
//for the select option with filter ends
const my = new Date();
//for datepicker ends
import { LRDto } from "src/app/dto/LR-dto";
//for the select option with filter ends

import { ReportService } from "src/app/dataService/report-service";
import { HttpClient } from '@angular/common/http';

import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
// import { flattenStyles } from "@angular/platform-browser/src/dom/dom_renderer";



@Component({
    selector: "app-lr-issue-report",
    templateUrl: "./lr-issue-report.component.html",
    styleUrls: ["./lr-issue-report.component.css"],
})
export class LrIssueReportComponent implements OnInit {
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    selectedFromDatePrint: any;
    selectedToDatePrint: any;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends
    srcForRpt = "Lr Issue Rpt Show Src All";

    getDataFrmServiceFrTable: any;
    issueReportDataList: any;
    selectedAgent: any;
    selectedFromDate: any;
    selectedToDate: any;
    //    onDestroyUnsubscribtionIssueReport: Subscription;

    controlMainSource = new FormControl();
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    lrDto: LRDto = new LRDto();
    agentDetailsSourceOptions: AgentDetailsDto[];
    @ViewChildren(DataTableDirective)
    public dtElements: QueryList<DataTableDirective>;

    dtTriggerIssueReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsIssueReport: any;
    pageTitle = "LR Issue Report";

    ///For Agent Names

    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;

    agentDtoSourceStationAllOption: AgentDetailsDto = new AgentDetailsDto();

    showSpinnerForAction = false;

    superAdminview: boolean = false;
    agentDetailsOptionsGet: any;
    agentNameList: AgentDetailsDto = new AgentDetailsDto();
    address: any;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    lrDtoForBtnSearch: LRDto = new LRDto();
    viewCustomPrintV1 = false;
    grandTotalLrs = 0;

    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    /////
	pageId="lrirc";
    
    constructor(
        private masterService: MasterService,
        private http: HttpClient,
        private modalService: NgbModal,
        private masterReadService: MasterReadService,
        private reportService: ReportService, private datePipe: DatePipe,
        private router: Router,
        public changeDetectorRef : ChangeDetectorRef
    ) {



        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        //Rights For Agent
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.address =
                this.userDataDtoReturnSession.addressId == null
                    ? ""
                    : this.userDataDtoReturnSession.addressId;

            //  alert("welcome");


            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] =="LrIssueRpt ShowAll") {
                        this.superAdminview = true;
                        console.log(this.superAdminview);
                    }
                }
            }
            this.getAgentDetailList();
            this.selectTodayDate();
            console.log(this.superAdminview);
        }
    }

    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#"+this.pageId+"fromDate").val(this.setTodayDateOnFromDate);
        $("#"+this.pageId+"toDate").val(this.setTodayDateOnToDate);
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {

        this.dtOptionsIssueReport = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {

                    var returnPaidDest = null;

                    returnPaidDest = "LR Issue Report" +
                        " From Date : " + moment($("#lrircfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#lrirctoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#lrircagentName").val();
                    return returnPaidDest;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": false,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };


                var totallr = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html();
                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html(totallr);


            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerIssueReport.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerIssueReport.next();
    }
    // changeDataTableTitile() {
    //     return (
    //         '<table><tr style="width:100%"><td style="width:60%"><img src="assets/images/srdLogisticPrintLogo.png"></td>' +
    //         '<td style="width:40%"><p>' +
    //         this.userDataDtoReturnSession.addressId +
    //         "</p></td></tr></table>"
    //     );
    // }

    // chnageDataTableMessageTop() {
    //     this.selectedFromDatePrint = $("#"+this.pageId+"fromDate").val();
    //     this.selectedToDatePrint = $("#"+this.pageId+"toDate").val();
    //     return (
    //         "<span>" +
    //         '<div style="text-align:center;">' +
    //         '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' +
    //         this.pageTitle +
    //         "</span></h5>" +
    //         '<h6 style="font-weight:bold;">' +
    //         "From : " +
    //         '<span style="font-weight:400;">' +
    //         this.selectedFromDatePrint +
    //         "</span>" +
    //         "To : " +
    //         '<span style="font-weight:400;">' +
    //         this.selectedToDatePrint +
    //         "</span>" +
    //         "</h6>" +
    //         "</div>" +
    //         "</span>"
    //     );
    // }
    // chnageDataTableMessageTopExcel() {
    //     this.selectedFromDatePrint = $("#"+this.pageId+"fromDate").val();
    //     this.selectedToDatePrint = $("#"+this.pageId+"toDate").val();
    //     return (
    //         "From: " +
    //         this.selectedFromDatePrint +
    //         "           " +
    //         "To: " +
    //         this.selectedToDatePrint
    //     );
    // }
    //For Agent Name Listener
    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#"+this.pageId+"agentName").val(this.modelAgentName.subStation);
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate(),
        };
    }

    //for datePicker



    validateBtnSearch() {
        this.getSearchMethod();
    }

    getSearchMethod() {
        this.selectedAgent = $("#"+this.pageId+"agentName").val();
        this.selectedFromDatePrint = $("#"+this.pageId+"fromDate").val();
        this.selectedToDatePrint = $("#"+this.pageId+"toDate").val();
        if (this.selectedFromDatePrint == null || this.selectedFromDatePrint == '' || this.selectedToDatePrint == null || this.selectedToDatePrint == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {

            this.lrDtoForBtnSearch = new LRDto();
            if (this.selectedAgent != null && this.selectedAgent == "All") {
                this.lrDtoForBtnSearch.mode = "ALL";
            } else {
                this.lrDtoForBtnSearch.mode = "SPECIFIC";
            }
            if (this.superAdminview == false) {
                this.lrDtoForBtnSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            }
            this.lrDtoForBtnSearch.agentName = this.selectedAgent;
            this.lrDtoForBtnSearch.fromDate = this.selectedFromDatePrint;
            this.lrDtoForBtnSearch.toDate = this.selectedToDatePrint
            this.lrDtoForBtnSearch.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.lrDtoForBtnSearch);
            this.getSearchMethodDetails();
        }
    }
    getSearchMethodDetails = () => {
        this.showSpinnerForAction = true;
        this.reportService.getLRIssueReportForLr(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"issueReportTableId").DataTable().destroy();
                this.issueReportDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.issueReportDataList = response;

                }
                this.dtTriggerIssueReport.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the LR Issue Report details", "info");
            }, () => console.log('done');
    };

    clearAll() {

        $("#"+this.pageId+"fromDate").val('');
        $("#"+this.pageId+"toDate").val('');
        $("#"+this.pageId+"agentName").val('');
        this.setTodayDateOnFromDate='';
        this.setTodayDateOnToDate='';
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedFromDatePrint= '';
        this.selectedToDatePrint = '';
        this.selectedAgent = '';
        this.lrDtoForBtnSearch = new LRDto();

        //For Custom Print
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.grandTotalLrs = 0;
        $("#"+this.pageId+"issueReportTableId").DataTable().destroy();
        this.issueReportDataList = [];
        this.dtTriggerIssueReport.next();
        this.showSpinnerForAction = false;
        localStorage.clear();
        this.selectTodayDate();


    }
flattenStyles



    getUserValuesForAgentDetailsList() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        this.agentDto.mode = "Sub";
        this.agentDto.status = "All";

        if (this.superAdminview == true) {
            this.agentDto.mode = "Sub";
            this.agentDto.status = "All";
        } else {
            this.agentDto.mode = "mainBranch";
            //As per asrar bhai 
            // if ( this.mainStationRpt == 'Paid Rpt Bkg Main Station' ) {
            //     this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
            // } else {
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
            // }
        }
        console.log(this.agentDto);
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                this.agentNameTA = [];
                this.agentNameOptions = [];

                console.log(this.agentNameOptions);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    this.agentDtoSourceStationAllOption.subStation = "All";
                    this.agentNameTA.push(this.agentDtoSourceStationAllOption);
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        //console.log( this.agentDetailsOptionsGet[i].subStation );

                        this.agentNameTA.push(this.agentNameOptions[i]);
                        
                    }

                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };
    printMethodForDateWise() {
        this.dateWisePrintMethod();

    }
    dateWisePrintMethod() {
        this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#"+this.pageId+"toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgent = $("#"+this.pageId+"agentName").val();

        if (this.issueReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Issue Date", "LR From", "LR To", "No Of LRS"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.grandTotalLrs = 0;


            for (let i = 0; i < this.issueReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.issueReportDataList[i].agentName, this.issueReportDataList[i].dateInDate, this.issueReportDataList[i].fromLrNo, this.issueReportDataList[i].toLrNo, this.issueReportDataList[i].noOfLrs];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalLrs = +this.grandTotalLrs + +this.issueReportDataList[i].noOfLrs;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.issueReportDataList.length, "", "", "", this.grandTotalLrs];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];




            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedAgent];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "LR Issue Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }




}
