import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';

@Component({
    selector: 'app-stock-for-tripsheet-driver-print-v2',
    templateUrl: './stock-for-tripsheet-driver-print-v2.component.html',
    styleUrls: ['./stock-for-tripsheet-driver-print-v2.component.css']
})
export class StockForTripsheetDriverPrintV2Component implements OnInit {

    getDataFrmServiceFrTable: any;
    onDestroyUnsubscribtionStatementReport: Subscription;
    model: NgbDateStruct;
    model2;
    loadingIndicator = true;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dataTable: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;
    tripSheetForDriverDataList: any;
    lrDto: LRDto = new LRDto();
    lrDtoList: Array<LRDto> = [];
    lrDtoForTripsheepPrintLocalStorage: any;
    sourcePrint: any;
    destinationPrint: any;
    vehicleNamePrint: any;
    vehicleNumberPrint: any;
    tripNoPrint: any;
    tripDatePrint: any;
    labourHamaliStmtPrint: any;
    driverNamePrint: any;
    driverMobileNoPrint: any;
    ewayBillNo: any;

    memoHamaliDD: any = null;
    totActWgtPrint: any = null;
    totChgWgtPrint: any = null;
    totHamaliDDPrint: any = null;
    totSizePrint: any = null;
    totArticlePrint: any = null;
    pageId = "sftdp";

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.lrDtoForTripsheepPrintLocalStorage = JSON.parse(localStorage.getItem('stockTripsheetDriverPrint'));
            console.log("Driver Print from trip");
            console.log(this.lrDtoForTripsheepPrintLocalStorage);
            if (this.lrDtoForTripsheepPrintLocalStorage != null &&
                this.lrDtoForTripsheepPrintLocalStorage != undefined &&
                this.lrDtoForTripsheepPrintLocalStorage != "") {
                this.setFieldsValue(this.lrDtoForTripsheepPrintLocalStorage);
            }
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }

    }

    setFieldsValue(lrDtoForTripsheepPrintLocalStorage) {
        this.sourcePrint = lrDtoForTripsheepPrintLocalStorage.branch;
        this.destinationPrint = lrDtoForTripsheepPrintLocalStorage.areaOfDelivery;
        this.vehicleNamePrint = lrDtoForTripsheepPrintLocalStorage.transportName;
        this.vehicleNumberPrint = lrDtoForTripsheepPrintLocalStorage.vehicleNumber;
        this.tripNoPrint = lrDtoForTripsheepPrintLocalStorage.localTripNumber == null ? lrDtoForTripsheepPrintLocalStorage.tripNo : lrDtoForTripsheepPrintLocalStorage.localTripNumber;
        this.tripDatePrint = lrDtoForTripsheepPrintLocalStorage.tripDate == null ? null : moment(lrDtoForTripsheepPrintLocalStorage.tripDate).format("DD-MM-YYYY hh:mm a");
        this.ewayBillNo = lrDtoForTripsheepPrintLocalStorage.eWayBillNo == null ? 'NA' : lrDtoForTripsheepPrintLocalStorage.eWayBillNo;

        this.memoHamaliDD = 0.0;
        this.totActWgtPrint = 0;
        this.totChgWgtPrint = 0;
        this.totHamaliDDPrint = 0;
        this.totSizePrint = 0;
        this.totArticlePrint = 0;
        this.lrDtoList = [];
        //console.log(lrDtoForTripsheepPrintLocalStorage.lrDtoEligible);
        for (let i = 0; i < lrDtoForTripsheepPrintLocalStorage.lrDtoEligible.length; i++) {
            this.lrDto = new LRDto();

            this.memoHamaliDD = 0.0;
            /*this.memoHamaliDD = +lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].memoHamaliDD == null ? 0 : +lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].memoHamaliDD +
                +lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].tripsheetHamali == null ? 0 : +lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].tripsheetHamali;
           */
            this.memoHamaliDD = +(lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].memoHamaliDD == null ? 0 : lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].memoHamaliDD)
                + +(lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].tripsheetHamali == null ? 0 : +lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].tripsheetHamali);
            //console.log(this.memoHamaliDD);
            this.lrDto.size = i + +1;
            this.lrDto.lrNumber = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].lrNumber;
            this.lrDto.consigneeName = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].consigneeName;
            this.lrDto.destination = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].destination;
            this.lrDto.bookingDateStr = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].bookingDateStr;
            this.lrDto.totalArticles = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].totalArticles;
            this.lrDto.actualWeight = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].actualWeight;
            this.lrDto.chargedWeight = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].chargedWeight;
            this.lrDto.hamaliDD = this.memoHamaliDD;
            this.lrDto.privateMarker = lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].privateMarker == null ? "NA" : lrDtoForTripsheepPrintLocalStorage.lrDtoEligible[i].privateMarker;

            this.lrDtoList.push(this.lrDto);

            this.totActWgtPrint = +this.totActWgtPrint + +this.lrDto.actualWeight;
            this.totChgWgtPrint = +this.totChgWgtPrint + +this.lrDto.chargedWeight;
            this.totHamaliDDPrint = +this.totHamaliDDPrint + +this.lrDto.hamaliDD;

            this.totArticlePrint = +this.totArticlePrint + +this.lrDto.totalArticles;
        }
        if (this.lrDtoList.length > 0) {
            this.tripSheetForDriverDataList = this.lrDtoList;
        }
        this.totSizePrint = lrDtoForTripsheepPrintLocalStorage.totalLrs;
        this.labourHamaliStmtPrint = lrDtoForTripsheepPrintLocalStorage.labourHamaliStmt == null ? "" : lrDtoForTripsheepPrintLocalStorage.labourHamaliStmt;
        this.driverNamePrint = lrDtoForTripsheepPrintLocalStorage.driverName;
        this.driverMobileNoPrint = lrDtoForTripsheepPrintLocalStorage.mobileNum == null ? "NA" : lrDtoForTripsheepPrintLocalStorage.mobileNum;
    }

    clearField() {
        this.sourcePrint = null;
        this.destinationPrint = null;
        this.vehicleNamePrint = null;
        this.vehicleNumberPrint = null;
        this.tripNoPrint = null;
        this.tripDatePrint = null;
        this.labourHamaliStmtPrint = null;
        this.driverNamePrint = null;
        this.driverMobileNoPrint = null;
        this.ewayBillNo = null;

        this.memoHamaliDD = null;
        this.totActWgtPrint = null;
        this.totChgWgtPrint = null;
        this.totHamaliDDPrint = null;
        this.totSizePrint = null;
        this.totArticlePrint = null;
        this.lrDtoForTripsheepPrintLocalStorage = new LRDto();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

    }

 //CHG_Ver_01
 prepareDataForGrouping() {
    const groupedData = {};

    this.tripSheetForDriverDataList.forEach(item => {

        const destination = item.destination;

        if (!groupedData[destination]) {
            groupedData[destination] = [];
        }

        groupedData[destination].push(item);
    });

    Object.keys(groupedData).forEach(destination => {
        groupedData[destination].forEach((item, index) => {
            item.serialNumber = index + 1; 
        });
    });

    const result = Object.keys(groupedData).map(destination => ({
        destination,
        data: groupedData[destination]
    }));

    return result;
}
calculateTotalArticles(data: any[]): number {
    let totalArticles = 0;
    data.forEach(item => {
        totalArticles += item.totalArticles;
    });
    return totalArticles;
}



calculateTotalActualWeight(data: any[]): number {
    let totalActualWeight = 0;
    data.forEach(item => {
        totalActualWeight += item.actualWeight;
    });
    return totalActualWeight;
}

calculateTotalChargedWeight(data: any[]): number {
    let totalChargedWeight = 0;
    data.forEach(item => {
        totalChargedWeight += item.chargedWeight;
    });
    return totalChargedWeight;
}

}
