<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetails">
            <ng-template #content let-a="close" let-b="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Report Short & Extra</h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Marks As</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
												class="fas fa-search"></i>
											</span>
                                            </div>
                                            <select class="custom-select col-12" id="{{pageId}}marksAs" name="markAs" #markAs (change)="markAsMode(markAs.value)">
											<option value="short">Short</option>
											<option value="extra">Extra</option>
											<option value="openCondition">Open Condition</option>
											<option value="wetCondition">Wet Condition</option>
											<option value="invoiceClear">Invoice Clear</option>
										</select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="otherView">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LR Number :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
													class="fas fa-file-alt"></i>
												</span>
                                                </div>
                                                <input type="text" id="{{pageId}}lrNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="lrNumberOnEnter($event)" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
													class="fas fa-road"></i>
												</span>
                                                </div>
                                                <input type="text" id="{{pageId}}destination" class="form-control" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Number of Articles :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
													class="fas fa-clone"></i>
												</span>
                                                </div>
                                                <input type="number" id="{{pageId}}noOfArticles" class="form-control" aria-describedby="basic-addon11" (keyup)="noOfArtOnEnter($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Remarks :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
													class="fas fa-file-alt"></i>
												</span>
                                                </div>
                                                <input type="text" id="{{pageId}}remarks" class="form-control" aria-describedby="basic-addon11" (keyup)="remarksOnEnter($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Invoice Number :</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
												class="fas fa-file-alt"></i>
											</span>
                                            </div>
                                            <input type="text" id="{{pageId}}invoiceNumber" class="form-control" (keyup)="invoiceOnEnter($event)" aria-describedby="basic-addon11" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Truck Number :</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
												class="fas fa-truck"></i>
											</span>
                                            </div>
                                            <input type="text" id="{{pageId}}truckNumber" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" id={{pageId}}confirmBtn (click)="submitChanges();">Confirm</button>

                    <button type="button" class="btn btn-outline-dark" (click)="b('Save click')">Cancel</button>
                </div>
            </ng-template>

            <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="open2(content)">Click Here</a>
        </div>
    </div>
</div>