<h1 class="title_custom" mat-dialog-title>
    <h6 style="margin-bottom: 0px;">LR Details Of Remaining Cashmemo</h6>
</h1>
<div mat-dialog-content>
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}lrDetailsOfremCashMemoId">
        <div class="col-md-12">
            <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintConsolInvoiceRpt()"
                id="{{pageId}}printAllBtn">
                <span><i class="fas fa-print">Print</i></span>
            </button>
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <table datatable id="{{pageId}}lrDetailsOfRemainingCashmemoId"
                            class="table table-striped table-bordered row-border hover"
                            [dtOptions]="dtOptionsLrDetailsOfRemainingCashmemo"
                            [dtTrigger]="dtTriggerLrDetailsOfRemainingCashmemo">

                            <thead>
                                <tr>
                                    <th>Memonumber</th>
                                    <th>Memo Date</th>
                                    <th>No. Of Articles</th>
                                    <th>LR Number</th>
                                    <th>Consignee</th>
                                    <th>Memo Name</th>
                                    <th>Memo Amount</th>
                                    <th>Hamali DD</th>
                                    <th>Area</th>
                                    <th>User Name</th>
                                    <th>Collection Man Name</th>
                                    <th *ngIf="showCol">Transferred Type</th>
                                    <th *ngIf="showCol">Transferred Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lrDetailsOfRemainingCashmemoData of lrDetailsOfRemainingCashmemoDataList ">
                                    <td>{{ lrDetailsOfRemainingCashmemoData.cashMemoNumber }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.memoDateStr }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.totalArticles }}
                                    </td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.lrNumber }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.consigneeName }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.memoName }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.grandTotal }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.hamaliDD }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.areaOfDelivery }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.userName }}</td>
                                    <td>{{ lrDetailsOfRemainingCashmemoData.collectionMan }}</td>
                                    <td *ngIf="showCol">{{ lrDetailsOfRemainingCashmemoData.mode }}</td>
                                    <td *ngIf="showCol">{{ lrDetailsOfRemainingCashmemoData.enteredDateStr }}</td>

                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td *ngIf="showCol"></td>
                                    <td *ngIf="showCol"></td>

                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <button class="btn btn-outline-danger" mat-button (click)="onCancelClick()">Cancel</button>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>