import { Component, OnInit } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
@Component({
    selector: 'app-bkg-payment-followup-challan-print',
    templateUrl: './bkg-payment-followup-challan-print.component.html',
    styleUrls: ['./bkg-payment-followup-challan-print.component.css']
})
export class BkgPaymentFollowupChallanPrintComponent implements OnInit {
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    dataForPrintChallanMemo: any;
    dataForPrintChallanMemoList: any;
    address: any;
    lessDescOpt: Array<any> = [];
    lessDescOptSent: any;
    setMainStation: string;
    entryLessTypeOptions = [
        { id: 1, label: 'Flat Discount' },
        { id: 2, label: 'Rate Difference' },
        { id: 3, label: 'Weight Difference' },
        { id: 4, label: 'Demmurage' },
        { id: 5, label: 'Unloading' },
        { id: 5, label: 'Door Deivery' },
        { id: 5, label: 'FOV' },
        { id: 5, label: 'TDS' },
        { id: 5, label: 'CLAIM' },
        { id: 5, label: 'FOC' }
    ];
    getChallanPrintValDto: CashMemoDto = new CashMemoDto();
    completedOnDt: any;
    collectionMan: string;
    returnVal: any;
    addressComaSplit: string[];
    addressLineOne: string = '';
    addressLineTwo: string = '';
    pageId = "pfcpc";

    constructor(private router: Router, private datePipe: DatePipe) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //            this.dataForPrint = localStorage.getItem( 'print' );
            this.getChallanPrintValDto = JSON.parse(localStorage.getItem('printAfterChallanMemo'));
            this.dataForPrintChallanMemoList = JSON.parse(localStorage.getItem('printAfterChallanMemolist'));
            //            console.log( this.dataForPrintChallanMemo );
            //            console.log( this.dataForPrintChallanMemoList );
            //            this.lessDescOptSent = this.entryLessTypeOptions;
            this.lessDescOptSent = this.getChallanPrintValDto.lessDesc;
            this.lessDescOpt = this.lessDescOptSent;
            this.setMainStation = this.userDataDtoReturnSession.mainStation.toUpperCase();
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            //        console.log(this.address);
            //            this.addressComaSplit = this.address.split( "," );
            //            if ( this.addressComaSplit.length > 0 ) {
            //                this.addressLineTwo = this.addressComaSplit[this.addressComaSplit.length - 1] + ".";
            //                //            console.log( this.addressLineTwo );
            //                for ( let b = 0; b < this.addressComaSplit.length - 1; b++ ) {
            //                    this.addressLineOne += this.addressComaSplit[b] + ",<br>";
            //                }
            //            } else {
            //                this.addressLineOne = this.address;
            //            }

            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {

        this.completedOnDt = this.getChallanPrintValDto.completedOn == null ? '' : this.getChallanPrintValDto.completedOn;
        this.collectionMan = this.getChallanPrintValDto.collectionMan == null ? '' : this.getChallanPrintValDto.collectionMan;
        this.getChallanPrintValDto.nfof2000Count = this.getChallanPrintValDto.nfof2000Count == null ? 0 : this.getChallanPrintValDto.nfof2000Count;
        this.getChallanPrintValDto.nfof500Count = this.getChallanPrintValDto.nfof500Count == null ? 0 : this.getChallanPrintValDto.nfof500Count;
        this.getChallanPrintValDto.nfof200Count = this.getChallanPrintValDto.nfof200Count == null ? 0 : this.getChallanPrintValDto.nfof200Count;
        this.getChallanPrintValDto.nfof100Count = this.getChallanPrintValDto.nfof100Count == null ? 0 : this.getChallanPrintValDto.nfof100Count;
        this.getChallanPrintValDto.nfof50Count = this.getChallanPrintValDto.nfof50Count == null ? 0 : this.getChallanPrintValDto.nfof50Count;
        this.getChallanPrintValDto.nfof20Count = this.getChallanPrintValDto.nfof20Count == null ? 0 : this.getChallanPrintValDto.nfof20Count;
        this.getChallanPrintValDto.nfof10Count = this.getChallanPrintValDto.nfof10Count == null ? 0 : this.getChallanPrintValDto.nfof10Count;
        this.getChallanPrintValDto.nfof5Count = this.getChallanPrintValDto.nfof5Count == null ? 0 : this.getChallanPrintValDto.nfof5Count;
        this.getChallanPrintValDto.nfof2Count = this.getChallanPrintValDto.nfof2Count == null ? 0 : this.getChallanPrintValDto.nfof2Count;
        this.getChallanPrintValDto.nfof1Count = this.getChallanPrintValDto.nfof1Count == null ? 0 : this.getChallanPrintValDto.nfof1Count;
        this.getChallanPrintValDto.nfOF2000 = this.getChallanPrintValDto.nfOF2000 == null ? 0.0 : this.getChallanPrintValDto.nfOF2000;
        this.getChallanPrintValDto.nfOF500 = this.getChallanPrintValDto.nfOF500 == null ? 0.0 : this.getChallanPrintValDto.nfOF500;
        this.getChallanPrintValDto.nfOF200 = this.getChallanPrintValDto.nfOF200 == null ? 0.0 : this.getChallanPrintValDto.nfOF200;
        this.getChallanPrintValDto.nfOF100 = this.getChallanPrintValDto.nfOF100 == null ? 0.0 : this.getChallanPrintValDto.nfOF100;
        this.getChallanPrintValDto.nfOF50 = this.getChallanPrintValDto.nfOF50 == null ? 0.0 : this.getChallanPrintValDto.nfOF50;
        this.getChallanPrintValDto.nfOF20 = this.getChallanPrintValDto.nfOF20 == null ? 0.0 : this.getChallanPrintValDto.nfOF20;
        this.getChallanPrintValDto.nfOF10 = this.getChallanPrintValDto.nfOF10 == null ? 0.0 : this.getChallanPrintValDto.nfOF10;
        this.getChallanPrintValDto.nfOF5 = this.getChallanPrintValDto.nfOF5 == null ? 0.0 : this.getChallanPrintValDto.nfOF5;
        this.getChallanPrintValDto.nfOF2 = this.getChallanPrintValDto.nfOF2 == null ? 0.0 : this.getChallanPrintValDto.nfOF2;
        this.getChallanPrintValDto.nfOF1 = this.getChallanPrintValDto.nfOF1 == null ? 0.0 : this.getChallanPrintValDto.nfOF1;
        this.getChallanPrintValDto.totalLess = this.getChallanPrintValDto.totalLess == null ? 0 : this.getChallanPrintValDto.totalLess;
        this.getChallanPrintValDto.amtToBeCollected = this.getChallanPrintValDto.amtToBeCollected == null ? 0 : this.getChallanPrintValDto.amtToBeCollected;
        this.getChallanPrintValDto.delhiLess = this.getChallanPrintValDto.delhiLess == null ? 0 : this.getChallanPrintValDto.delhiLess;
        this.getChallanPrintValDto.mumbaiLess = this.getChallanPrintValDto.mumbaiLess == null ? 0 : this.getChallanPrintValDto.mumbaiLess;
        this.getChallanPrintValDto.gujaratLess = this.getChallanPrintValDto.gujaratLess == null ? 0 : this.getChallanPrintValDto.gujaratLess;
        this.getChallanPrintValDto.punjabLess = this.getChallanPrintValDto.punjabLess == null ? 0 : this.getChallanPrintValDto.punjabLess;
        this.getChallanPrintValDto.tds = this.getChallanPrintValDto.tds == null ? 0 : this.getChallanPrintValDto.tds;
        this.getChallanPrintValDto.claim = this.getChallanPrintValDto.claim == null ? 0 : this.getChallanPrintValDto.claim;
        this.getChallanPrintValDto.totalChqAmt = this.getChallanPrintValDto.totalChqAmt == null ? 0 : this.getChallanPrintValDto.totalChqAmt;
        this.getChallanPrintValDto.totalNeftAmt = this.getChallanPrintValDto.totalNeftAmt == null ? 0 : this.getChallanPrintValDto.totalNeftAmt;
        this.getChallanPrintValDto.totalCashAmt = this.getChallanPrintValDto.totalCashAmt == null ? 0 : this.getChallanPrintValDto.totalCashAmt;
        this.getChallanPrintValDto.balanceAmt = this.getChallanPrintValDto.balanceAmt == null ? 0 : this.getChallanPrintValDto.balanceAmt;
        this.getChallanPrintValDto.grandTotal = this.getChallanPrintValDto.grandTotal == null ? 0 : this.getChallanPrintValDto.grandTotal;
        this.getChallanPrintValDto.totalMemos = this.getChallanPrintValDto.totalMemos == null ? 0 : this.getChallanPrintValDto.totalMemos;

    }

    clearField() {
        this.address = '';
        this.dataForPrintChallanMemo = '';
        this.dataForPrintChallanMemoList = '';
        this.address = '';
        this.completedOnDt = '';
        this.collectionMan = '';
        this.returnVal = '';
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    myFunction(option) {
        console.log('1- ' + option);
        this.returnVal = '';
        for (let key in this.dataForPrintChallanMemoList.hashMapDiffLess) {
            console.log('2- ' + key);
            if (option == key) {
                this.returnVal = this.dataForPrintChallanMemoList.hashMapDiffLess[key];
                console.log('3- ' + this.returnVal);
                break;
            } else {
                this.returnVal = '';
                console.log('4');
            }
        }
        return this.returnVal;
    }
}

