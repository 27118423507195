<html>
<head>
</head>

<body>
	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card">
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-12 lineBottom_custom">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-3">
									<div class="row">

										<div class="col-sm-12 col-md-12">
											<ul class="timeline timeline-left">

												<li class="timeline-inverted timeline-item">
													<div class="timeline-badge success">
														<i class="fas fa-home"></i>
													</div>
													<div class="timeline-panel">
														<div class="timeline-heading">
															<h4 class="timeline-title">Nanglipoona</h4>
														</div>

													</div>
												</li>
												<li>
													<div class="custome_Effects">
														<i class="fas fa-truck " title="Trip Creation"></i>
													</div>

												</li>
												<li class="timeline-inverted timeline-item">
													<div class="timeline-badge success">
														<i class="fas fa-warehouse"></i>
													</div>
													<div class="timeline-panel">
														<div class="timeline-heading">
															<h4 class="timeline-title">Alwaye</h4>
														</div>

													</div>
												</li>
											</ul>
										</div>

									</div>

								</div>
								<div class="col-lg-4 vl">
									<div class="col-sm-12 col-md-12 bbtitle">
										<h6 class="card-title">Info Invoice</h6>
									</div>
									<div class="row">


										<!-- 											the first autocomplete starts -->
										<div class="col-sm-12 col-md-12 p-t-10">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}loaderHead">
														<label>Loader Head</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="ti-truck"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Loader Head"
															[formControl]="controlLoaderHead"
															[appAutocomplete]="autocompleteLoaderHead">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteLoaderHead="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(loaderHeadOptions | filter: controlLoaderHead.value) as resultLoaderHead">
											<app-option *ngFor="let option of resultLoaderHead"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultLoaderHead.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the first autocomplete ends -->
										<!-- 											the second autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}loaderName">
														<label>Loader Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Loader Name"
															[formControl]="controlLoaderName"
															[appAutocomplete]="autocompleteLoaderName">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteLoaderName="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(loaderNameOptions | filter: controlLoaderName.value) as resultLoaderName">
											<app-option *ngFor="let option of resultLoaderName"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultLoaderName.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the second autocomplete ends -->
										<!-- 											the third autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}truckNumber">
														<label>Truck No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-truck"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Truck No"
															[formControl]="controlTruckNumber"
															[appAutocomplete]="autocompleteTruckNumber">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteTruckNumber="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(truckNumberOptions | filter: controlTruckNumber.value) as resultTruckNumber">
											<app-option *ngFor="let option of resultTruckNumber"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultTruckNumber.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the third autocomplete ends -->
										<!-- 											the fourth autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}supplier">
														<label>Supplier</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Supplier"
															[formControl]="controlSupplier"
															[appAutocomplete]="autocompleteSupplier">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteSupplier="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(supplierOptions | filter: controlSupplier.value) as resultSupplier">
											<app-option *ngFor="let option of resultSupplier"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultSupplier.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the fourth autocomplete ends -->
										<!-- 											the fifth autocomplete starts -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}driverName">
														<label>Driver Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="ti-user"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Driver Name"
															[formControl]="controlDriverName"
															[appAutocomplete]="autocompleteDriverName">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteDriverName="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(driverNameOptions | filter: controlDriverName.value) as resultDriverName">
											<app-option *ngFor="let option of resultDriverName"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultDriverName.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the fifth autocomplete ends -->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}licenceNumber">
													<label>Licence Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-id-card"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>

									</div>
								</div>
								<div class="col-lg-5 vl">
									<div class="col-sm-12 col-md-12 bbtitle">
										<h6 class="card-title">TP Info</h6>
									</div>
									<div class="row">

										<!-- 											the sixth autocomplete starts  -->
										<div class="col-sm-12 col-md-12 p-t-10">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}entryCp">
														<label>Entry CP</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="mdi mdi-truck-delivery"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Entry CP"
															[formControl]="controlEntryCp"
															[appAutocomplete]="autocompleteEntryCp">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteEntryCp="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(entryCpOptions | filter: controlEntryCp.value) as resultEntryCp">
											<app-option *ngFor="let option of resultEntryCp"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultEntryCp.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the sixth autocomplete ends -->
										<!-- 											the seventh autocomplete starts  -->
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}exitCp">
														<label>Exit CP</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="mdi mdi-truck-delivery"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Exit CP"
															[formControl]="controlExitCp"
															[appAutocomplete]="autocompleteExitCp">
													</div>
												</div>
											</div>
											<app-autocomplete #autocompleteExitCp="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(exitCpOptions | filter: controlExitCp.value) as resultExitCp">
											<app-option *ngFor="let option of resultExitCp"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultExitCp.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the seventh autocomplete ends -->
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Exp Exit Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" name="expExitDates" ngbDatepicker
														#expExitDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="expExitDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}expExitDays">
													<label>Exp Exit Days</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="form-group" id="{{pageId}}remarks">
													<label>Remarks</label>
													<div class="form-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-notes-medical"></i>
														</span>
													</div>
													<textarea class="form-control" rows="4"></textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>