import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { SuplierService } from './supplier-master-service';
import { MasterService } from 'src/app/dataService/master-service';

import { ElementRef } from "@angular/core";
import { SupplierModel } from "./supplier-model";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Subject, Subscription, from, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterDto } from 'src/app/dto/master-dto';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from "@angular/router";

//for the select option with filter ends
//service starts
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
//sweet alert starts
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
//sweet alert ends

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'my-auth-token'
    })
};
//service ends
@Component({
    selector: 'app-supplier-master',
    templateUrl: './supplier-master.component.html',
    styleUrls: ['./supplier-master.component.css']
})

export class SupplierMasterComponent implements OnInit {
    @ViewChild("panNumber") panNumberField: ElementRef;
    @ViewChild('regStateName') regStateNameInputElement;
    //
    //the below shows error for while adding export buttons    
    //dtOptions: DataTables.Settings = {}; /*this if we are not using any export and others like pagination and scroll means*/
    supplierDetailsList: any;
    truckDataDto: TruckDataDto = new TruckDataDto();
    supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
    supplierDetailsDeleteDto: SupplierDetailsDto = new SupplierDetailsDto();
    truckDataDtoSaveRet: any;
    supplierDetailsDeleteRet: any;
    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    //dtTrigger: Subject = new Subject();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerSupplierDetails: Subject<any> = new Subject();
    @ViewChild('dataTable') table: ElementRef;
    dataTable: any;
    dtOptionsSupplierDetails: any;
    supplierMasterId = false;
    loadingIndicator = true;
    reorderable = true;

    controlState = new FormControl();


    userDataDtoReturnSession: any;
    isLoggedIn = true;

    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    controlRegState = new FormControl();
    partyMasterDtoOptions: PartyMasterDto[];
    public modelRegState: any;
    modelRegStateTA: Array<PartyMasterDto> = [];
    focusRegStateTA$ = new Subject<string>();
    searchRegState = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusRegStateTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelRegStateTA
                : this.modelRegStateTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterRegState = (x: { state: string }) => x.state;

    validateStateName: any;
    validateStateCode: any;
    rowSelectedStateName: any;
    validatePanNumber: any;
    readonlySupplierName = false;
    showSpinnerForAction: boolean = false;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    pageId="spmsc";

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    constructor(private http: HttpClient, private masterService: MasterService, private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //            sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {
            //          userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            this.getSupplierDetailsList();
            this.getStatesMethod();
        }
    }

    //    panNumberfocus(): void {
    //        this.panNumberField.nativeElement.focus();
    //    }

    ngOnInit(): void {
        this.dtOptionsSupplierDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'

                    }
                },
               
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            //pageLength: 9,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var columnData = api.column(0).data();
                // Update footer
                $(api.column(0).footer()).html(
                    columnData.count()
                );
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerSupplierDetails.unsubscribe();
    }

    getSupplierDetails() {
        this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        this.supplierDetailsDto.mode = "Working";

    }
    getSupplierDetailsList = () => {
        this.getSupplierDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getSuplierMaster(this.supplierDetailsDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.supplierDetailsList = response;
                    $("#"+this.pageId+"suupplierMasteDatatable").DataTable().destroy();
                    this.dtTriggerSupplierDetails.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Supplier Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    // for datatable starts
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    // for datatable ends

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    //    ValidateSupplierDetails() {
    //            this.saveUpdateSupplierDetails();
    //    }

    ValidateSupplierDetails() {
        var supplierName = this.truckDataDto.supplierName;
        var address = this.truckDataDto.address;
        var city = this.truckDataDto.city;
        var pincode = this.truckDataDto.pinCodeNumber;
        var pan = this.truckDataDto.panNo;
        var contactnumber = this.truckDataDto.contactNo;
        var contectPerson = this.truckDataDto.contactPerson;
        // var state = this.truckDataDto.state;
        var supplierCode = this.truckDataDto.suppCode;

        this.validateStateName = $("#"+this.pageId+"regStateName").val();
        this.validatePanNumber = $("#"+this.pageId+"panNumber").val();
        var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;

        // if ((supplierName == null) || (supplierName == undefined) || (supplierName == '') || 
        if ((address == null) || (address == undefined) || (address == '') ||
            (city == null) || (city == undefined) || (city == '') ||
            (pincode == null) || (pincode == undefined) || (pincode == '') ||
            (pan == null) || (pan == undefined) || (pan == '') ||
            (contactnumber == null) || (contactnumber == undefined) || (contactnumber == '') ||
            (this.validateStateName == null) || (this.validateStateName == undefined) ||
            (this.validateStateName == '') || (supplierCode == null) || (supplierCode == undefined) ||
            (supplierCode == '')) {
            swal("Mandatory Field", "Please Enter the Mandetory Fields", "warning");
            return false;
        } else if (!regpan.test(this.validatePanNumber)) {
            swal("Mandatory Field", "Invalid PAN Number", "warning");
            return false;
        } else {
            this.saveUpdateSupplierDetails();
        }
    }
    saveSupplierDetails() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.userId = this.userDataDtoReturnSession.userId;
        this.truckDataDto.status = "Working";
        if ($("#"+this.pageId+"regStateName").val() != null && $("#"+this.pageId+"regStateName").val() != "") {
            this.validateStateName = $("#"+this.pageId+"regStateName").val();
            this.truckDataDto.state = this.validateStateName;
            if (this.modelRegState != null && this.modelRegState != undefined) {
                this.truckDataDto.stateCode = this.modelRegState.stateCode;
            }
        }
    }
    saveUpdateSupplierDetails = () => {
        this.saveSupplierDetails();
        //        this.masterService.createSupplier( this.truckDataDto ).subscribe( data => console.log( data ), error => console.log( error ) );
        this.masterService.createSupplier(this.truckDataDto).
            subscribe((data) => {
                this.truckDataDtoSaveRet = data;
                //console.log( this.truckDataDtoSaveRet.status );
                if (this.truckDataDtoSaveRet.status == "persisted") {
                    swal({
                        title: "Success",
                        text: "Supplier Details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getSupplierDetailsList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Supplier Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Error While Saving Supplier Details1",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        $("#"+this.pageId+"regStateName").val('');
        $("#"+this.pageId+"address").val('');
        this.truckDataDto.supplierName = null;
        this.validateStateCode = null;
        this.validateStateName = null;
        this.rowSelectedStateName = null;
        this.readonlySupplierName = false;
    }

    //to insert value of selected row in table to input field starts
    rowSelected(lstOfData) {
        console.log(lstOfData);
        this.truckDataDto.supplierName = lstOfData.supplierName;
        this.truckDataDto.address = lstOfData.address;
        this.truckDataDto.city = lstOfData.city;
        this.truckDataDto.pinCodeNumber = lstOfData.pincode;
        // this.truckDataDto.state = lstOfData.state;
        this.truckDataDto.panNo = lstOfData.tinNumber;
        this.truckDataDto.contactNo = lstOfData.contactNumber;
        this.truckDataDto.contactPerson = lstOfData.contactPerson;
        this.truckDataDto.suppCode = lstOfData.suppCode;
        if (lstOfData.state != null && lstOfData.state != "") {
            this.rowSelectedStateName = lstOfData.state;
            $("#"+this.pageId+"regStateName").val(this.rowSelectedStateName);
        }
        this.readonlySupplierName = true;
    }
    //to insert value of selected row in table to input field ends

    ngAfterViewInit() {
        this.dtTriggerSupplierDetails.next();
    }

    //    signout() {
    //        sessionStorage.clear();
    //        this.router.navigate( ['/authentication/login'] );
    //    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateDeleteRow() {
        this.deleteSupplierDetails();
    }

    getDeleteSupplierDetails() {
        this.supplierDetailsDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.supplierDetailsDeleteDto.supplierName = this.truckDataDto.supplierName;
        this.supplierDetailsDeleteDto.userId = this.userDataDtoReturnSession.userId;
    }

    deleteSupplierDetails = () => {
        this.getDeleteSupplierDetails();
        this.masterService.deleteSupplier(this.supplierDetailsDeleteDto).
            subscribe((data) => {
                this.supplierDetailsDeleteRet = data;
                //                console.log( this.truckDataDtoSaveRet.status );
                if (this.supplierDetailsDeleteRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Supplier Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getSupplierDetailsList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Deleting Supplier Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Deleting Supplier Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clickListnerForRegState(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelRegState = e.item;
        console.log(this.modelRegState);
        $("#"+this.pageId+"regStateName").val(this.modelRegState.state);
        $("#"+this.pageId+"panNumber").focus();
    }

    getStatesMethod() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getStateDetailsService(this.partyMasterDto).subscribe(
            (response) => {
                if (response) {
                    // console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No getStatesMethod records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = [];
                        this.modelRegStateTA = [];
                    } else {
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = response;
                        this.modelRegStateTA = [];
                        for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
                            this.modelRegStateTA.push(this.partyMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting getStatesMethod",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }
    focusRegStateTA(e) {
        console.log(e);
        if (e.keyCode == 13) {
            $("#"+this.pageId+"panNumber").focus();
        }
    }
    keyEventHandlerForStateName(event) {
        if (event == 13) {
            $("#"+this.pageId+"panNumber").focus();
        }
    }

    customPrintSupplierMaster() {
        if (this.supplierDetailsList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Supplier Name", "Address", "City", "Pincode", "State", "Gst Number", "Contact Number", "Contact Person", "Supp Code"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [12, 16, 8, 8, 10, 10, 12, 13, 13, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            for (let i = 0; i < this.supplierDetailsList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.supplierDetailsList[i].supplierName, this.supplierDetailsList[i].address, this.supplierDetailsList[i].city
                    , this.supplierDetailsList[i].pincode, this.supplierDetailsList[i].state, this.supplierDetailsList[i].tinNumber, this.supplierDetailsList[i].contactNumber,
                this.supplierDetailsList[i].contactPerson, this.supplierDetailsList[i].suppCode];



                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.supplierDetailsList.length, "", "", "", "", "", "", "", ""];
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Supplier Master");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}



