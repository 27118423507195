import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-amount-receivable',
    templateUrl: './amount-receivable.component.html',
    styleUrls: ['./amount-receivable.component.css']
})
export class AmountReceivableComponent implements OnInit {

    loadingIndicator = true;
    model: NgbDateStruct;
    date: { year: number; month: number };
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    amountReceivableDataList: any;
    dtTriggerAmountReceivable: Subject<any> = new Subject();
    dtOptionsAmountReceivable: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    fromDatesModal: any;
    toDatesModal: any;
    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;
    showSpinnerForAction = false;
    hideShowDropDownDestination = false;
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    rightsForSuperAdministrator = "right for Super Administrator to show Destination"

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    chequeSummary: number;
    amountRecSummary: number;
    cashSummary: number;
    pdcSummary: number;
    pageId = "amtre";

    constructor(private router: Router,
        private masterReadService: MasterReadService, private destStmtReport: DestStmtReport, private datePipe: DatePipe,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //if(this.rightsForSuperAdministrator == "right for Super Administrator to show Destination"){
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Super Administrator") {
                this.hideShowDropDownDestination = true;
                this.getDestinationDetails();
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var booleanDestination = this.hideShowDropDownDestination;
        this.dtOptionsAmountReceivable = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if (booleanDestination == true) {
                            return " Amount Received Details Report- " +
                                "From Date : " + moment($("#amtrefromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#amtretoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#amtredestinationId").val() + "";
                        } else {
                            return " Amount Received Details Report- " +
                                "From Date : " + moment($("#amtrefromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#amtretoDates").val()).format('DD-MM-YYYY') + "";
                        }
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var cashAmt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chequeAmt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var pdcAmt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var receivedAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total');
                $(api.column(1).footer()).html(data.length);
                $(api.column(2).footer()).html(cashAmt);
                $(api.column(3).footer()).html(chequeAmt);
                $(api.column(4).footer()).html(pdcAmt);
                $(api.column(5).footer()).html(receivedAmt);
            },
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerAmountReceivable.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerAmountReceivable.unsubscribe();
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.modelDestination = null;
        this.validateDestination = null;
        $("#" + this.pageId + "fromDates").val('');
        $("#" + this.pageId + "toDates").val('');
        $("#" + this.pageId + "destinationId").val('');
        this.validateFromDate = null;
        this.validateToDate = null;
        $("#" + this.pageId + "amountReceivableTableId").DataTable().destroy();
        this.dtTriggerAmountReceivable.next();
        this.amountReceivableDataList = [];
        $("#" + this.pageId + "textId").text('');
    }

    clearBtn() {
        this.clearField();
    }

    validateSearchBtn() {
        // console.log(this.hideShowDropDownDestination);
        if (this.hideShowDropDownDestination == true) {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
                ($("#" + this.pageId + "destinationId").val() == "")) {
                swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                return false;
            } else {
                this.setAmountReceivableReport();
            }
        } else {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "")) {
                swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                return false;
            } else {
                this.setAmountReceivableReport();
            }
        }
    }

    getAmountReceivableDetailsData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate

        //if(this.rightsForSuperAdministrator == "right for Super Administrator to show Destination"){
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator") {
            this.validateDestination = $("#" + this.pageId + "destinationId").val();
            this.fortNightStmtDto.destination = this.validateDestination;
        } else {
            this.fortNightStmtDto.destination = this.userDataDtoReturnSession.mainStation;
        }
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;

        if (this.hideShowDropDownDestination == true) {
            $("#" + this.pageId + "textId").text("Amount Received Details For " + this.modelDestination.destination);
        } else {
            $("#" + this.pageId + "textId").text("Amount Received Details For " + this.userDataDtoReturnSession.mainStation);
        }
    }
    setAmountReceivableReport() {
        this.getAmountReceivableDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getAmtRecFromAgentDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                console.log(response);
                this.amountReceivableDataList = [];
                $("#" + this.pageId + "amountReceivableTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.amountReceivableDataList = response;
                    this.dtTriggerAmountReceivable.next();
                } else {
                    this.dtTriggerAmountReceivable.next();
                    swal("Amount Receivable Report", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting Amount Receivable Report details", "warning");
            },
            () => console.log('done');
    }

    validateEditBtn(amountReceivableData) {
        localStorage.clear();
        localStorage.setItem('BngStmtAmtRecEdit', "Edit");
        localStorage.setItem('BngStmtAmtRecEditData', JSON.stringify(amountReceivableData));
        this.router.navigate(['/fortnightStatement/agent/amountReceivableFromAgent']);
    }


    customPrintAmountReceivable() {
        if (this.amountReceivableDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Received Date", "Cash", "Cheque", "PDC", "Received Amount"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date
            this.validateFromDate = this.datePipe.transform($("#" + this.pageId + "fromDates").val(), "dd-MM-yyyy");
            this.validateToDate = this.datePipe.transform($("#" + this.pageId + "toDates").val(), "dd-MM-yyyy");
            //this.validateFromDate = $("#"+this.pageId+"fromDates").val();
            //this.validateToDate = $("#"+this.pageId+"toDates").val();

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];


            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDate, this.validateToDate];

            ///Heaading Logics For Party Name
            this.validateDestination = $("#" + this.pageId + "destinationId").val();

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashSummary = this.chequeSummary = this.pdcSummary = this.amountRecSummary = 0;

            for (let i = 0; i < this.amountReceivableDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.amountReceivableDataList[i].receivedDateStr, this.amountReceivableDataList[i].cashAmt,
                this.amountReceivableDataList[i].chequeAmt, this.amountReceivableDataList[i].pdcAmt,
                this.amountReceivableDataList[i].receivableAmt];

                /////////
                this.cashSummary = this.cashSummary + this.amountReceivableDataList[i].cashAmt;
                this.chequeSummary = this.chequeSummary + this.amountReceivableDataList[i].chequeAmt;
                this.pdcSummary = this.pdcSummary + this.amountReceivableDataList[i].pdcAmt;
                this.amountRecSummary = this.amountRecSummary + this.amountReceivableDataList[i].receivableAmt;

                /////
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.amountReceivableDataList.length, this.cashSummary, this.chequeSummary, this.pdcSummary, this.amountRecSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "Amount Receivable Details Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }
}
