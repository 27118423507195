import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

	isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    fortNightStmtDtoUserValueCurStmt: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoUserValueAgentBal: FortNightStmtDto = new FortNightStmtDto();
    fortnightStatementSmryDataList: any;
    fortNightStmtDtoAgentBalRet: FortNightStmtDto = new FortNightStmtDto();
    curStmtValue:any;
    agentBalValue:any;
    
    pageId="sumc";
    
  constructor( private router: Router,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {
	  if (sessionStorage.length == 0) {
          this.isLoggedIn = false;
          swal({
              title: "Session Expired",
              text: "Please relogin to access the application!",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
          }).then(() => {
              this.logInPage();
          })
      }
      if (this.isLoggedIn) {
          this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          this.gridReconifgureOnReloadBtn();
      }
  }

  ngOnInit() {
  }
  
  gridReconifgureOnReloadBtn(){
	  this.getPendingStmtDetails();
	  this.getAgnetAmountBalanceDetails();
		
	}
  getPendingStmtDetailsUserValues() {
		this.fortNightStmtDtoUserValueCurStmt = new FortNightStmtDto();
		this.fortNightStmtDtoUserValueCurStmt.status = "Finished";
		this.fortNightStmtDtoUserValueCurStmt.mode = "summarydb";
		this.fortNightStmtDtoUserValueCurStmt.companyId = this.userDataDtoReturnSession.companyId;
		this.fortNightStmtDtoUserValueCurStmt.destination = this.userDataDtoReturnSession.office;
	}
  getPendingStmtDetails = () => {
	  	this.curStmtValue = 0;
		this.getPendingStmtDetailsUserValues();
		this.destStmtReport.getFortNightStmt(this.fortNightStmtDtoUserValueCurStmt).subscribe(
			(response) => {
				this.fortnightStatementSmryDataList = [];
				if (response.length == 0) {
					this.curStmtValue = 0;
				} else {
					this.fortnightStatementSmryDataList = response;
					this.curStmtValue = this.fortnightStatementSmryDataList[0].receivableAmt;
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Problem Occurred While getting the Statement Details", "info");
			}, () => console.log('done');
	};
	
	getAgnetAmountBalanceUserValues() {
		this.fortNightStmtDtoUserValueAgentBal = new FortNightStmtDto();
		this.fortNightStmtDtoUserValueAgentBal.destination = this.userDataDtoReturnSession.office;
	}
	getAgnetAmountBalanceDetails = () => {
	  	this.agentBalValue = 0;
		this.getAgnetAmountBalanceUserValues();
		this.destStmtReport.getAgnetAmountBalance(this.fortNightStmtDtoUserValueAgentBal).subscribe(
			(response) => {
				this.fortNightStmtDtoAgentBalRet = new FortNightStmtDto();
				this.fortNightStmtDtoAgentBalRet = response;
				this.agentBalValue = this.fortNightStmtDtoAgentBalRet.balanceAmt;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Problem Occurred While getting the Agent Balance Details", "info");
			}, () => console.log('done');
	};
  
  logInPage() {
      this.router.navigate(['/authentication/login']);
  }

}
