//Chg_Ver_EBVER_01 _ Issue in popup closed and again extend clicked means its blocked by imran on 19072024
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
const my = new Date();
import { LRDto } from "src/app/dto/LR-dto";
import { ReportService } from "src/app/dataService/report-service";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EwaybillService } from "src/app/dataService/ewaybill-service";

@Component({
  selector: "app-ewaybilldate-validation-extension-rpt",
  templateUrl: "./ewaybilldate-validation-extension-rpt.component.html",
  styleUrls: ["./ewaybilldate-validation-extension-rpt.component.css"],
})
export class EWayBillDateValidationExtensionRptComponent implements OnInit {
  extentionHistoryDetailsDataList: any;
  validationLrsDetailsDataList: any;
  autoValidationLrsDetailsDataList: any;
  autoExtentionHistoryDetailsDataList: any;
  model: NgbDateStruct;
  searchDate = true;
  viewDate = false;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  userDataDtoReturnSession: any;
  userRole = "Truck Rpt Show Src All";
  isLoggedIn = true;
  supplierNameOptions: SupplierDetailsDto[];
  supplierDetailsList: any;
  supplierNameAll: SupplierDetailsDto = new SupplierDetailsDto();
  lrDto: LRDto = new LRDto();
  hireSlipDto: HireSlipDto = new HireSlipDto();
  toStationNameOptions: LRDto[];
  toStationDetailsList: any;
  fromStation: any;
  fromStationArray: Array<any> = [];
  fromStationNameOptions: LRDto[];
  fromStationDetailsList: any;
  lrDtoFromStation: LRDto = new LRDto();
  lrDtoFromStationAll: LRDto = new LRDto();
  lrDtoToStationAll: LRDto = new LRDto();
  selectedSearchBy: any;
  selectedToStations: any;
  selectedDate: any;
  selectedSource: any;
  dateNeedToAdd: string;
  dateToAddInPrint: string;

  pageTitle: string;
  selectedFromDate: any;
  selectedToDate: any;
  selectedSupplier: any;
  viewSource: boolean;
  //for the select option with filter ends
  @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

  dtTriggerValidationLrsDetailsTable: Subject<any> = new Subject();
  dtTriggerExtentionHistoryDetails: Subject<any> = new Subject();
  dtTriggerAutoValidationLrsDetailsTable: Subject<any> = new Subject();
  dtTriggerAutoExtentionHistoryDetails: Subject<any> = new Subject();

  dataTable: any;
  dtOptionsValidationLrsDetailsTable: any;
  dtOptionsExtentionHistoryDetails: any;
  dtOptionsAutoValidationLrsDetailsTable: any;
  dtOptionsAutoExtentionHistoryDetails: any;

  agentDto: AgentDetailsDto = new AgentDetailsDto();
  agentDtoAll: AgentDetailsDto = new AgentDetailsDto();

  superAdminview: boolean = false;
  //For Destination
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { destination: string }) => x.destination;
  showSpinnerForAction = false;
  destinationDtoForAll: LRDto = new LRDto();
  destinationList: Array<any> = [];
  showSource = false;

  //    For From Station 
  lrDtoFromStn: LRDto = new LRDto();
  lrDtoFromStnAll: LRDto = new LRDto();
  lrDtosFromStnOptionsGet: LRDto[];
  srcListForBkgAdmin: LRDto = new LRDto();

  rightsOfTruckRpt: any;
  supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
  supplierDetailsDtoAll: SupplierDetailsDto = new SupplierDetailsDto();
  supplierOptions: SupplierDetailsDto[];
  public modelSupplierName: any;
  supplierNameTA: Array<SupplierDetailsDto> = [];
  focusSupplierNameTA$ = new Subject<string>();
  searchSupplierName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSupplierNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.supplierNameTA
        : this.supplierNameTA.filter(v => v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSupplierName = (x: { supplierName: string }) => x.supplierName;
  srcLists: any;
  srcList: LRDto = new LRDto();

  truckDetailsTable = true;
  setTodayDateOnToDate: any;
  setTodayDateOnFromDate: any;
  hireSlipDtoForTruckDetailsWise: HireSlipDto = new HireSlipDto();
  hireSlipDtoForLorryHireWise: HireSlipDto = new HireSlipDto();
  hireSlipDtoForTruckInvoiceWise: HireSlipDto = new HireSlipDto();
  srcListsSet: Array<any> = [];
  destListSet: Array<any> = [];

  ///For Agent Names
  agentNameOptions: AgentDetailsDto[];
  agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
  public modelAgentName: any;
  agentNameTA: Array<AgentDetailsDto> = [];
  focusAgentNameTA$ = new Subject<string>();
  searchAgentName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterAgentName = (x: { subStation: string }) => x.subStation;

  validationView = true;
  extensionView = false;
  autoExtensionView = false;
  autoExtensionViewHistory = false;
  lrDtoForValidityWise: LRDto = new LRDto();
  lrDtoForExtensionWise: LRDto = new LRDto();
  selectedDestination: any;

  setTodayDateOnUpdateAllDate: any;
  showSpinnerForActionForUpload = false;
  setTodayDateOnUpdateDate: any;
  selectedLrNumber: any;
  lrDtoList: Array<any> = [];
  selectedLRNumberIgnore: any;
  selectedUpdateAllDate: any;
  selectedUpdateDate: any;
  selectedUpdaterAllReason: any;
  selectedUpdateReason: any;

  updateLrForBulkAndSingleLrs: LRDto = new LRDto();
  updateLrForExtensionReasonBulkAndSingleLrs: LRDto = new LRDto();

  viewCustomPrintV1 = false;
  viewCustomPrintV2 = false;

  grandActWt = 0;
  grandChWt = 0;
  grandToPay = 0;
  grandPaid = 0;
  grandGoodsVal = 0;
  //For Custom Print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;

  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  eWayBillValidUptoDateMultiModel: any;
  dtOptionsMultiEway: any;
  dtTriggerMultEway: Subject<any> = new Subject();
  multiEwayTableDataList: Array<any> = [];
  private newAttributeEwayBillToPush: any = {};
  editTableId: null;
  editSelected = false;
  selectedMultiEwyaUpdateDate: any;
  updateLrForMultiEway: LRDto = new LRDto();
  pageId = "ewbvc";
  viewSrc: boolean = true;
  viewDest: boolean = true;
  multiSelect: boolean = false;
  constructor(private modalService: NgbModal, private lrReportService: LrReportService, private router: Router,
    private masterReadService: MasterReadService, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef,
    private ewaybillService: EwaybillService) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.getDestinationDetails();
      this.selectTodayDate();
      this.getAgentDetailList();
      console.log(this.userDataDtoReturnSession.eWayBillValidBeforeInHrs);

    }

  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }
  selectTodayDate() {
    this.setTodayDateOnToDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
    this.setTodayDateOnFromDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };

    $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
    $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
  }

  ngOnInit(): void {
    this.dtOptionsValidationLrsDetailsTable = {
      dom: 'Bfrtip',
      buttons: [{
        extend: 'excel',
        text: '<i class="fas fa-file-excel"> Excel</i>',
        titleAttr: 'Excel',
        footer: true,
        exportOptions: {
          columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
        },
        title: function () {

          var returnPaidDest = null;

          returnPaidDest = "EWay Bill Validation Extension Action Report" + " Source : " + $("#ewbvcsource").val() + " Destination : " + $("#ewbvcdestination").val();
          return returnPaidDest;
        }
      }],

      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 350,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };

        var actWgt = api.column(7).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var chgWgt = api.column(8).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var topay = api.column(9).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api.column(10).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var goodVal = api.column(11).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(0).footer()).html('Total : ');
        $(api.column(1).footer()).html(data.length);
        $(api.column(7).footer()).html(actWgt);
        $(api.column(8).footer()).html(chgWgt);
        $(api.column(9).footer()).html(topay);
        $(api.column(10).footer()).html(paid);
        $(api.column(11).footer()).html(goodVal);

      }
    },

      this.dtOptionsExtentionHistoryDetails = {
        dom: 'Bfrtip',
        buttons: [{
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          footer: true,
          title: function () {
            var returExtendHistroyDate = null;

            returExtendHistroyDate = "EWay Bill Validation Extension History Report " +
              " From Date : " + moment($("#ewbvcfromDate").val()).format('DD-MM-YYYY') + " -  " +
              "To Date : " + moment($("#ewbvctoDate").val()).format('DD-MM-YYYY') + " Source : " + $("#ewbvcsource").val() + " Destination: " + $("#ewbvcdestination").val();
            return returExtendHistroyDate;
          }
        }],

        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search..."
        },
        processing: true,
        responsive: true,
        "scrollX": true,
        "scrollY": 300,
        "scrollCollapse": true,
        "paging": false,
        "info": false,
        "footerCallback": function (row, data, start, end, display) {
          var api = this.api(), data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === 'string' ?
              +i.replace(/[\$,]/g, '') * 1 :
              typeof i === 'number' ?
                i : 0;
          };

          var actWgt = api.column(7).data().reduce(
            function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var chgWgt = api.column(8).data().reduce(
            function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var topay = api.column(9).data().reduce(
            function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var paid = api.column(10).data().reduce(
            function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var goodVal = api.column(11).data().reduce(
            function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          $(api.column(0).footer()).html();
          $(api.column(1).footer()).html('Total : ' + data.length);
          $(api.column(2).footer()).html();
          $(api.column(3).footer()).html();
          $(api.column(4).footer()).html();
          $(api.column(5).footer()).html();
          $(api.column(6).footer()).html();
          $(api.column(7).footer()).html(actWgt);
          $(api.column(8).footer()).html(chgWgt);
          $(api.column(9).footer()).html(topay);
          $(api.column(10).footer()).html(paid);
          $(api.column(11).footer()).html(goodVal);
          $(api.column(12).footer()).html();
          $(api.column(13).footer()).html();
          $(api.column(14).footer()).html();
          $(api.column(15).footer()).html();
          $(api.column(16).footer()).html();
          $(api.column(17).footer()).html();
        }
      },
      this.dtOptionsMultiEway = {
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search..."
        },
        searching: false,
        processing: true,
        responsive: true,
        "scrollX": true,
        "scrollY": 280,
        "scrollCollapse": true,
        "paging": false,
        "info": false,
        "footerCallback": function (row, data, start, end, display) {
          var api = this.api(), data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === 'string' ?
              +i.replace(/[\$,]/g, '') * 1 :
              typeof i === 'number' ?
                i : 0;
          };
          $(api.column(0).footer()).html('Total : ' + data.length);
        }
      }

    this.dtOptionsAutoValidationLrsDetailsTable = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          footer: true,
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      searching: true,
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 280,
      "scrollCollapse": true,
      "paging": false,
      "info": true
    },

      this.dtOptionsAutoExtentionHistoryDetails = {
        dom: 'Bfrtip',
        buttons: [{
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: 'Excel',
          footer: true,
          title: function () {
            var returExtendHistroyDate = null;

            returExtendHistroyDate = "EWay Bill Validation Extension History Report " +
              " From Date : " + moment($("#ewbvcfromDate").val()).format('DD-MM-YYYY') + " -  " +
              "To Date : " + moment($("#ewbvctoDate").val()).format('DD-MM-YYYY') + " Source : " + $("#ewbvcsource").val() + " Destination: " + $("#ewbvcdestination").val();
            return returExtendHistroyDate;
          }
        }],

        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search..."
        },
        processing: true,
        responsive: true,
        "scrollX": true,
        "scrollY": 300,
        "scrollCollapse": true,
        "paging": false,
        "info": true

      }
  }
  ngOnDestroy(): void {
    this.dtTriggerValidationLrsDetailsTable.unsubscribe();
    this.dtTriggerExtentionHistoryDetails.unsubscribe();
    this.dtTriggerMultEway.unsubscribe();
    this.dtTriggerAutoValidationLrsDetailsTable.unsubscribe();
    this.dtTriggerAutoExtentionHistoryDetails.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerValidationLrsDetailsTable.next();
    this.dtTriggerExtentionHistoryDetails.next();
    this.dtTriggerMultEway.next();
    this.dtTriggerAutoValidationLrsDetailsTable.next();
    this.dtTriggerAutoExtentionHistoryDetails.next();
  }

  clearTable() {
    $("#" + this.pageId + "validationLrsDetailsTableId").DataTable().destroy();
    this.validationLrsDetailsDataList = [];
    this.dtTriggerValidationLrsDetailsTable.next();

    $("#" + this.pageId + "extentionHistoryDetailsTableId").DataTable().destroy();
    this.extentionHistoryDetailsDataList = [];
    this.dtTriggerExtentionHistoryDetails.next();

    $("#" + this.pageId + "autoValidationLrsDetailsTableId").DataTable().destroy();
    this.autoValidationLrsDetailsDataList = [];
    this.dtTriggerAutoValidationLrsDetailsTable.next();

    $("#" + this.pageId + "autoExtentionHistoryDetailsTableId").DataTable().destroy();
    this.autoExtentionHistoryDetailsDataList = [];
    this.dtTriggerAutoExtentionHistoryDetails.next();
  }
  hideTable() {
    this.validationView = false;
    this.extensionView = false;
    this.autoExtensionView = false;
    this.autoExtensionViewHistory = false;
  }
  searchByMode(searchBy: string) {
    if (searchBy === 'validityExtenstion') {
      this.viewDate = false;
      this.hideTable();
      this.clearTable();
      this.selectTodayDate();
      this.validationView = true;
      this.viewSrc = true;
      this.viewDest = true;

    } else if (searchBy === 'extendedHistory') {
      this.viewDate = true;
      this.hideTable();
      this.clearTable();
      this.selectTodayDate();
      this.extensionView = true;
      this.viewSrc = true;
      this.viewDest = true;

    } else if (searchBy === 'autoValidityExtenstion') {
      this.viewDate = false;
      this.hideTable();
      this.clearTable();
      this.selectTodayDate();
      this.autoExtensionView = true;
      this.viewSrc = false;
      this.viewDest = false;
    } else if (searchBy === 'autoValidityExtenstionHistory') {
      this.viewDate = true;
      this.hideTable();
      this.clearTable();
      this.selectTodayDate();
      this.autoExtensionViewHistory = true;
      this.viewSrc = true;
      this.viewDest = true;
    } else {
      this.searchDate = false;
      this.viewDate = false;
      this.hideTable();
      this.clearTable();
      this.validationView = true;
      this.viewSrc = true;
      this.viewDest = true;
    }
  }

  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.mode = "other";
    this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
    // this.lrDtoDestination.mode = "desttrsp";
  }
  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response) {
          //this.stationOptions = response;
          if (response.length > 0) {
            this.destinationOptions = response;
            this.destinationTA = [];
            this.destinationDtoForAll.destination = "ALL";
            this.destinationTA.push(this.destinationDtoForAll);

            this.destinationList = [];
            for (let i = 0; i < this.destinationOptions.length; i++) {
              this.destinationTA.push(this.destinationOptions[i]);
              this.destinationList.push(this.destinationOptions[i].destination);
            }

          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
      },
      () => console.log('done');

  };

  //For Agent Names
  getUserValuesForAgentDetailsList() {
    this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDto.column2 = "Working";
    this.agentDto.mode = "Sub";
    this.agentDto.status = "All";

    if (this.userDataDtoReturnSession.role != null
      && this.userDataDtoReturnSession.role == "Booking Administrator" && (this.userDataDtoReturnSession.stationList != '') && (this.userDataDtoReturnSession.stationList != null)) {
      this.agentDto.mode = "Sub";
      this.agentDto.status = "All";
    } else {
      //  this.agentDto.mode = "mainBranch";
      //As per asrar bhai 
      // if ( this.mainStationRpt == 'Paid Rpt Bkg Main Station' ) {
      //     this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
      // } else {
      this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      // }
    }
    console.log(this.agentDto);
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
      (response) => {
        this.agentNameTA = [];
        this.agentNameOptions = [];

        console.log(this.agentNameOptions);
        if (response.length > 0) {
          this.agentNameOptions = response;

          for (let i = 0; i < this.agentNameOptions.length; i++) {
            this.agentNameTA.push(this.agentNameOptions[i]);
          }

        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Source Details", "info");
      }, () => console.log('done');
  };

  getSearchMethod() {
    this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    if (this.selectedSearchBy != null && this.selectedSearchBy != '' && this.selectedSearchBy == 'validityExtenstion') {
      this.searchMethodForValidityExtensionTable();
    } else if (this.selectedSearchBy != null && this.selectedSearchBy != '' && this.selectedSearchBy == 'extendedHistory') {
      if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
        swal({
          title: "Mandatory Field",
          text: "Please select From Date and To Date to get the history details!",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });

      } else {
        this.searchMethodForExtensionHistoryTable();
      }
    } else if (this.selectedSearchBy != null && this.selectedSearchBy != '' && this.selectedSearchBy == 'autoValidityExtenstion') {
      this.searchMethodForAutoValidityExtensionTable();
    } else if (this.selectedSearchBy != null && this.selectedSearchBy != '' && this.selectedSearchBy == 'autoValidityExtenstionHistory') {
      if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
        swal({
          title: "Mandatory Field",
          text: "Please select From Date and To Date to get the history details!",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      } else {
        this.searchMethodForAutoExtensionHistoryTable();
      }
    }

  }
  searchMethodForValidityExtensionTable() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    this.lrDtoForValidityWise = new LRDto();
    this.lrDtoForValidityWise.source = this.selectedSource;
    this.lrDtoForValidityWise.destination = this.selectedDestination;
    this.lrDtoForValidityWise.diffDays = this.userDataDtoReturnSession.eWayBillValidBeforeInHrs;
    // this.lrDtoForValidityWise.diffDays = 10;
    this.lrDtoForValidityWise.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.lrDtoForValidityWise);
    this.searchMethodForValidityExtensionDetails();
  }

  searchMethodForValidityExtensionDetails = () => {
    this.showSpinnerForAction = true;
    this.lrReportService.getDetailsForValidityExtensionDetails(this.lrDtoForValidityWise).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "validationLrsDetailsTableId").DataTable().destroy();
        this.validationLrsDetailsDataList = [];
        this.multiSelect =false;
        this.lrDtoList = [];
        console.log(response);
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.validationLrsDetailsDataList = response;
          console.log("this.validationLrsDetailsDataList >> listOfmultipleEwayBill");
          console.log(this.validationLrsDetailsDataList);
        }
        this.dtTriggerValidationLrsDetailsTable.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Validity Extention Report ", "info");
      }, () => console.log('done');
  };

  bulkUpdteBtn(updateAllDatePopUpTemplate) {
    if (this.lrDtoList.length == 0) {
      swal({
        title: "No Lrs",
        text: "Select the LR to extend the E-Way bill validity!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      // this.modalRefferenceUpdateAllDatePopUp = this.modalService.open(updateAllDatePopUpTemplate,
      //   { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceUpdateAllDatePopUp.result.then((result) => {
      //   this.modalRefferenceUpdateAllDateClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceUpdateAllDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });

      this.modalService.open(updateAllDatePopUpTemplate,
        { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceUpdateAllDatePopUp.result.then((result) => {
      //   this.modalRefferenceUpdateAllDateClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceUpdateAllDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    }
  }
  extendDateBtn(validationLrsDetailsData, updateDatePopUpTemplate) {
    this.selectedLrNumber = validationLrsDetailsData.lrNumber;

    if (this.selectedLrNumber != null) {
      this.lrDtoList = [];
      this.lrDtoList.push(validationLrsDetailsData.lrNumber);

      // this.modalRefferenceUpdateDatePopUp = this.modalService.open(updateDatePopUpTemplate,
      //   { centered: true, windowClass: "myCustomModalClassSingle" });
      // this.modalRefferenceUpdateDatePopUp.result.then((result) => {
      //   this.modalRefferenceUpdateDateClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceUpdateDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
      this.modalService.open(updateDatePopUpTemplate,
        { centered: true, windowClass: "myCustomModalClassSingle" });
      // this.modalRefferenceUpdateDatePopUp.result.then((result) => {
      //   this.modalRefferenceUpdateDateClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceUpdateDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    } else {
      swal({
        title: "No Lrs",
        text: "Select the LR to update the E-Way bill validity!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  closeUpdateAllDatePopUp() {
    // this.modalRefferenceUpdateAllDatePopUp.close();
    this.selectedUpdateAllDate = '';
    this.selectedUpdateDate = '';
    $("#" + this.pageId + "updateDate").val('');
    this.setTodayDateOnUpdateDate = '';
    $("#" + this.pageId + "updateAllDate").val('');
    this.setTodayDateOnUpdateAllDate = '';
    //Chg_Ver_EBVER_01
    // this.lrDtoList = [];
    this.selectedLrNumber = '';
    this.updateLrForBulkAndSingleLrs = new LRDto();
  }
  closeUpdateDatePopUp() {
    // this.modalRefferenceUpdateDatePopUp.close();
    this.selectedUpdateAllDate = '';
    this.selectedUpdateDate = '';
    $("#" + this.pageId + "updateDate").val('');
    this.setTodayDateOnUpdateDate = '';
    $("#" + this.pageId + "updateAllDate").val('');
    this.setTodayDateOnUpdateAllDate = '';
        //Chg_Ver_EBVER_01
    // this.lrDtoList = [];
    this.selectedLrNumber = '';
    this.updateLrForBulkAndSingleLrs = new LRDto();
  }

  ignoreAllMethod(ignoreSelectedAllPopUpTemplate) {
    if (this.lrDtoList.length == 0) {
      swal({
        title: "No Lrs",
        text: "Select LRs to Ignore!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      // this.modalRefferenceIgnoreSelectedAllPopUp = this.modalService.open(ignoreSelectedAllPopUpTemplate,
      //   { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceIgnoreSelectedAllPopUp.result.then((result) => {
      //   this.modalRefferenceIgnoreSelectedAllClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceIgnoreSelectedAllClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
      this.modalService.open(ignoreSelectedAllPopUpTemplate,
        { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceIgnoreSelectedAllPopUp.result.then((result) => {
      //   this.modalRefferenceIgnoreSelectedAllClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceIgnoreSelectedAllClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    }
  }

  ignoreMethod(validationLrsDetailsData, ignoreSelectedAllPopUpTemplate) {
    this.selectedLRNumberIgnore = validationLrsDetailsData.lrNumber;


    if (this.selectedLRNumberIgnore != null) {
      this.lrDtoList = [];
      this.lrDtoList.push(validationLrsDetailsData.lrNumber);
      // this.modalRefferenceIgnoreSelectedPopUp = this.modalService.open(ignoreSelectedAllPopUpTemplate,
      //   { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceIgnoreSelectedPopUp.result.then((result) => {
      //   this.modalRefferenceIgnoreSelectedClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceIgnoreSelectedClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
      this.modalService.open(ignoreSelectedAllPopUpTemplate,
        { centered: true, windowClass: "myCustomModalClass" });
      // this.modalRefferenceIgnoreSelectedPopUp.result.then((result) => {
      //   this.modalRefferenceIgnoreSelectedClosePopUp = `Closed with: ${result}`;
      // }, reason => {
      //   this.modalRefferenceIgnoreSelectedClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    } else {
      swal({
        title: "No Lrs",
        text: "Select the LR to Ignore!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  closeIgnoreAllPopUp() {
    // this.modalRefferenceIgnoreSelectedAllPopUp.close();
    this.lrDtoList = [];
    this.selectedUpdaterAllReason = '';
    $("#" + this.pageId + "ignoreSelectedAll").val('');
    this.selectedUpdateReason = '';
    $("#" + this.pageId + "ignoreSelectedSingle").val('');
    this.selectedLRNumberIgnore = '';
    this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();

  }


  rowCheckBoxChecked(e, validationLrsDetailsData) {
    if (e.currentTarget.checked) {
      if (this.lrDtoList.length == 0) {
        this.lrDtoList = [];
        this.lrDtoList.push(validationLrsDetailsData.lrNumber);
      } else {
        this.lrDtoList.push(validationLrsDetailsData.lrNumber);
      }
    } else {
      let index = this.lrDtoList.indexOf(validationLrsDetailsData.lrNumber);
      if (index > -1) {
        this.lrDtoList.splice(index, 1);
      }
    }
    console.log(this.lrDtoList);
  }



  bulkExtendInfo() {
    this.selectedUpdateAllDate = $("#" + this.pageId + "updateAllDate").val();
    if (this.selectedUpdateAllDate == null || this.selectedUpdateAllDate == '') {

      swal({
        title: "Warning",
        text: "Please Select Date",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {

      swal({
        title: "Confirm Update",
        text: "Sure you want to change the Eway Bill Valid Upto Date for the selected LR numbers?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((bulk) => {
        if (bulk) {
          this.updateBulkAndSingle(this.selectedUpdateAllDate);
        }
      });


    }

  }

  updateExtendInfo() {
    this.selectedUpdateDate = $("#" + this.pageId + "updateDate").val();
    if (this.selectedUpdateDate == null || this.selectedUpdateDate == '') {

      swal({
        title: "Warning",
        text: "Please Select Date",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      swal({
        title: "Confirm Update",
        text: "Sure you want to change the Eway Bill Valid Upto Date for the selected LR numbers?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((bulk) => {
        if (bulk) {
          this.updateBulkAndSingle(this.selectedUpdateDate);
        }
      });
    }

  }

  updateBulkAndSingle(selectedUpdateDate) {
    this.updateLrForBulkAndSingleLrs = new LRDto();
    this.updateLrForBulkAndSingleLrs.list = this.lrDtoList;
    this.updateLrForBulkAndSingleLrs.ewayBillValidUpto = selectedUpdateDate;
    this.updateLrForBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
    this.updateLrForBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
    this.updateBulkAndSingleDetails();
    console.log(this.updateLrForBulkAndSingleLrs);

  }

  // updateBulkAndSingleDetails = () => {
  //   this.showSpinnerForActionForUpload = true;
  //   //  updateEwayBillExpiryDate
  //   this.lrReportService.updateEwayBillExpiryDate(this.updateLrForBulkAndSingleLrs).subscribe(
  //     (response) => {
  //       this.showSpinnerForActionForUpload = false;
  //       console.log(response);
  //       this.lrDtoList = [];
  //       if (response.status == 'Success') {

  //         swal({
  //           title: "Success",
  //           text: "Eway Bill validation extension done!",
  //           icon: "warning",
  //           closeOnClickOutside: false,
  //           closeOnEsc: false,
  //         });

  //       } else {
  //         swal({
  //           title: "Failure",
  //           text: "Failed to update the Eway Bill extension date!",
  //           icon: "warning",
  //           closeOnClickOutside: false,
  //           closeOnEsc: false,
  //         });

  //       }
  //       this.selectedUpdateAllDate = '';
  //       this.selectedUpdateDate = '';
  //       $("#" + this.pageId + "updateDate").val('');
  //       this.setTodayDateOnUpdateDate = '';
  //       $("#" + this.pageId + "updateAllDate").val('');
  //       this.setTodayDateOnUpdateAllDate = '';
  //       this.lrDtoList = [];
  //       this.selectedLrNumber = '';
  //       this.updateLrForBulkAndSingleLrs = new LRDto();
  //       this.searchMethodForValidityExtensionTable();
  //       this.changeDetectorRef.detectChanges();

  //     }), (error) => {
  //       this.showSpinnerForActionForUpload = false;
  //       swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
  //     }, () => console.log('done');
  // };

  updateBulkAndSingleDetails = () => {
    this.showSpinnerForActionForUpload = true;
    this.lrReportService.updateMultiEwayBillExpiryDate(this.updateLrForBulkAndSingleLrs).subscribe(
      (response) => {
        this.showSpinnerForActionForUpload = false;
        console.log(response);
        this.lrDtoList = [];
        if (response.status == 'Success') {

          swal({
            title: "Success",
            text: "Eway Bill validation extension done!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          swal({
            title: "Failure",
            text: "Failed to update the Eway Bill extension date!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        }
        this.selectedUpdateAllDate = '';
        this.selectedUpdateDate = '';
        $("#" + this.pageId + "updateDate").val('');
        this.setTodayDateOnUpdateDate = '';
        $("#" + this.pageId + "updateAllDate").val('');
        this.setTodayDateOnUpdateAllDate = '';
        this.lrDtoList = [];
        this.selectedLrNumber = '';
        this.updateLrForBulkAndSingleLrs = new LRDto();
        this.searchMethodForValidityExtensionTable();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForActionForUpload = false;
        swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
      }, () => console.log('done');
  };

  //////////////////////////////////////


  updateExtensionValidityBulkInfo() {
    this.selectedUpdaterAllReason = $("#" + this.pageId + "ignoreSelectedAll").val();
    if (this.selectedUpdaterAllReason == null || this.selectedUpdaterAllReason == '') {

      swal({
        title: "Warning",
        text: "Please Enter Reason",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {

      swal({
        title: "Confirm Update",
        text: "Sure you want to change the Extension  Validity Ignore Details For the selected LR numbers?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((bulk) => {
        if (bulk) {
          this.updateExtensionReasonForBulkAndSingle(this.selectedUpdaterAllReason);
        }
      });


    }

  }



  updateExtensionReasonForBulkAndSingle(selectedUpdateReason) {
    this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
    this.updateLrForExtensionReasonBulkAndSingleLrs.list = this.lrDtoList;
    this.updateLrForExtensionReasonBulkAndSingleLrs.reason = selectedUpdateReason;
    this.updateLrForExtensionReasonBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
    this.updateLrForExtensionReasonBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
    this.updateExtensionReasonBulkAndSingleDetails();
    console.log(this.updateLrForExtensionReasonBulkAndSingleLrs);

  }

  updateExtensionReasonBulkAndSingleDetails = () => {
    this.showSpinnerForActionForUpload = true;
    this.lrReportService.ignoreEwayBillExpiryDate(this.updateLrForExtensionReasonBulkAndSingleLrs).subscribe(
      (response) => {
        this.showSpinnerForActionForUpload = false;
        console.log(response);
        this.lrDtoList = [];
        if (response.status == 'Success') {

          swal({
            title: "Success",
            text: "Selected LR ignored from the EWay Bill Validation done!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          swal({
            title: "Failure",
            text: "Failed to update the ignore details!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        }
        this.lrDtoList = [];
        this.selectedUpdaterAllReason = '';
        $("#" + this.pageId + "ignoreSelectedAll").val('');
        this.selectedUpdateReason = '';
        $("#" + this.pageId + "ignoreSelectedSingle").val('');
        this.selectedLRNumberIgnore = '';
        this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
        this.searchMethodForValidityExtensionTable();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForActionForUpload = false;
        swal("Error", "Server error occurred while  update the Failed to update the ignore details! ", "info");
      }, () => console.log('done');
  };


  printMethodForValidityExtensionWise() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();

    if (this.validationLrsDetailsDataList.length == 0) {
      swal({

        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Agent", "Source", "Destination", "EWay Bill No", "Consignee Name", "Consignor Name", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "Goods Value", "Commodity Name", "LR Generated By", "LR Status", "Remarks", "Vehicle Number", "Invoice Number", "Last Location", "Eway Bill Valid Upto"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      this.grandActWt = 0;
      this.grandChWt = 0;
      this.grandToPay = 0;
      this.grandPaid = 0;
      this.grandGoodsVal = 0;
      for (let i = 0; i < this.validationLrsDetailsDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [this.validationLrsDetailsDataList[i].lrNumber, this.validationLrsDetailsDataList[i].agentName, this.validationLrsDetailsDataList[i].source, this.validationLrsDetailsDataList[i].destination, this.validationLrsDetailsDataList[i].eWayBillNo, this.validationLrsDetailsDataList[i].consigneeName, this.validationLrsDetailsDataList[i].consignorName, this.validationLrsDetailsDataList[i].actualWeight, this.validationLrsDetailsDataList[i].chargedWeight, this.validationLrsDetailsDataList[i].toPay, this.validationLrsDetailsDataList[i].paid, this.validationLrsDetailsDataList[i].goodsValue, this.validationLrsDetailsDataList[i].commodityName, this.validationLrsDetailsDataList[i].generatedBy, this.validationLrsDetailsDataList[i].status, this.validationLrsDetailsDataList[i].remarks, this.validationLrsDetailsDataList[i].vehicleNumber, this.validationLrsDetailsDataList[i].invoiceNumber, this.validationLrsDetailsDataList[i].location, this.validationLrsDetailsDataList[i].ewayBillValidUptoStr];

        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


        this.grandActWt = +this.grandActWt + +this.validationLrsDetailsDataList[i].actualWeight;
        this.grandChWt = +this.grandChWt + +this.validationLrsDetailsDataList[i].chargedWeight;
        this.grandToPay = +this.grandToPay + +this.validationLrsDetailsDataList[i].toPay;
        this.grandPaid = +this.grandPaid + +this.validationLrsDetailsDataList[i].paid;
        this.grandGoodsVal = +this.grandGoodsVal + +this.validationLrsDetailsDataList[i].goodsValue;

        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.validationLrsDetailsDataList.length, "", "", "", "", "", "", this.grandActWt, this.grandChWt, this.grandToPay, this.grandPaid, this.grandGoodsVal, "", "", "", "", "", "", "", ""];
      }
      //heading logics For Date

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Source", "Destination"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedSource, this.selectedDestination];



      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }

      //  this.cashMemoDtoForCustomPrintListHeadingV2 = [];



      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "EWay Bill Validation Extension Action Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }


  searchMethodForExtensionHistoryTable() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    this.lrDtoForExtensionWise = new LRDto();
    this.lrDtoForExtensionWise.source = this.selectedSource;
    this.lrDtoForExtensionWise.destination = this.selectedDestination;
    this.lrDtoForExtensionWise.fromDate = this.selectedFromDate;
    this.lrDtoForExtensionWise.toDate = this.selectedToDate;
    this.lrDtoForExtensionWise.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.lrDtoForExtensionWise);
    this.searchMethodForExtensionHistoryDetails();
  }
  searchMethodForExtensionHistoryDetails = () => {
    this.showSpinnerForAction = true;
    this.lrReportService.getMethodForExtensionHistoryDetails(this.lrDtoForExtensionWise).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "extentionHistoryDetailsTableId").DataTable().destroy();
        this.extentionHistoryDetailsDataList = [];
        this.multiSelect=false;
        this.lrDtoList = [];
        console.log(response);
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.extentionHistoryDetailsDataList = response;
        }
        this.dtTriggerExtentionHistoryDetails.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the  Extention History Report ", "info");
      }, () => console.log('done');
  };


  printMethodForExtensionHistoryWise() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();

    if (this.extentionHistoryDetailsDataList.length == 0) {
      swal({

        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];

      this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Agent", "Source", "Destination", "EWay Bill No", "Consignee Name", "Consignor Name", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "Goods Value", "Commodity Name", "Eway Bill Valid Upto", "Previous Validity Date", "Extended/Ignored By", "Extended/Ignored At", "Ignored Reason"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [5, 5, 5, 5, 10, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      this.grandActWt = 0;
      this.grandChWt = 0;
      this.grandToPay = 0;
      this.grandPaid = 0;
      this.grandGoodsVal = 0;


      for (let i = 0; i < this.extentionHistoryDetailsDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [this.extentionHistoryDetailsDataList[i].lrNumber, this.extentionHistoryDetailsDataList[i].agentName, this.extentionHistoryDetailsDataList[i].source, this.extentionHistoryDetailsDataList[i].destination, this.extentionHistoryDetailsDataList[i].eWayBillNo, this.extentionHistoryDetailsDataList[i].consigneeName, this.extentionHistoryDetailsDataList[i].consignorName, this.extentionHistoryDetailsDataList[i].actualWeight, this.extentionHistoryDetailsDataList[i].chargedWeight, this.extentionHistoryDetailsDataList[i].toPay, this.extentionHistoryDetailsDataList[i].paid, this.extentionHistoryDetailsDataList[i].goodsValue, this.extentionHistoryDetailsDataList[i].commodityName, this.extentionHistoryDetailsDataList[i].ewayBillValidUptoStr, this.extentionHistoryDetailsDataList[i].invoiceDateStr, this.extentionHistoryDetailsDataList[i].lastUpdatedBy, this.extentionHistoryDetailsDataList[i].bookingDateStr, this.extentionHistoryDetailsDataList[i].reason];

        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


        this.grandActWt = +this.grandActWt + +this.extentionHistoryDetailsDataList[i].actualWeight;
        this.grandChWt = +this.grandChWt + +this.extentionHistoryDetailsDataList[i].chargedWeight;
        this.grandToPay = +this.grandToPay + +this.extentionHistoryDetailsDataList[i].toPay;
        this.grandPaid = +this.grandPaid + +this.extentionHistoryDetailsDataList[i].paid;
        this.grandGoodsVal = +this.grandGoodsVal + +this.extentionHistoryDetailsDataList[i].goodsValue;



        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.extentionHistoryDetailsDataList.length, "", "", "", "", "", "", this.grandActWt, this.grandChWt, this.grandToPay, this.grandPaid, this.grandGoodsVal, "", "", "", "", "", ""];
      }
      //heading logics For Date

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintListHeadingV2 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From", "To "];
      this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
        this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
      }
      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "EWay Bill Validation Extension History Report ");
      this.viewCustomPrintV2 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV2) {
          this.viewCustomPrintV2 = false;
          localStorage.clear();
        }
      });
    }
  }

  clearAll() {
    this.selectedSearchBy = '';
    $("#" + this.pageId + "searchBy").val('validityExtenstion');
    this.selectedFromDate = '';
    this.selectedToDate = '';
    this.selectedDestination = '';
    $("#" + this.pageId + "destination").val('');
    this.selectedSource = '';
    $("#" + this.pageId + "source").val('');
    this.lrDtoForValidityWise = new LRDto();
    this.viewDate = false;
    this.viewSrc = true;
    this.viewDest = true;
    this.hideTable();
    this.clearTable();
    this.selectTodayDate();
    this.validationView = true;
    this.lrDtoList = [];
    this.selectedUpdateAllDate = '';
    this.selectedUpdateDate = '';
    $("#" + this.pageId + "updateDate").val('');
    this.setTodayDateOnUpdateDate = '';
    $("#" + this.pageId + "updateAllDate").val('');
    this.setTodayDateOnUpdateAllDate = '';
    this.lrDtoList = [];
    this.selectedLrNumber = '';
    this.updateLrForBulkAndSingleLrs = new LRDto();
    this.lrDtoList = [];
    this.selectedUpdaterAllReason = '';
    $("#" + this.pageId + "ignoreSelectedAll").val('');
    this.selectedUpdateReason = '';
    $("#" + this.pageId + "ignoreSelectedSingle").val('');
    this.selectedLRNumberIgnore = '';
    this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
    this.showSpinnerForActionForUpload = false;
    this.grandActWt = 0;
    this.grandChWt = 0;
    this.grandToPay = 0;
    this.grandPaid = 0;
    this.grandGoodsVal = 0;

    this.viewCustomPrintV2 = false;
    this.cashMemoDtoForCustomPrint = new CashMemoDto();
    this.cashMemoDtoForCustomPrintList = [];
    this.cashMemoDtoForCustomPrintListColumnNames = [];
    this.cashMemoDtoForCustomPrintListColumnWidths = [];
    this.cashMemoDtoForCustomPrintDataList = [];
    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
    this.cashMemoDtoForCustomPrintListColumnValues = [];
    this.cashMemoDtoForCustomPrintDataSummaryList = [];
    this.viewCustomPrintV1 = false;
    this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
    this.viewDate = false;
    this.eWayBillValidUptoDateMultiModel = null;
    $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
    this.multiEwayTableDataList = [];
    this.dtTriggerMultEway.next();
    this.newAttributeEwayBillToPush = {};
    this.selectedMultiEwyaUpdateDate = '';
  }

  lrNumberToEnquiryForm(dataList) {
    this.selectedLrNumber = dataList.lrNumber;
    console.log(this.selectedLrNumber);
    localStorage.clear();

    // localStorage.setItem('lrNumberFromEwayBillValidation',
    // 	 JSON.stringify(this.selectedLrNumber));  
    //         this.router.navigate(['/lr/enquiry/lrEnquiry']);

    localStorage.setItem('searchBy', "lrnumber");
    localStorage.setItem('enteredLrnumber', this.selectedLrNumber);
    this.router.navigate(['/lr/enquiry/lrEnquiry']);
  }

  openMultEwayPopup(validationLrsDetailsData, multipleEwayBillPopupOpen) {
    this.selectedLrNumber = validationLrsDetailsData.lrNumber;
    $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
    this.multiEwayTableDataList = [];


    for (let i = 0; i < validationLrsDetailsData.listOfmultipleEwayBill.length; i++) {
      this.newAttributeEwayBillToPush = {};
      this.newAttributeEwayBillToPush.autoId = validationLrsDetailsData.listOfmultipleEwayBill[i].autoId;
      this.newAttributeEwayBillToPush.lrNumber = validationLrsDetailsData.listOfmultipleEwayBill[i].lrNumber;
      this.newAttributeEwayBillToPush.eWayBillNo = validationLrsDetailsData.listOfmultipleEwayBill[i].eWayBillNo;
      this.newAttributeEwayBillToPush.ewayBillValidUpto = this.datePipe.transform(validationLrsDetailsData.listOfmultipleEwayBill[i].ewayBillValidUpto, "yyyy-MM-dd");
      this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);

    }
    this.dtTriggerMultEway.next();

    // this.modalRefferenceUpdateMultiEwayDatePopUp = this.modalService.open(multipleEwayBillPopupOpen, { centered: true, windowClass: "myCustomModalClassMultiEwayLr" });
    // this.modalRefferenceUpdateMultiEwayDatePopUp.result.then((result) => {
    //   this.modalRefferenceUpdateMultiEwayDateClosePopUp = `Closed with: ${result}`;
    // }, reason => {
    //   this.modalRefferenceUpdateMultiEwayDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    this.modalService.open(multipleEwayBillPopupOpen, { centered: true, windowClass: "myCustomModalClassMultiEwayLr" });
    // this.modalRefferenceUpdateMultiEwayDatePopUp.result.then((result) => {
    //   this.modalRefferenceUpdateMultiEwayDateClosePopUp = `Closed with: ${result}`;
    // }, reason => {
    //   this.modalRefferenceUpdateMultiEwayDateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
    // });

  }
  multiEwayFocus() {
    // this.dtTriggerMultEway.unsubscribe();
    this.eWayBillValidUptoDateMultiModel = null;
    this.updateMultiEwayInfo();
  }
  eWayBillValidUptoDateFocusMulti(e) {
    // console.log("am in for focus on plus btn");
    if (e.keyCode == 13) {
      // this.focusEWayBillValidUptoCalendarMulti();
      // this.chkDuplicateMultiEwayBillNo();
      this.addInTableMultiEway();
    }
  }

  focusEWayBillValidUptoCalendarMulti() {
    // console.log("am in for focus on plus btn");
    this.addInTableMultiEway();
  }
  rowSelectedEditMultiEway(multipleArtTableData, index) {
    console.log("multipleArtTableData");
    console.log(multipleArtTableData);
    this.editSelected = true;
    $("#" + this.pageId + "eWayBillNoMulti").val(multipleArtTableData.eWayBillNo);
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val(multipleArtTableData.ewayBillValidUpto);
    $("#" + this.pageId + "hiddenIndex").val(index);
    this.editTableId = index;
  }

  addInTableMultiEway() {
    this.newAttributeEwayBillToPush = {};

    this.newAttributeEwayBillToPush.eWayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    this.newAttributeEwayBillToPush.ewayBillValidUpto = $("#" + this.pageId + "eWayBillValidUptoDateMulti").val();

    // var firstDate = true;
    if (this.newAttributeEwayBillToPush.eWayBillNo != '' && this.newAttributeEwayBillToPush.ewayBillValidUpto != '' && this.newAttributeEwayBillToPush.eWayBillNo != null && this.newAttributeEwayBillToPush.ewayBillValidUpto != null) {
      if (this.editTableId != null) {
        this.multiEwayTableDataList.splice(this.editTableId, 1);
      }
      if (this.multiEwayTableDataList == null) {
        this.multiEwayTableDataList = [];
      }
      console.log("this.newAttributeEwayBillToPush");
      console.log(this.newAttributeEwayBillToPush);
      $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
      if (this.multiEwayTableDataList.length == 0) {
        this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);
        // firstDate = true;
        this.editSelected = false;
      } else {

        // firstDate = false;
        let array = this.multiEwayTableDataList;
        this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);
        this.editSelected = false;

      }

      this.dtTriggerMultEway.next();
      // if (firstDate == true) {
      //     $("#" + this.pageId + "eWayBillValidUptoDate").val(this.newAttributeMultiEway.ewayBillValidUpto);
      // }
      this.newAttributeEwayBillToPush = {};
      $("#" + this.pageId + "eWayBillNoMulti").val('');
      $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');


      this.editTableId = null;

    } else {
      swal({
        title: "Warning",
        text: "All Fields Are Mandetory",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }


  }


  updateMultiEwayInfo() {
    // this.selectedMultiEwyaUpdateDate = $("#" + this.pageId + "eWayBillValidUptoDateMulti").val();
    if (this.multiEwayTableDataList.length == 0) {
      swal({
        title: "Warning",
        text: "Please Select Date",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      swal({
        title: "Confirm Update",
        text: "Sure you want to change the Eway Bill Valid Upto Date for the selected LR numbers?",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((multiEway) => {
        if (multiEway) {
          // this.updateBulkAndSingle(this.selectedMultiEwyaUpdateDate);
          this.updateMultiEwayBill();
        }
      });
    }

  }

  updateMultiEwayBill() {
    this.updateLrForMultiEway = new LRDto();
    // this.updateLrForMultiEway.list = this.lrDtoList;
    // this.updateLrForMultiEway.ewayBillValidUpto = selectedUpdateDate;
    this.updateLrForMultiEway.lrNumber = this.selectedLrNumber;
    this.updateLrForMultiEway.lrDtos = this.multiEwayTableDataList;
    this.updateLrForMultiEway.userName = this.userDataDtoReturnSession.userId;
    this.updateLrForMultiEway.companyId = this.userDataDtoReturnSession.companyId;
    this.updateMultiEwayBillDetails();
    console.log(this.updateLrForMultiEway);

  }

  updateMultiEwayBillDetails = () => {
    this.showSpinnerForActionForUpload = true;
    this.lrReportService.updateMultiEwayBillExpiryDateV2(this.updateLrForMultiEway).subscribe(
      (response) => {
        this.showSpinnerForActionForUpload = false;
        console.log(response);
        this.lrDtoList = [];
        if (response.status == 'Success') {

          swal({
            title: "Success",
            text: "Eway Bill validation extension done!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          swal({
            title: "Failure",
            text: "Failed to update the Eway Bill extension date!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        }
        // this.selectedUpdateAllDate = '';
        // this.selectedUpdateDate = '';
        // $("#" + this.pageId + "updateDate").val('');
        // this.setTodayDateOnUpdateDate = '';
        // $("#" + this.pageId + "updateAllDate").val('');
        // this.setTodayDateOnUpdateAllDate = '';
        // this.lrDtoList = [];
        this.selectedLrNumber = '';
        this.updateLrForMultiEway = new LRDto();
        // this.searchMethodForValidityExtensionTable();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForActionForUpload = false;
        swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
      }, () => console.log('done');
  };


  closeUpdateMultiEwayDatePopUp() {
    // this.modalRefferenceUpdateMultiEwayDatePopUp.close();
    // this.selectedUpdateAllDate = '';
    // this.selectedUpdateDate = '';
    // $("#" + this.pageId + "updateDate").val('');
    // this.setTodayDateOnUpdateDate = '';
    // $("#" + this.pageId + "updateAllDate").val('');
    // this.setTodayDateOnUpdateAllDate = '';
    // this.lrDtoList = [];
    this.selectedLrNumber = '';
    this.updateLrForMultiEway = new LRDto();
    // this.eWayBillValidUptoDateMultiModel = null;
    // this.multiEwayTableDataList = [];
    // this.newAttributeEwayBillToPush = {};
    // this.editTableId = null;
    // this.editSelected = false;
    // this.selectedMultiEwyaUpdateDate = null;
  }

  searchMethodForAutoValidityExtensionTable() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    this.lrDtoForValidityWise = new LRDto();
    this.lrDtoForValidityWise.source = this.selectedSource;
    this.lrDtoForValidityWise.destination = this.selectedDestination;
    this.lrDtoForValidityWise.diffDays = this.userDataDtoReturnSession.eWayBillValidBeforeInHrs;
    // this.lrDtoForValidityWise.diffDays = 10;
    this.lrDtoForValidityWise.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.lrDtoForValidityWise);
    this.searchMethodForAutoValidityExtensionDetails();
  }

  searchMethodForAutoValidityExtensionDetails = () => {
    this.showSpinnerForAction = true;
    this.ewaybillService.getDetailsForAutoValidityExtensionDetails(this.lrDtoForValidityWise).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "autoValidationLrsDetailsTableId").DataTable().destroy();
        this.autoValidationLrsDetailsDataList = [];
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.autoValidationLrsDetailsDataList = response;
        }
        this.dtTriggerAutoValidationLrsDetailsTable.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Auto Validity Extention Report ", "info");
      }, () => console.log('done');
  }

  methodForAutoValidityExtensionTable() {

    this.lrDtoForValidityWise = new LRDto();

    this.methodForAutoValidityExtensionDetails();
  }

  methodForAutoValidityExtensionDetails = () => {
    this.showSpinnerForAction = true;
    this.ewaybillService.extendAutoEwayBillValidity(this.lrDtoForValidityWise).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.status == 'Success') {
          swal("Error", "Server Problem Occurred While getting the Auto Validity Extention Report ", "info");
        } else if (response.status == 'Failed') {
          swal("Error", "Server Problem Occurred While getting the Auto Validity Extention Report ", "info");
        }

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Auto Validity Extention Report ", "info");
      }, () => console.log('done');
  }

  searchMethodForAutoExtensionHistoryTable() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    this.lrDtoForExtensionWise = new LRDto();
    this.lrDtoForExtensionWise.source = this.selectedSource;
    this.lrDtoForExtensionWise.destination = this.selectedDestination;
    this.lrDtoForExtensionWise.fromDate = this.selectedFromDate;
    this.lrDtoForExtensionWise.toDate = this.selectedToDate;
    this.lrDtoForExtensionWise.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.lrDtoForExtensionWise);
    this.searchMethodForAutoExtensionHistoryDetails();
  }
  searchMethodForAutoExtensionHistoryDetails = () => {
    this.showSpinnerForAction = true;
    this.lrReportService.getMethodForAutoExtensionHistoryDetails(this.lrDtoForExtensionWise).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "autoExtentionHistoryDetailsTableId").DataTable().destroy();
        this.autoExtentionHistoryDetailsDataList = [];
        this.lrDtoList = [];
        this.multiSelect=false;
        console.log(response);
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.autoExtentionHistoryDetailsDataList = response;
        }
        this.dtTriggerAutoExtentionHistoryDetails.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Auto Extention History Report ", "info");
      }, () => console.log('done');
  };
  bulkAutoDateExtUpdteBtn() {
    if (this.lrDtoList.length == 0) {
      swal({
        title: "No Lrs",
        text: "Select the LR to extend the E-Way bill validity!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      this.updateBulkAndSingleAutoExtDateDetails();

    }
  }
  updateBulkAndSingleAutoDateExtData() {
    this.updateLrForBulkAndSingleLrs = new LRDto();
    this.updateLrForBulkAndSingleLrs.list = this.lrDtoList;
    this.updateLrForBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
    this.updateLrForBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
    console.log(this.updateLrForBulkAndSingleLrs);

  }


  updateBulkAndSingleAutoExtDateDetails = () => {

    this.updateBulkAndSingleAutoDateExtData();
    this.showSpinnerForAction = true;
    this.ewaybillService.updateAutoMultiEwayBillExpiryDate(this.updateLrForBulkAndSingleLrs).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.lrDtoList = [];
        if (response.status == 'Success') {

          swal({
            title: "Success",
            text: "Eway Bill validation extension done!",
            icon: "success",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          swal({
            title: "Failure",
            text: "Failed to update the Eway Bill extension date!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        }

        this.lrDtoList = [];
        this.selectedLrNumber = '';
        this.updateLrForBulkAndSingleLrs = new LRDto();
        this.searchMethodForValidityExtensionTable();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
      }, () => console.log('done');
  };

  toggleAllRows(event: Event) {
    this.multiSelect = (event.target as HTMLInputElement).checked;
    this.validationLrsDetailsDataList.forEach(item => {
      item.checked = this.multiSelect;
      this.updateLrDtoList(item, this.multiSelect);
    });
  }

  updateLrDtoList(validationLrsDetailsData, isChecked: boolean) {
    if (isChecked) {
        if (!this.lrDtoList.includes(validationLrsDetailsData.lrNumber)) {
            this.lrDtoList.push(validationLrsDetailsData.lrNumber);
        }
    } else {
        const index = this.lrDtoList.indexOf(validationLrsDetailsData.lrNumber);
        if (index > -1) {
            this.lrDtoList.splice(index, 1);
        }
    }
    console.log(this.lrDtoList);
}
}
