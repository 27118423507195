<!-- Row -->
<div class="row">

	<div class="col-lg-12">

		<div class="card">
			<div class="row" style="margin-top: 10px;">
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">Trip
									Details</h6>

								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}inoviceNumber">
											<label>Invoice Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-file-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}invoiceDate">
											<label>Invoice Date</label> <input class="form-control"
												placeholder="yyyy-mm-dd" name="invoiceDates" ngbDatepicker
												#invoiceDates="ngbDatepicker">
											<div class="input-group-append"
												(click)="invoiceDates.toggle()">
												<span class="input-group-text"> <i
													class="fa fa-calendar"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}hireslipNumber">
											<label>Hireslip Number</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-file-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}source">
											<label>Source</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-box"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}destination">
											<label>Destination</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-road"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}numberOfLrs">
											<label>Number Of LR's</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-clipboard-list"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}numberOfArticles">
											<label>Number Of Articles</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-clone"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}guranteeWeight">
											<label>Gurantee Weight</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-weight"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}kantaWeight">
											<label>Kanta Weight</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i
													class="fas fa-weight"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group" id="{{pageId}}loadedBy">
											<label>Loaded By</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="ti-user"></i>
												</span>
											</div>
											<input type="text" class="form-control"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>



							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">Goods
									Short & Extra Details</h6>
								<!-- 							<div class="row"> -->
								<div class="box-body">
									<table datatable
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsGoodsShortAndExtraDetails"
										[dtTrigger]="dtTriggerGoodsShortAndExtraDetails">

										<thead>
											<tr>
												<th>LR No</th>
												<th>Art</th>
												<th>Extra</th>
												<th>Open</th>
												<th>Short</th>
												<th>Wet</th>
												<th>Remarks</th>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="let goodsShortAndExtraDetailsData of goodsShortAndExtraDetailsDataList ">
												<td>{{ goodsShortAndExtraDetailsData.lrNo }}</td>
												<td>{{ goodsShortAndExtraDetailsData.art }}</td>
												<td>{{ goodsShortAndExtraDetailsData.extra }}</td>
												<td>{{ goodsShortAndExtraDetailsData.open }}</td>
												<td>{{ goodsShortAndExtraDetailsData.short }}</td>
												<td>{{ goodsShortAndExtraDetailsData.wet }}</td>
												<td>{{ goodsShortAndExtraDetailsData.remarks }}</td>
											</tr>


											<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

										</tbody>
									</table>

									<!-- 								</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 vl">
					<div class="row">
						<div class="col-md-12 ">
							<div class="card-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">
									Vehicle Details</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}lorryNumber">
												<label>Lorry Number</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="ti-truck"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}lorryOwner">
												<label>Lorry Owner</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}truckLength">
												<label>Truck Length</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-boxes"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}lorryOwnerDetails">
												<a (click)="goToLrEnquiryPage()"><u class="hyperLink_td">Update
														Lorry Owner Details</u></a>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}contactPerson">
												<label>Contact Person</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}address">
												<label>Address</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-hashtag"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}driverName">
												<label>Driver Name</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}driverLicenseNo">
												<label>Driver License No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-id-card"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}driverMobileNo">
												<label>Driver Mobile No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-phone"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}supplierName">
												<label>Supplier Name</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}panNumber">
												<label>Pan No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-id-card"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}insuranceNo">
												<label>Insurance No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-id-card"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}trackingDeviceId">
													<label>Tracking Device Id</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-map-marker-alt"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium"
														placeholder="Select Tracking Device Id"
														[formControl]="controlTrackingDeviceId"
														[appAutocomplete]="autocompleteTrackingDeviceId">
												</div>
											</div>
										</div>

										<app-autocomplete
											#autocompleteTrackingDeviceId="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(trackingDeviceIdOptions | filter: controlTrackingDeviceId.value) as resultTrackingDeviceId">
										<app-option *ngFor="let option of resultTrackingDeviceId"
											[value]="option.label"> {{ option.label }} </app-option> <app-option
											class="no-result" *ngIf="!resultTrackingDeviceId.length">No
										result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}noOfNoEwbInConsolidatedSystem">
												<label>No of NO EWB in Consolidated-System</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-laptop"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}noOfNoEwbInConsolidatedManual">
												<label>No of NO EWB in Consolidated-Manual</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-keyboard"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}consolidatedWwayBill">
												<label>Consolidated Eway Bill</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}confirmConsolidatedEwayBill">
												<label>Confirm Consolidated Eway Bill</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>






								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="col-md-4 vl">
					<div class="row">
						<div class="col-md-12 ">
							<div class="card-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">Billing
									Details</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}totalHire">
												<label>Total Hire</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}advance">
												<label>Advance</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}balance">
												<label>Balance</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}payableAt">
												<label>Payable At</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-map-marker-alt"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="form-group" id="{{pageId}}remarks">
												<label>Remarks</label>
												<div class="form-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-notes-medical"></i>
													</span>
												</div>
												<textarea class="form-control" rows="4"></textarea>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}deliveryPoints">
												<label>Delivery Points</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-truck-loading"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-6">
										<div class="form-group">
											<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input"
													id="{{pageId}}twoPointDD" [(ngModel)]="twoPointDoorDelivery">
												<label class=" custom-control-label wtfull "
													for="{{pageId}}twoPointDD">2 Point Door Delivery</label>
											</div>
										</div>
									</div>
									<div *ngIf="twoPointDoorDelivery" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}twoPointsDoorDeliveryLrs">
												<label>2 Point Door Delivery LRs(Multiple LRs
													separated by comma(,)):</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-truck-loading"></i>
													</span>
												</div>
												<input type="text" class="form-control"
													aria-describedby=" basic-addon11 ">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}printRewardDetails">
												<label>Print Reward Details</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-file-medical"></i>
													</span>
												</div>
												<select class="custom-select col-12">
													<option selected value="yes">Yes</option>
													<option value="no">No</option>
												</select>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<h6 class="card-title">Time</h6>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Departure Date</label> <input id="{{pageId}}departureDate"
													class="form-control" placeholder="2019-01-06"
													name="departureDates" ngbDatepicker
													#departureDates="ngbDatepicker" readonly>
												<div class="input-group-append"
													(click)="departureDates.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}departureTime">
													<label>Departure Time</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-clock"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium"
														placeholder="02:30 AM"
														[formControl]="controlDepartureTime"
														[appAutocomplete]="autocompleteDepartureTime" readonly>
												</div>
											</div>
										</div>

										<app-autocomplete #autocompleteDepartureTime="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(departureTimeOptions | filter: control.value) as resultDepartureTime">
										<app-option *ngFor="let option of resultDepartureTime"
											[value]="option.label"> {{ option.label }} </app-option> <app-option
											class="no-result" *ngIf="!resultDepartureTime.length">No
										result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}scheduledDate">
												<label>Scheduled Date</label> <input class="form-control"
													placeholder="2019-01-12" name="scheduledDates"
													ngbDatepicker #scheduledDates="ngbDatepicker" readonly>
												<div class="input-group-append"
													(click)="scheduledDates.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}scheduledTime">
													<label>Scheduled Time</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-clock"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium"
														placeholder="Select Scheduled Time"
														[formControl]="controlScheduledTime"
														[appAutocomplete]="autocompleteScheduledTime">
												</div>
											</div>
										</div>

										<app-autocomplete #autocompleteScheduledTime="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(scheduledTimeOptions | filter: controlScheduledTime.value) as resultScheduledTime">
										<app-option *ngFor="let option of resultScheduledTime"
											[value]="option.label"> {{ option.label }} </app-option> <app-option
											class="no-result" *ngIf="!resultScheduledTime.length">No
										result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div>

								</div>
							</div>
						</div>
					</div>
					<!-- 					the proceed popup starts -->





					<div class="col-md-12">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}popupDetailsNotAssigned">
								<ng-template #contentProceed let-ok="close" let-d="dismiss">


								<div class="modal-header">
									<h5>Time</h5>
									<button type="button" class="close" aria-label="Close"
										(click)="d('Cross click')">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<div class="row">
										<div class="col-md-12">
											<div class="card-body">
												<!-- 												<h6 class="card-title">Time</h6> -->
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group">
																<label>Departure Date</label> <input id="{{pageId}}departureDate"
																	class="form-control" placeholder="2019-01-06"
																	name="departureDates" ngbDatepicker
																	#departureDates="ngbDatepicker">
																<div class="input-group-append"
																	(click)="departureDates.toggle()">
																	<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																	</span>
																</div>
															</div>
														</div>
													</div>

													<div class="col-sm-12 col-md-12">
														<div class="control">
															<div class="form-group">
																<div class="input-group" id="{{pageId}}departureTime">
																	<label>Departure Time</label>
																	<div class="input-group-prepend">
																		<span class="input-group-text"> <i
																			class="fas fa-clock"></i>
																		</span>
																	</div>
																	<input class="auto_selectOption input is-medium"
																		placeholder="02:30 AM"
																		[formControl]="controlDepartureTime"
																		[appAutocomplete]="autocompleteDepartureTime">
																</div>
															</div>
														</div>

														<app-autocomplete
															#autocompleteDepartureTime="appAutocomplete">
														<ng-template appAutocompleteContent> <ng-container
															*ngIf="(departureTimeOptions | filter: control.value) as resultDepartureTime">
														<app-option *ngFor="let option of resultDepartureTime"
															[value]="option.label"> {{ option.label }} </app-option>
														<app-option class="no-result"
															*ngIf="!resultDepartureTime.length">No
														result</app-option> </ng-container> </ng-template> </app-autocomplete>
													</div>
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}scheduledDate">
																<label>Scheduled Date</label> <input
																	class="form-control" placeholder="2019-01-12"
																	name="scheduledDates" ngbDatepicker
																	#scheduledDates="ngbDatepicker">
																<div class="input-group-append"
																	(click)="scheduledDates.toggle()">
																	<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-12">
														<div class="control">
															<div class="form-group">
																<div class="input-group" id="{{pageId}}scheduledTime">
																	<label>Scheduled Time</label>
																	<div class="input-group-prepend">
																		<span class="input-group-text"> <i
																			class="fas fa-clock"></i>
																		</span>
																	</div>
																	<input class="auto_selectOption input is-medium"
																		placeholder="Select Scheduled Time"
																		[formControl]="controlScheduledTime"
																		[appAutocomplete]="autocompleteScheduledTime">
																</div>
															</div>
														</div>

														<app-autocomplete
															#autocompleteScheduledTime="appAutocomplete">
														<ng-template appAutocompleteContent> <ng-container
															*ngIf="(scheduledTimeOptions | filter: controlScheduledTime.value) as resultScheduledTime">
														<app-option *ngFor="let option of resultScheduledTime"
															[value]="option.label"> {{ option.label }} </app-option>
														<app-option class="no-result"
															*ngIf="!resultScheduledTime.length">No
														result</app-option> </ng-container> </ng-template> </app-autocomplete>
													</div>

												</div>
											</div>
										</div>
									</div>



								</div>
								</ng-template>
							</div>
						</div>
					</div>
					<!-- 					the procced popup ends -->
				</div>
				<hr style="width: 80%; border-top: none; margin: 3px;">
				<div class="col-md-3"></div>
				<div class="col-md-6" style="text-align: center;">
					<button type="button"
						(click)="openNotAssignedPopup(contentProceed)"
						class="btn btn-dark" id="{{pageId}}proceedBtn">Proceed</button>
					<button type="submit" class="btn btn-dark" id="{{pageId}}printInvoiceBtn">Print
						Invoice</button>
					<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}cancelBtn">Cancel</button>
				</div>
				<div class="col-md-3"></div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
