import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";

@Component( {
    selector: 'app-trucks-required-for-trip',
    templateUrl: './trucks-required-for-trip.component.html',
    styleUrls: ['./trucks-required-for-trip.component.css']
} )
export class TrucksRequiredForTripComponent implements  OnInit {

    truckRequiredForTripDataList: any;
    dtTriggerTruckRequiredForTrip: Subject<any> = new Subject();
    dtOptionsTruckRequiredForTrip: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
pageId="trftc";
    constructor(private router: Router,private hireslipService : HireslipService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }


    ngOnInit(): void {
        this.dtOptionsTruckRequiredForTrip = {
            dom: 'Bfrtip',
            buttons: [
                       {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"></i>',
                        titleAttr: 'Excel',
                       exportOptions: {
                       columns: ':visible'
                       }
                      }
                  ],
            // language: {
            //     search: "_INPUT_",
            //     searchPlaceholder: "Search..."
            // },
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            // destory : true,
            // "footerCallback": function (row, data, start, end, display) {
            //     var api = this.api(), data;
            //     // converting to interger to find total
            //     var intVal = function (i) {
            //         return typeof i === 'string' ?
            //             +i.replace(/[\$,]/g, '') * 1 :
            //             typeof i === 'number' ?
            //                 i : 0;
            //     };
            //     var totActWgt = api.column(1).data().reduce(
            //         function (a, b) {
            //             return intVal(a) + intVal(b);
            //         }, 0);
            //     var totChgWgt = api.column(2).data().reduce(
            //             function (a, b) {
            //                 return intVal(a) + intVal(b);
            //             }, 0);
            //     var totAvgTruckReq = api.column(3).data().reduce(
            //             function (a, b) {
            //                 return intVal(a) + intVal(b);
            //             }, 0);
                
            //     $(api.column(0).footer()).html('Total : ' + data.length);
            //     $(api.column(1).footer()).html(totActWgt);
            //     $(api.column(2).footer()).html(totChgWgt);
            //     $(api.column(3).footer()).html(""+Math.round(totAvgTruckReq));
            // }
        }
    }
    
    getUserValues() {
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.source = this.userDataDtoReturnSession.mainStation;
	}
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.hireslipService.getOSStocksDestWise(this.lrDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"truckReqForTripTableId").DataTable().destroy();
				this.truckRequiredForTripDataList = [];
				if (response.length == 0) {
					swal({
						title: "Truck Required For Trip",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.truckRequiredForTripDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerTruckRequiredForTrip.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

    // ngOnDestroy(): void {
    //     this.dtTriggerTruckRequiredForTrip.unsubscribe();
    // }
    
    ngAfterViewInit(): void {
        this.dtTriggerTruckRequiredForTrip.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}

