import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
@Component( {
    selector: 'app-memo',
    templateUrl: './memo.component.html',
    styleUrls: ['./memo.component.css']
} )
export class MemoComponent implements OnInit {

    getDataBookingMemoRptFrTable: any;

    bookingMemoRptDataList: any;


    onDestroyUnsubscribtionBookingMemoRpt: Subscription;


    loadingIndicator = true;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBookingMemoRpt: Subject<any> = new Subject();


    dataTable: any;
    dtOptionsBookingMemoRpt: any;

pageId="memoc";


    constructor( private bookingMemoRptScreen: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }




    ngOnInit() {
        this.dtOptionsBookingMemoRpt = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        this.getDataBookingMemoRptFrTable = this.bookingMemoRptScreen.getSummaryData()
        this.onDestroyUnsubscribtionBookingMemoRpt = this.getDataBookingMemoRptFrTable.subscribe( data => {
            this.bookingMemoRptDataList = data['data'];
            this.dtTriggerBookingMemoRpt.next();
        } );

    }
    ngOnDestroy(): void {
        this.dtTriggerBookingMemoRpt.unsubscribe();


        this.onDestroyUnsubscribtionBookingMemoRpt.unsubscribe();
    }

}
