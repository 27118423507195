import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from "src/app/dataService/lr-service";
import { Router } from "@angular/router";
import swal from 'sweetalert';

@Component({
    selector: 'app-lrs-short-and-extra',
    templateUrl: './lrs-short-and-extra.component.html',
    styleUrls: ['./lrs-short-and-extra.component.css']
})
export class LrsShortAndExtraComponent implements OnInit {

    closeResult: string;
    otherView = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    lrDto: LRDto = new LRDto();
    enteredLrNumber: any;
    invoiceNumber: any;
    status: any;
    remarks: any;
    destination: any;
    vehicleNumber: any;
    lrsShortage: any;
    lrDtoRet: LRDto = new LRDto();
    isReadOnly = false;
    lrDtoRetInv: LRDto = new LRDto();
    pageId = "lsaec";

    constructor(private router: Router, private modalService: NgbModal, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        }
    }
    //for popup modal starts 
    open2(content) {
        this.markAsMode("short");
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends 

    markAsMode(markAs: string) {
        if (markAs === 'short') {
            this.otherView = true;
            this.clearFields();
            // $("#" + this.pageId + "destination").prop('readonly', true);
            // $("#" + this.pageId + "invoiceNumber").prop('readonly', true);
            // $("#" + this.pageId + "truckNumber").prop('readonly', true);
        } else if
            (markAs === 'extra') {
            this.otherView = true;
            this.clearFields();
            // $("#" + this.pageId + "destination").prop('readonly', true);
            // $("#" + this.pageId + "invoiceNumber").prop('readonly', false);
            // $("#" + this.pageId + "truckNumber").prop('readonly', true);
        } else if
            (markAs === 'openCondition') {
            this.otherView = true;
            this.clearFields();
            // $("#" + this.pageId + "destination").prop('readonly', true);
            // $("#" + this.pageId + "invoiceNumber").prop('readonly', true);
            // $("#" + this.pageId + "truckNumber").prop('readonly', true);
        } else if
            (markAs === 'wetCondition') {
            this.otherView = true;
            this.clearFields();
            // $("#" + this.pageId + "destination").prop('readonly', true);
            // $("#" + this.pageId + "invoiceNumber").prop('readonly', true);
            // $("#" + this.pageId + "truckNumber").prop('readonly', true);
        } else if
            (markAs === 'invoiceClear') {
            this.otherView = false;
            this.clearFields();
            $("#" + this.pageId + "invoiceNumber").prop('readonly', false);
            // $("#" + this.pageId + "truckNumber").prop('readonly', true);
        }
    }
    ngOnInit() {
    }

    lrNumberOnEnter(e) {
        if (e.keyCode == 13) {
            this.validateLr();
        }
    }


    getValueDetails() {
        this.lrDto = new LRDto();
        this.lrDto.lrNumber = this.enteredLrNumber;
    }

    validateLr() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        if (this.enteredLrNumber == null || this.enteredLrNumber == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter LR Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $('#lsaeclrNumber').focus();
                }, 100);

            });

        } else {
            this.getValueDetails();
            this.status = $("#" + this.pageId + "marksAs option:selected").text();
            this.showSpinnerForAction = true;
            this.lrService.chkLrExist(this.lrDto).subscribe(
                (response) => {
                    console.log(response);
                    if (response.status == "Notexist") {
                        swal("Alert", "No LR found, please check the LR number!", "warning");
                    } else {
                        this.lrDtoRet = response;
                        if (this.status != "Extra") {
                            $("#" + this.pageId + "destination").prop('readonly', true);
                            $("#" + this.pageId + "invoiceNumber").prop('readonly', true);
                            $("#" + this.pageId + "truckNumber").prop('readonly', true);
                            $("#" + this.pageId + "destination").val(this.lrDtoRet.destination);
                            $("#" + this.pageId + "invoiceNumber").val(this.lrDtoRet.invoiceNumber);
                            $("#" + this.pageId + "truckNumber").val(this.lrDtoRet.vehicleNumber);
                        } else {
                            $("#" + this.pageId + "destination").prop('readonly', true);
                            $("#" + this.pageId + "invoiceNumber").prop('readonly', false);
                            $("#" + this.pageId + "truckNumber").prop('readonly', true);
                            $("#" + this.pageId + "destination").val(this.lrDtoRet.destination);
                        }
                        this.focusField();
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error while getting the LR Details", "warning");
                }, () => console.log('done');
        }
    }

    invoiceOnEnter(e) {
        if (e.keyCode == 13) {
            // this.status = $("#" + this.pageId + "marksAs option:selected").text();
            // if (this.status == 'Invoice Clear') {
            //     this.validateInvoice();
            // }
            //  for extra invEnter issue corrected.
            this.validateInvoice();
        }
    }

    validateInvoice() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        if (this.invoiceNumber == null || this.invoiceNumber == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter LR Invoice Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $('#lsaecinvoiceNumber').focus();
                }, 100);

            });

        } else {
            this.lrDto = new LRDto();
            this.lrDto.invoiceNumber = this.invoiceNumber;
            this.showSpinnerForAction = true;
            this.lrService.getLrDetForLrShortage(this.lrDto).subscribe(
                (response) => {
                    console.log(response);
                    if (response.status == "NotExist") {
                        swal({
                            title: "Alert",
                            text: "No Invoice found, '" + this.invoiceNumber + "' please check the Invoice number!",
                            icon: "warning",
                            closeOnClickOutside: true,
                            closeOnEsc: true,
                        }).then(function () {
                            swal.close();
                            window.setTimeout(function () {
                                $('#lsaecinvoiceNumber').focus();
                                $("#lsaectruckNumber").val('');
                            }, 100);

                        });
                        // swal("Alert", "No Invoice found, '" + this.invoiceNumber + "' please check the Invoice number!", "warning");
                        // $("#" + this.pageId + "truckNumber").val('');
                    } else {
                        this.lrDtoRetInv = new LRDto();
                        this.lrDtoRetInv = response;
                        $("#" + this.pageId + "truckNumber").val(this.lrDtoRetInv.vehicleNumber);
                        window.setTimeout(function () {
                            $('#lsaecconfirmBtn').focus();
                        }, 100);
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error while getting the invoice details", "warning");
                }, () => console.log('done');
        }
    }

    getChangesValueDetails() {
        this.invoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.status = $("#" + this.pageId + "marksAs option:selected").text();
        this.remarks = $("#" + this.pageId + "remarks").val();
        this.destination = $("#" + this.pageId + "destination").val();
        this.vehicleNumber = $("#" + this.pageId + "truckNumber").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.lrsShortage = $("#" + this.pageId + "noOfArticles").val();

        this.lrDto = new LRDto();
        this.lrDto.invoiceNumber = this.invoiceNumber;
        this.lrDto.status = this.status;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.lrNumber = this.enteredLrNumber;
        this.lrDto.lrsShortage = this.lrsShortage;
        this.lrDto.remarks = this.remarks;
        this.lrDto.stocksAt = "Transit";
        this.lrDto.destination = this.destination;
        this.lrDto.vehicleNumber = this.vehicleNumber;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDto);
        console.log("this.lrDto");
        console.log("this.lrDto.lrsShortage > this.lrDtoRet.totalArticles");
        console.log(this.lrDto.lrsShortage, this.lrDtoRet.totalArticles);
    }
    submitChanges() {
        this.getChangesValueDetails();
        if (this.status != 'Invoice Clear') {
            if (this.lrDto.lrNumber == null || this.lrDto.lrNumber == "" || this.lrDto.lrNumber == undefined ||
                this.lrsShortage == null || this.lrsShortage == "" || this.lrsShortage == undefined) {
                swal({
                    title: "Mandatory Fields",
                    text: "Please enter the LR Number & Number Of Articles to proceed!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                }).then(function () {
                    swal.close();
                    window.setTimeout(function () {
                        $('#lsaeclrNumber').focus();
                    }, 100);

                });

                // swal("Mandatory Fields", "Please enter the LR Number & No Of Articles to proceed!", "warning");
                // window.setTimeout(function () {
                //     $('#lsaeclrNumber').val('');
                //     $('#lsaeclrNumber').focus();
                // }, 100);
            } else {
                if (this.lrDto.lrsShortage > this.lrDtoRet.totalArticles) {
                    swal("Not Allowed", "Entered Articles is more than Actual Articles", "warning");
                } else {
                    this.confirmSubmitChanges();
                }
            }
        } else {
            if (this.lrDto.invoiceNumber == null || this.lrDto.invoiceNumber == "" || this.lrDto.invoiceNumber == undefined) {
                swal({
                    title: "Mandatory Fields",
                    text: "Please enter the Invoice Number to proceed!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                }).then(function () {
                    swal.close();
                    window.setTimeout(function () {
                        $("#lsaecinvoiceNumber").val('');
                        $('#lsaecinvoiceNumber').focus();
                    }, 100);

                });
                // swal("Mandatory Fields", "Please enter the Invoice Number to proceed!", "warning");
                // window.setTimeout(function () {
                //     $('#lsaecinvoiceNumber').val('');
                //     $('#lsaecinvoiceNumber').focus();
                // }, 100);
            }
            else {
                this.confirmSubmitChanges();
            }
        }
    }

    confirmSubmitChanges() {
        swal({
            title: "Confirm changes",
            text: "Sure U want to submit the entered details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.showSpinnerForAction = true;
                this.lrService.updateLRsShortage(this.lrDto).subscribe(
                    (response) => {
                        if (response.status == "Success") {
                            swal("Success", "Entered details submitted", "success");
                            this.clearFields();
                        } else if (response.status == "successLrAllClear") {
                            swal("Alert", "Hireslip Updated As All Lr's Clear", "warning");
                            this.clearFields();
                        } else if (response.status == "Invoiceclear exist") {
                            swal("Alert", "Short & Extra Already Exist for This Invoice Number. You Can't make this all clear", "warning");
                            this.clearFields();
                        } else {
                            swal("Alert", "Error while submitting the changes", "warning");
                        }
                        this.showSpinnerForAction = false;
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Error While submitting the changes", "warning");
                    }, () => console.log('done');
            }
        });
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    clearFields() {
        this.lrDto = new LRDto();
        $("#" + this.pageId + "invoiceNumber").val('');
        $("#" + this.pageId + "remarks").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "truckNumber").val('');
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "noOfArticles").val('');
    }

    focusField() {
        this.status = $("#" + this.pageId + "marksAs option:selected").text();
        if (this.status != "Invoice Clear") {
            window.setTimeout(function () {
                $('#lsaecnoOfArticles').focus();
            }, 100);
        }
    }
    noOfArtOnEnter(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $('#lsaecremarks').focus();
            }, 100);
        }
    }

    remarksOnEnter(e) {
        this.status = $("#" + this.pageId + "marksAs option:selected").text();
        if (e.keyCode == 13) {
            if ((this.status == "Invoice Clear") || (this.status == "Extra")) {
                window.setTimeout(function () {
                    $('#lsaecinvoiceNumber').focus();
                }, 100);
            } else {
                window.setTimeout(function () {
                    $('#lsaecconfirmBtn').focus();
                }, 100);
            }
        }

    }

}
