import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { serverUrl } from "src/environments/environment";
import { LRDto } from '../dto/LR-dto';
import { InvoiceDto } from '../dto/Invoice-dto';
import { HireSlipDto } from '../dto/HireSlip-dto';
import { TruckDataDto } from '../dto/TruckData-dto';
import { GeneralDto } from '../dto/General-dto';
import { RequestDto } from '../dto/Request-dto';
import { ResponseDto } from '../dto/Response-dto';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  //private _url = "./assets/data/supplier-master.json";

  private _baseUrl = serverUrl.webStockReport;

  constructor(
    private http: HttpClient,
  ) {
  }

  getSummaryData(): Observable<any[]> {
    //return this.http.get<SupplierModel[]>(this._url);
    return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');

  }
  getSupplierMasterData(): Observable<any[]> {
    //return this.http.get<any[]>(this._url);
    return this.http.get<any[]>('./assets/data/supplier-master.json');
  }


  getStocksSummaryData(): Observable<any[]> {
    //return this.http.get<any[]>(this._url);
    return this.http.get<any[]>('./assets/data/stocks-summary.json');
  }

  //Article Grouping //added at 20-02-2021
  getArticlesStocksSummary(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksSummaryApi`, lrDto);
  }

  getArticlesStocks(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksApi`, lrDto);
  }

  getDestStocksForInside(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getDestStocksForInsideApi`, lrDto);
  }

  getDestStocks(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getDestStocksApi`, lrDto);
  }

  getLRNewTrip(lrDtoForCheckDestination: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/getLRNewTripApi`, lrDtoForCheckDestination);
  }

  addInvoiceDetails(invoiceDto: Object): Observable<InvoiceDto> {
    return this.http.post<InvoiceDto>(`${this._baseUrl}/addInvoiceDetailsApi`, invoiceDto);
  }

  getBookingInvoiceDetails(lrDto: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/getBookingInvoiceDetailsApi`, lrDto);
  }

  getInvoiceDestinationSummary(lrDto: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/getInvoiceDestinationSummaryApi`, lrDto);
  }

  checkInvoiceForMultiPtDeliv(hireSlipDtoMultiInvoicePopup: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/checkInvoiceForMultiPtDelivApi`, hireSlipDtoMultiInvoicePopup);
  }

  getLRShortageDtls(hireSlipDto: Object): Observable<HireSlipDto[]> {
    return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLRShortageDtlsApi`, hireSlipDto);
  }

  getLrsForNoEwayBillValidations(ewayBillLrForLRDto: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/getLrsForNoEwayBillValidationsApi`, ewayBillLrForLRDto);
  }

  getTruckTypeFromTruckMaster(hireSlipDtoGoodsTypes: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/getTruckTypeFromTruckMasterApi`, hireSlipDtoGoodsTypes);
  }

  chkHireslipStatus(chkStatusHireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/chkHireslipStatusApi`, chkStatusHireSlipDto);
  }

  addHireSlipDetails(hireSlipDtoSaveHireslip: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/addHireSlipDetailsApi`, hireSlipDtoSaveHireslip);
  }

  getRcLicInscDtls(truckDataDto: Object): Observable<TruckDataDto> {
    return this.http.post<TruckDataDto>(`${this._baseUrl}/getRcLicInscDtlsApi`, truckDataDto);
  }

  getPanInscDtls(truckDataDto: Object): Observable<TruckDataDto> {
    return this.http.post<TruckDataDto>(`${this._baseUrl}/getPanInscDtlsApi`, truckDataDto);
  }

  updateHireSlipStatus(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/updateHireSlipStatusApi`, hireSlipDto);
  }

  getHireSlipDetails(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireSlipDetailsApi`, hireSlipDto);
  }

  getGodownsDetails(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/getGodownsDetailsApi`, hireSlipDto);
  }

  setRcLicInscDtls(truckDataDto: Object): Observable<TruckDataDto> {
    return this.http.post<TruckDataDto>(`${this._baseUrl}/setRcLicInscDtls`, truckDataDto);
  }

  setLrHideInDummyInvoice(lrDto: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/setLrHideInDummyInvoiceApi`, lrDto);
  }

  trackingIntgTripFT(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/trackingIntgTripFTApi`, hireSlipDto);
  }

  trackingIntgTripFTUpdate(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/trackingIntgTripFTUpdateApi`, hireSlipDto);
  }

  getLRList(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getLRListApi`, lrDto);
  }

  addNumberOfTrucks(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/addNumberOfTrucksApi`, hireSlipDto);
  }
  //end 
  //local booking
  getArticlesStocksSummForLocalBooking(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksSummForLocalBookingApi`, lrDto);
  }

  getArticlesStocksForLocalBooking(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksForLocalBookingApi`, lrDto);
  }

  getBookingStocksForLocalOpenTrip(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingStocksForLocalOpenTripApi`, lrDto);
  }

  getBookingStocksForLocal(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingStocksForLocalApi`, lrDto);
  }

  //stock grouping maaz 12042021
  determineDepDateTime(hireSlipDto: Object): Observable<HireSlipDto> {
    return this.http.post<HireSlipDto>(`${this._baseUrl}/determineDepDateTimeApi`, hireSlipDto);
  }

  getBookingStocksForLocalBarcode(lrDto: Object): Observable<LRDto> {
    return this.http.post<LRDto>(`${this._baseUrl}/getBookingStocksForLocalBarcodeApi`, lrDto);
  }

  updateHireslipTruckType(truckDataDto: Object): Observable<GeneralDto> {
    return this.http.post<GeneralDto>(`${this._baseUrl}/updateHireslipTruckTypeApi`, truckDataDto);
  }

  getInvoiceLoadingSheetFlow(lrDto: Object): Observable<LRDto[]> {
    return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceLoadingSheetFlowApi`, lrDto);
  }

  // COD LR's by Asrar Jr on 09/05/2024
  getCODLRDetailLists(requestDto: Object): Observable<ResponseDto[]> {
    return this.http.post<ResponseDto[]>(`${this._baseUrl}/getCODLRDetailListsApi`, requestDto);
  }
}

