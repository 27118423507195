<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Hireslip History Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewType"
															name="viewType" #viewType
															(change)="viewTypeMode(viewType.value)">
															<option selected value="hireslipHistory">Hireslip History</option>
															<option value="lrHistory">LR History</option>
														</select>
													</div>
												</div>
											</div>
											
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
 															name="searchBy" #searchBy
 															(change)="searchByMode(searchBy.value)">
															<option selected value="hireslipNoWise">Hireslip No Wise</option>
															<option value="dateWise">Date Wise</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="searchByHireslipNoWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}hireslipNo">
														<label>Hireslip No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="searchByDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}fromDate">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="searchByDateWise" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input
															id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd"
															name="toDates" ngbDatepicker #toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											





										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsHireslipHistory" [dtTrigger]="dtTriggerHireslipHistory">

								<thead>
									<tr>
										<th>Truck No</th>
										<th>Depart Date</th>
										<th>Depart Time</th>
										<th>Driver</th>
										<th>Enter By</th>
										<th>Loaded By</th>
										<th>Sch Date</th>
										<th>Sch Time</th>
										<th>Supplier</th>
										<th>Tot Hire</th>
										<th>Gur Wt</th>
										<th>Kan Wt</th>
										<th>Advance</th>
										<th>Balance</th>
										<th>Status</th>
										<th>Reason</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let hireslipHistoryData of hireslipHistoryDataList ">
										<td>{{ hireslipHistoryData.truckNo }}</td>
										<td>{{ hireslipHistoryData.departDate }}</td>
										<td>{{ hireslipHistoryData.departTime }}</td>
										<td>{{ hireslipHistoryData.driver }}</td>
										<td>{{ hireslipHistoryData.enterBy }}</td>
										<td>{{ hireslipHistoryData.loadedBy }}</td>
										<td>{{ hireslipHistoryData.schDate }}</td>
										<td>{{ hireslipHistoryData.schTime }}</td>
										<td>{{ hireslipHistoryData.supplier }}</td>
										<td>{{ hireslipHistoryData.totHire }}</td>
										<td>{{ hireslipHistoryData.gurWt }}</td>
										<td>{{ hireslipHistoryData.kanWt }}</td>
										<td>{{ hireslipHistoryData.advance }}</td>
										<td>{{ hireslipHistoryData.balance }}</td>
										<td>{{ hireslipHistoryData.status }}</td>
										<td>{{ hireslipHistoryData.reason }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>
							<div>
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionLrHistory" [dtTrigger]="dtTriggerLrHistory">
									<thead>
										<tr>
											<th>Lr No</th>
											<th>Updated By</th>
											<th>Updated On</th>
											<th>Status</th>
											<th>Reason</th>
											
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let lrHistoryData of lrHistoryDataList ">
											<td>{{ lrHistoryData.lrNo }}</td>
											<td>{{ lrHistoryData.updatedBy }}</td>
											<td>{{ lrHistoryData.updatedOn }}</td>
											<td>{{ lrHistoryData.status }}</td>
											<td>{{ lrHistoryData.reason }}</td>
										</tr>


										<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

									</tbody>

								</table>
							</div>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>