<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row"*ngIf="isLoggedIn" id="{{pageId}}showExpPaymentRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Expenses Details Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}type" name="type">
															<option selected value="all">All</option>
															<option value="paid">Paid</option>
															<option value="unpaid">Unpaid</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate"
															[(ngModel)]="setTodayDateOnFromDate" ngbDatepicker
															#fromDate="ngbDatepicker">
														<div class="input-group-append" (click)="fromDate.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDate" class="form-control"
															[(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy"
															name="toDate" ngbDatepicker #toDate="ngbDatepicker">
														<div class="input-group-append" (click)="toDate.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<!-- 											the first autocomplete starts -->
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Vendor Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text">
																<i class="ti-user"></i>
															</span>
														</div>
														<input #vendorName id="{{pageId}}vendorName" name="vendorName" type="text"
															class="form-control" [(ngModel)]="modelVendor"
															[ngbTypeahead]="searchVendor"
															[resultFormatter]="formatterVendor"
															[inputFormatter]="formatterVendor"
															(focus)="focusVendorTA$.next($any($event).target.value)"
															placeholder="Select Vendor" />
													</div>
												</div>
											</div>
											<!-- 											the first autocomplete ends -->

										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="searchMethod()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearAll();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="card-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="row p-t-10">

								<div class="col-md-8">
									<h6 class="card-title">Expenses Details Report</h6>


								</div>
								<div class="col-md-4">
										<button  type="submit" class="dt-button"
										style="margin-left:225px;" id="{{pageId}}printBtn" (click)="printMethod()">
										<span><i class="fas fa-print">Print</i></span>
									</button>

								</div>
							</div>
							<div class="box-body">
								<table datatable id="{{pageId}}expensesPaymentFollowupReportId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsExpensesPaymentFollowupReport"
									[dtTrigger]="dtTriggerExpensesPaymentFollowupReport">

									<thead>
										<tr>
											<th>Bill Number</th>
											<th>Bill Date</th>
											<th>Vendor Name</th>
											<th>Bill Amount</th>
											<th>Paid Amount</th>
											<th>Due Amount</th>
											<th *ngIf="btnPayEntryView">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let expensesPaymentFollowupReportData of expensesPaymentFollowupReportDataList ">
											<td>{{ expensesPaymentFollowupReportData.billNumber }}</td>
											<td>{{ expensesPaymentFollowupReportData.billDateStr }}</td>
											<td>{{ expensesPaymentFollowupReportData.supplierName }}</td>
											<td>{{ expensesPaymentFollowupReportData.grandTotal==null ?0: expensesPaymentFollowupReportData.grandTotal }}
											</td>
											<td>{{ expensesPaymentFollowupReportData.totalAmount ==null ?0:expensesPaymentFollowupReportData.totalAmount}}
											</td>
											<td>{{ expensesPaymentFollowupReportData.baseFreight }}</td>
											<td *ngIf="btnPayEntryView"><button type="submit" class="btn btn-primary m-r-10"
													style="padding: 1px 4px;" id="{{pageId}}paymentEntryBtn"
													(click)="paymentEntryBtn(expensesPaymentFollowupReportData,paymentEntryPopUpTemplate);">
													<i class="fas fa-file-alt"></i>Payment Entry
												</button></td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td *ngIf="btnPayEntryView"></td>
										</tr>
									</tfoot>
								</table>


							</div>
						</div>
					</div>




				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #paymentEntryPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h5>Bill Number: {{popupBillNumber}}, Vendor Name: {{popupVendorName}} </h5>

						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
											<div class="form-group">
												<div class="input-group">
													<mat-progress-bar mode="indeterminate" style="color: green;">
													</mat-progress-bar>
													<br>
													<h6 class="card-title" align='center'
														style="color: green; margin: auto; font-size: 18px;">
														Please Wait ....</h6>
												</div>
											</div>
										</div>
									</div>
									<h5>Payment Details </h5>
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Total Bill Amount:</label> <input id="{{pageId}}totalBillAmt"
														name="totalBillAmt" type="number" class="form-control"
														aria-describedby="basic-addon11" />
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Payment Mode:</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-question"></i>
														</span>
													</div>
													<select #paymentMode class="custom-select col-12" id="{{pageId}}paymentMode"
														name="paymentMode" (change)="searchByMode(paymentMode.value)">
														<option selected value="Cash">Cash</option>
														<option value="Cheque">Cheque</option>
														<option value="NEFT">NEFT/RTGS</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Amount Paid:</label> <input id="{{pageId}}amountPaid" name="amountPaid"
														type="number" class="form-control"
														aria-describedby="basic-addon11" />
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Payment Date:</label> <input id="{{pageId}}paymentDate"
														class="form-control" placeholder="dd-mm-yyyy" name="paymentDate"
														[(ngModel)]="setPaymentDate" ngbDatepicker
														#paymentDate="ngbDatepicker">
													<div class="input-group-append" (click)="paymentDate.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="showChqNumber" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Cheque Number:</label> <input id="{{pageId}}chequeNumber"
														name="chequeNumber" type="text" class="form-control"
														aria-describedby="basic-addon11" />
												</div>
											</div>
										</div>
										<div *ngIf="showRefNumber" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Reference Number:</label> <input id="{{pageId}}referenceNumber"
														name="refrenceNumber" type="text" class="form-control"
														aria-describedby="basic-addon11" />
												</div>
											</div>
										</div>
										
								
										<div class="col-sm-12 col-md-12" *ngIf="showBankName">
												<div class="form-group">
													<div class="input-group">
														<label>Bank Name: </label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<select #paymentMode class="custom-select col-12" id="{{pageId}}bankName"
															name="bankName">
															<option selected value="selectBank">Select Bank</option>
															<option  value="SBI">SBI</option>
															<option value="HDFC">HDFC</option>
															<option value="ICICI">ICICI</option>
															<option value="AXIS">AXIS</option>
															<option value="BOI">BOI</option>
															<option value="PNB">PNB</option>
															
														</select>
													</div>
												</div>
											</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Balance Amount:</label> <input id="{{pageId}}balanceAmt"
														name="balanceAmt" type="number" class="form-control"
														aria-describedby="basic-addon11" />
												</div>
											</div>
										</div>
									</div>

									<br>
									<div class="row">


										<div class="col-sm-12 col-md-12">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnId" (click)="closePaymentEntryPopUp();">Cancel</button>
											<button style="float: right;" type="button" class="btn btn-success m-r-10"
												id="{{pageId}}confirmPaymentBtn" (click)="validateConfirmBtn();">Confirm
												Payment</button>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
			<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
		</div>
</body>

</html>