<div mat-dialog-content id="{{pageId}}showLRIssueBalcRpt">
	<h6>Destination : {{onloadDestination}} - {{onloadSource}} "-" {{onloadMode}}</h6>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<!-- spinner start-->
				<div *ngIf="showSpinnerForAction" class="col-md-12">
					<div class="form-group">
						<div class="input-group">
							<mat-progress-bar mode="indeterminate" style="color: green;">
							</mat-progress-bar>
							<br>
							<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
								Please Wait Loading Details.....</h6>
						</div>
					</div>
				</div>
				<!-- spinner end-->
				<div class="col-md-12">
					<!--<button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrint();"
						id="{{pageId}}printAllBtn">
						<span><i class="fas fa-print">Print</i></span>
					</button>-->
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}consigneeActiveNonActivePopUpTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsConsigneeActiveNonActivePopUp"
								[dtTrigger]="dtTriggerConsigneeActiveNonActivePopUp">
								<thead>
									<tr>
										<th>Consignee Name</th>
										<th>GST Number</th>
										<th>Address</th>
										<th>Area</th>
										<th>Contact Person</th>
										<th>Office Number</th>
										<th>Mobile Number</th>
										<th>Email Id</th>
										<th>Collection Man</th>
										<th>Commodity</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let gridConsigneeActiveNonActive of gridConsigneeActiveNonActiveList let i = index">
										<td>{{ gridConsigneeActiveNonActive.consigneeName }}</td>
										<td>{{ gridConsigneeActiveNonActive.gstNoConsignee }}</td>
										<td>{{ gridConsigneeActiveNonActive.address }}</td>
										<td>{{ gridConsigneeActiveNonActive.areaOfDelivery }}</td>
										<td>{{ gridConsigneeActiveNonActive.contactPerson }}</td>
										<td>{{ gridConsigneeActiveNonActive.officeNo }}</td>
										<td>{{ gridConsigneeActiveNonActive.mobileNum }}</td>
										<td>{{ gridConsigneeActiveNonActive.emailId }}</td>
										<td>{{ gridConsigneeActiveNonActive.collectionMan }}</td>
										<td>{{ gridConsigneeActiveNonActive.mainCommodity }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
	<button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>

<!--<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>-->