<!-- PFCHG_V5 @Imran Chg, Added Debit mode and upi in payment mode added on 10052024 -->

<div *ngIf="isLoggedIn" class="page row system_responsive" id="{{pageId}}challanPrint">
    <div class="container border">
        <div class="col-md-12">
            <!--first row starts-->
            <div class="row" style="padding-top: 5px;">

                <div class="col-md-5">
                    <!-- 					<h6 style="font-size: 16px;" [innerHtml]="addressLineOne"> -->
                    <!-- 						 {{addressLineOne}} -->
                    <!-- 					</h6> -->
                    <!-- 					<h6 style="font-size: 16px;"> -->
                    <!-- 						 {{addressLineTwo}}  -->
                    <!-- 					</h6> -->
                    <h6 style='margin-left: 20px; font-size: 16px; word-wrap: break-word;'>
                        {{this.address}}
                    </h6>

                </div>
                <div class="col-md-7">
                    <img style='margin-left: 270px;' src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                </div>
            </div>
            <!--first row starts-->
            <div class="row">
                <div class="col-md-12" align='center'>
                    <h6 style='margin-left: 20px; font-size: 24px;'>
                        <strong><u>{{setMainStation}}</u></strong> <strong><u>
								COLLECTION REPORT</u></strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" align='left'>
                    <h6 style='margin-right: 20px; font-size: 22px;'>
                        <strong>Date : </strong> <strong>{{completedOnDt}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" align='left'>
                    <h6 style='margin-right: 20px; font-size: 22px;'>
                        <strong>Collection Man : </strong> <strong>{{collectionMan}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row" style='border-top: 1px solid #000;'>
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>CASH : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalCashAmt}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>CHEQUE : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalChqAmt}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>NEFT/IMPS : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalNeftAmt}}</strong>
                    </h6>
                </div>
            </div>
            <!-- PFCHG_V5 -->
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>DEBIT A/c : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalDebitAcAmt}}</strong>
                    </h6>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>UPI : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalUPIAmt}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>PARTY LESS (DELHI) : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.delhiLess}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>PARTY LESS (MUMBAI) : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.mumbaiLess}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>PARTY LESS (GUJARAT) : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.gujaratLess}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>PARTY LESS (PUNJAB) : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.punjabLess}}</strong>
                    </h6>
                </div>
            </div>
            <div class="row" *ngFor="let lessDescOptData of lessDescOpt">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>{{lessDescOptData.label}} : </strong>
                    </h6>
                </div>
                <div *ngIf="lessDescOptData.label == 'TDS' || lessDescOptData.label == 'CLAIM'" class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{myFunction(lessDescOptData.label)}}</strong>
                    </h6>
                </div>
                <div *ngIf="lessDescOptData.label != 'TDS' && lessDescOptData.label != 'CLAIM'" class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px; margin-left: 390px;'>
                        <strong>{{myFunction(lessDescOptData.label)}}</strong>
                    </h6>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>OTHERS : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong></strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 21px; font-size: 18px; padding-top: 17px;'>
                        <strong>TOTAL COLLECTION AMOUNT :</strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.grandTotal}} </strong>
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5" align='left'>
                    <h6 style='margin-right: 20px; font-size: 18px; padding-top: 17px;'>
                        <strong>TOTAL GC COLLECTED : </strong>
                    </h6>
                </div>
                <div class="col-md-7" style='border-bottom: 1px solid #000;'>
                    <h6 style='font-size: 20px; padding-top: 17px;' align='center'>
                        <strong>{{this.getChallanPrintValDto.totalMemos}} </strong>
                    </h6>
                </div>
            </div>
            <div class="row" style='border-bottom: 1px solid #000;'>
                <div class="col-md-12" align='left'></div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;2000</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof2000Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF2000}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;500</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof500Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF500}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;200</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof200Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF200}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;100</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof100Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF100}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;50</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof50Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF50}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;20</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof20Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF20}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;10</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof10Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF10}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;5</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof5Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF5}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;2</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof2Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF2}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <h6 style='font-size: 15px;'>
                                <strong>&nbsp;1</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> X </strong>
                            </h6>
                        </div>
                        <div class="col-md-2" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfof1Count}}</strong>
                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px;'>
                                <strong> = </strong>
                            </h6>
                        </div>
                        <div class="col-md-4" style='border-bottom: 1px solid #000;'>
                            <h6 style='font-size: 15px;' align='center'>
                                <strong>{{this.getChallanPrintValDto.nfOF1}}</strong>
                            </h6>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-12" align='left'></div>
            </div>
        </div>
    </div>