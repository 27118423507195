<!-- Row -->
<div class="row">
		<div class="col-lg-12">
	
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Commodity Rate Master</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-4 vl">
						<div class="row">
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-lg-12">
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<div class="control">
															<div class="form-group">
																<div class="input-group">
																	<label>Station</label>
																	<div class="input-group-prepend">
																		<span class="input-group-text"> <i
																				class="fas fa-user"></i>
																		</span>
																	</div>
																	<input id="{{pageId}}destination" type="text" class="form-control"
																		[(ngModel)]="modelDestination"
																		(selectItem)="commodityDestListener($event)"
																		[ngbTypeahead]="searchDestination"
																		[resultFormatter]="formatterDestination"
																		[inputFormatter]="formatterDestination"
																		(focus)="focusDestinationTA$.next($any($event).target.value)"
																		placeholder="Select Station" />
	
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group">
																<label>Goods Type :</label>
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-warehouse"></i>
																	</span>
																</div>
																<input #CommodityMainUnldg id="{{pageId}}CommodityMainUnldgId"
																	type="text" placeholder="Select Commodity"
																	class="form-control"
																	[(ngModel)]="modelCommodityMainUnldg"
																	[ngbTypeahead]="searchCommodityMainUnldg"
																	[resultFormatter]="formatterCommodityMainUnldg"
																	[inputFormatter]="formatterCommodityMainUnldg"
																	(focus)="focusCommodityMainUnldgTA$.next($any($event).target.value)"
																	autocomplete="off" />
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-4">
														<div class="form-group">
															<div class="input-group">
															
																<input type="number" class="form-control" id="{{pageId}}gdHamali"
																	placeholder="GD Hamali"
																	aria-describedby="basic-addon11">
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-4">
														<div class="form-group">
															<div class="input-group">
															
																<input type="number" class="form-control"id="{{pageId}}ddHamali"
																	placeholder="DD Hamali"
																	aria-describedby="basic-addon11">
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-4">
														<div class="form-group">
															<div class="input-group">
															
																<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}addBtn"(click)="validateAddInTable();">Add</button>
															</div>
														</div>
													</div>
													<div class="col-md-12">
														<div class="box-body">
															<table datatable id="{{pageId}}commodityTableId"
																class="table table-striped table-bordered row-border hover"
																[dtOptions]="dtOptionsCommodity"
																[dtTrigger]="dtTriggerCommodity">
	
																<thead>
																	<tr>
																		<th>Commodity Name</th>
																		<th>H. GD</th>
																		<th>H. DD</th>
																		<th>Action</th>
	
																	</tr>
																</thead>
																<tbody>
																	<tr *ngFor="let commodityData of commodityDataList let i = index ">
																		<td>{{ commodityData.commodityName }}</td>
																		<td>{{ commodityData.hamaligd}}</td>
																		<td>{{ commodityData.hamali }}</td>
																		<td><i style=" cursor: pointer;" class="fas fa-pencil-alt"
																			title="Edit Particlar Details"(click)="rowSelectedParticularDataOnEdit(commodityData,i);"></i>
																		&nbsp;&nbsp;
																		<i style=" cursor: pointer;" class="fas fa-trash"
																			title="Delete Particlar Details"(click)="deleteSelectedParticularDetails(commodityData,i);"></i>
																		</td>
	
																	</tr>
																	
																</tbody>
															</table>
	
	
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-sm-12 col-md-12" style='padding-top: 5px;'>
														<div class="form-group">
															<div class="input-group">
																<label>Unit</label>
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-weight"></i>
																	</span>
																</div>
																<select class="custom-select col-12" id="{{pageId}}unit"name="unit" #unit>
																	
																	<option selected value="Article">Article</option>
																	<option value="ActualWeight">Actual Weight</option>
																	<option value="ChargedWeight">Charged Weight</option>
																</select>
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group" >
																<label>Recipt Charge</label>
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-rupee-sign"></i>
																	</span>
																</div>
																<input type="number"id="{{pageId}}reciptCharge" class="form-control"
																	aria-describedby="basic-addon11">
															</div>
														</div>
													</div>
													<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group" >
																<label>Service Charge</label>
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																			class="fas fa-rupee-sign"></i>
																	</span>
																</div>
																<input type="number" id="{{pageId}}serviceCharge"class="form-control"
																	aria-describedby="basic-addon11">
															</div>
														</div>
													</div>
	
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12" style="text-align: center;">
									<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}applyBtn"(click)="btnApply();">Apply</button>
									<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"(click)="clearAll();">Clear</button>
								</div>
							</div>
						</div>
					</div>
	
	
	
					<div class="col-md-8 vl p-t-10">
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="row p-t-10">
								<div class="col-md-8">
									<h6 class="card-title">Destination -Commodity Wise Rate
									</h6>
	
	
	
								</div>
								<div class="col-md-4">
									<button type="submit" class="dt-button" style="margin-left: 50%;"
										id="{{pageId}}printBtnForGetDetails"(click)="getDetailsMethod();">
										<span><i class="fas fa-print">Get Details</i></span>
									</button>
	
								</div>
							</div>
							<div class="box-body">
								<div>
									<table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}commissionRateMasterTableId"
										[dtOptions]="dtOptionsCommissionRateMaster"
										[dtTrigger]="dtTriggerCommissionRateMaster">
										<thead>
											<tr>
												<th>Destination</th>
												<th>Commodity Name</th>
												<th>Hamali GD</th>
												<th>Hamali DD</th>
												<th>Receipt Charge</th>
												<th>Service Charge</th>
												<th>Hamali Unit</th>
												<th>Effective From</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let commissionRateMasterData of commissionRateMasterDataList ">
												<td>{{ commissionRateMasterData.destination }}</td>
												<td>{{ commissionRateMasterData.commodityName }}</td>
												<td>{{ commissionRateMasterData.hamaligd}}</td>
												<td>{{ commissionRateMasterData.hamali }}</td>
												<td>{{ commissionRateMasterData.receiptCharges }}</td>
												<td>{{ commissionRateMasterData.serviceCharges }}</td>
												<td>{{ commissionRateMasterData.unit}}</td>
												<td>{{ commissionRateMasterData.lastModifiedDateStr }}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
	
	
											</tr>
										</tfoot>
	
									</table>
								</div>
							</div>
	
	
						</div>
					</div>
	
	
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>