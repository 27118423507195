import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { TripDeliveryConfirmationDetailsComponent } from 'src/app/dashboards/dashboards-popup/trip-delivery-confirmation-details/trip-delivery-confirmation-details.component';


@Component({
    selector: 'app-local-trips',
    templateUrl: './local-trips.component.html',
    styleUrls: ['./local-trips.component.css']
})
export class LocalTripsComponent implements OnInit {
    localTripDataList: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLocalTrip: Subject<any> = new Subject();
    dtOptionsLocalTrip: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;

    lrDtoForLocalTrip: LRDto = new LRDto();
    lrDtoForLocalTripSelectedRow: LRDto = new LRDto();
    lrDtoForLocalTripSelectedRowReturn: any;
    lrDtoForLocalTripListForLocalStorage: LRDto = new LRDto();
    pageId = "lctrc";

    constructor(private dashboardService: DashboardService, public dialog: MatDialog,
        private router: Router, private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsLocalTrip = {
            dom: 'Bfrtip',
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerLocalTrip.next();
    }

    gridReconifgureOnReloadBtn() {
        this.lrDtoForLocalTrip = new LRDto();

        this.lrDtoForLocalTrip.status = "Pending";
        this.lrDtoForLocalTrip.godownName = this.userDataDtoReturnSession.office;
        this.lrDtoForLocalTrip.companyId = this.userDataDtoReturnSession.companyId;
        //console.log(this.lrDtoForLocalTrip);
        this.gridReconifgureDetails();
    }
    gridReconifgureDetails = () => {
        this.showSpinnerForAction = true;
        this.dashboardService.getPendingLocalTrips(this.lrDtoForLocalTrip).subscribe(
            (response) => {
                //console.log(response);
                this.localTripDataList = [];
                // $("#"+this.pageId+"localTripTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.localTripDataList = response;
                } else {
                    swal("Warning", "No Details found !", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerLocalTrip.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Pending Schedule Local Trip", "info");
            }, () => console.log('done');
    };

    slectedRowOfLocalTrip(localTripData) {
        //console.log(localTripData);
        this.lrDtoForLocalTripSelectedRow = new LRDto();
        this.lrDtoForLocalTripSelectedRow.tripId = localTripData.tripId;
        this.lrDtoForLocalTripSelectedRow.status = "Pending";
        this.lrDtoForLocalTripSelectedRow.godownName = this.userDataDtoReturnSession.office;
        this.lrDtoForLocalTripSelectedRow.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForLocalTripSelectedRow.deliverygodown = localTripData.deliverygodown;
        this.setLocalTripSheetDetails();
    }

    setLocalTripSheetDetails() {
        this.showSpinnerForAction = true;
        this.hireslipService.getLocalTripsLrsDetails(this.lrDtoForLocalTripSelectedRow).subscribe(
            (response) => {
                console.log(response);
                this.lrDtoForLocalTripSelectedRowReturn = [];
                this.lrDtoForLocalTripListForLocalStorage = new LRDto();
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.lrDtoForLocalTripSelectedRowReturn = response;
                    this.lrDtoForLocalTripListForLocalStorage.tripId = this.lrDtoForLocalTripSelectedRow.tripId;
                    this.lrDtoForLocalTripListForLocalStorage.deliverygodown = this.lrDtoForLocalTripSelectedRow.deliverygodown;
                    this.lrDtoForLocalTripListForLocalStorage.lrDtoList = this.lrDtoForLocalTripSelectedRowReturn;
                    localStorage.clear();
                    localStorage.setItem('localTripsheetDashboardForPopup',
                        JSON.stringify(this.lrDtoForLocalTripListForLocalStorage));

                    const dialogRef = this.dialog.open(TripDeliveryConfirmationDetailsComponent, {
                        /* height: '500px',
                       width: '1300px',
                     position: {
                           top: '50vh',
                           left: '50vw'
                       },*/
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        console.log('The dialog was closed');
                        console.log(result);
                        if (result != null && result != undefined &&
                            result == "No Trip") {
                            this.gridReconifgureOnReloadBtn();
                        }
                        // this.router.navigate(['.'], { relativeTo: this.route });
                    });
                } else {
                    swal("Warning", "No Details found ", "warning");
                }
                console.log(this.lrDtoForLocalTripListForLocalStorage);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting local trip lrs Details ", "error");
            },
            () => console.log('done');
    };

}
