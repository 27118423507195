<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <!-- <h6 class="card-title text-white">Confirm Consignee Mobile Number</h6> -->
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Destination</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
                                                        class="fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                placeholder="Select Destination" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button #saveBtn type="submit" class="btn btn-success m-r-10" (click)="getConsigneeMobileNoConfirmList()" id="{{pageId}}searchBtn">Search</button>
                    </div>
                </div>
                <div class="col-md-8 vl">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}consigneeDetailsDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSpecificConsigneeDetails" [dtTrigger]="dtTriggerSpecificConsigneeDetails">
                                        <thead>
                                            <tr>
                                                <th>Consignee Name</th>
                                                <th>GST Number</th>
                                                <th>Destination</th>
                                                <th>Added Mobile Number</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let consigneeData of consigneeDataList">
                                                <td>{{ consigneeData.consigneeName }}</td>
                                                <td>{{ consigneeData.gstNoConsignee }}</td>
                                                <td>{{ consigneeData.destination }}</td>
                                                <td>{{ consigneeData.mobileNum }}</td>
                                                <td>
                                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmBtn" (click)="validateConsigneeMobileNoConfirm(consigneeData);">
																<i title="Confirm" class="fa fa-check"></i>
															</button>
                                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}rejectBtn" (click)="rejectRequest(rejectPopup,consigneeData);">
																<i title="Reject" class="fa fa-trash"></i>
															</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<ng-template #rejectPopup let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Specify the rejection reason</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="col-sm-12 col-md-12">
                    <div class="control">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Reason:</label>
                                <textarea class="form-control" aria-describedby="basic-addon11" #rejectReason id="{{pageId}}rejectReason" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12">
                    <button type="submit" class="btn btn-dark" (click)="validateConsigneeMobileNoReject()">Submit</button>
                    <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>