//comments section
//CHG-V-CM:01 - @Asrar chg, even dis in -ve then the same should show as per Sadakath @Dated:18/04/2020
//CHG-V-CM:02 - @Asrar chg, if dis in -ve then block and show the msg as per Senthil Sir @Dated:17/02/2021
//CHG-V-CM:03 - @Asrar chg, As per Senthil Sir, for bng src bkg unloading hamali and other chg should zero even rate set only for door delivery @08/03/2021
//CHG-V-CM:04 - @Asrar chg, getting gc&dd from consignee rate master if set else from edit company
//CHG-V-CM:05 - @Asrar chg, for paid lrs dis and fov set to zero
//CHG_V-CM:06 - @Asrar chg, for binnymills, option to tick for direct assign @Senthil Sir req
//CHG_V-CM:07 - @Asrar chg, for chennai, hamali dd new flow, if no rate then consider hamali dd from edit company, @Senthil Sir req
//CHG_V-CM:08 - @Asrar chg, for chennai, hamali dd new flow, if rate set and Net Rate DD Hamali Less is enabled then consider hamali dd from edit company and the same applicable in Net Rate DD Hamali Less field, @Senthil Sir req
//CHG_V-CM:09 - @Asrar chg, for chennai, implemented demurage flow, based the days difference between curdate and stockindate(from invoicedetails table), also setup the days and chgs details in edit company.
//CHG_V-CM:10 - @Asrar chg, for chennai, hamali gd enabled for chennai only for Godown Delivery.
//CHG_V-CM:11 - @Asrar chg, for chennai rate consignees, logic to block the hamali and other chgs field and enable only by admin based on rights.
//CHG_V-CM:12 - @Asrar chg, for bangalore, if Destination Hamali is set in admin rate master then hamali gd must calculate based on the consignee Destination Hamali rate only, area wise not applicable @Sadakath
//CHG_V-CM:13 - @Asrar chg, for madurai, Unloading hamali logic like Chennai @Musaddik Bh 29/03/2023
//CHG_V-CM:14 - @Asrar chg, for cc copy lrs option to enable the memo generation @Musaddik Bh @06/04/2023.
//CHG_V-CM:15 - @Asrar chg, for bangalore, if consignee rate is available in admin rate master  then hamali gd must calculate based on the consignee Destination Hamali rate only even if its zero then the hamali gd remains zero must not change if area change, area wise not applicable @Sadakath @26/04/2023.
//CHG_V-CM:16 - @Asrar chg, for demurage charge lr, option to enable the memo generation by admin @Senthil Sir @10/05/2023.
//CHG_V-CM:17 - @Asrar chg, only the selected payment mode from the consignee master will show here @03/07/2023
//CHG_V-CM:18 - @Asrar chg, consider consigneeadminrate for Trivandrum also @06/07/2023
//CHG_V-CM:20 - @Asrar chg, logic to makeup the -ve value in others/dd fields @Senthil Sir @07/08/2023
//CHG_V-CM:21 - @Asrar chg, loading hamali flow, replace hamal gd flow by new loading hamali flow if loading hamali is enable for spcific destiantion @Sadakath Dated:08/08/2023 (Currently for TDM)
//CHG_V-CM:22 - @Asrar chg, For TDM, service chg will calculate based on the area charge based logic, if less party will consider from admin rate master else from Agentsubstation areas @Sadakath Dated:08/08/2023 (Currently for TDM)
//CHG_V-CM:23 - @Asrar chg, added vehicle select option to capture vehicle of each memo and while creating local trip, after selecting the vehicle all LR details should come. @Sadakth 31/08/2023
//CHG_V-CM:24 - @Asrar chg, hamali src custom chg option if hamali src is yes & custom option enabled @Dated:03/09/2023
//CHG_V-CM:25 - @Asrar chg, Vehicle no for GD also to generate eway bill for single lr if eway bill number available.
//Chg_V-CM:26 @Imran Chg, Added Debit mode and upi in payment mode added on 10052024
//Chg_V-CM:27 @Imran Chg, Blocked Unloading Hamalichg and given edit option to admin on 10/07/2024
//Chg_V-CM:28 @Imran Chg, Blocking discount calculation if the size exceed on 01/08/2024
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormControl } from "@angular/forms";
import { Observable, Subject, merge } from "rxjs";
import {
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import swal from "sweetalert";
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { RequestDto } from "src/app/dto/Request-dto";
import { EwaybillService } from "src/app/dataService/ewaybill-service";
import * as FileSaver from "file-saver";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-generate-cashmemo",
  templateUrl: "./generate-cashmemo.component.html",
  styleUrls: ["./generate-cashmemo.component.css"],
})
export class GenerateCashmemoComponent implements OnInit {
  PrintSerials = [
    {
      SerialId: "BNG/2021/10002101",
      Name: "A",
    },
    //    ,
    //    {
    //        SerialId: 585885,
    //        Name: 'A'
    //    }
  ];

  //    deliveryTypes = false;
  viewNewAreaCollectionDtlsFieldSet = false;
  viewNewAreaBillingDtlsFieldSet = false;
  viewNewCollectionMans = false;
  viewNewBankName = false;

  //for datepicker starts
  model: NgbDateStruct;
  model2;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  closeResult: string;
  //for datepicker ends
  userDataDtoReturnSession: any;
  isLoggedIn = true;

  userDtoCollectionMan: UserDataDto = new UserDataDto();
  userDtoCollectionManAll: UserDataDto = new UserDataDto();
  userDtoPartyBankDtls: UserDataDto = new UserDataDto();
  lrDtoBranchArea: LRDto = new LRDto();
  lrDtoCommodityAdmin: LRDto = new LRDto();
  lrDtoAreaAll: LRDto = new LRDto();
  lrDtoForChkLrNoDtls: LRDto = new LRDto();
  lrDtoForGetLrNoDtls: LRDto = new LRDto();
  userDtoPartyBankAll: UserDataDto = new UserDataDto();
  branchAreaDataList: LRDto[];
  stationWiseFieldPosition = "cashmeme hide area in billing details";
  isAreaBasedLogic = false;
  areaBillingDetailsWiseFieldSet = false;
  areaCollectionDetailsWiseFieldSet = false;
  viewCommodityPopup = false;
  //for hide and show starts
  viewByCash = false;
  viewByChequeNumber = false;
  viewByChequeDate = false;
  viewByConsigneeBankName = false;
  viewByReferenceNumber = false;
  //for hide and show ends
  isMemoAlreadyGenerated = false;
  codEnableRights = false;
  roundOffView = false;
  viewRoundOffAmt = false;
  godown: any;
  cashMemoDateObject: any;
  memoTime: any;
  memoTimeByUser: any;
  generatedBy: string = null;
  consigneeId: number = 0;
  consignorId: number = 0;
  viewSrmDiscount = false;
  viewSrmSrcHamali = false;
  viewSrmRate = true;
  viewSrmRatePercentage = false;
  viewMemoDate = false;
  viewCmAtMemoGeneration = false;
  collectionMenAtMemoGeneration: any;
  viewTripsheetHamali = false;
  viewMemoAlreadyGeneratedMsg = false;
  viewGeneratedBy = false;
  viewDeliveryTypeTxt = false;
  viewDeliveryType = true;
  enteredPaid: any;
  enteredGrandTotal: any;
  amountLimit: any;
  gstAmt: number;
  gstRoundedAmt: number;
  enteredCgstAmt: any;
  enteredSgstAmt: any;
  enteredIgstAmt: any;
  gstTotalAmt: number;
  cashMemoDiscountEditBlock = false;
  isTransitMemoEnableBtn = false;
  enteredTotalDiscount: any;
  enteredHamaliDd: any;
  enteredHamaliGd: any;
  viewUnloadingHamali = false;
  enteredUnloadingHamali: any;
  totalHamaliDd: number;
  totalHamaliGd: number;
  totalUnloadingHamali: number;
  roundOffValue = 0;
  diffInRoundValue = 0;
  checkRateMemoAmt = "cashmemo check rate memo amt";
  rateAmt = 0;
  rateMasterDto: RateMasterDto = new RateMasterDto();
  newArea: any;
  newCollectionMan: any;
  enteredArea: any;
  selectedCollectionMan: any;
  enteredBookingDate: any;
  enteredConsignee: any;
  enteredConsignor: any;
  enteredDemurage: any;
  enteredddHamaliLess: any;
  enteredGcNoteNo: any;
  inRupees: any;
  enteredLocalCharge: any;
  enteredLorryFreight: any;
  enteredMemoDate: any;
  enteredReceiptCharge: any;
  enteredServiceCharge: any;
  enteredInvoiceNumber: any;
  enteredInvoiceDate: any;
  enteredCommodityName: any;
  enteredSourceStation: any;
  enteredAgentName: any;
  commodityAdmin: any;
  enteredNoOfArticle: any;
  enteredChargedWeight: any;
  selectedPaymentMode: any;
  selectedPaymentType: any;
  selectedBankName: any;
  enteredNewBankName: any;
  enteredChequeNumber: any;
  enteredReferenceNumber: any;
  enteredChequeDate: any;
  selectedDeliveryMode: any;
  selectedDeliveryType: any;
  enteredDeliveryType: any;
  enteredCashMemoDate: any;
  enteredSrmSourceStation: any;
  enteredSrmRateId: any;
  enteredSrmRate: any;
  enteredSrmSourceHamali: any;
  enteredSrmDestHamali: any;
  enteredSrmServiceCharge: any;
  enteredSrmReceiptCharge: any;
  enteredSrmGcCharge: any;
  enteredSrmDiscount: any;
  enteredUnloadingCharge: any;
  enteredRoundOffAmt: any;
  checkBoxLetterHead: any;
  enteredIgst: any;
  enteredCgst: any;
  enteredSgst: any;
  enteredGstTotal: any;
  enteredTripsheetHamali: any;
  enteredEwayBillNo: any;
  enteredGoodsValue: any;
  validateMemoDate = "cashmemo validate memo date";
  dataForPrint: any;
  userDtoBankName: UserDataDto = new UserDataDto();
  newPartyBankDetial: any;
  viewCashMemoPrint = false;
  subAmountCalc: any;
  hamaliAmt: any;
  discnt: any;
  calcDisOne: any;
  calcDisTwo: any;
  calcDisThree: any;
  calcDisFour: any;
  calcGrandTotal = "cashmemo calc gT with hamaliDd";
  unit: any;
  deliveryTypeTxt: any;
  todayDate: any;
  currentTime: any;
  riskChg = 0;
  fovLessAmt = 0;
  cbWLH = false;
  viewCashOption = true;
  hideOSMode = false;
  hideCashMode = false;
  rateDtoForGoodsType: RateMasterDto = new RateMasterDto();
  //    dataListOfCommodity: RateMasterDto[];
  dataListOfCommodity: any;
  compServChg = 0;
  compRecpChg = 0;
  hamaliddPerArt = 0;
  userDtoCmpyDtls: UserDataDto = new UserDataDto();
  userDataDtoGet: any;
  inLaserPrint = false;
  lrDtoMsg: LRDto = new LRDto();
  viewHamaliDd = true;
  viewHamaliGd = true;
  viewOthers = false;
  setUnloadingChg = 0;
  setUnit = null;
  setUnloadingChgParty: any;
  setUnldgCommodities: any;
  setUnldgSource: any;
  crossingChg: any;
  unloadHamali: any;
  totalCrossingChg: any;
  subStationGDChg: any;
  hamaliGDAmt: any;
  unldgCommoditiesSplit: string[];
  unldgSourceSplit: string[];
  hamaliValues = "cashmemo set hamali values";
  readonlyHamaliDd = false;
  readonlyTripsheetHamali = false;
  readonlyRcptChgBillSide: boolean;
  readonlySrvChgBillSide: boolean;
  chkHamaliRate = "cashmemo getHamaliRate";
  lrDtoCrossingChg: LRDto = new LRDto();
  lrDtoCrossingChgArea: LRDto = new LRDto();
  dataListOfCrossingChg: any;
  dataListOfCrossingChgArea: any;
  setValuesInFields = "cashmemo enable set values in field";
  lrDtoCrossingChgSpecfic: LRDto = new LRDto();
  dataListOfCrossingChgSpecfic: any;
  isFixedRate = false;
  isLCChg = false;
  isBCChg = false;
  isAoc = false;
  isInsChg = false;
  isDD = false;
  isOthers = false;
  isHamaliChg = false;
  hamaliSrcCustom = false;
  customHamaliSrcChg: any;

  readonlySrmSourceStation = false;
  readonlySrmRate = false;
  readonlySrmGcCharges = false;
  readonlySrmSourceHamali = false;
  readonlySrmDestinationHamali = false;
  readonlySrmReciptCharges = false;
  readonlySrmServiceCharges = false;
  readonlySrmRatePercentage = false;
  readonlySrmDiscount = false;
  readonlyServiceCharges = false;
  readonlyReceiptCharges = false;
  readonlyLocalCharge = false;
  readonlyUnloadingHamali = false;
  readonlyDemurrage = false;
  readonlyCbLetterHead = false;
  readonlyddHamaliLess = false;
  readonlySrmPerUnit = false;
  readonlyNetRateHamaliLess = true;
  readonlySrmOtherChg = true;
  isFOV = true;
  calcDiscnt: any;
  otherDiscChg: any;
  enteredActualWeight: any;
  enteredBaseFreight: any;
  //from here
  rateUnit = null;
  ratePerUnit = null;
  printShow = false;
  message = null;
  messageList: any;
  status = null;
  blockConsignee = false;
  allowDuplicateMemo = false;
  laserMemoAdmin = false;
  allowDupMemoMessage = false;
  isMemoTransit = false;
  viewTransitMemoPrtBtn = false;
  viewPrintBtn = false;
  viewDeleteBtn = false;
  userDtoNewCollMan: UserDataDto = new UserDataDto();
  isAdminRate = false;
  setAdminRate = "cashmemo enable admin rate";
  unitType: any;
  isSerTaxExemp = false;
  serviceTax: any;
  description: any;
  readonlyHamaliGd = false;
  dataListReturnOfConsigneeRateDtls: any;
  lrDtoLRDetailsAfterBkgMemoChged: LRDto = new LRDto();
  commodityComboDataList: any;
  //commodityComboDataList: any;
  //commodityComboDataList:LRDto = new LRDto();
  //    lrDtoCommodityCombo: LRDto = new LRDto();
  viewCommodityCombo = false;
  viewCommodity = true;
  selectedCommodityCombo: any;
  returnLrDetailsAfterBkhMemoChanged: any;
  isRecalculate = false;
  goodsTypeResponse: any;
  memoPrintAgain = false;
  convertedBookingDate: any;
  convertedInvoiceDate: any;
  lrDtoYesLrFields: LRDto = new LRDto();
  enableMemoPrintAgain = "cashmemo enable memo print again";
  collectionManDataList: any;
  commodityCombo: any;
  destinationObject: any = "";
  readonlyTotalDiscount: boolean;
  readonlyDiscount: boolean;
  readonlyFovLess: boolean;
  srmDiscount: any;
  totalDiscount: any;
  hamaliDd: any;
  unloadingHamali: any;
  srmRate: any;
  srmRatePercentage: any;
  srmGcCharge: any;
  srmSourceHamali: any;
  srmDestinationHamali: any;
  srmReciptCharges: any;
  srmServiceCharges: any;
  viewBtnRecalculate = false;
  consigneeBankName: any;
  mandatoryAreaBillingDtlsFieldSet = false;
  mandatoryAreaCollectionDtlsFieldSet = false;
  mandatoryCollectionMan = true;
  memoNumberObject: any;
  viewEditBtn = false;
  viewUpdateBtn = false;
  asPerRightShowEditBtn = false;
  asPerRightShowUpdateBtn = false;
  asPerRightShowDeleteBtn = false;
  asPerRightShowEditDelvBtn = false;
  viewEditDelvBtn = false;
  showMemoDate = false;
  readonlyCashmemoDate = true;
  viewSaveBtn = true;
  consigneeBankNameArray: UserDataDto[];
  consigneeBankNameDataList: any;
  //    keyboardEventsManager: ListKeyManager;
  //for the select option with filter starts
  controlCollectionMan = new FormControl();
  controlAreaBillingDtlsFieldSet = new FormControl();
  controlAreaCollectionDtlsFieldSet = new FormControl();
  controlConsigneeBankName = new FormControl();
  controlCommodityCombo = new FormControl();
  controlGoodsType = new FormControl();

  //    collectionManDataList = [
  //        { id: 1, label: 'All' },
  //        { id: 2, label: 'Aamer' },
  //        { id: 3, label: 'Afzal' },
  //        { id: 3, label: 'Arif' }
  //    ];
  //for the select option with filter ends

  enteredLrNumber: any;
  responseOfcheckLrNumber: any;
  checkLrNumber: LRDto = new LRDto();
  responseOfGetLrNumberDtls: any;
  returnLrNumberDtls: LRDto = new LRDto();
  deliveryAreaCreationLrDto: LRDto = new LRDto();
  deliveryAreaReturnDtls: any;
  rateDtoReturnCreateCashmemoDtls: RateMasterDto = new RateMasterDto();
  rateDtoForUpdateLaserPrint: RateMasterDto = new RateMasterDto();
  rateDtoForRcCall: RateMasterDto = new RateMasterDto();
  rateDtoRecalMemo: RateMasterDto = new RateMasterDto();
  returnOfConsigneeRateDtls: any;
  rateDtoForUserRateDetails: RateMasterDto = new RateMasterDto();
  rateDtoTransitMemoEnable: RateMasterDto = new RateMasterDto();
  rateDtoPrintTransitmemo: RateMasterDto = new RateMasterDto();
  viewTransitMemoEnableBtn = false;
  rateDtoDuplicateMemoEnable: RateMasterDto = new RateMasterDto();
  viewEnableDuplicateMemoBtn = false;
  viewEnableBankLr = false;
  viewEnableSelfLr = false;
  viewEnableBlockLr = false;
  isDupMemoEnableBtn = false;
  lrDtoBankLr: LRDto = new LRDto();
  lrDtoSelfLr: LRDto = new LRDto();
  lrDtoBlockLr: LRDto = new LRDto();
  viewCodLrEnable = false;
  rateDtoPrint: RateMasterDto = new RateMasterDto();
  rateDtoUpdateCashMemo: RateMasterDto = new RateMasterDto();
  lrDtoDeleteCashMemo: LRDto = new LRDto();
  lrDtoNoLrFields: LRDto = new LRDto();
  selectedCommodityList: any;
  reasonEditValue: any;
  reasonDeleteValue: any;

  barcodeValue: string = "BNG/2021/4444454";
  lrDtosOtherChg: any;
  rateMasterDtoOtherChg: RateMasterDto = new RateMasterDto();
  enteredOtherChg: any;
  convertedMemoDate: any;
  newconvertedMemoDate: any;
  discNegBlock = false;
  readonlyOtherChg = false;
  viewHamaliEditEnable = false;
  isHamaliOtherEditBlock = false;
  viewCCCopyEditEnable = false;
  viewDemurageLREnable = false;
  isDemurageLr = false;
  isQrPrintMemo = false;

  @ViewChild("elem") elem: ElementRef;

  //    public openTypeahead(): void {
  //        // Dispatch event on input element that NgbTypeahead is bound to
  //        this.elem.nativeElement.dispatchEvent(new Event('input'));
  //        // Ensure input has focus so the user can start typing
  //        this.elem.nativeElement.focus();
  //      }

  //    openTypeahead(inp) {
  //        inp._elementRef.nativeElement.value = '';
  //        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
  //        inp._elementRef.nativeElement.focus();
  //    }

  commodityComboTA: Array<any> = [];
  @ViewChild("instanceCommodityCombo") instanceCommodityCombo: NgbTypeahead;
  focusCommodityComboTA$ = new Subject<string>();
  //clickCommodityComboTA$ = new Subject<string>();

  commodityComboSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    //const clicksWithClosedPopup$ = this.clickCommodityComboTA$.pipe( filter(() => !this.instanceCommodityCombo.isPopupOpen() ) );
    const inputFocus$ = this.focusCommodityComboTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.commodityComboTA
          : this.commodityComboTA.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };

  collectionManTA: Array<any> = [];
  @ViewChild("instanceCM") instanceCM: NgbTypeahead;
  focusCMTA$ = new Subject<string>();
  clickCMTA$ = new Subject<string>();

  cmSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickCMTA$.pipe(
      filter(() => !this.instanceCommodityCombo.isPopupOpen())
    );
    const inputFocus$ = this.focusCMTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.collectionManTA
          : this.collectionManTA.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };

  areaBillingTA: Array<any> = [];
  @ViewChild("instanceAreaBilling") instanceAreaBilling: NgbTypeahead;
  focusAreaBillingTA$ = new Subject<string>();
  clickAreaBillingTA$ = new Subject<string>();

  areaBillingSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickAreaBillingTA$.pipe(
      filter(() => !this.instanceAreaBilling.isPopupOpen())
    );
    const inputFocus$ = this.focusAreaBillingTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.areaBillingTA
          : this.areaBillingTA.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };

  vehicleNumberOption: Array<TruckDataDto> = [];
  truckDataDtoVehicleNumber: TruckDataDto = new TruckDataDto();
  truckDataDtoAddNewVehicleNumber: TruckDataDto = new TruckDataDto();
  public modelDropDownVehicleNumber: any;
  vehicleNumberTA: Array<any> = [];
  focusDropDownVehicleNumberTA$ = new Subject<string>();
  searchDropDownInputFieldVehicleNumber = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDropDownVehicleNumberTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.vehicleNumberTA
          : this.vehicleNumberTA.filter(
            (v) =>
              v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDropDownVehicleNumber = (x: { truckNumber: string }) =>
    x.truckNumber;

  //CashMemoReportPopup functionality start here
  /*cashmemoRptData: any = null;
    rateMasterDtoFromCashMemoRptLocalStorage: RateMasterDto = new RateMasterDto();
    lrDtoCashMemoRpt: LRDto = new LRDto();
    lrDtoReturnCashMemoRpt: any = null;*/
  //CashMemoReportPopup functionality End here

  pageId = "gencc";
  viewDirectAssgOption = false;
  readonlyDirectAssg = false;
  cbDirectAssg = false;
  checkBoxDirectAssg: any;
  ddHamaliLessAmt: any;
  hamaliDDRateLessStatus: boolean;
  hamaliDDRateLessStatusShow: boolean;
  demurageExmp: boolean;
  loadingHamaliOptionFlow: boolean = false;
  showVehicleNumberOption: boolean = false;
  selectedVehicleNumber = null;
  showVehicleNumberOptionGD: boolean = true;
  requestDto: RequestDto = new RequestDto();
  ewayBillNo = null;
  viewGenerateCEWbBtn: boolean = false;
  viewGenerateDCBtn: boolean = false;
  printGenerateCEWbBtn: boolean = false;
  printGenerateDCBtn: boolean = false;
  showSpinner = false;
  memoConsolidatedEWB = null;
  newPartyViewPrint: boolean = false;
  ewayBillValidityDate: Date = new Date();
  ewayBillValidityDateTemp: any;
  ewayBillDCNo = null;
  isSizeExceeded: boolean = false;
  destMemoSizeExceedRights: boolean = false;
  viewIsSizeExeededLrText: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private memoService: MemoService,
    private masterReadService: MasterReadService,
    private datePipe: DatePipe,
    public changeDetectorRef: ChangeDetectorRef,
    private ewaybillService: EwaybillService
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.getCollectionManDetailsList();
      this.getBranchAreaDetailsList();
      this.getPartyBankDetailsService();
      this.getVehicleDetailsList();
      //            if ( this.stationWiseFieldPosition == "cashmeme show area in billing details" ) {
      //                this.isAreaBasedLogic = true;
      //            }
      if (
        this.userDataDtoReturnSession.mainStation == "Bangalore" ||
        this.userDataDtoReturnSession.mainStation == "Trivandrum"
      ) {
        this.isAreaBasedLogic = true;
      }
      if (this.isAreaBasedLogic == true) {
        this.areaBillingDetailsWiseFieldSet = true;
        this.areaCollectionDetailsWiseFieldSet = false;
      } else {
        this.areaBillingDetailsWiseFieldSet = false;
        this.areaCollectionDetailsWiseFieldSet = true;
      }
      //            this.getMemoCode();
      //            this.getCollectionManDetailsList();
      //                        console.log( this.userDataDtoReturnSession );
      //            if ( this.userDataDtoReturnSession.collectionFlow == false ) {
      //                this.btnTransferAllView = true;
      //            }

      if (
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup != null &&
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup
      ) {
        this.loadingHamaliOptionFlow = true;
      }

      this.rightMethodForUsers();
      this.amountLimit = this.userDataDtoReturnSession.serviceTaxAmt;

      if (this.cashMemoDiscountEditBlock == true) {
        this.readonlyTotalDiscount = true;
        this.readonlyDiscount = true;
        this.readonlyFovLess = true;
      } else {
        this.readonlyTotalDiscount = false;
        this.readonlyDiscount = false;
        this.readonlyFovLess = false;
      }
      this.todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      this.currentTime = this.datePipe.transform(new Date(), "h:mm a");
      this.allowDuplicateMemo =
        this.userDataDtoReturnSession.allowDuplicateMemo;
      this.allowDupMemoMessage =
        this.userDataDtoReturnSession.allowDupMemoMessage;

      // block payment mode @Dated : 15/02/2018
      this.collectionModeBlock();
      this.getGoodsType();
      //get company charges
      this.getEditCompValues();
      this.inLaserPrint = this.userDataDtoReturnSession.inLaserCashMemo;
      this.getMessageInPrint();
      if (this.roundOffView == true) {
        this.viewRoundOffAmt = true;
      }
      //$( "#saveBtn" ).prop( "disabled", true );

      if (
        this.userDataDtoReturnSession.mainStation != null &&
        this.userDataDtoReturnSession.mainStation == "Chennai"
      ) {
        this.viewOthers = true;
        this.discNegBlock = true;
      } else {
        this.viewOthers = false;
        this.discNegBlock = false;
      }
      //CashMemoReport Pop up functionality start
      /* this.cashmemoRptData = localStorage.getItem('cashmemoReportPopUp');
             this.rateMasterDtoFromCashMemoRptLocalStorage = JSON.parse(localStorage.getItem('rateMasterDtoForCashmemoReportPopUp'));
             if (this.cashmemoRptData != null && this.cashmemoRptData != undefined && this.cashmemoRptData != "") {
                 this.get();
             }*/
      //CashMemoReport Pop up functionality end
      //CHG_V-CM:06
      if (this.userDataDtoReturnSession.specCollectionFlow) {
        this.viewDirectAssgOption = true;
      }
      //CHG_V-CM:07
      if (this.userDataDtoReturnSession.showddHamaliLessDestMemo) {
        this.hamaliDDRateLessStatusShow = true;
      }
      if (this.userDataDtoReturnSession.destPaymentQREnabled) {
        this.isQrPrintMemo = true;
      }


      //CHG_V-CM:17
      // if (this.userDataDtoReturnSession.destMemoPaymentModeBlock) {
      //     this.hideOSMode = true;
      //     this.hideCashMode = true;
      // }
      //Chg_V-CM:28
      if (this.userDataDtoReturnSession.destMemoSizeExceeded) {
        this.destMemoSizeExceedRights = true;
      }
    }
  }

  ngOnInit() { }
  //Basha
  //    areaMethod( areaSelect ) {
  //      alert('asdf');
  //      if ( areaSelect.godownName == 'ADD NEW' ) {
  //          if ( this.isAreaBasedLogic == true ) {
  //              this.viewNewAreaBillingDtlsFieldSet = true;
  //
  //          } else {
  //              this.viewNewAreaCollectionDtlsFieldSet = true;
  //          }
  //          if ( this.isAreaBasedLogic == true ) {
  //              if ( this.isMemoAlreadyGenerated == true ) {
  //                  if ( this.returnLrNumberDtls.areaOfDelivery == areaSelect.godownName ) {
  //                      this.clearRateFields();
  //                      if ( this.isAdminRate == true ) {
  //                          this.getRecalculateMemo( "list" );
  //                      }
  //
  //                      else {
  //                          this.getRcCall( "list" );
  //                      }
  //
  //
  //                  } else {
  //                      this.setCrossingChgOnAreaChange();
  //                  }
  //              }
  //          }
  //
  //
  //
  //      } else {
  //          this.viewNewAreaCollectionDtlsFieldSet = false;
  //          this.viewNewAreaBillingDtlsFieldSet = false;
  //
  //      }
  //
  //  }
  areaMethod(areaSelect, e) {
    if (e.keyCode == 13) {
      if (areaSelect == "ADD NEW") {
        if (this.isAreaBasedLogic == true) {
          this.viewNewAreaBillingDtlsFieldSet = true;
        } else {
          this.viewNewAreaCollectionDtlsFieldSet = true;
        }
      } else {
        this.viewNewAreaCollectionDtlsFieldSet = false;
        this.viewNewAreaBillingDtlsFieldSet = false;

        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
          this.getOtherChgDetailsList(areaSelect);
        }
        if (this.isAreaBasedLogic == true) {
          if (this.isMemoAlreadyGenerated == true) {
            if (this.returnLrNumberDtls.areaOfDelivery == areaSelect) {
              this.clearRateFields();
              if (this.isAdminRate == true) {
                this.getRecalculateMemo("list");
              } else {
                this.getRcCall("list");
              }
            } else {
              this.methodForCrossingChgOnAreaChange(areaSelect);
            }
          }
        }
      }
    }
  }

  collectionManMethod(dataForCollectionMan, e) {
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    if (e.keyCode == 13) {
      if (dataForCollectionMan == "KARTIK") {
        this.viewCommodityCombo = true;
        this.viewCommodity = false;
        this.viewBtnRecalculate = true;
        this.isRecalculate = true;
        this.getCommodity();
        this.viewNewCollectionMans = false;
      } else if (dataForCollectionMan == "ADD NEW") {
        this.viewNewCollectionMans = true;
        $("#" + this.pageId + "newCollectionMan").focus();
        this.viewCommodityCombo = false;
        this.viewCommodity = true;
        this.viewBtnRecalculate = false;
        this.isRecalculate = false;
      } else {
        this.viewNewCollectionMans = false;
        this.viewCommodityCombo = false;
        this.viewCommodity = true;
        this.viewBtnRecalculate = false;
        this.isRecalculate = false;

        /*      if ((this.isRecalculate == true && this.selectedDeliveryType != null)) {
                                                this.clearRateFields();
                                                this.getRcCall("list");
                                            }*/
      }
    }
  }

  localChgFocus(e) {
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();
    if (e.keyCode == 13) {
      if (this.enteredLocalCharge > this.enteredTotalDiscount) {
        swal({
          title: "Not Applicable",
          text: "Discount Amount is less than Local Charge",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          swal.close();
          window.setTimeout(function () {
            $("#" + this.pageId + "localCharge").val("");
            $("#" + this.pageId + "localCharge").focus();
          }, 100);
        });
      } else {
        this.calculateDiscount();
        this.logicForGST();
        //                $( "#hamaliDd" ).focus();
      }
    }
  }

  hamaliDdFocus(e) {
    if (e.keyCode == 13) {
      this.hamaliDdCalcMethod();
    }
  }

  hamaliDdCalcMethod() {
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();

    //        if ( this.calcGrandTotal = "cashmemo calc gT with hamaliDd" ) {
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      //            this.totalHamaliDd = +this.enteredHamaliDd * +this.enteredNoOfArticle;
      //            //            $( "#hamaliDd" ).val( this.totalHamaliDd.toFixed( 1 ) );
      //            //            this.hamaliDd = this.totalHamaliDd.toFixed( 1 );
      //            //As per Asrar Bhai change to math.round
      //            $( "#hamaliDd" ).val( Math.round( this.totalHamaliDd ) );
      //            this.hamaliDd = Math.round( this.totalHamaliDd );
      //            if ( this.srmRate == "0.0" || this.srmRate == "0" ) {
      //                this.calculateDisc();
      //            } else if ( this.unit != null || this.unit != "" ) {
      //                if ( this.unit == "Rate" ) {
      //                    this.calculateDisc();
      //                } else if ( this.unit == "Discount" ) {
      //                    if ( this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt" ) {
      //                        this.calculateDisc();
      //                    } else if ( this.srmDiscount == "onTotal" ) {
      //                        this.calculateDiscOnTotal();
      //                    }
      //                }
      //            }
      //            this.logicForGST();
      $("#" + this.pageId + "rcptChgBillSide").focus();
    } else {
      //            this.totalHamaliDd = +this.enteredHamaliDd * +this.enteredNoOfArticle;
      //            //            console.log( this.totalHamaliDd );
      //            //            $( "#hamaliDd" ).val( this.totalHamaliDd.toFixed( 1 ) );
      //            //            this.hamaliDd = this.totalHamaliDd.toFixed( 1 );
      //            //As per Asrar Bhai change to math.round
      //            this.hamaliDd = Math.round( this.totalHamaliDd );
      //            $( "#hamaliDd" ).val( Math.round( this.totalHamaliDd ) );
      //            this.logicForGST();
      $("#" + this.pageId + "hamaliGd").focus();
    }
  }

  hamaliGdFocus(e) {
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();

    if (e.keyCode == 13) {
      //            this.enteredHamaliGd = this.enteredHamaliGd * this.enteredNoOfArticle;
      //            console.log( this.enteredHamaliGd + " << >> " + this.enteredNoOfArticle );
      //Basha
      //            this.totalHamaliGd = +( this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd.toFixed(1) ) * +this.enteredNoOfArticle;
      //            $( "#hamaliGd" ).val( this.totalHamaliGd );
      //            this.totalHamaliGd = +( this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd ) * +this.enteredNoOfArticle;
      //            $( "#hamaliGd" ).val( Math.round( this.totalHamaliGd ) );
      //            if ( this.srmRate == "0.0" || this.srmRate == "0" ) {
      //                this.calculateDisc();
      //            } else if ( this.unit != null || this.unit != "" ) {
      //                if ( this.unit == "Rate" ) {
      //                    this.calculateDisc();
      //                } else if ( this.unit == "Discount" ) {
      //                    if ( this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt" ) {
      //                        this.calculateDisc();
      //                    } else if ( this.srmDiscount == "onTotal" ) {
      //                        this.calculateDiscOnTotal();
      //                    }
      //                }
      //            }
      //            this.logicForGST();
      $("#" + this.pageId + "srvChgBillSide").focus();
    }
  }

  unloadingHamaliFocus(e) {
    this.enteredUnloadingHamali = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    if (e.keyCode == 13) {
      //            this.totalUnloadingHamali = +this.unloadingHamali * +this.enteredNoOfArticle;
      //            //            $( "#unloadingHamali" ).val( this.totalUnloadingHamali );
      //            this.unloadingHamali = Math.round( this.totalUnloadingHamali );
      //            if ( this.srmRate == "0.0" || this.srmRate == "0" ) {
      //                this.calculateDisc();
      //            } else if ( this.unit != null || this.unit != "" ) {
      //                if ( this.unit == "Rate" ) {
      //                    this.calculateDisc();
      //                } else if ( this.unit == "Discount" ) {
      //                    if ( this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt" ) {
      //                        this.calculateDisc();
      //                    } else if ( this.srmDiscount == "onTotal" ) {
      //                        this.calculateDiscOnTotal();
      //                    }
      //                }
      //            }
      //            this.logicForGST();
      if (this.selectedDeliveryType == "Godown") {
        $("#" + this.pageId + "rcptChgBillSide").focus();
      } else if (this.selectedDeliveryType == "Door Delivery") {
        $("#" + this.pageId + "hamaliDd").focus();
      }
    }
  }

  srvChgBillSideFocus(e) {
    this.calculateDisc();
    this.logicForGST();
    if (e.keyCode == 13) {
      $("#" + this.pageId + "demurrage").focus();
    }
  }

  rcptChgBillSideFocus(e) {
    this.calculateDisc();
    this.logicForGST();
    if (e.keyCode == 13) {
      $("#" + this.pageId + "srvChgBillSide").focus();
    }
  }

  othersFocus(e) {
    if (e.keyCode == 13) {
      //            this.enteredOtherChg = $( "#others" ).val();
      //            this.enteredNoOfArticle = $( "#noOfArticle" ).val();
      //            this.enteredOtherChg = +this.enteredOtherChg * +this.enteredNoOfArticle;
      //            $( "#others" ).val( this.enteredOtherChg );
      //            //            $( "#totalDiscount" ).focus();
      //            //            $( "#paymentMode" ).focus();
      //            this.calculateDisc();
      //            this.logicForGST();
    }
  }

  demurrageFocus(e) {
    this.subTotalWithDemurage();
    this.logicForGST();
    if (e.keyCode == 13) {
      //            $( "#totalDiscount" ).focus();
      //            $( "#paymentMode" ).focus();
    }
  }

  totalDiscountFocus(e) {
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    if (e.keyCode == 13) {
      if (this.srmRate == "0.0" || this.srmRate == "0") {
        this.calculateDisc();
      } else if (this.unit != null || this.unit != "") {
        if (this.unit == "Rate") {
          this.calculateDisc();
        } else if (this.unit == "Discount") {
          if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
            this.calculateDisc();
          } else if (this.srmDiscount == "onTotal") {
            this.calculateDiscOnTotal();
          }
        }
      }
      this.logicForGST();
    }
  }

  btnSave() {
    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
    this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
    this.newCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
    if (this.isAreaBasedLogic == true) {
      this.enteredArea = $("#" + this.pageId + "areaBillingDtlsFieldSet").val();
    } else {
      this.enteredArea = $(
        "#" + this.pageId + "areaCollectionDtlsFieldSet"
      ).val();
    }
    //CHG-V-CM:02
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();
    if (this.discNegBlock == true && this.enteredTotalDiscount < 0) {
      swal({
        title: "Not Allowed",
        text: "Discount should not be in -Ve value, please check!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
      //CHG_V-CM:17
    } else if (this.selectedPaymentMode == "Select") {
      swal({
        title: "Mandatory Fields",
        text: "Please select the Payment Mode!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      if (this.selectedDeliveryMode == "GD") {
        this.selectedVehicleNumber = $(
          "#" + this.pageId + "gdVehicleNumber"
        ).val();

        if (
          this.selectedVehicleNumber != null ||
          this.selectedVehicleNumber != ""
        ) {
          this.selectedVehicleNumber = this.selectedVehicleNumber.replace(
            /\s/g,
            ""
          );
          this.selectedVehicleNumber = this.selectedVehicleNumber.replace(
            "-",
            ""
          );
          this.selectedVehicleNumber = this.selectedVehicleNumber.replace(
            ".",
            ""
          );
          this.selectedVehicleNumber = this.selectedVehicleNumber.replace(
            ",",
            ""
          );
          console.log(this.selectedVehicleNumber);
        }

        this.ewayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log("btnSave");
        console.log(this.selectedVehicleNumber + " - " + this.ewayBillNo);
        if (
          this.ewayBillNo != null &&
          this.ewayBillNo != "0" &&
          this.ewayBillNo != "NA" &&
          this.ewayBillNo != "" &&
          (this.selectedVehicleNumber == null ||
            this.selectedVehicleNumber == "" ||
            this.selectedVehicleNumber.length < 7 ||
            this.selectedVehicleNumber.length > 11)
        ) {
          swal({
            title: "Not Allowed",
            text: "Please enter the Valid Vehicle Number for Consolidated Ewaybill!",
            icon: "warning",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          if (this.selectedPaymentMode == "Cash") {
            this.saveMethod();
          } else if (this.selectedPaymentMode != "Cash") {
            if (
              this.selectedCollectionMan == null ||
              this.selectedCollectionMan == "" ||
              this.selectedCollectionMan == "N/A" ||
              this.selectedCollectionMan == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please select the Collection Man",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            } else if (this.selectedCollectionMan == "ADD NEW") {
              if (
                this.newCollectionMan == null ||
                this.newCollectionMan == "" ||
                this.newCollectionMan == "N/A" ||
                this.newCollectionMan == "NA"
              ) {
                swal({
                  title: "Mandatory Fields",
                  text: "Please Enter Collection Man",
                  icon: "warning",
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                });
              }
            } else {
              this.saveMethod();
            }
          }
        }
      }

      if (this.selectedDeliveryMode == "DD") {
        if (this.selectedPaymentMode == "Cash") {
          this.saveMethod();
        } else if (this.selectedPaymentMode != "Cash") {
          if (
            this.selectedCollectionMan == null ||
            this.selectedCollectionMan == "" ||
            this.selectedCollectionMan == "N/A" ||
            this.selectedCollectionMan == "NA" ||
            this.enteredArea == null ||
            this.enteredArea == "" ||
            this.enteredArea == "N/A" ||
            this.enteredArea == "NA"
          ) {
            swal({
              title: "Mandatory Fields",
              text: "Please select the Collection Man and Area",
              icon: "warning",
              closeOnClickOutside: true,
              closeOnEsc: true,
            });
          } else if (this.enteredArea == "ADD NEW") {
            if (this.isAreaBasedLogic == true) {
              this.newArea = $(
                "#" + this.pageId + "newAreaBillingDtlsFieldSet"
              ).val();
            } else {
              this.newArea = $(
                "#" + this.pageId + "newAreaCollectionDtlsFieldSet"
              ).val();
            }

            if (
              this.newArea == null ||
              this.newArea == "" ||
              this.newArea == "N/A" ||
              this.newArea == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please Enter Collection Man and Area",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            }
          } else if (this.selectedCollectionMan == "ADD NEW") {
            if (
              this.newCollectionMan == null ||
              this.newCollectionMan == "" ||
              this.newCollectionMan == "N/A" ||
              this.newCollectionMan == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please Enter Collection Man and Area",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            }
          } else {
            this.saveMethod();
          }
        }
      }
    }
  }

  getDetailsForCollectionMasterRead() {
    this.userDtoCollectionMan.branchId =
      this.userDataDtoReturnSession.mainStation;
    this.userDtoCollectionMan.companyId =
      this.userDataDtoReturnSession.companyId;
    this.userDtoCollectionMan.status = "D";
  }

  getCollectionManDetailsList() {
    this.collectionManDataList = [];
    this.getDetailsForCollectionMasterRead();
    this.masterReadService
      .getCollectionManMasterDetails(this.userDtoCollectionMan)
      .subscribe((response) => {
        if (response) {
          //                    console.log( response );
          this.collectionManDataList = response;
          this.userDtoCollectionManAll.collectioMan = "ADD NEW";
          this.collectionManDataList.push(this.userDtoCollectionManAll);

          this.collectionManTA = [];
          for (let i = 0; i < this.collectionManDataList.length; i++) {
            this.collectionManTA.push(
              this.collectionManDataList[i].collectioMan
            );
          }
        }
        //                else {
        //                    swal( {
        //                        title: "Warning",
        //                        text: "No records found for the selected city!",
        //                        icon: "warning",
        //                        closeOnClickOutside: false,
        //                        closeOnEsc: false,
        //                    } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting Collection Man Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getMethodForBranchArea() {
    this.lrDtoBranchArea.branch = this.userDataDtoReturnSession.office;
    this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
    this.lrDtoBranchArea.companyId = this.userDataDtoReturnSession.companyId;
  }

  getBranchAreaDetailsList() {
    this.getMethodForBranchArea();
    this.masterReadService
      .getBranchAreaDetailsService(this.lrDtoBranchArea)
      .subscribe((response) => {
        if (response) {
          //                    console.log( response );
          this.branchAreaDataList = response;
          //console.log(this.branchAreaDataList);
          this.lrDtoAreaAll.godownName = "ADD NEW";
          this.branchAreaDataList.push(this.lrDtoAreaAll);

          this.areaBillingTA = [];
          for (let i = 0; i < this.branchAreaDataList.length; i++) {
            this.areaBillingTA.push(this.branchAreaDataList[i].godownName);
          }
          //                    this.areaPassing = this.this.branchAreaDataList.godownName;
        }
        //                else {
        //                    swal( {
        //                        title: "Warning",
        //                        text: "No records found for the selected city!",
        //                        icon: "warning",
        //                        closeOnClickOutside: false,
        //                        closeOnEsc: false,
        //                    } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting Area Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getMethodForgetPartyBankDetailsService() {
    this.userDtoPartyBankDtls.office =
      this.userDataDtoReturnSession.mainStation;
    this.userDtoPartyBankDtls.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  getPartyBankDetailsService() {
    this.getMethodForgetPartyBankDetailsService();
    this.masterReadService
      .getPartyBankDetailsService(this.userDtoPartyBankDtls)
      .subscribe((response) => {
        //                if ( response.length > 0 ) {
        //                                        console.log( response );
        this.consigneeBankNameArray = response;
        //                    for ( let i = 0; i < this.consigneeBankName.length; i++ ) {
        //                        //                        console.log(this.consigneeBankName[i].bankName);
        //                        //                        this.consigneeBankNameDataList=this.consigneeBankName[i].bankName;
        //                        this.consigneeBankNameArray = this.consigneeBankName[i].bankName;
        //                    }
        this.userDtoPartyBankAll.bankName = "ADD NEW BANK";
        //                    console.log("ok");
        //                                    console.log(this.consigneeBankNameArray);
        this.consigneeBankNameArray.push(this.userDtoPartyBankAll);
        //                }
        //                else {
        //                    swal( {
        //                        title: "Warning",
        //                        text: "No records found for the selected city!",
        //                        icon: "warning",
        //                        closeOnClickOutside: false,
        //                        closeOnEsc: false,
        //                    } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting Party Bank Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  paymentModeMethod(data, paymentMethodPopup) {
    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();

    if (data == "Cash" && this.selectedDeliveryMode == "GD") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop("disabled", true);
        this.mandatoryAreaBillingDtlsFieldSet = false;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          true
        );
        this.mandatoryAreaCollectionDtlsFieldSet = false;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", true);
      this.mandatoryCollectionMan = false;
      this.modalService.open(paymentMethodPopup);
    } else if (data == "Cash" && this.selectedDeliveryMode == "DD") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = false;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = false;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", true);
      this.mandatoryCollectionMan = false;
      this.modalService.open(paymentMethodPopup);
    } else if (data == "Outstanding" && this.selectedDeliveryMode == "GD") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = true;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = true;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", false);
      this.mandatoryCollectionMan = true;
    } else if (data == "Outstanding" && this.selectedDeliveryMode == "DD") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = true;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = true;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", false);
      this.mandatoryCollectionMan = true;
    }
    this.collectionManBlock();
  }

  deliveryModeMethod(data) {
    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
    this.modelDropDownVehicleNumber = null;
    this.selectedVehicleNumber = null;

    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val("");
    if (data == "GD" && this.selectedPaymentMode == "Cash") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop("disabled", true);
        this.mandatoryAreaBillingDtlsFieldSet = false;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          true
        );
        this.mandatoryAreaCollectionDtlsFieldSet = false;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", true);
      this.mandatoryCollectionMan = false;
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").val("");
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").val("");
      }
      $("#" + this.pageId + "collectionMan").val("");
    } else if (data == "GD" && this.selectedPaymentMode == "Outstanding") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = true;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = true;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", false);
      this.mandatoryCollectionMan = true;
    } else if (data == "DD" && this.selectedPaymentMode == "Cash") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = true;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = true;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", true);
      this.mandatoryCollectionMan = false;
      $("#" + this.pageId + "collectionMan").val("");
    } else if (data == "DD" && this.selectedPaymentMode == "Outstanding") {
      if (this.isAreaBasedLogic == true) {
        $("#" + this.pageId + "areaBillingDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaBillingDtlsFieldSet = true;
      } else {
        $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
          "disabled",
          false
        );
        this.mandatoryAreaCollectionDtlsFieldSet = true;
      }
      $("#" + this.pageId + "collectionMan").prop("disabled", false);
      this.mandatoryCollectionMan = true;
    }
    this.collectionManBlock();

    if (data == "DD") {
      this.showVehicleNumberOption = true;
      this.showVehicleNumberOptionGD = false;
    } else {
      this.showVehicleNumberOption = false;
      this.showVehicleNumberOptionGD = true;
    }
  }

  //for popup modal starts
  openPaymentTypePopup(paymentMethodPopup) {
    this.modalService.open(paymentMethodPopup, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  open3(www) {
    this.modalService.open(www, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason == ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason == ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  //for popup modal ends

  //for hide and show starts

  paymentTypeMode() {

    let paymentType = this.selectedPaymentType;
    if (paymentType == "Cash") {
      this.viewByCash = true;
      this.viewByChequeNumber = false;
      this.viewByChequeDate = false;
      this.viewByConsigneeBankName = false;
      this.viewByReferenceNumber = false;
    } else if (paymentType == "Cheque/PDC") {
      this.viewByCash = true;
      this.viewByChequeNumber = true;
      this.viewByChequeDate = true;
      this.viewByConsigneeBankName = true;
      this.viewByReferenceNumber = false;
      $("#" + this.pageId + "chequeNumber").focus();
    } else if (paymentType == "NEFT/IMPS") {
      this.viewByCash = true;
      this.viewByChequeNumber = false;
      this.viewByChequeDate = false;
      this.viewByConsigneeBankName = true;
      this.viewByReferenceNumber = true;
      $("#" + this.pageId + "referenceNumber").focus();
    }
    //Chg_V-CM:26
    else if (paymentType == "Debit Account") {
      this.viewByCash = true;
      this.viewByChequeNumber = false;
      this.viewByChequeDate = false;
      this.viewByConsigneeBankName = false;
      this.viewByReferenceNumber = true;
      $("#" + this.pageId + "referenceNumber").focus();
    } else if (paymentType == "UPI") {
      this.viewByCash = true;
      this.viewByChequeNumber = false;
      this.viewByChequeDate = false;
      this.viewByConsigneeBankName = false;
      this.viewByReferenceNumber = true;
      $("#" + this.pageId + "referenceNumber").focus();
    }
    ////
    else {
      this.viewByCash = false;
      this.viewByChequeNumber = false;
      this.viewByChequeDate = false;
      this.viewByConsigneeBankName = false;
      this.viewByReferenceNumber = false;
    }
  }
  //for hide and show ends
  partyBankMethod(option) {
    if (option.bankName == "ADD NEW BANK") {
      this.viewNewBankName = true;
    } else {
      this.viewNewBankName = false;
    }
  }

  lrNumberOnEnter(e) {
    if (e.keyCode == 13) {
      this.lrNumberGetDtls();
    }
  }

  lrNumberGetDtls() {
    this.demurageExmp = false;
    this.readonlyOtherChg = false;
    //Chg_V-CM:27
    this.readonlyUnloadingHamali = false;
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please Enter LR Number!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      }).then(function () {
        swal.close();
        window.setTimeout(function () {
          $("#" + this.pageId + "lrNumber").focus();
        }, 100);
      });
    } else {
      this.isMemoAlreadyGenerated = false;
      this.viewBtnRecalculate = false;
      this.viewCommodity = true;

      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (
          let i = 0;
          i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
          i++
        ) {
          //if(this.enableMemoPrintAgain ="cashmemo enable memo print again"){}
          if (
            this.userDataDtoReturnSession.office == "Binnymills" ||
            this.userDataDtoReturnSession.office == "Konditope"
          ) {
            if (
              this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] !=
              null &&
              this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
              "Memo Print Again"
            ) {
              this.memoPrintAgain = true;
            }
          } else {
            this.memoPrintAgain = true;
          }
        }
      }

      this.viewEditDelvBtn = false;
      $("#" + this.pageId + "saveBtn").prop("disabled", false);
      this.viewGeneratedBy = false;
      this.viewEditBtn = false;
      this.viewDeleteBtn = false;
      this.viewUpdateBtn = false;
      this.checkLrNumberDetails();
    }
  }

  checkMethodForCheckLrNumberDtls() {
    this.lrDtoForChkLrNoDtls = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoForChkLrNoDtls.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoForChkLrNoDtls.lrNumber = this.enteredLrNumber;
  }
  checkLrNumberDetails() {
    this.checkMethodForCheckLrNumberDtls();
    this.memoService
      .chkLrInCpService(this.lrDtoForChkLrNoDtls)
      .subscribe((response) => {
        if (response) {
          //                    console.log( response );
          this.checkLrNumber = new LRDto();
          this.responseOfcheckLrNumber = response;
          this.checkLrNumber = this.responseOfcheckLrNumber;
          //                    console.log( this.checkLrNumber.isLrInCp );
          if (this.checkLrNumber.isLrInCp == false) {
            this.viewMemoAlreadyGeneratedMsg = false;
            this.getLrNumberDetails();
          } else {
            swal({
              title: "Stock In Check Post",
              text:
                "LR Number : " +
                this.enteredLrNumber +
                " is in CheckPost, You can't generate the CashMemo!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              swal.close();
              window.setTimeout(function () {
                $("#" + this.pageId + "lrNumber").val("");
                $("#" + this.pageId + "lrNumber").focus();
              }, 100);
            });
          }
        }
        //                else {
        //                                    swal( {
        //                                        title: "Warning",
        //                                        text: "No records found for the selected city!",
        //                                        icon: "warning",
        //                                        closeOnClickOutside: false,
        //                                        closeOnEsc: false,
        //                                    } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while checking Entered Lr Number Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getMethodForGetLrNumberDtls() {
    this.lrDtoForGetLrNoDtls = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoForGetLrNoDtls.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoForGetLrNoDtls.lrNumber = this.enteredLrNumber;
    this.lrDtoForGetLrNoDtls.stocksAt = this.userDataDtoReturnSession.office;
  }
  getLrNumberDetails() {
    this.getMethodForGetLrNumberDtls();
    this.memoService
      .getLrDetailsForCashMemoService(this.lrDtoForGetLrNoDtls)
      .subscribe((response) => {
        if (response.length == 0) {
          swal({
            title: "Record Not Found",
            text: "No records found for Entered Lr Number Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.clearFields();
          this.returnLrNumberDtls = new LRDto();
          this.responseOfGetLrNumberDtls = response;
          //                    console.log( this.enteredLrNumber );
          $("#" + this.pageId + "lrNumber").val(this.enteredLrNumber);
          this.returnLrNumberDtls = this.responseOfGetLrNumberDtls;
          //                    console.table( this.returnLrNumberDtls );
          console.log(
            "is cgnee blocked : " + this.returnLrNumberDtls.cashMemoBlock1
          );

          let calculatedDemurageChg = this.validateDemurageApplicable();
          console.log(" this.validateDemurageApplicable()");
          console.log(this.returnLrNumberDtls.enableDemurageLRForDestMemo);

          if (this.returnLrNumberDtls.status == "Diff Dest") {
            this.diffDestAlert(this.returnLrNumberDtls);
          } else if (this.returnLrNumberDtls.status == "Not Found") {
            this.notFoundAlert();
          } else if (this.returnLrNumberDtls.size == 0) {
            this.notFoundAlert();
          } else if (this.returnLrNumberDtls.memoStatus == "Blocked") {
            this.blcokedAlert();
          } else if (
            this.returnLrNumberDtls.lrsShortage ==
            this.returnLrNumberDtls.totalArticles &&
            this.returnLrNumberDtls.lrsExtra == 0
          ) {
            this.notAllowedAlert();
          } else if (this.returnLrNumberDtls.status == "Compound") {
            this.compundAlert();
          } else if (this.returnLrNumberDtls.cashMemoNumber != null) {
            this.memoLogicToShow(this.returnLrNumberDtls);
          } else if (
            this.returnLrNumberDtls.blocked == true &&
            this.returnLrNumberDtls.enableBlockedLrForDestMemo == false
          ) {
            this.enableBlockedLrForDestMemoAlert(this.returnLrNumberDtls);
          }
          //CHG_V-CM:14
          else if (
            this.returnLrNumberDtls.ccCopyEnabled == true &&
            this.returnLrNumberDtls.enableCCCopyLRForDestMemo == false
          ) {
            this.enableCCCopyLrForDestMemoAlert();
          }
          //CHG_V-CM:16
          else if (
            this.isDemurageLr == true &&
            this.returnLrNumberDtls.enableDemurageLRForDestMemo == false
          ) {
            this.blockedDemurageApplicableLrForDestMemoAlert(
              calculatedDemurageChg
            );
          } else {
            console.log(
              "is cgnee blocked - 1: " + this.returnLrNumberDtls.cashMemoBlock1
            );
            if (this.returnLrNumberDtls.cashMemoBlocked == true) {
              this.cashMemoBlocked(this.returnLrNumberDtls);
            } else if (this.returnLrNumberDtls.cashMemoBlock1 == true) {
              console.log(
                "is cgnee blocked - 2: " +
                this.returnLrNumberDtls.cashMemoBlock1
              );
              this.blcokedAlert();
            } else {
              //                            need to start
              this.bankLrs(this.returnLrNumberDtls);
              this.selfLrs(this.returnLrNumberDtls);
            }
          }
          
          if (this.returnLrNumberDtls.isSizeExceeded) {
            this.isSizeExceeded = true;


          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting Entered Lr Number Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  diffDestAlert(dataForDiffDest) {
    swal({
      title: "Different Destination",
      text:
        "The LR Number " +
        this.enteredLrNumber +
        " is not belongs to  " +
        this.userDataDtoReturnSession.office,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      swal.close();
      window.setTimeout(function () {
        $("#" + this.pageId + "lrNumber").focus();
      }, 100);
    });
  }

  notFoundAlert() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    swal({
      title: "Record Not Found",
      text: "LR no " + this.enteredLrNumber + " not found",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      swal.close();
      window.setTimeout(function () {
        $("#" + this.pageId + "lrNumber").focus();
      }, 1000);
    });
  }

  blcokedAlert() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    swal({
      title: "Cash Memo Block",
      text: "This LR no " + this.enteredLrNumber + " Blocked for Cash Memo",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      swal.close();
      window.setTimeout(function () {
        $("#" + this.pageId + "lrNumber").focus();
      }, 100);
    });
  }
  notAllowedAlert() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    swal({
      title: "Not Allowed",
      text:
        "All the Articles of this LR No " +
        this.enteredLrNumber +
        " are under Shortage",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      swal.close();
      window.setTimeout(function () {
        $("#" + this.pageId + "lrNumber").val("");
        $("#" + this.pageId + "lrNumber").focus();
      }, 100);
    });
  }

  compundAlert() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    swal({
      title: "Not Allowed",
      text:
        "LR No " +
        this.enteredLrNumber +
        " are at Compound, take the Invoice into Stock to Generate the Cashmemo",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      swal.close();
      window.setTimeout(function () {
        $("#" + this.pageId + "lrNumber").val("");
        $("#" + this.pageId + "lrNumber").focus();
      }, 100);
    });
  }
  memoLogicToShow(dataListForMemoLogic) {
    this.viewDeliveryTypeTxt = true;
    this.viewCmAtMemoGeneration = false;
    this.viewMemoAlreadyGeneratedMsg = false;
    this.viewGeneratedBy = true;
    this.viewDeliveryType = false;
    this.viewSaveBtn = false;

    if (this.asPerRightShowDeleteBtn == true) {
      this.viewDeleteBtn = true;
    }
    if (this.asPerRightShowEditBtn == true) {
      this.viewEditBtn = true;
    }

    if (this.asPerRightShowEditDelvBtn == true) {
      this.viewEditDelvBtn = true;
    }

    $("#" + this.pageId + "saveBtn").prop("disabled", true);
    $("#" + this.pageId + "generatedBy").val(dataListForMemoLogic.generatedBy);

    $("#" + this.pageId + "btnEnableTransitMemo").prop("disabled", true);
    $("#" + this.pageId + "enableDuplicateMemoBtn").prop("disabled", true);

    if (this.showMemoDate == true) {
      this.viewMemoDate = true;
    }

    if (this.isTransitMemoEnableBtn == true) {
      $("#" + this.pageId + "btnEnableTransitMemo").prop("disabled", false);
    }

    if (this.isDupMemoEnableBtn == true) {
      $("#" + this.pageId + "enableDuplicateMemoBtn").prop("disabled", false);
    }

    if (this.laserMemoAdmin == false) {
      if (dataListForMemoLogic.transitMemoPrt == true) {
        $("#" + this.pageId + "btnPrintTransitmemo").prop("disabled", true);
        this.viewTransitMemoPrtBtn = false;
      } else if (this.isMemoTransit == true) {
        this.viewTransitMemoPrtBtn = true;
        $("#" + this.pageId + "btnPrintTransitmemo").prop("disabled", false);
      }
    }

    //hide and show the btn as per rights is pending here
    if (
      dataListForMemoLogic.paymentMode != null &&
      dataListForMemoLogic.paymentMode == "OS" &&
      dataListForMemoLogic.deliveryMode != null &&
      dataListForMemoLogic.deliveryMode == "GD"
    ) {
      if (
        this.isMemoTransit == true &&
        dataListForMemoLogic.transitMemoPrt == false
      ) {
        this.viewTransitMemoPrtBtn = true;
      }
      if (this.laserMemoAdmin == true) {
        this.viewTransitMemoPrtBtn = true;
      }
    }

    if (this.memoPrintAgain == true) {
      this.viewPrintBtn = true;
    }
    if (this.allowDuplicateMemo == true) {
      if (this.laserMemoAdmin == false) {
        if (dataListForMemoLogic.duplicateMemo == true) {
          $("#" + this.pageId + "printBtn").prop("disabled", true);
          this.viewPrintBtn = false;
        }
      }
    }

    this.setExistingGeneratedLrFieldsDetails(dataListForMemoLogic);
    this.setReadOnlyFields();
    this.commodityForBangalore(dataListForMemoLogic);
  }

  setExistingGeneratedLrFieldsDetails(dataListForExistingGnrtd) {
    console.log("dataListForExistingGnrtd");
    console.log(dataListForExistingGnrtd);
    //need to start
    //        let status = dataListForExistingGnrtd.status;
    this.generatedBy = dataListForExistingGnrtd.generatedBy;
    $("#" + this.pageId + "collectionMenAtMemoGeneration").val(
      dataListForExistingGnrtd.cmAtMemoGeneration
    );
    this.collectionMenAtMemoGeneration =
      dataListForExistingGnrtd.cmAtMemoGeneration;
    this.status = dataListForExistingGnrtd.status;
    this.memoNumberObject = dataListForExistingGnrtd.cashMemoNumber;
    this.memoConsolidatedEWB = dataListForExistingGnrtd.respEwayBillNo;
    this.ewayBillDCNo = dataListForExistingGnrtd.ewayBillDCNo;
    this.newPartyViewPrint = dataListForExistingGnrtd.isNewConsignee;
    this.convertedBookingDate = this.datePipe.transform(
      dataListForExistingGnrtd.bookingDate,
      "dd-MM-yyyy"
    );
    $("#" + this.pageId + "bookingDate").val(this.convertedBookingDate);
    $("#" + this.pageId + "invoiceNumber").val(
      dataListForExistingGnrtd.invoiceNumber
    );
    $("#" + this.pageId + "sourceStation").val(dataListForExistingGnrtd.source);
    this.destinationObject = dataListForExistingGnrtd.destination;
    $("#" + this.pageId + "agentName").val(dataListForExistingGnrtd.agentName);
    $("#" + this.pageId + "consignorName").val(
      dataListForExistingGnrtd.consignorName
    );
    $("#" + this.pageId + "consigneeName").val(
      dataListForExistingGnrtd.consigneeName
    );
    $("#" + this.pageId + "noOfArticle").val(
      dataListForExistingGnrtd.totalArticles
    );
    $("#" + this.pageId + "actualWeight").val(
      dataListForExistingGnrtd.actualWeight
    );
    $("#" + this.pageId + "chargedWeight").val(
      dataListForExistingGnrtd.chargedWeight
    );
    $("#" + this.pageId + "toPay").val(dataListForExistingGnrtd.toPay);
    this.convertedInvoiceDate = this.datePipe.transform(
      dataListForExistingGnrtd.invoiceDate,
      "dd-MM-yyyy"
    );
    $("#" + this.pageId + "invoiceDate").val(this.convertedInvoiceDate);

    $("#" + this.pageId + "commodity").val(dataListForExistingGnrtd.column2);
    $("#" + this.pageId + "goodsValue").val(
      dataListForExistingGnrtd.goodsValue
    );
    $("#" + this.pageId + "ewayBillNo").val(
      dataListForExistingGnrtd.eWayBillNo
    );
    $("#" + this.pageId + "ewayBillValidUpto").val(
      dataListForExistingGnrtd.ewayBillValidUpto
    );
    this.ewayBillValidityDate =
      dataListForExistingGnrtd.ewayBillValidUpto as Date;
    console.log(this.ewayBillValidityDate);

    if (dataListForExistingGnrtd.paid == null) {
      $("#" + this.pageId + "paid").val(0);
    } else {
      $("#" + this.pageId + "paid").val(dataListForExistingGnrtd.paid);
    }

    if (dataListForExistingGnrtd.toPay == null) {
      $("#" + this.pageId + "toPay").val(0);
    } else {
      $("#" + this.pageId + "toPay").val(dataListForExistingGnrtd.toPay);
    }

    if (dataListForExistingGnrtd.baseFreight == null) {
      $("#" + this.pageId + "baseFreight").val(0);
    } else {
      $("#" + this.pageId + "baseFreight").val(
        dataListForExistingGnrtd.baseFreight
      );
    }
    if (
      dataListForExistingGnrtd.paid == null ||
      dataListForExistingGnrtd.paid == 0 ||
      dataListForExistingGnrtd.paid == 0.0
    ) {
      //the below is for readonly
      this.readonlyRcptChgBillSide = true;
      this.readonlySrvChgBillSide = true;
    } else {
      this.readonlyRcptChgBillSide = false;
      this.readonlySrvChgBillSide = false;
    }
    this.description = dataListForExistingGnrtd.column2;

    $("#" + this.pageId + "collectionMan").val(
      dataListForExistingGnrtd.collectionMan
    );
    //temprovery added as we using two area field
    if (this.isAreaBasedLogic == true) {
      $("#" + this.pageId + "areaBillingDtlsFieldSet").val(
        dataListForExistingGnrtd.deliveryarea
      );
    } else {
      $("#" + this.pageId + "areaCollectionDtlsFieldSet").val(
        dataListForExistingGnrtd.deliveryarea
      );
    }
    $("#" + this.pageId + "deliveryTypeTxt").val(
      dataListForExistingGnrtd.deliveryType
    );
    this.deliveryTypeTxt = dataListForExistingGnrtd.deliveryType;
    $("#" + this.pageId + "localCharge").val(
      dataListForExistingGnrtd.localCharge
    );
    $("#" + this.pageId + "unloadingHamali").val(
      dataListForExistingGnrtd.unLdgChg
    );
    this.unloadingHamali = dataListForExistingGnrtd.unLdgChg;
    //double value of is pending
    //Basha
    //        $( "#hamaliDd" ).val( dataListForExistingGnrtd.hamaliDD == null ? 0 : dataListForExistingGnrtd.hamaliDD.toFixed( 1 ) );
    $("#" + this.pageId + "hamaliDd").val(
      dataListForExistingGnrtd.hamaliDD == null
        ? 0
        : Math.round(dataListForExistingGnrtd.hamaliDD)
    );
    //        $( "#hamaliGd" ).val( dataListForExistingGnrtd.hamaliGD == null ? 0 : dataListForExistingGnrtd.hamaliGD.toFixed() );//double value of is pending
    $("#" + this.pageId + "hamaliGd").val(
      dataListForExistingGnrtd.hamaliGD == null
        ? 0
        : Math.round(dataListForExistingGnrtd.hamaliGD)
    ); //double value of is pending
    $("#" + this.pageId + "rcptChgBillSide").val(
      dataListForExistingGnrtd.receiptCharge
    );
    $("#" + this.pageId + "srvChgBillSide").val(
      dataListForExistingGnrtd.serviceCharge
    );
    $("#" + this.pageId + "demurrage").val(dataListForExistingGnrtd.demurage);
    $("#" + this.pageId + "ddHamaliLess").val(
      dataListForExistingGnrtd.ddHamaliLess
    );

    $("#" + this.pageId + "others").val(
      dataListForExistingGnrtd.otherChgAmtDestMemo
    );
    $("#" + this.pageId + "totalDiscount").val(
      dataListForExistingGnrtd.discountAmount
    );
    this.totalDiscount = dataListForExistingGnrtd.discountAmount;
    //        subTotal is removed because we are using only GST but it flow and rights to give in grandtotal
    //        serviceTax & serviceTaxAmount are not add because we are using GST
    //Basha
    //        $( "#grandTotal" ).val( dataListForExistingGnrtd.grandTotal );
    $("#" + this.pageId + "grandTotal").val(
      Math.round(dataListForExistingGnrtd.grandTotal)
    );
    if (this.roundOffView == true) {
      //            this.viewRoundOffAmt = true;
      $("#" + this.pageId + "roundOffAmt").val(
        dataListForExistingGnrtd.roundOffAmt
      );
    }
    //        godown is not showing in UI just its using to send the value
    this.godown = dataListForExistingGnrtd.godownName;
    //        paymentMode
    if (
      dataListForExistingGnrtd.paymentMode != null &&
      dataListForExistingGnrtd.paymentMode == "OS"
    ) {
      $("#" + this.pageId + "paymentMode").val("Outstanding");
    } else if (
      dataListForExistingGnrtd.paymentMode != null &&
      dataListForExistingGnrtd.paymentMode == "Cash"
    ) {
      $("#" + this.pageId + "paymentMode").val("Cash");
      if (
        dataListForExistingGnrtd.cashAmt != null &&
        dataListForExistingGnrtd.cashAmt > 0
      ) {
        $("#" + this.pageId + "paymentType").val("Cash");
        //hide and show is pending
        //                this.viewByCash = true; no use as per old code need to ask bhaiya
        //                this.viewByChequeNumber = false;
        //                this.viewByChequeDate = false;
        //                this.viewByConsigneeBankName = false;
        //                this.viewByReferenceNumber = false;
      } else if (
        dataListForExistingGnrtd.chqAmt != null &&
        dataListForExistingGnrtd.chqAmt > 0
      ) {
        $("#" + this.pageId + "paymentType").val("Cheque/PDC");
        $("#" + this.pageId + "consigneeBankName").val(
          dataListForExistingGnrtd.bankName
        );
        $("#" + this.pageId + "chequeNumber").val(
          dataListForExistingGnrtd.chequeNumber
        );
        $("#" + this.pageId + "chequeDate").val(
          dataListForExistingGnrtd.chequeDate
        );
        //                this.viewByCash = true; no use as per old code need to ask bhaiya
        //                this.viewByChequeNumber = true;
        //                this.viewByChequeDate = true;
        //                this.viewByConsigneeBankName = true;
        //                this.viewByReferenceNumber = false;
      } else if (
        dataListForExistingGnrtd.neftAmt != null &&
        dataListForExistingGnrtd.neftAmt > 0
      ) {
        $("#" + this.pageId + "paymentType").val("NEFT/IMPS");
        $("#" + this.pageId + "consigneeBankName").val(
          dataListForExistingGnrtd.bankName
        );
        $("#" + this.pageId + "referenceNumber").val(
          dataListForExistingGnrtd.refNumber
        );
        //                this.viewByCash = true;
        //                this.viewByChequeNumber = false;
        //                this.viewByChequeDate = false;
        //                this.viewByConsigneeBankName = true;
        //                this.viewByReferenceNumber = true;
      }
      //Chg_V-CM:26
      else if (
        dataListForExistingGnrtd.debitAcAmt != null &&
        dataListForExistingGnrtd.debitAcAmt > 0
      ) {
        $("#" + this.pageId + "paymentType").val("Debit Account");
        $("#" + this.pageId + "referenceNumber").val(
          dataListForExistingGnrtd.refNumber
        );
        //                this.viewByCash = true;
        //                this.viewByChequeNumber = false;
        //                this.viewByChequeDate = false;
        //                this.viewByConsigneeBankName = true;
        //                this.viewByReferenceNumber = true;
      } else if (
        dataListForExistingGnrtd.upiAmt != null &&
        dataListForExistingGnrtd.upiAmt > 0
      ) {
        $("#" + this.pageId + "paymentType").val("UPI");
        $("#" + this.pageId + "referenceNumber").val(
          dataListForExistingGnrtd.refNumber
        );
        //                this.viewByCash = true;
        //                this.viewByChequeNumber = false;
        //                this.viewByChequeDate = false;
        //                this.viewByConsigneeBankName = true;
        //                this.viewByReferenceNumber = true;
      }
      ///
    }
    //PP type is removed
    this.selectedVehicleNumber = dataListForExistingGnrtd.vehicleNumber;

    if (
      dataListForExistingGnrtd.deliveryMode != null &&
      dataListForExistingGnrtd.deliveryMode == "DD"
    ) {
      $("#" + this.pageId + "deliveryMode").val("DD");
      $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val(
        this.selectedVehicleNumber
      );

      this.showVehicleNumberOption = true;
      this.showVehicleNumberOptionGD = false;
      this.viewGenerateCEWbBtn = false;
      this.viewGenerateDCBtn = false;
      this.printGenerateCEWbBtn = false;
      this.printGenerateDCBtn = false;
    } else if (
      dataListForExistingGnrtd.deliveryMode != null &&
      dataListForExistingGnrtd.deliveryMode == "GD"
    ) {
      $("#" + this.pageId + "deliveryMode").val("GD");
      this.showVehicleNumberOption = false;
      this.showVehicleNumberOptionGD = true;
      $("#" + this.pageId + "gdVehicleNumber").val(this.selectedVehicleNumber);
      console.log(
        dataListForExistingGnrtd.respEwayBillNo +
        " - " +
        dataListForExistingGnrtd.ewayBillDCNo +
        " - " +
        dataListForExistingGnrtd.isExpired
      );
      if (
        dataListForExistingGnrtd.respEwayBillNo == null &&
        dataListForExistingGnrtd.ewayBillDCNo == null
      ) {
        console.log("1");
        this.printGenerateCEWbBtn = false;
        this.printGenerateDCBtn = false;
        if (dataListForExistingGnrtd.isExpired) {
          this.viewGenerateCEWbBtn = false;
          this.viewGenerateDCBtn = true;
        } else {
          this.viewGenerateCEWbBtn = true;
          this.viewGenerateDCBtn = false;
        }
      } else if (dataListForExistingGnrtd.ewayBillDCNo != null) {
        console.log("2");
        this.viewGenerateCEWbBtn = false;
        this.viewGenerateDCBtn = false;
        this.printGenerateCEWbBtn = false;
        this.printGenerateDCBtn = true;
      } else if (dataListForExistingGnrtd.respEwayBillNo != null) {
        console.log("3");
        this.viewGenerateCEWbBtn = false;
        this.viewGenerateDCBtn = false;
        this.printGenerateCEWbBtn = true;
        this.printGenerateDCBtn = false;
      }
    }
    //IN OLD WS WE HAVE 'dfCashMemoDate' AS A OBJECT IN NEW WE WE ARE USING 'cashMemoDateObject'
    this.cashMemoDateObject = dataListForExistingGnrtd.cashMemoDate;
    //        console.log(dataListForExistingGnrtd.cashMemoDate);
    if (dataListForExistingGnrtd.cashMemoTime == null) {
      this.memoTimeByUser = null;
    } else {
      this.memoTimeByUser = dataListForExistingGnrtd.cashMemoTime; //check for time format
    }

    this.consigneeId = dataListForExistingGnrtd.consigneeId;
    this.consignorId = dataListForExistingGnrtd.consignorId;

    //        isSerTaxExemp is removed
    //      dataListForExistingGnrtd

    $("#" + this.pageId + "srmSourceStation").val(
      dataListForExistingGnrtd.rateSrcStation == null
        ? "N/A"
        : dataListForExistingGnrtd.rateSrcStation
    );
    $("#" + this.pageId + "srmRateId").val(
      dataListForExistingGnrtd.consigneeRateId == null
        ? "0"
        : dataListForExistingGnrtd.consigneeRateId
    );
    $("#" + this.pageId + "srmRate").val(
      dataListForExistingGnrtd.rate == null
        ? "0"
        : dataListForExistingGnrtd.rate
    );
    this.srmRate =
      dataListForExistingGnrtd.rate == null
        ? "0"
        : dataListForExistingGnrtd.rate;
    $("#" + this.pageId + "srmRatePercentage").val(
      dataListForExistingGnrtd.rate == null
        ? "0"
        : dataListForExistingGnrtd.rate
    );
    this.srmRatePercentage =
      dataListForExistingGnrtd.rate == null
        ? "0"
        : dataListForExistingGnrtd.rate;
    this.srmDiscount =
      dataListForExistingGnrtd.rateDiscountOn == null
        ? "0"
        : dataListForExistingGnrtd.rateDiscountOn;
    $("#" + this.pageId + "srmDiscount").val(
      dataListForExistingGnrtd.rateDiscountOn == null
        ? "0"
        : dataListForExistingGnrtd.rateDiscountOn
    );
    this.srmGcCharge =
      dataListForExistingGnrtd.rateGcChg == null
        ? "0"
        : dataListForExistingGnrtd.rateGcChg;
    $("#" + this.pageId + "srmGcCharges").val(
      dataListForExistingGnrtd.rateGcChg == null
        ? "0"
        : dataListForExistingGnrtd.rateGcChg
    );
    this.srmSourceHamali =
      dataListForExistingGnrtd.rateSrcHamali == null
        ? "0"
        : dataListForExistingGnrtd.rateSrcHamali;
    $("#" + this.pageId + "srmSourceHamali").val(
      dataListForExistingGnrtd.rateSrcHamali == null
        ? "0"
        : dataListForExistingGnrtd.rateSrcHamali
    );
    this.srmDestinationHamali =
      dataListForExistingGnrtd.rateDestHamali == null
        ? "0"
        : dataListForExistingGnrtd.rateDestHamali;
    $("#" + this.pageId + "srmDestinationHamali").val(
      dataListForExistingGnrtd.rateDestHamali == null
        ? "0"
        : dataListForExistingGnrtd.rateDestHamali
    );
    this.srmReciptCharges =
      dataListForExistingGnrtd.rateReceiptChg == null
        ? "0"
        : dataListForExistingGnrtd.rateReceiptChg;
    $("#" + this.pageId + "srmReciptCharges").val(
      dataListForExistingGnrtd.rateReceiptChg == null
        ? "0"
        : dataListForExistingGnrtd.rateReceiptChg
    );
    this.srmServiceCharges =
      dataListForExistingGnrtd.rateServiceChg == null
        ? "0"
        : dataListForExistingGnrtd.rateServiceChg;
    $("#" + this.pageId + "srmServiceCharges").val(
      dataListForExistingGnrtd.rateServiceChg == null
        ? "0"
        : dataListForExistingGnrtd.rateServiceChg
    );
    //this.viewSrmDiscount
    if (
      dataListForExistingGnrtd.rateUnit != null &&
      dataListForExistingGnrtd.rateUnit == "Discount"
    ) {
      this.viewSrmRate = false;
      this.viewSrmRatePercentage = true;
      this.viewSrmDiscount = true;
    } else {
      this.viewSrmRate = true;
      this.viewSrmRatePercentage = false;
      this.viewSrmDiscount = false;
    }

    $("#" + this.pageId + "leatherHead").val(
      dataListForExistingGnrtd.withLetterHead
    );
    //        console.log(dataListForExistingGnrtd.cashMemoTime);
    this.convertedMemoDate = this.datePipe.transform(
      dataListForExistingGnrtd.cashMemoTime,
      "dd-MM-yyyy"
    );
    this.newconvertedMemoDate = dataListForExistingGnrtd.cashMemoTime;
    $("#" + this.pageId + "cashmemoDate").val(this.convertedMemoDate);
    $("#" + this.pageId + "cgst").val(dataListForExistingGnrtd.cgst);
    $("#" + this.pageId + "cgstAmt").val(dataListForExistingGnrtd.cgstamt);
    $("#" + this.pageId + "sgst").val(dataListForExistingGnrtd.sgst);
    $("#" + this.pageId + "sgstAmt").val(dataListForExistingGnrtd.sgstamt);
    $("#" + this.pageId + "igst").val(dataListForExistingGnrtd.igst);
    $("#" + this.pageId + "igstAmt").val(dataListForExistingGnrtd.igstamt);
    $("#" + this.pageId + "gstTotal").val(dataListForExistingGnrtd.gstAmtTotal);
    this.riskChg = dataListForExistingGnrtd.riskCharge;
    this.viewCmAtMemoGeneration = true;
    this.viewMemoAlreadyGeneratedMsg = true;
    $("#" + this.pageId + "tripsheetHamali").val(
      dataListForExistingGnrtd.tripsheetHamali
    );
  }

  setReadOnlyFields() {
    //is pending
    this.readonlyServiceCharges = true;
    this.readonlyReceiptCharges = true;
    this.readonlyLocalCharge = true;

    //CHG_V-CM:13
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      (this.userDataDtoReturnSession.mainStation == "Chennai" ||
        this.userDataDtoReturnSession.mainStation == "Madurai" ||
        this.userDataDtoReturnSession.mainStation == "Trivandrum")
    ) {
      this.viewUnloadingHamali = true;
      this.viewHamaliDd = true;
      // this.viewHamaliGd = false;
      //CHG_V-CM:10
      this.viewHamaliGd = true;
    } else {
      this.viewUnloadingHamali = false;
      this.viewHamaliDd = true;
      this.viewHamaliGd = true;
    }
    this.readonlyHamaliDd = true;
    this.readonlyHamaliGd = true;

    // this.readonlyUnloadingHamali = true;
    this.readonlyDemurrage = true;
    this.readonlyddHamaliLess = true;

    $("#" + this.pageId + "paymentMode").prop("disabled", true);
    $("#" + this.pageId + "deliveryMode").prop("disabled", true);
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").prop(
      "disabled",
      true
    );

    this.readonlySrmSourceStation = true;
    this.readonlySrmRate = true;
    this.readonlySrmGcCharges = true;
    this.readonlySrmSourceHamali = true;
    this.readonlySrmDestinationHamali = true;
    this.readonlySrmReciptCharges = true;
    this.readonlySrmServiceCharges = true;
    this.readonlySrmRatePercentage = true;
    this.readonlySrmDiscount = true;
    this.readonlyCbLetterHead = true;
    this.readonlyDirectAssg = true;
    this.readonlySrmPerUnit = true;

    this.viewTripsheetHamali = false;
    if (
      this.returnLrNumberDtls.paid != null &&
      this.returnLrNumberDtls.paid > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        //if(this.hamaliValues="cashmemo set hamali values"){}
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
          $("#" + this.pageId + "hamaliDd").val(0);
          this.viewTripsheetHamali = true;
          this.readonlyTripsheetHamali = true;
        } else {
          this.viewTripsheetHamali = false;
          this.readonlyTripsheetHamali = true;
        }
      }
    } else if (
      this.returnLrNumberDtls.toPay != null &&
      this.returnLrNumberDtls.toPay > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        //if(this.hamaliValues="cashmemo set hamali values"){}
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
          $("#" + this.pageId + "hamaliDd").val(0);
          this.viewTripsheetHamali = true;
          this.readonlyTripsheetHamali = true;
        } else {
          this.viewTripsheetHamali = false;
          this.readonlyTripsheetHamali = true;
        }
      }
    }

    this.isMemoAlreadyGenerated = true;
  }

  enableBlockedLrForDestMemoAlert(dataListForEnableBlkdLr) {
    swal({
      title: "Memo Generation is Blocked ",
      text: dataListForEnableBlkdLr.blockedRemarks,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  enableCCCopyLrForDestMemoAlert() {
    swal({
      title: "Memo Generation is Blocked ",
      text: "Do not deliver without collecting the Consignee Copy. Please contact Admin to enable it.",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  cashMemoBlocked(dalaListForCashMemoBlocked) {
    var consigneeNameAlert = dalaListForCashMemoBlocked.consigneeName;
    swal({
      title: "Incomplete in Consignee Master",
      text:
        consigneeNameAlert +
        " - Details is InComplete in Consignee Master\n Do You Want To Update in Consignee Master",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(() => {
      //            if btn click Yes
      //            redirect to consignee master
      //          if btn click no
      this.isMemoAlreadyGenerated = true;
      this.bankLrs(dalaListForCashMemoBlocked);
      this.selfLrs(dalaListForCashMemoBlocked);
    });
  }

  bankLrs(dalaListForBankLrs) {
    //        console.log( "Dto Banklr " + dalaListForBankLrs.isBankLr );
    if (dalaListForBankLrs.bankLr != null && dalaListForBankLrs.bankLr == "1") {
      //            console.log( this.userDataDtoReturnSession.blockBankLr );
      if (this.userDataDtoReturnSession.blockBankLr == true) {
        //                console.log( this.dalaListForBankLrs.enableBankLrForDestMemo );
        if (dalaListForBankLrs.enableBankLrForDestMemo == true) {
          //alert( "Inside of IF of BankLR" );
          this.codValidationsLogic(dalaListForBankLrs);
        } else {
          swal({
            title: "Bank LR",
            text: "This is bank Lr, please collect the consignee copy and then deliver. To generate Memo, please contact Admin to enable the memo generation!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            this.clearFields();
          });
        }
      } else {
        swal({
          title: "Bank LR",
          text: "This is bank Lr, please collect the consignee copy and then deliver",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          this.codValidationsLogic(dalaListForBankLrs);
        });
      }
    } else {
      //            alert( "Inside of Else of Bank LR" );
      this.codValidationsLogic(dalaListForBankLrs);
    }
  }

  selfLrs(dalaListForSelfLrs) {
    //        console.log( "Dto SelfLr>> " + dalaListForSelfLrs.selfLr );
    if (dalaListForSelfLrs.selfLr == true) {
      //            console.log( this.userDataDtoReturnSession.blockSelfLr );
      if (this.userDataDtoReturnSession.blockSelfLr == true) {
        //                console.log( this.dalaListForSelfLrs.enableSelfLrForDestMemo );
        if (dalaListForSelfLrs.enableSelfLrForDestMemo == true) {
          //alert( "Inside of IF of SelfLR" );
          this.codValidationsLogic(dalaListForSelfLrs);
        } else {
          swal({
            title: "Self LR",
            text: "This is Self LR, please collect the consignee copy and then deliver. To generate Memo, please contact Admin to enable the memo generation!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            this.clearFields();
          });
        }
      } else {
        swal({
          title: "Self LR",
          text: "This is Self LR, please collect the consignee copy and then deliver",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          this.codValidationsLogic(dalaListForSelfLrs);
        });
      }
    } else {
      //            alert( "Inside of Else of SelfLR" );
      this.codValidationsLogic(dalaListForSelfLrs);
    }
  }

  codValidationsLogic(dataFroCodValidate) {
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    if (
      dataFroCodValidate.isCod == true &&
      this.userDataDtoReturnSession.isCODLrMemoBlock == false
    ) {
      //            alert( "Inside of If" );
      this.codLrValidation();
      this.generateCashMemo(dataFroCodValidate);
      this.commodityForBangalore(dataFroCodValidate);
    } else if (
      dataFroCodValidate.isCod == true &&
      this.userDataDtoReturnSession.isCODLrMemoBlock == true &&
      dataFroCodValidate.codPaymentMode == null &&
      dataFroCodValidate.enableCodLrForDestMemo == true
    ) {
      this.generateCashMemo(dataFroCodValidate);
      this.commodityForBangalore(dataFroCodValidate);
    } else if (
      dataFroCodValidate.isCod == true &&
      this.userDataDtoReturnSession.isCODLrMemoBlock == true &&
      dataFroCodValidate.codPaymentMode == null &&
      dataFroCodValidate.enableCodLrForDestMemo == false
    ) {
      //            alert( "Inside of Else If" );
      swal({
        title: "Memo Generation Blocked ",
        text:
          "This LR " +
          this.enteredGcNoteNo +
          " is COD, please complete the payment",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      //            alert( "Inside of Else" );
      //            console.log(dataFroCodValidate);
      this.generateCashMemo(dataFroCodValidate);
      this.commodityForBangalore(dataFroCodValidate);
    }
  }

  codLrValidation() {
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    for (let i = 0; i < 3; i++) {
      swal({
        title: "Remainder " + (i + 1),
        text:
          "This LR " +
          this.enteredGcNoteNo +
          " is COD please collect the cheque",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  generateCashMemo(dataFrogenerateCM) {
    //        console.log( dataFrogenerateCM.commodityConfirm );
    if (dataFrogenerateCM.commodityConfirm == false) {
      swal({
        title: "UnConfirmed Commodity LR",
        text: "Sure You Want To Update ",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          swal({
            title: "Confirmation",
            text:
              "Sure You Want to Confirm The Commodity : " +
              dataFrogenerateCM.column2 +
              " To Consignee : " +
              dataFrogenerateCM.consigneeName,
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((yesBtn) => {
            if (yesBtn) {
              this.setAllYesLrFields(dataFrogenerateCM);
            } else {
              this.setAllNoLrFields(dataFrogenerateCM);
            }
          });
        } else {
          this.setAllLrFields(dataFrogenerateCM);
        }
      });
    } else {
      this.setAllLrFields(dataFrogenerateCM);
    }
  }

  commodityForBangalore(dataListFroCmdtyForBng) {
    console.log("ok commodity");
    if ($("#" + this.pageId + "collectionMan").val() == "KARTIK") {
      this.viewCommodityCombo = true;
      this.viewCommodity = false;
      //btnRecalculate.setVisible(true);
      this.isRecalculate = true;
      this.getCommodity();
      this.viewNewCollectionMans = false;
    } else {
      this.commodityAdmin = $("#" + this.pageId + "agentName").val();
      this.lrDtoCommodityAdmin.source = this.commodityAdmin;
      this.lrDtoCommodityAdmin.consigneeId = this.consigneeId;
      this.lrDtoCommodityAdmin.companyId =
        this.userDataDtoReturnSession.companyId;

      this.masterReadService
        .getCommodityFromAdminRateMater(this.lrDtoCommodityAdmin)
        .subscribe((response) => {
          if (response) {
            this.commodityComboDataList = null;
            //console.log(response);
            this.commodityComboDataList = response;
            //console.log("Inside of service getCommodityFromAdminRateMater");
            //console.log(this.commodityComboDataList);
            if (this.commodityComboDataList.length > 0) {
              this.viewCommodityCombo = true;
              this.viewCommodity = false;
              this.isRecalculate = true;
              this.viewNewCollectionMans = false;
              this.viewBtnRecalculate = true;
              //$( "#commodityCombo" ).val( this.returnLrNumberDtls.column2 );
              this.commodityCombo = this.returnLrNumberDtls.column2;
            } else {
              this.viewCommodityCombo = false;
              this.viewCommodity = true;
              this.isRecalculate = false;
              this.viewBtnRecalculate = false;
              this.viewNewCollectionMans = false;
            }

            this.commodityComboTA = [];
            for (let i = 0; i < this.commodityComboDataList.length; i++) {
              this.commodityComboTA.push(
                this.commodityComboDataList[i].commodityName
              );
            }
          }
          this.changeDetectorRef.detectChanges();
        }),
        (error) =>
          swal({
            title: "Server Error",
            text: "Problem occur while getting Commodity From Admin Rate Master",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }),
        () => console.log("done");
    }
  }

  setAllLrFields(dataFroAllLrFields) {
    this.viewDeliveryType = true;
    this.viewDeliveryTypeTxt = false;
    /*
        btnLaserPrintMemo.setVisible(false);*/
    this.viewPrintBtn = false;
    this.viewTransitMemoPrtBtn = false;
    this.viewSaveBtn = true;
    this.setLrFieldsDetails(dataFroAllLrFields);
    $("#" + this.pageId + "deliveryType").focus();
  }

  setLrFieldsDetails(dataFroLrFields) {
    this.convertedBookingDate = this.datePipe.transform(
      dataFroLrFields.bookingDate,
      "dd-MM-yyyy"
    );
    $("#" + this.pageId + "bookingDate").val(this.convertedBookingDate);
    $("#" + this.pageId + "invoiceNumber").val(dataFroLrFields.invoiceNumber);
    $("#" + this.pageId + "sourceStation").val(dataFroLrFields.source);
    this.destinationObject = dataFroLrFields.destination;
    $("#" + this.pageId + "agentName").val(dataFroLrFields.agentName);
    $("#" + this.pageId + "consignorName").val(dataFroLrFields.consignorName);
    $("#" + this.pageId + "consigneeName").val(dataFroLrFields.consigneeName);
    $("#" + this.pageId + "noOfArticle").val(dataFroLrFields.totalArticles);
    $("#" + this.pageId + "toPay").val(dataFroLrFields.toPay);
    $("#" + this.pageId + "actualWeight").val(dataFroLrFields.actualWeight);
    $("#" + this.pageId + "chargedWeight").val(dataFroLrFields.chargedWeight);
    this.convertedInvoiceDate = this.datePipe.transform(
      dataFroLrFields.invoiceDate,
      "dd-MM-yyyy"
    );
    $("#" + this.pageId + "invoiceDate").val(this.convertedInvoiceDate);
    $("#" + this.pageId + "commodity").val(dataFroLrFields.column2);

    $("#" + this.pageId + "goodsValue").val(dataFroLrFields.goodsValue);
    $("#" + this.pageId + "ewayBillNo").val(dataFroLrFields.eWayBillNo);
    $("#" + this.pageId + "ewayBillValidUpto").val(
      dataFroLrFields.ewayBillValidUpto
    );
    this.ewayBillValidityDate = dataFroLrFields.ewayBillValidUpto as Date;
    //CHG_V-CM:17
    if (this.userDataDtoReturnSession.destMemoPaymentModeBlock) {
      this.collectionPaymentModeOptions(dataFroLrFields);
    }
    console.log(dataFroLrFields.paymentMode);
    this.godown = dataFroLrFields.godownName;
    if (dataFroLrFields.paid == null) {
      $("#" + this.pageId + "paid").val(0);
    } else {
      $("#" + this.pageId + "paid").val(dataFroLrFields.paid);
    }

    if (dataFroLrFields.toPay == null) {
      $("#" + this.pageId + "toPay").val(0);
    } else {
      $("#" + this.pageId + "toPay").val(dataFroLrFields.toPay);
    }

    if (dataFroLrFields.baseFreight == null) {
      $("#" + this.pageId + "baseFreight").val(0);
    } else {
      $("#" + this.pageId + "baseFreight").val(dataFroLrFields.baseFreight);
    }
    this.description = dataFroLrFields.column2;
    $("#" + this.pageId + "collectionMan").val(dataFroLrFields.collectionMan);
    //As we using two area field
    if (this.isAreaBasedLogic == true) {
      $("#" + this.pageId + "areaBillingDtlsFieldSet").val(
        dataFroLrFields.areaOfDelivery
      );
    } else {
      $("#" + this.pageId + "areaCollectionDtlsFieldSet").val(
        dataFroLrFields.areaOfDelivery
      );
    }
    this.cashMemoDateObject = this.todayDate;
    //        console.log(this.cashMemoDateObject);
    this.memoTime = this.currentTime;

    //        deliveryType.setEnabled(true);

    this.readonlyLocalCharge = false;
    this.readonlyHamaliDd = false;
    this.readonlyHamaliGd = false;
    this.readonlyUnloadingHamali = false;
    this.readonlyDemurrage = false;
    this.readonlyddHamaliLess = false;

    $("#" + this.pageId + "paymentMode").prop("disabled", false);
    $("#" + this.pageId + "deliveryMode").prop("disabled", false);
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").prop(
      "disabled",
      false
    );
    //        radOthers.setReadOnly(false);
    //        radSelf.setReadOnly(false);
    //        radOthers.setEnabled(true);
    //        radSelf.setEnabled(true);
    //
    if (
      dataFroLrFields.deliveryMode != null &&
      dataFroLrFields.deliveryMode == "Godown Delivery"
    ) {
      $("#" + this.pageId + "deliveryMode").val("GD");
    } else if (
      dataFroLrFields.deliveryMode != null &&
      dataFroLrFields.deliveryMode == "Door Delivery"
    ) {
      $("#" + this.pageId + "deliveryMode").val("DD");

      $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val(
        dataFroLrFields.vehicleNumber
      );

      this.showVehicleNumberOption = true;
      this.showVehicleNumberOptionGD = false;
    } else {
      $("#" + this.pageId + "deliveryMode").val("GD");
      this.showVehicleNumberOption = false;
      this.showVehicleNumberOptionGD = true;
    }
    this.generatedBy = dataFroLrFields.GeneratedBy;
    this.consigneeId = dataFroLrFields.consigneeId;
    this.consignorId = dataFroLrFields.consignorId;

    if (this.roundOffView == true) {
      $("#" + this.pageId + "roundOffAmt").val(dataFroLrFields.roundOffAmt);
    }
    this.readonlyCbLetterHead = false;
    this.readonlyDirectAssg = false;

    this.riskChg = dataFroLrFields.riskCharge;
    this.enteredTripsheetHamali = dataFroLrFields.tripsheetHamali;
    //   isSerTaxExemp is not added
  }
  clearFields() {
    $('input[type="text"],input[type="number"]').val("");
    this.controlCollectionMan.reset();
    this.viewMemoAlreadyGeneratedMsg = false;
    this.controlAreaBillingDtlsFieldSet.reset();
    this.controlAreaCollectionDtlsFieldSet.reset();
    this.controlConsigneeBankName.reset();
    this.controlCommodityCombo.reset();
    this.controlGoodsType.reset();
    $("#" + this.pageId + "paymentType").val("");
    $("#" + this.pageId + "paymentMode").val("Select");
    //        Pending
    //        radOthers.setValue(false);
    //        radSelf.setValue(true);
    $("#" + this.pageId + "deliveryType").val("Select Delivery Type");
    //in old ws it were not ther so commentted need to ask bhaiya
    //        $( "#deliveryMode" ).val( 'GD' );
    $("#" + this.pageId + "bookingDate").val("");
    $("#" + this.pageId + "invoiceDate").val("");
    $("#" + this.pageId + "unloadingHamali").val("");
    //        saveButton.setVisible(true);
    //        clearButton.setVisible(true);
    //        deleteButton.setVisible(false);
    //        editButton.setVisible(false);
    //        editDelvBtn.setVisible(false);
    //        updateButton.setVisible(false);
    //        btnPrintMemo.setVisible(false);
    //        btnLaserPrintMemo.setVisible(false);
    //        btnTransitMemoPrt.setVisible(false);

    $("#" + this.pageId + "roundOffAmt").val("");

    this.lrDtosOtherChg = [];

    this.unitType = "";
    this.description = "";
    this.unit = "";
    //        txtAreaNew.setReadOnly(false);
    //        collectionManNew.setReadOnly(false);
    //        collectionMan.setReadOnly(false);
    //        area.setReadOnly(false);
    //        cbLetterHead.setValue(false);
    //        dfMemoDate.setEnabled(false);

    //        serviceTax.setVisible(true);
    //        serviceTaxAmount.setVisible(true);
    //        isGSTMemo = false;
    this.readonlyHamaliDd = false;
    this.readonlyRcptChgBillSide = false;
    this.readonlySrvChgBillSide = false;
    //        dfMemoDate.setVisible(false);
    this.cbWLH = false;
    this.cbDirectAssg = false;
    $("#" + this.pageId + "letterHead").prop("checked", false);
    $("#" + this.pageId + "directAssg").prop("checked", false);
    $("#" + this.pageId + "areaBillingDtlsFieldSet").prop("disabled", false);
    $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop("disabled", false);
    $("#" + this.pageId + "collectionMan").prop("disabled", false);
    $("#" + this.pageId + "saveBtn").prop("disabled", false);
    this.viewNewAreaCollectionDtlsFieldSet = false;
    this.viewNewAreaBillingDtlsFieldSet = false;
    this.viewNewCollectionMans = false;

    this.stationWiseFieldPosition = null;

    //        this.isAreaBasedLogic = false;
    this.viewByCash = false;
    this.viewByChequeNumber = false;
    this.viewByChequeDate = false;
    this.viewByConsigneeBankName = false;
    this.viewByReferenceNumber = false;

    this.isMemoAlreadyGenerated = false;
    //        this.codEnableRights = false;
    //        this.roundOffView = false;
    this.viewRoundOffAmt = false;
    this.godown = "";
    this.cashMemoDateObject = "";
    this.memoTime = "";
    this.memoTimeByUser = "";
    this.generatedBy = null;
    this.consigneeId = 0;
    this.consignorId = 0;
    this.viewSrmDiscount = false;
    this.viewSrmSrcHamali = false;
    this.viewSrmRate = true;
    this.viewSrmRatePercentage = false;
    this.viewMemoDate = false;
    this.viewCmAtMemoGeneration = false;
    this.collectionMenAtMemoGeneration = null;
    $("#" + this.pageId + "collectionMenAtMemoGeneration").val("");
    this.viewTripsheetHamali = false;
    this.viewGeneratedBy = false;
    this.viewDeliveryTypeTxt = false;
    this.viewDeliveryType = true;
    this.dataListOfCrossingChgArea = "";
    this.enteredPaid = "";
    this.enteredGrandTotal = "";

    this.gstAmt = 0;
    this.gstRoundedAmt = 0;
    this.enteredCgstAmt = "";
    this.enteredSgstAmt = "";
    this.enteredIgstAmt = "";
    this.gstTotalAmt = 0;

    //        this.cashMemoDiscountEditBlock = false;

    this.enteredTotalDiscount = "";
    this.enteredHamaliDd = 0;
    this.enteredHamaliGd = "";
    this.viewUnloadingHamali = false;

    this.enteredUnloadingHamali = "";
    this.totalHamaliDd = 0;
    this.totalHamaliGd = 0;
    this.totalUnloadingHamali = 0;

    this.roundOffValue = 0;
    this.diffInRoundValue = 0;
    //        this.checkRateMemoAmt = null;
    this.rateAmt = 0;

    this.newArea = "";
    this.newCollectionMan = "";
    this.enteredArea = "";
    this.selectedCollectionMan = "";
    this.enteredBookingDate = "";
    this.enteredConsignee = "";
    this.enteredConsignor = "";
    this.enteredDemurage = "";
    this.enteredGcNoteNo = "";
    this.inRupees = "";
    this.enteredLocalCharge = "";
    this.enteredOtherChg = "";
    this.enteredLorryFreight = "";
    this.enteredMemoDate = "";
    this.enteredReceiptCharge = "";
    this.enteredServiceCharge = "";
    this.enteredInvoiceNumber = "";
    this.enteredInvoiceDate = "";
    this.enteredCommodityName = "";
    this.enteredSourceStation = "";
    this.enteredAgentName = "";
    this.commodityAdmin = "";
    this.enteredNoOfArticle = "";
    this.enteredChargedWeight = "";
    this.selectedPaymentMode = "";
    this.selectedPaymentType = "";
    this.selectedBankName = "";
    this.enteredNewBankName = "";
    this.enteredChequeNumber = "";
    this.enteredReferenceNumber = "";
    this.enteredChequeDate = "";
    this.selectedDeliveryMode = "";
    this.selectedDeliveryType = "";
    this.enteredDeliveryType = "";
    this.enteredCashMemoDate = "";
    this.enteredSrmSourceStation = "";
    this.enteredSrmRateId = 0;
    this.enteredSrmRate = "";
    this.enteredSrmSourceHamali = "";
    this.enteredSrmDestHamali = "";
    this.enteredSrmServiceCharge = "";
    this.enteredSrmReceiptCharge = "";
    this.enteredSrmGcCharge = "";
    this.enteredSrmDiscount = "";
    this.enteredUnloadingCharge = "";
    this.enteredRoundOffAmt = "";
    this.checkBoxLetterHead = "";
    this.checkBoxDirectAssg = "";
    this.enteredIgst = "";
    this.enteredCgst = "";
    this.enteredSgst = "";
    this.enteredGstTotal = "";
    this.enteredTripsheetHamali = "";

    this.enteredEwayBillNo = "";
    this.enteredGoodsValue = "";

    this.validateMemoDate = "";
    this.dataForPrint = "";

    //        this.viewCashMemoPrint = false;
    this.discnt = "";
    this.calcDisOne = "";
    this.calcDisTwo = "";
    this.calcDisThree = "";
    this.calcDisFour = "";

    this.calcGrandTotal = null;
    this.unit = "";
    this.deliveryTypeTxt = null;
    $("#" + this.pageId + "deliveryTypeTxt").val("");
    //        this.todayDate ='';
    //        this.currentTime ='';

    this.riskChg = 0;
    this.fovLessAmt = 0;
    this.cbWLH = false;
    this.viewCashOption = true;
    //        this.inLaserPrint = false;

    this.viewHamaliDd = true;
    this.viewHamaliGd = true;
    this.setUnloadingChg = 0;
    this.setUnit = null;

    this.setUnloadingChgParty = "";
    this.setUnldgCommodities = "";
    this.setUnldgSource = "";
    this.crossingChg = "";
    this.unloadHamali = "";
    this.totalCrossingChg = "";
    this.subStationGDChg = "";
    this.hamaliGDAmt = "";

    this.readonlyCashmemoDate = false;

    this.unldgCommoditiesSplit = [];
    this.unldgSourceSplit = [];
    this.hamaliValues = null;
    this.readonlyTripsheetHamali = false;
    this.chkHamaliRate = null;

    this.dataListOfCrossingChg = "";
    this.setValuesInFields = null;

    this.dataListOfCrossingChgSpecfic = "";
    this.isFixedRate = null;
    this.isLCChg = null;
    this.isBCChg = null;
    this.isAoc = null;
    this.isInsChg = null;
    this.isDD = null;
    this.isOthers = null;
    this.isHamaliChg = null;
    this.hamaliSrcCustom = false;
    this.customHamaliSrcChg = null;

    this.readonlySrmSourceStation = false;
    this.readonlySrmRate = false;
    this.readonlySrmGcCharges = false;
    this.readonlySrmSourceHamali = false;
    this.readonlySrmDestinationHamali = false;
    this.readonlySrmReciptCharges = false;
    this.readonlySrmServiceCharges = false;
    this.readonlySrmRatePercentage = false;
    this.readonlySrmDiscount = false;
    this.readonlyServiceCharges = false;
    this.readonlyReceiptCharges = false;
    this.readonlyLocalCharge = false;
    this.readonlyUnloadingHamali = false;
    this.readonlyDemurrage = false;
    this.readonlyddHamaliLess = false;
    this.readonlySrmPerUnit = false;
    $("#" + this.pageId + "paymentMode").prop("disabled", false);
    $("#" + this.pageId + "deliveryMode").prop("disabled", false);
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").prop(
      "disabled",
      false
    );
    this.readonlyCbLetterHead = false;
    this.readonlyDirectAssg = false;
    this.isFOV = true;
    this.calcDiscnt = 0;
    this.otherDiscChg = 0;
    this.ddHamaliLessAmt = 0;
    this.enteredActualWeight = "";
    this.enteredBaseFreight = "";
    //from here
    this.rateUnit = null;
    this.ratePerUnit = null;
    //NO USE OF STATUS
    this.status = null;
    this.memoNumberObject = null;
    this.memoConsolidatedEWB = null;
    this.ewayBillDCNo = null;
    this.newPartyViewPrint = false;
    //        this.blockConsignee = false;
    //        this.allowDuplicateMemo = false;
    //        this.laserMemoAdmin = false;
    //        this.allowDupMemoMessage = false;
    //        this.isMemoTransit = false;
    this.viewTransitMemoPrtBtn = false;
    this.viewPrintBtn = false;
    this.viewDeleteBtn = false;
    //                this.isAdminRate = false;
    this.setAdminRate = "";
    this.unitType = "";
    this.isSerTaxExemp = false;
    this.serviceTax = "";
    this.description = "";
    this.readonlyHamaliGd = false;
    this.dataListReturnOfConsigneeRateDtls = "";

    //        this.commodityComboDataList = '';
    //commodityComboDataList='';
    //commodityComboDataList:LRDto = new LRDto();

    this.viewCommodityCombo = false;
    this.viewCommodity = true;
    this.selectedCommodityCombo = "";
    this.returnLrDetailsAfterBkhMemoChanged = "";
    this.isRecalculate = false;
    this.convertedBookingDate = "";
    this.convertedInvoiceDate = "";

    this.enableMemoPrintAgain = "";

    this.commodityCombo = "";
    this.destinationObject = "";

    this.responseOfcheckLrNumber = "";

    this.responseOfGetLrNumberDtls = "";

    this.deliveryAreaReturnDtls = "";

    this.returnOfConsigneeRateDtls = "";
    this.totalDiscount = 0;
    this.hamaliDd = 0;
    this.unloadingHamali = 0;
    this.selectedCommodityList = "";

    this.viewEditBtn = false;
    this.viewUpdateBtn = false;
    this.viewSaveBtn = true;
    this.viewEditDelvBtn = false;
    //        this.lrDtoForChkLrNoDtls = new LRDto();
    //        this.lrDtoForGetLrNoDtls = new LRDto();
    //        this.lrDtoYesLrFields = new LRDto();
    //        this.lrDtoCommodityCombo = new LRDto();
    this.rateMasterDto = new RateMasterDto();
    //        this.userDtoBankName = new UserDataDto();
    //        this.lrDtoCrossingChg = new LRDto();
    //        this.lrDtoCrossingChgSpecfic = new LRDto();
    //        this.userDtoNewCollMan = new UserDataDto();
    //        this.lrDtoLRDetailsAfterBkgMemoChged = new LRDto();
    //        this.checkLrNumber = new LRDto();
    //        this.deliveryAreaCreationLrDto = new LRDto();
    //        this.rateDtoReturnCreateCashmemoDtls = new RateMasterDto();
    //        this.rateDtoForUpdateLaserPrint = new RateMasterDto();
    //        this.rateDtoForRcCall = new RateMasterDto();
    //        this.rateDtoRecalMemo = new RateMasterDto();
    //        this.rateDtoForUserRateDetails = new RateMasterDto();
    //        this.lrDtoCrossingChgArea = new LRDto();
    this.reasonEditValue = "";
    this.convertedMemoDate = "";
    this.newconvertedMemoDate = "";
    $("#" + this.pageId + "cashmemoDate").val("");
    this.readonlyOtherChg = false;
    this.showVehicleNumberOption = false;
    this.showVehicleNumberOptionGD = true;
    this.modelDropDownVehicleNumber = null;
    this.selectedVehicleNumber = null;
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val("");
    $("#" + this.pageId + "ewayBillValidUpto").val("");
    this.requestDto = new RequestDto();
    this.ewayBillNo = null;
    this.clearPaymentModeDetails();
    this.isSizeExceeded = false;
    this.viewIsSizeExeededLrText=false;
  }

  clearPaymentModeDetails() {
    //        txtBankName.setVisible( false );

    this.viewByCash = false;
    this.viewByChequeNumber = false;
    this.viewByChequeDate = false;
    this.viewByConsigneeBankName = false;
    this.viewByReferenceNumber = false;
    this.viewNewBankName = false;
  }

  clearFieldsValidate() {
    swal({
      title: "Confirmation",
      text: "Sure You Want to Clear All the Fields",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.clearFields();
        this.viewMemoAlreadyGeneratedMsg = false;
        this.viewBtnRecalculate = false;
        this.viewCommodityCombo = false;
        this.viewCommodity = true;
        window.setTimeout(function () {
          $("#" + this.pageId + "lrNumber").focus();
        }, 100);
      } else {
        swal.close();
      }
    });
  }

  rcBtmValidate(type) {
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    if (
      this.isRecalculate == true &&
      this.selectedDeliveryType != null &&
      this.selectedDeliveryType != "Select Delivery Type"
    ) {
      swal({
        title: "Confirm Recalculate",
        text: "Sure U Want to Recalculate the Commodity Value",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.getRecalculateMemo(type);
        } else {
          swal.close();
        }
      });
    } else {
      swal({
        title: "Recalculation Alert ",
        text: "Please check commodity value and Collection man value",
        icon: "info",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    }
  }

  logicForGST() {
    this.enteredPaid = $("#" + this.pageId + "paid").val();
    this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();

    if (
      this.enteredPaid == null ||
      this.enteredPaid == 0 ||
      this.enteredPaid == 0.0
    ) {
      if (this.enteredGrandTotal >= this.amountLimit) {
        this.gstAmt = (this.enteredGrandTotal * 5) / 100;
        //                this.enteredIgst = '5';
        //                this.enteredCgst = '0';
        //                this.enteredSgst = '0';
        //
        //                this.enteredIgstAmt = this.gstAmt;
        //                this.enteredCgstAmt = '0';
        //                this.enteredSgstAmt = '0';
        this.gstRoundedAmt = Math.round(this.gstAmt);
        $("#" + this.pageId + "igst").val(5);
        $("#" + this.pageId + "cgst").val(0);
        $("#" + this.pageId + "sgst").val(0);
        $("#" + this.pageId + "igstAmt").val(this.gstRoundedAmt);
        $("#" + this.pageId + "cgstAmt").val(0);
        $("#" + this.pageId + "sgstAmt").val(0);
      }
    } else {
      if (this.enteredGrandTotal >= this.amountLimit) {
        this.gstAmt = (this.enteredGrandTotal * 2.5) / 100;
        //                this.enteredCgst = '2.5';
        //                this.enteredSgst = '2.5';
        //                this.enteredIgst = '0';
        //                this.enteredCgstAmt = this.gstAmt;
        //                this.enteredSgstAmt = this.gstAmt;
        //                this.enteredIgstAmt = '0';
        this.gstRoundedAmt = Math.round(this.gstAmt);
        $("#" + this.pageId + "cgst").val(2.5);
        $("#" + this.pageId + "sgst").val(2.5);
        $("#" + this.pageId + "igst").val(0);
        $("#" + this.pageId + "cgstAmt").val(this.gstRoundedAmt);
        $("#" + this.pageId + "sgstAmt").val(this.gstRoundedAmt);
        $("#" + this.pageId + "igstAmt").val(0);
      }
    }

    this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
    this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
    this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
    //        Math.round( this.enteredCgstAmt );
    //        Math.round( this.enteredSgstAmt );
    //        Math.round( this.enteredIgstAmt );

    this.gstTotalAmt =
      +this.enteredCgstAmt + +this.enteredSgstAmt + +this.enteredIgstAmt;
    $("#" + this.pageId + "gstTotal").val(this.gstTotalAmt);
    // console.log( "grand total GST>> " + +this.enteredCgstAmt + +this.enteredSgstAmt + +this.enteredIgstAmt );
    if (this.roundOffView == true) {
      this.setRoundOffValue(this.enteredGrandTotal);
    }

    //        if ( this.checkRateMemoAmt = "cashmemo check rate memo amt" ) {
    //            if ( this.rateAmt > this.enteredGrandTotal ) {
    //                swal( {
    //                    title: "Warning",
    //                    text: "Rate Master Memo Amount is Greater than CashMemo Amount",
    //                    icon: "info",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } )
    //            }
    //
    //        }

    //CHG_V-CM:18
    if (
      this.userDataDtoReturnSession.mainStation == "Bangalore" ||
      this.userDataDtoReturnSession.mainStation == "Madurai" ||
      this.userDataDtoReturnSession.mainStation == "Trivandrum"
    ) {
      // if (lrDtoCrossingChg.getId() > 0
      // && lrDtoCrossingChg.getBkgMemoAmt() > (grandTotal
      // .getValue().doubleValue())) {

      if (this.rateAmt > this.enteredGrandTotal) {
        swal({
          title: "Warning",
          text: "Rate Master Memo Amount is Greater than CashMemo Amount",
          icon: "info",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    }
  }

  setRoundOffValue(dataForRoundOffValue) {
    this.roundOffValue;
    this.diffInRoundValue;
    let result = dataForRoundOffValue % 10;
    let round = dataForRoundOffValue / 10;

    if (result > 5) {
      this.roundOffValue = (round + 1) * 10;
    } else {
      this.roundOffValue = round * 10;
    }
    this.diffInRoundValue = +this.roundOffValue + -dataForRoundOffValue;
    $("#" + this.pageId + "grandTotal").val(this.roundOffValue);
    $("#" + this.pageId + "roundOffAmt").val(this.diffInRoundValue);
  }

  //the below we used for printbtn
  printMemo() {
    //        this.router.navigate( '/generation/cashMemoPrint', { data: this.getUserValues() } )
    this.getUserValues();
    //        console.table( this.dataForPrint );
    localStorage.clear();
    localStorage.setItem("print", JSON.stringify(this.rateMasterDto));
    //        console.table(( localStorage.getItem( 'print' ) ) );
    this.viewCashMemoPrint = true;
    //        var customWindow = window.open( '/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //        var customWindow = window.open( '/angular/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //
    //        window.onafterprint = function() {
    //            customWindow.close();
    //        }
    window.addEventListener("afterprint", (event) => {
      this.viewCashMemoPrint = false;
      this.changeDetectorRef.detectChanges();
    });
    this.clearFields();
    //window.setTimeout( function() {
    //customWindow.close();
    //}, 1000 );

    //        window.onafterprint(this.viewCashMemoPrint = false;)
    //        setTimeout( function() {
    //            $( window ).one( 'mousemove', window.onafterprint );
    //        }, 1 );
    $("#" + this.pageId + "lrNumber").focus();
    //        this.router.navigate( ['/generation/bookingCashmemo'] );
    //        this.router.navigate( ['/cashmemo/generation/cashMemoPrint'], );
  }

  isPrintForData() {
    let user = localStorage.getItem("print");
    return !(user == null);
  }

  getUserValues() {
    this.rateMasterDto = new RateMasterDto();
    this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
    //        this.enteredBookingDate = $( "#bookingDate" ).val();
    this.enteredConsignee = $("#" + this.pageId + "consigneeName").val();
    this.enteredConsignor = $("#" + this.pageId + "consignorName").val();
    this.enteredDemurage = $("#" + this.pageId + "demurrage").val();
    this.enteredddHamaliLess = $("#" + this.pageId + "ddHamaliLess").val();
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    //        this.inRupees
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.enteredMemoDate = $("#" + this.pageId + "cashmemoDate").val(); //please see this once in OLD WS using >> 'dfMemoDate'
    this.enteredPaid = $("#" + this.pageId + "paid").val();
    this.enteredReceiptCharge = $("#" + this.pageId + "rcptChgBillSide").val();
    this.enteredServiceCharge = $("#" + this.pageId + "srvChgBillSide").val();
    //        the service tax and service tax amount is removed
    this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
    this.enteredInvoiceDate = $("#" + this.pageId + "invoiceDate").val();
    //the below commodity is commentted as we setting direct returnLrNumberDtls as it its readonly fields
    this.enteredCommodityName = $("#" + this.pageId + "commodity").val(); //please see this once in OLD WS using >> 'commodityName' and also see for 'commodityCombo'
    this.enteredSourceStation = $("#" + this.pageId + "sourceStation").val();
    this.enteredAgentName = $("#" + this.pageId + "agentName").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();
    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
    //        this.selectedPaymentType = $( "#paymentType" ).val();
    //        this.selectedBankName = $( "#consigneeBankName" ).val();//"ADD NEW"; means call this service 4370 addBankNames()
    //        this.enteredNewBankName = $( "#newBankName" ).val();
    //        this.enteredChequeNumber = $( "#chequeNumber" ).val();
    //        this.enteredReferenceNumber= $( "#referenceNumber" ).val();
    //                this.enteredChequeDate = $( "#chequeDate" ).val();
    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    //IN OLD WS WE HAVE 'dfCashMemoDate' AS A OBJECT IN NEW WE WE ARE USING 'cashMemoDate'
    //the below comment line removed by basha
    //                this.enteredCashMemoDate=this.cashMemoDateObject;

    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();

    if (this.selectedDeliveryMode == "GD") {
      this.selectedVehicleNumber = $(
        "#" + this.pageId + "gdVehicleNumber"
      ).val();
    } else {
      this.selectedVehicleNumber = $(
        "#" + this.pageId + "dropDownInputFieldVehicleNumber"
      ).val();
    }
    this.enteredSrmSourceStation = $(
      "#" + this.pageId + "srmSourceStation"
    ).val();
    this.enteredSrmRateId = $("#" + this.pageId + "srmRateId").val();

    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmSourceHamali = $(
      "#" + this.pageId + "srmSourceHamali"
    ).val();
    this.enteredSrmDestHamali = $(
      "#" + this.pageId + "srmDestinationHamali"
    ).val();
    this.enteredSrmServiceCharge = $(
      "#" + this.pageId + "srmServiceCharges"
    ).val();
    this.enteredSrmReceiptCharge = $(
      "#" + this.pageId + "srmReciptCharges"
    ).val();
    this.enteredSrmGcCharge = $("#" + this.pageId + "srmGcCharges").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    this.enteredUnloadingCharge = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredRoundOffAmt = $("#" + this.pageId + "roundOffAmt").val();
    this.checkBoxLetterHead = this.cbWLH;
    this.checkBoxDirectAssg = this.cbDirectAssg;
    this.selectedCommodityCombo = $("#" + this.pageId + "commodityCombo").val();
    // for just 1 day chng the flow of chennai too, not considering 5:30
    // time @Dated : 02/02/19
    // revised on 07/02/2019
    if (this.userDataDtoReturnSession.mainStation == "Chennai") {
      this.rateMasterDto.validMemoDate = true;
    } else {
      this.rateMasterDto.validMemoDate = false;
    }
    this.enteredIgst = $("#" + this.pageId + "igst").val();
    this.enteredIgstAmt = $("#" + this.pageId + "igstAmt").val();
    this.enteredCgst = $("#" + this.pageId + "cgst").val();
    this.enteredCgstAmt = $("#" + this.pageId + "cgstAmt").val();
    this.enteredSgst = $("#" + this.pageId + "sgst").val();
    this.enteredSgstAmt = $("#" + this.pageId + "sgstAmt").val();
    this.enteredGstTotal = $("#" + this.pageId + "gstTotal").val();
    //        added Tripsheet Hamali 04/12/2018
    this.enteredTripsheetHamali = $(
      "#" + this.pageId + "tripsheetHamali"
    ).val();

    this.enteredBookingDate = this.datePipe.transform(
      this.returnLrNumberDtls.bookingDate,
      "yyyy-MM-dd"
    );

    this.rateMasterDto.bookingDate = this.enteredBookingDate;
    this.rateMasterDto.consignee = this.enteredConsignee;
    //        this.rateMasterDto.memoName=this.enteredConsignee;
    this.rateMasterDto.consignor = this.enteredConsignor;
    //        this.rateMasterDto.demurrage = this.enteredDemurage;
    //        console.log(this.enteredDemurage);
    this.rateMasterDto.demurrage =
      this.enteredDemurage == null
        ? 0
        : this.enteredDemurage == ""
          ? 0
          : this.enteredDemurage;
    this.rateMasterDto.ddHamaliLess =
      this.enteredddHamaliLess == null
        ? 0
        : this.enteredddHamaliLess == ""
          ? 0
          : this.enteredddHamaliLess;
    //        console.log(this.rateMasterDto.demurrage);
    this.enteredOtherChg = $("#" + this.pageId + "others").val();
    this.rateMasterDto.otherChgAmt =
      this.enteredOtherChg == null
        ? 0
        : this.enteredOtherChg == ""
          ? 0
          : this.enteredOtherChg;
    this.rateMasterDto.gcNoteNumber = this.enteredGcNoteNo;
    this.rateMasterDto.godown = this.godown;
    //Basha
    //        this.rateMasterDto.grandTotal = this.enteredGrandTotal == null ? 0 : this.enteredGrandTotal == "" ? 0 : this.enteredGrandTotal;
    this.enteredGrandTotal =
      this.enteredGrandTotal == null
        ? 0
        : this.enteredGrandTotal == ""
          ? 0
          : this.enteredGrandTotal;
    this.rateMasterDto.grandTotal = Math.round(this.enteredGrandTotal);
    this.rateMasterDto.hamaligd =
      this.enteredHamaliGd == null
        ? 0
        : this.enteredHamaliGd == ""
          ? 0
          : this.enteredHamaliGd;
    //        this.rateMasterDto.inRupees='' // but set in any way
    this.rateMasterDto.localCharge = this.enteredLocalCharge;
    this.rateMasterDto.lorryFreight =
      this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight;
    //                this.enteredMemoDate=this.datePipe.transform( this.enteredMemoDate, "yyyy-MM-dd" );
    //        console.log(this.enteredMemoDate);
    //the below code is added  by basha
    if (this.enteredMemoDate == null || this.enteredMemoDate == "") {
      this.enteredMemoDate = this.cashMemoDateObject;
      //            console.log(this.enteredMemoDate);
      if (this.convertedMemoDate != null && this.convertedMemoDate != "") {
        //                this.convertedMemoDate=this.datePipe.transform( this.convertedMemoDate, "yyyy-MM-dd" );
        //                this.enteredMemoDate=this.convertedMemoDate;
        this.newconvertedMemoDate = this.datePipe.transform(
          this.newconvertedMemoDate,
          "yyyy-MM-dd"
        );
        this.enteredMemoDate = this.newconvertedMemoDate;
        //                console.log(this.enteredMemoDate);
      }
      this.enteredMemoDate = this.datePipe.transform(
        this.enteredMemoDate,
        "yyyy-MM-dd"
      );
      //            console.log(this.enteredMemoDate);
    } else {
      this.enteredMemoDate = this.datePipe.transform(
        this.enteredMemoDate,
        "yyyy-MM-dd"
      );
      //            console.log(this.enteredMemoDate);
    }
    /////
    if (this.showMemoDate == true) {
      this.rateMasterDto.fromDate = this.enteredMemoDate;
      //            console.log(this.enteredMemoDate);
    } else {
      //            console.log(this.enteredMemoDate);
      this.enteredMemoDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      this.rateMasterDto.fromDate = this.enteredMemoDate;
      //            console.log(this.enteredMemoDate);
      this.enteredMemoDate = "";
    }

    this.rateMasterDto.memoTime = this.memoTimeByUser; //getUserValues
    this.rateMasterDto.paid = this.enteredPaid == null ? 0 : this.enteredPaid;
    this.rateMasterDto.receiptCharges =
      this.enteredReceiptCharge == null
        ? 0
        : this.enteredReceiptCharge == ""
          ? 0
          : this.enteredReceiptCharge;
    this.rateMasterDto.invoiceNumber = this.enteredInvoiceNumber;
    this.rateMasterDto.serviceCharges =
      this.enteredServiceCharge == null
        ? 0
        : this.enteredServiceCharge == ""
          ? 0
          : this.enteredServiceCharge;
    this.enteredInvoiceDate = this.datePipe.transform(
      this.returnLrNumberDtls.invoiceDate,
      "yyyy-MM-dd"
    );
    this.rateMasterDto.invoiceDate = this.enteredInvoiceDate;
    this.rateMasterDto.commodityName = this.returnLrNumberDtls.column2;
    this.rateMasterDto.source = this.enteredSourceStation;
    this.rateMasterDto.destination = this.destinationObject;
    this.rateMasterDto.agentName = this.enteredAgentName;
    this.rateMasterDto.totalArticles =
      this.enteredNoOfArticle == null
        ? 0
        : this.enteredNoOfArticle == ""
          ? 0
          : this.enteredNoOfArticle;
    this.rateMasterDto.weight =
      this.enteredChargedWeight == null
        ? 0
        : this.enteredChargedWeight == ""
          ? 0
          : this.enteredChargedWeight;
    this.rateMasterDto.hamaliDD =
      this.enteredHamaliDd == null
        ? 0
        : this.enteredHamaliDd == ""
          ? 0
          : this.enteredHamaliDd;
    this.rateMasterDto.discount =
      this.enteredTotalDiscount == null ? 0 : this.enteredTotalDiscount;
    this.rateMasterDto.deliveryMode = this.selectedDeliveryMode;
    this.rateMasterDto.deliveryType =
      this.selectedDeliveryType == "Select Delivery Type"
        ? null
        : this.selectedDeliveryType;
    this.rateMasterDto.vehicleNo = this.selectedVehicleNumber;
    this.rateMasterDto.status = "billing";
    this.rateMasterDto.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDto.station = this.userDataDtoReturnSession.office;
    this.rateMasterDto.financialYear =
      this.userDataDtoReturnSession.financialYear;
    this.cashMemoDateObject = this.datePipe.transform(
      this.cashMemoDateObject,
      "yyyy-MM-dd"
    );
    this.rateMasterDto.memoDate = this.cashMemoDateObject;
    //        this.rateMasterDto.memoDate = "2020-02-28 00:00:00";
    this.rateMasterDto.memoTime = this.memoTime;
    this.rateMasterDto.enteredBy = this.userDataDtoReturnSession.userId;
    this.rateMasterDto.consigneeId = this.consigneeId;
    this.rateMasterDto.consignorId = this.consignorId;
    this.rateMasterDto.rateSrcStation =
      this.enteredSrmSourceStation == null
        ? "NA"
        : this.enteredSrmSourceStation;
    this.rateMasterDto.cneeRateMasterAutoId = this.enteredSrmRateId;
    this.rateMasterDto.rate =
      this.enteredSrmRate == null ? 0 : this.enteredSrmRate;
    this.rateMasterDto.rateUnit = this.rateUnit;
    this.rateMasterDto.rateSrcHamali =
      this.enteredSrmSourceHamali == null ? 0 : this.enteredSrmSourceHamali;
    this.rateMasterDto.rateDestHamali =
      this.enteredSrmDestHamali == null ? 0 : this.enteredSrmDestHamali;
    this.rateMasterDto.rateServiceChg =
      this.enteredSrmServiceCharge == null ? 0 : this.enteredSrmServiceCharge;
    this.rateMasterDto.rateReceiptChg =
      this.enteredSrmReceiptCharge == null ? 0 : this.enteredSrmReceiptCharge;
    this.rateMasterDto.rateGcChg =
      this.enteredSrmGcCharge == null ? 0 : this.enteredSrmGcCharge;
    this.rateMasterDto.ratePerUnit = this.ratePerUnit;
    this.rateMasterDto.rateDiscountOn =
      this.enteredSrmDiscount == null ? 0 : this.enteredSrmDiscount;
    this.rateMasterDto.column1 = this.message;
    this.rateMasterDto.inPrint = this.printShow;
    if (this.roundOffView == true) {
      this.rateMasterDto.roundOffAmt =
        this.enteredRoundOffAmt == "" ? 0 : this.enteredRoundOffAmt;
    } else {
      this.rateMasterDto.roundOffAmt = 0;
    }
    //basha
    //        this.rateMasterDto.unloadingChg = this.enteredUnloadingHamali == null ? 0 : this.enteredUnloadingHamali == "" ? 0 : this.enteredUnloadingHamali;
    this.rateMasterDto.unloadingChg =
      this.enteredUnloadingCharge == null
        ? 0
        : this.enteredUnloadingCharge == ""
          ? 0
          : this.enteredUnloadingCharge;
    this.rateMasterDto.withLetterHead = this.checkBoxLetterHead;
    this.rateMasterDto.memoDirectAssg = this.checkBoxDirectAssg;

    this.rateMasterDto.igst = this.enteredIgst;
    this.rateMasterDto.igstamt = this.enteredIgstAmt;
    this.rateMasterDto.cgst = this.enteredCgst;
    this.rateMasterDto.cgstamt = this.enteredCgstAmt;
    this.rateMasterDto.sgst = this.enteredSgst;
    this.rateMasterDto.sgstamt = this.enteredSgstAmt;
    this.rateMasterDto.gstAmtTotal = this.enteredGstTotal;
    this.rateMasterDto.consignorAddress =
      this.returnLrNumberDtls.consignorAdress == null
        ? " "
        : this.returnLrNumberDtls.consignorAdress;
    this.rateMasterDto.consignorState =
      this.returnLrNumberDtls.consignorState == null
        ? " "
        : this.returnLrNumberDtls.consignorState;
    this.rateMasterDto.consignorStateCode =
      this.returnLrNumberDtls.consignorStateCode == null
        ? " "
        : this.returnLrNumberDtls.consignorStateCode;
    this.rateMasterDto.gstConsgnor =
      this.returnLrNumberDtls.gstNoConsignor == null
        ? " "
        : this.returnLrNumberDtls.gstNoConsignor;
    this.rateMasterDto.consigneeAddress =
      this.returnLrNumberDtls.address == null
        ? " "
        : this.returnLrNumberDtls.address;
    this.rateMasterDto.consigneeState =
      this.returnLrNumberDtls.consigneeState == null
        ? " "
        : this.returnLrNumberDtls.consigneeState;
    this.rateMasterDto.consigneeStateCode =
      this.returnLrNumberDtls.consigneeStateCode == null
        ? " "
        : this.returnLrNumberDtls.consigneeStateCode;
    this.rateMasterDto.gstConsignee =
      this.returnLrNumberDtls.gstNoConsignee == null
        ? " "
        : this.returnLrNumberDtls.gstNoConsignee;
    this.rateMasterDto.stationCode =
      this.returnLrNumberDtls.code == null ? " " : this.returnLrNumberDtls.code;
    this.rateMasterDto.consigneeMobileNo =
      this.returnLrNumberDtls.mobileNum == null
        ? " "
        : this.returnLrNumberDtls.mobileNum;
    this.rateMasterDto.consignorMobileNo =
      this.returnLrNumberDtls.consignorMobileNo == null
        ? " "
        : this.returnLrNumberDtls.consignorMobileNo;
    this.rateMasterDto.eWayBillNo = this.returnLrNumberDtls.eWayBillNo;
    this.rateMasterDto.goodsValue = this.returnLrNumberDtls.goodsValue;
    this.rateMasterDto.tripsheetHamali =
      this.enteredTripsheetHamali == null ? 0 : this.enteredTripsheetHamali;
    //console.table( this.rateMasterDto.lorryFreight + "" + this.rateMasterDto.fromDate + "" + this.rateMasterDto.deliveryType + "" + this.rateMasterDto.rateSrcHamali + "" + this.rateMasterDto.rateDiscountOn + "" + this.rateMasterDto.unloadingChg );

    if (this.isAreaBasedLogic == true) {
      this.enteredArea = $("#" + this.pageId + "areaBillingDtlsFieldSet").val();

      if (this.enteredArea == "ADD NEW") {
        this.newArea = $(
          "#" + this.pageId + "newAreaBillingDtlsFieldSet"
        ).val();
        this.rateMasterDto.area = this.newArea;
      } else {
        this.rateMasterDto.area = this.enteredArea;
      }
    } else {
      this.enteredArea = $(
        "#" + this.pageId + "areaCollectionDtlsFieldSet"
      ).val();

      if (this.enteredArea == "ADD NEW") {
        this.newArea = $(
          "#" + this.pageId + "newAreaCollectionDtlsFieldSet"
        ).val();
        this.rateMasterDto.area = this.newArea;
      } else {
        this.rateMasterDto.area = this.enteredArea;
      }
    }

    if (this.selectedCollectionMan == "ADD NEW") {
      this.newCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
      this.rateMasterDto.collectionMan = this.newCollectionMan;
    } else {
      this.rateMasterDto.collectionMan = this.selectedCollectionMan;
    }

    if (this.selectedPaymentMode == "Cash") {
      this.rateMasterDto.cashMemoType = "Cash";
      if (
        this.selectedPaymentType != "" &&
        this.selectedPaymentType == "Cash"
      ) {
        this.rateMasterDto.paymentMode = "completedcash";
      } else if (
        this.selectedPaymentType != "" &&
        this.selectedPaymentType == "Cheque/PDC"
      ) {
        // add new bank name
        //Chg_V-CM:26
        if (
          this.selectedBankName != "" &&
          this.selectedBankName == "ADD NEW BANK"
        ) {
          this.rateMasterDto.bankName = this.enteredNewBankName;
          this.addBankNames();
        } else {
          this.rateMasterDto.bankName = this.selectedBankName;
        }
        this.rateMasterDto.paymentMode = "completedcheque";
        this.enteredChequeDate = this.datePipe.transform(
          this.enteredChequeDate,
          "yyyy-MM-dd"
        );
        this.rateMasterDto.chequeNumber = this.enteredChequeNumber;
        this.rateMasterDto.chequeDate = this.enteredChequeDate;
      } else if (this.selectedPaymentType != "" && this.selectedPaymentType == "NEFT/IMPS") {
        this.rateMasterDto.paymentMode = "completedneft";
        this.rateMasterDto.bankName = this.selectedBankName;
        this.rateMasterDto.chequeNumber = this.enteredReferenceNumber;
      } else if (this.selectedPaymentType != "" && this.selectedPaymentType == "Debit Account") {
        this.rateMasterDto.paymentMode = "completeddebitacc";
        this.rateMasterDto.chequeNumber = this.enteredReferenceNumber;
      } else if (this.selectedPaymentType != "" && this.selectedPaymentType == "UPI") {
        this.rateMasterDto.paymentMode = "completedupi";
        this.rateMasterDto.chequeNumber = this.enteredReferenceNumber;
      }
    } else if (this.selectedPaymentMode == "Outstanding") {
      this.rateMasterDto.cashMemoType = "OS";
    }

    // for just 1 day chng the flow of chennai too, not considering 5:30
    // time @Dated : 02/02/19
    // revised on 07/02/2019
    //        if ( this.validateMemoDate == "cashmemo validate memo date" ) {
    //            this.rateMasterDto.validMemoDate = true;
    //        } else {
    //            this.rateMasterDto.validMemoDate = false;
    //        }
    if (this.userDataDtoReturnSession.mainStation == "Chennai") {
      this.rateMasterDto.validMemoDate = true;
    } else {
      this.rateMasterDto.validMemoDate = false;
    }

    if (this.isRecalculate == true) {
      this.rateMasterDto.rateCommodity =
        this.selectedCommodityCombo == null
          ? "NA"
          : this.selectedCommodityCombo;
    } else {
      this.rateMasterDto.rateCommodity =
        this.enteredCommodityName == null ? "NA" : this.enteredCommodityName;
    }

    // for hamali dd logic if dd charged in lrentry
    if (
      this.returnLrNumberDtls.paid != null &&
      this.returnLrNumberDtls.paid > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          this.rateMasterDto.hamaliValidation = "Pending";
        }
      }
    } else if (
      this.returnLrNumberDtls.toPay != null &&
      this.returnLrNumberDtls.toPay > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          this.rateMasterDto.hamaliValidation = "Pending";
        }
      }
    }
    //console.table( this.returnLrNumberDtls );
    this.rateMasterDto.gujDiac = this.returnLrNumberDtls.isGujDisc;
    // /added Tripsheet Hamali 04/12/2018
    this.rateMasterDto.serviceTaxAmt = 0;
    this.rateMasterDto.isOfferLR = this.returnLrNumberDtls.isOfferLR;

    return this.rateMasterDto;
  }

  getDataMethodForBankNames() {
    this.userDtoBankName = new UserDataDto();
    this.userDtoBankName.bankId = 0;
    this.userDtoBankName.bankName = this.enteredNewBankName;
    this.userDtoBankName.office = this.userDataDtoReturnSession.mainStation;
    this.userDtoBankName.createdBy = this.userDataDtoReturnSession.userId;
    this.userDtoBankName.companyId = this.userDataDtoReturnSession.companyId;
  }
  addBankNames() {
    this.getDataMethodForBankNames();
    this.memoService
      .createNewPartyBankMasterService(this.userDtoBankName)
      .subscribe((response) => {
        if (response) {
          this.newPartyBankDetial = response;
          if (this.newPartyBankDetial.status == "persisted") {
            this.getPartyBankDetailsService();
          } else {
            swal({
              title: "Not Saved",
              text: "Error Occured While adding the new Bank Name",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          }
          this.newPartyBankDetial = "";
        }
        //                else {
        //                                                        swal( {
        //                                                            title: "Warning",
        //                                                            text: "No records found for the selected city!",
        //                                                            icon: "warning",
        //                                                            closeOnClickOutside: false,
        //                                                            closeOnEsc: false,
        //                                                        } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Saving New Bank Name",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForAddDeliveryAreaService() {
    this.deliveryAreaCreationLrDto = new LRDto();
    if (this.isAreaBasedLogic == true) {
      this.newArea = $("#" + this.pageId + "newAreaBillingDtlsFieldSet").val();
    } else {
      this.newArea = $(
        "#" + this.pageId + "newAreaCollectionDtlsFieldSet"
      ).val();
    }
    this.deliveryAreaCreationLrDto.branch =
      this.userDataDtoReturnSession.office;
    this.deliveryAreaCreationLrDto.subStations = this.newArea;
    this.deliveryAreaCreationLrDto.companyId =
      this.userDataDtoReturnSession.companyId;
    this.deliveryAreaCreationLrDto.userName =
      this.userDataDtoReturnSession.userId; // in old ws this code is not there
  }
  addDeliveryAreaService() {
    this.methodForAddDeliveryAreaService();
    this.memoService
      .createDeliveryAreaService(this.deliveryAreaCreationLrDto)
      .subscribe((response) => {
        this.deliveryAreaReturnDtls = response;
        if (this.deliveryAreaReturnDtls.status == "duplicate") {
          swal({
            title: "Duplicate Entry",
            text: "Area " + this.newArea + " Already Exist",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.branchAreaDataList.push(this.deliveryAreaReturnDtls);
          this.areaBillingTA.push(this.deliveryAreaReturnDtls);
        }
        this.deliveryAreaReturnDtls = "";
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Saving New Area Name",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }
  saveMethod() {
    if (this.isAreaBasedLogic == true) {
      this.enteredArea = $("#" + this.pageId + "areaBillingDtlsFieldSet").val();
    } else {
      this.enteredArea = $(
        "#" + this.pageId + "areaCollectionDtlsFieldSet"
      ).val();
    }
    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
    if (this.enteredArea == "ADD NEW") {
      if (this.selectedDeliveryMode == "DD") {
        this.addDeliveryAreaService();
      }
    }
    this.addNewCollectionMan();
    //        this.createMemo();
    this.calculateTheTotalAmt();
  }

  addNewCollManData() {
    this.userDtoNewCollMan = new UserDataDto();
    this.newCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
    this.userDtoNewCollMan.collectioMan = this.newCollectionMan;
    this.userDtoNewCollMan.branchId = this.userDataDtoReturnSession.mainStation;
    this.userDtoNewCollMan.office = this.userDataDtoReturnSession.office;
    this.userDtoNewCollMan.createdBy = this.userDataDtoReturnSession.userId;
    this.userDtoNewCollMan.companyId = this.userDataDtoReturnSession.companyId;
  }

  addNewCollectionMan() {
    this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
    this.newCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
    if (this.selectedCollectionMan == "ADD NEW") {
      if (
        this.newCollectionMan == null ||
        this.newCollectionMan == "N/A" ||
        this.newCollectionMan == "NA" ||
        this.newCollectionMan == ""
      ) {
        swal({
          title: "Mandatory Fields",
          text: "Please Enter Collection Man",
          icon: "warning",
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
      } else {
        this.addNewCollManData();
        this.memoService
          .createNewCollectionManService(this.userDtoNewCollMan)
          .subscribe((response) => {
            this.collectionManDataList.collectionMan.push(
              this.newCollectionMan
            );
            this.changeDetectorRef.detectChanges();
          }),
          (error) =>
            swal({
              title: "Server Error",
              text: "Problem occured while Saving New Collection Man",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }),
          () => console.log("done");
      }
    }
  }

  //creating memo
  createMemo() {
    this.rateDtoReturnCreateCashmemoDtls = new RateMasterDto();
    this.getUserValues();

    if (
      this.rateMasterDto.gcNoteNumber != null &&
      this.rateMasterDto.gcNoteNumber != "" &&
      this.rateMasterDto.deliveryType != null
    ) {
      this.memoService
        .createCashMemoService(this.rateMasterDto)
        .subscribe((response) => {
          this.rateDtoReturnCreateCashmemoDtls = response;
          //                    console.log(this.rateDtoReturnCreateCashmemoDtls.memoDate);
          if (
            this.rateDtoReturnCreateCashmemoDtls.memoNumber == null ||
            this.rateDtoReturnCreateCashmemoDtls.status == "failed"
          ) {
            swal({
              title: "Failed",
              text: "Problem occured while generating the CashMemo",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else if (
            this.rateDtoReturnCreateCashmemoDtls.memoNumber == null ||
            this.rateDtoReturnCreateCashmemoDtls.status == "statuspbm"
          ) {
            swal({
              title: "Failed",
              text: "Problem occured while updating the LR Details",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else {
            this.codEnableRights = false;
            this.rateMasterDto.memoNumber =
              this.rateDtoReturnCreateCashmemoDtls.memoNumber;
            this.newPartyViewPrint =
              this.rateDtoReturnCreateCashmemoDtls.isNewParty;
            this.rateMasterDto.isNewParty =
              this.rateDtoReturnCreateCashmemoDtls.isNewParty;

            if (
              this.selectedDeliveryMode == "GD" &&
              this.ewayBillNo != null &&
              this.ewayBillNo != "0" &&
              this.ewayBillNo != "NA" &&
              this.ewayBillNo != ""
            ) {
              //validate ewaybill date, if expired then go for DC else consolidated
              if (this.rateDtoReturnCreateCashmemoDtls.isExpired) {
                console.log("DC generation");
                this.generateDCForLREwaybill("new");
              } else {
                console.log("Consolidate generation");
                swal({
                  title: "Consolidate Ewaybill",
                  text:
                    "Generate Consolidate Ewaybill for the Memo - " +
                    this.rateMasterDto.memoNumber,
                  icon: "info",
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                  buttons: ["No", "Yes"],
                }).then((yesBtn) => {
                  if (yesBtn) {
                    this.generateConsolidateEwaybill(
                      "new",
                      this.rateMasterDto.memoNumber
                    );
                  } else {
                    this.printMemoMethod();
                  }
                });
              }
            } else {
              this.printMemoMethod();
            }
          }
          this.changeDetectorRef.detectChanges();
        }),
        (error) =>
          swal({
            title: "Server Error",
            text: "Problem occured while generating the CashMemo",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }),
        () => console.log("done");
    }
  }

  printMemoMethod() {
    swal({
      title: "Print CashMemo",
      text:
        "Cashmemo " +
        this.rateMasterDto.memoNumber +
        " Amount " +
        this.rateMasterDto.grandTotal +
        " Sure U Want to Print this Cashmemo",
      icon: "info",
      closeOnClickOutside: true,
      closeOnEsc: true,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.rateMasterDto.memoNumber =
          this.rateDtoReturnCreateCashmemoDtls.memoNumber;
        this.rateMasterDto.memoDate =
          this.rateDtoReturnCreateCashmemoDtls.memoDate;
        this.rateMasterDto.withOutSlash =
          this.rateDtoReturnCreateCashmemoDtls.withOutSlash;
        this.rateMasterDto.mainStation =
          this.userDataDtoReturnSession.mainStation;
        this.rateMasterDto.consolidatedEWayBillNo = this.memoConsolidatedEWB;
        this.rateMasterDto.isNewParty = this.newPartyViewPrint;
        //                            if(this.inLaserPrint ==true){

        //                            }
        //console.table( this.dataForPrint );
        localStorage.clear();
        localStorage.setItem(
          "printAfterSave",
          JSON.stringify(this.rateMasterDto)
        );
        //console.table(( localStorage.getItem( 'printAfterSave' ) ) );
        this.viewCashMemoPrint = true;
        //                                var customWindow = window.open( '/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
        //                                var customWindow = window.open( '/angular/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
        //
        window.onafterprint = function () {
          //                                    customWindow.close();
        };
        $("#" + this.pageId + "lrNumber").val("");
        $("#" + this.pageId + "lrNumber").focus();
        this.setCashmemoLaserPrint(this.rateMasterDto);
        this.clearFields();
        window.addEventListener("afterprint", (event) => {
          this.viewCashMemoPrint = false;
          this.changeDetectorRef.detectChanges();
        });
      } else {
        $("#" + this.pageId + "lrNumber").val("");
        $("#" + this.pageId + "lrNumber").focus();
        this.clearFields();
      }

      // blocked sms to consignor due
      // to GST, will activate once
      // consignor merge is done
      // @ dated : 01/09/2017

      // send DD Lr sms to
      // consignor

      // activated on 02/11/2017
      // again on 11/11/2017
      // if (lrDtoCrossingChg
      // .getIsDoorDeliv()) {
      // sendMemoSMS();
      // }
      //

      //mode some is here need to add it later because now we are only working Save
    });
  }

  calculateDiscOnTotal() {
    this.subAmountCalc = 0;
    this.hamaliAmt = 0;
    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.enteredServiceCharge = $("#" + this.pageId + "srvChgBillSide").val();
    this.enteredReceiptCharge = $("#" + this.pageId + "rcptChgBillSide").val();
    this.enteredDemurage = $("#" + this.pageId + "demurrage").val();
    this.enteredddHamaliLess = $("#" + this.pageId + "ddHamaliLess").val();
    this.enteredUnloadingHamali = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();
    //        this.enteredSrmRate = $( "#srmRate" ).val();
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredOtherChg = $("#" + this.pageId + "others").val();
    this.discnt = 0;

    //        if ( this.calcGrandTotal = "cashmemo calc gT with hamaliDd" ) {
    //            this.hamaliAmt = this.hamaliDd == null ? 0 : this.hamaliDd;
    //        } else {
    //            this.hamaliAmt = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
    //        }
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      //          this.hamaliAmt = this.enteredHamaliDd == null ? 0 : this.enteredHamaliDd;
      //CHG_V-CM:10
      // this.hamaliAmt = this.hamaliDd == null ? 0 : this.hamaliDd;
      this.hamaliAmt =
        (this.hamaliDd == null ? 0 : this.hamaliDd) +
        (this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd);
    } else {
      this.hamaliAmt = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
    }

    if (this.viewUnloadingHamali == true) {
      this.enteredUnloadingHamali = this.unloadingHamali;
    } else {
      this.enteredUnloadingHamali = 0;
    }

    console.log(this.hamaliDd);
    this.subAmountCalc =
      +(this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight) +
      +(this.enteredServiceCharge == null ? 0 : this.enteredServiceCharge) +
      +(this.enteredReceiptCharge == null ? 0 : this.enteredReceiptCharge) +
      +(this.enteredDemurage == null ? 0 : this.enteredDemurage) +
      +this.hamaliAmt +
      +(this.enteredUnloadingHamali == null ? 0 : this.enteredUnloadingHamali) +
      +(this.enteredOtherChg == null ? 0 : this.enteredOtherChg);
    //console.log( this.enteredUnloadingHamali );
    //console.log( "inside of calculateDiscOnTotal()" );
    //        this.subAmountCalc = +this.enteredLorryFreight + +this.enteredServiceCharge + +this.enteredReceiptCharge + +this.enteredDemurage + +this.hamaliAmt + +this.enteredUnloadingHamali + -this.totalDiscount;
    //console.log( this.enteredLorryFreight + " << >>" + this.enteredServiceCharge + " << >>" + this.enteredReceiptCharge + " << >>" + this.enteredDemurage + " << >>" + //this.hamaliAmt + " << >>" + this.enteredUnloadingHamali + " << >>" + this.totalDiscount );
    //                console.log( +this.enteredLorryFreight + +this.enteredServiceCharge + +this.enteredReceiptCharge + +this.enteredDemurage + +this.hamaliAmt + //+this.enteredUnloadingHamali + -this.totalDiscount );
    //        console.log( this.subAmountCalc );
    //basha
    //        $( "#grandTotal" ).val( this.subAmountCalc );
    $("#" + this.pageId + "grandTotal").val(Math.round(this.subAmountCalc));

    this.calcDisOne = +this.enteredGrandTotal + +this.enteredDemurage;
    //        console.log( this.calcDisOne );
    this.calcDisTwo = +this.calcDisOne * +this.srmRate;
    //        console.log( this.calcDisTwo );
    this.calcDisThree = +this.calcDisTwo / 100;
    //        console.log( this.calcDisThree );
    this.calcDisFour = +this.calcDisThree + -this.enteredLocalCharge;
    //        console.log( this.calcDisFour );
    this.discnt =
      +this.enteredGrandTotal +
      (+this.enteredDemurage * +this.srmRate) / 100 +
      -this.enteredLocalCharge;
    //        console.log( this.discnt );

    //CHG-V-CM:01

    //        if ( this.calcDisFour < 0 ) {
    //            //            $( "#totalDiscount" ).val( 0 );
    //            this.totalDiscount = 0;
    //            this.discnt = 0;
    //        } else {
    //            //            $( "#totalDiscount" ).val( this.calcDisFour );
    //            this.totalDiscount = Math.round( this.calcDisFour );
    //        }

    this.totalDiscount = Math.round(this.calcDisFour);

    this.subAmountCalc = +this.subAmountCalc + -this.discnt;
    //                console.log( this.subAmountCalc );
    //basha
    //        $( "#grandTotal" ).val( this.subAmountCalc );

    console.log(this.hamaliDd);
    $("#" + this.pageId + "grandTotal").val(Math.round(this.subAmountCalc));
  }

  calculateDisc() {
    this.subAmountCalc = 0;
    this.hamaliAmt = 0;
    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.enteredServiceCharge = $("#" + this.pageId + "srvChgBillSide").val();
    this.enteredReceiptCharge = $("#" + this.pageId + "rcptChgBillSide").val();
    this.enteredDemurage = $("#" + this.pageId + "demurrage").val();
    this.enteredddHamaliLess = $("#" + this.pageId + "ddHamaliLess").val();
    this.enteredUnloadingHamali = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredOtherChg = $("#" + this.pageId + "others").val();
    // alert( this.enteredOtherChg );
    // console.log( this.enteredLorryFreight + " << >> " + this.enteredServiceCharge + "<< >>" + this.enteredReceiptCharge + "<< >>" + //this.enteredDemurage + "<< >>" + //this.enteredUnloadingHamali + "<< >>" + this.totalDiscount + "<< >>" + this.hamaliAmt );
    //        if ( this.calcGrandTotal = "cashmemo calc gT with hamaliDd" ) {
    //            this.hamaliAmt = this.hamaliDd == null ? 0 : this.hamaliDd;
    //        } else {
    //            this.hamaliAmt = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
    //        }

    //mdu chg for dd per art consideration @12/12/2023
    // if (this.userDataDtoReturnSession.mainStation != null
    //     && this.userDataDtoReturnSession.mainStation == "Chennai") {
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      (this.userDataDtoReturnSession.mainStation == "Chennai" ||
        this.userDataDtoReturnSession.mainStation == "Madurai")
    ) {
      //            this.hamaliAmt = this.enteredHamaliDd == null ? 0 : this.enteredHamaliDd;
      //CHG_V-CM:10
      //this.hamaliAmt = this.hamaliDd == null ? 0 : this.hamaliDd;
      this.hamaliAmt =
        +(this.hamaliDd == null ? 0 : this.hamaliDd) +
        +(this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd);
    } else {
      this.hamaliAmt = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
    }
    if (this.viewUnloadingHamali == true) {
      this.enteredUnloadingHamali = this.unloadingHamali;
    } else {
      this.enteredUnloadingHamali = 0;
    }
    //      console.log( this.enteredLorryFreight + " << >>" + this.enteredServiceCharge + " << >>" + this.enteredReceiptCharge + " << >>" + this.enteredDemurage + " << >>" + this.hamaliAmt + " << >>" + this.enteredUnloadingHamali + " << >>" + this.totalDiscount );
    this.subAmountCalc =
      +(this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight) +
      +(this.enteredServiceCharge == null ? 0 : this.enteredServiceCharge) +
      +(this.enteredReceiptCharge == null ? 0 : this.enteredReceiptCharge) +
      +(this.enteredDemurage == null ? 0 : this.enteredDemurage) +
      +this.hamaliAmt +
      +(this.enteredUnloadingHamali == null ? 0 : this.enteredUnloadingHamali) +
      +(this.enteredOtherChg == null ? 0 : this.enteredOtherChg) +
      -this.totalDiscount;
    //console.log(+( this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight ) + +( this.enteredServiceCharge == null ? 0 : this.enteredServiceCharge ) + +( this.enteredReceiptCharge == null ? 0 : this.enteredReceiptCharge ) + +( this.enteredDemurage == null ? 0 : this.enteredDemurage ) + +this.hamaliAmt + +( this.enteredUnloadingHamali == null ? 0 : this.enteredUnloadingHamali ) + -this.totalDiscount);

    //                console.log( this.totalDiscount+"<< >>"+this.subAmountCalc );
    //                console.log( "inside of calculateDisc()" );
    //        this.subAmountCalc = +this.enteredLorryFreight + +this.enteredServiceCharge + +this.enteredReceiptCharge + +this.enteredDemurage + +this.hamaliAmt + +this.enteredUnloadingHamali + -this.totalDiscount;
    //        console.log( this.enteredLorryFreight + " << >>" + this.enteredServiceCharge + " << >>" + this.enteredReceiptCharge + " << >>" + this.enteredDemurage + " << >>" + this.hamaliAmt + " << >>" + this.enteredUnloadingHamali + " << >>" + this.totalDiscount );
    //                console.log(this.totalDiscount);
    //                        console.log( +this.enteredLorryFreight + +this.enteredServiceCharge + +this.enteredReceiptCharge + +this.enteredDemurage + +this.hamaliAmt + +this.enteredUnloadingHamali + -this.totalDiscount );
    //                console.log( this.subAmountCalc );
    //basha
    //        $( "#grandTotal" ).val( this.subAmountCalc );
    console.log(this.hamaliDd);
    $("#" + this.pageId + "grandTotal").val(Math.round(this.subAmountCalc));
    this.subAmountCalc = 0;
  }

  subTotalWithDemurage() {
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    if (this.unit == null || this.unit == "") {
      this.calculateDisc();
    } else {
      if (this.unit == "Rate") {
        this.calculateDisc();
      } else if (this.unit == "Discount") {
        if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
          this.calculateDisc();
        } else if (this.srmDiscount == "onTotal") {
          this.calculateDiscOnTotal();
        }
      }
    }
  }
  afterPrint() {
    this.viewCashMemoPrint = false;
  }

  toggleChkBoxForLetterHeader(event) {
    if (event.target.checked) {
      this.cbWLH = true;
    } else {
      this.cbWLH = false;
    }
  }

  toggleChkBoxForDirectAssg(event) {
    if (event.target.checked) {
      this.cbDirectAssg = true;
    } else {
      this.cbDirectAssg = false;
    }
  }

  MethodForSetCashmemoLaserPrint(dataForSetCashMemo) {
    this.rateDtoForUpdateLaserPrint = new RateMasterDto();
    this.rateDtoForUpdateLaserPrint = dataForSetCashMemo;
    this.rateDtoForUpdateLaserPrint.duplicateMemo = true;
    this.rateDtoForUpdateLaserPrint.mode = "duplicate";
    this.rateDtoForUpdateLaserPrint.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  setCashmemoLaserPrint(dataForSetCashMemoLaserPrint) {
    this.MethodForSetCashmemoLaserPrint(dataForSetCashMemoLaserPrint);
    this.memoService
      .setCashMemoLaserPrintService(this.rateDtoForUpdateLaserPrint)
      .subscribe((response) => {
        // swal( {
        //   title: "Record Not Found",
        // text: "No records found for Entered Lr Number Details",
        //icon: "warning",
        //closeOnClickOutside: false,
        //closeOnEsc: false,
        //} );
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Updating Laser Print Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  rightMethodForUsers() {
    //CHG_V-CM:11
    if (
      this.userDataDtoReturnSession.hamaliOtherEditBlockDestMemo != null &&
      this.userDataDtoReturnSession.hamaliOtherEditBlockDestMemo
    ) {
      this.isHamaliOtherEditBlock = true;
    }
    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
      //console.log( this.userDataDtoReturnSession );
      for (
        let i = 0;
        i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
        i++
      ) {
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Cash Memo Block UnFinished Consignee"
        ) {
          this.blockConsignee = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "CashMemo RoundOffView"
        ) {
          this.roundOffView = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Laser Admin"
        ) {
          this.laserMemoAdmin = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Transit"
        ) {
          this.isMemoTransit = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "adminRateMaster"
        ) {
          this.isAdminRate = true;
        }

        // for Bng and Mdu only consider adminrate master hence implement the
        // below logic, this is to avoid user based rate issue which happened
        // for farid bng user
        // @Dated : 06/08/2018
        //if (this.setAdminRate=="cashmemo enable admin rate") {
        //          this.isAdminRate = true;
        //}

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "cashMemoDiscountEditBlock"
        ) {
          this.cashMemoDiscountEditBlock = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Transit Admin"
        ) {
          this.isTransitMemoEnableBtn = true;
          this.viewTransitMemoEnableBtn = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Duplicate Admin"
        ) {
          this.viewEnableDuplicateMemoBtn = true;
          this.isDupMemoEnableBtn = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "bankLrEnableDestMemo"
        ) {
          this.viewEnableBankLr = true;
        }

        if (
          this.userDataDtoReturnSession.office == "Binnymills" ||
          this.userDataDtoReturnSession.office == "Konditope"
        ) {
          if (
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            "Memo Print Again"
          ) {
            this.memoPrintAgain = true;
          }
        } else {
          this.memoPrintAgain = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "codLrEnable"
        ) {
          this.viewCodLrEnable = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "selfLrEnableDestMemo"
        ) {
          this.viewEnableSelfLr = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "blockLrEnableDestMemo"
        ) {
          this.viewEnableBlockLr = true;
        }
        //CHG_V-CM:11
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "hamaliOtherEditEnableDestMemo"
        ) {
          this.viewHamaliEditEnable = true;
        }

        //CHG_V-CM:14
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Enable CC Copy DestMemo"
        ) {
          this.viewCCCopyEditEnable = true;
        }

        //CHG_V-CM:16
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Enable Demurage LR DestMemo"
        ) {
          this.viewDemurageLREnable = true;
        }

        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Edit"
        ) {
          this.asPerRightShowEditBtn = true;
          this.asPerRightShowUpdateBtn = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Edit Date"
        ) {
          this.showMemoDate = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo User Rights"
        ) {
          this.asPerRightShowEditDelvBtn = true;
          this.asPerRightShowUpdateBtn = true;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "Memo Delete"
        ) {
          this.asPerRightShowDeleteBtn = true;
        }

        //                                                                if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
        //                                                                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "" ) {
        //
        //
        //                                                                    }
      }
    }

    //CHG_V-CM:18
    console.log("Admin rate?");
    console.log(this.userDataDtoReturnSession.mainStation);
    if (
      this.userDataDtoReturnSession.mainStation == "Bangalore" ||
      this.userDataDtoReturnSession.mainStation == "Madurai" ||
      this.userDataDtoReturnSession.mainStation == "Trivandrum"
    ) {
      this.isAdminRate = true;
    }
  }

  deliveryTypeMethod() {
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (
      this.selectedDeliveryType != "Select Delivery Type" &&
      this.enteredLrNumber != "" &&
      this.enteredLrNumber != null
    ) {
      this.clearRateFields();
      if (this.isAdminRate == true) {
        this.getRecalculateMemo("list");
      } else {
        this.getRcCall("list");
      }
    }
  }
  clearRateFields() {
    $("#" + this.pageId + "srmDestinationHamali").val("");
    $("#" + this.pageId + "srmGcCharges").val("");
    $("#" + this.pageId + "srmRate").val("");
    $("#" + this.pageId + "srmReciptCharges").val("");
    $("#" + this.pageId + "srmServiceCharges").val("");
    $("#" + this.pageId + "srmSourceHamali").val("");
    $("#" + this.pageId + "srmSourceStation").val("");
    $("#" + this.pageId + "ddHamaliLess").val("");
    $("#" + this.pageId + "srmPerUnit").val("");
    $("#" + this.pageId + "srmNetRateHamaliLess").val("");
    $("#" + this.pageId + "srmFov").val("");
    $("#" + this.pageId + "srmOtherChg").val("");
    $("#" + this.pageId + "srmRateId").val("0");
    this.unitType = "";
    this.unit = "";
    //srm_RatePerc-need to work
    $("#" + this.pageId + "srmDiscount").val("");
    this.srmDiscount = "";
    this.srmRate = "";
    this.srmRatePercentage = "";
    this.srmGcCharge = "";
    this.srmSourceHamali = "";
    this.srmDestinationHamali = "";
    this.srmReciptCharges = "";
    this.srmServiceCharges = "";
    this.rateUnit = null;
    this.ratePerUnit = null;
    this.fovLessAmt = 0;
    this.enteredSrmSourceStation = "";
    this.enteredSrmRateId = 0;
    this.enteredSrmRate = "";
    this.enteredSrmSourceHamali = "";
    this.enteredSrmDestHamali = "";
    this.enteredSrmServiceCharge = "";
    this.enteredSrmReceiptCharge = "";
    this.enteredSrmGcCharge = "";
    this.enteredSrmDiscount = "";
    this.setUnloadingChgParty = "";
    this.setUnldgCommodities = "";
    this.setUnldgSource = "";
    this.crossingChg = "";
    this.unloadHamali = "";
    this.totalCrossingChg = "";
    this.subStationGDChg = "";
    this.hamaliGDAmt = "";
    this.dataListOfCrossingChg = "";
    this.unldgCommoditiesSplit = [];
    this.unldgSourceSplit = [];
    this.dataListOfCrossingChgSpecfic = "";
    this.dataListOfCrossingChgArea = "";
    this.returnLrDetailsAfterBkhMemoChanged = "";
    this.returnOfConsigneeRateDtls = "";
    this.dataListReturnOfConsigneeRateDtls = "";
    this.gstAmt = 0;
    this.gstRoundedAmt = 0;
    this.enteredCgstAmt = "";
    this.enteredSgstAmt = "";
    this.enteredIgstAmt = "";
    this.gstTotalAmt = 0;
    this.enteredTotalDiscount = "";
    //        this.enteredHamaliDd = '';
    this.hamaliDd = 0;
    this.enteredHamaliGd = "";

    this.roundOffValue = 0;
    this.diffInRoundValue = 0;
    //        this.checkRateMemoAmt = null;
    this.rateAmt = 0;
    this.enteredDemurage = "";
    this.enteredLocalCharge = "";
    this.enteredOtherChg = "";
    this.enteredReceiptCharge = "";
    this.enteredServiceCharge = "";
    this.enteredUnloadingCharge = "";
    this.enteredIgst = "";
    this.enteredCgst = "";
    this.enteredSgst = "";
    this.enteredGstTotal = "";
    this.enteredTripsheetHamali = "";
    this.discnt = "";
    this.calcDisOne = "";
    this.calcDisTwo = "";
    this.calcDisThree = "";
    this.calcDisFour = "";

    this.calcGrandTotal = null;
    this.unit = "";
    this.setUnloadingChg = 0;
    this.setUnit = null;

    this.hamaliValues = null;
    this.dataListOfCrossingChg = "";
    this.isFixedRate = null;
    this.isLCChg = null;
    this.isBCChg = null;
    this.isAoc = null;
    this.isInsChg = null;
    this.isDD = null;
    this.isOthers = null;
    this.isHamaliChg = null;
    this.hamaliSrcCustom = false;
    this.customHamaliSrcChg = null;
    this.calcDiscnt = 0;
    this.otherDiscChg = 0;
    this.ddHamaliLessAmt = 0;

    this.unitType = "";
    this.serviceTax = "";
    this.readonlyHamaliGd = false;
    this.totalDiscount = 0;
    this.hamaliDd = 0;
    this.unloadingHamali = 0;
  }
  getMethodForRecalculateMemo(type) {
    this.rateDtoRecalMemo = new RateMasterDto();
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    this.enteredAgentName = $("#" + this.pageId + "agentName").val();
    if (type == "button") {
      this.enteredCommodityName = $("#" + this.pageId + "commodityCombo").val();
    } else {
      this.enteredCommodityName = this.returnLrNumberDtls.column2;
    }
    //alert( 'getMethodForRecalculateMemo : ' + this.enteredCommodityName );

    this.enteredConsignee = $("#" + this.pageId + "consigneeName").val();

    this.rateDtoRecalMemo.mode = "lessRate";
    this.rateDtoRecalMemo.gcNoteNumber = this.enteredGcNoteNo;
    this.rateDtoRecalMemo.source = this.enteredAgentName;
    this.rateDtoRecalMemo.consigneeId = this.consigneeId;
    this.rateDtoRecalMemo.enteredBy = this.userDataDtoReturnSession.userId;
    this.rateDtoRecalMemo.fixedBy = type;
    this.rateDtoRecalMemo.consigneeName = this.enteredConsignee;
    this.rateDtoRecalMemo.companyId = this.userDataDtoReturnSession.companyId;
    this.rateDtoRecalMemo.commodityName = this.enteredCommodityName;
  }

  getRecalculateMemo(type) {
    this.getMethodForRecalculateMemo(type);
    //alert( 'getRecalculateMemo : ' + this.enteredCommodityName );
    this.masterReadService
      .recalculateBkgMemoAfterAdminRateService(this.rateDtoRecalMemo)
      .subscribe((response) => {
        this.returnOfConsigneeRateDtls = response;
        this.getLRDetailsAfterBkgMemoChged(type);

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Recalculate after booking memo",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForGetLRDetailsAfterBkgMemoChged() {
    this.lrDtoLRDetailsAfterBkgMemoChged = new LRDto();
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    this.selectedCommodityCombo = $("#" + this.pageId + "commodityCombo").val();
    this.lrDtoLRDetailsAfterBkgMemoChged.lrNumber = this.enteredGcNoteNo;
    this.lrDtoLRDetailsAfterBkgMemoChged.subCommodity =
      this.selectedCommodityCombo;
    this.lrDtoLRDetailsAfterBkgMemoChged.userName =
      this.userDataDtoReturnSession.userId;
    this.lrDtoLRDetailsAfterBkgMemoChged.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  getLRDetailsAfterBkgMemoChged(type) {
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    this.methodForGetLRDetailsAfterBkgMemoChged();
    this.masterReadService
      .getLRDetailsAfterBkgMemoChgedService(
        this.lrDtoLRDetailsAfterBkgMemoChged
      )
      .subscribe((response) => {
        this.returnLrDetailsAfterBkhMemoChanged = response;
        //                                                                    console.log( this.returnLrDetailsAfterBkhMemoChanged.discountAmount +"<< >>"+ this.srmRate);
        this.totalDiscount =
          this.returnLrDetailsAfterBkhMemoChanged.discountAmount == null
            ? 0
            : this.returnLrDetailsAfterBkhMemoChanged.discountAmount;
        //                    $( "#totalDiscount" ).val( this.returnLrDetailsAfterBkhMemoChanged.discountAmount == null ? 0 : this.returnLrDetailsAfterBkhMemoChanged.discountAmount );
        this.getRcCall(type);

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting LR details after booking memo",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getRcCall(type) {
    this.getCompServTax();
    this.rateDtoForRcCall = new RateMasterDto();
    this.rateDtoForRcCall = this.getUserRateDetails(type);
    this.getConsigneeRateDetails(this.rateDtoForRcCall);
    if (
      (this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Bangalore") ||
      (this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Madurai") ||
      (this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Trivandrum")
    ) {
      this.readonlyHamaliDd = true;
    }
  }

  getCompServTax() {
    if (this.isSerTaxExemp == true) {
      this.serviceTax = 0;
    } else {
      this.serviceTax = this.userDataDtoReturnSession.servTax;
    }
  }

  getUserRateDetails(type) {
    this.rateDtoForUserRateDetails = new RateMasterDto();
    this.enteredConsignee = $("#" + this.pageId + "consigneeName").val();
    this.enteredSourceStation = $("#" + this.pageId + "sourceStation").val();
    this.enteredAgentName = $("#" + this.pageId + "agentName").val();
    this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    //        if ( type == 'button' ) {
    //            this.enteredCommodityName = $( "#commodityCombo" ).val();
    //        } else {
    //            this.enteredCommodityName = $( "#commodity" ).val();
    //        }

    if (type == "button") {
      this.enteredCommodityName = $("#" + this.pageId + "commodityCombo").val();
    } else {
      this.enteredCommodityName = this.returnLrNumberDtls.column2;
    }
    //alert( 'getUserRateDetails : ' + this.enteredCommodityName );
    this.enteredGcNoteNo = $("#" + this.pageId + "lrNumber").val();
    this.enteredBookingDate = $("#" + this.pageId + "bookingDate").val();

    //        this.enteredBookingDate = this.datePipe.transform( this.enteredBookingDate, "yyyy-MM-dd" );
    this.enteredBookingDate = this.datePipe.transform(
      this.returnLrNumberDtls.bookingDate,
      "yyyy-MM-dd"
    );

    this.rateDtoForUserRateDetails.mode == "cashmemoform";
    this.rateDtoForUserRateDetails.consigneeName = this.enteredConsignee;
    this.rateDtoForUserRateDetails.consigneeId = this.consigneeId;
    this.rateDtoForUserRateDetails.description = this.description;
    this.rateDtoForUserRateDetails.source = this.enteredAgentName;
    // this.rateDtoForUserRateDetails.source=this.enteredSourceStation;
    this.rateDtoForUserRateDetails.deliveryType = this.selectedDeliveryType;
    this.rateDtoForUserRateDetails.city =
      this.userDataDtoReturnSession.mainStation;
    this.rateDtoForUserRateDetails.collectionMan = this.selectedCollectionMan;
    this.rateDtoForUserRateDetails.commodityName = this.enteredCommodityName;

    if (this.isAdminRate == true) {
      this.rateDtoForUserRateDetails.rateType = "Admin";
    } else {
      this.rateDtoForUserRateDetails.rateType = "Party";
    }

    this.rateDtoForUserRateDetails.gcNoteNumber = this.enteredGcNoteNo;
    this.rateDtoForUserRateDetails.bookingDate = this.enteredBookingDate;
    this.rateDtoForUserRateDetails.companyId =
      this.userDataDtoReturnSession.companyId;

    return this.rateDtoForUserRateDetails;
  }

  getConsigneeRateDetails(dataforConsigneeRateDetails) {
    // if (this.userDataDtoReturnSession.mainStation == 'Chennai') {
    //     if (this.isAreaBasedLogic == true) {
    //         this.getOtherChgDetailsList($("#" + this.pageId + "areaBillingDtlsFieldSet").val());
    //     } else {
    //         this.getOtherChgDetailsList($("#" + this.pageId + "areaCollectionDtlsFieldSet").val());
    //     }
    // }

    this.readonlyHamaliGd = false;
    this.masterReadService
      .getConsigneeRateDetailsForCashMemoService(dataforConsigneeRateDetails)
      .subscribe((response) => {
        this.dataListReturnOfConsigneeRateDtls = [];
        this.dataListReturnOfConsigneeRateDtls = response;
        console.log("dataListReturnOfConsigneeRateDtls");
        console.log(this.dataListReturnOfConsigneeRateDtls);
        // for guj 50% dis, no discount should calculate @Dated
        // :
        // 17/09/2018
        if (this.returnLrNumberDtls.isGujDisc == true) {
          this.dataListReturnOfConsigneeRateDtls = "";
        }
        if (this.dataListReturnOfConsigneeRateDtls == "") {
          //CHG-V-CM:04
          this.setEditCompnayValues(this.dataListReturnOfConsigneeRateDtls);
          $("#" + this.pageId + "deliveryType").focus();
          this.readonlyOtherChg = false;
          //Chg_V-CM:27
          this.readonlyUnloadingHamali = false;
          if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            if (this.isAreaBasedLogic == true) {
              this.getOtherChgDetailsList(
                $("#" + this.pageId + "areaBillingDtlsFieldSet").val()
              );
            } else {
              this.getOtherChgDetailsList(
                $("#" + this.pageId + "areaCollectionDtlsFieldSet").val()
              );
            }
          }
        } else {
          if (this.isAreaBasedLogic == true) {
            this.readonlyHamaliGd = true;
          } else {
            this.readonlyHamaliGd = false;
          }
          //CHG_V-CM:11
          if (this.isHamaliOtherEditBlock == true) {
            if (this.returnLrNumberDtls.enableHamaliEditForDestMemo == false) {
              this.readonlyOtherChg = true;
              //Chg_V-CM:27
              this.readonlyUnloadingHamali = true;
            }
          }
          this.setFields(this.dataListReturnOfConsigneeRateDtls);
          $("#" + this.pageId + "deliveryType").focus();
        }

        //this.fovChargeNewLogic(this.dataListReturnOfConsigneeRateDtls);

        if (this.srmRate == "0.0" || this.srmRate == "0") {
          this.calculateDisc();
        } else if (this.unit != null || this.unit != "") {
          if (this.unit == "Rate") {
            this.calculateDisc();
          } else if (this.unit == "Discount") {
            if (
              this.srmDiscount == "onLr" ||
              this.srmDiscount == "onBaseFrgt"
            ) {
              this.calculateDisc();
            } else if (this.srmDiscount == "onTotal") {
              this.calculateDiscOnTotal();
            }
          }
        }
        this.logicForGST();
        //console.log(this.dataListReturnOfConsigneeRateDtls.length);

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while getting Consignee Rate Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  setEditCompnayValues(dataListReturnOfConsigneeRateDtls) {
    //CHG_V-CM:09
    console.log("demurage flow - editcomp");
    console.log(this.userDataDtoReturnSession.demurrageExmpDays);
    console.log(this.userDataDtoReturnSession.demurrageChg);
    console.log(this.userDataDtoReturnSession.demurrageUnit);
    console.log(this.returnLrNumberDtls.diffDays);
    console.log(this.returnLrNumberDtls.demurageExmp);

    $("#" + this.pageId + "demurrage").val(0);
    if (
      this.returnLrNumberDtls.demurageExmp != null &&
      this.returnLrNumberDtls.demurageExmp == false
    ) {
      if (
        this.userDataDtoReturnSession.demurrageExmpDays != null &&
        this.userDataDtoReturnSession.demurrageExmpDays > 0 &&
        this.returnLrNumberDtls.diffDays >
        this.userDataDtoReturnSession.demurrageExmpDays
      ) {
        let demurageChg =
          this.userDataDtoReturnSession.demurrageChg == null
            ? 0
            : this.userDataDtoReturnSession.demurrageChg;
        let demurrageUnit =
          this.userDataDtoReturnSession.demurrageUnit == null
            ? 0
            : this.userDataDtoReturnSession.demurrageUnit;
        let diffDays =
          this.returnLrNumberDtls.diffDays -
          this.userDataDtoReturnSession.demurrageExmpDays;
        let lrChgWgt =
          this.returnLrNumberDtls.chargedWeight == null
            ? 0
            : this.returnLrNumberDtls.chargedWeight;
        let lrActWgt =
          this.returnLrNumberDtls.actualWeight == null
            ? 0
            : this.returnLrNumberDtls.actualWeight;
        let calculatedDemurage = 0;
        if (demurrageUnit == "Charged Weight") {
          calculatedDemurage = lrChgWgt * demurageChg * diffDays;
        } else if (demurrageUnit == "Actual Weight") {
          calculatedDemurage = lrActWgt * demurageChg * diffDays;
        }
        $("#" + this.pageId + "demurrage").val(
          calculatedDemurage == null ? 0 : Math.round(calculatedDemurage)
        );
      }
    }

    $("#" + this.pageId + "localCharge").val(0);
    //        $( "#hamaliDd" ).val( 0 );
    this.hamaliDd = 0;
    $("#" + this.pageId + "hamaliGd").val(0);

    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    if (this.returnLrNumberDtls.isGujDisc == true) {
      this.totalDiscount = Math.round(
        this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight / 2
      );
      //            console.log(this.totalDiscount);
      //                        $( "#totalDiscount" ).val( this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight / 2 );
    } else {
      this.totalDiscount = 0;
      $("#" + this.pageId + "totalDiscount").val(0);
    }

    //        $( "#srmRate" ).val( "0" );
    this.srmRate = "0";
    //        $( "#srmGcCharges" ).val( "0" );
    this.srmGcCharge = "0";
    //        $( "#srmSourceHamali" ).val( "0" );
    this.srmSourceHamali = "0";
    //        $( "#srmDestinationHamali" ).val( "0" );
    this.srmDestinationHamali = "0";
    //        $( "#srmReciptCharges" ).val( "0" );
    this.srmReciptCharges = "0";
    //        $( "#srmServiceCharges" ).val( "0" );
    this.srmServiceCharges = "0";
    //        $( "#srmRatePercentage" ).val( "0" );
    this.srmRatePercentage = "0";
    $("#" + this.pageId + "srmSourceStation").val("N/A");
    $("#" + this.pageId + "srmRateId").val("0");

    //$("#" + this.pageId + "srvChgBillSide").val(this.compServChg); //logic changed due to tdm, chk in calculateSubStationGDChg

    //CHG_V-CM:22
    if (!this.loadingHamaliOptionFlow) {
      $("#" + this.pageId + "srvChgBillSide").val(this.compServChg);
    }

    $("#" + this.pageId + "rcptChgBillSide").val(this.compRecpChg);

    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      //CHG-V-CM:04
      this.logicForGDDD(dataListReturnOfConsigneeRateDtls);
      //CHG_V-CM:07
      if (this.selectedDeliveryType == "Door Delivery") {
        console.log("inside");
        console.log(this.hamaliddPerArt);
        console.log(this.returnLrNumberDtls.totalArticles);
        $("#" + this.pageId + "hamaliDd").val(
          this.hamaliddPerArt * this.returnLrNumberDtls.totalArticles
        );
        this.hamaliDd =
          this.hamaliddPerArt * this.returnLrNumberDtls.totalArticles;
      }
    } else if (
      this.userDataDtoReturnSession.mainStation != null &&
      (this.userDataDtoReturnSession.mainStation == "Madurai" ||
        this.userDataDtoReturnSession.mainStation == "Trivandrum")
    ) {
      //CHG_V-CM:13
      this.logicForGDDDDefaultValue(dataListReturnOfConsigneeRateDtls);
      //mdu chg for dd per art consideration @12/12/2023
      if (this.selectedDeliveryType == "Door Delivery") {
        console.log("inside");
        console.log(this.hamaliddPerArt);
        console.log(this.returnLrNumberDtls.totalArticles);
        $("#" + this.pageId + "hamaliDd").val(
          this.hamaliddPerArt * this.returnLrNumberDtls.totalArticles
        );
        this.hamaliDd =
          this.hamaliddPerArt * this.returnLrNumberDtls.totalArticles;
      }
    }

    if (
      this.userDataDtoReturnSession.isLRHamaliEffect != null &&
      this.userDataDtoReturnSession.isLRHamaliEffect == true
    ) {
      this.setHamaliValues();
    }
    // Get Hamali Rate for BNG substations
    //if (this.chkHamaliRate != null && this.chkHamaliRate=="cashmemo getHamaliRate") {}

    //mdu chg for dd per art consideration @12/12/2023
    // if (this.userDataDtoReturnSession.office != null && this.userDataDtoReturnSession.office == "Bangalore" || this.userDataDtoReturnSession.office != null && this.userDataDtoReturnSession.office == "Madurai" || this.userDataDtoReturnSession.office != null && this.userDataDtoReturnSession.office == "Trivandrum") {
    if (
      (this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Bangalore") ||
      (this.userDataDtoReturnSession.office != null &&
        this.userDataDtoReturnSession.office == "Trivandrum")
    ) {
      if (
        this.selectedDeliveryType != null &&
        this.selectedDeliveryType == "Door Delivery"
      ) {
        this.setCrossingChg();
      } else {
        this.calculateDisc();
        this.logicForGST();
      }
    } else {
      this.calculateDisc();
      this.logicForGST();
    }

    //CHG_V-CM:21
    if (this.loadingHamaliOptionFlow) {
      this.logicForLoadingHamaliValue();
    }
  }

  collectionModeBlock() {
    //        if ( this.userDataDtoReturnSession.office != null
    //                && this.userDataDtoReturnSession.office == "Binnymills"
    //                && this.userDataDtoReturnSession.role != "Administrator" ) {
    //                this.viewCashOption == false;
    //                //the pp code is removed
    //            }
    console.log("collectionModeBlock");
    if (
      this.userDataDtoReturnSession.office != null &&
      this.userDataDtoReturnSession.office == "Binnymills" &&
      this.userDataDtoReturnSession.role != "Administrator"
    ) {
      this.viewCashOption == false;
      console.log("collectionModeBlock - 1");
      //the pp code is removed
    }
  }

  methodForGetGoodsType() {
    this.rateDtoForGoodsType.mode = "LrEntry";
    this.rateDtoForGoodsType.companyId =
      this.userDataDtoReturnSession.companyId;
  }
  //it is used in popup of GoodsType
  getGoodsType() {
    this.methodForGetGoodsType();
    this.masterReadService
      .getCommmodityMasterDetailsService(this.rateDtoForGoodsType)
      .subscribe((response) => {
        this.goodsTypeResponse = response;
        //console.log( this.goodsTypeResponse );
        this.dataListOfCommodity = this.goodsTypeResponse.subCommodity;

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while loading Commodity Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getValuesForEditCompanyDetails() {
    this.userDtoCmpyDtls.companyId = this.userDataDtoReturnSession.companyId;
    this.userDtoCmpyDtls.mainStation =
      this.userDataDtoReturnSession.mainStation;
    this.userDtoCmpyDtls.city = this.userDataDtoReturnSession.mainStation;
  }
  getEditCompValues() {
    this.getValuesForEditCompanyDetails();
    this.masterReadService
      .getEditCompany(this.userDtoCmpyDtls)
      .subscribe((response) => {
        if (response) {
          this.userDataDtoGet = response;
          //console.table( this.userDataDtoGet );
          this.compServChg = this.userDataDtoGet.scChg;
          this.compRecpChg = this.userDataDtoGet.rcChg;
          this.hamaliddPerArt = this.userDataDtoGet.hamaliddPerArt;
          console.log("edit company");
          console.log(this.hamaliddPerArt);
          console.log(this.userDataDtoGet.hamaliddPerArt);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Server Error While Getting Edit Company Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  getMethodForgetMessageInPrint() {
    this.lrDtoMsg.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoMsg.mainstation = this.userDataDtoReturnSession.mainStation;
  }
  getMessageInPrint() {
    this.getMethodForgetMessageInPrint();
    this.masterReadService
      .getMsgDispInCMService(this.lrDtoMsg)
      .subscribe((response) => {
        if (response) {
          this.messageList = response;
          this.message = this.messageList.message;
          this.printShow = this.messageList.isLrInCp;
        }
        //                else {
        //                    swal( {
        //                        title: "Warning",
        //                        text: "No records found for the selected city!",
        //                        icon: "warning",
        //                        closeOnClickOutside: false,
        //                        closeOnEsc: false,
        //                    } );

        //                }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Server Error While Getting Message In Print Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  //CHG-V-CM:04
  logicForGDDD(dataForSetFields) {
    this.viewUnloadingHamali = true;
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    if (this.selectedDeliveryType == "Godown") {
      //            $( "#unloadingHamali" ).val( this.enteredHamaliGd );
      this.unloadingHamali = this.enteredHamaliGd;
      this.viewHamaliDd = false;
      //            $( "#hamaliDd" ).val( 0 );
      this.hamaliDd = 0;

      //CHG_V-CM:10
      // this.viewHamaliGd = false;
      this.viewHamaliGd = true;
      $("#" + this.pageId + "hamaliGd").val(0);
      // calculate unloading hamali for gd also which is set in edit
      // company
      // @Senthil Sir Dated : 22/01/2020
      this.setUnloadingChg = 0;

      //CHG-V-CM:04
      if (dataForSetFields == "") {
        this.setUnit = this.userDataDtoReturnSession.unitGD;
        this.setUnloadingChgParty =
          this.userDataDtoReturnSession.unloadingHamaliChgGD;
      } else {
        this.setUnit = dataForSetFields.unitGd;
        this.setUnloadingChgParty = dataForSetFields.unloadingChgGd;
      }
      if (this.setUnit != null && this.setUnit == "Article") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.totalArticles;
      } else if (this.setUnit != null && this.setUnit == "Actual Weight") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.actualWeight;
      } else if (this.setUnit != null && this.setUnit == "Charged Weight") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.chargedWeight;
      }
      //            $( "#unloadingHamali" ).val( this.setUnloadingChg );
      this.unloadingHamali = Math.round(this.setUnloadingChg);
      this.setUnloadingChg = 0;
    } else if (this.selectedDeliveryType == "Door Delivery") {
      this.viewHamaliDd = true;
      this.viewHamaliGd = false;
      $("#" + this.pageId + "hamaliGd").val(0);
      this.setUnloadingChg = 0;
      //CHG-V-CM:04
      if (dataForSetFields == "") {
        this.setUnit = this.userDataDtoReturnSession.unit;
      } else {
        this.setUnit = dataForSetFields.unitDd;
      }
      //            console.log(this.userDataDtoReturnSession);
      //            console.log(this.returnLrNumberDtls);
      this.setUnloadingChgParty = 0;
      this.setUnldgCommodities = this.returnLrNumberDtls.unldgCommodities;
      this.setUnldgSource = this.returnLrNumberDtls.unldgSource;
      if (this.setUnldgCommodities != null) {
        this.unldgCommoditiesSplit = this.setUnldgCommodities.split("#");
        this.unldgSourceSplit = this.setUnldgSource.split("#");

        for (let i = 0; i < this.unldgCommoditiesSplit.length; i++) {
          if (this.unldgSourceSplit[i] == "All") {
            if (this.unldgCommoditiesSplit[i] == "All") {
              this.setUnloadingChgParty =
                this.returnLrNumberDtls.unldgChg.split("#")[i];

              this.setUnit = this.returnLrNumberDtls.unldgChg.split("#")[i];
              break;
            } else if (
              this.unldgCommoditiesSplit[i] ==
              this.returnLrNumberDtls.mainCommodity
            ) {
              this.setUnloadingChgParty =
                this.returnLrNumberDtls.unldgChg.split("#")[i];
              this.setUnit = this.returnLrNumberDtls.unldgChg.split("#")[i];
              break;
            }
          } else if (
            this.unldgSourceSplit[i] == "this.returnLrNumberDtls.mainstation"
          ) {
            if (this.unldgCommoditiesSplit[i] == "All") {
              this.setUnloadingChgParty =
                this.returnLrNumberDtls.unldgChg.split("#")[i];

              this.setUnit = this.returnLrNumberDtls.unldgChg.split("#")[i];
              break;
            } else if (
              this.unldgCommoditiesSplit[i] ==
              this.returnLrNumberDtls.mainCommodity
            ) {
              this.setUnloadingChgParty =
                this.returnLrNumberDtls.unldgChg.split("#")[i];
              this.setUnit = this.returnLrNumberDtls.unldgChg.split("#")[i];
              break;
            }
          } else {
            //CHG-V-CM:04
            if (dataForSetFields == "") {
              this.setUnit = this.userDataDtoReturnSession.unit;
              this.setUnloadingChgParty =
                this.userDataDtoReturnSession.unloadingHamaliChg;
            } else {
              this.setUnit = dataForSetFields.unitDd;
              this.setUnloadingChgParty = dataForSetFields.unloadingChgDd;
            }
          }
        }
      } else {
        //CHG-V-CM:04
        if (dataForSetFields == "") {
          this.setUnit = this.userDataDtoReturnSession.unit;
          this.setUnloadingChgParty =
            this.userDataDtoReturnSession.unloadingHamaliChg;
        } else {
          this.setUnit = dataForSetFields.unitDd;
          this.setUnloadingChgParty = dataForSetFields.unloadingChgDd;
        }
      }

      if (this.setUnit != null && this.setUnit == "Article") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.totalArticles;
      } else if (this.setUnit != null && this.setUnit == "Actual Weight") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.actualWeight;
      } else if (this.setUnit != null && this.setUnit == "Charged Weight") {
        this.setUnloadingChg =
          +this.setUnloadingChgParty * +this.returnLrNumberDtls.chargedWeight;
      }
      //            $( "#unloadingHamali" ).val( this.setUnloadingChg );
      this.unloadingHamali = Math.round(this.setUnloadingChg);
      //            this.setUnloadingChg = 0;
      //            this.setUnloadingChgParty=null;
      //            this.setUnit=null;
      //            this.setUnldgCommodities = null;
      //            this.setUnldgSource = null;
      // CHG-V-CM:03
      if (
        this.userDataDtoReturnSession.mainStation != null &&
        this.userDataDtoReturnSession.mainStation == "Chennai"
      ) {
        if (this.returnLrNumberDtls.isRateEffect == true) {
          this.unloadingHamali = 0;
        }
      }
    }
  }

  //CHG_V-CM:13
  logicForGDDDDefaultValue(dataForSetFields) {
    console.log("logicForGDDDDefaultValue");
    this.viewUnloadingHamali = true;
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    this.setUnloadingChg = 0;
    if (this.selectedDeliveryType == "Godown") {
      $("#" + this.pageId + "hamaliGd").val(0);
      if (dataForSetFields == "") {
        this.setUnit = this.userDataDtoReturnSession.unitGD;
        this.setUnloadingChgParty =
          this.userDataDtoReturnSession.unloadingHamaliChgGD;
      } else {
        this.setUnit = dataForSetFields.unitGd;
        this.setUnloadingChgParty = dataForSetFields.unloadingChgGd;
      }
    } else if (this.selectedDeliveryType == "Door Delivery") {
      if (dataForSetFields == "") {
        this.setUnit = this.userDataDtoReturnSession.unit;
        this.setUnloadingChgParty =
          this.userDataDtoReturnSession.unloadingHamaliChg;
      } else {
        this.setUnit = dataForSetFields.unitDd;
        this.setUnloadingChgParty = dataForSetFields.unloadingChgDd;
      }
    }
    console.log(dataForSetFields.unloadingChgGd);
    console.log(dataForSetFields.unloadingChgDd);

    if (this.setUnit != null && this.setUnit == "Article") {
      this.setUnloadingChg =
        +this.setUnloadingChgParty * +this.returnLrNumberDtls.totalArticles;
    } else if (this.setUnit != null && this.setUnit == "Actual Weight") {
      this.setUnloadingChg =
        +this.setUnloadingChgParty * +this.returnLrNumberDtls.actualWeight;
    } else if (this.setUnit != null && this.setUnit == "Charged Weight") {
      this.setUnloadingChg =
        +this.setUnloadingChgParty * +this.returnLrNumberDtls.chargedWeight;
    }
    this.unloadingHamali = Math.round(this.setUnloadingChg);
    this.setUnloadingChg = 0;
  }

  setHamaliValues() {
    this.viewTripsheetHamali = false;
    if (
      this.returnLrNumberDtls.paid != null &&
      this.returnLrNumberDtls.paid > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        //if(this.hamaliValues="cashmemo set hamali values"){}
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
          $("#" + this.pageId + "hamaliDd").val(0);
          this.readonlyHamaliDd = true;
          this.viewTripsheetHamali = true;
          this.readonlyTripsheetHamali = false;
        } else {
          $("#" + this.pageId + "hamaliGd").val(0);
          this.readonlyHamaliGd = true;
          this.viewTripsheetHamali = false;
          this.readonlyTripsheetHamali = true;
        }
      }
      if (
        this.returnLrNumberDtls.hamaliRC != null &&
        this.returnLrNumberDtls.hamaliRC > 0
      ) {
        $("#" + this.pageId + "rcptChgBillSide").val(0);
        this.readonlyRcptChgBillSide = true;
      }
      if (
        this.returnLrNumberDtls.hamaliSC != null &&
        this.returnLrNumberDtls.hamaliSC > 0
      ) {
        $("#" + this.pageId + "srvChgBillSide").val(0);
        this.readonlySrvChgBillSide = true;
      }
    } else if (
      this.returnLrNumberDtls.toPay != null &&
      this.returnLrNumberDtls.toPay > 0
    ) {
      if (
        this.returnLrNumberDtls.ddAmt != null &&
        this.returnLrNumberDtls.ddAmt > 0
      ) {
        //if(this.hamaliValues="cashmemo set hamali values"){}
        if (
          this.userDataDtoReturnSession.mainStation != null &&
          this.userDataDtoReturnSession.mainStation == "Chennai"
        ) {
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
          $("#" + this.pageId + "hamaliDd").val(0);
          this.readonlyHamaliDd = true;
          this.viewTripsheetHamali = true;
          this.readonlyTripsheetHamali = false;
        } else {
          $("#" + this.pageId + "hamaliGd").val(0);
          this.readonlyHamaliGd = true;
          this.viewTripsheetHamali = false;
          this.readonlyTripsheetHamali = true;
        }
      }
    }
  }

  methodForSetCrossingChg() {
    this.lrDtoCrossingChg = new LRDto();
    this.lrDtoCrossingChg.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoCrossingChg.mainstation = this.returnLrNumberDtls.destination;
    this.lrDtoCrossingChg.subStations = this.returnLrNumberDtls.areaOfDelivery;
  }
  setCrossingChg() {
    this.methodForSetCrossingChg();
    this.masterReadService
      .getCrossingChgService(this.lrDtoCrossingChg)
      .subscribe((response) => {
        this.dataListOfCrossingChg = response;
        //                console.log( this.dataListOfCrossingChg );
        this.calculateCrossingChg(this.dataListOfCrossingChg);
        if (this.isAreaBasedLogic == true) {
          if (this.isMemoAlreadyGenerated == true) {
            this.calculateSubStationGDChg(this.dataListOfCrossingChg);
          }
        }
        this.calculateDisc();
        this.logicForGST();
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while loading Charge Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  calculateCrossingChg(dataListForCalculateCrossingChg) {
    //        console.log( dataListForCalculateCrossingChg.crossingChg+ "<< >>"+this.crossingChg);
    this.crossingChg = 0;
    this.unloadHamali = 0;
    this.totalCrossingChg = 0;
    if (dataListForCalculateCrossingChg.size > 0) {
      //For Crossing
      if (
        dataListForCalculateCrossingChg.unitCrossChg != null &&
        dataListForCalculateCrossingChg.unitCrossChg == "Actual Weight"
      ) {
        this.crossingChg =
          +this.crossingChg +
          +(
            +(dataListForCalculateCrossingChg.crossingChg == null
              ? 0
              : dataListForCalculateCrossingChg.crossingChg) *
            +this.returnLrNumberDtls.actualWeight
          );
        //                console.log( this.crossingChg + "<< >>" + dataListForCalculateCrossingChg.crossingChg + "<< >>" + this.returnLrNumberDtls.actualWeight );
        //                console.log( this.crossingChg + +( ( dataListForCalculateCrossingChg.crossingChg == null ? 0 : dataListForCalculateCrossingChg.crossingChg ) * this.returnLrNumberDtls.actualWeight ) );
      } else if (
        dataListForCalculateCrossingChg.unitCrossChg != null &&
        dataListForCalculateCrossingChg.unitCrossChg == "Charged Weight"
      ) {
        this.crossingChg =
          +this.crossingChg +
          +(
            +(dataListForCalculateCrossingChg.crossingChg == null
              ? 0
              : dataListForCalculateCrossingChg.crossingChg) *
            +this.returnLrNumberDtls.chargedWeight
          );
        //                console.log( this.crossingChg + "<< >>" + dataListForCalculateCrossingChg.crossingChg + "<< >>" + this.returnLrNumberDtls.chargedWeight );
        //                console.log( this.crossingChg + +( ( dataListForCalculateCrossingChg.crossingChg == null ? 0 : dataListForCalculateCrossingChg.crossingChg ) * this.returnLrNumberDtls.chargedWeight ) );
      } else if (
        dataListForCalculateCrossingChg.unitCrossChg != null &&
        dataListForCalculateCrossingChg.unitCrossChg == "Article"
      ) {
        this.crossingChg =
          +this.crossingChg +
          +(
            +(dataListForCalculateCrossingChg.crossingChg == null
              ? 0
              : dataListForCalculateCrossingChg.crossingChg) *
            +this.returnLrNumberDtls.totalArticles
          );
        //                console.log( this.crossingChg + "<< >>" + dataListForCalculateCrossingChg.crossingChg + "<< >>" + this.returnLrNumberDtls.totalArticles );
        //                console.log( this.crossingChg + +( ( dataListForCalculateCrossingChg.crossingChg == null ? 0 : dataListForCalculateCrossingChg.crossingChg ) * this.returnLrNumberDtls.totalArticles ) );
      }

      // For Hamali
      if (
        dataListForCalculateCrossingChg.unitUnloadHamaliChg == "Actual Weight"
      ) {
        this.unloadHamali =
          +this.unloadHamali +
          +(
            +(dataListForCalculateCrossingChg.unloadHamaliChg == null
              ? 0
              : dataListForCalculateCrossingChg.unloadHamaliChg) *
            +this.returnLrNumberDtls.actualWeight
          );
        //                console.log("on line 3821");
        //                console.log(this.unloadHamali);
      } else if (
        dataListForCalculateCrossingChg.unitUnloadHamaliChg == "Charged Weight"
      ) {
        this.unloadHamali =
          +this.unloadHamali +
          +(
            +(dataListForCalculateCrossingChg.unloadHamaliChg == null
              ? 0
              : dataListForCalculateCrossingChg.unloadHamaliChg) *
            +this.returnLrNumberDtls.chargedWeight
          );
      } else if (
        dataListForCalculateCrossingChg.unitUnloadHamaliChg == "Article"
      ) {
        this.unloadHamali =
          +this.unloadHamali +
          +(
            +(dataListForCalculateCrossingChg.unloadHamaliChg == null
              ? 0
              : dataListForCalculateCrossingChg.unloadHamaliChg) *
            +this.returnLrNumberDtls.totalArticles
          );
      }
    }

    this.totalCrossingChg = +this.crossingChg + +this.unloadHamali;
    this.totalCrossingChg = this.crossingChg;
    //Basha
    this.hamaliDd =
      this.totalCrossingChg == null ? 0 : Math.round(this.totalCrossingChg);
    $("#" + this.pageId + "hamaliDd").val(
      this.totalCrossingChg == null ? 0 : Math.round(this.totalCrossingChg)
    );
    //       this.hamaliDd = ( this.totalCrossingChg == null ? 0 : this.totalCrossingChg.toFixed( 1 ) );
    //        $( "#hamaliDd" ).val( this.totalCrossingChg == null ? 0 : this.totalCrossingChg.toFixed( 1 ) );
  }

  // @15/07/2019
  calculateSubStationGDChg(dataListForCalculateSubStationGDChg) {
    this.subStationGDChg = 0;
    this.readonlySrvChgBillSide = false;

    if (dataListForCalculateSubStationGDChg.size > 0) {
      if (
        dataListForCalculateSubStationGDChg.subStationGDChgUnit != null &&
        dataListForCalculateSubStationGDChg.subStationGDChgUnit ==
        "Actual Weight"
      ) {
        this.subStationGDChg =
          +this.subStationGDChg +
          +(
            +(dataListForCalculateSubStationGDChg.subStationGDChg == null
              ? 0
              : dataListForCalculateSubStationGDChg.subStationGDChg) *
            +this.returnLrNumberDtls.actualWeight
          );
      } else if (
        dataListForCalculateSubStationGDChg.subStationGDChgUnit != null &&
        dataListForCalculateSubStationGDChg.subStationGDChgUnit ==
        "Charged Weight"
      ) {
        this.subStationGDChg =
          +this.subStationGDChg +
          +(
            +(dataListForCalculateSubStationGDChg.subStationGDChg == null
              ? 0
              : dataListForCalculateSubStationGDChg.subStationGDChg) *
            +this.returnLrNumberDtls.chargedWeight
          );
      } else if (
        dataListForCalculateSubStationGDChg.subStationGDChgUnit != null &&
        dataListForCalculateSubStationGDChg.subStationGDChgUnit == "Article"
      ) {
        this.subStationGDChg =
          +this.subStationGDChg +
          +(
            +(dataListForCalculateSubStationGDChg.subStationGDChg == null
              ? 0
              : dataListForCalculateSubStationGDChg.subStationGDChg) *
            +this.returnLrNumberDtls.totalArticles
          );
      }
      this.readonlyHamaliGd = true;
    } else {
      this.readonlyHamaliGd = false;

      //CHG_V-CM:22
      if (this.loadingHamaliOptionFlow) {
        if (
          this.userDataDtoReturnSession.unitServiceChgOtherArea != null &&
          this.userDataDtoReturnSession.unitServiceChgOtherArea ==
          "Actual Weight"
        ) {
          this.subStationGDChg =
            +this.subStationGDChg +
            +(
              +(this.userDataDtoReturnSession.serviceChgOtherArea == null
                ? 0
                : this.userDataDtoReturnSession.serviceChgOtherArea) *
              +this.returnLrNumberDtls.actualWeight
            );
        } else if (
          this.userDataDtoReturnSession.unitServiceChgOtherArea != null &&
          this.userDataDtoReturnSession.unitServiceChgOtherArea ==
          "Charged Weight"
        ) {
          this.subStationGDChg =
            +this.subStationGDChg +
            +(
              +(this.userDataDtoReturnSession.serviceChgOtherArea == null
                ? 0
                : this.userDataDtoReturnSession.serviceChgOtherArea) *
              +this.returnLrNumberDtls.chargedWeight
            );
        } else if (
          this.userDataDtoReturnSession.unitServiceChgOtherArea != null &&
          this.userDataDtoReturnSession.unitServiceChgOtherArea == "Article"
        ) {
          this.subStationGDChg =
            +this.subStationGDChg +
            +(
              +(this.userDataDtoReturnSession.serviceChgOtherArea == null
                ? 0
                : this.userDataDtoReturnSession.serviceChgOtherArea) *
              +this.returnLrNumberDtls.totalArticles
            );
        }
      }
    }
    //Basha
    //        $( "#hamaliGd" ).val( this.subStationGDChg == null ? 0 : this.subStationGDChg.toFixed( 1 ) );

    //CHG_V-CM:22
    if (this.loadingHamaliOptionFlow) {
      $("#" + this.pageId + "srvChgBillSide").val(
        this.subStationGDChg == null ? 0 : Math.round(this.subStationGDChg)
      );
      if (this.subStationGDChg != null && this.subStationGDChg > 0) {
        this.readonlySrvChgBillSide = true;
      }
    } else {
      $("#" + this.pageId + "hamaliGd").val(
        this.subStationGDChg == null ? 0 : Math.round(this.subStationGDChg)
      );
    }
    console.log("inside calculateSubStationGDChg");
    console.log(this.subStationGDChg);
    console.log(this.returnLrNumberDtls.ddAmt);
    // if hamali DD entered in LR Entry then Hamali GD should be zero
    // @Sadakath : 23/09/2019
    if (
      this.returnLrNumberDtls.ddAmt != null &&
      this.returnLrNumberDtls.ddAmt > 0
    ) {
      console.log(this.returnLrNumberDtls.ddAmt);
      $("#" + this.pageId + "hamaliGd").val(0);
    }
  }
  setFields(dataForSetFields) {
    this.hamaliDDRateLessStatus = false;
    //console.log( dataForSetFields );
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    for (let i = 0; i < dataForSetFields.length; i++) {
      console.log("setFields");
      //            console.log( dataForSetFields[i].rate );
      //            console.log( Math.round( this.hamaliGDAmt * this.enteredNoOfArticle ) );
      //if (this.setValuesInFields != null && this.setValuesInFields=="cashmemo enable set values in field") {}
      if (
        (this.userDataDtoReturnSession.office != null &&
          this.userDataDtoReturnSession.office == "Bangalore") ||
        (this.userDataDtoReturnSession.office != null &&
          this.userDataDtoReturnSession.office == "Madurai") ||
        (this.userDataDtoReturnSession.office != null &&
          this.userDataDtoReturnSession.office == "Trivandrum")
      ) {
        console.log(dataForSetFields[i].hamaliDestination);
        if (
          this.selectedDeliveryType != null &&
          this.selectedDeliveryType == "Door Delivery"
        ) {
          this.hamaliGDAmt =
            dataForSetFields[i].hamaliDestination == null
              ? 0
              : dataForSetFields[i].hamaliDestination;
          //                    console.log( dataForSetFields[i].hamaliDestination );
          $("#" + this.pageId + "hamaliGd").val(
            Math.round(this.hamaliGDAmt * this.enteredNoOfArticle)
          );

          // if party rate is set in adminratemaster and hamail gd is
          // zero in master then hamailgd field should be editable
          // Sadakath @11/10/2019
          //gd not editable if rate is set even Destination Hamali is 0, hence commeting this @Sadakath 26/04/2023
          // if (this.hamaliGDAmt == 0) {
          //     this.readonlyHamaliGd = false;
          // }
          this.setCrossingChgSpecific();
        } else {
          $("#" + this.pageId + "hamaliGd").val(0);
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
        }
      } else {
        if (
          this.selectedDeliveryType != null &&
          this.selectedDeliveryType == "Door Delivery"
        ) {
          this.hamaliGDAmt =
            dataForSetFields[i].hamaliDestination == null
              ? 0
              : dataForSetFields[i].hamaliDestination;
          //Basha
          //                    $( "#hamaliGd" ).val(( this.hamaliGDAmt * this.enteredNoOfArticle ).toFixed() );
          $("#" + this.pageId + "hamaliGd").val(
            Math.round(this.hamaliGDAmt * this.enteredNoOfArticle)
          );
          //                    $( "#hamaliDd" ).val( Math.round( dataForSetFields[i].hamaliDestination * this.enteredNoOfArticle ) );
          //                    this.hamaliDd = Math.round( dataForSetFields[i].hamaliDestination * this.enteredNoOfArticle );
          //                    this.hamaliDd = ( this.hamaliGDAmt * this.enteredNoOfArticle ).toFixed( 1 );

          this.hamaliDd = Math.round(
            this.hamaliGDAmt * this.enteredNoOfArticle
          );
          //CHG_V-CM:08
          if (dataForSetFields[i].hamaliDDRateLessStatus) {
            this.hamaliDDRateLessStatus =
              dataForSetFields[i].hamaliDDRateLessStatus;
            this.hamaliDd =
              this.hamaliddPerArt * this.returnLrNumberDtls.totalArticles;
            $("#" + this.pageId + "ddHamaliLess").val(this.hamaliDd);
          }
        } else {
          $("#" + this.pageId + "hamaliGd").val(0);
          //                    $( "#hamaliDd" ).val( 0 );
          this.hamaliDd = 0;
          $("#" + this.pageId + "ddHamaliLess").val(this.hamaliDd);
          this.hamaliDDRateLessStatus = false;
        }
      }
      $("#" + this.pageId + "localCharge").val(0);
      //console.log( "Hai" );
      //console.log( dataForSetFields[i].receiptCharges );
      $("#" + this.pageId + "rcptChgBillSide").val(
        dataForSetFields[i].receiptCharges
      );
      //CHG_V-CM:22
      if (!this.loadingHamaliOptionFlow) {
        console.log("service chg-1 - setFields");
        $("#" + this.pageId + "srvChgBillSide").val(
          dataForSetFields[i].serviceCharges
        );
      } else {
        console.log("service chg-2 - setFields");
        let serviceChgCustomized = 0;
        if (
          dataForSetFields[i].serviceChargesUnit != null &&
          dataForSetFields[i].serviceChargesUnit == "Actual Weight"
        ) {
          serviceChgCustomized =
            +serviceChgCustomized +
            +(
              +(dataForSetFields[i].serviceCharges == null
                ? 0
                : dataForSetFields[i].serviceCharges) *
              +this.returnLrNumberDtls.actualWeight
            );
        } else if (
          dataForSetFields[i].serviceChargesUnit != null &&
          dataForSetFields[i].serviceChargesUnit == "Charged Weight"
        ) {
          serviceChgCustomized =
            +serviceChgCustomized +
            +(
              +(dataForSetFields[i].serviceCharges == null
                ? 0
                : dataForSetFields[i].serviceCharges) *
              +this.returnLrNumberDtls.chargedWeight
            );
        } else if (
          dataForSetFields[i].serviceChargesUnit != null &&
          dataForSetFields[i].serviceChargesUnit == "Article"
        ) {
          serviceChgCustomized =
            +serviceChgCustomized +
            +(
              +(dataForSetFields[i].serviceCharges == null
                ? 0
                : dataForSetFields[i].serviceCharges) *
              +this.returnLrNumberDtls.totalArticles
            );
        }

        $("#" + this.pageId + "srvChgBillSide").val(
          serviceChgCustomized == null ? 0 : Math.round(serviceChgCustomized)
        );
      }
      console.log("inside ok");

      //CHG_V-CM:09
      console.log("demurage flow - setFields");
      console.log(this.userDataDtoReturnSession.demurrageExmpDays);
      console.log(this.userDataDtoReturnSession.demurrageChg);
      console.log(this.userDataDtoReturnSession.demurrageUnit);
      console.log(this.returnLrNumberDtls.diffDays);
      console.log(this.returnLrNumberDtls.demurageExmp);

      $("#" + this.pageId + "demurrage").val(0);
      if (
        this.returnLrNumberDtls.demurageExmp != null &&
        this.returnLrNumberDtls.demurageExmp == false
      ) {
        if (
          this.userDataDtoReturnSession.demurrageExmpDays != null &&
          this.userDataDtoReturnSession.demurrageExmpDays > 0 &&
          this.returnLrNumberDtls.diffDays >
          this.userDataDtoReturnSession.demurrageExmpDays
        ) {
          let demurageChg =
            this.userDataDtoReturnSession.demurrageChg == null
              ? 0
              : this.userDataDtoReturnSession.demurrageChg;
          let demurrageUnit =
            this.userDataDtoReturnSession.demurrageUnit == null
              ? 0
              : this.userDataDtoReturnSession.demurrageUnit;
          let diffDays =
            this.returnLrNumberDtls.diffDays -
            this.userDataDtoReturnSession.demurrageExmpDays;
          let lrChgWgt =
            this.returnLrNumberDtls.chargedWeight == null
              ? 0
              : this.returnLrNumberDtls.chargedWeight;
          let lrActWgt =
            this.returnLrNumberDtls.actualWeight == null
              ? 0
              : this.returnLrNumberDtls.actualWeight;
          let calculatedDemurage = 0;
          if (demurrageUnit == "Charged Weight") {
            calculatedDemurage = lrChgWgt * demurageChg * diffDays;
          } else if (demurrageUnit == "Actual Weight") {
            calculatedDemurage = lrActWgt * demurageChg * diffDays;
          }
          $("#" + this.pageId + "demurrage").val(
            calculatedDemurage == null ? 0 : Math.round(calculatedDemurage)
          );
        }
      }

      this.unitType = dataForSetFields[i].perUnit;
      this.unit = dataForSetFields[i].unit;
      this.isFixedRate = dataForSetFields[i].isFixedRate;

      this.isLCChg = dataForSetFields[i].lcChg;
      this.isBCChg = dataForSetFields[i].bcChg;
      this.isAoc = dataForSetFields[i].aoc;
      this.isInsChg = dataForSetFields[i].insChg;
      this.isDD = dataForSetFields[i].dd;
      this.isOthers = dataForSetFields[i].others;
      this.isHamaliChg = dataForSetFields[i].hamaliChg;
      this.hamaliSrcCustom = dataForSetFields[i].hamaliSrcCustom;
      this.customHamaliSrcChg =
        dataForSetFields[i].customHamaliSrcChg == null
          ? 0.0
          : dataForSetFields[i].customHamaliSrcChg;

      if (
        this.userDataDtoReturnSession.mainStation != null &&
        this.userDataDtoReturnSession.mainStation == "Chennai"
      ) {
        //CHG-V-CM:04
        this.logicForGDDD(dataForSetFields[i]);
      } else if (
        this.userDataDtoReturnSession.mainStation != null &&
        (this.userDataDtoReturnSession.mainStation == "Madurai" ||
          this.userDataDtoReturnSession.mainStation == "Trivandrum")
      ) {
        //CHG_V-CM:13
        this.logicForGDDDDefaultValue(dataForSetFields[i]);
      }
      if (dataForSetFields[i].rate != null) {
        $("#" + this.pageId + "srmRate").val(dataForSetFields[i].rate);
        this.srmRate = dataForSetFields[i].rate;
        this.srmRatePercentage = dataForSetFields[i].rate;
        $("#" + this.pageId + "srmRatePercentage").val(
          dataForSetFields[i].rate
        );
      } else {
        $("#" + this.pageId + "srmRate").val("0");
        this.srmRate = "0";
        this.srmRatePercentage = "0";
        $("#" + this.pageId + "srmRatePercentage").val("0");
      }

      $("#" + this.pageId + "srmPerUnit").val(this.unitType);
      $("#" + this.pageId + "srmNetRateHamaliLess").val(
        this.hamaliDDRateLessStatus == true ? "Yes" : "No"
      );
      console.log(dataForSetFields[i].riskCharge);
      if (dataForSetFields[i].riskCharge == null) {
        $("#" + this.pageId + "srmFov").val("");
      } else {
        $("#" + this.pageId + "srmFov").val(
          dataForSetFields[i].riskCharge == true ? "Yes" : "No"
        );
      }

      if (dataForSetFields[i].gcCharge != null) {
        $("#" + this.pageId + "srmGcCharges").val(dataForSetFields[i].gcCharge);
        this.srmGcCharge = dataForSetFields[i].gcCharge;
      } else {
        $("#" + this.pageId + "srmGcCharges").val("0");
        this.srmGcCharge = "0";
      }
      if (dataForSetFields[i].hamaliSource != null) {
        $("#" + this.pageId + "srmSourceHamali").val(
          dataForSetFields[i].hamaliSource
        );
        this.srmSourceHamali = dataForSetFields[i].hamaliSource;
      } else {
        $("#" + this.pageId + "srmSourceHamali").val("0");
        this.srmSourceHamali = "0";
      }
      if (dataForSetFields[i].hamaliDestination != null) {
        $("#" + this.pageId + "srmDestinationHamali").val(
          dataForSetFields[i].hamaliDestination
        );
        this.srmDestinationHamali = dataForSetFields[i].hamaliDestination;
      } else {
        $("#" + this.pageId + "srmDestinationHamali").val("0");
        this.srmDestinationHamali = "0";
      }
      if (dataForSetFields[i].receiptCharges != null) {
        $("#" + this.pageId + "srmReciptCharges").val(
          dataForSetFields[i].receiptCharges
        );
        this.srmReciptCharges = dataForSetFields[i].receiptCharges;
      } else {
        $("#" + this.pageId + "srmReciptCharges").val("0");
        this.srmReciptCharges = "0";
      }
      if (dataForSetFields[i].serviceCharges != null) {
        $("#" + this.pageId + "srmServiceCharges").val(
          dataForSetFields[i].serviceCharges
        );
        this.srmServiceCharges = dataForSetFields[i].serviceCharges;
      } else {
        $("#" + this.pageId + "srmServiceCharges").val("0");
        this.srmServiceCharges = "0";
      }

      if (dataForSetFields[i].source != null) {
        $("#" + this.pageId + "srmSourceStation").val(
          dataForSetFields[i].source
        );
      } else {
        $("#" + this.pageId + "srmSourceStation").val("N/A");
      }
      $("#" + this.pageId + "srmRateId").val(dataForSetFields[i].id);

      //console.log( dataForSetFields[i].column2 );
      this.srmDiscount = dataForSetFields[i].column2;
      $("#" + this.pageId + "srmDiscount").val(dataForSetFields[i].column2);
      this.readonlySrmSourceStation = true;
      this.readonlySrmRate = true;
      this.readonlySrmGcCharges = true;
      this.readonlySrmSourceHamali = true;
      this.readonlySrmDestinationHamali = true;
      this.readonlySrmReciptCharges = true;
      this.readonlySrmServiceCharges = true;
      this.readonlySrmRatePercentage = true;
      this.readonlySrmDiscount = true;
      this.readonlySrmPerUnit = true;

      //console.log( dataForSetFields[i].unit );
      if (dataForSetFields[i].unit == "Discount") {
        this.viewSrmRate = false;
        this.viewSrmRatePercentage = true;
        this.viewSrmDiscount = true;
      } else {
        this.viewSrmRate = true;
        this.viewSrmRatePercentage = false;
        this.viewSrmDiscount = true;
        this.srmDiscount = "Discount Rate";
        $("#" + this.pageId + "srmDiscount").val("Discount Rate");
      }
      // risk chg
      this.isFOV = dataForSetFields[i].riskCharge;

      if (
        this.userDataDtoReturnSession.isLRHamaliEffect != null &&
        this.userDataDtoReturnSession.isLRHamaliEffect == true
      ) {
        this.setHamaliValues();
      }
      console.log("inside other logic 1");
      console.log(dataForSetFields[i]);
      if (this.userDataDtoReturnSession.mainStation == "Chennai") {
        console.log("inside other logic 2");
        if (
          this.selectedDeliveryType != null &&
          this.selectedDeliveryType == "Door Delivery"
        ) {
          $("#" + this.pageId + "srmOtherChg").val(
            dataForSetFields[i].otherChgAmt
          );
          if (dataForSetFields[i].otherChgUnit == "Article") {
            console.log("inside other logic 3");
            this.enteredNoOfArticle = $(
              "#" + this.pageId + "noOfArticle"
            ).val();
            $("#" + this.pageId + "others").val(
              this.enteredNoOfArticle * dataForSetFields[i].otherChgAmt
            );
            console.log(dataForSetFields[i].otherChgAmt);
          } else if (dataForSetFields[i].otherChgUnit == "Lumpsum") {
            $("#" + this.pageId + "others").val(
              dataForSetFields[i].otherChgAmt
            );
          }
        }
      }

      this.calculateDiscount();
      this.logicForGST();
      this.rateUnit = dataForSetFields[i].unit;
      this.ratePerUnit = dataForSetFields[i].perUnit;
    }
  }
  methodForSetCrossingChgSpecific() {
    this.lrDtoCrossingChgSpecfic = new LRDto();
    this.lrDtoCrossingChgSpecfic.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoCrossingChgSpecfic.mainstation =
      this.returnLrNumberDtls.destination;
    this.lrDtoCrossingChgSpecfic.subStations =
      this.returnLrNumberDtls.areaOfDelivery;
  }
  setCrossingChgSpecific() {
    this.methodForSetCrossingChgSpecific();
    this.masterReadService
      .getCrossingChgService(this.lrDtoCrossingChgSpecfic)
      .subscribe((response) => {
        this.dataListOfCrossingChgSpecfic = response;
        //console.log( this.dataListOfCrossingChgSpecfic );
        this.calculateCrossingChg(this.dataListOfCrossingChgSpecfic);
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while loading Charge Specific Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }
  calculateDiscount() {
    console.log("calculateDiscount");
    this.calcDiscnt = 0;
    this.otherDiscChg = 0;
    this.ddHamaliLessAmt = 0;
    //        this.enteredSrmRate = $( "#srmRate" ).val();
    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enteredSrmGcCharge = $("#" + this.pageId + "srmGcCharges").val();
    this.enteredSrmSourceHamali = $(
      "#" + this.pageId + "srmSourceHamali"
    ).val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
    this.enteredBaseFreight = $("#" + this.pageId + "baseFreight").val();
    //        console.log(this.enteredSrmRate +"<< >>"+this.srmRate+"<< >>"+this.enteredSrmDiscount +"<< >>"+this.srmDiscount+"<< >>" +this.srmGcCharge+"<<>>"+this.enteredSrmSourceHamali+ "<< >>"+ this.srmSourceHamali);
    if (this.srmRate == "0.0" || this.srmRate == "0") {
      if (this.returnLrNumberDtls.isGujDisc == true) {
        this.totalDiscount = Math.round(
          this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight / 2
        );
        //                $( "#totalDiscount" ).val( this.enteredLorryFreight == null ? 0 : this.enteredLorryFreight / 2 );
      } else {
        this.totalDiscount = 0;
        //                $( "#totalDiscount" ).val( 0 );
        //17/08/2023
        if (this.userDataDtoReturnSession.mainStation != "Chennai") {
          console.log("this.srmRate");
          this.otherDiscChg =
            this.calculateMiscDiscInAdminRate() + +this.srmGcCharge;
          console.log(this.otherDiscChg);
          this.totalDiscount = this.otherDiscChg;
        }
      }
      this.calculateDisc();
    } else {
      //                        console.log( this.unit );
      if (this.unit == null || this.unit == "") {
      } else {
        this.otherDiscChg = this.calculateMiscDiscInAdminRate();
        this.ddHamaliLessAmt = $("#" + this.pageId + "ddHamaliLess").val();
        if (this.ddHamaliLessAmt == null && this.ddHamaliLessAmt == undefined) {
          this.ddHamaliLessAmt = 0;
        }
        console.log("dd");
        console.log(this.ddHamaliLessAmt);
        if (this.unit == "Rate") {
          //                                        console.log( this.unitType );
          if (this.unitType == "Chargable Weight") {
            //                                                console.log(this.rateAmt);
            //                                                console.log(this.enteredChargedWeight + "<< >>"+ this.srmRate + "<< >>"+ this.srmGcCharge + "<< >>"+ this.srmSourceHamali + "<< >>"+ this.enteredNoOfArticle );
            //                                                console.log( this.enteredChargedWeight * this.srmRate  + +this.srmGcCharge + + this.srmSourceHamali * this.enteredNoOfArticle );
            this.rateAmt =
              this.enteredChargedWeight * this.srmRate +
              +this.srmGcCharge +
              +(this.srmSourceHamali * this.enteredNoOfArticle);
            //                                                console.log( this.rateAmt )
            // added fov in rate calculation @Dated : 12/01/2018
            //console.log(this.isFOV+"<< >>"+this.calcDiscnt+"<< >>"+this.riskChg+"<< >>"+this.fovLessAmt);

            if (this.isFixedRate != null && this.isFixedRate == false) {
              this.calcDiscnt = this.srmRate * this.enteredChargedWeight;
              console.log("1 : " + this.calcDiscnt);
            } else {
              if (this.isFOV == true) {
                //                                                          console.log( this.enteredLorryFreight + "<< >>"+ this.enteredChargedWeight + "<< >>"+  this.srmRate + "<< >>"+ this.srmGcCharge + "<< >>"+  this.srmSourceHamali + "<< >>"+ this.enteredNoOfArticle + "<< >>"+ this.riskChg + "<< >>"+ this.enteredLocalCharge );
                //                                                          console.log( this.enteredLorryFreight - ( this.enteredChargedWeight * this.srmRate ) + +this.srmGcCharge + +( this.srmSourceHamali * this.enteredNoOfArticle ) + +this.riskChg - this.enteredLocalCharge );

                // this.calcDiscnt = (this.enteredLorryFreight - ((this.enteredChargedWeight * this.srmRate) + +this.srmGcCharge + +(this.srmSourceHamali * this.enteredNoOfArticle) + +this.riskChg)) - this.enteredLocalCharge;
                var fovCalculated = this.fovApplicableCalcNewLogic();
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (this.enteredChargedWeight * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle) +
                    +fovCalculated) -
                  this.enteredLocalCharge;
                //                               console.log(this.calcDiscnt);
                //
                //                                                          console.log(this.enteredLorryFreight );
                //                                                          console.log(this.enteredChargedWeight );
                //                                                          console.log(this.srmRate );
                //                                                          console.log(this.srmGcCharge );
                //                                                          console.log(this.srmSourceHamali );
                //                                                          console.log(this.enteredNoOfArticle );
                //                                                                                          console.log(this.riskChg );
                //                                                          console.log(this.enteredLocalCharge );
                //this.fovLessAmt = this.riskChg;
                console.log("2 : " + this.calcDiscnt + " - " + this.fovLessAmt);
              } else {
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (this.enteredChargedWeight * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle)) -
                  this.enteredLocalCharge;
                //                                console.log(this.calcDiscnt);
                this.fovLessAmt = 0;
                console.log("3 : " + this.calcDiscnt);
              }
            }
            //                        console.log(this.calcDiscnt);
            //CHG-V-CM:01
            //                        if ( this.calcDiscnt < 0 ) {
            //                            //                            $( "#totalDiscount" ).val( 0 );
            //                            this.totalDiscount = 0;
            //                            $( "#fovLess" ).val( 0 );
            //                            $( "#discount" ).val( 0 );
            //                        } else {
            //                            //                            $( "#totalDiscount" ).val( Math.round( this.calcDiscnt ) );
            //                            //                            console.log(this.calcDiscnt+"<< >>"+this.fovLessAmt +"<< Chargable Weight");
            //                            this.totalDiscount = Math.round( this.calcDiscnt );
            //                            $( "#fovLess" ).val( this.fovLessAmt );
            //                            $( "#discount" ).val( this.calcDiscnt - this.fovLessAmt );
            //                        }
            console.log(this.otherDiscChg);
            this.calcDiscnt = this.calcDiscnt + -this.otherDiscChg;
            console.log("4 : " + this.calcDiscnt);

            //CHG_V-CM:08
            //avoid ddHamaliLessAmt addition if dis is in -ve
            // if (this.calcDiscnt < 0) {
            //     this.calcDiscnt = this.calcDiscnt;
            // } else {
            //     this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            // }

            this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            this.calcDiscnt = Math.round(this.calcDiscnt);

            //CHG_V-CM:20
            if (this.calcDiscnt < 0) {
              let otherChgTemp1 = $("#" + this.pageId + "others").val();
              console.log("otherChgTemp1");
              console.log(otherChgTemp1);
              this.calcDiscnt = this.calcDiscnt * -1;
              let otherChgTemp2 = this.calcDiscnt + +otherChgTemp1;
              console.log("otherChgTemp2");
              console.log(otherChgTemp2);
              $("#" + this.pageId + "others").val(otherChgTemp2);
              this.calcDiscnt = 0;
            }

            console.log("5 : " + this.calcDiscnt);
            
            $("#" + this.pageId + "fovLess").val(this.fovLessAmt);
            //Chg_V-CM:28
            // $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
            // this.totalDiscount = Math.round(this.calcDiscnt);
            if (!this.destMemoSizeExceedRights) {
              
              $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
              this.totalDiscount = Math.round(this.calcDiscnt);
            } else if (this.destMemoSizeExceedRights) {
              if (!this.isSizeExceeded) {
                
                $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
                this.totalDiscount = Math.round(this.calcDiscnt);
              } else if (this.isSizeExceeded) {
                this.viewIsSizeExeededLrText = true;
                this.totalDiscount = Math.round(this.fovLessAmt);
              }
            }

            //                        console.log(this.calcDiscnt);
            //                        console.log(this.fovLessAmt);
            //                        console.log(this.calcDiscnt - this.fovLessAmt);
            //CHG-V-CM:05
            if (
              $("#" + this.pageId + "paid").val() != null &&
              $("#" + this.pageId + "paid").val() > 0
            ) {
              this.totalDiscount = 0;
              this.fovLessAmt = 0;
              $("#" + this.pageId + "fovLess").val(0);
              $("#" + this.pageId + "discount").val(0);
            }
            this.calculateDisc();
          } else if (this.unitType == "Actual Weight") {
            //                                this.rateAmt= ((this.enteredActualWeight * this.srmRate) + +this.srmGcCharge + +(this.srmSourceHamali * this.enteredNoOfArticle));
            this.rateAmt =
              this.enteredActualWeight * this.srmRate +
              +this.srmGcCharge +
              +(this.srmSourceHamali * this.enteredNoOfArticle);
            //                        console.log( this.rateAmt );
            // added fov in rate calculation @Dated : 12/01/2018
            console.log("OnActWgt");
            console.log(this.dataListReturnOfConsigneeRateDtls[0].rateOnActWgt);
            if (this.isFixedRate != null && this.isFixedRate == false) {
              this.calcDiscnt = this.srmRate * this.enteredActualWeight;
            } else {
              var calculatedActWgt = this.enteredActualWeight;
              if (
                this.dataListReturnOfConsigneeRateDtls[0].rateOnActWgt !=
                null &&
                this.dataListReturnOfConsigneeRateDtls[0].rateOnActWgt != ""
              ) {
                calculatedActWgt =
                  this.enteredActualWeight *
                  this.dataListReturnOfConsigneeRateDtls[0].rateOnActWgt;
              }

              if (this.isFOV == true) {
                // this.calcDiscnt = (this.enteredLorryFreight - ((this.enteredActualWeight * this.srmRate) + +this.srmGcCharge + +(this.srmSourceHamali * this.enteredNoOfArticle) + +this.riskChg)) - this.enteredLocalCharge;
                // this.fovLessAmt = this.riskChg;
                var fovCalculated = this.fovApplicableCalcNewLogic();
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (calculatedActWgt * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle) +
                    +fovCalculated) -
                  this.enteredLocalCharge;
              } else {
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (calculatedActWgt * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle)) -
                  this.enteredLocalCharge;
                this.fovLessAmt = 0;
              }
            }
            //CHG-V-CM:01

            //                        if ( this.calcDiscnt < 0 ) {
            //                            //                            $( "#totalDiscount" ).val( 0 );
            //
            //                            this.totalDiscount = 0;
            //                            $( "#fovLess" ).val( 0 );
            //                            $( "#discount" ).val( 0 );
            //                        } else {
            //                            //                            $( "#totalDiscount" ).val( Math.round( this.calcDiscnt ) );
            //                            //                            console.log(this.calcDiscnt+"<< >>"+this.fovLessAmt +"<< Actual Weight");
            //                            this.totalDiscount = Math.round( this.calcDiscnt );
            //                            $( "#fovLess" ).val( this.fovLessAmt );
            //                            $( "#discount" ).val( this.calcDiscnt - this.fovLessAmt );
            //                        }

            // this.calcDiscnt = this.calcDiscnt + -this.otherDiscChg;
            //CHG_V-CM:08
            //avoid ddHamaliLessAmt addition if dis is in -ve
            // if (this.calcDiscnt < 0) {
            //     this.calcDiscnt = this.calcDiscnt;
            // } else {
            //     this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            // }

            this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            this.calcDiscnt = Math.round(this.calcDiscnt);

            //CHG_V-CM:20
            if (this.calcDiscnt < 0) {
              let otherChgTemp1 = $("#" + this.pageId + "others").val();
              console.log("otherChgTemp1");
              console.log(otherChgTemp1);
              this.calcDiscnt = this.calcDiscnt * -1;
              let otherChgTemp2 = this.calcDiscnt + +otherChgTemp1;
              console.log("otherChgTemp2");
              console.log(otherChgTemp2);
              $("#" + this.pageId + "others").val(otherChgTemp2);
              this.calcDiscnt = 0;
            }

            $("#" + this.pageId + "fovLess").val(this.fovLessAmt);
            
            //Chg_V-CM:28
            // $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
            // this.totalDiscount = Math.round(this.calcDiscnt);
            if (!this.destMemoSizeExceedRights) {
              
              $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
              this.totalDiscount = Math.round(this.calcDiscnt);
            } else if (this.destMemoSizeExceedRights) {
              if (!this.isSizeExceeded) {
                
                $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
                this.totalDiscount = Math.round(this.calcDiscnt);
              }else if (this.isSizeExceeded){
                this.viewIsSizeExeededLrText=true;
                this.totalDiscount = Math.round(this.fovLessAmt);
              }
            }
            //CHG-V-CM:05
            if (
              $("#" + this.pageId + "paid").val() != null &&
              $("#" + this.pageId + "paid").val() > 0
            ) {
              this.totalDiscount = 0;
              this.fovLessAmt = 0;
              $("#" + this.pageId + "fovLess").val(0);
              $("#" + this.pageId + "discount").val(0);
            }
            this.calculateDisc();
          } else if (this.unitType == "Article") {
            this.rateAmt =
              this.enteredNoOfArticle * this.srmRate +
              +this.srmGcCharge +
              +(this.srmSourceHamali * this.enteredNoOfArticle);
            //                        console.log( this.rateAmt )
            //                        ( ( this.enteredNoOfArticle * this.srmRate ) + +this.srmGcCharge + +( this.srmSourceHamali * this.enteredNoOfArticle ) );
            if (this.isFixedRate != null && this.isFixedRate == false) {
              this.calcDiscnt = this.srmRate * this.enteredNoOfArticle;
              console.log("0 - " + this.calcDiscnt);
            } else {
              if (this.isFOV == true) {
                // this.calcDiscnt = (this.enteredLorryFreight - ((this.enteredNoOfArticle * this.srmRate) + +this.srmGcCharge + +(this.srmSourceHamali * this.enteredNoOfArticle) + +this.riskChg)) - this.enteredLocalCharge;
                // this.fovLessAmt = this.riskChg;
                var fovCalculated = this.fovApplicableCalcNewLogic();
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (this.enteredNoOfArticle * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle) +
                    +fovCalculated) -
                  this.enteredLocalCharge;
                console.log("1 - " + this.calcDiscnt);
              } else {
                this.calcDiscnt =
                  this.enteredLorryFreight -
                  (this.enteredNoOfArticle * this.srmRate +
                    +this.srmGcCharge +
                    +(this.srmSourceHamali * this.enteredNoOfArticle)) -
                  this.enteredLocalCharge;
                this.fovLessAmt = 0;
                console.log("2 - " + this.calcDiscnt);
              }
            }

            //CHG-V-CM:01
            //                        if ( this.calcDiscnt < 0 ) {
            //                            //                            $( "#totalDiscount" ).val( 0 );
            //                            this.totalDiscount = 0;
            //                            $( "#fovLess" ).val( 0 );
            //                            $( "#discount" ).val( 0 );
            //                        } else {
            //                            //                            $( "#totalDiscount" ).val( Math.round( this.calcDiscnt ) );
            //                            //                            console.log(this.calcDiscnt+"<< >>"+this.fovLessAmt +"<< Article");
            //                            this.totalDiscount = Math.round( this.calcDiscnt );
            //                            $( "#fovLess" ).val( this.fovLessAmt );
            //                            $( "#discount" ).val( this.calcDiscnt - this.fovLessAmt );
            //                        }

            this.calcDiscnt = this.calcDiscnt + -this.otherDiscChg;
            //CHG_V-CM:08
            //avoid ddHamaliLessAmt addition if dis is in -ve
            // if (this.calcDiscnt < 0) {
            //     this.calcDiscnt = this.calcDiscnt;
            // } else {
            //     this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            // }
            this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            this.calcDiscnt = Math.round(this.calcDiscnt);

            //CHG_V-CM:20
            if (this.calcDiscnt < 0) {
              let otherChgTemp1 = $("#" + this.pageId + "others").val();
              console.log("otherChgTemp1");
              console.log(otherChgTemp1);
              this.calcDiscnt = this.calcDiscnt * -1;
              let otherChgTemp2 = this.calcDiscnt + +otherChgTemp1;
              console.log("otherChgTemp2");
              console.log(otherChgTemp2);
              $("#" + this.pageId + "others").val(otherChgTemp2);
              this.calcDiscnt = 0;
            }

            console.log(this.calcDiscnt);

            
            $("#" + this.pageId + "fovLess").val(this.fovLessAmt);
            //Chg_V-CM:28
            // $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
            // this.totalDiscount = Math.round(this.calcDiscnt);
            if (!this.destMemoSizeExceedRights) {
              
              $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
              this.totalDiscount = Math.round(this.calcDiscnt);
            } else if (this.destMemoSizeExceedRights) {
              if (!this.isSizeExceeded) {
               
                $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
                this.totalDiscount = Math.round(this.calcDiscnt);
              }else if (this.isSizeExceeded){
                this.viewIsSizeExeededLrText=true;
                this.totalDiscount = Math.round(this.fovLessAmt);
              }
            }
            //CHG-V-CM:05
            if (
              $("#" + this.pageId + "paid").val() != null &&
              $("#" + this.pageId + "paid").val() > 0
            ) {
              this.totalDiscount = 0;
              this.fovLessAmt = 0;
              $("#" + this.pageId + "fovLess").val(0);
              $("#" + this.pageId + "discount").val(0);
            }
            this.calculateDisc();
          }
        } else if (this.unit == "Discount") {
          if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
            if (this.srmDiscount == "onLr") {
              //                                                        console.log(this.enteredLorryFreight+"<< >>" +this.srmRate );
              //                                                        console.log(+this.enteredLorryFreight * +this.srmRate );
              //                                                        console.log(this.enteredLorryFreight * this.srmRate );
              this.calcDiscnt = (this.enteredLorryFreight * this.srmRate) / 100;
              this.calcDiscnt = this.calcDiscnt + -this.otherDiscChg;
              console.log(
                Math.round(this.calcDiscnt) + "<< >>" + this.calcDiscnt
              );
            } else if (this.srmDiscount == "onBaseFrgt") {
              if (this.isFOV == true) {
                // this.calcDiscnt = (this.enteredLorryFreight - ((this.enteredNoOfArticle * this.srmRate) + +this.srmGcCharge + +(this.srmSourceHamali * this.enteredNoOfArticle) + +this.riskChg)) - this.enteredLocalCharge;
                // this.fovLessAmt = this.riskChg;

                var fovCalculated = this.fovApplicableCalcNewLogic();
                console.log("ok111");

                this.calcDiscnt =
                  (this.enteredBaseFreight * this.srmRate) / 100;
                console.log(
                  fovCalculated +
                  " - " +
                  this.otherDiscChg +
                  " - " +
                  this.calcDiscnt
                );
                this.calcDiscnt = this.calcDiscnt + +fovCalculated;
              } else {
                this.calcDiscnt =
                  (this.enteredBaseFreight * this.srmRate) / 100;
              }
              

              $("#" + this.pageId + "fovLess").val(this.fovLessAmt);
              //Chg_V-CM:28
              // $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
              // this.totalDiscount = Math.round(this.calcDiscnt);
              if (!this.destMemoSizeExceedRights) {
                
                $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
                this.totalDiscount = Math.round(this.calcDiscnt);
              } else if (this.destMemoSizeExceedRights) {
                if (!this.isSizeExceeded) {
                
                  $("#" + this.pageId + "discount").val(this.calcDiscnt - this.fovLessAmt);
                  this.totalDiscount = Math.round(this.calcDiscnt);
                }else if (this.isSizeExceeded){
                  this.viewIsSizeExeededLrText=true;
                  this.totalDiscount = Math.round(this.fovLessAmt);
                }
              }
            }
            //CHG-V-CM:01

            //                        if ( this.calcDiscnt < 0 ) {
            //                            //                            $( "#totalDiscount" ).val( 0 );
            //                            this.totalDiscount = 0;
            //                        } else {
            //                            //                            $( "#totalDiscount" ).val( Math.round( this.calcDiscnt ) );
            //                            this.totalDiscount = Math.round( this.calcDiscnt );
            //                        }

            //CHG_V-CM:08
            //avoid ddHamaliLessAmt addition if dis is in -ve
            // if (this.calcDiscnt < 0) {
            //     this.calcDiscnt = this.calcDiscnt;
            // } else {
            //     this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            // }

            this.calcDiscnt = this.calcDiscnt + +this.ddHamaliLessAmt;
            this.calcDiscnt = Math.round(this.calcDiscnt);

            //CHG_V-CM:20
            if (this.calcDiscnt < 0) {
              let otherChgTemp1 = $("#" + this.pageId + "others").val();
              console.log("otherChgTemp1");
              console.log(otherChgTemp1);
              this.calcDiscnt = this.calcDiscnt * -1;
              let otherChgTemp2 = this.calcDiscnt + +otherChgTemp1;
              console.log("otherChgTemp2");
              console.log(otherChgTemp2);
              $("#" + this.pageId + "others").val(otherChgTemp2);
              this.calcDiscnt = 0;
            }

            this.totalDiscount = Math.round(this.calcDiscnt);
            //CHG-V-CM:05
            if (
              $("#" + this.pageId + "paid").val() != null &&
              $("#" + this.pageId + "paid").val() > 0
            ) {
              this.totalDiscount = 0;
              this.fovLessAmt = 0;
              $("#" + this.pageId + "fovLess").val(0);
              $("#" + this.pageId + "discount").val(0);
            }
            this.calculateDisc();
          } else if (this.srmDiscount == "onTotal") {
            this.calculateDiscOnTotal();
          }
        }
      }
    }
  }

  getCommodity() {
    this.enteredAgentName = $("#" + this.pageId + "agentName").val();
    this.viewCommodityCombo = true;
    this.viewCommodity = false;
    this.isRecalculate = true;
    //this.commodityComboDataList.commodityName = ["A.footwear(till 72)", "B.footwear(till 78)", "C.footwear(86 & above)", "Eva footwear(till 85)", "China footwear 1", "China footwear //2", "Agra footwear"];
    this.commodityComboDataList = null;
    this.commodityComboDataList = [
      { commodityName: "A.footwear(till 72)" },
      { commodityName: "B.footwear(till 78)" },
      { commodityName: "C.footwear(86 & above)" },
      { commodityName: "Eva footwear(till 85)" },
      { commodityName: "China footwear 1" },
      { commodityName: "China footwear 2" },
      { commodityName: "Agra footwear" },
    ]; //        this.lrDtoCommodityCombo.push(commodityComboDataSet);
    //this.commodityComboDataList           = commodityComboDataSet.commodityName;
    //        this.lrDtoCommodityCombo.commodityName = "A.footwear(till 72)";
    //        this.lrDtoCommodityCombo.commodityName = "B.footwear(till 78)";
    //        this.lrDtoCommodityCombo.commodityName = "C.footwear(86 & above)";
    //        this.lrDtoCommodityCombo.commodityName = "Eva footwear(till 85)";
    //        this.lrDtoCommodityCombo.commodityName = "China footwear 1";
    //        this.lrDtoCommodityCombo.commodityName = "China footwear 2";
    //        this.lrDtoCommodityCombo.commodityName = "Agra footwear";
    //        this.commodityComboDataList = this.lrDtoCommodityCombo;
    //console.log( "commodity Name for Karthik" );
    //console.log( this.commodityComboDataList );

    //        }

    this.commodityComboTA = [];
    for (let i = 0; i < this.commodityComboDataList.length; i++) {
      this.commodityComboTA.push(this.commodityComboDataList[i].commodityName);
    }

    //$( "#commodityCombo" ).val( this.returnLrNumberDtls.column2 );
    this.commodityCombo = this.returnLrNumberDtls.column2;
    // console.log( this.returnLrNumberDtls.column2 );
  }

  methodForSetAllYesLrFields() {
    this.lrDtoYesLrFields = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoYesLrFields.lrNumber = this.enteredLrNumber;
    this.lrDtoYesLrFields.companyId = this.userDataDtoReturnSession.companyId;
  }
  setAllYesLrFields(dataForAllYesLrFields) {
    this.methodForSetAllYesLrFields();
    this.memoService
      .updateCommodityDtlsService(this.lrDtoYesLrFields)
      .subscribe((response) => {
        this.setAllLrFields(dataForAllYesLrFields);
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occured while Saving New Collection Man",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  setAllNoLrFields(dataForAllNoLrFields) {
    //this.openCommodityList( dataForAllNoLrFields );
    //btn ok and btn close
    //        this.modalService.open(commodityList);
    this.setAllLrFields(this.returnLrNumberDtls);
  }

  openCommodityList(commodityList) {
    this.modalService.open(commodityList, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  methodForCrossingChgOnAreaChange(areaSelect) {
    console.log("methodForCrossingChgOnAreaChange");
    if (this.isAreaBasedLogic == true) {
      this.enteredArea = $("#" + this.pageId + "areaBillingDtlsFieldSet").val();
    } else {
      this.enteredArea = $(
        "#" + this.pageId + "areaCollectionDtlsFieldSet"
      ).val();
    }
    //CHG_V-CM:12
    // if (this.hamaliGDAmt > 0) {
    //     //nothing and remain same
    // } else {
    //     this.lrDtoCrossingChgArea = new LRDto();
    //     this.lrDtoCrossingChgArea.companyId = this.userDataDtoReturnSession.companyId;
    //     this.lrDtoCrossingChgArea.mainstation = this.returnLrNumberDtls.destination;
    //     //Basha
    //     //        this.lrDtoCrossingChgArea.subStations = this.returnLrNumberDtls.areaOfDelivery;
    //     this.lrDtoCrossingChgArea.subStations = areaSelect;
    //     this.setCrossingChgOnAreaChange();
    // }
    this.lrDtoCrossingChgArea = new LRDto();
    this.lrDtoCrossingChgArea.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoCrossingChgArea.mainstation = this.returnLrNumberDtls.destination;
    this.lrDtoCrossingChgArea.subStations = areaSelect;
    this.setCrossingChgOnAreaChange();
  }

  // @15/07/2019 - calculating hamali dd and gd based on area
  setCrossingChgOnAreaChange() {
    //        this.methodForCrossingChgOnAreaChange();
    this.masterReadService
      .getCrossingChgService(this.lrDtoCrossingChgArea)
      .subscribe((response) => {
        this.dataListOfCrossingChgArea = response;
        console.log(this.dataListOfCrossingChg);
        this.calculateCrossingChg(this.dataListOfCrossingChgArea);
        if (this.isAreaBasedLogic == true) {
          if (this.isMemoAlreadyGenerated == true) {
            ////CHG_V-CM:15
            if (this.dataListReturnOfConsigneeRateDtls == "") {
              this.calculateSubStationGDChg(this.dataListOfCrossingChgArea);
            } else {
              if (this.dataListOfCrossingChgArea.size > 0) {
                this.readonlyHamaliGd = true;
              } else {
                this.readonlyHamaliGd = false;
              }
            }
          }
        }
        this.calculateDisc();
        this.logicForGST();
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while loading Charge Details",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  // collection man edit option only for admin
  // collection man block for all, changed @ 19/06/2018, hence commented the
  // below admin code
  // not required the IsCollectionManBlock since we are checking the
  // destination
  collectionManBlock() {
    // if (SRDWeb.isCollectionManBlock) {
    // if (SRDWeb.mainStation != null
    // && SRDWeb.mainStation.equalsIgnoreCase("Chennai")
    // && !SRDWeb.role.equalsIgnoreCase("Administrator")) {
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      //            collectionMan.setEnabled(false);
      $("#" + this.pageId + "collectionMan").prop("disabled", true);
    }
    // }
  }

  dataForEnableTransitMemo() {
    this.rateDtoTransitMemoEnable = new RateMasterDto();
    this.rateDtoTransitMemoEnable.memoNumber = this.memoNumberObject;
    this.rateDtoTransitMemoEnable.transitMemoPrt = false;
    this.rateDtoTransitMemoEnable.mode = "transit";
    this.rateDtoTransitMemoEnable.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  //    serviceForTransitMemoEnable(){
  //
  //    }
  methodForEnableTransitMemo() {
    this.dataForEnableTransitMemo();
    this.memoService
      .setCashMemoLaserPrintService(this.rateDtoTransitMemoEnable)
      .subscribe((response) => {
        let retrunStatusForTransitMemoEnable = response;
        if (retrunStatusForTransitMemoEnable.status == "success") {
          swal({
            title: "Message",
            text: "Transit Memo Generation Option Enabled For One Time",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Enableing Transit Memo",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }
  dataForPrintTransitMemo() {
    this.rateDtoPrintTransitmemo = new RateMasterDto();
    this.rateDtoPrintTransitmemo = this.getUserValues();
    this.rateDtoPrintTransitmemo.memoNumber = this.memoNumberObject;
    this.rateDtoPrintTransitmemo.enteredBy = this.generatedBy;
    this.rateDtoPrintTransitmemo.companyId =
      this.userDataDtoReturnSession.companyId;
    this.rateDtoPrintTransitmemo.consolidatedEWayBillNo =
      this.memoConsolidatedEWB;
    this.rateDtoPrintTransitmemo.isNewParty = this.newPartyViewPrint;
  }

  methodForPrintTransitMemo() {
    this.dataForPrintTransitMemo();
    if (this.allowDuplicateMemo == true) {
      this.rateDtoPrintTransitmemo.transitMemoPrt = true;
      this.rateDtoPrintTransitmemo.mode = "transit";
      this.memoService
        .setCashMemoLaserPrintService(this.rateDtoPrintTransitmemo)
        .subscribe((response) => {
          this.changeDetectorRef.detectChanges();
        }),
        (error) =>
          swal({
            title: "Server Error",
            text: "Problem occur while Print Transit Memo",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }),
        () => console.log("done");
    }
    this.rateDtoPrintTransitmemo.mode = "message";
    localStorage.clear();
    localStorage.setItem(
      "printTransitMemo",
      JSON.stringify(this.rateDtoPrintTransitmemo)
    );
    //console.table(( localStorage.getItem( 'printAfterSave' ) ) );
    this.viewCashMemoPrint = true;
    //                                var customWindow = window.open( '/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //                                var customWindow = window.open( '/angular/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //
    window.onafterprint = function () {
      //                                    customWindow.close();
    };
    this.clearFields();
    this.viewMemoAlreadyGeneratedMsg = false;
    window.addEventListener("afterprint", (event) => {
      this.viewCashMemoPrint = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  dataForEnableDuplicateMemo() {
    this.rateDtoDuplicateMemoEnable = new RateMasterDto();
    this.rateDtoDuplicateMemoEnable.memoNumber = this.memoNumberObject;
    this.rateDtoDuplicateMemoEnable.duplicateMemo = false;
    this.rateDtoDuplicateMemoEnable.mode = "duplicate";
    this.rateDtoDuplicateMemoEnable.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  methodForEnableDuplicateMemo() {
    this.dataForEnableDuplicateMemo();
    this.memoService
      .setCashMemoLaserPrintService(this.rateDtoDuplicateMemoEnable)
      .subscribe((response) => {
        let retrunStatusForDuplicateMemoEnable = response;
        if (retrunStatusForDuplicateMemoEnable.status == "success") {
          swal({
            title: "Message",
            text: "Duplicate Memo Generation Option Enabled For One Time",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Problem occur while Enableing Duplicate Memo",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForEnableBankLr() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Enable ",
        text: "Sure you want to enable to generate memo for this Bank LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateBankLrEnable();
        } else {
          swal.close();
        }
      });
    }
  }
  methodForUpdateBankLrEnable() {
    this.lrDtoBankLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBankLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBankLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBankLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateBankLrEnable() {
    this.methodForUpdateBankLrEnable();
    this.memoService
      .updateEnableBankLrDestMemoService(this.lrDtoBankLr)
      .subscribe((response) => {
        let retrunStatusForEnableBankLr = response;
        if (retrunStatusForEnableBankLr.status == "Success") {
          swal({
            title: "Bank LR Enabled",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Cashmemo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the Bank LR memo generation",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the Bank LR memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  // methodForCodEnableLr() {
  //     this.codEnableRights = true;
  //     swal({
  //         title: "COD Memo Enabled",
  //         text: "COD LR enbaled to generate the memo",
  //         icon: "info",
  //         closeOnClickOutside: true,
  //         closeOnEsc: true,
  //     });
  // }

  dataForPrintCashMemo() {
    this.rateDtoPrint = new RateMasterDto();
    this.rateDtoPrint = this.getUserValues();
    this.rateDtoPrint.memoNumber = this.memoNumberObject;
    this.rateDtoPrint.enteredBy = this.generatedBy;
    this.rateDtoPrint.companyId = this.userDataDtoReturnSession.companyId;
    this.rateDtoPrint.consolidatedEWayBillNo = this.memoConsolidatedEWB;
    this.rateDtoPrint.isNewParty = this.newPartyViewPrint;
  }

  methodForPrintBtn() {
    this.dataForPrintCashMemo();
    $("#" + this.pageId + "printBtn").prop("disabled", true);
    this.viewPrintBtn = false;
    if (this.allowDuplicateMemo == true) {
      this.rateDtoPrint.duplicateMemo = true;
      this.rateDtoPrint.mode = "duplicate";
      this.memoService
        .setCashMemoLaserPrintService(this.rateDtoPrint)
        .subscribe((response) => {
          this.changeDetectorRef.detectChanges();
        }),
        (error) =>
          swal({
            title: "Server Error",
            text: "Problem occur while Print Transit Memo",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }),
        () => console.log("done");
    }
    localStorage.clear();
    //        console.log(this.rateDtoPrint);
    localStorage.setItem("printCashMemo", JSON.stringify(this.rateDtoPrint));
    //console.table(( localStorage.getItem( 'printAfterSave' ) ) );
    this.viewCashMemoPrint = true;
    //                                var customWindow = window.open( '/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //                                var customWindow = window.open( '/angular/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
    //
    window.onafterprint = function () {
      //                                    customWindow.close();
    };
    this.clearFields();
    this.viewMemoAlreadyGeneratedMsg = false;
    $("#" + this.pageId + "btnEnableTransitMemo").prop("disabled", true);
    $("#" + this.pageId + "enableDuplicateMemoBtn").prop("disabled", false);
    window.addEventListener("afterprint", (event) => {
      this.viewCashMemoPrint = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  methodForEnableSelfLr() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Enable ",
        text: "Sure you want to enable to generate memo for this Self LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateSelfLrEnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateSelfLrEnable() {
    this.lrDtoSelfLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoSelfLr.lrNumber = this.enteredLrNumber;
    this.lrDtoSelfLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoSelfLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateSelfLrEnable() {
    this.methodForUpdateSelfLrEnable();
    this.memoService
      .updateEnableSelfLrDestMemoService(this.lrDtoSelfLr)
      .subscribe((response) => {
        let retrunStatusForEnableSelfLr = response;
        if (retrunStatusForEnableSelfLr.status == "Success") {
          swal({
            title: "Self LR Enabled",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Cashmemo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the Self LR memo generation",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the Self LR memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForEnableBlockLr() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Enable ",
        text: "Sure you want to enable to generate memo for this Blocked LR (Blocked from Consignee Master)?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateBlockLrEnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateBlockLrEnable() {
    this.lrDtoBlockLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBlockLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBlockLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBlockLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateBlockLrEnable() {
    this.methodForUpdateBlockLrEnable();
    this.memoService
      .updateEnableBlockedLrDestMemoService(this.lrDtoBlockLr)
      .subscribe((response) => {
        let retrunStatusForEnableBlockLr = response;
        if (retrunStatusForEnableBlockLr.status == "Success") {
          swal({
            title: "Blocked LR Enabled",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Cashmemo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the BLocked LR memo generation",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the Blocked LR memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  editBtn() {
    let paymentmode = this.returnLrNumberDtls.paymentMode.toLowerCase();
    //console.log( this.returnLrNumberDtls );
    if (this.returnLrNumberDtls.isEditDelete == true) {
      if (
        this.returnLrNumberDtls.memoStatus != null &&
        this.returnLrNumberDtls.memoPayMentSts != null
      ) {
        if (
          this.returnLrNumberDtls.memoStatus == "collection" ||
          this.returnLrNumberDtls.memoStatus == "To party"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Edit is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (
          this.returnLrNumberDtls.memoStatus == "completed" &&
          this.returnLrNumberDtls.memoPayMentSts == "completed" &&
          paymentmode != "cash"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Edit is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          swal({
            title: "Confirm Update",
            text: "Sure U want to Edit this Cashmemo",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((yesBtn) => {
            if (yesBtn) {
              this.activateFieldsForUpdate();
            } else {
              swal.close();
            }
          });
        }
      }
    } else {
      swal({
        title: "Warning",
        text: "Cash Memo Edit is Blocked Same Day Memo Only Edit Access",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  activateFieldsForUpdate() {
    $("#" + this.pageId + "paymentMode").prop("disabled", false);
    $("#" + this.pageId + "deliveryMode").prop("disabled", false);
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").prop(
      "disabled",
      false
    );
    if (this.asPerRightShowUpdateBtn == true) {
      this.viewUpdateBtn = true;
    }

    this.viewEditDelvBtn = false;
    this.viewEditBtn = false;
    this.viewPrintBtn = false;
    this.viewTransitMemoPrtBtn = false;
    this.viewDeleteBtn = false;
    this.readonlyCashmemoDate = false;
    this.readonlyCbLetterHead = false;
    this.readonlyDirectAssg = false;
    this.readonlyTripsheetHamali = false;
  }

  btnUpdate(openEditReasonPopup) {
    this.selectedDeliveryMode = $("#" + this.pageId + "deliveryMode").val();
    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
    this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
    this.newCollectionMan = $("#" + this.pageId + "newCollectionMan").val();
    if (this.isAreaBasedLogic == true) {
      this.enteredArea = $("#" + this.pageId + "areaBillingDtlsFieldSet").val();
    } else {
      this.enteredArea = $(
        "#" + this.pageId + "areaCollectionDtlsFieldSet"
      ).val();
    }
    //CHG_V-CM:17
    if (this.selectedPaymentMode == "Select") {
      swal({
        title: "Mandatory Fields",
        text: "Please select the Payment Mode!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      if (this.selectedDeliveryMode == "GD") {
        if (this.selectedPaymentMode == "Cash") {
          this.updateMtd(openEditReasonPopup);
        } else if (this.selectedPaymentMode != "Cash") {
          if (
            this.selectedCollectionMan == null ||
            this.selectedCollectionMan == "" ||
            this.selectedCollectionMan == "N/A" ||
            this.selectedCollectionMan == "NA"
          ) {
            swal({
              title: "Mandatory Fields",
              text: "Please select the Collection Man",
              icon: "warning",
              closeOnClickOutside: true,
              closeOnEsc: true,
            });
          } else if (this.selectedCollectionMan == "ADD NEW") {
            if (
              this.newCollectionMan == null ||
              this.newCollectionMan == "" ||
              this.newCollectionMan == "N/A" ||
              this.newCollectionMan == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please Enter Collection Man",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            }
          } else {
            this.updateMtd(openEditReasonPopup);
          }
        }
      }
      if (this.selectedDeliveryMode == "DD") {
        if (this.selectedPaymentMode == "Cash") {
          this.updateMtd(openEditReasonPopup);
        } else if (this.selectedPaymentMode != "Cash") {
          if (
            this.selectedCollectionMan == null ||
            this.selectedCollectionMan == "" ||
            this.selectedCollectionMan == "N/A" ||
            this.selectedCollectionMan == "NA" ||
            this.enteredArea == null ||
            this.enteredArea == "" ||
            this.enteredArea == "N/A" ||
            this.enteredArea == "NA"
          ) {
            swal({
              title: "Mandatory Fields",
              text: "Please select the Collection Man and Area",
              icon: "warning",
              closeOnClickOutside: true,
              closeOnEsc: true,
            });
          } else if (this.enteredArea == "ADD NEW") {
            if (this.isAreaBasedLogic == true) {
              this.newArea = $(
                "#" + this.pageId + "newAreaBillingDtlsFieldSet"
              ).val();
            } else {
              this.newArea = $(
                "#" + this.pageId + "newAreaCollectionDtlsFieldSet"
              ).val();
            }

            if (
              this.newArea == null ||
              this.newArea == "" ||
              this.newArea == "N/A" ||
              this.newArea == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please Enter Collection Man and Area",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            }
          } else if (this.selectedCollectionMan == "ADD NEW") {
            if (
              this.newCollectionMan == null ||
              this.newCollectionMan == "" ||
              this.newCollectionMan == "N/A" ||
              this.newCollectionMan == "NA"
            ) {
              swal({
                title: "Mandatory Fields",
                text: "Please Enter Collection Man and Area",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            }
          } else {
            this.updateMtd(openEditReasonPopup);
          }
        }
      }
    }
  }

  updateMtd(openEditReasonPopup) {
    //open popup Code here
    //console.log( "open popup Code here" );
    this.modalService.open(openEditReasonPopup);
  }

  //    btnEditConfirm() {
  ////        let reasonEditValue = $( "reasonEdit" ).val();
  //        //
  //        //        this.selectedPaymentType = $( "#paymentType" ).val();
  //        //        this.selectedBankName = $( "#consigneeBankName" ).val();//"ADD NEW"; means call this service 4370 addBankNames()
  //        //        this.enteredNewBankName = $( "#newBankName" ).val();
  //        //        this.enteredChequeNumber = $( "#chequeNumber" ).val();
  //        //        this.enteredChequeDate = $( "#chequeDate" ).val();
  //
  //        //console.log( this.selectedPaymentType + "<< >>" + this.selectedBankName + "<< >>" + this.enteredChequeNumber + "<< >>" + this.enteredReferenceNumber + "<< >>" + //this.enteredChequeDate );
  ////        console.log( reasonEditValue );
  //        if ( this.reasonEditValue == null || this.reasonEditValue == "" ) {
  //            swal( {
  //                title: "Mandatory Fields",
  //                text: "Please Enter Reason For Edit",
  //                icon: "warning",
  //                closeOnClickOutside: true,
  //                closeOnEsc: true,
  //            } ).then(() => {
  //                $( "reasonEdit" ).focus();
  //            } )
  //        } else {
  //            //console.log( reasonEditValue );
  //            this.updateCashmemoDetails();
  //        }
  //
  //    }

  btnEditConfirm() {
    if (this.reasonEditValue == null || this.reasonEditValue == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please Enter Reason For Edit",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      }).then(() => {
        $("#" + this.pageId + "reasonEdit").focus();
      });
    } else {
      //console.log( reasonEditValue );
      this.updateCashmemoDetails();
    }
  }

  reasonEditFocus(e) {
    if (e.keyCode == 13) {
      $("#" + this.pageId + "confirmEdit").focus();
    }
  }
  userDataUpdateCashmemoDetails() {
    this.rateDtoUpdateCashMemo = new RateMasterDto();
    //        let reasonEditValue = $( "#reasonEdit" ).val();
    this.rateDtoUpdateCashMemo = this.getUserValues();
    this.rateDtoUpdateCashMemo.memoNumber = this.memoNumberObject;
    //        this.rateDtoPrint.enteredBy = this.generatedBy;
    this.rateDtoUpdateCashMemo.companyId =
      this.userDataDtoReturnSession.companyId;
    this.rateDtoUpdateCashMemo.reason = this.reasonEditValue;
    this.rateDtoUpdateCashMemo.consolidatedEWayBillNo =
      this.memoConsolidatedEWB;
    this.rateDtoUpdateCashMemo.isNewParty = this.newPartyViewPrint;
    this.reasonEditValue = "";
    this.reasonDeleteValue = "";
  }
  updateCashmemoDetails() {
    this.userDataUpdateCashmemoDetails();
    this.memoService
      .editCashmemoDetailsService(this.rateDtoUpdateCashMemo)
      .subscribe((response) => {
        let retrunStatusForUpdateMemo = response;
        if (retrunStatusForUpdateMemo.status == "failed") {
          swal({
            title: "Failed",
            text: "Update Failed",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          // add new collectionman
          this.addNewCollectionMan();
          // setCollectionManIntoCombo();
          swal({
            title: "Print CashMemo",
            text:
              "Cashmemo: " +
              this.rateDtoUpdateCashMemo.memoNumber +
              " Amount: " +
              this.rateDtoUpdateCashMemo.grandTotal +
              " Sure U Want to Print this Cashmemo",
            icon: "info",
            closeOnClickOutside: true,
            closeOnEsc: true,
            buttons: ["No", "Yes"],
          }).then((yesBtn) => {
            if (yesBtn) {
              localStorage.clear();
              localStorage.setItem(
                "printAfterUpdate",
                JSON.stringify(this.rateDtoUpdateCashMemo)
              );
              //console.table(( localStorage.getItem( 'printAfterSave' ) ) );
              this.viewCashMemoPrint = true;
              //                                var customWindow = window.open( '/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
              //                                var customWindow = window.open( '/angular/cashmemo/generation/cashMemoPrint', '_blank', '/cashmemo/generation/generateCashmemo' );
              //
              // window.onafterprint = function () {
              //                                    customWindow.close();
              // }

              this.viewMemoAlreadyGeneratedMsg = false;
              window.addEventListener("afterprint", (event) => {
                this.viewCashMemoPrint = false;
                this.changeDetectorRef.detectChanges();
              });
              $("#" + this.pageId + "lrNumber").val("");
              $("#" + this.pageId + "lrNumber").focus();
              this.clearFields();
            } else {
              $("#" + this.pageId + "lrNumber").val("");
              $("#" + this.pageId + "lrNumber").focus();
              this.clearFields();
            }
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Update Failed",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  deleteBtn(openDeleteReasonPopup) {
    //console.log( this.returnLrNumberDtls );
    let paymentmode = this.returnLrNumberDtls.paymentMode.toLowerCase();
    if (this.returnLrNumberDtls.isEditDelete == true) {
      if (
        this.returnLrNumberDtls.memoStatus != null &&
        this.returnLrNumberDtls.memoPayMentSts != null
      ) {
        if (
          this.returnLrNumberDtls.memoStatus == "collection" ||
          this.returnLrNumberDtls.memoStatus == "To party"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Delete is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (
          this.returnLrNumberDtls.memoStatus == "completed" &&
          this.returnLrNumberDtls.memoPayMentSts == "completed" &&
          paymentmode != "cash"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Delete is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          swal({
            title: "Confirm Delete",
            text: "Sure U want to Delete Cashmemo " + this.memoNumberObject,
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((yesBtn) => {
            if (yesBtn) {
              //open delete reason popup here
              this.modalService.open(openDeleteReasonPopup);
            } else {
              swal.close();
            }
          });
        }
      }
    } else {
      swal({
        title: "Warning",
        text: "Cash Memo Delete is Blocked Same Day Memo Only Delete Access",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  reasonDeleteFocus(e) {
    if (e.keyCode == 13) {
      $("#" + this.pageId + "confirmDelete").focus();
    }
  }

  btnDeleteConfirm() {
    if (this.reasonDeleteValue == null || this.reasonDeleteValue == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please Enter Reason For Delete",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      }).then(() => {
        $("#" + this.pageId + "reasonDelete").focus();
      });
    } else {
      //console.log( reasonDeleteValue );
      this.deleteMethod();
    }
  }
  userDataDeleteCashmemoDetails() {
    this.lrDtoDeleteCashMemo = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();

    this.lrDtoDeleteCashMemo.memoNumber = this.memoNumberObject;
    this.lrDtoDeleteCashMemo.companyId =
      this.userDataDtoReturnSession.companyId;
    this.lrDtoDeleteCashMemo.reason = this.reasonDeleteValue;
    this.lrDtoDeleteCashMemo.lrNumber = this.enteredLrNumber;
    this.lrDtoDeleteCashMemo.userName = this.userDataDtoReturnSession.userId;
    this.reasonDeleteValue = "";
  }

  deleteMethod() {
    this.userDataDeleteCashmemoDetails();
    this.memoService
      .deleteCashMemoService(this.lrDtoDeleteCashMemo)
      .subscribe((response) => {
        let retrunStatusForDeleteMemo = response;
        if (retrunStatusForDeleteMemo.status == "success") {
          swal({
            title: "CashMemo Deleted",
            text: "CashMemo " + this.enteredLrNumber + " Deleted Successfully",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          }).then(() => {
            this.viewMemoAlreadyGeneratedMsg = false;
            this.viewEditBtn = false;
            this.viewUpdateBtn = false;
            this.viewEditDelvBtn = false;
            this.viewDeleteBtn = false;
            this.viewPrintBtn = false;
            this.viewTransitMemoPrtBtn = false;
            this.viewSaveBtn = true;
            $("#" + this.pageId + "saveBtn").prop("disabled", false);
            this.clearFields();
            this.memoNumberObject = "";
            this.memoConsolidatedEWB = null;
            this.ewayBillDCNo = null;
            this.newPartyViewPrint = false;
            $("#" + this.pageId + "lrNumber").val("");
            $("#" + this.pageId + "lrNumber").focus();
            this.reasonDeleteValue = "";
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Update Failed",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  editDelvBtn() {
    //console.log( this.returnLrNumberDtls );
    if (this.returnLrNumberDtls.isEditDelete == true) {
      if (
        this.returnLrNumberDtls.memoStatus != null &&
        this.returnLrNumberDtls.memoPayMentSts != null
      ) {
        if (
          this.returnLrNumberDtls.memoStatus == "collection" ||
          this.returnLrNumberDtls.memoStatus == "To party"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Edit is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (
          this.returnLrNumberDtls.memoStatus == "completed" &&
          this.returnLrNumberDtls.memoPayMentSts == "completed" &&
          this.returnLrNumberDtls.paymentMode != "cash"
        ) {
          swal({
            title: "Warning",
            text: "Cash Memo Edit is Blocked",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          swal({
            title: "Confirm Update",
            text: "Sure U want to Edit this Cashmemo",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((yesBtn) => {
            if (yesBtn) {
              this.activateFieldsForUpdateDelMode();
            } else {
              swal.close();
            }
          });
        }
      }
    } else {
      swal({
        title: "Warning",
        text: "Cash Memo Edit is Blocked Same Day Memo Only Edit Access",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  activateFieldsForUpdateDelMode() {
    if (this.isAreaBasedLogic == true) {
      $("#" + this.pageId + "areaBillingDtlsFieldSet").prop("disabled", true);
    } else {
      $("#" + this.pageId + "areaCollectionDtlsFieldSet").prop(
        "disabled",
        true
      );
    }
    $("#" + this.pageId + "collectionMan").prop("disabled", true);
    $("#" + this.pageId + "paymentMode").prop("disabled", false);
    $("#" + this.pageId + "deliveryMode").prop("disabled", true);
    $("#" + this.pageId + "dropDownInputFieldVehicleNumber").prop(
      "disabled",
      true
    );
    if (this.asPerRightShowUpdateBtn == true) {
      this.viewUpdateBtn = true;
    }

    this.viewEditDelvBtn = false;
    this.viewEditBtn = false;
    this.viewPrintBtn = false;
    this.viewTransitMemoPrtBtn = false;
    this.viewDeleteBtn = false;
    // enable payment modes fields for edit
    //i think below is not work here because we kept paymentMode as readonly
    //but due to this code added in ws simply copied and paste here need to show bhaiya
    //cbPaymentModes.setEnabled(true);
    //cbBankName.setEnabled(true);
    //txtCheqNumber.setReadOnly(false);
    //txtNeftRefNumber.setReadOnly(false);
    //dfCheqDate.setReadOnly(false);
  }

  methodToStoreData() {
    this.selectedPaymentType = $("#" + this.pageId + "paymentType").val();
    this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val(); //"ADD NEW"; means call this service 4370 addBankNames()

    this.enteredChequeNumber = $("#" + this.pageId + "chequeNumber").val();
    let NewBankName = $("#" + this.pageId + "newBankName").val();
    let chequeDate = $("#" + this.pageId + "chequeDate").val();
    this.enteredNewBankName = NewBankName;
    this.enteredChequeDate = chequeDate;
    //alert('before : '+this.enteredChequeDate);
    this.enteredChequeDate = this.datePipe.transform(
      this.enteredChequeDate,
      "yyyy-MM-dd"
    );
    //console.log( this.selectedPaymentType + "<< >>" + this.selectedBankName + "<< >>" + this.enteredReferenceNumber + "<< >>" + this.enteredChequeNumber + "<< >>" + this.enteredChequeDate );
    //alert('after : '+this.enteredChequeDate);
  }
  goodsTypeMethod(data) {
    this.selectedCommodityList = data.subCommodity;
  }

  methodForNoLrFieldsBtnOk() {
    this.lrDtoNoLrFields = new LRDto();
    this.lrDtoNoLrFields.lrNumber = this.returnLrNumberDtls.lrNumber;
    this.lrDtoNoLrFields.commodityName = this.selectedCommodityList;
    this.lrDtoNoLrFields.commodityCode = this.returnLrNumberDtls.column2;
    this.lrDtoNoLrFields.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoNoLrFields.mode = "UpdateConsignee";
  }
  noLrFieldsBtnOk() {
    this.methodForNoLrFieldsBtnOk();
    if (
      this.lrDtoNoLrFields.lrNumber != null &&
      this.lrDtoNoLrFields.commodityName != null &&
      this.lrDtoNoLrFields.commodityCode != null
    ) {
      this.memoService
        .updateCommodityDtlsService(this.lrDtoNoLrFields)
        .subscribe((response) => {
          //                    this.setAllLrFields( dataForAllYesLrFields );
          this.changeDetectorRef.detectChanges();
        }),
        (error) =>
          swal({
            title: "Server Error",
            text: "Problem occured while Saving New Collection Man",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }),
        () => console.log("done");
    }
    //      this.modalService.close( openEditReasonPopup );
    //      close
    this.setAllLrFields(this.returnLrNumberDtls);
  }

  noLrFieldsBtnClose() {
    //        this.modalService.close( openEditReasonPopup );
    //        close
    this.setAllLrFields(this.returnLrNumberDtls);
  }
  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  calculateMiscDiscInAdminRate() {
    var otherDiscChg = 0.0;
    if (this.isLCChg != null && this.isLCChg == true) {
      otherDiscChg =
        this.returnLrNumberDtls.lcChg == null
          ? 0.0
          : this.returnLrNumberDtls.lcChg;
    }

    if (this.isBCChg != null && this.isBCChg == true) {
      otherDiscChg += +(this.returnLrNumberDtls.bcChg == null
        ? 0.0
        : this.returnLrNumberDtls.bcChg);
    }

    if (this.isAoc != null && this.isAoc == true) {
      otherDiscChg += +(this.returnLrNumberDtls.aoc == null
        ? 0.0
        : this.returnLrNumberDtls.aoc);
    }

    if (this.isInsChg != null && this.isInsChg == true) {
      otherDiscChg += +(this.returnLrNumberDtls.insurance == null
        ? 0.0
        : this.returnLrNumberDtls.insurance);
    }

    if (this.isDD != null && this.isDD == true) {
      otherDiscChg += +(this.returnLrNumberDtls.ddAmt == null
        ? 0.0
        : this.returnLrNumberDtls.ddAmt);
    }

    if (this.isOthers != null && this.isOthers == true) {
      otherDiscChg += +(this.returnLrNumberDtls.others == null
        ? 0.0
        : this.returnLrNumberDtls.others);
    }
    console.log("calculateMiscDiscInAdminRate");
    console.log(otherDiscChg);
    console.log(this.hamaliSrcCustom);
    console.log(this.customHamaliSrcChg);
    if (this.isHamaliChg != null && this.isHamaliChg == true) {
      console.log("calculateMiscDiscInAdminRate-1");
      //CHG_V-CM:24
      if (this.hamaliSrcCustom != null && this.hamaliSrcCustom == true) {
        console.log("calculateMiscDiscInAdminRate-2");
        var srcHamaliCustomChgs = 0.0;
        srcHamaliCustomChgs =
          this.customHamaliSrcChg * this.returnLrNumberDtls.totalArticles;
        console.log(srcHamaliCustomChgs);
        otherDiscChg += +srcHamaliCustomChgs;
      } else {
        console.log("calculateMiscDiscInAdminRate-3");
        otherDiscChg += +(this.returnLrNumberDtls.hamali == null
          ? 0.0
          : this.returnLrNumberDtls.hamali);
      }
    }
    console.log("otherDiscChg");
    console.log(otherDiscChg);
    return otherDiscChg;
  }

  fovApplicableCalcNewLogic() {
    var fovChg = 0.0;
    if (this.unit != null && this.unit != "" && this.unit == "Rate") {
      if (this.isFOV != null && this.isFOV) {
        fovChg = this.fovChargeNewLogic();
      }
    }
    // fov calculate for disc->base frg @27/11/2021 - As per Sadakath
    else if (this.unit != null && this.unit != "" && this.unit == "Discount") {
      if ((this.srmDiscount = "onBaseFrgt")) {
        if (this.isFOV != null && this.isFOV) {
          fovChg = this.fovChargeNewLogic();
        }
      }
    }
    return fovChg;
  }
  fovChargeNewLogic() {
    var riskChg = 0.0;
    var riskChgCalculated = 0.0;
    var riskChgActual =
      this.returnLrNumberDtls.riskCharge == null
        ? 0.0
        : this.returnLrNumberDtls.riskCharge;
    console.log(
      "fovChargeNewLogic - " +
      this.dataListReturnOfConsigneeRateDtls[0].fovOn +
      " - " +
      this.returnLrNumberDtls.goodsValue +
      " - " +
      this.dataListReturnOfConsigneeRateDtls[0].fovPerc
    );
    console.log(this.dataListReturnOfConsigneeRateDtls[0]);
    // new fov logic @29/11/2021
    if (
      this.dataListReturnOfConsigneeRateDtls[0].fovOn != null &&
      this.dataListReturnOfConsigneeRateDtls[0].fovOn == "Fixed"
    ) {
      riskChgCalculated =
        (this.returnLrNumberDtls.goodsValue *
          this.dataListReturnOfConsigneeRateDtls[0].fovPerc) /
        100;
    } else if (
      this.dataListReturnOfConsigneeRateDtls[0].fovOn != null &&
      this.dataListReturnOfConsigneeRateDtls[0].fovOn == "Percentage"
    ) {
      riskChgCalculated =
        ((this.returnLrNumberDtls.riskCharge == null
          ? 0.0
          : this.returnLrNumberDtls.riskCharge) *
          this.dataListReturnOfConsigneeRateDtls[0].fovPerc) /
        100;
    } else {
      riskChgCalculated =
        this.returnLrNumberDtls.riskCharge == null
          ? 0.0
          : this.returnLrNumberDtls.riskCharge;
    }
    riskChg = riskChgActual - riskChgCalculated;
    console.log(
      "fovChargeNewLogic - " +
      " - " +
      riskChgActual +
      " - " +
      riskChgCalculated +
      " - " +
      riskChg
    );
    this.fovLessAmt = Math.round(riskChg);
    return Math.round(riskChgCalculated);
  }

  getOtherChgDetails(selectedArea) {
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();
    this.rateMasterDtoOtherChg = new RateMasterDto();
    this.rateMasterDtoOtherChg.mainStation =
      this.userDataDtoReturnSession.mainStation;
    this.rateMasterDtoOtherChg.area = selectedArea;
    if (
      this.selectedDeliveryType != null &&
      this.selectedDeliveryType == "Door Delivery"
    ) {
      this.rateMasterDtoOtherChg.deliveryType = "DD";
    } else if (
      this.selectedDeliveryType != null &&
      this.selectedDeliveryType == "Godown"
    ) {
      this.rateMasterDtoOtherChg.deliveryType = "GD";
    }
    //        console.log( this.rateMasterDtoOtherChg );
  }

  getOtherChgDetailsList = (selectedArea) => {
    this.lrDtosOtherChg = [];
    this.enteredOtherChg = "";
    $("#" + this.pageId + "others").val("");
    this.getOtherChgDetails(selectedArea);
    console.log("getOtherChgDetailsList");
    this.masterReadService
      .getDestMemoOtherChgDetails(this.rateMasterDtoOtherChg)
      .subscribe((response) => {
        if (response.length == 0) {
          //                    swal( {
          //                        title: "Record Not Found",
          //                        text: "No records found for other charge Details",
          //                        icon: "warning",
          //                        closeOnClickOutside: false,
          //                        closeOnEsc: false,
          //                    } );
          this.calculateDisc();
          this.logicForGST();
        } else {
          this.lrDtosOtherChg = response;
          console.log("getOtherChgDetailsList-2");
          console.log(this.lrDtosOtherChg[0]);
          if (this.lrDtosOtherChg[0].ratePerUnit == "Article") {
            this.enteredNoOfArticle = $(
              "#" + this.pageId + "noOfArticle"
            ).val();
            $("#" + this.pageId + "others").val(
              this.enteredNoOfArticle * this.lrDtosOtherChg[0].chqAmt
            );
          } else if (this.lrDtosOtherChg[0].ratePerUnit == "Lumpsum") {
            $("#" + this.pageId + "others").val(this.lrDtosOtherChg[0].chqAmt);
          }

          // CHG-V-CM:03
          if (
            this.userDataDtoReturnSession.mainStation != null &&
            this.userDataDtoReturnSession.mainStation == "Chennai"
          ) {
            if (
              this.selectedDeliveryType == "Door Delivery" &&
              this.returnLrNumberDtls.isRateEffect == true
            ) {
              $("#" + this.pageId + "others").val(0);
            }
          }

          this.calculateDisc();
          this.logicForGST();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Error",
          text: "Server Error While Getting Other Chg Details",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  };

  clickCommodityComboTA(inp) {
    inp._elementRef.nativeElement.value = "";
    inp._elementRef.nativeElement.dispatchEvent(new Event("input"));
    inp._elementRef.nativeElement.focus();
  }

  localChgBlur(e) {
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();

    if (this.enteredLocalCharge > this.enteredTotalDiscount) {
      swal({
        title: "Not Applicable",
        text: "Discount Amount is less than Local Charge",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        swal.close();
        window.setTimeout(function () {
          $("#" + this.pageId + "localCharge").val("");
          //                        $( '#localCharge' ).focus();
        }, 100);
      });
    } else {
      this.calculateDiscount();
      this.logicForGST();
      //                $( "#hamaliDd" ).focus();
    }
  }

  hamaliGdBlur(e) {
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    //            this.enteredHamaliGd = this.enteredHamaliGd * this.enteredNoOfArticle;
    //            console.log( this.enteredHamaliGd + " << >> " + this.enteredNoOfArticle );
    //Basha
    //            this.totalHamaliGd = +( this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd.toFixed(1) ) * +this.enteredNoOfArticle;
    //            $( "#hamaliGd" ).val( this.totalHamaliGd );
    this.totalHamaliGd =
      +(this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd) *
      +this.enteredNoOfArticle;
    $("#" + this.pageId + "hamaliGd").val(Math.round(this.totalHamaliGd));
    if (this.srmRate == "0.0" || this.srmRate == "0") {
      this.calculateDisc();
    } else if (this.unit != null || this.unit != "") {
      if (this.unit == "Rate") {
        this.calculateDisc();
      } else if (this.unit == "Discount") {
        if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
          this.calculateDisc();
        } else if (this.srmDiscount == "onTotal") {
          this.calculateDiscOnTotal();
        }
      }
    }
    this.logicForGST();
    //            $( "#srvChgBillSide" ).focus();
  }

  unloadingHamaliBlur(e) {
    this.enteredUnloadingHamali = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    this.selectedDeliveryType = $("#" + this.pageId + "deliveryType").val();

    this.totalUnloadingHamali =
      +this.unloadingHamali * +this.enteredNoOfArticle;
    //            $( "#unloadingHamali" ).val( this.totalUnloadingHamali );
    this.unloadingHamali = Math.round(this.totalUnloadingHamali);
    if (this.srmRate == "0.0" || this.srmRate == "0") {
      this.calculateDisc();
    } else if (this.unit != null || this.unit != "") {
      if (this.unit == "Rate") {
        this.calculateDisc();
      } else if (this.unit == "Discount") {
        if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
          this.calculateDisc();
        } else if (this.srmDiscount == "onTotal") {
          this.calculateDiscOnTotal();
        }
      }
    }
    this.logicForGST();
    if (this.selectedDeliveryType == "Godown") {
      //                $( "#rcptChgBillSide" ).focus();
    } else if (this.selectedDeliveryType == "Door Delivery") {
      //                $( "#hamaliDd" ).focus();
    }
  }

  hamaliDdBlur(e) {
    if (this.dataListReturnOfConsigneeRateDtls == "") {
      console.log("inside other logic - no rate -hamaliDdBlur");
    } else {
      console.log("inside other logic 1 -hamaliDdBlur");
      console.log(this.dataListReturnOfConsigneeRateDtls[0]);
      if (this.userDataDtoReturnSession.mainStation == "Chennai") {
        console.log("inside other logic 2-hamaliDdBlur");
        if (
          this.selectedDeliveryType != null &&
          this.selectedDeliveryType == "Door Delivery"
        ) {
          //$("#" + this.pageId + "srmOtherChg").val(dataForSetFields[i].otherChgAmt);
          if (
            this.dataListReturnOfConsigneeRateDtls[0].otherChgUnit == "Article"
          ) {
            console.log("inside other logic 3-hamaliDdBlur");
            this.enteredNoOfArticle = $(
              "#" + this.pageId + "noOfArticle"
            ).val();
            $("#" + this.pageId + "others").val(
              this.enteredNoOfArticle *
              this.dataListReturnOfConsigneeRateDtls[0].otherChgAmt
            );
            console.log(this.dataListReturnOfConsigneeRateDtls[0].otherChgAmt);
          } else if (
            this.dataListReturnOfConsigneeRateDtls[0].otherChgUnit == "Lumpsum"
          ) {
            $("#" + this.pageId + "others").val(
              this.dataListReturnOfConsigneeRateDtls[0].otherChgAmt
            );
          }
        }
      }
    }
    this.hamaliDdCalcMethodBlur();
  }

  hamaliDdCalcMethodBlur() {
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();

    //        if ( this.calcGrandTotal = "cashmemo calc gT with hamaliDd" ) {
    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      this.totalHamaliDd = +this.enteredHamaliDd * +this.enteredNoOfArticle;
      //            $( "#hamaliDd" ).val( this.totalHamaliDd.toFixed( 1 ) );
      //            this.hamaliDd = this.totalHamaliDd.toFixed( 1 );
      //As per Asrar Bhai change to math.round
      $("#" + this.pageId + "hamaliDd").val(Math.round(this.totalHamaliDd));
      this.hamaliDd = Math.round(this.totalHamaliDd);
      //CHG_V-CM:08
      if (this.hamaliDDRateLessStatus) {
        $("#" + this.pageId + "ddHamaliLess").val(this.hamaliDd);
      }
      this.calculateDiscount();

      if (this.srmRate == "0.0" || this.srmRate == "0") {
        this.calculateDisc();
      } else if (this.unit != null || this.unit != "") {
        if (this.unit == "Rate") {
          this.calculateDisc();
        } else if (this.unit == "Discount") {
          if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
            this.calculateDisc();
          } else if (this.srmDiscount == "onTotal") {
            this.calculateDiscOnTotal();
          }
        }
      }
      this.logicForGST();
      // $( "#rcptChgBillSide" ).focus();
    } else {
      this.totalHamaliDd = +this.enteredHamaliDd * +this.enteredNoOfArticle;
      //            console.log( this.totalHamaliDd );
      //            $( "#hamaliDd" ).val( this.totalHamaliDd.toFixed( 1 ) );
      //            this.hamaliDd = this.totalHamaliDd.toFixed( 1 );
      //As per Asrar Bhai change to math.round
      this.hamaliDd = Math.round(this.totalHamaliDd);
      $("#" + this.pageId + "hamaliDd").val(Math.round(this.totalHamaliDd));
      this.logicForGST();
      // $( "#hamaliGd" ).focus();
    }
  }

  rcptChgBillSideBlur(e) {
    this.calculateDisc();
    this.logicForGST();
    //            $( "#srvChgBillSide" ).focus();
  }
  srvChgBillSideBlur(e) {
    this.calculateDisc();
    this.logicForGST();

    //            $( "#demurrage" ).focus();
  }
  demurrageBlur(e) {
    this.subTotalWithDemurage();
    this.logicForGST();
  }

  othersBlur(e) {
    this.enteredOtherChg = $("#" + this.pageId + "others").val();
    this.enteredNoOfArticle = $("#" + this.pageId + "noOfArticle").val();
    this.enteredOtherChg = +this.enteredOtherChg * +this.enteredNoOfArticle;
    $("#" + this.pageId + "others").val(this.enteredOtherChg);

    this.calculateDisc();
    this.logicForGST();
  }

  totalDiscountBlur(e) {
    this.enteredSrmRate = $("#" + this.pageId + "srmRate").val();
    this.enteredSrmDiscount = $("#" + this.pageId + "srmDiscount").val();
    if (this.srmRate == "0.0" || this.srmRate == "0") {
      this.calculateDisc();
    } else if (this.unit != null || this.unit != "") {
      if (this.unit == "Rate") {
        this.calculateDisc();
      } else if (this.unit == "Discount") {
        if (this.srmDiscount == "onLr" || this.srmDiscount == "onBaseFrgt") {
          this.calculateDisc();
        } else if (this.srmDiscount == "onTotal") {
          this.calculateDiscOnTotal();
        }
      }
    }
    this.logicForGST();
  }

  calculateTheTotalAmt() {
    var totalAmt = 0;
    this.enteredLorryFreight = $("#" + this.pageId + "toPay").val();
    this.enteredServiceCharge = $("#" + this.pageId + "srvChgBillSide").val();
    this.enteredReceiptCharge = $("#" + this.pageId + "rcptChgBillSide").val();
    this.enteredDemurage = $("#" + this.pageId + "demurrage").val();
    this.enteredddHamaliLess = $("#" + this.pageId + "ddHamaliLess").val();
    this.enteredUnloadingHamali = $(
      "#" + this.pageId + "unloadingHamali"
    ).val();
    this.enteredHamaliGd = $("#" + this.pageId + "hamaliGd").val();
    this.enteredHamaliDd = $("#" + this.pageId + "hamaliDd").val();
    this.enteredGrandTotal = $("#" + this.pageId + "grandTotal").val();
    //        this.enteredSrmRate = $( "#srmRate" ).val();
    this.enteredLocalCharge = $("#" + this.pageId + "localCharge").val();
    this.enteredOtherChg = $("#" + this.pageId + "others").val();
    this.enteredTotalDiscount = $("#" + this.pageId + "totalDiscount").val();
    //        if ( this.userDataDtoReturnSession.mainStation != null
    //            && this.userDataDtoReturnSession.mainStation == "Chennai" ) {
    //
    //            this.hamaliAmt = this.hamaliDd == null ? 0 : this.hamaliDd;
    //        } else {
    //            this.hamaliAmt = this.enteredHamaliGd == null ? 0 : this.enteredHamaliGd;
    //        }

    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Bangalore"
    ) {
      totalAmt =
        +(this.enteredLorryFreight == null
          ? 0
          : this.enteredLorryFreight == ""
            ? 0
            : this.enteredLorryFreight) +
        +(this.enteredServiceCharge == null
          ? 0
          : this.enteredServiceCharge == ""
            ? 0
            : this.enteredServiceCharge) +
        +(this.enteredReceiptCharge == null
          ? 0
          : this.enteredReceiptCharge == ""
            ? 0
            : this.enteredReceiptCharge) +
        +(this.enteredDemurage == null
          ? 0
          : this.enteredDemurage == ""
            ? 0
            : this.enteredDemurage) +
        +(+(this.enteredUnloadingHamali == null
          ? 0
          : this.enteredUnloadingHamali == ""
            ? 0
            : this.enteredUnloadingHamali)) +
        +(this.enteredOtherChg == null
          ? 0
          : this.enteredOtherChg == ""
            ? 0
            : this.enteredOtherChg) +
        +(this.enteredLocalCharge == null
          ? 0
          : this.enteredLocalCharge == ""
            ? 0
            : this.enteredLocalCharge) +
        +(this.enteredHamaliGd == null
          ? 0
          : this.enteredHamaliGd == ""
            ? 0
            : this.enteredHamaliGd);
      console.log("bangalore");
    } else {
      totalAmt =
        +(this.enteredLorryFreight == null
          ? 0
          : this.enteredLorryFreight == ""
            ? 0
            : this.enteredLorryFreight) +
        +(this.enteredServiceCharge == null
          ? 0
          : this.enteredServiceCharge == ""
            ? 0
            : this.enteredServiceCharge) +
        +(this.enteredReceiptCharge == null
          ? 0
          : this.enteredReceiptCharge == ""
            ? 0
            : this.enteredReceiptCharge) +
        +(this.enteredDemurage == null
          ? 0
          : this.enteredDemurage == ""
            ? 0
            : this.enteredDemurage) +
        +(+(this.enteredUnloadingHamali == null
          ? 0
          : this.enteredUnloadingHamali == ""
            ? 0
            : this.enteredUnloadingHamali)) +
        +(this.enteredOtherChg == null
          ? 0
          : this.enteredOtherChg == ""
            ? 0
            : this.enteredOtherChg) +
        +(this.enteredLocalCharge == null
          ? 0
          : this.enteredLocalCharge == ""
            ? 0
            : this.enteredLocalCharge) +
        +(this.enteredHamaliGd == null
          ? 0
          : this.enteredHamaliGd == ""
            ? 0
            : this.enteredHamaliGd) +
        +(this.enteredHamaliDd == null
          ? 0
          : this.enteredHamaliDd == ""
            ? 0
            : this.enteredHamaliDd);
      console.log("others");
    }

    console.log(totalAmt, this.enteredGrandTotal);

    totalAmt =
      totalAmt -
      +(this.enteredTotalDiscount == null
        ? 0
        : this.enteredTotalDiscount == ""
          ? 0
          : this.enteredTotalDiscount);
    console.log(totalAmt);
    console.log(totalAmt, this.enteredGrandTotal);
    if (totalAmt == this.enteredGrandTotal) {
      //            console.log( 'equal' );
      this.createMemo();
    } else {
      //            console.log( 'notequal' );
      swal({
        title: "Warning",
        text: "Total Amount Does not Tally With Grand Total",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  //CashMemoReport Pop up functionality start
  /*get() {
        this.lrDtoCashMemoRpt = new LRDto();
        this.lrDtoCashMemoRpt.lrNumber = this.rateMasterDtoFromCashMemoRptLocalStorage.gcNoteNumber;
        console.log(this.lrDtoCashMemoRpt.lrNumber);
        this.memoReport.getLrdetailsForCashMemoRpt(this.lrDtoCashMemoRpt).subscribe(
            (response) => {
                this.lrDtoReturnCashMemoRpt = null;
                this.lrDtoReturnCashMemoRpt = response;
                console.log(this.lrDtoReturnCashMemoRpt.status);
                console.log(this.lrDtoReturnCashMemoRpt);
                //this.setExistingGeneratedLrFieldsDetails(this.lrDtoReturnCashMemoRpt);
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting Lr Details For CashMemo Rpt", "error");
            }, () => console.log('done');
    }*/
  //CashMemoReport Pop up functionality end

  methodForCodEnableLr() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm COD Enable",
        text: "Sure you want to enable to generate memo for this COD LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateCodLrEnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateCodLrEnable() {
    this.lrDtoBankLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBankLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBankLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBankLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateCodLrEnable() {
    this.methodForUpdateCodLrEnable();
    this.memoService
      .updateEnableCodLrDestMemo(this.lrDtoBankLr)
      .subscribe((response) => {
        let retrunStatusForEnableBankLr = response;
        if (retrunStatusForEnableBankLr.status == "Success") {
          swal({
            title: "COD LR Enabled",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Cashmemo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the COD LR for memo generation",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the COD LR for memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForUpdateHamaliConfirm() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Hamali/Other charges Edit Enable",
        text: "Sure you want to enable the Hamali/Other charges edit option for this LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateHamaliEditEnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateHamaliEditEnable() {
    this.lrDtoBankLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBankLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBankLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBankLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateHamaliEditEnable() {
    this.methodForUpdateHamaliEditEnable();
    this.memoService
      .updateEnableHamaliEditDestMemo(this.lrDtoBankLr)
      .subscribe((response) => {
        let retrunStatus = response;
        if (retrunStatus.status == "Success") {
          swal({
            title: "Hamali/Other charges Edit Enabled!",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to edit the Hamali/Other charges for the Cashmemo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the edit option for Hamali/Other charges!",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForEnableCCCopyConfirm() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Enable Memo Generation",
        text: "Sure you want to enable the Memo Generation for this CC Copy LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateCCCopyEditEnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateCCCopyEditEnable() {
    this.lrDtoBankLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBankLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBankLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBankLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateCCCopyEditEnable() {
    this.methodForUpdateCCCopyEditEnable();
    this.memoService
      .updateEnableCCCopyDestMemo(this.lrDtoBankLr)
      .subscribe((response) => {
        let retrunStatus = response;
        if (retrunStatus.status == "Success") {
          swal({
            title: "CC Copy LR Memo Generation Enabled!",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Memo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the the Memo Generation!",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  methodForEnableDemurageConfirm() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == "") {
      swal({
        title: "Mandatory Fields",
        text: "Please enter LR Number to enable it!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Enable",
        text: "Sure you want to enable the Memo Generation for this Demurage Charges LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateDemurageLREnable();
        } else {
          swal.close();
        }
      });
    }
  }

  methodForUpdateDemurageEnable() {
    this.lrDtoBankLr = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrDtoBankLr.lrNumber = this.enteredLrNumber;
    this.lrDtoBankLr.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoBankLr.companyId = this.userDataDtoReturnSession.companyId;
  }
  updateDemurageLREnable() {
    this.methodForUpdateDemurageEnable();
    this.memoService
      .updateDemurageLREnableDestMemo(this.lrDtoBankLr)
      .subscribe((response) => {
        let retrunStatus = response;
        if (retrunStatus.status == "Success") {
          swal({
            title: "Demurage LR Memo Generation Enabled!",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " enabled to generate the Memo!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the the Memo Generation!",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  //CHG_V-CM:16
  validateDemurageApplicable() {
    this.isDemurageLr = false;
    let calculatedDemurage = 0;
    console.log("validateDemurageApplicable");
    if (
      this.returnLrNumberDtls.demurageExmp != null &&
      this.returnLrNumberDtls.demurageExmp == false
    ) {
      console.log("validateDemurageApplicable 1");
      if (
        this.userDataDtoReturnSession.demurrageExmpDays != null &&
        this.userDataDtoReturnSession.demurrageExmpDays > 0 &&
        this.returnLrNumberDtls.diffDays >
        this.userDataDtoReturnSession.demurrageExmpDays
      ) {
        console.log("validateDemurageApplicable 2");
        let demurageChg =
          this.userDataDtoReturnSession.demurrageChg == null
            ? 0
            : this.userDataDtoReturnSession.demurrageChg;
        let demurrageUnit =
          this.userDataDtoReturnSession.demurrageUnit == null
            ? 0
            : this.userDataDtoReturnSession.demurrageUnit;
        let diffDays =
          this.returnLrNumberDtls.diffDays -
          this.userDataDtoReturnSession.demurrageExmpDays;
        let lrChgWgt =
          this.returnLrNumberDtls.chargedWeight == null
            ? 0
            : this.returnLrNumberDtls.chargedWeight;
        let lrActWgt =
          this.returnLrNumberDtls.actualWeight == null
            ? 0
            : this.returnLrNumberDtls.actualWeight;

        if (demurrageUnit == "Charged Weight") {
          calculatedDemurage = lrChgWgt * demurageChg * diffDays;
        } else if (demurrageUnit == "Actual Weight") {
          calculatedDemurage = lrActWgt * demurageChg * diffDays;
        }
        if (calculatedDemurage > 0) {
          this.isDemurageLr = true;
        }

        console.log(this.isDemurageLr);
        console.log(calculatedDemurage);
      }
    }
    //temp set this, need to remove after deployment
    // this.isDemurageLr = false;
    return calculatedDemurage;
  }

  //CHG_V-CM:16
  blockedDemurageApplicableLrForDestMemoAlert(calculatedDemurageChg) {
    swal({
      title: "Memo Generation is Blocked ",
      text:
        "Demurage charges of Rs " +
        calculatedDemurageChg +
        " are applicable for this LR, please contact Admin to enable it.",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  collectionPaymentModeOptions(dataFroLrFields) {
    console.log("collectionPaymentModeOptions");
    if (dataFroLrFields.paymentMode == null) {
      this.hideOSMode = false;
      this.hideCashMode = false;
    } else if (dataFroLrFields.paymentMode == "Cash") {
      this.hideOSMode = true;
      this.hideCashMode = false;
    } else if (dataFroLrFields.paymentMode == "Outstanding") {
      this.hideOSMode = false;
      this.hideCashMode = true;
    }
  }

  //CHG_V-CM:21
  logicForLoadingHamaliValue() {
    console.log("logicForLoadingHamaliValue");
    let loadingHamaliChg = 0;
    let loadingHamaliUnit = null;
    let loadingHamaliValue = 0;
    loadingHamaliChg = this.userDataDtoReturnSession.loadingChg;
    loadingHamaliUnit = this.userDataDtoReturnSession.unitLoading;

    console.log(loadingHamaliChg);
    console.log(loadingHamaliUnit);

    if (loadingHamaliUnit != null && loadingHamaliUnit == "Article") {
      loadingHamaliValue =
        +loadingHamaliChg * +this.returnLrNumberDtls.totalArticles;
    } else if (
      loadingHamaliUnit != null &&
      loadingHamaliUnit == "Actual Weight"
    ) {
      loadingHamaliValue =
        +loadingHamaliChg * +this.returnLrNumberDtls.actualWeight;
    } else if (
      loadingHamaliUnit != null &&
      loadingHamaliUnit == "Charged Weight"
    ) {
      loadingHamaliValue =
        +loadingHamaliChg * +this.returnLrNumberDtls.chargedWeight;
    }
    console.log(loadingHamaliValue);
    $("#" + this.pageId + "hamaliGd").val(
      loadingHamaliValue == null ? 0 : Math.round(loadingHamaliValue)
    );

    this.calculateDisc();
    this.logicForGST();
  }

  getVehicleNumberDetails() {
    this.truckDataDtoVehicleNumber = new TruckDataDto();
    this.truckDataDtoVehicleNumber.office =
      this.userDataDtoReturnSession.mainStation;
    this.truckDataDtoVehicleNumber.isActive = true;
    this.truckDataDtoVehicleNumber.companyId =
      this.userDataDtoReturnSession.companyId;
  }

  getVehicleDetailsList() {
    this.getVehicleNumberDetails();
    this.masterReadService
      .getVehicleSetupMaster(this.truckDataDtoVehicleNumber)
      .subscribe((response) => {
        this.vehicleNumberOption = [];
        this.vehicleNumberTA = [];
        this.vehicleNumberOption = response;
        for (let i = 0; i < this.vehicleNumberOption.length; i++) {
          this.vehicleNumberTA.push(this.vehicleNumberOption[i]);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Server Error",
          "Problem occur while getting Vehicle Details",
          "error"
        );
      },
      () => console.log("done");
  }

  // clickListnerForDropDownInputFieldVehicleNumber(event) {
  //     this.modelDropDownVehicleNumber = event.item;
  //     $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val(this.modelDropDownVehicleNumber.truckNumber);
  // }

  generateConsolidateEwaybillForGeneratedMemo(type) {
    this.selectedVehicleNumber = $("#" + this.pageId + "gdVehicleNumber").val();
    this.ewayBillNo = $("#" + this.pageId + "ewayBillNo").val();
    console.log(
      "generateConsolidateEwaybillForGeneratedMemo - " +
      this.selectedVehicleNumber +
      " - " +
      this.ewayBillNo
    );
    if (
      this.ewayBillNo != null &&
      this.ewayBillNo != "0" &&
      this.ewayBillNo != "NA" &&
      this.ewayBillNo != "" &&
      (this.selectedVehicleNumber == null ||
        this.selectedVehicleNumber == "" ||
        this.selectedVehicleNumber.length < 7 ||
        this.selectedVehicleNumber.length > 11)
    ) {
      swal({
        title: "Not Allowed",
        text: "Please enter the Valid Vehicle Number for Consolidated Ewaybill!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Generate",
        text: "Sure you want to generate the Consolidated Ewaybill for this LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.generateConsolidateEwaybill(type, this.memoNumberObject);
        } else {
          swal.close();
        }
      });
    }
  }

  generateConsolidateEwaybill(type, memoNo) {
    this.ewayBillNo = $("#" + this.pageId + "ewayBillNo").val();
    this.requestDto = new RequestDto();
    this.requestDto.param1 = memoNo;
    this.requestDto.param2 = this.ewayBillNo;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.showSpinner = true;
    this.changeDetectorRef.detectChanges();
    this.ewaybillService
      .generateConsolidateEwaybillForLR(this.requestDto)
      .subscribe((response) => {
        if (response.status == "Success") {
          swal({
            title: "Completed",
            text:
              "Consolidated Eway bill generated. Eway Bill No : " +
              response.ewayBillNo,
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            if (type != null && type == "new") {
              this.printMemoMethod();
            }
          });
          this.memoConsolidatedEWB = response.ewayBillNo;
          this.downloadEWBPdf(response.ewayBillNo, response.token1, 5);
        } else {
          swal({
            title: "Failed",
            text: "Failed to generate the Consolidated Eway bill, please try again or contact Admin!",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            if (type != null && type == "new") {
              this.printMemoMethod();
            }
          });
        }
        this.showSpinner = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinner = false;
        swal(
          "Error",
          "Error while process the Consolidated Eway Bill generation request!",
          "error"
        );
      },
      () => console.log("done");
  }

  printCEWB() {
    if (this.memoConsolidatedEWB == null) {
      swal(
        "Not Allowed",
        "Consolidated Eway Bill not found for this Memo / LR, please verify it!",
        "warning"
      );
    } else {
      this.requestDto = new RequestDto();
      //this.requestDto.param1 = this.memoNumberObject;
      //this.requestDto.mode = 'DestMemo';
      this.showSpinner = true;
      this.changeDetectorRef.detectChanges();
      this.ewaybillService
        .generateEwaybillToken(this.requestDto)
        .subscribe((response) => {
          if (response.status == "Success") {
            this.downloadEWBPdf(this.memoConsolidatedEWB, response.token1, 5);
          } else {
            swal(
              "Failed",
              "Failed to generate the token for Consolidated Eway Bill print request, please contact Admin!",
              "error"
            );
          }
          this.showSpinner = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinner = false;
          swal(
            "Server Error",
            "Failed to generate the token for Consolidated Eway bill print request, please contact Admin!",
            "error"
          );
        },
        () => console.log("done");
    }
  }

  downloadEWBPdf(ewayBillNo, token, docType) {
    const jsonRequest = { ewbNo: [ewayBillNo], type: docType };

    const authToken = token;

    this.ewaybillService
      .downloadConsolidatedEWBApi(jsonRequest, authToken)
      .subscribe((data) => {
        console.log("downloadPdf - " + ewayBillNo);
        const blob = new Blob([data], { type: "application/pdf" });
        FileSaver.saveAs(blob, ewayBillNo + "-CEWB.pdf");
      });
  }

  generateDCForGeneratedMemo(type) {
    this.selectedVehicleNumber = $("#" + this.pageId + "gdVehicleNumber").val();
    this.ewayBillNo = $("#" + this.pageId + "ewayBillNo").val();
    console.log(
      "generateDCForGeneratedMemo - " +
      this.selectedVehicleNumber +
      " - " +
      this.ewayBillNo
    );

    this.validateDCGeneration(type);

    // this.ewayBillValidityDateTemp = {
    //     year: this.ewayBillValidityDate.getFullYear(),
    //     month: this.ewayBillValidityDate.getMonth() + 1,
    //     day: this.ewayBillValidityDate.getDate()
    // };
    // console.log(this.ewayBillValidityDateTemp);
    // console.log(this.ewayBillValidityDateTemp.getTime());
    // //validate ewaybill date, if expired then go for DC else consolidated

    // var ewayBillValidityDateStr = this.datePipe.transform(this.ewayBillValidityDate, "dd-MM-yyyy");

    // const todayDate = new Date();
    // var todayDateStr = this.datePipe.transform(todayDate, "dd-MM-yyyy");
    // console.log(ewayBillValidityDateStr + " - " + todayDateStr);
    // if (ewayBillValidityDateStr < todayDateStr) {
    //     console.log('ok');
    // }
    // // Parse the input date string
    // var givenDate = this.parseDate(ewayBillValidityDateStr);
    // console.log(givenDate);
    // // Compare the given date with today's date
    // var isAfterToday: boolean = this.isAfterToday(givenDate);

    // console.log(isAfterToday);

    // const today = new Date();
    // today.setHours(0, 0, 0, 0);
    // console.log(today + "-" + this.ewayBillValidityDate);
    // if (type != null && type == 'regenerate') {
    //     if (this.ewayBillValidityDate < today) {
    //         this.validateDCGeneration(type);
    //     } else {
    //         swal("Not Allowed", "Ewaybill validity date is not yet expired hence can't generate the Delivery Challan, please generate Consolidated Eway bill else contact Admin!", "warning");
    //     }
    // } else {
    //     this.validateDCGeneration(type);
    // }
  }

  validateDCGeneration(type) {
    if (
      this.ewayBillNo != null &&
      this.ewayBillNo != "0" &&
      this.ewayBillNo != "NA" &&
      this.ewayBillNo != "" &&
      (this.selectedVehicleNumber == null ||
        this.selectedVehicleNumber == "" ||
        this.selectedVehicleNumber.length < 7 ||
        this.selectedVehicleNumber.length > 11)
    ) {
      swal({
        title: "Not Allowed",
        text: "Please enter the Valid Vehicle Number for DC!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Generate",
        text: "Sure you want to generate the Delivery Challan for this LR?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.generateDCForLREwaybill(type);
        } else {
          swal.close();
        }
      });
    }
  }

  generateDCForLREwaybill(type) {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();

    this.requestDto = new RequestDto();
    this.requestDto.param1 = this.selectedVehicleNumber;
    this.requestDto.lrNumber = this.enteredLrNumber;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.showSpinner = true;
    this.changeDetectorRef.detectChanges();
    this.ewaybillService
      .generateDeliveryChallanForEwayBill(this.requestDto)
      .subscribe((response) => {
        if (response.respStatus == 1) {
          swal({
            title: "Completed",
            text:
              "Delivery Challan generated. Eway Bill No : " +
              response.respEwayBillNo,
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            if (type != null && type == "new") {
              this.printMemoMethod();
            }
          });
          this.ewayBillDCNo = response.respEwayBillNo;
          this.downloadEWBPdf(response.respEwayBillNo, response.remarks, 4);
        } else {
          if (response.status == "Not Expired") {
            swal(
              "Failed",
              "Ewaybill validity date is not yet expired hence can't generate the Delivery Challan, please generate Consolidated Eway bill else contact Admin!",
              "warning"
            );
          } else {
            swal(
              "Failed",
              "Failed to generate the Delivery Challan, please try again or contact Admin!",
              "error"
            );
          }
        }
        this.showSpinner = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinner = false;
        swal(
          "Server Error",
          "Failed to generate the Delivery Challan, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  // downloadLRDCPdf(ewayBillNo, token) {
  //     const jsonRequest = { "ewbNo": [ewayBillNo], "type": 4 };

  //     const authToken = token;

  //     this.ewaybillService.downloadLREWBPrintApi(jsonRequest, authToken).subscribe((data) => {
  //         console.log('downloadPdf - ' + ewayBillNo);
  //         const blob = new Blob([data], { type: 'application/pdf' });
  //         FileSaver.saveAs(blob, ewayBillNo + '-EWB.pdf');
  //     });
  // }

  printDCEwaybill() {
    if (this.ewayBillDCNo == null) {
      swal(
        "Not Allowed",
        "DC Eway Bill number not found for this Memo / LR, please verify it!",
        "warning"
      );
    } else {
      this.requestDto = new RequestDto();
      this.showSpinner = true;
      this.changeDetectorRef.detectChanges();
      this.ewaybillService
        .generateEwaybillToken(this.requestDto)
        .subscribe((response) => {
          if (response.status == "Success") {
            this.downloadEWBPdf(this.ewayBillDCNo, response.token1, 4);
          } else {
            swal(
              "Failed",
              "Failed to generate the token for DC Eway Bill print request, please contact Admin!",
              "error"
            );
          }
          this.showSpinner = false;
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinner = false;
          swal(
            "Server Error",
            "Failed to generate the token for DC Eway bill print request, please contact Admin!",
            "error"
          );
        },
        () => console.log("done");
    }
  }

  // Function to parse date from string
  private parseDate(dateString: string): Date {
    // Assuming the input date string is in the format "MM/dd/yyyy HH:mm:ss"
    console.log(dateString);
    const [month, day, year, time] = dateString.split(/[\s\/:]+/);
    console.log(new Date(+year, +month - 1, +day, +time));
    return new Date(+year, +month - 1, +day, +time);
  }

  // Function to compare date with today's date
  private isAfterToday(date: Date): boolean {
    if (!date) {
      return false;
    }
    // Get today's date without the time portion
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Compare the two dates
    return date.getTime() > today.getTime();
  }
  rowSelectedVehicleNo(e: NgbTypeaheadSelectItemEvent) {
    $("#" + this.pageId + "vehicleName").val("");
    console.log(e.item);
    $("#" + this.pageId + "vehicleName").val(e.item.vehicleName);
  }

}
