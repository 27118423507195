<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        <div class="container border">
            <div>
                <table width='100%'>
                    <td width='50%'><img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo"></td>
                    <td width='50%'>{{address}}</td>
                </table>
            </div>
        </div>
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>

                    <div class="row" style="padding: 25px;">
                        <div class="col-md-8">
                            <h6 style='margin-left:150px;font-size: 25px!important;text-transform: uppercase;' align='center'>
                                <u><strong>TDS Voucher</strong></u>
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <h6 style='font-size: 16px; '>
                                <strong>Date : </strong> <span>{{dateInDate}}</span>
                            </h6>
                        </div>

                    </div>
                </table>
            </div>
        </div>
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style='font-size: 25px!important; margin-left: 20px;'>
                                <strong>We Acknowledge TDS Deducton of Rs. {{totalHire}} at {{tdsPerc}} (TDS Amount)
									Rs.{{tdsAmt}}, On Following Truck Details</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>a) Truck Number</strong>
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>:</strong>&nbsp;&nbsp;&nbsp;{{lorryNo}}
                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>b) Pan Number</strong>
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>:</strong>&nbsp;&nbsp;&nbsp;<strong>{{panNo}}</strong>
                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>c) Owner Name</strong>
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>:</strong>&nbsp;&nbsp;&nbsp;{{supplierName}}
                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>d) Hireslip Number</strong>
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>:</strong>&nbsp;&nbsp;&nbsp;{{hireslipNumber}}
                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>e) Departure Date</strong>
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <strong>:</strong>&nbsp;&nbsp;&nbsp;{{departureDate}}
                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>

                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px; margin-left: 20px;'>

                            </h6>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>

                            </h6>
                        </div>
                        <div class="col-md-1">
                            <h6 style='font-size: 15px; margin-left: 20px;'>

                            </h6>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <h6 style='font-size: 15px; margin-left: 20px;'>
                                <span>Thank You, </span>
                            </h6>
                        </div>

                        <div class="col-md-6">
                            <h6 style='font-size: 15px;margin-left: 390px !important;'>

                                <span>Signature </span>
                            </h6>
                        </div>
                    </div>




                </table>
            </div>
        </div>



    </div>
</div>