<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">
            <div class="card" style="border: 1px solid darkcyan !important">
                <div class="row system_responsive" style="margin-bottom: 10px">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <label>Source</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="ti-home"></i>
																</span>
                                                            </div>
                                                            <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelSource" (selectItem)="clickListnerForSource($event)" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                                #instanceAgentName="ngbTypeahead" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                            [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>CFT Charged Weight</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}chargedWt" #chargedWt name="chargedWt" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px" />
                        <div class="col-md-12" style="text-align: center">
                            <button type="submit" (click)="confirmSave();" class="btn btn-success m-r-10" id="{{pageId}}saveBtn">Save</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">
								Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="col-md-12">
                                    <h6 class="card-title border_bottom">Commodity Wise CFT Exemption Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}cftExemptionCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCftExmpCommodity" [dtTrigger]="dtTriggerCftExmpCommodity">
                                            <thead>
                                                <tr>
                                                    <th>Commodity Name</th>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>Charged Wgt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cftExmpCommodityData of cftExmpCommodityDataList">
                                                    <td>{{ cftExmpCommodityData.commodityName }}</td>
                                                    <td>{{ cftExmpCommodityData.source }}</td>
                                                    <td>{{ cftExmpCommodityData.destination }}</td>
                                                    <td>{{ cftExmpCommodityData.chargedWgt }}</td>
                                                    <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(cftExmpCommodityData);">
															<i title="View" class="fas fa-edit"></i>
														</button>
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(cftExmpCommodityData)">
															<i title="Remove" class="fas fa-trash"></i>
														</button></td>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>