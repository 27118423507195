import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends



import { PaymentRoutes } from 'src/app/stock/payment/payment.routing';
import { HireslipAdvanceComponent } from 'src/app/stock/payment/hireslip-advance/hireslip-advance.component';
import { HireslipBalanceComponent } from 'src/app/stock/payment/hireslip-balance/hireslip-balance.component';
import { MaterialModule } from "src/app/material.module";
import { LrReportModule } from 'src/app/report/lr-report/lr-report.module';
@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(PaymentRoutes), 
        FormsModule, NgbModule,
         DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
          ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, 
          LrReportModule],
    declarations: [
        HireslipAdvanceComponent,
        HireslipBalanceComponent]
})
export class PaymentModule { }
