import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

@Component({
  selector: 'app-hireslip-history-report',
  templateUrl: './hireslip-history-report.component.html',
  styleUrls: ['./hireslip-history-report.component.css']
})
export class HireslipHistoryReportComponent implements OnInit {


    getDataFrmServiceFrHireslipHistoryTable: any;
getDataFrmServiceFrLrHistoryTable: any;

hireslipHistoryDataList: any; 
lrHistoryDataList: any;

onDestroyUnsubscribtionHireslipHistory:  Subscription;
onDestroyUnsubscribtionLrHistory:  Subscription;


//for datepicker starts
model: NgbDateStruct;
model2;
//for datepicker ends

loadingIndicator = true;
viewTypeHireslipHistory = true;
viewTypeLrHistory = false;


searchByHireslipNoWise =true;
searchByDateWise =false;

//for datePicker starts
hoveredDate: NgbDateStruct;
fromDate: NgbDateStruct;
toDate: NgbDateStruct;
closeResult: string;
//for datepicker ends
    pageId="hshrc";

@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

        dtTriggerHireslipHistory: Subject<any> = new Subject();
        dtTriggerLrHistory: Subject<any> = new Subject();

     
      
        dataTable: any;
        dtOptionsHireslipHistory: any;
        dtOptionLrHistory: any;


        constructor(private hireslipHistoryRpt:ReportService,public changeDetectorRef : ChangeDetectorRef){
          
            
        }
        
        rerender(): void {
            this.dtElements.forEach((dtElement: DataTableDirective) => {
              dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
      dtInstance.destroy();
              });
            });
          }

        

        
        
        
        
        ngOnInit(): void {
            
            
            this.dtOptionsHireslipHistory = {  
                    //};
                            //columns is used to for export and others starts
                    columns: [
                              {
                                  title: 'Truck No',
                                    data: 'truckNo'
                                },
                                {
                                    title: 'Depart Date',
                                    data: 'departDate'
                                },
                                {
                                    title: 'Depart Time',
                                    data: 'departTime'
                                },
                                {
                                    title: 'Driver',
                                    data: 'driver'
                                },
                                {
                                    title: 'Enter By',
                                    data: 'enterBy'
                                },
                                {
                                    title: 'Loaded By',
                                    data: 'loadedBy'
                                }, {
                                    title: 'Sch Date',
                                    data: 'schDate'
                                }, {
                                    title: 'Sch Time',
                                    data: 'schTime'
                                }, {
                                    title: 'Supplier',
                                    data: 'supplier'
                                }, {
                                    title: 'Tot Hire',
                                    data: 'totHire'
                                }, {
                                    title: 'Gur Wt',
                                    data: 'gurWt'
                                }, {
                                    title: 'Kan Wt',
                                    data: 'kanWt'
                                }, {
                                    title: 'Advance',
                                    data: 'advance'
                                }, {
                                    title: 'Balance',
                                    data: 'balance'
                                }, {
                                    title: 'Status',
                                    data: 'status'
                                }, {
                                    title: 'Reason',
                                    data: 'reason'
                                }
                    ],        
                    
                    
                  //columns is used to for export and others endss
                    
                    
                    // the below code is for button export starts
                        dom: 'Bfrtip',
                    /*buttons: [
                              'excel', 'print'
                          ],*/
                        buttons: [
                                      {
                                          extend: 'excel',
                                          text:      '<i class="fas fa-file-excel"> Excel</i>',
                                          titleAttr: 'Excel',
                                          exportOptions: {
                                              columns: ':visible'
                                          }
                                      },
                                      {
                                          extend: 'print',
                                          text:      '<i class="fas fa-print"> Print</i>',
                                          titleAttr: 'Print',

                                      }
                                      
                                 
                              ],    
                          
                          
                          
                       // the below code is for button export ends
                          
                          
                          //place holder in search/filter in datatable starts
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                        //place holder in search/filter in datatable ends

                          
                          
                          processing: true,
                        //scroll in datatable starts
                          responsive: true,
                          "scrollX": true,
                          "scrollY": 380,
                          "scrollCollapse": true,
                              //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                                  "paging": false,
                                  "info": false,
                               //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                        //scroll in datatable ends
                          

                    
                          
                          
                }
            
            this.dtOptionLrHistory = {  
                    //};
                            //columns is used to for export and others starts
                            columns: [
                                      {
                                          title: 'Lr No',
                                            data: 'lrNo'
                                        },
                                        {
                                            title: 'Updated By',
                                            data: 'updatedBy'
                                        },
                                        {
                                            title: 'Updated On',
                                            data: 'updatedOn'
                                        },
                                        {
                                            title: 'Status',
                                            data: 'status'
                                        },
                                        {
                                            title: 'Reason',
                                            data: 'reason'
                                        }
                            ],
                  //columns is used to for export and others endss
                    
                    
                    // the below code is for button export starts
                        dom: 'Bfrtip',
                    /*buttons: [
                              'excel', 'print'
                          ],*/
                        buttons: [
                                      {
                                          extend: 'excel',
                                          text:      '<i class="fas fa-file-excel"> Excel</i>',
                                          titleAttr: 'Excel',
                                          exportOptions: {
                                              columns: ':visible'
                                          }
                                      },
                                      {
                                          extend: 'print',
                                          text:      '<i class="fas fa-print"> Print</i>',
                                          titleAttr: 'Print',

                                      }

                              ],    
                          
                       // the below code is for button export ends
                          
                          
                          //place holder in search/filter in datatable starts
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                        //place holder in search/filter in datatable ends
                                                    
                          processing: true,
                        //scroll in datatable starts
                          responsive: true,
                          "scrollX": true,
                          "scrollY": 380,
                          "scrollCollapse": true,
                              //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                                  "paging": false,
                                  "info": false,
                               //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                        //scroll in datatable ends
                          
                }

          //the below code is for the getting data through json starts
//            this.supplierList.getAllData().subscribe(data => {
//                this.lrDispatchBknRptList = data['data'];
//                this.dtTriggerSummary.next();
//                } );
            this.getDataFrmServiceFrHireslipHistoryTable=this.hireslipHistoryRpt.getSummaryData()
            this.onDestroyUnsubscribtionHireslipHistory= this.getDataFrmServiceFrHireslipHistoryTable.subscribe(data => {
                this.hireslipHistoryDataList = data['data'];
                this.dtTriggerHireslipHistory.next();
                } );
            
            this.getDataFrmServiceFrLrHistoryTable = this.hireslipHistoryRpt.getSummaryData();
            this.onDestroyUnsubscribtionLrHistory = this.getDataFrmServiceFrLrHistoryTable.subscribe(data => {
                this.lrHistoryDataList = data['data'];
                this.dtTriggerLrHistory.next();
                } );
          //the below code is for the getting data through json ends
            
        }

        ngOnDestroy(): void {
            this.dtTriggerHireslipHistory.unsubscribe();
            this.dtTriggerLrHistory.unsubscribe();
            this.onDestroyUnsubscribtionHireslipHistory.unsubscribe();
            this.onDestroyUnsubscribtionLrHistory.unsubscribe();
          }
        
        //input field Hide and Show
        searchByMode( searchBy: string ) {
            if ( searchBy === 'hireslipNoWise' ) {
                this.searchByHireslipNoWise = true;
                this.searchByDateWise = false;

            } else if ( searchBy === 'dateWise' ) {
                this.searchByHireslipNoWise = false;
                this.searchByDateWise = true;
                
            }  else {
                this.searchByHireslipNoWise = false;
                this.searchByDateWise = false;
            }
        }
        
        
      //dataTable Hide and Show the code is to add in HTML file  
        viewTypeMode( viewType: string ) {
            if ( viewType === 'hireslipHistory' ) {
                this.viewTypeHireslipHistory = true;
                this.viewTypeLrHistory = false;

            } else if ( viewType === 'lrHistory' ) {
                this.viewTypeHireslipHistory = false;
                this.viewTypeLrHistory = true;
                
            }  else {
                this.viewTypeHireslipHistory = false;
                this.viewTypeLrHistory = false;
            }
        }
        
        //for datepicker
        // the selectToday is the method for  selecting todays'z date
        selectToday() {
            this.model = {
                year: my.getFullYear(),
                month: my.getMonth() + 1,
                day: my.getDate()
            };
        }
        
        //for datePicker
}
