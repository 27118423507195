<div *ngIf="isLoggedIn" id="{{pageId}}missAgentsScr">
    <h1 class="title_custom" mat-dialog-title>
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">LR Entry Missed LRs Agent</h6>
    </h1>
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;">
                    </mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Please Wait Loading Details.....</h6>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-content style="width: 36vw;">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-12">
                                <form>
                                    <div class="row" *ngIf="showSearchView">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <h6 class="card-title" align='left'
                                                        style="color: green; margin: auto; font-size: 14px;">
                                                        Search Info</h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showSearchView">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>&nbsp;&nbsp;&nbsp;&nbsp;LR No :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input #lrNumberForSearchView type="text" id="{{pageId}}lrNumberForSearchView"
                                                        (keyup)="validateLrNumberForSearchView($event)"
                                                        class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <h6 class="card-title" align='left'
                                                        style="color: green; margin: auto; font-size: 14px;">
                                                        General Info</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                <label>Agent Name</label>

                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="ti-home"></i>
                                                    </span>
                                                </div>

                                                <input #agentName id="{{pageId}}agentName" type="text" class="form-control"
                                                    placeholder="Select Agent" [(ngModel)]="challanAgentName"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngbTypeahead]="agentNameSearchTA"
                                                    [resultFormatter]="formatterAgentName"
                                                    [inputFormatter]="formatterAgentName"
                                                    (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                    #instanceAgentName="ngbTypeahead" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LR Number :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                    </span>
                                                </div>
                                                <input #lrNumber type="text" id="{{pageId}}lrNumber"
                                                    (keyup)="validateLrNumber($event)" class="form-control"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Remarks :</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                    </span>
                                                </div>
                                                <input #remarks type="text" id="{{pageId}}remarks" class="form-control"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>

        <div class="row">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" id="{{pageId}}nextBtn"
                    (click)="btnNextValidation();">Next</button>
                <button type="button" class="btn btn-outline-dark" id="{{pageId}}finishBtn"
                    (click)="btnFinishMethod();">Finish</button>
                <button type="button" class="btn btn-outline-dark" id="{{pageId}}resetBtn"
                    (click)="btnFinishMethod();">Reset</button>
                <button type="button" class="btn btn-outline-dark" id="{{pageId}}close();"
                    (click)="onCloseClick();">Close</button>
                <button *ngIf="showSearchView" type="button" class="btn btn-outline-dark" id="{{pageId}}blockBtn"
                    (click)="btnBlockMethod();"disabled>Block</button>
                <button *ngIf="showSearchView" type="button" class="btn btn-outline-dark" id="{{pageId}}unBlockBtn"
                    (click)="btnUnBlockMethod();"disabled>UnBlock</button>
            </div>

            <div class="col-md-12" style="padding-top: 10px; text-align: center;">
                <h6 style='color: green;'>No.Of LRs :
                    {{lRCountsValues}}</h6>
            </div>
        </div>
    </div>

</div>