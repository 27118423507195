import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';

@Component({
    selector: 'app-lr-performance-monitor-print',
    templateUrl: './lr-performance-monitor-print.component.html',
    styleUrls: ['./lr-performance-monitor-print.component.css']
})
export class LrPerformanceMonitorPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    pageId = "lrpmp";
    dataForLrPerformancePrint: any;
    printDetails: any;
    address: any;
    lrNumber: any;
    description: any;
    trackingDate: any;
    consigneeName: any;
    source: any;
    destination: any;
    bookingDateStr: any;
    departuredateStr: any;
    arrivalDateStr: any;
    deliveryDateStr: any;
    status: any;
    multiDescription: any;
    arrivalwithin: any;
    deliveredwithin: any;
    consignorName: any;
    enteredDateStr: any;

    constructor(private router: Router, private datePipe: DatePipe,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            this.dataForLrPerformancePrint = JSON.parse(localStorage.getItem('lrPerformanceMonitorPrint'));
            console.log("LR Print")
            console.log(this.dataForLrPerformancePrint);
            if (this.dataForLrPerformancePrint != null) {
                this.printDetails = this.dataForLrPerformancePrint;
            }
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.validatBeforePrint();
            // this.changeDetectorRef.detectChanges();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
                // this.changeDetectorRef.detectChanges();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {

        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
        this.lrNumber = this.printDetails.lrNumber == null ? ' ' : this.printDetails.lrNumber;
        this.consigneeName = this.printDetails.consigneeName == null ? ' ' : this.printDetails.consigneeName;
        this.source = this.printDetails.agentId == null ? ' ' : this.printDetails.agentId;
        this.destination = this.printDetails.destination == null ? ' ' : this.printDetails.destination;
        this.bookingDateStr = this.printDetails.bookingDateStr == null ? ' ' : this.printDetails.bookingDateStr;
        this.departuredateStr = this.printDetails.departuredateStr == null ? ' ' : this.printDetails.departuredateStr;
        this.arrivalDateStr = this.printDetails.arrivalDateStr == null ? ' ' : this.printDetails.arrivalDateStr;
        this.deliveryDateStr = this.printDetails.deliveryDateStr == null ? ' ' : this.printDetails.deliveryDateStr;
        this.status = this.printDetails.status == null ? ' ' : this.printDetails.status;
        this.description = this.printDetails.description == null ? ' ' : this.printDetails.description;
        this.arrivalwithin = this.printDetails.arrivalwithin == null ? ' ' : this.printDetails.arrivalwithin;
        this.deliveredwithin = this.printDetails.deliveredwithin == null ? ' ' : this.printDetails.deliveredwithin;
        this.consignorName = this.printDetails.consignorName == null ? ' ' : this.printDetails.consignorName;
        this.enteredDateStr = this.printDetails.enteredDateStr == null ? ' ' : this.printDetails.enteredDateStr;

        if (this.description != null) {
            let splitMessage = this.description.split("#");
            this.multiDescription = splitMessage;
        }

    }

    clearField() {
        this.address = '';
        this.lrNumber = '';
        this.consigneeName = '';
        this.source = '';
        this.destination = '';
        this.bookingDateStr = '';
        this.departuredateStr = '';
        this.arrivalDateStr = '';
        this.deliveryDateStr = '';
        this.status = '';
        this.description = '';
        this.arrivalwithin = '';
        this.deliveredwithin = '';
        this.consignorName = '';
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
