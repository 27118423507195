<div *ngIf="isLoggedIn"
	style="border-top:1px solid #000;border-right:1px solid #000;border-left:1px solid #000;border-bottom:1px solid #000;">
	<h1 class="title_custom" mat-dialog-title style="border-bottom:1px solid #000;">
		<h6 class="modal-title" id="{{pageId}}modal-basic-title" style="color: blue;"><strong>&nbsp;Block Lr
				Value</strong></h6>
	</h1>
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">Please
						Wait Updating Lr....</h6>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-content style="width: 36vw;">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<form>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}clerkName">
												<label>LR Number :</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-file-alt"></i>
													</span>
												</div>
												<input type="text" class="form-control" id="{{pageId}}lrNumber"
													name="lrNumber" aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}clerkName">
												<label>Reason:</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-file-alt"></i>
													</span>
												</div>
												<textarea class="form-control" rows="1" id="{{pageId}}reasonId"
													[(ngModel)]="reasonValue"
													[ngModelOptions]="{standalone: true}"></textarea>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Is GST Detained :</label>
												<div class="input-group-append">
													<div class="custom-control custom-checkbox">
														<input type="checkbox" class="custom-control-input"
															id="{{pageId}}isGstDetained">
														<label class="custom-control-label wtfull"
															for="{{pageId}}isGstDetained"></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions style="float: right;">

		<button *ngIf="showBlockBtn" type="button" class="btn btn-outline-dark" (click)="blockMethod();">Block</button>
		<button *ngIf="showUnBlockBtn" type="button" class="btn btn-outline-dark" (click)="unBlockMethod();">Un
			Block</button>
		<button type="button" class="btn btn-outline-dark" (click)="onCancelClick()" #cancelBtn
			id="{{pageId}}cancelBtn">Cancel</button>
	</div>
</div>