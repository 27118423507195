import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/*import { DragulaService, dragula } from "ng2-dragula";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material';*/

@Component({
	selector: 'app-memo-less-description-report',
	templateUrl: './memo-less-description-report.component.html',
	styleUrls: ['./memo-less-description-report.component.css']
})
export class MemoLessDescriptionReportComponent implements OnInit {

	memoLessDescDataList: any;
	onDestroyUnsubscribtionMemoLessDescription: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerMemoLessDescriptionDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsMemoLessDescriptionDetails: any;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;
	cashMemoDtoLocalStorage:CashMemoDto = new CashMemoDto();
	cashMemoDto:CashMemoDto = new CashMemoDto();
	pageId="mldrc";
	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.cashMemoDtoLocalStorage = JSON.parse(localStorage.getItem('popupMemoLessDescription'));
			localStorage.clear();
			this.getMemoLessDescriptionDetailsList(this.cashMemoDtoLocalStorage);
		}

	}

	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsMemoLessDescriptionDetails = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(discAmt);
			},
		}
	}


	ngOnDestroy(): void {
		this.dtTriggerMemoLessDescriptionDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		//this.dtTriggerMemoLessDescriptionDetails.next();
	}

	getMemoLessDescriptionDetailsList(cashMemoDtoLocalStorage) {
		this.cashMemoDto = new CashMemoDto();
		this.cashMemoDto.memoNumber = cashMemoDtoLocalStorage.memoNumber;
		this.memoReport.getMemoLessDescriptionDetailsReport(this.cashMemoDto).subscribe(
			(response) => {
				if (response.length > 0) {
					this.memoLessDescDataList = response;
					$("#"+this.pageId+"memoLessDescriptionTableId").DataTable().destroy();
					this.dtTriggerMemoLessDescriptionDetails.next();
				} else {
					swal("No Records", "No Records found for this search", "info");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				swal("Error", "Server Problem Occurred While getting the Memo Less Description Details", "info");
			},
			() => console.log('done');
	}

}

