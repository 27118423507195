<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card ">
				<div class="row">
					<!-- spinner start-->
					<div *ngIf="showSpinnerForAction" class="col-md-12">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
					<!-- spinner end-->
				</div>
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-2 ">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
												</span>
											</div>
											<input type="text" Placeholder="LR Number" class="form-control"
												id="{{pageId}}lrNumber" autocomplete="off"
												(keypress)="onKeyPressListnerForLRNumber($event);"
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-search"></i>
													</span>
												</div>
												<input #binsDropDown id="{{pageId}}dropDownInputFieldBins" type="text"
													class="form-control"
													(selectItem)="clickListnerForDropDownInputFieldBins($event)"
													[(ngModel)]="modelDropDownBins"
													[ngbTypeahead]="searchDropDownInputFieldBins"
													[resultFormatter]="formatterDropDownBins"
													[inputFormatter]="formatterDropDownBins"
													placeholder="Please Select The Bins"
													(keypress)="onKeyPressListnerForBinsDestination($event);"
													(focus)="focusDropDownBinsTA$.next($any($event).target.value)" />
											</div>
										</div>
									</div>
								</div>
								<!-- 											the first autocomplete ends -->


							</div>


						</div>
					</div>


					<div class="col-md-10 vl">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<div class="box-body">
										<h6 class="card-title">Eligible LRs</h6>
										<table datatable id="{{pageId}}eligibleLrsTableId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsEligibleLrs" [dtTrigger]="dtTriggerEligibleLrs">

											<thead>
												<tr>
													<th>LR Number</th>
													<th>Articles</th>
													<th>Consignor</th>
													<th>Consignee</th>
													<th>Booking Date</th>
													<th>Actual Wgt</th>
													<th>Charged Wgt</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let eligibleLrsData of eligibleLrsDataList ">
													<td>{{ eligibleLrsData.lrNumber }}</td>
													<td>{{ eligibleLrsData.totalArticles }}</td>
													<td>{{ eligibleLrsData.consignorName }}</td>
													<td>{{ eligibleLrsData.consigneeName }}</td>
													<td>{{ eligibleLrsData.bookingDate }}</td>
													<td>{{ eligibleLrsData.actualWeight }}</td>
													<td>{{ eligibleLrsData.chargedWeight }}</td>
													<td>{{ eligibleLrsData.totalAmount }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div class="col-md-6 vl">
									<div class="box-body">
										<h6 class="card-title">Remaining LRs</h6>
										<table datatable id="{{pageId}}remainingLrsTableId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionRemainingLrs" [dtTrigger]="dtTriggerRemainingLrs">
											<thead>
												<tr>
													<th>LR Number</th>
													<th>Articles</th>
													<th>Consignor</th>
													<th>Consignee</th>
													<th>Booking Date</th>
													<th>Actual Wgt</th>
													<th>Charged Wgt</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let remainingLrsData of remainingLrsDataList ">
													<td>{{ remainingLrsData.lrNumber }}</td>
													<td>{{ remainingLrsData.totalArticles }}</td>
													<td>{{ remainingLrsData.consignorName }}</td>
													<td>{{ remainingLrsData.consigneeName }}</td>
													<td>{{ remainingLrsData.bookingDate }}</td>
													<td>{{ remainingLrsData.actualWeight }}</td>
													<td>{{ remainingLrsData.chargedWeight }}</td>
													<td>{{ remainingLrsData.totalAmount }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--  to open  popup use this << (click)="openPopup(content)" >>  -->
					<!-- Row -->
					<div class="col-md-12">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}popupDetailsTwo">
								<ng-template #content let-save="close" let-cancel="close" let-d="dismiss">


									<div class="modal-header">
										<h5>Add New Truck Capacity</h5>
										<button type="button" class="close" aria-label="Close"
											(click)="d('Cross click')">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>

									<div class="modal-body">
										<div class="row" style="text-align: center;">
											<div class="col-sm-12 col-md-12">
												<h5>Add Truck Capacity</h5>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}truckCapacityPopup">
														<label>Truc Capacity (Tons)</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-clipboard-list"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-md-12 p-t-10">
												<button type="button" class="btn btn-outline-secondary"
													(click)="save('Save click')">Save</button>
												<button type="button" class="btn btn-outline-danger"
													(click)="cancle('Cancel click')">Cancel</button>

											</div>
										</div>
									</div>
								</ng-template>


							</div>
						</div>
					</div>
					<!--pop up end row-->
				</div>
				<div class="row">
					<div class="col-md-12" style="text-align: right;">
						<button class="btn btn-dark m-r-10" id="{{pageId}}backToStockForTripsheetBtn" mat-button
							matStepperPrevious>Back</button>
						<button class="btn btn-dark m-r-10" id="{{pageId}}backToStockForTripsheetBtn" (click)="check($event);">Back</button>
						<button class="btn btn-success m-r-10" (click)="stockTripNextBtn();" id="{{pageId}}nextToTripDetailsBtn"
							mat-button matStepperNext>Next</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>

</html>