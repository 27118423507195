<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetails">
            <ng-template #content let-a="close" let-b="close" let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Enter the LR Number to Enable</h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-9">
                            <form>
                                <div class="col-sm-12 col-md-12">

                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>LR Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
												class="fas fa-file-alt"></i>
											</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}lrNumber" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="enableCCCopy();">Enable</button>
                    <button type="button" class="btn btn-outline-dark" (click)="c('Cancel click')">Cancel</button>
                </div>
            </ng-template>

            <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="open2(content)">Enable Consignee Copy Print In LR Entry</a>
        </div>
    </div>
</div>