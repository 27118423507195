<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Transit LR's Details</h6>
				</div>
				<div class="row system_responsive">
					<div class="col-md-12  p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8"></div>
										</div>
										<div class="row p-t-10">
											<div class="col-md-8">
												<h6 class="card-title">{{consigneeNameInTable}}-
													Transit LR Details</h6>

											</div>

										</div>

										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}transitLrsDetailsId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsTransitLrsDetailsDetailsReport"
														[dtTrigger]="dtTriggerTransitLrsDetailsReport">

														<thead>
															<tr>
																<th>Lr Number</th>
																<th>Booking Date</th>
																<th>Source</th>
																<th>Consignor Name</th>
																<th>Articles</th>
																<th>To Pay</th>
																<th>Paid</th>
																<th>Actual Weight</th>
																<th>Charged Weight</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let transitLrsDetailsReportData of transitLrsDetailsReportDataList  ">
																<td>{{transitLrsDetailsReportData.lrNumber }}</td>
																<td>{{ transitLrsDetailsReportData.bookingDateStr
																	}}</td>
																<td>{{ transitLrsDetailsReportData.fromStation }}</td>
																<td>{{transitLrsDetailsReportData.consignorName }}</td>
																<td>{{ transitLrsDetailsReportData.totalArticles }}</td>
																<td>{{ transitLrsDetailsReportData.topay }}</td>
																<td>{{transitLrsDetailsReportData.paid }}</td>
																<td>{{ transitLrsDetailsReportData.actWeight }}</td>
																<td>{{ transitLrsDetailsReportData.chargedwt }}</td>
															</tr>
														</tbody>
														<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>
												</div>

											</div>
										</div>
									</div>


								</div>
							</div>

						</div>
					</div>



				</div>

				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>