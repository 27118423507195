<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <h6 id="{{pageId}}showHeader" style="margin-bottom: 0px;"> {{viewType}}</h6>
        </div>
    </div>
    <div class="row">
        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label class="label_custome">Delivery Date</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}deliveryDates" name="deliveryDates" ngbDatepicker #deliveryDates="ngbDatepicker" [(ngModel)]="deliveryDatesModal" autocomplete="off">
                                <div class="input-group-append" (click)="deliveryDates.toggle()">
                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
									</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label class="label_custome">LR Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-clipboard-list"></i>
									</span>
                                </div>
                                <input type="text" Placeholder="LR Number" class="form-control" id="{{pageId}}lrNumber" autocomplete="off" (keypress)="onKeyPressListnerForLRNumber($event);" aria-describedby="basic-addon11">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="control">
                            <div class="form-group" style="position: static;">
                                <label class="label_custome"></label>
                                <div class="input-group">
                                    <div class="input-group-prepend"></div>
                                    <button type="submit" id="{{pageId}}deliveryBtnId" class="dt-button" style="float:left;" (click)="validateDeliveredLrsBtn();">
										<span><i class="fas fa-truck"> Delivered </i></span>
									</button>
                                    <button type="submit" class="dt-button" style="float:left;" (click)="validateUnDeliveredLrsBtn(tripDeliveryLrsDetailsPopUpTemplate);">
										<span><i class="fas fa-undo-alt"> UnDelivered </i></span>
									</button>
                                    <button type="submit" class="dt-button" style="float:left;" (click)="validateTripCancelBtn(tripDeliveryLrsDetailsPopUpTemplate);">
										<span><i class="fas fa-times"> Trip Cancelled</i></span>
									</button>
                                    <button type="submit" id="{{pageId}}multipleLRDeliveryBtnId" class="dt-button" style="float:left;" (click)="validateDeliveredMultipleLrsBtn();">
										<span><i class="fas fa-truck"> Multiple LR Delivered </i></span>
									</button>
                                    <!--<button type="submit" class="dt-button">
										<span><i class="fas fa-file"> Goods Return</i></span>
									</button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <table datatable style="width: 100%;" id="{{pageId}}scheduleTripDeliveryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsScheduleTripDelivery" [dtTrigger]="dtTriggerScheduleTripDelivery">

                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>LR No </th>
                                    <th>Consignee Name</th>
                                    <th>Consignor Name</th>
                                    <th>Bkg Dt</th>
                                    <th>Art</th>
                                    <th>H.GD</th>
                                    <th>H.DD</th>
                                    <th>Act Wgt</th>
                                    <th>Chg Wgt</th>
                                    <th>Amount</th>
                                    <th>POD Copy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style='cursor: pointer;' (click)="slectedRowForTripDeliveryConfirm(scheduleTripDeliverySummaryData);" *ngFor="let scheduleTripDeliverySummaryData of scheduleTripDeliverySummaryDataList ">
                                    <td><input type="checkbox" [checked]="multiSelect" id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, scheduleTripDeliverySummaryData,i)" />
                                    </td>
                                    <td>{{ scheduleTripDeliverySummaryData.lrNumber }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.consigneeName }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.consignorName }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.bookingDateStr }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.totalArticles }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.hamaliGD }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.hamaliDD }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.actualWeight }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.chargedWeight }}</td>
                                    <td>{{ scheduleTripDeliverySummaryData.totalAmount }}</td>
                                    <td style="cursor: pointer; color: blue;text-decoration: underline;" (click)="getLrPodCopy(scheduleTripDeliverySummaryData.viewPodLrCopy);">{{ scheduleTripDeliverySummaryData.remarks }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <button class="btn btn-outline-danger" mat-button (click)="onCancelClick()">Cancel</button>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #tripDeliveryLrsDetailsPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForActionReasonPopup" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 15px;">Please Wait Loading....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Reason</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-comments"></i>
														</span>
                                                    </div>
                                                    <input #reasonName id="{{pageId}}reasonNameId" type="text" class="form-control" (selectItem)="clickListnerForReasonName($event)" [(ngModel)]="modelReasonName" [ngbTypeahead]="searchReasonName" [resultFormatter]="formatterReasonName" [inputFormatter]="formatterReasonName"
                                                        (keypress)="onKeyPressReasonNameDropDown($event);" (focus)="focusReasonNameTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div [hidden]="!viewNewReasonField" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>New Reason</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-comments"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}newReasonNameId" type="text" class="form-control" autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style='background: green;color: white;' class="btn btn-outline-dark" [hidden]="!viewOkPopUpBtn" id="{{pageId}}okPopupBtnId" (click)="okBtnClickReasonPopUp()">Ok</button>
                    <button type="button" style='background: green;color: white;' class="btn btn-outline-dark" [hidden]="!viewconfirmCancelPopUpBtn" id="{{pageId}}confirmCancelPopupBtnId" (click)="confirmCancelBtnClickReasonPopUp()">Confirm
						Cancel</button>
                    <button type="button" style='background: black;color: white;' class="btn btn-outline-dark" (click)="closePopUp()">Cancel</button>
                </div>
            </ng-template>
        </div>
    </div>
</div>