<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}rateHistoryId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Rate History Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
															<option selected value="partyWise">Party Wise</option>
															<option value="commodityWise">Commodity Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="fromDateModel" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="toDateModel" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="consigneeDestListener($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewParty" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewCommodity" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Commodity</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #commodity id="{{pageId}}commodity" name="commodity" type="text" class="form-control" placeholder="Select Commodity" [(ngModel)]="modelCommodityMain" [ngbTypeahead]="searchCommodityMain" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterCommodityMain"
                                                            [inputFormatter]="formatterCommodityMain" (focus)="focusCommodityMainTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source </label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintRateHistoryPartyRpt()" id="{{pageId}}printAllBtn" [hidden]="!partyWiseTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                            <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintRateHistoryCommodityRpt()" id="{{pageId}}printAllBtn" [hidden]="!commodityWiseTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <h6 class="card-title" [hidden]="!partyWiseTable">Party History Report</h6>
                                                <h6 class="card-title" [hidden]="!commodityWiseTable">Commodity History Report</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!partyWiseTable">
                                                    <table datatable id="{{pageId}}partyWiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateHistoryReportPartyWise" [dtTrigger]="dtTriggerRateHistoryReportPartyWise">

                                                        <thead>
                                                            <tr>
                                                                <th>Unit</th>
                                                                <th>Des.Ham</th>
                                                                <th>Unloading Charge(DD)</th>
                                                                <th>Unit(DD)</th>
                                                                <th>Unloading Charge(GD)</th>
                                                                <th>Unit(GD)</th>
                                                                <th>Rate</th>
                                                                <th>R.C</th>
                                                                <th>S.C</th>
                                                                <th>S.Ham</th>
                                                                <th>G.C</th>
                                                                <th>P Unit</th>
                                                                <th>Commodity</th>
                                                                <th>Fixed By</th>
                                                                <th>Effect From</th>
                                                                <th>Effect To</th>
                                                                <th>Source</th>
                                                                <th>LC</th>
                                                                <th>S.HAMALI</th>
                                                                <th>BC</th>
                                                                <th>AOC</th>
                                                                <th>F O V</th>
                                                                <th>DD</th>
                                                                <th>Others</th>
                                                                <th>Group By</th>
                                                                <th style="display:none;">Fixed On</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let rateHistoryReportPartyWiseData of rateHistoryReportPartyWiseDataList ">
                                                                <td>{{ rateHistoryReportPartyWiseData.column2 }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.hamaliDestination }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.unloadingChg }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.unloadingChgUnit }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.unloadingChgGd }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.unloadingChgUnitGd }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.rate }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.receiptCharges }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.serviceCharges }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.hamaliSource }}
                                                                </td>
                                                                <td>{{ rateHistoryReportPartyWiseData.gcCharge }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.perUnit== null ? "NA": rateHistoryReportPartyWiseData.perUnit== "" ? "NA":rateHistoryReportPartyWiseData.perUnit}}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.commodityName }}
                                                                </td>
                                                                <td>{{ rateHistoryReportPartyWiseData.fixedBy }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.effectiveFromDateStg}}
                                                                </td>
                                                                <td>{{ rateHistoryReportPartyWiseData.effectiveToDateSrg== null ? "NA": rateHistoryReportPartyWiseData.effectiveToDateSrg== "" ? "NA":rateHistoryReportPartyWiseData.effectiveToDateSrg}}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.sourceCode }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.lcChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.hamaliChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.bcChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.aoc == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.riskCharge == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.dd == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.others == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportPartyWiseData.groupBy }}</td>
                                                                <td style="display:none;">{{ rateHistoryReportPartyWiseData.column3 }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!commodityWiseTable">
                                                    <table datatable id="{{pageId}}commodityWiseId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateHistoryReportCommodityWise" [dtTrigger]="dtTriggerRateHistoryReportCommodityWise">

                                                        <thead>
                                                            <tr>
                                                                <th>Unit</th>
                                                                <th>Des.Ham</th>
                                                                <th>Unloading Charge(DD)</th>
                                                                <th>Unit(DD)</th>
                                                                <th>Unloading Charge(GD)</th>
                                                                <th>Unit(GD)</th>
                                                                <th>Rate</th>
                                                                <th>R.C</th>
                                                                <th>S.C</th>
                                                                <th>S.Ham</th>
                                                                <th>G.C</th>
                                                                <th>P Unit</th>
                                                                <th>Fixed By</th>
                                                                <th>Effect From</th>
                                                                <th>Effect To</th>
                                                                <th>Source</th>
                                                                <th>LC</th>
                                                                <th>S.HAMALI</th>
                                                                <th>BC</th>
                                                                <th>AOC</th>
                                                                <th>F O V</th>
                                                                <th>DD</th>
                                                                <th>Others</th>
                                                                <th>Group By</th>
                                                                <th style="display:none;">Fixed On</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let rateHistoryReportCommodityWiseData of rateHistoryReportCommodityWiseDataList ">
                                                                <td>{{ rateHistoryReportCommodityWiseData.column2 }}
                                                                </td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.hamaliDestination }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.unloadingChg }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.unloadingChgUnit }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.unloadingChgGd }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.unloadingChgUnitGd }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.rate }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.receiptCharges }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.serviceCharges }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.hamaliSource }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.gcCharge }}
                                                                </td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.perUnit== null ? "NA": rateHistoryReportCommodityWiseData.perUnit== "" ? "NA":rateHistoryReportCommodityWiseData.perUnit}}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.fixedBy }}
                                                                </td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.effectiveFromDateStg}}
                                                                </td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.effectiveToDateSrg== null ? "NA": rateHistoryReportCommodityWiseData.effectiveToDateSrg== "" ? "NA":rateHistoryReportCommodityWiseData.effectiveToDateSrg}}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.sourceCode }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.lcChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.hamaliChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.bcChg == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.aoc == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.riskCharge == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.dd == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.others == true ?'Yes': 'No' }}</td>
                                                                <td>{{ rateHistoryReportCommodityWiseData.groupBy }}
                                                                </td>
                                                                <td style="display:none;">{{ rateHistoryReportCommodityWiseData.column3 }}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>