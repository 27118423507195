<div *ngIf="showPrintForOtherBooking">
	<div class="page row system_responsive" id="{{pageId}}firstPrint">
		<div class="container border">
			<div class="col-md-12">
				<!--first row starts-->
				<div class="row" style="padding-top: 5px;">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-7">

								<!-- 							<img [src]='src/lrEntryBarcode/' -->
								<!-- 								+{{resultDtos.withOutSlash}}+'.jpg' style="height: 70px;"> -->

								<tr *ngFor="let PS of PrintBarcode">
									<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27"
										[bc-font-size]="13" [bc-value]="PS.barcode"
										[bc-display-value]="true"> </ngx-barcode> -->
									<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27"
										[bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="true">
									</ngx-barcode6>
								</tr>

							</div>
							<div class="col-md-5">
								<h6 class="border_bottom dsply-ib">GOODS CONSIGNMENT NOTE</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6" style="margin-left: -10px;">
								<div class="form-group">
									<div class="input-group">
										<h6>
											<strong>Head Office :</strong><br> <span [innerHTML]="mainAddress"></span>
											<br>Tel.No&nbsp;:<span [innerHTML]="mainMobileNo"></span>
											<br>Email&nbsp;&nbsp;:
											{{mainEmailId}}<br> <strong>CIN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
												U63090MH2009PTC189328</strong><br> <strong>ISO
												9001:2008</strong>
										</h6>
									</div>
								</div>


							</div>
							<div class="col-md-6">
								<h6>
									{{address1}}<br>{{address2}}<br>{{cityPin}} <br>Tel.No&nbsp;:
									{{phone}}<br>Email&nbsp;&nbsp;: {{emailId}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
					</div>
				</div>

				<!--first row ends -->
				<!--seconde row Starts-->
				<div class="row">
					<div class="col-md-4" style="margin-left: -7px; margin-top: -10px;">
						<h6 style="font-size: 17px">
							<!-- 							<strong>G.C.No. </strong> <strong>{{resultDtos.lrNumber}}</strong> -->
						</h6>
					</div>
					<div class="col-md-8" style="margin-left: -7px;">
						<h6>
							<strong>CONSIGNEE COPY</strong> <strong>{{printMode}}</strong>
						</h6>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12" style="margin-left: -7px;">
						<h6 align='center' width='100%' style='font-size: 14px; height: 20px;'>
							<strong> {{handleWitCare}}{{slashInCode}}</strong><span *ngIf="showSelfLr"><strong> SELF LR
									- DO NOT
									DELIVER WITHOUT CONSIGNEE COPY</strong></span>
						</h6>
					</div>
					<!-- 					<div *ngIf="showSelfLr" class="col-md-9" style="margin-left: -7px;"> -->

					<!-- 						<h6 align='center' width='100%' -->
					<!-- 							style='font-size: 14px; height: 20px;'> -->

					<!-- 						</h6> -->
					<!-- 					</div> -->
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNOR</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>&nbsp;: {{ resultDtos.consignorName }}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.addressConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.gstNoConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{resultDtos.consignorMobileNo }}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-6">

						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNEE</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>: {{ resultDtos.consigneeName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.addressConsignee }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.gstNoConsignee}}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.consigneeMobileNo }}</h6>
							</div>
						</div>
					</div>
				</div>
				<!--seconde row ends-->
				<!--third row starts-->
				<div class="row border_top border_bottom">
					<div class="col-md-6 txt_ac border_right">
						<h6>
							<strong>BOOKING DETAILS</strong>
						</h6>
					</div>
					<div class="col-md-6 txt_ac">
						<h6>
							<strong>AMOUNT CHARGES</strong>
						</h6>

					</div>
				</div>
				<!--third row ends-->
				<!--fourth row starts-->
				<div class="row">
					<div class="col-md-6 border_right">
						<div class="row">
							<div class="col-md-12">
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6 style="font-size: 17px">
											<strong>G.C.No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6 style="font-size: 17px">
											: <strong>{{resultDtos.lrNumber}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Booking Date</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.date}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Route</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{sourcePrint}}</strong> <strong> TO
											</strong><strong>{{destinationPrint}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>E-Way Bill No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{gateCcESugam}}&nbsp;&nbsp;{{eDecCom}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>No.of Articles</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.totalArticles}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Nature of Packing</strong>
										</h6>
									</div>
									<div class="col-md-8 fontS_11" style="margin-left: -50px;">
										<h6>: {{resultDtos.packNature}}</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Said to Contain</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.commodityName}}{{subCommodity}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Value of Goods</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>: Rs {{resultDtos.goodsValue}}/-</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
							</div>
						</div>


						<!-- 					<div class="row border_top" style="padding: 1px;"> -->
						<!-- 						<div class="col-md-12 fontS_10"> -->
						<!-- 							<h6> -->

						<!-- 							</h6> -->
						<!-- 						</div> -->
						<!-- 					</div> -->

					</div>
					<div class="col-md-2 border_right">
						<div class="row">
							<div class="col-md-12 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>WEIGHT(In Kgs.)</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Actual</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Charged</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong> {{resultDtos.actualWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>{{resultDtos.chargedWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 ">
								<div class="row">
									<div class="col-md-12 txt_ac">
										<!-- 										<h6>{{artSize}}{{artChgWt}}</h6> -->
										<h6 [innerHTML]="artSize"></h6>
										<h6>{{artChgWt}}</h6>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-4">
						<div class="row">
							<div class="col-md-6 border_bottom border_right">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<!-- 										<strong>FRIGHT(In Rs.)</strong> -->
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>FREIGHT(In Rs.)</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 border_right">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>ToPay</strong>
												</h6>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>Paid</strong>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>Base Freight</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Hamali</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Statistical Charges</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Others</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>L.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>B.C</strong>
										</h6>
									</div>

									<div class="col-md-12">
										<h6>
											<strong>A.O.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>F O V</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>DD</strong>
										</h6>
									</div>


									<!-- 								<div class="col-md-12" -->
									<!-- 									style="margin-top: 10px; border-top: 1px solid #000;"> -->
									<!-- 									<h6 class="fontS_11"> -->
									<!-- 										<strong>Total</strong> -->
									<!-- 									</h6> -->
									<!-- 								</div> -->


								</div>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;" id="{{pageId}}left">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
							<div class="col-md-3 border_left" style="text-align: right;" id="{{pageId}}right">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf=ViewPaid>{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--fourth row ends-->

				<div class="row border_top">
					<div class="col-md-6  border_right">
						<h6>
							<strong>Remark : &nbsp;&nbsp;&nbsp;{{gateCc}}</strong>
						</h6>
						<h6 style='font-size: 13px;'>
							&nbsp;&nbsp;&nbsp;&nbsp;<strong>{{unloadByCsn}}</strong>
						</h6>
					</div>
					<div class="col-md-2">
						<div class="row txt_ac">
							<div class="col-md-12 border_right">
								<h6>
									<strong>Bill.No / Pvt.Mark</strong><br>{{resultDtos.billNumber}}
									/ {{resultDtos.privateMarker}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac" style='min-height: 35px;'>
							<div class="col-md-6 border_right">
								<h6>
									<strong>TOTAL</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;">
								<h6 *ngIf="ViewToPay">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
							<div class="col-md-3">
								<h6 *ngIf="ViewPaid">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>

				<div class="row border_top">
					<div class="col-md-8 border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6>
									<strong>CONSIGNEE COPY</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right txt_ac">
								<h6>
									<strong>G.C.No. </strong><strong>{{resultDtos.lrNumber}}</strong>
								</h6>
							</div>
							<div class="col-md-3 txt_ac">
								<h6>
									<strong>Transport ID</strong><br> {{transportId}}
								</h6>
							</div>
						</div>



					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>FOR SRD Logistics Pvt.Ltd.</strong>
								</h6>
							</div>
						</div>
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>Prepared by</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style='margin-top: -8px;'>
					<div class="col-md-8  border_top border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6 style='font-size: 10px;'>
									<strong>DELIVERY SHALL BE AGAINST CONSIGNEE COPY ONLY</strong>
								</h6>
								<h6 style='font-size: 9px;'>This G.C Note is subject to the
									Terms & Conditions printed overleaf</h6>
								<h6>
									<strong>Booked at Owner's Risk</strong>
								</h6>
							</div>
							<div class="col-md-6">
								<h6>{{message}}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 10px; padding-top: 5px;'>
									<strong>{{entryByPrint}}{{clerkName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<h6 style='font-size: 10px; text-align: right; font-style: Brush Script MT;'>
									<strong>Thank You</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<!--  PRINT 1 ENDS HERE -->
	<!-- 	<div style='float: left'> -->

	<!-- 		<p style='overflow: hidden; page-break-before: always;'></p> -->

	<!-- 	</div> -->
	<div class="page row system_responsive" id="{{pageId}}secondPrint">
		<div class="container border">
			<div class="col-md-12">
				<!--first row starts-->
				<div class="row" style="padding-top: 5px;">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-7">

								<!-- 							<img [src]='src/cashMemoBarcode/' -->
								<!-- 								+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->

								<tr *ngFor="let PS of PrintBarcode">
									<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
										[bc-value]="PS.barcode" [bc-display-value]="true"> </ngx-barcode> -->
									<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27"
										[bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="true">
									</ngx-barcode6>
								</tr>

							</div>
							<div class="col-md-5">
								<h6 class="border_bottom dsply-ib">GOODS CONSIGNMENT NOTE</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6" style="margin-left: -10px;">
								<div class="form-group">
									<div class="input-group">
										<h6>
											<strong>Head Office :</strong><br> <span [innerHTML]="mainAddress"></span>
											<br>Tel.No&nbsp;:<span [innerHTML]="mainMobileNo"></span>
											<br>Email&nbsp;&nbsp;:
											{{mainEmailId}}<br> <strong>CIN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
												U63090MH2009PTC189328</strong><br> <strong>ISO
												9001:2008</strong>
										</h6>
									</div>
								</div>


							</div>
							<div class="col-md-6">
								<h6>
									{{address1}}<br>{{address2}}<br>{{cityPin}} <br>Tel.No&nbsp;:
									{{phone}}<br>Email&nbsp;&nbsp;: {{emailId}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
					</div>
				</div>

				<!--first row ends -->
				<!--seconde row Starts-->
				<div class="row">
					<div class="col-md-4" style="margin-left: -7px; margin-top: -10px;">
						<h6 style="font-size: 17px">
							<!-- 							<strong>G.C.No. </strong> <strong>{{resultDtos.lrNumber}}</strong> -->
						</h6>
					</div>
					<div class="col-md-8" style="margin-left: -7px;">
						<h6>
							<strong>CONSIGNOR COPY</strong>
						</h6>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12" style="margin-left: -7px;">
						<h6 align='center' width='100%' style='font-size: 14px; height: 20px;'>
							<strong> {{handleWitCare}}{{slashInCode}}</strong><span *ngIf="showSelfLr"><strong> SELF LR
									- DO NOT
									DELIVER WITHOUT CONSIGNEE COPY</strong></span>
						</h6>
					</div>
					<!-- 					<div *ngIf="showSelfLr" class="col-md-9" style="margin-left: -7px;"> -->

					<!-- 						<h6 align='center' width='100%' -->
					<!-- 							style='font-size: 14px; height: 20px;'> -->

					<!-- 						</h6> -->
					<!-- 					</div> -->
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNOR</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>&nbsp;: {{ resultDtos.consignorName }}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.addressConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.gstNoConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{resultDtos.consignorMobileNo }}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-6">

						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNEE</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>: {{ resultDtos.consigneeName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.addressConsignee }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.gstNoConsignee}}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.consigneeMobileNo }}</h6>
							</div>
						</div>
					</div>
				</div>
				<!--seconde row ends-->
				<!--third row starts-->
				<div class="row border_top border_bottom">
					<div class="col-md-6 txt_ac border_right">
						<h6>
							<strong>BOOKING DETAILS</strong>
						</h6>
					</div>
					<div class="col-md-6 txt_ac">
						<h6>
							<strong>AMOUNT CHARGES</strong>
						</h6>

					</div>
				</div>
				<!--third row ends-->
				<!--fourth row starts-->
				<div class="row">
					<div class="col-md-6 border_right">
						<div class="row">
							<div class="col-md-12">
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6 style="font-size: 17px">
											<strong>G.C.No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6 style="font-size: 17px">
											: <strong>{{resultDtos.lrNumber}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Booking Date</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.date}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Route</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{sourcePrint}}</strong> <strong> TO
											</strong><strong>{{destinationPrint}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>E-Way Bill No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{gateCcESugam}}&nbsp;&nbsp;{{eDecCom}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>No.of Articles</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.totalArticles}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Nature of Packing</strong>
										</h6>
									</div>
									<div class="col-md-8 fontS_11" style="margin-left: -50px;">
										<h6>: {{resultDtos.packNature}}</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Said to Contain</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.commodityName}}{{subCommodity}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Value of Goods</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>: Rs {{resultDtos.goodsValue}}/-</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
							</div>
						</div>


						<!-- 					<div class="row border_top" style="padding: 1px;"> -->
						<!-- 						<div class="col-md-12 fontS_10"> -->
						<!-- 							<h6> -->

						<!-- 							</h6> -->
						<!-- 						</div> -->
						<!-- 					</div> -->

					</div>
					<div class="col-md-2 border_right">
						<div class="row">
							<div class="col-md-12 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>WEIGHT(In Kgs.)</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Actual</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Charged</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong> {{resultDtos.actualWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>{{resultDtos.chargedWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 ">
								<div class="row">
									<div class="col-md-12 txt_ac">
										<!-- 										<h6>{{artSize}}{{artChgWt}}</h6> -->
										<h6 [innerHTML]="artSize"></h6>
										<h6>{{artChgWt}}</h6>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-4">
						<div class="row">
							<div class="col-md-6 border_bottom border_right">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<!-- 										<strong>FRIGHT(In Rs.)</strong> -->
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>FREIGHT(In Rs.)</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 border_right">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>ToPay</strong>
												</h6>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>Paid</strong>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>Base Freight</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Hamali</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Statistical Charges</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Others</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>L.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>B.C</strong>
										</h6>
									</div>

									<div class="col-md-12">
										<h6>
											<strong>A.O.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>F O V</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>DD</strong>
										</h6>
									</div>


									<!-- 								<div class="col-md-12" -->
									<!-- 									style="margin-top: 10px; border-top: 1px solid #000;"> -->
									<!-- 									<h6 class="fontS_11"> -->
									<!-- 										<strong>Total</strong> -->
									<!-- 									</h6> -->
									<!-- 								</div> -->


								</div>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;" id="{{pageId}}left">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
							<div class="col-md-3 border_left" style="text-align: right;" id="{{pageId}}right">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf=ViewPaid>{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--fourth row ends-->

				<div class="row border_top">
					<div class="col-md-6  border_right">
						<h6>
							<strong>Remark : &nbsp;&nbsp;&nbsp;{{gateCc}}</strong>
						</h6>
						<h6 style='font-size: 13px;'>
							&nbsp;&nbsp;&nbsp;&nbsp;<strong>{{unloadByCsn}}</strong>
						</h6>
					</div>
					<div class="col-md-2">
						<div class="row txt_ac">
							<div class="col-md-12 border_right">
								<h6>
									<strong>Bill.No / Pvt.Mark</strong><br>{{resultDtos.billNumber}}
									/ {{resultDtos.privateMarker}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac" style='min-height: 35px;'>
							<div class="col-md-6 border_right">
								<h6>
									<strong>TOTAL</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;">
								<h6 *ngIf="ViewToPay">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
							<div class="col-md-3">
								<h6 *ngIf="ViewPaid">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>

				<div class="row border_top">
					<div class="col-md-8 border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6>
									<strong>CONSIGNOR COPY</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right txt_ac">
								<h6>
									<strong>G.C.No. </strong><strong>{{resultDtos.lrNumber}}</strong>
								</h6>
							</div>
							<div class="col-md-3 txt_ac">
								<h6>
									<strong>Transport ID</strong><br> {{transportId}}
								</h6>
							</div>
						</div>



					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>FOR SRD Logistics Pvt.Ltd.</strong>
								</h6>
							</div>
						</div>
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>Prepared by</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style='margin-top: -8px;'>
					<div class="col-md-8  border_top border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6 style='font-size: 10px;'>
									<strong>DELIVERY SHALL BE AGAINST CONSIGNEE COPY ONLY</strong>
								</h6>
								<h6 style='font-size: 9px;'>This G.C Note is subject to the
									Terms & Conditions printed overleaf</h6>
								<h6>
									<strong>Booked at Owner's Risk</strong>
								</h6>
							</div>
							<div class="col-md-6">
								<h6>{{resultDtos.message}}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 10px; padding-top: 5px;'>
									<strong>{{entryByPrint}}{{clerkName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<h6 style='font-size: 10px; text-align: right; font-style: Brush Script MT;'>
									<strong>Thank You</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<!--  PRINT 2 ENDS HERE -->
	<!-- 	<div> -->
	<!-- 		<p style='page-break-after: always;'></p> -->
	<!-- 	</div> -->
	<div class="page row system_responsive" id="{{pageId}}thirdPrint">
		<div class="container border">
			<div class="col-md-12">
				<!--first row starts-->
				<div class="row" style="padding-top: 5px;">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-7">

								<!-- 							<img [src]='src/cashMemoBarcode/' -->
								<!-- 								+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->

								<tr *ngFor="let PS of PrintBarcode">
									<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
										[bc-value]="PS.barcode" [bc-display-value]="true"> </ngx-barcode> -->
									<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27"
										[bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="true">
									</ngx-barcode6>
								</tr>

							</div>
							<div class="col-md-5">
								<h6 class="border_bottom dsply-ib">GOODS CONSIGNMENT NOTE</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6" style="margin-left: -10px;">
								<div class="form-group">
									<div class="input-group">
										<h6>
											<strong>Head Office :</strong><br> <span [innerHTML]="mainAddress"></span>
											<br>Tel.No&nbsp;:<span [innerHTML]="mainMobileNo"></span>
											<br>Email&nbsp;&nbsp;:
											{{mainEmailId}}<br> <strong>CIN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
												U63090MH2009PTC189328</strong><br> <strong>ISO
												9001:2008</strong>
										</h6>
									</div>
								</div>


							</div>
							<div class="col-md-6">
								<h6>
									{{address1}}<br>{{address2}}<br>{{cityPin}} <br>Tel.No&nbsp;:
									{{phone}}<br>Email&nbsp;&nbsp;: {{emailId}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
					</div>
				</div>

				<!--first row ends -->
				<!--seconde row Starts-->
				<div class="row">
					<div class="col-md-4" style="margin-left: -7px; margin-top: -10px;">
						<h6 style="font-size: 17px">
							<!-- 							<strong>G.C.No. </strong> <strong>{{resultDtos.lrNumber}}</strong> -->
						</h6>
					</div>
					<div class="col-md-8" style="margin-left: -7px;">
						<h6>
							<strong>LORRY COPY</strong>
						</h6>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12" style="margin-left: -7px;">
						<h6 align='center' width='100%' style='font-size: 14px; height: 20px;'>
							<strong> {{handleWitCare}}{{slashInCode}}</strong><span *ngIf="showSelfLr"><strong> SELF LR
									- DO NOT
									DELIVER WITHOUT CONSIGNEE COPY</strong></span>
						</h6>
					</div>
					<!-- 					<div *ngIf="showSelfLr" class="col-md-9" style="margin-left: -7px;"> -->

					<!-- 						<h6 align='center' width='100%' -->
					<!-- 							style='font-size: 14px; height: 20px;'> -->

					<!-- 						</h6> -->
					<!-- 					</div> -->
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNOR</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>&nbsp;: {{ resultDtos.consignorName }}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.addressConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.gstNoConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{resultDtos.consignorMobileNo }}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-6">

						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNEE</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>: {{ resultDtos.consigneeName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.addressConsignee }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.gstNoConsignee}}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.consigneeMobileNo }}</h6>
							</div>
						</div>
					</div>
				</div>
				<!--seconde row ends-->
				<!--third row starts-->
				<div class="row border_top border_bottom">
					<div class="col-md-6 txt_ac border_right">
						<h6>
							<strong>BOOKING DETAILS</strong>
						</h6>
					</div>
					<div class="col-md-6 txt_ac">
						<h6>
							<strong>AMOUNT CHARGES</strong>
						</h6>

					</div>
				</div>
				<!--third row ends-->
				<!--fourth row starts-->
				<div class="row">
					<div class="col-md-6 border_right">
						<div class="row">
							<div class="col-md-12">
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6 style="font-size: 17px">
											<strong>G.C.No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6 style="font-size: 17px">
											: <strong>{{resultDtos.lrNumber}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Booking Date</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.date}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Route</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{sourcePrint}}</strong> <strong> TO
											</strong><strong>{{destinationPrint}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>E-Way Bill No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{gateCcESugam}}&nbsp;&nbsp;{{eDecCom}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>No.of Articles</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.totalArticles}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Nature of Packing</strong>
										</h6>
									</div>
									<div class="col-md-8 fontS_11" style="margin-left: -50px;">
										<h6>: {{resultDtos.packNature}}</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Said to Contain</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.commodityName}}{{subCommodity}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Value of Goods</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>: Rs {{resultDtos.goodsValue}}/-</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
							</div>
						</div>


						<!-- 					<div class="row border_top" style="padding: 1px;"> -->
						<!-- 						<div class="col-md-12 fontS_10"> -->
						<!-- 							<h6> -->

						<!-- 							</h6> -->
						<!-- 						</div> -->
						<!-- 					</div> -->

					</div>
					<div class="col-md-2 border_right">
						<div class="row">
							<div class="col-md-12 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>WEIGHT(In Kgs.)</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Actual</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Charged</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong> {{resultDtos.actualWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>{{resultDtos.chargedWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 ">
								<div class="row">
									<div class="col-md-12 txt_ac">
										<!-- 										<h6>{{artSize}}{{artChgWt}}</h6> -->
										<h6 [innerHTML]="artSize"></h6>
										<h6>{{artChgWt}}</h6>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-4">
						<div class="row">
							<div class="col-md-6 border_bottom border_right">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<!-- 										<strong>FRIGHT(In Rs.)</strong> -->
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>FREIGHT(In Rs.)</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 border_right">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>ToPay</strong>
												</h6>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>Paid</strong>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>Base Freight</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Hamali</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Statistical Charges</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Others</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>L.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>B.C</strong>
										</h6>
									</div>

									<div class="col-md-12">
										<h6>
											<strong>A.O.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>F O V</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>DD</strong>
										</h6>
									</div>


									<!-- 								<div class="col-md-12" -->
									<!-- 									style="margin-top: 10px; border-top: 1px solid #000;"> -->
									<!-- 									<h6 class="fontS_11"> -->
									<!-- 										<strong>Total</strong> -->
									<!-- 									</h6> -->
									<!-- 								</div> -->


								</div>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;" id="{{pageId}}left">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
							<div class="col-md-3 border_left" style="text-align: right;" id="{{pageId}}right">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf=ViewPaid>{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--fourth row ends-->

				<div class="row border_top">
					<div class="col-md-6  border_right">
						<h6>
							<strong>Remark : &nbsp;&nbsp;&nbsp;{{gateCc}}</strong>
						</h6>
						<h6 style='font-size: 13px;'>
							&nbsp;&nbsp;&nbsp;&nbsp;<strong>{{unloadByCsn}}</strong>
						</h6>
					</div>
					<div class="col-md-2">
						<div class="row txt_ac">
							<div class="col-md-12 border_right">
								<h6>
									<strong>Bill.No / Pvt.Mark</strong><br>{{resultDtos.billNumber}}
									/ {{resultDtos.privateMarker}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac" style='min-height: 35px;'>
							<div class="col-md-6 border_right">
								<h6>
									<strong>TOTAL</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;">
								<h6 *ngIf="ViewToPay">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
							<div class="col-md-3">
								<h6 *ngIf="ViewPaid">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>

				<div class="row border_top">
					<div class="col-md-8 border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6>
									<strong>LORRY COPY</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right txt_ac">
								<h6>
									<strong>G.C.No. </strong><strong>{{resultDtos.lrNumber}}</strong>
								</h6>
							</div>
							<div class="col-md-3 txt_ac">
								<h6>
									<strong>Transport ID</strong><br> {{transportId}}
								</h6>
							</div>
						</div>



					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>FOR SRD Logistics Pvt.Ltd.</strong>
								</h6>
							</div>
						</div>
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>Prepared by</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style='margin-top: -8px;'>
					<div class="col-md-8  border_top border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6 style='font-size: 10px;'>
									<strong>DELIVERY SHALL BE AGAINST CONSIGNEE COPY ONLY</strong>
								</h6>
								<h6 style='font-size: 9px;'>This G.C Note is subject to the
									Terms & Conditions printed overleaf</h6>
								<h6>
									<strong>Booked at Owner's Risk</strong>
								</h6>
							</div>
							<div class="col-md-6">
								<h6>{{resultDtos.message}}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 10px; padding-top: 5px;'>
									<strong>{{entryByPrint}}{{clerkName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<h6 style='font-size: 10px; text-align: right; font-style: Brush Script MT;'>
									<strong>Thank You</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

</div>
<!--  PRINT 3 ENDS HERE -->
<!-- Row -->
<div *ngIf="showPrintForLocalBooking">
	<div class="row system_responsive">
		<div class="container border">
			<div class="col-md-12">
				<!--first row starts-->
				<div class="row" style="padding-top: 5px;">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-7">

								<!-- 							<img [src]='src/cashMemoBarcode/' -->
								<!-- 								+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->

								<tr *ngFor="let PS of PrintBarcode">
									<!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
										[bc-value]="PS.barcode" [bc-display-value]="true"> </ngx-barcode> -->
									<ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27"
										[bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="true">
									</ngx-barcode6>
								</tr>

							</div>
							<div class="col-md-5">
								<h6 class="border_bottom dsply-ib">GOODS CONSIGNMENT NOTE</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6" style="margin-left: -10px;">
								<div class="form-group">
									<div class="input-group">
										<h6>
											<strong>Head Office :</strong><br> <span [innerHTML]="mainAddress"></span>
											<br>Tel.No&nbsp;:<span [innerHTML]="mainMobileNo"></span>
											<br>Email&nbsp;&nbsp;:
											{{mainEmailId}}<br> <strong>CIN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
												U63090MH2009PTC189328</strong><br> <strong>ISO
												9001:2008</strong>
										</h6>
									</div>
								</div>


							</div>
							<div class="col-md-6">
								<h6>
									{{address1}}<br>{{address2}}<br>{{cityPin}} <br>Tel.No&nbsp;:
									{{phone}}<br>Email&nbsp;&nbsp;: {{emailId}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
					</div>
				</div>

				<!--first row ends -->
				<!--seconde row Starts-->

				<div class="row">
					<div class="col-md-12" style="margin-left: -7px;">
						<h6 align='center' width='100%' style='font-size: 14px; height: 20px;'>
							<span *ngIf="showSelfLr"><strong> SELF LR - DO
									NOT DELIVER WITHOUT CONSIGNEE COPY</strong></span>
						</h6>
					</div>
					<!-- 					<div *ngIf="showSelfLr" class="col-md-9" style="margin-left: -7px;"> -->

					<!-- 						<h6 align='center' width='100%' -->
					<!-- 							style='font-size: 14px; height: 20px;'> -->

					<!-- 						</h6> -->
					<!-- 					</div> -->
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNOR</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>&nbsp;: {{ resultDtos.consignorName }}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.addressConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{ resultDtos.gstNoConsignor }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>&nbsp;: {{resultDtos.consignorMobileNo }}</h6>
							</div>
						</div>
					</div>
					<div class="col-md-6">

						<div class="row">
							<div class="col-md-3">
								<h6 style="font-size: 14px">
									<strong>CONSIGNEE</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6 style="font-size: 14px">
									<strong>: {{ resultDtos.consigneeName}}</strong>
								</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>ADDRESS</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.addressConsignee }}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>GSTIN No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.gstNoConsignee}}</h6>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<h6>
									<strong>MOBILE No</strong>
								</h6>
							</div>
							<div class="col-md-9" style="margin-left: -40px;">
								<h6>: {{resultDtos.consigneeMobileNo }}</h6>
							</div>
						</div>
					</div>
				</div>
				<!--seconde row ends-->
				<!--third row starts-->
				<div class="row border_top border_bottom">
					<div class="col-md-6 txt_ac border_right">
						<h6>
							<strong>BOOKING DETAILS</strong>
						</h6>
					</div>
					<div class="col-md-6 txt_ac">
						<h6>
							<strong>AMOUNT CHARGES</strong>
						</h6>

					</div>
				</div>
				<!--third row ends-->
				<!--fourth row starts-->
				<div class="row">
					<div class="col-md-6 border_right">
						<div class="row">
							<div class="col-md-12">
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6 style="font-size: 17px">
											<strong>G.C.No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6 style="font-size: 17px">
											: <strong>{{resultDtos.lrNumber}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Booking Date</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.date}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Route</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{sourcePrint}}</strong> <strong> TO
											</strong><strong>{{destinationPrint}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>E-Way Bill No</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{gateCcESugam}}&nbsp;&nbsp;{{eDecCom}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">

									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>No.of Articles</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.totalArticles}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Nature of Packing</strong>
										</h6>
									</div>
									<div class="col-md-8 fontS_11" style="margin-left: -50px;">
										<h6>: {{resultDtos.packNature}}</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Said to Contain</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>
											: <strong>{{resultDtos.commodityName}}{{subCommodity}}</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4" style="margin-left: -10px;">
										<h6>
											<strong>Value of Goods</strong>
										</h6>
									</div>
									<div class="col-md-8" style="margin-left: -50px;">
										<h6>: Rs {{resultDtos.goodsValue}}/-</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-3">
										<h6>
											<strong></strong>
										</h6>
									</div>
									<div class="col-md-9" style="margin-left: -40px;">
										<h6></h6>
									</div>
								</div>
							</div>
						</div>


						<!-- 					<div class="row border_top" style="padding: 1px;"> -->
						<!-- 						<div class="col-md-12 fontS_10"> -->
						<!-- 							<h6> -->

						<!-- 							</h6> -->
						<!-- 						</div> -->
						<!-- 					</div> -->

					</div>
					<div class="col-md-2 border_right">
						<div class="row">
							<div class="col-md-12 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>WEIGHT(In Kgs.)</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Actual</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>Charged</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong> {{resultDtos.actualWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-12 txt_ac" style="border-bottom: 1px solid #000;">
										<h6>
											<strong>{{resultDtos.chargedWeight}}</strong>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 ">
								<div class="row">
									<div class="col-md-12 txt_ac">
										<!-- 										<h6>{{artSize}}{{artChgWt}}</h6> -->
										<h6 [innerHTML]="artSize"></h6>
										<h6>{{artChgWt}}</h6>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-4">
						<div class="row">
							<div class="col-md-6 border_bottom border_right">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<!-- 										<strong>FRIGHT(In Rs.)</strong> -->
										</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6 border_bottom">
								<div class="row txt_ac" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>FREIGHT(In Rs.)</strong>
										</h6>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 border_right">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>ToPay</strong>
												</h6>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12 txt_ac" style="border-top: 1px solid #000;">
												<h6>
													<strong>Paid</strong>
												</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 border_right">
								<div class="row" style="margin-left: -24px;">
									<div class="col-md-12">
										<h6>
											<strong>Base Freight</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Hamali</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Statistical Charges</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>Others</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>L.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>B.C</strong>
										</h6>
									</div>

									<div class="col-md-12">
										<h6>
											<strong>A.O.C</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>F O V</strong>
										</h6>
									</div>
									<div class="col-md-12">
										<h6>
											<strong>DD</strong>
										</h6>
									</div>


									<!-- 								<div class="col-md-12" -->
									<!-- 									style="margin-top: 10px; border-top: 1px solid #000;"> -->
									<!-- 									<h6 class="fontS_11"> -->
									<!-- 										<strong>Total</strong> -->
									<!-- 									</h6> -->
									<!-- 								</div> -->


								</div>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;" id="{{pageId}}left">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewToPay">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
							<div class="col-md-3 border_left" style="text-align: right;" id="{{pageId}}right">
								<div class="row">
									<div class="col-md-12">
										<h6 *ngIf=ViewPaid>{{resultDtos.frieghtAmt}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.hamali}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.statisticalCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.others}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.lcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.bcChg}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.aoc}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.riskCharge}}</h6>
									</div>
									<div class="col-md-12">
										<h6 *ngIf="ViewPaid">{{resultDtos.ddAmt}}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--fourth row ends-->

				<div class="row border_top">
					<div class="col-md-6  border_right">
						<h6>
							<strong>Remark : &nbsp;&nbsp;&nbsp;{{gateCc}}</strong>
						</h6>
						<h6 style='font-size: 13px;'>
							&nbsp;&nbsp;&nbsp;&nbsp;<strong>{{unloadByCsn}}</strong>
						</h6>
					</div>
					<div class="col-md-2">
						<div class="row txt_ac">
							<div class="col-md-12 border_right">
								<h6>
									<strong>Bill.No / Pvt.Mark</strong><br>{{resultDtos.billNumber}}
									/ {{resultDtos.privateMarker}}
								</h6>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row txt_ac" style='min-height: 35px;'>
							<div class="col-md-6 border_right">
								<h6>
									<strong>TOTAL</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right" style="text-align: right;">
								<h6 *ngIf="ViewToPay">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
							<div class="col-md-3">
								<h6 *ngIf="ViewPaid">
									<strong>{{resultDtos.grandTotal}}</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>

				<div class="row border_top">
					<div class="col-md-8 border_right">
						<div class="row">
							<div class="col-md-6 border_right">
								<h6>
									<strong>CONSIGNEE COPY</strong>
								</h6>
							</div>
							<div class="col-md-3 border_right txt_ac">
								<h6>
									<strong>G.C.No. </strong><strong>{{resultDtos.lrNumber}}</strong>
								</h6>
							</div>
							<div class="col-md-3 txt_ac">
								<h6>
									<strong>Transport ID</strong><br> {{transportId}}
								</h6>
							</div>
						</div>



					</div>
					<div class="col-md-4">
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>FOR SRD Logistics Pvt.Ltd.</strong>
								</h6>
							</div>
						</div>
						<div class="row txt_ac">
							<div class="col-md-12">
								<h6 style='font-size: 12px;'>
									<strong>Prepared by</strong>
								</h6>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

	</div>
</div>


<!-- <div id="{{pageId}}print"> -->
<!-- 	<app-cashmemo-genrate-print></app-cashmemo-genrate-print> -->
<!-- </div> -->