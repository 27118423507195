<!-- Row -->
<div class="row system_responsive" *ngIf="isLoggedIn" id="{{pageId}}showStocksIntakenRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Stock In Taken Report</h6>
            </div>
            <div class="row " style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <!-- <h6 class="card-title">Tempo Details</h6> -->
                                <div class="row">


                                    <div *ngIf="showSrcDest" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showSrcDest" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="fromDateModel" placeholder="yyyy-mm-dd" name="fromDate" ngbDatepicker #fromDates="ngbDatepicker">
                                                <div class="input-group-append" (click)="fromDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="toDateModel" class="form-control" placeholder="yyyy-mm-dd" name="toDate" ngbDatepicker #toDates="ngbDatepicker">
                                                <div class="input-group-append" (click)="toDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <!-- column -->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10">
                                        <div class="col-md-8"></div>
                                    </div>
                                    <table width=100%>
                                        <td width=80%>
                                            <h6 class="card-title">Stock In Taken Details</h6>
                                        </td>
                                        <td width=20%>
                                            <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrintStocksInTkn()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
                                        </td>
                                    </table>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}stocksInTakenReportId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStocksInTakenReport" [dtTrigger]="dtTriggerStocksInTakenReport">

                                                    <thead>
                                                        <tr>
                                                            <th *ngIf="isSendToPayEnabled">Action</th>
                                                            <th>Invoice Number</th>
                                                            <th>Payment Status</th>
                                                            <th>Truck No.</th>
                                                            <th>Supplier</th>
                                                            <th>Source</th>
                                                            <th>Total LRs</th>
                                                            <th>Total Articles</th>
                                                            <th>Actual Weight</th>
                                                            <th>Charged Weight</th>
                                                            <th>Gur. Weight</th>
                                                            <th>Kanta Weight</th>
                                                            <th>To Pay</th>
                                                            <th>Paid</th>
                                                            <th>Departure Date</th>
                                                            <th>Departure Time</th>
                                                            <th>St Arrival Date</th>
                                                            <th>St Arrival Time</th>
                                                            <th>St Days Taken</th>
                                                            <th>St Time Taken</th>
                                                            <th>Scan Date</th>
                                                            <th>Scan Time</th>
                                                            <th>Scan Time Taken</th>
                                                            <th>Rewarding-H-Amount</th>
                                                            <th *ngIf="showRewardHAmt">Reward Amount</th>
                                                            <th>Stock Date</th>
                                                            <th>Account Type</th>
                                                            <th>Account Name</th>
                                                            <th>Account Number</th>
                                                            <th>Bank Name</th>
                                                            <th>Branch Name</th>
                                                            <th>IFSC Code</th>
                                                            <th>Ac Proof Doc</th>
                                                            <th>Reason If Rejected</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let stocksInTakenReportData of stocksInTakenReportDataList ">
                                                            <td *ngIf="isSendToPayEnabled">
                                                                <button type="button" style="background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}oktopayBtn" (click)="openPaymentRequestPopUp(paymentReqPopup,stocksInTakenReportData)">
																			<i title="Ok To Pay Amount" class="fa fa-rupee-sign"></i>
																		</button>
                                                            </td>
                                                            <td>{{ stocksInTakenReportData.invoiceNumber }}</td>
                                                            <td *ngIf="stocksInTakenReportData.balPaymentStatus == 'Pending'" style="color: lightgreen;font-size: 15px;font-weight: bold;">{{ stocksInTakenReportData.balPaymentStatus }}</td>
                                                            <td *ngIf="stocksInTakenReportData.balPaymentStatus == 'In Process'" style="color: green;font-size: 15px;font-weight: bold;">{{ stocksInTakenReportData.balPaymentStatus }}</td>
                                                            <td *ngIf="stocksInTakenReportData.balPaymentStatus == 'Completed'" style="color: blue;font-size: 15px;font-weight: bold;">{{ stocksInTakenReportData.balPaymentStatus }}</td>
                                                            <td *ngIf="stocksInTakenReportData.balPaymentStatus == 'Rejected'" style="color: red;font-size: 15px;font-weight: bold;">{{ stocksInTakenReportData.balPaymentStatus }}</td>
                                                            <td>{{ stocksInTakenReportData.vehicleNumber }}</td>
                                                            <td>{{ stocksInTakenReportData.supplierName }}</td>
                                                            <td>{{ stocksInTakenReportData.fromStation }}</td>
                                                            <td>{{ stocksInTakenReportData.totalLRs }}</td>
                                                            <td>{{ stocksInTakenReportData.totalArticles }}</td>
                                                            <td>{{ stocksInTakenReportData.actWeight }}</td>
                                                            <td>{{ stocksInTakenReportData.chargedwt }}</td>
                                                            <td>{{ stocksInTakenReportData.gurWeight }}</td>
                                                            <td>{{ stocksInTakenReportData.kantaWeight }}</td>
                                                            <td>{{ stocksInTakenReportData.topay }}</td>
                                                            <td>{{ stocksInTakenReportData.paid }}</td>
                                                            <td>{{ stocksInTakenReportData.invoiceDateStr }}</td>
                                                            <td>{{ stocksInTakenReportData.departureTime }}</td>
                                                            <td>{{ stocksInTakenReportData.arrivalDateStr }}</td>
                                                            <td>{{ stocksInTakenReportData.arrivalTime }}</td>
                                                            <td>{{ stocksInTakenReportData.daysDiff }}</td>
                                                            <td>{{ stocksInTakenReportData.todate }}</td>
                                                            <td>{{ stocksInTakenReportData.scanDateStr }}</td>
                                                            <td>{{ stocksInTakenReportData.scanTime }}</td>
                                                            <td>{{ stocksInTakenReportData.timeTaken }}</td>
                                                            <td>{{ stocksInTakenReportData.driverRewardHAmt }}</td>
                                                            <td *ngIf="showRewardHAmt">{{ stocksInTakenReportData.driverRewardAmt }}</td>
                                                            <td>{{ stocksInTakenReportData.stockDateStr }}</td>
                                                            <td>{{ stocksInTakenReportData.acType }}</td>
                                                            <td>{{ stocksInTakenReportData.acHolder }}</td>
                                                            <td>{{ stocksInTakenReportData.acNumber }}</td>
                                                            <td>{{ stocksInTakenReportData.bankName }}</td>
                                                            <td>{{ stocksInTakenReportData.branchName }}</td>
                                                            <td>{{ stocksInTakenReportData.ifscCode }}</td>
                                                            <td>
                                                                <a (click)="viewBankAcProofDoc(stocksInTakenReportData);" style="cursor: pointer;"> <u>{{
                                                                    stocksInTakenReportData.hireamtbalbankacproofcopy ==
                                                                        null ? "NA" :
                                                                        stocksInTakenReportData.hireamtbalbankacproofcopy ==
                                                                        "NA" ? "NA" : "View Bank Ac Doc" }}</u></a>
                                                            </td>
                                                            <td style="color: red;font-size: 15px;font-weight: bold;">{{ stocksInTakenReportData.remarks }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td *ngIf="isSendToPayEnabled"></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td *ngIf="showRewardHAmt"></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<ng-template #paymentReqPopup let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Select Payment Bank details for :<b>
                {{selectedHireslipNumberForPayment}}</b> </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Uploading File....</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12">
                    <div class="control">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Select Bank AC Type</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                </div>
                                <input #bankName id="{{pageId}}bankNameId" type="text" class="form-control" (selectItem)="clickListnerForBankName($event)" [(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                    (focus)="focusBankNameTA$.next($any($event).target.value)" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12" *ngIf="showBankAcDetails">
                    <div class="card card-hover imb_custome">
                        <div class="card-body" style="background-color: lightcyan;">
                            <h5 class="card-title">Account Details</h5>
                            <div class="row">
                                <div class="col-md-12">
                                    <label style="width: 100%;font-size: 15px;">Ac No : <span>{{selectedAcNo}}</span></label>
                                </div>

                                <div class="col-md-12">
                                    <label style="width: 100%;font-size: 15px;">Bank : <span>{{selectedBankName}}</span></label>
                                </div>

                                <div class="col-md-12">
                                    <label style="width: 100%;font-size: 15px;">Ac Holder : <span>{{selectedAcName}}</span></label>
                                </div>

                                <div class="col-md-12">
                                    <label style="width: 100%;font-size: 15px;">IFSC : <span>{{selectedIfsc}}</span></label>
                                </div>

                                <div class="col-md-12">
                                    <label style="width: 100%;font-size: 15px;">Branch : <span>{{selectedBranch}}</span></label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12" *ngIf="showBankAcDetails">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Bank Ac Proof:</label> <input type="file" id="{{pageId}}bankAcProofFile1Upload" (change)="onFileChanged($event)" #bankAcProofFile1Upload />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadBankAcProofFile()">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12">
                    <button type="submit" class="btn btn-dark" (click)="oktopayAmount()">Update Details</button>
                    <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>