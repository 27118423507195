<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 20px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 16px; padding-top: 30px;'>
                    <strong>Reward To </strong> <span>{{rewardTo}}
						- </span><strong>Lorry A/c</strong>
                </h6>
            </div>
            <div class="col-md-5" align='right'>
                <h6 style='margin-right: 20px; font-size: 16px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{dateInDate}}</span>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 16px; padding-top: 30px;'>
                    <strong>Hireslip Number</strong> <span> :&nbsp;{{hireslipNumber}}</span>
                </h6>
            </div>
            <div class="col-md-5" align='right'>
            </div>
        </div>
        <!--Second  row starts-->
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style='font-size: 17px; border-bottom: 1px solid #000;' align='center'>
                                <strong>PARTICULARS </strong>
                            </h6>
                        </div>

                    </div>


                    <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;' height='180px;'>
                        <div class="row" style='border-bottom: 1px solid #000; flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <h6 style='font-size: 17px;' align='center'>
                                    <strong>Invoice Details</strong>
                                </h6>
                            </div>
                            <!--<div class="col-md-4" style='border-right: 1px solid #000;'>
								<h6 style='font-size: 17px;' align='center'>
									<strong>Bank Details </strong>
								</h6>
							</div>-->
                            <div class="col-md-8">
                                <h6 style='font-size: 17px;' align='center'>
                                    <strong>Lorry Details </strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong>Invoice No </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{invoiceNumber}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong>Supplier Name </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{supplierName}}
										</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong>Departure Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{departureDate}}
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong>Invoice Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{invoicedate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong>Lorry Number</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{lorryNo}}
										</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 15px; word-wrap: break-word;' align='left'>
                                            <strong>Departure Time</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{departureTime}}
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 [hidden]="!showHireColumns" style='font-size: 15px;' align='left'>
                                            <strong>Hire Amount</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <span [hidden]="!showHireColumns" style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{hireAmt}}
										</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 15px; word-wrap: break-word;' align='left'>
                                            <strong> Arrival Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{arrivalDate}}
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 15px; word-wrap: break-word;' align='left'>
                                            <strong>Arrival Time&nbsp;</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{arrivalTime}}
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-5">
                                    </div>
                                    <div class="col-md-7">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 15px; word-wrap: break-word;' align='left'>
                                            <strong>Time Taken To Reach
											</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <span style='font-size: 15px; word-wrap: break-word;' align='left'>
											:&nbsp;{{timeTakenToReach}}
										</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-6">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-6">

                                        <h6 style='font-size: 15px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>

                                    <div class="col-md-6">

                                        <h6 style='font-size: 15px;'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='border-top: 1px solid #000; flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-12" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 style='font-size: 15px;' align='left'>
                                            <span>LORRY DRIVER&nbsp;<u><b>{{drivername}}</b></u>&nbsp; HAS BEEN REWARDED
												OF RS&nbsp;
												<u><b>{{rewardAmt}}</b></u>&nbsp;</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-12" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 style='font-size: 15px;' align='left'>
                                            AMOUNT IN WORDS&nbsp;:&nbsp;{{printAmt}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </table>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h6 style='font-size: 15px; margin-left: 20px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 16px; padding-top: 22px;'>
                    <strong>Approved By :</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 16px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>