<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">

                <div class="col-md-7">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center'
                                                        style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait Loading Details.....</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title">LR Booking Date - Custom Change</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}source">
                                                            <label class="wtfull">Source</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}fromTime">
                                                            <label class="wtfull">From Time</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}toTime">
                                                            <label class="wtfull">To Time</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}increamentDays">
                                                            <label class="wtfull">Increment Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}weekendDays">
                                                            <label class="wtfull">Weekend Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}addingDays">
                                                            <label class="wtfull">Adding Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #agentName id="{{pageId}}agentName" type="text"
                                                                class="form-control" [(ngModel)]="agentNameModal"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [ngbTypeahead]="agentNameSearchTA"
                                                                [resultFormatter]="formatterAgentName"
                                                                [inputFormatter]="formatterAgentName"
                                                                placeholder="Select Source"
                                                                (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                                #instanceAgentName="ngbTypeahead" />&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}fromTime" name="fromTime"
                                                                class="form-control" #fromTime
                                                                (keyup)="fieldFocus($event, toTime)"
                                                                aria-describedby="basic-addon11"
                                                                [(ngModel)]="fromTimeModel">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}toTime" name="toTime"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                #toTime (keyup)="fieldFocus($event, increamentDays)"
                                                                [(ngModel)]="toTimeModel">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}increamentDays"
                                                                name="increamentDays" class="form-control"
                                                                #increamentDays
                                                                (keyup)="fieldFocus($event, weekendDays)"
                                                                aria-describedby="basic-addon11"
                                                                [(ngModel)]="incrementDaysModel">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #weekendDays id="{{pageId}}weekendDays"
                                                                name="weekendDays" type="text" class="form-control"
                                                                [(ngModel)]="modelWeekendDays" placeholder="Select Day"
                                                                [ngbTypeahead]="weekendDaysSearchTA"
                                                                (focus)="focusWeekendDaysTA$.next($any($event).target.value)"
                                                                #instanceIndex="ngbTypeahead" />&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}addingDays"
                                                                name="addingDays" class="form-control" #addingDays
                                                                (keyup)="fieldFocus($event, addBtn)"
                                                                aria-describedby="basic-addon11"
                                                                [(ngModel)]="addingDaysModel">&nbsp;

                                                            <a href="javascript:;" type='button'
                                                                id="{{pageId}}addInTable" #addBtn
                                                                class="btn btn-icon-only yellow"
                                                                (click)="validateForAddInTable();"> <i class="fa fa-plus"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="box-body">
                                                        <table datatable id="{{pageId}}lrBookingDateCustomChangeTableId"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsLrBookingDateCustomChange"
                                                            [dtTrigger]="dtTriggerLrBookingDateCustomChange">
                                                            <thead>
                                                                <tr>
                                                                    <th>Source</th>
                                                                    <th>From Time</th>
                                                                    <th>To Time</th>
                                                                    <th>Increment Days</th>
                                                                    <th>Weekend Days</th>
                                                                    <th>Adding Dayss</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let lrBkgDateCustomChangeDetails of lrBkgDateCustomChangeDetailLists; let i = index">
                                                                    <td>{{ lrBkgDateCustomChangeDetails.source }}</td>
                                                                    <td>{{ lrBkgDateCustomChangeDetails.startTime }}
                                                                    </td>
                                                                    <td>{{ lrBkgDateCustomChangeDetails.endTime }}</td>
                                                                    <td>{{ lrBkgDateCustomChangeDetails.increamentDays
                                                                        }}</td>
                                                                    <td>{{ lrBkgDateCustomChangeDetails.weekendDays }}
                                                                    </td>
                                                                    <td>{{ lrBkgDateCustomChangeDetails.addingDays }}
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                                            class="btn m-r-10"
                                                                            id="{{pageId}}tableEditBtn"
                                                                            (click)="rowSelectedEdit(lrBkgDateCustomChangeDetails,i);">
                                                                            <i title="Edit"
                                                                                class="fas fa-pencil-alt"></i>
                                                                        </button>&nbsp;
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr style="width: 95%;">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}saveBtn" #saveBtn
                                                    (click)="validateForLrBookingDateCustomChangeSave()">Save</button>
                                                <button type="submit" class="btn btn-dark m-r-10"
                                                    id="{{pageId}}clearBtn" (click)="clearFields()">Clear</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 vl">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="card-title">LR Booking Date - Custom Change for Holiday</h6>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Source&nbsp;*</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-home"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #agentName id="{{pageId}}agentNameForHoliday"
                                                                        type="text" class="form-control"
                                                                        [(ngModel)]="agentNameModalForHoliday"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [ngbTypeahead]="agentNameForHolidaySearchTA"
                                                                        [resultFormatter]="formatterAgentNameForHoliday"
                                                                        [inputFormatter]="formatterAgentNameForHoliday"
                                                                        placeholder="Select Source"
                                                                        (focus)="focusAgentNameForHolidayTA$.next($any($event).target.value)"
                                                                        #instanceAgentName="ngbTypeahead" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>From Date&nbsp;*</label> <input
                                                                        class="form-control" id="{{pageId}}fromDate"
                                                                        placeholder="yyyy-mm-dd" name="fromDate"
                                                                        ngbDatepicker #fromDates="ngbDatepicker"
                                                                        autocomplete="off" [(ngModel)]="fromDateModel">
                                                                    <div class="input-group-append"
                                                                        (click)="fromDates.toggle()">
                                                                        <span class="input-group-text"> <i
                                                                                class="fa fa-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>To Date&nbsp;*</label> <input
                                                                        class="form-control" id="{{pageId}}toDate"
                                                                        placeholder="yyyy-mm-dd" name="toDate"
                                                                        ngbDatepicker #toDates="ngbDatepicker"
                                                                        autocomplete="off" [(ngModel)]="toDateModel">
                                                                    <div class="input-group-append"
                                                                        (click)="toDates.toggle()">
                                                                        <span class="input-group-text"> <i
                                                                                class="fa fa-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Considered Date&nbsp;*</label> <input
                                                                        class="form-control"
                                                                        id="{{pageId}}consideredDate"
                                                                        placeholder="yyyy-mm-dd" name="consideredDate"
                                                                        ngbDatepicker #consideredDates="ngbDatepicker"
                                                                        autocomplete="off"
                                                                        [(ngModel)]="consideredDateModel">
                                                                    <div class="input-group-append"
                                                                        (click)="consideredDates.toggle()">
                                                                        <span class="input-group-text"> <i
                                                                                class="fa fa-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12" style="text-align: center;">
                                                            <button type="submit" class="btn btn-success m-r-10"
                                                                id="{{pageId}}saveBtn" #saveBtn
                                                                (click)="validateSaveLrBkgDateCustomChangeForHolidayDetails()">Save</button>
                                                            <button type="submit" class="btn btn-dark m-r-10"
                                                                id="{{pageId}}clearBtn"
                                                                (click)="clearAll()">Clear</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}lrBookingDateForHolidayDetailsTableId"
                                                    class="table table-striped table-bordered row-border hover"
                                                    [dtOptions]="dtOptionsLrBookingDateForHolidayDetails"
                                                    [dtTrigger]="dtTriggerLrBookingDateForHolidayDetails">
                                                    <thead>
                                                        <tr>
                                                            <th>Source</th>
                                                            <th>From Date</th>
                                                            <th>To Date</th>
                                                            <th>Considered Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let lrBkgDateForHolidayDetails of lrBkgDateCustomChangeForHolidayDetailLists; let j = index">
                                                            <td>{{ lrBkgDateForHolidayDetails.source }}</td>
                                                            <td>{{ lrBkgDateForHolidayDetails.fromDate }}</td>
                                                            <td>{{ lrBkgDateForHolidayDetails.toDate }}</td>
                                                            <td>{{ lrBkgDateForHolidayDetails.date }}</td>
                                                            <td><button
                                                                    style="padding: 1px 4px; background-color: #ffffff00;"
                                                                    class="btn m-r-10" id="{{pageId}}tableEditBtn"
                                                                    (click)="rowSelectedEditForHoliday(lrBkgDateForHolidayDetails,j);">
                                                                    <i title="Edit" class="fas fa-pencil-alt"></i>
                                                                </button>&nbsp;
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>