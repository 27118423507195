import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Invoice } from 'src/app/dataService/invoice';
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';

@Component({
    selector: 'app-invoice-report',
    templateUrl: './invoice-report.component.html',
    styleUrls: ['./invoice-report.component.css']
})
export class InvoiceReportComponent implements OnInit {
    getDataSourceWiseDataTable: any;
    //getDataDestinationWiseTable: any;
    getDataInvoiceMainStation: any;
    getDataInvoiceSubStation: any;
    getDataServiceFrCityWiseTable: any;


    sourceWiseDataList: any;
    //destinationWiseDataList: any;
    invoiceMainStationDataList: any;
    invoiceSubStationDataList: any;
    cityWiseDataList: any;


    onDestroyUnsubscribtionSourceWise: Subscription;
    //onDestroyUnsubscribtionDestinationWise: Subscription;
    onDestroyUnsubscribtionInvoiceMainStation: Subscription;
    onDestroyUnsubscribtionInvoiceSubStation: Subscription;
    onDestroyUnsubscribtionCityWise: Subscription;

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    showSpinnerForAction = false;

    viewSource = true;
    viewDestination = false;
    viewStationInvoice = false;
    viewCity = false;
    viewAgent = false;
    selectedSearchBy: any;
    hideAndShowViaSourceField: boolean = false;

    invoiceSrcWiseTable = true;
    invoiceMainStationTable = false;
    invoiceSubStationTable = false;
    cityWiseTable = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSourceWise: Subject<any> = new Subject();
    //dtTriggerDestinationWise: Subject<any> = new Subject();
    dtTriggerInvoiceMainStation: Subject<any> = new Subject();
    dtTriggerInvoiceSubStation: Subject<any> = new Subject();
    dtTriggerCityWise: Subject<any> = new Subject();


    dataTable: any;

    dtOptionsSourceWise: any;
    //dtOptionsDestinationWise: any;
    dtOptionsInvoiceMainStation: any;
    dtOptionsInvoiceSubStation: any;
    dtOptionsCityWise: any;
    lrDtoSearch: LRDto = new LRDto();

    fromDateSelected: any;
    toDateSelected: any;
    sourceSelected: any;
    destinationSelected: any;
    mainSrcSelected: any;
    agentSelected: any;
    validateViaSourceStationIdValue: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    isSpecialRights = false;

    // source
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
    //destination

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    destinationTempOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    //city
    sourceAllList: Array<any> = [];
    sourceList: Array<any> = [];
    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;

    //via
    lrDtoViaSourceStationAllOption: LRDto = new LRDto();
    viaSourceStationOptions: LRDto[];
    lrDtoViaSourceStation: LRDto = new LRDto();
    public modelViaSourceStation: any;
    viaSourceStationNameTA: Array<LRDto> = [];
    focusViaSourceStationTA$ = new Subject<string>();
    searchViaSourceStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusViaSourceStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.viaSourceStationNameTA
                : this.viaSourceStationNameTA.filter(v => v.destination && v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterViaSource = (x: { destination: string }) => x.destination;

    pageId = "invrc";

    constructor(private invoiceDetailsRpt: Invoice, private router: Router, private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.selectedSearchBy = 'sourceWise';

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "StmtInvRptSpecRights") {
                        this.isSpecialRights = true;
                    }
                }
            }

            this.getFromStationMethod();
            this.getDestinationDetails();
            this.getMainBookingSourceDetails();
            this.getViaSourceStationDetails();
        }
    }

    ngOnInit(): void {
        var groupColumnSrcWise = 0;
        var groupColumnMainStatWise = 0;
        var groupColumnSubStatWise = 0;
        //the first datatable starts
        this.dtOptionsSourceWise = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-excel"> Excel</i>',
                    titleAttr: 'Excel',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var actWgt = 0;
                var chgWgt = 0;
                var toPayAmt = 0;
                var paidAmt = 0;
                var count = 0;
                var totddAmt = 0;
                api.column(groupColumnSrcWise, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .prop('outerHTML'));
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 7,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    actWgt += +val[2];
                    chgWgt += +val[3];
                    toPayAmt += +val[4];
                    paidAmt += +val[5];
                    totddAmt += +val[6];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count

                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": actWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": chgWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPayAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paidAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totddAmt
                                        }))
                                    .prop('outerHTML'));
                        actWgt = 0;
                        chgWgt = 0;
                        toPayAmt = 0;
                        paidAmt = 0;
                        count = 0;
                        totddAmt = 0;
                    }
                });
            },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var actWgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPayAmt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paidAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totddAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(actWgt);
                $(api.column(3).footer()).html(chgWgt);
                $(api.column(4).footer()).html(toPayAmt);
                $(api.column(5).footer()).html(paidAmt);
                $(api.column(6).footer()).html(totddAmt);
            }
        },
            this.dtOptionsInvoiceMainStation = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totArt = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPayAmt = 0;
                    var paidAmt = 0;
                    var totLrs = 0;
                    var fov = 0;
                    var less = 0;
                    var count = 0;
                    var totddAmt = 0;
                    api.column(groupColumnMainStatWise, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totArt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPayAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paidAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": fov
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": less
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totddAmt
                                                }))
                                            .prop('outerHTML'));
                                totLrs = 0;
                                totArt = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPayAmt = 0;
                                paidAmt = 0;
                                count = 0;
                                fov = 0;
                                less = 0;
                                totddAmt = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 11,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totLrs += +val[2];
                        totArt += +val[4];
                        actWgt += +val[5];
                        chgWgt += +val[6];
                        toPayAmt += +val[7];
                        paidAmt += +val[8];
                        fov += +val[9];
                        less += +val[10];
                        totddAmt += +val[11];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": less
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .prop('outerHTML'));
                            totLrs = 0;
                            totArt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            fov = 0;
                            less = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                    });
                },
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var totLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totArt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var lessAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totddAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totLrs);
                    $(api.column(4).footer()).html(totArt);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(fovAmt);
                    $(api.column(10).footer()).html(lessAmt);
                    $(api.column(11).footer()).html(totddAmt);
                }
            },
            //the third datatable ends
            //the fourth datatable starts
            this.dtOptionsInvoiceSubStation = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totArt = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPayAmt = 0;
                    var paidAmt = 0;
                    var totLrs = 0;
                    var less = 0;
                    var count = 0;
                    var totddAmt = 0;
                    api.column(groupColumnMainStatWise, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totArt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPayAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paidAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": less
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totddAmt
                                                }))
                                            .prop('outerHTML'));
                                totLrs = 0;
                                totArt = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPayAmt = 0;
                                paidAmt = 0;
                                count = 0;
                                less = 0;
                                totddAmt = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 10,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totLrs += +val[2];
                        totArt += +val[4];
                        actWgt += +val[5];
                        chgWgt += +val[6];
                        toPayAmt += +val[7];
                        paidAmt += +val[8];
                        less += +val[9];
                        totddAmt += +val[10];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": less
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .prop('outerHTML'));
                            totLrs = 0;
                            totArt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            less = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                    });
                },
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var totLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totArt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var lessAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totddAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totLrs);
                    $(api.column(4).footer()).html(totArt);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(lessAmt);
                    $(api.column(10).footer()).html(totddAmt);
                }
            },
            //the fourth datatable ends
            //the fifth datatable starts 
            this.dtOptionsCityWise = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 360,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var actWgt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totArt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var goodsValue = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totddAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(actWgt);
                    $(api.column(2).footer()).html(chgWgt);
                    $(api.column(3).footer()).html(totArt);
                    $(api.column(4).footer()).html(toPayAmt);
                    $(api.column(5).footer()).html(paidAmt);
                    $(api.column(6).footer()).html(fovAmt);
                    $(api.column(7).footer()).html(goodsValue);
                    $(api.column(8).footer()).html(totddAmt);
                }
            }
        //the fifth datatable ends

    }

    ngAfterViewInit(): void {
        this.dtTriggerSourceWise.next();
        //this.dtTriggerDestinationWise.next();
        this.dtTriggerInvoiceMainStation.next();
        this.dtTriggerInvoiceSubStation.next();
        this.dtTriggerCityWise.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerSourceWise.unsubscribe();
        //this.dtTriggerDestinationWise.unsubscribe();
        this.dtTriggerInvoiceMainStation.unsubscribe();
        this.dtTriggerInvoiceSubStation.unsubscribe();
        this.dtTriggerCityWise.unsubscribe();
    }

    searchByMode(searchBy: string) {
        if (searchBy === 'sourceWise') {
            this.viewSource = true;
            this.viewDestination = false;
            this.viewStationInvoice = false;
            this.viewCity = false;
            this.viewAgent = false;
            this.invoiceSrcWiseTable = true;
            this.invoiceMainStationTable = false;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'sourceWise';

            $("#" + this.pageId + "invoiceSrcWiseTableId").DataTable().destroy();
            this.sourceWiseDataList = [];
            this.dtTriggerSourceWise.next();
        } else if (searchBy === 'destinationWise') {
            this.viewSource = false;
            this.viewDestination = true;
            this.viewStationInvoice = false;
            this.viewCity = false;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = true;
            this.invoiceMainStationTable = false;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'destinationWise';

        } else if (searchBy === 'mainInvoice') {
            this.viewSource = false;
            this.viewDestination = true;
            this.viewStationInvoice = true;
            this.viewCity = false;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = false;
            this.invoiceMainStationTable = true;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'mainInvoice';

        } else if (searchBy === 'cityWise') {
            this.viewSource = false;
            this.viewDestination = false;
            this.viewStationInvoice = false;
            this.viewCity = true;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = false;
            this.invoiceMainStationTable = false;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = true;
            this.selectedSearchBy = 'cityWise';

        } else if (searchBy === 'agentWise') {
            this.viewSource = true;
            this.viewDestination = true;
            this.viewStationInvoice = false;
            this.viewCity = false;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = true;
            this.invoiceMainStationTable = false;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'agentWise';

            $("#" + this.pageId + "invoiceSrcWiseTableId").DataTable().destroy();
            this.sourceWiseDataList = [];
            this.dtTriggerSourceWise.next();
        } else {
            this.viewSource = false;
            this.viewDestination = false;
            this.viewStationInvoice = false;
            this.viewCity = false;
            this.viewAgent = false;
        }
    }

    searchByMainInvoiceStationWiseMode(searchMainInvoiceStationWiseBy: string) {
        console.log(searchMainInvoiceStationWiseBy);
        if (searchMainInvoiceStationWiseBy === 'mainStation') {
            this.viewSource = false;
            this.viewDestination = true;
            this.viewStationInvoice = true;
            this.viewCity = false;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = false;
            this.invoiceMainStationTable = true;
            this.invoiceSubStationTable = false;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'mainInvoice';

            $("#" + this.pageId + "destinationId").val('All');
            this.hideAndShowViaSourceField = false;

            this.invoiceMainStationDataList = [];
            $("#" + this.pageId + "invoiceMainStationTableId").DataTable().destroy();
            this.dtTriggerInvoiceMainStation.next();

        } else if (searchMainInvoiceStationWiseBy === 'subStation') {
            this.viewSource = false;
            this.viewDestination = true;
            this.viewStationInvoice = true;
            this.viewCity = false;
            this.viewAgent = false;

            this.invoiceSrcWiseTable = false;
            this.invoiceMainStationTable = false;
            this.invoiceSubStationTable = true;
            this.cityWiseTable = false;
            this.selectedSearchBy = 'mainInvoice';

            $("#" + this.pageId + "destinationId").val('All');
            this.hideAndShowViaSourceField = false;

            this.invoiceSubStationDataList = [];
            $("#" + this.pageId + "invoiceSubStationTableId").DataTable().destroy();
            this.dtTriggerInvoiceSubStation.next();
        }
    }

    getUserValues() {
        this.fromDateSelected = $("#" + this.pageId + "fromDate").val();
        this.toDateSelected = $("#" + this.pageId + "toDate").val();
        this.sourceSelected = $("#" + this.pageId + "sourceId").val();
        this.destinationSelected = $("#" + this.pageId + "destinationId").val();
        this.mainSrcSelected = $("#" + this.pageId + "mainBookingSourceId").val();
        this.agentSelected = $("#" + this.pageId + "sourceId").val();
        this.validateViaSourceStationIdValue = $("#" + this.pageId + "viaSourceId").val();
    }

    validateSearchBtn() {
        this.getUserValues();
        if (this.selectedSearchBy == 'destinationWise') {

        } else if (this.selectedSearchBy == 'sourceWise') {
            this.lrDtoSearch = new LRDto();
            this.lrDtoSearch.source = this.sourceSelected;
            this.lrDtoSearch.fromDate = this.fromDateSelected;
            this.lrDtoSearch.toDate = this.toDateSelected;
            this.lrDtoSearch.destination = this.destinationSelected;
            this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.getSrcWiseDetailsList();
        } else if (this.selectedSearchBy == 'mainInvoice') {
            this.lrDtoSearch = new LRDto();
            this.lrDtoSearch.fromDate = this.fromDateSelected;
            this.lrDtoSearch.toDate = this.toDateSelected;
            this.lrDtoSearch.destination = this.destinationSelected;
            this.lrDtoSearch.adminReport = "statement";
            this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoSearch.via = this.validateViaSourceStationIdValue;
            if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoSearch.source = "bkgAdmin";
            } else {
                this.lrDtoSearch.source = "supAdmin";
            }
            /*alert($("#"+this.pageId+"searchByStation").val());
            if ($("#"+this.pageId+"searchByStation").val() == "mainStation") {
                this.lrDtoSearch.reportMode = "mainStation";
                this.lrDtoSearch.mode = "mode1";
                this.setDateWiseAndMainInvoiceDetailsForReport();
            } else {
                this.lrDtoSearch.reportMode = "subStation";
                this.lrDtoSearch.mode = "mode1";
                this.setDateWiseAndMainInvoiceSubstationDetailsForReport();
            }*/

            if (this.destinationSelected != null &&
                this.destinationSelected == "All") {
                this.lrDtoSearch.mode = "mode1";
            } else {
                this.lrDtoSearch.mode = "mode2";
            }

            if ($("#" + this.pageId + "searchByStation").val() == "mainStation") {
                this.lrDtoSearch.reportMode = "mainStation";
                if (this.hideAndShowViaSourceField == true) {
                    //Method Call HERE
                    this.setDateWiseAndMainInvoiceDetailsForReport();
                } else {
                    //Method Call HERE
                    this.setDateWiseAndMainInvoiceDetailsForReport();
                }
            } else if ($("#" + this.pageId + "searchByStation").val() == "subStation") {
                this.lrDtoSearch.reportMode = "subStation";
                if (this.hideAndShowViaSourceField == true) {
                    //Method Call HERE
                    this.setDateWiseAndMainInvoiceSubstationDetailsForReport();
                } else {
                    //Method Call HERE
                    this.setDateWiseAndMainInvoiceSubstationDetailsForReport();
                }
            }


        } else if (this.selectedSearchBy == 'agentWise') {
            this.lrDtoSearch = new LRDto();
            this.lrDtoSearch.source = this.agentSelected;
            this.lrDtoSearch.fromDate = this.fromDateSelected;
            this.lrDtoSearch.toDate = this.toDateSelected;
            this.lrDtoSearch.destination = this.destinationSelected;
            this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.getBkgAgentWiseDetailsList();
        } else if (this.selectedSearchBy == 'cityWise') {
            this.lrDtoSearch = new LRDto();
            this.lrDtoSearch.city = this.mainSrcSelected;
            this.lrDtoSearch.fromDate = this.fromDateSelected;
            this.lrDtoSearch.toDate = this.toDateSelected;
            this.lrDtoSearch.destination = this.destinationSelected;
            this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
            this.getCityWiseDetailsList();
        }
    }

    getSrcWiseDetailsList() {
        this.showSpinnerForAction = true;

        this.invoiceDetailsRpt.getStatementInvoiceReportSouceWise(this.lrDtoSearch).subscribe(
            (response) => {
                $("#" + this.pageId + "invoiceSrcWiseTableId").DataTable().destroy();
                this.sourceWiseDataList = [];
                if (response.length > 0) {
                    this.sourceWiseDataList = response;
                } else {
                    swal("No Records", "No Records found for this search", "info");
                }
                setTimeout(() => {
                    this.dtTriggerSourceWise.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            },
            () => console.log('done');
    }

    getBkgAgentWiseDetailsList() {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getStatementInvoiceReportBkgAgentWise(this.lrDtoSearch).subscribe(
            (response) => {
                $("#" + this.pageId + "invoiceSrcWiseTableId").DataTable().destroy();
                this.sourceWiseDataList = [];
                if (response.length > 0) {
                    this.sourceWiseDataList = response;
                } else {
                    swal("No Records", "No Records found for this search", "info");
                }
                setTimeout(() => {
                    this.dtTriggerSourceWise.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            },
            () => console.log('done');
    }

    getCityWiseDetailsList() {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getStatementInvoiceReportCityWise(this.lrDtoSearch).subscribe(
            (response) => {
                $("#" + this.pageId + "cityWiseTableId").DataTable().destroy();
                this.cityWiseDataList = [];
                if (response.length > 0) {
                    this.cityWiseDataList = response;
                } else {
                    swal("No Records", "No Records found for this search", "info");
                }
                setTimeout(() => {
                    this.dtTriggerCityWise.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            },
            () => console.log('done');
    }

    setDateWiseAndMainInvoiceDetailsForReport() {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsMainInvoiceService(this.lrDtoSearch).subscribe(
            (response) => {
                console.log(response);
                this.invoiceMainStationDataList = [];
                $("#" + this.pageId + "invoiceMainStationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceMainStationDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceMainStation.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error while getting report details", "info");
            },
            () => console.log('done');
    }

    setDateWiseAndMainInvoiceSubstationDetailsForReport() {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsMainInvoiceService(this.lrDtoSearch).subscribe(
            (response) => {
                console.log(response);
                this.invoiceSubStationDataList = [];
                $("#" + this.pageId + "invoiceSubStationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceSubStationDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceSubStation.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error while getting report details", "info");
            },
            () => console.log('done');
    }

    getFromStationMethod() {
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Booking Administrator" &&
            this.userDataDtoReturnSession.stationList != "") {
            this.getAgentNamesMethod();
        } else {
            this.getSourceStationDetails();
        }
    }

    getAgentNamesMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Booking Administrator") {
            this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainStation;
        } else {
            this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log("Agent");
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    // this.lrDtoSourceStationAllOption.subStations = "All";
                    //this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.mode = "All";
    }
    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log("Source");
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    this.lrDtoSourceStationAllOption.subStations = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationTempOptions = [];
                    this.destinationOptions = response;
                    this.destinationTempOptions = this.destinationOptions;

                    this.lrDtoDestinationAllOption = new LRDto();
                    this.lrDtoDestinationAllOption.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAllOption);

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    $("#" + this.pageId + "destinationId").val('All');

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationTempOptions = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    }

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    this.sourceList = [];
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                        this.sourceList.push(this.agentDtoOptionSourceStation[i].mainBookStations);
                    }
                    this.sourceAllList = [];
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.sourceAllList.push(this.agentDtoOptionSourceStation[i].mainBookStations);
                    }
                    console.log(this.sourceAllList);
                    this.showSpinnerForAction = false;
                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log('done');

    }

    getViaSourceStationMethod() {
        this.lrDtoViaSourceStation = new LRDto();
        this.lrDtoViaSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoViaSourceStation.mode = "logininfo";
    }
    getViaSourceStationDetails() {
        this.getViaSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoViaSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.viaSourceStationNameTA = [];
                    this.viaSourceStationOptions = [];
                    this.viaSourceStationOptions = response;
                    this.lrDtoViaSourceStationAllOption.subStations = "All";
                    this.viaSourceStationNameTA.push(this.lrDtoViaSourceStationAllOption);
                    for (let i = 0; i < this.viaSourceStationOptions.length; i++) {
                        this.viaSourceStationNameTA.push(this.viaSourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        if ($("#" + this.pageId + "destinationId").val() != "All" && $("#" + this.pageId + "searchBy").val() == "mainInvoice" &&
            $("#" + this.pageId + "searchByStation").val() == "mainStation" ||
            $("#" + this.pageId + "searchByStation").val() == "subStation") {
            this.hideAndShowViaSourceField = true;
            $("#" + this.pageId + "viaSourceId").val('All');
        } else {
            this.hideAndShowViaSourceField = false;
            this.modelViaSourceStation = null;
        }
    }

    viaSourceStationListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelViaSourceStation = e.item;
        $("#" + this.pageId + "viaSourceId").val(this.modelViaSourceStation.destination);
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
