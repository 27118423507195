import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { EditCompanyComponent } from 'src/app/master/company/edit-company/edit-company.component';
import { CommonChargesComponent } from 'src/app/master/company/common-charges/common-charges.component';
import { UserCreationComponent } from 'src/app/master/company/user-creation/user-creation.component';
import { ExpensesDetailsEntryComponent } from 'src/app/master/company/expenses-details-entry/expenses-details-entry.component';
import { StaffMasterComponent } from 'src/app/master/company/staff-master/staff-master.component';
import { ConsignmentsClaimSettlementEntryComponent } from 'src/app/master/company/consignments-claim-settlement-entry/consignments-claim-settlement-entry.component';
import { UserFeaturesCustomizationComponent } from 'src/app/master/company/user-features-customization/user-features-customization.component';
import { SourceChargesSetupComponent } from 'src/app/master/company/source-charges-setup/source-charges-setup.component';
import { DatePipe } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { EditProfileComponent, EditProfilePopupComponent } from './edit-profile/edit-profile.component';
import { InchargeMasterComponent } from './incharge-master/incharge-master.component';
import { LoaderMasterComponent } from './loader-master/loader-master.component';
import { StockNotificationSetupComponent } from './stock-notification-setup/stock-notification-setup.component';
import { LrdeleteMasterpwdSetupComponent } from './lrdelete-masterpwd-setup/lrdelete-masterpwd-setup.component';
import { PartywisePerformanceMonitorComponent } from './party-wise-performance-monitor/party-wise-performance-monitor.component';
import { LrPerformanceMonitorPrintComponent } from './lr-performance-monitor-print/lr-performance-monitor-print.component';

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule,
        MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule],
    declarations: [EditCompanyComponent, CommonChargesComponent, UserCreationComponent, ExpensesDetailsEntryComponent,
        StaffMasterComponent, ConsignmentsClaimSettlementEntryComponent, UserFeaturesCustomizationComponent,
        SourceChargesSetupComponent, EditProfileComponent, EditProfilePopupComponent, InchargeMasterComponent,
        LoaderMasterComponent, StockNotificationSetupComponent, LrdeleteMasterpwdSetupComponent, PartywisePerformanceMonitorComponent,
        LrPerformanceMonitorPrintComponent],
    providers: [DatePipe],
    entryComponents: [EditProfilePopupComponent],
    exports: [LrPerformanceMonitorPrintComponent]
})
export class CompanyModule { }
