import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { Router } from "@angular/router";
import { MasterService } from 'src/app/dataService/master-service';
import { HttpClient } from '@angular/common/http';
@Component( {
    selector: 'app-common-charges',
    templateUrl: './common-charges.component.html',
    styleUrls: ['./common-charges.component.css']
} )
export class CommonChargesComponent implements OnInit {

    rateMasterDto: RateMasterDto = new RateMasterDto();
    userDataDtoReturnSession: any;
    rateMasterDtoSaveRet: any;
    isLoggedIn = true;
    bankMasterDataList: any;
    commonChargesId = 0;
    pageId="cccpt";
    constructor( private masterReadService: MasterReadService, private router: Router, private masterService: MasterService, private http: HttpClient, public changeDetectorRef : ChangeDetectorRef ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.getCommonChargeDetailsList();
        }
    }

    ngOnInit() {
    }

    //  service starts
    getDetailsForCommonChgMasterRead() {
        this.rateMasterDto.city = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDto.status = "InUse";
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    }


    getCommonChargeDetailsList = () => {
        this.getDetailsForCommonChgMasterRead();
        this.masterReadService.getCommonChargesMasterDetails( this.rateMasterDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    //                    console.log( response );
                    this.bankMasterDataList = response;
                    this.rateMasterDto = this.bankMasterDataList;
                    this.commonChargesId = this.rateMasterDto.id;
                    //alert(this.commonChargesId);
                    //                    console.log( this.rateMasterDto.isDDFlowApplicableOnLr );
                    //                    console.log( this.rateMasterDto.ddMinAmtOnLr );
                    //                    console.log( this.rateMasterDto.ddMinPercOnLr );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Common Charges Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }

    validateCommonCharges() {
    	swal({
            title: "Confirm Changes",
            text: "Sure U Want to Update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
            	 this.saveUpdateCommonChargesDetails();
            }
        });
    }
    saveCommonChargesDetails() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.id = this.commonChargesId;
        this.rateMasterDto.userName = this.userDataDtoReturnSession.userId;
    }
    saveUpdateCommonChargesDetails = () => {
        this.saveCommonChargesDetails();
        this.masterService.createCommonCharges( this.rateMasterDto ).
            subscribe(( data ) => {
                this.rateMasterDtoSaveRet = data;
                //                console.log( this.rateMasterDtoSaveRet.status );
                if ( this.rateMasterDtoSaveRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Common Charges Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
					this.getCommonChargeDetailsList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Common Charges Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Common Charges Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        //$( 'input[type="text"],[type="number"]' ).val( '' );
        //$( "#ddFlowInLrEntry" ).val( '' );
        this.commonChargesId = 0;

    }
}
