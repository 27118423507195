import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
@Component( {
    selector: 'app-tp-master',
    templateUrl: './tp-master.component.html',
    styleUrls: ['./tp-master.component.css']
} )
export class TpMasterComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrTpMasterTable: any;


    tpMasterDataList: any;


    onDestroyUnsubscribtionTpMaster: Subscription;

    //for datatable ends

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTpMaster: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsTpMaster: any;

    //for datatable ends

    //for the select option with filter starts

    control = new FormControl();

    dlIssueStateOptions = [
        { id: 1, label: 'Assam' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Tamil Nadu' },
    ];


    //for the select option with filter ends
    pageId="tpmc";


    constructor(/* for datatable starts */private tpMasterScreen: MasterService , public changeDetectorRef : ChangeDetectorRef/* for datatable endss */ ) {


    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */


    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsTpMaster = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Commodity Name',
                    data: 'commodityName'
                },
                {
                    title: 'Commodity Code',
                    data: 'commodityCode'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //        this.supplierList.getAllData().subscribe(data => {
        //            this.lrDispatchBknRptList = data['data'];
        //            this.dtTriggerSummary.next();
        //            } );
        this.gettingDataFrmServiceFrTpMasterTable = this.tpMasterScreen.getSummaryData()
        this.onDestroyUnsubscribtionTpMaster = this.gettingDataFrmServiceFrTpMasterTable.subscribe( data => {
            this.tpMasterDataList = data['data'];
            this.dtTriggerTpMaster.next();
        } );
        //for datatable ends
        //the below code is for the getting data through json ends
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerTpMaster.unsubscribe();

        this.onDestroyUnsubscribtionTpMaster.unsubscribe();
        //for datatable ends
    }

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends


}
