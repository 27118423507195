export class MessageDto {
    message: string;
    mobileNum: string;
    fromEmailId: string;
    fromEmailPass: string;
    emailSubject: string;
    emailContent: string;
    //    List<String> emailToList;
    //    List<String> emailCcList;
    //    List<String> emailBccList;
    emailToList: string[];
    emailCcList: string[];
    emailBccList: string[];
    autoId: number;
    senderId: string;
    templateName: string;
    templateType: string;
    template: string;
    templateId: string;
    isActive: boolean;
    variable: number;;
    createdAt: Date;;
    createdBy: string;
    modifiedAt: Date;;
    modifiedBy: string;
    status: string;
    lrNumber: string;
}