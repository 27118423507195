/*import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
export interface DialogData {
	animal: string;
	name: string;
}*/
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-hamali-detail-report',
	templateUrl: './hamali-detail-report.component.html',
	styleUrls: ['./hamali-detail-report.component.css']
})
export class HamaliDetailReportComponent implements OnInit {

	gridTosetHamaliList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerHamali: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsHamali: any;
	pageId="hdrc";

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	popUpOpenHamaliData: any;


	/* DROP DOWN */

	adminStationSourceHireSlipDto: HireSlipDto = new HireSlipDto();
	adminStationSourceOption: HireSlipDto[];
	public modelAdminStationSource: any;
	adminStationSourceTA: Array<HireSlipDto> = [];
	focusAdminStationSourceTA$ = new Subject<string>();
	searchAdminStationSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAdminStationSourceTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.adminStationSourceTA
				: this.adminStationSourceTA.filter(v => v.branch.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAdminStationSource = (x: { branch: string }) => x.branch;

	/* DROP DOWN */

	private newAttributeTosetHamaliDetails: any = {};

	editHamaliTableDetailsId: any = null;

	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private modalService: NgbModal, public dialogRef: MatDialogRef<HamaliDetailReportComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenHamaliData = JSON.parse(localStorage.getItem('HamaliPopUpOpen'));
			localStorage.clear();
			console.log(this.popUpOpenHamaliData);
			this.setAdminStationSourceDetails();

			//
			if (this.popUpOpenHamaliData != null && this.popUpOpenHamaliData != undefined) {
				//this.newAttributeTosetHamaliDetails = this.popUpOpenHamaliData;
				//this.gridTosetHamaliList.push(this.newAttributeTosetHamaliDetails);
				//this.gridTosetHamaliList = this.newAttributeTosetHamaliDetails;
				console.log(this.gridTosetHamaliList);
				/*var dataArray = this.popUpOpenHamaliData;
				console.log(dataArray);
				var arrayData = [];
				this.gridTosetHamaliList = [];
				dataArray.forEach(function (item) {
					//this.newAttributeTosetHamaliDetails.source = $("#"+this.pageId+"adminStationSource").val();
					//this.newAttributeTosetHamaliDetails.hamaliType = $("#"+this.pageId+"hamaliType").val();
					//this.newAttributeTosetHamaliDetails.hamaliValue = $("#"+this.pageId+"hamaliValue").val();
					arrayData.push(item.source, item.loadingHamali, item.source);
				});
				this.gridTosetHamaliList = arrayData;
				console.log(this.gridTosetHamaliList);*/
				//this.gridTosetHamaliList = this.popUpOpenHamaliData;
				/*let hamaliDataConvert = this.popUpOpenHamaliData;
				let hamaliDataValues = [];
				this.gridTosetHamaliList = [];
				for (let key in hamaliDataConvert) {   //Pay attention to the 'in'
					hamaliDataValues.push(hamaliDataConvert[key]);
				}
				console.log(hamaliDataValues);
				
				for (let i = 0; i < hamaliDataValues.length; i++) {
					console.log(hamaliDataValues[i]);
				}*/
				//console.log(this.gridTosetHamaliList);
				/*var hamaliArrayList = [];
				hamaliDataValues.forEach(function (item) {
					console.log(item);
					hamaliArrayList.push(item);
				});
				this.gridTosetHamaliList = hamaliArrayList;
				console.log(hamaliArrayList);
				console.log(this.gridTosetHamaliList);*/
				/*for (let hamaliData of hamaliDataValues) {
					//this.newAttributeTosetHamaliDetails.source = hamaliData[0];
					//this.newAttributeTosetHamaliDetails.hamaliType = hamaliData[1];
					//this.newAttributeTosetHamaliDetails.hamaliValue = hamaliData[2];
					//this.gridTosetHamaliList.push(this.newAttributeTosetHamaliDetails);
					//this.dtTriggerHamali.next();
					console.log("Data Values", hamaliData);
				}*/

			}
		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsHamali = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				//$(api.column(0).footer()).html('Total : ' + data.length);
				//$(api.column(1).footer()).html(discAmt);
			},
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerHamalis.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerHamali.next();
	}

	onKeyPressFieldForHamaliType(event) {
		if (event.charCode == '13') {
			$("#"+this.pageId+"hamaliValue").focus();
		}
	}

	onKeyPressFieldForHamaliValue(event) {
		console.log(event);
		if (event == '13') {
			if (($("#"+this.pageId+"adminStationSource").val() == null) || ($("#"+this.pageId+"adminStationSource").val() == undefined) ||
				($("#"+this.pageId+"adminStationSource").val() == "") || ($("#"+this.pageId+"hamaliType").val() == null) ||
				($("#"+this.pageId+"hamaliType").val() == undefined) || ($("#"+this.pageId+"hamaliType").val() == "") ||
				($("#"+this.pageId+"hamaliValue").val() == null) || ($("#"+this.pageId+"hamaliValue").val() == undefined) ||
				($("#"+this.pageId+"hamaliValue").val() == "")) {
				swal("Not Allowed", "Please enter all the details to proceed", "warning");
				return false;
			} else {
				this.gridReconfigureHamaliDetails();
			}
		}
	}

	addInTableOfHamaliDetailsTable() {
		if (($("#"+this.pageId+"adminStationSource").val() == null) || ($("#"+this.pageId+"adminStationSource").val() == undefined) ||
			($("#"+this.pageId+"adminStationSource").val() == "") || ($("#"+this.pageId+"hamaliType").val() == null) ||
			($("#"+this.pageId+"hamaliType").val() == undefined) || ($("#"+this.pageId+"hamaliType").val() == "") ||
			($("#"+this.pageId+"hamaliValue").val() == null) || ($("#"+this.pageId+"hamaliValue").val() == undefined) ||
			($("#"+this.pageId+"hamaliValue").val() == "")) {
			swal("Not Allowed", "Please enter all the details to proceed", "warning");
			return false;
		} else {
			this.gridReconfigureHamaliDetails();
		}
	}


	clickListnerForAdminStationSource(e: NgbTypeaheadSelectItemEvent) {
		this.modelAdminStationSource = e.item;
		$("#"+this.pageId+"adminStationSource").val(this.modelAdminStationSource.branch);
		$("#"+this.pageId+"hamaliType").focus();
	}

	setAdminStationSourceDetails = () => {
		this.adminStationSourceHireSlipDto = new HireSlipDto();
		this.adminStationSourceHireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
		this.adminStationSourceHireSlipDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
		this.masterReadService.getAdminStationsBranch(this.adminStationSourceHireSlipDto).subscribe(
			(response) => {
				if (response.length == 0) {
					this.adminStationSourceTA = [];
					this.adminStationSourceOption = [];
					swal("Warning", "No Admin Station  records found!", "warning");
				} else {
					this.adminStationSourceTA = [];
					this.adminStationSourceOption = [];
					this.adminStationSourceOption = response;
					for (let i = 0; i < this.adminStationSourceOption.length; i++) {
						this.adminStationSourceTA.push(this.adminStationSourceOption[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	gridReconfigureHamaliDetails() {
		this.newAttributeTosetHamaliDetails.source = $("#"+this.pageId+"adminStationSource").val();
		this.newAttributeTosetHamaliDetails.hamaliType = $("#"+this.pageId+"hamaliType").val();
		this.newAttributeTosetHamaliDetails.hamaliValue = $("#"+this.pageId+"hamaliValue").val();

		if (this.editHamaliTableDetailsId != null &&
			this.editHamaliTableDetailsId != undefined) {
			this.gridTosetHamaliList.splice(this.editHamaliTableDetailsId, 1);
		}

		if (this.gridTosetHamaliList == null || this.gridTosetHamaliList == undefined ||
			this.gridTosetHamaliList.length == 0) {
			this.gridTosetHamaliList = [];
		}

		if (this.gridTosetHamaliList.length == 0) {
			$("#"+this.pageId+"hamaliDetailTableId").DataTable().destroy();
			this.gridTosetHamaliList.push(this.newAttributeTosetHamaliDetails);
			this.dtTriggerHamali.next();
		} else {
			console.log(this.newAttributeTosetHamaliDetails);
			console.log(this.gridTosetHamaliList);
			const result = this.gridTosetHamaliList.filter(gridTosetHamaliData =>
				gridTosetHamaliData.source === this.newAttributeTosetHamaliDetails.source &&
				gridTosetHamaliData.hamaliType === this.newAttributeTosetHamaliDetails.hamaliType);
			//console.log( result );
			if (result.length > 0) {
				swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
			} else {
				$("#"+this.pageId+"hamaliDetailTableId").DataTable().destroy();
				this.gridTosetHamaliList.push(this.newAttributeTosetHamaliDetails);
				this.dtTriggerHamali.next();
			}
		}
		//clear
		this.newAttributeTosetHamaliDetails = {};
		this.modelAdminStationSource = null;
		$("#"+this.pageId+"adminStationSource").val('');
		$("#"+this.pageId+"hamaliType").val('');
		$("#"+this.pageId+"hamaliValue").val('');
		this.editHamaliTableDetailsId = null;
	}

	rowSelectedHamaliDataOnEdit(editHamaliData, index) {
		console.log(editHamaliData);
		$("#"+this.pageId+"adminStationSource").val(editHamaliData.source);
		$("#"+this.pageId+"hamaliType").val(editHamaliData.hamaliType);
		$("#"+this.pageId+"hamaliValue").val(editHamaliData.hamaliValue);
		this.editHamaliTableDetailsId = index;
	}

	hamaliDetailsOkClick() {
		this.dialogRef.close(JSON.stringify(this.gridTosetHamaliList));
		localStorage.clear();
		this.clearField();
	}

	onCancelClick(): void {
		this.dialogRef.close("Close");
		localStorage.clear();
		this.clearField();
	}

	clearField() {
		this.popUpOpenHamaliData = null;
		this.gridTosetHamaliList = [];
		this.newAttributeTosetHamaliDetails = {};
		this.editHamaliTableDetailsId = null;
	}

}

