<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showEwayBillExdRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info hide-header" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">EWay Bill Date Validation Extension Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Search By</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
                                                        <option selected value="validityExtenstion">Validity
                                                            Extenstion</option>
                                                        <option value="extendedHistory">Extended History</option>
                                                        <option value="autoValidityExtenstion">Auto Validity
                                                            Extenstion</option>
                                                            <option value="autoValidityExtenstionHistory">Auto Validity
                                                                Extenstion History</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewSrc" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName" (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                        placeholder="Select Source" />
                                                </div>
                                            </div>
                                        </div>


                                        <div *ngIf="viewDest" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getSearchMethod()">Search</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                        <!-- <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}extendValidityBtn" (click)="methodForAutoValidityExtensionTable()">Extend Validity</button> -->
                                    </div>
                                </div>

                                <div class="col-md-9 vl p-t-10">
                                    <div class="card-body">
                                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait Loading Details.....</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-t-10">

                                            <div class="col-md-4">
                                                <h6 class="card-title" [hidden]="!validationView">EWay Bill - Validation Action Lrs Details</h6>
                                                <h6 class="card-title" [hidden]="!extensionView">EWay Bill - Validation Extension History Details</h6>
                                                <h6 class="card-title" [hidden]="!autoExtensionView">EWay Bill - Auto Validation Extension Details</h6>
                                                <h6 class="card-title" [hidden]="!autoExtensionViewHistory">EWay Bill - Auto Validation Extension History Details</h6>

                                            </div>
                                            <div class="col-md-8">
                                                <button type="submit" [hidden]="!validationView" class="btn btn-primary m-r-10" style="padding: 1px 4px; margin-left: 8px;" id="{{pageId}}bulkdateAutoApiExtend" (click)="bulkAutoDateExtUpdteBtn();">
                                                    <i class="fas fa-file-alt"></i>&nbsp;Bulk API Date Extend
                                                </button>

                                                <button type="submit" [hidden]="!validationView" class="btn btn-primary m-r-10" style="padding: 1px 4px; margin-left: 57x;" id="{{pageId}}bulkdateExtend" (click)="bulkUpdteBtn(updateAllDatePopUpTemplate);">
                                                    <i class="fas fa-file-alt"></i>&nbsp;Bulk Date Extend
                                                </button>
                                                <button type="submit" [hidden]="!validationView" class="btn btn-primary m-r-10" style="padding: 1px 4px;" id="{{pageId}}ignoreSelected" (click)="ignoreAllMethod(ignoreSelectedAllPopUpTemplate);">
                                                    <i class="fas fa-file-alt"></i>&nbsp;Ignore Selected
                                                </button>
                                                <button [hidden]="!validationView" type="submit" class="dt-button" id="{{pageId}}printAllBtn" style="margin-left: 45px;" (click)="printMethodForValidityExtensionWise()">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <button [hidden]="!extensionView" type="submit" class="dt-button" style="margin-left: 385px;" id="{{pageId}}printBtn" (click)="printMethodForExtensionHistoryWise()">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="box-body" [hidden]="!validationView">

                                            <table datatable id="{{pageId}}validationLrsDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsValidationLrsDetailsTable" [dtTrigger]="dtTriggerValidationLrsDetailsTable">

                                                <thead>
                                                    <tr>
                                                        <th> <input type="checkbox" [checked]="multiSelect" (change)="toggleAllRows($event)">Actions</th>
                                                        <th>LR Number</th>
                                                        <th>Agent</th>
                                                        <th>Source</th>
                                                        <th>Destination</th>
                                                        <th>EWay Bill</th>
                                                        <th>Eway Bill Valid Upto</th>
                                                        <th>A.Wgt</th>
                                                        <th>C.Wgt</th>
                                                        <th>To Pay</th>
                                                        <th>Paid</th>
                                                        <th>Goods Value</th>
                                                        <th>Commodity Name</th>
                                                        <th>LR Generated By</th>
                                                        <th>LR Status</th>
                                                        <th>Remarks</th>
                                                        <th>Vehicle No</th>
                                                        <th>Invoice No</th>
                                                        <!-- <th>Last Location</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let validationLrsDetailsData of validationLrsDetailsDataList">
                                                        <td><input type="checkbox" [checked]="multiSelect" (change)="rowCheckBoxChecked($event, validationLrsDetailsData)" id='selected' style="margin-left: 0px">
                                                            <button style="padding: 1px 1px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}extendDateBtn" (click)="extendDateBtn(validationLrsDetailsData,updateDatePopUpTemplate);">
                                                                <i title="Extend Date" class="fas fa-edit"></i>
                                                            </button>
                                                            <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}ignoreBtn" (click)="ignoreMethod(validationLrsDetailsData,ignoreSelectedAllPopUpTemplate);">
                                                                <i title="Ignore" class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <a class="hyperLink_td" (click)="lrNumberToEnquiryForm(validationLrsDetailsData)">
                                                                <u>{{ validationLrsDetailsData.lrNumber }}</u>
                                                            </a>
                                                        </td>
                                                        <td>{{ validationLrsDetailsData.agentName }}</td>
                                                        <td>{{ validationLrsDetailsData.source }}</td>
                                                        <td>{{ validationLrsDetailsData.destination }}</td>
                                                        <!-- <td>{{ validationLrsDetailsData.eWayBillNo }}</td> -->
                                                        <!-- <td>{{ validationLrsDetailsData.ewayBillValidUptoStr}} -->
                                                        <!-- <td>
                                                            <span *ngIf="validationLrsDetailsData.listOfmultipleEwayBill.length == 0">{{
                                                                validationLrsDetailsData.eWayBillNo }}</span>
                                                            <span *ngIf="validationLrsDetailsData.listOfmultipleEwayBill.length > 0" (click)="openMultEwayPopup(validationLrsDetailsData,multipleEwayBillPopupOpen);"><u
                                                                    style="cursor: pointer;color:blue;">View Multi
                                                                    Eway</u></span>

                                                        </td> -->
                                                        <td>{{ validationLrsDetailsData.eWayBillNo }}</td>
                                                        <td>
                                                            <span *ngIf="validationLrsDetailsData.listOfmultipleEwayBill.length == 0">{{
                                                                validationLrsDetailsData.ewayBillValidUptoStr }}</span>
                                                            <span *ngIf="validationLrsDetailsData.listOfmultipleEwayBill.length > 0" (click)="openMultEwayPopup(validationLrsDetailsData,multipleEwayBillPopupOpen);"><u
                                                                    style="cursor: pointer;color:blue;">View Multi
                                                                    Eway</u></span>
                                                        </td>
                                                        <td>{{ validationLrsDetailsData.actualWeight }}</td>
                                                        <td>{{ validationLrsDetailsData.chargedWeight }}</td>
                                                        <td>{{ validationLrsDetailsData.toPay }}</td>
                                                        <td>{{ validationLrsDetailsData.paid }}</td>
                                                        <td>{{ validationLrsDetailsData.goodsValue }}</td>
                                                        <td>{{ validationLrsDetailsData.commodityName }}</td>
                                                        <td>{{ validationLrsDetailsData.generatedBy }}</td>
                                                        <td>{{ validationLrsDetailsData.status }}</td>
                                                        <td>{{ validationLrsDetailsData.remarks }}</td>
                                                        <td>{{ validationLrsDetailsData.vehicleNumber }}</td>
                                                        <td>{{ validationLrsDetailsData.invoiceNumber }}</td>
                                                        <!-- <td>{{ validationLrsDetailsData.location }}</td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <!-- <td></td> -->
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="box-body" [hidden]="!extensionView">
                                            <table datatable id="{{pageId}}extentionHistoryDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsExtentionHistoryDetails" [dtTrigger]="dtTriggerExtentionHistoryDetails">

                                                <thead>
                                                    <tr>
                                                        <th>LR Number</th>
                                                        <th>Agent</th>
                                                        <th>Source</th>
                                                        <th>Destination</th>
                                                        <th>EWay Bill No</th>
                                                        <th>Consignee Name</th>
                                                        <th>Consignor Name</th>
                                                        <th>Act Wgt</th>
                                                        <th>Chg Wgt</th>
                                                        <th>To Pay</th>
                                                        <th>Paid</th>
                                                        <th>Goods Value</th>
                                                        <th>Commodity Name</th>
                                                        <th>Eway Bill Valid Upto</th>
                                                        <th>Previous Validity Date</th>
                                                        <th>Extended/Ignored By</th>
                                                        <th>Extended/Ignored At</th>
                                                        <th>Ignored Reason</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let extentionHistoryDetailsData of extentionHistoryDetailsDataList">

                                                        <td>

                                                            <a class="hyperLink_td" (click)="lrNumberToEnquiryForm(extentionHistoryDetailsData)">
                                                                <u>{{ extentionHistoryDetailsData.lrNumber }}</u>
                                                            </a>
                                                        </td>
                                                        <td>{{ extentionHistoryDetailsData.agentName }}</td>
                                                        <td>{{ extentionHistoryDetailsData.source }}</td>
                                                        <td>{{ extentionHistoryDetailsData.destination }}</td>
                                                        <td>{{ extentionHistoryDetailsData.eWayBillNo }}</td>
                                                        <td>{{ extentionHistoryDetailsData.consigneeName }}</td>
                                                        <td>{{ extentionHistoryDetailsData.consignorName }}</td>
                                                        <td>{{ extentionHistoryDetailsData.actualWeight }}</td>
                                                        <td>{{ extentionHistoryDetailsData.chargedWeight }}</td>
                                                        <td>{{ extentionHistoryDetailsData.toPay }}</td>
                                                        <td>{{ extentionHistoryDetailsData.paid }}</td>
                                                        <td>{{ extentionHistoryDetailsData.goodsValue }}</td>
                                                        <td>{{ extentionHistoryDetailsData.commodityName }}</td>
                                                        <td>{{ extentionHistoryDetailsData.ewayBillValidUptoStr }}</td>
                                                        <td>{{ extentionHistoryDetailsData.invoiceDateStr }}</td>
                                                        <td>{{ extentionHistoryDetailsData.lastUpdatedBy }}</td>
                                                        <td>{{ extentionHistoryDetailsData.bookingDateStr }}</td>
                                                        <td>{{ extentionHistoryDetailsData.reason }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="box-body" [hidden]="!autoExtensionView">

                                            <table datatable id="{{pageId}}autoValidationLrsDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAutoValidationLrsDetailsTable" [dtTrigger]="dtTriggerAutoValidationLrsDetailsTable">

                                                <thead>
                                                    <tr>
                                                        <th>Eway Bill No</th>
                                                        <th>Vehicle No</th>
                                                        <th>From Place</th>
                                                        <th>From State</th>
                                                        <th>Rem Distance</th>
                                                        <th>Trans Doc No</th>
                                                        <th>Trans Doc Date</th>
                                                        <th>Trans Mode</th>
                                                        <th>Extn Reason Code</th>
                                                        <th>Extn Remarks</th>
                                                        <th>User Gstin</th>
                                                        <th>Address1</th>
                                                        <th>Address2</th>
                                                        <th>Address3</th>
                                                        <th>From Pincode</th>
                                                        <th>Transit Type</th>
                                                        <th>Consignment Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let autoValidationLrsDetailsData of autoValidationLrsDetailsDataList">
                                                        <td>{{ autoValidationLrsDetailsData.eWayBillNo }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.vehicleNumber }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.fromStation }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.fromState }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.remainingDist }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.transDocNo }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.transDocDate }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.transMode }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.extnReasonCode }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.extnRemarks }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.transportId }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.address1 }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.address2 }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.address3 }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.fromPincode }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.transitType }}</td>
                                                        <td>{{ autoValidationLrsDetailsData.status }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="box-body" [hidden]="!autoExtensionViewHistory">
                                            <table datatable id="{{pageId}}autoExtentionHistoryDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAutoExtentionHistoryDetails" [dtTrigger]="dtTriggerAutoExtentionHistoryDetails">

                                                <thead>
                                                    <tr>
                                                        <th>LR Number</th>
                                                        <th>Booking Date</th>
                                                        <th>Agent</th>
                                                        <th>Source</th>
                                                        <th>Destination</th>
                                                        <th>EWay Bill No</th>
                                                        <th>Consignee Name</th>
                                                        <th>Consignor Name</th>
                                                        <th>Ext Status</th>
                                                        <th>Extension Message</th>
                                                        <th>Remarks</th>
                                                        <th>Eway Bill Valid Upto</th>
                                                        <th>Extended By</th>
                                                        <th>Extended At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let autoExtentionHistoryDetailsData of autoExtentionHistoryDetailsDataList">
                                                        <td>
                                                            <a class="hyperLink_td" (click)="lrNumberToEnquiryForm(autoExtentionHistoryDetailsData)">
                                                                <u>{{ autoExtentionHistoryDetailsData.lrNumber }}</u>
                                                            </a>
                                                        </td>
                                                        <td>{{ autoExtentionHistoryDetailsData.bookingDateStr }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.agentName }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.source }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.destination }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.eWayBillNo }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.consigneeName }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.consignorName }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.status }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.respMessage }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.message }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.ewayBillValidUptoStr }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.lastUpdatedBy }}</td>
                                                        <td>{{ autoExtentionHistoryDetailsData.lastUpdatedDateStr }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #updateAllDatePopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Change EWay Bill Valid Upto Date For Selected Lr Numbers</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> New Valid Upto Date:</label> <input id="{{pageId}}updateAllDate" class="form-control" [(ngModel)]="setTodayDateOnUpdateAllDate" placeholder="dd-mm-yyyy" name="updateAllDate" ngbDatepicker #updateAllDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="updateAllDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">


                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelAllBtnId" (click)="closeUpdateAllDatePopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}updateAllBtn" (click)="bulkExtendInfo();">Update
                                                All</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #updateDatePopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Change EWay Bill Valid Upto Date For Selected Lr Number {{selectedLrNumber}}
                        </h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>New Valid Upto Date:</label> <input id="{{pageId}}updateDate" class="form-control" [(ngModel)]="setTodayDateOnUpdateDate" placeholder="dd-mm-yyyy" name="updateDate" ngbDatepicker #updateDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="updateDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">


                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeUpdateDatePopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}updateBtn" (click)="updateExtendInfo();">Update</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #ignoreSelectedAllPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Ignore reason for the selected LRs</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Reason:</label>
                                                    <textarea class="form-control" rows="4" id="{{pageId}}ignoreSelectedAll"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">


                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelIgnoreAllBtnId" (click)="closeIgnoreAllPopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}ignoreAllBtn" (click)="updateExtensionValidityBulkInfo();">Confirm
                                                Ignore</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}popupDetails">
                <!--<label>Multiple Art</label>-->
                <ng-template #multipleEwayBillPopupOpen let-c="close" let-d="dismiss">
                    <div class="modal-header">
                        <h6>Change EWay Bill Valid Upto Date For this Lr Number : {{selectedLrNumber}}
                        </h6>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}eWallBillLbl">
                                                <label class="wtfull">E-Way
                                                    Bill No.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}eWayDateLbl">
                                                <label class="wtfull">E-Way
                                                    Bill Valid
                                                    Upto</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input type="number" aria-describedby=" basic-addon11 " autocomplete="off" title="Must Be 10 Number" class="form-control" id="{{pageId}}eWayBillNoMulti" (keyup)="eWayBillNoMultiFocus($event)" oninput="javascript: if(this.value.length > this.maxLength) 
                                                this.value=value.slice(0,this.maxLength);" maxLength="10" #eWayBillNoMulti readonly>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input class="form-control" id="{{pageId}}eWayBillValidUptoDateMulti" name="eWayBillValidUptoDateMulti" (keyup)="eWayBillValidUptoDateFocusMulti($event)" ngbDatepicker [(ngModel)]="eWayBillValidUptoDateMultiModel" placeholder="dd-mm-yyyy" #eWayBillValidUptoDateMulti="ngbDatepicker"
                                                    (ngModelChange)="focusEWayBillValidUptoCalendarMulti()" (keydown.arrowdown)="eWayBillValidUptoDateMulti.toggle();" (click)="eWayBillValidUptoDateMulti.toggle();">&nbsp;
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-sm-12 col-md-1">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">

                                                    <a href="javascript:;" type='button' id="{{pageId}}addInTableMultiEwayBtn" #addInTableMultiEwayBtn class="btn btn-icon-only yellow" (click)="chkDuplicateMultiEwayBillNo();">
                                                        <i class="fa fa-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="box-body">
                                            <!-- 							the first datatble starts -->
                                            <!-- timeTable-->
                                            <table datatable id="{{pageId}}multipleEwayPopupTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMultiEway" [dtTrigger]="dtTriggerMultEway">

                                                <thead>
                                                    <tr>
                                                        <th>E-way Bill No
                                                        </th>
                                                        <th>E-Way Bill Valid Upto
                                                        </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let multiEwayTableData of multiEwayTableDataList let i = index">
                                                        <td>{{ multiEwayTableData.eWayBillNo }}
                                                        </td>
                                                        <td>{{ multiEwayTableData.ewayBillValidUpto }}
                                                        </td>
                                                        <td>
                                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditMultiEway(multiEwayTableData,i);">
                                                                <i title="Edit" class="fas fa-pencil-alt"></i>
                                                            </button>&nbsp;
                                                            <!-- <button
                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                class="btn m-r-10"
                                                                id="{{pageId}}tableRemoveBtn"
                                                                (click)="rowSelectedDeleteMultiEway(multiEwayTableData,i)">
                                                                <i title="Remove"
                                                                    class="fas fa-trash"></i>
                                                            </button> -->
                                                        </td>
                                                    </tr>
                                                    <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                </tbody>
                                                <!-- 																					<tfoot> -->
                                                <!-- 																						<tr> -->
                                                <!-- 																							<td style="text-align: left" id="{{pageId}}totalMultiArticles"></td> -->
                                                <!-- 																							<td></td> -->
                                                <!-- 																							<td></td> -->
                                                <!-- 																							<td></td> -->
                                                <!-- 																							<td></td> -->
                                                <!-- 																						</tr> -->
                                                <!-- 																					</tfoot> -->
                                            </table>
                                        </div>
                                    </div>
                                    <!-- 																			<div class="col-md-12"> -->
                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                    <!-- 																					id="{{pageId}}saveMultiArtBtn" -->
                                    <!-- 																					(click)="onCancelClick();d('Cross click')">Save</button> -->
                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                    <!-- 																					(click)="onCancelClick()" id="{{pageId}}closeMultiArtBtn">close</button> -->
                                    <!-- 																			</div> -->
                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateMultiArtBtn" (click)="multiEwayFocus();">Update</button>
                                        <button type="submit" class="btn btn-success m-r-10" (click)="closeUpdateMultiEwayDatePopUp();d('Cross click')" id="{{pageId}}closeMultiArtBtn">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--<a style="cursor: pointer; color: blue;"
                (click)="open7(multipleEwayBillPopupOpen)"><u> View Details</u></a>-->
            </div>
        </div>
    </div>

</body>

</html>