import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ConsignorService } from 'src/app/dataService/consignor-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal ends


@Component( {
    selector: 'app-trip-creation-make-a-trip',
    templateUrl: './trip-creation-make-a-trip.component.html',
    styleUrls: ['./trip-creation-make-a-trip.component.css']
} )
export class TripCreationMakeATripComponent implements OnInit {

    //to insert value of selected row in table to input field starts 
    consignorName: string;
    selectedUser: any;
    //to insert value of selected row in table to input field ends
    getDataFrmServiceFrEligibleLrsTable: any;
    getDataFrmServiceFrRemainingLrsTable: any;

    eligibleLrsDataList: any;
    remainingLrsDataList: any;



    onDestroyUnsubscribtionEligibleLrs: Subscription;
    onDestroyUnsubscribtionRemainingLrs: Subscription;

    //for the select option with filter starts


    controlTruckCapacity = new FormControl();
    controlBins = new FormControl();


    truckCapacityOptions = [
        { id: 1, label: 'ADD NEW' },
        { id: 2, label: '0' },
        { id: 3, label: '1' },
        { id: 4, label: '2' },
        { id: 5, label: '3' },
    ];
    binsOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'Bangalore' },
        { id: 3, label: 'Chennai' },
        { id: 4, label: 'Erode' },
        { id: 5, label: 'Kannur' },
    ];



    //for the select option with filter ends

    //for popup modal starts
    closeResultContent: string;
    //for popup modal ends




    //summaryTable:any;

    loadingIndicator = true;


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;



    dataTable: any;


    //

    dtTriggerEligibleLrs: Subject<any> = new Subject();
    dtTriggerRemainingLrs: Subject<any> = new Subject();


    //


    // @ViewChild('dataTable') table: ElementRef;
    dtOptionsEligibleLrs: any;
    dtOptionRemainingLrs: any;
    pageId="tcmtc";

    constructor( private tripCreationMakeATripScreen: ConsignorService,
        //for popup modal starts
        private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef
        //for popup modal ends                
    ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {

        //first datatable starts
        this.dtOptionsEligibleLrs = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }

            ],

            // the below code is for button export ends                          

            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends

            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            pageLength: 8,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends


        },//first datatable ends
            //second datatable starts 
            this.dtOptionRemainingLrs = {
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 220,
                "scrollCollapse": true,
                //this to paggination and content like showing 1 to 3 of 20 entries Starts
                pagingType: 'full_numbers',
                pageLength: 8,
                //this to paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            //second datatable ends


            //the below code is for the getting data through json starts
            //            this.supplierList.getAllData().subscribe(data => {
            //                this.lrDispatchBknRptList = data['data'];
            //                this.dtTriggerSummary.next();
            //                } );

            //first service starts 
            this.getDataFrmServiceFrEligibleLrsTable = this.tripCreationMakeATripScreen.getSupplierMasterData()
        this.onDestroyUnsubscribtionEligibleLrs = this.getDataFrmServiceFrEligibleLrsTable.subscribe( data => {
            this.eligibleLrsDataList = data['data'];
            this.dtTriggerEligibleLrs.next();
        } );
        //first service ends
        //second service starts 

        this.getDataFrmServiceFrRemainingLrsTable = this.tripCreationMakeATripScreen.getSupplierMasterData();
        this.onDestroyUnsubscribtionRemainingLrs = this.getDataFrmServiceFrRemainingLrsTable.subscribe( data => {
            this.remainingLrsDataList = data['data'];
            this.dtTriggerRemainingLrs.next();
        } );
        //second service ends

        //the below code is for the getting data through json ends

    }



    ngOnDestroy(): void {
        this.dtTriggerEligibleLrs.unsubscribe();
        this.dtTriggerRemainingLrs.unsubscribe();

        this.onDestroyUnsubscribtionEligibleLrs.unsubscribe();
        this.onDestroyUnsubscribtionRemainingLrs.unsubscribe();

    }
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected( confirmedConsignorListData ) {
        this.selectedUser = confirmedConsignorListData;
        this.consignorName = this.selectedUser.name;
        console.log( this.selectedUser.name );
    }
    //to insert value of selected row in table to input field ends


    //for popup modal starts 

    openPopup( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }

    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

}
