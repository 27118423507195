<h1 class="title_custom" mat-dialog-title>
    <!-- 	<h6 style="margin-bottom: 0px;">Lr Details For -->
    <!-- 		{{getInvoiceNumber}}</h6> -->
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <!-- spinner start-->
            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                            Please Wait Loading Details.....</h6>
                    </div>
                </div>
            </div>
            <!-- spinner end-->
            <div class="card">
                <div class="card-body">
                    <div class="box-body">
                        <table datatable id="{{pageId}}lrDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                            <thead>
                                <tr>
                                    <th>Bkg Dt</th>
                                    <th>LR Number</th>
                                    <th>Agent</th>
                                    <th>Consignee</th>
                                    <th [hidden]="!viewLessChkShow1">B.Frgt</th>
                                    <th [hidden]="!viewLessChkShow1">S.Ham Chg</th>
                                    <th [hidden]="!viewLessChkShow1">LC Chg</th>
                                    <th [hidden]="!viewLessChkShow1">BC Chg</th>
                                    <th [hidden]="!viewLessChkShow1">AOC Chg</th>
                                    <th [hidden]="!viewLessChkShow1">FOV Chg</th>
                                    <th [hidden]="!viewLessChkShow1">DD Chg</th>
                                    <th [hidden]="!viewLessChkShow1">GC Chg</th>
                                    <th [hidden]="!viewLessChkShow1">Oth Chg</th>
                                    <th>To Pay</th>
                                    <th [hidden]="hideMLessColumn">M Less Amt</th>
                                    <th>Total Amt</th>
                                    <th>Articles</th>
                                    <th>Act Wgt</th>
                                    <th>Chg Wgt</th>
                                    <th>Commodity</th>

                                    <th>Act Less</th>
                                    <th>Ent Less</th>
                                    <th>Diff Less</th>
                                    <th [hidden]="hideMLessColumn">Stmt No</th>
                                    <th [hidden]="!viewLessChkShow1">Unit</th>
                                    <th [hidden]="!viewLessChkShow1">Per Unit</th>
                                    <th [hidden]="!viewLessChkShow1">Rate</th>
                                    <th [hidden]="!viewLessChkShow1">GC</th>

                                    <th [hidden]="!viewLessChkShow1">S.Ham</th>
                                    <th [hidden]="!viewLessChkShow1">LC</th>
                                    <th [hidden]="!viewLessChkShow1">BC</th>
                                    <th [hidden]="!viewLessChkShow1">AOC</th>
                                    <th [hidden]="!viewLessChkShow1">FOV</th>
                                    <th [hidden]="!viewLessChkShow1">DD</th>
                                    <th [hidden]="!viewLessChkShow1">Oth</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                    <td>{{ lrDetailsData.bookingDateStr }}</td>
                                    <td>{{ lrDetailsData.lrNumber }}</td>
                                    <td>{{ lrDetailsData.agentName }}</td>
                                    <td>{{ lrDetailsData.consigneeName }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.baseFreight }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.srcHamali }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.lcChg }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.bcChg }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.aoc }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.riskCharge }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.ddAmt }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.gcChargeLr }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.others }}</td>
                                    <td>{{ lrDetailsData.toPay }}</td>
                                    <td [hidden]="hideMLessColumn">{{ lrDetailsData.mLessAmt }}</td>
                                    <td>{{ lrDetailsData.totalAmount }}</td>
                                    <td>{{ lrDetailsData.totalArticles }}</td>
                                    <td>{{ lrDetailsData.actualWeight }}</td>
                                    <td>{{ lrDetailsData.chargedWeight }}</td>
                                    <td>{{ lrDetailsData.commodityName }}</td>

                                    <td>{{ lrDetailsData.actualLessAmt }}</td>
                                    <td>{{ lrDetailsData.enteredLessAmt }}</td>
                                    <td>{{ lrDetailsData.diffValue }}</td>
                                    <td [hidden]="hideMLessColumn">{{ lrDetailsData.enteredStmtNo }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.mode }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.ratePerUnit }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.rate }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.gcCharge }}</td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.hamaliCharge==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.lcCharge==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.bcCharge==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.aOCCharge==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.riskChg==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.dDCharge==true?"Yes":"No" }}
                                    </td>
                                    <td [hidden]="!viewLessChkShow1">{{ lrDetailsData.othersCharge==true?"Yes":"No" }}
                                    </td>


                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td></td>
                                    <td [hidden]="hideMLessColumn"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td [hidden]="hideMLessColumn"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                    <td [hidden]="!viewLessChkShow1"></td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <!-- 	<button class="btn btn-outline-danger" mat-button -->
    <!-- 		(click)="onCancelClick()">Cancel</button> -->
    <button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>