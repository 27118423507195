import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { MatDialog } from '@angular/material/dialog';
import { SupplierDetailsDto } from 'src/app/dto/SupplierDetails-dto';
import { PdcInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/pdc-details-popup/pdc-info-popup.component';
import { ChequeInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/cheque-details-popup/cheque-info-popup.component';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { InvoiceDto } from 'src/app/dto/Invoice-dto';

@Component({
	selector: 'app-hirepayment-enet-bank-report',
	templateUrl: './hirepayment-enet-bank-report.component.html',
	styleUrls: ['./hirepayment-enet-bank-report.component.css']
})
export class HirepaymentEnetBankReportComponent implements OnInit {

	advancePaymentDataList: any;
	bankPaymentDetailsList = [];
	model: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	setTodayDateOnToDate: any;
	setTodayDateOnFromDate: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerAdvancePayment: Subject<any> = new Subject();
	dtTriggerBankPaymentDetails: Subject<any> = new Subject();
	dtOptionsAdvancePayment: any;
	dtOptionsBankPaymentDetails: any;
	//For Source Main Stations
	lrDtoSourceStationAllOption: LRDto = new LRDto();
	sourceStationOptions: LRDto[];
	lrDtoSourceStation: LRDto = new LRDto();

	public modelSource: any;
	sourceSubStationNameTA: Array<LRDto> = [];
	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourceSubStationNameTA
				: this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSource = (x: { source: string }) => x.source;

	//    For From Station 
	lrDtoFromStn: LRDto = new LRDto();
	lrDtoFromStnAll: LRDto = new LRDto();
	lrDtosFromStnOptionsGet: LRDto[];

	srcLists: any;
	srcList: LRDto = new LRDto();
	srcListForBkgAdmin: LRDto = new LRDto();
	rightsOfHirelsipRpt: any;

	// for To station
	lrDtoToStationAll: LRDto = new LRDto();
	lrDtoToStation: LRDto = new LRDto();
	lrDtosToStationOptionsGet: LRDto[];

	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { godownName: string }) => x.godownName;

	destinationDtoForAll: LRDto = new LRDto();
	supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
	supplierDetailsDtoAll: SupplierDetailsDto = new SupplierDetailsDto();
	supplierOptions: SupplierDetailsDto[];
	public modelSupplierName: any;
	supplierNameTA: Array<SupplierDetailsDto> = [];
	focusSupplierNameTA$ = new Subject<string>();
	searchSupplierName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSupplierNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.supplierNameTA
				: this.supplierNameTA.filter(v => v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

	hashMapSupplierIndex: Map<String, Number> = new Map<String, Number>();
	hashMapSupplier: Map<String, SupplierDetailsDto> = new Map<String, SupplierDetailsDto>();

	showSpinnerForAction = false;
	selectedFromDate: any;
	selectedToDate: any;
	selectedSource: any;
	selectedDestination: any;
	selectedSupplierName: any;
	selectedViewBy: any;
	selectedPaymentMethod: any;
	selectedSearchBy: any;
	hireslipDtoForSearch: HireSlipDto = new HireSlipDto();

	listLrDtosChq: LRDto = new LRDto();
	listLrDtosChqList: Array<LRDto> = [];
	listLrDtosPdc: LRDto = new LRDto();
	listLrDtosPdcList: Array<LRDto> = [];
	viewPaymentVoucher = false;
	viewPaymentVoucherChq = false;
	viewPaymentVoucherPdc = false;

	modalRefferenceConfirmPaymentPopUp: NgbModalRef;
	modalRefferenceConfirmPaymentClosePopUp: string;
	@ViewChild('confirmPaymentPopUpTemplate') private confirmPaymentPopUpTemplate;
	viewConfirmPaymentPopUp = false;
	hirePrintDto: HireSlipDto = new HireSlipDto();
	hirePrintDtoPrintVoucher: HireSlipDto = new HireSlipDto();
	valueMin = 0.0;
	valueMax = 0.0;
	modalRefferenceAdvancePaymentPopUp: NgbModalRef;
	modalRefferenceAdvancePaymentClosePopUp: string;
	@ViewChild('advancePaymentPopUpTemplate') private advancePaymentPopUpTemplate;
	isUploaded = false;
	selectedAdvancePaymentDetails: any;
	showSpinnerForActionForUpload = false;
	panNumberUpload: any;
	truckNumberUpload: any;
	ackReckPopupStmtNo: any;

	splitedUrl: Array<any> = [];
	hireslipDtoForFinYear: HireSlipDto = new HireSlipDto();
	financialYear: any;
	fileName: any;
	truckDataDtoForRemoveImg: TruckDataDto = new TruckDataDto();
	showForSupplierWise = true;
	showForDestWise = false;
	pageId = "ehbpc";
	showHireColumns: Boolean = false;
	//payment flow
	dtTriggerAdvancePaymentCashChequePdc: Subject<any> = new Subject();
	dtOptionsAdvancePaymentCashChequePdc: any;
	advancePaymentChequePdcDataList = [];

	bankNameOptions: TruckDataDto[];
	public modelBankName: any;
	bankNameTA: Array<TruckDataDto> = [];
	focusBankNameTA$ = new Subject<string>();
	searchBankName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusBankNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.bankNameTA
				: this.bankNameTA.filter(v => v.acType.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterBankName = (x: { acType: string }) => x.acType;

	viewCashChequePdcAmtField: boolean = true;
	viewChequePdcNoField: boolean = true;
	viewChequeDueDateField: boolean = false;
	viewChequePdcBankNameField: boolean = true;

	modelChequeDueDates: any;
	modelPayingDates: any;

	localStorageOfPaymentData: any;
	localStorageOfPaymentDataHireslipNumber: any;

	private newAttributeTosetBank: any = {};
	editBankTableDetailsId: any;

	validateBalAmtToBePaid: any;
	validateBalAmtCash: any;
	validateBalAmtCheque: any;
	validateBalAmtPdc: any;

	validateBalAmtForSave: any;
	validateSummCashAmtForSave: any;
	validateSummChequeAmtForSave: any;
	validateSummPdcAmtForSave: any;
	validatePayingDateForSave: any;
	validateInitBalAmtForSave: any;
	resultDuplicate: any;

	fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
	fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
	fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();

	Statement: any;
	hireslipNumber: any;
	selectedAdvancePayable: any;
	hireslipDtoForSave: HireSlipDto = new HireSlipDto();
	hireslipDtoForUserVal: HireSlipDto = new HireSlipDto();
	hireslipDtoForMulVal: HireSlipDto = new HireSlipDto();
	viewTdsVoucher = false;
	viewTdsVoucherWithPayment = false;

	sentSrcList: Array<any> = [];
	searchSrcListInLoop: Array<any> = [];
	truckDataDto: TruckDataDto = new TruckDataDto();

	selectedTruckBankAcId = null;
	selectedAcNo = null;
	selectedAcName = null;
	selectedBankName = null;
	selectedIfsc = null;
	selectedBranch = null;
	selectedAcType = null;
	showBankAcDetails: boolean = false;
	selectedHireslipNo = null;
	selectedTruckNo = null;
	selectedHireBalAmt = null;
	selectedTrnxType = "RTGS";
	selectedTrnxTypeTemp: any;
	viewSelectedBalance: boolean = false;
	private newAttributeToBankFinal: any = {};
	editFinalBankTableDetailsId: any;
	totCashAmt: number;
	totBankAmt: number;
	totBankCashPaymentAmt: number;
	hireslipDtoPayment: HireSlipDto = new HireSlipDto();
	hireslipDtoPaymentRet: HireSlipDto = new HireSlipDto();
	hireslipDtosPayment: HireSlipDto[];
	showHireAmt: boolean = false;
	showHireAdv: boolean = false;
	showHireBal: boolean = false;
	srcPaymentOption: boolean = false;
	destPaymentOption: boolean = false;
	closeResult: string;
	selectedHireslipNumberForReject: string;
	invoiceDto: InvoiceDto = new InvoiceDto();
	enteredReason = null;

	constructor(private router: Router, private hireslipService: HireslipService,
		public dialog: MatDialog, private masterReadService: MasterReadService,
		private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef,
		private masterService: MasterService) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			//          userdata get through from login starts
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.selectTodayDate();
			this.gettingFromStation();
			this.getToStationDetailsList();
			///Rights
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.role != null
						&& this.userDataDtoReturnSession.role == "Booking Administrator") {
						this.rightsOfHirelsipRpt = 'AdvancePayment Rpt R1';
						// For Booking Administrator 
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enet Rpt Show Hire") {
						this.showHireAmt = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enet Rpt Show Adv") {
						this.showHireAdv = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enet Rpt Show Bal") {
						this.showHireBal = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enet Rpt Src Payment") {
						this.srcPaymentOption = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enet Rpt Dest Payment") {
						this.destPaymentOption = true;
					}
				}
			}
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	ngOnInit(): void {
		this.dtOptionsAdvancePayment = {
			dom: 'Bfrtip',
			buttons: [{
				extend: 'excel',
				text: '<i class="fas fa-file-excel"> Excel</i>',
				titleAttr: 'Excel',
				footer: true,
			}],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			destroy: true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var adv = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var bal = api.column(11).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totHire = api.column(12).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				// var cashAmt = api.column(13).data().reduce(
				// 	function (a, b) {
				// 		return intVal(a) + intVal(b);
				// 	}, 0);
				// var banAmt = api.column(14).data().reduce(
				// 	function (a, b) {
				// 		return intVal(a) + intVal(b);
				// 	}, 0);

				$(api.column(0).footer()).html('Total');
				$(api.column(1).footer()).html(data.length);
				$(api.column(10).footer()).html(adv);
				$(api.column(11).footer()).html(bal);
				$(api.column(12).footer()).html(totHire);
				// $(api.column(13).footer()).html(cashAmt);
				// $(api.column(14).footer()).html(banAmt);
			}
		}

		this.dtOptionsBankPaymentDetails = {
			dom: 'Bfrtip',
			buttons: [{
				extend: 'excel',
				text: '<i class="fas fa-file-excel"> Excel</i>',
				titleAttr: 'Excel',
				footer: true,
			},
			{
				extend: 'csv',
				fieldBoundary: '',
				text: '<i class="fas fa-file-excel"> Generate Bank Copy</i>',
				titleAttr: 'Generate Bank Copy',
				footer: false,
				header: false,
				exportOptions: {
					columns: [12, 3, 4, 5, 7, 13, 14, 15, 16, 17, 18, 19, 20,
						8, 21, 22, 23, 24, 25, 26, 27, 28,
						9, 29,
						10, 30, 31,
						11]
				}
			}],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			destroy: true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalBankAmt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalCashAmt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total');
				$(api.column(1).footer()).html(data.length);
				$(api.column(5).footer()).html(totalBankAmt);
				$(api.column(6).footer()).html(totalCashAmt);
			}
		}

		this.dtOptionsAdvancePaymentCashChequePdc = {
			dom: 'Bfrtip',
			buttons: [
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 150,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			searching: false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totaAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total');
				$(api.column(1).footer()).html(totaAmt);
			}
		}

	}

	ngAfterViewInit(): void {
		this.dtTriggerAdvancePayment.next();
		this.dtTriggerBankPaymentDetails.next();
		this.dtTriggerAdvancePaymentCashChequePdc.next();
	}
	ngOnDestroy(): void {
		this.dtTriggerAdvancePayment.unsubscribe();
		this.dtTriggerBankPaymentDetails.unsubscribe();
		this.dtTriggerAdvancePaymentCashChequePdc.unsubscribe();
	}

	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	selectTodayDate() {
		this.setTodayDateOnToDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
		this.setTodayDateOnFromDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};

		$("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
		$("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
	}

	gettingFromStation() {
		if ((this.rightsOfHirelsipRpt == 'rightsOfHirelsipRpt') && (this.userDataDtoReturnSession.stationList != null)) {
			//    for boooking adminsitrator
			this.srcLists = this.userDataDtoReturnSession.stationList;
			this.srcList.source = this.srcLists;
			console.log(this.srcLists);
			console.log(this.srcList.source);
			this.lrDtoFromStnAll.source = "ALL";
			this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
			for (let i = 0; i < this.srcList.source.length; i++) {
				this.srcListForBkgAdmin = new LRDto();
				this.srcListForBkgAdmin.source = this.srcList.source[i];
				console.log(this.srcListForBkgAdmin.source);
				this.sourceSubStationNameTA.push(this.srcListForBkgAdmin);

			}
		} else {
			this.gettingFromStationList();
		}

	}
	gettingFromStationDetails() {
		this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoFromStn.mode = "notStmtInvRpt";
	}
	gettingFromStationList = () => {
		this.showSpinnerForAction = true;
		this.gettingFromStationDetails();
		this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Source records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {

					this.sourceSubStationNameTA = [];
					this.lrDtosFromStnOptionsGet = [];
					this.searchSrcListInLoop = [];
					this.lrDtosFromStnOptionsGet = response;
					this.lrDtoFromStnAll.source = "ALL";
					this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
					for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
						this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);
						this.searchSrcListInLoop.push(this.lrDtosFromStnOptionsGet[i].source);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Destination Details", "warning");
			},
			() => console.log('done');
	};


	/// Get To Stations
	getToStationDetails() {
		this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;

	}
	getToStationDetailsList = () => {
		this.getToStationDetails();
		this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Destination records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {

					this.destinationOptions = response;
					console.log(this.destinationOptions);
					this.destinationTA = [];
					this.destinationDtoForAll.godownName = "ALL";
					this.destinationTA.push(this.destinationDtoForAll);
					for (let i = 0; i < this.destinationOptions.length; i++) {
						this.destinationTA.push(this.destinationOptions[i]);

					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Destination Details", "warning");
			},
			() => console.log('done');
	};

	getSupplierDetails() {
		this.supplierDetailsDto = new SupplierDetailsDto();
		this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
		//this.supplierDetailsDto.mode = "Working";
	}
	getSupplierDetailsList = () => {
		this.getSupplierDetails();
		this.showSpinnerForAction = true;
		this.masterReadService.getSuplierMaster(this.supplierDetailsDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					this.supplierOptions = [];
					this.supplierNameTA = [];
					swal("Warning", "No Supplier Master records found!", "warning");
				} else {
					this.supplierOptions = [];
					this.supplierNameTA = [];
					this.supplierOptions = response;
					this.supplierDetailsDtoAll.supplierName = "All";
					this.supplierNameTA.push(this.supplierDetailsDtoAll);
					for (let i = 0; i < this.supplierOptions.length; i++) {
						this.supplierNameTA.push(this.supplierOptions[i]);

					}
				}
				//this.showSpinnerForActionForInvoiceDetails = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Supplier Master Details", "warning");
			},
			() => console.log('done');
	};

	searchMethod() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedSource = $("#" + this.pageId + "sourceId").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();

		if (this.selectedSource == null || this.selectedSource == '' || this.selectedSource == undefined) {
			swal({
				title: "Mandatory Field",
				text: "Please select Source",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined) {
			swal({
				title: "Mandatory Field",
				text: "Please select Destination",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
		this.hireslipDtoForSearch = new HireSlipDto();
		this.hireslipDtoForSearch.fromDate = this.selectedFromDate;
		this.hireslipDtoForSearch.toDate = this.selectedToDate;
		this.hireslipDtoForSearch.stationList = this.userDataDtoReturnSession.stationList;
		this.hireslipDtoForSearch.fromStation = this.selectedSource;
		this.hireslipDtoForSearch.toStation = this.selectedDestination;
		this.hireslipDtoForSearch.companyid = this.userDataDtoReturnSession.companyId;
		if (this.selectedSource == '' || this.selectedSource == null || this.selectedSource == 'ALL') {
			this.sentSrcList = [];
			this.sentSrcList = this.searchSrcListInLoop;
			this.hireslipDtoForSearch.listOfSource = this.sentSrcList;
		} else {
			this.sentSrcList = [];
			this.sentSrcList.push(this.selectedSource);
			this.hireslipDtoForSearch.listOfSource = this.sentSrcList;
		}
		if (this.srcPaymentOption) {
			this.hireslipDtoForSearch.reportMode = 'Advance';
		} else if (this.destPaymentOption) {
			this.hireslipDtoForSearch.reportMode = 'Balance';
		}
		this.searchMethodDetails();
	}

	searchMethodDetails = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getEnetHirePaymentDetails(this.hireslipDtoForSearch).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
				this.advancePaymentDataList = [];
				console.log('searchMethodDetails');
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Records found for this search!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.advancePaymentDataList = response;
					console.log('searchMethodDetails 1');
					console.log(this.advancePaymentDataList);
				}
				this.dtTriggerAdvancePayment.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	}


	getChqInfoTable(advancePaymentData) {
		if (advancePaymentData.advTotalChqAmt != null && advancePaymentData.advTotalChqAmt != 0) {
			this.chqInfoDialog(advancePaymentData);
		}
	}
	//for modal starts
	chqInfoDialog(advancePaymentData): void {
		console.log(advancePaymentData);
		this.listLrDtosChq = new LRDto();
		this.listLrDtosChq.chequeNumber = advancePaymentData.advChqNumber;
		this.listLrDtosChq.chequeAmt = advancePaymentData.advChqAmt;
		this.listLrDtosChqList = [];
		this.listLrDtosChqList.push(this.listLrDtosChq);
		const dialogRef = this.dialog.open(ChequeInfoPopupComponent, {
			data: { listOfDataToPass: this.listLrDtosChqList }
		});
	}

	getPdcInfoTable(advancePaymentData) {
		if (advancePaymentData.advPdcChqAmt != null && advancePaymentData.advPdcChqAmt != 0) {
			this.pdcInfoDialog(advancePaymentData);
		}
	}
	//for modal starts
	pdcInfoDialog(advancePaymentData): void {
		console.log(advancePaymentData);
		this.listLrDtosPdc = new LRDto();
		this.listLrDtosPdc.chequeNumber = advancePaymentData.advPdcChqNumber;
		this.listLrDtosPdc.chequeAmt = advancePaymentData.advPdcChqAmt;
		this.listLrDtosPdcList = [];
		this.listLrDtosPdcList.push(this.listLrDtosPdc);
		const dialogRef = this.dialog.open(PdcInfoPopupComponent, {
			data: { listOfDataToPass: this.listLrDtosPdcList }
		});
	}

	setConfirmPayment(advancePaymentData) {
		this.selectedHireslipNo = null;
		this.selectedTruckNo = null;
		this.selectedHireBalAmt = null;
		this.viewSelectedBalance = false;
		if (advancePaymentData.hireamtbalsendtobank) {
			swal("Not Allowed", "Payment already completed for this Hireslip!", "info");
		} else {
			this.localStorageOfPaymentData = advancePaymentData;
			this.filledValues(this.localStorageOfPaymentData);
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	closeConfirmPaymentDetailsPopUp() {
		this.modalRefferenceConfirmPaymentPopUp.close();

	}
	clearAll() {
		$("#" + this.pageId + "sourceId").val('ALL');
		$("#" + this.pageId + "destination").val('ALL');
		$("#" + this.pageId + "supplierName").val('All');
		$("#" + this.pageId + "viewBy").val('supplierWise');
		$("#" + this.pageId + "paymentMethod").val('all');
		$("#" + this.pageId + "searchBy").val('hireslipDateWise');

		this.showSpinnerForAction = false;
		this.selectedFromDate = '';
		this.selectedToDate = '';
		this.selectedSource = '';
		this.selectedDestination = '';
		this.selectedSupplierName = '';
		this.selectedViewBy = '';
		this.selectedPaymentMethod = '';
		this.selectedSearchBy = '';
		this.hireslipDtoForSearch = new HireSlipDto();
		this.listLrDtosChq = new LRDto();
		this.listLrDtosChqList = [];
		this.listLrDtosPdc = new LRDto();
		this.listLrDtosPdcList = [];
		$("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
		$("#" + this.pageId + "advancePaymentTableIdDestWise").DataTable().destroy();
		this.advancePaymentDataList = [];
		this.bankPaymentDetailsList = [];
		this.dtTriggerAdvancePayment.next();
		this.dtTriggerBankPaymentDetails.next();
		this.selectTodayDate();
		this.viewPaymentVoucher = false;
		this.panNumberUpload = '';
		this.truckNumberUpload = '';
		this.splitedUrl = [];
		this.hireslipDtoForFinYear = new HireSlipDto();
		this.financialYear = '';
		this.fileName = '';
		this.truckDataDtoForRemoveImg = new TruckDataDto();
		this.viewConfirmPaymentPopUp = false;
		this.hirePrintDto = new HireSlipDto();
		this.valueMin = 0.0;
		this.valueMax = 0.0;
		this.isUploaded = false;
	}


	setPrintVoucher(advancePaymentData) {
		this.hirePrintDto = new HireSlipDto();
		this.hirePrintDto.fromStation = advancePaymentData.fromStation;
		this.hirePrintDto.toStation = advancePaymentData.toStation;
		this.hirePrintDto.vehicleNumber = advancePaymentData.vehicleNumber;
		this.hirePrintDto.hireslipnumber = advancePaymentData.hireslipnumber;
		this.hirePrintDto.hireslipdate = advancePaymentData.hireslipdate;
		this.hirePrintDto.suppliername = advancePaymentData.supplierName;
		this.hirePrintDto.tdsAmount = advancePaymentData.tdsAmount;
		this.hirePrintDto.tdsPercent = advancePaymentData.tdsPercent;
		this.hirePrintDto.totalhire = advancePaymentData.totalhire;
		this.hirePrintDto.advance = advancePaymentData.advance;
		this.hirePrintDto.paid = advancePaymentData.advTotalPaid;
		this.hirePrintDto.paymentDate = advancePaymentData.paymentDate;
		this.hirePrintDto.advCashAmt = advancePaymentData.cashAmt;
		this.hirePrintDto.advTotalChqAmt = advancePaymentData.advTotalChqAmt;
		this.hirePrintDto.advTotalPdcAmt = advancePaymentData.advTotalPdcAmt;
		this.hirePrintDto.chequeno = advancePaymentData.advChqNumber;
		this.hirePrintDto.column1 = advancePaymentData.advPdcChqNumber;
		this.hirePrintDto.advChqBankName = advancePaymentData.advChqBankName;
		this.hirePrintDto.advPdcChqBankName = advancePaymentData.advPdcChqBankName;
		this.hirePrintDto.advChequeAmt = advancePaymentData.advTotalChqAmt;
		this.hirePrintDto.cashAmt = advancePaymentData.cashAmt;
		this.viewPaymentVoucher = false;
		console.log(this.hirePrintDto);
		this.printVocher();

	}
	printVocher() {
		swal({
			title: "Payment Print",
			text: "Sure U Want To Print",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((paymentPrint) => {
			if (paymentPrint) {
				this.hirePrintDto.size = 1;
				console.log('total value : ' + this.hirePrintDto.cashAmt + " - " + this.hirePrintDto.advTotalChqAmt + " - " + this.hirePrintDto.advTotalPdcAmt);
				if (this.hirePrintDto.cashAmt > 0) {
					console.log('cash');
					this.cashPrint(this.hirePrintDto);
				} else if (this.hirePrintDto.advTotalChqAmt > 0) {
					console.log('cheque');
					this.chequePrint();
				} else if (this.hirePrintDto.advTotalPdcAmt > 0) {
					console.log('pdc');
					this.pdcPrint();
				}
			}
		});
	}

	cashPrint(hirePrintDto) {
		swal({
			title: "Payment Confirmation",
			text: "Sure U Want To Print the Cash Voucher",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((cash) => {
			if (cash) {
				console.log('paymentCalculation 1 :' + this.hirePrintDto.cashAmt);
				if (this.hirePrintDto.cashAmt > 20000) {
					console.log('paymentCalculation 2');
					this.paymentCalculation(this.hirePrintDto.cashAmt);
					this.hirePrintDto.paid = this.hirePrintDtoPrintVoucher.paid;
					this.hirePrintDto.size = this.hirePrintDtoPrintVoucher.size;
				} else {
					this.hirePrintDto.paid = hirePrintDto.cashAmt;
				}

				this.hirePrintDto.mode = 'cash';
				localStorage.clear();
				console.log(this.hirePrintDto);
				localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
				this.viewPaymentVoucher = true;
				window.addEventListener('afterprint', (onclick) => {
					console.log('chekc');
					if (this.viewPaymentVoucher) {
						this.viewPaymentVoucher = false;
						if (this.hirePrintDto.advTotalChqAmt > 0) {
							this.chequePrint();
						}
					}
				});
			} else {
				if (this.hirePrintDto.advTotalChqAmt > 0) {
					this.chequePrint();
				}
			}
			this.changeDetectorRef.detectChanges();
		});
	}

	paymentCalculation(sum) {
		console.log('paymentCalculation 3');
		var lastValue = 20001, firstValve = 0, iterValve = 1;
		var finalValueMin = 0;
		var resultValue = 0.0, finalValue = 0.0, maxIterValue = 0.0;
		var finalResult = null;
		for (let i = 2; i < 20; i++) {
			let z = i * 20000;
			firstValve = lastValue;
			lastValue = z;
			maxIterValue = ++iterValve;
			if ((sum >= firstValve) && (sum <= lastValue)) {
				resultValue = maxIterValue;
				break;
			}
			lastValue = lastValue + 1;
		}
		console.log("sum - " + sum + "resultValue" + resultValue);
		finalValue = sum / resultValue;
		console.log("finalValue - " + finalValue);
		finalValueMin = Math.round(finalValue);
		finalResult = finalValueMin + "";
		this.valueMin = finalResult;

		this.hirePrintDtoPrintVoucher = new HireSlipDto();
		this.hirePrintDtoPrintVoucher.paid = this.valueMin;
		this.hirePrintDtoPrintVoucher.size = resultValue;
	}

	chequePrint() {
		swal({
			title: "Payment Confirmation",
			text: "Sure U Want To Print the Cheque Voucher",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((cheque) => {
			if (cheque) {
				console.log('inside chq : ' + this.hirePrintDto.advTotalChqAmt)
				this.hirePrintDto.paid = this.hirePrintDto.advTotalChqAmt;
				this.hirePrintDto.mode = 'cheque';
				this.hirePrintDto.size = 1;
				if (this.hirePrintDto.chequeno != null) {
					var chequeNo = this.hirePrintDto.chequeno;
					var chequeNoNew = chequeNo.replace('#', ',');
					this.hirePrintDto.chequeno = chequeNoNew;
				}
				if (this.hirePrintDto.advChqBankName != null) {
					var cheqBankName = this.hirePrintDto.advChqBankName;
					var cheqBankNameNew = cheqBankName.replace('#', ',');
					this.hirePrintDto.advChqBankName = cheqBankNameNew;
				}
				localStorage.clear();
				localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
				this.viewPaymentVoucherChq = true;
				window.addEventListener('afterprint', (onclick) => {
					if (this.viewPaymentVoucherChq) {
						this.viewPaymentVoucherChq = false;
						if (this.hirePrintDto.advTotalPdcAmt > 0) {
							this.pdcPrint();
						}
					}

				});

			} else {
				if (this.hirePrintDto.advTotalPdcAmt > 0) {
					this.pdcPrint();
				}
			}
			this.changeDetectorRef.detectChanges();
		});

	}
	pdcPrint() {
		swal({
			title: "Payment Confirmation",
			text: "Sure U Want To Print the Pdc Voucher",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((pdc) => {
			if (pdc) {
				this.hirePrintDto.paid = this.hirePrintDto.advTotalPdcAmt;
				this.hirePrintDto.mode = 'pdcCheque';
				this.hirePrintDto.size = 1;
				if (this.hirePrintDto.column1 != null) {
					var pdcChequeNo = this.hirePrintDto.column1;
					var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
					this.hirePrintDto.column1 = pdcChequeNoNew;
				}
				if (this.hirePrintDto.advPdcChqBankName != null) {
					var pdcBankName = this.hirePrintDto.advPdcChqBankName;
					var pdcBankNameNew = pdcBankName.replace('#', ',');
					this.hirePrintDto.advChqBankName = pdcBankNameNew;
				}
				localStorage.clear();
				localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
				this.viewPaymentVoucherPdc = true;
				window.addEventListener('afterprint', (onclick) => {
					if (this.viewPaymentVoucherPdc) {
						console.log('chekc');
						this.viewPaymentVoucherPdc = false;
					}
				});
			}
			this.changeDetectorRef.detectChanges();
		});
	}

	paymentVoucher() {
		localStorage.clear();
		console.log(this.hirePrintDto);
		localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
		this.viewPaymentVoucher = true;
		setTimeout(() => {
			this.viewPaymentVoucher = false;
			console.log('not workingdd');
		}, 1000);
		window.addEventListener('afterPopUp', (event) => {
			this.viewPaymentVoucher = false;
			console.log('not working');
		});
	}


	getImg(advancePaymentData, advancePaymentPopUpTemplate) {
		this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
		this.truckNumberUpload = '';
		this.panNumberUpload = '';

		if (advancePaymentData.imgURLPan == null || advancePaymentData.imgURLPan == undefined) {

			this.panNumberUpload = advancePaymentData.panNo;
			if (this.panNumberUpload.toUpperCase() == "AAAAA9999A") {

				swal({
					title: "Warning",
					text: "Please Update Correct Pan Number",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else {
				this.truckNumberUpload = advancePaymentData.vehicleNumber;
				this.fileName = '';
				// fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
				this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
				console.log(this.fileName);
				console.log(this.financialYear);
				//Upload Pop up Pending
				this.modalRefferenceAdvancePaymentPopUp = this.modalService.open(advancePaymentPopUpTemplate,
					{ centered: true });
				this.modalRefferenceAdvancePaymentPopUp.result.then((result) => {
					this.modalRefferenceAdvancePaymentClosePopUp = `Closed with: ${result}`;
				}, reason => {
					this.modalRefferenceAdvancePaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
				});
			}

		} else {
			var imageUrl = advancePaymentData.imgURLPan;
			var newimageUrl = "https://vehicledriverdocs.s3.amazonaws.com/truckownerdeclaration/";
			this.splitedUrl = null;
			this.splitedUrl = imageUrl.split("#");
			if (this.splitedUrl.length > 1) {
				console.log(this.splitedUrl.length);
				for (let i = 0; i < this.splitedUrl.length; i++) {
					console.log(i);
					if (i == 0) {
						console.log('add');
						window.open(newimageUrl + this.splitedUrl[i], "Decl. Document - ", "");
					} else if (i == 1) {
						console.log('add111');
						this.openWindow(newimageUrl, this.splitedUrl[i]);

					}

				}

			} else {
				var completeImg = newimageUrl + imageUrl;
				console.log('ddaaa');

				window.open(completeImg, "Decl. Document", "");
				imageUrl = "";
			}
		}

	}

	openWindow(newimageUrl, image) {
		console.log(image);
		window.open(newimageUrl + image, "Decl. Document - ", "_blank");
	}

	applyAdvancePaymentBtnPopUp() {
		//	this.updateAckRecDetails();
	}

	closeAdvancePaymentBtnPopUp() {
		this.modalRefferenceAdvancePaymentPopUp.close();
		this.isUploaded = false;
		this.financialYear = '';
		this.fileName = '';
		$("#" + this.pageId + "advancePaymentFileUpload").val('');
	}

	onFileChangedAdvancePayment(event) {
		this.selectedAdvancePaymentDetails = event.target.files[0];
		console.log(this.selectedAdvancePaymentDetails);
	}

	uploadAdvancePaymentBtnPopUp() {
		console.log($("#" + this.pageId + "advancePaymentFileUpload").val());
		if ($("#" + this.pageId + "advancePaymentFileUpload").val() != null && $("#" + this.pageId + "advancePaymentFileUpload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			console.log(this.selectedAdvancePaymentDetails.type);
			if ((this.selectedAdvancePaymentDetails.type != null &&
				(this.selectedAdvancePaymentDetails.type == "image/jpeg" ||
					this.selectedAdvancePaymentDetails.type == "image/jpg" ||
					this.selectedAdvancePaymentDetails.type == "image/png" ||
					this.selectedAdvancePaymentDetails.type == "application/pdf"))) {
				var splitTypeAdvancePayment = this.selectedAdvancePaymentDetails.type.split("/");

				// const commonListAdvancePayment = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
				const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&ASRAR&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
				const formData = new FormData();
				formData.append(commonListAdvancePayment, commonListAdvancePayment);
				formData.append('truckownerdeclaration', this.selectedAdvancePaymentDetails, this.selectedAdvancePaymentDetails.name);
				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							console.log(response);
							this.showSpinnerForActionForUpload = false;
							if (response[0] != "Error") {
								swal("File Upload", "File Uploaded Succcessfully", "success");
								this.isUploaded = true;
							} else {
								swal("ERROR", "Correct the File and Upload Again", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}

	}

	getFinancialYearDetails(hireSlipDate) {
		console.log('Hireslipdate : ' + hireSlipDate);
		this.hireslipDtoForFinYear = new HireSlipDto();
		this.hireslipDtoForFinYear.fromDate = hireSlipDate;
		console.log(this.hireslipDtoForFinYear);
		this.getFinancialYearInfo();
	}

	getFinancialYearInfo = () => {

		this.hireslipService.getFinancialYearForUpload(this.hireslipDtoForFinYear).subscribe(
			(response) => {
				this.showSpinnerForAction = false;

				if (response.financialYear == null) {
					swal({
						title: "Warning",
						text: "No Records found for this search!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.financialYear = response.financialYear;
					this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
					console.log('fin year : ' + this.financialYear);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {

				swal("Error", "Server Problem Occurred While getting the Advance Payment details", "info");
			}, () => console.log('done');
	};


	getImgForReUpload(advancePaymentData, advancePaymentPopUpTemplate) {
		this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
		this.truckNumberUpload = '';
		this.panNumberUpload = '';

		if (advancePaymentData.imgURLPan != null && advancePaymentData.imgURLPan != undefined && advancePaymentData.imgURLPan != '') {

			this.panNumberUpload = advancePaymentData.panNo;

			this.truckNumberUpload = advancePaymentData.vehicleNumber;
			this.fileName = '';
			// fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
			this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
			console.log(this.fileName);
			console.log(this.financialYear);
			console.log('aaaa');
			//Upload Pop up Pending
			this.modalRefferenceAdvancePaymentPopUp = this.modalService.open(advancePaymentPopUpTemplate,
				{ centered: true });
			this.modalRefferenceAdvancePaymentPopUp.result.then((result) => {
				this.modalRefferenceAdvancePaymentClosePopUp = `Closed with: ${result}`;
			}, reason => {
				this.modalRefferenceAdvancePaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
			});

		} else {
			swal({
				title: "Alert",
				text: "Pleaase Upload The Image First",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}


	getImgForRemove(advancePaymentData) {
		this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
		if (advancePaymentData.imgURLPan != null && advancePaymentData.imgURLPan != undefined && advancePaymentData.imgURLPan != '') {
			swal({
				title: "Confirm Apply",
				text: "Sure You Want To Remove the Declaration",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((remove) => {
				if (remove) {
					this.removeImgMethod(advancePaymentData);
				}
			});

		} else {

			swal({
				title: "Alert",
				text: "Pleaase Upload The Image First",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}
	removeImgMethod(advancePaymentData) {
		this.panNumberUpload = advancePaymentData.panNo;
		this.truckNumberUpload = advancePaymentData.vehicleNumber;

		this.truckDataDtoForRemoveImg = new TruckDataDto();
		this.truckDataDtoForRemoveImg.mode = 'particularTruck';
		this.truckDataDtoForRemoveImg.panNo = this.panNumberUpload;
		this.truckDataDtoForRemoveImg.truckNumber = this.truckNumberUpload;
		this.truckDataDtoForRemoveImg.companyId = this.userDataDtoReturnSession.companyId;
		this.truckDataDtoForRemoveImg.financialYear = this.financialYear;
		console.log(this.truckDataDtoForRemoveImg);
		this.removeImgDetails();
	}

	removeImgDetails = () => {

		this.masterReadService.setTruckOwnerDetails(this.truckDataDtoForRemoveImg).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				this.searchMethodDetails();
				this.changeDetectorRef.detectChanges();
			}), (error) => {

				swal("Error", "Server Error While setTruckOwnerDetails", "info");
			}, () => console.log('done');
	};

	clearTable() {
		$("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
		this.dtTriggerAdvancePayment.next();
		$("#" + this.pageId + "bankPaymentDetailsTableId").DataTable().destroy();
		this.dtTriggerBankPaymentDetails.next();
	}


	viewByMode(viewBy: string) {
		if (viewBy === 'supplierWise') {
			this.showForSupplierWise = true;
			this.showForDestWise = false;
			this.clearTable();

		} else if (viewBy === 'destinationWise') {
			this.showForSupplierWise = false;
			this.showForDestWise = true;
			this.clearTable();

		} else {
			this.showForSupplierWise = false;
			this.showForDestWise = false;
			this.clearTable();
		}
	}

	// clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
	// 	this.modelBankName = e.item;
	// 	console.log('clickListnerForBankName');
	// 	console.log(this.modelBankName.id);
	// 	this.showBankAcDetails = true;
	// 	this.selectedTruckBankAcId = this.modelBankName.id;
	// 	this.selectedAcNo = this.modelBankName.acNumber;
	// 	this.selectedAcName = this.modelBankName.acHolder;
	// 	this.selectedBankName = this.modelBankName.bankName;
	// 	this.selectedIfsc = this.modelBankName.ifscCode;
	// 	this.selectedBranch = this.modelBankName.branchName;
	// 	$("#" + this.pageId + "bankNameId").val(this.modelBankName.bankName);
	// 	$("#" + this.pageId + "bankNameId").focusout();
	// 	$("#" + this.pageId + "" + this.pageId + "cashChequePdcAmount").focus();
	// }

	transferModeListner(e: NgbTypeaheadSelectItemEvent) {
		console.log($("#" + this.pageId + "transferMode").val());
		this.selectedTrnxTypeTemp = $("#" + this.pageId + "transferMode").val();
		this.selectedTrnxType = this.selectedTrnxTypeTemp;
	}

	resetBankFields() {
		this.modelBankName = null;
		this.selectedTruckBankAcId = null;
		this.selectedAcNo = null;
		this.selectedAcType = null;
		this.selectedAcName = null;
		this.selectedBankName = null;
		this.selectedIfsc = null;
		this.selectedBranch = null;
		$("#" + this.pageId + "bankNameId").val('');
		this.showBankAcDetails = false;
		this.bankNameOptions = [];
		this.bankNameTA = [];
	}

	getDetailsForBankMasterRead(truckNumber) {
		this.truckDataDto = new TruckDataDto();
		this.truckDataDto.truckNumber = truckNumber;
		this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
		this.getBankDetailsList();
	}

	getBankDetailsList = () => {
		this.showSpinnerForAction = true;
		this.bankNameOptions = [];
		this.bankNameTA = [];
		this.showBankAcDetails = false;
		this.masterService.getTruckBankDetails(this.truckDataDto).subscribe(
			(response) => {
				if (response) {
					if (response.length > 0) {
						this.bankNameOptions = response;
						for (let i = 0; i < this.bankNameOptions.length; i++) {
							this.bankNameTA.push(this.bankNameOptions[i]);
						}
						this.showSpinnerForAction = false;
					} else {
						this.showSpinnerForAction = false;
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting the Bank Details", "error");
			},
			() => console.log('done');
	}

	payemntModeTypeListner(modeType: string) {
		if (modeType == "Cash") {
			this.viewCashChequePdcAmtField = true;
			this.viewChequePdcNoField = false;
			this.viewChequeDueDateField = false;
			this.viewChequePdcBankNameField = false;
			$("#" + this.pageId + "chequePdcNo").val('');
			$("#" + this.pageId + "chequeDueDates").val('');
			$("#" + this.pageId + "bankNameId").val('');
			$("#" + this.pageId + "cashChequePdcAmount").val('');
			$("#" + this.pageId + "cashChequePdcAmount").focus();
			this.modelChequeDueDates = null;
			this.modelBankName = null;
			this.showBankAcDetails = false;
		} else if (modeType == "Bank") {
			this.viewCashChequePdcAmtField = true;
			this.viewChequePdcNoField = true;
			this.viewChequeDueDateField = false;
			this.viewChequePdcBankNameField = true;
			$("#" + this.pageId + "chequePdcNo").focus();
			$("#" + this.pageId + "chequePdcNo").val('');
			$("#" + this.pageId + "chequeDueDates").val('');
			$("#" + this.pageId + "bankNameId").val('');
			$("#" + this.pageId + "cashChequePdcAmount").val('');
			this.modelChequeDueDates = null;
			this.modelBankName = null;
			this.showBankAcDetails = true;
		}
	}

	filledValues(advancePaymentData) {
		($("#" + this.pageId + "hireslipNumberPayment").val(advancePaymentData.hireslipnumber));
		this.selectedHireslipNo = advancePaymentData.hireslipnumber;
		this.selectedTruckNo = advancePaymentData.vehicleNumber;
		if (this.srcPaymentOption) {
			this.selectedHireBalAmt = advancePaymentData.advance;
			($("#" + this.pageId + "amount").val(advancePaymentData.advance));
		} else if (this.destPaymentOption) {
			this.selectedHireBalAmt = advancePaymentData.balance;
			($("#" + this.pageId + "amount").val(advancePaymentData.balance));
		}
		this.viewSelectedBalance = true;
		this.resetBankFields();
		this.getDetailsForBankMasterRead(advancePaymentData.vehicleNumber);
		this.showSelectedTruckBankDetails(advancePaymentData);
	}

	getTruckBankDetailsForPayment(truckNumber) {

	}

	calculateCashAmtSum() {
		var cashSum = 0.0;
		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
				cashSum = cashSum + +this.advancePaymentChequePdcDataList[i].amount;
			}
		}
		return cashSum;
	}

	calculateChequeAmtSum() {
		var chequeSum = 0.0;
		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "Bank") {
				chequeSum = chequeSum + +this.advancePaymentChequePdcDataList[i].amount;
			}
		}
		return chequeSum;
	}

	calculatePdcAmtSum() {
		var pdcSum = 0.0;
		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
				pdcSum = pdcSum + +this.advancePaymentChequePdcDataList[i].amount;
			}
		}
		return pdcSum;
	}

	onKeyPressListnerForAmount(event) {
		if (event.charCode == 13) {
			this.addBankDetailsInTable();
		}
	}

	addBankDetailsInTable() {
		if (this.selectedHireslipNo == null || this.selectedHireslipNo == "" || this.selectedHireslipNo == undefined) {
			swal("Not Allowed", "Please select the Hireslip details to proceed!", "warning");
			return false;
		} else if (($("#" + this.pageId + "payemntModeType").val() == "Bank") && (this.selectedAcNo == null || this.selectedAcNo == "" || this.selectedAcNo == undefined)) {
			swal("Not Allowed", "No account details found to proceed!", "warning");
			return false;
		} else if (($("#" + this.pageId + "payemntModeType").val() == "Cash") &&
			($("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
				$("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
			swal("Mandatory Field", "Please enter amount", "warning");
			return false;
		} else if (($("#" + this.pageId + "payemntModeType").val() == "Bank") &&
			(this.selectedAcType == null || this.selectedAcType == undefined || this.selectedAcType == "" ||
				$("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined || $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
			swal("Mandatory Field", "Please enter the mandatory field", "warning");
			return false;
		} else {
			this.addIntoTableOfBankDetails();
		}
	}

	addIntoTableOfBankDetails() {
		this.newAttributeTosetBank = {};
		this.newAttributeTosetBank.paymentType = $("#" + this.pageId + "payemntModeType").val();
		this.newAttributeTosetBank.chequeNumber = $("#" + this.pageId + "chequePdcNo").val();
		this.newAttributeTosetBank.amount = $("#" + this.pageId + "cashChequePdcAmount").val();
		this.newAttributeTosetBank.bankName = this.selectedAcType;
		this.newAttributeTosetBank.pdcDueDate = $("#" + this.pageId + "chequeDueDates").val();
		//this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
		if (this.editBankTableDetailsId != null &&
			this.editBankTableDetailsId != undefined) {
			this.advancePaymentChequePdcDataList.splice(this.editBankTableDetailsId, 1);
		}
		if (this.advancePaymentChequePdcDataList == null ||
			this.advancePaymentChequePdcDataList == undefined ||
			this.advancePaymentChequePdcDataList.length == 0) {
			this.advancePaymentChequePdcDataList = [];
		}
		if (this.advancePaymentChequePdcDataList.length == 0) {
			this.advancePaymentChequePdcDataList = [];
			$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
			this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
			this.dtTriggerAdvancePaymentCashChequePdc.next();
			this.clearFieldBank();
		} else {
			if ($("#" + this.pageId + "payemntModeType").val() == "Cash") {
				const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
					bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
				if (result.length > 0) {
					swal("Duplicate Entry", "Cash amount is already available, please edit it if you want to update.", "warning");
				} else {
					$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
					this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
					this.dtTriggerAdvancePaymentCashChequePdc.next();
					this.clearFieldBank();
				}
			} else if ($("#" + this.pageId + "payemntModeType").val() == "Bank") {
				const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
					bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
				if (result.length > 0) {
					swal("Duplicate Entry", "Bank amount is already available, please edit it if you want to update.", "warning");
				} else {
					$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
					this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
					this.dtTriggerAdvancePaymentCashChequePdc.next();
					this.clearFieldBank();
				}
			}
		}
	}

	rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData, index) {
		this.editBankTableDetailsId = index;
		console.log("rowSelectedBankDataOnEdit");
		console.log(bkgAgentStmtCashChequePdcData);
		if (bkgAgentStmtCashChequePdcData.paymentType == "Cash") {
			this.viewCashChequePdcAmtField = true;
			this.viewChequePdcNoField = false;
			this.viewChequeDueDateField = false;
			this.viewChequePdcBankNameField = false;
			setTimeout(() => {
				$("#" + this.pageId + "payemntModeType").val("Cash");
				$("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
			}, 1000);
		} else if (bkgAgentStmtCashChequePdcData.paymentType == "Bank") {
			this.viewCashChequePdcAmtField = true;
			this.viewChequePdcNoField = true;
			this.viewChequeDueDateField = false;
			this.viewChequePdcBankNameField = true;
			setTimeout(() => {
				$("#" + this.pageId + "payemntModeType").val("Bank");
				$("#" + this.pageId + "chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
				$("#" + this.pageId + "bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
				$("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
			}, 1000);
		}
	}

	deleteSelectedBankDetails(index) {
		$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
		this.advancePaymentChequePdcDataList.splice(index, 1);
		this.dtTriggerAdvancePaymentCashChequePdc.next();
	}


	clearFieldBank() {
		this.editBankTableDetailsId = null;
		this.newAttributeTosetBank = {};
		$("#" + this.pageId + "chequePdcNo").val('');
		$("#" + this.pageId + "chequeDueDates").val('');
		$("#" + this.pageId + "bankNameId").val('');
		$("#" + this.pageId + "cashChequePdcAmount").val('');

		this.modelChequeDueDates = null;
		this.modelBankName = null;
	}

	clearBankDetailsInTable() {
		this.clearFieldBank();
		$("#" + this.pageId + "payemntModeType").val('Bank');
		this.viewCashChequePdcAmtField = true;
		this.viewChequePdcNoField = true;
		this.viewChequeDueDateField = false;
		this.viewChequePdcBankNameField = true;
	}

	getUserValues() {
		this.hireslipDtoForUserVal = new HireSlipDto();
		this.validateBalAmtForSave = 0.0;
		this.validateSummCashAmtForSave = 0.0;
		this.validateSummChequeAmtForSave = 0.0;
		this.validateSummPdcAmtForSave = 0.0;
		this.validatePayingDateForSave = '';
		this.validateInitBalAmtForSave = 0.0;

		this.validateBalAmtForSave = $("#" + this.pageId + "balanceToBePaid").val();
		this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
		this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
		this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();
		this.validatePayingDateForSave = $("#" + this.pageId + "payingDates").val();
		this.validateInitBalAmtForSave = $("#" + this.pageId + "amount").val();

		this.hireslipDtoForUserVal = this.setMultipleValue();
		this.hireslipDtoForUserVal.lastupdatedby = this.userDataDtoReturnSession.userId;
		this.hireslipDtoForUserVal.advHireAmtPaidDate = this.validatePayingDateForSave;
		this.hireslipDtoForUserVal.cashAmt = this.validateSummCashAmtForSave;
		this.hireslipDtoForUserVal.advTotalChqAmt = this.validateSummChequeAmtForSave;
		this.hireslipDtoForUserVal.advTotalPdcAmt = this.validateSummPdcAmtForSave;
		this.hireslipDtoForUserVal.totalhire = this.localStorageOfPaymentData.totalHire;
		this.hireslipDtoForUserVal.tdsAmount = this.localStorageOfPaymentData.tdsAmount;

		var totAmtPaid = 0.0;
		totAmtPaid = +(this.hireslipDtoForUserVal.cashAmt = null ? 0.0 : this.hireslipDtoForUserVal.cashAmt) +
			+(this.hireslipDtoForUserVal.advTotalChqAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalChqAmt) +
			+(this.hireslipDtoForUserVal.advTotalPdcAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalPdcAmt) + +(this.hireslipDtoForUserVal.tdsAmount == null ? 0.0 : this.hireslipDtoForUserVal.tdsAmount);

		this.hireslipDtoForUserVal.advTotalPaid = totAmtPaid;
		this.hireslipDtoForUserVal.hireslipnumber = this.localStorageOfPaymentData.hireslipnumber;
		return this.hireslipDtoForUserVal;
	}

	setMultipleValue() {
		this.hireslipDtoForMulVal = new HireSlipDto();
		var cashAmtMultiple = null;
		var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
		var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;
		var chequeNames = "NA", pdcChequeNames = "NA";

		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "Bank") {
				chqNumMultiple = chqNumMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].chequeNumber;
				chqAmtMultiple = chqAmtMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].amount;
				cheqBankNameMultiple = cheqBankNameMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].bankName;

				if (i == 0) {
					chequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
				} else {
					chequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
				}
			}
		}

		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
				pdcNumMultiple = pdcNumMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].chequeNumber;
				pdcAmtMultiple = pdcAmtMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].amount;
				pdcDueDateMultiple = pdcDueDateMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].pdcDueDate;
				pdcBankNameMultiple = pdcBankNameMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].bankName;
				if (i == 0) {
					pdcChequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
				} else {
					pdcChequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
				}
			}
		}

		for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
			if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
				cashAmtMultiple = cashAmtMultiple + "#"
					+ this.advancePaymentChequePdcDataList[i].amount;
			}
		}

		if (chqNumMultiple != null) {
			chqNumMultiple = chqNumMultiple.replace("null#", "");
		}
		if (chqAmtMultiple != null) {
			chqAmtMultiple = chqAmtMultiple.replace("null#", "");
		}
		if (pdcNumMultiple != null) {
			pdcNumMultiple = pdcNumMultiple.replace("null#", "");
		}
		if (pdcAmtMultiple != null) {
			pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
		}
		if (pdcDueDateMultiple != null) {
			pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
		}
		if (pdcBankNameMultiple != null) {
			pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
		}
		if (cheqBankNameMultiple != null) {
			cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
		}

		if (cashAmtMultiple != null) {
			cashAmtMultiple = cashAmtMultiple.replace("null#", "");
		}
		this.hireslipDtoForMulVal.advChqNumber = chqNumMultiple;
		this.hireslipDtoForMulVal.advChqAmt = chqAmtMultiple;
		this.hireslipDtoForMulVal.advChqBankName = cheqBankNameMultiple;
		this.hireslipDtoForMulVal.advPdcChqNumber = pdcNumMultiple;
		this.hireslipDtoForMulVal.advPdcChqAmt = pdcAmtMultiple;
		this.hireslipDtoForMulVal.advPdcChqDueDate = pdcDueDateMultiple;
		this.hireslipDtoForMulVal.advPdcChqBankName = pdcBankNameMultiple;

		this.hirePrintDto.chequeno = chequeNames;
		this.hirePrintDto.column1 = pdcChequeNames;
		this.hirePrintDto.advChqBankName = cheqBankNameMultiple;
		this.hirePrintDto.advPdcChqBankName = pdcBankNameMultiple;
		return this.hireslipDtoForMulVal;
	}

	clearOverAllField() {
		$("#" + this.pageId + "payemntModeType").val('Bank');
		this.viewCashChequePdcAmtField = true;
		this.viewChequePdcNoField = true;
		this.viewChequeDueDateField = false;
		this.viewChequePdcBankNameField = true;
		this.clearFieldBank();

		this.modelChequeDueDates = null;
		this.modelPayingDates = null;

		this.localStorageOfPaymentData = null;

		this.newAttributeTosetBank = {};
		this.editBankTableDetailsId = null;

		this.validateBalAmtToBePaid = null;
		this.validateBalAmtCash = null;
		this.validateBalAmtCheque = null;
		this.validateBalAmtPdc = null;

		this.validateBalAmtForSave = null;
		this.validateSummCashAmtForSave = null;
		this.validateSummChequeAmtForSave = null;
		this.validateSummPdcAmtForSave = null;
		this.validatePayingDateForSave = null;
		this.validateInitBalAmtForSave = null;
		this.resultDuplicate = null;

		this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
		this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
		this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

		$("#" + this.pageId + "totalAmountToBePaid").val('');
		$("#" + this.pageId + "balanceToBePaid").val('');
		$("#" + this.pageId + "summaryCashAmt").val('');
		$("#" + this.pageId + "summaryChequeAmt").val('');
		$("#" + this.pageId + "summaryPDCAmt").val('');
		$("#" + this.pageId + "payingDates").val('');
		$("#" + this.pageId + "destinationId").val('');
		$("#" + this.pageId + "tdsDeducted").val('');
		$("#" + this.pageId + "amount").val('');
		$("#" + this.pageId + "advancePayable").val('')

		this.advancePaymentChequePdcDataList = [];
		$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
		this.dtTriggerAdvancePaymentCashChequePdc.next();
		this.Statement = '';
	}
	chqDueDateFocus(e) {
		if (e.keyCode == 13) {
			this.focusChqDueDateCalendar();
		}
	}
	focusChqDueDateCalendar() {
		window.setTimeout(function () {
			$("#" + this.pageId + "bankNameId").focus();
		}, 100);

	}

	confirmPayment() {
		console.log('ddaccccc');
		this.validateBalAmtForSave = 0.0;
		this.validateSummCashAmtForSave = 0.0;
		this.validateSummChequeAmtForSave = 0.0;
		this.validateSummPdcAmtForSave = 0.0;
		this.validateBalAmtForSave = $("#" + this.pageId + "amount").val();
		this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
		this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
		this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();

		var overAllTotalAmt = (this.validateSummCashAmtForSave == null ? 0.0 : parseInt(this.validateSummCashAmtForSave)) + +(this.validateSummChequeAmtForSave == null ? 0.0 : parseInt(this.validateSummChequeAmtForSave)) + +(this.validateSummPdcAmtForSave == null ? 0.0 : parseInt(this.validateSummPdcAmtForSave));
		var balnceToBePaidAmt = (this.validateBalAmtForSave == null ? 0.0 : parseInt(this.validateBalAmtForSave)) + -(overAllTotalAmt == null ? 0.0 : overAllTotalAmt);

		this.hirePrintDto.advChequeAmt = this.validateSummChequeAmtForSave;
		this.hirePrintDto.advCashAmt = this.validateSummCashAmtForSave;
		console.log(balnceToBePaidAmt);
		console.log(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave, overAllTotalAmt, balnceToBePaidAmt);
		console.log(this.hirePrintDto);
		if ((overAllTotalAmt > 0.0) && !(balnceToBePaidAmt < 0.0)) {
			swal({
				title: "Payment Confirmation",
				text: "Sure U Want To Submit the Amount Details",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((payment) => {
				if (payment) {
					this.hireslipDtoForSave = new HireSlipDto();
					this.hireslipDtoForSave = this.getUserValues();
					if (this.hireslipDtoForSave.advTotalPaid > this.localStorageOfPaymentData.totalHire) {
						swal("Not Allowed", "Total Paid Should not be Greater than Paid Amount", "warning");
						return false;
					} else {
						this.hirePrintDto.paid = this.hireslipDtoForSave.advTotalPaid;
						this.hirePrintDto.cashAmt = this.validateSummCashAmtForSave;
						this.hirePrintDto.advTotalChqAmt = this.validateSummChequeAmtForSave;
						this.hirePrintDto.advTotalPdcAmt = this.validateSummPdcAmtForSave;
						this.saveLryAdvance(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave);

					}
				}
			});
		} else if (balnceToBePaidAmt < 0.0) {
			swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Extra", "warning");
			return false;
		} else if (overAllTotalAmt == 0.0) {
			swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Zero", "warning");
			return false;
		}
	}

	saveLryAdvance(cashTotAmt, chequeTotAmt, pdcTotAmt) {
		console.log(this.hireslipDtoForSave);
		this.showSpinnerForAction = true;
		this.hireslipService.lryHireAdvPayment(this.hireslipDtoForSave).subscribe(
			(response) => {

				console.log(response);
				this.showSpinnerForAction = false;
				this.validatePayingDateForSave = $("#" + this.pageId + "payingDates").val();
				if (response.status == 'Success') {
					this.hirePrintDto.paymentDate = this.validatePayingDateForSave;
					this.searchMethodDetails();
					this.clearOverAllField();
					if (this.hirePrintDto.cashAmt > 0) {
						console.log('cash');
						this.cashPrint(this.hirePrintDto);
					} else if (this.hirePrintDto.advTotalChqAmt > 0) {
						console.log('cheque');
						this.chequePrint();
					} else if (this.hirePrintDto.advTotalPdcAmt > 0) {
						console.log('pdc');
						this.pdcPrint();
					}
				} else if (response.status == 'Not Found') {
					swal("Error", "Hireslip number not Found", "info");
				} else if (response.status == 'Failed') {
					swal("Error", "Server Problem Occurred While Saving The Details", "info");
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Saving The Details", "info");
			},
			() => console.log('done');
	}
	validateTDSPaymentBtn() {
		localStorage.clear();
		console.log(this.hirePrintDto);
		localStorage.setItem('tdsVoucherPrint', JSON.stringify(this.localStorageOfPaymentData));
		this.viewTdsVoucher = true;
		window.addEventListener('afterprint', (onclick) => {
			console.log('chekc');
			this.viewTdsVoucher = false;
		});
	}

	addIntoFinalTableDetails() {
		if (this.advancePaymentChequePdcDataList.length == 0) {
			swal("No Data", "No payment details found to proceed, please verify!", "warning");
		} else {
			const result = this.bankPaymentDetailsList.filter(bankPaymentDetailsData =>
				bankPaymentDetailsData.hireslipnumber === this.selectedHireslipNo);
			if (result.length > 0) {
				swal("Duplicate Entry", "Payment details already entered for this Hireslip, please verify it.", "warning");
			} else {
				this.totBankAmt = this.totCashAmt = this.totBankCashPaymentAmt = 0;
				for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
					if (this.advancePaymentChequePdcDataList[i].paymentType == 'Bank') {
						this.totBankAmt = this.advancePaymentChequePdcDataList[i].amount;
					} else if (this.advancePaymentChequePdcDataList[i].paymentType == 'Cash') {
						this.totCashAmt = this.advancePaymentChequePdcDataList[i].amount;
					}
				}
				this.totBankCashPaymentAmt = +this.totBankAmt + +this.totCashAmt;
				console.log('this.totBankCashPaymentAmt');
				console.log(this.totBankCashPaymentAmt);
				//full cash possible as per Musaddik bh hence removed this validation @27/12/2022
				// if (this.totBankAmt == 0) {
				// 	swal("Not Allowed", "No Bank payment found, Bank payment is must to proceed, please verify it!", "warning");
				// } else 
				if ((this.totBankCashPaymentAmt < this.selectedHireBalAmt) || (this.totBankCashPaymentAmt > this.selectedHireBalAmt)) {
					swal("Not Allowed", "Total payment amount must be equal to Hire balance amount, please verify it!", "warning");
				} else {
					this.newAttributeToBankFinal = {};
					this.newAttributeToBankFinal.trnxType = this.selectedTrnxType;
					this.newAttributeToBankFinal.benfCode = 'M125';
					this.newAttributeToBankFinal.truckBankAcId = this.selectedTruckBankAcId;
					this.newAttributeToBankFinal.acNo = this.selectedAcNo;
					this.newAttributeToBankFinal.benfName = this.selectedAcName;
					this.newAttributeToBankFinal.debitStmtNarrt = this.selectedHireslipNo;
					this.newAttributeToBankFinal.trnxDate = $("#" + this.pageId + "payingDates").val();
					this.newAttributeToBankFinal.ifscCode = this.selectedIfsc;
					this.newAttributeToBankFinal.benfEmail = this.userDataDtoReturnSession.cmpEmailId;
					this.newAttributeToBankFinal.hireslipnumber = this.selectedHireslipNo;
					this.newAttributeToBankFinal.trnxBankAmt = this.totBankAmt;
					this.newAttributeToBankFinal.trnxCashAmt = this.totCashAmt;

					if (this.selectedTrnxType == 'RTGS') {
						this.newAttributeToBankFinal.trnxTypeCode = 'R';
					} else if (this.selectedTrnxType == 'NEFT') {
						this.newAttributeToBankFinal.trnxTypeCode = 'N';
					} else if (this.selectedTrnxType == 'SELF') {
						this.newAttributeToBankFinal.trnxTypeCode = 'I';
					}

					if (this.editFinalBankTableDetailsId != null &&
						this.editFinalBankTableDetailsId != undefined) {
						this.bankPaymentDetailsList.splice(this.editFinalBankTableDetailsId, 1);
					}
					if (this.bankPaymentDetailsList == null ||
						this.bankPaymentDetailsList == undefined ||
						this.bankPaymentDetailsList.length == 0) {
						this.bankPaymentDetailsList = [];
					}
					$("#" + this.pageId + "bankPaymentDetailsTableId").DataTable().destroy();
					this.bankPaymentDetailsList.push(this.newAttributeToBankFinal);
					this.dtTriggerBankPaymentDetails.next();
					this.clearPaymentFields();
					// console.log('checking for length zero');
					// $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
					// $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
					// $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
					// this.balanceAmt();
				}
			}
		}
	}

	clearPaymentFields() {
		this.resetBankFields();
		$("#" + this.pageId + "cashChequePdcAmount").val('');
		$("#" + this.pageId + "bankNameId").val('');
		$("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
		this.dtTriggerAdvancePaymentCashChequePdc.next();
		this.advancePaymentChequePdcDataList = [];
		this.selectedHireslipNo = null;
		this.selectedTruckNo = null;
		this.selectedHireBalAmt = null;
		this.viewSelectedBalance = false;
		// this.selectedTrnxType = "RTGS";
	}

	confirmBankSubmit() {
		if (this.bankPaymentDetailsList.length == 0) {
			swal("Not Allowed", "No Payment details found, please enter the details to proceed!", "warning");
		} else {
			swal({
				title: "Confirm Submit",
				text: "Sure you want to Confirm the details for payment?",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.hireslipDtosPayment = [];
					for (let i = 0; i < this.bankPaymentDetailsList.length; i++) {
						this.hireslipDtoPayment = new HireSlipDto();
						this.hireslipDtoPayment.hireslipnumber = this.bankPaymentDetailsList[i].hireslipnumber;
						this.hireslipDtoPayment.enteredby = this.userDataDtoReturnSession.userName;
						this.hireslipDtoPayment.trnxType = this.bankPaymentDetailsList[i].trnxType;
						this.hireslipDtoPayment.trnxTypeCode = this.bankPaymentDetailsList[i].trnxTypeCode;
						this.hireslipDtoPayment.truckBankAcId = this.bankPaymentDetailsList[i].truckBankAcId;
						this.hireslipDtoPayment.trnxBankAmt = this.bankPaymentDetailsList[i].trnxBankAmt;
						this.hireslipDtoPayment.trnxCashAmt = this.bankPaymentDetailsList[i].trnxCashAmt;
						this.hireslipDtoPayment.debitStmtNarrt = this.bankPaymentDetailsList[i].debitStmtNarrt;
						this.hireslipDtoPayment.trnxDate = this.bankPaymentDetailsList[i].trnxDate;
						this.hireslipDtoPayment.benfEmail = this.bankPaymentDetailsList[i].benfEmail;
						this.hireslipDtoPayment.benfCode = this.bankPaymentDetailsList[i].benfCode;
						if (this.srcPaymentOption) {
							this.hireslipDtoPayment.reportMode = 'Advance';
						} else if (this.destPaymentOption) {
							this.hireslipDtoPayment.reportMode = 'Balance';
						}
						this.hireslipDtosPayment.push(this.hireslipDtoPayment);
					}
					this.confirmSubmitMethod();
				}
			});
		}
	}

	confirmSubmitMethod() {
		this.showSpinnerForAction = true;
		this.hireslipService.updateSendToBankHirAmountApi(this.hireslipDtosPayment).subscribe(
			(response) => {
				this.hireslipDtoPaymentRet = new HireSlipDto();
				this.hireslipDtoPaymentRet = response;
				if (response.status == 'Success') {
					swal("Success", "Details updated for payment", "info");
					this.clearConfirmPaymentFields();
					this.searchMethod();
				} else if (response.status == 'Failed') {
					swal("Failed", "Problem occurred while updating the details", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the details", "error");
			}, () => console.log('done');
	}

	clearConfirmPaymentFields() {
		$("#" + this.pageId + "bankPaymentDetailsTableId").DataTable().destroy();
		this.dtTriggerBankPaymentDetails.next();
		this.bankPaymentDetailsList = [];
	}

	viewBankAcProofDoc(advancePaymentData) {
		if (advancePaymentData.hireamtbalbankacproofcopy == null || advancePaymentData.hireamtbalbankacproofcopy == undefined || advancePaymentData.hireamtbalbankacproofcopy == 'NA') {
		} else {
			window.open(advancePaymentData.hireamtbalbankacproofcopy, '_blank');
		}
	}

	showSelectedTruckBankDetails(advancePaymentData) {
		console.log('showSelectedTruckBankDetails');
		console.log(advancePaymentData.truckBankAcId);
		this.showBankAcDetails = true;
		this.selectedTruckBankAcId = advancePaymentData.truckBankAcId;
		this.selectedAcNo = advancePaymentData.acNumber;
		this.selectedAcType = advancePaymentData.acType;
		this.selectedAcName = advancePaymentData.acHolder;
		this.selectedBankName = advancePaymentData.bankname;
		this.selectedIfsc = advancePaymentData.ifscCode;
		this.selectedBranch = advancePaymentData.branchName;

		setTimeout(() => {
			$("#" + this.pageId + "bankNameId").val(advancePaymentData.acType);
		}, 1000);
	}

	rejectPaymentRequest(paymentRequestRejectPopup, advancePaymentData) {
		this.selectedHireslipNumberForReject = null;
		this.selectedHireslipNumberForReject = advancePaymentData.hireslipnumber;
		this.modalService.open(paymentRequestRejectPopup, { centered: true }).result.then(
			result => {
				this.closeResult = `Closed with: ${result}`;
			},
			reason => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	confirmReject() {
		swal({
			title: "Confirm reject",
			text: "Sure you want to reject and resend this payment request? Hireslip Number :" + this.selectedHireslipNumberForReject + " ",
			icon: "info",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((sureYesBtn) => {
			if (sureYesBtn) {
				this.enteredReason = $("#" + this.pageId + "rejectReason").val();
				this.invoiceDto = new InvoiceDto();
				this.invoiceDto.invoiceNumber = this.selectedHireslipNumberForReject;
				this.invoiceDto.userName = this.userDataDtoReturnSession.userName;
				this.invoiceDto.mode = 'Hire Balance';
				this.invoiceDto.driverId = this.selectedTruckBankAcId;
				this.invoiceDto.remarks = this.enteredReason;
				this.invoiceDto.status = "Rejected";
				this.confirmRejectMethod();
			}
		});
	}

	confirmRejectMethod() {
		this.showSpinnerForAction = true;
		this.hireslipService.updateHirePaymentRejectReason(this.invoiceDto).subscribe(
			(response) => {
				if (response.status == 'Success') {
					swal("Success", "Selected Hireslip Payment request rejected and resend for updates!", "info");
					this.selectedHireslipNumberForReject = null;
					this.enteredReason = null;
					this.modalService.dismissAll();
					this.searchMethod();
				} else if (response.status == 'Not Allowed') {
					swal("Not Allowed", "Payment already completed for this Hireslip!", "warning");
				} else if (response.status == 'Failed') {
					swal("Failed", "Problem occurred while updating the details", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the details", "error");
			}, () => console.log('done');
	}

	deleteSelectedBankPaymentDetails(bankPaymentDetailsData) {
		const deletedContrat = this.bankPaymentDetailsList.find(x => x.hireslipnumber === bankPaymentDetailsData.hireslipnumber);
		this.bankPaymentDetailsList.splice(this.bankPaymentDetailsList.indexOf(deletedContrat), 1);
		$("#" + this.pageId + "bankPaymentDetailsTableId").DataTable().destroy();
		setTimeout(() => {
			this.dtTriggerBankPaymentDetails.next();
		}, 1000);
	}
}



