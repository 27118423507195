import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
@Component({
    selector: 'app-commodity-mismatch-report',
    templateUrl: './commodity-mismatch-report.component.html',
    styleUrls: ['./commodity-mismatch-report.component.css']
})
export class CommodityMismatchReportComponent implements OnInit {

    getDataFrmServiceFrTable: any;

    commodityMismatchReportDataList: any;

    onDestroyUnsubscribtionCommodityMismatchReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCommodityMismatchReport: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsCommodityMismatchReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationSpecificCity: LRDto = new LRDto();

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        /* return merge(debouncedText$, inputFocus$).pipe(
             map(term => (term === '' ? this.destinationTA
                 : this.destinationTA.filter(v => v.destination)).slice(0, 200))
         );*/
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    lrDto: LRDto = new LRDto();

    userSpecificCity = false;

    fromDatesModal: any;
    toDatesModal: any;
    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;

    rightForSuperAdminRole = "rights for super administrator specific destination";
    showSpinnerForAction = false;

//For Custom Print
viewCustomPrintV1:any;

cashMemoDtoForCustomPrintListHeadingV1: any;
cashMemoDtoForCustomPrintListHeadingV2:any;
cashMemoDtoForCustomPrintListHeadingV3: any;
cashMemoDtoForCustomPrintListHeadingV4: any;

cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintList: any;
cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
cashMemoDtoForCustomPrintDataList: any;
cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
cashMemoDtoForCustomPrintDataSummaryList: any;
actWtSummary:number;
chgWtSummary:number;
artSummary:number;
discountSummary:number;
memoTotalSummary:number;
pageId="cmdmr";

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            //if(this.rightForSuperAdminRole == "rights for super administrator specific destination"){
            if (this.userDataDtoReturnSession.role != "Super Administrator") {
                this.getDestinationDetailsForSpecificCity();
            } else {
                this.getDestinationDetails();
            }

        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsCommodityMismatchReport = {
            dom: 'Bfrtip',
            buttons: [
               
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Commodity Mismatch Report - " +
                            "From Date : " + moment($("#cmdmrfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#cmdmrtoDates").val()).format('DD-MM-YYYY') + " - " +
                            "Destination : " + $("#cmdmrdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var actWgtAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgtAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArticleAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var discountAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var memoAmt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(actWgtAmt);
                $(api.column(7).footer()).html(chgWgtAmt);
                $(api.column(8).footer()).html(totalArticleAmt);
                $(api.column(9).footer()).html(discountAmt);
                $(api.column(10).footer()).html(memoAmt);
            },
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerCommodityMismatchReport.next();
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerCommodityMismatchReport.unsubscribe();
        //this.onDestroyUnsubscribtionCommodityMismatchReport.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#"+this.pageId+"destinationId").val(this.modelDestination.destination);
    }

    getDestinationDetailsForSpecificCity() {
        this.lrDtoDestinationSpecificCity = new LRDto();
        this.destinationTA = [];
        this.destinationOptions = [];
        this.lrDtoDestinationSpecificCity.destination = this.userDataDtoReturnSession.mainStation;
        this.destinationOptions.push(this.lrDtoDestinationSpecificCity)
        this.destinationTA.push(this.destinationOptions[0]);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.modelDestination = null;
        this.validateDestination = null;
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"destinationId").val('');
        this.validateFromDate = null;
        this.validateToDate = null;
        $("#"+this.pageId+"commodityMismatchRptTableId").DataTable().destroy();
        this.dtTriggerCommodityMismatchReport.next();
        this.commodityMismatchReportDataList = [];
    }

    clearBtn() {
        this.clearField();
    }

    validateSearchBtn() {
        if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
            ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
            ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
            ($("#"+this.pageId+"destinationId").val() == null) || ($("#"+this.pageId+"destinationId").val() == undefined) ||
            ($("#"+this.pageId+"destinationId").val() == "")) {
            swal("Mandatory Fields", "Please select the mandatory fields", "warning");
            return false;
        } else {
            this.setCommodityMisMatchDetailsReport();
        }
    }

    getCommodityMisMatchDetailsData() {
        this.lrDto = new LRDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateDestination = $("#"+this.pageId+"destinationId").val();

        this.lrDto.fromDate = this.validateFromDate;
        this.lrDto.toDate = this.validateToDate;
        this.lrDto.city = this.validateDestination;
    }

    setCommodityMisMatchDetailsReport() {
        this.getCommodityMisMatchDetailsData();
        this.showSpinnerForAction = true;
        this.memoReport.getCommodityMismatchDetails(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                this.commodityMismatchReportDataList = [];
                $("#"+this.pageId+"commodityMismatchRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.commodityMismatchReportDataList = response;
                    this.dtTriggerCommodityMismatchReport.next();
                } else {
                    this.dtTriggerCommodityMismatchReport.next();
                    swal("Alert", "No datas found for this information", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting CommodityMisMatch Report details", "warning");
            },
            () => console.log('done');
    }
    customPrintcomdtymismatchRpt()
    {
        if (this.commodityMismatchReportDataList.length==0) {
            swal({
        
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }else{
        
                localStorage.clear();
                this.cashMemoDtoForCustomPrintList = [];
                this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Source","Cash Memo No", "Consignee Name/Consignee", "LR Commodity","Memo Commodity","Act WT","Chg WT","Art","Discount","Memo Total","Source User","Des User"];
                this.cashMemoDtoForCustomPrintListColumnWidths = [8,10,12,13,10,9,4,4,3,7,5,9,6];
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                    this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                    this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
                }
                this.cashMemoDtoForCustomPrintDataList = [];
                this.cashMemoDtoForCustomPrintDataSummaryList = [];
                this.actWtSummary=this.chgWtSummary=this.artSummary=this.discountSummary=this.memoTotalSummary=0;
                
                for (let i = 0; i < this.commodityMismatchReportDataList.length; i++) {
                    
                    this.cashMemoDtoForCustomPrintListColumnValues = [this.commodityMismatchReportDataList[i].lrNumber, this.commodityMismatchReportDataList[i].source,
                    this.commodityMismatchReportDataList[i].cashMemoNumber, this.commodityMismatchReportDataList[i].consigneeName,this.commodityMismatchReportDataList[i].commodityName,
                                           this.commodityMismatchReportDataList[i].desCommodityName, this.commodityMismatchReportDataList[i].actualWeight,
                                           this.commodityMismatchReportDataList[i].chargedWeight, this.commodityMismatchReportDataList[i].totalArticles,
                                           this.commodityMismatchReportDataList[i].discountAmount,
										    this.commodityMismatchReportDataList[i].grandTotal, this.commodityMismatchReportDataList[i].srcUserName,
                                           this.commodityMismatchReportDataList[i].destUserName];
                    /////////
                    
                    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                    this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                    this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        
                    this.actWtSummary=this.actWtSummary+this.commodityMismatchReportDataList[i].actualWeight;
				this.chgWtSummary=this.chgWtSummary+this.commodityMismatchReportDataList[i].chargedWeight;
					this.artSummary=this.artSummary+this.commodityMismatchReportDataList[i].totalArticles;
				this.discountSummary=this.discountSummary+this.commodityMismatchReportDataList[i].discountAmount;
				this.memoTotalSummary=this.memoTotalSummary+this.commodityMismatchReportDataList[i].grandTotal;
											
                this.cashMemoDtoForCustomPrintDataSummaryList =	["Total : " + this.commodityMismatchReportDataList.length,"","","","","",this.actWtSummary,this.chgWtSummary,this.artSummary,this.discountSummary,this.memoTotalSummary,"",""];
                }
               
				
                //heading logics For Date
               
               
                //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
                this.cashMemoDtoForCustomPrintListHeadingV1 = [];
                this.cashMemoDtoForCustomPrintListHeadingV2 = [];
                this.cashMemoDtoForCustomPrintListHeadingV3 = [];
                this.cashMemoDtoForCustomPrintListHeadingV4 = [];
               
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"] ;
                this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination"] ;
              
           
             this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [ this.lrDto.fromDate,this.lrDto.toDate];
               this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [ this.lrDto.city];
               
            
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
                }
               
                for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                    this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                    this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];	
                    this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
                }
        
                //this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
                //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                    //this.cashMemoDtoForCustomPrint = new CashMemoDto();
                    //this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                    //this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            //	}
                
                //this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
                //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
                //	this.cashMemoDtoForCustomPrint = new CashMemoDto();
                //	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
                //	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
                //}
        
                localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
                localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
                localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
               
                //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
                //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
                localStorage.setItem('printTitle', "Commodity Mismatch Report");
                this.viewCustomPrintV1 = true;
                
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewCustomPrintV1) {
                        this.viewCustomPrintV1 = false;
                        localStorage.clear();
                    }
                });
        
            
            
        }
}
}