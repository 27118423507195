import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LrService } from 'src/app/dataService/lr-service';

@Component({
    selector: 'app-service-tax-report',
    templateUrl: './service-tax-report.component.html',
    styleUrls: ['./service-tax-report.component.css']
})
export class ServiceTaxReportComponent implements OnInit {

    gettingDataFrmServiceFrSmryTable: any;
    gettingDataFrmServiceFrDetailTable: any;



    onDestroyUnsubscribtionBookingServiceTaxRpt: Subscription;
    onDestroyUnsubscribtionDestinationServiceTaxRpt: Subscription;


    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    viewTypeSummary = true;
    viewTypeDetail = false;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //Datatable Start 
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dataTable: any;
    dtOptionsBookingServiceTaxDefault: any;
    dtOptionDestinationServiceTax: any;
    dtOptionSubStationServiceTax: any;
    dtOptionsBookingLessDetailsReport: any;

    dtTriggerBookingServiceTaxDefault: Subject<any> = new Subject();
    dtTriggerDestinationServiceTax: Subject<any> = new Subject();
    dtTriggerSubStationServiceTax: Subject<any> = new Subject();
    dtTriggerBookingLessDetailsReport: Subject<any> = new Subject();

    bookingServiceTaxDefaultDataList: any;
    destinationServiceTaxDataList: any;
    subStationServiceTaxDataList: any;
    bookingLessDetailsReportDataList: any;
    //Datatable End 


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    showSpinnerForAction = false;

    hideshowBkgServiceTaxDefaultRptTable = false;
    hideshowDestinationServiceTaxRptTable = false;
    hideshowSubStationServiceTaxRptTable = false;
    hideshowBookingLessDetailsReportTable = false;


    toDatesModal: any = null;
    fromDatesModal: any = null;
    validateDestinationValue: any = null;
    validateConsigneeNameValue: any = null;
    validateConsigneeNameValueId: any = null;
    validateFromDateValue: any = null;
    valiateToDateValue: any = null;

    lrDtoForBookingServiceTax: LRDto = new LRDto();
    lrDtoForDestinationServiceTax: LRDto = new LRDto();
    lrDtoForSubStationServiceTax: LRDto = new LRDto();

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    avgActWtSummary: number;
    avgChgWtSummary: number;
    articleSummary: number;
    actWtSummary: number;
    totalMemoSummary: number;
    chgWtSummary: number;
    totalAmtSummary: number;

    toPaySummary: number;
    totalStockLrsSummary: number;
    paidSummary: number;
    totalStockAmtSummary: number;
    hamaliSummary: number;
    totalAssignedLrsSummary: number;
    rcSummary: number;
    totalAssignedAmtSummary: number;
    scSummary: number;
    totalToPartyLrsSummary: number;
    grossPaySummary: number;
    totalToPartyAmtSummary: number;
    discountSummary: number;
    netPaySummary: number;
    pageId = "srtrc";
    lrDtoForRcMemoLrDetailsSelected: LRDto = new LRDto();
    lrDtoForRcMemoLrDetails: LRDto[];
    lrDtoForRcMemoCallList: LRDto[];
    lrDtoForRcMemoCallListReturn: any;

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private lrReportService: LrReportService,
        public changeDetectorRef: ChangeDetectorRef, private lrService: LrService) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.hideshowBkgServiceTaxDefaultRptTable = true;
            //this.getConsigneeDetails();
            this.getDestinationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsBookingServiceTaxDefault = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var article = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamaliChg = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var receiptChg = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var serviceChg = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var grossPay = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var disc = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var netPay = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(article);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(chgWt);
                $(api.column(9).footer()).html(toPay);
                $(api.column(10).footer()).html(paid);
                $(api.column(11).footer()).html(hamaliChg);
                $(api.column(12).footer()).html(receiptChg);
                $(api.column(13).footer()).html(serviceChg);
                $(api.column(14).footer()).html(grossPay);
                $(api.column(15).footer()).html(disc.toFixed(2));
                $(api.column(16).footer()).html(netPay.toFixed(2));
            }
        }, this.dtOptionDestinationServiceTax = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        return "<br><table style='width: 100%;'>" +
                            "<tr>" +
                            "<td style='width: 20%;'>" +
                            "<table>" +
                            "<tr>" +
                            "<td width='8%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
                            "</td>" +
                            "<td width='2%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>:</strong>" +
                            "</td>" +
                            "<td width='10%' style='text-align:left !important;'>" +
                            "<span style='font-size:15px;'>" + moment($("#srtrcfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                            "</td>" +
                            "</tr>" +
                            "<tr>" +
                            "<td width='8%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                            "</td>" +
                            "<td width='2%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>:</strong>" +
                            "</td>" +
                            "<td width='10%' style='text-align:left !important;'>" +
                            "<span style='font-size:15px;'>" + moment($("#srtrctoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                            "</td>" +
                            "</tr>" +
                            "<tr>" +
                            "<td width='8%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>Consignee</strong>" +
                            "</td>" +
                            "<td width='2%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>:</strong>" +
                            "</td>" +
                            "<td width='10%' style='text-align:left !important;'>" +
                            "<span style='font-size:15px;'>" + $("#srtrcconsigneeName").val() + "</span><br>" +
                            "</td>" +
                            "</tr>" +
                            "<tr>" +
                            "<td width='8%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>Destination</strong>" +
                            "</td>" +
                            "<td width='2%' style='text-align:left !important;'>" +
                            "<strong style='font-size:15px;'>:</strong>" +
                            "</td>" +
                            "<td width='10%' style='text-align:left !important;'>" +
                            "<span style='font-size:15px;'>" + $("#srtrcdestinationId").val() + "</span><br>" +
                            "</td>" +
                            "</tr>" +
                            "</table>" +
                            "</td>" +
                            "<td rowspan='3'style='width:60%;word-wrap: break-word;text-align:left !important;'>" +
                            "<strong style='font-size:25px;'><u>Destination Chargeable / Unchargeable Service Tax</u></strong><br>" +
                            "</td>" +
                            "<td rowspan='3' align='left' style='width:20%;'>" +
                            "</td>" +
                            "</tr>" +
                            "</table><br>";
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    },
                    exportOptions: {
                        columns: [0, 1, 3, 4, 5, 6, 9, 10, 7, 8]
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Destination Chargeable / Unchargeable Service Tax -" +
                            "From Date : " + moment($("#srtrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#srtrctoDates").val()).format('DD-MM-YYYY') + " - " +
                            "Consignee : " + $("#srtrcconsigneeName").val() + " - " +
                            "Destination : " + $("#srtrcdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var article = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(article);
                $(api.column(7).footer()).html(actWt);
                $(api.column(8).footer()).html(chgWt);
                $(api.column(9).footer()).html(toPay);
                $(api.column(10).footer()).html(paid);
            }
        },
            this.dtOptionSubStationServiceTax = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'print',
                        text: '<i class="fas fa-print"> Print</i>',
                        titleAttr: 'Print',
                        customize: function (win) {
                            $(win.document.body).css('font-size', '10pt');
                            $(win.document.body).find('th, td').
                                css('font-family', 'Arial, Helvetica, sans-serif')
                                .css('font-size', '12px').css('text-align', 'center');
                        },
                        footer: true,
                        messageTop: function () {
                            return "<br><table style='width: 100%;'>" +
                                "<tr>" +
                                "<td style='width: 20%;'>" +
                                "<table>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#srtrcfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#srtrctoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>Consignee</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + $("#srtrcconsigneeName").val() + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>Destination</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + $("#srtrcdestinationId").val() + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "</table>" +
                                "</td>" +
                                "<td rowspan='3'style='width:60%;word-wrap: break-word;text-align:left !important;'>" +
                                "<strong style='font-size:25px;'><u>" + $("#srtrcdestinationId").val() + " - SubStation Details Report</u></strong><br>" +
                                "</td>" +
                                "<td rowspan='3' align='left' style='width:20%;'>" +
                                "</td>" +
                                "</tr>" +
                                "</table><br>";
                        },
                        title: function () {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='left' style='width: 20%;'>" +
                                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                                "</td>" +
                                "<td align='left' style='width: 30%;'>" +
                                "</td>" +
                                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                                companyAddressDetls +
                                "</td>" +
                                "</tr></table>";
                        },
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]
                        }
                    },
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return " " +
                                "" + $("#srtrcdestinationId").val() + " - Substation Details Report" +
                                " - Consignee : " + $("#srtrcconsigneeName").val() + " - " +
                                " From Date : " + moment($("#srtrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#srtrctoDates").val()).format('DD-MM-YYYY') + "";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 300,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": true,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var article = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actWt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPay = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(3).footer()).html(article);
                    $(api.column(7).footer()).html(actWt);
                    $(api.column(8).footer()).html(chgWt);
                    $(api.column(9).footer()).html(toPay);
                    $(api.column(10).footer()).html(paid);
                }
            },
            this.dtOptionsBookingLessDetailsReport = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "BILL TO BILL PARTIES & LESS PARTIES REPORT " + " - " +
                                "From : " + moment($("#srtrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To : " + moment($("#srtrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#srtrcdestinationId").val() + " - " +
                                "Consignee : " + $("#srtrcconsigneeName").val();
                        },
                        customize: function (xlsx: { xl: { worksheets: { [x: string]: any; }; }; }) {
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];
                            //for border in table export
                            $('row c', sheet).attr('s', '25');
                            // for first column to be bold
                            // $('row:first c', sheet).attr('s', '2');
                            $('row:nth-child(2) c', sheet).attr('s', '2');
                            // for last column to be bold
                            $('row:last c', sheet).attr('s', '2');

                            // width set for column
                            var col = $('col', sheet);
                            console.log('excel : ' + col);
                            $(col[26]).attr('width', 100);
                            // for wrap text for particulr column
                            $('row c[r^="AB"]', sheet).attr('s', '55');
                            //center
                            // $('row c[r^="A"]', sheet).attr('s', '51');
                            // $('row c[r^="B"]', sheet).attr('s', '51');
                            // $('row c[r^="C"]', sheet).attr('s', '51');
                            // $('row c[r^="D"]', sheet).attr('s', '51');

                            //$('row c[r*="1"]', sheet).attr( 's', '25' );
                            //$('row c[r*="1"]', sheet).attr( 's', '51' );
                            $('row:eq(0) c', sheet).attr('s', '2');

                            var lastXfIndex = $('cellXfs xf', sheet).length;
                            console.log('center : ' + lastXfIndex);
                            var greyBoldCentered = lastXfIndex + 4;
                            // $('row:eq(0) c', sheet).attr('s', 50);


                            // $('row:eq(0) c', sheet).attr('s', '50');
                            // $('row:eq(1) c', sheet).attr('s', '50');
                            // $('row:last c', sheet).attr('s', '50');
                        },
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                                11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                                21, 22, 23, 24, 25, 26]
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": true,
                //"order": [[7, 'asc'], [17, 'asc']],
                // columnDefs: [{
                //     targets: 0,
                //     render: function (data, type, row, meta) {
                //         console.log(meta.row);
                //         console.log(type == 'export' ? meta.row + 1 : data);
                //         return meta.row + 1;
                //     }
                // }],

                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 

                    var article = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var baseFreight = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPay = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fov = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var disc = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var sHamali = api.column(27).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var lcChg = api.column(28).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var bcChg = api.column(29).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var aocChg = api.column(30).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var ddChg = api.column(31).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var gcChg = api.column(32).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total');
                    $(api.column(1).footer()).html(data.length);
                    $(api.column(9).footer()).html(article);
                    $(api.column(10).footer()).html(actWgt);
                    $(api.column(11).footer()).html(chgWt);
                    $(api.column(12).footer()).html(baseFreight);
                    $(api.column(13).footer()).html(toPay);
                    $(api.column(14).footer()).html(paid);
                    $(api.column(15).footer()).html(fov);
                    $(api.column(16).footer()).html(disc);

                    $(api.column(20).footer()).html(sHamali);
                    $(api.column(21).footer()).html(gcChg);
                    $(api.column(22).footer()).html(lcChg);
                    $(api.column(23).footer()).html(bcChg);
                    $(api.column(24).footer()).html(aocChg);
                    $(api.column(25).footer()).html(ddChg);
                }
            }
    }

    ngAfterViewInit(): void {
        this.dtTriggerBookingServiceTaxDefault.next();
        this.dtTriggerBookingLessDetailsReport.next();
        //this.dtTriggerDetail.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerBookingServiceTaxDefault.unsubscribe();
        this.dtTriggerDestinationServiceTax.unsubscribe();
        this.dtTriggerSubStationServiceTax.unsubscribe();
        this.dtTriggerBookingLessDetailsReport.unsubscribe();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }


    viewTypeMode(viewType: string) {
        if (viewType === 'bookingServiceTaxReport') {
            this.hideshowBkgServiceTaxDefaultRptTable = true;
            this.hideshowDestinationServiceTaxRptTable = false;
            this.hideshowSubStationServiceTaxRptTable = false;
            this.hideshowBookingLessDetailsReportTable = false;

            this.bookingServiceTaxDefaultDataList = [];
            $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
            this.dtTriggerBookingServiceTaxDefault.next();

            this.destinationServiceTaxDataList = [];
            $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();

            this.subStationServiceTaxDataList = [];
            $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();

        } else if (viewType === 'destinationServiceTaxReport') {
            this.hideshowBkgServiceTaxDefaultRptTable = false;
            this.hideshowDestinationServiceTaxRptTable = true;
            this.hideshowSubStationServiceTaxRptTable = false;
            this.hideshowBookingLessDetailsReportTable = false;

            this.destinationServiceTaxDataList = [];
            $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();
            this.dtTriggerDestinationServiceTax.next();


            this.bookingServiceTaxDefaultDataList = [];
            $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
            this.subStationServiceTaxDataList = [];
            $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();

            this.clearFields();

        } else if (viewType === 'subStationDetailsReport') {
            this.hideshowBkgServiceTaxDefaultRptTable = false;
            this.hideshowDestinationServiceTaxRptTable = false;
            this.hideshowSubStationServiceTaxRptTable = true;
            this.hideshowBookingLessDetailsReportTable = false;

            this.subStationServiceTaxDataList = [];
            $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();
            this.dtTriggerSubStationServiceTax.next();

            this.bookingServiceTaxDefaultDataList = [];
            $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
            this.destinationServiceTaxDataList = [];
            $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();

            this.clearFields();
        } else if (viewType === 'bookingLessDetailsReport') {
            this.hideshowBkgServiceTaxDefaultRptTable = false;
            this.hideshowDestinationServiceTaxRptTable = false;
            this.hideshowSubStationServiceTaxRptTable = false;
            this.hideshowBookingLessDetailsReportTable = true;

            this.subStationServiceTaxDataList = [];
            $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();

            this.bookingServiceTaxDefaultDataList = [];
            $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();

            this.destinationServiceTaxDataList = [];
            $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();

            this.bookingLessDetailsReportDataList = [];
            $("#" + this.pageId + "bookingLessDetailsReportTableId").DataTable().destroy();
            this.dtTriggerBookingLessDetailsReport.next();

            this.clearFields();
        }

    }


    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        this.validateConsigneeNameValueId = null;
        if ($("#" + this.pageId + "destinationId").val() != null && $("#" + this.pageId + "destinationId").val() != undefined &&
            $("#" + this.pageId + "destinationId").val() != "") {
            this.getConsigneeDetails();

            this.subStationServiceTaxDataList = [];
            $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();
            this.dtTriggerBookingServiceTaxDefault.next();

            this.bookingServiceTaxDefaultDataList = [];
            $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
            this.dtTriggerDestinationServiceTax.next();

            this.destinationServiceTaxDataList = [];
            $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();
            this.dtTriggerSubStationServiceTax.next();

            this.bookingLessDetailsReportDataList = [];
            $("#" + this.pageId + "bookingLessDetailsReportTableId").DataTable().destroy();
            this.dtTriggerBookingLessDetailsReport.next();
        }
    }

    consigneeDropDownListner(event) {
        this.modelConsigneeName = event.item;
        $("#" + this.pageId + "consigneeName").val(event.item.consigneeName);
        this.validateConsigneeNameValue = event.item.consigneeName;
        this.validateConsigneeNameValueId = event.item.consigneeId;
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    //this.lrDtoDestinationAllOption.destination = "All";
                    //this.destinationTA.push(this.lrDtoDestinationAllOption);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getConsigneeDetailsList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        //this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.mode = "specific";
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        if (this.validateDestinationValue != null && this.validateDestinationValue != undefined &&
            this.validateDestinationValue != "") {
            this.partyMasterDtoForconsignee.office = this.validateDestinationValue;
        }
        /*else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }*/
    }
    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];

                    this.consigneeNameOptions = response;

                    this.consigneeNamePartyDtoAllOption = new PartyMasterDto();
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    $("#" + this.pageId + "consigneeName").val('All');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    validateClearBtn() {
        $("#" + this.pageId + "viewType").val('bookingServiceTaxReport');

        this.hideshowBkgServiceTaxDefaultRptTable = true;
        this.hideshowDestinationServiceTaxRptTable = false;
        this.hideshowSubStationServiceTaxRptTable = false;
        this.hideshowBookingLessDetailsReportTable = false;

        this.destinationServiceTaxDataList = [];
        $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();
        this.subStationServiceTaxDataList = [];
        $("#" + this.pageId + "bookingServiceTaxDefaultDataList").DataTable().destroy();


        this.bookingServiceTaxDefaultDataList = [];
        $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
        this.dtTriggerBookingServiceTaxDefault.next();

        this.clearFields();
    }

    clearFields() {
        this.modelDestination = null;

        this.modelConsigneeName = null;
        this.consigneeNamePartyDtoAllOption = new PartyMasterDto();
        this.consigneeNameOptions = [];
        this.consigneeNameTA = [];

        this.validateConsigneeNameValue = null;
        this.validateConsigneeNameValueId = null;
        this.validateFromDateValue = null;
        this.valiateToDateValue = null;
        this.toDatesModal = null;
        this.fromDatesModal = null;
        this.validateDestinationValue = null;
        $("#" + this.pageId + "consigneeName").val('');
    }

    validateSearchBtn() {
        if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
            ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
            ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
            ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
            ($("#" + this.pageId + "destinationId").val() == "")) {
            swal("Mandatory Fields", "From Date,To Date and Destination Are mandatory fields", "warning");
            return false;
        } else {
            if ($("#" + this.pageId + "viewType").val() == "bookingServiceTaxReport") {
                this.setBookingServiceTaxReportDetails();
            } else if ($("#" + this.pageId + "viewType").val() == "destinationServiceTaxReport") {
                this.setDestinationServiceTaxReportDetails();
            } else if ($("#" + this.pageId + "viewType").val() == "subStationDetailsReport") {
                this.setSubStationServiceTaxReportDetails();
            } else if ($("#" + this.pageId + "viewType").val() == "bookingLessDetailsReport") {
                this.setBookingLessDetailsReport();
            }
        }

    }

    getBookingServiceTaxReportData() {
        this.lrDtoForBookingServiceTax = new LRDto();
        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();

        this.lrDtoForBookingServiceTax.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForBookingServiceTax.fromDate = this.validateFromDateValue;
        this.lrDtoForBookingServiceTax.toDate = this.valiateToDateValue;
        this.lrDtoForBookingServiceTax.destination = this.validateDestinationValue;
        this.lrDtoForBookingServiceTax.consigneeName = this.validateConsigneeNameValue;
        if ($("#" + this.pageId + "consigneeName").val() == null || $("#" + this.pageId + "consigneeName").val() == undefined ||
            $("#" + this.pageId + "consigneeName").val() == "" || $("#" + this.pageId + "consigneeName").val() == "All") {
            this.lrDtoForBookingServiceTax.consigneeId = 0;
        } else {
            this.lrDtoForBookingServiceTax.consigneeId = this.validateConsigneeNameValueId;
        }
    }

    setBookingServiceTaxReportDetails() {
        this.getBookingServiceTaxReportData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingServiceTaxBookingReport(this.lrDtoForBookingServiceTax).subscribe(
            (response) => {
                console.log(response);
                this.bookingServiceTaxDefaultDataList = [];
                $("#" + this.pageId + "bookingServiceTaxDefaultRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.bookingServiceTaxDefaultDataList = response;
                } else {
                    swal("Alert", "No datas found for this information", "warning");
                }
                this.dtTriggerBookingServiceTaxDefault.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Service Tax details", "info");
            }, () => console.log('done');
    }

    setBookingLessDetailsReport() {
        this.getBookingServiceTaxReportData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingServiceTaxBookingLessDetails(this.lrDtoForBookingServiceTax).subscribe(
            (response) => {
                console.log(response);
                this.bookingLessDetailsReportDataList = [];
                $("#" + this.pageId + "bookingLessDetailsReportTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.bookingLessDetailsReportDataList = response;
                    console.log('less data : ' + this.bookingLessDetailsReportDataList[0]);
                } else {
                    swal("Alert", "No datas found for this information", "warning");
                }
                this.dtTriggerBookingLessDetailsReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Service Tax details", "info");
            }, () => console.log('done');
    }

    getDestinationServiceTaxReportData() {
        this.lrDtoForDestinationServiceTax = new LRDto();

        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();

        this.lrDtoForDestinationServiceTax.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForDestinationServiceTax.fromDate = this.validateFromDateValue;
        this.lrDtoForDestinationServiceTax.toDate = this.valiateToDateValue;
        this.lrDtoForDestinationServiceTax.destination = this.validateDestinationValue;
        this.lrDtoForDestinationServiceTax.consigneeName = this.validateConsigneeNameValue;
        if ($("#" + this.pageId + "consigneeName").val() == null || $("#" + this.pageId + "consigneeName").val() == undefined ||
            $("#" + this.pageId + "consigneeName").val() == "" || $("#" + this.pageId + "consigneeName").val() == "All") {
            this.lrDtoForDestinationServiceTax.consigneeId = 0;
        } else {
            this.lrDtoForDestinationServiceTax.consigneeId = this.validateConsigneeNameValueId;
        }
    }

    setDestinationServiceTaxReportDetails() {
        this.getDestinationServiceTaxReportData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingServiceTaxDestReport(this.lrDtoForDestinationServiceTax).subscribe(
            (response) => {
                console.log(response);
                this.destinationServiceTaxDataList = [];
                $("#" + this.pageId + "destinationServiceTaxRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.destinationServiceTaxDataList = response;
                } else {
                    swal("Alert", "No datas found for this information", "warning");
                }
                this.dtTriggerDestinationServiceTax.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Service Tax details", "info");
            }, () => console.log('done');
    }

    getSubStationServiceTaxReportData() {
        this.lrDtoForSubStationServiceTax = new LRDto();

        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
        this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();

        this.lrDtoForSubStationServiceTax.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForSubStationServiceTax.fromDate = this.validateFromDateValue;
        this.lrDtoForSubStationServiceTax.toDate = this.valiateToDateValue;
        this.lrDtoForSubStationServiceTax.destination = this.validateDestinationValue;
        this.lrDtoForSubStationServiceTax.consigneeName = this.validateConsigneeNameValue;
        if ($("#" + this.pageId + "consigneeName").val() == null || $("#" + this.pageId + "consigneeName").val() == undefined ||
            $("#" + this.pageId + "consigneeName").val() == "" || $("#" + this.pageId + "consigneeName").val() == "All") {
            this.lrDtoForSubStationServiceTax.consigneeId = 0;
        } else {
            this.lrDtoForSubStationServiceTax.consigneeId = this.validateConsigneeNameValueId;
        }
    }

    setSubStationServiceTaxReportDetails() {
        this.getSubStationServiceTaxReportData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingServiceTaxSubstationReport(this.lrDtoForSubStationServiceTax).subscribe(
            (response) => {
                console.log(response);
                this.subStationServiceTaxDataList = [];
                $("#" + this.pageId + "subStationServiceTaxRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.subStationServiceTaxDataList = response;
                } else {
                    swal("Alert", "No datas found for this information", "warning");
                }
                this.dtTriggerSubStationServiceTax.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the SubStation Service Tax details", "info");
            }, () => console.log('done');
    }

    bulkLRRCMemoBtn() {
        if (this.bookingLessDetailsReportDataList == null || this.bookingLessDetailsReportDataList == undefined ||
            this.bookingLessDetailsReportDataList == "" || this.bookingLessDetailsReportDataList.length == 0) {
            swal("Not Allowed", "No Lrs found to proceed", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Action",
                text: "Sure you want to recalculate the discount for all the LRs? This process will take some time!",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.rcMemoCall(this.getRcMemoLrDetails(this.bookingLessDetailsReportDataList));
                }
            });
        }
    }

    getRcMemoLrDetails(bookingLessDetailsReportDataList) {
        this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
        this.lrDtoForRcMemoLrDetails = [];
        for (let i = 0; i < bookingLessDetailsReportDataList.length; i++) {
            this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
            this.lrDtoForRcMemoLrDetailsSelected.lrNumber = bookingLessDetailsReportDataList[i].lrNumber;
            this.lrDtoForRcMemoLrDetailsSelected.source = bookingLessDetailsReportDataList[i].source;
            this.lrDtoForRcMemoLrDetailsSelected.subCommodity = bookingLessDetailsReportDataList[i].commodityName;
            this.lrDtoForRcMemoLrDetailsSelected.consigneeId = bookingLessDetailsReportDataList[i].consigneeId;
            this.lrDtoForRcMemoLrDetailsSelected.consigneeName = bookingLessDetailsReportDataList[i].consigneeName;
            this.lrDtoForRcMemoLrDetailsSelected.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoForRcMemoLrDetailsSelected.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForRcMemoLrDetails.push(this.lrDtoForRcMemoLrDetailsSelected);
        }
        return this.lrDtoForRcMemoLrDetails;
    }

    rcMemoCall(bookingLessDetailsReportDataList) {
        this.showSpinnerForAction = true;
        this.lrDtoForRcMemoCallList = [];
        this.lrDtoForRcMemoCallList = bookingLessDetailsReportDataList;
        console.log(bookingLessDetailsReportDataList);
        console.log(this.lrDtoForRcMemoCallList);
        this.lrService.recalculateBkgMemoAfterAdminRateOnDestLessChkUpload(this.lrDtoForRcMemoCallList)
            .subscribe((response) => {
                this.lrDtoForRcMemoCallListReturn = null;
                this.lrDtoForRcMemoCallListReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                var failedSuccessMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                console.log(failedSuccessMsg);
                if (failedSuccessMsg[0] != null &&
                    failedSuccessMsg[0] == "success") {
                    swal("Info", "Memo recalculate done!", "info");
                } else {
                    var failedMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                    swal("Failed", "Failed to recalculate the Discount! The LR Number is " + failedMsg[1] + "", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Recalculate the Discount!", "info");
            },
            () => console.log("done");
    }

    //Custom Print
    customPrintBkgServiceTaxDefaultRpt() {
        if (this.bookingServiceTaxDefaultDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Destination", "Art No", "Consignee Name", "Invoice No", "Invoice Date", "Act Wt", "Chg Wt", "To Pay", "Paid", "Hamali Chg", "RC", "SC", "Gross Pay", "Discount", "Net Pay"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [7, 9, 4, 10, 10, 6, 5, 5, 5, 5, 7, 5, 5, 7, 7, 6];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            this.articleSummary = this.actWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.hamaliSummary = this.rcSummary = this.scSummary = this.grossPaySummary = this.discountSummary =
                this.netPaySummary = 0;

            for (let i = 0; i < this.bookingServiceTaxDefaultDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.bookingServiceTaxDefaultDataList[i].lrNumber, this.bookingServiceTaxDefaultDataList[i].destination,
                this.bookingServiceTaxDefaultDataList[i].totalArticles, this.bookingServiceTaxDefaultDataList[i].consigneeName, this.bookingServiceTaxDefaultDataList[i].invoiceNumber, this.bookingServiceTaxDefaultDataList[i].invoiceDateStr, this.bookingServiceTaxDefaultDataList[i].actualWeight,
                this.bookingServiceTaxDefaultDataList[i].chargedWeight, this.bookingServiceTaxDefaultDataList[i].toPay, this.bookingServiceTaxDefaultDataList[i].paid, this.bookingServiceTaxDefaultDataList[i].hamali, this.bookingServiceTaxDefaultDataList[i].receiptCharge,
                this.bookingServiceTaxDefaultDataList[i].serviceCharge, this.bookingServiceTaxDefaultDataList[i].totalAmount, this.bookingServiceTaxDefaultDataList[i].discountAmount, this.bookingServiceTaxDefaultDataList[i].grandTotal];

                /////////  

                this.articleSummary = +this.articleSummary + this.bookingServiceTaxDefaultDataList[i].totalArticles;
                this.actWtSummary = +this.actWtSummary + this.bookingServiceTaxDefaultDataList[i].actualWeight;
                this.chgWtSummary = +this.chgWtSummary + this.bookingServiceTaxDefaultDataList[i].chargedWeight;

                this.toPaySummary = +this.toPaySummary + this.bookingServiceTaxDefaultDataList[i].toPay;
                this.paidSummary = +this.paidSummary + this.bookingServiceTaxDefaultDataList[i].paid;
                this.hamaliSummary = +this.hamaliSummary + this.bookingServiceTaxDefaultDataList[i].hamali;
                this.rcSummary = +this.rcSummary + this.bookingServiceTaxDefaultDataList[i].receiptCharge;
                this.scSummary = +this.scSummary + this.bookingServiceTaxDefaultDataList[i].serviceCharge;
                this.grossPaySummary = +this.grossPaySummary + this.bookingServiceTaxDefaultDataList[i].totalAmount;
                this.discountSummary = +this.discountSummary + this.bookingServiceTaxDefaultDataList[i].discountAmount;
                this.netPaySummary = +this.netPaySummary + this.bookingServiceTaxDefaultDataList[i].grandTotal;


                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.bookingServiceTaxDefaultDataList.length, "", this.articleSummary, "", "", "",
                this.actWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.hamaliSummary, this.rcSummary,
                this.scSummary, this.grossPaySummary, this.discountSummary, this.netPaySummary];
            }




            //heading logics
            this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
            this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateValue, this.valiateToDateValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
            this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignee", "Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateConsigneeNameValue, this.validateDestinationValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }






            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));

            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking Chargeable/Unrechargeable Service Tax");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }




    customPrintSubStationServiceTaxRpt() {
        if (this.subStationServiceTaxDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Station", "Booking Date", "Articles", "Consignee Name", "Invoice No", "Invoice Date", "Act Wt", "Chg Wt", "To Pay", "Paid"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 8, 16, 15, 9, 8, 8, 8, 8];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            this.articleSummary = this.actWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.hamaliSummary = this.rcSummary = this.scSummary = this.grossPaySummary = this.discountSummary =
                this.netPaySummary = 0;

            for (let i = 0; i < this.subStationServiceTaxDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.subStationServiceTaxDataList[i].lrNumber, this.subStationServiceTaxDataList[i].deliveryPoints, this.subStationServiceTaxDataList[i].bookingDateStr,
                this.subStationServiceTaxDataList[i].totalArticles, this.subStationServiceTaxDataList[i].consigneeName, this.subStationServiceTaxDataList[i].invoiceNumber, this.subStationServiceTaxDataList[i].invoiceDateStr, this.subStationServiceTaxDataList[i].actualWeight, this.subStationServiceTaxDataList[i].chargedWeight, this.subStationServiceTaxDataList[i].toPay,
                this.subStationServiceTaxDataList[i].paid];

                /////////  

                this.articleSummary = +this.articleSummary + this.subStationServiceTaxDataList[i].totalArticles;
                this.actWtSummary = +this.actWtSummary + this.subStationServiceTaxDataList[i].actualWeight;
                this.chgWtSummary = +this.chgWtSummary + this.subStationServiceTaxDataList[i].chargedWeight;

                this.toPaySummary = +this.toPaySummary + this.subStationServiceTaxDataList[i].toPay;
                this.paidSummary = +this.paidSummary + this.subStationServiceTaxDataList[i].paid;



                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.subStationServiceTaxDataList.length, "", "", this.articleSummary, "", "", "",
                this.actWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary];
            }




            //heading logics
            this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
            this.valiateToDateValue = $("#" + this.pageId + "toDates").val();

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateValue, this.valiateToDateValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
            this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignee", "Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateConsigneeNameValue, this.validateDestinationValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));

            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "SubStation Chargeable/Unchargeable Service Tax");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }


    customPrintDestinationServiceTaxRpt() {
        if (this.destinationServiceTaxDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Destination", "Articles", "Consignee Name", "Invoice No", "Invoice Date", "To Pay Amt", "Paid Amt", "Act Wt", "Chg Wt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 8, 16, 15, 9, 8, 8, 8, 8];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            this.articleSummary = this.actWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.hamaliSummary = this.rcSummary = this.scSummary = this.grossPaySummary = this.discountSummary =
                this.netPaySummary = 0;

            for (let i = 0; i < this.destinationServiceTaxDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.destinationServiceTaxDataList[i].lrNumber, this.destinationServiceTaxDataList[i].destination,
                this.destinationServiceTaxDataList[i].totalArticles, this.destinationServiceTaxDataList[i].consigneeName, this.destinationServiceTaxDataList[i].invoiceNumber, this.destinationServiceTaxDataList[i].invoiceDateStr, this.destinationServiceTaxDataList[i].toPay,
                this.destinationServiceTaxDataList[i].paid, this.destinationServiceTaxDataList[i].actualWeight, this.destinationServiceTaxDataList[i].chargedWeight];

                /////////  

                this.articleSummary = +this.articleSummary + this.destinationServiceTaxDataList[i].totalArticles;
                this.actWtSummary = +this.actWtSummary + this.destinationServiceTaxDataList[i].actualWeight;
                this.chgWtSummary = +this.chgWtSummary + this.destinationServiceTaxDataList[i].chargedWeight;

                this.toPaySummary = +this.toPaySummary + this.destinationServiceTaxDataList[i].toPay;
                this.paidSummary = +this.paidSummary + this.destinationServiceTaxDataList[i].paid;



                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.destinationServiceTaxDataList.length, "", this.articleSummary, "", "", "",
                this.toPaySummary, this.paidSummary, this.actWtSummary, this.chgWtSummary];
            }




            //heading logics
            this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
            this.valiateToDateValue = $("#" + this.pageId + "toDates").val();

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateValue, this.valiateToDateValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
            this.validateConsigneeNameValue = $("#" + this.pageId + "consigneeName").val();
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignee", "Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateConsigneeNameValue, this.validateDestinationValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));

            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Destination Chargeable/Unchargeable Service Tax");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }
}
