<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Driver Rewarded Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}fromDate">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
															#fromDates="ngbDatepicker">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input
															id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd"
															name="toDates" ngbDatepicker #toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Destination</option>
															<option value="all">All</option>
															<option value="bangalore">Bangalore</option>
															<option value="chennai">Chennai</option>
															<option value="kerela">Kerela</option>
														</select>
													</div>
												</div>
											</div>
											




										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsDriverRewardedReport" [dtTrigger]="dtTriggerDriverRewardedReport">

								<thead>
									<tr>
										<th>GSTIN/UNI Of Recipient</th>
										<th>Invoice Number</th>
										<th>Invoice Date</th>
										<th>Invoice Value</th>
										<th>Place Of Supply</th>
										<th>Reverse Charge</th>
										<th>Invoice Type</th>
										<th>E-Commerece GSTIN</th>
										<th>Rate</th>
										<th>Taxable Value</th>
										<th>Cess Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let driverRewardedReportData of driverRewardedReportDataList ">
										<td>{{ driverRewardedReportData.gstinUinOfRecipient }}</td>
										<td>{{ driverRewardedReportData.invoiceNumber }}</td>
										<td>{{ driverRewardedReportData.invoiceDate }}</td>
										<td>{{ driverRewardedReportData.invoiceValue }}</td>
										<td>{{ driverRewardedReportData.placeOfSupply }}</td>
										<td>{{ driverRewardedReportData.reverseCharge }}</td>
										<td>{{ driverRewardedReportData.invoiceType }}</td>
										<td>{{ driverRewardedReportData.eCommerceGstin }}</td>
										<td>{{ driverRewardedReportData.rate }}</td>
										<td>{{ driverRewardedReportData.taxableValue }}</td>
										<td>{{ driverRewardedReportData.cessAmount }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>