<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Agent Setup</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6" style="padding: 0px !important;">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-md-6">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Agent Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}selectAgentType"
															name="selectAgentType" #selectAgentType
															(change)="selectAgentTypeMode(selectAgentType.value)">
															<!-- 															<option selected>Select...</option> -->
															<option value="bookingAgent" selected>Booking
																Agent</option>
															<option value="destinationAgent">Destination
																Agent</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="viewStationType" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Station Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12"
															id="{{pageId}}selectStationType" name="selectStationType"
															#selectStationType
															(change)="selectStationTypeMode(selectStationType.value)">
															<option *ngIf="viewBookingAgent"
																value="mainStationBooking" selected>Main
																Station</option>
															<option *ngIf="viewBookingAgent"
																value="subStationBooking">Sub Station</option>
															<option *ngIf="viewDestinationAgent"
																value="mainStationDestination" selected>Main
																Station</option>
															<option *ngIf="viewDestinationAgent"
																value="subStationDestination">Sub Station</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}agentName">
														<label>Agent Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewBookingAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}city">
														<label>City</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>

											<!-- 											the first autocomplete starts  -->
											<div class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}state">
															<label>State</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select State" [formControl]="controlState"
																[appAutocomplete]="autocompleteState">
														</div>
													</div>
												</div>

												<app-autocomplete #autocompleteState="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(stateOptions | filter: controlState.value) as resultState">
												<app-option *ngFor="let option of resultState"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultState.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>
											<!-- 											the first autocomplete ends -->
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="form-group" id="{{pageId}}address">
														<label>Address</label>
														<div class="form-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
														</div>
														<textarea class="form-control" rows="2"></textarea>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}phoneNo">
														<label>Phone No</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-phone"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}emailAddress">
														<label>Email Address</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-envelope"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}contactPerson">
														<label>Contact Person</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6 vl">
											<div *ngIf="viewBookingAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}agentCode">
														<label>Agent Code</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-lock"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewBookingAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Main Branch</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-home"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}mainBranch"
															name="mainBranch">
															<option selected value="">Select.....</option>
															<option value="mumbai">Mumbai</option>
															<option value="delhi">Delhi</option>
														</select>
													</div>
												</div>
											</div>


											<!-- 											the second autocomplete starts  -->
											<div *ngIf="viewMainStation" class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group" id="{{pageId}}mainStationName">
															<label>Main Station Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-warehouse"></i>
																</span>
															</div>
															<input class="auto_selectOption input is-medium"
																placeholder="Select Main Station"
																[formControl]="control" [appAutocomplete]="autocomplete">
														</div>
													</div>
												</div>

												<app-autocomplete #autocomplete="appAutocomplete">
												<ng-template appAutocompleteContent> <ng-container
													*ngIf="(mainStationNameOptions | filter: control.value) as resultMainStationName">
												<app-option *ngFor="let option of resultMainStationName"
													[value]="option.label"> {{ option.label }} </app-option> <app-option
													class="no-result" *ngIf="!resultMainStationName.length">No
												result</app-option> </ng-container> </ng-template> </app-autocomplete>
											</div>
											<!-- 											the second autocomplete ends -->


											<div *ngIf="viewDestinationAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Working On Sunday</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-home"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}workingOnSumday"
															name="workingOnSumday">
															<option selected value="no">No</option>
															<option value="yes">Yes</option>
														</select>
													</div>
												</div>
											</div>


											<div *ngIf="
												viewDestinationAgent"
												class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}deliveryCharges">
														<label>Delivery Charges</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewDestinationAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Unit</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-weight"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}unitDc"
															name="unitDc">
															<option selected>Select...</option>
															<option value="articleDc">Article</option>
															<option value="actualWeightDc">Actual Weight</option>
															<option value="chargedWeightDc">Charged Weight</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="
												viewDestinationAgent"
												class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}loadingAndUnloadingCharges">
														<label>Loading & Unloading Charges</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewDestinationAgent" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Unit</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-weight"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}unitLuc"
															name="unitLuc">
															<option selected>Select...</option>
															<option value="articleLuc">Article</option>
															<option value="actualWeightLuc">Actual Weight</option>
															<option value="chargedWeightLuc">Charged Weight</option>
														</select>
													</div>
												</div>
											</div>

											<div *ngIf="viewDestinationAgentSubStation"
												class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}subStationGdCharges">
														<label>Sub Station GD Charges</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="number" class="form-control"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div *ngIf="viewDestinationAgentSubStation"
												class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Unit</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-weight"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}unitSsgdc"
															name="unitSsgdc">
															<option selected>Select...</option>
															<option value="articleSsgdc">Article</option>
															<option value="actualWeightSsgdc">Actual Weight</option>
															<option value="chargedWeightSsgdc">Charged
																Weight</option>
														</select>
													</div>
												</div>
											</div>



										</div>
									</div>
									<div class="row p-t-10">
										<div class="col-md-12" style="text-align: center;">
											<button type="submit" class="btn btn-success m-r-10"
												id="{{pageId}}saveBtn">Save</button>
											<button type="submit" class="btn btn-danger m-r-10"
												id="{{pageId}}deleteBtn">Delete</button>
											<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
										</div>
									</div>
								</div>
								<div class="col-md-6 vl p-t-10">
									<div class="row">
										<div class="col-sm-12 col-md-6">
											<div class="form-group">
												<div class="input-group">
													<label>Agent Type</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-question"></i>
														</span>
													</div>
													<select class="custom-select col-12" id="{{pageId}}selectAgentType"
														name="selectAgentType">
														<option selected>Select...</option>
														<option value="bookingAgent">Booking Agent</option>
														<option value="destinationAgent">Destination
															Agent</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-md-12 p-t-10">
											<div class="box-body">
												<table datatable
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsBookingAgent"
													[dtTrigger]="dtTriggerBookingAgent">

													<thead>
														<tr>
															<th>Agent Name</th>
															<th>Station Name</th>
															<th style="display: none;">Contact Person</th>
															<th style="display: none;">Phone No</th>
															<th>Address</th>
															<th>City</th>
															<th>State</th>
															<th style="display: none;">Email Address</th>
															<th>Agent Code</th>
															<th>Branch</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let bookingAgentData of bookingAgentDataList ">
															<td>{{ bookingAgentData.agentName }}</td>
															<td>{{ bookingAgentData.stationName }}</td>
															<td style="display: none;">{{
																bookingAgentData.contactPerson }}</td>
															<td style="display: none;">{{
																bookingAgentData.phoneNo }}</td>
															<td>{{ bookingAgentData.address }}</td>
															<td>{{ bookingAgentData.city }}</td>
															<td>{{ bookingAgentData.state }}</td>
															<td style="display: none;">{{
																bookingAgentData.emailAddress }}</td>
															<td>{{ bookingAgentData.agentCode }}</td>
															<td>{{ bookingAgentData.branch }}</td>
														</tr>


														<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

													</tbody>
												</table>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>